import * as IGrid from 'aui-grid';



export const translatorInvoiceStatus = {
  ORDER_TAB: 'INVOICE_STATUS',
  ORDER_PROGRESS: 'INVOICE_STATUS_1',
  ORDER_FINISHORCANCEL: 'INVOICE_STATUS_2',
};


export const ORDER_COUNTS = {
  ORDER_TAB: ['confirmFlag', 'assignFlag', 'unassignFlag', 'finishFlag', 'assigningFlag', 'pendFlag', 'cancelFlag'],
  ORDER_PROGRESS: ['confirmFlag', 'assignFlag', 'unassignFlag', 'assigningFlag', 'pendFlag'],
};

export const ORDER_FLAG_KR = {
  confirmFlag: '스케줄확정',
  assignFlag: '접수완료',
  unassignFlag: '미연동',
  finishFlag: '완료',
  assigningFlag: '처리중',
  pendFlag: '보류',
  cancelFlag: '취소',
};

export const defaultSelectTypeObj = {
  SEARCH_ORDER: null,
  SEARCH_INVOICE: null,
  SEARCH_DATE: null,
  SEARCH_MODEL: null,
  SEARCH_SKU: null,
  SEARCH_NAME: 'receiverName',
  SEARCH_ADDRESS: 'receiverAddr',
  SEARCH_CONTACT: 'receiverPhone',
};

export const FLAG_DETAILS = {
  confirmFlagKr: ['스케줄확정', '출고대기', '반출대기', '배송중'],
  confirmFlag: ['confirm', 'loan', 'removing', 'delivery'],
  assignFlagKr: ['접수완료'],
  assignFlag: ['assign'],
  unassignFlagKr: ['주소오류', '접수대기', '우편번호오류', '배송불가지역', '창고권역미설정', '기사권역미설정', '기사설정오류', '기사캐파초과', '컷오프마감', '재고없음', '가접수완료'],
  unassignFlag: [
    'addressError',
    'init',
    'zipcodeError',
    'excludeZipcode',
    'notExistCenter',
    'notExistDriver',
    'driverSettingError',
    'driverCapaExceed',
    'centerCutoffClose',
    'notInInventory',
    'assignVirt',
  ],
  finishFlagColor: 'blue',
  finishFlagKr: [
    '배송완료',
    '방문완료',
    '회수완료',
    '교환회수완료',
    '교환배송완료',
    '이전회수완료',
    '이전설치완료',
    '대리점회수완료',
    '대리점설치완료',
    '택배배송완료',
    '택배회수완료',
    '수리설치완료',
    '수리회수완료',
    'AS완료',
    '납품완료',
    '납품회수완료',
  ],
  finishFlag: [
    'finishDeliv',
    'finishVisit',
    'finishColle',
    'finishExchaout',
    'finishExcha',
    'finishMoveout',
    'finishMove',
    'finishDpout',
    'finishDp',
    'finishParcel',
    'finishParcelout',
    'finishRepair',
    'finishRepairout',
    'finishAs',
    'finishSupply',
    'finishSupplyout',
  ],
  assigningFlagColor: 'purple',
  assigningFlagKr: ['처리중'],
  assigningFlag: ['assigning'],
  pendFlagColor: 'gray',
  pendFlagKr: ['보류', '이사일정지연', '고객요청', '귀책확인필요', '입금확인필요', '비용확인필요', '상품생산지연', '기타'],
  pendFlag: ['pending', 'pendSchedule', 'pendCustomer', 'pendReason', 'pendDeposit', 'pendPayment', 'pendProduct', 'pendEtc'],
  cancelFlagColor: 'red',
  cancelFlagKr: ['주문오등록취소', '재고없음취소', '단순취소', '간선출고전취소', '간선출고후취소', '기사출고전취소', '기사출고후취소', '고객집도착후취소', '개봉후취소'],
  cancelFlag: ['cancelWrong', 'cancelInventory', 'cancelSimple', 'cancelLineBefore', 'cancelLineAfter', 'cancelBefore', 'cancelAfter', 'cancelVisit', 'cancelOpen'],
};

export const FLAG_OPT = ['confirmFlag', 'assignFlag', 'unassignFlag', 'finishFlag', 'assigningFlag', 'pendFlag', 'cancelFlag'];
export const PARCEL_FLAG_OPT = ['collectFlag', 'transitFlag', 'centerFlag', 'deliveryFlag', 'finishFlag', 'transferFlag'];
export const FLAG_QUNTITY_OPT = ['totalQuantity', 'confirmQuantity', 'assignQuantity', 'unassignQuantity', 'finishQuantity', 'assigningQuantity', 'pendQuantity', 'cancelQuantity'];

export const uploadColumn = {
  MODEL: [
    'partnerSeq',
    'externalOrderNumber',
    'externalInvoiceNumber',
    'transOrderNumber',
    'parcelInvoiceNumber',
    'orderType',
    'feeType',
    'customerName',
    'customerPhone',
    'customerPhone2',
    'customerAddr',
    'customerZipcode',
    'receiverName',
    'receiverPhone',
    'receiverPhone2',
    'receiverAddr',
    'receiverZipcode',
    'orderDatetime',
    'promiseDeliveryDate',
    'shipmentNote',
    'barcode',
    'model',
    'productModelType',
    'quantity',
    'recommenderNumber',
    'recommenderName',
    'cancelChargeFlag',
    'cashOnDelivery',
    'salesChannelSeq',
    'parcelType',
    'centerCode',
    'parcelPayType',
    'seller',
    'liteFlag',
    'parcelExtraFee',
    'stage',
  ],
  PRODUCT: [
    'partnerSeq',
    'externalOrderNumber',
    'externalInvoiceNumber',
    'transOrderNumber',
    'parcelInvoiceNumber',
    'orderType',
    'feeType',
    'customerName',
    'customerPhone',
    'customerPhone2',
    'customerAddr',
    'customerZipcode',
    'receiverName',
    'receiverPhone',
    'receiverPhone2',
    'receiverAddr',
    'receiverZipcode',
    'orderDatetime',
    'promiseDeliveryDate',
    'shipmentNote',
    'productSeq',
    'externalProductNumber', //
    'productQuantity',
    'recommenderNumber',
    'recommenderName',
    'cancelChargeFlag',
    'cashOnDelivery',
    'salesChannelSeq',
    'parcelType',
    'centerCode',
    'parcelPayType',
    'seller',
    'liteFlag',
    'parcelExtraFee',
    'stage',
  ],
  PARCEL: [
    //
    'partnerSeq',
    'externalInvoiceNumber',
    'parcelInvoiceNumber',
    'parcelType',
  ],
};

export const labelObjforParcel = {
  판매사: 'partnerSeq',
  외부송장번호: 'externalInvoiceNumber',
  운송장번호: 'parcelInvoiceNumber',
  택배사: 'parcelType',
};

export const SEARCH_MODEL = [
  { label: '제품', value: 'model' },
  { label: '제품명', value: 'modelName' },
  { label: '바코드', value: 'barcode' },
  { label: 'SKU', value: 'modelStockSeq' },
  { label: '외부SKU', value: 'skuNumber' },
  { label: '대표상품코드', value: 'productCode' },
];

export const LITE_FLAG = [
  {
    label: '라이트',
    value: 'true',
  },
  {
    label: '일반',
    value: 'false',
  },
];

export const FIX_DATE = [
  { value: 'true', label: '지정' },
  { value: 'false', label: '미지정' },
];

export const DURATION = [
  { value: 30, label: '30분' },
  { value: 60, label: '60분' },
  { value: 90, label: '90분' },
  { value: 120, label: '120분' },
];

export const PARCEL_OBJ = {
  판매사: 'partnerSeq',
  외부송장번호: 'externalInvoiceNumber',
  운송장번호: 'parcelInvoiceNumber',
  택배사: 'parcelType',
};

export const COLS_TO_OBJ = (COLS_MASTER) => {
  const obj = {};
  COLS_MASTER?.forEach((ele) => {
    obj[ele.value] = ele;
  });
  return obj;
};

export const PARCEL_DEFAULT_COLUMNS = [
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'showParcelDetail',
    parameterName: '운송장상세',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 1,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'showOrderDetail',
    parameterName: '주문상세',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 2,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'externalOrderNumber',
    parameterName: '외부주문번호(B/L)',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 3,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'externalInvoiceNumber',
    parameterName: '외부송장번호',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 4,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'partnerSeqKr',
    parameterName: '판매사',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 5,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'seller',
    parameterName: '판매처',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 6,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'centerCodeKr',
    parameterName: '소속창고',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 7,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'orderSeq',
    parameterName: '주문번호',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 8,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'invoiceSeq',
    parameterName: '송장번호',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 9,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'parcelInvoiceNumber',
    parameterName: '운송장번호',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 10,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'feeTypeKr',
    parameterName: '송장유형',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 11,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'invoiceStatusKr',
    parameterName: '송장상태',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 12,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'receiverInfo',
    parameterName: '받는분고객정보',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 13,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'senderInfo',
    parameterName: '보내는분고객정보',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 14,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'shipmentNote',
    parameterName: '고객요청사항',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 15,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'registerDatetime',
    parameterName: '주문등록일',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 16,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'stage',
    parameterName: '차수',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 17,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'orderDate',
    parameterName: '고객주문일',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 18,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'finishDate',
    parameterName: '배송완료일',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 19,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'productName',
    parameterName: '상품명',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 20,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'quantity',
    parameterName: '상품구성수량',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 21,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'userIdKr',
    parameterName: '주기사',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 22,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'parcelPayTypeKr',
    parameterName: '운임구분',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 23,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'price',
    parameterName: '운임비',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 24,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'parcelExtraFee',
    parameterName: '도선료',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 25,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'weight',
    parameterName: '무게(g)',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 26,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'width',
    parameterName: '가로(cm)',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 27,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'depth',
    parameterName: '세로(cm)',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 28,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'height',
    parameterName: '높이(cm)',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 29,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'volume',
    parameterName: '체적(cm3)',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 30,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'packingStateKr',
    parameterName: '포장상태',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 31,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'salesChannelName',
    parameterName: '판매채널',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 32,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'purchaseSeq',
    parameterName: '발주번호',
    parentParameter: null,
    description: null,
    level: 1,
    sort: 33,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'receiverName',
    parameterName: '이름',
    parentParameter: 'receiverInfo',
    description: null,
    level: 2,
    sort: 1,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'receiverCompanyName',
    parameterName: '회사명',
    parentParameter: 'receiverInfo',
    description: null,
    level: 2,
    sort: 2,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'receiverPlaceName',
    parameterName: '지점명',
    parentParameter: 'receiverInfo',
    description: null,
    level: 2,
    sort: 3,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'receiverZipcode',
    parameterName: '우편번호',
    parentParameter: 'receiverInfo',
    description: null,
    level: 2,
    sort: 4,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'receiverAddr1',
    parameterName: '주소',
    parentParameter: 'receiverInfo',
    description: null,
    level: 2,
    sort: 5,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'receiverAddr2',
    parameterName: '상세주소',
    parentParameter: 'receiverInfo',
    description: null,
    level: 2,
    sort: 6,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'receiverPhone',
    parameterName: '연락처',
    parentParameter: 'receiverInfo',
    description: null,
    level: 2,
    sort: 7,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'receiverPhone2',
    parameterName: '연락처2',
    parentParameter: 'receiverInfo',
    description: null,
    level: 2,
    sort: 8,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'senderName',
    parameterName: '이름',
    parentParameter: 'senderInfo',
    description: null,
    level: 2,
    sort: 1,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'senderCompanyName',
    parameterName: '회사명',
    parentParameter: 'senderInfo',
    description: null,
    level: 2,
    sort: 2,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'senderPlaceName',
    parameterName: '지점명',
    parentParameter: 'senderInfo',
    description: null,
    level: 2,
    sort: 3,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'senderZipcode',
    parameterName: '우편번호',
    parentParameter: 'senderInfo',
    description: null,
    level: 2,
    sort: 4,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'senderAddr1',
    parameterName: '주소',
    parentParameter: 'senderInfo',
    description: null,
    level: 2,
    sort: 5,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'senderAddr2',
    parameterName: '상세주소',
    parentParameter: 'senderInfo',
    description: null,
    level: 2,
    sort: 6,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'senderPhone',
    parameterName: '연락처',
    parentParameter: 'senderInfo',
    description: null,
    level: 2,
    sort: 7,
    useFlag: true,
  },
  {
    gridId: 'PARCELINVOICE_TAB1',
    parameter: 'senderPhone2',
    parameterName: '연락처2',
    parentParameter: 'senderInfo',
    description: null,
    level: 2,
    sort: 8,
    useFlag: true,
  },
];

export const DEFAULT_COLUMNS = [
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'showOrderDetail',
    parameterName: '주문상세',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '주문상세',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 1,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'showParcelDetail',
    parameterName: '운송장상세',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '운송장상세',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 2,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'showTracking',
    parameterName: '배송/반품현황',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '배송/반품현황',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 3,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'liteFlagKr',
    parameterName: '등록유형',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '등록유형',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 4,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'orderDate',
    parameterName: '고객주문일',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '고객주문일',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 5,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'registerDatetime',
    parameterName: '등록일시',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '등록일시',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 6,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'overTimeRegitKr',
    parameterName: '등록일 경과일',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '등록일 경과일',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 7,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'promiseDate',
    parameterName: '희망일',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '희망일',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 8,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'predictDeliveryDate',
    parameterName: '출고가능일',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '출고가능일',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 9,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'deliveryDate',
    parameterName: '지정일<br />(예정일)',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '지정일<br />(예정일)',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 10,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'deliveryConfirmDate',
    parameterName: '배송확정일',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '배송확정일',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 11,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'finishOrCancelDatetime',
    parameterName: '완료/<br />취소일시',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '완료/<br />취소일시',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 12,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'orderSeq',
    parameterName: '주문번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '주문번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 13,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'externalOrderNumber',
    parameterName: '외부주문번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '외부주문번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 14,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'invoiceSeq',
    parameterName: '송장번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '송장번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 15,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'externalInvoiceNumber',
    parameterName: '외부송장번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '외부송장번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 16,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'transOrderNumber',
    parameterName: '묶음주문번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '묶음주문번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 17,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'orderTypeKr',
    parameterName: '주문유형',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '주문유형',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 18,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'feeTypeKr',
    parameterName: '송장유형',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '송장유형',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 19,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'invoiceStatusKr',
    parameterName: '송장상태',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '송장상태',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 20,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'sendStage',
    parameterName: '차수',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '차수',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 21,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'inventoryLocationKr',
    parameterName: '재고위치',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '재고위치',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 22,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'productSeq',
    parameterName: '상품번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '상품번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 23,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'externalProductNumber',
    parameterName: '외부상품번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '외부상품번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 24,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'productName',
    parameterName: '상품명',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '상품명',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 25,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'memberCountKr',
    parameterName: '배송팀유형',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '배송팀유형',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 26,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'productQuantity',
    parameterName: '상품주문수량',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '상품주문수량',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 27,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'quantity',
    parameterName: '상품구성수량',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '상품구성수량',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 28,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'shipmentNote',
    parameterName: '고객요청사항',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '고객요청사항',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 29,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'centerCodeKr',
    parameterName: '소속창고',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '소속창고',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 30,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'userIdKr',
    parameterName: '주기사',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '주기사',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 31,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'driverMemo',
    parameterName: '기사요청사항',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '기사요청사항',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 32,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'receiverInfo',
    parameterName: '실수령자 고객정보',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '실수령자 고객정보',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 33,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'customerInfo',
    parameterName: '주문자 고객정보',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '주문자 고객정보',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 34,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'recommenderNumber',
    parameterName: '추천인고유번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '추천인고유번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 35,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'recommenderName',
    parameterName: '추천인',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '추천인',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 36,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'primePrice',
    parameterName: '원가',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '원가',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 37,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'parcelExtraFee',
    parameterName: '도선료',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '도선료',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 38,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'packingStateKr',
    parameterName: '포장상태',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '포장상태',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 39,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'sellingPrice',
    parameterName: '판매가',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '판매가',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 40,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'parcelPayTypeKr',
    parameterName: '운임구분',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '운임구분',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 41,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'cashOnDelivery',
    parameterName: '운임비',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '운임비',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 42,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'cancelChargeFlagKr',
    parameterName: '취소비용',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '취소비용',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 43,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'parcelType',
    parameterName: '택배사',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '택배사',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 44,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'parcelInvoiceNumber',
    parameterName: '운송장번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '운송장번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 45,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'serialNumber',
    parameterName: '시리얼번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '시리얼번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 46,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'happyCallCount',
    parameterName: '해피콜',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '해피콜',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 47,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'smsCount',
    parameterName: '메시지발송횟수',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '메시지발송횟수',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 48,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'assistUserIdKr',
    parameterName: '부기사',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '부기사',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 49,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'userIdPhoneKr',
    parameterName: '연락처(주기사)',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '연락처(주기사)',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 50,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'infraSeqKr',
    parameterName: '인프라',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '인프라',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 51,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'partnerSeqKr',
    parameterName: '판매사',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '판매사',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 52,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'seller',
    parameterName: '판매처',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '판매처',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 53,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'salesChannelName',
    parameterName: '판매채널',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '판매채널',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 54,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'deliveryDatetime',
    parameterName: '약속시간',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '약속시간',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 55,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'purchaseSeq',
    parameterName: '발주번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '발주번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 56,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'printDatetime',
    parameterName: '출력일시',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '출력일시',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 57,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'totalModelWeight',
    parameterName: '총 무게',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '총 무게',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 58,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'totalBoxVolume',
    parameterName: '총 CBM',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '총 CBM',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 59,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'registerId',
    parameterName: '주문등록자',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '주문등록자',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 60,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'centerCallNumKr',
    parameterName: '창고연락처',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '창고연락처',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 61,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'workNote',
    parameterName: '작업특이사항',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '작업특이사항',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 62,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'rescheduleInfo',
    parameterName: '지정일 변경사유',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '지정일 변경사유',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 63,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'zipcodeGroupInfo',
    parameterName: '배송권역',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '배송권역',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 64,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'customerName',
    parameterName: '고객명',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '고객명',
    },
    parameterNameCustom: null,
    parentParameter: 'customerInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 1,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'customerZipcode',
    parameterName: '우편번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '우편번호',
    },
    parameterNameCustom: null,
    parentParameter: 'customerInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 2,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'customerAddr',
    parameterName: '주소',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '주소',
    },
    parameterNameCustom: null,
    parentParameter: 'customerInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 3,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'customerPhone',
    parameterName: '연락처1',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '연락처1',
    },
    parameterNameCustom: null,
    parentParameter: 'customerInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 4,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'customerPhone2',
    parameterName: '연락처2',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '연락처2',
    },
    parameterNameCustom: null,
    parentParameter: 'customerInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 5,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'receiverName',
    parameterName: '고객명',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '고객명',
    },
    parameterNameCustom: null,
    parentParameter: 'receiverInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 1,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'receiverZipcode',
    parameterName: '우편번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '우편번호',
    },
    parameterNameCustom: null,
    parentParameter: 'receiverInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 2,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'receiverAddr',
    parameterName: '주소',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '주소',
    },
    parameterNameCustom: null,
    parentParameter: 'receiverInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 3,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'receiverPhone',
    parameterName: '연락처1',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '연락처1',
    },
    parameterNameCustom: null,
    parentParameter: 'receiverInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 4,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'receiverPhone2',
    parameterName: '연락처2',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '연락처2',
    },
    parameterNameCustom: null,
    parentParameter: 'receiverInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 5,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'rescheduleTypeKr',
    parameterName: '변경유형',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '변경유형',
    },
    parameterNameCustom: null,
    parentParameter: 'rescheduleInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 1,
    useFlag: true,
  },
  {
    gridId: 'MANAGEORDERINFOLIST1_MAIN',
    parameter: 'rescheduleNote',
    parameterName: '기타사유',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '기타사유',
    },
    parameterNameCustom: null,
    parentParameter: 'rescheduleInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 2,
    useFlag: true,
  },
];

export const COLS_PRODUCT = [
  { label: '판매사(필)', value: 'partnerSeq', master: 'SELLER_WHOLE' },
  { label: '외부주문번호(필)', value: 'externalOrderNumber' },
  { label: '외부송장번호(필)', value: 'externalInvoiceNumber' },
  { label: '묶음주문번호', value: 'transOrderNumber' },
  { label: '운송장번호', value: 'parcelInvoiceNumber' },
  { label: '주문유형(필)', value: 'orderType', master: 'ORDER_TYPE' },
  { label: '송장유형(필)', value: 'feeType', master: 'FEE_TYPE' },
  { label: '주문인명(필)', value: 'customerName' },
  { label: '주문인전화번호1(필)', value: 'customerPhone' },
  { label: '주문인전화번호2', value: 'customerPhone2' },
  { label: '주문인주소(필)', value: 'customerAddr' },
  { label: '주문인우편번호', value: 'customerZipcode' },
  { label: '수령인(필)', value: 'receiverName' },
  { label: '수령인전화번호1(필)', value: 'receiverPhone' },
  { label: '수령인전화번호2', value: 'receiverPhone2' },
  { label: '수령인주소(필)', value: 'receiverAddr' },
  { label: '수령인우편번호', value: 'receiverZipcode' },
  { label: '주문일(필)', value: 'orderDatetime' },
  { label: '희망일', value: 'promiseDeliveryDate' },
  { label: '고객요청사항', value: 'shipmentNote' },

  { label: '상품번호', value: 'productSeq' },
  { label: '외부상품번호(필)', value: 'externalProductNumber' },
  { label: '상품수량', value: 'productQuantity', isNumber: true },
  { label: '추천인고유번호', value: 'recommenderNumber' },
  { label: '추천인이름', value: 'recommenderName' },
  { label: '취소비용 고객부담 여부', value: 'cancelChargeFlag', master: 'OX_FLAG' },
  { label: '운임비', value: 'cashOnDelivery', isNumber: true },
  { label: '판매채널', value: 'salesChannelSeq', master: 'SALES_CHANNEL' },
  { label: '택배사', value: 'parcelType' },
  { label: '창고', value: 'centerCode', master: 'CENTER_WHOLE' },

  { label: '운임구분', value: 'parcelPayType', master: 'PARCEL_PAY_TYPE' },
  { label: '판매처', value: 'seller' },
  { label: '등록유형', value: 'liteFlag', master: 'LITE_FLAG' },
  { label: '도선료', value: 'parcelExtraFee', isNumber: true },
  { label: '차수', value: 'stage' },
  { label: '판매가', value: 'sellingPrice' },
  { label: '', value: undefined },
];

export const TRANSLATOR_PRODUCT = [
  { label: '판매사(필)', value: 'partnerSeq', master: 'SELLER_WHOLE' },
  { label: '외부주문번호(필)', value: 'externalOrderNumber' },
  { label: '외부송장번호(필)', value: 'externalInvoiceNumber' },
  { label: '묶음주문번호', value: 'transOrderNumber' },
  { label: '운송장번호', value: 'parcelInvoiceNumber' },
  { label: '주문유형(필)', value: 'orderType', master: 'ORDER_TYPE' },
  { label: '송장유형(필)', value: 'feeType', master: 'FEE_TYPE' },
  { label: '주문인명(필)', value: 'customerName' },
  { label: '주문인전화번호1(필)', value: 'customerPhone' },
  { label: '주문인전화번호2', value: 'customerPhone2' },
  { label: '주문인주소(필)', value: 'customerAddr' },
  { label: '주문인우편번호', value: 'customerZipcode' },
  { label: '수령인(필)', value: 'receiverName' },
  { label: '수령인전화번호1(필)', value: 'receiverPhone' },
  { label: '수령인전화번호2', value: 'receiverPhone2' },
  { label: '수령인주소(필)', value: 'receiverAddr' },
  { label: '수령인우편번호', value: 'receiverZipcode' },
  { label: '주문일(필)', value: 'orderDatetime' },
  { label: '희망일', value: 'promiseDeliveryDate' },
  { label: '고객요청사항', value: 'shipmentNote' },

  { label: '상품번호', value: 'productSeq' },
  { label: '외부상품번호(필)', value: 'externalProductNumber' },
  { label: '상품수량', value: 'productQuantity', isNumber: true },
  { label: '추천인고유번호', value: 'recommenderNumber' },
  { label: '추천인이름', value: 'recommenderName' },
  { label: '취소비용 고객부담 여부', value: 'cancelChargeFlag', master: 'OX_FLAG' },
  { label: '운임비', value: 'cashOnDelivery', isNumber: true },
  { label: '판매채널', value: 'salesChannelSeq', master: 'SALES_CHANNEL' },
  { label: '택배사', value: 'parcelType' },
  { label: '창고', value: 'centerCode', master: 'CENTER_WHOLE' },

  { label: '운임구분', value: 'parcelPayType', master: 'PARCEL_PAY_TYPE' },
  { label: '판매처', value: 'seller' },
  { label: '등록유형', value: 'liteFlag', master: 'LITE_FLAG' },
  { label: '도선료', value: 'parcelExtraFee', isNumber: true },
  { label: '차수', value: 'stage' },
  { label: '판매가', value: 'sellingPrice' },
  { label: '', value: undefined },
];
export const TRANSLATOR_MODEL = [
  { label: '판매사(필)', value: 'partnerSeq', master: 'SELLER_WHOLE' },
  { label: '외부주문번호(필)', value: 'externalOrderNumber' },
  { label: '외부송장번호(필)', value: 'externalInvoiceNumber' },
  { label: '묶음주문번호', value: 'transOrderNumber' },
  { label: '운송장번호', value: 'parcelInvoiceNumber' },
  { label: '주문유형(필)', value: 'orderType', master: 'ORDER_TYPE' },
  { label: '송장유형(필)', value: 'feeType', master: 'FEE_TYPE' },
  { label: '주문인명(필)', value: 'customerName' },
  { label: '주문인전화번호1(필)', value: 'customerPhone' },
  { label: '주문인전화번호2', value: 'customerPhone2' },
  { label: '주문인주소(필)', value: 'customerAddr' },
  { label: '주문인우편번호', value: 'customerZipcode' },
  { label: '수령인(필)', value: 'receiverName' },
  { label: '수령인전화번호1(필)', value: 'receiverPhone' },
  { label: '수령인전화번호2', value: 'receiverPhone2' },
  { label: '수령인주소(필)', value: 'receiverAddr' },
  { label: '수령인우편번호', value: 'receiverZipcode' },
  { label: '주문일(필)', value: 'orderDatetime' },
  { label: '희망일', value: 'promiseDeliveryDate' },
  { label: '고객요청사항', value: 'shipmentNote' },
  { label: '바코드(필)', value: 'barcode' },
  { label: '제품', value: 'model' },
  { label: '제품구분', value: 'productModelType', master: 'PMODEL_TYPE' }, //
  { label: '제품수량(필)', value: 'quantity', isNumber: true },

  { label: '상품명', value: 'productName' },
  { label: '추천인고유번호', value: 'recommenderNumber' },
  { label: '추천인이름', value: 'recommenderName' },
  { label: '취소비용 고객부담 여부', value: 'cancelChargeFlag', master: 'OX_FLAG' },
  { label: '운임비', value: 'cashOnDelivery', isNumber: true },

  { label: '판매채널', value: 'salesChannelName' },
  { label: '판매가', value: 'sellingPrice', isNumber: true },
  { label: '택배사', value: 'parcelType' },
  { label: '창고', value: 'centerCode', master: 'CENTER_WHOLE' },
  { label: '운임구분', value: 'parcelPayType', master: 'PARCEL_PAY_TYPE' },
  { label: '판매처', value: 'seller' },
  { label: '등록유형', value: 'liteFlag', master: 'LITE_FLAG' },

  { label: '도선료', value: 'parcelExtraFee', isNumber: true },
  { label: '차수', value: 'stage', isNumber: true },
];

export const COLS_MODEL_FOROBJ = [
  { label: '판매사(필)', value: 'partnerSeq', master: 'SELLER_WHOLE' },
  { label: '외부주문번호(필)', value: 'externalOrderNumber' },
  { label: '외부송장번호(필)', value: 'externalInvoiceNumber' },
  { label: '묶음주문번호', value: 'transOrderNumber' },
  { label: '운송장번호', value: 'parcelInvoiceNumber' },
  { label: '주문유형(필)', value: 'orderType', master: 'ORDER_TYPE' },
  { label: '송장유형(필)', value: 'feeType', master: 'FEE_TYPE' },
  { label: '주문인명(필)', value: 'customerName' },
  { label: '주문인전화번호1(필)', value: 'customerPhone' },
  { label: '주문인전화번호2', value: 'customerPhone2' },
  { label: '주문인주소(필)', value: 'customerAddr' },
  { label: '주문인우편번호', value: 'customerZipcode' },
  { label: '수령인(필)', value: 'receiverName' },
  { label: '수령인전화번호1(필)', value: 'receiverPhone' },
  { label: '수령인전화번호2', value: 'receiverPhone2' },
  { label: '수령인주소(필)', value: 'receiverAddr' },
  { label: '수령인우편번호', value: 'receiverZipcode' },
  { label: '주문일(필)', value: 'orderDatetime' },
  { label: '희망일', value: 'promiseDeliveryDate' },
  { label: '고객요청사항', value: 'shipmentNote' },
  { label: '바코드(필)', value: 'barcode' },
  { label: '제품', value: 'model' },
  { label: '제품구분', value: 'productModelType', master: 'PMODEL_TYPE' }, //
  { label: '제품수량(필)', value: 'quantity', isNumber: true },

  { label: '상품명', value: 'productName' },
  { label: '추천인고유번호', value: 'recommenderNumber' },
  { label: '추천인이름', value: 'recommenderName' },
  { label: '취소비용 고객부담 여부', value: 'cancelChargeFlag', master: 'OX_FLAG' },
  { label: '운임비', value: 'cashOnDelivery', isNumber: true },

  { label: '판매채널', value: 'salesChannelName' },
  { label: '판매가', value: 'sellingPrice', isNumber: true },
  { label: '택배사', value: 'parcelType' },
  { label: '창고', value: 'centerCode', master: 'CENTER' },
  { label: '운임구분', value: 'parcelPayType', master: 'PARCEL_PAY_TYPE' },
  { label: '판매처', value: 'seller' },
  { label: '등록유형', value: 'liteFlag', master: 'LITE_FLAG' },

  { label: '도선료', value: 'parcelExtraFee', isNumber: true },
  { label: '차수', value: 'stage', isNumber: true },
];
export const _COLS_MODEL = [
  { label: '판매사(필)', value: 'partnerSeq', master: 'SELLER_WHOLE' },
  { label: '외부주문번호(필)', value: 'externalOrderNumber' },
  { label: '외부송장번호(필)', value: 'externalInvoiceNumber' },
  { label: '묶음주문번호', value: 'transOrderNumber' },
  { label: '운송장번호', value: 'parcelInvoiceNumber' },
  { label: '주문유형(필)', value: 'orderType', master: 'ORDER_TYPE' },
  { label: '송장유형(필)', value: 'feeType', master: 'FEE_TYPE' },
  { label: '주문인명(필)', value: 'customerName' },
  { label: '주문인전화번호1(필)', value: 'customerPhone' },
  { label: '주문인전화번호2', value: 'customerPhone2' },
  { label: '주문인주소(필)', value: 'customerAddr' },
  { label: '주문인우편번호', value: 'customerZipcode' },
  { label: '수령인(필)', value: 'receiverName' },
  { label: '수령인전화번호1(필)', value: 'receiverPhone' },
  { label: '수령인전화번호2', value: 'receiverPhone2' },
  { label: '수령인주소(필)', value: 'receiverAddr' },
  { label: '수령인우편번호', value: 'receiverZipcode' },
  { label: '주문일(필)', value: 'orderDatetime' },
  { label: '희망일', value: 'promiseDeliveryDate' },
  { label: '고객요청사항', value: 'shipmentNote' },
  { label: '바코드(필)', value: 'barcode' },
  { label: '제품', value: 'model' },
  { label: '제품구분', value: 'productModelType', master: 'PMODEL_TYPE' }, //
  { label: '제품수량(필)', value: 'quantity', isNumber: true },

  { label: '상품명', value: 'productName' },
  { label: '추천인고유번호', value: 'recommenderNumber' },
  { label: '추천인이름', value: 'recommenderName' },
  { label: '취소비용 고객부담 여부', value: 'cancelChargeFlag', master: 'OX_FLAG' },
  { label: '운임비', value: 'cashOnDelivery', isNumber: true },
  { label: '판매채널', value: 'salesChannelSeq', master: 'SALES_CHANNEL' },

  { label: '택배사', value: 'parcelType' },
  { label: '창고', value: 'centerCode', master: 'CENTER' },
  { label: '운임구분', value: 'parcelPayType', master: 'PARCEL_PAY_TYPE' },
  { label: '판매처', value: 'seller' },
  { label: '등록유형', value: 'liteFlag', master: 'LITE_FLAG' },

  { label: '도선료', value: 'parcelExtraFee', isNumber: true },
  { label: '차수', value: 'stage', isNumber: true },
];
export const COLS_MODEL = [
  { label: '판매사(필)', value: 'partnerSeq', master: 'SELLER_WHOLE' },
  { label: '외부주문번호(필)', value: 'externalOrderNumber' },
  { label: '외부송장번호(필)', value: 'externalInvoiceNumber' },
  { label: '묶음주문번호', value: 'transOrderNumber' },
  { label: '운송장번호', value: 'parcelInvoiceNumber' },
  { label: '주문유형(필)', value: 'orderType', master: 'ORDER_TYPE' },
  { label: '송장유형(필)', value: 'feeType', master: 'FEE_TYPE' },
  { label: '주문인명(필)', value: 'customerName' },
  { label: '주문인전화번호1(필)', value: 'customerPhone' },
  { label: '주문인전화번호2', value: 'customerPhone2' },
  { label: '주문인주소(필)', value: 'customerAddr' },
  { label: '주문인우편번호', value: 'customerZipcode' },
  { label: '수령인(필)', value: 'receiverName' },
  { label: '수령인전화번호1(필)', value: 'receiverPhone' },
  { label: '수령인전화번호2', value: 'receiverPhone2' },
  { label: '수령인주소(필)', value: 'receiverAddr' },
  { label: '수령인우편번호', value: 'receiverZipcode' },
  { label: '주문일(필)', value: 'orderDatetime' },
  { label: '희망일', value: 'promiseDeliveryDate' },
  { label: '고객요청사항', value: 'shipmentNote' },
  { label: '창고', value: 'centerCode', master: 'CENTER_WHOLE' },
  { label: '바코드(필)', value: 'barcode' },
  { label: '제품', value: 'model' },
  { label: '제품구분', value: 'productModelType', master: 'PMODEL_TYPE' }, //
  { label: '제품수량(필)', value: 'quantity', isNumber: true },

  { label: '상품명', value: 'productName' },
  { label: '추천인고유번호', value: 'recommenderNumber' },
  { label: '추천인이름', value: 'recommenderName' },
  { label: '취소비용 고객부담 여부', value: 'cancelChargeFlag', master: 'OX_FLAG' },
  { label: '택배사', value: 'parcelType' },
  { label: '운임구분', value: 'parcelPayType', master: 'PARCEL_PAY_TYPE' },
  { label: '운임비', value: 'cashOnDelivery', isNumber: true },
  { label: '판매채널', value: 'salesChannelSeq', master: 'SALES_CHANNEL' },
  { label: '판매처', value: 'seller' },
  { label: '등록유형', value: 'liteFlag', master: 'LITE_FLAG' },

  { label: '도선료', value: 'parcelExtraFee', isNumber: true },
  { label: '차수', value: 'stage', isNumber: true },
];

export const WEEK_DAY = [
  { value: 'mondayFlag', label: '월' },
  { value: 'tuesdayFlag', label: '화' },
  { value: 'wednesdayFlag', label: '수' },
  { value: 'thursdayFlag', label: '목' },
  { value: 'fridayFlag', label: '금' },
  { value: 'saturdayFlag', label: '토' },
  { value: 'sundayFlag', label: '일' },
];

export const MASTER_FLAG = [
  { value: 'masterFlag', label: '마스터' },
  { value: 'giftFlag', label: '제품' },
];

export const OX_FLAG = [
  { value: 'true', label: 'O' },
  { value: 'false', label: 'X' },
];

export const excelColumns: IGrid.Column[] = [
  {
    headerText: '등록유형',
    dataField: 'liteFlagKr',
  },
  {
    headerText: '고객주문일',
    dataField: 'orderDate',
  },
  {
    headerText: '등록일',
    dataField: 'registerDatetime',
  },
  {
    headerText: '희망일',
    dataField: 'promiseDate',
  },
  {
    headerText: '출고가능일',
    dataField: 'predictDeliveryDate',
  },
  {
    headerText: '지정일<br />(확정일)',
    dataField: 'deliveryDate',
  },
  {
    headerText: '완료/<br />취소일시',
    dataField: 'finishOrCancelDatetime',
  },
  {
    headerText: '주문번호',
    dataField: 'orderSeq',
  },
  {
    headerText: '외부주문번호',
    dataField: 'externalOrderNumber',
  },
  {
    headerText: '송장번호',
    dataField: 'invoiceSeq',
  },
  {
    headerText: '외부송장번호',
    dataField: 'externalInvoiceNumber',
  },
  {
    headerText: '묶음주문번호',
    dataField: 'transOrderNumber',
  },
  {
    headerText: '주문유형',
    dataField: 'orderTypeKr',
  },
  {
    headerText: '송장유형',
    dataField: 'feeTypeKr',
  },
  {
    headerText: '송장상태',
    dataField: 'invoiceStatusKr',
  },
  {
    headerText: '재고위치',
    dataField: 'inventoryLocationKr',
  },
  {
    headerText: '상품번호',
    dataField: 'productSeq',
  },
  {
    headerText: '상품명',
    dataField: 'productName',
  },
  {
    headerText: '상품구성수량',
    dataField: 'quantity',
  },
  {
    headerText: '고객요청사항',
    dataField: 'shipmentNote',
    width: 150,
  },
  {
    headerText: '소속창고',
    dataField: 'centerCodeKr',
  },
  {
    headerText: '권역정보',
    dataField: 'zipcodeGroupInfo',
  },
  {
    headerText: '주기사',
    dataField: 'userIdKr',
  },
  {
    headerText: '기사코드',
    dataField: 'userId',
  },
  {
    headerText: '위탁사',
    dataField: 'networkPartnerSeqKr',
  },
  {
    headerText: '기사요청사항',
    dataField: 'driverMemo',
  },
  {
    headerText: '실수령자 고객정보',
    children: [
      {
        headerText: '고객명',
        dataField: 'receiverName',
      },
      {
        headerText: '우편번호',
        dataField: 'receiverZipcode',
      },
      {
        headerText: '주소',
        dataField: 'receiverAddr',
        width: 150,
      },
      {
        headerText: '연락처1',
        dataField: 'receiverPhone',
      },
      {
        headerText: '연락처2',
        dataField: 'receiverPhone2',
      },
    ],
  },
  {
    headerText: '주문자 고객정보',
    children: [
      {
        headerText: '고객명',
        dataField: 'customerName',
      },
      {
        headerText: '우편번호',
        dataField: 'customerZipcode',
      },
      {
        headerText: '주소',
        dataField: 'customerAddr',
        width: 150,
      },
      {
        headerText: '연락처1',
        dataField: 'customerPhone',
      },
      {
        headerText: '연락처2',
        dataField: 'customerPhone2',
      },
    ],
  },
  {
    headerText: '추천인고유번호',
    dataField: 'recommenderNumber',
  },
  {
    headerText: '추천인',
    dataField: 'recommenderName',
  },
  {
    headerText: '원가',
    dataField: 'primePrice',
    dataType: 'numeric',
    formatString: '#,##0',
  },
  {
    headerText: '판매가',
    dataField: 'sellingPrice',
    dataType: 'numeric',
    formatString: '#,##0',
  },
  {
    headerText: '운임구분',
    dataField: 'parcelPayTypeKr', //
  },
  {
    headerText: '운임비',
    dataField: 'cashOnDelivery',
    dataType: 'numeric',
    formatString: '#,##0',
  },
  {
    headerText: '취소비용',
    dataField: 'cancelChargeFlagKr',
  },
  {
    headerText: '택배사',
    dataField: 'parcelType',
  },
  {
    headerText: '운송장번호',
    dataField: 'parcelInvoiceNumber',
  },
  {
    headerText: '시리얼번호',
    dataField: 'serialNumber',
  },
  {
    headerText: '해피콜',
    dataField: 'happyCallCount',
  },
  {
    headerText: '메시지발송횟수',
    dataField: 'smsCount',
  },
  {
    headerText: '부기사',
    dataField: 'assistUserIdKr',
  },
  {
    headerText: '연락처(주기사)',
    dataField: 'userIdPhoneKr',
  },
  {
    headerText: '인프라',
    dataField: 'infraSeqKr',
  },
  {
    headerText: '판매사',
    dataField: 'partnerSeqKr',
  },
  {
    headerText: '판매채널',
    dataField: 'salesChannelName',
  },
  {
    headerText: '약속시간',
    dataField: 'deliveryDatetime',
  },
];

export const excelDetailColumns: IGrid.Column[] = [
  {
    headerText: '등록유형',
    dataField: 'liteFlagKr',
  },
  {
    headerText: '고객주문일',
    dataField: 'orderDate',
  },
  {
    headerText: '등록일',
    dataField: 'registerDatetime',
  },
  {
    headerText: '희망일',
    dataField: 'promiseDate',
  },
  {
    headerText: '출고가능일',
    dataField: 'predictDeliveryDate',
  },
  {
    headerText: '지정일<br />(확정일)',
    dataField: 'deliveryDate',
  },
  {
    headerText: '완료/<br />취소일',
    dataField: 'finishOrCancelDatetime',
  },
  {
    headerText: '주문번호',
    dataField: 'orderSeq',
  },
  {
    headerText: '외부주문번호',
    dataField: 'externalOrderNumber',
  },
  {
    headerText: '송장번호',
    dataField: 'invoiceSeq',
  },
  {
    headerText: '외부송장번호',
    dataField: 'externalInvoiceNumber',
  },
  {
    headerText: '발주번호',
    dataField: 'purchaseSeq',
  },
  {
    headerText: '주문유형',
    dataField: 'orderTypeKr',
  },
  {
    headerText: '송장유형',
    dataField: 'feeTypeKr',
  },
  {
    headerText: '송장상태',
    dataField: 'invoiceStatusKr',
  },
  {
    headerText: '재고위치',
    dataField: 'inventoryLocationKr',
  },
  {
    headerText: '상품번호',
    dataField: 'productSeq',
  },
  {
    headerText: '상품명',
    dataField: 'productName',
  },
  {
    headerText: '총 CBM',
    dataField: 'totalBoxVolume',
  },
  {
    headerText: '총 무게',
    dataField: 'totalModelWeight',
  },
  {
    headerText: '제품',
    dataField: 'model',
  },
  {
    headerText: '제품명',
    dataField: 'modelName',
  },
  {
    headerText: 'SKU',
    dataField: 'modelStockSeq',
  },
  {
    headerText: '외부SKU',
    dataField: 'skuNumber',
  },
  {
    headerText: '바코드',
    dataField: 'barcode',
  },
  {
    headerText: 'CBM',
    dataField: 'boxVolume',
  },
  {
    headerText: '무게',
    dataField: 'modelWeight',
  },
  {
    headerText: '상품주문수량',
    dataField: 'productQuantity',
  },
  {
    headerText: '상품구성수량',
    dataField: 'quantity',
  },
  {
    headerText: '고객요청사항',
    dataField: 'shipmentNote',
  },
  {
    headerText: '소속창고',
    dataField: 'centerCodeKr',
  },
  {
    headerText: '권역정보',
    dataField: 'zipcodeGroupInfo',
  },
  {
    headerText: '주기사',
    dataField: 'userIdKr',
  },
  {
    headerText: '기사코드',
    dataField: 'userId',
  },
  {
    headerText: '위탁사',
    dataField: 'networkPartnerSeqKr',
  },
  {
    headerText: '기사요청사항',
    dataField: 'workNote',
  },
  {
    headerText: '창고연락처',
    dataField: 'centerCallNumKr',
  },
  {
    headerText: '주문등록자',
    dataField: 'registerId',
  },
  {
    headerText: '작업특이사항',
    dataField: 'driverMemo',
  },
  {
    headerText: '지정일 변경사유',
    dataField: 'rescheduleInfo',
    children: [
      {
        headerText: '변경유형',
        dataField: 'rescheduleTypeKr',
      },
      {
        headerText: '기타사유',
        dataField: 'rescheduleNote',
      },
    ],
  },
  {
    headerText: '실수령자 고객정보',
    children: [
      {
        headerText: '고객명',
        dataField: 'receiverName',
      },
      {
        headerText: '우편번호',
        dataField: 'receiverZipcode',
      },
      {
        headerText: '주소',
        dataField: 'receiverAddr',
        width: 150,
      },
      {
        headerText: '연락처1',
        dataField: 'receiverPhone',
      },
      {
        headerText: '연락처2',
        dataField: 'receiverPhone2',
      },
    ],
  },
  {
    headerText: '주문자 고객정보',
    children: [
      {
        headerText: '고객명',
        dataField: 'customerName',
      },
      {
        headerText: '우편번호',
        dataField: 'customerZipcode',
      },
      {
        headerText: '주소',
        dataField: 'customerAddr',
        width: 150,
      },
      {
        headerText: '연락처1',
        dataField: 'customerPhone',
      },
      {
        headerText: '연락처2',
        dataField: 'customerPhone2',
      },
    ],
  },
  {
    headerText: '추천인고유번호',
    dataField: 'recommenderNumber',
  },
  {
    headerText: '추천인',
    dataField: 'recommenderName',
  },
  {
    headerText: '원가',
    dataField: 'primePrice',
    dataType: 'numeric',
    formatString: '#,##0',
  },
  {
    headerText: '판매가',
    dataField: 'sellingPrice',
    dataType: 'numeric',
    formatString: '#,##0',
  },
  {
    headerText: '운임구분',
    dataField: 'parcelPayTypeKr', //
  },
  {
    headerText: '운임비',
    dataField: 'cashOnDelivery',
    dataType: 'numeric',
    formatString: '#,##0',
  },
  {
    headerText: '취소비용',
    dataField: 'cancelChargeFlagKr',
  },
  {
    headerText: '택배사',
    dataField: 'parcelType',
  },
  {
    headerText: '운송장번호',
    dataField: 'parcelInvoiceNumber',
  },
  {
    headerText: '시리얼번호',
    dataField: 'serialNumber',
  },
  {
    headerText: '해피콜',
    dataField: 'happyCallCount',
  },
  {
    headerText: '메시지발송횟수',
    dataField: 'smsCount',
  },
  {
    headerText: '부기사',
    dataField: 'assistUserIdKr',
  },
  {
    headerText: '연락처(주기사)',
    dataField: 'userIdPhoneKr',
  },
  {
    headerText: '인프라',
    dataField: 'infraSeqKr',
  },
  {
    headerText: '판매사',
    dataField: 'partnerSeqKr',
  },
  {
    headerText: '판매채널',
    dataField: 'salesChannelName',
  },
  {
    headerText: '약속시간',
    dataField: 'deliveryDatetime',
  },
];
