import { useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { deliveryService } from '../../../_services/service';
import { ViewList } from './component/view';
import { AuthPopup } from './component/authPopup';
import { ReadOnlyPopup } from '../presenter';

import useSearchGridPaging from 'hooks/grid/useGridPaging';

import { AnnouncementListDTO, AnnouncementListSearchDTO } from 'interface/user';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { PagingListDTO } from 'interface/util';

const Index = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();

  const [authPopupObj, setAuthPopupObj] = useState({
    visible: false,
    data: null,
    tempList: null,
    saveFn: (data) => saveBoard(data),
    saveTmpFn: (data) => saveTempBoard(data),
    getDetailFn: (data) => fetchDetailList(data),
    getTmpFn: () => getTemplist(),
    fetchListFn: (data) => wrappedFetchList(data),
    deleteFn: (data) => deleteInMain(data),
  });

  const [viewObj, setViewObj] = useState({
    visible: false,
    data: null,
  });
  const [popupObj, setPopupObj] = useState({
    visible: false,
    announcementSeq: null,
    fetchListFn: (data) => wrappedFetchList(data),
  });

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      width: 100,
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: (e: IGrid.ButtonClickEvent) => {
          setPopupObj((prev) => {
            return {
              ...prev,
              visible: true,
              announcementSeq: e.item.announcementSeq,
            };
          });
        },
      },
    },
    {
      headerText: '대상',
      dataField: 'typeKr',
      width: 100,
      styleFunction: function (rowIndex, columnIndex, value, headerText, item, dataField) {
        if (value === '업데이트') {
          return 'badge-green';
        } else if (value === '중요') {
          return 'badge-red';
        } else if (value === '일반') {
          return 'badge-gray';
        }
        return null;
      },
    },
    {
      headerText: '제목',
      dataField: 'title',
    },
    {
      headerText: '등록일',
      dataField: 'registerDatetime',
      width: 150,
    },
    {
      headerText: '작성자',
      dataField: 'registerId',
      width: 100,
    },
    {
      headerText: '조회수',
      dataField: 'view',
      width: 100,
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: (e) => {
          //
          //fetchViews(e),
        },
      },
    },
  ];

  const labellingKr = (data: PagingListDTO<AnnouncementListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        typeKr: selectlabel(row.type, masterOptions?.ANNOUNCE_TYPE),
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: AnnouncementListSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await deliveryService.getAnnouncementPaging(searchObj, 'SYSTEM'));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const saveBoard = async ({ data, searchObj }) => {
    const rs = await deliveryService.saveBoard(data, 'SYSTEM');
    if (rs?.status === 200) {
      alert('저장되었습니다!');
      wrappedFetchList(searchObj);
    }
  };

  const saveTempBoard = async ({ boardObj }) => {
    const data = {
      ...boardObj,
      erpFlag: boardObj?.typesArr.includes('erp') ? true : false,
      installAppFlag: boardObj?.typesArr.includes('logiInstall') ? true : false,
      warehouseAppFlag: boardObj?.typesArr.includes('logiChango') ? true : false,
      tempFlag: true,
    };
    delete data.typesArr;

    const rs = await deliveryService.saveBoard(data, 'SYSTEM');
    return {
      rs,
      data,
    };
  };

  const getTemplist = async () => {
    return await deliveryService.getAnnouncementTemplist('SYSTEM');
  };

  const letsWrite = async () => {
    const tempList = await getTemplist();
    setAuthPopupObj((prev) => {
      return {
        ...prev,
        visible: true,
        data: null,
        tempList,
      };
    });
  };

  const fetchDetailList = async (item) => {
    const [data, tempList] = await Promise.all([deliveryService.getAnnouncementDetail(item.announcementSeq), deliveryService.getAnnouncementTemplist('SYSTEM')]);
    setAuthPopupObj((prev) => {
      return {
        ...prev,
        visible: true,
        data,
        tempList,
      };
    });
  };

  const deleteInMain = async (items) => {
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      const data = {
        announcementSeqs: items?.map((ele) => ele.announcementSeq),
      };
      const rs = await deliveryService.deleteBoard(data);
      if (rs?.status === 200) wrappedFetchList(searchObj);
    }
  };

  return (
    <div className="page-content">
      {viewObj?.visible && <ViewList viewObj={viewObj} setViewObj={setViewObj} />}
      {authPopupObj?.visible && <AuthPopup authPopupObj={authPopupObj} setAuthPopupObj={setAuthPopupObj} searchObj={searchObj} />}
      {popupObj?.visible && <ReadOnlyPopup popupObj={popupObj} setPopupObj={setPopupObj} searchObj={searchObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area only-right">
          <div
            className="blue"
            onClick={() => {
              letsWrite();
            }}
          >
            글쓰기
          </div>
          <div
            className="orange"
            onClick={() => {
              const items = gridRef.current.getCheckedRowItemsAll();
              if (items?.length > 0) {
                if (items?.length > 1) {
                  alert('한 건만 선택해주세요');
                } else {
                  fetchDetailList(items[0]);
                }
              } else {
                alert('선택된 항목이 존재하지 않습니다.');
              }
            }}
          >
            수정
          </div>
          <div
            className="red"
            onClick={() => {
              const items = gridRef.current.getCheckedRowItemsAll();
              if (items?.length > 0) {
                deleteInMain(items);
              } else {
                alert('선택된 항목이 존재하지 않습니다.');
              }
            }}
          >
            삭제
          </div>
        </div>
        <GridBox gridRef={gridRef} columns={columns} gridProps={{ showRowAllCheckBox: true, showRowCheckColumn: true }} />
      </div>
    </div>
  );
};

export default Index;
