import { useState, useEffect } from 'react';
import { DetailTopTab, IDetailTopTab } from 'components/template/detailTopTab';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './orderRegit';
// options
import { nullingMASTER } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { ILOCALE } from 'redux/services/localeSlice';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { ORDER_MASTER } from 'pages/ORDER/1order/orderListTab';

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { locale } = useSelector((state: RootState) => state.locale);
  const [tabObj, setTabObj] = useState<IDetailTopTab>();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters(locale.value);
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = (locale: ILOCALE) => {
    initMasterOptions(ORDER_MASTER);
  };

  useEffect(() => {
    if (MASTER_OBJ) {
      setTabObj({
        titles: ['주문조회', '주문 불러오기2'],
        routers: ['/manageorderInfoList', '/manageOrderRegit'],
        container: [<Tab1 />],
      });
    }
  }, [MASTER_OBJ]);

  return <>{tabObj && <DetailTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
