import { useEffect, useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { addMASTER, setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { GridDTO, GridSearchDTO } from 'interface/user';
import { DetailModal, IDetailObj } from './component/detailPopup';
import { PagingListDTO } from 'interface/util';
import { serviceStore } from 'services/services';

const Index = () => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();

  const [detailObj, setDetailObj] = useState<IDetailObj>();

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      dataField: 'showDetail',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: (e) => {
          setDetailObj({
            searchObj: JSON.parse(e.item?.searchObj),
            visible: true,
            item: e.item,
            fetchFn: wrappedFetchList,
          });
        },
      },
    },
    {
      headerText: '화면URL',
      dataField: 'url',
    },
    {
      headerText: '화면이름',
      dataField: 'screenName',
    },
    {
      headerText: '화면ID',
      dataField: 'screenId',
    },
    {
      headerText: '탭이름',
      dataField: 'tabName',
    },
    {
      headerText: '탭ID',
      dataField: 'tabId',
    },
    {
      headerText: '그리드이름',
      dataField: 'gridName',
    },
    {
      headerText: '그리드ID',
      dataField: 'gridId',
    },
    {
      headerText: '그리드유형',
      dataField: 'gridType',
    },
    {
      headerText: '고정칼럼',
      dataField: 'fixedColumn',
    },
    {
      headerText: '페이지크기',
      dataField: 'pageSize',
    },
    {
      headerText: '설명',
      dataField: 'description',
    },
    {
      headerText: '등록아이디',
      dataField: 'registerId',
    },
    {
      headerText: '등록일시',
      dataField: 'registerDatetime',
    },
    {
      headerText: '등록아이디',
      dataField: 'registerId',
      width: 100,
    },
    {
      headerText: '등록일',
      dataField: 'registerDatetime',
    },
    {
      headerText: '사용 여부',
      dataField: 'useFlagKr',
      width: 70,
    },
  ];

  const labellingKr = (data: PagingListDTO<GridDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        useFlagKr: row.useFlag === true ? '사용' : '미사용',
        url: MASTER_OBJ?.SCREENID_URL?.[row.screenId],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  useEffect(() => {
    addMasterOptions();
  }, []);

  const addMasterOptions = async () => {
    const addOps = {};
    if (!MASTER_OBJ?.SCREENID_URL) {
      addOps['SCREENID_URL'] = MASTER_OPS?.SCREEN_WHOLE?.map((ele) => {
        return {
          value: ele.screenId,
          label: ele.url,
        };
      });
    } else if (!MASTER_OBJ?.TAB_NAME) {
      addOps['TAB_NAME'] = MASTER_OPS?.TAB_WHOLE?.map((ele) => {
        return {
          value: ele.tabId,
          label: ele.tabName,
        };
      });
    }
    if (Object?.keys(addOps)?.length > 0) dispatch(addMASTER(addOps));
  };

  const fetchPaging = async (searchObj: GridSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.userAction(`grid`, 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const CRUDFunction = async ({ data, crud, searchObj }) => {
    dispatch(setLoading('POST'));
    let rs;
    if (crud === 'D') {
      rs = await serviceStore.userAction('grid', 'DELETE', null, { data });
    } else if (crud === 'C') {
      rs = await serviceStore.userAction('grid/parameter', 'POST', null, data);
      //
    } else if (crud === 'U') {
      rs = await serviceStore.userAction('grid/parameter', 'PUT', null, data);
    }

    if (rs?.status === 200) {
      alert('처리 되었습니다!');
      setDetailObj(null);
      wrappedFetchList(searchObj);
    }

    dispatch(setLoading(null));
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const handleCheckItems = (e) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      const id = e.target.id;
      if (id === 'delete-function') CRUDFunction({ data: items?.map((ele) => ele.gridId), crud: 'D', searchObj: JSON.parse(items[0]?.searchObj) });
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };
  return (
    <div className="page-content">
      {detailObj?.visible && <DetailModal detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area ">
          <div
            className="blue"
            onClick={() => {
              setDetailObj({
                searchObj,
                visible: true,
                item: null,
                fetchFn: wrappedFetchList,
              });
            }}
          >
            생성
          </div>
          <div className="red" id="delete-function" onClick={handleCheckItems}>
            삭제
          </div>
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
      </div>
    </div>
  );
};

export default Index;
