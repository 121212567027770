// redux
import { useSelector } from 'react-redux';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

// styled-component
import styled from 'styled-components';
import { getMonDotDay, getMonNDayKr, returnYoil } from 'common/util/dateParsingFn';

// img
import set from './img/set.png';
import dot from './img/dot.png';
import line from './img/line.png';

import centerActive from './img/center_active.png';
import centerInactive from './img/center_inactive.png';
import finishActive from './img/finish_active.png';
import finishInactive from './img/finish_inactive.png';
import deliveryActive from './img/delivery_active.png';
import deliveryInactive from './img/delivery_inactive.png';
import collectActive from './img/collect_active.png';
import collectInactive from './img/collect_inactive.png';
import { RootState } from 'redux/store';

export const Invoices = ({ invoices, setSelected }) => {
  return (
    <div style={{ width: '80%', margin: '10px auto' }}>
      <p style={{ fontSize: 18, fontWeight: 700 }}>
        {invoices[0]?.orderSeq} ( 외부주문번호 : {invoices[0]?.externalOrderNumber} )
      </p>
      <table className="border-table" style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th>송장번호</th>
            <th>주문유형</th>
            <th>송장유형</th>
            <th>송장상태</th>
            <th style={{ width: 80 }}>상세보기</th>
          </tr>
        </thead>
        <tbody>
          {invoices?.map((ele, idx) => {
            return (
              <tr key={`invoice_${idx}`}>
                <td>{ele?.invoiceSeq}</td>
                <td>{ele?.orderTypeKr}</td>
                <td>{ele?.feeTypeKr}</td>
                <td>{ele?.invoiceStatusKr}</td>
                <td>
                  <button
                    className="btn btn-etc sssm"
                    onClick={() => {
                      setSelected(ele);
                    }}
                  >
                    선택
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

// SelectedInvoice component
const InvoiceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
  align-items: center;
  box-shadow: -2px 5px 15px 2px #95959553;
  background-color: #f4f6f9;
  margin: 30px 0;
`;

const InvoiceParts = styled.div`
  background-color: white;
  width: 100%;
  padding: 30px;
  border-bottom: 5px solid #f4f6f9;
`;

const MainTitle = styled.div`
  color: #ff910f;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  border-bottom: 2px solid #dee1e4;
  padding-bottom: 15px;
  padding-top: 30px;
`;

const ModelTitle = styled.div`
  font-size: 15px;
  padding-top: 15px;
  font-weight: 500;
`;
const StatusTitle = styled.div`
  font-size: 13px;
  color: #a3a7b9;
`;

const ScheduleImgArea = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ScheduleImgLabel = styled.div`
  width: 300px;
  height: 37px;
  display: flex;
  font-size: 12px;
  font-weight: 300;
  justify-content: space-between;
  align-items: flex-end;
  color: #a3a7b9;
`;

const ScheduleList = styled.div`
  padding: 15px;
  color: #a3a7b9;
`;

const SpanWrapper = styled.div`
  display: flex;
  margin: 3px 0;
`;

const BoldSpan = styled.span`
  font-weight: 600;
  font-size: 13px;
  width: 100px;
  display: inline-block;
`;

const NotBoldSpan = styled.span`
  font-size: 12px;
  width: 210px;
`;

const Schedule = styled.div`
  position: relative;
  padding: 8px 0;
`;

const ScheduleImg = styled.img`
  height: 32px;
  position: absolute;
  top: 0;
`;

export const SelectedInvoice = ({ selected }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);

  return (
    <InvoiceWrapper>
      <InvoiceParts>
        <div style={{ marginBottom: 20, fontWeight: 500, fontSize: 15 }}>
          <div>운송장번호 : {selected?.parcelInvoiceNumber} </div>
          <div>HBL : {selected?.houseNumber || '-'}</div>
        </div>
        <SpanWrapper>
          <BoldSpan>수령인</BoldSpan>
          <NotBoldSpan>{selected?.receiverName}</NotBoldSpan>
        </SpanWrapper>
        <SpanWrapper>
          <BoldSpan>주소</BoldSpan>
          <NotBoldSpan>
            {selected?.receiverAddr1} {selected?.receiverAddr2 && selected?.receiverAddr2}
          </NotBoldSpan>
        </SpanWrapper>
        <SpanWrapper>
          <BoldSpan>기사정보</BoldSpan>
          <NotBoldSpan>{selected?.driverName ? `${selected?.driverName}(${selected?.driverPhone})` : ''}</NotBoldSpan>
        </SpanWrapper>
        <SpanWrapper>
          <BoldSpan>고객요청사항</BoldSpan>
          <NotBoldSpan>{selected?.shipmentNote || '-'}</NotBoldSpan>
        </SpanWrapper>
      </InvoiceParts>
      <InvoiceParts>
        <ScheduleImgArea>
          <img src={set} alt="" style={{ height: 32 }} />
          {selected?.status === 'COLLECT' && <ScheduleImg src={collectActive} alt="" style={{ left: -7, top: 4 }} />}
          {selected?.status === 'TRANSIT' && <ScheduleImg src={deliveryActive} alt="" style={{ left: 64, top: 4 }} />}
          {selected?.status === 'CENTER' && <ScheduleImg src={centerActive} alt="" style={{ left: 134, top: 4 }} />}
          {selected?.status === 'DELIVERY' && <ScheduleImg src={deliveryActive} alt="" style={{ right: 64, top: 4 }} />}
          {selected?.status === 'FINISH' && <ScheduleImg src={finishActive} alt="" style={{ right: -7, top: 4 }} />}
        </ScheduleImgArea>

        <ScheduleImgLabel>
          <span style={{ color: selected?.status === 'COLLECT' ? '#FF910F' : '#a3a7b9' }}>인수</span>
          <span style={{ color: selected?.status === 'TRANSIT' ? '#FF910F' : '#a3a7b9' }}>이동중</span>
          <span style={{ color: selected?.status === 'CENTER' ? '#FF910F' : '#a3a7b9' }}>배달지</span>
          <span style={{ color: selected?.status === 'DELIVERY' ? '#FF910F' : '#a3a7b9' }}>배달중</span>
          <span style={{ color: selected?.status === 'FINISH' ? '#FF910F' : '#a3a7b9' }}>완료</span>
        </ScheduleImgLabel>
        <ScheduleList>
          {selected?.tracks?.map((e, idx) => {
            return (
              <Schedule key={`schedule_${idx}`}>
                <img src={dot} alt="" style={{ position: 'absolute', left: -20, top: 17 }} />
                {idx !== 0 && <img src={line} alt="" style={{ position: 'absolute', left: -15, top: -20 }} />}
                <span style={{ color: selected?.status?.includes(e.eventCode) ? '#FF910F' : '#A3A7B9' }}>{e.eventName}</span>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    {getMonNDayKr(e?.eventDatetime)}({returnYoil(e?.eventDatetime)})&nbsp;{e?.eventDatetime?.split(' ')[1]}
                  </div>
                  <div>{e.location}</div>
                </div>
              </Schedule>
            );
          })}
        </ScheduleList>
      </InvoiceParts>
    </InvoiceWrapper>
  );
};
