import { InvoiceListDTOKr } from '..';

import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import { VisibleModal } from 'components/modal/visibleModal';
import { useEffect, useRef, useState } from 'react';
import { returnFeeTypes } from 'common/util/orderTypes';
import { REGION_NUMBERS } from 'common/data/regionNumber';
import { returnDDay } from 'common/util/counting';
import phonePng from '../data/phone.png';
import noInvenPng from '../img/noInven.png';

import { OrderDetail } from 'pages/ORDER/1order/orderListTab/common/component/invocieDetail/orderDetail';
import GridBox from 'common/grid/gridBox';

export interface IMultiCells {
  e?: IGrid.CellClickEvent;
  mode?: string;
  visible?: boolean;
  items?: InvoiceListDTOKr[];
}

export const MultiCells = ({ multiCellsObj, setMultiCellsObj, dragSchedule, dragParentRef }) => {
  const [orderDetailObj, setOrderDetailObj] = useState<{ item?: any; visible?: boolean }>({
    visible: false,
  });
  const poptableRef = useRef<AUIGrid>();
  useEffect(() => {
    if (multiCellsObj?.items) {
      if (typeof multiCellsObj?.items === 'string') {
        poptableRef?.current?.setGridData(JSON.parse(multiCellsObj?.items));
      } else {
        poptableRef?.current?.setGridData(multiCellsObj?.items);
      }

      poptableRef?.current?.bind('dropEndBefore', function (e) {
        dragSchedule(e);
        return false;
      });

      poptableRef?.current?.bind('cellClick', function (e) {
        if (e.item)
          setOrderDetailObj({
            visible: true,
            item: e.item,
          });
      });
    }
  }, [multiCellsObj]);

  const columns: IGrid.Column[] = [
    {
      dataField: 'fixed0',
      headerText: '설치기사',
      width: 170,
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item, dataField) {
        let div = `<div class="has-schedule ${returnFeeTypes(item?.feeType)} ${item?.confirmFlag ? 'confirm' : ''}">`;
        if (REGION_NUMBERS?.includes(item?.receiverPhone?.substr(0, 3))) {
          div += `<span class="phone"><img src=${phonePng} alt="수령자의 등록된 연락처가 지역번호입니다" /></span>`;
        }
        if (item?.invoiceStatus === 'NOT_IN_INVENTORY') div += `<span class="noInvnent"><img src=${noInvenPng} alt="" /></span>`;

        div += `<span class="labeling masterProduct" title="${item?.productName}">${item?.productName}</span>`;
        if (item?.predictDeliveryDate) div += `<span class="labeling predictDay">${item?.predictDeliveryDate}</span>`;
        if (item?.revisitFlag) div += `<span class="labeling revisit" title="재방문">재방문</span>`;
        if (item.promiseDeliveryDate) div += `<span class="labeling dday">${returnDDay(item.deliveryDate, item.promiseDeliveryDate)}</span>`;

        div += '<div>';
        div += item?.receiverName;
        div += '</div>';
        div += '<div>';
        div += item?.city;
        div += '</div>';
        div += '<div>';
        div += item?.modelGroupKr || '가구';
        div += '</div>';
        div += '</div>';
        return div;
      },
    },
  ];
  return (
    <VisibleModal
      dragParentRef={dragParentRef}
      title={`[${multiCellsObj?.e.headerText}] ${
        multiCellsObj?.mode === 'noDate' ? '날짜 미지정' : multiCellsObj?.mode === 'noTime' ? '시간 미지정' : multiCellsObj?.e?.item?.[multiCellsObj?.e?.dataField] || multiCellsObj?.e?.item?.fixed0
      }`}
      visible={multiCellsObj?.visible}
      setVisible={() => {
        setMultiCellsObj();
      }}
      children={
        <>
          {orderDetailObj?.visible && <OrderDetail orderDetailObj={orderDetailObj} setOrderDetailObj={setOrderDetailObj} />}
          <div className="bottom0Grid">
            <GridBox
              gridRef={poptableRef}
              columns={columns}
              gridProps={{
                wordWrap: true,
                headerHeight: 0,
                pagingPanelHeight: 0,
                height: multiCellsObj?.items?.length > 5 ? 800 : 500,
                usePaging: false,
                showRowAllCheckBox: false,
                showRowCheckColumn: false,
                showRowNumColumn: false,
                //
                enableDragByCellDrag: true,
                enableDrag: true,
                enableDrop: true,
                dropToOthers: true,
              }}
            />
          </div>
        </>
      }
    />
  );
};
