import { COLORS, TRANSITIONS, VALUES } from 'common/constants/appearance';
import styled from 'styled-components';
import { LDSLabel3XS, LDSLabelMD, LDSLabelSM } from '../atoms/Typography';
import { BsCaretRightFill } from 'react-icons/bs';
import { FiChevronRight, FiCheck } from 'react-icons/fi';
import LDSBadge from '../atoms/Badge';
import LDSCheckbox from '../atoms/Checkbox';
import LDSProfile from '../atoms/Profile';
import { format } from 'date-fns';
import { useLayoutEffect, useRef, useState } from 'react';
import { calculateElementPositionAndSize } from 'common/util/handleElement';
import { PortalPositionDataProps } from './Tooltip';
import LDSDropdown, { BaseStyle, DropdownProps } from '../layouts/Dropdown';
import { useDropdown } from '../hooks/useDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setShowDropdown } from 'redux/services/portalSlice';
import { UnionFieldTypeProps, UnionFormTypeProps } from '../types/types';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { HiChevronDown, HiChevronRight, HiChevronUp } from 'react-icons/hi';

type ListItemProps = {
  $type: 'MENU' | 'MENU_ACTION' | 'SELECT' | 'TOGGLE' | 'ACTION' | 'NOTIFICATION' | 'EXPANDABLE_MENU';
  $label: string;
  $status?: 'DEFAULT' | 'ACTIVE' | 'SELECTED' | 'UNREAD' | 'OPEN' | 'CLOSED';
  $badgeCount?: string;
  $hasDepth?: boolean;
  $description?: string;
  $icon?: React.ReactNode;
  $timeStamp?: Date | string;
  children?: React.ReactNode;
  $dataValue?: string;
  onClick?: () => void;
  $dropdown?: Omit<DropdownProps, '$data'>;
  $fieldNameData?: UnionFieldTypeProps;
  register?: UseFormRegister<UnionFormTypeProps>;
  watch?: UseFormWatch<UnionFormTypeProps>;
  autoFocus?: boolean;
  $navigate?: string;
  $isSubDepth?: boolean;
};

export default function LDSListItem({
  $label,
  $icon,
  $status,
  $type,
  $hasDepth,
  $badgeCount,
  $description,
  $timeStamp,
  children,
  onClick,
  $dropdown,
  $dataValue,
  $fieldNameData,
  register,
  watch,
  autoFocus,
  $navigate,
  $isSubDepth,
}: ListItemProps) {
  const listItemRef = useRef(null);
  const [showSubDepth, setShowSubDepth] = useState(false);

  const { dropdownRef, setDropdownSize } = useDropdown();

  const dispatch = useDispatch();
  const { showDropdown } = useSelector((state: RootState) => state.portal);

  useLayoutEffect(() => {
    setDropdownSize(calculateElementPositionAndSize(dropdownRef.current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <>
      <LDSListItemWrapper
        className={
          $status === 'ACTIVE'
            ? 'active'
            : showSubDepth
            ? 'active'
            : $status === 'OPEN' || $status === 'CLOSED'
            ? ''
            : showDropdown === $label
            ? 'active'
            : $type === 'NOTIFICATION' && $status === 'UNREAD'
            ? 'active'
            : ''
        }
        $status={$status}
        $type={$type}
        ref={listItemRef}
        data-value={$dataValue}
        onClick={() => {
          onClick && $status !== 'ACTIVE' && onClick();
          if (children) setShowSubDepth(!showSubDepth);
          $dropdown && !showDropdown && dispatch(setShowDropdown($label));
        }}
        autoFocus={autoFocus}
        $isSubDepth={$isSubDepth}
      >
        {$type === 'TOGGLE' ? (
          <LDSCheckbox $label={$label} $isSelected={$status === 'SELECTED' ? true : false} register={register} watch={watch} $fieldName={$fieldNameData} />
        ) : $type === 'NOTIFICATION' ? (
          <>
            {$icon && <LDSProfile $type="BADGE" $badgeType={'ICON'} $size="XS" $icon={$icon} $color={$status === 'UNREAD' ? 'PRIMARY' : 'NEUTRAL'} />}
            <div className="list-content">
              <LDSLabelSM $weight="BOLD" className={$status === 'ACTIVE' || $status === 'UNREAD' ? 'active' : ''}>
                {$label}
              </LDSLabelSM>
              <LDSLabelSM $weight="MEDIUM">{$description}</LDSLabelSM>
              {$timeStamp && <LDSLabel3XS $weight="SEMIBOLD">{typeof $timeStamp !== 'string' ? format($timeStamp, 'yyyy-MM-dd HH:mm:SS') : $timeStamp}</LDSLabel3XS>}
            </div>
          </>
        ) : (
          <>
            <a
              href={$navigate}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              link
            </a>
            {($type === 'ACTION' || $type === 'MENU_ACTION') && $icon && $icon}
            {$type === 'EXPANDABLE_MENU' && $status === 'OPEN' && <HiChevronDown />}
            {$type === 'EXPANDABLE_MENU' && $status === 'CLOSED' && <HiChevronRight />}
            {$type === 'MENU' && $status === 'ACTIVE' && <BsCaretRightFill size={10} />}
            <LDSLabelMD $weight={$status === 'ACTIVE' || $status === 'SELECTED' ? 'BOLD' : 'MEDIUM'}>{$label}</LDSLabelMD>
            {$badgeCount && <LDSBadge $type="NUMBER" $value={$badgeCount} $size="SM" $color="PRIMARY" />}
            {$hasDepth && <FiChevronRight className="list-depth-icon" />}
            {$type === 'SELECT' && $status === 'SELECTED' && <FiCheck size={20} strokeWidth={2} />}
          </>
        )}
      </LDSListItemWrapper>
      {showSubDepth && children && (
        <LDSListItemChildWrapper ref={dropdownRef} $data={calculateElementPositionAndSize(listItemRef.current)}>
          {children}
        </LDSListItemChildWrapper>
      )}
      {showDropdown === $label && $dropdown && (
        <LDSDropdown
          $width={$dropdown.$width}
          $maxHeight={$dropdown.$maxHeight}
          $position={$dropdown.$position}
          $align={$dropdown.$align}
          $data={calculateElementPositionAndSize(listItemRef?.current)}
        >
          {$dropdown.children}
        </LDSDropdown>
      )}
    </>
  );
}

type ListItemWrapperProps = Omit<ListItemProps, '$label' | '$icon' | '$timeStamp'>;

const LDSListItemWrapper = styled.li<ListItemWrapperProps>`
  display: flex;
  align-items: ${(props) => (props.$type === 'NOTIFICATION' ? 'flex-start' : 'center')};
  gap: 6px;

  transition: background-color 300ms ${TRANSITIONS.BEZIER};
  cursor: pointer;
  position: relative;

  padding: ${(props) => (props.$type.includes('MENU') ? '9px 12px' : props.$type === 'TOGGLE' ? '0 0' : props.$type === 'NOTIFICATION' ? '10px 14px' : '9px 14px')};
  padding-left: ${(props) => (props.$isSubDepth ? 28 : props.$type === 'TOGGLE' ? 0 : 12)}px;
  border-radius: ${(props) => (props.$type.includes('MENU') ? VALUES.RADIUS : 0)}px;
  color: ${(props) => ((props.$status === 'ACTIVE' || props.$status === 'SELECTED') && props.$type !== 'TOGGLE' ? COLORS.PRIMARY : COLORS.GRAY_07)};
  background-color: ${(props) => (props.$type === 'NOTIFICATION' && props.$status === 'UNREAD' ? COLORS.PRIMARY_008 : 'transparent')};

  // > div {
  //   background-color: ${(props) => (props.$type === 'NOTIFICATION' && props.$status === 'UNREAD' ? COLORS.PRIMARY : COLORS.GRAY_04)};
  // }

  & > label {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .lds-badge {
    min-width: 24px;
    justify-content: center;

    & + svg {
      margin-left: -4px;
    }
  }

  & > svg {
    flex-shrink: 0;
    width: ${(props) => (props.$type === 'MENU' ? 10 : 16)}px;
    height: ${(props) => (props.$type === 'MENU' ? 10 : 16)}px;

    &.list-depth-icon {
      color: ${COLORS.GRAY_05};
    }
    &:last-child:not(.list-depth-icon) {
      margin-right: -4px;
      width: 20px;
      height: 20px;
    }
  }

  .lds-checkbox {
    padding: 11px 16px;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
  }

  & * {
    cursor: inherit !important;
  }

  &:hover,
  &.active {
    background-color: ${(props) => props.$status !== 'UNREAD' && COLORS.BLACK_004};
    color: ${(props) => props.$status === 'DEFAULT' && COLORS.GRAY_08};

    .lds-checkbox {
      background-color: transparent;
    }
  }

  &:active {
    background-color: ${(props) => props.$status !== 'UNREAD' && COLORS.BLACK_008};
    color: ${(props) => props.$status === 'DEFAULT' && COLORS.GRAY_08};
  }

  .list-content {
    display: flex;
    flex-direction: column;
    gap: 2px;
    word-break: keep-all;
    overflow-wrap: break-word;
    width: 100%;

    label:not(:first-child) {
      color: ${COLORS.GRAY_05};
    }
  }
  & a {
    color: inherit;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    user-select: none;
    font-size: 0;
  }
`;

const LDSListItemChildWrapper = styled(BaseStyle)<{ $data: PortalPositionDataProps }>`
  position: fixed;
  width: ${(props) => props.$data?.width}px;
  left: ${(props) => props.$data?.x + 200 - VALUES.BUFFER / 3}px;
`;
