import { KeyboardEvent, useEffect, useState } from 'react';
import { Col, Row, Form } from 'reactstrap';
import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { WarehouseReceiptInSearchDTO, WarehouseReceiptOutSearchDTO, WarehouseReceiptStorageSearchDTO } from '../../../_interface/warehouse';
import { resetAllSelections } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = (props: ISearchBoxProps<WarehouseReceiptInSearchDTO & WarehouseReceiptOutSearchDTO & WarehouseReceiptStorageSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, TARGET, fetchList } = props;
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">판매사</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.SELLER_SELLER_AUTH}
              value={searchObj?.partnerSeqList?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeqList: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">창고</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.CENTER_AUTH}
              value={searchObj?.centerCodeList?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.CENTER_AUTH?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  centerCodeList: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          {TARGET?.includes('_IN_') && (
            <>
              <Col>
                <label className="col-form-label">운송유형</label>
                <SelectD
                  isMulti={true}
                  options={MASTER_OPS?.LOGISTIC_TYPE}
                  value={searchObj?.logisticTypes?.map((ele) => {
                    return {
                      value: ele,
                      label: MASTER_OBJ?.LOGISTIC_TYPE?.[ele],
                    };
                  })}
                  onChange={(option) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        logisticTypes: (option as OptionItem[])?.map((ele) => ele.value),
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">작업유형</label>
                <SelectD
                  isMulti={true}
                  options={MASTER_OPS?.WORK_TYPE}
                  value={searchObj?.workTypes?.map((ele) => {
                    return {
                      value: ele,
                      label: MASTER_OBJ?.WORK_TYPE?.[ele],
                    };
                  })}
                  onChange={(option) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        workTypes: (option as OptionItem[])?.map((ele) => ele.value),
                      };
                    });
                  }}
                />
              </Col>
            </>
          )}
          {TARGET?.includes('_OUT_') && (
            <>
              <Col>
                <label className="col-form-label">작업유형</label>
                <SelectD
                  isMulti={true}
                  options={MASTER_OPS?.WORK_TYPE}
                  value={searchObj?.workTypes?.map((ele) => {
                    return {
                      value: ele,
                      label: MASTER_OBJ?.WORK_TYPE?.[ele],
                    };
                  })}
                  onChange={(option) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        workTypes: (option as OptionItem[])?.map((ele) => ele.value),
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">파렛트유형</label>
                <SelectD
                  isMulti={true}
                  options={MASTER_OPS?.PALLET_TYPE}
                  value={searchObj?.palletTypes?.map((ele) => {
                    return {
                      value: ele,
                      label: MASTER_OBJ?.PALLET_TYPE?.[ele],
                    };
                  })}
                  onChange={(option) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        palletTypes: (option as OptionItem[])?.map((ele) => ele.value),
                      };
                    });
                  }}
                />
              </Col>
            </>
          )}
          {TARGET?.includes('_STORAGE_') && (
            <>
              <Col>
                <label className="col-form-label">로케이션구분</label>
                <SelectD
                  isMulti={true}
                  options={MASTER_OPS?.LOCATION_CATEGORY}
                  value={searchObj?.locationCategory?.map((ele) => {
                    return {
                      value: ele,
                      label: MASTER_OBJ?.LOCATION_CATEGORY?.[ele],
                    };
                  })}
                  onChange={(option) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        locationCategory: (option as OptionItem[])?.map((ele) => ele.value),
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">로케이션규격</label>
                <SelectD
                  isMulti={true}
                  options={MASTER_OPS?.LOCATION_SIZE}
                  value={searchObj?.locationSize?.map((ele) => {
                    return {
                      value: ele,
                      label: MASTER_OBJ?.LOCATION_SIZE?.[ele],
                    };
                  })}
                  onChange={(option) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        locationSize: (option as OptionItem[])?.map((ele) => ele.value),
                      };
                    });
                  }}
                />
              </Col>
            </>
          )}

          <Col>
            <label className="col-form-label">기간검색</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_DATE
                      ? {
                          value: selectTypeObj?.SEARCH_DATE,
                          label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_DATE}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_DATE: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <FlatpickrD
                  disabled={!selectTypeObj?.SEARCH_DATE}
                  value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                      [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                    });
                  }}
                  onClose={(option) => {
                    if (option?.length === 1)
                      setSearchObj({
                        ...searchObj,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                        [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                      });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">제품</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_MODEL
                      ? {
                          value: selectTypeObj?.SEARCH_MODEL,
                          label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_MODEL}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_MODEL: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_MODEL}
                  value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
        </Row>
        <Row>
          {TARGET?.includes('STORAGE') && (
            <Col>
              <label className="col-form-label">적용단위</label>
              <SelectD
                isMulti={true}
                options={MASTER_OPS?.DATE_UNIT}
                // value={searchObj?.dateUnit?.map((ele) => {
                //   return {
                //     value: ele,
                //     label: MASTER_OBJ?.DATE_UNIT?.[ele],
                //   };
                // })}
                // onChange={(option) => {
                //   setSearchObj((prev) => {
                //     return {
                //       ...prev,
                //       dateUnit: (option as OptionItem[])?.map((ele) => ele.value),
                //     };
                //   });
                // }}
              />
            </Col>
          )}
          <Col>
            <label className="col-form-label">영수증 상태값</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.OR_STATUS}
              value={searchObj?.statuses?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.OR_STATUS?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  statuses: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col />
          <Col />
          <Col />

          {TARGET?.includes('STORAGE') ? (
            <>
              <Col style={{ textAlign: 'right' }}>
                <button
                  className="btn btn-search"
                  onClick={(e) => {
                    e.preventDefault();
                    fetchList(searchObj);
                  }}
                >
                  조회
                </button>
              </Col>
            </>
          ) : (
            <>
              <Col />
              <Col style={{ textAlign: 'right' }}>
                <button
                  className="btn btn-search"
                  onClick={(e) => {
                    e.preventDefault();
                    fetchList(searchObj);
                  }}
                >
                  조회
                </button>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </>
  );
};
