import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './deliveryByDriver/container';
import Tab2 from './deliveryByCenter';
import Tab3 from './deliveryByRegionMap/container';
import Tab4 from './deliveryByOrderTab';
import Tab5 from './tms';

// import Container3 from './deliveryByRegion/container'; 폐기예정

// options
import { RootState } from 'redux/store';
import { InvoiceDetailSearchDTO } from 'interface/order';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER, RAWDATA } from 'redux/services/menuSlice';
import { DRIVER_LIST_MASTER } from 'pages/ETC/1driver/driverListTab';
export interface IDetailObjProps {
  visible: boolean;
  item?: InvoiceDetailSearchDTO;
}

export const DELIVERY_SCHEDULE_MASTER = [
  ...DRIVER_LIST_MASTER,
  `${RAWDATA}DRIVER_WHOLE`,
  `${RAWDATA}DRIVER_AUTH`,
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  'DRIVER_PHONE_WHOLE', // 라벨용이 많으니
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'CENTER_CALL_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_NETWORK_AUTH',
  'SELLER_WHOLE',
  'INFRA',
  'SALES_CHANNEL',
  //
  'ORDER_TYPE',
  `${RAWDATA}ORDER_TYPE`,
  'INVOICE_STATUS',
  'INVOICE_STATUS_1',
  'INVOICE_STATUS_2',
  'FEE_TYPE',
  'FEE_TYPE',
  'CANCEL_REASON',
  'COLLECT_REASON',
  'SERVICE_TYPE',
  'MODEL_TYPE',
  'MODEL_GROUP',
  'RESCHEDULE_TYPE',
  'BODY_WIFI_REASON',
  'EOL_GROUP2',
  `${RAWDATA}EOL_GROUP2`,
  'EOL_GROUP3',
  'COUNSEL_DETAIL_TYPE',
  'COUNSEL_TYPE',
  'LADDER_CO',
  'MODEL_GRP_1',
  'MODEL_GRP_2',
  'MODEL_GRP_3',
  'CANCEL_TYPE',
  'MESSAGE_TYPE',
  'INVENTORY_LOCATION',
  'PROVINCE_WHOLE',
  'CITY',
  'DRIVER_PARTY',
  'TEAM_GRADE',
  'DRV_POS',
  'CAR_TYPE',
  'DRV_TYPE',
  'TEAM_TYPE',
  'LOGI_TYPE',
  'PACKING_STATE',
  'INV_TRACK_TYPE',
  'PARCEL_PAY_TYPE',
  'INVOICE_UPDATE_CONTENT',
  'INSTALL_FORM',
  //
  'SEARCH_ORDER',
  'SEARCH_INVOICE',
  'SEARCH_MODEL',
  //
  'INSTALL_DURATION',
  'DELIVERY_TIME',
  'UNIFORM_SIZE',
  'WEEK_DAY',
  'OX_FLAG',
  'USE_FLAG',
  'LITE_FLAG',
  'FIX_DATE',
  //
  'REGION',
  'CONFIRM_FLAG',
  {
    PMODEL_TYPE: [
      { value: 'MODEL', label: '구성품' },
      { value: 'GIFT', label: '사은품' },
    ],
  },
  {
    DELIVERY_FLAG: [
      { value: 'PROMISE', label: '약속일' },
      { value: 'REVISIT', label: '재방문' },
      { value: 'DELAY', label: '지연배송' },
    ],
  },
  {
    SEARCH_DATE: [
      {
        label: '고객주문일',
        value: 'OrderDate',
      },
      {
        label: '배송확정일',
        value: 'DeliveryConfirmDate',
      },
      {
        label: '지정일',
        value: 'DeliveryDate',
      },
    ],
  },
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(DELIVERY_SCHEDULE_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            //
            DELIVERYSCHEDULE_OLD: <Tab1 tabId={'DELIVERYSCHEDULE_OLD'} />,
            DELIVERYSCHEDULE_CENTER: <Tab2 tabId={'DELIVERYSCHEDULE_CENTER'} />,
            DELIVERYSCHEDULE_MAP: <Tab3 tabId={'DELIVERYSCHEDULE_MAP'} />,
            DELIVERYSCHEDULE_ORDER: <Tab4 tabId={'DELIVERYSCHEDULE_ORDER'} />,
            TMS_TAB: <Tab5 tabId={'TMS_TAB'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
