import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { getTodayLocalDateString } from 'common/util/dateParsingFn';
import CommonModalNew from 'components/modal/commonModalNew';
import { JustPallet, PalletAndSign, PrintTitle } from 'components/print/print';
import { SelectD } from 'components/reactstrap/reactstrap';
import { LocationSupplementDTO, WarehouseDTO } from 'interface/warehouse';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { RootState } from 'redux/store';

export interface IPrints {
  visible?: boolean;
  items?: LocationSupplementDTO[];
}

export const Prints = ({ printObj, setPrintObj }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [data, setData] = useState<LocationSupplementDTO[]>();
  const today = getTodayLocalDateString();
  const wrapperRef = useRef();
  const [printFormat, setPrintFormat] = useState<'P' | 'L'>('P');
  const printFormatOptions = [
    {
      value: 'P', // 세로출력
      label: '기본출력',
    },
    {
      value: 'L', // 가로출력
      label: '상세출력',
    },
  ];
  const [thisCenter, setThisCenter] = useState<WarehouseDTO>();
  const initData = (items: LocationSupplementDTO[]) => {
    const center = masterOptions?.CENTER_WHOLE?.filter((ele) => ele.centerCode === items?.[0]?.centerCode)?.[0];
    setThisCenter(center);
    setData(
      items?.map((row) => {
        return {
          ...row,
          receiverNames: row.receiverNames?.length > 0 ? row.receiverNames : !row.receiverNames ? null : new Array(row.quantity)?.fill('-'),
          serialNumbers: row.serialNumbers?.length > 0 ? row.serialNumbers : !row.serialNumbers ? null : new Array(row.quantity)?.fill('-'),
        };
      }),
    );
  };

  useEffect(() => {
    initData(printObj?.items);
  }, []);

  return (
    <>
      <CommonModalNew
        title={`재고보충지시서`}
        style={{ width: 1300 }}
        visible={printObj?.visible}
        setVisible={() => setPrintObj(null)}
        rightTitle={
          <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            <div style={{ width: 100 }}>
              <label className="col-form-label">출력형식</label>
              <SelectD
                hasNull={false}
                value={{ value: printFormat, label: selectlabel(printFormat, printFormatOptions) }}
                options={printFormatOptions}
                onChange={(option) => {
                  setPrintFormat((option as OptionItem)?.value);
                }}
              />
            </div>
            <div style={{ paddingBottom: 3 }}>
              <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
            </div>
          </div>
        }
        children={
          <>
            <div
              ref={wrapperRef}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div style={{ padding: 30, minHeight: '297mm' }} className="break-for-onePage-print">
                <PrintTitle seq={null} title={'재고보충지시서'} />
                <div className="presenterInput">
                  <span>1. 기본정보</span>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <table className="border-table" style={{ width: '100mm' }}>
                      <thead>
                        <tr>
                          <th>출력일시</th>
                          <td colSpan={2}>{today}</td>
                        </tr>
                        <tr>
                          <th>창고</th>
                          <td colSpan={2}>
                            <div className="b">{thisCenter?.centerName}</div>
                            <div>
                              {thisCenter?.address} {thisCenter?.addressDetail}
                            </div>
                          </td>
                        </tr>
                      </thead>
                    </table>
                    <table className="border-table" style={{ width: '100mm' }}>
                      <thead>
                        <tr>
                          <th>작업지시일</th>
                          <th>작업지시자(인)</th>
                          <th>작업자(인)</th>
                          <th>창고장(인)</th>
                        </tr>
                        <tr style={{ height: 50 }}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>

                <div className="presenterInput">
                  <span>2. 제품정보</span>
                  {printFormat === 'L' ? (
                    <table className="border-table">
                      <thead className="text-center">
                        <tr>
                          <th colSpan={10}>제품정보</th>
                          <th colSpan={4}>로케이션</th>
                          <th>수량</th>
                          <th rowSpan={2} colSpan={3}>
                            고객명
                          </th>
                          <th rowSpan={2} colSpan={3}>
                            시리얼번호
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={2}>바코드</th>
                          <th colSpan={4}>제품</th>
                          <th colSpan={4}>제품명</th>
                          <th colSpan={2}>보충</th>
                          <th colSpan={2}>대표</th>
                          <th>양품</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {data?.map((list, idx) => (
                          <tr key={`modelListL_${idx}`}>
                            <th colSpan={2}>{list?.barcode}</th>
                            <th colSpan={4}>{list?.model}</th>
                            <th colSpan={4}>{list?.modelName}</th>
                            <th colSpan={2}>{list?.locationCodeForSupplement}</th>
                            <th colSpan={2}>{list?.locationCodeForRepresentative}</th>
                            <th>{list?.quantity}</th>
                            <th colSpan={3}>
                              {list?.receiverNames?.map((sn) => {
                                return <div>{sn || '-'}</div>;
                              })}
                            </th>
                            <th colSpan={3}>
                              {list?.serialNumbers?.map((sn) => {
                                return <div>{sn || '-'}</div>;
                              })}
                            </th>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot style={{ textAlign: 'center' }}>
                        <tr>
                          <td style={{ textAlign: 'right', paddingRight: 10 }} colSpan={21}>
                            합계 &nbsp;
                            {data
                              ?.map((ele) => Number(ele.quantity))
                              .reduce((acc, cur) => {
                                return acc + cur;
                              })}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  ) : (
                    <table className="border-table">
                      <thead className="text-center">
                        <tr>
                          <th colSpan={10}>제품정보</th>
                          <th colSpan={4}>로케이션</th>
                          <th>수량</th>
                        </tr>
                        <tr>
                          <th colSpan={2}>바코드</th>
                          <th colSpan={4}>제품</th>
                          <th colSpan={4}>제품명</th>
                          <th colSpan={2}>보충</th>
                          <th colSpan={2}>대표</th>
                          <th>양품</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {data?.map((list, idx) => (
                          <tr key={`modelListL_${idx}`}>
                            <th colSpan={2}>{list?.barcode}</th>
                            <th colSpan={4}>{list?.model}</th>
                            <th colSpan={4}>{list?.modelName}</th>
                            <th colSpan={2}>{list?.locationCodeForSupplement}</th>
                            <th colSpan={2}>{list?.locationCodeForRepresentative}</th>
                            <th>{list?.quantity}</th>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot style={{ textAlign: 'center' }}>
                        <tr>
                          <td style={{ textAlign: 'right', paddingRight: 10 }} colSpan={15}>
                            합계 &nbsp;
                            {data
                              ?.map((ele) => Number(ele.quantity))
                              .reduce((acc, cur) => {
                                return acc + cur;
                              })}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  )}
                </div>
                <JustPallet />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
