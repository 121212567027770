import { useEffect, useState } from 'react';
import { Row, Col, Input, Label } from 'reactstrap';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { UserDTOForAdmin } from 'interface/user';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, ITypeEyeBoolean, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { setLoading } from 'redux/services/menuSlice';
import { serviceStore } from 'services/services';
import { updateToken } from 'common/util/authFunc';
import { setLocalStorageTokens, decodeAndSetUserInfo } from 'common/util/localStorage';
import { setAuth } from 'redux/services/authSlice';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { USER_MASTER } from '../..';

export interface UserDTOForAdminEx extends UserDTOForAdmin {
  userCodeKr?: string;
  password2?: string;
  userAuthoritiesKr?: OptionItem[];
  similaritySearchFlag?: boolean;
}

export const UserDetailPopupforUser = ({ userPopObj, setUserPopObj }) => {
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [userInfos, setUserInfos] = useState<UserDTOForAdminEx>();
  const [currentTab, setCurrentTab] = useState(0);
  const [visible, setVisible] = useState<boolean>();
  const hasTabs = reduxUserInfo?.developerFlag ? [{ title: '기본정보' }, { title: 'ERP 설정' }, { title: '시크릿키발급(개발자전용)' }] : [{ title: '기본정보' }, { title: 'ERP설정' }];

  useEffect(() => {
    if (MASTER_OBJ?.SALES_CHANNEL_TYPE) {
      if (userPopObj?.userId) {
        fetchDetail(userPopObj?.userId);
      }
    } else {
      initMasters();
    }
  }, [MASTER_OBJ, userPopObj?.viisble]);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    await initMasterOptions(USER_MASTER);
  };

  const fetchDetail = async (userId) => {
    dispatch(setLoading('GET'));

    const data: UserDTOForAdminEx = (await serviceStore.userAction(`${userId}`, 'GET', null))?.data;
    if (data) {
      setVisible(true);
      setUserInfos(data);
    }
    dispatch(setLoading(null));
  };

  const userValidator = (userInfos) => {
    if (!userInfos) {
      alert('내용을 입력하세요!');
      return false;
    }

    if (!userInfos.userName) {
      alert('이름을 입력하세요!');
      return false;
    }

    if (!userInfos.phoneNumber) {
      alert('연락처를 입력하세요!');
      return false;
    }

    if (userInfos?.password !== userInfos?.password2) {
      alert('비밀번호와 비밀번호 재입력값이 다릅니다!');
      return false;
    }

    return true;
  };

  const makeData = (userInfos: UserDTOForAdminEx) => {
    const data = { ...userInfos };
    return data;
  };

  const createSecretKey = async () => {
    if (window.confirm(`시크릿키를 ${userInfos?.secretKey ? '재' : ''}발급 하시겠습니까?`)) {
      dispatch(setLoading('GET'));
      const rs = await serviceStore?.userAction('secretKey', 'POST', null, null);
      if (rs?.status === 200) {
        alert(`시크릿키가 ${userInfos?.secretKey ? '재' : ''}발급 되었습니다`);
        setUserInfos({
          ...userInfos,
          secretKey: rs?.data?.secretKey,
        });
      }
      dispatch(setLoading(null));
    }
  };

  const handleCopyClipBoard = () => {
    navigator.clipboard.writeText(userInfos?.secretKey);
    alert('클립보드에 복사되었습니다.');
  };

  const updateUser = async (data: UserDTOForAdmin) => {
    const rs = await serviceStore.userAction(`my/info`, 'PUT', null, data);
    if (rs?.status === 200) {
      alert('사용자 정보가 수정되었습니다!');
      const refreshedTokens = await updateToken();
      if (refreshedTokens) {
        setLocalStorageTokens(refreshedTokens);
        const userInfo = decodeAndSetUserInfo(refreshedTokens);
        dispatch(setAuth(userInfo));
      }
      setUserPopObj({
        ...userPopObj,
        userId: null,
      });
    }
  };

  return (
    <CommonModalNew
      title={`개인정보수정`}
      style={{ width: 1000 }}
      visible={visible}
      rightTitle={
        <>
          {currentTab < 2 ? (
            <button
              className={'btn btn-secondary ssm'}
              onClick={() => {
                if (userValidator(userInfos)) {
                  const data = makeData(userInfos);
                  if (data) {
                    updateUser(data);
                  }
                }
              }}
            >
              수정
            </button>
          ) : (
            <button className={'btn btn-secondary ssm'} onClick={createSecretKey}>
              {userInfos?.secretKey ? '재발급' : '발급'}
            </button>
          )}
        </>
      }
      setVisible={() => {
        setVisible(false);
        setUserPopObj({
          ...userPopObj,
          userId: null,
        });
      }}
      children={
        <div style={{ minHeight: 500 }}>
          <div className="div-top-tab">
            {hasTabs.map((tab, index) => {
              return (
                <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                  <li
                    onClick={() => {
                      setCurrentTab(index);
                    }}
                  >
                    {tab?.title}
                  </li>
                </div>
              );
            })}
          </div>
          {/* 기본정보 탭 */}
          <div style={{ display: 'flex', zIndex: currentTab === 0 ? 13 : 10 }}>
            <div style={{ width: 270, display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  backgroundColor: '#eff2f7',
                  border: '1px solid #e0e0e0',
                  marginTop: 30,
                  width: 180,
                  height: 180,
                  borderRadius: 180,
                  position: 'absolute',
                }}
              ></div>
            </div>
            <div style={{ width: 645 }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Label check style={{ width: 70 }}>
                  <InputD
                    type="radio"
                    name="useFlag"
                    checked={userInfos?.useFlag === true}
                    onChange={(e) => {
                      setUserInfos({ ...userInfos, useFlag: true });
                    }}
                  />
                  사용
                </Label>
                <Label check style={{ width: 60 }}>
                  <InputD
                    type="radio"
                    name="useFlag"
                    checked={userInfos?.useFlag === false}
                    onChange={(e) => {
                      setUserInfos({ ...userInfos, useFlag: false });
                    }}
                  />
                  미사용
                </Label>
              </div>
              <Row>
                <Col>
                  <label className="col-form-label">이름*</label>
                  <InputD
                    value={userInfos?.userName || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        userName: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">사용자 유형 / 사용자ID*</label>
                  <div style={{ display: 'flex' }}>
                    <Input style={{ width: 80 }} disabled readOnly value={MASTER_OBJ?.USER_CODE?.[userInfos?.userCode] || ''} />
                    <Input readOnly disabled defaultValue={userInfos?.userId} />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label className="col-form-label">연락처*</label>
                  <InputD
                    value={userInfos?.phoneNumber || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        phoneNumber: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">사용자 유형*</label>
                  <div className="radio-area">
                    {MASTER_OPS?.USER_CODE?.map((ele) => {
                      return (
                        <Label key={ele.value} htmlFor={ele.value} check style={{ width: 80 }}>
                          <Input id={ele.value} disabled checked={userInfos?.userCode === ele.value} type="radio" />
                          {ele.label}
                        </Label>
                      );
                    })}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label className="col-form-label">비밀번호*</label>
                  <InputD
                    type="password"
                    value={userInfos?.password || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        password: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">비밀번호 확인*</label>
                  <InputD
                    type="password"
                    value={userInfos?.password2 || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        password2: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">이메일</label>
                  <InputD
                    value={userInfos?.email || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        email: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col />
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">소속파트너</label>
                  <SelectD
                    options={MASTER_OPS?.SELLER_AUTH}
                    value={
                      userInfos?.partnerSeq
                        ? {
                            value: userInfos?.partnerSeq,
                            label: MASTER_OBJ?.SELLER_AUTH?.[userInfos?.partnerSeq],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setUserInfos({
                        ...userInfos,
                        partnerSeq: (option as OptionItem)?.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">소속인프라</label>
                  <SelectD
                    options={MASTER_OPS?.INFRA}
                    value={
                      userInfos?.infraSeq
                        ? {
                            value: userInfos?.infraSeq,
                            label: MASTER_OBJ?.INFRA?.[userInfos?.infraSeq],
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setUserInfos({
                        ...userInfos,
                        infraSeq: value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">소속센터</label>
                  <SelectD
                    options={MASTER_OPS?.CENTER_AUTH}
                    value={
                      userInfos?.centerCode
                        ? {
                            value: userInfos?.centerCode,
                            label: MASTER_OBJ?.CENTER_AUTH?.[userInfos?.centerCode],
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setUserInfos({
                        ...userInfos,
                        centerCode: value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">소속판매채널</label>
                  <SelectD
                    options={MASTER_OPS?.SALES_CHANNEL_TYPE}
                    value={
                      userInfos?.salesChannelSeq
                        ? {
                            value: userInfos?.salesChannelSeq,
                            label: MASTER_OBJ?.SALES_CHANNEL_TYPE?.[userInfos?.salesChannelSeq],
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setUserInfos({
                        ...userInfos,
                        salesChannelSeq: value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
          {/* 설정 탭 */}
          <div style={{ display: 'flex', zIndex: currentTab === 1 ? 13 : 10, flexDirection: 'column', padding: '2rem 24px' }}>
            <Row>
              <Col>
                <label className="col-form-label">유사검색여부</label>
                <div className="radio-select-area">
                  <span
                    className={userInfos?.similaritySearchFlag === true ? 'active' : ''}
                    onClick={() => {
                      setUserInfos((prev) => {
                        return {
                          ...prev,
                          similaritySearchFlag: true,
                        };
                      });
                    }}
                  >
                    유사검색
                  </span>
                  <span
                    className={userInfos?.similaritySearchFlag === false ? 'active' : ''}
                    onClick={() => {
                      setUserInfos((prev) => {
                        return {
                          ...prev,
                          similaritySearchFlag: false,
                        };
                      });
                    }}
                  >
                    일치검색
                  </span>
                </div>
              </Col>
              <Col />
              <Col />
              <Col />
              <Col />
            </Row>
          </div>
          {userInfos?.developerFlag && (
            <div style={{ display: 'flex', zIndex: currentTab === 2 ? 13 : 10, flexDirection: 'column', padding: '2rem 24px' }}>
              <Row>
                <Col>
                  <label className="col-form-label">시크릿키(웹전용)</label>
                  <div style={{ display: 'flex', alignContent: 'center' }}>
                    <InputD isTypePassword={true} style={{ width: 500 }} value={userInfos?.secretKey || ''} disabled readOnly />
                    <button style={{ marginTop: 2 }} className={'btn btn-secondary ssm'} onClick={handleCopyClipBoard}>
                      복사
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      }
    />
  );
};
