import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';
import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { InventoryMoveSearchDTO } from 'interface/warehouse';
import { resetAllSelections } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = ({ searchObj, setSearchObj, fetchList }: ISearchBoxProps<InventoryMoveSearchDTO>) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_MODEL: null,
    SEARCH_LOC: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_LOC, searchObj));
  }, [selectTypeObj?.SEARCH_LOC]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            options={masterOptions?.CENTER_AUTH}
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품그룹</label>
          <SelectD
            options={masterOptions?.MODEL_GROUP}
            value={
              searchObj?.modelGroup
                ? {
                    value: searchObj?.modelGroup,
                    label: selectlabel(searchObj?.modelGroup, masterOptions?.MODEL_GROUP),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                modelGroup: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            options={masterOptions?.SELLER_AUTH}
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품/제품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: selectlabel(selectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                      }
                    : null
                }
                options={masterOptions?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">로케이션</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_LOC
                    ? {
                        value: selectTypeObj?.SEARCH_LOC,
                        label: selectlabel(selectTypeObj?.SEARCH_LOC, masterOptions?.SEARCH_LOC),
                      }
                    : null
                }
                options={masterOptions?.SEARCH_LOC}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_LOC: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_LOC}
                value={searchObj?.[selectTypeObj?.SEARCH_LOC] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_LOC]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>

        <Col>
          <label className="col-form-label">이동일자</label>
          <FlatpickrD
            value={[searchObj?.fromMoveDate, searchObj?.toMoveDate]}
            onReset={() => {
              setSearchObj({
                ...searchObj,
                fromMoveDate: null,
                toMoveDate: null,
              });
            }}
            onChange={(date) => {
              setSearchObj({
                ...searchObj,
                fromMoveDate: returnDateyyyymmdd(date[0]),
                toMoveDate: returnDateyyyymmdd(date[1]),
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">유형</label>
          <SelectD
            options={masterOptions?.INVENTORY_MOVE_TYPE}
            value={
              searchObj?.moveType
                ? {
                    value: searchObj?.moveType,
                    label: selectlabel(searchObj?.moveType, masterOptions?.INVENTORY_MOVE_TYPE),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                moveType: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
