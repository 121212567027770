import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { PARCEL_GRID_PAGE_ROW_COUNT, IPagingGrid, LoadingGridBox } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
// service
import { RootState } from 'redux/store';
import { COMMON_GRIDID, setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ParcelInvoiceDTO, ParcelInvoiceQuantityDTO, ParcelInvoiceSearchDTO, ParcelInvoiceReleaseDTO, ParcelInvoiceKdexpDTO } from '../../_interface/parcel';
import useGridButton from 'hooks/grid/useGridButton';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
// component
import { PagingListDTO } from 'interface/util';
import { DivideOrderPopup, IDividerObj } from './component/divideOrder';
import { ITransferObj, TransferrderPopup } from './component/transferOrder';

import { IPrints, Prints } from './component/print';
import { IParcelDetail, ParcelDetail } from './component/parcelDetail';
import { IParcelActionObj, ParcelActionPopup } from './component/parcelAction';
import { defaultFixedColumnCountObjType, gridParamToGridColumn } from 'common/grid/columnManager';
import { PARCEL_SERVICE_PARCEL_CUSTOMERINFO_REGIT_FORM, PARCEL_SERVICE_PARCEL_FEE_REGIT_FORM, PARCEL_SERVICE_PARCEL_REGIT_FORM, PARCEL_SERVICE_PARCEL_TRANSFER_REGIT_FORM } from 'envVar2';
import { DetailPopup, IDetailObj } from 'pages/ETC/1driver/driverListTab/driverList/component/detailPopup';
import { PARCEL_SERVICE_LIST_GRID } from 'pages/PARCEL/_asset/asset';
import { serviceStore } from 'services/services';
import ChangeFareModal, { IChangeFareObj } from './component/changeFare';
import { optimizeSearchObj } from 'common/util/searchBox';
import { generateAndExcuteSubCommand } from './component/printKdexpLabel';

export const defaultOrderDetailObj = {
  visible: false,
  item: null,
};

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const reduxSearchObj = useSelector((state: RootState) => state.tab)?.searchObj?.[tabId];
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const GRIDID = useMemo(() => {
    return `${COMMON_GRIDID}${tabId}`;
  }, [tabId]);

  const defaultSearchFilter = useMemo(() => {
    return {
      infraSeq: [],
      partnerSeq: [],
      portCode: [],
      parcelType: [],
      centerCode: [],
      parcelInvoiceNumber: [],
      houseNumber: [],
      subNumber: [],
      externalInvoiceNumber: [],
      senderName: [],
      receiverName: [],
      boxSize: [],
      payType: [],
      similaritySearchFlag: reduxUserInfo?.['similaritySearchFlag'] ?? true, // 유사검색여부
      pageNo: -1,
      pageSize: MASTER_OBJ?.[GRIDID]?.pageSize || PARCEL_GRID_PAGE_ROW_COUNT,
    };
  }, [reduxUserInfo]);

  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelGridRefShippingList = useRef<AUIGrid>();
  const excelGridRefKDEXPList = useRef<AUIGrid>();
  const [divideOrderObj, setDivideOrderObj] = useState<IDividerObj>();
  const [transferOrderObj, setTransferOrderObj] = useState<ITransferObj>();
  const [printObj, setPrintObj] = useState<IPrints>();
  const [detailObj, setDetailObj] = useState<IParcelDetail>();
  const [changeFareObj, setChangeFareObj] = useState<IChangeFareObj>();

  const [driverDetailObj, setDriverDetailObj] = useState<IDetailObj>();
  const [fetchCount, setFetchCount] = useState<ParcelInvoiceQuantityDTO>();

  const labellingKr = (data: PagingListDTO<ParcelInvoiceDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        printCountKr: row.printCount ? 'O' : 'X',
        customsReleaseProgressDayKr: !row.customsReleaseProgressDay ? 'D-Day' : `D+${row.customsReleaseProgressDay}`,
        collectProgressDayKr: !row.collectProgressDay ? 'D-Day' : `D+${row.collectProgressDay}`,
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        portCodeKr: MASTER_OBJ?.PORT_CODE?.[row.portCode],
        statusKr: MASTER_OBJ?.PARCEL_INVOICE_STATUS?.[row.status],
        boxSizeKr: MASTER_OBJ?.PARCEL_BOX_SIZE?.[row.boxSize],
        payTypeKr: MASTER_OBJ?.PARCEL_PAY_TYPE?.[row.payType],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        userIdKr: MASTER_OBJ?.DRIVER_WHOLE?.[row.userId],
        parcelTypeKr: MASTER_OBJ?.PARCEL_TYPE?.[row.parcelType],
        transferParcel: MASTER_OBJ?.EXTERNAL_PARCEL?.[row.transferParcel],
        receiverAddr: `${row.receiverAddr1 || ''}  ${row.receiverAddr2 || ''}`,
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const kdexpPayTypeKr = {
    착택: '착택',
    착불: '착택',
    신용: '현택',
    선불: '현택',
    현불: '현택',
    현택: '현택',
  };

  const parcelPagingApi = {
    PARCEL_SERVICE_LIST: '',
    PARCEL_RESERVED_LIST: '/schedule',
    PARCEL_DELIVERY_COMPLETED_LIST: '/finish',
  };

  const excelFileNaming = {
    PARCEL_SERVICE_LIST: '',
    PARCEL_RESERVED_LIST: ' (예약접수)',
    PARCEL_DELIVERY_COMPLETED_LIST: ' (배송완료)',
  };
  const fetchTotal = async (searchObj: ParcelInvoiceSearchDTO) => {
    if (tabId !== 'PARCEL_SERVICE_LIST') return;
    const total = (await serviceStore?.parcelAction('invoice/quantity', 'POST', null, searchObj))?.data;
    setFetchCount(total as ParcelInvoiceQuantityDTO);
    return total;
  };

  const fetchPaging = async (originSearchObj: ParcelInvoiceSearchDTO) => {
    const searchObj = optimizeSearchObj(originSearchObj);
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore?.parcelAction(`invoice${parcelPagingApi[tabId]}/paging`, 'POST', null, searchObj, true))?.data as PagingListDTO<ParcelInvoiceDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const downloadExcel = async (type: 'main', ref?: React.MutableRefObject<AUIGrid>, title?: string, useFullTitleFlag?: boolean) => {
    const prompt = window.confirm(`엑셀다운로드${type === 'main' ? '' : ' *상세*'}를 시작합니다. \n※ 많은 양의 데이터는 30초 이상의 시간이 소요될 수 있습니다.`);
    if (prompt) {
      dispatch(setLoading('GET'));

      if (!title) {
        const { list } = labellingKr({ list: (await serviceStore?.parcelAction(`invoice${parcelPagingApi[tabId]}/excel`, 'POST', null, searchObj, true))?.data as ParcelInvoiceDTO[] });
        excelGridRef.current.setGridData(list);
        excelGridRef.current.exportAsXlsx({ fileName: `택배조회${excelFileNaming[tabId]}` });
      } else {
        let list = [];
        if (title === '출고리스트') {
          list = labellingKr({ list: (await serviceStore?.parcelAction('invoice/release/excel', 'POST', null, searchObj, false))?.data as ParcelInvoiceReleaseDTO[] }).list;
        } else if (title === '경동택배 업로드 양식') {
          list = labellingKr({ list: (await serviceStore?.parcelAction('invoice/excel/kdexp', 'POST', null, searchObj, false))?.data as ParcelInvoiceKdexpDTO[] }).list;
          list = list.map((row) => {
            return {
              ...row,
              payTypeKr: kdexpPayTypeKr[row.payTypeKr],
            };
          });
        }
        ref?.current?.setGridData(list);
        ref?.current?.exportAsXlsx({ fileName: useFullTitleFlag ? title : `택배조회 ${title}` });
      }

      dispatch(setLoading(null));
    }
  };

  const cancelParcelService = async (items) => {
    if (window.confirm(`등록취소 하시겠습니까?\n선택: ${items?.length}건`)) {
      const rs = await serviceStore?.parcelAction(
        'invoice/create/cancel',
        'POST',
        null,
        items?.map((ele) => {
          return {
            seq: ele.parcelInvoiceSeq,
          };
        }),
      );

      if (rs?.status === 200) {
        alert(`${items?.length}건 취소되었습니다!`);
        wrappedFetchList(searchObj);
      }
    }
  };

  const completeParcelService = async (items) => {
    if (window.confirm(`배송완료 하시겠습니까?\n선택: ${items?.length}건`)) {
      const rs = await serviceStore?.parcelAction(
        'invoice/force/finish',
        'POST',
        null,
        items?.map((ele) => {
          return {
            seq: ele.parcelInvoiceSeq,
          };
        }),
      );

      if (rs?.status === 200) {
        alert(`${items?.length}건 완료되었습니다!`);
        wrappedFetchList(searchObj);
      }
    }
  };

  const returnParcels = async (items) => {
    if (window.confirm(`반품신청 하시겠습니까?\n선택: ${items?.length}건`)) {
      const rs = await serviceStore?.parcelAction(
        'invoice/return',
        'POST',
        null,
        items?.map((ele) => {
          return {
            seq: ele.parcelInvoiceSeq,
          };
        }),
      );

      if (rs?.status === 200) {
        alert(`${items?.length}건 완료되었습니다!`);
        wrappedFetchList(searchObj);
      }
    }
  };

  const kdexTransferShipping = async (items) => {
    if (window.confirm(`발송처리 하시겠습니까?\n선택: ${items?.length}건`)) {
      const rs = await serviceStore?.parcelAction(
        'invoice/transfer/kdexp',
        'POST',
        null,
        items?.map((ele) => {
          return {
            seq: ele.parcelInvoiceSeq,
          };
        }),
      );

      if (rs?.status === 200) {
        alert(`${items?.length}건 완료되었습니다!`);
        wrappedFetchList(searchObj);
      }
    }
  };

  const popupDriverDetail = async (e) => {
    if (e.text) {
      setDriverDetailObj((prev) => {
        return {
          ...prev,
          visible: true,
          item: { userId: e.item?.[e.dataField?.split('Kr')[0]] },
        };
      });
    }
  };

  const printKdexLabel = async (items) => {
    for (let item of items) {
      if (!item.externalReservationNumber || !item?.transferParcel || item?.transferParcel !== '경동택배') {
        alert('택배운송장이 없는 항목이 있습니다.');
        return;
      }
    }

    dispatch(setLoading('GET'));

    const rs = await serviceStore.parcelAction(
      'invoice/print/kdexp',
      'POST',
      null,
      items?.map((ele) => {
        return {
          seq: ele.parcelInvoiceSeq,
        };
      }),
    );

    const validItems = rs?.data?.Items?.filter((item) => {
      if (item?.ResultCode === '200') return item;
    });

    if (validItems?.length === 0) {
      alert('출력 가능한 운송장이 없습니다.');
    }

    dispatch(setLoading(null));

    if (window.confirm(`경동택배용 라벨을 출력합니다. 프린터 연결을 확인해주세요.\n출력대상: ${validItems?.length}건 (선택 : ${items?.length}건)`)) {
      generateAndExcuteSubCommand(validItems);
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'PARCEL_LIST_CREATE_EX_API') {
      setParcelActionObj({
        visible: true,
        type: 'APIcall',
        searchObj,
        fetchFn: wrappedFetchList,
      });
    } else if (id === 'PARCEL_SERVICE_PARCEL_EXCELDOWN_MAIN' || id === 'PARCEL_RESERVED_PARCEL_EXCELDOWN_MAIN' || id === 'PARCEL_DELIVERY_COMPLETED_PARCEL_EXCELDOWN_MAIN') {
      downloadExcel('main');
    } else if (id === 'PARCEL_SERVICE_PARCEL_EXCELDOWN_SHIPPING_LIST') {
      downloadExcel('main', excelGridRefShippingList, '출고리스트');
    } else if (id === 'PARCEL_SERVICE_PARCEL_EXCELDOWN_SHIPPING_LIST') {
      downloadExcel('main', excelGridRefShippingList, '출고리스트');
    } else if (id === 'PARCEL_SERVICE_PARCEL_EXCELDOWN_KDEXP_UPLOAD_FORM') {
      downloadExcel('main', excelGridRefKDEXPList, '경동택배 업로드 양식', true);
    } else if (id === 'PARCEL_SERVICE_PARCEL_TRANSFER_REGIT_FORM') {
      window.open(PARCEL_SERVICE_PARCEL_TRANSFER_REGIT_FORM);
    } else if (id === 'PARCEL_SERVICE_PARCEL_REGIT_FORM') {
      window.open(PARCEL_SERVICE_PARCEL_REGIT_FORM);
    } else if (id === 'PARCEL_SERVICE_PARCEL_CUSTOMERINFO_REGIT_FORM') {
      window.open(PARCEL_SERVICE_PARCEL_CUSTOMERINFO_REGIT_FORM);
    } else if (id === 'PARCEL_SERVICE_PARCEL_FEE_REGIT_FORM' || id === 'PARCEL_RESERVED_PARCEL_REGIT_FORM') {
      window.open(PARCEL_SERVICE_PARCEL_FEE_REGIT_FORM);
    } else if (
      id === 'PARCEL_SERVICE_ORDERNUMBER_IMPORT_UPLOAD' ||
      id === 'PARCEL_SERVICE_PARCEL_REGIT' ||
      id === 'PARCEL_SERVICE_PARCEL_CUSTOMERINFO_REGIT' ||
      id === 'PARCEL_SERVICE_PARCEL_FEE_REGIT' ||
      id === 'PARCEL_SERVICE_PARCEL_TRANSFER_REGIT' ||
      id === 'PARCEL_RESERVED_PARCEL_REGIT' ||
      id === 'PARCEL_RESERVED_ORDERNUMBER_IMPORT_UPLOAD'
    ) {
      uploaderClick(id);
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'PARCEL_SERVICE_CANCEL' || id === 'PARCEL_RESERVED_CANCEL') {
        cancelParcelService(items);
      } else if (id === 'PARCEL_SERVICE_COMPLETE' || id === 'PARCEL_RESERVED_COMPLETE') {
        const done = items?.filter((ele) => ele.type === 'FINISH' || ele.type === 'TRANSFER');
        if (done?.length > 0) alert('이미 배송완료 혹은 타택배이관 된 건이 있습니다');
        else completeParcelService(items);
      } else if (id === 'PARCEL_SERVICE_DIVIDE') {
        setDivideOrderObj({
          visible: true,
          items,
          searchObj,
          fetchFn: wrappedFetchList,
        });
      } else if (id === 'PARCEL_SERVICE_MOVE') {
        if (items?.length > 1) {
          alert('한건씩 가능합니다!');
        } else {
          if (items[0]?.type === 'FINISH' || items[0]?.type === 'TRANSFER') {
            alert('이미 배송완료 혹은 타택배이관 된 건입니다');
          } else {
            setTransferOrderObj({
              visible: true,
              items,
              searchObj,
              fetchFn: wrappedFetchList,
            });
          }
        }
      } else if (id === 'PARCEL_SERVICE_PRINT' || id === 'PARCEL_RESERVED_PRINT') {
        setPrintObj({
          visible: true,
          type: 'default',
          data: items,
        });
      } else if (id === 'PARCEL_SERVICE_PRINT_UI' || id === 'PARCEL_RESERVED_PRINT_UI') {
        setPrintObj({
          visible: true,
          type: 'uni',
          data: items,
        });
      } else if (id === 'PARCEL_SERVICE_RETURN') {
        returnParcels(items);
      } else if (id === 'PARCEL_SERVICE_ACCIDENT') {
        setParcelActionObj({
          visible: true,
          type: 'accident',
          items,
          searchObj,
          fetchFn: (searchObj) => wrappedFetchList(searchObj),
        });
      } else if (id === 'PARCEL_SERVICE_REVISIT') {
        setParcelActionObj({
          visible: true,
          type: 'revisit',
          items,
          searchObj,
          fetchFn: (searchObj) => wrappedFetchList(searchObj),
        });
      } else if (id === 'REQUEST_CHANGE_FARE') {
        if (items.length > 1) {
          alert('운임비 변경은 1건씩 처리할 수 있습니다.');
          return;
        }
        setChangeFareObj({
          visible: true,
          items,
          query: {
            parcelInvoiceSeq: items[0].parcelInvoiceSeq,
            beforePrice: items[0].price || 0,
          },
        });
      } else if (id === 'PARCEL_SERVICE_KDEXPRESS_SHIPPING') {
        kdexTransferShipping(items);
      } else if (id === 'PARCEL_SERVICE_KDEXPRESS_PRINT_LABEL') {
        printKdexLabel(items);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const KRTOVALUE = {
    partnerSeq: 'SELLER_WHOLE',
    payType: 'PARCEL_PAY_TYPE',
    portCode: 'PORT_CODE',
  };

  const EXCELHEADER = {
    PARCEL_SERVICE_PARCEL_REGIT: [
      'houseNumber',
      'totalBoxQuantity',
      'parcelInvoiceNumber',
      'portCode',
      'partnerSeq',
      'customerName',
      'customerZipcode',
      'customerAddress',
      'customerPhone',
      'shipmentNote',
      'width',
      'depth',
      'height',
      'weight',
      'payType',
      'price',
      'extraFee',
      'productName',
      'stage',
      'orderNumber',
      'remark',
    ],
    PARCEL_SERVICE_ORDERNUMBER_IMPORT_UPLOAD: ['parcelInvoiceNumber', 'orderNumber'],
    PARCEL_SERVICE_PARCEL_CUSTOMERINFO_REGIT: [
      'houseNumber',
      'customerName',
      'customerZipcode',
      'customerAddress',
      'customerPhone',
      'payType',
      'senderName',
      'price',
      'shipmentNote',
      'stage',
      'orderNumber',
    ],
    PARCEL_SERVICE_PARCEL_FEE_REGIT: ['parcelInvoiceNumber', 'payType', 'price', 'extraFee', 'width', 'depth', 'height'], //
    PARCEL_SERVICE_PARCEL_TRANSFER_REGIT: ['parcelInvoiceNumber', 'transferParcel', 'externalInvoiceNumber', 'transferPrice'],
  };
  const HEADERLINEIDX = {
    PARCEL_SERVICE_PARCEL_REGIT: 1,
  };

  const uploadExcel = async (dataDTO, id) => {
    let action;
    if (id === 'PARCEL_SERVICE_PARCEL_REGIT') {
      action = 'create';
    } else if (id === 'PARCEL_SERVICE_PARCEL_CUSTOMERINFO_REGIT') {
      action = 'info/import';
    } else if (id === 'PARCEL_SERVICE_PARCEL_FEE_REGIT') {
      action = 'price/import';
    } else if (id === 'PARCEL_SERVICE_PARCEL_TRANSFER_REGIT') {
      action = 'transfer/import';
    } else if (id === 'PARCEL_SERVICE_ORDERNUMBER_IMPORT_UPLOAD') {
      action = 'orderNumber/import';
    }
    const rs = await serviceStore?.parcelAction(`invoice/${action}`, 'POST', null, dataDTO);
    if (rs?.status === 200) {
      alert('등록되었습니다!');
      wrappedFetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE, HEADERLINEIDX });

  useEffect(() => {
    if (parsedData) {
      if (parsedData?.data?.length > 0) {
        uploadExcel(parsedData?.data, parsedData?.id);
      } else {
        alert('읽혀진 값이 없습니다');
      }
    }
  }, [parsedData]);

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      ...defaultSearchFilter,
    },
    gridRef,
    fetchTotal,
    fetchPaging,
  });

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj, tabId]);

  useEffect(() => {
    setFetchCount(null);
    setSearchObj({ ...defaultSearchFilter });

    gridRef?.current?.changeColumnLayout(gridParamToGridColumn(MASTER_OBJ?.[GRIDID]?.userGridParameters ? MASTER_OBJ?.[GRIDID]?.userGridParameters : PARCEL_SERVICE_LIST_GRID, mappingFunction));
    gridRef?.current?.clearGridData();
    gridRef?.current?.setFixedColumnCount(MASTER_OBJ?.[GRIDID]?.fixedColumn ?? (defaultFixedColumnCountObj[tabId] || 2));

    setSearchObj((prev) => {
      return {
        ...prev,
        ...reduxSearchObj,
        pageNo: -1, //NOTE : 기존 탭에서 조회를 수행한 경우 searchObj의 pageNo값에 -1이 아닌 값이 할당되므로 탭 전환 시 초기화
      };
    });
  }, [tabId]);

  const openDetail = async (e) => {
    setDetailObj({
      visible: true,
      item: e.item,
      fetchFn: (data) => wrappedFetchList(data),
    });
  };

  const mappingFunction = {
    showDetail: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: openDetail,
      },
    },
    collectProgressDayKr: {
      type: 'styleFunction',
      mapValue: function (rowIndex, columnIndex, value, headerText, item, dataField) {
        if (item?.collectProgressDay < 3) {
          return '';
        } else if (item?.collectProgressDay < 5) {
          return 'small-warning-column';
        } else {
          return 'warning-column';
        }
      },
    },
    customsReleaseProgressDayKr: {
      type: 'styleFunction',
      mapValue: function (rowIndex, columnIndex, value, headerText, item, dataField) {
        if (item?.customsReleaseProgressDay < 3) {
          return '';
        } else if (item?.customsReleaseProgressDay < 5) {
          return 'small-warning-column';
        } else {
          return 'warning-column';
        }
      },
    },
    statusKr: {
      type: 'styleFunction',
      mapValue: function (rowIndex, columnIndex, value, headerText, item, dataField) {
        if (value === '인수') {
          return 'status-badge-yellow';
        } else if (value === '예약접수' || value === '집하지시') {
          return 'status-badge-gray';
        } else if (value === '등록실패') {
          return 'status-badge-orange';
        } else if (value === '이동중' || value === '배달지' || value === '배송출발') {
          return 'status-badge-green';
        } else if (value === '타택배배송중' || value === '배송완료') {
          return 'status-badge-blue';
        } else if (value === '오입고') {
          return 'status-badge-red';
        }

        return null;
      },
    },
    userIdKr: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: popupDriverDetail,
      },
    },
    width: {
      dataType: 'numeric',
      formatString: '#,##0',
    },
    height: {
      dataType: 'numeric',
      formatString: '#,##0',
    },
    depth: {
      dataType: 'numeric',
      formatString: '#,##0',
    },
    volume: {
      dataType: 'numeric',
      formatString: '#,##0',
    },
    weight: {
      dataType: 'numeric',
      formatString: '#,##0',
    },
    price: {
      dataType: 'numeric',
      formatString: '#,##0',
    },
    extraFee: {
      dataType: 'numeric',
      formatString: '#,##0',
    },
    transferPrice: {
      dataType: 'numeric',
      formatString: '#,##0',
    },
  };

  const [columns, _] = useState(gridParamToGridColumn(MASTER_OBJ?.[GRIDID]?.userGridParameters ? MASTER_OBJ?.[GRIDID]?.userGridParameters : PARCEL_SERVICE_LIST_GRID, mappingFunction));
  const [parcelActionObj, setParcelActionObj] = useState<IParcelActionObj>();
  const shippingListColumns: IGrid.Column[] = [
    {
      headerText: '접수일',
      dataField: 'datetime',
    },
    {
      headerText: '운송장번호',
      dataField: 'parcelInvoiceNumber',
    },
    {
      headerText: 'HBL',
      dataField: 'houseNumber',
    },
    {
      headerText: '경동송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '받는분',
      dataField: 'receiverName',
    },
    {
      headerText: '배송구분',
      dataField: 'payTypeKr',
    },
    {
      headerText: '박스수량',
      dataField: 'totalBoxQuantity',
    },
    {
      headerText: '운임비',
      dataField: 'price',
    },
    {
      headerText: '비고',
      dataField: 'remark',
    },
  ];

  const kdexpListColumns: IGrid.Column[] = [
    {
      headerText: '우편번호',
      dataField: 'receiverZipcode',
    },
    {
      headerText: '도착영업소',
      dataField: 'arrivalStore',
    },
    {
      headerText: '받는분',
      dataField: 'receiverName',
    },
    {
      headerText: '전화번호',
      dataField: 'receiverPhone',
    },
    {
      headerText: '기타전화번호',
      dataField: 'etcPhone',
    },
    {
      headerText: '주소',
      dataField: 'receiverAddr1',
    },
    {
      headerText: '상세주소',
      dataField: 'receiverAddr2',
    },
    {
      headerText: '품목명',
      dataField: 'productName',
    },
    {
      headerText: '수량',
      dataField: 'totalBoxQuantity',
    },
    {
      headerText: '포장상태',
      dataField: 'packageType',
    },
    {
      headerText: '개별단가(만원)',
      dataField: 'individualUnitPrice',
    },
    {
      headerText: '배송구분',
      dataField: 'payTypeKr',
    },
    {
      headerText: '운임',
      dataField: 'price',
    },
    {
      headerText: '별도운임',
      dataField: 'separatePrice',
    },
    {
      headerText: '기타운임',
      dataField: 'etcPrice',
    },
    {
      headerText: '메모1',
      dataField: 'parcelInvoiceNumber',
    },
    {
      headerText: '메모2',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '메모3',
      dataField: 'number',
    },
    {
      headerText: '메모4',
      dataField: '',
    },
    {
      headerText: '메모5',
      dataField: '',
    },
    {
      headerText: '메모6',
      dataField: '',
    },
    {
      headerText: '메모7',
      dataField: '',
    },
    {
      headerText: '메모8',
      dataField: '',
    },
    {
      headerText: '메모9',
      dataField: '',
    },
    {
      headerText: '메모10',
      dataField: '',
    },
    {
      headerText: '메모11',
      dataField: '',
    },
    {
      headerText: '메모12',
      dataField: '',
    },
    {
      headerText: '메모13',
      dataField: '',
    },
    {
      headerText: '메모14',
      dataField: '',
    },
    {
      headerText: '메모15',
      dataField: '',
    },
  ];

  const defaultFixedColumnCountObj: defaultFixedColumnCountObjType = {
    PARCEL_SERVICE_LIST: 6,
    PARCEL_RESERVED_LIST: 4,
    PARCEL_DELIVERY_COMPLETED_LIST: 3,
  };

  return (
    <div className="page-content">
      {driverDetailObj?.visible && <DetailPopup detailObj={driverDetailObj} setDetailObj={setDriverDetailObj} />}
      {parcelActionObj?.visible && <ParcelActionPopup parcelActionObj={parcelActionObj} setParcelActionObj={setParcelActionObj} />}
      {detailObj?.visible && <ParcelDetail detailObj={detailObj} setDetailObj={setDetailObj} />}
      {printObj?.visible && <Prints printObj={printObj} setPrintObj={setPrintObj} />}
      {transferOrderObj?.visible && <TransferrderPopup transferOrderObj={transferOrderObj} setTransferOrderObj={setTransferOrderObj} />}
      {divideOrderObj?.visible && <DivideOrderPopup divideOrderObj={divideOrderObj} setDivideOrderObj={setDivideOrderObj} />}
      {changeFareObj?.visible && <ChangeFareModal changeFareObj={changeFareObj} setChangeFareObj={setChangeFareObj} />}
      <div className="presenterSearch">
        <SearchBox fetchCount={fetchCount} searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} TARGET={tabId} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}

        {GRIDID ? (
          <GridBox
            gridId={GRIDID}
            gridRef={gridRef}
            columns={columns}
            gridProps={{
              //NOTE: api로부터 받은 fixedColumn 응답 값이 null인 경우 defaultFixedColumnCountObj에 정의된 고정컬럼 값을 적용
              fixedColumnCount: MASTER_OBJ?.[GRIDID]?.fixedColumn ?? (defaultFixedColumnCountObj[tabId] || 2),
              pageRowCount: MASTER_OBJ?.[GRIDID]?.pageSize || PARCEL_GRID_PAGE_ROW_COUNT,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
            }}
            defaultFixedColumnCountObj={defaultFixedColumnCountObj}
          />
        ) : (
          <LoadingGridBox />
        )}
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelGridRefShippingList} columns={shippingListColumns} />
        <PrintGridBox gridRef={excelGridRefKDEXPList} columns={kdexpListColumns} />
      </div>
    </div>
  );
};

export default Index;
