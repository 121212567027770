import { useEffect, useMemo, useRef, useState } from 'react';
// redux
import { Button, Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

import queryString from 'query-string';
import styled from 'styled-components';
import * as IGrid from 'aui-grid';

import { useLocation, useNavigate } from 'react-router-dom';
import { orderService } from '../../_services/service';
import { IPopupGridObj } from '../../orderListTab/common/component/invocieDetail/modelStockModal';
import { OrderSaveDTO } from '../../orderListTab/_interface/order';
import ProductStockModal from '../../orderListTab/common/component/invocieDetail/productStockModal';
import ModelStockModal from '../../orderListTab/common/component/invocieDetail/modelStockModal';
import { RAWDATA, setLoading } from 'redux/services/menuSlice';
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import { generateUrlByDesignType } from 'components/LDS/utils/path';

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin: 40px 5px 5px 2px;
`;
export const falseNtrueOpt = [
  {
    value: true,
    label: 'O',
  },
  {
    value: false,
    label: 'X',
  },
];

const Index = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentSeq = queryString.parse(location.search);

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const { MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const defaultSearchFilter = useMemo(() => {
    return {
      orderDate: returnDateyyyymmdd(new Date()), // 주문일자
      partnerSeq: MASTER_OPS?.SELLER_SELLER_AUTH?.find((seller) => seller.value === reduxUserInfo['partner']) ? reduxUserInfo['partner'] : null, // 판매사
      salesChannelSeq: reduxUserInfo['salesChannelSeq'], // 판매채널
    };
  }, [reduxUserInfo]);

  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const [invoiceData, setInvoiceData] = useState<OrderSaveDTO>({
    orderType: null,
    feeType: null,
    customerName: null,
    customerPhone: null,
    customerAddr1: null,
    receiverName: null,
    receiverPhone: null,
    receiverAddr1: null,
    productQuantity: null,
    productModels: null,
    ...defaultSearchFilter,
  });
  const [customerReceiverIsSame, setCustomerReceiverIsSame] = useState(false);

  const columns: IGrid.Column[] = [
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
      editable: false,
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
      editable: false,
    },
    {
      headerText: '제품',
      dataField: 'model',
      editable: false,
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
      editable: false,
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
      editable: false,
    },
    {
      headerText: '수량',
      dataField: 'quantity',
      editable: true,
      formatString: '#,##0',
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        autoThousandSeparator: true,
        onlyNumeric: true,
      },
    },
    {
      headerText: '사은품',
      dataField: 'giftFlag',
      renderer: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
        editable: true,
      },
    },
    {
      headerText: '판매가',
      dataField: 'sellingPrice',
      editable: true,
      formatString: '#,##0',
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        autoThousandSeparator: true,
        onlyNumeric: true,
      },
    },
  ];

  useEffect(() => {
    // 수정
    if (currentSeq['invoiceSeq']) {
      setData(Number(currentSeq['invoiceSeq']));
    } else {
      //주문등록시
      const obj = {
        ...invoiceData,
        liteFlag: false,
      };
      if (invoiceData?.salesChannelSeq) {
        if (!reduxUserInfo?.salesChannelSeqs?.includes(',') && reduxUserInfo?.salesChannelSeqs !== '-1' && reduxUserInfo?.salesChannelSeqs !== '0')
          obj['salesChannelSeq'] = reduxUserInfo?.salesChannelSeqs;
      }

      setInvoiceData(obj);
    }
  }, []);

  const setData = async (invoiceSeq) => {
    if (typeof invoiceSeq === 'number') {
      const data = await fetchData(invoiceSeq);
      if (
        data?.receiverName === data?.customerName &&
        data?.receiverPhone === data?.customerPhone &&
        data?.receiverZipcode === data?.customerZipcode &&
        data?.receiverAddr1 === data?.customerAddr1 &&
        data?.receiverAddr2 === data?.customerAddr2
      ) {
        setCustomerReceiverIsSame(true);
      }
      setInvoiceData((prev) => {
        return {
          ...prev,
          ...data,
        };
      });
      gridRef.current.setGridData(data?.productModels);
    }
  };

  useEffect(() => {
    if (customerReceiverIsSame === true) {
      setInvoiceData((prev) => {
        return {
          ...prev,
          receiverName: prev.customerName,
          receiverPhone: prev.customerPhone,
          receiverPhone2: prev.customerPhone2,
          receiverZipcode: prev.customerZipcode,
          receiverAddr1: prev.customerAddr1,
          receiverAddr2: prev.customerAddr2,
        };
      });
    }
  }, [customerReceiverIsSame]);

  const handleDaumPost = (type) => {
    new (window as any).daum.Postcode({
      oncomplete: function (data) {
        const { zonecode, roadAddress, jibunAddress } = data;
        switch (type) {
          case 'customer':
            setInvoiceData((prev) => {
              return {
                ...prev,
                customerZipcode: zonecode,
                customerAddr1: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
              };
            });

            break;
          case 'receiver':
            setInvoiceData((prev) => {
              return {
                ...prev,
                receiverZipcode: zonecode,
                receiverAddr1: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
              };
            });

            break;

          default:
            break;
        }
      },
    }).open();
  };

  const [productStockObj, setProductStockObj] = useState<IPopupGridObj>();
  const [modelStockObj, setModelStockObj] = useState<IPopupGridObj>();

  useEffect(() => {
    if (modelStockObj?.visible === false && modelStockObj?.returns) setModelStockObj(null);
    if (productStockObj?.visible === false && productStockObj?.returns) setProductStockObj(null);
  }, [productStockObj, productStockObj]);

  useEffect(() => {
    if (modelStockObj?.returns) {
      const rows = gridRef.current.getGridData();
      const returns = modelStockObj?.returns?.map((row) => {
        return {
          ...row,
          quantity: 1,
        };
      });
      const added = rows.concat(returns);
      gridRef.current.setGridData(added);
      setInvoiceData((prev) => {
        return {
          ...prev,
          productSeq: null,
          productName: null,
          productQuantity: 1, // reset 수량
        };
      });
    }
  }, [modelStockObj?.returns]);

  const editableDataField = ['giftFlag', 'quantity'];
  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.bind('cellEditEnd', bindCellEdit);
    }
  }, []);

  const bindCellEdit = (e: IGrid.CellEditEndEvent) => {
    if (editableDataField?.includes(e.dataField)) {
      setInvoiceData((prev) => {
        return {
          ...prev,
          productSeq: null,
          productName: null,
          productQuantity: 1,
        };
      });
    }
  };
  useEffect(() => {
    if (productStockObj?.returns) {
      const items = gridRef?.current?.getGridData();
      if (items?.length > 0 && window.confirm('상품추가시에 상품목록이 초기화됩니다 진행하시겠습니까?')) {
        fetchProductAndAddon(productStockObj?.returns[0]);
      } else if (items?.length === 0) {
        fetchProductAndAddon(productStockObj?.returns[0]);
      }
    }
  }, [productStockObj?.returns]);

  const fetchProductAndAddon = async (item) => {
    const products = await orderService.getProductModelGroup(item?.productSeq);
    if (products) {
      setInvoiceData((prev) => {
        return {
          ...prev,
          productSeq: item.productSeq,
          productName: item.productName,
          modelGroup: item.modelGroup,
          installDuration: item.installDuration,
          memberCount: item.memberCount,
          productQuantity: 1,
        };
      });
      gridRef.current.setGridData(products);
      alert('상품이 등록되었습니다.');
    }
  };

  const [filteredOptions, setFilteredOptions] = useState({
    FEE_TYPE: [],
  });

  useEffect(() => {
    if (invoiceData?.orderType) {
      if (invoiceData?.orderType === 'RECORD') {
        setFilteredOptions({
          FEE_TYPE: MASTER_OPS?.FEE_TYPE,
        });
      } else {
        const orderType = MASTER_OPS?.[`${RAWDATA}ORDER_TYPE`]?.filter((ele) => ele.code === invoiceData?.orderType)[0];
        const filtered = orderType.subCodes.map((ele) => {
          return {
            value: ele.code,
            label: ele.codeName,
          };
        });
        setFilteredOptions({
          FEE_TYPE: filtered,
        });
      }
    }
  }, [invoiceData?.orderType]);

  const fetchData = async (invoiceSeq: number) => {
    const data = await orderService.getOrderCopy(invoiceSeq);
    if (data) {
      return data;
    }
  };
  const gridButtonhandler = (e) => {
    if (invoiceData?.partnerSeq) {
      const id = e.target.id;
      if (id === 'ORDERPRODUCT') {
        setProductStockObj((prev) => {
          return { ...prev, partnerSeq: invoiceData?.partnerSeq, visible: true };
        });
      } else if (id === 'ORDERMODEL') {
        setModelStockObj((prev) => {
          return { ...prev, partnerSeq: invoiceData?.partnerSeq, visible: true };
        });
      } else if (id === 'ORDERMODELCLEAR') {
        const checked = gridRef.current.getCheckedRowItems()?.map((ele) => ele.rowIndex);
        const rows = gridRef.current.getGridData();
        const filtered = rows.filter((_, idx) => !checked?.includes(idx));
        gridRef.current.setGridData(filtered);
        if (filtered?.length === 0) {
          setInvoiceData((prev) => {
            return {
              ...prev,
              productSeq: null,
              productName: null,
              modelGroup: null,
              installDuration: null,
              memberCount: null,
              productQuantity: null, // reset 수량
            };
          });
        } else {
          setInvoiceData((prev) => {
            return {
              ...prev,
              productSeq: null,
              productName: null,
              productQuantity: 1, // reset 수량
            };
          });
        }
      }
    } else {
      alert('판매사를 선택해주세요!');
    }
  };

  const validate = (orderInfo, rows) => {
    if (!orderInfo?.orderDate) {
      alert('주문일을 선택해주세요');
      return false;
    } else if (rows?.length === 0) {
      alert('주문등록하려는 제품 또는 상품이 없습니다');
      return false;
    } else if (rows?.length > 0 && !orderInfo?.productQuantity) {
      alert('상품에 대한 수량을 입력하셔야합니다!');
      return false;
    } else if (!orderInfo?.partnerSeq) {
      alert('판매사를 선택해주세요');
      return false;
    } else if (!orderInfo?.orderType) {
      alert('주문유형을 선택해주세요');
      return false;
    } else if (!orderInfo?.feeType) {
      alert('송장유형을 선택해주세요');
      return false;
    } else if (!orderInfo?.externalOrderNumber) {
      alert('외부주문번호를 입력해주세요');
      return false;
    } else if (!orderInfo?.externalInvoiceNumber) {
      alert('외부송장번호를 입력해주세요');
      return false;
    } else if (orderInfo?.liteFlag === null) {
      alert('등록유형을 선택해주세요!');
      return false;
    } else if (!orderInfo?.customerName) {
      alert('고객이름을 입력해주세요');
      return false;
    } else if (!orderInfo?.customerPhone) {
      alert('고객연락처를 입력해주세요');
      return false;
    } else if (!orderInfo?.customerAddr1) {
      alert('고객주소를 입력해주세요');
      return false;
    } else if (!orderInfo?.receiverName) {
      alert('수령인이름을 입력해주세요');
      return false;
    } else if (!orderInfo?.receiverPhone) {
      alert('수령인연락처를 입력해주세요');
      return false;
    } else if (!orderInfo?.receiverAddr1) {
      alert('수령인주소를 입력해주세요');
      return false;
    }
    return true;
  };
  const rowValidate = (rows) => {
    const quantityFlag = rows?.every((ele) => ele.quantity && Number(ele.quantity) > 0);
    if (quantityFlag === false) {
      alert('수량이 입력되지 않은 제품이 있습니다!');
    }
    return quantityFlag;
  };

  const registerOrderData = async (infoData) => {
    const rows = gridRef.current.getGridData();
    if (validate(infoData, rows)) {
      if (rowValidate(rows)) {
        dispatch(setLoading('POST'));
        const productModels = rows.map((ele) => {
          return {
            modelStockSeq: ele.modelStockSeq,
            masterFlag: ele.masterFlag || false,
            giftFlag: ele.giftFlag || false,
            sellingPrice: ele.sellingPrice,
            quantity: ele.quantity,
          };
        });
        infoData.salesChannelSeq = infoData.salesChannelSeq ? infoData.salesChannelSeq : 1000; // 비었으면 본사가 기본
        infoData.productModels = productModels;

        const rs = await orderService.saveOrder(infoData);
        if (rs.status === 200) {
          alert('등록에 성공했습니다!');
          navigate(generateUrlByDesignType('/manageorderInfoList'));
        }
        dispatch(setLoading(null));
      }
    }
  };

  return (
    <>
      <div className="page-content">
        {productStockObj?.visible && <ProductStockModal productStockObj={productStockObj} setProductStockObj={setProductStockObj} />}
        {modelStockObj?.visible && <ModelStockModal modelStockObj={modelStockObj} setModelStockObj={setModelStockObj} />}
        <Row style={{ textAlign: 'right' }}>
          <Col>
            <Button
              className="btn-confirm btn"
              onClick={() => {
                registerOrderData(invoiceData);
              }}
            >
              주문등록
            </Button>
          </Col>
        </Row>
        <>
          <Title>기본정보</Title>
          <Row>
            <Col>
              <label className="col-form-label">*판매사</label>
              <SelectD
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                value={
                  invoiceData?.partnerSeq
                    ? {
                        value: invoiceData?.partnerSeq,
                        label: selectlabel(invoiceData?.partnerSeq, MASTER_OPS?.SELLER_SELLER_AUTH),
                      }
                    : null
                }
                onChange={(option) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      partnerSeq: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*주문일자</label>
              <FlatpickrD
                options={{
                  mode: 'single',
                }}
                value={invoiceData?.orderDate}
                onChange={(opt) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      orderDate: returnDateyyyymmdd(opt[0]),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">희망일</label>
              <FlatpickrD
                options={{
                  mode: 'single',
                }}
                onReset={() => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      promiseDeliveryDate: null,
                    };
                  });
                }}
                value={invoiceData?.promiseDeliveryDate}
                onChange={(opt) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      promiseDeliveryDate: returnDateyyyymmdd(opt[0]),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*주문유형</label>
              <SelectD
                options={MASTER_OPS?.ORDER_TYPE}
                value={
                  invoiceData?.orderType
                    ? {
                        value: invoiceData?.orderType,
                        label: selectlabel(invoiceData?.orderType, MASTER_OPS?.ORDER_TYPE),
                      }
                    : null
                }
                onChange={(option) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      orderType: (option as OptionItem).value,
                      feeType: null,
                      parcelType: null,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*송장유형</label>
              <SelectD
                options={filteredOptions?.FEE_TYPE}
                value={
                  invoiceData?.feeType
                    ? {
                        value: invoiceData?.feeType,
                        label: selectlabel(invoiceData?.feeType, MASTER_OPS?.FEE_TYPE),
                      }
                    : null
                }
                onChange={(option) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      feeType: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*외부주문번호</label>
              <InputD
                value={invoiceData?.externalOrderNumber || ''}
                onChange={({ target }) => {
                  setInvoiceData({
                    ...invoiceData,
                    externalOrderNumber: target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*외부송장번호</label>
              <InputD
                value={invoiceData?.externalInvoiceNumber || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      externalInvoiceNumber: target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">판매채널</label>
              <SelectD
                hasNull={false}
                options={MASTER_OPS?.SALES_CHANNEL?.filter((ele) => ele.value)}
                value={
                  invoiceData?.salesChannelSeq
                    ? {
                        value: invoiceData?.salesChannelSeq,
                        label: selectlabel(invoiceData?.salesChannelSeq, MASTER_OPS?.SALES_CHANNEL),
                      }
                    : null
                }
                onChange={(option) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      salesChannelSeq: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">창고</label>
              <SelectD
                options={MASTER_OPS?.CENTER_AUTH}
                value={
                  invoiceData?.centerCode
                    ? {
                        value: invoiceData?.centerCode,
                        label: selectlabel(invoiceData?.centerCode, MASTER_OPS?.CENTER_AUTH),
                      }
                    : null
                }
                onChange={({ value }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      centerCode: value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">판매처</label>
              <InputD
                value={invoiceData?.seller || ''}
                onChange={(e) => {
                  setInvoiceData({
                    ...invoiceData,
                    seller: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*등록유형</label>
              <SelectD
                options={MASTER_OPS?.LITE_FLAG}
                value={
                  invoiceData?.liteFlag !== null
                    ? {
                        value: invoiceData?.liteFlag,
                        label: selectlabel(invoiceData?.liteFlag + '', MASTER_OPS?.LITE_FLAG),
                      }
                    : null
                }
                onChange={(option) => {
                  setInvoiceData({
                    ...invoiceData,
                    liteFlag: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col />
          </Row>
        </>
        <>
          <Title>상품정보</Title>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 150, display: 'flex', alignItems: 'center' }}>
              <span className="b">상품 번호 :</span> &nbsp;&nbsp; &nbsp;&nbsp;{invoiceData?.productSeq || '-'} &nbsp;&nbsp; &nbsp;&nbsp;
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span className="b">상품명 :&nbsp;&nbsp;</span>
            <InputD
              disabled={invoiceData?.productSeq !== null || gridRef?.current?.getGridData()?.length < 1}
              placeholder="미등록 상품명을 별도로 입력하지 않을 시 자동 생성 됩니다. (예 : 라클라우드 외1건)"
              style={{ width: 415 }}
              value={invoiceData?.productName || ''}
              onChange={(e) => {
                setInvoiceData((prev) => {
                  return {
                    ...prev,
                    productName: e.target.value,
                  };
                });
              }}
            />
          </div>
          <div style={{ display: 'flex', marginTop: 5, alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className="b">상품 수량 :</span>&nbsp;&nbsp;
                <InputD
                  style={{ width: 50 }}
                  type="number"
                  disabled={gridRef?.current?.getGridData()?.length < 1}
                  value={invoiceData?.productQuantity || ''}
                  onChange={(e) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        productQuantity: Number(e.target.value),
                        productSeq: null,
                      };
                    });
                  }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
                <span className="b" style={{ width: 60 }}>
                  제품그룹 :&nbsp;&nbsp;
                </span>
                <span style={{ width: 120 }}>
                  <SelectD
                    isDisabled={invoiceData?.productSeq !== null || gridRef?.current?.getGridData()?.length < 1}
                    value={
                      invoiceData?.modelGroup
                        ? {
                            value: invoiceData?.modelGroup,
                            label: selectlabel(invoiceData?.modelGroup, MASTER_OPS?.MODEL_GROUP),
                          }
                        : null
                    }
                    options={MASTER_OPS?.MODEL_GROUP}
                    onChange={(option) => {
                      setInvoiceData((prev) => {
                        return {
                          ...prev,
                          modelGroup: (option as OptionItem)?.value,
                        };
                      });
                    }}
                  />
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
                <span className="b" style={{ width: 120 }}>
                  배송팀유형 :&nbsp;&nbsp;
                </span>
                <SelectD
                  isDisabled={invoiceData?.productSeq !== null || gridRef?.current?.getGridData()?.length < 1}
                  value={
                    invoiceData?.memberCount
                      ? {
                          value: invoiceData?.memberCount + '',
                          label: selectlabel(invoiceData?.memberCount + '', MASTER_OPS?.TEAM_TYPE),
                        }
                      : null
                  }
                  options={MASTER_OPS?.TEAM_TYPE}
                  onChange={(option) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        memberCount: (option as OptionItem)?.value,
                      };
                    });
                  }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
                <span className="b" style={{ width: 150 }}>
                  설치소요시간 :&nbsp;&nbsp;
                </span>
                <SelectD
                  isDisabled={invoiceData?.productSeq !== null || gridRef?.current?.getGridData()?.length < 1}
                  value={
                    invoiceData?.installDuration + ''
                      ? {
                          value: invoiceData?.installDuration,
                          label: selectlabel(invoiceData?.installDuration, MASTER_OPS?.INSTALL_DURATION),
                        }
                      : null
                  }
                  options={MASTER_OPS?.INSTALL_DURATION}
                  onChange={(option) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        installDuration: (option as OptionItem)?.value,
                      };
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="grid-button-area only-right">
              <div className="red" id="ORDERMODELCLEAR" onClick={gridButtonhandler}>
                - 행삭제
              </div>
              <div className="orange" id="ORDERPRODUCT" onClick={gridButtonhandler}>
                + 상품등록
              </div>
              <div className="orange" id="ORDERMODEL" onClick={gridButtonhandler}>
                + 제품등록
              </div>
            </div>
            <GridBox
              gridRef={gridRef}
              columns={columns}
              gridProps={{
                height: 400,
                editable: true,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
            />
          </div>
        </>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%', marginRight: 20 }}>
            <Title>주문인정보</Title>
            <Row>
              <Col>
                <label className="col-form-label">*주문인이름</label>
                <InputD
                  value={invoiceData?.customerName || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        customerName: target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col />
            </Row>
            <Row>
              <Col>
                <label className="col-form-label">*주문인 연락처</label>
                <InputD
                  value={invoiceData?.customerPhone || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        customerPhone: target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">주문인 연락처2</label>
                <InputD
                  value={invoiceData?.customerPhone2 || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        customerPhone2: target.value,
                      };
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="col-form-label">*주문인 주소</label>
                <Row>
                  <Col style={{ display: 'flex' }}>
                    <InputD
                      readOnly
                      onClick={() => {
                        handleDaumPost('customer');
                      }}
                      placeholder="우편번호"
                      style={{ display: 'inline-block', width: 80, marginRight: 10, cursor: 'pointer' }}
                      value={invoiceData?.customerZipcode || ''}
                    />
                    <InputD
                      placeholder="우편번호를 클릭하세요"
                      disabled
                      value={invoiceData?.customerAddr1?.replace('null', '') || ''}
                      onChange={(e) => {
                        setInvoiceData((prev) => {
                          return {
                            ...prev,
                            customerAddr1: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <InputD
                      placeholder="상세주소를 입력하세요"
                      value={invoiceData?.customerAddr2?.replace('null', '') || ''}
                      onChange={(e) => {
                        setInvoiceData((prev) => {
                          return {
                            ...prev,
                            customerAddr2: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div style={{ width: '50%', position: 'relative' }}>
            <Title>수령인정보</Title>
            <div style={{ left: 100, position: 'absolute', top: 50 }}>
              <label style={{ marginRight: 4 }}>주문인 정보와 동일</label>
              <InputD
                type="checkbox"
                checked={customerReceiverIsSame}
                onChange={(e) => {
                  setCustomerReceiverIsSame(!customerReceiverIsSame);
                }}
              />
            </div>
            <Row>
              <Col>
                <label className="col-form-label">*수령인이름</label>
                <InputD
                  value={invoiceData?.receiverName || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        receiverName: target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col />
            </Row>
            <Row>
              <Col>
                <label className="col-form-label">*수령인연락처</label>
                <InputD
                  value={invoiceData?.receiverPhone || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        receiverPhone: target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">수령인연락처2</label>
                <InputD
                  value={invoiceData?.receiverPhone2 || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        receiverPhone2: target.value,
                      };
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="col-form-label">*수령인주소</label>
                <Row>
                  <Col style={{ display: 'flex' }}>
                    <InputD
                      readOnly
                      onClick={() => {
                        handleDaumPost('receiver');
                      }}
                      placeholder="우편번호"
                      style={{ display: 'inline-block', width: 80, marginRight: 10, cursor: 'pointer' }}
                      value={invoiceData?.receiverZipcode || ''}
                    />
                    <InputD
                      placeholder="우편번호를 클릭하세요"
                      disabled
                      value={invoiceData?.receiverAddr1?.replace('null', '') || ''}
                      onChange={(e) => {
                        setInvoiceData((prev) => {
                          return {
                            ...prev,
                            receiverAddr1: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <InputD
                      placeholder="상세주소를 입력하세요"
                      value={invoiceData?.receiverAddr2?.replace('null', '') || ''}
                      onChange={(e) => {
                        setInvoiceData((prev) => {
                          return {
                            ...prev,
                            receiverAddr2: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <>
          <Title>기타정보</Title>
          <Row>
            <Col>
              <label className="col-form-label">고객 요청사항</label>
              <textarea
                value={invoiceData?.shipmentNote || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      shipmentNote: target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">콜센터 요청사항</label>
              <textarea
                value={invoiceData?.callCenterNote || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      callCenterNote: target.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">추천인고유번호</label>
              <InputD
                value={invoiceData?.recommenderNumber || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      recommenderNumber: target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">추천인이름</label>
              <InputD
                value={invoiceData?.recommenderName || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      recommenderName: target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">취소비용 고객부담</label>
              <SelectD
                options={falseNtrueOpt}
                value={
                  invoiceData?.cancelChargeFlag
                    ? {
                        value: invoiceData?.cancelChargeFlag,
                        label: selectlabel(invoiceData?.cancelChargeFlag + '', falseNtrueOpt),
                      }
                    : null
                }
                onChange={({ value }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      cancelChargeFlag: value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">착불비</label>
              <InputD
                type="number"
                value={invoiceData?.cashOnDelivery || ''}
                onChange={(e) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      cashOnDelivery: Number(e.target.value),
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">택배사</label>
              <InputD
                disabled={invoiceData?.orderType !== 'PARCEL'}
                value={invoiceData?.parcelType || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      parcelType: target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">운임구분</label>
              <SelectD
                options={MASTER_OPS?.PARCEL_PAY_TYPE}
                value={
                  invoiceData?.parcelPayType
                    ? {
                        value: invoiceData?.parcelPayType,
                        label: selectlabel(invoiceData?.parcelPayType, MASTER_OPS?.PARCEL_PAY_TYPE),
                      }
                    : null
                }
                onChange={(option) => {
                  setInvoiceData({
                    ...invoiceData,
                    parcelPayType: (option as OptionItem)?.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">도선료</label>
              <InputD
                type="number"
                value={invoiceData?.parcelExtraFee || ''}
                onChange={({ target }) => {
                  setInvoiceData({
                    ...invoiceData,
                    parcelExtraFee: Number(target.value),
                  });
                }}
              />
            </Col>
            <Col />
          </Row>
        </>
      </div>
    </>
  );
};

export default Index;
