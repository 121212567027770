import { AnnouncementDTO } from 'interface/user';
import { deliveryService } from 'pages/DELIVERY/_services/service';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';

interface AnnouncementDTOEX extends AnnouncementDTO {
  typesArr: string[];
}
export const typeArrToKr = (arr) => {
  const str = [];
  if (arr.includes('erp')) {
    str.push('ERP웹');
  }
  if (arr.includes('logiInstall')) {
    str.push('로지설치+');
  }
  if (arr.includes('logiChango')) {
    str.push('로지창고+');
  }
  return str;
};
const Pointer = styled.span`
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
  margin: 0 10px;
`;
const HOME_VISITED = localStorage.getItem('viewAnnounce5');
export const RecentAnnounce = () => {
  const [should5, setShould5] = useState<AnnouncementDTOEX[]>();
  const [hiddenSeqs, setHiddenSeqs] = useState<{ announcementSeq: number; expires: string }[]>();
  const [now, setNow] = useState(0);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    fetchAnnounce5();
  }, []);

  const fetchAnnounce5 = async () => {
    const today = new Date();
    let hiddenSeq = [];

    if (HOME_VISITED) {
      hiddenSeq = (JSON.parse(HOME_VISITED) || [])?.filter((ele) => ele.expires || ele.expires > today);
      setHiddenSeqs(hiddenSeq);
    }
    const data: any[] = (await serviceStore.userAction(`announcement/import/announcement`, 'GET', null))?.data;
    if (data?.length > 0) {
      data?.forEach((ele) => {
        ele.typesArr = [ele?.erpFlag && 'erp', ele?.installAppFlag && 'logiInstall', ele?.warehouseAppFlag && 'logiChango'];
      });
      const should5 = data?.filter((ele) => !hiddenSeq?.map((ele) => ele.announcementSeq)?.includes(ele.announcementSeq));
      if (should5?.length > 0) {
        setShould5(should5);
        setVisible(true);
      }
    }
  };

  const hideThisArticle = (thisArticle: AnnouncementDTOEX) => {
    const after24h = new Date().setHours(new Date().getHours() + 24) + '';
    const hiddenArr = hiddenSeqs || [];
    hiddenArr?.push({
      announcementSeq: thisArticle.announcementSeq,
      expires: after24h,
    });
    setHiddenSeqs(hiddenArr);
    localStorage.setItem(`viewAnnounce5`, JSON.stringify(hiddenArr));

    const hiddenData = should5?.filter((ele) => ele?.announcementSeq !== thisArticle?.announcementSeq);
    if (hiddenData?.length === 0) {
      setVisible(false);
    } else {
      setShould5(hiddenData);
      setNow(now - 1 > -1 ? now - 1 : 0);
    }
  };

  return (
    <CommonModalNew
      title="공지사항"
      style={{ width: 1300 }}
      visible={visible}
      setVisible={() => {
        setVisible(false);
      }}
      children={
        <>
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {should5?.[now]?.type === 'UPDATE' ? (
                <div className="badge-green" style={{ display: 'inline-block' }}>
                  <div>업데이트</div>
                </div>
              ) : should5?.[now]?.type === 'IMPORTANT' ? (
                <div className="badge-red" style={{ display: 'inline-block' }}>
                  <div>중요</div>
                </div>
              ) : (
                <div className="badge-gray" style={{ display: 'inline-block' }}>
                  <div>일반</div>
                </div>
              )}
            </div>
            <div>작성일: {should5?.[now]?.registerDatetime}</div>
          </div>
          <div style={{ padding: 10 }}>
            {should5?.[now]?.typesArr &&
              typeArrToKr(should5?.[now]?.typesArr).map((ele, idx) => {
                return (
                  <span key={`typesarr_${idx}`}>
                    {idx !== 0 && <span style={{ margin: '0 5px', color: 'rgba(217, 222, 231, 1)' }}> | </span>} <span style={{ fontSize: 12 }}>{ele}</span>
                  </span>
                );
              })}
          </div>
          <div style={{ height: 60, overflowY: 'scroll', padding: 10, marginBottom: 10 }}>
            {should5?.[now]?.files?.length > 0 && <span>(총 {should5?.[now]?.files?.length}개의 파일)</span>}
            {should5?.[now]?.files?.map((ele, idx) => {
              return (
                <span
                  className="clickable"
                  key={`uploadedPdfFile${idx}`}
                  style={{ margin: '0 5px' }}
                  onClick={() => {
                    window.open(ele.uploadUrl);
                  }}
                >
                  <img src={require('assets/images/icon/download_blue.png')} style={{ width: 10, marginRight: 3 }} />
                  <span style={{ textDecoration: 'underline' }}>{ele.tableColumn}</span>
                </span>
              );
            })}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
          <div>
            <div style={{ padding: '5px 10px', fontSize: 20, fontWeight: 600 }}>{should5?.[now]?.title}</div>
            <div style={{ minHeight: 500, position: 'relative' }}>
              <div dangerouslySetInnerHTML={{ __html: should5?.[now]?.content }} style={{ minHeight: 500, overflowX: 'scroll' }} className="divPretendTextArea"></div>
            </div>
          </div>

          <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', position: 'relative' }}>
            <Pointer style={{ color: now === 0 ? '#b3b3b3' : 'black' }} onClick={() => now !== 0 && setNow(now - 1)}>
              {'<'}
            </Pointer>
            <Pointer>
              {now + 1} / {should5?.length}
            </Pointer>
            {now !== 5 && (
              <Pointer style={{ color: now === should5?.length - 1 ? '#b3b3b3' : 'black' }} onClick={() => now !== should5?.length - 1 && setNow(now + 1)}>
                {'>'}
              </Pointer>
            )}
          </div>
          <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', bottom: 5, right: 30, display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
              <span
                className="click-fake-item"
                onClick={() => {
                  hideThisArticle(should5[now]);
                }}
              >
                이 글 하루 동안 다시 열지 않기
              </span>
            </div>
          </div>
        </>
      }
    />
  );
};
