import { useState, useEffect } from 'react';
import Select from 'react-select';
import { Form, Col, Row } from 'reactstrap';
import Grid from '../../../../components/grid/auiGrid';

import searchIcon from '../../../../assets/images/button/searchIcon.png';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';

const Presenter = (props) => {
  return (
    <>
      <div className="page-content">
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox2 {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const Search = (props) => {
  const { setSearchObj, searchObj, fetchList, masterOptions, filteredOptions } = props;
  useEffect(() => {
    if (searchObj?.deliveryDate?.[0] && searchObj?.deliveryDate?.[1] === 'NaN-NaN-NaN') {
      setSearchObj({
        ...searchObj,
        deliveryDate: null,
      });
    }
  }, [searchObj?.deliveryDate]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">배송 설치일</label>
            <FlatpickrD
              onChange={(date) => {
                setSearchObj({
                  ...searchObj,
                  deliveryDate: [returnDateyyyymmdd(date[0]), returnDateyyyymmdd(date[1])],
                });
              }}
            />
          </Col>
          <Col lg={4}>
            <Row>
              <label className="col-form-label">기사소속창고/ 설치기사</label>
              <div style={{ display: 'flex' }}>
                <Col md={6} style={{ display: 'inline-block' }}>
                  <SelectD
                    options={masterOptions?.CENTER}
                    value={
                      searchObj?.centerCode
                        ? {
                            value: searchObj?.centerCode,
                            label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setSearchObj({
                        ...searchObj,
                        centerCode: value,
                      });
                    }}
                  />
                </Col>
                <Col md={6} style={{ display: 'inline-block' }}>
                  <SelectD
                    options={filteredOptions?.DRIVER}
                    value={
                      searchObj?.userId
                        ? {
                            value: searchObj?.userId,
                            label: selectlabel(searchObj?.userId, filteredOptions?.DRIVER),
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setSearchObj({ ...searchObj, userId: value });
                    }}
                  />
                </Col>
              </div>
            </Row>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <button
              onClick={(e) => {
                e.preventDefault();
                fetchList(searchObj);
              }}
              className="btn-search  btn"
            >
              <img src={searchIcon} alt="" style={{ height: 13, width: 13, marginRight: '1rem' }} />
              조회
            </button>
          </Col>
          <Col />
          <Col />
          <Col />
        </Row>
      </Form>
    </>
  );
};

const GridBox = (props) => {
  const { rows, gridId, columns } = props;

  return (
    <>
      <div>1. 주문 마감</div>
      <div style={{ width: '100%' }}>{gridId && <Grid id={gridId} rows={rows} columns={columns} style={{ height: 100 }} />}</div>
    </>
  );
};

const GridBox2 = (props) => {
  const { rows2, gridId2, columns2 } = props;
  const [exportExcelThisGrid, setExportExcelThisGrid] = useState();

  return (
    <>
      <div>2. 주문 목록</div>
      <div style={{ width: '100%' }}>
        <>
          <div className="grid-button-area only-right">
            <div className="green" onClick={() => setExportExcelThisGrid({ type: 'xlsx', id: `#${gridId2}` })}>
              엑셀다운
            </div>
          </div>
          {gridId2 && <Grid id={gridId2} rows={rows2} columns={columns2} style={{ height: 500 }} exportExcelThisGrid={exportExcelThisGrid} />}
        </>
      </div>
    </>
  );
};
