import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { PagingListDTO } from 'interface/util';
import { RootState } from 'redux/store';
import { locationService } from '../../_services/service';
import { InventoryMoveDTO, InventoryMoveSearchDTO } from 'interface/warehouse';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { useNavigate } from 'react-router-dom';

const Index = ({ tabId }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const columns: IGrid.Column[] = [
    {
      headerText: '유형',
      dataField: 'moveTypeKr',
    },
    {
      headerText: '창고명',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
      minWidth: 200,
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
      minWidth: 200,
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '로케이션 이동 전',
      children: [
        {
          headerText: '양품',
          dataField: 'beforeAvailableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'beforeDefectedQuantity',
        },
        {
          headerText: '기타',
          dataField: 'beforeEtcQuantity',
        },
      ],
    },
    {
      headerText: '로케이션 이동 후',
      children: [
        {
          headerText: '양품',
          dataField: 'afterAvailableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'afterDefectedQuantity',
        },
        {
          headerText: '기타',
          dataField: 'afterEtcQuantity',
        },
      ],
    },
    {
      headerText: '로케이션 이동',
      children: [
        {
          headerText: '~에서',
          dataField: 'fromLocationCode',
        },
        {
          headerText: '~로',
          dataField: 'toLocationCode',
        },
      ],
    },
    {
      headerText: '증감',
      children: [
        {
          headerText: '양품',
          dataField: 'moveAvailableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'moveDefectedQuantity',
        },
        {
          headerText: '기타',
          dataField: 'moveEtcQuantity',
        },
      ],
    },
    {
      headerText: '이동일시',
      dataField: 'moveDatetime',
    },
    {
      headerText: '이동확정자',
      dataField: 'moveId',
    },
  ];

  const labellingKr = (data: PagingListDTO<InventoryMoveDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        moveTypeKr: selectlabel(row.moveType, masterOptions?.INVENTORY_MOVE_TYPE),
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryMoveSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await locationService.getInventoryMoveAction('inventory/move/paging', searchObj, true));
    console.log(dataKr);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'INV_MOVEACTION_GO') {
      currentDesignType === 1 ? navigate('/main/moveActionList/moveActionRegit') : window.open(`/moveActionRegit`);
    } else if (id === 'INV_MOVEACTION_EXCELDOWN') {
      downloadExcel();
    }
  };

  const downloadExcel = async () => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: await locationService.getInventoryMoveAction('inventory/move/excel', searchObj, false) }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '재고이동' });
    dispatch(setLoading(null));
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
