import { useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './takeout';
import Tab2 from './returns';

// options
import { nullingMASTER } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { InvoiceDetailSearchDTO } from 'interface/order';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
export interface IDetailObjProps {
  visible: boolean;
  item?: InvoiceDetailSearchDTO;
}

export const MANAGE_PARCEL_TRACKING_REPORT_MASTER = ['PARCEL_PAY_TYPE', 'SELLER_FORWARDER_AUTH', 'SELLER_FORWARDER_WHOLE'];

const Index = () => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_TRACKING_REPORT_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PARCEL_TRACKING_3_TAKEOUT: <Tab1 tabId={'PARCEL_TRACKING_3_TAKEOUT'} />,
            PARCEL_TRACKING_3_RETURNS: <Tab2 tabId={'PARCEL_TRACKING_3_RETURNS'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
