import LDSButton from '../atoms/Button';
import { LDSSubTitle, LDSTagline } from '../atoms/Typography';
import { COLORS } from 'common/constants/appearance';
import styled from 'styled-components';
import { LDSSpacing } from '../atoms/Spacing';
import { ErrorWrapper } from './Error';

export default function LDSNotFound({ $description, $buttonLabel, $link }: { $description?: string; $buttonLabel?: string; $link?: string }) {
  return (
    <NotFoundWrapper>
      <LDSTagline $color={COLORS.WARNING_LIGHT}>404</LDSTagline>
      <LDSSubTitle $color={COLORS.GRAY_06}>{$description ? $description?.split('\n').map((row, index) => <div key={`${index}_${row}`}>{row}</div>) : <>페이지를 찾을 수 없습니다.</>}</LDSSubTitle>
      <LDSSpacing $direction="V" $value={8} />
      <LDSButton $type={'LINK'} $size={'LG'} $primary={false} $usage={'BLUE'} $style={'FILL'} $label={$buttonLabel ?? '메인으로'} $linkTo={$link ?? '/'} />
    </NotFoundWrapper>
  );
}

const NotFoundWrapper = styled(ErrorWrapper)``;
