import { ReactNode, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

// service
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ParcelInvoicePriceResponseDTO, ParcelInvoicePriceSearchDTO } from '../../../_interface/parcel';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
// component
import { PagingListDTO } from 'interface/util';
import { serviceStore } from 'services/services';

import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const labellingKr = (data: PagingListDTO<ParcelInvoicePriceResponseDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        partnerSeqKr: MASTER_OBJ?.SELLER_FORWARDER_WHOLE?.[row?.partnerSeq],
        statusKr: MASTER_OBJ?.PARCEL_FEE_CHANGE_FARE_STATUS?.[row?.status],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '판매사명',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '운송장번호',
      dataField: 'parcelInvoiceNumber',
    },
    {
      headerText: '현재운임비',
      dataField: 'beforePrice',
    },
    {
      headerText: '변경운임비',
      dataField: 'price',
    },
    {
      headerText: '변경사유',
      dataField: 'reason',
    },
    {
      headerText: '변경요청자',
      dataField: 'registerId',
    },
    {
      headerText: '변경요청일',
      dataField: 'registerDatetime',
    },
    {
      headerText: '변경완료자',
      dataField: 'confirmId',
    },
    {
      headerText: '변경완료일',
      dataField: 'confirmDatetime',
    },
    {
      headerText: '변경상태',
      dataField: 'statusKr',
    },
  ];

  const fetchPaging = async (searchObj: ParcelInvoicePriceSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore?.parcelAction('invoice/priceForPaging', 'GET', searchObj, null, true))?.data as PagingListDTO<ParcelInvoicePriceResponseDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 500,
    },
    gridRef,
    fetchPaging,
  });

  const completeChangeFare = async (items: ParcelInvoicePriceResponseDTO[]) => {
    const partnerInvoiceSeqs = items
      .map((item) => {
        if (item.status === 'READY') {
          return { seq: item.parcelInvoicePriceSeq };
        }
      })
      .filter((item) => {
        return item !== undefined && item;
      });

    if (partnerInvoiceSeqs.length === 0) {
      alert('이미 변경 완료 처리된 요청입니다.');
      return;
    }

    if (window.confirm(`변경완료 하시겠습니까?\n(완료처리 대상 : ${partnerInvoiceSeqs.length}건)`)) {
      dispatch(setLoading('POST'));
      const rs = await serviceStore?.parcelAction(`invoice/price/confirm`, 'POST', null, [...partnerInvoiceSeqs]);
      if (rs?.status === 200) {
        alert('완료처리 되었습니다!');
        wrappedFetchList(searchObj);
      } else {
        rs.message && alert(rs.message);
      }
      dispatch(setLoading(null));
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    handleCheckItems(id);
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'CHANGE_FARE_COMPLETE') {
        completeChangeFare(items);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}

        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 0,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
