import { ReactNode, useEffect, useState } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './list';

// options
import { nullingMASTER } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { useLocation } from 'react-router-dom';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { TopTabController } from 'components/template/TopTabController';

export const MANAGE_PARCEL_LOAD_LIST_MASTER = ['CENTER_AUTH', 'CENTER_WHOLE', 'PLINE_UNLOAD_TYPE'];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const location = useLocation();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_LOAD_LIST_MASTER);
  };

  const tabIdTranslator = {
    '/manageParcelLoadList': 'PARCEL_LINEHAUL_LOAD_LIST',
    '/manageParcelUnloadList': 'PARCEL_LINEHAUL_UNLOAD_LIST',
  };

  const currentTabId = tabIdTranslator[location?.pathname];

  let containers = {};

  if (location?.pathname === '/manageParcelLoadList') containers['PARCEL_LINEHAUL_LOAD_LIST'] = <Tab1 tabId={currentTabId} />;
  if (location?.pathname === '/manageParcelUnloadList') containers['PARCEL_LINEHAUL_UNLOAD_LIST'] = <Tab1 tabId={currentTabId} />;

  return <>{MASTER_OBJ && <TopTabController containers={containers} />}</>;
};

export default Index;
