import { useRef, useState } from 'react';
import styled from 'styled-components';
import LDSButton from './Button';
import { FiX } from 'react-icons/fi';
import { COLORS, TRANSITIONS, VALUES } from 'common/constants/appearance';
import { LDSErrorMessage } from './Typography';
import { UseFormRegister, UseFormResetField, UseFormWatch } from 'react-hook-form';
import { UnionFieldTypeProps, UnionFormTypeProps } from '../types/types';

type InputfieldTypeProps = 'text' | 'email' | 'password' | string;
type InputFieldProps = {
  $type: InputfieldTypeProps;
  $status: 'ACTIVE' | 'INACTIVE' | 'READONLY';
  $fieldName?: string;
  $placeholder?: string;
  $default?: string;
  $autofocus?: boolean;
  $width?: number | 'FULL';
  $errorMessage?: string;
  $isRequire?: boolean;
  $fieldDataName?: UnionFieldTypeProps;
  register?: UseFormRegister<UnionFormTypeProps>;
  watch?: UseFormWatch<UnionFormTypeProps>;
  resetField?: UseFormResetField<UnionFormTypeProps>;
  onClick?: () => void;
};

export default function LDSInputField({
  $type,
  $default,
  $fieldName,
  $placeholder,
  $autofocus,
  $status,
  $errorMessage,
  $width,
  $isRequire,
  $fieldDataName,
  register,
  watch,
  resetField,
  onClick,
}: InputFieldProps) {
  const placeholderText = $placeholder || `${$fieldName || '필드'} 입력...`;
  const [content, setContent] = useState<string>($default || '');
  const { onChange, onBlur, name, ref } = register && register($fieldDataName);
  const fieldContent = watch && watch($fieldDataName);

  return (
    <>
      <LDSInputFieldWrapper $width={$width} $errorMessage={$errorMessage}>
        <LDSInput
          onClick={onClick}
          autoFocus={$autofocus ? true : false}
          type={$type || 'text'}
          placeholder={placeholderText}
          id={$fieldName}
          required={$isRequire}
          readOnly={$status === 'READONLY' ? true : false}
          disabled={$status === 'INACTIVE' ? true : false}
          ref={ref && ref}
          name={name && name}
          onChange={onChange && onChange}
          // onChange={(event: React.ChangeEvent<HTMLInputElement>) => setContent(event.target.value)}
        />
        {typeof fieldContent === 'string' && fieldContent.length > 0 && $status === 'ACTIVE' && (
          <LDSButton
            tabIndex={-1}
            $type="BUTTON"
            $primary={false}
            $isCircled={true}
            $style="FILL"
            $size="XS"
            $icon={<FiX />}
            $showIcon={true}
            onClick={() => {
              content && setContent('');
              resetField($fieldDataName);
            }}
          />
        )}

        <div className="field-background"></div>
      </LDSInputFieldWrapper>
      {$errorMessage && <LDSErrorMessage>{$errorMessage}</LDSErrorMessage>}
    </>
  );
}

type StyledInputWrapperProps = Pick<InputFieldProps, '$width' | '$errorMessage'>;

export const LDSBaseFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .field-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: ${VALUES.RADIUS}px;
    border-width: 1px;
    border-style: solid;
    pointer-events: none;
    -webkit-transition: all 200ms ${TRANSITIONS.BEZIER};
    transition: all 200ms ${TRANSITIONS.BEZIER};
  }

  .field-background {
    border-color: ${COLORS.GRAY_03_DEEP};
  }

  &:hover {
    cursor: text;

    *:not([disabled]):not([readonly]) ~ .field-background {
      border-color: ${COLORS.GRAY_04};
    }
  }

  *:hover:not([disabled]):not([readonly]) ~ .field-background {
    border-color: ${COLORS.GRAY_04};
  }
  *:focus:not([disabled]):not([readonly]) ~ .field-background {
    border-color: ${COLORS.PRIMARY};
  }
`;

const LDSInputFieldWrapper = styled(LDSBaseFieldWrapper)<StyledInputWrapperProps>`
  width: ${(props) => (props.$width === 'FULL' ? '100%' : typeof props.$width === 'number' ? `${props.$width}px` : 'auto')};

  .field-background {
    border-color: ${(props) => (!props.$errorMessage ? COLORS.GRAY_03_DEEP : `${COLORS.ALERT} !important`)};
  }

  &:not(:hover) input:not(:focus) ~ button {
    opacity: 0;
    pointer-events: none;
  }
`;

export const LDSInput = styled.input`
  border: none;
  outline: none;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 10px;
  width: 100%;
  color: ${COLORS.GRAY_07};

  &::placeholder {
    color: ${COLORS.GRAY_04};
    font-size: 14px !important;
  }
  &[disabled] {
    cursor: not-allowed;
    background-color: ${COLORS.GRAY_01};
  }

  &[readonly] {
    background-color: ${COLORS.PRIMARY_004};

    & ~ .field-background {
      border-color: ${COLORS.PRIMARY_015};
    }
  }

  & ~ button {
    min-height: 23px;
    margin-right: 8px !important;
    user-select: none;
  }
`;
