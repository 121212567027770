import { useEffect, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { userService } from '../../_service/service';
import { UserListDTO, UserSearchDTO } from 'interface/user';
import { PagingListDTO } from 'interface/util';
import { IAuthDetailObj, UserDetailPopup } from './component/userDetail';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const [authDetailObj, setAuthDetailObj] = useState<IAuthDetailObj>();
  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    setAuthDetailObj({
      visible: true,
      fetchFn: (searchObj) => wrappedFetchList(searchObj),
      searchObj,
      item: e.item,
    });

    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetail,
      },
    },
    {
      headerText: '소속 조직',
      dataField: 'groupSeqKr',
    },
    {
      headerText: '소속 파트너',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '소속 인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '소속 창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '소속 판매채널',
      dataField: 'salesChannelSeqKr',
    },
    {
      headerText: '이름',
      dataField: 'userName',
    },
    {
      headerText: '아이디',
      dataField: 'userId',
    },
    {
      headerText: '사용자 유형',
      dataField: 'userCodeKr',
    },
    {
      headerText: '사용자 상태값',
      dataField: 'useFlagKr',
    },
    {
      headerText: '연락처',
      dataField: 'phoneNumber',
    },
    {
      headerText: '이메일',
      dataField: 'email',
    },
  ];

  const labellingKr = (data: PagingListDTO<UserListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        //
        groupSeqKr: MASTER_OBJ?.['JOJIK_GROUP']?.[row.groupSeq],
        centerCodeKr: MASTER_OBJ?.['CENTER_AUTH']?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.['SELLER_AUTH']?.[row.partnerSeq],
        userCodeKr: MASTER_OBJ?.['USER_CODE']?.[row.userCode],
        infraSeqKr: MASTER_OBJ?.['INFRA']?.[row.infraSeq],
        salesChannelSeqKr: MASTER_OBJ?.['SALES_CHANNEL']?.[row.salesChannelSeq],

        useFlagKr: row.useFlag === true ? '사용' : '미사용',
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: UserSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await userService.getUserListPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  return (
    <div className="page-content">
      {authDetailObj?.visible && <UserDetailPopup authDetailObj={authDetailObj} setAuthDetailObj={setAuthDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area">
          <div
            className="blue"
            onClick={() => {
              setAuthDetailObj({
                visible: true,
                fetchFn: (searchObj) => wrappedFetchList(searchObj),
                searchObj,
                item: null,
              });
            }}
          >
            사용자 추가
          </div>
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
