import { ReactNode, useMemo, useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// utils
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
// service
import { InventoryInvestigationDTO, InventoryInvestigationSearchDTO } from '../../../_interface/warehouse';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { serviceStore } from 'services/services';
import { InvestigatePrint, IPrints } from '../_print/investigatePrint';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const bottomGriQuantityRef = useRef<IPagingGrid>();
  const bottomGridSerialRef = useRef<IPagingGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const showDetail = async (e: IGrid.ButtonClickEvent) => {
    const data = (await serviceStore.warehouseAction(`inventory/investigate/detail?inventoryInvestigationSeq=${e.item?.inventoryInvestigationSeq}`, 'GET', null))?.data;
    const data2 = (await serviceStore.warehouseAction(`inventory/investigate/serial?inventoryInvestigationSeq=${e.item?.inventoryInvestigationSeq}`, 'GET', null))?.data;

    if (data?.length > 0) {
      const rows = data?.map((row) => {
        return {
          ...row,
          centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
          partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
          availableTypeKr: MASTER_OBJ?.INVA_TYPE?.[row.availableType],
        };
      });
      const rows2 = data2?.map((row) => {
        return {
          ...row,
          centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
          partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
          scanPartnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.scanPartnerSeq],
          availableTypeKr: MASTER_OBJ?.INVA_TYPE?.[row.availableType],
          scanAvailableTypeKr: MASTER_OBJ?.INVA_TYPE?.[row.scanAvailableType],
        };
      });
      setBottomSheetObj({
        visible: true,
        hasTabs: [
          {
            title: '재고조사 상세(수량)',
            gridRef: bottomGriQuantityRef,
            columns: detailColumns?.['QUANTITY'],
            rows,
            options: {
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
            },
          },
          {
            title: '재고조사 상세(시리얼)',
            gridRef: bottomGridSerialRef,
            columns: detailColumns?.['SERIAL'],
            rows: rows2,
            options: {
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
            },
          },
        ],
      });
    } else {
      alert('조회되는 정보가 없습니다!');
    }
    dispatch(setLoading(null));
  };

  const [printObj, setPrintObj] = useState<IPrints>();
  const showPrint = (e: IGrid.ButtonClickEvent) => {
    setPrintObj({ visible: true, item: e.item });
  };
  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: showDetail,
      },
    },
    {
      headerText: '재고조사지시서',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '프린트',
        onClick: showPrint,
      },
    },
    {
      headerText: '작업지시번호',
      dataField: 'inventoryInvestigationSeq',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '작업자',
      dataField: 'userId',
    },
    {
      headerText: '수량',
      headerTooltip: {
        show: true,
        tooltipHtml: `<div>전산수량보다 실사수량이 많은 경우 <span style="color: red">빨강</span></div>
            <div>전산수량보다 실사수량이 적은 경우 <span style="color: blue">파랑</span></div>`,
      },
      children: [
        {
          headerText: '전산수량',
          dataField: 'quantity',
          styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
            if (item?.diffQuantity < 0) {
              return 'text-color-blue';
            } else if (item?.diffQuantity > 0) {
              return 'text-color-red';
            } else {
              return 'text-color-black';
            }
          },
        },
        {
          headerText: '실사수량',
          dataField: 'scanQuantity',
          styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
            if (item?.diffQuantity < 0) {
              return 'text-color-blue';
            } else if (item?.diffQuantity > 0) {
              return 'text-color-red';
            } else {
              return 'text-color-black';
            }
          },
        },
        {
          headerText: '차이',
          dataField: 'diffQuantity',
          styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
            if (item?.diffQuantity < 0) {
              return 'text-color-blue';
            } else if (item?.diffQuantity > 0) {
              return 'text-color-red';
            } else {
              return 'text-color-black';
            }
          },
        },
      ],
    },
    {
      headerText: '시리얼',
      headerTooltip: {
        show: true,
        tooltipHtml: `<div>신규나 수정 수량이 있는 경우  <span style="color: purple">보라</span></div>`,
      },
      children: [
        {
          headerText: '전산시리얼',
          dataField: 'serialQuantity',
          styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
            if (item?.serialNewQuantity + item?.serialUpdateQuantity === 0) {
              return 'text-color-black';
            } else {
              return 'text-color-purple';
            }
          },
        },
        {
          headerText: '실사 시리얼(신규/수정)',
          dataField: 'serialQuantityKr',
          styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
            if (item?.serialNewQuantity + item?.serialUpdateQuantity === 0) {
              return 'text-color-black';
            } else {
              return 'text-color-purple';
            }
          },
        },
        {
          headerText: '미확인 시리얼',
          dataField: 'serialDeleteQuantity',
          styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
            if (item?.serialNewQuantity + item?.serialUpdateQuantity === 0) {
              return 'text-color-black';
            } else {
              return 'text-color-purple';
            }
          },
        },
      ],
    },
    {
      headerText: '시작일시',
      dataField: 'startDatetime',
    },
    {
      headerText: '완료일시',
      dataField: 'finishDatetime',
    },
    {
      headerText: '마감일시',
      dataField: 'closeDatetime',
    },
    {
      headerText: '재고반영일시',
      dataField: 'applyDatetime',
    },
    {
      headerText: '마감자',
      dataField: 'closeId',
    },
    {
      headerText: '재고반영자',
      dataField: 'applyId',
    },
  ];

  const detailColumns: { [key: string]: IGrid.Column[] } = {
    QUANTITY: [
      {
        headerText: '로케이션',
        dataField: 'locationCode',
      },
      {
        headerText: '작업자',
        dataField: 'userId',
      },
      {
        headerText: '제품정보',
        children: [
          {
            headerText: '판매사',
            dataField: 'partnerSeqKr',
          },
          {
            headerText: '제품',
            dataField: 'model',
          },
          {
            headerText: '제품명',
            dataField: 'modelName',
          },
          {
            headerText: '바코드',
            dataField: 'barcode',
          },
          {
            headerText: '재고유형',
            dataField: 'availableTypeKr',
          },
        ],
      },
      {
        headerText: '수량',
        children: [
          {
            headerText: '전산수량',
            dataField: 'quantity',
            styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
              if (item?.diffQuantity < 0) {
                return 'text-color-blue';
              } else if (item?.diffQuantity > 0) {
                return 'text-color-red';
              } else {
                return 'text-color-black';
              }
            },
          },
          {
            headerText: '실사수량',
            dataField: 'scanQuantity',
            styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
              if (item?.diffQuantity < 0) {
                return 'text-color-blue';
              } else if (item?.diffQuantity > 0) {
                return 'text-color-red';
              } else {
                return 'text-color-black';
              }
            },
          },
          {
            headerText: '차이수량',
            dataField: 'diffQuantity',
            styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
              if (item?.diffQuantity < 0) {
                return 'text-color-blue';
              } else if (item?.diffQuantity > 0) {
                return 'text-color-red';
              } else {
                return 'text-color-black';
              }
            },
          },
        ],
      },
      {
        headerText: '로케이션<br/>시작일시',
        dataField: 'startDatetime',
      },
      {
        headerText: '로케이션<br/>마감일시',
        dataField: 'closeDatetime',
      },
    ],
    SERIAL: [
      {
        headerText: '시리얼번호',
        dataField: 'serialNumber',
      },
      {
        headerText: '작업자',
        dataField: 'userId',
      },
      {
        headerText: '전산정보',
        children: [
          {
            headerText: '판매사',
            dataField: 'partnerSeqKr',
          },
          {
            headerText: '제품',
            dataField: 'model',
          },
          {
            headerText: '제품명',
            dataField: 'modelName',
          },
          {
            headerText: '바코드',
            dataField: 'barcode',
          },
          {
            headerText: '재고유형',
            dataField: 'availableTypeKr',
          },
          {
            headerText: '로케이션',
            dataField: 'locationCode',
          },
        ],
      },
      {
        headerText: '실사정보',
        children: [
          {
            headerText: '판매사',
            dataField: 'scanPartnerSeqKr',
          },
          {
            headerText: '제품',
            dataField: 'scanModel',
          },
          {
            headerText: '제품명',
            dataField: 'scanModelName',
          },
          {
            headerText: '바코드',
            dataField: 'scanBarcode',
          },
          {
            headerText: '재고유형',
            dataField: 'scanAvailableTypeKr',
          },
          {
            headerText: '로케이션',
            dataField: 'scanLocationCode',
          },
        ],
      },
      {
        headerText: '로케이션<br/>마감일시',
        dataField: 'closeDatetime',
      },
    ],
  };

  const labellingKr = (data: PagingListDTO<InventoryInvestigationDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        statusKr: MASTER_OBJ?.INV_STATUS?.[row.status],
        serialQuantityKr: `${row.serialQuantity}(${row.serialNewQuantity}/${row.serialUpdateQuantity})`,
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryInvestigationSearchDTO) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore.warehouseAction(`inventory/investigate/paging`, 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const excelAPIConvertor = {
    INVENTORYINVESTIGATE_DETAIL_EXCEL_MAIN: 'inventory/investigate/excel',
    INVENTORYINVESTIGATE_DETAIL_EXCEL_QUANTITY: 'inventory/investigate/detail/excel',
    INVENTORYINVESTIGATE_DETAIL_EXCEL_SERIAL: 'inventory/investigate/serial/excel',
  };

  const downloadExcel = async (id) => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: (await serviceStore.warehouseAction(excelAPIConvertor[id], 'GET', searchObj, null, true))?.data }, true);
    excelGridRef?.current?.changeColumnLayout(id?.includes('MAIN') ? columns : detailColumns[id?.split('_')[3]]);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `재고조사상세(${id?.includes('MAIN') ? '메인' : id?.includes('QUANTITY') ? '수량' : '시리얼'})` });
    dispatch(setLoading(null));
  };

  const simpleApi = async (id, items) => {
    if (id?.includes('CLOSE') && items?.[0]?.status !== 'CLOSE') {
      alert('마감된 재고조사만 재고반영이 가능합니다.');
    } else {
      const inventoryInvestigationSeq = items?.[0]?.inventoryInvestigationSeq;
      if (window.confirm(`선택된 건을 ${id?.includes('CLOSE') ? '마감' : '취소'}하시겠습니까?`)) {
        const rs = await serviceStore.warehouseAction(id?.includes('CLOSE') ? 'inventory/investigate/close' : 'inventory/investigate/cancel', 'POST', { inventoryInvestigationSeq }, null);
        if (rs?.status === 200) {
          alert('처리되었습니다!');
          wrappedFetchList(searchObj);
        }
      }
    }
  };

  const useNewDesignFlag = JSON.parse(localStorage.getItem('useNewDesignFlag'));
  const handleCheckItems = (id) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (items?.length > 1) {
        alert('한 건씩 가능합니다!');
      } else {
        if (id === 'INVENTORYINVESTIGATE_DETAIL_APPLY') {
          useNewDesignFlag === 1
            ? navigate(`/main/inventoryInvestigate/investigateApplyRegit?sq=${items[0]?.inventoryInvestigationSeq}`)
            : window.open(`/investigateApplyRegit?sq=${items[0]?.inventoryInvestigationSeq}`);
        } else if (id === 'INVENTORYINVESTIGATE_DETAIL_CANCEL' || id === 'INVENTORYINVESTIGATE_DETAIL_CLOSE') {
          simpleApi(id, items);
        }
      }
    } else {
      alert('선택된 건이 없습니다!');
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    console.log(id);
    if (id?.includes('EXCEL')) {
      downloadExcel(id);
    } else if (id === 'INVENTORYINVESTIGATE_DETAIL_CREATE') {
      useNewDesignFlag === 1 ? navigate(`/main/inventoryInvestigate/investigateRegit`) : window.open(`/investigateRegit`);
    } else {
      handleCheckItems(id);
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      {printObj?.visible && <InvestigatePrint printObj={printObj} setPrintObj={setPrintObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 2,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
