import { ReactNode, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

// service
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ParcelInvoiceDTO, ParcelInvoiceFeeListDTO, ParcelInvoiceFeeSearchDTO } from '../../../_interface/parcel';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
// component
import { PagingListDTO } from 'interface/util';
import { serviceStore } from 'services/services';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { getDateFromToday } from 'common/util/dateParsingFn';
import { makeObjtoFooter } from 'common/util/grid';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';

export const defaultOrderDetailObj = {
  visible: false,
  item: null,
};

export const transTabIdTo = {
  PARCEL_FEE_DAILY: 'collectDate',
  PARCEL_FEE_SELLER: 'partner',
  PARCEL_FEE_RETURN: 'return',
  PARCEL_FEE_SALESCHANNEL: 'salesChannel',
};

export const transTabIdToDetailSearch = {
  PARCEL_FEE_DAILY: 'collectDate',
  PARCEL_FEE_SELLER: 'partner',
  PARCEL_FEE_RETURN: 'collectDate',
  PARCEL_FEE_SALESCHANNEL: 'salesChannel',
};

export const transTabIdToTitle = {
  PARCEL_FEE_DAILY: '운임정산(일자별)',
  PARCEL_FEE_SELLER: '운임정산(판매사별)',
  PARCEL_FEE_RETURN: '운임정산(반송)',
  PARCEL_FEE_SALESCHANNEL: '운임정산(판매채널별)',
};

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<AUIGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();

  const labellingKr = (data: PagingListDTO<ParcelInvoiceFeeListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_FORWARDER_WHOLE?.[row?.partnerSeq],
        salesChannelSeqKr: MASTER_OBJ?.SALES_CHANNEL?.[row?.salesChannelSeq],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const _searchObj = JSON.parse(e.item.searchObj);
    let searchDTO = {
      creditLoanSeq: e.item?.creditLoanSeq,
      fromCollectDate: _searchObj.fromCollectDate,
      toCollectDate: _searchObj.toCollectDate,
      pageNo: 1,
      pageSize: 500,
    };
    if (tabId === 'PARCEL_FEE_SELLER') searchDTO['partnerSeq'] = e.item?.partnerSeq;
    else if (tabId === 'PARCEL_FEE_SALESCHANNEL') {
      searchDTO['salesChannelSeq'] = e.item?.salesChannelSeq;
      searchDTO['partnerSeq'] = e.item?.partnerSeq;
    } else {
      searchDTO['collectDate'] = e.item?.collectDate;
    }

    const data = (await serviceStore.parcelAction(`fee/${transTabIdTo[tabId]}/detail`, 'GET', searchDTO, null, true))?.data?.list;
    if (data?.length > 0) {
      const rows = data?.map((row) => {
        return {
          ...row,
          payTypeKr: MASTER_OBJ?.PARCEL_PAY_TYPE?.[row?.payType],
        };
      });

      setBottomSheetObj({
        visible: true,
        hasTabs: [
          {
            title: transTabIdToTitle[tabId],
            isPaging: true,
            gridRef: bottomGridRef,
            columns: detailColumns,
            rows,
            buttons: [],
            options: {
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              pageRowCount: 500,
            },
          },
        ],
      });
    } else {
      alert('상세내역이 없습니다');
    }
    dispatch(setLoading(null));
  };

  const columns: { [key: string]: IGrid.Column[] } = {
    PARCEL_FEE_DAILY: [
      {
        headerText: '상세보기',
        renderer: {
          type: IGrid.RendererKind.ButtonRenderer,
          labelText: '상세보기',
          onClick: fetchDetail,
        },
      },
      {
        headerText: '집화일자',
        dataField: 'collectDate',
      },
      {
        headerText: '건수',
        dataField: 'totalQuantity',
      },
      {
        headerText: '박스수량',
        dataField: 'totalBoxQuantity',
      },
      {
        headerText: '운임합계',
        dataField: 'totalFee',
        dataType: 'numeric',
      },
      {
        headerText: '기본운임',
        dataField: 'totalPrice',
        dataType: 'numeric',
      },
      {
        headerText: '별도운임',
        dataField: 'totalExtraFee',
        dataType: 'numeric',
      },
    ],
    PARCEL_FEE_SELLER: [
      {
        headerText: '상세보기',
        renderer: {
          type: IGrid.RendererKind.ButtonRenderer,
          labelText: '상세보기',
          onClick: fetchDetail,
        },
      },
      {
        headerText: '판매사코드',
        dataField: 'partnerSeq',
      },
      {
        headerText: '판매사명',
        dataField: 'partnerSeqKr',
      },
      {
        headerText: '박스수량',
        dataField: 'totalBoxQuantity',
      },
      {
        headerText: '운임합계',
        dataField: 'totalFee',
        dataType: 'numeric',
      },
      {
        headerText: '기본운임',
        dataField: 'totalPrice',
        dataType: 'numeric',
      },
      {
        headerText: '별도운임',
        dataField: 'totalExtraFee',
        dataType: 'numeric',
      },
    ],
    PARCEL_FEE_RETURN: [
      {
        headerText: '상세보기',
        renderer: {
          type: IGrid.RendererKind.ButtonRenderer,
          labelText: '상세보기',
          onClick: fetchDetail,
        },
      },
      {
        headerText: '집화일자',
        dataField: 'collectDate',
      },
      {
        headerText: '건수',
        dataField: 'totalQuantity',
      },
      {
        headerText: '박스수량',
        dataField: 'totalBoxQuantity',
      },
      {
        headerText: '운임합계',
        dataField: 'totalFee',
        dataType: 'numeric',
      },
      {
        headerText: '기본운임',
        dataField: 'totalPrice',
        dataType: 'numeric',
      },
      {
        headerText: '별도운임',
        dataField: 'totalExtraFee',
        dataType: 'numeric',
      },
    ],
    PARCEL_FEE_SALESCHANNEL: [
      {
        headerText: '상세보기',
        renderer: {
          type: IGrid.RendererKind.ButtonRenderer,
          labelText: '상세보기',
          onClick: fetchDetail,
        },
      },
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
      },
      {
        headerText: '판매채널명',
        dataField: 'salesChannelSeqKr',
      },
      {
        headerText: '건수',
        dataField: 'totalQuantity',
      },
      {
        headerText: '박스수량',
        dataField: 'totalBoxQuantity',
      },
      {
        headerText: '운임합계',
        dataField: 'totalFee',
        dataType: 'numeric',
      },
      {
        headerText: '기본운임',
        dataField: 'totalPrice',
        dataType: 'numeric',
      },
      {
        headerText: '별도운임',
        dataField: 'totalExtraFee',
        dataType: 'numeric',
      },
    ],
  };

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '집화일자',
      dataField: 'collectDate',
    },
    {
      headerText: '작업구분',
      dataField: 'workType',
    },
    {
      headerText: '운송장번호',
      dataField: 'parcelInvoiceNumber',
    },
    {
      headerText: '송하인명',
      dataField: 'senderName',
    },
    {
      headerText: '수하인명',
      dataField: 'receiverName',
    },
    {
      headerText: '박스수량',
      dataField: 'totalBoxQuantity',
    },
    {
      headerText: '운임구분',
      dataField: 'payTypeKr',
    },
    {
      headerText: '운임합계',
      dataField: 'totalFee',
      dataType: 'numeric',
    },
    {
      headerText: '기본운임',
      dataField: 'price',
      dataType: 'numeric',
    },
    {
      headerText: '별도운임',
      dataField: 'extraFee',
      dataType: 'numeric',
    },
  ];
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();
  const fetchPaging = async (searchObj: ParcelInvoiceFeeSearchDTO) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore?.parcelAction(`fee/${transTabIdTo[tabId]}/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<ParcelInvoiceDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const fetchTotal = async (searchObj: ParcelInvoiceFeeSearchDTO) => {
    const total = (await serviceStore?.parcelAction(tabId === 'PARCEL_FEE_RETURN' ? `fee/return/quantity` : `fee/quantity`, 'GET', searchObj, null))?.data;
    return makeObjtoFooter(total);
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 500,
      fromCollectDate: getDateFromToday(-1),
      toCollectDate: getDateFromToday(-1),
    },
    gridRef,
    fetchTotal,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));

    const { list } = labellingKr(
      {
        list: (await serviceStore.parcelAction(`fee/${transTabIdToDetailSearch[tabId]}/${type === 'main' ? '' : 'detail/'}excel`, 'GET', searchObj, null))?.data as ParcelInvoiceDTO[],
      },
      true,
    );

    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: transTabIdToTitle[tabId] });
    } else {
      excelDetailGridRef.current.setGridData(list);
      excelDetailGridRef.current.exportAsXlsx({ fileName: transTabIdToTitle[tabId] + '_상세' });
    }

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id?.includes('EXCELMAIN')) {
      downloadExcel('main');
    } else if (id?.includes('EXCELDETAIL')) {
      downloadExcel('detail');
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj, tabId]);

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox TARGET={tabId} searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}

        <GridBox
          gridRef={gridRef}
          columns={columns[tabId]}
          gridProps={{
            fixedColumnCount: 1,
            showFooter: true,
            footerPosition: 'top',
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns[tabId]} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={detailColumns} />
      </div>
    </div>
  );
};

export default Index;
