import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';

export interface IdefaultSearchObj {
  pageNo?: number;
  pageSize?: number;
  lastPage?: 1;
  [key: string]: any;
}

export const defaultSeachObj = {
  pageNo: 1,
  pageSize: 100,
};

export interface ISearchObj {
  [key: string]: any;
}

export interface ISearchProps {
  searchObj: ISearchObj;
  setSearchObj: React.Dispatch<React.SetStateAction<ISearchObj>>;
}

export interface ISearchBoxProps<T> {
  TARGET?: string;
  searchObj?: T;
  setSearchObj?: React.Dispatch<React.SetStateAction<T>>;
  handleSearchObj?: any;
  fetchCount?: any;
  fetchList: (searchObj: T) => void;
  MASTER_OBJ?: any;
  MASTER_OPS?: any;
}

export interface ISearchNodeMaker<T> {
  rawArr?: string[];
  INPUT?: string[];
  SELECT_SINGLE?: string[];
  SELECT_MULTI?: string[];
  searchObj?: T;
  setSearchObj?: React.Dispatch<React.SetStateAction<T>>;
  convertKr?: { [key: string]: string };
  convertMaster?: { [key: string]: string };
  btnNode?: ReactElement;
  colNumInRow?: number;
}

const useSearch = () => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const inputsMaker = ({ rawArr, INPUT, SELECT_SINGLE, SELECT_MULTI, searchObj, setSearchObj, convertKr, convertMaster, btnNode, colNumInRow = 6 }: ISearchNodeMaker<typeof searchObj>) => {
    const reactNodes =
      rawArr?.map((col: string, i) => {
        if (INPUT?.includes(col)) {
          return (
            <Col key={`${col}_${i}`}>
              <label className="col-form-label">{convertKr[col]}</label>
              <InputD
                defaultValue={searchObj?.[col] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [col]: e.target.value,
                  });
                }}
              />
            </Col>
          );
        } else if (SELECT_SINGLE?.includes(col)) {
          return (
            <Col key={`${col}_${i}`}>
              <label className="col-form-label">{convertKr[col]}</label>
              <SelectD
                options={MASTER_OPS?.[convertMaster[col]]}
                value={
                  searchObj?.[col]
                    ? {
                        label: selectlabel(searchObj?.[col], MASTER_OPS?.[convertMaster[col]]),
                        value: searchObj?.[col],
                      }
                    : null
                }
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      [col]: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
          );
        } else if (SELECT_MULTI?.includes(col)) {
          return (
            <Col key={`${col}_${i}`}>
              <label className="col-form-label">{convertKr[col]}</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.[convertMaster[col]]}
                value={searchObj?.[col]?.map((ele) => {
                  return {
                    value: ele,
                    label: selectlabel(ele, MASTER_OPS?.[convertMaster[col]]),
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    [col]: (option as OptionItem[]).map((ele) => ele.value),
                  });
                }}
              />
            </Col>
          );
        }
      }) || [];
    if (reactNodes?.length > 0) {
      if (reactNodes?.length % colNumInRow === 0) {
        new Array(reactNodes?.length > 0 ? colNumInRow - ((reactNodes?.length % colNumInRow) + (btnNode ? 1 : 0)) : reactNodes?.length - (btnNode ? 1 : 0)).fill(0).forEach((_) => {
          reactNodes.push(<Col />);
        });
        if (btnNode) reactNodes.push(btnNode);
      } else {
        if (btnNode) reactNodes.push(btnNode);
        const eleLength = reactNodes?.length;
        new Array(eleLength > 0 ? colNumInRow - (eleLength % colNumInRow) : eleLength).fill(0).forEach((_) => {
          reactNodes.push(<Col />);
        });
      }

      const nodeRow = new Array(reactNodes?.length / colNumInRow).fill(0).map((_, i) => {
        return (
          <Row key={`searchRow_${i}`}>
            {new Array(colNumInRow).fill(0).map((_, ci) => {
              return <React.Fragment key={`searchcol_${i}_${ci}`}>{reactNodes[i * colNumInRow + ci]}</React.Fragment>;
            })}
          </Row>
        );
      });
      return nodeRow;
    }
  };

  return { inputsMaker };
};

export default useSearch;
