import { useEffect, useRef, useState } from 'react';
import { Col, Row, Form } from 'reactstrap';
import Grid from 'components/grid/auiGrid';

// redux
import { useSelector } from 'react-redux';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { BottomSheet } from 'components/template/bottomSheet';
import PrintGridBox from 'common/grid/printGridBox';

const Presenter = (props) => {
  const { bottomSheetObj, setBottomSheetObj } = props;

  return (
    <>
      <div className="page-content" style={{ overflow: 'auto' }}>
        {bottomSheetObj?.visible && <BottomSheet bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const Search = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { searchObj, setSearchObj, setPagingSetting, pagingSetting, fetchList } = props;

  useEffect(() => {
    if (pagingSetting?.pageNo && pagingSetting?.fetchNew) {
      fetchList(searchObj);
    }
  }, [pagingSetting?.fetchNew]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      setPagingSetting((prev) => {
        return {
          ...prev,
          pageNo: 1,
          fetchNew: true,
        };
      });
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">송장번호/기사출고번호</label>
            <div style={{ display: 'flex' }}>
              <Col md={6} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    searchObj?.orderNumType
                      ? {
                          value: searchObj?.orderNumType,
                          label: selectlabel(searchObj?.orderNumType, masterOptions?.ORDER_TYPE),
                        }
                      : null
                  }
                  options={masterOptions?.ORDER_TYPE}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      orderNumType: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={6} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!searchObj?.orderNumType}
                  value={searchObj?.orderNum || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      orderNum: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">출고예정일</label>
            <FlatpickrD
              options={{
                mode: 'single', // default range
              }}
              onReset={() => {
                setSearchObj({
                  ...searchObj,
                  loanDate: null,
                });
              }}
              value={searchObj?.loanDate}
              onChange={(date) => {
                setSearchObj({
                  ...searchObj,
                  loanDate: returnDateyyyymmdd(date[0]),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">창고</label>
            <SelectD
              options={masterOptions?.CENTER}
              value={
                searchObj?.centerCode
                  ? {
                      value: searchObj?.centerCode,
                      label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">창고타입</label>
            <SelectD
              options={masterOptions?.WH_TYPE}
              value={
                searchObj?.centerType
                  ? {
                      value: searchObj?.centerType,
                      label: selectlabel(searchObj?.centerType, masterOptions?.WH_TYPE),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  centerType: value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">기사</label>
            <div style={{ display: 'flex' }}>
              <Col md={6} style={{ display: 'inline-block' }}>
                <SelectD
                  options={masterOptions?.DRIVER_NAME}
                  value={
                    searchObj?.userId
                      ? {
                          value: searchObj?.userId,
                          label: selectlabel(searchObj?.userId, masterOptions?.DRIVER_NAME),
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      userId: value,
                    });
                  }}
                />
              </Col>
              <Col md={6} style={{ display: 'inline-block' }}>
                <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">진행상태</label>
            <SelectD
              options={masterOptions?.LOAN_STATUS}
              value={
                searchObj?.status
                  ? {
                      value: searchObj?.status,
                      label: selectlabel(searchObj?.status, masterOptions?.LOAN_STATUS),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  status: value,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: 'right' }}>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();

                setPagingSetting((prev) => {
                  return {
                    ...prev,
                    pageNo: 1,
                    fetchNew: true,
                  };
                });
              }}
            >
              조회
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const GridBox = (props) => {
  const { rows = [], gridId, columns = [], detailColumns = [], onCellClick, pagingSetting, setPagingSetting, downloadExcel, getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid } = props;

  const excelGridRef = useRef();
  const excelDetailGridRef = useRef();
  const excelDownClicked = async (type) => {
    const data = (await downloadExcel(type)) || [];
    if (data.length > 0) {
      if (type === 'main') {
        excelGridRef.current.setGridData(data);
        excelGridRef.current.exportAsXlsx({ fileName: '기사출고(메인)' });
      } else {
        excelDetailGridRef.current.setGridData(data);
        excelDetailGridRef.current.exportAsXlsx({ fileName: '기사출고(상세)' });
      }
    } else {
      alert('조회되는 값이 없습니다!');
    }
  };

  return (
    <>
      <div className="grid-button-area space-between">
        <div>
          <div
            className="orange"
            onClick={() => {
              setGetCheckedDataTriggerThisGrid({
                type: 'confirm-loan',
                id: `#${gridId}`,
              });
            }}
          >
            출고지시 확정
          </div>
          <div
            className="red"
            onClick={() => {
              setGetCheckedDataTriggerThisGrid({
                type: 'save-lite-loan',
                id: `#${gridId}`,
              });
            }}
          >
            재고차감
          </div>
        </div>
        <div>
          <div
            className="green"
            onClick={() => {
              excelDownClicked('main');
            }}
          >
            엑셀다운
          </div>
          <div
            className="green"
            onClick={() => {
              excelDownClicked('detail');
            }}
          >
            엑셀다운(상세)
          </div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        {gridId && (
          <>
            <Grid
              id={gridId}
              rows={rows}
              columns={columns}
              // custompaging **

              pagingSetting={pagingSetting}
              setPagingSetting={setPagingSetting}
              settingOptions={{
                usePaging: true,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
                fixedColumnCount: 3,
              }}
              onCellClick={onCellClick}
              getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
              setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            />
            <PrintGridBox gridRef={excelGridRef} columns={columns} />
            <PrintGridBox gridRef={excelDetailGridRef} columns={detailColumns} />
          </>
        )}
      </div>
    </>
  );
};
