// styled
import { returnDateyyyymmddHhmmssNotab } from 'common/util/dateParsingFn';
import CommonModalNew from 'components/modal/commonModalNew';
import html2canvas from 'html2canvas';
import { InventoryModelMoveDTO } from 'interface/warehouse';
import jsPDF from 'jspdf';
import { useRef } from 'react';
import styled from 'styled-components';

const PrintBarcode180100 = styled.div({
  padding: '0 10px',
  paddingRight: 50,
  display: ' flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  height: '100mm',
  width: '180mm',
  border: '1px solid #d0d0d0',
});

export interface IlabelObj {
  visible: boolean;
  data: Array<InventoryModelMoveDTO>;
}

interface IlabelPopup {
  labelObj: IlabelObj;
  setLabelObj: React.Dispatch<React.SetStateAction<IlabelObj>>;
}

export const LabelPopup = ({ labelObj, setLabelObj }: IlabelPopup) => {
  const wrapperRef = useRef<HTMLDivElement>();

  const downloadPdf = async (receiptWrapper: HTMLDivElement) => {
    const barcodeArr = labelObj?.data?.length;
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: [180, 100],
    });
    // 다운로드 로직 구현
    const options = {
      scale: 1, // 스케일 값을 조정하여 비율을 변경합니다. 0.5는 50%의 비율을 나타냅니다.
    };
    const canvas = await html2canvas(receiptWrapper, options);
    let positionY = 0;
    let positionX = 0;

    let imgData = canvas.toDataURL('image/jpeg', 1.0);
    let imgWidth = 180; // 이미지 가로 길이(mm) A4 기준
    let pageHeight = 100; // 출력 페이지 세로 길이 계산 A4 기준
    let imgHeight = barcodeArr * pageHeight;

    let heightLeft = imgHeight;

    // 첫 페이지 출력
    doc.addImage(imgData, 'PNG', positionX, positionY, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // 한 페이지 이상일 경우 루프 돌면서 출력
    while (heightLeft >= pageHeight) {
      positionY = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, 'PNG', positionX, positionY, imgWidth, imgHeight);
      heightLeft = heightLeft - pageHeight;
    }

    // PDF로 저장하기
    // [다른 이름으로 저장] 창이 뜨며 defalut값으로 지정한 이름이 입력된다.
    doc.save(`barcode8028_${returnDateyyyymmddHhmmssNotab(new Date(), '')}`);
  };

  return (
    <CommonModalNew
      style={{ width: 770 }}
      visible={labelObj?.visible}
      setVisible={() => {
        setLabelObj((prev) => {
          return {
            ...prev,
            data: null,
            visible: false,
          };
        });
      }}
      children={
        <>
          <div style={{ textAlign: 'right', marginBottom: 10 }}>
            <button
              className="btn btn-green"
              onClick={() => {
                downloadPdf(wrapperRef.current);
              }}
            >
              pdf다운로드
            </button>
          </div>
          <div ref={wrapperRef}>
            {labelObj?.data?.map((ele, index) => {
              return <PrintBarcode180100 key={`barcode_${index}`} id={`barcode_${index}`}></PrintBarcode180100>;
            })}
          </div>
        </>
      }
    />
  );
};
