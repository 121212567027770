import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { TransportStatementSearchDTOForUnload } from '../../../../_interface/warehouse';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = (props: ISearchBoxProps<TransportStatementSearchDTOForUnload>) => {
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE: null,
    SEARCH_SEQ: null,
    ORDER_SEQ: null,
    SEARCH_MODEL: null,
  });

  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SEQ, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.ORDER_SEQ, searchObj));
  }, [selectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">기간검색</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_DATE
                    ? {
                        value: selectTypeObj?.SEARCH_DATE,
                        label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_DATE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_DATE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <FlatpickrD
                disabled={!selectTypeObj?.SEARCH_DATE}
                onClose={(option) => {
                  if (option?.length === 1)
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                      [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                    });
                }}
                value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                    [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">번호검색</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_SEQ
                    ? {
                        value: selectTypeObj?.SEARCH_SEQ,
                        label: MASTER_OBJ?.SEARCH_SEQ?.[selectTypeObj?.SEARCH_SEQ],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_SEQ}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_SEQ: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_SEQ}
                value={searchObj?.[selectTypeObj?.SEARCH_SEQ] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_SEQ]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">출발창고</label>
          <SelectD
            value={
              searchObj?.originalCenterCode
                ? {
                    value: searchObj?.originalCenterCode,
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.originalCenterCode],
                  }
                : null
            }
            options={MASTER_OPS?.CENTER_AUTH}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                originalCenterCode: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">도착창고</label>
          <SelectD
            value={
              searchObj?.destinationCenterCode
                ? {
                    value: searchObj?.destinationCenterCode,
                    label: MASTER_OBJ?.CENTER_DIFF?.[searchObj?.destinationCenterCode],
                  }
                : null
            }
            options={MASTER_OPS?.CENTER_DIFF}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                destinationCenterCode: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">간선유형</label>
          <SelectD
            value={
              searchObj?.linehaulType
                ? {
                    value: searchObj?.linehaulType,
                    label: MASTER_OBJ?.LINEHAUL_TYPE?.[searchObj?.linehaulType],
                  }
                : null
            }
            options={MASTER_OPS?.LINEHAUL_TYPE}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                linehaulType: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">진행상태</label>
          <Row>
            <SelectD
              value={
                searchObj?.status
                  ? {
                      value: searchObj?.status,
                      label: MASTER_OBJ?.TRANSPORT_STAT?.[searchObj?.status],
                    }
                  : null
              }
              options={MASTER_OPS?.TRANSPORT_STAT}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  status: (option as OptionItem).value,
                });
              }}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">주문번호/송장번호</label>
          {/* orderSeq */}
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.ORDER_SEQ
                    ? {
                        value: selectTypeObj?.ORDER_SEQ,
                        label: MASTER_OBJ?.ORDER_SEQ?.[selectTypeObj?.ORDER_SEQ],
                      }
                    : null
                }
                options={MASTER_OPS?.ORDER_SEQ}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    ORDER_SEQ: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.ORDER_SEQ}
                value={searchObj?.[selectTypeObj?.ORDER_SEQ] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.ORDER_SEQ]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">제품/제품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">유사검색여부</label>
          {/* similaritySearchFlag */}
          <div className="radio-select-area">
            <span
              className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: true,
                  };
                });
              }}
            >
              유사검색
            </span>
            <span
              className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: false,
                  };
                });
              }}
            >
              일치검색
            </span>
          </div>
        </Col>
        <Col>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
