import LoginPage from 'components/LDS/pages/Login/Index';
import RootPage from 'components/LDS/pages/root';
import { createBrowserRouter } from 'react-router-dom';
import { generateRoutingPathMap, MasterOptionWrapper } from './mainRouterMap/routingPathMap';
import POrderTracking from 'pages/DELIVERY/2delivery/morderTrackingForParcel';
import Logis from 'pages/DELIVERY/2delivery/morderTrackingForInnos';
import OrderTracking3 from 'pages/DELIVERY/2delivery/orderTrackingTab';

import { mainUrl } from 'components/LDS/utils/path';

import styled from 'styled-components';
import Loader from 'components/LDS/modules/Loader';
import { useEffect, useState } from 'react';
import LDSError from 'components/LDS/layouts/Error';
import LDSNotFound from 'components/LDS/layouts/NotFound';
import { VALUES } from 'common/constants/appearance';

const authLoaderFunc = () => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) return { hasAuth: true };
  return { hasAuth: false };
};

const localStorageTabs = localStorage.getItem('tabs');
const localStorageFetchMenus = localStorage.getItem('fetchMenus');
const localStorageFlatMenus = localStorage.getItem('flatMenus');

const new_router = createBrowserRouter([
  {
    path: '/',
    loader: authLoaderFunc,
    element: <RootPage />,
    children: [
      {
        path: mainUrl,
        element: <MasterOptionWrapper />,
        children: generateRoutingPathMap(),
      },
      {
        path: '*',
        element: localStorageTabs && localStorageFetchMenus && localStorageFlatMenus ? <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} /> : <NavigateBuffer />,
      },
    ],
  },
  //NOTE: 외부에서 접근이 필요한 페이지의 경우 (인증을 거치지 않아도 되는) /main 하위가 아닌 root 하위에 별도로 지정
  {
    path: '/logistics',
    element: <POrderTracking />,
  },
  {
    path: '/logis',
    element: <Logis />,
  },
  {
    path: '/mordertracking3',
    element: <OrderTracking3 />,
  },
  {
    path: '/login',
    loader: authLoaderFunc,
    element: <LoginPage />,
  },
  {
    path: '*',
    element: <>404</>,
  },
]);

export { new_router };

export function NavigateBuffer({ children }: { children?: React.ReactElement }) {
  //NOTE: tabs, fetchMenus, flatMenus 값이 로컬 스토리지에 존재 하는 지 검증 후 root로 redirect 시키도록 수정
  //NOTE: 만약 5번의 시도후에도 정상 데이터를 받지 못한 경우 에러 화면을 띄우고, 모든 스토리지 삭제 후 root로 갈 수 있도록 구현
  const tabs = localStorage.getItem('tabs');
  const fetchMenus = localStorage.getItem('fetchMenus');
  const flatMenus = localStorage.getItem('flatMenus');
  const [resetCount, setResetCount] = useState(0);
  const maxResetCount = 20;

  useEffect(() => {
    const interval = setTimeout(() => {
      if (tabs && fetchMenus && flatMenus) window.location.href = '/';
      else setResetCount(resetCount + 1);
    }, VALUES.LOGIN_BUFFER_TIME);

    if (resetCount === maxResetCount) {
      clearTimeout(interval);
    }

    return () => clearTimeout(interval);
  }, [resetCount]);

  return (
    <MainLoadingWrapper>
      {children}
      {resetCount === maxResetCount ? <LDSError $buttonLabel={'재시도'} $link={'/main'} /> : <Loader loadingStatus={'PUT'} />}
    </MainLoadingWrapper>
  );
}

const MainLoadingWrapper = styled.div`
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

/* modules/Grid.tsx 마지막 부분

export const agUtils: {
  readonly version: string;
  readonly releaseDate: number;
  isCreated: () => boolean;
  formatDate: (date: string | Date, formatString: string) => string;
  formatNumber: (number: number, formatString: string, rouding?: 'rounding' | 'ceil' | 'floor') => string;
  getActiveGrid: () => string | null;
  getCreatedGridAll: () => string[];
  makeValueMasked: (mask: string, value: string) => string;
  makeValueUnmasked: (mask: string, value: string) => string;
} = {
  releaseDate: $ag?.releaseDate,
  version: $ag?.version,
  isCreated: $ag?.isCreated,
  formatDate: $ag?.formatDate,
  formatNumber: $ag?.formatNumber,
  getActiveGrid: $ag?.getActiveGrid,
  getCreatedGridAll: $ag?.getCreatedGridAll,
  makeValueMasked: $ag?.makeValueMasked,
  makeValueUnmasked: $ag?.makeValueUnmasked,
};

*/
