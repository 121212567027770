import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tab1 from './1leadtime';
import Tab2 from './2promise';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      //
      'DRIVER_AUTH',
      'DRIVER_WHOLE',
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      //
      'INVENTORY_LOCATION',
      'ORDER_TYPE',
      'FEE_TYPE',
      'INVOICE_STATUS',
      //
      {
        SEARCH_LEADTIME_DATE: [
          {
            label: '고객주문일',
            value: 'OrderDate',
          },
          {
            label: '등록일',
            value: 'RegisterDate',
          },
        ],
      },
      {
        SEARCH_PROMISE_DATE: [
          {
            label: '지정일',
            value: 'DeliveryDate',
          },
          {
            label: '희망일',
            value: 'PromiseDate',
          },
        ],
      },
    ];
    initMasterOptions(masterArr);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            KPI_LEADTIME: <Tab1 tabId={'KPI_LEADTIME'} />,
            KPI_PROMISEDATE: <Tab2 tabId={'KPI_PROMISEDATE'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
