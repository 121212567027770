import { ReactNode, useEffect, useState } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './liveSCM';
import Tab2 from './periodSCM';

// options
import { centerListForlabeling, codeMasterOptionReturn, driverListForLabeling, InfraOptionReturn, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { setLoading, setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { TopTab } from 'components/template/topTabNew';
import { useLocation } from 'react-router-dom';
import { TabDTO } from 'interface/user';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Index = () => {
  const location = useLocation();
  const [tabs, setTabs] = useState<TabDTO[]>();
  const [tabItems, setTabItems] = useState<{ titles?: string[]; containers: ReactNode[] }>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { useableTabs } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [
      DRIVER_WHOLE,
      SELLER,
      CENTER_SELECT_WHOLE,
      CENTER_WHOLE,
      INFRA,
      NETWORK_WHOLE,
      MODEL_GROUP,
      MODEL_TYPE,
      FEE_TYPE,
      LO_STATUS,
      CANCEL_REASON,
      DEFECTED_STAT,
      INVA_STATUS,
      INVA_TYPE,
      INVM_STATUS,
    ] = await Promise.all([
      driverListForLabeling(),
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling({ selectFlag: true }),
      centerListForlabeling(),
      InfraOptionReturn(),
      partnerListForlabeling({ networkFlag: true }),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
      codeMasterOptionReturn('FEE_TYPE'),
      codeMasterOptionReturn('LO_STATUS'),
      codeMasterOptionReturn('CANCEL_REASON'),
      codeMasterOptionReturn('DEFECTED_STAT'),
      codeMasterOptionReturn('INVA_STATUS'),
      codeMasterOptionReturn('INVA_TYPE'),
      codeMasterOptionReturn('INVM_STATUS'),
    ]);

    dispatch(
      setMasterOptions({
        DRIVER_WHOLE,
        DRIVER_NAME: DRIVER_WHOLE?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        SELLER: SELLER?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_AUTH: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        INFRA,
        NETWORK: NETWORK_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        MODEL_GROUP,
        MODEL_TYPE,
        FEE_TYPE,
        LO_STATUS,
        CANCEL_REASON,
        DEFECTED_STAT,
        INVA_STATUS,
        INVA_TYPE,
        INVM_STATUS,
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
        ],

        SEARCH_SKU: [
          { value: 'modelStockSeq', label: 'SKU' },
          { value: 'skuNumber', label: '외부SKU' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };

  const containerList = [
    { container: <Tab1 tabId={'SCM_LIVE'} />, tabId: 'SCM_LIVE' },
    { container: <Tab2 tabId={'SCM_PERIOD'} />, tabId: 'SCM_PERIOD' },
  ];

  useEffect(() => {
    if (tabs) {
      const tabIds = tabs?.map((ele) => ele.tabId);
      const tabLabeling = tabs?.map((ele) => {
        return {
          value: ele.tabId,
          label: ele.tabName,
        };
      });

      setTabItems({
        titles: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => selectlabel(ele.tabId, tabLabeling)),
        containers: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => ele.container),
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (useableTabs) {
      const tabDTO = useableTabs?.[location.pathname] ? [...useableTabs?.[location.pathname]] : [];
      if (tabDTO?.length > 0) {
        tabDTO?.sort((a: TabDTO, b: TabDTO) => a?.sort - b?.sort);
        setTabs(tabDTO);
        initMasterOptions();
      }
    }
  }, [useableTabs]);

  return <>{masterOptions && tabItems && <TopTab titles={tabItems?.titles} containers={tabItems?.containers} />}</>;
};

export default Index;
