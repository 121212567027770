import { ReactNode, useMemo, useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';

// utils
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
// service
import { InventoryInvestigationApplySearchDTO, InventoryInvestigationApplyDTO } from '../../../_interface/warehouse';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const columns: IGrid.Column[] = [
    {
      headerText: '작업지시번호',
      dataField: 'inventoryCheckNumber',
    },
    {
      headerText: '재고/시리얼번호',
      dataField: 'inventoryCheckNumber',
    },
    {
      headerText: '재고/시리얼',
      dataField: 'investigationTypeKr',
    },
    {
      headerText: '수불유형',
      dataField: 'applyTypeKr',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '재고유형',
          dataField: 'availableTypeKr',
        },
        {
          headerText: '로케이션',
          dataField: 'locationCode',
        },
      ],
    },
    {
      headerText: '전기일',
      dataField: 'postingDate',
    },
    {
      headerText: '반영일',
      dataField: 'applyDate',
    },
    {
      headerText: '비고',
      dataField: 'remark',
    },
  ];

  const labellingKr = (data: PagingListDTO<InventoryInvestigationApplyDTO & { partnerSeq: number }>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        applyTypeKr: MASTER_OBJ?.INVESTIGATION_APPLY_TYPE?.[row.applyType],
        availableTypeKr: MASTER_OBJ?.INVA_TYPE?.[row.availableType],
        investigationTypeKr: MASTER_OBJ?.INVESTIGATION_TYPE?.[row.investigationType],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryInvestigationApplySearchDTO) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore.warehouseAction(`inventory/investigate/apply/paging`, 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      similaritySearchFlag: true,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async () => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: (await serviceStore.warehouseAction(`inventory/investigate/apply/excel`, 'GET', searchObj, null))?.data }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `재고조사반영` });
    dispatch(setLoading(null));
  };

  const handleCheckItems = (e) => {
    const id = e.target.id;
    if (id?.includes('EXCEL')) {
      downloadExcel();
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, handleCheckItems);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}

      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
