import { createRef, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { CSVLink } from 'react-csv';

import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

//redux
import { useSelector } from 'react-redux';

const Presenter = (props) => {
  return (
    <>
      <div className="page-content">
        <div className="presenterSearch">
          <Search {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const Search = (props) => {
  const { searchObj, setSearchObj, downloadExcel, excelRowsMain, excelheaders, categoryList } = props;
  const { masterOptions } = useSelector((state) => state.menu);

  const csvLinkMain = createRef();

  useEffect(() => {
    if (excelRowsMain?.length > 0) {
      console.log('@');
      csvLinkMain.current.link.click();
    }
  }, [excelRowsMain?.length]);

  const dateOption = [
    {
      label: '간선출고예정일',
      value: 'LinehaulDate',
    },
    {
      label: '발주입고예정일',
      value: 'PromiseDate',
    },
    {
      label: '발주입고확정일',
      value: 'FinishDate',
    },
    {
      label: '간선입고예정일',
      value: 'UnloadPromiseDate',
    },
    {
      label: '반출출고예정일',
      value: 'StockRemoveDate',
    },
    {
      label: '피킹지시확정일',
      value: 'ConfirmDate',
    },
    {
      label: '피킹완료일',
      value: 'PickDate',
    },
  ];

  return (
    <>
      <div className="inputTitle mb-4">기본검색자</div>
      <Row>
        <Col md={2}>
          <label className="col-form-label">카테고리</label>
          <Row>
            <SelectD
              options={categoryList}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  urlType: option.value,
                });
              }}
            />
          </Row>
        </Col>
        <Col md={4}>
          <label className="col-form-label">기간검색</label>
          <div style={{ display: 'flex' }}>
            <Col style={{ display: 'inline-block' }}>
              <SelectD
                options={dateOption}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    dateType: option.value,
                  });
                }}
              />
            </Col>
            <Col style={{ display: 'inline-block' }}>
              <FlatpickrD
                onReset={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      fromDate: null,
                      toDate: null,
                    };
                  });
                }}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    fromDate: returnDateyyyymmdd(option[0]),
                    toDate: returnDateyyyymmdd(option[1]),
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col />
        <Col style={{ textAlign: 'left' }}>
          <div className="grid-button-area">
            <div
              className="green"
              onDoubleClick={() => {
                return;
              }}
              onClick={() => downloadExcel('detail')}
            >
              <CSVLink headers={excelheaders} data={excelRowsMain || []} filename={selectlabel(searchObj.urlType, categoryList)} target="_blank" className="hidden" ref={csvLinkMain}></CSVLink>
              엑셀 다운로드
            </div>
          </div>
        </Col>
      </Row>
      <hr className="title-hr" />
      <div>간선검색자</div>
      <Row>
        <Col>
          <label className="col-form-label">피킹 지시번호</label>
          <InputD
            value={searchObj?.linehaulSeq || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                linehaulSeq: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">출고 지시번호</label>
          <InputD
            value={searchObj?.loadingStatementSeq || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                loadingStatementSeq: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">운송번호</label>
          <InputD
            value={searchObj?.transportStatementSeq || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                transportStatementSeq: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">출발창고</label>
          <SelectD
            value={
              searchObj?.originalCenterCod
                ? {
                    value: searchObj?.originalCenterCode,
                    label: selectlabel(searchObj?.originalCenterCode, masterOptions?.CENTER_LABEL),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                originalCenterCode: option.value,
              });
            }}
            options={masterOptions?.CENTER_LABEL}
          />
        </Col>
        <Col>
          <label className="col-form-label">도착창고</label>
          <SelectD
            value={
              searchObj?.destinationCenterCode
                ? {
                    value: searchObj?.destinationCenterCode,
                    label: selectlabel(searchObj?.destinationCenterCode, masterOptions?.CENTER),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                destinationCenterCode: option.value,
              });
            }}
            options={masterOptions?.CENTER}
          />
        </Col>
        <Col>
          <label className="col-form-label">간선유형</label>
          <SelectD
            value={
              searchObj?.linehaulType
                ? {
                    value: searchObj?.linehaulType,
                    label: selectlabel(searchObj?.linehaulType, masterOptions?.LINEHAUL_TYPE),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                linehaulType: option.value,
              });
            }}
            options={masterOptions?.LINEHAUL_TYPE}
          />
        </Col>
        <Col>
          <label className="col-form-label">진행상태</label>
          <SelectD
            value={
              searchObj?.status
                ? {
                    value: searchObj?.status,
                    label: selectlabel(searchObj?.status, masterOptions?.LINE_STAT),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                status: option.value,
              });
            }}
            options={masterOptions?.LINE_STAT}
          />
        </Col>
      </Row>
      <hr className="title-hr" />
      <div>반출검색자</div>
      <Row>
        <Col>
          <label className="col-form-label">피킹 지시번호</label>
          <InputD
            value={searchObj?.linehaulSeq2 || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                linehaulSeq2: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">출고 지시번호</label>
          <InputD
            value={searchObj?.loadingStatementSeq2 || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                loadingStatementSeq2: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">운송번호</label>
          <InputD
            value={searchObj?.transportStatementSeq2 || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                transportStatementSeq2: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">반출창고</label>
          <SelectD
            value={
              searchObj?.originalCenterCodeTo
                ? {
                    value: searchObj?.originalCenterCodeTo,
                    label: selectlabel(searchObj?.originalCenterCodeTo, masterOptions?.CENTER),
                  }
                : null
            }
            onChange={(option) => {
              searchObj.urlType = '/warehouse/remove/picking/list'
                ? setSearchObj({
                    ...searchObj,
                    centerCodeTo: option.value,
                  })
                : setSearchObj({
                    ...searchObj,
                    originalCenterCodeTo: option.value,
                  });
            }}
            options={masterOptions?.CENTER}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: option.value,
              });
            }}
            options={masterOptions?.SELLER}
          />
        </Col>
        <Col>
          <label className="col-form-label">반출유형</label>
          <SelectD
            value={
              searchObj?.stockRemoveType
                ? {
                    value: searchObj?.stockRemoveType,
                    label: selectlabel(searchObj?.stockRemoveType, masterOptions?.REMOVE_REASON),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                stockRemoveType: option.value,
              });
            }}
            options={masterOptions?.REMOVE_REASON}
          />
        </Col>
        <Col>
          <label className="col-form-label">진행상태</label>
          <SelectD
            value={
              searchObj?.statusOut
                ? {
                    value: searchObj?.statusOut,
                    label: selectlabel(searchObj?.statusOut, masterOptions?.REMOVE_STATUS),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                statusOut: option.value,
              });
            }}
            options={masterOptions?.REMOVE_STATUS}
          />
        </Col>
      </Row>
      <hr className="title-hr" />
      <div>발주검색자</div>
      <Row>
        <Col>
          <label className="col-form-label">발주번호</label>
          <div style={{ display: 'flex' }}>
            <SelectD
              value={
                searchObj?.purchaseSeqType
                  ? {
                      value: searchObj?.purchaseSeqType,
                      label: selectlabel(searchObj?.purchaseSeqType, masterOptions?.PURCHASESEQ_TYPE),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  purchaseSeqType: option.value,
                });
              }}
              options={masterOptions?.PURCHASESEQ_TYPE}
            />
            <InputD
              disabled={!searchObj?.purchaseSeqType}
              value={searchObj?.purchaseSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  purchaseSeq: e.target.value,
                });
              }}
            />
          </div>
        </Col>
        <Col>
          <label className="col-form-label">위탁사</label>
          <SelectD
            value={
              searchObj?.networkPartnerSeq
                ? {
                    value: searchObj?.networkPartnerSeq,
                    label: selectlabel(searchObj?.networkPartnerSeq, masterOptions?.NETWORK),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                networkPartnerSeq: option.value,
              });
            }}
            options={masterOptions?.NETWORK}
          />
        </Col>
        <Col>
          <label className="col-form-label">입고창고</label>
          <SelectD
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: option.value,
              });
            }}
            options={masterOptions?.CENTER}
          />
        </Col>
        <Col />
        <Col />
        <Col />
        <Col />
      </Row>
    </>
  );
};
