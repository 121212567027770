import CommonModalNew from 'components/modal/commonModalNew';
import { InvoiceInventoryLocationDTO } from '../../_interface/order';

export interface IInventoryLocationObj {
  visible?: boolean;
  data?: InvoiceInventoryLocationDTO[];
}

export const InventoryLocation = ({ inventoryLocationObj, setInventoryLocationObj }) => {
  return (
    <CommonModalNew
      title="재고위치"
      style={{ width: 1200 }}
      visible={inventoryLocationObj?.visible}
      setVisible={() => {
        setInventoryLocationObj(null);
      }}
      children={
        <>
          <table className="border-table" style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th colSpan={4}>제품명</th>
                <th>
                  간선/반출피킹
                  <br />
                  지시번호
                </th>
                <th>
                  간선/반출출고
                  <br />
                  지시번호
                </th>
                <th>운송번호</th>
                <th>
                  FC피킹
                  <br />
                  지시번호
                </th>
                <th>기사출고번호</th>
                <th>기사회수번호</th>
                <th colSpan={2}>재고위치</th>
              </tr>
            </thead>
            <tbody>
              {inventoryLocationObj?.data?.map((ele, idx) => {
                return (
                  <tr key={`inventory_location_${idx}`}>
                    <td colSpan={4}>{ele.modelName}</td>
                    <td>{ele.linehaulSeq || ele.stockRemoveSeq}</td>
                    <td>{ele.loadingStatementSeq}</td>
                    <td>{ele.transportStatementSeq}</td>
                    <td>{ele.loanPickSeq}</td>
                    <td>{ele.loanSeq}</td>
                    <td>{ele.collectSeq}</td>
                    <td colSpan={2}>{ele.inventoryLocation}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      }
    />
  );
};
