import * as IGrid from 'aui-grid';
export const columns: IGrid.Column[] = [
  {
    headerText: '제품',
    dataField: 'model',
  },
  {
    headerText: '제품명',
    dataField: 'modelName',
  },
  {
    headerText: '바코드',
    dataField: 'barcode',
  },
  {
    headerText: '출고창고',
    dataField: 'centerCodeKr',
  },
  {
    headerText: 'CBM',
    dataField: 'totalBoxVolume',
  },
  {
    headerText: '금액정보(개당)',
    children: [
      {
        headerText: '공급가액',
        dataField: 'discountedSellingPrice',
        formatString: '#,##0',
        editRenderer: {
          type: IGrid.EditRendererKind.InputEditRenderer,
          autoThousandSeparator: true,
          onlyNumeric: true,
        },
      },
      {
        headerText: '부가세',
        dataField: 'discountedSurtax',
        formatString: '#,##0',
        editRenderer: {
          type: IGrid.EditRendererKind.InputEditRenderer,
          autoThousandSeparator: true,
          onlyNumeric: true,
        },
      },
      {
        headerText: '단가(VAT포함)',
        dataField: 'discountedTotalPrice',
        formatString: '#,##0',
        editRenderer: {
          type: IGrid.EditRendererKind.InputEditRenderer,
          autoThousandSeparator: true,
          onlyNumeric: true,
        },
      },
    ],
  },
];
