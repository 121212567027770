import { useState, useEffect } from 'react';
// redux
import { useSelector } from 'react-redux';

// component
import Container1 from './manageorderRegister';
import { DetailTopTab, IDetailTopTab } from 'components/template/detailTopTab';

// options
import { RootState } from 'redux/store';
import { useLocation } from 'react-router-dom';
import { getLanguangeInlocalStorage, saveLanguangeInlocalStorage } from 'common/util/lang';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { ORDER_MASTER } from '../orderListTab';

const Index = () => {
  const [tabObj, setTabObj] = useState<IDetailTopTab>();
  const location = useLocation();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { locale } = useSelector((state: RootState) => state.locale);

  useEffect(() => {
    if (MASTER_OBJ)
      setTabObj({
        titles: ['주문조회', `${location.search ? '주문불러오기(건별)' : '주문등록(건별)'}`],
        routers: ['/manageorderInfoList', '/manageorderRegister'],
        container: [<Container1 />],
      });
  }, [MASTER_OBJ]);

  useEffect(() => {
    if (locale.value) {
      const localLocale = getLanguangeInlocalStorage();
      if (localLocale !== locale.value || !MASTER_OBJ) {
        initMasters();
        saveLanguangeInlocalStorage(locale.value);
      }
    }
  }, [locale]);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(ORDER_MASTER);
  };
  return <>{tabObj && MASTER_OBJ && <DetailTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
