export const addingParamsToUrl = <T>(searchDTO: T) => {
  let add = '?';
  if (searchDTO) {
    Object.keys(searchDTO)?.forEach((key, idx) => {
      // 값이 있으면
      if (typeof searchDTO[key] === 'boolean') {
        if (!key?.includes('day')) add += `&${key}=${searchDTO[key]}`;
        if (key?.includes('Flag') && searchDTO[key] === true) add += `&${key}=${searchDTO[key]}`; //NOTE: Flag를 포함하는 key인 경우 값이 true일 때만 param에 더하도록 추가
      } else {
        if (searchDTO[key]) {
          if (Array.isArray(searchDTO[key]) && searchDTO[key]?.filter((ele) => ele && ele !== '')?.length > 0) {
            searchDTO[key]?.forEach((ele: string | number) => {
              if (ele) add += `&${key}=${encodeURIComponent(ele)}`;
            });
          } else if (!Array.isArray(searchDTO[key]) && searchDTO[key]) {
            add += `&${key}=${encodeURIComponent(searchDTO[key])}`;
          }
        }
      }
    });
    return add;
  }
  return '';
};

export const addingParamsToUrlexceptPaging = <T>(searchDTO: T) => {
  let add = '?';
  if (searchDTO) {
    Object.keys(searchDTO)?.forEach((key, idx) => {
      // 값이 있으면
      if (key !== 'pageNo' && key !== 'pageSize' && searchDTO[key] !== null) {
        if (typeof searchDTO[key] === 'boolean') {
          if (!key?.includes('day')) add += `&${key}=${searchDTO[key]}`;
        } else {
          if (Array.isArray(searchDTO[key]) && searchDTO[key]?.filter((ele) => ele && ele !== '')?.length > 0) {
            searchDTO[key]?.forEach((ele: string | number) => {
              if (ele) add += `&${key}=${ele}`;
            });
          } else if (!Array.isArray(searchDTO[key]) && searchDTO[key]) {
            add += `&${key}=${searchDTO[key]}`;
          }
        }
      }
    });
    return add;
  }
  return '';
};

export const eveningPostDTO = <T>(postDTO: T) => {
  let newObj = { ...postDTO };
  if (newObj) {
    Object.keys(newObj)?.forEach((key, idx) => {
      // 값이 있으면
      if (postDTO[key]) {
        if (typeof postDTO[key] === 'boolean') {
          // if (!key?.includes('day')) add += `&${key}=${postDTO[key]}`;
        } else {
          if (Array.isArray(postDTO[key]) && postDTO[key]?.filter((ele) => ele && ele !== '')?.length === 0) {
            delete newObj[key];
          } else if (!Array.isArray(postDTO[key]) && postDTO[key]) {
            // add += `&${key}=${postDTO[key]}`;
          }
        }
      }
      if (key !== 'totalCount' && (postDTO[key] === '' || postDTO[key] === null)) delete newObj[key];
    });
    return newObj;
  }
  return null;
};
