// react
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Barcode from 'react-barcode';

import ReactToPrint from 'react-to-print';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { InvoicePrintDTOForParcel } from '../../../_interface/order';
import { numberThousandComma } from 'common/util/counting';
import { InvoiceDTOForOrderSheetPrint } from '../../../_interface/warehouse';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { SelectD } from 'components/reactstrap/reactstrap';
import { getToday } from 'common/util/dateParsingFn';
import { serviceStore } from 'services/services';

// img
import ilyang from '../img/ilyang.png';

const PrintBarcode200102 = styled.div({
  position: 'relative',
  display: 'flex',
  paddingRight: 0,
  paddingLeft: 0,
  height: '102mm',
  maxHeight: '102mm',
  width: '200mm',
  fontSize: '3.5mm',
  marginBottom: 10,
  border: '1px solid lightgray',
});

const PrintBarcode100180 = styled.div({
  position: 'relative',
  display: 'flex',
  paddingRight: 0,
  paddingLeft: 0,
  height: '100mm',
  maxHeight: '100mm',
  width: '180mm',
  fontSize: '3.5mm',
  marginBottom: 10,
});

const PrintBarcode100123 = styled.div({
  position: 'relative',
  display: 'flex',
  paddingRight: 0,
  paddingLeft: 0,
  height: '100mm',
  maxHeight: '100mm',
  width: '123mm',
  fontSize: '3.5mm',
  marginBottom: 10,
  border: '1px solid lightgray',
});

const PrintBarcode100150 = styled.div({
  position: 'relative',
  display: 'flex',
  paddingRight: 0,
  paddingLeft: 0,
  height: '100mm',
  width: '150mm',
  fontSize: 12,
  fontWeight: 500,
  marginBottom: 10,
});

const PrintBarcode100120 = styled.div({
  padding: 10,
  position: 'relative',
  border: '1px solid gray',
  display: 'flex',
  flexDirection: 'column',
  height: '100mm',
  width: '120mm',
  fontSize: 12,
  fontWeight: 500,
  marginBottom: 10,
});

const TitleText = styled.span({
  display: 'inline-block',
  width: '30mm',
});

const BoldText = styled.span({
  fontWeight: 800,
});

export interface IPrints {
  visible?: boolean;
  type?: 'default' | 'uni';
  data?: InvoicePrintDTOForParcel[] | InvoiceDTOForOrderSheetPrint[];
}

export const parcelPrintOptions = [
  { value: 'BASIC_A', label: '표준라벨A' },
  { value: 'BASIC_B', label: '표준라벨B' },
  { value: 'HANJIN', label: '한진택배' },
  { value: 'ILYANG', label: '일양로지스' },
];

export const Prints = ({ printObj, setPrintObj }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [invoices, setInvoices] = useState<InvoicePrintDTOForParcel[] | InvoiceDTOForOrderSheetPrint[]>();
  const [printSetting, setPrintSetting] = useState('HANJIN');
  const today = getToday();
  const wrapperRef = useRef();
  useEffect(() => {
    if (printObj?.data) {
      const rows = printObj?.data;
      setInvoices(rows);
    }
  }, []);

  const maskingName = (name: string) => {
    let masking = [...name];
    if (name?.length < 4) masking[2] = '*';
    else {
      masking[masking?.length - 1] = '*';
      masking[masking?.length - 2] = '*';
    }
    return masking?.join('');
  };

  const maskingPhone = (phone: string) => {
    let masking = [...phone];
    const masked = masking?.map((_, idx) => (idx > 2 && idx < 7 ? '*' : _));
    return masked;
  };

  const maskingAddr2 = (addr2: string) => {
    let masking = [...addr2];
    const masked = masking?.map((_, idx) => (idx < 10 ? _ : '*'));
    return masked;
  };

  const countPrint = async () => {
    await serviceStore.parcelAction(
      'invoice/print',
      'POST',
      null,
      printObj?.data?.map((ele) => {
        return { seq: ele.parcelInvoiceSeq };
      }),
    );
  };

  return (
    <>
      {invoices && (
        <CommonModalNew
          title={'택배운송장 출력'}
          style={{ width: printObj?.type === 'default' ? 800 : 500 }}
          visible={printObj?.visible}
          setVisible={() => setPrintObj(null)}
          rightTitle={
            <>
              <div style={{ display: 'flex' }}>
                {printObj?.type === 'default' && (
                  <div style={{ width: 120 }}>
                    <SelectD
                      hasNull={false}
                      value={
                        printSetting
                          ? {
                              value: printSetting,
                              label: selectlabel(printSetting, parcelPrintOptions),
                            }
                          : null
                      }
                      options={parcelPrintOptions}
                      onChange={(option) => {
                        setPrintSetting((option as OptionItem)?.value);
                      }}
                    />
                  </div>
                )}
                <ReactToPrint onAfterPrint={countPrint} trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
              </div>
            </>
          }
          children={
            <>
              <div ref={wrapperRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {invoices?.map((ele, idx) => {
                  const { ilyangInfo } = ele;
                  if (printObj?.type === 'default') {
                    if (printSetting === 'BASIC_A')
                      return (
                        <PrintBarcode100150 key={`print_${idx}`} className="break-for-onePage-print">
                          <div style={{ border: '1px solid gray', borderRight: 'none', padding: 10, width: 240 }}>
                            <div>
                              <BoldText style={{ marginRight: 10 }}>접 수 일 자</BoldText>
                              <BoldText>{ele?.registerDatetime || ''}</BoldText>
                            </div>
                            <div>
                              <div style={{ display: 'flex' }}>
                                <div style={{ width: 60 }}>보내는분</div>
                                <BoldText>{ele?.senderName}</BoldText>
                              </div>
                              <div style={{ display: 'flex' }}>
                                <div style={{ width: 60 }}>우편번호</div>
                                <BoldText style={{ width: '20%' }}>{ele?.senderZipcode}</BoldText>
                                <div style={{ width: 35 }}>TEL.</div>
                                <BoldText style={{ width: '25%' }}>{ele?.senderPhone}</BoldText>
                              </div>
                              <div style={{ margin: '10px 0' }}>
                                <div>주소</div>
                                <BoldText>
                                  {ele?.senderAddr1} {ele?.senderAddr2}
                                </BoldText>
                              </div>
                              <div style={{ display: 'flex' }}>
                                <TitleText>운송장번호</TitleText>
                                <BoldText>{ele?.parcelInvoiceNumber || ''}</BoldText>
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                {ele?.parcelInvoiceNumber && <Barcode width={1.6} value={ele?.parcelInvoiceNumber} background="transparent" displayValue={false} height={40} />}
                              </div>
                              <div style={{ margin: '10px 0' }}>
                                <div>상품명</div>
                                <BoldText>{ele?.productName}</BoldText>
                              </div>
                              <div style={{ display: 'flex' }}>
                                <TitleText>분류코드</TitleText>
                              </div>
                              <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <BoldText style={{ minHeight: 20 }}>{ele?.groupCode || ''}</BoldText>
                                  <TitleText style={{ minHeight: 20 }}>{ele?.groupCode2 || ''}</TitleText>
                                </div>
                                {ele?.groupBarcode && <Barcode width={1.2} value={ele?.groupBarcode} background="transparent" displayValue={false} height={40} />}
                              </div>
                            </div>
                          </div>
                          <div style={{ width: 326 }}>
                            <div style={{ width: 326, height: 50, display: 'flex' }}>
                              <div
                                style={{
                                  width: 200,
                                  borderRight: '1px solid gray',
                                  borderLeft: '1px solid gray',
                                  borderTop: '1px solid gray',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontSize: 20,
                                  fontWeight: 700,
                                }}
                              >
                                {MASTER_OBJ?.CENTER_WHOLE?.[ele?.centerCode]}
                              </div>
                              <div style={{ border: '1px solid gray', width: 196, borderBottom: 'none', borderLeft: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <BoldText style={{ fontSize: 20 }}>{ele?.userIdKr}</BoldText>
                              </div>
                            </div>
                            <div style={{ width: 326, height: 328, borderTop: 'none', border: '1px solid gray', padding: 10, fontSize: 15, fontWeight: 700 }}>
                              <div style={{ display: 'flex' }}>
                                <div style={{ width: 60 }}>받는분</div>
                                <div>{ele?.receiverName}</div>
                              </div>
                              <div style={{ display: 'flex' }}>
                                <div style={{ width: 60 }}>우편번호</div>
                                <div style={{ width: 80 }}>{ele?.receiverZipcode}</div>
                                <div style={{ width: 60 }}>TEL.</div>
                                <div>{ele?.receiverPhone}</div>
                              </div>
                              <div style={{ margin: '10px 0', minHeight: 100 }}>
                                <div style={{ width: 60 }}>주소</div>
                                <div>
                                  {ele?.receiverAddr1} {ele?.receiverAddr2}
                                </div>
                              </div>
                              <div style={{ display: 'flex' }}>
                                <div style={{ width: 60 }}>운임비용</div>
                                <div>
                                  {ele?.payTypeKr} / {ele?.price ? numberThousandComma(ele?.price) : '-'}
                                </div>
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                {ele?.parcelInvoiceNumber && <Barcode width={1.8} value={ele?.parcelInvoiceNumber} background="transparent" fontSize={15} height={40} />}
                              </div>
                            </div>
                          </div>
                        </PrintBarcode100150>
                      );
                    else if (printSetting === 'BASIC_B')
                      return (
                        <PrintBarcode100180 key={`print_${idx}`} className="break-for-onePage-print">
                          <table className="border-table b-here f15-here">
                            <tbody>
                              <tr>
                                <td colSpan={13}>
                                  <div style={{ display: 'flex' }}>
                                    <div style={{ fontWeight: 'bolder', fontSize: 30, width: '33%', textAlign: 'center' }}>{MASTER_OBJ?.INFRA?.[ele?.infraSeq]}</div>
                                    <div style={{ width: '33%' }}>{ele?.groupCode && <Barcode width={1.8} value={ele?.groupCode} background="transparent" fontSize={15} height={40} />}</div>
                                    <div style={{ width: '33%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      <span style={{ marginRight: 5 }}> {ele?.groupCode}</span>
                                      <span style={{ fontWeight: 500 }}> {ele?.groupCode2}</span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={13}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
                                    <div style={{ display: 'flex' }}>
                                      <div style={{ width: 100 }}>운송장번호</div>
                                      <div>{ele?.parcelInvoiceNumber}</div>
                                    </div>
                                    <div>{getToday()}</div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ writingMode: 'vertical-lr', textAlign: 'center', fontSize: 17 }}>받 는 분</td>
                                <td colSpan={12}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                      <div style={{ fontSize: 20 }}>
                                        {ele?.receiverAddr1} {ele?.receiverAddr2}
                                      </div>
                                      <div>
                                        <span style={{ marginRight: 5 }}>{ele?.receiverName}</span>
                                        <span>{ele?.receiverPhone}</span>
                                      </div>
                                    </div>
                                    <div>
                                      {ele?.subNumber ? (
                                        <Barcode width={1.8} value={ele?.subNumber} background="transparent" fontSize={15} height={35} />
                                      ) : (
                                        <Barcode width={1.8} value={ele?.houseNumber} background="transparent" fontSize={15} height={35} />
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: 'center' }}>운임</td>
                                <td colSpan={2}>{MASTER_OBJ?.PARCEL_PAY_TYPE?.[ele?.payType]}</td>
                                <td style={{ textAlign: 'center' }}>수량</td>
                                <td colSpan={9}>
                                  {ele?.totalBoxQuantity} - {ele?.boxIndex}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ writingMode: 'vertical-lr', textAlign: 'center' }}>보 내 는 분</td>
                                <td colSpan={12}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                      <div>
                                        {ele?.senderAddr1} {ele?.senderAddr2}
                                      </div>
                                      <div>
                                        <span style={{ display: 'inline-block', width: 50 }}>{ele?.senderName}</span>
                                        <span>{ele?.senderPhone}</span>
                                      </div>
                                    </div>
                                    <div>{ele?.houseNumber && <Barcode width={1.8} value={ele?.houseNumber} background="transparent" fontSize={15} height={35} />}</div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: 'center' }}>품명</td>
                                <td colSpan={12}>{ele?.productName || '품명~~~'}</td>
                              </tr>
                            </tbody>
                          </table>
                        </PrintBarcode100180>
                      );
                    else if (printSetting === 'HANJIN')
                      return (
                        <PrintBarcode100123 key={`print_${idx}`} className="break-for-onePage-print">
                          <table className="border-table noBorder">
                            <tbody>
                              <tr>
                                <td colSpan={12}>
                                  <span style={{ fontSize: 10, color: 'white' }}>운송장번호 : </span>
                                  {ele.parcelInvoiceNumber}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={12}>
                                  <div style={{ display: 'flex', maxHeight: '15mm', justifyContent: 'space-evenly', alignItems: 'center', fontSize: 36, fontWeight: 700 }}>
                                    <div>{ele?.groupBarcode}</div>
                                    <div>
                                      {ele?.groupCode} {ele?.groupStore}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td rowSpan={2} style={{ writingMode: 'vertical-lr', textAlign: 'center', fontSize: 10 }}></td>
                                <td colSpan={11}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                      {ele?.receiverName} <span className="b">{ele?.receiverPhone}</span>
                                    </div>
                                    <div style={{ paddingRight: '10mm' }}>
                                      {ele?.parcelInvoiceNumber && <Barcode width={1.3} value={ele?.parcelInvoiceNumber} margin={0} height={20} displayValue={false} />}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={11}>
                                  <div style={{ position: 'relative', minHeight: '15mm' }}>
                                    <span style={{ fontSize: 22, fontWeight: 700, letterSpacing: '-0.5mm' }}>{ele?.receiverAddr}</span>
                                    <span style={{ position: 'absolute', bottom: 2, right: '10mm', border: '1px solid lightgray', padding: '2px 10px' }}>
                                      {ele.payTypeKr} {ele.price}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ fontSize: 10, textAlign: 'center' }}></td>
                                <td colSpan={11}>
                                  <div style={{ fontSize: 10 }}>
                                    {ele?.senderName} <span className="b">{ele?.senderPhone}</span>
                                    <div className="b">{ele?.senderAddr}</div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={12}>
                                  <div style={{ minHeight: '5mm', maxHeight: '10mm' }}>{ele?.productName}</div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={12}>
                                  <div style={{ maxHeight: '30mm', textAlign: 'right', paddingRight: '10mm' }}>
                                    {ele?.parcelInvoiceNumber && <Barcode width={2.5} margin={0} value={ele?.parcelInvoiceNumber} height={35} displayValue={false} />}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </PrintBarcode100123>
                      );
                    else if (printSetting === 'ILYANG')
                      return (
                        <PrintBarcode200102 key={`print_${idx}`} className="break-for-onePage-print">
                          <img className="print-hidden" src={ilyang} style={{ opacity: 0.8, zIndex: 0, position: 'absolute', top: -3, left: -3, width: '200mm', height: '105mm' }} />
                          <table className="border-table noBorder" style={{ width: '72.5mm', marginTop: 0, zIndex: 1 }}>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="ellipsis" style={{ height: '12.96mm', fontSize: 40, fontWeight: 700, textAlign: 'center' }}>
                                    {ilyangInfo?.ilyRcv?.result?.[0]?.Center_Name}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div style={{ height: '13mm', textAlign: 'center' }}>
                                    {ele?.externalInvoiceNumber && (
                                      <Barcode format={'CODE39'} width={1.1} margin={0} fontSize={10} background="transparent" value={ele?.externalInvoiceNumber} height={35} />
                                    )}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div style={{ maxHeight: '27.2mm', height: '27.2mm', paddingLeft: '7mm' }}>{ele?.productName}</div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div style={{ height: '28mm', maxHeight: '28mm', fontSize: 18, fontWeight: 700, paddingLeft: '7mm' }}>
                                    {ele?.receiverAddr
                                      ?.split(' ')
                                      ?.filter((_, idx) => {
                                        if (ele?.receiverAddr?.split(' ')[0]?.includes('세종') && idx > 0) return true;
                                        else if (!ele?.receiverAddr?.split(' ')[0]?.includes('세종') && idx > 1) return true;
                                        return false;
                                      })
                                      ?.join(' ')}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table className="border-table noBorder" style={{ width: '118.5mm', marginTop: 0, marginLeft: '7mm', zIndex: 1 }}>
                            <tbody>
                              <tr>
                                <td colSpan={12}>
                                  <div style={{ height: '6mm', textAlign: 'center', paddingTop: '2mm' }}>{ele?.externalInvoiceNumber} </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={12}>
                                  <div style={{ height: '10mm' }}>
                                    <div>
                                      {maskingName(ele?.receiverName)} / {maskingPhone(ele.receiverPhone)}
                                    </div>
                                    <div>
                                      {ele?.receiverAddr1} {ele?.receiverAddr2 ? maskingAddr2(ele?.receiverAddr2) : ''}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={12}>
                                  <div style={{ height: '7mm' }}>
                                    {maskingName(ele?.senderName)} / {maskingPhone(ele.senderPhone)}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={12}>
                                  <div style={{ height: '40mm', position: 'relative' }}>
                                    <div style={{ display: 'flex', height: '10mm' }}>
                                      <div style={{ width: '24mm', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>선불</div>
                                      <div
                                        className="ellipsis"
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '94mm', fontSize: 13, fontWeight: 700, paddingRight: '10mm' }}
                                      >
                                        {`${ilyangInfo?.ilySnd?.result?.[2]?.Office_Name || '-'}지점(${ilyangInfo?.ilySnd?.result?.[3]?.Svc_Man || '-'})`} {today} {ilyangInfo?.accountNo || '-'}
                                      </div>
                                    </div>
                                    <div>
                                      <div style={{ height: '10mm', display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '24mm', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{ele?.quantity || 1}</div>
                                        <div style={{ paddingRight: '10mm', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                          <span style={{ margin: '0 10px', fontSize: 20, fontWeight: 700 }}>
                                            {ele?.boxIndex} / {ele?.totalBoxQuantity}
                                          </span>
                                          <div style={{ textAlign: 'center', paddingTop: 12 }}>
                                            {ele?.externalInvoiceNumber && (
                                              <Barcode format={'CODE39'} margin={0} width={1.1} fontSize={10} background="transparent" value={ele?.externalInvoiceNumber} height={45} />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div style={{ height: '10mm', width: '24mm', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{ele?.weight}</div>
                                    </div>
                                    <div>
                                      <div style={{ height: '10mm', display: 'flex', alignItems: 'center' }}>{ele?.shipmentNote}</div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={12}>
                                  <div style={{ height: '12mm' }}>
                                    <div>
                                      {maskingName(ele?.receiverName)} / {maskingPhone(ele.receiverPhone)}
                                    </div>
                                    <div>
                                      {ele?.receiverAddr1} {ele?.receiverAddr2 ? maskingAddr2(ele?.receiverAddr2) : ''}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={12}>
                                  <div style={{ height: '12mm' }}>
                                    <div>
                                      {maskingName(ele?.senderName)} / {maskingPhone(ele.senderPhone)}
                                    </div>
                                    <div>
                                      {ele?.senderAddr1} {ele?.senderAddr2 ? maskingAddr2(ele?.senderAddr2) : ''}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={12} style={{ height: '3mm' }}></td>
                              </tr>
                            </tbody>
                          </table>
                        </PrintBarcode200102>
                      );
                  } else
                    return (
                      <PrintBarcode100120 key={`print_${idx}`} className="break-for-onePage-print">
                        <table style={{ width: '100%' }}>
                          <tbody>
                            <tr>
                              <td>접수일자</td>
                              <td>{ele?.registerDatetime}</td>
                              <td>주문번호</td>
                              <td>{ele?.orderNumber}</td>
                            </tr>
                            <tr>
                              <td>운송장번호</td>
                              <td colSpan={3}>{ele?.parcelInvoiceNumber}</td>
                            </tr>
                            <tr style={{ border: '1px solid gray' }}>
                              <td colSpan={2}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <span style={{ fontSize: 15 }} className="b">
                                    {ele?.groupCode}
                                  </span>
                                  <span>{ele?.groupCode2}</span>
                                </div>
                              </td>
                              <td colSpan={2}>{ele?.groupBarcode && <Barcode width={2} value={ele?.groupBarcode} background="transparent" displayValue={false} height={40} />}</td>
                            </tr>
                            <tr>
                              <td style={{ fontSize: 15 }}>받는분</td>
                              <td style={{ fontSize: 15 }} colSpan={3}>
                                {ele?.receiverName}
                              </td>
                            </tr>
                            <tr>
                              <td>우편번호 / TEL</td>
                              <td style={{ fontSize: 15 }} colSpan={3}>
                                {ele?.receiverZipcode} / {ele?.receiverPhone}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ fontSize: 15 }} rowSpan={2}>
                                주소
                              </td>
                              <td style={{ fontSize: 15 }} colSpan={3} rowSpan={2}>
                                {ele?.receiverAddr1} {ele?.receiverAddr2 || ''}
                              </td>
                            </tr>
                            <tr></tr>
                            <tr>
                              <td>고객요청사항</td>
                              <td colSpan={3}>{ele?.shipmentNote}</td>
                            </tr>
                            <tr>
                              <td>배송메모</td>
                              <td>{ele?.remark}</td>
                              <td>운임비용</td>
                              <td>{ele?.payTypeKr}</td>
                            </tr>
                          </tbody>
                        </table>
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '50%' }}>
                            {ele?.parcelInvoiceNumber && <Barcode width={1.8} displayValue={false} value={ele?.parcelInvoiceNumber} background="transparent" fontSize={15} height={40} />}
                          </div>
                          <div></div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '75%' }}>
                            <table>
                              <tbody>
                                <tr>
                                  <td style={{ fontSize: 8 }}>보내는분</td>
                                  <td style={{ fontSize: 8 }} colSpan={3}>
                                    {ele?.receiverName}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ fontSize: 8 }}>우편번호/TEL</td>
                                  <td style={{ fontSize: 8 }} colSpan={3}>
                                    {ele?.receiverZipcode} / {ele?.receiverPhone}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ fontSize: 8 }}>보내는주소</td>
                                  <td style={{ fontSize: 8 }} colSpan={3}>
                                    {ele?.receiverAddr1} {ele?.receiverAddr2}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ fontSize: 8 }}>상품명</td>
                                  <td colSpan={3}>{ele?.productName}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div>{ele?.parcelInvoiceNumber && <Barcode width={1.5} value={ele?.parcelInvoiceNumber} background="transparent" fontSize={10} height={40} />}</div>
                        </div>
                      </PrintBarcode100120>
                    );
                })}
              </div>
            </>
          }
        />
      )}
    </>
  );
};
