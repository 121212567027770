import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './model';
import Tab2 from './return';
import Tab3 from './component';
import Tab4 from './inspect';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';

export const GANSUNCOLLECT_MASTER = [
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  'INFRA',
  //
  'BODY_SHIPPING_TYPE',
  'LOAN_STATUS',
  'COLLECT_TYPE',
  'COLLECT_STATUS',
  'CANCEL_REASON',
  'COLLECT_REASON',
  'FEE_TYPE',
  'INVA_STATUS',
  //
  'COLLECT_INSPECT_STATUS',
  'INSPECT_CAUSE',
  'INSPECT_REASON',
  'INSPECT_PROCESS',

  //
  {
    SEARCH_MODEL: [
      {
        label: '제품',
        value: 'model',
      },
      {
        label: '제품명',
        value: 'modelName',
      },
    ],
  },
  {
    SEARCH_SEQ: [
      {
        label: '주문번호',
        value: 'orderSeq',
      },
      {
        label: '송장번호',
        value: 'invoiceSeq',
      },
    ],
  },
  {
    SEARCH_DATE: [
      {
        label: '회수생성일',
        value: 'RegisterDate',
      },
      {
        label: '창고확정일',
        value: 'CenterConfirmDate',
      },
    ],
  },
  //
  'INSPECT_FLAG',
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(GANSUNCOLLECT_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            DRIVERCOLLECT_MODEL: <Tab1 tabId={'DRIVERCOLLECT_MODEL'} />,
            DRIVERCOLLECT_REMOVE: <Tab2 tabId={'DRIVERCOLLECT_REMOVE'} />,
            DRIVERCOLLECT_COMPONENT: <Tab3 tabId={'DRIVERCOLLECT_COMPONENT'} />,
            COLLECTINSPECT_TAB: <Tab4 tabId={'COLLECTINSPECT_TAB'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
