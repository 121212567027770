import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';
import {
  DROPSHIPPING_INVOICE_PRINT,
  EXCEL_TEMPLATE_LIST,
  EXTRA_CHARGE_PAYMENT_CANCEL,
  EXTRA_CHARGE_PAYMENT_DETAIL,
  EXTRA_CHARGE_PAYMENT_DETAIL_EXCEL,
  EXTRA_CHARGE_PAYMENT_EXCEL,
  EXTRA_CHARGE_PAYMENT_PAGING,
  GET_INVOICE_DETAIL_V2,
  GET_INVOICE_INSTALL_CERTI,
  GET_INVOICE_LIST,
  MESSAGE_HISTORY,
  MESSAGE_HISTORY_DETAIL,
  MESSAGE_HISTORY_EXCEL,
  MESSAGE_SMS_RETRY,
  MOVE_CONFIRM_V2,
  MOVE_ORDER_LIST_V2,
  OMS,
  OMS_FINDPARCEL,
  OMS_HAPPY_CALL,
  OMS_ORDER_ASSIGN,
  OMS_ORDER_ASSIGN_ADMIN,
  OMS_ORDER_ASSIGN_V3,
  OMS_ORDER_CANCEL,
  OMS_ORDER_DETAIL_COUNSEL,
  OMS_ORDER_DETAIL_FILE,
  OMS_ORDER_DETAIL_FOR_ORDER_REGISTER,
  OMS_ORDER_DETAIL_TIMESTAMP,
  OMS_ORDER_INSTALL_COMPLETE,
  OMS_ORDER_INVENTORY,
  OMS_ORDER_INVENTORY_LOCATION,
  OMS_ORDER_LIST_PAGING_V2,
  OMS_ORDER_LIST_QUANTITY_V2,
  OMS_ORDER_SAVE,
  OMS_ORDER_SHEET_PRINT,
  OMS_PRODUCT_MODEL_GROUP,
  OMS_PURCHASE_REQUEST,
  OMS_RESEND_COMPLETE,
  OMS_ROLLBACK,
  OMS_SERVICE_DELETE,
  OMS_SERVICE_EXCEL,
  OMS_SERVICE_PAGING,
  OMS_SERVICE_SAVE,
  OMS_UNASSIGN_ORDER,
  ORDER_CHANGE_PARCEL,
  ORDER_DETAIL_HISTORY,
  ORDER_EXCEL_LIST_DETAIL,
  ORDER_HISTORY_EXCEL,
  ORDER_HISTORY_PAGING,
  ORDER_LIST_EXCEL_V2,
  ORDER_NOTININVENTORY,
  ORDER_PEND_LIST,
  ORDER_SAVE_EXCEL,
  ORDER_SMS,
  PARCEL_INVOICE_EXCEL,
  PARCEL_INVOICE_EXCEL_SAVE,
  PARCEL_INVOICE_PAGING,
  PARCEL_INVOICE_PRINT,
  PARCEL_INVOICE_QUANTITY,
  PARCEL_INVOICE_SAVE,
  PRODUCT_SEARCH_DETAIL_PAGING,
  SAVE_EXCEL_TEMPLATE,
  TRAKING_V2,
  WMS_MODEL_STOCK_MODEL_PAGING,
} from 'envVar';
import {
  CounselDTO,
  CustomerOrderCancelDTOSecond,
  CustomerOrderMoveListDTOSecond,
  CustomerOrderMoveSearchDTOSecond,
  ExtraChargePaymentCancelBeforeDTO,
  ExtraChargePaymentCancelDTO,
  ExtraServiceDTO,
  ExtraServiceSearchDTO,
  InventoryLackDTO,
  InvoiceChangeParcelDTO,
  InvoiceDTOForDropshippingPrint,
  InvoiceDTOForOrderSheetPrint,
  InvoiceDetailDTO,
  InvoiceDetailListDTO,
  InvoiceInventoryLocationDTO,
  InvoicePaymentDTO,
  InvoicePaymentDetailDTO,
  InvoicePendDTO,
  InvoicePrintDTOForParcel,
  InvoiceSeqDTO,
  InvoiceSeqListDTO,
  InvoiceUpdateHistoryDTO,
  InvoiceUpdateHistoryListDTO,
  InvoiceUpdateHistorySearchDTO,
  MessageDTO,
  MessageHistoryDTO,
  MessageHistoryDetailDTO,
  MessageHistorySearchDTO,
  OrderDTO,
  OrderExcelDTO,
  OrderExcelTemplateDTO,
  OrderExcelTemplateSearchDTO,
  OrderExcelTemplateSetDTO,
  OrderListDTO,
  OrderListQuantityDTO,
  OrderSearchDTO,
  ParcelInvoiceDTO,
  ParcelInvoiceExcelDTO,
  ParcelInvoiceQuantityDTO,
  ParcelInvoiceSearchDTO,
  PaymentSearchDTO,
  ProductDetailListDTO,
  ProductDetailSearchDTO,
  ProductModelDTOForGroup,
  TimeDTO,
} from 'interface/order';

import { ResponseDTO } from 'interface/user';
import { LinehaulDTOForMove, ModelStockAndModelDTO, ModelStockAndModelSearchDTO } from 'interface/warehouse';
import { FileDTO, PagingListDTO } from 'interface/util';

class OrderService {
  public async getModelListPaging(searchDTO: ModelStockAndModelSearchDTO): Promise<PagingListDTO<ModelStockAndModelDTO>> {
    let url = WMS_MODEL_STOCK_MODEL_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getProductListPaging(searchDTO: ProductDetailSearchDTO): Promise<PagingListDTO<ProductDetailListDTO>> {
    let url = PRODUCT_SEARCH_DETAIL_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  // 주문불러오기
  public async getOrderCopy(invoiceSeq: number): Promise<OrderDTO> {
    let url = OMS_ORDER_DETAIL_FOR_ORDER_REGISTER + `?invoiceSeq=${invoiceSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getProductModelGroup(productSeq: number): Promise<ProductModelDTOForGroup[]> {
    let url = OMS_PRODUCT_MODEL_GROUP + `?productSeq=${productSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async saveOrder(dataDTO: OrderDTO): Promise<ResponseDTO> {
    let url = OMS_ORDER_SAVE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  // 양식관리
  public async getUploadFormList(searchDTO: OrderExcelTemplateSearchDTO): Promise<OrderExcelTemplateDTO[]> {
    let url = EXCEL_TEMPLATE_LIST + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async saveUploadForm(dataDTO: OrderExcelTemplateSetDTO): Promise<ResponseDTO> {
    let url = SAVE_EXCEL_TEMPLATE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  // 주문정보
  public async getOrderListDetailExcel(searchDTO: OrderSearchDTO): Promise<OrderListDTO[]> {
    let url = ORDER_EXCEL_LIST_DETAIL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getOrderListQuantity(searchDTO: OrderSearchDTO): Promise<OrderListQuantityDTO> {
    let url = OMS_ORDER_LIST_QUANTITY_V2 + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getOrderListPaging(searchDTO: OrderSearchDTO): Promise<PagingListDTO<OrderListDTO>> {
    let url = OMS_ORDER_LIST_PAGING_V2 + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getOrderListMainExcel(searchDTO: OrderSearchDTO): Promise<OrderListDTO[]> {
    let url = ORDER_LIST_EXCEL_V2 + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInvoiceDetail(invoiceSeq: number): Promise<InvoiceDetailDTO> {
    let url = GET_INVOICE_DETAIL_V2 + `?invoiceSeq=${invoiceSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getInvoiceList(orderSeq: number): Promise<InvoiceDetailListDTO[]> {
    let url = GET_INVOICE_LIST + `?orderSeq=${orderSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInvoiceTimeStamp(invoiceSeq: number): Promise<TimeDTO> {
    let url = OMS_ORDER_DETAIL_TIMESTAMP + `/${invoiceSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getInvoiceCounsel(invoiceSeq: number): Promise<CounselDTO[]> {
    let url = OMS_ORDER_DETAIL_COUNSEL + `/${invoiceSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getInvoiceHistory(orderSeq: number, invoiceSeq: number): Promise<InvoiceUpdateHistoryDTO[]> {
    let url = ORDER_DETAIL_HISTORY + `?orderSeq=${orderSeq}&invoiceSeq=${invoiceSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getInvoiceInstallation(orderSeq: number, invoiceSeq: number): Promise<InvoiceUpdateHistoryDTO[]> {
    let url = GET_INVOICE_INSTALL_CERTI + `?installCertificateSeq=${invoiceSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getTrackingData(invoiceSeq: number): Promise<InvoiceDetailDTO> {
    let url = TRAKING_V2 + `?invoiceSeq=${invoiceSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postPurchaseRequest(type: string, datDTO: LinehaulDTOForMove[]): Promise<ResponseDTO> {
    let url = OMS_PURCHASE_REQUEST + `/${type}`;
    const rs = await httpClient.post(url, datDTO);
    return rs;
  }
  // 택배 운송장 조회
  public async getOrderParcelQuantity(searchDTO: ParcelInvoiceSearchDTO): Promise<ParcelInvoiceQuantityDTO> {
    let url = PARCEL_INVOICE_QUANTITY + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getOrderParcelPaging(searchDTO: ParcelInvoiceSearchDTO): Promise<PagingListDTO<ParcelInvoiceDTO>> {
    let url = PARCEL_INVOICE_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getOrderParcelMainExcel(searchDTO: ParcelInvoiceSearchDTO): Promise<ParcelInvoiceDTO[]> {
    let url = PARCEL_INVOICE_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getParcelDetail(orderSeq: number): Promise<ParcelInvoiceDTO[]> {
    let url = OMS_FINDPARCEL + `?orderSeq=${orderSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getParcelPrint(dataDTO: InvoiceSeqDTO[]): Promise<InvoicePrintDTOForParcel[]> {
    let url = PARCEL_INVOICE_PRINT;
    const { data } = await httpClient.post(url, dataDTO);
    return data;
  }

  public async postParcelInvoiceExcelSave(dataDTO: ParcelInvoiceExcelDTO[]): Promise<ResponseDTO> {
    let url = PARCEL_INVOICE_EXCEL_SAVE;
    const { data } = await httpClient.post(url, dataDTO);
    return data;
  }

  public async postParcelDetailInvoiceSave(dataDTO: ParcelInvoiceDTO): Promise<ResponseDTO> {
    let url = PARCEL_INVOICE_SAVE;
    const { data } = await httpClient.post(url, dataDTO);
    return data;
  }

  public async getDropshippingPrint(dataDTO: InvoiceSeqDTO[]): Promise<InvoiceDTOForDropshippingPrint[]> {
    let url = DROPSHIPPING_INVOICE_PRINT;
    const { data } = await httpClient.post(url, dataDTO);
    return data;
  }
  // 이전 주문조회
  public async getOrderRelocatePaging(searchDTO: CustomerOrderMoveSearchDTOSecond): Promise<CustomerOrderMoveListDTOSecond[]> {
    let url = MOVE_ORDER_LIST_V2 + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postCreatePickingForRelocateOrder(data: LinehaulDTOForMove[]): Promise<ResponseDTO> {
    let url = MOVE_CONFIRM_V2;
    const rs = await httpClient.post(url, data);
    return rs;
  }
  // 서비스
  public async getServicePaging(searchDTO: ExtraServiceSearchDTO): Promise<PagingListDTO<ExtraServiceDTO>> {
    let url = OMS_SERVICE_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getServiceMainExcel(searchDTO: ExtraServiceSearchDTO): Promise<ExtraServiceDTO[]> {
    let url = OMS_SERVICE_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async uploadService(dataDTO: ExtraServiceDTO[]): Promise<ResponseDTO> {
    let url = OMS_SERVICE_SAVE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
  public async postDeleteExtraService(extraServiceSeqs: { extraServiceSeqs: number[] }): Promise<ResponseDTO> {
    let url = OMS_SERVICE_DELETE;
    const rs = await httpClient.post(url, extraServiceSeqs);
    return rs;
  }
  // 주문이력조회
  public async getOrderHistoryPaging(searchDTO: InvoiceUpdateHistorySearchDTO): Promise<PagingListDTO<InvoiceUpdateHistoryListDTO>> {
    let url = ORDER_HISTORY_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getOrderHistoryExcel(searchDTO: InvoiceUpdateHistorySearchDTO): Promise<InvoiceUpdateHistoryListDTO[]> {
    let url = ORDER_HISTORY_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  // message

  public async getMessageHistoryPaging(searchDTO: MessageHistorySearchDTO): Promise<PagingListDTO<MessageHistoryDTO>> {
    let url = MESSAGE_HISTORY + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getMessageHistoryExcel(searchDTO: PaymentSearchDTO): Promise<MessageHistoryDTO[]> {
    let url = MESSAGE_HISTORY_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getMessageHistoryDetail(seq: string): Promise<MessageHistoryDetailDTO[]> {
    let url = MESSAGE_HISTORY_DETAIL + `?messageSeq=${seq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postMessageRetry(messageSeqList: { messageSeq: string }[]): Promise<ResponseDTO> {
    let url = MESSAGE_SMS_RETRY;
    const rs = await httpClient.post(url, messageSeqList);
    return rs;
  }

  // 추가결제
  public async getPaymentPaging(searchDTO: PaymentSearchDTO): Promise<PagingListDTO<InvoicePaymentDTO>> {
    let url = EXTRA_CHARGE_PAYMENT_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getPaymentDetail(paymentSeq: number): Promise<InvoicePaymentDetailDTO[]> {
    let url = EXTRA_CHARGE_PAYMENT_DETAIL + `?paymentSeq=${paymentSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getPaymentMainExcel(searchDTO: PaymentSearchDTO): Promise<InvoicePaymentDTO[]> {
    let url = EXTRA_CHARGE_PAYMENT_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getPaymentDetailExcel(searchDTO: PaymentSearchDTO): Promise<InvoicePaymentDTO[]> {
    let url = EXTRA_CHARGE_PAYMENT_DETAIL_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async postCancelExtraChargePayment(dataDTO: ExtraChargePaymentCancelDTO): Promise<ResponseDTO> {
    let url = EXTRA_CHARGE_PAYMENT_CANCEL;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
  public async postCancelDriverExtraChargePayment(dataDTO: ExtraChargePaymentCancelBeforeDTO): Promise<ResponseDTO> {
    let url = EXTRA_CHARGE_PAYMENT_CANCEL + '/before';
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  // 주문조회
  public async getInvoiceFile(invoiceSeq: number): Promise<FileDTO[]> {
    let url = OMS_ORDER_DETAIL_FILE + `/${invoiceSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getOrderSms(invoiceSeq: number): Promise<MessageDTO[]> {
    let url = ORDER_SMS + `?invoiceSeq=${invoiceSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInvoiceInventory(invoiceSeq: number): Promise<InventoryLackDTO[]> {
    let url = OMS_ORDER_INVENTORY + `/${invoiceSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInvoiceInventoryLocation(invoiceSeq: number): Promise<InvoiceInventoryLocationDTO[]> {
    let url = OMS_ORDER_INVENTORY_LOCATION + `/${invoiceSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postOrderAssignAdmin(dataDTO: { invoiceSeqs: number[] }): Promise<ResponseDTO> {
    let url = OMS_ORDER_ASSIGN_ADMIN;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async postOrderCancel(dataDTO: CustomerOrderCancelDTOSecond): Promise<ResponseDTO> {
    let url = OMS_ORDER_CANCEL;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
  public async postOrderPendlist(dataDTO: InvoicePendDTO): Promise<ResponseDTO> {
    let url = ORDER_PEND_LIST;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
  public async postOrderRollback(invoiceSeq: number): Promise<ResponseDTO> {
    let url = OMS_ROLLBACK + `/${invoiceSeq}`;
    const rs = await httpClient.post(url);
    return rs;
  }

  public async postOrderInstallCompleteLite(invoiceSeq: number): Promise<ResponseDTO> {
    let url = OMS_ORDER_INSTALL_COMPLETE;
    const rs = await httpClient.post(url, { invoiceSeq });
    return rs;
  }
  public async postOrderNotInInventory(dataDTO: InvoiceSeqListDTO): Promise<ResponseDTO> {
    let url = ORDER_NOTININVENTORY;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async postOrderConfirm(dataDTO: any): Promise<ResponseDTO> {
    let url = OMS_ORDER_ASSIGN_V3;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
  public async postOrderSheetPrint(dataDTO: InvoiceSeqDTO): Promise<InvoiceDTOForOrderSheetPrint[]> {
    let url = OMS_ORDER_SHEET_PRINT;
    const { data } = await httpClient.post(url, dataDTO);
    return data;
  }
  // 낱개
  public async postOrderSaveExcel(dataDTO: OrderExcelDTO): Promise<ResponseDTO> {
    let url = ORDER_SAVE_EXCEL;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
  public async putOrderParcelSaveExcel(dataDTO: InvoiceChangeParcelDTO[]): Promise<ResponseDTO> {
    let url = ORDER_CHANGE_PARCEL;
    const { data } = await httpClient.put(url, dataDTO);
    return data;
  }
  public async postOrderAssign(dataDTO: any): Promise<ResponseDTO> {
    let url = OMS_ORDER_ASSIGN;
    const { data } = await httpClient.post(url, dataDTO);
    return data;
  }
  public async postResendComplete(dataDTO: { seq: number }[]): Promise<ResponseDTO> {
    let url = OMS_RESEND_COMPLETE;
    const { data } = await httpClient.post(url, dataDTO);
    return data;
  }

  public async postParcelAction(action: string, dataDTO: { seq: number }[]): Promise<ResponseDTO> {
    let url = `/order/${action}`;
    const { data } = await httpClient.post(url, dataDTO);
    return data;
  }
  public async deleteUnassignAndDelete(dataDTO: number[]): Promise<ResponseDTO> {
    let url = OMS_UNASSIGN_ORDER;
    const rs = await httpClient.delete(url, { data: dataDTO });
    return rs;
  }
  // 해피콜
  public async getHappycall(invoiceSeq: number): Promise<ResponseDTO> {
    let url = OMS_HAPPY_CALL + `/${invoiceSeq}`;
    const rs = await httpClient.get(url);
    return rs;
  }

  public async deleteHappycall(invoiceSeq: number, data: { seq: number }[]): Promise<ResponseDTO> {
    let url = OMS_HAPPY_CALL + `/${invoiceSeq}`;
    const rs = await httpClient.delete(url, { data });
    return rs;
  }
  public async readHappycall(invoiceSeq: number): Promise<ResponseDTO> {
    let url = OMS_HAPPY_CALL + `/${invoiceSeq}`;
    const rs = await httpClient.post(url);
    return rs;
  }

  public async getOMSAction(action: string, searchDTO: any, isPaging = false): Promise<any[]> {
    let url = OMS(`${action}`) + (isPaging ? addingParamsToUrl(searchDTO) : addingParamsToUrlexceptPaging(searchDTO));
    const { data } = await httpClient.get(url);
    return data;
  }

  public async posOMSAction(action: string, dataDTO: any, searchDTO: any): Promise<ResponseDTO> {
    let url = OMS(`${action}`);
    if (searchDTO) url += addingParamsToUrlexceptPaging(searchDTO);
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async putOMSAction(action: string, dataDTO: any, searchDTO: any): Promise<ResponseDTO> {
    let url = OMS(`${action}`);
    if (searchDTO) url += addingParamsToUrlexceptPaging(searchDTO);
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }
}

export const orderService = new OrderService();
