import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { Button, Label } from 'reactstrap';
import { FileDTOForProductDetail, ProductDetailDTO, ProductManualDTOForProductDetail, ProductSearchDTO } from 'interface/order';

// utils
import { handleFiles, uploadFile } from 'common/util/photo';
import CloseImg from 'assets/images/close/cross.png';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import * as IGrid from 'aui-grid';
import { v4 as uuidv4 } from 'uuid';
import { RAWDATA, setLoading } from 'redux/services/menuSlice';
import { defaultSeachObj } from 'hooks/grid/useSearch';
// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import { DetailPopup as ModelDetailPopup } from 'pages/INVENTORY/4modelNproduct/modelTab/model/component/detailPopup';
import { IModelDetailObj } from 'pages/INVENTORY/4modelNproduct/modelTab/model/component/detailPopup';
import { ImageModal } from 'pages/ORDER/1order/orderListTab/common/component/photos/imageModal';
import ModelStockModal from 'pages/ORDER/1order/orderListTab/common/component/invocieDetail/modelStockModal';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';

export interface IDetailObj {
  visible: boolean;
  item?: ProductDetailDTO;
  searchObj?: string;
  fetchFn: (data: ProductSearchDTO) => {};
}

interface IDetailPoup {
  detailObj: IDetailObj;
  setDetailObj: React.Dispatch<React.SetStateAction<IDetailObj>>;
}

export const DetailPopup = ({ detailObj, setDetailObj }: IDetailPoup) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  // 1. 기본구성
  const [productObj, setProductObj] = useState<ProductDetailDTO>();
  // 2. 제품구성
  const [modelStockObj, setModelStockObj] = useState({
    visible: false,
    returns: null,
  });
  // 3. 상품사진
  const [files, setFiles] = useState<{ PRODUCT_INSTALL: FileDTOForProductDetail[]; PRODUCT_SELLING: FileDTOForProductDetail[] }>();
  // 4. 매뉴얼
  const [productManuals, setProductManuals] = useState<{ INSTALL_MANUAL: ProductManualDTOForProductDetail[]; CUSTOMER_MANUAL: ProductManualDTOForProductDetail[] }>();

  const [currentTab, setCurrentTab] = useState(0);
  const hasTabs = [{ title: '기본정보' }, { title: '제품 구성' }, { title: '상품사진' }, { title: '매뉴얼' }];

  useEffect(() => {
    if (detailObj?.item !== null) {
      fetchDetail(detailObj?.item?.productSeq);
    } else {
      setProductManuals({
        INSTALL_MANUAL: [],
        CUSTOMER_MANUAL: [],
      });
      setFiles({
        PRODUCT_INSTALL: [],
        PRODUCT_SELLING: [],
      });
    }
  }, []);

  useEffect(() => {
    if (modelStockObj?.returns) {
      const models = gridRef.current.getGridData();
      modelStockObj?.returns?.forEach((row) => {
        models.push({
          ...row,
          availableLoanFlagKr: row.availableLoanFlag ? 'O' : 'X',
          availableInvoiceInstallFlagKr: row.availableInvoiceInstallFlag ? 'O' : 'X',
          giftFlag: row.giftFlag ? row.giftFlag : false,
          modelGroupLargeKr: MASTER_OBJ?.MODEL_GRP_1?.[row.modelGroupLarge],
          modelGroupMediumKr: MASTER_OBJ?.MODEL_GRP_2?.[row.modelGroupMedium],
        });
      });
      gridRef.current.setGridData(models);
    }
  }, [modelStockObj?.returns]);

  const checkFlags = (rowIndex, item) => {
    const items = gridRef.current.getGridData();
    items[rowIndex] = item;

    gridRef.current.setGridData(items);
  };

  const fetchDetail = async (productSeq: number) => {
    dispatch(setLoading('GET'));
    const data = (await serviceStore.orderAction(`product/${productSeq}`, 'GET', null))?.data;
    if (data) {
      setProductObj(data);
      setProductManuals({
        INSTALL_MANUAL: data?.productManuals?.filter((ele) => ele.manualType === 'INSTALL_MANUAL'),
        CUSTOMER_MANUAL: data?.productManuals?.filter((ele) => ele.manualType === 'CUSTOMER_MANUAL'),
      });
      setFiles({
        PRODUCT_INSTALL: data?.files?.filter((ele) => ele.column?.includes('PRODUCT_INSTALL')),
        PRODUCT_SELLING: data?.files?.filter((ele) => ele.column?.includes('PRODUCT_SELLING')),
      });
      const rows = data?.productModels?.map((row) => {
        return {
          ...row,
          giftFlag: row.giftFlag ? row.giftFlag : false,
          availableLoanFlagKr: row.availableLoanFlag ? 'O' : 'X',
          availableInvoiceInstallFlagKr: row.availableInvoiceInstallFlag ? 'O' : 'X',
          modelGroupLargeKr: MASTER_OBJ?.MODEL_GRP_1?.[row.modelGroupLarge],
          modelGroupMediumKr: MASTER_OBJ?.MODEL_GRP_2?.[row.modelGroupMedium],
          productModelUpdateFlag: detailObj?.item?.productModelUpdateFlag,
        };
      });
      gridRef.current.setGridData(rows);

      if (data?.modelGroupLarge) {
        const thisM = MASTER_OPS?.[`${RAWDATA}MODEL_GRP_1`]?.filter((ele) => ele.code === data?.modelGroupLarge)[0];
        setModelMOp(
          thisM
            ? thisM.subCodes?.map((ele) => {
                return {
                  value: ele.code,
                  label: ele.codeName,
                };
              })
            : [],
        );
      }
    }
    dispatch(setLoading(null));
  };

  const handleFileRemove = (e) => {
    dispatch(setLoading('POST'));
    const id = e.target.id;
    const idx = id.split('_')[2];
    if (id?.includes('PRODUCT_SELLING')) {
      const PRODUCT_SELLING = files?.PRODUCT_SELLING?.filter((_, i) => i !== Number(idx));
      setFiles((prev) => {
        return {
          ...prev,
          PRODUCT_SELLING,
        };
      });
    } else {
      const PRODUCT_INSTALL = files?.PRODUCT_INSTALL?.filter((_, i) => i !== Number(idx));
      setFiles((prev) => {
        return {
          ...prev,
          PRODUCT_INSTALL,
        };
      });
    }
    dispatch(setLoading(null));
  };

  const handleFileUpload = async (e) => {
    dispatch(setLoading('POST'));
    const id = e.target.id;
    const returns = await handleFiles(e);
    const res = await Promise.all(
      returns?.map(async (ret) => {
        return await uploadFile(ret.file, ret.url);
      }),
    );
    if (id === 'ADD_PRODUCT_SELLING') {
      const PRODUCT_SELLING = JSON.parse(JSON.stringify(files?.PRODUCT_SELLING));
      res.forEach((uploadUrl, idx) => {
        PRODUCT_SELLING.push({
          column: `PRODUCT_SELLING_${uuidv4().split('-')[4]}`,
          uploadUrl,
          fileName: `판매완료사진_${uuidv4().split('-')[4]}`,
        });
      });
      setFiles((prev) => {
        return {
          ...prev,
          PRODUCT_SELLING,
        };
      });
    } else {
      const PRODUCT_INSTALL = JSON.parse(JSON.stringify(files?.PRODUCT_INSTALL));
      res.forEach((uploadUrl, idx) => {
        PRODUCT_INSTALL.push({
          column: `PRODUCT_INSTALL_${uuidv4().split('-')[4]}`,
          uploadUrl,
          fileName: `판매완료사진_${uuidv4().split('-')[4]}`,
        });
      });
      setFiles((prev) => {
        return {
          ...prev,
          PRODUCT_INSTALL,
        };
      });
    }
    dispatch(setLoading(null));
  };

  const makePostObj = (productObj: ProductDetailDTO) => {
    const productModels = gridRef.current.getGridData();
    const data: ProductDetailDTO = {
      ...productObj,
      productModels,
      productManuals: [...productManuals?.CUSTOMER_MANUAL, ...productManuals?.INSTALL_MANUAL],
      files: [...files?.PRODUCT_SELLING, ...files?.PRODUCT_INSTALL],
    };
    return data;
  };

  const validationForSave = (data: ProductDetailDTO) => {
    if (!productObj?.partnerSeq) {
      alert('판매사는 필수값입니다!');
      return false;
    }
    if (!productObj?.productName) {
      alert('상품명은 필수값입니다!');
      return false;
    }
    if (!productObj?.memberCount) {
      alert('배송팀 유형은 필수값입니다!');
      return false;
    }
    if (!productObj?.installDuration) {
      alert('설치소요 시간은 필수값입니다!');
      return false;
    }

    if (!productObj?.modelGroup) {
      alert('제품그룹은 필수값입니다!');
      return false;
    }

    if (!productObj?.modelGroupLarge) {
      alert('대분류는  필수값입니다!');
      return false;
    }

    if (!productObj?.modelGroupMedium) {
      alert('중분류는 필수값입니다!');
      return false;
    }
    const products = gridRef.current.getGridData();
    if (!products || products?.length === 0) {
      alert('상품으로 구성할 제품 값이 없습니다!');
      return false;
    }
    if (products?.filter((ele) => !ele.quantity)?.length > 0) {
      alert('수량이 입력되지 않은 제품이 있습니다!');
      return false;
    }
    return true;
  };

  const saveProduct = async (data: ProductDetailDTO) => {
    if (validationForSave(data)) {
      dispatch(setLoading('POST'));
      const obj = makePostObj(data);
      const rs = await serviceStore.orderAction('product/save', 'POST', null, obj);
      if (rs?.status === 200) {
        alert('상품을 저장하였습니다!');

        let searchObj = defaultSeachObj;
        if (detailObj?.searchObj) searchObj = JSON.parse(detailObj?.searchObj);
        detailObj.fetchFn(searchObj);
        setDetailObj(null);
      }
      dispatch(setLoading(null));
    }
  };

  const [modelDetailObj, setModelDetailObj] = useState<IModelDetailObj>();

  const popupModelDetail = (e: IGrid.ButtonClickEvent) => {
    setModelDetailObj({
      visible: true,
      item: e.item,
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupModelDetail,
      },
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '제품대분류',
      dataField: 'modelGroupLargeKr',
    },
    {
      headerText: '제품중분류',
      dataField: 'modelGroupMediumKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '수량',
      dataField: 'quantity',
      style: 'aui-textinputer',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        autoThousandSeparator: true,
        onlyNumeric: true,
        allowNegative: false,
      },
    },
    {
      headerText: '사은품여부',
      dataField: 'giftFlag',
      width: 60,
      renderer: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
        checkValue: true,
        checkableFunction(rowIndex, columnIndex, value, isChecked, item, dataField) {
          checkFlags(rowIndex, item);
          return true;
        },
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, isChecked: boolean, item: any, dataField: string) => {
          return item?.productModelUpdateFlag === false ? true : false;
        },
      },
    },
    {
      headerText: '바코드 스캔',
      children: [
        {
          headerText: '기사출고시',
          dataField: 'availableLoanFlagKr',
        },
        {
          headerText: '설치완료시',
          dataField: 'availableInvoiceInstallFlagKr',
        },
      ],
    },
  ];

  const [modelMOp, setModelMOp] = useState<OptionItem[]>();

  return (
    <CommonModalNew
      style={{ width: 900 }}
      title={detailObj?.item ? `상품 상세` : '상품 등록'}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      rightTitle={
        <>
          <Button
            className="btn-secondary btn ssm"
            onClick={(e) => {
              saveProduct(productObj);
            }}
          >
            {detailObj?.item ? '수정' : '등록'}
          </Button>
        </>
      }
      children={
        <div style={{ minHeight: 520 }}>
          <div className="div-top-tab">
            {hasTabs.map((tab, index) => {
              return (
                <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                  <li
                    onClick={() => {
                      setCurrentTab(index);
                    }}
                  >
                    {tab?.title}
                  </li>
                </div>
              );
            })}
          </div>

          <div style={{ flexDirection: 'column', zIndex: currentTab === 0 ? 13 : 10 }}>
            <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
              <thead>
                <tr>
                  <th colSpan={12}>1. 기본정보</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>*판매사</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={detailObj?.item !== null}
                      options={MASTER_OPS?.SELLER_SELLER_AUTH}
                      value={
                        productObj?.partnerSeq
                          ? {
                              value: productObj?.partnerSeq,
                              label: MASTER_OBJ?.SELLER_WHOLE?.[productObj?.partnerSeq],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setProductObj((prev) => {
                          return {
                            ...prev,
                            partnerSeq: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>브랜드</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={detailObj?.item !== null}
                      options={MASTER_OPS?.BRAND}
                      value={
                        productObj?.brand
                          ? {
                              value: productObj?.brand,
                              label: MASTER_OBJ?.BRAND?.[productObj?.brand],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setProductObj((prev) => {
                          return {
                            ...prev,
                            brand: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*상품명</th>
                  <td colSpan={5}>
                    <InputD
                      value={productObj?.productName || ''}
                      onChange={(e) => {
                        setProductObj((prev) => {
                          return {
                            ...prev,
                            productName: e.target.value,
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>상품번호</th>
                  <td colSpan={2}>
                    {detailObj?.item ? <InputD readOnly disabled={detailObj?.item !== null} value={productObj?.productSeq || ''} /> : <InputD disabled readOnly value={'자동선택'} />}
                  </td>
                  <th>외부상품번호</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={detailObj?.item !== null}
                      value={productObj?.externalProductNumber || ''}
                      onChange={(e) => {
                        setProductObj((prev) => {
                          return {
                            ...prev,
                            externalProductNumber: e.target.value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*배송팀 유형</th>
                  <td colSpan={2}>
                    <SelectD
                      options={MASTER_OPS?.TEAM_TYPE}
                      value={
                        productObj?.memberCount
                          ? {
                              value: productObj?.memberCount,
                              label: MASTER_OBJ?.TEAM_TYPE?.[productObj?.memberCount],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setProductObj((prev) => {
                          return {
                            ...prev,
                            memberCount: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*설치소요시간</th>
                  <td colSpan={2}>
                    <SelectD
                      options={MASTER_OPS?.INSTALL_DURATION}
                      value={
                        selectlabel(productObj?.installDuration, MASTER_OPS?.INSTALL_DURATION)
                          ? {
                              value: productObj?.installDuration,
                              label: selectlabel(productObj?.installDuration, MASTER_OPS?.INSTALL_DURATION),
                            }
                          : null
                      }
                      onChange={(option) => {
                        setProductObj((prev) => {
                          return {
                            ...prev,
                            installDuration: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>*제품그룹</th>
                  <td colSpan={2}>
                    <SelectD
                      hasNull={false}
                      options={MASTER_OPS?.MODEL_GROUP}
                      value={
                        productObj?.modelGroup
                          ? {
                              value: productObj?.modelGroup,
                              label: selectlabel(productObj?.modelGroup, MASTER_OPS?.MODEL_GROUP),
                            }
                          : null
                      }
                      onChange={(option) => {
                        setProductObj((prev) => {
                          return {
                            ...prev,
                            modelGroup: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*대분류</th>
                  <td colSpan={2}>
                    <SelectD
                      options={MASTER_OPS?.MODEL_GRP_1}
                      value={
                        productObj?.modelGroupLarge
                          ? {
                              value: productObj?.modelGroupLarge,
                              label: selectlabel(productObj?.modelGroupLarge, MASTER_OPS?.MODEL_GRP_1),
                            }
                          : null
                      }
                      onChange={(option) => {
                        const thisL = MASTER_OPS?.[`${RAWDATA}MODEL_GRP_1`]?.filter((ele) => ele.code === (option as OptionItem)?.value)[0];
                        setModelMOp(
                          thisL
                            ? thisL.subCodes?.map((ele) => {
                                return {
                                  value: ele.code,
                                  label: ele.codeName,
                                };
                              })
                            : [],
                        );
                        setProductObj((prev) => {
                          return {
                            ...prev,
                            modelGroupLarge: (option as OptionItem)?.value,
                            modelGroupMedium: null,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*중분류</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={!productObj?.modelGroupLarge}
                      options={modelMOp}
                      onChange={(option) => {
                        setProductObj((prev) => {
                          return {
                            ...prev,
                            modelGroupMedium: (option as OptionItem)?.value,
                          };
                        });
                      }}
                      value={
                        productObj?.modelGroupMedium
                          ? {
                              value: productObj?.modelGroupMedium,
                              label: selectlabel(productObj?.modelGroupMedium, modelMOp),
                            }
                          : null
                      }
                    />
                  </td>
                  <th colSpan={3}></th>
                </tr>
                <tr>
                  <th>구성품수량</th>
                  <td colSpan={2}>{detailObj?.item ? <InputD disabled readOnly value={productObj?.quantity || ''} /> : <InputD disabled readOnly value={'자동선택'} />}</td>
                  <th>
                    사전방문
                    <br />
                    필요여부
                  </th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled
                      options={MASTER_OPS?.NEED_FLAG}
                      // value={productObj?}
                    />
                  </td>
                  <th>판매상태</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled
                      // value={detailObj?.item?.useFlag}
                      // options={masterOptions?.OX_FLAG}
                      // value={productObj?}
                    />
                  </td>
                  <th colSpan={3}></th>
                </tr>
              </tbody>
            </table>
          </div>

          <div style={{ flexDirection: 'column', zIndex: currentTab === 1 ? 13 : 10 }}>
            {modelDetailObj?.visible && <ModelDetailPopup detailObj={modelDetailObj} setDetailObj={setModelDetailObj} />}
            {modelStockObj?.visible && (
              <ModelStockModal
                //
                modelStockObj={modelStockObj}
                setModelStockObj={setModelStockObj}
              />
            )}
            {/* 등록시에만 수정가능함 */}
            {(!detailObj?.item || detailObj?.item?.productModelUpdateFlag) && (
              <div className="grid-button-area space-between">
                <div>
                  <div
                    className="blue"
                    onClick={() => {
                      if (!productObj?.partnerSeq) {
                        alert('"1. 기본정보"에서 판매사를 먼저 선택해주세요!');
                      } else {
                        setModelStockObj((prev) => {
                          return {
                            ...prev,
                            visible: true,
                            partnerSeq: productObj?.partnerSeq,
                          };
                        });
                      }
                    }}
                  >
                    +제품추가
                  </div>

                  <div
                    className="red"
                    onClick={() => {
                      const items = gridRef.current.getCheckedRowItems();
                      const wholeRows = gridRef.current.getGridData();
                      if (items?.length > 0) {
                        const checked = items.map((ele) => ele.rowIndex);
                        const filtered = wholeRows.filter((_, idx) => !checked.includes(idx));
                        gridRef.current.setGridData(filtered);
                      } else {
                        alert('선택된 항목이 존재하지 않습니다.');
                      }
                    }}
                  >
                    - 행삭제
                  </div>
                </div>
                <div></div>
              </div>
            )}
            <div style={{ width: '100%' }}>
              <GridBox
                gridRef={gridRef}
                columns={columns}
                gridProps={{
                  fixedColumnCount: 1,
                  showRowAllCheckBox: true,
                  showRowCheckColumn: true,
                  height: 400,
                  width: 1000,
                  editable: detailObj?.item === null || detailObj?.item?.productModelUpdateFlag,
                }}
              />
            </div>
          </div>

          <div style={{ flexDirection: 'column', zIndex: currentTab === 2 ? 13 : 10 }}>
            <div className="inputSmaillTitle">설치완료사진</div>
            <div className="grid-wrapper">
              {files?.PRODUCT_INSTALL?.map((photo, i) => {
                return (
                  <div className="grid-ele" key={`PRODUCT_INSTALL_${i}_${photo.uploadUrl}}`}>
                    <div className="photo-zone">
                      <span className="bck-font">{i + 1}</span>
                      {photo && (
                        <div style={{ display: 'inline-block' }}>
                          <img
                            src={CloseImg}
                            id={`PRODUCT_INSTALL_${i}`}
                            alt=""
                            onClick={(e) => {
                              handleFileRemove(e);
                            }}
                            className="closeBtn"
                          />
                          <ImageModal photoArray={files?.PRODUCT_INSTALL} idx={i} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="grid-ele">
                <div className="photo-zone">
                  <span className="bck-font">추가</span>
                  <input style={{ display: 'none' }} type="file" multiple accept="image/*" id={'ADD_PRODUCT_INSTALL'} className="ADD_PRODUCT_INSTALL" onChange={handleFileUpload} />
                  <Button size="md" color="primary" outline>
                    <Label for={`ADD_PRODUCT_INSTALL`} style={{ marginBottom: 0 }}>
                      ㅤㅤㅤ파일 선택ㅤㅤㅤ
                    </Label>
                  </Button>
                </div>
              </div>
            </div>
            <div className="inputSmaillTitle">판매사진</div>
            <div className="grid-wrapper">
              {files?.PRODUCT_SELLING?.map((photo, i) => {
                return (
                  <div className="grid-ele" key={`PRODUCT_SELLING_${i}_${photo.uploadUrl}}`}>
                    <div className="photo-zone">
                      <span className="bck-font">판매사진{i + 1}</span>
                      {photo && (
                        <div style={{ display: 'inline-block' }}>
                          <img
                            src={CloseImg}
                            id={`PRODUCT_SELLING_${i}`}
                            alt=""
                            onClick={(e) => {
                              handleFileRemove(e);
                            }}
                            className="closeBtn"
                          />
                          <ImageModal photoArray={files?.PRODUCT_SELLING} idx={i} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

              <div className="grid-ele">
                <div className="photo-zone">
                  <span className="bck-font">추가</span>
                  <input style={{ display: 'none' }} type="file" multiple accept="image/*" id={'ADD_PRODUCT_SELLING'} className="ADD_PRODUCT_SELLING" onChange={handleFileUpload} />
                  <Button size="md" color="primary" outline>
                    <Label for={`ADD_PRODUCT_SELLING`} style={{ marginBottom: 0 }}>
                      ㅤㅤㅤ파일 선택ㅤㅤㅤ
                    </Label>
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div style={{ flexDirection: 'column', zIndex: currentTab === 3 ? 13 : 10 }}>
            <table className="border-table detail-table no-td-line" style={{ width: '100%', marginBottom: 30, height: 'fit-content' }}>
              <thead>
                <tr>
                  <th colSpan={12}>1. 설치 매뉴얼</th>
                </tr>
              </thead>
              <tbody>
                {productManuals?.INSTALL_MANUAL?.map((ele: ProductManualDTOForProductDetail, idx) => {
                  return (
                    <Fragment key={`manual_install${idx}`}>
                      <tr>
                        <th>매뉴얼 유형</th>
                        <td colSpan={2}>
                          <SelectD
                            isDisabled
                            value={{
                              value: ele.manualType,
                              label: MASTER_OBJ?.PRODUCT_MANUAL_TYPE?.[ele.manualType],
                            }}
                          />
                        </td>
                        <th colSpan={2}>설치앱 노출 여부</th>
                        <td colSpan={2}>
                          <div style={{ display: 'flex', height: 30, alignItems: 'center', justifyContent: 'center' }}>
                            {MASTER_OPS?.OX_FLAG?.map((pEle, oi) => {
                              return (
                                <span key={`installAppFlag_${oi}`} style={{ display: 'inline-flex', width: 50, justifyContent: 'space-evenly', alignItems: 'flex-end' }}>
                                  <label>{pEle.label}</label>
                                  <InputD
                                    type="radio"
                                    checked={ele?.installAppFlag + '' === pEle.value}
                                    onChange={() => {
                                      const INSTALL_MANUAL = productManuals?.INSTALL_MANUAL;
                                      INSTALL_MANUAL[idx]['installAppFlag'] = pEle.value;
                                      setProductManuals((prev) => {
                                        return {
                                          ...prev,
                                          INSTALL_MANUAL,
                                        };
                                      });
                                    }}
                                  />
                                </span>
                              );
                            })}
                          </div>
                        </td>
                        <th colSpan={2}>고객문자 첨부여부</th>
                        <td colSpan={2}>
                          <div style={{ display: 'flex', height: 30, alignItems: 'center', justifyContent: 'center' }}>
                            {MASTER_OPS?.OX_FLAG?.map((pEle, oi) => {
                              return (
                                <span key={`smsFlag_${oi}`} style={{ display: 'inline-flex', width: 50, justifyContent: 'space-evenly', alignItems: 'flex-end' }}>
                                  <label>{pEle.label}</label>
                                  <InputD
                                    type="radio"
                                    checked={ele?.smsFlag + '' === pEle.value}
                                    onChange={() => {
                                      const INSTALL_MANUAL = productManuals?.INSTALL_MANUAL;
                                      INSTALL_MANUAL[idx]['smsFlag'] = pEle.value;
                                      setProductManuals((prev) => {
                                        return {
                                          ...prev,
                                          INSTALL_MANUAL,
                                        };
                                      });
                                    }}
                                  />
                                </span>
                              );
                            })}
                          </div>
                        </td>
                        <td rowSpan={3} style={{ width: 60 }}>
                          <button
                            className="btn btn-danger outline sssm"
                            onClick={() => {
                              const _manualObj = JSON.parse(JSON.stringify(productManuals));
                              const INSTALL_MANUAL = _manualObj.INSTALL_MANUAL?.filter((_, i) => i !== idx);
                              setProductManuals((prev) => {
                                return {
                                  ...prev,
                                  INSTALL_MANUAL,
                                };
                              });
                            }}
                          >
                            삭제
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th>*내용</th>
                        <td colSpan={10}>
                          <InputD
                            value={ele.content || ''}
                            onChange={(e) => {
                              const INSTALL_MANUAL = productManuals?.INSTALL_MANUAL;
                              INSTALL_MANUAL[idx]['content'] = e.target.value;
                              setProductManuals((prev) => {
                                return {
                                  ...prev,
                                  INSTALL_MANUAL,
                                };
                              });
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>*링크</th>
                        <td colSpan={10}>
                          <InputD
                            //
                            value={ele.url || ''}
                            onChange={(e) => {
                              const INSTALL_MANUAL = productManuals?.INSTALL_MANUAL;
                              INSTALL_MANUAL[idx]['url'] = e.target.value;
                              setProductManuals((prev) => {
                                return {
                                  ...prev,
                                  INSTALL_MANUAL,
                                };
                              });
                            }}
                          />
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
                <tr style={{ textAlign: 'center', backgroundColor: '#EFF2F7' }}>
                  <td colSpan={12}>
                    <button
                      className="btn btn-blue outline ssm"
                      onClick={() => {
                        const _manualObj = JSON.parse(JSON.stringify(productManuals));
                        _manualObj?.INSTALL_MANUAL?.push({
                          manualType: 'INSTALL_MANUAL',
                          content: '',
                          url: '',
                          installAppFlag: false,
                          smsFlag: false,
                        });
                        setProductManuals(_manualObj);
                      }}
                    >
                      + 추가
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="border-table detail-table no-td-line" style={{ width: '100%', marginBottom: 30, height: 'fit-content' }}>
              <thead>
                <tr>
                  <th colSpan={12}>2. 고객 매뉴얼</th>
                </tr>
              </thead>
              <tbody>
                {productManuals?.CUSTOMER_MANUAL?.map((ele: ProductManualDTOForProductDetail, idx) => {
                  return (
                    <Fragment key={`manual_install${idx}`}>
                      <tr>
                        <th>매뉴얼 유형</th>
                        <td colSpan={2}>
                          <SelectD
                            isDisabled
                            value={{
                              value: ele.manualType,
                              label: MASTER_OBJ?.PRODUCT_MANUAL_TYPE?.[ele.manualType],
                            }}
                          />
                        </td>
                        <th colSpan={2}>고객문자 첨부여부</th>
                        <td colSpan={2}>
                          <div style={{ display: 'flex', height: 30, alignItems: 'center', justifyContent: 'center' }}>
                            {MASTER_OPS?.OX_FLAG?.map((pEle, oi) => {
                              return (
                                <span key={`smsFlag_${oi}`} style={{ display: 'inline-flex', width: 50, justifyContent: 'space-evenly' }}>
                                  <label>{pEle.label}</label>
                                  <input
                                    type="radio"
                                    checked={ele?.smsFlag + '' === pEle.value}
                                    onChange={() => {
                                      const CUSTOMER_MANUAL = productManuals?.CUSTOMER_MANUAL;
                                      CUSTOMER_MANUAL[idx]['smsFlag'] = pEle.value;
                                      setProductManuals((prev) => {
                                        return {
                                          ...prev,
                                          CUSTOMER_MANUAL,
                                        };
                                      });
                                    }}
                                  />
                                </span>
                              );
                            })}
                          </div>
                        </td>
                        <td colSpan={4} />
                        <td rowSpan={3} style={{ width: 60 }}>
                          <button
                            className="btn btn-danger outline sssm"
                            onClick={() => {
                              const _manualObj = JSON.parse(JSON.stringify(productManuals));
                              const CUSTOMER_MANUAL = _manualObj.CUSTOMER_MANUAL?.filter((_, i) => i !== idx);
                              setProductManuals((prev) => {
                                return {
                                  ...prev,
                                  CUSTOMER_MANUAL,
                                };
                              });
                            }}
                          >
                            삭제
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th>*내용</th>
                        <td colSpan={10}>
                          <InputD
                            value={ele.content || ''}
                            onChange={(e) => {
                              const CUSTOMER_MANUAL = productManuals?.CUSTOMER_MANUAL;
                              CUSTOMER_MANUAL[idx]['content'] = e.target.value;
                              setProductManuals((prev) => {
                                return {
                                  ...prev,
                                  CUSTOMER_MANUAL,
                                };
                              });
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>*링크</th>
                        <td colSpan={10}>
                          <InputD
                            //
                            value={ele.url || ''}
                            onChange={(e) => {
                              const CUSTOMER_MANUAL = productManuals?.CUSTOMER_MANUAL;
                              CUSTOMER_MANUAL[idx]['url'] = e.target.value;
                              setProductManuals((prev) => {
                                return {
                                  ...prev,
                                  CUSTOMER_MANUAL,
                                };
                              });
                            }}
                          />
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
                <tr style={{ textAlign: 'center', backgroundColor: '#EFF2F7' }}>
                  <td colSpan={12}>
                    <button
                      className="btn btn-blue outline ssm"
                      onClick={() => {
                        const _manualObj = JSON.parse(JSON.stringify(productManuals));
                        _manualObj?.CUSTOMER_MANUAL?.push({
                          manualType: 'CUSTOMER_MANUAL',
                          content: '',
                          url: '',
                          installAppFlag: false,
                          smsFlag: false,
                        });
                        setProductManuals(_manualObj);
                      }}
                    >
                      + 추가
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
    />
  );
};
