import { useEffect, useMemo, useRef, useState } from 'react';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IAddingPopupObj, SearchModelForRefurModal } from './component/searchModelForRefur';
import { Col, Row } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { inventoryService } from 'pages/INVENTORY/1inventory/_service/service';
import { INVENTORY_MODEL_MOVE_INFO } from 'envVar';
import { XLSX, checkHTML5Brower, fixdata, parseCvs, process_wb, rABS } from 'common/util/fileUploader';
import { httpClient } from 'common/http-client/axiosConfig';
import { INV_TRANSFER_UPLOAD_FORM } from 'envVar2';
import { serviceStore } from 'services/services';
import { InventoryModelRefurbishSaveDTO } from 'interface/warehouse';
import { generateUrlByDesignType } from 'components/LDS/utils/path';

export interface ICreateObj {
  centerCode?: string;
  partnerSeq?: string;
}

const Index = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const gridRef = useRef<IPagingGrid>();
  const { masterOptions, MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);

  const [addingPopupObj, setAddingPopupObj] = useState<IAddingPopupObj>();

  const defaultSearchFilter = useMemo(() => {
    return {
      centerCode: reduxUserInfo['centerCode'], // 창고
      partnerSeq: MASTER_OPS?.SELLER_SELLER_WHOLE.find((seller) => seller.value === reduxUserInfo['partner']) ? reduxUserInfo['partner'] : null, // 판매사
    };
  }, [reduxUserInfo]);

  const [createObj, setCreateObj] = useState<ICreateObj>(defaultSearchFilter);

  const columns: IGrid.Column[] = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '변경전 제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'beforeModelGroupKr',
        },
        {
          headerText: '제품',
          dataField: 'beforeModel',
        },
        {
          headerText: '제품명',
          dataField: 'beforeModelName',
        },
        {
          headerText: 'SKU',
          dataField: 'beforeModelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'beforeSkuNumber',
        },

        {
          headerText: '바코드',
          dataField: 'beforeBarcode',
        },
      ],
    },
    {
      headerText: '변경후 제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'afterModelGroupKr',
        },
        {
          headerText: '제품',
          dataField: 'afterModel',
        },
        {
          headerText: '제품명',
          dataField: 'afterModelName',
        },
        {
          headerText: 'SKU',
          dataField: 'afterModelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'afterSkuNumber',
        },

        {
          headerText: '바코드',
          dataField: 'afterBarcode',
        },
      ],
    },
    {
      headerText: '재고상태',
      dataField: 'availableStatusKr',
    },
    {
      headerText: '메모',
      dataField: 'memo',
      editable: true,
    },
    {
      headerText: '변경전 시리얼번호',
      dataField: 'beforeSerialNumber',
      editable: true,
    },
    {
      headerText: '변경후 시리얼번호',
      dataField: 'afterSerialNumber',
      editable: true,
    },
  ];

  useEffect(() => {
    if (addingPopupObj?.returns) {
      const items = gridRef.current.getGridData();
      if (addingPopupObj?.mode === 'before') {
        addingPopupObj?.returns?.forEach((row) => {
          new Array(Number(row.availableQuantityTo) + Number(row.defectedQuantityTo)).fill(0)?.forEach((_, idx) => {
            const isAvailable = idx < row.availableQuantityTo;
            items.push({
              ...row,
              centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
              partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
              beforeModelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
              beforeModel: row.model,
              beforeModelName: row.modelName,
              beforeModelStockSeq: row.modelStockSeq,
              beforeSkuNumber: row.skuNumber,
              beforeBarcode: row.barcode,
              beforeSerialNumber: '',
              afterSerialNumber: '',
              memo: '',
              isAvailable: isAvailable,
              availableStatusKr: isAvailable ? '양품' : '불용',
              availableQuantity: isAvailable ? 1 : 0,
              defectedQuantity: isAvailable ? 0 : 1,
            });
          });
        });
      } else {
        //
        const after = addingPopupObj?.returns[0];
        const checked = gridRef.current.getCheckedRowItems().map((ele) => ele.rowIndex);
        items?.forEach((row, idx) => {
          if (checked.includes(idx)) {
            row.afterModelGroupKr = MASTER_OBJ?.MODEL_GROUP?.[after?.modelGroup];
            row.afterModel = after.model;
            row.afterModelName = after.modelName;
            row.afterModelStockSeq = after.modelStockSeq;
            row.afterSkuNumber = after.skuNumber;
            row.afterBarcode = after.barcode;
          }
        });
      }

      gridRef.current.setGridData(items);
    }
  }, [addingPopupObj?.returns]);

  const validation = (rows) => {
    if (rows?.length === 0) {
      alert('리퍼를 생성할 제품이 입력되지 않았습니다.');
      return false;
    }
    const empty = rows.filter((ele) => !ele.afterModelStockSeq);
    if (empty?.length > 0) {
      alert('변경 후 제품정보가 입력되지 않은 건이 있습니다!');
      return false;
    }

    return true;
  };

  const createMove = async () => {
    const rows = gridRef.current.getGridData();
    if (validation(rows)) {
      const data = rows.map((row) => {
        return {
          centerCode: row.centerCode,
          locationCode: row.locationCode,
          availableStatus: row.isAvailable ? 'AVAILABLE' : 'DEFECTED',
          partnerSeq: row.partnerSeq,
          beforeModelStockSeq: row.beforeModelStockSeq,
          beforeSerialNumber: row.beforeSerialNumber,
          afterModelStockSeq: row.afterModelStockSeq,
          afterSerialNumber: row.afterSerialNumber,
          memo: row.memo,
        };
      });
      dispatch(setLoading('POST'));
      const rs = await serviceStore.warehouseAction(`inventory/model/refurbish/save`, 'POST', null, data as InventoryModelRefurbishSaveDTO[], false);
      if (rs?.status === 200) {
        alert('리퍼 생성에 성공하였습니다!');
        history(generateUrlByDesignType('/refur'));
      }
      dispatch(setLoading(null));
    }
  };

  const fileSelector = (evt) => {
    try {
      if (!checkHTML5Brower()) {
        alert('브라우저가 HTML5 를 지원하지 않습니다.\r\n서버로 업로드해서 해결하십시오.');
        return;
      }

      const file = evt.target.files[0];
      if (typeof file === 'undefined') {
        return;
      }

      const reader = new FileReader();

      reader.onload = function (e) {
        const data = e.target.result;
        let workbook;
        if (rABS) {
          workbook = XLSX.read(data, { type: 'binary' });
        } else {
          var arr = fixdata(data);
          workbook = XLSX.read(Buffer.from(arr, 'base64'), { type: 'base64' });
        }
        var jsonObj = process_wb(workbook);
        if (jsonObj[Object.keys(jsonObj)[0]]) {
          const parseExcelDataArr = parseCvs(jsonObj[Object.keys(jsonObj)[0]]);
          const addArr = [];
          const colField = ['locationCode', 'beforeBarcode', 'beforeSerialNumber', 'afterBarcode', 'afterSerialNumber', 'availableStatusKr'];
          parseExcelDataArr.forEach((textArr = [], idx) => {
            if (idx !== 0) {
              const obj = {};
              for (let index = 0; index < textArr.length; index++) {
                obj[colField[index]] = textArr[index];
              }
              addArr.push(obj);
            }
          });
          const searchDTO = addArr.map((row) => {
            return {
              ...row,
              availableStatus: row.availableStatusKr === '양품' ? 'AVAILABLE' : 'DEFECTED',
              centerCode: createObj?.centerCode,
              partnerSeq: createObj?.partnerSeq,
            };
          });
          getSearch(searchDTO);
        }
      };

      reader.onloadend = () => {
        evt.target.value = '';
      };

      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {}
  };

  const getSearch = async (data) => {
    dispatch(setLoading('GET'));
    await httpClient.post(INVENTORY_MODEL_MOVE_INFO, data).then((rs) => {
      if (rs?.status === 200) {
        const data = rs?.data?.map((row) => {
          return {
            ...row,
            centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
            partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
            beforeModelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.beforeModelGroup],
            afterModelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.afterModelGroup],
            availableStatusKr: row.availableStatus === 'AVAILABLE' ? '양품' : row.availableStatus === 'DEFECTED' ? '불용' : '',
            isAvailable: row.availableStatus === 'AVAILABLE' ? true : false,
          };
        });
        gridRef.current.setGridData(data);
      }
    });
    dispatch(setLoading(null));
  };

  return (
    <div className="page-content">
      {addingPopupObj?.visible && <SearchModelForRefurModal addingPopupObj={addingPopupObj} setAddingPopupObj={setAddingPopupObj} />}
      <div className="presenterSearch">
        <Row>
          <Col>
            <label className="col-form-label">창고*</label>
            <SelectD
              options={MASTER_OPS?.CENTER_AUTH}
              value={
                createObj?.centerCode
                  ? {
                      value: createObj?.centerCode,
                      label: MASTER_OBJ?.CENTER_AUTH?.[createObj?.centerCode],
                    }
                  : null
              }
              onChange={(option) => {
                setCreateObj({
                  ...createObj,
                  centerCode: (option as OptionItem).value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">판매사*</label>
            <SelectD
              options={MASTER_OPS?.SELLER_SELLER_AUTH}
              value={
                createObj?.partnerSeq
                  ? {
                      value: createObj?.partnerSeq,
                      label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[createObj?.partnerSeq],
                    }
                  : null
              }
              onChange={(option) => {
                setCreateObj({
                  ...createObj,
                  partnerSeq: (option as OptionItem).value,
                });
              }}
            />
          </Col>
          <Col />
          <Col />
          <Col />
          <Col style={{ textAlign: 'right' }}>
            <button
              className="btn btn-blue"
              onClick={() => {
                createMove();
              }}
            >
              리퍼 생성
            </button>
          </Col>
        </Row>
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area space-between">
          <div>
            <div
              className="orange"
              onClick={() => {
                if (!createObj?.centerCode || !createObj?.partnerSeq) {
                  alert('창고와 판매사를 선택하세요');
                } else {
                  setAddingPopupObj({
                    mode: 'before',
                    visible: true,
                    createObj,
                    returns: null,
                  });
                }
              }}
            >
              + 제품추가(변경 전 제품정보)
            </div>
            <div
              className="orange"
              onClick={() => {
                if (!createObj?.centerCode || !createObj?.partnerSeq) {
                  alert('창고와 판매사를 선택하세요');
                } else {
                  const items = gridRef.current.getCheckedRowItemsAll();
                  if (items?.length > 0) {
                    setAddingPopupObj({
                      mode: 'after',
                      visible: true,
                      createObj,
                      returns: null,
                      choosed: items,
                    });
                  } else {
                    alert('선택된 항목이 존재하지 않습니다.');
                  }
                }
              }}
            >
              + 전환제품추가(변경 후 제품정보)
            </div>
            <div
              className="red"
              onClick={() => {
                const items = gridRef.current.getCheckedRowItems();
                const wholeRows = gridRef.current.getGridData();
                if (items?.length > 0) {
                  const checked = items.map((ele) => ele.rowIndex);
                  const filtered = wholeRows.filter((_, idx) => !checked.includes(idx));
                  gridRef.current.setGridData(filtered);
                } else {
                  alert('선택된 항목이 존재하지 않습니다.');
                }
              }}
            >
              - 행삭제
            </div>
          </div>
          <div>
            <div
              className="green"
              onClick={() => {
                window.open(INV_TRANSFER_UPLOAD_FORM);
              }}
            >
              엑셀 양식
            </div>
            <div
              className="green"
              onClick={() => {
                if (!createObj?.centerCode) {
                  alert('창고 선택은 필수입니다!');
                } else if (!createObj?.partnerSeq) {
                  alert('판매사 선택은 필수입니다!');
                } else {
                  document.getElementById('excelUpload').click();
                }
              }}
            >
              + 엑셀등록
              <InputD style={{ display: 'none' }} onChange={fileSelector} accept=".xlsx" type="file" name="file" id="excelUpload" />
            </div>
          </div>
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            editable: true,
          }}
        />
      </div>
    </div>
  );
};

export default Index;
