import { useEffect, useState } from 'react';
import { IMS_DRIVER_LIST, SCHEDULE_CLOSE_DRIVER_DELIVERYDATE } from 'envVar';
import Presenter from './presenter';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { v4 as uuidv4 } from 'uuid';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { httpClient } from 'common/http-client/axiosConfig';
import { setLoading } from 'redux/services/menuSlice';
const Container = (props) => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state) => state.menu);
  const now = new Date();

  //grid
  const [rows, setRows] = useState();
  const [rows2, setRows2] = useState();
  const [gridId, setGrid] = useState();
  const [gridId2, setGrid2] = useState();

  //기타
  const [filteredOptions, setFilteredOptions] = useState();
  const [searchObj, setSearchObj] = useState({
    deliveryDate: null,
    userId: null,
    centerCode: null,
  });

  useEffect(() => {
    async function driverFollowsCenter() {
      setFilteredOptions({
        ...filteredOptions,
        DRIVER: await driverOptionsInCenter(searchObj?.centerCode),
      });
    }

    if (searchObj?.centerCode !== null) {
      driverFollowsCenter();
    } else {
      // 전체창고
      setFilteredOptions({
        ...filteredOptions,
        DRIVER: masterOptions?.DRIVER,
      });
    }

    setSearchObj({
      ...searchObj,
      userId: null,
    });
  }, [searchObj?.centerCode]);

  useEffect(() => {
    initGrid();
  }, []);

  const initGrid = () => {
    setGrid(`driver_deliveryClosing_main_${uuidv4()}`);
    setGrid2(`driver_deliveryClosing_detail_${uuidv4()}`);
  };

  const driverOptionsInCenter = async (centerCode) => {
    const url = `${IMS_DRIVER_LIST}?centerCode=${centerCode}`;
    return await httpClient.get(url).then((rs) => {
      const result = rs?.status === 200 ? rs.data : [];
      const filtered = [];
      filtered.push({
        value: null,
        label: '기사 전체',
      });
      result.forEach((driver) => {
        filtered.push({
          value: driver.userId,
          label: driver.driverName,
        });
      });

      return filtered;
    });
  };

  const fetchList = async (searchObj) => {
    let url = SCHEDULE_CLOSE_DRIVER_DELIVERYDATE + '?';
    if (searchObj) {
      if (searchObj.userId) {
        url += `&userId=${searchObj.userId}`;
      }
      if (searchObj.centerCode) {
        url += `&centerCode=${searchObj.centerCode}`;
      }
      if (searchObj.deliveryDate) {
        url += `&fromDeliveryDate=${searchObj.deliveryDate[0]}`;
        url += `&toDeliveryDate=${searchObj.deliveryDate[1]}`;
      } else {
        url += `&fromDeliveryDate=${returnDateyyyymmdd(now)}`;
        url += `&toDeliveryDate=${returnDateyyyymmdd(now)}`;
      }
    }

    dispatch(setLoading('GET'));
    await httpClient.get(url).then((rs) => {
      const result = rs.data || {};
      setRows(result);
      if (result.customerOrderInvoices) {
        const results = result.customerOrderInvoices;
        results.forEach((row) => {
          row.feeType = selectlabel(row.feeType, masterOptions?.FEE_TYPE);
          row.invoiceStatus = selectlabel(row.invoiceStatus, masterOptions?.INVOICE_STATUS);
          row.cancelReason = selectlabel(row.cancelReason, masterOptions?.CANCEL_REASON);
          row.customerAddr = row.customerAddr1 + '' + row?.customerAddr2;
          row.promiseFlag = rows?.promiseFlag === true ? 'O' : 'X';
          row.businessFlag = rows?.businessFlag === true ? 'O' : 'X';
          row.ladderTruckFlag = rows?.ladderTruckFlag === true ? 'O' : 'X';
          row.liftingFlag = rows?.liftingFlag === true ? 'O' : 'X';
          row.downFlag = rows?.downFlag === true ? 'O' : 'X';
          row.driverName = selectlabel(row.userId, masterOptions?.DRIVER);
        });
        setRows2(results);
      }
    });
    dispatch(setLoading(null));
  };

  const columns = [
    {
      dataField: 'totalCount',
      headerText: '배정',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'finishCount',
      headerText: '완료',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'notFinishCount',
      headerText: '미완료',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'cancelCount',
      headerText: '취소',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: '',
      headerText: '',
    },
    {
      dataField: 'ladderTruckCount',
      headerText: '사다리차',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'liftingCount',
      headerText: '양중',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'downCount',
      headerText: '내림',
      filter: {
        showIcon: true,
      },
    },
  ];

  const columns2 = [
    {
      dataField: 'feeType',
      headerText: '송장유형',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'invoiceStatus',
      headerText: '송장상태',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'driverName',
      headerText: '기사명',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'cancelReason',
      headerText: '취소사유',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'customerName',
      headerText: '고객',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'finishDatetime',
      headerText: '기사최종완료',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'customerPhone',
      headerText: '연락처1',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'customerAddr',
      headerText: '주소',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'productName',
      headerText: '상품명',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'serialNumber',
      headerText: '시리얼번호',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'promiseFlag',
      headerText: '희망일 준수여부',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'customerAddr',
      headerText: '설치장소',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'ladderTruckFlag',
      headerText: '사다리차',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'liftingFlag',
      headerText: '양중',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'downFlag',
      headerText: '내림',
      filter: {
        showIcon: true,
      },
    },
  ];

  return (
    <Presenter
      rows={rows}
      rows2={rows2}
      columns={columns}
      columns2={columns2}
      gridId={gridId}
      gridId2={gridId2}
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      fetchList={fetchList}
      filteredOptions={filteredOptions}
      masterOptions={masterOptions}
    />
  );
};

export default Container;
