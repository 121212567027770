import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { InventoryInvestigationApplySearchDTO } from '../../../../_interface/warehouse';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = (props: ISearchBoxProps<InventoryInvestigationApplySearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_MODEL: null,
    SEARCH_DATE_APPLY: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE_APPLY, searchObj));
  }, [selectTypeObj?.SEARCH_DATE_APPLY]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">작업지시번호</label>
          <InputD
            type="number"
            value={searchObj?.inventoryInvestigationSeq || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                inventoryInvestigationSeq: Number(e.target.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">재고/시리얼번호</label>
          <InputD
            value={searchObj?.tableNumber || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                tableNumber: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                  }
                : null
            }
            options={MASTER_OPS?.CENTER_AUTH}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">재고/시리얼</label>
          <SelectD
            value={
              searchObj?.investigationType
                ? {
                    value: searchObj?.investigationType,
                    label: MASTER_OBJ?.INVESTIGATION_TYPE?.[searchObj?.investigationType],
                  }
                : null
            }
            options={MASTER_OPS?.INVESTIGATION_TYPE}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                investigationType: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">수불유형</label>
          <SelectD
            value={
              searchObj?.applyType
                ? {
                    label: MASTER_OBJ?.INVESTIGATION_APPLY_TYPE?.[searchObj?.applyType],
                    value: searchObj?.applyType,
                  }
                : null
            }
            options={MASTER_OPS?.INVESTIGATION_APPLY_TYPE}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                applyType: (option as OptionItem)?.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">기간검색</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_DATE_APPLY
                    ? {
                        value: selectTypeObj?.SEARCH_DATE_APPLY,
                        label: MASTER_OBJ?.SEARCH_DATE_APPLY?.[selectTypeObj?.SEARCH_DATE_APPLY],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_DATE_APPLY}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_DATE_APPLY: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <FlatpickrD
                disabled={!selectTypeObj?.SEARCH_DATE_APPLY}
                onClose={(option) => {
                  if (option?.length === 1)
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE_APPLY}`]: null,
                      [`to${selectTypeObj?.SEARCH_DATE_APPLY}`]: null,
                    });
                }}
                value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE_APPLY}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE_APPLY}`]]}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    [`from${selectTypeObj?.SEARCH_DATE_APPLY}`]: returnDateyyyymmdd(option[0]),
                    [`to${selectTypeObj?.SEARCH_DATE_APPLY}`]: returnDateyyyymmdd(option[1]),
                  });
                }}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">유사검색여부</label>
          <div className="radio-select-area">
            <span
              className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: true,
                  };
                });
              }}
            >
              유사검색
            </span>
            <span
              className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: false,
                  };
                });
              }}
            >
              일치검색
            </span>
          </div>
        </Col>
        <Col />
        <Col />
        <Col />
        <Col />
        <Col style={{ textAlign: 'right' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
