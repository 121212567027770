import { ReactNode, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid, PARCEL_GRID_PAGE_ROW_COUNT } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
// service
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useGridButton from 'hooks/grid/useGridButton';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
// component
import { PagingListDTO } from 'interface/util';
import {
  ParcelInvoiceLoadDetailListDTO,
  ParcelInvoiceLoadListDTO,
  ParcelInvoiceLoadSearchDTO,
  ParcelInvoiceUnloadDetailListDTO,
  ParcelInvoiceUnloadListDTO,
  ParcelInvoiceUnloadSearchDTO,
} from '../../../_interface/parcel';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { serviceStore } from 'services/services';
import { getToday } from 'common/util/dateParsingFn';

export const defaultOrderDetailObj = {
  visible: false,
  item: null,
};

const translatorKr = {
  PARCEL_LINEHAUL_LOAD_LIST: '상차',
  PARCEL_LINEHAUL_UNLOAD_LIST: '하차',
};
const translatorAPI = {
  PARCEL_LINEHAUL_LOAD_LIST: 'load',
  PARCEL_LINEHAUL_UNLOAD_LIST: 'unload',
};

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const labellingKr = (data: PagingListDTO<ParcelInvoiceLoadListDTO & ParcelInvoiceUnloadListDTO & ParcelInvoiceLoadDetailListDTO & ParcelInvoiceUnloadDetailListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
        originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.originalCenterCode],
        destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.destinationCenterCode],
        unloadTypeKr: MASTER_OBJ?.PLINE_UNLOAD_TYPE?.[row?.unloadType],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: ParcelInvoiceLoadSearchDTO & ParcelInvoiceUnloadSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(
      (await serviceStore.parcelAction(`linehaul/${translatorAPI[tabId]}/v2/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<ParcelInvoiceLoadListDTO & ParcelInvoiceUnloadListDTO>,
    );
    dispatch(setLoading(null));
    return dataKr;
  };

  const downloadExcel = async (type: 'main' | 'detail') => {
    const prompt = window.confirm(`엑셀다운로드를 시작합니다. \n※ 많은 양의 데이터는 30초 이상의 시간이 소요될 수 있습니다.`);
    if (prompt) {
      dispatch(setLoading('GET'));
      const { list } = labellingKr(
        {
          list:
            type === 'main'
              ? ((await serviceStore.parcelAction(`linehaul/${translatorAPI[tabId]}/v2/excel`, 'GET', searchObj, null))?.data as (ParcelInvoiceLoadListDTO & ParcelInvoiceUnloadListDTO)[])
              : ((await serviceStore.parcelAction(`linehaul/${translatorAPI[tabId]}/v2/excel/detail`, 'GET', searchObj, null))?.data as (ParcelInvoiceLoadDetailListDTO &
                  ParcelInvoiceUnloadDetailListDTO)[]),
        },
        true,
      );
      if (type === 'main') {
        excelGridRef.current.setGridData(list);
        excelGridRef.current.exportAsXlsx({ fileName: `간선${translatorKr[tabId]}(메인)` });
      } else if (type === 'detail') {
        excelDetailGridRef.current.setGridData(list);
        excelDetailGridRef.current.exportAsXlsx({ fileName: `간선${translatorKr[tabId]}(상세)` });
      }
      dispatch(setLoading(null));
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id?.includes('MAIN')) {
      downloadExcel('main');
    } else if (id?.includes('DETAIL')) {
      downloadExcel('detail');
    }
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      fromRegisterDate: getToday(),
      toRegisterDate: getToday(),
    },
    gridRef,
    fetchPaging,
  });

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj, tabId]);

  const openDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const item = e.item;
    const searchData = {
      pageNo: 1,
      pageSize: item?.loadQuantity || item?.unloadQuantity,
      registerDate: item?.registerDate,
      originalCenterCode: item?.originalCenterCode,
      destinationCenterCode: item?.destinationCenterCode,
    };
    //
    const data = (await serviceStore.parcelAction(`linehaul/${translatorAPI[tabId]}/v2/detail`, 'GET', searchData, null, true))?.data?.list;
    const rows = (data as (ParcelInvoiceLoadDetailListDTO & ParcelInvoiceUnloadDetailListDTO)[])?.map((row) => {
      return {
        ...row,
        searchObj: JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
        originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.originalCenterCode],
        destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.destinationCenterCode],
        unloadTypeKr: MASTER_OBJ?.PLINE_UNLOAD_TYPE?.[row?.unloadType],
      };
    });

    const gridButtonhandler = async (e) => {
      const id = e.target.id;
      if (id === 'excel') {
        bottomGridRef.current.exportAsXlsx({ fileName: `(${item?.registerDate}${item?.originalCenterCodeKr || item?.destinationCenterCodeKr})간선${translatorKr[tabId]}_상세` });
      }
    };

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: `간선${translatorKr[tabId]} 상세`,
          gridRef: bottomGridRef,
          columns: detailColumns[tabId],
          rows,
          buttons: [
            <div key={`excel`} className={`green`} id={`excel`} onClick={gridButtonhandler}>
              엑셀다운
            </div>,
          ],
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
      ],
    });
    dispatch(setLoading(null));
  };

  const columns: { [key: string]: IGrid.Column[] } = {
    PARCEL_LINEHAUL_LOAD_LIST: [
      {
        headerText: '상세보기',
        dataField: 'detail',
        renderer: {
          type: IGrid.RendererKind.ButtonRenderer,
          labelText: '상세보기',
          onClick: openDetail,
        },
      },
      {
        headerText: '상차일',
        dataField: 'registerDate',
      },
      {
        headerText: '상차지',
        dataField: 'originalCenterCodeKr',
      },
      {
        headerText: '상차수량',
        dataField: 'loadQuantity',
      },
    ],
    PARCEL_LINEHAUL_UNLOAD_LIST: [
      {
        headerText: '상세보기',
        dataField: 'detail',
        renderer: {
          type: IGrid.RendererKind.ButtonRenderer,
          labelText: '상세보기',
          onClick: openDetail,
        },
      },
      {
        headerText: '하차일',
        dataField: 'registerDate',
      },
      {
        headerText: '하차지',
        dataField: 'destinationCenterCodeKr',
      },
      {
        headerText: '수량',
        children: [
          {
            headerText: '합계',
            dataField: 'unloadQuantity',
          },
          {
            headerText: '정상입고',
            dataField: 'normalQuantity',
          },
          {
            headerText: '오입고',
            dataField: 'wrongQuantity',
          },
          {
            headerText: '사고접수',
            dataField: 'accidentQuantity',
          },
        ],
      },
    ],
  };

  const detailColumns: { [key: string]: IGrid.Column[] } = {
    PARCEL_LINEHAUL_LOAD_LIST: [
      {
        headerText: '상차일',
        dataField: 'registerDate',
      },
      {
        headerText: '상차지',
        dataField: 'originalCenterCodeKr',
      },
      {
        headerText: '하차지',
        dataField: 'destinationCenterCodeKr',
      },
      {
        headerText: '운송장번호',
        dataField: 'parcelInvoiceNumber',
      },
      {
        headerText: '운임비',
        dataField: 'price',
        dataType: 'numeric',
      },
    ],
    PARCEL_LINEHAUL_UNLOAD_LIST: [
      {
        headerText: '하차일',
        dataField: 'registerDate',
      },
      {
        headerText: '하차지',
        dataField: 'destinationCenterCodeKr',
      },
      {
        headerText: '배달지',
        dataField: 'centerCodeKr',
      },
      {
        headerText: '운송장번호',
        dataField: 'parcelInvoiceNumber',
      },
      {
        headerText: '입고상태',
        dataField: 'unloadTypeKr',
      },
    ],
  };

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} TARGET={tabId} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}

        <GridBox
          gridRef={gridRef}
          columns={columns[tabId]}
          gridProps={{
            fixedColumnCount: 1,
            pageRowCount: PARCEL_GRID_PAGE_ROW_COUNT,
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns[tabId]} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={detailColumns[tabId]} />
      </div>
    </div>
  );
};

export default Index;
