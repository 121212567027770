import { useEffect, useRef, useState } from 'react';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// print
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import Barcode from 'react-barcode';

export interface IPrint {
  items?: any;
  visible?: boolean;
}

const FORMTAG_6 = styled.div({
  backgroundColor: 'white',
  width: '210mm',
  height: '297mm',
  display: 'grid',
  alignItems: 'center',
  justifyItems: 'center',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(3, 1fr)',
  border: '1px solid lightgray',
  columnGap: '2mm',
  padding: '8mm 5mm',
});

const FORMTAG_24 = styled.div({
  backgroundColor: 'white',
  width: '210mm',
  height: '297mm',
  display: 'grid',
  alignItems: 'center',
  justifyItems: 'center',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(8, 1fr)',
  border: '1px solid lightgray',
  columnGap: '2mm',
  padding: '10mm 7mm',
  paddingTop: '13mm',
});

const PrintBarcode991931 = styled.div({
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '99.1mm',
  height: '93.1mm',
  border: '1px solid #d0d0d0',
  fontSize: 15,
  fontWeight: 800,
});
const PrintBarcode6434 = styled.div({
  backgroundColor: 'white',
  position: 'relative',
  height: '34mm',
  width: '64mm',
  border: '1px solid #d0d0d0',
  fontSize: 15,
  fontWeight: 800,
});

const formOptions = [
  { value: 'default_1', label: 'A4단일' },
  { value: 'formtag_6', label: '폼텍 6형' },
  { value: 'formtag_24', label: '폼텍 24형' },
];

export const Print = ({ printObj, setPrintObj }) => {
  const wrapperRef = useRef();
  const [printContents, setPrintContents] = useState<any[]>();
  const [printSetting, setPrintSetting] = useState<{ quantity: number; size?: string; format?: string }>();
  useEffect(() => {
    setPrintSetting({ quantity: 2, format: 'formtag_6' });
  }, []);

  useEffect(() => {
    if (printSetting) setPrint(printSetting);
  }, [printSetting]);

  const setPrint = (printSetting) => {
    const items = JSON.parse(JSON.stringify(printObj?.items));
    items?.sort(function (a, b) {
      return a[printSetting?.order] < b[printSetting?.order] ? -1 : a[printSetting?.order] > b[printSetting?.order] ? 1 : 0;
    });

    let array = [];
    items?.forEach((item) => {
      new Array(printSetting?.quantity).fill(item).forEach((item) => {
        array.push(item);
      });
    });

    if (printSetting?.format === 'default_1') setPrintContents(array);
    else {
      const a4Arr = [];
      const onePageHas = Number(printSetting?.format?.split('formtag_')?.[1]);
      new Array(Math.ceil(array?.length / onePageHas)).fill(0)?.forEach((vacant, gi) => {
        const group = [];
        new Array(Number(onePageHas))?.fill(0)?.forEach((_, idx) => {
          group.push(array[gi * onePageHas + idx]);
        });
        a4Arr?.push(group);
      });
      console.log(a4Arr);
      setPrintContents(a4Arr);
    }
  };

  return (
    <CommonModalNew
      title={'라벨지 출력'}
      type="print"
      visible={printObj?.visible}
      setVisible={() => {
        setPrintObj(null);
      }}
      rightTitle={
        <>
          <div style={{ display: 'flex' }}>
            <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
          </div>
        </>
      }
      style={{ width: 1045 }}
      children={
        <div style={{ display: 'flex', minHeight: 300 }}>
          <div style={{ width: 185, borderRight: '1px solid lightgray', paddingRight: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'column', position: 'fixed' }}>
              <div>
                <label className="col-form-label">출력형식</label>
                <SelectD
                  hasNull={false}
                  value={printSetting?.format ? { value: printSetting?.format, label: selectlabel(printSetting?.format, formOptions) } : null}
                  options={printObj?.type === 'haldang' ? formOptions?.filter((ele) => !ele.value?.includes('24')) : formOptions}
                  onChange={(option) => {
                    setPrintSetting((prev) => {
                      return {
                        ...prev,
                        format: (option as OptionItem)?.value,
                        size: (option as OptionItem)?.value === 'formtag_24' ? '64_34' : (option as OptionItem)?.value === 'formtag_6' ? '991_931' : prev.size,
                      };
                    });
                  }}
                />
              </div>
              <div>
                <label className="col-form-label">출력매수</label>
                <InputD
                  placeholder="출력 매수"
                  type="number"
                  value={printSetting?.quantity || ''}
                  onChange={(e) => {
                    setPrintSetting((prev) => {
                      return {
                        ...prev,
                        quantity: Number(e.target.value),
                      };
                    });
                  }}
                />
              </div>
              <hr />
              <div>
                <div style={{ textAlign: 'right' }}>
                  <span className="b">{printObj?.items?.length * printSetting?.quantity}</span> 개의 라벨 / 전체&nbsp;
                  <span className="b">{Math.ceil((printObj?.items?.length * printSetting?.quantity) / Number(printSetting?.format?.split('_')[1]))}</span> 장
                </div>
              </div>
              <hr />
              <div>
                <div style={{ textAlign: 'right', marginBottom: 5 }}>
                  총 <span className="b">{printObj?.items?.length}</span> 건
                </div>
                <div style={{ maxHeight: 500, overflow: 'scroll', textAlign: 'center' }}>
                  {printObj?.items?.map((row, idx) => {
                    return <div key={`barcode_${idx}`}>{row?.locationCode}</div>;
                  })}
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div style={{ width: 825 }}>
            <div className="break-for-onePage-print-wrapper" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginLeft: 20, width: '100%' }} ref={wrapperRef}>
              <>
                {printSetting?.format === 'default_1'
                  ? printContents?.map((ele, idx) => {
                      return (
                        <div className="break-for-onePage-print" key={`wrapper_default_1_${idx}`} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Element ele={ele} height={100} width={4} />
                        </div>
                      );
                    })
                  : printContents?.map((group, idx) => {
                      if (printSetting?.format === 'formtag_6' && group?.length > 0)
                        return (
                          <FORMTAG_6 className="break-for-onePage-print-keep-raw" key={`wrapper_formtag_6_${idx}`}>
                            {group?.map((ele, gi) => {
                              return <Element6 ele={ele} key={`formtag_6_${gi}`} />;
                            })}
                          </FORMTAG_6>
                        );
                      else if (printSetting?.format === 'formtag_24' && group?.length > 0)
                        return (
                          <FORMTAG_24 className="break-for-onePage-print-keep-raw" key={`wrapper_formtag_24_${idx}`}>
                            {group?.map((ele, gi) => {
                              return <Element24 ele={ele} key={`formtag_24_${gi}`} />;
                            })}
                          </FORMTAG_24>
                        );
                    })}
              </>
            </div>
          </div>
        </div>
      }
    />
  );
};
const Element = ({ ele = null, width = 1.5, height = 40 }) => {
  return (
    <>
      {ele?.barcode && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '10%' }}>
          <div className="b" style={{ fontSize: 50, textAlign: 'center' }}>
            {ele?.modelName}
          </div>
          <div style={{ fontSize: 50 }}>{ele?.barcode}</div>
          <Barcode width={width} value={ele?.barcode} height={height} />
        </div>
      )}
    </>
  );
};

const Element6 = ({ ele = null }) => {
  return (
    <PrintBarcode991931>
      {ele && (
        <table className="border-table printing" style={{ height: '100%' }}>
          <tbody>
            <tr>
              <td colSpan={6} style={{ textAlign: 'center' }}>
                {ele?.barcode && <Barcode width={1.5} value={ele?.barcode} height={40} />}
              </td>
            </tr>
            <tr>
              <th>바코드</th>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {ele?.barcode}
              </td>
            </tr>
            <tr>
              <th>제품</th>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {ele?.model}
              </td>
            </tr>
            <tr>
              <th>제품명</th>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {ele?.modelName}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </PrintBarcode991931>
  );
};

const Element24 = ({ ele = null }) => {
  return (
    <PrintBarcode6434>
      {ele && (
        <table className="border-table printing" style={{ height: '100%' }}>
          <tbody>
            <tr>
              <td colSpan={6} style={{ textAlign: 'center' }}>
                {ele?.barcode && <Barcode width={1} value={ele?.barcode} height={40} displayValue={false} />}
              </td>
            </tr>
            <tr>
              <th>바코드</th>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {ele?.barcode}
              </td>
            </tr>
            <tr>
              <th>제품명</th>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {ele?.modelName}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </PrintBarcode6434>
  );
};
