import { COLORS, VALUES } from 'common/constants/appearance';
import styled from 'styled-components';
import { LDSSubTitle } from '../atoms/Typography';
import { LDSSpacing } from '../atoms/Spacing';
import LDSListItem from '../modules/ListItem';
import LDSButton from '../atoms/Button';
import { useDispatch } from 'react-redux';
import { FiSliders, FiX } from 'react-icons/fi';
import { setShowModal } from 'redux/services/portalSlice';
import { useEffect, useMemo, useRef, useState } from 'react';
import OnboardingImage01 from '../../../assets/images/onboarding/Onboarding_image01.gif';
import OnboardingImage02 from '../../../assets/images/onboarding/Onboarding_image02.gif';
import OnboardingImage03 from '../../../assets/images/onboarding/Onboarding_image03.gif';
import { LuFolderTree, LuLayout } from 'react-icons/lu';
import { ModalProps } from './Modal';

export default function ModalOnboarding({ $size }: ModalProps) {
  const dispatch = useDispatch();
  const imageRef = useRef(null);

  const [activeItem, setActiveItem] = useState(1);
  const [progressTargetWidth, setProgressTargetWidth] = useState(0);
  const [progressFromWidth, _] = useState(0);

  const rotationTimes = useMemo(() => {
    return {
      1: VALUES.ONBOARDING_LOTATION_TIME_01,
      2: VALUES.ONBOARDING_LOTATION_TIME_02,
      3: VALUES.ONBOARDING_LOTATION_TIME_03,
    };
  }, []);

  const rotationContents = useMemo(() => {
    return [
      {
        order: 1,
        label: '메뉴 탐색',
        description: '즐겨찾기 모아보기, 메뉴 검색, 중메뉴 사용 여부 선택 기능 등 개선된 메뉴 탐색을 활용하실 수 있습니다.',
        icon: <LuFolderTree />,
      },
      {
        order: 2,
        label: '레이아웃',
        description: '사이드바를 닫은 상태에서도 자유롭게 메뉴를 이동할 수 있으며, 콘텐츠 영역이 확장되어 보다 많은 정보를 한 눈에 확인하실 수 있습니다.',
        icon: <LuLayout />,
      },
      {
        order: 3,
        label: '조회 옵션 간소화(준비중)',
        description: '조회 옵션을 간소화한 뷰 타입 선택 기능을 제공하여 화면에서 더 많은 조회 결과를 확인할 수 있습니다.',
        icon: <FiSliders />,
      },
    ];
  }, []);

  useEffect(() => {
    const imageWrapperWidth = imageRef?.current?.offsetWidth;
    setProgressTargetWidth(imageWrapperWidth);
    const rotationTimeout = setTimeout(() => {
      if (activeItem !== Object.keys(rotationTimes)?.length) setActiveItem(activeItem + 1);
      else setActiveItem(1);
      setProgressTargetWidth(0);
    }, rotationTimes[activeItem]);

    return () => {
      clearTimeout(rotationTimeout);
    };
  }, [activeItem]);

  const handleSelectItem = (order: number) => {
    setProgressTargetWidth(0);
    setActiveItem(order);
  };

  return (
    <OnboardingContentWrapper $size={$size}>
      <OnboradingContentList $size={$size}>
        <div style={{ paddingLeft: 20 }} className="title">
          <LDSSubTitle $color={COLORS.GRAY_07}>로지메이트의 새로운 모습을 만나보세요.</LDSSubTitle>
        </div>
        <LDSSpacing $direction="V" $value={80} />
        <ListWrapper>
          {rotationContents?.map((content, index) => (
            <LDSListItem
              key={`${content.label}_${index}`}
              $type={'NOTIFICATION'}
              $label={content.label}
              $description={content.description}
              onClick={() => activeItem !== content.order && handleSelectItem(content.order)}
              $icon={content.icon}
              $status={activeItem === content.order ? 'UNREAD' : 'DEFAULT'}
            />
          ))}
        </ListWrapper>
      </OnboradingContentList>
      <OnboadingImageContainer ref={imageRef} $size={$size}>
        <LazyImage
          url={activeItem === 1 ? OnboardingImage01 : activeItem === 2 ? OnboardingImage02 : OnboardingImage03}
          alt={activeItem === 1 ? 'image01' : activeItem === 2 ? 'image02' : 'image03'}
          className={activeItem ? 'active' : ''}
        />
        <Progress $time={rotationTimes[activeItem]} $from={progressFromWidth} $to={progressTargetWidth} />
      </OnboadingImageContainer>
      <LDSButton
        $primary={false}
        $size={'MD'}
        $type={'BUTTON'}
        $style={'TRANSPARENT'}
        $showIcon={true}
        $icon={<FiX />}
        $label="닫기"
        $tooltip={{ $description: `닫기`, $direction: 'BOTTOM', $anchorPosition: 'CENTER' }}
        onClick={() => {
          dispatch(setShowModal(''));
        }}
      />
    </OnboardingContentWrapper>
  );
}

const LazyImage = ({ url, alt, className }) => {
  return <img src={url} alt={alt} className={className} />;
};

const OnboardingContentWrapper = styled.div<ModalProps>`
  display: flex;
  max-height: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;

  & > button {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 34px;
    height: 34px;
    z-index: 2;
    background-color: ${COLORS.GRAY_05_DEEP};
    color: ${COLORS.WHITE};

    & svg {
      margin: 0 !important;
    }

    & label {
      display: none;
    }

    &:hover {
      background-color: ${COLORS.GRAY_06};
    }
    &:active {
      background-color: ${COLORS.GRAY_06_DEEP};
    }
  }

  @media screen and (max-width: ${(props) => props.$size}px) {
    flex-direction: column-reverse;

    & > button {
      width: auto;
      & svg {
        margin-left: -4px !important;
      }
      & label {
        display: inline-block;
      }
    }
  }
`;

const OnboradingContentList = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  padding-top: 20px;
  width: ${VALUES.ONBOARDING_MODAL_CONTENT_WIDTH}px;
  z-index: 1;
  box-shadow: 1px 0 5px 0 ${COLORS.BLACK_008};

  .title {
    word-break: keep-all;
    overflow-wrap: break-word;
    width: 100%;
  }

  @media screen and (max-width: ${(props) => props.$size}px) {
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;

    & > div:nth-child(2) {
      margin-top: 20px;
    }
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  & > li {
    padding: 14px 20px;

    & > div {
      transition: all 300ms;
    }
    &.active {
      pointer-events: none;
      & > div:first-child {
        background-color: ${COLORS.PRIMARY} !important;
        color: ${COLORS.WHITE} !important;
      }
    }
    & > div.list-content {
      gap: 4px;
    }
    & label {
      &:first-child {
        font-size: 15px;
        &.active {
          color: ${COLORS.PRIMARY};
        }
      }

      &:last-child {
        font-size: 13px;
      }
    }
  }
`;

const OnboadingImageContainer = styled.div<ModalProps>`
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${COLORS.GRAY_01};
  filter: brightness(0.99);
  min-height: 500px;
  max-width: calc(${(props) => (typeof props.$size === 'number' ? `${props.$size}px` : `100%`)} - ${VALUES.ONBOARDING_MODAL_CONTENT_WIDTH}px);
  overflow: hidden;

  & > img {
    width: inherit;
    flex-grow: 1;
    max-height: ${(props) => typeof props.$size === 'number' && props.$size - VALUES.ONBOARDING_MODAL_CONTENT_WIDTH}px;
    object-fit: cover;
    padding-left: 2px;

    display: none;

    &.active {
      display: block;
    }
  }

  @media screen and (max-width: ${(props) => props.$size}px) {
    max-width: 100vw;
    max-height: 100vw;
    min-height: 0;
    flex-shrink: 0;

    & > img {
      // height: calc(${VALUES.ONBOARDING_MODAL_IMG_MIN_HEIGHT}px - 5px);
      max-height: calc(100vw - 5px);
      object-fit: contain;
    }
  }
`;

const Progress = styled.div<{ $time: number; $from: number; $to: number }>`
  height: 5px;
  flex-shrink: 0;
  z-index: 1;
  place-self: flex-start;
  background-color: ${COLORS.PRIMARY};

  width: ${(props) => props.$from}px;
  width: ${(props) => props.$to}px;
  transition: width ${(props) => (props.$to !== 0 ? props.$time : 0)}ms linear;
`;
