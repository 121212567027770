import styled from 'styled-components';
import { COLORS, VALUES } from 'common/constants/appearance';
import { LDSLabelSM, LDSLabelXXS, LDSTitle } from 'components/LDS/atoms/Typography';
import LDSButton from 'components/LDS/atoms/Button';
import { HiOutlineStar, HiStar } from 'react-icons/hi';
import { LDSSpacing } from 'components/LDS/atoms/Spacing';

import { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import LDSSearchBox from 'components/LDS/layouts/Searchbox/SearchBox';
import LDSNavigation from 'components/LDS/modules/Navigation';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import LDSTab from 'components/LDS/atoms/Tab';
import { useBookmarks } from 'components/LDS/hooks/useBookmarks';
import useUseableMenus from 'hooks/user/useUseableMenus';
import { setBookmarkUpdated } from 'redux/services/menuSlice';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { convertCamelCase, isRegisterScreen, isTrackingScreen, saveCurrentTabUrl } from 'components/LDS/utils/path';
import { TabDTO } from 'interface/user';
import { getToday, returnDateyymmddKr, returnYoil } from 'common/util/dateParsingFn';
import LDSError from 'components/LDS/layouts/Error';
import { setDocumentTitle } from 'common/util/html';
import { justServer } from 'common/util/isServer';
import LDSNotFound from 'components/LDS/layouts/NotFound';
import { ErrorBoundary } from 'react-error-boundary';
import Loader from 'components/LDS/modules/Loader';

type MainPageProps = {
  $title?: string;
  $path?: string;
  $description?: string;
  $masterOptions?: string[];
  $useGrid?: boolean; // NOTE: Storybook에서 불러올 때 false로 할당 필요
  children?: React.ReactNode;
};

const SuspenseComponent = ({ children }) => {
  const [buffer, setBuffer] = useState(VALUES.DYNAMIC_LOADING_BUFFER_TIME as number);
  const { openOrclose } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    setTimeout(() => {
      setBuffer(0);
    }, buffer);
  }, []);

  const fallbackUI = (
    <LDSLabelXXS $color={COLORS.GRAY_05} $weight={'REGULAR'}>
      {buffer === 0 && 'Loading...'}
    </LDSLabelXXS>
  );
  return (
    <ErrorBoundary fallback={<Loader loadingStatus={'PUT'} openOrclose={openOrclose?.side} />}>
      <Suspense fallback={fallbackUI}>{children}</Suspense>
    </ErrorBoundary>
  );
};

const RestrictPageFallbackComponent = () => {
  return <LDSError $description={'해당 페이지에 접근 권한이 없습니다.\n관리자에게 문의해주세요.'} $buttonLabel={'메인으로'} $link={'/'} />;
};

const NotFoundFallbackComponent = () => {
  return <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />;
};

// key, className, defaultValue :: $ custom한 값..
export function MainPageTemplate({ $title, $description, $path, $masterOptions, $useGrid = true, children }: MainPageProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { useableTabs, useableScreens } = useSelector((state: RootState) => state.auth);
  const { openOrclose, bookmarkUpdated, masterOptions, MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [tabs, setTabs] = useState<TabDTO[]>([]);

  const { initMasterOptions } = useMasterOptions();
  const { addMyBookmark, deleteMyBookmark } = useBookmarks();
  const { getFavoriteMenus } = useUseableMenus();
  const [isBookmarked, setIsBookmarked] = useState(false);
  // console.log(location?.pathname); // ex ))   /main/dashboard
  const screenUrl = location.pathname.split('/')?.[2];
  const tabUrl = location.pathname.split('/')?.[3];

  const flatMenus = JSON.parse(localStorage.getItem('flatMenus'));

  const fetchPageInfo = async () => {
    const bookmarks = await getFavoriteMenus();

    if (bookmarks !== undefined) {
      const checkCurrentPath = bookmarks?.filter((bookmark) => {
        if (`${$path}` === bookmark.url) {
          return bookmark;
        }
      });
      checkCurrentPath?.length > 0 ? setIsBookmarked(true) : setIsBookmarked(false);
      return;
    }
    setIsBookmarked(false);
  };

  useEffect(() => {
    fetchPageInfo();
    bookmarkUpdated && dispatch(setBookmarkUpdated(false));
  }, [screenUrl, bookmarkUpdated]);

  useEffect(() => {
    if (
      tabs?.length > 0 &&
      !isTrackingScreen(screenUrl) &&
      !isRegisterScreen(screenUrl) &&
      !tabUrl &&
      (location.pathname === `/main${tabs[0]?.url}` || location.pathname === `/main${tabs[0]?.url}/`)
    ) {
      const lastTabUrl = JSON.parse(localStorage.getItem(`/${screenUrl}`));
      navigate(`/main${tabs?.[0]?.url}/${lastTabUrl?.tabUrl ? lastTabUrl?.tabUrl : convertCamelCase(tabs?.[0]?.tabId)}`);
    } else if (useableScreens && useableScreens[`/${screenUrl}`]?.TABS?.length === 0 && screenUrl !== 'authorManage') {
      if (!isRegisterScreen(screenUrl) && !isTrackingScreen(screenUrl)) navigate(`/main/${screenUrl}/notfound`);
    }

    //tabUrl이 없다면 screenUrl기준으로 설정
    setDocumentTitle(flatMenus?.find((menu) => menu.url === `/${screenUrl}`)?.menuName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenUrl, tabs]);

  useEffect(() => {
    if (useableTabs && $path !== '/dashboard') {
      const tabsDTO =
        $path !== '/authorManage'
          ? useableTabs?.[$path]
            ? [...useableTabs?.[$path]]
            : []
          : [
              // NOTE: 기타 > 조직관리 메뉴에서 사용하는 탭 수동으로 정의
              { tabId: 'AUTHORIZATION', tabName: '사용자 그룹관리', url: '/authorManage' },
              { tabId: 'MENU', tabName: '메뉴/화면 관리', url: '/authorManage' },
              { tabId: 'TABS', tabName: '탭/기능 관리', url: '/authorManage' },
              { tabId: 'SCREEN', tabName: '라우터 조회', url: '/authorManage' },
              { tabId: 'TAB', tabName: '탭 조회', url: '/authorManage' },
              { tabId: 'FUNCTION', tabName: '기능 조회', url: '/authorManage' },
              { tabId: 'GRID', tabName: '그리드 조회', url: '/authorManage' },
            ];

      if (tabsDTO?.length > 0) {
        tabsDTO?.sort((a: TabDTO, b: TabDTO) => a?.sort - b?.sort);
        setTabs(tabsDTO);
        $masterOptions && initMasterOptions($masterOptions);
      } else {
        setTabs([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenUrl, tabUrl, useableTabs]);

  return (
    <>
      {((useableScreens && Object.keys(useableScreens)?.includes(`/${screenUrl}`)) || screenUrl === 'dashboard') && (
        <MainContentsWrapper $expanded={openOrclose?.side} style={{ paddingBottom: screenUrl === 'manageorderRegister' ? '160px' : null }}>
          {(tabs?.length > 0 || $title) && !isRegisterScreen(tabUrl) && (
            <>
              <PageTitleSection>
                <LDSTitle $color={COLORS.GRAY_09}>{$title || tabs?.[0]?.screenName}</LDSTitle>
                {$path !== '/dashboard' && (
                  <LDSButton
                    $type={'BUTTON'}
                    $primary={false}
                    $label={isBookmarked ? '즐겨찾기 해제' : '즐겨찾기'}
                    $size={'SM'}
                    $style={'OUTLINED'}
                    $icon={isBookmarked ? <HiStar color={COLORS.WARNING} /> : <HiOutlineStar />}
                    $showIcon={true}
                    onClick={() => {
                      const data = {
                        menuName: $title,
                        url: `${$path}`,
                      };
                      isBookmarked ? deleteMyBookmark(data) : addMyBookmark(data);
                    }}
                  />
                )}
                {$path === '/dashboard' && (
                  <LDSLabelSM $weight={'MEDIUM'} $color={COLORS.GRAY_05}>
                    {returnDateyymmddKr(getToday())} {returnYoil(getToday())}요일 입니다.
                  </LDSLabelSM>
                )}
                {$description && (
                  <LDSLabelSM $weight={'MEDIUM'} $color={COLORS.GRAY_05}>
                    {$description}
                  </LDSLabelSM>
                )}
              </PageTitleSection>
              <LDSSpacing $direction="V" $value={tabs?.length > 1 ? 20 : 24} />
            </>
          )}
          {tabs?.length > 1 && !$path.includes('dashboard') && !isRegisterScreen(tabUrl) && (
            //NOTE: 대시보드는 탭 네비게이션 미노출
            <>
              <LDSNavigation $sticky={true} $style={'RESPONSIVE'} $activeId={location.pathname.split('/')?.length < 4 ? convertCamelCase(tabs?.[0]?.tabId) : tabUrl} $hasBorder={true}>
                {tabs.map((tab, index) => (
                  <LDSTab
                    key={`${tab?.tabName}_${tab?.tabId}_${index}`}
                    $label={tab?.tabName}
                    $type={'TAB'}
                    $activeId={location.pathname.split('/').length < 4 ? convertCamelCase(tabs[0]?.tabId) : tabUrl}
                    $tabId={convertCamelCase(tab?.tabId)}
                    onClick={() => {
                      saveCurrentTabUrl(screenUrl, convertCamelCase(tab?.tabId));
                      navigate(`/main${tab?.url}/${convertCamelCase(tab?.tabId)}`);
                    }}
                  />
                ))}
              </LDSNavigation>
              <LDSSpacing $direction="V" $value={32} />
            </>
          )}

          {/* {children} */}
          <SuspenseComponent>
            {isTrackingScreen(screenUrl) ? (
              <Outlet />
            ) : screenUrl !== 'dashboard' &&
              screenUrl !== 'authorManage' &&
              tabUrl &&
              !isRegisterScreen(tabUrl) &&
              !useableTabs?.[`/${screenUrl}`]?.find((tab) => convertCamelCase(tab.tabId) === tabUrl) ? (
              <NotFoundFallbackComponent />
            ) : MASTER_OPS || MASTER_OBJ ? (
              <Outlet />
            ) : masterOptions ? (
              <Outlet />
            ) : (
              <></>
            )}
          </SuspenseComponent>
        </MainContentsWrapper>
      )}
      {useableScreens && !Object.keys(useableScreens)?.includes(`/${screenUrl}`) && screenUrl !== 'dashboard' && <RestrictPageFallbackComponent />}
    </>
  );
}

MainPageTemplate.SearchBox = LDSSearchBox;

export const MainContentsWrapper = styled.div<{ $expanded?: boolean }>`
  flex-grow: 1;
  padding: 20px 32px 36px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: inherit;
  z-index: 2;

  & [class*='sheetWrapper'] {
    width: calc(100% - ${(props) => (props.$expanded ? VALUES.SIDEBAR_EXPANDED : VALUES.SIDEBAR_COLLAPSED)}px) !important;
    overflow-y: auto;
    bottom: 0;
    min-height: 200px;
  }

  // 아래는 예전 컴포넌트 사용을 위한 임시 스타일 분기 (시작)
  .page-content {
    padding: 0 !important;
    overflow: visible !important;
    height: auto;
    position: relative !important;

    & > div:first-child {
      margin: 0;
    }

    & > div:first-child > div:first-child:not(.col) {
      margin-top: 0 !important;
    }

    & > [class*='presenter_title'] {
      padding: 0;
      display: none;
    }
    & > div.row:first-child .btn {
      margin: 0 !important;
    }

    .sheetBody,
    [class*='bottomSheet'] {
      .bordering {
        padding: 8px 16px;
        background-color: transparent;

        li {
          font-size: 12px !important;
          line-height: 16px;
        }
      }

      .bordering-current {
        padding: 8px 16px;
        border-color: ${COLORS.PRIMARY};

        li {
          color: ${COLORS.PRIMARY} !important;
          font-size: 12px !important;
          line-height: 16px;
          font-weight: 700 !important;
        }
      }

      .sheetContent .div-top-tab {
        height: auto;
      }
    }
  }

  .grid-button-area {
    padding: 8px 0;
    row-gap: 4px;

    .orange,
    .red,
    .blue,
    .green,
    .gray {
      border-radius: 5px;
    }
  }

  section {
    & ~ .div-top-tab {
      display: none; //그리드 상단에 노출되는 탭 아이템 숨김처리
    }
  }
  .presenterSearch {
    margin-top: 0;

    .btn-search {
      font-size: 11px !important;
      font-weight: 600;
      height: 26px !important;
    }
  }
  .btn-search,
  .btn.btn-secondary,
  .radio-select-area .active {
    background-color: ${COLORS.PRIMARY} !important;
    border-width: 0 !important;
    & > img {
      display: none;
    }
  }

  .btn.btn-secondary {
    font-size: 12px !important;
    font-weight: 600;
  }
  // 아래는 예전 컴포넌트 사용을 위한 임시 스타일 분기 (끝)
`;

const PageTitleSection = styled.section`
  display: flex;
  gap: 12px;
  align-items: center;
`;
