export const SEARCH_MODEL_KO = [
  { label: '제품', value: 'model' },
  { label: '제품명', value: 'modelName' },
  { label: '바코드', value: 'barcode' },
  { label: 'SKU', value: 'modelStockSeq' },
  { label: '외부SKU', value: 'skuNumber' },
  { value: 'productSeq', label: '상품번호' },
  { value: 'externalProductNumber', label: '외부상품번호' },
];
export const LITE_FLAG_KO = [
  {
    label: '라이트',
    value: 'true',
  },
  {
    label: '일반',
    value: 'false',
  },
];

export const FIX_DATE_KO = [
  { value: 'true', label: '지정' },
  { value: 'false', label: '미지정' },
];

export const CONFIRM_FLAG = [
  { value: 'true', label: '확정' },
  { value: 'false', label: '미확정' },
];

export const DURATION_KO = [
  { value: 30, label: '30분' },
  { value: 60, label: '60분' },
  { value: 90, label: '90분' },
  { value: 120, label: '120분' },
];

export const FLOOR_KO = [
  {
    label: '2층 단품',
    value: '2층 단품',
  },
  {
    label: '2층 결합',
    value: '2층 결합',
  },
  {
    label: '3층 단품',
    value: '3층 단품',
  },
  {
    label: '3층 결합',
    value: '3층 결합',
  },
  {
    label: '4층 단품',
    value: '4층 단품',
  },
  {
    label: '4층 결합',
    value: '4층 결합',
  },
  {
    label: '5층 단품',
    value: '5층 단품',
  },
  {
    label: '5층 결합',
    value: '5층 결합',
  },
];

export const SEARCH_ORDER_KO = [
  {
    label: '주문번호',
    value: 'orderSeq',
  },
  {
    label: '외부주문번호',
    value: 'externalOrderNumber',
  },
  {
    label: '묶음주문번호',
    value: 'transOrderNumber',
  },
  {
    label: '배송번호',
    value: 'deliverySeq',
  },
];

export const SEARCH_INVOICE_KO = [
  {
    label: '송장번호',
    value: 'invoiceSeq',
  },
  {
    label: '외부송장번호',
    value: 'externalInvoiceNumber',
  },
  {
    label: '운송장번호',
    value: 'parcelInvoiceNumber',
  },
];

export const SEARCH_DATE_KO = [
  {
    label: '고객주문일',
    value: 'OrderDate',
  },
  {
    label: '등록일',
    value: 'RegisterDate',
  },
  {
    label: '희망일',
    value: 'PromiseDeliveryDate',
  },
  {
    label: '지정일',
    value: 'DeliveryDate',
  },
  {
    label: '배송확정일',
    value: 'DeliveryConfirmDate',
  },
  {
    label: '완료일/취소일',
    value: 'FinishOrCancelDate',
  },
];
// en
export const SEARCH_MODEL_EN = [
  { label: 'Model', value: 'model' },
  { label: 'Model Name', value: 'modelName' },
  { label: 'Barcode', value: 'barcode' },
  { label: 'SKU', value: 'modelStockSeq' },
  { label: 'External SKU', value: 'skuNumber' },
];
export const LITE_FLAG_EN = [
  {
    label: 'Lite',
    value: 'true',
  },
  {
    label: 'General',
    value: 'false',
  },
];

export const FIX_DATE_EN = [
  { value: 'true', label: 'Fixed' },
  { value: 'false', label: 'Non-Fixed' },
];

export const DURATION_EN = [
  { value: 30, label: '30min' },
  { value: 60, label: '60min' },
  { value: 90, label: '90min' },
  { value: 120, label: '120min' },
];

export const FLOOR_EN = [
  {
    label: '2F Single',
    value: '2F Single',
  },
  {
    label: '2F Multi',
    value: '2F Multi',
  },
  {
    label: '3F Single',
    value: '3F Single',
  },
  {
    label: '3F Multi',
    value: '3F Multi',
  },
  {
    label: '4F Single',
    value: '4F Single',
  },
  {
    label: '4F Multi',
    value: '4F Multi',
  },
  {
    label: '5F Single',
    value: '5F Single',
  },
  {
    label: '5F Multi',
    value: '5F Multi',
  },
];

export const SEARCH_ORDER_EN = [
  {
    label: 'order Number',
    value: 'orderSeq',
  },
  {
    label: 'External OrderNumber',
    value: 'externalOrderNumber',
  },
  {
    label: 'Trans OrderNumber',
    value: 'transOrderNumber',
  },
];

export const SEARCH_INVOICE_EN = [
  {
    label: 'Invoice Number',
    value: 'invoiceSeq',
  },
  {
    label: 'External Invoice Number',
    value: 'externalInvoiceNumber',
  },
  {
    label: 'Parcel InvoiceNumber',
    value: 'parcelInvoiceNumber',
  },
];

export const SEARCH_DATE_EN = [
  {
    label: 'Order Date',
    value: 'OrderDate',
  },
  {
    label: 'Register Date',
    value: 'RegisterDate',
  },
  {
    label: 'Promise DeliveryDate',
    value: 'PromiseDeliveryDate',
  },
  {
    label: 'Delivery Date',
    value: 'DeliveryDate',
  },
  {
    label: 'Finish Or CancelDate',
    value: 'FinishOrCancelDate',
  },
];

// zh
export const SEARCH_MODEL_ZH = [
  { label: '製品', value: 'model' },
  { label: '製品名', value: 'modelName' },
  { label: '条形码', value: '바코드' },
  { label: 'SKU', value: 'modelStockSeq' },
  { label: '外部SKU', value: 'skuNumber' },
];
export const LITE_FLAG_ZH = [
  {
    label: '轻货物',
    value: 'true',
  },
  {
    label: '普通',
    value: 'false',
  },
];

export const FIX_DATE_ZH = [
  { value: 'true', label: '选择' },
  { value: 'false', label: '未选择' },
];

export const DURATION_ZH = [
  { value: 30, label: '30分' },
  { value: 60, label: '60分' },
  { value: 90, label: '90分' },
  { value: 120, label: '120分' },
];

export const FLOOR_ZH = [
  {
    label: '2층 單品',
    value: '2층 單品',
  },
  {
    label: '2층 结合',
    value: '2층 结合',
  },
  {
    label: '3층 單品',
    value: '3층 單品',
  },
  {
    label: '3층 结合',
    value: '3층 结合',
  },
  {
    label: '4층 單品',
    value: '4층 單品',
  },
  {
    label: '4층 结合',
    value: '4층 结合',
  },
  {
    label: '5층 單品',
    value: '5층 單品',
  },
  {
    label: '5층 结合',
    value: '5층 结合',
  },
];

export const SEARCH_ORDER_ZH = [
  {
    label: '订单号',
    value: 'orderSeq',
  },
  {
    label: '外部订单号',
    value: 'externalOrderNumber',
  },
  {
    label: '批量采购订单号',
    value: 'transOrderNumber',
  },
];

export const SEARCH_INVOICE_ZH = [
  {
    label: '发票号',
    value: 'invoiceSeq',
  },
  {
    label: '外部发票号',
    value: 'externalInvoiceNumber',
  },
  {
    label: '货运单号',
    value: 'parcelInvoiceNumber',
  },
];

export const SEARCH_DATE_ZH = [
  {
    label: '客户下单日',
    value: 'OrderDate',
  },
  {
    label: '注册日期',
    value: 'RegisterDate',
  },
  {
    label: '期望日期',
    value: 'PromiseDeliveryDate',
  },
  {
    label: '指定日期',
    value: 'DeliveryDate',
  },
  {
    label: '结束日/取消日',
    value: 'FinishOrCancelDate',
  },
];

export const LABEL = {
  SEARCH_ORDER: {
    ko: '주문번호',
    zh: '订单号',
    en: 'Order Number',
  },
  SEARCH_INVOICE: {
    ko: '송장번호',
    zh: '发票号',
    en: 'Invoice Number',
  },
  partnerSeqList: {
    ko: '판매사',
    zh: '销售商',
    en: 'Product seller',
  },
  infraSeqList: {
    ko: '인프라',
    zh: '基础设施',
    en: 'Infrastructure',
  },
  centerCodeList: {
    ko: '소속창고',
    zh: '所属仓库',
    en: 'Warehouse in charge',
  },
  orderTypes: {
    ko: '주문유형',
    zh: '订单类型',
    en: 'Type of Order',
  },
  feeTypes: {
    ko: '송장유형',
    zh: '发票类型',
    en: 'Type of Invoice',
  },
  invoiceStatuses: {
    ko: '송장상태',
    zh: '发票状态',
    en: 'Status of Invoice',
  },
  userId: {
    ko: '배송기사',
    zh: '配送司机',
    en: 'Deliveryman',
  },
  SEARCH_DATE: {
    ko: '기간검색',
    zh: '日期范围查询',
    en: 'Search period',
  },
  SEARCH_MODEL: {
    ko: '제품/상품',
    zh: '产品',
    en: 'Product',
  },
  receiverName: {
    ko: '고객명',
    zh: '客户名',
    en: 'Client name',
  },
  receiverAddr: {
    ko: '주소',
    zh: '地址',
    en: 'Address',
  },
  receiverPhone: {
    ko: '연락처',
    zh: '联系电话',
    en: 'Contact',
  },
  promiseFlag: {
    ko: '날짜지정 여부',
    zh: '是否选择日期',
    en: 'Is the date fixed',
  },
  inventoryLocations: {
    ko: '재고위치',
    zh: '库存位置',
    en: 'Location of stock',
  },
  recommenderName: {
    ko: '추천인',
    zh: '推荐人',
    en: 'Recommender',
  },
  seller: {
    ko: '판매처',
    zh: '销售公司',
    en: 'Sold at',
  },
  salesChannelSeqList: {
    ko: '판매채널',
    zh: '销售渠道',
    en: 'Pipeline channel for Selling',
  },
  liteFlag: {
    ko: '등록유형',
    zh: '销售商',
    en: 'Order Number',
  },
  parcelPayTypes: {
    ko: '운임구분',
    zh: '销售商',
    en: 'Order Number',
  },
  sendStage: {
    ko: '차수',
    zh: '下单次数',
    en: 'Order count',
  },
  similaritySearchFlag: {
    ko: '유사검색여부',
    zh: '是否相似查询',
    en: 'search mode',
  },
};

export const BUTTONS = {
  search: {
    ko: '조회',
    zh: '查询',
    en: 'Search',
  },
  similarities: {
    ko: '유사검색',
    zh: '查询相似数据',
    en: 'similarities',
  },
  accordance: {
    ko: '일치검색',
    zh: '查询相同数据',
    en: 'accordance',
  },
};

export const FUNCTION_INNER_LANG = {
  PURCHASEREQUESTSELECT: {
    ko: '발주요청',
    zh: '【库存入库请求】',
    en: '【Purchase Request】',
  },
  PURCHASEREQUESTSEARCH: {
    ko: '발주요청',
    zh: '【库存入库请求】',
    en: '【Purchase Request】',
  },
  ORDERFORMMODEL: {
    ko: '주문등록 양식',
    zh: '【订单注册格式】',
    en: '【Template Excel】',
  },
  ORDERFORMPRODUCT: {
    ko: '주문등록 양식',
    zh: '【订单注册格式】',
    en: '【Template Excel】',
  },
  ORDERFORMPARCEL: {
    ko: '주문등록 양식',
    zh: '【订单注册格式】',
    en: '【Template Excel】',
  },
  ORDEREXCELUPLOADDEFAULT: {
    ko: '엑셀 주문등록',
    zh: '【电子表格订单注册】',
    en: '[Enroll Order with Excel]',
  },
  ORDEREXCELUPLOADCUSTOM: {
    ko: '엑셀 주문등록',
    zh: '【电子表格订单注册】',
    en: '[Enroll Order with Excel]',
  },
  ORDEREXCELUPLOADPARCEL: {
    ko: '엑셀 주문등록',
    zh: '【电子表格订单注册】',
    en: '[Enroll Order with Excel]',
  },
  ORDEREXCELDOWNMAIN: {
    ko: '엑셀 다운',
    zh: '【下载电子表格】',
    en: '[Download with Excel]',
  },
  ORDEREXCELDOWNDETAIL: {
    ko: '엑셀 다운',
    zh: '【下载电子表格】',
    en: '[Download with Excel]',
  },
  INVOICEPRINT: {
    ko: '출력',
    zh: '【打印】',
    en: '[Print out]',
  },
  LADDERTRUCKPRINT: {
    ko: '출력',
    zh: '【打印】',
    en: '[Print out]',
  },
  PRINTSHEET: {
    ko: '출력',
    zh: '【打印】',
    en: '[Print out]',
  },
};

export const FUNCTIONS_LANG = {
  ORDERCANCEL: {
    ko: '주문취소',
    zh: '取消订单',
    en: 'Cancel order',
  },
  ORDERPEND: {
    ko: '주문보류',
    zh: '保留订单',
    en: 'Pend order',
  },
  ORDERUNLINK: {
    ko: '연동취소',
    zh: '取消同步',
    en: 'Cancel Sync',
  },
  NOTININVENT: {
    ko: '재고연동해제',
    zh: '查询相同数据',
    en: 'Clear Inventory Linkage',
  },
  ORDERRESTORE: {
    ko: '주문원복',
    zh: '订单还原',
    en: 'Order Restore',
  },
  ORDERDISTRIBUTE: {
    ko: '주문분배',
    zh: '查询相同数据',
    en: 'ReOrder',
  },
  ORDERASSIGN: {
    ko: '주문승인',
    zh: '查询相同数据',
    en: 'Assign Order',
  },
  ORDERASSIGNSIMPLE: {
    ko: '재고주문승인',
    zh: '查询相同数据',
    en: 'Assign Order Inventory',
  },
  ORDERIMPORT: {
    ko: '주문불러오기',
    zh: '查询相同数据',
    en: 'Import Order',
  },
  INSTALL_COMPLETE: {
    ko: '배송완료',
    zh: '查询相同数据',
    en: 'Install Complete',
  },
  PURCHASEREQUESTSELECT: {
    ko: '선택',
    zh: '选择',
    en: 'Select Mode',
  },
  PURCHASEREQUESTSEARCH: {
    ko: '검색',
    zh: '查询',
    en: 'Search Mode',
  },
  ORDERFORMMODEL: {
    ko: '제품',
    zh: '产品',
    en: 'Model',
  },
  ORDERFORMPRODUCT: {
    ko: '상품',
    zh: '商品',
    en: 'Product',
  },
  ORDERFORMPARCEL: {
    ko: '택배',
    zh: '快递',
    en: 'Parcel',
  },
  ORDEREXCELUPLOADDEFAULT: {
    ko: '기본',
    zh: '基本',
    en: 'Model',
  },
  ORDEREXCELUPLOADCUSTOM: {
    ko: '커스텀',
    zh: '定制',
    en: 'Custom',
  },
  ORDEREXCELUPLOADPARCEL: {
    ko: '택배',
    zh: '快递',
    en: 'Parcel',
  },
  ORDEREXCELDOWNMAIN: {
    ko: '메인',
    zh: '详细',
    en: 'Main',
  },
  ORDEREXCELDOWNDETAIL: {
    ko: '상세',
    zh: '快递',
    en: 'Detail',
  },
  INVOICEPRINT: {
    ko: '택배송장',
    zh: '快递发票',
    en: 'Parcel Invoice',
  },
  LADDERTRUCKPRINT: {
    ko: '사용증',
    zh: '正在使用云梯车',
    en: 'Ladder truck in use',
  },
  PRINTSHEET: {
    ko: '주문서',
    zh: '订购单',
    en: 'Order sheet',
  },
};

export const COLUMNS_LANG = {
  showOrderDetail: {
    ko: '주문상세',
    zh: '订单',
    en: 'Order',
  },
  showParcelDetail: {
    ko: '운송장상세',
    zh: '货运',
    en: 'Waybill',
  },
  showTracking: {
    ko: '배송/반품현황',
    zh: '配送/退货情况',
    en: 'Status of delivery<br/>/withdrawl',
  },
  liteFlagKr: {
    ko: '등록유형',
    zh: '注册类型',
    en: 'Enrolled type',
  },
  orderDate: {
    ko: '고객주문일',
    zh: '客户下单日期',
    en: 'Ordered Date<br/> (Client)',
  },
  registerDatetime: {
    ko: '등록일시',
    zh: '注册时间',
    en: 'Enrolled date',
  },
  promiseDate: {
    ko: '희망일',
    zh: '期望日期',
    en: 'Anticipated date',
  },
  predictDeliveryDate: {
    ko: '출고가능일',
    zh: '可出库日期',
    en: 'Potential Date for departure',
  },
  deliveryDate: {
    ko: '지정일<br />(확정일)',
    zh: '指定日期<br />(确定日期）',
    en: 'Fixed Date',
  },
  finishOrCancelDatetime: {
    ko: '완료/<br />취소일시',
    zh: '结束/取消时间',
    en: 'Completed<br/>/Cancelled Date',
  },
  orderSeq: {
    ko: '주문번호',
    zh: '订单号',
    en: 'Order number',
  },
  externalOrderNumber: {
    ko: '외부주문번호',
    zh: '外部订单号',
    en: 'External Order number',
  },
  invoiceSeq: {
    ko: '송장번호',
    zh: '发票号',
    en: 'Invoice number ',
  },
  externalInvoiceNumber: {
    ko: '외부송장번호',
    zh: '外部发票号',
    en: 'External order number',
  },
  transOrderNumber: {
    ko: '묶음주문번호',
    zh: '批量采购订单号',
    en: 'Order number in bulk',
  },
  orderTypeKr: {
    ko: '주문유형',
    zh: '订单类型',
    en: 'Type of order',
  },
  feeTypeKr: {
    ko: '송장유형',
    zh: '发票类型',
    en: 'Invoice type',
  },
  invoiceStatusKr: {
    ko: '송장상태',
    zh: '发票状态',
    en: 'Invoice status',
  },
  stage: {
    ko: '차수',
    zh: '下单次数',
    en: 'Order Count',
  },
  inventoryLocationKr: {
    ko: '재고위치',
    zh: '库存位置',
    en: 'Stock location',
  },
  productSeq: {
    ko: '상품번호',
    zh: '商品编号',
    en: 'productSeq',
  },
  productName: {
    ko: '상품명',
    zh: '商品名',
    en: 'productName',
  },
  productQuantity: {
    ko: '상품주문수량',
    zh: '查询相同数据',
    en: 'Num. of order goods',
  },
  quantity: {
    ko: '상품구성수량',
    zh: '商品总数',
    en: 'Num. of total goods',
  },
  shipmentNote: {
    ko: '고객요청사항',
    zh: '客户请求事项',
    en: 'Client Requests',
  },
  centerCodeKr: {
    ko: '소속창고',
    zh: '所属仓库',
    en: 'Warehouse in charge',
  },
  userIdKr: {
    ko: '주기사',
    zh: '主司机',
    en: 'Main driver',
  },
  driverMemo: {
    ko: '기사요청사항',
    zh: '司机请求事项',
    en: 'Request to the driver',
  },
  receiverInfo: {
    ko: '실수령자 고객정보',
    zh: '收件人/客户信息',
    en: 'Client Information (Recipient)',
  },
  receiverName: {
    ko: '고객명',
    zh: '客户名',
    en: 'Client name',
  },
  receiverZipcode: {
    ko: '우편번호',
    zh: '邮编',
    en: 'Zip Code',
  },
  receiverAddr: {
    ko: '주소',
    zh: '地址',
    en: 'Address',
  },
  receiverPhone: {
    ko: '연락처',
    zh: '联系方式',
    en: 'Contact',
  },
  receiverPhone2: {
    ko: '연락처2',
    zh: '联系方式2',
    en: 'Contact2',
  },
  customerInfo: {
    ko: '주문자 고객정보',
    zh: '订货人/客户信息',
    en: 'Client Information (who Ordered)',
  },
  customerName: {
    ko: '고객명',
    zh: '客户名',
    en: 'Client name',
  },
  customerZipcode: {
    ko: '우편번호',
    zh: '邮编',
    en: 'Zip Code',
  },
  customerAddr: {
    ko: '주소',
    zh: '地址',
    en: 'Address',
  },
  customerPhone: {
    ko: '연락처',
    zh: '联系方式',
    en: 'Contact',
  },
  customerPhone2: {
    ko: '연락처2',
    zh: '联系方式2',
    en: 'Contact2',
  },
  recommenderNumber: {
    ko: '추천인고유번호',
    zh: '推荐人固有编号',
    en: 'Common num. of the recommender',
  },
  recommenderName: {
    ko: '추천인',
    zh: '推荐人',
    en: 'Recommender',
  },
  primePrice: {
    ko: '원가',
    zh: '成本',
    en: 'Original Price',
  },
  parcelExtraFee: {
    ko: '도선료',
    zh: '偏远费',
    en: 'ferry charges',
  },
  packingStateKr: {
    ko: '포장상태',
    zh: '包装状态',
    en: 'packing State',
  },
  sellingPrice: {
    ko: '판매가',
    zh: '售价',
    en: 'Sell Price',
  },
  parcelPayTypeKr: {
    ko: '운임구분',
    zh: '付运费方式',
    en: 'Type of Delivery',
  },
  cashOnDelivery: {
    ko: '운임비',
    zh: '运费',
    en: 'Cost for Delivery',
  },
  cancelChargeFlagKr: {
    ko: '취소비용',
    zh: '取消费用',
    en: 'Cost for Cancellation',
  },
  parcelType: {
    ko: '택배사',
    zh: '快递公司',
    en: 'Parcel Service <br/>(company name)',
  },
  parcelInvoiceNumber: {
    ko: '운송장번호',
    zh: '货运单号',
    en: 'Waybill Number',
  },
  serialNumber: {
    ko: '시리얼번호',
    zh: '序列号',
    en: 'Serial Number',
  },
  happyCallCount: {
    ko: '해피콜',
    zh: '客户关怀',
    en: 'Happy Call',
  },
  smsCount: {
    ko: '메시지발송횟수',
    zh: '短信发送次数',
    en: 'Messages sent (no.)',
  },
  assistUserIdKr: {
    ko: '부기사',
    zh: '副司机',
    en: 'Secondary driver',
  },
  userIdPhoneKr: {
    ko: '연락처(주기사)',
    zh: '联系方式（主司机）',
    en: 'Contact Point (Main driver)',
  },
  infraSeqKr: {
    ko: '인프라',
    zh: '基础设施',
    en: 'Infrastructure',
  },
  partnerSeqKr: {
    ko: '판매사',
    zh: '销售商',
    en: 'Product seller',
  },
  seller: {
    ko: '판매처',
    zh: '销售公司',
    en: 'Sold at',
  },
  salesChannelName: {
    ko: '판매채널',
    zh: '销售渠道',
    en: 'Pipeline channel for Selling',
  },
  deliveryDatetime: {
    ko: '약속시간',
    zh: '约定时间',
    en: 'Appointment at',
  },
  purchaseSeq: {
    ko: '발주번호',
    zh: '订单号',
    en: 'Order number',
  },
  printDatetime: {
    ko: '출력일시',
    zh: '打印时间',
    en: 'Initial date of printing',
  },
  totalModelWeight: {
    ko: '총 무게',
    zh: '总重量',
    en: 'Weight in total',
  },
  totalBoxVolume: {
    ko: '총 CBM',
    zh: '总CBM',
    en: 'CBM in total',
  },
  registerId: {
    ko: '주문등록자',
    zh: '订单注册人员',
    en: 'Order registrant',
  },
  centerCallNumKr: {
    ko: '창고연락처',
    zh: '仓库联系电话',
    en: 'Warehouse contact point',
  },
  workNote: {
    ko: '작업특이사항',
    zh: '作业特别事项',
    en: 'Any specifics',
  },
  rescheduleInfo: {
    ko: '지정일 변경사유',
    zh: '指定日期变更事由',
    en: 'Reason for change in fixed date',
  },
  rescheduleTypeKr: {
    ko: '변경유형',
    zh: '变更类型',
    en: 'Type changed',
  },
  rescheduleNote: {
    ko: '기타사유',
    zh: '其他事由',
    en: 'other factors',
  },
};
