import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { orderService } from '../../_services/service';
import { BottomSheet, IBottomSheetObj } from 'components/template/bottomSheet';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { InvoicePaymentDTO, PaymentSearchDTO } from '../../_interface/order';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { CancelReasonPop, ICancelReason } from './component/cancelReason';
import { PagingListDTO } from 'interface/util';
import { OrderDetail, IOrderDetailObj } from '../../orderListTab/common/component/invocieDetail/orderDetail';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<AUIGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelGridDetailRef = useRef<AUIGrid>();

  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();
  const [orderDetailObj, setOrderDetailObj] = useState<IOrderDetailObj>();
  const [cancelReasonObj, setCancelReasionObj] = useState<ICancelReason>();

  const columns: IGrid.Column[] = [
    {
      headerText: '주문상세',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '주문상세',
        onClick: (e: IGrid.ButtonClickEvent) => {
          setOrderDetailObj({
            item: e.item,
            visible: true,
          });
        },
      },
    },
    {
      headerText: '결제상세',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '결제상세',
        onClick: (e: IGrid.ButtonClickEvent) => {
          fetchDetail(e);
        },
      },
    },
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '결제번호',
      dataField: 'paymentSeq',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '소속창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '설치기사',
      dataField: 'userIdKr',
    },
    {
      headerText: '기사코드',
      dataField: 'userId',
    },
    {
      headerText: '고객명',
      dataField: 'customerName',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '결제수량',
      dataField: 'quantity',
    },
    {
      headerText: '결제금액(A)',
      dataField: 'amount',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: 'PG수수료(B)',
      dataField: 'fee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '정산액(A-B)',
      dataField: 'payOutAmount',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '결제수단',
      dataField: 'method',
    },
    {
      headerText: '결제요청일시',
      dataField: 'registerDatetime',
    },
    {
      headerText: '결제일시',
      dataField: 'finishDatetime',
    },
    {
      headerText: '취소일시',
      dataField: 'cancelDatetime',
    },
    {
      headerText: '취소사유',
      dataField: 'cancelReasonKr',
    },
  ];
  const detailColumns: IGrid.Column[] = [
    {
      headerText: '결제번호',
      dataField: 'paymentSeq',
    },
    {
      headerText: '결제유형',
      dataField: 'typeKr',
    },
    {
      headerText: '결제항목',
      dataField: 'columnName',
    },
    {
      headerText: '수량',
      dataField: 'quantity',
    },
    {
      headerText: '단가',
      dataField: 'unitPrice',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '금액',
      dataField: 'price',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '청구여부',
      dataField: 'chargeFlagKr',
    },
  ];
  const detailExcelColumns: IGrid.Column[] = [
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '결제번호',
      dataField: 'paymentSeq',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '결제유형',
      dataField: 'typeKr',
    },
    {
      headerText: '결제항목',
      dataField: 'columnName',
    },
    {
      headerText: '수량',
      dataField: 'quantity',
    },
    {
      headerText: '단가',
      dataField: 'unitPrice',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '금액',
      dataField: 'price',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '청구여부',
      dataField: 'chargeFlagKr',
    },
    {
      headerText: '소속창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '설치기사',
      dataField: 'userIdKr',
    },
    {
      headerText: '기사코드',
      dataField: 'userId',
    },
    {
      headerText: '고객명',
      dataField: 'customerName',
    },
    {
      headerText: '상태값',
      dataField: 'statusKr',
    },
    {
      headerText: '결제수단',
      dataField: 'method',
    },
    {
      headerText: '결제요청일시',
      dataField: 'registerDatetime',
    },
    {
      headerText: '결제일시',
      dataField: 'finishDatetime',
    },
    {
      headerText: '취소일시',
      dataField: 'cancelDatetime',
    },
    {
      headerText: '취소사유',
      dataField: 'cancelReasonKr',
    },
  ];

  const labellingKr = (data: PagingListDTO<InvoicePaymentDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        userIdKr: MASTER_OBJ?.DRIVER_WHOLE?.[row.userId],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        statusKr: MASTER_OBJ?.TOSS_PAYMENT_STATUS?.[row.status],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        cancelReasonKr: MASTER_OBJ?.PAYMENT_CANCEL?.[row.cancelReason],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: PaymentSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await orderService.getPaymentPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));

    const { list } = labellingKr({ list: type === 'main' ? await orderService.getPaymentMainExcel(searchObj) : await orderService.getPaymentDetailExcel(searchObj) }, true);
    excelGridRef.current.setGridData(list);
    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '추가결제내역' });
    } else {
      excelGridDetailRef.current.setGridData(list);
      excelGridDetailRef.current.exportAsXlsx({ fileName: '추가결제내역(상세)' });
    }
    dispatch(setLoading(null));
  };

  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    setBottomSheetObj((prev) => {
      return {
        ...prev,
        visible: true,
        title: '추가결제내역 상세',
        grid: {
          gridRef: bottomGridRef,
          columns: detailColumns,
          buttons: [
            {
              color: 'green',
              title: '엑셀다운로드',
              onClick: () => {
                //

                bottomGridRef.current.exportAsXlsx({ fileName: '추가결제내역(상세)' });
              },
            },
          ],
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
      };
    });
    const searchObj = JSON.parse(e.item.searchObj);

    const data = await orderService.getPaymentDetail(e.item.paymentSeq);
    const rows = data.map((row) => {
      return {
        ...row,
        searchObj,
        typeKr: MASTER_OBJ?.EXTRA_TYPE?.[row.type],
        chargeFlagKr: MASTER_OBJ?.CHARGE_FLAG?.[row.chargeFlag + ''],
      };
    });

    bottomGridRef.current.setGridData(rows);

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'EXTRAPAYMENTEXCEL') {
      downloadExcel('main');
    } else if (id === 'EXTRAPAYMENTEXCELDETAIL') {
      downloadExcel('detail');
    } else {
      const items = gridRef.current.getCheckedRowItemsAll();
      if (items?.length > 0) {
        if (items?.length > 1) {
        } else {
          if (id === 'EXTRAPAYMENTCANCEL') {
            setCancelReasionObj({
              visible: true,
              searchObj,
              fetchFn: (searchObj) => wrappedFetchList(searchObj),
              item: items[0],
            });
          } else if (id === 'EXTRAPAYMENTDRIVERCANCEL') {
            cancelDriverExtraChargePayment(items[0]);
          }
        }
      } else {
        alert('선택된 항목이 존재하지 않습니다.');
      }
    }
  };

  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const cancelDriverExtraChargePayment = async (item) => {
    if (window.confirm('결제 요청된 정보를 취소하시겠습니까?')) {
      const dataDTO = { paymentSeq: item.paymentSeq };
      const rs = await orderService.postCancelDriverExtraChargePayment(dataDTO);
      if (rs?.status === 200) {
        alert('취소 되었습니다!');
        wrappedFetchList(searchObj);
      }
    }
  };

  return (
    <div className="page-content">
      {cancelReasonObj?.visible && <CancelReasonPop cancelReasonObj={cancelReasonObj} setCancelReasionObj={setCancelReasionObj} />}
      {orderDetailObj?.visible && <OrderDetail orderDetailObj={orderDetailObj} setOrderDetailObj={setOrderDetailObj} />}
      {bottomSheetObj?.visible && <BottomSheet bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            //
            fixedColumnCount: 2,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns.filter((_, idx) => idx > 1)} />
        <PrintGridBox gridRef={excelGridDetailRef} columns={detailExcelColumns} />
      </div>
    </div>
  );
};

export default Index;
