import React, { useEffect } from 'react';
import JsBarcode from 'jsbarcode';
import { v4 as uuidv4 } from 'uuid';
import { QRCodeSVG } from 'qrcode.react';

import styles from './barcodesPrint.module.scss';
import { Button } from 'reactstrap';

export const defaultBarcodesPrintObj = {
  visible: false,
  data: null,
};

export class BarcodeRenderer extends React.Component {
  componentDidMount() {
    JsBarcode(this.props.name).init();
  }
  render() {
    return null;
  }
}

export const PrintMode = (props) => {
  const { barcodesPrintObj = [], setBarcodesPrintObj } = props;

  useEffect(() => {
    if (barcodesPrintObj?.data?.length > 0) {
      window.print();
    }
  }, [barcodesPrintObj]);

  return (
    <>
      <div className="noPrint" style={{ textAlign: 'right', padding: 50 }}>
        <Button
          onClick={() => {
            setBarcodesPrintObj({
              ...barcodesPrintObj,
              visible: false,
            });
          }}
        >
          {'< 뒤로가기'}
        </Button>
      </div>
      <div className={`${styles.printBody} noMargin noPadding`}>
        {barcodesPrintObj?.data?.map((ele, dIndex) => {
          const quantityArr = new Array(ele.printQuantity).fill(1);
          return (
            <div key={`barcode_${dIndex}_${uuidv4()}`}>
              {quantityArr.map((empty, qIdex) => {
                return (
                  <table className={`${styles.onePatch} break-for-onePage-print noMargin barcodePatch noBorder`} key={`barcode_${dIndex}/${qIdex}_${uuidv4()}`}>
                    <tbody>
                      <tr>
                        <td rowSpan={5} style={{ maxWidth: 40, width: 40 }}>
                          <div className={`${styles.rotateBarcode} rotat90`}>
                            <svg
                              className="barcode"
                              jsbarcode-format="CODE128"
                              jsbarcode-fill="transparent"
                              jsbarcode-fontoptions="bold"
                              jsbarcode-value={ele.barcode}
                              jsbarcode-textmargin="0"
                              jsbarcode-width="2px"
                            />
                            <BarcodeRenderer name=".barcode" />
                          </div>
                        </td>
                        <td className={`${styles.date}`} colSpan={2}>
                          {ele.promiseDate ? `희망일: ${ele.promiseDate}` : ''}
                        </td>
                        <td className={`${styles.date}`} style={{ textAlign: 'right', paddingRight: 20 }} colSpan={2}>
                          출력시간: {ele.now}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} style={{ fontSize: 20 }}>
                          {ele.partnerSeqKr || ele.partnerName} / {ele.brandKr}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}>{ele.model}</td>
                      </tr>
                      <tr>
                        <td colSpan={4}>{ele.modelName}</td>
                      </tr>
                      <tr>
                        <td colSpan={1} style={{ textAlign: 'right' }}>
                          <QRCodeSVG value={ele.barcode} size={70} />,
                        </td>
                        <td colSpan={3}>
                          <div className={styles.rotateBarcode2}>
                            <svg
                              className="barcode"
                              jsbarcode-format="CODE128"
                              jsbarcode-fill="transparent"
                              jsbarcode-value={ele.barcode}
                              jsbarcode-textmargin="0"
                              jsbarcode-width="2px"
                              jsbarcode-height="60px"
                              jsbarcode-fontoptions="bold"
                            />
                            <BarcodeRenderer name=".barcode" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};
