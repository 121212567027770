import { Col, Row, Form } from 'reactstrap';
import Grid from 'components/grid/auiGrid';
import { useEffect, useMemo } from 'react';

// redux
import { useSelector } from 'react-redux';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { ModelListModal } from './modelListModal';
const Presenter = (props) => {
  const { modelSearchObj, setModelSearchObj } = props;

  return (
    <>
      <div className="page-content" style={{ overflow: 'auto' }}>
        {modelSearchObj?.visible && <ModelListModal modelSearchObj={modelSearchObj} setModelSearchObj={setModelSearchObj} />}
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const Search = (props) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);
  const { searchObj, setSearchObj, fetchList, goRegister } = props;

  const handleSubmit = (e) => {
    if (!searchObj?.centerCode) {
      alert('창고 검색자는 필수값 입니다!');
    } else {
      e?.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col style={{ textAlign: 'right' }}>
          <button
            className="btn btn-blue sm"
            onClick={(e) => {
              e.preventDefault();
              goRegister();
            }}
          >
            등록하기
          </button>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <label className="col-form-label">*창고</label>
            <SelectD
              value={
                searchObj?.centerCode
                  ? {
                      value: searchObj?.centerCode,
                      label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: option.value,
                });
              }}
              options={MASTER_OPS?.CENTER_AUTH}
            />
          </Col>
          <Col>
            <label className="col-form-label">로케이션</label>
            <InputD
              value={searchObj?.locationCode || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  locationCode: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">판매사</label>
            <SelectD
              value={
                searchObj?.partnerSeq
                  ? {
                      value: searchObj?.partnerSeq,
                      label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[searchObj?.partnerSeq],
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: option.value,
                });
              }}
              options={MASTER_OPS?.SELLER_SELLER_AUTH}
            />
          </Col>
          <Col>
            <label className="col-form-label">제품명</label>
            <InputD
              value={searchObj?.modelName || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  modelName: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">바코드</label>
            <InputD
              value={searchObj?.barcode || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  barcode: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">SKU</label>
            <InputD
              value={searchObj?.modelStockSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  modelStockSeq: e.target.value,
                });
              }}
            />
          </Col>
          <Col style={{ textAlign: 'left' }}>
            <button className="btn btn-search" type="submit">
              조회
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const GridBox = (props) => {
  const { rows = [], gridId, columns = [], setRows, getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid, onCellClick, onEditEnded } = props;

  return (
    <>
      <div className="grid-button-area">
        <div
          className="red"
          onClick={() => {
            setGetCheckedDataTriggerThisGrid({
              type: 'delete-row',
              id: `#${gridId}`,
              withRowIndex: true,
            });
          }}
        >
          삭제
        </div>
        <div
          className="red"
          onClick={(e) => {
            setRows([]);
          }}
        >
          목록초기화
        </div>
      </div>
      <div style={{ width: '100%' }}>
        {gridId && (
          <Grid
            id={gridId}
            rows={rows || []}
            columns={columns}
            getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
            setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            onEditEnded={onEditEnded}
            onCellClick={onCellClick}
            settingOptions={{
              usePaging: true,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              fixedColumnCount: 1,
              hScrollPosition: 1500,
            }}
          />
        )}
      </div>
    </>
  );
};
