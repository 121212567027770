import GridBox, { PARCEL_GRID_PAGE_ROW_COUNT } from 'common/grid/gridBox';
import CommonModalNew from 'components/modal/commonModalNew';
import AUIGrid from 'modules/Grid';
import { useEffect, useRef } from 'react';
import * as IGrid from 'aui-grid';
import { serviceStore } from 'services/services';

export interface IinviteObj {
  visible?: boolean;
}

export const InvitePopup = ({ inviteObj, setInviteObj }) => {
  const gridRef = useRef<AUIGrid>();

  useEffect(() => {
    const empty = new Array(500)?.fill(0)?.map((ele) => {
      return {
        phoneNumber: '',
        email: '',
      };
    });
    gridRef?.current?.setGridData(empty);
  });

  const columns: IGrid.Column[] = [
    {
      headerText: '연락처',
      dataField: 'phoneNumber',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        inputMode: 'tel',
      },
    },
    {
      headerText: '이메일',
      dataField: 'email',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        inputMode: 'email',
      },
    },
  ];

  const letsInvite = async () => {
    //
    const items = gridRef?.current?.getGridData();
    const filtered = items?.filter((ele) => ele.phoneNumber || ele.email);
    const rs = await serviceStore.userAction(`access/user/invite`, 'POST', null, filtered);
    if (rs?.status === 200) {
      alert(`${filtered?.length}건의 초대가 발송 완료 되었습니다`);
      // 값있는거 위로 올린다
      items?.sort((a, b) => {
        if (!a.phoneNumber && !a.email) return 1;
        else return -1;
      });
      gridRef?.current?.setGridData(items);
    }
  };

  return (
    <CommonModalNew
      title={'조직원 초대'}
      subTitle="연락처 또는 이메일을 필수로 입력해주세요"
      style={{ width: 500 }}
      visible={inviteObj?.visible}
      setVisible={() => {
        setInviteObj(null);
      }}
      rightTitle={
        <>
          <button className="btn btn-secondary ssm" onClick={letsInvite}>
            초대
          </button>
        </>
      }
      children={
        <>
          <GridBox
            gridRef={gridRef}
            columns={columns}
            gridProps={{
              pageRowCount: PARCEL_GRID_PAGE_ROW_COUNT,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              editable: true,
            }}
          />
        </>
      }
    />
  );
};
