import imageCompression from 'browser-image-compression';
import { FILE_URL } from 'envVar';
import axios from 'axios';
import { httpClient } from '../http-client/axiosConfig';
import { ChangeEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const resizeImage = async (file) => {
  // Math 메소를 활용해서 캔버스에 맞으면서 이미지 배율을 유지한챌 할수 잇도록 scale을 정해줌
  // const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
  // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
  const options = {
    // maxSizeMB: 10,
    maxWidthOrHeight: 1000,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    // resize된 이미지의 url을 받아 fileUrl에 저장
    const result = await imageCompression.getDataUrlFromFile(compressedFile);
    return { compressedFile: compressedFile, fileUrl: result };
  } catch (error) {
    // console.log(error);
  }
  return {};
};

// 미리보기 이미지
export const handleFileReturnImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
  if (!e.target.files) return;
  const files = (e.target as HTMLInputElement).files;
  if (files?.length > 0) {
    const values = Object.values(files);
    const rawFiles = await Promise.all(
      values?.map(async (file) => {
        const image = window.URL.createObjectURL(file);
        const ret = await resizeImage(file);
        console.log(ret);
        return { file, ret, image };
      }),
    );

    (e.target as HTMLInputElement).value = '';
    return rawFiles;
  } else {
    return [];
  }
};

export const handleFiles = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const files = (e.target as HTMLInputElement).files;
  if (files?.length > 0) {
    const values = Object.values(files);
    const res = await Promise.all(
      values?.map(async (file) => {
        if (file.type === 'image/jpeg') {
          const ret = await resizeImage(file);
          return { file: ret.compressedFile, url: ret.fileUrl + uuidv4().split('-') };
        } else {
          const url = window.URL.createObjectURL(file);
          console.log(url);
          return { file: file, url: url };
        }
      }),
    );

    (e.target as HTMLInputElement).value = '';
    // (e.target as HTMLInputElement).id = '';

    return res;
  } else {
    return [];
  }
};

export const handleFile = async (e) => {
  const file = e.target.files[0];
  if (file) {
    //if (file.type.startsWith("image")) {
    if (file.type === 'image/jpeg') {
      const ret = await resizeImage(file);
      return { file: ret.compressedFile, url: ret.fileUrl };
    } else {
      const url = window.URL.createObjectURL(file);
      return { file: file, url: url };
    }
  } else {
    return {};
  }
};

export const uploadFile = async (file, fileUrl) => {
  const uploadURL = await getUploadUrl(file);
  if (!uploadURL) return false;
  return uploadByUrl(file, fileUrl, uploadURL);
};

export const getUploadUrl = async (file) => {
  if (!file) return false;
  var data = JSON.stringify({
    name: file.name,
    type: file.type,
  });

  const rs = await httpClient.post(FILE_URL, data);
  if (rs?.data?.uploadURL) return rs.data.uploadURL;
  return null;
};
export const uploadByUrl = async (file, fileUrl, uploadURL) => {
  const config = {
    method: 'put',
    url: uploadURL,
    headers: {
      'Content-Type': file.type,
    },
    data: file,
  };
  const rs = await axios(config);
  if (rs?.status === 200) {
    const downloadUrl = uploadURL.substring(0, uploadURL.indexOf('?'));
    if (rs?.status === 200) return downloadUrl;
  }
};

// custom converter
export const convertURLtoFile = async (url, name) => {
  const response = await fetch(url);
  const data = await response.blob();
  const filename = name; // url 구조에 맞게 수정할 것
  const metadata = { type: `image/jpeg` };
  return new File([data], filename, metadata);
};
