import { useState, useEffect, ReactNode, useRef, ForwardedRef } from 'react';
import styled from 'styled-components';
import transparent from '../template/transparent.png';

const DraggableWrapper = styled.div({
  display: 'inline-block',
  position: 'absolute',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  zIndex: 10000,
  backgroundColor: 'white',
  transform: 'translate(+50%, +50%)',
});

export interface IDraggable {
  children: ReactNode;
  parentRef: ForwardedRef<HTMLDivElement>;
  defaultPosition: {
    topOrBottom?: {
      position: 'top' | 'bottom';
      number: number;
    };
    leftOrRight?: {
      position: 'left' | 'right';
      number: number;
    };
  };
}

const Draggable = ({ children, parentRef, defaultPosition }: IDraggable) => {
  const [top, setTop] = useState<number>(10);
  const [left, setLeft] = useState<number>(0);
  const [tmp, setTmp] = useState<{ top: number; left: number }>({ top: 10, left: 0 });
  const ghostEle = useRef(null);
  const wrapperRef = useRef<HTMLDivElement>();

  useEffect(() => {
    ghostEle.current = new Image();
    ghostEle.current.src = transparent;
    if (parentRef && typeof parentRef !== 'function') {
      parentRef?.current?.setAttribute('overflow', 'visible');
      parentRef?.current?.setAttribute('background-color', 'red');
      const parentWidth = parentRef?.current?.clientWidth;
      const parentHeight = parentRef?.current?.clientHeight;
      const top = defaultPosition?.topOrBottom?.position === 'top' ? defaultPosition?.topOrBottom?.number : parentHeight - defaultPosition?.topOrBottom?.number;
      const left = defaultPosition?.leftOrRight?.position === 'left' ? defaultPosition?.leftOrRight?.number : parentWidth - defaultPosition?.leftOrRight?.number;
      setTop(top);
      setLeft(left);
      setTmp({
        top,
        left,
      });
    }
  }, []);

  const isThis = () => {
    return true;
  };
  const onDragStart = (e: any) => {
    if (isThis()) {
      e.dataTransfer.setDragImage(ghostEle.current, 0, 0);
      setTmp({ top: e.clientY, left: e.clientX });
    }
  };

  const onDragOver = (e: any) => {
    if (isThis()) {
      setTmp({ top: e.clientY, left: e.clientX });
      setTop(top - (tmp?.top - e.clientY));
      setLeft(left - (tmp?.left - e.clientX));
    }
  };

  // 어플용
  const onTouchStart = (e: any) => {
    if (isThis()) {
      const x = e.changedTouches?.[0].pageX;
      const y = e.changedTouches?.[0].pageY;

      setTmp({ top: y, left: x });
    }
  };
  const onTouchEnd = (e: any) => {
    if (isThis()) {
      const x = e.changedTouches?.[0].pageX;
      const y = e.changedTouches?.[0].pageY;
      setTmp({ top: y, left: x });
      setTop(top - (tmp?.top - y));
      setLeft(left - (tmp?.left - x));
    }
  };

  return (
    <>
      <DraggableWrapper
        ref={wrapperRef}
        style={{
          top,
          left,
        }}
        draggable
        onTouchStart={onTouchStart}
        onTouchMove={onTouchEnd}
        onTouchEnd={onTouchEnd}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
      >
        {children}
      </DraggableWrapper>
    </>
  );
};

export default Draggable;
