import { Row, Col } from 'reactstrap';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { useRef, useState } from 'react';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';
import { INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION, MODEL_STOCK_SEARCH } from 'envVar';

import { setLoading } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import * as IGrid from 'aui-grid';
import { ICreateObj } from '..';
import { InventoryModelDTO, ModelStockAndModelDTO } from 'interface/warehouse';

const menuOptions = [
  { label: '바코드', value: 'barcode' },
  { label: 'SKU', value: 'modelStockSeq' },
  { label: '외부SKU', value: 'skuNumber' },
  { label: '제품', value: 'model' },
  { label: '제품명', value: 'modelName' },
];

export interface IAddingPopupObj {
  mode?: 'before' | 'after';
  visible?: boolean;
  createObj?: ICreateObj;
  returns?: any;
  choosed?: any;
}

export const SearchModelForMoveModal = ({ addingPopupObj, setAddingPopupObj }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const [searchObj, setSearchObj] = useState({
    centerCode: addingPopupObj?.createObj?.centerCode,
    partnerSeq: addingPopupObj?.createObj?.partnerSeq,
    modelType: null,
    modelGroup: null,
    menuSelect: null,
    menuValue: null,
  });

  const beforeColumns: IGrid.Column[] = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
    },
    {
      headerText: '현재보관수량',
      children: [
        {
          headerText: '양품',
          dataField: 'totalAvailableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'totalDefectedQuantity',
        },
        {
          headerText: '변경가능양품',
          dataField: 'availableQuantity',
        },
        {
          headerText: '변경가능불용',
          dataField: 'defectedQuantity',
        },
      ],
    },
    {
      headerText: '변경수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantityTo',
          editable: true,
          style: 'my-custom-aui',
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            autoThousandSeparator: true,
            onlyNumeric: true,
            allowNegative: false,
          },
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantityTo',
          style: 'my-custom-aui',
          editable: true,
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            autoThousandSeparator: true,
            onlyNumeric: true,
            allowNegative: false,
          },
        },
      ],
    },
  ];

  const afterColumns = [
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
    },
  ];

  const addSearchObj = (url, searchObj) => {
    if (searchObj?.centerCode) url += `&centerCode=${searchObj?.centerCode}`;
    if (searchObj?.partnerSeq) url += `&partnerSeq=${searchObj?.partnerSeq}`;

    if (searchObj?.modelType) url += `&modelType=${searchObj?.modelType}`;
    if (searchObj?.modelGroup) url += `&modelGroup=${searchObj?.modelGroup}`;
    if (searchObj?.menuSelect) url += `&${searchObj?.menuSelect}=${searchObj?.menuValue}`;

    return url;
  };

  const fetchList = async (mode: 'before' | 'after') => {
    dispatch(setLoading('GET'));
    let url: string;
    if (mode === 'before') {
      url = INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION + `?`;
    } else {
      url = MODEL_STOCK_SEARCH + `?`;
    }
    url = addSearchObj(url, searchObj);

    await httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        const data: ModelStockAndModelDTO[] | InventoryModelDTO[] = rs?.data;
        data?.forEach((row) => {
          row.centerCodeKr = MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode];
          row.partnerSeqKr = MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq];
          row.modelGroupKr = MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup];
          row.availableQuantityTo = 0;
          row.defectedQuantityTo = 0;
        });
        gridRef.current.setGridData(data);
      }
    });
    dispatch(setLoading(null));
  };

  const validator = (items) => {
    const noItems = items?.filter((ele) => ele.availableQuantityTo === 0 && ele.defectedQuantityTo === 0);
    const noAvailable = items?.filter((ele) => ele.availableQuantity === 0 && ele.defectedQuantity === 0);
    const quantityError = items?.filter((ele) => ele.availableQuantity < ele.availableQuantityTo || ele.defectedQuantity < ele.defectedQuantityTo);
    if (noItems?.length > 0) {
      alert('변경수량이 입력되지 않은 제품이 존재합니다');
      return false;
    }
    if (noAvailable?.length > 0) {
      alert('전환가능한품목이 0인 제품이 존재합니다');
      return false;
    }
    if (quantityError?.length > 0) {
      alert('전환가능한품목 수량을 초과한 제품이 존재합니다');
      return false;
    }
    return true;
  };

  return (
    <CommonModalNew
      title={`${addingPopupObj?.mode === 'before' ? '제품추가' : '전환제품추가'}`}
      style={{ width: addingPopupObj?.mode === 'before' ? 1500 : 1200 }}
      visible={addingPopupObj?.visible}
      setVisible={() => {
        setAddingPopupObj(null);
      }}
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">제품타입</label>
              <SelectD
                options={MASTER_OPS?.MODEL_TYPE}
                value={
                  searchObj?.modelType
                    ? {
                        value: searchObj?.modelType,
                        label: MASTER_OBJ?.MODEL_TYPE?.[searchObj?.modelType],
                      }
                    : null
                }
                onChange={(value) => {
                  setSearchObj({
                    ...searchObj,
                    modelType: value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">제품그룹</label>
              <SelectD
                options={MASTER_OPS?.MODEL_GROUP}
                value={
                  searchObj?.modelGroup
                    ? {
                        value: searchObj?.modelGroup,
                        label: MASTER_OBJ?.MODEL_GROUP?.[searchObj?.modelGroup],
                      }
                    : null
                }
                onChange={({ value }) => {
                  setSearchObj({
                    ...searchObj,
                    modelGroup: value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">제품/바코드</label>
              <div style={{ display: 'flex' }}>
                <SelectD
                  options={menuOptions}
                  value={
                    searchObj?.menuSelect
                      ? {
                          value: searchObj?.menuSelect,
                          label: selectlabel(searchObj?.menuSelect, menuOptions),
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      menuSelect: value,
                    });
                  }}
                />
                <InputD
                  value={searchObj?.menuValue || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      menuValue: e.target.value,
                    });
                  }}
                />
              </div>
            </Col>
            <Col>
              <button
                className="btn btn-secondary sm"
                onClick={(e) => {
                  e.preventDefault();
                  fetchList(addingPopupObj?.mode);
                }}
              >
                조회
              </button>
            </Col>
          </Row>
          <div className="grid-button-area only-right">
            <div
              className="orange"
              onClick={() => {
                const items = gridRef.current.getCheckedRowItemsAll();
                if (items?.length > 0) {
                  if (addingPopupObj?.mode === 'before' ? validator(items) : true) {
                    alert('추가되었습니다!');
                    setAddingPopupObj((prev) => {
                      return {
                        ...prev,
                        returns: items,
                      };
                    });
                    fetchList(addingPopupObj?.mode);
                  }
                } else {
                  alert('선택된 항목이 존재하지 않습니다.');
                }
              }}
            >
              {addingPopupObj?.mode === 'before' ? '+제품추가' : ' +전환제품 선택'}
            </div>
          </div>
          <GridBox
            gridRef={gridRef}
            columns={addingPopupObj?.mode === 'before' ? beforeColumns : afterColumns}
            gridProps={{
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              editable: true,
              rowCheckToRadio: addingPopupObj?.mode === 'before' ? false : true,
            }}
          />
        </>
      }
    />
  );
};
