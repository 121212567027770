import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as IGrid from 'aui-grid';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { InventoryMoveInfoDTO, InventoryMoveDetailInfoDTO } from 'interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';
import GridBox from 'common/grid/gridBox';
import { locationService } from 'pages/INVENTORY/3location/_services/service';

import AUIGrid from 'modules/Grid';

export interface IDetailObj {
  visible?: boolean;
  item?: InventoryMoveInfoDTO;
  dataField?: string;
  selected?: {
    [key: string]: {
      availableInventorySeqs?: number[];
      defectedInventorySeqs?: number[];
    };
  };
}

export const DetailPopup = ({ detailObj, setDetailObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<AUIGrid>();
  const columns: { [key: string]: IGrid.Column[] } = {
    defectedInventorySeqsKr: [
      {
        headerText: '재고번호',
        dataField: 'inventorySeq',
      },
      {
        headerText: '송장유형',
        dataField: 'feeTypeKr',
      },
      {
        headerText: '회수주문번호',
        dataField: 'orderSeqForCollect',
      },
      {
        headerText: '회수송장번호',
        dataField: 'invoiceSeqForCollect',
      },
      {
        headerText: '고객명',
        dataField: 'customerName',
      },
      {
        headerText: '시리얼번호',
        dataField: 'serialNumber',
      },
    ],
    availableInventorySeqsKr: [
      {
        headerText: '재고번호',
        dataField: 'inventorySeq',
      },
      {
        headerText: '지정일',
        dataField: 'deliveryDate',
      },
      {
        headerText: '기사출고창고',
        dataField: 'loanCenterCodeKr',
      },
      {
        headerText: '주문번호',
        dataField: 'orderSeq',
      },
      {
        headerText: '송장번호',
        dataField: 'invoiceSeq',
      },
      {
        headerText: '고객명',
        dataField: 'customerName',
      },
      {
        headerText: '시리얼번호',
        dataField: 'serialNumber',
      },
    ],
  };

  const thisKey = detailObj?.item?.forMerges;
  useEffect(() => {
    if (detailObj?.visible) fetchPaging();
  }, [detailObj?.visible]);

  const returnThese = () => {
    const items = gridRef.current.getCheckedRowItemsAll();
    console.log(items);
    const selected = JSON.parse(JSON.stringify(detailObj?.selected));
    selected[thisKey] = {
      ...selected[thisKey],
      [detailObj?.dataField?.split('Kr')[0]]: items?.map((ele) => ele.inventorySeq),
    };
    console.log(thisKey);
    console.log(selected);
    alert('저장되었습니다');
    setDetailObj((prev) => {
      return {
        ...prev,
        visible: false,
        selected,
      };
    });
  };

  const fetchPaging = async () => {
    dispatch(setLoading('GET'));
    const searchDTO = {
      centerCode: detailObj?.item?.centerCode,
      locationCode: detailObj?.item?.locationCode,
      modelStockSeq: detailObj?.item?.modelStockSeq,
      availableType: detailObj?.dataField?.includes('defectedInventorySeqsKr') ? 'DEFECTED' : 'AVAILABLE',
    };
    console.log(detailObj);

    const data = await locationService.getInventoryMoveAction('inventory/remove/detail/info', searchDTO, false);
    const dataKr = (data as InventoryMoveDetailInfoDTO[])?.map((row) => {
      return {
        ...row,
        availableTypeKr: MASTER_OBJ?.INVA_TYPE?.[row?.availableType],
        feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row?.feeType],
        loanCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.loanCenterCode],
      };
    });
    gridRef.current.setGridData(dataKr);
    gridRef.current.setCheckedRowsByValue('inventorySeq', detailObj?.selected?.[thisKey][detailObj?.dataField?.split('Kr')?.[0]]);
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title={detailObj?.dataField === 'defectedInventorySeqsKr' ? '불용할당재고 선택' : '양품할당재고 선택'}
      style={{ width: 1300 }}
      visible={detailObj?.visible}
      rightTitle={
        <>
          <button
            className="btn btn-secondary sm"
            onClick={() => {
              returnThese();
            }}
          >
            저장
          </button>
        </>
      }
      setVisible={() => {
        setDetailObj((prev) => {
          return {
            ...prev,
            visible: false,
            item: null,
            dataField: null,
          };
        });
      }}
      children={
        <>
          <div className="presenterGridBox">
            <GridBox
              //
              gridRef={gridRef}
              columns={columns[detailObj?.dataField]}
              gridProps={{
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
            />
          </div>
        </>
      }
    />
  );
};
