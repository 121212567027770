import { useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { setLoading } from 'redux/services/menuSlice';
import { useDispatch } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { GroupDTO, GroupSearchDTO } from 'interface/user';
import { PagingListDTO } from 'interface/util';
import { managerService } from '../_service/service';
import { init } from 'redux/services/groupSlice';
import { AuthGroupDetail, IAuthDetailObj } from './component/authGroupDetail';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const labellingKr = (data: PagingListDTO<GroupDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      const groupTypeKr = [];
      if (row.logisticFlag) groupTypeKr?.push('물류사');
      if (row.ownerFlag) groupTypeKr?.push('화주사');

      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        groupTypeKr: groupTypeKr?.join(','),
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: GroupSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await managerService.getGroupPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const deleteGroup = async (e) => {
    const checked = gridRef.current.getCheckedRowItemsAll();
    if (checked?.length > 0) {
      if (window.confirm('선택한 조직을 정말로 삭제하시겠습니까?')) {
        dispatch(setLoading('GET'));
        const rs = await managerService.postGroupAction(
          'delete',
          checked?.map((ele) => {
            return {
              seq: ele.groupSeq,
            };
          }),
        );
        if (rs?.status === 200) {
          alert('삭제되었습니다');
          wrappedFetchList(searchObj);
        }
        dispatch(setLoading(null));
      }
    } else {
      alert('선택된 건이 없습니다!');
    }
  };

  const [authDetailObj, setAuthDetailObj] = useState<IAuthDetailObj>();
  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(init());
    dispatch(setLoading('GET'));
    setAuthDetailObj({
      visible: true,
      fetchFn: (searchObj) => wrappedFetchList(searchObj),
      searchObj,
      item: e.item,
    });
    dispatch(setLoading(null));
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetail,
      },
    },
    // 화주사 ownerFlag
    // 물류사 logisticFlag
    {
      headerText: '조직유형',
      dataField: 'groupTypeKr',
    },
    {
      headerText: '조직명',
      dataField: 'groupName',
    },
    {
      headerText: '조직장명',
      dataField: 'userName',
    },
    {
      headerText: '조직장ID',
      dataField: 'userId',
    },
    {
      headerText: '조직원 수',
      dataField: 'userQuantity',
    },
  ];

  return (
    <div className="page-content">
      {authDetailObj?.visible && <AuthGroupDetail authDetailObj={authDetailObj} setAuthDetailObj={setAuthDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area">
          <div
            className="blue"
            onClick={() => {
              setAuthDetailObj({
                visible: true,
                item: null,
                fetchFn: (searchObj) => wrappedFetchList(searchObj),
                searchObj,
              });
            }}
          >
            조직 등록
          </div>
          <div className="red" onClick={deleteGroup}>
            조직 삭제
          </div>
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
