import { KeyboardEvent } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

import { useEffect, useState } from 'react';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { resetAllSelections } from 'common/util/searchBox';
import { PurchaseOrderSerialNumberSearchDTO } from 'interface/warehouse';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

export const SearchBox = (props: ISearchBoxProps<PurchaseOrderSerialNumberSearchDTO>) => {
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_MODEL: null,
    SEARCH_BARCODE: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_BARCODE, searchObj));
  }, [selectTypeObj?.SEARCH_BARCODE]);

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">발주번호</label>
          <InputD
            value={searchObj?.purchaseSeq || ''}
            onChange={(e) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  purchaseSeq: Number(e.target.value),
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            options={masterOptions?.SELLER}
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER),
                  }
                : null
            }
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품/제품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: masterOptions?.SEARCH_MODEL_OBJ[selectTypeObj?.SEARCH_MODEL],
                      }
                    : null
                }
                options={masterOptions?.SEARCH_MODEL}
                onChange={({ value }) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                    };
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">바코드/LOT번호(외부바코드)</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_BARCODE
                    ? {
                        value: selectTypeObj?.SEARCH_BARCODE,
                        label: masterOptions?.SEARCH_BARCODE_OBJ[selectTypeObj?.SEARCH_BARCODE],
                      }
                    : null
                }
                options={masterOptions?.SEARCH_BARCODE}
                onChange={({ value }) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_BARCODE: value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_BARCODE}
                value={searchObj?.[selectTypeObj?.SEARCH_BARCODE] || ''}
                onChange={(e) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      [selectTypeObj?.SEARCH_BARCODE]: e.target.value,
                    };
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">시리얼번호</label>
          <InputD
            value={searchObj?.serialNumber || ''}
            onChange={(e) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  serialNumber: e.target.value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
