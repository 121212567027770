import { ReactNode, useMemo, useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';

// utils
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
// service
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { serviceStore } from 'services/services';
import { MessageTemplateDTO, MessageTemplateSearchDTO } from 'interface/order';
import { ITplDetailObj, TplDetailPopup } from './component/message';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const [tplDetailObj, setTplDetailObj] = useState<ITplDetailObj>();
  const modiMessage = async (e: IGrid.ButtonClickEvent) => {
    //

    setTplDetailObj({
      visible: true,
      fetchFn: wrappedFetchList,
      item: e.item,
      searchObj,
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '템플릿 수정',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '수정',
        onClick: modiMessage,
      },
    },
    {
      headerText: '템플릿 일련번호',
      dataField: 'messageTemplateSeq',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypesKr',
    },
    {
      headerText: '템플릿 관리명',
      dataField: 'title',
    },
    {
      headerText: '유형 선택',
      dataField: 'customFlagKr',
    },
    {
      headerText: '전송 시점',
      dataField: 'messageTypeKr',
    },
    {
      headerText: '전송 유무',
      dataField: 'sendFlagKr',
    },
  ];

  const deleteTemplate = async (items) => {
    if (window.confirm('"템플릿 삭제"를 진행하시겠습니까?')) {
      const rs = await serviceStore.orderAction(`message/template/delete?&messageTemplateSeqs=${items?.map((ele) => ele.messageTemplateSeq)?.join('&messageTemplateSeqs=')}`, 'POST', null);
      if (rs?.status === 200) {
        alert('배송안내 템플릿이 삭제되었습니다!');
        wrappedFetchList(searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  const labellingKr = (data: PagingListDTO<MessageTemplateDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        messageTypeKr: MASTER_OBJ?.MESSAGE_TYPE?.[row.messageType],
        customFlagKr: row.customFlag ? '커스텀' : '기본',
        sendFlagKr: row.sendFlag ? '전송' : '미전송',
        feeTypesKr: row.feeTypes?.map((ele) => MASTER_OBJ?.FEE_TYPE?.[ele])?.join(','),
        feeTypes: JSON.stringify(row.feeTypes),
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: MessageTemplateSearchDTO) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore.orderAction(`message/template/paging`, 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const handleCheckItems = (id) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'CUSTOMALERTMSGREMOVE') {
        deleteTemplate(items);
      }
    } else {
      alert('선택된 건이 없습니다!');
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    console.log(id);
    if (id === 'CUSTOMALERTMSGADD') {
      setTplDetailObj({
        visible: true,
        fetchFn: wrappedFetchList,
        searchObj,
      });
    } else {
      handleCheckItems(id);
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj, tabId]);

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      {tplDetailObj?.visible && <TplDetailPopup tplDetailObj={tplDetailObj} setTplDetailObj={setTplDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
