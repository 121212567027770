import { OptionItem } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { SelectD } from 'components/reactstrap/reactstrap';
import { ExtraChargePaymentCancelDTO, InvoicePaymentDTO, PaymentSearchDTO } from '../../../_interface/order';
import { orderService } from 'pages/ORDER/1order/_services/service';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';

export interface ICancelReason {
  visible?: boolean;
  item?: InvoicePaymentDTO;
  searchObj?: PaymentSearchDTO;
  fetchFn?: (data: PaymentSearchDTO) => {};
}

export const CancelReasonPop = ({ cancelReasonObj, setCancelReasionObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [cancelReason, setCancelReason] = useState<string>();

  const cancelExtraChargePayment = async () => {
    if (window.confirm('결제완료된 내역을 취소하시겠습니까?')) {
      const dataDTO: ExtraChargePaymentCancelDTO = {
        ...cancelReasonObj?.item,
        // 필수값
        cancelReason,
      };
      const rs = await orderService.postCancelExtraChargePayment(dataDTO);
      if (rs?.status === 200) {
        alert('취소 되었습니다!');
        cancelReasonObj?.fetchFn(cancelReasonObj?.searchObj);
        setCancelReasionObj(null);
      }
    }
  };
  return (
    <CommonModalNew
      title="결제취소"
      subTitle={`결제번호 : ${cancelReasonObj?.item?.paymentSeq}`}
      style={{ width: 350 }}
      visible={cancelReasonObj?.visible}
      setVisible={() => {
        setCancelReasionObj(null);
      }}
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">*취소사유</label>
              <SelectD
                options={MASTER_OPS?.PAYMENT_CANCEL}
                value={
                  cancelReason
                    ? {
                        value: cancelReason,
                        label: MASTER_OBJ?.PAYMENT_CANCEL?.[cancelReason],
                      }
                    : null
                }
                onChange={(option) => {
                  setCancelReason((option as OptionItem)?.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'right' }}>
              <button
                className="btn-orange btn"
                onClick={(e) => {
                  if (cancelReason) {
                    cancelExtraChargePayment();
                  }
                }}
              >
                취소
              </button>
            </Col>
          </Row>
        </>
      }
    />
  );
};
