import {
  clearBuffer,
  setLabelId,
  drawDeviceFont,
  draw1DBarcode,
  drawQRCode,
  getLabelData,
  printBuffer,
  setSpeed,
  setDensity,
  setOrientation,
  setMargin,
  setLength,
  setWidth,
} from 'assets/bixolon/bixolon_label';
import { requestPrint } from 'assets/bixolon/bixolon_common';

//NOTE: 각 클라이언트 환경마다 sdk 설치 후 프린터 이름 LogicalName 으로 동일하게 설정필요
const BIXOLON_PRINTER_LOGICAL_NAME = 'LcsBixolonLabelPrinter';

/* validItems는 아래 객체로 된 배열
  {
    ReservationNumber: 예약번호 
    ResultCode: 응답코드
    Subs: [
      {
        Index: 1
        LabelCommand: [
          커맨드 텍스트로 이루어진 배열
          예시 : "T800,20,c,1,1,0,1,N,N,F,'발송영업소'"
          >> ,를 기준으로 split 해서 가장 마지막 요소를 첫번째 순서로, 가장 첫번째 요소의 첫글자를 판단해서 아래 분기 처리
          >> T인 경우 drawDeviceFont 함수 사용
          >> B1인 경우 draw1DBarcode 함수 사용
          >> B2인 경우 drawQRCode 함수 사용
        ]
      }
    ]
  }

  1. validItems의 Subs의 LabelCommand 배열만 뽑아 새로운 subs 배열을 생성
  2. 생성된 subs 배열을 map으로 순회하며 배열 내 중복 순회로 배열에 담긴 각 문자열들을 파싱
  4. sdk에서 제공하는 메소드를 사용하여 JSON 객체를 생성하고
  5. 생성된 JSON 객체를 라벨 프린터로 요청을 보내고 콜백을 받아 처리
*/

const generateAndExcuteSubCommand = (validItems) => {
  const subs = validItems?.map((item) => item?.Subs?.[0]?.LabelCommand);

  for (let sub of subs) {
    let printObj = {};
    let issueID = 1;

    sub.forEach((command: string, index: number) => {
      if (command.includes('SS')) {
        const setSpeed = command.replace('SS', '');
        printObj['setSpeed'] = Number(setSpeed);
      }
      if (command.includes('SD')) {
        const setDensity = command.replace('SD', '');
        printObj['setDensity'] = Number(setDensity);
      }
      if (command.includes('SO')) {
        const setOrientation = command.replace('SO', '');
        printObj['setOrientation'] = setOrientation;
      }
      if (command.includes('SM')) {
        const setMargin = command
          .replace('SM', '')
          ?.split(',')
          ?.map((num) => Number(num));
        printObj['setMargin'] = setMargin;
      }
      if (command.includes('SL')) {
        const setLength = command
          .replace('SL', '')
          ?.split(',')
          ?.map((num, index) => (index < 2 ? Number(num) : num));
        printObj['setLength'] = setLength;
      }
      if (command.includes('SW')) {
        const setWidth = command.replace('SW', '');

        printObj['setWidth'] = Number(setWidth);
      }

      const originCommandStr = command?.split(',')?.map((command) => command.replaceAll("'", ''));
      let commandStr = [...originCommandStr];

      if (originCommandStr?.length > 3) {
        const TYPE = originCommandStr[0]?.slice(1, originCommandStr[0].length);
        const TEXT = originCommandStr[originCommandStr.length - 1];
        commandStr.pop();
        commandStr.splice(0, 1);

        if (originCommandStr[0]?.[0] === 'T') {
          commandStr.unshift(TYPE);
          commandStr.unshift(TEXT);
          printObj[`drawDeviceFont_${index}`] = commandStr;
        }

        if (originCommandStr[0]?.[0] === 'B') {
          if (originCommandStr[0]?.[1] === '1') {
            commandStr.unshift(TYPE.slice(1, TYPE?.length));
            commandStr.unshift(TEXT);
            commandStr.pop();
            printObj[`draw1DBarcode_${index}`] = commandStr;
          }

          if (originCommandStr[0]?.[1] === '2') {
            commandStr.unshift(TYPE.slice(1, TYPE?.length));
            commandStr.unshift(TEXT);
            commandStr.splice(3, 1);
            printObj[`drawQRCode_${index}`] = commandStr;
          }
        }
      }
    });

    executeKdexpLabelPrint(printObj, issueID);

    issueID++;
  }
};

const executeKdexpLabelPrint = (printObj, issueID) => {
  // console.log(printObj);

  setLabelId(issueID);
  clearBuffer();

  const printKeys = Object?.keys(printObj);

  for (let key of printKeys) {
    const data = printObj[key];
    key.includes('setSpeed') && setSpeed(data);
    key.includes('setDensity') && setDensity(data);
    key.includes('setOrientation') && setOrientation(data);
    key.includes('setMargin') && setMargin(...data);
    key.includes('setLength') && setLength(...data, 0);
    key.includes('setWidth') && setWidth(data);

    key.includes('drawDeviceFont') &&
      drawDeviceFont(
        data[0], //문자열
        calibratePosition(Number(data[1])), //x좌표
        Number(data[2]), //y좌표
        data[3], //폰트타입
        Number(data[4]), //수평 확대 1-4
        Number(data[5]), //수직 확대 1-4
        1, //Number(data[6]), //회전 - 0 : 안함 1: 90도 2: 180도 3: 270도
        0, //역상 사용 - 0: 미사용 1: 사용
        0, //굵은글씨 사용 - 0: 미사용 1: 사용
        0, //정렬 - 0: 왼쪽
      );
    key.includes('drawQRCode') &&
      drawQRCode(
        data[0], //문자열
        calibratePosition(Number(data[1])), //x좌표
        Number(data[2]), //y좌표
        data[3] === '2' ? 1 : 0, //model 1:0 or 2:1
        data[4], //eccLevl 에러레벨 교정값
        Number(data[5]), //바코드크기
        Number(data[6]), //회전값
      );
    key.includes('draw1DBarcode') &&
      draw1DBarcode(
        data[0], //문자열
        calibratePosition(Number(data[1])), //x좌표
        Number(data[2]), //y좌표
        Number(data[3]), //symbol - 바코드타입
        Number(data[4]), //narrow bar - 좁은 바의 너비
        Number(data[5]), //wide bar - 넓은 바의 너비
        Number(data[6]), //height - 바코드의 높이
        Number(data[7]), //rotation - 회전 - 0 : 안함 1: 90도 2: 180도 3: 270도
        Number(data[8]), //hri 폰트 크기 및 위치 - 0: 인쇄안함 1: 바코드아래쪽 & 폰트사이즈 1
      );
  }

  printBuffer();

  var strSubmit = getLabelData();

  console.log(strSubmit);

  requestPrint(BIXOLON_PRINTER_LOGICAL_NAME, strSubmit, (msg) => {
    if (msg) {
      console.log('result :', msg);
      if (msg === 'Cannot connect to server' || msg === 'No printers') {
        alert('프린터 연결을 확인해주세요!');
      }
      return msg;
    }
  });
};

export { executeKdexpLabelPrint, generateAndExcuteSubCommand };

const calibratePosition = (input) => {
  return input + 30;
};
