import { Fragment, ReactNode } from 'react';

// styled-component
import styled from 'styled-components';
import { FunctionDTO } from 'interface/user';

export const GridButtonArea = styled.div<{ justifyContent: 'normal' | 'flex-end' | 'space-between' }>(({ justifyContent }) => ({
  display: 'flex',
  overflow: 'auto',
  marginTop: 15,
  backgroundColor: 'white',
  justifyContent,
}));

const useGridButton = () => {
  const printFunctionToBtns = (functions: FunctionDTO[], gridButtonhandler: (e: any) => void, functionType: 'MAIN' | 'DETAIL' = 'MAIN'): ReactNode | ReactNode[] => {
    const groupKey = [];
    const doneGroup = [];
    const groupKeyNValue = {};
    functions?.forEach((ele) => {
      if (ele.functionGroup) {
        if (groupKey?.includes(ele.functionGroup)) {
          groupKeyNValue[ele.functionGroup].push(ele);
        } else {
          groupKey.push(ele.functionGroup);
          groupKeyNValue[ele.functionGroup] = [ele];
        }
      }
    });

    const btns: ReactNode = functions?.map((ele, idx) => {
      if (!ele.functionGroup) {
        return (
          <div
            key={`noGroup_${idx}`}
            id={ele.functionId}
            className={`${coloringButton(ele.color)} ${functionType}`}
            onDoubleClick={() => {
              return;
            }}
            onClick={gridButtonhandler}
          >
            {ele.functionName}
          </div>
        );
      } else {
        // functionGroup 있을때
        const isFirst = groupKeyNValue?.[ele.functionGroup][0]?.functionId;
        doneGroup.push(ele.functionGroup);
        return (
          <Fragment key={`inGroup_${idx}`}>
            {isFirst === ele.functionId && <div className={'justText'}>{ele.functionGroup}</div>}
            <div
              id={ele.functionId}
              className={`${coloringButton(ele.color)} ${functionType}`}
              onDoubleClick={() => {
                return;
              }}
              onClick={gridButtonhandler}
            >
              {/* TEMP */}
              {ele.functionName}
            </div>
          </Fragment>
        );
      }
    });
    return btns;
  };

  const coloringButton = (color: string = 'ORANGE') => {
    return color?.toLowerCase();
  };

  return { coloringButton, printFunctionToBtns };
};

export default useGridButton;
