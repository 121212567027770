import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { ProductSearchDTO } from 'interface/order';

export const SearchBox = (props: ISearchBoxProps<ProductSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SEQ, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">상품번호</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_SEQ
                    ? {
                        value: selectTypeObj?.SEARCH_SEQ,
                        label: MASTER_OBJ?.SEARCH_SEQ?.[selectTypeObj?.SEARCH_SEQ],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_SEQ}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_SEQ: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_SEQ}
                value={searchObj?.[selectTypeObj?.SEARCH_SEQ]}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_SEQ]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">제품대분류</label>
          <Row>
            <SelectD
              options={MASTER_OPS?.MODEL_GRP_1}
              value={
                searchObj?.modelGroupLarge
                  ? {
                      value: searchObj?.modelGroupLarge,
                      label: selectlabel(searchObj?.modelGroupLarge, MASTER_OPS?.MODEL_GRP_1),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  modelGroupLarge: (option as OptionItem).value,
                });
              }}
            />
          </Row>
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: selectlabel(searchObj?.partnerSeq, MASTER_OPS?.SELLER_WHOLE),
                  }
                : null
            }
            options={MASTER_OPS?.SELLER_SELLER_AUTH}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품/상품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL]}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">SKU</label>
          <InputD
            type="number"
            value={searchObj?.modelStockSeq}
            onChange={(e) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  modelStockSeq: Number(e.target.value) || null,
                };
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
