import { useState, useEffect } from 'react';
import { DetailTopTab, IDetailTopTab } from 'components/template/detailTopTab';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './purchaseRegit';
// options
import { InfraOptionReturn, centerListForlabeling, codeMasterOptionReturn, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { setLoading, setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';

const Index = () => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [tabObj, setTabObj] = useState<IDetailTopTab>();

  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);

  useEffect(() => {
    if (masterOptions) {
      setTabObj({
        titles: ['발주입고', '발주등록'],
        routers: ['/purchaseOrderAdmin', '/purchaseRegit'],
        container: [<Tab1 />],
      });
    }
  }, [masterOptions]);

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [CENTER_AUTH_WHOLE, CENTER_WHOLE, SELLER_AUTH_WHOLE, SELLER_WHOLE, SUPPLIER, INFRA, MODEL_GROUP, FEE_TYPE, LOGISTIC_TYPE, WORK_TYPE] = await Promise.all([
      centerListForlabeling({ selectFlag: true }),
      centerListForlabeling(),
      partnerListForlabeling({ sellerFlag: true }),
      partnerListForlabeling(),
      partnerListForlabeling({ supplierFlag: true }),
      InfraOptionReturn(),
      //
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('FEE_TYPE'),
      codeMasterOptionReturn('LOGISTIC_TYPE'),
      codeMasterOptionReturn('WORK_TYPE'),
    ]);

    dispatch(
      setMasterOptions({
        CENTER_WHOLE,
        CENTER_AUTH: CENTER_AUTH_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        SELLER_AUTH: SELLER_AUTH_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SUPPLIER: SUPPLIER?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        INFRA,
        FEE_TYPE,
        LOGISTIC_TYPE,
        MODEL_GROUP,
        WORK_TYPE,
        SEARCH_SEQ: [
          {
            label: '주문번호',
            value: 'orderSeq',
          },
          {
            label: '외부주문번호',
            value: 'externalOrderNumber',
          },
          {
            label: '송장번호',
            value: 'invoiceSeq',
          },
          {
            label: '외부송장번호',
            value: 'externalInvoiceNumber',
          },
          {
            label: '고객명',
            value: 'customerName',
          },
        ],
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'barcode', label: '바코드' },
          { label: 'SKU', value: 'modelStockSeq' },
          { label: '외부SKU', value: 'skuNumber' },
        ],
        SEARCH_LOC: [
          { value: 'PurchaseDate', label: '입고로케이션' },
          { value: 'RegisterDate', label: '출고로케이션' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };

  return <>{tabObj && <DetailTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
