import { ReactNode, useEffect, useState } from 'react';

import Tab1 from './barcodePrint';

// redux
import { useSelector, useDispatch } from 'react-redux';

// options
import { setLoading, setMasterOptions } from 'redux/services/menuSlice';
import { codeMasterOptionReturn, partnerListForlabeling, centerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { RootState } from 'redux/store';
import { TopTab } from 'components/template/topTabNew';
import { useLocation } from 'react-router-dom';
import { TabDTO } from 'interface/user';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Index = () => {
  const location = useLocation();
  const [tabs, setTabs] = useState<TabDTO[]>();
  const [tabItems, setTabItems] = useState<{ titles?: string[]; containers: ReactNode[] }>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { useableTabs } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [PARTNER_SELLER, CENTER_SELECT_WHOLE, LOGITYPE, MODEL_GROUP, MODEL_TYPE, LINEHAUL_TYPE, LINE_STAT, REMOVE_REASON, BRAND] = await Promise.all([
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling(),
      codeMasterOptionReturn('LOGI_TYPE'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
      codeMasterOptionReturn('LINEHAUL_TYPE'),
      codeMasterOptionReturn('LINE_STAT'),
      codeMasterOptionReturn('REMOVE_REASON'),
      codeMasterOptionReturn('BRAND'),
    ]);

    dispatch(
      setMasterOptions({
        SELLER: PARTNER_SELLER?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        LOGITYPE,
        MODEL_GROUP,
        MODEL_TYPE,
        LINEHAUL_TYPE,
        LINE_STAT,
        REMOVE_REASON,
        BRAND,
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
        ],
        SEARCH_BARCODE: [
          { value: 'barcode', label: '바코드' },
          { value: 'externalBarcode', label: 'LOT번호(외부바코드)' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };

  const containerList = [{ container: <Tab1 tabId={'BARCODEPRINT'} />, tabId: 'BARCODEPRINT' }];

  useEffect(() => {
    if (tabs) {
      const tabIds = tabs?.map((ele) => ele.tabId);
      const tabLabeling = tabs?.map((ele) => {
        return {
          value: ele.tabId,
          label: ele.tabName,
        };
      });

      setTabItems({
        titles: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => selectlabel(ele.tabId, tabLabeling)),
        containers: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => ele.container),
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (useableTabs) {
      const tabDTO = useableTabs?.[location.pathname] ? [...useableTabs?.[location.pathname]] : [];
      if (tabDTO?.length > 0) {
        tabDTO?.sort((a: TabDTO, b: TabDTO) => a?.sort - b?.sort);
        setTabs(tabDTO);
        initMasterOptions();
      }
    }
  }, [useableTabs]);

  return <>{masterOptions && tabItems && <TopTab titles={tabItems?.titles} containers={tabItems?.containers} />}</>;
};

export default Index;
