import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// redux
import { Button, Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import useDebounce from 'hooks/debounceHooks';

import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import GridBox, { defaultGridProps } from 'common/grid/gridBox';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';
import { InventoryModelDTO, LinehaulDetailDTOForCreateSecond } from 'interface/warehouse';

import * as IGrid from 'aui-grid';

import { IModelObj, ModelPopup } from './component/model';
import { useNavigate } from 'react-router-dom';

import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import { locationService } from 'pages/INVENTORY/3location/_services/service';
import AUIGrid from 'modules/Grid';
import { HaldangPopup, IHaldangObj } from './component/haldang';
import { _TRUNK_PICKING_REGIT_FORM } from 'envVar2';
import { generateUrlByDesignType } from 'components/LDS/utils/path';

const Index = () => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);

  const history = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();

  const defaultSearchFilter = useMemo(() => {
    return {
      originalCenterCode: reduxUserInfo['centerCode'], // 출고창고
      linehaulDate: returnDateyyyymmdd(new Date()), // 출고예정일
    };
  }, [reduxUserInfo]);

  const [postObj, setPostObj] = useState<LinehaulDetailDTOForCreateSecond>({ ...defaultSearchFilter, destinationCenterCode: '', models: [] });
  const [modelObj, setModelObj] = useState<IModelObj>();

  useEffect(() => {
    if (modelObj?.returns?.length > 0) {
      const rows = gridRef.current.getGridData();
      const added = [...rows, ...modelObj?.returns];
      const newRows = added?.map((row) => {
        return {
          ...row,
          centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
          modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
          partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
          supplierPartnerSeqKr: MASTER_OBJ?.SELLER_SUPPLIER_WHOLE?.[row?.supplierPartnerSeq],
        };
      });
      gridRef?.current?.setGridData(newRows);
      countSum(newRows);
      alert('추가되었습니다!');
      setModelObj((prev) => {
        return {
          ...prev,
          returns: [],
        };
      });
    }
  }, [modelObj?.returns]);

  const [count, setCount] = useState({
    total: 0,
    availAble: 0,
    unavailAble: 0,
  });

  useEffect(() => {
    console.log(count);
  }, [count]);

  const countSum = (rows = null) => {
    const items = rows ? rows : gridRef?.current?.getGridData();
    if (items?.length > 0) {
      const availAble = items
        .map((ele) => Number(ele.availableQuantity))
        .reduce((acc, cur) => {
          return acc + cur;
        });
      const unavailAble = items
        .map((ele) => Number(ele.defectedQuantity))
        .reduce((acc, cur) => {
          return acc + cur;
        });
      setCount({
        total: items?.length,
        availAble,
        unavailAble,
      });
    } else {
      setCount({
        total: 0,
        availAble: 0,
        unavailAble: 0,
      });
    }
  };
  const [haldangObj, setHaldangObj] = useState<IHaldangObj>();
  const columns: IGrid.Column[] = [
    {
      headerText: '할당정보',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '할당정보',
        visibleFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return item?.inventories?.length > 0;
        },
        onClick: (e) => {
          setHaldangObj({
            visible: true,
            items: e.item?.inventories,
          });
        },
      },
    },
    {
      headerText: '출고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
      ],
    },
    {
      headerText: '현재보관수량',
      children: [
        {
          headerText: '양품',
          dataField: 'totalAvailableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'totalDefectedQuantity',
        },
        {
          headerText: '이동가능양품',
          dataField: 'usableAvailableQuantity',
        },
        {
          headerText: '이동가능불용',
          dataField: 'usableDefectedQuantity',
        },
      ],
    },
    {
      headerText: '간선피킹대상수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          style: 'aui-textinputer',
          editable: true,
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            validator: (oldValue: any, newValue: any, item: any, dataField: string, fromClipboard: boolean, which: any) => {
              if (item?.inventories?.length > 0) return { validate: false, message: '주문연동 건은 수량을 수정할수 없습니다!' };
              if (item?.usableAvailableQuantity === 0) return { validate: false, message: '이동가능한 수량이 없습니다' };
              if (item?.usableAvailableQuantity < newValue) return { validate: false, message: '이동가능한 수량 초과' };
            },
            autoThousandSeparator: true,
            onlyNumeric: true,
            allowNegative: false,
          },
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          style: 'aui-textinputer',
          editable: true,
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            validator: (oldValue: any, newValue: any, item: any, dataField: string, fromClipboard: boolean, which: any) => {
              if (item?.inventories?.length > 0) return { validate: false, message: '주문연동 건은 수량을 수정할수 없습니다!' };
              if (item?.usableDefectedQuantity === 0) return { validate: false, message: '이동가능한 수량이 없습니다' };
              if (item?.usableDefectedQuantity < newValue) return { validate: false, message: '이동가능한 수량 초과' };
            },
            autoThousandSeparator: true,
            onlyNumeric: true,
            allowNegative: false,
          },
        },
      ],
    },
  ];
  const actionRegister = useCallback(
    useDebounce(async (dataDTO) => {
      dispatch(setLoading('POST'));

      const rs = await locationService.postWMSAction('linehaul/picking/v2', null, dataDTO);
      if (rs?.status === 200) {
        alert('간선피킹생성이 완료되었습니다!');
        history(generateUrlByDesignType('/trunkWorks'));
      }
      dispatch(setLoading(null));
    }, 500),
    [],
  );

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'UPLOAD_FORM') {
      window.open(_TRUNK_PICKING_REGIT_FORM);
    } else if (id === 'UPLOAD') {
      if (postObj?.originalCenterCode) {
        const items = gridRef?.current?.getGridData();
        if (items?.length > 0 && window.confirm('엑셀 업로드시 기존 항목들은 초기화됩니다. 진행하시겠습니까?')) {
          uploaderClick(id);
        } else {
          uploaderClick(id);
        }
      } else {
        alert('출고창고를 선택해주세요!');
      }
    } else if (id === 'GET_PICKED') {
      if (!postObj?.originalCenterCode) {
        alert('출고창고를 선택해주세요!');
      } else if (!postObj?.destinationCenterCode) {
        alert('입고창고를 선택해주세요!');
      } else {
        setModelObj({ visible: true, type: 'picked', postObj });
      }
    } else if (id === 'GET_MODEL') {
      if (postObj?.originalCenterCode) {
        setModelObj({ visible: true, type: 'model', postObj });
      } else {
        alert('출고창고를 선택해주세요!');
      }
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItems();
    if (items?.length > 0) {
      const wholeRows = gridRef.current.getGridData();
      if (id === 'DEL_ROW') {
        const checked = items.map((ele) => ele.rowIndex);
        const rows = wholeRows?.filter((_, idx) => !checked?.includes(idx));
        gridRef?.current?.setGridData(rows);

        countSum(rows);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const validation = (postObj) => {
    const items = gridRef?.current?.getGridData();
    if (!postObj?.originalCenterCode) {
      alert('기본정보에서 출고창고를 선택해주세요');
      return null;
    }
    if (!postObj?.destinationCenterCode) {
      alert('입고창고를 선택해주세요');
      return null;
    }
    if (!postObj?.linehaulDate) {
      alert('출고예정일을 선택해주세요');
      return null;
    }

    const noQuantity = items?.filter((ele) => !ele.availableQuantity && !ele.defectedQuantity);
    if (noQuantity?.length > 0) {
      alert('수량이 입력되지 않은 건이 있습니다');
      return null;
    }

    const dataDTO = {
      ...postObj,
      models: items?.map((row) => {
        return {
          ...row,
          locationCode: row.locationCode === '자동생성' ? '' : row.locationCode,
          inventorySeqs: row.inventories?.map((ele) => ele.inventorySeq),
        };
      }),
    };

    return dataDTO;
  };
  const uploadData = async (excelData) => {
    if (excelData?.filter((ele) => ele.centerCode !== postObj?.originalCenterCode)?.length > 0) {
      alert('출고창고와 다른 창고의 제품을 입력한 건이 있습니다. 엑셀파일을 확인해주세요!');
    } else {
      dispatch(setLoading('GET'));
      const dataDTO = {
        centerCode: postObj?.originalCenterCode,
        models: excelData?.map((row) => {
          return {
            barcode: row.barcode,
            model: row.model,
            partnerSeq: row.partnerSeq,
          };
        }),
      };
      const rs = await locationService?.postWMSAction('inventory/v2/picking/model/list', null, dataDTO);
      if (rs?.status === 200) {
        const rows = (rs?.data as InventoryModelDTO[])?.map((row, i) => {
          return {
            ...row,
            centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
            partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
            modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
            usableAvailableQuantity: row?.availableQuantity || 0,
            usableDefectedQuantity: row?.defectedQuantity || 0,
            availableQuantity: 0,
            defectedQuantity: 0,
          };
        });
        gridRef.current.setGridData(rows);
      }
      dispatch(setLoading(null));
    }
  };

  const KRTOVALUE = {
    centerCode: 'CENTER',
    partnerSeq: 'SELLER',
  };

  const EXCELHEADER = {
    UPLOAD: [
      'centerCode',
      'locationCode',
      'modelGroup',
      'model',
      'barcode',
      'modelStockSeq',
      'skuNumber',
      'partnerSeq',
      'totalAvailableQuantity',
      'totalDefectedQuantity',
      'usableAvailableQuantity',
      'usableDefectedQuantity',
      'importAvailableQuantity',
      'importDefectedQuantity',
      'sumAvailableQuantity',
      'sumDefectedQuantity',
    ],
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE });
  useEffect(() => {
    if (parsedData?.data?.length > 0) {
      uploadData(parsedData?.data);
    }
  }, [parsedData]);

  return (
    <>
      <div className="page-content">
        {haldangObj?.visible && <HaldangPopup haldangObj={haldangObj} setHaldangObj={setHaldangObj} />}
        {modelObj?.visible && <ModelPopup modelObj={modelObj} setModelObj={setModelObj} />}
        <Row style={{ textAlign: 'right' }}>
          <Col>
            <Button
              className="btn-blue btn"
              onClick={() => {
                if (window.confirm('간선피킹생성을 진행하시겠습니까?')) {
                  const dataDTO = validation(postObj);
                  if (dataDTO) actionRegister(dataDTO);
                }
              }}
            >
              간선피킹 생성
            </Button>
          </Col>
        </Row>
        <div className="presenterInput">
          <p>1. 기본정보</p>
          <Row style={{ marginLeft: 10 }}>
            <Col>
              <label className="col-form-label">*출고 예정일</label>
              <FlatpickrD
                value={postObj?.linehaulDate || ''}
                onChange={(value) => {
                  setPostObj({
                    ...postObj,
                    linehaulDate: returnDateyyyymmdd(value[0]),
                  });
                }}
                options={{
                  mode: 'single',
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*출고창고</label>
              <SelectD
                value={
                  postObj?.originalCenterCode
                    ? {
                        value: postObj?.originalCenterCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[postObj?.originalCenterCode],
                      }
                    : null
                }
                options={MASTER_OPS?.CENTER_AUTH}
                onChange={(option) => {
                  setPostObj({
                    ...postObj,
                    originalCenterCode: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*입고창고</label>
              <SelectD
                value={
                  postObj?.destinationCenterCode
                    ? {
                        value: postObj?.destinationCenterCode,
                        label: MASTER_OBJ?.CENTER_DIFF?.[postObj?.destinationCenterCode],
                      }
                    : null
                }
                options={MASTER_OPS?.CENTER_DIFF}
                onChange={(option) => {
                  setPostObj({
                    ...postObj,
                    destinationCenterCode: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col />
            <Col />
            <Col />
          </Row>
        </div>
        <div>
          <div className="presenterInput">
            <div className="presenterGridBox">
              <p>2. 상세정보</p>
              <div className="grid-button-area space-between">
                <div>
                  <div id={`DEL_ROW`} className={`red`} onClick={gridButtonhandler}>
                    선택행 삭제
                  </div>
                  <div id={`GET_PICKED`} className={`orange`} onClick={gridButtonhandler}>
                    피킹지시 불러오기
                  </div>
                  <div id={`GET_MODEL`} className={`orange`} onClick={gridButtonhandler}>
                    + 안전재고 제품추가
                  </div>
                  <div className={`transparent`}>엑셀 업로드</div>
                  <div id={`UPLOAD_FORM`} className={`green`} onClick={gridButtonhandler}>
                    양식
                  </div>
                  <div id={`UPLOAD`} className={`green`} onClick={gridButtonhandler}>
                    <ExcelDetector />
                    +등록
                  </div>
                </div>
                <div className="notBtn">
                  <div>
                    제품 수량 :&nbsp; <span>총 &nbsp;{count?.total}</span>개
                  </div>
                  <div>
                    양품 수량 :&nbsp; <span className="blue">{count?.availAble}</span>개
                  </div>
                  <div>
                    불용 수량 :&nbsp; <span className="red">{count?.unavailAble}</span>개
                  </div>
                </div>
              </div>
              <GridBox
                gridRef={gridRef}
                columns={columns}
                gridProps={{
                  showRowAllCheckBox: true,
                  showRowCheckColumn: true,
                  editable: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
