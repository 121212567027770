import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { InvoiceUpdateHistoryDTO } from '../../../_interface/order';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';

export const History = (props) => {
  // options
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { invoiceDetail, visible, setVisible } = props;
  const [rawHistoryList, setRawHistoryList] = useState<InvoiceUpdateHistoryDTO[]>();
  const [historyList, setHistoryList] = useState<InvoiceUpdateHistoryDTO[]>();
  const [search, setSearch] = useState<string>();
  const [type, setType] = useState<string>();
  useEffect(() => {
    fetchList();
  }, [invoiceDetail]);

  const searchClick = (search: string, type: string) => {
    if (!(!search && !type)) {
      const filtered = rawHistoryList?.filter((ele) => ele?.content === type && (ele.registerId?.includes(search) || ele.beforeContent?.includes(search) || ele.afterContent?.includes(search)));
      setHistoryList(filtered);
    } else {
      setHistoryList(rawHistoryList);
    }
  };

  const fetchList = async () => {
    const rs = await serviceStore?.orderAction(`detail/update/history`, 'GET', { orderSeq: invoiceDetail?.orderSeq, invoiceSeq: invoiceDetail?.invoiceSeq, deliverySeq: invoiceDetail?.deliverySeq });
    if (rs?.data) {
      setHistoryList(rs?.data);
      setRawHistoryList(rs?.data);
      setSearch('');
    }
  };

  return (
    <CommonModalNew
      title="변경이력조회"
      style={{ width: 800 }}
      setVisible={setVisible}
      visible={visible}
      children={
        <>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
            <span style={{ padding: '0 10px', fontWeight: 500 }}>변경사항</span>
            <span style={{ width: '15%' }}>
              <SelectD
                options={masterOptions?.INVOICE_UPDATE_CONTENT}
                value={type ? { value: type, label: selectlabel(type, masterOptions?.INVOICE_UPDATE_CONTENT) } : null}
                onChange={(option) => {
                  setType((option as OptionItem).value);
                }}
              />
            </span>
            <span style={{ padding: '0 10px', fontWeight: 500 }}>내용</span>
            <span style={{ display: 'inline-block', width: '40%' }}>
              <InputD
                value={search || ''}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </span>
            <span>
              <button
                className="btn-search btn ssm"
                style={{ marginTop: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  searchClick(search, type);
                }}
              >
                조회
              </button>
            </span>
            {/* </Form> */}
          </div>
          <table className="border-table">
            <thead>
              {historyList?.length === 0 && (
                <tr>
                  <th>변경 된 이력이 없습니다</th>
                </tr>
              )}
              {historyList?.map((c = {}, index) => {
                return (
                  <React.Fragment key={`history_${index}`}>
                    <tr key={`historyList_${index}`}>
                      <th>
                        <span className="badge">No. {historyList?.length - index}</span>
                      </th>
                      <th>변경사항</th>
                      <th colSpan={2}>
                        <InputD disabled readOnly value={selectlabel(c?.content, masterOptions?.INVOICE_UPDATE_CONTENT)} />
                      </th>
                      <th>변경아이디</th>
                      <th colSpan={2}>
                        <InputD disabled readOnly value={c?.registerId} />
                      </th>
                      <th>변경일시</th>
                      <th colSpan={2}>
                        <InputD disabled readOnly value={c?.registerDatetime} />
                      </th>
                    </tr>
                    <tr>
                      <th>전</th>
                      <td colSpan={4}>
                        <textarea disabled readOnly value={c?.beforeContent} />
                      </td>
                      <th>후</th>
                      <td colSpan={4}>
                        <textarea disabled readOnly value={c?.afterContent} />
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </thead>
          </table>
        </>
      }
    />
  );
};
