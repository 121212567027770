import { useEffect, useState, useRef } from 'react';

import CommonModalNew from 'components/modal/commonModalNew';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';

import Barcode from 'react-barcode';
import { maskingReceiverName } from 'common/util/counting';
import { SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { DROPSHIPPING_INVOICE_PRINT } from 'envVar';
import { httpClient } from 'common/http-client/axiosConfig';
import { DropshippingPrintDTO } from 'interface/order';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';

const PrintBarcode116150 = styled.div({
  position: 'relative',
  padding: 20,
  height: '116mm',
  width: '150mm',
  border: '1px solid #d0d0d0',
  fontSize: 15,
  fontWeight: 800,
  marginBottom: 10,
});

const TitleText = styled.span({
  display: 'inline-block',
  width: '22mm',
});

const BoldText = styled.span({
  fontWeight: 700,
});

const IPrintOp = [
  { value: 'DEFAULT', label: '기본순' },
  { value: 'BARCODE', label: '제품코드순' },
  { value: 'DOCK_NUMBER', label: '도크넘버순' },
];

export interface IDropshippingPrint {
  visible?: boolean;
  items?: any[];
}

export const Prints = ({ printObj, setPrintObj }) => {
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState<DropshippingPrintDTO[]>();
  //
  const [orderBy, setOrderBy] = useState<'DEFAULT' | 'BARCODE' | 'DOCK_NUMBER'>();
  const wrapperRef = useRef();
  useEffect(() => {
    if (printObj?.items) {
      setOrderBy('DEFAULT');
    }
  }, []);

  useEffect(() => {
    if (orderBy) {
      printAll(printObj?.items, orderBy);
    }
  }, [orderBy]);

  const printAll = async (items, orderBy) => {
    dispatch(setLoading('GET'));
    const rs = await httpClient.post(DROPSHIPPING_INVOICE_PRINT, {
      invoices: items?.map((ele) => {
        return {
          invoiceSeq: ele.invoiceSeq,
          inventorySeq: ele.inventorySeq,
        };
      }),
      orderBy: orderBy !== 'DEFAULT' ? orderBy : null,
    });
    if (rs?.status === 200) {
      setInvoices(rs?.data);
    }
    dispatch(setLoading(null));
  };

  return (
    <>
      <CommonModalNew
        style={{ width: 610 }}
        visible={printObj?.visible}
        setVisible={() => setPrintObj(null)}
        title={'직배송장 출력'}
        rightTitle={
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 120 }}>
                <SelectD
                  hasNull={false}
                  value={orderBy ? { value: orderBy, label: selectlabel(orderBy, IPrintOp) } : null}
                  options={IPrintOp}
                  onChange={(option) => {
                    setOrderBy((option as OptionItem)?.value);
                  }}
                />
              </div>
              <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
            </div>
          </>
        }
        children={
          <>
            <div ref={wrapperRef}>
              {invoices?.map((ele, idx) => {
                return (
                  <PrintBarcode116150 key={`print_${idx}`} className="break-for-onePage-print">
                    <div>
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '60%' }}>
                          <div>
                            <TitleText>고 객 명</TitleText>
                            <BoldText>{maskingReceiverName(ele?.receiverName)}</BoldText>
                          </div>
                          <div>
                            <TitleText>배송예정일</TitleText>
                            <BoldText>{ele?.deliveryDate}</BoldText>
                          </div>
                          <div>
                            <TitleText>주문번호</TitleText>
                            <BoldText>{ele?.orderSeq}</BoldText>
                          </div>
                          <div>
                            <TitleText>주 소</TitleText>
                            <BoldText>
                              {ele?.province || ''}&nbsp;
                              {ele?.city || ''}
                            </BoldText>
                          </div>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <div style={{ position: 'absolute', top: -15, right: 10 }}>
                            {ele?.invoiceSeq && <Barcode width={1.5} value={ele?.invoiceSeq + '' || ''} displayValue={false} height={40} />}
                          </div>
                          <div style={{ height: 45 }}></div>
                          <div>
                            <TitleText>송장번호</TitleText>
                            <BoldText>{ele?.invoiceSeq}</BoldText>
                          </div>
                          <div>
                            <TitleText>배송기사</TitleText>
                            <BoldText>{ele?.driverName}</BoldText>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '70%' }}>
                          <div>
                            <TitleText>제품</TitleText>
                          </div>
                          <div>
                            <BoldText>{ele?.model}</BoldText>
                          </div>
                        </div>
                        <div>
                          <BoldText style={{ fontSize: 35, textAlign: 'right' }}>
                            {ele.modelIndex} / {ele.modelQuantity}
                          </BoldText>
                        </div>
                      </div>
                      <div>
                        <div style={{ marginTop: 10 }}>
                          <TitleText>제품명</TitleText>
                        </div>
                        <div style={{ minHeight: 40, maxHeight: 60, fontSize: 20 }}>
                          <BoldText style={{ display: 'block' }}>{ele?.modelName}</BoldText>
                        </div>
                      </div>
                      <hr />
                      <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: '35%' }}>
                          <div>
                            <span>도크NO-시프트</span>
                          </div>
                          <div>
                            <BoldText style={{ fontSize: 30 }}>{ele?.dockNumber}</BoldText>
                          </div>
                        </div>
                        <div>
                          <div>
                            <TitleText>권역정보</TitleText>
                          </div>
                          <div>
                            <BoldText style={{ fontSize: 45 }}>{ele.zipcodeGroupInfo}</BoldText>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PrintBarcode116150>
                );
              })}
            </div>
          </>
        }
      />
    </>
  );
};
