import { handleFile, uploadFile } from 'common/util/photo';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { WarehouseDTO, WarehouseSearchDTO } from 'interface/warehouse';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Label } from 'reactstrap';
import { RootState } from 'redux/store';
import CloseImg from 'assets/images/close/cross.png';
import { OptionItem} from 'common/master/codeMasterReturnHelper';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';
import { defaultGridProps } from 'common/grid/gridBox';
import { defaultCenterObj } from 'pages/ETC/2center/_asset/asset';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';

export interface IDetailObj {
  visible: boolean;
  type: string;
  item?: any;
  fetchFn?: (data: WarehouseSearchDTO) => {};
}

export const DetailPopup = ({ detailObj, setDetailObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const [centerObj, setCenterObj] = useState<WarehouseDTO>();
  const gridRef = useRef<AUIGrid[]>([]);
  useEffect(() => {
    if (detailObj?.type === 'detail') {
      fetchDetail(detailObj?.item?.centerCode);
    } else {
      setCenterObj(defaultCenterObj);
    }
  }, []);
  const did = useRef(false);

  useEffect(() => {
    if (gridRef.current && centerObj) {
      if (!did.current) {
        did.current = true;
        gridRef.current?.forEach((ref, idx) => {
          if (idx < 3) {
            (ref as AUIGrid)?.setGridData(centerObj?.[`extraWorkdayFor${pickType[idx]}`]);
          } else {
            const infraDetails = centerObj?.infraDetails?.map((row) => {
              return {
                ...row,
                infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
                centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
                partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
                modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row.modelGroup],
                parentCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.parentCenterCode],
              };
            });

            (ref as AUIGrid)?.setGridData(infraDetails);
          }
        });
      }
    }
  }, [gridRef.current, centerObj]);

  const pickTypeKr = ['간선피킹', '반출피킹', 'FC피킹'];
  const pickType = ['LinePicking', 'RemovePicking', 'FcPicking'];
  const flagsArr = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'extraWorkday'];
  const fetchDetail = async (centerCode: string) => {
    const rs = await serviceStore.warehouseAction(`${centerCode}`, 'GET', null);
    if (rs?.status === 200) {
      setCenterObj(rs.data);
    }
  };

  const handleFileRemove = (e) => {
    setCenterObj({
      ...centerObj,
      sealImage: null,
    });
  };

  const handleFileUpload = async (e) => {
    const ret = await handleFile(e);
    const uploadUrl = await uploadFile(ret.file, ret.url);
    setCenterObj({
      ...centerObj,
      sealImage: uploadUrl,
    });
  };

  const handleDaumPost = () => {
    new (window as any).daum.Postcode({
      oncomplete: function (data) {
        const { roadAddress, zonecode, jibunAddress } = data;
        console.log(data);
        setCenterObj((prev) => {
          return {
            ...prev,
            address: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
            zipcode: zonecode,
          };
        });
      },
    }).open();
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '*추가근무일',
      dataField: 'date',
      editRenderer: {
        type: IGrid.EditRendererKind.CalendarRenderer,
        defaultFormat: 'yyyy-mm-dd',
      },
    },
  ];

  const infraColumns: IGrid.Column[] = [
    {
      headerText: '*인프라',
      dataField: 'infraSeqKr',
      editRenderer: {
        type: IGrid.EditRendererKind.ComboBoxRenderer,
        showEditorBtnOver: true,
        autoCompleteMode: true,
        autoEasyMode: true,
        matchFromFirst: false,
        list: MASTER_OPS?.INFRA,
        keyField: 'label',
        valueField: 'label',
      },
    },
    {
      headerText: '*창고',
      dataField: 'centerCodeKr',
      editable: false,
      editRenderer: {
        type: IGrid.EditRendererKind.ComboBoxRenderer,
        showEditorBtnOver: true,
        autoCompleteMode: true,
        autoEasyMode: true,
        matchFromFirst: false,
        list: MASTER_OPS?.CENTER_AUTH,
        keyField: 'label',
        valueField: 'label',
      },
    },
    {
      headerText: '*판매사',
      dataField: 'partnerSeqKr',
      editRenderer: {
        type: IGrid.EditRendererKind.ComboBoxRenderer,
        showEditorBtnOver: true,
        autoCompleteMode: true,
        autoEasyMode: true,
        matchFromFirst: false,
        list: MASTER_OPS?.SELLER_SELLER_AUTH,
        keyField: 'label',
        valueField: 'label',
      },
    },
    {
      headerText: '*제품그룹',
      dataField: 'modelGroupKr',
      editRenderer: {
        type: IGrid.EditRendererKind.ComboBoxRenderer,
        showEditorBtnOver: true,
        autoCompleteMode: true,
        autoEasyMode: true,
        matchFromFirst: false,
        list: MASTER_OPS?.MODEL_GROUP,
        keyField: 'label',
        valueField: 'label',
      },
    },
    {
      headerText: '상위창고',
      dataField: 'parentCenterCodeKr',
      editRenderer: {
        type: IGrid.EditRendererKind.ComboBoxRenderer,
        showEditorBtnOver: true,
        autoCompleteMode: true,
        autoEasyMode: true,
        matchFromFirst: false,
        list: MASTER_OPS?.CENTER_AUTH,
        keyField: 'label',
        valueField: 'label',
      },
    },
  ];

  const validationForSave = (data: WarehouseDTO) => {
    if (!data?.centerType) {
      alert('창고타입은 필수 입력값입니다');
      return false;
    }
    if (!data?.centerName) {
      alert('창고명은 필수 입력값입니다');
      return false;
    }
    if (!data?.address) {
      alert('주소는 필수 입력값입니다');
      return false;
    }
    if (!data?.partnerSeq) {
      alert('위탁사는 필수 입력값입니다');
      return false;
    }
    return true;
  };

  const CRUDcenter = async () => {
    const type = detailObj?.type == 'detail' ? 'update' : 'add';
    if (validationForSave(centerObj)) {
      const infraDetails = gridRef?.current[3]?.getGridData().map((row) => {
        return {
          ...row,
          partnerSeq: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeqKr],
          infraSeq: MASTER_OBJ?.INFRA?.[row.infraSeqKr],
          modelGroup: MASTER_OBJ?.MODEL_GROUP?.[row.modelGroupKr],
          centerCode: centerObj?.centerCode,
          parentCenterCode: MASTER_OBJ?.CENTER_WHOLE?.[row.parentCenterCodeKr],
        };
      });
      const dataObj = {};
      const keys = Object.keys(centerObj);
      keys.forEach((key) => {
        if (key?.includes('FlagFor')) {
          // 월화수목금추가근무일까지
          dataObj[key] = centerObj[key] ? centerObj[key] : false;
        } else {
          if (centerObj[key]) dataObj[key] = centerObj[key];
        }
      });

      dataObj['extraWorkdayForFcPicking'] = gridRef.current?.[0]?.getGridData() || [];
      dataObj['extraWorkdayForLinePicking'] = gridRef.current?.[1]?.getGridData() || [];
      dataObj['extraWorkdayForRemovePicking'] = gridRef.current?.[2]?.getGridData() || [];
      dataObj['infraDetails'] = infraDetails;
      const rs = await serviceStore.warehouseAction(`save`, 'POST', null, { [type]: [dataObj] });
      if (rs?.status === 200) {
        alert(`창고가 ${type === 'add' ? '등록' : '수정'}되었습니다!`);
        if (detailObj?.fetchFn && detailObj?.item?.searchObj) detailObj?.fetchFn(JSON.parse(detailObj?.item?.searchObj));
        setDetailObj(null);
      }
    }
  };
  const hasTabs = [{ title: '기본정보' }, { title: '운영정보' }, { title: '인프라정보' }];
  const [currentTab, setCurrentTab] = useState(0);

  const gridButtonhandler = (e) => {
    if (centerObj?.centerCode && centerObj?.centerName) {
      const id = e.target.id;
      if (id?.includes('addRow')) {
        const idx = id.split('addRow_')[1];
        if (idx < 3) {
          gridRef.current[idx]?.addRow({});
        } else {
          gridRef.current[idx]?.addRow({
            centerCodeKr: detailObj?.type === 'detail' ? MASTER_OBJ?.CENTER_WHOLE?.[centerObj?.centerCode] : centerObj?.centerName,
          });
        }
      } else if (id?.includes('deleteRow')) {
        const idx = id.split('deleteRow_')[1];
        const idxs = gridRef.current[idx]?.getCheckedRowItems().map((ele) => ele.rowIndex);
        if (idxs?.length > 0) {
          const rows = gridRef.current[idx]?.getGridData();
          const newRows = rows.filter((_, i) => !idxs.includes(i));
          gridRef.current[idx]?.setGridData(newRows);
        } else {
          alert('선택된 것이 없습니다');
        }
      }
    } else {
      alert('창고명과 창고코드 입력후에 인프라 추가가 가능합니다!');
    }
  };

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    setCenterObj((prev) => {
      return {
        ...prev,
        [id]: e.target.checked,
      };
    });
  };

  return (
    <CommonModalNew
      style={{ width: 1000 }}
      title={detailObj?.type === 'detail' ? `창고 상세` : '창고 등록'}
      visible={detailObj?.visible}
      rightTitle={
        <>
          <Button className="btn-secondary btn ssm" onClick={CRUDcenter}>
            {detailObj?.type === 'detail' ? '수정' : '등록'}
          </Button>
        </>
      }
      setVisible={() => {
        setDetailObj((prev) => {
          return {
            ...prev,
            visible: false,
            type: null,
            data: null,
          };
        });
      }}
      children={
        <div style={{ minHeight: 780 }}>
          <div className="div-top-tab">
            {hasTabs?.slice(0, reduxUserInfo?.sub?.includes('LD') ? 3 : 2)?.map((tab, index) => {
              return (
                <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                  <li
                    onClick={() => {
                      setCurrentTab(index);
                    }}
                  >
                    {tab?.title}
                  </li>
                </div>
              );
            })}
          </div>
          <div style={{ display: 'flex', zIndex: currentTab === 0 ? 13 : 10, height: 750 }}>
            <div style={{ display: 'inline-flex', alignItems: 'center', flexDirection: 'column', width: 140, height: 200, marginTop: 30, marginRight: 30 }}>
              {centerObj?.sealImage ? (
                <>
                  <img
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      width: 13,
                      top: 125,
                      left: 210,
                    }}
                    src={CloseImg}
                    alt=""
                    onClick={handleFileRemove}
                    className="closeBtn"
                  />
                  <div style={{ height: 170, width: 170, border: '1px solid #d5d5d5', overflow: 'hidden', borderRadius: 100, marginBottom: 10 }}>
                    <img style={{ width: 170 }} src={centerObj?.sealImage} alt="" />
                  </div>
                </>
              ) : (
                <>
                  <div style={{ height: 150, width: 150, marginLeft: 10, border: '1px solid #d5d5d5', borderRadius: 75, marginBottom: 10 }} />
                  <InputD style={{ display: 'none' }} type="file" accept="image/*" id={`sealImage`} onChange={handleFileUpload} />
                  <Button>
                    <Label for={`sealImage`} style={{ marginBottom: 0 }}>
                      직인 파일 추가
                    </Label>
                  </Button>
                </>
              )}
            </div>

            <div style={{ width: '100%' }}>
              <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
                <thead>
                  <tr>
                    <th colSpan={12}>1. 기본정보</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>*창고상태</th>
                    <td colSpan={2}>
                      <SelectD
                        hasNull={false}
                        options={MASTER_OPS?.USE_FLAG}
                        value={
                          centerObj?.useFlag + ''
                            ? {
                                value: centerObj?.useFlag + '',
                                label: MASTER_OBJ?.USE_FLAG?.[centerObj?.useFlag + ''],
                              }
                            : null
                        }
                        onChange={({ value }) => {
                          setCenterObj({
                            ...centerObj,
                            useFlag: value,
                          });
                        }}
                      />
                    </td>
                    <th>*창고타입</th>
                    <td colSpan={2}>
                      <SelectD
                        hasNull={false}
                        options={MASTER_OPS?.WH_TYPE}
                        value={
                          centerObj?.centerType
                            ? {
                                value: centerObj?.centerType,
                                label: MASTER_OBJ?.WH_TYPE?.[centerObj?.centerType],
                              }
                            : null
                        }
                        onChange={(option) => {
                          setCenterObj({
                            ...centerObj,
                            centerType: (option as OptionItem)?.value,
                          });
                        }}
                      />
                    </td>
                    <th>*창고코드</th>
                    <td colSpan={2}>
                      <InputD
                        disabled={detailObj?.type !== 'create'}
                        value={centerObj?.centerCode || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            centerCode: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>
                      외부
                      <br />
                      창고코드
                    </th>
                    <td colSpan={2}>
                      <InputD
                        value={centerObj?.externalCenterCode || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            externalCenterCode: e.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>*창고명</th>
                    <td colSpan={2}>
                      <InputD
                        value={centerObj?.centerName || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            centerName: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>창고 영문명</th>
                    <td colSpan={2}>
                      <InputD
                        value={centerObj?.centerNameEn || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            centerNameEn: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>*위탁사</th>
                    <td colSpan={2}>
                      <SelectD
                        options={MASTER_OPS?.SELLER_NETWORK_AUTH}
                        value={
                          centerObj?.partnerSeq
                            ? {
                                value: centerObj?.partnerSeq,
                                label: MASTER_OBJ?.SELLER_NETWORK_AUTH?.[centerObj?.partnerSeq],
                              }
                            : null
                        }
                        onChange={(option) => {
                          setCenterObj({
                            ...centerObj,
                            partnerSeq: (option as OptionItem).value,
                          });
                        }}
                      />
                    </td>
                    <td colSpan={3} />
                  </tr>
                  <tr>
                    <th>우편번호</th>
                    <td colSpan={2} onClick={() => handleDaumPost()} style={{ cursor: 'pointer' }}>
                      <InputD
                        className="read-only-should-click"
                        value={centerObj?.zipcode || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            zipcode: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>*주소</th>
                    <td colSpan={3}>
                      <InputD value={centerObj?.address || ''} disabled={true} placeholder="우편번호 검색시 자동기입" />
                    </td>
                    <td colSpan={5}>
                      <InputD
                        value={centerObj?.addressDetail || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            addressDetail: e.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>등록일</th>
                    <td colSpan={2}>
                      <InputD readOnly disabled value={centerObj?.registerDatetime || ''} />
                    </td>
                    <th>등록자</th>
                    <td colSpan={2}>
                      <InputD readOnly disabled value={centerObj?.registerId || ''} />
                    </td>
                    <th>수정일</th>
                    <td colSpan={2}>
                      <InputD readOnly disabled value={centerObj?.updateDatetime || ''} />
                    </td>
                    <th>수정자</th>
                    <td colSpan={2}>
                      <InputD readOnly disabled value={centerObj?.updateId || ''} />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
                <thead>
                  <tr>
                    <th colSpan={12}>2. 건물정보</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      *적재가능
                      <br />
                      높이(m)
                    </th>
                    <td>
                      <InputD
                        type="number"
                        value={centerObj?.loadingHeight || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            loadingHeight: Number(e.target.value),
                          });
                        }}
                      />
                    </td>
                    <th>
                      *적재가능
                      <br />
                      면적(m²)
                    </th>
                    <td>
                      <InputD
                        type="number"
                        value={centerObj?.loadingArea || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            loadingArea: Number(e.target.value),
                          });
                        }}
                      />
                    </td>
                    <th>*피킹면적(m²)</th>
                    <td>
                      <InputD
                        type="number"
                        value={centerObj?.pickingArea || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            pickingArea: Number(e.target.value),
                          });
                        }}
                      />
                    </td>
                    <th>
                      *적재가능
                      <br />셀 수
                    </th>
                    <td>
                      <InputD
                        type="number"
                        value={centerObj?.loadingCell || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            loadingCell: Number(e.target.value),
                          });
                        }}
                      />
                    </td>
                    <th>*창고총면적(m²)</th>
                    <td>
                      <InputD
                        type="number"
                        value={centerObj?.centerArea || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            centerArea: Number(e.target.value),
                          });
                        }}
                      />
                    </td>
                    <td colSpan={2} />
                  </tr>
                </tbody>
              </table>

              <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
                <thead>
                  <tr>
                    <th colSpan={12}>3. 컨텍정보</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>*창고장명</th>
                    <td colSpan={2}>
                      <InputD
                        value={centerObj?.managerName || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            managerName: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>*연락처</th>
                    <td colSpan={2}>
                      <InputD
                        value={centerObj?.managerPhone || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            managerPhone: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>이메일</th>
                    <td colSpan={3}>
                      <InputD
                        value={centerObj?.managerEmail || ''}
                        onChange={(e) => {
                          setCenterObj({
                            ...centerObj,
                            managerEmail: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th></th>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ display: 'flex', zIndex: currentTab === 1 ? 13 : 10, height: 750, flexDirection: 'column' }}>
            <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
              <thead>
                <tr>
                  <th colSpan={13}>*운영정보</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style={{ backgroundColor: '#eff2f7' }}>컷오프유형</th>
                  <th  colSpan={2}>컷오프 적용</th>
                  <th colSpan={2}>컷오프시간</th>
                  <th>월요일</th>
                  <th>화요일</th>
                  <th>수요일</th>
                  <th>목요일</th>
                  <th>금요일</th>
                  <th>토요일</th>
                  <th>일요일</th>
                  <th>추가근무일</th>
                </tr>
                {pickType?.map((ele, tIdx) => {
                  if (tIdx === 2 && centerObj?.centerType !== 'FC') return <Fragment key={`${ele}_${tIdx}0`}></Fragment>;
                  else
                    return (
                      <tr key={`${ele}_tr`}>
                        <th style={{ backgroundColor: '#eff2f7' }}>{pickTypeKr[tIdx]}</th>
                        <th  colSpan={2}>
                          <input type="checkbox" id={`cutoffFlagFor${pickType[tIdx]}`} checked={centerObj?.[`cutoffFlagFor${pickType[tIdx]}`] === true} onChange={handleCheckChange} />
                        </th>
                        <th colSpan={2} style={{ padding: '0 10px' }}>
                          <SelectD
                            hasNull={false}
                            isDisabled={!centerObj?.[`cutoffFlagFor${pickType[tIdx]}`]}
                            options={MASTER_OPS?.DELIVERY_TIME}
                            value={
                              centerObj?.[`cutoffTimeFor${pickType[tIdx]}`]
                                ? {
                                    value: centerObj?.[`cutoffTimeFor${pickType[tIdx]}`],
                                    label: MASTER_OBJ?.DELIVERY_TIME?.[centerObj?.[`cutoffTimeFor${pickType[tIdx]}`]],
                                  }
                                : null
                            }
                            onChange={(option) => {
                              setCenterObj((prev) => {
                                return {
                                  ...prev,
                                  [`cutoffTimeFor${pickType[tIdx]}`]: (option as OptionItem).value,
                                };
                              });
                            }}
                          />
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            id={`${flagsArr[0]}FlagFor${pickType[tIdx]}`}
                            disabled={!centerObj?.[`cutoffFlagFor${pickType[tIdx]}`]}
                            checked={centerObj?.[`${flagsArr[0]}FlagFor${pickType[tIdx]}`] === true}
                            onChange={handleCheckChange}
                          />
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            id={`${flagsArr[1]}FlagFor${pickType[tIdx]}`}
                            disabled={!centerObj?.[`cutoffFlagFor${pickType[tIdx]}`]}
                            checked={centerObj?.[`${flagsArr[1]}FlagFor${pickType[tIdx]}`] === true}
                            onChange={handleCheckChange}
                          />
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            id={`${flagsArr[2]}FlagFor${pickType[tIdx]}`}
                            disabled={!centerObj?.[`cutoffFlagFor${pickType[tIdx]}`]}
                            checked={centerObj?.[`${flagsArr[2]}FlagFor${pickType[tIdx]}`] === true}
                            onChange={handleCheckChange}
                          />
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            id={`${flagsArr[3]}FlagFor${pickType[tIdx]}`}
                            disabled={!centerObj?.[`cutoffFlagFor${pickType[tIdx]}`]}
                            checked={centerObj?.[`${flagsArr[3]}FlagFor${pickType[tIdx]}`] === true}
                            onChange={handleCheckChange}
                          />
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            id={`${flagsArr[4]}FlagFor${pickType[tIdx]}`}
                            disabled={!centerObj?.[`cutoffFlagFor${pickType[tIdx]}`]}
                            checked={centerObj?.[`${flagsArr[4]}FlagFor${pickType[tIdx]}`] === true}
                            onChange={handleCheckChange}
                          />
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            id={`${flagsArr[5]}FlagFor${pickType[tIdx]}`}
                            disabled={!centerObj?.[`cutoffFlagFor${pickType[tIdx]}`]}
                            checked={centerObj?.[`${flagsArr[5]}FlagFor${pickType[tIdx]}`] === true}
                            onChange={handleCheckChange}
                          />
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            id={`${flagsArr[6]}FlagFor${pickType[tIdx]}`}
                            disabled={!centerObj?.[`cutoffFlagFor${pickType[tIdx]}`]}
                            checked={centerObj?.[`${flagsArr[6]}FlagFor${pickType[tIdx]}`] === true}
                            onChange={handleCheckChange}
                          />
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            id={`${flagsArr[7]}FlagFor${pickType[tIdx]}`}
                            disabled={!centerObj?.[`cutoffFlagFor${pickType[tIdx]}`]}
                            checked={centerObj?.[`${flagsArr[7]}FlagFor${pickType[tIdx]}`] === true}
                            onChange={handleCheckChange}
                          />
                        </th>
                      </tr>
                    );
                })}
                  <tr>
                  <th style={{ backgroundColor: '#eff2f7' }}>입고유형</th>
                  <th  style={{ backgroundColor: '#eff2f7' }} colSpan={6}>위치지정</th>
                  <th  style={{ backgroundColor: '#eff2f7' }} colSpan={6}>검수</th>
                </tr>
                  <tr>
                  <th style={{ backgroundColor: '#eff2f7' }}>발주입고</th>
                  <th colSpan={6}> <input type="checkbox" id="purchaseLocationFlag" checked={centerObj?.purchaseLocationFlag || false} onChange={handleCheckChange} />
                     </th>
                  <th colSpan={6}> <input type="checkbox" id="purchaseInspectFlag" checked={centerObj?.purchaseInspectFlag || false} onChange={handleCheckChange} />
                     </th>
                </tr>
                  <tr>
                  <th style={{ backgroundColor: '#eff2f7' }}>간선입고</th>
                  <th colSpan={6}> <input type="checkbox" id="linehaulLocationFlag" disabled={true} checked={centerObj?.linehaulLocationFlag || false} onChange={handleCheckChange} />
                     </th>
                  <th colSpan={6}> <input type="checkbox" id="linehaulInspectFlag" disabled={true}  checked={centerObj?.linehaulInspectFlag || false} onChange={handleCheckChange} />
                     </th>
                </tr>
                  <tr>
                  <th style={{ backgroundColor: '#eff2f7' }}>회수입고</th>
                  <th colSpan={6}> <input type="checkbox" id="collectLocationFlag" disabled={true} checked={centerObj?.collectLocationFlag || false} onChange={handleCheckChange} />
                     </th>
                  <th colSpan={6}> <input type="checkbox" id="collectInspectFlag" checked={centerObj?.collectInspectFlag || false} onChange={handleCheckChange} />
                     </th>
                </tr>
              </tbody>
            </table>
            <div className="grid-wrapper div3">
              {pickType?.map((ele, idx) => {
                if (idx === 2 && centerObj?.centerType !== 'FC') return <Fragment key={`${ele}_${idx}00`}></Fragment>;
                else
                  return (
                    <div className="presenterGridBox" key={`${ele}`}>
                      <div className="grid-button-area space-between">
                        <div>
                          <div className="transparent">{pickTypeKr[idx]} 추가근무일</div>
                        </div>
                        <div>
                          <div id={`addRow_${idx}`} className={`blue`} onClick={gridButtonhandler}>
                            +행추가
                          </div>
                          <div id={`deleteRow_${idx}`} className={`red`} onClick={gridButtonhandler}>
                            -행삭제
                          </div>
                        </div>
                      </div>
                      <AUIGrid
                        ref={(el) => (gridRef.current[idx] = el)}
                        name={`${ele}_grid`}
                        columnLayout={columns}
                        gridProps={{
                          ...defaultGridProps,
                          editable: true,
                          showRowAllCheckBox: true,
                          showRowCheckColumn: true,
                          height: 200,
                        }}
                      />
                    </div>
                  );
              })}
            </div>
          </div>
          <div style={{ display: reduxUserInfo?.sub?.includes('LD') ? 'flex' : 'none', zIndex: currentTab === 2 ? 13 : 10, width: '100%', height: 750, flexDirection: 'column' }}>
            <div className="presenterGridBox">
              <div className="grid-button-area">
                <div id={`addRow_3`} className={`blue`} onClick={gridButtonhandler}>
                  +행추가
                </div>
                <div id={`deleteRow_3`} className={`red`} onClick={gridButtonhandler}>
                  -행삭제
                </div>
              </div>
              <AUIGrid
                ref={(el) => (gridRef.current[3] = el)}
                name={`infra_grid`}
                columnLayout={infraColumns}
                gridProps={{
                  ...defaultGridProps,
                  editable: true,
                  showRowAllCheckBox: true,
                  showRowCheckColumn: true,
                  height: 400,
                }}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};
