import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { InventoryLiveScmListDTO, InventoryLiveScmSearchDTO } from 'interface/warehouse';
import { scmService } from '../../_services/service';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';

const Index = ({ tabId }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<AUIGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const fetchPaging = async (searchObj: InventoryLiveScmSearchDTO) => {
    dispatch(setLoading('GET'));
    setBottomSheetObj(null);
    const dataKr = labellingKr(await scmService.getInventoryLiveSCMPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));

    const searchObj = JSON.parse(e.item.searchObj);
    if (e.item.modelStockSeq) searchObj.modelStockSeq = e.item.modelStockSeq;
    const data = await scmService.getInventoryLiveSCMDetail(searchObj);
    const rows = data.map((row) => {
      return {
        ...row,
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
      };
    });

    const gridButtonhandler = (e) => {
      const id = e.target.id;
      if (id === 'SCM_LIVE_DETAIL_EXCELDOWN') {
        bottomGridRef.current.exportAsXlsx({ fileName: '실시간SCM_상세' });
      }
    };

    const buttons = printFunctionToBtns(
      gridFunctions?.filter((ele) => !ele.functionId?.includes('SHOW')),
      gridButtonhandler,
    );
    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '실시간SCM 상세',
          gridRef: bottomGridRef,
          columns: columns.filter((_, idx) => idx !== 0),
          buttons,
          rows,
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
      ],
    });

    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetail,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.SCM_LIVE_SHOWDETAIL;
        },
      },
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품타입',
          dataField: 'modelTypeKr',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },

        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
    },
    {
      headerText: '기초가용재고',
      dataField: 'availableQuantity',
    },
    {
      headerText: '주문등록수량',
      children: [
        {
          headerText: '합계',
          dataField: 'orderQuantity',
        },
        {
          headerText: '배송출고',
          dataField: 'deliveryOrderQuantity',
        },
        {
          headerText: '교환출고',
          dataField: 'exchangeOrderQuantity',
        },
        {
          headerText: '택배출고',
          dataField: 'parcelOrderQuantity',
        },
        {
          headerText: '납품출고',
          dataField: 'supplyOrderQuantity',
        },
      ],
    },
    {
      headerText: '입고예정',
      children: [
        {
          headerText: '합계',
          dataField: 'inPromiseQuantity',
        },
        {
          headerText: '발주입고예정',
          dataField: 'purchasePromiseQuantity',
        },
        {
          headerText: '반출회수입고예정', //
          dataField: 'parcelCollectQuantity',
        },
        {
          headerText: '기사회수입고예정',
          dataField: 'driverCollectQuantity',
        },
      ],
    },
    {
      headerText: '안전재고 간선이동',
      children: [
        {
          headerText: '간선출차대기',
          dataField: 'linehaulOutQuantity',
        },
        {
          headerText: '간선입고대기',
          dataField: 'linehaulInQuantity',
        },
      ],
    },
    {
      headerText: '반출출고대기',
      dataField: 'removeOutQuantity',
    },
    {
      headerText: '배송중',
      dataField: 'deliveryQuantity',
    },
    {
      headerText: '가용재고수량<br />(기초가용재고-주문등록수량)',
      dataField: 'realAvailableQuantity',
    },
    {
      headerText: '현시점발주필요재고',
      dataField: 'purchaseRequireQuantity',
    },
    {
      headerText: '전월판매량',
      dataField: 'lastMonthSellQuantity',
    },
    {
      headerText: '판매량을 고려한 <br /> 발주필요재고',
      dataField: 'finalPurchaseRequireQuantity',
    },
  ];

  const fetchTotal = async (searchObj: InventoryLiveScmSearchDTO) => {
    const total = await scmService.getInventoryLiveSCMTotal(searchObj);
    return total;
  };

  const labellingKr = (data: PagingListDTO<InventoryLiveScmListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      period: 7, //default 7
    },
    gridRef,
    fetchTotal,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: type === 'main' ? await scmService.getInventoryLiveSCMExcelMain(searchObj) : await scmService.getInventoryLiveSCMExcelDetail(searchObj) });
    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '실시간SCM' });
    } else {
      excelDetailGridRef.current.setGridData(list);
      excelDetailGridRef.current.exportAsXlsx({ fileName: '실시간SCM(상세)' });
    }
    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'SCM_LIVE_EXCELDOWN_MAIN') {
      downloadExcel('main');
    } else if (id === 'SCM_LIVE_EXCELDOWN_DETAIL') {
      downloadExcel('detail');
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}

      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area only-right">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showFooter: true,
            footerPosition: 'top',
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
