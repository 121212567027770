import { useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './revisit';

// options
import { nullingMASTER } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';

export const MANAGE_PARCEL_REVISIT_MASTER = [
  'SELLER_FORWARDER_AUTH',
  'SELLER_FORWARDER_WHOLE',
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  //
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  //
  'PORT_CODE',
  'PARCEL_REVISIT_REASON',
  //
  {
    SEARCH_SEQ: [
      { value: 'parcelInvoiceNumber', label: '운송장번호' },
      { value: 'houseNumber', label: 'HBL' },
      { value: 'subNumber', label: 'SBL' },
    ],
  },
  { SEARCH_DATE: [{ value: 'RegisterDate', label: '미배송접수일시' }] },
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_REVISIT_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PARCEL_LIST_REVISIT: <Tab1 tabId={'PARCEL_LIST_REVISIT'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
