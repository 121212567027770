import { useEffect, useState } from 'react';
import { USER_SIGN_IN } from 'envVar';
import { Input } from 'reactstrap';

// import images
import logo from 'assets/images/new-logo-light.png';
import close from 'assets/images/close/cross.png';

import HFSLOGO from 'pages/_HOME/_img/HFS_LOGO.png';
import { useNavigate } from 'react-router';
import { justServer } from 'common/util/isServer';
import { decodeAndSetUserInfo, setLocalStorageTokens } from 'common/util/localStorage';
import { useDispatch } from 'react-redux';
import { setAuth } from 'redux/services/authSlice';
import { serviceStore } from 'services/services';

const Login = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loginObj, setLoginObj] = useState({
    userId: '',
    password: '',
    loginRemainFlag: false,
  });
  const [login, setLogin] = useState(true);

  useEffect(() => {
    document.body.className = 'authentication-bg';
    return function cleanup() {
      document.body.className = '';
    };
  });

  const handleValidSubmit = async(e) => {
    const data = JSON.stringify({
      userId: loginObj.userId,
      password: loginObj.password,
      loginRemainFlag: loginObj.loginRemainFlag,
    });

     const res = await serviceStore?.userAction('sign/in', 'POST', null, data);
      if (res?.data?.result && res?.data?.accessToken && res?.data?.code === 1000) {
        setLocalStorageTokens(res?.data);
        const userInfo = decodeAndSetUserInfo(res?.data);
        dispatch(setAuth(userInfo));

        alert('로그인 되었습니다!');
        history('/')
      } else if (res?.status === 200 && res?.data?.result === false) {
        alert(res?.data?.message);
      }
  };

  return (
    <>
      <div
        style={{
          //display: 'flex', alignItems: 'center', justifyContent: 'center',
          height: '100%',
        }}
      >
        <>
          {justServer('lfs-temp.') ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50%' }}>
              <img src={HFSLOGO} />
            </div>
          ) : (
            <>
              <span className="login-login" onClick={() => setLogin(!login)}>
                Login
              </span>
              <div className="bg-login-layout" />

              <span className={login ? 'reserved-mark-center down' : 'reserved-mark-center'}>
                <img src={logo} alt="logo" style={{ width: 70 }} />
                <span className="letter">we guarantee your properties, be in the right place at the right time.</span>
              </span>
              <span className="reserved-mark-left">All Rights Reserved © 2021 ​Logimate</span>
              <span className="reserved-mark-right">More applications {'>>'}</span>
            </>
          )}
        </>
      </div>
      <div style={{ position: 'absolute', top: 0, display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        {login && (
          <div className={justServer('lfs-temp.') ? 'loginBox temp' : 'loginBox'}>
            {justServer('lfs-temp.') ? (
              ''
            ) : (
              <span style={{ position: 'absolute', top: 10, right: 10 }}>
                <img
                  alt=""
                  src={close}
                  style={{ width: 15 }}
                  onClick={() => {
                    setLogin(false);
                  }}
                />
              </span>
            )}
            <div className="loginTitle">로그인</div>
            <div className="mb-3">
              <label className="text-muted">아이디</label>
              <Input
                onChange={(e) => {
                  setLoginObj({
                    ...loginObj,
                    userId: e.target.value,
                  });
                }}
                className="form-control"
                required
              />
            </div>

            <div className="mb-3">
              <label className="text-muted">비밀번호</label>
              <Input
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleValidSubmit(e);
                  }
                }}
                onChange={(e) => {
                  setLoginObj({
                    ...loginObj,
                    password: e.target.value,
                  });
                }}
                type="password"
                required
              />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="form-check" style={{ paddingLeft: 0, display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  id="customControlInline"
                  checked={loginObj.loginRemainFlag}
                  onChange={() => {
                    setLoginObj({
                      ...loginObj,
                      loginRemainFlag: !loginObj.loginRemainFlag,
                    });
                  }}
                />
                <span style={{ fontSize: 10, color: 'gray', marginLeft: 10 }}>로그인 상태 유지</span>
              </div>
            </div>

            <div className="mt-5">
              <button
                className="btn w-100"
                style={{ background: '#444663', color: 'white' }}
                onClick={(e) => {
                  if (loginObj?.userId === '') {
                    alert('아이디를 입력해주세요.');
                  } else if (loginObj?.password === '') {
                    alert('비밀번호를 입력해주세요.');
                  } else {
                    handleValidSubmit(e);
                  }
                }}
              >
                로그인
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
