import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import useToast from 'hooks/useToast';
import { SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { ChangeModal, IChangeObj } from './component/changeObj';
import { ParcelInvoiceCollectSmallSaveDTO, ParcelInvoiceCollectUserDTO } from '../../../_interface/parcel';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const [inputs, setInputs] = useState<ParcelInvoiceCollectUserDTO>();
  const [todayList, setTodayList] = useState<{ realSize: number; list: ParcelInvoiceCollectUserDTO[] }>();
  const successPlayer = useRef<HTMLAudioElement>();
  const boxCheckPlayer = useRef<HTMLAudioElement>();
  const failurePlayer = useRef<HTMLAudioElement>();
  const validatePlayer = useRef<HTMLAudioElement>();
  const [checked, setChecked] = useState<number[]>();
  const { Toast, displayToast, hideToast, setColor, setMsg } = useToast({
    message: '',
    ms: 3000,
  });

  useEffect(() => {
    getTempToday();
    scanInputRef?.current?.focus();
  }, []);

  const boxCheckHandler = (boxSize = null) => {
    if (BOXBARCODE_CONVERTER[boxSize] !== inputs?.boxSize) boxCheckPlayer?.current?.play();
    else failurePlayer?.current?.play();

    setColor('green');
    setMsg(`박스감지 : ${MASTER_OBJ?.CARGO_BOX_SIZE?.[boxSize]}`);
    displayToast();
    if (boxSize) {
      setInputs((prev) => {
        return {
          ...prev,
          boxSize: BOXBARCODE_CONVERTER[boxSize],
          houseNumber: null,
        };
      });
    }
  };

  const addTempToday = (data: ParcelInvoiceCollectUserDTO) => {
    dispatch(setLoading('GET'));

    const _todayList = JSON.parse(JSON.stringify(todayList));
    const realSize = todayList?.realSize + 1;
    const list = JSON.parse(JSON.stringify(_todayList?.list));
    list?.unshift({
      ...data,
      number: realSize,
      price: inputs?.price * 1000 || 0,
    });
    setTodayList({ list, realSize });
    setChecked([]);
    dispatch(setLoading(null));
  };

  const scanInputRef = useRef<HTMLInputElement>(null);
  const scanRight = (str = 'HBL이 등록되었습니다.', data: ParcelInvoiceCollectUserDTO) => {
    if (data) addTempToday(data);
    else getTempToday();
    successPlayer.current.play();
    setColor('green');
    setMsg(str);
    displayToast();
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        houseNumber: null,
      };
    });
    scanInputRef?.current?.focus();
  };

  const scanRightSound = (str = null) => {
    successPlayer.current.play();
    setColor('green');
    setMsg(str || '전송완료되었습니다');
    displayToast();
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        houseNumber: null,
      };
    });
    scanInputRef?.current?.focus();
  };

  const reset = () => {
    hideToast();
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        houseNumber: null,
        partnerSeq: null,
        parcelType: null,
        boxSize: 'XS',
      };
    });
    scanInputRef?.current?.focus();
  };

  const lengthErrorSound = (str = null) => {
    validatePlayer.current.play();
    setColor('red');
    setMsg(str || '12자리~13자리여야합니다');
    displayToast();
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        number: null,
        houseNumber: null,
      };
    });
    scanInputRef?.current?.focus();
  };

  const noLocalStorage = () => {
    failurePlayer.current.play();
    setColor('red');
    setMsg('복원할 내역이 없습니다');
    displayToast();
  };

  const dupErrorSound = (str = null) => {
    failurePlayer.current.play();
    setColor('red');
    setMsg(str || '중복입니다!');
    displayToast();
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        number: null,
        houseNumber: null,
      };
    });
    scanInputRef?.current?.focus();
  };

  const validation = (inputs: ParcelInvoiceCollectUserDTO) => {
    if (!inputs?.partnerSeq) {
      alert('포워더값은 필수값입니다!');
      return false;
    }
    if (!inputs?.houseNumber) {
      alert('HBL은 필수값입니다!');
      return false;
    }

    if (inputs?.houseNumber?.length > 13 || inputs?.houseNumber?.length < 12) {
      lengthErrorSound();
      return false;
    }

    const houses = templist?.map((ele) => ele.houseNumber);
    if (houses?.includes(inputs?.houseNumber)) {
      dupErrorSound();
      return false;
    }
    addLocalStorage(inputs);
  };

  const [templist, setTemplist] = useState<ParcelInvoiceCollectSmallSaveDTO[]>([]);
  const addLocalStorage = (data: ParcelInvoiceCollectUserDTO) => {
    const _templist = JSON.parse(JSON.stringify(templist));
    _templist?.unshift({
      partnerSeq: data?.partnerSeq,
      parcelType: data?.parcelType,
      boxSize: data?.boxSize,
      houseNumber: data?.houseNumber,
    });
    setTemplist(_templist);
    localStorage.setItem(reduxUserInfo?.sub, JSON.stringify(_templist));
    scanRightSound(data?.houseNumber);
  };

  const resetLocalStorage = () => {
    localStorage.setItem(reduxUserInfo?.sub, '');
  };

  const postSaveTemp = async (templist: ParcelInvoiceCollectSmallSaveDTO[]) => {
    const rs = await serviceStore.parcelAction(`invoice/collect/small/list`, 'POST', null, templist);
    if (rs?.status === 200) {
      scanRightSound('집화 성공하였습니다');
      resetLocalStorage();
      getTempToday();
    } else {
      lengthErrorSound('다시 시도해주세요');
    }
  };

  const deleteTask = async (checked) => {
    if (window.confirm('삭제하시겠습니까?')) {
      const rs = await serviceStore.parcelAction(
        `invoice/collect/cancel`,
        'POST',
        null,
        checked?.map((ele) => {
          return {
            seq: ele,
          };
        }),
      );
      if (rs?.data) {
        scanRight('삭제되었습니다', null);
      }
    }
  };

  const getTempToday = async () => {
    dispatch(setLoading('GET'));
    const { data } = await serviceStore?.parcelAction('invoice/collect/user', 'GET', { packageType: 'SMALL' }, null);
    if (data) setTodayList({ list: data?.filter((ele) => ele.useFlag) as ParcelInvoiceCollectUserDTO[], realSize: data?.length });
    setTemplist([]);
    setChecked([]);
    dispatch(setLoading(null));
  };

  const [changeObj, setChangeObj] = useState<IChangeObj>();
  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (checked?.length > 0) {
      if (id?.includes('change-task-info')) {
        setChangeObj({
          visible: true,
          checked,
          fetchFn: () => getTempToday(),
        });
      } else if (id?.includes('delete')) {
        deleteTask(checked);
      }
    } else {
      alert('선택된 것이 없습니다');
    }
  };

  const BOXBARCODE = ['BOX_A', 'BOX_B', 'BOX_C', 'BOX_D', 'BOX_E', 'BOX_F'];
  const BOXBARCODE_CONVERTER = { BOX_A: 'XS', BOX_B: 'S', BOX_C: 'M', BOX_D: 'L', BOX_E: 'XL', BOX_F: 'XXL' };

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      if (inputs?.boxSize && inputs?.houseNumber && inputs?.parcelType) validation(inputs);
    }
  };

  const getLocalstorage = () => {
    const str = localStorage.getItem(reduxUserInfo?.sub);
    if (str) {
      const templist = JSON.parse(str);
      setTemplist(templist);
    } else {
      noLocalStorage();
    }
  };

  return (
    <div className="page-content">
      {changeObj?.visible && <ChangeModal changeObj={changeObj} setChangeObj={setChangeObj} />}
      <div style={{ maxWidth: 500, marginBottom: 200 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 50 }}>
          <button
            onClick={(e) => {
              e.preventDefault();
              reset();
            }}
          >
            화면 새로고침
          </button>
          <span>
            {templist?.length === 0 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  getLocalstorage();
                }}
              >
                전송 전 내역복원
              </button>
            )}
          </span>
        </div>
        <div style={{ position: 'relative', height: 50, marginBottom: 12 }}>
          <Toast />
        </div>
        <Form onKeyDown={onKeyPress}>
          <Row>
            <Col>
              <label className="col-form-label">*포워더</label>
              <SelectD
                hasNull={false}
                placeholder="포워더를 선택하세요"
                value={
                  inputs?.partnerSeq
                    ? {
                        value: inputs?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_FORWARDER_WHOLE?.[inputs?.partnerSeq],
                      }
                    : null
                }
                options={MASTER_OPS?.SELLER_FORWARDER_WHOLE}
                onChange={(options) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      partnerSeq: (options as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*택배사</label>
              <SelectD
                menuPlacement="top"
                hasNull={false}
                placeholder="택배사를 선택하세요"
                value={
                  inputs?.parcelType
                    ? {
                        value: inputs?.parcelType,
                        label: MASTER_OBJ?.PARCEL_TYPE?.[inputs?.parcelType],
                      }
                    : null
                }
                options={MASTER_OPS?.PARCEL_TYPE}
                onChange={(options) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      parcelType: (options as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*크기</label>
              <SelectD
                menuPlacement="top"
                hasNull={false}
                isDisabled
                placeholder="크기를 선택하세요"
                value={
                  inputs?.boxSize
                    ? {
                        value: inputs?.boxSize,
                        label: MASTER_OBJ?.CARGO_BOX_SIZE?.[inputs?.boxSize],
                      }
                    : null
                }
                options={MASTER_OPS?.CARGO_BOX_SIZE}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*메인(HBL)</label>
              <input
                ref={scanInputRef}
                value={inputs?.houseNumber || ''}
                className="form-control backgroundYellow"
                placeholder="HBL을 입력하세요"
                onChange={(e) => {
                  if (BOXBARCODE?.includes(e.target.value)) {
                    boxCheckHandler(e.target.value);
                  } else {
                    setInputs((prev) => {
                      return {
                        ...prev,
                        houseNumber: e.target.value.substring(0, 13),
                      };
                    });
                  }
                }}
              />
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            <button
              onClick={(e) => {
                e.preventDefault();
                if (window.confirm('집화한 내용을 전송하시겠습니까?')) {
                  const str = localStorage.getItem(reduxUserInfo?.sub);
                  console.log(str);
                  const letsDataArr = str ? JSON.parse(str) : templist;
                  console.log(templist);
                  if (letsDataArr?.length > 0) {
                    postSaveTemp(letsDataArr);
                  } else {
                    lengthErrorSound('전송할 집화내역이 없습니다');
                  }
                }
              }}
              style={{ width: '100%', marginTop: 10 }}
              className="btn btn-secondary big"
            >
              집화전송
            </button>
          </Col>
        </Row>
        <div className="grid-button-area space-between" style={{ marginTop: 30 }}>
          <div>
            <div id={`change-task-info`} className={`orange`} onClick={gridButtonhandler}>
              변경
            </div>
            <div id={`delete`} className={`red`} onClick={gridButtonhandler}>
              삭제
            </div>
          </div>
          <div className="ellipsis">
            <div className={`transparent`} style={{ color: 'gray', maxWidth: 'unset' }}>
              집화전송 건만 변경/삭제 가능
            </div>
          </div>
        </div>
        <table className="border-table noBorder fontSize10" style={{ fontSize: 10, marginTop: 0 }}>
          <thead>
            <tr>
              <th>
                <input
                  style={{ marginTop: 4 }}
                  type="checkbox"
                  disabled={todayList?.list?.length === 0}
                  checked={checked?.length === todayList?.list?.length}
                  onChange={() => {
                    if (checked?.length === todayList?.list?.length) {
                      setChecked([]);
                    } else {
                      setChecked(todayList?.list?.map((ele) => ele.parcelInvoiceCollectSeq));
                    }
                  }}
                />
              </th>
              <th colSpan={2}>순번</th>
              <th colSpan={2}>HBL</th>
              <th colSpan={2}>포워더</th>
              <th colSpan={2}>택배사</th>
              <th colSpan={2}>크기</th>
            </tr>
          </thead>
        </table>
        <div style={{ maxHeight: 500, overflowY: 'scroll' }}>
          {templist?.map((ele, idx) => {
            return (
              <div key={`sm_temp_package_${idx}`} className="li-btn">
                <div style={{ padding: '3px' }}>
                  <input style={{ marginTop: 4 }} type="checkbox" disabled />
                </div>
                <div style={{ width: '87%', paddingLeft: 10 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="badge-number"></span>
                    <span>{ele.houseNumber}</span>
                  </div>
                  <div>
                    {MASTER_OBJ?.SELLER_FORWARDER_WHOLE?.[ele?.partnerSeq]} | {MASTER_OBJ?.PARCEL_TYPE?.[ele?.parcelType]}
                  </div>
                </div>
                <div className="b box-size">{MASTER_OBJ?.CARGO_BOX_SIZE?.[ele?.boxSize]}</div>
              </div>
            );
          })}
          {todayList?.list?.map((ele, idx) => {
            return (
              <div
                key={`sm_package_${idx}`}
                className={checked?.includes(ele.parcelInvoiceCollectSeq) || false ? 'li-btn active' : 'li-btn'}
                onClick={() => {
                  if (checked?.includes(ele.parcelInvoiceCollectSeq)) {
                    setChecked(checked?.filter((check) => check !== ele.parcelInvoiceCollectSeq));
                  } else {
                    setChecked(checked?.concat(ele.parcelInvoiceCollectSeq) || [ele.parcelInvoiceCollectSeq]);
                  }
                }}
              >
                <div style={{ padding: '3px' }}>
                  <input
                    style={{ marginTop: 4 }}
                    type="checkbox"
                    checked={checked?.includes(ele.parcelInvoiceCollectSeq) || false}
                    onChange={() => {
                      if (checked?.includes(ele.parcelInvoiceCollectSeq)) {
                        setChecked(checked?.filter((check) => check !== ele.parcelInvoiceCollectSeq));
                      } else {
                        setChecked(checked?.concat(ele.parcelInvoiceCollectSeq) || [ele.parcelInvoiceCollectSeq]);
                      }
                    }}
                  />
                </div>
                <div style={{ width: '87%', paddingLeft: 10 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="badge-number">{ele.number}</span>
                    <span>{ele.houseNumber}</span>
                  </div>
                  <div>
                    {MASTER_OBJ?.SELLER_FORWARDER_WHOLE?.[ele?.partnerSeq]} | {MASTER_OBJ?.PARCEL_TYPE?.[ele?.parcelType]}
                  </div>
                </div>
                <div className="b box-size">{MASTER_OBJ?.CARGO_BOX_SIZE?.[ele?.boxSize]}</div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="audioWrapper">
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_sound.mp3" ref={successPlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_sound.mp3" ref={boxCheckPlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_fail_sound.mp3" ref={failurePlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/surprise-sound.mp3" ref={validatePlayer} controls></audio>
      </div>
    </div>
  );
};

export default Index;
