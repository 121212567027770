import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { PurchaseOrderSerialNumberListDTO, PurchaseOrderSerialNumberSearchDTO } from 'interface/warehouse';
import { modelNproductService } from '../../_services/service';
import BarcodePopup, { IPrintObj } from './component/barcodePopup';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { PagingListDTO } from 'interface/util';
import { setLoading } from 'redux/services/menuSlice';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';

const Index = ({ tabId }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const [printObj, setPrintObj] = useState<IPrintObj>();

  const labellingKr = (data: PagingListDTO<PurchaseOrderSerialNumberListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: PurchaseOrderSerialNumberSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await modelNproductService.getPurchaseSerialListPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList, getCheckedRowItems } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const columns: IGrid.Column[] = [
    {
      headerText: '발주번호',
      dataField: 'purchaseSeq',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: 'LOT번호(외부바코드)',
      dataField: 'externalBarcode',
    },
    {
      headerText: '시리얼번호 자릿수',
      dataField: 'serialNumberLength',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '바코드 출력횟수',
      dataField: 'purchasePrintCount',
    },
  ];

  const increatePurchasePrintCount = (items: PurchaseOrderSerialNumberListDTO[]) => {
    const body = items.map((ele) => {
      return {
        id: ele.serialNumber,
      };
    });
    modelNproductService.increatePurchasePrintCount(body);
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    const items = gridRef?.current?.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'BARCODEPRINT') {
        increatePurchasePrintCount(items);
        setPrintObj({
          visible: true,
          data: items,
        });
      }
    } else {
      alert('선택된 건이 없습니다!');
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {printObj?.visible && <BarcodePopup printObj={printObj} setPrintObj={setPrintObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox gridRef={gridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
