import { useEffect, useRef, useState } from 'react';
import cancleimg from '../../../assets/images/기사회수입고사진.png';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { CollectPrintDTO } from '../_interface/warehouse';

import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import CommonModalNew from 'components/modal/commonModalNew';
import Barcode from 'react-barcode';
import { serviceStore } from 'services/services';

export interface ICollectPrint {
  visible?: boolean;
  collectSeqs?: Array<number>;
}

const PrintWrapper = styled.div({
  position: 'relative',
  padding: 30,
  fontSize: 16,
  fontWeight: 700,
  marginBottom: 10,
});

export const CollectPrint = ({ printObj, setPrintObj }) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [data, setData] = useState<CollectPrintDTO[]>();

  useEffect(() => {
    fetchCollectList();
  }, []);

  const fetchCollectList = async () => {
    if (printObj?.collectSeqs?.length > 0) {
      const dataArr = printObj?.collectSeqs?.map((ele) => {
        return {
          collectSeq: ele,
        };
      });
      const rs = await serviceStore?.warehouseAction(`collect/list/print`, 'POST', null, dataArr);
      if (rs?.status === 200) {
        setData(rs.data);
      }
    }
  };

  return (
    <CommonModalNew
      visible={printObj?.visible}
      style={{ width: 1200 }}
      setVisible={() => {
        setPrintObj(null);
      }}
      title={
        <>
          <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
        </>
      }
      children={
        <div ref={wrapperRef}>
          {data?.map((ele, idx) => {
            return (
              <PrintWrapper className="break-for-onePage-print" key={`${idx}`}>
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                  <div className="printTitle" style={{ fontSize: 80, fontWeight: 700 }}>
                    {MASTER_OBJ?.FEE_TYPE?.[ele?.feeType]}
                  </div>
                  <div>{(ele?.serialNumber || ele?.barcode) && <Barcode width={1.5} value={ele?.serialNumber || ele.barcode || ''} height={40} />}</div>
                  <div>{ele?.invoiceSeq && <Barcode width={1.5} value={ele?.invoiceSeq + '' || ''} height={40} />}</div>
                </div>

                <table className="border-table border-font" style={{ border: '3px solid black' }}>
                  <tbody>
                    <tr>
                      <th>회수창고</th>
                      <td colSpan={2}>{MASTER_OBJ?.CENTER_WHOLE?.[ele?.centerCode]}</td>
                      <th>회수기사명</th>
                      <td colSpan={2}>{MASTER_OBJ?.DRIVER_WHOLE?.[ele?.userId]}</td>
                      <th>회수유형</th>
                      <td colSpan={2}>{MASTER_OBJ?.COLLECT_TYPE?.[ele?.collectType]}</td>
                      <th>회수사유</th>
                      <td colSpan={2}>{MASTER_OBJ?.CANCEL_REASON?.[ele?.collectReason]}</td>
                    </tr>
                    <tr>
                      <th>제품명</th>
                      <td colSpan={5}>{ele.modelName}</td>
                      <th>S/N</th>
                      <td colSpan={5}>{ele.serialNumber}</td>
                    </tr>
                    <tr>
                      <th>회수고객명</th>
                      <td colSpan={5} className="ellipsis" style={{ fontSize: 50, fontWeight: 600 }}>
                        {ele.receiverName}
                      </td>
                      <th>설치고객명</th>
                      <td colSpan={5} className="ellipsis" style={{ fontSize: 50, fontWeight: 600 }}>
                        {ele.moveName}
                      </td>
                    </tr>
                    <tr>
                      <th>회수주소</th>
                      <td colSpan={5}>{ele.receiverAddr1}</td>
                      <th>설치주소</th>
                      <td colSpan={5}>{ele.moveAddr1}</td>
                    </tr>
                    <tr>
                      <th>회수일자</th>
                      <td colSpan={2}>{ele.driverConfirmDatetime}</td>
                      <th>입고일자</th>
                      <td colSpan={2}>{ele.centerConfirmDatetime}</td>
                      <th colSpan={6} rowSpan={6} style={{ position: 'relative' }}>
                        <img src={cancleimg} alt="불량위치" />
                        <div style={{ position: 'absolute', bottom: 10 }}>{'<불량위치>'}</div>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        입고
                        <br />
                        확인자명
                      </th>
                      <td colSpan={2}>{ele.centerConfirmId}</td>
                      <th>제품상태</th>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <th>
                        양품화
                        <br />
                        담당자명/
                        <br />
                        비고
                      </th>
                      <td colSpan={2}></td>
                      <th>수리완료</th>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <th>특이사항</th>
                      <td colSpan={5}>{ele.workNote}</td>
                    </tr>
                    <tr>
                      <th>
                        불량/수리
                        <br />
                        메모
                      </th>
                      <td colSpan={5}>{ele.defectedNote}</td>
                    </tr>
                  </tbody>
                </table>
              </PrintWrapper>
            );
          })}
        </div>
      }
    />
  );
};
