import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

// service
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LocationSupplementSearchDTO, LocationSupplementDTO } from 'interface/warehouse';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
// component
import { PagingListDTO } from 'interface/util';
import { serviceStore } from 'services/services';
import { IPrints, Prints } from './component/print';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { RootState } from 'redux/store';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  const labellingKr = (data: PagingListDTO<LocationSupplementSearchDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_AUTH),
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER_AUTH),
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: LocationSupplementSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.warehouseAction(`${tabId}/supplement/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<LocationSupplementDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      ...defaultSearchFilter,
    },
    gridRef,
    fetchPaging,
  });

  const columns: IGrid.Column[] = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
    },
    {
      headerText: '로케이션',
      children: [
        {
          headerText: '보충',
          dataField: 'locationCodeForSupplement',
        },
        {
          headerText: '대표',
          dataField: 'locationCodeForRepresentative',
        },
      ],
    },
  ];

  const openPrint = (items) => {
    const centers = [];
    items?.forEach((item) => {
      if (!centers?.includes(item?.centerCode)) centers?.push(item?.centerCode);
    });
    if (centers?.length > 1) {
      alert('하나의 창고로만 재고보충 지시서를 작성할 수 있습니다!');
    } else {
      setPrintObj({
        visible: true,
        items,
      });
    }
  };
  const gridButtonhandler = (e) => {
    const id = e.target.id;
    handleCheckItems(id);
  };
  const [printObj, setPrintObj] = useState<IPrints>();
  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'print') {
        openPrint(items);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  return (
    <div className="page-content">
      {printObj?.visible && <Prints printObj={printObj} setPrintObj={setPrintObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        <div className="grid-button-area">
          <div className="gray" id="print" onClick={gridButtonhandler}>
            프린트
          </div>
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            pageRowCount: GRID_PAGE_ROW_COUNT,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
