import { useMemo, useRef, useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';

import { useDispatch } from 'react-redux';
import useToast from 'hooks/useToast';

import axios from 'axios';
import { setLoading } from 'redux/services/menuSlice';
import { handleFile, uploadFile } from 'common/util/photo';
import CommonModalNew from 'components/modal/commonModalNew';
import { AuthTmpPopup } from './authTmpPopup';
import { InputD } from 'components/reactstrap/reactstrap';

import add_file_icon from '../img/add_file_icon.png';
import download_blue from '../img/download_blue.png';
import closeIcon_black from '../img/closeIcon_black.png';
import { NOTICE_IMAGE_BUCKET } from 'pages/DELIVERY/_asset/asset';

export const AuthPopup = (props) => {
  const dispatch = useDispatch();
  const QuillRef = useRef<ReactQuill>();
  const fileUploadRef = useRef();
  const [uploadedPdfFile, setUploadedPdfFile] = useState([]);

  const { authPopupObj, setAuthPopupObj, searchObj } = props;
  const [tempObj, setTempObj] = useState({
    visible: false,
    now: null,
    tempList: null,
  });
  const [boardObj, setBoardObj] = useState({
    type: null,
    typesArr: ['erp', 'logiInstall', 'logiChango'],
    category: 'GENERAL',
    title: null,
    erpFlag: false,
    installAppFlag: false,
    warehouseAppFlag: false,
    tempFlag: false,
    content: null,
    files: [],
  });

  const { Toast, showToast } = useToast({
    message: '임시저장이 완료되었습니다',
    ms: 3000,
  });
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    const url = `${NOTICE_IMAGE_BUCKET}${new Date().toISOString()}`;

    input.onchange = async () => {
      if (input && input.files) {
        const file = input.files[0];
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = async () => {
          const arrayBuffer = reader.result;
          if (arrayBuffer) {
            try {
              await axios.put(url, arrayBuffer, {
                headers: {
                  'Content-Type': 'image',
                },
              });
            } catch (e) {
              console.log(e);
            }
            if (QuillRef.current) {
              const editor = QuillRef.current.getEditor();
              const range = editor.getSelection();
              editor.insertEmbed(range.index, 'image', url);
            }
          }
        };
      }
    };
  };

  useEffect(() => {
    if (authPopupObj?.data) {
      const typesArr = [];
      if (authPopupObj?.data?.erpFlag) typesArr.push('erp');
      if (authPopupObj?.data?.installAppFlag) typesArr.push('logiInstall');
      if (authPopupObj?.data?.warehouseAppFlag) typesArr.push('logiChango');

      setBoardObj({
        ...authPopupObj?.data,
        typesArr,
      });
      setTempObj((prev) => {
        return {
          ...prev,
          now: authPopupObj?.data?.announcementSeq,
          tempList: authPopupObj?.tempList,
        };
      });
      setUploadedPdfFile(authPopupObj?.data?.files || []);
    } else {
      setBoardObj({
        typesArr: ['erp', 'logiInstall', 'logiChango'],
        category: 'GENERAL',
        type: 'UPDATE',
        erpFlag: false,
        tempFlag: false,
        installAppFlag: false,
        warehouseAppFlag: false,
        title: null,
        content: null,
        files: [],
      });
    }
  }, [authPopupObj]);

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          //[{ 'font': [] }],
          [{ header: [1, 2, 3, 4, 5, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image'],
          [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
        handlers: {
          image: imageHandler,
        },
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
    };
  }, []);

  const formats = useMemo(() => {
    return [
      // 'font',
      'size',
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'align',
      'color',
      'background',
    ];
  }, []);

  const handleCheckbox = (e) => {
    if (e.target.id === 'all') {
      if (e.target.checked === true) {
        setBoardObj((prev) => {
          return {
            ...prev,
            typesArr: ['erp', 'logiInstall', 'logiChango'],
          };
        });
      } else {
        setBoardObj((prev) => {
          return {
            ...prev,
            typesArr: [],
          };
        });
      }
    } else {
      let set = new Set(boardObj?.typesArr);
      if (set.has(e.target.id)) {
        set.delete(e.target.id);
      } else {
        set.add(e.target.id);
      }
      const typesArr = Array.from(set);
      setBoardObj((prev) => {
        return {
          ...prev,
          typesArr,
        };
      });
    }
  };

  const handleRadiobox = (e) => {
    setBoardObj((prev) => {
      return {
        ...prev,
        type: e.target.id,
      };
    });
  };

  const handleTempSave = async () => {
    const { rs, data } = await authPopupObj?.saveTmpFn({ boardObj });
    if (rs.status === 200) {
      const tempList = await authPopupObj?.getTmpFn(rs?.data?.announcementSeq);
      setAuthPopupObj((prev) => {
        return {
          ...prev,
          data,
          tempList,
        };
      });
      showToast();
    }
  };

  const onFileUploaderChange = async (e) => {
    dispatch(setLoading('PUT'));
    const ret = await handleFile(e);
    const uploadUrl = await uploadFile(ret.file, ret.url);
    if (uploadUrl) {
      const arr = JSON.parse(JSON.stringify(uploadedPdfFile));
      arr.push({
        uploadUrl,
        tableColumn: ret.file.name,
      });
      setUploadedPdfFile(arr);
    }
    dispatch(setLoading(null));
  };

  const deleteInPopup = async () => {
    if (window.confirm('이 글을 정말 삭제하시겠습니까?')) {
      const rs = await authPopupObj?.deleteFn({ announcementSeqs: [authPopupObj?.data?.announcementSeq] });
      if (rs?.status === 200) {
        authPopupObj?.fetchListFn(searchObj);
        setAuthPopupObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }
    }
  };

  return (
    <CommonModalNew
      title={'시스템 공지사항'}
      subTitle={'관리자전용'}
      style={{ width: 1100 }}
      visible={authPopupObj?.visible}
      setVisible={() => {
        setAuthPopupObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      children={
        <>
          <Toast />
          {tempObj?.visible && (
            <AuthTmpPopup
              tempObj={tempObj}
              setTempObj={setTempObj}
              deleteTmpThen={(data) => {
                authPopupObj?.getDetailFn(data);
              }}
              setTmpToAuth={(data) => {
                authPopupObj?.getDetailFn(data);
                // tempPopup끄기
                setTempObj((prev) => {
                  return {
                    ...prev,
                    visible: false,
                    now: null,
                    tempList: null,
                  };
                });
              }}
            />
          )}
          <div style={{ textAlign: 'right' }}>
            <div>
              <span
                className="btn-half btn-white"
                onClick={() => {
                  authPopupObj?.data ? alert('저장된 글은 임시저장 불가합니다') : handleTempSave();
                }}
              >
                저장
              </span>
              <span
                className="btn-half btn-white"
                onClick={() => {
                  if (authPopupObj?.tempList?.length > 0) {
                    setTempObj((prev) => {
                      return {
                        ...prev,
                        visible: true,
                        tempList: authPopupObj?.tempList,
                        now: authPopupObj?.data?.announcementSeq,
                        deleteFn: authPopupObj?.deleteFn,
                      };
                    });
                  }
                }}
              >
                {authPopupObj?.tempList?.length || 0}
              </span>
              {authPopupObj?.data?.tempFlag === false && (
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    deleteInPopup();
                  }}
                >
                  삭제
                </button>
              )}

              <button
                className="btn btn-secondary sm"
                onClick={() => {
                  //
                  if (window.confirm(`등록하시겠습니까?`)) {
                    const data = {
                      ...boardObj,
                      erpFlag: boardObj?.typesArr?.includes('erp') ? true : false,
                      installAppFlag: boardObj?.typesArr?.includes('logiInstall') ? true : false,
                      warehouseAppFlag: boardObj?.typesArr?.includes('logiInstall') ? true : false,
                      tempFlag: 'false',
                    };

                    if (uploadedPdfFile?.length > 0) {
                      data.files = uploadedPdfFile;
                    }

                    delete data.typesArr;
                    authPopupObj?.saveFn({ data, searchObj });
                  }
                }}
              >
                {authPopupObj?.data?.tempFlag === false ? '수정' : '등록'}
              </button>
            </div>
          </div>

          <div style={{ height: 60, overflowY: 'scroll', padding: 10, margin: '10px 0' }}>
            {uploadedPdfFile?.length > 0 && <span>(총 {uploadedPdfFile?.length}개의 파일)</span>}
            {uploadedPdfFile?.map((ele, idx) => {
              return (
                <span className="clickable" key={`uploadedPdfFile${idx}`} style={{ margin: '0 5px' }}>
                  <img
                    src={download_blue}
                    style={{ width: 10, marginRight: 3 }}
                    onClick={() => {
                      window.open(ele.uploadUrl);
                    }}
                  />
                  <span
                    style={{ textDecoration: 'underline' }}
                    onClick={() => {
                      window.open(ele.uploadUrl);
                    }}
                  >
                    {ele.tableColumn}
                  </span>
                  <img
                    src={closeIcon_black}
                    style={{ width: 7, marginLeft: 5 }}
                    onClick={(e) => {
                      e.preventDefault();
                      const filtered = uploadedPdfFile.filter((ele, i) => i !== idx);
                      setUploadedPdfFile(filtered);
                    }}
                  />
                </span>
              );
            })}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div className="check-area">
              <span>대상</span>
              <input type="checkbox" id="all" checked={boardObj?.typesArr?.length === 3} onChange={handleCheckbox} />
              <label htmlFor="all">전체</label>
              <input type="checkbox" id="erp" checked={boardObj?.typesArr?.includes('erp')} onChange={handleCheckbox} />
              <label htmlFor="erp">ERP</label>
              <input type="checkbox" id="logiInstall" checked={boardObj?.typesArr?.includes('logiInstall')} onChange={handleCheckbox} />
              <label htmlFor="logiInstall">로지설치+</label>
              <input type="checkbox" id="logiChango" checked={boardObj?.typesArr?.includes('logiChango')} onChange={handleCheckbox} />
              <label htmlFor="logiChango">로지창고+</label>
            </div>
            &nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="check-area">
              <span>구분</span>
              <input type="radio" id="IMPORTANT" name="type" checked={boardObj?.type === 'IMPORTANT'} onChange={handleRadiobox} />
              <label htmlFor="IMPORTANT">중요</label>
              <input type="radio" id="UPDATE" name="type" checked={boardObj?.type === 'UPDATE'} onChange={handleRadiobox} />
              <label htmlFor="UPDATE">업데이트</label>
              <input type="radio" id="GENERAL" name="type" checked={boardObj?.type === 'GENERAL'} onChange={handleRadiobox} />
              <label htmlFor="GENERAL">일반</label>
            </div>
          </div>
          <div>
            <div>
              <InputD
                className="ql-editor-title"
                value={boardObj?.title || ''}
                placeholder="제목"
                onChange={(e) => {
                  setBoardObj((prev) => {
                    return {
                      ...prev,
                      title: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div style={{ minHeight: 500, position: 'relative' }}>
              <span style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <img style={{ width: 22 }} src={add_file_icon} alt="파일업로드" /> &nbsp;&nbsp;파일업로드
                <input ref={fileUploadRef} style={{ position: 'absolute', width: 80, height: 20, right: 0 }} type="file" id="file" accept="application/pdf" onChange={onFileUploaderChange} />
              </span>
              <ReactQuill
                theme="snow"
                ref={QuillRef}
                modules={modules}
                formats={formats}
                placeholder="입력"
                value={boardObj?.content}
                onChange={(content, delta, source, editor) => {
                  setBoardObj((prev) => {
                    return {
                      ...prev,
                      content: editor?.getHTML(),
                    };
                  });
                }}
              />
            </div>
          </div>
        </>
      }
    />
  );
};
