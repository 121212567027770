import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import CommonModalNew from 'components/modal/commonModalNew';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { CreditLoanListDTO, CreditLoanSearchDTO } from '../../../../_interface/parcel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { setLoading } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';

export interface ICuOjModal {
  visible?: boolean;
  data?: CreditLoanListDTO;
  searchObj?: CreditLoanSearchDTO;
  fetchFn?: <T>(searchObj: T) => void;
}

export const CuObjModal = ({ cuObj, setCuObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [data, setData] = useState<CreditLoanListDTO>();

  useEffect(() => {
    if (cuObj?.data) {
      setData(cuObj?.data);
    }
  }, []);

  const validate = (data: CreditLoanListDTO) => {
    if (!data?.partnerSeq) {
      alert('판매사는 필수값입니다!');
      return false;
    }
    if (!data?.maximumLoan) {
      alert('여신한도액은 필수값입니다!');
      return false;
    }
    return true;
  };

  const saveLoanDetail = async () => {
    if (validate(data)) {
      dispatch(setLoading('POST'));
      const rs = await serviceStore.parcelAction('fee/credit/loan', cuObj?.data ? 'PUT' : 'POST', null, data);
      if (rs?.status === 200) {
        alert('저장되었습니다');
        if (cuObj?.fetchFn && cuObj?.searchObj) cuObj?.fetchFn(cuObj?.searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  return (
    <CommonModalNew
      style={{ width: 350 }}
      visible={cuObj?.visible}
      setVisible={() => {
        setCuObj(null);
      }}
      title={cuObj?.data ? '상세보기' : '여신등록'}
      rightTitle={
        <>
          <Button
            className="btn btn-orange ssm"
            onClick={() => {
              saveLoanDetail();
            }}
          >
            저장
          </Button>
        </>
      }
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">*판매사 (포워더)</label>
              <SelectD
                isDisabled={cuObj?.data}
                options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
                value={
                  data?.partnerSeq
                    ? {
                        value: data?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_FORWARDER_AUTH?.[data?.partnerSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setData((prev) => {
                    return {
                      ...prev,
                      partnerSeq: (option as OptionItem)?.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*여신한도액</label>
              <InputD
                value={data?.maximumLoan || ''}
                onChange={(e) => {
                  setData((prev) => {
                    return {
                      ...prev,
                      maximumLoan: e.target.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          {cuObj?.data && (
            <Row>
              <Col>
                <label className="col-form-label">여신기간설정</label>
                <FlatpickrD
                  value={[data?.fromCreditLoanDate, data?.toCreditLoanDate]}
                  onChange={(option) => {
                    setData({
                      ...data,
                      fromCreditLoanDate: returnDateyyyymmdd(option[0]),
                      toCreditLoanDate: returnDateyyyymmdd(option[1]),
                    });
                  }}
                />
              </Col>
            </Row>
          )}
        </>
      }
    />
  );
};
