import { useEffect, useMemo, useRef, useState } from 'react';
// redux
import { Button, Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

import styled from 'styled-components';
import * as IGrid from 'aui-grid';

import { useNavigate } from 'react-router-dom';
import { setLoading } from 'redux/services/menuSlice';
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import { generateUrlByDesignType } from 'components/LDS/utils/path';
import { serviceStore } from 'services/services';
import { IPopupObj, Popup } from './component/popup';
import { OrderSaveSSDTO } from '../../_interface/order';
import { useIsMounted } from 'hooks/basichooks';
import { delay } from 'common/util/counting';
import { GetVendor, IGetVendorObj } from '../../ssListTab/ssList/component/detail/getVendor';

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin: 40px 5px 5px 2px;
`;

const Index = () => {
  const navigate = useNavigate();
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const [invoiceData, setInvoiceData] = useState<OrderSaveSSDTO & { discountRate?: number }>({
    orderDate: returnDateyyyymmdd(new Date()), // 주문일자
    partnerSeq: reduxUserInfo['partner'] || 384,
    feeType: null,
    customerName: null,
    customerPhone: null,
    customerZipcode: null,
    customerAddr1: null,
    receiverName: null,
    receiverPhone: null,
    receiverZipcode: null,
    receiverAddr1: null,
    vendorFlag: false,
    productModels: [],
  });
  const [customerReceiverIsSame, setCustomerReceiverIsSame] = useState(false);

  const isMounted = useIsMounted();
  useEffect(() => {
    void delay(100).then(() => {
      if (isMounted) {
        gridRef.current.bind('cellEditEnd', bindCellEdit);
      }
    });
  }, [isMounted]);

  const discountMachine = (item) => {
    const discountedSellingPrice = (Number(item.sellingPrice) * (100 - Number(item?.discountRate))) / 100;
    const discountedSurtax = (Number(item.surtax) * (100 - Number(item?.discountRate))) / 100;
    return {
      ...item,
      discountedSellingPrice,
      discountedSurtax,
      discountedTotalPrice: discountedSellingPrice + discountedSurtax,
    };
  };

  const bindCellEdit = (e: IGrid.CellEditEndEvent) => {
    if (e.dataField === 'discountRate') {
      const items = gridRef?.current?.getGridData();
      const rows = items?.map((row) => {
        if (row.modelStockSeq === e.item.modelStockSeq) {
          return discountMachine(row);
        }
        return row;
      });
      gridRef?.current?.setGridData(rows);
    }
  };

  useEffect(() => {
    const items = gridRef?.current?.getGridData();
    if (items?.length > 0) {
      const rows = items?.map((row) => {
        return discountMachine({
          ...row,
          discountRate: invoiceData?.discountRate,
        });
      });
      gridRef?.current?.setGridData(rows);
    }
  }, [invoiceData?.discountRate]);

  const columns: IGrid.Column[] = [
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '수량',
      dataField: 'quantity',
      formatString: '#,##0',
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        validator: (oldValue, newValue, item, dataField, fromClipboard, which) => {
          if (!newValue) return { validate: false, message: '수량은 필수값입니다' };
          if (newValue === 0) return { validate: false, message: '수량은 필수값입니다' };
        },
        autoThousandSeparator: true,
        onlyNumeric: true,
        allowNegative: false,
      },
      editable: true,
    },
    {
      headerText: '출고창고',
      dataField: 'centerCodeKr',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: (e: IGrid.ButtonClickEvent) => {
          setPopupObj({
            item: e.item,
            visible: true,
            type: 'center',
          });
        },
      },
    },
    {
      headerText: '할인율(%)',
      dataField: 'discountRate',
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        validator: (oldValue, newValue, item, dataField, fromClipboard, which) => {
          if (newValue > 100) return { validate: false, message: '100%가 최대 입력값 입니다' };
        },
        autoThousandSeparator: true,
        onlyNumeric: true,
        allowNegative: false,
      },
      editable: true,
    },
    {
      headerText: '할인 전 금액(개당)',
      children: [
        {
          headerText: '공급가액',
          dataField: 'sellingPrice',
          formatString: '#,##0',
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            autoThousandSeparator: true,
            onlyNumeric: true,
          },
        },
        {
          headerText: '부가세',
          dataField: 'surtax',
          formatString: '#,##0',
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            autoThousandSeparator: true,
            onlyNumeric: true,
          },
        },
        {
          headerText: '단가(VAT포함)',
          dataField: 'totalPrice',
          formatString: '#,##0',
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            autoThousandSeparator: true,
            onlyNumeric: true,
          },
        },
      ],
    },
    {
      headerText: '할인 후 금액(개당)',
      children: [
        {
          headerText: '공급가액',
          dataField: 'discountedSellingPrice',
          formatString: '#,##0',
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            autoThousandSeparator: true,
            onlyNumeric: true,
          },
        },
        {
          headerText: '부가세',
          dataField: 'discountedSurtax',
          formatString: '#,##0',
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            autoThousandSeparator: true,
            onlyNumeric: true,
          },
        },
        {
          headerText: '단가(VAT포함)',
          dataField: 'discountedTotalPrice',
          formatString: '#,##0',
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            autoThousandSeparator: true,
            onlyNumeric: true,
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (customerReceiverIsSame === true) {
      setInvoiceData((prev) => {
        return {
          ...prev,
          receiverName: prev.customerName,
          receiverPhone: prev.customerPhone,
          receiverPhone2: prev.customerPhone2,
          receiverZipcode: prev.customerZipcode,
          receiverAddr1: prev.customerAddr1,
          receiverAddr2: prev.customerAddr2,
        };
      });
    }
  }, [customerReceiverIsSame]);

  const handleDaumPost = (type) => {
    new (window as any).daum.Postcode({
      oncomplete: function (data) {
        const { zonecode, roadAddress, jibunAddress } = data;
        switch (type) {
          case 'customer':
            setInvoiceData((prev) => {
              return {
                ...prev,
                customerZipcode: zonecode,
                customerAddr1: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
              };
            });

            break;
          case 'receiver':
            setInvoiceData((prev) => {
              return {
                ...prev,
                receiverZipcode: zonecode,
                receiverAddr1: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
              };
            });

            break;

          default:
            break;
        }
      },
    }).open();
  };

  const gridButtonhandler = (e) => {
    if (invoiceData?.partnerSeq) {
      const id = e.target.id;
      if (id === 'removeRows') {
        //
        const items = gridRef.current.getCheckedRowItems();
        const wholeRows = gridRef.current.getGridData();
        const checked = items.map((ele) => ele.rowIndex);
        const rows = wholeRows?.filter((_, idx) => !checked?.includes(idx));
        gridRef?.current?.setGridData(rows);
      } else if (id === 'getModels') {
        //
        setPopupObj({
          visible: true,
          type: 'model',
          item: { partnerSeq: invoiceData?.partnerSeq },
        });
      }
    } else {
      alert('판매사를 선택해주세요!');
    }
  };

  const validate = (orderInfo, rows) => {
    return true;
  };
  const rowValidate = (rows) => {
    const quantityFlag = rows?.every((ele) => ele.quantity && Number(ele.quantity) > 0);
    if (quantityFlag === false) {
      alert('수량이 입력되지 않은 제품이 있습니다!');
    }
    return quantityFlag;
  };

  const registerOrderData = async (infoData) => {
    const productModels = gridRef.current.getGridData()?.map((row) => {
      return {
        ...row,
        discountRate: row.discountRate === '-' ? 0 : row.discountRate,
      };
    });
    if (validate(infoData, productModels)) {
      if (rowValidate(productModels)) {
        dispatch(setLoading('POST'));
        const rs = await serviceStore.orderAction(`ss/save`, 'POST', null, {
          ...infoData,
          productModels,
        });
        if (rs.status === 200) {
          alert('등록에 성공했습니다!');
          navigate(generateUrlByDesignType('/manageorderInfoList'));
        }
        dispatch(setLoading(null));
      }
    }
  };

  const [popupObj, setPopupObj] = useState<IPopupObj>();
  useEffect(() => {
    if (popupObj?.returns) {
      if (popupObj?.type === 'model') {
        gridRef?.current?.addRow(
          popupObj?.returns?.map((row) => {
            return {
              ...row,
              quantity: 1,
              discountRate: '-',
              discountedSellingPrice: row.sellingPrice,
              discountedSurtax: row.surtax,
              discountedTotalPrice: row.totalPrice,
            };
          }),
        );
        alert('제품이 추가되었습니다');
      }
    }
  }, [popupObj?.returns]);

  const [getVendorObj, setGetVendorObj] = useState<IGetVendorObj>();
  useEffect(() => {
    if (getVendorObj?.returns) {
      alert(`거래처를 불러왔습니다! ${getVendorObj?.returns?.[0]?.discountRate ? '(제품정보에 할인율이 일괄 반영됩니다)' : ''}`);
      setInvoiceData((prev) => {
        return {
          ...prev,
          vendorFlag: true,
          discountRate: getVendorObj?.returns?.[0]?.discountRate,
          customerName: getVendorObj?.returns?.[0]?.partnerName,
          customerPhone: getVendorObj?.returns?.[0]?.managerPhone,
          customerZipcode: getVendorObj?.returns?.[0]?.partnerName,
          customerAddr1: getVendorObj?.returns?.[0]?.address,
          customerAddr2: getVendorObj?.returns?.[0]?.addressDetail,
        };
      });
      setGetVendorObj(null);
    }
  }, [getVendorObj?.returns]);

  return (
    <>
      <div className="page-content">
        {getVendorObj?.visible && <GetVendor getVendorObj={getVendorObj} setGetVendorObj={setGetVendorObj} />}
        {popupObj?.visible && <Popup popupObj={popupObj} setPopupObj={setPopupObj} />}
        <Row style={{ textAlign: 'right' }}>
          <Col>
            <Button
              className="btn-confirm btn"
              onClick={() => {
                registerOrderData(invoiceData);
              }}
            >
              판매등록
            </Button>
          </Col>
        </Row>
        <>
          <Title>기본정보</Title>
          <Row>
            <Col>
              <label className="col-form-label">*판매사</label>
              <SelectD
                isDisabled
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                value={
                  invoiceData?.partnerSeq
                    ? {
                        value: invoiceData?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[invoiceData?.partnerSeq],
                      }
                    : null
                }
              />
            </Col>
            <Col>
              <label className="col-form-label">*주문일자</label>
              <FlatpickrD
                options={{
                  mode: 'single',
                }}
                value={invoiceData?.orderDate}
                onChange={(opt) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      orderDate: returnDateyyyymmdd(opt[0]),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*배송희망일</label>
              <FlatpickrD
                options={{
                  mode: 'single',
                }}
                onReset={() => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      promiseDeliveryDate: null,
                    };
                  });
                }}
                value={invoiceData?.promiseDeliveryDate}
                onChange={(opt) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      promiseDeliveryDate: returnDateyyyymmdd(opt[0]),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*배송희망시간대</label>
              <SelectD
                options={MASTER_OPS?.PROMISE_TIME_ZONE_1}
                value={
                  invoiceData?.promiseTimeZone
                    ? {
                        value: invoiceData?.promiseTimeZone,
                        label: MASTER_OBJ?.PROMISE_TIME_ZONE_1?.[invoiceData?.promiseTimeZone],
                      }
                    : null
                }
                onChange={(option) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      promiseTimeZone: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*송장유형</label>
              <SelectD
                options={MASTER_OPS?.SS_FEE_TYPE}
                value={
                  invoiceData?.feeType
                    ? {
                        value: invoiceData?.feeType,
                        label: MASTER_OBJ?.SS_FEE_TYPE?.[invoiceData?.feeType],
                      }
                    : null
                }
                onChange={(option) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      feeType: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">담당자</label>
              <InputD
                value={invoiceData?.managerName || ''}
                onChange={(e) => {
                  setInvoiceData({
                    ...invoiceData,
                    managerName: e.target.value,
                  });
                }}
              />
            </Col>
            <Col />
            <Col />
          </Row>
        </>
        <>
          <Title>제품정보</Title>
          <div>
            <div className="grid-button-area only-right">
              <div className="red" id="removeRows" onClick={gridButtonhandler}>
                - 행삭제
              </div>
              <div className="orange" id="getModels" onClick={gridButtonhandler}>
                + 제품등록
              </div>
            </div>
            <GridBox
              gridRef={gridRef}
              columns={columns}
              gridProps={{
                editable: true,
                height: 400,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
            />
          </div>
        </>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%', marginRight: 20, position: 'relative' }}>
            <Title>주문인정보</Title>
            <div style={{ right: 15, top: 40, position: 'absolute' }}>
              <Button
                className="btn-secondary btn"
                onClick={() => {
                  if (!invoiceData?.vendorFlag) {
                    setGetVendorObj({
                      visible: true,
                    });
                  } else {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        vendorFlag: false,
                      };
                    });
                  }
                }}
              >
                {!invoiceData?.vendorFlag ? ' 거래처 불러오기' : '거래처 해제하기'}
              </Button>
            </div>
            <Row>
              <Col>
                <label className="col-form-label">*주문인이름</label>
                <InputD
                  disabled={invoiceData?.vendorFlag}
                  value={invoiceData?.customerName || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        customerName: target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col />
            </Row>
            <Row>
              <Col>
                <label className="col-form-label">*주문인 연락처</label>
                <InputD
                  disabled={invoiceData?.vendorFlag}
                  value={invoiceData?.customerPhone || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        customerPhone: target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">주문인 연락처2</label>
                <InputD
                  value={invoiceData?.customerPhone2 || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        customerPhone2: target.value,
                      };
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="col-form-label">*주문인 주소</label>
                <Row>
                  <Col style={{ display: 'flex' }}>
                    <InputD
                      readOnly
                      disabled={invoiceData?.vendorFlag}
                      onClick={() => {
                        handleDaumPost('customer');
                      }}
                      placeholder="우편번호"
                      style={{ display: 'inline-block', width: 80, marginRight: 10, cursor: 'pointer' }}
                      value={invoiceData?.customerZipcode || ''}
                    />
                    <InputD
                      placeholder="우편번호를 클릭하세요"
                      disabled
                      value={invoiceData?.customerAddr1?.replace('null', '') || ''}
                      onChange={(e) => {
                        setInvoiceData((prev) => {
                          return {
                            ...prev,
                            customerAddr1: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <InputD
                      placeholder="상세주소를 입력하세요"
                      value={invoiceData?.customerAddr2?.replace('null', '') || ''}
                      onChange={(e) => {
                        setInvoiceData((prev) => {
                          return {
                            ...prev,
                            customerAddr2: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="col-form-label">할인율(%)</label>
                <InputD
                  placeholder="%"
                  type="number"
                  value={invoiceData?.discountRate}
                  onChange={(e) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        discountRate: Number(e.target.value),
                      };
                    });
                  }}
                />
              </Col>
              <Col />
            </Row>
          </div>
          <div style={{ width: '50%', position: 'relative' }}>
            <Title>수령인정보</Title>
            <div style={{ left: 100, position: 'absolute', top: 50 }}>
              <label style={{ marginRight: 4 }}>주문인 정보와 동일</label>
              <InputD
                type="checkbox"
                checked={customerReceiverIsSame}
                onChange={(e) => {
                  setCustomerReceiverIsSame(!customerReceiverIsSame);
                }}
              />
            </div>
            <Row>
              <Col>
                <label className="col-form-label">*수령인이름</label>
                <InputD
                  value={invoiceData?.receiverName || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        receiverName: target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col />
            </Row>
            <Row>
              <Col>
                <label className="col-form-label">*수령인연락처</label>
                <InputD
                  value={invoiceData?.receiverPhone || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        receiverPhone: target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">수령인연락처2</label>
                <InputD
                  value={invoiceData?.receiverPhone2 || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        receiverPhone2: target.value,
                      };
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="col-form-label">*수령인주소</label>
                <Row>
                  <Col style={{ display: 'flex' }}>
                    <InputD
                      readOnly
                      onClick={() => {
                        handleDaumPost('receiver');
                      }}
                      placeholder="우편번호"
                      style={{ display: 'inline-block', width: 80, marginRight: 10, cursor: 'pointer' }}
                      value={invoiceData?.receiverZipcode || ''}
                    />
                    <InputD
                      placeholder="우편번호를 클릭하세요"
                      disabled
                      value={invoiceData?.receiverAddr1?.replace('null', '') || ''}
                      onChange={(e) => {
                        setInvoiceData((prev) => {
                          return {
                            ...prev,
                            receiverAddr1: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <InputD
                      placeholder="상세주소를 입력하세요"
                      value={invoiceData?.receiverAddr2?.replace('null', '') || ''}
                      onChange={(e) => {
                        setInvoiceData((prev) => {
                          return {
                            ...prev,
                            receiverAddr2: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <>
          <Title>기타정보</Title>
          <Row>
            <Col>
              <label className="col-form-label">고객 요청사항</label>
              <textarea
                value={invoiceData?.shipmentNote || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      shipmentNote: target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">콜센터 요청사항</label>
              <textarea
                value={invoiceData?.callCenterNote || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      callCenterNote: target.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Title>설치자정보</Title>
          <Row>
            <Col>
              <label className="col-form-label">설치자이름</label>
              <InputD
                value={invoiceData?.installerName || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      installerName: target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">설치자 생년월일</label>
              <FlatpickrD
                options={{
                  mode: 'single',
                }}
                value={invoiceData?.installerBirthDate}
                onChange={(opt) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      installerBirthDate: returnDateyyyymmdd(opt[0]),
                    };
                  });
                }}
              />
            </Col>
            <Col />
            <Col />
          </Row>
        </>
      </div>
    </>
  );
};

export default Index;
