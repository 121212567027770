import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tab1 from './puchaseOrder';
import Tab2 from './purchaseInspect';
// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER, RAWDATA } from 'redux/services/menuSlice';

export const PURCHASEORDER_MASTER = [
  //
  `${RAWDATA}CENTER_WHOLE`,
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_NETWORK_AUTH',
  'SELLER_SUPPLIER_AUTH',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  'INFRA',
  //
  'BRAND',
  'PO_STATUS',
  'PO_RECEIVE',
  'LOGISTIC_TYPE',
  'WORK_TYPE',
  'MODEL_GROUP',
  'MODEL_TYPE',
  //
  'PURCHASE_INSPECT_STATUS',
  'INSPECT_CAUSE',
  'INSPECT_REASON',
  'INSPECT_PROCESS',
  //
  'SEARCH_MODEL',
  {
    SEARCH_SEQ: [
      { value: 'purchaseSeq', label: '발주번호' },
      { value: 'externalPurchaseNumber', label: '외부발주번호' },
    ],
  },
  {
    SEARCH_DATE: [
      { value: 'PurchaseDate', label: '발주일' },
      { value: 'RegisterDate', label: '등록일' },
      { value: 'PromiseDate', label: '입고예정일' },
      { value: 'FinishDate', label: '입고확정일' },
      { value: 'ReceiveDate', label: '입고마감일' },
    ],
  },
  //
  'PICK_FLAG',
  'USE_FLAG',
  'STOP_FLAG',
  'INSPECT_FLAG',
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(PURCHASEORDER_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PURCHASEORDER_TAB: <Tab1 tabId={'PURCHASEORDER_TAB'} />,
            PURCHASINSPECT_TAB: <Tab2 tabId={'PURCHASINSPECT_TAB'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
