import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { CreditLoanSearchDTO } from '../../../../_interface/parcel';

export const SearchBox = (props: ISearchBoxProps<CreditLoanSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    fetchList(searchObj);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <label className="col-form-label">판매사 (포워더)</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
              value={searchObj?.partnerSeq?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.SELLER_FORWARDER_AUTH?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: (option as OptionItem[])?.map((ele) => ele.value),
                });
              }}
            />
          </Col>
          <Col style={{ textAlign: 'left' }}>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                fetchList(searchObj);
              }}
            >
              조회
            </button>
          </Col>
          <Col />
          <Col />
          <Col />
          <Col />
        </Row>
      </Form>
    </>
  );
};
