import { httpClient } from '../http-client/axiosConfig';
import { DriverSelectListDTO } from 'interface/install';
import { PartnerListDTO } from 'interface/user';

export type OptionItem = {
  value: any;
  label: any;
  [key: string]: any;
};

export const getTimeZone = (isObj: boolean, is24 = false) => {
  let _return;
  if (isObj) {
    _return = {};
    for (let i = 0; i < (is24 ? 24 : 16); i++) {
      for (let j = 0; j < 2; j++) {
        // 두자리수 맞추기
        let h = i + 8 + '';
        let m = 30 * j + '';
        h = h.toString().length < 2 ? '0' + h : h;
        m = m.toString().length < 2 ? '0' + m : m;
        _return[`${h}:${m}`] = [];
      }
    }
  } else {
    // arr
    _return = [];
    for (let i = 0; i < (is24 ? 24 : 16); i++) {
      for (let j = 0; j < 2; j++) {
        // 두자리수 맞추기
        let h = i + (is24 ? 0 : 8) + '';
        let m = 30 * j + '';
        h = h.toString().length < 2 ? '0' + h : h;
        m = m.toString().length < 2 ? '0' + m : m;

        _return.push({
          value: `${h}:${m}`,
          label: `${h}:${m}`,
        });
      }
    }
  }
  return _return;
};

export const labelArrToObj = (arr: OptionItem[]) => {
  if (arr === null || arr === undefined || arr.length < 1) return;
  const obj: { [key: string]: string } = {};
  // const notWhole = Object.keys(arr[0])?.includes('label') ? true : false;
  // const wholeKey = Object.keys(arr[0]);
  arr?.forEach((ele) => {
    if (ele?.value !== null) {
      // if (notWhole) {
      obj[ele?.value + ''] = ele?.label;
      obj[ele?.label + ''] = ele?.value;
      // } else {
      // wholeKey?.forEach((key) => {
      //   obj[key] = ele.key;
      // });
      // }
    }
  });
  return obj;
};

export const addValueNullAtFirstPlace = (arr: OptionItem[], label = '전체') => {
  const array: OptionItem[] = [
    {
      value: null,
      label,
    },
  ];
  const returnArr = array.concat(arr);

  return returnArr;
};

export const getLabelNvalueOptions = (wholeOptions: any[], label?: string, value?: string | null) => {
  const newArr: OptionItem[] = [];
  if (wholeOptions?.length > 0) {
    wholeOptions.forEach((opt: any) => {
      if (label && value) {
        newArr.push({
          value: opt[value],
          label: opt[label],
        });
      } else {
        newArr.push({
          label: opt.codeName,
          value: opt.code,
        });
      }
    });
  }
  return newArr;
};

export const getValueLabelObjArr = (listAll: [], valueKey: string, labelKey: string) => {
  const arr = listAll.map((ele) => {
    return {
      value: ele[valueKey],
      label: ele[labelKey],
    };
  });
  return arr;
};

export const getWholeDriversforLabel = async () => {
  const rs = await httpClient.get('/install/driver/label');
  if (rs.status === 200) {
    const data = rs.data;
    const opts = data.map((ele: DriverSelectListDTO) => {
      return {
        label: ele.driverName,
        value: ele.userId,
      };
    });
    return opts;
  }
};

export const getPureSellerforLabel = async () => {
  const rs = await httpClient.get('/user/partner/seller');
  if (rs.status === 200) {
    const data = rs.data;
    const opts = data.map((ele: PartnerListDTO) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
    return opts;
  }
};

//기사정보에서 제품그룹에 따라 라벨만 보여주기
export function selectime(time: string) {
  if (time.split(' ')[1].length > 7) {
    let b = time.split('');
    b.pop();
    b.pop();
    b.pop();
    let a = b.join('');

    return a;
  }

  return time;
}
export const sellerTypesToStr = ({ seller, network, supplier, transport, forwarder }) => {
  let arr = [];
  if (seller === true) {
    arr.push('판매사');
  }
  if (network === true) {
    arr.push('위탁사');
  }
  if (supplier === true) {
    arr.push('공급사');
  }
  if (transport === true) {
    arr.push('운송사');
  }
  if (forwarder === true) {
    arr.push('포워더');
  }
  return arr.join(', ');
};

// 코드마스터에서 가져온 목록중 라벨 보이게 변환함수
export function selectlabel(val: string | number | boolean, optionarray: OptionItem[]) {
  let stringval = val + ''; // stringify
  if (stringval === 'undefined') return;
  if (optionarray !== null) {
    for (let i = 0; i < optionarray?.length; i++) {
      if (optionarray[i]?.value + '' === stringval) {
        return optionarray[i]?.label;
      }
    }
  }

  return stringval === 'null' ? '' : stringval;
}

export function selectvalue(val: string | number, optionarray: OptionItem[]) {
  let stringval;
  if (!val) return;
  if (optionarray !== null) {
    for (let i = 0; i < optionarray?.length; i++) {
      if (optionarray[i]?.label === val) {
        stringval = optionarray[i]?.value;
      }
    }
  }

  return !stringval ? val : stringval;
}

export function selectlabelForGrid(val: string, optionarray: OptionItem[]) {
  let stringval = val + ''; // stringify
  if (stringval === 'undefined') return;
  if (optionarray !== null) {
    for (let i = 0; i < optionarray?.length; i++) {
      if (optionarray[i].value + '' === stringval) {
        return optionarray[i].code;
      }
    }
  }

  return stringval === 'null' ? '' : stringval;
}

export function wholeOptionsToObj(key: string, value: any, optionArray: any[]) {
  const obj = {};
  optionArray.forEach((row) => {
    obj[row[key]] = value === 'whole' ? row : row[value];
  });
  return obj;
}

export const dayToFullDayFlag = {
  mon: 'mondayFlag',
  tue: 'tuesdayFlag',
  wed: 'wednesdayFlag',
  thu: 'thursdayFlag',
  fri: 'fridayFlag',
  sat: 'saturdayFlag',
  sun: 'sundayFlag',
};
