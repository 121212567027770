import { useEffect, useRef, useState } from 'react';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BottomSheet, IBottomSheetObj } from 'components/template/bottomSheet';
import { deliveryService } from '../../_services/service';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Index = () => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    dispatch(setLoading('GET'));
    setBottomSheetObj(null);
    const data = await deliveryService.getInventoryReturnList();
    const dataKr = data?.map((row) => {
      return {
        ...row,
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER_LABEL),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        statusKr: selectlabel(row.status, masterOptions?.REMOVE_STATUS),
        reasonKr: selectlabel(row.reason, masterOptions?.REMOVE_REASON),
      };
    });

    gridRef.current.setGridData(dataKr);
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '재고반출 일련번호',
      dataField: 'stockRemoveSeq',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '판매자',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '반출상태',
      dataField: 'statusKr',
    },
    {
      headerText: '반출사유',
      dataField: 'reasonKr',
    },
    {
      headerText: '반출대상',
      dataField: 'target',
    },
    {
      headerText: '요청수량',
      dataField: 'quantity',
    },
    {
      headerText: '확정수량',
      dataField: 'confirmQuantity',
    },
    {
      headerText: '반출요청자',
      dataField: 'registerId',
    },
    {
      headerText: '반출요청일',
      dataField: 'registerDatetime',
    },
    {
      headerText: '반출확정자',
      dataField: 'confirmId',
    },
    {
      headerText: '반출확정일',
      dataField: 'confirmDatetime',
    },
  ];

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheet bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterGridBox">
        <GridBox gridRef={gridRef} columns={columns} gridProps={{ showRowAllCheckBox: false, showRowCheckColumn: false }} />
      </div>
    </div>
  );
};

export default Index;
