import { useEffect, useRef, useState } from 'react';
import CommonModalNew from 'components/modal/commonModalNew';
import { LoadingStatementAddParcelDTO, LoadingStatementStockRemoveDTOForParcel, LoadingStatementStockRemoveDetailDTOForParcel } from 'interface/warehouse';
import { Col, Form, Row } from 'reactstrap';
import { InputD } from 'components/reactstrap/reactstrap';
import GridBox, { IPagingGrid } from 'common/grid/gridBox';

import * as IGrid from 'aui-grid';
import useToast from 'hooks/useToast';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useIsMounted } from 'hooks/basichooks';
import { delay } from 'common/util/counting';
import { takeoutService } from 'pages/OUT/2takeout/_services/service';
import { selectlabel, selectvalue } from 'common/master/codeMasterReturnHelper';
import { setLoading } from 'redux/services/menuSlice';

export const LoadingParcelPopup = ({ loadingParcelObj, setLoadingParcelObj }) => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state: RootState) => state.menu);

  const gridRef = useRef<IPagingGrid>();
  const successPlayer = useRef<HTMLAudioElement>();
  const failurePlayer = useRef<HTMLAudioElement>();

  const [loadingouts, setLoadingOuts] = useState<LoadingStatementStockRemoveDTOForParcel>();
  const [details, setDetails] = useState<LoadingStatementStockRemoveDetailDTOForParcel[]>();
  const [scanningNow, setScanningNow] = useState<LoadingStatementStockRemoveDetailDTOForParcel>();
  const scanInputRef = useRef<HTMLInputElement>(null);
  // const [successPlay, setSuccessPlay] = useState(false);
  // const [failPlay, setFailPlay] = useState(false);

  const { Toast, showToast, setColor, setMsg } = useToast({
    message: '알맞게 검수 되었습니다',
    ms: 3000,
  });

  useEffect(() => {
    fetchLoadingoutParcel();
  }, []);

  // useEffect(() => {
  //   if (successPlay) {
  //     setTimeout(() => {
  //       setSuccessPlay(false);
  //     }, 1000);
  //   }
  // }, [successPlay]);

  // useEffect(() => {
  //   if (failPlay) {
  //     setTimeout(() => {
  //       setFailPlay(false);
  //     }, 1000);
  //   }
  // }, [failPlay]);

  const isMounted = useIsMounted();
  useEffect(() => {
    void delay(1000).then(() => {
      if (isMounted) {
        scanInputRef?.current?.focus();
        gridRef.current.bind('cellEditEnd', function (e) {
          setTimeout(() => {
            scanInputRef.current.value = '';
            scanInputRef?.current?.focus();
          }, 100);
        });
      }
    });
  }, [isMounted]);

  const fetchLoadingoutParcel = async () => {
    if (loadingParcelObj?.item?.loadingStatementSeq) {
      dispatch(setLoading('GET'));
      const data: LoadingStatementStockRemoveDTOForParcel = await takeoutService.getRemoveParcel(loadingParcelObj?.item?.loadingStatementSeq);
      if (data) {
        setLoadingOuts(data);
        const rows = data?.details?.map((row: LoadingStatementStockRemoveDetailDTOForParcel) => {
          return {
            ...row,
            checkedQunatity: 0, // 검수수량: 프론트 값
            partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
          };
        });
        rows.sort(scanningSorting);
        setDetails(rows);

        if (rows?.filter((ele) => ele.quantity !== ele.loadingQuantity)?.length > 0) {
          // 단포인경우만 보여준다
          if (loadingParcelObj?.item?.stockRemoveType === 'SINGLE_PARCEL') gridRef.current.setGridData(rows);
          else gridRef.current.setGridData([]);
        } else {
          // 완료된 건은 단포, 합포 모두 리스트를 보여준다
          scanRight('검수가 모두 완료된 건입니다');
          gridRef.current.setGridData(rows);
        }
      }
      dispatch(setLoading(null));
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
      editable: false,
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
      editable: false,
    },
    {
      headerText: '제품',
      dataField: 'model',
      editable: false,
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
      editable: false,
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      editable: false,
    },
    {
      headerText: '고객명',
      dataField: 'receiverName',
      editable: false,
    },
    {
      headerText: '등록수량',
      dataField: 'quantity',
      editable: false,
    },
    {
      headerText: '확정수량',
      dataField: 'loadingQuantity',
      editable: false,
    },
    {
      headerText: '검수수량',
      dataField: 'checkedQunatity',
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        onlyNumeric: true,
        validator: function (oldValue, newValue, item) {
          if (item.quantity < newValue) {
            return { validate: false, message: '등록수량보다 많습니다' };
          }
          return { validate: true };
        },
      },
      headerTooltip: {
        show: true,
        tooltipHtml: '강제로 입력가능(숫자만)',
      },
    },
    {
      headerText: '피킹작업자',
      dataField: 'pickId',
      editable: false,
    },
    {
      headerText: '검수작업자',
      dataField: 'loadId',
      editable: false,
    },
  ];

  const Box = masterOptions?.PARCEL_BOX_BARCODE?.map((ele) => ele.label);
  const scanSingle = () => {
    const value = scanInputRef.current.value;
    // 송장번호(invoiceSeq) 거나 barcode 거나 boxType 인데 example::
    if (Box?.includes(value)) {
      endLoadingSingle(scanningNow, value);
    } else {
      const loadedParcel = details.filter((ele) => ele.barcode === value)[0];
      if (loadedParcel) {
        if (scanningNow?.invoiceSeq) {
          if (scanningNow?.invoiceSeq === loadedParcel.invoiceSeq) {
            continueScanningSingle(loadedParcel);
          } else {
            scanError('다른 상품이 스캔되었습니다!');
          }
        } else {
          setScanningNow(loadedParcel);
          continueScanningSingle(loadedParcel);
        }
      } else {
        scanError();
      }
    }
  };

  const scanRight = (str = '알맞게 검수 되었습니다') => {
    setColor('green');
    setMsg(str);
    showToast();
    successPlayer.current.play();
    scanInputRef.current.value = '';
  };

  const scanError = (str = '잘못된 검수입니다') => {
    setColor('red');
    setMsg(str);
    showToast();
    failurePlayer.current.play();
    scanInputRef.current.value = '';
  };

  // 3PL_NUMBER_1
  const endLoadingSingle = (nowScanning: LoadingStatementStockRemoveDetailDTOForParcel, value: string) => {
    const rows = gridRef.current.getGridData();
    const parcelBoxType = selectvalue(value, masterOptions?.PARCEL_BOX_BARCODE);
    // 확정되지 않은것중에 스캔중인것
    const data = rows?.filter((ele) => ele.loadingQuantity === 0 && ele.checkedQunatity !== 0 && ele?.modelStockSeq === nowScanning?.modelStockSeq); // 검수하지 않은것
    if (data?.length > 0) {
      const dataDTO: LoadingStatementAddParcelDTO = {
        loadingStatementSeq: loadingouts.loadingStatementSeq,
        parcelBoxType,
        details: [
          {
            ...nowScanning,
            quantity: data[0].checkedQunatity,
          },
        ],
      };
      setScanningNow((prev) => {
        return {
          ...prev,
          parcelBoxType: value,
        };
      });
      postRemoveLoadingParcel(dataDTO);
    } else {
      scanError('검수완료할 품목이 없습니다');
    }
  };

  //3PL_NUMBER_1
  const endLoadingMultiple = (value: string) => {
    console.log(value);
    const rows = gridRef.current.getGridData();
    const parcelBoxType = selectvalue(value, masterOptions?.PARCEL_BOX_BARCODE);
    if (rows?.filter((ele) => ele.checkedQunatity === 0)?.length > 0) {
      scanError('검수 수량이 0인 제품이 있습니다');
    } else {
      const data = rows?.filter((ele) => ele.quantity > ele.checkedQunatity); // 검수를 0으로도 찍을수 있다.
      const dataDTO: LoadingStatementAddParcelDTO = {
        loadingStatementSeq: loadingouts.loadingStatementSeq,
        parcelBoxType,
        details: rows.map((row) => {
          return {
            ...row,
            quantity: row.checkedQunatity,
          };
        }),
      };
      if (data?.length > 0) {
        if (window.confirm('검수가 진행중인 제품이 있습니다. 운송장을 출력하시겠습니까?')) {
          postRemoveLoadingParcel(dataDTO);
        }
      } else {
        postRemoveLoadingParcel(dataDTO);
      }
    }
  };

  const postRemoveLoadingParcel = async (dataDTO: LoadingStatementAddParcelDTO) => {
    const rs = await takeoutService.postRemoveParcel(dataDTO);
    if (rs?.status === 200) {
      scanRight('검수가 완료되었습니다');
      setScanningNow(null);
      fetchLoadingoutParcel();
    }
  };

  const continueScanningSingle = (loadedParcel: LoadingStatementStockRemoveDetailDTOForParcel) => {
    const rows = gridRef.current.getGridData();
    let idx = -1;
    let data;
    rows?.forEach((ele, i) => {
      if (ele.barcode === loadedParcel.barcode && idx === -1 && ele.quantity > ele?.checkedQunatity && ele.loadingQuantity === 0) {
        data = ele;
        idx = i;
      }
    });

    if (idx !== -1) {
      data.checkedQunatity += 1;
      const updates = rows.map((row, i) => {
        if (i === idx) return data;
        else return row;
      });
      gridRef.current.setGridData(updates);
      scanRight();
    } else {
      scanError('검수가 완료된 제품입니다');
    }
  };

  // 합포일때
  const continueScanningMultiple = (barcode: string) => {
    const rows = gridRef.current.getGridData();
    if (rows?.filter((ele) => ele.quantity !== ele.loadingQuantity)?.length > 0) {
      let idx = -1;
      let data;
      rows?.forEach((ele, i) => {
        ele.justScanned = null;
        if (idx === -1 && ele.barcode === barcode) {
          idx = i;
          if (ele.quantity > ele.checkedQunatity) data = ele;
        }
      });
      if (data && idx !== -1) {
        data.checkedQunatity += 1;
        const updates = rows?.map((row, i) => {
          row.justScanned = barcode;
          if (i === idx) return data;
          else return row;
        });

        updates.sort(scanningSorting);
        const lefts = updates.filter((ele) => ele.quantity !== ele.checkedQunatity);
        if (lefts?.length === 0) {
          scanRight('검수가 완료되었습니다 박스를 스캔해세요');
        } else {
          scanRight();
        }

        setScanningNow(data);
        gridRef.current.setGridData(updates);
      } else if (idx === -1) {
        scanError('해당바코드의 제품이 없습니다');
      } else {
        scanError('해당 제품은 스캔이 완료되었습니다');
      }
    } else {
      setScanningNow(null);
      scanError('검수가 완료된 송장입니다');
    }
  };

  const scanningSorting = (a, b) => {
    // 1순위: 검수상태
    if (a.barcode === a.justScanned) return -1;
    if (a.quantity === a.loadingQuantity) return 1; // 뒤로
    if (a.quantity === a.checkedQunatity) return -1;
    // 2순위.
    return b.modelStockSeq - a.modelStockseq;
  };

  const scanMultiple = () => {
    const value = scanInputRef.current.value;
    const rows = gridRef.current.getGridData();
    if (Box?.includes(value)) {
      endLoadingMultiple(value);
    } else {
      // 기본정보에 송장이없다 :: 송장스캔 가능
      if (!scanningNow?.invoiceSeq) {
        if (value === 'blacksheepwall') {
          if (rows?.length === 0) gridRef.current.setGridData(details);
          else gridRef.current.setGridData([]);
        } else {
          // 확정수량이 없는 건이 있다
          const rows = details.filter((ele) => ele.invoiceSeq + '' === value);
          rows.sort(scanningSorting);
          if (rows?.length > 0) {
            gridRef.current.setGridData(rows);
            if (rows.filter((ele) => ele.quantity !== ele.loadingQuantity)?.length) {
              const { invoiceSeq, orderSeq, model, modelName, barcode, modelStockSeq, quantity } = rows[0];
              setScanningNow({
                invoiceSeq,
                orderSeq,
                // 사실 아래는 필수가 아님
                model,
                modelName,
                barcode,
                modelStockSeq,
                quantity,
              });
              scanRight('송장이 스캔되었습니다');
            } else {
              setScanningNow(null); // reset
              scanRight('확정처리가 완료된 송장입니다!');
            }
          } else {
            gridRef.current.setGridData([]);
            setScanningNow(null); // reset
            scanError('송장정보가 없습니다!');
          }
        }
      } else {
        // 상품 바코드 스캔
        if (rows?.filter((ele) => ele.loadingQuantity !== ele.quantity)?.length > 0) {
          continueScanningMultiple(value);
        } else {
          scanError('검수가 완료된 송장입니다!');
        }
      }
    }
  };

  const fetchScanList = () => {
    if (loadingParcelObj?.item?.stockRemoveType === 'SINGLE_PARCEL') scanSingle();
    else scanMultiple();
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchScanList();
    }
  };

  return (
    <CommonModalNew
      style={{ width: 1200 }}
      title={`택배검수${loadingParcelObj?.item?.stockRemoveType === 'SINGLE_PARCEL' ? '(단포)' : '(합포)'}`}
      visible={loadingParcelObj?.visible}
      setVisible={() => {
        setLoadingParcelObj(null);
      }}
      rightTitle={
        <>
          <Toast />
        </>
      }
      children={
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <div className="photo-zone" style={{ width: 200, height: 150 }}>
              <span className="bck-font">사 진</span>
            </div>

            <div style={{ width: 880 }}>
              <span>1. 기본정보</span>

              <Form onKeyPress={onKeyPress}>
                <Row>
                  <Col lg={2}>
                    <label className="col-form-label">반출출고번호</label>
                    <InputD disabled readOnly value={loadingouts?.loadingStatementSeq || ''} />
                  </Col>
                  <Col lg={2}>
                    <label className="col-form-label">주문번호</label>
                    <InputD disabled readOnly value={scanningNow?.orderSeq || ''} />
                  </Col>
                  <Col lg={2}>
                    <label className="col-form-label">송장번호</label>
                    <InputD disabled readOnly value={scanningNow?.invoiceSeq || ''} />
                  </Col>
                  <Col lg={2}>
                    <label className="col-form-label">반출유형</label>
                    <InputD value={masterOptions?.REMOVE_REASON_OBJ?.[loadingouts?.stockRemoveType] || ''} disabled readOnly />
                  </Col>
                  <Col lg={2}>
                    <label className="col-form-label">반출대상</label>
                    <InputD value={loadingouts?.target || ''} disabled readOnly />
                  </Col>
                  <Col lg={2}>
                    <label className="col-form-label">주문자명</label>
                    <InputD value={scanningNow?.receiverName || ''} disabled readOnly />
                  </Col>
                </Row>
                <Row>
                  <Col lg={2}>
                    <label className="col-form-label">박스호수</label>
                    {/* label */}
                    <InputD value={scanningNow?.parcelBoxType || ''} disabled readOnly />
                  </Col>
                  <Col>
                    <label className="col-form-label">주소</label>
                    <InputD value={scanningNow?.receiverAddr1 + scanningNow?.receiverAddr2 || ''} disabled readOnly />
                  </Col>
                  <Col>
                    <label className="col-form-label">제품비고</label>
                    <InputD
                      // value={scanningNow?. || ''}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <label className="col-form-label">SCAN</label>
                    <input ref={scanInputRef} className="form-control backgroundYellow" placeholder="주문서의 송장번호 또는 제품의 바코드를 스캔해주세요" />
                  </Col>
                  <Col lg={8}>
                    <label className="col-form-label">진행률</label>
                    <span style={{ display: 'block', position: 'relative' }}>
                      <span style={{ position: 'absolute', width: '100%', textAlign: 'center', zIndex: 3, top: 4 }}>
                        {loadingouts?.loadingQuantity} / {loadingouts?.quantity} [ {loadingouts?.loadingQuantity === 0 ? 0 : ((loadingouts?.loadingQuantity / loadingouts?.quantity) * 100).toFixed(2)}{' '}
                        % ]
                      </span>
                      <InputD
                        placeholder=" "
                        wrapperstyle={{ position: 'absolute', display: 'inline-block', width: '100%' }}
                        className="backgroundOrange"
                        style={{
                          position: 'absolute',
                          zIndex: 2,
                          display: `${loadingouts?.loadingQuantity === 0 ? 'none' : 'block'}`,
                          width: `${loadingouts?.loadingQuantity === 0 ? 0 : (loadingouts?.loadingQuantity / loadingouts?.quantity) * 100}%`,
                        }}
                      />
                      <InputD className="backgroundWhite" placeholder=" " disabled readOnly />
                    </span>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>

          <div style={{ width: '100%' }}>
            <span style={{ display: 'inline-block', margin: '10px 0' }}>2. 출고 상품내역</span>
            <GridBox
              gridRef={gridRef}
              columns={columns}
              gridProps={{
                height: 400,
                editable: true,
                showRowAllCheckBox: false,
                showRowCheckColumn: false,
                rowStyleFunction: function (rowIndex, item) {
                  if (item.barcode === item.justScanned) return 'here-row-style'; // 스캔중
                  if (item?.loadingQuantity === item?.quantity) return 'complete-row-style';
                  return '';
                },
              }}
            />
          </div>
          <div className="audioWrapper">
            <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_sound.mp3" ref={successPlayer} controls></audio>
            <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_fail_sound.mp3" ref={failurePlayer} controls></audio>
          </div>
        </>
      }
    />
  );
};
