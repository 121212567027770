import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './customerCs';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER, RAWDATA } from 'redux/services/menuSlice';

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      //
      `${RAWDATA}DRIVER_WHOLE`,
      'DRIVER_AUTH',
      'DRIVER_WHOLE',
      'DRIVER_PHONE_WHOLE', // 라벨용이 많으니
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'CENTER_CALL_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_NETWORK_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      'SALES_CHANNEL',
      //
      'ORDER_TYPE',
      `${RAWDATA}ORDER_TYPE`,
      'INVOICE_STATUS',
      'FEE_TYPE',
      'FEE_TYPE',
      'CANCEL_REASON',
      'COLLECT_REASON',
      'SERVICE_TYPE',
      'MODEL_TYPE',
      'MODEL_GROUP',
      'RESCHEDULE_TYPE',
      'BODY_WIFI_REASON',
      'EOL_GROUP2',
      `${RAWDATA}EOL_GROUP2`,
      'EOL_GROUP3',
      'COUNSEL_DETAIL_TYPE',
      'COUNSEL_TYPE',
      'LADDER_CO',
      'MODEL_GRP_1',
      'MODEL_GRP_2',
      'MODEL_GRP_3',
      'CANCEL_TYPE',
      'MESSAGE_TYPE',
      'INVENTORY_LOCATION',
      'RAWDATA_PROVINCE',
      'CITY',
      'DRIVER_PARTY',
      'TEAM_GRADE',
      'DRV_POS',
      'CAR_TYPE',
      'DRV_TYPE',
      'TEAM_TYPE',
      'LOGI_TYPE',
      'PACKING_STATE',
      'INV_TRACK_TYPE',
      'PARCEL_PAY_TYPE',
      'INVOICE_UPDATE_CONTENT',
      'INSTALL_FORM',
      //
      'SEARCH_ORDER',
      'SEARCH_INVOICE',
      'SEARCH_MODEL',
      //
      'INSTALL_DURATION',
      'DELIVERY_TIME',
      'UNIFORM_SIZE',
      'WEEK_DAY',
      'OX_FLAG',
      'USE_FLAG',
      'LITE_FLAG',
      'FIX_DATE',
      {
        SEARCH_DATE: [
          {
            label: '고객주문일',
            value: 'OrderDate',
          },
          {
            label: '등록일',
            value: 'RegisterDate',
          },
          {
            label: '희망일',
            value: 'PromiseDeliveryDate',
          },
          {
            label: '지정일',
            value: 'DeliveryDate',
          },
          {
            label: '배송확정일',
            value: 'DeliveryConfirmDate',
          },
          {
            label: '완료일/취소일',
            value: 'FinishOrCancelDate',
          },
        ],
      },
      {
        PMODEL_TYPE: [
          { value: 'MODEL', label: '구성품' },
          { value: 'GIFT', label: '사은품' },
        ],
      },
      {
        SEARCH_PRODUCT: [
          { value: 'productName', label: '상품명' },
          { value: 'productSeq', label: '상품번호' },
          { value: 'externalProductNumber', label: '외부상품번호' },
        ],
      },
    ];
    initMasterOptions(masterArr);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            CUSTOMERCS: <Tab1 tabId={'CUSTOMERCS'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
