import { useRef, useState } from 'react';
import { XLSX, checkHTML5Brower, fixdata, parseCvs, process_wb, rABS } from 'common/util/fileUploader';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';

interface useExcelProps {
  keyKrObj: { [key: string]: string };
}

const useExcelHooks = () => {
  const dispatch = useDispatch();

  const fileRef = useRef<HTMLInputElement>();
  const [parsedData, setParsedData] = useState<{ id: string; header: string[]; data: string[] }>();

  const uploaderClick = (id: string) => {
    fileRef.current.id = id;
    fileRef.current.click();
  };

  const parseExcelFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLoading('GET'));
    if (!checkHTML5Brower()) {
      alert('브라우저가 HTML5 를 지원하지 않습니다.\r\n서버로 업로드해서 해결하십시오.');
      return;
    }
    const id = e.target.id;
    const file = (e.target as HTMLInputElement).files[0];
    if (typeof file === 'undefined') {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      /* 엑셀 바이너리 읽기 */
      let workbook;
      if (rABS) {
        // 일반적인 바이너리 지원하는 경우
        workbook = XLSX.read(data, { type: 'binary' });
      } else {
        // IE 10, 11인 경우
        var arr = fixdata(data);
        workbook = XLSX.read(Buffer.from(arr, 'base64'), { type: 'base64' });
      }

      var jsonObj = process_wb(workbook);

      if (jsonObj[Object.keys(jsonObj)[0]]) {
        const parseExcelDataArr = parseCvs(jsonObj[Object.keys(jsonObj)[0]]);
        const header = parseExcelDataArr[0];
        const parsedData = parseExcelDataArr?.filter((_, idx) => idx > 0);
        console.log(parseExcelDataArr);
        setParsedData({ id, header, data: parsedData });
      }
    };

    reader.onloadend = () => {
      (e.target as HTMLInputElement).value = '';
      (e.target as HTMLInputElement).id = '';
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  return {
    ExcelDetector: () => {
      return <input accept=".xlsx" type="file" ref={fileRef} style={{ display: 'none' }} onChange={parseExcelFiles} />;
    },
    uploaderClick,
    parsedData,
  };
};

export default useExcelHooks;
