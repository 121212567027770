import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { InventoryModelMoveDTO, InventoryModelMoveSearchDTO } from 'interface/warehouse';
import { inventoryService } from '../../_service/service';
import { IlabelObj, LabelPopup } from './component/labelPopup';
import { useNavigate } from 'react-router-dom';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';

const Index = ({ tabId }) => {
  const history = useNavigate();
  const [labelObj, setLabelObj] = useState<IlabelObj>();
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);

  const columns: IGrid.Column[] = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '변경전 제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'beforeModelGroupKr',
        },
        {
          headerText: '제품',
          dataField: 'beforeModel',
        },
        {
          headerText: '제품명',
          dataField: 'beforeModelName',
        },
        {
          headerText: 'SKU',
          dataField: 'beforeModelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'beforeSkuNumber',
        },

        {
          headerText: '바코드',
          dataField: 'beforeBarcode',
        },
      ],
    },
    {
      headerText: '변경후 제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'afterModelGroupKr',
        },
        {
          headerText: '제품',
          dataField: 'afterModel',
        },
        {
          headerText: '제품명',
          dataField: 'afterModelName',
        },
        {
          headerText: 'SKU',
          dataField: 'afterModelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'afterSkuNumber',
        },

        {
          headerText: '바코드',
          dataField: 'afterBarcode',
        },
      ],
    },
    {
      headerText: '재고상태',
      dataField: 'availableStatusKr',
    },
    {
      headerText: '메모',
      dataField: 'memo',
    },
    {
      headerText: '변경전 시리얼번호',
      dataField: 'beforeSerialNumber',
    },
    {
      headerText: '변경후 시리얼번호',
      dataField: 'afterSerialNumber',
    },
    {
      headerText: '전환자',
      dataField: 'registerId',
    },
    {
      headerText: '전환일시',
      dataField: 'registerDatetime',
    },
  ];

  const labellingKr = (data: PagingListDTO<InventoryModelMoveDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        beforeModelGroupKr: selectlabel(row.beforeModelGroup, masterOptions?.MODEL_GROUP),
        afterModelGroupKr: selectlabel(row.afterModelGroup, masterOptions?.MODEL_GROUP),
        availableStatusKr: selectlabel(row.availableStatus, masterOptions?.STATUS),
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryModelMoveSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await inventoryService.getInventoryMoveListPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: await inventoryService.getInventoryMoveExcelMain(searchObj) }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '재고품목전환' });
    dispatch(setLoading(null));
  };

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'INVENTORY_MOVE_CREATE') {
      currentDesignType === 1 ? history('/main/modelMoveList/modelMoveRegit') : history('/modelMoveRegit');
    } else if (id === 'INVENTORY_MOVE_EXCELDOWN') {
      downloadExcel('main');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {labelObj?.visible && <LabelPopup labelObj={labelObj} setLabelObj={setLabelObj} />}

      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
