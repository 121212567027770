import { getToday, returnDateyyyymmdd, returnYoil, weekArrKr } from 'common/util/dateParsingFn';
import { httpClient } from 'common/http-client/axiosConfig';
// 공공데이터 포털 특일정보
//https://www.data.go.kr/iim/api/selectAPIAcountView.do

const SERVICE_KEY = `y6nlRqK4ZR6o9vdA%2BCP1ejkGtjQ7SLo9%2BjrEK6iHyyc7ZprJ01ik8dTQBIwD8FThtwXITv9bFRIlVFNpXi8UAw%3D%3D`;

const HOLYDAY_API = `https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getRestDeInfo`;
const RESTDAY_API = `https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getRestDeInfo`;

export const returnHolidayInthisMonth = async (year: number, month: number) => {
  await httpClient.get(`${HOLYDAY_API}?serviceKey=${SERVICE_KEY}&solYear=${year}&solMonth=${month}`).then((rs) => {
    if (rs?.status === 200) {
      //
      return rs?.data;
    }
  });
};
export const returnRestdayInthisMonth = async (year: number, month: number) => {
  await httpClient.get(`${RESTDAY_API}?serviceKey=${SERVICE_KEY}&solYear=${year}&solMonth=${month}`).then((rs) => {
    if (rs?.status === 200) {
      //
      return rs?.data;
    }
  });
};
export const diffDate = (strDate: string, standardDate: string) => {
  const targetDate = new Date(strDate.split(' ')[0]);
  const today = new Date(standardDate);
  let diff = parseInt(today.getTime() - targetDate.getTime() + '');
  diff = Math.floor(diff / (1000 * 60 * 60 * 24));
  return diff;
};

export const diffDateFromToday = (strDate: string) => {
  const targetDate = new Date(strDate.split(' ')[0]);
  const today = new Date(getToday());
  let diff = Math.abs(today.getTime() - targetDate.getTime());
  diff = Math.ceil(diff / (1000 * 60 * 60 * 24));
  // console.log(targetDate, ' ', today, ':', diff);
  return diff;
};

export const pickThisWeek = (date0: Date | string) => {
  const diffToStart = weekArrKr.indexOf(returnYoil(returnDateyyyymmdd(date0)));
  const diffToEnd = 6 - diffToStart;
  const dateDup0 = new Date(date0);
  const dateDup1 = new Date(date0);
  const op0 = new Date(dateDup0.setDate(dateDup0.getDate() - diffToStart));
  const op1 = new Date(dateDup1.setDate(dateDup1.getDate() + diffToEnd));

  return [returnDateyyyymmdd(op0), returnDateyyyymmdd(op1)];
};
