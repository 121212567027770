import CommonModalNew from 'components/modal/commonModalNew';
import { InventoryModificationDefectedListDTO, InventoryModificationDefectedPrintDTO, InventoryModificationListDTO, InventoryModificationPrintDTO } from 'interface/warehouse';
import { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import { inventoryService } from '../../_service/service';
import Barcode from 'react-barcode';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export interface IPrintObj {
  item: InventoryModificationListDTO | InventoryModificationDefectedListDTO;
  visible: boolean;
}

const PrintWrapper = styled.div({
  position: 'relative',
  padding: 20,
  paddingBottom: 100,
  fontSize: 15,
  width: '100%',
  height: '100%',

  fontWeight: 800,
  marginBottom: 10,
});
export const ModificationPrint = ({ printObj, setPrintObj }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);

  const wrapperRef = useRef<HTMLDivElement>();
  const [data, setData] = useState<InventoryModificationDefectedPrintDTO[] | InventoryModificationPrintDTO[]>();
  useEffect(() => {
    if (printObj?.visible && printObj?.item) {
      fetchData();
    }
  }, [printObj?.visible]);

  const fetchData = async () => {
    let action = `modification/${printObj?.item?.inventoryModificationSeq ? '' : 'defected/'}print?`;
    action += !printObj?.item?.inventoryModificationSeq
      ? `inventoryModificationDefectedSeq=${printObj?.item?.inventoryModificationDefectedSeq}`
      : `inventoryModificationSeq=${printObj?.item?.inventoryModificationSeq}`;
    const data = await inventoryService?.getInventoryAction(null, action);
    if (data) {
      setData(data);
    }
  };

  return (
    <CommonModalNew
      style={{ width: 1000 }}
      title={`${printObj?.item?.inventoryModificationSeq ? '양품' : '불용'}재고 조정 출력물`}
      subTitle={`${printObj?.item?.inventoryModificationSeq ? '양품' : '불용'}재고 조정번호: ${
        printObj?.item?.inventoryModificationSeq ? printObj?.item?.inventoryModificationSeq : printObj?.item?.inventoryModificationDefectedSeq
      }`}
      visible={printObj?.visible}
      setVisible={() => {
        setPrintObj(null);
      }}
      rightTitle={
        <>
          <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
        </>
      }
      children={
        <>
          <PrintWrapper ref={wrapperRef}>
            {data?.map((ele, idx) => {
              return (
                <div key={`print_${idx}`} className="break-for-onePage-print">
                  <div style={{ padding: 50 }}>
                    <div style={{ fontSize: 40, fontWeight: 700, textAlign: 'center', marginBottom: 50 }}>재고조정</div>
                    <table className="border-table">
                      <tbody>
                        <tr>
                          <th>불용코드</th>
                          <td colSpan={5}>{ele?.defectedCode && <Barcode width={1.5} value={ele?.defectedCode} height={40} />}</td>
                          <th>바코드</th>
                          <td colSpan={5}>{ele?.barcode && <Barcode width={1.5} value={ele?.barcode} height={40} />}</td>
                        </tr>
                        <tr>
                          <th>공급사</th>
                          <td colSpan={2}>{selectlabel(ele.supplierPartnerSeq, masterOptions?.SUPPLIER)}</td>
                          <th>판매사</th>
                          <td colSpan={2}>{selectlabel(ele.partnerSeq, masterOptions?.SELLER)}</td>
                          <th>브랜드</th>
                          <td colSpan={2}>{selectlabel(ele.brand, masterOptions?.BRAND)}</td>
                          <th>S/N</th>
                          <td colSpan={2}></td>
                        </tr>
                        <tr>
                          <th>제품그룹</th>
                          <td colSpan={2}>{selectlabel(ele.modelGroup, masterOptions?.MODEL_GROUP)}</td>
                          <th>제품타입</th>
                          <td colSpan={2}>{selectlabel(ele.modelType, masterOptions?.MODEL_TYPE)}</td>
                          <th>
                            분류
                            <br />
                            (대/중/소)
                          </th>
                          <td colSpan={5}>
                            {selectlabel(ele.modelGroupLarge, masterOptions?.MODEL_1)} / {selectlabel(ele.modelGroupMedium, masterOptions?.MODEL_2)} /{' '}
                            {selectlabel(ele.modelGroupSmall, masterOptions?.MODEL_3)}
                          </td>
                        </tr>
                        <tr>
                          <th>제품명</th>
                          <td colSpan={5}>{ele.modelName}</td>
                          <th>제품</th>
                          <td colSpan={5}>{ele.model}</td>
                        </tr>
                        <tr>
                          <th>
                            제품상태
                            <br />
                            (전/후)
                          </th>
                          <td colSpan={5}>
                            {selectlabel(ele.beforeAvailableStatus, masterOptions?.INVA_STATUS) + '-' + selectlabel(ele.beforeAvailableType, masterOptions?.INVA_TYPE)} {' ⇒ '}
                            {selectlabel(ele.afterAvailableStatus, masterOptions?.INVA_STATUS) + '-' + selectlabel(ele.afterAvailableType, masterOptions?.INVA_TYPE)}
                          </td>
                          <th>불용사유</th>
                          <td colSpan={5}>{ele.reason}</td>
                        </tr>
                        <tr>
                          <th>비고</th>
                          <td colSpan={5}>{ele.note}</td>
                          <th>조정요청자</th>
                          <td colSpan={5}>{ele.requestId}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          </PrintWrapper>
        </>
      }
    />
  );
};
