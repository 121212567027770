import { useEffect, useState, useMemo } from 'react';
import { INVENTORY_MODIFICATION_ALL, INVENTORY_MODIFICATION_DEF_SAVE } from 'envVar';
import Presenter from './presenter';
import { httpClient } from 'common/http-client/axiosConfig';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setLoading } from 'redux/services/menuSlice';
import { v4 as uuidv4 } from 'uuid';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { generateUrlByDesignType } from 'components/LDS/utils/path';

export const defaultModelSearchObj = {
  visible: false,
  data: [],
  returns: null,
};

export const statusAfterOptions = [
  { value: 'DEFECTED/COLLECT', label: '불용-회수입고' },
  { value: 'DEFECTED/REFUR', label: '불용-리퍼대상' },
  { value: 'DEFECTED/SCRAP', label: '불용-폐기대상' },
  { value: 'DEFECTED/MANUF', label: '불용-반출대상' },
  { value: 'DEFECTED/INVESTIGATE', label: '불용-재고조사' },
  { value: 'AVAILABLE/AVAILABLE', label: '양품' },
];

export const reasonOptions = [
  { value: '초도불량', label: '초도불량' },
  { value: '간선중파손', label: '간선중파손' },
  { value: '창고내파손', label: '창고내파손' },
  { value: '배송중파손', label: '배송중파손' },
  { value: '직접입력', label: '>비고란에 직접입력' },
];

const Container = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);
  const { reduxUserInfo } = useSelector((state) => state.auth);

  const [gridId, setGrid] = useState();
  const [rows, setRows] = useState([]);
  const [modelSearchObj, setModelSearchObj] = useState(defaultModelSearchObj);

  const defaultSearchFilter = useMemo(() => {
    return {
      centerCode: reduxUserInfo['centerCode'], // 창고
      partnerSeq: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[reduxUserInfo['partner']] ? reduxUserInfo['partner'] : null, // 판매사
    };
  }, [reduxUserInfo]);

  const [searchObj, setSearchObj] = useState({
    locationCode: null,
    partnerSeq: null,
    modelName: null,
    barcode: null,
    modelStockSeq: null,
    ...defaultSearchFilter,
  });

  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'delete-row') {
        deleteCheckedRows(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  useEffect(() => {
    if (modelSearchObj?.returns) {
      const items = window.AUIGrid.getGridData(`#${gridId}`);
      modelSearchObj?.returns?.forEach((row) => {
        new Array(Number(row.quantity)).fill(0)?.forEach((_, idx) => {
          items.push({
            ...row,
            partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
            centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
          });
        });
      });
      alert('추가되었습니다!');
      setRows(items);
    }
  }, [modelSearchObj?.returns]);

  const goRegister = async () => {
    const items = window.AUIGrid.getGridData(`#${gridId}`);
    const data = [];
    items.forEach((item) => {
      const obj = {
        centerCode: item.centerCode,
        locationCode: item.locationCode,
        modelStockSeq: item.modelStockSeq,
        serialNumber: item.serialNumber,
        quantity: item.quantity,
        beforeAvailableStatus: 'DEFECTED',
        beforeAvailableType: item.beforeState,
        afterAvailableStatus: item.afterState.split('/')[0],
        afterAvailableType: item.afterState.split('/')[1],
      };
      if (item.reason) obj.reason = item.reason;
      if (item.note) obj.note = item.note;

      data.push(obj);
    });

    dispatch(setLoading('POST'));
    const res = await httpClient.post(INVENTORY_MODIFICATION_DEF_SAVE, data);
    if (res?.status === 200) {
      alert(`${items?.length}건에 대하여 불용재고조정 신청이 완료되었습니다!`);
      history(generateUrlByDesignType('/modifications'));
    }
    dispatch(setLoading(null));
  };

  const onCellClick = (e) => {
    if (e.dataField === 'pass') {
      setDiff(e);
    }
  };

  const deleteCheckedRows = (items) => {
    const rows = window.AUIGrid.getGridData(`#${gridId}`);
    if (rows?.length > 0) {
      const checkedRows = [];
      items.forEach((item) => {
        checkedRows.push(item.rowIndex);
      });
      const _new = [];
      rows.forEach((row, idx) => {
        if (!checkedRows.includes(idx)) _new.push(row);
      });
      setRows(_new);
    }
  };

  const columns = [
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          editable: false,
        },
        {
          headerText: '제품',
          dataField: 'model',
          editable: false,
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          editable: false,
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          editable: false,
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          editable: false,
        },
      ],
    },
    {
      headerText: '로케이션정보',
      children: [
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
          editable: false,
        },
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          editable: false,
        },
      ],
    },
    {
      headerText: '*재고조정(입력부분)',
      children: [
        {
          headerText: '*변경전 상태값',
          dataField: 'beforeState',
          width: 100,
          renderer: {
            type: 'DropDownListRenderer',
            listFunction: function (rowIndex, columnIndex, item, dataField) {
              const list = [];
              if (item.refurbishQuantity > 0) list.push({ value: 'REFUR', label: '불용-리퍼대상' });
              if (item.collectQuantity > 0) list.push({ value: 'COLLECT', label: '불용-회수입고' });
              if (item.scrapQuantity > 0) list.push({ value: 'SCRAP', label: '불용-폐기대상' });
              if (item.manufactureQuantity > 0) list.push({ value: 'MANUF', label: '불용-반출대상' });
              if (item.investigateQuantity > 0) list.push({ value: 'INVESTIGATE', label: '불용-재고조사' });
              console.log(list);
              return list;
            },
            keyField: 'value', // key 에 해당되는 필드명
            valueField: 'label', // value 에 해당되는 필드명
          },
        },
        {
          headerText: '*변경후 상태값',
          dataField: 'afterState',
          width: 100,
          renderer: {
            type: 'DropDownListRenderer',
            list: statusAfterOptions,
            listFunction: function (rowIndex, columnIndex, item, dataField) {
              const list = statusAfterOptions.filter((ele) => !ele.value.includes(item.beforeState));
              return list;
            },
            keyField: 'value', // key 에 해당되는 필드명
            valueField: 'label', // value 에 해당되는 필드명
          },
        },
        {
          headerText: '*조정사유',
          dataField: 'reason',
          width: 150,
          renderer: {
            type: 'DropDownListRenderer',
            list: reasonOptions,
            keyField: 'value', // key 에 해당되는 필드명
            valueField: 'label', // value 에 해당되는 필드명
          },
        },
        {
          headerText: "비고(조정사유 '직접입력'시에만)",
          dataField: 'note',
          width: 250,
          editable: true,
        },
        {
          headerText: '시리얼번호',
          dataField: 'serialNumber',
          editable: true,
        },
      ],
    },
  ];

  useEffect(() => {
    setGrid(`inventoryControlAvailableRegit_${uuidv4()}`);
  }, []);

  useEffect(() => {
    if (modelSearchObj?.returns) {
      window.AUIGrid.addRow(`#${gridId}`, { ...modelSearchObj?.returns }, 'last');
      setModelSearchObj({
        ...modelSearchObj,
        returns: null,
      });
    }
  }, [modelSearchObj?.returns]);

  const setDiff = (e) => {
    const row = { ...e.item };
    const rowIndex = e.rowIndex;
    const beforeState = row['beforeState'];
    const afterState = row['afterState'].split('/');

    const keyObj = {
      REFUR: ['refurbishQuantity', 'afterRefurbishQuantity'],
      SCRAP: ['scrapQuantity', 'afterScrapQuantity'],
      MANUF: ['manufactureQuantity', 'afterManufactureQuantity'],
      COLLECT: ['collectQuantity', 'afterCollectQuantity'],
      INVESTIGATE: ['investigateQuantity', 'afterInvestigateQuantity'],
      AVAILABLE: ['availableQuantity', 'afterAvailableQuantity'],
    };

    if (!row['beforeState']) {
      alert('"*변경전 상태값"을 입력하세요!');
    } else if (!row['afterState']) {
      alert('"*변경후 상태값"을 입력하세요!');
    } else if (beforeState === afterState[1]) {
      alert('"*변경전 상태값"과 "*변경후 상태값"은 같을 수 없습니다!');
    } else if (!row['reason']) {
      alert('"*조정사유"를 입력하세요!');
    } else if (row['reason'] === '>비고란에 직접입력' && !row['note']) {
      alert('"비고"란에 조정사유를 수기로 입력하세요!');
    } else {
      try {
        if (row['quantity'] > 0) {
          if (row['note']) row['reason'] = reasonOptions[4].value;
          row['whatChanged'] = afterState[1];
          console.log(keyObj[beforeState][1], keyObj[beforeState][0], keyObj[afterState[1]][1], keyObj[afterState[1]][0]);
          row[keyObj[beforeState][1]] = row[keyObj[beforeState][0]] - row['quantity'];
          row[keyObj[afterState[1]][1]] = row[keyObj[afterState[1]][0]] + row['quantity'];
          row['passFlag'] = true;
          console.log(row);
          window.AUIGrid.updateRow(`#${gridId}`, row, rowIndex);
        }
      } catch (error) {
        row['passFlag'] = false;
        window.AUIGrid.updateRow(`#${gridId}`, row, rowIndex);
      }
    }
  };

  const paramingSearchObjToUrl = (url) => {
    if (searchObj?.centerCode) url += `&centerCode=${searchObj?.centerCode}`;
    if (searchObj?.locationCode) url += `&locationCode=${searchObj?.locationCode}`;
    if (searchObj?.partnerSeq) url += `&partnerSeq=${searchObj?.partnerSeq}`;
    if (searchObj?.modelName?.trim()) url += `&modelName=${searchObj?.modelName}`;
    if (searchObj?.barcode) url += `&barcode=${searchObj?.barcode}`;
    if (searchObj?.modelStockSeq) url += `&modelStockSeq=${searchObj?.modelStockSeq}`;
    return url;
  };

  const fetchList = async () => {
    dispatch(setLoading('GET'));
    let url = INVENTORY_MODIFICATION_ALL + '?';
    url = paramingSearchObjToUrl(url);

    httpClient.get(url).then((res) => {
      let data = [];
      if (res?.status === 200) {
        data = res.data;
        setModelSearchObj({
          ...modelSearchObj,
          visible: true,
          data,
        });
      } else {
        alert('잘못된 접근입니다');
      }
      dispatch(setLoading(null));
    });
  };

  return (
    <Presenter
      gridId={gridId}
      rows={rows}
      setRows={setRows}
      columns={columns}
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      fetchList={fetchList}
      onCellClick={onCellClick}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      goRegister={goRegister}
      modelSearchObj={modelSearchObj}
      setModelSearchObj={setModelSearchObj}
    />
  );
};

export default Container;
