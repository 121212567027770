import { useEffect } from 'react';
import { Col, Row, Form } from 'reactstrap';
import { ISearchBoxProps } from 'hooks/grid/useSearch';
// redux
import { MenuSearchDTO } from 'interface/user';

export const SearchBox = (props: ISearchBoxProps<MenuSearchDTO>) => {
  const { searchObj, setSearchObj, fetchList } = props;

  useEffect(() => {
    fetchList(searchObj);
  }, [searchObj?.useFlag]);

  const handleFixedFlag = (e) => {
    const id = e.target.id;
    if (id) {
      setSearchObj((prev) => {
        return {
          ...prev,
          useFlag: id === 'whole' ? null : id === 'true' ? true : false,
        };
      });
    }
  };

  return (
    <Form>
      <Row>
        <Col>
          <label className="col-form-label">사용 여부</label>
          <div className="radio-select-area">
            <span id="whole" className={!searchObj?.useFlag ? 'active' : ''} onClick={handleFixedFlag}>
              전체
            </span>
            <span id="true" className={searchObj?.useFlag === true ? 'active' : ''} onClick={handleFixedFlag}>
              사용중
            </span>
            <span id="false" className={searchObj?.useFlag === false ? 'active' : ''} onClick={handleFixedFlag}>
              미사용중
            </span>
          </div>
        </Col>
        <Col />
        <Col />
        <Col />
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
