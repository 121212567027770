import CommonModalNew from 'components/modal/commonModalNew';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';
import GridBox from 'common/grid/gridBox';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { serviceStore } from 'services/services';
import Divider from 'components/LDS/atoms/Divider';
import { InventoryQuantityInfoDTO, InventoryQuantityInfoSearchDTO } from 'interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';
import { Col, Form, Row } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { resetAllSelections, toggleBooleanOptions } from 'common/util/searchBox';
import { useFrontSearchData } from 'hooks/grid/useFrontSearchData';

export interface IhangdangObj {
  visible?: boolean;
  items?: any;
  target?: any;
}

export const InventoryDetailModal = ({ detailObj, setDetailObj }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();
  const columns: IGrid.Column[] = [
    {
      headerText: '재고번호',
      dataField: 'inventorySeq',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '재고상태',
      dataField: 'availableStatusKr',
    },
    {
      headerText: '재고유형',
      dataField: 'availableTypeKr',
    },
    {
      headerText: '이동상태',
      dataField: 'moveStatusKr',
    },
    {
      headerText: '로케이션코드',
      dataField: 'locationCode',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '수령인',
      dataField: 'receiverName',
    },
    {
      headerText: '수령인 연락처',
      dataField: 'receiverPhone',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '배송기사',
      dataField: 'driverNameKr', //driverName을 그대로 사용하지 않고 userId를 라벨링 처리하여 적용
    },
    {
      headerText: '기사소속센터',
      dataField: 'driverCenterCodeKr',
    },
    {
      headerText: '지정일',
      dataField: 'deliveryDate',
    },
    {
      headerText: '간선피킹번호',
      dataField: 'linehaulSeq',
    },
    {
      headerText: '반출피킹번호',
      dataField: 'stockRemoveSeq',
    },
    {
      headerText: '출고번호',
      dataField: 'loadingStatementSeq',
    },
    {
      headerText: '운송번호',
      dataField: 'transportStatementSeq',
    },
    {
      headerText: 'FC피킹번호',
      dataField: 'loanPickSeq',
    },
    {
      headerText: '기사출고번호',
      dataField: 'loanSeq',
    },
    {
      headerText: '자재출고번호',
      dataField: 'loanComponentSeq',
    },
    {
      headerText: '출발센터',
      dataField: 'originalCenterCodeKr',
    },
    {
      headerText: '도착센터',
      dataField: 'destinationCenterCodeKr',
    },
    {
      headerText: '입고예정일',
      dataField: 'promiseUnloadDate',
    },
  ];

  const convertKeyValue = {
    customerAddr: (row) => (row?.customerAddr1 ? `${row?.customerAddr1} ${row?.customerAddr2 || ''}` : ''),
    centerCodeKr: (row) => selectlabel(row?.centerCode, masterOptions?.CENTER_WHOLE),
    partnerSeqKr: (row) => selectlabel(row?.partnerSeq, masterOptions?.SELLER_WHOLE),
    modelGroupKr: (row) => selectlabel(row?.modelGroup, masterOptions?.MODEL_GROUP),
    unloadFlagKr: (row) => (row?.unloadFlag === true ? 'O' : 'X'),
    orderCancelFlagKr: (row) => (row?.orderCancelFlag === true ? 'O' : 'X'),
    pickFlagKr: (row) => (row?.pickFlag === true ? 'O' : 'X'),
    loadFlagKr: (row) => (row?.loadFlag === true ? 'O' : 'X'),
    availableStatusKr: (row) => selectlabel(row?.availableStatus, masterOptions?.['INVA_STATUS']),
    availableTypeKr: (row) => selectlabel(row?.availableType, masterOptions?.['INVA_TYPE']),
    feeTypeKr: (row) => selectlabel(row?.feeType, masterOptions?.['FEE_TYPE']),
    driverCenterCodeKr: (row) => selectlabel(row?.driverCenterCode, masterOptions?.['CENTER_WHOLE']),
    originalCenterCodeKr: (row) => selectlabel(row?.originalCenterCode, masterOptions?.['CENTER_WHOLE']),
    destinationCenterCodeKr: (row) => selectlabel(row?.destinationCenterCode, masterOptions?.['CENTER_WHOLE']),
    driverNameKr: (row) => selectlabel(row?.userId, masterOptions?.DRIVER_WHOLE),
    moveStatusKr: (row) => selectlabel(row?.moveStatus, masterOptions?.INVM_STATUS),
  };

  const detailSelectType = { SEARCH_NUMBER: null, SEARCH_SEQ: null, SEARCH_TABLE_SEQ: null };
  const { setGridData, setDetailSelectTypeObj, handleSubmitFiltering, detailSelectTypeObj, detailSearchObj, setDetailSearchObj, convertFieldValue, returnKeyValues, resetFilters } = useFrontSearchData(
    {
      gridRef,
      initDetailSearchObj: {},
      detailSelectType,
      convertKeyValue,
    },
  );

  useEffect(() => {
    fetchInventoryDetailInfo();
  }, []);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.SEARCH_NUMBER, detailSearchObj));
  }, [detailSelectTypeObj?.SEARCH_NUMBER]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.SEARCH_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.SEARCH_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.SEARCH_TABLE_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.SEARCH_TABLE_SEQ]);

  const handleCheckItems = (e) => {
    const id = e.target.id;
    if (id === 'excel') {
      gridRef.current.exportAsXlsx({ fileName: '재고정보' });
    }
  };
  console.log(detailSearchObj);

  const fetchInventoryDetailInfo = async () => {
    const searchObj = { ...detailObj?.items };
    delete searchObj['searchObj'];
    delete searchObj['_$uid'];
    searchObj[`${detailObj?.target}Flag`] = true;

    dispatch(setLoading('GET'));
    const data = (await serviceStore.warehouseAction(`inventory/quantity/info`, 'GET', searchObj as InventoryQuantityInfoSearchDTO))?.data as InventoryQuantityInfoDTO[];
    if (data) {
      setGridData(data);
      const dataKr = data?.map((row) => {
        let keyValues = returnKeyValues(convertKeyValue, row);

        return {
          ...row,
          ...keyValues,
        };
      });

      if (dataKr?.length > 0) {
        gridRef?.current?.setGridData(dataKr);
      } else {
        alert('재고정보가 없습니다!');
        setDetailObj(null);
      }
    }
    dispatch(setLoading(null));
  };

  return (
    <>
      <CommonModalNew
        title={`재고정보`}
        style={{ width: 1400 }}
        subTitle={
          <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, flexWrap: 'wrap', rowGap: '2px' }}>
            <div>
              <strong>창고</strong> : {detailObj?.items?.centerCodeKr || '-'}
            </div>{' '}
            <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <div>
              <strong>판매사</strong> : {detailObj?.items?.partnerSeqKr || '-'}
            </div>{' '}
            <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <div>
              <strong>제품</strong> : {detailObj?.items?.model || '-'}
            </div>{' '}
            <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <div>
              <strong>제품명</strong> : {detailObj?.items?.modelName || '-'}
            </div>{' '}
            <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <div>
              {' '}
              <strong>SKU</strong> : {detailObj?.items?.modelStockSeq || '-'}
            </div>{' '}
            <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <div>
              <strong>외부SKU</strong> : {detailObj?.items?.skuNumber || '-'}
            </div>{' '}
            <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <div>
              <strong>대표상품코드</strong> : {detailObj?.items?.productCode || '-'}
            </div>{' '}
            <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <div>
              <strong>바코드</strong> : {detailObj?.items?.barcode || '-'}
            </div>
          </div>
        }
        visible={detailObj?.visible}
        setVisible={() => {
          setDetailObj(null);
        }}
        children={
          <>
            <Form onSubmit={handleSubmitFiltering}>
              <Row>
                <Col>
                  <label className="col-form-label">재고번호/시리얼번호</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={4} style={{ display: 'inline-block' }}>
                      <SelectD
                        options={masterOptions?.SEARCH_NUMBER}
                        value={
                          detailSelectTypeObj?.SEARCH_NUMBER
                            ? {
                                label: selectlabel(detailSelectTypeObj?.SEARCH_NUMBER, masterOptions?.SEARCH_NUMBER),
                                value: detailSelectTypeObj?.SEARCH_NUMBER,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setDetailSelectTypeObj({
                            ...detailSelectTypeObj,
                            SEARCH_NUMBER: (option as OptionItem).value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={8} style={{ display: 'inline-block' }}>
                      <InputD
                        disabled={!detailSelectTypeObj?.SEARCH_NUMBER}
                        value={detailSearchObj?.[detailSelectTypeObj?.SEARCH_NUMBER] || ''}
                        onChange={(e) => {
                          setDetailSearchObj({
                            ...detailSearchObj,
                            [detailSelectTypeObj?.SEARCH_NUMBER]: convertFieldValue(detailSelectTypeObj['SEARCH_NUMBER'], e.target.value),
                          });
                        }}
                      />
                    </Col>
                  </div>
                </Col>
                <Col>
                  <label className="col-form-label">주문번호/송장번호</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={4} style={{ display: 'inline-block' }}>
                      <SelectD
                        options={masterOptions?.SEARCH_SEQ}
                        value={
                          detailSelectTypeObj?.SEARCH_SEQ
                            ? {
                                label: selectlabel(detailSelectTypeObj?.SEARCH_SEQ, masterOptions?.SEARCH_SEQ),
                                value: detailSelectTypeObj?.SEARCH_SEQ,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setDetailSelectTypeObj({
                            ...detailSelectTypeObj,
                            SEARCH_SEQ: (option as OptionItem).value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={8} style={{ display: 'inline-block' }}>
                      <InputD
                        disabled={!detailSelectTypeObj?.SEARCH_SEQ}
                        value={detailSearchObj?.[detailSelectTypeObj?.SEARCH_SEQ] || ''}
                        onChange={(e) => {
                          setDetailSearchObj({
                            ...detailSearchObj,
                            [detailSelectTypeObj?.SEARCH_SEQ]: convertFieldValue(detailSelectTypeObj['SEARCH_SEQ'], e.target.value),
                          });
                        }}
                      />
                    </Col>
                  </div>
                </Col>
                <Col>
                  <label className="col-form-label">작업지시번호</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={4} style={{ display: 'inline-block' }}>
                      <SelectD
                        options={masterOptions?.SEARCH_TABLE_SEQ}
                        value={
                          detailSelectTypeObj?.SEARCH_TABLE_SEQ
                            ? {
                                label: selectlabel(detailSelectTypeObj?.SEARCH_TABLE_SEQ, masterOptions?.SEARCH_TABLE_SEQ),
                                value: detailSelectTypeObj?.SEARCH_TABLE_SEQ,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setDetailSelectTypeObj({
                            ...detailSelectTypeObj,
                            SEARCH_TABLE_SEQ: (option as OptionItem).value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={8} style={{ display: 'inline-block' }}>
                      <InputD
                        disabled={!detailSelectTypeObj?.SEARCH_TABLE_SEQ}
                        value={detailSearchObj?.[detailSelectTypeObj?.SEARCH_TABLE_SEQ] || ''}
                        onChange={(e) => {
                          setDetailSearchObj({
                            ...detailSearchObj,
                            [detailSelectTypeObj?.SEARCH_TABLE_SEQ]: convertFieldValue(detailSelectTypeObj['SEARCH_TABLE_SEQ'], e.target.value),
                          });
                        }}
                      />
                    </Col>
                  </div>
                </Col>
                <Col>
                  <label className="col-form-label">로케이션코드</label>
                  <InputD
                    value={detailSearchObj?.locationCode || ''}
                    onChange={(e) => {
                      setDetailSearchObj({
                        ...detailSearchObj,
                        locationCode: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">기사</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={6} style={{ display: 'inline-block' }}>
                      <SelectD
                        options={masterOptions?.DRIVER_AUTH}
                        value={
                          detailSearchObj?.userId
                            ? {
                                value: detailSearchObj?.userId,
                                label: selectlabel(detailSearchObj?.userId, masterOptions?.DRIVER_AUTH),
                              }
                            : null
                        }
                        onChange={({ value }) => {
                          setDetailSearchObj({
                            ...detailSearchObj,
                            userId: value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={6} style={{ display: 'inline-block' }}>
                      <InputD disabled value={detailSearchObj?.userId || ''} placeholder="기사를 선택하세요" />
                    </Col>
                  </div>
                </Col>
                <Col style={{ textAlign: 'right', placeSelf: 'flex-end', display: 'flex', gap: '4px' }}>
                  <button type="button" className="btn" style={{ border: '1px solid #DADADA', minWidth: '80px' }} onClick={resetFilters}>
                    초기화
                  </button>
                  <button type="submit" className="btn btn-search" style={{ flexGrow: 1 }}>
                    조회
                  </button>
                </Col>
              </Row>
            </Form>
            <Divider $direction={'H'} $color={'#eaeaea'} $spacing={'LG'} $value={'FULL'} />
            <div className="grid-button-area">
              <div className="green" id="excel" onClick={handleCheckItems}>
                엑셀다운
              </div>
            </div>
            <GridBox
              //
              gridRef={gridRef}
              columns={columns}
              gridProps={{ height: 500, enableCellMerge: true }}
            />
          </>
        }
      />
    </>
  );
};
