import { useEffect, useState } from 'react';
import { Col, Row, Button } from 'reactstrap';

// redux
import { useSelector } from 'react-redux';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { httpClient } from 'common/http-client/axiosConfig';
import { RootState } from 'redux/store';
// component
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { ProductView } from './productView';

import { TransactionStatementDTO } from 'interface/order';

import { categoryChanger } from '../../FEE/3transactionStatement';
import { numberThousandComma } from 'common/util/counting';
import { generateUrlByDesignType } from 'components/LDS/utils/path';
import { Title } from 'pages/ORDER/1order/manageorderRegisterTab/manageorderRegister';
import { RAWDATA } from 'redux/services/menuSlice';

const StatementRegister = () => {
  const [infoData, setInfoData] = useState<TransactionStatementDTO>();
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const params = useParams();
  const history = useNavigate();

  useEffect(() => {
    if (params?.seq && params?.seq !== '0') {
      fetchInfoData(params?.seq);
    }
  }, []);
  const fetchInfoData = async (seq: string) => {
    setInfoData(null);
    await httpClient.get(`/order/transaction/statement/${categoryChanger('/' + params?.from)}/detail?transactionStatementSeq=${seq}`).then((rs) => {
      if (rs?.status === 200) {
        setInfoData(rs?.data);
      }
    });
  };

  const createStatement = async (data: TransactionStatementDTO) => {
    await httpClient.post(`/order/transaction/statement/${categoryChanger('/' + params?.from)}/create`, data).then((rs) => {
      if (rs?.status === 200) {
        alert('거래명세서가 생성되었습니다!');
        history(generateUrlByDesignType(`/transactionRegister${'/' + params?.from}/${rs?.data?.data}`));
        fetchInfoData(rs?.data?.data); //return transactionStatementSeq
      }
    });
  };

  const saveDetailStatement = async (data: TransactionStatementDTO) => {
    await httpClient.post(`/order/transaction/statement/${categoryChanger('/' + params?.from)}/detail/save`, data).then((rs) => {
      if (rs?.status === 200) {
        alert('저장되었습니다!');
        fetchInfoData(params?.seq);
      }
    });
  };

  const handleDaumPost = (type: 'provider' | 'receiver') => {
    new (window as any).daum.Postcode({
      oncomplete: function (data: any) {
        const { zonecode, roadAddress, jibunAddress } = data;
        switch (type) {
          case 'provider':
            setInfoData({
              ...infoData,
              providerOfficeZipcode: zonecode,
              providerOfficeAddress: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
            });
            break;
          case 'receiver':
            setInfoData({
              ...infoData,
              receiverOfficeZipcode: zonecode,
              receiverOfficeAddress: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
            });
            break;

          default:
            break;
        }
      },
    }).open();
  };

  return (
    <>
      <div className="page-content" style={{ overflow: 'auto', padding: '3rem' }}>
        <Row style={{ textAlign: 'right', margin: '50px 0' }}>
          <Col style={{ display: 'flex', alignItems: 'center' }}>{infoData?.transactionStatementSeq && <span> 일련번호 : {infoData?.transactionStatementSeq}</span>}</Col>
          <Col style={{ textAlign: 'center' }}>
            <span style={{ fontSize: 30 }}>거래명세서</span>
          </Col>
          <Col>
            <Button
              className="btn btn-confirm"
              onClick={() => {
                if (params?.seq === '0') {
                  createStatement(infoData);
                } else {
                  saveDetailStatement(infoData);
                }
              }}
            >
              {params?.seq === '0' ? '생성하기' : '저장하기'}
            </Button>
          </Col>
        </Row>
        {categoryChanger('/' + params?.from) === 'HEADQUATERS' && (
          <Row>
            <Col>
              <label className="col-form-label">*판매사</label>
              <SelectD
                isDisabled={params?.seq !== '0'}
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                value={
                  infoData?.partnerSeq
                    ? {
                        value: infoData?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[infoData?.partnerSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setInfoData((prev) => {
                    return {
                      ...prev,
                      partnerSeq: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            <Col />
            <Col />
            <Col />
            <Col />
            <Col>
              <label className="col-form-label">상태값</label>
              <SelectD
                isDisabled
                options={MASTER_OPS?.TS_STATUS}
                value={
                  infoData?.status
                    ? {
                        value: infoData?.status,
                        label: MASTER_OBJ?.TS_STATUS?.[infoData?.status],
                      }
                    : null
                }
                onChange={(option) => {
                  setInfoData((prev) => {
                    return {
                      ...prev,
                      status: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
        )}

        {categoryChanger('/' + params?.from) === 'SUBCONTRACT' && (
          <Row>
            <Col>
              <label className="col-form-label">*판매사</label>
              <SelectD
                isDisabled={params?.seq !== '0'}
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                value={
                  infoData?.partnerSeq
                    ? {
                        value: infoData?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[infoData?.partnerSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setInfoData((prev) => {
                    return {
                      ...prev,
                      partnerSeq: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*인프라</label>
              <SelectD
                isDisabled={params?.seq !== '0'}
                options={MASTER_OPS?.INFRA}
                value={
                  infoData?.infraSeq
                    ? {
                        value: infoData?.infraSeq,
                        label: MASTER_OBJ?.INFRA?.[infoData?.infraSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setInfoData((prev) => {
                    return {
                      ...prev,
                      infraSeq: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            <Col />
            <Col />
            <Col />
            <Col>
              <label className="col-form-label">상태값</label>
              <SelectD
                isDisabled
                options={MASTER_OPS?.TS_STATUS}
                value={
                  infoData?.status
                    ? {
                        value: infoData?.status,
                        label: MASTER_OBJ?.TS_STATUS?.[infoData?.status],
                      }
                    : null
                }
                onChange={(option) => {
                  setInfoData((prev) => {
                    return {
                      ...prev,
                      status: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
        )}

        {categoryChanger('/' + params?.from) === 'DRIVER' && (
          <Row>
            <Col>
              <label className="col-form-label">*판매사</label>
              <SelectD
                isDisabled={params?.seq !== '0'}
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                value={
                  infoData?.partnerSeq
                    ? {
                        value: infoData?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[infoData?.partnerSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setInfoData((prev) => {
                    return {
                      ...prev,
                      partnerSeq: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*창고</label>
              <SelectD
                isDisabled={params?.seq !== '0'}
                options={MASTER_OPS?.CENTER_AUTH}
                value={
                  infoData?.centerCode
                    ? {
                        value: infoData?.centerCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[infoData?.centerCode],
                      }
                    : null
                }
                onChange={(option) => {
                  setInfoData((prev) => {
                    return {
                      ...prev,
                      centerCode: (option as OptionItem).value,
                      userId: null,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*기사</label>
              <div style={{ display: 'flex' }}>
                <Col md={6} style={{ display: 'inline-block' }}>
                  <SelectD
                    isDisabled={params?.seq !== '0'}
                    options={MASTER_OPS?.[`${RAWDATA}DRIVER_AUTH`]
                      ?.filter((ele) => ele.centerCode === infoData?.centerCode)
                      ?.map((ele) => {
                        return {
                          value: ele?.userId,
                          label: ele?.driverName,
                        };
                      })}
                    value={
                      infoData?.userId
                        ? {
                            value: infoData?.userId,
                            label: MASTER_OBJ?.DRIVER_AUTH?.[infoData?.userId],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setInfoData((prev) => {
                        return {
                          ...prev,
                          userId: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
                <Col md={6} style={{ display: 'inline-block' }}>
                  <InputD disabled value={infoData?.userId || ''} placeholder="기사를 선택하세요" />
                </Col>
              </div>
            </Col>
            <Col />
            <Col />
            <Col>
              <label className="col-form-label">상태값</label>
              <SelectD
                isDisabled
                options={MASTER_OPS?.TS_STATUS}
                value={
                  infoData?.status
                    ? {
                        value: infoData?.status,
                        label: MASTER_OBJ?.TS_STATUS?.[infoData?.status],
                      }
                    : null
                }
                onChange={(option) => {
                  setInfoData((prev) => {
                    return {
                      ...prev,
                      status: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
        )}

        {categoryChanger('/' + params?.from) === 'CENTER' && (
          <Row>
            <Col>
              <label className="col-form-label">*판매사</label>
              <SelectD
                isDisabled={params?.seq !== '0'}
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                value={
                  infoData?.partnerSeq
                    ? {
                        value: infoData?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[infoData?.partnerSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setInfoData((prev) => {
                    return {
                      ...prev,
                      partnerSeq: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*창고</label>
              <SelectD
                isDisabled={params?.seq !== '0'}
                options={MASTER_OPS?.CENTER_AUTH}
                value={
                  infoData?.centerCode
                    ? {
                        value: infoData?.centerCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[infoData?.centerCode],
                      }
                    : null
                }
                onChange={(option) => {
                  setInfoData((prev) => {
                    return {
                      ...prev,
                      centerCode: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            <Col />
            <Col />
            <Col />
            <Col>
              <label className="col-form-label">상태값</label>
              <SelectD
                isDisabled
                options={MASTER_OPS?.TS_STATUS}
                value={
                  infoData?.status
                    ? {
                        value: infoData?.status,
                        label: MASTER_OBJ?.TS_STATUS?.[infoData?.status],
                      }
                    : null
                }
                onChange={(option) => {
                  setInfoData((prev) => {
                    return {
                      ...prev,
                      status: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Title>공급자</Title>
          </Col>
          <Col>
            <Title>공급받는자</Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">사업자번호</label>
            <InputD
              value={infoData?.providerBusinessNumber || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    providerBusinessNumber: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">상호</label>
            <InputD
              value={infoData?.providerCompanyName || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    providerCompanyName: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">대표자명</label>
            <InputD
              value={infoData?.providerName || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    providerName: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">사업자번호</label>
            <InputD
              value={infoData?.receiverBusinessNumber || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    receiverBusinessNumber: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">상호</label>
            <InputD
              value={infoData?.receiverCompanyName || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    receiverCompanyName: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">대표자명</label>
            <InputD
              value={infoData?.receiverName || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    receiverName: e.target.value,
                  };
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <label className="col-form-label">사업장주소</label>
            <Row>
              <Col style={{ display: 'flex' }}>
                <InputD
                  readOnly
                  onClick={() => {
                    handleDaumPost('provider');
                  }}
                  placeholder="우편번호"
                  style={{ display: 'inline-block', width: 80, marginRight: 10, cursor: 'pointer' }}
                  value={infoData?.providerOfficeZipcode || ''}
                />
                <InputD wrapperstyle={{ width: '100%' }} placeholder="우편번호를 클릭하세요" disabled value={infoData?.providerOfficeAddress || ''} readOnly />
              </Col>
              <Col>
                <InputD
                  placeholder="상세주소를 입력하세요"
                  value={infoData?.providerOfficeAddressDetail || ''}
                  onChange={(e) => {
                    setInfoData((prev) => {
                      return {
                        ...prev,
                        providerOfficeAddressDetail: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col />
          <Col lg={4}>
            <label className="col-form-label">사업장주소</label>
            <Row>
              <Col style={{ display: 'flex' }}>
                <InputD
                  readOnly
                  onClick={() => {
                    handleDaumPost('receiver');
                  }}
                  placeholder="우편번호"
                  style={{ display: 'inline-block', width: 80, marginRight: 10, cursor: 'pointer' }}
                  value={infoData?.receiverOfficeZipcode || ''}
                />
                <InputD placeholder="우편번호를 클릭하세요" disabled wrapperstyle={{ width: '100%' }} value={infoData?.receiverOfficeAddress || ''} readOnly />
              </Col>
              <Col>
                <InputD
                  placeholder="상세주소를 입력하세요"
                  value={infoData?.receiverOfficeAddressDetail || ''}
                  onChange={(e) => {
                    setInfoData((prev) => {
                      return {
                        ...prev,
                        receiverOfficeAddressDetail: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">업태</label>
            <InputD
              value={infoData?.providerIndustry || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    providerIndustry: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">종목</label>
            <InputD
              value={infoData?.providerProduct || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    providerProduct: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col />
          <Col>
            <label className="col-form-label">업태</label>
            <InputD
              value={infoData?.receiverIndustry || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    receiverIndustry: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">종목</label>
            <InputD
              value={infoData?.receiverProduct || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    receiverProduct: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col />
        </Row>

        <Title>명세서정보</Title>
        <Row>
          <Col>
            <label className="col-form-label">*작성일</label>
            <FlatpickrD
              options={{ mode: 'single' }}
              value={infoData?.issueDate}
              onReset={() => {
                setInfoData({
                  ...infoData,
                  issueDate: null,
                });
              }}
              onChange={(value) => {
                setInfoData({
                  ...infoData,
                  issueDate: returnDateyyyymmdd(value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">*시작일</label>
            <FlatpickrD
              options={{ mode: 'single' }}
              value={infoData?.startDate}
              onReset={() => {
                setInfoData({
                  ...infoData,
                  startDate: null,
                });
              }}
              onChange={(value) => {
                setInfoData({
                  ...infoData,
                  startDate: returnDateyyyymmdd(value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">*종료일</label>
            <FlatpickrD
              options={{ mode: 'single' }}
              value={infoData?.endDate}
              onReset={() => {
                setInfoData({
                  ...infoData,
                  endDate: null,
                });
              }}
              onChange={(value) => {
                setInfoData({
                  ...infoData,
                  endDate: returnDateyyyymmdd(value),
                });
              }}
            />
          </Col>
          <Col lg={6}>
            <label className="col-form-label">비고란</label>
            <InputD
              value={infoData?.remark || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    remark: e.target.value,
                  };
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">발급일</label>
            <FlatpickrD
              disabled
              options={{ mode: 'single' }}
              value={infoData?.registerDate}
              onReset={() => {
                setInfoData({
                  ...infoData,
                  registerDate: null,
                });
              }}
              onChange={(value) => {
                setInfoData({
                  ...infoData,
                  registerDate: returnDateyyyymmdd(value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">발급아이디</label>
            <InputD
              disabled
              value={infoData?.registerId || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    registerId: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">확정일</label>
            <FlatpickrD
              disabled
              options={{ mode: 'single' }}
              value={infoData?.confirmDate}
              onReset={() => {
                setInfoData({
                  ...infoData,
                  confirmDate: null,
                });
              }}
              onChange={(value) => {
                setInfoData({
                  ...infoData,
                  confirmDate: returnDateyyyymmdd(value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">확정아이디</label>
            <InputD
              disabled
              value={infoData?.confirmId || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    confirmId: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">전기일</label>
            <FlatpickrD
              disabled
              options={{ mode: 'single' }}
              value={infoData?.postingDate}
              onReset={() => {
                setInfoData({
                  ...infoData,
                  postingDate: null,
                });
              }}
              onChange={(value) => {
                setInfoData({
                  ...infoData,
                  postingDate: returnDateyyyymmdd(value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">전기아이디</label>
            <InputD
              disabled
              value={infoData?.postingId || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    postingId: e.target.value,
                  };
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">지급일</label>
            <FlatpickrD
              disabled
              options={{ mode: 'single' }}
              value={infoData?.payDate}
              onReset={() => {
                setInfoData({
                  ...infoData,
                  payDate: null,
                });
              }}
              onChange={(value) => {
                setInfoData({
                  ...infoData,
                  payDate: returnDateyyyymmdd(value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">지급아이디</label>
            <InputD
              disabled
              value={infoData?.payId || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    payId: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">취소일</label>
            <FlatpickrD
              disabled
              options={{ mode: 'single' }}
              value={infoData?.cancelDate}
              onReset={() => {
                setInfoData({
                  ...infoData,
                  cancelDate: null,
                });
              }}
              onChange={(value) => {
                setInfoData({
                  ...infoData,
                  cancelDate: returnDateyyyymmdd(value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">취소아이디</label>
            <InputD
              disabled
              value={infoData?.cancelId || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    cancelId: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">이메일</label>
            <InputD
              value={infoData?.email || ''}
              onChange={(e) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    email: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">이메일 전송 여부</label>
            <SelectD
              isDisabled
              options={MASTER_OPS?.FLAG}
              value={
                infoData?.emailSendFlag
                  ? {
                      value: infoData?.emailSendFlag,
                      label: MASTER_OBJ?.FLAG?.[infoData?.emailSendFlag as any],
                    }
                  : null
              }
              onChange={(option) => {
                setInfoData((prev) => {
                  return {
                    ...prev,
                    emailSendFlag: (option as OptionItem).value,
                  };
                });
              }}
            />
          </Col>
        </Row>

        <ProductView infoData={infoData} setInfoData={setInfoData} />

        <Title>합계정보</Title>
        <Row>
          <Col>
            <label className="col-form-label">수량</label>
            <InputD disabled value={numberThousandComma(infoData?.quantity) || ''} />
          </Col>
          <Col>
            <label className="col-form-label">공급가액</label>
            <InputD disabled value={numberThousandComma(infoData?.supplyValue) || ''} />
          </Col>
          <Col>
            <label className="col-form-label">부가세</label>
            <InputD disabled value={numberThousandComma(infoData?.valueAddedTax) || ''} />
          </Col>
          <Col>
            <label className="col-form-label">합계금액</label>
            <InputD disabled value={numberThousandComma(infoData?.total) || ''} />
          </Col>
          <Col />
          <Col />
        </Row>
      </div>
    </>
  );
};

export default StatementRegister;
