import { COLORS, SIZES, TRANSITIONS } from 'common/constants/appearance';
import styled from 'styled-components';
import { LDSTitle, LDSSubTitle, LDSLabelXS, LDSLabelSM } from './Typography';
import { useTooltip } from '../hooks/useTooltip';
import LDSTooltip, { TooltipProps } from '../modules/Tooltip';
import { calculateElementPositionAndSize } from 'common/util/handleElement';
import LDSDropdown, { DropdownProps } from '../layouts/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setShowDropdown } from 'redux/services/portalSlice';

type ProfileProps = {
  $size: 'XS' | 'SM' | 'MD' | 'XL';
  $type: 'USER' | 'ICON' | 'IMAGE' | 'BADGE';
  $badgeType?: 'ICON' | 'LABEL';
  $icon?: React.ReactNode;
  $imageUrl?: string;
  $label?: string;
  $name?: string;
  $interactive?: boolean;
  $color?: 'PRIMARY' | 'ALERT' | 'WARNING' | 'BLUE' | 'SUCCESS' | 'PURPLE' | 'NEUTRAL';
  $tooltip?: Omit<TooltipProps, '$data'>;
  $dropdown?: Omit<DropdownProps, '$data'>;
};

export default function LDSProfile({ $size, $label, $name, $type, $icon, $badgeType, $imageUrl, $tooltip, $interactive, $dropdown, ...props }: ProfileProps) {
  const { showTooltip, setShowTooltip, targetRef } = useTooltip();
  const dispatch = useDispatch();
  const { showDropdown } = useSelector((state: RootState) => state.portal);

  return (
    <>
      <LDSProfileStyle
        ref={targetRef}
        className={$interactive && showDropdown === $tooltip?.$description && $dropdown ? 'selected' : ''}
        $size={$size}
        $type={$type}
        $badgeType={$badgeType}
        $interactive={$interactive}
        {...props}
        onMouseOverCapture={() => $tooltip && $interactive && setShowTooltip(true)}
        onMouseOutCapture={() => $tooltip && $interactive && setShowTooltip(false)}
        onMouseDownCapture={() => $tooltip && $interactive && setShowTooltip(false)}
        onMouseUpCapture={() => $dropdown && !showDropdown && dispatch(setShowDropdown($label || $tooltip?.$description))}
      >
        {($type === 'USER' || ($type === 'BADGE' && $badgeType === 'LABEL')) && ($size === 'MD' || $size === 'XL') ? (
          <LDSTitle>{$type === 'USER' ? $name : $label}</LDSTitle>
        ) : $type === 'USER' && $size === 'SM' ? (
          <LDSSubTitle>{$name}</LDSSubTitle>
        ) : $type === 'BADGE' && $badgeType === 'LABEL' && $size === 'SM' ? (
          <LDSLabelSM $weight={'BOLD'}>{$label}</LDSLabelSM>
        ) : ($type === 'USER' || ($type === 'BADGE' && $badgeType === 'LABEL')) && $size === 'XS' ? (
          <LDSLabelXS $weight={'SEMIBOLD'}>{$type === 'USER' ? $name : $label}</LDSLabelXS>
        ) : (
          <></>
        )}
        {($type === 'ICON' || ($type === 'BADGE' && $badgeType === 'ICON')) && $icon}
        {$type === 'IMAGE' && <img src={$imageUrl} alt={'Profile'} />}
      </LDSProfileStyle>
      {showTooltip && (
        <LDSTooltip
          $data={calculateElementPositionAndSize(targetRef?.current)}
          $direction={$tooltip.$direction}
          $anchorPosition={$tooltip.$anchorPosition}
          $description={$tooltip.$description}
        ></LDSTooltip>
      )}
      {showDropdown === $tooltip?.$description && $dropdown && (
        <LDSDropdown $width={$dropdown.$width} $position={$dropdown.$position} $align={$dropdown.$align} $data={calculateElementPositionAndSize(targetRef?.current)}>
          {$dropdown.children}
        </LDSDropdown>
      )}
    </>
  );
}

const LDSProfileStyle = styled.div<ProfileProps>`
  width: ${(props) => (props.$size === 'XS' ? SIZES.SIZE_XS : props.$size === 'SM' ? SIZES.SIZE_SM : props.$size === 'MD' ? SIZES.SIZE_MD : SIZES.SIZE_XL)}px;
  height: ${(props) => (props.$size === 'XS' ? SIZES.SIZE_XS : props.$size === 'SM' ? SIZES.SIZE_SM : props.$size === 'MD' ? SIZES.SIZE_MD : SIZES.SIZE_XL)}px;
  border-radius: ${(props) => (props.$size === 'XS' ? SIZES.SIZE_XS : props.$size === 'SM' ? SIZES.SIZE_SM : props.$size === 'MD' ? SIZES.SIZE_MD : SIZES.SIZE_XL)}px;

  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  background-color: ${(props) =>
    props.$type === 'USER' || props.$type === 'ICON'
      ? COLORS.PRIMARY
      : props.$type === 'BADGE' && props.$color !== 'NEUTRAL'
      ? COLORS[`${props.$color}_015`]
      : props.$color === 'NEUTRAL'
      ? COLORS.BLACK_008
      : COLORS.WHITE};
  color: ${(props) => (props.$type === 'USER' || props.$type === 'ICON' ? COLORS.WHITE : props.$type === 'BADGE' && props.$color === 'NEUTRAL' ? COLORS.GRAY_05 : COLORS[props.$color])};

  transition: box-shadow 300ms ${TRANSITIONS.BEZIER};
  cursor: ${(props) => (props.$interactive ? 'pointer' : 'default')};
  box-shadow: ${(props) => (props.$type === 'IMAGE' ? `0px 0px 0px 1px ${COLORS.GRAY_02_DEEP} ` : 'none')};

  label {
    cursor: inherit;
  }

  & > * {
    color: inherit;
  }

  svg {
    width: ${(props) => (props.$size === 'XS' ? 14 : props.$size === 'SM' ? 18 : props.$size === 'MD' ? 24 : 36)}px;
    height: ${(props) => (props.$size === 'XS' ? 14 : props.$size === 'SM' ? 18 : props.$size === 'MD' ? 24 : 36)}px;
  }
  img {
    width: ${(props) => (props.$size === 'XS' ? SIZES.SIZE_XS : props.$size === 'SM' ? SIZES.SIZE_SM : props.$size === 'MD' ? SIZES.SIZE_MD : SIZES.SIZE_XL)}px;
    height: ${(props) => (props.$size === 'XS' ? SIZES.SIZE_XS : props.$size === 'SM' ? SIZES.SIZE_SM : props.$size === 'MD' ? SIZES.SIZE_MD : SIZES.SIZE_XL)}px;
  }

  &:hover,
  &.selected {
    box-shadow: ${(props) => (props.$interactive ? `0px 0px 0px ${props.$size === 'SM' || props.$size === 'XS' ? 3 : 4}px ${COLORS.BLACK_015}}` : '')};
  }
`;
