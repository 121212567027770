import { CargoManifestDTO, CargoManifestSearchDTO } from '../../../../_interface/order';
import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

// temp img
import docuImg from '../../../../_asset/docu.png';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { cargoService } from 'pages/CARGO/_service/service';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IActionObj {
  visible?: boolean;
  type?: 'memo' | 'docu';
  item?: CargoManifestDTO;
  searchObj?: CargoManifestSearchDTO;
  fetchFn?: (seaerchObj: CargoManifestSearchDTO) => {};
}
export const ActionPopup = ({ actionObj, setActionObj }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<{ [key: string]: string }>();

  useEffect(() => {
    //
    if (actionObj?.type === 'memo') {
      if (actionObj?.item?.memo) setData({ memo: actionObj?.item?.memo });
    }
  }, []);

  const saveMemo = async () => {
    dispatch(setLoading('POST'));
    const rs = await cargoService.postOrderCargoAction('memo/save', { cargoManifestSeq: actionObj?.item?.cargoManifestSeq, memo: data?.memo });
    if (rs?.status === 200) {
      alert('적하목록 메모를 저장하였습니다!');
      setActionObj((prev) => {
        return {
          ...prev,
          visible: false,
        };
      });
      actionObj?.fetchFn(actionObj?.searchObj);
    }
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      style={{ width: 650 }}
      title={actionObj?.type === 'memo' ? `고객상담 메모 (B/L: ${actionObj?.item?.houseNumber})` : `통관 증빙서류 다운로드`}
      visible={actionObj?.visible}
      setVisible={() => {
        setActionObj(null);
      }}
      rightTitle={
        <>
          <button className="btn btn-secondary ssm" onClick={saveMemo}>
            수정
          </button>
        </>
      }
      children={
        <>
          {actionObj?.type === 'memo' ? (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">메모 내용</label>
                  <textarea
                    value={data?.memo || ''}
                    onChange={(e) => {
                      setData({
                        ...data,
                        memo: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <img src={docuImg} alt="" />
            </>
          )}
        </>
      }
    />
  );
};
