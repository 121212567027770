import { v4 as uuidv4 } from 'uuid';
import { useEffect, useRef, useState, useMemo } from 'react';
import Presenter from './presenter';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { defaultBottomSheetObj } from 'components/template/bottomSheets';

const Container = (props) => {
  const dispatch = useDispatch();
  // options
  const { masterOptions } = useSelector((state) => state.menu);

  const { reduxUserInfo } = useSelector((state) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  const [gridId, setGrid] = useState('');
  const [rows, setRows] = useState([]);

  const [pagingSetting, setPagingSetting] = useState({
    pageNo: 1,
    pageSize: 100,
    fetchNew: false,
  });

  //바텀시트
  const [bottomSheetObj, setBottomSheetObj] = useState(defaultBottomSheetObj);
  const [haldangObj, setHaldangObj] = useState({
    visible: false,
    data: null,
    confirmFn: (data) => confirmPickingAPI(data),
    cancelFn: (data) => cancelPickingAPI(data),
    rollbackFn: (data) => rollbackStatusAPI(data),
  });
  const [cancelObj, setCancelObj] = useState({
    visible: false,
    data: null,
    cancelFn: (data) => cancelPickingAPI(data),
  });
  const [serialObj, setSerialObj] = useState({
    visible: false,
    data: null,
    event: null,
    modiFn: (data) => modiSerial(data),
  });
  const [locationChangeObj, setLocationChangeObj] = useState({
    visible: false,
    data: null,
    actionFn: (data) => changeLocationAPI(data),
  });
  const [searchObj, setSearchObj] = useState({
    orderNumType: null,
    orderNum: null,
    loanDate: null,
    centerType: null,
    centerCode: null,
    userId: null,
    status: null,

    ...defaultSearchFilter,
  });
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false); ///////main

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'confirm-loan') {
        if (items?.length > 1) {
          alert('기사출고지시 확정은 한개씩만 가능합니다!');
        } else {
          confirmLoan(items[0]);
        }
      } else if (getCheckedDataTriggerThisGrid?.type === 'rollback-status') {
        rollbackStatus(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  useEffect(() => {
    setGrid(`loanRenewal_${uuidv4()}`);
  }, []);

  const paramingSearchObjToUrl = (url) => {
    const searchParams = Object.keys(searchObj);

    let urlWithSearchParams = url;
    for (let param of searchParams) {
      if (searchObj[param] !== null && searchObj[param] !== '' && param !== 'orderNumType')
        param.includes('orderNum') ? (urlWithSearchParams += `&${searchObj['orderNumType']}=${searchObj[param]}`) : (urlWithSearchParams += `&${param}=${searchObj[param]}`);
    }

    return urlWithSearchParams;
  };

  const fetchList = () => {
    if (bottomSheetObj?.visible) setBottomSheetObj(defaultBottomSheetObj);
    dispatch(setLoading('GET'));
    let url = `/warehouse/loan/v2/list/paging?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
    url = paramingSearchObjToUrl(url);

    httpClient.get(url).then((res) => {
      if (res.status === 200) {
        const addList = res.data.list || [];
        const maxPage = Math.ceil(res.data.totalCount / pagingSetting?.pageSize);
        addList?.forEach((row) => {
          row.maxPage = maxPage;
          row.totalCount = res.data.totalCount;

          row.print = row.status.includes('INIT') ? '' : '프린트';
          row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER_LBL);
          row.userIdKr = selectlabel(row.userId, masterOptions?.DRIVER_NAME);
          row.driverPhoneKr = selectlabel(row.userId, masterOptions?.DRIVER_PHONE);
          row.statusKr = selectlabel(row.status, masterOptions?.LOAN_STATUS);
          row.teamKr = selectlabel(row.userId, masterOptions?.DRIVER_MEMBERCOUNT) === 2 ? '2인 1조' : selectlabel(row.userId, masterOptions?.DRIVER_MEMBERCOUNT) === 1 ? '1인 1조' : '';
        });

        setRows(addList);
        setPagingSetting((prev) => {
          return {
            ...prev,
            fetchNew: false,
          };
        });
        dispatch(setLoading(null));
      }
    });
  };

  const bottomGridRef = useRef();
  const fetchDetailList = async (e) => {
    dispatch(setLoading('GET'));
    let url = '/warehouse/loan/v2?';
    if (e.item?.loanSeq) url += `&loanSeq=${e.item?.loanSeq}`;
    if (e.item?.centerCode) url += `&centerCode=${e.item?.centerCode}`;
    if (e.item?.userId) url += `&userId=${e.item?.userId}`;
    if (e.item?.loanDate) url += `&loanDate=${e.item?.loanDate}`;
    if (e.item?.orderSeq) url += `&orderSeq=${e.item?.orderSeq}`;
    if (e.item?.invoiceSeq) url += `&invoiceSeq=${e.item?.invoiceSeq}`;

    const rs = await httpClient.get(url);
    if (rs?.status === 200) {
      const data = rs.data.details || [];
      const rows = [];
      data?.forEach((detail) => {
        detail.inventories.forEach((inventory) => {
          rows.push({
            ...detail,
            ...inventory,
            e: JSON.stringify(e),
            loanSeq: rs.data.loanSeq,
            centerCode: inventory.centerCode,
            centerCodeKr: selectlabel(inventory.centerCode, masterOptions?.CENTER_LBL),
            userId: rs.data.userId,
            statusKr: selectlabel(detail.status, masterOptions?.LOAN_STATUS),
            userIdKr: selectlabel(rs.data.userId, masterOptions?.DRIVER_NAME),
            loanDate: rs.data.loanDate,
            targetFlagKr: inventory?.targetFlag === true ? 1 : 0,
            moveTargetFlagKr: inventory?.moveTargetFlag === true ? 1 : 0,
            loanFlagKr: inventory?.loanFlag === true ? 1 : 0,
            moveLoanFlagKr: inventory?.moveLoanFlag === true ? 1 : 0,
            noLoanFlagKr: inventory?.noLoanFlag === true ? 1 : 0,
            noMoveLoanFlagKr: inventory?.noMoveLoanFlag === true ? 1 : 0,
            partnerSeqKr: selectlabel(inventory.partnerSeq, masterOptions?.SELLER),
            feeTypeKr: selectlabel(detail?.feeType, masterOptions?.FEE_TYPE),
            modelGroupLargeKr: selectlabel(inventory.modelGroupLarge, masterOptions?.MODEL_LARGE),
          });
        });
      });

      const gridButtonhandler = (e) => {
        const id = e.target.id;
        const items = bottomGridRef?.current?.getCheckedRowItemsAll();
        if (id === 'excel-down') {
          bottomGridRef?.current?.exportAsXlsx({ fileName: `기사출고_상세` });
        } else if (id === 'cancel-loan-parts') {
          if (items?.length > 0) {
            cancelLoanParts({ items, e });
          } else {
            alert('선택된 건이 없습니다!');
          }
        }
      };

      setBottomSheetObj({
        visible: true,
        hasTabs: [
          {
            title: '기사출고 상세',
            gridRef: bottomGridRef,
            columns: detailColumns,
            rows,
            buttons: [
              <div key={`cancel-loan-parts`} id={`cancel-loan-parts`} className={`red`} onClick={gridButtonhandler}>
                기사출고 취소
              </div>,
              <div key={`excel-down`} id={`excel-down`} className={`green`} onClick={gridButtonhandler}>
                엑셀다운
              </div>,
            ],
            options: {
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              showFooter: false,
              rowCheckToRadio: false,
            },
          },
        ],
      });
    }
    dispatch(setLoading(null));
  };

  const confirmPickingAPI = (dataArr) => {
    httpClient.post('/warehouse/loan/v2/save', dataArr).then((rs) => {
      if (rs.data.message) alert(rs.data.message);
      fetchList();
    });
  };

  const confirmLoan = (item) => {
    const data = {
      loanSeq: item.loanSeq,
      userId: item.userId,
      centerCode: item.centerCode,
      loanDate: item.loanDate,
    };
    confirmPickingAPI([data]);
  };

  const rollbackStatusAPI = (dataArr) => {
    httpClient.post('/warehouse/loan/picking/rollback', dataArr).then((rs) => {
      if (rs?.status === 200) {
        alert('이전단계로 상태값 변경이 완료되었습니다!');
        fetchList();
      }
    });
  };

  const rollbackStatus = (items) => {
    const availStat = ['피킹지시확정', '피킹중'];
    const statusKr = items[0].statusKr;
    if (items.every((item) => item.statusKr === statusKr)) {
      if (availStat.includes(statusKr)) {
        if (window.confirm(`${statusKr} 상태에서 ${statusKr === '피킹지시확정' ? '피킹지시대기' : '피킹지시확정'} 상태로 돌아가시겠습니까?`)) {
          const dataArr = items.map((ele) => {
            return {
              loanPickSeq: ele.loanPickSeq,
            };
          });
          rollbackStatusAPI(dataArr);
        }
      } else {
        alert('피킹지시확정 혹은 피킹중 상태만 이전단계로 돌릴 수 있습니다!');
      }
    } else {
      alert(`진행상태가 서로 다른 건들이 선택되었습니다.\n 이전단계로 돌아가려면 진행상태가 동일해야합니다.`);
    }
  };

  const cancelLoanParts = ({ items, e }) => {
    if (window.confirm('해당 송장들을 기사출고취소 하시겠습니까?')) {
      const invoiceSeq = [];
      items.forEach((item) => {
        if (!invoiceSeq.includes(item.invoiceSeq)) invoiceSeq.push(item.invoiceSeq);
      });
      const data = {
        loanSeq: items[0].loanSeq,
        invoiceSeq,
      };
      httpClient.post('/warehouse/loan/v2/cancel', data).then((rs) => {
        if (rs?.status === 200) {
          alert('기사출고 취소에 성공하였습니다!');
          fetchList();
          fetchDetailList(e);
        }
      });
    }
  };

  const cancelPickingAPI = (dataArr) => {
    httpClient.post('/warehouse/loan/picking/cancel', dataArr).then((rs) => {
      if (rs?.status === 200) {
        alert('피킹취소에 성공하였습니다!');
        fetchList();
      }
    });
  };

  const changeLocationAPI = ({ data, e }) => {
    httpClient.post('/warehouse/loan/picking/location/change', data).then((rs) => {
      if (rs?.status === 200) {
        alert('로케이션 변경에 성공하였습니다!');
        setLocationChangeObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
        fetchDetailList(e);
      }
    });
  };

  const onCellClick = (e) => {
    if (e.pid.includes('loanRenewal_')) {
      if (e.dataField === 'print' && e.value) {
        printAction(e);
      }
    }
  };

  const [driverPrintObj, setDriverPrintObj] = useState();
  const printAction = async (event) => {
    setDriverPrintObj({
      visible: true,
      type: 'model',
      item: event?.item,
    });
  };

  const modiSerial = async ({ data, serial }) => {
    const body = {
      loanDetailInventorySeq: data.loanDetailInventorySeq,
      serialNumber: serial,
    };
    dispatch(setLoading('POST'));
    await httpClient.post(`/warehouse/loan/serial/number/change`, body).then((rs) => {
      if (rs?.status === 200) {
        alert('시리얼 수정되었습니다!');
        fetchDetailList(JSON.parse(data.e));
      }
    });
    dispatch(setLoading(null));
  };

  const popupSerial = (event) => {
    setSerialObj((prev) => {
      return {
        ...prev,
        visible: true,
        data: event.item,
        event,
      };
    });
  };

  const columns = [
    {
      headerText: '상세보기',
      editable: false,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '상세보기',
        onClick: fetchDetailList,
      },
    },
    {
      headerText: '출고지시서',
      dataField: 'print',
      editable: false,
      style: 'click-fake-item',
    },
    {
      headerText: '기사출고번호',
      dataField: 'loanSeq',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고예정일',
      dataField: 'loanDate',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '창고타입',
      dataField: 'centerType',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '기사이름',
      dataField: 'userIdKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '기사연락처',
      dataField: 'driverPhoneKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '팀유형',
      dataField: 'teamKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고대상 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'targetQuantity',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '이전',
          dataField: 'moveTargetQuantity',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
      ],
    },

    {
      headerText: '출고완료 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'loanQuantity',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '이전',
          dataField: 'moveLoanQuantity',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '미출고 수량(대상-완료)',
      children: [
        {
          headerText: '양품',
          dataField: 'noLoanQuantity',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '이전',
          dataField: 'noMoveLoanQuantity',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '출고지시<br />확정일',
      dataField: 'loanInitDatetime',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고지시<br />확정자',
      dataField: 'loanInitId',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
  ];

  const detailColumns = [
    {
      headerText: '기사출고번호',
      dataField: 'loanSeq',
      cellMerge: true,
      mergeRef: 'invoiceSeq',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '주문정보',
      children: [
        {
          headerText: '송장번호',
          dataField: 'invoiceSeq',
          editable: false,
          cellMerge: true,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '고객명',
          dataField: 'customerName',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '송장유형',
          dataField: 'feeTypeKr',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '약속시간',
          dataField: 'deliveryDatetime',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupLargeKr',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '기사출고대상 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'targetFlagKr',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '이전',
          dataField: 'moveTargetFlagKr',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
      ],
    },

    {
      headerText: '출고완료 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'loanFlagKr',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '이전',
          dataField: 'moveLoanFlagKr',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '미출고 수량(대상-완료)',
      children: [
        {
          headerText: '양품',
          dataField: 'noLoanFlagKr',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '이전',
          dataField: 'noMoveLoanFlagKr',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '출고<br />시리얼번호',
      dataField: 'serialNumber',
      editable: false,
      renderer: {
        type: 'ButtonRenderer',
        label: '출고<br />시리얼번호',
        onClick: function (event) {
          popupSerial(event);
        },
      },
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '완료<br />시리얼번호',
      dataField: 'completeSerialNumber',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '취소<br />시리얼번호',
      dataField: 'cancelSerialNumber',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고완료일',
      dataField: 'loanDatetime',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고완료자<br />(설치기사)',
      dataField: 'loanId',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
  ];

  const downloadExcel = async (type) => {
    dispatch(setLoading('GET'));
    let url;

    if (type === 'main') {
      url = '/warehouse/loan/v2/list/excel?';
    } else {
      url = '/warehouse/loan/v2/list/excel/detail?';
    }
    url = paramingSearchObjToUrl(url, searchObj);

    return await httpClient.get(url).then((rs) => {
      if (rs.status === 200) {
        rs.data.forEach((row) => {
          if (type === 'main') {
            row.statusKr = selectlabel(row.status, masterOptions?.LOAN_STATUS);
            row.teamKr = selectlabel(row.userId, masterOptions?.DRIVER_MEMBERCOUNT) === 2 ? '2인 1조' : selectlabel(row.userId, masterOptions?.DRIVER_MEMBERCOUNT) === 1 ? '1인 1조' : '';
            row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER_LBL);
            row.userIdKr = selectlabel(row.userId, masterOptions?.DRIVER_NAME);
            row.driverPhoneKr = selectlabel(row.userId, masterOptions?.DRIVER_PHONE);
          } else {
            row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
            row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER_LBL);
            row.statusKr = selectlabel(row.status, masterOptions?.LOAN_STATUS);
            row.targetFlagKr = row.targetQuantity;
            row.moveTargetFlagKr = row.moveTargetQuantity;
            row.loanFlagKr = row.loanQuantity;
            row.moveLoanFlagKr = row.moveLoanQuantity;
            row.noLoanFlagKr = row.noLoanQuantity;
            row.noMoveLoanFlagKr = row.noMoveLoanQuantity;
            row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
            row.feeTypeKr = selectlabel(row?.feeType, masterOptions?.FEE_TYPE);
            row.modelGroupLargeKr = selectlabel(row.modelGroupLarge, masterOptions?.MODEL_LARGE);
          }
        });
        dispatch(setLoading(null));
        return rs.data;
      } else {
        dispatch(setLoading(null));
        return null;
      }
    });
  };

  return (
    <Presenter
      gridId={gridId}
      rows={rows}
      columns={columns}
      detailColumns={detailColumns}
      //
      pagingSetting={pagingSetting}
      setPagingSetting={setPagingSetting}
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      fetchList={fetchList}
      onCellClick={onCellClick}
      downloadExcel={downloadExcel}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      //
      bottomSheetObj={bottomSheetObj}
      setBottomSheetObj={setBottomSheetObj}
      haldangObj={haldangObj}
      setHaldangObj={setHaldangObj}
      locationChangeObj={locationChangeObj}
      setLocationChangeObj={setLocationChangeObj}
      cancelObj={cancelObj}
      setCancelObj={setCancelObj}
      //
      serialObj={serialObj}
      setSerialObj={setSerialObj}
      driverPrintObj={driverPrintObj}
      setDriverPrintObj={setDriverPrintObj}
    />
  );
};

export default Container;
