import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './common';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { getGridParameters } from 'common/master/codeMasterOptionReturn';
import { COMMON_GRIDID, nullingMASTER, RAWDATA } from 'redux/services/menuSlice';
import { DRIVER_LIST_MASTER } from 'pages/ETC/1driver/driverListTab';

export const MANAGE_PARCEL_SERVICE_LIST_MASTER = [
  //
  'SELLER_FORWARDER_AUTH',

  `${RAWDATA}DRIVER_WHOLE`,
  `${RAWDATA}DRIVER_AUTH`,
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  'DRIVER_PHONE_WHOLE', // 라벨용이 많으니
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'CENTER_CALL_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_NETWORK_AUTH',
  'SELLER_WHOLE',
  'INFRA',
  'SALES_CHANNEL',
  //
  `${RAWDATA}ORDER_TYPE`,
  'ORDER_TYPE',
  'INVOICE_STATUS',
  'INVOICE_STATUS_1',
  'INVOICE_STATUS_2',
  'FEE_TYPE',
  'CANCEL_REASON',
  'COLLECT_REASON',
  'SERVICE_TYPE',
  'MODEL_TYPE',
  'MODEL_GROUP',
  'RESCHEDULE_TYPE',
  'BODY_WIFI_REASON',
  'EOL_GROUP2',
  `${RAWDATA}EOL_GROUP2`,
  'EOL_GROUP3',
  'COUNSEL_DETAIL_TYPE',
  'COUNSEL_TYPE',
  'LADDER_CO',
  'MODEL_GRP_1',
  'MODEL_GRP_2',
  'MODEL_GRP_3',
  'CANCEL_TYPE',
  'MESSAGE_TYPE',
  'INVENTORY_LOCATION',
  'PROVINCE_WHOLE',
  'CITY',
  'DRIVER_PARTY',
  'TEAM_GRADE',
  'DRV_POS',
  'CAR_TYPE',
  'DRV_TYPE',
  'TEAM_TYPE',
  'LOGI_TYPE',
  'PACKING_STATE',
  'INV_TRACK_TYPE',
  'PARCEL_PAY_TYPE',
  'INVOICE_UPDATE_CONTENT',
  'INSTALL_FORM',
  //
  'SEARCH_ORDER',
  'SEARCH_INVOICE',
  'SEARCH_MODEL',
  // parcel

  // parcel
  'PORT_CODE',
  'PARCEL_PAY_TYPE',
  'PARCEL_INVOICE_STATUS',
  'PACKAGE_TYPE',
  'PARCEL_TYPE',
  'FINISH_LOCATION',
  'ACCIDENT_TYPE',
  'ACCIDENT_DETAIL',
  'ACCIDENT_ITEM',
  'INTERIOR_PACKAGE',
  'EXTERIOR_PACKAGE',
  'PARCEL_REVISIT_REASON',
  'EXTERNAL_PARCEL',

  'INSTALL_DURATION',
  'DELIVERY_TIME',
  'UNIFORM_SIZE',
  'WEEK_DAY',
  'OX_FLAG',
  'USE_FLAG',
  'LITE_FLAG',
  'FIX_DATE',
  {
    SEARCH_NAME: [
      { value: 'senderName', label: '송하인명' },
      { value: 'receiverName', label: '고객명' },
    ],
  },
  {
    SEARCH_SEQ: [
      { value: 'parcelInvoiceNumber', label: '운송장번호' },
      { value: 'houseNumber', label: 'HBL' },
      { value: 'subNumber', label: 'SBL' },
      { value: 'externalInvoiceNumber', label: '외부운송장번호' },
    ],
  },
  {
    PARCEL_BOX_SIZE: [
      { value: 'XS', label: '극소' },
      { value: 'S', label: '소' },
      { value: 'M', label: '중' },
      { value: 'L', label: '대' },
      { value: 'ABNORMAL', label: '이형' },
    ],
  },
  {
    SEARCH_DATE: [
      { value: 'RegisterDate', label: '등록일시' },
      { value: 'FinishDate', label: '완료일시' },
      { value: 'CollectScanDate', label: '집화스캔일' },
      { value: 'CustomsReleaseDate', label: '세관반출일' },
    ],
  },
  {
    PMODEL_TYPE: [
      { value: 'MODEL', label: '구성품' },
      { value: 'GIFT', label: '사은품' },
    ],
  },
  {
    SEARCH_PRODUCT: [
      { value: 'productName', label: '상품명' },
      { value: 'productSeq', label: '상품번호' },
      { value: 'externalProductNumber', label: '외부상품번호' },
    ],
  },
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    const PARCEL_SERVICE_LIST = await getGridParameters('PARCEL_SERVICE_LIST');
    const PARCEL_RESERVED_LIST = await getGridParameters('PARCEL_RESERVED_LIST');
    const PARCEL_DELIVERY_COMPLETED_LIST = await getGridParameters('PARCEL_DELIVERY_COMPLETED_LIST');
    const masterArr = [
      //
      { [`${COMMON_GRIDID}PARCEL_SERVICE_LIST`]: PARCEL_SERVICE_LIST },
      { [`${COMMON_GRIDID}PARCEL_RESERVED_LIST`]: PARCEL_RESERVED_LIST },
      { [`${COMMON_GRIDID}PARCEL_DELIVERY_COMPLETED_LIST`]: PARCEL_DELIVERY_COMPLETED_LIST },
      ...DRIVER_LIST_MASTER,
      ...MANAGE_PARCEL_SERVICE_LIST_MASTER,
    ];
    initMasterOptions(masterArr);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PARCEL_SERVICE_LIST: <Tab1 tabId={'PARCEL_SERVICE_LIST'} />,
            PARCEL_RESERVED_LIST: <Tab1 tabId={'PARCEL_RESERVED_LIST'} />,
            PARCEL_DELIVERY_COMPLETED_LIST: <Tab1 tabId={'PARCEL_DELIVERY_COMPLETED_LIST'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
