import { useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import { MenuDTO, MenuSearchDTO } from 'interface/user';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import CommonModalNew from 'components/modal/commonModalNew';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { element } from 'prop-types';

export interface IDetailObj {
  searchObj?: MenuSearchDTO;
  visible: boolean;
  item?: MenuDTO;
  CRUDFn?: <T>(T) => void;
}

interface IDetailModal {
  detailObj: IDetailObj;
  setDetailObj: React.Dispatch<React.SetStateAction<IDetailObj>>;
}

export const DetailModal = ({ detailObj, setDetailObj }: IDetailModal) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const [data, setData] = useState<MenuDTO>();
  useEffect(() => {
    console.log(detailObj?.item);
    if (detailObj?.item) {
      setData(detailObj?.item);
    }
  }, []);

  const saveDetail = (data: MenuDTO) => {
    if (!detailObj?.item) {
      if (window.confirm('등록 하시겠습니까?')) {
        detailObj?.CRUDFn({ data, crud: 'C', searchObj: detailObj?.searchObj });
      }
    } else {
      if (window.confirm('수정 하시겠습니까?')) {
        detailObj?.CRUDFn({ data, crud: 'U', searchObj: detailObj?.searchObj });
      }
    }
  };

  const deleteDetail = (data: MenuDTO) => {
    if (window.confirm('삭제 하시겠습니까?')) {
      detailObj?.CRUDFn({ data: [data?.menuId], crud: 'U', searchObj: detailObj?.searchObj });
    }
  };

  return (
    <CommonModalNew
      style={{ width: 500 }}
      title={!detailObj?.item ? '메뉴 생성' : '메뉴 수정'}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      children={
        <>
          <label>*메뉴레벨</label>
          <div className="grid-wrapper div2">
            <SelectD
              hasNull={false}
              options={MASTER_OPS?.MENU_LEVEL}
              value={
                data?.level
                  ? {
                      value: data?.level,
                      label: MASTER_OBJ?.MENU_LEVEL?.[data?.level],
                    }
                  : null
              }
              onChange={(option) => {
                setData({
                  ...data,
                  level: (option as OptionItem)?.value,
                  parentMenuId: (option as OptionItem)?.value === 1 ? null : data?.parentMenuId,
                  parentMenuName: (option as OptionItem)?.value === 1 ? null : data?.parentMenuName,
                  screenId: (option as OptionItem)?.value !== 3 ? null : data?.screenId,
                  screenName: (option as OptionItem)?.value !== 3 ? null : data?.screenName,
                });
              }}
            />
            <div></div>
          </div>
          <label>상위메뉴ID/메뉴이름</label>
          <div className="grid-wrapper div2">
            <InputD
              disabled={true}
              placeholder="자동선택"
              value={data?.parentMenuId || ''}
              onChange={(e) => {
                setData({
                  ...data,
                  parentMenuId: e.target.value,
                });
              }}
            />
            <SelectD
              isDisabled={data?.level === 1 || !data?.level}
              options={MASTER_OPS?.MENU_WHOLE?.filter((ele) => ele.level === (data?.level - 1 > 0 ? data?.level - 1 : 0))?.map((ele) => {
                return {
                  label: ele.menuName,
                  value: ele.menuId,
                };
              })}
              value={
                data?.parentMenuId
                  ? {
                      value: data?.parentMenuId,
                      label: MASTER_OBJ?.MENU_NAME?.[data?.parentMenuId],
                    }
                  : null
              }
              onChange={(option) => {
                setData({
                  ...data,
                  parentMenuId: (option as OptionItem)?.value,
                  parentMenuName: (option as OptionItem)?.label,
                  menuId: null,
                  menuName: null,
                });
              }}
            />
          </div>
          <label>메뉴ID/메뉴명</label>
          <div className="grid-wrapper div2">
            {detailObj?.item ? (
              <>
                <InputD
                  disabled={true}
                  placeholder="자동선택"
                  value={data?.menuId || ''}
                  onChange={(e) => {
                    setData({
                      ...data,
                      menuId: e.target.value,
                    });
                  }}
                />
                <SelectD
                  isDisabled={!data?.parentMenuId}
                  options={MASTER_OPS?.MENU_WHOLE?.filter((ele) => ele.parentMenuId === data?.parentMenuId)?.map((ele) => {
                    return {
                      label: ele.menuName,
                      value: ele.menuId,
                    };
                  })}
                  value={
                    data?.menuId
                      ? {
                          value: data?.menuId,
                          label: MASTER_OBJ?.MENU_NAME?.[data?.menuId],
                        }
                      : null
                  }
                  onChange={(option) => {
                    setData({
                      ...data,
                      menuId: (option as OptionItem)?.value,
                      menuName: (option as OptionItem)?.label,
                    });
                  }}
                />
              </>
            ) : (
              <>
                <InputD
                  value={data?.menuId || ''}
                  onChange={(e) => {
                    setData({
                      ...data,
                      menuId: e.target.value,
                    });
                  }}
                />
                <InputD
                  value={data?.menuName || ''}
                  onChange={(e) => {
                    setData({
                      ...data,
                      menuName: e.target.value,
                    });
                  }}
                />
              </>
            )}
          </div>
          <label>화면 아이디/연결 url</label>
          <div className="grid-wrapper div2">
            <InputD disabled={data?.level !== 3} readOnly value={data?.screenId || ''} />
            <SelectD
              isDisabled={data?.level !== 3}
              options={MASTER_OPS?.SCREENID_URL}
              value={
                data?.screenId
                  ? {
                      value: data?.screenId,
                      label: MASTER_OBJ?.SCREENID_URL?.[data?.screenId],
                    }
                  : null
              }
              onChange={(option) => {
                setData({
                  ...data,
                  screenId: (option as OptionItem)?.value,
                  url: (option as OptionItem)?.label,
                });
              }}
            />
          </div>
          <label>설명</label>
          <textarea
            style={{ width: '100%' }}
            value={data?.description || ''}
            onChange={(e) => {
              setData({
                ...data,
                description: e.target.value,
              });
            }}
          />
          <label>사용여부</label>
          <div className="radio-area one-line">
            <Label>
              <Input
                type="radio"
                checked={data?.useFlag === true}
                onChange={(e) => {
                  setData({
                    ...data,
                    useFlag: true,
                  });
                }}
              />
              &nbsp;&nbsp;사용
            </Label>
            <Label>
              <Input
                type="radio"
                checked={data?.useFlag === false}
                onChange={(e) => {
                  setData({
                    ...data,
                    useFlag: false,
                  });
                }}
              />
              &nbsp;&nbsp;사용 안 함
            </Label>
          </div>
          <div style={{ paddingTop: 30, textAlign: 'right' }}>
            {detailObj?.item && (
              <button className="btn btn-danger" onClick={() => deleteDetail(data)}>
                삭제
              </button>
            )}
            <button className={detailObj?.item ? 'btn btn-orange' : 'btn btn-blue'} onClick={() => saveDetail(data)}>
              {detailObj?.item ? '수정' : '등록'}
            </button>
          </div>
        </>
      }
    />
  );
};
