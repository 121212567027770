import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, Form, Button, Label, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';

import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { v4 as uuidv4 } from 'uuid';

// redux
import { useDispatch, useSelector } from 'react-redux';

import CloseImg from 'assets/images/close/cross.png';
import { handleFile, uploadFile } from 'common/util/photo';
import { httpClient } from 'common/http-client/axiosConfig';
import { TAKE_OUT_LOADINGOUT_IMAGE_SAVE, TAKE_OUT_REMOVE_CANCEL } from 'envVar';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// component
import Grid from 'components/grid/auiGrid';
import DeliveryInformationManagePopup from './DeliveryInformationManagePopup.js';
import { BottomSheets } from 'components/template/bottomSheets';
import { HaldangModal } from './component/haldang';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { setLoading } from 'redux/services/menuSlice';
import useGridButton from 'hooks/grid/useGridButton';
import CommonModalNew from 'components/modal/commonModalNew';
import { BanchulPrints } from '../../_print/banchulPrint';

import { resetAllSelections } from 'common/util/searchBox';
import PrintGridBox from 'common/grid/printGridBox';

const Presenter = (props) => {
  const {
    haldangObj,
    setHaldangObj,
    photoPopupObj,
    setPhotoPopupObj,
    fetchList,
    fetchPhotos,

    driverModalObj,
    setDriverModalObj,

    bottomSheetObj,
    setBottomSheetObj,
    inventoryCancelModalObj,
    setInventoryCancelModalObj,
    banchulPrintObj,
    setBanchulPrintObj,
    searchObj,
    setSearchObj,
  } = props;

  return (
    <>
      <div className="page-content">
        {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
        {banchulPrintObj?.visible && <BanchulPrints banchulPrintObj={banchulPrintObj} setBanchulPrintObj={setBanchulPrintObj} />}
        {haldangObj?.visible && <HaldangModal haldangObj={haldangObj} setHaldangObj={setHaldangObj} />}
        {inventoryCancelModalObj?.visible && (
          <InventoryCancelModal
            inventoryCancelModalObj={inventoryCancelModalObj}
            setInventoryCancelModalObj={setInventoryCancelModalObj}
            searchObj={searchObj}
            setSearchObj={setSearchObj}
            fetchList={fetchList}
          />
        )}
        {photoPopupObj?.visible && <PhotoPopup fetchList={fetchList} fetchPhotos={fetchPhotos} photoPopupObj={photoPopupObj} setPhotoPopupObj={setPhotoPopupObj} />}
        {driverModalObj?.visible && <DeliveryInformationManagePopup driverModalObj={driverModalObj} setDriverModalObj={setDriverModalObj} />}
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const InventoryCancelModal = ({ inventoryCancelModalObj, setInventoryCancelModalObj, searchObj, setSearchObj, fetchList }) => {
  const dispatch = useDispatch;
  const { visible, data, isParts } = inventoryCancelModalObj;
  const { masterOptions } = useSelector((state) => state.menu);
  const [inputObj, setInputObj] = useState({
    holdType: null,
    holdNote: null,
  });

  const takeoutModalCancelAction = async (inputObj) => {
    let payloadArr;
    if (isParts) {
      payloadArr = [
        {
          stockRemoveSeq: data[0].stockRemoveSeq,
          loadingStatementSeq: data[0].loadingStatementSeq,
          loadingOutSeq: data[0].loadingOutSeq,
          centerCode: data[0].centerCode,
          target: data[0].target,
          partnerSeq: data[0].partnerSeq,
          stockRemoveDate: data[0].stockRemoveDate,

          inventorySeqs: data?.map((ele) => ele.inventorySeq),

          holdType: inputObj?.holdType,
          holdNote: inputObj?.holdNote,
        },
      ];
    } else {
      payloadArr = data.map((ele) => {
        return {
          stockRemoveSeq: ele.stockRemoveSeq,
          loadingStatementSeq: ele.loadingStatementSeq,
          loadingOutSeq: ele.loadingOutSeq,
          centerCode: ele.centerCode,
          target: ele.target,
          partnerSeq: ele.partnerSeq,

          stockRemoveDate: ele.stockRemoveDate,
          holdType: inputObj?.holdType,
          holdNote: inputObj?.holdNote,
        };
      });
    }
    let url = TAKE_OUT_REMOVE_CANCEL;
    // dispatch(setLoading('POST'));

    const res = await httpClient.post(url, payloadArr);
    if (res?.status === 200) {
      alert('취소 되었습니다.');
      fetchList(searchObj);
      setSearchObj({ ...searchObj });
      // inventoryCancelModalObj?.fetchListFn(inventoryCancelModalObj?.searchObj);
      setInventoryCancelModalObj(null);
    }
    // dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title="출차취소"
      setVisible={() => {
        setInventoryCancelModalObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
            searchObj: null,
          };
        });
      }}
      visible={visible}
      style={{ width: 300 }}
      children={
        <>
          <div>
            <Col>
              <label className="col-form-label">출차취소사유</label>
              <SelectD
                value={
                  inputObj?.holdType
                    ? {
                        value: inputObj?.holdType,
                        label: selectlabel(inputObj?.holdType, masterOptions?.ORDER_HOLD_TYPE),
                      }
                    : null
                }
                options={masterOptions?.['ORDER_HOLD_TYPE']}
                onChange={({ value }) => {
                  setInputObj((prev) => {
                    return {
                      ...prev,
                      holdType: value,
                    };
                  });
                }}
              />
            </Col>
            <Col style={{ marginTop: '1rem' }}>
              <InputD
                value={inputObj.holdNote || ''}
                onChange={(e) => {
                  setInputObj((prev) => {
                    return {
                      ...prev,
                      holdNote: e.target.value,
                    };
                  });
                }}
                placeholder="출차취소사유 입력"
              />
            </Col>
            <Col style={{ marginTop: '1rem', textAlign: 'right' }}>
              <Button className="btn-secondary btn sm" onClick={() => takeoutModalCancelAction(inputObj)}>
                확인
              </Button>
            </Col>
          </div>
        </>
      }
    />
  );
};

const Search = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const [selectTypeObj, setSelectTypeObj] = useState({
    ORDER_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.ORDER_SEQ, searchObj));
  }, [selectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">작업 지시번호</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    searchObj?.seqSearchType
                      ? {
                          label: masterOptions?.SEARCH_SEQ_TAKEOUT_OBJ[searchObj?.seqSearchType],
                          value: searchObj?.seqSearchType,
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_SEQ_TAKEOUT}
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      seqSearchType: value,
                      seqSearchValue: null,
                    });
                  }}
                />
              </Col>
              <Col md={8}>
                <InputD
                  value={searchObj?.seqSearchValue || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      seqSearchValue: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">출차 날짜</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    searchObj?.dateSearchType
                      ? {
                          label: masterOptions?.SEARCH_DATE_TAKEOUT_OBJ[searchObj?.dateSearchType],
                          value: searchObj?.dateSearchType,
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_DATE_TAKEOUT}
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      dateSearchType: value,
                    });
                  }}
                />
              </Col>
              <Col md={8}>
                <FlatpickrD
                  disabled={!searchObj?.dateSearchType}
                  onReset={() => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        dateSearchValue: null,
                      };
                    });
                  }}
                  onChange={(date) => {
                    setSearchObj({
                      ...searchObj,
                      dateSearchValue: [returnDateyyyymmdd(date[0]), returnDateyyyymmdd(date[1])],
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">판매사</label>
            <SelectD
              value={
                searchObj?.partnerSeq
                  ? {
                      value: searchObj?.partnerSeq,
                      label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: option.value,
                });
              }}
              options={masterOptions?.SELLER}
            />
          </Col>
          <Col>
            <label className="col-form-label">반출창고</label>
            <SelectD
              value={
                searchObj?.centerCode
                  ? {
                      value: searchObj?.centerCode,
                      label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: option.value,
                });
              }}
              options={masterOptions?.CENTER_AUTH}
            />
          </Col>
          <Col>
            <label className="col-form-label">반출유형</label>
            <SelectD
              value={
                searchObj?.reason
                  ? {
                      value: searchObj?.reason,
                      label: selectlabel(searchObj?.reason, masterOptions?.REMOVE_REASON),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  reason: value,
                });
              }}
              options={masterOptions?.REMOVE_REASON}
            />
          </Col>
          <Col>
            <label className="col-form-label">진행상태</label>
            <SelectD
              value={
                searchObj?.status
                  ? {
                      value: searchObj?.status,
                      label: selectlabel(searchObj?.status, masterOptions?.LOADING_OUT_STAT),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  status: option.value,
                });
              }}
              options={masterOptions?.LOADING_OUT_STAT}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">주문번호/송장번호</label>
            {/* orderSeq */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.ORDER_SEQ
                      ? {
                          value: selectTypeObj?.ORDER_SEQ,
                          label: selectlabel(selectTypeObj?.ORDER_SEQ, masterOptions?.ORDER_SEQ),
                        }
                      : null
                  }
                  options={masterOptions?.ORDER_SEQ}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      ORDER_SEQ: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.ORDER_SEQ}
                  value={searchObj?.[selectTypeObj?.ORDER_SEQ] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.ORDER_SEQ]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">제품/제품명</label>
            {/* model / modelName  */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_MODEL
                      ? {
                          value: selectTypeObj?.SEARCH_MODEL,
                          label: selectlabel(selectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_MODEL}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_MODEL: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_MODEL}
                  value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">유사검색여부</label>
            {/* similaritySearchFlag */}
            <div className="radio-select-area">
              <span
                className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: true,
                    };
                  });
                }}
              >
                유사검색
              </span>
              <span
                className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: false,
                    };
                  });
                }}
              >
                일치검색
              </span>
            </div>
          </Col>
          <Col>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                fetchList(searchObj);
              }}
            >
              조회
            </button>
          </Col>
          <Col />
          <Col />
        </Row>
      </Form>
    </>
  );
};

const GridBox = (props) => {
  const {
    rows = [],
    gridId,
    columns,
    detailColumns,
    excelDetailColumns,
    onCellClick,
    pagingSetting,
    setPagingSetting,
    onEditEnded,
    setGetCheckedDataTriggerThisGrid,
    getCheckedDataTriggerThisGrid,
    downloadExcel,
    fetchList,
    searchObj,
  } = props;

  useEffect(() => {
    if (pagingSetting?.pageNo && pagingSetting?.fetchNew) {
      fetchList();
    }
  }, [pagingSetting?.fetchNew]);

  const excelGridRef = useRef();
  const excelDetailGridRef = useRef();
  const excelDownClicked = async (type) => {
    const data = await downloadExcel(type);
    if (data?.length > 0) {
      if (type === 'main') {
        excelGridRef.current.setGridData(data);
        excelGridRef.current.exportAsXlsx({ fileName: '반출출차지시(메인)' });
      } else {
        excelDetailGridRef.current.setGridData(data);
        excelDetailGridRef.current.exportAsXlsx({ fileName: '반출출차지시(상세)' });
      }
    }
  };
  const checkedArr = [
    //
    'TAKEOUT_MANAGE_DRIVER',
    'TAKEOUT_OUT_COMPLETE',
    'TAKEOUT_OUT_ROLLBACK',
    'TAKEOUT_OUT_CANCEL',
  ];

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (checkedArr?.includes(id)) {
      setGetCheckedDataTriggerThisGrid({
        type: id,
        id: `#${gridId}`,
      });
    } else if (id === 'TAKEOUT_OUT_DOWNLOAD_MAIN') {
      excelDownClicked('main');
    } else if (id === 'TAKEOUT_OUT_DOWNLOAD_DETAIL') {
      excelDownClicked('detail');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState();
  const functions = fetchUsableFunctionsInThisTab('STOCKREMOVE_TAB3');
  useMemo(() => {
    if (gridId) {
      const btns = printFunctionToBtns(functions, gridButtonhandler);
      setFunctionBtns(btns);
    }
  }, [gridId, searchObj]);

  return (
    <>
      {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
      <div style={{ width: '100%' }}>
        {gridId && (
          <>
            <Grid
              id={gridId}
              rows={rows}
              columns={columns}
              onCellClick={onCellClick}
              onEditEnded={onEditEnded}
              pagingSetting={pagingSetting}
              setPagingSetting={setPagingSetting}
              getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
              setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
              settingOptions={{
                fixedColumnCount: 4,
                showPageRowSelect: false,
                showRowAllCheckBox: true,
                editable: true,
                showRowCheckColumn: true,
              }}
            />
            <PrintGridBox gridRef={excelGridRef} columns={columns} />
            <PrintGridBox gridRef={excelDetailGridRef} columns={excelDetailColumns} />
          </>
        )}
      </div>
    </>
  );
};

const PhotoPopup = ({ photoPopupObj, setPhotoPopupObj, fetchPhotos }) => {
  const ImageModal = (props) => {
    const { title = '', className, src } = props;
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
      <div>
        <img alt="" src={src} style={{ width: 45, height: 45, cursor: 'pointer' }} onClick={toggle} />
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <ModalBody>
            <div style={{ textAlign: 'center' }}>
              <img alt="" style={{ maxWidth: 400 }} src={src} />
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    );
  };

  const handleFileUpload = async (e, index) => {
    const contentArr = e.target.id.split('_');
    const content = contentArr[0];
    const idx = Number(contentArr[1]);
    const ret = await handleFile(e);
    const uploadUrl = await uploadFile(ret.file, ret.url, `${content}_${idx}`);
    const data = {
      loadingStatementSeq: photoPopupObj?.photos?.loadingStatementSeq,
      addFiles: [
        {
          column: `OUTGOING${index + 1}`,
          uploadUrl,
        },
      ],
    };

    await httpClient.post(TAKE_OUT_LOADINGOUT_IMAGE_SAVE, data).then((res) => {
      if (res?.status === 200) {
        alert('사진 업로드에 성공하였습니다!');
        fetchPhotos(photoPopupObj?.data);
      }
    });
  };

  const handleFileRemove = async (e, index) => {
    const contentArr = e.target.id.split('_');
    const idx = Number(contentArr[1]) + 1;

    const prompt = window.confirm('사진을 정말 삭제하시겠습니까?');
    if (prompt) {
      const data = {
        loadingStatementSeq: photoPopupObj?.photos?.loadingStatementSeq,
        deleteFiles: [
          {
            column: `OUTGOING${idx}`,
          },
        ],
      };
      await httpClient.post(TAKE_OUT_LOADINGOUT_IMAGE_SAVE, data).then((res) => {
        if (res?.status === 200) {
          fetchPhotos(photoPopupObj?.data);
        }
      });
    }
  };

  const [files, setFiles] = useState();

  useEffect(() => {
    if (photoPopupObj?.photos) {
      setPhotos();
    }
  }, [photoPopupObj?.photos]);

  const setPhotos = () => {
    const photos = new Array(10).fill(null);
    photoPopupObj?.photos?.files.forEach((file) => {
      const i = Number(file?.column.split('OUTGOING')[1]) - 1;
      photos[i] = file.uploadUrl;
    });
    setFiles(photos);
  };

  return (
    <>
      <CommonModalNew
        title="사진 모아보기"
        subTitle={`출고지시번호 ${photoPopupObj?.photos?.loadingStatementSeq}`}
        visible={photoPopupObj?.visible}
        setVisible={() => {
          setPhotoPopupObj({
            ...photoPopupObj,
            visible: false,
          });
        }}
        children={
          <>
            <Row>
              <div className="moa-bogi">
                <Row>
                  {files?.map((url, i) => {
                    return (
                      <Col key={`outgoingPhoto_${i}_${uuidv4()}`}>
                        <div className="photo-zone">
                          <span className="bck-font"> 사진 {i + 1}</span>
                          {url ? (
                            <div style={{ display: 'inline-block' }}>
                              <img
                                src={CloseImg}
                                id={`outgoingPhoto_${i}_`}
                                alt=""
                                onClick={(e) => {
                                  handleFileRemove(e, i);
                                }}
                                className="closeBtn"
                              />
                              <ImageModal className="top_bar_profile_img" src={url ? url : null} alt="이미지미리보기" title={`사진 ${i + 1}`} />
                            </div>
                          ) : (
                            <>
                              <input style={{ display: 'none' }} type="file" accept="image/*" id={`outgoingPhoto_${i}`} className="outgoingPhoto" onChange={(e) => handleFileUpload(e, i)} />

                              <Button size="md" color="primary" outline>
                                <Label for={`outgoingPhoto_${i}`} style={{ marginBottom: 0 }}>
                                  ㅤㅤㅤ파일 선택ㅤㅤㅤ
                                </Label>
                              </Button>
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Row>
          </>
        }
      />
    </>
  );
};
