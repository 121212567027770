import { VALUES } from 'common/constants/appearance';

export function calculateElementPositionAndSize(target: Element) {
  if (!target) return;

  const targetRect = target?.getBoundingClientRect();
  const targetPosition = {
    x: targetRect.left,
    y: targetRect.top,
    width: targetRect.width,
    height: targetRect.height,
  };

  return targetPosition;
}

/*
 * parameter로는 - width : 그리드박스의 너비, childrenCount : child 요소들의 길이, columnCount : 현재 grid에 할당된 count
 *
 * 계산 방식 :
 * - 먼저 childrenCount와 columnCount를 비교해서 두 값이 같다면 childrenCount를, 아니라면 columnCount를 targetColumnCount 값으로 사용한다.
 * - 최소 너비를 186px로 잡고
 * - (gap 간격을 count-1 한 값을 곱해서 뺀값을 구하여) width에서 뺀 값을 구한다.
 * - 이 값을 targetColumnCount로 나누어서 186보다 크다면 targetColumnCount를 그대로 리턴하고
 * - 아니라면 targetColumnCount를 2로 나눈 값을 리턴한다.
 *
 * 예를들어 너비가 1200px이고 카운트가 6인 경우, 1200 - (5*16) / 6 = 186.6이 나오기 때문에 6을 절반으로 나눈 3이 리턴된다.
 */
export function calculateGridColumnCount(width: number, childrenCount: number, columnCount?: number) {
  const targetColumnCount = childrenCount === columnCount ? childrenCount : columnCount;
  const eachItemWidthWithChildren = (width - VALUES.GRID_GAP * (childrenCount - 1)) / childrenCount;
  const eachItemWidthWidthColumnCount = (width - VALUES.GRID_GAP * (childrenCount - 1)) / targetColumnCount;

  /*
  console.log(
    '요소 길이 :',
    childrenCount,
    '/ 현재 컬럼 카운트 :',
    columnCount,
    '/ 기준 카운트 :',
    targetColumnCount,
    '/ 요소 길이로 나눈 값 :',
    eachItemWidthWithChildren,
    '/ 현재 컬럼 기준으로 나눈 값 :',
    eachItemWidthWidthColumnCount,
  ); */

  if (eachItemWidthWidthColumnCount > VALUES.FIELD_ITEM_MIN_WIDTH * 2) return targetColumnCount * 2 > childrenCount ? childrenCount : targetColumnCount * 2;
  else if (eachItemWidthWidthColumnCount > VALUES.FIELD_ITEM_MIN_WIDTH) return targetColumnCount;
  else return Math.ceil(targetColumnCount / 2);
}
