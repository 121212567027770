import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './orderHistory';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';
export const ORDERHISTORY_MASTER = [
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_AUTH',
  'SELLER_WHOLE',
  //
  'INVOICE_UPDATE_CONTENT',
  //
  {
    SEARCH_INVOICE: [
      {
        label: '송장번호',
        value: 'invoiceSeq',
      },
      {
        label: '외부송장번호',
        value: 'externalInvoiceNumber',
      },
      {
        label: '운송장번호',
        value: 'parcelInvoiceNumber',
      },
    ],
  },
  {
    SEARCH_ORDER: [
      {
        label: '주문번호',
        value: 'orderSeq',
      },
      {
        label: '외부주문번호',
        value: 'externalOrderNumber',
      },
      {
        label: '배송번호',
        value: 'deliverySeq',
      },
    ],
  },
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(ORDERHISTORY_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            //
            ORDER_HISTORY_TAB1: <Tab1 tabId={'ORDER_HISTORY_TAB1'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
