import { ReactNode, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

// service
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CreditLoanListDTO, CreditLoanSearchDTO } from '../../../_interface/parcel';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
// component
import { PagingListDTO } from 'interface/util';
import { serviceStore } from 'services/services';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { CuObjModal, ICuOjModal } from './component/cuObjModal';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';

export const defaultOrderDetailObj = {
  visible: false,
  item: null,
};

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<AUIGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();

  const [cuObj, setCuObj] = useState<ICuOjModal>();
  const labellingKr = (data: PagingListDTO<CreditLoanListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_FORWARDER_WHOLE?.[row?.partnerSeq],
        statusKr: MASTER_OBJ?.CREDIT_LOAN_STATUS?.[row?.status],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };
  const openLoanDetail = async (e: IGrid.ButtonClickEvent) => {
    setCuObj({
      visible: true,
      data: e.item,
      searchObj,
      fetchFn: wrappedFetchList,
    });
  };

  const fetchParcelDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const rows = (await serviceStore.parcelAction('fee/credit/loan/invoice/detail', 'GET', { creditLoanSeq: e.item.creditLoanSeq, pageNo: 1, pageSize: 5000 }, null, true))?.data?.list;

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '운송장상세 내역',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          buttons: [],
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
      ],
    });
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '여신정보',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: openLoanDetail,
      },
    },
    {
      headerText: '운송장',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchParcelDetail,
      },
    },
    {
      headerText: '판매사코드',
      dataField: 'partnerSeq',
    },
    {
      headerText: '판매사명',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '여신한도액',
      dataField: 'maximumLoan',
      dataType: 'numeric',
      style: 'sub-total-color',
    },
    {
      headerText: '여신한도사용액',
      dataField: 'used',
      dataType: 'numeric',
      style: 'sub-total-color',
    },
    {
      headerText: '여신잔여액',
      dataField: 'balance',
      dataType: 'numeric',
      style: 'sub-total-color',
    },
    {
      headerText: '여신한도초과액',
      dataField: 'excess',
      dataType: 'numeric',
      style: 'sub-total-color',
    },
    {
      headerText: '여신기간설정',
      dataField: 'creditLoanDateKr',
      dataType: 'numeric',
    },
    {
      headerText: '결제상태',
      dataField: 'statusKr',
    },
    {
      headerText: '결제완료일',
      dataField: 'confirmId',
    },
    {
      headerText: '결제완료자',
      dataField: 'confirmDate',
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '집화일자',
      dataField: 'collectDate',
    },
    {
      headerText: '작업구분',
      dataField: 'workType',
    },
    {
      headerText: '운송장번호',
      dataField: 'parcelInvoiceNumber',
    },
    {
      headerText: '송하인명',
      dataField: 'senderName',
    },
    {
      headerText: '수하인명',
      dataField: 'receiverName',
    },
    {
      headerText: '박스수량',
      dataField: 'totalBoxQuantity',
    },
    {
      headerText: '운임구분',
      dataField: 'payTypeKr',
    },
    {
      headerText: '운임합계',
      dataField: 'totalFee',
    },
    {
      headerText: '기본운임',
      dataField: 'price',
    },
    {
      headerText: '별도운임',
      dataField: 'extraFee',
    },
  ];

  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();
  const fetchPaging = async (searchObj: CreditLoanSearchDTO) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore?.parcelAction('fee/credit/loan/paging', 'GET', searchObj, null, true))?.data as PagingListDTO<CreditLoanListDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 500,
    },
    gridRef,
    fetchPaging,
  });

  const completeLoans = async (items: CreditLoanListDTO[]) => {
    if (window.confirm('완료처리 하시겠습니까?')) {
      const rs = await serviceStore?.parcelAction(
        'fee/credit/loan/confirm',
        'PUT',
        null,
        items?.map((ele) => {
          return {
            creditLoanSeq: ele.creditLoanSeq,
          };
        }),
      );
      if (rs?.status === 200) {
        alert('완료처리 되었습니다!');
        wrappedFetchList(searchObj);
      }
    }
  };

  const deleteLoans = async (items: CreditLoanListDTO[]) => {
    //
    if (window.confirm('삭제 하시겠습니까?')) {
      const rs = await serviceStore?.parcelAction(
        'fee/credit/loan',
        'DELETE',
        null,
        items?.map((ele) => {
          return {
            creditLoanSeq: ele.creditLoanSeq,
          };
        }),
      );
      if (rs?.status === 200) {
        alert('삭제처리 되었습니다!');
        wrappedFetchList(searchObj);
      }
    }
  };

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.parcelAction(`${type === 'main' ? 'fee/credit/loan/excel' : 'fee/credit/loan/invoice/detail/excel'}`, 'GET', searchObj, null))?.data as CreditLoanListDTO[],
      },
      true,
    );

    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '여신관리' });
    } else {
      excelDetailGridRef.current.setGridData(list);
      excelDetailGridRef.current.exportAsXlsx({ fileName: '여신운송장' });
    }

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'CREATE_PARCEL_FEE_LOAN') {
      setCuObj({
        visible: true,
        data: null,
        searchObj,
        fetchFn: wrappedFetchList,
      });
    } else if (id === 'PARCEL_FEE_LOAN_EXCELMAIN') {
      downloadExcel('main');
    } else if (id === 'PARCEL_FEE_LOAN_EXCELDETAIL') {
      downloadExcel('detail');
    } else {
      handleCheckItems(id);
    }
  };
  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'PARCEL_FEE_LOAN_COMPLETE') {
        completeLoans(items);
      } else if (id === 'PARCEL_FEE_LOAN_DELETE') {
        deleteLoans(items);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {cuObj?.visible && <CuObjModal cuObj={cuObj} setCuObj={setCuObj} />}
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}

        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 2,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={detailColumns} />
      </div>
    </div>
  );
};

export default Index;
