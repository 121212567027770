import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { SelectD } from 'components/reactstrap/reactstrap';
import { CustomerOrderMoveSearchDTOSecond } from '../../../_interface/order';
import { orderService } from 'pages/ORDER/1order/_services/service';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import { setLoading } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';

export interface IObj {
  visible: boolean;
  item?: string[];
  searchObj?: string;
  fetchFn: (data: CustomerOrderMoveSearchDTOSecond) => {};
}

interface IPoupObj {
  popupObj: IObj;
  setPopupObj: React.Dispatch<React.SetStateAction<IObj>>;
}
export const CreatePopup = ({ popupObj, setPopupObj }: IPoupObj) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [centerAddDiff, setCenterAddDiff] = useState<OptionItem[]>();
  const [destin, setDestin] = useState<string>();

  useEffect(() => {
    const centerAddDiff = [...MASTER_OPS?.CENTER_AUTH];
    const centerCodes = centerAddDiff?.map((ele) => ele.value);
    MASTER_OPS?.CENTER_DIFF?.forEach((center) => {
      if (!centerCodes?.includes(center?.value)) centerAddDiff?.push(center);
    });
    setCenterAddDiff(centerAddDiff);
  }, []);

  const sortingGroup = (data) => {
    const sortedGroup = {};
    data.forEach((ele) => {
      if (!sortedGroup[ele.customerOrderSeq]) sortedGroup[ele.customerOrderSeq] = [];
      if ((ele.feeType === 'MOVEOUT' || ele.feeType === 'DPOUT') && !ele.invoiceStatus.includes('CANCEL') && !ele.invoiceStatus.includes('PEND')) sortedGroup[ele.customerOrderSeq].push(ele);
      if ((ele.feeType === 'MOVE' || ele.feeType === 'DP') && !ele.invoiceStatus.includes('CANCEL') && !ele.invoiceStatus.includes('PEND')) sortedGroup[ele.customerOrderSeq].push(ele);
    });
    return sortedGroup;
  };
  const letsCreate = async (center?: string) => {
    dispatch(setLoading('PUT'));
    const sorted = sortingGroup(popupObj.item);
    const seqs = Object.keys(sorted); // 한 주문번호에
    const data = seqs?.map((seq) => {
      return {
        originalCenterCode: sorted[seq][0]?.centerCode,
        destinationCenterCode: sorted[seq][1]?.centerCode,
        moveOutInvoiceSeq: sorted[seq][0]?.invoiceSeq,
        moveInvoiceSeq: sorted[seq][1]?.invoiceSeq,
        stopoverCenterCode: center || null,
      };
    });
    const rs = await orderService.postCreatePickingForRelocateOrder(data);
    if (rs?.status === 200) {
      alert('간선피킹 생성(이전)에 성공하였습니다!');
      if (popupObj?.searchObj) {
        popupObj?.fetchFn(JSON.parse(popupObj?.searchObj));
        setPopupObj(null);
      }
    }
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      //
      title="간선 피킹 생성(이전)"
      style={{ width: 400 }}
      visible={popupObj?.visible}
      setVisible={() => setPopupObj(null)}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={() => {
              letsCreate(destin);
            }}
          >
            생성
          </button>
        </>
      }
      children={
        <>
          <Row style={{ height: 60 }}>
            <label className="col-form-label">경유지 추가</label>
            <SelectD
              options={centerAddDiff}
              value={
                destin
                  ? {
                      value: destin,
                      label: selectlabel(destin, centerAddDiff),
                    }
                  : null
              }
              onChange={(option) => {
                setDestin((option as OptionItem).value);
              }}
            />
          </Row>
        </>
      }
    />
  );
};
