import { LuMaximize2, LuMinimize2, LuSearch } from 'react-icons/lu';
import LDSButton from '../../atoms/Button';
import { LABELS } from 'common/constants/localization';
import styled from 'styled-components';
import { ButtonGroup } from '../../atoms/ButtonGroup';
import { LDSSpacing } from '../../atoms/Spacing';
import { useDropdown } from '../../hooks/useDropdown';
import { cloneElement, useEffect, useMemo, useRef, useState } from 'react';
import { VALUES } from 'common/constants/appearance';
import { Children } from 'react';
import { calculateElementPositionAndSize, calculateGridColumnCount } from 'common/util/handleElement';
import LDSTagGroup from '../../modules/TagGroup';
import { useSearchBox } from './Searchbox.hooks';
import { SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
import { UnionFormTypeProps } from 'components/LDS/types/types';
import React from 'react';

function LDSSearchBox({ children, handleSubmit, onSubmit }: { children?: React.ReactNode; handleSubmit?: UseFormHandleSubmit<UnionFormTypeProps>; onSubmit?: SubmitHandler<UnionFormTypeProps> }) {
  const gridRef = useRef(null);
  // TODO: masterOptions를 받아서 그리기 (개별 컴포넌트에서 하는게?)

  const { windowWidth, handleResize } = useDropdown();
  const childrenCount = useMemo(() => Children.count(children), [children]); // NOTE: GRID, TAG 2개씩 children이 들어오므로

  const { columnCount, setColumnCount, toggleViewType, handleCustomSubmit, searchBoxViewType } = useSearchBox(childrenCount);

  // const gridTypeChildren = ({ children }) => {
  //   const ModifiedChildren = () =>
  //     Children.map(children, (child) => {
  //       if (child.type.name === 'LDSFieldItem') return cloneElement(child, {});
  //     });
  //   return <ModifiedChildren />;
  // };

  // const tagTypeChildren = ({ children }) => {
  //   const ModifiedChildren = () =>
  //     Children.map(children, (child) => {
  //       if (child.type.name === 'LDSTagItem') return cloneElement(child, { $option: null }); //TODO: $option 값을 동적으로 주입할 수 있도록 수정 필요
  //     });
  //   return <ModifiedChildren />;
  // };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      // cleanup
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (searchBoxViewType === 'DEFAULT') {
      const { width } = calculateElementPositionAndSize(gridRef?.current);
      const gridCount = calculateGridColumnCount(width, childrenCount, columnCount);
      setColumnCount(gridCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, searchBoxViewType]);

  return (
    <FilterForm onSubmit={handleSubmit ? handleSubmit(onSubmit) : handleCustomSubmit}>
      {searchBoxViewType === 'DEFAULT' && (
        <>
          <FilterGrid ref={gridRef} $gridColumn={columnCount}>
            {/* {gridTypeChildren({ children })} */}
            {children}
          </FilterGrid>
          <LDSSpacing $direction="V" $value={28} />
          <ButtonGroup $align={'CENTER'}>
            <LDSButton $type={'SUBMIT'} $size={'LG'} $primary={true} $label={LABELS.SEARCH_KR} $style={'FILL'} $usage={'PRIMARY'} $showIcon={true} $icon={<LuSearch />} />
            <LDSButton
              $type={'SUBMIT'}
              $size={'LG'}
              $primary={true}
              $label={LABELS.OPTIMIZE_FILTER_KR}
              $style={'OUTLINED'}
              $usage={'PRIMARY'}
              $showIcon={true}
              $icon={<LuMinimize2 />}
              onClick={toggleViewType}
            />
          </ButtonGroup>
        </>
      )}
      {searchBoxViewType === 'OPTIMIZED' && (
        <OptimzedViewContainer>
          <LDSTagGroup $gap={8} $padding={0}>
            {/* {tagTypeChildren({ children })} */}
            {children}
            <LDSButton
              $icon={<LuMaximize2 />}
              $width={36}
              $size={'XS'}
              $primary={false}
              $type={'BUTTON'}
              $showIcon={true}
              $style={'OUTLINED'}
              $isCircled={true}
              onClick={toggleViewType}
              $tooltip={{
                $description: '필터 확장',
                $direction: 'BOTTOM',
                $anchorPosition: 'CENTER',
              }}
            />
          </LDSTagGroup>
          <LDSButton $size={'MD'} $width={120} $label={LABELS.SEARCH_KR} $primary={true} $type={'SUBMIT'} $style={'FILL'} $isCircled={true} $usage={'PRIMARY'} />
        </OptimzedViewContainer>
      )}
      <LDSSpacing $direction="V" $value={32} />
    </FilterForm>
  );
}

export default React.memo(LDSSearchBox);

type FilterGridProps = {
  $gridColumn?: number;
};

const FilterGrid = styled.div<FilterGridProps>`
  display: grid;
  width: inherit;
  gap: ${VALUES.GRID_GAP}px;
  grid-template-columns: repeat(${(props) => props.$gridColumn || 3}, 1fr);
`;

const FilterForm = styled.form`
  width: 100%;
`;

const OptimzedViewContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;

  button {
    flex-shrink: 0;
  }
  ul {
    .gap {
      flex-grow: 1;
    }

    & > button {
    min-height: 36px;

    &[type='submit'] {
      justify-self: flex-end;
    }
  }
`;
