import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as IGrid from 'aui-grid';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { Col, Row, Form } from 'reactstrap';
import { LinehaulDetailDTOForCreateSecond, LinehaulImportSearchDTO, InventoryModelSearchDTO } from 'interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';
import { resetAllSelections } from 'common/util/searchBox';
import { locationService } from 'pages/INVENTORY/3location/_services/service';

import AUIGrid, { mappingDefaultOptionToColumn } from 'modules/Grid';
import GridBox, { defaultGridProps } from 'common/grid/gridBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export interface IModelObj {
  visible?: boolean;
  type?: 'picked' | 'model';
  postObj?: LinehaulDetailDTOForCreateSecond;
  returns?: any[];
}

export const ModelPopup = ({ modelObj, setModelObj }) => {
  const gridRef = useRef<AUIGrid>();
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);
  const [searchObj, setSearchObj] = useState<LinehaulImportSearchDTO & InventoryModelSearchDTO>(
    modelObj?.type === 'model'
      ? {
          ...defaultSearchFilter,
          centerCode: modelObj?.postObj?.originalCenterCode,
        }
      : {
          originalCenterCode: modelObj?.postObj?.originalCenterCode,
          destinationCenterCode: modelObj?.postObj?.destinationCenterCode,
          fromLinehaulDate: modelObj?.postObj?.linehaulDate,
          toLinehaulDate: modelObj?.postObj?.linehaulDate,
        },
  );
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SEQ, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ]);
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const columns: { [key: string]: IGrid.Column[] } = {
    picked: [
      {
        headerText: '피킹지시번호',
        dataField: 'linehaulSeq',
      },
      {
        headerText: '출고예정일',
        dataField: 'linehaulDate',
      },
      {
        headerText: '출고창고',
        dataField: 'originalCenterCodeKr',
      },
      {
        headerText: '입고창고',
        dataField: 'destinationCenterCodeKr',
      },
      {
        headerText: '간선유형',
        dataField: 'linehaulTypeKr',
      },
      {
        headerText: '합계',
        dataField: 'quantity',
      },
      {
        headerText: '간선피킹대상수량',
        children: [
          {
            headerText: '양품',
            dataField: 'availableQuantity',
            editable: false,
          },
          {
            headerText: '불용',
            dataField: 'defectedQuantity',
            editable: false,
          },
        ],
      },
      {
        headerText: '진행상태',
        dataField: 'statusKr',
      },
    ],
    model: [
      {
        headerText: '출고창고',
        dataField: 'originalCenterCodeKr',
      },
      {
        headerText: '제품정보',
        children: [
          {
            headerText: '로케이션',
            dataField: 'locationCode',
            filter: {
              showIcon: true,
            },
          },
          {
            headerText: '제품그룹',
            dataField: 'modelGroupKr',
            filter: {
              showIcon: true,
            },
          },
          {
            headerText: '판매사',
            dataField: 'partnerSeqKr',
            filter: {
              showIcon: true,
            },
          },
          {
            headerText: '제품',
            dataField: 'model',
            filter: {
              showIcon: true,
            },
          },
          {
            headerText: '제품명',
            dataField: 'modelName',
            filter: {
              showIcon: true,
            },
          },
          {
            headerText: 'SKU',
            dataField: 'modelStockSeq',
            filter: {
              showIcon: true,
            },
          },
          {
            headerText: '외부SKU',
            dataField: 'skuNumber',
            filter: {
              showIcon: true,
            },
          },
          {
            headerText: '바코드',
            dataField: 'barcode',
            filter: {
              showIcon: true,
            },
          },
        ],
      },
      {
        headerText: '현재보관수량',
        children: [
          {
            headerText: '양품',
            dataField: 'totalAvailableQuantity',
            editable: false,
            filter: {
              showIcon: true,
            },
          },
          {
            headerText: '불용',
            dataField: 'totalDefectedQuantity',
            editable: false,
            filter: {
              showIcon: true,
            },
          },
          {
            headerText: '이동가능양품',
            dataField: 'usableAvailableQuantity',
            editable: false,
            filter: {
              showIcon: true,
            },
          },
          {
            headerText: '이동가능불용',
            dataField: 'usableDefectedQuantity',
            editable: false,
            filter: {
              showIcon: true,
            },
          },
        ],
      },
      {
        headerText: '간선피킹대상수량',
        children: [
          {
            headerText: '양품',
            dataField: 'availableQuantity',
            style: 'aui-textinputer',
            editable: true,
            editRenderer: {
              type: IGrid.EditRendererKind.InputEditRenderer,
              validator: (oldValue: any, newValue: any, item: any, dataField: string, fromClipboard: boolean, which: any) => {
                if (item?.usableAvailableQuantity === 0) return { validate: false, message: '이동가능한 수량이 없습니다' };
                if (item?.usableAvailableQuantity < newValue) return { validate: false, message: '이동가능한 수량 초과' };
              },
              autoThousandSeparator: true,
              onlyNumeric: true,
              allowNegative: false,
            },
          },
          {
            headerText: '불용',
            dataField: 'defectedQuantity',
            style: 'aui-textinputer',
            editable: true,
            editRenderer: {
              type: IGrid.EditRendererKind.InputEditRenderer,
              validator: (oldValue: any, newValue: any, item: any, dataField: string, fromClipboard: boolean, which: any) => {
                if (item?.usableDefectedQuantity === 0) return { validate: false, message: '이동가능한 수량이 없습니다' };
                if (item?.usableDefectedQuantity < newValue) return { validate: false, message: '이동가능한 수량 초과' };
              },
              autoThousandSeparator: true,
              onlyNumeric: true,
              allowNegative: false,
            },
          },
        ],
      },
    ],
  };

  const validate = (items) => {
    if (modelObj?.type === 'model') {
      const noQuantity = items?.filter((ele) => !ele.availableQuantity && !ele.defectedQuantity);
      if (noQuantity?.length > 0) {
        alert('수량이 입력되지 않은 제품이 있습니다!');
        return false;
      }
    }
    return true;
  };
  const findDetails = async (searchObj) => {
    dispatch(setLoading('GET'));
    const data = await locationService.getWMSAction('linehaul/picking/import/detail/list', searchObj, false);
    dispatch(setLoading(null));
    if (data?.length === 0) {
      alert('조회되는 상세 정보가 없어 추가 할 수 없습니다');
      return [];
    } else {
      const rows = data?.map((row) => {
        return {
          ...row,
          ...searchObj,
        };
      });
      return labellingKrForDetail(rows);
    }
  };

  const returnThese = async () => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (validate(items)) {
        if (modelObj?.type === 'picked') {
          const returns = await findDetails(items[0]);
          console.log(returns);
          if (returns?.length > 0) {
            setModelObj((prev) => {
              return {
                ...prev,
                returns,
              };
            });
          }
        } else {
          setModelObj((prev) => {
            return {
              ...prev,
              returns: items,
            };
          });
        }
      }
    } else {
      alert('선택한 제품이 없습니다');
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchPaging(searchObj);
    }
  };

  const labellingKrForDetail = (data) => {
    const labeledList = data?.map((row) => {
      return {
        ...row,
        partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
        supplierPartnerSeqKr: MASTER_OBJ?.SELLER_SUPPLIER_WHOLE?.[row?.supplierPartnerSeq],
        modelTypeKr: MASTER_OBJ?.MODEL_TYPE?.[row?.modelType],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        linehaulTypeKr: MASTER_OBJ?.LINEHAUL_TYPE?.[row?.linehaulType],
        statusKr: MASTER_OBJ?.LINE_STAT?.[row?.status],
        originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.originalCenterCode ? row?.originalCenterCode : row?.centerCode],
        destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.destinationCenterCode],
        availableQuantity: row.importAvailableQuantity,
        defectedQuantity: row.importDefectedQuantity,
      };
    });
    return labeledList;
  };

  const labellingKr = (data) => {
    const labeledList = data?.map((row) => {
      return {
        ...row,
        partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
        supplierPartnerSeqKr: MASTER_OBJ?.SELLER_SUPPLIER_WHOLE?.[row?.supplierPartnerSeq],
        modelTypeKr: MASTER_OBJ?.MODEL_TYPE?.[row?.modelType],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        linehaulTypeKr: MASTER_OBJ?.LINEHAUL_TYPE?.[row?.linehaulType],
        statusKr: MASTER_OBJ?.LINE_STAT?.[row?.status],
        originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.originalCenterCode ? row?.originalCenterCode : row?.centerCode],
        destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.destinationCenterCode],
        usableAvailableQuantity: modelObj?.type === 'model' ? row.availableQuantity : row.usableAvailableQuantity,
        usableDefectedQuantity: modelObj?.type === 'model' ? row.defectedQuantity : row.usableDefectedQuantity,
        availableQuantity: modelObj?.type === 'model' ? 0 : row.availableQuantity,
        defectedQuantity: modelObj?.type === 'model' ? 0 : row.defectedQuantity,
      };
    });
    return labeledList;
  };

  const fetchPaging = async (searchObj: LinehaulImportSearchDTO & InventoryModelSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(
      await locationService.getWMSAction(modelObj?.type === 'model' ? 'inventory/centerCode/locationCode/modelStock/movable/list' : 'linehaul/picking/import/list', searchObj, true),
    );
    gridRef?.current?.setGridData(dataKr);
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title={modelObj?.type === 'picked' ? '피킹지시 불러오기' : '+안전재고 제품추가'}
      subTitle={modelObj?.type === 'picked' ? '' : `출고창고: ${MASTER_OBJ?.CENTER_WHOLE?.[modelObj?.postObj?.originalCenterCode]}`}
      style={{ width: 1300 }}
      visible={modelObj?.visible}
      setVisible={() => {
        setModelObj(null);
      }}
      children={
        <>
          <Form onKeyPress={onKeyPress}>
            {modelObj?.type === 'picked' ? (
              <>
                <Row>
                  <Col>
                    <label>피킹지시번호</label>
                    <InputD
                      type="number"
                      value={searchObj?.linehaulSeq || ''}
                      onChange={(e) => {
                        setSearchObj({
                          ...searchObj,
                          linehaulSeq: Number(e.target.value),
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label className="col-form-label">*출고 예정일</label>
                    <FlatpickrD
                      value={[searchObj?.fromLinehaulDate, searchObj?.toLinehaulDate]}
                      onChange={(value) => {
                        setSearchObj({
                          ...searchObj,
                          fromLinehaulDate: returnDateyyyymmdd(value[0]),
                          toLinehaulDate: returnDateyyyymmdd(value[1]),
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label className="col-form-label">*출고창고</label>
                    <SelectD
                      value={
                        searchObj?.originalCenterCode
                          ? {
                              value: searchObj?.originalCenterCode,
                              label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.originalCenterCode],
                            }
                          : null
                      }
                      isDisabled
                    />
                  </Col>
                  <Col>
                    <label className="col-form-label">*입고창고</label>
                    <SelectD
                      isDisabled
                      value={
                        searchObj?.destinationCenterCode
                          ? {
                              value: searchObj?.destinationCenterCode,
                              label: MASTER_OBJ?.CENTER_DIFF?.[searchObj?.destinationCenterCode],
                            }
                          : null
                      }
                    />
                  </Col>
                  <Col>
                    <div style={{ paddingTop: 10, textAlign: 'left' }}>
                      <button
                        className="btn btn-search"
                        onClick={(e) => {
                          e.preventDefault();
                          fetchPaging(searchObj);
                        }}
                      >
                        조회
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col>
                  <label>제품타입</label>
                  <SelectD
                    options={MASTER_OPS?.MODEL_TYPE}
                    value={
                      searchObj?.modelType
                        ? {
                            value: searchObj?.modelType,
                            label: MASTER_OBJ?.MODEL_TYPE?.[searchObj?.modelType],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          modelType: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label>제품그룹</label>
                  <SelectD
                    options={MASTER_OPS?.MODEL_GROUP}
                    value={
                      searchObj?.modelGroup
                        ? {
                            value: searchObj?.modelGroup,
                            label: MASTER_OBJ?.MODEL_GROUP?.[searchObj?.modelGroup],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          modelGroup: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label>제품</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={4} style={{ display: 'inline-block' }}>
                      <SelectD
                        value={
                          selectTypeObj?.SEARCH_MODEL
                            ? {
                                value: selectTypeObj?.SEARCH_MODEL,
                                label: MASTER_OBJ?.SEARCH_MODEL[selectTypeObj?.SEARCH_MODEL],
                              }
                            : null
                        }
                        options={MASTER_OPS?.SEARCH_MODEL}
                        onChange={(option) => {
                          setSelectTypeObj({
                            ...selectTypeObj,
                            SEARCH_MODEL: (option as OptionItem).value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={8} style={{ display: 'inline-block' }}>
                      <InputD
                        disabled={!selectTypeObj?.SEARCH_MODEL}
                        value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                        onChange={(e) => {
                          setSearchObj({
                            ...searchObj,
                            [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </div>
                </Col>
                <Col>
                  <label className="col-form-label">유사검색여부</label>
                  <div className="radio-select-area">
                    <span
                      className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                      onClick={() => {
                        setSearchObj((prev) => {
                          return {
                            ...prev,
                            similaritySearchFlag: true,
                          };
                        });
                      }}
                    >
                      유사검색
                    </span>
                    <span
                      className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                      onClick={() => {
                        setSearchObj((prev) => {
                          return {
                            ...prev,
                            similaritySearchFlag: false,
                          };
                        });
                      }}
                    >
                      일치검색
                    </span>
                  </div>
                </Col>
                <Col>
                  <div style={{ paddingTop: 10, textAlign: 'left' }}>
                    <button
                      className="btn btn-search"
                      onClick={(e) => {
                        console.log('search');
                        e.preventDefault();
                        fetchPaging(searchObj);
                      }}
                    >
                      조회
                    </button>
                  </div>
                </Col>
              </Row>
            )}
          </Form>
          <div className="presenterGridBox">
            <div className="grid-button-area only-right">
              <div
                className="blue"
                onClick={(e) => {
                  e.preventDefault();
                  returnThese();
                }}
              >
                +추가
              </div>
            </div>
            <GridBox
              gridRef={gridRef}
              columns={columns[modelObj?.type]}
              gridProps={{
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
                editable: true,
                rowCheckToRadio: modelObj?.type === 'picked',
              }}
            />
          </div>
        </>
      }
    />
  );
};
