import { useEffect, useState } from 'react';

// img
import unfav from 'assets/images/button/star-regular.svg';
import fav from 'assets/images/button/star-solid.png';

import dropdownopen from 'assets/images/close/dropdownopen.png';
import dropdownclose from 'assets/images/close/dropdownclose.png';
import closed1 from '../_img/closedFolder1.png';
import open1 from '../_img/openFolder1.png';
import close from 'assets/images/close/cross.png';
import list from 'assets/images/close/list.svg';

import { Link, useLocation } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';

import { setAuth } from 'redux/services/authSlice';
import { httpClient } from 'common/http-client/axiosConfig';
import { MY_BOOKMARK } from 'envVar';
import { getMyBookmarks } from './layoutManager';
import { setNowMenu, setOpenOrcloseSide } from 'redux/services/menuSlice';
import { justServer } from 'common/util/isServer';
import useUseableMenus from 'hooks/user/useUseableMenus';
import { RootState } from 'redux/store';

export const SideMenu = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { getFetchUseableMenus } = useUseableMenus();
  const { nowMenu, openOrclose } = useSelector((state: RootState) => state.menu);
  const { useableMenus } = useSelector((state: RootState) => state.auth);
  const { headerObj, setHeaderObj, deleteMyBookmark, tokenValidFlag, setTokenValidFlag } = props;
  const [menus, setMenus] = useState([]);
  const [openedArr, setOpenedArr] = useState([]);

  useEffect(() => {
    if (tokenValidFlag && !useableMenus) {
      getMenuByUserInfo();
    }
  }, [tokenValidFlag]);

  useEffect(() => {
    if (menus?.length > 0) {
      // 처음 부팅될때
      initOpenDropdown(menus, location.pathname);
    }
  }, [menus]);

  useEffect(() => {
    if (headerObj?.recentVisits && nowMenu?.url) {
      const recentUrls = headerObj?.recentVisits.map((v) => v.url) || [];
      // if (!recentUrls?.includes(nowMenu?.url)) {
      //   addMyRecentVisit(nowMenu);
      // }
    }
  }, [headerObj?.recentVisits, nowMenu?.url]);

  const addMyBookmark = async (data) => {
    await httpClient.post(MY_BOOKMARK, data).then(async (res) => {
      if (res?.status === 200) {
        alert('즐겨찾기에 추가되었습니다.');
        setHeaderObj({
          ...headerObj,
          bookMarks: await getMyBookmarks(),
        });
      }
    });
  };

  const addMyRecentVisit = async (data) => {
    // await httpClient.post(MY_RECENTVISIT, data).then(async (res) => {
    //   if (res?.status === 200) {
    //     setHeaderObj({
    //       ...headerObj,
    //       recentVisits: await getMyRecentVisits(),
    //     });
    //   }
    // });
  };

  const openDropdown = (e) => {
    const idx = e.target.id.split('_')[1] + '_' + e.target.id.split('_')[2];
    if (openedArr.includes(idx)) {
      setOpenedArr(openedArr.filter((item) => item !== idx));
    } else {
      const menu = [...openedArr];
      menu.push(idx);
      setOpenedArr(menu);
    }
  };

  const splitMenus = (menu0) => {
    const collectedMenu = menu0?.map((menu1) => {
      const children1 = menu1?.childMenus?.map((menu2) => {
        const children2 = menu2?.childMenus?.map((menu3) => {
          return {
            url: menu3.url,
            name: menu3.menuName,
            menuId: menu3?.menuId,
            screenId: menu3.screenId,
          };
        });
        return {
          menuName: menu2.menuName,
          children: children2,
        };
      });
      return {
        menuName: menu1.menuName,
        children: children1,
      };
    });

    collectedMenu?.unshift({
      menuName: '홈',
      children: [
        {
          menuName: '홈',
          children: [
            {
              url: '/',
              name: '대시보드',
            },
          ],
        },
      ],
    });
    return collectedMenu;
  };
  const getMenuByUserInfo = async () => {
    if (userInfo) {
      console.log(userInfo);
      dispatch(setAuth(userInfo));
      const fetchMenu = splitMenus(await getFetchUseableMenus());

      setMenus(fetchMenu);
    }
  };

  const initOpenDropdown = (chosenMenuObj, pathname) => {
    if (pathname) {
      chosenMenuObj.forEach((menu1st, idx1) => {
        menu1st?.children?.forEach((menu2nd, idx2) => {
          menu2nd?.children?.forEach((menu3rd, idx3) => {
            if (menu3rd.url === pathname) {
              dispatch(
                setNowMenu({
                  url: menu3rd.url,
                  menuName: menu3rd.name,
                }),
              );
              if (!openedArr?.includes(`${idx1}_${idx2}`)) {
                setOpenedArr([`${idx1}_${idx2}`]);
              }
            }
          });
        });
      });
    }
  };

  const openORcloseSideMenu = () => {
    dispatch(setOpenOrcloseSide(openOrclose));
  };

  return (
    <div className={openOrclose?.side ? 'sidebar' : 'sidebar close'}>
      <div className="sideBar-head">
        <span className="menu-name">{nowMenu?.menuName}</span>
        <span className="menu-close-btn">
          <img src={openOrclose?.side ? close : list} alt="메뉴 창 닫기" style={{ width: 15, height: 15 }} onClick={openORcloseSideMenu} />
        </span>
      </div>

      <ul className="topnav">
        {menus?.map((data, seq) => {
          if (seq > 0)
            return (
              <li key={`menu1st_${seq}`} className={justServer('lfs-temp.') ? 'menu1st-li temp' : 'menu1st-li'}>
                <div className="menu-name">{data?.menuName}</div>
                <ul className="menu2nd-ul">
                  {data?.children?.map((listdata, idx) => {
                    return (
                      <li key={`openMenu2nd_${idx}`} className="dropdown-item menu2nd-li">
                        <div
                          className="menu-name"
                          id={`openMenu2nd_${seq}_${idx}`}
                          onClick={(e) => {
                            openDropdown(e);
                          }}
                        >
                          {justServer('lfs-temp.') ? (
                            <>
                              <img src={openedArr.includes(`${seq}_${idx}`) ? closed1 : open1} alt="드롭다운 닫기/ 열기" style={{ width: 20, marginRight: '0.7rem' }} />
                            </>
                          ) : (
                            <>
                              <img
                                src={openedArr.includes(`${seq}_${idx}`) ? dropdownclose : dropdownopen}
                                alt="드롭다운 닫기/ 열기"
                                style={{
                                  width: 10,
                                  height: 10,
                                  marginRight: '0.7rem',
                                }}
                              />
                            </>
                          )}
                          {listdata.menuName}
                        </div>
                        <ul
                          className="menu3rd-ul"
                          style={{
                            display: openedArr.includes(`${seq}_${idx}`) ? 'block' : 'none',
                          }}
                        >
                          {listdata?.children?.map((each, idx2) => {
                            const bookmarksArr = [];
                            headerObj?.bookMarks?.forEach((ele) => {
                              bookmarksArr.push(ele.url);
                            });
                            const hasTab = each?.name?.split('(')[1] && each?.name?.includes('/');
                            return (
                              <li
                                key={`openMenu3rd_${idx2}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (location.pathname !== each.url) {
                                    setTokenValidFlag(false);
                                    dispatch(
                                      setNowMenu({
                                        menuName: each.name,
                                        url: each.url,
                                      }),
                                    );
                                  }
                                }}
                                className={location.pathname === each.url ? 'menu3rd-li active' : 'menu3rd-li'}
                              >
                                <Link to={`${each.url}`}>
                                  <div className="menu-name">
                                    <img
                                      alt="호호"
                                      className={bookmarksArr.includes(each.url) ? 'twinkle fav-img' : 'fav-img'}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation(); //**
                                        if (bookmarksArr.includes(each.url)) {
                                          deleteMyBookmark({
                                            url: each.url,
                                            menuName: each.name,
                                          });
                                        } else {
                                          addMyBookmark({
                                            url: each.url,
                                            menuName: each.name,
                                          });
                                        }
                                      }}
                                      src={bookmarksArr.includes(each?.url) ? fav : unfav}
                                    />
                                    {each?.name?.split('(')[0]}
                                    <br />
                                    {each?.name?.split('(')[1] && `(${each?.name?.split('(')[1]}`}
                                    {hasTab === true && (
                                      <>
                                        <br />
                                        <span className="has-tab">
                                          {'('}
                                          {each?.name.split('(')[1]}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
        })}
        <div style={{ position: 'absolute', bottom: 3, right: 3, color: '#afafaf', fontSize: 8 }}>All Rights Reserved © 2021 ​Logimate</div>
      </ul>
    </div>
  );
};
