import { useEffect, useState } from 'react';
import { Row, Col, Input, Label } from 'reactstrap';

//redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { UserDTOForAdmin } from 'interface/user';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { serviceStore } from 'services/services';

export interface UserDTOForAdminEx extends UserDTOForAdmin {
  password2?: string;
  userAuthoritiesKr?: OptionItem[];
}

export const UserDetailPopup = ({ detailObj, setDetailObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [userInfos, setUserInfos] = useState<UserDTOForAdminEx>();
  const [currentTab, setCurrentTab] = useState(0);
  const hasTabs = [
    //
    { title: '기본정보' },
    { title: '데이터권한(인프라)' },
    { title: '데이터권한(판매사)' },
    { title: '데이터권한(창고)' },
    { title: '데이터권한(타창고)' },
    { title: '데이터권한(판매채널)' },
  ];

  const [partnerSeqs, setPartnerSeqs] = useState([]);
  const [centerCodes, setCenterCodes] = useState([]);
  const [diffCenterCodes, setDiffCenterCodes] = useState([]);
  const [salesChannelSeqs, setSalesChannelSeqs] = useState([]);
  const [infraSeqs, setInfraSeqs] = useState([]);

  const [excludePartnerSeqs, setExcludePartnerSeqs] = useState([]);
  const [excludeCenterCodes, setExcludeCenterCodes] = useState([]);
  const [excludeDiffCenterCodes, setExcludeDiffCenterCodes] = useState([]);
  const [excludeSalesChannelSeqs, setExcludeSalesChannelSeqs] = useState([]);
  const [excludeInfraSeqs, setExcludeInfraSeqs] = useState([]);

  useEffect(() => {
    if (detailObj?.type === 'U') {
      setUserInfos({
        ...detailObj?.data,
      });
      setCenterCodes(detailObj?.data?.centerCodes);
      setDiffCenterCodes(detailObj?.data?.diffCenterCodes);
      setPartnerSeqs(detailObj?.data?.partnerSeqs);
      setSalesChannelSeqs(detailObj?.data?.salesChannelSeqs);
      setInfraSeqs(detailObj?.data?.infraSeqs);

      setExcludeCenterCodes(detailObj?.data?.excludeCenterCodes);
      setExcludeDiffCenterCodes(detailObj?.data?.excludeDiffCenterCodes);
      setExcludePartnerSeqs(detailObj?.data?.excludePartnerSeqs);
      setExcludeSalesChannelSeqs(detailObj?.data?.excludeSalesChannelSeqs);
      setExcludeInfraSeqs(detailObj?.data?.excludeInfraSeqs);
    } else {
      setUserInfos(null);
    }
  }, []);

  const userValidator = (userInfos) => {
    if (!userInfos) {
      alert('내용을 입력하세요!');
      return false;
    }

    if (!userInfos.userName) {
      alert('이름을 입력하세요!');
      return false;
    }

    if (detailObj?.type === 'C') {
      if (!userInfos.userId) {
        alert('사용자 ID를 입력하세요!');
        return false;
      }
      if (!userInfos.userCode) {
        alert('사용자 유형을 선택하세요!');
        return false;
      }
    }

    if (!userInfos.phoneNumber) {
      alert('연락처를 입력하세요!');
      return false;
    }

    if (userInfos?.password !== userInfos?.password2) {
      alert('비밀번호와 비밀번호 재입력값이 다릅니다!');
      return false;
    }

    return true;
  };

  const makeData = (userInfos: UserDTOForAdminEx) => {
    const data = { ...userInfos };
    data.centerCodes = centerCodes?.filter((ele) => ele !== null);
    data.partnerSeqs = partnerSeqs?.filter((ele) => ele !== null);
    data.diffCenterCodes = diffCenterCodes?.filter((ele) => ele !== null);
    data.salesChannelSeqs = salesChannelSeqs?.filter((ele) => ele !== null);
    data.infraSeqs = infraSeqs?.filter((ele) => ele !== null);
    data.excludeCenterCodes = excludeCenterCodes?.filter((ele) => ele !== null);
    data.excludeDiffCenterCodes = excludeDiffCenterCodes?.filter((ele) => ele !== null);
    data.excludePartnerSeqs = excludePartnerSeqs?.filter((ele) => ele !== null);
    data.excludeSalesChannelSeqs = excludeSalesChannelSeqs?.filter((ele) => ele !== null);
    data.excludeInfraSeqs = excludeInfraSeqs?.filter((ele) => ele !== null);
    return data;
  };

  const resetPassword = async () => {
    if (window.confirm('비밀번호 초기화 진행하시겠습니까?\n "123456789a"로 초기화됩니다')) {
      const rs = await serviceStore.userAction('password/admin', 'POST', null, {
        userIds: [detailObj?.data?.userId],
        resetPassword: '123456789a',
      });
      if (rs?.status === 200) {
        alert('초기화되었습니다');
      }
    }
  };

  return (
    <CommonModalNew
      style={{ width: 1000 }}
      title={`사용자 ${detailObj?.type === 'C' ? '등록' : '상세보기'}`}
      visible={detailObj?.visible}
      rightTitle={
        <>
          <button
            className={'btn btn-secondary'}
            onClick={() => {
              if (detailObj?.type !== 'C') resetPassword();
            }}
          >
            비밀번호 초기화
          </button>
          <button
            className={'btn btn-secondary ssm'}
            onClick={() => {
              if (userValidator(userInfos)) {
                const data = makeData(userInfos);
                if (data) {
                  if (detailObj?.type === 'C') {
                    //NOTE: 사용자 등록인 경우 유사검색여부 flag를 항상 true로 요청
                    detailObj?.createFn({ data: { ...data, similaritySearchFlag: true }, searchObj: detailObj?.searchObj });
                  } else {
                    detailObj?.updateFn({ data, searchObj: detailObj?.searchObj });
                  }
                }
              }
            }}
          >
            {detailObj?.type === 'C' ? '등록' : '수정'}
          </button>
        </>
      }
      setVisible={() => {
        setDetailObj({
          ...detailObj,
          visible: false,
          data: null,
        });
      }}
      children={
        <div style={{ minHeight: 550 }}>
          <div className="div-top-tab">
            {hasTabs.map((tab, index) => {
              return (
                <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                  <li
                    onClick={() => {
                      setCurrentTab(index);
                    }}
                  >
                    {tab?.title}
                  </li>
                </div>
              );
            })}
          </div>
          <div style={{ display: 'flex', zIndex: currentTab === 0 ? 13 : 10 }}>
            <div style={{ width: 270, display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  backgroundColor: '#eff2f7',
                  border: '1px solid #e0e0e0',
                  marginTop: 30,
                  width: 180,
                  height: 180,
                  borderRadius: 180,
                  position: 'absolute',
                }}
              ></div>
            </div>
            <div style={{ width: 645 }}>
              {detailObj?.type === 'U' && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Label check style={{ width: 70 }}>
                    <Input
                      type="radio"
                      name="useFlag"
                      checked={userInfos?.useFlag === true}
                      onChange={(e) => {
                        setUserInfos({ ...userInfos, useFlag: true });
                      }}
                    />
                    사용
                  </Label>
                  <Label check style={{ width: 60 }}>
                    <Input
                      type="radio"
                      name="useFlag"
                      checked={userInfos?.useFlag === false}
                      onChange={(e) => {
                        setUserInfos({ ...userInfos, useFlag: false });
                      }}
                    />
                    미사용
                  </Label>
                </div>
              )}
              <Row>
                <Col>
                  <label className="col-form-label">이름*</label>
                  <InputD
                    value={userInfos?.userName || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        userName: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">사용자 유형 / 사용자ID*</label>
                  <div style={{ display: 'flex' }}>
                    <InputD style={{ width: 80 }} disabled readOnly value={MASTER_OBJ?.USER_CODE?.[userInfos?.userCode] || ''} />
                    <InputD
                      placeholder="사용자 ID"
                      disabled={detailObj?.type === 'U'}
                      value={userInfos?.userId}
                      onChange={(e) => {
                        setUserInfos({ ...userInfos, userId: e.target.value });
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label className="col-form-label">연락처*</label>
                  <InputD
                    value={userInfos?.phoneNumber || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        phoneNumber: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">사용자 유형*</label>
                  <div className="radio-area">
                    {MASTER_OPS?.USER_CODE?.map((ele) => {
                      return (
                        <Label key={ele.value} htmlFor={ele.value} check style={{ width: 80 }}>
                          <Input
                            id={ele.value}
                            checked={userInfos?.userCode + '' === ele.value}
                            type="radio"
                            onChange={(e) => {
                              setUserInfos({
                                ...userInfos,
                                userCode: e.target.id,
                              });
                            }}
                          />
                          {ele.label}
                        </Label>
                      );
                    })}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label className="col-form-label">비밀번호*</label>
                  <InputD
                    type="password"
                    value={userInfos?.password || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        password: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">비밀번호 확인*</label>
                  <InputD
                    type="password"
                    value={userInfos?.password2 || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        password2: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <label className="col-form-label">이메일</label>
                  <InputD
                    value={userInfos?.email || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        email: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">권한그룹</label>
                  <SelectD
                    isMulti
                    options={MASTER_OPS?.USER_GROUP}
                    value={userInfos?.userGroupId?.map((ele) => {
                      return {
                        label: MASTER_OBJ?.USER_GROUP?.[ele],
                        value: ele,
                      };
                    })}
                    onChange={(option) => {
                      setUserInfos({
                        ...userInfos,
                        userGroupId: (option as OptionItem[]).map((ele) => ele.value),
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">소속파트너</label>
                  <SelectD
                    options={MASTER_OPS?.SELLER_AUTH}
                    value={
                      userInfos?.partnerSeq
                        ? {
                            value: userInfos?.partnerSeq,
                            label: MASTER_OBJ?.SELLER_AUTH?.[userInfos?.partnerSeq],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setUserInfos({
                        ...userInfos,
                        partnerSeq: (option as OptionItem)?.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">소속인프라</label>
                  <SelectD
                    options={MASTER_OPS?.INFRA}
                    value={
                      userInfos?.infraSeq
                        ? {
                            value: userInfos?.infraSeq,
                            label: MASTER_OBJ?.INFRA?.[userInfos?.infraSeq],
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setUserInfos({
                        ...userInfos,
                        infraSeq: value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">소속센터</label>
                  <SelectD
                    options={MASTER_OPS?.CENTER_AUTH}
                    value={
                      userInfos?.centerCode
                        ? {
                            value: userInfos?.centerCode,
                            label: MASTER_OBJ?.CENTER_AUTH?.[userInfos?.centerCode],
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setUserInfos({
                        ...userInfos,
                        centerCode: value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">소속판매채널</label>
                  <SelectD
                    options={MASTER_OPS?.SALES_CHANNEL_TYPE}
                    value={
                      userInfos?.salesChannelSeq
                        ? {
                            value: userInfos?.salesChannelSeq,
                            label: MASTER_OBJ?.SALES_CHANNEL_TYPE?.[userInfos?.salesChannelSeq],
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setUserInfos({
                        ...userInfos,
                        salesChannelSeq: value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div style={{ display: 'flex', zIndex: currentTab === 1 ? 13 : 10, flexDirection: 'column' }}>
            <div className="button-container">
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={infraSeqs?.length === 1 && infraSeqs?.[0] === '0'}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setInfraSeqs(['0']);
                        setExcludeInfraSeqs([]);
                      }
                    }}
                  />
                  <span title={'항상 일괄로 설정'}>항상 일괄로 설정</span>
                </label>
              </div>
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={!((infraSeqs?.length === 1 && infraSeqs?.[0] === '0') || (infraSeqs?.length === 1 && infraSeqs?.[0] === '-1'))}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setInfraSeqs([]);
                        setExcludeInfraSeqs(['-1']);
                      }
                    }}
                  />
                  <span title={'직접 선택'}>직접 선택</span>
                </label>
              </div>
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={infraSeqs?.length === 1 && infraSeqs?.[0] === '-1'}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setInfraSeqs(['-1']);
                        setExcludeInfraSeqs(['-1']);
                      }
                    }}
                  />
                  <span title={'설정안함'}>설정안함</span>
                </label>
              </div>
            </div>
            {!(infraSeqs?.length === 1 && infraSeqs?.[0] === '-1') && (
              <>
                <div style={{ padding: 15, paddingBottom: 5, height: 40 }}>{infraSeqs?.length === 1 && infraSeqs?.[0] === '0' && '▼ 제외 판매채널'}</div>
                <div className="button-container" style={{ maxHeight: 450, overflowY: 'scroll' }}>
                  <div className="cat">
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          (!infraSeqs?.includes('-1') && !infraSeqs?.includes('0') && infraSeqs?.length === MASTER_OPS?.INFRA?.length) ||
                          (!excludeInfraSeqs?.includes('-1') && !excludeInfraSeqs?.includes('0') && excludeInfraSeqs?.length === MASTER_OPS?.INFRA?.length)
                        }
                        style={{ marginRight: 10 }}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            infraSeqs?.length === 1 && infraSeqs?.[0] === '0'
                              ? setExcludeInfraSeqs(MASTER_OPS?.INFRA?.map((ele) => ele.value && ele.value + ''))
                              : setInfraSeqs(MASTER_OPS?.INFRA?.map((ele) => ele.value && ele.value + ''));
                          } else {
                            infraSeqs?.length === 1 && infraSeqs?.[0] === '0' ? setExcludeInfraSeqs([]) : setInfraSeqs([]);
                          }
                        }}
                      />
                      <span title={'전체선택/해제'}>전체선택</span>
                    </label>
                  </div>
                  {MASTER_OPS?.INFRA.map((channel, idx) => {
                    const value = channel.value + '';
                    if (value !== 'null')
                      return (
                        <div className="cat" key={`infra_btn_${idx}_${value}`}>
                          <label>
                            <input
                              type="checkbox"
                              checked={new Set(infraSeqs)?.has(value) || new Set(excludeInfraSeqs)?.has(value)}
                              style={{ marginRight: 10 }}
                              onChange={() => {
                                let set = new Set(infraSeqs?.length === 1 && infraSeqs?.[0] === '0' ? excludeInfraSeqs : infraSeqs);
                                if (!set.has(value)) {
                                  set.add(value);
                                } else {
                                  set.delete(value);
                                }
                                infraSeqs?.length === 1 && infraSeqs?.[0] === '0' ? setExcludeInfraSeqs(Array.from(set)) : setInfraSeqs(Array.from(set));
                              }}
                            />
                            <span title={channel?.label}>{channel?.label}</span>
                          </label>
                        </div>
                      );
                  })}
                </div>
              </>
            )}
          </div>
          <div style={{ display: 'flex', zIndex: currentTab === 2 ? 13 : 10, flexDirection: 'column' }}>
            <div className="button-container">
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={partnerSeqs?.length === 1 && partnerSeqs?.[0] === '0'}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setPartnerSeqs(['0']);
                        setExcludePartnerSeqs([]);
                      }
                    }}
                  />
                  <span title={'항상 일괄로 설정'}>항상 일괄로 설정</span>
                </label>
              </div>
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={!((partnerSeqs?.length === 1 && partnerSeqs?.[0] === '0') || (partnerSeqs?.length === 1 && partnerSeqs?.[0] === '-1'))}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setPartnerSeqs([]);
                        setExcludePartnerSeqs(['-1']);
                      }
                    }}
                  />
                  <span title={'직접 선택'}>직접 선택</span>
                </label>
              </div>
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={partnerSeqs?.length === 1 && partnerSeqs?.[0] === '-1'}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setPartnerSeqs(['-1']);
                        setExcludePartnerSeqs(['-1']);
                      }
                    }}
                  />
                  <span title={'설정안함'}>설정안함</span>
                </label>
              </div>
            </div>
            {!(partnerSeqs?.length === 1 && partnerSeqs?.[0] === '-1') && (
              <>
                <div style={{ padding: 15, paddingBottom: 5, height: 40 }}>{partnerSeqs?.length === 1 && partnerSeqs?.[0] === '0' && '▼ 제외 판매사'}</div>
                <div className="button-container" style={{ maxHeight: 450, overflowY: 'scroll' }}>
                  <div className="cat">
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          (!partnerSeqs?.includes('-1') && !partnerSeqs?.includes('0') && partnerSeqs?.length === MASTER_OPS?.SELLER_SELLER_AUTH?.length) ||
                          (!excludePartnerSeqs?.includes('-1') && !excludePartnerSeqs?.includes('0') && excludePartnerSeqs?.length === MASTER_OPS?.SELLER_SELLER_AUTH?.length)
                        }
                        style={{ marginRight: 10 }}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            partnerSeqs?.length === 1 && partnerSeqs?.[0] === '0'
                              ? setExcludePartnerSeqs(MASTER_OPS?.SELLER_SELLER_AUTH.map((ele) => ele.value + '')?.filter((ele) => ele !== null))
                              : setPartnerSeqs(MASTER_OPS?.SELLER_SELLER_AUTH.map((ele) => ele.value + '')?.filter((ele) => ele !== null));
                          } else {
                            partnerSeqs?.length === 1 && partnerSeqs?.[0] === '0' ? setExcludePartnerSeqs([]) : setPartnerSeqs([]);
                          }
                        }}
                      />
                      <span title={'전체선택/해제'}>전체선택</span>
                    </label>
                  </div>
                  {MASTER_OPS?.SELLER_SELLER_AUTH.map((partner, idx) => {
                    const value = partner.value + '';
                    if (value !== 'null')
                      return (
                        <div className="cat" key={`partnerSeq_btn_${idx}`}>
                          <label>
                            <input
                              type="checkbox"
                              checked={new Set(partnerSeqs)?.has(value) || new Set(excludePartnerSeqs)?.has(value)}
                              style={{ marginRight: 10 }}
                              onChange={() => {
                                let set = new Set(partnerSeqs?.length === 1 && partnerSeqs?.[0] === '0' ? excludePartnerSeqs : partnerSeqs);
                                if (!set.has(value)) {
                                  set.add(value);
                                } else {
                                  set.delete(value);
                                }
                                partnerSeqs?.length === 1 && partnerSeqs?.[0] === '0' ? setExcludePartnerSeqs(Array.from(set)) : setPartnerSeqs(Array.from(set));
                              }}
                            />
                            <span title={partner?.label}>{partner?.label}</span>
                          </label>
                        </div>
                      );
                  })}
                </div>
              </>
            )}
          </div>
          <div style={{ display: 'flex', zIndex: currentTab === 3 ? 13 : 10, flexDirection: 'column' }}>
            <div className="button-container">
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={centerCodes?.length === 1 && centerCodes?.[0] === '0'}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setCenterCodes(['0']);
                        setExcludeCenterCodes([]);
                      }
                    }}
                  />
                  <span title={'항상 일괄로 설정'}>항상 일괄로 설정</span>
                </label>
              </div>
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={!((centerCodes?.length === 1 && centerCodes?.[0] === '0') || (centerCodes?.length === 1 && centerCodes?.[0] === '-1'))}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setCenterCodes([]);
                        setExcludeCenterCodes(['-1']);
                      }
                    }}
                  />
                  <span title={'직접 선택'}>직접 선택</span>
                </label>
              </div>
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={centerCodes?.length === 1 && centerCodes?.[0] === '-1'}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setCenterCodes(['-1']);
                        setExcludeCenterCodes(['-1']);
                      }
                    }}
                  />
                  <span title={'설정안함'}>설정안함</span>
                </label>
              </div>
            </div>
            {!(centerCodes?.length === 1 && centerCodes?.[0] === '-1') && (
              <>
                <div style={{ padding: 15, paddingBottom: 5, height: 40 }}>{centerCodes?.length === 1 && centerCodes?.[0] === '0' && '▼ 제외 창고'}</div>
                <div className="button-container" style={{ maxHeight: 450, overflowY: 'scroll' }}>
                  <div className="cat">
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          (!centerCodes?.includes('-1') && !centerCodes?.includes('0') && centerCodes?.length === MASTER_OPS?.CENTER_AUTH?.length) ||
                          (!excludeCenterCodes?.includes('-1') && !excludeCenterCodes?.includes('0') && excludeCenterCodes?.length === MASTER_OPS?.CENTER_AUTH?.length)
                        }
                        style={{ marginRight: 10 }}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            centerCodes?.length === 1 && centerCodes?.[0] === '0'
                              ? setExcludeCenterCodes(MASTER_OPS?.CENTER_AUTH?.map((ele) => ele.value))
                              : setCenterCodes(MASTER_OPS?.CENTER_AUTH?.map((ele) => ele.value));
                          } else {
                            centerCodes?.length === 1 && centerCodes?.[0] === '0' ? setExcludeCenterCodes([]) : setCenterCodes([]);
                          }
                        }}
                      />
                      <span title={'전체선택/해제'}>전체선택</span>
                    </label>
                  </div>
                  {MASTER_OPS?.CENTER_AUTH.map((center, idx) => {
                    const value = center.value + '';
                    if (value !== 'null') {
                      return (
                        <div className="cat" key={`center_btn_${idx}_${value}`}>
                          <label>
                            <input
                              type="checkbox"
                              checked={new Set(centerCodes)?.has(value) || new Set(excludeCenterCodes)?.has(value)}
                              style={{ marginRight: 10 }}
                              onChange={() => {
                                let set = new Set(centerCodes?.length === 1 && centerCodes?.[0] === '0' ? excludeCenterCodes : centerCodes);
                                if (!set.has(value)) {
                                  set.add(value);
                                } else {
                                  set.delete(value);
                                }
                                centerCodes?.length === 1 && centerCodes?.[0] === '0' ? setExcludeCenterCodes(Array.from(set)) : setCenterCodes(Array.from(set));
                              }}
                            />
                            <span title={center?.label}>{center?.label}</span>
                          </label>
                        </div>
                      );
                    }
                  })}
                </div>
              </>
            )}
          </div>
          <div style={{ display: 'flex', zIndex: currentTab === 4 ? 13 : 10, flexDirection: 'column' }}>
            <div className="button-container">
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={diffCenterCodes?.length === 1 && diffCenterCodes?.[0] === '0'}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setDiffCenterCodes(['0']);
                        setExcludeDiffCenterCodes([]);
                      }
                    }}
                  />
                  <span title={'항상 일괄로 설정'}>항상 일괄로 설정</span>
                </label>
              </div>
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={!((diffCenterCodes?.length === 1 && diffCenterCodes?.[0] === '0') || (diffCenterCodes?.length === 1 && diffCenterCodes?.[0] === '-1'))}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setDiffCenterCodes([]);
                        setExcludeDiffCenterCodes(['-1']);
                      }
                    }}
                  />
                  <span title={'직접 선택'}>직접 선택</span>
                </label>
              </div>
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={diffCenterCodes?.length === 1 && diffCenterCodes?.[0] === '-1'}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setDiffCenterCodes(['-1']);
                        setExcludeCenterCodes(['-1']);
                      }
                    }}
                  />
                  <span title={'설정안함'}>설정안함</span>
                </label>
              </div>
            </div>
            {!(diffCenterCodes?.length === 1 && diffCenterCodes?.[0] === '-1') && (
              <>
                <div style={{ padding: 15, paddingBottom: 5, height: 40 }}>{diffCenterCodes?.length === 1 && diffCenterCodes?.[0] === '0' && '▼ 제외 타창고'}</div>
                <div className="button-container" style={{ maxHeight: 450, overflowY: 'scroll' }}>
                  <div className="cat">
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          (!diffCenterCodes?.includes('-1') && !diffCenterCodes?.includes('0') && diffCenterCodes?.length === MASTER_OPS?.CENTER_AUTH?.length) ||
                          (!excludeDiffCenterCodes?.includes('-1') && !excludeDiffCenterCodes?.includes('0') && excludeDiffCenterCodes?.length === MASTER_OPS?.CENTER_AUTH?.length)
                        }
                        style={{ marginRight: 10 }}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            diffCenterCodes?.length === 1 && diffCenterCodes?.[0] === '0'
                              ? setExcludeDiffCenterCodes(MASTER_OPS?.CENTER_AUTH?.map((ele) => ele.value))
                              : setDiffCenterCodes(MASTER_OPS?.CENTER_AUTH?.map((ele) => ele.value));
                          } else {
                            diffCenterCodes?.length === 1 && diffCenterCodes?.[0] === '0' ? setExcludeDiffCenterCodes([]) : setDiffCenterCodes([]);
                          }
                        }}
                      />
                      <span title={'전체선택/해제'}>전체선택</span>
                    </label>
                  </div>
                  {MASTER_OPS?.CENTER_AUTH.map((center, idx) => {
                    const value = center.value + '';
                    if (value !== 'null')
                      return (
                        <div className="cat" key={`center_btn_${idx}_${value}`}>
                          <label>
                            <input
                              type="checkbox"
                              checked={new Set(diffCenterCodes)?.has(value) || new Set(excludeDiffCenterCodes)?.has(value)}
                              style={{ marginRight: 10 }}
                              onChange={() => {
                                let set = new Set(diffCenterCodes?.length === 1 && diffCenterCodes?.[0] === '0' ? excludeDiffCenterCodes : diffCenterCodes);
                                if (!set.has(value)) {
                                  set.add(value);
                                } else {
                                  set.delete(value);
                                }
                                diffCenterCodes?.length === 1 && diffCenterCodes?.[0] === '0' ? setExcludeDiffCenterCodes(Array.from(set)) : setDiffCenterCodes(Array.from(set));
                              }}
                            />
                            <span title={center?.label}>{center?.label}</span>
                          </label>
                        </div>
                      );
                  })}
                </div>
              </>
            )}
          </div>
          <div style={{ display: 'flex', zIndex: currentTab === 5 ? 13 : 10, flexDirection: 'column' }}>
            <div className="button-container">
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={salesChannelSeqs?.length === 1 && salesChannelSeqs?.[0] === '0'}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setSalesChannelSeqs(['0']);
                        setExcludeSalesChannelSeqs([]);
                      }
                    }}
                  />
                  <span title={'항상 일괄로 설정'}>항상 일괄로 설정</span>
                </label>
              </div>
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={!((salesChannelSeqs?.length === 1 && salesChannelSeqs?.[0] === '0') || (salesChannelSeqs?.length === 1 && salesChannelSeqs?.[0] === '-1'))}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setSalesChannelSeqs([]);
                        setExcludeSalesChannelSeqs(['-1']);
                      }
                    }}
                  />
                  <span title={'직접 선택'}>직접 선택</span>
                </label>
              </div>
              <div className="cat">
                <label>
                  <input
                    type="checkbox"
                    checked={salesChannelSeqs?.length === 1 && salesChannelSeqs?.[0] === '-1'}
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setSalesChannelSeqs(['-1']);
                        setExcludeSalesChannelSeqs(['-1']);
                      }
                    }}
                  />
                  <span title={'설정안함'}>설정안함</span>
                </label>
              </div>
            </div>
            {!(salesChannelSeqs?.length === 1 && salesChannelSeqs?.[0] === '-1') && (
              <>
                <div style={{ padding: 15, paddingBottom: 5, height: 40 }}>{salesChannelSeqs?.length === 1 && salesChannelSeqs?.[0] === '0' && '▼ 제외 판매채널'}</div>
                <div className="button-container" style={{ maxHeight: 450, overflowY: 'scroll' }}>
                  <div className="cat">
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          (!salesChannelSeqs?.includes('-1') && !salesChannelSeqs?.includes('0') && salesChannelSeqs?.length === MASTER_OPS?.SALES_CHANNEL_TYPE?.length) ||
                          (!excludeSalesChannelSeqs?.includes('-1') && !excludeSalesChannelSeqs?.includes('0') && excludeSalesChannelSeqs?.length === MASTER_OPS?.SALES_CHANNEL_TYPE?.length)
                        }
                        style={{ marginRight: 10 }}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            salesChannelSeqs?.length === 1 && salesChannelSeqs?.[0] === '0'
                              ? setExcludeSalesChannelSeqs(MASTER_OPS?.SALES_CHANNEL_TYPE?.map((ele) => ele.value && ele.value + ''))
                              : setSalesChannelSeqs(MASTER_OPS?.SALES_CHANNEL_TYPE?.map((ele) => ele.value && ele.value + ''));
                          } else {
                            salesChannelSeqs?.length === 1 && salesChannelSeqs?.[0] === '0' ? setExcludeSalesChannelSeqs([]) : setSalesChannelSeqs([]);
                          }
                        }}
                      />
                      <span title={'전체선택/해제'}>전체선택</span>
                    </label>
                  </div>
                  {MASTER_OPS?.SALES_CHANNEL_TYPE.map((channel, idx) => {
                    const value = channel.value + '';
                    if (value !== 'null')
                      return (
                        <div className="cat" key={`sales_btn_${idx}_${value}`}>
                          <label>
                            <input
                              type="checkbox"
                              checked={new Set(salesChannelSeqs)?.has(value) || new Set(excludeSalesChannelSeqs)?.has(value)}
                              style={{ marginRight: 10 }}
                              onChange={() => {
                                let set = new Set(salesChannelSeqs?.length === 1 && salesChannelSeqs?.[0] === '0' ? excludeSalesChannelSeqs : salesChannelSeqs);
                                if (!set.has(value)) {
                                  set.add(value);
                                } else {
                                  set.delete(value);
                                }
                                salesChannelSeqs?.length === 1 && salesChannelSeqs?.[0] === '0' ? setExcludeSalesChannelSeqs(Array.from(set)) : setSalesChannelSeqs(Array.from(set));
                              }}
                            />
                            <span title={channel?.label}>{channel?.label}</span>
                          </label>
                        </div>
                      );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      }
    />
  );
};
