import { createSlice } from '@reduxjs/toolkit';
import { labelArrToObj, OptionItem } from 'common/master/codeMasterReturnHelper';
import { ViewType } from 'components/LDS/layouts/Searchbox/Searchbox.hooks';

export const COMMON_GRIDID = 'GRIDID_';
export const RAWDATA = 'RAWDATA_';
interface InitProps {
  nowMenu?: {
    url?: string;
    menuName?: string;
    tabId?: string;
    tabName?: string;
  };
  loading?: null | 'GET' | 'PUT' | 'POST' | 'DELETE';
  masterOptions?: any;
  MASTER_OPS?: { [key: string | number]: OptionItem[] | any[] };
  MASTER_OBJ?: { [key: string | number]: any };
  tabs?: {
    [key: string]: number;
  };
  dataInUrl?: {
    [tabNum: string]: {
      searchObj?: any;
      main?: any;
      total?: any;
    };
  };
  openOrclose?: {
    side: boolean;
    header: boolean;
  };
  searchBoxViewType?: ViewType;
  bookmarkUpdated?: boolean;
}

const LocalSidebarIsOpen = JSON.parse(localStorage.getItem('SidebarIsOpen'));

const initialState: InitProps = {
  nowMenu: {
    url: '/',
    menuName: '대시보드',
    tabName: '',
  },
  tabs: {},
  dataInUrl: {},
  masterOptions: null,
  MASTER_OBJ: null,
  loading: null,
  openOrclose: {
    side: LocalSidebarIsOpen ?? true,
    header: true,
  },
  searchBoxViewType: 'DEFAULT',
  bookmarkUpdated: false,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    clearScreen: (state) => {
      state.dataInUrl = {};
      state.tabs = {};
      state.masterOptions = null;
    },
    setOpenOrcloseSide: (state, action) => {
      state.openOrclose = {
        ...state.openOrclose,
        side: !state.openOrclose?.side,
      };
    },
    setNowMenu: (state, action) => {
      state.nowMenu = {
        ...state.nowMenu,
        menuName: action.payload?.menuName || state.nowMenu?.menuName,
        url: action.payload?.url || state.nowMenu?.url,
        tabId: action.payload?.tabId || state.nowMenu?.tabId,
        tabName: action.payload?.tabName || state.nowMenu?.tabName,
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setMasterOptions: (state, action) => {
      // ****** OBJ 화 [key]: value, [value]: key
      if (action.payload) {
        const keys = Object.keys(action.payload);
        keys?.forEach((key: string) => {
          if (!key?.includes('_OBJ') && Array.isArray(action.payload[key])) {
            action.payload[`${key}_OBJ`] = labelArrToObj(action.payload[key]);
          }
        });
      }
      // 없애고 싶어
      state.masterOptions = action.payload;
    },
    nullingMASTER: (state) => {
      state.MASTER_OBJ = null;
      state.MASTER_OPS = null;
      state.masterOptions = null;
    },
    setMASTER: (state, action) => {
      // ****** OBJ 화 [key]: value, [value]: key
      if (action.payload) {
        const keys = Object.keys(action.payload);
        const MASTER_OBJ = {};
        keys?.forEach((key: string) => {
          if (key?.includes(COMMON_GRIDID) || key?.includes(RAWDATA)) {
            MASTER_OBJ[key] = action.payload[key]; // 그 자체로 넣음
          } else if (Array.isArray(action.payload[key])) {
            MASTER_OBJ[key] = labelArrToObj(action.payload[key]);
          }
        });
        state.MASTER_OBJ = MASTER_OBJ;
        state.MASTER_OPS = action.payload;
        // 없앨 것
        state.masterOptions = action.payload;
      }
    },
    addMASTER: (state, action) => {
      // { [key: string]: OptionItem[]}
      console.log(action.payload);
      const keys = Object.keys(action.payload);
      const MASTER_OBJ = {};
      keys?.forEach((key: string) => {
        if (key?.includes(COMMON_GRIDID) || key?.includes(RAWDATA)) {
          MASTER_OBJ[key] = action.payload[key]; // 그 자체로 넣음
        } else if (Array.isArray(action.payload[key])) {
          MASTER_OBJ[key] = labelArrToObj(action.payload[key]);
        }
      });
      state.MASTER_OBJ = Object.assign({}, MASTER_OBJ, state.MASTER_OBJ);
      state.MASTER_OPS = Object.assign({}, action.payload, state.MASTER_OPS);
      // 없앨 것
      state.masterOptions = Object.assign({}, action.payload, state.masterOptions);
    },
    // 없앨것
    addMasterOptions: (state, action) => {
      state.masterOptions = Object.assign({}, action.payload, state.masterOptions);
    },
    fetchMaster: (state, action) => {
      state.masterOptions = {
        ...state.masterOptions,
        [action.payload.key]: action.payload.value,
      };
      state.MASTER_OPS = {
        ...state.MASTER_OPS,
        [action.payload.key]: action.payload.value,
      };
      state.MASTER_OBJ = {
        ...state.MASTER_OBJ,
        [action.payload.key]: labelArrToObj(action.payload.value),
      };
    },
    fetchNewMasterOptions: (state, action) => {
      state.masterOptions = {
        ...state.masterOptions,
        [action.payload.key]: action.payload.value,
        // 없애고 싶어
        [action.payload.key + '_OBJ']: Array.isArray(action.payload.value) ? labelArrToObj(action.payload.value) : {},
        // 없애고 싶어
      };
    },
    setNowTab: (state, action) => {
      if (action.payload.key) {
        state.nowMenu = {
          ...state.nowMenu,
          tabName: action.payload.label,
        };
        state.tabs = {
          ...state.tabs,
          [action.payload.key]: action.payload.value,
        };
      }
    },
    setDataInUrlSearchObj: (state, action) => {
      if (action.payload.key)
        state.dataInUrl = {
          ...state.dataInUrl,
          // key 는 tabID
          [action.payload.key]: {
            ...state.dataInUrl?.[action.payload.key],
            searchObj: action.payload.value,
          },
        };
      else {
        //NOTE: payload에 key가 없는 경우 초기화되도록 케이스 추가
        state.dataInUrl = {};
      }
    },
    setDataInUrlMain: (state, action) => {
      if (action.payload.key)
        state.dataInUrl = {
          ...state.dataInUrl,
          [action.payload.key]: {
            ...state.dataInUrl?.[action.payload.key],
            main: action.payload.value,
          },
        };
    },
    setDataInUrlTotal: (state, action) => {
      if (action.payload.key)
        state.dataInUrl = {
          ...state.dataInUrl,
          [action.payload.key]: {
            ...state.dataInUrl?.[action.payload.key],
            total: action.payload.value,
          },
        };
    },
    setSearchBoxViewType: (state, action) => {
      state.searchBoxViewType = action.payload;
    },
    setBookmarkUpdated: (state, action) => {
      state.bookmarkUpdated = action.payload;
    },
  },
});

export const {
  //
  clearScreen,
  setNowMenu,
  setLoading,
  // new version
  setMASTER,
  addMASTER,
  fetchMaster,
  nullingMASTER,
  //
  setMasterOptions,
  addMasterOptions,
  fetchNewMasterOptions,
  setOpenOrcloseSide,
  setNowTab,
  setDataInUrlSearchObj,
  setDataInUrlMain,
  setDataInUrlTotal,
  setSearchBoxViewType,
  setBookmarkUpdated,
} = menuSlice.actions;

export default menuSlice.reducer;
