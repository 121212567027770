import { useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { statisticsService } from '../../_services/service';
import { InvoicePromiseDTO, InvoicePromiseDetailDTO, InvoicePromiseSearchDTO } from '../../_interface/order';
import { BottomSheet, IBottomSheetObj } from 'components/template/bottomSheet';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { PagingListDTO } from 'interface/util';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<AUIGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();

  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    setBottomSheetObj((prev) => {
      return {
        ...prev,
        visible: true,
        title: '약속일준수율 상세',
        grid: {
          gridRef: bottomGridRef,
          columns: detailColumns,
          buttons: [
            {
              color: 'green',
              title: '엑셀다운로드',
              onClick: () => {
                //

                bottomGridRef.current.exportAsXlsx({ fileName: '약속일준수율(상세)' });
              },
            },
          ],
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
      };
    });
    const item = e.item;
    const searchObj = JSON.parse(e.item.searchObj);
    const orderType = e.dataField?.split('TotalQuantity')[0];
    if (item?.partnerSeq) searchObj['partnerSeq'] = item?.partnerSeq;
    if (item?.infraSeq) searchObj['infraSeq'] = item?.infraSeq;
    if (item?.centerCode) searchObj['centerCode'] = item?.centerCode;
    if (orderType && orderType !== 'total') searchObj['orderType'] = orderType;

    const { data } = await serviceStore.orderAction('/invoice/promise/detail', 'GET', searchObj, null);
    const rows = (data as InvoicePromiseDetailDTO[])?.map((row) => {
      return {
        ...row,
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        dateTypeKr: row.dateType,
        orderTypeKr: MASTER_OBJ?.ORDER_TYPE?.[row.orderType],
        feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row.feeType],
        invoiceStatusKr: MASTER_OBJ?.INVOICE_STATUS?.[row.invoiceStatus],
        userIdKr: MASTER_OBJ?.DRIVER_WHOLE?.[row.userId],
        inventoryLocationKr: MASTER_OBJ?.INVENTORY_LOCATION?.[row.inventoryLocation],
      };
    });
    bottomGridRef?.current?.setGridData(rows);
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '전체',
      children: [
        {
          headerText: '전체',
          dataField: 'totalTotalQuantity',
          headerTooltip: {
            show: true,
            tooltipHtml: '<div style="width:280px;"><div>취소 후 생성되는 반납회수, 설치중회수, 부분설치, 부분회수 송장은 포함되지 않습니다.</div></div>',
          },
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: (e) => {
              fetchDetail(e);
            },
          },
        },
        {
          headerText: '~D+3(%)',
          dataField: 'totalThreeBeforeQuantity',
        },
        {
          headerText: '~D+5(%)',
          dataField: 'totalFiveBeforeQuantity',
        },
        {
          headerText: '~D+7(%)',
          dataField: 'totalSevenBeforeQuantity',
        },
        {
          headerText: 'D+8~(%)',
          dataField: 'totalEightAfterQuantity',
        },
        {
          headerText: '미완료',
          dataField: 'totalIncompleteQuantity',
        },
        {
          headerText: '취소',
          dataField: 'totalCancelQuantity',
        },
      ],
    },
    {
      headerText: '배송',
      children: [
        {
          headerText: '전체',
          dataField: 'deliveryTotalQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: (e) => {
              fetchDetail(e);
            },
          },
        },
        {
          headerText: '~D+3(%)',
          dataField: 'deliveryThreeBeforeQuantity',
        },
        {
          headerText: '~D+5(%)',
          dataField: 'deliveryFiveBeforeQuantity',
        },
        {
          headerText: '~D+7(%)',
          dataField: 'deliverySevenBeforeQuantity',
        },
        {
          headerText: 'D+8~(%)',
          dataField: 'deliveryEightAfterQuantity',
        },
        {
          headerText: '미완료',
          dataField: 'deliveryIncompleteQuantity',
        },
        {
          headerText: '취소',
          dataField: 'deliveryCancelQuantity',
        },
      ],
    },
    {
      headerText: '방문',
      children: [
        {
          headerText: '전체',
          dataField: 'visitTotalQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: (e) => {
              fetchDetail(e);
            },
          },
        },
        {
          headerText: '~D+3(%)',
          dataField: 'visitThreeBeforeQuantity',
        },
        {
          headerText: '~D+5(%)',
          dataField: 'visitFiveBeforeQuantity',
        },
        {
          headerText: '~D+7(%)',
          dataField: 'visitSevenBeforeQuantity',
        },
        {
          headerText: 'D+8~(%)',
          dataField: 'visitEightAfterQuantity',
        },
        {
          headerText: '미완료',
          dataField: 'visitIncompleteQuantity',
        },
        {
          headerText: '취소',
          dataField: 'visitCancelQuantity',
        },
      ],
    },
    {
      headerText: '회수',
      children: [
        {
          headerText: '전체',
          dataField: 'collectTotalQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: (e) => {
              fetchDetail(e);
            },
          },
        },
        {
          headerText: '~D+3(%)',
          dataField: 'collectThreeBeforeQuantity',
        },
        {
          headerText: '~D+5(%)',
          dataField: 'collectFiveBeforeQuantity',
        },
        {
          headerText: '~D+7(%)',
          dataField: 'collectSevenBeforeQuantity',
        },
        {
          headerText: 'D+8~(%)',
          dataField: 'collectEightAfterQuantity',
        },
        {
          headerText: '미완료',
          dataField: 'collectIncompleteQuantity',
        },
        {
          headerText: '취소',
          dataField: 'collectCancelQuantity',
        },
      ],
    },
    {
      headerText: '교환',
      children: [
        {
          headerText: '전체',
          dataField: 'exchangeTotalQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: (e) => {
              fetchDetail(e);
            },
          },
        },
        {
          headerText: '~D+3(%)',
          dataField: 'exchangeThreeBeforeQuantity',
        },
        {
          headerText: '~D+5(%)',
          dataField: 'exchangeFiveBeforeQuantity',
        },
        {
          headerText: '~D+7(%)',
          dataField: 'exchangeSevenBeforeQuantity',
        },
        {
          headerText: 'D+8~(%)',
          dataField: 'exchangeEightAfterQuantity',
        },
        {
          headerText: '미완료',
          dataField: 'exchangeIncompleteQuantity',
        },
        {
          headerText: '취소',
          dataField: 'exchangeCancelQuantity',
        },
      ],
    },
    {
      headerText: '이전',
      children: [
        {
          headerText: '전체',
          dataField: 'moveTotalQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: (e) => {
              fetchDetail(e);
            },
          },
        },
        {
          headerText: '~D+3(%)',
          dataField: 'moveThreeBeforeQuantity',
        },
        {
          headerText: '~D+5(%)',
          dataField: 'moveFiveBeforeQuantity',
        },
        {
          headerText: '~D+7(%)',
          dataField: 'moveSevenBeforeQuantity',
        },
        {
          headerText: 'D+8~(%)',
          dataField: 'moveEightAfterQuantity',
        },
        {
          headerText: '미완료',
          dataField: 'moveIncompleteQuantity',
        },
        {
          headerText: '취소',
          dataField: 'moveCancelQuantity',
        },
      ],
    },
    {
      headerText: '전시품',
      children: [
        {
          headerText: '전체',
          dataField: 'displayTotalQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: (e) => {
              fetchDetail(e);
            },
          },
        },
        {
          headerText: '~D+3(%)',
          dataField: 'displayThreeBeforeQuantity',
        },
        {
          headerText: '~D+5(%)',
          dataField: 'displayFiveBeforeQuantity',
        },
        {
          headerText: '~D+7(%)',
          dataField: 'displaySevenBeforeQuantity',
        },
        {
          headerText: 'D+8~(%)',
          dataField: 'displayEightAfterQuantity',
        },
        {
          headerText: '미완료',
          dataField: 'displayIncompleteQuantity',
        },
        {
          headerText: '취소',
          dataField: 'displayCancelQuantity',
        },
      ],
    },
    {
      headerText: 'AS',
      children: [
        {
          headerText: '전체',
          dataField: 'afterTotalQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: (e) => {
              fetchDetail(e);
            },
          },
        },
        {
          headerText: '~D+3(%)',
          dataField: 'afterThreeBeforeQuantity',
        },
        {
          headerText: '~D+5(%)',
          dataField: 'afterFiveBeforeQuantity',
        },
        {
          headerText: '~D+7(%)',
          dataField: 'afterSevenBeforeQuantity',
        },
        {
          headerText: 'D+8~(%)',
          dataField: 'afterEightAfterQuantity',
        },
        {
          headerText: '미완료',
          dataField: 'afterIncompleteQuantity',
        },
        {
          headerText: '취소',
          dataField: 'afterCancelQuantity',
        },
      ],
    },
    {
      headerText: '수리',
      children: [
        {
          headerText: '전체',
          dataField: 'repairTotalQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: (e) => {
              fetchDetail(e);
            },
          },
        },
        {
          headerText: '~D+3(%)',
          dataField: 'repairThreeBeforeQuantity',
        },
        {
          headerText: '~D+5(%)',
          dataField: 'repairFiveBeforeQuantity',
        },
        {
          headerText: '~D+7(%)',
          dataField: 'repairSevenBeforeQuantity',
        },
        {
          headerText: 'D+8~(%)',
          dataField: 'repairEightAfterQuantity',
        },
        {
          headerText: '미완료',
          dataField: 'repairIncompleteQuantity',
        },
        {
          headerText: '취소',
          dataField: 'repairCancelQuantity',
        },
      ],
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '기준',
      dataField: 'dateTypeKr',
    },
    {
      headerText: '주문유형',
      dataField: 'orderTypeKr',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '송장상태',
      dataField: 'invoiceStatusKr',
    },
    {
      headerText: '리드타임',
      dataField: 'leadTime',
    },
    {
      headerText: '주기사',
      dataField: 'userIdKr',
    },
    {
      headerText: '주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '등록일',
      dataField: 'registerDate',
    },
    {
      headerText: '희망일',
      dataField: 'promiseDeliveryDate',
    },
    {
      headerText: '완료일/취소일',
      dataField: 'finishOrCancelDate',
    },
    {
      headerText: '주문번호',
      dataField: 'customerOrderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'customerOrderInvoiceSeq',
    },
    {
      headerText: '재고위치',
      dataField: 'inventoryLocationKr',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '상품구성수량',
      dataField: 'quantity',
    },
  ];

  const validationForFetchList = (searchObj: InvoicePromiseSearchDTO) => {
    if (!searchObj?.fromDeliveryDate && !searchObj?.fromPromiseDate) {
      alert('기준일은 필수 검색자입니다!');
      return false;
    }
    return true;
  };

  const fetchTotal = async (searchObj: InvoicePromiseSearchDTO) => {
    const total = await statisticsService.getInvoicePromiseTotal(searchObj);

    return total;
  };

  const labellingKr = (data: PagingListDTO<InvoicePromiseDTO & InvoicePromiseDetailDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        dateTypeKr: row.dateType,
        orderTypeKr: MASTER_OBJ?.ORDER_TYPE?.[row.orderType],
        feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row.feeType],
        invoiceStatusKr: MASTER_OBJ?.INVOICE_STATUS?.[row.invoiceStatus],
        userIdKr: MASTER_OBJ?.DRIVER_WHOLE?.[row.userId],
        inventoryLocationKr: MASTER_OBJ?.INVENTORY_LOCATION?.[row.inventoryLocation],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InvoicePromiseSearchDTO) => {
    dispatch(setLoading('GET'));
    setBottomSheetObj(null);
    const dataKr = labellingKr(await statisticsService.getInvoicePromisePaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };
  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchTotal,
    fetchPaging,
    validationForFetchList,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    if (validationForFetchList(searchObj)) {
      dispatch(setLoading('GET'));
      const { list } = labellingKr({ list: type === 'main' ? await statisticsService.getInvoicePromiseExcelMain(searchObj) : await statisticsService.getInvoicePromiseDetail(searchObj) }, true);

      if (type === 'main') {
        excelGridRef.current.setGridData(list);
        excelGridRef.current.exportAsXlsx({ fileName: '약속일준수율' });
      } else {
        excelDetailGridRef.current.setGridData(list);
        excelDetailGridRef.current.exportAsXlsx({ fileName: '약속일준수율(상세)' });
      }
      dispatch(setLoading(null));
    }
  };

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheet bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area only-right">
          <div
            className="green"
            onClick={() => {
              //
              downloadExcel('main');
            }}
          >
            엑셀 다운로드
          </div>
          <div
            className="green"
            onClick={() => {
              //
              downloadExcel('detail');
            }}
          >
            엑셀 다운로드(상세)
          </div>
        </div>
        <GridBox gridRef={gridRef} columns={columns} gridProps={{ showFooter: true, footerPosition: 'top', showRowAllCheckBox: false, showRowCheckColumn: false }} />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={detailColumns} />
      </div>
    </div>
  );
};

export default Index;
