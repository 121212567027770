import { OptionItem } from 'common/master/codeMasterReturnHelper';

export const resetAllSelections = <T extends OptionItem[], V>(masterSelection: T, searchObj: V): V => {
  const _searchObj = { ...searchObj };
  const masterSelectionValues = masterSelection?.map((ele) => ele.value);
  Object.keys(_searchObj).forEach((key, idx) => {
    if (masterSelectionValues?.includes(key) || (masterSelectionValues?.[0]?.includes('Date') && key?.includes('Date'))) {
      _searchObj[key] = null;
    }
  });
  return _searchObj;
};

type ParamType = {
  name: String; // NOTE: 얼럿 띄울 시 표시할 필터명
  value: Number | String; // NOTE : 검증할 필터 값
};

export const validateSearchFilters = (...filters: ParamType[]) => {
  let result = false;

  for (let filter of filters) {
    if (!filter.value || filter.value === '' || filter.value === undefined) {
      alert(`${filter.name}는 필수값입니다!`);

      result = false;
      return;
    }
    result = true;
  }

  return result;
};

export const capitalizeParamName = (param: string) => {
  const head = param[0].toUpperCase();
  const rest = param.slice(1);
  return head + rest;
};

export const toggleBooleanOptions = (key: string, flag: any, searchObj, setSearchObj) => {
  const matchedKey = searchObj[key];

  if (matchedKey === undefined || matchedKey === null || ((matchedKey !== undefined || matchedKey !== null) && flag !== String(searchObj[key]))) {
    setSearchObj((prev) => {
      return {
        ...prev,
        [key]: flag === 'true' ? true : false,
      };
    });
  } else {
    setSearchObj((prev) => {
      return {
        ...prev,
        [key]: null,
      };
    });
  }
};

export const optimizeSearchObj = (searchObj) => {
  const keys = Object.keys(searchObj);
  const newSearchObj = { ...searchObj };
  if (keys?.length === 0) return searchObj;

  for (let key of keys) {
    if (newSearchObj?.[key] === null || newSearchObj?.[key]?.length === 0 || newSearchObj?.[key] === '' || key === key.toUpperCase()) delete newSearchObj?.[key];
  }

  return newSearchObj;
};

export const updateSelectTypeInSearchObj = (searchObj, selectTypeObj) => {
  const selectKeys = Object?.keys(selectTypeObj);
  if (!selectKeys) return searchObj;

  let updatedSearchObj = { ...searchObj };

  for (let key of selectKeys) {
    updatedSearchObj[key] = selectTypeObj[key];
  }

  return updatedSearchObj;
};
