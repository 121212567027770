import { createSlice } from '@reduxjs/toolkit';

type PortalSliceProp = {
  showDropdown: string;
  showModal: string;
};

const initialState: PortalSliceProp = {
  showDropdown: '',
  showModal: '',
};

export const portalSlice = createSlice({
  name: 'portal',
  initialState,
  reducers: {
    setShowDropdown: (state, action) => {
      state.showDropdown = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
});

export const { setShowDropdown, setShowModal } = portalSlice.actions;

export default portalSlice.reducer;
