import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './customUploadForm';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';
import { COLS_MODEL, COLS_PRODUCT } from '../orderListTab/_asset/asset';
export const CUSTOMFORM_MASTER = [
  //
  'SELLER_AUTH',
  'SELLER_WHOLE',
  //
  'ORDER_EXCEL_TYPE',
  //
  {
    COLS: new Array(50).fill(0).map((_, i) => {
      return {
        value: String.fromCharCode(i + 66),
        label: i < 25 ? String.fromCharCode(i + 66) : 'A' + String.fromCharCode(i + 40),
      };
    }),
  },
  {
    COLS_MODEL,
  },
  {
    COLS_PRODUCT,
  },
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(CUSTOMFORM_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            //
            ORDERFORM: <Tab1 tabId={'ORDERFORM'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
