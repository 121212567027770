// react
import { useEffect, useRef, useState } from 'react';
import { InventoryInspectDTO, InventoryInspectPrintDTO, InventoryModelPrintDTO } from '../../../../_interface/warehouse';
// redux
import { useDispatch } from 'react-redux';
import CommonModalNew from 'components/modal/commonModalNew';
import { setLoading } from 'redux/services/menuSlice';
import ModalforBarcode, { IBarcodeInfoObj, PrintTitle } from 'components/print/print';
import { serviceStore } from 'services/services';
import ReactToPrint from 'react-to-print';

export interface IPrints {
  visible?: boolean;
  item?: InventoryInspectDTO;
}

export const InspectPrint = ({ printObj, setPrintObj }) => {
  const dispatch = useDispatch();
  const [printData, setPrintData] = useState<InventoryInspectPrintDTO & { totalAvailableCount: number; totalDefectedCount: number }>();
  const [barcodeInfoObj, setBarcodeInfoObj] = useState<IBarcodeInfoObj>();

  const wrapperRef = useRef();
  useEffect(() => {
    fetchPrintData(printObj?.item);
  }, []);

  const fetchPrintData = async (item) => {
    dispatch(setLoading('GET'));
    //
    const rs = await serviceStore.warehouseAction(`inventory/inspect/print`, 'GET', { inventoryInspectSeq: item?.inventoryInspectSeq });
    if (rs?.status === 200) {
      setPrintData({
        ...rs?.data,
        totalAvailableCount: (rs?.data?.models as InventoryModelPrintDTO[])
          ?.map((ele) => ele.availableQuantity)
          ?.reduce((acc: number, cur: number) => {
            return acc + cur;
          }),
        totalDefectedCount: (rs?.data?.models as InventoryModelPrintDTO[])
          ?.map((ele) => ele.defectedQuantity)
          ?.reduce((acc: number, cur: number) => {
            return acc + cur;
          }),
      });
    }
    dispatch(setLoading(null));
  };

  return (
    <>
      <CommonModalNew
        style={{ width: 1300 }}
        visible={printObj?.visible}
        setVisible={() => setPrintObj(null)}
        rightTitle={
          <>
            <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
          </>
        }
        children={
          <>
            <div
              ref={wrapperRef}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ModalforBarcode barcodeInfoObj={barcodeInfoObj} setBarcodeInfoObj={setBarcodeInfoObj} />
              <div style={{ padding: 30, width: '100%' }} className="break-for-onePage-print">
                <PrintTitle seq={printData?.inventoryInspectSeq} title={<div>검수작업 지시서</div>} />
                <div className="presenterInput">
                  <span>1. 기본정보</span>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <table className="border-table" style={{ width: '150mm' }}>
                      <thead>
                        <tr>
                          <th>전기일</th>
                          <td colSpan={5}>{printData?.postingDate}</td>
                        </tr>
                        <tr>
                          <th>검수생성일</th>
                          <td colSpan={5}>{printData?.registerDate}</td>
                        </tr>
                        <tr>
                          <th>검수창고</th>
                          <td colSpan={5}>{printData?.centerName}</td>
                        </tr>
                      </thead>
                    </table>
                    <table className="border-table" style={{ width: '100mm' }}>
                      <thead>
                        <tr>
                          <th>작업지시일</th>
                          <th>작업지시자(인)</th>
                          <th>작업자(인)</th>
                          <th>창고장(인)</th>
                        </tr>
                        <tr style={{ height: 50 }}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
                <div className="presenterInput">
                  <span>2. 제품정보</span>

                  <table className="border-table">
                    <thead className="text-center">
                      <tr>
                        <th colSpan={12}>제품정보</th>
                        <th colSpan={2}>수량</th>
                      </tr>
                      <tr>
                        <th colSpan={2}>로케이션</th>
                        <th colSpan={2}>바코드</th>
                        <th colSpan={2}>
                          SKU
                          <br />
                          (외부SKU)
                        </th>
                        <th colSpan={3}>제품</th>
                        <th colSpan={3}>제품명</th>
                        <th>양품</th>
                        <th>불용</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {printData?.models?.map((list, idx) => (
                        <tr key={`model_${idx}`}>
                          <td colSpan={2}>{list.locationCode}</td>
                          <td colSpan={2} onClick={() => setBarcodeInfoObj({ visible: true, data: list?.barcode })}>
                            {list.barcode}
                          </td>
                          <td colSpan={2}>
                            {list.modelStockSeq}
                            <br />({list.skuNumber})
                          </td>
                          <td colSpan={3}>{list.model}</td>
                          <td colSpan={3}>
                            {/* {list?.handleWithCareFlag && <HandleWithCare />} */}
                            {list.modelName}
                          </td>
                          <td>{list.availableQuantity}</td>
                          <td>{list.defectedQuantity}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot style={{ textAlign: 'center' }}>
                      <tr>
                        <td style={{ textAlign: 'right' }} colSpan={12}>
                          계
                        </td>
                        <td>{printData?.totalAvailableCount}</td>
                        <td>{printData?.totalDefectedCount}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
