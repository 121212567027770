import { KeyboardEvent } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { IFeeSearch } from '..';

export const SearchBox = (props: ISearchBoxProps<IFeeSearch>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList, TARGET } = props;
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            options={MASTER_OPS?.SELLER_SELLER_AUTH}
            value={
              searchObj?.partnerSeq
                ? {
                    label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[searchObj?.partnerSeq],
                    value: searchObj?.partnerSeq,
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  partnerSeq: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            options={MASTER_OPS?.CENTER_AUTH}
            value={
              searchObj?.centerCode
                ? {
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                    value: searchObj?.centerCode,
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  centerCode: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
        {TARGET?.includes('_IN_') && (
          <>
            <Col>
              <label className="col-form-label">운송유형</label>
              <SelectD
                isMulti={true}
                options={MASTER_OPS?.LOGISTIC_TYPE}
                value={searchObj?.logisticTypes?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.LOGISTIC_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      logisticTypes: (option as OptionItem[])?.map((ele) => ele.value),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">작업유형</label>
              <SelectD
                isMulti={true}
                options={MASTER_OPS?.WORK_TYPE}
                value={searchObj?.workTypes?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.WORK_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      workTypes: (option as OptionItem[])?.map((ele) => ele.value),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <button
                className="btn btn-search"
                onClick={(e) => {
                  e.preventDefault();
                  fetchList(searchObj);
                }}
              >
                조회
              </button>
            </Col>
            <Col />
          </>
        )}
        {TARGET?.includes('_OUT_') && (
          <>
            <Col>
              <label className="col-form-label">작업유형</label>
              <SelectD
                isMulti={true}
                options={MASTER_OPS?.WORK_TYPE}
                value={searchObj?.workTypes?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.WORK_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      workTypes: (option as OptionItem[])?.map((ele) => ele.value),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">파렛트유형</label>
              <SelectD
                isMulti={true}
                options={MASTER_OPS?.PALLET_TYPE}
                value={searchObj?.palletTypes?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PALLET_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      palletTypes: (option as OptionItem[])?.map((ele) => ele.value),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <button
                className="btn btn-search"
                onClick={(e) => {
                  e.preventDefault();
                  fetchList(searchObj);
                }}
              >
                조회
              </button>
            </Col>
            <Col />
          </>
        )}
        {TARGET?.includes('_STORAGE_') && (
          <>
            <Col>
              <label className="col-form-label">로케이션구분</label>
              <SelectD
                isMulti={true}
                options={MASTER_OPS?.LOCATION_CATEGORY}
                value={searchObj?.locationCategory?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.LOCATION_CATEGORY?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      locationCategory: (option as OptionItem[])?.map((ele) => ele.value),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">로케이션규격</label>
              <SelectD
                isMulti={true}
                options={MASTER_OPS?.LOCATION_SIZE}
                value={searchObj?.locationSize?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.LOCATION_SIZE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      locationSize: (option as OptionItem[])?.map((ele) => ele.value),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">적용단위</label>
              <SelectD
                isMulti={true}
                options={MASTER_OPS?.DATE_UNIT}
                value={searchObj?.dateUnit?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.DATE_UNIT?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      dateUnit: (option as OptionItem[])?.map((ele) => ele.value),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <button
                className="btn btn-search"
                onClick={(e) => {
                  e.preventDefault();
                  fetchList(searchObj);
                }}
              >
                조회
              </button>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};
