import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';

// utils
import styled from 'styled-components';
import { InvoiceListDTOKr, Title } from '..';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import * as IGrid from 'aui-grid';
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { DivideOrder } from 'pages/ORDER/1order/orderListTab/common/component/divideOrder';
import { httpClient } from 'common/http-client/axiosConfig';
import { setLoading } from 'redux/services/menuSlice';
import { REASSIGN_INVOICE } from 'envVar';
import { keyboardKey } from '@testing-library/user-event';
import { OrderDetail } from 'pages/ORDER/1order/orderListTab/common/component/invocieDetail/orderDetail';
import { IDetailObjProps } from 'pages/ORDER/1order/orderListTab';

const NoDriverArea = styled.div`
  position: relative;
  padding: 20px;
  border: 1px solid #cccccc;
  width: 30%;
  max-width: 700px;
  margin-right: 20px;
  min-height: 100%;
`;

const FilterArea = styled.div``;
const GridArea = styled.div``;

interface IFilterObj {
  deliveryDate?: string;
  promiseDate?: string;
  region: string;
  receiverName: string;
  orderTypes: string[];
}

export const NotThisDriver = ({ noDriverObj }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const gridRef2 = useRef<IPagingGrid>();
  const [rawData, setRawData] = useState<{
    //
    noDriver: InvoiceListDTOKr[];
    noDate: InvoiceListDTOKr[];
  }>();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [orderDetailObj, setOrderDetailObj] = useState<IDetailObjProps>({
    visible: false,
  });
  const [filterObj, setFilterObj] = useState<IFilterObj>();
  const [filterObj2, setFilterObj2] = useState<IFilterObj>();

  const [divideOrderObj, setDivideOrderObj] = useState({
    visible: false,
    items: null,
  });

  useEffect(() => {
    const filterObj = {
      deliveryDate: null,
      region: null,
      receiverName: null,
      orderTypes: [...MASTER_OPS?.ORDER_TYPE?.map((ele: OptionItem) => ele.value), 'ALL'],
    };
    setFilterObj(filterObj);

    const filterObj2 = {
      promiseDate: null,
      region: null,
      receiverName: null,
      orderTypes: [...MASTER_OPS?.ORDER_TYPE?.map((ele: OptionItem) => ele.value), 'ALL'],
    };

    setFilterObj2(filterObj2);
  }, []);

  useEffect(() => {
    if (noDriverObj?.data) {
      const noDriver = noDriverObj?.data?.filter((ele: InvoiceListDTOKr) => !ele.userId);
      const noDate = noDriverObj?.data?.filter((ele: InvoiceListDTOKr) => ele.userId && !ele.deliveryDate);
      setRawData({
        noDriver,
        noDate,
      });
      gridRef.current.setGridData(noDriver);
      gridRef2.current.setGridData(noDate);
    }
  }, [noDriverObj]);

  const fetchList = (filterObj: IFilterObj, isNoDriver: boolean) => {
    if (isNoDriver) {
      let filtered = rawData?.noDriver?.filter((ele) => filterObj?.orderTypes?.includes(ele.orderType));
      if (filterObj?.deliveryDate) filtered = filtered?.filter((ele) => ele.deliveryDate?.includes(filterObj?.deliveryDate));
      if (filterObj?.region) filtered = filtered?.filter((ele) => ele.region?.includes(filterObj?.region));
      if (filterObj?.receiverName) filtered = filtered?.filter((ele) => ele.receiverName?.includes(filterObj?.receiverName));
      gridRef.current.setGridData(filtered);
    } else {
      let filtered2 = rawData?.noDate?.filter((ele) => filterObj?.orderTypes?.includes(ele.orderType));
      if (filterObj?.deliveryDate) filtered2 = filtered2?.filter((ele) => ele.deliveryDate?.includes(filterObj?.deliveryDate));
      if (filterObj?.region) filtered2 = filtered2?.filter((ele) => ele.region?.includes(filterObj?.region));
      if (filterObj?.receiverName) filtered2 = filtered2?.filter((ele) => ele.receiverName?.includes(filterObj?.receiverName));
      gridRef2.current.setGridData(filtered2);
    }
  };

  const onKeyPress = (e: keyboardKey, isDriver: boolean) => {
    if (e.key === 'Enter') {
      fetchList(filterObj, isDriver);
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: (e: IGrid.ButtonClickEvent) => {
          setOrderDetailObj({
            visible: true,
            item: {
              invoiceSeq: e.item.invoiceSeq,
              orderSeq: e.item.orderSeq,
            },
          });
        },
      },
    },
    {
      headerText: '지정일',
      dataField: 'deliveryDate',
    },
    {
      headerText: '수령인',
      dataField: 'receiverName',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '송장상태',
      dataField: 'invoiceStatusKr',
    },
    {
      headerText: '지역',
      width: 100,
      dataField: 'regionKr',
    },
    {
      headerText: '출고가능일',
      dataField: 'predictDeliveryDate',
    },
  ];

  const columns2: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: (e: IGrid.ButtonClickEvent) => {
          setOrderDetailObj({
            visible: true,
            item: {
              invoiceSeq: e.item.invoiceSeq,
              orderSeq: e.item.orderSeq,
            },
          });
        },
      },
    },
    {
      headerText: '희망일',
      dataField: 'promiseDate',
    },
    {
      headerText: '수령인',
      dataField: 'receiverName',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '송장상태',
      dataField: 'invoiceStatusKr',
    },
    {
      headerText: '지역',
      width: 100,
      dataField: 'regionKr',
    },
    {
      headerText: '출고가능일',
      dataField: 'predictDeliveryDate',
    },
  ];
  useEffect(() => {
    if (rawData) fetchList(filterObj, true);
  }, [filterObj?.orderTypes]);

  useEffect(() => {
    if (rawData) fetchList(filterObj2, false);
  }, [filterObj2?.orderTypes]);

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, isDriver: boolean) => {
    const id = e.target.id;
    const orderTypes = isDriver ? [...filterObj?.orderTypes] : [...filterObj2?.orderTypes];
    if (id === 'ALL') {
      if (orderTypes.includes('ALL')) {
        isDriver
          ? setFilterObj((prev) => {
              return {
                ...prev,
                orderTypes: [],
              };
            })
          : setFilterObj2((prev) => {
              return {
                ...prev,
                orderTypes: [],
              };
            });
      } else {
        isDriver
          ? setFilterObj((prev) => {
              return {
                ...prev,
                orderTypes: [...MASTER_OPS?.ORDER_TYPE?.map((ele: OptionItem) => ele.value), 'ALL'],
              };
            })
          : setFilterObj2((prev) => {
              return {
                ...prev,
                orderTypes: [...MASTER_OPS?.ORDER_TYPE?.map((ele: OptionItem) => ele.value), 'ALL'],
              };
            });
      }
    } else {
      if (orderTypes?.includes(e.target.id)) {
        isDriver
          ? setFilterObj((prev) => {
              return {
                ...prev,
                orderTypes: orderTypes.filter((type) => type !== e.target.id),
              };
            })
          : setFilterObj2((prev) => {
              return {
                ...prev,
                orderTypes: orderTypes.filter((type) => type !== e.target.id),
              };
            });
      } else {
        orderTypes.push(id);
        isDriver
          ? setFilterObj((prev) => {
              return {
                ...prev,
                orderTypes,
              };
            })
          : setFilterObj2((prev) => {
              return {
                ...prev,
                orderTypes,
              };
            });
      }
    }
  };

  const reassignDriverAPI = async (items) => {
    dispatch(setLoading('POST'));
    const data = {
      invoiceSeqs: items?.map((ele) => ele.invoiceSeq),
      centerCode: noDriverObj?.searchObj?.centerCode,
      deliveryDate: noDriverObj?.searchObj?.deliveryDate,
    };
    await httpClient.post(REASSIGN_INVOICE, data).then((rs) => {
      if (rs?.status === 200) {
        alert('자동 분배에 성공하였습니다!');
        noDriverObj?.fetchFn(noDriverObj?.searchObj);
      }
    });
    dispatch(setLoading(null));
  };

  return (
    <NoDriverArea>
      {divideOrderObj?.visible && <DivideOrder divideOrderObj={divideOrderObj} setDivideOrderObj={setDivideOrderObj} />}
      {orderDetailObj?.visible && <OrderDetail orderDetailObj={orderDetailObj} setOrderDetailObj={setOrderDetailObj} />}
      <Title>기사 미지정 주문</Title>
      <FilterArea>
        <Form onKeyPress={(e) => onKeyPress(e, true)}>
          <Row>
            <Col>
              <label className="col-form-label">지정일</label>
              <FlatpickrD
                options={{ mode: 'single' }}
                value={filterObj?.deliveryDate}
                onChange={(option) => {
                  setFilterObj({
                    ...filterObj,
                    deliveryDate: returnDateyyyymmdd(option[0]),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">지역</label>
              <SelectD
                options={MASTER_OPS?.REGION}
                value={
                  filterObj?.region
                    ? {
                        label: MASTER_OBJ?.REGION?.[filterObj?.region],
                        value: filterObj?.region,
                      }
                    : null
                }
                onChange={(option) => {
                  setFilterObj({
                    ...filterObj,
                    region: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">수령인</label>
              <InputD
                value={filterObj?.receiverName || ''}
                onChange={(e) => {
                  setFilterObj({
                    ...filterObj,
                    receiverName: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>

          <div className="status-panel" style={{ marginTop: 20 }}>
            <span>
              <input type="checkbox" id="ALL" checked={filterObj?.orderTypes?.includes('ALL') || false} onChange={(e) => handleCheckbox(e, true)} />
              <label htmlFor="all">전체</label>
            </span>
            {MASTER_OPS?.ORDER_TYPE?.map((ele: OptionItem, idx: number) => {
              return (
                <span key={`orderType_${idx}`}>
                  <input type="checkbox" checked={filterObj?.orderTypes?.includes(ele.value) || false} id={ele.value} onChange={(e) => handleCheckbox(e, true)} />
                  <label htmlFor={ele.value}>{ele.label}</label>
                </span>
              );
            })}
          </div>
          <Row>
            <Col style={{ textAlign: 'right', height: 30 }}>
              <button
                className="btn-search btn"
                style={{ marginTop: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  fetchList(filterObj, true);
                }}
              >
                조회
              </button>
            </Col>
          </Row>
        </Form>
      </FilterArea>
      <GridArea>
        <div className="grid-button-area only-right">
          <div
            className="orange"
            onClick={() => {
              const items = gridRef.current.getCheckedRowItemsAll();
              if (items?.length > 0) {
                setDivideOrderObj((prev) => {
                  return {
                    ...prev,
                    visible: true,
                    items: items?.map((item) => item.invoiceSeq),
                    searchObj: noDriverObj?.searchObj,
                    fetchFn: (data) => noDriverObj?.fetchFn(data),
                  };
                });
              } else {
                alert('선택된 항목이 존재하지 않습니다.');
              }
            }}
          >
            주문분배
          </div>
          <div
            className="orange"
            onClick={() => {
              const items = gridRef.current.getCheckedRowItemsAll();
              if (items?.length > 0) {
                if (window.confirm(`선택된 ${items?.length}건에 대해서 자동분배를 진행하시겠습니까?`)) {
                  reassignDriverAPI(items);
                }
              } else {
                alert('선택된 항목이 존재하지 않습니다.');
              }
            }}
          >
            자동분배
          </div>
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            height: 300,
            enableDragByCellDrag: false,
            enableDrag: false,
            enableDrop: false,
            dropToOthers: false,
          }}
        />
      </GridArea>
      <Title style={{ marginTop: 30 }}>날짜 미지정 주문</Title>
      <FilterArea>
        <Form onKeyPress={(e) => onKeyPress(e, false)}>
          <Row>
            <Col>
              <label className="col-form-label">희망일</label>
              <FlatpickrD
                options={{ mode: 'single' }}
                value={filterObj2?.promiseDate}
                onChange={(option) => {
                  setFilterObj2({
                    ...filterObj2,
                    promiseDate: returnDateyyyymmdd(option[0]),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">지역</label>
              <SelectD
                options={MASTER_OPS?.REGION}
                value={
                  filterObj2?.region
                    ? {
                        label: MASTER_OBJ?.REGION?.[filterObj2?.region],
                        value: filterObj2?.region,
                      }
                    : null
                }
                onChange={(option) => {
                  setFilterObj2({
                    ...filterObj2,
                    region: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">수령인</label>
              <InputD
                value={filterObj2?.receiverName || ''}
                onChange={(e) => {
                  setFilterObj2({
                    ...filterObj2,
                    receiverName: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>

          <div className="status-panel" style={{ marginTop: 20 }}>
            <span>
              <input type="checkbox" id="ALL" checked={filterObj2?.orderTypes?.includes('ALL') || false} onChange={(e) => handleCheckbox(e, false)} />
              <label htmlFor="all">전체</label>
            </span>
            {MASTER_OPS?.ORDER_TYPE?.map((ele: OptionItem, idx: number) => {
              return (
                <span key={`orderType_${idx}`}>
                  <input type="checkbox" checked={filterObj2?.orderTypes?.includes(ele.value) || false} id={ele.value} onChange={(e) => handleCheckbox(e, false)} />
                  <label htmlFor={ele.value}>{ele.label}</label>
                </span>
              );
            })}
          </div>
          <Row>
            <Col style={{ textAlign: 'right', height: 30 }}>
              <button
                className="btn-search btn"
                style={{ marginTop: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  fetchList(filterObj, false);
                }}
              >
                조회
              </button>
            </Col>
          </Row>
        </Form>
      </FilterArea>
      <GridArea>
        <div className="grid-button-area only-right">
          <div
            className="orange"
            onClick={() => {
              const items = gridRef2.current.getCheckedRowItemsAll();
              if (items?.length > 0) {
                setDivideOrderObj((prev) => {
                  return {
                    ...prev,
                    visible: true,
                    items: items?.map((item) => item.invoiceSeq),
                    searchObj: noDriverObj?.searchObj,
                    fetchFn: (data) => noDriverObj?.fetchFn(data),
                  };
                });
              } else {
                alert('선택된 항목이 존재하지 않습니다.');
              }
            }}
          >
            주문분배
          </div>
          <div
            className="orange"
            onClick={() => {
              const items = gridRef2.current.getCheckedRowItemsAll();
              if (items?.length > 0) {
                if (window.confirm(`선택된 ${items?.length}건에 대해서 자동분배를 진행하시겠습니까?`)) {
                  reassignDriverAPI(items);
                }
              } else {
                alert('선택된 항목이 존재하지 않습니다.');
              }
            }}
          >
            자동분배
          </div>
        </div>
        <GridBox
          gridRef={gridRef2}
          columns={columns2}
          gridProps={{
            height: 300,
            enableDragByCellDrag: false,
            enableDrag: false,
            enableDrop: false,
            dropToOthers: false,
          }}
        />
      </GridArea>
    </NoDriverArea>
  );
};
