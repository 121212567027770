import { Button, Col, Row, Input } from 'reactstrap';
import Grid from 'components/grid/auiGrid';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { useEffect } from 'react';
import SearchAvailableModelModal from 'components/modal/searchAvailableModelModal';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { setLoading } from 'redux/services/menuSlice';
import { _TAKEOUT_PICKING_REGIT_FORM } from 'envVar2';

const Presenter = (props) => {
  const dispatch = useDispatch();
  const {
    gridId,
    rows,
    setRows,
    columns,

    fileSelector,

    searchObj,
    searchModelObj,
    setSearchModelObj,

    getAllGridDataTriggerThisGrid,
    setGetAllGridDataTriggerThisGrid,
    getCheckedDataTriggerThisGrid,
    setGetCheckedDataTriggerThisGrid,

    createPicking,
  } = props;

  useEffect(() => {
    if (rows?.length > 0) {
      const confirm = window.confirm('기본정보를 바꾸시면 초기화됩니다. 진행하시겠습니까?');
      if (confirm) {
        setRows([]);
      }
    }
  }, [searchObj?.centerCode, searchObj?.reason, searchObj?.partnerSeq]);

  return (
    <>
      {searchModelObj?.visible && <SearchAvailableModelModal searchModelObj={searchModelObj} setSearchModelObj={setSearchModelObj} />}
      <div className="page-content">
        <div style={{ textAlign: 'right' }}>
          <Button
            className="btn-confirm btn"
            onDoubleClick={() => {
              return;
            }}
            onClick={() => {
              if (!searchObj?.stockRemoveDate) {
                alert('반출예정일을 입력하세요');
              } else if (!searchObj?.centerCode) {
                alert('반출창고를 입력하세요');
              } else if (!searchObj?.reason) {
                alert('반출유형을 입력하세요');
              } else if (!searchObj?.partnerSeq) {
                alert('판매사를 입력하세요');
              } else if (!searchObj?.target) {
                alert('반출대상을 입력하세요');
              } else {
                dispatch(setLoading('PUT'));
                const rows = window.AUIGrid.getGridData(`#${gridId}`);
                createPicking(rows, searchObj);
              }
            }}
          >
            반출피킹 생성
          </Button>
        </div>
        <div className="presenterInput">
          <SearchInfo {...props} />
        </div>
        <div className="presenterInput">
          <GridBox
            gridId={gridId}
            rows={rows}
            setRows={setRows}
            columns={columns}
            fileSelector={fileSelector}
            searchObj={searchObj}
            searchModelObj={searchModelObj}
            setSearchModelObj={setSearchModelObj}
            getAllGridDataTriggerThisGrid={getAllGridDataTriggerThisGrid}
            setGetAllGridDataTriggerThisGrid={setGetAllGridDataTriggerThisGrid}
            getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
            setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
          />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const SearchInfo = (props) => {
  const { masterOptions, MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);
  const { searchObj, setSearchObj } = props;

  return (
    <>
      <div className="inputTitle mb-4">1. 기본정보</div>
      <>
        <Row style={{ marginLeft: 10 }}>
          <Col>
            <label className="col-form-label">*반출예정일</label>
            <FlatpickrD
              value={searchObj?.stockRemoveDate}
              onChange={(date) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    stockRemoveDate: returnDateyyyymmdd(date),
                  };
                });
              }}
              options={{
                mode: 'single',
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">*반출창고</label>
            <SelectD
              value={
                searchObj.centerCode
                  ? {
                      value: searchObj.centerCode,
                      label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                    }
                  : null
              }
              options={MASTER_OPS?.CENTER_AUTH}
              onChange={({ value }) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    centerCode: value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">*반출유형</label>
            <SelectD
              value={
                searchObj.reason
                  ? {
                      value: searchObj.reason,
                      label: MASTER_OBJ?.REMOVE_REASON?.[searchObj?.reason],
                    }
                  : null
              }
              options={MASTER_OPS?.REMOVE_REASON}
              onChange={({ value }) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    reason: value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">*판매사</label>
            <SelectD
              value={
                searchObj.partnerSeq
                  ? {
                      value: searchObj.partnerSeq,
                      label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[searchObj?.partnerSeq],
                    }
                  : null
              }
              options={MASTER_OPS?.SELLER_SELLER_AUTH}
              onChange={({ value }) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    partnerSeq: value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">*반출대상</label>
            <InputD
              value={searchObj.target || ''}
              onChange={(e) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    target: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">특이사항</label>
            <InputD
              value={searchObj.memo || ''}
              onChange={(e) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    memo: e.target.value,
                  };
                });
              }}
            />
          </Col>
        </Row>
      </>
    </>
  );
};

const GridBox = (props) => {
  const { AUIGrid } = window;

  const {
    gridId,
    rows = [],
    columns,

    fileSelector,

    searchObj,
    searchModelObj,
    setSearchModelObj,
    getAllGridDataTriggerThisGrid,
    setGetAllGridDataTriggerThisGrid,
    getCheckedDataTriggerThisGrid,
    setGetCheckedDataTriggerThisGrid,
  } = props;

  const upload = () => {
    document.getElementById('excelUpload').click();
  };

  const cellEditEndThisGrid = (event) => {
    const reason = event.item?.reason;
    if (reason === 'MAKER_EXPORT') {
      if (event.dataField === 'requestAvailableQuantity') {
        if (event.value > event.item.requestAvailableQuantityDefault) {
          alert('보관수량보다 많이 입력하셨습니다');
          AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
        }
      } else if (event.dataField === 'requestDefectedQuantity') {
        alert('양품반출에서는 불용값을 설정할 수 없습니다!');
        AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
      }
    } else if (reason === 'DEFECT_EXPORT') {
      if (event.dataField === 'requestAvailableQuantity') {
        alert('불량반출에서는 양품값을 설정할 수 없습니다!');
        AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
      } else if (event.dataField === 'requestDefectedQuantity') {
        if (event.value > event.item.requestDefectedQuantityDefault) {
          alert('보관수량보다 많이 입력하셨습니다');
          AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
        }
      }
    } else if (reason === 'LOST' || reason === 'PARCEL') {
      if (event.dataField === 'requestAvailableQuantity') {
        if (event.value > event.item.requestAvailableQuantityDefault) {
          alert('보관수량보다 많이 입력하셨습니다');
          AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
        }
      }
      if (event.dataField === 'requestDefectedQuantity') {
        if (event.value > event.item.requestDefectedQuantityDefault) {
          alert('보관수량보다 많이 입력하셨습니다');
          AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
        }
      }
    }
  };

  return (
    <>
      <div className="inputTitle mb-2">2. 피킹 상세정보</div>
      <div className="grid-button-area space-between">
        <div className="notBtn">
          <div>
            제품 수량 : 총 &nbsp; <span>{rows.length}</span>개
          </div>
          {/* <div>양품 수량 : <span className="blue"> */}
          {/* {availAble} */}
          {/* </span>개</div>
          <div>불용 수량 : <span className="red"> */}
          {/* {unavailAble} */}
          {/* </span>개</div> */}
        </div>
        <div style={{ border: 0 }}>
          <div
            className="red"
            onClick={() => {
              setGetCheckedDataTriggerThisGrid({
                type: 'remove-thisRows',
                id: `#${gridId}`,
              });
            }}
          >
            선택 행 삭제
          </div>
          <div
            className="green"
            onClick={() => {
              window.open(_TAKEOUT_PICKING_REGIT_FORM);
            }}
          >
            엑셀양식
          </div>
          <div
            className="green"
            onClick={() => {
              if (!searchObj?.centerCode) {
                alert('반출창고를 입력하세요');
              } else if (!searchObj?.reason) {
                alert('반출유형을 입력하세요');
              } else if (!searchObj?.partnerSeq) {
                alert('판매사를 입력하세요');
              } else {
                upload();
              }
            }}
          >
            <Input style={{ display: 'none' }} onChange={fileSelector} accept=".xlsx" type="file" name="file" id="excelUpload" />+ 엑셀등록
          </div>
          <div
            className="orange"
            onClick={() => {
              if (!searchObj?.centerCode) {
                alert('반출창고를 입력하세요');
              } else if (!searchObj?.reason) {
                alert('반출유형을 입력하세요');
              } else if (!searchObj?.partnerSeq) {
                alert('판매사를 입력하세요');
              } else {
                setSearchModelObj({
                  ...searchModelObj,
                  visible: true,
                  params: searchObj,
                });
              }
            }}
          >
            + 반출재고 제품 추가
          </div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        {gridId && (
          <Grid
            id={gridId}
            rows={rows}
            columns={columns}
            style={{
              height: 700,
            }}
            //grid-function
            cellEditEndThisGrid={cellEditEndThisGrid}
            getAllGridDataTriggerThisGrid={getAllGridDataTriggerThisGrid}
            setGetAllGridDataTriggerThisGrid={setGetAllGridDataTriggerThisGrid}
            getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
            setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            settingOptions={{
              enableFocus: false,
              showStateColumn: false,
              selectionMode: 'singleRow',
              softRemoveRowMode: false,
              showRowAllCheckBox: true,
              independentAllCheckBox: false,
              showRowCheckColumn: true,
              showStateColumn: false,
            }}
          />
        )}
      </div>
    </>
  );
};
