import AUIGrid from 'modules/Grid';
import { useEffect, useRef } from 'react';
import * as IGrid from 'aui-grid';
import { FunctionDTO } from 'interface/user';
import { userService } from 'pages/ETC/3user/_service/service';
import { defaultGridProps } from 'common/grid/gridBox';
import { delay } from 'common/util/counting';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { useIsMounted } from 'hooks/basichooks';
import { BUTTON_COLOR } from '..';
import CommonModalNew from 'components/modal/commonModalNew';

export interface ISortObj {
  e?: IGrid.ButtonClickEvent;
  visible: boolean;
  tabName?: string;
  items?: FunctionDTO[];
  fetchFn?: <T>(T) => void;
}

interface ISortModal {
  sortObj: ISortObj;
  setSortObj: React.Dispatch<React.SetStateAction<ISortObj>>;
}

export const SortModal = ({ sortObj, setSortObj }: ISortModal) => {
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();
  const columns: IGrid.Column[] = [
    {
      headerText: 'Function명',
      dataField: 'functionNameKr',
    },
    {
      headerText: '색',
      dataField: 'color',
      width: 100,
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        if (value)
          return `<div style="text-align: left;margin-left: 10px"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" fill="${BUTTON_COLOR[value]}" /></svg><span style="margin-left: 5px">${value}</span></div>`;
      },
    },
    {
      headerText: '설명',
      dataField: 'description',
    },
  ];

  const isMounted = useIsMounted();

  useEffect(() => {
    dispatch(setLoading('GET'));
    void delay(1000).then(() => {
      if (isMounted) {
        gridRef.current.setGridData(
          sortObj?.items?.map((item) => {
            return {
              ...item,
              functionNameKr: `${item?.functionGroup ? '[' + item?.functionGroup + ']' : ''} ${item?.functionName}`,
            };
          }),
        );
        dispatch(setLoading(null));
      }
    });
  }, [isMounted]);

  const saveDecs = async () => {
    dispatch(setLoading('POST'));
    console.log(sortObj?.items);
    const itemObj = {};
    sortObj?.items?.forEach((ele) => {
      //
      itemObj[ele.functionId] = ele;
    });
    const dataArr = gridRef.current.getGridData().map((ele, idx) => {
      const item = itemObj[ele.functionId];
      return {
        ...item,
        sort: idx,
        color: item?.functionType === 'GRID' && item?.functionId?.includes('SHOW') ? null : item?.color,
        functionTitle: item?.functionType === 'GRID' && item?.functionId?.includes('SHOW') ? `[${item?.functionName}]` : item.functionTitle || '',
        description: `${item?.tabName}(${item?.functionTypeKr})/ ${item?.functionGroup ? '[' + item?.functionGroup + ']' : ''} ${item?.functionName} (${idx + 1}/${sortObj?.items?.length}) `,
      };
    });

    dataArr?.forEach(async (ele) => {
      setTimeout(async () => {
        await userService.modiFunction(ele);
      }, 200);
    });
    if (sortObj?.e?.item?.searchObj) sortObj?.fetchFn(JSON.parse(sortObj?.e?.item?.searchObj));
    dispatch(setLoading(null));
  };

  const saveSort = async () => {
    const rows = gridRef.current.getGridData();
    const data = rows.map((ele, idx) => {
      return {
        functionId: ele.functionId,
        sort: idx + 1,
      };
    });

    const rs = await userService.saveFunctionSort(data);
    if (rs?.status === 200) {
      if (sortObj?.e?.item?.searchObj) sortObj?.fetchFn(JSON.parse(sortObj?.e?.item?.searchObj));
    }
  };
  return (
    <CommonModalNew
      title={`[${sortObj?.tabName}(${sortObj?.e?.item?.functionTypeKr})] 기능 순서`}
      subTitle={'드래그앤 드롭으로 순서를 정하세요 '}
      style={{ width: 700 }}
      visible={sortObj?.visible}
      setVisible={() => {
        setSortObj(null);
      }}
      children={
        <>
          <div className="grid-button-area">
            <div
              className="orange"
              onClick={() => {
                saveSort();
              }}
            >
              순서저장
            </div>
            <div
              className="orange"
              onClick={() => {
                saveDecs();
              }}
            >
              설명 저장
            </div>
          </div>
          <AUIGrid
            ref={gridRef}
            columnLayout={columns}
            name={`sortModal`}
            gridProps={{
              ...defaultGridProps,
              height: 500,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              //
              enableDrag: true,
              enableMultipleDrag: true,
              enableDragByCellDrag: true,
            }}
          />
        </>
      }
    />
  );
};
