import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import CommonModalNew from 'components/modal/commonModalNew';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';
import { OrderSearchDTO, OrderSSListDTO } from 'pages/ORDER/2ss/_interface/order';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';

const translator = {
  title: {
    divide: '배송변경일 변경',
  },
};

export interface IPopupObj {
  visible: boolean;
  type: 'divide';
  items?: OrderSSListDTO[];
  searchObj?: OrderSearchDTO;
  fetchFn?: (searchObj: OrderSearchDTO) => void;
}

export const Popup = ({ popupObj, setPopupObj }) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [createObj, setCreateObj] = useState<any>();
  const actionHandler = () => {
    //
  };
  return (
    <CommonModalNew
      title={translator?.title?.[popupObj?.type]}
      visible={popupObj?.visible}
      setVisible={() => {
        setPopupObj(null);
      }}
      style={{ width: 320 }}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={() => {
              actionHandler();
            }}
          >
            변경
          </button>
        </>
      }
      children={
        <>
          <Row>
            <Col style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
              <input
                id="confirm"
                type="checkbox"
                checked={createObj?.confirmFlag}
                onChange={() => {
                  if (createObj?.deliveryDateFlag && !createObj?.deliveryDate) {
                    alert('지정일 없이 스케줄확정처리 할 수 없습니다');
                  } else {
                    setCreateObj({
                      ...createObj,
                      confirmFlag: !createObj?.confirmFlag,
                    });
                  }
                }}
              />
              <label htmlFor="confirm">스케줄확정 포함</label>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center', height: 50 }}>
              <div>
                총 <span className="b">{popupObj?.items?.length}</span>건에 대하여 변경 진행합니다
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">배송예정일 선택</label>
              <FlatpickrD
                options={{
                  mode: 'single',
                }}
                value={createObj?.deliveryDate}
                onReset={() => {
                  setCreateObj({
                    ...createObj,
                    deliveryDate: null,
                  });
                }}
                onChange={(date) => {
                  setCreateObj({
                    ...createObj,
                    deliveryDate: returnDateyyyymmdd(date[0]),
                  });
                }}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};
