import * as IGrid from 'aui-grid';
const defaultColumns: IGrid.Column[] = [
  {
    headerText: '판매사',
    dataField: 'partnerSeqKr',
  },
  {
    headerText: '인프라',
    dataField: 'infraSeqKr',
  },
  {
    headerText: '창고',
    dataField: 'centerCodeKr',
  },
  {
    headerText: '일/공휴일 제외 <br />Capa(건수)',
    dataField: 'capacityForWorkday',
  },
  {
    headerText: '일/공휴일<br /> Capa(건수)',
    dataField: 'capacityForWeekend',
  },
  {
    headerText: `일/공휴일 제외<br />완료 건수(%)`,
    dataField: 'totalQuantityForWorkdayKr',
  },
  {
    headerText: '일/공휴일 <br />완료 건수(%)',
    dataField: 'totalQuantityForWeekendKr',
  },
  {
    headerText: '평균 완료 건수',
    dataField: 'averageQuantity',
  },
  {
    headerText: '잔여 Capa(%)',
    dataField: 'leftoverCapacityQuantityKr',
  },
];

const addDay1to31 = new Array(31).fill(0).map((ele, idx) => {
  return {
    headerText: `${idx + 1}일`,
    width: 50,
    dataField: `day${idx + 1}Quantity`,
  };
});

export const columns = [...defaultColumns, ...addDay1to31];
