import { KeyboardEvent } from 'react';
import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { UserSearchDTO } from 'interface/user';

export const SearchBox = (props: ISearchBoxProps<UserSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">소속조직</label>
          <SelectD
            options={MASTER_OPS?.JOJIK_GROUP}
            value={
              searchObj?.groupSeq
                ? {
                    label: MASTER_OBJ?.['JOJIK_GROUP']?.[searchObj?.groupSeq],
                    value: searchObj?.groupSeq,
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  groupSeq: (option as OptionItem)?.value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">소속파트너</label>
          <SelectD
            options={MASTER_OPS?.SELLER_AUTH}
            value={
              searchObj?.partnerSeq
                ? {
                    label: MASTER_OBJ?.['SELLER_AUTH']?.[searchObj?.partnerSeq],
                    value: searchObj?.partnerSeq,
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  partnerSeq: (option as OptionItem)?.value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">소속인프라</label>
          <SelectD
            options={MASTER_OPS?.INFRA}
            value={
              searchObj?.infraSeq
                ? {
                    label: MASTER_OBJ?.['INFRA']?.[searchObj?.infraSeq],
                    value: searchObj?.infraSeq,
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  infraSeq: (option as OptionItem)?.value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">소속창고</label>
          <SelectD
            options={MASTER_OPS?.CENTER_AUTH}
            value={
              searchObj?.centerCode
                ? {
                    label: MASTER_OBJ?.['CENTER_AUTH']?.[searchObj?.centerCode],
                    value: searchObj?.centerCode,
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  centerCode: (option as OptionItem)?.value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">소속판매채널</label>
          <SelectD
            options={MASTER_OPS?.SALES_CHANNEL}
            value={
              searchObj?.salesChannelSeq
                ? {
                    label: MASTER_OBJ?.['SALES_CHANNEL']?.[searchObj?.salesChannelSeq],
                    value: searchObj?.salesChannelSeq,
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  salesChannelSeq: (option as OptionItem)?.value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">이름</label>
          <InputD
            value={searchObj?.userName || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                userName: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">아이디</label>
          <InputD
            value={searchObj?.userId || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                userId: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">이메일</label>
          <InputD
            value={searchObj?.email || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                email: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">연락처</label>
          <InputD
            value={searchObj?.phoneNumber || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                phoneNumber: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">사용자 유형</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.USER_CODE}
            value={searchObj?.userCode?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.['USER_CODE']?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  userCode: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">사용여부</label>
          <SelectD
            options={MASTER_OPS?.USE_FLAG}
            value={
              searchObj?.useFlag === false || searchObj?.useFlag
                ? {
                    value: searchObj?.useFlag,
                    label: MASTER_OBJ?.['USE_FLAG']?.[searchObj?.useFlag + ''],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  useFlag: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
