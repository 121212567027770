import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { LocationInOutSearchDTO } from 'interface/warehouse';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { httpClient } from 'common/http-client/axiosConfig';

export const SearchBox = (props: ISearchBoxProps<LocationInOutSearchDTO>) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  const [locationCodeList, setLocationCodeList] = useState<OptionItem[]>();
  useEffect(() => {
    if (searchObj?.centerCode) {
      fetchLocationCode(searchObj?.centerCode);
    } else {
      setLocationCodeList(null);
    }
  }, [searchObj?.centerCode]);

  const fetchLocationCode = async (centerCode) => {
    const rs = await httpClient.get(`/warehouse/${centerCode}/location`);
    if (rs?.status === 200) {
      const result = rs.data.splice(1);
      const temp = ['DOCK', 'CROSS'];
      result.forEach((row) => {
        row.forEach((ele) => {
          if (ele !== '0') {
            temp.push(ele.slice(0, -1) + '1');
            temp.push(ele.slice(0, -1) + '2');
            temp.push(ele.slice(0, -1) + '3');
            temp.push(ele.slice(0, -1) + '4');
            temp.push(ele.slice(0, -1) + '5');
          }
        });
      });

      setLocationCodeList(
        [...new Set(temp)].map((ele) => {
          return {
            label: ele,
            value: ele,
          };
        }),
      );
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">날짜</label>
          <FlatpickrD
            options={{ mode: 'single' }}
            value={searchObj?.inOutDate}
            onReset={() => {
              setSearchObj({
                ...searchObj,
                inOutDate: null,
              });
            }}
            onChange={(value) => {
              setSearchObj({
                ...searchObj,
                inOutDate: returnDateyyyymmdd(value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                  }
                : null
            }
            options={masterOptions?.CENTER}
            onChange={({ value }) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  centerCode: value,
                  locationCode: null,
                };
              });
            }}
          />
        </Col>
        {searchObj?.centerCode ? (
          <Col>
            <label className="col-form-label">로케이션</label>
            <SelectD
              options={locationCodeList}
              value={searchObj?.locationCode ? { label: searchObj?.locationCode, value: searchObj?.locationCode } : null}
              onChange={(option) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    locationCode: (option as OptionItem)?.value,
                  };
                });
              }}
            />
          </Col>
        ) : (
          <Col>
            <label className="col-form-label">로케이션</label>
            <SelectD value={''} placeholder={'창고를 선택하세요'} isDisabled={true} />
          </Col>
        )}
        <Col>
          <label className="col-form-label">판매사</label>
          <div>
            <SelectD
              value={
                searchObj?.partnerSeq
                  ? {
                      label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER),
                      value: searchObj?.partnerSeq,
                    }
                  : null
              }
              options={masterOptions?.SELLER}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: (option as OptionItem).value,
                });
              }}
            />
          </div>
        </Col>
        <Col>
          <label className="col-form-label">제품</label>
          <InputD
            value={searchObj?.model || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                model: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">바코드</label>
          <InputD
            value={searchObj?.barcode || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                barcode: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'right' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
