import { justServer } from 'common/util/isServer';
import { getLanguageInBrowser, getLanguangeInlocalStorage, saveLanguangeInlocalStorage } from 'common/util/lang';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ILOCALE, setLocale } from 'redux/services/localeSlice';

export function useEnvironment() {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);

  const [classNameForWrapper, setClassNameForWrapper] = useState<string>();

  useEffect(() => {
    let str = '';
    if (justServer('lfs-temp.')) str += 'temp';
    if (isMobile) str += ' mobileAccess';
    setClassNameForWrapper(str);
    settingLanguage();
  }, [isMobile]);

  const settingLanguage = () => {
    const localStorageLang = getLanguangeInlocalStorage();
    const browserLang = getLanguageInBrowser().split('-')[0];
    // localStorage가 우선
    if (localStorageLang?.trim() === 'undefined' || !localStorageLang) {
      dispatch(setLocale(browserLang));
      saveLanguangeInlocalStorage(browserLang as ILOCALE);
    } else {
      dispatch(setLocale(localStorageLang));
    }
  };

  return {
    classNameForWrapper,
    setIsMobile,
  };
}
