import { useState } from 'react';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { DIVIDE_INVOICE, OMS_INVOICE_DETAIL, OMS_ORDER_CHANGE_NOTE, SCHEDULE_CHANGE_LIST } from 'envVar';
import Presenter from './presenter';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { httpClient } from 'common/http-client/axiosConfig';
import { setLoading } from 'redux/services/menuSlice';

export const defaultSearchObj = {
  fromDeliveryDate: returnDateyyyymmdd(new Date()),
  toDeliveryDate: returnDateyyyymmdd(new Date()),
  partnerSeq: [],
  infraSeq: [],
  centerCode: [],
  userId: [],
  region: [],
  customer: null,
  viewPolyFlag: true,
  //
  promiseFlag: 'fixed',
};

const Container = (props) => {
  const dispatch = useDispatch();
  //옵션
  const { MASTER_OBJ } = useSelector((state) => state.menu);
  const [searchObj, setSearchObj] = useState();
  const [detailObj, setDetailObj] = useState({
    visible: false,
    ele: null,
    data: null,
    messageFn: (data) => sendMessage(data),
    changeScheduleFn: (data) => scheduleChange(data),
    changeNoteFn: (data) => putChangeNote(data),
  });

  const [divideOrderObj, setDivideOrderObj] = useState({
    visible: false,
    list: [],
    content: {
      deliveryDateFlag: false,
      deliveryDate: null,
      userId: null,
      centerCode: null,
      assistUserId: null,
      internUserId: null,
      confirmFlag: false,
    },
    divideFn: (data) => divideOrder(data),
  });

  const scheduleChange = async (data) => {
    dispatch(setLoading('PUT'));
    return await httpClient.post(DIVIDE_INVOICE, data).then((rs) => {
      dispatch(setLoading(null));
      return rs;
    });
  };

  const divideOrder = async (obj) => {
    const data = {
      ...obj.content,
      invoices: obj.list.map((ele) => {
        return {
          invoiceSeq: ele.invoiceSeq,
          callCenterNote: ele.callCenterNote,
        };
      }),
    };
    dispatch(setLoading('PUT'));
    return await httpClient.post(SCHEDULE_CHANGE_LIST, data).then((rs) => {
      dispatch(setLoading(null));
      return rs;
    });
  };

  const putChangeNote = async (data) => {
    await httpClient.put(OMS_ORDER_CHANGE_NOTE, data).then((rs) => {
      if (rs?.status === 200) {
        alert(rs.data.code);
      } else {
        alert('콜센터 요청사항에 실패하였습니다');
      }
    });
  };

  const sendMessage = async (data) => {
    await httpClient.post(`/order/message/schedule/send`, data).then((rs) => {
      if (rs?.status === 200) {
        alert('문자 전송에 성공하였습니다!');
      }
    });
  };

  const paramingSearchObjToUrl = (url, searchObj) => {
    if (searchObj?.fromDeliveryDate && searchObj?.toDeliveryDate) url += `&fromDeliveryDate=${searchObj?.fromDeliveryDate}&toDeliveryDate=${searchObj?.toDeliveryDate}`; // deliveryDate는 필수임
    if (searchObj?.infraSeq?.length > 0) {
      searchObj?.infraSeq?.forEach((ele) => {
        url += `&infraSeq=${ele.value}`;
      });
    }
    if (searchObj?.partnerSeq?.length > 0) {
      searchObj?.partnerSeq?.forEach((ele) => {
        url += `&partnerSeq=${ele.value}`;
      });
    }
    if (searchObj?.centerCode?.length > 0) {
      searchObj?.centerCode?.forEach((ele) => {
        url += `&centerCode=${ele.value}`;
      });
    }
    if (searchObj?.userId?.length > 0) {
      searchObj?.userId?.forEach((ele) => {
        url += `&userId=${ele.value}`;
      });
    }
    if (searchObj?.region?.length > 0) {
      searchObj?.region?.forEach((ele) => {
        url += `&region=${ele.value}`;
      });
    }
    if (searchObj?.modelStockSeq) url += `&modelStockSeq=${searchObj?.modelStockSeq}`;
    if (searchObj?.customerName) url += `&customerName=${searchObj?.customerName}`;
    if (searchObj?.promiseFlag !== 'null') url += `&promiseFlag=${searchObj?.promiseFlag === 'fixed' ? 'true' : 'false'}`;
    return url;
  };

  const fetchRawData = async (searchObj, bounds) => {
    let url = `/order/schedule/region/map?`;
    // 특정기사 선택시 기간선택이 아닐시
    if (searchObj?.userId?.length > 0 && searchObj?.fromDeliveryDate === searchObj?.toDeliveryDate) {
      // bounds 검색 아님
    } else if (bounds?._max && bounds?._min) {
      url += `&latitude=${bounds?._max?._lat}&latitude=${bounds?._min?._lat}`;
      url += `&longitude=${bounds?._max?._lng}&longitude=${bounds?._min?._lng}`;
    }
    url = paramingSearchObjToUrl(url, searchObj);

    dispatch(setLoading('GET'));
    return await httpClient.get(url).then((rs) => {
      dispatch(setLoading(null));
      if (rs?.status === 200) {
        rs.data.forEach((row) => {
          row.userIdKr = MASTER_OBJ?.DRIVER_WHOLE?.[row.userId];
          row.feeTypeKr = MASTER_OBJ?.FEE_TYPE?.[row.feeType];
          row.modelGroupKr = MASTER_OBJ?.MODEL_GROUP?.[row.modelGroup];
          row.memberCountKr = row.memberCount === 2 ? '2인 1팀' : '1인 1팀';
          row.deliveryDurationKr = row.deliveryDuration ? '주문일 + ' + row.deliveryDuration : '-';
          // row.up = '⬆';
          // row.down = '⬇';
        });
        return rs.data;
      }
    });
  };

  const fetchDetailData = async (seq) => {
    dispatch(setLoading('GET'));
    return await httpClient
      .get(`${OMS_INVOICE_DETAIL}/${seq}`)
      .then((rs) => {
        if (rs?.status === 200) {
          dispatch(setLoading(null));
          return rs.data;
        }
      })
      .catch((err) => {
        dispatch(setLoading(null));
        return null;
      });
  };

  return (
    <Presenter
      //
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      //
      detailObj={detailObj}
      setDetailObj={setDetailObj}
      divideOrderObj={divideOrderObj}
      setDivideOrderObj={setDivideOrderObj}
      fetchRawData={fetchRawData}
      fetchDetailData={fetchDetailData}
    />
  );
};

export default Container;
