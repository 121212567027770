import { useState, useEffect, ReactNode } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './inventoryTracking';

// options
import { codeMasterOptionReturn, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { setLoading, setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { TopTab } from 'components/template/topTabNew';
import { SEARCH_MODEL } from 'pages/ORDER/1order/orderListTab/_asset/asset';
import { useLocation } from 'react-router-dom';
import { TabDTO } from 'interface/user';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Index = () => {
  const location = useLocation();
  const [tabs, setTabs] = useState<TabDTO[]>();
  const [tabItems, setTabItems] = useState<{ titles?: string[]; containers: ReactNode[] }>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { useableTabs } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [SELLER_WHOLE, INV_TRACK_TYPE, INV_AVAIL_STAT] = await Promise.all([
      partnerListForlabeling({ sellerFlag: true }),
      codeMasterOptionReturn('INV_TRACK_TYPE'),
      codeMasterOptionReturn('INV_AVAIL_STAT'),
    ]);

    dispatch(
      setMasterOptions({
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        INV_TRACK_TYPE,
        INV_AVAIL_STAT,
        SEARCH_MODEL,
        SEARCH_LOCATION: [
          { value: 'locationCodeIn', label: '입고로케이션' },
          { value: 'locationCodeOut', label: '출고로케이션' },
        ],
        SEARCH_PLACE: [
          { value: 'placeIn', label: '입고지' },
          { value: 'placeOut', label: '출고지' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };
  const containerList = [{ container: <Tab1 tabId={'INVENTORYTRACK'} />, tabId: 'INVENTORYTRACK' }];

  useEffect(() => {
    if (tabs) {
      const tabIds = tabs?.map((ele) => ele.tabId);
      const tabLabeling = tabs?.map((ele) => {
        return {
          value: ele.tabId,
          label: ele.tabName,
        };
      });

      setTabItems({
        titles: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => selectlabel(ele.tabId, tabLabeling)),
        containers: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => ele.container),
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (useableTabs) {
      const tabDTO = useableTabs?.[location.pathname] ? [...useableTabs?.[location.pathname]] : [];
      if (tabDTO?.length > 0) {
        tabDTO?.sort((a: TabDTO, b: TabDTO) => a?.sort - b?.sort);
        setTabs(tabDTO);
        initMasterOptions();
      }
    }
  }, [useableTabs]);

  return <>{masterOptions && tabItems && <TopTab titles={tabItems?.titles} containers={tabItems?.containers} />}</>;
};

export default Index;
