import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { CollectSearchDTO } from '../../../../_interface/warehouse';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { numberTestRegExp } from 'common/util/regExp';

export const SearchBox = (props: ISearchBoxProps<CollectSearchDTO>) => {
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  useEffect(() => {}, [searchObj]);

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">자재반납번호</label>
          <InputD
            value={searchObj?.collectSeq || ''}
            onChange={(e) => {
              if (numberTestRegExp(e.target.value)) {
                setSearchObj({
                  ...searchObj,
                  collectSeq: Number(e.target.value),
                });
              } else {
                alert('숫자만 입력가능합니다');
              }
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">기간검색</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_DATE
                    ? {
                        value: selectTypeObj?.SEARCH_DATE,
                        label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_DATE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_DATE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <FlatpickrD
                disabled={!selectTypeObj?.SEARCH_DATE}
                value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                    [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                  });
                }}
                onClose={(option) => {
                  if (option?.length === 1)
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                      [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                    });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">반납창고</label>
          <Row>
            <SelectD
              value={
                searchObj?.centerCode
                  ? {
                      value: searchObj?.centerCode,
                      label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                    }
                  : null
              }
              options={MASTER_OPS?.CENTER_AUTH}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: (option as OptionItem).value,
                });
              }}
            />
          </Row>
        </Col>
        <Col>
          <label className="col-form-label">기사</label>
          <Row>
            <SelectD
              options={MASTER_OPS?.DRIVER_AUTH}
              value={
                searchObj?.userId
                  ? {
                      value: searchObj?.userId,
                      label: MASTER_OBJ?.DRIVER_AUTH?.[searchObj?.userId],
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  userId: (option as OptionItem).value,
                });
              }}
            />
          </Row>
        </Col>
        <Col>
          <label className="col-form-label">진행상태</label>
          <SelectD
            value={
              searchObj?.status
                ? {
                    value: searchObj?.status,
                    label: MASTER_OBJ?.COLLECT_STATUS?.[searchObj?.status],
                  }
                : null
            }
            options={MASTER_OPS?.COLLECT_STATUS}
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                status: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품/제품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[searchObj?.partnerSeq],
                  }
                : null
            }
            options={MASTER_OPS?.SELLER_SELLER_AUTH}
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">바코드</label>
          <InputD
            value={searchObj?.barcode || ''}
            onChange={(e) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  barcode: e.target.value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">외부SKU</label>
          <InputD
            value={searchObj?.skuNumber || ''}
            onChange={(e) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  skuNumber: e.target.value,
                };
              });
            }}
          />
        </Col>
        <Col />
        <Col />
        <Col style={{ textAlign: 'right' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
