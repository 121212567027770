import { KeyboardEvent } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { InventoryRefurbishSearchDTO } from 'interface/warehouse';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = (props: ISearchBoxProps<InventoryRefurbishSearchDTO>) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">창고*</label>
          <SelectD
            options={masterOptions?.CENTER}
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                  }
                : null
            }
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                centerCode: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            options={masterOptions?.SELLER}
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER),
                  }
                : null
            }
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">변경일</label>
          <FlatpickrD
            value={searchObj?.modifyDate}
            options={{ mode: 'single' }}
            onReset={() => {
              setSearchObj({
                ...searchObj,
                modifyDate: null,
              });
            }}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                modifyDate: returnDateyyyymmdd(option[0]),
              });
            }}
          />{' '}
        </Col>
        <Col>
          <label className="col-form-label">확정상태</label>
          <SelectD
            options={masterOptions?.INV_REFUR_STAT}
            value={
              searchObj?.status
                ? {
                    value: searchObj?.status,
                    label: selectlabel(searchObj?.status, masterOptions?.INV_REFUR_STAT),
                  }
                : null
            }
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                status: value,
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
