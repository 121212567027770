import { Input } from 'reactstrap';
// redux
import { useEffect, useState } from 'react';

import searchImg from './img/search.png';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { fetchNewMasterOptions } from 'redux/services/menuSlice';
import { codeMasterOptionReturn } from 'common/master/codeMasterOptionReturn';
import CommonModalNew from 'components/modal/commonModalNew';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';
import { SalesChannelSearchDTO } from 'interface/order';

export const defaultCategorizationObj = {
  visible: true,
};

export const defaultModalInModalObj = {
  visible: false,
  codeWhat: 0,
  from: null,
};

export interface IModalObj {
  visible?: boolean;
  type?: string;
  data?: any;
  searchObj?: SalesChannelSearchDTO;
  fetchFn?: <T>(T) => void;
}

const SalesModal = ({ modalObj, setModalObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [allChannels, setAllChannels] = useState<{ [key: string]: any[] }>();
  const [currentTab, setCurrentTab] = useState(0);
  const hasTabs = [
    { title: '판매조직', code: 'TYPE' },
    { title: '판매채널', code: 'CHANNEL' },
  ];

  const [list, setList] = useState<any>();
  const [crudObj, setCrudObj] = useState({
    TYPE: null,
    CHANNEL: null,
    MODAL_IN_MODAL: null,
  });
  const [searchInput, setSearchInput] = useState({
    TYPE: null,
    CHANNEL: null,
    MODAL_IN_MODAL: null,
  });
  const [modalInModalObj, setModalInModalObj] = useState(defaultModalInModalObj);

  useEffect(() => {
    fetchInitData();
  }, []);

  const fetchInitData = async () => {
    const rs = await serviceStore.orderAction('salesChannel', 'GET', null);
    if (rs.status === 200) {
      setAllChannels({
        TYPE: MASTER_OPS?.SALES_CHANNEL_TYPE?.map((ele) => {
          return {
            type: ele.value,
            typeKr: ele.label,
            codeSeq: ele.codeSeq,
          };
        }),
        CHANNEL: rs.data,
      });
    }
  };

  const searchClick = async (masterCode, isInModal = false) => {
    const filtered = allChannels?.[masterCode].filter((ele) =>
      masterCode === 'TYPE' ? ele.typeKr?.includes(searchInput[masterCode] || '') : ele.salesChannelName?.includes(searchInput[masterCode] || ''),
    );
    setList((prev) => {
      return {
        ...prev,
        [isInModal ? 'MODAL_IN_MODAL' : masterCode]: filtered,
      };
    });
  };

  const handleCRUD = async (crud) => {
    const data = crudObj[hasTabs[currentTab]?.code];
    if (hasTabs[currentTab]?.code === 'TYPE') {
      const obj = {
        crud: crud,
        codeGroup: 'SALES_CHANNEL',
        codes: [
          {
            locale: 'ko',
            code: data.type,
            codeName: data.typeKr,
            codeSeq: data.codeSeq,
          },
        ],
      };
      const rs = await serviceStore.masterAction('code/web', 'POST', null, obj);
      if (rs.status === 200) {
        alert(`판매조직이 ${crud === 'C' ? '등록' : crud === 'U' ? '수정' : '삭제'} 되었습니다`);
        const value = await codeMasterOptionReturn('SALES_CHANNEL');
        dispatch(fetchNewMasterOptions({ key: 'SALES_CHANNEL', value }));
        fetchInitData();
        if (modalObj?.fetchFn && modalObj?.searchObj) modalObj?.fetchFn(modalObj?.searchObj);
      }
    } else {
      const obj = {
        [crud === 'C' ? 'add' : crud === 'U' ? 'update' : 'delete']: data,
      };
      const rs = await serviceStore.orderAction('salesChannel', 'POST', null, obj);
      if (rs.status === 200) {
        alert(`판매채널이 ${crud === 'C' ? '등록' : crud === 'U' ? '수정' : '삭제'} 되었습니다`);
        if (modalObj?.fetchFn && modalObj?.searchObj) modalObj?.fetchFn(modalObj?.searchObj);
      }
    }
  };

  return (
    <>
      <CommonModalNew
        title={'판매채널 관리'}
        style={{ width: 600 }}
        visible={modalObj?.visible}
        setVisible={() => {
          setModalObj(null);
        }}
        children={
          <>
            {modalInModalObj?.visible && (
              <CommonModalNew
                title={'판매조직 조회'}
                visible={modalInModalObj?.visible}
                setVisible={() => {
                  setModalInModalObj(null);
                }}
                style={{ width: 550 }}
                children={
                  <>
                    <div style={{ display: 'flex', margin: '20px 0' }}>
                      <span style={{ fontWeight: 700, width: 60 }}>판매채널명</span>
                      <Input
                        style={{ width: '55%', margin: '0 25px' }}
                        value={searchInput?.MODAL_IN_MODAL || ''}
                        onChange={(e) => {
                          setSearchInput({
                            ...searchInput,
                            MODAL_IN_MODAL: e.target.value,
                          });
                        }}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          searchClick('TYPE', true);
                        }}
                        style={{ marginLeft: 10, marginTop: 0 }}
                        className="btn btn-search ssm"
                      >
                        조회
                      </button>
                    </div>
                    <table className="border-table" style={{ minHeight: 300 }}>
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th colSpan={4}>판매조직코드</th>
                          <th colSpan={4}>판매조직명</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!list?.['MODAL_IN_MODAL'] && (
                          <tr>
                            <td colSpan={9}>"판매조직 명"으로 조회해주세요</td>
                          </tr>
                        )}
                        {list?.['MODAL_IN_MODAL']?.map((row, idx) => {
                          return (
                            <tr
                              key={`modelInModel_${idx}`}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                const obj = { ...crudObj };
                                obj['CHANNEL'] = {
                                  type: null,
                                  typeKr: null,
                                };
                                if (row.type) obj['CHANNEL']['type'] = row.type;
                                if (row.typeKr) obj['CHANNEL']['typeKr'] = row.typeKr;
                                setCrudObj(obj);
                                setModalInModalObj({
                                  ...defaultModalInModalObj,
                                });
                              }}
                            >
                              <td>{idx + 1}</td>
                              <td colSpan={4}>{row.type}</td>
                              <td colSpan={4}>{row.typeKr}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                }
              />
            )}
            <div style={{ minHeight: 600 }}>
              <div className="div-top-tab">
                {hasTabs.map((tab, index) => {
                  return (
                    <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                      <li
                        onClick={() => {
                          setCurrentTab(index);
                        }}
                      >
                        {tab?.title}
                      </li>
                    </div>
                  );
                })}
              </div>
              <div style={{ zIndex: currentTab === 0 ? 13 : 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ fontWeight: 700 }}>판매조직명</span>
                  <Input
                    style={{ width: '70%' }}
                    value={searchInput?.TYPE || ''}
                    onChange={(e) => {
                      setSearchInput({
                        ...searchInput,
                        TYPE: e.target.value,
                      });
                    }}
                  />
                  <button onClick={() => searchClick('TYPE')} style={{ marginLeft: 10, marginTop: 0 }} className="btn btn-search ssm">
                    조회
                  </button>
                </div>
                <table className="border-table" style={{ minHeight: 300 }}>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th colSpan={4}>판매조직코드</th>
                      <th colSpan={4}> 판매조직명</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!list?.['TYPE'] && (
                      <tr>
                        <td colSpan={9}>"판매조직명"으로 조회해주세요</td>
                      </tr>
                    )}
                    {list?.['TYPE']?.map((row, idx) => {
                      return (
                        <tr
                          key={`type_list_${idx}`}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setCrudObj({
                              ...crudObj,
                              TYPE: { ...row },
                            });
                          }}
                        >
                          <td>{idx + 1}</td>
                          <td colSpan={4}>{row.type}</td>
                          <td colSpan={4}>{row.typeKr}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div style={{ display: 'flex' }}>
                  <div>
                    <label className="col-form-label">판매조직코드</label>
                    <Input disabled value={crudObj?.TYPE?.type || ''} />
                  </div>
                  <div>
                    <label className="col-form-label">판매조직명</label>
                    <Input
                      value={crudObj?.TYPE?.typeKr || ''}
                      onChange={(e) => {
                        const obj = {
                          ...crudObj,
                          TYPE: {
                            ...crudObj['TYPE'],
                            typeKr: e.target.value,
                          },
                        };
                        setCrudObj(obj);
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <button onClick={() => handleCRUD('C')} style={{ marginLeft: 10 }} className="btn btn-secondary sssm">
                      등록
                    </button>
                    <button onClick={() => handleCRUD('U')} style={{ marginLeft: 10 }} className="btn btn-secondary sssm">
                      수정
                    </button>
                    <button
                      onClick={() => {
                        if (window.confirm('정말로 삭제하시겠습니까?')) {
                          handleCRUD('D');
                        }
                      }}
                      style={{ marginLeft: 10 }}
                      className="btn btn-red sssm"
                    >
                      삭제
                    </button>
                  </div>
                </div>
              </div>
              <div style={{ zIndex: currentTab === 1 ? 13 : 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ fontWeight: 700 }}>판매채널명</span>
                  <Input
                    style={{ width: '70%' }}
                    value={searchInput?.CHANNEL || ''}
                    onChange={(e) => {
                      setSearchInput({
                        ...searchInput,
                        CHANNEL: e.target.value,
                      });
                    }}
                  />
                  <button onClick={() => searchClick('CHANNEL')} style={{ marginLeft: 10, marginTop: 0 }} className="btn btn-search ssm">
                    조회
                  </button>
                </div>

                <table className="border-table" style={{ minHeight: 300 }}>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th colSpan={4}>판매조직명</th>
                      <th colSpan={4}>판매채널코드</th>
                      <th colSpan={4}>판매채널명</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!list?.['CHANNEL'] && (
                      <tr>
                        <td colSpan={13}>"판매채널명"으로 조회해주세요</td>
                      </tr>
                    )}
                    {list?.['CHANNEL']?.map((row, idx) => {
                      return (
                        <tr
                          key={`modelGrp2_${idx}`}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setCrudObj({
                              ...crudObj,
                              CHANNEL: { ...row },
                            });
                          }}
                        >
                          <td>{idx + 1}</td>
                          <td colSpan={4}>{MASTER_OBJ?.SALES_CHANNEL?.[row.type]}</td>
                          <td colSpan={4}>{row.salesChannelSeq}</td>
                          <td colSpan={4}>{row.salesChannelName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div style={{ display: 'flex' }}>
                  <div>
                    <div style={{ display: 'flex', width: 200 }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginRight: 10,
                        }}
                      >
                        <label className="col-form-label">판매조직코드</label>
                        <Input disabled value={crudObj?.CHANNEL?.type || ''} />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className="col-form-label">판매조직명</label>
                        <Input disabled value={MASTER_OBJ?.SALES_CHANNEL?.[crudObj?.CHANNEL?.type] || ''} />
                      </div>
                    </div>
                    <div style={{ display: 'flex', width: 200 }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginRight: 10,
                        }}
                      >
                        <label className="col-form-label">판매채널코드</label>
                        <Input disabled value={crudObj?.CHANNEL?.salesChannelSeq || ''} />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className="col-form-label">판매채널명</label>
                        <Input
                          value={crudObj?.CHANNEL?.salesChannelName || ''}
                          onChange={(e) => {
                            const obj = {
                              ...crudObj,
                              CHANNEL: {
                                ...crudObj['CHANNEL'],
                                salesChannelName: e.target.value,
                              },
                            };
                            setCrudObj(obj);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'flex-end', position: 'relative' }}>
                    <img
                      src={searchImg}
                      alt="판매조직조회"
                      style={{
                        width: 24,
                        top: 20,
                        left: 8,
                        position: 'absolute',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setModalInModalObj(null);
                      }}
                    />
                    <button onClick={() => handleCRUD('C')} style={{ marginLeft: 10 }} className="btn btn-secondary sssm">
                      등록
                    </button>
                    <button onClick={() => handleCRUD('U')} style={{ marginLeft: 10 }} className="btn btn-secondary sssm">
                      수정
                    </button>
                    <button
                      onClick={() => {
                        if (window.confirm('정말로 삭제하시겠습니까?')) {
                          handleCRUD('D');
                        }
                      }}
                      style={{ marginLeft: 10 }}
                      className="btn btn-red sssm"
                    >
                      삭제
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default SalesModal;
