import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { LocationSelectListDTO, LocationSelectSearchDTO } from '../../../_interface/warehouse';
import useGridButton from 'hooks/grid/useGridButton';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { ILocateObj, LocatePoup } from './component/locatePopup';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { ISerialObj, SerialsPopup } from 'pages/IPGO/2trunk/gansunIpgoTab/gansunIpgo/component/serialPopup';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();
  const bottomGridRef = useRef<IPagingGrid>();
  const [locateObj, setLocateObj] = useState<ILocateObj>();

  const labellingKr = (data: PagingListDTO<LocationSelectListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: row.partnerSeqs?.map((ele) => MASTER_OBJ?.SELLER_WHOLE?.[ele])?.join(','),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        statusKr: MASTER_OBJ?.LO_STATUS?.[row.status],
        confirmTypeKr: MASTER_OBJ?.LO_SELECT_TYPE?.[row.confirmType],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };
  const fetchPaging = async (searchObj: LocationSelectSearchDTO) => {
    dispatch(setLoading('GET'));
    setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore.warehouseAction(`location/select/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<LocationSelectListDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction(`location/select/list`, 'GET', searchObj, null))?.data as LocationSelectListDTO[],
      },
      true,
    );

    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '로케이션지정내역' });

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'LOCATIONSELECTEXCEL') {
      downloadExcel('main');
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const confirmLocationLocate = async (items) => {
    setLocateObj({
      visible: true,
      event: items[0].e,
      item: items,
      searchObj: items[0].searchObj,
      fetchFn: (searchObj: LocationSelectSearchDTO) => wrappedFetchList(searchObj),
      fetchDetailFn: (e: IGrid.ButtonClickEvent) => popupDetail(e),
    });
  };
  const popupDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));

    const gridButtonhandler = (e) => {
      const id = e.target.id;
      const items = bottomGridRef.current.getCheckedRowItemsAll();
      if (items?.length > 0) {
        if (id === 'LOCATIONSELECTFINISH') confirmLocationLocate(items);
      } else {
        alert('선택된 항목이 존재하지 않습니다.');
      }
    };
    const buttons = printFunctionToBtns(
      gridFunctions?.filter((ele) => !ele?.functionId?.includes('SHOW')),
      gridButtonhandler,
    );
    const locationSelectSeq = e.item.locationSelectSeq;
    const data = (await serviceStore.warehouseAction('location/select/detail', 'GET', { locationSelectSeq }))?.data;
    if (data) {
      const rows = data?.map((row) => {
        return {
          ...row,
          e: JSON.stringify(e),
          searchObj: e.item.searchObj,
          statusKr: MASTER_OBJ?.LO_STATUS?.[row?.status],
          modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
          partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],
          confirmTypeKr: MASTER_OBJ?.LO_SELECT_TYPE?.[row?.confirmType],

          serialNumber: row.serialNumber ? JSON.stringify(row.serialNumber) : null,
        };
      });
      setBottomSheetObj({
        visible: true,
        hasTabs: [
          {
            title: '로케이션 지정내역 상세',
            gridRef: bottomGridRef,
            columns: detailColumns,
            rows,
            buttons,
            options: {
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              editable: true,
            },
          },
        ],
      });
    }

    dispatch(setLoading(null));
  };

  const [serialsPopupObj, setSerialsPopupObj] = useState<ISerialObj>();
  const popupSerials = (event: IGrid.ButtonClickEvent) => {
    const barcode = event.item.barcode;
    const model = event.item.model;
    console.log(event);
    const data = event.item.serialNumber;

    if (data) {
      setSerialsPopupObj({
        visible: true,
        title: model,
        bottomtitle: barcode,
        data,
      });
    } else {
      alert('수집된 시리얼 번호가 없습니다!');
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupDetail,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.INVENTORY_LOCATION_LIST_GRID_SHOWDETAIL;
        },
      },
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '입고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '입고예정일',
      dataField: 'promiseDate',
    },
    {
      headerText: '입출고구분',
      dataField: 'confirmTypeKr',
    },
    {
      headerText: '작업번호',
      dataField: 'unloadSeq',
    },
    {
      headerText: '예정 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
        },

        {
          headerText: '불용',
          dataField: 'defectedQuantity',
        },
      ],
    },
    {
      headerText: '로케이션지정 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'confirmAvailableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'confirmDefectedQuantity',
        },
      ],
    },
    {
      headerText: '차이',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
        },

        {
          headerText: '불용',
          dataField: 'defectedDiff',
        },
      ],
    },
    {
      headerText: '로케이션지정<br />상태',
      dataField: 'statusKr',
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '입출고구분',
      dataField: 'confirmTypeKr',
    },
    {
      headerText: '작업번호',
      dataField: 'unloadSeq',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },

        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
      ],
    },
    {
      headerText: '예정 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
        },

        {
          headerText: '불용',
          dataField: 'defectedQuantity',
        },
      ],
    },
    {
      headerText: '로케이션지정 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'confirmAvailableQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: popupSerials,
          },
        },

        {
          headerText: '불용',
          dataField: 'confirmDefectedQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: popupSerials,
          },
        },
      ],
    },
    {
      headerText: '로케이션 적재위치',
      dataField: 'locationCode',
    },
    {
      headerText: '위치지정완료자',
      dataField: 'confirmId',
    },
    {
      headerText: '위치지정완료일',
      dataField: 'confirmDatetime',
    },
    {
      headerText: '로케이션지정상태',
      dataField: 'statusKr',
    },
  ];

  return (
    <div className="page-content">
      {serialsPopupObj?.visible && <SerialsPopup serialsPopupObj={serialsPopupObj} setSerialsPopupObj={setSerialsPopupObj} />}
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      {locateObj?.visible && <LocatePoup locateObj={locateObj} setLocateObj={setLocateObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            fixedColumnCount: 1,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns.filter((_, idx) => idx > 1)} />
      </div>
    </div>
  );
};

export default Index;
