import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { ParcelInvoiceNumberSearchDTO } from '../../../_interface/parcel';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { resetAllSelections } from 'common/util/searchBox';

export const SearchBox = (props: ISearchBoxProps<ParcelInvoiceNumberSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    fetchList(searchObj);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <label className="col-form-label">기간검색</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_DATE
                      ? {
                          value: selectTypeObj?.SEARCH_DATE,
                          label: MASTER_OBJ?.SEARCH_DATE[selectTypeObj?.SEARCH_DATE],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_DATE}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_DATE: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <FlatpickrD
                  disabled={!selectTypeObj?.SEARCH_DATE}
                  onClose={(option) => {
                    if (option?.length === 1)
                      setSearchObj({
                        ...searchObj,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                        [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                      });
                  }}
                  value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                      [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">판매사 (포워더)</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
              value={searchObj?.partnerSeq?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.SELLER_FORWARDER_AUTH?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: (option as OptionItem[])?.map((ele) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">진행상태</label>
            <SelectD
              isMulti
              value={searchObj?.status?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.PARCEL_INVOICE_NUMBER_STATUS?.[ele],
                };
              })}
              options={MASTER_OPS?.PARCEL_INVOICE_NUMBER_STATUS}
              onChange={(options) => {
                setSearchObj({
                  ...searchObj,
                  status: (options as OptionItem[]).map((ele) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <button className="btn btn-search" type="submit">
              조회
            </button>
          </Col>
          <Col />
          <Col />
        </Row>
      </Form>
    </>
  );
};
