import { ILOCALE } from 'redux/services/localeSlice';

export const getLanguageInBrowser = () => {
  return navigator.language;
};

export const getLanguangeInlocalStorage = () => {
  const lang = localStorage.getItem('locale');
  return lang;
};

export const saveLanguangeInlocalStorage = (lang: ILOCALE) => {
  localStorage.setItem('locale', lang);
};
