import { useState, useEffect } from 'react';
import { defaultUnitedPageWithTopTab, SINGLE_EXCEL, UnitedPageWithTopTab } from 'components/template/topTab';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Container1 from './excelSinglePage/container.js';

// options
import { centerListForlabeling, codeMasterOptionReturn, InfraOptionReturn, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';

const Index = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    initMasterOptions();
  }, []);

  useEffect(() => {
    if (masterOptions) {
      const unitedName = SINGLE_EXCEL;
      const currentTab = JSON.parse(localStorage.getItem(unitedName)) ? JSON.parse(localStorage.getItem(unitedName)) : 0;
      setTabObj({
        unitedName: unitedName,
        currentTab: currentTab,
        titles: ['통합 엑셀 다운로드'],
        containers: [<Container1 />],
      });
    }
  }, [masterOptions]);

  const initMasterOptions = async () => {
    const [
      SELLER,
      CENTER,
      CENTER_LABEL,
      MODEL_GROUP,
      LINEHAUL_TYPE,
      LINE_STAT,
      ORDER_HOLD_TYPE,
      INVA_TYPE,
      LOADING_STAT,
      MODEL_LARGE,
      LOADING_OUT_STAT,
      CAR_TYPE,
      INFRA,
      REMOVE_STATUS,
      REMOVE_REASON,
      SUPPLIER,
      NETWORK,
      BRAND,
      PO_STATUS,
    ] = await Promise.all([
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling({ selectFlag: false }),
      centerListForlabeling(),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('LINEHAUL_TYPE'),
      codeMasterOptionReturn('LINE_STAT'),
      codeMasterOptionReturn('ORDER_HOLD_TYPE'),
      codeMasterOptionReturn('INVA_TYPE'),
      codeMasterOptionReturn('LOADING_STAT'),
      codeMasterOptionReturn('MODEL_GRP_1'),
      codeMasterOptionReturn('LOADING_OUT_STAT'),
      codeMasterOptionReturn('CAR_TYPE'),
      InfraOptionReturn(),
      codeMasterOptionReturn('REMOVE_STATUS'),
      codeMasterOptionReturn('REMOVE_REASON'),
      partnerListForlabeling({ supplierFlag: true }),
      partnerListForlabeling({ networkFlag: true }),
      codeMasterOptionReturn('BRAND'),
      codeMasterOptionReturn('PO_STATUS'),
    ]);
    dispatch(
      setMasterOptions({
        SELLER: SELLER?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_LABEL: CENTER_LABEL?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        MODEL_GROUP,
        LINEHAUL_TYPE,
        LINE_STAT,
        ORDER_HOLD_TYPE,
        INVA_TYPE,
        LOADING_STAT,
        MODEL_LARGE,
        LOADING_OUT_STAT,
        CAR_TYPE,
        INFRA,
        REMOVE_STATUS,
        REMOVE_REASON,
        SUPPLIER: SUPPLIER?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        NETWORK: NETWORK?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        BRAND,
        PO_STATUS,
        PURCHASESEQ_TYPE: [
          { value: null, label: '전체' },
          { value: 'purchaseSeq', label: '발주번호' },
          { value: 'externalPurchaseNumber', label: '외부발주번호' },
        ],
        DESC_DIFF: [
          { value: '박스훼손', label: 'DEFECT' },
          { value: '수량부족', label: 'SHORT' },
          { value: '사유없음', label: 'NONE' },
          { value: '부분입고', label: 'PARTIAL' },
        ],
      }),
    );
  };

  return <>{masterOptions && tabObj?.titles.length > 0 && <UnitedPageWithTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
