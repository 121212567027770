import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import styled from 'styled-components';
import { OrderReceiptDTO } from '../../../_interface/order';
import { stringToThousandComma } from 'common/util/counting';
import ReactToPrint from 'react-to-print';

const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
  margin: 40px 5px 5px 20px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const WrapperCol = styled.div`
  width: 50%;
  padding: 0 30px;
`;

const ReceiptRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ReceiptRowTitle = styled.div`
  display: flex;
  width: 100px;
`;

export interface IReceipt {
  visible?: boolean;
  data?: OrderReceiptDTO[];
  fetchFn?: (data) => {};
}

export const ReceiptPopup = ({ receiptObj, setReceiptObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [receiptArr, setReceiptArr] = useState<OrderReceiptDTO[]>();
  const wrapperRef = useRef();

  useEffect(() => {
    setReceiptArr(receiptObj?.data);
  }, []);

  return (
    <CommonModalNew
      visible={receiptObj?.visible}
      setVisible={() => {
        setReceiptObj({
          visible: false,
          data: null,
        });
      }}
      title={
        <>
          <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
        </>
      }
      children={
        <div className="receiptWrapper" ref={wrapperRef}>
          {receiptArr?.map((receipt, idx) => {
            return (
              <div id={`receipt_${idx}`} style={{ border: '1px solid rgb(227 227 227)', padding: 30, marginBottom: 30, width: 430 }} key={`download_pdf_${idx}`} className="break-for-onePage-print">
                <Title style={{ textAlign: 'center', marginTop: 0 }}>영수증</Title>
                <hr />
                <Title style={{ marginTop: 0 }}>주문정보</Title>
                <Wrapper>
                  <WrapperCol style={{ width: '100%' }}>
                    <ReceiptRow>
                      <ReceiptRowTitle>영수증번호</ReceiptRowTitle>
                      <div>{receipt?.orderReceiptSeq}</div>
                    </ReceiptRow>
                    <ReceiptRow>
                      <ReceiptRowTitle>판매사</ReceiptRowTitle>
                      <div>{MASTER_OBJ?.SELLER_WHOLE?.[receipt?.partnerSeq]}</div>
                    </ReceiptRow>
                    <ReceiptRow>
                      <ReceiptRowTitle>완료일시</ReceiptRowTitle>
                      <div>{receipt?.finishDate}</div>
                    </ReceiptRow>
                    <ReceiptRow>
                      <ReceiptRowTitle>취소일</ReceiptRowTitle>
                      <div>{receipt?.cancelDate}</div>
                    </ReceiptRow>
                    <ReceiptRow>
                      <ReceiptRowTitle>송장번호</ReceiptRowTitle>
                      <div>{receipt?.invoiceSeq}</div>
                    </ReceiptRow>
                  </WrapperCol>
                </Wrapper>
                <hr />
                <Title style={{ marginTop: 0 }}>결제금액</Title>
                <Wrapper>
                  <WrapperCol style={{ width: '100%' }}>
                    <ReceiptRow>
                      <ReceiptRowTitle>공급가액</ReceiptRowTitle>
                      <div>{stringToThousandComma(receipt?.supplyValue + '')}</div>
                    </ReceiptRow>
                    <ReceiptRow>
                      <ReceiptRowTitle>부가세액</ReceiptRowTitle>
                      <div>{stringToThousandComma(receipt?.valueAddedTax + '')}</div>
                    </ReceiptRow>
                  </WrapperCol>
                </Wrapper>
                <hr />
                <Wrapper>
                  <WrapperCol style={{ width: '100%' }}>
                    <ReceiptRow>
                      <ReceiptRowTitle>회사명</ReceiptRowTitle>
                      <div>{receipt?.supplyValue}</div>
                    </ReceiptRow>
                    <ReceiptRow>
                      <ReceiptRowTitle>주소</ReceiptRowTitle>
                      <div>{receipt?.valueAddedTax}</div>
                    </ReceiptRow>
                  </WrapperCol>
                </Wrapper>
              </div>
            );
          })}
        </div>
      }
    />
  );
};
