import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import WMSFEE from './wmsFee';
import OMSFEE from './omsFee';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER, RAWDATA } from 'redux/services/menuSlice';

export const BASICFEE_MASTER = [
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  'INFRA',
  //
  'FEE_TYPE',
  'OPTION_TYPE',
  'ADJUSTMENT_TYPE',
  'TS_CATEGORY',
  'DRIVER_PARTY',
  'TEAM_GRADE',

  'MODEL_GROUP',
  `${RAWDATA}MODEL_GRP_1`,
  `${RAWDATA}MODEL_GRP_2`,
  'MODEL_GRP_1',
  'MODEL_GRP_2',
  'MODEL_GRP_3',
  'CANCEL_REASON',
  'LOGISTIC_TYPE',
  'WORK_TYPE',
  'PALLET_TYPE',
  'DATE_UNIT',
  'STORAGE_TYPE',
  'LOCATION_CATEGORY',
  'LOCATION_SIZE',
  `${RAWDATA}PROVINCE`,
  'PROVINCE',
  'CITY',
  //
  'OX_FLAG',
  'MONTH',
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(BASICFEE_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            BASICFEE_DEFAULT_TAB: <OMSFEE TARGET={'DEFAULT'} />,
            BASICFEE_PRODUCT_TAB: <OMSFEE TARGET={'PRODUCT'} />,
            BASICFEE_GROUP_TAB: <OMSFEE TARGET={'GROUP'} />,
            BASICFEE_REGION_TAB: <OMSFEE TARGET={'REGION'} />,
            BASICFEE_MODEL_TAB: <OMSFEE TARGET={'MODEL'} />,
            BASICFEE_DATE_TAB: <OMSFEE TARGET={'DATE'} />,
            BASICFEE_CANCEL_TAB: <OMSFEE TARGET={'CANCEL'} />,
            BASICFEE_OPTION_TAB: <OMSFEE TARGET={'OPTION'} />,
            //
            BASICFEE_DEFAULT_TAB_CENTER: <OMSFEE TARGET={'DEFAULT'} />,
            BASICFEE_PRODUCT_TAB_CENTER: <OMSFEE TARGET={'PRODUCT'} />,
            BASICFEE_GROUP_TAB_CENTER: <OMSFEE TARGET={'GROUP'} />,
            BASICFEE_REGION_TAB_CENTER: <OMSFEE TARGET={'REGION'} />,
            BASICFEE_MODEL_TAB_CENTER: <OMSFEE TARGET={'MODEL'} />,
            BASICFEE_DATE_TAB_CENTER: <OMSFEE TARGET={'DATE'} />,
            BASICFEE_CANCEL_TAB_CENTER: <OMSFEE TARGET={'CANCEL'} />,
            BASICFEE_OPTION_TAB_CENTER: <OMSFEE TARGET={'OPTION'} />,
            // 지점만 있다
            BASICFEE_IN_TAB_CENTER: <WMSFEE tabId={'BASICFEE_IN_TAB_CENTER'} />,
            BASICFEE_OUT_TAB_CENTER: <WMSFEE tabId={'BASICFEE_OUT_TAB_CENTER'} />,
            BASICFEE_STORAGE_TAB_CENTER: <WMSFEE tabId={'BASICFEE_STORAGE_TAB_CENTER'} />,
            //
            BASICFEE_DEFAULT_TAB_DRIVER: <OMSFEE TARGET={'DEFAULT'} />,
            BASICFEE_PRODUCT_TAB_DRIVER: <OMSFEE TARGET={'PRODUCT'} />,
            BASICFEE_GROUP_TAB_DRIVER: <OMSFEE TARGET={'GROUP'} />,
            BASICFEE_REGION_TAB_DRIVER: <OMSFEE TARGET={'REGION'} />,
            BASICFEE_MODEL_TAB_DRIVER: <OMSFEE TARGET={'MODEL'} />,
            BASICFEE_DATE_TAB_DRIVER: <OMSFEE TARGET={'DATE'} />,
            BASICFEE_CANCEL_TAB_DRIVER: <OMSFEE TARGET={'CANCEL'} />,
            BASICFEE_OPTION_TAB_DRIVER: <OMSFEE TARGET={'OPTION'} />,
            //
            BASICFEE_DEFAULT_TAB_SUBCONTRACT: <OMSFEE TARGET={'DEFAULT'} />,
            BASICFEE_PRODUCT_TAB_SUBCONTRACT: <OMSFEE TARGET={'PRODUCT'} />,
            BASICFEE_GROUP_TAB_SUBCONTRACT: <OMSFEE TARGET={'GROUP'} />,
            BASICFEE_REGION_TAB_SUBCONTRACT: <OMSFEE TARGET={'REGION'} />,
            BASICFEE_MODEL_TAB_SUBCONTRACT: <OMSFEE TARGET={'MODEL'} />,
            BASICFEE_DATE_TAB_SUBCONTRACT: <OMSFEE TARGET={'DATE'} />,
            BASICFEE_CANCEL_TAB_SUBCONTRACT: <OMSFEE TARGET={'CANCEL'} />,
            BASICFEE_OPTION_TAB_SUBCONTRACT: <OMSFEE TARGET={'OPTION'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
