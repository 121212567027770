import { useState, useEffect, useRef } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { httpClient } from 'common/http-client/axiosConfig';

import { v4 as uuidv4 } from 'uuid';

// component
import { defaultBottomSheetObj } from 'components/template/bottomSheets';
import Presenter from './presenter';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Container = () => {
  const { masterOptions } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  // grid
  const [gridId, setGrid] = useState();
  //
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setGrid(`deliverMagam_${uuidv4()}`);
  }, []);

  const [searchObj, setSearchObj] = useState({
    deliveryDate: null,
    infraSeq: null,
    centerCode: null,
    partnerSeq: null,
    closeStatus: null,
    orderNumType: null,
    orderNum: null,
    invoiceNumType: null,
    invoiceNum: null,
  });
  const [bottomSheetObj, setBottomSheetObj] = useState(defaultBottomSheetObj);

  const paramingSearchObjToUrl = (url, searchObj, range) => {
    if (range) {
      if (range?.from) url += `&fromDeliveryDate=${returnDateyyyymmdd(range?.from)}`;
      if (range?.to) url += `&toDeliveryDate=${returnDateyyyymmdd(range?.to)}`;
    } else {
      if (searchObj?.deliveryDate) url += `&deliveryDate=${searchObj?.deliveryDate}`;
    }
    if (searchObj?.infraSeq) url += `&infraSeq=${searchObj?.infraSeq}`;
    if (searchObj?.centerCode) url += `&centerCode=${searchObj?.centerCode}`;
    if (searchObj?.partnerSeq) url += `&partnerSeq=${searchObj?.partnerSeq}`;
    if (searchObj?.closeStatus) url += `&closeStatus=${searchObj?.closeStatus}`;
    if (searchObj?.orderNum && searchObj?.orderNumType) url += `&${searchObj?.orderNumType}=${searchObj?.orderNum}`;
    if (searchObj?.invoiceNum && searchObj?.invoiceNumType) url += `&${searchObj?.invoiceNumType}=${searchObj?.invoiceNum}`;
    return url;
  };

  const fetchList = async (searchObj) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(defaultBottomSheetObj);

    let url = `/order/v2/close/list/excel?`;
    url = paramingSearchObjToUrl(url, searchObj);
    const infraArr = [];
    const infraCenterObj = {};
    await httpClient.get(url).then((rs) => {
      const addList = rs.data || [];
      const rows = [];
      addList?.forEach((row, idx) => {
        if (row.infraSeq === null) {
          // idx === 0
          // 전체 전체
          const obj = {
            ...row,
            // maxPage,
            searchObj: JSON.stringify(searchObj),
            totalCount: rs.data.totalCount,
            totalQuantityKr: `${row.totalQuantity}${row.rate ? `(${row.rate}%)` : ''}`,
            centerCodeInInfra: null,
            centerCodeInInfraKr: idx === 0 ? '전체' : '',
            partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE),
            centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE),
            children: [],
          };
          infraArr.push(null);
          rows.push(obj);
        } else {
          if (!infraArr.includes(row.infraSeq)) {
            const obj = {
              ...row,
              searchObj: JSON.stringify(searchObj),
              closeStatusKr: row.closeStatusKr === null ? '전체' : row.partnerSeq !== null ? '-' : row.closeStatusKr,

              totalQuantityKr: `${row.totalQuantity}(${row.rate}%)`,
              centerCodeInInfraKr: row.centerCode === null ? selectlabel(row.infraSeq, masterOptions?.INFRA) : selectlabel(row.centerCode, masterOptions?.CENTER),
              partnerSeqKr: row.partnerSeq ? selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE) : '전체',
              centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE),
              children: [],
            };
            infraArr.push(row.infraSeq);
            infraCenterObj[row.infraSeq] = [];
            rows.push(obj);
          } else {
            if (!infraCenterObj[row.infraSeq].includes(row.centerCode)) {
              const obj = {
                ...row,
                // maxPage,
                searchObj: JSON.stringify(searchObj),
                totalCount: rs.data.totalCount,
                totalQuantityKr: `${row.totalQuantity}(${row.rate}%)`,
                closeStatusKr: row.closeStatusKr === null ? '전체' : row.partnerSeq !== null ? '-' : row.closeStatusKr,
                centerCodeInInfraKr: row.centerCode === null ? selectlabel(row.infraSeq, masterOptions?.INFRA) : selectlabel(row.centerCode, masterOptions?.CENTER),
                partnerSeqKr: row.partnerSeq ? selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE) : '전체',
                centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE),
                children: [],
              };
              rows[infraArr.indexOf(row.infraSeq)]['children'].push(obj);
              infraCenterObj[row.infraSeq].push(row.centerCode);
            } else {
              const obj = {
                ...row,
                searchObj: JSON.stringify(searchObj),
                totalQuantityKr: `${row.totalQuantity}(${row.rate}%)`,
                closeStatusKr: row.closeStatusKr === null ? '전체' : row.partnerSeq !== null ? '-' : row.closeStatusKr,
                centerCodeInInfraKr: '',
                partnerSeqKr: row.partnerSeq ? selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE) : '전체',
                centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE),
              };
              rows[infraArr.indexOf(row.infraSeq)]['children'][infraCenterObj[row.infraSeq].indexOf(row.centerCode)]['children'].push(obj);
            }
          }
        }
      });
      setRows(rows);
    });
    dispatch(setLoading(null));
  };

  const bottomGridRef = useRef();
  const fetchDetailList = async (e) => {
    const item = e.item;
    let url = `/order/v2/close/detail/list?`;
    const searchObj = JSON.parse(item.searchObj);
    if (item.deliveryDate) url += `&deliveryDate=${item.deliveryDate}`;
    if (item.centerCode) url += `&centerCode=${item.centerCode}`;
    else if (!item.centerCode && searchObj?.centerCode) {
      url += `&centerCode=${searchObj?.centerCode}`;
    }
    if (item.closeFlag === true) url += `&closeFlag=${true}`;
    if (item.closeFlag === false) url += `&closeFlag=${false}`;
    if (item.partnerSeq) {
      url += `&partnerSeq=${item.partnerSeq}`;
    } else {
      if (searchObj.partnerSeq) url += `&partnerSeq=${searchObj.partnerSeq}`;
    }

    if (item.infraSeq) url += `&infraSeq=${item.infraSeq}`;
    else if (!item.infraSeq && searchObj?.infraSeq) {
      url += `&infraSeq=${searchObj?.infraSeq}`;
    }
    if (item.closeStatus) url += `&closeStatus=${item.closeStatus}`;
    if (item.orderSeq) url += `&orderSeq=${item.orderSeq}`;
    if (item.externalOrderNumber) url += `&externalOrderNumber=${item.externalOrderNumber}`;
    if (item.invoiceSeq) url += `&invoiceSeq=${item.invoiceSeq}`;
    if (item.externalInvoiceNumber) url += `&externalInvoiceNumber=${item.externalInvoiceNumber}`;

    if (item.centerCodeInInfraKr !== '' && item.closeStatusKr === '전체') {
      const searchObj = JSON.parse(item?.searchObj);
      if (searchObj?.closeStatus) url += `&closeStatus=${searchObj?.closeStatus}`;
    }
    await httpClient.get(url).then((rs) => {
      if (rs.status === 200) {
        const rows = rs.data;
        rows?.forEach((row) => {
          row.modelGroupSmallKr = selectlabel(row.modelGroupSmall, masterOptions?.MODEL_GRP_3);
          row.inventoryLocationKr = selectlabel(row.inventoryLocation, masterOptions?.INVENTORY_LOCATION);
          row.receiverAddr = row.receiverAddr1 + ' ' + row.receiverAddr2;
          row.customerAddr = row.customerAddr1 + ' ' + row.customerAddr2;
          row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE);
          row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE);
          row.orderTypeKr = selectlabel(row.orderType, masterOptions?.ORDER_TYPE);
          row.feeTypeKr = selectlabel(row.feeType, masterOptions?.FEE_TYPE);
          row.invoiceStatusKr = selectlabel(row.invoiceStatus, masterOptions?.INVOICE_STATUS);
          row.ladderTruckFlagKr = row.ladderTruckFlag === true ? 'O' : 'X';
          row.liftingFlagKr = row.liftingFlag === true ? 'O' : 'X';
          row.scrapFlagKr = row.scrapFlag === true ? 'O' : 'X';
          row.driverPhoneKr = selectlabel(row.userId, masterOptions?.DRIVER_PHONE_WHOLE);
          row.infraSeqKr = selectlabel(row.infraSeq, masterOptions?.INFRA);
          row.userIdKr = selectlabel(row.userId, masterOptions?.DRIVER_WHOLE);
          row.assistUserIdKr = selectlabel(row.assistUserId, masterOptions?.DRIVER_WHOLE);
          row.networkPartnerSeq = selectlabel(row.networkPartnerSeq, masterOptions?.NETWORK);
        });

        const gridButtonhandler = (e) => {
          const id = e.target.id;
          if (id === 'excel-down') {
            bottomGridRef?.current?.exportAsXlsx({ fileName: `일일배송마감_상세` });
          }
        };

        setBottomSheetObj({
          visible: true,
          hasTabs: [
            {
              title: '일일배송마감 상세',
              gridRef: bottomGridRef,
              columns: detailColumns,
              rows,
              buttons: [
                <div key={`excel-down`} id={`excel-down`} className={`green`} onClick={gridButtonhandler}>
                  엑셀다운
                </div>,
              ],
              options: {
                isRowAllCheckCurrentView: false,
                showRowAllCheckBox: false,
                showRowCheckColumn: false,
              },
            },
          ],
        });
      }
    });
  };

  const columns = [
    {
      headerText: '인프라/창고',
      dataField: 'centerCodeInInfraKr',
      width: 150,
      editable: false,
      styleFunction: function (rowIndex, columnIndex, value, headerText, item, dataField) {
        if (item?.centerCodeInInfra === null) {
          return 'bold-column-style';
        }
        return null;
      },
    },
    {
      headerText: '진행상태',
      dataField: 'closeStatusKr',
      editable: false,
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      editable: false,
    },
    {
      headerText: '상세보기',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '상세보기',
        onClick: fetchDetailList,
      },
      editable: false,
    },
    {
      headerText: '합계',
      dataField: 'totalQuantityKr',
      editable: false,
    },
    {
      headerText: '배송',
      dataField: 'deliveryQuantity',
      editable: false,
    },
    {
      headerText: '방문',
      dataField: 'visitQuantity',
      editable: false,
    },
    {
      headerText: '회수',
      dataField: 'collectQuantity',
      editable: false,
    },
    {
      headerText: '교환',
      dataField: 'exchangeQuantity',
      editable: false,
    },
    {
      headerText: '이전',
      dataField: 'moveQuantity',
      editable: false,
    },
    {
      headerText: '전시품',
      dataField: 'displayQuantity',
      editable: false,
    },
    {
      headerText: '택배',
      dataField: 'parcelQuantity',
      editable: false,
    },
    {
      headerText: 'AS',
      dataField: 'asQuantity',
      editable: false,
    },
    {
      headerText: '수리',
      dataField: 'repairQuantity',
      editable: false,
    },
    {
      headerText: '납품',
      dataField: 'supplyQuantity',
      editable: false,
    },
  ];

  const excelColumns = [
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
      width: 200,
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
      width: 200,
    },
    {
      headerText: '진행상태',
      dataField: 'closeStatusKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '합계',
      dataField: 'totalQuantityKr',
    },
    {
      headerText: '배송',
      dataField: 'deliveryQuantity',
    },
    {
      headerText: '방문',
      dataField: 'visitQuantity',
    },
    {
      headerText: '회수',
      dataField: 'collectQuantity',
    },
    {
      headerText: '교환',
      dataField: 'exchangeQuantity',
    },
    {
      headerText: '이전',
      dataField: 'moveQuantity',
    },
    {
      headerText: '전시품',
      dataField: 'displayQuantity',
    },
    {
      headerText: '택배',
      dataField: 'parcelQuantity',
    },
    {
      headerText: 'AS',
      dataField: 'asQuantity',
    },
    {
      headerText: '수리',
      dataField: 'repairQuantity',
    },
    {
      headerText: '납품',
      dataField: 'supplyQuantity',
    },
  ];

  const [orderDetailObj, setOrderDetailObj] = useState();

  const detailColumns = [
    {
      headerText: '상세보기',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '상세보기',
        onClick: (e) => {
          console.log(e);
          setOrderDetailObj({
            item: e.item,
            visible: true,
          });
        },
      },
      editable: false,
    },
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
      editable: false,
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
      editable: false,
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      editable: false,
    },
    {
      headerText: '진행상태',
      dataField: 'closeStatusKr',
      editable: false,
    },
    {
      headerText: '권역정보',
      dataField: 'zipcodeGroupInfo',
    },
    {
      headerText: '주기사',
      dataField: 'userIdKr',
    },
    {
      headerText: '기사코드',
      dataField: 'userId',
    },
    {
      headerText: '위탁사',
      dataField: 'networkPartnerSeqKr',
    },
    {
      headerText: '주문일',
      dataField: 'orderDate',
      editable: false,
    },
    {
      headerText: '등록일',
      dataField: 'registerDate',
      editable: false,
    },
    {
      headerText: '희망일',
      dataField: 'promiseDeliveryDate',
      editable: false,
    },
    {
      headerText: '지정일(확정일)',
      dataField: 'deliveryDate',
      editable: false,
    },
    {
      headerText: '해피콜진행시각',
      dataField: 'happyCallDatetime',
      editable: false,
    },
    {
      headerText: '완료일',
      dataField: 'finishDatetime',
      editable: false,
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
      editable: false,
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
      editable: false,
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
      editable: false,
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
      editable: false,
    },
    {
      headerText: '주문유형',
      dataField: 'orderTypeKr',
      editable: false,
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
      editable: false,
    },
    {
      headerText: '송장상태',
      dataField: 'invoiceStatusKr',
      editable: false,
      styleFunction: function (rowIndex, columnIndex, value, headerText, item, dataField) {
        if (value === '접수대기') {
          return 'status-badge-yellow';
        } else if (
          //
          value === '재고없음' ||
          value === '기사캐파초과' ||
          value === '배송불가지역' ||
          value === '주소오류' ||
          value === '창고권역미설정' ||
          value === '기사권역미설정' ||
          value === '기사설정오류' ||
          value === '컷오프마감'
        ) {
          return 'status-badge-orange';
        } else if (
          value === '가접수완료' ||
          value === '접수완료' ||
          value === '반출대기' ||
          value === '가배차완료' ||
          value === '스케줄확정' ||
          value === '출고대기' ||
          value === '배송중' ||
          value === '간선이동중'
        ) {
          return 'status-badge-green';
        } else if (
          value === '배송완료' ||
          value === '방문완료' ||
          value === '방문설치' ||
          value === '납품회수완료' ||
          value === '회수완료' ||
          value === '교환회수완료' ||
          value === '교환배송완료' ||
          value === '이전회수완료' ||
          value === '이전설치완료' ||
          value === '전시품설치완료' ||
          value === '전시품회수완료' ||
          value === '택배배송완료' ||
          value === '택배회수완료' ||
          value === 'AS완료' ||
          value === '수리회수완료' ||
          value === '수리설치완료' ||
          value === '납품완료'
        ) {
          return 'status-badge-blue';
        } else if (
          value === '주문오등록취소' ||
          value === '고객집도착후취소' ||
          value === '배송완료후취소' ||
          value === '개봉후취소' ||
          value === '재고없음취소' ||
          value === '간선출고전취소' ||
          value === '간선출고후취소' ||
          value === '기사출고전취소' ||
          value === '기사출고후취소'
        ) {
          return 'status-badge-red';
        } else if (
          value === '보류(이사일정지연)' ||
          value === '보류(고객요청)' ||
          value === '보류(귀책확인필요)' ||
          value === '보류(입금확인필요)' ||
          value === '보류(비용확인필요)' ||
          value === '보류(상품생산필요)' ||
          value === '보류(기타)' ||
          value === '보류'
        ) {
          return 'status-badge-gray';
        } else if (value === '처리중') {
          return 'status-badge-purple';
        }
        return null;
      },
    },
    {
      headerText: '재고위치',
      dataField: 'inventoryLocationKr',
      editable: false,
    },
    {
      headerText: '상품명',
      dataField: 'productName',
      editable: false,
      width: 200,
    },
    {
      headerText: '상품구성수량',
      dataField: 'quantity',
      editable: false,
    },
    {
      headerText: '수령인 고객정보',
      children: [
        {
          headerText: '수령인',
          dataField: 'receiverName',
          editable: false,
        },
        {
          headerText: '우편번호',
          dataField: 'receiverZipcode',
          editable: false,
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr',
          editable: false,
          width: 150,
        },
        {
          headerText: '연락처1',
          dataField: 'receiverPhone',
          editable: false,
        },
        {
          headerText: '연락처2',
          dataField: 'receiverPhone2',
          editable: false,
        },
      ],
    },
    {
      headerText: '주문인 고객정보',
      children: [
        {
          headerText: '주문인',
          dataField: 'customerName',
          editable: false,
        },
        {
          headerText: '우편번호',
          dataField: 'customerZipcode',
          editable: false,
        },
        {
          headerText: '주소',
          dataField: 'customerAddr',
          width: 150,
          editable: false,
        },
        {
          headerText: '연락처1',
          dataField: 'customerPhone',
          editable: false,
        },
        {
          headerText: '연락처2',
          dataField: 'customerPhone2',
          editable: false,
        },
      ],
    },
    {
      headerText: '부기사',
      dataField: 'assistUserIdKr',
    },
    {
      headerText: '연락처(주기사)',
      dataField: 'driverPhoneKr',
    },
    {
      headerText: '기사 요청사항',
      dataField: 'driverMemo',
    },
    {
      headerText: '사다리차',
      dataField: 'ladderTruckFlagKr',
    },
    {
      headerText: '양중',
      dataField: 'liftingFloor',
    },
    {
      headerText: '내림',
      dataField: 'scrapFlagKr',
    },
    {
      headerText: '고객사대행료',
      dataField: 'totalFee',
    },
  ];

  const excelDetailColumns = [
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '진행상태',
      dataField: 'closeStatusKr',
    },
    {
      headerText: '권역정보',
      dataField: 'zipcodeGroupInfo',
    },
    {
      headerText: '주기사',
      dataField: 'userIdKr',
    },
    {
      headerText: '기사코드',
      dataField: 'userId',
    },
    {
      headerText: '위탁사',
      dataField: 'networkPartnerSeqKr',
    },
    {
      headerText: '주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '등록일',
      dataField: 'registerDate',
    },
    {
      headerText: '희망일',
      dataField: 'promiseDeliveryDate',
    },
    {
      headerText: '지정일(확정일)',
      dataField: 'deliveryDate',
    },
    {
      headerText: '해피콜진행시각',
      dataField: 'happyCallDatetime',
    },
    {
      headerText: '완료일',
      dataField: 'finishDatetime',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '주문유형',
      dataField: 'orderTypeKr',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '송장상태',
      dataField: 'invoiceStatusKr',
    },
    {
      headerText: '재고위치',
      dataField: 'inventoryLocationKr',
    },
    {
      headerText: '상품번호',
      dataField: 'productSeq',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: 'CBM',
      dataField: 'boxVolume',
    },
    {
      headerText: '무게',
      dataField: 'modelWeight',
    },
    {
      headerText: '(제품)소분류',
      dataField: 'modelGroupSmallKr',
    },
    {
      headerText: '상품구성수량',
      dataField: 'quantity',
    },
    {
      headerText: '수령인 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'receiverName',
        },
        {
          headerText: '우편번호',
          dataField: 'receiverZipcode',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr',
          width: 150,
        },
        {
          headerText: '연락처1',
          dataField: 'receiverPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'receiverPhone2',
        },
      ],
    },
    {
      headerText: '주문인 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'customerName',
        },
        {
          headerText: '우편번호',
          dataField: 'customerZipcode',
        },
        {
          headerText: '주소',
          dataField: 'customerAddr',
          width: 150,
        },
        {
          headerText: '연락처1',
          dataField: 'customerPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'customerPhone2',
        },
      ],
    },
    {
      headerText: '부기사',
      dataField: 'assistUserIdKr',
    },
    {
      headerText: '연락처(주기사)',
      dataField: 'driverPhoneKr',
    },
    {
      headerText: '기사 요청사항',
      dataField: 'driverMemo',
    },
    {
      headerText: '사다리차',
      dataField: 'ladderTruckFlagKr',
    },
    {
      headerText: '양중',
      dataField: 'liftingFloor',
    },
    {
      headerText: '내림',
      dataField: 'scrapFlagKr',
    },
    {
      headerText: '고객사대행료',
      dataField: 'totalFee',
    },
  ];

  const downloadExcelV2 = async (type, range) => {
    let url;
    if (type === 'main') {
      url = `/order/v2/close/list/excel?`;
      url = paramingSearchObjToUrl(url, searchObj);
    } else if (type === 'detail') {
      url = `/order/v2/close/detail/list/excel?`;
      url = paramingSearchObjToUrl(url, searchObj);
    } else if (type === 'rangeDetail') {
      url = `/order/v2/close/detail/list/excel/period?`;
      url = paramingSearchObjToUrl(url, searchObj, range);
    }

    return httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        const data = rs.data;
        data.forEach((row) => {
          row.closeStatusKr = row.closeStatusKr ? row.closeStatusKr : '전체';
          row.closeFlagKr = row.closeFlag === null ? '전체' : row.closeFlag === false ? '미마감' : '마감';
          row.modelGroupSmallKr = selectlabel(row.modelGroupSmall, masterOptions?.MODEL_GRP_3);
          row.partnerSeqKr = row.partnerSeq ? selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE) : '전체';
          row.centerCodeKr = row.centerCode ? selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE) : '전체';
          row.userIdKr = selectlabel(row.userId, masterOptions?.DRIVER_WHOLE);
          row.infraSeqKr = row.infraSeq ? selectlabel(row.infraSeq, masterOptions?.INFRA) : '전체';
          row.orderTypeKr = selectlabel(row.orderType, masterOptions?.ORDER_TYPE);
          row.feeTypeKr = selectlabel(row.feeType, masterOptions?.FEE_TYPE);
          row.invoiceStatusKr = selectlabel(row.invoiceStatus, masterOptions?.INVOICE_STATUS);
          row.inventoryLocationKr = selectlabel(row.inventoryLocation, masterOptions?.INVENTORY_LOCATION);
          row.receiverAddr = row.receiverAddr1 + ' ' + row.receiverAddr2;
          row.customerAddr = row.customerAddr1 + ' ' + row.customerAddr2;
          row.assistUserIdKr = selectlabel(row.assistUserId, masterOptions?.DRIVER_WHOLE);
          row.driverPhoneKr = selectlabel(row.userId, masterOptions?.DRIVER_PHONE_WHOLE);
          row.ladderTruckFlagKr = row.ladderTruckFlag === true ? 'O' : 'X';
          row.scrapFlagKr = row.scrapFlag === true ? 'O' : 'X';
          row.networkPartnerSeqKr = selectlabel(row.networkPartnerSeq, masterOptions?.SELLER_WHOLE);
          row.totalQuantityKr = `${row.totalQuantity}${row.rate ? `(${row.rate}%)` : ''}`;
        });
        return data;
      }
    });
  };

  return (
    <Presenter
      gridId={gridId}
      rows={rows}
      columns={columns}
      excelColumns={excelColumns}
      excelDetailColumns={excelDetailColumns}
      orderDetailObj={orderDetailObj}
      setOrderDetailObj={setOrderDetailObj}
      //
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      fetchList={fetchList}
      downloadExcelV2={downloadExcelV2}
      //
      bottomSheetObj={bottomSheetObj}
      setBottomSheetObj={setBottomSheetObj}
    />
  );
};

export default Container;
