import { KeyboardEvent } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { InventoryModificationDefectedSearchDTO, InventoryModificationSearchDTO } from 'interface/warehouse';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = (props: ISearchBoxProps<InventoryModificationDefectedSearchDTO & InventoryModificationSearchDTO>) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList, TARGET } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        {TARGET === 'MODIFICATION_AVA' ? (
          <Col>
            <label className="col-form-label">양품조정번호</label>
            <InputD
              type="number"
              value={searchObj?.inventoryModificationSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  inventoryModificationSeq: Number(e.target.value),
                });
              }}
            />
          </Col>
        ) : (
          <Col>
            <label className="col-form-label">불용조정번호</label>
            <InputD
              type="number"
              value={searchObj?.inventoryModificationDefectedSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  inventoryModificationDefectedSeq: Number(e.target.value),
                });
              }}
            />
          </Col>
        )}
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: (option as OptionItem).value,
              });
            }}
            options={masterOptions?.CENTER_AUTH}
          />
        </Col>
        <Col>
          <label className="col-form-label">로케이션</label>
          <InputD
            value={searchObj?.locationCode || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                locationCode: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem).value,
              });
            }}
            options={masterOptions?.SELLER_AUTH}
          />
        </Col>
        <Col>
          <label className="col-form-label">조정일시</label>
          <FlatpickrD
            onChange={(date) => {
              setSearchObj({
                ...searchObj,
                fromRequestDate: returnDateyyyymmdd(date[0]),
                toRequestDate: returnDateyyyymmdd(date[1]),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">조정요청자</label>
          <InputD
            value={searchObj?.requestId || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                requestId: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'right' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
