import { useEffect, useState } from 'react';
import { DetailTopTab, IDetailTopTab } from 'components/template/detailTopTab';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { nullingMASTER } from 'redux/services/menuSlice';

// component
import Container2 from './container.js';
import { RootState } from 'redux/store';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';

export const REFUR_AND_MODEL_MOVE_REGISTER_MASTER = [
  'SELLER_SELLER_AUTH',
  'SELLER_SELLER_WHOLE',
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  //
  'MODEL_GROUP',
  'MODEL_TYPE',
];

const Index = () => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState<IDetailTopTab>();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(REFUR_AND_MODEL_MOVE_REGISTER_MASTER);
  };

  useEffect(() => {
    if (MASTER_OBJ) {
      setTabObj({
        titles: ['리퍼관리 (구)', '리퍼 생성'],
        routers: ['/refur', '/refurRegister'],
        container: [<Container2 />],
      });
    }
  }, [MASTER_OBJ]);

  return <>{tabObj && <DetailTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
