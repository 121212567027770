import { Col, Row, Form } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { PagingListDTO } from 'interface/util';
import { setLoading } from 'redux/services/menuSlice';
import { orderService } from 'pages/ORDER/1order/_services/service';
import { useEffect, useRef, useState } from 'react';
import { resetAllSelections } from 'common/util/searchBox';
import { ProductDetailListDTO, ProductDetailSearchDTO } from '../../../_interface/order';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import * as IGrid from 'aui-grid';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IProductStock {
  visible?: boolean;
  partnerSeq?: number;
  returns?: any;
}

const ProductStockModal = ({ productStockObj, setProductStockObj }) => {
  const gridRefProduct = useRef<IPagingGrid>();
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS, masterOptions } = useSelector((state: RootState) => state.menu);
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_PRODUCT: null,
    SEARCH_MODEL: null,
  });
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_PRODUCT, searchObj));
  }, [selectTypeObj?.SEARCH_PRODUCT]);

  const columns: IGrid.Column[] = [
    {
      headerText: '상품번호',
      dataField: 'productSeq',
      style: 'ellipsis-div',
    },
    {
      headerText: '외부상품번호',
      dataField: 'externalProductNumber',
      style: 'ellipsis-div',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
      style: 'ellipsis-div',
      width: 250,
    },
    {
      headerText: '구성<br/>제품수량',
      dataField: 'totalQuantity',
      style: 'ellipsis-div',
      width: 70,
    },
    {
      headerText: '구성제품',
      children: [
        {
          headerText: '제품구분',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item: ProductDetailListDTO) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.giftFlag ? '사은품' : '구성품'}</span>`;
            });
            div += ' </div>';
            return div;
          },
        },
        {
          headerText: '제품그룹',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item: ProductDetailListDTO) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${MASTER_OBJ?.MODEL_GROUP?.[model?.modelGroup] ?? selectlabel(model?.modelGroup, masterOptions?.MODEL_GROUP)}</span>`;
            });
            div += ' </div>';
            return div;
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item: ProductDetailListDTO) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.model}</span>`;
            });
            div += '</div>';
            return div;
          },
          width: 250,
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item: ProductDetailListDTO) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.modelName}</span>`;
            });
            div += '</div>';
            return div;
          },
          width: 250,
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item: ProductDetailListDTO) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.modelStockSeq}</span>`;
            });
            div += '</div>';
            return div;
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item: ProductDetailListDTO) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.modelStockSeq}</span>`;
            });
            div += ' </div>';
            return div;
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item: ProductDetailListDTO) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.skuNumber}</span>`;
            });
            div += ' </div>';
            return div;
          },
        },
        {
          headerText: '수량',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item: ProductDetailListDTO) {
            let div = `<div class="column-direction"> `;
            item?.productModels?.forEach((model) => {
              div += ` <span>${model?.quantity}</span>`;
            });
            div += ' </div>';
            return div;
          },
          dataField: 'quantity',
        },
      ],
    },
  ];

  const labellingKr = (data: PagingListDTO<ProductDetailListDTO>) => {
    const labeledList = [];
    const productSeqs = [];
    data?.list?.forEach((product) => {
      product.productModels?.forEach((model) => {
        if (!productSeqs?.includes(product?.productSeq))
          labeledList?.push({
            ...Object.assign({}, product, model),
            // model Kr
            giftFlagKr: model.giftFlag ? '사은품' : '구성품',
            // product Kr
            modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[product?.modelGroup],
            partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[product?.partnerSeq],
            productModelUpdateFlagKr: product.productModelUpdateFlag ? 'O' : 'X',
            installDurationKr: MASTER_OBJ?.INSTALL_DURATION?.[product?.installDuration],
            memberCountKr: MASTER_OBJ?.TEAM_TYPE?.[product?.memberCount],
          });
        productSeqs.push(product?.productSeq);
      });
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: ProductDetailSearchDTO) => {
    dispatch(setLoading('GET'));
    const useFlagTrueSearchObj = {
      ...searchObj,
      partnerSeq: productStockObj?.partnerSeq,
      useFlag: true,
    };
    const dataKr = labellingKr(await orderService.getProductListPaging(useFlagTrueSearchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    gridRef: gridRefProduct,
    fetchPaging,
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      partnerSeq: productStockObj?.partnerSeq,
    },
  });

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      wrappedFetchList(searchObj);
    }
  };

  return (
    <CommonModalNew
      title="상품 검색"
      subTitle={`판매사 : ${MASTER_OBJ?.SELLER_WHOLE?.[productStockObj?.partnerSeq] ?? selectlabel(productStockObj?.partnerSeq, masterOptions?.SELLER_WHOLE)}`}
      style={{ maxWidth: 1400 }}
      visible={productStockObj?.visible}
      setVisible={() => {
        setProductStockObj({ visible: false });
      }}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={(e) => {
              e.preventDefault();
              const items = gridRefProduct.current.getCheckedRowItemsAll();
              if (items?.length > 0) {
                setProductStockObj((prev) => {
                  return {
                    ...prev,
                    returns: items,
                    visible: false,
                  };
                });
              } else {
                alert('선택된 상품이 없습니다!');
              }
            }}
          >
            선택
          </button>
        </>
      }
      children={
        <div className="rows-count">
          <Form onKeyPress={onKeyPress}>
            <Row style={{ marginBottom: 20 }}>
              <Col>
                <label className="col-form-label">상품</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      value={
                        selectTypeObj?.SEARCH_PRODUCT
                          ? {
                              value: selectTypeObj?.SEARCH_PRODUCT,
                              label: MASTER_OBJ?.SEARCH_PRODUCT?.[selectTypeObj?.SEARCH_PRODUCT] ?? selectlabel(selectTypeObj?.SEARCH_PRODUCT, masterOptions?.SEARCH_PRODUCT),
                            }
                          : null
                      }
                      options={MASTER_OPS?.SEARCH_PRODUCT ?? masterOptions?.SEARCH_PRODUCT}
                      onChange={(option) => {
                        setSelectTypeObj({
                          ...selectTypeObj,
                          SEARCH_PRODUCT: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!selectTypeObj?.SEARCH_PRODUCT}
                      value={searchObj?.[selectTypeObj?.SEARCH_PRODUCT] || ''}
                      onChange={(e) => {
                        setSearchObj({
                          ...searchObj,
                          [selectTypeObj?.SEARCH_PRODUCT]: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">제품</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      value={
                        selectTypeObj?.SEARCH_MODEL
                          ? {
                              value: selectTypeObj?.SEARCH_MODEL,
                              label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL] ?? selectlabel(selectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                            }
                          : null
                      }
                      options={MASTER_OPS?.SEARCH_MODEL ?? masterOptions?.SEARCH_MODEL}
                      onChange={(option) => {
                        setSelectTypeObj({
                          ...selectTypeObj,
                          SEARCH_MODEL: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!selectTypeObj?.SEARCH_MODEL}
                      value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                      onChange={(e) => {
                        setSearchObj({
                          ...searchObj,
                          [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">제품그룹</label>
                <SelectD
                  value={
                    searchObj?.modelGroup
                      ? {
                          value: searchObj?.modelGroup,
                          label: MASTER_OBJ?.MODEL_GROUP?.[searchObj?.modelGroup] || selectlabel(searchObj?.modelGroup, masterOptions?.MODEL_GROUP),
                        }
                      : null
                  }
                  options={MASTER_OPS?.MODEL_GROUP || masterOptions?.MODEL_GROUP}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      modelGroup: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col style={{ display: 'flex', paddingTop: 10 }}>
                <button
                  className="btn btn-search ssm"
                  onClick={(e) => {
                    e.preventDefault();
                    wrappedFetchList(searchObj);
                  }}
                >
                  조회
                </button>
              </Col>
            </Row>
          </Form>
          <GridBox
            gridRef={gridRefProduct}
            columns={columns}
            gridProps={{
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              enableCellMerge: true,
              enableSummaryMerge: true,
              wordWrap: true,
              fixedColumnCount: 4,
              rowCheckToRadio: true,
              rowStyleFunction(rowIndex, item) {
                return `row-height-${item?.productModels?.length}-count`; // display: none
              },
            }}
          />
        </div>
      }
    />
  );
};

export default ProductStockModal;
