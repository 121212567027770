export const XLSX = require('xlsx');
// IE10, 11은 readAsBinaryString 지원을 안함. 따라서 체크함.
export var rABS = typeof FileReader !== 'undefined' && typeof FileReader.prototype !== 'undefined' && typeof FileReader.prototype.readAsBinaryString !== 'undefined';

export function checkHTML5Brower() {
  var isCompatible = false;
  if (window.File && window.FileReader && window.FileList && window.Blob) {
    isCompatible = true;
  }
  return isCompatible;
}

// IE10, 11는 바이너리스트링 못읽기 때문에 ArrayBuffer 처리 하기 위함.😨
export function fixdata(data) {
  var o = '',
    l = 0,
    w = 10240;
  for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
  return o;
}

// 파싱된 시트의 CDATA 제거 후 반환.
export function process_wb(wb) {
  var output = '';
  output = JSON.stringify(to_json(wb));
  output = output.replace(/<!\[CDATA\[(.*?)\]\]>/g, '$1');
  return JSON.parse(output);
}

// 엑셀 시트를 파싱하여 반환
// 이게 존나 오래걸림 왜지
export function to_json(workbook) {
  const sheetName = workbook['SheetNames'][0];
  var result = {};
  let keyArr = Object.keys(workbook.Sheets[sheetName]);
  // 개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳

  for (let index = 0; index < keyArr.length; index++) {
    if (index !== 0 && index !== keyArr.length - 1) {
      if (workbook.Sheets[sheetName][keyArr[index]].t && workbook.Sheets[sheetName][keyArr[index]].t.replace) {
        workbook.Sheets[sheetName][keyArr[index]].t = workbook.Sheets[sheetName][keyArr[index]].t.replace(/\n/g, '');
      }
      if (workbook.Sheets[sheetName][keyArr[index]].v && workbook.Sheets[sheetName][keyArr[index]].v.replace) {
        workbook.Sheets[sheetName][keyArr[index]].v = workbook.Sheets[sheetName][keyArr[index]].v.replace(/\n/g, '');
      }
      if (workbook.Sheets[sheetName][keyArr[index]].r && workbook.Sheets[sheetName][keyArr[index]].r.replace)
        workbook.Sheets[sheetName][keyArr[index]].r = workbook.Sheets[sheetName][keyArr[index]].r.replace(/\n/g, '');
      if (workbook.Sheets[sheetName][keyArr[index]].h && workbook.Sheets[sheetName][keyArr[index]].h.replace)
        workbook.Sheets[sheetName][keyArr[index]].h = workbook.Sheets[sheetName][keyArr[index]].h.replace(/\n/g, '');
      if (workbook.Sheets[sheetName][keyArr[index]].w && workbook.Sheets[sheetName][keyArr[index]].w.replace)
        workbook.Sheets[sheetName][keyArr[index]].w = workbook.Sheets[sheetName][keyArr[index]].w.replace(/\n/g, '');
    }
  }
  // 개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳개행문자바꾸는곳

  workbook.SheetNames.forEach(function (sheetName) {
    // JSON 으로 파싱
    //var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);

    // CSV 로 파싱
    var roa = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName], { FS: 'Å' });
    if (roa.length > 0) {
      result[sheetName] = roa;
    }
  });
  return result;
}

export const parseCvs = (value) => {
  var rows = value.split('\n');
  var pData = [];
  for (var i = 0, len = rows.length; i < len; i++) {
    let obj = rows[i].split('Å');
    if (obj.length > 1) {
      let flag = false;
      for (let j = 0; j < obj.length; j++) {
        if (obj[j].length > 0) {
          flag = true;
        }
      }
      if (flag) {
        pData[i] = obj;
      }
    }
    if (obj.length === 1) {
      if (obj[0].length > 0) {
        pData[i] = obj;
      }
    }
  }
  return pData;
  // var rows = value.split("\n");
  // console.log(rows);
  // var pData = [];
  // for (var i = 0, len = rows.length; i < len; i++) {
  //   // let obj = rows[i].split(",");
  //   let obj = [];
  //   let till = -1;
  //   let passPlz = false;
  //   let during = false;
  //   for(let idx=0; idx<rows[i].length; idx++) {
  //     if(rows[i][idx] === '\"') {
  //       if(during === false) {
  //         till = idx + 1;
  //         during = true;
  //       } else if(during === true){
  //         const str = rows[i].substring(till, idx);
  //         obj.push(str);
  //         during = false;
  //         passPlz = true;
  //         till = idx + 1;
  //       }
  //     }
  //     if(rows[i][idx] === ',' && !during) {
  //       if(!passPlz) {
  //         const str = rows[i].substring(till, idx);
  //         obj.push(str);
  //       }
  //       passPlz = false;
  //       till = idx + 1;
  //     }
  //   }

  //   console.log(obj);
  //   if (obj.length > 1) {
  //     let flag = false;
  //     for (let j = 0; j < obj.length; j++) {
  //       if (obj[j].length > 0) {
  //         flag = true;
  //       }
  //     }
  //     if (flag) {
  //       pData[i] = obj;
  //     }
  //   }
  //   if (obj.length === 1) {
  //     if (obj[0].length > 0) {
  //       pData[i] = obj;
  //     }
  //   }
  // }
  // return pData;
};

export const excelTranslator = (e, transCol?: string[] | ((headers: string[]) => string[])): Promise<any[]> => {
  if (!checkHTML5Brower()) {
    alert('브라우저가 HTML5 를 지원하지 않습니다.\r\n서버로 업로드해서 해결하십시오.');
    return;
  }
  console.log(typeof transCol);
  const file = e.target.files[0];
  if (typeof file === 'undefined') {
    return;
  }
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const addArr = [];
      const data = e.target.result;
      /* 엑셀 바이너리 읽기 */
      let workbook;
      if (rABS) {
        // 일반적인 바이너리 지원하는 경우
        workbook = XLSX.read(data, { type: 'binary' });
      } else {
        // IE 10, 11인 경우
        const arr = fixdata(data);
        workbook = XLSX.read(Buffer.from(arr, 'base64'), { type: 'base64' });
      }
      const jsonObj = process_wb(workbook);

      if (jsonObj[Object.keys(jsonObj)[0]]) {
        const parseExcelDataArr = parseCvs(jsonObj[Object.keys(jsonObj)[0]]);
        const headers = parseExcelDataArr[0];
        const colEnArr = typeof transCol === 'function' ? transCol(headers) : transCol; // type object or function
        parseExcelDataArr?.forEach((oneRow = [], idx) => {
          let obj = {};
          if (idx !== 0) {
            oneRow.forEach((ele: string, i: number) => {
              if (colEnArr[i])
                obj = {
                  ...obj,
                  [colEnArr[i]]: ele.trim(),
                };
            });
            addArr.push(obj);
          }
        });
      }

      // await returns
      resolve(addArr);
    };
    reader.onloadend = () => {
      e.target.value = '';
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
};
