import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';

import * as IGrid from 'aui-grid';
import { makeObjtoFooter } from 'common/util/grid';
import { InventoryLiveScmListDTO, InventoryLiveScmSearchDTO, InventoryPeriodScmListDTO, InventoryPeriodScmSearchDTO } from 'interface/warehouse';
import {
  INVENTORY_LIVE_SCM_DETAIL,
  INVENTORY_LIVE_SCM_DETAIL_EXCEL,
  INVENTORY_LIVE_SCM_LIST_EXCEL,
  INVENTORY_LIVE_SCM_LIST_PAGING,
  INVENTORY_LIVE_SCM_LIST_QUANTITY,
  INVENTORY_PERIOD_SCM_DETAIL,
  INVENTORY_PERIOD_SCM_DETAIL_EXCEL,
  INVENTORY_PERIOD_SCM_LIST_EXCEL,
  INVENTORY_PERIOD_SCM_LIST_PAGING,
} from 'envVar';
import { PagingListDTO } from 'interface/util';

class SCMService {
  // live
  public async getInventoryLiveSCMPaging(searchDTO: InventoryLiveScmSearchDTO): Promise<PagingListDTO<InventoryLiveScmListDTO>> {
    let url = INVENTORY_LIVE_SCM_LIST_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInventoryLiveSCMTotal(searchDTO: InventoryLiveScmSearchDTO): Promise<IGrid.Footer[]> {
    let url = INVENTORY_LIVE_SCM_LIST_QUANTITY + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    const footer = makeObjtoFooter(data);
    return footer;
  }

  public async getInventoryLiveSCMDetail(searchDTO: InventoryLiveScmSearchDTO): Promise<InventoryLiveScmListDTO[]> {
    let url = INVENTORY_LIVE_SCM_DETAIL + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInventoryLiveSCMExcelMain(searchDTO: InventoryLiveScmSearchDTO): Promise<InventoryLiveScmListDTO[]> {
    let url = INVENTORY_LIVE_SCM_LIST_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryLiveSCMExcelDetail(searchDTO: InventoryLiveScmSearchDTO): Promise<InventoryLiveScmListDTO[]> {
    let url = INVENTORY_LIVE_SCM_DETAIL_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  // period
  public async getInventoryPeriodSCMPaging(searchDTO: InventoryPeriodScmSearchDTO): Promise<PagingListDTO<InventoryPeriodScmListDTO>> {
    let url = INVENTORY_PERIOD_SCM_LIST_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInventoryPeriodSCMDetail(searchDTO: InventoryPeriodScmSearchDTO): Promise<InventoryPeriodScmListDTO[]> {
    let url = INVENTORY_PERIOD_SCM_DETAIL + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInventoryPeriodSCMExcelMain(searchDTO: InventoryPeriodScmSearchDTO): Promise<InventoryPeriodScmListDTO[]> {
    let url = INVENTORY_PERIOD_SCM_LIST_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryPeriodSCMExcelDetail(searchDTO: InventoryPeriodScmSearchDTO): Promise<InventoryPeriodScmListDTO[]> {
    let url = INVENTORY_PERIOD_SCM_DETAIL_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
}

export const scmService = new SCMService();
