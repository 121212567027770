import { Col, Form, Row } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, OptionItem, SelectD } from 'components/reactstrap/reactstrap';

import { KeyboardEvent, useEffect, useState } from 'react';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { resetAllSelections } from 'common/util/searchBox';
import { MessageHistorySearchDTO } from '../../../_interface/order';

export const SearchBox = (props: ISearchBoxProps<MessageHistorySearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE: null,
    SEARCH_CUSTOMER: null,
    SEARCH_SEQ: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_CUSTOMER, searchObj));
  }, [selectTypeObj?.SEARCH_CUSTOMER]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SEQ, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ]);

  const { searchObj, setSearchObj, fetchList } = props;
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">날짜선택</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_DATE
                    ? {
                        value: selectTypeObj?.SEARCH_DATE,
                        label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_DATE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_DATE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <FlatpickrD
                disabled={!selectTypeObj?.SEARCH_DATE}
                value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                    [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                  });
                }}
                onClose={(option) => {
                  if (option?.length === 1)
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                      [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                    });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">고객정보</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_CUSTOMER
                    ? {
                        value: selectTypeObj?.SEARCH_CUSTOMER,
                        label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_CUSTOMER],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_CUSTOMER}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_CUSTOMER: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_CUSTOMER}
                value={searchObj?.[selectTypeObj?.SEARCH_CUSTOMER]}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_CUSTOMER]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">주문/송장번호</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_SEQ
                    ? {
                        value: selectTypeObj?.SEARCH_SEQ,
                        label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_SEQ],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_SEQ}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_SEQ: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_SEQ}
                value={searchObj?.[selectTypeObj?.SEARCH_SEQ]}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_SEQ]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            options={MASTER_OPS?.SELLER_AUTH}
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: MASTER_OBJ?.SELLER_AUTH?.[searchObj?.partnerSeq],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">전송시점</label>
          <SelectD
            options={MASTER_OPS?.MESSAGE_TYPE}
            value={
              searchObj?.messageType
                ? {
                    value: searchObj?.messageType,
                    label: MASTER_OBJ?.MESSAGE_TYP?.[searchObj?.messageType],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                messageType: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
