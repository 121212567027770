import { ReactNode, useEffect, useState } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './modelMove';

// options
import { centerListForlabeling, codeMasterOptionReturn, driverListForLabeling, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { setLoading, setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { TopTab } from 'components/template/topTabNew';
import { useLocation } from 'react-router-dom';
import { TabDTO } from 'interface/user';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Index = () => {
  const location = useLocation();
  const [tabs, setTabs] = useState<TabDTO[]>();
  const [tabItems, setTabItems] = useState<{ titles?: string[]; containers: ReactNode[] }>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { useableTabs } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [
      //
      DRIVER_WHOLE,
      SELLER,
      CENTER_SELECT_WHOLE,
      CENTER_WHOLE,
      MODEL_GROUP,
      MODEL_TYPE,
      FEE_TYPE,
    ] = await Promise.all([
      driverListForLabeling(),
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling({ selectFlag: true }),
      centerListForlabeling(),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
      codeMasterOptionReturn('FEE_TYPE'),
    ]);

    dispatch(
      setMasterOptions({
        STATUS: [
          { value: 'AVAILABLE', label: '양품' },
          { value: 'DEFECTED', label: '불용' },
        ],
        DRIVER_WHOLE,
        DRIVER_NAME: DRIVER_WHOLE?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        SELLER: SELLER?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_AUTH: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        MODEL_GROUP,
        MODEL_TYPE,
        FEE_TYPE,
        BEFORE: [
          { value: 'beforeBarcode', label: '바코드' },
          { value: 'beforeModelStockSeq', label: 'SKU' },
          { value: 'beforeSkuNumber', label: '외부SKU' },
          { value: 'beforeModel', label: '제품' },
          { value: 'beforeModelName', label: '제품명' },
          { value: 'beforeSerialNumber', label: '시리얼번호' },
        ],
        AFTER: [
          { value: 'afterBarcode', label: '바코드' },
          { value: 'afterModelStockSeq', label: 'SKU' },
          { value: 'afterSkuNumber', label: '외부SKU' },
          { value: 'afterModel', label: '제품' },
          { value: 'afterModelName', label: '제품명' },
          { value: 'afterSerialNumber', label: '시리얼번호' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };
  const containerList = [{ container: <Tab1 tabId={'MODEL_MOVE_LIST'} />, tabId: 'MODEL_MOVE_LIST' }];

  useEffect(() => {
    if (tabs) {
      const tabIds = tabs?.map((ele) => ele.tabId);
      const tabLabeling = tabs?.map((ele) => {
        return {
          value: ele.tabId,
          label: ele.tabName,
        };
      });

      setTabItems({
        titles: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => selectlabel(ele.tabId, tabLabeling)),
        containers: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => ele.container),
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (useableTabs) {
      const tabDTO = useableTabs?.[location.pathname] ? [...useableTabs?.[location.pathname]] : [];
      if (tabDTO?.length > 0) {
        tabDTO?.sort((a: TabDTO, b: TabDTO) => a?.sort - b?.sort);
        setTabs(tabDTO);
        initMasterOptions();
      }
    }
  }, [useableTabs]);

  return <>{masterOptions && tabItems && <TopTab titles={tabItems?.titles} containers={tabItems?.containers} />}</>;
};

export default Index;
