import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging, { initialSearchObj } from 'hooks/grid/useGridPaging';
import { SearchBox } from './component/searchBox';
import {
  WarehouseReceiptInSearchDTO,
  WarehouseReceiptOutSearchDTO,
  WarehouseReceiptStorageSearchDTO,
  WarehouseReceiptInDTO,
  WarehouseReceiptOutDTO,
  WarehouseReceiptStorageDTO,
} from '../../_interface/warehouse';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { IReceipt, ReceiptPopup } from '../1receipt/components/receipt';
import { AdjustmentPopup, IAdjustmentObj } from '../1receipt/components/adjustment';
import { serviceStore } from 'services/services';
import { ITypeObj, Types } from './component/types';

export interface IFeeSearch {
  partnerSeq?: number;
  pageNo: number;
  pageSize: number;
}

export const urlConvertor = {
  ORDER_RECEIPT_IN_CENTER: 'in',
  ORDER_RECEIPT_OUT_CENTER: 'out',
  ORDER_RECEIPT_STORAGE_CENTER: 'storage',
};

export const seqConvertor = {
  ORDER_RECEIPT_IN_CENTER: 'warehouseReceiptInSeq',
  ORDER_RECEIPT_OUT_CENTER: 'warehouseReceiptOutSeq',
  ORDER_RECEIPT_STORAGE_CENTER: 'warehouseReceiptStorageSeq',
};

const Index = ({ tabId }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const [receiptObj, setReceiptObj] = useState<IReceipt>();
  const [adjustmentObj, setAdjustmentObj] = useState<IAdjustmentObj>();
  const [typeObj, setTypeObj] = useState<ITypeObj>();

  const popupType = (e: IGrid.ButtonClickEvent) => {
    setTypeObj({
      visible: true,
      data: e.item,
      tabId,
      searchObj: searchObj,
      fetchFn: wrappedFetchList,
    });
  };

  const inColumns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupType,
      },
    },
    {
      headerText: '영수증 상태값',
      dataField: 'statusKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '창고명',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '운송유형',
      dataField: 'logisticTypeKr',
    },
    {
      headerText: '작업유형',
      dataField: 'workTypeKr',
    },
    {
      headerText: '단가',
      dataField: 'unitPrice',
    },
    {
      headerText: '수량',
      dataField: 'quantity',
    },
    {
      headerText: '입고금액(A)',
      dataField: 'feeTax',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '조정금액(B)',
      dataField: 'adjustmentFee',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '재정산금액(C)',
      dataField: 'recalculateFee',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: 'A~C합계(D)',
      dataField: 'supplyValue',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '세액(E)',
      dataField: 'valueAddedTax',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '합계(D+E)',
      dataField: 'total',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '비고',
      dataField: 'remark',
    },
    {
      headerText: '발주 비고',
      dataField: 'purchaseRemark',
    },
    {
      headerText: '영수증 발행일',
      dataField: 'registerDate',
    },
  ];

  const outColumns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupType,
      },
    },
    {
      headerText: '영수증 상태값',
      dataField: 'statusKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '창고명',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '작업유형',
      dataField: 'workTypeKr',
    },
    {
      headerText: '파렛트유형',
      dataField: 'palletTypeKr',
    },
    {
      headerText: '단가',
      dataField: 'unitPrice',
    },
    {
      headerText: '수량',
      dataField: 'quantity',
    },
    {
      headerText: '출고금액(A)',
      dataField: 'feeTax',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '조정금액(B)',
      dataField: 'adjustmentFee',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '재정산금액(C)',
      dataField: 'recalculateFee',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: 'A~C합계(D)',
      dataField: 'supplyValue',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '세액(E)',
      dataField: 'valueAddedTax',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '합계(D+E)',
      dataField: 'total',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '비고',
      dataField: 'remark',
    },
    {
      headerText: '영수증 발행일',
      dataField: 'registerDate',
    },
  ];

  const storageColumns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupType,
      },
    },
    {
      headerText: '영수증 상태값',
      dataField: 'statusKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '창고명',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '보관유형',
      dataField: 'storageTypeKr',
    },
    {
      headerText: '적용단위',
      dataField: 'dateUnitKr',
    },
    {
      headerText: '적용범위',
      dataField: 'dateRange',
    },
    {
      headerText: '단가',
      dataField: 'unitPrice',
    },
    {
      headerText: '수량',
      dataField: 'quantity',
    },
    {
      headerText: '보관금액(A)',
      dataField: 'feeTax',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '조정금액(B)',
      dataField: 'adjustmentFee',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '재정산금액(C)',
      dataField: 'recalculateFee',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: 'A~C합계(D)',
      dataField: 'supplyValue',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '세액(E)',
      dataField: 'valueAddedTax',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '합계(D+E)',
      dataField: 'total',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '비고',
      dataField: 'remark',
    },
    {
      headerText: '영수증 발행일',
      dataField: 'registerDate',
    },
  ];

  const labellingKr = (data: PagingListDTO<WarehouseReceiptInDTO & WarehouseReceiptOutDTO & WarehouseReceiptStorageDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],

        logisticTypeKr: MASTER_OBJ?.LOGISTIC_TYPE_1?.[row.logisticType],
        workTypeKr: MASTER_OBJ?.WORK_TYPE_1?.[row.workType],
        // storageTypeKr: MASTER_OBJ?.STORAGE_TYPE?.[row.storageType],
        palletTypeKr: MASTER_OBJ?.PALLET_TYPE?.[row.palletType],
        dateUnitKr: MASTER_OBJ?.DATE_UNIT?.[row.dateUnit],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchTotal = async (searchObj: WarehouseReceiptInSearchDTO & WarehouseReceiptOutSearchDTO & WarehouseReceiptStorageSearchDTO) => {
    const total = (await serviceStore?.warehouseAction(`receipt/center/${urlConvertor[tabId]}/total`, 'GET', searchObj, null))?.data;
    return total;
  };

  const fetchPaging = async (searchObj: WarehouseReceiptInSearchDTO & WarehouseReceiptOutSearchDTO & WarehouseReceiptStorageSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore?.warehouseAction(`receipt/center/${urlConvertor[tabId]}/paging`, 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj,
    gridRef,
    fetchTotal,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: (await serviceStore?.warehouseAction(`fee/center/${urlConvertor[tabId]}/excel`, 'GET', searchObj))?.data }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `${tabId?.includes('_IN_') ? '입고영수증' : tabId?.includes('_OUT_') ? '출고영수증' : '보관영수증'}` });
    dispatch(setLoading(null));
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id?.includes('RECEIPTINOUTSTOREDOWNLOAD')) {
        setReceiptObj({
          visible: true,
          data: items,
        });
      } else if (id?.includes('RECEIPTINOUTSTOREADDADJUST')) {
        setAdjustmentObj((prev) => {
          return {
            ...prev,
            visible: true,
            fetchFn: (data) => wrappedFetchList(data),
            data: items?.map((ele) => ele.orderReceiptSeq),
          };
        });
      }
    } else {
      alert('선택된 건이 없습니다!');
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id?.includes('RECEIPTINOUTSTOREEXCELDOWN')) {
      downloadExcel('main');
    } else {
      handleCheckItems(id);
    }
  };

  useEffect(() => {
    if (tabId) {
      setSearchObj(initialSearchObj);
      gridRef?.current?.setGridData([]);
      excelGridRef?.current?.setGridData([]);
      gridRef?.current?.changeColumnLayout(tabId?.includes('_IN_') ? inColumns : tabId?.includes('_OUT_') ? outColumns : storageColumns);
      excelGridRef?.current?.changeColumnLayout(tabId?.includes('_IN_') ? inColumns : tabId?.includes('_OUT_') ? outColumns : storageColumns);
    }
  }, [tabId]);

  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj, tabId]);

  return (
    <div className="page-content">
      {typeObj?.visible && <Types typeObj={typeObj} setTypeObj={setTypeObj} />}
      {receiptObj?.visible && <ReceiptPopup receiptObj={receiptObj} setReceiptObj={setReceiptObj} />}
      {adjustmentObj?.visible && <AdjustmentPopup adjustmentObj={adjustmentObj} setAdjustmentObj={setAdjustmentObj} />}

      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} TARGET={tabId} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={inColumns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox //
          gridRef={excelGridRef}
          columns={inColumns}
        />
      </div>
    </div>
  );
};

export default Index;
