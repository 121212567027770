import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './common';

// options
import { RootState } from 'redux/store';
import { InvoiceDetailSearchDTO } from 'interface/order';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { getGridParameters } from 'common/master/codeMasterOptionReturn';
import { COMMON_GRIDID, nullingMASTER, RAWDATA } from 'redux/services/menuSlice';
import { DRIVER_LIST_MASTER } from 'pages/ETC/1driver/driverListTab';
export interface IDetailObjProps {
  visible: boolean;
  item?: InvoiceDetailSearchDTO;
}

export const ORDER_MASTER = [
  //
  ...DRIVER_LIST_MASTER,
  `${RAWDATA}DRIVER_WHOLE`,
  `${RAWDATA}DRIVER_AUTH`,
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  'DRIVER_PHONE_WHOLE', // 라벨용이 많으니
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'CENTER_CALL_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_NETWORK_AUTH',
  'SELLER_WHOLE',
  'INFRA',
  'SALES_CHANNEL',
  //
  'ORDER_TYPE',
  `${RAWDATA}ORDER_TYPE`,
  'INVOICE_STATUS',
  'INVOICE_STATUS_1',
  'INVOICE_STATUS_2',
  'FEE_TYPE',
  'CANCEL_REASON',
  'COLLECT_REASON',
  'SERVICE_TYPE',
  'MODEL_TYPE',
  'MODEL_GROUP',
  'RESCHEDULE_TYPE',
  'BODY_WIFI_REASON',
  'EOL_GROUP2',
  `${RAWDATA}EOL_GROUP2`,
  'EOL_GROUP3',
  'COUNSEL_DETAIL_TYPE',
  'COUNSEL_TYPE',
  'LADDER_CO',
  'MODEL_GRP_1',
  'MODEL_GRP_2',
  'MODEL_GRP_3',
  'CANCEL_TYPE',
  'MESSAGE_TYPE',
  'INVENTORY_LOCATION',
  `${RAWDATA}PROVINCE`,
  'CITY',
  'DRIVER_PARTY',
  'TEAM_GRADE',
  'DRV_POS',
  'CAR_TYPE',
  'DRV_TYPE',
  'TEAM_TYPE',
  'LOGI_TYPE',
  'PACKING_STATE',
  'INV_TRACK_TYPE',
  'PARCEL_PAY_TYPE',
  'INVOICE_UPDATE_CONTENT',
  'INSTALL_FORM',
  //
  'SEARCH_ORDER',
  'SEARCH_INVOICE',
  // 'SEARCH_MODEL',
  //
  'INSTALL_DURATION',
  'DELIVERY_TIME',
  'UNIFORM_SIZE',
  'WEEK_DAY',
  'OX_FLAG',
  'USE_FLAG',
  'LITE_FLAG',
  'FIX_DATE',
  {
    SEARCH_DATE: {
      ORDER_TAB: [
        {
          label: '고객주문일',
          value: 'OrderDate',
        },
        {
          label: '등록일',
          value: 'RegisterDate',
        },
        {
          label: '희망일',
          value: 'PromiseDeliveryDate',
        },
        {
          label: '지정일',
          value: 'DeliveryDate',
        },
        {
          label: '배송확정일',
          value: 'DeliveryConfirmDate',
        },
        {
          label: '완료일/취소일',
          value: 'FinishOrCancelDate',
        },
      ],
      ORDER_PROGRESS: [
        {
          label: '고객주문일',
          value: 'OrderDate',
        },
        {
          label: '등록일',
          value: 'RegisterDate',
        },
        {
          label: '희망일',
          value: 'PromiseDeliveryDate',
        },
        {
          label: '지정일',
          value: 'DeliveryDate',
        },
        {
          label: '배송확정일',
          value: 'DeliveryConfirmDate',
        },
      ],
      ORDER_FINISHORCANCEL: [
        {
          label: '고객주문일',
          value: 'OrderDate',
        },
        {
          label: '등록일',
          value: 'RegisterDate',
        },
        {
          label: '희망일',
          value: 'PromiseDeliveryDate',
        },
        {
          label: '지정일',
          value: 'DeliveryDate',
        },
        {
          label: '배송확정일',
          value: 'DeliveryConfirmDate',
        },
        {
          label: '완료일/취소일',
          value: 'FinishOrCancelDate',
        },
      ],
    },
  },
  {
    PMODEL_TYPE: [
      { value: 'MODEL', label: '구성품' },
      { value: 'GIFT', label: '사은품' },
    ],
  },
  {
    SEARCH_PRODUCT: [
      { value: 'productName', label: '상품명' },
      { value: 'productSeq', label: '상품번호' },
      { value: 'externalProductNumber', label: '외부상품번호' },
    ],
  },
  // 택배운송장
  'PACKING_STATE',
  'FLOOR', //양중층수
  {
    SEARCH_MODEL: [
      { value: 'model', label: '제품' },
      { value: 'modelName', label: '제품명' },
      { value: 'barcode', label: '바코드' },
      { value: 'modelStockSeq', label: 'SKU' },
      { value: 'skuNumber', label: '외부SKU' },
      { value: 'productSeq', label: '상품번호' },
      { value: 'externalProductNumber', label: '외부상품번호' },
    ],
  },
  {
    SEARCH_NAME: [
      { value: 'receiverName', label: '실수령자' },
      { value: 'customerName', label: '주문자' },
    ],
  },
  {
    SEARCH_ADDRESS: [
      { value: 'receiverAddr', label: '실수령자' },
      { value: 'customerAddr', label: '주문자' },
    ],
  },
  {
    SEARCH_CONTACT: [
      { value: 'receiverPhone', label: '실수령자' },
      { value: 'customerPhone', label: '주문자' },
    ],
  },
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions([...ORDER_MASTER, { [COMMON_GRIDID + 'MANAGEORDERINFOLIST1_MAIN']: await getGridParameters('MANAGEORDERINFOLIST1_MAIN') }]);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            //
            ORDER_TAB: <Tab1 tabId={'ORDER_TAB'} />,
            ORDER_PROGRESS: <Tab1 tabId={'ORDER_PROGRESS'} />,
            ORDER_FINISHORCANCEL: <Tab1 tabId={'ORDER_FINISHORCANCEL'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
