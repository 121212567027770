import { MainContentsWrapper, MainPageTemplate } from 'components/LDS/pages/main';
import { mainUrl } from 'components/LDS/utils/path';
import { routingTabElementsDynamic } from './routingElements';
import { Outlet, useLocation } from 'react-router-dom';
import {
  BarcodePrinting,
  CargoManifestAccidentList,
  CargoManifestTransferList,
  CargoManifestList,
  CenterLocation,
  CollectReceiving,
  CustomAlertmsg,
  CustomerCs,
  DailyClosing,
  Dashboard,
  DeliveryOrder,
  DeliverySector,
  DistributingCar,
  DriverCs,
  DriverDeliveryClosing,
  DriverList,
  Fcpicking,
  FcpickingRegit,
  Headquarters,
  InfraGroupList,
  InuserList,
  InventoryInOutList,
  InventoryInvestigate,
  WarehouseList,
  InventoryLocation,
  InventoryLocationSelect,
  InventoryReports,
  InventoryTracking,
  Kpibycapa,
  Kpis,
  ManageDeliverySchedules,
  ManageLazyorderList,
  ManageOrderHistory,
  ManageorderInfoList,
  ManageorderRegister,
  ManageParcelAccident,
  ManageParcelFeeChangeFare,
  ManageParcelFeeList,
  ManageParcelFeeLoan,
  ManageParcelLinehaulList,
  ManageParcelList,
  ManageParcelLoadList,
  ManageParcelNumber,
  ManageParcelNumberPrint,
  ManageParcelRemain,
  ManageParcelRevisit,
  ManageParcelServiceList,
  ManageParcelTaskList,
  ManageParcelTrackingReport,
  ManageRelocationorderInfoList,
  ManagerLD,
  ManageService,
  ManageUserInGroup,
  MessageSendManange,
  ModelList,
  ModelMoveList,
  ModificationAvaDefRegit,
  Modifications,
  MoveActionList,
  OrderReceiptheadquaters,
  OrderUploadForm,
  PartnerList,
  Payment,
  PlaceOrderRegister,
  ProductList,
  PurchaseOrderAdmin,
  PurchaseIn,
  PurchaseRegit,
  Refur,
  SalesChannel,
  ScmManagement,
  StockRemoveParcel,
  SystemAnnouncement,
  TakeOutPickingInstructionRegister,
  TakeoutRegit,
  TakeoutWorks,
  TransactionStatheadquaters,
  TrunkReceiving,
  TrunkRegister,
  TrunkRegit,
  TrunkWorks,
  WasteAppliances,
  PickingStrategy,
  Settings,
  SettingLists,
  StoreTakeOutPickingInstructionRegister,
  InventoryRegister,
  MoveActionRegit,
  InventoryInspect,
  SsRegit,
  SsList,
  DeliveryWorks,
  // TransactionRegister,
} from './masterOptionWrapper';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setMasterOptions } from 'redux/services/menuSlice';
import styled from 'styled-components';
import { COLORS, VALUES } from 'common/constants/appearance';
import { clearTab } from 'redux/services/tabSlice';

type PathNamesProp = {
  path?: string;
  name?: string;
  masterOptions?: string[];
}[];

// NOTE: 모든 "소분류" 메뉴의 path (url)과 메뉴명(name)을 배열 형태로 선언 했었으나 sideBar에서 받아온 API 응답값 fetchMenus를 로컬 스토리지에 저장하고 dynamic route 메뉴로 활용
export function generateRoutingPathMap() {
  const flatMenus = JSON.parse(localStorage.getItem('flatMenus'));
  const routingPaths: PathNamesProp = flatMenus?.map((menu) => {
    return {
      path: menu?.url,
      name: menu?.menuName,
      // masterOptions: MASTEROPTIONS_FOR_URL?.[removePrefixPath(menu?.url)], 추후 적용
    };
  });

  // NOTE: pathNames과 routingTabElements를 토대로 mainRoutingPathMap을 생성하여 export
  const mainRoutingPathMap = routingPaths
    // .filter((pathObj, index) => index < 81) // tab element 맵이 더 추가되면 필터 로직 제거 필요 - 모든 tab의 description 또는 신규 필드(filePath?)를 추가하는 것이 선행되어야 함.
    ?.map((pathObj: { path: string; name: string; masterOptions?: string[] }) => {
      return {
        path: `${mainUrl}${pathObj?.path}`,
        element: <MainPageTemplate $title={pathObj?.name} $path={pathObj?.path} $masterOptions={pathObj?.masterOptions} />,
        children: routingTabElementsDynamic?.[pathObj?.path]?.(pathObj?.path) || [],
      };
    });

  return mainRoutingPathMap;
}

export function MasterOptionWrapper() {
  const location = useLocation();
  const dispatch = useDispatch();

  const screenUrl = location.pathname.split('/')?.[2];
  const tabUrl = location.pathname.split('/')?.[3];

  useEffect(() => {
    dispatch(setMasterOptions(null));
    dispatch(clearTab());
    localStorage.setItem('searchObj', null);
  }, [screenUrl]);

  // refurRegister,moveActionRegit 등록 화면에서 사용하는 마스터옵션은 따로 정의하지 않음 (화면과 동일)
  // purchaseRegit 화면(주문조회 > 발주요청)이 PurchaseOrderAdmin 화면의 마스터옵션과 다름
  return (
    <MasterOptionContentsWrapper>
      {screenUrlPathIntoComponent?.[screenUrl]?.(tabUrl)}
      <Outlet />
    </MasterOptionContentsWrapper>
  );
}

const MasterOptionContentsWrapper = styled(MainContentsWrapper)`
  padding: 0;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.BLACK_008};
  border-radius: ${VALUES.RADIUS * 2}px 0 0 ${VALUES.RADIUS * 2}px;
  box-shadow: -1px 0 3px 0 ${COLORS.BLACK_008};
`;

const screenUrlPathIntoComponent = {
  dashboard: () => <Dashboard />,
  cargoManifestTransferList: () => <CargoManifestTransferList />,
  cargoManifestList: () => <CargoManifestList />,
  cargoManifestAccidentList: () => <CargoManifestAccidentList />,
  manageParcelTaskList: () => <ManageParcelTaskList />,
  manageParcelServiceList: () => <ManageParcelServiceList />,
  manageParcelAccident: () => <ManageParcelAccident />,
  manageParcelRevisit: () => <ManageParcelRevisit />,
  manageParcelLinehaulList: () => <ManageParcelLinehaulList />,
  manageParcelLoadList: () => <ManageParcelLoadList />,
  manageParcelUnloadList: () => <ManageParcelLoadList />, //동일 컴포넌트 사용
  manageParcelRemain: () => <ManageParcelRemain />,
  manageParcelNumber: () => <ManageParcelNumber />,
  manageParcelNumberPrint: () => <ManageParcelNumberPrint />,
  manageParcelFeeList: () => <ManageParcelFeeList />,
  manageParcelFeeLoan: () => <ManageParcelFeeLoan />,
  manageParcelFeeChangeFare: () => <ManageParcelFeeChangeFare />,
  manageParcelTrackingReport: () => <ManageParcelTrackingReport />,
  purchaseOrderAdmin: () => <PurchaseOrderAdmin />,
  purchaseIn: () => <PurchaseIn />, //
  placeOrderRegister: () => <PlaceOrderRegister />,
  trunkReceiving: () => <TrunkReceiving />,
  collectReceiving: () => <CollectReceiving />,
  inventoryLocationSelect: () => <InventoryLocationSelect />,
  trunkWorks: (tabUrl) => (tabUrl === 'trunkRegister' ? <TrunkRegister /> : tabUrl === 'trunkRegit' ? <TrunkRegit /> : <TrunkWorks />),
  takeoutWorks: (tabUrl) =>
    tabUrl === 'TakeOutPickingInstructionRegister' ? (
      <TakeOutPickingInstructionRegister />
    ) : tabUrl === 'takeoutRegit' ? (
      <TakeoutRegit />
    ) : tabUrl === 'storeTakeOutPickingInstructionRegister' ? (
      <StoreTakeOutPickingInstructionRegister />
    ) : (
      <TakeoutWorks />
    ),
  deliveryWorks: () => <DeliveryWorks />, //
  stockRemoveParcel: () => <StockRemoveParcel />,
  wasteAppliances: () => <WasteAppliances />,
  fcpicking: (tabUrl) => (tabUrl === 'createfcpicking' ? <FcpickingRegit /> : <Fcpicking />),
  deliveryOrder: () => <DeliveryOrder />,
  distributingCar: () => <DistributingCar />,
  inventoryReports: () => <InventoryReports />,
  modifications: (tabUrl) => (tabUrl === 'modificationAvaRegit' || tabUrl === 'modificationDefRegit' ? <ModificationAvaDefRegit /> : <Modifications />),
  inventoryInvestigate: () => <InventoryInvestigate />,
  inventoryTracking: () => <InventoryTracking />,
  inventoryInspect: () => <InventoryInspect />,
  refur: (tabUrl) => (tabUrl === 'refurRegister' || tabUrl === 'refurRegit' ? <InventoryRegister /> : <Refur />),
  modelMoveList: (tabUrl) => (tabUrl === 'modelMoveRegit' ? <InventoryRegister /> : <ModelMoveList />),
  scmManagement: () => <ScmManagement />,
  inventoryInOutList: () => <InventoryInOutList />,
  inventoryLocation: () => <InventoryLocation />,
  moveActionList: (tabUrl) => (tabUrl === 'moveActionRegit' ? <MoveActionRegit /> : <MoveActionList />),
  modelList: () => <ModelList />,
  productList: () => <ProductList />,
  barcodePrinting: () => <BarcodePrinting />,
  OrderUploadForm: () => <OrderUploadForm />,
  manageorderInfoList: (tabUrl) => (tabUrl === 'purchaseRegit' ? <PurchaseRegit /> : <ManageorderInfoList />),
  manageParcelList: () => <ManageParcelList />,
  manageOrderHistory: () => <ManageOrderHistory />,
  manageService: () => <ManageService />,
  payment: () => <Payment />,
  manageRelocationorderInfoList: () => <ManageRelocationorderInfoList />,
  manageorderRegister: () => <ManageorderRegister />,
  // 판매
  ssList: () => <SsList />,
  ssRegit: () => <SsRegit />,
  MessageSendManange: () => <MessageSendManange />,
  customerCs: () => <CustomerCs />,
  driverCs: () => <DriverCs />,
  systemAnnouncement: () => <SystemAnnouncement />,
  customAlertmsg: () => <CustomAlertmsg />,
  manageLazyorderList: () => <ManageLazyorderList />,
  manageDeliverySchedules: () => <ManageDeliverySchedules />,
  dailyClosing: () => <DailyClosing />,
  driverDeliveryClosing: () => <DriverDeliveryClosing />,
  kpis: () => <Kpis />,
  kpibycapa: () => <Kpibycapa />,
  headquarters: () => <Headquarters />,
  subcontract: () => <Headquarters />,
  center: () => <Headquarters />,
  driver: () => <Headquarters />,
  OrderReceiptheadquaters: () => <OrderReceiptheadquaters />,
  OrderReceiptSubcontract: () => <OrderReceiptheadquaters />,
  OrderReceiptCenter: () => <OrderReceiptheadquaters />,
  OrderReceiptDriverteam: () => <OrderReceiptheadquaters />,
  transactionStatheadquaters: () => <TransactionStatheadquaters />,
  transactionStatSubcontract: () => <TransactionStatheadquaters />,
  transactionStatCenter: () => <TransactionStatheadquaters />,
  transactionStatDriverteam: () => <TransactionStatheadquaters />,
  driverList: () => <DriverList />,
  deliverySector: () => <DeliverySector />,
  inventoryList: () => <WarehouseList />,
  centerLocation: () => <CenterLocation />,
  PickingStrategy: () => <PickingStrategy />,
  salesChannel: () => <SalesChannel />,
  inuserList: () => <InuserList />,
  partnerList: () => <PartnerList />,
  infraGroupList: () => <InfraGroupList />,
  managerLD: () => <ManagerLD />,
  manageUserInGroup: () => <ManageUserInGroup />,

  // 설정

  settings: () => <Settings />,
  settingList: () => <SettingLists />,
};
