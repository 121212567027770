import { OptionItem } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { SelectD } from 'components/reactstrap/reactstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { setLoading } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { ParcelInvoiceSearchDTO } from '../../../_interface/parcel';
import { serviceStore } from 'services/services';

export interface IParcelActionObj {
  visible?: boolean;
  type?: 'accident' | 'revisit' | 'APIcall';
  searchObj?: ParcelInvoiceSearchDTO;
  items?: string[];
  fetchFn?: (data) => {};
}

export const ParcelActionPopup = ({ parcelActionObj, setParcelActionObj }) => {
  const dispatch = useDispatch();
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [createObj, setCreateObj] = useState<{ [key: string]: any }>();
  console.log(reduxUserInfo);

  useEffect(() => {
    if (parcelActionObj?.type === 'APIcall') {
      setCreateObj({
        networkPartnerSeq: !reduxUserInfo?.sub?.includes('LD') ? reduxUserInfo?.partner : null,
        partnerSeq: [],
        portCode: [],
      });
    }
  }, []);

  const validation = (createObj) => {
    if (parcelActionObj?.type === 'accident') {
      if (!createObj?.type) {
        alert('사고유형은 필수값입니다!');
        return false;
      }
      if (!createObj?.detail) {
        alert('사고상세는 필수값입니다!');
        return false;
      }
      if (!createObj?.item) {
        alert('사고품목은 필수값입니다!');
        return false;
      }
      if (!createObj?.interiorPackage) {
        alert('내부포장은 필수값입니다!');
        return false;
      }
      if (!createObj?.exteriorPackage) {
        alert('외부포장은 필수값입니다!');
        return false;
      }
    } else if (parcelActionObj?.type === 'revisit') {
      if (!createObj?.revisitReason) {
        alert('미배송 사유는 필수값입니다!');
        return false;
      }
    } else if (parcelActionObj?.type === 'APIcall') {
      if (createObj?.partnerSeq?.length === 0) {
        alert('판매사(포워더)는 필수값입니다!');
        return false;
      }
      if (createObj?.portCode?.length === 0) {
        alert('양륙항은 필수값입니다!');
        return false;
      }
    }

    return true;
  };
  const callExAPI = async () => {
    if (window.confirm('외부 API를 등록하시겠습니까?')) {
      dispatch(setLoading('POST'));

      const rs = await serviceStore?.parcelAction(`invoice/create/api`, 'POST', null, createObj);
      if (rs?.status === 200) {
        alert('등록되었습니다!');
        if (parcelActionObj?.fetchFn && parcelActionObj?.searchObj) parcelActionObj?.fetchFn(parcelActionObj?.searchObj);
        setParcelActionObj(null);
      }
      dispatch(setLoading(null));
    }
  };

  const parcelActionAPI = async ({ createObj, items }) => {
    const data = items?.map((ele) => {
      return {
        seq: ele.parcelInvoiceSeq,
      };
    });

    dispatch(setLoading('PUT'));
    const rs = await serviceStore.parcelAction(
      `invoice/${parcelActionObj?.type === 'accident' ? 'accident' : 'revisit'}`,
      'POST',
      parcelActionObj?.type === 'accident'
        ? {
            type: createObj?.type,
            detail: createObj?.detail,
            item: createObj?.item,
            interiorPackage: createObj?.interiorPackage,
            exteriorPackage: createObj?.exteriorPackage,
          }
        : {
            revisitReason: createObj?.revisitReason,
          },
      data,
    );

    if (rs?.status === 200) {
      alert(parcelActionObj?.type === 'accident' ? '사고접수' : '미배송' + ' 완료 되었습니다!');
      if (parcelActionObj?.fetchFn && parcelActionObj?.searchObj) parcelActionObj?.fetchFn(parcelActionObj?.searchObj);
      setParcelActionObj(null);
    }
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title={parcelActionObj?.type === 'accident' ? '사고접수' : parcelActionObj?.type === 'revisit' ? '미배송' : 'API호출'}
      visible={parcelActionObj?.visible}
      style={{ width: 350 }}
      setVisible={() => {
        setParcelActionObj(null);
      }}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={() => {
              if (validation(createObj)) {
                if (parcelActionObj?.type !== 'APIcall') parcelActionAPI({ createObj, items: parcelActionObj?.items });
                else callExAPI();
              }
            }}
          >
            접수
          </button>
        </>
      }
      children={
        <>
          <Row style={{ flexDirection: 'column', marginBottom: 10 }}>
            {parcelActionObj?.type === 'accident' && (
              <>
                <Col>
                  <label className="col-form-label">*사고유형</label>
                  <SelectD
                    value={
                      createObj?.type
                        ? {
                            value: createObj?.type,
                            label: MASTER_OBJ?.ACCIDENT_TYPE?.[createObj?.type],
                          }
                        : null
                    }
                    options={MASTER_OPS?.ACCIDENT_TYPE}
                    onChange={(option) => {
                      setCreateObj({
                        ...createObj,
                        type: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">*사고상세</label>
                  <SelectD
                    value={
                      createObj?.detail
                        ? {
                            value: createObj?.detail,
                            label: MASTER_OBJ?.ACCIDENT_DETAIL?.[createObj?.detail],
                          }
                        : null
                    }
                    options={MASTER_OPS?.ACCIDENT_DETAIL}
                    onChange={(option) => {
                      setCreateObj({
                        ...createObj,
                        detail: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">*사고품목</label>
                  <SelectD
                    value={
                      createObj?.item
                        ? {
                            value: createObj?.item,
                            label: MASTER_OBJ?.ACCIDENT_ITEM?.[createObj?.item],
                          }
                        : null
                    }
                    options={MASTER_OPS?.ACCIDENT_ITEM}
                    onChange={(option) => {
                      setCreateObj({
                        ...createObj,
                        item: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">*내부포장</label>
                  <SelectD
                    value={
                      createObj?.interiorPackage
                        ? {
                            value: createObj?.interiorPackage,
                            label: MASTER_OBJ?.INTERIOR_PACKAGE?.[createObj?.interiorPackage],
                          }
                        : null
                    }
                    options={MASTER_OPS?.INTERIOR_PACKAGE}
                    onChange={(option) => {
                      setCreateObj({
                        ...createObj,
                        interiorPackage: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">*외부포장</label>
                  <SelectD
                    value={
                      createObj?.exteriorPackage
                        ? {
                            value: createObj?.exteriorPackage,
                            label: MASTER_OBJ?.EXTERIOR_PACKAGE?.[createObj?.exteriorPackage],
                          }
                        : null
                    }
                    options={MASTER_OPS?.EXTERIOR_PACKAGE}
                    onChange={(option) => {
                      setCreateObj({
                        ...createObj,
                        exteriorPackage: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
              </>
            )}
            {parcelActionObj?.type === 'revisit' && (
              <>
                <Col>
                  <label className="col-form-label">*미배송사유</label>
                  <SelectD
                    value={
                      createObj?.revisitReason
                        ? {
                            value: createObj?.revisitReason,
                            label: MASTER_OBJ?.PARCEL_REVISIT_REASON?.[createObj?.revisitReason],
                          }
                        : null
                    }
                    options={MASTER_OPS?.PARCEL_REVISIT_REASON}
                    onChange={(option) => {
                      setCreateObj({
                        ...createObj,
                        revisitReason: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
              </>
            )}
            {parcelActionObj?.type === 'APIcall' && (
              <>
                <Col>
                  <label className="col-form-label">*판매사(포워더)</label>
                  <SelectD
                    isMulti
                    options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
                    height50
                    value={createObj?.partnerSeq?.map((ele) => {
                      return {
                        value: ele,
                        label: MASTER_OBJ?.SELLER_FORWARDER_AUTH?.[ele],
                      };
                    })}
                    onChange={(option) => {
                      setCreateObj({
                        ...createObj,
                        partnerSeq: (option as OptionItem[])?.map((ele) => ele.value),
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">*양륙항</label>
                  <SelectD
                    isMulti
                    options={MASTER_OPS?.PORT_CODE}
                    height50
                    value={createObj?.portCode?.map((ele) => {
                      return {
                        value: ele,
                        label: MASTER_OBJ?.PORT_CODE?.[ele],
                      };
                    })}
                    onChange={(option) => {
                      setCreateObj({
                        ...createObj,
                        portCode: (option as OptionItem[])?.map((ele) => ele.value),
                      });
                    }}
                  />
                  <label className="col-form-label">*위탁사</label>
                  <SelectD
                    isDisabled={!reduxUserInfo?.sub?.includes('LD')}
                    options={MASTER_OPS?.SELLER_NETWORK_AUTH}
                    placeholder={'전체선택'}
                    nullLabeled={'전체선택'}
                    value={
                      createObj?.networkPartnerSeq
                        ? {
                            value: createObj?.networkPartnerSeq,
                            label: MASTER_OBJ?.SELLER_NETWORK_AUTH?.[createObj?.networkPartnerSeq],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setCreateObj({
                        ...createObj,
                        networkPartnerSeq: (option as OptionItem)?.value,
                      });
                    }}
                  />
                </Col>
              </>
            )}
          </Row>
        </>
      }
    />
  );
};
