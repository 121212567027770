// react
import { useEffect, useState, useRef, act } from 'react';
// redux
import * as IGrid from 'aui-grid';
import { useDispatch, useSelector } from 'react-redux';
import GridBox, { defaultGridProps } from 'common/grid/gridBox';
import CommonModalNew from 'components/modal/commonModalNew';
import { WMS_REMOVE_PICKING_DETAIL_INFO } from 'envVar';
import { httpClient } from 'common/http-client/axiosConfig';
import { RootState } from 'redux/store';
import AUIGrid from 'modules/Grid';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { serviceStore } from 'services/services';
import { useFrontSearchData } from 'hooks/grid/useFrontSearchData';
import Divider from 'components/LDS/atoms/Divider';
import { Col, Form, Row } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { actionParamsProp } from 'pages/OUT/1trunk/gansunWorksTab/picking/haldang';
import { StockRemoveCancelDTO, StockRemoveDTOForPicking } from 'interface/warehouse';
import { resetAllSelections, toggleBooleanOptions } from 'common/util/searchBox';
import { setLoading } from 'redux/services/menuSlice';

export const HaldangModal = (props) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { haldangObj, setHaldangObj } = props;
  const [actionParams, setActionParams] = useState<actionParamsProp & StockRemoveCancelDTO & StockRemoveDTOForPicking>();
  const gridRef = useRef<AUIGrid>();
  const dispatch = useDispatch();

  const columns: IGrid.Column[] = [
    {
      headerText: '주문취소여부',
      dataField: 'orderCancelFlagKr',
    },
    {
      headerText: '재고번호',
      dataField: 'inventorySeq',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '재고유형', // 기존 : 제품유형
      dataField: 'availableTypeKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },

    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '고객명',
      dataField: 'customerName',
    },
    {
      headerText: '고객주소',
      dataField: 'customerAddr',
    },
    {
      headerText: '연락처',
      dataField: 'customerPhone',
    },
    {
      headerText: '권역정보',
      dataField: 'zipcodeGroupInfo',
    },
    {
      headerText: '도크코드',
      dataField: 'dockNumber',
    },
    {
      headerText: '고객주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '비고',
      dataField: 'memo',
    },
  ];

  const convertKeyValue = {
    customerAddr: (row) => (row.customerAddr1 ? `${row.customerAddr1} ${row.customerAddr2 || ''}` : ''),
    centerCodeKr: (row) => selectlabel(row.centerCode, masterOptions?.CENTER),
    originalCenterCodeKr: (row) => selectlabel(row.originalCenterCode, masterOptions?.CENTER),
    destinationCenterCodeKr: (row) => selectlabel(row.destinationCenterCode, masterOptions?.CENTER),
    driverCenterCodeKr: (row) => selectlabel(row.driverCenterCode, masterOptions?.CENTER),
    partnerSeqKr: (row) => selectlabel(row.partnerSeq, masterOptions?.SELLER),
    modelGroupKr: (row) => selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
    availableTypeKr: (row) => selectlabel(row.availableType, masterOptions?.INVA_TYPE),
    unloadFlagKr: (row) => (row.unloadFlag === true ? 'O' : 'X'),
    orderCancelFlagKr: (row) => (row.orderCancelFlag === true ? 'O' : 'X'),
    pickFlagKr: (row) => (row.pickFlag === true ? 'O' : 'X'),
    loadFlagKr: (row) => (row.loadFlag === true ? 'O' : 'X'),
  };
  const detailSelectType = { INVENTORY_SEQ: null, ORDER_SEQ: null, SEARCH_MODEL: null };

  const { setGridData, setDetailSelectTypeObj, handleSubmitFiltering, detailSelectTypeObj, detailSearchObj, setDetailSearchObj, convertFieldValue, returnKeyValues, resetFilters } = useFrontSearchData(
    {
      gridRef,
      initDetailSearchObj: {},
      detailSelectType,
      convertKeyValue,
    },
  );

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.INVENTORY_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.INVENTORY_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.ORDER_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, detailSearchObj));
  }, [detailSelectTypeObj?.SEARCH_MODEL]);

  // grid-function
  useEffect(() => {
    fetchList(haldangObj?.data);
  }, [haldangObj.data]);

  const fetchList = async (item) => {
    dispatch(setLoading('GET'));

    const searchDTO = {
      loadingOutSeq: item.loadingOutSeq,
    };

    // NOTE: 액션 버튼 api 호출 시 필요한 parameter들을 정의
    setActionParams({
      linehaulSeq: item?.linehaulSeq,
      loadingStatementSeq: item?.loadingStatementSeq,
      loadingOutSeq: item?.loadingOutSeq,
      transportStatementSeq: item?.transportStatementSeq,
      originalCenterCode: item?.originalCenterCode,
      destinationCenterCode: item?.destinationCenterCode,
      linehaulDate: item?.linehaulDate,
      linehaulType: item?.linehaulType,
      status: item?.status,
      stockRemoveSeq: item?.stockRemoveSeq,
      centerCode: item?.centerCode,
      target: item?.target,
      partnerSeq: item?.partnerSeq,
      stockRemoveDate: item?.stockRemoveDate,
      stockRemoveType: item?.stockRemoveType,
      holdType: item?.holdType,
    });

    const rs = await serviceStore?.warehouseAction(`remove/loading/out/info`, 'GET', searchDTO);

    if (rs.status === 200) {
      const result = rs.data;
      setGridData(rs.data);
      const dataKr = result.map((row) => {
        let keyValues = returnKeyValues(convertKeyValue, row);

        return {
          ...row,
          ...keyValues,
        };
      });

      gridRef?.current?.setGridData(dataKr);
      dispatch(setLoading(null));
    }
  };

  const handleClick = (e) => {
    const id = e.target.id;
    const items = gridRef.current.getCheckedRowItemsAll();

    if (items.length === 0 && !id.includes('excel')) {
      alert('선택된 항목이 존재하지 않습니다.');
      return;
    }

    if (id.includes('excel')) {
      gridRef.current.exportAsXlsx({ fileName: '반출출차지시_할당정보' });
      return;
    }

    const paramKeys = Object.keys(actionParams);
    for (let param of paramKeys) {
      items[0][param] = actionParams[param];
    }

    if (id === 'rollback') {
      if (actionParams?.status !== 'INIT' && actionParams?.status !== 'OUT') {
        alert('출차지시대기, 출차확정 상태일 때만 가능합니다!');
        return;
      }
    }
    if (id === 'cancel') {
      if (actionParams?.status !== 'INIT') {
        alert('출차지시대기 상태일 때만 가능합니다!');
        return;
      }
    }

    haldangObj[id](items, true);
  };

  return (
    <>
      <CommonModalNew
        style={{ width: 1400 }}
        title={'반출출차 할당정보'}
        subTitle={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <strong>출고지시번호</strong> : {haldangObj?.data?.loadingStatementSeq || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <strong>운송번호</strong> : {haldangObj?.data?.transportStatementSeq || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <strong>반출창고</strong> : {masterOptions?.CENTER_OBJ[haldangObj?.data.centerCode] || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <strong>반출대상</strong> : {haldangObj.data.target || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <strong>판매사</strong> : {masterOptions?.SELLER_WHOLE_OBJ[haldangObj.data.partnerSeq] || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <strong>진행상태</strong> : {masterOptions?.LOADING_OUT_STAT_OBJ[haldangObj.data.status] || '-'}
          </div>
        }
        visible={haldangObj?.visible}
        setVisible={() => {
          setHaldangObj(null);
        }}
        children={
          <>
            <Form onSubmit={handleSubmitFiltering}>
              <Row>
                <Col>
                  <label className="col-form-label">재고번호/시리얼번호</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={4} style={{ display: 'inline-block' }}>
                      <SelectD
                        options={masterOptions?.INVENTORY_SEQ}
                        value={
                          detailSelectTypeObj?.INVENTORY_SEQ
                            ? {
                                label: selectlabel(detailSelectTypeObj?.INVENTORY_SEQ, masterOptions?.INVENTORY_SEQ),
                                value: detailSelectTypeObj?.INVENTORY_SEQ,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setDetailSelectTypeObj({
                            ...detailSelectTypeObj,
                            INVENTORY_SEQ: (option as OptionItem).value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={8} style={{ display: 'inline-block' }}>
                      <InputD
                        disabled={!detailSelectTypeObj?.INVENTORY_SEQ}
                        value={detailSearchObj?.[detailSelectTypeObj?.INVENTORY_SEQ] || ''}
                        onChange={(e) => {
                          setDetailSearchObj({
                            ...detailSearchObj,
                            [detailSelectTypeObj?.INVENTORY_SEQ]: convertFieldValue(detailSelectTypeObj['INVENTORY_SEQ'], e.target.value),
                          });
                        }}
                      />
                    </Col>
                  </div>
                </Col>
                <Col>
                  <label className="col-form-label">주문번호/송장번호</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={4} style={{ display: 'inline-block' }}>
                      <SelectD
                        options={masterOptions?.ORDER_SEQ}
                        value={
                          detailSelectTypeObj?.ORDER_SEQ
                            ? {
                                label: selectlabel(detailSelectTypeObj?.ORDER_SEQ, masterOptions?.ORDER_SEQ),
                                value: detailSelectTypeObj?.ORDER_SEQ,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setDetailSelectTypeObj({
                            ...detailSelectTypeObj,
                            ORDER_SEQ: (option as OptionItem).value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={8} style={{ display: 'inline-block' }}>
                      <InputD
                        disabled={!detailSelectTypeObj?.ORDER_SEQ}
                        value={detailSearchObj?.[detailSelectTypeObj?.ORDER_SEQ] || ''}
                        onChange={(e) => {
                          setDetailSearchObj({
                            ...detailSearchObj,
                            [detailSelectTypeObj?.ORDER_SEQ]: convertFieldValue(detailSelectTypeObj['ORDER_SEQ'], e.target.value),
                          });
                        }}
                      />
                    </Col>
                  </div>
                </Col>
                <Col>
                  <label className="col-form-label">제품/제품명</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={4} style={{ display: 'inline-block' }}>
                      <SelectD
                        options={masterOptions?.SEARCH_MODEL}
                        value={
                          detailSelectTypeObj?.SEARCH_MODEL
                            ? {
                                label: selectlabel(detailSelectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                                value: detailSelectTypeObj?.SEARCH_MODEL,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setDetailSelectTypeObj({
                            ...detailSelectTypeObj,
                            SEARCH_MODEL: (option as OptionItem).value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={8} style={{ display: 'inline-block' }}>
                      <InputD
                        disabled={!detailSelectTypeObj?.SEARCH_MODEL}
                        value={detailSearchObj?.[detailSelectTypeObj?.SEARCH_MODEL] || ''}
                        onChange={(e) => {
                          setDetailSearchObj({
                            ...detailSearchObj,
                            [detailSelectTypeObj?.SEARCH_MODEL]: convertFieldValue(detailSelectTypeObj['SEARCH_MODEL'], e.target.value),
                          });
                        }}
                      />
                    </Col>
                  </div>
                </Col>

                <Col>
                  <label className="col-form-label">주문취소여부</label>
                  <div
                    className="radio-select-area"
                    onClick={(e) => {
                      toggleBooleanOptions('orderCancelFlag', (e.target as HTMLDivElement).dataset.flagId, detailSearchObj, setDetailSearchObj);
                    }}
                  >
                    <span className={detailSearchObj?.orderCancelFlag === true ? 'active' : ''} data-flag-id="true">
                      O
                    </span>
                    <span className={detailSearchObj?.orderCancelFlag === false ? 'active' : ''} data-flag-id="false">
                      X
                    </span>
                  </div>
                </Col>
                <Col />
                <Col style={{ textAlign: 'right', placeSelf: 'flex-end', display: 'flex', gap: '4px' }}>
                  <button type="button" className="btn" style={{ border: '1px solid #DADADA', minWidth: '80px' }} onClick={resetFilters}>
                    초기화
                  </button>
                  <button type="submit" className="btn btn-search" style={{ flexGrow: 1 }}>
                    조회
                  </button>
                </Col>
              </Row>
            </Form>
            <Divider $direction={'H'} $color={'#eaeaea'} $spacing={'LG'} $value={'FULL'} />
            <div className="grid-button-area space-between">
              <div>
                <div className="red" id="rollback" onClick={handleClick}>
                  이전단계
                </div>
                <div className="red" id="cancel" onClick={handleClick}>
                  출차취소
                </div>

                <div className="green" id="excel" onClick={handleClick}>
                  엑셀다운
                </div>
              </div>
            </div>
            <GridBox gridRef={gridRef} columns={columns} />
          </>
        }
      />
    </>
  );
};
