import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { InvoiceUpdateHistorySearchDTO } from '../../../_interface/order';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = (props: ISearchBoxProps<InvoiceUpdateHistorySearchDTO>) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_INVOICE: null,
    SEARCH_ORDER: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_INVOICE, searchObj));
  }, [selectTypeObj?.SEARCH_INVOICE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_ORDER, searchObj));
  }, [selectTypeObj?.SEARCH_ORDER]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">주문번호</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_ORDER
                    ? {
                        value: selectTypeObj?.SEARCH_ORDER,
                        label: MASTER_OBJ?.SEARCH_ORDER?.[selectTypeObj?.SEARCH_ORDER],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_ORDER}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_ORDER: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_ORDER}
                value={searchObj?.[selectTypeObj?.SEARCH_ORDER] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_ORDER]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">송장번호</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_INVOICE
                    ? {
                        value: selectTypeObj?.SEARCH_INVOICE,
                        label: MASTER_OBJ?.SEARCH_INVOICE?.[selectTypeObj?.SEARCH_INVOICE],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_INVOICE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_INVOICE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_INVOICE}
                value={searchObj?.[selectTypeObj?.SEARCH_INVOICE] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_INVOICE]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.SELLER_AUTH}
            value={searchObj?.partnerSeq?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.SELLER_AUTH?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  partnerSeq: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">변경사항</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.INVOICE_UPDATE_CONTENT}
            value={searchObj?.content?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.INVOICE_UPDATE_CONTENT?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  content: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">변경전</label>
          <InputD
            value={searchObj?.beforeContent || ''}
            onChange={(e) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  beforeContent: e.target.value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">변경후</label>
          <InputD
            value={searchObj?.afterContent || ''}
            onChange={(e) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  afterContent: e.target.value,
                };
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">변경아이디</label>
          <InputD
            value={searchObj?.registerId || ''}
            onChange={(e) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  registerId: e.target.value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">변경일</label>
          <FlatpickrD
            value={[searchObj?.fromRegisterDate, searchObj?.toRegisterDate]}
            onReset={() => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  fromRegisterDate: null,
                  toRegisterDate: null,
                };
              });
            }}
            onChange={(date) => {
              setSearchObj({
                ...searchObj,
                fromRegisterDate: returnDateyyyymmdd(date[0]),
                toRegisterDate: returnDateyyyymmdd(date[1]),
              });
            }}
          />
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
