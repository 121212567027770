import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './messageSendManage';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';
export const MANAGEMESSAGE_MASTER = [
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_AUTH',
  'SELLER_WHOLE',
  //
  'MESSAGE_TYPE',
  //
  {
    SEARCH_DATE: [
      {
        label: '고객주문일',
        value: 'OrderDate',
      },
      {
        label: '등록일',
        value: 'RegisterDate',
      },
      {
        label: '희망일',
        value: 'PromiseDeliveryDate',
      },
      {
        label: '지정일',
        value: 'DeliveryDate',
      },
      {
        label: '완료일/취소일',
        value: 'FinishOrCancelDate',
      },
    ],
  },
  {
    SEARCH_CUSTOMER: [
      { value: 'customerName', label: '고객명' },
      { value: 'receiverName', label: '수령자명' },
      { value: 'customerPhone', label: '고객연락처' },
      { value: 'receiverPhone', label: '수령자연락처' },
    ],
  },
  {
    SEARCH_SEQ: [
      {
        label: '주문번호',
        value: 'orderSeq',
      },
      {
        label: '외부주문번호',
        value: 'externalOrderNumber',
      },
      {
        label: '송장번호',
        value: 'invoiceSeq',
      },
      {
        label: '외부송장번호',
        value: 'externalInvoiceNumber',
      },
    ],
  },
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(MANAGEMESSAGE_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            //
            MESSAGESENDMANAGE: <Tab1 tabId={'MESSAGESENDMANAGE'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
