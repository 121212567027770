import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import Grid from '../../../../../components/grid/auiGrid';
import { TRANSPORT_DRIVER_INFO, TRANSPORT_DRIVER_INFO_SAVE, TRANSPORT_DRIVER_INFO_DELETE } from 'envVar';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { InputD, SelectD } from '../../../../../components/reactstrap/reactstrap';
import { httpClient } from 'common/http-client/axiosConfig';
import { v4 as uuidv4 } from 'uuid';

//redux
import { useSelector } from 'react-redux';
import CommonModalNew from '../../../../../components/modal/commonModalNew';

function Popup(props) {
  const { masterOptions } = useSelector((state) => state.menu);
  const { driverModalObj, setDriverModalObj } = props;

  useEffect(() => {
    if (masterOptions) {
      setGrid(`DeliveryInformationManagePopup${uuidv4()}`);
      fetchList();
    }
  }, [masterOptions]);

  const [rows, setRows] = useState([]);
  const [gridId, setGrid] = useState(null);

  const [userId, setUserId] = useState();
  const [driverOps, setDriverOps] = useState();

  const [driverAddModalObj, setDriverAddModalObj] = useState(null);
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'loadingShipping-driver-delete') {
        deleteSelectedDriver(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'loadingShipping-driver-set') {
        driverModalObj?.driverSetFn({
          data: items[0],
          loadingStatementSeqs: driverModalObj?.loadingStatementSeqs,
        });
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const fetchList = async () => {
    let url = TRANSPORT_DRIVER_INFO;
    if (userId) url += `?userId=${userId}`;
    httpClient.get(url).then((rs) => {
      const result = rs.data;
      const list = [];
      for (let i = 0; i < result.length; i++) {
        list.push({
          value: result[i].driverName,
          label: result[i].driverName,
        });
      }

      result.forEach((row) => {
        row.carTypeKr = selectlabel(row.carType, masterOptions?.CAR_TYPE);
      });

      setRows(result);
      setDriverOps(list);
    });
  };

  const deleteSelectedDriver = async (data) => {
    const result = {};
    const newArr = data.map((a, idx) => a.transportDriverSeq);
    result.transportDriverSeqs = newArr;

    httpClient.post(TRANSPORT_DRIVER_INFO_DELETE, result).then((rs) => {
      if (rs?.status === 200) {
        alert('삭제되었습니다!');
        fetchList();
      }
    });
  };

  const columns = [
    {
      headerText: '운송기사명',
      dataField: 'driverName',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '연락처',
      dataField: 'phoneNumber',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '차량종류',
      dataField: 'carTypeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '차량번호',
      dataField: 'carNumber',
      filter: {
        showIcon: true,
      },
    },
  ];

  return (
    <>
      {driverAddModalObj?.visible && <DriverInformationManageModal driverAddModalObj={driverAddModalObj} setDriverAddModalObj={setDriverAddModalObj} />}
      <CommonModalNew
        title="운송정보 입력 / 관리"
        style={{ width: 800 }}
        visible={driverModalObj?.visible}
        setVisible={() => {
          setDriverModalObj({
            visible: false,
          });
        }}
        children={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '15rem' }}>
                <label className="col-form-label">운송기사</label>
                <SelectD
                  options={driverOps}
                  onChange={(option) => {
                    setUserId(option.value);
                  }}
                />
              </div>
              <div className="grid-button-area" style={{ marginTop: '2.1rem' }}>
                <div
                  className="gray"
                  onDoubleClick={() => {
                    return;
                  }}
                  onClick={() => fetchList()}
                >
                  조회
                </div>
                <div
                  className="orange"
                  onDoubleClick={() => {
                    return;
                  }}
                  onClick={() =>
                    setGetCheckedDataTriggerThisGrid({
                      type: 'loadingShipping-driver-set',
                      id: `#${gridId}`,
                    })
                  }
                >
                  운송정보입력
                </div>
                <div
                  className="orange"
                  onDoubleClick={() => {
                    return;
                  }}
                  onClick={() => {
                    setDriverAddModalObj({
                      visible: true,
                      fetchListFn: () => fetchList(),
                    });
                  }}
                >
                  운송기사추가
                </div>
                <div
                  className="red"
                  onDoubleClick={() => {
                    return;
                  }}
                  onClick={() =>
                    setGetCheckedDataTriggerThisGrid({
                      type: 'loadingShipping-driver-delete',
                      id: `#${gridId}`,
                    })
                  }
                >
                  운송기사삭제
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              {gridId && (
                <Grid
                  id={gridId}
                  rows={rows}
                  columns={columns}
                  style={{ height: 500 }}
                  getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
                  setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
                  settingOptions={{
                    showPageRowSelect: false,
                    showRowAllCheckBox: false,
                    showRowCheckColumn: true,
                    editable: false,
                    rowCheckToRadio: true,
                  }}
                />
              )}
            </div>
          </>
        }
      />
    </>
  );
}

export default Popup;

const DriverInformationManageModal = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { driverAddModalObj, setDriverAddModalObj } = props;

  const driverAddForDelivery = async (data) => {
    httpClient.post(TRANSPORT_DRIVER_INFO_SAVE, data).then((rs) => {
      if (rs?.status === 200) {
        alert('추가되었습니다!');
        driverAddModalObj?.fetchListFn();
      }
    });
  };

  return (
    <CommonModalNew
      title="기사 정보"
      visible={driverAddModalObj?.visible}
      setVisible={() => {
        setDriverAddModalObj({
          ...driverAddModalObj,
          visible: false,
        });
      }}
      rightTitle={
        <Button className="btn-confirm btn" onClick={() => driverAddForDelivery(driverAddModalObj)}>
          추가하기
        </Button>
      }
      style={{ width: 350 }}
      children={
        <>
          <div>
            <label className="col-form-label" style={{ marginBottom: 5 }}>
              * 운송기사명
            </label>
            <InputD
              value={driverAddModalObj?.driverName || ''}
              onChange={(e) => {
                setDriverAddModalObj({
                  ...driverAddModalObj,
                  driverName: e.target.value,
                });
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <label className="col-form-label" style={{ marginBottom: 5 }}>
              * 연락처
            </label>
            <InputD
              value={driverAddModalObj?.phoneNumber || ''}
              onChange={(e) => {
                setDriverAddModalObj({
                  ...driverAddModalObj,
                  phoneNumber: e.target.value,
                });
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <label className="col-form-label" style={{ marginBottom: 5 }}>
              * 차량번호
            </label>
            <InputD
              value={driverAddModalObj?.carNumber || ''}
              onChange={(e) => {
                setDriverAddModalObj({
                  ...driverAddModalObj,
                  carNumber: e.target.value,
                });
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <label className="col-form-label" style={{ marginBottom: 5 }}>
              * 차량종류
            </label>
            <SelectD
              style={{ textAlign: 'center' }}
              value={{
                value: driverAddModalObj?.carType,
                label: selectlabel(driverAddModalObj?.carType, masterOptions?.CAR_TYPE),
              }}
              options={masterOptions?.CAR_TYPE}
              onChange={({ value }) => {
                setDriverAddModalObj({
                  ...driverAddModalObj,
                  carType: value,
                });
              }}
            />
          </div>
        </>
      }
    />
  );
};
