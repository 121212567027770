import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';

import {
  InventoryDetailInfoListDTO,
  InventoryDetailInfoSearchDTO,
  InventoryInOutDTO,
  InventoryInOutDetailDTO,
  InventoryInOutLocationDTO,
  InventoryInoutSearchDTO,
  InventoryLiveDTO,
  InventoryLiveDefectedDTO,
  InventoryLiveDefectedDetailDTO,
  InventoryLiveSearchDTO,
  InventoryLocationListDTO,
  InventoryLocationSearchDTO,
  InventoryLocationStatisticDTO,
  InventoryModelMoveDTO,
  InventoryModelMoveSaveDTO,
  InventoryModelMoveSearchDTO,
  InventoryTrackDTO,
  InventoryTrackSearchDTO,
  LocationDTOForAdd,
  SerialNumberAssetTypeSaveDTO,
  SerialNumberDateSaveDTO,
  SerialNumberLiveDTO,
  SerialNumberLiveSearchDTO,
  SerialNumberModifyDTO,
  SerialNumberSaveExcelDTO,
} from 'interface/warehouse';
import {
  INVENTORY_DAILY,
  INVENTORY_DAILY_DETAIL_EXCEL,
  INVENTORY_DAILY_DETAIL_INFO,
  INVENTORY_DAILY_EXCEL,
  INVENTORY_DAILY_QUANTITY,
  INVENTORY_DAILY_SELLER_EXCEL,
  INVENTORY_DAILY_SELLER_LIST,
  INVENTORY_DAILY_SELLER_LIST_DETAIL_EXCEL,
  INVENTORY_DAILY_SELLER_LIST_EXCEL,
  INVENTORY_DAILY_SELLER_QUANTITY,
  INVENTORY_DAILY_SELLING_TEMP,
  INVENTORY_DAILY_TEMP,
  INVENTORY_DEFECTED_DETAIL_EXCEL,
  INVENTORY_DEFECTED_EXCEL,
  INVENTORY_INOUT_DETAIL,
  INVENTORY_INOUT_DETAIL_EXCEL,
  INVENTORY_INOUT_DETAI_LOCATION_EXCEL,
  INVENTORY_INOUT_MAIN_EXCEL,
  INVENTORY_INOUT_TEMP,
  INVENTORY_LIVE,
  INVENTORY_LIVE_DEF,
  INVENTORY_LIVE_DEF_DETAIL,
  INVENTORY_LIVE_DEF_QUANTITY,
  INVENTORY_LIVE_DEF_TEMP,
  INVENTORY_LIVE_DETAIL_EXCEL,
  INVENTORY_LIVE_EXCEL,
  INVENTORY_LIVE_QUANTITY,
  INVENTORY_LIVE_SERIAL,
  INVENTORY_LIVE_SERIAL_EXCEL,
  INVENTORY_LIVE_TEMP,
  INVENTORY_LOCATION_LISTS_FOR_LOCATION,
  INVENTORY_LOCATION_LISTS_FOR_LOCATION_PAGING,
  INVENTORY_LOCATION_STATISTICS,
  INVENTORY_MODEL_MOVE_EXCEL,
  INVENTORY_MODEL_MOVE_PAGING,
  INVENTORY_MODEL_MOVE_SAVE,
  INVENTORY_MODIFICATION_LIST_PAGING,
  INVENTORY_TRACKING_EXCEL,
  INVENTORY_TRACKING_LIST,
  SERIAL_NUMBER_ASSETTYPE_SAVE,
  SERIAL_NUMBER_DISTRIBUTEDATE_SAVE,
  SERIAL_NUMBER_EXCEL_SAVE,
  SERIAL_NUMBER_MANUFACTUREDATE_SAVE,
  SERIAL_NUMBER_MODIFY,
  WAREHOUSE_LOCATION_SAVE,
} from 'envVar';
import { ResponseDTO } from 'interface/user';

import * as IGrid from 'aui-grid';
import { makeObjtoFooter } from 'common/util/grid';
import { PagingListDTO } from 'interface/util';

class InventoryService {
  public async getInventoryTrackingListPaging(searchDTO: InventoryTrackSearchDTO): Promise<PagingListDTO<InventoryTrackDTO>> {
    let url = INVENTORY_TRACKING_LIST + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }
  public async getInventoryTrackingExcelMain(searchDTO: InventoryTrackSearchDTO): Promise<InventoryTrackDTO[]> {
    let url = INVENTORY_TRACKING_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  // live model
  public async getInventoryModelTotal(searchDTO: InventoryLiveSearchDTO): Promise<IGrid.Footer[]> {
    let url = INVENTORY_LIVE_QUANTITY + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    const footerData = {
      ...data,
      customerTotal: data?.move + data?.repair,
    };
    const footer = makeObjtoFooter(footerData);
    return footer;
  }
  public async getInventoryModelListPaging(searchDTO: InventoryLiveSearchDTO): Promise<PagingListDTO<InventoryLiveDTO>> {
    let url = INVENTORY_LIVE_TEMP + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInventoryModelExcelMain(searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDTO[]> {
    let url = INVENTORY_LIVE_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryModelExcelDetail(searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDTO[]> {
    let url = INVENTORY_LIVE_DETAIL_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryModelDetail(modelStockSeq: string, searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDTO[]> {
    let url = INVENTORY_LIVE + `/${modelStockSeq}` + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryModelInfoDetail(searchDTO: InventoryDetailInfoSearchDTO): Promise<InventoryDetailInfoListDTO[]> {
    let url = INVENTORY_DAILY_DETAIL_INFO + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  // live defected
  public async getInventoryDefectedTotal(searchDTO: InventoryLiveSearchDTO): Promise<IGrid.Footer[]> {
    let url = INVENTORY_LIVE_DEF_QUANTITY + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    const footerData = {
      ...data,
    };
    const footer = makeObjtoFooter(footerData);
    return footer;
  }
  public async getInventoryLiveDefectedListPaging(searchDTO: InventoryLiveSearchDTO): Promise<PagingListDTO<InventoryLiveDefectedDTO>> {
    let url = INVENTORY_LIVE_DEF_TEMP + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInventoryDefectedExcelMain(searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDefectedDTO[]> {
    let url = INVENTORY_DEFECTED_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryDefectedExcelDetail(searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDefectedDTO[]> {
    let url = INVENTORY_DEFECTED_DETAIL_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryDefectedDetail(modelStockSeq: string, searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDTO[]> {
    let url = INVENTORY_LIVE_DEF + `/${modelStockSeq}` + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryDefectedDetailInfo(modelStockSeq: string, centerCode: string, searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDefectedDetailDTO> {
    let url = INVENTORY_LIVE_DEF_DETAIL + `/${modelStockSeq}/${centerCode}` + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  // live magam
  public async getInventoryDailyMagamTotal(searchDTO: InventoryLiveSearchDTO): Promise<IGrid.Footer[]> {
    let url = INVENTORY_DAILY_QUANTITY + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    const footerData = {
      ...data,
      customerTotal: data?.move + data?.repair,
    };
    const footer = makeObjtoFooter(footerData);
    return footer;
  }
  public async getInventoryDailyMagamPaging(searchDTO: InventoryLiveSearchDTO): Promise<PagingListDTO<InventoryLiveDTO>> {
    let url = INVENTORY_DAILY_TEMP + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInventoryDailyMagamExcelMain(searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDTO[]> {
    let url = INVENTORY_DAILY_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryDailyMagamExcelDetail(searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDTO[]> {
    let url = INVENTORY_DAILY_DETAIL_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryDailyMagamExcelPartner(searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDTO[]> {
    let url = INVENTORY_DAILY_SELLER_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryDailydDetail(modelStockSeq: string, searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDTO[]> {
    let url = INVENTORY_DAILY + `/${modelStockSeq}` + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  // live sales
  public async getInventorySalesMagamTotal(searchDTO: InventoryLiveSearchDTO): Promise<IGrid.Footer[]> {
    let url = INVENTORY_DAILY_SELLER_QUANTITY + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    const footerData = {
      ...data,
      customerTotal: data?.move + data?.repair,
    };
    const footer = makeObjtoFooter(footerData);
    return footer;
  }
  public async getInventorySalesMagamPaging(searchDTO: InventoryLiveSearchDTO): Promise<PagingListDTO<InventoryLiveDTO>> {
    let url = INVENTORY_DAILY_SELLING_TEMP + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInventorySalesMagamExcelMain(searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDTO[]> {
    let url = INVENTORY_DAILY_SELLER_LIST_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventorySalesMagamExcelDetail(searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDTO[]> {
    let url = INVENTORY_DAILY_SELLER_LIST_DETAIL_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventorySalesMagamDailydDetail(modelStockSeq: string, searchDTO: InventoryLiveSearchDTO): Promise<InventoryLiveDTO[]> {
    let url = INVENTORY_DAILY_SELLER_LIST + `/${modelStockSeq}` + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  // live subul
  public async getInventoryInoutPaging(searchDTO: InventoryInoutSearchDTO): Promise<PagingListDTO<InventoryInOutDTO>> {
    let url = INVENTORY_INOUT_TEMP + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInventoryInoutExcelMain(searchDTO: InventoryInoutSearchDTO): Promise<InventoryInOutDTO[]> {
    let url = INVENTORY_INOUT_MAIN_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryInoutExcelDetail(searchDTO: InventoryInoutSearchDTO): Promise<InventoryInOutDetailDTO[]> {
    let url = INVENTORY_INOUT_DETAIL_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryInoutDetail(searchDTO: InventoryInoutSearchDTO): Promise<InventoryInOutDTO[]> {
    let url = INVENTORY_INOUT_DETAIL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  // live
  public async getInventoryMoveListPaging(searchDTO: InventoryModelMoveSearchDTO): Promise<PagingListDTO<InventoryModelMoveDTO>> {
    let url = INVENTORY_MODEL_MOVE_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInventoryMoveExcelMain(searchDTO: InventoryModelMoveSearchDTO): Promise<InventoryModelMoveDTO[]> {
    let url = INVENTORY_MODEL_MOVE_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postInventoryModelMoveSave(dataDTO: InventoryModelMoveSaveDTO[]): Promise<ResponseDTO> {
    let url = INVENTORY_MODEL_MOVE_SAVE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
  // live serial
  public async getInventoryLiveListPaging(searchDTO: SerialNumberLiveSearchDTO): Promise<PagingListDTO<SerialNumberLiveDTO>> {
    let url = INVENTORY_LIVE_SERIAL + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInventoryLiveLExcelMain(searchDTO: SerialNumberLiveSearchDTO): Promise<SerialNumberLiveDTO[]> {
    let url = INVENTORY_LIVE_SERIAL_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postModifiedSerialNumber(dataDTO: SerialNumberModifyDTO): Promise<ResponseDTO> {
    let url = SERIAL_NUMBER_MODIFY;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async postManufactureDate(dataDTO: SerialNumberDateSaveDTO): Promise<ResponseDTO> {
    let url = SERIAL_NUMBER_MANUFACTUREDATE_SAVE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async postDistributeDate(dataDTO: SerialNumberDateSaveDTO): Promise<ResponseDTO> {
    let url = SERIAL_NUMBER_DISTRIBUTEDATE_SAVE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async postAssetType(dataDTO: SerialNumberAssetTypeSaveDTO): Promise<ResponseDTO> {
    let url = SERIAL_NUMBER_ASSETTYPE_SAVE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
  public async postSerialNumberExcelSave(dataDTO: SerialNumberSaveExcelDTO[]): Promise<ResponseDTO> {
    let url = SERIAL_NUMBER_EXCEL_SAVE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
  //
  public async getInventoryAvailableListPaging(searchDTO: InventoryModelMoveSearchDTO): Promise<PagingListDTO<InventoryModelMoveDTO>> {
    let url = INVENTORY_MODIFICATION_LIST_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }
  public async getInventoryDefectedListPaging(searchDTO: InventoryModelMoveSearchDTO): Promise<PagingListDTO<InventoryModelMoveDTO>> {
    let url = INVENTORY_MODEL_MOVE_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  // 로케이션 조회
  public async getInventoryLocationStatistics(searchDTO: InventoryLocationSearchDTO): Promise<InventoryLocationStatisticDTO> {
    let url = INVENTORY_LOCATION_STATISTICS + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryLocationStatisticsListPaging(searchDTO: InventoryLocationSearchDTO): Promise<PagingListDTO<InventoryLocationListDTO>> {
    let url = INVENTORY_LOCATION_LISTS_FOR_LOCATION_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryLocationStatisticsMainExcel(searchDTO: InventoryLocationSearchDTO): Promise<InventoryLocationListDTO[]> {
    let url = INVENTORY_LOCATION_LISTS_FOR_LOCATION + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async addLocationForCenter(dataDTO: LocationDTOForAdd): Promise<ResponseDTO> {
    let url = WAREHOUSE_LOCATION_SAVE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
  //
  public async getInventoryAction(searchDTO: any, action: string, isPaging = false): Promise<any> {
    let url = `/warehouse/inventory/${action}` + (isPaging ? addingParamsToUrl(searchDTO) : addingParamsToUrlexceptPaging(searchDTO));
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postInventoryAction(action: string, dataDTO: any, addingParams?: any, CUD = 'C'): Promise<ResponseDTO> {
    let url = `/warehouse/inventory/${action}`;
    if (addingParams) url += addingParamsToUrlexceptPaging(addingParams);
    const rs = CUD === 'C' ? await httpClient.post(url, dataDTO) : await httpClient.put(url, dataDTO);
    return rs;
  }

  public async getOrderAction(searchDTO: any, action: string, isPaging = false): Promise<any> {
    let url = `/order/${action}` + (isPaging ? addingParamsToUrl(searchDTO) : addingParamsToUrlexceptPaging(searchDTO));
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postOrderAction(action: string, dataDTO: any, addingParams?: any, CUD = 'C'): Promise<ResponseDTO> {
    let url = `/order/${action}`;
    if (addingParams) url += addingParamsToUrlexceptPaging(addingParams);
    const rs = CUD === 'C' ? await httpClient.post(url, dataDTO) : await httpClient.put(url, dataDTO);
    return rs;
  }
}

export const inventoryService = new InventoryService();
