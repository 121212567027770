import { useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { dayToFullDayFlag, OptionItem } from 'common/master/codeMasterReturnHelper';
import { DriverSearchDTO } from 'interface/install';

export const SearchBox = (props: ISearchBoxProps<DriverSearchDTO>) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;
  const [dayOfweekForSearch, setDayOfweekForSearch] = useState<string[]>();

  useEffect(() => {
    const obj = JSON.parse(JSON.stringify(searchObj));
    MASTER_OPS?.WEEK_DAY?.forEach((ele) => {
      obj[dayToFullDayFlag[ele.value]] = dayOfweekForSearch?.includes(ele.value) ? true : false;
    });
    setSearchObj(obj);
  }, [dayOfweekForSearch]);

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    fetchList(searchObj);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <label className="col-form-label">소속창고</label>
          <SelectD
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                  }
                : null
            }
            options={MASTER_OPS?.CENTER_AUTH}
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                centerCode: value,
              });
            }}
          />
        </Col>
        <Col md={2}>
          <label className="col-form-label">기사명</label>
          <div style={{ display: 'flex' }}>
            <Col md={6} style={{ display: 'inline-block' }}>
              <SelectD
                options={MASTER_OPS?.DRIVER_AUTH}
                value={
                  searchObj?.userId
                    ? {
                        value: searchObj?.userId,
                        label: MASTER_OBJ?.DRIVER_AUTH?.[searchObj?.userId],
                      }
                    : null
                }
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    userId: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={6} style={{ display: 'inline-block' }}>
              <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">기사타입</label>
          <SelectD
            value={
              searchObj?.driverType
                ? {
                    value: searchObj?.driverType,
                    label: MASTER_OBJ?.DRV_TYPE?.[searchObj?.driverType],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                driverType: (option as OptionItem).value,
              });
            }}
            options={MASTER_OPS?.DRV_TYPE}
          />
        </Col>
        <Col>
          <label className="col-form-label">연락처</label>
          <InputD
            value={searchObj?.phoneNumber || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                phoneNumber: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">근무가능요일</label>
          <Col>
            <SelectD
              isMulti
              options={MASTER_OPS?.WEEK_DAY}
              value={dayOfweekForSearch?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.WEEK_DAY?.[ele],
                };
              })}
              onChange={(option) => setDayOfweekForSearch((option as OptionItem[]).map((ele) => ele.value))}
            />
          </Col>
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <button className="btn btn-search" type="submit">
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
