export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const whatJustInputEntered = (value: string, prev: string) => {
  if (value?.length < prev?.length) return ''; // 지울때는 빈값 return
  const diff = value?.split('')?.filter((ele, idx) => {
    return prev?.[idx] !== ele;
  });
  const count = value?.length - prev.length;
  return diff?.slice(0, count);
};

export function round2(num: number) {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
}

export function returnDay(dateString: string) {
  // dateString yyyy-mm-dd
  const stringDate = dateString.toString();
  const returnDay = Number(stringDate.substr(8));
  return returnDay;
}

export function returnDDay(d1: string, d2: string) {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  const diffDate = date1.getTime() - date2.getTime();

  const diffDay = Math.abs(diffDate / (1000 * 60 * 60 * 24));

  if (diffDay < 0) return 'D' + diffDay;
  else return 'D+' + diffDay;
}

export function numberThousandComma(num: number | string): string {
  const thousandCommaAdded = (num + '').replaceAll(',', '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return thousandCommaAdded;
}

export function thousandCommaToString(thousandComma: string): number {
  const thousandCommaAdded = thousandComma?.replaceAll(',', '') || 0;
  return Number(thousandCommaAdded);
}

export function stringCommaToInt(num: number): number {
  const nums1 = num.toString().replace(/,/g, '');
  return Number(nums1);
}

export const stringToThousandComma = (str: string): string => {
  return addCommas(removeNonNumeric(str));
};

const addCommas = (num: string) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const removeNonNumeric = (num: string): string => num.toString().replace(/[^0-9]/g, '');

export const maskingReceiverName = (name: string) => {
  if (name.length > 2) {
    const originName = name.split('');
    const str = originName.map((ele, idx) => (idx === name?.length - 1 ? '*' : ele)).join('');
    return str;
  } else if (name.length === 2) {
    return name[0] + '*';
  }
  return name;
};
