import { useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './task';
import Tab2 from './list';
import Tab3 from './task_small';
import Tab4 from './task_small_new';
import Tab5 from './export';

// options
import { nullingMASTER } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';

export const MANAGE_PARCEL_TASK_LIST_MASTER = [
  //
  'SELLER_FORWARDER_AUTH',
  'SELLER_FORWARDER_WHOLE',
  //
  'CARGO_BOX_SIZE',
  'PARCEL_TYPE',
  'PORT_CODE',
  //
];

const Index = () => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_TASK_LIST_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            CARGO_TASKING: <Tab1 tabId={'CARGO_TASKING'} />,
            CARGO_TASK_LIST: <Tab2 tabId={'CARGO_TASK_LIST'} />,
            CARGO_TASKING_SM: <Tab3 tabId={'CARGO_TASKING_SM'} />,
            CARGO_TASKING_SM_LIST: <Tab4 tabId={'CARGO_TASKING_SM_LIST'} />,
            CARGO_TASKING_EXPORT: <Tab5 tabId={'CARGO_TASKING_EXPORT'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
