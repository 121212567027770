import { selectlabel } from 'common/master/codeMasterReturnHelper';
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import CommonModalNew from 'components/modal/commonModalNew';
import { locationService } from 'pages/INVENTORY/3location/_services/service';
import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';

export interface IBulyongSearchDTO {
  centerCode: string;
  locationCode: string;
  modelStockSeq: number;
}
export interface IBulyongModalObj {
  data: IBulyongSearchDTO;
  visible: boolean;
}

export const BulyongModal = ({ bulyongModalObj, setBulyongModalObj }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();

  useEffect(() => {
    fetchBulyong({ ...bulyongModalObj?.data });
  }, []);

  const fetchBulyong = async ({ centerCode, locationCode, modelStockSeq }: IBulyongSearchDTO) => {
    dispatch(setLoading('GET'));
    const data = await locationService.getWarehouseLocationBulyong({ centerCode, locationCode, modelStockSeq });
    dispatch(setLoading(null));
    if (data?.length > 0) {
      const dataKr = data.map((row) => {
        return {
          ...row,
          centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
          feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row.feeType],
          userIdKr: MASTER_OBJ?.DRIVER_WHOLE?.[row.userId],
          addressKr: row.receiverAddr1 ? row.receiverAddr1 : '' + ' ' + row.receiverAddr2 ? row.receiverAddr2 : '',
        };
      });
      gridRef.current.setGridData(dataKr);
    } else {
      alert('조회되는 재고가 없습니다');
    }
  };

  const columns = [
    {
      headerText: '재고번호',
      dataField: 'inventorySeq',
    },
    {
      headerText: '수령인',
      dataField: 'receiverName',
    },
    {
      headerText: '주소',
      dataField: 'addressKr',
      width: 200,
    },
    {
      headerText: '배송창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '배송기사',
      dataField: 'userIdKr',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '고객주문일',
      dataField: 'orderDatetime',
    },
    {
      headerText: '스케줄확정일',
      dataField: 'confirmDatetime',
    },
    {
      headerText: '기사최종완료일',
      dataField: 'finishDatetime',
    },
  ];

  return (
    <CommonModalNew
      subTitle='"회수"된 제품이 아니면 주문정보가 없을 수 있습니다'
      title={`불용재고 상세`}
      style={{ width: 1000 }}
      visible={bulyongModalObj?.visible}
      setVisible={() => {
        setBulyongModalObj(null);
      }}
      children={
        <>
          <GridBox gridRef={gridRef} columns={columns} gridProps={{ height: 500, showRowAllCheckBox: false, showRowCheckColumn: false }} />
        </>
      }
    />
  );
};
