import { v4 as uuidv4 } from 'uuid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, Form, Button } from 'reactstrap';
import Grid from 'components/grid/auiGrid';
import { BottomSheets } from 'components/template/bottomSheets';
import CommonModalNew from 'components/modal/commonModalNew';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

// redux
import { useSelector } from 'react-redux';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { DriverPrint } from '../../_print/driverPrint';

import { resetAllSelections } from 'common/util/searchBox';
import PrintGridBox from 'common/grid/printGridBox';

const Presenter = (props) => {
  const {
    //
    bottomSheetObj,
    setBottomSheetObj,
    haldangObj,
    setHaldangObj,
    locationChangeObj,
    setLocationChangeObj,
    cancelObj,
    setCancelObj,
    serialObj,
    setSerialObj,
    driverPrintObj,
    setDriverPrintObj,
  } = props;

  return (
    <>
      <div className="page-content" style={{ overflow: 'auto' }}>
        {driverPrintObj?.visible && <DriverPrint driverPrintObj={driverPrintObj} setDriverPrintObj={setDriverPrintObj} />}
        {serialObj?.visible && <SerialPoup serialObj={serialObj} setSerialObj={setSerialObj} />}
        {cancelObj?.visible && <CancelModal cancelObj={cancelObj} setCancelObj={setCancelObj} />}
        {locationChangeObj?.visible && <LocationChangeModal locationChangeObj={locationChangeObj} setLocationChangeObj={setLocationChangeObj} />}
        {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
        {haldangObj?.visible && <HaldangModal haldangObj={haldangObj} setHaldangObj={setHaldangObj} setCancelObj={setCancelObj} />}
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const SerialPoup = ({ serialObj, setSerialObj }) => {
  const [serial, setSerial] = useState(serialObj?.data?.serialNumber);
  return (
    <CommonModalNew
      title="출고 시리얼 수정"
      style={{ width: 300 }}
      visible={serialObj?.visible}
      setVisible={() => {
        setSerialObj((prev) => {
          return {
            ...prev,
            event: null,
            visible: false,
            data: null,
          };
        });
      }}
      children={
        <>
          <InputD
            value={serial || ''}
            onChange={(e) => {
              setSerial(e.target.value);
            }}
          />
          <div style={{ marginTop: 30, float: 'right' }}>
            <button
              className="btn btn-secondary sm"
              onClick={() => {
                serialObj?.modiFn({ data: serialObj?.data, serial });
              }}
            >
              수정
            </button>
          </div>
        </>
      }
    />
  );
};

const CancelModal = ({ cancelObj, setCancelObj }) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const [inputObj, setInputObj] = useState({
    holdType: null,
    holdNote: null,
  });
  return (
    <CommonModalNew
      //
      style={{ width: 330 }}
      title="피킹취소"
      visible={cancelObj?.visible}
      setVisible={() => {
        setCancelObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      rightTitle={
        <div>
          <Button
            className="btn btn-orange ssm"
            onClick={() => {
              if (!inputObj?.holdType) {
                alert('피킹취소사유를 선택해주세요');
              } else {
                let dataArr;
                if (!cancelObj?.origin) {
                  dataArr = cancelObj?.data.map((ele) => {
                    return {
                      loanPickSeq: ele.loanPickSeq,
                      centerCode: ele.centerCode,
                      loanPickDate: ele.loanPickDate,
                      holdType: inputObj?.holdType,
                      holdNote: inputObj?.holdNote,
                    };
                  });
                } else {
                  dataArr = [
                    {
                      loanPickSeq: cancelObj?.origin?.loanPickSeq,
                      centerCode: cancelObj?.origin?.centerCode,
                      loanPickDate: cancelObj?.origin?.loanPickDate,
                      holdType: inputObj?.holdType,
                      holdNote: inputObj?.holdNote,
                      inventorySeqs: cancelObj?.data?.map((ele) => {
                        return ele.inventorySeq;
                      }),
                    },
                  ];
                }
                cancelObj?.cancelFn(dataArr);
              }
            }}
          >
            취소 신청
          </Button>
        </div>
      }
      children={
        <>
          <div>피킹 작업을 취소하시겠습니까?</div>
          <div style={{ fontSize: 10, color: 'red', marginBottom: 20 }}>※ 취소할 경우 다시 되돌릴 수 없으니 신중히 선택해주세요</div>
          <Row>
            <Col>
              <label className="col-form-label">피킹취소사유</label>
              <SelectD
                value={
                  inputObj?.holdType
                    ? {
                        value: inputObj?.holdType,
                        label: selectlabel(inputObj?.holdType, masterOptions?.ORDER_HOLD_TYPE),
                      }
                    : null
                }
                options={masterOptions?.ORDER_HOLD_TYPE}
                onChange={({ value }) => {
                  setInputObj({
                    ...inputObj,
                    holdType: value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputD
                value={inputObj?.holdNote || ''}
                onChange={(e) => {
                  setInputObj({
                    ...inputObj,
                    holdNote: e.target.value,
                  });
                }}
                placeholder="피킹취소사유 입력"
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};

const LocationChangeModal = ({ locationChangeObj, setLocationChangeObj }) => {
  const [availLocation, setAvailLocation] = useState();
  const [selectObj, setSelectObj] = useState({
    beforeLocationCode: null,
    quantity: 1,
    afterLocationCode: null,
  });

  useEffect(() => {
    if (locationChangeObj?.data) {
      const availLocation = locationChangeObj?.availLocation?.map((ele) => {
        return {
          value: ele,
          label: ele,
        };
      });
      setAvailLocation(availLocation);
      setSelectObj((prev) => {
        return {
          ...prev,
          beforeLocationCode: locationChangeObj?.data?.locationCode,
        };
      });
    }
  }, []);
  return (
    <CommonModalNew
      title="로케이션 변경"
      style={{ width: 300 }}
      visible={locationChangeObj?.visible}
      setVisible={() => {
        setLocationChangeObj({
          ...locationChangeObj,
          visible: false,
          data: null,
        });
      }}
      rightTitle={
        <div>
          <Button
            className="btn btn-orange ssm"
            onClick={() => {
              if (selectObj?.quantity === 0) {
                alert('변경하고자 하는 수량을 정확히 입력해주세요!');
              } else if (!selectObj?.afterLocationCode) {
                alert('변경 후 로케이션을 선택해주세요!');
              } else {
                const data = {
                  centerCode: locationChangeObj?.data?.centerCode,
                  loanPickDate: locationChangeObj?.data?.loanPickDate,
                  modelStockSeq: locationChangeObj?.data?.modelStockSeq,
                  beforeLocationCode: selectObj?.beforeLocationCode,
                  quantity: selectObj?.quantity,
                  afterLocationCode: selectObj?.afterLocationCode,
                };
                locationChangeObj?.actionFn({ data, e: JSON.parse(locationChangeObj?.data?.e) });
              }
            }}
          >
            변경
          </Button>
        </div>
      }
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">변경 전 로케이션</label>
              <InputD disabled value={selectObj?.beforeLocationCode || ''} />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">변경 수량</label>
              <InputD
                value={selectObj?.quantity || ''}
                onChange={(e) => {
                  setSelectObj({
                    ...selectObj,
                    quantity: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">변경 후 로케이션</label>
              <SelectD
                options={availLocation}
                value={
                  selectObj?.afterLocationCode
                    ? {
                        value: selectObj?.afterLocationCode,
                        label: selectlabel(selectObj?.afterLocationCode, availLocation),
                      }
                    : null
                }
                onChange={({ value }) => {
                  setSelectObj({
                    ...selectObj,
                    afterLocationCode: value,
                  });
                }}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};

const HaldangModal = ({ haldangObj, setHaldangObj, cancelObj, setCancelObj }) => {
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState();
  const [gridId, setGrid] = useState(`fcPicking_Haldang_${uuidv4()}`);
  const [rows, setRows] = useState();
  const [exportExcelThisGrid, setExportExcelThisGrid] = useState(false);

  const columns = [
    {
      headerText: '피킹여부',
      dataField: 'pickFlagKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품유형',
      dataField: 'availableTypeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품',
      dataField: 'model',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '외부 SKU',
      dataField: 'skuNumber',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '고객명',
      dataField: 'receiverName',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '고객주소',
      dataField: 'receiverAddrKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '연락처',
      dataField: 'receivePhone',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '기사출고창고',
      dataField: 'driverCenterCodeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '설치기사',
      dataField: 'driverName',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '기사출고예정일',
      dataField: 'deliveryDate',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '주문등록일',
      dataField: 'orderDate',
      filter: {
        showIcon: true,
      },
    },
  ];

  useEffect(() => {
    if (haldangObj?.data) {
      setRows(haldangObj?.data);
    }
  }, []);

  const confirmPickingParts = (items) => {
    if (window.confirm('부분피킹확정 하시겠습니까?')) {
      const data = {
        centerCode: items[0].centerCode,
        loanPickDate: items[0].loanPickDate,
        inventorySeqs: items?.map((ele) => {
          return ele.inventorySeq;
        }),
      };
      haldangObj?.confirmFn([data]);
    }
  };

  const rollbackStatusParts = (items) => {
    const availStat = ['피킹지시확정', '피킹중'];
    const statusKr = haldangObj?.origin?.statusKr;
    if (availStat.includes(statusKr)) {
      if (window.confirm(`${statusKr} 상태에서 ${statusKr === '피킹지시확정' ? '피킹지시대기' : '피킹지시확정'} 상태로 돌아가시겠습니까?`)) {
        const dataArr = [
          {
            loanPickSeq: haldangObj?.origin?.loanPickSeq,
            inventorySeqs: items?.map((ele) => {
              return ele.inventorySeq;
            }),
          },
        ];
        haldangObj?.rollbackFn(dataArr);
      }
    } else {
      alert('피킹지시확정 혹은 피킹중 상태만 이전단계로 돌릴 수 있습니다!');
    }
  };

  const cancelPickingParts = (items) => {
    const availStat = ['피킹지시대기', '피킹지시확정'];
    const statusKr = haldangObj?.origin?.statusKr;
    if (availStat.includes(statusKr)) {
      setCancelObj((prev) => {
        return {
          ...prev,
          visible: true,
          origin: haldangObj?.origin,
          data: items,
        };
      });
    } else {
      alert('피킹취소는 진행상태가 피킹지시대기, 피킹지시확정 일때에만 가능합니다!');
    }
  };

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'confirm-picking-parts') {
        confirmPickingParts(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'rollback-status-parts') {
        rollbackStatusParts(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'cancel-picking-parts') {
        cancelPickingParts(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);
  return (
    <CommonModalNew
      title="할당정보"
      style={{ width: 1000 }}
      visible={haldangObj?.visible}
      setVisible={() => {
        setHaldangObj({
          ...haldangObj,
          visible: false,
          data: null,
        });
      }}
      children={
        <>
          <div className="grid-button-area space-between">
            <div>
              <div
                className="orange"
                onClick={() => {
                  setGetCheckedDataTriggerThisGrid({
                    type: 'confirm-picking-parts',
                    id: `#${gridId}`,
                  });
                }}
              >
                부분 피킹지시확정
              </div>
              <div
                className="red"
                onClick={() => {
                  setGetCheckedDataTriggerThisGrid({
                    type: 'rollback-status-parts',
                    id: `#${gridId}`,
                  });
                }}
              >
                이전단계
              </div>
              <div
                className="red"
                onClick={() => {
                  setGetCheckedDataTriggerThisGrid({
                    type: 'cancel-picking-parts',
                    id: `#${gridId}`,
                  });
                }}
              >
                피킹취소
              </div>
            </div>
            <div>
              <div
                className="green"
                onClick={() => {
                  setExportExcelThisGrid({ type: 'xlsx', id: `#${gridId}` });
                }}
              >
                엑셀다운
              </div>
            </div>
          </div>
          <div style={{ width: '100%' }}>
            {gridId && (
              <>
                <Grid
                  style={{ height: 500 }}
                  id={gridId}
                  rows={rows}
                  columns={columns}
                  settingOptions={{
                    showRowAllCheckBox: true,
                    showRowCheckColumn: true,
                  }}
                  exportExcelThisGrid={exportExcelThisGrid}
                  getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
                  setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
                />
              </>
            )}
          </div>
        </>
      }
    />
  );
};

const Search = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { searchObj, setSearchObj, setPagingSetting, pagingSetting, fetchList } = props;

  const [selectTypeObj, setSelectTypeObj] = useState({
    ORDER_SEQ: null,
    SEARCH_MODEL: null,
  });
  console.log(searchObj);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.ORDER_SEQ, searchObj));
  }, [selectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    if (pagingSetting?.pageNo && pagingSetting?.fetchNew) {
      fetchList();
    }
  }, [pagingSetting?.fetchNew]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      setPagingSetting((prev) => {
        return {
          ...prev,
          pageNo: 1,
          fetchNew: true,
        };
      });
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">기사출고번호</label>
            <InputD
              value={searchObj?.loanSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  loanSeq: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">출고예정일</label>
            <FlatpickrD
              options={{
                mode: 'single', // default range
              }}
              value={searchObj?.loanDate}
              onChange={(date) => {
                setSearchObj({
                  ...searchObj,
                  loanDate: returnDateyyyymmdd(date[0]),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">창고</label>
            <SelectD
              options={masterOptions?.CENTER}
              value={
                searchObj?.centerCode
                  ? {
                      value: searchObj?.centerCode,
                      label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">창고타입</label>
            <SelectD
              options={masterOptions?.WH_TYPE}
              value={
                searchObj?.centerType
                  ? {
                      value: searchObj?.centerType,
                      label: selectlabel(searchObj?.centerType, masterOptions?.WH_TYPE),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  centerType: value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">기사</label>
            <div style={{ display: 'flex' }}>
              <Col md={6} style={{ display: 'inline-block' }}>
                <SelectD
                  options={masterOptions?.DRIVER_NAME}
                  value={
                    searchObj?.userId
                      ? {
                          value: searchObj?.userId,
                          label: selectlabel(searchObj?.userId, masterOptions?.DRIVER_NAME),
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      userId: value,
                    });
                  }}
                />
              </Col>
              <Col md={6} style={{ display: 'inline-block' }}>
                <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">진행상태</label>
            <SelectD
              options={masterOptions?.LOAN_STATUS}
              value={
                searchObj?.status
                  ? {
                      value: searchObj?.status,
                      label: selectlabel(searchObj?.status, masterOptions?.LOAN_STATUS),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  status: value,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">주문번호/송장번호</label>
            {/* orderSeq */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.ORDER_SEQ
                      ? {
                          value: selectTypeObj?.ORDER_SEQ,
                          label: selectlabel(selectTypeObj?.ORDER_SEQ, masterOptions?.ORDER_SEQ),
                        }
                      : null
                  }
                  options={masterOptions?.ORDER_SEQ}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      ORDER_SEQ: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.ORDER_SEQ}
                  value={searchObj?.[selectTypeObj?.ORDER_SEQ] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.ORDER_SEQ]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">제품/제품명</label>
            {/* model / modelName  */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_MODEL
                      ? {
                          value: selectTypeObj?.SEARCH_MODEL,
                          label: selectlabel(selectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_MODEL}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_MODEL: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_MODEL}
                  value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">유사검색여부</label>
            {/* similaritySearchFlag */}
            <div className="radio-select-area">
              <span
                className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: true,
                    };
                  });
                }}
              >
                유사검색
              </span>
              <span
                className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: false,
                    };
                  });
                }}
              >
                일치검색
              </span>
            </div>
          </Col>
          <Col>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();

                setPagingSetting((prev) => {
                  return {
                    ...prev,
                    pageNo: 1,
                    fetchNew: true,
                  };
                });
              }}
            >
              조회
            </button>
          </Col>
          <Col />
          <Col />
        </Row>
      </Form>
    </>
  );
};

const GridBox = (props) => {
  const {
    rows = [],
    gridId,
    columns = [],
    searchObj,
    detailColumns = [],
    onCellClick,
    pagingSetting,
    setPagingSetting,
    downloadExcel,
    getCheckedDataTriggerThisGrid,
    setGetCheckedDataTriggerThisGrid,
  } = props;
  const excelGridRef = useRef();
  const excelDetailGridRef = useRef();
  const excelDownClicked = async (type) => {
    const data = (await downloadExcel(type)) || [];
    if (data.length > 0) {
      if (type === 'main') {
        excelGridRef.current.setGridData(data);
        excelGridRef.current.exportAsXlsx({ fileName: '기사출고제품(메인)' });
      } else {
        excelDetailGridRef.current.setGridData(data);
        excelDetailGridRef.current.exportAsXlsx({ fileName: '기사출고제품(상세)' });
      }
    } else {
      alert('조회되는 값이 없습니다!');
    }
  };
  const transfer = {
    DELIVERYORDER_MODEL_CONFIRM_LOAN: 'confirm-loan',
  };

  const handleCheckItems = (e) => {
    const id = e.target.id;
    if (id === 'DELIVERYORDER_MODEL_EXCELDOWNMAIN') {
      excelDownClicked('main');
    } else if (id === 'DELIVERYORDER_MODEL_EXCELDOWNDETAIL') {
      excelDownClicked('detail');
    } else {
      setGetCheckedDataTriggerThisGrid({
        type: transfer[id],
        id: `#${gridId}`,
      });
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState();
  const functions = fetchUsableFunctionsInThisTab('DELIVERYORDER_MODEL');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, handleCheckItems);
    setFunctionBtns(btns);
  }, [searchObj, gridId]);

  return (
    <>
      {functionBtns ? (
        <div className="grid-button-area">{functionBtns}</div>
      ) : (
        <div className="grid-button-area space-between">
          <div>
            <div
              className="orange"
              onClick={() => {
                setGetCheckedDataTriggerThisGrid({
                  type: 'confirm-loan',
                  id: `#${gridId}`,
                });
              }}
            >
              출고지시 확정
            </div>
          </div>
          <div>
            <div
              className="green"
              onClick={() => {
                excelDownClicked('main');
              }}
            >
              엑셀다운
            </div>
            <div
              className="green"
              onClick={() => {
                excelDownClicked('detail');
              }}
            >
              엑셀다운(상세)
            </div>
          </div>
        </div>
      )}
      <div style={{ width: '100%' }}>
        {gridId && (
          <>
            <Grid
              id={gridId}
              rows={rows}
              columns={columns}
              // custompaging **

              pagingSetting={pagingSetting}
              setPagingSetting={setPagingSetting}
              settingOptions={{
                usePaging: true,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
                fixedColumnCount: 3,
              }}
              onCellClick={onCellClick}
              getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
              setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            />
            <PrintGridBox gridRef={excelGridRef} columns={columns} />
            <PrintGridBox gridRef={excelDetailGridRef} columns={detailColumns} />
          </>
        )}
      </div>
    </>
  );
};
