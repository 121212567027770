import { handleFiles, uploadFile } from 'common/util/photo';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { numberTestRegExp } from 'common/util/regExp';
import { getToday, returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { setLoading } from 'redux/services/menuSlice';
import { v4 as uuidv4 } from 'uuid';

import CloseImg from 'assets/images/close/cross.png';
import { Button, Label } from 'reactstrap';
import { FileDTOForModelStock } from 'interface/warehouse';
import { ImageModal } from 'pages/ORDER/1order/orderListTab/common/component/photos/imageModal';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';
import { PartnerDTO, PartnerSearchDTO } from 'pages/ETC/3user/_interface/user';

export interface IDetailObj {
  visible: boolean;
  item?: PartnerDTO;
  searchObj?: PartnerSearchDTO;
  fetchFn?: (data: PartnerSearchDTO) => {};
}

export const DetailPopup = ({ detailObj, setDetailObj }) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const [partnerInfos, setpartnerInfos] = useState<PartnerDTO>();
  const [whArr, setWhArr] = useState([]);
  const [webHooks, setWebHooks] = useState([]);

  const [currentTab, setCurrentTab] = useState(0);
  const hasTabs = [{ title: '기본정보' }, { title: '세부사항' }, { title: '개발정보(Web Hook)' }];

  const [files, setFiles] = useState<FileDTOForModelStock[]>([]);

  const handleFileRemove = (e) => {
    dispatch(setLoading('POST'));
    const id = e.target.id?.split('_')[1];
    const _files = files?.filter((_, i) => i !== Number(id));
    setFiles(_files);
    dispatch(setLoading(null));
  };

  const handleFileUpload = async (e) => {
    dispatch(setLoading('POST'));
    const returns = await handleFiles(e);
    const res = await Promise.all(
      returns?.map(async (ret) => {
        return await uploadFile(ret.file, ret.url);
      }),
    );
    res.forEach((uploadUrl, idx) => {
      files?.push({
        column: `PARTNER${getToday()}_${uuidv4().split('-')[4]}_${idx}`,
        uploadUrl,
      });
    });
    setFiles(files);
    dispatch(setLoading(null));
  };

  useEffect(() => {
    if (detailObj?.item) {
      fetchDetailData(detailObj?.item?.partnerSeq);
    }
  }, []);

  const fetchDetailData = async (partnerSeq: string) => {
    dispatch(setLoading('GET'));
    const data = (await serviceStore.userAction(`partner/${partnerSeq}`, 'GET', null))?.data;
    if (data?.webhooks?.length > 0) {
      const whs = data?.webhooks?.map((wh) => {
        return wh.type;
      });
      setWhArr(whs);
      setWebHooks(data?.webhooks);
    }
    setFiles(data?.files || []);
    setpartnerInfos(data);
    dispatch(setLoading(null));
  };

  const handleDaumPost = () => {
    new (window as any).daum.Postcode({
      oncomplete: function (data) {
        const { zonecode, roadAddress, jibunAddress } = data;
        setpartnerInfos((prev) => {
          return {
            ...prev,
            zipcode: zonecode,
            address: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
          };
        });
      },
    }).open();
  };

  const save = async (webhook, partnerInfos) => {
    partnerInfos.webhooks = webhook;
    partnerInfos.files = files;
    const rs = (await serviceStore.userAction('partner', 'POST', null, partnerInfos))?.data;
    if (rs?.status === 200) {
      alert('저장되었습니다!');
      detailObj?.fetchFn(detailObj?.searchObj);
      setDetailObj(null);
    }
  };

  const validate = (data) => {
    if (data?.sellerFlag && !data?.cutoffDay) {
      alert('판매사 체크시 재고할당 기준일수는 필수값입니다!');
      return false;
    }
    return true;
  };

  const partnerColorToRgb = {
    BLUE: 'rgb(62,161,255)',
    ORANGE: 'rgb(255,145,15)',
    RED: 'rgb(253,92,81)',
  };

  return (
    <CommonModalNew
      title={`파트너 ${!detailObj?.item ? '등록' : '상세보기'}`}
      style={{ width: 1000 }}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      rightTitle={
        <>
          <Button
            className="btn-secondary btn ssm"
            onClick={(e) => {
              save(webHooks, partnerInfos);
            }}
          >
            {detailObj?.item ? '수정' : '등록'}
          </Button>
        </>
      }
      children={
        <>
          <div style={{ minHeight: 600 }}>
            <div className="div-top-tab">
              {hasTabs.map((tab, index) => {
                return (
                  <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                    <li
                      onClick={() => {
                        setCurrentTab(index);
                      }}
                    >
                      {tab?.title}
                    </li>
                  </div>
                );
              })}
            </div>
            <>
              <div style={{ flexDirection: 'column', display: currentTab === 0 ? 'flex' : 'none' }}>
                <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
                  <thead>
                    <tr>
                      <th colSpan={12}>1. 기본정보</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>*파트너명</th>
                      <td colSpan={2}>
                        <InputD
                          value={partnerInfos?.partnerName || ''}
                          onChange={(e) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              partnerName: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <td colSpan={3}>
                        <div>
                          <span style={{ display: 'inline-flex', width: 50, justifyContent: 'space-evenly' }}>
                            <label>공급사</label>
                            <input
                              type="checkbox"
                              checked={partnerInfos?.supplierFlag || false}
                              onChange={() => {
                                setpartnerInfos({
                                  ...partnerInfos,
                                  supplierFlag: !partnerInfos?.supplierFlag,
                                });
                              }}
                            />
                          </span>
                          <span style={{ display: 'inline-flex', width: 50, justifyContent: 'space-evenly' }}>
                            <label>위탁사</label>
                            <input
                              type="checkbox"
                              checked={partnerInfos?.networkFlag || false}
                              onChange={() => {
                                setpartnerInfos({
                                  ...partnerInfos,
                                  networkFlag: !partnerInfos?.networkFlag,
                                });
                              }}
                            />
                          </span>
                          <span style={{ display: 'inline-flex', width: 50, justifyContent: 'space-evenly' }}>
                            <label>판매사</label>
                            <input
                              type="checkbox"
                              checked={partnerInfos?.sellerFlag || false}
                              onChange={() => {
                                setpartnerInfos({
                                  ...partnerInfos,
                                  sellerFlag: !partnerInfos?.sellerFlag,
                                  cutoffDay: !partnerInfos?.sellerFlag ? 1 : null,
                                });
                              }}
                            />
                          </span>
                          <span style={{ display: 'inline-flex', width: 50, justifyContent: 'space-evenly' }}>
                            <label>운송사</label>
                            <input
                              type="checkbox"
                              checked={partnerInfos?.transportFlag || false}
                              onChange={() => {
                                setpartnerInfos({
                                  ...partnerInfos,
                                  transportFlag: !partnerInfos?.transportFlag,
                                });
                              }}
                            />
                          </span>
                          <span style={{ display: 'inline-flex', width: 50, justifyContent: 'space-evenly' }}>
                            <label>포워더</label>
                            <input
                              type="checkbox"
                              checked={partnerInfos?.forwarderFlag || false}
                              onChange={() => {
                                setpartnerInfos({
                                  ...partnerInfos,
                                  forwarderFlag: !partnerInfos?.forwarderFlag,
                                });
                              }}
                            />
                          </span>
                        </div>
                      </td>
                      <th>재고할당 기준일수</th>
                      <td colSpan={2}>
                        <InputD
                          type="number"
                          disabled={!partnerInfos?.sellerFlag || false}
                          value={partnerInfos?.cutoffDay || ''}
                          onChange={(e) => {
                            if (e.target.value.trim()?.length > 0) {
                              if (numberTestRegExp(e.target.value)) {
                                if (Number(e.target.value) > 0) {
                                  setpartnerInfos({
                                    ...partnerInfos,
                                    cutoffDay: Number(e.target.value),
                                  });
                                } else {
                                  alert('최솟값은 1입니다');
                                }
                              } else {
                                alert('숫자만 입력가능합니다');
                              }
                            } else {
                              setpartnerInfos({
                                ...partnerInfos,
                                cutoffDay: Number(e.target.value),
                              });
                            }
                          }}
                        />
                      </td>
                      <th>파트너 운영중</th>
                      <td colSpan={2}>
                        <input
                          type="checkbox"
                          checked={partnerInfos?.status === 'ACTIVE'}
                          onChange={() => {
                            setpartnerInfos({
                              ...partnerInfos,
                              status: partnerInfos?.status === 'ACTIVE' ? 'END' : 'ACTIVE',
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>대표자명</th>
                      <td colSpan={2}>
                        <InputD
                          value={partnerInfos?.managerName || ''}
                          onChange={(e) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              managerName: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>우편번호/주소</th>
                      <td colSpan={8}>
                        <div style={{ display: 'flex' }}>
                          <InputD readOnly onClick={handleDaumPost} style={{ display: 'inline-block', width: 80, marginRight: 10, cursor: 'pointer' }} value={partnerInfos?.zipcode || ''} />
                          <InputD
                            style={{ width: 240 }}
                            placeholder="우편번호를 클릭하세요"
                            disabled
                            value={partnerInfos?.address || ''}
                            onChange={(e) => {
                              setpartnerInfos({
                                ...partnerInfos,
                                address: e.target.value,
                              });
                            }}
                          />
                          <InputD
                            style={{ width: 350 }}
                            value={partnerInfos?.addressDetail || ''}
                            onChange={(e) => {
                              setpartnerInfos({
                                ...partnerInfos,
                                addressDetail: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>사업자번호</th>
                      <td colSpan={2}>
                        <InputD
                          value={partnerInfos?.businessNumber || ''}
                          onChange={(e) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              businessNumber: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>대표번호</th>
                      <td colSpan={2}>
                        <InputD
                          value={partnerInfos?.managerPhone || ''}
                          onChange={(e) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              managerPhone: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>이메일</th>
                      <td colSpan={5}>
                        <InputD
                          value={partnerInfos?.managerEmail || ''}
                          onChange={(e) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              managerEmail: e.target.value,
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>은행</th>
                      <td colSpan={2}>
                        <InputD
                          value={partnerInfos?.bank || ''}
                          onChange={(e) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              bank: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>계좌번호</th>
                      <td colSpan={2}>
                        <InputD
                          value={partnerInfos?.accountNumber || ''}
                          onChange={(e) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              accountNumber: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>거래시작일</th>
                      <td colSpan={2}>
                        <FlatpickrD
                          disabled={detailObj?.item}
                          value={partnerInfos?.startDate || ''}
                          onChange={(date) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              startDate: returnDateyyyymmdd(date[0]),
                            });
                          }}
                          options={{ mode: 'single' }}
                        />
                      </td>
                      <td colSpan={3} />
                    </tr>
                    <tr>
                      <th>외부파트너코드</th>
                      <td colSpan={2}>
                        <InputD
                          value={partnerInfos?.externalPartnerCode || ''}
                          onChange={(e) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              externalPartnerCode: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>배송경로확인(간편) 마커색상</th>
                      <td colSpan={2}>
                        <SelectD
                          hasColor={partnerColorToRgb[partnerInfos?.partnerColor ?? 'ORANGE']}
                          options={MASTER_OPS?.PARTNER_COLOR}
                          value={
                            partnerInfos?.partnerColor
                              ? {
                                  value: partnerInfos?.partnerColor,
                                  label: MASTER_OBJ?.PARTNER_COLOR?.[partnerInfos?.partnerColor],
                                }
                              : null
                          }
                          onChange={({ value }) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              partnerColor: value,
                            });
                          }}
                        />
                      </td>
                      <td colSpan={6}>
                        {/* <SelectD
                          isMulti
                          options={MASTER_OPS?.CENTER_AUTH}
                          value={partnerInfos?.parentCenterCodes?.map((ele) => {
                            return {
                              value: ele,
                              label: MASTER_OBJ?.CENTER_AUTH?.[ele],
                            };
                          })}
                          onChange={(opts) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              parentCenterCodes: (opts as OptionItem[]).map((ele) => ele.value),
                            });
                          }}
                        /> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div style={{ display: currentTab === 1 ? 'flex' : 'none' }}>
                <table className="border-table detail-table no-td-line" style={{ width: '100%', marginBottom: 30, height: 'fit-content' }}>
                  <thead>
                    <tr>
                      <th colSpan={12}>2. 상세정보(인프라-파트너)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partnerInfos?.infraPartners?.map((ele, idx) => {
                      return (
                        <Fragment key={`infraPartners_${idx}`}>
                          <tr>
                            <th>인프라</th>
                            <td colSpan={10} className="no-maxWidth">
                              <SelectD
                                value={
                                  ele.infraSeq
                                    ? {
                                        value: ele.infraSeq,
                                        label: MASTER_OBJ?.INFRA?.[ele.infraSeq],
                                      }
                                    : null
                                }
                                options={MASTER_OPS?.INFRA}
                                onChange={({ value }) => {
                                  const _partnerInfos = JSON.parse(JSON.stringify(partnerInfos));
                                  _partnerInfos.infraPartners[idx].infraSeq = value;
                                  setpartnerInfos(_partnerInfos);
                                }}
                              />
                            </td>
                            <td rowSpan={3} style={{ width: 60 }}>
                              <button
                                className="btn btn-danger sssm"
                                onClick={() => {
                                  const _partnerInfos = JSON.parse(JSON.stringify(partnerInfos));
                                  const infraPartners = _partnerInfos?.infraPartners.filter((ele, i) => i !== idx);
                                  _partnerInfos.infraPartners = infraPartners;
                                  setpartnerInfos(_partnerInfos);
                                }}
                              >
                                삭제
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <th>운영창고</th>
                            <td colSpan={10}>
                              <SelectD
                                isMulti
                                height100
                                options={MASTER_OPS?.CENTER_AUTH}
                                value={ele.centerCodes?.map((ele) => {
                                  return {
                                    value: ele,
                                    label: MASTER_OBJ?.CENTER_AUTH?.[ele],
                                  };
                                })}
                                onChange={(opts) => {
                                  const _partnerInfos = JSON.parse(JSON.stringify(partnerInfos));
                                  _partnerInfos.infraPartners[idx].centerCodes = (opts as OptionItem[]).map((ele) => ele.value);
                                  setpartnerInfos(_partnerInfos);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>주문비율 (%)</th>
                            <td colSpan={10}>
                              <InputD
                                type="number"
                                value={ele?.percentage || ''}
                                onChange={(e) => {
                                  const _partnerInfos = JSON.parse(JSON.stringify(partnerInfos));
                                  _partnerInfos.infraPartners[idx].percentage = e.target.value;
                                  setpartnerInfos(_partnerInfos);
                                }}
                              />
                            </td>
                          </tr>
                          {idx !== partnerInfos?.infraPartners?.length - 1 && (
                            <tr style={{ textAlign: 'center', backgroundColor: '#EFF2F7' }}>
                              <td colSpan={12} />
                            </tr>
                          )}
                        </Fragment>
                      );
                    })}
                    <tr style={{ textAlign: 'center', backgroundColor: '#EFF2F7' }}>
                      <td colSpan={12}>
                        <button
                          className="btn btn-etc"
                          onClick={() => {
                            const infraPartners = partnerInfos?.infraPartners || [];
                            infraPartners.push({});

                            setpartnerInfos((prev) => {
                              return {
                                ...prev,
                                infraPartners,
                              };
                            });
                          }}
                        >
                          추가
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
              <div style={{ display: currentTab === 1 ? 'block' : 'none' }}>
                <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
                  <thead>
                    <tr>
                      <th colSpan={12}>세부사항 사진첨부</th>
                    </tr>
                  </thead>
                </table>
                <div className="grid-wrapper">
                  {files?.map((photo, i) => {
                    return (
                      <div className="grid-ele" key={`PARTNER${i}_${photo.uploadUrl}}`}>
                        <div className="photo-zone">
                          <span className="bck-font">사진{i + 1}</span>
                          {photo && (
                            <div style={{ display: 'inline-block' }}>
                              <img src={CloseImg} id={`PARTNER_${i}`} alt="" onClick={handleFileRemove} className="closeBtn" />
                              <ImageModal photoArray={files} idx={i} title={'사진'} />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="grid-ele">
                    <div className="photo-zone">
                      <span className="bck-font">추가</span>
                      <input
                        //
                        style={{ display: 'none' }}
                        type="file"
                        multiple
                        accept="image/*"
                        id={'PHOTO'}
                        onChange={handleFileUpload}
                      />
                      <Button size="md" color="primary" outline>
                        <Label for={`PHOTO`} style={{ marginBottom: 0 }}>
                          ㅤㅤㅤ파일 추가
                        </Label>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: currentTab === 2 ? 'flex' : 'none' }}>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <div className="button-container" style={{ width: 350 }}>
                    {MASTER_OPS?.WEBHOOK_TYPE?.map((webhook, idx) => {
                      return (
                        <div className="cat" key={`webhook_btn_${idx}`}>
                          <label>
                            <input
                              type="checkbox"
                              checked={new Set(whArr)?.has(webhook.value)}
                              style={{ marginRight: 10 }}
                              onChange={() => {
                                const set = new Set(whArr);
                                if (!set.has(webhook.value)) {
                                  set.add(webhook.value);
                                  const _ = JSON.parse(JSON.stringify(webHooks));
                                  _.push({
                                    type: webhook.value,
                                    target: '',
                                  });
                                  setWebHooks(_);
                                } else {
                                  set.delete(webhook.value);
                                  setWebHooks(webHooks.filter((ele) => ele.type !== webhook.value));
                                }
                                setWhArr(Array.from(set));
                              }}
                            />
                            <span>{webhook?.label}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <table className="border-table detail-table" style={{ width: 800, marginBottom: 30, height: 'fit-content' }}>
                    <thead>
                      <tr>
                        <th colSpan={12}>4. 개발정보(Web Hook)</th>
                      </tr>
                      <tr>
                        <th colSpan={2} style={{ fontSize: 10 }}>
                          메뉴명
                        </th>
                        <th colSpan={8}>url</th>
                        <th colSpan={2} style={{ fontSize: 10 }}>
                          스케줄러 <br />
                          사용여부
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {webHooks?.map((wh, i) => {
                        return (
                          <tr key={`webhook_with_tone2_${i}`}>
                            <th colSpan={2}>{MASTER_OBJ?.WEBHOOK_TYPE?.[wh?.type]}</th>
                            <td colSpan={8}>
                              <InputD
                                value={wh.target || ''}
                                onChange={(e) => {
                                  const data = e.target.value;
                                  const _ = [...webHooks];
                                  _[i]['target'] = data;
                                  setWebHooks(_);
                                }}
                              />
                            </td>
                            <td colSpan={2} style={{ textAlign: 'center' }}>
                              <input
                                type="checkbox"
                                //
                                id={`webhook_schedulerFlag_${i}`}
                                checked={wh.schedulerFlag === true}
                                onChange={(e) => {
                                  const _ = [...webHooks];
                                  _[i]['schedulerFlag'] = e.target.checked;
                                  setWebHooks(_);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          </div>
        </>
      }
    />
  );
};
