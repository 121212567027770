import { useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// component
import Tab1 from './changeFareList';

// options

import { RootState } from 'redux/store';
import { InvoiceDetailSearchDTO } from 'interface/order';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { TopTabController } from 'components/template/TopTabController';
import { nullingMASTER } from 'redux/services/menuSlice';
export interface IDetailObjProps {
  visible: boolean;
  item?: InvoiceDetailSearchDTO;
}

export const MANAGE_PARCEL_FEE_CHANGE_FARE_MASTER = ['PARCEL_FEE_CHANGE_FARE_STATUS', 'SELLER_FORWARDER_AUTH', 'SELLER_FORWARDER_WHOLE'];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_FEE_CHANGE_FARE_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PARCEL_FEE_CHANGE_FARE_LIST: <Tab1 tabId={'PARCEL_FEE_CHANGE_FARE_LIST'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
