import { DragEvent, ForwardedRef, ReactNode } from 'react';
import handleDrag from './handleDrag.png';
import close from 'assets/images/close/cross.png';
import { useEffect, useRef, useState } from 'react';
import transparent from './transparent.png';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import * as IGrid from 'aui-grid';
import GridBox, { IPagingGrid, defaultGridProps } from 'common/grid/gridBox';
import AUIGrid from 'modules/Grid';
import PrintGridBox from 'common/grid/printGridBox';
import { BottomSheetWrapper } from './bottomSheet';

const color = ['#62a03f', '#2a5af7', '#fd5c51', '#90752e'];

// const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
//   dispatch(setLoading('GET'));
//   const searchObj = JSON.parse(e.item.searchObj);

//   const gridButtonhandler = (e) => {
//     const id = e.target.id;
//     if (id === 'INVENTORY_MODEL_BTMSHEET_EXCEL') {
//       bottomGridRef.current.exportAsXlsx({ fileName: '실시간 제품별 재고현황(상세)' });
//     }
//   };
//   const buttons = printFunctionToBtns(
//     gridFunctions?.filter((ele) => !ele.functionId?.includes('SHOW')),
//     gridButtonhandler,
//   );
//   const data = await inventoryService.getInventoryModelDetail(e.item.modelStockSeq, searchObj);
//   const rows = data?.map((row) => {
//     return {
//       ...row,
//       centerCodeKr: row.centerCode ? selectlabel(row.centerCode, masterOptions?.CENTER) : '전체',
//       partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
//       modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
//       modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
//       infraSeqKr: row.infraName,
//       customerTotal: row.move + row.repair,
//       searchObj: JSON.stringify(searchObj),
//     };
//   });

//   setBottomSheetObj({
//     visible: true,
//     hasTabs: [
//       {
//         title: '실시간 제품별 재고현황 상세',
//         gridRef: bottomGridRef,
//         columns: detailColumns,
//         rows,
//         buttons,
//         options: {
//           showRowAllCheckBox: false,
//           showRowCheckColumn: false,
//         },
//       },
//     ],
//   });
//   dispatch(setLoading(null));
// };

interface IGridTab {
  title?: string;
  isPaging?: boolean;
  gridRef?: ForwardedRef<IPagingGrid> | ForwardedRef<AUIGrid>;
  excelGridRef?: ForwardedRef<IPagingGrid> | ForwardedRef<AUIGrid>;
  columns?: IGrid.Column[];
  rows?: any[];
  bindCellEdit?: (e: IGrid.CellEditEndEvent) => void;
  buttons?: ReactNode | ReactNode[];
  options?: IGrid.Props;
}

export interface IBottomSheetObj {
  visible?: boolean;
  hasTabs?: IGridTab[];
}

export const defaultBottomSheetObj: IBottomSheetObj = {
  visible: false,
  hasTabs: [],
};

// 이 파일을 쓰세요 *****
export const BottomSheets = ({ bottomSheetObj, setBottomSheetObj }) => {
  const { openOrclose } = useSelector((state: RootState) => state.menu);
  const { visible, hasTabs } = bottomSheetObj;
  const [nowTab, setNowTab] = useState<number>(0);
  const defaultTop = 150;
  const [top, setTop] = useState<number>(650);
  const [tmp, setTmp] = useState<number>();
  const ghostEle = useRef(null);
  // const [maxHeight, _] = useState<number>(document.body.offsetHeight - 400); //NOTE : 신규 디자인인 경우 document.body.offsetHeight값이 0이 할당되어 오류 발생

  useEffect(() => {
    ghostEle.current = new Image();
    ghostEle.current.src = transparent;
  }, []);

  useEffect(() => {
    hasTabs?.forEach((ele) => {
      if (ele?.gridRef && typeof ele?.gridRef !== 'function') ele?.gridRef?.current?.resize();
    });
  }, [openOrclose?.side]);

  const onDragStart = (e: DragEvent) => {
    e.dataTransfer.setDragImage(ghostEle.current, 0, 0);
    if (e.clientY > defaultTop) {
      setTmp(e.clientY);
    }
  };

  const onDragOver = (e: DragEvent) => {
    if (e.clientY > defaultTop) {
      setTmp(e.clientY);
      // setTop(top - (tmp - e.clientY) < maxHeight ? top - (tmp - e.clientY) : maxHeight);
      setTop(top - (tmp - e.clientY));
    }
  };

  // 어플용
  const onTouchStart = (e: any) => {
    const y = e.changedTouches?.[0].pageY;
    if (y > defaultTop) setTmp(y);
  };
  const onTouchEnd = (e: any) => {
    const y = e.changedTouches?.[0].pageY;
    if (y > defaultTop) {
      setTmp(y);
      // setTop(top - (tmp - y) < maxHeight ? top - (tmp - y) : maxHeight);
      setTop(top - (tmp - y));
    }
  };

  useEffect(() => {
    if (bottomSheetObj?.visible) {
      bottomSheetObj?.hasTabs?.forEach((tab: IGridTab) => {
        if (tab?.rows) {
          if (typeof tab.gridRef !== 'function') {
            tab.gridRef.current.setGridData(tab.rows);
            if (tab.bindCellEdit) tab.gridRef.current.bind('cellEditEnd', tab.bindCellEdit);
          }
        }
      });
    }
  }, [bottomSheetObj]);

  return (
    <BottomSheetWrapper
      style={{ top }}
      visible={visible}
      openOrclose={openOrclose.side}
      className={'sheetWrapper'}
      draggable
      onTouchStart={onTouchStart}
      onTouchMove={onTouchEnd}
      onTouchEnd={onTouchEnd}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
    >
      <div className={'sheetBody'}>
        <div
          className={'sheetHandler'}
          style={{
            cursor: 'grab',
          }}
        >
          <img alt="드래그 핸들" src={handleDrag} className={'dragImg'} />
          <img
            alt="닫기"
            src={close}
            className="closeImg"
            onClick={() => {
              setBottomSheetObj({ ...bottomSheetObj, visible: false });
            }}
          />
        </div>

        <div className={'sheetContent'}>
          <div className="div-top-tab" style={{ position: 'relative', marginBottom: 0 }}>
            {hasTabs?.map((ele: IGridTab, i: number) => {
              return (
                <div className={i === nowTab ? 'bordering-current' : 'bordering'} onClick={() => setNowTab(i)} key={`tab_${i}`}>
                  <li>{ele.title}</li>
                </div>
              );
            })}
          </div>

          {hasTabs?.map((ele: IGridTab, i: number) => {
            return (
              <div className="presenterGridBox" style={{ position: 'absolute', zIndex: i === nowTab ? 10 : 5, width: '100%' }} key={`grid_${i}`}>
                <div
                  className="grid-button-area"
                  style={
                    {
                      // backgroundColor: color[i]
                    }
                  }
                >
                  {ele?.buttons}
                </div>
                {ele?.isPaging ? (
                  <GridBox gridRef={ele?.gridRef as ForwardedRef<IPagingGrid>} columns={ele?.columns} gridProps={{ height: 500, usePaging: true, ...ele?.options }} />
                ) : (
                  <GridBox gridRef={ele?.gridRef as ForwardedRef<AUIGrid>} columns={ele?.columns} gridProps={{ height: 500, usePaging: true, ...ele?.options }} />
                )}
                {ele?.excelGridRef && <PrintGridBox gridRef={ele?.excelGridRef} columns={ele?.columns} />}
              </div>
            );
          })}
        </div>
      </div>
    </BottomSheetWrapper>
  );
};
