import { ReactNode, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
// service
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useGridButton from 'hooks/grid/useGridButton';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
// component
import { PagingListDTO } from 'interface/util';
import {
  //
  ParcelLinehaulDTO,
  ParcelLinehaulLoadDTO,
  ParcelLinehaulLoadExcelDTO,
  ParcelLinehaulSearchDTO,
  ParcelLinehaulUnloadDTO,
  ParcelLinehaulUnloadExcelDTO,
} from '../../../_interface/parcel';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { serviceStore } from 'services/services';

export const defaultOrderDetailObj = {
  visible: false,
  item: null,
};

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<IPagingGrid>();
  const bottomGridRef2 = useRef<IPagingGrid>();
  const bottomExcelGridRef = useRef<IPagingGrid>();
  const bottomExcelGridRef2 = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const labellingKr = (data: PagingListDTO<ParcelLinehaulDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        statusKr: MASTER_OBJ?.PLINE_STATUS?.[row.status],
        originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.originalCenterCode],
        destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.destinationCenterCode],
        driverCarTypeKr: MASTER_OBJ?.CAR_TYPE?.[row.driverCarType],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: ParcelLinehaulSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.parcelAction(`linehaul/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<ParcelLinehaulDTO>);

    dispatch(setLoading(null));
    return dataKr;
  };

  const downloadExcel = async (type: 'main' | 'load' | 'unload') => {
    const prompt = window.confirm(`엑셀다운로드를 시작합니다. \n※ 많은 양의 데이터는 30초 이상의 시간이 소요될 수 있습니다.`);
    if (prompt) {
      dispatch(setLoading('GET'));
      const { list } = labellingKr(
        {
          list:
            type === 'main'
              ? ((await serviceStore.parcelAction(`linehaul/excel`, 'GET', searchObj, null))?.data as ParcelLinehaulDTO[])
              : type === 'load'
              ? ((await serviceStore.parcelAction(`linehaul/load/excel`, 'GET', searchObj, null))?.data as ParcelLinehaulLoadExcelDTO[])
              : ((await serviceStore.parcelAction(`linehaul/unload/excel`, 'GET', searchObj, null))?.data as ParcelLinehaulUnloadExcelDTO[]),
        },
        true,
      );
      if (type === 'main') {
        excelGridRef.current.setGridData(list);
        excelGridRef.current.exportAsXlsx({ fileName: '택배간선' });
      } else if (type === 'load') {
        excelDetailGridRef.current.setGridData(list);
        excelDetailGridRef.current.exportAsXlsx({ fileName: '택배간선(상차)' });
      } else {
        excelDetailGridRef.current.setGridData(list);
        excelDetailGridRef.current.exportAsXlsx({ fileName: '택배간선(하차)' });
      }
      dispatch(setLoading(null));
    }
  };

  const unloadLinehaul = async (items) => {
    if (window.confirm(`하차완료 하시겠습니까?`)) {
      const rs = await serviceStore.parcelAction(
        `linehaul/unload`,
        'POST',
        null,
        items?.map((ele) => {
          return {
            parcelLinehaulSeq: ele.parcelLinehaulSeq,
            originalCenterCode: ele.originalCenterCode,
            destinationCenterCode: ele.destinationCenterCode,
          };
        }),
      );

      if (rs?.status === 200) {
        alert(`출차확정이 완료되었습니다!`);
        wrappedFetchList(searchObj);
      }
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'PARCEL_LINEHAUL_EXCEL_MAIN') {
      downloadExcel('main');
    } else if (id === 'PARCEL_LINEHAUL_EXCEL_DETAIL') {
      downloadExcel('load');
    } else if (id === 'PARCEL_LINEHAUL_EXCEL_UNLOAD_DETAIL') {
      downloadExcel('unload');
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'PARCEL_LINEHAUL_UNLOAD') {
        if (items?.length > 1) {
          alert('한건씩 처리 가능합니다');
        } else {
          unloadLinehaul(items);
        }
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const openDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const item = e.item;
    const searchData = {
      parcelLinehaulSeq: item?.parcelLinehaulSeq,
      originalCenterCode: item?.originalCenterCode,
      destinationCenterCode: item?.destinationCenterCode,
    };
    //

    const [data, data2] = await Promise.all([
      (await serviceStore.parcelAction(`linehaul/load`, 'GET', searchData, null))?.data,
      (await serviceStore.parcelAction(`linehaul/unload`, 'GET', searchData, null))?.data,
    ]);

    const rows = (data as ParcelLinehaulLoadDTO[])?.map((row) => {
      return {
        ...row,
        searchObj: JSON.stringify(searchObj),
        originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.originalCenterCode],
        destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.destinationCenterCode],
        receiverAddr: row.receiverAddr1 + row.receiverAddr2,
      };
    });
    const rows2 = (data2 as ParcelLinehaulUnloadDTO[])?.map((row) => {
      return {
        ...row,
        searchObj: JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.originalCenterCode],
        destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.destinationCenterCode],
        receiverAddr: row.receiverAddr1 + row.receiverAddr2,
        typeKr: MASTER_OBJ?.PLINE_UNLOAD_TYPE?.[row.type],
      };
    });

    const gridButtonhandler = async (e) => {
      const id = e.target.id;
      if (id === 'sangcha') {
        const { data } = await serviceStore.parcelAction(`linehaul/load/excel`, 'GET', searchData, null);
        const rows = (data as ParcelLinehaulLoadExcelDTO[])?.map((row) => {
          return {
            ...row,
            searchObj: JSON.stringify(searchObj),
            originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.originalCenterCode],
            destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.destinationCenterCode],
            receiverAddr: row.receiverAddr1 + row.receiverAddr2,
          };
        });
        bottomExcelGridRef.current.setGridData(rows);
        bottomExcelGridRef.current.exportAsXlsx({ fileName: `택배간선번호_${item?.parcelLinehaulSeq}_상차상세` });
      } else if (id === 'hacha') {
        const { data } = await serviceStore.parcelAction(`linehaul/unload/excel`, 'GET', searchData, null);
        const rows2 = (data as ParcelLinehaulUnloadExcelDTO[])?.map((row) => {
          return {
            ...row,
            searchObj: JSON.stringify(searchObj),
            centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
            originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.originalCenterCode],
            destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.destinationCenterCode],
            receiverAddr: row.receiverAddr1 + row.receiverAddr2,
            typeKr: MASTER_OBJ?.PLINE_UNLOAD_TYPE?.[row.type],
          };
        });
        bottomExcelGridRef2.current.setGridData(rows2);
        bottomExcelGridRef2.current.exportAsXlsx({ fileName: `택배간선번호_${item?.parcelLinehaulSeq}_하차상세` });
      }
    };

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '상차조회',
          gridRef: bottomGridRef,
          excelGridRef: bottomExcelGridRef,
          columns: loadColumns,
          rows,
          buttons: [
            <div key={`excel`} className={`green`} id={`sangcha`} onClick={gridButtonhandler}>
              엑셀다운
            </div>,
          ],
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
        {
          title: '하차조회',
          gridRef: bottomGridRef2,
          excelGridRef: bottomExcelGridRef2,
          columns: unloadColumns,
          rows: rows2,
          buttons: [
            <div key={`excel`} className={`green`} id={`hacha`} onClick={gridButtonhandler}>
              엑셀다운
            </div>,
          ],
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
      ],
    });
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      dataField: 'detail',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: openDetail,
      },
    },
    {
      headerText: '택배간선번호',
      dataField: 'parcelLinehaulSeq',
    },
    {
      headerText: '출차일',
      dataField: 'confirmDate',
    },
    {
      headerText: '하차완료일',
      dataField: 'unloadDate',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '상차지',
      dataField: 'originalCenterCodeKr',
    },
    {
      headerText: '하차지',
      dataField: 'destinationCenterCodeKr',
    },
    {
      headerText: '상차수량',
      dataField: 'loadQuantity',
    },
    {
      headerText: '하차수량',
      children: [
        {
          headerText: '합계',
          dataField: 'unloadQuantity',
        },
        {
          headerText: '정상',
          dataField: 'normalQuantity',
        },
        {
          headerText: '사고',
          dataField: 'accidentQuantity',
        },
        {
          headerText: '오입고',
          dataField: 'wrongQuantity',
        },
      ],
    },
    {
      headerText: '운송정보',
      children: [
        {
          headerText: '운송기사명',
          dataField: 'driverName',
        },
        {
          headerText: '연락처',
          dataField: 'driverPhone',
        },
        {
          headerText: '차량종류',
          dataField: 'driverCarTypeKr',
        },
        {
          headerText: '차량번호',
          dataField: 'driverCarNumber',
        },
        {
          headerText: '메모',
          dataField: 'remark',
        },
      ],
    },
  ];

  const loadColumns: IGrid.Column[] = [
    {
      headerText: '택배간선번호',
      dataField: 'parcelLinehaulSeq',
    },
    {
      headerText: '출차일',
      dataField: 'confirmDate',
    },
    {
      headerText: '하차완료일',
      dataField: 'unloadDate',
    },
    {
      headerText: '운송장번호',
      dataField: 'parcelInvoiceNumber',
    },
    {
      headerText: '배달지',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '상차지',
      dataField: 'originalCenterCodeKr',
    },
    {
      headerText: '하차지',
      dataField: 'destinationCenterCodeKr',
    },
    {
      headerText: '수령인정보',
      children: [
        {
          headerText: '수령인',
          dataField: 'receiverName',
        },
        {
          headerText: '우편번호',
          dataField: 'receiverZipcode',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr',
        },
        {
          headerText: '연락처',
          dataField: 'receiverPhone',
        },
      ],
    },
  ];

  const unloadColumns: IGrid.Column[] = [
    {
      headerText: '택배간선번호',
      dataField: 'parcelLinehaulSeq',
    },
    {
      headerText: '출차일',
      dataField: 'confirmDate',
    },
    {
      headerText: '하차완료일',
      dataField: 'unloadDate',
    },
    {
      headerText: '운송장번호',
      dataField: 'parcelInvoiceNumber',
    },
    {
      headerText: '배달지',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '상차지',
      dataField: 'originalCenterCodeKr',
    },
    {
      headerText: '하차지',
      dataField: 'destinationCenterCodeKr',
    },
    {
      headerText: '하차유형',
      dataField: 'typeKr',
    },
    {
      headerText: '수령인정보',
      children: [
        {
          headerText: '수령인',
          dataField: 'receiverName',
        },
        {
          headerText: '우편번호',
          dataField: 'receiverZipcode',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr',
        },
        {
          headerText: '연락처',
          dataField: 'receiverPhone',
        },
      ],
    },
  ];
  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}

        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            pageRowCount: GRID_PAGE_ROW_COUNT,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={loadColumns} />
      </div>
    </div>
  );
};

export default Index;
