import { useState, useEffect } from 'react';

// component
import { SearchBox } from './component/searchBox';

import { useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { deliveryService } from '../../_services/service';
import { InvoiceCenterSearchDTO, InvoiceListDTO } from 'interface/order';
import styled from 'styled-components';
import { NoDriver } from './component/noDriver';
import { HasDriver } from './component/hasDriver';
import { setDataInUrlMain, setDataInUrlSearchObj, setLoading } from 'redux/services/menuSlice';
import { ThisDriver } from './component/thisDriver';
import { NotThisDriver } from './component/notThisDriver';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { getToday } from 'common/util/dateParsingFn';
import { IData, IMsgObj, MessagePops } from './component/messagePops';
import { SCHEDULE_CONFIRM_ALL } from 'envVar';
import { httpClient } from 'common/http-client/axiosConfig';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  min-height: 104%;
`;

export const Title = styled.h3``;

export interface InvoiceListDTOKr extends InvoiceListDTO {}

export interface IDriverObj {
  data: InvoiceListDTOKr[];
  searchObj: InvoiceCenterSearchDTO;
  fetchFn?: (data: InvoiceCenterSearchDTO) => Promise<void>;
}

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { masterOptions, tabs } = useSelector((state: RootState) => state.menu);
  const location = useLocation();
  const [searchObj, setSearchObj] = useState<InvoiceCenterSearchDTO>({ deliveryDate: getToday(), centerCode: null });
  const [hasDriverObj, setHasDriverObj] = useState<IDriverObj>();
  const [noDriverObj, setNoDriverObj] = useState<IDriverObj>();
  const [rawData, setRawData] = useState<IData>();

  const validationForFetchList = (searchObj: InvoiceCenterSearchDTO) => {
    if (!searchObj?.deliveryDate) {
      alert('지정일은 필수 겁색자입니다!');
      return false;
    }
    if (!searchObj?.centerCode) {
      alert('창고는 필수 겁색자입니다!');
      return false;
    }
    return true;
  };

  const fetchList = async (searchObj: InvoiceCenterSearchDTO): Promise<void> => {
    if (validationForFetchList(searchObj)) {
      dispatch(setLoading('GET'));
      const rsdata = await deliveryService.getOrderScheduleByCenter(searchObj);
      if (rsdata) {
        const data = rsdata?.map((row) => {
          return {
            ...row,
            feeTypeKr: selectlabel(row.feeType, masterOptions?.FEE_TYPE),
            invoiceStatusKr: selectlabel(row.invoiceStatus, masterOptions?.INVOICE_STATUS),
            regionKr: row.city + ' ' + row.area,
          };
        });

        dispatch(setDataInUrlSearchObj({ key: tabs[location.pathname], value: searchObj }));
        dispatch(setDataInUrlMain({ key: tabs[location.pathname], value: JSON.stringify(data) }));
        console.log(data);
        divideData(data);
      }
      dispatch(setLoading(null));
    }
  };

  const divideData = (data: InvoiceListDTO[]) => {
    if (searchObj?.userId) {
      setHasDriverObj({ searchObj, data: data.filter((ele) => ele.userId === searchObj?.userId), fetchFn: (searchObj: InvoiceCenterSearchDTO) => fetchList(searchObj) });
      setNoDriverObj({ searchObj, data: data.filter((ele) => !ele.userId || ele.userId !== searchObj?.userId), fetchFn: (searchObj: InvoiceCenterSearchDTO) => fetchList(searchObj) });
    } else {
      setHasDriverObj({ searchObj, data: data.filter((ele) => ele.userId), fetchFn: (searchObj: InvoiceCenterSearchDTO) => fetchList(searchObj) });
      setNoDriverObj({ searchObj, data: data.filter((ele) => !ele.userId), fetchFn: (searchObj: InvoiceCenterSearchDTO) => fetchList(searchObj) });
    }
    setRawData({
      rawData: data,
      thisDriver: data.filter((ele) => ele.userId === searchObj?.userId),
      hasDriver: data.filter((ele) => ele.userId),
    });
  };

  const confirmAllSchedule = async () => {
    const orders = searchObj?.userId
      ? rawData?.thisDriver?.filter((ele) => ele.deliveryDate && !ele.confirmFlag && ele.invoiceStatus === 'ASSIGN')
      : rawData?.hasDriver?.filter((ele) => ele.deliveryDate && !ele.confirmFlag && ele.invoiceStatus === 'ASSIGN');
    if (orders?.length > 0) {
      console.log(orders);
      if (window.confirm(`아래 테이블의 미확정 주문(${orders?.length}건)을 일괄 확정하시겠습니까?`)) {
        //
        dispatch(setLoading('PUT'));
        const dataArr = orders.map((ele) => {
          return { invoiceSeq: ele.invoiceSeq };
        });
        await httpClient.post(SCHEDULE_CONFIRM_ALL, dataArr).then((rs) => {
          if (rs?.status === 200) {
            alert('일괄 확정 되었습니다!');
            hasDriverObj?.fetchFn(hasDriverObj?.searchObj);
          }
        });
        dispatch(setLoading(null));
      }
    } else {
      alert('확정할 미확정 주문건이 없습니다!');
    }
  };

  const [msgObj, setMsgObj] = useState<IMsgObj>();

  return (
    <div className="page-content">
      <div className="presenterSearch" style={{ marginTop: 0 }}>
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={fetchList} />
      </div>
      <div className="grid-button-area only-right">
        <div
          className="orange"
          onClick={() => {
            confirmAllSchedule();
          }}
        >
          일괄 스케줄 확정
        </div>
      </div>
      <Wrapper>
        {msgObj?.visible && <MessagePops msgObj={msgObj} setMsgObj={setMsgObj} />}
        {searchObj?.userId ? (
          <>
            <NotThisDriver noDriverObj={noDriverObj} />
            <ThisDriver hasDriverObj={hasDriverObj} />
          </>
        ) : (
          <>
            <NoDriver noDriverObj={noDriverObj} />
            <HasDriver hasDriverObj={hasDriverObj} />
          </>
        )}
      </Wrapper>
    </div>
  );
};

export default Index;
