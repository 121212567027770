import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { orderService } from '../../_services/service';
import { SmsLogPopup } from '../../orderListTab/common/component/smsLogPopup';
import { serviceStore } from 'services/services';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { MessageHistoryDTO, MessageHistorySearchDTO } from '../../_interface/order';

interface SmsObject {
  visible: boolean;
  data: any;
}
const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [smsObject, setSmsObject] = useState<SmsObject>({
    visible: false,
    data: null,
  });

  const columns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '고객주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '등록일',
      dataField: 'registerDatetime',
    },
    {
      headerText: '희망일',
      dataField: 'promiseDeliveryDate',
    },
    {
      headerText: '지정일(스케줄확정일)',
      dataField: 'deliveryDate',
    },
    {
      headerText: '완료일',
      dataField: 'finishDatetime',
    },
    {
      headerText: '전송시점',
      dataField: 'messageTypeKr',
    },
    {
      headerText: '메시지전송횟수',
      dataField: 'smsSendCount',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: async (event) => {
          if (Number(event.text) > 0) {
            const item = event.item;
            const detailHistory = await orderService.getMessageHistoryDetail(item.messageSeq);
            if (detailHistory?.length > 0) {
              setSmsObject({
                visible: true,
                data: detailHistory,
              });
            }
          }
        },
      },
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },

    {
      headerText: '실수령자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'receiverName',
        },
        {
          headerText: '우편번호',
          dataField: 'receiverZipcode',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr',
          width: 150,
        },
        {
          headerText: '연락처1',
          dataField: 'receiverPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'receiverPhone2',
        },
      ],
    },
    {
      headerText: '주문자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'customerName',
        },
        {
          headerText: '우편번호',
          dataField: 'customerZipcode',
        },
        {
          headerText: '주소',
          dataField: 'customerAddr',
          width: 150,
        },
        {
          headerText: '연락처1',
          dataField: 'customerPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'customerPhone2',
        },
      ],
    },
    {
      headerText: '배송메시지',
      dataField: 'content',
    },
    {
      headerText: '고객요청사항',
      dataField: 'shipmentNote',
    },
    {
      headerText: '최초문자발송일시',
      dataField: 'sendDatetime',
    },
    {
      headerText: '최초 전송자',
      dataField: 'sendId',
    },
  ];

  const labellingKr = (data: PagingListDTO<MessageHistoryDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        messageTypeKr: MASTER_OBJ?.MESSAGE_TYPE?.[row.messageType],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: MessageHistorySearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.orderAction('message/history/list/paging', 'GET', searchObj, null, true))?.data as PagingListDTO<MessageHistoryDTO>);

    gridRef.current.setGridData(dataKr);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    if (type === 'main') {
      const dataKr = labellingKr({
        list: await orderService.getMessageHistoryExcel(searchObj),
      });

      excelGridRef.current.setGridData(dataKr);
      excelGridRef.current.exportAsXlsx({ fileName: '문자발송관리' });
    }
    dispatch(setLoading(null));
  };

  const apiResend = async (items) => {
    const rs = await orderService.postMessageRetry(
      items.map((ele) => {
        return { messageSeq: ele.messageSeq };
      }),
    );
    if (rs?.status === 200) {
      alert('문자 재발송 되었습니다!');
      const searchObj = JSON.parse(items[0].searchObj);
      wrappedFetchList(searchObj);
    }
  };

  const handleCheckItems = (e) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      const id = e.target.id;
      if (id === 'MESSAGESENDMANAGE_RESEND') apiResend(items);
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'MESSAGESENDMANAGE_EXCELDOWN') {
      downloadExcel('main');
    } else {
      handleCheckItems(id);
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {smsObject.visible && <SmsLogPopup smsObj={smsObject} setSmsObj={setSmsObject} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox gridRef={gridRef} columns={columns} gridProps={{ showRowAllCheckBox: true, showRowCheckColumn: true }} />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
