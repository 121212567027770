import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { InventoryLiveModelDTO, InventoryQuantityDTO, InventoryQuantitySearchDTO } from 'interface/warehouse';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { PagingListDTO } from 'interface/util';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { serviceStore } from 'services/services';
import { IhangdangObj, InventoryDetailModal } from './component/inventoryDetail';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);
  const [detailObj, setDetailObj] = useState<IhangdangObj>();

  const labellingKr = (data: PagingListDTO<InventoryQuantityDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: row.centerCode ? selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE) : '전체',
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
        modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
        customerTotal: row.move + row.repair,
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryQuantitySearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.warehouseAction('inventory/quantity/paging', 'GET', searchObj, null, true))?.data as PagingListDTO<InventoryQuantityDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      ...defaultSearchFilter,
    },
    gridRef,
    fetchPaging,
  });

  const fetchDetailInfo = async (e: IGrid.ButtonClickEvent) => {
    console.log(e);
    setDetailObj({
      visible: true,
      items: e?.item,
      target: e?.dataField,
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품타입',
          dataField: 'modelTypeKr',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '대표상품코드',
          dataField: 'productCode',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
    },
    {
      headerText: '전체<br/>재고합계',
      dataField: 'total',
      dataType: 'numeric',
      formatString: '#,##0',
      style: 'main-total-color',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: fetchDetailInfo,
      },
    },
    {
      headerText: '양품보관재고',
      children: [
        {
          headerText: '재고합계',
          dataField: 'availableTotal',
          dataType: 'numeric',
          style: 'sub-total-color',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchDetailInfo,
          },
        },
        {
          headerText: '대기재고',
          dataField: 'availableWait',
          dataType: 'numeric',
          style: 'sub-total-color',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchDetailInfo,
          },
        },
        {
          headerText: '이동가능',
          children: [
            {
              headerText: '안전재고',
              dataField: 'available',
              dataType: 'numeric',
              headerTooltip: {
                show: true,
                tooltipHtml: '<div style="width:150px;"><div>안전재고는</div><div>어떤 주문과도 연동되지 않은</div><div>재고를 의미합니다.</div></div>',
              },
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
            {
              headerText: '주문할당',
              dataField: 'reserved',
              dataType: 'numeric',
              headerTooltip: {
                show: true,
                tooltipHtml: '<div style="width:150px;"><div>주문할당은</div><div>주문과 연동된 재고를 의미합니다.</div></div>',
              },
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
          ],
        },
        {
          headerText: '이동 불가능',
          children: [
            {
              headerText: '간선피킹할당',
              dataField: 'availableLine',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
            {
              headerText: '반출피킹할당',
              dataField: 'availableRemove',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
            {
              headerText: 'FC피킹할당',
              dataField: 'availableFc',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
          ],
        },
      ],
    },
    {
      headerText: '불용보관재고',
      children: [
        {
          headerText: '재고합계',
          dataField: 'defectedTotal',
          dataType: 'numeric',
          style: 'sub-total-color',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchDetailInfo,
          },
        },
        {
          headerText: '이동가능',
          children: [
            {
              headerText: '안전재고',
              dataField: 'defected',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
          ],
        },
        {
          headerText: '이동 불가능',
          children: [
            {
              headerText: '간선피킹할당',
              dataField: 'defectedLine',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
            {
              headerText: '반출피킹할당',
              dataField: 'defectedRemove',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
          ],
        },
      ],
    },
    {
      headerText: '기타보관재고',
      children: [
        {
          headerText: '재고합계',
          dataField: 'etcTotal',
          dataType: 'numeric',
          style: 'sub-total-color',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchDetailInfo,
          },
        },
        {
          headerText: '이동가능',
          children: [
            {
              headerText: '이전',
              dataField: 'move',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
            {
              headerText: '수리',
              dataField: 'repair',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
          ],
        },
        {
          headerText: '이동 불가능',
          children: [
            {
              headerText: '간선피킹할당',
              dataField: 'etcLine',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
            {
              headerText: 'FC피킹할당',
              dataField: 'etcFc',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
          ],
        },
      ],
    },
    {
      headerText: '이동중재고',
      children: [
        {
          headerText: '재고합계<br/>간선출고+기사배송',
          dataField: 'deliveryTotal',
          dataType: 'numeric',
          style: 'sub-total-color',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchDetailInfo,
          },
        },
        {
          headerText: '간선입고',
          children: [
            {
              headerText: '양품',
              dataField: 'availableTransitLineIn',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
            {
              headerText: '불용',
              dataField: 'defectedTransitLineIn',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
            {
              headerText: '기타',
              dataField: 'etcTransitLineIn',
              dataType: 'numeric',
              headerTooltip: {
                show: true,
                tooltipHtml: '<div style="width:150px;"><div>기타는 </div><div>이전과 수리 재고를 의미합니다.</div></div>',
              },
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
          ],
        },
        {
          headerText: '간선출고',
          children: [
            {
              headerText: '양품',
              dataField: 'availableTransitLineOut',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
            {
              headerText: '불용',
              dataField: 'defectedTransitLineOut',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
            {
              headerText: '기타',
              dataField: 'etcTransitLineOut',
              dataType: 'numeric',
              headerTooltip: {
                show: true,
                tooltipHtml: '<div style="width:150px;"><div>기타는 </div><div>이전과 수리 재고를 의미합니다.</div></div>',
              },
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
          ],
        },
        {
          headerText: '기사배송',
          children: [
            {
              headerText: '배송중',
              dataField: 'driverLoan',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
            {
              headerText: '회수중',
              dataField: 'driverCollect',
              dataType: 'numeric',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: fetchDetailInfo,
              },
            },
          ],
        },
      ],
    },
  ];

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction('inventory/quantity/excel', 'GET', searchObj, null))?.data as InventoryLiveModelDTO[],
      },
      true,
    );

    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '실시간_제품별_재고현황' });
    }

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'INVENTORY_WHOLE_EXCELMAIN') {
      downloadExcel('main');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {detailObj?.visible && <InventoryDetailModal detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area only-right">{functionBtns}</div>}
        <GridBox
          //
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            //
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            fixedColumnCount: 1,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
