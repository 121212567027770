import { useState, useEffect } from 'react';
import { defaultUnitedPageWithTopTab, UnitedPageWithTopTab } from 'components/template/topTab';

import Container1 from './container.js';

// redux
import { useSelector, useDispatch } from 'react-redux';

// options
import { centerListForlabeling, codeMasterOptionReturn, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { setMasterOptions } from 'redux/services/menuSlice';

import { driverListForLabeling } from 'common/master/codeMasterOptionReturn';

const InventoryReportUnited = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    initMasterOptions();
  }, []);

  useEffect(() => {
    if (masterOptions) {
      setTabObj({
        currentTab: 0,
        titles: ['기사출고(라이트)'],
        containers: [<Container1 />],
      });
    }
  }, [masterOptions]);

  const initMasterOptions = async () => {
    const [DRIVER_WHOLE, SELLER_WHOLE, CENTER_WHOLE, LOAN_STATUS, ORDER_HOLD_TYPE, MODEL_GROUP, FEE_TYPE, MODEL_LARGE, WH_TYPE] = await Promise.all([
      driverListForLabeling(),
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling(),
      codeMasterOptionReturn('LOAN_STATUS'),
      codeMasterOptionReturn('ORDER_HOLD_TYPE', false),
      codeMasterOptionReturn('MODEL_GROUP', false),
      codeMasterOptionReturn('FEE_TYPE', false),
      codeMasterOptionReturn('MODEL_GRP_1'),
      codeMasterOptionReturn('WH_TYPE'),
    ]);
    dispatch(
      setMasterOptions({
        WH_TYPE,
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_LBL: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        LOAN_STATUS,
        ORDER_HOLD_TYPE,
        MODEL_GROUP,
        FEE_TYPE,
        MODEL_LARGE,
        DRIVER_NAME: DRIVER_WHOLE.map((ele) => {
          return { value: ele.userId, label: ele.driverName };
        }),
        DRIVER_PHONE: DRIVER_WHOLE.map((ele) => {
          return { value: ele.userId, label: ele.phone1 };
        }),
        DRIVER_MEMBERCOUNT: DRIVER_WHOLE.map((ele) => {
          return { value: ele.userId, label: ele.memberCount };
        }),
        ORDER_TYPE: [
          { value: null, label: '선택' },
          { value: 'invoiceSeq', label: '송장번호' },
          { value: 'loanSeq', label: '기사출고번호' },
        ],
      }),
    );
  };

  return <>{masterOptions && tabObj?.titles.length > 0 && <UnitedPageWithTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default InventoryReportUnited;
