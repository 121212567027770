import {
  centerListForlabeling,
  codeMasterOptionReturn,
  codeMasterOptionsWholeReturn,
  diffCenterListReturn,
  diffcenterWholeReturn,
  driverDurationAMReturn,
  driverDurationPMReturn,
  driverListForLabeling,
  functionWholeReturn,
  InfraOptionReturn,
  infraWholeReturn,
  menuWholeReturn,
  partnerListForlabeling,
  salesChannelReturnAsOption,
  ScreenWholeReturn,
  tabWholeReturn,
  userAuthoritySelectListReturn,
  userJojikSelectListReturn,
  zipcode2ndDeliveryOptionReturn,
  zipcodeGyeonYeokOptionReturn,
  zipcodeMinGroupOptionReturn,
} from 'common/master/codeMasterOptionReturn';
import { getTimeZone, OptionItem } from 'common/master/codeMasterReturnHelper';
import { useDispatch } from 'react-redux';
import { RAWDATA, setLoading, setMASTER } from 'redux/services/menuSlice';

const masterAPIs = {
  [`${RAWDATA}DRIVER_WHOLE`]: async () => {
    return await driverListForLabeling({ selectFlag: false });
  },
  [`${RAWDATA}DRIVER_AUTH`]: async () => {
    return await driverListForLabeling();
  },
  [`${RAWDATA}CENTER_WHOLE`]: async () => {
    return await centerListForlabeling({ selectFlag: false });
  },
  [`${RAWDATA}INFRA`]: async () => {
    return await infraWholeReturn();
  },
  [`${RAWDATA}CENTER_DIFF`]: async () => {
    return await diffcenterWholeReturn();
  },
  [`${RAWDATA}SELLER_WHOLE`]: async () => {
    return await partnerListForlabeling({ selectFlag: false });
  },
  USER_GROUP: async () => {
    return await userAuthoritySelectListReturn();
  },
  DRIVER_AUTH: async () => {
    return (await driverListForLabeling())?.map((ele) => {
      return {
        label: ele.driverName,
        value: ele.userId,
      };
    });
  },
  DRIVER_WHOLE: async () => {
    return (await driverListForLabeling({ selectFlag: false }))?.map((ele) => {
      return {
        label: ele.driverName,
        value: ele.userId,
      };
    });
  },
  DRIVER_PHONE_WHOLE: async () => {
    return (await driverListForLabeling({ selectFlag: false }))?.map((ele) => {
      return {
        label: ele.phone1,
        value: ele.userId,
      };
    });
  },
  //
  CENTER_DIFF: async () => {
    return await diffCenterListReturn();
  },
  CENTER_AUTH: async () => {
    return (await centerListForlabeling())?.map((ele) => {
      return {
        label: ele.centerName,
        value: ele.centerCode,
      };
    });
  },
  CENTER_WHOLE: async () => {
    return (await centerListForlabeling({ selectFlag: false }))?.map((ele) => {
      return {
        label: ele.centerName,
        value: ele.centerCode,
      };
    });
  },
  CENTER_CALL_WHOLE: async () => {
    return (await centerListForlabeling({ selectFlag: false }))?.map((ele) => {
      return {
        label: ele.managerPhone,
        value: ele.centerCode,
      };
    });
  },
  //
  SELLER_AUTH: async () => {
    return (await partnerListForlabeling())?.map((ele) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
  },
  SELLER_WHOLE: async () => {
    return (await partnerListForlabeling({ selectFlag: false }))?.map((ele) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
  },
  SELLER_SELLER_AUTH: async () => {
    return (await partnerListForlabeling({ sellerFlag: true, selectFlag: true }))?.map((ele) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
  },
  SELLER_SELLER_WHOLE: async () => {
    return (await partnerListForlabeling({ sellerFlag: true, selectFlag: false }))?.map((ele) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
  },
  SELLER_NETWORK_AUTH: async () => {
    return (await partnerListForlabeling({ networkFlag: true, selectFlag: true }))?.map((ele) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
  },
  SELLER_NETWORK_WHOLE: async () => {
    return (await partnerListForlabeling({ networkFlag: true, selectFlag: false }))?.map((ele) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
  },
  SELLER_SUPPLIER_AUTH: async () => {
    return (await partnerListForlabeling({ supplierFlag: true, selectFlag: true }))?.map((ele) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
  },
  SELLER_SUPPLIER_WHOLE: async () => {
    return (await partnerListForlabeling({ supplierFlag: true, selectFlag: false }))?.map((ele) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
  },
  SELLER_TRANSPORT_AUTH: async () => {
    return (await partnerListForlabeling({ transportFlag: true, selectFlag: true }))?.map((ele) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
  },
  SELLER_TRANSPORT_WHOLE: async () => {
    return (await partnerListForlabeling({ transportFlag: true, selectFlag: false }))?.map((ele) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
  },
  SELLER_FORWARDER_AUTH: async () => {
    return (await partnerListForlabeling({ forwarderFlag: true, selectFlag: true }))?.map((ele) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
  },
  SELLER_FORWARDER_WHOLE: async () => {
    return (await partnerListForlabeling({ forwarderFlag: true, selectFlag: false }))?.map((ele) => {
      return {
        label: ele.partnerName,
        value: ele.partnerSeq,
      };
    });
  },
  //
  INFRA: async () => {
    return await InfraOptionReturn();
  },
  SALES_CHANNEL: async () => {
    return await salesChannelReturnAsOption();
  },
  JOJIK_GROUP: async () => {
    return await userJojikSelectListReturn();
  },

  MENU_WHOLE: async () => {
    return await menuWholeReturn();
  },
  SCREEN_WHOLE: async () => {
    return await ScreenWholeReturn();
  },
  TAB_WHOLE: async () => {
    return await tabWholeReturn();
  },
  FUNCTION_WHOLE: async () => {
    return await functionWholeReturn();
  },

  // FLAG
  NEED_FLAG: () => {
    return [
      { value: 'true', label: '필요' },
      { value: 'false', label: '불필요' },
    ];
  },
  USE_FLAG: () => {
    return [
      { value: 'true', label: '사용' },
      { value: 'false', label: '미사용' },
    ];
  },
  LITE_FLAG: () => {
    return [
      { value: 'true', label: '라이트' },
      { value: 'false', label: '일반' },
    ];
  },
  FIX_DATE: () => {
    return [
      { value: 'true', label: '지정' },
      { value: 'false', label: '미지정' },
    ];
  },
  AM: () => {
    return driverDurationAMReturn();
  },
  PM: () => {
    return driverDurationPMReturn();
  },
  UNIFORM_SIZE: () => {
    return [
      { value: 95, label: '95' },
      { value: 100, label: '100' },
      { value: 105, label: '105' },
      { value: 110, label: '110' },
    ];
  },
  REGION: () => {
    return [
      {
        label: '수도권',
        value: '수도권',
      },
      {
        label: '충청',
        value: '충청',
      },
      {
        label: '강원',
        value: '강원',
      },
      {
        label: '호남',
        value: '호남',
      },
      {
        label: '제주',
        value: '제주',
      },
      {
        label: '영남',
        value: '영남',
      },
    ];
  },
  ZIPCODE_RANGE: async () => {
    return await zipcodeGyeonYeokOptionReturn();
  },
  ZIPCODE_2ND: async () => {
    return await zipcode2ndDeliveryOptionReturn();
  },
  ZIPCODE_MIN_GROUP: async () => {
    return await zipcodeMinGroupOptionReturn();
  },
  DELIVERY_TIME: () => {
    return getTimeZone(false, true) as OptionItem[];
  },
  SEARCH_ORDER: () => {
    return [
      {
        label: '주문번호',
        value: 'orderSeq',
      },
      {
        label: '외부주문번호',
        value: 'externalOrderNumber',
      },
      {
        label: '묶음주문번호',
        value: 'transOrderNumber',
      },
      {
        label: '배송번호',
        value: 'deliverySeq',
      },
    ];
  },
  SEARCH_INVOICE: () => {
    return [
      {
        label: '송장번호',
        value: 'invoiceSeq',
      },
      {
        label: '외부송장번호',
        value: 'externalInvoiceNumber',
      },
      {
        label: '운송장번호',
        value: 'parcelInvoiceNumber',
      },
    ];
  },
  SEARCH_SKU: () => {
    return [
      { value: 'modelStockSeq', label: 'SKU' },
      { value: 'skuNumber', label: '외부SKU' },
    ];
  },
  ACTIVE_OP: () => {
    return [
      { value: 'ACTIVE', label: '운영중' },
      { value: 'END', label: '종료' },
    ];
  },
  PICK_FLAG: () => {
    return [
      { value: 'true', label: '적용' },
      { value: 'false', label: '미적용' },
    ];
  },
  MANAGE_FLAG: () => {
    return [
      { value: 'true', label: '관리' },
      { value: 'false', label: '미관리' },
    ];
  },
  STORAGE_FEE: () => {
    return [
      { value: 'DAY', label: '일' },
      { value: 'MONTH', label: '월' },
    ];
  },
  STOP_FLAG: () => {
    return [
      { value: 'true', label: '단종' },
      { value: 'false', label: '생산중' },
    ];
  },
  ACTIVE_FLAG: () => {
    return [
      { value: 'true', label: '활성' },
      { value: 'false', label: '비활성' },
    ];
  },
  OX_FLAG: () => {
    return [
      { value: 'true', label: 'O' },
      { value: 'false', label: 'X' },
    ];
  },
  INSPECT_FLAG: () => {
    return [
      { value: 'true', label: '일치' },
      { value: 'false', label: '불일치' },
    ];
  },
  CONFIRM_FLAG: () => {
    return [
      { value: 'true', label: '확정' },
      { value: 'false', label: '미확정' },
    ];
  },
  ORDER_SEQ: () => {
    return [
      { value: 'orderSeq', label: '주문번호' },
      { value: 'externalOrderNumber', label: '외부주문번호' },
      { value: 'invoiceSeq', label: '송장번호' },
      { value: 'externalInvoiceNumber', label: '외부송장번호' },
      { value: 'customerName', label: '고객명' },
    ];
  },
  SEARCH_MODEL: () => {
    return [
      { value: 'model', label: '제품' },
      { value: 'modelName', label: '제품명' },
      { value: 'barcode', label: '바코드' },
      { value: 'modelStockSeq', label: 'SKU' },
      { value: 'skuNumber', label: '외부SKU' },
      { label: '대표상품코드', value: 'productCode' },
    ];
  },
  PARCEL_FEE_CHANGE_FARE_STATUS: () => {
    return [
      { value: 'READY', label: '변경전' },
      { value: 'CONFIRM', label: '변경완료' },
    ];
  },
  WEEK_DAY: () => {
    return [
      { value: 'mon', label: '월' },
      { value: 'tue', label: '화' },
      { value: 'wed', label: '수' },
      { value: 'thu', label: '목' },
      { value: 'fri', label: '금' },
      { value: 'sat', label: '토' },
      { value: 'sun', label: '일' },
    ];
  },
  INSTALL_DURATION: () => {
    return [
      { value: 0, label: '0분' },
      { value: 10, label: '10분' },
      { value: 20, label: '20분' },
      { value: 30, label: '30분' },
      { value: 40, label: '40분' },
      { value: 50, label: '50분' },
      { value: 60, label: '60분' },
    ];
  },
  MONTH: () => {
    return new Array(28)?.fill({})?.map((ele, idx) => {
      return {
        value: idx + 1,
        label: idx + 1,
      };
    });
  },
  LIFTINGS: () => {
    return [
      {
        label: '미수행',
        value: '미수행',
      },
      {
        label: '2층 단품',
        value: '2층 단품',
      },
      {
        label: '2층 결합',
        value: '2층 결합',
      },
      {
        label: '3층 단품',
        value: '3층 단품',
      },
      {
        label: '3층 결합',
        value: '3층 결합',
      },
      {
        label: '4층 단품',
        value: '4층 단품',
      },
      {
        label: '4층 결합',
        value: '4층 결합',
      },
      {
        label: '5층 단품',
        value: '5층 단품',
      },
      {
        label: '5층 결합',
        value: '5층 결합',
      },
    ];
  },
  FLOOR: () => {
    return [
      {
        label: '2층 단품',
        value: '2층 단품',
      },
      {
        label: '2층 결합',
        value: '2층 결합',
      },
      {
        label: '3층 단품',
        value: '3층 단품',
      },
      {
        label: '3층 결합',
        value: '3층 결합',
      },
      {
        label: '4층 단품',
        value: '4층 단품',
      },
      {
        label: '4층 결합',
        value: '4층 결합',
      },
      {
        label: '5층 단품',
        value: '5층 단품',
      },
      {
        label: '5층 결합',
        value: '5층 결합',
      },
      {
        label: '6층 단품',
        value: '6층 단품',
      },
      {
        label: '6층 결합',
        value: '6층 결합',
      },
      {
        label: '7층 단품',
        value: '7층 단품',
      },
      {
        label: '7층 결합',
        value: '7층 결합',
      },
      {
        label: '8층 단품',
        value: '8층 단품',
      },
      {
        label: '8층 결합',
        value: '8층 결합',
      },
      {
        label: '9층 단품',
        value: '9층 단품',
      },
      {
        label: '9층 결합',
        value: '9층 결합',
      },
    ];
  },

  //
  OTHERS: async (masterKey: string) => {
    if (masterKey?.includes(RAWDATA)) return await codeMasterOptionsWholeReturn(masterKey?.split(RAWDATA)?.[1]);
    return await codeMasterOptionReturn(masterKey);
  },
};

export const useMasterOptions = () => {
  const dispatch = useDispatch();
  const initMasterOptions = async (dtoArr: (string | object)[]) => {
    const filter = [];
    const filteredArr = dtoArr
      ?.map((ele) => {
        if (typeof ele === 'string') {
          if (filter?.includes(ele)) {
            return null;
          } else {
            filter?.push(ele);
            return ele;
          }
        }
        return ele;
      })
      ?.filter((ele) => ele);
    dispatch(setLoading('GET'));
    const masterOBJ = {};
    const data = await Promise.all(
      filteredArr?.map(async (ele) => {
        if (typeof ele === 'string') {
          // 공통일반
          if (!masterOBJ[ele] && masterAPIs[ele]) return { [ele]: await masterAPIs[ele](null) };
          if (!masterOBJ[ele]) return { [ele]: await masterAPIs['OTHERS'](ele) };
        } else {
          return ele;
        }
      }),
    );
    data?.forEach((ele, idx) => {
      const key = Object?.keys(ele)[0];
      const value = Object?.values(ele)[0];
      masterOBJ[key] = value;
    });
    dispatch(setMASTER(masterOBJ));
    dispatch(setLoading(null));
  };

  const addMasterOptions = (dtoObj: { [key: string]: { key: string; label: string; value: string } }) => {
    // dispatch(addMASTER(dtoObj));
  };

  return {
    initMasterOptions,
    addMasterOptions,
  };
};
