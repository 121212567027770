import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { OrderDTOForTracking } from 'interface/order';
import parsingDataString from 'query-string';
import { httpClient } from 'common/http-client/axiosConfig';

// img
import closeImg from '../../../../assets/images/button/closeImg.png';
import Innoslogo from './img/innos.png';

// styled-component
import styled from 'styled-components';
import { numberTestRegExp } from 'common/util/regExp';
import { SelectedInvoice } from '../orderTrackingTab/trackInvoice';

const Wrapper = styled.div`
  min-height: 80vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  justify-content: center;
  align-items: center;
`;

const WrapperInput = styled.div`
  position: relative;
  display: flex;
`;

const SearchInput = styled.input`
  border: 1px solid rgba(217, 222, 231, 1);
  height: 50px;
  width: 250px;
  color: rgba(163, 167, 185, 1);
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 13px;
`;

const SearchButton = styled.button`
  height: 50px;
  width: 60px;
  margin-left: 20px;
  background-color: black;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  border: none;
`;

const CloseImg = styled.img`
  position: absolute;
  top: 15px;
  right: 78px;
  cursor: pointer;
`;

const Index = () => {
  const history = useNavigate();
  const location = useLocation();
  const [invoiceSeq, setInvoiceSeq] = useState<string>();
  const [infoData, setInfoData] = useState<OrderDTOForTracking>();

  useEffect(() => {
    const seq = parsingDataString.parse(location.search)?.seq as string;
    if (seq) fetchInfoData(seq);
  }, [location]);

  const fetchInfoData = async (seq: string) => {
    if (seq) {
      await httpClient.get(`/order/v2/tracking/customer?invoiceSeq=${seq}`).then((rs) => {
        if (rs?.status === 200) {
          setInfoData(rs?.data);
        }
      });
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (invoiceSeq) history(`/logis?seq=${invoiceSeq}`, { replace: true });
      else alert('송장번호를 입력해주세요!');
    }
  };

  return (
    <>
      {!infoData ? (
        <div className="page-content" style={{ margin: 0, padding: 0, height: '100vh' }}>
          <Wrapper onKeyPress={onKeyPress}>
            {location?.pathname === '/logis' && <img src={Innoslogo} style={{ width: 200 }} />}
            <WrapperInput>
              <SearchInput
                placeholder="송장번호를 입력해주세요."
                value={invoiceSeq || ''}
                onChange={(e) => {
                  if (numberTestRegExp(e.target.value)) {
                    setInvoiceSeq(e.target.value);
                  } else {
                    alert('숫자만 기입가능합니다!');
                  }
                }}
              />
              {invoiceSeq && (
                <CloseImg
                  src={closeImg}
                  onClick={() => {
                    setInvoiceSeq('');
                  }}
                />
              )}
              <SearchButton
                onClick={() => {
                  if (invoiceSeq) history(`/logis?seq=${invoiceSeq}`);
                  else alert('송장번호를 입력해주세요!');
                }}
              >
                조회
              </SearchButton>
            </WrapperInput>
          </Wrapper>
        </div>
      ) : (
        <div className="page-content">
          <Wrapper style={{ justifyContent: 'flex-start' }}>
            <SelectedInvoice selected={infoData} />
            <span
              style={{ width: '100%', textAlign: 'center', padding: '13px 0', backgroundColor: '#FF9110', fontSize: 16, color: 'white', height: 50, cursor: 'pointer' }}
              onClick={() => {
                history('/logis');
                window.location.reload();
              }}
            >
              뒤로가기
            </span>
          </Wrapper>
        </div>
      )}
    </>
  );
};

export default Index;
