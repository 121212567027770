import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { httpClient } from 'common/http-client/axiosConfig';
import { setUseableMenus } from 'redux/services/authSlice';
import { MY_BOOKMARK } from 'envVar';

const useUseableMenus = () => {
  const useableMenus = useSelector((state: RootState) => state.auth.useableMenus);
  const dispatch = useDispatch();

  const getFetchUseableMenus = async () => {
    const requestURL = '/user/my/menu';
    const result = await httpClient.get(requestURL);
    if (result?.data && result?.data?.length > 0) {
      dispatch(setUseableMenus(result.data));
      return result.data;
    }
  };

  // NOTE : Sidebar 컴포넌트 리뉴얼 하면서 menus 배열에 즐겨찾기 메뉴도 포함시키기 위해 추가했습니다.
  const getFavoriteMenus = async () => {
    const requestURL = MY_BOOKMARK;
    const result = await httpClient.get(requestURL);
    if (result?.data && result?.data?.length > 0) {
      return result.data;
    }
  };

  return { getFetchUseableMenus, getFavoriteMenus, useableMenus };
};

export default useUseableMenus;
