import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { InventoryLackDTO, OrderListDTO } from '../../_interface/order';
import { orderService } from 'pages/ORDER/1order/_services/service';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IInventoryCheck {
  [key: string]: string | number | any;
  visible?: boolean;
  item?: OrderListDTO;
  centerCodeKr?: string;
}

interface IInventoryPopup {
  inventoryCheckObj: IInventoryCheck;
  setInventoryCheckObj: React.Dispatch<React.SetStateAction<IInventoryCheck>>;
}

export const ProductInventoryCheck = ({ inventoryCheckObj, setInventoryCheckObj }: IInventoryPopup) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [data, setData] = useState<InventoryLackDTO[]>();

  const fetchData = async (invoiceSeq: number) => {
    dispatch(setLoading('GET'));
    const data = await orderService.getInvoiceInventory(invoiceSeq);
    if (data?.length > 0) setData(data);
    else setInventoryCheckObj(null);
    dispatch(setLoading(null));
  };
  useEffect(() => {
    fetchData(inventoryCheckObj?.item?.invoiceSeq);
  }, []);

  const labelingCenters = (centers) => {
    const labeled = centers.map((center) => {
      return MASTER_OBJ?.CENTER_WHOLE?.[center];
    });
    return labeled;
  };

  return (
    <>
      <CommonModalNew
        style={{ width: 1200 }}
        visible={inventoryCheckObj?.visible}
        setVisible={() => {
          setInventoryCheckObj(null);
        }}
        title="재고확인"
        children={
          <>
            {data?.length > 0 && (
              <>
                <div style={{ textAlign: 'right', padding: 5 }}>
                  <>
                    {`상품번호 : ${inventoryCheckObj?.item?.productSeq ? inventoryCheckObj?.item?.productSeq : '-'}`}
                    <span className="divider">|</span>
                    {`외부상품번호 : ${inventoryCheckObj?.item?.externalProductNumber || '-'}`}
                    <span className="divider">|</span>
                    {`상위창고 : ${data?.[0]?.parentCenterCodes?.length > 0 ? labelingCenters(data[0]?.parentCenterCodes) : '-'}`}
                    <span className="divider">|</span>
                    {`소속창고 : ${MASTER_OBJ?.CENTER_WHOLE?.[inventoryCheckObj?.item?.centerCode]}`}
                  </>
                </div>
                <table className="border-table">
                  <thead>
                    <tr className="table-primary">
                      <th rowSpan={2}>판매사</th>
                      <th rowSpan={2} style={{ width: 180 }}>
                        제품
                      </th>
                      <th rowSpan={2} style={{ width: 180 }}>
                        제품명
                      </th>
                      <th rowSpan={2}>바코드</th>
                      <th rowSpan={2}>SKU</th>
                      <th rowSpan={2}>외부SKU</th>
                      <th rowSpan={2}>
                        상위창고
                        <br />
                        사용가능재고
                      </th>
                      <th colSpan={5}>소속창고 재고</th>
                      <th rowSpan={2}>필요재고</th>
                      <th rowSpan={2}>
                        발주필요
                        <br />
                        여부
                      </th>
                    </tr>
                    <tr>
                      <th>주문할당</th>
                      <th>사용가능</th>
                      <th>사용불가능</th>
                      <th>입고예정</th>
                      <th>입고예정일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((a = {}, idx) => (
                      <tr key={`inventoryDetailList_${idx}`}>
                        <td style={{ textAlign: 'center' }}>{MASTER_OBJ?.SELLER_WHOLE?.[a.partnerSeq]}</td>
                        <td style={{ textAlign: 'center' }}>{a.model}</td>
                        <td style={{ textAlign: 'center' }}>{a.modelName}</td>
                        <td style={{ textAlign: 'center' }}>{a.barcode}</td>
                        <td style={{ textAlign: 'center' }}>{a.modelStockSeq}</td>
                        <td style={{ textAlign: 'center' }}>{a.skuNumber}</td>
                        <td style={{ textAlign: 'center' }}>{a.parentCenterAvailable}</td>
                        <td style={{ textAlign: 'center' }}>{a.reserved}</td>
                        <td style={{ textAlign: 'center' }}>{a.available}</td>
                        <td style={{ textAlign: 'center' }}>{a.unavailable}</td>
                        <td style={{ textAlign: 'center' }}>{a.future}</td>
                        <td style={{ textAlign: 'center' }}>{a.promiseDate}</td>
                        <td style={{ textAlign: 'center' }}>{a.quantity}</td>
                        <td style={{ textAlign: 'center' }}>{a.purchaseFlag === true ? '필요' : '미필요'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </>
        }
      />
    </>
  );
};
