import { KeyboardEvent } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { OrderExcelTemplateSearchDTO } from '../../../_interface/order';

export const SearchBox = (props: ISearchBoxProps<OrderExcelTemplateSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">엑셀유형</label>
          <Row>
            <SelectD
              options={MASTER_OPS?.ORDER_EXCEL_TYPE}
              value={
                searchObj?.excelType
                  ? {
                      value: searchObj?.excelType,
                      label: MASTER_OBJ?.ORDER_EXCEL_TYPE?.[searchObj?.excelType],
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  excelType: (option as OptionItem).value,
                });
              }}
            />
          </Row>
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <Row>
            <SelectD
              options={MASTER_OPS?.SELLER_AUTH}
              value={
                searchObj?.partnerSeq
                  ? {
                      value: searchObj?.partnerSeq,
                      label: MASTER_OBJ?.SELLER_AUTH?.[searchObj?.partnerSeq],
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: (option as OptionItem).value,
                });
              }}
            />
          </Row>
        </Col>
        <Col>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
