import {
  MASTER_CODE_GROUP,
  REGION_LIST,
  MASTER_INFRA,
  SALES_CHANNEL,
  INSTALL_ZIPCODE_GROUP_NAME_LIST_RANGE,
  INSTALL_ZIPCODE_GROUP_NAME_LIST_GROUP,
  INSTALL_ZIPCODE_GROUP_NAME_LIST_PACKAGE,
  USER_MY_INFO,
  USER_SCREEN_ALL,
  USER_MENU_ALL,
  CENTER_FOR_LABELING,
  DRIVER_FOR_LABELING,
  PARTNER_FOR_LABELING,
  DIFF_CENTER_LIST,
  USER_TAB_ALL,
  USER_FUNCTION_ALL,
  MY_GRID_PARAMETER,
  LOCATION_COLOUMN_LIST,
} from 'envVar';
import { httpClient } from '../http-client/axiosConfig';
import { InfraDTO, WarehouseDTO, WarehouseSelectListDTO, WarehouseSelectListSearchDTO } from 'interface/warehouse';
import { OptionItem } from './codeMasterReturnHelper';
import { GroupSelectDTO, MenuDTO, PartnerSelectListDTO, PartnerSelectListSearchDTO, UserGridDTO, UserGroupListDTO, UserGroupSelectListDTO } from 'interface/user';
import { SalesChannelDTO } from 'interface/order';
import { addingParamsToUrlexceptPaging } from 'common/util/httpclient';
import { DriverSelectListDTO, DriverSelectListSearchDTO } from 'interface/install';
import { serviceStore } from 'services/services';

export const SELLER_MASTER = [
  //
  'SELLER_AUTH_WHOLE',
  'SELLER_WHOLE',
  'SELLER_PARTNER_AUTH_WHOLE',
  'SELLER_PARTNER_WHOLE',
  'SELLER_NETWORK_AUTH_WHOLE',
  'SELLER_NETWORK_WHOLE',
  'SELLER_SUPPLIER_AUTH_WHOLE',
  'SELLER_SUPPLIER_WHOLE',
  'SELLER_TRANSPORT_AUTH_WHOLE',
  'SELLER_TRANSPORT_WHOLE',
  'SELLER_FORWARDER_AUTH_WHOLE',
  'SELLER_FORWARDER_WHOLE',
];

export const CONSTANT_FEE_TYPE = 'FEE_TYPE';
export const CONSTANT_ORDER_TYPE = 'ORDER_TYPE';
export const CONSTANT_INVOICE_STATUS = 'INVOICE_STATUS';

export const LANG_AVAILABLE = [CONSTANT_FEE_TYPE, CONSTANT_ORDER_TYPE, CONSTANT_INVOICE_STATUS];

export const CODEMASTER_NEED_AUTH = [
  //
  'CENTER_AUTH_WHOLE',
  'CENTER_WHOLE',
  'DRIVER_AUTH_WHOLE',
  'DRIVER_WHOLE',
  'SELLER_PARTNER_AUTH_WHOLE',
  'SELLER_PARTNER_WHOLE',
  'SELLER_NETWORK_AUTH_WHOLE',
  'SELLER_NETWORK_WHOLE',
  'SELLER_SUPPLIER_AUTH_WHOLE',
  'SELLER_SUPPLIER_WHOLE',
  'SELLER_TRANSPORT_AUTH_WHOLE',
  'SELLER_TRANSPORT_WHOLE',
  'SELLER_FORWARDER_AUTH_WHOLE',
  'SELLER_FORWARDER_WHOLE',
  'DIFF_CENTER',
  //
  'SELLER_AUTH_WHOLE',
  'SELLER_WHOLE',
  //
  'INFRA',
  'SALES_CHANNEL',
];

export const CODEMASTER_GROUP = [
  CONSTANT_FEE_TYPE,
  CONSTANT_ORDER_TYPE,
  CONSTANT_INVOICE_STATUS,
  'SALES_CHANNEL_TYPE',
  'CANCEL_REASON',
  'COLLECT_REASON',
  'SERVICE_TYPE',
  'PMODEL_TYPE',
  'MODEL_TYPE',
  'MODEL_GROUP',
  'RESCHEDULE_TYPE',
  'EOL_GROUP2',
  'EOL_GROUP3',
  'BODY_WIFI_REASON',
  'COUNSEL_DETAIL_TYPE',
  'COUNSEL_TYPE',
  'LADDER_CO',
  'MODEL_GRP_1',
  'MODEL_GRP_2',
  'MODEL_GRP_3',
  'CANCEL_TYPE',
  'MESSAGE_TYPE',
  'INVENTORY_LOCATION',
  'PROVINCE',
  'CITY',
  'DRIVER_PARTY',
  'TEAM_GRADE',
  'DRV_POS',
  'CAR_TYPE',
  'DRV_TYPE',
  'TEAM_TYPE',
  'LOGI_TYPE',
  'PACKING_STATE',
  'INV_TRACK_TYPE',
  'PARCEL_PAY_TYPE',
  'INVOICE_UPDATE_CONTENT',
  'INSTALL_FORM',
  'SALES_CHANNEL_TYPE',
  'PO_STATUS',
  'REMOVE_REASON',
  'LOADING_OUT_STAT',
  'INVA_TYPE',
  'ORDER_HOLD_TYPE',
  'REMOVE_STATUS',
  'LINE_STAT',
  'BRAND',
  'LINEHAUL_TYPE',
  'PALLET_TYPE',
  'MODEL_GRP_DIV',
  'WH_TYPE',
  'LOAN_COMP_STATUS',
  'LOAN_STATUS',
  'TOSS_PAYMENT_STATUS',
  'LOADING_STAT',
  'LOAN_PICK_TYPE',
  'LOAN_PICK_CLOSE_REASON',
  'PARCEL_BOX_BARCODE',
  'TRANSPORT_STAT',
  'PAYMENT_CANCEL',
  'ORDER_EXCEL_TYPE',
  'USER_CODE',
  'CM_STATUS',
  'CM_STATUS_1',
  'CM_STATUS_2',
  'CM_USAGE_DIVISION',
  'CM_TRANSPORT_TYPE',
  'CM_ECOMM_CATEGORY',
  'CM_ECOMM_TYPE',
  'CM_BCD_CATEGORY',
  'CM_CDTP_CATEGORY',
  'CM_PARCEL_PAY_TYPE',
  'CURRENCY',
  'PORT_CODE',
  'PARCEL_PAY_TYPE',
  'PARCEL_INVOICE_STATUS',
  'PACKAGE_TYPE',
  'PARCEL_BOX_SIZE',
  'PARCEL_TYPE',
  'PLINE_STATUS',
  'CAR_TYPE',
  'PLINE_UNLOAD_TYPE',
  'PARCEL_REVISIT_REASON',
  'FINISH_LOCATION',
  //
  'ACCIDENT_TYPE',
  'ACCIDENT_DETAIL',
  'ACCIDENT_ITEM',
  'INTERIOR_PACKAGE',
  'EXTERIOR_PACKAGE',
  'PARCEL_REVISIT_REASON',
  'PARCEL_INVOICE_NUMBER_STATUS',
  'CREDIT_LOAN_STATUS',
];
export async function codeMasterOptionReturn(codeType: string, locale = 'ko') {
  return await httpClient.get(MASTER_CODE_GROUP + `/${codeType}?locale=${LANG_AVAILABLE?.includes(codeType) ? locale : 'ko'}`).then((rs) => {
    if (rs?.status === 200) {
      let data: OptionItem[] = [];
      if (codeType === 'SALES_CHANNEL_TYPE') {
        data = rs?.data?.codes?.map((ele: any) => {
          return {
            value: ele?.code,
            label: ele?.codeName,
            codeSeq: ele?.codeSeq,
          };
        });
      } else if (codeType === 'EOL_GROUP2') {
        data = rs?.data?.codes?.map((ele: any) => {
          return {
            value: ele?.code,
            label: ele?.codeName,
            codeSeq: ele?.codeSeq,
          };
        });
      } else {
        data = rs?.data?.codes?.map((ele: any) => {
          return {
            value: ele?.code,
            label: ele?.codeName,
            children: ele.subCodes?.map((e: any) => {
              return {
                label: e.codeName,
                value: e.code,
              };
            }),
          };
        });
      }
      return data;
    }
  });
}

export const returnMasterCodesWithArray = async (arr: string[], locale = 'ko') => {
  const fetchs = arr?.map((ele) => {
    if (CODEMASTER_GROUP?.includes(ele)) {
      return codeMasterOptionReturn(ele, locale);
    } else if (CODEMASTER_NEED_AUTH?.includes(ele)) {
      if (ele?.startsWith('CENTER_')) return centerListForlabeling(ele?.includes('AUTH') ? null : { selectFlag: false });
      if (ele?.startsWith('DRIVER_')) return driverListForLabeling(ele?.includes('AUTH') ? null : { selectFlag: false });
      if (ele?.startsWith('INFRA')) return InfraOptionReturn();
      if (ele?.startsWith('SALES_CHANNEL')) return salesChannelReturnAsOption();
      if (ele?.startsWith('DIFF_CENTER')) return diffCenterListAllReturn();
      if (SELLER_MASTER?.includes(ele)) {
        if (ele?.startsWith('SELLER_')) {
          if (ele === 'SELLER_AUTH_WHOLE' || ele === 'SELLER_WHOLE') {
            return partnerListForlabeling({
              selectFlag: ele?.includes('AUTH') ? true : false,
            });
          } else {
            return partnerListForlabeling({
              selectFlag: ele?.includes('AUTH') ? true : false,
              networkFlag: ele?.includes('NETWORK') ? true : false,
              sellerFlag: ele?.includes('PARTNER') ? true : false,
              supplierFlag: ele?.includes('SUPPLIER') ? true : false,
              transportFlag: ele?.includes('TRANSPORT') ? true : false,
              forwarderFlag: ele?.includes('FORWARD') ? true : false,
            });
          }
        }
      }
    }
  });
  return await Promise.all(fetchs);
};

export async function locationColoumnListReturn(centerCode: string) {
  if (centerCode) {
    const rs = await httpClient.get(LOCATION_COLOUMN_LIST + `/${centerCode}`);
    if (rs?.status === 200) {
      return rs.data?.map((ele) => {
        return {
          value: ele,
          label: ele,
        };
      });
    }
    return null;
  }
}

export const partnerListForlabeling = async (paramDTO?: PartnerSelectListSearchDTO): Promise<PartnerSelectListDTO[]> => {
  const searchDTO = {
    ...paramDTO,
    selectFlag: paramDTO?.selectFlag === false ? 'false' : 'true', // default값
    sellerFlag: paramDTO?.sellerFlag === true ? 'true' : 'false',
    networkFlag: paramDTO?.networkFlag === true ? 'true' : 'false',
    supplierFlag: paramDTO?.supplierFlag === true ? 'true' : 'false',
    transportFlag: paramDTO?.transportFlag === true ? 'true' : 'false',
    forwarderFlag: paramDTO?.forwarderFlag === true ? 'true' : 'false',
  };
  const url = PARTNER_FOR_LABELING + addingParamsToUrlexceptPaging(searchDTO);
  return await httpClient.get(url).then((rs) => {
    if (rs?.status === 200) {
      return rs?.data;
    }
  });
};
export const getGridParameters = async (gridId?: string): Promise<{ gridId: UserGridDTO[] }> => {
  const url = MY_GRID_PARAMETER + `?gridId=${gridId}`;
  const rs = await httpClient.get(url);
  if (rs?.status === 200) {
    return rs?.data;
  }
};

export const getGroupOptions = async (): Promise<OptionItem[]> => {
  const rs = await serviceStore.userAction('access/user/paging', 'GET', {}, null);
  if (rs?.status === 200) {
    return rs?.data;
  }
};

export const centerListForlabeling = async (paramDTO?: WarehouseSelectListSearchDTO): Promise<WarehouseSelectListDTO[]> => {
  const searchDTO = {
    ...paramDTO,
    selectFlag: paramDTO?.selectFlag === false ? 'false' : 'true', // default값
  };
  const url = CENTER_FOR_LABELING + addingParamsToUrlexceptPaging(searchDTO);
  return await httpClient.get(url).then((rs) => {
    if (rs?.status === 200) {
      return rs?.data;
    }
  });
};

export const driverListForLabeling = async (paramDTO?: DriverSelectListSearchDTO): Promise<DriverSelectListDTO[]> => {
  const searchDTO = {
    ...paramDTO,
    selectFlag: paramDTO?.selectFlag === false ? 'false' : 'true', // default값
  };
  const url = DRIVER_FOR_LABELING + addingParamsToUrlexceptPaging(searchDTO);
  return await httpClient.get(url).then((rs) => {
    if (rs?.status === 200) {
      return rs?.data;
    }
  });
};

export async function userJojikSelectListReturn() {
  const rs = await serviceStore.userAction(`group/select`, 'GET', { selectFlag: true });
  if (rs?.status === 200) {
    const data = rs?.data?.map((ele: GroupSelectDTO) => {
      return {
        value: ele.groupSeq,
        label: ele.groupName,
      };
    });
    return data;
  }
}

export async function userAuthoritySelectListReturn() {
  const rs = await serviceStore.userAction(`authority/userGroup/select/list`, 'GET', { selectFlag: true });
  if (rs?.status === 200) {
    const data = rs?.data?.map((ele: UserGroupSelectListDTO) => {
      return {
        value: ele.userGroupId,
        label: ele.userGroupName,
      };
    });
    return data;
  }
}

export async function getUserInfo() {
  return await httpClient.get(USER_MY_INFO).then((rs) => {
    if (rs.status === 200) return rs.data;
  });
}

export function weedayOptionReturn() {
  return [
    { value: 'mon', label: '월' },
    { value: 'tue', label: '화' },
    { value: 'wed', label: '수' },
    { value: 'thu', label: '목' },
    { value: 'fri', label: '금' },
    { value: 'sat', label: '토' },
    { value: 'sun', label: '일' },
  ];
}

export const INSTALL_DURATION = [
  { value: 0, label: '0분' },
  { value: 10, label: '10분' },
  { value: 20, label: '20분' },
  { value: 30, label: '30분' },
  { value: 40, label: '40분' },
  { value: 50, label: '50분' },
  { value: 60, label: '60분' },
];

export function uniformSizeOptionReturn() {
  return [
    { value: 95, label: '95' },
    { value: 100, label: '100' },
    { value: 105, label: '105' },
    { value: 110, label: '110' },
  ];
}

export async function codeMasterOptionsWholeReturn(codeType: string) {
  return await httpClient.get(MASTER_CODE_GROUP + `/${codeType}`).then((rs) => {
    if (rs?.status === 200) {
      return rs?.data?.codes;
    }
  });
}

// export async function driverWholeReturn() {
//   return (await serviceStore.installAction(`driver/all`, 'GET', null))?.data;
// }
export async function diffcenterWholeReturn() {
  return (await serviceStore.warehouseAction(`list/diff`, 'GET', null))?.data;
}
// export async function centerWholeReturn() {
//   return (await serviceStore.warehouseAction(`list/excel`, 'GET', null))?.data;
// }
export async function infraWholeReturn() {
  return (await serviceStore.warehouseAction(`infra`, 'GET', null))?.data;
}
// export async function partnerWholeReturn() {
//   return (await serviceStore.userAction(`partner/list/excel`, 'GET', null))?.data;
// }
export async function regionOptionReturn(type: string) {
  return await httpClient.get(REGION_LIST + `?type=${type}`).then((rs) => {
    if (rs?.status === 200) {
      const data = rs?.data?.map((ele: string) => {
        return {
          value: ele,
          label: ele,
        };
      });
      return data;
    }
  });
}

// will deprecate
export const diffCenterListAllReturn = async () => {
  const rs = await httpClient.get(DIFF_CENTER_LIST);
  if (rs?.status === 200) return rs.data;
};

export const diffCenterListReturn = async () => {
  const rs = await httpClient.get(DIFF_CENTER_LIST);
  if (rs?.status === 200) {
    return rs?.data?.map((ele) => {
      return {
        value: ele.centerCode,
        label: ele.centerName,
      };
    });
  }
};

export interface IKeyNValue {}

export async function salesChannelReturnWhole() {
  return await httpClient.get(SALES_CHANNEL).then((rs) => {
    if (rs?.status === 200) {
      return rs?.data;
    }
  });
}

export async function salesChannelReturnAsOption() {
  return await httpClient.get(SALES_CHANNEL).then((rs) => {
    if (rs?.status === 200) {
      const data = rs?.data?.map((ele: SalesChannelDTO) => {
        return {
          value: ele.salesChannelSeq,
          label: ele.salesChannelName,
        };
      });
      return data;
    }
  });
}

export async function centerLatLngReturn() {
  const rs = await centerListForlabeling();
  const data = rs?.map((ele: WarehouseDTO) => {
    return {
      latitude: ele.latitude,
      longitude: ele.longitude,
    };
  });
  return data;
}

export async function zipcodeGyeonYeokOptionReturn() {
  return await httpClient.get(INSTALL_ZIPCODE_GROUP_NAME_LIST_RANGE).then((rs) => {
    if (rs?.status === 200) {
      const data = rs?.data?.map((ele: string) => {
        return {
          value: ele,
          label: ele,
        };
      });
      return data;
    }
  });
}

export function driverDurationAMReturn() {
  const AM = [];
  for (let i = 8; i >= 0; i--) {
    AM.push({ value: i * 30, label: `${i}건(${30 * i}분)` });
  }
  return AM;
}

export function driverDurationPMReturn() {
  const PM = [];
  for (let i = 24; i >= 0; i--) {
    PM.push({ value: i * 30, label: `${i}건(${30 * i}분)` });
  }
  return PM;
}

export function driverDurationAMPMReturn() {
  const AM = [];
  const PM = [];

  for (let i = 8; i >= 0; i--) {
    AM.push({ value: i * 30, label: `${i}건(${30 * i}분)` });
  }
  for (let i = 24; i >= 0; i--) {
    PM.push({ value: i * 30, label: `${i}건(${30 * i}분)` });
  }

  return { AM, PM };
}

export async function zipcode2ndDeliveryOptionReturn() {
  return await httpClient.get(INSTALL_ZIPCODE_GROUP_NAME_LIST_GROUP).then((rs) => {
    if (rs?.status === 200) {
      const data = rs?.data?.map((ele: string) => {
        return {
          value: ele,
          label: ele,
        };
      });
      return data;
    }
  });
}

export async function zipcodeMinGroupOptionReturn() {
  return await httpClient.get(INSTALL_ZIPCODE_GROUP_NAME_LIST_PACKAGE).then((rs) => {
    if (rs?.status === 200) {
      const data = rs?.data?.map((ele: string) => {
        return {
          value: ele,
          label: ele,
        };
      });
      return data;
    }
  });
}

export async function InfraOptionReturn() {
  return await httpClient.get(MASTER_INFRA).then((rs) => {
    if (rs?.status === 200) {
      const data = rs?.data?.map((ele: InfraDTO) => {
        return {
          value: ele.infraSeq,
          label: ele.infraName,
        };
      });
      return data;
    }
  });
}

export const menuDescriptionOptionReturn = async () => {
  return await httpClient.get(USER_MENU_ALL).then((rs) => {
    if (rs?.status === 200) {
      const data = rs?.data
        ?.filter((e: any) => e.level !== 3)
        ?.map((ele: MenuDTO) => {
          return {
            value: ele.menuId,
            label: ele.description,
          };
        });
      return data;
    }
  });
};

export async function menuWholeReturn() {
  return await httpClient.get(USER_MENU_ALL).then((rs) => {
    if (rs?.status === 200) {
      return rs?.data;
    }
  });
}

export async function tabWholeReturn() {
  return await httpClient.get(USER_TAB_ALL).then((rs) => {
    if (rs?.status === 200) {
      return rs.data;
    }
  });
}

export async function ScreenWholeReturn() {
  return await httpClient.get(USER_SCREEN_ALL).then((rs) => {
    if (rs?.status === 200) {
      return rs.data;
    }
  });
}

export async function functionWholeReturn() {
  return await httpClient.get(USER_FUNCTION_ALL).then((rs) => {
    if (rs?.status === 200) {
      return rs.data;
    }
  });
}
export const orderOpsReturn = () => {
  const orderOps: OptionItem[] = [
    {
      label: '주문번호',
      value: 'customerOrderSeq',
    },
    {
      label: '외부주문번호',
      value: 'externalOrderNumber',
    },
  ];

  return orderOps;
};

export const invoiceOpsReturn = () => {
  const invoiceOps: OptionItem[] = [
    {
      label: '송장번호',
      value: 'customerOrderInvoiceSeq',
    },
    {
      label: '외부송장번호',
      value: 'externalInvoiceNumber',
    },
  ];

  return invoiceOps;
};

export const dateOpsReturn = () => {
  const dateOps: OptionItem[] = [
    {
      label: '주문일',
      value: 'OrderDate',
    },
    {
      label: '희망일',
      value: 'PromiseDate',
    },
    {
      label: '지정일',
      value: 'DeliveryDate',
    },
    {
      label: '완료일',
      value: 'FinishDate',
    },
  ];

  return dateOps;
};

export const customerOpsReturn = () => {
  const ops: OptionItem[] = [
    {
      label: '고객명',
      value: 'customerName',
    },
    {
      label: '고객주소',
      value: 'address',
    },
    {
      label: '고객연락처',
      value: 'phone',
    },
  ];

  return ops;
};

export const liftingOpsReturn = () => {
  const ops: OptionItem[] = [
    {
      label: '미수행',
      value: '미수행',
    },
    {
      label: '2층 단품',
      value: '2층 단품',
    },
    {
      label: '2층 결합',
      value: '2층 결합',
    },
    {
      label: '3층 단품',
      value: '3층 단품',
    },
    {
      label: '3층 결합',
      value: '3층 결합',
    },
    {
      label: '4층 단품',
      value: '4층 단품',
    },
    {
      label: '4층 결합',
      value: '4층 결합',
    },
    {
      label: '5층 단품',
      value: '5층 단품',
    },
    {
      label: '5층 결합',
      value: '5층 결합',
    },
  ];

  return ops;
};

export const flagReturns = (trueLabel?: string, falseLabel?: string) => {
  const ops: OptionItem[] = [
    {
      label: trueLabel ? trueLabel : '완료',
      value: true,
    },
    {
      label: falseLabel ? falseLabel : '미완료',
      value: false,
    },
  ];

  return ops;
};

export function regionOptionReturnTmp() {
  const newArr: OptionItem[] = [];

  const regions = [
    {
      label: '수도권',
      value: '수도권',
    },
    {
      label: '충청',
      value: '충청',
    },
    {
      label: '강원',
      value: '강원',
    },
    {
      label: '호남',
      value: '호남',
    },
    {
      label: '제주',
      value: '제주',
    },
    {
      label: '영남',
      value: '영남',
    },
  ];

  regions.forEach((region) => {
    newArr.push(region);
  });

  return newArr;
}
