import { KeyboardEvent, ReactNode, useEffect, useState } from 'react';
import { Col, Form } from 'reactstrap';
import useSearch, { ISearchBoxProps } from 'hooks/grid/useSearch';

// redux
import {
  OrderFeeCancelSearchDTO,
  OrderFeeDateSearchDTO,
  OrderFeeDefaultSearchDTO,
  OrderFeeGroupSearchDTO,
  OrderFeeModelSearchDTO,
  OrderFeeOptionSearchDTO,
  OrderFeeProductSearchDTO,
  OrderFeeRegionSearchDTO,
} from '../../../_interface/order';
import { INPUT, SEARCH, SELECT_MULTI, SELECT_SINGLE, convertKr, convertMaster } from '../asset/asset';
import { useLocation } from 'react-router-dom';

export const SearchBox = (
  props: ISearchBoxProps<
    OrderFeeCancelSearchDTO &
      OrderFeeDateSearchDTO &
      OrderFeeDefaultSearchDTO &
      OrderFeeModelSearchDTO &
      OrderFeeGroupSearchDTO &
      OrderFeeOptionSearchDTO &
      OrderFeeProductSearchDTO &
      OrderFeeRegionSearchDTO
  >,
) => {
  const { searchObj, setSearchObj, fetchList, TARGET } = props;
  const location = useLocation();
  const [nodeRow, setNodeRow] = useState<ReactNode[]>();

  useEffect(() => {
    initSearchNode();
  }, [searchObj, TARGET]);

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));
  const pathOrder = currentDesignType === 1 ? 2 : 1;

  const { inputsMaker } = useSearch();

  const initSearchNode = () => {
    setNodeRow(
      inputsMaker({
        rawArr: SEARCH[TARGET][location.pathname.split('/')[pathOrder].toUpperCase()],
        INPUT,
        SELECT_SINGLE,
        SELECT_MULTI,
        searchObj,
        setSearchObj,
        convertKr,
        convertMaster,
        btnNode: (
          <Col style={{ textAlign: TARGET === 'GROUP' ? 'right' : 'left' }}>
            <button
              className="btn-search btn"
              onClick={(e) => {
                e.preventDefault();
                fetchList(searchObj);
              }}
            >
              조회
            </button>
          </Col>
        ),
      }),
    );
  };

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return <Form onKeyPress={onKeyPress}>{nodeRow}</Form>;
};
