// redux
import { useSelector } from 'react-redux';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

// styled-component
import styled from 'styled-components';
import { getMonDotDay, getMonNDayKr, returnYoil } from 'common/util/dateParsingFn';

// img
import collectSet from './img/collectSet.jpg';
import deliverySet from './img/deliverySet.jpg';
import dot from './img/dot.png';
import line from './img/line.png';

import delivery1 from './img/delivery1.png';
import delivery2 from './img/delivery2.png';
import delivery3 from './img/delivery3.png';
import delivery4 from './img/delivery4.png';
import delivery5 from './img/delivery5.png';

import collect1 from './img/collect1.png';
import collect2 from './img/collect2.png';
import collect3 from './img/collect3.png';
import { RootState } from 'redux/store';

export const Invoices = ({ invoices, setSelected }) => {
  return (
    <div style={{ width: '80%', margin: '10px auto' }}>
      <p style={{ fontSize: 18, fontWeight: 700 }}>
        {invoices[0]?.orderSeq} ( 외부주문번호 : {invoices[0]?.externalOrderNumber} )
      </p>
      <table className="border-table" style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th>송장번호</th>
            <th>주문유형</th>
            <th>송장유형</th>
            <th>송장상태</th>
            <th style={{ width: 80 }}>상세보기</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((ele, idx) => {
            return (
              <tr key={`invoice_${idx}`}>
                <td>{ele?.invoiceSeq}</td>
                <td>{ele?.orderTypeKr}</td>
                <td>{ele?.feeTypeKr}</td>
                <td>{ele?.invoiceStatusKr}</td>
                <td>
                  <button
                    className="btn btn-etc sssm"
                    onClick={() => {
                      setSelected(ele);
                    }}
                  >
                    선택
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

// SelectedInvoice component
const InvoiceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 370px;
  align-items: center;
  box-shadow: -2px 5px 15px 2px #95959553;
  background-color: #f4f6f9;
  margin: 30px 0;
`;

const InvoiceParts = styled.div`
  background-color: white;
  width: 100%;
  padding: 30px;
  border-bottom: 5px solid #f4f6f9;
`;

const MainTitle = styled.div`
  color: #ff910f;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  border-bottom: 2px solid #dee1e4;
  padding-bottom: 15px;
  padding-top: 30px;
`;

const ModelTitle = styled.div`
  font-size: 15px;
  padding-top: 15px;
  font-weight: 500;
`;
const StatusTitle = styled.div`
  font-size: 13px;
  color: #a3a7b9;
`;

const ScheduleImgArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ScheduleImgLabel = styled.div`
  width: 300px;
  height: 37px;
  display: flex;
  font-size: 12px;
  font-weight: 300;
  justify-content: space-between;
  align-items: flex-end;
  color: #a3a7b9;
`;

const ScheduleList = styled.div`
  padding: 15px;
  color: #a3a7b9;
`;

const SpanWrapper = styled.div`
  display: flex;
  margin: 3px 0;
`;

const BoldSpan = styled.span`
  font-weight: 600;
  font-size: 13px;
  width: 100px;
  display: inline-block;
`;

const NotBoldSpan = styled.span`
  font-size: 12px;
  width: 210px;
`;

const Schedule = styled.div`
  position: relative;
  padding: 8px 0;
`;

const ScheduleImg = styled.img`
  position: absolute;
  top: 0;
`;

export const SelectedInvoice = ({ selected }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);

  const deliveryStat = {
    ASSIGN: '주문이 접수되었습니다.',
    CONFIRM: '배송설치 도착 예정입니다.',
    DELIVERY: '배송설치 도착 예정입니다.',
    FINISH_DELIV: '배송이 완료되었습니다.',
  };

  const onlyDeliveryStat = {
    ASSIGN: '주문이 접수되었습니다.',
    CONFIRM: '스케줄이 확정되었습니다.',
    DELIVERY: '배송이 출발하였습니다.',
    FINISH_DELIV: '배송이 완료되었습니다.',
  };

  const collectStat = {
    ASSIGN: '주문이 접수되었습니다.',
    CONFIRM: '방문예정입니다.',
    FINISH_COLLE: '회수가 완료되었습니다.',
  };
  const mainTitleReturner = (selected) => {
    console.log(selected);
    const invStatus = selected?.invoiceStatus;
    const orderType = selected?.orderType;
    const feeType = selected?.feeType;
    let str = '';
    if (invStatus !== 'ASSIGN' && !invStatus?.includes('FINISH')) {
      str += `${getMonNDayKr(selected?.deliveryDate)}(${returnYoil(selected?.deliveryDate)})${feeType !== 'DELIV' ? selected?.deliveryDatetime?.split(' ')[1] || '' : ''}
      `;
    }
    if (orderType === 'COLLECT') {
      str += `${collectStat[invStatus] || ''}`;
    } else {
      if (feeType === 'DELIV') {
        str += `${onlyDeliveryStat[invStatus]}`;
      } else if (deliveryStat[invStatus]) str += `${deliveryStat[invStatus] || ''}`;
      else if (invStatus?.includes('CANCEL')) str = '주문이 취소되었습니다.';
      else str = '-'; //초기화
    }
    return str;
  };

  return (
    <InvoiceWrapper>
      <InvoiceParts>
        <MainTitle>
          {mainTitleReturner(selected).split('\n')[0]}
          <br />
          {mainTitleReturner(selected).split('\n')[1]}
        </MainTitle>
        <ModelTitle>{selected?.productName}</ModelTitle>
        <StatusTitle>
          {getMonDotDay(selected?.assignDatetime)}({returnYoil(selected?.assignDatetime)})&nbsp;
          {masterOptions?.INVOICE_STATUS ? selectlabel(selected?.invoiceStatus, masterOptions?.INVOICE_STATUS) : selected?.invoiceStatusKr} |&nbsp;
          {masterOptions?.FEE_TYPE ? selectlabel(selected?.feeType, masterOptions?.FEE_TYPE) : selected?.feeTypeKr}
        </StatusTitle>
      </InvoiceParts>
      <InvoiceParts>
        <div style={{ marginBottom: 20 }}>
          <div>주문번호 : {selected?.orderSeq} </div>
          <div>외부주문번호 : {selected?.externalOrderNumber}</div>
          <div>송장번호 : {selected?.invoiceSeq}</div>
          <div>외부송장번호 : {selected?.externalInvoiceNumber}</div>
        </div>
        <SpanWrapper>
          <BoldSpan>수령인</BoldSpan>
          <NotBoldSpan>{selected?.receiverName}</NotBoldSpan>
        </SpanWrapper>
        <SpanWrapper>
          <BoldSpan>기사정보</BoldSpan>
          <NotBoldSpan>{selected?.driverName ? `${selected?.driverName}(${selected?.driverPhone})` : ''}</NotBoldSpan>
        </SpanWrapper>
        <SpanWrapper>
          <BoldSpan>배송요청사항</BoldSpan>
          <NotBoldSpan>{selected?.shipmentNote || '-'}</NotBoldSpan>
        </SpanWrapper>
      </InvoiceParts>
      <InvoiceParts>
        <ScheduleImgArea>
          <img src={selected?.orderType === 'COLLECT' ? collectSet : deliverySet} alt="" />
          <ScheduleImgLabel>
            {selected?.orderType === 'COLLECT' ? (
              <>
                <span style={{ color: selected?.orderType !== 'COLLECT' && selected?.invoiceStatus === 'ASSIGN' ? '#FF910F' : '#a3a7b9' }}>회수접수</span>
                <span style={{ color: selected?.invoiceStatus === 'CONFIRM' ? '#FF910F' : '#a3a7b9' }}>회수중</span>
                <span style={{ color: selected?.invoiceStatus === 'FINISH_COLLE' || selected?.invoiceStatus?.includes('CANCEL') ? '#FF910F' : '#a3a7b9' }}>회수완료</span>
              </>
            ) : (
              <>
                <span style={{ color: selected?.orderType !== 'COLLECT' && selected?.invoiceStatus === 'ASSIGN' ? '#FF910F' : '#a3a7b9' }}>배송준비</span>
                <span style={{ color: selected?.invoiceStatus === 'CONFIRM' ? '#FF910F' : '#a3a7b9' }}>스케줄확정</span>
                <span style={{ color: selected?.invoiceStatus === 'DELIVERY' ? '#FF910F' : '#a3a7b9' }}>배송출발</span>
                <span style={{ color: selected?.invoiceStatus === 'FINISH_DELIV' || selected?.invoiceStatus?.includes('CANCEL') ? '#FF910F' : '#a3a7b9' }}>배송완료</span>
              </>
            )}
          </ScheduleImgLabel>

          {/* DELIVERY */}
          {selected?.orderType !== 'COLLECT' && selected?.invoiceStatus === 'ASSIGN' && <ScheduleImg src={delivery1} alt="" style={{ left: 10 }} />}
          {selected?.orderType !== 'COLLECT' && selected?.invoiceStatus === 'CONFIRM' && <ScheduleImg src={delivery2} alt="" style={{ left: 96 }} />}
          {selected?.invoiceStatus === 'DELIVERY' && <ScheduleImg src={delivery3} alt="" style={{ left: 184 }} />}
          {selected?.invoiceStatus === 'FINISH_DELIV' && <ScheduleImg src={delivery4} alt="" style={{ left: 266 }} />}
          {/* COLLECT */}
          {selected?.orderType === 'COLLECT' && selected?.invoiceStatus === 'ASSIGN' && <ScheduleImg src={collect1} alt="" style={{ left: 10 }} />}
          {selected?.orderType === 'COLLECT' && selected?.invoiceStatus === 'CONFIRM' && <ScheduleImg src={collect2} alt="" style={{ left: 139 }} />}
          {selected?.invoiceStatus === 'FINISH_COLLE' && <ScheduleImg src={collect3} alt="" style={{ left: 266 }} />}
          {/* CANCEL */}
          {selected?.invoiceStatus?.includes('CANCEL') && <ScheduleImg src={delivery5} alt="" style={{ left: 266 }} />}
        </ScheduleImgArea>
        <ScheduleList>
          {selected?.events?.map((e, idx) => {
            return (
              <Schedule key={`schedule_${idx}`}>
                {e.eventType === 'CONFIRM' && (
                  <span style={{ position: 'absolute', right: -25, top: 25, maxWidth: 185, display: 'inline-block' }}>
                    {selected?.driverName}기사 {getMonDotDay(selected?.deliveryDatetime || selected?.deliveryDate)}({returnYoil(selected?.deliveryDate)})&nbsp;
                    {selected?.feeType !== 'DELIV' ? selected?.deliveryDatetime?.split(' ')[1] : ''} 예정
                  </span>
                )}
                <img src={dot} alt="" style={{ position: 'absolute', left: -20, top: 17 }} />
                {idx !== 0 && <img src={line} alt="" style={{ position: 'absolute', left: -15, top: -20 }} />}
                <span style={{ color: selected?.invoiceStatus?.includes(e.eventType) ? '#FF910F' : '#A3A7B9' }}>{e.eventName}</span>
                <div>
                  {getMonNDayKr(e?.eventDatetime)}({returnYoil(e?.eventDatetime)})&nbsp;{e?.eventDatetime?.split(' ')[1]}
                </div>
              </Schedule>
            );
          })}
        </ScheduleList>
      </InvoiceParts>
    </InvoiceWrapper>
  );
};
