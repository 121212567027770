import { httpClient } from 'common/http-client/axiosConfig';
import { useState } from 'react';

interface IAlarm {
  visible?: boolean;
  unreadCount?: number;
  list?: any[];
  totalCount?: number;
  fetching?: boolean;
}

export function useNotification(setGongjiObj) {
  const [alarms, setAlarms] = useState<IAlarm>();
  const [pagingSetting, setPagingSetting] = useState({
    pageNo: 1,
    pageSize: 10,
    fetchNew: false,
  });

  const fetchAlarms = async () => {
    setAlarms((prev) => {
      return {
        ...prev,
        fetching: true,
      };
    });
    const [unreadCount, data] = await Promise.all([fetchAlarmCount(), fetchAlarmList()]);
    setAlarms((prev) => {
      return {
        ...prev,
        visible: false,
        unreadCount,
        totalCount: data?.totalCount,
        list: data?.list,
        fetching: false,
      };
    });
  };

  const concatAlarmList = async () => {
    const { list } = await fetchAlarmList();
    setAlarms((prev) => {
      return {
        ...prev,
        list: alarms?.list?.concat(list),
      };
    });
    setPagingSetting((prev) => {
      return {
        ...prev,
        fetchNew: false,
      };
    });
  };

  const fetchAlarmCount = async () => {
    return await httpClient.get(`/user/notification/quantity?erpFlag=true`).then((rs) => {
      if (rs?.status === 200) {
        return rs?.data;
      }
    });
  };

  const readNotification = async (ele) => {
    const rs = await httpClient.post(`/user/notification/read?notificationSeq=${ele.notificationSeq}`);
    if (rs?.status === 200) {
      await fetchAlarms();
      setGongjiObj({
        visible: true,
        announcementSeq: ele?.tableSeq,
      });
    }
  };

  const fetchAlarmList = async () => {
    let search = `/user/notification/list/paging?erpFlag=true&pageNo=${pagingSetting.pageNo}&pageSize=${pagingSetting.pageSize}`;
    return await httpClient.get(search).then((rs) => {
      if (rs?.status === 200) {
        return rs?.data;
      }
    });
  };

  return { alarms, setAlarms, fetchAlarms, concatAlarmList, readNotification, pagingSetting, setPagingSetting };
}
