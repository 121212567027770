import { useState, useEffect } from 'react';
import { defaultUnitedPageWithTopTab, INVENTORY_MOVE_ACTION, UnitedPageWithTopTab } from 'components/template/topTab';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Container1 from './container';

// options
import { centerListForlabeling, codeMasterOptionReturn, InfraOptionReturn, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';

const ShippingFeeRegister = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);

  useEffect(() => {
    if (masterOptions) {
      const unitedName = INVENTORY_MOVE_ACTION;
      const currentTab = JSON.parse(localStorage.getItem(unitedName)) ? JSON.parse(localStorage.getItem(unitedName)) : 0;
      setTabObj({
        unitedName: unitedName,
        currentTab: currentTab,
        titles: ['배차'],
        containers: [<Container1 />],
      });
    }
  }, [masterOptions]);

  const initMasterOptions = async () => {
    const [INFRA, PROVINCE, CITY, PARTNER_SELLER_WHOLE, MODEL_GROUP, CENTER_SELECT_WHOLE] = await Promise.all([
      //
      InfraOptionReturn(),
      codeMasterOptionReturn('PROVINCE'),
      codeMasterOptionReturn('CITY'),
      partnerListForlabeling({ sellerFlag: true }),
      codeMasterOptionReturn('MODEL_GROUP'),
      centerListForlabeling(),
    ]);
    dispatch(
      setMasterOptions({
        INFRA,
        PROVINCE,
        CITY,
        SELLER: PARTNER_SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        MODEL_GROUP,
        CENTER: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
      }),
    );
  };

  return <>{masterOptions && tabObj?.titles.length > 0 && <UnitedPageWithTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default ShippingFeeRegister;
