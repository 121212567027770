import { ReactNode, useMemo, useRef, useState } from 'react';

// utils
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';

// hooks
import { PagingListDTO } from 'interface/util';
import { SearchBox } from './component/searchBox';
import PrintGridBox from 'common/grid/printGridBox';
import { detectMobileDevice } from 'common/util/detectDevice';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { ParcelInvoiceNumberDTO, ParcelInvoiceNumberSearchDTO } from '../../_interface/parcel';
import { IRequestObj, RequestPopup } from './component/request';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const isMobile = detectMobileDevice(window.navigator.userAgent);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const columns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '신청수량',
      dataField: 'quantity',
    },
    {
      headerText: '운송장번호',
      children: [
        {
          headerText: '(FROM)',
          dataField: 'fromParcelInvoiceNumber',
        },
        {
          headerText: '(TO)',
          dataField: 'toParcelInvoiceNumber',
        },
      ],
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '신청일',
      dataField: 'registerDate',
    },
    {
      headerText: '승인일',
      dataField: 'approveDate',
    },
    {
      headerText: '신청자',
      dataField: 'registerId',
    },
    {
      headerText: '승인자',
      dataField: 'approveId',
    },
  ];

  const labellingKr = (data: PagingListDTO<ParcelInvoiceNumberDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_FORWARDER_WHOLE?.[row?.partnerSeq],
        statusKr: MASTER_OBJ?.PARCEL_INVOICE_NUMBER_STATUS?.[row?.status],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: ParcelInvoiceNumberSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.parcelAction(`invoice/number/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<ParcelInvoiceNumberDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 500,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: (await serviceStore.parcelAction(`invoice/number/excel`, 'GET', searchObj, null))?.data as ParcelInvoiceNumberDTO[] }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `운송장신청` });

    dispatch(setLoading(null));
  };

  const actionTransfer = {
    PARCEL_NUMBER_CANCEL: {
      ko: '취소',
      action: 'number/cancel',
    },
    PARCEL_NUMBER_REJECT: {
      ko: '반려',
      action: 'number/reject',
    },
    PARCEL_NUMBER_APPROVE: {
      ko: '승인',
      action: 'number/approve',
    },
  };

  const postNumberAction = async (id, items) => {
    if (window.confirm(`운송장신청을 ${actionTransfer[id]?.ko}하시겠습니까?`)) {
      const rs = await serviceStore.parcelAction(
        `invoice/${actionTransfer[id]?.action}`,
        'POST',
        null,
        items?.map((ele) => {
          return { seq: ele.parcelInvoiceNumberSeq };
        }),
      );

      if (rs?.status === 200) {
        alert(`운송장신청이 ${actionTransfer[id]?.ko}되었습니다!`);
        wrappedFetchList(searchObj);
      }
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'PARCEL_NUMBER_REQUEST') {
      setRequestObj({
        visible: true,
        fetchFn: wrappedFetchList,
        searchObj,
      });
    } else if (id === 'PARCEL_NUMBER_EXCELDOWN') {
      downloadExcel('main');
    } else {
      handleCheckItems(id);
    }
  };
  const [requestObj, setRequestObj] = useState<IRequestObj>();
  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      postNumberAction(id, items);
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content" style={{ paddingTop: isMobile ? 0 : '3rem' }}>
      {requestObj?.visible && <RequestPopup requestObj={requestObj} setRequestObj={setRequestObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            pageRowCount: 500,
            pageRowSelectValues: [500],
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
