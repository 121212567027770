import styled from 'styled-components';

export type ButtonGroupProps = {
  $align?: 'CENTER' | 'RIGHT' | 'LEFT';
  $padding?: number;
};

export const ButtonGroup = styled.div<ButtonGroupProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: ${(props) => props.$padding}px;
  justify-content: ${(props) => (props.$align === 'CENTER' ? 'center' : props.$align === 'LEFT' ? 'flex-start' : 'flex-end')};
`;
