import styled from 'styled-components';
import LDSButton from './Button';
import { LDSLabelSM } from './Typography';
import { COLORS, TRANSITIONS } from 'common/constants/appearance';
import { HiOutlinePlus } from 'react-icons/hi';
import { setDocumentTitle } from 'common/util/html';
import { useEffect } from 'react';

type TabProps = {
  $label: string;
  $tabId?: string;
  $activeId?: string;
  $type: 'TAB' | 'BUTTON';
  onClick?: () => void;
};

export default function LDSTab({ $type, $label, $tabId, $activeId, onClick }: TabProps) {
  useEffect(() => {
    $activeId === $tabId && setDocumentTitle($label);
  }, [$activeId]);

  return (
    <TabButtonWrapper $type={$type} className={$activeId === $tabId && $type !== 'BUTTON' ? 'active' : ''} onClick={$type !== 'BUTTON' ? onClick : () => {}} data-tab-id={$tabId}>
      {$type !== 'BUTTON' ? (
        <LDSLabelSM $weight={'BOLD'}>{$label}</LDSLabelSM>
      ) : (
        <LDSButton $icon={<HiOutlinePlus />} $size={'XS'} $primary={false} $type={'BUTTON'} $showIcon={true} $style={'FILL'} onClick={onClick} />
      )}
      <TabButtonBorder $type={$type} />
    </TabButtonWrapper>
  );
}

type TabStyleProps = Pick<TabProps, '$type'>;

const TabButtonWrapper = styled.div<TabStyleProps>`
  padding: ${(props) => (props.$type === 'BUTTON' ? '2px 6px 4px 6px' : '8px 16px')};
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  transition: all 200ms ${TRANSITIONS.BEZIER};

  cursor: ${(props) => props.$type !== 'BUTTON' && 'pointer'};

  & > * {
    cursor: inherit;
    &:not(button) {
      pointer-events: none;
    }
  }

  &.active {
    color: ${COLORS.PRIMARY} !important;
    pointer-events: none;

    & > div {
      height: 2px !important;
      background-color: ${COLORS.PRIMARY} !important;
    }
  }

  color: ${COLORS.GRAY_05};

  & > div {
    height: 1px;
    background-color: ${COLORS.GRAY_02_DEEP};
  }

  &:hover {
    background-color: ${(props) => (props.$type === 'BUTTON' ? 'transparent' : COLORS.GRAY_01_DEEP)};
    color: ${COLORS.GRAY_06};

    & > div {
      height: 1px;
      background-color: ${(props) => (props.$type !== 'BUTTON' ? COLORS.GRAY_03 : COLORS.GRAY_02_DEEP)};
    }
  }

  &:active {
    background-color: ${(props) => (props.$type === 'BUTTON' ? 'transparent' : COLORS.GRAY_02_DEEP)};

    &:not(.active) > div {
      background-color: ${(props) => (props.$type !== 'BUTTON' ? COLORS.GRAY_03_DEEP : COLORS.GRAY_02_DEEP)};
    }
  }
`;

const TabButtonBorder = styled.div<TabStyleProps>`
  position: absolute;
  transition: all 200ms ${TRANSITIONS.BEZIER};
  bottom: 0;
  left: 0;
  right: 0;
`;
