import { useEffect, useState } from 'react';
import { INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION } from 'envVar';
import { v4 as uuidv4 } from 'uuid';

import Grid from 'components/grid/auiGrid';
import { Button, Col, Input, Row, Spinner } from 'reactstrap';
import { centerListForlabeling, codeMasterOptionReturn, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';
import { InputD, SelectD } from '../reactstrap/reactstrap';
import CommonModalNew from './commonModalNew';

export const defaultSearchModelObj = {
  visible: false,
  params: null,
  returns: null,
};

const modelOptions = [
  { label: '전체', value: null },
  { label: '바코드', value: 'barcode' },
  { label: 'SKU', value: 'modelStockSeq' },
  { label: '외부SKU', value: 'skuNumber' },
  { label: '제품명', value: 'modelName' },
];

const SearchAvailableModelModal = (props) => {
  const { AUIGrid } = window;
  const { searchModelObj, setSearchModelObj } = props;
  const [loading, setLoading] = useState(false);

  const [gridId, setGridId] = useState();
  const [rows, setRows] = useState([]);
  const [searchObj, setSearchObj] = useState({
    modelType: null,
    modelGroup: null,
    modelOption: null,
    modelInput: null,
  });
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  const [masterOptions, setMasterOptions] = useState();

  useEffect(() => {
    console.log(searchModelObj);
    if (searchModelObj?.visible) {
      initGridId();
      fetchOptions();
    }
  }, [searchModelObj?.visible]);

  const initGridId = () => {
    setGridId(`searchAvailableModelModal_${uuidv4()}`);
  };

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'add-model') {
        setSearchModelObj({
          ...searchModelObj,
          returns: items,
        });

        window.AUIGrid.setAllCheckedRows(`#${gridId}`, false);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const fetchOptions = async () => {
    setMasterOptions({
      CENTER: (await centerListForlabeling({ selectFlag: false })).map((ele) => {
        return {
          label: ele.centerName,
          value: ele.centerCode,
        };
      }),
      SELLER: (await partnerListForlabeling({ sellerFlag: true })).map((ele) => {
        return {
          label: ele.partnerName,
          value: ele.partnerSeq,
        };
      }),
      MODEL_TYPE: await codeMasterOptionReturn('MODEL_TYPE', false, '선택 안함'),
      MODEL_GROUP: await codeMasterOptionReturn('MODEL_GROUP', false, '선택 안함'),
      REMOVE_REASON: await codeMasterOptionReturn('REMOVE_REASON', false, '선택 안함'),
    });
  };

  const fetchList = async (searchObj) => {
    setLoading(true);
    let url = `${INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION}?`;
    if (searchObj) {
      if (searchObj?.modelOption) {
        if (searchObj.modelOption === 'barcode') url += `&barcode=${searchObj.modelInput}`;
        if (searchObj.modelOption === 'skuNumber') url += `&skuNumber=${searchObj.modelInput}`;
        if (searchObj.modelOption === 'modelStockSeq') url += `&modelStockSeq=${searchObj.modelInput}`;
        if (searchObj.modelOption === 'modelName') url += `&modelName=${searchObj.modelInput}`;
      }
      if (searchObj?.modelGroup) url += `&modelGroup=${searchObj.modelGroup}`;
      if (searchObj?.modelType) url += `&modelType=${searchObj.modelType}`;
    }
    if (searchModelObj) {
      if (searchModelObj?.params?.centerCode) url += `&centerCode=${searchModelObj?.params?.centerCode}`;
      if (searchModelObj?.params?.partnerSeq) url += `&partnerSeq=${searchModelObj?.params?.partnerSeq}`;
    }

    await httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        const reason = searchModelObj?.params?.reason;
        const _rows = rs.data || [];
        _rows.forEach((row) => {
          row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER);
          row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
          row.modelGroupKr = selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP);
          row.requestAvailableQuantity = 0;
          row.requestAvailableQuantityDefault = reason !== 'DEFECT_EXPORT' ? row.availableQuantity : 0;
          row.requestDefectedQuantity = 0;
          row.requestDefectedQuantityDefault = reason !== 'MAKER_EXPORT' ? row.defectedQuantity : 0;
          row.reason = reason;
        });

        console.log(_rows);
        setRows(_rows);
      }
    });
    setLoading(false);
  };

  const columns = [
    {
      headerText: '반출창고',
      dataField: 'centerCodeKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '현재보관수량',
      children: [
        {
          headerText: '양품',
          dataField: 'totalAvailableQuantity',

          editable: false,
        },
        {
          headerText: '불용',
          dataField: 'totalDefectedQuantity',
          editable: false,
        },
        {
          headerText: '이동가능양품',
          dataField: 'availableQuantity',
          editable: false,
        },
        {
          headerText: '이동가능불용',
          dataField: 'defectedQuantity',
          editable: false,
        },
      ],
    },
    {
      headerText: '반출재고수량',
      children: [
        {
          headerText: '양품',
          dataField: 'requestAvailableQuantity',
          dataType: 'numeric',
          style: 'my-custom-aui',
          // style: searchModelObj?.params?.reason === 'MAKER_EXPORT' ? 'my-custom-aui' : 'my-custom-aui disabled',
          // editable: searchModelObj?.params?.reason === 'MAKER_EXPORT' ? true : false,
        },
        {
          headerText: '불용',
          dataField: 'requestDefectedQuantity',
          dataType: 'numeric',
          style: 'my-custom-aui',
          // style: searchModelObj?.params?.reason === 'DEFECT_EXPORT' ? 'my-custom-aui' : 'my-custom-aui disabled',
          // editable: searchModelObj?.params?.reason === 'DEFECT_EXPORT' ? true : false,
        },
      ],
    },
  ];

  const cellEditEndThisGrid = (event) => {
    const reason = event.item?.reason;
    if (reason === 'MAKER_EXPORT') {
      if (event.dataField === 'requestAvailableQuantity') {
        if (event.value > event.item.requestAvailableQuantityDefault) {
          alert('보관수량보다 많이 입력하셨습니다');
          AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
        }
      } else if (event.dataField === 'requestDefectedQuantity') {
        alert('양품반출에서는 불용값을 설정할 수 없습니다!');
        AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
      }
    } else if (reason === 'DEFECT_EXPORT') {
      if (event.dataField === 'requestAvailableQuantity') {
        alert('불량반출에서는 양품값을 설정할 수 없습니다!');
        AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
      } else if (event.dataField === 'requestDefectedQuantity') {
        if (event.value > event.item.requestDefectedQuantityDefault) {
          alert('보관수량보다 많이 입력하셨습니다');
          AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
        }
      }
    } else if (reason === 'LOST' || reason === 'PARCEL') {
      if (event.dataField === 'requestAvailableQuantity') {
        if (event.value > event.item.requestAvailableQuantityDefault) {
          alert('보관수량보다 많이 입력하셨습니다');
          AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
        }
      }
      if (event.dataField === 'requestDefectedQuantity') {
        if (event.value > event.item.requestDefectedQuantityDefault) {
          alert('보관수량보다 많이 입력하셨습니다');
          AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
        }
      }
    }
  };

  return (
    <CommonModalNew
      title={`반출창고 : ${selectlabel(searchModelObj?.params.centerCode, masterOptions?.CENTER)}`}
      style={{ width: 1400 }}
      visible={searchModelObj?.visible}
      setVisible={() => {
        setSearchModelObj({
          ...searchModelObj,
          visible: !searchModelObj?.visible,
        });
      }}
      children={
        <>
          {loading && (
            <Spinner
              style={{
                position: 'absolute',
                left: '50%',
                top: '30%',
                zIndex: 100,
              }}
              color="danger"
            />
          )}
          <Row>
            <Col lg={2}>
              <label>제품타입</label>
              <SelectD
                value={
                  searchObj?.modelType
                    ? {
                        value: searchObj?.modelType,
                        label: selectlabel(searchObj?.modelType, masterOptions?.MODEL_TYPE),
                      }
                    : null
                }
                options={masterOptions?.MODEL_TYPE}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    modelType: option.value,
                  });
                }}
              />
            </Col>
            <Col lg={2}>
              <label>제품그룹</label>
              <SelectD
                options={masterOptions?.MODEL_GROUP}
                value={
                  searchObj?.modelGroup
                    ? {
                        value: searchObj?.modelGroup,
                        label: selectlabel(searchObj?.modelGroup, masterOptions?.MODEL_GROUP),
                      }
                    : null
                }
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    modelGroup: option.value,
                  });
                }}
              />
            </Col>
            <Col lg={5}>
              <label>제품/바코드</label>
              <Row>
                <Col md={5} style={{ paddingRight: 0 }}>
                  <SelectD
                    options={modelOptions}
                    value={
                      searchObj?.modelOption
                        ? {
                            value: searchObj?.modelOption,
                            label: selectlabel(searchObj?.modelOption, modelOptions),
                          }
                        : null
                    }
                    onChange={(option) => {
                      setSearchObj({
                        ...searchObj,
                        modelOption: option.value,
                      });
                    }}
                  />
                </Col>
                <Col style={{ paddingLeft: 0 }}>
                  <InputD
                    onChange={(e) => {
                      setSearchObj({
                        ...searchObj,
                        modelInput: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col style={{ paddingTop: 6 }}>
              <Button
                className="btn btn-etc sm"
                onClick={() => {
                  fetchList(searchObj);
                }}
              >
                조회
              </Button>
              <Button
                className="btn btn-secondary sm"
                onClick={() => {
                  setGetCheckedDataTriggerThisGrid({
                    type: 'add-model',
                    id: `#${gridId}`,
                  });
                }}
              >
                추가
              </Button>
            </Col>
          </Row>
          <div className="presenterGridBox">
            {gridId && (
              <Grid
                id={gridId}
                rows={rows}
                columns={columns}
                style={{ height: 600 }}
                settingOptions={{
                  usePaging: true,
                  showRowAllCheckBox: true,
                  showRowCheckColumn: true,
                }}
                cellEditEndThisGrid={cellEditEndThisGrid}
                getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
                setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
              />
            )}
          </div>
        </>
      }
    />
  );
};

export default SearchAvailableModelModal;
