import { useEffect, useRef, useState } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { GridDTO, GridSearchDTO } from 'interface/user';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import GridBox, { IGridPageRowSelectValues, defaultGridProps } from 'common/grid/gridBox';
import { useDidMountEffect } from 'hooks/basichooks';
import { userService } from 'pages/ETC/3user/_service/service';
import useExcelHooks from 'hooks/excel/useExcelHooks';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IDetailObj {
  searchObj?: GridSearchDTO;
  visible: boolean;
  item?: GridDTO;
  fetchFn?: <T>(T) => void;
}

interface IDetailModal {
  detailObj: IDetailObj;
  setDetailObj: React.Dispatch<React.SetStateAction<IDetailObj>>;
}

export const doTree = (gridParameters) => {
  const pparentArr = gridParameters?.filter((ele) => ele.level === 1); // 최상부모
  const parentArr = gridParameters?.filter((ele) => ele.level === 2);
  const lastChildArr = gridParameters?.filter((ele) => ele.level === 3);

  if (lastChildArr?.length > 0) {
    parentArr.forEach((parent) => {
      if (lastChildArr?.filter((ele) => ele.parentParameter === parent.parameter)?.length > 0) {
        parent.children = lastChildArr?.filter((ele) => ele.parentParameter === parent.parameter);
      }
    });
  }
  pparentArr?.forEach((parent) => {
    if (parentArr?.filter((ele) => ele.parentParameter === parent.parameter)?.length > 0) {
      parent.children = parentArr?.filter((ele) => ele.parentParameter === parent.parameter);
    }
  });
  console.log(pparentArr);
  return pparentArr;
};

export const DetailModal = ({ detailObj, setDetailObj }: IDetailModal) => {
  const gridRef = useRef<AUIGrid>();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [data, setData] = useState<GridDTO>();

  useEffect(() => {
    if (detailObj?.item) {
      const data = detailObj?.item;
      fetchGridParameters(data?.gridId);
    } else {
      setData({
        gridType: 'PAGING',
        fixedColumn: 0,
        pageSize: 100,
        useFlag: true,
      });
    }
  }, []);

  const fetchGridParameters = async (gridId: string) => {
    const data = await userService.getGridDetail(gridId);
    if (data) {
      const tree = doTree(data?.gridParameters);
      gridRef.current.setGridData(tree);
      setData(data);
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '*변수',
      dataField: 'parameter',
    },
    {
      headerText: '*변수명',
      dataField: 'parameterName',
    },
    {
      headerText: '설명',
      dataField: 'description',
    },
    // {
    //   headerText: '순서',
    //   dataField: 'sort',
    //   editable: false,
    //   width: 50,
    // },
  ];

  const validation = (data) => {
    return true;
  };

  const makeData = (data: GridDTO) => {
    const tree = gridRef.current.getTreeGridData();
    tree?.forEach((pparent, ppi) => {
      pparent.sort = ppi + 1;
      pparent.parentParameter = null;
      pparent.level = 1;
      pparent.children?.forEach((parent, pi) => {
        parent.sort = pi + 1;
        parent.parentParameter = pparent.parameter;
        parent.level = 2;
        parent.children?.forEach((child, i) => {
          child.sort = i + 1;
          child.level = 3;
          child.parentParameter = parent.parameter;
        });
      });
    });
    gridRef.current.setGridData(tree);

    const gridParameters = gridRef.current.getTreeFlatData();

    const filtered = gridParameters.filter((ele) => ele.parameter && ele.parameterName);
    if (filtered?.length !== gridParameters?.length) alert('변수와 변수명이 입력되지 않은 건은 저장에서 제외됩니다');

    filtered.forEach((ele, ppi) => {
      ele.level = ele._$depth ? ele._$depth : ele.level;
      delete ele.children;
    });
    const dataDTO = {
      ...data,
      gridParameters: filtered,
    };
    return dataDTO;
  };

  const saveDetail = async (data: GridDTO) => {
    if (window.confirm(detailObj?.item ? '수정사항을 저장하시겠습니까?' : '새그리드를 생성하시겠습니까?')) {
      if (validation(data)) {
        const dataDTO = makeData(data);
        const rs = detailObj?.item ? await userService.modiGrid(dataDTO) : await userService.createGrid(dataDTO);
        if (rs?.status === 200) {
          alert('저장되었습니다');
          detailObj?.fetchFn(detailObj?.searchObj);
          setDetailObj(null);
        }
      }
    }
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks();

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    const tree = gridRef.current.getTreeGridData();
    const checked = gridRef.current.getCheckedRowItemsAll();
    if (id === 'upload-excel') {
      uploaderClick(id);
    } else if (id === 'delete-row') {
      const checkeds = checked?.map((ele) => ele._$uid);
      const filtered = tree.filter((ele) => !checkeds?.includes(ele._$uid));
      gridRef.current.setGridData(filtered);
    } else if (id === 'be-children') {
      if (gridRef.current.getTreeTotalDepth() > 3) {
        alert('그리드 헤더는 최대 3단까지 가능하여 불가 합니다');
      } else {
        gridRef.current.indentTreeDepth();
      }
    } else if (id === 'add-child') {
      gridRef.current.addTreeRow({ parameter: null, parentParameter: null, sort: tree?.length + 1 }, null, 'selectionDown');
    }
  };

  useDidMountEffect(() => {
    if (parsedData?.data?.length > 0) {
      const data = parsedData?.data?.map((row, i) => {
        return {
          parameterName: row[0],
          parameter: row[1],
          sort: i + 1,
          level: 1,
        };
      });
      gridRef.current.setGridData(data);
    }
  }, [parsedData]);

  return (
    <CommonModalNew
      style={{ width: 1200 }}
      title={!detailObj?.item ? '그리드 생성' : '그리드 상세'}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      children={
        <>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '40%', position: 'relative' }}>
              <Row>
                <Col>
                  <label>*그리드 아이디</label>
                  <InputD
                    placeholder="영문,숫자, 특수문자 _- 가능"
                    value={data?.gridId || ''}
                    disabled={detailObj?.item !== null}
                    onChange={(e) => {
                      if (/^[0-9a-zA-Z_-]*$/.test(e.target.value)) {
                        setData({
                          ...data,
                          gridId: e.target.value.toLocaleUpperCase(),
                        });
                      } else {
                        alert('영문,숫자, 특수문자 _- 만 입력 가능합니다');
                      }
                    }}
                  />
                </Col>
                <Col>
                  <label>*그리드명</label>
                  <InputD
                    value={data?.gridName || ''}
                    onChange={(e) => {
                      setData({
                        ...data,
                        gridName: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>그리드 유형</label>
                  <InputD disabled readOnly value={data?.gridType || 'PAGING'} />
                </Col>
                <Col>
                  <div className="grid-wrapper div2">
                    <div>
                      <label>*고정칼럼</label>
                      <InputD
                        type="number"
                        value={data?.fixedColumn || ''}
                        onChange={(e) => {
                          setData({
                            ...data,
                            fixedColumn: Number(e.target.value),
                          });
                        }}
                      />
                    </div>
                    <div>
                      <label>*페이지크기</label>
                      <SelectD
                        options={IGridPageRowSelectValues?.map((ele) => {
                          return {
                            label: ele,
                            value: ele,
                          };
                        })}
                        value={
                          data?.pageSize
                            ? {
                                label: data?.pageSize,
                                value: data?.pageSize,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setData({
                            ...data,
                            pageSize: (option as OptionItem).value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>설명</label>
                  <textarea
                    style={{ width: '100%' }}
                    value={data?.description || ''}
                    onChange={(e) => {
                      setData({
                        ...data,
                        description: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="grid-wrapper div2">
                    <div>
                      <label>*연결화면</label>
                      <SelectD
                        value={
                          data?.screenId
                            ? {
                                label: MASTER_OBJ?.SCREENID_URL?.[data?.screenId],
                                value: data?.screenId,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setData({
                            ...data,
                            screenId: (option as OptionItem).value,
                            url: (option as OptionItem).label,
                            tabId: null,
                          });
                        }}
                        options={MASTER_OPS?.SCREENID_URL}
                      />
                    </div>
                    <div>
                      <label>*연결탭</label>
                      <SelectD
                        isDisabled={!data?.screenId}
                        options={MASTER_OPS?.TAB_WHOLE?.filter((ele) => ele.screenId === data?.screenId).map((ele) => {
                          return {
                            value: ele.tabId,
                            label: ele.tabName,
                          };
                        })}
                        value={
                          data?.tabId
                            ? {
                                label: MASTER_OBJ?.TAB_NAME?.[data?.tabId],
                                value: data?.tabId,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setData({
                            ...data,
                            tabId: (option as OptionItem).value,
                            tabName: (option as OptionItem).label,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <label>사용여부</label>
              <div className="radio-area one-line">
                <Label>
                  <Input
                    type="radio"
                    checked={data?.useFlag || false}
                    onChange={(e) => {
                      setData({
                        ...data,
                        useFlag: true,
                      });
                    }}
                  />
                  &nbsp;&nbsp;사용
                </Label>
                <Label>
                  <Input
                    type="radio"
                    checked={!data?.useFlag || false}
                    onChange={(e) => {
                      setData({
                        ...data,
                        useFlag: false,
                      });
                    }}
                  />
                  &nbsp;&nbsp;사용 안 함
                </Label>
              </div>
            </div>
            <div style={{ width: '60%', padding: '0 30px' }}>
              <div className="grid-button-area space-between">
                <div>
                  <div className="blue" id="add-child" onClick={gridButtonhandler}>
                    자식 추가
                  </div>
                  <div className="orange" id="be-children" onClick={gridButtonhandler}>
                    상단의 자식으로
                  </div>
                  <div className="green" id="upload-excel" onClick={gridButtonhandler}>
                    <ExcelDetector />
                    엑셀로 업로드
                  </div>
                  {/* <div className="orange" id="be-level1" onClick={gridButtonhandler}>
                    폴더 해제
                  </div> */}
                </div>
                <div>
                  <div className="red" id="delete-row" onClick={gridButtonhandler}>
                    삭제
                  </div>
                </div>
              </div>
              <GridBox
                //
                gridRef={gridRef}
                columns={columns}
                gridProps={{
                  //
                  ...defaultGridProps,
                  editable: true,
                  height: 500,
                  treeIdField: 'parameter',
                  treeIdRefField: 'parentParameter',
                  showRowAllCheckBox: true,
                  showRowCheckColumn: true,
                  displayTreeOpen: true,
                  //
                  enableDrag: true,
                  enableMultipleDrag: true,
                  enableDragByCellDrag: true,
                  enableDrop: true,
                  dropToOthers: true,
                  //
                  rowStyleFunction: function (rowIndex, item) {
                    return '';
                  },
                }}
              />
            </div>
          </div>
          <div style={{ textAlign: 'right', padding: 30, paddingBottom: 0 }}>
            <button
              className={'btn btn-orange'}
              onClick={() => {
                saveDetail(data);
              }}
            >
              저장
            </button>
          </div>
        </>
      }
    />
  );
};
