import { ReactNode, useMemo, useRef, useState } from 'react';

// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';

// utils
import useSearchGridPaging from 'hooks/grid/useGridPaging';
// service
import { serviceStore } from 'services/services';
import { InventoryInspectDTO, InventoryInspectDetailDTO, InventoryInspectSearchDTO } from '../../../_interface/warehouse';

// component
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import { PagingListDTO } from 'interface/util';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import { useNavigate } from 'react-router-dom';
import { InspectPrint, IPrints } from './_print/inspectPrint';
import { ISerialObj, SerialsPopup } from './component/serialPopup';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const bottomGridRef = useRef<IPagingGrid>();
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const fetchPaging = async (searchObj: InventoryInspectSearchDTO) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore.warehouseAction(`inventory/inspect/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<InventoryInspectDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItems();
    if (items?.length > 0) {
      if (id === 'INVENTORY_INSPECT_TAB_CANCEL') {
        cancelInspect(items);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };
  const useNewDesignFlag = JSON.parse(localStorage.getItem('useNewDesignFlag'));
  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id?.includes('EXCEL')) downloadExcel(id);
    else if (id === 'INVENTORY_INSPECT_TAB_CREATE') {
      useNewDesignFlag === 1 ? navigate(`/main/inventoryInspect/inventoryInspectRegit`) : window.open(`/inventoryInspectRegit`);
    } else {
      handleCheckItems(id);
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const labellingKr = (data: PagingListDTO<InventoryInspectDTO & InventoryInspectDetailDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        //
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],
        statusKr: MASTER_OBJ?.INVENTORY_INSPECT_STATUS?.[row.status],
        supplierPartnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.supplierPartnerSeq],
        availableStatusKr: MASTER_OBJ?.INVA_STATUS?.[row.availableStatus],
        //
        resultKr: MASTER_OBJ?.INSPECT_FLAG?.[row?.result + ''],
        causeKr: MASTER_OBJ?.INSPECT_CAUSE?.[row?.cause],
        reasonKr: MASTER_OBJ?.INSPECT_REASON?.[row?.reason],
        processKr: MASTER_OBJ?.INSPECT_PROCESS?.[row?.process],
        //
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
        partnerSeqsKr: row.partnerSeqs?.map((ele) => MASTER_OBJ?.SELLER_WHOLE?.[ele])?.join(', '),
        serialNumbersKr: row.serialNumbers?.join(', '),
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const cancelInspect = async (items) => {
    if (window?.confirm(`보관재고검수를 취소 하시겠습니까?`)) {
      const rs = await serviceStore?.warehouseAction(
        `inspect/cancel`,
        'POST',
        null,
        items?.map((ele) => {
          return {
            seq: ele.inventoryInspectSeq,
          };
        }),
      );
      if (rs?.status === 200) {
        alert('취소 완료되었습니다');
        wrappedFetchList(searchObj);
      }
    }
  };

  const downloadExcel = async (id) => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction(`inventory/inspect/${id?.includes('MAIN') ? '' : 'detail/'}excel`, 'GET', searchObj, null))?.data as InventoryInspectDTO[],
      },
      true,
    );

    excelGridRef.current.setGridData(list);
    excelGridRef?.current?.changeColumnLayout(id?.includes('MAIN') ? columns?.filter((_, idx) => idx > 1) : detailExcelColumns);
    excelGridRef.current.exportAsXlsx({ fileName: `재고검수${id?.includes('MAIN') ? '(메인)' : '(상세)'}` });
    dispatch(setLoading(null));
  };

  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const gridButtonhandler = (e) => {
      const id = e.target.id;
      if (id === 'excel') {
        bottomGridRef?.current?.exportAsXlsx({ fileName: `보관재고검수상세` });
      }
    };
    const data = (await serviceStore.warehouseAction(`inventory/inspect/detail?inventoryInspectSeq=${e.item?.inventoryInspectSeq}`, 'GET', null))?.data;
    const rows = labellingKr({ list: data })?.list;
    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '보관재고검수 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          buttons: [
            <div key={`excel`} className={`green`} id={`excel`} onClick={gridButtonhandler}>
              엑셀다운
            </div>,
          ],
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
      ],
    });

    dispatch(setLoading(null));
  };
  const [serialObj, setSerialObj] = useState<ISerialObj>();
  const popupSerial = (e: IGrid.ButtonClickEvent) => {
    //
    setSerialObj({
      visible: true,
      item: e.item,
    });
  };

  const [printObj, setPrintObj] = useState<IPrints>();
  const fetchPrint = (e: IGrid.ButtonClickEvent) => {
    setPrintObj({ visible: true, item: e.item });
  };
  const columns: IGrid.Column[] = [
    {
      headerText: '작업지시서',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '프린트',
        onClick: fetchPrint,
      },
    },
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetail,
      },
    },
    {
      headerText: '작업지시번호',
      dataField: 'inventoryInspectSeq',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqsKr',
    },
    {
      headerText: '검수창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '검수상태',
      dataField: 'statusKr',
    },
    {
      headerText: '검수지시수량',
      children: [
        {
          headerText: '총',
          dataField: 'quantity',
        },
        {
          headerText: '양품',
          dataField: 'availableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
        },
      ],
    },
    {
      headerText: '검수완료수량',
      children: [
        {
          headerText: '총',
          dataField: 'confirmQuantity',
        },
        {
          headerText: '양품',
          dataField: 'confirmAvailableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'confirmDefectedQuantity',
        },
      ],
    },
    {
      headerText: '전기일',
      dataField: 'postingDate',
    },
    {
      headerText: '검수생성일',
      dataField: 'registerDatetime',
    },
    {
      headerText: '검수완료일시',
      dataField: 'confirmDatetime',
    },
    {
      headerText: '검수완료자',
      dataField: 'confirmId',
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '작업지시번호',
      dataField: 'inventoryInspectSeq',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '로케이션',
          dataField: 'locationCode',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
    },
    {
      headerText: '재고상태',
      dataField: 'availableStatusKr',
    },
    {
      headerText: '검수지시수량',
      dataField: 'quantity',
    },
    {
      headerText: '검수완료수량',
      children: [
        {
          headerText: '양품',
          dataField: 'confirmAvailableQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: popupSerial,
            disabledFunction(rowIndex, columnIndex, value, item, dataField) {
              console.log(value);
              if (value === 0) return true;
              if (item?.confirmAvailableQuantity < item?.confirmDefectedQuantity) return true;
              if (item?.confirmAvailableQuantity === item?.confirmDefectedQuantity && item?.availableStatusKr === '불용') return true;
              return false;
            },
          },
        },
        {
          headerText: '불용',
          dataField: 'confirmDefectedQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: popupSerial,
            disabledFunction(rowIndex, columnIndex, value, item, dataField) {
              if (value === 0) return true;
              if (item?.confirmAvailableQuantity > item?.confirmDefectedQuantity) return true;
              if (item?.confirmAvailableQuantity === item?.confirmDefectedQuantity && item?.availableStatusKr === '양품') return true;
              return false;
            },
          },
        },
      ],
    },
    {
      headerText: '판정결과',
      dataField: 'resultKr',
    },
    {
      headerText: '귀책사유',
      dataField: 'causeKr',
    },
    {
      headerText: '불용사유',
      dataField: 'reasonKr',
    },
    {
      headerText: '조치사항',
      dataField: 'processKr',
    },
    {
      headerText: '검수완료일시',
      dataField: 'confirmDatetime',
    },
    {
      headerText: '검수완료자',
      dataField: 'confirmId',
    },
  ];

  const detailExcelColumns: IGrid.Column[] = [
    {
      headerText: '발주번호',
      dataField: 'purchaseSeq',
    },
    {
      headerText: '입고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '검수상태',
      dataField: 'statusKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '검수지시수량',
      dataField: 'quantity',
    },
    {
      headerText: '검수완료수량',
      children: [
        {
          headerText: '총 검수수량',
          dataField: 'confirmQuantity',
        },
        {
          headerText: '양품수량',
          dataField: 'confirmAvailableQuantity',
        },
        {
          headerText: '불용수량',
          dataField: 'confirmDefectedQuantity',
        },
      ],
    },
    {
      headerText: '시리얼번호리스트',
      dataField: 'serialNumbersKr',
    },
    {
      headerText: '판정결과',
      dataField: 'resultKr',
    },
    {
      headerText: '귀책사유',
      dataField: 'causeKr',
    },
    {
      headerText: '불용사유',
      dataField: 'reasonKr',
    },
    {
      headerText: '조치사항',
      dataField: 'processKr',
    },
    {
      headerText: '검수완료일시',
      dataField: 'confirmDatetime',
    },
    {
      headerText: '검수완료자',
      dataField: 'confirmId',
    },
  ];

  return (
    <div className="page-content">
      {serialObj?.visible && <SerialsPopup serialObj={serialObj} setSerialObj={setSerialObj} />}
      {printObj?.visible && <InspectPrint printObj={printObj} setPrintObj={setPrintObj} />}
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 2,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
