import { useEffect, useState, useRef } from 'react';
import { Row, Col, Input, Form, Table, Button } from 'reactstrap';

import 'react-dropdown/style.css';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { returnFeeTypes, returnIsInvoiceStatusFinished } from 'common/util/orderTypes';
import { v4 as uuidv4 } from 'uuid';
// img flags
import delivery from 'common/navermaps/flags/delivery.svg';
import visit from 'common/navermaps/flags/visit.svg';
import collect from 'common/navermaps/flags/collect.svg';
import exchange from 'common/navermaps/flags/exchange.svg';
import move from 'common/navermaps/flags/move.svg';
import store from 'common/navermaps/flags/store.svg';
import as from 'common/navermaps/flags/as.svg';
import fix from 'common/navermaps/flags/fix.svg';
import parcel from 'common/navermaps/flags/parcel.png';
import seperator from 'common/navermaps/flags/seperator.svg';

import repeat from './img/redo.png';
import centerImg from './img/center.png';
import noInvenPng from './img/noInven.png';
import noDriverPng from './img/noDriver.png';
import promised from './img/promise.png';

import './presenter.scss';
import ximg from 'assets/images/close/cross.png';
// navermaps
import { MarkerClustering } from 'common/navermaps/MarkerClustering';
import clusteringMarker from 'common/navermaps/marker/clustering.svg';

// component
import Grid from 'components/grid/auiGrid';

// utils
import _ from 'lodash';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { centerListForlabeling, driverListForLabeling } from 'common/master/codeMasterOptionReturn';
// redux
import { httpClient } from 'common/http-client/axiosConfig';
import { useSelector, useDispatch } from 'react-redux';
import { RAWDATA, setLoading } from 'redux/services/menuSlice';

import { defaultSearchObj } from './container';

import styled from 'styled-components';
import { useDidMountEffect } from 'hooks/basichooks';
import { copyAlert } from 'common/util/clipboard';
import CommonModalNew from 'components/modal/commonModalNew';
import Draggable from 'components/modal/draggable';
import { ProductInventoryCheck } from 'pages/ORDER/1order/orderListTab/common/component/productInventoryCheck';
import ModelStockModal from 'pages/ORDER/1order/orderListTab/common/component/invocieDetail/modelStockModal';

const ViewPoly = styled.div`
  width: 70px;
  height: 21px;
  position: absolute;
  bottom: -15px;
  right: 4px;
  display: flex;
  background-color: white;
  border: 1px solid #c7c7c7;
  z-index: 4;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
  border-radius: 3px;
`;
export const defaultMapProps = {
  zoom: 7,
  center: new window.naver.maps.LatLng(35.5, 127.23),
  bounds: null,
  zoomControl: true, //줌 컨트롤의 표시 여부
  zoomControlOptions: {
    //줌 컨트롤의 옵션
    position: window.naver.maps.Position.RIGHT_BOTTOM,
  },
};

const Presenter = (props) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);
  const { searchObj, setSearchObj, detailObj, setDetailObj, divideOrderObj, setDivideOrderObj, fetchRawData, fetchDetailData } = props;
  const [filteredOptions, setFilteredOptions] = useState();

  const navermaps = window.naver.maps;
  const [flagData, setFlagData] = useState({ count: null, data: null });

  const [mapProps, setMapProps] = useState(defaultMapProps);
  const [markerMode, setMarkerMode] = useState(null);
  const [clickedObj, setClickedObj] = useState();

  useEffect(() => {
    setSearchObj(defaultSearchObj);
    buildFirstMap();
    setDivideOrderObj((prev) => {
      return {
        ...prev,
        coloringFn: (data) => coloringData(data),
      };
    });
  }, []);

  const buildFirstMap = () => {
    const map = new navermaps.Map('map', mapProps);

    /* 변경점 */
    navermaps.onJSContentLoaded = function () {
      // const drawingManager = new navermaps.drawing.DrawingManager({ map: map });
    };
  };

  useEffect(() => {
    if (clickedObj) {
      if (divideOrderObj?.visible) {
        if (validatorForDivideList(clickedObj)) {
          let list;
          if (divideOrderObj?.list?.length > 0) {
            list = JSON.parse(JSON.stringify(divideOrderObj?.list));
            list.push(clickedObj);
          } else {
            list = [clickedObj];
          }
          setDivideOrderObj((prev) => {
            return {
              ...prev,
              list,
              searchObj,
            };
          });
        }
      } else {
        // detailPopup
        setDetailObj((prev) => {
          return {
            ...prev,
            ele: { ...clickedObj },
          };
        });
        setClickedObj(null);
      }
    }
  }, [clickedObj]);

  useEffect(() => {
    if (detailObj?.ele) {
      fetchDetailPopup(detailObj?.ele);
    }
  }, [detailObj?.ele]);

  const centerOptionsInInfras = async (infras) => {
    return await centerListForlabeling({ infraSeq: infras });
  };

  const driverOptionsInInfras = async (infras) => {
    const driversInInfras = await driverListForLabeling({ infraSeq: infras });
    const justConcat = _.flatten(driversInInfras);
    const hap = justConcat.reduce(function (acc, current) {
      // acc는 통과한 배열, current는 하나씩
      if (
        acc.findIndex(({ userId }) => {
          return userId === current.userId;
        }) === -1
      ) {
        acc.push(current);
      }
      return acc;
    }, []);
    return hap.map((ele) => {
      return {
        value: ele.userId,
        label: ele.driverName,
      };
    });
  };

  const driverOptionsInCenters = async (centers) => {
    const drivers = await driverListForLabeling({ centerCode: centers.map((ele) => ele.value) });
    const justConcat = _.flatten(drivers);
    const hap = justConcat.reduce(function (acc, current) {
      // acc는 통과한 배열, current는 하나씩
      if (
        acc.findIndex(({ userId }) => {
          return userId === current.userId;
        }) === -1
      ) {
        acc.push(current);
      }
      return acc;
    }, []);

    const DRIVER = hap.map((ele) => {
      return {
        value: ele.userId,
        label: ele.driverName,
      };
    });

    setFilteredOptions((prev) => {
      return {
        ...prev,
        DRIVER,
      };
    });
  };

  const fetchDriversNCenter = async (infras) => {
    const [CENTER, DRIVER] = await Promise.all([centerOptionsInInfras(infras), driverOptionsInInfras(infras)]);
    setFilteredOptions({
      CENTER,
      DRIVER,
    });
  };

  useEffect(() => {
    if (searchObj?.infraSeq?.length > 0) {
      console.log(searchObj?.infraSeq?.map((ele) => ele.value));
      fetchDriversNCenter(searchObj?.infraSeq?.map((ele) => ele.value));
    } else {
      setFilteredOptions({
        CENTER: MASTER_OPS?.CENTER_AUTH,
        DRIVER: MASTER_OPS?.DRIVER_AUTH,
      });
    }
  }, [searchObj?.infraSeq]);

  useEffect(() => {
    if (searchObj?.centerCode?.length > 0) {
      driverOptionsInCenters(searchObj?.centerCode);
    } else {
      setFilteredOptions((prev) => {
        return {
          ...prev,
          DRIVER: MASTER_OPS?.DRIVER_AUTH,
        };
      });
    }
  }, [searchObj?.centerCode]);

  const validatorForDivideList = (obj) => {
    const invoiceSeqs = divideOrderObj?.list.map((ele) => ele.invoiceSeq);
    if (invoiceSeqs?.includes(obj?.invoiceSeq)) {
      alert('이미 입력된 건입니다!');
      return false;
    }

    if (obj?.feeType?.includes('PARCEL')) {
      alert('택배는 주문분배 할 수 없습니다');
      return false;
    }

    if (obj?.invoiceStatus.includes('FINISH')) {
      alert('완료된 건은 주문분배 불가합니다!');
      return false;
    }

    return true;
  };

  const fetchDetailPopup = async (ele) => {
    const data = await fetchDetailData(ele.customerOrderInvoiceSeq);
    if (data) {
      setDetailObj((prev) => {
        return {
          ...prev,
          visible: true,
          data,
          searchObj,
          coloringFn: (data) => coloringData(data),
        };
      });
    }
  };

  useDidMountEffect(() => {
    if (searchObj?.fromDeliveryDate && searchObj?.toDeliveryDate && mapProps?.bounds) {
      coloringData(searchObj);
    }
  }, [searchObj?.toDeliveryDate, searchObj?.promiseFlag, searchObj?.viewPolyFlag]);

  const coloringData = async (searchObj) => {
    const rawData = await fetchRawData(searchObj, mapProps?.bounds);
    if (rawData) {
      let delivery = 0;
      let visit = 0;
      let collect = 0;
      let exchange = 0;
      let move = 0;
      let store = 0;
      let as = 0;
      let fix = 0;
      let parcel = 0;
      let finish = 0;

      const _typedData = JSON.parse(JSON.stringify(rawData));

      _typedData.forEach((ele) => {
        if (returnFeeTypes(ele.feeType.trim()) === 'delivery') {
          delivery += 1;
          ele.type = 'delivery';
          ele.color = '#3EA1FF';
        } else if (returnFeeTypes(ele.feeType.trim()) === 'visit') {
          visit += 1;
          ele.type = 'visit';
          ele.color = '#FFAD0F';
        } else if (returnFeeTypes(ele.feeType.trim()) === 'collect') {
          collect += 1;
          ele.type = 'collect';
          ele.color = '#FFA09C';
        } else if (returnFeeTypes(ele.feeType.trim()) === 'exchange') {
          exchange += 1;
          ele.type = 'exchange';
          ele.color = '#61CACA';
        } else if (returnFeeTypes(ele.feeType.trim()) === 'move') {
          move += 1;
          ele.type = 'move';
          ele.color = '#CA87FF';
        } else if (returnFeeTypes(ele.feeType.trim()) === 'store') {
          store += 1;
          ele.type = 'store';
          ele.color = '#DDA97A';
        } else if (returnFeeTypes(ele.feeType.trim()) === 'as') {
          as += 1;
          ele.type = 'as';
          ele.color = '#21AD76';
        } else if (returnFeeTypes(ele.feeType.trim()) === 'fix') {
          fix += 1;
          ele.type = 'fix';
          ele.color = '#21AD76';
        } else if (returnFeeTypes(ele.feeType.trim()) === 'parcel') {
          parcel += 1;
          ele.type = 'parcel';
          ele.color = '#5772D4';
        } else if (returnFeeTypes(ele.feeType.trim()) === 'nothing') {
          ele.color = 'white';
        }

        if (returnIsInvoiceStatusFinished(ele.invoiceStatus.trim())) {
          finish += 1;
          ele.color = '#A3A7B9';
        }
      });

      const finished = _typedData?.filter((ele) => ele.invoiceStatus.includes('FINISH')).length;
      const nonFinished = _typedData?.length - finished;
      setFlagData({
        data: _typedData,
        count: {
          total: rawData.length,
          delivery,
          visit,
          collect,
          exchange,
          move,
          store,
          as,
          fix,
          parcel,
          finish,
          finished,
          nonFinished,
        },
      });
    }
  };

  useEffect(() => {
    if (flagData?.data) {
      sortingData(flagData?.data, markerMode);
    }
  }, [flagData?.data, markerMode]);

  const sortingData = (rawData, markerMode) => {
    let typedData;
    if (markerMode && markerMode !== 'null') {
      if (markerMode?.includes('finish')) {
        if (markerMode === 'nonfinish') typedData = rawData.filter((ele) => !ele.invoiceStatus.includes('FINISH'));
        else typedData = rawData.filter((ele) => ele.invoiceStatus.includes('FINISH'));
      } else {
        typedData = rawData.filter((ele) => ele.type === markerMode);
      }
    } else {
      typedData = rawData;
    }

    const latPlate = [];
    const lngPlate = [];
    const coordsPlate = {};

    typedData?.forEach((ele, idx) => {
      if (idx === 0) {
        latPlate.push(ele.latitude);
        lngPlate.push(ele.longitude);
        coordsPlate[idx] = [ele];
      } else {
        let isPlateHasCoords = -1;
        latPlate.forEach((lat, i) => {
          if (lat === ele.latitude && lngPlate[i] === ele.longitude) {
            isPlateHasCoords = i;
          }
        });

        if (isPlateHasCoords === -1) {
          latPlate.push(ele.latitude);
          lngPlate.push(ele.longitude);
          coordsPlate[latPlate.length - 1] = [ele];
        } else {
          coordsPlate[isPlateHasCoords].push(ele);
        }
      }
    });
    setMarkerOnMap({
      latPlate,
      lngPlate,
      coordsPlate,
      markerMode,
    });
  };

  const initMap = () => {
    const mapid = document.querySelector('#map');
    if (mapid) {
      mapid.remove(); // 제거
      const newMapid = document.createElement('div');
      newMapid.id = 'map';
      const parentDiv = document.querySelector('.mapIncludes2');
      parentDiv.appendChild(newMapid);
    }
    const map = new navermaps.Map('map', mapProps);
    return map;
  };

  const markerOnMap = (plate) => {
    const { coordsPlate, latPlate, lngPlate, markerMode } = plate;
    const coordsPlates = JSON.parse(JSON.stringify(coordsPlate));
    // initMap
    const map = initMap();
    const markers = [];
    const paths = {};
    const pathsElems = {};
    searchObj?.userId?.forEach((ele) => {
      pathsElems[ele.value] = [];
      paths[ele.value] = [];
    });

    // 지도위 좌표별 마커만큼
    Object.keys(coordsPlates)?.forEach((key, i) => {
      const lastEle = coordsPlates[i][coordsPlates[i].length - 1];
      const moreThan1 = coordsPlates[i].length;
      const noInven = lastEle?.invoiceStatus === 'NOT_IN_INVENTORY' ? true : false;
      const hasNoDriver = lastEle?.invoiceStatus === 'NOT_EXIST_DRIVER' ? true : false;
      const isMixed = coordsPlates[i].filter((ele) => ele.promiseFlag).length * coordsPlates[i].filter((ele) => ele.promiseFlag === false).length !== 0;
      const isPromised = isMixed ? false : coordsPlates[i].filter((ele) => ele.promiseFlag).length > 0;
      const active = markerMode === null ? true : lastEle.type === markerMode;
      const isFinish = lastEle.invoiceStatus?.includes('FINISH') && markerMode === 'finish' ? true : false;
      const isNonFinish = !lastEle.invoiceStatus.includes('FINISH') && markerMode === 'nonfinish' ? true : false;

      if (searchObj?.userId?.length > 0) {
        coordsPlates[i].forEach((ele, idx) => {
          if (ele.promiseFlag) {
            pathsElems[ele.userId].push(ele);
            if (paths[ele.userId]?.length === 0) {
              paths[ele.userId] = [[latPlate[i], lngPlate[i]]];
            } else {
              const [beforeLat, beforeLng] = paths[ele.userId][paths[ele.userId]?.length - 1];
              if (beforeLat !== latPlate[i] && beforeLng !== lngPlate[i]) {
                paths[ele.userId].push([(beforeLat + latPlate[i]) / 2, (beforeLng + lngPlate[i]) / 2]);
                paths[ele.userId].push([latPlate[i], lngPlate[i]]);
              }
            }
          }
        });
      } else {
        coordsPlates[i].forEach((ele, idx) => {
          if (ele.promiseFlag) {
            !pathsElems[ele.userId] ? (pathsElems[ele.userId] = [ele]) : pathsElems[ele.userId].push(ele);
          }
        });
      }

      const marker = new navermaps.Marker({
        position: new navermaps.LatLng(lastEle.latitude, lastEle.longitude),
        map: map,
        zIndex: isFinish || isNonFinish || active ? 10 : 1,
        icon: {
          content: `
          <svg class='marker ${lastEle.latitude}_${lastEle.longitude} ${lastEle.invoiceSeq}' 
           width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.3" cx="20" cy="20" r="20" fill=${lastEle.color} />
          <circle cx="20" cy="20" r="17" fill=${lastEle.color} />
          ${noInven ? `<span class="labelOnMarker noInven"><img src=${noInvenPng} /></span>` : ''}
          ${hasNoDriver ? `<span class="labelOnMarker noDriver"><img src=${noDriverPng} /></span>` : ''}
          <span class="moreThan1v2 _${moreThan1} ${isMixed ? 'mixed' : ''}">${moreThan1 > 1 ? moreThan1 : ''}</span>
          ${isPromised ? `<span class="promised"> <img src=${promised} /></span>` : ''}
          ${isMixed ? `<span class="isMixed">MIX</span>` : ''}
          </svg>
          `,
          size: new navermaps.Size(22, 35),
          anchor: new navermaps.Point(20, 20),
          animation: navermaps.Animation.BOUNCE,
        },
      });

      // 마커 클릭시 인포창 안의 마커들
      let contents = '';
      if (moreThan1 > 1) {
        coordsPlates[i].forEach((ele, index) => {
          contents += `<div class="relative-box ${lastEle.latitude}_${lastEle.longitude} ${ele.invoiceSeq}" id="${i}_${index}">
          <span class="inner-absolute-box">${ele.receiverName}</span>
          <span class="inner-absolute-box">
            <span class="${MASTER_OBJ?.INVOICE_STATUS?.[ele?.invoiceStatus] === '재고없음' ? 'orange' : ''}">
            ${MASTER_OBJ?.INVOICE_STATUS?.[ele?.invoiceStatus]}
            </span>
            ${MASTER_OBJ?.FEE_TYPE?.[ele?.feeType]}
          </span>
          <svg width="170" height="60" viewBox="0 0 126 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="170" height="60" rx="2" fill="white"/>
          <circle opacity="0.3" cx="19.6875" cy="21.4375" r="12.6875" fill=${ele.color} />
          <circle cx="19.6875" cy="21.4375" r="9.1875" fill=${ele.color} >
          ${ele.promiseFlag ? `<span class="promised"> <img src=${promised} /></span>` : ''}
          </circle>
          <path d="M14.9116 21.4934L18.4327 25.0835L25.0835 18.3022" stroke=${
            ele.invoiceStatus === 'FINISH' ? 'white' : 'transparent'
          }  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </div>
          `;
        });
      }
      contents = "<div class='wrapper-whole'>" + contents + '</div>';
      const infowindow = new navermaps.InfoWindow({
        content: contents,
        borderColor: '#e2e2e2',
        borderRadius: 10,
        maxHeight: 500,
      });

      ///////////////////////////////// 보이는 부분만 마커표시 START

      navermaps.Event.addListener(map, 'idle', function (e) {
        updateMarkers(map, markers);
      });

      function updateMarkers(map, markers) {
        var mapBounds = map.getBounds();
        var marker, position;

        for (var i = 0; i < markers.length; i++) {
          marker = markers[i];
          position = marker.getPosition();

          if (mapBounds.hasLatLng(position)) {
            showMarker(map, marker);
          } else {
            hideMarker(map, marker);
          }
        }
      }

      function showMarker(map, marker) {
        if (marker.getMap()) return;
        marker.setMap(map);
      }

      function hideMarker(map, marker) {
        if (!marker.getMap()) return;
        marker.setMap(null);
      }

      ///////////////////////////////// 보이는 부분만 마커표시 END

      navermaps.Event.addListener(map, 'click', function (e) {
        if (infowindow.getMap()) {
          infowindow.close();
        }
      });

      navermaps.Event.addListener(marker, 'click', function (e) {
        if (moreThan1 > 1) {
          if (infowindow.getMap()) {
            infowindow.close();
          } else {
            infowindow.open(map, marker);
            coordsPlates[i].forEach((ele, index) => {
              const unit = document.getElementById(`${i}_${index}`);
              unit.onclick = (e) => {
                e.preventDefault();
                setClickedObj(ele);
              };
            });
          }
        } else {
          // 1개일때
          setClickedObj(lastEle);
        }
      });
      markers.push(marker);
    });

    function makeMakerCenterIfExist(centerCode) {
      const latLng = MASTER_OPS?.[`${RAWDATA}CENTER_WHOLE`]?.filter((ele) => ele.centerCode === centerCode)?.[0];
      if (latLng) {
        new navermaps.Marker({
          position: new navermaps.LatLng(latLng.latitude, latLng.longitude),
          map: map,
          zIndex: 3,
          icon: {
            content: `<img src=${centerImg} class="center-marker"/>`,
            size: new navermaps.Size(30, 42),
            origin: new navermaps.Point(0, 0),
            anchor: new navermaps.Point(15, 42),
          },
        });
      }
    }

    const centerMarkers = [];
    if (searchObj?.centerCode?.length > 0) {
      searchObj?.centerCode?.forEach((center) => {
        centerMarkers.push(center?.value);
        makeMakerCenterIfExist(center?.value);
      });
    }

    if (searchObj?.userId?.length > 0) {
      searchObj?.userId?.forEach((user) => {
        const userCenter = MASTER_OPS?.[`${RAWDATA}DRIVER_WHOLE`]?.filter((ele) => ele.userId === user.value)?.[0]?.centerCode;
        if (!centerMarkers.includes(userCenter)) {
          centerMarkers.push(userCenter);
          makeMakerCenterIfExist(userCenter);
        }
      });
    }

    if (searchObj?.userId?.length > 0 && searchObj?.fromDeliveryDate === searchObj?.toDeliveryDate && searchObj?.viewPolyFlag) {
      const pathColor = ['red', 'purple', 'green'];
      const orderMarkers = [];
      Object.keys(pathsElems)?.forEach((key, i) => {
        pathsElems[key].forEach((ele, idx) => {
          let content = `
          <span class="startEndText  ${pathColor[i]}">
          ${idx === 0 ? 'START' : idx === pathsElems[key]?.length - 1 ? 'END' : ''}
          </span>`;
          if (ele.deliveryDatetime) {
            const [h, m, s] = ele.deliveryDatetime?.split(' ')[1]?.split(':');
            content += `
                <div class="label-wrapper">
                  <span class="label-time">(${idx + 1}) ${h}: ${m}</span>
                </div>`;
          }
          const marker = new navermaps.Marker({
            position: new navermaps.LatLng(ele.latitude, ele.longitude),
            map: map,
            zIndex: 12,
            icon: {
              content,
              size: new navermaps.Size(22, 35),
              anchor: new navermaps.Point(25, 25),
              animation: navermaps.Animation.BOUNCE,
            },
          });
          orderMarkers.push(marker);
        });
      });
      Object.keys(paths)?.forEach((key, i) => {
        paths[key].forEach((ele, idx) => {
          if (idx !== 0) {
            const path = [new navermaps.LatLng(paths[key][idx - 1][0], paths[key][idx - 1][1]), new navermaps.LatLng(ele[0], ele[1])];
            new navermaps.Polyline({
              map: map,
              path,
              clickable: true,
              strokeColor: pathColor[i],
              strokeStyle: 'shortdash',
              strokeOpacity: 0.7,
              strokeWeight: 5,
              endIcon: 1,
              endIconSize: 15,
            });
          }
        });
      });
    }

    new MarkerClustering({
      minClusterSize: 2,
      maxZoom: 9,
      map: map,
      markers: markers,
      disableClickZoom: true,
      gridSize: 150,
      icons: [clusteringMarker],
      indexGenerator: [10, 100, 200, 500, 1000],
      averageCenter: true,
      stylingFunction: function (clusterMarker, count) {
        clusterMarker.getElement().innerHTML = `<span class="cluster-count"><span>${count}<span></span>`;
      },
    });

    // idle: 유휴상태(움직임이 멈춘)
    navermaps.Event.addListener(map, 'idle', function () {
      // 인터랙션한 정보를 state에 담는다
      const { _lat, _lng } = map.getOptions('center');
      const center = new navermaps.LatLng(_lat, _lng);
      const bounds = map.getBounds();
      const zoom = map.getOptions('zoom');
      setMapProps({
        ...mapProps,
        bounds,
        center,
        zoom: zoom + 1,
      });
    });
  };

  const setMarkerOnMap = (plate) => {
    if (plate?.latPlate?.length > 0) initMap();
    markerOnMap(plate);
  };

  const handleFixedFlag = (e) => {
    setMarkerMode(null);
    setSearchObj((prev) => {
      return {
        ...prev,
        promiseFlag: e.target.id,
      };
    });
  };

  const markerModeHandler = (mode) => {
    setMarkerMode(mode);
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      coloringData(searchObj);
    }
  };

  useEffect(() => {
    if (divideOrderObj?.visible) {
      const unit = document.getElementsByClassName(clickedObj?.invoiceSeq);
      if (unit?.length >= 2) {
        unit.forEach((ele, i) => {
          if (i !== 0) ele.classList?.add('active');
        });
      }
      const unit2 = document.getElementsByClassName(`${clickedObj?.latitude}_${clickedObj?.longitude}`);
      if (unit2?.length >= 1) {
        unit2?.forEach((ele) => {
          if (ele.classList.contains('marker')) ele.classList?.add('active');
        });
      }
      setClickedObj(null);
    } else {
      sortingData(flagData?.data, markerMode);
    }
  }, [divideOrderObj?.list]);

  const [modelStockObj, setModelStockObj] = useState();

  useEffect(() => {
    if (modelStockObj?.returns?.length > 0) {
      const model = { ...modelStockObj?.returns?.[0] };
      setSearchObj((prev) => {
        return {
          ...prev,
          modelStockSeq: model?.modelStockSeq,
          modelName: model?.modelName,
        };
      });
      setModelStockObj(null);
    }
  }, [modelStockObj?.returns]);

  const dragParentRef = useRef();
  return (
    <div
      ref={dragParentRef}
      className={`full-map mapIncludes2 page-content ${divideOrderObj?.list.map((ele) => ele.invoiceSeq)?.join(' ')}  ${divideOrderObj?.visible ? 'lets-divide' : ''} `}
      style={{ padding: '3rem 0 0 0' }}
    >
      {modelStockObj?.visible && <ModelStockModal modelStockObj={modelStockObj} setModelStockObj={setModelStockObj} />}
      {divideOrderObj?.visible && <DividerPopup divideOrderObj={divideOrderObj} setDivideOrderObj={setDivideOrderObj} />}
      {detailObj?.visible && <DetailModal detailObj={detailObj} setDetailObj={setDetailObj} />}
      <Form onKeyPress={onKeyPress} className={divideOrderObj?.visible ? 'map-search noTouch' : 'map-search'}>
        <div>
          <SelectD
            isMulti
            placeholder={'인프라 선택...'}
            value={searchObj?.infraSeq}
            options={MASTER_OPS?.INFRA?.filter((ele) => ele.value)}
            onChange={(options) => {
              options?.length > 3
                ? alert('3개까지 선택가능합니다!')
                : setSearchObj({
                    ...searchObj,
                    infraSeq: options,
                  });
            }}
          />
        </div>
        <div>
          <SelectD
            isMulti
            placeholder={'판매사 선택...'}
            value={searchObj?.partnerSeq}
            options={MASTER_OPS?.SELLER_SELLER_AUTH?.filter((ele) => ele.value)}
            onChange={(options) => {
              options?.length > 3
                ? alert('3개까지 선택가능합니다!')
                : setSearchObj({
                    ...searchObj,
                    partnerSeq: options,
                  });
            }}
          />
        </div>
        <div>
          <SelectD
            isMulti
            placeholder={'창고 선택...'}
            options={filteredOptions?.CENTER?.filter((ele) => ele.value)}
            value={searchObj?.centerCode}
            onChange={(options) => {
              options?.length > 3
                ? alert('3개까지 선택가능합니다!')
                : setSearchObj({
                    ...searchObj,
                    centerCode: options,
                  });
            }}
          />
        </div>
        <div style={{ position: 'relative' }}>
          {searchObj?.userId?.length > 0 && (
            <ViewPoly>
              <input
                type="checkbox"
                id="viewPolyFlag"
                checked={searchObj?.viewPolyFlag || true}
                onChange={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      viewPolyFlag: !prev.viewPolyFlag,
                    };
                  });
                }}
              />
              <label htmlFor="viewPolyFlag">동선 보기</label>
            </ViewPoly>
          )}
          <SelectD
            className="colors3"
            isMulti
            placeholder={'기사 선택...'}
            options={filteredOptions?.DRIVER?.filter((ele) => ele.value)}
            value={searchObj?.userId}
            onChange={(options) => {
              options?.length > 3
                ? alert('3개까지 선택가능합니다!')
                : setSearchObj({
                    ...searchObj,
                    userId: options,
                  });
            }}
          />
        </div>
        <div>
          <SelectD
            isMulti
            placeholder={'지역 선택...'}
            options={MASTER_OPS?.REGION}
            value={searchObj?.region}
            onChange={(options) => {
              options?.length > 3
                ? alert('3개까지 선택가능합니다!')
                : setSearchObj({
                    ...searchObj,
                    region: options,
                  });
            }}
          />
        </div>
        <div>
          <InputD
            placeholder={'고객명 입력...'}
            style={{ height: 28 }}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                customerName: e.target.value,
              });
            }}
          />
        </div>
        <div style={{ position: 'relative' }}>
          <span
            style={{
              position: 'absolute',
              top: 8,
              zIndex: 3,
              right: 5,
              cursor: 'pointer',
              color: '#c5c5c5',
            }}
            onClick={() => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  modelName: null,
                  modelStockSeq: null,
                };
              });
            }}
          >
            <img src={ximg} alt="" style={{ width: 7 }} />
          </span>
          <InputD
            title={searchObj?.modelName}
            readOnly
            value={searchObj?.modelName || ''}
            placeholder="클릭시 제품검색 팝업"
            className="read-only-should-click"
            onClick={(e) => {
              setModelStockObj((prev) => {
                return {
                  ...prev,
                  isRadio: true,
                  visible: true,
                };
              });
            }}
          />
        </div>
        <div style={{ width: 180 }}>
          <FlatpickrD
            value={[searchObj?.fromDeliveryDate, searchObj?.toDeliveryDate]}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                fromDeliveryDate: returnDateyyyymmdd(option[0]),
                toDeliveryDate: returnDateyyyymmdd(option[1]),
              });
            }}
            options={{
              mode: 'range',
            }}
          />
        </div>
        <div
          style={{ width: 50, backgroundColor: 'black', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
          onClick={() => {
            coloringData(searchObj);
          }}
        >
          검색
        </div>
      </Form>
      <div className={divideOrderObj?.visible ? 'map-result noTouch handle' : 'map-result handle'}>
        {(!searchObj?.userId || searchObj?.userId?.length === 0) && (
          <div>
            <label>기사님</label>
            <span>{filteredOptions?.DRIVER?.length - 1 || '-'}명</span>
          </div>
        )}
        <img src={seperator} className="seperator" alt="" />
        <div>
          <label>총</label>
          <span className="clickable" onClick={() => markerModeHandler(null)}>
            {flagData?.count?.total}건
          </span>
        </div>
        <img src={seperator} className="seperator" alt="" />
        <div>
          <label>완료</label>
          <span className="clickable" onClick={() => markerModeHandler('finish')}>
            {flagData?.count?.finished}건
          </span>
        </div>
        <img src={seperator} className="seperator" alt="" />
        <div>
          <label>미완료</label>
          <span className="clickable" onClick={() => markerModeHandler('nonfinish')}>
            {flagData?.count?.nonFinished}건
          </span>
        </div>
      </div>
      <div className={divideOrderObj?.visible ? 'map-fix-radio noTouch' : 'map-fix-radio'}>
        <span id="null" className={searchObj?.promiseFlag === 'null' ? 'active-p' : ''} onClick={handleFixedFlag}>
          전체
        </span>
        <span id="fixed" className={searchObj?.promiseFlag === 'fixed' ? 'active-p' : ''} onClick={handleFixedFlag}>
          지정
        </span>
        <span id="none-fixed" className={searchObj?.promiseFlag === 'none-fixed' ? 'active-p' : ''} onClick={handleFixedFlag}>
          미지정
        </span>
      </div>
      <div className={divideOrderObj?.visible ? 'map-flags noTouch' : 'map-flags'}>
        <span onClick={() => markerModeHandler('delivery')}>
          <img src={delivery} alt="" />
          <span>{flagData?.count?.delivery} 건</span>
        </span>
        <span onClick={() => markerModeHandler('visit')}>
          <img src={visit} alt="" />
          <span>{flagData?.count?.visit}건</span>
        </span>
        <span onClick={() => markerModeHandler('collect')}>
          <img src={collect} alt="" />
          <span>{flagData?.count?.collect}건</span>
        </span>
        <span onClick={() => markerModeHandler('exchange')}>
          <img src={exchange} alt="" />
          <span>{flagData?.count?.exchange}건</span>
        </span>
        <span onClick={() => markerModeHandler('move')}>
          <img src={move} alt="" />
          <span>{flagData?.count?.move}건</span>
        </span>
        <span onClick={() => markerModeHandler('store')}>
          <img src={store} alt="" />
          <span>{flagData?.count?.store}건</span>
        </span>
        <span onClick={() => markerModeHandler('as')}>
          <img src={as} alt="" />
          <span>{flagData?.count?.as}건</span>
        </span>
        <span onClick={() => markerModeHandler('fix')}>
          <img src={fix} alt="" />
          <span>{flagData?.count?.fix}건</span>
        </span>
        <span onClick={() => markerModeHandler('parcel')}>
          <img src={parcel} alt="" />
          <span>{flagData?.count?.parcel}건</span>
        </span>
      </div>
      <div
        className="map-delivery-button"
        onClick={() => {
          setDivideOrderObj((prev) => {
            return {
              ...prev,
              visible: !divideOrderObj?.visible,
              list: [],
            };
          });
        }}
      >
        {divideOrderObj?.visible ? '취소' : '주문분배'}
      </div>
      <div id="map" />
      <div
        className="searchBar"
        onClick={() => {
          coloringData(searchObj);
        }}
      >
        <img src={repeat} alt="" />현 지도에서 검색
      </div>
    </div>
  );
};

export default Presenter;

const DividerPopup = (props) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);
  const { divideOrderObj, setDivideOrderObj } = props;
  const [gridId, _] = useState(`map_dividerPoup_${uuidv4()}`);
  const [rows, setRows] = useState();
  const [inventoryCheckObj, setInventoryCheckObj] = useState();
  const [driverInCenterOption, setDriverInCenterOption] = useState();

  const deleteEle = (e) => {
    const list = window.AUIGrid.getGridData(`#${gridId}`).filter((ele, idx) => idx !== e.rowIndex);
    const unit = document.getElementsByClassName(e.item?.invoiceSeq);
    if (unit.length >= 2) {
      unit.forEach((ele, i) => {
        if (i !== 0) ele.classList?.remove('active');
      });
    }
    const actives = document.getElementsByClassName(`${e.item?.latitude}_${e.item?.longitude} active`);
    if (actives?.length === 1) actives[0].classList?.remove('active');

    setRows(list);
    setDivideOrderObj((prev) => {
      return {
        ...prev,
        list,
      };
    });
  };

  useEffect(() => {
    setRows(divideOrderObj?.list);
  }, [divideOrderObj?.list]);

  const columns = [
    {
      headerText: '-',
      width: 50,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '삭제',
        onClick: deleteEle,
      },
    },
    {
      headerText: '사용가능재고',
      dataField: 'availableInventoryExistStatusKr',
      style: 'click-fake-item-default',
      editable: false,
      headerTooltip: {
        show: true,
        tooltipHtml: '<div style="width:150px;"><div> O : 구성된 제품이 전부 있다</div><div> ▲ : 일부만 있다</div><div> X : 전부 없다</div></div>',
      },
    },
    {
      headerText: '약속일',
      dataField: 'deliveryDate',
      editable: false,
    },
    {
      headerText: '리드타임',
      dataField: 'deliveryDurationKr',
      editable: false,
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
      editable: false,
    },
    {
      headerText: '주기사',
      dataField: 'userIdKr',
      editable: false,
    },
    {
      headerText: '수령인',
      dataField: 'receiverName',
      editable: false,
    },
    {
      headerText: '기사요청사항',
      dataField: 'driverMemo',
      editable: false,
    },
    {
      headerText: '고객요청사항',
      dataField: 'shipmentNote',
      editable: false,
    },
    {
      headerText: 'CS입력',
      dataField: 'callCenterNote',
      width: 100,
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
      editable: false,
    },
    {
      headerText: '상품명',
      dataField: 'productName',
      editable: false,
    },
    {
      headerText: '설치소요시간',
      dataField: 'installDuration',
      editable: false,
    },
    {
      headerText: '설치팀유형',
      dataField: 'memberCountKr',
      editable: false,
    },
  ];

  useEffect(() => {
    if (divideOrderObj?.content?.centerCode) {
      setDrivers(divideOrderObj?.content?.centerCode);
      setAvailableInventoryExistStatus(divideOrderObj?.content?.centerCode);
    }
  }, [divideOrderObj?.content?.centerCode]);

  const setDrivers = (centerCode) => {
    const filtered = MASTER_OPS?.[`${RAWDATA}DRIVER_WHOLE`].filter((ele) => ele.centerCode === centerCode && ele.useFlag);
    const driverIncenterOption = filtered?.map(({ userId }) => {
      return {
        value: userId,
        label: MASTER_OBJ?.DRIVER_WHOLE?.[userId],
      };
    });
    setDriverInCenterOption(driverIncenterOption);
  };

  const setAvailableInventoryExistStatus = async (centerCode) => {
    const rows = window.AUIGrid.getGridData(`#${gridId}`);
    if (rows?.length > 0) {
      const data = { centerCode, invoiceSeq: rows.map((ele) => ele.invoiceSeq) };
      dispatch(setLoading('GET'));
      await httpClient.post(`/order/v2/invoice/exist/inventory/check`, data).then((rs) => {
        if (rs?.status === 200) {
          const data = rs.data;
          rows.forEach((ele, idx) => {
            ele.centerCode = centerCode;
            ele.centerCodeKr = MASTER_OBJ?.CENTER_WHOLE?.[centerCode];
            ele.availableInventoryExistStatus = data[idx].availableInventoryExistStatus;
            ele.availableInventoryExistStatusKr = !data[idx].availableInventoryExistStatus
              ? '확인불가 송장'
              : data[idx].availableInventoryExistStatus === 'ALL'
              ? 'O'
              : data[idx].availableInventoryExistStatus === 'PARTIAL'
              ? '▲'
              : 'X ';
          });
        }
      });
      setRows(rows);
      dispatch(setLoading(null));
    }
  };

  const letsDivide = async (data) => {
    const rows = window.AUIGrid.getGridData(`#${gridId}`);
    data.list = rows;
    const rs = await divideOrderObj?.divideFn(data);
    if (rs?.status === 200) {
      alert(rs?.data?.message);
      divideOrderObj?.coloringFn(divideOrderObj?.searchObj);
      setDivideOrderObj((prev) => {
        return {
          ...prev,
          visible: false,
          list: [],
          content: {
            deliveryDateFlag: false,
            deliveryDate: null,
            centerCode: null,
            userId: null,
            assistUserId: null,
            internUserId: null,
            confirmFlag: false,
          },
        };
      });
    }
  };

  const onCellClick = async (e) => {
    if (e.headerText === '사용가능재고' && e.value) {
      FetchInventoryDetailList(e.item);
    }
    //
    if (e.dataField === 'up') {
      window.AUIGrid.moveRowsToUp(e.pid);
      const row3 = window.AUIGrid.getGridData(e.pid);
      row3.forEach((row, idx) => {
        row.priority = idx + 1;
      });
      setRows({
        ...rows,
        strategy_3: row3,
      });
    } else if (e.dataField === 'down') {
      window.AUIGrid.moveRowsToDown(e.pid);
      const row3 = window.AUIGrid.getGridData(e.pid);
      row3.forEach((row, idx) => {
        row.priority = idx + 1;
      });
      setRows({
        ...rows,
        strategy_3: row3,
      });
    }
  };

  const FetchInventoryDetailList = async (item) => {
    if (item) {
      dispatch(setLoading('GET'));
      setInventoryCheckObj({ visible: true, item, centerCodeKr: item?.centerCodeKr });
      dispatch(setLoading(null));
    }
  };

  return (
    <>
      {inventoryCheckObj?.visible && <ProductInventoryCheck inventoryCheckObj={inventoryCheckObj} setInventoryCheckObj={setInventoryCheckObj} />}
      <Draggable handle=".handle">
        <div className={divideOrderObj?.visible ? 'divide-order-popup' : ''} style={{ display: 'none' }}>
          <div className="divide-order-popup-header handle">
            <span>주문분배</span>
            <span style={{ float: 'right' }}>
              <span>총 {divideOrderObj?.list?.length} 개</span>
            </span>
          </div>
          <div className="divide-order-popup-content">
            <div style={{ width: 950 }}>
              {gridId && (
                <Grid
                  id={gridId}
                  rows={rows}
                  style={{ height: 300, width: '100%' }}
                  columns={columns}
                  onCellClick={onCellClick}
                  settingOptions={{
                    showRowNumColumn: true,
                    showRowAllCheckBox: false,
                    showRowCheckColumn: false,
                    //
                    enableDrag: true,
                    enableMultipleDrag: true,
                    enableDragByCellDrag: true,
                    enableDrop: true,
                    dropToOthers: true,
                    //
                    fixedColumnCount: 4,
                  }}
                />
              )}
            </div>
            <div className="divide-content-wrapper">
              <Row style={{ flexDirection: 'column', hegith: 260 }}>
                <Col>
                  <label className="col-form-label" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>지정일 날짜선택</span>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        id="deliveryFlag"
                        style={{ marginRight: 5 }}
                        type="checkbox"
                        checked={divideOrderObj?.content?.deliveryDateFlag}
                        onChange={() => {
                          setDivideOrderObj((prev) => {
                            return {
                              ...prev,
                              content: {
                                ...prev.content,
                                deliveryDateFlag: !divideOrderObj?.content?.deliveryDateFlag,
                                deliveryDate: null,
                                confirmFlag: false,
                              },
                            };
                          });
                        }}
                      />
                      <label htmlFor="deliveryFlag">변경 여부</label>
                    </span>
                  </label>
                  <FlatpickrD
                    disabled={!divideOrderObj?.content?.deliveryDateFlag}
                    options={{
                      mode: 'single',
                    }}
                    value={divideOrderObj?.content?.deliveryDate}
                    onReset={() => {
                      setDivideOrderObj((prev) => {
                        return {
                          ...prev,
                          content: {
                            ...prev.content,
                            deliveryDate: null,
                          },
                        };
                      });
                    }}
                    onChange={(date) => {
                      setDivideOrderObj((prev) => {
                        return {
                          ...prev,
                          content: {
                            ...prev.content,
                            deliveryDate: returnDateyyyymmdd(date[0]),
                          },
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">창고선택</label>
                  <SelectD
                    value={
                      divideOrderObj?.content?.centerCode
                        ? {
                            value: divideOrderObj?.content?.centerCode,
                            label: MASTER_OBJ?.CENTER_WHOLE?.[divideOrderObj?.content?.centerCode],
                          }
                        : null
                    }
                    options={MASTER_OPS?.CENTER_AUTH}
                    onChange={({ value }) => {
                      setDivideOrderObj((prev) => {
                        return {
                          ...prev,
                          content: {
                            ...prev.content,
                            centerCode: value,
                          },
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">주기사선택</label>
                  <SelectD
                    isDisabled={!divideOrderObj?.content?.centerCode}
                    value={
                      divideOrderObj?.content?.userId
                        ? {
                            value: divideOrderObj?.content?.userId,
                            label: MASTER_OBJ?.DRIVER_WHOLE?.[divideOrderObj?.content?.userId],
                          }
                        : null
                    }
                    options={driverInCenterOption}
                    onChange={({ value }) => {
                      setDivideOrderObj((prev) => {
                        return {
                          ...prev,
                          content: {
                            ...prev.content,
                            userId: value,
                          },
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">부기사선택</label>
                  <SelectD
                    isDisabled={!divideOrderObj?.content?.centerCode}
                    value={
                      divideOrderObj?.content?.assistUserId
                        ? {
                            value: divideOrderObj?.content?.assistUserId,
                            label: MASTER_OBJ?.DRIVER_WHOLE?.[divideOrderObj?.content?.assistUserId],
                          }
                        : null
                    }
                    options={driverInCenterOption}
                    onChange={({ value }) => {
                      setDivideOrderObj((prev) => {
                        return {
                          ...prev,
                          content: {
                            ...prev.content,
                            assistUserId: value,
                          },
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">동행기사선택</label>
                  <SelectD
                    isDisabled={!divideOrderObj?.content?.centerCode}
                    value={
                      divideOrderObj?.content?.internUserId
                        ? {
                            value: divideOrderObj?.content?.internUserId,
                            label: MASTER_OBJ?.DRIVER_WHOLE?.[divideOrderObj?.content?.internUserId],
                          }
                        : null
                    }
                    options={driverInCenterOption}
                    onChange={({ value }) => {
                      setDivideOrderObj((prev) => {
                        return {
                          ...prev,
                          content: {
                            ...prev.content,
                            internUserId: value,
                          },
                        };
                      });
                    }}
                  />
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 10 }}>
                  <input
                    id="confirm"
                    style={{ marginRight: 5 }}
                    type="checkbox"
                    checked={divideOrderObj?.content?.confirmFlag}
                    onChange={() => {
                      if (divideOrderObj?.content?.deliveryDateFlag && !divideOrderObj?.content?.deliveryDate) {
                        alert('지정일 없이 스케줄 확정처리 할 수 없습니다');
                      } else {
                        setDivideOrderObj((prev) => {
                          return {
                            ...prev,
                            content: {
                              ...prev.content,
                              confirmFlag: !divideOrderObj?.content?.confirmFlag,
                            },
                          };
                        });
                      }
                    }}
                  />
                  <label htmlFor="confirm">스케줄 확정 </label>
                </Col>
              </Row>
            </div>
          </div>
          <div className="divide-order-popup-footer handle">
            <button
              className="btn btn-secondary sm"
              onClick={() => {
                setDivideOrderObj((prev) => {
                  return {
                    ...prev,
                    visible: false,
                    content: null,
                    list: [],
                  };
                });
              }}
            >
              취소
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => {
                if (window.confirm('주문분배 하시겠습니까?')) {
                  if (divideOrderObj?.content?.deliveryDateFlag && !divideOrderObj?.content?.deliveryDate && divideOrderObj?.content?.confirmFlag) {
                    alert('변경할 지정일 없이 스케줄 확정처리 할 수 없습니다');
                  } else {
                    letsDivide(divideOrderObj);
                  }
                }
              }}
            >
              주문분배
            </button>
          </div>
        </div>
      </Draggable>
    </>
  );
};
// inner component
const DetailModal = (props) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);
  const { detailObj, setDetailObj } = props;
  const [filteredDrivers, setFilteredDrivers] = useState(MASTER_OPS?.CENTER_AUTH);
  const [note, setNote] = useState(detailObj?.data?.callCenterNote);
  const [scheduleChangeModalVisible, setScheduleChangeModalVisible] = useState(false);
  const [scheduleChangeObj, setScheduleChangeObj] = useState({
    visible: false,
    searchBar: {
      invoiceSeqs: [detailObj?.ele?.customerOrderInvoiceSeq],
      centerCode: detailObj?.ele?.centerCode,
      userId: detailObj?.ele?.userId,
      deliveryDate: detailObj?.ele?.deliveryDate,
      confirmFlag: false,
      deliveryDateFlag: false,
    },
  });

  useEffect(() => {
    if (scheduleChangeObj?.searchBar?.centerCode) {
      setFilterDriverIncenter(scheduleChangeObj?.searchBar?.centerCode);
    }
  }, [scheduleChangeObj?.searchBar?.centerCode]);

  const setFilterDriverIncenter = async (centerCode) => {
    console.log(scheduleChangeObj?.searchBar?.centerCode);
    const drivers = MASTER_OPS?.[`${RAWDATA}DRIVER_WHOLE`]?.filter((ele) => scheduleChangeObj?.searchBar?.centerCode?.includes(ele.centerCode));
    setFilteredDrivers(
      drivers.map((ele) => {
        return {
          label: ele.driverName,
          value: ele.userId,
        };
      }),
    );
  };

  const confirmSchedule = async (data) => {
    const rs = await detailObj?.changeScheduleFn(data);
    if (rs?.status === 200) {
      alert('스케줄 변경에 성공하였습니다');
      setDetailObj((prev) => {
        return {
          ...prev,
          visible: false,
          ele: null,
          data: null,
        };
      });
      setScheduleChangeModalVisible(false);
      detailObj?.coloringFn(detailObj?.searchObj);
    }
  };

  return (
    <>
      {scheduleChangeModalVisible && (
        <CommonModalNew
          style={{ width: 1000 }}
          title={'스케줄 변경'}
          rightTitle={
            <div>
              <Button
                className="btn btn-orange ssm"
                onClick={() => {
                  if (window.confirm('스케줄을 변경하시겠습니까?')) {
                    confirmSchedule(scheduleChangeObj?.searchBar);
                  }
                }}
              >
                변경
              </Button>
            </div>
          }
          visible={scheduleChangeModalVisible}
          setVisible={setScheduleChangeModalVisible(null)}
          children={
            <div>
              <Row>
                <Col>
                  <label className="col-form-label">창고</label>
                  <SelectD
                    options={MASTER_OPS?.CENTER_AUTH?.filter((ele) => ele.value !== null)}
                    value={
                      scheduleChangeObj?.searchBar?.centerCode
                        ? {
                            value: scheduleChangeObj?.searchBar?.centerCode,
                            label: MASTER_OBJ?.CENTER_WHOLE?.[scheduleChangeObj?.searchBar?.centerCode],
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setScheduleChangeObj((prev) => {
                        return {
                          ...prev,
                          searchBar: {
                            ...prev.searchBar,
                            centerCode: value,
                          },
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">기사명</label>
                  <SelectD
                    options={filteredDrivers?.filter((ele) => ele.value !== null)}
                    value={
                      scheduleChangeObj?.searchBar?.userId
                        ? {
                            value: scheduleChangeObj?.searchBar?.userId,
                            label: MASTER_OBJ?.DRIVER_WHOLE?.[scheduleChangeObj?.searchBar?.userId],
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setScheduleChangeObj((prev) => {
                        return {
                          ...prev,
                          searchBar: {
                            ...prev.searchBar,
                            userId: value,
                          },
                        };
                      });
                    }}
                  />
                </Col>
                <Col style={{ position: 'relative' }}>
                  <span style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: 12 }}>
                    <input
                      id="deliveryFlag"
                      style={{ marginRight: 5 }}
                      type="checkbox"
                      checked={scheduleChangeObj?.searchBar?.deliveryDateFlag}
                      onChange={() => {
                        setScheduleChangeObj((prev) => {
                          return {
                            ...prev,
                            searchBar: {
                              ...prev.searchBar,
                              deliveryDateFlag: !prev.searchBar?.deliveryDateFlag,
                            },
                          };
                        });
                      }}
                    />
                    <label htmlFor="deliveryFlag">변경 여부</label>
                  </span>
                  <label className="col-form-label">방문예정날짜</label>
                  <FlatpickrD
                    disabled={!scheduleChangeObj?.searchBar?.deliveryDateFlag}
                    value={scheduleChangeObj?.searchBar?.deliveryDate}
                    onReset={() => {
                      setScheduleChangeObj((prev) => {
                        return {
                          ...prev,
                          searchBar: {
                            ...prev.searchBar,
                            deliveryDate: null,
                            deliveryDateFlag: true,
                          },
                        };
                      });
                    }}
                    onChange={(date) => {
                      setScheduleChangeObj((prev) => {
                        return {
                          ...prev,
                          searchBar: {
                            ...prev.searchBar,
                            deliveryDate: returnDateyyyymmdd(date[0]),
                            deliveryDateFlag: returnDateyyyymmdd(date[0]) !== detailObj?.ele?.deliveryDate,
                          },
                        };
                      });
                    }}
                    options={{
                      mode: 'single',
                    }}
                  />
                </Col>
                <div style={{ display: 'flex', padding: 10, justifyContent: 'flex-end' }}>
                  <input
                    id="confirm"
                    style={{ marginRight: 5 }}
                    type="checkbox"
                    checked={scheduleChangeObj?.searchBar?.confirmFlag}
                    onChange={() => {
                      setScheduleChangeObj((prev) => {
                        return {
                          ...prev,
                          searchBar: {
                            ...prev.searchBar,
                            confirmFlag: !prev.searchBar?.confirmFlag,
                          },
                        };
                      });
                    }}
                  />
                  <label htmlFor="confirm">스케줄 확정</label>
                </div>
              </Row>
            </div>
          }
        />
      )}
      <CommonModalNew
        style={{ width: 1100 }}
        title="상세보기"
        visible={detailObj?.visible}
        setVisible={() => {
          setDetailObj((prev) => {
            return {
              ...prev,
              ele: null,
              visible: false,
              data: null,
            };
          });
        }}
        children={
          <>
            <div className="button-area" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ color: 'red', fontSize: 15 }}>{detailObj?.ele?.promiseFlag === false ? '미확정' : ''}</div>
              <div>
                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!detailObj?.ele?.confirmFlag) {
                      alert('확정된 스케줄에 대해서만 문자를 보낼수 있습니다');
                    } else {
                      const result = window.confirm('현 스케줄에 대해 문자메시지를 보내겠습니까?');
                      if (result) {
                        detailObj?.messageFn({
                          data: {
                            invoiceSeqs: [detailObj?.data?.customerOrderInvoiceSeq],
                          },
                          ele: detailObj?.ele,
                        });
                      }
                    }
                  }}
                >
                  알림메세지 발송
                </button>
                <button
                  className="btn btn-secondary sm"
                  onClick={() => {
                    setScheduleChangeModalVisible(true);
                  }}
                >
                  스케줄 변경
                </button>
              </div>
            </div>
            <Table>
              <thead>
                <tr className="table-primary">
                  <th>소속창고</th>
                  <th>스케줄 확정일</th>
                  <th>약속시간</th>
                  <th>기사명</th>
                  <th>설치기사 연락처</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'center' }}>{MASTER_OBJ?.CENTER_WHOLE?.[detailObj?.data?.centerCode]}</td>
                  <td style={{ textAlign: 'center' }}>{detailObj?.data?.deliveryDate}</td>
                  <td style={{ textAlign: 'center' }}>{detailObj?.data?.deliveryTime}</td>
                  <td style={{ textAlign: 'center' }}>{MASTER_OBJ?.DRIVER_WHOLE?.[detailObj?.ele?.userId]}</td>
                  <td style={{ textAlign: 'center' }}>{MASTER_OBJ?.DRIVER_PHONE_WHOLE?.[detailObj?.ele?.userId]}</td>
                </tr>
              </tbody>
            </Table>
            <div className="detail-area">
              <div>
                <span className="title">접수 정보</span>
                <Table bordered>
                  <tbody>
                    <tr>
                      <th style={{ width: 100 }} className="tbodyTh">
                        주문번호
                      </th>
                      <td style={{ minWidth: 100 }}>{detailObj?.data?.customerOrderSeq}</td>
                      <th style={{ width: 100 }} className="tbodyTh">
                        외부주문번호
                      </th>
                      <td>{detailObj?.data?.externalOrderNumber}</td>
                    </tr>

                    <tr>
                      <th className="tbodyTh">송장번호</th>
                      <td>{detailObj?.data?.customerOrderInvoiceSeq}</td>
                      <th className="tbodyTh">외부송장번호</th>
                      <td
                        className={detailObj?.data?.feeType === 'PARCEL' ? 'clickable' : ''}
                        onClick={() => {
                          if (detailObj?.data?.feeType === 'PARCEL' && detailObj?.data?.externalInvoiceNumber) {
                            if (window.confirm('외부송장번호가 복사되었습니다. 붙여넣기하고 배송현황을 조회하세요')) {
                              copyAlert(detailObj?.data?.externalInvoiceNumber);
                              window.open(`https://www.cjlogistics.com/ko/tool/parcel/tracking`);
                            }
                          }
                        }}
                      >
                        {detailObj?.data?.externalInvoiceNumber}
                      </td>
                    </tr>
                    <tr>
                      <th className="tbodyTh" scope="row">
                        주문유형
                      </th>
                      <td>{MASTER_OBJ?.FEE_TYPE?.[detailObj?.data?.feeType]}</td>
                    </tr>

                    <tr>
                      <th className="tbodyTh">송장유형</th>
                      <td>{detailObj?.data?.feeType ? `${MASTER_OBJ?.FEE_TYPE?.[detailObj?.data?.feeType]}` : ``} </td>
                      <th className="tbodyTh">송장상태</th>
                      <td>
                        <span style={{ color: detailObj?.data?.invoiceStatus === 'NOT_IN_INVENTORY' || detailObj?.data?.invoiceStatus === 'NOT_EXIST_DRIVER' ? 'red' : 'black' }}>
                          {MASTER_OBJ?.INVOICE_STATUS?.[detailObj?.data?.invoiceStatus]}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th className="tbodyTh" scope="row">
                        주문일
                      </th>
                      <td>{detailObj?.data?.orderDate}</td>
                      <th className="tbodyTh" scope="row">
                        희망일
                      </th>
                      <td>{detailObj?.data?.promiseDeliveryDate}</td>
                    </tr>
                    <tr>
                      <th className="tbodyTh" scope="row">
                        인프라
                      </th>
                      <td>{MASTER_OBJ?.INFRA?.[detailObj?.data?.infraSeq]}</td>
                      <th className="tbodyTh" scope="row">
                        판매사
                      </th>
                      <td>{MASTER_OBJ?.SELLER_WHOLE?.[detailObj?.data?.partnerSeq]}</td>
                    </tr>

                    <tr>
                      <th className="tbodyTh" scope="row">
                        상품명
                      </th>
                      <td colSpan={3}>{detailObj?.data?.productName}</td>
                    </tr>
                    <tr>
                      <th className="tbodyTh" scope="row">
                        제품
                      </th>
                      <td colSpan={3}>
                        {detailObj?.data?.productModels?.map((ele, idx) => {
                          return <div key={`productmodel:${idx}`}>{ele.model}</div>;
                        })}
                      </td>
                    </tr>
                    <tr>
                      <th className="tbodyTh">고객 요청사항</th>
                      <td colSpan={3}>{detailObj?.data?.shipmentNote}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div>
                <span className="title">상담 정보</span>
                <Table bordered>
                  <tbody>
                    <tr>
                      <th className="tbodyTh" scope="row" style={{ width: 120 }}>
                        수령 고객
                      </th>
                      <td>{detailObj?.data?.receiverName}</td>
                    </tr>
                    <tr>
                      <th className="tbodyTh" scope="row">
                        수령인 주소
                      </th>
                      <td>
                        {detailObj?.data?.receiverAddr1} {detailObj?.data?.receiverAddr2 ? detailObj?.data?.receiverAddr2 : ''}
                      </td>
                    </tr>
                    <tr>
                      <th className="tbodyTh" scope="row">
                        수령인 연락처
                      </th>
                      <td>
                        {detailObj?.data?.receiverPhone} {detailObj?.data?.receiverPhone2 && ' / ' + detailObj?.data?.receiverPhone2}
                      </td>
                    </tr>
                    <tr>
                      <th className="tbodyTh" scope="row">
                        콜센터 요청사항
                      </th>
                      <td style={{ display: 'flex' }}>
                        <Input
                          value={note || ''}
                          onChange={(e) => {
                            setNote(e.target.value);
                          }}
                          style={{ width: 'calc(100% - 70px)' }}
                        />
                        <button
                          disabled={!note}
                          className="btn btn-etc ssm"
                          onClick={() => {
                            const result = window.confirm('콜센터 요청사항을 저장하겠습니까?');
                            if (result) {
                              detailObj?.changeNoteFn({
                                customerOrderSeq: detailObj?.data?.customerOrderSeq,
                                customerOrderInvoiceSeq: detailObj?.data?.customerOrderInvoiceSeq,
                                callCenterNote: note,
                              });
                            }
                          }}
                        >
                          수정
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th className="tbodyTh" scope="row">
                        기사 요청사항
                      </th>
                      <td>{detailObj?.data?.driverMemo}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
