import { useEffect, useRef, useState } from 'react';
// image
import roatetImg from './img/rotate.png';
import rightImg from './img/right.png';
import leftImg from './img/left.png';

import { ModalHeader, ModalBody, ModalFooter, Modal, Button } from 'reactstrap';
export const ImageModal = (props) => {
  const { title = '', idx, photoArray, deleteFn, mainSeq } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rotateNo, setRotateNo] = useState(0);
  const [idxNo, setIdxNo] = useState(0);

  useEffect(() => {
    if (idx) setIdxNo(idx);
  }, [idx]);

  const handleIdx = (isUp) => {
    if (isUp) {
      if (idxNo + 1 !== photoArray?.length) {
        let nextIdxNo;
        photoArray.forEach((ele, i) => {
          if (i > idxNo && ele && !nextIdxNo) {
            nextIdxNo = i;
          }
        });
        if (nextIdxNo) setIdxNo(nextIdxNo);
      }
    } else {
      if (idxNo !== 0) {
        let nextIdxNo;
        for (let i = 1; i <= idxNo; i++) {
          if (!nextIdxNo && photoArray[idxNo - i]) nextIdxNo = i;
        }
        if (nextIdxNo) setIdxNo(idxNo - nextIdxNo);
      }
    }
  };
  const wrapperRef = useRef<HTMLImageElement>();

  return (
    <div style={{ position: 'absolute', left: 50, top: 6, zIndex: 3 }}>
      <img alt="" src={photoArray[idx]?.uploadUrl} style={{ width: 45, height: 45, cursor: 'pointer' }} onClick={toggle} />
      {modal && (
        <Modal isOpen={modal} toggle={toggle} className="top_bar_profile_img">
          <ModalHeader toggle={toggle}>{typeof title === 'function' ? title(idxNo) : title + (idxNo + 1)}</ModalHeader>
          <ModalBody style={{ height: 800, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={leftImg} style={{ width: 30, height: 30, position: 'absolute', left: 10, top: 300, cursor: 'pointer', zIndex: 10 }} onClick={() => handleIdx(false)} />
            <div>
              <img ref={wrapperRef} alt="" style={{ maxWidth: 600, transform: `rotate(${90 * rotateNo}deg)` }} src={photoArray[idxNo]?.uploadUrl} />
            </div>
            <img src={rightImg} style={{ width: 30, height: 30, position: 'absolute', right: 10, top: 300, cursor: 'pointer', zIndex: 10 }} onClick={() => handleIdx(true)} />
          </ModalBody>
          <ModalFooter>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: 20, cursor: 'pointer' }}>
              <span
                onClick={() => {
                  setRotateNo((prev) => {
                    return (prev + 1) % 4;
                  });
                }}
              >
                <img src={roatetImg} style={{ width: 20, height: 20 }} />
                &nbsp;회전하기
              </span>
              <span>
                <Button
                  onClick={() => {
                    window.open(wrapperRef?.current?.src);
                  }}
                  className="btn btn-orange ssm"
                >
                  다운로드
                </Button>
                <Button onClick={deleteFn} id={`${idx}_${mainSeq}`} className="btn btn-cancel ssm">
                  삭제
                </Button>
              </span>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};
