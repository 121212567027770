import { useEffect, useMemo, useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { addMASTER, setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { MenuDTO, MenuSearchDTO } from 'interface/user';
import { ISortObj, SortModal } from './component/sortModal';
import { DetailModal, IDetailObj } from './component/detailPopup';
import { PagingListDTO } from 'interface/util';
import { serviceStore } from 'services/services';

const Index = () => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const [detailObj, setDetailObj] = useState<IDetailObj>();
  const [sortObj, setSortObj] = useState<ISortObj>();
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);

  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: (e) => {
          setDetailObj({
            searchObj: JSON.parse(e.item?.searchObj),
            visible: true,
            item: e.item,
            CRUDFn: CRUDFunction,
          });
        },
      },
    },
    {
      headerText: '상위메뉴',
      children: [
        {
          headerText: 'ID',
          dataField: 'parentMenuId',
        },
        {
          headerText: '메뉴명',
          dataField: 'parentMenuName',
        },
      ],
    },
    {
      headerText: '메뉴ID',
      dataField: 'menuId',
      style: 'main-total-color',
    },
    {
      headerText: '메뉴이름',
      dataField: 'menuName',
      style: 'sub-total-color',
    },
    {
      headerText: '순서',
      dataField: 'sort',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: (e) => {
          const rows = gridRef.current.getGridData();
          const menus = rows.filter((ele) => ele.parentMenuId === e.item.parentMenuId);
          menus.sort((a, b) => a.sort - b.sort);
          setSortObj({
            e,
            visible: true,
            item: e.item,
            items: menus,
            fetchFn: (data) => wrappedFetchList(data),
          });
        },
      },
      width: 50,
    },
    {
      headerText: '연결화면',
      children: [
        {
          headerText: '연결주소',
          dataField: 'url',
        },
        {
          headerText: '화면ID',
          dataField: 'screenId',
        },
        {
          headerText: '화면명',
          dataField: 'screenName',
        },
      ],
    },
    {
      headerText: '등록 아이디',
      dataField: 'registerId',
    },
    {
      headerText: '등록일',
      dataField: 'registerDatetime',
    },
    {
      headerText: '사용 여부',
      dataField: 'useFlag',
      visible: false,
    },
    {
      headerText: '사용 여부',
      dataField: 'useFlagKr',
    },
  ];

  const labellingKr = (data: PagingListDTO<MenuDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        useFlagKr: row.useFlag === true ? '사용' : '미사용',
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: MenuSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.userAction(`menu`, 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const CRUDFunction = async ({ data, crud, searchObj }) => {
    dispatch(setLoading('POST'));
    let rs;
    if (crud === 'D') {
      rs = await serviceStore.userAction('menu', 'DELETE', null, { data });
    } else if (crud === 'C') {
      rs = await serviceStore.userAction('menu', 'POST', null, data);
      //
    } else if (crud === 'U') {
      rs = await serviceStore.userAction('menu', 'PUT', null, data);
    }

    if (rs?.status === 200) {
      alert('처리 되었습니다!');
      setDetailObj(null);
      wrappedFetchList(searchObj);
    }

    dispatch(setLoading(null));
  };

  useEffect(() => {
    addMasterOptions();
  }, []);

  const addMasterOptions = async () => {
    const addOps = {};
    if (!MASTER_OBJ?.SCREENID_URL) {
      addOps['SCREENID_URL'] = MASTER_OPS?.SCREEN_WHOLE?.map((ele) => {
        return {
          value: ele.screenId,
          label: ele.url,
        };
      });
    } else if (!MASTER_OBJ?.MENU_NAME) {
      addOps['MENU_NAME'] = MASTER_OPS?.MENU_WHOLE?.map((ele) => {
        return {
          value: ele.menuId,
          label: ele.menuName,
        };
      });
    }
    if (Object?.keys(addOps)?.length > 0) dispatch(addMASTER(addOps));
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      ...defaultSearchFilter,
    },
    gridRef,
    fetchPaging,
  });

  const handleCheckItems = (e) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      const id = e.target.id;
      if (id === 'delete-function') CRUDFunction({ data: items?.map((ele) => ele.menuId), crud: 'D', searchObj: JSON.parse(items[0]?.searchObj) });
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const excelDownload = () => {
    gridRef.current.exportAsXlsx({ fileName: '메뉴' });
  };

  return (
    <div className="page-content">
      {detailObj?.visible && <DetailModal detailObj={detailObj} setDetailObj={setDetailObj} />}
      {sortObj?.visible && <SortModal sortObj={sortObj} setSortObj={setSortObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area space-between">
          <div>
            <div className="red" id="delete-function" onClick={handleCheckItems}>
              삭제
            </div>
            <div
              className="blue"
              onClick={() => {
                setDetailObj({
                  searchObj,
                  visible: true,
                  item: null,
                  CRUDFn: (data) => CRUDFunction(data),
                });
              }}
            >
              생성
            </div>
          </div>
          <div>
            <div
              className="green"
              onClick={() => {
                excelDownload();
              }}
            >
              엑셀다운
            </div>
          </div>
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
      </div>
    </div>
  );
};

export default Index;
