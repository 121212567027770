import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import * as IGrid from 'aui-grid';
import { Col, Row } from 'reactstrap';
import CommonModalNew from 'components/modal/commonModalNew';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import AUIGrid from 'modules/Grid';
import GridBox, { defaultGridProps } from 'common/grid/gridBox';
import { InputD } from 'components/reactstrap/reactstrap';
import { setLoading } from 'redux/services/menuSlice';
import { UserGroupDTO, UserGroupSearchDTO } from 'interface/user';
import { serviceStore } from 'services/services';

export interface IUserInGroup {
  visible?: boolean;
  thisGroup?: UserGroupDTO;
  groupName?: string;
  searchObj?: UserGroupSearchDTO;
  e?: any;
  fetchFn?: (searchObj: UserGroupSearchDTO) => {};
  detailFetchFn?: (e) => {};
}

export const AddUser = ({ userInGroupObj, setUserInGroupObj }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [searchObj, setSearchObj] = useState({
    userId: null,
    userName: null,
    phoneNumber: null,
  });

  const columns: IGrid.Column[] = [
    {
      headerText: '아이디',
      dataField: 'userId',
    },
    {
      headerText: '이름',
      dataField: 'userName',
    },
    {
      headerText: '이메일',
      dataField: 'email',
    },
    {
      headerText: '연락처',
      dataField: 'phoneNumber',
    },
    {
      headerText: '소속창고',
      dataField: 'centerCodeKr',
    },
  ];
  const handleCheckbox = (e) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      addUser(items);
    } else {
      alert('선택된 건이 없습니다!');
    }
  };

  const addUser = async (items) => {
    dispatch(setLoading('POST'));
    const rs = await serviceStore.userAction(
      `authority/userGroupUser/${userInGroupObj?.thisGroup?.userGroupId}`,
      'POST',
      null,
      items?.map((ele) => {
        return { userId: ele.userId };
      }),
    );
    if (rs?.status === 200) {
      userInGroupObj?.detailFetchFn(userInGroupObj?.e);
    }
    dispatch(setLoading(null));
  };

  const fetchUsers = async (searchObj) => {
    const rs = await serviceStore.userAction(`list`, 'GET', { ...searchObj, userFlag: true });
    if (rs?.status === 200) {
      const data = rs.data;
      data.forEach((row) => {
        row.centerCodeKr = MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode];
        row.userGroupId = userInGroupObj?.userGroupId;
      });
      gridRef.current.setGridData(data);
    }
  };

  return (
    <CommonModalNew
      visible={userInGroupObj?.visible}
      style={{ width: 700 }}
      setVisible={() => setUserInGroupObj(null)}
      title={`사용자 추가`}
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">아이디</label>
              <InputD
                value={searchObj?.userId || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    userId: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">이름</label>
              <InputD
                value={searchObj?.userName || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    userName: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">연락처</label>
              <InputD
                value={searchObj?.phoneNumber || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    phoneNumber: e.target.value,
                  });
                }}
              />
            </Col>
            <Col style={{ textAlign: 'left', paddingTop: 12 }}>
              <button
                className="btn btn-search"
                onClick={(e) => {
                  e.preventDefault();
                  fetchUsers(searchObj);
                }}
              >
                조회
              </button>
            </Col>
          </Row>
          <div className="grid-button-area only-right">
            <div className="orange" onClick={handleCheckbox}>
              추가
            </div>
          </div>
          <GridBox
            gridRef={gridRef}
            columns={columns}
            gridProps={{
              ...defaultGridProps,
              height: 500,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              //
            }}
          />
        </>
      }
    />
  );
};
