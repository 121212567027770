import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tab1 from './customAlertMsg';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';

export const CUSTOMALERTMSG_MASTER = ['SELLER_SELLER_AUTH', 'SELLER_WHOLE', 'MESSAGE_TYPE', 'FEE_TYPE'];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(CUSTOMALERTMSG_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            CUSTOMALERTMSG: <Tab1 tabId={'CUSTOMALERTMSG'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
