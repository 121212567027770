import { createPortal } from 'react-dom';
import { BackdropStyle, PortalProps, BaseStyle } from './Dropdown';
import styled from 'styled-components';
import { COLORS, TRANSITIONS, VALUES, WIDTHS } from 'common/constants/appearance';
import { useDispatch } from 'react-redux';
import { setShowModal } from 'redux/services/portalSlice';
import { useRef } from 'react';
import React from 'react';

export type ModalProps = PortalProps & {
  $size: 'MD' | 'LG' | 'XL' | 'XXL' | number;
  $strictClose?: boolean;
};

function LDSModal({ children, $size, $strictClose = false, ...props }: ModalProps) {
  const ModalPortal = ({ children }) => {
    return createPortal(children, document.getElementById('modal-root') as HTMLElement);
  };

  const modalRef = useRef(null);
  const dispatch = useDispatch();

  return (
    <ModalPortal>
      <ModalPortalWrapper $size={$size}>
        <ModalBackdropStyle onClick={() => $strictClose || dispatch(setShowModal(''))} />
        <ModalContainer $size={$size} ref={modalRef} className="dd">
          {children}
        </ModalContainer>
      </ModalPortalWrapper>
    </ModalPortal>
  );
}
export default React.memo(LDSModal);

const ModalPortalWrapper = styled.div<ModalProps>`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;

  @media screen and (max-width: ${(props) => props.$size}px) {
    align-items: flex-end;
    padding-bottom: 24px;
  }
`;
const ModalBackdropStyle = styled(BackdropStyle)`
  background-color: ${COLORS.BLACK_020};
  z-index: ${VALUES.MODAL_Z_INDEX};

  opacity: 0;
  animation: show 500ms cubic-bezier(0.44, 0.13, 0.37, 1.07) forwards;

  & + div {
    opacity: 0;
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ModalContainer = styled(BaseStyle)<ModalProps>`
  z-index: ${VALUES.MODAL_Z_INDEX};
  position: relative;
  display: flex;

  border: none;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 2px 5px -1px ${COLORS.BLACK_020};

  max-width: ${(props) => (typeof props.$size !== 'number' ? WIDTHS[`LAYOUT_${props.$size}_WIDTH`] : props.$size)}px;

  width: 100%;

  animation: slideDown 480ms 240ms ${TRANSITIONS.BOUNCY} forwards !important;

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(42px);
    }
    100% {
      opacity: 1;
      transform: translateY(24px);
    }
  }

  @media screen and (max-width: ${(props) => props.$size}px) {
    border-radius: 8px 8px 0 0;
    max-height: 100%;
  }
`;
