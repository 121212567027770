import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab from './commonTab';

// options
import { RootState } from 'redux/store';
import { nullingMASTER } from 'redux/services/menuSlice';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { TopTabController } from 'components/template/TopTabController';
import { MANAGERLD_MASTER } from '../jojikLDTab';

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    await initMasterOptions(MANAGERLD_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            MANAGE_USERGROUP_USERS: <Tab tabId={'MANAGE_USERGROUP_USERS'} />,
            MANAGE_USERGROUP_SCREEN: <Tab tabId={'MANAGE_USERGROUP_SCREEN'} />,
            MANAGE_USERGROUP_PARTNERS: <Tab tabId={'MANAGE_USERGROUP_PARTNERS'} />,
            MANAGE_USERGROUP_INFRA: <Tab tabId={'MANAGE_USERGROUP_INFRA'} />,
            MANAGE_USERGROUP_CENTERS: <Tab tabId={'MANAGE_USERGROUP_CENTERS'} />,
            MANAGE_USERGROUP_DIFF: <Tab tabId={'MANAGE_USERGROUP_DIFF'} />,
            MANAGE_USERGROUP_SALESCHANNEL: <Tab tabId={'MANAGE_USERGROUP_SALESCHANNEL'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
