import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { httpClient } from 'common/http-client/axiosConfig';
import { returnDateyymmddKr } from 'common/util/dateParsingFn';
import { InvoiceDetailDTO, InvoiceInstallCertificateDTO } from '../../../_interface/order';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IinstallModalObj {
  visible: boolean;
  item: InvoiceDetailDTO;
}

interface InstallationModalProps {
  installModalObj: IinstallModalObj;
  setInstallModalObj: React.Dispatch<React.SetStateAction<IinstallModalObj>>;
}
interface InvoiceInstallCertificateDTOEx extends InvoiceInstallCertificateDTO {
  partnerSeqKr: string;
  modelGroupMediumKr: string;
  memberCount: number;
  files: {
    fileName?: string;
    tableColumn?: string;
    tableName?: string;
    tableSeq?: number;
    uploadUrl?: string;
  }[];
}
export const InstallationModal: React.FC<InstallationModalProps> = ({ installModalObj, setInstallModalObj }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const [data, setData] = useState<InvoiceInstallCertificateDTOEx>();

  useEffect(() => {
    if (installModalObj?.item?.invoiceSeq) {
      fetchData(installModalObj?.item?.invoiceSeq);
    }
  }, [installModalObj?.visible]);

  const fetchData = async (invoiceSeq) => {
    dispatch(setLoading('GET'));

    const rs = await httpClient.get(`/order/v2/invoice/install/certificate?invoiceSeq=${invoiceSeq}`);
    if (rs?.status === 200) {
      const data = rs?.data;
      const partnerSeqKr = selectlabel(data?.partnerSeq, masterOptions.SELLER_WHOLE);
      const modelGroupMediumKr = selectlabel(data?.modelGroupMedium, masterOptions.MODEL_GRP_2);
      setData({
        ...rs.data,
        partnerSeqKr,
        modelGroupMediumKr,
      });
    }
    console.log('rs?.data>>>', rs?.data);
    dispatch(setLoading(null));
  };

  const signFile = data?.files.find((file) => file.tableColumn === 'SIGN');

  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <CommonModalNew
      style={{ width: 1000 }}
      visible={installModalObj.visible}
      setVisible={() => {
        setInstallModalObj(null);
      }}
      title={
        <>
          <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
        </>
      }
      children={
        <>
          <div ref={wrapperRef} style={{ padding: 30 }}>
            <div className="printTitle">{data?.partnerSeqKr} 설치확인서</div>
            <div className="printTitle" style={{ textAlign: 'left' }}>
              고객정보
            </div>
            <table className="border-table border-font" style={{ width: '100%', textAlign: 'center' }}>
              <tbody>
                <tr>
                  <th>고객명</th>
                  <td>{data?.customerName}</td>
                  <th>수취자</th>
                  <td>{data?.receiverName}</td>
                </tr>
                <tr>
                  <th rowSpan={3}>주소</th>
                  <th>우편번호</th>
                  <th>연락처1</th>
                  <td>{data?.receiverPhone}</td>
                </tr>
                <tr>
                  <td>{data?.receiverZipcode}</td>
                  <th>연락처2</th>
                  <td>{data?.receiverPhone2}</td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    {data?.receiverAddr1} {data?.receiverAddr2}
                  </td>
                </tr>
                <tr>
                  <th>고객요청사항</th>
                  <td colSpan={3}>{data?.shipmentNote}</td>
                </tr>
              </tbody>
            </table>

            <div className="printTitle" style={{ textAlign: 'left' }}>
              기사정보
            </div>
            <table className="border-table border-font" style={{ width: '100%', textAlign: 'center' }}>
              <tbody>
                <tr>
                  <th>설치자</th>
                  <th>설치일자</th>
                  <th>연락처</th>
                  <th>비고</th>
                </tr>
                <tr>
                  <td>{data?.driverName}</td>
                  <td>{data?.finishDatetime}</td>
                  <td>{data?.driverPhone}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            {!(installModalObj?.item?.infraSeq === 22 && installModalObj?.item?.feeType === 'DELIV') && (
              <>
                <div className="printTitle" style={{ textAlign: 'left' }}>
                  배송/설치 서비스 검진표
                </div>
                <table className="border-table border-font" style={{ width: '100%', textAlign: 'center' }}>
                  <tbody>
                    <tr>
                      <th colSpan={9}>고객확인사항</th>
                      <th colSpan={1}>
                        확인
                        <br />
                        (네/아니오)
                      </th>
                    </tr>
                    {data?.partnerSeqKr === '바디프랜드' ? (
                      data?.modelGroupMediumKr === '기타(라클라우드)' ? (
                        <>
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>1. 제품 외관 이상이 없는지 확인이 되었나요?</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey1 ? '네' : '아니오'}
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>2. 제품이 고객이 원하는 장소에 설치가 되었나요?</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey2 ? '네' : '아니오'}
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>3. La Cloud 제품의 기능과 특장점 설명을 들었나요?</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey3 ? '네' : '아니오'}
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>4. 제품의 기능 정상 작동을 확인했나요? (모션베드)</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey4 ? '네' : '아니오'}
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>5. La Cloud 제품에 관한 설치 확인 및 점검을 완료했나요?</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey5 ? '네' : '아니오'}
                            </th>
                          </tr>
                        </>
                      ) : data?.modelGroupMediumKr === '안마의자' ? (
                        <>
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>1. 안마의자 설치 후 제품의 정상작동 및 외관의 이상 유무를 확인하셨습니까?</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey1 ? '네' : '아니오'}
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>2. 설치자로부터 안마의자 및 리모컨 사용법에 대한 설명을 충분히 들었나요?</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey2 ? '네' : '아니오'}
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>3. 사용시 중요사항을 확인하셨나요?</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey3 ? '네' : '아니오'}
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>4. 설치 후 주변 정리 정돈 및 앱 설치에 대해 설명을 충분히 들었나요?</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey4 ? '네' : '아니오'}
                            </th>
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>1. 제품 사용법 설명을 하셨습니까?</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey1 ? '네' : '아니오'}
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>2. 제품 고장 시 서비스 접수 센터 안내를 하셨습니까?</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey2 ? '네' : '아니오'}
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>3. 고객님이 배송/설치 서비스에 만족하셨습니까?</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey3 ? '네' : '아니오'}
                            </th>
                          </tr>
                        </>
                      )
                    ) : (data?.partnerSeqKr === '㈜암웨이' && data?.modelGroupMediumKr === '싸이클') || data?.modelGroupMediumKr === '싸이클부품' ? (
                      <>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>
                              · 제품의 외관은 정상이며, 정상 설치 및 정상 작동함을 확인합니다.
                              <br />
                              · AS 보증기간은 설치일로부터 12개월까지임을 확인합니다. (단, 가정 외 피트니스 및 공공장소 설치 시 AS보증기간 6개월)
                              <br />
                              · 제품 AS 보증기간내 사용자의 고의 또는 과실로 인한 파손, 스크래치 등의 외상은 무상 서비스 품목에서 제외되며 정상 설치 후 단순 변심에 의한 반품은 불가함을 확인합니다.
                              <br />
                              · 바이크에 내장된 V자형 벨트의 품질보증은 1년 또는 라이딩 앱 멀티 프로필 합산 주행 거리 6,000km 중 선도래하는 시점까지 적용됩니다. 제품에 포함된 사용설명서와 본 확인서의
                              내용이 상이한 경우, 본 확인서의 내용이 우선합니다.
                              <br />· 안전 및 유의사항 (세이프 가드에 대한 설명 등)에 대해 설명을 잘 들었고, 내용을 충분히 이해하였습니다.
                            </div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey1 ? '네' : '아니오'}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>
                              한국암웨이(주)는 25센트 라이드 바이크 A/S를 위하여 다음과 같이 귀하의 개인정보를 수집, 이용합니다.
                              <br />
                              · 수집하는 개인정보의 항복: 성명, 연락처, 주소, 시리얼 번호, 서비스 관련 사진
                              <br />
                              · 개인정보의 수집 및 이용 목적: 25센트 라이드 바이크 서비스 및 이력 관리
                              <br />
                              · 개인정보의 보유 및 이용기간: 5년
                              <br />
                              본인은 위 개인정보 수집 ·이용에 동의합니다.
                            </div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey2 ? '네' : '아니오'}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>
                              당사는 25센트 라이드 바이크 서비스 진행을 위하여 다음과 같이 귀하의 개인정보 처리를 위탁합니다.
                              <br />
                              · 수탁자: (주)한국고용정보, (주)CJ 대한통운, (주)지오아이티
                              <br />
                              · 위탁하는 업무의 내용:
                              <br />
                              1. (주)한국고용정보: 서비스 이용 관련 고객 상담 (콜센터 운영)
                              <br />
                              2. (주)CJ 대한통운: 25센트 라이드 바이크 배송·설치, 서비스 이력관리"
                              <br />
                              3. (주)지오아이티: 바이크 관련 A/S 업무, 고객 상담 아웃바운드 콜<br />
                              본인은 위 개인정보 수집 ·이용에 동의합니다.
                              <br />
                            </div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey3 ? '네' : '아니오'}
                          </th>
                        </tr>
                      </>
                    ) : data?.partnerSeqKr === '(주)리쏘' || data?.partnerSeqKr === '㈜리쏘' ? (
                      <>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>1. 안마의자 설치 후 제품의 정상 작동 및 외관의 이상 유무를 확인하셨습니까?</div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey1 ? '네' : '아니오'}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>2. 제품이 고객이 원하는 장소에 설치가 되었나요?</div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey2 ? '네' : '아니오'}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>3. 설치자로부터 안마의자 사용방법에 대한 설명을 충분히 들었나요?</div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey3 ? '네' : '아니오'}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>4. 안마의자 렌탈 실 사용자와 신청자가 다를 경우 렌탈 신청자로부터 제품설치 내용에 대해 사전에 확인하셨습니까?</div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey4 ? '네' : '아니오'}
                          </th>
                        </tr>
                      </>
                    ) : ['NPD', '매직볼트', '무니토', '무랩', '바이헤이데이', '오블리크테이블', '잭슨카멜레온', '카레클린트', '텍스쳐'].includes(data?.partnerSeqKr) ? (
                      <>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>1. 상품의 내/외부에 이상이 없음을 확인하셨나요?</div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey1 ? '네' : '아니오'}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>2. 상품의 균형 및 수평이 맞게 설치되었나요?</div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey2 ? '네' : '아니오'}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>3. 상품 설치 중 벽지/장판 훼손이 없음을 확인하셨나요?</div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey3 ? '네' : '아니오'}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>4. (벽고정 상품 추가 질문) 벽 고정장치에 대한 내용을 확인하셨나요?</div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey4 ? '네' : '아니오'}
                          </th>
                        </tr>
                        {data.memberCount === 2 ? (
                          <tr>
                            <th colSpan={9} style={{ textAlign: 'left' }}>
                              <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>5. 2명 이상의 기사님이 방문하셨나요?</div>
                            </th>
                            <th colSpan={1} style={{ textAlign: 'center' }}>
                              {data?.survey5 ? '네' : '아니오'}
                            </th>
                          </tr>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>1. 제품 사용법 설명을 하셨습니까?</div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey1 ? '네' : '아니오'}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>2. 제품 고장 시 서비스 접수 센터 안내를 하셨습니까?</div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey2 ? '네' : '아니오'}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={9} style={{ textAlign: 'left' }}>
                            <div style={{ fontWeight: 'normal', paddingLeft: 20 }}>3. 고객님이 배송/설치 서비스에 만족하셨습니까?</div>
                          </th>
                          <th colSpan={1} style={{ textAlign: 'center' }}>
                            {data?.survey3 ? '네' : '아니오'}
                          </th>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </>
            )}

            <div style={{ textAlign: 'center', marginTop: 50 }}>위와 같이 제품배송 및 설치를 확인하여 제품에 이상이 없음을 확인합니다.</div>
            <div style={{ textAlign: 'center', margin: 50 }}>{data?.finishDatetime ? returnDateyymmddKr(data?.finishDatetime) : ''}</div>
            <div className="signs_area">
              <div>
                <span>고객명 (수취인) : </span>
                {!signFile && <span className="sign_area"></span>}
                {signFile ? (
                  <span className="sign_area">
                    <img src={signFile.uploadUrl} alt="sign" style={{ maxWidth: '70%', height: 'auto', marginLeft: '60px' }} />
                  </span>
                ) : null}
                {!signFile && <span>(인)</span>}
              </div>
              <div>
                <span>설치자 : </span>
                <span className="sign_area"></span>
                <span style={{ marginLeft: '42px' }}>(인)</span>
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};
