import { v4 as uuidv4 } from 'uuid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, Form, Button } from 'reactstrap';
import Grid from 'components/grid/auiGrid';

// redux
import { useSelector, useDispatch } from 'react-redux';
// import ExcelDownloadGrid from 'components/grid/customGrid_willDestroySoon/excelDownloadGrid';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { BottomSheets } from 'components/template/bottomSheets';

import styles from '../presenter.module.scss';
import { httpClient } from 'common/http-client/axiosConfig';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import CommonModalNew from 'components/modal/commonModalNew';
import { FCPrint } from '../../_print/print';
import { Prints } from '../total/component/prints';

import { resetAllSelections, toggleBooleanOptions } from 'common/util/searchBox';
import PrintGridBox from 'common/grid/printGridBox';
import { useFrontSearchData } from 'hooks/grid/useFrontSearchData';
import { setLoading } from 'redux/services/menuSlice';
import { serviceStore } from 'services/services';
import Divider from 'components/LDS/atoms/Divider';
import GridBoxCommon from 'common/grid/gridBox';
import { useNavigate } from 'react-router-dom';

const Presenter = (props) => {
  const {
    //
    bottomSheetObj,
    setBottomSheetObj,
    haldangObj,
    setHaldangObj,
    locationChangingObj,
    setLocationChangingObj,
    cancelObj,
    setCancelObj,
    // forcingMagamObj,
    // setForcingMagamObj,
    fcPrintObj,
    setFcPrintObj,
  } = props;

  return (
    <>
      <div className="page-content" style={{ overflow: 'auto' }}>
        {fcPrintObj?.visible && <FCPrint fcPrintObj={fcPrintObj} setFcPrintObj={setFcPrintObj} />}
        {cancelObj?.visible && <CancelModal cancelObj={cancelObj} setCancelObj={setCancelObj} />}
        {locationChangingObj?.visible && <LocationChangingModal locationChangingObj={locationChangingObj} setLocationChangingObj={setLocationChangingObj} />}
        {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
        {haldangObj?.visible && <HaldangModal haldangObj={haldangObj} setHaldangObj={setHaldangObj} setCancelObj={setCancelObj} />}
        {/* {forcingMagamObj?.visible && <ForcingMagamModal forcingMagamObj={forcingMagamObj} setForcingMagamObj={setForcingMagamObj} />} */}
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const ForcingMagamModal = ({ forcingMagamObj, setForcingMagamObj }) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const [reasonArr, setReasonArr] = useState();

  useEffect(() => {
    const arr = forcingMagamObj?.items?.map((ele, idx) => {
      return {
        key: `${idx}_0`,
        reason: null,
        quantity: 0,
        modelStockSeq: ele.modelStockSeq,
      };
    });
    setReasonArr(arr);
  }, []);

  return (
    <CommonModalNew
      title={'수량 차이 사유 확인'}
      visible={forcingMagamObj?.visible}
      style={{ width: 420 }}
      setVisible={() => {
        setForcingMagamObj((prev) => {
          return {
            ...prev,
            visible: false,
            items: null,
          };
        });
      }}
      rightTitle={
        <div>
          <Button
            className="btn btn-orange ssm"
            onClick={() => {
              reasonArr.forEach((ele) => {
                delete ele.key;
              });
              forcingMagamObj?.magamFn?.({ loanPickSeq: forcingMagamObj?.items?.[0].loanPickSeq, list: reasonArr, e: JSON.parse(forcingMagamObj?.items?.[0].e) });
            }}
          >
            강제마감
          </Button>
        </div>
      }
      children={
        <div>
          {forcingMagamObj?.items?.map((item, iIdx) => {
            return (
              <div className={styles.itemWrapper} key={`itemRea_${iIdx}`}>
                <div className={styles.modelWrapper}>
                  <div> 제품 : {item?.model || '-'} </div>
                  <div> 제품명 : {item?.modelName || '-'}</div>
                  <div>
                    <span>바코드 : {item?.barcode || '-'}</span>
                    <span>외부SKU :{item?.skuNumber || '-'}</span>
                  </div>
                </div>
                <div className={styles.quantityPanelWrapper}>
                  <div>
                    <div>총 피킹수량</div>
                    <div>{item?.availableQuantity || 0}</div>
                  </div>
                  <div>
                    <div>피킹완료수량</div>
                    <div>{item?.pickAvailableQuantity || 0}</div>
                  </div>
                  <div>
                    <div>미피킹수량</div>
                    <div>{item?.availableDiff || 0}</div>
                  </div>
                </div>
                {reasonArr?.map((ele, idx) => {
                  if (ele?.key?.split('_')[0] === iIdx + '') {
                    return (
                      <div className={styles.magamReasonWrapper} key={`magamReason_${idx}`}>
                        <SelectD
                          options={masterOptions?.LOAN_PICK_CLOSE_REASON}
                          value={
                            ele.reason
                              ? {
                                  value: ele.reason,
                                  label: selectlabel(ele.reason, masterOptions?.LOAN_PICK_CLOSE_REASON),
                                }
                              : null
                          }
                          onChange={({ value }) => {
                            const _reasonArr = JSON.parse(JSON.stringify(reasonArr));
                            _reasonArr.forEach((reason) => {
                              if (reason.key === ele.key) {
                                reason.reason = value;
                              }
                            });
                            setReasonArr(_reasonArr);
                          }}
                        />
                        <InputD
                          type="number"
                          style={{ width: 100, marginLeft: 10, marginRight: 10 }}
                          value={ele.quantity || ''}
                          onChange={(e) => {
                            const _reasonArr = JSON.parse(JSON.stringify(reasonArr));
                            _reasonArr.forEach((reason) => {
                              if (reason.key === ele.key) {
                                reason.quantity = e.target.value;
                              }
                            });
                            setReasonArr(_reasonArr);
                          }}
                        />
                        <button
                          className="btn btn-orange sssm"
                          onClick={(e) => {
                            const _reasonArr = JSON.parse(JSON.stringify(reasonArr));
                            const length = _reasonArr.filter((ele) => ele.key.split('_')[0] === iIdx + '').length;
                            const key = `${iIdx}_${length + 1}`;
                            _reasonArr.push({
                              key,
                              reason: null,
                              quantity: 0,
                              modelStockSeq: ele.modelStockSeq,
                            });
                            setReasonArr(_reasonArr);
                          }}
                        >
                          +
                        </button>
                      </div>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      }
    />
  );
};

const CancelModal = ({ cancelObj, setCancelObj }) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const [inputObj, setInputObj] = useState({
    holdType: null,
    holdNote: null,
  });
  return (
    <CommonModalNew
      //
      style={{ width: 330 }}
      title="피킹취소"
      visible={cancelObj?.visible}
      setVisible={() => {
        setCancelObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
            refetchFn: null,
          };
        });
      }}
      rightTitle={
        <div>
          <Button
            className="btn btn-orange ssm"
            onClick={async () => {
              if (!inputObj?.holdType) {
                alert('피킹취소사유를 선택해주세요');
              } else {
                let dataArr;
                if (!cancelObj?.origin) {
                  dataArr = cancelObj?.data.map((ele) => {
                    return {
                      loanPickSeq: ele.loanPickSeq,
                      centerCode: ele.centerCode,
                      loanPickDate: ele.loanPickDate,
                      holdType: inputObj?.holdType,
                      holdNote: inputObj?.holdNote,
                    };
                  });
                } else {
                  dataArr = [
                    {
                      loanPickSeq: cancelObj?.origin?.loanPickSeq,
                      centerCode: cancelObj?.origin?.centerCode,
                      loanPickDate: cancelObj?.origin?.loanPickDate,
                      userId: cancelObj?.origin?.userId,
                      holdType: inputObj?.holdType,
                      holdNote: inputObj?.holdNote,
                      inventorySeqs: cancelObj?.data?.map((ele) => {
                        return ele.inventorySeq;
                      }),
                    },
                  ];
                }
                await cancelObj?.cancelFn?.(dataArr, cancelObj?.searchObj);
                //NOTE: refetchFn이 있다면 refetch를 수행함
                cancelObj?.refetchFn?.(cancelObj?.origin);
              }
            }}
          >
            취소 신청
          </Button>
        </div>
      }
      children={
        <>
          <div>피킹 작업을 취소하시겠습니까?</div>
          <div style={{ fontSize: 10, color: 'red', marginBottom: 20 }}>※ 취소할 경우 다시 되돌릴 수 없으니 신중히 선택해주세요</div>
          <Row>
            <Col>
              <label className="col-form-label">피킹취소사유</label>
              <SelectD
                value={
                  inputObj?.holdType
                    ? {
                        value: inputObj?.holdType,
                        label: selectlabel(inputObj?.holdType, masterOptions?.ORDER_HOLD_TYPE),
                      }
                    : null
                }
                options={masterOptions?.ORDER_HOLD_TYPE}
                onChange={({ value }) => {
                  setInputObj({
                    ...inputObj,
                    holdType: value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputD
                value={inputObj?.holdNote || ''}
                onChange={(e) => {
                  setInputObj({
                    ...inputObj,
                    holdNote: e.target.value,
                  });
                }}
                placeholder="피킹취소사유 입력"
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};

const LocationChangingModal = ({ locationChangingObj, setLocationChangingObj }) => {
  const [gridId, setGrid] = useState({
    SEARCH: `locationChange_search_${uuidv4()}`,
    SAVE: `locationChange_save_${uuidv4()}`,
  });
  const [rows, setRows] = useState({
    SEARCH: [],
    SAVE: [],
  });
  const [searchObj, setSearchObj] = useState({
    locationCode: null,
  });
  const columns = [
    {
      headerText: '로케이션',
      dataField: 'locationCode',
      editable: false,
    },
    {
      headerText: '피킹가능수량',
      dataField: 'availableQuantity',
      editable: false,
    },
    {
      headerText: '변경수량',
      dataField: 'quantity',
      style: 'my-custom-aui',
      editable: true,
      renderer: {
        type: 'TemplateRenderer',
        aliasFunction: function (rowIndex, columnIndex, value, headerText, item) {
          return value;
        },
      },
    },
  ];

  const currentSearchObj = useMemo(() => locationChangingObj?.searchObj && JSON.parse(locationChangingObj?.searchObj), [locationChangingObj.visible]);
  console.log(currentSearchObj, locationChangingObj);
  const changeLocations = () => {
    const data = {
      centerCode: locationChangingObj?.item?.centerCode,
      loanPickDate: locationChangingObj?.item?.loanPickDate,
      userId: locationChangingObj?.item?.userId,
      modelStockSeq: locationChangingObj?.item?.modelStockSeq,
      beforeLocationCode: locationChangingObj?.item?.locationCode,

      list: [],
    };

    const rows = window.AUIGrid.getGridData(`#${gridId?.SAVE}`);
    data.list = rows.map((ele) => {
      if (Number(ele.quantity) > 0) {
        return {
          quantity: ele.quantity,
          afterLocationCode: ele.locationCode,
        };
      }
      return null;
    });
    if (data.list?.filter((ele) => ele !== null)?.length === rows.length) {
      locationChangingObj?.saveFn?.({ data, event: locationChangingObj?.event, searchObj: currentSearchObj, bottomSheetObj: locationChangingObj });
    } else {
      alert('수량을 입력하지 않은 아이템이 존재합니다!');
    }
  };

  const fetchList = (searchObj) => {
    let url = `/warehouse/inventory/centerCode/locationCode/modelStock/movable/list?modelStockSeq=${locationChangingObj?.item?.modelStockSeq}`;
    if (locationChangingObj?.item?.centerCode) url += `&centerCode=${locationChangingObj?.item?.centerCode}`;
    if (searchObj?.locationCode) url += `&locationCode=${searchObj?.locationCode}`;
    httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        const data = rs.data.map((ele) => {
          return {
            locationCode: ele.locationCode,
            availableQuantity: ele.availableQuantity,
            quantity: 0,
          };
        });

        setRows((prev) => {
          return {
            ...prev,
            SEARCH: data,
          };
        });
      }
    });
  };
  return (
    <CommonModalNew
      style={{ width: 400 }}
      title="피킹 로케이션 변경"
      visible={locationChangingObj?.visible}
      setVisible={() => {
        setLocationChangingObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      children={
        <>
          <Row>
            <div style={{ fontWeight: 'bold', fontSize: 18 }}>{locationChangingObj?.item?.locationCode}</div>
            <div style={{ color: 'gray' }}>{locationChangingObj?.item?.modelName}</div>
            <div style={{ color: 'gray' }}>{locationChangingObj?.item?.barcode}</div>
          </Row>
          {gridId?.SAVE && (
            <>
              <div className="grid-button-area only-right">
                <div
                  className="dark"
                  onClick={() => {
                    changeLocations();
                  }}
                >
                  저장
                </div>
              </div>
              <Grid
                id={gridId?.SAVE}
                rows={rows?.SAVE}
                style={{ height: 200, marginBottom: 20 }}
                columns={columns}
                settingOptions={{
                  showRowNumColumn: true,
                  showRowAllCheckBox: false,
                  showRowCheckColumn: false,
                  //
                  enableDrag: true,
                  enableMultipleDrag: true,
                  enableDragByCellDrag: true,
                  enableDrop: true,
                  dropToOthers: true,
                }}
              />
            </>
          )}
          {gridId && (
            <div className="grid-button-area" style={{ margin: 15, justifyContent: 'space-evenly' }}>
              <div className="blue fake">추가 ▲</div>
              <div className="transparent">드래그 앤 드롭으로 이동</div>
              <div className="red fake">삭제 ▼</div>
            </div>
          )}
          <Row>
            <Col lg={9}>
              <label className="col-form-label">로케이션</label>
              <InputD
                value={searchObj?.locationCode || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    locationCode: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <button
                className="btn btn-etc sssm"
                onClick={(e) => {
                  e.preventDefault();
                  fetchList(searchObj);
                }}
              >
                조회
              </button>
            </Col>
          </Row>

          {gridId?.SEARCH && (
            <Grid
              id={gridId?.SEARCH}
              rows={rows?.SEARCH}
              style={{ height: 200, marginTop: 10, marginBottom: 20 }}
              columns={columns}
              settingOptions={{
                showRowNumColumn: true,
                showRowAllCheckBox: false,
                showRowCheckColumn: false,
                //
                enableDrag: true,
                enableMultipleDrag: true,
                enableDragByCellDrag: true,
                enableDrop: true,
                dropToOthers: true,
              }}
            />
          )}
        </>
      }
    />
  );
};

const HaldangModal = ({ haldangObj, setHaldangObj, cancelObj, setCancelObj }) => {
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState();
  const [gridId, setGrid] = useState(`fcPicking_Haldang_${uuidv4()}`);
  const [actionParams, setActionParams] = useState();

  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state) => state.menu);

  const gridRef = useRef();
  const convertKeyValue = {
    customerAddr: (row) => (row.customerAddr1 ? `${row.customerAddr1} ${row.customerAddr2 || ''}` : ''),
    centerCodeKr: (row) => selectlabel(row.centerCode, masterOptions?.CENTER),
    originalCenterCodeKr: (row) => selectlabel(row.originalCenterCode, masterOptions?.CENTER),
    destinationCenterCodeKr: (row) => selectlabel(row.destinationCenterCode, masterOptions?.CENTER),
    driverCenterCodeKr: (row) => selectlabel(row.driverCenterCode, masterOptions?.CENTER),
    partnerSeqKr: (row) => selectlabel(row.partnerSeq, masterOptions?.SELLER),
    modelGroupKr: (row) => selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
    availableTypeKr: (row) => selectlabel(row.availableType, masterOptions?.INVA_TYPE),
    unloadFlagKr: (row) => (row.unloadFlag === true ? 'O' : 'X'),
    orderCancelFlagKr: (row) => (row.orderCancelFlag === true ? 'O' : 'X'),
    pickFlagKr: (row) => (row.pickFlag === true ? 'O' : 'X'),
    loadFlagKr: (row) => (row.loadFlag === true ? 'O' : 'X'),
  };
  const detailSelectType = { INVENTORY_SEQ: null, ORDER_SEQ: null, SEARCH_MODEL: null };

  const { setGridData, setDetailSelectTypeObj, handleSubmitFiltering, detailSelectTypeObj, detailSearchObj, setDetailSearchObj, convertFieldValue, returnKeyValues, resetFilters } = useFrontSearchData(
    {
      gridRef,
      initDetailSearchObj: {},
      detailSelectType,
      convertKeyValue,
    },
  );

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.INVENTORY_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.INVENTORY_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.ORDER_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, detailSearchObj));
  }, [detailSelectTypeObj?.SEARCH_MODEL]);

  const columns = [
    {
      headerText: '피킹여부',
      dataField: 'pickFlagKr',
    },
    {
      headerText: '피킹취소여부',
      dataField: 'orderCancelFlagKr',
    },
    {
      headerText: '재고번호',
      dataField: 'inventorySeq',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '재고유형',
      dataField: 'availableTypeKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '고객명',
      dataField: 'customerName',
    },
    {
      headerText: '고객주소',
      dataField: 'customerAddr',
    },
    {
      headerText: '연락처',
      dataField: 'customerPhone',
    },
    {
      headerText: '권역정보',
      dataField: 'zipcodeGroupInfo',
    },
    {
      headerText: '도크코드',
      dataField: 'dockNumber',
    },
    {
      headerText: '기사명', // 이전 : 설치기사
      dataField: 'driverName',
    },
    {
      headerText: '기사소속창고', // 이전 : 기사출고창고
      dataField: 'driverCenterCodeKr', // 이전 : centerCodeKr
    },
    {
      headerText: '지정일', // 이전 : 기사출고예정일
      dataField: 'deliveryDate',
    },
    {
      headerText: '고객주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '비고',
      dataField: 'memo',
    },
    /*
    {
      headerText: '기사출고창고',
      dataField: 'driverCenterCodeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '기사출고예정일',
      dataField: 'deliveryDate',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '주문취소일시',
      dataField: 'cancelDatetime',
      filter: {
        showIcon: true,
      },
    },
    */
  ];

  useEffect(() => {
    fetchHaldangInfo(haldangObj?.origin);
  }, [haldangObj.origin]);

  const currentSearchObj = useMemo(() => haldangObj?.origin?.searchObj && JSON.parse(haldangObj?.origin?.searchObj), [haldangObj.origin]);

  const fetchHaldangInfo = async (item) => {
    dispatch(setLoading('GET'));
    setHaldangObj((prev) => {
      return {
        ...prev,
        item: {
          statusKr: item?.statusKr,
          userId: item?.userId,
          loanPickSeq: item?.loanPickSeq,
        },
      };
    });

    const searchDTO = {
      loanPickSeq: item?.loanPickSeq,
      centerCode: item?.centerCode,
      loanPickDate: item?.loanPickDate,
      userId: item.userId, // 기사별 피킹에 필요함
    };

    // NOTE: 액션 버튼 api 호출 시 필요한 parameter들을 정의
    setActionParams({
      linehaulSeq: item?.linehaulSeq,
      loadingStatementSeq: item?.loadingStatementSeq,
      loadingOutSeq: item?.loadingOutSeq,
      transportStatementSeq: item?.transportStatementSeq,
      originalCenterCode: item?.originalCenterCode,
      destinationCenterCode: item?.destinationCenterCode,
      linehaulDate: item?.linehaulDate,
      linehaulType: item?.linehaulType,
      status: item?.status,
      stockRemoveSeq: item?.stockRemoveSeq,
      centerCode: item?.centerCode,
      target: item?.target,
      partnerSeq: item?.partnerSeq,
      stockRemoveDate: item?.stockRemoveDate,
      stockRemoveType: item?.stockRemoveType,
      holdType: item?.holdType,
      loanPickSeq: item?.loanPickSeq,
      loanPickDate: item?.loanPickDate,
      userId: item.userId,
    });

    const rs = await serviceStore?.warehouseAction(`loan/picking/info`, 'GET', searchDTO);

    if (rs?.status === 200) {
      const data = rs?.data;
      setGridData(data);
      const dataKr = data?.map((row) => {
        let keyValues = returnKeyValues(convertKeyValue, row);

        return {
          ...row,
          ...keyValues,
        };
      });
      gridRef?.current?.setGridData(dataKr);
      dispatch(setLoading(null));
    }
  };

  const confirmPickingParts = async (items) => {
    if (window.confirm('피킹확정 하시겠습니까?')) {
      const data = {
        centerCode: haldangObj?.origin?.centerCode,
        loanPickDate: haldangObj?.origin?.loanPickDate,
        inventorySeqs: items?.map((ele) => {
          return ele.inventorySeq;
        }),
      };

      await haldangObj?.confirmFn?.([data], currentSearchObj);
      fetchHaldangInfo(haldangObj?.origin);
    }
  };

  const rollbackStatusParts = async (items) => {
    const availStat = ['피킹지시확정', '피킹중'];
    const statusKr = haldangObj?.origin?.statusKr;
    if (availStat.includes(statusKr)) {
      if (window.confirm(`${statusKr} 상태에서 ${statusKr === '피킹지시확정' ? '피킹지시대기' : '피킹지시확정'} 상태로 돌아가시겠습니까?`)) {
        const dataArr = [
          {
            loanPickSeq: haldangObj?.origin?.loanPickSeq,
            inventorySeqs: items?.map((ele) => {
              return ele.inventorySeq;
            }),
          },
        ];

        await haldangObj?.rollbackFn?.(dataArr, currentSearchObj);
        fetchHaldangInfo(haldangObj?.origin);
      }
    } else {
      alert('피킹지시확정 혹은 피킹중 상태만 이전단계로 돌릴 수 있습니다!');
    }
  };

  const cancelPickingParts = (items) => {
    const availStat = ['피킹지시대기', '피킹지시확정'];
    const statusKr = haldangObj?.origin?.statusKr;
    if (availStat.includes(statusKr)) {
      setCancelObj((prev) => {
        return {
          ...prev,
          visible: true,
          searchObj: currentSearchObj,
          origin: haldangObj?.origin,
          data: items,
          refetchFn: (origin) => fetchHaldangInfo(origin),
        };
      });
    } else {
      alert('피킹취소는 진행상태가 피킹지시대기, 피킹지시확정 일때에만 가능합니다!');
    }
  };

  const [printObj, setPrintObj] = useState();

  const gridActionObj = {
    'rollback-status-parts': (items) => rollbackStatusParts(items),
    'cancel-picking-parts': (items) => cancelPickingParts(items),
    'confirm-picking-parts': (items) => confirmPickingParts(items),
  };

  const handleCheckItems = (e) => {
    const buttonId = e.target.id;
    const items = gridRef.current.getCheckedRowItemsAll();

    if (items.length === 0 && !buttonId.includes('excel')) {
      alert('선택된 항목이 존재하지 않습니다.');
      return;
    }

    if (buttonId.includes('excel')) {
      gridRef.current.exportAsXlsx({ fileName: 'FC피킹_할당정보(기사별피킹)' });
      return;
    }

    if (buttonId === 'rollback-status-parts') {
      if (actionParams?.loanPickSeq === '') {
        alert('피킹지시번호가 없습니다!');
        return;
      }
      if (actionParams?.status !== 'READY' && actionParams?.status !== 'PICKING') {
        alert('피킹지시확정, 피킹중 상태일 때만 가능합니다!');
        return;
      }
    }

    if (buttonId === 'cancel-picking-parts') {
      if (actionParams?.status !== 'INIT' && actionParams?.status !== 'READY') {
        alert('피킹지시대기, 피킹지시확정 상태일 때만 가능합니다!');
        return;
      }
    }

    if (buttonId === 'confirm-picking-parts') {
      if (actionParams?.loanPickSeq) {
        alert('피킹지시번호가 이미 존재합니다!');
        return;
      }
      if (actionParams?.status !== 'INIT') {
        alert('피킹지시대기 상태일 때만 가능합니다!');
        return;
      }
    }

    if (buttonId === 'print-dropshipping') {
      for (let item of items) {
        if (!item.invoiceSeq) {
          alert('송장번호가 없는 항목이 존재합니다!');
          return;
        }
      }
      setPrintObj({
        visible: true,
        items,
      });
      return;
    }

    const paramKeys = Object.keys(actionParams);
    for (let param of paramKeys) {
      items[0][param] = actionParams[param];
    }
    setGetCheckedDataTriggerThisGrid({
      type: buttonId,
      id: gridId,
      items,
    });

    gridActionObj[buttonId](items);
    e.target.blur();
  };

  return (
    <CommonModalNew
      title="FC피킹 할당정보 (기사별피킹)"
      style={{ width: 1400 }}
      subTitle={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>피킹지시번호</strong> : {haldangObj?.item?.loanPickSeq || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>기사명</strong> : {masterOptions?.DRIVER_LABEL_OBJ?.[haldangObj.item?.userId] || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>진행상태</strong> : {haldangObj?.item?.statusKr || '-'}
        </div>
      }
      visible={haldangObj?.visible}
      setVisible={() => {
        setHaldangObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      children={
        <>
          <Form onSubmit={handleSubmitFiltering}>
            <Row>
              <Col>
                <label className="col-form-label">재고번호/시리얼번호</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.INVENTORY_SEQ}
                      value={
                        detailSelectTypeObj?.INVENTORY_SEQ
                          ? {
                              label: selectlabel(detailSelectTypeObj?.INVENTORY_SEQ, masterOptions?.INVENTORY_SEQ),
                              value: detailSelectTypeObj?.INVENTORY_SEQ,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          INVENTORY_SEQ: option.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.INVENTORY_SEQ}
                      value={detailSearchObj?.[detailSelectTypeObj?.INVENTORY_SEQ] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.INVENTORY_SEQ]: convertFieldValue(detailSelectTypeObj['INVENTORY_SEQ'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">주문번호/송장번호</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.ORDER_SEQ}
                      value={
                        detailSelectTypeObj?.ORDER_SEQ
                          ? {
                              label: selectlabel(detailSelectTypeObj?.ORDER_SEQ, masterOptions?.ORDER_SEQ),
                              value: detailSelectTypeObj?.ORDER_SEQ,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          ORDER_SEQ: option.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.ORDER_SEQ}
                      value={detailSearchObj?.[detailSelectTypeObj?.ORDER_SEQ] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.ORDER_SEQ]: convertFieldValue(detailSelectTypeObj['ORDER_SEQ'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">제품/제품명</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.SEARCH_MODEL}
                      value={
                        detailSelectTypeObj?.SEARCH_MODEL
                          ? {
                              label: selectlabel(detailSelectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                              value: detailSelectTypeObj?.SEARCH_MODEL,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          SEARCH_MODEL: option.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.SEARCH_MODEL}
                      value={detailSearchObj?.[detailSelectTypeObj?.SEARCH_MODEL] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.SEARCH_MODEL]: convertFieldValue(detailSelectTypeObj['SEARCH_MODEL'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">판매사</label>
                <div style={{ display: 'flex' }}>
                  <Col style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.SELLER}
                      value={
                        detailSearchObj?.partnerSeq
                          ? {
                              label: selectlabel(detailSearchObj?.partnerSeq, masterOptions?.SELLER),
                              value: detailSearchObj?.partnerSeq,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          partnerSeq: option.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>

              <Col>
                <label className="col-form-label">피킹여부</label>
                <div
                  className="radio-select-area"
                  onClick={(e) => {
                    toggleBooleanOptions('pickFlag', e.target.dataset.flagId, detailSearchObj, setDetailSearchObj);
                  }}
                >
                  <span className={detailSearchObj?.pickFlag === true ? 'active' : ''} data-flag-id="true">
                    O
                  </span>
                  <span className={detailSearchObj?.pickFlag === false ? 'active' : ''} data-flag-id="false">
                    X
                  </span>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">피킹취소여부</label>
                <div
                  className="radio-select-area"
                  onClick={(e) => {
                    toggleBooleanOptions('orderCancelFlag', e.target.dataset.flagId, detailSearchObj, setDetailSearchObj);
                  }}
                >
                  <span className={detailSearchObj?.orderCancelFlag === true ? 'active' : ''} data-flag-id="true">
                    O
                  </span>
                  <span className={detailSearchObj?.orderCancelFlag === false ? 'active' : ''} data-flag-id="false">
                    X
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col />
              <Col />
              <Col />
              <Col />
              <Col />
              <Col style={{ textAlign: 'right', placeSelf: 'flex-end', display: 'flex', gap: '4px' }}>
                <button type="button" className="btn" style={{ border: '1px solid #DADADA', minWidth: '60px' }} onClick={resetFilters}>
                  초기화
                </button>
                <button type="submit" className="btn btn-search" style={{ flexGrow: 1 }}>
                  조회
                </button>
              </Col>
            </Row>
          </Form>
          <Divider $direction={'H'} $color={'#eaeaea'} $spacing={'LG'} $value={'FULL'} />
          {printObj?.visible && <Prints printObj={printObj} setPrintObj={setPrintObj} />}
          <div className="grid-button-area space-between">
            <div>
              <div className="red" id="rollback-status-parts" onClick={handleCheckItems}>
                이전단계
              </div>
              <div className="red" id="cancel-picking-parts" onClick={handleCheckItems}>
                피킹취소
              </div>
              <div className="orange" id="confirm-picking-parts" onClick={handleCheckItems}>
                피킹지시확정
              </div>
              <div className="gray" id="print-dropshipping" onClick={handleCheckItems}>
                송장출력
              </div>

              <div className="green" id="excel" onClick={handleCheckItems}>
                엑셀다운
              </div>
            </div>
          </div>
          <GridBoxCommon
            gridRef={gridRef}
            columns={columns}
            getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
            setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            gridProps={{
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              showPageRowSelect: true,
              rowStyleFunction: function (rowIndex, item) {
                if (item.orderCancelFlag === true) return 'warning-row-style';
                return '';
              },
            }}
          />
          {/* <div style={{ width: '100%' }}>
            {gridId && (
              <>
                <Grid
                  style={{ height: 500 }}
                  id={gridId}
                  rows={rows}
                  columns={columns}
                  settingOptions={{
                    showRowAllCheckBox: true,
                    showRowCheckColumn: true,
                    showPageRowSelect: true,
                    pageRowCount: 300,
                    rowStyleFunction: function (rowIndex, item) {
                      if (item.orderCancelFlag === true) return 'warning-row-style';
                      return '';
                    },
                  }}
                  exportExcelThisGrid={exportExcelThisGrid}
                  getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
                  setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
                />
              </>
            )}
          </div> */}
        </>
      }
    />
  );
};

const Search = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const [selectTypeObj, setSelectTypeObj] = useState({
    ORDER_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.ORDER_SEQ, searchObj));
  }, [selectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchList(searchObj);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <label className="col-form-label">피킹지시번호</label>
            <InputD
              value={searchObj?.loanPickSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  loanPickSeq: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">출고예정일</label>
            <FlatpickrD
              value={searchObj?.loanPickDate}
              onChange={(date) => {
                setSearchObj({
                  ...searchObj,
                  loanPickDate: [returnDateyyyymmdd(date[0]), returnDateyyyymmdd(date[1])],
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">창고</label>
            <SelectD
              options={masterOptions?.CENTER}
              value={
                searchObj?.centerCode
                  ? {
                      value: searchObj?.centerCode,
                      label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">기사</label>
            <div style={{ display: 'flex' }}>
              <Col md={6} style={{ display: 'inline-block' }}>
                <SelectD
                  options={masterOptions?.DRIVER}
                  value={
                    searchObj?.userId
                      ? {
                          value: searchObj?.userId,
                          label: selectlabel(searchObj?.userId, masterOptions?.DRIVER),
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      userId: value,
                    });
                  }}
                />
              </Col>
              <Col md={6} style={{ display: 'inline-block' }}>
                <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">진행상태</label>
            <SelectD
              options={masterOptions?.LOAN_PICK_STATUS}
              value={
                searchObj?.status
                  ? {
                      value: searchObj?.status,
                      label: selectlabel(searchObj?.status, masterOptions?.LOAN_PICK_STATUS),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  status: value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">주문번호/송장번호</label>
            {/* orderSeq */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.ORDER_SEQ
                      ? {
                          value: selectTypeObj?.ORDER_SEQ,
                          label: selectlabel(selectTypeObj?.ORDER_SEQ, masterOptions?.ORDER_SEQ),
                        }
                      : null
                  }
                  options={masterOptions?.ORDER_SEQ}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      ORDER_SEQ: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.ORDER_SEQ}
                  value={searchObj?.[selectTypeObj?.ORDER_SEQ] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.ORDER_SEQ]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">제품/제품명</label>
            {/* model / modelName  */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_MODEL
                      ? {
                          value: selectTypeObj?.SEARCH_MODEL,
                          label: selectlabel(selectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_MODEL}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_MODEL: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_MODEL}
                  value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">유사검색여부</label>
            {/* similaritySearchFlag */}
            <div className="radio-select-area">
              <span
                className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: true,
                    };
                  });
                }}
              >
                유사검색
              </span>
              <span
                className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: false,
                    };
                  });
                }}
              >
                일치검색
              </span>
            </div>
          </Col>
          <Col>
            <button className="btn btn-search" type="submit">
              조회
            </button>
          </Col>
          <Col />
          <Col />
          <Col />
        </Row>
      </Form>
    </>
  );
};

const GridBox = (props) => {
  const {
    rows = [],
    gridId,
    columns = [],
    detailColumns = [],
    onCellClick,
    fetchList,
    pagingSetting,
    setPagingSetting,
    downloadExcel,
    getCheckedDataTriggerThisGrid,
    setGetCheckedDataTriggerThisGrid,
    searchObj,
  } = props;
  const navigate = useNavigate();

  const excelGridRef = useRef();
  const excelDetailGridRef = useRef();
  const excelDownClicked = async (type) => {
    const data = (await downloadExcel(type)) || [];
    if (data.length > 0) {
      if (type === 'main') {
        excelGridRef.current.setGridData(data);
        excelGridRef.current.exportAsXlsx({ fileName: 'FC피킹기사별(메인)' });
      } else {
        excelDetailGridRef.current.setGridData(data);
        excelDetailGridRef.current.exportAsXlsx({ fileName: 'FC피킹기사별(상세)' });
      }
    }
  };

  useEffect(() => {
    if (pagingSetting?.pageNo && pagingSetting?.fetchNew) {
      // 단순히 페이지만 바뀔때
      fetchList();
    }
  }, [pagingSetting?.fetchNew]);

  const transfer = {
    FCPICKING_DRIVER_CONFIRM: 'confirm-picking',
    FCPICKING_DRIVER_COMPLETE: 'complete-picking',
    FCPICKING_DRIVER_ROLLBACK: 'rollback-status',
    FCPICKING_DRIVER_CANCEL: 'cancel-picking',
    FCPICKING_DRIVER_PRINTALL: 'print-all',
  };

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  const handleCheckItems = (e) => {
    const id = e.target.id;
    if (id?.includes('_CREATE')) {
      currentDesignType === 1 ? navigate('/main/fcpicking/createfcpicking') : window.open('/createfcpicking', '_blank');
    } else if (id === 'FCPICKING_DRIVER_EXCELDOWNMAIN') {
      excelDownClicked('main');
    } else if (id === 'FCPICKING_DRIVER_EXCELDOWNDETAIL') {
      excelDownClicked('detail');
    } else {
      setGetCheckedDataTriggerThisGrid({
        type: transfer[id],
        id: `#${gridId}`,
      });
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState();
  const functions = fetchUsableFunctionsInThisTab('FCPICKING_DRIVER');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, handleCheckItems);
    setFunctionBtns(btns);
  }, [searchObj, gridId]);

  return (
    <>
      {functionBtns ? (
        <div className="grid-button-area">{functionBtns}</div>
      ) : (
        <div className="grid-button-area space-between">
          <div>
            <div
              className="blue"
              onClick={() => {
                window.open('/createfcpicking', '_blank');
              }}
            >
              FC피킹 생성
            </div>
            <div
              className="orange"
              onClick={() => {
                setGetCheckedDataTriggerThisGrid({
                  type: 'confirm-picking',
                  id: `#${gridId}`,
                });
              }}
            >
              피킹지시 확정
            </div>
            <div
              className="orange"
              onClick={() => {
                setGetCheckedDataTriggerThisGrid({
                  type: 'complete-picking',
                  id: `#${gridId}`,
                });
              }}
            >
              피킹 완료
            </div>
            <div
              className="red"
              onClick={() => {
                setGetCheckedDataTriggerThisGrid({
                  type: 'rollback-status',
                  id: `#${gridId}`,
                });
              }}
            >
              이전단계
            </div>
            <div
              className="red"
              onClick={() => {
                setGetCheckedDataTriggerThisGrid({
                  type: 'cancel-picking',
                  id: `#${gridId}`,
                });
              }}
            >
              피킹취소
            </div>
            <div
              className="gray"
              onClick={() => {
                setGetCheckedDataTriggerThisGrid({
                  type: 'print-all',
                  id: `#${gridId}`,
                });
              }}
            >
              일괄출력
            </div>
          </div>
          <div>
            <div
              className="green"
              onClick={() => {
                excelDownClicked('main');
              }}
            >
              엑셀다운
            </div>
            <div
              className="green"
              onClick={() => {
                excelDownClicked('detail');
              }}
            >
              엑셀다운(상세)
            </div>
          </div>
        </div>
      )}
      <div style={{ width: '100%' }}>
        {gridId && (
          <>
            <Grid
              id={gridId}
              rows={rows}
              columns={columns}
              // custompaging **

              pagingSetting={pagingSetting}
              setPagingSetting={setPagingSetting}
              settingOptions={{
                usePaging: true,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
                fixedColumnCount: 3,
                rowStyleFunction: function (rowIndex, item) {
                  if (item.orderCancelFlag === true) return 'warning-row-style';
                  return '';
                },
              }}
              onCellClick={onCellClick}
              getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
              setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            />
            <PrintGridBox gridRef={excelGridRef} columns={columns} />
            <PrintGridBox gridRef={excelDetailGridRef} columns={detailColumns} />
          </>
        )}
      </div>
    </>
  );
};
