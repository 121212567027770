import { ReactNode, useMemo, useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { WarehouseDTO, WarehouseSearchDTO } from 'interface/warehouse';
import { DetailPopup, IDetailObj } from './component/detailPopup';
import { PagingListDTO } from 'interface/util';
import { serviceStore } from 'services/services';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import PrintGridBox from 'common/grid/printGridBox';
import AUIGrid from 'modules/Grid';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const [detailObj, setDetailObj] = useState<IDetailObj>();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const labellingKr = (data: PagingListDTO<WarehouseDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        useFlagKr: row.useFlag ? '운영중' : '종료',
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        centerTypeKr: MASTER_OBJ?.WH_TYPE?.[row.centerType],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: WarehouseSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.warehouseAction(`list/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<WarehouseDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction(`list/excel`, 'GET', searchObj, null))?.data as WarehouseDTO[],
      },
      true,
    );
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '창고관리' });
    dispatch(setLoading(null));
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const openDetail = async (e: IGrid.ButtonClickEvent) => {
    setDetailObj((prev) => {
      return {
        ...prev,
        visible: true,
        type: 'detail',
        item: e.item,
        fetchFn: wrappedFetchList,
      };
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      dataField: 'detail',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: openDetail,
      },
    },
    {
      headerText: '위탁사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '창고타입',
      dataField: 'centerTypeKr',
    },
    {
      headerText: '창고',
      dataField: 'centerName',
    },
    {
      headerText: '창고코드',
      dataField: 'centerCode',
    },
    {
      headerText: '창고상태',
      dataField: 'useFlagKr',
    },
    {
      headerText: '주소',
      dataField: 'address',
      width: '25%',
    },
    {
      headerText: '창고장',
      dataField: 'managerName',
    },
    {
      headerText: '연락처',
      dataField: 'managerPhone',
    },
  ];

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'WAREHOUSEREGISTER') {
      setDetailObj({
        visible: true,
        type: 'create',
        item: {
          searchObj: JSON.stringify(searchObj),
        },
        fetchFn: wrappedFetchList,
      });
    } else if (id === 'WAREHOUSEEXCEL') {
      downloadExcel('main');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox gridRef={gridRef} columns={columns} gridProps={{ showRowAllCheckBox: false, showRowCheckColumn: false, fixedColumnCount: 1 }} />
        <PrintGridBox gridRef={excelGridRef} columns={columns.filter((_, idx) => idx > 0)} />
      </div>
    </div>
  );
};

export default Index;
