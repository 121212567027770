import { createSlice } from '@reduxjs/toolkit';

export type ILOCALE = 'ko' | 'en' | 'zh';
export const LocaleObj = {
  ko: {
    label: '한국어',
    exp: '',
  },
  en: {
    label: 'English',
    exp: 'Access is available only for pages that have been translated into English ',
  },
  zh: {
    label: '中文',
    exp: '仅已完成翻译的页面可以显示中文',
  },
};

interface InitProps {
  locale?: {
    value: ILOCALE;
    visible: boolean;
  };
}

const initialState: InitProps = {
  locale: {
    value: 'ko',
    visible: false,
  },
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = {
        ...state.locale,
        value: action.payload,
      };
    },
    setLocaleVisible: (state) => {
      state.locale = {
        ...state.locale,
        visible: !state.locale.visible,
      };
    },
  },
});

export const {
  //
  setLocale,
  setLocaleVisible,
} = localeSlice.actions;

export default localeSlice.reducer;
