import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tab1 from './locationHistory';
// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';

export const LOCATIONHISTORY_MASTER = [
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  //
  'LO_STATUS',
  'LO_SELECT_TYPE',
  'MODEL_GROUP',
  //
  'SEARCH_MODEL',
  {
    SEARCH_DATE: [
      {
        label: '입고예정일',
        value: 'PromiseDate',
      },
      {
        label: '입고확정일',
        value: 'ConfirmDate',
      },
      {
        label: '위치지정완료일',
        value: 'FinishDate',
      },
    ],
  },
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(LOCATIONHISTORY_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            INVENTORY_LOCATION_LIST: <Tab1 tabId={'INVENTORY_LOCATION_LIST'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
