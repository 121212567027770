import { Col, Row } from 'reactstrap';

//redux
import { useSelector } from 'react-redux';

import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// styled-component
import styled from 'styled-components';
//components
import Grid from 'components/grid/auiGrid';
import { useEffect, useState } from 'react';
import CommonModalNew from 'components/modal/commonModalNew';

const Presenter = (props) => {
  const { searchObj, modalObj, setModalObj } = props;

  return (
    <>
      {modalObj?.visible && <UpdateModal modalObj={modalObj} setModalObj={setModalObj} searchObj={searchObj} />}
      <div className="page-content">
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const Search = (props) => {
  const { setRows, fetchList, searchObj, setSearchObj } = props;
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);

  return (
    <>
      <Row>
        <Col>
          <label className="col-form-label">*창고</label>
          <SelectD
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                  }
                : null
            }
            options={MASTER_OPS?.CENTER_AUTH}
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                centerCode: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">*판매사</label>
          <SelectD
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[searchObj?.partnerSeq],
                  }
                : null
            }
            options={MASTER_OPS?.SELLER_SELLER_AUTH}
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품그룹</label>
          <SelectD
            value={
              searchObj?.modelGroup
                ? {
                    value: searchObj?.modelGroup,
                    label: MASTER_OBJ?.MODEL_GROUP?.[searchObj?.modelGroup],
                  }
                : null
            }
            options={MASTER_OPS?.MODEL_GROUP}
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                modelGroup: value,
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={async () => {
              setRows({
                strategy_1: await fetchList({ type: 'strategy_1', searchObj }),
                strategy_2: [],
                strategy_3: [],
              });
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
      </Row>
    </>
  );
};

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const Step = styled.div``;
const GridBox = ({ gridId, columns, rows, searchObj, saveStrategyAPI, onCellClick, modalObj, setModalObj }) => {
  return (
    <>
      <div style={{ width: '100%', marginBottom: 50 }}>
        <Step>
          <Title>1. 전략명</Title>
          {gridId?.strategy_1 && (
            <>
              <div className="grid-button-area">
                {rows ? (
                  <div
                    className="blue"
                    onClick={() => {
                      setModalObj({
                        ...modalObj,
                        visible: true,
                        data: null,
                      });
                    }}
                  >
                    전략명 추가하기
                  </div>
                ) : (
                  <div className="transparent" />
                )}
              </div>
              <Grid
                style={{ height: 400 }}
                id={gridId?.strategy_1}
                rows={rows?.strategy_1}
                columns={columns?.strategy_1}
                onCellClick={onCellClick}
                settingOptions={{
                  isRowAllCheckCurrentView: true,
                  showRowAllCheckBox: false,
                  showRowCheckColumn: false,
                }}
              />
            </>
          )}
        </Step>
      </div>
      <div style={{ display: 'flex' }}>
        <Step style={{ width: 'calc(50% - 50px)', marginRight: 50 }}>
          <Title>2. 전략선택</Title>
          {gridId?.strategy_2 && (
            <>
              <div className="grid-button-area">
                <div className="transparent" />
              </div>
              <Grid
                style={{ height: 400 }}
                id={gridId?.strategy_2}
                rows={rows?.strategy_2}
                columns={columns?.strategy_2}
                onCellClick={onCellClick}
                settingOptions={{
                  isRowAllCheckCurrentView: true,
                  showRowAllCheckBox: false,
                  showRowCheckColumn: false,
                }}
              />
            </>
          )}
        </Step>
        <Step style={{ margin: 0, width: '50%' }}>
          <Title>3. 전략구성</Title>
          {gridId?.strategy_3 && (
            <>
              <div className="grid-button-area only-right">
                {rows?.strategy_3 && rows.strategy_3[0]?.delete ? (
                  <div
                    className="orange"
                    onClick={() => {
                      const row3 = window.AUIGrid.getGridData(`#${gridId?.strategy_3}`);
                      if (row3?.length > 0) {
                        saveStrategyAPI(row3);
                      } else {
                        alert('저장할 전략구성이 설정되지 않았습니다');
                      }
                    }}
                  >
                    저장하기
                  </div>
                ) : (
                  <div className="transparent" />
                )}
              </div>
              <Grid
                style={{ height: 400 }}
                id={gridId?.strategy_3}
                rows={rows?.strategy_3}
                columns={columns?.strategy_3}
                onCellClick={onCellClick}
                settingOptions={{
                  isRowAllCheckCurrentView: true,
                  showRowAllCheckBox: false,
                  showRowCheckColumn: false,
                }}
              />
            </>
          )}
        </Step>
      </div>
    </>
  );
};

const UpdateModal = ({ modalObj, setModalObj, searchObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);
  const [updateObj, setUpdateObj] = useState({
    type: 'PICK',
    centerCode: searchObj?.centerCode,
    partnerSeq: searchObj?.partnerSeq,
    modelGroup: searchObj?.modelGroup,
    title: null,
    warehouseStrategySeq: null,
  });

  useEffect(() => {
    const { data } = modalObj;
    if (data) {
      setUpdateObj({
        ...updateObj,
        partnerSeq: data.partnerSeq,
        modelGroup: data?.modelGroup,
        title: data?.title,
        warehouseStrategySeq: data?.warehouseStrategySeq,
      });
    }
  }, []);

  return (
    <>
      <CommonModalNew
        title={`전략명 ${modalObj?.data ? '수정하기' : '추가하기'}`}
        style={{ width: 800 }}
        visible={modalObj?.visible}
        setVisible={() =>
          setModalObj({
            ...modalObj,
            visible: false,
          })
        }
        children={
          <>
            <Row>
              <Col>
                <label className="col-form-label">*판매사</label>
                <SelectD
                  isDisabled
                  value={
                    updateObj?.partnerSeq
                      ? {
                          value: updateObj?.partnerSeq,
                          label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[updateObj?.partnerSeq],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SELLER_SELLER_AUTH}
                  onChange={({ value }) => {
                    setUpdateObj({
                      ...updateObj,
                      partnerSeq: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">*제품그룹</label>
                <SelectD
                  isDisabled={modalObj?.data}
                  value={
                    updateObj?.modelGroup
                      ? {
                          value: updateObj?.modelGroup,
                          label: MASTER_OBJ?.MODEL_GROUP?.[updateObj?.modelGroup],
                        }
                      : null
                  }
                  options={MASTER_OPS?.MODEL_GROUP}
                  onChange={({ value }) => {
                    setUpdateObj({
                      ...updateObj,
                      modelGroup: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">*전략명</label>
                <InputD
                  value={updateObj?.title || ''}
                  onChange={(e) => {
                    setUpdateObj({
                      ...updateObj,
                      title: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <button
                  className="btn-secondary btn"
                  onClick={() => {
                    if (!updateObj?.partnerSeq) {
                      alert('판매사를 선택하세요!');
                    } else if (!updateObj?.modelGroup) {
                      alert('제품그룹을 선택하세요!');
                    } else if (!updateObj?.title) {
                      alert('전략명이 비어있습니다!');
                    } else {
                      modalObj?.updateFn({ data: updateObj, searchObj });
                    }
                  }}
                >
                  {modalObj?.data ? '수정' : '추가'}
                </button>
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
};
