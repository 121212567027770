import { useEffect, useRef, useState } from 'react';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { setLoading } from 'redux/services/menuSlice';
import { Button, Col } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { ParcelInvoiceDTO, ParcelInvoiceDetailDTO, ParcelInvoiceSearchDTO, ParcelInvoiceTrackDTO } from '../../../_interface/parcel';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { numberThousandComma, thousandCommaToString } from 'common/util/counting';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';
import { IPhotoObj, Photos } from './photos';

const TitleText = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: rgba(255, 145, 14, 1);
  font-weight: 500;
`;

export interface IParcelDetail {
  visible?: boolean;
  item?: ParcelInvoiceDTO;
  searchObj?: ParcelInvoiceSearchDTO;
  fetchFn?: (data: ParcelInvoiceSearchDTO) => {};
}

export const ParcelDetail = ({ detailObj, setDetailObj }) => {
  const [modiFlag, setModiFlag] = useState(true);
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [parcelDetailInfo, setParcelDetailInfo] = useState<ParcelInvoiceDetailDTO>();

  useEffect(() => {
    if (detailObj?.item) {
      fetchDetail(detailObj?.item?.parcelInvoiceSeq);
    }
  }, []);

  const fetchDetail = async (parcelInvoiceSeq: number) => {
    const data = (await serviceStore.parcelAction(`invoice/detail`, 'GET', { parcelInvoiceSeq }, null))?.data;

    if (data) {
      setParcelDetailInfo(data as ParcelInvoiceDetailDTO);
    } else {
      alert('조회되는 운송장이 없습니다!');
      setDetailObj(null);
    }
  };
  const [tracks, setTracks] = useState<ParcelInvoiceTrackDTO[]>();
  useEffect(() => {
    if (parcelDetailInfo) {
      setTracks(parcelDetailInfo?.tracks);
    }
  }, [parcelDetailInfo]);

  const saveParcelDetail = async () => {
    dispatch(setLoading('POST'));

    const rs = await serviceStore?.parcelAction(`invoice/detail/save`, 'POST', null, parcelDetailInfo);
    if (rs?.status === 200) {
      alert('저장되었습니다');
      detailObj?.fetchFn(detailObj?.searchObj);
    }
    dispatch(setLoading(null));
  };

  const handleDaumPost = (e) => {
    if (!modiFlag) {
      const id = e.target.id;
      new (window as any).daum.Postcode({
        oncomplete: function (data) {
          const { zonecode, roadAddress, jibunAddress } = data;

          if (id === 'receiver')
            setParcelDetailInfo((prev) => {
              return {
                ...prev,
                receiverAddr1: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
                receiverZipcode: zonecode,
              };
            });
          else
            setParcelDetailInfo((prev) => {
              return {
                ...prev,
                senderAddr1: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
                senderZipcode: zonecode,
              };
            });
        },
      }).open();
    }
  };

  const [photoObj, setPhotoObj] = useState<IPhotoObj>();
  return (
    <CommonModalNew
      style={{ width: 1200 }}
      visible={detailObj?.visible}
      setVisible={() => setDetailObj(null)}
      title={
        <>
          <button className={'btn btn-secondary'}>{parcelDetailInfo?.parcelInvoiceNumber}</button>
        </>
      }
      rightTitle={
        <>
          <Button
            className="btn-reload btn ssm"
            onClick={() => {
              if (parcelDetailInfo?.status === 'FINISH' || parcelDetailInfo?.status === 'TRANSFER') {
                setPhotoObj({
                  visible: true,
                  data: parcelDetailInfo,
                });
              } else {
                alert('완료된 건만 사진을 확인하실 수 있습니다!');
              }
            }}
          >
            사진보기
          </Button>
          <Button
            className="btn btn-orange ssm"
            onClick={() => {
              if (!modiFlag) saveParcelDetail();
              setModiFlag(!modiFlag);
            }}
          >
            {!modiFlag ? '저장하기' : '수정하기'}
          </Button>
        </>
      }
      children={
        <>
          {photoObj?.visible && <Photos photoObj={photoObj} setPhotoObj={setPhotoObj} />}
          <div className={'grid-wrapper div2'}>
            <div>
              <TitleText>■ 주문정보</TitleText>
              <table className="border-table">
                <tbody>
                  <tr>
                    <th>운송장번호</th>
                    <td colSpan={2}>{parcelDetailInfo?.parcelInvoiceNumber}</td>
                    <th>HBL</th>
                    <td colSpan={2}>{parcelDetailInfo?.houseNumber}</td>
                  </tr>
                  <tr>
                    <th>SBL</th>
                    <td colSpan={2}>{parcelDetailInfo?.subNumber}</td>
                    <th>차수</th>
                    <td colSpan={2}>{parcelDetailInfo?.stage}</td>
                  </tr>
                  <tr>
                    <th>양륙항</th>
                    <td colSpan={2}>{MASTER_OBJ?.PORT_CODE?.[parcelDetailInfo?.portCode]}</td>
                    <th>판매사</th>
                    <td colSpan={2}>{MASTER_OBJ?.SELLER_WHOLE?.[parcelDetailInfo?.partnerSeq]}</td>
                  </tr>
                  <tr>
                    <th>받는분</th>
                    <td colSpan={2}>
                      <InputD
                        disabled={modiFlag}
                        value={parcelDetailInfo?.receiverName || ''}
                        onChange={(e) => {
                          setParcelDetailInfo((prev) => {
                            return {
                              ...prev,
                              weight: e.target.value,
                            };
                          });
                        }}
                      />
                    </td>
                    <th>전화번호</th>
                    <td colSpan={2}>
                      <InputD
                        disabled={modiFlag}
                        value={parcelDetailInfo?.receiverPhone || ''}
                        onChange={(e) => {
                          setParcelDetailInfo((prev) => {
                            return {
                              ...prev,
                              receiverPhone: e.target.value,
                            };
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      운송장
                      <br />
                      등록자
                    </th>
                    <td colSpan={2}>{parcelDetailInfo?.registerId}</td>
                    <th>우편번호</th>
                    <td colSpan={2} id="receiver" onClick={handleDaumPost} style={{ cursor: 'pointer' }}>
                      <InputD
                        id="receiver"
                        className="read-only-should-click"
                        value={parcelDetailInfo?.receiverZipcode || ''}
                        onChange={(e) => {
                          setParcelDetailInfo({
                            ...parcelDetailInfo,
                            receiverZipcode: e.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td colSpan={5}>
                      <InputD
                        disabled
                        value={parcelDetailInfo?.receiverAddr1 || ''}
                        onChange={(e) => {
                          setParcelDetailInfo((prev) => {
                            return {
                              ...prev,
                              receiverAddr1: e.target.value,
                            };
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>상세주소</th>
                    <td colSpan={5}>
                      <InputD
                        disabled={modiFlag}
                        value={parcelDetailInfo?.receiverAddr2 || ''}
                        onChange={(e) => {
                          setParcelDetailInfo((prev) => {
                            return {
                              ...prev,
                              receiverAddr2: e.target.value,
                            };
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>보내는분</th>
                    <td colSpan={2}>
                      <InputD
                        disabled={modiFlag}
                        value={parcelDetailInfo?.senderName || ''}
                        onChange={(e) => {
                          setParcelDetailInfo((prev) => {
                            return {
                              ...prev,
                              senderName: e.target.value,
                            };
                          });
                        }}
                      />
                    </td>
                    <th>우편번호</th>
                    <td colSpan={2} id="sender" onClick={handleDaumPost} style={{ cursor: 'pointer' }}>
                      <InputD
                        id="sender"
                        className="read-only-should-click"
                        value={parcelDetailInfo?.senderZipcode || ''}
                        onChange={(e) => {
                          setParcelDetailInfo({
                            ...parcelDetailInfo,
                            senderZipcode: e.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td colSpan={5}>
                      <InputD
                        disabled
                        value={parcelDetailInfo?.senderAddr1 || ''}
                        onChange={(e) => {
                          setParcelDetailInfo((prev) => {
                            return {
                              ...prev,
                              senderAddr1: e.target.value,
                            };
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>상세주소</th>
                    <td colSpan={5}>
                      <InputD
                        disabled={modiFlag}
                        value={parcelDetailInfo?.senderAddr2 || ''}
                        onChange={(e) => {
                          setParcelDetailInfo((prev) => {
                            return {
                              ...prev,
                              senderAddr2: e.target.value,
                            };
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      고객
                      <br />
                      요청사항
                    </th>
                    <td colSpan={5}>
                      <textarea
                        disabled={modiFlag}
                        value={parcelDetailInfo?.shipmentNote || ''}
                        onChange={(e) => {
                          setParcelDetailInfo((prev) => {
                            return {
                              ...prev,
                              shipmentNote: e.target.value,
                            };
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style={{ position: 'relative' }}>
              <TitleText style={{ marginBottom: 5 }}>■ 배송추적</TitleText>
              <div style={{ height: 350 }}>
                <table className="border-table scroll" style={{ fontSize: 10, marginTop: 0 }}>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th colSpan={3}>일시</th>
                      <th colSpan={2}>현재위치</th>
                      <th colSpan={2}>배송상태</th>
                      <th colSpan={2}>담당자</th>
                      <th colSpan={2}>담당기사</th>
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: 345 }}>
                    {tracks?.map((ele, idx) => {
                      return (
                        <tr key={`today_${idx}`}>
                          <th>{idx + 1}</th>
                          <th colSpan={3}>{ele?.eventDatetime}</th>
                          <th colSpan={2}>
                            {ele?.location}
                            <br /> {ele?.officePhone && `(${ele?.officePhone})`}
                          </th>
                          <th colSpan={2}>{ele?.eventName}</th>
                          <th colSpan={2}>{ele?.registerId}</th>
                          <th colSpan={2}>
                            {ele?.deliveryMan}
                            <br /> {ele?.deliveryPhone && `(${ele?.deliveryPhone})`}
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <>
            <TitleText>■ 배송정보</TitleText>
            <table className="border-table">
              <tbody>
                <tr>
                  <th>택배상태</th>
                  <td colSpan={2}>{MASTER_OBJ?.PARCEL_INVOICE_STATUS?.[parcelDetailInfo?.status]}</td>
                  <th>현재위치</th>
                  <td colSpan={2}>{parcelDetailInfo?.location}</td>
                  <th>운임구분</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={modiFlag}
                      options={MASTER_OPS?.PARCEL_PAY_TYPE}
                      value={
                        parcelDetailInfo?.payType
                          ? {
                              value: parcelDetailInfo?.payType,
                              label: MASTER_OBJ?.PARCEL_PAY_TYPE?.[parcelDetailInfo?.payType],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setParcelDetailInfo((prev) => {
                          return {
                            ...prev,
                            payType: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>운임비</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={modiFlag}
                      value={numberThousandComma(parcelDetailInfo?.price + '') || ''}
                      onChange={(e) => {
                        setParcelDetailInfo((prev) => {
                          return {
                            ...prev,
                            price: thousandCommaToString(e.target.value),
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th rowSpan={2}>상품명</th>
                  <td rowSpan={2} colSpan={5}>
                    {parcelDetailInfo?.productName}
                  </td>
                  <th>도선료</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={modiFlag}
                      value={parcelDetailInfo?.extraFee ? numberThousandComma(parcelDetailInfo?.extraFee + '') : ''}
                      onChange={(e) => {
                        setParcelDetailInfo((prev) => {
                          return {
                            ...prev,
                            extraFee: thousandCommaToString(e.target.value),
                          };
                        });
                      }}
                    />
                  </td>
                  <th>미배송사유</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={modiFlag}
                      options={MASTER_OPS?.PARCEL_REVISIT_REASON}
                      value={
                        parcelDetailInfo?.revisitReason
                          ? {
                              value: parcelDetailInfo?.revisitReason,
                              label: MASTER_OBJ?.PARCEL_REVISIT_REASON?.[parcelDetailInfo?.revisitReason],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setParcelDetailInfo((prev) => {
                          return {
                            ...prev,
                            revisitReason: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>가로(cm)</th>
                  <td colSpan={2}>
                    <InputD
                      type="number"
                      disabled={modiFlag}
                      value={parcelDetailInfo?.width || ''}
                      onChange={(e) => {
                        setParcelDetailInfo((prev) => {
                          return {
                            ...prev,
                            width: e.target.value,
                            volume: parseInt(e.target.value) * parseInt(parcelDetailInfo?.depth) * parseInt(parcelDetailInfo?.height) + '',
                          };
                        });
                      }}
                    />
                  </td>
                  <th>세로(cm)</th>
                  <td colSpan={2}>
                    <InputD
                      type="number"
                      disabled={modiFlag}
                      value={parcelDetailInfo?.depth || ''}
                      onChange={(e) => {
                        setParcelDetailInfo((prev) => {
                          return {
                            ...prev,
                            depth: e.target.value,
                            volume: parseInt(parcelDetailInfo?.width) * parseInt(e.target.value) * parseInt(parcelDetailInfo?.height) + '',
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>배달지</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={modiFlag}
                      options={MASTER_OPS?.CENTER_AUTH}
                      value={
                        parcelDetailInfo?.centerCode
                          ? {
                              value: parcelDetailInfo?.centerCode,
                              label: MASTER_OBJ?.CENTER_AUTH?.[parcelDetailInfo?.centerCode],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setParcelDetailInfo((prev) => {
                          return {
                            ...prev,
                            centerCode: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>배송완료장소</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={modiFlag}
                      options={MASTER_OPS?.FINISH_LOCATION}
                      value={
                        parcelDetailInfo?.finishLocation
                          ? {
                              value: parcelDetailInfo?.finishLocation,
                              label: MASTER_OBJ?.FINISH_LOCATION?.[parcelDetailInfo?.finishLocation],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setParcelDetailInfo((prev) => {
                          return {
                            ...prev,
                            finishLocation: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>높이(cm)</th>
                  <td colSpan={2}>
                    <InputD
                      type="number"
                      disabled={modiFlag}
                      value={parcelDetailInfo?.height || ''}
                      onChange={(e) => {
                        setParcelDetailInfo((prev) => {
                          return {
                            ...prev,
                            height: e.target.value,
                            volume: parseInt(parcelDetailInfo?.width) * parseInt(parcelDetailInfo?.depth) * parseInt(e.target.value) + '',
                          };
                        });
                      }}
                    />
                  </td>
                  <th>무게(kg)</th>
                  <td colSpan={2}>
                    <InputD readOnly type="number" disabled={true} value={parcelDetailInfo?.weight || ''} />
                  </td>
                </tr>
                <tr>
                  <th>주기사</th>
                  <td colSpan={2}>
                    <div style={{ display: 'flex' }}>
                      <Col md={6} style={{ display: 'inline-block' }}>
                        <SelectD
                          isDisabled={modiFlag}
                          options={MASTER_OPS?.RAWDATA_DRIVER_WHOLE?.filter((ele) => ele.centerCode === parcelDetailInfo?.centerCode)?.map((ele) => {
                            return {
                              value: ele?.userId,
                              label: MASTER_OBJ?.DRIVER_WHOLE?.[ele?.userId],
                            };
                          })}
                          value={
                            parcelDetailInfo?.userId
                              ? {
                                  value: parcelDetailInfo?.userId,
                                  label: MASTER_OBJ?.DRIVER_WHOLE?.[parcelDetailInfo?.userId],
                                }
                              : null
                          }
                          onChange={({ value }) => {
                            setParcelDetailInfo({
                              ...parcelDetailInfo,
                              userId: value,
                            });
                          }}
                        />
                      </Col>
                      <Col md={6} style={{ display: 'inline-block' }}>
                        <InputD disabled value={parcelDetailInfo?.userId || ''} placeholder="기사를 선택하세요" />
                      </Col>
                    </div>
                  </td>
                  <th>전화번호</th>
                  <td colSpan={2}>
                    <InputD value={MASTER_OBJ?.DRIVER_PHONE_WHOLE?.[parcelDetailInfo?.userId] || ''} disabled readOnly />
                  </td>

                  <th>부피(cm3)</th>
                  <td colSpan={2}>
                    <InputD readOnly disabled value={parcelDetailInfo?.volume || '자동계산'} />
                  </td>
                  <td colSpan={3}></td>
                </tr>
                <tr>
                  <th>배송메모</th>
                  <td colSpan={11}>
                    <textarea
                      disabled={modiFlag}
                      value={parcelDetailInfo?.remark || ''}
                      onChange={(e) => {
                        setParcelDetailInfo((prev) => {
                          return {
                            ...prev,
                            remark: e.target.value,
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </>
          <>
            <TitleText>■ 타택배정보</TitleText>
            <table className="border-table">
              <tbody>
                <tr>
                  <th>타택배이관</th>
                  <td colSpan={2}>{parcelDetailInfo?.transferParcel}</td>
                  <th>
                    외부
                    <br />
                    운송장번호
                  </th>
                  <td colSpan={2}>{parcelDetailInfo?.externalInvoiceNumber}</td>
                  <th>이관비</th>
                  <td colSpan={5}>{parcelDetailInfo?.transferPrice ? numberThousandComma(parcelDetailInfo?.transferPrice) : '-'}</td>
                </tr>
              </tbody>
            </table>
          </>
        </>
      }
    />
  );
};
