import { useState, useEffect } from 'react';
import CommonModalNew from 'components/modal/commonModalNew';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { SerialNumberLiveDTO, SerialNumberLiveSearchDTO } from 'interface/warehouse';
import { Col, Row } from 'reactstrap';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { inventoryService } from 'pages/INVENTORY/1inventory/_service/service';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { RootState } from 'redux/store';
import { WIDTHS } from 'common/constants/appearance';
export interface IPopupForModiObj {
  visible: boolean;
  type?: 'serial' | 'manufactureDate' | 'distributeDate' | 'assetType';
  items?: SerialNumberLiveDTO[];
  searchObj?: string;
  fetchFn: (data: SerialNumberLiveSearchDTO) => void;
}

interface IData {
  serialNumber?: string;
  date?: string;
  assetType?: string;
}

export const PopupForModi = ({ popupForModiObj, setPopupForModiObj }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const [data, setData] = useState<IData>();
  useEffect(() => {
    if (popupForModiObj?.type === 'serial') {
      setData({ serialNumber: popupForModiObj?.items?.serialNumber });
    }
  }, []);

  const saveFn = async (data: IData) => {
    let rs;
    dispatch(setLoading('POST'));
    if (popupForModiObj?.type === 'serial') {
      rs = await inventoryService.postModifiedSerialNumber({
        originalSerialNumber: popupForModiObj?.items?.serialNumber,
        modifiedSerialNumber: data.serialNumber,
      });
    } else if (popupForModiObj?.type === 'manufactureDate') {
      rs = await inventoryService.postManufactureDate({
        serialNumbers: popupForModiObj?.items?.map((ele) => ele.serialNumber),
        date: data?.date,
      });
    } else if (popupForModiObj?.type === 'distributeDate') {
      rs = await inventoryService.postDistributeDate({
        serialNumbers: popupForModiObj?.items?.map((ele) => ele.serialNumber),
        date: data?.date,
      });
    } else if (popupForModiObj?.type === 'assetType') {
      rs = await inventoryService.postAssetType({
        serialNumbers: popupForModiObj?.items?.map((ele) => ele.serialNumber),
        assetType: data?.assetType,
      });
    }

    if (rs?.status === 200) {
      alert('저장되었습니다!');
      popupForModiObj?.fetchFn(JSON.parse(popupForModiObj?.searchObj));
      setPopupForModiObj({
        ...popupForModiObj,
        visible: null,
      });
    }
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      style={{ width: WIDTHS.LAYOUT_SM_WIDTH }}
      title={
        popupForModiObj?.type === 'serial' ? '시리얼 수정' : popupForModiObj?.type === 'manufactureDate' ? '제조일 저장' : popupForModiObj?.type === 'distributeDate' ? '유통일 저장' : '자산유형 저장'
      }
      visible={popupForModiObj?.visible}
      setVisible={() => {
        setPopupForModiObj({
          visible: false,
          type: null,
        });
      }}
      children={
        <>
          {popupForModiObj?.type === 'serial' ? (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">시리얼번호</label>
                  <InputD
                    value={data?.serialNumber || ''}
                    onChange={(e) => {
                      setData({
                        ...data,
                        serialNumber: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: 'right', marginTop: 10 }}>
                  <button
                    className="btn btn-secondary ssm"
                    onClick={(e) => {
                      if (data?.serialNumber !== '') {
                        if (data?.serialNumber !== popupForModiObj?.items?.serialNumber) {
                          e.preventDefault();
                          saveFn(data);
                        } else {
                          alert('수정하려는 시리얼번호가 동일합니다');
                        }
                      } else {
                        alert('시리얼번호를 입력하세요');
                      }
                    }}
                  >
                    수정
                  </button>
                </Col>
              </Row>
            </>
          ) : popupForModiObj?.type === 'assetType' ? (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">자산유형</label>
                  <SelectD
                    value={
                      data?.assetType
                        ? {
                            value: data?.assetType,
                            label: selectlabel(data?.assetType, masterOptions?.ASSET_TYPE),
                          }
                        : null
                    }
                    options={masterOptions?.ASSET_TYPE}
                    onChange={(option) => {
                      setData((prev) => {
                        return {
                          ...prev,
                          assetType: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: 'right', marginTop: 10 }}>
                  <button
                    className="btn btn-secondary ssm"
                    onClick={(e) => {
                      e.preventDefault();
                      saveFn(data);
                    }}
                  >
                    수정
                  </button>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">일자</label>
                  <FlatpickrD
                    options={{ mode: 'single' }}
                    onReset={() => {
                      setData({
                        ...data,
                        date: null,
                      });
                    }}
                    value={data?.date}
                    onChange={(date) => {
                      setData({
                        ...data,
                        date: returnDateyyyymmdd(date[0]),
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: 'right', marginTop: 10 }}>
                  <button
                    className="btn btn-secondary ssm"
                    onClick={(e) => {
                      if (!data?.date) {
                        alert('일자를 선택해주세요');
                      } else {
                        e.preventDefault();
                        saveFn(data);
                      }
                    }}
                  >
                    수정
                  </button>
                </Col>
              </Row>
            </>
          )}
        </>
      }
    />
  );
};
