import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

// component
import AUIGrid from 'modules/Grid';
import GridBox, { defaultGridProps } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
//
import { orderService } from '../../_services/service';
import { CustomerOrderMoveSearchDTOSecond } from '../../_interface/order';
import useGridButton from 'hooks/grid/useGridButton';
import { useDidMountEffect } from 'hooks/basichooks';
import { CreatePopup, IObj } from './component/createPopup';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { VALUES } from 'common/constants/appearance';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, openOrclose } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<AUIGrid>();
  const [popupObj, setPopupObj] = useState<IObj>();
  const [searchObj, setSearchObj] = useState<CustomerOrderMoveSearchDTOSecond>({});

  const columns: IGrid.Column[] = [
    {
      headerText: '구분',
      dataField: 'moveClassKr',
      cellMerge: true,
      mergeRef: 'orderSeq',
      mergePolicy: 'restrict',
    },
    {
      headerText: '간선피킹지시번호',
      dataField: 'linehaulSeq',
      cellMerge: true,
      mergeRef: 'orderSeq',
      mergePolicy: 'restrict',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '경유지',
      dataField: 'stopoverCenterCode',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '판매채널',
          dataField: 'salesChannelName',
        },
        {
          headerText: '제품명',
          dataField: 'productName',
        },
      ],
    },
    {
      headerText: '실수령자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'receiverName',
        },
        {
          headerText: '우편번호',
          dataField: 'receiverZipcode',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr',
        },
        {
          headerText: '연락처1',
          dataField: 'receiverPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'receiverPhone2',
        },
      ],
    },
    {
      headerText: '주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '희망일',
      dataField: 'promiseDate',
    },
    {
      headerText: '지정일(확정일)',
      dataField: 'deliveryDate',
    },
    {
      headerText: '완료일',
      dataField: 'finishDate',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
      cellMerge: true,
      mergeRef: 'orderSeq',
      mergePolicy: 'restrict',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '송장상태',
      dataField: 'invoiceStatusKr',
    },
  ];

  useDidMountEffect(() => {
    if (gridRef.current) gridBind();
  }, [gridRef.current]);

  const gridBind = () => {
    gridRef.current.bind('rowCheckClick', function (event: IGrid.RowCheckClickEvent) {
      if (event.checked === true && event?.item?.['orderSeq']) {
        gridRef.current.addCheckedRowsByValue('orderSeq', event.item?.['orderSeq']);
      } else if (event.checked === false && event.item['orderSeq']) {
        gridRef.current.addUncheckedRowsByValue('orderSeq', event.item?.['orderSeq']);
      }
    });
  };

  const fetchList = async (searchObj: CustomerOrderMoveSearchDTOSecond) => {
    dispatch(setLoading('GET'));
    const datas = await orderService.getOrderRelocatePaging(searchObj);
    const dataKr = datas
      ?.map((data, idx) => {
        return data?.list?.map((row, idx2) => {
          return {
            ...row,
            seq: idx,
            isFirst: idx2 === 0 ? true : false,
            searchObj: JSON.stringify(searchObj),
            moveClass: data.moveClass,
            orderSeq: data.orderSeq,
            linehaulSeq: data.linehaulSeq,
            moveClassKr: MASTER_OBJ?.MOVE_CLASS?.[data?.moveClass],
            partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],
            feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row?.feeType],
            invoiceStatusKr: MASTER_OBJ?.INVOICE_STATUS?.[row?.invoiceStatus],
            centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
            //
            receiverAddr: row.receiverAddr1 + ' ' + row.receiverAddr2,
          };
        });
      })
      .flat();
    gridRef.current.setGridData(dataKr);
    dispatch(setLoading(null));
  };

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    if (type === 'main') {
      gridRef.current.exportAsXlsx({ fileName: '이전주문조회' });
    }
    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'ORDERMOVELINEHAULREADY') {
      const item = gridRef.current.getCheckedRowItemsAll();
      if (item?.length > 0) {
        setPopupObj({
          visible: true,
          item,
          searchObj: item?.[0].searchObj,
          fetchFn: (searchObj) => fetchList(searchObj),
        });
      } else {
        alert('선택된 항목이 존재하지 않습니다.');
      }
    } else if (id === 'ORDERMOVEEXCEL') {
      downloadExcel('main');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  useEffect(() => {
    if (typeof gridRef !== 'undefined') {
      setTimeout(
        () => {
          gridRef?.current?.resize();
        },
        JSON.parse(localStorage.getItem('useNewDesignFlag')) === 1 ? VALUES.SIDEBAR_TRANSITION_TIME : 0, //NOTE: 신규 디자인 Sidebar Animation Duration 만큼 Delay 적용
      );
    }
  }, [openOrclose.side]);

  return (
    <div className="page-content">
      {popupObj?.visible && <CreatePopup popupObj={popupObj} setPopupObj={setPopupObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={fetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            ...defaultGridProps,
            selectionMode: 'multipleCells',
            showRowNumColumn: false,
            showStateColumn: false,
            showRowAllCheckBox: false,
            showRowCheckColumn: true,
            enableCellMerge: true,
            enableSummaryMerge: true,
            showFooter: false,
            showPageRowSelect: false,
            independentAllCheckBox: true,
            rowCheckVisibleFunction: function (rowIndex, isChecked, item) {
              if (!item.isFirst) {
                return false; // display: none
              }
              return true;
            },
            rowCheckDisabledFunction: function (rowIndex, isChecked, item) {
              if (item.moveClassKr === '동일창고' || item.moveClassKr === '당일이전') {
                return false;
              }
              if (item.linehaulSeq) return false; // 간선피킹번호가 있는 경우에도 disabled
              return true; // 일반 활성화 된 체크박스로 표현
            },
          }}
        />
      </div>
    </div>
  );
};

export default Index;
