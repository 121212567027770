import { useEffect, useRef, useState } from 'react';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { takeoutService } from '../../_services/service';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Index = () => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    dispatch(setLoading('GET'));
    const data = await takeoutService.getOrderUselessAppliance();
    const dataKr = data?.map((row) => {
      return {
        ...row,
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER_LABEL),
        userIdKr: masterOptions?.DRIVER_NAME_LABEL_OBJ?.[row.userId],
      };
    });

    gridRef.current.setGridData(dataKr);
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '기사명',
      dataField: 'userIdKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelRange',
    },
    {
      headerText: '제품',
      dataField: 'modelName',
    },
    {
      headerText: '용량',
      dataField: 'volume',
    },
    {
      headerText: '수량',
      dataField: 'quantity',
    },
    {
      headerText: '회수일',
      dataField: 'initDatetime',
    },
    {
      headerText: '반출일',
      dataField: 'removeDatetime',
    },
    {
      headerText: '반출인',
      dataField: 'removeId',
    },
  ];

  return (
    <div className="page-content">
      <div className="presenterGridBox">
        <GridBox gridRef={gridRef} columns={columns} gridProps={{ showRowAllCheckBox: false, showRowCheckColumn: false }} />
      </div>
    </div>
  );
};

export default Index;
