import axios from 'axios';
import { clearLocalStorageData, setLocalStorageTokens } from 'common/util/localStorage';
import { USER_SIGN_REFRESH } from 'envVar';
import queryString from 'query-string';

const DEFAULT_LFS = process.env.REACT_APP_API_URL;

export const httpClient = axios.create({
  baseURL: DEFAULT_LFS,
  headers: {
    'Content-Type': 'application/json',
  },
});
const exceptUrl = [
  //
  '/user/sign/out',
  '/order/save/excel',
  '/warehouse/loan/v2/save/lite',
  '/order/cargoManifest/createCargoManifestTemp',
  '/order/cargoManifest/createCargoManifestRemain',
];

const alertMuteUrl = [
  '/user/sign/out',
  '/parcel/invoice/centerAndboxInfo',
  '/parcel/invoice/unload', //
  '/parcel/invoice/load', //
  '/parcel/invoice/remain',
  '/parcel/invoice/collect',
  '/parcel/invoice/collect/small',
];

const controller = new AbortController();
const sourceRequest: any = {};

/////////////////////////////////////////////////////////////////////
////////////////              REQUEST             ///////////////////
/////////////////////////////////////////////////////////////////////

const refresh = async (config) => {
  const accessTokenExp = Number(localStorage.getItem('accessTokenExp'));
  const timeStamp = new Date().getTime();
  let token = localStorage.getItem('accessToken');
  if (token) {
    // if () // refresh here
    if (30 * 1000 > accessTokenExp - timeStamp) {
      // TODO:: 30초 테스트
      try {
        const rs = await axios.post(process.env.REACT_APP_API_URL + USER_SIGN_REFRESH, null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
          },
        });
        if (rs?.status === 200) {
          setLocalStorageTokens(rs.data);

          if (config.url !== USER_SIGN_REFRESH) {
            config.headers.Authorization = `Bearer ${rs.data.accessToken}`;
            return config;
          } else {
            return config;
          }
        }
      } catch (err) {
        alert('토큰이 만료되어 로그인창으로 이동합니다!');
        clearLocalStorageData();
        window.location.href = '/login';
      }
      // else // paging 기본 param값 만족하지 않으면 보내지않음
    } else {
      // console.log('NO NEED REFRESH:', config);
      config.headers.Authorization = `Bearer ${token}`;
      if (config.method !== 'get') {
        const key = `${config.method}${config.url}$${JSON.stringify(config.data)}`;
        if (sourceRequest[key]) {
          const diff = new Date().getTime() - new Date(sourceRequest[key]).getTime();
          sourceRequest[key] = new Date();
          if (diff < 1000) {
            // console.log(new Date(sourceRequest[key]).getTime(), new Date().getTime(), ' :: ', diff);
            // console.log(config);
            config.signal = controller.signal;
            controller.abort();
            // throw new Error('Automatic cancellation');
          } else return config;
        } else {
          sourceRequest[key] = new Date();
          return config;
        }
      } else if (config.method === 'get') {
        if (config?.url?.includes('/paging')) {
          // ####### TEST
          const { pageNo, pageSize } = queryString.parse(config?.url?.split('?')[1]);
          // console.log(config?.url);
          if (Number(pageNo) > 0 && Number(pageSize) > 0) return config;
          // else // request하지 않음
        } else {
          return config;
        }
      }
    }
  } else {
    // console.log('NO TOKEN:', config);
    return config;
  }
};

const refreshErrorHandle = (err) => {
  clearLocalStorageData();
  alert('로그인이 만료되었습니다. 다시 로그인 해주세요.');
  window.location.href = '/login';
  return err;
};

httpClient.interceptors.request.use(refresh, refreshErrorHandle);

/////////////////////////////////////////////////////////////////////
////////////////              RESPONSE            ///////////////////
/////////////////////////////////////////////////////////////////////

httpClient.interceptors.response.use(
  function (config) {
    /** 요청을 보낸 뒤에 response(응답)이 오는 경우에 여기서 먼저 확인이 가능하다.
     * [활용]
     * 1. status-code가 정상적이어도 내용상의 이유로 에러처리가 필요한 경우
     * 2. 민감정보 또는 데이터에 대한 가공 작업
     */
    return config;
  },
  ({ error, config, request, response, ...err }) => {
    // console.log(error, config, request, response);
    // const { error, config, request, response, ...err } = errorRes;
    // /** response응답 후에 status-code가 4xx, 5xx 처럼 에러를 나타내는 경우 해당 루트를 수행한다.
    //  */
    if (request) {
      if (alertMuteUrl?.includes(config?.url?.split('?')[0])) {
        return response;
      } else if (response?.status === 503) {
        alert('사용자 네트워크 연결이 불안정 하거나, 방화벽 등에 의해 사용이 차단된 환경일 수 있습니다.\n이 오류가 반복되면 네트워크 관리자에게 문의하세요. (오류코드: 504)');
        return response; //err로 가요
      } else if (response?.status === 401) {
        if (config?.url !== '/user/sign/out') alert('권한이 없습니다 로그인창으로 이동합니다');
        clearLocalStorageData();
        window.location.href = '/login';
      } else if (response?.status === 404) {
        alert('잠시 후 다시 시도해주세요!');
        return response;
      } else if (response?.status !== 200 && (response?.data?.message || response?.data?.detailMessage)) {
        if (exceptUrl.includes(config?.url)) {
          return response;
        } else {
          if (response?.data?.detailMessage) alert(response?.data?.detailMessage);
          else if (response?.data?.message) alert(response?.data?.message);
          return response;
        }
      }

      return Promise.reject({
        error,
        config,
        response: response,
        ...err,
      });
    } else {
      return {
        error,
        config,
        response: null,
        ...err,
      };
    }
  },
);

export const tokenCheck = () => {
  const accessTokenExp = Number(localStorage.getItem('accessTokenExp'));
  const timeStamp = new Date().getTime();

  if (40 * 1000 > accessTokenExp - timeStamp) {
    // TODO:: 30초 테스트
    // console.log('토큰 체크 후 리프레시 필요::2))');
    return NEED_REFRESH;
  } else {
    // 괜찮은 상황
    return NO_NEED_REFRESH;
  }
};

export const NO_NEED_REFRESH = '- no need refresh -';
export const NEED_REFRESH = '- need refresh -';
