import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';

// utils
import { useDidMountEffect } from 'hooks/basichooks';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
// service
import { serviceStore } from 'services/services';
import { PurchaseOrderListSSDTO, PurchaseOrderSearchSSDTO, PurchaseOrderSSDTO } from '../../../_interface/warehouse';

// component
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import { PagingListDTO } from 'interface/util';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import { IPrint, Print } from './component/print';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const detailExcelGridRef = useRef<AUIGrid>();
  const bottomGridRef = useRef<IPagingGrid>();

  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.bind('cellEditEnd', bindCellEdit);
    }
  }, []);

  const bindCellEdit = (e: IGrid.CellEditEndEvent) => {
    if (e.dataField === 'promiseDate') {
      changePromiseDate(e);
    } else if (e.dataField === 'memo') {
      saveMemo(e);
    }
  };

  const fetchPaging = async (searchObj: PurchaseOrderSearchSSDTO) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore.warehouseAction(`purchase/ss/list/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<PurchaseOrderSSDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const saveMemo = async (e: IGrid.CellEditEndEvent) => {
    if (window.confirm('입력한 특이사항을 저장하시겠습니까?')) {
      dispatch(setLoading('POST'));
      let rs;
      if (e.item.purchaseDetailSeq) {
        rs = await serviceStore?.warehouseAction(`purchase/detail/memo/save`, 'POST', null, { purchaseDetailSeq: e.item.purchaseDetailSeq, memo: e.value });
        const detailE = JSON.parse(e.item.e);
        getPurchaseDetail(detailE);
      } else {
        rs = await serviceStore?.warehouseAction(`purchase/memo/save`, 'POST', null, { purchaseSeq: e.item.purchaseSeq, memo: e.value });
      }
      if (rs?.status === 200) {
        alert('특이사항 저장되었습니다!');
      }
      const searchObj = JSON.parse(e.item.searchObj);
      wrappedFetchList(searchObj);
    } else {
      const searchObj = JSON.parse(e.item.searchObj);
      wrappedFetchList(searchObj);
    }
  };

  const changePromiseDate = async (e: IGrid.CellEditEndEvent) => {
    if (e.item.partnerSeq === 46) {
      alert('판매사가 "바디프랜드"는 입고예정일 변경이 불가합니다');
    } else if (e.item.status === 'READY') {
      if (window.confirm('입력한 입고예정일로 수정하시겠습니까?')) {
        dispatch(setLoading('POST'));
        const rs = await serviceStore?.warehouseAction(`purchase/update/promiseDate`, 'PUT', null, { purchaseSeq: e.item.purchaseSeq, promiseDate: e.value });
        if (rs?.status === 200) {
          alert('입고예정일이 변경되었습니다!');
        }
        dispatch(setLoading(null));
      }
    } else {
      alert('입고대기 상태일때만 입고 예정일 변경 가능합니다');
    }
    const searchObj = JSON.parse(e.item.searchObj);
    wrappedFetchList(searchObj);
  };

  const getPhotos = (e) => {
    alert('준비중입니다');
    // setPhotoPopupObj({
    //   //
    //   visible: true,
    //   title: '발주',
    //   mainSeq: 'purchaseSeq',
    //   item: e.item,
    //   searchObj: e.item.searchObj,
    // });
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'PURCHASESERIALUPLOADFORM') {
    } else {
      alert('준비중입니다!');
      // handleCheckItems(e);
    }
  };

  const handleCheckItems = (e) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      const id = e.target.id;
      if (id === 'APPROVEPURCHASE') {
        //
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const getPurchaseDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const gridButtonhandler = (e) => {
      const id = e.target.id;
      const items = bottomGridRef.current.getCheckedRowItemsAll();
      if (items?.length > 0) {
        if (id === 'PURCHASESAVEDESCDIFF') saveDiff(items);
        if (id === 'PURCHASE_DETAIL_BARCODEPRINT')
          setBarcodePrintObj({
            visible: true,
            items,
            type: 'detail',
          });
      } else {
        alert('선택된 항목이 존재하지 않습니다.');
      }
    };
    const buttons = printFunctionToBtns(
      gridFunctions?.filter((ele) => !ele?.functionId?.includes('SHOW')),
      gridButtonhandler,
    );
    const data = (await serviceStore.warehouseAction(`purchase/ss?purchaseSeq=${e.item?.purchaseSeq}`, 'GET', null))?.data;
    const rows = data.details.map((row) => {
      return {
        ...e.item,
        ...row,
        ...data,
        e: JSON.stringify(e),
        searchObj: e.item.searchObj,
        printQuantity: 1,
        //
        registerDatetime: data.registerDatetime,
        registerId: data.registerId,
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[data?.centerCode],
        purchaseOrderTypeKr: MASTER_OBJ?.PURCHASE_ORDER_TYPE?.[data?.purchaseOrderType],
        //
        finishDatetime: row.finishDatetime,
        barcode: row.barcode,
        partnerSeq: row.partnerSeq,
        purchaseSeq: row.purchaseSeq,
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],
      };
    });

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '구매입고 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          buttons,
          options: {
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            editable: true,
          },
        },
      ],
    });

    dispatch(setLoading(null));
  };
  // const [printObj, setPrintObj] = useState<IBaljuPrint>();
  const [barcodePrintObj, setBarcodePrintObj] = useState<IPrint>();
  const printBalju = (e: IGrid.ButtonClickEvent) => {
    alert('준비중입니다');
    // setPrintObj({
    //   visible: true,
    //   type: e.dataField === 'confirmPrint' ? 'confirm' : 'model',
    //   data: e.item,
    // });
  };

  const labellingKr = (data: PagingListDTO<PurchaseOrderListSSDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        //
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
        statusKr: MASTER_OBJ?.PO_STATUS?.[row?.status],
        purchaseOrderTypeKr: MASTER_OBJ?.PURCHASE_ORDER_TYPE?.[row.purchaseOrderType],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction(type === 'main' ? 'purchase/list/excel' : 'purchase/list/excel/detail', 'GET', searchObj, null))?.data,
      },
      true,
    );

    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '발주입고' });
    } else {
      detailExcelGridRef.current.setGridData(list);
      detailExcelGridRef.current.exportAsXlsx({ fileName: '발주입고(상세)' });
    }
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: getPurchaseDetail,
      },
    },
    {
      headerText: '사진보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '사진보기',
        onClick: getPhotos,
      },
    },
    {
      headerText: '발주명세',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '프린트',
        onClick: printBalju,
      },
    },
    {
      headerText: '입고명세',
      dataField: 'confirmPrint',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '프린트',
        onClick: printBalju,
      },
    },
    {
      headerText: '입고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '납품번호',
      dataField: 'externalPurchaseNumber',
    },
    {
      headerText: '주문번호',
      dataField: 'externalPurchaseOrderNumber',
    },
    {
      headerText: '견적번호',
      dataField: 'externalQuotationNumber',
    },
    {
      headerText: '주문유형',
      dataField: 'purchaseOrderTypeKr',
    },
    {
      headerText: '주문일',
      dataField: 'purchaseDate',
    },
    {
      headerText: '요구납기일',
      dataField: 'requestDeliveryDate',
    },
    {
      headerText: '입고예정일',
      dataField: 'promiseDate',
    },
    {
      headerText: '입고완료일',
      dataField: 'finishDateTime',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '수량',
      children: [
        {
          headerText: '주문',
          dataField: 'purchaseQuantity',
        },
        {
          headerText: '물류출고',
          dataField: 'shippingQuantity',
        },
        {
          headerText: '스캔',
          dataField: 'confirmQuantity',
        },
        {
          headerText: '입고완료',
          dataField: 'inventoryQuantity',
        },
        {
          headerText: '미입고',
          dataField: 'diffQuantity',
        },
        {
          headerText: '미출',
          dataField: 'notShippingQuantity',
        },
      ],
    },
    {
      headerText: '금액',
      children: [
        {
          headerText: '매입단가',
          dataField: 'purchasePrice',
        },
        {
          headerText: '공급가',
          dataField: 'supplyPrice',
        },
        {
          headerText: '부가세',
          dataField: 'surtax',
        },
        {
          headerText: '합계',
          dataField: 'totalPrice',
        },
      ],
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '입고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '납품번호',
      dataField: 'externalPurchaseNumber',
    },
    {
      headerText: '주문번호',
      dataField: 'externalPurchaseOrderNumber',
    },
    {
      headerText: '견적번호',
      dataField: 'externalQuotationNumber',
    },
    {
      headerText: '주문유형',
      dataField: 'purchaseOrderTypeKr',
    },
    {
      headerText: '주문일',
      dataField: 'purchaseDate',
    },
    {
      headerText: '요구납기일',
      dataField: 'requestDeliveryDate',
    },
    {
      headerText: '입고예정일',
      dataField: 'promiseDate',
    },
    {
      headerText: '입고완료일',
      dataField: 'finishDateTime',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '수량',
      children: [
        {
          headerText: '주문',
          dataField: 'purchaseQuantity',
        },
        {
          headerText: '물류출고',
          dataField: 'shippingQuantity',
        },
        {
          headerText: '스캔',
          dataField: 'confirmQuantity',
        },
        {
          headerText: '입고완료',
          dataField: 'inventoryQuantity',
        },
        {
          headerText: '미입고',
          dataField: 'diffQuantity',
        },
        {
          headerText: '미출',
          dataField: 'notShippingQuantity',
        },
      ],
    },
    {
      headerText: '금액',
      children: [
        {
          headerText: '매입단가',
          dataField: 'purchasePrice',
        },
        {
          headerText: '공급가',
          dataField: 'supplyPrice',
        },
        {
          headerText: '부가세',
          dataField: 'surtax',
        },
        {
          headerText: '합계',
          dataField: 'totalPrice',
        },
      ],
    },
  ];

  const saveDiff = async (items) => {
    alert('준비중입니다!');
    // if (window.confirm('수량차이사유를 수정 하시겠습니까?')) {
    //   const rs = await serviceStore.warehouseAction(
    //     `purchase/update/description`,
    //     'PUT',
    //     null,
    //     items.map((ele: PurchaseOrderDetailDTO) => {
    //       return {
    //         purchaseDetailSeq: ele.purchaseDetailSeq,
    //         description: ele.description,
    //       };
    //     }),
    //   );
    //   if (rs?.status === 200) {
    //     alert('수량차이사유가 저장되었습니다!');
    //   }
    //   const searchObj = JSON.parse(items[0].searchObj);
    //   wrappedFetchList(searchObj);
    //   const e = JSON.parse(items[0].e);
    //   getPurchaseDetail(e);
    // }
  };

  const savePurchaseSerialExcel = async (excelrows, isBe = false) => {
    if (excelrows?.length === 0) {
      alert('추출된 값이 없습니다. 엑셀을 확인해주세요');
    } else {
      let rs = await serviceStore.warehouseAction(isBe ? `purchase/excel/schedule/serial/number/save` : `purchase/excel/serial/number/save`, 'POST', null, excelrows);
      if (rs.status === 200) {
        alert('모든 시리얼번호 등록이 완료되었습니다!');
        wrappedFetchList(searchObj);
      }
    }
  };

  const savePurchaseAll = async (excelrows) => {
    dispatch(setLoading('POST'));
    const externalPurchaseNumberArr = [];
    const externalPurchaseNumberIdx = [];
    const dataDTO = [];
    excelrows.forEach((row, idx) => {
      if (!externalPurchaseNumberArr?.includes(row.externalPurchaseNumber)) {
        externalPurchaseNumberIdx.push(externalPurchaseNumberArr.length);
        dataDTO.push({
          externalPurchaseNumber: row.externalPurchaseNumber,
          centerCode: row.centerCode,
          purchaseDate: row.purchaseDate,
          promiseDate: row.promiseDate,
          logisticType: row.logisticType,
          workType: row.workType,
          memo: row.memo,
          details: [
            {
              partnerSeq: row.partnerSeq,
              barcode: row.barcode,
              skuNumber: row.skuNumber,
              purchaseQuantity: row.purchaseQuantity,
              primePrice: row.primePrice,
              memo: row.memo,
              invoiceSeqs: row.invoiceSeqs ? row.invoiceSeqs?.split(',') : null,
              scheduleSerialNumbers: row.scheduleSerialNumbers ? row.scheduleSerialNumbers?.split(',') : null,
            },
          ],
        });
        externalPurchaseNumberArr?.push(row.externalPurchaseNumber);
      } else {
        dataDTO[externalPurchaseNumberArr.indexOf(row.externalPurchaseNumber)]['details'].push({
          partnerSeq: row.partnerSeq,
          barcode: row.barcode,
          skuNumber: row.skuNumber,
          purchaseQuantity: row.purchaseQuantity,
          primePrice: row.primePrice,
          memo: row.memo,
          invoiceSeqs: row.invoiceSeqs ? row.invoiceSeqs?.split(',') : null,
          scheduleSerialNumbers: row.scheduleSerialNumbers ? row.scheduleSerialNumbers?.split(',') : null,
        });
      }
    });
    const rs = await serviceStore.warehouseAction(`purchase/save/excel`, 'POST', null, dataDTO);
    if (rs.status === 200) {
      alert('엑셀로 발주등록이 완료되었습니다!');
      wrappedFetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  const HEADERLINEIDX = {
    PURCHASESAVEEXCELALLUPLOAD: 1,
  };

  const KRTOVALUE = {
    centerCode: 'CENTER',
    logisticType: 'LOGISTIC_TYPE',
    workType: 'WORK_TYPE',
    partnerSeq: 'SELLER',
  };

  const EXCELHEADER = {
    PURCHASESERIALBEUPLOAD: ['purchaseSeq', 'externalPurchaseNumber', 'barcode', 'serialNumber'],
    PURCHASESERIALUPLOAD: ['purchaseSeq', 'externalPurchaseNumber', 'barcode', 'serialNumber'],
    PURCHASESAVEEXCELALLUPLOAD: [
      'externalPurchaseNumber',
      'centerCode',
      'purchaseDate',
      'promiseDate',
      'logisticType',
      'workType',
      'memo',
      'partnerSeq',
      'barcode',
      'skuNumber',
      'purchaseQuantity',
      'primePrice',
      'memo',
      'invoiceSeqs',
      'scheduleSerialNumbers',
    ],
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE, HEADERLINEIDX });

  useDidMountEffect(() => {
    if (parsedData?.data?.length > 0) {
      if (parsedData?.id?.includes('PURCHASESERIAL')) {
        savePurchaseSerialExcel(parsedData?.data, parsedData?.id?.includes('BE'));
      } else if (parsedData?.id === 'PURCHASESAVEEXCELALLUPLOAD') {
        savePurchaseAll(parsedData?.data);
      }
    }
  }, [parsedData]);

  return (
    <div className="page-content">
      {barcodePrintObj?.visible && <Print printObj={barcodePrintObj} setPrintObj={setBarcodePrintObj} />}
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            editable: true,
            fixedColumnCount: 5,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns.filter((_, idx) => idx > 3)} />
        <PrintGridBox gridRef={detailExcelGridRef} columns={detailColumns} />
      </div>
    </div>
  );
};

export default Index;
