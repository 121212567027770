import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

// utils
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import { DetailPopup, IModelDetailObj } from './component/detailPopup';
// service
import { ModelStockAndModelDTO, ModelStockAndModelSearchDTO } from 'interface/warehouse';
import { modelNproductService } from '../../_services/service';
// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';

// hooks
import useGridButton from 'hooks/grid/useGridButton';
import BunRyuModal from 'components/modal/bunryuModal';
import { PagingListDTO } from 'interface/util';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { MODEL_MOD_FORM, MODEL_UPLOAD_FORM } from 'envVar2';
import { serviceStore } from 'services/services';
import { IPrint, Print } from './component/print';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [detailObj, setDetailObj] = useState<IModelDetailObj>();
  const [bunryuVisible, setBunryuVisible] = useState<boolean>(false);

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  const popupModelDetail = (e) => {
    setDetailObj({
      visible: true,
      item: e.item,
      searchObj: e.item.searchObj,
      fetchFn: (searchObj: ModelStockAndModelSearchDTO) => wrappedFetchList(searchObj),
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupModelDetail,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.MODEL_TAB_SHOWDETAIL;
        },
      },
    },
    {
      headerText: '사용여부',
      dataField: 'useFlagKr',
      width: 50,
    },
    {
      headerText: '단종여부',
      dataField: 'stopFlagKr',
      width: 50,
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '대표상품코드',
      dataField: 'productCode',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '시리얼<br/>여부',
      dataField: 'serialNumberFlagKr',
      width: 50,
    },
    {
      headerText: '로케이션<br/>여부',
      dataField: 'availableLocationFlagKr',
      width: 50,
    },
    {
      headerText: 'MTO<br/>여부',
      dataField: 'mtoFlagKr',
      width: 50,
    },
    {
      headerText: 'LOT번호(외부바코드)',
      children: [
        {
          headerText: '여부',
          width: 50,
          dataField: 'externalBarcodeFlagKr',
        },
        {
          headerText: '번호',
          dataField: 'externalBarcode',
        },
      ],
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '브랜드',
      dataField: 'brandKr',
    },
    {
      headerText: '취급주의<br/>여부',
      dataField: 'handleWithCareFlagKr',
    },
    {
      headerText: '제품타입',
      dataField: 'modelTypeKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '대분류',
      dataField: 'modelGroupLargeKr',
    },
    {
      headerText: '중분류',
      dataField: 'modelGroupMediumKr',
    },
    {
      headerText: '소분류',
      dataField: 'modelGroupSmallKr',
    },
    {
      headerText: '제품크기 가로',
      dataField: 'modelWidth',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '제품크기 세로',
      dataField: 'modelDepth',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '제품크기 높이',
      dataField: 'modelHeight',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '박스크기 가로',
      dataField: 'boxWidth',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '박스크기 세로',
      dataField: 'boxDepth',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '박스크기 높이',
      dataField: 'boxHeight',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '무게',
      dataField: 'modelWeight',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '용량',
      dataField: 'modelDivisionKr',
    },
    {
      headerText: '파렛트 유형',
      dataField: 'palletTypeKr',
    },
    {
      headerText: '파렛트 적재가능수',
      dataField: 'palletCapacity',
    },
    {
      headerText: '구매단가',
      dataField: 'purchasePrice',
      dataType: 'numeric',
      formatString: '#,##0',
      postfix: '원',
    },
    {
      headerText: '기준대행료',
      dataField: 'standardFee',
      dataType: 'numeric',
      formatString: '#,##0',
      postfix: '원',
    },
    {
      headerText: '박스별 보관료',
      dataField: 'boxStorageFee',
      dataType: 'numeric',
      formatString: '#,##0',
      postfix: '원',
    },
    {
      headerText: '파렛트별 보관료',
      dataField: 'palletStorageFee',
      dataType: 'numeric',
      formatString: '#,##0',
      postfix: '원',
    },
    {
      headerText: '설치팀 유형(인)',
      dataField: 'memberCountKr',
    },
    {
      headerText: '설치소요시간(분)',
      dataField: 'installDurationKr',
      dataType: 'numeric',
      formatString: '#,##0',
    },
  ];

  const labellingKr = (data: PagingListDTO<ModelStockAndModelDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        brandKr: MASTER_OBJ?.BRAND?.[row.brand],
        supplierPartnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.supplierPartnerSeq],
        modelTypeKr: MASTER_OBJ?.MODEL_TYPE?.[row.modelType],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row.modelGroup],
        modelGroupLargeKr: MASTER_OBJ?.MODEL_GRP_1?.[row.modelGroupLarge],
        modelGroupMediumKr: MASTER_OBJ?.MODEL_GRP_2?.[row.modelGroupMedium],
        modelGroupSmallKr: MASTER_OBJ?.MODEL_GRP_3?.[row.modelGroupSmall],
        modelDivisionKr: MASTER_OBJ?.MODEL_GRP_DIV?.[row.modelDivision],
        palletTypeKr: MASTER_OBJ?.PALLET_TYPE?.[row.palletType],
        installDurationKr: MASTER_OBJ?.INSTALL_DURATION?.[row.installDuration],
        memberCountKr: MASTER_OBJ?.TEAM_TYPE?.[row.memberCount],
        handleWithCareFlagKr: row.handleWithCareFlag === true ? 'O' : 'X',
        availableLocationFlagKr: row.availableLocationFlag === true ? 'O' : row.availableLocationFlag === false ? 'X' : '',
        serialNumberFlagKr: row.serialNumberFlag === true ? 'O' : row.serialNumberFlag === false ? 'X' : '',
        externalBarcodeFlagKr: row.externalBarcodeFlag === true ? 'O' : row.externalBarcodeFlag === false ? 'X' : '',
        mtoFlagKr: row.mtoFlag === true ? 'O' : row.mtoFlag === false ? 'X' : '',
        stopFlagKr: row.stopFlag === true ? 'O' : row.stopFlag === false ? 'X' : '',
        useFlagKr: row.useFlag === true ? 'O' : row.useFlag === false ? 'X' : '',
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: ModelStockAndModelSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.warehouseAction(`model/stock/paging`, 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      ...defaultSearchFilter,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: await modelNproductService.getModelMainExcel(searchObj) }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '제품관리' });

    dispatch(setLoading(null));
  };

  const [barcodePrintObj, setBarcodePrintObj] = useState<IPrint>();
  const printModalOpen = (items) => {
    setBarcodePrintObj({
      visible: true,
      items,
    });
  };

  const handleCheckItems = (id) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (id === 'MODEL_TAB_LABEL_PRINT') {
      printModalOpen(items);
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'MODELREGISTER') {
      setDetailObj({
        visible: true,
        item: null,
        searchObj: JSON.stringify(searchObj),
        fetchFn: (searchObj: ModelStockAndModelSearchDTO) => wrappedFetchList(searchObj),
      });
    } else if (id === 'MODELEXCEL') {
      downloadExcel('main');
    } else if (id === 'MODELUPLOADFORM') {
      window.open(MODEL_UPLOAD_FORM);
    } else if (id === 'MODELMODIUPLOADFORM') {
      window.open(MODEL_MOD_FORM);
    } else if (id === 'MODELUPLOAD' || id === 'MODELMODIUPLOAD') {
      uploaderClick(id);
    } else if (id === 'MODELGROUPMANAGE') {
      setBunryuVisible(true);
    } else {
      handleCheckItems(id);
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const KRTOVALUE = {
    supplierPartnerSeq: 'SELLER_WHOLE',
    partnerSeq: 'SELLER_WHOLE',
    brand: 'BRAND',
    modelGroup: 'MODEL_GROUP',
    modelType: 'MODEL_TYPE',
    modelGroupLarge: 'MODEL_GRP_1',
    modelGroupMedium: 'MODEL_GRP_2',
    modelGroupSmall: 'MODEL_GRP_3',
    modelDivision: 'MODEL_GRP_DIV',
    boxStorageFeeUnit: 'STORAGE_FEE',
    palletStorageFeeUnit: 'STORAGE_FEE',
    memberCount: 'TEAM_TYPE',
    installDuration: 'INSTALL_DURATION',
    boxStorageFee: 'isNumber',
    palletStorageFee: 'isNumber',
    useFlag: 'ACTIVE_FLAG',
    stopFlag: 'STOP_FLAG',
    palletType: 'PALLET_TYPE',
    serialNumberFlag: 'MANAGE_FLAG',
    externalBarcodeFlag: 'MANAGE_FLAG',
  };

  const EXCELHEADER = {
    MODELUPLOAD: [
      'supplierPartnerSeq',
      'partnerSeq',
      'brand',
      'modelGroup',
      'modelType',
      'model',
      'modelName',
      'barcode',
      'serialNumberFlag',
      //
      'externalBarcodeFlag',
      'externalBarcode',
      'serialNumberLength',
      'skuNumber',
      'modelGroupLarge',
      'modelGroupMedium',
      'modelGroupSmall',
      'modelWidth',
      'modelDepth',
      'modelHeight',
      'boxWidth',
      'boxDepth',
      'boxHeight',
      'modelWeight',
      'modelDivision',
      'shelfLife',
      'palletType',
      'palletCapacity',
      'sellPrice',
      'purchasePrice',
      'boxStorageFeeUnit',
      'boxStorageFee',
      'palletStorageFeeUnit',
      'palletStorageFee',
      'memberCount',
      'installDuration',
      'purchaseFlag',
      //
      'availableLocationFlag',
      'mtoFlag',
      'defectedLocationFlag',
      //
      'availableLineFlag',
      'availableRemoveFlag',
      'availableLoanFlag',
      'availableCollectFlag',
      'availableInvoiceInstallFlag',
      'availableInvoiceCollectFlag',
      'defectedLineFlag',
      'defectedRemoveFlag',
      'defectedCollectFlag',
      'defectedInvoiceCollectFlag',
      'etcLineFlag',
      'etcLoanFlag',
      'etcCollectFlag',
      'etcInvoiceInstallFlag',
      'etcInvoiceCollectFlag',
    ],
    MODELMODIUPLOAD: [
      //
      'partnerSeq',
      'barcode',
      'modelStockSeq',
      'useFlag',
      'stopFlag',
      'brand',
      'modelGroup',
      'modelType',
      'handleWithCareFlag',
      'modelGroupLarge',
      'modelGroupMedium',
      'modelGroupSmall',
      'model',
      'modelName',
      'memberCount',
      'installDuration',
      'skuNumber',
      //
      'modelWidth',
      'modelDepth',
      'modelHeight',
      'modelDivision',
      'modelWeight',
      //
      'shelfLife',
      'palletType',
      'sellPrice',
      'purchasePrice',
      'palletCapacity',
      'boxWidth',
      'boxDepth',
      'boxHeight',
      //
      'boxStorageFeeUnit',
      'boxStorageFee',
      'palletStorageFeeUnit',
      'palletStorageFee',
      //
      'serialNumberFlag',
      'externalBarcodeFlag',
      'externalBarcode',
      'serialNumberLength',
      //
      'purchaseFlag',
      'availableRemoveFlag',
      'availableInvoiceInstallFlag',
      'defectedRemoveFlag',
      'defectedCollectFlag',
      'availableLocationFlag',
      'mtoFlag',
      'defectedLocationFlag',
      'productCode',
    ],
  };

  const HEADERLINEIDX = {
    MODELUPLOAD: 0,
    MODELMODIUPLOAD: 2,
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE, HEADERLINEIDX });

  const uploadModi = async (excelData) => {
    const dataDTO = excelData?.map((row) => {
      const obj = {
        ...row,
        boxStorageDayFee: row.boxStorageFeeUnit === 'DAY' ? row?.boxStorageFee : 0,
        boxStorageMonthFee: row.boxStorageFeeUnit === 'MONTH' ? row?.boxStorageFee : 0,
        palletStorageDayFee: row.palletStorageFeeUnit === 'DAY' ? row?.palletStorageFee : 0,
        palletStorageMonthFee: row.palletStorageFeeUnit === 'MONTH' ? row?.palletStorageFee : 0,
      };
      return obj;
    });
    const rs = await serviceStore.warehouseAction(`model/update/excel`, 'POST', null, dataDTO);
    if (rs?.status === 200) {
      alert(rs?.data?.message ?? `제품 등록에 성공하였습니다!`);
      wrappedFetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  useEffect(() => {
    if (parsedData) {
      if (parsedData?.data?.length > 0) {
        if (parsedData?.id === 'MODELUPLOAD') excelSaveModel(parsedData?.data);
        else if (parsedData?.id === 'MODELMODIUPLOAD') {
          uploadModi(parsedData?.data);
        }
      } else {
        alert('읽혀진 값이 없습니다');
      }
    }
  }, [parsedData]);
  const excelSaveModel = async (dataDTO) => {
    dispatch(setLoading('GET'));
    const data = {
      add: dataDTO,
    };
    const rs = await modelNproductService.saveModelDetail(data);
    if (rs?.status === 200) {
      alert(rs?.data?.message ?? `제품 등록에 성공하였습니다!`);
      wrappedFetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  return (
    <div className="page-content">
      {barcodePrintObj?.visible && <Print printObj={barcodePrintObj} setPrintObj={setBarcodePrintObj} />}
      {bunryuVisible && <BunRyuModal visible={bunryuVisible} setVisible={setBunryuVisible} />}
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            fixedColumnCount: 1,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
