import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './manageService';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';

export const ORDERSERVICE_MASTER = [
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_AUTH',
  'SELLER_WHOLE',
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(ORDERSERVICE_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            //
            MANAGESERVICE_TAB1: <Tab1 tabId={'MANAGESERVICE_TAB1'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
