import { useState, useEffect, ReactNode } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import { TopTab } from 'components/template/topTabNew';
import Tab1 from './inoutList';

// options
import { centerListForlabeling, codeMasterOptionReturn, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { useLocation } from 'react-router-dom';
import { TabDTO } from 'interface/user';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Index = () => {
  const location = useLocation();
  const [tabs, setTabs] = useState<TabDTO[]>();
  const [tabItems, setTabItems] = useState<{ titles?: string[]; containers: ReactNode[] }>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { useableTabs } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    const [PARTNER_SELLER_WHOLE, CENTER_SELECT_WHOLE, MODEL_GROUP] = await Promise.all([
      //
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling(),
      codeMasterOptionReturn('MODEL_GROUP'),
    ]);

    dispatch(
      setMasterOptions({
        SELLER: PARTNER_SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        MODEL_GROUP,
      }),
    );
  };

  const containerList = [{ container: <Tab1 tabId={'LOCATIONINOUT'} />, tabId: 'LOCATIONINOUT' }];

  useEffect(() => {
    if (tabs) {
      const tabIds = tabs?.map((ele) => ele.tabId);
      const tabLabeling = tabs?.map((ele) => {
        return {
          value: ele.tabId,
          label: ele.tabName,
        };
      });

      setTabItems({
        titles: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => selectlabel(ele.tabId, tabLabeling)),
        containers: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => ele.container),
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (useableTabs) {
      const tabDTO = useableTabs?.[location.pathname] ? [...useableTabs?.[location.pathname]] : [];
      if (tabDTO?.length > 0) {
        tabDTO?.sort((a: TabDTO, b: TabDTO) => a?.sort - b?.sort);
        setTabs(tabDTO);
        initMasterOptions();
      }
    }
  }, [useableTabs]);

  return <>{masterOptions && tabItems && <TopTab titles={tabItems?.titles} containers={tabItems?.containers} />}</>;
};

export default Index;
