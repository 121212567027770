import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import useToast from 'hooks/useToast';
import { serviceStore } from 'services/services';
import { Print } from './component/print';
import styled from 'styled-components';
import { ParcelInvoiceTempDTO } from 'pages/PARCEL/_interface/parcel';

const PrintBarcode100123 = styled.div({
  position: 'relative',
  paddingRight: 0,
  paddingLeft: 0,
  width: '123mm',
  height: '100mm',
  maxWidth: '123mm',
  maxHeight: '100mm',
});

const Index = ({ tabId }) => {
  const [inputs, setInputs] = useState<{ parcelInvoiceNumber: string }>();
  const successPlayer = useRef<HTMLAudioElement>();
  const failurePlayer = useRef<HTMLAudioElement>();
  const validatePlayer = useRef<HTMLAudioElement>();
  const scanInputRef = useRef<HTMLInputElement>(null);
  const { Toast, displayToast, hideToast, setColor, setMsg } = useToast({
    message: '',
    ms: 3000,
  });
  useEffect(() => {
    scanInputRef?.current?.focus();
  }, []);

  const [printList, setPrintList] = useState<{ data: ParcelInvoiceTempDTO; status: '대기' | '완료' }[]>();
  const scanRight = (str = 'SUCCESS', data: ParcelInvoiceTempDTO) => {
    successPlayer.current.play();
    setColor('green');
    setMsg(str);
    displayToast();
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        parcelInvoiceNumber: null,
      };
    });
    const _printList = printList ? JSON.parse(JSON.stringify(printList)) : [];
    _printList?.push({ data, status: '완료' });
    _printList?.reverse();
    setPrintList(_printList);
  };

  const scanError = (str = 'ERROR') => {
    failurePlayer.current.play();
    setColor('red');
    setMsg(str);
    displayToast();
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        parcelInvoiceNumber: null,
      };
    });
    scanInputRef?.current?.focus();
  };

  const getParcelInfo = async (searchDTO) => {
    const rs = await serviceStore.parcelAction(`invoice/temp`, 'GET', searchDTO);
    console.log(rs);
    if (rs?.status === 200) {
      scanRight(searchDTO?.parcelInvoiceNumber, rs?.data);
    } else {
      scanError();
    }
  };

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      if (inputs?.parcelInvoiceNumber) {
        getParcelInfo(inputs);
      }
    }
  };

  return (
    <div className="page-content">
      <div style={{ display: 'flex', paddingTop: 50 }}>
        <div style={{ maxWidth: 500, marginBottom: 200 }}>
          <div style={{ position: 'relative', marginBottom: 50 }}>
            <Toast />
          </div>
          <Form onKeyDown={onKeyPress}>
            <Row>
              <Col>
                <label className="col-form-label">*운송장번호</label>
                <input
                  ref={scanInputRef}
                  value={inputs?.parcelInvoiceNumber || ''}
                  className="form-control backgroundYellow"
                  placeholder="운송장번호를 스캔하세요"
                  onChange={(e) => {
                    setInputs((prev) => {
                      return {
                        ...prev,
                        parcelInvoiceNumber: e.target.value.substring(0, 13),
                      };
                    });
                  }}
                />
              </Col>
            </Row>
          </Form>
          <div className="grid-button-area space-between" style={{ marginTop: 30 }}></div>
          <table className="border-table noBorder fontSize10" style={{ fontSize: 10, marginTop: 0 }}>
            <thead>
              <tr>
                <th>순번</th>
                <th colSpan={12}>운송장번호</th>
                <th colSpan={2}>출력상태</th>
              </tr>
            </thead>
          </table>
          <table className="border-table noBorder">
            <tbody>
              {printList?.map(({ data, status }, idx) => {
                return (
                  <tr key={`today_${idx}`}>
                    <th>{printList?.length - idx}</th>
                    <th colSpan={12}>{data?.parcelInvoiceNumber}</th>
                    <th colSpan={2}>{status}</th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Print scanInputRef={scanInputRef} data={printList?.[0]?.data} />
      </div>
      <div className="audioWrapper">
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_sound.mp3" ref={successPlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_fail_sound.mp3" ref={failurePlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/surprise-sound.mp3" ref={validatePlayer} controls></audio>
      </div>
    </div>
  );
};

export default Index;
