import { ReactNode, useMemo, useRef, useState } from 'react';
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';

import AUIGrid from 'modules/Grid';
// service
import { inventoryService } from '../../_service/service';

import * as IGrid from 'aui-grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import { PagingListDTO } from 'interface/util';
import { InventoryLiveDTO, InventoryLiveSearchDTO } from 'interface/warehouse';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { getDateFromToday } from 'common/util/dateParsingFn';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { InventoryReportsCommonDefaultSearchFilters } from '..';
import useSearchGridPagingNew from 'hooks/grid/useGridPagingNew';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const searchObj = useSelector((state: RootState) => state.tab)?.searchObj?.[tabId];
  const defaultSearchFilter = useMemo(() => {
    return {
      ...InventoryReportsCommonDefaultSearchFilters,
      registerDate: getDateFromToday(-1),
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    };
  }, [reduxUserInfo]);

  const fetchTotal = async (searchObj: InventoryLiveSearchDTO) => {
    const total = await inventoryService.getInventorySalesMagamTotal(searchObj);
    return total;
  };

  const labellingKr = (data: PagingListDTO<InventoryLiveDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        registerDate: searchObj?.registerDate,
        customerTotal: row.move + row.repair,
        centerCodeKr: row.centerCode ? selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE) : '전체',
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
        modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
        purchaseFlag: row.purchaseFlag ? 'O' : 'X',
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryLiveSearchDTO) => {
    dispatch(setLoading('GET'));
    setBottomSheetObj(null);
    const dataKr = labellingKr(await inventoryService.getInventorySalesMagamPaging(searchObj));

    dispatch(setLoading(null));
    return dataKr;
  };

  const { initFetch, handleSearchObj } = useSearchGridPagingNew({
    tabId,
    gridRef,
    fetchTotal,
    fetchPaging,
    initialSearchObj: {
      ...defaultSearchFilter,
      ...searchObj,
    },
  });

  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    //
    dispatch(setLoading('GET'));
    const searchObj = JSON.parse(e.item.searchObj);

    const data = await inventoryService.getInventorySalesMagamDailydDetail(e.item.modelStockSeq, searchObj);
    const rows = data?.map((row) => {
      return {
        ...row,
        searchObj: JSON.stringify(searchObj),
        customerTotal: row.move + row.repair,
        registerDate: searchObj?.registerDate,
        centerCodeKr: row.centerCode ? selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE) : '전체',
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
        modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
      };
    });

    const gridButtonhandler = (e) => {
      const id = e.target.id;
      if (id === 'INVENTORY_SALES_BTMSHEET_EXCEL') {
        bottomGridRef.current.exportAsXlsx({ fileName: '일자별_판매_현황(상세)' });
      }
    };

    const buttons = printFunctionToBtns(
      gridFunctions?.filter((ele) => !ele.functionId?.includes('SHOW')),
      gridButtonhandler,
    );
    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '일자별 판매현황 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          buttons,
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
      ],
    });
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetail,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.INVENTORY_SALES_SHOWDETAIL;
        },
      },
    },
    {
      headerText: '마감일자',
      dataField: 'registerDate',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품타입',
          dataField: 'modelTypeKr',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
          width: 280,
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          width: 280,
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '대표상품코드',
          dataField: 'productCode',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
      // end of 1분기 children
    },
    {
      headerText: '전국재고 계',
      dataField: 'total',
      dataType: 'numeric',
      formatString: '#,##0',
      style: 'main-total-color',
    },
    {
      headerText: '양품보관재고',
      children: [
        {
          headerText: '양품보관<br />재고합계',
          dataField: 'storageTotal',
          dataType: 'numeric',
          formatString: '#,##0',
          style: 'sub-total-color',
        },
        {
          headerText: '안전재고',
          children: [
            {
              headerText: '주문대기',
              dataField: 'available',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '피킹할당',
              dataField: 'availableStorageLineAndRemove',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '고객 주문할당',
          children: [
            {
              headerText: '미출고',
              dataField: 'reservedProcess',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '익일기사출고',
              dataField: 'reservedDriver',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '익일간선출고',
              dataField: 'reservedStorageLine',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '약속(D+2 이상)',
              dataField: 'reserved',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
    {
      headerText: '고객보관재고',
      children: [
        {
          headerText: '고객보관<br />재고합계',
          dataField: 'customerTotal',
          dataType: 'numeric',
          formatString: '#,##0',
          style: 'sub-total-color',
        },
        {
          headerText: '이전',
          dataField: 'move',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          headerText: '수리',
          dataField: 'repair',
          dataType: 'numeric',
          formatString: '#,##0',
        },
      ],
    },
    {
      headerText: '이동중 재고',
      children: [
        {
          headerText: '이동재고합계<br />(간선출고+기사배송)',
          dataField: 'deliveryTotal',
          dataType: 'numeric',
          formatString: '#,##0',
          style: 'sub-total-color',
        },
        {
          headerText: '간선입고',
          children: [
            {
              headerText: '안전',
              dataField: 'availableTransitLineIn',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '고객 주문할당',
              dataField: 'reservedTransitLineIn',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '간선출고',
          children: [
            {
              headerText: '안전',
              dataField: 'availableTransitLineOut',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '고객 주문할당',
              dataField: 'reservedTransitLineOut',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '기사배송',
          children: [
            {
              headerText: '배송중',
              dataField: 'driverLoan',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기사회수',
              dataField: 'driverCollect',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
    {
      headerText: '발주여부',
      children: [
        {
          headerText: '기사출고완료 <br>(4주평균)',
          dataField: 'finishAverage',
        },
        {
          headerText: '발주필요재고<br>(판매량 - 주문대기)',
          dataField: 'purchaseRequire',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          headerText: '발주점검필요',
          dataField: 'purchaseFlag',
        },
      ],
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '마감일자',
      dataField: 'registerDate',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품타입',
          dataField: 'modelTypeKr',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },

        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '대표상품코드',
          dataField: 'productCode',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '박스별 보관료',
          dataField: 'boxStorageFee',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          headerText: '파렛트별 보관료',
          dataField: 'palletStorageFee',
          dataType: 'numeric',
          formatString: '#,##0',
        },
      ],
      // end of 1분기 children
    },
    {
      headerText: '전국재고 계',
      dataField: 'total',
      dataType: 'numeric',
      formatString: '#,##0',
      style: 'main-total-color',
    },
    {
      headerText: '양품보관재고',
      children: [
        {
          headerText: '양품보관<br />재고합계',
          dataField: 'storageTotal',
          dataType: 'numeric',
          formatString: '#,##0',
          style: 'sub-total-color',
        },
        {
          headerText: '안전재고',
          children: [
            {
              headerText: '주문대기',
              dataField: 'available',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '피킹할당',
              dataField: 'availableStorageLineAndRemove',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '고객 주문할당',
          children: [
            {
              headerText: '미출고',
              dataField: 'reservedProcess',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '익일기사출고',
              dataField: 'reservedDriver',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '익일간선출고',
              dataField: 'reservedStorageLine',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '약속(D+2 이상)',
              dataField: 'reserved',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
    {
      headerText: '고객보관재고',
      children: [
        {
          headerText: '고객보관<br />재고합계',
          dataField: 'customerTotal',
          dataType: 'numeric',
          formatString: '#,##0',
          style: 'sub-total-color',
        },
        {
          headerText: '이전',
          dataField: 'move',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          headerText: '수리',
          dataField: 'repair',
          dataType: 'numeric',
          formatString: '#,##0',
        },
      ],
    },
    {
      headerText: '이동중 재고',
      children: [
        {
          headerText: '이동재고합계<br />(간선출고+기사배송)',
          dataField: 'deliveryTotal',
          dataType: 'numeric',
          formatString: '#,##0',
          style: 'sub-total-color',
        },
        {
          headerText: '간선입고',
          children: [
            {
              headerText: '안전',
              dataField: 'availableTransitLineIn',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '고객 주문할당',
              dataField: 'reservedTransitLineIn',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '간선출고',
          children: [
            {
              headerText: '안전',
              dataField: 'availableTransitLineOut',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '고객 주문할당',
              dataField: 'reservedTransitLineOut',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '기사배송',
          children: [
            {
              headerText: '배송중',
              dataField: 'driverLoan',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기사회수',
              dataField: 'driverCollect',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
  ];

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const data = type === 'main' ? await inventoryService.getInventorySalesMagamExcelMain(searchObj) : await inventoryService.getInventorySalesMagamExcelDetail(searchObj);
    const { list } = labellingKr({ list: data }, true);
    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '일자별_판매_현황' });
    } else if (type === 'detail') {
      excelDetailGridRef.current.setGridData(list);
      excelDetailGridRef.current.exportAsXlsx({ fileName: '일자별_판매_현황(상세)' });
    }

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'INVENTORY_SALES_EXCELDOWN_MAIN') {
      downloadExcel('main');
    } else if (id === 'INVENTORY_SALES_EXCELDOWN_DETAIL') {
      downloadExcel('detail');
    }
  };
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox handleSearchObj={handleSearchObj} TARGET={tabId} fetchList={initFetch} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area only-right">{functionBtns}</div>}
        <GridBox
          //
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            //
            showFooter: true,
            footerPosition: 'top',
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            fixedColumnCount: 1,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={detailColumns} />
      </div>
    </div>
  );
};

export default Index;
