// react
import { useRef, useState } from 'react';

//components
import Grid from '../../../../components/grid/auiGrid';
import { BAECHA_EXCEL } from 'envVar';

const Presenter = (props) => {
  return (
    <>
      <div className="page-content">
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const GridBox = (props) => {
  const {
    columns = [],
    rows = [],
    setRows,
    gridId,
    saveBaecha,
    getCheckedDataTriggerThisGrid,
    setGetCheckedDataTriggerThisGrid,
    // excel
    baechaExcelUploader,
  } = props;

  const [tmpExcelDataTriggerThisGrid, setTmpExcelDataTriggerThisGrid] = useState();
  const uploadBaechaExcelRef = useRef();

  return (
    <div style={{ width: '100%' }} className="likeCustomPaging">
      {gridId && (
        <>
          <div className="grid-button-area space-between">
            <div>
              <div
                className="blue"
                onClick={() => {
                  // test
                  const _rows = [{}];
                  const __rows = JSON.parse(JSON.stringify(rows));
                  setRows(_rows.concat(__rows));
                }}
              >
                행 추가
              </div>
              <div
                className="orange"
                onClick={() => {
                  const rows = window.AUIGrid.getGridData(`#${gridId}`);
                  saveBaecha(rows);
                }}
              >
                배차 저장
              </div>
              <div
                className="red"
                onClick={() => {
                  setGetCheckedDataTriggerThisGrid({
                    type: 'delete-baecha',
                    id: `#${gridId}`,
                  });
                }}
              >
                배차 삭제
              </div>
              <div
                className="green"
                onDoubleClick={() => {
                  return;
                }}
                onClick={() => {
                  setTmpExcelDataTriggerThisGrid({
                    id: `#${gridId}`,
                    data: rows,
                    title: `배차`,
                  });
                }}
              >
                엑셀다운
              </div>
            </div>
            <div>
              <div
                className="green"
                onDoubleClick={() => {
                  return;
                }}
                onClick={() => {
                  window.open(BAECHA_EXCEL);
                }}
              >
                엑셀양식
              </div>
              <div
                className="green"
                onDoubleClick={() => {
                  return;
                }}
                onClick={() => {
                  uploadBaechaExcelRef?.current.click();
                }}
              >
                <input
                  ref={uploadBaechaExcelRef}
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    console.log(e);
                    baechaExcelUploader(e);
                  }}
                  id="uploadBaechaExcelRef"
                  accept=".xlsx"
                  type="file"
                />
                + 엑셀등록
              </div>
            </div>
          </div>
          <Grid
            id={gridId}
            rows={rows}
            columns={columns}
            tmpExcelDataTriggerThisGrid={tmpExcelDataTriggerThisGrid}
            setTmpExcelDataTriggerThisGrid={setTmpExcelDataTriggerThisGrid}
            getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
            setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            settingOptions={{
              isRowAllCheckCurrentView: true,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              fixedColumnCount: 1,
            }}
          />
        </>
      )}
    </div>
  );
};
