import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OrderExcelTemplateDTO, OrderExcelTemplateSearchDTO } from '../../../_interface/order';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { orderService } from 'pages/ORDER/1order/_services/service';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';
import { Button } from 'reactstrap';

export interface IDetailObj {
  visible: boolean;
  item?: OrderExcelTemplateDTO;
  searchObj?: OrderExcelTemplateSearchDTO;
  fetchFn?: (data: OrderExcelTemplateSearchDTO) => {};
}

interface IDetailPoup {
  detailObj: IDetailObj;
  setDetailObj: React.Dispatch<React.SetStateAction<IDetailObj>>;
}

export const DetailPopup = ({ detailObj, setDetailObj }: IDetailPoup) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [formData, setFormData] = useState<OrderExcelTemplateDTO>();
  const [filtered, setFiltered] = useState<OptionItem[]>();

  useEffect(() => {
    if (detailObj?.item !== null) {
      setFormData(detailObj?.item);
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          externalNumberFlag: false,
          receiverFlag: false,
        };
      });
    }
  }, []);

  useEffect(() => {
    if (formData) {
      const values = Object.values(formData);
      const filtered = MASTER_OPS?.COLS?.filter((ele) => !values.includes(ele.value));
      setFiltered(filtered);
    }
  }, [formData]);

  const validate = (formData: OrderExcelTemplateDTO) => {
    let noData = MASTER_OPS['COLS_' + formData?.excelType].filter((ele) => {
      return ele.label?.includes('(필)') && !formData?.[ele.value];
    });
    if (formData?.externalNumberFlag) {
      noData = noData.filter((ele) => ele.value !== 'externalInvoiceNumber');
    }

    if (formData?.receiverFlag) {
      noData = noData.filter((ele) => !ele.value?.includes('receiver'));
    }

    if (noData?.length > 0) {
      alert(`${noData[0].label}는 필수값입니다!`);
      return false;
    }

    return true;
  };

  const saveTemplate = async (formData: OrderExcelTemplateDTO) => {
    if (validate(formData)) {
      if (window.confirm(`${detailObj?.item ? '수정' : '생성'}하시겠습니까?`)) {
        dispatch(setLoading('PUT'));
        const rs = await orderService.saveUploadForm({ [detailObj?.item ? 'update' : 'add']: formData });
        if (rs?.status === 200) {
          alert(`${detailObj?.item ? '수정' : '생성'} 되었습니다!`);
          if (detailObj?.searchObj && detailObj?.fetchFn) {
            detailObj?.fetchFn(detailObj?.searchObj);
            setDetailObj(null);
          }
        }
        dispatch(setLoading(null));
      }
    }
  };

  return (
    <CommonModalNew
      style={{ width: 1000 }}
      title={detailObj?.item ? `양식 상세` : '양식 등록'}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      rightTitle={
        <>
          <Button
            className="btn btn-orange ssm"
            onClick={() => {
              saveTemplate(formData);
            }}
          >
            저장
          </Button>
        </>
      }
      children={
        <div style={{ minHeight: 600 }}>
          <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
            <thead>
              <tr>
                <th colSpan={12}>기본정보</th>
              </tr>
              <tr>
                <th colSpan={2}>양식 번호</th>
                <td colSpan={2}>{formData?.customerOrderExcelTemplateSeq || 'new'}</td>
                <th colSpan={2}>*판매사</th>
                <td colSpan={2}>
                  <SelectD
                    options={MASTER_OPS?.SELLER_AUTH}
                    value={
                      formData?.partnerSeq
                        ? {
                            value: formData?.partnerSeq,
                            label: MASTER_OBJ?.SELLER_AUTH?.[formData?.partnerSeq],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setFormData((prev) => {
                        return {
                          ...prev,
                          partnerSeq: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </td>
                <th colSpan={2}>*엑셀 유형</th>
                <td colSpan={2}>
                  <SelectD
                    options={MASTER_OPS?.ORDER_EXCEL_TYPE}
                    value={
                      formData?.excelType
                        ? {
                            value: formData?.excelType,
                            label: MASTER_OBJ?.ORDER_EXCEL_TYPE?.[formData?.excelType],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setFormData((prev) => {
                        return {
                          ...prev,
                          excelType: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </td>
              </tr>
            </thead>
          </table>

          <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
            <thead>
              <tr>
                <th colSpan={12}>엑셀 위치정보(열)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  판매사
                  <br />
                  (고정)
                </th>
                <td colSpan={2}>
                  <InputD readOnly disabled value={'A'} />
                </td>
                <th colSpan={3}></th>
                <th colSpan={3}>
                  <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <span>
                      외부주문번호와 <br /> 외부송장번호 동일
                    </span>
                    <input
                      type="checkbox"
                      checked={formData?.externalNumberFlag || false}
                      onChange={() => {
                        console.log(formData?.externalNumberFlag);
                        setFormData((prev) => {
                          return {
                            ...prev,
                            externalNumberFlag: formData.externalNumberFlag ? false : true,
                          };
                        });
                      }}
                    />
                  </div>
                </th>
                <th colSpan={3}>
                  <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <span>
                      주문자 정보와 <br /> 실수령자 정보 동일
                    </span>
                    <input
                      type="checkbox"
                      checked={formData?.receiverFlag || false}
                      onChange={() => {
                        console.log(formData?.receiverFlag);
                        setFormData((prev) => {
                          return {
                            ...prev,
                            receiverFlag: formData.receiverFlag ? false : true,
                            receiverName: null,
                            receiverPhone: null,
                            receiverAddr: null,
                            receiverZipcode: null,
                          };
                        });
                      }}
                    />
                  </div>
                </th>
              </tr>
              {MASTER_OPS['COLS_' + formData?.excelType]?.map((ele, idx) => {
                if (idx / 4 >= 1 && idx % 4 === 0)
                  return (
                    <tr key={`${ele.value}_${idx}`}>
                      <th>{MASTER_OPS['COLS_' + formData?.excelType][idx - 3]['label']}</th>
                      <td colSpan={2}>
                        {MASTER_OPS['COLS_' + formData?.excelType][idx - 3]['value'] && (
                          <SelectD
                            isDisabled={
                              (formData?.externalNumberFlag === true && MASTER_OPS['COLS_' + formData?.excelType][idx - 3]['value'] === 'externalInvoiceNumber') ||
                              (formData?.receiverFlag && MASTER_OPS['COLS_' + formData?.excelType][idx - 3]['value']?.includes('receiver'))
                            }
                            options={filtered}
                            value={
                              formData?.[MASTER_OPS['COLS_' + formData?.excelType][idx - 3]['value']]
                                ? {
                                    value: formData?.[MASTER_OPS['COLS_' + formData?.excelType][idx - 3]['value']],
                                    label: MASTER_OBJ?.COLS?.[formData?.[MASTER_OPS['COLS_' + formData?.excelType][idx - 3]['value']]],
                                  }
                                : null
                            }
                            onChange={(option) => {
                              setFormData((prev) => {
                                return {
                                  ...prev,
                                  [MASTER_OPS['COLS_' + formData?.excelType][idx - 3]['value']]: (option as OptionItem).value,
                                };
                              });
                            }}
                          />
                        )}
                      </td>
                      <th>{MASTER_OPS['COLS_' + formData?.excelType][idx - 2]['label']}</th>
                      <td colSpan={2}>
                        {MASTER_OPS['COLS_' + formData?.excelType][idx - 2]['value'] && (
                          <SelectD
                            options={filtered}
                            isDisabled={
                              (formData?.externalNumberFlag === true && MASTER_OPS['COLS_' + formData?.excelType][idx - 2]['value'] === 'externalInvoiceNumber') ||
                              (formData?.receiverFlag && MASTER_OPS['COLS_' + formData?.excelType][idx - 2]['value']?.includes('receiver'))
                            }
                            value={
                              formData?.[MASTER_OPS['COLS_' + formData?.excelType][idx - 2]['value']]
                                ? {
                                    value: formData?.[MASTER_OPS['COLS_' + formData?.excelType][idx - 2]['value']],
                                    label: MASTER_OBJ?.COLS?.[formData?.[MASTER_OPS['COLS_' + formData?.excelType][idx - 2]['value']]],
                                  }
                                : null
                            }
                            onChange={(option) => {
                              setFormData((prev) => {
                                return {
                                  ...prev,
                                  [MASTER_OPS['COLS_' + formData?.excelType][idx - 2]['value']]: (option as OptionItem).value,
                                };
                              });
                            }}
                          />
                        )}
                      </td>
                      <th>{MASTER_OPS['COLS_' + formData?.excelType][idx - 1]['label']}</th>
                      <td colSpan={2}>
                        {MASTER_OPS['COLS_' + formData?.excelType][idx - 1]['value'] && (
                          <SelectD
                            options={filtered}
                            isDisabled={
                              (formData?.externalNumberFlag === true && MASTER_OPS['COLS_' + formData?.excelType][idx - 1]['value'] === 'externalInvoiceNumber') ||
                              (formData?.receiverFlag && MASTER_OPS['COLS_' + formData?.excelType][idx - 1]['value']?.includes('receiver'))
                            }
                            value={
                              formData?.[MASTER_OPS['COLS_' + formData?.excelType][idx - 1]['value']]
                                ? {
                                    value: formData?.[MASTER_OPS['COLS_' + formData?.excelType][idx - 1]['value']],
                                    label: MASTER_OBJ?.COLS?.[formData?.[MASTER_OPS['COLS_' + formData?.excelType][idx - 1]['value']]],
                                  }
                                : null
                            }
                            onChange={(option) => {
                              setFormData((prev) => {
                                return {
                                  ...prev,
                                  [MASTER_OPS['COLS_' + formData?.excelType][idx - 1]['value']]: (option as OptionItem).value,
                                };
                              });
                            }}
                          />
                        )}
                      </td>
                      <th>{MASTER_OPS['COLS_' + formData?.excelType][idx]['label']}</th>
                      <td colSpan={2}>
                        {MASTER_OPS['COLS_' + formData?.excelType][idx]['value'] && (
                          <SelectD
                            options={filtered}
                            isDisabled={
                              (formData?.externalNumberFlag === true && MASTER_OPS['COLS_' + formData?.excelType][idx]['value'] === 'externalInvoiceNumber') ||
                              (formData?.receiverFlag && MASTER_OPS['COLS_' + formData?.excelType][idx]['value']?.includes('receiver'))
                            }
                            value={
                              formData?.[MASTER_OPS['COLS_' + formData?.excelType][idx]['value']]
                                ? {
                                    value: formData?.[MASTER_OPS['COLS_' + formData?.excelType][idx]['value']],
                                    label: MASTER_OBJ?.COLS?.[formData?.[MASTER_OPS['COLS_' + formData?.excelType][idx]['value']]],
                                  }
                                : null
                            }
                            onChange={(option) => {
                              setFormData((prev) => {
                                return {
                                  ...prev,
                                  [MASTER_OPS['COLS_' + formData?.excelType][idx]['value']]: (option as OptionItem).value,
                                };
                              });
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  );
              })}
            </tbody>
          </table>
        </div>
      }
    />
  );
};
