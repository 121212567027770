import { ForwardedRef } from 'react';
import AUIGrid, { mappingDefaultOptionToColumnForExcel } from '../../modules/Grid';
import * as IGrid from 'aui-grid';
import { v4 as uuidv4 } from 'uuid';
export interface IGridObj {
  gridId: string | null;
  rows: any[] | null;
}

export const defaultGridProps = {
  height: 100,
  showRowNumColumn: false,
};

interface IGridProps {
  gridRef: ForwardedRef<AUIGrid>;
  columns: IGrid.Column[];
  gridProps?: IGrid.Props;
}

const PrintGridBox: React.FC<IGridProps> = ({ gridRef, columns, gridProps }) => {
  return (
    <div style={{ display: 'none' }}>
      <AUIGrid ref={gridRef} name={`printGrid-${uuidv4()}`} columnLayout={mappingDefaultOptionToColumnForExcel(columns)} gridProps={{ ...defaultGridProps, ...gridProps }} />
    </div>
  );
};

export default PrintGridBox;
