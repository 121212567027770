import { useEffect, useRef } from 'react';
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import GridBox from 'common/grid/gridBox';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { InventoryDetailInfoSearchDTO } from 'interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';

export interface IInfoDetailObj {
  visible?: boolean;
  e?: IGrid.ButtonClickEvent;
}

export const InfoDetail = ({ infoDetailObj, setInfoDetailObj }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();
  const { masterOptions } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    fetchDetailInfo(infoDetailObj?.e);
  }, []);

  const fetchDetailInfo = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const dataField = e?.dataField;
    const item = e?.item;
    const searchDTO: InventoryDetailInfoSearchDTO = {
      ...JSON.parse(item?.searchObj),
      centerCode: item.centerCode,
      modelStockSeq: item.modelStockSeq,
      [dataField + 'Flag']: true,
    };
    const data = (await serviceStore.warehouseAction(`inventory/live/model/info`, 'GET', searchDTO, null))?.data;
    if (data) {
      const rows = data?.map((row) => {
        return {
          ...row,
          availableStatusKr: selectlabel(row.availableStatus, masterOptions?.INVA_STATUS),
          availableTypeKr: selectlabel(row.availableType, masterOptions?.INVA_TYPE),
          moveStatusKr: selectlabel(row.moveStatus, masterOptions?.INVM_STATUS),
          driverCenterCodeKr: selectlabel(row.driverCenterCode, masterOptions?.CENTER_WHOLE),
          originalCenterCodeKr: selectlabel(row.originalCenterCode, masterOptions?.CENTER_WHOLE),
          destinationCenterCodeKr: selectlabel(row.destinationCenterCode, masterOptions?.CENTER_WHOLE),
          feeTypeKr: selectlabel(row.feeType, masterOptions?.FEE_TYPE),
        };
      });
      gridRef.current.setGridData(rows);
    }
    dispatch(setLoading(null));
  };

  const excelDownload = () => {
    gridRef.current.exportAsXlsx({ fileName: '재고정보' });
  };

  const columns = [
    {
      headerText: '재고번호',
      dataField: 'inventorySeq',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
      headerTooltip: {
        show: true,
        tooltipHtml: '<div style="width:200px;"><div>시리얼번호는 시리얼번호 및 로케이션 관리 재고인 경우에만 존재합니다.</div></div>',
      },
    },
    {
      headerText: '재고상태',
      dataField: 'availableStatusKr',
    },
    {
      headerText: '재고유형',
      dataField: 'availableTypeKr',
    },
    {
      headerText: '이동상태',
      dataField: 'moveStatusKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '수령인',
      dataField: 'receiverName',
    },
    {
      headerText: '수령인 연락처',
      dataField: 'receiverPhone',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '배송기사',
      dataField: 'driverName',
    },
    {
      headerText: '기사소속센터',
      dataField: 'driverCenterCodeKr',
    },
    {
      headerText: '지정일',
      dataField: 'deliveryDate',
    },
    {
      headerText: '간선피킹번호',
      dataField: 'linehaulSeq',
    },
    {
      headerText: '반출피킹번호',
      dataField: 'stockRemoveSeq',
    },
    {
      headerText: '출고번호',
      dataField: 'loanComponentSeq',
    },
    {
      headerText: '운송번호',
      dataField: 'transportStatementSeq',
    },
    {
      headerText: 'FC피킹번호',
      dataField: 'loanPickSeq',
    },
    {
      headerText: '기사출고번호',
      dataField: 'loanComponentSeq',
    },
    {
      headerText: '자재출고번호',
      dataField: 'loanSeq',
    },
    {
      headerText: '출발센터',
      dataField: 'originalCenterCodeKr',
    },
    {
      headerText: '도착센터',
      dataField: 'destinationCenterCodeKr',
    },
    {
      headerText: '입고예정일',
      dataField: 'promiseUnloadDate',
    },
  ];

  return (
    <CommonModalNew
      //
      title={'재고정보'}
      style={{ width: 1200 }}
      visible={infoDetailObj?.visible}
      setVisible={() => setInfoDetailObj(null)}
      children={
        <>
          <div className="grid-button-area">
            <div className="green" id="add-child" onClick={excelDownload}>
              엑셀다운
            </div>
          </div>
          <GridBox
            gridRef={gridRef}
            columns={columns}
            gridProps={{
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
            }}
          />
        </>
      }
    />
  );
};
