import { useEffect, useRef, useState } from 'react';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { addMASTER, setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { MenuDTO, MenuSearchDTO, ScreenDTO } from 'interface/user';
import { Col, Row } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';

// utils
import styled from 'styled-components';
import { ISortObj, SortModal } from './component/sortModal';
import { SearchBox } from './component/searchBox';
import { serviceStore } from 'services/services';

const BorderWrapper = styled.div`
  width: 100%;
  border: 4px solid rgb(216, 223, 225);
  border-radius: 10px;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const Index = () => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [sortObj, setSortObj] = useState<ISortObj>();
  const [createObj, setCreateObj] = useState<ScreenDTO>();
  const [detailObj, setDetailObj] = useState<MenuDTO>();

  const columns: IGrid.Column[] = [
    {
      headerText: '메뉴 아이디',
      dataField: 'menuId',
    },
    {
      headerText: '메뉴명',
      dataField: 'menuName',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: (e) => {
          setDetailObj(e.item);
        },
      },
    },
    {
      headerText: '연결 주소',
      dataField: 'url',
    },
  ];

  useEffect(() => {
    addMasterOptions();
    wrappedFetchList(searchObj);
  }, []);

  const addMasterOptions = async () => {
    const addOps = {};
    if (!MASTER_OBJ?.SCREENID_URL) {
      addOps['SCREENID_URL'] = MASTER_OPS?.SCREEN_WHOLE?.map((ele) => {
        return {
          value: ele.screenId,
          label: ele.url,
        };
      });
    } else if (!MASTER_OBJ?.MENU_NAME) {
      addOps['MENU_NAME'] = MASTER_OPS?.MENU_WHOLE?.map((ele) => {
        return {
          value: ele.menuId,
          label: ele.menuName,
        };
      });
    } else if (!MASTER_OBJ?.MENU1) {
      addOps['MENU1'] = MASTER_OPS?.MENU_WHOLE?.filter((ele) => ele.level === 1);
      addOps['MENU2'] = MASTER_OPS?.MENU_WHOLE?.filter((ele) => ele.level === 2);
      addOps['MENU3'] = MASTER_OPS?.MENU_WHOLE?.filter((ele) => ele.level === 3);
    }
    if (Object?.keys(addOps)?.length > 0) dispatch(addMASTER(addOps));
  };

  const fetchPaging = async (searchObj: MenuSearchDTO) => {
    dispatch(setLoading('GET'));
    const data = (await serviceStore.userAction('menu', 'GET', searchObj, null, true))?.data;
    const allMenu = data?.list;
    allMenu.forEach((ele) => {
      delete ele['childMenus'];
    });
    const parentParent = allMenu?.filter((ele) => ele.level === 1).sort((a, b) => a.sort - b.sort);
    const parent = allMenu?.filter((ele) => ele.level === 2).sort((a, b) => a.sort - b.sort);
    const child = allMenu?.filter((ele) => ele.level === 3).sort((a, b) => a.sort - b.sort);

    const menu23 = parent.map((p) => {
      return {
        ...p,
        children: child.filter((ele) => ele.parentMenuId === p.menuId),
      };
    });
    const menu12 = parentParent.map((pp) => {
      return {
        ...pp,
        children: menu23.filter((ele) => ele.parentMenuId === pp.menuId),
      };
    });
    gridRef.current.setGridData(menu12);
    dispatch(setLoading(null));
    return data;
  };

  const validateMenu = (obj: MenuDTO) => {
    return true;
  };

  const CRUDMenu = async ({ data, crud, searchObj }) => {
    let rs;
    if (window.confirm(`${crud === 'D' ? '삭제' : '저장'}하시겠습니까? `)) {
      dispatch(setLoading('POST'));
      if (crud === 'D') {
        rs = await serviceStore.userAction('menu', 'DELETE', null, { data });
      } else {
        if (validateMenu(data)) {
          rs = crud === 'U' ? await serviceStore.userAction('menu', 'PUT', null, data) : await serviceStore.userAction('menu', 'POST', null, data);
        }
      }

      if (rs?.status === 200) {
        alert('처리 되었습니다!');
        setDetailObj(null);
        wrappedFetchList(searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  const handleCheckItems = (e) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      const id = e.target.id;
      if (id === 'delete-function') CRUDMenu({ data: items?.map((ele) => ele.menuId), crud: 'D', searchObj: JSON.parse(items[0]?.searchObj) });
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const createScreen = async (createObj: ScreenDTO) => {
    if (window.confirm('url을 등록하시겠습니까?')) {
      const data = {
        ...createObj,
        screenId: createObj?.url?.substring(1).toUpperCase(),
        screenName: createObj?.url?.substring(1).toUpperCase(),
        useFlag: true,
      };

      const rs = await serviceStore.userAction('screen', 'POST', null, data);
      if (rs?.status === 200) {
        alert('등록되었습니다!');
        window.location.reload();
      }
    }
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 400,
      useFlag: true,
    },
    gridRef,
    fetchPaging,
  });

  return (
    <div className="page-content">
      {sortObj?.visible && <SortModal sortObj={sortObj} setSortObj={setSortObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        <div style={{ display: 'flex' }}>
          <div style={{ width: 'calc(100% - 650px)', maxWidth: '50%', marginRight: 50 }}>
            <div className="grid-button-area space-between">
              <div>
                <div
                  className="blue"
                  onClick={() => {
                    setDetailObj({
                      menuId: null,
                      menuName: null,
                      description: null,
                      useFlag: true,
                      registerId: null,
                    });
                  }}
                >
                  생성
                </div>
                <div className="red" id="delete-function" onClick={handleCheckItems}>
                  삭제
                </div>
              </div>
              <div>
                <div
                  className="orange"
                  onClick={() => {
                    setSortObj({
                      visible: true,
                      fetchFn: (data) => wrappedFetchList(data),
                    });
                  }}
                >
                  순서조정
                </div>
              </div>
            </div>
            <GridBox
              gridRef={gridRef}
              columns={columns}
              gridProps={{
                fixedColumnCount: 1,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
                pageRowCount: 400,
                showPageRowSelect: false,
                rowIdField: 'menuId',
              }}
            />
          </div>
          <div style={{ width: 700 }}>
            <BorderWrapper style={{ height: 'calc(100% - 320px)', marginBottom: 20 }}>
              <Title>메뉴 {detailObj?.registerId ? '상세' : '생성'}</Title>
              <div>
                <label>메뉴코드*</label>
                <InputD
                  disabled={detailObj?.registerId !== null}
                  value={detailObj?.menuId || ''}
                  onChange={(e) => {
                    setDetailObj({
                      ...detailObj,
                      menuId: e.target.value,
                    });
                  }}
                />
                <label>메뉴명*</label>
                <InputD
                  value={detailObj?.menuName || ''}
                  onChange={(e) => {
                    setDetailObj({
                      ...detailObj,
                      menuName: e.target.value,
                    });
                  }}
                />
                <label>설명</label>
                <textarea
                  style={{ width: '100%' }}
                  value={detailObj?.description || ''}
                  onChange={(e) => {
                    setDetailObj({
                      ...detailObj,
                      description: e.target.value,
                    });
                  }}
                />
                <Row>
                  <Col>
                    <label>상위메뉴</label>
                    <div className="grid-wrapper div2">
                      <SelectD
                        isDisabled={detailObj?.level === 1}
                        options={MASTER_OPS?.MENU_WHOLE?.filter((ele) => ele.level !== 3)?.map((ele) => {
                          return {
                            label: ele.menuName,
                            value: ele.menuId,
                          };
                        })}
                        value={
                          detailObj?.parentMenuId
                            ? {
                                label: MASTER_OBJ?.MENU_NAME?.[detailObj?.parentMenuId],
                                value: detailObj?.parentMenuId,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setDetailObj({
                            ...detailObj,
                            parentMenuId: (option as OptionItem).value,
                          });
                        }}
                      />
                      <InputD readOnly disabled value={detailObj?.parentMenuId || ''} />
                    </div>
                  </Col>
                  <Col>
                    <label>메뉴레벨</label>
                    <SelectD
                      options={MASTER_OPS?.MENU_LEVEL}
                      value={
                        detailObj?.level
                          ? {
                              label: MASTER_OBJ?.MENU_LEVEL?.[detailObj?.level],
                              value: detailObj?.level,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailObj({
                          ...detailObj,
                          level: (option as OptionItem).value,
                          parentMenuId: null,
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>연결URL</label>
                    <SelectD
                      options={MASTER_OPS?.SCREENID_URL}
                      isDisabled={detailObj?.level !== 3}
                      value={
                        detailObj?.screenId
                          ? {
                              label: MASTER_OBJ?.SCREENID_URL?.[detailObj?.screenId],
                              value: detailObj?.screenId,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailObj({
                          ...detailObj,
                          screenId: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                </Row>
                <label>사용여부</label>
                <div className="radio-area one-line">
                  {MASTER_OPS?.USE_FLAG?.map((ele) => {
                    return (
                      <span key={`useFlag_${ele.value}`}>
                        <InputD
                          type="radio"
                          checked={detailObj?.useFlag + '' === ele.value}
                          onChange={() => {
                            setDetailObj({
                              ...detailObj,
                              useFlag: ele.value,
                            });
                          }}
                        />
                        <span>{ele.label}</span>
                      </span>
                    );
                  })}
                </div>
                <Row>
                  <Col style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-secondary sm"
                      onClick={() => {
                        CRUDMenu({
                          data: detailObj,
                          crud: detailObj?.registerId ? 'U' : 'C',
                          searchObj,
                        });
                      }}
                    >
                      {detailObj?.registerId ? '수정' : '추가'}
                    </button>
                  </Col>
                </Row>
              </div>
            </BorderWrapper>
            <BorderWrapper style={{ height: 300, marginBottom: 20 }}>
              <Title>URL 간편 화면 등록</Title>
              <Row>
                <Col>
                  <label>URL</label>
                  <InputD
                    value={createObj?.url || ''}
                    onChange={(e) => {
                      setCreateObj((prev) => {
                        return {
                          ...prev,
                          url: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>화면설명 및 메모</label>
                  <textarea
                    style={{ width: '100%', height: 80 }}
                    value={createObj?.description || ''}
                    onChange={(e) => {
                      setCreateObj((prev) => {
                        return {
                          ...prev,
                          description: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: 'right' }}>
                  <button className="btn btn-secondary sm" onClick={() => createScreen(createObj)}>
                    저장
                  </button>
                </Col>
              </Row>
            </BorderWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
