import { COLORS } from 'common/constants/appearance';
import LDSSidebar from 'components/LDS/layouts/Sidebar';
import { Navigate, Outlet, useLoaderData, useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { PageWrapper } from '../Login/Index';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { useLayoutEffect, useEffect, useState } from 'react';
import { useEnvironment } from 'components/LDS/hooks/useEnvironment';
import { detectMobileDevice } from 'common/util/detectDevice';
import { NO_NEED_REFRESH, tokenCheck } from 'common/http-client/axiosConfig';
import { postRefreshToken } from 'common/http-client/refreshToken';
import { ReadOnlyPopup } from 'pages/DELIVERY/1qna/systemAnnouncementTab/presenter';
import { UserDetailPopupforUser } from 'pages/ETC/3user/userTab/userList/component/userDetailforUsers';
import { clearLocalStorageData, setLocalStorageTokens, setLocalTabs } from 'common/util/localStorage';
import { mainFallbackUrl, mainUrl } from 'components/LDS/utils/path';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import LDSLoader from 'components/LDS/modules/Loader';

export default function RootPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const { hasAuth } = useLoaderData() as { hasAuth: boolean };
  const { loading, openOrclose } = useSelector((state: RootState) => state.menu);

  const [gongjiObj, setGongjiObj] = useState<{ visible: boolean; announcementSeq: number }>();
  const [userPopObj, setUserPopObj] = useState({
    userId: '',
  });

  const { getFetchUseableFunctions, getFetchUseableScreenNTabs } = useUseableScreenTabFunctions();
  const { classNameForWrapper, setIsMobile } = useEnvironment();

  const screenUrl = location.pathname?.split('/')?.[2];

  const fetchUserSettingInfos = async () => {
    const [functions, screens] = await Promise.all([getFetchUseableFunctions(), getFetchUseableScreenNTabs()]);

    setLocalTabs(screens);

    return [functions];
  };

  useLayoutEffect(() => {
    const redirectToLogin = () => {
      alert('로그인이 필요합니다');
      clearLocalStorageData();
      return navigate('/login');
    };
    hasAuth ? fetchUserSettingInfos() : redirectToLogin();
  }, []);

  useEffect(() => {
    if (hasAuth) {
      const tokenStatus = tokenCheck();
      if (tokenStatus !== NO_NEED_REFRESH) {
        postRefreshToken()
          .then((res) => {
            if (res?.status === 200) {
              setLocalStorageTokens(res?.data);

              // if (location?.pathname === '/login') {
              //   setTokenValidFlag(true);
              //   alert('로그인 되어있으므로 홈으로 이동합니다');
              //   history('/');
              //   setTokenValidFlag(false);
              // } else {
              //   itsOkay();
              // }
            } else {
              // 혹시나
              alert('토큰이 만료되어 로그인창으로 이동합니다!');
              clearLocalStorageData();
              navigate('/login');
            }
          })
          .catch((err) => {
            alert('토큰이 만료되어 로그인창으로 이동합니다!');
            clearLocalStorageData();
            navigate('/login');
          });
      }
    }
  }, [screenUrl]);

  useEffect(() => {
    const isMobile = detectMobileDevice(window.navigator.userAgent);
    if (isMobile) setIsMobile(true);
  }, []);

  return (
    <RootWrapper className={classNameForWrapper}>
      {/* login되어있을 때 디폴트로 리다이렉트시킬 라우팅 경로는 추후 업데이트 */}
      {hasAuth && (
        <>
          {(location.pathname === '/' || location.pathname === mainUrl || location.pathname === `${mainUrl}/`) && <Navigate to={mainFallbackUrl} />}

          <LDSSidebar $isCollapsed={false} setGongjiObj={setGongjiObj} setUserPopObj={setUserPopObj} />

          <Outlet />
          {/* RecentAnnounce는 기존에 대시보드 컴포넌트에 달려있었음. 추후 이동 */}
          {gongjiObj?.visible && <ReadOnlyPopup popupObj={gongjiObj} setPopupObj={setGongjiObj} />}
          {userPopObj?.userId && <UserDetailPopupforUser userPopObj={userPopObj} setUserPopObj={setUserPopObj} />}
        </>
      )}
      {loading !== null && <LDSLoader openOrclose={openOrclose?.side} />}
    </RootWrapper>
  );
}

const RootWrapper = styled(PageWrapper)`
  background-color: ${COLORS.GRAY_01};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: auto;
  min-height: auto;
  max-height: none;
`;
