import { useState, useEffect } from 'react';
import Container from './container';

import { defaultUnitedPageWithTopTab, UnitedPageWithTopTab } from 'components/template/topTab';

// redux
import { useSelector, useDispatch } from 'react-redux';

// options
import { setMasterOptions } from 'redux/services/menuSlice';
import { centerListForlabeling, codeMasterOptionReturn, driverListForLabeling } from 'common/master/codeMasterOptionReturn';
import { RootState } from 'redux/store';

const Index = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    initMasterOptions();
  }, []);

  useEffect(() => {
    if (masterOptions) {
      setTabObj({
        currentTab: 0,
        titles: ['기사별 배송마감'],
        containers: [<Container />],
      });
    }
  }, [masterOptions]);

  const initMasterOptions = async () => {
    const [FEE_TYPE, INVOICE_STATUS, CANCEL_REASON, CENTER_WHOLE, DRIVER_WHOLE] = await Promise.all([
      codeMasterOptionReturn('FEE_TYPE'),
      codeMasterOptionReturn('INVOICE_STATUS'),
      codeMasterOptionReturn('CANCEL_REASON'),
      centerListForlabeling(),
      driverListForLabeling(),
    ]);
    dispatch(
      setMasterOptions({
        FEE_TYPE,
        INVOICE_STATUS,
        CANCEL_REASON,
        CENTER: CENTER_WHOLE.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        DRIVER: DRIVER_WHOLE?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
      }),
    );
  };

  return <>{masterOptions && tabObj?.titles.length > 0 && <UnitedPageWithTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
