import CommonModalNew from 'components/modal/commonModalNew';
import { useEffect, useState } from 'react';
import { serviceStore } from 'services/services';
import { TimeDTO } from '../../../_interface/order';

export const TimeStampModal = (props) => {
  const { invoiceDetail, visible, setVisible } = props;
  const [timeStampList, setTimeStampList] = useState<TimeDTO>();

  useEffect(() => {
    timeStampfetchList();
  }, [invoiceDetail]);

  const timeStampfetchList = async () => {
    const rs = await serviceStore?.orderAction(`detail/time/${invoiceDetail.invoiceSeq}`, 'GET', null);
    if (rs?.status === 200) {
      setTimeStampList(rs.data);
    }
  };

  return (
    <CommonModalNew
      title="타임스탬프"
      setVisible={setVisible}
      visible={visible}
      style={{ width: 800 }}
      children={
        <>
          <table className="border-table" style={{ textAlign: 'center' }}>
            <thead>
              <tr className="table-primary">
                <th>주문일시</th>
                <th>주문등록일시</th>
                <th>희망일</th>
                <th>지정일</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{timeStampList?.orderDatetime || ''}</td>
                <td>{timeStampList?.registerDatetime || ''}</td>
                <td>{timeStampList?.promiseDeliveryDate || ''}</td>
                <td>{timeStampList?.deliveryDate || ''}</td>
              </tr>
            </tbody>
            <thead>
              <tr className="table-primary">
                <th>허브창고 출차일</th>
                <th>거점창고 입고일</th>
                <th>기사출고 완료일</th>
                <th>배송출발</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{timeStampList?.loadDatetime || ''}</td>
                <td>{timeStampList?.unloadDatetime || ''}</td>
                <td>{timeStampList?.loanDatetime || ''}</td>
                <td>{timeStampList?.deliveryStartDatetime || ''}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>고객집 도착일</th>
                <th>설치/회수 완료일</th>
                <th>창고 회수일</th>
                <th>배송마감일</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{timeStampList?.visitDatetime || ''}</td>
                <td>{timeStampList?.finishDatetime || ''}</td>
                <td>{timeStampList?.warehouseCollectDatetime || ''}</td>
                <td>{timeStampList?.deliveryCloseDatetime || ''}</td>
              </tr>
            </tbody>
          </table>
        </>
      }
    />
  );
};
