import styled from 'styled-components';

export type TagGroupType = {
  $padding?: number;
  $width?: number;
  $gap?: number;
};

export default function LDSTagGroup({ children, ...props }: TagGroupType & { children?: React.ReactNode }) {
  return <LDSTagGroupStyle {...props}>{children}</LDSTagGroupStyle>;
}
export const LDSTagGroupStyle = styled.ul<TagGroupType>`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => (props.$gap ? props.$gap : 0)}px;
  padding: ${(props) => (props.$padding ? props.$padding : 0)}px;
  width: ${(props) => (props.$width ? `${props.$width}px` : '100%')};
  background-color: inherit;
  align-items: center;
`;
