import * as IGrid from 'aui-grid';
import { LocationSelectDetailListDTO, LocationSelectSearchDTO } from '../../../../_interface/warehouse';
import { InputD } from 'components/reactstrap/reactstrap';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';

export interface ILocateObj {
  visible?: boolean;
  item?: LocationSelectDetailListDTO[];
  event?: string;
  searchObj?: string;
  fetchFn?: (e: LocationSelectSearchDTO) => void;
  fetchDetailFn?: (e: IGrid.ButtonClickEvent) => void;
}

export interface IlocatePopupObj {
  locateObj: ILocateObj;
  setLocateObj: React.Dispatch<React.SetStateAction<ILocateObj>>;
}

export const LocatePoup = ({ locateObj, setLocateObj }: IlocatePopupObj) => {
  const dispatch = useDispatch();
  const [toWhere, setTowhere] = useState<string>();

  const saveLocate = async (locationCode: string) => {
    if (window.confirm('일괄 지정하시겠습니까?')) {
      dispatch(setLoading('POST'));
      const rs = await serviceStore.warehouseAction('location/select/list/location', 'POST', null, {
        locationSelectDetailSeqs: locateObj?.item?.map((ele) => ele.locationSelectDetailSeq),
        locationCode,
      });

      if (rs?.status === 200) {
        alert('일괄 지정되었습니다!');
        if (locateObj?.fetchFn && locateObj?.fetchDetailFn) {
          const e = JSON.parse(locateObj?.event);
          const searchObj = JSON.parse(locateObj?.searchObj);
          locateObj?.fetchFn(searchObj);
          locateObj?.fetchDetailFn(e);
        }
      }
      dispatch(setLoading(null));
    }
  };

  return (
    <CommonModalNew
      style={{ width: 350 }}
      title="일괄 위치지정"
      visible={locateObj?.visible}
      setVisible={() => {
        setLocateObj(null);
      }}
      children={
        <>
          <InputD
            value={toWhere || ''}
            onChange={(e) => {
              setTowhere(e.target.value);
            }}
          />
          <div style={{ textAlign: 'right', marginTop: 30 }}>
            <button
              className="btn btn-orange ssm"
              onClick={() => {
                saveLocate(toWhere);
              }}
            >
              지정
            </button>
          </div>
        </>
      }
    />
  );
};
