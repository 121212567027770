import { useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import { ScreenDTO, ScreenSearchDTO } from 'interface/user';
import { InputD } from 'components/reactstrap/reactstrap';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IDetailObj {
  searchObj?: ScreenSearchDTO;
  visible: boolean;
  item?: ScreenDTO;
  CRUDFn?: <T>(T) => void;
}

interface IDetailModal {
  detailObj: IDetailObj;
  setDetailObj: React.Dispatch<React.SetStateAction<IDetailObj>>;
}

export const DetailModal = ({ detailObj, setDetailObj }: IDetailModal) => {
  const [data, setData] = useState<ScreenDTO>();
  useEffect(() => {
    if (detailObj?.item) {
      setData(detailObj?.item);
    }
  }, []);

  const saveDetail = (data: ScreenDTO) => {
    if (!detailObj?.item) {
      if (window.confirm('등록 하시겠습니까?')) {
        detailObj?.CRUDFn({ data, crud: 'C', searchObj: detailObj?.searchObj });
      }
    } else {
      if (window.confirm('수정 하시겠습니까?')) {
        detailObj?.CRUDFn({ data, crud: 'U', searchObj: detailObj?.searchObj });
      }
    }
  };

  const deleteDetail = (data: ScreenDTO) => {
    if (window.confirm('삭제 하시겠습니까?')) {
      detailObj?.CRUDFn({ data: [data?.screenId], crud: 'U', searchObj: detailObj?.searchObj });
    }
  };

  return (
    <CommonModalNew
      style={{ width: 500 }}
      title={!detailObj?.item ? '화면 생성' : '화면 수정'}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      children={
        <>
          <label>연결 URL*</label>
          <InputD
            value={data?.url || ''}
            onChange={(e) => {
              setData({
                ...data,
                url: e.target.value,
              });
            }}
          />
          <label>화면 아이디*</label>
          <InputD
            disabled={detailObj?.item !== null}
            value={data?.screenId || ''}
            onChange={(e) => {
              setData({
                ...data,
                screenId: e.target.value,
              });
            }}
          />
          <label>화면명*</label>
          <InputD
            value={data?.screenName || ''}
            onChange={(e) => {
              setData({
                ...data,
                screenName: e.target.value,
              });
            }}
          />
          <label>설명</label>
          <textarea
            style={{ width: '100%' }}
            value={data?.description || ''}
            onChange={(e) => {
              setData({
                ...data,
                description: e.target.value,
              });
            }}
          />
          <label>사용여부</label>
          <div className="radio-area one-line">
            <Label>
              <Input
                type="radio"
                checked={data?.useFlag === true}
                onChange={(e) => {
                  setData({
                    ...data,
                    useFlag: true,
                  });
                }}
              />
              &nbsp;&nbsp;사용
            </Label>
            <Label>
              <Input
                type="radio"
                checked={data?.useFlag === false}
                onChange={(e) => {
                  setData({
                    ...data,
                    useFlag: false,
                  });
                }}
              />
              &nbsp;&nbsp;사용 안 함
            </Label>
          </div>
          <div style={{ paddingTop: 30, textAlign: 'right' }}>
            {detailObj?.item && (
              <button className="btn btn-danger" onClick={() => deleteDetail(data)}>
                삭제
              </button>
            )}
            <button className={detailObj?.item ? 'btn btn-orange' : 'btn btn-blue'} onClick={() => saveDetail(data)}>
              {detailObj?.item ? '수정' : '등록'}
            </button>
          </div>
        </>
      }
    />
  );
};
