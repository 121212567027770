import { returnFeeTypes, returnIsInvoiceStatusFinished } from 'common/util/orderTypes';
export interface IColoredData {
  [flag: string]: number;
}

export interface IColorEle {
  feeType: string;
  invoiceStatus: string;
  type: string;
  color: string;
}
export const coloringData = (hasSchedule): IColoredData => {
  let delivery = 0;
  let visit = 0;
  let collect = 0;
  let exchange = 0;
  let move = 0;
  let store = 0;
  let as = 0;
  let fix = 0;
  let finish = 0;
  hasSchedule?.forEach((ele) => {
    if (returnFeeTypes(ele.feeType.trim()) === 'delivery') {
      delivery += 1;
      ele.type = 'delivery';
      ele.color = '#3EA1FF';
    } else if (returnFeeTypes(ele.feeType.trim()) === 'visit') {
      visit += 1;
      ele.type = 'visit';
      ele.color = '#FFAD0F';
    } else if (returnFeeTypes(ele.feeType.trim()) === 'collect') {
      collect += 1;
      ele.type = 'collect';
      ele.color = '#FFA09C';
    } else if (returnFeeTypes(ele.feeType.trim()) === 'exchange') {
      exchange += 1;
      ele.type = 'exchange';
      ele.color = '#61CACA';
    } else if (returnFeeTypes(ele.feeType.trim()) === 'move') {
      move += 1;
      ele.type = 'move';
      ele.color = '#CA87FF';
    } else if (returnFeeTypes(ele.feeType.trim()) === 'store') {
      store += 1;
      ele.type = 'store';
      ele.color = '#DDA97A';
    } else if (returnFeeTypes(ele.feeType.trim()) === 'as') {
      as += 1;
      ele.type = 'as';
      ele.color = '#21AD76';
    } else if (returnFeeTypes(ele.feeType.trim()) === 'fix') {
      fix += 1;
      ele.type = 'fix';
      ele.color = '#21AD76';
    } else if (returnFeeTypes(ele.feeType.trim()) === 'nothing') {
      ele.color = 'white';
    }

    if (returnIsInvoiceStatusFinished(ele.invoiceStatus.trim())) {
      finish += 1;
      ele.color = '#A3A7B9';
    }
  });

  // hasSchedule.sort(function (a, b) {
  //   if (a.invoiceStatus === 'FINISH' && a.invoiceStatus !== 'FINISH') return 1;
  //   if (a.invoiceStatus === 'FINISH' && b.invoiceStatus === 'FINISH') return 0;
  //   if (a.invoiceStatus !== 'FINISH' && b.invoiceStatus !== 'FINISH') return 0;
  //   if (a.invoiceStatus !== 'FINISH' && b.invoiceStatus === 'FINISH') return -1;
  // });

  return {
    // elements: hasSchedule,
    total: hasSchedule.length,
    delivery,
    visit,
    collect,
    exchange,
    move,
    store,
    as,
    fix,
    finish,
  };
};
