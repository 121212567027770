import { ReactNode, useMemo, useRef, useState } from 'react';

// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';

// utils
import useSearchGridPaging from 'hooks/grid/useGridPaging';
// service
import { serviceStore } from 'services/services';
import { CollectInspectDTO, CollectInspectSearchDTO } from '../../../_interface/warehouse';

// component
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import { PagingListDTO } from 'interface/util';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const fetchPaging = async (searchObj: CollectInspectSearchDTO) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore.warehouseAction(`collect/inspect/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<CollectInspectDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    downloadExcel(id);
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const labellingKr = (data: PagingListDTO<CollectInspectDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        //
        collectTypeKr: MASTER_OBJ?.COLLECT_TYPE?.[row.collectType],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],
        //inspectStatusKr
        inspectStatusKr: MASTER_OBJ?.COLLECT_INSPECT_STATUS?.[row.inspectStatus],
        inspectAvailableStatusKr: MASTER_OBJ?.INVA_STATUS?.[row.inspectAvailableStatus],
        causeKr: MASTER_OBJ?.INSPECT_CAUSE?.[row?.cause],
        reasonKr: MASTER_OBJ?.INSPECT_REASON?.[row?.reason],
        processKr: MASTER_OBJ?.INSPECT_PROCESS?.[row?.process],
        resultKr: MASTER_OBJ?.INSPECT_FLAG?.[row?.result + ''],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const downloadExcel = async (id) => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction(`collect/inspect/excel`, 'GET', searchObj, null))?.data as CollectInspectDTO[],
      },
      true,
    );

    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `회수검수` });
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '회수번호',
      dataField: 'collectSeq',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
      ],
    },
    {
      headerText: '입고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '회수입고구분',
      dataField: 'collectTypeKr',
    },
    {
      headerText: '검수상태',
      dataField: 'inspectStatusKr',
    },
    {
      headerText: '검수재고상태',
      dataField: 'inspectAvailableStatusKr',
    },
    {
      headerText: '판정결과',
      dataField: 'resultKr',
    },
    {
      headerText: '귀책사유',
      dataField: 'causeKr',
    },
    {
      headerText: '불용사유',
      dataField: 'reasonKr',
    },
    {
      headerText: '조치사항',
      dataField: 'processKr',
    },
    {
      headerText: '검수완료일시',
      dataField: 'confirmDatetime',
    },
    {
      headerText: '검수완료자',
      dataField: 'confirmId',
    },
  ];

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
