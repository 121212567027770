import { ReactNode, useEffect } from 'react';
import { setNowMenu } from 'redux/services/menuSlice';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import seperator from './seperator.png';
import { setDocumentTitle } from 'common/util/html';
import { RootState } from 'redux/store';

export interface IDetailTopTab {
  titles: string[];
  routers: string[];
  container: ReactNode[];
}

export const defaultUnitedPageWithTopTab = {
  titles: [],
  routers: [],
  container: null,
};

export const DetailTopTab = ({ tabObj, setTabObj }) => {
  const { useableScreens } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (tabObj) {
      setDocumentTitle(tabObj?.titles[1]);
      dispatch(
        setNowMenu({
          url: location.pathname,
          menuName: tabObj?.titles?.[1],
        }),
      );
    }
  }, [tabObj]);

  return (
    <>
      {tabObj && Object.keys(useableScreens)?.includes(!location.pathname.includes('transactionRegister') ? location.pathname : `/${location.pathname?.split('/')?.[1]}`) ? (
        <>
          <div className="div-top-tab fake">
            <div className="bordering">
              <li
                onClick={() => {
                  window.location.href = tabObj?.routers[0];
                }}
              >
                {tabObj?.titles?.[0]}
              </li>
              <img src={seperator} alt="" style={{ position: 'absolute', right: -10, opacity: 0.5, zIndex: 2 }} />
            </div>
            <div className="bordering-current">
              <li>{tabObj?.titles?.[1]}</li>
            </div>
          </div>
          <div className="container-wrapper">{tabObj?.container?.[0]}</div>
        </>
      ) : (
        <>
          <div style={{ padding: 10 }}>
            해당 페이지에 접근 권한이 없습니다
            <br />
            관리자에게 문의해주세요
          </div>
        </>
      )}
    </>
  );
};
