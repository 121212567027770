import { selectlabel } from 'common/master/codeMasterReturnHelper';
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import CommonModalNew from 'components/modal/commonModalNew';
import { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';
import { InventoryLiveModelInfoDTO } from 'interface/warehouse';

export type ILocationDetailSearchDTOAvailableType = 'AVAILABLE' | 'DEFECTED' | 'ETC' | '';
export interface ILocationDetailSearchDTO {
  centerCode: string;
  locationCode: string;
  modelStockSeq: number;
  availableType?: ILocationDetailSearchDTOAvailableType;
}
export interface ILocationDetailModalObj {
  data: ILocationDetailSearchDTO;
  visible: boolean;
}

export const LocationDetailModal = ({ locationDetailModalObj, setLocationDetailModalObj }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();

  useEffect(() => {
    fetchLocationDetail({ ...locationDetailModalObj?.data });
  }, []);

  const fetchLocationDetail = async ({ centerCode, locationCode, modelStockSeq, availableType }: ILocationDetailSearchDTO) => {
    dispatch(setLoading('GET'));
    const data = (await serviceStore.warehouseAction(`inventory/location/list/all/info`, 'GET', { centerCode, locationCode, modelStockSeq, availableType }, null))?.data as InventoryLiveModelInfoDTO[];
    dispatch(setLoading(null));

    if (data?.length > 0) {
      const dataKr = data.map((row) => {
        return {
          ...row,
          availableStatusKr: MASTER_OBJ?.INVA_STATUS?.[row.availableStatus],
          availableTypeKr: MASTER_OBJ?.INVA_TYPE?.[row.availableType],
          moveStatusKr: MASTER_OBJ?.INVM_STATUS?.[row.moveStatus],
          driverCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.driverCenterCode],
          originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.originalCenterCode],
          destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.destinationCenterCode],
          feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row.feeType],
        };
      });
      gridRef.current.setGridData(dataKr);
    } else {
      alert('조회되는 재고가 없습니다');
    }
  };

  const excelDownload = () => {
    gridRef.current.exportAsXlsx({ fileName: '재고정보' });
  };

  const columns = [
    {
      headerText: '재고번호',
      dataField: 'inventorySeq',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '재고상태',
      dataField: 'availableStatusKr',
    },
    {
      headerText: '재고유형',
      dataField: 'availableTypeKr',
    },
    {
      headerText: '이동상태',
      dataField: 'moveStatusKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '수령인',
      dataField: 'receiverName',
    },
    {
      headerText: '수령인연락처',
      dataField: 'receiverPhone',
    },
    {
      headerText: '배송기사',
      dataField: 'driverName',
    },
    {
      headerText: '기사소속센터',
      dataField: 'driverCenterCodeKr',
    },
    {
      headerText: '지정일',
      dataField: 'deliveryDate',
    },
    {
      headerText: '간선피킹번호',
      dataField: 'linehaulSeq',
    },
    {
      headerText: '반출피킹번호',
      dataField: 'stockRemoveSeq',
    },
    {
      headerText: '출고지시번호',
      dataField: 'loadingStatementSeq',
    },
    {
      headerText: '운송번호',
      dataField: 'transportStatementSeq',
    },
    {
      headerText: 'FC피킹지시번호',
      dataField: 'loanPickSeq',
    },
    {
      headerText: '기사출고번호',
      dataField: 'loanSeq',
    },
    {
      headerText: '자재출고번호',
      dataField: 'loanComponentSeq',
    },
    {
      headerText: '출발센터',
      dataField: 'originalCenterCodeKr',
    },
    {
      headerText: '도착센터',
      dataField: 'destinationCenterCodeKr',
    },
    {
      headerText: '입고예정일',
      dataField: 'promiseUnloadDate',
    },
  ];

  return (
    <CommonModalNew
      subTitle=""
      title={`재고정보`}
      style={{ width: 1000 }}
      visible={locationDetailModalObj?.visible}
      setVisible={() => {
        setLocationDetailModalObj(null);
      }}
      children={
        <>
          <div className="grid-button-area">
            <div className="green" id="excel-download" onClick={excelDownload}>
              엑셀다운
            </div>
          </div>
          <GridBox gridRef={gridRef} columns={columns} gridProps={{ height: 500, showRowAllCheckBox: false, showRowCheckColumn: false }} />
        </>
      }
    />
  );
};
