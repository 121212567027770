export const MASTER_FILE_SAVE = '/master/file/save';
export const USER_SIGN_UP = '/user/sign/up';
export const USER_SIGN_REFRESH = '/user/sign/refresh';
export const USER_SIGN_IN = '/user/sign/in';
export const USER_SIGN_VALID_TOKEN = '/user/sign/valid/token';
export const USER_SIGN_OUT = '/user/sign/out';

export const MY_GRID_PARAMETER = '/user/my/grid/parameter';
export const MY_BOOKMARK = '/user/my/bookmark';
export const MY_RECENTVISIT = '/user/my/recentVisit';

export const INVENTORY_TODAY = '/warehouse/inventory/today/dashboard';
export const INVENTORY_REFURBISH_CREATE = '/warehouse/inventory/refurbish/create';
export const ORDER_TODAY = '/order/today/dashboard';
export const ORDER_TODAY_REGION = '/order/today/dashboard/region';
export const ORDER_TODAY_GRAGH = '/order/today/dashboard/graph';
export const REGION_LIST = '/install/zipcode/region/list';

export const PAYMENT = '/order/payment';
export const ORDER_SMS = '/order/sms';
export const EXTRA_CHARGE_PAYMENT_PAGING = '/order/extra/charge/payment/paging';
export const EXTRA_CHARGE_PAYMENT_DETAIL = '/order/extra/charge/payment/detail';
export const EXTRA_CHARGE_PAYMENT_EXCEL = '/order/extra/charge/payment/excel';
export const EXTRA_CHARGE_PAYMENT_DETAIL_EXCEL = '/order/extra/charge/payment/detail/excel';
export const EXTRA_CHARGE_PAYMENT_SAVE = '/order/extra/charge/payment/save';
export const EXTRA_CHARGE_PAYMENT_CANCEL = '/order/extra/charge/payment/cancel';

export const USER_DETAIL = '/user';
export const USER_MY_INFO = '/user/my/info';
export const USER_USER_LIST = '/user/list';
export const USER_USER_LIST_PAGING = '/user/list/paging';

export const USER_SAVE = '/user/save';
export const USER_LIST_EXCEL = '/user/list/excel';

// 관리자 전용
export const USER_MENU = '/user/menu';
export const USER_MENU_ALL = '/user/menu/all';
export const USER_MENU_SORT = '/user/menu/sort';

export const USER_SCREEN = '/user/screen';
export const USER_SCREEN_ALL = '/user/screen/all';
export const USER_FUNCTION = '/user/function';
export const USER_FUNCTION_ALL = '/user/function/all';
export const USER_FUNCTION_SORT = '/user/function/sort';
export const USER_PASSWORD_RESET = '/user/password/admin';

export const USER_TAB = '/user/tab';

export const USER_TAB_ALL = '/user/tab/all';
export const USER_TAB_SORT = '/user/tab/sort';
export const USER_TAB_USERGROUP = 'user/tab/userGroup';

export const USER_ATTENDANCE_LIST = '/user/attendance/list';
export const USER_ATTENDANCE_DETAIL = '/user/attendance';
export const USER_HOLIDAY_LIST = '/user/holiday';

export const MESSAGE_TEMPLATE = '/order/message/template';
export const MESSAGE_HISTORY = '/order/message/history/list';
export const MESSAGE_HISTORY_DETAIL = '/order/message/history/detail/list';
export const MESSAGE_HISTORY_EXCEL = '/order/message/history/list/excel';
export const MESSAGE_SCHEDULE_SEND = '/order/message/schedule/send';
export const MESSAGE_SMS_RETRY = '/order/message/sms/retry';

export const ORDER_HISTORY_PAGING = '/order/invoice/update/history/paging';
export const ORDER_HISTORY_EXCEL = '/order/invoice/update/history/excel';

// 권한 관리(관리자 전용)
export const GET_AUTHORITY = '/user/authority';

export const USER_GROUP = (action: string) => `/user/group/${action}`;
export const USER_ACCESS = (category: string, action: string) => `/user/access/${category}/${action}`;
// 사용자 그룹 조회
export const USERGROUP_AUTHORITY = '/user/authority/userGroup';
export const USERGROUP_USER = `/user/authority/userGroupUser`;

export const REMOVE_PICKING_MEMO_SAVE = '/warehouse/remove/picking/memo/save';
export const REMOVE_LOADING_MEMO_SAVE = '/warehouse/remove/loading/memo/save';
export const REMOVE_LOADINGOUT_MEMO_SAVE = '/warehouse/remove/loading/out/memo/save';

/**
 * ORDER 주문
 */
export const OMS_FINDPARCEL = '/order/findParcelInvoice';
export const OMS_ORDER_ASSIGN = '/order/assign';
export const OMS_HAPPY_CALL = '/order/happyCall';
export const OMS_RESEND_COMPLETE = '/order/resendCompleteData';
export const OMS_ORDER_ASSIGN_V2 = '/order/assign/v2';
export const OMS_ORDER_ASSIGN_V3 = '/order/assign/v3';
export const OMS_FINISH_TO_DELIVERY = '/order/finishToDelivery';
export const OMS_ROLLBACK = '/order/invoice/rollback';
export const OMS_UPDATE_TO_CONFIRM = '/order/collectToConfirm';
export const OMS_ORDER_SAVE = '/order/save';
export const OMS_ORDER_LIST_QUANTITY_V2 = '/order/v2/list/quantity';
export const OMS_ORDER_LIST_PAGING_V2 = '/order/v2/list/paging';
export const OMS_PURCHASE_REQUEST = '/order/invoice/purchase/request';
export const OMS_ORDER_SHEET_PRINT = '/order/invoice/order/sheet/print';

export const OMS_SERVICE_PAGING = '/order/extra/service/paging';
export const OMS_SERVICE_EXCEL = '/order/extra/service/excel';
export const OMS_SERVICE_SAVE = '/order/extra/service/save';
export const OMS_SERVICE_DELETE = '/order/extra/service/delete';

export const OMS_ORDER_LIST_EXCEL = '/order/list/excel';
export const OMS_UNASSIGN_ORDER = '/order/unassignAndDelete';

export const OMS_ORDER_LADDERCAR_PRINT = '/order/ladderTruck';

export const SIMPLE_LIST = '/order/simple/list/paging';
export const SIMPLE_LIST_EXCEL = '/order/simple/list/excel';

// 이전
export const MOVE_ORDER_LIST = '/order/move';
export const MOVE_ORDER_LIST_V2 = '/order/move/v2';
export const MOVE_CONFIRM = '/warehouse/linehaul/move/save';
export const MOVE_CONFIRM_V2 = '/warehouse/linehaul/move/save/v2';
export const MOVE_EDIT = '/warehouse/linehaul/move/update';

export const FAILED_ORDER_LIST = '/order/body/fail/list';
export const FAILED_EXCEL_ORDER_LIST = '/order/fail/list';

export const FAILED_ORDER_REORDER_BODY = '/order/save/retry';
export const FAILED_ORDER_REORDER = '/order/fail/re';
export const FAILED_ORDER_REORDER_UPDATE = '/order/fail';
export const FAILED_ORDER_REORDER_UPDATE_PRODUCT = '/order/fail/save';
export const FAILED_ORDER_REORDER_UPDATE_PRODUCT_MODAL = '/order/fail/item/save';
export const FAILED_ORDER_REORDER_UPDATE_PAGING = '/order/fail';
export const ORDER_COLLECT = '/order/collect';
export const OMS_ORDER_DETAIL = '/order/detail';
export const ORDER_DETAIL_HISTORY = '/order/detail/update/history';
export const OMS_ORDER_DETAIL_FOR_ORDER_REGISTER = '/order/copy';
export const OMS_ORDER_DETAIL_FOR_ORDER_REGISTER_COPY = '/order/simple/copy';
export const OMS_ORDER_DETAIL_COUNSEL = '/order/detail/counsel';
export const OMS_ORDER_DETAIL_TIMESTAMP = '/order/detail/time';
export const OMS_ORDER_DETAIL_FILE = '/order/detail/file';
export const PARCEL_PHOTO_FILE = '/parcel/invoice/image';
export const OMS_ORDER_RESTORE = '/order/finishToDelivery';
export const ORDER_DETAIL_LIST = '/order/detail/list';
export const ORDER_DETAIL_SAVE = '/order/detail/save';
export const ORDER_PEND_LIST = '/order/pend/list';
export const ORDER_NOTININVENTORY = '/order/notInInventory';

export const OMS_ORDER_INVENTORY = '/order/inventory';
export const OMS_ORDER_INSTALL_COMPLETE = '/order/install/complete/lite';
export const OMS_ORDER_INVENTORY_LOCATION = '/order/inventory/location';
export const OMS_ORDER_INVENTORY_LACK = '/order/inventory/lack';
export const OMS_ORDER_INVOICE = '/order/invoice';
export const OMS_ORDER_CHANGE_PHONE = '/order/change/phone';
export const OMS_ORDER_CHANGE_NOTE = '/order/change/note';
export const OMS_ORDER_COUNCEL_SAVE = '/order/counsel/save';
export const OMS_ORDER_CANCEL = '/order/cancel/list';
export const OMS_ORDER_ASSIGN_ADMIN = '/order/assign/admin';
export const OMS_ORDER_CHANGE_INVOICE = '/order/change/invoice';
export const OMS_ORDER_REQUEST_COLLECT = '/order/request/collect';
export const OMS_ORDER_REQUEST_EXCHANGE = '/order/request/exchange';
export const OMS_ORDER_SAVE_COLLECT = '/order/save/collect';
export const OMS_ORDER_SCHEDULE_UNPROMISED = '/order/schedule/unpromised';
export const OMS_ORDER_SCHEDULE_UNPROMISED_CHANGE = '/order/schedule//change/unpromised';
export const OMS_ORDER_SCHEDULE_PROMISED = '/order/schedule/promised';
export const OMS_ORDER_SCHEDULE_ALL = '/order/schedule/all';
export const ORDER_SCHEDULE_ACTION = (action) => `/order/schedule/${action}`;

export const OMS_RECEIPT_RECALCULATE_PAGING = (category: string) => `/order/receipt/${category}/recalculate/paging`;
export const OMS_RECEIPT_RECALCULATE_TOTAL = (category: string) => `/order/receipt/${category}/recalculate/total`;
export const OMS_RECEIPT_PAGING = (category: string) => `/order/receipt/${category}/paging`;
export const OMS_RECEIPT_TOTAL = (category: string) => `/order/receipt/${category}/total`;
export const OMS_RECEIPT_EXCEL = (category: string) => `/order/receipt/${category}/excel`;
export const OMS_RECEIPT_RECALCULATE_EXCEL = (category: string) => `/order/receipt/${category}/recalculate/excel`;
export const OMS_RECEIPT_ADJUSTMENT_SAVE = (category: string) => `/order/receipt/${category}/adjustment/save`;
export const OMS_RECEIPT_DETAIL_SAVE = (category: string) => `/order/receipt/${category}/detail/save`;
export const OMS_RECEIPT_CREATE = (category: string) => `/order/receipt/${category}/create`;
export const OMS_RECEIPT_RECALCULATE = (category: string) => `/order/receipt/${category}/recalculate/create`;
export const OMS_RECEIPT_CALCULATE = (category: string) => `/order/receipt/${category}/calculate`;
export const WMS_FEE = (category: string, target: string, action: string) => `/warehouse/fee/${category}/${target}/${action}`;
export const WMS_RECEIPT = (category: string, target: string, action: string) => `/warehouse/receipt/${category}/${target}/${action}`;
export const OMS_FEE = (category: string, target: string, action: string) => `/order/fee/${category}/${target}/${action}`;

export const OMS_TRANSACTION_STATEMENT = (category: string, action: string) => `/order/transaction/statement/${category}/${action}`;

export const OMS_ORDER_SCHEDULE_REGION_LIST = '/order/schedule/region/list';
export const OMS_INVOICE_DETAIL = '/order/invoice/info';

export const ORDER_LIST_EXCEL_V2 = '/order/v2/list/excel';
export const ORDER_EXCEL_LIST_DETAIL = '/order/excel/list/detail';
export const ORDER_SAVE_EXCEL2 = '/order/save/excel/model';
export const ORDER_UPDATE_EXCEL = '/order/change/carNumber';
export const ORDER_ADD_INVOICE_EXCEL = '/order/invoice/external';
export const ORDER_USELESS_APPLIANCE = '/order/scrap/list';
export const ORDER_USELESS_REMOVE = '/order/scrap/remove';
export const ORDER_CS_LIST = '/order/counsel/board';
export const ORDER_CS_UDPATE = '/order/counsel/save';
export const ORDER_CS_SOLVE = '/order/counsel/solve';
export const ORDER_CS_OPEN = '/order/counsel/board';
export const ORDER_CS_FINISH = '/order/counsel/solve';

export const ORDER_SAVE_EXCEL_MODEL_V2 = '/order/save/excel/model/v2';
export const ORDER_SAVE_EXCEL_PRODUCT_V2 = '/order/save/excel/product/v2';
export const ORDER_CHANGE_PARCEL = '/order/change/parcel';
export const ORDER_CHANGE_PARCEL_SIMPLE = '/order/simple/change/parcel';

export const ORDER_SAVE_EXCEL = '/order/save/excel';
export const ORDER_SAVE_EXCEL_SIMPLE = '/order/save/simple/excel';

export const PURCHASE_MEMO_SAVE = '/warehouse/purchase/memo/save';
export const PURCHASE_DETAIL_MEMO_SAVE = '/warehouse/purchase/detail/memo/save';
export const PURCHASE_DETAIL_SERIAL_SAVE = `/warehouse/purchase/detail/serial/number/save`;
export const PURCHASE_DESCRIPTION_SAVE = '/warehouse/purchase/update/description';
export const PURCHASE_FINISH = '/warehouse/purchase/finish';
export const PURCHASE_SERIAL_BE_SAVE = '/warehouse/purchase/excel/schedule/serial/number/save'; // 입고
export const PURCHASE_SERIAL_SAVE = '/warehouse/purchase/excel/serial/number/save'; // 입고
export const PURCHSE_BODY_SAVE = '/warehouse/purchase/body/list/save';
/**
 * OMS_PRODUCT 상품
 */
export const OMS_PRODUCT_MODEL_GROUP = '/order/product/model/group';
export const OMS_PRODUCT_MODEL_INDIVIDUAL = '/order/product/model/individual';
export const OMS_PRODUCT_SAVE = '/order/product/save';
export const WMS_MODEL_MODI = '/warehouse/model/save/volume/weight';
export const OMS_PRODUCT_EXCEL_SAVE = '/order/product/save/excel';
export const OMS_PRODUCT_EXCEL_UPDATE = '/order/product/update/excel';
export const OMS_PRODUCT_SEARCH_ORDER = '/order/product/search/order';
export const OMS_PRODUCT = '/order/product';
export const OMS_PRODUCT_FEE_SAVE = '/order/product/fee/save';
export const OMS_PRODUCT_LIST = '/order/product/list';
export const OMS_PRODUCT_CONFIRM = '/order/product/confirm';
export const PRODUCT_SEARCH_DETAIL_PAGING = '/order/product/detail/list/paging';
export const OMS_PRODUCT_CRUD = '/order/productDetail/save';

export const CARGO = (action: string) => `/order/cargoManifest${action}`;
export const OMS = (action: string) => `/order/${action}`;
/**
 * WMS_MODEL 제품
 */
export const MODEL_STOCK_SEARCH = '/warehouse/model/stock/excel';
export const OMS_PRODUCT_DETAIL_EXCEL = '/order/product/list/excel/detail';
export const OMS_PRODUCT_MAIN_EXCEL = '/order/product/list/excel';
export const WMS_MODEL_APPROVE_LIST = '/warehouse/model/confirm/approve/list';
export const WMS_MODEL_STOCK_MODEL = '/warehouse/model/stock/model';
export const WMS_MODEL_STOCK = '/warehouse/model/stock'; // 옛날 api will deprecate
export const WMS_MODEL_STOCK_SEARCH = '/warehouse//model/stock/excel'; // 제일 많은 정보
export const WMS_MODEL_STOCK_MODEL_PAGING = '/warehouse/model/stock/list/paging';
export const OMS_ = '/warehouse/model/stock/list/paging';

export const WMS_MODEL = '/warehouse/model';
export const WMS_MODEL_SAVEONE = '/warehouse/model/saveone';
export const WMS_MODEL_SAVE = '/warehouse/model/save';
export const WMS_MODEL_REJECT = '/warehouse/model/confirm/reject';
export const WMS_MODEL_APPROVE = '/warehouse/model/confirm/approve';

export const PRODUCT_LIST_PAGING = '/order/product/list/paging';

/**
 * DRIVER 기사
 */
export const IMS_DRIVER_LIST_WHOLE = '/install/driver/all';
export const IMS_DRIVER_LIST = '/install/driver/list';
export const IMS_DRIVER_ID_LOOKFOR = '/user/nextId';
export const IMS_DRIVER_REGISTER = '/user/driver';
export const USER_DRIVER_EXCEL = '/user/driver/excel';
export const USER_DRIVER_UPDATE_DOCK_EXCEL = '/user/driver/excel/update';
export const IMS_DRIVER_MODELGROUP_DELETE = '/user/driver/modelGroup';
export const IMS_DRIVER_REST_DAY_LIST = '/install/driver/holiday/list';

/**
 * CODE MASTER 코드마스터
 */
export const MASTER_CODE_GROUP = '/master/code/group';
export const MASTER_CODE_LIST = '/master/code/list';
export const CODE_MASTER = '/master/code/web';
export const CODE_GROUP_MODEL = '/master/code/group/model';

/**
 * INFRA 인프라
 */
export const MASTER_INFRA = '/warehouse/infra';
export const INFRA_PAGING = '/warehouse/infra/paging';
export const INFRA_CREATE = '/warehouse/infra/create';
export const INFRA_DELETE = '/warehouse/infra/delete';
export const INFRA_DETAIL = '/warehouse/infra/main/detail';
export const INFRA_MAIN_SAVE = '/warehouse/infra/main/save';
export const INFRA_SUB_SAVE = '/warehouse/infra/sub/save';
export const INFRA_SUB_LIST = '/warehouse/infra/sub/detail';

export const PLACE_ORDER_SAMPLE_RENEW = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/placeorder-example.xlsx';

/**
 * GRID 그리드
 */
export const GRID = '/user/grid';
export const GRID_PARAM = '/user/grid/parameter';

/**
 * USER_PARTNER 거래처관리
 */

export const USER_QA_DRIVER_DETAIL = '/user/question/';
export const USER_QA_DRIVER = '/user/question/web';
export const USER_QA_DRIVER_SEARCH = '/user/question/';
export const USER_QA_DRIVER_DELETE = '/user/question/delete';
export const USER_QA_DRIVER_REGISTER = '/user/question/answer';
export const USER_PARTNER_LIST = '/user/partner/list';
export const USER_PARTNER_EXCEL = '/user/partner/list/excel';
export const USER_PARTNER_DETAIL = '/user/partner';
export const USER_PARTNER_PAGING = '/user/partner/list/paging';
/**
 * 대행료
 */
export const ACCOUNT_CLOSE_LIST = '/order/account/close';

export const SERIAL_NUMBER_MODIFY = '/warehouse/serial/number/modify';
export const SERIAL_NUMBER_EXCEL_SAVE = '/warehouse/serial/number/excel/save';
export const SERIAL_NUMBER_DISTRIBUTEDATE_SAVE = '/warehouse/serial/number/distributeDate/save';
export const SERIAL_NUMBER_ASSETTYPE_SAVE = '/warehouse/serial/number/assetType/save';
export const SERIAL_NUMBER_MANUFACTUREDATE_SAVE = '/warehouse/serial/number/manufactureDate/save';

/**
 * INVENTORY 재고 : DEF - 불용재고
 */

export const WAREHOUSE_LOAN_PAGING = `/warehouse/loan/component/paging`;
export const WAREHOUSE_LOAN_DETAIL = `/warehouse/loan/component/detail`;
export const WAREHOUSE_LOAN_EXCEL = `/warehouse/loan/component/excel`;

export const INVENTORY_LIVE = '/warehouse/inventory/live';
export const INVENTORY_LIVE_QUANTITY = '/warehouse/inventory/live/quantity';
export const INVENTORY_LIVE_TEMP = '/warehouse/inventory/live/temp';
export const INVENTORY_LIVE_EXCEL = '/warehouse/inventory/live/excel';
export const INVENTORY_LIVE_DETAIL_EXCEL = '/warehouse/inventory/live/detail/excel';

export const INVENTORY_LIVE_DEF = '/warehouse/inventory/live/defected';
export const INVENTORY_LIVE_DEF_QUANTITY = '/warehouse/inventory/live/defected/quantity';
export const INVENTORY_LIVE_DEF_DETAIL = '/warehouse/inventory/live/defected/detail';
export const INVENTORY_LIVE_DEF_TEMP = '/warehouse/inventory/live/defected/temp';

export const INVENTORY_MODIFICATION_ALL = '/warehouse/inventory/modification/all';
export const INVENTORY_MODIFICATION_AVAIL_LIST = '/warehouse/inventory/modification/list'; // 불용재고 조정 리스트
export const INVENTORY_MODIFICATION_AVAIL_NOTE = '/warehouse/inventory/modification/note'; // 불용재고 조정 비고 수정

export const INVENTORY_MODIFICATION_DEF_LIST = '/warehouse/inventory/modification/defected/list'; // 불용재고 조정 리스트
export const INVENTORY_MODIFICATION_DEF_LIST_PAGING = '/warehouse/inventory/modification/defected/list/paging';
export const INVENTORY_MODIFICATION_DEF_NOTE = '/warehouse/inventory/modification/defected/note'; // 불용재고 조정 비고 수정

export const INVENTORY_MODIFICATION_DEF_CONFIRM = '/warehouse/inventory/modification/defected/confirm';
export const INVENTORY_MODIFICATION_DEF_GET_MODEL = '/warehouse/inventory/modification/defected/centerCode';

export const INVENTORY_DEF_CODE_LIST = '/warehouse/inventory/modification/defected/code/list';
export const INVENTORY_DEFDEF_CODE_LIST = '/warehouse/inventory/modification/defected/defected/code/list';

export const INVENTORY_INOUT_CENTER_LIST = '/warehouse/inventory/inout/center/model/list'; // 창고별 일일재고 현황 제품별 입출고 수량 목록
export const INVENTORY_INOUT_CENTER = '/warehouse/inventory/inout/center/model';
export const INVENTORY_INOUT_CENTER_MODEL_MODAL = '/warehouse/inventory/inout/center/model/modal';

export const WMS_INVENTORY_STATUS = '/warehouse/inventory/status';
export const WMS_INVENTORY_DAILY_STATUS = '/warehouse/inventory/status/daily';
export const INVENTORY_DAILY_DETAIL_INFO = '/warehouse/inventory/detailInfo';
export const INVENTORY_DAILY = '/warehouse/inventory/daily';
export const INVENTORY_DAILY_QUANTITY = '/warehouse/inventory/daily/quantity';

export const INVENTORY_LIVE_SERIAL_EXCEL = '/warehouse/serial/number/live/excel';
export const INVENTORY_LIVE_SERIAL = '/warehouse/serial/number/live/paging';
export const INVENTORY_LIVE_SERIAL_QUANTITY = '/warehouse/serial/number/live/quantity';

export const INVENTORY_DEFECTED_EXCEL = '/warehouse/inventory/live/defected/excel';
export const INVENTORY_DEFECTED_DETAIL_EXCEL = '/warehouse/inventory/live/defected/detail/excel';
export const INVENTORY_INOUT_EXCEL = '/warehouse/inventory/inout/excel';

export const INVENTORY_DAILY_EXCEL = '/warehouse/inventory/daily/excel';
export const INVENTORY_DAILY_DETAIL_EXCEL = '/warehouse/inventory/daily/detail/excel';
export const INVENTORY_DAILY_SELLER_EXCEL = '/warehouse/inventory/daily/excel/partner';
export const WMS_INVENTORY_SELLER_LIST = '/warehouse/inventory/selling';
export const INVENTORY_DAILY_SELLER_LIST = '/warehouse/inventory/daily/selling';
export const INVENTORY_DAILY_SELLER_QUANTITY = '/warehouse/inventory/daily/selling/quantity';
export const INVENTORY_DAILY_SELLER_LIST_EXCEL = '/warehouse/inventory/daily/selling/excel';
export const INVENTORY_DAILY_SELLER_LIST_DETAIL_EXCEL = '/warehouse/inventory/daily/selling/detail/excel';
export const INVENTORY_TRUNK_MODEL_LIST = '/warehouse/inventory/model/list/linehaul';

export const WMS_INOUT_AVAILABLE_LIST = '/warehouse/location/inOut/list';

export const INVENTORY_MODIFICATION_LIST = '/warehouse/inventory/modification/list';
export const INVENTORY_MODIFICATION_LIST_PAGING = '/warehouse/inventory/modification/list/paging';

// 로케이션 조정
export const WAREHOUSE_LOCATION_SAVE = '/warehouse/location/save';
export const INVENTORY_LOCATION_STATISTICS = '/warehouse/inventory/location/statistics';
export const INVENTORY_LOCATION_STATISTICS_LIST_PAGING = '/warehouse/location/list';

// 재고 조정을 위한 제품 조회
export const INVENTORY_MODIFICATION_DETAIL = '/warehouse/inventory/modification';
export const INVENTORY_MODIFICATION_DEF_DETAIL = '/warehouse/inventory/modification/defected';
export const INVENTORY_MODIFICATION_CONFIRM = '/warehouse/inventory/modification/confirm';

// 재고조정:: 시리얼 있음
export const INVENTORY_MODIFICATION_SAVE = '/warehouse/inventory/modification/save';
export const INVENTORY_MODIFICATION_DEF_SAVE = '/warehouse/inventory/modification/defected/save';

// 시리얼 없음
export const INVENTORY_MODIFICATION_SAVE_V2 = '/warehouse/inventory/v2/modification/save';
export const INVENTORY_MODIFICATION_DEF_SAVE_V2 = '/warehouse/inventory/v2/modification/defected/save';

export const INVENTORY_POPUP_INFO = '/warehouse/inventory/detailInfo';
export const INVENTORY_LIVE_INFO = '/warehouse/inventory/live';

/**
 * INVENTORY 재고 : 리팩토링 페이징 추가 된 API
 */

export const INVENTORY_DAILY_TEMP = '/warehouse/inventory/daily/temp';
export const INVENTORY_DAILY_SELLING_TEMP = '/warehouse/inventory/daily/selling/temp';
export const INVENTORY_INOUT_TEMP = '/warehouse/inventory/inout/temp';

/**
 * Fee 대행료
 */
export const MASTER_FEE = '/master/fee';
export const MASTER_FEE_CALCULATE_LIST = '/master/fee/calculate/list';

export const MASTER_FEE_PARTNER = '/master/fee/partner';
export const MASTER_FEE_REGION = '/master/fee/region';
export const MASTER_FEE_CANCEL = '/master/fee/cancelReason';
export const MASTER_FEE_STATUS = '/master/fee/invoiceStatus';
export const MASTER_FEE_FEETYPE = '/master/fee/invoiceType';
export const FEE_PARTNER_UPLOAD_EXCEL_SAMPLE = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/fee-partner-upload2.xlsx';
export const FEE_CANCELREASON_UPLOAD_EXCEL_SAMPLE = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/fee-cancelreason-upload.xlsx';
export const FEE_REGION_UPLOAD_EXCEL_SAMPLE = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/fee-region-upload.xlsx';
export const FEE_FEETYPE_UPLOAD_EXCEL_SAMPLE = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/fee-feetype-upload2.xlsx';
export const FEE_INVOICE_STATUS_UPLOAD_EXCEL_SAMPLE = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/fee-invoicestatus-upload2.xlsx';
export const WMS_MODEL_STOCK_FEE_STANDARD = '/warehouse/model/stock/fee/standard';
export const OMS_PRODUCT_FEE_STANDARD = '/order/product/fee/standard';

export const FEE_MODEL_UPLOAD_SAMPLE = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/fee_model_upload2.xlsx';

export const FEE_PRODUCT_UPLOAD_SAMPLE = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/fee_product_upload2.xlsx';

/**
 * Linehaul 간선
 */
export const WMS_ACTION = (action: string) => `/warehouse/${action}`;
export const WMS_WAREHOUSE = '/warehouse';
export const WMS_WAREHOUSE_INVENTORY_HOLD = '/warehouse/inventory/hold';
export const WMS_LINEHAUL_DETAIL = '/warehouse/linehaul/detail';
export const WMS_LINEHAUL = '/warehouse/linehaul';
export const WMS_LINEHAUL_PICKING_LIST_EXCEL = '/warehouse/linehaul/picking/list/excel';
export const WMS_LINEHAUL_LOADING_LIST = '/warehouse/linehaul/loading/web/list';
export const WMS_LINEHAUL_PICKING_LIST_EXCEL_DETAIL = '/warehouse/linehaul/picking/list/excel/detail';
export const WMS_LINEHAUL_PICKING_DETAIL = '/warehouse/linehaul/picking/detail';
export const WMS_LINEHAUL_PICKING_DETAIL_INVENTORY = '/warehouse/linehaul/picking/detail/inventory';
export const WMS_LINEHAUL_PICKING_DETAIL_INFO = '/warehouse/linehaul/picking/detail/info';
export const WMS_REMOVE_PICKING_DETAIL_INFO = '/warehouse/remove/picking/detail/info';
export const WMS_LINEHAUL_PICKING_EXCEL = '/warehouse/linehaul/picking/excel';
export const WMS_REMOVE_TRANSFER = '/warehouse/remove/transfer';
export const WMS_REMOVE_PICKING_TRANSFER_LIST = '/warehouse/remove/picking/transfer/inventory/list';
export const WMS_REMOVE_LOADING_COMPLETE = '/warehouse/remove/loading/complete/';
export const WMS_LINEHAUL_LOADING = '/warehouse/linehaul/loading';
export const WMS_LINEHAUL_LOADING_TEMP = '/warehouse/linehaul/loading/temp';
export const WMS_LINEHAUL_UNTREATED = '/warehouse/linehaul/untreated';
export const WMS_LINEHAUL_CONFIRM = '/warehouse/linehaul/confirm';
export const WMS_LINEHAUL_CONFIRM_BATCH = '/warehouse/linehaul/confirm/batch';
export const WMS_LINEHAUL_PICKING_MERGE = '/warehouse/linehaul/picking/merge';
export const WMS_LINEHAUL_PICKING_COMPLETE = '/warehouse/linehaul/picking/complete';
export const WMS_LINEHAUL_PICKING_COMPLETE_DETAIL = '/warehouse/linehaul/picking/complete/detail';
export const WMS_LINEHAUL_PICKING_LIST_PAGING = '/warehouse/linehaul/picking/list/paging';
export const WMS_LINEHAUL_BEFORE_LOADING = '/warehouse/linehaul/beforeLoading';
export const WMS_LINEHAUL_BEFORE_LOADING_EXCEL = '/warehouse/linehaul/beforeLoading/excel';
export const WMS_LINEHAUL_BEFORE_LOADING_TEMP = '/warehouse/linehaul/beforeLoading/temp';
export const WMS_LINEHAUL_LOADING_WEB = '/warehouse/linehaul/loading/web';
export const WMS_LINEHAUL_LOADING_WEB_TEMP = '/warehouse/linehaul/loading/web/temp';
export const WMS_LINEHAUL_LOADING_WEB_EXCEL = '/warehouse/linehaul/loading/web/excel';
export const WMS_LINEHAUL_LOADING_MERGE = '/warehouse/linehaul/loading/merge';
export const WMS_LINEHAUL_LOADING_COMPLETE = '/warehouse/linehaul/loading/complete';
export const WMS_LINEHAUL_LOADING_COMPLETE_DETAIL = (loadingStatementSeq) => `/warehouse/linehaul/loading/complete/${loadingStatementSeq}/detail`;
export const WMS_LINEHAUL_ACTION = (action) => `/warehouse/linehaul/${action}`;
export const WMS_COLLECT_EXCEL = (category) => `/warehouse/collect/${category}/excel`;
export const WMS_COLLECT_PAGING = (category) => `/warehouse/collect/${category}/paging`;
export const WMS_COLLECT_CONFIRM = '/warehouse/collect/confirm';
export const WMS_COLLECT_ROLLBACK = '/warehouse/collect/rollback';

export const WMS_COLLECT_WORKNOTE_SAVE = '/warehouse/collect/work/note/save';
export const WMS_COLLECT_DEFNOTE_SAVE = '/warehouse/collect/defected/note/save';
//이전 출차
export const MOVE_LINEHAUL_LOADING_WEB = '/warehouse/linehaul/move/list';
export const MOVE_LINEHAUL_LOADING_DETAIL = '/warehouse/linehaul/move';
export const MOVE_LINEHAUL_LOADING_CONFIRM = '/warehouse/linehaul/move/confirm';
export const MOVE_LINEHAUL_LOADING_CONFIRM_LIST = '/warehouse/linehaul/move/confirm/list';

export const MOVE_LINEHAUL_LOADING_DELETE = '/warehouse/linehaul/move/detail/remove';
export const LINEHAUL_TRANSFER_LIST = '/warehouse/linehaul/picking/transfer/inventory/list';
export const LINEHAUL_TRANSFER_REQUEST = '/warehouse/linehaul/transfer';

export const LINEHAUL_UNLOAD_IMG = '/warehouse/linehaul/unload/image';

export const WMS_LINEHAUL_UNLOADING_LIST = '/warehouse/linehaul/loading/unload';
export const WMS_LINEHAUL_UNLOADING_LIST_EXCEL = '/warehouse/linehaul/loading/unload/excel';
export const WMS_LINEHAUL_UNLOADING = '/warehouse/linehaul/loading/unload/detail';
export const WMS_LINEHAUL_LOADING_CONFIRM = '/warehouse/linehaul/loading/confirm';
export const LINEHAUL_LOADING_CHANGE = '/warehouse/linehaul/loading/driver';
export const WMS_LINEHAUL_BEFORE_LOADING_DETAIL = '/warehouse/linehaul/beforeLoading/detail';
export const WMS_LINEHAUL_LOADING_DETAIL = '/warehouse/linehaul/loading/detail';

export const LINEHAUL_BEFORE_LOADING_Detail_PRINT = '/warehouse/linehaul/beforeLoading/detail/print';
/**
 * Loan 출고
 */

export const WMS_LOAN_LIST = '/warehouse/loan/list';

export const WMS_LOAN_DETAIL = '/warehouse/loan';
export const WMS_LOAN_MODEL = '/warehouse/loan/model';

export const WMS_LOAN_SAVE = '/warehouse/loan/save';
export const WMS_LOAN_SAVE_SKIP = '/warehouse/loan/skip';
export const WMS_LOAN_RETURN = '/warehouse/loan/return';
export const LOAN_CANCLE = '/warehouse/loan/cancel';

export const SALES_CHANNEL = '/order/salesChannel';

// 창고리스트
export const CENTER_LIST_PAGING = '/warehouse/list/paging';
export const DIFF_CENTER_LIST = '/warehouse/list/diff';

export const CHANGO_CRUD = '/warehouse/save';

export const SCHEDULE_LIST = '/order/schedule';
export const SCHEDULE_PATH_DISTANCE = '/order/schedule/path/distance';
export const SCHEDULE_CONFIRM = '/order/schedule/confirm';
export const SCHEDULE_CHANGE = '/order/schedule/change';
export const SCHEDULE_CHANGE_LIST = '/order/schedule/change/list';
export const SCHEDULE_CHANGE_DETAIL_LIST = '/order/schedule/change/invoice/detail/list';
export const SCHEDULE_CAPA = (userId) => `/order/schedule/driver/${userId}/changableDate`;
export const SCHEDULE_DRIVER_CHANGE = (userId) => `/order/schedule/tracking/date/change`;

export const INSTALLATION_CONFIRM = (userId, customerOrderInvoiceSeq) => `/order/schedule/driver/${userId}/customerOrderInvoiceSeq/${customerOrderInvoiceSeq}`;

export const SCHEDULE_CONFIRM_ALL = '/order/schedule/confirm/all';
export const SCHEDULE_MESSAGE_ALL = '/order/schedule/confirm/sms';
export const SCHEDULE_CLOSE_DRIVER_DELIVERYDATE = '/order/schedule/close/driver/deliveryDate';
export const SCHEDULE_DELIVERY_MAGAM_PERSONAL = (userId) => `/order/schedule/close/driver/${userId}/deliveryDate`;

export const USER_ANNOUNCEMENT_PAGING = (category) => `/user/announcement/${category}/list/paging`;
export const USER_ANNOUNCEMENT_DETAIL = (announcementSeq) => `/user/announcement/${announcementSeq}/detail`;
export const USER_ANNOUNCEMENT_TEMPLIST = (category) => `/user/announcement/${category}/temp/list`;
export const USER_ANNOUNCEMENT_SAVE = (category) => `/user/announcement/${category}/save`;
export const USER_ANNOUNCEMENT_VIEW = (announcementSeq) => `/user/announcement/${announcementSeq}/view`;
export const USER_ANNOUNCEMENT_VIEW_INCREASE = (announcementSeq) => `/user/announcement/${announcementSeq}/view/increase`;
export const USER_ANNOUNCEMENT_DELETE = `/user/announcement/delete`;
export const USER_SHOULD_READ_5ANNOUNCEMENT = `/user/announcement/import/announcement`;

export const CREATE_LINEHAUL_PICKING_V4 = '/warehouse/linehaul/picking/v4';
export const WAREHOUSE_PURCHASE = '/warehouse/purchase';
export const WAREHOUSE_PURCHASE_SAVE_EXCEL = '/warehouse/purchase/save/excel';
export const WAREHOUSE_PURCHASE_IMG = '/warehouse/purchase/image';
export const WAREHOUSE_PURCHASE_IMG_SAVE = '/warehouse/purchase/image/save';
export const WAREHOUSE_PURCHASE_LIST_EXCEL = '/warehouse/purchase/list/excel';
export const WAREHOUSE_PURCHASE_LIST_EXCEL_DETAIL = '/warehouse/purchase/list/excel/detail';
export const WAREHOUSE_PURCHASE_LIST = '/warehouse/purchase/list';
export const WAREHOUSE_PURCHASE_LIST_PAGING = '/warehouse/purchase/list/paging';
export const WAREHOUSE_PURCHASE_BODY_SAVE = '/warehouse/purchase/body/save';
export const WAREHOUSE_PURCHASE_APPROVE = '/warehouse/purchase/approve';
export const WAREHOUSE_PURCHASE_APPROVE_LIST = '/warehouse/purchase/approve/list';
export const PURCHASE_CANCEL = (purchaseSeq) => `/warehouse/purchase/${purchaseSeq}/cancel`;

export const WAREHOUSE_PURCHASE_BULk = '/warehouse/purchase/receive/bulk';
export const WAREHOUSE_PURCHASE_LOCATION_BULK = '/warehouse/purchase/location/bulk';
export const WAREHOUSE_LOCATION_ADVICE_PURCHASE = '/warehouse/location/advice/purchase';
export const WAREHOUSE_LOCATION_LIST_CENTERCODE = '/warehouse/location/list';

// 우편번호
export const ZIPCODE_EXCEL = '/install/zipcode/excel';
export const ZIPCODE_EXCLUSION = '/install/zipcode/exclusion/list';
export const ZIPCODE_LIST_SEARCH = '/install/zipcode/list';
export const ZIPCODE_GROUP = '/install/zipcode/group';
export const ZIPCODE_GROUP_LIST = '/install/zipcode/group/list';
export const ZIPCODE_GROUP_RANGE_CENTER = '/install/zipcode/group/range/center';
export const POSTAL_ADD_LIST = '/install/zipcode/list';
export const ZIPCODE_EXCLUSION_SAVE = '/install/zipcode/exclusion/save';
export const ZIPCODE_ADD_AND_SAVE = '/install/zipcode/save';
export const ZIPCODE_LIST = '/install/zipcode/group/name/list';
export const ZIPCODE_EXCEL_UPLOAD = '/install/zipcode/group/driver/add/list';

export const ZIPCODE_SECTOR = '/install/zipcode/sector';
export const ZIPCODE_GROUP_WEEK = '/install/zipcode/group/week';
export const ZIPCODE_SECTOR_DRIVER_ADD = '/install/zipcode/sector/driver/add';
export const ZIPCODE_SECTOR_DRIVER_DELETE = '/install/zipcode/sector/driver/delete';

// 주문접수 샘플다운로드
export const OUT_UPLOAD = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/takeout_excel.xlsx';

export const TRUNK_UPLOAD = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/linehaul-picking-upload.xlsx';

export const TAKEOUT_UPLOAD = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/takeout_excel.xlsx';

export const ORDER_EXAMPLE_URL = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/order_example.xlsx';
export const ORDER_SAMPLE_URL = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/order-upload.xlsx';

export const MODEL_ORDER_UPLOAD_LITE_VER = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/register_model.xlsx';

export const MODEL_ORDER_UPDATE_URL = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/order-update-form.xlsx';
export const PRODUCT_ORDER_SAMPLE_URL = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/order_register_sangpum.xlsx';

export const TRANSPORT_DRIVER_SAMPLE_URL =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%E1%84%8B%E1%85%AE%E1%86%AB%E1%84%89%E1%85%A9%E1%86%BC_%E1%84%80%E1%85%B5%E1%84%89%E1%85%A1%E1%84%80%E1%85%AA%E1%86%AB%E1%84%85%E1%85%B5_%E1%84%8B%E1%85%A6%E1%86%A8%E1%84%89%E1%85%A6%E1%86%AF_%E1%84%8B%E1%85%A5%E1%86%B8%E1%84%85%E1%85%A9%E1%84%83%E1%85%B3_%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.xlsx';

export const TRANSPORT_ADDRESS_SAMPLE_URL =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%E1%84%8B%E1%85%AE%E1%86%AB%E1%84%89%E1%85%A9%E1%86%BC_%E1%84%8C%E1%85%AE%E1%84%89%E1%85%A9%E1%84%80%E1%85%AA%E1%86%AB%E1%84%85%E1%85%B5_%E1%84%8B%E1%85%A6%E1%86%A8%E1%84%89%E1%85%A6%E1%86%AF_%E1%84%8B%E1%85%A5%E1%86%B8%E1%84%85%E1%85%A9%E1%84%83%E1%85%B3_%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.xlsx';

export const PO_SAMPLE_URL = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/po-upload.xlsx';

export const UPLOAD_FORM_URL =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%E1%84%8C%E1%85%A6%E1%84%91%E1%85%AE%E1%86%B7%E1%84%83%E1%85%B3%E1%86%BC%E1%84%85%E1%85%A9%E1%86%A8%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.xlsx';

export const TAKEOUT_DELIVERY_SERIAL_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%8B%9C%EB%A6%AC%EC%96%BC%EC%97%91%EC%85%80%EB%93%B1%EB%A1%9D%EC%96%91%EC%8B%9D(%EA%B0%84%EC%84%A0%EC%B6%9C%EA%B3%A0%EC%99%84%EB%A3%8C).xlsx';

export const UPLOAD_MODEL =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%E1%84%8C%E1%85%A6%E1%84%91%E1%85%AE%E1%86%B7%E1%84%83%E1%85%B3%E1%86%BC%E1%84%85%E1%85%A9%E1%86%A8_%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.xlsx';

export const UPLOAD_MODEL_NEW = `https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/register_model_page.xlsx`;

export const FILE_URL = 'https://apigw.logimate.co.kr/v1/file';

export const S3_FILE_URL = 'https://apigw.logimate.co.kr/file/s3upload';

export const EDIT_ORDER = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/order_edit.xlsx';

export const BAECHA_EXCEL = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/DistributingCar.xlsx';

export const ZIPINSTALLER_EXCEL_SAMPLE = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/zipInstaller_upload_form.xlsx';

export const DRIVER_UPLOAD_EXCEL_SAMPLE = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/driver_upload.xlsx';

export const DRIVER_UPLOAD_EXCEL_SAMPLE_V2 = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/driver_upload_v2.xlsx';

export const CREATE_FC_PICKING_UPLOAD = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/fcpicking-upload.xlsx';

export const UNIT_PRICE = 'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/unit_Price.xlsx';

export const UPLOAD_SERVICE =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%E1%84%89%E1%85%A5%E1%84%87%E1%85%B5%E1%84%89%E1%85%B3%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.xlsx';

// po sync
export const PO_SYNC = 'https://apigw.logimate.co.kr/v1/po/sync';

// 우편전호 그룹 조회

export const INSTALL_ZIPCODE_GROUP_NAME_LIST_PACKAGE = '/install/zipcode/group/name/list?type=PACKAGE';
export const INSTALL_ZIPCODE_GROUP_NAME_LIST_GROUP = '/install/zipcode/group/name/list?type=GROUP';
export const INSTALL_ZIPCODE_GROUP_NAME_LIST_RANGE = '/install/zipcode/group/name/list?type=RANGE';
export const INTSALL_ZIPCODE_GROUP_NAME_LIST_ADD = '/install/zipcode/group/group/save';
export const INTSALL_ZIPCODE_RANGE_CHANGE = '/install/zipcode/group/group';
export const INTSALL_ZIPCODE_PACKAGE_CHANGE = '/install/zipcode/group/package';
export const INTSALL_LOCATE_DRIVER_SCHEDULE = '/install/zipcode/group/schedule';

export const COLLECT_PRINT = '/warehouse/collect/list/print';
// 설치기사 배송지역 조회

export const DELIVERY_ZIPCODE_AVAILABLE_DRIVER_LIST = '/install/zipcode/group/driver';
export const DELIVERY_LOCATION = '/install/zipcode/group/driver/list';
export const DELIVERY_ZIPGROUP_LIST = '/install/zipcode/group/driver/add';
export const DELIVERY_ZIPGROUP_REMOVE_LIST = '/install/zipcode/group/driver';
export const DELIVERY_ZIPGROUP_DRIVER_DELETE = '/install/zipcode/group/driver/delete';
export const DELIVERY_ZIPGROUP_DRIVER_ADDALL = '/install/zipcode/group/driver/list';
export const DELIVERY_ZIPGROUP_DRIVER_CLEAR = '/install/zipcode/group/driver/clear';

// 로케이션
export const LOCATION_CODE_LIST = '/warehouse/location/code/list';
export const LOCATION_MODEL = '/warehouse/location/list/model';
export const LOCATION_LISTS = '/warehouse/location/list/centerCode';
export const INVENTORY_LOCATION_LISTS = '/warehouse/inventory/location/list';
export const INVENTORY_LOCATION_LISTS_FOR_LOCATION = '/warehouse/inventory/location/list/all';
export const INVENTORY_LOCATION_LISTS_FOR_LOCATION_PAGING = '/warehouse/inventory/location/list/all/paging';

export const LOCATION_BASIC = '/warehouse/location/list';
export const LOCATION_CENTER_LOCATION_FETCH = '/warehouse/location'; // 해당 로케이션의 불용재고 관련 주문을 조회한다

// 재고반출
export const INVENTORY_RETURN = '/warehouse/remove';
export const INVENTORY_RETURN_LIST = '/warehouse/remove/list';
export const INVENTORY_RETURN_ACTION = '/warehouse/remove/save';
export const INVENTORY_RETURN_LOCATION = '/warehouse/remove/location';
export const INVENTORY_RETURN_CENTERCODE = '/warehouse/remove/centerCode';
export const INVENTORY_RETURN_CANCEL = '/warehouse/remove/cancel';
export const INVENTORY_RETURN_ROLLBACK = '/warehouse/remove/rollback';

// 검수
export const REMOVE_BEFORELOADING_PARCEL = '/warehouse/remove/beforeLoading/parcel';
export const REMOVE_LOADING_PARCEL = '/warehouse/remove/loading/parcel';
// 입고위치지정
export const INVENTORY_LOCATION_SETTING_SAVE = '/warehouse/location/setting/save';
export const INVENTORY_LOCATION_LOCATE_DETAIL = '/warehouse/location/select/detail';
export const INVENTORY_LOCATION_LOCATE_LIST = '/warehouse/location/select/list';
export const INVENTORY_LOCATION_LOCATE_PROCESS = '/warehouse/location/select/location';
export const INVENTORY_LOCATION_SELECT_PAGING = '/warehouse/location/select/paging';
export const INVENTORY_LOCATION_SELECT_LIST_LOCATION = '/warehouse/location/select/list/location';
export const LOCATION_LIST_EXCEL = '/warehouse/location/list/excel';

export const LOCATION_EXCEL_CREATE = '/warehouse/location/excel/create';
export const LOCATION_EXCEL_UPDATE = '/warehouse/location/excel/update';

export const INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION = '/warehouse/inventory/centerCode/locationCode/modelStock/movable/list';
export const INVENTORY_MODEL_FOR_LINEHAULMAKE = '/warehouse/inventory/centerCode/modelStock/movable/list';
export const INVENTORY_MODEL_FOR_LINEHAULMAKE_V2 = '/warehouse/inventory/centerCode/locationCode/modelStock/movable/list2';
export const INVENTORY_MODEL_FOR_All_LIST = '/warehouse/inventory/centerCode/modelStock/movable/reserved/etc/list';
export const INVENTORY_MODEL_FOR_LINEHAULMAKE_EXCEL = '/warehouse/inventory/centerCode/modelStock/movable/list2';
export const INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LIST = '/warehouse/linehaul/picking/import/list';
export const INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH__DETAILLIST = '/warehouse/linehaul/picking/import/detail/list';
export const INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH__DETAILLIST_V2 = '/warehouse/linehaul/picking/import/detail/list2';
export const INVENTORY_LINEHAUL_PICKING_MODEL_LIST = '/warehouse/linehaul/picking/model/list';

export const LINEHAUL_LOADSTAT_IMG = '/warehouse/linehaul/loadingStatement/image';
export const LINEHAUL_LOADSTAT_IMG_SAVE = '/warehouse/linehaul/loadingStatement/image/save';

export const REMOVE_LOADSTAT_IMG = '/warehouse/remove/loadingStatement/image';
export const REMOVE_LOADSTAT_IMG_SAVE = '/warehouse/remove/loadingStatement/image/save';

// 재고조사 지시
export const _INVENTORY_INVESTIGATE = '/warehouse/inventory/investigate';
export const INVENTORY_INVESTIGATE = '/warehouse/inventory/investigate/temp';
export const INVENTORY_INVESTIGATE_DETAIL = '/warehouse/inventory/investigate/detail';
export const INVENTORY_INVESTIGATE_CLOSE = '/warehouse/inventory/investigate/close';
export const INVENTORY_INVESTIGATE_CANCEL = '/warehouse/inventory/investigate/cancel';
export const INVENTORY_INVESTIGATE_REQUEST = '/warehouse/inventory/investigate/request';
export const INVENTORY_INVESTIGATE_GRANT = '/warehouse/inventory/investigate/request/grant';
export const INVENTORY_INVESTIGATE_REJECT = '/warehouse/inventory/investigate/request/reject';
export const INVENTORY_INVESTIGATE_RDC_PRINT = '/warehouse/inventory/investigate/print';

//재고추적
export const INVENTORY_TRACKING = '/warehouse/inventory/track/list';
export const INVENTORY_TRACKING_LIST = '/warehouse/inventory/track/list/temp';
export const INVENTORY_TRACKING_EXCEL = '/warehouse/inventory/track/list/excel';

// 창고열 조회
export const LOCATION_COLOUMN_LIST = '/warehouse/location/column/list';

// 간선생성

export const TRUNK_MAKE = '/warehouse/linehaul/picking/v2';
export const TRUNK_MAKE_V3 = '/warehouse/linehaul/picking/v3';

// 간선입고 마감
export const TRUNK_UNLOAD_CLOSE = '/warehouse/linehaul/loading/unload/close';

// 간선 취소

export const LINEHAUL_ROLLBACK = '/warehouse/linehaul/rollback';
export const LINEHAUL_CANCEL = '/warehouse/linehaul/cancel';

// 트래킹

export const TRAKING = '/order/tracking';
export const TRAKING_V2 = '/order/v2/tracking';
export const TRAKING_WITHOUT_TOKEN = '/order/tracking2';

export const RETURN_TRAKING = '/order/return/tracking';
export const RETURN_TRAKING_WITHOUT_TOKEN = '/order/return/tracking2';

// 반출
export const PICKING_PART_CANCEL = '/warehouse/remove/picking/cancel';

export const TAKE_OUT_REMOVE_CANCEL = '/warehouse/remove/cancel';

// 피킹
export const TAKE_OUT_PICKING_LIST = '/warehouse/remove/picking/list';
export const TAKE_OUT_PICKING_LIST_EXCEL = '/warehouse/remove/picking/list/excel';
export const TAKE_OUT_PICKING_LIST_BODY = '/warehouse/remove/picking/list/body';
export const TAKE_OUT_PICKING_DETAIL = '/warehouse/remove/picking/detail';
export const TAKE_OUT_PICKING_REGISTER = '/warehouse/remove/picking/new';
export const TAKE_OUT_PICKING = '/warehouse/remove/picking';
export const TAKE_OUT_PICKING_FINISH = '/warehouse/remove/finish'; //분실처리완료
export const TAKE_OUT_PICKING_COMPLETE = '/warehouse/remove/picking/complete'; //반출피킹완료
export const TAKE_OUT_PICKING_PHOTO = '/warehouse/remove/picking/image';

// 출고
export const TAKE_OUT_BEFORELOADING_LIST = '/warehouse/remove/beforeLoading';
export const TAKE_OUT_BEFORELOADING_LIST_EXCEL = '/warehouse/remove/beforeLoading/excel';
export const TAKE_OUT_BEFORELOADING_DETAIL = '/warehouse/remove/beforeLoading/detail';
export const TAKE_OUT_BEFORELOADING_PRINT = '/warehouse/remove//beforeLoading/detail/print';

// 상차
export const TAKE_OUT_BEFORELOADING_CONFRIM = '/warehouse/remove/loading/web';
export const TAKE_OUT_BEFORELOADING_CONFRIM_EXCEL = '/warehouse/remove/loading/web/excel';
export const TAKE_OUT_CANCLE = '/warehouse/remove/loading/cancel';

export const TAKE_OUT_LOADING = '/warehouse/remove/loading/web';
export const TAKE_OUT_LOADING_DETAIL = '/warehouse/remove/loading/detail';
export const TAKE_OUT_LOADING_CONFIRM = '/warehouse/remove/loading/confirm';

export const TAKE_OUT_LOADINGOUT_IMAGE = '/warehouse/remove/loadingStatement/image';
export const TAKE_OUT_LOADINGOUT_IMAGE_SAVE = '/warehouse/remove/loadingStatement/image/save';

export const PURCHASE_GET_EXCEL = '/warehouse/purchase/detail';
export const LINEHAUL_GET_EXCEL = '/warehouse/linehaul/loading/unload/detail/excel';
export const LINEHAUL_GET_DETAIL_EXCEL = '/warehouse/linehaul/loading/detail/excel';
export const REMOVE_LOADING_DETAIL_EXCEL = '/warehouse/remove/loading/detail/excel';

// fc-picking
export const FCPICKING_TOTAL_PAGING = '/warehouse/loan/picking/paging';
export const FCPICKING_TOTAL_DETAIL = '/warehouse/loan/picking/detail';
export const FCPICKING_EXCEL = '/warehouse/loan/picking/excel';
export const FCPICKING_DETAIL_EXCEL = '/warehouse/loan/picking/detail/excel';

export const FCPICKING_LOCATION_CHANGE = '/warehouse/loan/picking/location/change';
export const FCPICKING_CLOSE = '/warehouse/loan/picking/close';
export const FCPICKING_CONFIRM = '/warehouse/loan/picking/confirm';
export const FCPICKING_COMPLETE = '/warehouse/loan/picking/complete';
export const FCPICKING_ROLLBACK = '/warehouse/loan/picking/rollback';
export const FCPICKING_INVENTORY = '/warehouse/loan/picking/inventory';

// 수불현황

export const INVENTORY_INOUT = '/warehouse/inventory/inout';
export const INVENTORY_INOUT_DETAIL = '/warehouse/inventory/inout/detail';
export const INVENTORY_INOUT_MAIN_EXCEL = '/warehouse/inventory/inout/excel';
export const INVENTORY_INOUT_DETAIL_EXCEL = '/warehouse/inventory/inout/list/excel/detail';
export const INVENTORY_INOUT_DETAI_LOCATION_EXCEL = '/warehouse/inventory/inout/detail/location/excel';

//일배송마감
export const _ORDER_DAYILY_DELIVERY_MAGAM = '/order/close/list';
export const ORDER_DAYILY_DELIVERY_MAGAM = '/order/close/paging';
export const ORDER_CLOSE_EXCEL_LIST = '/order/close/excel/list';

export const DAIYL_CLOSE = '/order/close';

export const LOCATION_DELIVERY_INFO = '/order/schedule/zipcode';
export const LOCATION_DELIVERY_WEEK_ALL = '/order/schedule/zipcode/week';
export const LOCATION_DELIVERY_INFO_INSTALLER = '/install/driver/center';

// 기사관리
export const IMS_ONE_DRIVER = '/install/driver';
export const DRIVER_PAGING = '/install/driver/paging';
export const IMS_DRIVER_LIST_EXCEL = '/install/driver/excel';

export const CHANGE_DRIVER = '/order/schedule/change/driver';
export const DIVIDE_INVOICE = '/order/schedule/change/invoice/list';
export const REASSIGN_INVOICE = '/order/reassign/driver';

// 주문병경

export const CHANGE_CUSTOMER = '/order/change/customer';
export const CHANGE_RECEIVER = '/order/change/receiver';
export const CHANGE_MOVE = '/order/change/move';
export const CHANGE_INVOICE_CARNUMBER = '/order/change/carNumber';
export const CHANGE_LADDERCAR = '/order/change/ladderTruck';
export const CHANGE_LIFTING = '/order/change/lifting';
export const CHANGE_SCRAP = '/order/change/scrap';

// 상담일괄처리

export const COUNSEL_ILLKWAL = '/order/counsel/solve';

// 주문유형 스케줄

export const JUMUN_SCHEDULE = '/order/schedule/order';
export const DRIVER_CHANGE = '/order/schedule/change/driver';
export const DRIVER_CHANGE_REGION = '/order/schedule/change/driver/region';

//

export const MESSAGE_TYPE = '/order/message/type';

// 배차

export const BAECHA_LIST = '/warehouse/linehaul/transport';
export const TRANSPORT_DRIVER_INFO = '/warehouse/linehaul/transport/driver';
export const TRANSPORT_DRIVER_INFO_SAVE = '/warehouse/linehaul/transport/driver/save';
export const TRANSPORT_DRIVER_INFO_DELETE = '/warehouse/linehaul/transport/driver/delete';
export const AUTH_USERGROUP_SELECT_LIST = `/user/authority/userGroup/select/list`;
// 운영중인 창고 리스트
export const CENTER_FOR_LABELING = '/warehouse/select/list';
export const DRIVER_FOR_LABELING = '/install/driver/select/list';
export const PARTNER_FOR_LABELING = '/user/partner/select/list';

export const ORDER_PHOTO_SAVE = '/order/save/image';
export const PARCEL_PHOTO_SAVE = '/parcel/invoice/image/save';
//주문등록 엑셀양식
export const SAVE_EXCEL_TEMPLATE = '/order/save/excel/template';
export const EXCEL_TEMPLATE_LIST = '/order/excel/template/list';

export const GET_TEMPLATE_LIST_PAGING = '/order/message/template/paging';

export const GET_DASHBOARD_TODAY_DELIVERY = '/order/dashboard/today/delivery';
export const GET_DASHBOARD_TODAY_ORDER = '/order/dashboard/today/order';
export const GET_BEST_5_QNA = '/user/question/dashboard/today/qna';
export const GET_DASHBOARD_TODAY_DRIVER = '/order/dashboard/today/driver';
export const GET_DASHBOARD_TODAY_MODEL = '/order/dashboard/today/model';
export const GET_DASHBOARD_TODAY_REGION = '/order/dashboard/today/region';
export const GET_INVOICE_LIST = `/order/v2/invoice/list`;
export const GET_INVOICE_INSTALL_CERTI = `/order/v2/install/certificate`;
export const GET_INVOICE_DETAIL_V2 = '/order/v2/invoice/detail';

// 택배
export const PARCEL_INVOICE_PAGING = '/order/parcel/invoice/paging';
export const PARCEL_INVOICE_EXCEL = '/order/parcel/invoice/excel';
export const PARCEL_INVOICE_DETAIL = '/order/parcel/invoice/detail';
export const PARCEL_INVOICE_QUANTITY = '/order/parcel/invoice/quantity';
export const PARCEL_INVOICE_PRINT = '/order/invoice/parcel/print';
export const DROPSHIPPING_INVOICE_PRINT = '/order/dropshipping/print';
export const PARCEL_INVOICE_EXCEL_SAVE = '/order/parcel/invoice/excel/save';
export const PARCEL_INVOICE_SAVE = '/parcel/invoice/detail/save';
//피킹전략
export const WMS_PICKING_STRATEGY = '/warehouse/strategy';
export const WMS_PICKING_STRATEGY_DETAIL = '/warehouse/strategy/detail';
export const WMS_PICKING_STRATEGY_ACTIVATE = '/warehouse/strategy/activate';
export const WMS_PICKING_STRATEGY_DELETE = '/warehouse/strategy/delete';
export const WMS_PICKING_STRATEGY_SAVE = '/warehouse/strategy/save';
export const WMS_PICKING_STRATEGY_DETAIL_SAVE = '/warehouse/strategy/detail/save';

export const WMS_FCPICKING_CREATE = '/warehouse/loan/v2/pick/save/lite';
export const WMS_LINEHAUL_PICKING_CREATE_V3 = '/warehouse/linehaul/picking/v3';
export const ORDER_PRODUCT_FEE_EXCEL = '/order/product/fee/excel';
export const ORDER_PRODUCT_FEE = '/order/product/fee';

export const WMS_LINEHAUL_PICKING_PHOTO = '/warehouse/linehaul/picking/image';

export const ORDER_INVOICE_LEAD_TIME_PAGING = `/order/invoice/lead/time/paging`;
export const ORDER_INVOICE_LEAD_TIME_TOTAL = `/order/invoice/lead/time/total`;
export const ORDER_INVOICE_LEAD_TIME_EXCEL = `/order/invoice/lead/time/excel`;
export const ORDER_INVOICE_LEAD_TIME_DETAIL = `/order/invoice/lead/time/detail`;

export const ORDER_INVOICE_PROMISE_PAGING = `/order/invoice/promise/paging`;
export const ORDER_INVOICE_PROMISE_TOTAL = `/order/invoice/promise/total`;
export const ORDER_INVOICE_PROMISE_EXCEL = `/order/invoice/promise/excel`;
export const ORDER_INVOICE_PROMISE_DETAIL = `/order/invoice/promise/detail`;

export const ORDER_INVOICE_DAY_PAGING = `/order/invoice/day/paging`;
export const ORDER_INVOICE_DAY_TOTAL = `/order/invoice/day/total`;
export const ORDER_INVOICE_DAY_EXCEL = `/order/invoice/day/excel`;

export const ORDER_INVOICE_WEEK_PAGING = `/order/invoice/week/paging`;
export const ORDER_INVOICE_WEEK_TOTAL = `/order/invoice/week/total`;
export const ORDER_INVOICE_WEEK_EXCEL = `/order/invoice/week/excel`;

export const ORDER_INVOICE_MONTH_PAGING = `/order/invoice/month/paging`;
export const ORDER_INVOICE_MONTH_TOTAL = `/order/invoice/month/total`;
export const ORDER_INVOICE_MONTH_EXCEL = `/order/invoice/month/excel`;

export const PURCHASE_V2_SERIAL_PAGING = `/warehouse/purchase/v2/serial/list/paging`;
export const PURCHASE_V2_SERIAL_PRINT = `/warehouse/purchase/v2/serial/print`;

export const PURCHASE_UPDATE_PROMISEDATE = '/warehouse/purchase/update/promiseDate';

export const INVENTORY_LIVE_SCM_LIST_QUANTITY = `/warehouse/inventory/v2/live/scm/list/quantity`;
export const INVENTORY_LIVE_SCM_LIST_PAGING = `/warehouse/inventory/v2/live/scm/list/paging`;
export const INVENTORY_LIVE_SCM_DETAIL = `/warehouse/inventory/v2/live/scm/detail`;
export const INVENTORY_LIVE_SCM_LIST_EXCEL = `/warehouse/inventory/v2/live/scm/list/excel`;
export const INVENTORY_LIVE_SCM_DETAIL_EXCEL = `/warehouse/inventory/v2/live/scm/list/excel/detail`;

export const INVENTORY_PERIOD_SCM_LIST_PAGING = `/warehouse/inventory/v2/period/scm/list/paging`;
export const INVENTORY_PERIOD_SCM_DETAIL = `/warehouse/inventory/v2/period/scm/detail`;
export const INVENTORY_PERIOD_SCM_LIST_EXCEL = `/warehouse/inventory/v2/period/scm/list/excel`;
export const INVENTORY_PERIOD_SCM_DETAIL_EXCEL = `/warehouse/inventory/v2/period/scm/list/excel/detail`;

export const ORDER_SCHEDULE_CENTER_LIST = `/order/schedule/center/list`;

export const INVENTORY_MODEL_MOVE_PAGING = `/warehouse/inventory/model/move/paging`;
export const INVENTORY_MODEL_MOVE_EXCEL = `/warehouse/inventory/model/move/excel`;
export const INVENTORY_MODEL_MOVE_SAVE = `/warehouse/inventory/model/move/save`;
export const INVENTORY_MODEL_MOVE_INFO = `/warehouse/inventory/model/move/info`;
