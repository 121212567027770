import { COLORS, VALUES } from 'common/constants/appearance';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { PortalPositionDataProps } from '../modules/Tooltip';
import { useDispatch } from 'react-redux';
import { setShowDropdown } from 'redux/services/portalSlice';
import { TBRL } from '../types/types';
import { useDropdown } from '../hooks/useDropdown';
import { calculateElementPositionAndSize } from 'common/util/handleElement';
import { useLayoutEffect } from 'react';

export type DropdownProps = {
  $width: number;
  $position: TBRL;
  $align: TBRL;
  $data?: PortalPositionDataProps;
  children?: React.ReactNode;
  $maxHeight?: number;
  $minHeight?: number;
  $noBackdrop?: boolean;
};

export default function LDSDropdown({ children, $data, $noBackdrop, ...props }: DropdownProps) {
  const DropdownPortal = ({ children }) => {
    return createPortal(children, document.getElementById('dropdown') as HTMLElement);
  };

  const dispatch = useDispatch();
  const { dropdownRef, dropdownSize, setDropdownSize, windowHeight, windowWidth } = useDropdown();

  useLayoutEffect(() => {
    setDropdownSize(calculateElementPositionAndSize(dropdownRef.current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <DropdownPortal>
      <>
        {children && $data && !$noBackdrop && <BackdropStyle className="dropdown-backdrop" onClick={() => dispatch(setShowDropdown(''))} />}
        <DropdownContainer
          $data={$data}
          {...props}
          ref={dropdownRef}
          $dropdownWidth={dropdownSize.width}
          $dropdownHeight={dropdownSize.height}
          $innerWindowSize={{ height: windowHeight, width: windowWidth }}
        >
          {children}
        </DropdownContainer>
      </>
    </DropdownPortal>
  );
}

export type PortalProps = {
  children?: React.ReactNode;
};

export const BaseStyle = styled.div`
  z-index: ${VALUES.DROPDOWN_Z_INDEX};
  background-color: ${COLORS.WHITE};
  border-radius: ${VALUES.RADIUS}px;
  padding: 4px 0;
  border: 1px solid ${COLORS.GRAY_03};
  box-shadow: 0 1px 3px 0 ${COLORS.BLACK_015};
  overflow-y: auto;
  overflow-x: hidden;
`;

const DropdownContainer = styled(BaseStyle)<DropdownProps & { $dropdownWidth?: number; $dropdownHeight?: number; $innerWindowSize?: { height?: number; width?: number } }>`
  width: ${(props) => props.$width}px;
  position: ${(props) => (!props.$data ? 'relative' : 'fixed')};
  min-height: ${(props) => (props.$align === 'TOP' && props.$innerWindowSize.height < props.$data?.y + VALUES.DROPDOWN_MIN_HEIGHT ? `${VALUES.DROPDOWN_MIN_HEIGHT}px` : 'auto')};
  max-height: ${(props) =>
    props.$maxHeight
      ? `${props.$maxHeight}px`
      : props.$align === 'TOP' && props.$innerWindowSize.height - props.$data?.y - VALUES.BUFFER > VALUES.DROPDOWN_MAX_HEIGHT
      ? `${VALUES.DROPDOWN_MAX_HEIGHT}px`
      : props.$align === 'BOTTOM'
      ? null
      : `${props.$innerWindowSize.height - props.$data?.y - VALUES.BUFFER}px`};

  left: ${(props) =>
    (props.$position === 'BOTTOM' || props.$position === 'TOP') && props.$align === 'LEFT'
      ? props.$data?.x - VALUES.BUFFER / 2
      : (props.$position === 'BOTTOM' || props.$position === 'TOP') && props.$align === 'RIGHT'
      ? props.$data?.x + props.$data?.width - props.$dropdownWidth + VALUES.BUFFER / 2
      : props.$position === 'RIGHT'
      ? props.$data?.x + props.$data?.width + VALUES.BUFFER / 2
      : props.$position === 'LEFT'
      ? props.$data?.x - props.$dropdownWidth - VALUES.BUFFER / 2
      : 0}px;
  top: ${(props) =>
    props.$position === 'BOTTOM'
      ? `${props.$data?.y + props.$data?.height + VALUES.BUFFER / 2}px`
      : props.$position === 'TOP'
      ? `${props.$data?.y - props.$dropdownHeight - VALUES.BUFFER / 2}px`
      : (props.$position === 'LEFT' || props.$position === 'RIGHT') && props.$align !== 'BOTTOM' && props.$innerWindowSize.height < props.$data?.y + VALUES.DROPDOWN_MIN_HEIGHT
      ? 'auto'
      : (props.$position === 'LEFT' || props.$position === 'RIGHT') && props.$align === 'TOP'
      ? `${props.$data?.y - VALUES.BUFFER / 2}px`
      : (props.$position === 'LEFT' || props.$position === 'RIGHT') && props.$align === 'BOTTOM'
      ? 'auto' //`${props.$data?.y + props.$data?.height - props.$dropdownHeight + VALUES.BUFFER / 2}px`
      : '0px'};
  bottom: ${(props) => (props.$innerWindowSize.height < props.$data?.y + VALUES.DROPDOWN_MIN_HEIGHT ? `${VALUES.BUFFER}px` : 'auto')};
`;

export const BackdropStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${VALUES.DROPDOWN_Z_INDEX};
`;
