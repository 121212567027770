import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { InventoryCheckSearchDTO } from '../../../../_interface/warehouse';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = (props: ISearchBoxProps<InventoryCheckSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_MODEL: null,
    SEARCH_DATE: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">작업지시번호</label>
          <InputD
            value={searchObj?.inventoryCheckNumber || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                inventoryCheckNumber: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                  }
                : null
            }
            options={MASTER_OPS?.CENTER_AUTH}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">로케이션</label>
          <InputD
            value={searchObj?.locationCode || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                locationCode: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">작업자</label>
          <InputD
            value={searchObj?.registerId || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                registerId: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">기간검색</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_DATE
                    ? {
                        value: selectTypeObj?.SEARCH_DATE,
                        label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_DATE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_DATE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <FlatpickrD
                disabled={!selectTypeObj?.SEARCH_DATE}
                onClose={(option) => {
                  if (option?.length === 1)
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                      [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                    });
                }}
                value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                    [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">제품/제품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'right' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
