import { useCallback, useEffect, useState } from 'react';
import { IMS_DRIVER_LIST, OMS_ORDER_CHANGE_NOTE, SCHEDULE_LIST, SCHEDULE_CONFIRM_ALL, CHANGE_DRIVER, OMS_ORDER_DETAIL, SCHEDULE_CONFIRM, SCHEDULE_CHANGE_LIST, SCHEDULE_PATH_DISTANCE } from 'envVar';

import { httpClient } from 'common/http-client/axiosConfig';
import useDebounce from 'hooks/debounceHooks';

import { extractLocaleISODATE, returnDateyyyymmdd } from 'common/util/dateParsingFn';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';

// component
import Presenter from './presenter';
import { getTimeZone, selectlabel } from 'common/master/codeMasterReturnHelper';
import { serviceStore } from 'services/services';

export const defaultMapObj = {
  visible: false,
  data: null,
};

const Container = (props) => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state) => state.menu);
  const [searchObj, setSearchObj] = useState({
    deliveryDate: returnDateyyyymmdd(new Date()),
    centerCode: null,
    userId: null,
    partnerSeq: null,
    confirmFlag: null,

    promiseFlag: null,
    deliveryType: [],
  });

  const [filteredOptions, setFilteredOptions] = useState();
  const [scheduleBoardData, setScheduleBoardData] = useState();
  const [multiItemsPopupObj, setMultiItemsPopupObj] = useState({
    visible: false,
    data: null,
    pointSchedule: null,
    triggerFn: (data) => getScheduleDetail(data),
    messageFn: (data) => messagePerson(data),
    changeNoteFn: (data) => changeSchduleNote(data),
    confirmFn: (data) => confirmAll(data),
    confirmOne: (data) => IndividualScheduleConfirm(data),
    changeScheduleFn: (data) => handleMoveSchedule(data),
  });
  const [detailScheduleObj, setDetailScheduleObj] = useState({
    visible: false,
    data: null,
    triggerFn: (data) => getScheduleDetail(data),
    messageFn: (data) => messagePerson(data),
    changeNoteFn: (data) => changeSchduleNote(data),
    confirmOne: (data) => IndividualScheduleConfirm(data),
    changeScheduleFn: (data) => handleMoveSchedule(data),
  });

  const [popupDataObj, setPopupDataObj] = useState({
    visible: false,
    data: null,
    searchObj: null,
    masterOptions: null,
    triggerFn: (data) => popupDataSetting(data),
    confirmAllFn: (data) => confirmAll(data),
    changeFn: (data) => handleChangeSchedule(data),
  });

  const [mapObj, setMapObj] = useState(defaultMapObj);

  const handleMoveSchedule = async ({ driverName, data, time, searchObj } = {}) => {
    if (window.confirm(`${driverName} 기사님의 ${data?.[0].deliveryDatetime}에 배송하는 것으로 수정하시겠습니까?`)) {
      dispatch(setLoading('PUT'));
      httpClient.put(SCHEDULE_CHANGE_LIST, data).then((rs) => {
        if (rs.data.status === 200) {
          alert(rs?.data?.message);
          dispatch(setLoading('PUT'));
          fetchTableData(searchObj, masterOptions);
        } else {
          alert('스케줄이 변경이 실패했습니다');
        }
      });
    }
  };

  const handleChangeSchedule = async ({ arr = [], searchObj }) => {
    dispatch(setLoading('PUT'));
    const newArr = [];
    const filtered = arr?.filter((c) => c.newDate);
    filtered.forEach((each, idx) => {
      if (each?.customerOrderSeq) {
        const temp = {};
        temp.customerOrderInvoiceSeq = each?.customerOrderInvoiceSeq;
        temp.deliveryDate = each?.newDate;
        newArr.push(temp);
      }
    });

    httpClient.put(CHANGE_DRIVER, newArr).then((rs) => {
      if (rs?.status === 200) {
        alert(rs?.data?.code);
        fetchTableData(searchObj, masterOptions);
      }
    });
    dispatch(setLoading(null));
  };

  const changeSchduleNote = async ({ note, searchObj }) => {
    dispatch(setLoading('PUT'));
    const data = {
      customerOrderSeq: note?.customerOrderSeq,
      customerOrderInvoiceSeq: note?.customerOrderInvoiceSeq,
      callCenterNote: note?.callCenterNote,
    };

    httpClient.put(OMS_ORDER_CHANGE_NOTE, data).then((rs) => {
      if (rs?.status === 200) {
        alert(rs.data.code);
        fetchTableData(searchObj, masterOptions);
      }
    });
    dispatch(setLoading(null));
  };

  const paramingSearchObjToUrl = (url, searchObj) => {
    if (searchObj?.deliveryDate) url += `&deliveryDate=${searchObj?.deliveryDate}`;
    if (searchObj?.centerCode) url += `&centerCode=${searchObj?.centerCode}`;
    if (searchObj?.userId) url += `&userId=${searchObj?.userId}`;
    if (searchObj?.partnerSeq) url += `&partnerSeq=${searchObj?.partnerSeq}`;
    if (searchObj?.confirmFlag !== null) url += `&confirmFlag=${searchObj?.confirmFlag}`;
    if (searchObj?.promiseFlag !== null) url += `&promiseFlag=${searchObj?.promiseFlag}`;

    if (searchObj?.deliveryType?.length > 0) {
      searchObj?.deliveryType?.forEach((ele) => {
        url += `&deliveryType=${ele.value}`;
      });
    }
    return url;
  };

  const fetchDrivers = async (searchObj) => {
    let url = IMS_DRIVER_LIST + `?&centerCode=${searchObj?.centerCode}`;
    return httpClient
      .get(url)
      .then((rs) => {
        if (rs?.status === 200) {
          return rs.data?.filter((ele) => ele.useFlag) || [];
        }
      })
      .catch((err) => {
        return null;
      });
  };

  const fetchSchedule = async (searchObj) => {
    let url = SCHEDULE_LIST + '?';
    url = paramingSearchObjToUrl(url, searchObj);

    return httpClient
      .get(url)
      .then((rs) => {
        if (rs?.status === 200) {
          return rs.data || [];
        }
      })
      .catch((err) => {
        return null;
      });
  };

  const fetchTableData = async (searchObj, masterOptions) => {
    if (!searchObj?.userId) {
      fetchCenterTableData(searchObj);
    } else {
      fetchDriverTableData(searchObj, masterOptions);
    }
  };

  const return2weeksArr = (todayDate) => {
    const arr = [];
    for (let index = 0; index < 14; index++) {
      var copiedDate = new Date(todayDate);
      var day = new Date(copiedDate.setDate(copiedDate.getDate() + index));
      arr.push(returnDateyyyymmdd(day));
    }
    return arr;
  };

  const fetchCenterTableData = useCallback(
    useDebounce(async (searchObj) => {
      const [drivers, scheduleArr] = await Promise.all([fetchDrivers(searchObj), fetchSchedule(searchObj)]);
      setCenterTableData(drivers, scheduleArr, searchObj);
    }, 2000),
    [],
  );

  const fetchDriverTableData = useCallback(
    useDebounce(async (searchObj, masterOptions) => {
      const [scheduleArr] = await Promise.all([fetchSchedule(searchObj)]);
      setDriverTableData(scheduleArr, searchObj, masterOptions);
    }, 2000),
    [],
  );

  // ****** fetchSchedule
  const setCenterTableData = async (drivers, scheduleArr, searchObj) => {
    const driverIdIsKeyObj = {}; // { 기사아이디1 : { confirm: number, unconfirm: number, timezone: { time1: obj, time2: obj }}, 기사아이디2: {...}, ...}

    scheduleArr?.sort((a, b) => a.userId - b.userId);
    const validSchedule = [];
    scheduleArr?.forEach((schedule, idx) => {
      // 초기화
      if (!driverIdIsKeyObj[schedule.userId]) {
        driverIdIsKeyObj[schedule.userId] = {
          userId: schedule.userId,
          confirm: 0,
          unConfirm: 0,
          timeZone: getTimeZone(true),
          deliveryDate: searchObj?.deliveryDate,
        };
      }
      const timeArr = extractLocaleISODATE(new Date(schedule.deliveryDatetime), 9).time.split(':');
      const h = timeArr[0];
      const hasTime = timeArr[0] + ':' + timeArr[1];

      if (parseInt(h) > 7) {
        const _confirm = driverIdIsKeyObj[schedule.userId]?.confirm;
        const _unConfirm = driverIdIsKeyObj[schedule.userId]?.unConfirm;
        const _timeZone = JSON.parse(JSON.stringify(driverIdIsKeyObj[schedule.userId])).timeZone;

        if (driverIdIsKeyObj[schedule.userId]?.timeZone?.[hasTime]) {
          _timeZone[hasTime].push(schedule);
          validSchedule.push(schedule);
        }

        driverIdIsKeyObj[schedule.userId] = {
          ...JSON.parse(JSON.stringify(driverIdIsKeyObj[schedule.userId])),
          confirm: schedule?.confirmFlag ? _confirm + 1 : _confirm,
          unConfirm: schedule?.confirmFlag ? _unConfirm : _unConfirm + 1,
          timeZone: _timeZone,
        };
      } else {
        console.log('/* 8:00 ~ 23:30 밖으로 표현되지 않은 스케줄이 있습니다', schedule);
      }
    });

    const scheduledDriverIds = Object.keys(driverIdIsKeyObj);
    const noScheduleDrivers = drivers?.filter((ele) => !scheduledDriverIds.includes(ele.userId));
    // 스케줄이 없는 기사들 초기화처리
    if (noScheduleDrivers?.length > 0) {
      noScheduleDrivers?.forEach((noScheduleDriver) => {
        Object.assign(driverIdIsKeyObj, {
          [noScheduleDriver.userId]: {
            confirm: 0,
            unConfirm: 0,
            deliveryDate: searchObj?.deliveryDate,
            timeZone: getTimeZone(true),
            userId: noScheduleDriver.userId,
          },
        });
      });
    }

    const scheduleBoardObj = {
      userId: [],
      driverName: [],
      centerName: [],
      deliveryDate: [],
      confirmCount: [],
      timeZone: getTimeZone(true),

      totalConfirm: 0,
      totalUnConfirm: 0,

      schedule: validSchedule,
    };

    Object.keys(driverIdIsKeyObj).forEach((userKey, idx) => {
      // userKey: userId
      const driverInfo = drivers.filter((ele) => ele.userId === userKey)?.[0];

      scheduleBoardObj.userId.push(userKey);
      scheduleBoardObj.driverName.push(driverInfo?.driverName);
      scheduleBoardObj.centerName.push(driverInfo?.centerCode ? selectlabel(driverInfo?.centerCode, masterOptions?.CENTER_WHOLE) : '');
      scheduleBoardObj.deliveryDate.push(driverIdIsKeyObj[userKey]?.deliveryDate);
      scheduleBoardObj.confirmCount.push(`${driverIdIsKeyObj[userKey]?.confirm}/${driverIdIsKeyObj[userKey]?.unConfirm}`);
      scheduleBoardObj.totalConfirm += driverIdIsKeyObj[userKey]?.confirm;
      scheduleBoardObj.totalUnConfirm += driverIdIsKeyObj[userKey]?.unConfirm;

      Object.keys(scheduleBoardObj?.timeZone).forEach((timeKey) => {
        scheduleBoardObj.timeZone[timeKey].push(driverIdIsKeyObj[userKey].timeZone[timeKey]);
      });
    });

    dispatch(setLoading(null));
    setScheduleBoardData(scheduleBoardObj);
  };

  const setDriverTableData = (scheduleArr, searchObj, masterOptions) => {
    const deliveryDateIsKeyObj = {}; // { 기사아이디1 : { confirm: number, unconfirm: number, timezone: { time1: obj, time2: obj }}, 기사아이디2: {...}, ...}
    const days14 = return2weeksArr(searchObj?.deliveryDate);
    const validSchedule = [];

    scheduleArr?.forEach((schedule, idx) => {
      // 초기화
      if (!deliveryDateIsKeyObj[schedule.deliveryDate]) {
        deliveryDateIsKeyObj[schedule.deliveryDate] = {
          confirm: 0,
          unConfirm: 0,
          timeZone: getTimeZone(true),
        };
      }
      const timeArr = extractLocaleISODATE(new Date(schedule.deliveryDatetime), 9).time.split(':');
      const h = timeArr[0];
      const hasTime = timeArr[0] + ':' + timeArr[1];

      if (parseInt(h) > 7) {
        const _confirm = deliveryDateIsKeyObj[schedule.deliveryDate]?.confirm;
        const _unConfirm = deliveryDateIsKeyObj[schedule.deliveryDate]?.unConfirm;
        const _timeZone = JSON.parse(JSON.stringify(deliveryDateIsKeyObj[schedule.deliveryDate])).timeZone;

        if (deliveryDateIsKeyObj[schedule.deliveryDate]?.timeZone?.[hasTime]) {
          _timeZone[hasTime].push(schedule);
          validSchedule.push(schedule);
        }
        deliveryDateIsKeyObj[schedule.deliveryDate] = {
          ...JSON.parse(JSON.stringify(deliveryDateIsKeyObj[schedule.deliveryDate])),
          confirm: schedule?.confirmFlag ? _confirm + 1 : _confirm,
          unConfirm: schedule?.confirmFlag ? _unConfirm : _unConfirm + 1,
          timeZone: _timeZone,
        };
      } else {
        console.log('/* 8:00 ~ 23:30 밖으로 표현되지 않은 스케줄이 있습니다', schedule);
      }
    });
    const noScheduleDeliveryDate = Object.keys(deliveryDateIsKeyObj);

    // 스케줄이 없는 기사들 초기화처리
    if (noScheduleDeliveryDate?.length !== 14) {
      days14?.forEach((deliDate) => {
        if (!noScheduleDeliveryDate.includes(deliDate)) {
          Object.assign(deliveryDateIsKeyObj, {
            [deliDate]: {
              confirm: 0,
              unConfirm: 0,
              timeZone: getTimeZone(true),
            },
          });
        }
      });
    }

    const scheduleBoardObj = {
      userId: [],
      driverName: [],
      deliveryDate: days14,
      confirmCount: [],
      timeZone: getTimeZone(true),

      totalConfirm: 0,
      totalUnConfirm: 0,

      schedule: validSchedule,
    };

    days14.forEach((dateKey, idx) => {
      // userKey: userId
      scheduleBoardObj.confirmCount.push(`${deliveryDateIsKeyObj[dateKey]?.confirm}/${deliveryDateIsKeyObj[dateKey]?.unConfirm}`); // 문자열임
      scheduleBoardObj.totalConfirm += deliveryDateIsKeyObj[dateKey]?.confirm;
      scheduleBoardObj.totalUnConfirm += deliveryDateIsKeyObj[dateKey]?.unConfirm;
      scheduleBoardObj.driverName.push(selectlabel(searchObj?.userId, masterOptions?.DRIVER_WHOLE));
      scheduleBoardObj.userId.push(searchObj?.userId);
      Object.keys(scheduleBoardObj?.timeZone).forEach((timeKey) => {
        scheduleBoardObj.timeZone[timeKey].push(deliveryDateIsKeyObj[dateKey].timeZone[timeKey]);
      });
    });

    dispatch(setLoading(null));
    setScheduleBoardData(scheduleBoardObj);
  };

  const popupDataSetting = async (data = {}) => {
    httpClient.get(SCHEDULE_LIST + `/${data.userId}/${data.deliveryDatetime}`).then((rs) => {
      if (rs?.status === 200) {
        if (rs.data?.length > 0) {
          setPopupDataObj({
            ...popupDataObj,
            visible: true,
            title: `[${data.deliveryDatetime}] 스케줄 변경`,
            data: rs.data || [],
            searchObj,
            masterOptions,
          });
        } else {
          alert('표시할 일정이 없습니다!');
        }
      }
    });
  };

  const fetchMapData = async (scheduleBoardData, idx) => {
    setDetailScheduleObj({
      ...detailScheduleObj,
      visible: false,
      data: null,
    });
    const userId = scheduleBoardData?.userId[idx];
    const deliveryDate = scheduleBoardData?.deliveryDate[idx];
    let latCenter = 0,
      lngCenter = 0,
      len = 0;
    const schedule = [];
    scheduleBoardData?.schedule?.forEach((eachData, idx) => {
      if (eachData.userId === userId) {
        schedule.push(eachData);
        latCenter += eachData.latitude;
        lngCenter += eachData.longitude;
        len += 1;
      }
    });

    if (len < 1) {
      alert('표시할 일정이 없습니다!');
    } else {
      latCenter = latCenter / len;
      lngCenter = lngCenter / len;
      const rs = await httpClient.get(SCHEDULE_PATH_DISTANCE + `/${userId}/${deliveryDate}`);
      if (rs?.status === 200) {
        const mapPanelData = [];
        rs.data.forEach((sc) => {
          mapPanelData.push({
            ...sc,
            changedDeliveryDatetime: sc.deliveryDatetime,
          });
        });
        setMapObj({
          ...mapObj,
          visible: true,
          center: new window.naver.maps.LatLng(latCenter, lngCenter),
          schedule: schedule,
          mapPanelData: mapPanelData,
        });
      }
    }
  };

  const fetchScheduleDetail = async (customerOrderInvoiceSeq) => {
    setMapObj(defaultMapObj);

    return httpClient
      .get(SCHEDULE_LIST + `/${customerOrderInvoiceSeq}`)
      .then((rs) => {
        if (rs?.status === 200) {
          return rs.data || [];
        }
      })
      .catch((err) => {
        return null;
      });
  };

  const fetchOrderDetail = async (invoiceSeq) => {
    const rs = await serviceStore.orderAction(`v2/invoice/detail`, 'GET', { invoiceSeq });
    return rs?.data;
  };

  const getScheduleDetail = async (data) => {
    dispatch(setLoading('GET'));
    const [scheduleDetail, orderDetail] = await Promise.all([fetchScheduleDetail(data.customerOrderInvoiceSeq), fetchOrderDetail(data.customerOrderInvoiceSeq)]);
    if (orderDetail && scheduleDetail) {
      const _data = Object.assign(orderDetail, scheduleDetail);
      dispatch(setLoading(null));
      return _data;
    } else {
      alert('상세정보를 가져오는데 오류가 있습니다!');
    }

    dispatch(setLoading(null));
    return null;
  };

  useEffect(() => {
    if (searchObj?.centerCode) fetchDriver(searchObj?.centerCode);
  }, [searchObj?.centerCode]);

  const fetchDriver = async (centerCode) => {
    if (centerCode) {
      await httpClient.get(`${IMS_DRIVER_LIST}?centerCode=${centerCode}`).then((rs) => {
        const result = rs?.status === 200 ? rs.data : [];
        const DRIVER_NAME = result.map((driver) => {
          return {
            value: driver.userId,
            label: driver.driverName,
            // url: driver
          };
        });
        setFilteredOptions({
          ...filteredOptions,
          DRIVER_NAME,
        });
      });
    }
  };

  const confirmAll = async ({ items, searchObj }) => {
    if (items?.length === 0) {
      alert('스케줄리스트가 존재하지 않습니다');
    } else {
      const result = window.confirm(
        scheduleBoardData?.schedule.length ? '현재 페이지의 미확정 리스트를 전부 확정하시겠습니까?\n(*주문 승인된 건에 한해서만 확정처리됩니다)' : '선택한 스케줄들을 확정하시겠습니까?',
      );
      if (result) {
        dispatch(setLoading('PUT'));
        const dataArr = [];
        const moveErrArr = [];
        items?.forEach((ele, idx) => {
          if (ele.feeType === 'MOVE' && !ele.invoiceStatus.includes('FINISH')) moveErrArr.push(ele);
          if (ele?.confirmFlag === false && ele.invoiceStatus === 'ASSIGN') {
            const temp = {};
            temp.customerOrderSeq = ele?.customerOrderSeq;
            temp.customerOrderInvoiceSeq = ele?.customerOrderInvoiceSeq;
            dataArr.push(temp);
          }
        });
        if (moveErrArr.length > 0) alert('창고 확정이 안된 이전설치 스케줄이 있습니다. 창고 확정을 하신 후에 스케쥴을 확정해주세요!');

        httpClient.post(SCHEDULE_CONFIRM_ALL, dataArr).then((rs) => {
          if (rs?.status === 200) {
            alert('스케줄이 확정되었습니다');
            fetchTableData(searchObj, masterOptions);
          } else {
            alert('스케줄 확정이 실패하였습니다');
          }
        });
        dispatch(setLoading(null));
      }
    }
  };

  const IndividualScheduleConfirm = async ({ data, searchObj, originData }) => {
    if (data.feeType === 'MOVE' && data.invoiceStatus !== 'FINISH') alert('창고 확정을 하신 후에 스케쥴을 확정해 주세요!');
    else {
      dispatch(setLoading('PUT'));
      httpClient
        .post(SCHEDULE_CONFIRM, {
          customerOrderSeq: data.customerOrderSeq,
          customerOrderInvoiceSeq: data.customerOrderInvoiceSeq,
        })
        .then((rs) => {
          if (rs?.status === 200) {
            alert('스케줄이 확정되었습니다!');
            fetchTableData(searchObj, masterOptions);

            if (originData) {
              setMultiItemsPopupObj({
                ...multiItemsPopupObj,
                data: originData?.data,
              });
            } else {
              getScheduleDetail(data).then((rs) => {
                if (rs) {
                  setDetailScheduleObj({
                    ...detailScheduleObj,
                    visible: true,
                    data: rs,
                  });
                }
              });
            }
          }
        });
      dispatch(setLoading(null));
    }
  };

  const messageAll = async (schedule) => {
    dispatch(setLoading('PUT'));

    const data = { invoiceSeqs: schedule.map((ele) => ele.customerOrderInvoiceSeq) };

    await httpClient.post(`/order/message/schedule/send?`, data).then((rs) => {
      if (rs?.status === 200) {
        alert('알림 메시지 발송에 성공하였습니다!');
      }
    });
    dispatch(setLoading(null));
  };

  const messagePerson = async (element) => {
    const result = window.confirm(`현 스케줄에 대해 알림 메시지를 보내겠습니까?
    *지역번호로 시작하는 연락처는 배송안내 메시지를 받지 못할 수 있습니다.`);
    if (result) {
      dispatch(setLoading('PUT'));
      const dataArr = {
        invoiceSeqs: [element?.customerOrderInvoiceSeq],
      };

      await httpClient.post(`/order/message/schedule/send`, dataArr).then((rs) => {
        if (rs?.status === 200) {
          alert('알림 메시지 발송에 성공하였습니다!');
        }
      });
      dispatch(setLoading(null));
    }
  };

  const getBestDistance = () => {
    console.log(scheduleBoardData);
  };

  return (
    <Presenter
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      filteredOptions={filteredOptions}
      fetchMapData={fetchMapData}
      fetchTableData={fetchTableData}
      scheduleBoardData={scheduleBoardData}
      setScheduleBoardData={setScheduleBoardData}
      popupDataObj={popupDataObj}
      setPopupDataObj={setPopupDataObj}
      detailScheduleObj={detailScheduleObj}
      setDetailScheduleObj={setDetailScheduleObj}
      multiItemsPopupObj={multiItemsPopupObj}
      setMultiItemsPopupObj={setMultiItemsPopupObj}
      mapObj={mapObj}
      setMapObj={setMapObj}
      confirmAll={confirmAll}
      messageAll={messageAll}
      getBestDistance={getBestDistance}
      handleMoveSchedule={handleMoveSchedule}
    />
  );
};
export default Container;
