import { Col, Form, Row } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { DatePickrD, OptionItem, SelectD } from 'components/reactstrap/reactstrap';

import { InvoiceMonthSearchDTO } from '../../../_interface/order';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { KeyboardEvent, useEffect, useState } from 'react';

export const SearchBox = (props: ISearchBoxProps<InvoiceMonthSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    if (searchObj?.year && searchObj?.month) {
      const date = new Date();
      date.setFullYear(searchObj?.year);
      date.setMonth(searchObj?.month - 1);
      setDate(date);
    }
  }, [searchObj?.year, searchObj?.month]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">*날짜(완료일)</label>
          <DatePickrD
            value={searchObj?.year + '년 ' + searchObj?.month + '월'}
            onChange={(v) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  year: v.getFullYear(),
                  month: v.getMonth() + 1,
                };
              });
            }}
            selected={date}
            dateFormat="yyyy.MM"
            showMonthYearPicker
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.SELLER_SELLER_AUTH}
            value={searchObj?.partnerSeq?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">인프라</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.INFRA}
            value={searchObj?.infraSeq?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.INFRA?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                infraSeq: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.CENTER_AUTH}
            value={searchObj?.centerCode?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.CENTER_AUTH?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
      </Row>
    </Form>
  );
};
