import { useEffect, useRef, useState } from 'react';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { takeoutService } from '../../_services/service';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Index = () => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    dispatch(setLoading('GET'));
    const data = await takeoutService.getTakeoutNParcel();
    const dataKr = data?.map((row) => {
      return {
        ...row,
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER),
        statusKr: selectlabel(row.status, masterOptions?.REMOVE_STATUS),
        reasonKr: selectlabel(row.reason, masterOptions?.REMOVE_REASON),
      };
    });

    gridRef.current.setGridData(dataKr);
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '반출번호',
      dataField: 'stockRemoveSeq',
    },
    {
      headerText: '반출창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '반출대상',
      dataField: 'target',
    },
    {
      headerText: '반출사유',
      dataField: 'reasonKr',
    },
    {
      headerText: '반출요청수량',
      dataField: 'quantity',
    },
    {
      headerText: '반출완료수량',
      dataField: 'confirmQuantity',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '반출요청일',
      dataField: 'registerDatetime',
    },
    {
      headerText: '반출요청자',
      dataField: 'registerId',
    },
    {
      headerText: '반출마감일',
      dataField: 'confirmDatetime',
    },

    {
      headerText: '반출마감자',
      dataField: 'confirmId',
    },
  ];

  return (
    <div className="page-content">
      <div className="presenterGridBox">
        <div className="grid-button-area">
          <div
            className="green"
            onClick={() => {
              gridRef.current.exportAsXlsx({ fileName: '반출/택배' });
            }}
          >
            엑셀다운
          </div>
        </div>
        <GridBox gridRef={gridRef} columns={columns} gridProps={{ showRowAllCheckBox: false, showRowCheckColumn: false }} />
      </div>
    </div>
  );
};

export default Index;
