import { useState, useEffect, useRef, CSSProperties } from 'react';
import { returnDateyyyymmddHhmmssNotab } from 'common/util/dateParsingFn';
import * as IGrid from 'aui-grid';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { IGridPageRowSelectValues } from 'common/grid/gridBox';
import { RootState } from 'redux/store';
import { VALUES } from 'common/constants/appearance';

export interface rowsProps {
  [key: string]: string | number;
}
export interface PagingSettingProps {
  pageNo?: number;
  pageSize?: number;
  fetchNew?: boolean;
  filterCache?: {};
}

export interface PagingConnectorProps {
  mode?: string;
  count?: number;
}

export interface TmpExcelDataTriggerThisGridProps {
  title?: string;
  data?: any;
  id?: string;
}

export interface ValueCheckedRowsProps {
  dataField?: string;
}

export interface GetCheckedDataTriggerThisGridProps {
  type?: string;
  id?: string;
  items?: any;
  withRowIndex?: boolean;
}
export interface GetCheckedDataTriggerThisGridPropsReturnedWithRowIndex {
  rowIndex: number;
  item: any;
}

export interface ExportExcelThisGridProps {
  type?: string;
}

interface AuiGridProps extends IGrid.Props {
  rows: any[];
  columns: IGrid.Column[];
  footerLayout?: IGrid.Footer[];
  totalCount?: number;
  id: string;
  className?: string;
  style?: CSSProperties | undefined;
  settingOptions?: Partial<IGrid.Props>;
  exportExcelThisGrid?: ExportExcelThisGridProps;
  editItem?: (val: Array<any>) => void;
  getCheckedDataTriggerThisGrid?: GetCheckedDataTriggerThisGridProps;
  setGetCheckedDataTriggerThisGrid?: React.Dispatch<React.SetStateAction<GetCheckedDataTriggerThisGridProps>>;
  onCellClick?: (e: any) => void;
  onEditEndBefore?: (e: IGrid.CellEditEndBeforeEvent) => void;
  onEditEnded?: (e: IGrid.CellEditEndEvent) => void;
  valueCheckedRows?: ValueCheckedRowsProps;
  setExportExcelThisGrid?: (val: ExportExcelThisGridProps) => void;
  reloadTriggerThisGrid?: boolean;
  setReloadTriggerThisGrid?: (val: boolean) => void;
  pagingSetting?: PagingSettingProps;
  pagingDirection?: 'horizontal' | 'vertical';
  onClickPrevButton?: () => void;
  onClickNextButton?: () => void;

  tmpExcelDataTriggerThisGrid?: TmpExcelDataTriggerThisGridProps;
  setPagingSetting?: (val: PagingSettingProps) => void;
}

const AuiGrid: React.FC<AuiGridProps> = (props) => {
  const {
    // basic
    rows,
    totalCount = 0,
    columns = [],
    id = '',
    className = '',
    //options
    style = {},
    settingOptions = {},
    // functions
    editItem = () => {},
    getCheckedDataTriggerThisGrid = {}, // 체크된 값 반환
    setGetCheckedDataTriggerThisGrid = () => {},
    onCellClick = () => {},
    onEditEndBefore = () => {},
    onEditEnded = () => {},
    valueCheckedRows = {},
    exportExcelThisGrid = {},
    setExportExcelThisGrid = () => {},
    reloadTriggerThisGrid = false,
    setReloadTriggerThisGrid = () => {},
    // - 없앨것
    footerLayout = [],
    tmpExcelDataTriggerThisGrid = {},
    pagingSetting = undefined,
    setPagingSetting = () => {},
    onClickPrevButton = undefined,
    onClickNextButton = undefined,
  } = props;

  const { AUIGrid } = window as any;
  const [fixedColumn, setFixedColumn] = useState<number>();
  const [pageRowCount, setPageRowCount] = useState(pagingSetting?.pageSize || 100);
  const [thisGrid, setThisGrid] = useState();
  const [parentWidth, setParentWidth] = useState<number>();
  const [isAUIMounted, setIsAUIMounted] = useState<boolean>(true);
  const dispatch = useDispatch();

  const { openOrclose } = useSelector((state: RootState) => state.menu);
  /**
   * IMPORT AUIGRID
   */
  const [pagingConnector, setPagingConnector] = useState<PagingConnectorProps | null>();

  // useEffect(() => {
  //   console.log(pagingSetting);
  // }, [pagingSetting]);

  useEffect(() => {
    if (pagingSetting?.pageNo && pagingConnector?.count) {
      if (pagingConnector?.mode === 'pageNo') {
        if (pagingSetting?.pageNo + pagingConnector?.count < 1) {
          alert('첫 페이지 입니다.');
        } else if (pagingSetting?.pageNo + pagingConnector?.count > (rows[0].maxPage as number)) {
          alert('마지막 페이지 입니다.');
        } else {
          setPagingSetting({
            ...pagingSetting,
            pageNo: pagingSetting?.pageNo + pagingConnector?.count,
            fetchNew: true,
          });
        }

        setPagingConnector(null);
      }
    }
  }, [pagingConnector?.count]);

  const createPagingButtons = (id: string) => {
    const existNext = document.querySelector(`${id}.custom-nextBtn`);
    const existPrev = document.querySelector(`${id}.custom-prevBtn`);
    if (!existPrev && !existNext) {
      const panel = document.querySelector(`${id}`);
      const prevBtn = document.createElement('span');
      const nextBtn = document.createElement('span');

      prevBtn.setAttribute('class', 'custom-prevBtn');
      prevBtn.innerHTML = '<';

      nextBtn.setAttribute('class', 'custom-nextBtn');
      nextBtn.innerHTML = '>';

      if (onClickPrevButton) {
        prevBtn?.addEventListener('click', onClickPrevButton);
      } else {
        prevBtn?.addEventListener('click', customPagingPrev);
      }

      if (onClickNextButton) {
        nextBtn?.addEventListener('click', onClickNextButton);
      } else {
        nextBtn?.addEventListener('click', customPagingNext);
      }

      panel?.appendChild(prevBtn);
      panel?.appendChild(nextBtn);
    }
  };

  const customPagingPrev = () => {
    setPagingConnector({
      mode: 'pageNo',
      count: -1,
    });
  };

  const customPagingNext = () => {
    setPagingConnector({
      mode: 'pageNo',
      count: 1,
    });
  };

  useEffect(() => {
    if (tmpExcelDataTriggerThisGrid && tmpExcelDataTriggerThisGrid?.data?.length > 0) {
      AUIGrid?.setGridData(tmpExcelDataTriggerThisGrid?.id, tmpExcelDataTriggerThisGrid?.data);
      if (AUIGrid?.getProp(tmpExcelDataTriggerThisGrid?.id, 'currentPage') === 0) {
        AUIGrid?.movePageTo(tmpExcelDataTriggerThisGrid?.id, 1);
      }
      exportTo(tmpExcelDataTriggerThisGrid);
    }
  }, [tmpExcelDataTriggerThisGrid]);

  // 엑셀 내보내기(Export);
  const exportTo = (tmp: TmpExcelDataTriggerThisGridProps) => {
    const { id, title } = tmp;
    if (!AUIGrid.isAvailableLocalDownload(id)) {
      alert('로컬 다운로드 불가능한 브라우저 입니다. 서버 사이드로 전송하여 다운로드 처리하십시오.');
      return;
    }

    AUIGrid.exportAsXlsx(id, {
      fileName: `${returnDateyyyymmddHhmmssNotab(new Date(), '')}_${title}`,
    });
    setTimeout(() => {
      AUIGrid?.setGridData(id); // 원복
      if (AUIGrid?.getProp(id, 'currentPage') === 0) {
        AUIGrid?.movePageTo(id, 1);
      }
      dispatch(setLoading(null));
    }, 500);
  };

  useEffect(() => {
    // auto width control
    let parentDiv = document.querySelector('.main-content');
    // NOTE: 신규 디자인의 경우 main-content를 클래스로 갖는 요소가 없기에 page-content 클래스를 가진 요소를 부모 요소로 할당토록 변경
    if (!parentDiv) parentDiv = document.querySelector('.page-content');

    const observer = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        // your code
        for (let entry of entries) {
          const { width } = entry.contentRect;
          setParentWidth(width);
        }
      });
    });
    if (parentDiv) observer.observe(parentDiv);

    if (settingOptions?.fixedColumnCount) {
      setFixedColumn(settingOptions?.fixedColumnCount);
    } else {
      setFixedColumn(0);
    }

    if (settingOptions?.pageRowCount) {
      setPageRowCount(settingOptions?.pageRowCount);
    }

    if (id && columns) {
      createAuiGrid(`#${id}`, columns);
    }
    return () => {
      // closing auto width control
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (typeof thisGrid !== 'undefined') {
      setTimeout(
        () => {
          AUIGrid?.resize(thisGrid);
        },
        JSON.parse(localStorage.getItem('useNewDesignFlag')) === 1 ? VALUES.SIDEBAR_TRANSITION_TIME : 0, //NOTE: 신규 디자인 Sidebar Animation Duration 만큼 Delay 적용
      );
    }
  }, [parentWidth, openOrclose.side]);

  useEffect(() => {
    if (id) {
      if (pagingSetting?.fetchNew && rows?.length === 0) {
        // console.log('조회되는 값이 없습니다!', id);
      }

      if (rows?.length >= 0) {
        if (footerLayout) {
          AUIGrid.setFooter(`#${id}`, footerLayout);
        }
        AUIGrid?.setGridData(`#${id}`, rows); // row에 할당
        if (AUIGrid?.getProp(`#${id}`, 'currentPage') === 0) {
          AUIGrid?.movePageTo(`#${id}`, 1);
        }

        if (pagingSetting?.filterCache) {
          const filterKeys = Object.keys(pagingSetting?.filterCache);
          if (filterKeys?.length > 0) {
            filterKeys.forEach((key) => {
              console.log(`#${id}`, key, pagingSetting?.filterCache[key]);
              window.AUIGrid.setFilterByValues(`#${id}`, key, pagingSetting?.filterCache[key]);
            });
          }
        }
      }
      if (pagingSetting?.fetchNew)
        setPagingSetting({
          ...pagingSetting,
          fetchNew: false,
        });
    }
  }, [rows, rows?.length]);

  useEffect(() => {
    if (reloadTriggerThisGrid) {
      AUIGrid.destroy(`#${id}`);
      createAuiGrid(`#${id}`);
      setReloadTriggerThisGrid(false);
    }
  }, [reloadTriggerThisGrid]);

  // grid-function
  useEffect(() => {
    if (exportExcelThisGrid?.type) {
      exportThisGrid(exportExcelThisGrid);
      setExportExcelThisGrid({});
    }
  }, [exportExcelThisGrid]);

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.id === thisGrid) {
      getCheckedRowItems(getCheckedDataTriggerThisGrid);
    }
  }, [getCheckedDataTriggerThisGrid]);

  useEffect(() => {
    if (fixedColumn) {
      AUIGrid.setFixedColumnCount(thisGrid, Number(fixedColumn));
    }
  }, [fixedColumn]);

  const firstCh = useRef(false);

  useEffect(() => {
    if (pageRowCount) {
      if (!firstCh.current) {
        firstCh.current = true;
      } else {
        AUIGrid.setPageRowCount(thisGrid, pageRowCount);
        setPagingSetting({
          ...pagingSetting,
          pageSize: pageRowCount,
          pageNo: 1,
          fetchNew: true,
        });
      }
    }
  }, [pageRowCount]);

  const createAuiGrid = (id: string, columns?: IGrid.Column[]) => {
    const auiGridProps = {
      enableFilter: true,
      enableMovingColumn: true,
      enableRowCheckShiftKey: true,
      isRowAllCheckCurrentView: true,
      editable: true,
      enableMouseWheel: true,
      enableHScrollByWheel: true,
      usePaging: true,
      pageRowCount: pageRowCount,
      pageRowSelectValues: IGridPageRowSelectValues,
      headerHeight: 28,
      rowHeight: 28,
      pagingMode: 'simple',
      pagingPanelHeight: 34,
      autoGridHeight: false, // **
      fixedRowCount: 1, // **
      showPageRowSelect: false,
      setFixedColumnCount: fixedColumn,
      editableOnFixedCell: true,
      showRowNumColumn: true,
      enableCellMerge: true,
      settingOptions,

      // copySingleCellOnRowMode: true,
      selectionMode: 'multipleCells',

      // 있다면 footer
      showFooter: false,
      footerPosition: 'top',
      // 새로 fetch시에도 유지
      resetHScroll: false, // 가로
      resetVScroll: false, // 세로

      // 스크롤 반응
      wheelSensitivity: 1,
      ...settingOptions,
    };

    const myGrid = AUIGrid.create(id, columns, auiGridProps);
    // if (AUIGrid?.getProp(myGrid, 'currentPage') === 0) {
    //   AUIGrid?.movePageTo(myGrid, 1);
    // }
    setThisGrid(myGrid);
    if (footerLayout) {
      AUIGrid.setFooter(myGrid, footerLayout);
    }

    if (pagingSetting) {
      createPagingButtons(myGrid);
    }

    gridBindFunction(myGrid);
  };

  const gridBindFunction = (id: string) => {
    // 필터링시 체크된 것들 해제
    AUIGrid.bind(id, 'filtering', function () {
      AUIGrid.setAllCheckedRows(id, false);
    });
    // 셀 클릭 이벤트 바인딩
    AUIGrid.bind(id, 'cellClick', function (event: IGrid.CellClickEvent) {
      onCellClick(event);
    });

    if (valueCheckedRows && valueCheckedRows?.dataField) {
      AUIGrid.bind(id, 'rowCheckClick', function (event: IGrid.RowCheckClickEvent) {
        if (event.checked === true && event.item[valueCheckedRows?.dataField as string]) {
          AUIGrid.addCheckedRowsByValue(id, valueCheckedRows.dataField, event.item[valueCheckedRows.dataField as string]);
        } else if (event.checked === false && event.item[valueCheckedRows.dataField as string]) {
          AUIGrid.addUncheckedRowsByValue(id, valueCheckedRows.dataField, event.item[valueCheckedRows.dataField as string]);
        }
      });
    }
  };

  // 에디팅 끝나기전 이벤트 바인딩
  AUIGrid.bind(`#${id}`, 'cellEditEndBefore', function (event: IGrid.CellEditEndBeforeEvent) {
    if (onEditEndBefore) {
      return onEditEndBefore(event);
    }

    // }
  });

  // 엔터 후 수정
  AUIGrid.bind(`#${id}`, 'cellEditEnd', function (event: IGrid.CellEditEndEvent) {
    onEditEnded(event);
    AUIGrid.forceEditingComplete(`#${id}`);
  });

  // function
  const getCheckedRowItems = (obj: GetCheckedDataTriggerThisGridProps) => {
    if (obj?.withRowIndex) {
      const checkedItems = AUIGrid.getCheckedRowItems(obj.id);
      if (checkedItems?.length <= 0) {
        alert('선택된 항목이 존재하지 않습니다.');
        return;
      }
      setGetCheckedDataTriggerThisGrid({ items: checkedItems, type: obj.type });
    } else {
      const checkedItems = AUIGrid.getCheckedRowItemsAll(obj.id);
      if (checkedItems?.length <= 0) {
        alert('선택된 항목이 존재하지 않습니다.');
        return;
      }
      setGetCheckedDataTriggerThisGrid({ items: checkedItems, type: obj.type });
    }
  };

  const exportThisGrid = (obj: any) => {
    // 내보내기 실행
    switch (obj.type) {
      case 'xlsx':
        AUIGrid.exportToXlsx(obj.id, {
          exportWithStyle: 'checked',
        });
        break;

      case 'csv':
        AUIGrid.exportToCsv(obj.id);
        break;

      case 'txt':
        AUIGrid.exportToTxt(obj.id);
        break;

      case 'xml':
        AUIGrid.exportToXml(obj.id);
        break;

      case 'json':
        AUIGrid.exportToJson(obj.id);
        break;

      case 'pdf': // AUIGrid.pdfkit.js 파일을 추가하십시오.
        if (!AUIGrid.isAvailabePdf(obj.id)) {
          alert('PDF 저장은 HTML5를 지원하는 최신 브라우저에서 가능합니다.(IE는 10부터 가능)');
          return;
        }
        AUIGrid.exportToPdf(obj.id, {
          // 폰트 경로 지정 (반드시 지정해야 함)
          fontPath: '../public/AUIGrid/pdfkit/jejugothic-regular.ttf',
        });
        break;

      case 'object': // array-object 는 자바스크립트 객체임
        var data = AUIGrid.exportToObject(obj.id);
        alert(data);
        break;

      default:
        break;
    }
  };

  const fixedColumnOption = [
    { value: 0, label: '없음' },
    { value: 1, label: '1열' },
    { value: 2, label: '2열' },
    { value: 3, label: '3열' },
    { value: 4, label: '4열' },
    { value: 5, label: '5열' },
    { value: 6, label: '6열' },
    { value: 7, label: '7열' },
    { value: 8, label: '8열' },
    { value: 9, label: '9열' },
  ];

  // jsx render

  const renderBottom = async () => {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1);
    });
    setIsAUIMounted(false);
  };

  useEffect(() => {
    renderBottom();
  }, []);

  return (
    <div
      id={id}
      className={`${fixedColumn && fixedColumn > 0 ? 'fixedColumn' : ''} ${pagingSetting ? 'customPaging' : ''} ${className}`}
      style={{ width: '100%', position: 'relative', height: 800, ...style }}
    >
      {pagingSetting && rows?.length > 0 && !isAUIMounted ? (
        <>
          <div className="fake-paging">
            <span className="fixed-column">
              고정컬럼&nbsp;
              <select
                value={fixedColumn}
                onChange={(e) => {
                  setFixedColumn(parseInt(e.target.value));
                }}
              >
                {fixedColumnOption.map((ele, idx) => {
                  return (
                    <option key={`${thisGrid}_fixedColumn_${idx}`} value={ele.value}>
                      {ele.label}
                    </option>
                  );
                })}
              </select>
            </span>
            <div style={{ position: 'relative' }}>
              {pagingSetting && (
                <span className="paging-size">
                  {pagingSetting?.pageNo}&nbsp;/&nbsp;{rows[0]?.maxPage}
                </span>
              )}
              <span className="row-count">
                <select
                  value={pageRowCount}
                  onChange={(e) => {
                    setPageRowCount(parseInt(e.target.value));
                  }}
                >
                  {IGridPageRowSelectValues.map((ele, idx) => {
                    return (
                      <option key={`${thisGrid}_rowCount_${idx}`} value={ele}>
                        {ele}
                      </option>
                    );
                  })}
                </select>
              </span>
              <span className="total-size">총 {rows[0]?.totalCount ?? totalCount}건</span>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AuiGrid;
