import { useMemo, useRef, useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize';

import { Form, Col, Row } from 'reactstrap';

import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

// component
import Grid from 'components/grid/auiGrid';

import axios from 'axios';

// redux
import { useSelector, useDispatch } from 'react-redux';
import useToast from 'hooks/useToast';
import { handleFile, uploadFile } from 'common/util/photo';
import { setLoading } from 'redux/services/menuSlice';
import { httpClient } from 'common/http-client/axiosConfig';
import CommonModalNew from 'components/modal/commonModalNew';
import { NOTICE_IMAGE_BUCKET } from 'pages/DELIVERY/_asset/asset';

export const typeArrToKr = (arr) => {
  const str = [];
  if (arr.includes('erp')) {
    str.push('ERP웹');
  }
  if (arr.includes('logiInstall')) {
    str.push('로지설치+');
  }
  if (arr.includes('logiChango')) {
    str.push('로지창고+');
  }
  return str;
};
const Presenter = (props) => {
  const { authPopupObj, setAuthPopupObj, popupObj, setPopupObj, viewObj, setViewObj, searchObj } = props;

  return (
    <div className="page-content">
      {viewObj?.visible && <ViewList viewObj={viewObj} setViewObj={setViewObj} />}
      {authPopupObj?.visible && <AuthPopup authPopupObj={authPopupObj} setAuthPopupObj={setAuthPopupObj} searchObj={searchObj} />}
      {popupObj?.visible && <ReadOnlyPopup popupObj={popupObj} setPopupObj={setPopupObj} searchObj={searchObj} />}
      <div className="presenterSearch">
        <Search {...props} />
      </div>
      <div className="presenterGridBox">
        <GridBox {...props} />
      </div>
    </div>
  );
};

export default Presenter;

const Search = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const {
    searchObj,
    setSearchObj,
    fetchList,

    pagingSetting,
    setPagingSetting,
  } = props;

  useEffect(() => {
    if (pagingSetting.pageNo && pagingSetting?.fetchNew) {
      fetchList(searchObj);
    }
  }, [pagingSetting.fetchNew]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPagingSetting({ pageNo: 1, pageSize: 100, fetchNew: true });
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">등록일</label>
            <FlatpickrD
              value={searchObj?.deliveryDate}
              onReset={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    deliveryDate: null,
                  };
                });
              }}
              options={{
                mode: 'single',
              }}
              onChange={(date) => {
                setSearchObj({
                  ...searchObj,
                  deliveryDate: returnDateyyyymmdd(date[0]),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">대상</label>
            <SelectD
              isMulti
              value={searchObj?.types}
              options={masterOptions?.ANNOUNCE_TYPE}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  types: option,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">구분</label>
            <SelectD
              isMulti
              value={searchObj?.category}
              options={masterOptions?.ANNOUNCE_CATEGORY}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  category: option,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">제목</label>
            <InputD
              value={searchObj?.title || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  title: e.target.value,
                });
              }}
            />
          </Col>
          <Col style={{ textAlign: 'left' }}>
            <button
              className="btn btn-search"
              onClick={() => {
                setPagingSetting({ pageNo: 1, pageSize: 100, fetchNew: true });
              }}
            >
              조회
            </button>
          </Col>
          <Col />
        </Row>
      </Form>
    </>
  );
};

const ViewList = (props) => {
  const { viewObj, setViewObj } = props;
  return (
    <CommonModalNew
      title={'조회수'}
      visible={viewObj?.visible}
      setVisible={() => {
        setViewObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      children={
        <>
          <table className="border-table">
            <thead style={{ textAlign: 'center' }}>
              <tr>
                <th>No.</th>
                <th>조회자</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: 'center' }}>
              {viewObj?.data?.map((ele, idx) => {
                return (
                  <tr key={`viewList_${idx}`}>
                    <td>{idx + 1}</td>
                    <td>{ele}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      }
    />
  );
};

const GridBox = (props) => {
  const { reduxUserInfo } = useSelector((state) => state.auth);

  const {
    gridId,
    rows = [],
    columns = [],

    pagingSetting,
    setPagingSetting,

    authPopupObj,
    setAuthPopupObj,
    getCheckedDataTriggerThisGrid,
    setGetCheckedDataTriggerThisGrid,
  } = props;

  const letsWrite = async () => {
    const tempList = await authPopupObj?.getTmpFn();
    setAuthPopupObj((prev) => {
      return {
        ...prev,
        visible: true,
        data: null,
        tempList,
      };
    });
  };

  return (
    <>
      <div className="grid-button-area">
        {reduxUserInfo?.roles === 'ADMIN' && (
          <>
            <div
              className="blue"
              onClick={() => {
                letsWrite();
              }}
            >
              글쓰기
            </div>
            <div
              className="orange"
              onClick={() => {
                setGetCheckedDataTriggerThisGrid({
                  type: 'lets-modi',
                  id: `#${gridId}`,
                });
              }}
            >
              수정
            </div>
            <div
              className="red"
              onClick={() => {
                setGetCheckedDataTriggerThisGrid({
                  type: 'delete-rows',
                  id: `#${gridId}`,
                });
              }}
            >
              삭제
            </div>
          </>
        )}
      </div>

      <Grid
        id={gridId}
        rows={rows}
        columns={reduxUserInfo?.roles === 'ADMIN' ? columns : columns?.slice(-1)}
        // custompaging **
        pagingSetting={pagingSetting}
        setPagingSetting={setPagingSetting}
        getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
        setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
        settingOptions={{
          showRowAllCheckBox: reduxUserInfo?.roles === 'ADMIN' ? true : false,
          showRowCheckColumn: reduxUserInfo?.roles === 'ADMIN' ? true : false,
        }}
      />
    </>
  );
};

export const ReadOnlyPopup = (props) => {
  const { popupObj, setPopupObj, searchObj } = props;
  const [detailData, setDetailData] = useState();
  useEffect(() => {
    if (popupObj?.announcementSeq) {
      fetchDetail(popupObj?.announcementSeq);
    }
  }, []);

  const fetchDetail = async (announcementSeq) => {
    await viewIncrease(announcementSeq);
    const data = await getDetail(announcementSeq);
    if (data) setDetailData(data);
  };

  const getDetail = async (announcementSeq) => {
    return await httpClient.get(`/user/announcement/${announcementSeq}/detail`).then((rs) => {
      if (rs?.status === 200) {
        rs.data.typesArr = [rs.data?.erpFlag && 'erp', rs.data?.installAppFlag && 'logiInstall', rs.data?.warehouseAppFlag && 'logiChango'];
        return rs.data;
      }
      return null;
    });
  };

  const viewIncrease = async (announcementSeq) => {
    await httpClient.post(`/user/announcement/${announcementSeq}/view/increase`);
    if (searchObj) popupObj?.fetchListFn(searchObj);
  };

  return (
    <CommonModalNew
      title={`시스템 공지사항`}
      style={{ width: 1300 }}
      visible={popupObj?.visible}
      setVisible={() => {
        setPopupObj((prev) => {
          return {
            ...prev,
            visible: false,
            announcementSeq: null,
          };
        });
      }}
      children={
        <>
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {detailData?.type === 'UPDATE' ? (
                <div className="badge-green" style={{ display: 'inline-block' }}>
                  <div>업데이트</div>
                </div>
              ) : detailData?.type === 'IMPORTANT' ? (
                <div className="badge-red" style={{ display: 'inline-block' }}>
                  <div>중요</div>
                </div>
              ) : (
                <div className="badge-gray" style={{ display: 'inline-block' }}>
                  <div>일반</div>
                </div>
              )}
            </div>
            <div>작성일: {detailData?.registerDatetime}</div>
          </div>
          <div style={{ padding: 10 }}>
            {detailData?.typesArr &&
              typeArrToKr(detailData?.typesArr).map((ele, idx) => {
                return (
                  <span key={`typesarr_${idx}`}>
                    {idx !== 0 && <span style={{ margin: '0 5px', color: 'rgba(217, 222, 231, 1)' }}> | </span>} <span style={{ fontSize: 12 }}>{ele}</span>
                  </span>
                );
              })}
          </div>
          <div style={{ height: 60, overflowY: 'scroll', padding: 10, marginBottom: 10 }}>
            {detailData?.files?.length > 0 && <span>(총 {detailData?.files?.length}개의 파일)</span>}
            {detailData?.files?.map((ele, idx) => {
              return (
                <span
                  className="clickable"
                  key={`uploadedPdfFile${idx}`}
                  style={{ margin: '0 5px' }}
                  onClick={() => {
                    window.open(ele.uploadUrl);
                  }}
                >
                  <img src={require('assets/images/icon/download_blue.png')} style={{ width: 10, marginRight: 3 }} />
                  <span style={{ textDecoration: 'underline' }}>{ele.tableColumn}</span>
                </span>
              );
            })}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
          <div>
            <div style={{ padding: '5px 10px', fontSize: 20, fontWeight: 600 }}>{detailData?.title}</div>
            <div style={{ minHeight: 500, position: 'relative' }}>
              <div dangerouslySetInnerHTML={{ __html: detailData?.content }} style={{ minHeight: 500, overflowX: 'scroll' }} className="divPretendTextArea"></div>
            </div>
          </div>
        </>
      }
    />
  );
};

Quill.register('modules/imageResize', ImageResize);

const AuthTmpPopup = (prop) => {
  const { tempObj, setTempObj, setTmpToAuth, deleteTmpThen } = prop;

  const [canEditTmpList, setCanEditTmpList] = useState(null);
  const [tmpFlag, setTmpFlag] = useState(false);

  useEffect(() => {
    console.log(tempObj);
    InitCheckBox();
  }, [tempObj]);

  const InitCheckBox = () => {
    setTmpFlag(false);
    setCanEditTmpList(null);
  };

  const handleDeleteTargets = (e) => {
    let set = new Set(canEditTmpList);
    if (set.has(e.target.id)) {
      set.delete(e.target.id);
    } else {
      set.add(e.target.id);
    }
    setCanEditTmpList(Array.from(set));
  };

  const deleteTargets = async (data) => {
    const rs = await tempObj?.deleteFn({ announcementSeqs: canEditTmpList });
    if (rs?.status === 200) {
      deleteTmpThen({ announcementSeq: tempObj?.now });
    }
  };

  return (
    <CommonModalNew
      title={'임시저장글'}
      visible={tempObj?.visible}
      setVisible={() => {
        setTempObj((prev) => {
          return {
            ...prev,
            visible: false,
            now: null,
          };
        });
      }}
      children={
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 12, paddingRight: 0 }}>
            <span style={{ fontSize: 13 }}>
              총 <span className="orange">{tempObj?.tempList?.length}</span> 개
            </span>
            <span>
              {tmpFlag && (
                <button
                  className="btn btn-danger ssm"
                  onClick={() => {
                    deleteTargets();
                  }}
                >
                  삭제
                </button>
              )}
              <button
                className="btn btn-white ssm"
                onClick={() => {
                  setTmpFlag(!tmpFlag);
                  if (tmpFlag) setCanEditTmpList([]);
                }}
              >
                {tmpFlag ? '취소' : '편집'}
              </button>
            </span>
          </div>
          <div className="justLineList" style={{ maxHeight: 500 }}>
            {tempObj?.tempList?.map((ele, idx) => {
              return (
                <div key={`tempList_${idx}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
                  <div style={{ display: 'flex' }}>
                    {tmpFlag && <input type="checkbox" id={ele.announcementSeq} onChange={handleDeleteTargets} style={{ marginLeft: -10, marginRight: 10, marginTop: 8 }} />}
                    <div
                      style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
                      onClick={() => {
                        setTmpToAuth(ele);
                      }}
                    >
                      <div className="title">{ele.title}</div>
                      <div className="registerDatetime">{ele.registerDatetime}</div>
                    </div>
                  </div>
                  <div>{tempObj?.now === ele?.announcementSeq && <span className="orange">작성중</span>}</div>
                </div>
              );
            })}
          </div>
        </>
      }
    />
  );
};

const AuthPopup = (props) => {
  const dispatch = useDispatch();
  const QuillRef = useRef();
  const fileUploadRef = useRef();
  const [uploadedPdfFile, setUploadedPdfFile] = useState([]);

  const { authPopupObj, setAuthPopupObj, searchObj } = props;
  const [tempObj, setTempObj] = useState({
    visible: false,
    now: null,
    tempList: null,
  });
  const [boardObj, setBoardObj] = useState({
    typesArr: ['erp', 'logiInstall', 'logiChango'],
    category: 'GENERAL',
    title: null,
  });

  const { Toast, showToast } = useToast({
    message: '임시저장이 완료되었습니다',
    ms: 3000,
  });
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    const url = `${NOTICE_IMAGE_BUCKET}${new Date().toISOString()}`;

    input.onchange = async () => {
      if (input && input.files) {
        const file = input.files[0];
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = async () => {
          const arrayBuffer = reader.result;
          if (arrayBuffer) {
            try {
              await axios.put(url, arrayBuffer, {
                headers: {
                  'Content-Type': 'image',
                },
              });
            } catch (e) {
              console.log(e);
            }
            if (QuillRef.current) {
              const editor = QuillRef.current.getEditor();
              const range = editor.getSelection();
              editor.insertEmbed(range.index, 'image', url);
            }
          }
        };
      }
    };
  };

  useEffect(() => {
    console.log(authPopupObj?.data);
    if (authPopupObj?.data) {
      setBoardObj(authPopupObj?.data);
      setTempObj((prev) => {
        return {
          ...prev,
          now: authPopupObj?.data?.announcementSeq,
          tempList: authPopupObj?.tempList,
        };
      });
      setUploadedPdfFile(authPopupObj?.data?.files || []);
    } else {
      setBoardObj({
        typesArr: ['erp', 'logiInstall', 'logiChango'],
        category: 'GENERAL',
        type: 'UPDATE',
        erpFlag: false,
        installAppFlag: false,
        warehouseAppFlag: false,
        title: null,
        content: null,
        files: [],
        tempFlag: false,
      });
    }
  }, [authPopupObj]);

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          //[{ 'font': [] }],
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image'],
          [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
        handlers: {
          image: imageHandler,
        },
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
    };
  }, []);

  const formats = useMemo(() => {
    return [
      //'font',
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'align',
      'color',
      'background',
    ];
  }, []);

  const handleCheckbox = (e) => {
    if (e.target.id === 'all') {
      if (e.target.checked === true) {
        setBoardObj((prev) => {
          return {
            ...prev,
            typesArr: ['erp', 'logiInstall', 'logiChango'],
          };
        });
      } else {
        setBoardObj((prev) => {
          return {
            ...prev,
            typesArr: [],
          };
        });
      }
    } else {
      let set = new Set(boardObj?.typesArr);
      if (set.has(e.target.id)) {
        set.delete(e.target.id);
      } else {
        set.add(e.target.id);
      }
      const typesArr = Array.from(set);
      setBoardObj((prev) => {
        return {
          ...prev,
          typesArr,
        };
      });
    }
  };

  const handleRadiobox = (e) => {
    setBoardObj((prev) => {
      return {
        ...prev,
        type: e.target.id,
      };
    });
  };

  const handleTempsave = async (boardObj) => {
    const data = {
      ...boardObj,
      erpFlag: boardObj?.typesArr.includes('erp') ? true : false,
      installAppFlag: boardObj?.typesArr.includes('erp') ? true : false,
      warehouseAppFlag: boardObj?.typesArr.includes('erp') ? true : false,
      tempFlag: true,
    };
    delete data.typesArr;
    const rs = await authPopupObj?.saveTmpFn(data);
    if (rs) {
      const tempList = await authPopupObj?.getTmpFn(rs?.data?.announcementSeq);
      setAuthPopupObj((prev) => {
        return {
          ...prev,
          data: boardObj,
          tempList,
        };
      });
      showToast();
    }
  };

  const onFileUploaderChange = async (e) => {
    dispatch(setLoading('PUT'));
    const ret = await handleFile(e);
    const uploadUrl = await uploadFile(ret.file, ret.url, ret.file.name);
    if (uploadUrl) {
      const arr = JSON.parse(JSON.stringify(uploadedPdfFile));
      arr.push({
        uploadUrl,
        tableColumn: ret.file.name,
      });
      setUploadedPdfFile(arr);
    }
    dispatch(setLoading(null));
  };

  const deleteInPopup = async (data) => {
    if (window.confirm('이 글을 정말 삭제하시겠습니까?')) {
      const rs = await authPopupObj?.deleteFn({ announcementSeqs: [authPopupObj?.data?.announcementSeq] });
      if (rs?.status === 200) {
        authPopupObj?.fetchListFn(searchObj);
        setAuthPopupObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }
    }
  };

  return (
    <CommonModalNew
      title={'시스템 공지사항'}
      subTitle={'관리자전용'}
      style={{ width: 1100 }}
      visible={authPopupObj?.visible}
      setVisible={() => {
        setAuthPopupObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      children={
        <>
          <Toast />
          {tempObj?.visible && (
            <AuthTmpPopup
              tempObj={tempObj}
              setTempObj={setTempObj}
              deleteTmpThen={(data) => {
                authPopupObj?.getDetailFn(data);
              }}
              setTmpToAuth={(data) => {
                authPopupObj?.getDetailFn(data);
                // tempPopup끄기
                setTempObj((prev) => {
                  return {
                    ...prev,
                    visible: false,
                    now: null,
                    tempList: null,
                  };
                });
              }}
            />
          )}
          <div style={{ textAlign: 'right' }}>
            <div>
              <span
                className="btn-half btn-white"
                onClick={() => {
                  authPopupObj?.data ? alert('저장된 글은 임시저장 불가합니다') : handleTempsave(boardObj);
                }}
              >
                저장
              </span>
              <span
                className="btn-half btn-white"
                onClick={() => {
                  if (authPopupObj?.tempList?.length > 0) {
                    setTempObj((prev) => {
                      return {
                        ...prev,
                        visible: true,
                        tempList: authPopupObj?.tempList,
                        now: authPopupObj?.data?.announcementSeq,
                        deleteFn: authPopupObj?.deleteFn,
                      };
                    });
                  }
                }}
              >
                {authPopupObj?.tempList?.length || 0}
              </span>
              {authPopupObj?.data?.tempFlag === false && (
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    deleteInPopup();
                  }}
                >
                  삭제
                </button>
              )}

              <button
                className="btn btn-secondary sm"
                onClick={() => {
                  //
                  if (window.confirm(`등록하시겠습니까?`)) {
                    const data = {
                      ...boardObj,
                      erpFlag: boardObj?.typesArr.includes('erp') ? true : false,
                      installAppFlag: boardObj?.typesArr.includes('erp') ? true : false,
                      warehouseAppFlag: boardObj?.typesArr.includes('erp') ? true : false,
                      tempFlag: 'false',
                    };

                    if (uploadedPdfFile?.length > 0) {
                      data.files = uploadedPdfFile;
                    }

                    delete data.typesArr;
                    authPopupObj?.saveFn({ data, searchObj });
                  }
                }}
              >
                {authPopupObj?.data?.tempFlag === false ? '수정' : '등록'}
              </button>
            </div>
          </div>

          <div style={{ height: 60, overflowY: 'scroll', padding: 10, margin: '10px 0' }}>
            {uploadedPdfFile?.length > 0 && <span>(총 {uploadedPdfFile?.length}개의 파일)</span>}
            {uploadedPdfFile?.map((ele, idx) => {
              return (
                <span className="clickable" key={`uploadedPdfFile${idx}`} style={{ margin: '0 5px' }}>
                  <img
                    src={require('assets/images/icon/download_blue.png')}
                    style={{ width: 10, marginRight: 3 }}
                    onClick={() => {
                      window.open(ele.uploadUrl);
                    }}
                  />
                  <span
                    style={{ textDecoration: 'underline' }}
                    onClick={() => {
                      window.open(ele.uploadUrl);
                    }}
                  >
                    {ele.tableColumn}
                  </span>
                  <img
                    src={require('assets/images/close/closeIcon_black.png')}
                    style={{ width: 7, marginLeft: 5 }}
                    onClick={(e) => {
                      e.preventDefault();
                      const filtered = uploadedPdfFile.filter((ele, i) => i !== idx);
                      setUploadedPdfFile(filtered);
                    }}
                  />
                </span>
              );
            })}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div className="check-area">
              <span>대상</span>
              <input type="checkbox" id="all" checked={boardObj?.typesArr?.length === 3} onChange={handleCheckbox} />
              <label htmlFor="all">전체</label>
              <input type="checkbox" id="erp" checked={boardObj?.typesArr?.includes('erp')} onChange={handleCheckbox} />
              <label htmlFor="erp">ERP</label>
              <input type="checkbox" id="logiInstall" checked={boardObj?.typesArr?.includes('logiInstall')} onChange={handleCheckbox} />
              <label htmlFor="logiInstall">로지설치+</label>
              <input type="checkbox" id="logiChango" checked={boardObj?.typesArr?.includes('logiChango')} onChange={handleCheckbox} />
              <label htmlFor="logiChango">로지창고+</label>
            </div>
            &nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="check-area">
              <span>구분</span>
              <input type="radio" id="IMPORTANT" name="type" checked={boardObj?.type === 'IMPORTANT'} onChange={handleRadiobox} />
              <label htmlFor="IMPORTANT">중요</label>
              <input type="radio" id="UPDATE" name="type" checked={boardObj?.type === 'UPDATE'} onChange={handleRadiobox} />
              <label htmlFor="UPDATE">업데이트</label>
              <input type="radio" id="GENERAL" name="type" checked={boardObj?.type === 'GENERAL'} onChange={handleRadiobox} />
              <label htmlFor="GENERAL">일반</label>
            </div>
          </div>
          <div>
            <div>
              <InputD
                className="ql-editor-title"
                value={boardObj?.title || ''}
                placeholder="제목"
                onChange={(e) => {
                  setBoardObj((prev) => {
                    return {
                      ...prev,
                      title: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div style={{ minHeight: 500, position: 'relative' }}>
              <span style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <img style={{ width: 22 }} src={require('assets/images/icon/add_file_icon.png')} alt="파일업로드" /> &nbsp;&nbsp;파일업로드
                <input ref={fileUploadRef} style={{ position: 'absolute', width: 80, height: 20, right: 0 }} type="file" id="file" accept="application/pdf" onChange={onFileUploaderChange} />
              </span>
              <ReactQuill
                theme="snow"
                ref={QuillRef}
                modules={modules}
                formats={formats}
                placeholder="입력"
                value={boardObj?.content}
                onChange={(content, delta, source, editor) => {
                  setBoardObj((prev) => {
                    return {
                      ...prev,
                      content: editor?.getHTML(),
                    };
                  });
                }}
              />
            </div>
          </div>
        </>
      }
    />
  );
};
