import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import useToast from 'hooks/useToast';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { SelectD } from 'components/reactstrap/reactstrap';
import { ParcelInvoiceUnloadUserDTO, ParcelLinehaulSelectDTO } from '../../../_interface/parcel';
import { setLoading } from 'redux/services/menuSlice';
import { detectMobileDevice } from 'common/util/detectDevice';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const isMobile = detectMobileDevice(window.navigator.userAgent);

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const [inputs, setInputs] = useState<{ centerCode: string; parcelLinehaulSeq: string; parcelInvoiceNumber: string }>({
    centerCode: reduxUserInfo?.centerCode,
    parcelInvoiceNumber: null,
    parcelLinehaulSeq: null,
  });
  const [todayList, setTodayList] = useState<ParcelInvoiceUnloadUserDTO[]>();
  const successPlayer = useRef<HTMLAudioElement>();
  const failurePlayer = useRef<HTMLAudioElement>();
  const { Toast, displayToast, setColor, setMsg } = useToast({
    message: '',
    ms: 3000,
  });

  const scanInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    scanInputRef?.current?.focus();
    getLinehaulToday();
  }, []);

  useEffect(() => {
    if (inputs?.centerCode) {
      fetchLinehaulSelectByCenter(inputs?.centerCode);
      getLinehaulToday();
    }
  }, [inputs?.centerCode]);

  const [workList, setWorkList] = useState<OptionItem[]>();

  const fetchLinehaulSelectByCenter = async (destinationCenterCode: string) => {
    const { data } = await serviceStore.parcelAction(`linehaul/select`, 'GET', { destinationCenterCode }, null);
    if (data?.code !== 404) {
      console.log(data);
      const workList = (data as ParcelLinehaulSelectDTO[])?.map((ele) => {
        return {
          value: ele.parcelLinehaulSeq,
          label: `${ele.parcelLinehaulSeq}(${ele.driverName}/${ele.driverCarNumber})`,
        };
      });
      setWorkList(workList);
    }
  };

  const getLinehaulToday = async () => {
    if (inputs?.centerCode) {
      dispatch(setLoading('GET'));
      const { data } = await serviceStore.parcelAction(`invoice/unload/user`, 'GET', inputs, null);
      if (data) setTodayList(data as ParcelInvoiceUnloadUserDTO[]);
      dispatch(setLoading(null));
    }
  };

  const scanRight = (str = 'centerName') => {
    setColor('green');
    successPlayer.current.play();
    setMsg(str);
    displayToast();
  };

  const scanError = (str = '오류입니다') => {
    setColor('red');
    failurePlayer.current.play();
    setMsg(str);
    displayToast();
    setInputs((prev) => {
      return {
        ...prev,
        parcelInvoiceNumber: null,
      };
    });
    scanInputRef.current.value = '';
  };

  const validation = (inputs) => {
    if (!inputs?.centerCode) {
      alert('하차지는 필수값입니다!');
      return false;
    }
    if (!inputs?.parcelLinehaulSeq) {
      alert('작업지시서 선택은 필수입니다!');
      return false;
    }
    if (!inputs?.parcelInvoiceNumber) {
      alert('운송장번호는 필수값입니다!');
      return false;
    }
    return true;
  };

  const reset = () => {
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        parcelInvoiceNumber: null,
      };
    });
    scanInputRef?.current?.focus();
  };

  const accidentUnload = async (ele) => {
    const rs = await serviceStore.parcelAction(`invoice/unload/accident`, 'POST', null, { parcelInvoiceUnloadSeq: ele.parcelInvoiceUnloadSeq });
    if (rs?.status === 200) {
      scanRight(ele?.type === 'NORMAL' ? '사고처리되었습니다!' : '정상처리되었습니다');
      getLinehaulToday();
    }
  };

  const postSaveLoad = async () => {
    if (validation(inputs)) {
      const rs = await serviceStore.parcelAction(`invoice/unload`, 'POST', inputs);
      if (rs?.status === 200) {
        rs?.data?.result ? scanRight('하차처리 되었습니다') : scanError('오입고 발생');
        getLinehaulToday();
        fetchLinehaulSelectByCenter(inputs?.centerCode);
        reset();
      } else {
        scanError(rs?.data?.message);
      }
    }
  };

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    postSaveLoad();
  };

  return (
    <div className="page-content" style={{ paddingTop: isMobile ? 0 : '4rem' }}>
      <div style={{ maxWidth: 500 }}>
        <div style={{ position: 'relative', height: 30, marginBottom: 12 }}>
          <Toast />
        </div>
        <Form onSubmit={handleSubmit}>
          <Row style={{ marginTop: 50 }}>
            <Col>
              <label className="col-form-label">*하차지</label>
              <SelectD
                options={MASTER_OPS?.CENTER_AUTH}
                hasNull={false}
                value={
                  inputs?.centerCode
                    ? {
                        value: inputs?.centerCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[inputs?.centerCode],
                      }
                    : null
                }
                onChange={(option) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      centerCode: (option as OptionItem)?.value,
                      parcelLinehaulSeq: null,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*작업지시서</label>
              <SelectD
                hasNull={false}
                options={workList}
                value={
                  inputs?.parcelLinehaulSeq
                    ? {
                        value: inputs?.parcelLinehaulSeq,
                        label: selectlabel(inputs?.parcelLinehaulSeq, null),
                      }
                    : null
                }
                onChange={(option) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      parcelLinehaulSeq: (option as OptionItem)?.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*운송장번호</label>
              <input
                ref={scanInputRef}
                value={inputs?.parcelInvoiceNumber || ''}
                className={'form-control backgroundYellow'}
                placeholder="운송장번호를 입력하세요"
                onChange={(e) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      parcelInvoiceNumber: e.target.value.substring(0, 13),
                    };
                  });
                }}
              />
            </Col>
          </Row>
        </Form>
        <div className="grid-button-area space-between" style={{ marginTop: 30 }}>
          <div></div>
          <div>하차리스트 : {todayList?.length || 0}</div>
        </div>
        <table className="border-table noBorder fontSize10" style={{ fontSize: 10 }}>
          <thead>
            <tr>
              <th colSpan={2}>순번</th>
              <th colSpan={5}>운송장번호</th>
              <th colSpan={5}>상태</th>
            </tr>
          </thead>
          <tbody>
            {todayList?.map((ele, idx) => {
              return (
                <tr key={`today_${idx}`}>
                  <th colSpan={2}>{todayList?.length - idx}</th>
                  <th colSpan={5}>{ele.parcelInvoiceNumber}</th>
                  <th colSpan={5}>
                    <div
                      className="clickable-underline"
                      style={{ color: ele.type !== 'NORMAL' ? 'red' : 'black' }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (ele.type !== 'WRONG') accidentUnload(ele);
                      }}
                    >
                      {MASTER_OBJ?.PLINE_UNLOAD_TYPE?.[ele.type]}
                    </div>
                  </th>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="audioWrapper">
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_sound.mp3" ref={successPlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_fail_sound.mp3" ref={failurePlayer} controls></audio>
      </div>
    </div>
  );
};

export default Index;
