import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { Button, Label, Col, Row } from 'reactstrap';
import { httpClient } from 'common/http-client/axiosConfig';
import { OMS_ORDER_DETAIL_FILE, ORDER_PHOTO_SAVE } from 'envVar';

import CloseImg from 'assets/images/close/cross.png';
import { handleFile, uploadFile } from 'common/util/photo';
import { ImageModal } from './imageModal';
import CommonModalNew from 'components/modal/commonModalNew';
import styled from 'styled-components';

type IPhotoObj = {
  beforevisit: any[];
  complete: any[];
  sign: any[];
  laddercar: any[];
  lifting: any[];
  scraps: {
    collects: any[];
    uncollects: any[];
  };
  collects: any[];
  uncollects: any[];
  photoArray: any[];
  serial: any[];
};

const OrderPhoto = (props) => {
  const { invoiceDetail, visible, setVisible } = props;
  const [photoObj, setPhotoObj] = useState<IPhotoObj>();

  useEffect(() => {
    if (invoiceDetail?.invoiceSeq) {
      PhotofetchList();
    }
  }, []);

  const PhotofetchList = async () => {
    const beforevisit = [];
    const complete = [];
    const serial = [null, null, null, null, null, null, null, null, null, null];
    const sign = [null, null];
    const laddercar = [null, null, null];
    const lifting = [null, null, null];
    const collects = [];
    const uncollects = [];
    const scraps = { collects: [], uncollects: [] };
    let photoArray = [];

    invoiceDetail.scraps?.forEach((scrap, idx) => {
      if (scrap.collectFlag) {
        collects.push(null);
        collects.push(null);
        scraps.collects.push(`수거${idx + 1}-1`);
        scraps.collects.push(`수거${idx + 1}-2`);
      } else {
        uncollects.push(null);
        uncollects.push(null);
        scraps.uncollects.push(`내림${idx + 1}-1`);
        scraps.uncollects.push(`내림${idx + 1}-2`);
      }
    });

    await httpClient
      .get(OMS_ORDER_DETAIL_FILE + `/${invoiceDetail?.invoiceSeq}`)
      .then((rs) => {
        if (rs?.status === 200) {
          photoArray = rs.data;
          // 사진처리
          if (photoArray?.length > 0) {
            const regex = /[^0-9]/g;

            for (let index = 0; index < photoArray?.length; index++) {
              const element = photoArray[index];
              if (element?.tableColumn?.includes('BEFORE_VISIT')) {
                const colNum = parseInt(element?.tableColumn.replace(regex, ''));
                element.id = colNum;
                beforevisit.push(element);
              }
              if (element?.tableColumn?.includes('MODEL')) {
                const colNum = parseInt(element?.tableColumn.replace(regex, ''));
                element.id = colNum;
                complete.push(element);
              }
              if (element?.tableColumn?.includes('SERIAL')) {
                const colNum = parseInt(element?.tableColumn.replace(regex, ''));
                if (serial?.length >= colNum) serial[colNum - 1] = element;
              }
              if (element?.tableColumn?.includes('LADDER_TRUCK')) {
                if (element.tableColumn === 'LADDER_TRUCK_NUMBER') {
                  laddercar[0] = element;
                } else if (element.tableColumn === 'LADDER_TRUCK') {
                  laddercar[1] = element;
                } else {
                  laddercar[2] = element;
                }
              }
              if (element?.tableColumn?.includes('LIFTING')) {
                const colNum = parseInt(element?.tableColumn.replace(regex, ''));
                lifting[colNum - 1] = element;
              }
              if (element?.tableColumn?.includes('SIGN')) {
                if (element?.tableColumn === 'SIGN') {
                  sign[0] = element;
                } else {
                  sign[1] = element;
                }
              }
              if (element?.tableColumn?.includes('SCRAP')) {
                if (invoiceDetail.scraps[element?.tableColumn[5] - 1].collectFlag) {
                  // 수거
                  scraps.collects.forEach((scrap, idx) => {
                    if (scrap.includes(element?.tableColumn.substr(5))) {
                      collects[idx] = element;
                    }
                  });
                } else {
                  // uncollect
                  scraps.uncollects.forEach((scrap, idx) => {
                    if (scrap.includes(element?.tableColumn.substr(5))) {
                      uncollects[idx] = element;
                    }
                  });
                }
              }
            }
            console.log(beforevisit);
          }
        }

        const obj = {
          beforevisit,
          complete,
          serial,
          sign,
          laddercar,
          lifting,
          scraps,
          collects,
          uncollects,
          photoArray,
        };
        console.log(obj);
        // 사진
        setPhotoObj(obj);
      })
      .catch((error) => {
        const obj = {
          beforevisit,
          complete,
          serial,
          sign,
          laddercar,
          lifting,
          scraps,
          collects,
          uncollects,
          photoArray: [],
        };
        console.log(obj);
        // 사진
        setPhotoObj(obj);
      });
  };
  console.log(photoObj);
  const transColumnFile = (content, idx) => {
    if (content === 'beforevisit') {
      return `BEFORE_VISIT${idx + 1}`;
    } else if (content === 'complete') {
      return `MODEL${idx + 1}`;
    } else if (content === 'serial') {
      return `SERIAL${idx + 1}`;
    } else if (content === 'sign') {
      if (idx === 0) return 'SIGN';
      else return 'DRIVER_SIGN';
    } else if (content === 'laddercar') {
      if (idx === 0) return 'LADDER_TRUCK_NUMBER';
      else if (idx === 1) return 'LADDER_TRUCK';
      else if (idx === 2) return 'LADDER_TRUCK2';
    } else if (content === 'lifting') {
      return `LIFTING${idx + 1}`;
    }
    return null;
  };

  const handleFileUpload = async (e) => {
    const contentArr = e.target.id.split('_');
    const content = contentArr[0];
    const idx = Number(contentArr[1]);
    const ret = await handleFile(e);

    let uploadUrl, data;

    if (content.includes('수거') || content.includes('내림')) {
      // NOTE: 파라미터가 2개인데, 3개를 요청하고 있었음
      //uploadUrl = await uploadFile(ret.file, ret.url, content.includes('수거') ? 'collects' : 'uncollects');
      uploadUrl = await uploadFile(ret.file, ret.url);
      data = {
        invoiceSeq: invoiceDetail?.invoiceSeq,
        addFiles: [
          {
            column: 'SCRAP' + content.substr(2),
            uploadUrl,
          },
        ],
      };
    } else {
      // NOTE: 파라미터가 2개인데, 3개를 요청하고 있었음
      // uploadUrl = await uploadFile(ret.file, ret.url, `${content}_${idx}`);
      uploadUrl = await uploadFile(ret.file, ret.url);

      data = {
        invoiceSeq: invoiceDetail?.invoiceSeq,
        addFiles: [
          {
            column: transColumnFile(content, Number(idx)),
            uploadUrl,
          },
        ],
      };
      console.log(data);
    }

    if (uploadUrl) {
      httpClient.post(ORDER_PHOTO_SAVE, data).then((res) => {
        if (res?.status === 200) {
          alert('사진 업로드에 성공하였습니다!');
          PhotofetchList();
        } else {
          if (res?.data?.code) alert(res?.data?.code);
        }
      });
    }
  };

  const handleFileRemove = async (e) => {
    const contentArr = e.target.id.split('_');
    const content = contentArr[0];
    const idx = Number(contentArr[1]);
    let data;

    const prompt = window.confirm('사진을 정말 삭제하시겠습니까?');
    if (prompt) {
      if (content.includes('수거') || content.includes('내림')) {
        data = {
          invoiceSeq: invoiceDetail?.invoiceSeq,
          deleteFiles: [
            {
              column: 'SCRAP' + content.substr(2),
            },
          ],
        };
      } else {
        data = {
          invoiceSeq: invoiceDetail?.invoiceSeq,
          deleteFiles: [
            {
              column: photoObj[content][idx]['tableColumn'],
              uploadUrl: photoObj[content][idx]['uploadUrl'],
            },
          ],
        };
      }
      httpClient.post(ORDER_PHOTO_SAVE, data).then((res) => {
        if (res?.status === 200) {
          PhotofetchList();
        }
      });
    }
  };

  return (
    <>
      <CommonModalNew
        title="사진 모아보기"
        style={{ width: 800 }}
        setVisible={setVisible}
        visible={visible}
        children={
          <>
            <Row>
              <div className="moa-bogi">
                <div className="inputSmaillTitle" style={{ marginTop: 0 }}>
                  고객집 도착 (최대 30장)
                </div>
                <StyledPhotoListLayout $col={5}>
                  {photoObj?.beforevisit?.map((photo, i) => {
                    return (
                      <PhotozoneDiv $label={`방문전 ${i + 1}`} key={`beforevisit_${i}_${uuidv4()}`}>
                        <div style={{ display: 'inline-block' }}>
                          <img
                            src={CloseImg}
                            id={`beforevisit_${i}_`}
                            alt=""
                            onClick={(e) => {
                              handleFileRemove(e);
                            }}
                            className="closeBtn"
                          />
                          <ImageModal photoArray={photoObj?.beforevisit} idx={i} title={`방문전 사진`} />
                        </div>
                      </PhotozoneDiv>
                    );
                  })}
                  {photoObj?.beforevisit?.length < 30 && (
                    <PhotozoneDiv $label={`방문전 ${photoObj?.beforevisit?.length + 1}`}>
                      <input
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        id={`beforevisit_${photoObj?.beforevisit?.sort((a, b) => a.id - b.id)?.[photoObj?.beforevisit?.length - 1]?.id ?? 0}`}
                        className="beforevisit"
                        onChange={(e) => handleFileUpload(e)}
                      />
                      <Button size="md" color="primary" outline>
                        <Label for={`beforevisit_${photoObj?.beforevisit?.sort((a, b) => a.id - b.id)?.[photoObj?.beforevisit?.length - 1]?.id ?? 0}`} style={{ marginBottom: 0 }}>
                          ㅤㅤㅤ파일 선택ㅤㅤㅤ
                        </Label>
                      </Button>
                    </PhotozoneDiv>
                  )}
                </StyledPhotoListLayout>

                <div className="inputSmaillTitle">완료 (최대 30장)</div>
                <StyledPhotoListLayout $col={5}>
                  {photoObj?.complete?.map((photo, i) => {
                    return (
                      <PhotozoneDiv $label={`사진 ${i + 1}`} key={`model_${i}_${uuidv4()}`}>
                        <div style={{ display: 'inline-block' }}>
                          <img
                            src={CloseImg}
                            id={`complete_${i}_`}
                            alt=""
                            onClick={(e) => {
                              handleFileRemove(e);
                            }}
                            className="closeBtn"
                          />
                          <ImageModal
                            //
                            photoArray={photoObj?.complete}
                            idx={i}
                            title={`완료 사진`}
                          />
                        </div>
                      </PhotozoneDiv>
                    );
                  })}
                  {photoObj?.complete?.length < 30 && (
                    <PhotozoneDiv $label={`사진 ${photoObj?.complete?.length + 1}`}>
                      <input
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        id={`complete_${photoObj?.complete?.sort((a, b) => a.id - b.id)?.[photoObj?.complete?.length - 1]?.id ?? 0}`}
                        className="complete"
                        onChange={(e) => handleFileUpload(e)}
                      />
                      <Button size="md" color="primary" outline>
                        <Label for={`complete_${photoObj?.complete?.sort((a, b) => a.id - b.id)?.[photoObj?.complete?.length - 1]?.id ?? 0}`} style={{ marginBottom: 0 }}>
                          ㅤㅤㅤ파일 선택ㅤㅤㅤ
                        </Label>
                      </Button>
                    </PhotozoneDiv>
                  )}
                </StyledPhotoListLayout>

                <div className="inputSmaillTitle">시리얼번호/송장</div>
                <StyledPhotoListLayout $col={5}>
                  {photoObj?.serial?.map((photo, i) => {
                    return (
                      <Col key={`serial_${i}_${uuidv4()}`}>
                        <div className="photo-zone">
                          <span className="bck-font">사진 {i + 1}</span>
                          {photo ? (
                            <div style={{ display: 'inline-block' }}>
                              <img
                                src={CloseImg}
                                id={`serial_${i}_`}
                                alt=""
                                onClick={(e) => {
                                  handleFileRemove(e);
                                }}
                                className="closeBtn"
                              />
                              <ImageModal
                                //
                                photoArray={photoObj?.serial}
                                idx={i}
                                title={`시리얼번호 사진`}
                              />
                            </div>
                          ) : (
                            <>
                              <input style={{ display: 'none' }} type="file" accept="image/*" id={`serial_${i}`} className="serial" onChange={(e) => handleFileUpload(e)} />

                              <Button size="md" color="primary" outline>
                                <Label for={`serial_${i}`} style={{ marginBottom: 0 }}>
                                  ㅤㅤㅤ파일 선택ㅤㅤㅤ
                                </Label>
                              </Button>
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </StyledPhotoListLayout>

                <div className="inputSmaillTitle">서명</div>
                <StyledPhotoListLayout $col={2}>
                  {photoObj?.sign?.map((photo, i) => {
                    return (
                      <Col key={`signphoto_${uuidv4()}`}>
                        <div className="photo-zone">
                          <span className="bck-font">{i === 0 ? '고객서명' : '기사서명'}</span>
                          {photo ? (
                            <div style={{ display: 'inline-block' }}>
                              <img
                                src={CloseImg}
                                id={`sign_${i}_`}
                                alt=""
                                onClick={(e) => {
                                  handleFileRemove(e);
                                }}
                                className="closeBtn"
                              />
                              <ImageModal
                                //
                                photoArray={photoObj?.sign}
                                idx={i}
                                title={(i) => {
                                  if (i === 0) {
                                    return '고객서명';
                                  } else return '기사서명';
                                }}
                              />
                            </div>
                          ) : (
                            <>
                              <input style={{ display: 'none' }} type="file" accept="image/*" id={`sign_${i}`} className="sign" onChange={(e) => handleFileUpload(e)} />

                              <Button size="md" color="primary" outline>
                                <Label for={`sign_${i}`} style={{ marginBottom: 0 }}>
                                  ㅤㅤㅤ파일 선택ㅤㅤㅤ
                                </Label>
                              </Button>
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </StyledPhotoListLayout>

                <div className="inputSmaillTitle">사다리차</div>
                <StyledPhotoListLayout $col={3}>
                  {photoObj?.laddercar?.map((photo, i) => {
                    return (
                      <Col key={`laddercar${i}_${uuidv4()}`}>
                        <div className="photo-zone">
                          <span className="bck-font">{i === 0 ? '차량번호판' : `사다리차${i}`}</span>
                          {photo ? (
                            <div style={{ display: 'inline-block' }}>
                              <img
                                src={CloseImg}
                                id={`laddercar_${i}_`}
                                alt=""
                                onClick={(e) => {
                                  handleFileRemove(e);
                                }}
                                className="closeBtn"
                              />
                              <ImageModal
                                //

                                photoArray={photoObj?.laddercar}
                                idx={i}
                                title={(i) => {
                                  if (i === 0) return '차량번호판';
                                  else return `사다리차${i}`;
                                }}
                              />
                            </div>
                          ) : (
                            <>
                              <input style={{ display: 'none' }} type="file" accept="image/*" id={`laddercar_${i}`} className="laddercar" onChange={(e) => handleFileUpload(e)} />

                              <Button size="md" color="primary" outline>
                                <Label for={`laddercar_${i}`} style={{ marginBottom: 0 }}>
                                  ㅤㅤㅤ파일 선택ㅤㅤㅤ
                                </Label>
                              </Button>
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </StyledPhotoListLayout>
                <div className="inputSmaillTitle">양중</div>
                <StyledPhotoListLayout $col={3}>
                  {photoObj?.lifting?.map((photo, i) => {
                    return (
                      <Col key={`lifting_${i}_${uuidv4()}`}>
                        <div className="photo-zone">
                          <span className="bck-font">{`양중사진${i + 1}`}</span>
                          {photo ? (
                            <div style={{ display: 'inline-block' }}>
                              <img
                                src={CloseImg}
                                id={`lifting_${i}_`}
                                alt=""
                                onClick={(e) => {
                                  handleFileRemove(e);
                                }}
                                className="closeBtn"
                              />
                              <ImageModal
                                //
                                photoArray={photoObj?.lifting}
                                idx={i}
                                title={'양중사진'}
                              />
                            </div>
                          ) : (
                            <>
                              <input style={{ display: 'none' }} type="file" accept="image/*" id={`lifting_${i}`} className="lifting" onChange={(e) => handleFileUpload(e)} />

                              <Button size="md" color="primary" outline>
                                <Label for={`lifting_${i}`} style={{ marginBottom: 0 }}>
                                  ㅤㅤㅤ파일 선택ㅤㅤㅤ
                                </Label>
                              </Button>
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </StyledPhotoListLayout>
                {photoObj?.collects?.length > 0 && (
                  <>
                    <div className="inputSmaillTitle">폐가전 수거</div>
                    <Row>
                      {photoObj?.collects?.map((collect, i) => {
                        return (
                          <Col key={`c1_${i}`}>
                            <div className="photo-zone">
                              <span className="bck-font">{photoObj?.scraps.collects[i]}</span>
                              {collect ? (
                                <div style={{ display: 'inline-block' }}>
                                  <img
                                    src={CloseImg}
                                    id={`collects${i}_`}
                                    alt=""
                                    onClick={(e) => {
                                      handleFileRemove(e);
                                    }}
                                    className="closeBtn"
                                  />
                                  <ImageModal
                                    //
                                    photoArray={photoObj?.collects}
                                    idx={i}
                                    title={'폐가전수거'}
                                  />
                                </div>
                              ) : (
                                <>
                                  <input style={{ display: 'none' }} type="file" accept="image/*" id={photoObj?.scraps.collects[i]} className="collects" onChange={(e) => handleFileUpload(e)} />

                                  <Button size="md" color="primary" outline>
                                    <Label for={photoObj?.scraps.collects[i]} style={{ marginBottom: 0 }}>
                                      ㅤㅤㅤ파일 선택ㅤㅤㅤ
                                    </Label>
                                  </Button>
                                </>
                              )}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                )}
                {photoObj?.uncollects?.length > 0 && (
                  <>
                    <div className="inputSmaillTitle">폐가전 내림</div>
                    <Row>
                      {photoObj?.uncollects?.map((collect, i) => {
                        return (
                          <Col key={`c_${i}`}>
                            <div className="photo-zone">
                              <span className="bck-font">{photoObj?.scraps.uncollects[i]}</span>
                              {collect ? (
                                <div style={{ display: 'inline-block' }}>
                                  <img
                                    src={CloseImg}
                                    id={`uncollects${i}_`}
                                    alt=""
                                    onClick={(e) => {
                                      handleFileRemove(e);
                                    }}
                                    className="closeBtn"
                                  />
                                  <ImageModal
                                    //
                                    photoArray={photoObj?.uncollects}
                                    idx={i}
                                    title={'폐가전내림'}
                                  />
                                </div>
                              ) : (
                                <>
                                  <input style={{ display: 'none' }} type="file" accept="image/*" id={photoObj?.scraps.uncollects[i]} className="uncollects" onChange={(e) => handleFileUpload(e)} />

                                  <Button size="md" color="primary" outline>
                                    <Label for={photoObj?.scraps.uncollects[i]} style={{ marginBottom: 0 }}>
                                      ㅤㅤㅤ파일 선택ㅤㅤㅤ
                                    </Label>
                                  </Button>
                                </>
                              )}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                )}
              </div>
            </Row>
          </>
        }
      />
    </>
  );
};

export default OrderPhoto;

const StyledPhotoListLayout = styled.div<{ $col: number }>`
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 16px;

  & > * {
    flex-grow: 0;
    flex-basis: calc((100% - ${(props) => (props.$col - 1) * 16}px) / ${(props) => props.$col});
  }
`;

type PhotozoneDivType = {
  children?: React.ReactNode;
  $label: string;
};

const PhotozoneDiv = ({ children, $label }: PhotozoneDivType) => {
  return (
    <div className="photo-zone">
      <span className="bck-font">{$label}</span>
      {children}
    </div>
  );
};
