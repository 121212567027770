import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './payment';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';
import { ORDER_MASTER } from '../orderListTab';

export const EXTRAPAYMENT_MASTER = [
  //
  ...ORDER_MASTER,
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_AUTH',
  'SELLER_WHOLE',
  //
  'EXTRA_TYPE',
  'TOSS_PAYMENT_STATUS',
  'PAYMENT_CANCEL',
  //
  {
    SEARCH_DATE: [
      { value: 'RegisterDate', label: '요청일' },
      { value: 'FinishDate', label: '완료일' },
      { value: 'CancelDate', label: '취소일' },
    ],
  },
  {
    CHARGE_FLAG: [
      { value: 'true', label: '유상' },
      { value: 'false', label: '무상' },
    ],
  },
  {
    METHOD: [
      { value: '간편결제', label: '간편결제' },
      { value: '계좌이체', label: '계좌이체' },
      { value: '카드', label: '카드' },
    ],
  },
  {
    PMODEL_TYPE: [
      { value: 'MODEL', label: '구성품' },
      { value: 'GIFT', label: '사은품' },
    ],
  },
  {
    SEARCH_PRODUCT: [
      { value: 'productName', label: '상품명' },
      { value: 'productSeq', label: '상품번호' },
      { value: 'externalProductNumber', label: '외부상품번호' },
    ],
  },
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(EXTRAPAYMENT_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            //
            EXTRAPAYMENT_TAB: <Tab1 tabId={'EXTRAPAYMENT_TAB'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
