import { useEffect, useState } from 'react';
import { INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION, TAKE_OUT_PICKING_LIST_BODY } from 'envVar';
import { v4 as uuidv4 } from 'uuid';

import Grid from 'components/grid/auiGrid';
import { httpClient } from 'common/http-client/axiosConfig';
import CommonModalNew from 'components/modal/commonModalNew';

export const defaultSearchModelObj = {
  visible: false,
  params: null,
  returns: null,
};

const SearchModelModalForStoreTakeOutPickingInstructionRegister = (props) => {
  const { searchModelObj, setSearchModelObj } = props;

  const [gridId, setGridId] = useState();
  const [rows, setRows] = useState([]);

  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  useEffect(() => {
    if (searchModelObj?.visible) {
      setGridId(`YangpanModal_${uuidv4()}`);
      fetchList(searchModelObj?.params);
    }
  }, [searchModelObj?.visible]);

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'getChecked') {
        if (validForModel(items)) {
          findModel(items);
        } else {
          alert('같은 출하요청 번호 & 반출대상에 한해서만 다중선택 가능');
        }
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const returnData = (items = []) => {
    const _items = [];
    let yangpanItems = getCheckedDataTriggerThisGrid?.items;
    yangpanItems.sort((a, b) => Number(a.modelStockSeq) - Number(b.modelStockSeq));
    items.sort((a, b) => Number(a.modelStockSeq) - Number(b.modelStockSeq));

    let flag = true;
    items?.forEach((item, i) => {
      let _item = {};
      if (item) {
        _item = { ...item };
        _item['drNo'] = yangpanItems[i]['drNo'];
        _item['soNo'] = yangpanItems[i]['soNo'];
        _item['target'] = yangpanItems[i]['target'];
        _item['requestQuantity'] = yangpanItems[i]['requestQuantity'];
        _items.push(_item);
      } else {
        flag = false;
      }
    });

    if (!flag) {
      if (items.length === 1) alert('발견된 재고가 없습니다');
      else alert('재고에 없는 양판점 물품이 있습니다! 없는 물품은 제외하여 추가됩니다');
    }

    setSearchModelObj({
      ...searchModelObj,
      returns: _items,
      visible: false,
    });
  };

  const columns = [
    {
      headerText: '출하요청번호',
      dataField: 'drNo',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '반출대상',
      dataField: 'target',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품',
      dataField: 'model',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '요청수량',
      dataField: 'requestQuantity',
      filter: {
        showIcon: true,
      },
    },
  ];

  const validForModel = (items) => {
    let drNo;
    let target;
    let validFlag = true;
    items.forEach((item) => {
      if (!drNo) {
        drNo = item.drNo;
      } else {
        if (drNo !== item.drNo) validFlag = false;
      }

      if (!target) {
        target = item.target;
      } else {
        if (target !== item.target) validFlag = false;
      }
    });

    return validFlag;
  };

  const [returnsWait, setReturnsWait] = useState([]);

  useEffect(() => {
    if (returnsWait && returnsWait?.length === getCheckedDataTriggerThisGrid?.items?.length) {
      returnData(returnsWait);
    }
  }, [returnsWait]);

  const findModel = async (items) => {
    const _returns = await Promise.all(
      items.map((item) => {
        return httpClient
          .get(
            `${INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION}?
			centerCode=${searchModelObj?.params.centerCode}&modelStockSeq=${item.modelStockSeq}`,
          )
          .then((rs) => {
            if (rs?.status === 200) {
              const data = rs.data[0];
              return data;
            }
          });
      }),
    );

    setReturnsWait(_returns);
  };

  const fetchList = async (params) => {
    let url = `${TAKE_OUT_PICKING_LIST_BODY}?centerCode=${params.centerCode}&stockRemoveDate=${params.stockRemoveDate}`;
    httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        let result = rs.data || [];
        result.sort((a, b) => Number(a.modelStockSeq) - Number(b.modelStockSeq));
        if (result.length === 0) {
          alert('조회되는 재고가 없습니다!');
          setSearchModelObj(defaultSearchModelObj);
        }
        setRows(result);
      }
    });
  };

  return (
    <CommonModalNew
      title={'양판점출고조회'}
      style={{ width: 1000 }}
      visible={searchModelObj?.visible && rows.length > 0}
      setVisible={() => {
        setSearchModelObj({
          ...searchModelObj,
          visible: !searchModelObj?.visible,
        });
      }}
      children={
        <>
          {gridId && (
            <>
              <div className="grid-button-area only-right">
                <div
                  className="dark"
                  onClick={() => {
                    setGetCheckedDataTriggerThisGrid({
                      type: 'getChecked',
                      id: `#${gridId}`,
                    });
                  }}
                >
                  + 추가
                </div>
              </div>
              <Grid
                id={gridId}
                rows={rows}
                columns={columns}
                style={{ height: 600 }}
                settingOptions={{
                  usePaging: true,
                  showRowAllCheckBox: true,
                  showRowCheckColumn: true,
                  editable: false,
                }}
                getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
                setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
              />
            </>
          )}
        </>
      }
    />
  );
};

export default SearchModelModalForStoreTakeOutPickingInstructionRegister;
