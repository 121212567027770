import { useEffect, useMemo, useRef, useState } from 'react';

// component
import GridBox, { IPagingGrid, PARCEL_GRID_PAGE_ROW_COUNT } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { addMASTER, setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { FunctionDTO, FunctionSearchDTO } from 'interface/user';
import { ISortObj, SortModal } from './component/sortModal';
import { DetailModal, IDetailObj } from './component/detailPopup';
import { PagingListDTO } from 'interface/util';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import { serviceStore } from 'services/services';

export const BUTTON_COLOR = {
  ORANGE: '#FF9110',
  RED: '#FD5C51',
  GREEN: '#026F3B',
  GRAY: '#A3A7B9',
  BLUE: '#2096F3',
};

const Index = () => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();

  const [detailObj, setDetailObj] = useState<IDetailObj>();
  const [sortObj, setSortObj] = useState<ISortObj>();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  useEffect(() => {
    addMasterOptions();
  }, []);

  const addMasterOptions = async () => {
    const addOps = {};
    if (!MASTER_OBJ?.SCREENID_URL) {
      addOps['SCREENID_URL'] = MASTER_OPS?.SCREEN_WHOLE?.map((ele) => {
        return {
          value: ele.screenId,
          label: ele.url,
        };
      });
    } else if (!MASTER_OBJ?.TAB_NAME) {
      addOps['TAB_NAME'] = MASTER_OPS?.TAB_WHOLE?.map((ele) => {
        return {
          value: ele.tabId,
          label: ele.tabName,
        };
      });
    }
    if (Object?.keys(addOps)?.length > 0) dispatch(addMASTER(addOps));
  };

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  const newPathGenerator = {
    '/moveActionRegit': '/main/moveActionList/moveActionRegit', //재고이동지시
    '/modelMoveRegit': '/main/modelMoveList/moveActionRegit', //재고전환생성
    '/modificationAvaRegit': '/main/modifications/modificationAvaRegit', //양품재고생성
    '/modificationDefRegit': '/main/modifications/modificationDefRegit', //불용재고생성
    '/TakeOutPickingInstructionRegister': '/main/takeoutWorks/TakeOutPickingInstructionRegister', //반출피킹생성
    '/storeTakeOutPickingInstructionRegister': '/main/takeoutWorks/storeTakeOutPickingInstructionRegister', //양판점출고
    '/takeoutRegit': '/main/takeoutWorks/takeoutRegit', //반출피킹생성 new
    '/trunkRegister': '/main/trunkWorks/trunkRegister', //간선피킹생성
    '/trunkRegit': '/main/trunkWorks/trunkRegit', //간선피킹생성 new
    '/refurRegister': '/main/refur/refurRegister', //리퍼생성
    '/refurRegit': '/main/refur/refurRegit', //리퍼생성 new
    '/purchaseRegit': '/main/manageorderInfoList/purchaseRegit', //발주요청
    '/manageorderRegister': '/main/manageorderInfoList/manageorderRegister', //주문불러오기
    '/placeOrderRegister': '/main/placeOrderRegister', //발주등록
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: (e) => {
          setDetailObj({
            searchObj: JSON.parse(e.item?.searchObj),
            visible: true,
            item: e.item,
            CRUDFn: CRUDFunction,
          });
        },
      },
    },
    {
      headerText: '화면URL',
      dataField: 'url',
    },
    {
      headerText: '화면ID',
      dataField: 'screenId',
    },
    {
      headerText: '탭ID',
      dataField: 'tabId',
      width: 200,
    },
    {
      headerText: '기능',
      dataField: 'functions',
      children: [
        {
          headerText: '기능ID',
          dataField: 'functionId',
          style: 'main-total-color',
          width: 200,
        },
        {
          headerText: '기능명',
          dataField: 'functionNameKr',
          style: 'sub-total-color',
          width: 100,
        },
        {
          headerText: '등록화면',
          dataField: 'openUrl',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: (e) => {
              window.open(currentDesignType === 1 ? newPathGenerator[e.text] ?? e.text : e.text);
            },
            visibleFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
              if (value) return true;
              return null;
            },
          },
        },
        {
          headerText: '설명',
          dataField: 'description',
          width: 200,
        },
        {
          headerText: '위치',
          dataField: 'functionTypeKr',
        },
        {
          headerText: '순서',
          dataField: 'sort',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: (e) => {
              const rows = gridRef.current.getGridData();
              const functions = rows.filter((ele) => ele.tabId === e.item.tabId && e.item?.functionType === ele.functionType);
              functions.sort((a, b) => a.sort - b.sort);
              setSortObj({
                e,
                visible: true,
                tabName: e.item.tabName,
                items: functions,
                fetchFn: (data) => wrappedFetchList(data),
              });
            },
          },
          width: 50,
        },
        {
          headerText: '제목',
          dataField: 'functionTitle',
        },
        {
          headerText: '버튼색상',
          dataField: 'color',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
            if (value)
              return `<div style="text-align: left;margin-left: 10px"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" fill="${BUTTON_COLOR[value]}" /></svg><span style="margin-left: 5px">${value}</span></div>`;
          },
        },
        {
          headerText: '사용 여부',
          dataField: 'useFlagKr',
          width: 70,
        },
      ],
    },
  ];

  const labellingKr = (data: PagingListDTO<FunctionDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        useFlagKr: row.useFlag === true ? 'O' : 'X',
        tabName: MASTER_OBJ?.TAB_NAME?.[row.tabId],
        url: MASTER_OBJ?.SCREENID_URL?.[row.screenId],
        functionTypeKr: MASTER_OBJ?.FUNCTION_TYPE?.[row.functionType],
        functionNameKr: row?.functionGroup ? `[${row?.functionGroup}]${row?.functionName}` : row?.functionName,
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: FunctionSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.userAction(`function`, 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const CRUDFunction = async ({ data, crud, searchObj }) => {
    dispatch(setLoading('POST'));
    let rs;
    if (crud === 'D') {
      rs = await serviceStore.userAction('function', 'DELETE', null, { data });
    } else if (crud === 'C') {
      rs = await serviceStore.userAction('function', 'POST', null, data);
      //
    } else if (crud === 'U') {
      rs = await serviceStore.userAction('function', 'PUT', null, data);
    }

    if (rs?.status === 200) {
      alert('처리 되었습니다!');
      setDetailObj(null);
      wrappedFetchList(searchObj);
    }

    dispatch(setLoading(null));
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: PARCEL_GRID_PAGE_ROW_COUNT,
      ...defaultSearchFilter,
    },
    gridRef,
    fetchPaging,
  });

  const handleCheckItems = (e) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      const id = e.target.id;
      if (id === 'delete-function') CRUDFunction({ data: items?.map((ele) => ele.functionId), crud: 'D', searchObj: JSON.parse(items[0]?.searchObj) });
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const postArray = async (data, id) => {
    dispatch(setLoading('POST'));
    if (id === 'upload') {
      data?.forEach(async (ele) => {
        setTimeout(async () => {
          await serviceStore.userAction('function', 'POST', null, ele);
        }, 1000);
      });
    } else if (id === 'modi') {
      data?.forEach(async (ele) => {
        setTimeout(async () => {
          await serviceStore.userAction('function', 'PUT', null, ele);
        }, 200);
      });
    }
    dispatch(setLoading(null));
  };

  const EXCELHEADER = {
    upload: ['url', 'screenId', 'tabId', 'functionId', 'functionName', 'openUrl', 'description', 'functionGroup', 'sort', 'color', 'useFlag'],
  };
  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER });

  useEffect(() => {
    console.log(parsedData);
    if (parsedData) {
      if (parsedData?.data?.length > 0) {
        postArray(parsedData?.data, parsedData?.id);
      } else {
        alert('읽혀진 값이 없습니다');
      }
    }
  }, [parsedData]);
  const excelDownload = () => {
    gridRef.current.exportAsXlsx({ fileName: '기능' });
  };

  return (
    <div className="page-content">
      {detailObj?.visible && <DetailModal detailObj={detailObj} setDetailObj={setDetailObj} />}
      {sortObj?.visible && <SortModal sortObj={sortObj} setSortObj={setSortObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area space-between">
          <div>
            <div
              className="blue"
              onClick={() => {
                setDetailObj({
                  searchObj,
                  visible: true,
                  item: null,
                  CRUDFn: (data) => CRUDFunction(data),
                });
              }}
            >
              생성
            </div>
            <div className="red" id="delete-function" onClick={handleCheckItems}>
              삭제
            </div>
          </div>
          <div>
            <div
              className="green"
              onClick={() => {
                excelDownload();
              }}
            >
              엑셀다운
            </div>
            <div
              className="green"
              id="form"
              onClick={() => {
                window.open('https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EA%B6%8C%ED%95%9C/%EA%B6%8C%ED%95%9C_%EA%B8%B0%EB%8A%A5%EC%97%85%EB%A1%9C%EB%93%9C.xlsx');
              }}
            >
              양식
            </div>
            <ExcelDetector />
            <div className="green" id="upload" onClick={() => uploaderClick('upload')}>
              +등록
            </div>
            <div className="green" id="modi" onClick={() => uploaderClick('modi')}>
              수정
            </div>
          </div>
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            pageRowCount: PARCEL_GRID_PAGE_ROW_COUNT,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
      </div>
    </div>
  );
};

export default Index;
