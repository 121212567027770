import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { resetAllSelections } from 'common/util/searchBox';
import { TransactionStatementSearchDTO } from 'interface/order';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { categoryChanger } from '../..';

export const SearchBox = (props: ISearchBoxProps<TransactionStatementSearchDTO>) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const location = useLocation();
  const { searchObj, setSearchObj, fetchCount, fetchList } = props;
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE: null,
    SEARCH_PROVIDER: null,
    SEARCH_RECEIVER: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_PROVIDER, searchObj));
  }, [selectTypeObj?.SEARCH_PROVIDER]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_RECEIVER, searchObj));
  }, [selectTypeObj?.SEARCH_RECEIVER]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        {categoryChanger(location.pathname) === 'HEADQUATERS' ? (
          <Row>
            <Col>
              <label className="col-form-label">판매사</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                value={searchObj?.partnerSeqs?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    partnerSeqs: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">상태값</label>
              <Row>
                <SelectD
                  value={
                    searchObj?.status
                      ? {
                          value: searchObj?.status,
                          label: MASTER_OBJ?.TS_STATUS?.[searchObj?.status],
                        }
                      : null
                  }
                  options={MASTER_OPS?.TS_STATUS}
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      status: value,
                    });
                  }}
                />
              </Row>
            </Col>
            <Col>
              <label className="col-form-label">공급자정보</label>
              <div style={{ display: 'flex' }}>
                <Col md={4} style={{ display: 'inline-block' }}>
                  <SelectD
                    value={
                      selectTypeObj?.SEARCH_PROVIDER
                        ? {
                            value: selectTypeObj?.SEARCH_PROVIDER,
                            label: MASTER_OBJ?.SEARCH_PROVIDER?.[selectTypeObj?.SEARCH_PROVIDER],
                          }
                        : null
                    }
                    options={MASTER_OPS?.SEARCH_PROVIDER}
                    onChange={(option) => {
                      setSelectTypeObj({
                        ...selectTypeObj,
                        SEARCH_PROVIDER: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col md={8} style={{ display: 'inline-block' }}>
                  <InputD
                    disabled={!selectTypeObj?.SEARCH_PROVIDER}
                    value={searchObj?.[selectTypeObj?.SEARCH_PROVIDER] || ''}
                    onChange={(e) => {
                      setSearchObj({
                        ...searchObj,
                        [selectTypeObj?.SEARCH_PROVIDER]: e.target.value,
                      });
                    }}
                  />
                </Col>
              </div>
            </Col>
            <Col>
              <label className="col-form-label">공급받는자정보</label>
              <div style={{ display: 'flex' }}>
                <Col md={4} style={{ display: 'inline-block' }}>
                  <SelectD
                    value={
                      selectTypeObj?.SEARCH_RECEIVER
                        ? {
                            value: selectTypeObj?.SEARCH_RECEIVER,
                            label: MASTER_OBJ?.SEARCH_RECEIVER?.[selectTypeObj?.SEARCH_RECEIVER],
                          }
                        : null
                    }
                    options={MASTER_OPS?.SEARCH_RECEIVER}
                    onChange={(option) => {
                      setSelectTypeObj({
                        ...selectTypeObj,
                        SEARCH_RECEIVER: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col md={8} style={{ display: 'inline-block' }}>
                  <InputD
                    disabled={!selectTypeObj?.SEARCH_RECEIVER}
                    value={searchObj?.[selectTypeObj?.SEARCH_RECEIVER] || ''}
                    onChange={(e) => {
                      setSearchObj({
                        ...searchObj,
                        [selectTypeObj?.SEARCH_RECEIVER]: e.target.value,
                      });
                    }}
                  />
                </Col>
              </div>
            </Col>
            <Col>
              <label className="col-form-label">기간검색</label>
              <div style={{ display: 'flex' }}>
                <Col md={4} style={{ display: 'inline-block' }}>
                  <SelectD
                    value={
                      selectTypeObj?.SEARCH_DATE
                        ? {
                            value: selectTypeObj?.SEARCH_DATE,
                            label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                          }
                        : null
                    }
                    options={MASTER_OPS?.SEARCH_DATE}
                    onChange={(option) => {
                      setSelectTypeObj({
                        ...selectTypeObj,
                        SEARCH_DATE: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col md={8}>
                  <FlatpickrD
                    onReset={() => {
                      setSearchObj({
                        ...searchObj,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                        [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                      });
                    }}
                    onClose={(option) => {
                      if (option?.length === 1)
                        setSearchObj({
                          ...searchObj,
                          [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                          [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                        });
                    }}
                    disabled={!selectTypeObj?.SEARCH_DATE}
                    value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                    onChange={(option) => {
                      setSearchObj({
                        ...searchObj,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                        [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                      });
                    }}
                  />
                </Col>
              </div>
            </Col>
            <Col>
              <button
                className="btn btn-search"
                onClick={(e) => {
                  e.preventDefault();
                  fetchList(searchObj);
                }}
              >
                조회
              </button>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col>
                <label className="col-form-label">판매사</label>
                <SelectD
                  isMulti
                  options={MASTER_OPS?.SELLER_SELLER_AUTH}
                  value={searchObj?.partnerSeqs?.map((ele) => {
                    return {
                      value: ele,
                      label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[ele],
                    };
                  })}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      partnerSeqs: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                    });
                  }}
                />
              </Col>
              {categoryChanger(location.pathname) === 'SUBCONTRACT' && (
                <Col>
                  <label className="col-form-label">인프라</label>
                  <SelectD
                    isMulti
                    options={MASTER_OPS?.INFRA}
                    value={searchObj?.infraSeqs?.map((ele) => {
                      return {
                        value: ele,
                        label: MASTER_OBJ?.INFRA?.[ele],
                      };
                    })}
                    onChange={(option) => {
                      setSearchObj({
                        ...searchObj,
                        infraSeqs: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                      });
                    }}
                  />
                </Col>
              )}

              {categoryChanger(location.pathname) === 'CENTER' && (
                <Col>
                  <label className="col-form-label">창고</label>
                  <SelectD
                    isMulti
                    options={MASTER_OPS?.CENTER_AUTH}
                    value={searchObj?.centerCodes?.map((ele) => {
                      return {
                        value: ele,
                        label: MASTER_OBJ?.CENTER_AUTH?.[ele],
                      };
                    })}
                    onChange={(option) => {
                      setSearchObj({
                        ...searchObj,
                        centerCodes: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                      });
                    }}
                  />
                </Col>
              )}

              {categoryChanger(location.pathname) === 'DRIVER' && (
                <Col>
                  <label className="col-form-label">기사</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={6} style={{ display: 'inline-block' }}>
                      <SelectD
                        options={MASTER_OPS?.DRIVER_AUTH}
                        value={
                          searchObj?.userId
                            ? {
                                value: searchObj?.userId,
                                label: MASTER_OBJ?.DRIVER_AUTH?.[searchObj?.userId],
                              }
                            : null
                        }
                        onChange={(option) => {
                          setSearchObj({
                            ...searchObj,
                            userId: (option as OptionItem).value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={6} style={{ display: 'inline-block' }}>
                      <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
                    </Col>
                  </div>
                </Col>
              )}
              <Col>
                <label className="col-form-label">상태값</label>
                <Row>
                  <SelectD
                    value={
                      searchObj?.status
                        ? {
                            value: searchObj?.status,
                            label: MASTER_OBJ?.TS_STATUS?.[searchObj?.status],
                          }
                        : null
                    }
                    options={MASTER_OPS?.TS_STATUS}
                    onChange={({ value }) => {
                      setSearchObj({
                        ...searchObj,
                        status: value,
                      });
                    }}
                  />
                </Row>
              </Col>
              <Col>
                <label className="col-form-label">공급자정보</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      value={
                        selectTypeObj?.SEARCH_PROVIDER
                          ? {
                              value: selectTypeObj?.SEARCH_PROVIDER,
                              label: MASTER_OBJ?.SEARCH_PROVIDER?.[selectTypeObj?.SEARCH_PROVIDER],
                            }
                          : null
                      }
                      options={MASTER_OPS?.SEARCH_PROVIDER}
                      onChange={(option) => {
                        setSelectTypeObj({
                          ...selectTypeObj,
                          SEARCH_PROVIDER: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!selectTypeObj?.SEARCH_PROVIDER}
                      value={searchObj?.[selectTypeObj?.SEARCH_PROVIDER] || ''}
                      onChange={(e) => {
                        setSearchObj({
                          ...searchObj,
                          [selectTypeObj?.SEARCH_PROVIDER]: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">공급받는자정보</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      value={
                        selectTypeObj?.SEARCH_RECEIVER
                          ? {
                              value: selectTypeObj?.SEARCH_RECEIVER,
                              label: MASTER_OBJ?.SEARCH_RECEIVER?.[selectTypeObj?.SEARCH_RECEIVER],
                            }
                          : null
                      }
                      options={MASTER_OPS?.SEARCH_RECEIVER}
                      onChange={(option) => {
                        setSelectTypeObj({
                          ...selectTypeObj,
                          SEARCH_RECEIVER: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!selectTypeObj?.SEARCH_RECEIVER}
                      value={searchObj?.[selectTypeObj?.SEARCH_RECEIVER] || ''}
                      onChange={(e) => {
                        setSearchObj({
                          ...searchObj,
                          [selectTypeObj?.SEARCH_RECEIVER]: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">기간검색</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      value={
                        selectTypeObj?.SEARCH_DATE
                          ? {
                              value: selectTypeObj?.SEARCH_DATE,
                              label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                            }
                          : null
                      }
                      options={MASTER_OPS?.SEARCH_DATE}
                      onChange={(option) => {
                        setSelectTypeObj({
                          ...selectTypeObj,
                          SEARCH_DATE: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8}>
                    <FlatpickrD
                      onReset={() => {
                        setSearchObj({
                          ...searchObj,
                          [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                          [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                        });
                      }}
                      onClose={(option) => {
                        if (option?.length === 1)
                          setSearchObj({
                            ...searchObj,
                            [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                            [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                          });
                      }}
                      disabled={!selectTypeObj?.SEARCH_DATE}
                      value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                      onChange={(option) => {
                        setSearchObj({
                          ...searchObj,
                          [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                          [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
            </Row>
            <Row style={{ textAlign: 'right' }}>
              <Col>
                <button
                  className="btn btn-search"
                  onClick={(e) => {
                    e.preventDefault();
                    fetchList(searchObj);
                  }}
                >
                  조회
                </button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </>
  );
};
