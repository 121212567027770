import GridBox from 'common/grid/gridBox';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections, toggleBooleanOptions } from 'common/util/searchBox';
import Divider from 'components/LDS/atoms/Divider';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { useFrontSearchData } from 'hooks/grid/useFrontSearchData';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { TransportStatementInfoSearchDTO } from '../../../../_interface/warehouse';
import AUIGrid from 'modules/Grid';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'reactstrap';
import { setLoading } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';

export interface IHaldangObj {
  visible?: boolean;
  item?: any;
}

const columns = [
  {
    headerText: '입고여부',
    dataField: 'unloadFlagKr',
  },
  {
    headerText: '주문취소여부',
    dataField: 'orderCancelFlagKr',
  },
  {
    headerText: '재고번호',
    dataField: 'inventorySeq',
  },
  {
    headerText: '시리얼번호',
    dataField: 'serialNumber',
  },
  {
    headerText: '재고유형', // 기존 : 제품유형
    dataField: 'availableTypeKr',
  },
  {
    headerText: '판매사',
    dataField: 'partnerSeqKr',
  },
  {
    headerText: '제품그룹',
    dataField: 'modelGroupKr',
  },
  {
    headerText: '제품',
    dataField: 'model',
  },
  {
    headerText: '제품명',
    dataField: 'modelName',
  },
  {
    headerText: '바코드',
    dataField: 'barcode',
  },
  {
    headerText: 'SKU',
    dataField: 'modelStockSeq',
  },
  {
    headerText: '외부SKU',
    dataField: 'skuNumber',
  },
  {
    headerText: '상품명',
    dataField: 'productName',
  },
  {
    headerText: '주문번호',
    dataField: 'orderSeq',
  },
  {
    headerText: '외부주문번호',
    dataField: 'externalOrderNumber',
  },
  {
    headerText: '송장번호',
    dataField: 'invoiceSeq',
  },
  {
    headerText: '외부송장번호',
    dataField: 'externalInvoiceNumber',
  },
  {
    headerText: '고객명',
    dataField: 'customerName',
  },
  {
    headerText: '고객주소',
    dataField: 'customerAddr',
  },
  {
    headerText: '연락처',
    dataField: 'customerPhone',
  },
  {
    headerText: '권역정보',
    dataField: 'zipcodeGroupInfo',
  },
  {
    headerText: '도크코드',
    dataField: 'dockNumber',
  },
  {
    headerText: '기사명', // 이전 : 설치기사
    dataField: 'driverName',
  },
  {
    headerText: '기사소속창고', // 이전 : 기사출고창고
    dataField: 'driverCenterCodeKr', // 이전 : centerCodeKr
  },
  {
    headerText: '지정일', // 이전 : 기사출고예정일
    dataField: 'deliveryDate',
  },
  {
    headerText: '고객주문일',
    dataField: 'orderDate',
  },
  {
    headerText: '비고',
    dataField: 'memo',
  },
  /*
  {
    headerText: '피킹지시번호',
    dataField: 'linehaulSeq',
  },
  {
    headerText: '출고번호',
    dataField: 'loadingStatementSeq',
  },
  {
    headerText: '운송번호',
    dataField: 'transportStatementSeq',
  },
  {
    headerText: '출발창고',
    dataField: 'originalCenterCodeKr',
  },
  {
    headerText: '도착창고',
    dataField: 'destinationCenterCodeKr',
  },
  {
    headerText: '입고예정일',
    dataField: 'promiseUnloadDate',
  },
  */
];

export const HaldangModal = ({ haldangObj, setHaldangObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();

  const convertKeyValue = {
    customerAddr: (row) => (row.customerAddr1 ? `${row.customerAddr1} ${row.customerAddr2 || ''}` : ''),
    centerCodeKr: (row) => MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
    originalCenterCodeKr: (row) => MASTER_OBJ?.CENTER_WHOLE?.[row.originalCenterCode],
    destinationCenterCodeKr: (row) => MASTER_OBJ?.CENTER_WHOLE?.[row.destinationCenterCode],
    driverCenterCodeKr: (row) => MASTER_OBJ?.CENTER_WHOLE?.[row.driverCenterCode],
    partnerSeqKr: (row) => MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
    modelGroupKr: (row) => MASTER_OBJ?.MODEL_GROUP?.[row.modelGroup],
    availableTypeKr: (row) => MASTER_OBJ?.INVA_TYPE?.[row.availableType],
    unloadFlagKr: (row) => (row.unloadFlag === true ? 'O' : 'X'),
    orderCancelFlagKr: (row) => (row.orderCancelFlag === true ? 'O' : 'X'),
    pickFlagKr: (row) => (row.pickFlag === true ? 'O' : 'X'),
    loadFlagKr: (row) => (row.loadFlag === true ? 'O' : 'X'),
  };
  const detailSelectType = { INVENTORY_SEQ: null, ORDER_SEQ: null, SEARCH_MODEL: null };

  const { setGridData, setDetailSelectTypeObj, handleSubmitFiltering, detailSelectTypeObj, detailSearchObj, setDetailSearchObj, convertFieldValue, returnKeyValues, resetFilters } = useFrontSearchData(
    {
      gridRef,
      initDetailSearchObj: {},
      detailSelectType,
      convertKeyValue,
    },
  );

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(MASTER_OPS?.INVENTORY_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.INVENTORY_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(MASTER_OPS?.ORDER_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, detailSearchObj));
  }, [detailSelectTypeObj?.SEARCH_MODE]);

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'TRUNKRECEIVE_TAB_HALDANG_EXCEL') {
      gridRef?.current?.exportAsXlsx({ fileName: '간선입고(할당정보)' });
    }
  };

  useEffect(() => {
    fetchHaldangInfo(haldangObj?.item);
  }, [haldangObj.item]);

  const fetchHaldangInfo = async (item) => {
    dispatch(setLoading('GET'));
    const searchDTO = {
      originalCenterCode: item.originalCenterCode,
      destinationCenterCode: item.destinationCenterCode,
      linehaulDate: item.linehaulDate,
      linehaulType: item.linehaulType,
      linehaulSeq: item.linehaulSeq,
      transportStatementSeq: item.transportStatementSeq,
      loadingStatementSeq: item.loadingStatementSeq,
    };
    const data = (await serviceStore?.warehouseAction(`linehaul/loading/unload/info`, 'GET', searchDTO as TransportStatementInfoSearchDTO))?.data;
    setGridData(data);
    const dataKr = data?.map((row) => {
      let keyValues = returnKeyValues(convertKeyValue, row);
      return {
        ...row,
        ...keyValues,
      };
    });
    gridRef?.current?.setGridData(dataKr);
    dispatch(setLoading(null));
  };

  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const gridFunctions = fetchUsableFunctionsInThisTab('TRUNKRECEIVE_TAB', 'GRID');

  useMemo(() => {
    fetchHaldangInfo(haldangObj?.item);
    const btns = printFunctionToBtns(
      gridFunctions?.filter((ele) => ele.functionId?.includes('TRUNKRECEIVE_TAB_HALDANG_EXCEL')),
      gridButtonhandler,
    );
    setFunctionBtns(btns);
  }, []);

  return (
    <CommonModalNew
      title="간선입고 할당정보"
      subTitle={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>운송번호</strong> : {haldangObj?.item?.transportStatementSeq || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>출발창고</strong> : {haldangObj?.item?.originalCenterCodeKr || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>도착창고</strong> : {haldangObj?.item?.destinationCenterCodeKr || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>진행상태</strong> : {haldangObj?.item?.statusKr || '-'}
        </div>
      }
      style={{ width: 1400 }}
      visible={haldangObj?.visible}
      setVisible={() => {
        setHaldangObj(null);
      }}
      children={
        <>
          <Form onSubmit={handleSubmitFiltering}>
            <Row>
              <Col>
                <label className="col-form-label">재고번호/시리얼번호</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={MASTER_OPS?.INVENTORY_SEQ}
                      value={
                        detailSelectTypeObj?.INVENTORY_SEQ
                          ? {
                              label: MASTER_OBJ?.INVENTORY_SEQ?.[detailSelectTypeObj?.INVENTORY_SEQ],
                              value: detailSelectTypeObj?.INVENTORY_SEQ,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          INVENTORY_SEQ: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.INVENTORY_SEQ}
                      value={detailSearchObj?.[detailSelectTypeObj?.INVENTORY_SEQ] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.INVENTORY_SEQ]: convertFieldValue(detailSelectTypeObj['INVENTORY_SEQ'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">주문번호/송장번호</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={MASTER_OPS?.ORDER_SEQ}
                      value={
                        detailSelectTypeObj?.ORDER_SEQ
                          ? {
                              label: MASTER_OBJ?.ORDER_SEQ?.[detailSelectTypeObj?.ORDER_SEQ],
                              value: detailSelectTypeObj?.ORDER_SEQ,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          ORDER_SEQ: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.ORDER_SEQ}
                      value={detailSearchObj?.[detailSelectTypeObj?.ORDER_SEQ] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.ORDER_SEQ]: convertFieldValue(detailSelectTypeObj['ORDER_SEQ'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">제품/제품명</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={MASTER_OPS?.SEARCH_MODEL}
                      value={
                        detailSelectTypeObj?.SEARCH_MODEL
                          ? {
                              label: MASTER_OBJ?.SEARCH_MODEL?.[detailSelectTypeObj?.SEARCH_MODEL],
                              value: detailSelectTypeObj?.SEARCH_MODEL,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          SEARCH_MODEL: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.SEARCH_MODEL}
                      value={detailSearchObj?.[detailSelectTypeObj?.SEARCH_MODEL] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.SEARCH_MODEL]: convertFieldValue(detailSelectTypeObj['SEARCH_MODEL'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">판매사</label>
                <div style={{ display: 'flex' }}>
                  <Col style={{ display: 'inline-block' }}>
                    <SelectD
                      options={MASTER_OPS?.SELLER_SELLER_AUTH}
                      value={
                        detailSearchObj?.partnerSeq
                          ? {
                              label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[detailSearchObj?.partnerSeq],
                              value: detailSearchObj?.partnerSeq,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          partnerSeq: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>

              <Col>
                <label className="col-form-label">입고여부</label>
                <div
                  className="radio-select-area"
                  onClick={(e) => {
                    toggleBooleanOptions('unloadFlag', (e.target as HTMLDivElement).dataset.flagId, detailSearchObj, setDetailSearchObj);
                  }}
                >
                  <span className={detailSearchObj?.unloadFlag === true ? 'active' : ''} data-flag-id="true">
                    O
                  </span>
                  <span className={detailSearchObj?.unloadFlag === false ? 'active' : ''} data-flag-id="false">
                    X
                  </span>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">주문취소여부</label>
                <div
                  className="radio-select-area"
                  onClick={(e) => {
                    toggleBooleanOptions('orderCancelFlag', (e.target as HTMLDivElement).dataset.flagId, detailSearchObj, setDetailSearchObj);
                  }}
                >
                  <span className={detailSearchObj?.orderCancelFlag === true ? 'active' : ''} data-flag-id="true">
                    O
                  </span>
                  <span className={detailSearchObj?.orderCancelFlag === false ? 'active' : ''} data-flag-id="false">
                    X
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col />
              <Col />
              <Col />
              <Col />
              <Col />
              <Col style={{ textAlign: 'right', placeSelf: 'flex-end', display: 'flex', gap: '4px' }}>
                <button type="button" className="btn" style={{ border: '1px solid #DADADA', minWidth: '60px' }} onClick={resetFilters}>
                  초기화
                </button>
                <button type="submit" className="btn btn-search" style={{ flexGrow: 1 }}>
                  조회
                </button>
              </Col>
            </Row>
          </Form>
          <Divider $direction={'H'} $color={'#eaeaea'} $spacing={'LG'} $value={'FULL'} />
          {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
          <GridBox gridRef={gridRef} columns={columns} gridProps={{ showRowAllCheckBox: false, showRowCheckColumn: false }} />
        </>
      }
    />
  );
};
