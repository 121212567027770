import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './ssList';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';

export const SSLIST_MASTER = [
  //
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  'INFRA',
  //
  'INVOICE_STATUS',
  'SS_FEE_TYPE',

  'INV_TRACK_TYPE',
  'INVOICE_UPDATE_CONTENT',
  //
  'SEARCH_ORDER',
  'SEARCH_INVOICE',
  //
  'PROMISE_TIME_ZONE_1',
  'OX_FLAG',
  'ACTIVE_OP',
  //
  {
    SEARCH_SEQ: [
      {
        value: 'orderSeq',
        label: '주문번호',
      },
      {
        value: 'externalOrderNumber',
        label: '외부주문번호',
      },
      {
        value: 'transOrderNumber',
        label: '묶음번호',
      },
      {
        value: 'deliverySeq',
        label: '배송번호',
      },
      {
        value: 'invoiceSeq',
        label: '송장번호',
      },
      {
        value: 'externalInvoiceNumber',
        label: '외부송장번호',
      },
      {
        value: 'parcelInvoiceNumber',
        label: '운송장번호',
      },
    ],
  },
  {
    SEARCH_CENTER: [
      {
        value: 'centerCodeList',
        label: '보관창고',
      },
      {
        value: 'startCenterCodeList',
        label: '출발창고',
      },
    ],
  },
  {
    SEARCH_DRIVER: [
      {
        value: 'centerCodeList',
        label: '보관창고',
      },
      {
        value: 'startCenterCodeList',
        label: '출발창고',
      },
    ],
  },
  'SEARCH_MODEL',
  {
    SEARCH_DRIVER: [
      {
        value: 'lhUserName',
        label: '운송기사',
      },
      {
        value: 'installerName',
        label: '설치기사',
      },
    ],
  },
  {
    SEARCH_NAME: [
      {
        value: 'receiverName',
        label: '실수령자',
      },
      {
        value: 'customerName',
        label: '주문자',
      },
    ],
  },
  {
    SEARCH_ADDRESS: [
      {
        value: 'receiverAddr',
        label: '실수령자',
      },
      {
        value: 'customerAddr',
        label: '주문자',
      },
    ],
  },
  {
    SEARCH_PHONE: [
      {
        value: 'receiverPhone',
        label: '실수령자',
      },
      {
        value: 'customerPhone',
        label: '주문자',
      },
    ],
  },
  //
  'PROMISE_TIME_ZONE_1',
  'OX_FLAG',
  'ACTIVE_OP',
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(SSLIST_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            //
            SS_LIST: <Tab1 tabId={'SS_LIST'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
