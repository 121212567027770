import axios from 'axios';
import { USER_SIGN_REFRESH, USER_MY_INFO, USER_SIGN_VALID_TOKEN } from 'envVar';
import { httpClient } from '../http-client/axiosConfig';

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken) {
    try {
      const rs = await axios.post(process.env.REACT_APP_API_URL + USER_SIGN_REFRESH, null, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });
      return rs;
    } catch (error) {
      return error;
    }
  } else {
    localStorage.setItem('accessToken', '');
    localStorage.setItem('refreshToken', '');
    localStorage.setItem('accessTokenExp', ''); //setting decoded
    window.location.replace('/login');
  }
};

export const checkUserToken = async () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    try {
      const rs = await axios.get(process.env.REACT_APP_API_URL + USER_MY_INFO, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log('유저정보 조회', rs.data)
      localStorage.setItem('userName', rs.data.userName);
      localStorage.setItem('userPhone', rs.data.phoneNumber);
      localStorage.setItem('userEmail', rs.data.email);
      localStorage.setItem('userId', rs.data.userId);
    } catch (error) {
      // console.log("user fetch error")
      refreshToken();
    }
  } else {
    refreshToken();
  }
};

export const validate = async () => {
  const token = localStorage.getItem('accessToken');
  await httpClient
    .get(USER_SIGN_VALID_TOKEN)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

// token만 업데이트하는 함수
export const updateToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) return null;

  // NOTE: token갱신 API는 serviceStore 미사용
  const rs = await axios.post(process.env.REACT_APP_API_URL + USER_SIGN_REFRESH, null, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
  if (!rs) return null;

  return {
    accessToken: rs?.data?.accessToken,
    accessTokenExp: rs?.data?.accessTokenExp,
    refreshToken: rs?.data?.refreshToken,
    refreshTokenExp: rs?.data?.refreshTokenExp,
  };
};
