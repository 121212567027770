import CommonModalNew from 'components/modal/commonModalNew';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { serviceStore } from 'services/services';
import { GroupDetailDTO } from 'interface/user';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import GridBox, { defaultGridProps } from 'common/grid/gridBox';
import AUIGrid from 'modules/Grid';
import { Col, Row, Form } from 'reactstrap';

import * as IGrid from 'aui-grid';
import { ControllerScreen, ITrappingData } from 'pages/ETC/4settings/settingTabs/1groups/component/modiMenuModal';
export interface IAuthDetailObj {
  visible: boolean;
  item: any;
  searchObj?: any;
  fetchFn: (searchObj) => {};
}

const tabIdColumns: IGrid.Column[][] = [
  [],
  [
    {
      headerText: '이름',
      dataField: 'userName',
    },
    {
      headerText: '아이디',
      dataField: 'userId',
    },
    {
      headerText: '사용자 유형',
      dataField: 'userCodeKr',
    },
    {
      headerText: '소속파트너',
      dataField: 'partnerSeqKr',
    },
  ],
  [],
  [
    {
      headerText: '판매사',
      dataField: 'partnerName',
    },
  ],
  [
    {
      headerText: '인프라',
      dataField: 'infraName',
    },
  ],
  [
    {
      headerText: '창고명',
      dataField: 'centerName',
    },
  ],
  [
    {
      headerText: '타창고명',
      dataField: 'diffCenterName',
    },
  ],
  [
    {
      headerText: '판매채널명',
      dataField: 'salesChannelName',
    },
  ],
];
const translatorSeq = ['', 'userId', '', 'partnerSeq', 'infraSeq', 'centerCode', 'diffCenterCode', 'salesChannelSeq'];
const transObj = ['', 'users', '', 'partners', 'infras', 'centers', 'diffCenters', 'salesChannels'];

export const AuthGroupDetail = ({ authDetailObj, setAuthDetailObj }) => {
  const excludedRef = useRef<AUIGrid>();
  const includedRef = useRef<AUIGrid>();
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [detailData, setDetailData] = useState<GroupDetailDTO>();

  const [searchObj, setSearchObj] = useState<{ [key: string]: any }>();

  useEffect(() => {
    if (detailData) {
      bindFunctions();
    }
  }, [detailData]);

  const printFlagData = (e: IGrid.DropEndEvent) => {
    const excluded = excludedRef?.current?.getGridData()?.map((ele) => {
      return {
        ...ele,
        useFlag: false,
      };
    });
    const included = includedRef?.current?.getGridData()?.map((ele) => {
      return {
        ...ele,
        useFlag: true,
      };
    });
    const objKey = e.items?.[0]?.objKey;
    if (objKey === 'users') {
      const isManIncluded = included?.map((ele) => ele.userId)?.includes(detailData?.userId);
      if (!isManIncluded) {
        setDetailData((prev) => {
          return {
            ...prev,
            userId: null,
            userName: null,
          };
        });
      }
      setUsersOn(
        included?.map((ele) => {
          return {
            label: ele.userName,
            value: ele.userId,
          };
        }),
      );
    }
    if (objKey) {
      setDetailData((prev) => {
        return {
          ...prev,
          [objKey]: [...excluded, ...included],
        };
      });
      //
      excludedRef?.current?.setGridData(excluded);
      includedRef?.current?.setGridData(included);
    }
  };

  const bindFunctions = () => {
    excludedRef?.current?.bind('dropEnd', function (e) {
      printFlagData(e);
    });
    includedRef?.current?.bind('dropEnd', function (e) {
      printFlagData(e);
    });
  };

  const fetchDetail = async (groupSeq) => {
    dispatch(setLoading('GET'));
    setSearchObj(null);
    const data = (await serviceStore?.userAction(groupSeq ? `group/detail?groupSeq=${groupSeq}` : `group/detail`, 'GET', null))?.data;
    const users = data?.users?.map((user) => {
      return {
        ...user,
        key: 'userId',
        userCodeKr: MASTER_OBJ?.['USER_CODE']?.[user.userCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[user.partnerSeq],
        objKey: 'users',
      };
    });
    const detailData = {
      ...data,
      users,
      centers: data?.centers?.map((ele) => {
        return {
          ...ele,
          key: 'centerCode',
          objKey: 'centers',
        };
      }),
      diffCenters: data?.diffCenters?.map((ele) => {
        return {
          ...ele,
          key: 'diffCenterCode',
          objKey: 'diffCenters',
        };
      }),
      infras: data?.infras?.map((ele) => {
        return {
          ...ele,
          key: 'infraSeq',
          objKey: 'infras',
        };
      }),
      partners: data?.partners?.map((ele) => {
        return {
          ...ele,
          key: 'partnerSeq',
          objKey: 'partners',
        };
      }),
      salesChannels: data?.salesChannels?.map((ele) => {
        return {
          ...ele,
          key: 'salesChannelSeq',
          objKey: 'salesChannels',
        };
      }),
    };
    setUsersOn(
      users
        ?.filter((ele) => ele.useFlag)
        ?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.userName,
          };
        }),
    );
    setDetailData(detailData);
    dispatch(setLoading(null));
  };

  useEffect(() => {
    fetchDetail(authDetailObj?.item?.groupSeq || null);
  }, []);

  const clickDrag = (e) => {
    const id = e.target.id?.split('-')[1];
    if (currentTab !== 2) {
      const key = translatorSeq[currentTab];
      const checkedItems = id === 'included' ? excludedRef?.current?.getCheckedRowItemsAll() : includedRef?.current?.getCheckedRowItemsAll();
      const checkedSeqs = checkedItems?.map((ele) => ele?.[key]);
      const beforeEx = excludedRef?.current?.getGridData();
      const beforeIn = includedRef?.current?.getGridData();
      const excludedRow = id === 'included' ? beforeEx?.filter((ele) => !checkedSeqs?.includes(ele?.[key])) : beforeEx?.concat(checkedItems)?.sort((a, b) => b?.[key] - a?.[key]);
      const includedRow = id === 'excluded' ? beforeIn?.filter((ele) => !checkedSeqs?.includes(ele?.[key])) : beforeIn?.concat(checkedItems)?.sort((a, b) => b?.[key] - a?.[key]);

      const incs = includedRow?.map((ele) => ele?.[key]);
      setDetailData((prev) => {
        return {
          ...prev,
          [transObj[currentTab]]: prev?.[transObj[currentTab]]?.map((row) => {
            return {
              ...row,
              useFlag: incs?.includes(row?.[key]) ? true : false,
            };
          }),
        };
      });
      if (currentTab === 1)
        setUsersOn(
          includedRow?.map((ele) => {
            return {
              value: ele.userId,
              label: ele.userName,
            };
          }),
        );
      excludedRef?.current?.setGridData(excludedRow);
      includedRef?.current?.setGridData(includedRow);
    }
    //
  };

  // 미추가 리스트만 필터링
  const filterSearch = () => {
    if (currentTab && currentTab !== 2) {
      let data = detailData?.[transObj[currentTab]]?.filter((ele) => !ele.useFlag);
      const searchKeys = Object.keys(searchObj);
      const searchValues = Object.values(searchObj);
      const hasValues = searchValues?.filter((ele) => ele || ele !== '');
      if (hasValues?.length === 0) {
        excludedRef?.current?.setGridData(data);
      } else {
        // data;
        searchValues?.forEach((ele, idx) => {
          if (ele) {
            data = data?.filter((row) => row?.[searchKeys?.[idx]]?.includes(ele));
          }
        });
        excludedRef?.current?.setGridData(data);
      }
    }
  };

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      filterSearch();
    }
  };

  const validate = (data: GroupDetailDTO) => {
    if (!data?.groupName) {
      alert('조직명은 필수입니다!');
      return false;
    }
    if (!data?.userId) {
      alert('조직장은 필수입니다!');
      return false;
    }
    return true;
  };

  const [trappingData, setTrappingData] = useState<ITrappingData>();
  const postSave = async (frontData = null) => {
    if (validate(detailData)) {
      if (window?.confirm(`저장하시겠습니까?`)) {
        const dataDTO = {
          ...detailData,
          ...frontData,
          users: detailData?.users?.filter((ele) => ele.useFlag),
          partners: detailData?.partners?.filter((ele) => ele.useFlag),
          infras: detailData?.infras?.filter((ele) => ele.useFlag),
          centers: detailData?.centers?.filter((ele) => ele.useFlag),
          diffCenters: detailData?.diffCenters?.filter((ele) => ele.useFlag),
          salesChannels: detailData?.salesChannels?.filter((ele) => ele.useFlag),
        };
        dispatch(setLoading('POST'));
        const rs = await serviceStore.userAction(`group/save`, 'POST', null, dataDTO);
        console.log(rs);
        if (rs?.status === 200) {
          alert('저장되었습니다!');
          if (rs?.data?.data) fetchDetail(rs?.data?.data);
          authDetailObj?.fetchFn(authDetailObj?.searchObj);
        }
        dispatch(setLoading(null));
      }
    }
  };

  useEffect(() => {
    if (trappingData?.data) {
      postSave(trappingData?.data);
    }
  }, [trappingData]);

  const [currentTab, setCurrentTab] = useState(0);
  const hasTabs = [
    { title: '기본정보' },
    { title: '조직원' },
    { title: '화면 권한' },
    { title: '판매사 권한' },
    { title: '인프라 권한' },
    { title: '창고 권한' },
    { title: '타창고 권한' },
    { title: '판매채널 권한' },
  ];
  const [usersOn, setUsersOn] = useState<OptionItem[]>();
  const handleCurrentTab = (e) => {
    dispatch(setLoading('GET'));
    const nextTab = Number(e.target.id?.split('_')[1]);
    if (nextTab) {
      if (nextTab !== 2) {
        const tabObj = transObj?.[nextTab];
        includedRef?.current?.changeColumnLayout(tabIdColumns[nextTab]);
        excludedRef?.current?.changeColumnLayout(tabIdColumns[nextTab]);
        const includedRow = detailData?.[tabObj]?.filter((ele) => ele.useFlag);
        const excludedRow = detailData?.[tabObj]?.filter((ele) => !ele.useFlag);
        includedRef?.current?.setGridData(includedRow);
        excludedRef?.current?.setGridData(excludedRow);
      }
    }
    setSearchObj(null);
    setCurrentTab(nextTab);

    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      style={{ width: 1280 }}
      visible={authDetailObj?.visible}
      setVisible={() => {
        setAuthDetailObj(null);
      }}
      subTitle={authDetailObj?.item ? `${authDetailObj?.item?.groupName}(${authDetailObj?.item?.userName})` : ''}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={() => {
              setTrappingData({
                on: true,
                data: null,
              });
            }}
          >
            {authDetailObj?.item ? '수정' : '추가'}
          </button>
        </>
      }
      title={`조직 ${authDetailObj?.item ? '상세보기' : '추가'}`}
      children={
        <div style={{ height: 700 }}>
          <div className="div-top-tab">
            {hasTabs.map((tab, index) => {
              return (
                <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                  <li id={`tab_${index}`} onClick={handleCurrentTab}>
                    {tab?.title}
                  </li>
                </div>
              );
            })}
          </div>

          <div style={{ flexDirection: 'column', zIndex: currentTab === 0 ? 13 : 10 }}>
            <table className="border-table">
              <tbody>
                <tr>
                  <th>*조직명</th>
                  <td colSpan={2}>
                    <InputD
                      id="groupName"
                      value={detailData?.groupName || ''}
                      onChange={(e) => {
                        setDetailData((prev) => {
                          return {
                            ...prev,
                            groupName: e.target.value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*조직장명</th>
                  <td colSpan={2}>
                    <InputD id="userName" disabled readOnly value={detailData?.userName || ''} placeholder="'조직원'탭에서 조직장을 선택해주세요" />
                  </td>
                  <th>*조직유형</th>
                  <td colSpan={2}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ display: 'flex', marginRight: 10 }}>
                        <input
                          type="checkbox"
                          id="ownerFlag"
                          checked={detailData?.ownerFlag || false}
                          onChange={(e) => {
                            setDetailData((prev) => {
                              return {
                                ...prev,
                                ownerFlag: !detailData?.ownerFlag,
                              };
                            });
                          }}
                        />
                        <label htmlFor="ownerFlag">화주사</label>
                      </span>
                      <span style={{ display: 'flex' }}>
                        <input
                          type="checkbox"
                          id="logisticFlag"
                          checked={detailData?.logisticFlag || false}
                          onChange={(e) => {
                            setDetailData((prev) => {
                              return {
                                ...prev,
                                logisticFlag: !detailData?.logisticFlag,
                              };
                            });
                          }}
                        />
                        <label htmlFor="logisticFlag">물류사</label>
                      </span>
                    </div>
                  </td>
                  <td colSpan={3} />
                </tr>
                <tr />
              </tbody>
            </table>
          </div>
          <div style={{ flexDirection: 'column', zIndex: currentTab === 2 ? 13 : 10 }}>
            {detailData && <ControllerScreen rawData={detailData} trappingData={trappingData} setTrappingData={setTrappingData} />}
          </div>
          <div style={{ flexDirection: 'column', zIndex: currentTab && currentTab !== 2 ? 13 : 10, paddingTop: 0 }}>
            <div className="presenterSearch">
              <label className="b">[미추가 리스트 검색]</label>
              <Form onKeyDown={onKeyPress}>
                <Row>
                  {tabIdColumns?.[currentTab]?.map((ele, idx) => {
                    return (
                      <Col key={`${ele.dataField}_${idx}`}>
                        <label className="col-form-label">{ele?.headerText}</label>
                        <InputD
                          value={searchObj?.[ele.dataField] || ''}
                          onChange={(e) => {
                            setSearchObj({
                              ...searchObj,
                              [ele.dataField]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    );
                  })}
                  <Col>
                    <label className="col-form-label" style={{ height: 12 }}></label>
                    <button
                      className="btn btn-search"
                      onClick={(e) => {
                        e.preventDefault();
                        filterSearch();
                      }}
                    >
                      조회
                    </button>
                  </Col>

                  {currentTab === 1 ? (
                    <Col>
                      <label className="col-form-label">*조직장</label>
                      <SelectD
                        hasNull={false}
                        options={usersOn}
                        value={
                          detailData?.userId
                            ? {
                                label: detailData?.userName,
                                value: detailData?.userId,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setDetailData((prev) => {
                            return {
                              ...prev,
                              userId: (option as OptionItem)?.value,
                              userName: (option as OptionItem)?.label,
                            };
                          });
                        }}
                      />
                    </Col>
                  ) : (
                    <>
                      <Col />
                      <Col />
                    </>
                  )}
                </Row>
              </Form>
            </div>
            <div className="grid2-wrapper" style={{ paddingTop: 3 }}>
              <div style={{ width: 'calc(50% - 30px)' }}>
                <label className="b">[미추가 리스트]</label>
                <GridBox
                  gridName="excluded-jojik"
                  gridRef={excludedRef}
                  columns={tabIdColumns[currentTab]}
                  gridProps={{
                    ...defaultGridProps,
                    enableDrag: true,
                    enableDrop: true,
                    dropToOthers: true,
                    showRowAllCheckBox: true,
                    showRowCheckColumn: true,
                    pageRowCount: 500,
                    pageRowSelectValues: [500],
                  }}
                />
              </div>
              <div style={{ width: 'calc(50% - 30px)' }}>
                <div className="grid-button-between">
                  <div className="button" id={'to-included'} onClick={clickDrag}>
                    ▶️ 추 가
                  </div>
                  <div className="button" id={'to-excluded'} onClick={clickDrag}>
                    삭 제 ◀️
                  </div>
                </div>
                <label className="b">[추가된 리스트]</label>

                <GridBox
                  gridName="included-jojik"
                  gridRef={includedRef}
                  columns={tabIdColumns[currentTab]}
                  gridProps={{
                    ...defaultGridProps,
                    enableDrag: true,
                    enableDrop: true,
                    dropToOthers: true,
                    showRowAllCheckBox: true,
                    showRowCheckColumn: true,
                    pageRowCount: 500,
                    pageRowSelectValues: [500],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
