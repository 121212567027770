import CommonModalNew from 'components/modal/commonModalNew';

export const CHAIprint = ({ printObj, setPrintObj }) => {
  return (
    <CommonModalNew
      title={'차이냐오 운송장PDF'}
      style={{ width: 1000 }}
      visible={printObj?.visible}
      setVisible={() => {
        setPrintObj(null);
      }}
      children={
        <>
          <textarea readOnly value={printObj?.data} style={{ height: 300 }} />
        </>
      }
    />
  );
};
