import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as IGrid from 'aui-grid';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { Col, Row, Form } from 'reactstrap';
import { LinehaulDetailDTOForCreateSecond, LinehaulImportSearchDTO, InventoryInfoSearchDTO, LinehaulImportDTO, LinehaulImportInventoryInfoDTO, InventoryInfoDTO } from 'interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';
import { resetAllSelections } from 'common/util/searchBox';

import AUIGrid from 'modules/Grid';
import GridBox from 'common/grid/gridBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { serviceStore } from 'services/services';

export interface IModelObj {
  visible?: boolean;
  type?: 'picked' | 'model';
  postObj?: LinehaulDetailDTOForCreateSecond;
  returns?: any[];
}

export const ModelPopup = ({ modelObj, setModelObj }) => {
  const gridRef = useRef<AUIGrid>();
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);
  const [searchObj, setSearchObj] = useState<LinehaulImportDTO & InventoryInfoSearchDTO>(
    modelObj?.type === 'model'
      ? {
          ...defaultSearchFilter,
          centerCode: modelObj?.postObj?.originalCenterCode,
        }
      : {
          originalCenterCode: modelObj?.postObj?.originalCenterCode,
          destinationCenterCode: modelObj?.postObj?.destinationCenterCode,
          linehaulDate: modelObj?.postObj?.linehaulDate,
        },
  );
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SEQ, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ]);
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const columns: { [key: string]: IGrid.Column[] } = {
    picked: [
      {
        headerText: '출고예정일',
        dataField: 'linehaulDate',
      },
      {
        headerText: '출고창고',
        dataField: 'originalCenterCodeKr',
      },
      {
        headerText: '도착창고',
        dataField: 'destinationCenterCodeKr',
      },
      {
        headerText: '간선유형',
        dataField: 'linehaulTypeKr',
      },
      {
        headerText: '간선피킹대상수량',
        children: [
          {
            headerText: '양품',
            dataField: 'availableQuantity',
          },
          {
            headerText: '불용',
            dataField: 'defectedQuantity',
          },
          {
            headerText: '기타',
            dataField: 'etcQuantity',
          },
        ],
      },
    ],
    model: [
      {
        headerText: '제품정보',
        children: [
          {
            headerText: '판매사',
            dataField: 'partnerSeqKr',
          },
          {
            headerText: 'SKU',
            dataField: 'modelStockSeq',
          },
          {
            headerText: '외부SKU',
            dataField: 'skuNumber',
          },
          {
            headerText: '제품그룹',
            dataField: 'modelGroupKr',
          },
          {
            headerText: '제품',
            dataField: 'model',
          },
          {
            headerText: '제품명',
            dataField: 'modelName',
          },
          {
            headerText: '바코드',
            dataField: 'barcode',
          },
          {
            headerText: '로케이션',
            dataField: 'locationCode',
          },
        ],
      },
      {
        headerText: '로케이션<br/>총 수량',
        dataField: 'totalQuantity',
      },
      {
        headerText: '이동불가능<br />수량',
        dataField: 'unavailableQuantity',
      },
      {
        headerText: '이동가능수량',
        children: [
          {
            headerText: '양품',
            dataField: 'availableQuantity',
          },
          {
            headerText: '불용',
            dataField: 'defectedQuantity',
          },
          {
            headerText: '기타',
            dataField: 'etcQuantity',
          },
        ],
      },
    ],
  };

  const findDetails = async (dataDTO: LinehaulImportDTO[]) => {
    dispatch(setLoading('GET'));
    const rs = await serviceStore?.warehouseAction(`linehaul/picking/import/inventory/info`, 'POST', null, dataDTO);
    dispatch(setLoading(null));
    const data = rs?.data as LinehaulImportInventoryInfoDTO[];

    if (data?.length === 0) {
      alert('조회되는 상세 정보가 없어 추가 할 수 없습니다');
      return [];
    } else {
      const rows = data?.map((row, idx) => {
        return {
          ...dataDTO[idx],
          ...row, //NOTE: row와 dataDTO[idx]에 모두 etcQuantity가 존재하는데, row의 etcQuantity가 적용되도록 순서 수정
          availableQuantity: 0,
          defectedQuantity: 0,
        };
      });
      return labellingKrForDetail(rows);
    }
  };

  const returnThese = async () => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (modelObj?.type === 'picked') {
        const returns = await findDetails(items);
        if (returns?.length > 0) {
          setModelObj((prev) => {
            return {
              ...prev,
              returns,
            };
          });
        }
      } else {
        setModelObj((prev) => {
          return {
            ...prev,
            returns: items,
          };
        });
      }
    } else {
      alert('선택한 제품이 없습니다');
    }
  };

  const labellingKrForDetail = (data) => {
    const labeledList = data?.map((row) => {
      return {
        ...row,
        partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
        supplierPartnerSeqKr: MASTER_OBJ?.SELLER_SUPPLIER_WHOLE?.[row?.supplierPartnerSeq],
        modelTypeKr: MASTER_OBJ?.MODEL_TYPE?.[row?.modelType],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        linehaulTypeKr: MASTER_OBJ?.LINEHAUL_TYPE?.[row?.linehaulType],
        statusKr: MASTER_OBJ?.LINE_STAT?.[row?.status],
        originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.originalCenterCode ? row?.originalCenterCode : row?.centerCode],
        destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.destinationCenterCode],
      };
    });
    return labeledList;
  };

  const labellingKr = (data: (LinehaulImportDTO & InventoryInfoDTO)[]) => {
    const labeledList = data?.map((row) => {
      return {
        ...row,
        partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        linehaulTypeKr: MASTER_OBJ?.LINEHAUL_TYPE?.[row?.linehaulType],
        statusKr: MASTER_OBJ?.LINE_STAT?.[row?.status],
        originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.originalCenterCode ? row?.originalCenterCode : row?.centerCode],
        destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.destinationCenterCode],
      };
    });
    return labeledList;
  };

  const fetchPaging = async (searchObj: LinehaulImportSearchDTO & InventoryInfoSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(
      (await serviceStore?.warehouseAction(modelObj?.type === 'model' ? 'inventory/line/info' : 'linehaul/picking/import/list', 'GET', searchObj, null))?.data as (LinehaulImportDTO &
        InventoryInfoDTO)[],
    );
    gridRef?.current?.setGridData(dataKr);
    dispatch(setLoading(null));
  };

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    fetchPaging(searchObj);
  };

  return (
    <CommonModalNew
      title={modelObj?.type === 'picked' ? '피킹지시대기 조회' : '+항목 추가'}
      subTitle={modelObj?.type === 'picked' ? '' : `출고창고: ${MASTER_OBJ?.CENTER_WHOLE?.[modelObj?.postObj?.originalCenterCode]}`}
      style={{ width: 1300 }}
      visible={modelObj?.visible}
      setVisible={() => {
        setModelObj(null);
      }}
      children={
        <>
          <Form onSubmit={handleSubmit}>
            {modelObj?.type === 'picked' ? (
              <>
                <Row>
                  <Col>
                    <label className="col-form-label">출고 예정일</label>
                    <FlatpickrD
                      value={searchObj?.linehaulDate}
                      onChange={(value) => {
                        setSearchObj({
                          ...searchObj,
                          linehaulDate: returnDateyyyymmdd(value[0]),
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label className="col-form-label">간선유형</label>
                    <SelectD
                      value={
                        searchObj?.linehaulType
                          ? {
                              value: searchObj?.linehaulType,
                              label: MASTER_OBJ?.LINEHAUL_TYPE?.[searchObj?.linehaulType],
                            }
                          : null
                      }
                      options={MASTER_OPS?.LINEHAUL_TYPE}
                      onChange={(option) => {
                        setSearchObj({
                          ...searchObj,
                          linehaulType: (option as OptionItem)?.value,
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label className="col-form-label">*출고창고</label>
                    <SelectD
                      value={
                        searchObj?.originalCenterCode
                          ? {
                              value: searchObj?.originalCenterCode,
                              label: MASTER_OBJ?.CENTER_WHOLE?.[searchObj?.originalCenterCode],
                            }
                          : null
                      }
                      isDisabled
                    />
                  </Col>
                  <Col>
                    <label className="col-form-label">*도착창고</label>
                    <SelectD
                      options={MASTER_OPS?.CENTER_DIFF}
                      value={
                        searchObj?.destinationCenterCode
                          ? {
                              value: searchObj?.destinationCenterCode,
                              label: MASTER_OBJ?.CENTER_DIFF?.[searchObj?.destinationCenterCode],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setSearchObj((prev) => {
                          return {
                            ...prev,
                            destinationCenterCode: (option as OptionItem)?.value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <div style={{ paddingTop: 10, textAlign: 'left' }}>
                      <button className="btn btn-search" type="submit">
                        조회
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    <label>주문정보</label>
                    <div style={{ display: 'flex' }}>
                      <Col md={4} style={{ display: 'inline-block' }}>
                        <SelectD
                          value={
                            selectTypeObj?.SEARCH_SEQ
                              ? {
                                  value: selectTypeObj?.SEARCH_SEQ,
                                  label: MASTER_OBJ?.SEARCH_SEQ[selectTypeObj?.SEARCH_SEQ],
                                }
                              : null
                          }
                          options={MASTER_OPS?.SEARCH_SEQ}
                          onChange={(option) => {
                            setSelectTypeObj({
                              ...selectTypeObj,
                              SEARCH_SEQ: (option as OptionItem).value,
                            });
                          }}
                        />
                      </Col>
                      <Col md={8} style={{ display: 'inline-block' }}>
                        <InputD
                          disabled={!selectTypeObj?.SEARCH_SEQ}
                          value={searchObj?.[selectTypeObj?.SEARCH_SEQ] || ''}
                          onChange={(e) => {
                            setSearchObj({
                              ...searchObj,
                              [selectTypeObj?.SEARCH_SEQ]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </div>
                  </Col>
                  <Col>
                    <label>판매사</label>
                    <SelectD
                      options={MASTER_OPS?.SELLER_SELLER_AUTH}
                      value={
                        searchObj?.partnerSeq
                          ? {
                              value: searchObj?.partnerSeq,
                              label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[searchObj?.partnerSeq],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setSearchObj((prev) => {
                          return {
                            ...prev,
                            partnerSeq: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label>제품그룹</label>
                    <SelectD
                      options={MASTER_OPS?.MODEL_GROUP}
                      value={
                        searchObj?.modelGroup
                          ? {
                              value: searchObj?.modelGroup,
                              label: MASTER_OBJ?.MODEL_GROUP?.[searchObj?.modelGroup],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setSearchObj((prev) => {
                          return {
                            ...prev,
                            modelGroup: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label>제품</label>
                    <div style={{ display: 'flex' }}>
                      <Col md={4} style={{ display: 'inline-block' }}>
                        <SelectD
                          value={
                            selectTypeObj?.SEARCH_MODEL
                              ? {
                                  value: selectTypeObj?.SEARCH_MODEL,
                                  label: MASTER_OBJ?.SEARCH_MODEL[selectTypeObj?.SEARCH_MODEL],
                                }
                              : null
                          }
                          options={MASTER_OPS?.SEARCH_MODEL}
                          onChange={(option) => {
                            setSelectTypeObj({
                              ...selectTypeObj,
                              SEARCH_MODEL: (option as OptionItem).value,
                            });
                          }}
                        />
                      </Col>
                      <Col md={8} style={{ display: 'inline-block' }}>
                        <InputD
                          disabled={!selectTypeObj?.SEARCH_MODEL}
                          value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                          onChange={(e) => {
                            setSearchObj({
                              ...searchObj,
                              [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </div>
                  </Col>
                  <Col>
                    <label className="col-form-label">유사검색여부</label>
                    <div className="radio-select-area">
                      <span
                        className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                        onClick={() => {
                          setSearchObj((prev) => {
                            return {
                              ...prev,
                              similaritySearchFlag: true,
                            };
                          });
                        }}
                      >
                        유사검색
                      </span>
                      <span
                        className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                        onClick={() => {
                          setSearchObj((prev) => {
                            return {
                              ...prev,
                              similaritySearchFlag: false,
                            };
                          });
                        }}
                      >
                        일치검색
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div style={{ paddingTop: 10, textAlign: 'left' }}>
                      <button className="btn btn-search" type="submit">
                        조회
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Form>
          <div className="presenterGridBox">
            <div className="grid-button-area only-right">
              <div
                className="blue"
                onClick={(e) => {
                  e.preventDefault();
                  returnThese();
                }}
              >
                +추가
              </div>
            </div>
            <GridBox
              gridRef={gridRef}
              columns={columns[modelObj?.type]}
              gridProps={{
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
            />
          </div>
        </>
      }
    />
  );
};
