import { useState, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './picking';
import Tab2 from './takeout';

// options

import { RootState } from 'redux/store.js';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { TopTabController } from 'components/template/TopTabController';
import { nullingMASTER } from 'redux/services/menuSlice';

export const DELIVERY_WORKS_MASTER = [
  'CENTER_AUTH',
  'CENTER_WHOLE',
  //
  'SELLER_SELLER_WHOLE',
  //
  'REMOVE_STATUS',
  'MODEL_GROUP',
  //
  {
    TIME_ZONE: [
      { value: 'AM', label: '오전' },
      { value: 'PM', label: '오후' },
      { value: 'NONE', label: '없음' },
    ],
  },
  {
    SEARCH_NAME: [
      { value: 'receiverName', label: '실수령자' },
      { value: 'customerName', label: '주문자' },
    ],
  },
  {
    SEARCH_DATE_PICKING: [
      {
        value: 'DesireDate',
        label: '희망일',
      },
      {
        value: 'DeliveryDate',
        label: '배송예정일',
      },
      {
        value: 'PickDate',
        label: '피킹완료일',
      },
    ],
  },
  {
    SEARCH_DATE_TAKEOUT: [
      {
        value: 'DeliveryDate',
        label: '배송예정일',
      },
      {
        value: 'ConfirmDate',
        label: '출고지시확정일',
      },
      {
        value: 'LoadDate',
        label: '출고완료일',
      },
    ],
  },
  {
    SEARCH_SEQ_TAKEOUT: [
      { value: 'orderSeq', label: '주문번호' },
      { value: 'invoiceSeq', label: '송장번호' },
    ],
  },
  {
    SEARCH_CENTER_TAKEOUT: [
      { value: 'centerCode', label: '출고창고' },
      { value: 'originalCenterCode', label: '출발창고' },
    ],
  },
  {
    SEARCH_MODEL_TAKEOUT: [
      { value: 'model', label: '제품' },
      { value: 'modelName', label: '제품명' },
      { value: 'barcode', label: '바코드' },
      { value: 'modelStockSeq', label: 'SKU' },
      { value: 'skuNumber', label: '외부SKU' },
    ],
  },
];

const Index = () => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(DELIVERY_WORKS_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            DELIVERY_PICKING: <Tab1 tabId={'DELIVERY_PICKING'} />,
            DELIVERY_TAKEOUT: <Tab2 tabId={'DELIVERY_TAKEOUT'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
