import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import useExcelHooks from 'hooks/excel/useExcelHooks';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
// service
import { orderService } from '../../_services/service';
import { RootState } from 'redux/store';
import { COMMON_GRIDID, setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PagingListDTO } from 'interface/util';
import { OrderExcelDTO, OrderListDTO, OrderListQuantityDTO, OrderSearchDTO } from '../_interface/order';
import useGridButton from 'hooks/grid/useGridButton';

import { COLS_TO_OBJ, DEFAULT_COLUMNS, PARCEL_OBJ, excelDetailColumns, ORDER_COUNTS, COLS_MODEL, COLS_PRODUCT, COLS_MODEL_FOROBJ } from '../_asset/asset';
import useSearchGridPagingNew from 'hooks/grid/useGridPagingNewNew';
// component
import { IInvoicePopupObj, InvoicePopup } from './component/invoicePopup';
import { IPrints, Prints } from './component/prints';
import { IOrderManObj, IPostObj, OrderCancel } from './component/orderCancel';
import { DetailPopup, IDetailObj } from 'pages/ETC/1driver/driverListTab/driverList/component/detailPopup';
import { DivideOrder, IDividerObj } from './component/divideOrder';
import { ISms, SmsLogPopup } from './component/smsLogPopup';
import { IInventoryLocationObj, InventoryLocation } from './component/inventoryLocation';
import AlertModal, { IAlertObj } from './component/alertModal';
import { selectvalue } from 'common/master/codeMasterReturnHelper';
import { defaultFixedColumnCountObjType, gridParamToGridColumn } from 'common/grid/columnManager';
import { IParcelDetail, ParcelDetail } from '../../parcelListTab/parcelList/component/parcelDetail';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { ILOCALE } from 'redux/services/localeSlice';
import { COLUMNS_LANG } from '../_asset/locale';
import { OrderDetail, IOrderDetailObj } from './component/invocieDetail/orderDetail';
import { IInventoryCheck, ProductInventoryCheck } from './component/productInventoryCheck';
import { HappyCallModal, IhappycallObj } from './component/invocieDetail/happycall';
import { ORDER_MODEL_UPLOAD, ORDER_PARCEL_UPLOAD, ORDER_PRODUCT_UPLOAD } from 'envVar2';
import { serviceStore } from 'services/services';
import { useNavigate } from 'react-router-dom';

const GRIDID = `${COMMON_GRIDID}MANAGEORDERINFOLIST1_MAIN`;
export const defaultOrderDetailObj = {
  visible: false,
  item: null,
};

const translatorExcelMain = {
  ORDER_TAB: 'v2/list/excel',
  ORDER_FINISHORCANCEL: 'invoice/finishOrCancel/excel',
  ORDER_PROGRESS: 'invoice/progress/excel',
};

const translatorExcelDetail = {
  ORDER_TAB: 'excel/list/detail',
  ORDER_FINISHORCANCEL: 'invoice/finishOrCancel/excel/detail',
  ORDER_PROGRESS: 'invoice/progress/excel/detail',
};

const translatorQuantity = {
  ORDER_TAB: 'v2/list/quantity',
  ORDER_PROGRESS: 'invoice/progress/quantity',
};

const translatorPaging = {
  ORDER_TAB: 'v2/list/paging',
  ORDER_FINISHORCANCEL: 'invoice/finishOrCancel/paging',
  ORDER_PROGRESS: 'invoice/progress/paging',
};

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const { locale } = useSelector((state: RootState) => state.locale);
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();

  const [detailObj, setDetailObj] = useState<IParcelDetail>();
  const [fetchCount, setFetchCount] = useState<OrderListQuantityDTO>();
  const [orderDetailObj, setOrderDetailObj] = useState<IOrderDetailObj>(); // js => tsx
  const [invoicePopupObj, setInvoicePopupObj] = useState<IInvoicePopupObj>();
  const [inventoryCheckObj, setInventoryCheckObj] = useState<IInventoryCheck>();
  const [printObj, setPrintObj] = useState<IPrints>();
  const [divideOrderObj, setDivideOrderObj] = useState<IDividerObj>();
  const [alertModalObj, setAlertModalObj] = useState<IAlertObj>();
  const [inventoryLocationObj, setInventoryLocationObj] = useState<IInventoryLocationObj>();
  const [driverDetailObj, setDriverDetailObj] = useState<IDetailObj>();
  const [smsObj, setSmsObj] = useState<ISms>();
  const [orderManObj, setOrderManObj] = useState<IOrderManObj>();
  const navigate = useNavigate();

  const fetchInvoiceData = async (e: IGrid.ButtonClickEvent) => {
    const item = e.item;
    if (item.statusKr === '접수점검' || item.statusKr === '접수대기') {
      alert('해당 상태에서는 배송/반품현황을 확인하실 수 없습니다.');
    } else {
      const data = await orderService.getTrackingData(e.item.invoiceSeq);
      if (data) {
        setInvoicePopupObj({
          visible: true,
          item: data,
        });
      }
    }
  };

  const fetchInventoryDetailList = async (e: IGrid.ButtonClickEvent) => {
    if (e.item) {
      dispatch(setLoading('GET'));
      setInventoryCheckObj({ visible: true, item: e.item, centerCodeKr: e?.item?.centerCodeKr });
      dispatch(setLoading(null));
    }
  };

  const fetchInventoryLocation = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const data = await orderService.getInvoiceInventoryLocation(e.item.invoiceSeq);
    if (data?.length > 0) {
      setInventoryLocationObj({
        visible: true,
        data,
      });
    }
    dispatch(setLoading(null));
  };

  const fetchSmsList = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const data = await orderService.getOrderSms(e.item.invoiceSeq);
    if (data) {
      setSmsObj({
        visible: true,
        data,
      });
    }
    dispatch(setLoading(null));
  };

  const popupDriverDetail = async (e) => {
    if (e.text) {
      setDriverDetailObj((prev) => {
        return {
          ...prev,
          visible: true,
          item: { userId: e.item?.[e.dataField?.split('Kr')[0]] },
        };
      });
    }
  };

  const labellingKr = (data: PagingListDTO<OrderListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),

        rescheduleTypeKr: MASTER_OBJ?.RESCHEDULE_TYPE?.[row.rescheduleType],
        centerCallNumKr: MASTER_OBJ?.CENTER_CALL_WHOLE?.[row.centerCode],
        memberCountKr: MASTER_OBJ?.TEAM_TYPE?.[row.memberCount],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        userIdKr: MASTER_OBJ?.DRIVER_WHOLE?.[row.userId],
        assistUserIdKr: MASTER_OBJ?.DRIVER_WHOLE?.[row.assistUserId],
        userIdPhoneKr: MASTER_OBJ?.DRIVER_PHONE_WHOLE?.[row.userId],
        orderTypeKr: MASTER_OBJ?.ORDER_TYPE?.[row.orderType],
        feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row.feeType],
        invoiceStatusKr: MASTER_OBJ?.INVOICE_STATUS?.[row.invoiceStatus],
        inventoryLocationKr: MASTER_OBJ?.INVENTORY_LOCATION?.[row.inventoryLocation],
        parcelPayTypeKr: MASTER_OBJ?.PARCEL_PAY_TYPE?.[row.parcelPayType],
        receiverAddr: `${row?.receiverAddr1?.replace('null', '') || ''} ${row?.receiverAddr2?.replace('null', '') || ''}`,
        customerAddr: `${row?.customerAddr1?.replace('null', '') || ''} ${row?.customerAddr2?.replace('null', '') || ''}`,
        overTimeRegitKr: row.overTimeRegit === 0 ? 'D-DAY' : `D+${row.overTimeRegit}`,
        salesChannelName: MASTER_OBJ?.SALES_CHANNEL?.[row.salesChannelSeq],
        liteFlagKr: MASTER_OBJ?.LITE_FLAG?.[row.liteFlag + ''],
        smsFlagKr: row.smsFlag ? 'O' : 'X',
        cancelChargeFlagKr: row.cancelChargeFlag ? 'O(고객부담)' : 'X(고객미부담)',
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchTotal = async (searchObj: OrderSearchDTO) => {
    if (tabId !== 'ORDER_FINISHORCANCEL') {
      const total = (await serviceStore.orderAction(translatorQuantity[tabId], 'POST', null, searchObj, false, true))?.data;
      setFetchCount(total);
      return total;
    }
  };

  const fetchPaging = async (searchObj: OrderSearchDTO) => {
    const dataKr = labellingKr((await serviceStore.orderAction(translatorPaging[tabId], 'POST', null, searchObj, true, true))?.data);
    return dataKr;
  };

  const downloadExcel = async (type: 'main' | 'detail') => {
    const prompt = window.confirm(`엑셀다운로드 ${type === 'main' ? '' : '*상세*'}를 시작합니다. \n※ 많은 양의 데이터는 30초 이상의 시간이 소요될 수 있습니다.`);
    if (prompt) {
      dispatch(setLoading('GET'));
      const { list } = labellingKr(
        { list: (await serviceStore.orderAction(type === 'main' ? translatorExcelMain[tabId] : translatorExcelDetail[tabId], 'POST', null, searchObj, false, true))?.data },
        true,
      );
      if (type === 'main') {
        excelGridRef.current.setGridData(list);
        excelGridRef.current.exportAsXlsx({ fileName: '주문조회' });
      } else {
        excelDetailGridRef.current.setGridData(list);
        excelDetailGridRef.current.exportAsXlsx({ fileName: '주문조회(상세)' });
      }
      dispatch(setLoading(null));
    }
  };

  const adminAssign = async (items) => {
    if (window.confirm('컷오프 승인을 진행하시겠습니까?')) {
      const rs = await orderService.postOrderAssignAdmin({ invoiceSeqs: items?.map((ele) => ele.invoiceSeq) });
      if (rs?.status === 200) {
        alert('컷오프 승인이 완료되었습니다.');
        wrappedFetchList(searchObj);
      }
    }
  };

  const createParcelInvoiceNumber = async (items) => {
    if (window.confirm('택배운송장을 생성 하시겠습니까?')) {
      dispatch(setLoading('GET'));
      const rs = await orderService.postParcelAction(
        'createParcelInvoiceNumber',
        items?.map((ele) => {
          return {
            seq: ele.invoiceSeq,
          };
        }),
      );
      if (rs?.status === 200) {
        alert('택배운송장이 생성되었습니다');
        wrappedFetchList(searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  const deleteParcelInvoiceNumber = async (items) => {
    if (window.confirm('택배운송장을 삭제 하시겠습니까?')) {
      dispatch(setLoading('GET'));
      const rs = await orderService.postParcelAction(
        'deleteParcelInvoiceNumber',
        items?.map((ele) => {
          return {
            seq: ele.invoiceSeq,
          };
        }),
      );
      if (rs?.status === 200) {
        alert('택배운송장이 삭제되었습니다');
        wrappedFetchList(searchObj);
      }
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id?.includes('ORDEREXCELDOWNMAIN')) {
      downloadExcel('main');
    } else if (id?.includes('ORDEREXCELDOWNDETAIL')) {
      downloadExcel('detail');
    } else if (id?.includes('ORDERFORMPRODUCT')) {
      window.open(ORDER_PRODUCT_UPLOAD);
    } else if (id?.includes('ORDERFORMMODEL')) {
      window.open(ORDER_MODEL_UPLOAD);
    } else if (id?.includes('ORDERFORMPARCEL')) {
      window.open(ORDER_PARCEL_UPLOAD);
    } else if (id?.includes('ORDEREXCELUPLOADDEFAULT') || id?.includes('ORDEREXCELUPLOADCUSTOM') || id?.includes('ORDEREXCELUPLOADPARCEL')) {
      uploaderClick(id);
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id?.includes('LADDERTRUCKPRINT')) {
        laddercarPrintAction(items);
      } else if (id?.includes('INVOICEPRINT')) {
        invoicePrintAction(items);
      } else if (id?.includes('ORDERCANCEL')) {
        popupOrderCancelModal(items);
      } else if (id?.includes('ORDERPEND')) {
        popupOrderPendModal(items);
      } else if (id?.includes('ORDERUNLINK')) {
        unAssignAction(items);
      } else if (id?.includes('ORDERDISTRIBUTE')) {
        divideOrderAction(items);
      } else if (id?.includes('ORDERRESTORE')) {
        finishToDeliveryAction(items);
      } else if (id?.includes('ORDERIMPORT')) {
        orderCallAction(items);
      } else if (id?.includes('NOTININVENT')) {
        notInInventory(items);
      } else if (id?.includes('PRINTSHEET')) {
        printSheet(items);
      } else if (id?.includes('ORDERASSIGNADMIN')) {
        adminAssign(items);
      } else if (id?.includes('PURCHASEREQUEST')) {
        useNewDesignFlag === 1
          ? navigate(`/main/manageorderInfoList/purchaseRegit?invoiceSeq=${items?.map((ele) => ele?.invoiceSeq)?.join(', ')}`)
          : window.open(`/purchaseRegit?invoiceSeq=${items?.map((ele) => ele?.invoiceSeq)?.join(',')}`);
      } else if (id?.includes('INSTALL_COMPLETE')) {
        if (items?.length > 1) {
          alert('배송완료는 한개씩 처리가능합니다!');
        } else {
          completeInstall(items[0]);
        }
      } else if (id?.includes('ORDERASSIGNSIMPLE')) {
        assignSimple(items);
      } else if (id?.includes('ORDERASSIGN')) {
        orderConfirmAction(items);
      } else if (id?.includes('RESEND_COMPLETE')) {
        resendComplete(items);
      } else if (id?.includes('ORDER_PARCEL_CREATE')) {
        createParcelInvoiceNumber(items);
      } else if (id?.includes('ORDER_PARCEL_DELETE')) {
        deleteParcelInvoiceNumber(items);
      }
    } else {
      alert(locale?.value === 'ko' ? '선택된 항목이 존재하지 않습니다.' : locale?.value === 'zh' ? '不存在的选项' : 'No item checked');
    }
  };
  const resendComplete = async (items) => {
    if (window.confirm('완료값 재전송을 진행하시겠습니까?')) {
      const rs = await orderService.postResendComplete(
        items.map((ele) => {
          return { seq: ele.invoiceSeq };
        }),
      );
      if (rs?.status === 200) {
        alert(rs?.message);
        wrappedFetchList(searchObj);
      }
    }
  };

  const assignSimple = async (items) => {
    if (window.confirm('재고주문승인을 진행하시겠습니까? (주문에 창고만 배정합니다)')) {
      const rs = await orderService.postOrderAssign({ invoiceSeqs: items?.map((ele) => ele.invoiceSeq) });
      if (rs?.status === 200) {
        alert('성공하였습니다!');
        wrappedFetchList(searchObj);
      }
    }
  };

  const printSheet = async (items) => {
    if (items?.length > 0) {
      setPrintObj({
        visible: true,
        items,
        type: 'sheet',
      });
    }
  };

  const validateForcompleteInstall = (item) => {
    if (!(item?.liteFlag || item?.feeType?.includes('PDELIV'))) {
      alert('라이트 유형 혹은 이형택배만 완료처리 가능합니다!');
      return false;
    }
    if (!item?.invoiceStatus?.includes('DELIVERY')) {
      alert('배송중 상태에서만 완료처리 가능합니다!');
      return false;
    }
    return true;
  };

  const completeInstall = async (item) => {
    if (validateForcompleteInstall(item)) {
      const rs = await orderService.postOrderInstallCompleteLite(item.invoiceSeq);
      if (rs?.status === 200) {
        alert('설치완료되었습니다!');
        wrappedFetchList(searchObj);
      }
    }
  };

  const notInInventory = async (items) => {
    if (window?.confirm('재고연동해제 하시겠습니까?')) {
      const data = {
        invoiceSeqs: items?.map((ele) => ele.invoiceSeq),
      };
      const rs = await orderService.postOrderNotInInventory(data);
      if (rs?.status === 200) {
        alert(rs?.data?.message);
        wrappedFetchList(searchObj);
      }
    }
  };

  const orderCallAction = (data) => {
    if (data.length === 1) {
      useNewDesignFlag === 1 ? navigate(`/main/manageorderInfoList/manageorderRegister?invoiceSeq=${data[0]?.invoiceSeq}`) : window.open(`/manageorderRegister?invoiceSeq=${data[0]?.invoiceSeq}`);
    } else {
      alert('주문은 1건씩만 불러올수 있습니다.');
    }
  };

  const laddercarPrintAction = (items = []) => {
    const filtered = items?.filter((c) => !c.deliveryDate);
    if (filtered.length > 0) {
      alert('지정일을 선택하지 않은 송장은 사다리차사용증을 출력할 수 없습니다. 지정일을 선택 후 다시 시도해주세요.');
    } else if (items.length > 50) {
      alert('50건 이하만 한꺼번에 프린트 할수 있습니다!');
    } else {
      setPrintObj({
        items,
        visible: true,
        type: 'ladders',
      });
    }
  };

  const invoicePrintAction = async (items = []) => {
    setPrintObj({
      items,
      visible: true,
      type: 'old',
    });
  };

  const orderCancelAction = async (data: IPostObj) => {
    if (window.confirm('"주문취소"를 진행 하시겠습니까? *취소시 복원이 불가합니다')) {
      dispatch(setLoading('POST'));
      const rs = await orderService.postOrderCancel({
        cancelType: data.cancelType,
        invoiceSeqs: data.invoiceSeqs,
      });
      if (rs?.status === 200) {
        alert('주문취소가 완료되었습니다.');
        wrappedFetchList(searchObj);
        setOrderManObj(null);
      }
      dispatch(setLoading(null));
    }
  };

  const popupOrderCancelModal = (items) => {
    setOrderManObj({
      type: 'CANCEL',
      items,
      visible: true,
      actionFn: (data) => orderCancelAction(data),
    });
  };

  const unAssignAction = async (items) => {
    let data = items.map(({ invoiceSeq }) => invoiceSeq);
    const confirm = window.confirm('"연동취소"를 진행 하시겠습니까? *취소시 복원이 불가합니다');
    if (confirm) {
      dispatch(setLoading('PUT'));
      const rs = await orderService.deleteUnassignAndDelete(data);
      if (rs?.status === 200) {
        alert(rs?.data?.message);
        wrappedFetchList(searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  const pendScheduleAction = async (data) => {
    if (window.confirm('선택된 주문을 일괄 보류하겠습니까?')) {
      dispatch(setLoading('POST'));
      const rs = await orderService.postOrderPendlist(data);
      if (rs?.status === 200) {
        alert('보류에 성공하였습니다!');
        setOrderManObj(null);
        wrappedFetchList(searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  const popupOrderPendModal = (items) => {
    setOrderManObj({
      type: 'PEND',
      items,
      visible: true,
      actionFn: (data) => pendScheduleAction(data),
    });
  };

  const finishToDeliveryAction = async (items = []) => {
    if (items.length > 1) {
      alert('주문 원복은 한건씩만 가능합니다!');
    } else {
      if (window.confirm('"주문원복"을 진행하시겠습니까?')) {
        dispatch(setLoading('PUT'));
        const rs = await orderService.postOrderRollback(items[0].invoiceSeq);
        if (rs.status === 200) {
          alert('주문 원복이 완료되었습니다.');
          wrappedFetchList(searchObj);
        }
        dispatch(setLoading(null));
      }
    }
  };

  const divideOrderAction = (items = []) => {
    if (items.some((item) => item.invoiceStatusKr === '배송중' || item.invoiceStatusKr === '배송완료')) {
      alert('송장상태가 배송중, 배송완료의 경우는 주문분배를 할 수 없습니다!');
    } else {
      setDivideOrderObj({
        ...divideOrderObj,
        visible: true,
        searchObj,
        fetchFn: (data) => wrappedFetchList(data),
        items: items?.map((item) => item.invoiceSeq),
      });
    }
  };

  const orderConfirmAction = async (items = []) => {
    dispatch(setLoading('PUT'));
    const data = {
      invoiceSeqs: items?.map((ele) => ele.invoiceSeq),
    };
    const rs = await orderService.postOrderConfirm(data);
    if (rs?.status === 200) {
      alert('주문에 재고와 기사가 배정되었습니다!');
      wrappedFetchList(searchObj);
    }
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks();

  useEffect(() => {
    if (parsedData) {
      if (parsedData?.data?.length > 0) {
        if (parsedData?.id?.includes('ORDEREXCELUPLOADPARCEL')) {
          excelSaveParcel({ dataArr: parsedData?.data });
        } else if (parsedData?.id?.includes('ORDEREXCELUPLOADDEFAULT')) {
          if (parsedData?.header?.includes('바코드(필)')) {
            excelSaveModel({ dataArr: parsedData?.data, master: COLS_MODEL });
          } else {
            excelSaveProduct({ dataArr: parsedData?.data, master: COLS_PRODUCT });
          }
        } else if (parsedData?.id?.includes('ORDEREXCELUPLOADCUSTOM')) {
          const data = {
            partnerSeq: MASTER_OBJ?.SELLER_WHOLE?.[parsedData?.data[0][0]?.trim()],
            excelType: parsedData?.header?.includes('바코드(필)') ? 'MODEL' : 'PRODUCT',
            items: parsedData?.data,
          };
          console.log(data);
          getPartnerExcelForm(data);
        }
      } else {
        alert('읽혀진 값이 없습니다');
      }
    }
  }, [parsedData]);

  const exceptKey = [
    //
    'orderExcelTemplateSeq',
    'customerOrderExcelTemplateSeq',
    'externalNumberFlag',
    'receiverFlag',
    'excelType',
    'partnerSeq',
  ];

  const getPartnerExcelForm = async ({ partnerSeq, excelType, items }) => {
    if (!partnerSeq) {
      alert('판매사 조회에 실패하였습니다!');
    } else {
      dispatch(setLoading('GET'));
      const rawFormData = (await orderService.getUploadFormList({ partnerSeq, excelType }))[0];
      dispatch(setLoading(null));
      if (rawFormData) {
        const keys = { ...rawFormData };
        exceptKey?.forEach((key) => {
          delete keys[key];
        });
        const formArr = Object.entries(keys)
          .sort((a, b) => (a[1] > b[1] ? 1 : -1))
          ?.map((ele) => ele[0]);
        formArr.unshift('partnerSeq');
        if (excelType === 'MODEL') {
          const MASTER = COLS_TO_OBJ(COLS_MODEL_FOROBJ);
          const master = formArr?.map((ele) => MASTER[ele])?.filter((ele) => ele);
          excelSaveModel({ dataArr: items, receiverFlag: rawFormData?.receiverFlag, externalNumberFlag: rawFormData?.externalNumberFlag, master });
        } else {
          const MASTER = COLS_TO_OBJ(COLS_PRODUCT);
          const master = formArr?.map((ele) => MASTER[ele])?.filter((ele) => ele);
          excelSaveProduct({ dataArr: items, receiverFlag: rawFormData?.receiverFlag, externalNumberFlag: rawFormData?.externalNumberFlag, master });
        }
      } else {
        alert('판매사로 등록된 양식을 찾을수 없습니다! 주문양식 관리 페이지를 확인해주세요!');
      }
    }
  };

  const saveExcelAPI = async (item: OrderExcelDTO) => {
    return await orderService.postOrderSaveExcel(item);
  };

  const excelSaveParcel = async ({ dataArr: items }) => {
    dispatch(setLoading('POST'));
    const keys: string[] = Object.values(PARCEL_OBJ);
    const dataDTO = [];
    items?.forEach((row) => {
      const obj = {};
      keys.forEach((key, i) => {
        obj[key] = i === 0 ? selectvalue(row[i]?.trim(), MASTER_OPS?.SELLER_WHOLE) : row[i]?.trim();
      });
      dataDTO.push(obj);
    });
    const rs = await orderService.putOrderParcelSaveExcel(dataDTO);
    if (rs?.status === 200) {
      alert('택배정보변경에 성공하였습니다');
      wrappedFetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  const makeExcelData = ({ dataArr, master, receiverFlag, externalNumberFlag }) => {
    const keys = master?.map((ele) => ele.value);
    const masterCode = master?.map((ele) => MASTER_OPS?.[ele.master]);
    const isNumber = master?.map((ele) => ele.isNumber);
    const dataDTO = [];
    dataArr?.forEach((row) => {
      const obj = {};
      keys?.forEach((key, i) => {
        if (key) obj[key] = masterCode[i] ? selectvalue(row[i]?.trim(), masterCode[i]) : isNumber[i] ? row[i]?.trim() && Number(row[i]?.replaceAll(',', '')) : row[i]?.trim() || null;
        if (key === 'orderDatetime') {
          obj[key] = returnDateyyyymmdd(row[i]?.trim()) + ' 00:00:00';
        } else if (key === 'promiseDeliveryDate') {
          obj[key] = row[i] ? returnDateyyyymmdd(row[i]?.trim()) : '';
        }
      });
      // not a number
      if (isNaN(obj?.['salesChannelSeq']) && !obj['salesChannelName']) {
        obj['salesChannelName'] = obj['salesChannelSeq'];
        delete obj['salesChannelSeq'];
      } else if (!isNaN(obj?.['salesChannelName']) && !obj['salesChannelSeq']) {
        obj['salesChannelSeq'] = obj['salesChannelName'];
        delete obj['salesChannelName'];
      }
      if (receiverFlag) {
        obj['receiverName'] = obj['customerName'];
        obj['receiverPhone'] = obj['customerPhone'];
        obj['receiverPhone2'] = obj['customerPhone2'];
        obj['receiverAddr'] = obj['customerAddr'];
        obj['receiverAddr1'] = obj['customerAddr1'];
        obj['receiverAddr2'] = obj['customerAddr2'];
        obj['receiverZipcode'] = obj['customerZipcode'];
      }
      if (externalNumberFlag) {
        obj['externalInvoiceNumber'] = obj['externalOrderNumber'];
      }
      dataDTO.push(obj);
    });
    return dataDTO;
  };

  const excelSaveProduct = async ({ dataArr, receiverFlag = false, externalNumberFlag = false, master }) => {
    dispatch(setLoading('GET'));
    const dataObjArr = makeExcelData({ dataArr, master, receiverFlag, externalNumberFlag });
    const dupExternalInvoiceNumber = [];
    let dupExternalInvoiceNumberFlag = false;
    dataObjArr?.forEach((ele) => {
      if (dupExternalInvoiceNumber?.includes(ele?.externalInvoiceNumber)) {
        dupExternalInvoiceNumberFlag = true;
        return;
      } else {
        dupExternalInvoiceNumber?.push(ele?.externalInvoiceNumber);
      }
    });

    if (!dupExternalInvoiceNumberFlag) {
      const res: any[] = await Promise.all(dataObjArr.map(async (item) => saveExcelAPI(item)));
      const status = res.map((rs) => rs.data);
      let msg = `파일업로드 완료!\n(성공: ${status.filter((ele) => ele?.result === true).length}건 / ${status.length}건, 실패: ${status.filter((ele) => ele.result === false).length}건 / ${
        status.length
      }건)\n\n${status.filter((ele) => ele.result === false).length > 0 ? '실패건을 확인해주세요!\n\n' : ''}`;
      status.forEach((ele, idx) => {
        msg += `${idx + 1}행) ${res[idx].data?.detailMessage || res[idx].data?.message || '성공'}\n`;
      });

      setAlertModalObj({
        visible: true,
        children: msg,
      });
    } else {
      alert('중복된 외부송장번호가 존재합니다. 상품마다 다른 외부송장번호를 입력하거나 같은 송장번호로 등록하고싶은 경우 제품으로 등록해주세요.');
    }
    dispatch(setLoading(null));
  };

  const modeledArr = (dataArr) => {
    const data = [];
    dataArr?.forEach((row, i) => {
      if (i === 0) {
        data.push({
          ...row,
          models: [
            {
              sellingPrice: row?.sellingPrice,
              model: row?.model,
              barcode: row?.barcode,
              productModelType: row?.productModelType,
              quantity: row?.quantity,
            },
          ],
        });
      } else {
        if (dataArr[i - 1]?.externalInvoiceNumber === row?.externalInvoiceNumber) {
          data?.at(-1)?.models?.push({
            sellingPrice: row?.sellingPrice,
            model: row?.model,
            barcode: row?.barcode,
            productModelType: row?.productModelType,
            quantity: row?.quantity,
          });
        } else {
          data.push({
            ...row,
            models: [
              {
                sellingPrice: row?.sellingPrice,
                model: row?.model,
                barcode: row?.barcode,
                productModelType: row?.productModelType,
                quantity: row?.quantity,
              },
            ],
          });
        }
      }
    });
    return data;
  };
  const [happycallObj, setHappycallObj] = useState<IhappycallObj>();
  const popupHappycall = (e: IGrid.ButtonClickEvent) => {
    setHappycallObj({
      visible: true,
      invoiceDetail: e.item,
    });
  };

  const excelSaveModel = async ({ dataArr, receiverFlag = false, externalNumberFlag = false, master }) => {
    dispatch(setLoading('GET'));
    const dataObjArr = makeExcelData({ dataArr, master, receiverFlag, externalNumberFlag });
    const modeled = modeledArr(dataObjArr);
    const res: any[] = await Promise.all(modeled.map(async (item) => saveExcelAPI(item)));
    const status = res.map((rs) => rs.data);
    let msg = `파일업로드 완료!\n(성공: ${status.filter((ele) => ele?.result === true).length}건 / ${status.length}건, 실패: ${status.filter((ele) => ele.result === false).length}건 / ${
      status.length
    }건)\n\n${status.filter((ele) => ele.result === false).length > 0 ? '실패건을 확인해주세요!\n\n' : ''}`;
    status.forEach((ele, idx) => {
      msg += `${idx + 1}행) ${res[idx].data?.detailMessage || res[idx].data?.message || '성공'}\n`;
    });

    setAlertModalObj({
      visible: true,
      children: msg,
    });
    dispatch(setLoading(null));
  };

  const visibleOrderDetail = (e: IGrid.ButtonClickEvent) => {
    setOrderDetailObj({
      visible: true,
      item: e.item,
      searchObj: searchObj,
      fetchFn: (data) => wrappedFetchList(data),
      fetchDetailFn: (e: IGrid.ButtonClickEvent) => visibleOrderDetail(e),
    });
  };

  const visibleParcelDetail = (e: IGrid.ButtonClickEvent) => {
    setDetailObj({
      visible: true,
      item: e.item,
    });
  };

  const mappingFunction = {
    showParcelDetail: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: locale.value === 'zh' ? '视' : locale.value === 'en' ? 'Detail' : '상세보기',
        onClick: visibleParcelDetail,
      },
    },
    showOrderDetail: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: locale.value === 'zh' ? '视' : locale.value === 'en' ? 'Detail' : '상세보기',
        onClick: visibleOrderDetail,
      },
    },
    showTracking: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: locale.value === 'zh' ? '视' : locale.value === 'en' ? 'Detail' : '보기',
        onClick: fetchInvoiceData,
      },
    },
    predictDeliveryDate: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: fetchInventoryDetailList,
      },
    },
    overTimeRegitKr: {
      type: 'styleFunction',
      mapValue: function (rowIndex, columnIndex, value, headerText, item, dataField) {
        if (item?.overTimeRegit < 3) {
          return '';
        } else {
          return 'warning-column';
        }
      },
    },
    invoiceStatusKr: {
      type: 'styleFunction',
      mapValue: function (rowIndex, columnIndex, value, headerText, item, dataField) {
        const invStat = item.invoiceStatus;
        if (invStat === 'INIT') {
          return 'status-badge-yellow';
        } else if (
          invStat === 'NOT_IN_INVENTORY' ||
          invStat === 'DRIVER_CAPA_EXCEED' ||
          invStat === 'EXCLUDE_ZIPCODE' ||
          invStat === 'ZIPCODE_ERROR' ||
          invStat === 'ADDRESS_ERROR' ||
          invStat === 'NOT_EXIST_CENTER' ||
          invStat === 'NOT_EXIST_DRIVER' ||
          invStat === 'DRIVER_SETTING_ERROR' ||
          invStat === 'CENTER_CUTOFF_CLOSE'
        ) {
          return 'status-badge-orange';
        } else if (
          //
          invStat === 'ASSIGN' ||
          invStat === 'ASSIGN_VIRT' ||
          invStat === 'REMOVING' ||
          invStat === 'CONFIRM' ||
          invStat === 'LOAN' ||
          invStat === 'DELIVERY'
        ) {
          return 'status-badge-green';
        } else if (invStat.startsWith('FINISH_')) {
          return 'status-badge-blue';
        } else if (invStat.startsWith('CANCEL_')) {
          return 'status-badge-red';
        } else if (invStat.startsWith('PEND')) {
          return 'status-badge-gray';
        } else if (invStat === 'ASSIGNING') {
          return 'status-badge-purple';
        }
        return null;
      },
    },
    inventoryLocationKr: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: fetchInventoryLocation,
      },
    },
    productName: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: fetchInventoryDetailList,
      },
      width: 350,
    },
    happyCallCount: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: popupHappycall,
      },
    },
    userIdKr: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: popupDriverDetail,
      },
    },
    smsCount: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: fetchSmsList,
      },
    },
    assistUserIdKr: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: popupDriverDetail,
      },
    },
    mondayFlag: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
      width: 40,
    },
    tuesdayFlag: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
      width: 40,
    },
    wednesdayFlag: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
      width: 40,
    },
    thursdayFlag: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
      width: 40,
    },
    fridayFlag: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
      width: 40,
    },
    saturdayFlag: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
      width: 40,
    },
    sundayFlag: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
      width: 40,
    },
  };
  const initFlags = () => {
    const initSearchObj = {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
      pageNo: -1,
      pageSize: MASTER_OBJ?.[GRIDID]?.pageSize || 100,
    };
    ORDER_COUNTS?.[tabId]?.forEach((flag) => {
      initSearchObj[flag] = 'true';
    });
    return initSearchObj;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPagingNew({
    initialSearchObj: initFlags(),
    gridRef,
    fetchPaging,
  });

  const wrappedLocale = (columns: IGrid.Column[], locale: ILOCALE) => {
    if (locale === 'ko') return columns;
    return columns?.map((ele) => {
      return {
        ...ele,
        headerText: COLUMNS_LANG?.[ele.dataField]?.[locale],
        children: ele.children?.map((child) => {
          return {
            ...child,
            headerText: COLUMNS_LANG[ele.dataField] ? COLUMNS_LANG[ele.dataField][locale] : ele.headerText,
          };
        }),
      };
    });
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);
  const [columns, _] = useState<IGrid.Column[]>(wrappedLocale(gridParamToGridColumn(MASTER_OBJ?.[GRIDID] ? MASTER_OBJ?.[GRIDID]?.userGridParameters : DEFAULT_COLUMNS, mappingFunction), locale.value));

  const defaultFixedColumnCountObj: defaultFixedColumnCountObjType = {
    MANAGEORDERINFOLIST1_MAIN: 3,
  };

  const useNewDesignFlag = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  useEffect(() => {
    if (useNewDesignFlag === 1) {
      setFetchCount(null);

      gridRef?.current?.changeColumnLayout(wrappedLocale(gridParamToGridColumn(MASTER_OBJ?.[GRIDID] ? MASTER_OBJ?.[GRIDID]?.userGridParameters : DEFAULT_COLUMNS, mappingFunction), locale.value));
      gridRef?.current?.clearGridData();
      gridRef?.current?.setFixedColumnCount(MASTER_OBJ?.[GRIDID]?.fixedColumn ?? (defaultFixedColumnCountObj[tabId] || 2));
    }
  }, [tabId]);

  return (
    <div className="page-content">
      {happycallObj?.visible && <HappyCallModal happycallObj={happycallObj} setHappycallObj={setHappycallObj} />}
      {detailObj?.visible && <ParcelDetail detailObj={detailObj} setDetailObj={setDetailObj} />}
      {printObj?.visible && <Prints printObj={printObj} setPrintObj={setPrintObj} />}
      {invoicePopupObj?.visible && <InvoicePopup invoicePopupObj={invoicePopupObj} setInvoicePopupObj={setInvoicePopupObj} />}
      {orderDetailObj?.visible && <OrderDetail orderDetailObj={orderDetailObj} setOrderDetailObj={setOrderDetailObj} />}
      {inventoryCheckObj?.visible && <ProductInventoryCheck inventoryCheckObj={inventoryCheckObj} setInventoryCheckObj={setInventoryCheckObj} />}
      {driverDetailObj?.visible && <DetailPopup detailObj={driverDetailObj} setDetailObj={setDriverDetailObj} />}
      {divideOrderObj?.visible && <DivideOrder divideOrderObj={divideOrderObj} setDivideOrderObj={setDivideOrderObj} />}
      {smsObj?.visible && <SmsLogPopup smsObj={smsObj} setSmsObj={setSmsObj} />}
      {orderManObj?.visible && <OrderCancel orderManObj={orderManObj} setOrderManObj={setOrderManObj} />}
      {alertModalObj?.visible && <AlertModal alertModalObj={alertModalObj} setAlertModalObj={setAlertModalObj} />}
      {inventoryLocationObj?.visible && <InventoryLocation inventoryLocationObj={inventoryLocationObj} setInventoryLocationObj={setInventoryLocationObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} fetchCount={fetchCount} TARGET={tabId} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}

        <GridBox
          gridId={GRIDID}
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: MASTER_OBJ?.[GRIDID]?.fixedColumn || 2,
            pageRowCount: MASTER_OBJ?.[GRIDID]?.pageSize || GRID_PAGE_ROW_COUNT,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
          defaultFixedColumnCountObj={defaultFixedColumnCountObj}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={excelDetailColumns} />
      </div>
    </div>
  );
};

export default Index;
