import CommonModalNew from 'components/modal/commonModalNew';
import { InvoiceDetailSSDTO, OrderSearchDTO, OrderSSListDTO } from 'pages/ORDER/2ss/_interface/order';
import * as IGrid from 'aui-grid';
import { Button } from 'reactstrap';
import { useEffect, useRef, useState } from 'react';
import { serviceStore } from 'services/services';
import { IModalObj } from './modal';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import GridBox from 'common/grid/gridBox';
import AUIGrid from 'modules/Grid';
import { columns } from './_asset/asset';
import { GetVendor, IGetVendorObj } from './getVendor';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
export interface IDetailObj {
  visible: boolean;
  item: OrderSSListDTO;
  searchObj?: OrderSearchDTO;
  fetchFn?: (searchObj: OrderSearchDTO) => void;
  fetchDetailFn?: (e: IGrid.ButtonClickEvent) => void;
}

export const DetailPopup = ({ detailObj, setDetailObj }) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [detailData, setDetailData] = useState<InvoiceDetailSSDTO & { externalPartnerCode: string }>();
  const [modalObj, setModalObj] = useState<IModalObj>();
  const [modiFlag, setModiFlag] = useState<boolean>(false);
  const gridRef = useRef<AUIGrid>();

  useEffect(() => {
    fetchDetail(detailObj?.item?.invoiceSeq);
  }, []);

  const fetchDetail = async (invoiceSeq: number) => {
    const rs = await serviceStore?.orderAction(`v2/invoice/detail/ss`, 'GET', { invoiceSeq });
    if (rs?.status === 200) {
      const rows = rs?.data?.invoiceInventories?.map((row) => {
        return {
          ...row,
          centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        };
      });
      console.log(rows);
      gridRef?.current?.setGridData(rows);
      setDetailData(rs?.data);
    }
  };
  const [getVendorObj, setGetVendorObj] = useState<IGetVendorObj>();
  useEffect(() => {
    if (getVendorObj?.returns) {
      alert(`거래처 정보를 불러왔습니다!`);
      setDetailData((prev) => {
        return {
          ...prev,
          vendorFlag: true,
          discountRate: getVendorObj?.returns?.[0]?.discountRate,
          customerName: getVendorObj?.returns?.[0]?.partnerName,
          customerPhone: getVendorObj?.returns?.[0]?.managerPhone,
          customerZipcode: getVendorObj?.returns?.[0]?.partnerName,
          customerAddr1: getVendorObj?.returns?.[0]?.address,
          customerAddr2: getVendorObj?.returns?.[0]?.addressDetail,
          externalPartnerCode: getVendorObj?.returns?.[0]?.externalPartnerCode,
        };
      });
      setGetVendorObj(null);
    }
  }, [getVendorObj?.returns]);

  const saveDetail = async () => {
    console.log(modiFlag);
    // const rs = await serviceStore.orderAction()
  };

  const handleDaumPost = (type) => {
    new (window as any).daum.Postcode({
      oncomplete: function (data) {
        const { zonecode, roadAddress, jibunAddress } = data;

        setDetailData({
          ...detailData,
          receiverZipcode: zonecode,
          receiverAddr1: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
        });
      },
    }).open();
  };

  return (
    <CommonModalNew
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div className="inputSmaillTitle mb-2" style={{ marginTop: 0 }}>
            <span style={{ fontSize: 16, fontWeight: 500 }}>주문정보</span>
            <span style={{ fontSize: 13, margin: '0 10px' }}>주문번호 : {detailData?.orderSeq}</span>
          </div>
          <div>
            <Button
              className="btn-reload btn"
              onClick={() => {
                setModalObj({
                  type: 'history',
                  visible: true,
                  item: detailObj?.item,
                });
              }}
            >
              변경이력조회
            </Button>
            <Button
              className="btn-reload btn"
              onClick={() => {
                setModalObj({
                  type: 'cs',
                  visible: true,
                  item: detailObj?.item,
                });
              }}
            >
              고객 상담
            </Button>
            <Button
              className="btn-reload btn"
              onClick={() => {
                setModalObj({
                  type: 'cs',
                  visible: true,
                  item: detailObj?.item,
                });
              }}
            >
              타임스탬프
            </Button>
            <Button
              className={'btn btn-secondary'}
              onClick={(e) => {
                e.preventDefault();
                if (modiFlag) {
                  saveDetail();
                } else {
                  setModiFlag(true);
                }
              }}
            >
              {!modiFlag ? '수정하기' : '저장하기'}
            </Button>
          </div>
        </div>
      }
      children={
        <>
          <div className={modiFlag ? '' : 'disabled'}>
            {getVendorObj?.visible && <GetVendor getVendorObj={getVendorObj} setGetVendorObj={setGetVendorObj} />}
            <table className="border-table">
              <tbody>
                <tr>
                  <th>*송장번호</th>
                  <td colSpan={2}>
                    <InputD readOnly value={detailData?.invoiceSeq || ''} disabled />
                  </td>
                  <th>주문일</th>
                  <td colSpan={2}>
                    <FlatpickrD
                      value={detailData?.orderDate}
                      onReset={() => {
                        setDetailData({
                          ...detailData,
                          orderDate: null,
                        });
                      }}
                      onChange={(date) => {
                        setDetailData({
                          ...detailData,
                          orderDate: returnDateyyyymmdd(date),
                        });
                      }}
                      options={{
                        mode: 'single',
                      }}
                    />
                  </td>
                  <th>희망일</th>
                  <td colSpan={2} style={{ position: 'relative' }}>
                    <FlatpickrD
                      value={detailData?.promiseDate}
                      onChange={(date) => {
                        setDetailData({
                          ...detailData,
                          promiseDate: returnDateyyyymmdd(date),
                        });
                      }}
                      onReset={() => {
                        setDetailData({
                          ...detailData,
                          promiseDate: null,
                        });
                      }}
                      options={{
                        mode: 'single',
                      }}
                    />
                  </td>
                  <th>
                    배송희망
                    <br />
                    시간대
                  </th>
                  <td colSpan={2}>
                    <SelectD
                      options={MASTER_OPS?.PROMISE_TIME_ZONE_1}
                      value={
                        detailData?.promiseTimeZone
                          ? {
                              value: detailData?.promiseTimeZone,
                              label: MASTER_OBJ?.PROMISE_TIME_ZONE_1?.[detailData?.promiseTimeZone],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailData({
                          ...detailData,
                          promiseTimeZone: (option as OptionItem).value,
                        });
                      }}
                    />
                  </td>
                  <td colSpan={3} />
                </tr>
                <tr>
                  <th>*주문자명</th>
                  <td colSpan={2}>
                    <InputD
                      value={detailData?.customerName || ''}
                      onChange={(e) => {
                        setDetailData({
                          ...detailData,
                          customerName: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <th>*연락처1</th>
                  <td colSpan={2}>
                    <InputD
                      value={detailData?.customerPhone || ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                          let check = /^[0-9\-\b]+$/;
                          if (!check.test(value)) {
                            alert('숫자와 "-"만 입력 가능합니다.');
                          } else {
                            setDetailData({
                              ...detailData,
                              customerPhone: value,
                            });
                          }
                        } else {
                          setDetailData({
                            ...detailData,
                            customerPhone: value,
                          });
                        }
                      }}
                    />
                  </td>
                  <th>연락처2</th>
                  <td colSpan={2}>
                    <InputD
                      value={detailData?.customerPhone2 || ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                          let check = /^[0-9\-\b]+$/;
                          if (!check.test(value)) {
                            alert('숫자와 "-"만 입력 가능합니다.');
                          } else {
                            setDetailData({
                              ...detailData,
                              customerPhone2: value,
                            });
                          }
                        } else {
                          setDetailData({
                            ...detailData,
                            customerPhone2: value,
                          });
                        }
                      }}
                    />
                  </td>
                  <th>할인율(%)</th>
                  <td colSpan={2}>
                    <InputD
                      type="number"
                      value={detailData?.discountRate || ''}
                      onChange={(e) => {
                        setDetailData({
                          ...detailData,
                          discountRate: Number(e.target.value),
                        });
                      }}
                    />
                  </td>
                  <th>거래처코드</th>
                  <td colSpan={2}>
                    <InputD
                      readOnly
                      className="read-only-should-click"
                      value={detailData?.externalPartnerCode || ''}
                      onClick={() => {
                        setGetVendorObj({
                          visible: true,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>*수령자명</th>
                  <td colSpan={2}>
                    <InputD
                      value={detailData?.receiverName || ''}
                      onChange={(e) => {
                        setDetailData({
                          ...detailData,
                          receiverName: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <th>*연락처1</th>
                  <td colSpan={2}>
                    <InputD
                      value={detailData?.receiverPhone || ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                          let check = /^[0-9\-\b]+$/;
                          if (!check.test(value)) {
                            alert('숫자와 "-"만 입력 가능합니다.');
                          } else {
                            setDetailData({
                              ...detailData,
                              receiverPhone: value,
                            });
                          }
                        } else {
                          setDetailData({
                            ...detailData,
                            receiverPhone: value,
                          });
                        }
                      }}
                    />
                  </td>
                  <th>연락처2</th>
                  <td colSpan={2}>
                    <InputD
                      value={detailData?.receiverPhone2 || ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                          let check = /^[0-9\-\b]+$/;
                          if (!check.test(value)) {
                            alert('숫자와 "-"만 입력 가능합니다.');
                          } else {
                            setDetailData({
                              ...detailData,
                              receiverPhone2: value,
                            });
                          }
                        } else {
                          setDetailData({
                            ...detailData,
                            receiverPhone2: value,
                          });
                        }
                      }}
                    />
                  </td>
                  <th>*판매사</th>
                  <td colSpan={2}>
                    <InputD disabled readOnly value={MASTER_OBJ?.SELLER_WHOLE?.[detailData?.partnerSeq] || ''} />
                  </td>
                  <th>*인프라</th>
                  <td colSpan={2}>
                    <SelectD
                      value={
                        detailData?.infraSeq
                          ? {
                              value: detailData?.infraSeq,
                              label: MASTER_OBJ?.INFRA?.[detailData?.infraSeq],
                            }
                          : null
                      }
                      options={MASTER_OPS?.INFRA}
                      onChange={(option) => {
                        setDetailData({
                          ...detailData,
                          infraSeq: (option as OptionItem).value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>*우편번호</th>
                  <td colSpan={2}>
                    <InputD readOnly className="read-only-should-click" value={detailData?.receiverZipcode || ''} onClick={handleDaumPost} />
                  </td>
                  <th>*주소</th>
                  <td colSpan={5}>
                    <InputD
                      placeholder="우편번호를 클릭하세요"
                      disabled
                      value={detailData?.receiverAddr1?.replace('null', '') || ''}
                      onClick={handleDaumPost}
                      onChange={(e) => {
                        setDetailData({
                          ...detailData,
                          receiverAddr1: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <th>상세주소</th>
                  <td colSpan={5}>
                    <InputD
                      value={detailData?.receiverAddr2?.replace('null', '') || ''}
                      onChange={(e) => {
                        setDetailData({
                          ...detailData,
                          receiverAddr2: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
            <table className="border-table">
              <tbody>
                <tr>
                  <th>
                    고객
                    <br />
                    요청사항
                  </th>
                  <td colSpan={5}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <textarea
                        value={detailData?.shipmentNote || ''}
                        onChange={(e) => {
                          setDetailData({
                            ...detailData,
                            shipmentNote: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </td>
                  <th>
                    콜센터 <br />
                    요청사항
                  </th>
                  <td colSpan={5}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <textarea
                      // value={detailData?.note || ''}
                      // onChange={(e) => {
                      //   setDetailData({
                      //     ...detailData,
                      //     callCenterNote: e.target.value,
                      //   });
                      // }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div style={{ fontSize: 16, fontWeight: 500 }} className="inputSmaillTitle mb-2">
              기사정보
            </div>
            <table className="border-table">
              <tbody className="body-style">
                <tr>
                  <th>운송기사 이름</th>
                  <td colSpan={2}>
                    <InputD disabled readOnly value={detailData?.lhUserName || ''} />
                  </td>
                  <th>운송기사 연락처</th>
                  <td colSpan={2}>
                    <InputD disabled readOnly value={detailData?.lhUserPhone || ''} />
                  </td>
                  <th>
                    설치기사
                    <br /> 이름
                  </th>
                  <td colSpan={2}>
                    <InputD
                      value={detailData?.installerName || ''}
                      onChange={(e) => {
                        setDetailData({
                          ...detailData,
                          installerName: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <th>
                    설치기사
                    <br />
                    생년월일
                  </th>
                  <td colSpan={2}>
                    <FlatpickrD
                      value={detailData?.installerBirthDate}
                      onReset={() => {
                        setDetailData({
                          ...detailData,
                          installerBirthDate: null,
                        });
                      }}
                      onChange={(date) => {
                        setDetailData({
                          ...detailData,
                          installerBirthDate: returnDateyyyymmdd(date),
                        });
                      }}
                      options={{
                        mode: 'single',
                      }}
                    />
                  </td>
                  <td colSpan={3} />
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div style={{ fontSize: 16, fontWeight: 500 }} className="inputSmaillTitle mb-2">
              송장정보
            </div>
            <table className="border-table">
              <tbody>
                <tr>
                  <th>*송장상태</th>
                  <td colSpan={2}>
                    <InputD readOnly value={detailData?.invoiceStatus ? MASTER_OBJ?.INVOICE_STATUS?.[detailData?.invoiceStatus] : ''} disabled />
                  </td>
                  <th>배송예정일</th>
                  <td colSpan={2}>
                    <FlatpickrD
                      value={detailData?.deliveryDate}
                      onReset={() => {
                        setDetailData({
                          ...detailData,
                          deliveryDate: null,
                        });
                      }}
                      onChange={(date) => {
                        setDetailData({
                          ...detailData,
                          deliveryDate: returnDateyyyymmdd(date),
                        });
                      }}
                      options={{
                        mode: 'single',
                      }}
                    />
                  </td>
                  <td colSpan={9} />
                </tr>
                <tr>
                  <th>
                    완료일/
                    <br />
                    취소일
                  </th>
                  <td colSpan={2}>
                    <FlatpickrD
                      disabled
                      // value={detailData?.finishOrCancelDate || ''}
                      // onChange={(date) => {
                      //   setDetailData({
                      //     ...detailData,
                      //     finishOrCancelDate: returnDateyyyymmdd(date),
                      //   });
                      // }}
                      // onReset={() => {
                      //   setDetailData({
                      //     ...detailData,
                      //     finishOrCancelDate: null,
                      //   });
                      // }}
                      // options={{
                      //   mode: 'single',
                      // }}
                    />
                  </td>
                  <th>
                    완료일/취소일
                    <br />
                    변경자
                  </th>
                  <td colSpan={2}>
                    <InputD readOnly value={detailData?.finishOrCancelUpdateUserId || ''} disabled />
                  </td>
                  <th>
                    완료일/취소일
                    <br />
                    변경사유
                  </th>
                  <td colSpan={2}>
                    <InputD
                      onChange={(e) => {
                        setDetailData({
                          ...detailData,
                          finishOrCancelUpdateReason: e.target.value,
                        });
                      }}
                      value={detailData?.finishOrCancelUpdateReason || ''}
                    />
                  </td>
                  <th>취소사유</th>
                  <td colSpan={2}>
                    <InputD readOnly value={detailData?.cancelReason ? MASTER_OBJ?.CANCEL_REASON?.[detailData?.cancelReason] : ''} disabled />
                  </td>
                  <td colSpan={3} />
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div style={{ fontSize: 16, fontWeight: 500 }} className="inputSmaillTitle mb-2">
              <span>제품정보</span>
            </div>
            <GridBox
              gridRef={gridRef}
              columns={columns}
              gridProps={{
                height: 300,
                showRowAllCheckBox: false,
                showRowCheckColumn: false,
              }}
            />
          </div>
        </>
      }
    />
  );
};
