import { useState, useEffect } from 'react';
import { DetailTopTab, IDetailTopTab } from 'components/template/detailTopTab';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './takeoutRegit';
// options
import { nullingMASTER } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';

export const TAKEOUT_REGIT_MASTER = [
  'SELLER_SELLER_AUTH',
  'SELLER_SELLER_WHOLE',
  //
  'SELLER_SUPPLIER_AUTH',
  'SELLER_SUPPLIER_WHOLE',
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  //
  'MODEL_GROUP',
  'MODEL_TYPE',
  'REMOVE_REASON',
  'REMOVE_STATUS',
  'INVA_TYPE',
  'FEE_TYPE',
  //
  'SEARCH_MODEL',
  {
    SEARCH_SEQ: [
      {
        label: '주문번호',
        value: 'orderSeq',
      },
      {
        label: '외부주문번호',
        value: 'externalOrderNumber',
      },
      {
        label: '송장번호',
        value: 'invoiceSeq',
      },
      {
        label: '외부송장번호',
        value: 'externalInvoiceNumber',
      },
      {
        label: '고객명',
        value: 'customerName',
      },
    ],
  },
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [tabObj, setTabObj] = useState<IDetailTopTab>();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(TAKEOUT_REGIT_MASTER);
  };

  useEffect(() => {
    if (MASTER_OBJ) {
      setTabObj({
        titles: ['반출피킹지시', '반출피킹 생성new'],
        routers: ['/takeoutWorks', '/takeoutRegit'],
        container: [<Tab1 />],
      });
    }
  }, [MASTER_OBJ]);

  return <>{tabObj && <DetailTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
