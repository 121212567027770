import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, OptionItem, SelectD } from 'components/reactstrap/reactstrap';

import { KeyboardEvent, useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { resetAllSelections, updateSelectTypeInSearchObj } from 'common/util/searchBox';
import { InventoryInoutSearchDTO } from 'interface/warehouse';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { setSearchObj } from 'redux/services/tabSlice';

export const SearchBox = (props: ISearchBoxProps<InventoryInoutSearchDTO>) => {
  const { handleSearchObj, fetchList, TARGET } = props;
  const dispatch = useDispatch();
  const searchObj = useSelector((state: RootState) => state.tab)?.searchObj?.[TARGET];
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_MODEL: searchObj?.['SEARCH_MODEL'] ?? null,
  });

  useEffect(() => {
    localStorage.setItem(`searchObj`, JSON.stringify({ [TARGET]: searchObj }));
  }, [searchObj]);

  useEffect(() => {
    dispatch(setSearchObj({ tabId: TARGET, searchObj: updateSelectTypeInSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj), selectTypeObj) }));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    fetchList(searchObj);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <label className="col-form-label">일자</label>
          <FlatpickrD
            value={[searchObj?.fromInOutDate ?? '', searchObj?.toInOutDate ?? '']}
            onChange={(date) => {
              // setSearchObj({
              //   ...searchObj,
              //   fromInOutDate: returnDateyyyymmdd(date[0]),
              //   toInOutDate: returnDateyyyymmdd(date[1]),
              // });
              dispatch(
                setSearchObj({
                  tabId: TARGET,
                  searchObj: {
                    ...searchObj,
                    [`fromInOutDate`]: returnDateyyyymmdd(date[0]),
                    [`toInOutDate`]: returnDateyyyymmdd(date[1]),
                  },
                }),
              );
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            isDisabled={reduxUserInfo?.roles === 'ONLYPARTNER' || reduxUserInfo?.roles === 'CENTER_RDC'}
            options={masterOptions?.CENTER_AUTH}
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER_AUTH),
                  }
                : null
            }
            onChange={(option) => {
              handleSearchObj('centerCode', (option as OptionItem).value);
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품그룹</label>
          <SelectD
            options={masterOptions?.MODEL_GROUP}
            value={
              searchObj?.modelGroup
                ? {
                    value: searchObj?.modelGroup,
                    label: selectlabel(searchObj?.modelGroup, masterOptions?.MODEL_GROUP),
                  }
                : null
            }
            onChange={(option) => {
              handleSearchObj('modelGroup', (option as OptionItem).value);
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품타입</label>
          <SelectD
            options={masterOptions?.MODEL_TYPE}
            value={
              searchObj?.modelType
                ? {
                    value: searchObj?.modelType,
                    label: selectlabel(searchObj?.modelType, masterOptions?.MODEL_TYPE),
                  }
                : null
            }
            onChange={(option) => {
              handleSearchObj('modelType', (option as OptionItem).value);
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            options={masterOptions?.SELLER_SELLER_AUTH}
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER_WHOLE),
                  }
                : null
            }
            onChange={(option) => {
              handleSearchObj('partnerSeq', (option as OptionItem).value);
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품/제품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: selectlabel(selectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                      }
                    : null
                }
                options={masterOptions?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  handleSearchObj(selectTypeObj?.SEARCH_MODEL, e.target.value);
                }}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">유사검색여부</label>
          <div className="radio-select-area">
            <span
              className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
              onClick={() => {
                handleSearchObj('similaritySearchFlag', true);
              }}
            >
              유사검색
            </span>
            <span
              className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
              onClick={() => {
                handleSearchObj('similaritySearchFlag', false);
              }}
            >
              일치검색
            </span>
          </div>
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <button className="btn-search btn" type="submit">
            조회
          </button>
        </Col>
        <Col />
        <Col />
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
