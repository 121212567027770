import { FormEvent } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { ParcelInvoicePriceSearchDTO } from '../../../../_interface/parcel';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = (props: ISearchBoxProps<ParcelInvoicePriceSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  // NOTE: 버튼 키 다운 이벤트, 클릭 이벤트를 submit 이벤트에 묶어 하나로 처리
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    fetchList(searchObj);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <label className="col-form-label">기간검색</label>
            <FlatpickrD
              onChange={(date) => {
                setSearchObj({
                  ...searchObj,
                  fromRegisterDate: returnDateyyyymmdd(date[0]),
                  toRegisterDate: returnDateyyyymmdd(date[1]),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">판매사 (포워더)</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
              value={searchObj?.partnerSeq?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.SELLER_FORWARDER_AUTH?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: (option as OptionItem[])?.map((ele) => ele.value),
                });
              }}
            />
          </Col>
          <Col style={{ textAlign: 'left' }}>
            <button className="btn btn-search" type="submit">
              조회
            </button>
          </Col>
          <Col />
          <Col />
          <Col />
        </Row>
      </Form>
    </>
  );
};
