import { setLoading } from 'redux/services/menuSlice';
import { Oval } from 'react-loader-spinner';
import close from 'assets/images/close/cross.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { COLORS, VALUES } from 'common/constants/appearance';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

export default function LDSLoader({ loadingStatus, openOrclose }: { loadingStatus?: string; openOrclose?: boolean }) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.menu);
  const modalRoot = document.querySelector('#modal-root');
  const [modalChildLength, setModalChildLength] = useState(0);

  useEffect(() => {
    setModalChildLength(modalRoot?.children?.length ?? 0);
  }, [loading]);

  return (
    <div className="lds-loader" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: modalChildLength > 0 ? '0px' : openOrclose === true ? `${VALUES.SIDEBAR_EXPANDED}px` : openOrclose === false ? `${VALUES.SIDEBAR_COLLAPSED}px` : '0px' }} />
      <LoadingWrapper style={{ position: 'relative' }}>
        {loadingStatus !== 'PUT' && (
          <CloseLoaderButton
            style={{ width: 10, position: 'absolute', top: '52%', left: '58%', zIndex: 1057, cursor: 'pointer' }}
            src={close}
            alt="로딩바닫기"
            onClick={(e) => {
              dispatch(setLoading(null));
            }}
          />
        )}
        <Oval
          height={100}
          width={100}
          color={COLORS.PRIMARY}
          wrapperClass={loadingStatus === 'GET' || loading === 'GET' ? 'progress-bar-wrapper get' : 'progress-bar-wrapper put'}
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor={COLORS.PRIMARY_015}
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
      </LoadingWrapper>
    </div>
  );
}

const CloseLoaderButton = styled.img`
  left: -10px !important;
  top: -10px !important;
`;
const LoadingWrapper = styled.div`
  margin-top: -100px;
  margin-left: -100px;
`;
