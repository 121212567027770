import { useEffect, useState, useMemo } from 'react';
import { INVENTORY_MODIFICATION_ALL, INVENTORY_MODIFICATION_SAVE } from 'envVar';
import Presenter from './presenter';
import { httpClient } from 'common/http-client/axiosConfig';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';

import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { generateUrlByDesignType } from 'components/LDS/utils/path';

export const defaultModelSearchObj = {
  visible: false,
  data: [],
  returns: null,
};

export const reasonOptions = [
  { value: '초도불량', label: '초도불량' },
  { value: '간선중파손', label: '간선중파손' },
  { value: '창고내파손', label: '창고내파손' },
  { value: '배송중파손', label: '배송중파손' },
  { value: '직접입력', label: '>비고란에 직접입력' },
];

const Container = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);
  const { reduxUserInfo } = useSelector((state) => state.auth);
  const defaultSearchFilter = {
    centerCode: reduxUserInfo['centerCode'], // 창고
    partnerSeq: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[reduxUserInfo['partner']] ? reduxUserInfo['partner'] : null, // 판매사
  };
  const [gridId, setGrid] = useState();
  const [rows, setRows] = useState([]);
  const [modelSearchObj, setModelSearchObj] = useState(defaultModelSearchObj);

  const [searchObj, setSearchObj] = useState({
    locationCode: null,
    partnerSeq: null,
    modelName: null,
    barcode: null,
    modelStockSeq: null,
    ...defaultSearchFilter,
  });

  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'delete-row') {
        deleteCheckedRows(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  useEffect(() => {
    if (modelSearchObj?.returns) {
      const items = window.AUIGrid.getGridData(`#${gridId}`);
      modelSearchObj?.returns?.forEach((row) => {
        new Array(Number(row.quantity)).fill(0)?.forEach((_, idx) => {
          items.push({
            ...row,
            partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
            centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
            beforeAvailableStatus: row.availableQuantity > 0 ? 'AVAILABLE' : 'MOVE',
          });
        });
      });
      alert('추가되었습니다!');
      setRows(items);
    }
  }, [modelSearchObj?.returns]);

  const goRegister = async () => {
    const items = window.AUIGrid.getGridData(`#${gridId}`);
    const data = [];
    items.forEach((item) => {
      const obj = {
        centerCode: item.centerCode,
        locationCode: item.locationCode,
        modelStockSeq: item.modelStockSeq,
        serialNumber: item.serialNumber,
        quantity: item.quantity,
        beforeAvailableStatus: item?.beforeAvailableStatus === 'AVAILABLE' ? 'AVAILABLE' : 'ETC',
        beforeAvailableType: item?.beforeAvailableStatus === 'AVAILABLE' ? 'AVAILABLE' : item?.beforeAvailableStatus,
        afterAvailableStatus: 'DEFECTED',
        afterAvailableType: item.afterState,
      };
      if (item.reason) obj.reason = item.reason;
      if (item.note) obj.note = item.note;

      data.push(obj);
    });
    dispatch(setLoading('POST'));
    const res = await httpClient.post(INVENTORY_MODIFICATION_SAVE, data);
    if (res?.status === 200) {
      alert(`${items?.length}건에 대하여 양품재고조정 신청이 완료되었습니다!`);
      history(generateUrlByDesignType('/modifications'));
    }
    dispatch(setLoading(null));
  };

  const onCellClick = (e) => {
    if (e.dataField === 'pass') {
      setDiff(e);
    }
  };

  const deleteCheckedRows = (items) => {
    const rows = window.AUIGrid.getGridData(`#${gridId}`);
    if (rows?.length > 0) {
      const checkedRows = [];
      items.forEach((item) => {
        checkedRows.push(item.rowIndex);
      });
      const _new = [];
      rows.forEach((row, idx) => {
        if (!checkedRows.includes(idx)) _new.push(row);
      });
      setRows(_new);
    }
  };

  const columns = [
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          editable: false,
        },
        {
          headerText: '제품',
          dataField: 'model',
          editable: false,
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          editable: false,
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          editable: false,
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          editable: false,
        },
      ],
    },
    {
      headerText: '로케이션정보',
      children: [
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
          editable: false,
        },
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          editable: false,
        },
      ],
    },
    {
      headerText: '*재고조정(입력부분)',
      children: [
        {
          headerText: '변경전 상태값',
          dataField: 'beforeAvailableStatus',
          width: 100,
          renderer: {
            type: 'DropDownListRenderer',
            listFunction: function (rowIndex, columnIndex, item, dataField) {
              console.log(item);
              let list = [];
              if (item.availableQuantity > 0)
                list?.push({
                  value: 'AVAILABLE',
                  label: '양품',
                });

              if (item.moveQuantity > 0)
                list?.push({
                  value: 'MOVE',
                  label: '기타(이전)',
                });

              if (item.repairQuantity > 0)
                list?.push({
                  value: 'REPAIR',
                  label: '기타(수리)',
                });
              return list;
            },
            keyField: 'value', // key 에 해당되는 필드명
            valueField: 'label', // value 에 해당되는 필드명
          },
        },
        {
          headerText: '*변경후 상태값',
          dataField: 'afterState',
          width: 100,
          renderer: {
            type: 'DropDownListRenderer',
            listFunction: function (rowIndex, columnIndex, item, dataField) {
              let list = [
                {
                  value: 'REFUR',
                  label: '불용-리퍼대상',
                  var: 'refurbishQuantity',
                },
                {
                  value: 'SCRAP',
                  label: '불용-폐기대상',
                  var: 'scrapQuantity',
                },
                {
                  value: 'MANUF',
                  label: '불용-반출대상',
                  var: 'manufactureQuantity',
                },
                {
                  value: 'COLLECT',
                  label: '불용-회수입고',
                  var: 'collectQuantity',
                },
                {
                  value: 'INVESTIGATE',
                  label: '불용-재고조사',
                  var: 'investigateQuantity',
                },
              ];
              return list;
            },
            keyField: 'value', // key 에 해당되는 필드명
            valueField: 'label', // value 에 해당되는 필드명
          },
        },
        {
          headerText: '*조정사유',
          dataField: 'reason',
          width: 150,
          renderer: {
            type: 'DropDownListRenderer',
            list: reasonOptions,
            keyField: 'value', // key 에 해당되는 필드명
            valueField: 'label', // value 에 해당되는 필드명
          },
        },
        {
          headerText: "비고(조정사유 '직접입력'시에만)",
          dataField: 'note',
          width: 250,
          editable: true,
        },
        {
          headerText: '시리얼번호',
          dataField: 'serialNumber',
          editable: true,
        },
      ],
    },
  ];

  useEffect(() => {
    setGrid(`inventoryControlAvailableRegit_${uuidv4()}`);
  }, []);

  useEffect(() => {
    if (modelSearchObj?.returns) {
      window.AUIGrid.addRow(`#${gridId}`, modelSearchObj?.returns, 'last');
      setModelSearchObj({
        ...modelSearchObj,
        returns: null,
      });
    }
  }, [modelSearchObj?.returns]);

  const setDiff = (e) => {
    const row = { ...e.item };
    const rowIndex = e.rowIndex;
    if (!row['afterState']) {
      alert('"*변경후 상태값"을 입력하세요!');
    } else if (!row['reason']) {
      alert('"*조정사유"를 입력하세요!');
    } else if (row['reason'] === '>비고란에 직접입력' && !row['note']) {
      alert('"비고"란에 조정사유를 수기로 입력하세요!');
    } else {
      try {
        if (row['note']) row['reason'] = reasonOptions[4].value;
        if (row['quantity'] > 0) {
          if (row['availableQuantity'] < row['quantity']) {
            alert('조정할수 있는 수량을 초과하였습니다! 변경수량을 확인해주세요');
            throw new Error();
          }
          if (row['afterState'] === 'COLLECT') {
            row['afterCollectQuantity'] = row['collectQuantity'] + row['quantity'];
            row['whatChanged'] = 'COLLECT';
          } else if (row['afterState'] === 'INVESTIGATE') {
            row['afterInvestigateQuantity'] = row['investigateQuantity'] + row['quantity'];
            row['whatChanged'] = 'INVESTIGATE';
          } else if (row['afterState'] === 'MANUF') {
            row['afterManufactureQuantity'] = row['manufactureQuantity'] + row['quantity'];
            row['whatChanged'] = 'MANUF';
          } else if (row['afterState'] === 'REFUR') {
            row['afterRefurbishQuantity'] = row['refurbishQuantity'] + row['quantity'];
            row['whatChanged'] = 'REFUR';
          } else if (row['afterState'] === 'SCRAP') {
            row['afterScrapQuantity'] = row['scrapQuantity'] + row['quantity'];
            row['whatChanged'] = 'SCRAP';
          }

          row['afterAvailableQuantity'] = row['availableQuantity'] - row['quantity'];
          row['passFlag'] = true;

          window.AUIGrid.updateRow(`#${gridId}`, row, rowIndex);
        } else {
          alert('"*변경수량"을 확인해주세요!');
        }
      } catch (error) {
        console.log(error);
        row['passFlag'] = false;
        window.AUIGrid.updateRow(`#${gridId}`, row, rowIndex);
      }
    }
  };

  const paramingSearchObjToUrl = (url) => {
    if (searchObj?.centerCode) url += `&centerCode=${searchObj?.centerCode}`;
    if (searchObj?.locationCode) url += `&locationCode=${searchObj?.locationCode}`;
    if (searchObj?.partnerSeq) url += `&partnerSeq=${searchObj?.partnerSeq}`;
    if (searchObj?.modelName?.trim()) url += `&modelName=${searchObj?.modelName}`;
    if (searchObj?.barcode) url += `&barcode=${searchObj?.barcode}`;
    if (searchObj?.modelStockSeq) url += `&modelStockSeq=${searchObj?.modelStockSeq}`;
    return url;
  };

  const fetchList = async () => {
    dispatch(setLoading('GET'));
    let url = INVENTORY_MODIFICATION_ALL + '?';
    url = paramingSearchObjToUrl(url);

    httpClient.get(url).then((res) => {
      let data = [];
      if (res?.status === 200) {
        data = res.data;
        setModelSearchObj({
          ...modelSearchObj,
          visible: true,
          data,
        });
      } else {
        alert('잘못된 접근입니다');
      }
      dispatch(setLoading(null));
    });
  };

  return (
    <Presenter
      gridId={gridId}
      rows={rows}
      setRows={setRows}
      columns={columns}
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      fetchList={fetchList}
      onCellClick={onCellClick}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      goRegister={goRegister}
      modelSearchObj={modelSearchObj}
      setModelSearchObj={setModelSearchObj}
    />
  );
};

export default Container;
