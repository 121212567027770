import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { SerialNumberLiveDTO, SerialNumberLiveSearchDTO } from 'interface/warehouse';
import { inventoryService } from '../../_service/service';
import { IPopupForModiObj, PopupForModi } from './component/popupForModi';
import { selectlabel, selectvalue } from 'common/master/codeMasterReturnHelper';
import useExcelHooks from 'hooks/excel/useExcelHooks';
import { PagingListDTO } from 'interface/util';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { LIVE_SEIRAL_TODAY_FORM } from 'envVar2';
import { InventoryReportsCommonDefaultSearchFilters } from '..';
import useSearchGridPagingNew from 'hooks/grid/useGridPagingNew';

const Index = ({ tabId }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const searchObj = useSelector((state: RootState) => state.tab)?.searchObj?.[tabId];
  const defaultSearchFilter = useMemo(() => {
    return {
      ...InventoryReportsCommonDefaultSearchFilters,
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    };
  }, [reduxUserInfo]);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const [popupForModiObj, setPopupForModiObj] = useState<IPopupForModiObj>();
  const columns: IGrid.Column[] = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품유형',
          dataField: 'modelTypeKr',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '대표상품코드',
          dataField: 'productCode',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: 'LOT번호(외부바코드)',
          dataField: 'externalBarcode',
        },
        {
          headerText: '시리얼번호',
          dataField: 'serialNumber',
          width: 200,
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: (e) => {
              setPopupForModiObj({
                visible: true,
                type: 'serial',
                items: e.item,
                searchObj: e.item.searchObj,
                fetchFn: (data) => initFetch(data),
              });
            },
            disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
              return !item?.INVENTORY_SERIAL_MODI_SERIAL;
            },
          },
        },
        {
          headerText: '발주번호',
          dataField: 'purchaseSeq',
        },
      ],
    },
    {
      headerText: '고객정보',
      children: [
        {
          headerText: '송장번호',
          dataField: 'invoiceSeq',
        },
        {
          headerText: '수령인',
          dataField: 'receiverName',
        },
        {
          headerText: '설치기사',
          dataField: 'userIdKr',
        },
      ],
    },
    {
      headerText: '재고상태',
      dataField: 'availableStatusKr',
    },
    {
      headerText: '재고유형',
      dataField: 'availableTypeKr',
    },
    {
      headerText: '이동상태',
      dataField: 'moveStatusKr',
    },
    {
      headerText: '생성일시',
      dataField: 'createDatetime',
    },
    {
      headerText: '경과일수',
      dataField: 'age',
    },
    {
      headerText: '제조일',
      dataField: 'manufactureDate',
    },
    {
      headerText: '유통일',
      dataField: 'distributeDate',
    },
    {
      headerText: '자산유형',
      dataField: 'assetTypeKr',
    },
  ];

  const labellingKr = (data: PagingListDTO<SerialNumberLiveDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE),
        modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
        availableTypeKr: selectlabel(row.availableType, masterOptions?.INVA_TYPE),
        availableStatusKr: selectlabel(row.availableStatus, masterOptions?.INVA_STATUS),
        moveStatusKr: selectlabel(row.moveStatus, masterOptions?.INVM_STATUS),
        userIdKr: selectlabel(row.userId, masterOptions?.DRIVER_WHOLE),
        assetTypeKr: selectlabel(row.assetType, masterOptions?.ASSET_TYPE),
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: SerialNumberLiveSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await inventoryService.getInventoryLiveListPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { initFetch, handleSearchObj } = useSearchGridPagingNew({
    tabId,
    gridRef,
    // fetchTotal,
    fetchPaging,
    initialSearchObj: {
      ...defaultSearchFilter,
      ...searchObj,
    },
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const data = await inventoryService.getInventoryLiveLExcelMain(searchObj);
    const { list } = labellingKr({ list: data }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '실시간시리얼별재고현황' });
    dispatch(setLoading(null));
  };

  const popupForModi = (type: 'manufactureDate' | 'distributeDate' | 'assetType') => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      setPopupForModiObj({
        visible: true,
        items,
        type,
        searchObj: JSON.stringify(searchObj),
        fetchFn: (data) => initFetch(data),
      });
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };
  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks();
  const saveWithExcel = async (excelrows = []) => {
    const rs = await inventoryService.postSerialNumberExcelSave(excelrows);
    if (rs?.status === 200) {
      alert('업로드 성공하였습니다!');
      initFetch(searchObj);
    }
    //
  };
  useEffect(() => {
    if (parsedData) {
      if (parsedData?.data?.length > 0) {
        const data = parsedData?.data?.map((row) => {
          return {
            serialNumber: row[0],
            assetType: selectvalue(row[1], masterOptions?.ASSET_TYPE),
            manufactureDate: row[2],
            distributeDate: row[3],
          };
        });
        saveWithExcel(data);
      } else {
        alert('읽혀진 값이 없습니다');
        dispatch(setLoading(null));
      }
    }
  }, [parsedData]);

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'INVENTORY_SERIAL_EXCELDOWN_MAIN') {
      downloadExcel('main');
    } else if (id === 'INVENTORY_SERIAL_EXCELUPLOAD_FORM') {
      window.open(LIVE_SEIRAL_TODAY_FORM);
    } else if (id === 'INVENTORY_SERIAL_EXCELUPLOAD') {
      uploaderClick(id);
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'INVENTORY_SERIAL_SAVE_MANUFACTURE') {
        popupForModi('manufactureDate');
      } else if (id === 'INVENTORY_SERIAL_SAVE_DISTRIBUTEDATE') {
        popupForModi('distributeDate');
      } else if (id === 'INVENTORY_SERIAL_SAVE_ASSETTYPE') {
        popupForModi('assetType');
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {popupForModiObj?.visible && <PopupForModi popupForModiObj={popupForModiObj} setPopupForModiObj={setPopupForModiObj} />}
      <div className="presenterSearch">
        <SearchBox fetchList={initFetch} handleSearchObj={handleSearchObj} TARGET={tabId} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
