import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './cargoList';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { getGridParameters } from 'common/master/codeMasterOptionReturn';
import { COMMON_GRIDID, nullingMASTER, RAWDATA } from 'redux/services/menuSlice';

export const CARGO_MANIFEST_LIST_MASTER = [
  //
  'CM_STATUS_2',
  'CM_TRANSPORT_TYPE',
  'PORT_CODE',
  {
    SEARCH_DATE: [
      {
        label: '등록일',
        value: 'RegisterDate',
      },
      {
        label: '선적일',
        value: 'LoadDate',
      },
      {
        label: '선적실패일',
        value: 'LoadFailDate',
      },
      {
        label: '통관시작일',
        value: 'CcStartDate',
      },
      {
        label: '통관완료일',
        value: 'CcCompleteDate',
      },
      {
        label: '통관실패일',
        value: 'CcFailDate',
      },
      {
        label: '택배인계일',
        value: 'ParcelTransferDate',
      },
      {
        label: '배송완료일',
        value: 'FinishDate',
      },
      {
        label: '배송실패일',
        value: 'FailDate',
      },
      {
        label: '재반입예정일',
        value: 'SendBackPlanDate',
      },
      {
        label: '재반입일',
        value: 'SendBackDate',
      },
      {
        label: '반송예정일',
        value: 'ReturnPlanDate',
      },
      {
        label: '반송준비일',
        value: 'ReturnReadyDate',
      },
      {
        label: '반송인계일',
        value: 'ReturnHandoverDate',
      },
      {
        label: '통관보류일',
        value: 'CcPendDate',
      },
    ],
  },
  {
    SEARCH_SEQ: [
      {
        label: '주문번호',
        value: 'orderNumber',
      },
      {
        label: 'bigBag No.',
        value: 'bagBagId',
      },
      {
        label: 'batch No.',
        value: 'batchNo',
      },
      {
        label: 'MBL',
        value: 'masterNumber',
      },
      {
        label: 'HBL',
        value: 'houseNumber',
      },
    ],
  },
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const CARGO_MANIFEST = await getGridParameters('CARGO_MANIFEST');
    initMasterOptions([{ [`${COMMON_GRIDID}CARGO_MANIFEST`]: CARGO_MANIFEST }, ...CARGO_MANIFEST_LIST_MASTER]);
  };
  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            CARGO_MANIFEST: <Tab1 tabId={'CARGO_MANIFEST'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
