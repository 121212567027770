import { validateSearchFilters } from 'common/util/searchBox';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD } from 'components/reactstrap/reactstrap';
import { ParcelInvoicePriceCreateDTO } from '../../../_interface/parcel';
import { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { setLoading } from 'redux/services/menuSlice';
import { serviceStore } from 'services/services';

export interface IChangeFareObj {
  visible?: boolean;
  items?: string[];
  query?: ParcelInvoicePriceCreateDTO;
}

export default function ChangeFareModal({ changeFareObj, setChangeFareObj }) {
  const dispatch = useDispatch();
  const [priceValue, setPriceValue] = useState<string>('');

  const handleSubmit = (e?: FormEvent) => {
    e.preventDefault();

    // NOTE: 필수 필터를 검증하는 공통 함수 validateSearchFilters를 적용하여 필수 필터 (복수)를 검증
    const isValidate = validateSearchFilters(
      {
        name: '변경 운임비',
        value: changeFareObj.query?.price === 0 ? String(changeFareObj.query?.price) : changeFareObj.query?.price,
      },
      {
        name: '변경 사유',
        value: changeFareObj.query?.reason,
      },
    );
    if (!isValidate) return;

    if (window.confirm('운임비를 변경하시겠습니까?')) {
      postChangeParcelFareAPI();
      return;
    }
  };

  const postChangeParcelFareAPI = async () => {
    dispatch(setLoading('POST'));

    const rs = await serviceStore?.parcelAction(`invoice/price`, 'POST', null, { ...changeFareObj.query });

    if (rs?.status === 200) {
      alert('운임비변경 요청이 완료되었습니다.');
      setChangeFareObj(null);
    }
    rs.message && alert(rs.message);

    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title={'운임비 변경 요청'}
      visible={changeFareObj?.visible}
      style={{ width: 350 }}
      setVisible={() => {
        setChangeFareObj({ visible: false });
      }}
      rightTitle={
        <button className="btn btn-secondary ssm" onClick={handleSubmit}>
          변경
        </button>
      }
      children={
        <form onSubmit={handleSubmit}>
          <Row style={{ flexDirection: 'column', marginBottom: 10, gap: 8 }}>
            <Col>
              <label className="col-form-label">현재 운임비</label>
              <InputD readOnly disabled value={changeFareObj?.query.beforePrice.toLocaleString('ko-KR') || 0} />
            </Col>
            <Col>
              <label className="col-form-label">*변경 운임비</label>
              <InputD
                value={priceValue}
                placeholder="숫자만 입력..."
                onChange={(e) => {
                  const typedValue = e.target.value.replace(/,/g, '');

                  if (!Number.isNaN(parseInt(typedValue)) && typeof parseInt(typedValue) === 'number') setPriceValue(parseInt(typedValue).toLocaleString('ko-KR'));
                  else setPriceValue('');

                  setChangeFareObj({
                    ...changeFareObj,
                    query: {
                      ...changeFareObj.query,
                      price: parseInt(typedValue),
                    },
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*변경 사유 입력</label>
              <InputD
                onChange={(e) => {
                  setChangeFareObj({
                    ...changeFareObj,
                    query: {
                      ...changeFareObj.query,
                      reason: e.target.value,
                    },
                  });
                }}
              />
            </Col>
          </Row>
          <button type="submit" style={{ display: 'none' }}>
            submit
          </button>
        </form>
      }
    />
  );
}
