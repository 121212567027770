import { useEffect, useState } from 'react';
import CommonModalNew from 'components/modal/commonModalNew';

export const AuthTmpPopup = (prop) => {
  const { tempObj, setTempObj, setTmpToAuth, deleteTmpThen } = prop;

  const [canEditTmpList, setCanEditTmpList] = useState(null);
  const [tmpFlag, setTmpFlag] = useState(false);

  useEffect(() => {
    InitCheckBox();
  }, [tempObj]);

  const InitCheckBox = () => {
    setTmpFlag(false);
    setCanEditTmpList(null);
  };

  const handleDeleteTargets = (e) => {
    let set = new Set(canEditTmpList);
    if (set.has(e.target.id)) {
      set.delete(e.target.id);
    } else {
      set.add(e.target.id);
    }
    setCanEditTmpList(Array.from(set));
  };

  const deleteTargets = async () => {
    const rs = await tempObj?.deleteFn({ announcementSeqs: canEditTmpList });
    if (rs?.status === 200) {
      deleteTmpThen({ announcementSeq: tempObj?.now });
    }
  };

  return (
    <CommonModalNew
      style={{ width: 350 }}
      title={'임시저장글'}
      visible={tempObj?.visible}
      setVisible={() => {
        setTempObj((prev) => {
          return {
            ...prev,
            visible: false,
            now: null,
          };
        });
      }}
      children={
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 12, paddingRight: 0 }}>
            <span style={{ fontSize: 13 }}>
              총 <span className="orange">{tempObj?.tempList?.length}</span> 개
            </span>
            <span>
              {tmpFlag && (
                <button
                  className="btn btn-danger ssm"
                  onClick={() => {
                    deleteTargets();
                  }}
                >
                  삭제
                </button>
              )}
              <button
                className="btn btn-white ssm"
                onClick={() => {
                  setTmpFlag(!tmpFlag);
                  if (tmpFlag) setCanEditTmpList([]);
                }}
              >
                {tmpFlag ? '취소' : '편집'}
              </button>
            </span>
          </div>
          <div className="justLineList" style={{ maxHeight: 500 }}>
            {tempObj?.tempList?.map((ele, idx) => {
              return (
                <div key={`tempList_${idx}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
                  <div style={{ display: 'flex' }}>
                    {tmpFlag && <input type="checkbox" id={ele.announcementSeq} onChange={handleDeleteTargets} style={{ marginLeft: -10, marginRight: 10, marginTop: 8 }} />}
                    <div
                      style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
                      onClick={() => {
                        setTmpToAuth(ele);
                      }}
                    >
                      <div className="title">{ele.title}</div>
                      <div className="registerDatetime">{ele.registerDatetime}</div>
                    </div>
                  </div>
                  <div>{tempObj?.now === ele?.announcementSeq && <span className="orange">작성중</span>}</div>
                </div>
              );
            })}
          </div>
        </>
      }
    />
  );
};
