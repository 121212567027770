import { ReactNode, useMemo, useRef, useState } from 'react';

// utils
import * as FileSaver from 'file-saver';
import * as IGrid from 'aui-grid';
import { XLSX } from 'common/util/fileUploader';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import { orderService } from '../../_services/service';
// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';

// hooks
import useGridButton from 'hooks/grid/useGridButton';
import { OrderExcelTemplateSearchDTO } from '../../_interface/order';
import { SearchBox } from './component/searchBox';
import { DetailPopup, IDetailObj } from './component/detailPopup';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [detailObj, setDetailObj] = useState<IDetailObj>();
  const [searchObj, setSearchObj] = useState<OrderExcelTemplateSearchDTO>({});

  const popupModelDetail = (e: IGrid.ButtonClickEvent) => {
    setDetailObj({
      visible: true,
      item: e.item,
      searchObj: e.item.searchObj,
      fetchFn: (searchObj: OrderExcelTemplateSearchDTO) => fetchList(searchObj),
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupModelDetail,
      },
    },

    {
      headerText: '양식 번호',
      dataField: 'customerOrderExcelTemplateSeq',
    },
    {
      headerText: '엑셀유형',
      dataField: 'excelTypeKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '판매사 열',
      dataField: 'partnerSeq_tmpCol',
    },
    {
      headerText: '주문유형 열',
      dataField: 'orderType',
    },
    {
      headerText: '송장유형 열',
      dataField: 'feeType',
    },
    {
      headerText: '외부주문번호 열',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '외부송장번호 열',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '고객명 열',
      dataField: 'customerName',
    },
    {
      headerText: '고객 연락처 열',
      dataField: 'customerPhone',
    },
    {
      headerText: '고객 연락처2 열',
      dataField: 'customerPhone2',
    },
    {
      headerText: '고객 주소 열',
      dataField: 'customerAddr',
    },
    {
      headerText: '고객 우편번호 열',
      dataField: 'customerZipcode',
    },
    {
      headerText: '수령인 열',
      dataField: 'receiverName',
    },
    {
      headerText: '수령인 연락처 열',
      dataField: 'receiverPhone',
    },
    {
      headerText: '수령인 연락처2 열',
      dataField: 'receiverPhone2',
    },
    {
      headerText: '수령인 주소 열',
      dataField: 'receiverAddr',
    },
    {
      headerText: '수령인 우편번호 열',
      dataField: 'receiverZipcode',
    },
    {
      headerText: '주문일자 열',
      dataField: 'orderDatetime',
    },
    {
      headerText: '희망일 열',
      dataField: 'promiseDeliveryDate',
    },
    {
      headerText: '배송시 특이사항 열',
      dataField: 'shipmentNote',
    },
    {
      headerText: '바코드 열',
      dataField: 'barcode',
    },
    {
      headerText: '제품 열',
      dataField: 'model',
    },
    {
      headerText: '제품 유형 열',
      dataField: 'productModelType',
    },
    {
      headerText: '제품 수량 열',
      dataField: 'quantity',
    },
    {
      headerText: '외부상품번호 열',
      dataField: 'externalProductNumber',
    },
    {
      headerText: '추천인 고유번호 열',
      dataField: 'recommenderNumber',
    },
    {
      headerText: '추천인 이름 열',
      dataField: 'recommenderName',
    },
    {
      headerText: '취소비용 고객부담 여부 열',
      dataField: 'cancelChargeFlag',
    },
    {
      headerText: '착불비 열',
      dataField: 'cashOnDelivery',
    },
    {
      headerText: '판매채널 열',
      dataField: 'salesChannelSeq',
    },
    {
      headerText: '택배사 열',
      dataField: 'parcelType',
    },
    {
      headerText: '전시장 열',
      dataField: 'displayCenterCode',
    },
    {
      headerText: '판매가 열',
      dataField: 'sellingPrice',
    },
  ];

  const fetchList = async (searchObj: OrderExcelTemplateSearchDTO) => {
    dispatch(setLoading('GET'));
    const data = await orderService.getUploadFormList(searchObj);

    const dataKr = data?.map((row) => {
      return {
        ...row,
        searchObj: JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        excelTypeKr: MASTER_OBJ?.ORDER_EXCEL_TYPE?.[row.excelType],
        partnerSeq_tmpCol: 'A',
      };
    });

    gridRef.current.setGridData(dataKr);
    dispatch(setLoading(null));
  };

  const deleteTemplate = async (item) => {
    //
    if (window.confirm(`삭제 하시겠습니까?`)) {
      dispatch(setLoading('PUT'));
      const rs = await orderService.saveUploadForm({ delete: { customerOrderExcelTemplateSeq: item.customerOrderExcelTemplateSeq } });
      if (rs?.status === 200) {
        alert('삭제되었습니다!');
        if (item?.searchObj) {
          const searchObj = JSON.parse(item?.searchObj);
          fetchList(searchObj);
        }
      }
      dispatch(setLoading(null));
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'ORDERFORMCREATE') {
      setDetailObj({
        visible: true,
        item: null,
        searchObj,
        fetchFn: (searchObj: OrderExcelTemplateSearchDTO) => fetchList(searchObj),
      });
    } else {
      handleCheckItems(e);
    }
  };

  const handleCheckItems = (e) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      const id = e.target.id;
      if (id === 'ORDERFORMREMOVE') {
        if (items?.length > 1) {
          alert('하나씩 삭제가능합니다!');
        } else {
          deleteTemplate(items[0]);
        }
      } else if (id === 'ORDERFORMEXCEL') {
        if (items?.length > 1) {
          alert('하나씩 선택 가능합니다!');
        } else {
          formDownloadAction(items[0]);
        }
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const formDownloadAction = async (item) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelType = item.excelType;
    const excelFileName = `주문등록 양식(${item.excelTypeKr})`;

    if (item.externalNumberFlag === true) {
      delete item.externalInvoiceNumber;
    }
    if (item.receiverFlag === true) {
      delete item.receiverAddr;
      delete item.receiverName;
      delete item.receiverPhone;
      delete item.receiverPhone2;
      delete item.receiverZipcode;
    }

    delete item.customerOrderExcelTemplateSeq;
    delete item.orderExcelTemplateSeq;
    delete item.excelType;
    delete item.excelTypeKr;
    delete item.modal;
    delete item.partnerSeq;
    delete item.partnerSeqKr;
    delete item.partnerSeq_tmpCol;
    delete item.receiverFlag;
    delete item.externalNumberFlag;
    delete item._$uid;
    delete item.searchObj;

    item.partnerSeq = 'A';
    const itemEntries = Object.entries(item);
    const result = itemEntries
      .sort((a, b) => {
        return ((a[1] + '') as string).charCodeAt(0) - ((b[1] + '') as string).charCodeAt(0);
      })
      .map((ele) => {
        return MASTER_OPS?.['COLS_' + excelType + '_OBJ']?.[ele[0]];
      });
    const ws = XLSX.utils.aoa_to_sheet([result]);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
  };

  return (
    <div className="page-content">
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={fetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            fixedColumnCount: 1,
          }}
        />
      </div>
    </div>
  );
};

export default Index;
