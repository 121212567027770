import { KeyboardEvent } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { MessageTemplateSearchDTO } from 'interface/order';

export const SearchBox = (props: ISearchBoxProps<MessageTemplateSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[searchObj?.partnerSeq],
                  }
                : null
            }
            options={MASTER_OPS?.SELLER_SELLER_AUTH}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">전송시점</label>
          <SelectD
            value={
              searchObj?.messageType
                ? {
                    value: searchObj?.messageType,
                    label: MASTER_OBJ?.MESSAGE_TYPE?.[searchObj?.messageType],
                  }
                : null
            }
            options={MASTER_OPS?.MESSAGE_TYPE}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                messageType: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
