import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';
import { useLocation } from 'react-router-dom';
import * as IGrid from 'aui-grid';
// component
import CommonModalNew from 'components/modal/commonModalNew';
import {
  OrderFeeCancelDTO,
  OrderFeeDateDTO,
  OrderFeeDefaultDTO,
  OrderFeeModelDTO,
  OrderFeeGroupDTO,
  OrderFeeOptionDTO,
  OrderFeeProductDTO,
  OrderFeeRegionDTO,
  OrderFeeCancelSearchDTO,
  OrderFeeDateSearchDTO,
  OrderFeeDefaultSearchDTO,
  OrderFeeModelSearchDTO,
  OrderFeeGroupSearchDTO,
  OrderFeeOptionSearchDTO,
  OrderFeeProductSearchDTO,
  OrderFeeRegionSearchDTO,
} from '../../../_interface/order';
import { CATEGORY, OMS_TARGET, feeService } from 'pages/FEE/_service/service';
import { INPUT, SELECT_MULTI, SELECT_SINGLE, TYPE, convertKr, convertMaster } from '../asset/asset';

import useSearch from 'hooks/grid/useSearch';
import AUIGrid from 'modules/Grid';
import GridBox, { defaultGridProps } from 'common/grid/gridBox';
import { ZipcodeSearchDTO } from 'interface/install';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import useToast from 'hooks/useToast';
import { RAWDATA } from 'redux/services/menuSlice';
import ModelStockModal, { IPopupGridObj } from 'pages/ORDER/1order/orderListTab/common/component/invocieDetail/modelStockModal';
import ProductStockModal from 'pages/ORDER/1order/orderListTab/common/component/invocieDetail/productStockModal';

export interface IAddTypeObj {
  visible?: boolean;
  target?: OMS_TARGET;
  data?: OrderFeeCancelDTO & OrderFeeDateDTO & OrderFeeDefaultDTO & OrderFeeModelDTO & OrderFeeGroupDTO & OrderFeeOptionDTO & OrderFeeProductDTO & OrderFeeRegionDTO;
  searchObj?: OrderFeeCancelSearchDTO &
    OrderFeeDateSearchDTO &
    OrderFeeDefaultSearchDTO &
    OrderFeeModelSearchDTO &
    OrderFeeGroupSearchDTO &
    OrderFeeOptionSearchDTO &
    OrderFeeProductSearchDTO &
    OrderFeeRegionSearchDTO;
  fetchFn?: (
    searchObj: OrderFeeCancelSearchDTO &
      OrderFeeDateSearchDTO &
      OrderFeeDefaultSearchDTO &
      OrderFeeModelSearchDTO &
      OrderFeeGroupSearchDTO &
      OrderFeeOptionSearchDTO &
      OrderFeeProductSearchDTO &
      OrderFeeRegionSearchDTO,
  ) => {};
}

export const AddType = ({ addTypeObj, setAddTypeObj }) => {
  const gridRef = useRef<AUIGrid>();
  const gridRef2 = useRef<AUIGrid>();
  const location = useLocation();
  const { MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { target } = addTypeObj;
  const [nodeRow, setNodeRow] = useState<ReactNode[]>();
  const [crudObj, setCrudObj] = useState<OrderFeeCancelDTO & OrderFeeDateDTO & OrderFeeDefaultDTO & OrderFeeModelDTO & OrderFeeGroupDTO & OrderFeeOptionDTO & OrderFeeProductDTO & OrderFeeRegionDTO>();
  const [filteredOptions, setFilteredOptions] = useState<OptionItem[]>([]);
  const [regionSearchObj, setRegionSearchObj] = useState<ZipcodeSearchDTO>();

  useEffect(() => {
    if (addTypeObj?.data) {
      setCrudObj(addTypeObj?.data);
    }
  }, []);

  useEffect(() => {
    initCRUDNode();
  }, [crudObj, target]);

  const { inputsMaker } = useSearch();

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));
  const pathOrder = currentDesignType === 1 ? 2 : 1;

  const initCRUDNode = () => {
    setNodeRow(
      inputsMaker({
        rawArr: TYPE[addTypeObj?.data ? `HAS_${target}` : target][location.pathname.split('/')[pathOrder].toLocaleUpperCase()],
        INPUT,
        SELECT_SINGLE,
        SELECT_MULTI,
        searchObj: crudObj,
        setSearchObj: setCrudObj,
        convertKr,
        convertMaster,
        colNumInRow: addTypeObj?.data ? 4 : 1,
      }),
    );
    // setDetails grid
    if (addTypeObj?.data?.details) {
      gridRef?.current?.setGridData(addTypeObj?.data?.details);
    }
  };

  const saveDetail = async (crudObj) => {
    const dataDTO = addTypeObj?.data
      ? {
          ...crudObj,
          details: gridRef.current.getGridData() || [],
        }
      : crudObj?.invoiceTypes?.map((ele) => {
          const obj = {
            ...crudObj,
            invoiceType: ele,
          };
          delete obj?.invoiceTypes;
          return obj;
        }) || [crudObj];

    const rs = await feeService.postOmsFeeAction(dataDTO, target, location.pathname.split('/')[pathOrder].toLocaleUpperCase() as CATEGORY, addTypeObj?.data ? 'detail/save' : 'save');
    if (rs?.status === 200) {
      alert('저장되었습니다!');

      addTypeObj?.fetchFn(addTypeObj?.searchObj);
      setAddTypeObj(null);
    }
  };

  const fetchList = async (searchObj: ZipcodeSearchDTO) => {
    //
    const data = await feeService.getZipcode(searchObj);
    if (data?.length > 0) {
      gridRef2.current.setGridData(data);
    } else {
      alert('검색되는 값이 없습니다');
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '대분류',
      dataField: 'modelGroupLarge',
      renderer: {
        type: IGrid.RendererKind.DropDownListRenderer,
        list: MASTER_OPS?.MODEL_GRP_1,
        descendants: ['modelGroupMedium'],
        descendantDefaultValues: [''],
        keyField: 'value',
        valueField: 'label',
      },
    },
    {
      headerText: '중분류',
      dataField: 'modelGroupMedium',
      renderer: {
        type: IGrid.RendererKind.DropDownListRenderer,
        descendants: ['modelGroupSmall'],
        descendantDefaultValues: [''],
        listFunction: function (rowIndex, columnIndex, item, dataField) {
          if (item.modelGroupLarge) {
            const code = MASTER_OPS?.[`${RAWDATA}MODEL_GRP_1`]?.filter((ele) => ele.code === item.modelGroupLarge)[0];
            return (
              code?.subCodes?.map((ele) => {
                return {
                  label: ele.codeName,
                  value: ele.code,
                };
              }) || []
            );
          }
          return [];
        },
        keyField: 'value',
        valueField: 'label',
      },
    },
    {
      headerText: '소분류',
      dataField: 'modelGroupSmall',
      renderer: {
        type: IGrid.RendererKind.DropDownListRenderer,
        listFunction: function (rowIndex, columnIndex, item, dataField) {
          if (item.modelGroupMedium) {
            const code = MASTER_OPS?.[`${RAWDATA}MODEL_GRP_2`]?.filter((ele) => ele.code === item.modelGroupMedium)[0];
            return (
              code?.subCodes?.map((ele) => {
                return {
                  label: ele.codeName,
                  value: ele.code,
                };
              }) || []
            );
          }
          return [];
        },
        keyField: 'value',
        valueField: 'label',
      },
    },
  ];

  const regionColumns: IGrid.Column[] = [
    {
      headerText: '우편번호',
      dataField: 'zipcode',
      editable: false,
    },
    {
      headerText: '권역명(시/도)',
      dataField: 'province',
      editable: false,
    },
    {
      headerText: '그룹명(시/군/구)',
      dataField: 'city',
      editable: false,
    },
    {
      headerText: '그룹상세(읍/면/동)',
      dataField: 'area',
      editable: false,
    },
  ];

  const regionDrag = (e) => {
    const id = e.target.id;
    const mains = gridRef.current.getGridData();

    if (id === 'main') {
      const checked = gridRef2.current.getCheckedRowItemsAll();
      const zipcodes = mains?.map((ele) => ele.zipcode);

      if (checked?.length > 0) {
        gridRef.current.setGridData(
          mains.concat(
            checked?.filter((ele) => {
              if (zipcodes?.includes(ele.zipcode)) addError();
              return !zipcodes?.includes(ele.zipcode);
            }),
          ),
        );
        gridRef2.current.setAllCheckedRows(false);
      } else {
        alert('선택된 검색값이 없습니다!');
      }
    } else {
      const checked = gridRef.current.getCheckedRowItemsAll()?.map((ele) => ele.zipcode);

      if (checked?.length > 0) {
        gridRef.current.setGridData(mains.filter((ele) => !checked.includes(ele.zipcode)));
      } else {
        alert('선택된 검색값이 없습니다!');
      }
    }
  };
  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'addRow') {
      gridRef.current.addRow({});
    } else if (id === 'deleteRow') {
      const idxs = gridRef.current.getCheckedRowItems().map((ele) => ele.rowIndex);
      if (idxs?.length > 0) {
        const rows = gridRef.current.getGridData();
        const newRows = rows.filter((_, i) => !idxs.includes(i));
        gridRef.current.setGridData(newRows);
      } else {
        alert('선택된 것이 없습니다');
      }
    }
  };

  const { Toast, showToast, setColor } = useToast({
    message: '중복된 값은 제외됩니다',
    ms: 3000,
  });

  const addError = () => {
    setColor('red');
    showToast();
  };

  const [productStockObj, setProductStockObj] = useState<IPopupGridObj>();
  const [modelStockObj, setModelStockObj] = useState<IPopupGridObj>();

  useEffect(() => {
    if (modelStockObj?.returns?.length > 0) {
      const model = { ...modelStockObj?.returns?.[0] };
      setCrudObj((prev) => {
        return {
          ...prev,
          barcode: model?.barcode,
        };
      });
      setModelStockObj(null);
    }
  }, [modelStockObj?.returns]);

  useEffect(() => {
    if (productStockObj?.returns?.length > 0) {
      const product = { ...productStockObj?.returns?.[0] };
      setCrudObj((prev) => {
        return {
          ...prev,
          productName: product?.productName,
          productSeq: product?.productSeq,
          externalProductNumber: product?.externalProductNumber,
          quantity: product?.quantity,
        };
      });
      setProductStockObj(null);
    }
  }, [productStockObj?.returns]);

  return (
    <CommonModalNew
      title={addTypeObj?.data ? '유형 상세' : '유형 추가'}
      style={{ width: (target === 'GROUP' && addTypeObj?.data) || (target === 'REGION' && addTypeObj?.data) ? 1200 : 300 }}
      visible={addTypeObj?.visible}
      setVisible={() => setAddTypeObj(null)}
      rightTitle={
        <>
          <button
            className="btn-secondary btn ssm"
            onClick={(e) => {
              e.preventDefault();
              saveDetail(crudObj);
            }}
          >
            {addTypeObj?.data ? '수정' : '등록'}
          </button>
        </>
      }
      children={
        <>
          <>
            <Toast />
          </>
          {!(target === 'REGION' && addTypeObj?.data) ? (
            <>
              {productStockObj?.visible && <ProductStockModal productStockObj={productStockObj} setProductStockObj={setProductStockObj} />}
              {modelStockObj?.visible && <ModelStockModal modelStockObj={modelStockObj} setModelStockObj={setModelStockObj} />}

              {nodeRow}
              {(target === 'PRODUCT' || target === 'MODEL') && (
                <Row>
                  <Col>
                    <label className="col-form-label">{target === 'PRODUCT' ? '상품' : '제품번호(바코드)'}</label>
                    <InputD
                      readOnly
                      placeholder="클릭시 검색 팝업"
                      className="read-only-should-click"
                      value={target === 'PRODUCT' ? crudObj?.productName : crudObj?.barcode || ''}
                      onClick={() => {
                        if (!crudObj?.partnerSeq) {
                          alert('판매사를 입력해주세요!');
                        } else {
                          target === 'PRODUCT'
                            ? setProductStockObj((prev) => {
                                return {
                                  ...prev,
                                  visible: true,
                                  partnerSeq: crudObj?.partnerSeq,
                                };
                              })
                            : setModelStockObj((prev) => {
                                return {
                                  ...prev,
                                  isRadio: true,
                                  visible: true,
                                  partnerSeq: crudObj?.partnerSeq,
                                };
                              });
                        }
                      }}
                    />
                  </Col>
                </Row>
              )}
              {target === 'GROUP' && addTypeObj?.data && (
                <div className="presenterGridBox">
                  <div className="grid-button-area">
                    <div id={`addRow`} className={`blue`} onClick={gridButtonhandler}>
                      +행추가
                    </div>
                    <div id={`deleteRow`} className={`red`} onClick={gridButtonhandler}>
                      -행삭제
                    </div>
                  </div>
                  <GridBox
                    gridRef={gridRef}
                    columns={columns}
                    gridProps={{
                      ...defaultGridProps,
                      editable: true,
                      showRowAllCheckBox: true,
                      showRowCheckColumn: true,
                      height: 300,
                    }}
                  />
                </div>
              )}
              {target === 'REGION' && !addTypeObj?.data && (
                <>
                  <Row>
                    <Col>
                      <label className="col-form-label">도서산간명</label>
                      <InputD
                        value={crudObj?.regionName || ''}
                        onChange={(e) => {
                          setCrudObj({
                            ...crudObj,
                            regionName: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : (
            <>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '48%' }}>
                  <Row>
                    <Col>
                      <label className="col-form-label">권역명(시/도)</label>
                      <SelectD
                        value={
                          regionSearchObj?.province
                            ? {
                                value: regionSearchObj?.province,
                                label: regionSearchObj?.province,
                              }
                            : null
                        }
                        onChange={(option) => {
                          const subCodes = MASTER_OPS?.[`${RAWDATA}PROVINCE`]?.filter((ele) => ele.code === (option as OptionItem).value)[0]?.subCodes;
                          setFilteredOptions(
                            subCodes?.map((ele) => {
                              return {
                                value: ele.code,
                                label: ele.codeName,
                              };
                            }),
                          );
                          setRegionSearchObj({
                            ...regionSearchObj,
                            province: (option as OptionItem).label,
                            city: null,
                          });
                        }}
                        options={MASTER_OPS?.PROVINCE}
                      />
                    </Col>
                    <Col>
                      <label className="col-form-label">그룹명(시/군/구)</label>
                      <SelectD
                        value={
                          regionSearchObj?.city
                            ? {
                                value: regionSearchObj?.city,
                                label: regionSearchObj?.city,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setRegionSearchObj({
                            ...regionSearchObj,
                            city: (option as OptionItem).label,
                          });
                        }}
                        options={filteredOptions}
                      />
                    </Col>
                    <Col>
                      <label className="col-form-label">그룹상세(읍/면/동)</label>
                      <InputD
                        value={regionSearchObj?.area || ''}
                        onChange={(e) => {
                          setRegionSearchObj({
                            ...regionSearchObj,
                            area: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col style={{ textAlign: 'left' }}>
                      <button
                        className="btn btn-search"
                        onClick={(e) => {
                          e.preventDefault();
                          fetchList(regionSearchObj);
                        }}
                      >
                        조회
                      </button>
                    </Col>
                  </Row>
                  <div style={{ marginTop: 10 }}>
                    <GridBox
                      gridRef={gridRef2}
                      columns={regionColumns}
                      gridProps={{
                        ...defaultGridProps,
                        enableDrag: true,
                        enableDrop: true,
                        dropToOthers: true,
                        showRowAllCheckBox: true,
                        showRowCheckColumn: true,
                        height: 500,
                      }}
                    />
                  </div>
                </div>
                <div className="grid2-wrapper" style={{ width: '4%', position: 'relative' }}>
                  <div className="grid-button-between" style={{ left: 8 }}>
                    <div className="button" id={'out'} onClick={regionDrag}>
                      ◀️ 삭제
                    </div>
                    <div className="button" id={'main'} onClick={regionDrag}>
                      추가 ▶️
                    </div>
                  </div>
                </div>
                <div style={{ width: '48%' }}>
                  {nodeRow}
                  <div style={{ marginTop: 10 }}>
                    <GridBox
                      gridRef={gridRef}
                      columns={regionColumns}
                      gridProps={{
                        ...defaultGridProps,
                        enableDrag: true,
                        enableDrop: true,
                        dropToOthers: true,
                        showRowAllCheckBox: true,
                        showRowCheckColumn: true,
                        height: 500,
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      }
    />
  );
};
