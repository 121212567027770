import { ReactNode, useMemo, useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';

// utils
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
// service
import { TransportStatementListDTOForUnload, TransportStatementSearchDTOForUnload } from '../../../_interface/warehouse';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { ISerialObj, SerialsPopup } from './component/serialPopup';
import { PagingListDTO } from 'interface/util';
import { PhotosPopup, IPhotoObj } from './component/photos/photosPopup';
import { ConfirmIpgo, IIpgoPrint } from '../../_print/confirmIpgo';
import { HaldangModal, IHaldangObj } from './component/haldangInfo';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const detailExcelGridRef = useRef<AUIGrid>();

  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();
  const [serialsPopupObj, setSerialsPopupObj] = useState<ISerialObj>();
  const [photoPopupObj, setPhotoPopupObj] = useState<IPhotoObj>();

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  const fetchPaging = async (searchObj: TransportStatementSearchDTOForUnload) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.warehouseAction(`linehaul/loading/unload/temp`, 'GET', searchObj, null, true))?.data as PagingListDTO<TransportStatementListDTOForUnload>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      ...defaultSearchFilter,
    },
    gridRef,
    fetchPaging,
  });

  const completeReceiving = async (data) => {
    if (data?.statusKr === '입고대기' || data?.statusKr === '입고중') {
      dispatch(setLoading('POST'));
      const rs = await serviceStore?.warehouseAction(`linehaul/loading/unload/complete/${data.transportStatementSeq}`, 'POST', null);
      if (rs.status === 200) {
        alert('간선입고 완료처리 되었습니다!');
        wrappedFetchList(data?.searchObj ? JSON.parse(data?.searchObj) : searchObj);
      }
      dispatch(setLoading(null));
    } else {
      alert('입고대기 혹은 입고중 상태의 제품을 간선입고완료 할 수 있습니다!');
    }
  };

  const getPhotos = (e) => {
    setPhotoPopupObj({
      //
      visible: true,
      title: '입고',
      mainSeq: 'transportStatementSeq',
      item: e.item,
      searchObj: e.item.searchObj,
    });
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'LINEHAULINEXCEL') {
      downloadExcel('main');
    } else if (id === 'LINEHAULINDETAILEXCEL') {
      downloadExcel('detail');
    } else {
      handleCheckItems(e);
    }
  };
  const handleCheckItems = (e) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      const id = e.target.id;
      if (id === 'LINEHAULCOMPLETE') {
        if (items?.length > 1) {
          alert('하나씩 완료처리 가능합니다');
        } else {
          completeReceiving(items[0]);
        }
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const getDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const gridButtonhandler = (e) => {
      const id = e.target.id;
      if (id === 'TRUNKRECEIVE_TAB_GANSUNDETAIL_EXCEL') {
        bottomGridRef?.current?.exportAsXlsx({ fileName: '간선입고(상세)' });
      }
    };
    const buttons = printFunctionToBtns(
      gridFunctions?.filter((ele) => ele.functionId === 'TRUNKRECEIVE_TAB_GANSUNDETAIL_EXCEL'),
      gridButtonhandler,
    );
    const searchDTO = {
      originalCenterCode: e.item.originalCenterCode,
      destinationCenterCode: e.item.destinationCenterCode,
      linehaulType: e.item.linehaulType,
      linehaulDate: e.item.linehaulDate,
      transportStatementSeq: e.item.transportStatementSeq,
      loadingStatementSeq: e.item.loadingStatementSeq,
      linehaulSeq: e.item.linehaulSeq,
    };
    const data = (await serviceStore?.warehouseAction(`linehaul/loading/unload/detail`, 'GET', searchDTO))?.data;
    const rows = data.map((row) => {
      console.log(row);
      return {
        ...row,
        e: JSON.stringify(e),
        searchObj: e.item.searchObj,
        statusKr: MASTER_OBJ?.TRANSPORT_STAT?.[row?.status],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],

        serialNumberForAvailable: row.serialNumberForAvailable ? JSON.stringify(row.serialNumberForAvailable) : null,
        serialNumberForDefected: row.serialNumberForDefected ? JSON.stringify(row.serialNumberForDefected) : null,
        serialNumberForEtc: row.serialNumberForEtc ? JSON.stringify(row.serialNumberForEtc) : null,
        serialNumberForAvailableLength: row.serialNumberForAvailable ? row.serialNumberForAvailable?.length : 0,
        serialNumberForDefectedLength: row.serialNumberForDefected ? row.serialNumberForDefected?.length : 0,
        serialNumberForEtcLength: row.serialNumberForEtc ? row.serialNumberForEtc?.length : 0,
      };
    });

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '간선입고 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          buttons,
          options: {
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            editable: true,
          },
        },
      ],
    });

    dispatch(setLoading(null));
  };
  const [printObj, setPrintObj] = useState<IIpgoPrint>();
  const printBalju = (e: IGrid.ButtonClickEvent) => {
    setPrintObj({
      visible: true,
      transportStatementSeq: e.item.transportStatementSeq,
    });
  };

  const popupSerials = (event: IGrid.ButtonClickEvent) => {
    const barcode = event.item.barcode;
    const model = event.item.model;

    const data =
      event.dataField === 'availableQuantity' ? event.item.serialNumberForAvailable : event.dataField === 'defectedQuantity' ? event.item.serialNumberForDefected : event.item.serialNumberForEtc;
    if (data) {
      setSerialsPopupObj({
        visible: true,
        title: model,
        bottomtitle: barcode,
        data,
      });
    } else {
      alert('수집된 시리얼 번호가 없습니다!');
    }
  };

  const labellingKr = (data: PagingListDTO<TransportStatementListDTOForUnload & any>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        linehaulTypeKr: MASTER_OBJ?.LINEHAUL_TYPE?.[row?.linehaulType],
        statusKr: MASTER_OBJ?.TRANSPORT_STAT?.[row?.status],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],
        originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.originalCenterCode],
        destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.destinationCenterCode],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction(type === 'main' ? 'linehaul/loading/unload/excel' : 'linehaul/loading/unload/list/excel/detail', 'GET', searchObj, null))
          ?.data as TransportStatementListDTOForUnload[],
      },
      true,
    );

    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '간선입고' });
    } else {
      detailExcelGridRef.current.setGridData(list);
      detailExcelGridRef.current.exportAsXlsx({ fileName: '간선입고(상세)' });
    }
    dispatch(setLoading(null));
  };

  const [haldangObj, setHaldangObj] = useState<IHaldangObj>();
  const getHaldangInfo = async (e) => {
    setHaldangObj({ visible: true, item: e.item });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: getDetail,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.TRUNKRECEIVE_TAB_GRID_SHOWDETAIL;
        },
      },
    },
    {
      headerText: '사진보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '사진보기',
        onClick: getPhotos,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.TRUNKRECEIVE_TAB_GRID_SHOWPIC;
        },
        visibleFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          if (item.transportStatementSeq) return true;
          return false;
        },
      },
    },
    {
      headerText: '확정명세',
      dataField: 'modelPrint',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '프린트',
        onClick: printBalju,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.TRUNKRECEIVE_TAB_GRID_CONFIRMPRINT;
        },
        visibleFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          if (item.transportStatementSeq) return true;
          return false;
        },
      },
    },
    {
      headerText: '할당정보',
      dataField: 'confirmPrint',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '할당정보',
        onClick: getHaldangInfo,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.TRUNKRECEIVE_TAB_GRID_HALDANG;
        },
      },
    },
    {
      headerText: '피킹지시번호',
      dataField: 'linehaulSeq',
    },
    {
      headerText: '출고지시번호',
      dataField: 'loadingStatementSeq',
    },
    {
      headerText: '운송번호',
      dataField: 'transportStatementSeq',
    },
    {
      headerText: '출고예정일',
      dataField: 'linehaulDate',
    },
    {
      headerText: '출차지시확정일',
      dataField: 'loadDate',
    },
    {
      headerText: '입고예정일',
      dataField: 'promiseUnloadDate',
    },
    {
      headerText: '출발창고',
      dataField: 'originalCenterCodeKr',
    },
    {
      headerText: '도착창고',
      dataField: 'destinationCenterCodeKr',
    },
    {
      headerText: '간선유형',
      dataField: 'linehaulTypeKr',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '입고예정수량(출차수량)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
        },
      ],
    },
    {
      headerText: '입고완료 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'unloadAvailableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'unloadDefectedQuantity',
        },
        {
          headerText: '기타',
          dataField: 'unloadEtcQuantity',
        },
      ],
    },
    {
      headerText: '미입고 수량(출차-로케이션지정)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
        },
        {
          headerText: '불용',
          dataField: 'defectedDiff',
        },
        {
          headerText: '기타',
          dataField: 'etcDiff',
        },
      ],
    },
    {
      headerText: '입고완료일',
      dataField: 'unloadDatetime',
    },
    {
      headerText: '입고마감확정자',
      dataField: 'unloadId',
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '운송번호',
      dataField: 'transportStatementSeq',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },

        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '입고로케이션',
          dataField: 'locationCode',
        },
      ],
    },
    {
      headerText: '입고예정수량(출차수량)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: popupSerials,
          },
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: popupSerials,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: popupSerials,
          },
        },
      ],
    },
    {
      headerText: '입고완료 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'unloadAvailableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'unloadDefectedQuantity',
        },
        {
          headerText: '기타',
          dataField: 'unloadEtcQuantity',
        },
      ],
    },
    {
      headerText: '미입고 수량(출차-로케이션지정)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
        },
        {
          headerText: '불용',
          dataField: 'defectedDiff',
        },
        {
          headerText: '기타',
          dataField: 'etcDiff',
        },
      ],
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '입고완료일',
      dataField: 'unloadDatetime',
    },
    {
      headerText: '입고완료자',
      dataField: 'unloadId',
    },
  ];

  return (
    <div className="page-content">
      {printObj?.visible && <ConfirmIpgo printObj={printObj} setPrintObj={setPrintObj} />}
      {haldangObj?.visible && <HaldangModal haldangObj={haldangObj} setHaldangObj={setHaldangObj} />}
      {photoPopupObj?.visible && <PhotosPopup photoPopupObj={photoPopupObj} setPhotoPopupObj={setPhotoPopupObj} />}
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      {serialsPopupObj?.visible && <SerialsPopup serialsPopupObj={serialsPopupObj} setSerialsPopupObj={setSerialsPopupObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            editable: true,
            fixedColumnCount: 4,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns.filter((_, idx) => idx > 3)} />
        <PrintGridBox gridRef={detailExcelGridRef} columns={detailColumns} />
      </div>
    </div>
  );
};

export default Index;
