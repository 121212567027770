import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import useToast from 'hooks/useToast';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { RootState } from 'redux/store';
import { numberThousandComma } from 'common/util/counting';
import { setLoading } from 'redux/services/menuSlice';
import { ChangeModal, IChangeObj } from './component/changeObj';
import { ParcelInvoiceCollectUserDTO } from '../../../_interface/parcel';
import { serviceStore } from 'services/services';
import { regExpNotNumber } from 'common/util/regExp';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [inputs, setInputs] = useState<ParcelInvoiceCollectUserDTO>();
  const [todayList, setTodayList] = useState<{ realSize: number; list: ParcelInvoiceCollectUserDTO[] }>();
  const successPlayer = useRef<HTMLAudioElement>();
  const boxCheckPlayer = useRef<HTMLAudioElement>();
  const failurePlayer = useRef<HTMLAudioElement>();
  const validatePlayer = useRef<HTMLAudioElement>();
  const [checked, setChecked] = useState<number[]>();
  const currentMode = localStorage.getItem('modeInTask') || 'ABNORMAL';
  const [mode, setMode] = useState<string>(currentMode);
  const { Toast, displayToast, hideToast, setColor, setMsg } = useToast({
    message: '',
    ms: 3000,
  });

  const scanInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    getTempToday(mode);
    scanInputRef?.current?.focus();
  }, [mode]);

  const boxCheckHandler = (boxSize = null) => {
    if (BOXBARCODE_CONVERTER[boxSize] !== inputs?.boxSize) boxCheckPlayer?.current?.play();
    else failurePlayer?.current?.play();

    setColor('green');
    setMsg(`박스감지 : ${MASTER_OBJ?.CARGO_BOX_SIZE?.[boxSize]}`);
    displayToast();
    if (boxSize) {
      setInputs((prev) => {
        return {
          ...prev,
          boxSize: BOXBARCODE_CONVERTER[boxSize],
          houseNumber: null,
        };
      });
    }
  };

  const scanRight = (str = 'HBL이 등록되었습니다.', data: ParcelInvoiceCollectUserDTO) => {
    if (data) addTempToday(data);
    else getTempToday(mode);

    successPlayer.current.play();
    setColor('green');
    setMsg(str);
    displayToast();
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        houseNumber: null,
        subNumber: null,
        price: 0,
      };
    });
    scanInputRef?.current?.focus();
  };

  const reset = () => {
    hideToast();
    getTempToday(mode);
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        houseNumber: null,
        subNumber: null,
        partnerSeq: null,
        price: 0,
        parcelType: null,
        boxSize: mode === 'ABNORMAL' ? null : 'XS',
      };
    });

    scanInputRef?.current?.focus();
  };

  const scanError = (str = '중복된 HBL입니다.') => {
    if (str?.includes('12자리~13자리') || str?.includes('UNIPASS 조회에 실패하였습니다')) validatePlayer.current.play();
    else failurePlayer.current.play();
    setColor('red');
    setMsg(str);
    displayToast();
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        houseNumber: null,
      };
    });
    scanInputRef?.current?.focus();
  };

  const validation = (inputs: ParcelInvoiceCollectUserDTO) => {
    if (mode === 'SMALL') {
      if (!inputs?.parcelType) {
        alert('택배사는 필수값입니다!!');
        return false;
      }
      if (!inputs?.houseNumber) {
        alert('HBL은 필수값입니다!');
        return false;
      }

      if (!inputs?.boxSize) {
        alert('박스사이즈는 필수값입니다!');
        return false;
      }
    } else {
      if (!inputs?.houseNumber) {
        alert('HBL은 필수값입니다!');
        return false;
      }
    }
    return true;
  };

  const postSaveTemp = async () => {
    if (validation(inputs)) {
      const dataDTO = {
        ...inputs,
        price: inputs?.price || 0, // 표기* 1000 !important 제거 (241004 백원단위 입력 가능토록 변경)
        packageType: mode,
      };
      delete dataDTO['number'];
      const rs = await serviceStore.parcelAction(`invoice/collect`, 'POST', null, dataDTO);
      if (rs?.status === 200) {
        scanRight(rs?.data?.message, inputs);
      } else {
        scanError(rs?.data?.detailMessage || rs?.data?.message);
      }
    }
  };

  const deleteTask = async (checked) => {
    if (window.confirm('삭제하시겠습니까?')) {
      const rs = await serviceStore.parcelAction(
        `invoice/collect/cancel`,
        'POST',
        null,
        checked?.map((ele) => {
          return {
            seq: ele,
          };
        }),
      );
      if (rs?.status === 200) {
        scanRight('삭제되었습니다', null);
      }
    }
  };

  const getTempToday = async (mode) => {
    dispatch(setLoading('GET'));
    const { data } = await serviceStore?.parcelAction('invoice/collect/user', 'GET', { packageType: mode }, null);
    if (data) setTodayList({ list: data?.filter((ele) => ele.useFlag) as ParcelInvoiceCollectUserDTO[], realSize: data?.length });
    setChecked([]);
    dispatch(setLoading(null));
  };

  const addTempToday = (data: ParcelInvoiceCollectUserDTO) => {
    dispatch(setLoading('GET'));
    const _todayList = JSON.parse(JSON.stringify(todayList));

    const realSize = todayList?.realSize + 1;
    const list = JSON.parse(JSON.stringify(_todayList?.list));
    list?.unshift({
      ...data,
      number: realSize,
      price: inputs?.price || 0, // price * 1000제거 (241004 백원단위 입력 가능토록 변경)
    });
    setTodayList({ list, realSize });
    setChecked([]);
    dispatch(setLoading(null));
  };

  const [changeObj, setChangeObj] = useState<IChangeObj>();
  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (checked?.length > 0) {
      if (id?.includes('change-task-info')) {
        setChangeObj({
          visible: true,
          checked,
          mode,
          fetchFn: (mode) => getTempToday(mode),
        });
      } else if (id?.includes('delete')) {
        deleteTask(checked);
      }
    } else {
      alert('선택된 것이 없습니다');
    }
  };

  const changeMode = (mode) => {
    localStorage.setItem('modeInTask', mode);
    setInputs(null);
    hideToast();
    scanInputRef.current.value = '';
    setMode(mode);
  };

  const BOXBARCODE = ['BOX_A', 'BOX_B', 'BOX_C', 'BOX_D'];
  const BOXBARCODE_CONVERTER = { BOX_A: 'XS', BOX_B: 'S', BOX_C: 'M', BOX_D: 'L' };

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    if (mode === 'SMALL') {
      if (inputs?.boxSize && inputs?.houseNumber && inputs?.parcelType) postSaveTemp();
    } else {
      if (inputs?.houseNumber) postSaveTemp();
    }
  };
  return (
    <div className="page-content">
      {changeObj?.visible && <ChangeModal changeObj={changeObj} setChangeObj={setChangeObj} />}
      <div style={{ maxWidth: 500, marginBottom: 200 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 50 }}>
          <span>
            <button
              onClick={(e) => {
                e.preventDefault();
                reset();
              }}
            >
              새로고침
            </button>
          </span>
          <div className="toggle-area">
            <div
              className={`element ${mode === 'SMALL' ? 'active' : ''}`}
              onClick={() => {
                changeMode('SMALL');
              }}
            >
              소형
            </div>
            <div
              className={`element ${mode === 'ABNORMAL' ? 'active' : ''}`}
              onClick={() => {
                changeMode('ABNORMAL');
              }}
            >
              이형
            </div>
          </div>
        </div>
        <div style={{ position: 'relative', height: 50, marginBottom: 12 }}>
          <Toast />
        </div>
        <Form onSubmit={handleSubmit}>
          {mode !== 'SMALL' ? (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">*메인(HBL)</label>
                  <input
                    ref={scanInputRef}
                    value={inputs?.houseNumber || ''}
                    className="form-control backgroundYellow"
                    placeholder="HBL을 입력하세요"
                    onChange={(e) => {
                      setInputs((prev) => {
                        return {
                          ...prev,
                          houseNumber: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">서브(SBL)</label>
                  <InputD
                    placeholder="SBL을 입력하세요"
                    value={inputs?.subNumber || ''}
                    onChange={(e) => {
                      setInputs((prev) => {
                        return {
                          ...prev,
                          subNumber: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">운임비</label>
                  {inputs?.price > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        backgroundColor: 'white',
                        left: 16,
                        top: 22,
                      }}
                    >
                      {numberThousandComma(inputs?.price)}
                    </div>
                  )}
                  <InputD
                    type="number"
                    placeholder="운임비를 입력하세요."
                    value={inputs?.price || ''}
                    onChange={(e) => {
                      const value = e.target.value.replace(regExpNotNumber, '');
                      setInputs((prev) => {
                        return {
                          ...prev,
                          price: Number(value),
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">*택배사</label>
                  <SelectD
                    menuPlacement="top"
                    hasNull={false}
                    placeholder="택배사를 선택하세요"
                    value={
                      inputs?.parcelType
                        ? {
                            value: inputs?.parcelType,
                            label: MASTER_OBJ?.PARCEL_TYPE?.[inputs?.parcelType],
                          }
                        : null
                    }
                    options={MASTER_OPS?.PARCEL_TYPE}
                    onChange={(options) => {
                      setInputs((prev) => {
                        return {
                          ...prev,
                          parcelType: (options as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">*크기</label>
                  <SelectD
                    menuPlacement="top"
                    hasNull={false}
                    isDisabled
                    placeholder="크기를 선택하세요"
                    value={
                      inputs?.boxSize
                        ? {
                            value: inputs?.boxSize,
                            label: MASTER_OBJ?.CARGO_BOX_SIZE?.[inputs?.boxSize],
                          }
                        : null
                    }
                    options={MASTER_OPS?.CARGO_BOX_SIZE}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">*메인(HBL)</label>
                  <input
                    ref={scanInputRef}
                    value={inputs?.houseNumber || ''}
                    className="form-control backgroundYellow"
                    placeholder="HBL을 입력하세요"
                    onChange={(e) => {
                      if (BOXBARCODE?.includes(e.target.value)) {
                        boxCheckHandler(e.target.value);
                      } else {
                        setInputs((prev) => {
                          return {
                            ...prev,
                            houseNumber: e.target.value,
                          };
                        });
                      }
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (mode === 'SMALL') {
                    if (inputs?.boxSize && inputs?.houseNumber && inputs?.parcelType) postSaveTemp();
                  } else {
                    if (inputs?.houseNumber) postSaveTemp();
                  }
                }}
                style={{ width: '100%', marginTop: 10 }}
                className="btn btn-secondary big"
              >
                집화입력
              </button>
            </Col>
          </Row>
        </Form>
        <div className="grid-button-area space-between" style={{ marginTop: 30 }}>
          <div>
            <div id={`change-task-info`} className={`orange`} onClick={gridButtonhandler}>
              변경
            </div>
            <div id={`delete`} className={`red`} onClick={gridButtonhandler}>
              삭제
            </div>
          </div>
          <div>
            <div className={`transparent`} style={{ color: 'gray', maxWidth: 'unset' }}>
              방금 집화한 내역은 새로고침 후 변경/삭제 가능
            </div>
          </div>
        </div>
        <table className="border-table noBorder fontSize10" style={{ fontSize: 10, marginTop: 0 }}>
          {mode === 'ABNORMAL' ? (
            <thead>
              <tr>
                <th>
                  <input
                    style={{ marginTop: 4 }}
                    type="checkbox"
                    disabled={todayList?.list?.filter((ele) => ele?.stage === '미정')?.length === 0}
                    checked={checked?.length === todayList?.list?.filter((ele) => ele?.stage === '미정')?.length}
                    onChange={() => {
                      if (checked?.length === todayList?.list?.filter((ele) => ele?.stage === '미정')?.length) {
                        setChecked([]);
                      } else {
                        setChecked(todayList?.list?.filter((ele) => ele?.stage === '미정')?.map((ele) => ele?.parcelInvoiceCollectSeq));
                      }
                    }}
                  />
                </th>
                <th>순번</th>
                <th colSpan={5}>
                  HBL
                  <br />
                  (SBL)
                </th>
                <th colSpan={3}>포워더</th>
                <th colSpan={2}>운임비</th>
              </tr>
            </thead>
          ) : (
            <thead>
              <tr>
                <th>
                  <input
                    style={{ marginTop: 4 }}
                    type="checkbox"
                    disabled={todayList?.list?.length === 0}
                    checked={checked?.length === todayList?.list?.length}
                    onChange={() => {
                      if (checked?.length === todayList?.list?.length) {
                        setChecked([]);
                      } else {
                        setChecked(todayList?.list?.map((ele) => ele?.parcelInvoiceCollectSeq));
                      }
                    }}
                  />
                </th>
                <th colSpan={2}>순번</th>
                <th colSpan={2}>HBL</th>
                <th colSpan={2}>포워더</th>
                <th colSpan={2}>택배사</th>
                <th colSpan={2}>크기</th>
              </tr>
            </thead>
          )}
        </table>
        {mode === 'ABNORMAL' ? (
          <table className="border-table noBorder">
            <tbody>
              {todayList?.list?.map((ele, idx) => {
                return (
                  <tr key={`today_${idx}`}>
                    <th>
                      <input
                        style={{ marginTop: 4 }}
                        type="checkbox"
                        disabled={ele?.stage !== '미정'}
                        checked={checked?.includes(ele?.parcelInvoiceCollectSeq) || false}
                        onChange={() => {
                          if (checked?.includes(ele?.parcelInvoiceCollectSeq)) {
                            setChecked(checked?.filter((check) => check !== ele?.parcelInvoiceCollectSeq));
                          } else {
                            setChecked(checked?.concat(ele?.parcelInvoiceCollectSeq) || [ele?.parcelInvoiceCollectSeq]);
                          }
                        }}
                      />
                    </th>
                    <th>{ele.number}</th>
                    <th colSpan={5}>
                      {ele.houseNumber}
                      <br />
                      {ele.subNumber && `(${ele?.subNumber})`}
                    </th>
                    <th colSpan={3}>{MASTER_OBJ?.SELLER_FORWARDER_WHOLE?.[ele?.partnerSeq]}</th>
                    <th colSpan={2}>{numberThousandComma(ele.price)}</th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div style={{ maxHeight: 500, overflow: 'scroll' }}>
            {todayList?.list?.map((ele, idx) => {
              return (
                <div
                  key={`sm_package_${idx}`}
                  className={checked?.includes(ele?.parcelInvoiceCollectSeq) || false ? 'li-btn active' : 'li-btn'}
                  onClick={() => {
                    if (checked?.includes(ele?.parcelInvoiceCollectSeq)) {
                      setChecked(checked?.filter((check) => check !== ele?.parcelInvoiceCollectSeq));
                    } else {
                      setChecked(checked?.concat(ele?.parcelInvoiceCollectSeq) || [ele?.parcelInvoiceCollectSeq]);
                    }
                  }}
                >
                  <div style={{ padding: '3px' }}>
                    <input
                      style={{ marginTop: 4 }}
                      type="checkbox"
                      checked={checked?.includes(ele?.parcelInvoiceCollectSeq) || false}
                      onChange={() => {
                        if (checked?.includes(ele?.parcelInvoiceCollectSeq)) {
                          setChecked(checked?.filter((check) => check !== ele?.parcelInvoiceCollectSeq));
                        } else {
                          setChecked(checked?.concat(ele?.parcelInvoiceCollectSeq) || [ele?.parcelInvoiceCollectSeq]);
                        }
                      }}
                    />
                  </div>
                  <div style={{ width: '87%', paddingLeft: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className="badge-number">{ele?.number}</span>
                      <span>{ele?.houseNumber}</span>
                    </div>
                    <div>{MASTER_OBJ?.SELLER_FORWARDER_WHOLE?.[ele?.partnerSeq] | MASTER_OBJ?.PARCEL_TYPE?.[ele?.parcelType]}</div>
                  </div>
                  <div className="b box-size">{MASTER_OBJ?.CARGO_BOX_SIZE?.[ele?.boxSize]}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="audioWrapper">
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_sound.mp3" ref={successPlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_sound.mp3" ref={boxCheckPlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_fail_sound.mp3" ref={failurePlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/surprise-sound.mp3" ref={validatePlayer} controls></audio>
      </div>
    </div>
  );
};

export default Index;
