import { useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './list';
import Tab2 from './load';
import Tab3 from './unload';

// options
import { nullingMASTER } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';

export const MANAGE_PARCEL_LINEHAUL_LIST_MASTER = [
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'CENTER_DIFF',
  //
  'PLINE_STATUS',
  'PLINE_UNLOAD_TYPE',
  //
  'CAR_TYPE',
  {
    SEARCH_DATE: [
      { value: 'ConfirmDate', label: '출차일' },
      { value: 'UnloadDate', label: '하차완료일' },
    ],
  },
];

const Index = () => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_LINEHAUL_LIST_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PARCEL_LINEHAUL_LIST: <Tab1 tabId={'PARCEL_LINEHAUL_LIST'} />,
            PARCEL_LINEHAUL_LOAD: <Tab2 tabId={'PARCEL_LINEHAUL_LOAD'} />,
            PARCEL_LINEHAUL_UNLOAD: <Tab3 tabId={'PARCEL_LINEHAUL_UNLOAD'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
