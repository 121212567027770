import { ReactNode, useMemo, useRef, useState } from 'react';

// utils
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';

import { ParcelInvoiceCollectDTO, ParcelInvoiceCollectQuantityDTO, ParcelInvoiceCollectSearchDTO } from '../../../_interface/parcel';
import { serviceStore } from 'services/services';

// hooks
import { PagingListDTO } from 'interface/util';
import { getToday } from 'common/util/dateParsingFn';
import { detectMobileDevice } from 'common/util/detectDevice';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';

//component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';
import PrintGridBox from 'common/grid/printGridBox';

const Index = ({ tabId }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const isMobile = detectMobileDevice(window.navigator.userAgent);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const [fetchCount, setFetchCount] = useState<ParcelInvoiceCollectQuantityDTO>();

  const deleteThisTask = async (e: IGrid.ButtonClickEvent) => {
    if (window.confirm('해당 집화내역을 삭제하시겠습니까?')) {
      const rs = await serviceStore.parcelAction(`invoice/collect/cancel`, 'PUT', null, [{ seq: e.item.parcelInvoiceCollectSeq }]);
      if (rs?.status === 200) {
        alert('삭제되었습니다');
        wrappedFetchList(searchObj);
      }
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '등록일시',
      dataField: 'registerDatetime',
    },
    {
      headerText: '등록자',
      dataField: 'registerId',
    },
    {
      headerText: '스캔순번',
      dataField: 'number',
    },
    {
      headerText: '포워더',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '양륙항',
      dataField: 'portCodeKr',
    },
    {
      headerText: '택배사',
      dataField: 'parcelTypeKr',
    },
    {
      headerText: 'HBL',
      dataField: 'houseNumber',
    },
    {
      headerText: 'SBL',
      dataField: 'subNumber',
    },
    {
      headerText: '운임비',
      dataField: 'price',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '차수',
      dataField: 'stage',
    },
    {
      headerText: '크기',
      dataField: 'boxSizeKr',
    },
    {
      headerText: '삭제',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '삭제',
        onClick: deleteThisTask,
      },
    },
  ];

  const labellingKr = (data: PagingListDTO<ParcelInvoiceCollectDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_FORWARDER_WHOLE?.[row?.partnerSeq],
        boxSizeKr: row.packageType === 'ABNORMAL' ? '이형' : MASTER_OBJ?.CARGO_BOX_SIZE?.[row?.boxSize],
        portCodeKr: MASTER_OBJ?.PORT_CODE?.[row?.portCode],
        parcelTypeKr: MASTER_OBJ?.PARCEL_TYPE?.[row?.parcelType],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchTotal = async (searchObj: ParcelInvoiceCollectSearchDTO) => {
    const total = (await serviceStore.parcelAction('invoice/collect/quantity', 'GET', searchObj, null, true))?.data;
    setFetchCount(total as ParcelInvoiceCollectQuantityDTO);
    return total;
  };

  const fetchPaging = async (searchObj: ParcelInvoiceCollectSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.parcelAction('invoice/collect/paging', 'GET', searchObj, null, true))?.data as PagingListDTO<ParcelInvoiceCollectDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 500,
      fromRegisterDate: getToday(),
      toRegisterDate: getToday(),
    },
    gridRef,
    fetchTotal,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: (await serviceStore.parcelAction('invoice/collect/excel', 'GET', searchObj))?.data as ParcelInvoiceCollectDTO[] }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `집화내역_${searchObj?.fromRegisterDate}-${searchObj?.toRegisterDate}` });

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'SAVE_CARGO_MANIFEST_TEMP') {
      //
      saveCargo();
    } else if (id === 'CARGO_TASK_EXCEL_DOWNLOAD') {
      downloadExcel('main');
    }
  };
  const saveCargo = async () => {
    if (window.confirm('전체 집화내역을 전송하시겠습니까?')) {
      dispatch(setLoading('GET'));

      const rs = await serviceStore.parcelAction(`invoice/collect/confirm`, 'POST', null, null);
      if (rs?.status === 200) {
        alert('전송되었습니다');
        wrappedFetchList(searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content" style={{ paddingTop: isMobile ? 0 : '3rem' }}>
      <div className="presenterSearch">
        <SearchBox fetchCount={fetchCount} searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            pageRowCount: 500,
            pageRowSelectValues: [500],
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
