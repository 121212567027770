import styled from 'styled-components';
import { AsideContainer, LogoImage } from '../Index';
import { COLORS, TRANSITIONS } from 'common/constants/appearance';
import { LDSTagline, LDSLabelSM } from 'components/LDS/atoms/Typography';

import HFSBG from 'assets/images/bg1.png';
import LOGIMATE_NEW_LOGO_WHITE from 'assets/images/new-logo-light.png';

import { DESCRIPTIONS } from 'common/constants/localization';
import Divider from '../../../atoms/Divider';
import { LDSSpacing } from 'components/LDS/atoms/Spacing';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import React from 'react';

function Aside() {
  const [showTagline, setShowTagline] = useState(false);

  let accumulatedLength = 0;
  const tagline = DESCRIPTIONS.LOGIN_TAGLINE_EN;
  const taglineRowMap = tagline.split('\n');
  const taglineCharacterLength = tagline
    .split('\n')
    .map((row) => row.length)
    .map((length) => {
      return (accumulatedLength += length);
    });

  useEffect(() => {
    setTimeout(() => {
      setShowTagline(true);
    }, 1100);
  }, []);

  return (
    <AsideContainer $width={35} $maxWidth={540} className="aside">
      <BackGroundImage className="background-image" src={HFSBG} alt={'BG'} />
      <DimLayer className="dim" />

      <TagLineContents>
        <LogoImage className="logo" src={LOGIMATE_NEW_LOGO_WHITE} alt={'new logo'} onClick={() => setShowTagline(!showTagline)} />
        <LDSSpacing $direction="V" $value={40} />
        <Divider $direction="H" $value={'FULL'} $color={COLORS.GRAY_04_DEEP} />
        <LDSSpacing $direction="V" $value={36} />
        <TagLineWrapper>
          {taglineRowMap.map((row, index1) => (
            <GeneratedHeading index={index1} data-order-row={index1} key={`${row}_${index1}`}>
              {row.split('').map((character, index2) => (
                <Character
                  $trigger={showTagline ? true : false}
                  key={`${character}_${index2}`}
                  data-order-cha={index2}
                  $rowIndex={index1}
                  $chaIndex={index2}
                  $beforeLowLength={index1 > 0 ? taglineCharacterLength[index1 - 1] : 0}
                  $lang={tagline.includes('대형물류') ? 'KR' : 'EN'}
                  style={{ paddingRight: character === ' ' ? '0.25em' : '0.2px', marginLeft: index1 === 0 && index2 === 1 && character === 'e' ? '-2px' : '0' }}
                >
                  {character}
                </Character>
              ))}
            </GeneratedHeading>
          ))}
        </TagLineWrapper>
        <LDSSpacing $direction="V" $value={160} />
      </TagLineContents>

      <Copyrights />
    </AsideContainer>
  );
}

export const Copyrights = ({ $width = 'FULL', $color = COLORS.GRAY_04_DEEP }: { $width?: number | 'FULL'; $color?: string }) => {
  return (
    <CopyrightsContainer>
      <Divider $direction="H" $value={$width} $color={$color} />
      <LDSSpacing $direction="V" $value={36} />
      <LDSLabelSM>
        <span>© 2021-2024</span>
        <LDSSpacing $direction="H" $value={4} />
        <Link to={'http://www.ftf.co.kr/'} target={'_blank'}>
          ​Logimate
        </Link>
      </LDSLabelSM>
    </CopyrightsContainer>
  );
};

export default React.memo(Aside);

const CopyrightsContainer = styled.div`
  z-index: 1;

  label {
    display: flex;
    color: ${COLORS.GRAY_03};
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    a {
      text-decoration: underline !important;
      color: inherit;
    }
  }
`;

const BackGroundImage = styled.img`
  bottom: 0;
  right: -40vw;
  position: absolute;
  height: 100vh;

  transform: translateX(-10vw);
  min-height: 768px;

  transition: all 450ms ${TRANSITIONS.SMOOTHY};

  @keyframes zoom {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  animation-name: zoom;
  animation-duration: 60s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
`;

const DimLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.5) 100%);
`;

const TagLineContents = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-self: end;
`;

const TagLineWrapper = styled.div`
  user-select: none;
  will-change: transform;
`;

type GenHeadingProp = {
  index: number;
};

const GeneratedHeading = styled(LDSTagline)<GenHeadingProp>`
  color: ${COLORS.WHITE};
  min-width: 400px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  display: flex;
`;

type CharacterProp = {
  $rowIndex: number;
  $chaIndex: number;
  $beforeLowLength: number;
  $trigger: boolean;
  $lang: 'KR' | 'EN';
};

const Character = styled.span<CharacterProp>`
  display: inline-block;
  transform-origin: center 8px;
  transform: translateY(${(props) => (props.$trigger ? 0 : 10 / (props.$chaIndex === 0 ? 1 : props.$chaIndex) + 48)}px);
  transition: all ${(props) => props.$rowIndex * 100 + props.$chaIndex * 16 + 200}ms
    ${(props) => props.$beforeLowLength * (props.$lang === 'KR' ? 60 : 24) + props.$chaIndex * (props.$lang === 'KR' ? 48 : 32)}ms ${TRANSITIONS.SMOOTHY};
`;
