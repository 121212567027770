import { KeyboardEvent } from 'react';
import { Col, Row, Form } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { PartnerVendorSearchDTO } from 'pages/ETC/3user/_interface/user';

export const SearchBox = (props: ISearchBoxProps<PartnerVendorSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">거래처명</label>
          <InputD
            value={searchObj?.partnerName || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                partnerName: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">거래처코드</label>
          <InputD
            value={searchObj?.externalPartnerCode || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                externalPartnerCode: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">운영상태</label>
          <SelectD
            value={
              searchObj?.status
                ? {
                    value: searchObj?.status,
                    label: MASTER_OBJ?.ACTIVE_OP?.[searchObj?.status],
                  }
                : null
            }
            options={MASTER_OPS?.ACTIVE_OP}
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                status: value,
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
