import { lazy } from 'react';

const lazyRetry = (componentImport) =>
  lazy(async () => {
    const hasRefreshed = JSON.parse(window.localStorage.getItem('retry-lazy-refreshed') || 'false');

    try {
      const component = await componentImport();
      window.localStorage.setItem('retry-lazy-refreshed', 'false');
      return component;
    } catch (error) {
      if (!hasRefreshed) {
        window.localStorage.setItem('retry-lazy-refreshed', 'true');
        return window.location.reload();
      }
      console.log(error);
    }
  });

export { lazyRetry };
