import { ReactNode, useMemo, useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { setLoading } from 'redux/services/menuSlice';
import { useDispatch } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { DetailPopup, IDetailObj } from './component/detailPopup';
import { sellerTypesToStr } from 'common/master/codeMasterReturnHelper';
import { PagingListDTO } from 'interface/util';
import AUIGrid from 'modules/Grid';
import PrintGridBox from 'common/grid/printGridBox';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { serviceStore } from 'services/services';
import { PartnerDTO, PartnerSearchDTO } from '../../_interface/user';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const excelGridRef = useRef<AUIGrid>();
  const [detailObj, setDetailObj] = useState<IDetailObj>();
  const gridRef = useRef<IPagingGrid>();

  const labellingKr = (data: PagingListDTO<PartnerDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerTypeKr: sellerTypesToStr({ supplier: row.supplierFlag, seller: row.sellerFlag, network: row.networkFlag, transport: row.transportFlag, forwarder: row.forwarderFlag }),
        statusKr: row.useFlag === true ? '사용' : '미사용',
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: PartnerSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.userAction(`partner/list/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<PartnerDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const openDetail = async ({ item }) => {
    setDetailObj((prev) => {
      return {
        ...prev,
        visible: true,
        item,
        searchObj,
        fetchFn: wrappedFetchList,
      };
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      dataField: 'detail',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: openDetail,
      },
    },
    {
      headerText: 'seq',
      dataField: 'partnerSeq',
      width: 50,
    },
    {
      headerText: '파트너명',
      dataField: 'partnerName',
    },
    {
      headerText: '파트너타입',
      dataField: 'partnerTypeKr',
    },
    {
      headerText: '운영상태',
      dataField: 'statusKr',
    },
    {
      headerText: '사업자번호',
      dataField: 'businessNumber',
    },
    {
      headerText: '대표자명',
      dataField: 'managerName',
    },
    {
      headerText: '담당자',
      dataField: 'managerName',
    },
  ];

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'PARTNERREGISTER') {
      setDetailObj({
        visible: true,
        item: null,
        searchObj,
        fetchFn: wrappedFetchList,
      });
    } else if (id === 'PARTNER_EXCELDOWN') {
      downloadExcel('main');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.userAction(`partner/list/excel`, 'GET', searchObj, null))?.data as PartnerDTO[],
      },
      true,
    );
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `파트너관리` });
    dispatch(setLoading(null));
  };

  return (
    <div className="page-content">
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox gridRef={gridRef} columns={columns} gridProps={{ showRowAllCheckBox: false, showRowCheckColumn: false, fixedColumnCount: 1 }} />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
