export const numberTestRegExp = (value) => {
  const regExp = /^[0-9]*$/;
  if (regExp.test(value)) return true;
  else return false;
};

export const numberFloatTestRegExp = (value) => {
  const regExp = /^[0-9.]*$/;
  if (regExp.test(value)) return true;
  else return false;
};

export const stringLengthTestRegExp = (value, length) => {
  const regExp = new RegExp(`/^[0-9a-zA-Z]{0,${length}}$/`);
  if (regExp.test(value)) return true;
  return false;
};

export const toCamelCase = (str: string) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const stringValueToNumber = (value: string) => {
  let currentValue = value.replace(regExpNotNumber, '');
  const firstCharacter = currentValue.charAt(0);
  currentValue = currentValue?.length > 1 && firstCharacter === '0' ? currentValue.slice(1, currentValue.length) : currentValue;
  return currentValue;
};

// 특수문자 체크 정규식
// const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;

// 모든 공백 체크 정규식
// const regExp = /\s/g;

// 숫자만 체크 정규식
// const regExp = /[0-9]/g;

// 숫자가 아닌 것만 체크 정규식
export const regExpNotNumber = /[^0-9]/g;

// 이메일 체크 정규식
// const regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

// 핸드폰번호 정규식
// const regExp = /^\d{3}-\d{3,4}-\d{4}$/;

// 일반 연락처 정규식
// const regExp = /^\d{2,3}-\d{3,4}-\d{4}$/;

// 아이디나 비밀번호 정규식
// const regExp = /^[a-z0-9_]{4,20}$/;

// 휴대폰번호 체크 정규식
// const regExp = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
