import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNullAuth } from 'redux/services/authSlice';
import LDSListGroup, { LDSEmptyListGroup } from '../modules/ListGroup';
import LDSListHeader from '../modules/ListHeader';
import LDSListItem from '../modules/ListItem';
import Notification from './Notification';

import { FiArchive, FiSearch, FiType, FiX } from 'react-icons/fi';
import { HiOutlineHome, HiStar } from 'react-icons/hi';
import {
  LuListFilter,
  LuPackage2,
  LuBarChart4,
  LuTruck,
  LuCircleDollarSign,
  LuLayoutGrid,
  LuArrowDownLeft,
  LuArrowUpRight,
  LuClipboardList,
  LuBell,
  LuUserCircle,
  LuGlobe,
  LuLogOut,
  LuLayout,
  LuSettings,
  LuFolderTree,
} from 'react-icons/lu';

import styled from 'styled-components';
import { COLORS, TRANSITIONS, VALUES } from 'common/constants/appearance';
import LDSProfileGroup from '../modules/ProfileGroup';
import LDSDivider from '../atoms/Divider';

import logo from 'assets/images/new-logo.png';
import { MENUS } from 'common/constants/localization';
import LDSButton from '../atoms/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { USER_SIGN_OUT } from 'envVar';
import { setShowDropdown } from 'redux/services/portalSlice';
import { RootState } from 'redux/store';
import { setBookmarkUpdated, setOpenOrcloseSide } from 'redux/services/menuSlice';
import React from 'react';
import { handleNewDesignFlag } from 'pages/_HOME/home';
import { setLocale } from 'redux/services/localeSlice';
import { clearLocalStorageData } from 'common/util/localStorage';
import { LDSInput } from '../atoms/InputField';
import { debounce } from 'lodash';
import LDSTwoDepthMenuList from './Sidebar.2depthList';
import LDSThreeDepthMenuList from './Sidebar.3depthList';
import { useNotification } from './Notification.hooks';
import { staticMenu, useSidebarMenus } from './Sidebar.hooks';

export type SidebarProps = {
  $isCollapsed?: boolean;
  setGongjiObj?: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      announcementSeq: number;
    }>
  >;
  setUserPopObj?: React.Dispatch<React.SetStateAction<{ userId?: string }>>;
};

export type MenusChildProps = {
  menuName: string;
  url: string;
  menuType: string;
};

function LDSSidebar({ $isCollapsed = false, setGongjiObj, setUserPopObj }: SidebarProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const { openOrclose, bookmarkUpdated } = useSelector((state: RootState) => state.menu);
  const { locale } = useSelector((state: RootState) => state.locale);

  const [collapsed, setCollapsed] = useState(!openOrclose.side);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const unUseMiddleMenuValue = JSON.parse(localStorage.getItem('unUseMiddleMenu'));
  const middleMenu = unUseMiddleMenuValue ? '2_DEPTH_MENUS' : '3_DEPTH_MENUS';

  const { alarms, fetchAlarms } = useNotification(setGongjiObj);
  const { menus, setMenus, flatMenus, getMenuByUserInfo, handleMenuDepthType, expandedMenuNames, setExpandedMenuNames, generateCenterValues, centerValues } = useSidebarMenus(middleMenu);

  const screenUrl = location.pathname?.split('/')?.[2];

  useEffect(() => {
    fetchAlarms();
    generateCenterValues();
  }, []);

  useEffect(() => {
    getMenuByUserInfo();
    bookmarkUpdated && dispatch(setBookmarkUpdated(false));
  }, [bookmarkUpdated]);

  const fetchLogout = async () => {
    const rs = await axios.post(process.env.REACT_APP_API_URL + USER_SIGN_OUT, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
      },
    });

    if (rs?.status === 200) {
      dispatch(setNullAuth());
      dispatch(setShowDropdown(''));
      alert('로그아웃 되었습니다!');
      clearLocalStorageData();

      navigate('/login');
    }
  };

  const setLanguageSetting = (setLocaleValue: 'ko' | 'en' | 'zh') => {
    dispatch(setShowDropdown(''));
    if (locale.value !== setLocaleValue) dispatch(setLocale(setLocaleValue));
  };

  const handleMenuSearch = (option?: string) => {
    if (option === '') {
      setSearchKeyword('');
      setSearchResults([]);
      return;
    }
    const childrenMenus = flatMenus
      .map((menu) => {
        let childrens = [];
        if (menu.menuName !== MENUS.FAVORITE_KR && menu.menuName !== MENUS.HOME_KR) childrens = menu.children;
        return childrens;
      })
      .flat(1);

    const filteredMenus = childrenMenus?.filter((menu) => {
      return menu?.menuName?.toUpperCase().includes(option.toUpperCase());
    });

    setSearchResults(filteredMenus);
  };

  return (
    <LDSSidebarStyle $isCollapsed={collapsed}>
      <LDSProfileGroup
        $border="BOTTOM"
        $profile={{
          profileImageUrl: logo,
        }}
        $status={collapsed ? 'COLLAPSED' : 'EXPAND'}
        $type="BRAND"
        onClick={() => {
          dispatch(setOpenOrcloseSide(openOrclose));
          localStorage.setItem('SidebarIsOpen', JSON.stringify(!openOrclose.side));
          setCollapsed(!collapsed);
        }}
        $tooltip={{ $description: `사이드바 ${!collapsed ? '접기' : '펼치기'}`, $direction: !collapsed ? 'BOTTOM' : 'RIGHT', $anchorPosition: 'CENTER' }}
        $imageLink={'/'}
        children={
          <LDSButton
            $primary={false}
            $size={'SM'}
            $type={'BUTTON'}
            $style={'TRANSPARENT'}
            $showIcon={true}
            $icon={<LuSettings />}
            $tooltip={{ $description: `사이드바 설정`, $direction: !collapsed ? 'BOTTOM' : 'RIGHT', $anchorPosition: 'CENTER' }}
            $dropdown={{
              $position: !collapsed ? 'BOTTOM' : 'RIGHT',
              $align: !collapsed ? 'LEFT' : 'TOP',
              $width: 200,
              children: (
                <>
                  <LDSListGroup $gap={0} $padding={0}>
                    <LDSListHeader $title="사이드바 설정" $type="OPTION" />
                    <LDSListItem $hasDepth $icon={<LuFolderTree />} $label="중메뉴 표시" $type="ACTION">
                      <LDSListGroup $gap={0} $padding={0}>
                        <LDSListHeader $title="옵션" $type="OPTION" />
                        <LDSListItem $label="중메뉴 사용" $type="SELECT" $status={middleMenu === '3_DEPTH_MENUS' ? 'SELECTED' : 'DEFAULT'} onClick={() => handleMenuDepthType('false')} />
                        <LDSListItem $label="중메뉴 사용 안함" $type="SELECT" $status={middleMenu === '3_DEPTH_MENUS' ? 'DEFAULT' : 'SELECTED'} onClick={() => handleMenuDepthType('true')} />
                      </LDSListGroup>
                    </LDSListItem>
                  </LDSListGroup>
                </>
              ),
            }}
          />
        }
      ></LDSProfileGroup>
      <MenuList $isCollapsed={collapsed}>
        {!collapsed && (
          <>
            <LDSListGroup $padding={8} $gap={4}>
              <LDSButton
                $type={'BUTTON'}
                $primary={false}
                $size={'MD'}
                $style={'FILL'}
                $label={'이전 디자인으로 돌아가기'}
                $showIcon={true}
                $icon={<LuLayout />}
                onClick={() => handleNewDesignFlag(0)}
              />
            </LDSListGroup>
            <LDSDivider $direction={'H'} $color={COLORS.GRAY_03} $value={'FULL'} $spacing={'SM'} />
          </>
        )}
        {/* Sidebar가 펼침 상태일 때 */}
        {/* 대시보드, 알림 */}
        {!collapsed &&
          staticMenu?.map((menu, index) => (
            <Fragment key={`${menu?.menuName}_${index}`}>
              <LDSListGroup $padding={8} $gap={4} data-group-id={`${menu?.menuName}_${index}`}>
                {menu?.children?.map((child: MenusChildProps, index: number) => (
                  <Fragment key={`${child?.menuName}_${index}`}>
                    <LDSListItem
                      key={child?.menuType + child?.menuName + index}
                      $type={child?.menuType === 'MENU_ACTION' ? 'MENU_ACTION' : 'MENU'}
                      $label={child?.menuName}
                      $icon={menuIcon[child?.menuName]}
                      $status={location.pathname.includes(`/main${child?.url}`) ? 'ACTIVE' : 'DEFAULT'}
                      $hasDepth={child?.menuName === MENUS.NOTIFICATION_KR ? true : false}
                      $badgeCount={child?.menuName !== MENUS.NOTIFICATION_KR ? null : alarms?.unreadCount > 9 ? '9+' : String(alarms?.unreadCount || '')}
                      onClick={() => child?.menuName !== MENUS.NOTIFICATION_KR && navigate(`/main${child?.url}`)}
                      $navigate={`/main${child?.url}`}
                      $dropdown={
                        child.menuName === MENUS.NOTIFICATION_KR && {
                          $position: 'RIGHT',
                          $align: 'TOP',
                          $width: 280,
                          $maxHeight: VALUES.NOTIFICATION_MIN_HEIGHT,
                          children: <Notification setGongjiObj={setGongjiObj} />,
                        }
                      }
                    />
                  </Fragment>
                ))}
              </LDSListGroup>
            </Fragment>
          ))}

        {/* 메뉴 검색 필드 */}
        {!collapsed && (
          <>
            <LDSDivider $direction={'H'} $color={COLORS.GRAY_03} $value={'FULL'} $spacing={'SM'} />
            <div style={{ top: '-4px', position: 'sticky', zIndex: 2, backgroundColor: COLORS.GRAY_01 }}>
              <LDSListGroup $padding={8} $gap={4}>
                <SearchInputWrapper
                  className={searchKeyword.length > 0 ? 'has-keyword' : null}
                  onSubmit={(e?: React.FormEvent) => {
                    e.preventDefault();
                    setSearchKeyword(searchKeyword);
                  }}
                >
                  <SearchInput
                    type={'text'}
                    placeholder={'검색할 메뉴 입력...'}
                    value={searchKeyword}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                      const handleSearchKeyword = debounce(() => {
                        handleMenuSearch(e.target.value);
                      }, VALUES.SEARCH_THROTTLE_BUFFER);
                      handleSearchKeyword();
                    }}
                  />
                  <FiSearch size={18} />
                  {searchKeyword && (
                    <LDSButton
                      $type="BUTTON"
                      $primary={false}
                      $isCircled={true}
                      $style="FILL"
                      $size="XS"
                      $icon={<FiX />}
                      $showIcon={true}
                      onClick={() => {
                        handleMenuSearch('');
                      }}
                    />
                  )}
                </SearchInputWrapper>
              </LDSListGroup>
            </div>
            <LDSDivider $direction={'H'} $color={COLORS.GRAY_03} $value={'FULL'} $spacing={'SM'} />
          </>
        )}
        {/* 검색결과가 있을 때 */}
        {!collapsed && searchKeyword.length > 0 && (
          <LDSListGroup $padding={8} $gap={4}>
            <LDSListHeader $type={'OPTION'} $title={'메뉴 검색결과'} $icon={menuIcon['SEARCH_KR']} />
            {searchResults.length > 0 ? (
              searchResults?.map((child: MenusChildProps, index: number) => (
                <Fragment key={`${child?.menuName}_${index}`}>
                  <LDSListItem
                    key={child?.menuType + child?.menuName + index}
                    $type={child?.menuType === 'MENU_ACTION' ? 'MENU_ACTION' : 'MENU'}
                    $label={child?.menuName}
                    $icon={menuIcon[child?.menuName]}
                    $status={screenUrl === `${child?.url?.replace('/', '')}` ? 'ACTIVE' : 'DEFAULT'}
                    $hasDepth={child?.menuName === MENUS.NOTIFICATION_KR ? true : false}
                    $badgeCount={child?.menuName !== MENUS.NOTIFICATION_KR ? null : alarms?.unreadCount > 9 ? '9+' : String(alarms?.unreadCount || '')}
                    onClick={() => child?.menuName !== MENUS.NOTIFICATION_KR && navigate(`/main${child.url}`)}
                    autoFocus={screenUrl === `${child?.url?.replace('/', '')}` ? true : false}
                    $navigate={`/main${child?.url}`}
                  />
                </Fragment>
              ))
            ) : (
              <LDSEmptyListGroup style={{ backgroundColor: 'transparent' }}>검색 결과가 없습니다.</LDSEmptyListGroup>
            )}
          </LDSListGroup>
        )}
        {middleMenu === '2_DEPTH_MENUS' && (
          <LDSTwoDepthMenuList
            collapsed={collapsed}
            hideMenus={searchKeyword.length === 0 && searchResults?.length === 0 ? false : true}
            menus={menus}
            setMenus={setMenus}
            expandedMenuNames={expandedMenuNames}
            setExpandedMenuNames={setExpandedMenuNames}
            alarms={alarms}
            setGongjiObj={setGongjiObj}
          />
        )}
        {middleMenu === '3_DEPTH_MENUS' && (
          <LDSThreeDepthMenuList
            collapsed={collapsed}
            hideMenus={searchKeyword.length === 0 && searchResults?.length === 0 ? false : true}
            menus={menus}
            setMenus={setMenus}
            expandedMenuNames={expandedMenuNames}
            setExpandedMenuNames={setExpandedMenuNames}
            alarms={alarms}
            setGongjiObj={setGongjiObj}
          />
        )}
      </MenuList>
      <LDSProfileGroup
        $border="TOP"
        $profile={{
          username: reduxUserInfo?.name,
          organization: centerValues?.[reduxUserInfo?.centerCode], // NOTE: 계정 정보 업데이트 시 소속 센터 변경 시 centerCodes가 아닌 centerCode가 변경됨.
        }}
        $status={collapsed ? 'COLLAPSED' : 'EXPAND'}
        $type="USER"
        $tooltip={{ $description: '계정', $direction: !collapsed ? 'TOP' : 'RIGHT', $anchorPosition: 'CENTER' }}
        $dropdown={{
          $position: 'RIGHT',
          $align: 'BOTTOM',
          $width: 200,
          children: (
            <>
              <LDSListGroup $gap={0} $padding={0}>
                <LDSListHeader $title="계정" $type="OPTION" />
                <LDSListItem
                  $icon={<LuUserCircle />}
                  $label="개인정보 수정"
                  $type="ACTION"
                  onClick={() => {
                    dispatch(setShowDropdown(''));
                    setUserPopObj({
                      userId: reduxUserInfo?.sub,
                    });
                  }}
                />
                {location.pathname.includes('manageorderInfoList') && (
                  <LDSListItem $hasDepth $icon={<LuGlobe />} $label="언어 설정" $type="ACTION">
                    <LDSListGroup $gap={0} $padding={0}>
                      <LDSListHeader $title="언어" $type="OPTION" />
                      <LDSListItem $label="한국어" $type="SELECT" $status={locale?.value === 'ko' ? 'SELECTED' : 'DEFAULT'} onClick={() => setLanguageSetting('ko')} />
                      <LDSListItem $label="English" $type="SELECT" $status={locale?.value === 'en' ? 'SELECTED' : 'DEFAULT'} onClick={() => setLanguageSetting('en')} />
                      <LDSListItem $label="中文" $type="SELECT" $status={locale?.value === 'zh' ? 'SELECTED' : 'DEFAULT'} onClick={() => setLanguageSetting('zh')} />
                    </LDSListGroup>
                  </LDSListItem>
                )}
                {/* TODO: 크기 조정 옵션 구현 예정 <LDSListItem $hasDepth $icon={<FiType />} $label="크기 조정" $type="ACTION">
                  <LDSListGroup $gap={0} $padding={0}>
                    <LDSListHeader $title="크기" $type="OPTION" />
                    <LDSListItem $label="작게" $type="SELECT" $status={locale?.value === 'en' ? 'SELECTED' : 'DEFAULT'} onClick={() => setLanguageSetting('ko')} />
                    <LDSListItem $label="보통" $type="SELECT" $status={locale?.value === 'ko' ? 'SELECTED' : 'DEFAULT'} onClick={() => setLanguageSetting('en')} />
                    <LDSListItem $label="크게" $type="SELECT" $status={locale?.value === 'zh' ? 'SELECTED' : 'DEFAULT'} onClick={() => setLanguageSetting('zh')} />
                  </LDSListGroup>
                </LDSListItem> */}
              </LDSListGroup>
              <LDSDivider $color={COLORS.GRAY_03} $direction={'H'} $spacing={'SM'} $value={'FULL'} />
              <LDSListItem $icon={<LuLogOut />} $label="로그아웃" $type="ACTION" onClick={fetchLogout} />
            </>
          ),
        }}
      />
    </LDSSidebarStyle>
  );
}

export default React.memo(LDSSidebar);

const LDSSidebarStyle = styled.aside<SidebarProps>`
  width: ${(props) => (props.$isCollapsed ? VALUES.SIDEBAR_COLLAPSED : VALUES.SIDEBAR_EXPANDED)}px;
  flex-shrink: 0;
  transition: width ${VALUES.SIDEBAR_TRANSITION_TIME}ms ${TRANSITIONS.SMOOTHY};

  background-color: ${COLORS.GRAY_01};
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div {
    flex-shrink: 0;
  }
`;

const MenuList = styled.section<SidebarProps>`
  overflow: hidden;
  overflow-y: auto;
  flex-grow: 1;
  padding: ${(props) => (props.$isCollapsed ? '8px 0' : '2px 0')};

  & ul {
    align-items: ${(props) => (props.$isCollapsed ? 'flex-start' : 'normal')};
    padding-left: ${(props) => (props.$isCollapsed ? 12 : 8)}px;
    padding-right: ${(props) => (props.$isCollapsed ? 12 : 8)}px;

    div {
      position: relative;
    }

    &.favorite div {
      color: ${COLORS.GRAY_05};
    }

    * {
      user-select: none;
    }
  }

  & > div {
    width: auto;
    &:last-child {
      display: none;
    }
  }
`;

const SearchInputWrapper = styled.form`
  position: relative;
  margin: 0 4px;
  & > svg {
    position: absolute;
    left: 11px;
    top: 10px;
    color: ${COLORS.GRAY_05};
    transition: all 200ms ${TRANSITIONS.BEZIER};
  }
  & > button {
    position: absolute;
    right: 2px;
    top: 6px;
    background-color: ${COLORS.BLACK_008};
    &:hover {
      background-color: ${COLORS.BLACK_015};
    }
    &:focus {
      background-color: ${COLORS.BLACK_020};
    }
  }
  &.has-keyword > svg {
    color: ${COLORS.GRAY_06} !important;
  }
`;
const SearchInput = styled(LDSInput)`
  background-color: transparent;
  padding: 9px 42px 9px 36px;
  font-size: 13px !important;
  line-height: 20px;
  border-width: 0 0 1px;
  border-radius: 5px;

  &::placeholder {
    color: ${COLORS.GRAY_03_DEEP};
    font-size: 13px !important;
  }

  transition: all 200ms ${TRANSITIONS.BEZIER};

  background-color: ${COLORS.GRAY_02_DEEP};

  &:hover {
    background-color: ${COLORS.GRAY_03};
    & + svg {
      color: ${COLORS.GRAY_05};
    }
  }
  &:focus {
    background-color: ${COLORS.GRAY_03};
    & + svg {
      color: ${COLORS.GRAY_06};
    }
  }
`;

export const menuIcon = {
  [MENUS.CARGO_KR]: <LuListFilter />, // 통관
  [MENUS.PARCEL_KR]: <LuPackage2 />, // 택배
  [MENUS.IPGO_KR]: <LuArrowDownLeft />, //입고
  [MENUS.OUT_KR]: <LuArrowUpRight />, // 출고
  [MENUS.INVENTORY_KR]: <FiArchive />, // 재고
  [MENUS.ORDER_KR]: <LuClipboardList />, // 주문
  [MENUS.DELIVERY_KR]: <LuTruck />, // 배송
  [MENUS.STATISTICS_KR]: <LuBarChart4 />, // 통계
  [MENUS.FEE_KR]: <LuCircleDollarSign />, // 정산
  [MENUS.ETC_KR]: <LuLayoutGrid />, // 기타
  [MENUS.DASHBOARD_KR]: <HiOutlineHome />, // 대시보드
  [MENUS.NOTIFICATION_KR]: <LuBell />, // 알림
  [MENUS.FAVORITE_KR]: <HiStar />, // 즐겨찾기
  [MENUS.SEARCH_KR]: <FiSearch />, // 즐겨찾기
};
