import { httpClient } from 'common/http-client/axiosConfig';
import { MY_BOOKMARK, MY_RECENTVISIT } from 'envVar';

export const getMyRecentVisits = async () => {
  return await httpClient.get(MY_RECENTVISIT).then((res) => {
    if (res?.status === 200) {
      return res?.data;
    }
    return [];
  });
};

export const getMyBookmarks = async () => {
  return await httpClient.get(MY_BOOKMARK).then((res) => {
    if (res?.status === 200) {
      return res?.data;
    }
    return [];
  });
};
