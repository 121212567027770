import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';
import { CARGO } from 'envVar';
import { CargoManifestDTO, CargoManifestQuantityDTO, CargoManifestSearchDTO } from 'interface/order';

import { PagingListDTO, ResponseDTO } from 'interface/util';

class CargoService {
  // 통관목록
  public async getCargoListQuantity(searchDTO: CargoManifestSearchDTO): Promise<CargoManifestQuantityDTO> {
    let url = CARGO('/quantity') + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getCargorListPaging(searchDTO: CargoManifestSearchDTO): Promise<PagingListDTO<CargoManifestDTO>> {
    let url = CARGO('/paging') + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getOrderListMainExcel(searchDTO: CargoManifestSearchDTO): Promise<CargoManifestDTO[]> {
    let url = CARGO('/excel') + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getOrderListDetailExcel(searchDTO: CargoManifestSearchDTO): Promise<CargoManifestDTO[]> {
    let url = CARGO('/detail/excel') + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getCargoDetail(cargoManifestSeq: number, action: string): Promise<any> {
    let url = CARGO(`/${action}`) + `?cargoManifestSeq=${cargoManifestSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postCargoDetail(action: string, dataDTO: any): Promise<any> {
    let url = CARGO(`/${action}`);
    const { data } = await httpClient.post(url, dataDTO);
    return data;
  }

  public async getOrderCargoActionPaging(action: string, searchDTO: any): Promise<any> {
    let url = CARGO(`/${action}/paging`) + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getOrderCargoAction(action: string, searchDTO: any): Promise<any> {
    let url = CARGO(`/${action}`) + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async postOrderCargoAction(action: string, dataDTO: any): Promise<ResponseDTO> {
    let url = CARGO(`/${action}`);
    const rs = await httpClient.post(url, dataDTO);

    return rs;
  }
}

export const cargoService = new CargoService();
