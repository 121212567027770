import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { LocationInOutSearchDTO } from 'interface/warehouse';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { locationService } from '../../_services/service';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);

  const handleCheckItems = (e) => {
    const id = e.target.id;
    if (id === 'LOCATIONINOUTEXCEL') {
      downloadExcel('main');
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '일자',
      dataField: 'inOutDate',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '입고수량',
      children: [
        {
          headerText: '양품',
          dataField: 'inAvailableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'inDefectedQuantity',
        },
      ],
    },
    {
      headerText: '출고수량',
      children: [
        {
          headerText: '양품',
          dataField: 'outAvailableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'outDefectedQuantity',
        },
      ],
    },
  ];

  const labellingKr = (data: PagingListDTO<LocationInOutSearchDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: LocationInOutSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await locationService.getWMSAction('location/inOut/paging', searchObj, true));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: await locationService.getWMSAction('location/inOut/excel', searchObj, false) });
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '로케이션_입출고_내역' });
    dispatch(setLoading(null));
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, handleCheckItems);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            editable: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
