import { COLORS } from 'common/constants/appearance';
import { stringValueToNumber } from 'common/util/regExp';
import { validateSearchFilters } from 'common/util/searchBox';
import { LDSLabelSM, LDSLabelXXS } from 'components/LDS/atoms/Typography';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Form, Row } from 'reactstrap';
import { setLoading } from 'redux/services/menuSlice';
import { serviceStore } from 'services/services';
import styled from 'styled-components';

type CalculationInputType = {
  width?: string; //가로
  depth?: string; //세로
  height?: string; //높이
  weight?: string; //무게
};

type ResultType = {
  [key: string]: number | null;
};

const Index = (tabId) => {
  const defaultResults = [{ weightPrice: null }, { volumePrice: null }, { price: null }];
  const [inputs, setInputs] = useState<CalculationInputType>({});
  const [results, setResults] = useState([...defaultResults]);
  const dispatch = useDispatch();

  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();

    const validation = validateSearchFilters(
      { name: '가로', value: inputs?.width },
      { name: '세로', value: inputs?.depth },
      { name: '높이', value: inputs?.height },
      { name: '무게', value: inputs?.weight },
    );

    if (validation) {
      dispatch(setLoading('GET'));
      const rs = await serviceStore.parcelAction(`invoice/standard/price`, 'POST', null, inputs);
      if (rs?.status === 200) {
        console.log(rs.data);

        setResults(() => {
          return [{ weightPrice: rs?.data.weightPrice }, { volumePrice: rs?.data.volumePrice }, { price: rs?.data.price }];
        });
      }
      dispatch(setLoading(null));
    }
  };

  return (
    <div className="page-content">
      <div style={{ maxWidth: 500, marginBottom: 200 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 50 }}>
          <span>
            <button
              onClick={(e) => {
                e.preventDefault();
                setInputs({});
                setResults([...defaultResults]);
              }}
            >
              초기화
            </button>
          </span>
        </div>
        <div style={{ position: 'relative', height: 50, marginBottom: 12 }}></div>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <label className="col-form-label">*가로(cm)</label>
              <input
                value={inputs?.width || ''}
                className="form-control backgroundYellow"
                placeholder="가로 사이즈를 입력하세요 (cm)"
                onChange={(e) => {
                  const formattedValue = stringValueToNumber(e?.target?.value);
                  setInputs((prev) => {
                    return {
                      ...prev,
                      width: formattedValue,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*세로(cm)</label>
              <input
                value={inputs?.depth || ''}
                className="form-control backgroundYellow"
                placeholder="세로 사이즈를 입력하세요 (cm)"
                onChange={(e) => {
                  const formattedValue = stringValueToNumber(e?.target?.value);
                  setInputs((prev) => {
                    return {
                      ...prev,
                      depth: formattedValue,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*높이(cm)</label>
              <input
                value={inputs?.height || ''}
                className="form-control backgroundYellow"
                placeholder="높이 사이즈를 입력하세요 (cm)"
                onChange={(e) => {
                  const formattedValue = stringValueToNumber(e?.target?.value);
                  setInputs((prev) => {
                    return {
                      ...prev,
                      height: formattedValue,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*중량(kg)</label>
              <input
                value={inputs?.weight || ''}
                className="form-control backgroundYellow"
                placeholder="중량을 입력하세요 (kg)"
                onChange={(e) => {
                  const formattedValue = stringValueToNumber(e?.target?.value);
                  setInputs((prev) => {
                    return {
                      ...prev,
                      weight: formattedValue,
                    };
                  });
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <button type="submit" style={{ width: '100%', marginTop: 10 }} className="btn btn-secondary big">
                계산하기
              </button>
            </Col>
          </Row>
        </Form>
        <>
          <div style={{ borderTop: `1px solid ${COLORS.GRAY_03}`, marginTop: 24, paddingTop: 24, marginBottom: 8, display: 'flex', alignItems: 'center', gap: 4 }}>
            <LDSLabelSM style={{ fontWeight: 700 }}>예상 운임비</LDSLabelSM>
            <LDSLabelXXS style={{ justifySelf: 'flex-end', color: COLORS.GRAY_05_DEEP }}>*vat는 별도</LDSLabelXXS>
          </div>

          <CalculateResultsBox>
            {results?.map((result: ResultType, index) => {
              const price = Object.keys(result)?.[0];
              return (
                <Fragment key={`${result}_${index}`}>
                  <li>
                    {price === 'volumePrice' ? '부피 운임' : price === 'weightPrice' ? '무게 운임' : '고객이 지급할 운임'} :{' '}
                    <span className={!result[price] ? 'price null' : 'price'}>{result[price]?.toLocaleString() ?? '-'}</span>
                  </li>
                  {result[price] && price === 'price' && (
                    <>
                      <div style={{ borderTop: `1px solid ${COLORS.GRAY_03_DEEP}`, marginTop: 8, paddingBottom: 8 }}></div>
                      <span className={'discount'}>
                        {'10% 할인 가격'} : <span className="price">{(result[price] * 0.9)?.toLocaleString()}</span>
                      </span>
                    </>
                  )}
                </Fragment>
              );
            })}
          </CalculateResultsBox>
        </>
      </div>
    </div>
  );
};

export default Index;

const CalculateResultsBox = styled.div`
  padding: 12px 20px;
  border-radius: 5px;
  background-color: ${COLORS.GRAY_01_DEEP};
  font-size: 14px;
  line-height: 22px;

  .price {
    &:not(.null) {
      font-weight: 700;
      color: ${COLORS.GRAY_08};
      &:after {
        content: '원';
        font-weight: 500;
        text-decoration: none;
      }
    }
  }

  li {
    list-style: none;
    color: ${COLORS.GRAY_06};

    &:before {
      content: '•';
      padding-right: 4px;
      margin-left: -2px;
    }
  }

  .discount {
    font-weight: 600;
    color: ${COLORS.GRAY_08};
    font-size: 16px;
    line-height: 28px;

    &:before {
      content: '✓';
      padding-right: 4px;
    }
    .price {
      padding: 1px 0;
      border-bottom: 1px solid;
    }
  }
`;
