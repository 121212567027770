import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging, { initialSearchObj } from 'hooks/grid/useGridPaging';
import { SearchBox } from './component/searchBox';
import { Types, ITypeObj } from './component/types';
import { PagingListDTO } from 'interface/util';
import { WarehouseFeeInDTO, WarehouseFeeInSearchDTO, WarehouseFeeOutDTO, WarehouseFeeOutSearchDTO, WarehouseFeeStorageDTO, WarehouseFeeStorageSearchDTO } from '../../_interface/warehouse';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { serviceStore } from 'services/services';

export type IFeeSearch = WarehouseFeeInSearchDTO & WarehouseFeeOutSearchDTO & WarehouseFeeStorageSearchDTO;

export const urlConvertor = {
  BASICFEE_IN_TAB_CENTER: 'in',
  BASICFEE_OUT_TAB_CENTER: 'out',
  BASICFEE_STORAGE_TAB_CENTER: 'storage',
};

export const seqConvertor = {
  BASICFEE_IN_TAB_CENTER: 'warehouseFeeInSeqs',
  BASICFEE_OUT_TAB_CENTER: 'warehouseFeeOutSeqs',
  BASICFEE_STORAGE_TAB_CENTER: 'warehouseFeeStorageSeqs',
};
const Index = ({ tabId }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const [typeObj, setTypeObj] = useState<ITypeObj>();

  const popupType = (e: IGrid.ButtonClickEvent) => {
    setTypeObj({
      visible: true,
      data: e.item,
      tabId,
      searchObj: searchObj,
      fetchFn: wrappedFetchList,
    });
  };

  const inColumns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupType,
      },
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      editable: false,
    },
    {
      headerText: '창고명',
      dataField: 'centerCodeKr',
      editable: false,
    },
    {
      headerText: '운송유형',
      dataField: 'logisticTypeKr',
      editable: false,
    },
    {
      headerText: '작업유형',
      dataField: 'workTypeKr',
    },
    {
      headerText: '금액',
      dataField: 'fee',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
  ];

  const outColumns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupType,
      },
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      editable: false,
    },
    {
      headerText: '창고명',
      dataField: 'centerCodeKr',
      editable: false,
    },
    {
      headerText: '작업유형',
      dataField: 'workTypeKr',
    },
    {
      headerText: '파렛트유형',
      dataField: 'palletTypeKr',
      editable: false,
    },
    {
      headerText: '금액',
      dataField: 'fee',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
  ];

  const storageColumns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupType,
      },
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      editable: false,
    },
    {
      headerText: '창고명',
      dataField: 'centerCodeKr',
      editable: false,
    },
    {
      headerText: '로케이션구분',
      dataField: 'locationCategoryKr',
      editable: false,
    },
    {
      headerText: '로케이션규격',
      dataField: 'locationSizeKr',
      editable: false,
    },
    {
      headerText: '적용단위',
      dataField: 'dateUnitKr',
      editable: false,
    },
    {
      headerText: '월기준일',
      dataField: 'day',
      editable: false,
    },
    {
      headerText: '금액',
      dataField: 'fee',
      style: 'sub-total-color',
      dataType: 'numeric',
      formatString: '#,##0',
    },
  ];

  const labellingKr = (data: PagingListDTO<WarehouseFeeInDTO & WarehouseFeeOutDTO & WarehouseFeeStorageDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        //
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        logisticTypeKr: MASTER_OBJ?.LOGISTIC_TYPE?.[row.logisticType],
        workTypeKr: MASTER_OBJ?.WORK_TYPE?.[row.workType],
        palletTypeKr: MASTER_OBJ?.PALLET_TYPE?.[row.palletType],
        dateUnitKr: MASTER_OBJ?.DATE_UNIT?.[row.dateUnit],
        locationCategoryKr: MASTER_OBJ?.LOCATION_CATEGORY?.[row.locationCategory],
        locationSizeKr: MASTER_OBJ?.LOCATION_SIZE?.[row.locationSize],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: IFeeSearch) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore?.warehouseAction(`fee/center/${urlConvertor[tabId]}/paging`, 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };
  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj,
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: (await serviceStore?.warehouseAction(`fee/center/${urlConvertor[tabId]}/excel`, 'GET', searchObj))?.data }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `${tabId?.includes('_IN_') ? '입고금액' : tabId?.includes('_OUT_') ? '출고금액' : '보관금액'}` });
    dispatch(setLoading(null));
  };

  const deleteFee = async (items) => {
    if (window.confirm('정말로 삭제하시곘습니까?')) {
      const rs = await serviceStore.warehouseAction(
        `fee/center/${urlConvertor[tabId]}/delete`,
        'POST',
        null,
        items?.map((ele) => {
          return {
            [seqConvertor[tabId]]: ele?.[seqConvertor[tabId]],
          };
        }),
      );

      if (rs?.status === 200) {
        alert('삭제되었습니다!');
        wrappedFetchList(searchObj);
      }
    }
  };

  const saveFee = async (items) => {
    const rs = await serviceStore.warehouseAction(`fee/center/${urlConvertor[tabId]}/save`, 'POST', null, items);
    if (rs?.status === 200) {
      alert('저장되었습니다');
      wrappedFetchList(searchObj);
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id?.includes('EXCELDOWN_')) {
      downloadExcel('main');
    } else if (id?.includes('ADDTYPE_')) {
      setTypeObj({
        visible: true,
        data: null,
        tabId,
        searchObj: searchObj,
        fetchFn: wrappedFetchList,
      });
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id?.includes('SAVEFEE_')) {
        saveFee(items);
      } else if (id?.includes('DELETE')) {
        deleteFee(items);
      }
    } else {
      alert('선택된 건이 없습니다!');
    }
  };

  useEffect(() => {
    if (tabId) {
      setSearchObj(initialSearchObj);
      gridRef?.current?.setGridData([]);
      excelGridRef?.current?.setGridData([]);
      gridRef?.current?.changeColumnLayout(tabId?.includes('_IN_') ? inColumns : tabId?.includes('_OUT_') ? outColumns : storageColumns);
      excelGridRef?.current?.changeColumnLayout(tabId?.includes('_IN_') ? inColumns : tabId?.includes('_OUT_') ? outColumns : storageColumns);
    }
  }, [tabId]);

  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj, tabId]);

  return (
    <div className="page-content">
      {typeObj?.visible && <Types typeObj={typeObj} setTypeObj={setTypeObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} TARGET={tabId} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={inColumns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            editable: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={inColumns} />
      </div>
    </div>
  );
};

export default Index;
