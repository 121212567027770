import { ReactNode, useMemo, useRef, useState } from 'react';
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';

import AUIGrid from 'modules/Grid';
// service
import { inventoryService } from '../../_service/service';

import * as IGrid from 'aui-grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import { PagingListDTO } from 'interface/util';
import { InventoryLiveDefectedDTO, InventoryLiveSearchDTO } from 'interface/warehouse';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { IInfoDetailObj, InfoDetail } from './component/infoDetail';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { InventoryReportsCommonDefaultSearchFilters } from '..';
import useSearchGridPagingNew from 'hooks/grid/useGridPagingNew';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();
  const [infoDetailObj, setInfoDetailObj] = useState<IInfoDetailObj>();
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const searchObj = useSelector((state: RootState) => state.tab)?.searchObj?.[tabId];
  const defaultSearchFilter = useMemo(() => {
    return {
      ...InventoryReportsCommonDefaultSearchFilters,
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    };
  }, [reduxUserInfo]);

  const fetchTotal = async (searchObj: InventoryLiveSearchDTO) => {
    const total = await inventoryService.getInventoryDefectedTotal(searchObj);
    return total;
  };

  const labellingKr = (data: PagingListDTO<InventoryLiveDefectedDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: row.centerCode ? selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE) : '전체',
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
        modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryLiveSearchDTO) => {
    dispatch(setLoading('GET'));
    setBottomSheetObj(null);
    const dataKr = labellingKr(await inventoryService.getInventoryLiveDefectedListPaging(searchObj));

    dispatch(setLoading(null));
    return dataKr;
  };

  const { initFetch, handleSearchObj } = useSearchGridPagingNew({
    tabId,
    gridRef,
    fetchTotal,
    fetchPaging,
    initialSearchObj: {
      ...defaultSearchFilter,
      ...searchObj,
    },
  });

  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    //
    dispatch(setLoading('GET'));
    const searchObj = JSON.parse(e.item.searchObj);

    const gridButtonhandler = (e) => {
      const id = e.target.id;
      if (id === 'INVENTORY_DEF_BOTTOM_EXCELDOWN') {
        bottomGridRef.current.exportAsXlsx({ fileName: '실시간 불용 재고현황(상세)' });
      }
    };
    const buttons = printFunctionToBtns(
      gridFunctions?.filter((ele) => !ele.functionId?.includes('SHOW')),
      gridButtonhandler,
    );
    const data = await inventoryService.getInventoryDefectedDetail(e.item.modelStockSeq, searchObj);
    const rows = data?.map((row) => {
      return {
        ...row,
        searchObj: JSON.stringify(searchObj),
        centerCodeKr: row.centerCode ? selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE) : '전체',
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
        modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
      };
    });

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '실시간 불용 재고현황 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          buttons,
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
      ],
    });
    dispatch(setLoading(null));
  };

  const modalOpenAction = async (e: IGrid.ButtonClickEvent) => {
    setInfoDetailObj({ visible: true, e });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetail,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.INVENTORY_DEF_SHOWDETAIL;
        },
      },
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품타입',
          dataField: 'modelTypeKr',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '대표상품코드',
          dataField: 'productCode',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
    },
    {
      headerText: '이동대기',
      children: [
        {
          dataField: 'storageQuantity',
          headerText: '총 수량',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'collectStorageQuantity',
          headerText: '회수입고',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'manufactureStorageQuantity',
          headerText: '제조사출고',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'recoverStorageQuantity',
          headerText: '자체수리',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'refurbishStorageQuantity',
          headerText: '리퍼대상',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'scrapStorageQuantity',
          headerText: '자체폐기',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'investigateStorageQuantity',
          headerText: '재고조사',
          dataType: 'numeric',
          formatString: '#,##0',
        },
      ],
    },
    {
      headerText: '이동준비',
      children: [
        {
          dataField: 'readyQuantity',
          headerText: '총 수량',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'collectReadyQuantity',
          headerText: '회수입고',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'manufactureReadyQuantity',
          headerText: '제조사출고',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'recoverReadyQuantity',
          headerText: '자체수리',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'refurbishReadyQuantity',
          headerText: '리퍼대상',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'scrapReadyQuantity',
          headerText: '자체폐기',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'investigateReadyQuantity',
          headerText: '재고조사',
          dataType: 'numeric',
          formatString: '#,##0',
        },
      ],
    },
    {
      headerText: '이동중',
      children: [
        {
          dataField: 'transitQuantity',
          headerText: '총 수량',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'collectTransitQuantity',
          headerText: '회수입고',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'manufactureTransitQuantity',
          headerText: '제조사출고',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'recoverTransitQuantity',
          headerText: '자체수리',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'refurbishTransitQuantity',
          headerText: '리퍼대상',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'scrapTransitQuantity',
          headerText: '자체폐기',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'investigateTransitQuantity',
          headerText: '재고조사',
          dataType: 'numeric',
          formatString: '#,##0',
        },
      ],
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품타입',
          dataField: 'modelTypeKr',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '대표상품코드',
          dataField: 'productCode',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
    },
    {
      headerText: '이동대기',
      children: [
        {
          dataField: 'storageQuantity',
          headerText: '총 수량',
          dataType: 'numeric',
          formatString: '#,##0',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: modalOpenAction,
          },
        },
        {
          dataField: 'collectStorageQuantity',
          headerText: '회수입고',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'manufactureStorageQuantity',
          headerText: '제조사출고',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'recoverStorageQuantity',
          headerText: '자체수리',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'refurbishStorageQuantity',
          headerText: '리퍼대상',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'scrapStorageQuantity',
          headerText: '자체폐기',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'investigateStorageQuantity',
          headerText: '재고조사',
          dataType: 'numeric',
          formatString: '#,##0',
        },
      ],
    },
    {
      headerText: '이동준비',
      children: [
        {
          dataField: 'readyQuantity',
          headerText: '총 수량',
          dataType: 'numeric',
          formatString: '#,##0',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: modalOpenAction,
          },
        },
        {
          dataField: 'collectReadyQuantity',
          headerText: '회수입고',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'manufactureReadyQuantity',
          headerText: '제조사출고',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'recoverReadyQuantity',
          headerText: '자체수리',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'refurbishReadyQuantity',
          headerText: '리퍼대상',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'scrapReadyQuantity',
          headerText: '자체폐기',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'investigateReadyQuantity',
          headerText: '재고조사',
          dataType: 'numeric',
          formatString: '#,##0',
        },
      ],
    },
    {
      headerText: '이동중',
      children: [
        {
          dataField: 'transitQuantity',
          headerText: '총 수량',
          dataType: 'numeric',
          formatString: '#,##0',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: modalOpenAction,
          },
        },
        {
          dataField: 'collectTransitQuantity',
          headerText: '회수입고',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'manufactureTransitQuantity',
          headerText: '제조사출고',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'recoverTransitQuantity',
          headerText: '자체수리',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'refurbishTransitQuantity',
          headerText: '리퍼대상',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'scrapTransitQuantity',
          headerText: '자체폐기',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          dataField: 'investigateTransitQuantity',
          headerText: '재고조사',
          dataType: 'numeric',
          formatString: '#,##0',
        },
      ],
    },
  ];

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const data = type === 'main' ? await inventoryService.getInventoryDefectedExcelMain(searchObj) : await inventoryService.getInventoryDefectedExcelDetail(searchObj);
    const { list } = labellingKr({ list: data }, true);
    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '실시간_불용재고_현황' });
    } else {
      excelDetailGridRef.current.setGridData(list);
      excelDetailGridRef.current.exportAsXlsx({ fileName: '실시간_불용재고_현황(상세)' });
    }

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'INVENTORY_DEF_EXCELDOWN_MAIN') {
      downloadExcel('main');
    } else if (id === 'INVENTORY_DEF_EXCELDOWN_DETAIL') {
      downloadExcel('detail');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {infoDetailObj?.visible && <InfoDetail infoDetailObj={infoDetailObj} setInfoDetailObj={setInfoDetailObj} />}
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox handleSearchObj={handleSearchObj} fetchList={initFetch} TARGET={tabId} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area only-right">{functionBtns}</div>}
        <GridBox
          //
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            //
            showFooter: true,
            footerPosition: 'top',
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            fixedColumnCount: 1,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={detailColumns} />
      </div>
    </div>
  );
};

export default Index;
