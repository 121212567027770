import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import Grid from '../../../../../components/grid/auiGrid';
import { WMS_REMOVE_PICKING_TRANSFER_LIST, WMS_REMOVE_TRANSFER } from 'envVar';
import Select from 'react-select';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';

const TakeoutCenterChangeModal = ({ changeCenterObj, setChangeCenterObj, PfetchList }) => {
  const dispatch = useDispatch();

  const { masterOptions } = useSelector((state) => state.menu);
  const [rows, setRows] = useState(null);
  const [gridId, setGrid] = useState();
  const [searchObj, setSearchObj] = useState({ centerCode: null });

  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  useEffect(() => {
    console.log(changeCenterObj);
  }, []);

  useEffect(() => {
    if (changeCenterObj?.visible) {
      const seed = uuidv4();
      setGrid(`takeoutCenterChangeModal${seed}`);
    }
  }, [changeCenterObj?.visible]);

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'center-change') {
        centerChangeAPI(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const fetchList = async () => {
    dispatch(setLoading('GET'));
    let url =
      WMS_REMOVE_PICKING_TRANSFER_LIST +
      `?transferCenterCode=${searchObj.centerCode}&centerCode=${changeCenterObj?.data?.centerCode}
    &stockRemoveDate=${changeCenterObj?.data?.stockRemoveDate}&partnerSeq=${changeCenterObj?.data?.partnerSeq}`;
    if (changeCenterObj?.data?.target && changeCenterObj?.data?.target !== '') url += `&target=${changeCenterObj?.data?.target}`;

    httpClient
      .get(url)
      .then((res) => {
        const data = res.data || [];
        data?.forEach((row) => {
          row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
        });
        setRows(data);
        dispatch(setLoading(null));
      })
      .catch(async (err) => {
        console.log(err);
        dispatch(setLoading(null));
      });
  };

  const centerChangeAPI = async (items) => {
    console.log(items);

    const payloadArr = {
      transferCenterCode: searchObj.centerCode,
      centerCode: changeCenterObj?.data?.centerCode,
      stockRemoveDate: changeCenterObj?.data?.stockRemoveDate,
      partnerSeq: changeCenterObj?.data?.partnerSeq,
    };

    if (payloadArr.originalCenterCode !== payloadArr.transferCenterCode) {
      if (changeCenterObj?.data?.target && changeCenterObj?.data?.target !== '') payloadArr['target'] = changeCenterObj?.data?.target;
      const modelStockSeqs = items.map((a) => {
        return a.modelStockSeq;
      });

      if (modelStockSeqs) {
        payloadArr.modelStockSeqs = modelStockSeqs;
      }

      const url = WMS_REMOVE_TRANSFER;
      httpClient
        .post(url, payloadArr)
        .then((res) => {
          if (res?.status === 200) {
            alert(`${selectlabel(payloadArr.centerCode, masterOptions?.CENTER)}에서 ${selectlabel(payloadArr.transferCenterCode, masterOptions?.CENTER)}로 변경이 완료되었습니다.`);
            PfetchList();
          } else {
            alert(`${res.data.message}`);
          }
        })
        .catch(async (err) => {
          alert(err.response.data.message);
        });
    } else {
      alert('출발창고와 변경창고가 동일합니다. 서로 다른 창고를 선택하여주세요.');
    }
  };

  const columns = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품',
      dataField: 'modelName',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '변경할 수량',
      dataField: 'transferQuantity',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '변경가능 수량',
      dataField: 'availableQuantity',
      filter: {
        showIcon: true,
      },
    },
  ];

  return (
    <>
      <CommonModalNew
        title="출발창고 변경"
        visible={changeCenterObj?.visible}
        setVisible={() => {
          setChangeCenterObj(null);
        }}
        style={{ width: 1000 }}
        children={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '15rem' }}>
                <label className="col-form-label">출발창고</label>
                <Select
                  value={{
                    value: searchObj?.centerCode,
                    label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                  }}
                  options={masterOptions?.CENTER}
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      centerCode: value,
                    });
                  }}
                />
              </div>
              <div className="grid-button-area" style={{ margin: 15 }}>
                <div
                  onDoubleClick={() => {
                    return;
                  }}
                  onClick={() => {
                    if (searchObj?.centerCode) {
                      fetchList();
                    } else {
                      alert('출발 창고를 선택하세요');
                    }
                  }}
                >
                  조회
                </div>
                <div
                  className="orange"
                  onDoubleClick={() => {
                    return;
                  }}
                  onClick={() =>
                    setGetCheckedDataTriggerThisGrid({
                      type: 'center-change',
                      id: `#${gridId}`,
                    })
                  }
                >
                  변경
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              {gridId && (
                <Grid
                  style={{ height: 500 }}
                  id={gridId}
                  rows={rows}
                  columns={columns}
                  getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
                  setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
                  settingOptions={{
                    showPageRowSelect: false,
                    showRowAllCheckBox: false,
                    showRowCheckColumn: true,
                    editable: false,
                  }}
                />
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default TakeoutCenterChangeModal;
