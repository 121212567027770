// react
import { useEffect, useState, useMemo } from 'react';
import Presenter from './presenter';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { httpClient } from 'common/http-client/axiosConfig';
import { setLoading } from 'redux/services/menuSlice';

import { INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION, INVENTORY_REFURBISH_CREATE } from 'envVar';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { generateUrlByDesignType } from 'components/LDS/utils/path';

const Container = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state) => state.menu);
  const { reduxUserInfo } = useSelector((state) => state.auth);

  const [tmpExcelDataTriggerThisGrid, setTmpExcelDataTriggerThisGrid] = useState([]);
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState();

  const [gridId, setGrid] = useState();
  const [rows, setRows] = useState([]);

  const [modelModalObj, setModelModalObj] = useState({
    visible: false,
    type: null,
    data: null,
    to: null,
    searchObj: null,
  });

  useEffect(() => {
    setGrid(`refur_create_${uuidv4()}`);
  }, []);

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'pick-afterModel') {
        pickAfterModel(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'delete-row') {
        deleteRow(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const deleteRow = (items) => {
    const _rows = [];
    const indexs = items.map((ele) => ele.rowIndex);
    rows.forEach((ele, idx) => {
      if (!indexs.includes(idx)) {
        _rows.push(ele);
      }
    });
    setRows(_rows);
  };

  const defaultSearchFilter = useMemo(() => {
    return {
      centerCode: reduxUserInfo['centerCode'], // 창고
      partnerSeq: MASTER_OPS?.SELLER_SELLER_WHOLE.find((seller) => seller.value === reduxUserInfo['partner']) ? reduxUserInfo['partner'] : null, // 판매사
      modifyDate: returnDateyyyymmdd(new Date()), //변경일
    };
  }, [reduxUserInfo]);

  const [searchObj, setSearchObj] = useState({
    ...defaultSearchFilter,
    dateSearch: null,
    modelType: null,
    modelGroup: null,
    model: null,
    barcode: null,
  });

  useEffect(() => {
    const items = modelModalObj?.data;
    if (items?.length > 0) {
      const _rows = JSON.parse(JSON.stringify(rows));
      items?.forEach((ele) => {
        if (ele.availableQuantityTo > 0 || ele.defectedQuantityTo > 0) {
          new Array(ele.availableQuantityTo + ele.defectedQuantityTo).fill(0)?.forEach((_, idx) => {
            const isAvailable = idx < ele.availableQuantityTo;
            const obj = {
              ...ele,
              beforeModelGroupKr: ele.modelGroupKr,
              beforeModelStockSeq: ele.modelStockSeq,
              beforeSkuNumber: ele.skuNumber,
              beforeBarcode: ele.barcode,
              beforeModel: ele.model,
              beforeModelName: ele.modelName,
              isAvailable: isAvailable,
              availableQuantity: isAvailable ? 1 : 0,
              defectedQuantity: isAvailable ? 0 : 1,
              beforeSerialNumber: ele.serialNumber,
            };
            _rows.push(obj);
          });
        }
      });
      setRows(_rows);
    }
  }, [modelModalObj?.data]);

  useEffect(() => {
    const _rows = JSON.parse(JSON.stringify(rows));
    _rows.forEach((ele, idx) => {
      if (modelModalObj?.rowIdx?.includes(idx)) {
        ele.afterModelGroupKr = modelModalObj?.afterData.modelGroupKr;
        ele.afterModelStockSeq = modelModalObj?.afterData.modelStockSeq;
        ele.aftereSkuNumber = modelModalObj?.afterData.skuNumber;
        ele.afterBarcode = modelModalObj?.afterData.barcode;
        ele.afterModel = modelModalObj?.afterData.model;
        ele.afterModelName = modelModalObj?.afterData.modelName;
        ele.afterSerialNumber = ele.serialNumber;
      }
    });

    setRows(_rows);
  }, [modelModalObj?.afterData]);

  const columns = [
    {
      headerText: '출고창고',
      dataField: 'centerCodeKr',
      editable: false,
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      editable: false,
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
      editable: false,
    },
    {
      headerText: '변경 전 제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'beforeModelGroupKr',
          editable: false,
        },
        {
          headerText: 'SKU',
          dataField: 'beforeModelStockSeq',
          editable: false,
        },
        {
          headerText: '외부SKU',
          dataField: 'beforeSkuNumber',
          editable: false,
        },
        {
          headerText: '바코드',
          dataField: 'beforeBarcode',
          editable: false,
        },
        {
          headerText: '제품',
          dataField: 'beforeModel',
          editable: false,
        },
        {
          headerText: '제품명',
          dataField: 'beforeModelName',
          editable: false,
        },
      ],
    },
    {
      headerText: '변경신청수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          editable: false,
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          editable: false,
        },
      ],
    },
    {
      headerText: '변경 후 제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'afterModelGroupKr',
          editable: false,
        },
        {
          headerText: 'SKU',
          dataField: 'afterModelStockSeq',
          editable: false,
        },
        {
          headerText: '외부SKU',
          dataField: 'aftereSkuNumber',
          editable: false,
        },
        {
          headerText: '바코드',
          dataField: 'afterBarcode',
          editable: false,
        },
        {
          headerText: '제품',
          dataField: 'afterModel',
          editable: false,
        },
        {
          headerText: '제품명',
          dataField: 'afterModelName',
          editable: false,
        },
      ],
    },
    {
      headerText: '변경 전 시리얼번호',
      dataField: 'beforeSerialNumber',
    },
    {
      headerText: '변경 후 시리얼번호',
      dataField: 'afterSerialNumber',
    },
    {
      headerText: '비고',
      dataField: 'memo',
    },
  ];

  const addSearchObj = (url, searchObj) => {
    if (searchObj?.centerCode) url += `&centerCode=${searchObj?.centerCode}`;
    if (searchObj?.partnerSeq) url += `&partnerSeq=${searchObj?.partnerSeq}`;

    if (searchObj?.modelType) url += `&modelType=${searchObj?.modelType}`;
    if (searchObj?.modelGroup) url += `&modelGroup=${searchObj?.modelGroup}`;
    if (searchObj?.model) url += `&model=${searchObj?.model}`;
    if (searchObj?.barcode) url += `&barcode=${searchObj?.barcode}`;

    return url;
  };

  const fetchList = async () => {
    dispatch(setLoading('GET'));
    let url = INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION + `?`;
    url = addSearchObj(url, searchObj);
    httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        rs.data?.forEach((row) => {
          row.centerCodeKr = MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode];
          row.partnerSeqKr = MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq];
          row.modelGroupKr = MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup];
          row.availableQuantityTo = 0;
          row.defectedQuantityTo = 0;
        });
        setRows(rs.data);
      }
    });
    dispatch(setLoading(null));
  };

  const pickAfterModel = (items) => {
    setModelModalObj({
      ...modelModalObj,
      visible: true,
      type: 'changeTo',
      rowIdx: items?.map((ele) => ele.rowIndex),
    });
  };

  const createValidation = (searchObj, rows) => {
    let flag = true;

    if (!searchObj?.centerCode) {
      alert('창고를 선택하세요!');
      flag = false;
    } else if (!searchObj?.partnerSeq) {
      alert('판매사를 선택하세요!');
      flag = false;
    } else if (!searchObj?.modifyDate) {
      alert('변경일을 선택하세요!');
      flag = false;
    } else {
      try {
        rows.forEach((row) => {
          if (!row.afterModelStockSeq) {
            flag = false;
            const err = new Error('변경 후 제품정보가 누락된 제품이 있습니다!');
            err.name = 'NoAfterModel';
            throw err;
          }
        });
      } catch (err) {
        if (err.name === 'NoAfterModel') {
          alert('변경 후 제품정보가 누락된 제품이 있습니다!');
        }
      }
    }

    return flag;
  };

  const createRefur = async (searchObj, rows) => {
    if (createValidation(searchObj, rows)) {
      const json = {
        centerCode: searchObj?.centerCode,
        partnerSeq: searchObj?.partnerSeq,
        modifyDate: searchObj?.modifyDate,
        details: [],
      };
      console.log(rows);
      rows?.forEach((row) => {
        json.details.push({
          locationCode: row.locationCode,
          availableStatus: row.availableQuantity > 0 ? 'AVAILABLE' : 'DEFECTED',
          beforeModelStockSeq: row.beforeModelStockSeq,
          afterModelStockSeq: row.afterModelStockSeq,
          beforeSerialNumber: row.beforeSerialNumber,
          afterSerialNumber: row.afterSerialNumber,
          memo: row.memo,
        });
      });

      httpClient.post(INVENTORY_REFURBISH_CREATE + `/apply`, json).then((rs) => {
        if (rs?.status === 200) {
          alert('리퍼 생성에 성공하였습니다!');
          history(generateUrlByDesignType('/refur'));
        }
      });
    }
  };

  return (
    <Presenter
      createRefur={createRefur}
      gridId={gridId}
      columns={columns}
      rows={rows}
      // searchBox
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      modelModalObj={modelModalObj}
      setModelModalObj={setModelModalObj}
      // gridBox
      fetchList={fetchList}
      tmpExcelDataTriggerThisGrid={tmpExcelDataTriggerThisGrid}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
    />
  );
};

export default Container;
