export const returnFeeTypes = (elementFeetype) => {
  if (['DELIV', 'SDELIV', 'PDELIV', 'INSTA', 'HINSTA', 'DINSTA', 'EINSTA', 'COLLI', 'GINSTA', 'PINSTA'].includes(elementFeetype)) {
    return 'delivery';
  } else if (['VISIT', 'RVISIT', 'SVISIT', 'MVISIT'].includes(elementFeetype)) {
    return 'visit';
  } else if (
    [
      //
      'ENDC',
      'INSCO',
      'COLLE',
      'HCOLLE',
      'DCOLLE',
      'DCOLLE',
      'ECOLLE',
      'BCOLLE',
      'GCOLLE',
      'PROCOLLE',
      'PCOLLE',
    ].includes(elementFeetype)
  ) {
    return 'collect';
  } else if (['EXCHA', 'DEXCHA', 'EXINSTA', 'DEXINSTA', 'EXCHAOUT', 'DEXCHAOUT'].includes(elementFeetype)) {
    return 'exchange';
  } else if (['MOVE', 'MOVEOUT', 'SMOVE', 'SMOVEOUT', 'FMOVE', 'FMOVEOUT'].includes(elementFeetype)) {
    return 'move';
  } else if (['DP', 'DPOUT'].includes(elementFeetype)) {
    return 'store';
  } else if (['ASVISIT', 'ASDELIV', 'ASINSTA', 'ASREPAIR', 'REPAIROUT', 'ASCHECK', 'ASPARCEL'].includes(elementFeetype)) {
    // AS 방문, 배송, 배송설치, 수리, 점검, 택배
    return 'as';
    // ONLY PARCEL
  } else if (['PARCEL', 'PARCELOUT', 'PARCELRETURN'].includes(elementFeetype)) {
    return 'parcel';
  } else if (['REPAIR', 'REPAIROUT'].includes(elementFeetype)) {
    return 'fix';
  } else {
    return 'nothing';
  }
};

export const returnIsInvoiceStatusFinished = (elementInvoiceStatus) => {
  if (elementInvoiceStatus?.includes('FINISH')) return true;
  return false;
};
