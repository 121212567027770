import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from './container';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';

export const STORE_TAKE_OUT_PICKING_INSTRUCTION_REGISTER_MASTER = [
  'SELLER_SELLER_AUTH',
  'SELLER_SELLER_WHOLE',
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  //
  'MODEL_GROUP',
  'MODEL_TYPE',
  'LOGITYPE',
  'LINEHAUL_TYPE',
  'LINE_STAT',
  'REMOVE_REASON',
];

const Index = (props) => {
  const { MASTER_OBJ } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(STORE_TAKE_OUT_PICKING_INSTRUCTION_REGISTER_MASTER);
  };

  return <>{MASTER_OBJ && <Container {...props} />}</>;
};

export default Index;
