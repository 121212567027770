import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

import { InvoiceCenterSearchDTO } from 'interface/order';
import { KeyboardEvent, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { fetchMaster, RAWDATA } from 'redux/services/menuSlice';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';

export const SearchBox = (props: ISearchBoxProps<InvoiceCenterSearchDTO>) => {
  const dispatch = useDispatch();
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };
  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">*지정일</label>
          <FlatpickrD
            options={{ mode: 'single' }}
            value={searchObj?.deliveryDate}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                deliveryDate: returnDateyyyymmdd(option[0]),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">*창고</label>
          <SelectD
            options={MASTER_OPS?.CENTER_AUTH}
            value={
              searchObj?.centerCode
                ? {
                    label: MASTER_OBJ?.CENTER_WHOLE?.[searchObj?.centerCode],
                    value: searchObj?.centerCode,
                  }
                : null
            }
            onChange={({ value }) => {
              const drivers = MASTER_OPS?.[`${RAWDATA}DRIVER_WHOLE`]?.filter((ele) => ele.centerCode === value);
              const DRIVER_FILTERED = drivers?.map((ele) => {
                return {
                  value: ele.userId,
                  label: ele.driverName,
                };
              });
              dispatch(
                fetchMaster({
                  key: 'DRIVER_FILTERED',
                  value: DRIVER_FILTERED,
                }),
              );
              setSearchObj({
                ...searchObj,
                centerCode: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">기사</label>
          <div style={{ display: 'flex' }}>
            <Col md={6} style={{ display: 'inline-block' }}>
              <SelectD
                isDisabled={!searchObj?.centerCode}
                options={MASTER_OPS?.DRIVER_FILTERED}
                value={
                  searchObj?.userId
                    ? {
                        value: searchObj?.userId,
                        label: MASTER_OBJ?.DRIVER_WHOLE?.[searchObj?.userId],
                      }
                    : null
                }
                onChange={({ value }) => {
                  setSearchObj({
                    ...searchObj,
                    userId: value,
                  });
                }}
              />
            </Col>
            <Col md={6} style={{ display: 'inline-block' }}>
              <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            options={MASTER_OPS?.SELLER_SELLER_AUTH}
            value={
              searchObj?.partnerSeq
                ? {
                    label: MASTER_OBJ?.SELLER_WHOLE?.[searchObj?.partnerSeq],
                    value: searchObj?.partnerSeq,
                  }
                : null
            }
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">확정여부</label>
          <SelectD
            options={MASTER_OPS?.CONFIRM_FLAG}
            value={
              searchObj?.confirmFlag
                ? {
                    label: selectlabel(searchObj?.confirmFlag + '', MASTER_OPS?.CONFIRM_FLAG),
                    value: searchObj?.confirmFlag,
                  }
                : null
            }
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                confirmFlag: value,
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
