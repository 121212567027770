import { useRef, useState } from 'react';
import { Input as RawInput, InputProps } from 'reactstrap';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import Select, { Props as SelectProps, createFilter } from 'react-select';

import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import kojs from 'flatpickr/dist/l10n/ko.js';
import { ko } from 'date-fns/esm/locale';

import ximg from 'assets/images/close/cross.png';
import eyeOn from 'assets/images/button/eye-on.png';
import eyeOff from 'assets/images/button/eye-off.png';
import explanationMark from './img/explanationMark.png';

import { addValueNullAtFirstPlace } from 'common/master/codeMasterReturnHelper';
import { ILOCALE } from 'redux/services/localeSlice';

import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
export type ITypeEyeBoolean = 'ON' | 'OFF';
export interface SelectDProps extends SelectProps {
  filterOnlyLabel?: boolean;
  height50?: boolean;
  height100?: boolean;
  hasNull?: boolean;
  locale?: ILOCALE;
  nullLabeled?: string;
  selectAll?: () => void;
  hasColor?: string;
}

export type OptionItem = {
  value: any;
  label: any;
};
interface InputDProps extends InputProps {
  onReset?: () => {};
  wrapperstyle?: any;
  shallow?: boolean;
  locale?: ILOCALE;
  isTypePassword?: boolean;
}

export const InputD = (props: InputDProps) => {
  const { shallow = false, className = '', style, ref, locale = 'ko', isTypePassword = false } = props;
  const [typePassword, setTypePassword] = useState<ITypeEyeBoolean>('ON');
  return (
    <span style={{ position: 'relative', ...props.wrapperstyle }}>
      {props?.onReset && (
        <span
          style={{
            position: 'absolute',
            top: 21,
            right: 5,
            cursor: 'pointer',
            color: '#c5c5c5',
          }}
          onClick={() => {
            if (props?.onReset) props?.onReset();
          }}
        >
          <img src={ximg} alt="" style={{ width: 7 }} />
        </span>
      )}
      {isTypePassword && (
        <img
          alt=" "
          style={{ position: 'absolute', width: 15, right: 7, top: 7, cursor: 'pointer' }}
          src={typePassword === 'ON' ? eyeOn : eyeOff}
          onClick={() => {
            setTypePassword(typePassword === 'ON' ? 'OFF' : 'ON');
          }}
        />
      )}
      <RawInput
        {...props}
        type={!isTypePassword ? props?.type : typePassword === 'ON' ? 'password' : props?.type}
        className={shallow ? className + 'shallow-input' : className + ''}
        placeholder={props?.placeholder ? props?.placeholder : locale === 'ko' ? '입력...' : locale === 'zh' ? '输入...' : 'input..'}
        // ref={ref}
      />
    </span>
  );
};
export interface IExplanation {
  title?: string;
  content?: string[];
}
export const ExplanationD = ({ title, content }: IExplanation) => {
  return (
    <div className="hover-explanation-wrapper">
      <img src={explanationMark} style={{ width: 12 }} />
      <div className="hover-explanation">
        <div className="b">{title}</div>
        <div className="content-area">
          {content?.map((cont, i) => {
            return <div key={`${i}_content`}>{cont}</div>;
          })}
        </div>
      </div>
    </div>
  );
};

export const SelectD = (props: SelectDProps) => {
  const {
    filterOnlyLabel = true,
    className = '',
    height100 = false,
    height50 = false,
    isMulti = false,
    hasNull = true,
    locale = 'ko',
    placeholder,
    selectAll = undefined,
    value,
    options,
    nullLabeled = '전체',
    hasColor,
  } = props;

  return (
    <>
      {selectAll && isMulti && (
        <span style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginBottom: 2 }}>
          <input type="checkbox" id="ALL" checked={(value as OptionItem[])?.length === options?.length} onChange={selectAll} />
          <label htmlFor="ALL">전체선택</label>
        </span>
      )}
      <StyledSelect
        {...props}
        closeMenuOnSelect={isMulti ? false : true}
        hasColor={hasColor}
        className={
          props?.isDisabled
            ? className + 'disabled single' + (height100 ? 'height100' : '') + (height50 ? 'height50' : '')
            : !props?.isMulti
            ? className + 'single' + (height100 ? 'height100' : '') + (height50 ? 'height50' : '')
            : className + (height100 ? 'height100' : '') + (height50 ? 'height50' : '')
        }
        // label로만 검색
        filterOption={
          filterOnlyLabel
            ? createFilter({
                matchFrom: 'any',
                stringify: (option) => `${option.label}`,
              })
            : createFilter({
                matchFrom: 'any',
              })
        }
        isDisabled={props?.isDisabled}
        options={(isMulti || !hasNull ? props?.options : addValueNullAtFirstPlace(props?.options as OptionItem[], locale === 'ko' ? nullLabeled : locale === 'zh' ? '整个' : 'whole')) || []}
        // 팝업시에도 가장 상위에
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...(base as any), zIndex: 9999 }) }}
        placeholder={placeholder ? placeholder : locale === 'ko' ? '선택...' : locale === 'zh' ? '抉择...' : 'select..'}
      />
    </>
  );
};

export const DatePickrD = (
  props: ReactDatePickerProps & {
    onReset?: () => void;
    locale?: ILOCALE;
  },
) => {
  const refDatePicker = useRef<DatePicker>(null);
  const { locale = 'ko', disabled } = props;
  return (
    <div style={{ position: 'relative' }}>
      {props?.onReset && (
        <span
          style={{
            position: 'absolute',
            top: 3,
            right: 5,
            cursor: 'pointer',
            color: '#c5c5c5',
          }}
          onClick={() => {
            if (!disabled) props?.onReset();
          }}
        >
          <img src={ximg} alt="" style={{ width: 7 }} />
        </span>
      )}
      <DatePicker {...props} locale={ko} placeholderText={locale === 'ko' ? '월,일' : locale === 'zh' ? '抉择...' : 'select..'} ref={refDatePicker} />
    </div>
  );
};

export const FlatpickrD = (
  props: DateTimePickerProps & {
    onReset?: () => void;
    locale?: ILOCALE;
  },
) => {
  const { options, onReset, className = 'form-control', locale = 'ko', disabled } = props;
  const refDatePicker = useRef<Flatpickr>(null);

  return (
    <div style={{ position: 'relative' }}>
      <Flatpickr
        {...props}
        ref={refDatePicker}
        className={className}
        placeholder={locale === 'ko' ? '년,월,일' : locale === 'zh' ? '年,月,日' : 'yy,mm,dd'}
        options={{
          ...options,
          mode: options?.mode || 'range', // default range || single
          dateFormat: 'Y-m-d',
          locale: kojs.ko,
        }}
      />
      {onReset && (
        <span
          style={{
            position: 'absolute',
            top: 3,
            right: 5,
            cursor: 'pointer',
            color: '#c5c5c5',
          }}
          onClick={() => {
            if (!disabled) {
              refDatePicker?.current?.flatpickr.clear();
              onReset();
            }
          }}
        >
          <img src={ximg} alt="" style={{ width: 7 }} />
        </span>
      )}
    </div>
  );
};

const StyledSelect = styled(Select)<{ hasColor?: string }>`
  & > div[class*='control'] {
    & div[class*='singleValue'] {
      padding-left: ${(props) => (props.hasColor ? '17px' : null)};

      &:after {
        content: '';
        position: absolute;
        left: 8px;
        top: 6px;
        width: 12px;
        height: 12px;
        border-radius: 4px;
        background-color: ${(props) => props.hasColor ?? null};
      }
    }
  }
`;
