import React, { useEffect, useRef, useState } from 'react';
import { MenuSearchDTO, UserGroupUserDTO } from 'interface/user';
import { useDispatch, useSelector } from 'react-redux';
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import GridBox, { defaultGridProps } from 'common/grid/gridBox';
import { setLoading } from 'redux/services/menuSlice';
import { userService } from 'pages/ETC/3user/_service/service';
import CommonModalNew from 'components/modal/commonModalNew';
import { selectvalue } from 'common/master/codeMasterReturnHelper';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';

export interface IMenuModalObj {
  visible: boolean;
  type?: string;
  item?: UserGroupUserDTO;
  searchObj?: MenuSearchDTO;
  fetchFn?: (searchObj) => void;
}

export const MenuModal = ({ menuObj, setMenuObj }) => {
  const dispatch = useDispatch();
  const [rawData, setRawData] = useState<any>();

  useEffect(() => {
    if (menuObj?.item) {
      fetchMenuData(menuObj?.item);
    }
  }, []);

  const fetchMenuData = async (item) => {
    const userGroupInfo = menuObj?.type === 'USER' ? await userService.getUserMenu(item.userGroupId, item?.userId) : await userService.getUserGroupMenu(item.userGroupId);
    setRawData(userGroupInfo);
  };

  const [trappingData, setTrappingData] = useState<ITrappingData>();

  const postSave = async (dataDTO) => {
    if (window?.confirm(`저장하시겠습니까?`)) {
      dispatch(setLoading('POST'));
      const rs =
        menuObj?.type === 'USER'
          ? await userService.postUserGroup(dataDTO, `/${menuObj.item.userGroupId}/${menuObj.item.userId}`)
          : await userService.postUserGroup(dataDTO, `/${menuObj.item.userGroupId}`);
      if (rs?.status === 200) {
        alert('저장되었습니다!');
        menuObj?.fetchFn(menuObj?.searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  useEffect(() => {
    if (trappingData?.data) {
      postSave(trappingData?.data);
    }
  }, [trappingData]);

  return (
    <CommonModalNew
      title={menuObj?.type === 'GROUP' ? `그룹 메뉴권한` : `사용자 메뉴권한`}
      visible={menuObj?.visible}
      setVisible={() => {
        setMenuObj(null);
      }}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={() => {
              setTrappingData({
                on: true,
                data: null,
              });
            }}
          >
            저장
          </button>
        </>
      }
      style={{ width: 1280 }}
      children={<>{rawData && <ControllerScreen rawData={rawData} trappingData={trappingData} setTrappingData={setTrappingData} />}</>}
    />
  );
};

export interface ITrappingData {
  on?: boolean;
  data?: any;
}

interface ControllerScreenProps {
  rawData?: any;
  trappingData?: ITrappingData;
  setTrappingData: React.Dispatch<React.SetStateAction<ITrappingData>>;
}

export const ControllerScreen = ({ rawData, trappingData, setTrappingData }: ControllerScreenProps) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [did, setDid] = useState(false);

  useEffect(() => {
    if (trappingData?.on) {
      setTrappingData({
        on: false,
        data: getData(),
      });
    }
  }, [trappingData?.on]);

  useEffect(() => {
    const menus = menusRef?.current?.getGridData();
    if (rawData?.menus?.length > 0 && menus?.length === 0) {
      initData(rawData);
    }
  }, [rawData]);

  const initData = (userGroupInfo) => {
    const openUrls = userGroupInfo?.functions?.filter((ele) => ele.openUrl);
    // 하드코딩
    const screenToUrl = {
      // ORDER: '/manageorderInfoList',
      // PURCHASEREGISTER: '/placeOrderRegister',
    };
    openUrls?.forEach((func) => {
      screenToUrl[func.openScreenId] = func.openUrl;
    });

    menusRef?.current?.setGridData(
      userGroupInfo?.menus?.map((men) => {
        return {
          ...men,
          openUrl: screenToUrl[men?.screenId],
        };
      }),
    );
    screenRef?.current?.setGridData(userGroupInfo?.screens);
    tabsAllRef?.current?.setGridData(userGroupInfo?.tabs);

    const functs = userGroupInfo?.functions?.map((func) => {
      return {
        ...func,
        functionNameKr: func?.functionGroup ? `[${func?.functionGroup}]${func?.functionName}` : func?.functionName,
        functionTitleKr: !func?.functionTitle ? '' : `[${func?.functionName}]` === func?.functionTitle ? `[${func?.functionName}]` : '-',
      };
    });

    functionsAllRef?.current?.setGridData(functs);
    setDid(true);
  };

  useEffect(() => {
    if (functionsAllRef.current) bindFunctions();
  }, [did]);

  const menusRef = useRef<AUIGrid>();
  const tabsRef = useRef<AUIGrid>();
  const screenRef = useRef<AUIGrid>();
  const functionsRef = useRef<AUIGrid>();
  const tabsAllRef = useRef<AUIGrid>();
  const functionsAllRef = useRef<AUIGrid>();

  const bindFunctions = () => {
    if (menusRef.current.getProp('currentPage') == 0) {
      menusRef.current.movePageTo(1);
    }

    if (tabsRef.current.getProp('currentPage') == 0) {
      tabsRef.current.movePageTo(1);
    }

    if (functionsRef.current.getProp('currentPage') == 0) {
      functionsRef.current.movePageTo(1);
    }
    menusRef?.current?.bind('cellClick', function (e) {
      if (e.dataField === 'menuName') {
        showTabsInThisMenu(e.item.screenId);
      } else if (e.dataField?.includes('Flag')) {
        checkFunction(e.dataField, e.item, e.item[e.dataField]);
      }
    });

    tabsRef?.current?.bind('cellClick', function (e) {
      if (e.dataField === 'tabName') {
        showFunctionsInThisTab(e.item.tabId);
      } else if (e.dataField?.includes('Flag')) {
        checkFunction(e.dataField, e.item, e.item[e.dataField]);
      }
    });

    functionsRef?.current?.bind('cellClick', function (e) {
      if (e.dataField === 'functionFlag') checkFunction(e.dataField, e.item, e.item[e.dataField]);
    });
  };

  const showTabsInThisMenu = (screenId: string) => {
    const tabsInthisScreen = tabsAllRef?.current?.getGridData()?.filter((ele) => ele.screenId === screenId);
    tabsRef.current.setGridData(tabsInthisScreen);

    if (tabsRef.current.getProp('currentPage') == 0) {
      tabsRef.current.movePageTo(1);
    }
    showFunctionsInThisTab(tabsInthisScreen[0]?.tabId);
  };

  const functionTypeSort = {
    MAIN: 1,
    GRID: 2,
    DETAIL: 3,
  };
  const showFunctionsInThisTab = (tabId: string) => {
    const functionsInthisTab = functionsAllRef?.current.getGridData()?.filter((ele) => ele.tabId === tabId); //tabId 들어와야함
    functionsInthisTab?.sort((a, b) => {
      return functionTypeSort[a.functionType] - functionTypeSort[b.functionType];
    });
    functionsRef.current.setGridData(functionsInthisTab);

    if (functionsRef.current.getProp('currentPage') == 0) {
      functionsRef.current.movePageTo(1);
    }
  };

  const headerCheckFunction = (e: IGrid.HeaderCheckClickEvent) => {
    const { dataField, checked } = e;
    const menus = menusRef.current.getGridData();
    const tabs = tabsRef.current.getGridData();
    const tabsAll = tabsAllRef.current.getGridData();
    const functions = functionsRef.current.getGridData();
    const functionsAll = functionsAllRef.current.getGridData();
    if (dataField?.includes('enu')) {
      if (!checked) {
        tabsAllRef.current.setGridData(
          tabsAll?.map((ele) => {
            return {
              ...ele,
              tabFlag: false,
            };
          }),
        );

        tabsRef.current.setGridData(
          tabs?.map((ele) => {
            return {
              ...ele,
              tabFlag: false,
            };
          }),
        );
        functionsAllRef.current.setGridData(
          functionsAll?.map((ele) => {
            return {
              ...ele,
              functionFlag: false,
            };
          }),
        );
        functionsRef.current.setGridData(
          functions?.map((ele) => {
            return {
              ...ele,
              functionFlag: false,
            };
          }),
        );
        menus?.forEach((row) => {
          if (dataField === 'parentParentMenuFlag') {
            row['parentMenuFlag'] = false;
            row['menuFlag'] = false;
          }
          if (dataField === 'parentMenuFlag') {
            row['menuFlag'] = false;
          }
          row[dataField] = checked;
        });
      } else {
        menus?.forEach((row) => {
          if (dataField === 'menuFlag') {
            row['parentMenuFlag'] = true;
            row['parentParentMenuFlag'] = true;
          }
          if (dataField === 'parentMenuFlag') {
            row['parentParentMenuFlag'] = true;
          }
          row[dataField] = checked;
        });
      }

      menusRef.current.setGridData(menus); // 메뉴는 저장하지 않음
    } else if (dataField?.includes('tab')) {
      // 킨다: 관련 메뉴들 켜짐
      if (checked) {
        const screenId = tabs?.[0]?.screenId;
        if (screenId) {
          const { menuId, parentMenuId, parentParentMenuId } = menus?.filter((ele) => ele.screenId === screenId)[0];
          menusRef?.current?.setGridData(
            menus?.map((menu) => {
              return {
                ...menu,
                menuFlag: menu.menuId === menuId ? true : menu?.menuFlag,
                parentMenuFlag: parentMenuId === menu?.parentMenuId ? true : menu?.parentMenuFlag,
                parentParentMenuFlag: parentParentMenuId === menu?.parentParentMenuId ? true : menu?.parentParentMenuFlag,
              };
            }),
          );

          // 자기자신
          tabsRef.current.setGridData(
            tabs.map((tab) => {
              return {
                ...tab,
                tabFlag: tab.screenId === screenId ? true : tab.tabFlag,
              };
            }),
          );
          tabsAllRef.current.setGridData(
            tabsAll.map((tab) => {
              return {
                ...tab,
                tabFlag: tab.screenId === screenId ? true : tab.tabFlag,
              };
            }),
          );
        }
        // 끌떄
      } else {
        const screenId = tabs?.[0]?.screenId;
        const tabId = tabs?.[0]?.tabId;
        if (tabId && screenId) {
          tabsRef.current.setGridData(
            tabs.map((tab) => {
              return {
                ...tab,
                tabFlag: tab.screenId === screenId ? false : tab.tabFlag,
              };
            }),
          );
          tabsAllRef.current.setGridData(
            tabsAll.map((tab) => {
              return {
                ...tab,
                tabFlag: tab.screenId === screenId ? false : tab.tabFlag,
              };
            }),
          );
          functionsAllRef.current.setGridData(
            functionsAll?.map((ele) => {
              return {
                ...ele,
                functionFlag: ele.tabId === tabId ? false : ele.functionFlag,
              };
            }),
          );
          functionsRef.current.setGridData(
            functions?.map((ele) => {
              return {
                ...ele,
                functionFlag: ele.tabId === tabId ? false : ele.functionFlag,
              };
            }),
          );
        }
      }
    } else {
      // 기능일때
      // 켜진다
      if (checked) {
        const tabId = functions?.[0]?.tabId;
        if (tabId) {
          functionsAllRef.current.setGridData(
            functionsAll?.map((ele) => {
              return {
                ...ele,
                functionFlag: tabId === ele.tabId ? true : ele.functionFlag,
              };
            }),
          );
          functionsRef.current.setGridData(
            functions?.map((ele) => {
              return {
                ...ele,
                functionFlag: tabId === ele.tabId ? true : ele.functionFlag,
              };
            }),
          );
          let screenId;
          tabsRef.current.setGridData(
            tabs.map((tab) => {
              return {
                ...tab,
                tabFlag: tab.tabId === tabId ? true : tab.tabFlag,
              };
            }),
          );
          tabsAllRef.current.setGridData(
            tabsAll.map((tab) => {
              if (tab.tabId === tabId) screenId = tab.screenId;
              return {
                ...tab,
                tabFlag: tab.tabId === tabId ? true : tab.tabFlag,
              };
            }),
          );
          if (screenId) {
            const { menuId, parentMenuId, parentParentMenuId } = menus?.filter((ele) => ele.screenId === screenId)[0];
            menusRef?.current?.setGridData(
              menus?.map((menu) => {
                return {
                  ...menu,
                  menuFlag: menu.menuId === menuId ? true : menu?.menuFlag,
                  parentMenuFlag: parentMenuId === menu?.parentMenuId ? true : menu?.parentMenuFlag,
                  parentParentMenuFlag: parentParentMenuId === menu?.parentParentMenuId ? true : menu?.parentParentMenuFlag,
                };
              }),
            );
          }
        }
      } else {
        const tabId = functions?.[0]?.tabId;
        if (tabId) {
          functionsAllRef.current.setGridData(
            functionsAll?.map((ele) => {
              return {
                ...ele,
                functionFlag: tabId === ele.tabId ? false : ele.functionFlag,
              };
            }),
          );
          functionsRef.current.setGridData(
            functions?.map((ele) => {
              return {
                ...ele,
                functionFlag: tabId === ele.tabId ? false : ele.functionFlag,
              };
            }),
          );
        }
      }
    }
  };

  const checkFunction = (dataField, item, text) => {
    //
    const menus = menusRef.current.getGridData();
    const tabs = tabsRef.current.getGridData();
    const tabsAll = tabsAllRef.current.getGridData();
    const functions = functionsRef.current.getGridData();
    const functionsAll = functionsAllRef.current.getGridData();
    // text: old true/false
    if (dataField?.includes('enu')) {
      const activeTabs = [];

      menus?.forEach((row) => {
        // 눌려진 값이 대메뉴일때
        if (dataField === 'parentParentMenuFlag' && text) {
          // 끌때
          if (row?.parentParentMenuId === item[dataField?.replace('Flag', 'Id')]) {
            row['parentMenuFlag'] = false;
            row['menuFlag'] = false;
          }
        }

        if (dataField === 'parentMenuFlag' && text) {
          if (row?.parentMenuId === item[dataField?.replace('Flag', 'Id')]) {
            row['menuFlag'] = false;
          }
        } else if (dataField === 'parentMenuFlag' && !text) {
          if (row?.parentParentMenuId === item?.parentParentMenuId) {
            row['parentParentMenuFlag'] = true;
          }
        }
        // 3.소메뉴 활성화시
        if (dataField === 'menuFlag' && !text) {
          // 3-1.상위 메뉴 활성화
          if (row?.parentMenuId === item['parentMenuId']) {
            row['parentMenuFlag'] = true;
          }
          if (row?.parentParentMenuId === item['parentParentMenuId']) {
            row['parentParentMenuFlag'] = true;
          }
        }

        // 자기자신
        if (item[dataField?.replace('Flag', 'Id')] === row[dataField?.replace('Flag', 'Id')]) row[dataField] = !row[dataField];
      });

      // 소메뉴 active => 해당내용 보여주기
      if (dataField === 'menuFlag' && !text) {
        showTabsInThisMenu(item?.screenId);
      } else if (dataField === 'menuFlag' && text) {
        // 소메뉴 deactive => 탭, 기능 deactive all
        tabsRef.current.setGridData(
          tabs.map((tab) => {
            return {
              ...tab,
              tabFlag: tab.screenId === item.screenId ? false : tab.tabFlag,
            };
          }),
        );

        tabsAllRef.current.setGridData(
          tabsAll.map((tab) => {
            if (tab.screenId === item.screenId) activeTabs.push(tab.tabId);
            return {
              ...tab,
              tabFlag: tab.screenId === item.screenId ? false : tab.tabFlag,
            };
          }),
        );
        // 3-3. 기능활성화
        functionsRef.current.setGridData(
          functions.map((func) => {
            return {
              ...func,
              functionFlag: activeTabs?.includes(func.tabId) ? false : func.functionFlag,
            };
          }),
        );

        functionsAllRef.current.setGridData(
          functionsAll.map((func) => {
            return {
              ...func,
              functionFlag: activeTabs?.includes(func.tabId) ? false : func.functionFlag,
            };
          }),
        );
      } else if (dataField === 'parentMenuFlag' && text) {
        // 중메뉴 deactive => 소메뉴, 탭, 기능 deactive all
        const parentMenuId = item?.parentMenuId;
        let screenIds = [];
        menusRef?.current?.setGridData(
          menus?.map((m) => {
            if (m.parentMenuId === parentMenuId) screenIds?.push(m.screenId);
            return {
              ...m,
              menuFlag: m.parentMenuId === parentMenuId ? false : m.menuFlag,
            };
          }),
        );

        tabsRef.current.setGridData(
          tabs.map((tab) => {
            return {
              ...tab,
              tabFlag: screenIds?.includes(tab.screenId) ? false : tab.tabFlag,
            };
          }),
        );

        tabsAllRef.current.setGridData(
          tabsAll.map((tab) => {
            if (screenIds?.includes(tab.screenId)) activeTabs.push(tab.tabId);
            return {
              ...tab,
              tabFlag: screenIds?.includes(tab.screenId) ? false : tab.tabFlag,
            };
          }),
        );
        // 3-3. 기능활성화
        functionsRef.current.setGridData(
          functions.map((func) => {
            return {
              ...func,
              functionFlag: activeTabs?.includes(func.tabId) ? false : func.functionFlag,
            };
          }),
        );

        functionsAllRef.current.setGridData(
          functionsAll.map((func) => {
            return {
              ...func,
              functionFlag: activeTabs?.includes(func.tabId) ? false : func.functionFlag,
            };
          }),
        );
      } else if (dataField === 'parentParentMenuFlag' && text) {
        // 대메뉴 deactive => 중, 소메뉴, 탭, 기능 deactive all
        const parentParentMenuId = item?.parentParentMenuId;
        let screenIds = [];
        menusRef?.current?.setGridData(
          menus?.map((m) => {
            if (m.parentParentMenuId === parentParentMenuId) screenIds?.push(m.screenId);
            return {
              ...m,
              menuFlag: m.parentParentMenuId === parentParentMenuId ? false : m.menuFlag,
            };
          }),
        );

        tabsRef.current.setGridData(
          tabs.map((tab) => {
            return {
              ...tab,
              tabFlag: screenIds?.includes(tab.screenId) ? false : tab.tabFlag,
            };
          }),
        );

        tabsAllRef.current.setGridData(
          tabsAll.map((tab) => {
            if (screenIds?.includes(tab.screenId)) activeTabs.push(tab.tabId);
            return {
              ...tab,
              tabFlag: screenIds?.includes(tab.screenId) ? false : tab.tabFlag,
            };
          }),
        );
        // 3-3. 기능활성화
        functionsRef.current.setGridData(
          functions.map((func) => {
            return {
              ...func,
              functionFlag: activeTabs?.includes(func.tabId) ? false : func.functionFlag,
            };
          }),
        );

        functionsAllRef.current.setGridData(
          functionsAll.map((func) => {
            return {
              ...func,
              functionFlag: activeTabs?.includes(func.tabId) ? false : func.functionFlag,
            };
          }),
        );
      }
      menusRef.current.setGridData(menus);
      // 탭 체크
    } else if (dataField === 'tabFlag') {
      const items = tabsRef.current.getGridData();
      const tabs = tabsAllRef.current.getGridData();
      items?.forEach((row) => {
        if (item['tabId'] === row['tabId']) row['tabFlag'] = !row['tabFlag'];
      });
      tabsRef.current.setGridData(items);
      tabs?.forEach((ele) => {
        items?.forEach((tab) => {
          if (tab?.tabId === ele.tabId) ele['tabFlag'] = tab.tabFlag;
        });
      });

      // 탭이 꺼지면 자식되는 기능들 모두 off
      if (text) {
        functionsRef.current.setGridData(
          functions.map((func) => {
            return {
              ...func,
              functionFlag: func?.tabId === item?.tabId ? false : func.tabId,
            };
          }),
        );

        functionsAllRef.current.setGridData(
          functionsAll.map((func) => {
            return {
              ...func,
              functionFlag: func?.tabId === item?.tabId ? false : func.tabId,
            };
          }),
        );
      } else if (!text) {
        const { parentMenuId, parentParentMenuId } = menus?.filter((ele) => ele.screenId === item?.screenId)[0];
        menusRef?.current?.setGridData(
          menus?.map((menu) => {
            return {
              ...menu,
              menuFlag: menu.screenId === item?.screenId ? true : menu?.menuFlag,
              parentMenuFlag: parentMenuId === menu?.parentMenuId ? true : menu?.parentMenuFlag,
              parentParentMenuFlag: parentParentMenuId === menu?.parentParentMenuId ? true : menu?.parentParentMenuFlag,
            };
          }),
        );
      }

      tabsAllRef.current.setGridData(tabs);
      // 기능 체크
    } else {
      const activeScreens = [];
      functionsAll?.forEach((row) => {
        if (item[dataField?.replace('Flag', 'Id')] === row[dataField?.replace('Flag', 'Id')]) {
          row[dataField] = !row[dataField];
        }
      });
      functions?.forEach((row) => {
        if (item[dataField?.replace('Flag', 'Id')] === row[dataField?.replace('Flag', 'Id')]) row[dataField] = !row[dataField];
      });

      // 활성화시 부모되는 탭 active too
      if (!text) {
        tabsRef.current.setGridData(
          tabs.map((tab) => {
            return {
              ...tab,
              tabFlag: tab.tabId === item.tabId ? true : tab.tabFlag,
            };
          }),
        );

        tabsAllRef.current.setGridData(
          tabsAll.map((tab) => {
            if (tab.tabId === item.tabId) activeScreens?.push(tab?.screenId);
            return {
              ...tab,
              tabFlag: tab.tabId === item.tabId ? true : tab.tabFlag,
            };
          }),
        );
        if (activeScreens?.length > 0) {
          const { parentMenuId, parentParentMenuId } = menus?.filter((ele) => ele.screenId === activeScreens[0])[0];
          menusRef?.current?.setGridData(
            menus?.map((menu) => {
              return {
                ...menu,
                menuFlag: menu.screenId === activeScreens[0] ? true : menu?.menuFlag,
                parentMenuFlag: parentMenuId === menu?.parentMenuId ? true : menu?.parentMenuFlag,
                parentParentMenuFlag: parentParentMenuId === menu?.parentParentMenuId ? true : menu?.parentParentMenuFlag,
              };
            }),
          );
        }
        // const { parentMenuId, parentParentMenuId } = menus?.filter((ele) => ele.screenId === item?.screenId)[0];
      }

      functionsRef.current.setGridData(functions);
      functionsAllRef.current.setGridData(functionsAll);
    }
  };

  const MENU_COLUMNS: IGrid.Column[] = [
    {
      headerText: '',
      dataField: 'parentParentMenuFlag',
      dataType: 'boolean',
      width: 30,
      headerRenderer: {
        type: IGrid.headerRendererKind.CheckBoxHeaderRenderer,
        dependentMode: true,
        position: 'bottom',
        // checked:
        onClick: headerCheckFunction,
      },
      renderer: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
      cellMerge: true,
      mergeRef: 'parentParentMenuId',
      mergePolicy: 'restrict',
    },
    {
      headerText: '대메뉴',
      editable: true,
      dataField: 'parentParentMenuName',
      cellMerge: true,
    },
    {
      headerText: '',
      dataField: 'parentMenuFlag',
      width: 30,
      editable: true,
      headerRenderer: {
        type: IGrid.headerRendererKind.CheckBoxHeaderRenderer,
        dependentMode: true,
        position: 'bottom',
        onClick: headerCheckFunction,
      },
      renderer: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
      cellMerge: true,
      mergeRef: 'parentMenuId',
      mergePolicy: 'restrict',
    },
    {
      headerText: '중메뉴',
      editable: true,
      dataField: 'parentMenuName',
      cellMerge: true,
    },
    {
      headerText: '',
      dataField: 'menuFlag',
      width: 30,
      headerRenderer: {
        type: IGrid.headerRendererKind.CheckBoxHeaderRenderer,
        dependentMode: true,
        position: 'bottom',
        onClick: headerCheckFunction,
      },
      renderer: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
    },
    {
      headerText: '소메뉴',
      dataField: 'menuName',
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let div = '<div>';
        if (item?.openUrl) {
          const link = process.env.REACT_APP_API_URL + item?.openUrl;
          div = `<a class="click-fake-item" href="${link}" target="_blank" title="${link}">${item?.menuName}</span>`;
        } else {
          div += `<span>${item?.menuName}</span>`;
        }
        div += '</div>';
        return div;
      },
    },
  ];

  const TAB_COLUMNS: IGrid.Column[] = [
    {
      headerText: '',
      dataField: 'tabFlag',
      width: 30,

      headerRenderer: {
        type: IGrid.headerRendererKind.CheckBoxHeaderRenderer,
        dependentMode: true,
        position: 'bottom',
        onClick: headerCheckFunction,
      },
      renderer: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
    },
    {
      headerText: '탭',
      dataField: 'tabName',
    },
  ];

  const FUNCTION_COLUMNS: IGrid.Column[] = [
    {
      headerText: '',
      dataField: 'functionFlag',
      width: 30,
      headerRenderer: {
        type: IGrid.headerRendererKind.CheckBoxHeaderRenderer,
        dependentMode: true,
        position: 'bottom',
        onClick: headerCheckFunction,
      },
      renderer: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
    },
    {
      headerText: '유형',
      dataField: 'functionType',
      labelFunction(rowIndex, columnIndex, value, headerText, item, dataField, cItem) {
        if (value === 'MAIN') return '메인';
        if (value === 'GRID') return '그리드';
        return '상세';
      },
      width: 100,
      cellMerge: true,
      mergeRef: 'functionType',
      mergePolicy: 'restrict',
    },
    {
      headerText: '제목',
      dataField: 'functionTitleKr',
    },
    {
      headerText: '기능명',
      dataField: 'functionNameKr',
      width: 200,
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let div = '<div>';
        if (item?.openUrl) {
          const link = process.env.REACT_APP_API_URL + item?.openUrl;
          div = `<a class="click-fake-item" href="${link}" target="_blank" title="${link}">${item?.functionNameKr}</span>`;
        } else {
          div += `<span>${item?.functionNameKr}</span>`;
        }
        div += '</div>';
        return div;
      },
    },
  ];

  const makeData = (data) => {
    return {
      menus: data?.menus?.map((ele) => {
        return { menuId: ele };
      }),
      screens: data?.screens?.map((ele) => {
        return { screenId: ele };
      }),
      tabs: data?.tabs?.map((ele) => {
        return { tabId: ele };
      }),
      functions: data?.functions?.map((ele) => {
        return { functionId: ele };
      }),
    };
  };

  const filterAsStringData = () => {
    const data = {
      menus: [],
      screens: [],
      tabs: [],
      functions: [],
    };

    menusRef.current?.getGridData()?.forEach((menu) => {
      if (menu.menuFlag && !data?.menus.includes(menu.menuId)) {
        if (menu?.screenId) data?.screens.push(menu.screenId);
        if (menu?.parentMenuId && !data?.menus?.includes(menu?.parentMenuId)) data?.menus?.push(menu?.parentMenuId);
        if (menu?.parentParentMenuId && !data?.menus?.includes(menu?.parentParentMenuId)) data?.menus?.push(menu?.parentParentMenuId);
        data?.menus.push(menu?.menuId);
      }
    });

    tabsAllRef.current?.getGridData()?.forEach((tab) => {
      if (tab.tabFlag && !data?.tabs.includes(tab?.tabId)) {
        data?.tabs.push(tab?.tabId);
      }
    });

    functionsAllRef.current?.getGridData()?.forEach((func) => {
      if (func.functionFlag) {
        if (!data?.functions.includes(func?.functionId)) data?.functions.push(func?.functionId);
        // 등록관련 화면의 기능이 들어가면 화면 activate **
        if (func?.openUrl) {
          if (!data?.screens?.includes(MASTER_OBJ?.SCREENID_URL?.[func?.openUrl])) data?.screens.push(MASTER_OBJ?.SCREENID_URL?.[func?.openUrl]);
        }
      }
    });
    return data;
  };

  const getData = () => {
    const concentrate = filterAsStringData();
    if (concentrate) {
      return makeData(concentrate);
    }
  };

  return (
    <>
      <div className="grid2-wrapper" style={{ width: `calc(100% - 4rem)` }}>
        <div className="grid-wrapper" style={{ display: 'flex', flexDirection: 'row', width: '65%', padding: 0 }}>
          <GridBox
            gridRef={menusRef}
            columns={MENU_COLUMNS}
            gridProps={{
              ...defaultGridProps,
              enableSorting: false,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              showRowNumColumn: false,
              enableCellMerge: true,
              resetVScroll: false,
              width: 550,
              height: 560,
              editable: false,
            }}
          />
          <GridBox
            gridRef={tabsRef}
            columns={TAB_COLUMNS}
            gridProps={{
              ...defaultGridProps,
              enableSorting: false,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              showRowNumColumn: false,
              resetVScroll: false,
              independentAllCheckBox: true,
              width: 180,
              height: 560,
              editable: false,
            }}
          />
        </div>
        <div className="grid-wrapper same" style={{ width: '40%', padding: 0, paddingLeft: 10 }}>
          <GridBox
            gridRef={functionsRef}
            columns={FUNCTION_COLUMNS}
            gridProps={{
              ...defaultGridProps,
              enableSorting: false,
              independentAllCheckBox: true,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              showRowNumColumn: false,
              enableCellMerge: true,
              resetVScroll: false,
              editable: false,
              width: 490,
              height: 560,
            }}
          />
        </div>
        <div style={{ display: 'none' }}>
          <GridBox
            gridRef={tabsAllRef}
            columns={TAB_COLUMNS}
            gridProps={{
              ...defaultGridProps,
            }}
          />
          <GridBox
            gridRef={screenRef}
            columns={FUNCTION_COLUMNS}
            gridProps={{
              ...defaultGridProps,
            }}
          />
          <GridBox
            gridRef={functionsAllRef}
            columns={FUNCTION_COLUMNS}
            gridProps={{
              ...defaultGridProps,
            }}
          />
        </div>
      </div>
    </>
  );
};
