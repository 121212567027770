import { ReactNode, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { IPagingGrid, PARCEL_GRID_PAGE_ROW_COUNT } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
// service
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CargoManifestScheduleDTO, CargoManifestSearchDTO } from '../../../_interface/order';

import useGridButton from 'hooks/grid/useGridButton';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
// component
import { PagingListDTO } from 'interface/util';
import { cargoService } from 'pages/CARGO/_service/service';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const labellingKr = (data: PagingListDTO<CargoManifestScheduleDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        statusKr: MASTER_OBJ?.CM_STATUS_1?.[row?.status],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: CargoManifestSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await cargoService.getOrderCargoActionPaging('schedule', searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const downloadExcel = async (type: 'main') => {
    const prompt = window.confirm(`엑셀다운로드 를 시작합니다. \n※ 많은 양의 데이터는 30초 이상의 시간이 소요될 수 있습니다.`);
    if (prompt) {
      dispatch(setLoading('GET'));
      const { list } = labellingKr({ list: await cargoService.getOrderCargoAction('schedule/excel', searchObj) }, true);
      if (type === 'main') {
        excelGridRef.current.setGridData(list);
        excelGridRef.current.exportAsXlsx({ fileName: '통관예정' });
      }
      dispatch(setLoading(null));
    }
  };
  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'CARGO_MANIFEST_TRANSFER_EXCELDOWN_MAIN') {
      downloadExcel('main');
    }
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: PARCEL_GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const columns: IGrid.Column[] = [
    {
      headerText: '등록일시',
      dataField: 'registerDate',
    },
    {
      headerText: '주문번호',
      dataField: 'orderNumber',
    },
    {
      headerText: 'bigBag<br/>No.',
      dataField: 'bigBagId',
    },
    {
      headerText: 'batch<br/>No.',
      dataField: 'batchNo',
    },
    {
      headerText: 'HBL',
      dataField: 'houseNumber',
    },
    {
      headerText: '수취인정보',
      dataField: 'consigneeName',
      children: [
        {
          headerText: '수취인명',
          dataField: 'consigneeName',
        },
        {
          headerText: '우편번호',
          dataField: 'consigneeZipcode',
        },
        {
          headerText: '주소',
          dataField: 'consigneeAddr1',
        },
        {
          headerText: '상세주소',
          dataField: 'consigneeAddr2',
        },
        {
          headerText: '연락처',
          dataField: 'consigneePhone',
        },
      ],
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '상품명<br/>(중문)',
      dataField: 'productName',
    },
    {
      headerText: '상품명<br/>(영문)',
      dataField: 'productNameEn',
    },
    {
      headerText: '추천상품명<br/>(중문)',
      dataField: 'suggestedProductName',
    },
    {
      headerText: '추천상품명<br/>(영문)',
      dataField: 'suggestedProductNameEn',
    },
    {
      headerText: 'HS코드',
      dataField: 'hsCode',
    },
    {
      headerText: '상품링크',
      dataField: 'ecommerceWebsiteUrl',
    },
    {
      headerText: '개인통관<br/>고유번호',
      dataField: 'personalCustomsClearanceCode',
    },
  ];

  return (
    <div className="page-content">
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}

        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            pageRowCount: PARCEL_GRID_PAGE_ROW_COUNT,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
