import { useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import { TabDTO, TabSearchDTO } from 'interface/user';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { addMASTER } from 'redux/services/menuSlice';

export interface IDetailObj {
  searchObj?: TabSearchDTO;
  visible: boolean;
  item?: TabDTO;
  CRUDFn?: <T>(T) => void;
}

interface IDetailModal {
  detailObj: IDetailObj;
  setDetailObj: React.Dispatch<React.SetStateAction<IDetailObj>>;
}

interface TabDTOEX extends TabDTO {
  screenUrl?: string;
  screenId: string;
}

export const DetailModal = ({ detailObj, setDetailObj }: IDetailModal) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [data, setData] = useState<TabDTOEX>();
  useEffect(() => {
    addMasterOptions();
    if (detailObj?.item) {
      const data = detailObj?.item;
      data.url = MASTER_OBJ?.SCREENID_URL?.[data.screenId];
      setData(detailObj?.item);
    }
  }, []);

  const addMasterOptions = async () => {
    if (!MASTER_OBJ?.SCREENID_URL) {
      dispatch(
        addMASTER({
          SCREENID_URL: MASTER_OPS?.SCREEN_WHOLE?.map((ele) => {
            return {
              value: ele.screenId,
              label: ele.url,
            };
          }),
          MENU_NAME: MASTER_OPS?.MENU_WHOLE?.map((ele) => {
            return {
              value: ele.menuId,
              label: ele.menuName,
            };
          }),
        }),
      );
    }
  };

  const saveDetail = (data: TabDTO) => {
    if (!detailObj?.item) {
      if (window.confirm('등록 하시겠습니까?')) {
        detailObj?.CRUDFn({ data, crud: 'C', searchObj: detailObj?.searchObj });
      }
    } else {
      if (window.confirm('수정 하시겠습니까?')) {
        detailObj?.CRUDFn({ data, crud: 'U', searchObj: detailObj?.searchObj });
      }
    }
  };

  const deleteDetail = (data: TabDTO) => {
    if (window.confirm('삭제 하시겠습니까?')) {
      detailObj?.CRUDFn({ data: [data?.tabId], crud: 'U', searchObj: detailObj?.searchObj });
    }
  };

  return (
    <CommonModalNew
      style={{ width: 500 }}
      title={!detailObj?.item ? '탭 생성' : '탭 수정'}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      children={
        <>
          <label>탭 아이디*</label>
          <InputD
            disabled={!detailObj?.item}
            value={data?.tabId || ''}
            onChange={(e) => {
              setData({
                ...data,
                tabId: e.target.value,
              });
            }}
          />
          <label>탭명*</label>
          <InputD
            value={data?.tabName || ''}
            onChange={(e) => {
              setData({
                ...data,
                tabName: e.target.value,
              });
            }}
          />
          <label>설명</label>
          <textarea
            style={{ width: '100%' }}
            value={data?.description || ''}
            onChange={(e) => {
              setData({
                ...data,
                description: e.target.value,
              });
            }}
          />
          <label>연결화면*</label>
          <SelectD
            value={
              data?.screenId
                ? {
                    label: MASTER_OBJ?.SCREENID_URL?.[data?.screenId],
                    value: data?.screenId,
                  }
                : null
            }
            onChange={(option) => {
              setData({
                ...data,
                screenId: (option as OptionItem).value,
                screenUrl: (option as OptionItem).label,
              });
            }}
            options={MASTER_OPS?.SCREENID_URL}
          />
          <label>해딩화면의 탭순서</label>
          <InputD
            type="number"
            value={data?.sort || ''}
            onChange={(e) => {
              setData({
                ...data,
                sort: Number(e.target.value),
              });
            }}
          />

          <label>사용여부</label>
          <div className="radio-area one-line">
            <Label>
              <Input
                type="radio"
                checked={data?.useFlag || false}
                onChange={(e) => {
                  setData({
                    ...data,
                    useFlag: true,
                  });
                }}
              />
              &nbsp;&nbsp;사용
            </Label>
            <Label>
              <Input
                type="radio"
                checked={!data?.useFlag || false}
                onChange={(e) => {
                  setData({
                    ...data,
                    useFlag: false,
                  });
                }}
              />
              &nbsp;&nbsp;사용 안 함
            </Label>
          </div>
          <div style={{ paddingTop: 30, textAlign: 'right' }}>
            {detailObj?.item && (
              <button className="btn btn-danger" onClick={() => deleteDetail(data)}>
                삭제
              </button>
            )}
            <button className={detailObj?.item ? 'btn btn-orange' : 'btn btn-blue'} onClick={() => saveDetail(data)}>
              {detailObj?.item ? '수정' : '등록'}
            </button>
          </div>
        </>
      }
    />
  );
};
