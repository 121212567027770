import { useEffect, useRef, useState } from 'react';
// redux
import { Button, Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

import styled from 'styled-components';
import * as IGrid from 'aui-grid';

import { InvoiceSaveDTO, OrderSaveDTO2 } from '../../../ORDER/1order/orderListTab/_interface/order';
import { RAWDATA, setLoading } from 'redux/services/menuSlice';
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import { serviceStore } from 'services/services';
import ProductStockModal from 'pages/ORDER/1order/orderListTab/common/component/invocieDetail/productStockModal';
import ModelStockModal, { IPopupGridObj } from 'pages/ORDER/1order/orderListTab/common/component/invocieDetail/modelStockModal';
import { IOrders, Orders } from './components/orders';
//
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin: 40px 5px 5px 2px;
`;

const transOrderNumberOrderType = ['MOVE', 'DISPLAY', 'REPAIR', 'EXCHANGE'];

const Index = () => {
  const location = useLocation();
  const currentSeq = queryString.parse(location.search);
  //
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const [invoiceData, setInvoiceData] = useState<OrderSaveDTO2 & { partnerSeq: number }>();
  const [nowInvoice, setNowInvoice] = useState<number>(0);
  const [invoices, setInvoices] = useState<InvoiceSaveDTO[]>();

  const columns: IGrid.Column[] = [
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '수량',
      dataField: 'quantity',
      editable: true,
      formatString: '#,##0',
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        autoThousandSeparator: true,
        onlyNumeric: true,
      },
    },
    {
      headerText: '사은품',
      dataField: 'giftFlag',
      editable: true,
      renderer: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
        editable: true,
      },
    },
    {
      headerText: '판매가',
      dataField: 'sellingPrice',
      editable: true,
      formatString: '#,##0',
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        autoThousandSeparator: true,
        onlyNumeric: true,
      },
    },
  ];

  useEffect(() => {
    if (currentSeq['invoiceSeq']) fetchExInvoice(Number(currentSeq['invoiceSeq']));
    else setInitData();
  }, []);

  const fetchExInvoice = async (invoiceSeq: number) => {
    //
    const data = (await serviceStore.orderAction('copy', 'GET', { invoiceSeq }))?.data;
    if (data) {
      setInvoiceData({
        ...data,
        // orderDate: returnDateyyyymmdd(new Date()), // 주문일자
      });
      if (data?.partnerSeq || data?.modelGroup) {
        fetchInfraNCenterOptions(data?.partnerSeq, data?.modelGroup);
      }
      setInvoices([
        {
          liteFlag: false,
          partnerSeq: data?.partnerSeq,
        },
      ]);
    }
  };

  const setInitData = async (fetchData = null) => {
    const data = (await serviceStore.userAction(`my/info`, 'GET', null))?.data;
    if (data) {
      console.log(data);
      setInvoiceData((prev) => {
        return {
          ...prev,
          orderDate: returnDateyyyymmdd(new Date()), // 주문일자
          partnerSeq: data?.partnerSeq || null, // 판매사
          salesChannelSeq: data?.salesChannelSeq || 1000, //판매채널
          ...fetchData,
        };
      });
      if (data?.partnerSeq || data?.modelGroup) {
        fetchInfraNCenterOptions(data?.partnerSeq, data?.modelGroup);
      }
      setInvoices([
        {
          liteFlag: false,
          partnerSeq: data?.partnerSeq,
        },
      ]);
    }
  };

  const [infraNcenterOptions, setInfraNCenterOptions] = useState<{ CENTER: OptionItem[]; INFRA: OptionItem[]; RAW_DATA: any }[]>();
  const filterWithRawDataLabeled = (RAW_DATA, centerCode = null, infraSeq = null) => {
    const INFRA = [];
    const CENTER = [];
    const INFRA_DATA = centerCode ? RAW_DATA?.filter((ele) => ele.centerCode === centerCode) : RAW_DATA;
    const CENTER_DATA = infraSeq ? RAW_DATA?.filter((ele) => ele.infraSeq === infraSeq) : RAW_DATA;
    INFRA_DATA?.forEach((ele) => {
      if (!INFRA?.includes(ele.infraSeq)) INFRA?.push(ele.infraSeq);
    });
    CENTER_DATA?.forEach((ele) => {
      if (!CENTER?.includes(ele.centerCode)) CENTER?.push(ele.centerCode);
    });
    return {
      INFRA: INFRA?.map((ele) => {
        return {
          value: ele,
          label: MASTER_OBJ?.INFRA?.[ele],
        };
      }),
      CENTER: CENTER?.map((ele) => {
        return { value: ele, label: MASTER_OBJ?.CENTER_WHOLE?.[ele] };
      }),
    };
  };

  const filterInfraNCenterOptions = (nowInvoice: number, centerCode: string, infraSeq: number) => {
    const { INFRA, CENTER } = filterWithRawDataLabeled(infraNcenterOptions?.[nowInvoice]?.RAW_DATA, centerCode, infraSeq);
    const newOptions = infraNcenterOptions?.map((ele, idx) => {
      if (idx !== nowInvoice) {
        return ele;
      } else {
        return {
          ...ele,
          INFRA,
          CENTER,
        };
      }
    });
    setInfraNCenterOptions(newOptions);
  };

  const fetchInfraNCenterOptions = async (partnerSeq = null, modelGroup = null, idx = null) => {
    let url = `infra/sub/detail?`;
    if (partnerSeq) url += `&partnerSeq=${partnerSeq}`;
    if (modelGroup) url += `&modelGroup=${modelGroup}`;

    const data = (await serviceStore.warehouseAction(url, 'GET', null))?.data;
    const { INFRA, CENTER } = filterWithRawDataLabeled(data?.details);
    if (idx) {
      const newOptions = infraNcenterOptions?.map((ele, i) => {
        if (i === idx)
          return {
            RAW_DATA: data?.details,
            INFRA,
            CENTER,
          };
        else return ele;
      });
      setInfraNCenterOptions(newOptions);
    } else {
      setInfraNCenterOptions([
        {
          RAW_DATA: data?.details,
          INFRA,
          CENTER,
        },
      ]);
    }
  };

  const addingNewInvoice = () => {
    const _invoices = JSON.parse(JSON.stringify(invoices));
    _invoices.push({ liteFlag: false, partnerSeq: invoiceData?.partnerSeq });
    const newInv = _invoices?.map((inv, i) => {
      return {
        ...inv,
        productIndex: i,
        invoiceInventorySeq: i > 0 ? inv?.invoiceInventorySeq : null,
      };
    });
    setInvoices(newInv);
    const RAW_DATA = infraNcenterOptions?.[0]?.RAW_DATA;
    const { CENTER, INFRA } = filterWithRawDataLabeled(RAW_DATA);

    const addedOptions = infraNcenterOptions?.concat({
      RAW_DATA,
      CENTER,
      INFRA,
    });
    setInfraNCenterOptions(addedOptions);
  };

  const handleDaumPost = (type) => {
    new (window as any).daum.Postcode({
      oncomplete: function (data) {
        const { zonecode, roadAddress, jibunAddress } = data;
        switch (type) {
          case 'customer':
            setInvoiceData((prev) => {
              return {
                ...prev,
                customerZipcode: zonecode,
                customerAddr1: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
              };
            });

            break;
          case 'receiver':
            setInvoiceData((prev) => {
              return {
                ...prev,
                receiverZipcode: zonecode,
                receiverAddr1: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
              };
            });

            break;

          default:
            break;
        }
      },
    }).open();
  };

  const [productStockObj, setProductStockObj] = useState<IPopupGridObj>();
  const [modelStockObj, setModelStockObj] = useState<IPopupGridObj>();

  useEffect(() => {
    if (modelStockObj?.visible === false && modelStockObj?.returns) setModelStockObj(null);
    if (productStockObj?.visible === false && productStockObj?.returns) setProductStockObj(null);
  }, [productStockObj, productStockObj]);

  useEffect(() => {
    if (modelStockObj?.returns) {
      const rows = gridRef.current.getGridData();
      const returns = modelStockObj?.returns?.map((row) => {
        return {
          ...row,
          quantity: 1,
        };
      });
      const added = rows?.concat(returns);
      const newInvoices = invoices?.map((inv, i) => {
        if (i !== nowInvoice) return inv;
        else
          return {
            ...inv,
            productSeq: null,
            productName: null,
            productQuantity: 1,
            productModels: added,
          };
      });
      setInvoices(newInvoices);
      gridRef.current.setGridData(added);
    }
  }, [modelStockObj?.returns]);

  const editableDataField = ['giftFlag', 'quantity'];
  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.bind('cellEditEnd', bindCellEdit);
    }
  }, []);

  const bindCellEdit = (e: IGrid.CellEditEndEvent) => {
    if (editableDataField?.includes(e.dataField)) {
      const newInvoices = invoices?.map((inv, i) => {
        if (i !== nowInvoice) return inv;
        else
          return {
            ...inv,
            productSeq: null,
            productName: null,
            productQuantity: 1,
          };
      });
      setInvoices(newInvoices);
    }
  };
  useEffect(() => {
    if (productStockObj?.returns) {
      const items = gridRef?.current?.getGridData();
      if (items?.length > 0 && window.confirm('상품추가시에 상품목록이 초기화됩니다 진행하시겠습니까?')) {
        fetchProductAndAddon(productStockObj?.returns[0]);
      } else if (items?.length === 0) {
        fetchProductAndAddon(productStockObj?.returns[0]);
      }
    }
  }, [productStockObj?.returns]);

  const fetchProductAndAddon = async (item) => {
    const products = (await serviceStore.orderAction('product/model/group', 'GET', { productSeq: item?.productSeq }))?.data;
    if (products) {
      const newInvoices = invoices?.map((inv, i) => {
        if (i !== nowInvoice) return inv;
        else
          return {
            ...inv,
            productSeq: item.productSeq,
            productName: item.productName,
            modelGroup: item.modelGroup,
            installDuration: item.installDuration,
            memberCount: item.memberCount,
            productQuantity: 1,
            productModels: products,
          };
      });
      setInvoices(newInvoices);
      gridRef.current.setGridData(products);

      alert('상품이 등록되었습니다.');
    }
  };

  const [filteredFeeTypeOps, setFilteredFeeTypeOps] = useState<OptionItem[]>();
  const handleProductRow = (idx, id) => {
    if (invoices?.[idx]?.partnerSeq) {
      if (id === 'ORDERPRODUCT') {
        setProductStockObj((prev) => {
          return { ...prev, partnerSeq: invoices?.[idx]?.partnerSeq, visible: true };
        });
      } else if (id === 'ORDERMODEL') {
        setModelStockObj((prev) => {
          return { ...prev, partnerSeq: invoices?.[idx]?.partnerSeq, visible: true };
        });
      } else if (id === 'ORDERMODELCLEAR') {
        const checked = gridRef.current.getCheckedRowItems()?.map((ele) => ele.rowIndex);
        const rows = gridRef.current.getGridData();
        const filtered = rows.filter((_, idx) => !checked?.includes(idx));
        if (filtered?.length === 0) {
          const newInvoices = invoices?.map((inv, i) => {
            if (i !== idx) return inv;
            else
              return {
                ...inv,
                productSeq: null,
                productName: null,
                modelGroup: null,
                installDuration: null,
                memberCount: null,
                productQuantity: null,
                productModels: filtered,
              };
          });
          setInvoices(newInvoices);
        } else {
          const newInvoices = invoices?.map((inv, i) => {
            if (i !== idx) return inv;
            else
              return {
                ...inv,
                productSeq: null,
                productName: null,
                productQuantity: 1,
                productModels: filtered,
              };
          });
          setInvoices(newInvoices);
        }
        gridRef.current.setGridData(filtered);
      }
    } else {
      alert('판매사를 선택해주세요!');
    }
  };

  const validate = (orderInfo: OrderSaveDTO2, invoices: InvoiceSaveDTO[]) => {
    if (!orderInfo?.externalOrderNumber) {
      alert('외부주문번호는 필수 입력값입니다!!');
      return false;
    }
    if (!orderInfo?.orderDate) {
      alert('주문일자는 필수 입력값입니다!!');
      return false;
    }
    if (!orderInfo?.customerName) {
      alert('주문인명은 필수 입력값입니다!!');
      return false;
    }
    if (!orderInfo?.customerName) {
      alert('주문인명은 필수 입력값입니다!!');
      return false;
    }
    if (!orderInfo?.customerPhone || !orderInfo?.customerPhone2) {
      alert('주문인 연락처는 필수 입력값입니다!!');
      return false;
    }
    if (!orderInfo?.customerAddr1) {
      alert('주문인 주소는 필수 입력값입니다!!');
      return false;
    }
    if (!orderInfo?.receiverName) {
      alert('수령인명은 필수 입력값입니다!!');
      return false;
    }
    if (!orderInfo?.receiverPhone || !orderInfo?.receiverPhone2) {
      alert('주문인 연락처는 필수 입력값입니다!!');
      return false;
    }
    if (!orderInfo?.receiverAddr1) {
      alert('주문인 주소는 필수 입력값입니다!!');
      return false;
    }
    let invFlag = true;
    invoices?.forEach((inv, iv) => {
      if (invFlag) {
        if (!inv?.partnerSeq && invFlag) {
          alert(`송장정보[${iv + 1}]의 판매사는 필수 입력값입니다!`);
          invFlag = false;
        }
        if (!inv?.orderType && invFlag) {
          alert(`송장정보[${iv + 1}]의 주문유형은 필수 입력값입니다!`);
          invFlag = false;
        }
        if (!inv?.feeType && invFlag) {
          alert(`송장정보[${iv + 1}]의 송장유형은 필수 입력값입니다!`);
          invFlag = false;
        }
        if (!inv?.externalInvoiceNumber && invFlag) {
          alert(`송장정보[${iv + 1}]의 외부송장번호는 필수 입력값입니다!`);
          invFlag = false;
        }

        const models = inv?.productModels;
        if (invFlag && (!models || models?.length === 0)) {
          alert(`송장정보[${iv + 1}]의 [상품정보]가 입력되지 않았습니다!`);
          invFlag = false;
        }
        if (invFlag && !models?.every((ele) => ele.quantity && Number(ele.quantity) > 0)) {
          alert(`송장정보[${iv + 1}]의 [상품정보]에 수량이 입력되지 않은 제품이 있습니다!`);
          invFlag = false;
        }
      }
    });

    return invFlag && true;
  };

  const handleInvoiceContent = (idx: number, id: string, value: any) => {
    const newInvoices = invoices?.map((inv, i) => {
      if (i !== idx) return inv;
      else
        return {
          ...inv,
          [id]: value,
          // 판매사 미선택시 초기화
          centerCode: id === 'partnerSeq' && value === null ? null : id === 'centerCode' ? value : inv?.centerCode,
          infraSeq: id === 'partnerSeq' && value === null ? null : id === 'infraSeq' ? value : inv?.infraSeq,
          // 주문유형 선택시 활성화
          feeType: id === 'orderType' ? null : id === 'feeType' ? value : inv?.feeType,
          parcelType: id === 'orderType' ? null : id === 'parcelType' ? value : inv?.parcelType,
          productSeq: id === 'productQuantity' ? null : id === 'productSeq' ? value : inv?.productSeq,
          transOrderNumber:
            id === 'orderType' && transOrderNumberOrderType?.includes(value) ? inv?.transOrderNumber : id === 'orderType' && !transOrderNumberOrderType?.includes(value) ? null : inv?.transOrderNumber,
        };
    });

    if (id === 'centerCode') filterInfraNCenterOptions(idx, value, invoices?.[idx]?.infraSeq);
    else if (id === 'infraSeq') filterInfraNCenterOptions(idx, invoices?.[idx]?.centerCode, value);
    else if (id === 'partnerSeq') fetchInfraNCenterOptions(value, null, idx);

    setInvoices(newInvoices);
  };

  const registerOrderData = async (infoData, invoices) => {
    if (validate(infoData, invoices)) {
      dispatch(setLoading('POST'));
      const dataDTO = {
        ...infoData,
        invoices,
      };
      const rs = await serviceStore.orderAction(`v2/save`, 'POST', null, dataDTO);
      if (rs.status === 200) {
        alert('등록에 성공했습니다!');
        window.location.href = '/manageorderInfoList';
      }
      dispatch(setLoading(null));
    }
  };

  const [orderObj, setOrderObj] = useState<IOrders>();

  useEffect(() => {
    if (orderObj?.returns) {
      alert('선택된 주문을 적용합니다');
      setInitData(orderObj?.returns);
      setOrderObj(null);
    }
  }, [orderObj?.returns]);

  return (
    <>
      <div className="page-content">
        {orderObj?.visible && <Orders orderObj={orderObj} setOrderObj={setOrderObj} />}
        {productStockObj?.visible && <ProductStockModal productStockObj={productStockObj} setProductStockObj={setProductStockObj} />}
        {modelStockObj?.visible && <ModelStockModal modelStockObj={modelStockObj} setModelStockObj={setModelStockObj} />}
        <Row style={{ textAlign: 'right' }}>
          <Col>
            <Button
              className="btn-confirm btn"
              onClick={() => {
                registerOrderData(invoiceData, invoices);
              }}
            >
              주문등록
            </Button>
          </Col>
        </Row>
        <>
          <Title>주문정보</Title>
          <Row>
            <Col>
              <label className="col-form-label">주문번호</label>
              <InputD
                value={invoiceData?.orderSeq || ''}
                placeholder="주문정보 조회"
                onClick={() => {
                  //
                  setOrderObj({
                    visible: true,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*외부주문번호</label>
              <InputD
                value={invoiceData?.externalOrderNumber || ''}
                onChange={(e) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      externalOrderNumber: e.target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*판매채널</label>
              <SelectD
                hasNull={false}
                options={MASTER_OPS?.SALES_CHANNEL}
                value={
                  invoiceData?.salesChannelSeq
                    ? {
                        value: invoiceData?.salesChannelSeq,
                        label: MASTER_OBJ?.SALES_CHANNEL?.[invoiceData?.salesChannelSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      salesChannelSeq: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">판매처</label>
              <InputD
                value={invoiceData?.seller || ''}
                onChange={(e) => {
                  setInvoiceData({
                    ...invoiceData,
                    seller: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*주문일자</label>
              <FlatpickrD
                options={{
                  mode: 'single',
                }}
                value={invoiceData?.orderDate}
                onChange={(opt) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      orderDate: returnDateyyyymmdd(opt[0]),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">추천인명</label>
              <InputD
                value={invoiceData?.recommenderName || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      recommenderName: target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">추천인고유번호</label>
              <InputD
                value={invoiceData?.recommenderNumber || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      recommenderNumber: target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col />
          </Row>
          <Row>
            <Col lg={3}>
              <label className="col-form-label">*주문인명</label>
              <InputD
                value={invoiceData?.customerName || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      customerName: target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col lg={3}>
              <label className="col-form-label">*주문인 연락처</label>
              <div style={{ display: 'flex' }}>
                <InputD
                  wrapperstyle={{ width: '50%' }}
                  placeholder="연락처1 입력..."
                  value={invoiceData?.customerPhone || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        customerPhone: target.value,
                      };
                    });
                  }}
                />
                <InputD
                  wrapperstyle={{ width: '50%' }}
                  placeholder="연락처2 입력..."
                  value={invoiceData?.customerPhone2 || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        customerPhone2: target.value,
                      };
                    });
                  }}
                />
              </div>
            </Col>
            <Col lg={6}>
              <label className="col-form-label">*주문인 주소</label>
              <div style={{ display: 'flex' }}>
                <InputD
                  wrapperstyle={{ width: 130 }}
                  readOnly
                  onClick={() => {
                    handleDaumPost('customer');
                  }}
                  placeholder="우편번호"
                  style={{ cursor: 'pointer' }}
                  value={invoiceData?.customerZipcode || ''}
                />
                <InputD
                  wrapperstyle={{ width: '40%' }}
                  placeholder="우편번호를 클릭하세요"
                  disabled
                  value={invoiceData?.customerAddr1 || ''}
                  onChange={(e) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        customerAddr1: e.target.value,
                      };
                    });
                  }}
                />
                <InputD
                  wrapperstyle={{ width: 'calc(60% - 130px)' }}
                  placeholder="상세주소를 입력하세요"
                  value={invoiceData?.customerAddr2 || ''}
                  onChange={(e) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        customerAddr2: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
        </>
        <>
          <div style={{ width: '50%', position: 'relative' }}>
            <Title>배송정보</Title>
            <div style={{ left: 100, position: 'absolute', top: 5 }}>
              <label style={{ marginRight: 4 }}>주문인 정보와 동일</label>
              <InputD
                type="checkbox"
                checked={invoiceData?.customerName === invoiceData?.receiverName && invoiceData?.customerPhone === invoiceData?.receiverPhone}
                onChange={(e) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      receiverName: prev.customerName,
                      receiverPhone: prev.customerPhone,
                      receiverPhone2: prev.customerPhone2,
                      receiverZipcode: prev.customerZipcode,
                      receiverAddr1: prev.customerAddr1,
                      receiverAddr2: prev.customerAddr2,
                    };
                  });
                }}
              />
            </div>
          </div>
          <Row>
            <Col lg={3}>
              <label className="col-form-label">*수령인명</label>
              <InputD
                value={invoiceData?.receiverName || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      receiverName: target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col lg={3}>
              <label className="col-form-label">*수령인 연락처</label>
              <div style={{ display: 'flex' }}>
                <InputD
                  wrapperstyle={{ width: '50%' }}
                  placeholder="연락처1 입력..."
                  value={invoiceData?.receiverPhone || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        receiverPhone: target.value,
                      };
                    });
                  }}
                />
                <InputD
                  wrapperstyle={{ width: '50%' }}
                  placeholder="연락처2 입력..."
                  value={invoiceData?.receiverPhone2 || ''}
                  onChange={({ target }) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        receiverPhone2: target.value,
                      };
                    });
                  }}
                />
              </div>
            </Col>
            <Col lg={6}>
              <label className="col-form-label">*수령인 주소</label>
              <div style={{ display: 'flex' }}>
                <InputD
                  wrapperstyle={{ width: 130 }}
                  readOnly
                  onClick={() => {
                    handleDaumPost('customer');
                  }}
                  placeholder="우편번호"
                  style={{ cursor: 'pointer' }}
                  value={invoiceData?.receiverZipcode || ''}
                />
                <InputD
                  wrapperstyle={{ width: '40%' }}
                  placeholder="우편번호를 클릭하세요"
                  disabled
                  value={invoiceData?.receiverAddr1 || ''}
                  onChange={(e) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        receiverAddr1: e.target.value,
                      };
                    });
                  }}
                />
                <InputD
                  wrapperstyle={{ width: 'calc(60% - 130px)' }}
                  placeholder="상세주소를 입력하세요"
                  value={invoiceData?.receiverAddr2 || ''}
                  onChange={(e) => {
                    setInvoiceData((prev) => {
                      return {
                        ...prev,
                        receiverAddr2: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">희망일</label>
              <FlatpickrD
                options={{
                  mode: 'single',
                }}
                onReset={() => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      promiseDate: null,
                    };
                  });
                }}
                value={invoiceData?.promiseDate}
                onChange={(opt) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      promiseDate: returnDateyyyymmdd(opt[0]),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">희망시간대</label>
              <SelectD
                options={MASTER_OPS?.PROMISE_TIME_ZONE}
                value={
                  invoiceData?.promiseTimeZone
                    ? {
                        value: invoiceData?.promiseTimeZone,
                        label: MASTER_OBJ?.PROMISE_TIME_ZONE?.[invoiceData?.promiseTimeZone],
                      }
                    : null
                }
                onChange={({ value }) => {
                  setInvoiceData({
                    ...invoiceData,
                    promiseTimeZone: value,
                  });
                }}
              />
            </Col>
            <Col />
            <Col />
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">고객 요청사항</label>
              <textarea
                value={invoiceData?.shipmentNote || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      shipmentNote: target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">콜센터 요청사항</label>
              <textarea
                value={invoiceData?.callCenterNote || ''}
                onChange={({ target }) => {
                  setInvoiceData((prev) => {
                    return {
                      ...prev,
                      callCenterNote: target.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
        </>
        <>
          <Title>송장정보</Title>
          <div className="grid-button-area space-between">
            <div>
              {invoices?.map((ele, idx) => {
                return (
                  <div
                    className={nowInvoice === idx ? 'orange' : 'light-orange'}
                    key={`productModels_idx_${idx}`}
                    onClick={() => {
                      gridRef?.current?.setGridData(invoices?.[idx]?.productModels || []);
                      setNowInvoice(idx);
                    }}
                  >
                    {idx + 1}
                  </div>
                );
              })}
              <div
                className={'light-orange'}
                onClick={() => {
                  addingNewInvoice();
                }}
              >
                + 추가
              </div>
            </div>
            <div>
              <div
                className={'red'}
                onClick={() => {
                  if (invoices?.length > 1) {
                    const filtered = invoices.filter((_, i) => i !== nowInvoice);
                    const nowinvIdx = nowInvoice - 1 > 0 ? nowInvoice - 1 : 0;
                    setNowInvoice(nowinvIdx);
                    setInvoices(filtered);
                    gridRef?.current?.setGridData(invoices?.[nowinvIdx]?.productModels);
                  } else {
                    alert('송장 정보는 적어도 1개 이상이어야 합니다!');
                  }
                }}
              >
                - 삭제
              </div>
            </div>
          </div>
          <div style={{ position: 'relative', height: 336, width: '100%', paddingTop: 20, borderTop: '1px solid lightgray' }}>
            {invoices?.map((invoice, idx) => {
              return (
                <div style={{ position: 'absolute', zIndex: nowInvoice === idx ? 2 : 1, width: '100%', backgroundColor: 'white' }} key={`invoice_${idx}`}>
                  <Row>
                    <Col>
                      <label className="col-form-label">*등록유형</label>
                      <SelectD
                        hasNull={false}
                        options={MASTER_OPS?.LITE_FLAG}
                        value={
                          invoice?.liteFlag !== null
                            ? {
                                value: invoice?.liteFlag,
                                label: MASTER_OBJ?.LITE_FLAG?.[invoice?.liteFlag + ''],
                              }
                            : null
                        }
                        onChange={(option) => {
                          handleInvoiceContent(idx, 'lightFlag', (option as OptionItem)?.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <label className="col-form-label">*판매사</label>
                      <SelectD
                        hasNull={false}
                        options={MASTER_OPS?.SELLER_SELLER_AUTH}
                        value={
                          invoice?.partnerSeq
                            ? {
                                value: invoice?.partnerSeq,
                                label: MASTER_OBJ?.SELLER_WHOLE?.[invoice?.partnerSeq],
                              }
                            : null
                        }
                        onChange={(option) => {
                          handleInvoiceContent(idx, 'partnerSeq', (option as OptionItem)?.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <label className="col-form-label">인프라</label>
                      <SelectD
                        isDisabled={!invoice?.partnerSeq}
                        options={infraNcenterOptions?.[idx]?.INFRA}
                        value={
                          invoice?.infraSeq
                            ? {
                                value: invoice?.infraSeq,
                                label: MASTER_OBJ?.INFRA?.[invoice?.infraSeq],
                              }
                            : null
                        }
                        onChange={(option) => {
                          handleInvoiceContent(idx, 'infraSeq', (option as OptionItem)?.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <label className="col-form-label">창고</label>
                      <SelectD
                        isDisabled={!invoice?.partnerSeq}
                        options={infraNcenterOptions?.[idx]?.CENTER}
                        value={
                          invoice?.centerCode
                            ? {
                                value: invoice?.centerCode,
                                label: MASTER_OBJ?.CENTER_WHOLE?.[invoice?.centerCode],
                              }
                            : null
                        }
                        onChange={(option) => {
                          handleInvoiceContent(idx, 'centerCode', (option as OptionItem)?.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="col-form-label">*주문유형</label>
                      <SelectD
                        hasNull={false}
                        options={MASTER_OPS?.ORDER_TYPE}
                        value={
                          invoice?.orderType
                            ? {
                                value: invoice?.orderType,
                                label: MASTER_OBJ?.ORDER_TYPE?.[invoice?.orderType],
                              }
                            : null
                        }
                        onChange={(option) => {
                          const feeTypeOps = MASTER_OPS?.[`${RAWDATA}ORDER_TYPE`]
                            ?.filter((ele) => ele.code === (option as OptionItem)?.value)?.[0]
                            ?.subCodes?.map((ele) => {
                              return {
                                value: ele.code,
                                label: ele.codeName,
                              };
                            });
                          setFilteredFeeTypeOps(feeTypeOps);
                          handleInvoiceContent(idx, 'orderType', (option as OptionItem)?.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <label className="col-form-label">*송장유형</label>
                      <SelectD
                        isDisabled={!invoice?.orderType}
                        hasNull={false}
                        options={filteredFeeTypeOps}
                        value={
                          invoice?.feeType
                            ? {
                                value: invoice?.feeType,
                                label: MASTER_OBJ?.FEE_TYPE?.[invoice?.feeType],
                              }
                            : null
                        }
                        onChange={(option) => {
                          handleInvoiceContent(idx, 'feeType', (option as OptionItem)?.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <label className="col-form-label">*외부송장번호</label>
                      <InputD
                        value={invoice?.externalInvoiceNumber || ''}
                        onChange={(e) => {
                          handleInvoiceContent(idx, 'externalInvoiceNumber', e.target?.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <label className="col-form-label">
                        {transOrderNumberOrderType?.includes(invoice?.orderType) && '*'}
                        묶음주문번호
                      </label>
                      <InputD
                        disabled={!transOrderNumberOrderType?.includes(invoice?.orderType)}
                        value={invoice?.transOrderNumber || ''}
                        onChange={(e) => {
                          handleInvoiceContent(idx, 'transOrderNumber', e.target?.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="col-form-label">취소비용 고객부담</label>
                      <SelectD
                        options={MASTER_OPS?.OX_FLAG}
                        value={
                          invoice?.cancelChargeFlag
                            ? {
                                value: invoice?.cancelChargeFlag,
                                label: MASTER_OBJ?.OX_FLAG?.[invoice?.cancelChargeFlag + ''],
                              }
                            : null
                        }
                        onChange={(option) => {
                          handleInvoiceContent(idx, 'cancelChargeFlag', (option as OptionItem)?.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <label className="col-form-label">착불비</label>
                      <InputD
                        type="number"
                        value={invoice?.cashOnDelivery || ''}
                        onChange={(e) => {
                          handleInvoiceContent(idx, 'cashOnDelivery', Number(e.target?.value));
                        }}
                      />
                    </Col>
                    <Col>
                      <label className="col-form-label">택배사</label>
                      <InputD
                        disabled={invoice?.orderType !== 'PARCEL'}
                        value={invoice?.parcelType || ''}
                        onChange={(e) => {
                          handleInvoiceContent(idx, 'parcelType', e.target?.value);
                        }}
                      />
                    </Col>
                    <Col />
                  </Row>
                  <Row>
                    <Col>
                      <label className="col-form-label">운임구분</label>
                      <SelectD
                        options={MASTER_OPS?.PARCEL_PAY_TYPE}
                        // value={
                        //   invoice?.parcelPayType
                        //     ? {
                        //         value: invoice?.parcelPayType,
                        //         label: MASTER_OBJ?.PARCEL_PAY_TYPE?.[invoice?.parcelPayType],
                        //       }
                        //     : null
                        // }
                        // onChange={(option) => {
                        //   handleInvoiceContent(idx, 'parcelPayType', (option as OptionItem)?.value);
                        // }}
                      />
                    </Col>
                    <Col>
                      <label className="col-form-label">도선료</label>
                      <InputD
                        type="number"
                        // value={invoice?.parcelExtraFee || ''}
                        // onChange={(e) => {
                        //   handleInvoiceContent(idx, 'parcelExtraFee', Number(e.target?.value));
                        // }}
                      />
                    </Col>
                    <Col />
                    <Col />
                  </Row>

                  <Title>상품정보</Title>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: 150, display: 'flex', alignItems: 'center' }}>
                      <span className="b">상품 번호 :</span> &nbsp;&nbsp; &nbsp;&nbsp;{invoice?.productSeq || '-'} &nbsp;&nbsp; &nbsp;&nbsp;
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="b">상품명 :&nbsp;&nbsp;</span>
                    <InputD
                      disabled={invoice?.productSeq !== null || gridRef?.current?.getGridData()?.length < 1}
                      placeholder="미등록 상품명을 별도로 입력하지 않을 시 자동 생성 됩니다. (예 : 라클라우드 외1건)"
                      style={{ width: 415 }}
                      value={invoice?.productName || ''}
                      onChange={(e) => {
                        handleInvoiceContent(idx, 'productName', e.target?.value);
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', marginTop: 5, alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="b">상품 수량 :</span>&nbsp;&nbsp;
                        <InputD
                          style={{ width: 50 }}
                          type="number"
                          disabled={gridRef?.current?.getGridData()?.length < 1}
                          value={invoice?.productQuantity || ''}
                          onChange={(e) => {
                            handleInvoiceContent(idx, 'productQuantity', Number(e.target?.value));
                          }}
                        />
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
                        <span className="b" style={{ width: 60 }}>
                          제품그룹 :&nbsp;&nbsp;
                        </span>
                        <span style={{ width: 120 }}>
                          <SelectD
                            isDisabled={invoice?.productSeq !== null || gridRef?.current?.getGridData()?.length < 1}
                            value={
                              invoice?.modelGroup
                                ? {
                                    value: invoice?.modelGroup,
                                    label: MASTER_OBJ?.MODEL_GROUP?.[invoice?.modelGroup],
                                  }
                                : null
                            }
                            options={MASTER_OPS?.MODEL_GROUP}
                            onChange={(option) => {
                              fetchInfraNCenterOptions((option as OptionItem)?.value);
                              handleInvoiceContent(idx, 'modelGroup', (option as OptionItem)?.value);
                            }}
                          />
                        </span>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
                        <span className="b" style={{ width: 120 }}>
                          배송팀유형 :&nbsp;&nbsp;
                        </span>
                        <SelectD
                          isDisabled={invoice?.productSeq !== null || gridRef?.current?.getGridData()?.length < 1}
                          value={
                            invoice?.memberCount
                              ? {
                                  value: invoice?.memberCount + '',
                                  label: MASTER_OBJ?.TEAM_TYPE?.[invoice?.memberCount],
                                }
                              : null
                          }
                          options={MASTER_OPS?.TEAM_TYPE}
                          onChange={(option) => {
                            handleInvoiceContent(idx, 'memberCount', (option as OptionItem)?.value);
                          }}
                        />
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
                        <span className="b" style={{ width: 150 }}>
                          설치소요시간 :&nbsp;&nbsp;
                        </span>
                        <SelectD
                          isDisabled={invoice?.productSeq !== null || gridRef?.current?.getGridData()?.length < 1}
                          value={
                            invoice?.installDuration + ''
                              ? {
                                  value: invoice?.installDuration,
                                  label: MASTER_OBJ?.INSTALL_DURATION?.[invoice?.installDuration],
                                }
                              : null
                          }
                          options={MASTER_OPS?.INSTALL_DURATION}
                          onChange={(option) => {
                            handleInvoiceContent(idx, 'installDuration', (option as OptionItem)?.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="grid-button-area only-right">
                      <div
                        className="red"
                        onClick={() => {
                          handleProductRow(idx, 'ORDERMODELCLEAR');
                        }}
                      >
                        - 행삭제
                      </div>
                      <div
                        className="orange"
                        onClick={() => {
                          handleProductRow(idx, 'ORDERPRODUCT');
                        }}
                      >
                        + 상품등록
                      </div>
                      <div
                        className="orange"
                        onClick={() => {
                          handleProductRow(idx, 'ORDERMODEL');
                        }}
                      >
                        + 제품등록
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: 50 }}>
            <GridBox
              gridRef={gridRef}
              columns={columns}
              gridProps={{
                height: 300,
                editable: true,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
            />
          </div>
        </>
      </div>
    </>
  );
};

export default Index;
