import { KeyboardEvent } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD } from 'components/reactstrap/reactstrap';

// redux
import { ScreenSearchDTO } from 'interface/user';

export const SearchBox = (props: ISearchBoxProps<ScreenSearchDTO>) => {
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">화면ID</label>
          <InputD
            value={searchObj?.screenId || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                screenId: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">화면명</label>
          <InputD
            value={searchObj?.screenName || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                screenName: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">화면주소</label>
          <InputD
            value={searchObj?.url || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                url: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">유사검색여부</label>
          <div className="radio-select-area">
            <span
              className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: true,
                  };
                });
              }}
            >
              유사검색
            </span>
            <span
              className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: false,
                  };
                });
              }}
            >
              일치검색
            </span>
          </div>
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
      </Row>
    </Form>
  );
};
