import { IPagingGrid } from 'common/grid/gridBox';
import { IdefaultSearchObj } from './useSearch';
import { PageRowCountChangeEvent } from 'aui-grid';
import { ForwardedRef, MutableRefObject, useEffect, useState } from 'react';
import { Pager, PagingListDTO } from 'interface/util';
import { AUIGRID_ID_PRE } from 'modules/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { numberThousandComma } from 'common/util/counting';
import { clearTab, setMain, setSearchObj, setTotal } from 'redux/services/tabSlice';
import { setLoading } from 'redux/services/menuSlice';
import { getTimeStamp } from 'common/util/dateParsingFn';

interface PagingConnectorProps {
  mode?: string;
  count?: number;
}

interface IuseSearchGridPaging<T> {
  tabId: string;
  initialSearchObj?: T;
  gridRef: ForwardedRef<IPagingGrid>;
  fetchTotal?: (searchObj: T) => Promise<any>;
  fetchPaging?: (searchObj: T) => Promise<PagingListDTO<any>>;
  validationForFetchList?: (searchObj: T) => boolean;
}

const useSearchGridPagingNew = <T extends IdefaultSearchObj>(props: IuseSearchGridPaging<T>) => {
  const { tabId, initialSearchObj, gridRef, fetchTotal, fetchPaging, validationForFetchList = () => true } = props;
  const searchObj = useSelector((state: RootState) => state.tab)?.searchObj?.[tabId];
  const dispatch = useDispatch();

  const [gridId, setGridId] = useState<string>();
  const [pagingConnector, setPagingConnector] = useState<PagingConnectorProps | null>();
  const [pagination, setPagination] = useState<Pager>();

  const useNewDesignFlag = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  useEffect(() => {
    if (typeof gridRef !== 'function' && gridRef?.current) {
      if (tabId && initialSearchObj) {
        dispatch(setSearchObj({ tabId: tabId, searchObj: initialSearchObj }));
      }
      bindCustomPageBtns();
      bindPagingGridFunction(gridRef);
    }
    return () => {
      //
      if (useNewDesignFlag !== 1) {
        //NOTE: redesign에서는 MasterOptionWrapper 컴포넌트에서 screenUrl이 변경될 때 초기화 처리를 하고있기 때문에 예외 처리
        dispatch(clearTab());
      } else {
        //NOTE: redesign에서는 컴포넌트 unmount 시점에 totalCount와 timeStamp를 각각 0, null 로 초기화하여 탭 진입 시 fetch가 발생하지 않도록 처리
        const localSearchObj = JSON.parse(localStorage.getItem('searchObj'))?.[tabId];
        dispatch(
          setSearchObj({
            tabId: tabId,
            searchObj: {
              ...localSearchObj,
              totalCount: 0,
              timeStamp: null,
            },
          }),
        );
      }
    };
  }, []);

  const fetchInitAPI = async (searchObj) => {
    dispatch(setLoading('GET'));
    if (validationForFetchList(searchObj) && searchObj?.pageNo && searchObj?.pageSize) {
      const [total, main] = await Promise.all([fetchTotal && !searchObj?.totalCount && fetchTotal(searchObj), fetchPaging(searchObj)]);
      if (total) {
        if (typeof gridRef !== 'function') gridRef?.current?.setFooter(total);
        if (tabId) dispatch(setTotal({ tabId: tabId, total: total }));
      }
      if (main) fetchMain(main);
    }
    dispatch(setLoading(null));
  };

  useEffect(() => {
    if (searchObj?.totalCount === null) {
      fetchInitAPI(searchObj);
    }
  }, [searchObj?.totalCount, searchObj?.timeStamp]);

  useEffect(() => {
    if (searchObj?.totalCount) {
      //NOTE: 신규 디자인에서 탭 전환 시 gridRef?.current?.clearGridData() 를 수행하고 있으므로 pageNo 변경 시 그리드 row가 0보다 클 경우에만 fetch 수행하도록 업데이트
      const currentGridRowCount = typeof gridRef !== 'function' ? gridRef?.current?.getRowCount() : undefined;
      currentGridRowCount > 0 && fetchInitAPI(searchObj);
    }
  }, [searchObj?.pageNo]);

  // 정확히 말하면 InitFetchTrigger
  const initFetch = (searchObj) => {
    if (searchObj?.pageSize && searchObj?.pageNo) {
      const timeStamp = getTimeStamp();
      dispatch(
        setSearchObj({
          tabId: tabId,
          searchObj: {
            ...searchObj,
            pageNo: 1,
            totalCount: null,
            timeStamp,
          },
        }),
      );
    }
  };

  const fetchMain = (main: PagingListDTO<any>) => {
    if (typeof gridRef !== 'function') {
      gridRef?.current?.closeFilterLayer();
      gridRef?.current?.clearFilterAll();
      gridRef?.current?.setGridData(main?.list);
    }
    if (tabId) dispatch(setMain({ tabId: tabId, main: main }));
    if (main?.pagination) {
      setPagination(main?.pagination);
      labeling(`${searchObj?.pageNo} / ${main?.pagination?.totalPage}`, main?.pagination?.totalCount);
      dispatch(
        setSearchObj({
          tabId: tabId,
          searchObj: {
            ...searchObj,
            totalCount: main?.pagination?.totalCount,
          },
        }),
      );
    }
  };

  const bindPagingGridFunction = (gridRef: ForwardedRef<IPagingGrid>) => {
    bindPageRowChange(gridRef);
  };

  const bindCustomPageBtns = () => {
    if (!gridId) {
      const gridId = `#${AUIGRID_ID_PRE}${(gridRef as MutableRefObject<IPagingGrid>)?.current?.props?.name}`;
      setGridId(gridId);

      const existPrev = document.querySelector(`${gridId} .aui-grid-paging-prev`);
      const existNext = document.querySelector(`${gridId} .aui-grid-paging-next`);
      existPrev?.addEventListener('click', prevBtnClick);
      existNext?.addEventListener('click', nextBtnClick);
      const pagingText = document.querySelector(`${gridId} .aui-grid-paging-panel`);
      const pagingLabel = document.createElement('span');
      const totalLabel = document.createElement('span');

      if (typeof gridRef !== 'function') {
        if (gridRef?.current?.props?.gridProps?.showPageRowSelect) {
          pagingLabel.className = 'custom-paging-text';
          pagingText?.appendChild(pagingLabel);
          totalLabel.className = 'custom-total-text';
          pagingText?.appendChild(totalLabel);
        } else {
          pagingLabel.className = 'custom-paging-text disabled';
          pagingText?.appendChild(pagingLabel);
        }
      }
    }
  };

  useEffect(() => {
    if (pagingConnector?.count) {
      if (searchObj?.pageNo + pagingConnector?.count < 1) {
        alert('첫 페이지 입니다.');
      } else if (searchObj?.pageNo + pagingConnector?.count > pagination?.totalPage) {
        alert('마지막 페이지 입니다.');
      } else {
        const timeStamp = getTimeStamp();
        dispatch(
          setSearchObj({
            tabId: tabId,
            searchObj: {
              ...searchObj,
              pageNo: searchObj?.pageNo + pagingConnector?.count,
              timeStamp,
            },
          }),
        );

        setPagingConnector((prev) => {
          return {
            ...prev,
            mode: null,
            count: null,
          };
        });
      }
    }
  }, [pagingConnector?.count]);

  const labeling = (str: string, totalPage: number) => {
    const pagingLabel = document.querySelector(`${gridId} .custom-paging-text`);
    const totalLabel = document.querySelector(`${gridId} .custom-total-text`);
    if (pagingLabel) {
      pagingLabel.innerHTML = str;
    } else {
      // 그럴일 없겠지만..
      const pagingText = document.querySelector(`${gridId} .aui-grid-paging-panel`);
      const pagingLabel = document.createElement('span');
      pagingLabel.className = 'custom-paging-text';
      pagingLabel.innerText = str;
      pagingText?.appendChild(pagingLabel);
    }
    if (totalLabel) {
      totalLabel.innerHTML = `총 ${totalPage ? numberThousandComma(totalPage) : '-'}건`;
    }
  };

  const prevBtnClick = () => {
    setPagingConnector((prev) => {
      return {
        ...prev,
        mode: 'pageNo',
        count: -1,
      };
    });
  };

  const nextBtnClick = () => {
    setPagingConnector((prev) => {
      return {
        ...prev,
        mode: 'pageNo',
        count: 1,
      };
    });
  };

  const bindPageRowChange = (gridRef: ForwardedRef<IPagingGrid>) => {
    (gridRef as MutableRefObject<IPagingGrid>)?.current?.bind('pageRowCountChange', function (e: PageRowCountChangeEvent) {
      let searchObj = null;
      if (typeof gridRef !== 'function') {
        const rows = gridRef?.current?.getGridData();
        searchObj = JSON.parse(rows?.[0]?.searchObj);
      }
      if (searchObj) {
        dispatch(
          setSearchObj({
            tabId: tabId,
            searchObj: {
              ...searchObj,
              pageNo: 1,
              pageSize: e.rowCount,
            },
          }),
        );
      }
    });

    (gridRef as MutableRefObject<IPagingGrid>)?.current?.bind('filtering', function (e: PageRowCountChangeEvent) {
      if (typeof gridRef !== 'function') {
        gridRef.current.setAllCheckedRows(false);
      }
    });
  };

  const initSearchObj = (searchObj) => {
    dispatch(
      setSearchObj({
        tabId: tabId,
        searchObj: {
          ...searchObj,
        },
      }),
    );
  };

  const handleSearchObj = (id: string, value: any) => {
    dispatch(
      setSearchObj({
        tabId: tabId,
        searchObj: {
          ...searchObj,
          [id]: value,
        },
      }),
    );
  };

  return {
    initSearchObj,
    handleSearchObj,
    initFetch, // temp
  };
};

export default useSearchGridPagingNew;
