import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl } from 'common/util/httpclient';
import { USER_ACCESS, USER_GROUP } from 'envVar';
import { PagingListDTO } from 'interface/util';
import { GroupDTO, GroupDetailDTO, GroupSearchDTO, ResponseDTO } from 'interface/user';

class ManagerService {
  // 조직
  public async getGroupPaging(searchDTO: GroupSearchDTO): Promise<PagingListDTO<GroupDTO>> {
    let url = USER_GROUP('paging') + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getGroupDetail(groupSeq: number = null): Promise<GroupDetailDTO> {
    let url = groupSeq ? USER_GROUP('detail') + `?groupSeq=${groupSeq}` : USER_GROUP('detail');
    const { data } = await httpClient.get(url);
    return data;
  }
  public async postGroupAction(action: string, dataDTO: any): Promise<ResponseDTO> {
    let url = USER_GROUP(action);
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
  public async saveGroupDetail(dataDTO: GroupDTO): Promise<ResponseDTO> {
    let url = USER_GROUP('save');
    const { data } = await httpClient.post(url, dataDTO);
    return data;
  }

  public async getAccessPaging(category: string, searchDTO: any): Promise<PagingListDTO<any>> {
    let url = USER_ACCESS(category, 'paging') + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getAccessDetail(category: string, groupSeq: number): Promise<any> {
    let url = USER_ACCESS(category, 'detail') + `?groupSeq=${groupSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getAccessAction(category: string, action: string, dataDTO: any): Promise<ResponseDTO> {
    let url = USER_ACCESS(category, action);
    const { data } = await httpClient.post(url, dataDTO);
    return data;
  }
}

export const managerService = new ManagerService();
