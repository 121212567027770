import { COLORS, VALUES } from 'common/constants/appearance';
import styled from 'styled-components';

export type ListGroupType = {
  $padding?: number;
  $width?: number;
  $gap?: number;
  className?: string;
};

export default function LDSListGroup({ children, ...props }: ListGroupType & { children?: React.ReactNode }) {
  return <LDSListGroupStyle {...props}>{children}</LDSListGroupStyle>;
}
export const LDSListGroupStyle = styled.ul<ListGroupType>`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.$gap ? props.$gap : 0)}px;
  padding: ${(props) => (props.$padding ? props.$padding : 0)}px;
  width: ${(props) => (props.$width ? `${props.$width}px` : '100%')};
  background-color: inherit;
`;

export const LDSEmptyListGroup = styled.div`
  padding: 10px; 6px;
  border-radius: ${VALUES.RADIUS}px;
  background-color: ${COLORS.GRAY_02};
  color: ${COLORS.GRAY_05};
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
  cursor: default;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
