import { ReactNode, useMemo, useRef, useState } from 'react';

// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';

// utils
import useSearchGridPaging from 'hooks/grid/useGridPaging';
// service
import { serviceStore } from 'services/services';
import { PurchaseOrderInspectDetailExcelDTO, PurchaseOrderInspectDTO, PurchaseOrderInspectSearchDTO } from '../../_interface/warehouse';

// component
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import { PagingListDTO } from 'interface/util';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import { ISerialObj, SerialsPopup } from './component/serialPopup';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const bottomGridRef = useRef<IPagingGrid>();

  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const fetchPaging = async (searchObj: PurchaseOrderInspectSearchDTO) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore.warehouseAction(`purchase/inspect/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<PurchaseOrderInspectDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    downloadExcel(id);
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const gridButtonhandler = (e) => {
      const id = e.target.id;
      if (id === 'excel') {
        bottomGridRef?.current?.exportAsXlsx({ fileName: `발주검수상세` });
      }
    };
    const data = (await serviceStore.warehouseAction(`purchase/inspect/detail?purchaseInspectSeq=${e.item?.purchaseInspectSeq}`, 'GET', null))?.data;
    console.log(data);
    const rows = labellingKr({ list: data })?.list;

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '발주검수 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          buttons: [
            <div key={`excel`} className={`green`} id={`excel`} onClick={gridButtonhandler}>
              엑셀다운
            </div>,
          ],
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
      ],
    });

    dispatch(setLoading(null));
  };

  const labellingKr = (data: PagingListDTO<PurchaseOrderInspectDTO & PurchaseOrderInspectDetailExcelDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        //

        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],
        partnerSeqsKr: row.partnerSeqs?.map((ele) => MASTER_OBJ?.SELLER_WHOLE?.[ele]).join(','),

        supplierPartnerSeqsKr: row.supplierPartnerSeqs?.map((ele) => MASTER_OBJ?.SELLER_WHOLE?.[ele]).join(','),
        supplierPartnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.supplierPartnerSeq],
        //
        statusKr: MASTER_OBJ?.PURCHASE_INSPECT_STATUS?.[row?.status],
        resultKr: MASTER_OBJ?.INSPECT_FLAG?.[row?.result + ''],
        causeKr: MASTER_OBJ?.INSPECT_CAUSE?.[row?.cause],
        reasonKr: MASTER_OBJ?.INSPECT_REASON?.[row?.reason],
        processKr: MASTER_OBJ?.INSPECT_PROCESS?.[row?.process],
        serialNumbersKr: row.serialNumbers?.join(', '),
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const downloadExcel = async (id) => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction(`purchase/inspect/${id?.includes('MAIN') ? '' : 'detail/'}excel`, 'GET', searchObj, null))?.data as PurchaseOrderInspectDTO[],
      },
      true,
    );

    excelGridRef.current.setGridData(list);
    excelGridRef?.current?.changeColumnLayout(id?.includes('MAIN') ? columns?.filter((_, idx) => idx > 0) : detailExcelColumns);
    excelGridRef.current.exportAsXlsx({ fileName: `발주검수${id?.includes('MAIN') ? '(메인)' : '(상세)'}` });
    dispatch(setLoading(null));
  };

  const [serialsPopupObj, setSerialsPopupObj] = useState<ISerialObj>();
  const popupSerials = (event: IGrid.ButtonClickEvent) => {
    setSerialsPopupObj({
      visible: true,
      quantityKey: 'confirmDefectedQuantity',
      serialKey: 'serialNumbers',
      item: event.item,
      event: JSON.stringify(event),
      searchObj: JSON.parse(event.item.searchObj),
      fetchFn: (e) => fetchDetail(e),
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetail,
      },
    },
    {
      headerText: '발주번호',
      dataField: 'purchaseSeq',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqsKr',
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqsKr',
    },
    {
      headerText: '입고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '검수상태',
      dataField: 'statusKr',
    },
    {
      headerText: '검수지시수량',
      dataField: 'quantity',
    },
    {
      headerText: '검수완료수량',
      children: [
        {
          headerText: '총 검수수량',
          dataField: 'confirmQuantity',
        },
        {
          headerText: '양품수량',
          dataField: 'confirmAvailableQuantity',
        },
        {
          headerText: '불용수량',
          dataField: 'confirmDefectedQuantity',
        },
      ],
    },
    {
      headerText: '검수완료일시',
      dataField: 'confirmDatetime',
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '발주번호',
      dataField: 'purchaseSeq',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '로케이션',
          dataField: 'locationCode',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
      ],
    },
    {
      headerText: '검수지시수량',
      dataField: 'quantity',
    },
    {
      headerText: '검수완료수량',
      children: [
        {
          headerText: '양품',
          dataField: 'confirmAvailableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'confirmDefectedQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: popupSerials,
            disabledFunction(rowIndex, columnIndex, value, item, dataField) {
              if (value > 0) return false;
              return true;
            },
          },
        },
      ],
    },
    {
      headerText: '판정결과',
      dataField: 'resultKr',
    },
    {
      headerText: '귀책사유',
      dataField: 'causeKr',
    },
    {
      headerText: '불용사유',
      dataField: 'reasonKr',
    },
    {
      headerText: '조치사항',
      dataField: 'processKr',
    },
    {
      headerText: '검수완료일시',
      dataField: 'confirmDatetime',
    },
    {
      headerText: '검수완료자',
      dataField: 'confirmId',
    },
  ];

  const detailExcelColumns: IGrid.Column[] = [
    {
      headerText: '발주번호',
      dataField: 'purchaseSeq',
    },
    {
      headerText: '입고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '검수상태',
      dataField: 'statusKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '검수지시수량',
      dataField: 'quantity',
    },
    {
      headerText: '검수완료수량',
      children: [
        {
          headerText: '총 검수수량',
          dataField: 'confirmQuantity',
        },
        {
          headerText: '양품수량',
          dataField: 'confirmAvailableQuantity',
        },
        {
          headerText: '불용수량',
          dataField: 'confirmDefectedQuantity',
        },
      ],
    },
    {
      headerText: '시리얼번호리스트',
      dataField: 'serialNumbersKr',
    },
    {
      headerText: '판정결과',
      dataField: 'resultKr',
    },
    {
      headerText: '귀책사유',
      dataField: 'causeKr',
    },
    {
      headerText: '불용사유',
      dataField: 'reasonKr',
    },
    {
      headerText: '조치사항',
      dataField: 'processKr',
    },
    {
      headerText: '검수완료일시',
      dataField: 'confirmDatetime',
    },
    {
      headerText: '검수완료자',
      dataField: 'confirmId',
    },
  ];

  return (
    <div className="page-content">
      {serialsPopupObj?.visible && <SerialsPopup serialsPopupObj={serialsPopupObj} setSerialsPopupObj={setSerialsPopupObj} />}
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            fixedColumnCount: 1,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
