import { useEffect, useState } from 'react';
import { Col, Row, Input, Form } from 'reactstrap';
import Grid from 'components/grid/auiGrid';

import Select from 'react-select';
import Dropdown from 'react-dropdown';

import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import CommonModalNew from 'components/modal/commonModalNew';
import { useSelector } from 'react-redux';

const SELECT_VALUE_KEY = 'MySelectValue';

const Presenter = (props) => {
  const { modalVisible, ipgoColumns, chulgoColumns, gisaChulgoColumns, ipchulFooterLayout, gisaFooterLayout, ipgoGridId, chulgoGridId, gisaChulgoGridId, rows, rowsIpgo, rowsChulgo, rowsGisaChulgo } =
    props;

  return (
    <>
      <div className="page-content">
        {modalVisible && <CreateModal title="작업 지시 생성" {...props} />}
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <>
          <div className="presenterButton">
            <Buttons {...props} />
          </div>
          <div className="presenterGridBox">
            <GridBox {...props} />
          </div>
        </>
        {ipgoColumns && (
          <>
            <div className="presenterTitle">
              <DetailTitle title="입고상세" />
            </div>
            <div className="presenterButton">
              <DetailButtons {...props} gridId={ipgoGridId} />
            </div>
            <div className="presenterGridBox">
              <GridBoxDetail {...props} gridId={ipgoGridId} footerLayout={ipchulFooterLayout} columns2={ipgoColumns} rows2={rowsIpgo} />
            </div>
          </>
        )}
        {chulgoColumns && (
          <>
            <div className="presenterTitle">
              <DetailTitle title="출고상세" />
            </div>
            <div className="presenterButton">
              <DetailButtons {...props} gridId={chulgoGridId} />
            </div>
            <div className="presenterGridBox">
              <GridBoxDetail {...props} gridId={chulgoGridId} columns2={chulgoColumns} footerLayout={ipchulFooterLayout} rows2={rowsChulgo} />
            </div>
          </>
        )}
        {gisaChulgoColumns && (
          <>
            <div className="presenterTitle">
              <DetailTitle title="기사출고" />
            </div>
            <div className="presenterButton">
              <DetailButtons {...props} gridId={gisaChulgoGridId} />
            </div>
            <div className="presenterGridBox">
              <GridBoxDetail {...props} gridId={gisaChulgoGridId} columns2={gisaChulgoColumns} footerLayout={gisaFooterLayout} rows2={rowsGisaChulgo} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Presenter;

const CreateModal = (props) => {
  const {
    modalVisible,
    setModalVisible,
    ipgoModalColumns,
    ipgoModalColumns2,
    chulgoModalColumns,
    gisaModalColumns,

    rowsOnModal,
    modalType,

    exportExcelThisGrid,
    setExportExcelThisGrid,
  } = props;

  const [gridId, setGridId] = useState();

  useEffect(() => {
    if (rowsOnModal) {
      setGridId(Math.random() + 'linehaulDetailModal');
    }
  }, [rowsOnModal]);
  const titleByMode = {
    PURCHASE: '입고 정보 상세',
    LINEHAULIN: '입고 정보 상세',
    LINEHAULOUT: '출고 정보 상세',
    DRIVERLOAN: '출고리스트 상세',
  };
  return (
    <CommonModalNew
      key={gridId}
      title={titleByMode[modalType]}
      style={{ width: '1500px' }}
      visible={modalVisible}
      setVisible={setModalVisible}
      children={
        <>
          <Row style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <button onClick={() => setExportExcelThisGrid({ type: 'xlsx', id: `#${gridId}` })} className="btn-excel btn">
              엑셀다운
            </button>
          </Row>
          <Grid
            id={gridId}
            settingOptions={{
              showPageRowSelect: false,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              editable: false,
              autoGridHeight: false,
              showFooter: false,
            }}
            rows={rowsOnModal}
            columns={modalType === 'PURCHASE' ? ipgoModalColumns : modalType === 'LINEHAULIN' ? ipgoModalColumns2 : modalType === 'LINEHAULOUT' ? chulgoModalColumns : gisaModalColumns}
            exportExcelThisGrid={exportExcelThisGrid}
            setExportExcelThisGrid={setExportExcelThisGrid}
          />
        </>
      }
    />
  );
};

const Buttons = (props) => {
  const { setlistCount, listCount, gridId, setExportExcelThisGrid } = props;

  const options = [
    { value: '10', label: '10개씩' },
    { value: '50', label: '50개씩' },
    { value: '100', label: '100개씩' },
    { value: '500', label: '500개씩' },
  ];

  function handlechange(e) {
    localStorage.setItem(SELECT_VALUE_KEY, JSON.stringify(e.value));
    setlistCount(e.value);
    // window.location.reload();
  }

  useEffect(() => {
    const lastSelected = JSON.parse(localStorage.getItem(SELECT_VALUE_KEY) ?? '[]');
    setlistCount(lastSelected.length === 0 ? '50' : lastSelected);
  }, []);

  return (
    <Row>
      <Col style={{ textAlign: 'left' }}>
        <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
          <div style={{ width: '8rem' }}>
            <Dropdown options={options} value={listCount} className="myClassName" onChange={(e) => handlechange(e)} />
          </div>
        </div>
      </Col>
      <Col />
      <Col />

      <Col style={{ textAlign: 'end', paddingRight: -5 }}>
        <button onClick={() => setExportExcelThisGrid({ type: 'xlsx', id: `#${gridId}` })} className="btn-excel btn">
          엑셀다운
        </button>
      </Col>
    </Row>
  );
};

const DetailButtons = (props) => {
  const { gridId, setExportExcelThisGrid } = props;

  return (
    <Row className="mt-4">
      <Col style={{ textAlign: 'left' }}></Col>
      <Col />
      <Col />

      <Col style={{ textAlign: 'end', paddingRight: -5 }}>
        <button onClick={() => setExportExcelThisGrid({ type: 'xlsx', id: `#${gridId}` })} className="btn-excel btn">
          엑셀다운
        </button>
      </Col>
    </Row>
  );
};
const Search = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { searchObj, setSearchObj, setSearchCenter, searchCenter, searchDate, setSearchDate, fetchList } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchList();
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <label className="col-form-label">날짜*</label>
            <div>
              <FlatpickrD
                value={searchDate || ''}
                onChange={(date) => {
                  setSearchDate(returnDateyyyymmdd(date));
                }}
                options={{
                  mode: 'single',
                }}
              />
            </div>
          </Col>
          <Col>
            <label className="col-form-label">창고*</label>
            <SelectD
              options={masterOptions?.CENTER_AUTH}
              value={searchCenter || ''}
              onChange={(option) => {
                setSearchCenter(option);
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">제품그룹</label>
            <SelectD
              options={masterOptions?.MODEL_GROUP}
              value={searchObj?.modelGroup || ''}
              onChange={(option) => {
                const copied = JSON.parse(JSON.stringify(searchObj));
                copied.modelGroup = option;
                setSearchObj(copied);
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">재퓸/자재</label>
            <SelectD
              options={masterOptions?.MODEL_TYPE}
              value={searchObj?.modelType || ''}
              onChange={(option) => {
                const copied = JSON.parse(JSON.stringify(searchObj));
                copied.modelType = option;
                setSearchObj(copied);
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">판매사</label>
            <SelectD
              options={masterOptions?.SELLER_SELLER_AUTH}
              value={searchObj?.seller || ''}
              onChange={(option) => {
                const copied = JSON.parse(JSON.stringify(searchObj));
                copied.seller = option;
                setSearchObj(copied);
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">제품명</label>
            <InputD
              value={searchObj?.modelName || ''}
              onChange={(e) => {
                const copied = JSON.parse(JSON.stringify(searchObj));
                copied.modelName = e.target.value;
                setSearchObj(copied);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">바코드</label>
            <InputD
              value={searchObj?.barcode || ''}
              onChange={(e) => {
                const copied = JSON.parse(JSON.stringify(searchObj));
                copied.barcode = e.target.value;
                setSearchObj(copied);
              }}
            />
          </Col>
          <Col>
            <button className="btn btn-search" type="submit">
              조회
            </button>
          </Col>
          <Col />
          <Col />
          <Col />
          <Col />
        </Row>
      </Form>
    </>
  );
};

const GridBox = (props) => {
  const { rows = [], gridId, columns = [], listCount, exportExcelThisGrid, setExportExcelThisGrid, onCellClick, footerLayout } = props;

  return (
    <>
      <div style={{ width: '100%' }}>
        {gridId && (
          <Grid
            id={gridId}
            settingOptions={{
              showPageRowSelect: false,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              editable: false,
            }}
            onCellClick={onCellClick}
            rows={rows}
            columns={columns}
            listCount={listCount}
            footerLayout={footerLayout}
            exportExcelThisGrid={exportExcelThisGrid}
            setExportExcelThisGrid={setExportExcelThisGrid}
          />
        )}
      </div>
    </>
  );
};

const DetailTitle = (props) => {
  const { title } = props;
  return (
    <Row>
      <div className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between" style={{ position: 'absolute', top: -5 }}>
          <h4 className="page-title mb-0 font-size-18">{title}</h4>
        </div>
      </div>
    </Row>
  );
};

const GridBoxDetail = (props) => {
  const { rows2 = [], gridId, columns2 = [], exportExcelThisGrid, setExportExcelThisGrid, onCellClick, footerLayout } = props;

  return (
    <>
      <div style={{ width: '100%' }}>
        {gridId && (
          <Grid
            id={gridId}
            settingOptions={{
              showPageRowSelect: false,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              editable: false,
              autoGridHeight: true,
            }}
            onCellClick={onCellClick}
            footerLayout={footerLayout}
            rows={rows2}
            columns={columns2}
            exportExcelThisGrid={exportExcelThisGrid}
            setExportExcelThisGrid={setExportExcelThisGrid}
          />
        )}
      </div>
    </>
  );
};
