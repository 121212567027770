import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { CustomerOrderMoveSearchDTOSecond } from '../../../_interface/order';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = (props: ISearchBoxProps<CustomerOrderMoveSearchDTOSecond>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE: null,
    SEARCH_ORDER: null,
    SEARCH_INVOICE: null,

    SEARCH_MODEL: null,
  });

  useEffect(() => {
    if (selectTypeObj?.SEARCH_DATE) setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  useEffect(() => {
    if (selectTypeObj?.SEARCH_MODEL) setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  const handleFixedFlag = (e) => {
    const id = e.target.id;
    if (id) {
      setSearchObj((prev) => {
        return {
          ...prev,
          linehaulFlag: id === 'whole' ? null : id === 'true' ? 'true' : 'false',
        };
      });
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">간선진행여부</label>
          <div className="radio-select-area">
            <span id="whole" className={!searchObj?.linehaulFlag ? 'active' : ''} onClick={handleFixedFlag}>
              전체
            </span>
            <span id="true" className={searchObj?.linehaulFlag === 'true' ? 'active' : ''} onClick={handleFixedFlag}>
              진행
            </span>
            <span id="false" className={searchObj?.linehaulFlag === 'false' ? 'active' : ''} onClick={handleFixedFlag}>
              미진행
            </span>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">주문번호</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_ORDER
                    ? {
                        value: selectTypeObj?.SEARCH_ORDER,
                        label: MASTER_OBJ?.SEARCH_ORDER[selectTypeObj?.SEARCH_ORDER],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_ORDER}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_ORDER: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_ORDER}
                value={searchObj?.[selectTypeObj?.SEARCH_ORDER] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_ORDER]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">송장번호</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_INVOICE
                    ? {
                        value: selectTypeObj?.SEARCH_INVOICE,
                        label: MASTER_OBJ?.SEARCH_INVOICE[selectTypeObj?.SEARCH_INVOICE],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_INVOICE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_INVOICE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_INVOICE}
                value={searchObj?.[selectTypeObj?.SEARCH_INVOICE] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_INVOICE]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.SELLER_AUTH}
            value={searchObj?.partnerSeqList?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.SELLER_AUTH?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeqList: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col md={2}>
          <label className="col-form-label">판매채널</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.SALES_CHANNEL}
            value={searchObj?.salesChannelSeqList?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.SALES_CHANNEL?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                salesChannelSeqList: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">구분</label>
          <SelectD
            options={MASTER_OPS?.MOVE_CLASS}
            value={
              searchObj?.moveClass
                ? {
                    value: searchObj?.moveClass,
                    label: MASTER_OBJ?.MOVE_CLASS?.[searchObj?.moveClass],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                moveClass: (option as OptionItem).value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">송장유형</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.FEE_TYPE}
            value={searchObj?.feeTypes?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.FEE_TYPE?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                feeTypes: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">송장상태</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.INVOICE_STATUS}
            value={searchObj?.invoiceStatuses?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.INVOICE_STATUS?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                invoiceStatuses: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">출발창고</label>
          <SelectD
            options={MASTER_OPS?.CENTER_AUTH}
            value={
              searchObj?.originalCenterCode
                ? {
                    value: searchObj?.originalCenterCode,
                    label: MASTER_OBJ?.CENTER_WHOLE?.[searchObj?.originalCenterCode],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                originalCenterCode: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">도착창고</label>
          <SelectD
            options={MASTER_OPS?.CENTER_DIFF}
            value={
              searchObj?.destinationCenterCode
                ? {
                    value: searchObj?.destinationCenterCode,
                    label: MASTER_OBJ?.CENTER_DIFF?.[searchObj?.destinationCenterCode],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                destinationCenterCode: (option as OptionItem).value,
              });
            }}
          />
        </Col>

        <Col>
          <label className="col-form-label">기간검색</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_DATE
                    ? {
                        value: selectTypeObj?.SEARCH_DATE,
                        label: MASTER_OBJ?.SEARCH_DATE[selectTypeObj?.SEARCH_DATE],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_DATE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_DATE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <FlatpickrD
                disabled={!selectTypeObj?.SEARCH_DATE}
                value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                    [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                  });
                }}
                onClose={(option) => {
                  if (option?.length === 1)
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                      [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                    });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col md={2}>
          <label className="col-form-label">고객명(수령인)</label>
          <InputD
            value={searchObj?.receiverName || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                receiverName: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <label className="col-form-label">주소</label>
          <InputD
            value={searchObj?.receiverAddr || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                receiverAddr: e.target.value,
              });
            }}
          />
        </Col>
        <Col md={2}>
          <label className="col-form-label">연락처</label>
          <InputD
            value={searchObj?.receiverPhone || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                receiverPhone: e.target.value,
              });
            }}
          />
        </Col>
        <Col md={2} />
        <Col md={2} />
        <Col style={{ textAlign: 'right' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
