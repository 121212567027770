import { COLORS } from 'common/constants/appearance';
import styled from 'styled-components';

type HeadingPropType = {
  $align?: string;
  $color?: string;
};

type LabelPropType = {
  $weight?: 'BOLD' | 'SEMIBOLD' | 'MEDIUM' | 'REGULAR';
  $color?: string;
};

type FormLabelPropType = {
  $isRequired?: boolean;
  $size?: number;
  $color?: string;
};

// 3XL
export const LDSTagline = styled.h2<HeadingPropType>`
  margin: 0;
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
  text-align: ${(props) => props.$align};
  color: ${(props) => props.$color || null};
`;

// 2XL
export const LDSHeading = styled.h3<HeadingPropType>`
  margin: 0;
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  text-align: ${(props) => props.$align};
  color: ${(props) => props.$color || null};
`;

// XL
export const LDSTitle = styled.h4<HeadingPropType>`
  margin: 0;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  text-align: ${(props) => props.$align};
  color: ${(props) => props.$color || null};
`;

// LG
export const LDSSubTitle = styled.h5<HeadingPropType>`
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  text-align: ${(props) => props.$align};
  color: ${(props) => props.$color || null};
`;

// MD
export const LDSLabelMD = styled.label<LabelPropType>`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: ${(props) => (props.$weight === 'BOLD' ? 700 : props.$weight === 'SEMIBOLD' ? 600 : props.$weight === 'MEDIUM' ? 500 : 400)};
  color: ${(props) => props.$color || null};
`;

// SM
export const LDSLabelSM = styled.label<LabelPropType>`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: ${(props) => (props.$weight === 'BOLD' ? 700 : props.$weight === 'SEMIBOLD' ? 600 : props.$weight === 'MEDIUM' ? 500 : 400)};
  color: ${(props) => props.$color || null};
`;

// XS
export const LDSLabelXS = styled.label<LabelPropType>`
  margin: 0;
  font-size: 11px;
  line-height: 14px;
  font-weight: ${(props) => (props.$weight === 'BOLD' ? 700 : props.$weight === 'SEMIBOLD' ? 600 : props.$weight === 'MEDIUM' ? 500 : 400)};
`;

// XXS
export const LDSLabelXXS = styled.label<LabelPropType>`
  margin: 0;
  font-size: 10px;
  line-height: 14px;
  font-weight: ${(props) => (props.$weight === 'BOLD' ? 700 : props.$weight === 'SEMIBOLD' ? 600 : props.$weight === 'MEDIUM' ? 500 : 400)};
  color: ${(props) => props.$color || null};
`;

// 3XS
export const LDSLabel3XS = styled.label<LabelPropType>`
  margin: 0;
  font-size: 9px;
  line-height: 14px;
  font-weight: ${(props) => (props.$weight === 'BOLD' ? 700 : props.$weight === 'SEMIBOLD' ? 600 : props.$weight === 'MEDIUM' ? 500 : 400)};
  color: ${(props) => props.$color || null};
`;

// Error Message Style
export const LDSErrorMessage = styled(LDSLabelXS)`
  color: ${COLORS.ALERT};
  margin-top: 4px;
  padding-left: 2px;
`;

// FieldItem Label Style
export const LDSFormLabel = styled(LDSLabelSM)<FormLabelPropType>`
  color: ${COLORS.GRAY_08};
  font-weight: 600;

  &:after {
    content: '*';
    display: ${(props) => (props.$isRequired ? 'inline-block' : 'none')};
    color: ${COLORS.ALERT};

    margin-left: 3px;
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
`;

// TODO: 아래 컴포넌트 정리 필요
export const LDSDescription = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.GRAY_05};

  & strong {
    color: ${COLORS.GRAY_07};
  }
`;
