import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import useGridButton from 'hooks/grid/useGridButton';
import { ZipcodeGroupSearchDTO, ZipcodeGroupListDTO } from 'pages/ETC/_interface/install';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { serviceStore } from 'services/services';
import { SearchBox } from './component/searchBox';
import { DetailPopup, IDetailObj } from '../../driverListTab/driverList/component/detailPopup';
import { DriverManageModal, IDriverManageObj } from './component/driverMangeModal';
import SectorManageModal, { ISectorManageObj } from './component/sectorManageModal';

export type IZipcodeGroupListDTOExtends = ZipcodeGroupListDTO & { isFirst: boolean; seedForMergeRef: string };

const Index = ({ tabId }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const [driverDetailObj, setDriverDetailObj] = useState<IDetailObj>(); //driverDetail
  const [sectorManageObj, setSectorManageObj] = useState<ISectorManageObj>();
  const [driverManageObj, setDriverManageObj] = useState<IDriverManageObj>();

  const onCellClick = (e: IGrid.ButtonClickEvent) => {
    if (e.dataField === 'driverTeamsEdit') {
      setDriverManageObj({
        mode: 'edit',
        visible: true,
        data: e.item,
        searchObj: JSON.parse(e?.item?.searchObj),
        fetchFn: (searchObj) => wrappedFetchList(searchObj),
      });
    } else if (e.dataField === 'driverTeamsKr') {
      const firstUserId = e.item?.drivers?.[0]?.userId;
      if (firstUserId) {
        setDriverDetailObj({
          visible: true,
          item: { userId: firstUserId },
        });
      }
    } else if (e.dataField === 'zipcodeGroupInfo') {
      setSectorManageObj({
        visible: true,
        mode: 'set-zipInfo',
        item: e.item,
        searchObj: JSON.parse(e?.item?.searchObj),
        fetchFn: (searchObj) => wrappedFetchList(searchObj),
      });
    }
  };

  const onCheckboxClick = (e: IGrid.RowCheckClickEvent) => {
    if (e.item.zipcodeSectorSeq) {
      if (e.checked) gridRef?.current?.addCheckedRowsByValue('zipcodeSectorSeq', e.item.zipcodeSectorSeq);
      else gridRef?.current?.addUncheckedRowsByValue('zipcodeSectorSeq', e.item.zipcodeSectorSeq);
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '섹터넘버',
      dataField: 'zipcodeSectorSeq',
      width: 80,
      cellMerge: true,
      mergeRef: 'zipcodeSectorSeq',
      mergePolicy: 'restrict',
    },
    {
      headerText: '인프라그룹',
      dataField: 'infraSeqKr',
      width: 100,
    },
    {
      headerText: '권역명(시도)',
      dataField: 'zipcodeRangeName',
      width: 150,
    },
    {
      headerText: '그룹명(시군구)',
      dataField: 'zipcodeGroupName',
      width: 150,
    },
    {
      headerText: '그룹상세(행정동)',
      dataField: 'areaKr',
      width: '15%',
    },
    {
      headerText: '그룹별 기사',
      dataField: 'driverTeamsKr',
      width: 350,
      cellMerge: true,
      mergeRef: 'driverTeamsKr',
      mergePolicy: 'restrict',
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
      },
      headerTooltip: {
        show: true,
        tooltipHtml: `<div">
        클릭시 대표팀 주기사 정보
          </div>`,
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        // HTML 템플릿 작성
        if (item.drivers) {
          let driverTeamsKr = '';
          if (item?.drivers?.length > 0) {
            driverTeamsKr += '<div>';
            driverTeamsKr += `<span class="click-fake-item">${item?.drivers?.[0]?.driverName}</span> `;
            if (item?.drivers?.[0]?.assistantDriverName) driverTeamsKr += `<span class="click-fake-item">${item?.drivers?.[0]?.assistantDriverName}</span> `;
            else driverTeamsKr += `<span>없음</span> `;
            if (item?.drivers?.[0]?.internDriverName) driverTeamsKr += `<span class="click-fake-item">${item?.drivers?.[0]?.internDriverName}</span> `;
            else driverTeamsKr += `<span>없음</span> `;
            if (item?.drivers?.length > 1) driverTeamsKr += `팀 포함 ${item?.drivers?.length}팀`;
            driverTeamsKr += '</div>';
          }
          return driverTeamsKr;
        }
        return ''; // HTML 템플릿 반환..그대도 innerHTML 속성값으로 처리됨
      },
    },
    {
      headerText: '그룹별 <br />기사 수정',
      dataField: 'driverTeamsEdit',
      width: 50,
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '수정',
        onClick: onCellClick,
      },
    },
    {
      headerText: '배송요일',
      children: [
        {
          headerText: '월',
          dataField: 'mondayFlag',
          width: 30,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '화',
          dataField: 'tuesdayFlag',
          width: 30,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '수',
          dataField: 'wednesdayFlag',
          width: 30,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '목',
          dataField: 'thursdayFlag',
          width: 30,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '금',
          dataField: 'fridayFlag',
          width: 30,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '토',
          dataField: 'saturdayFlag',
          width: 30,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '일',
          dataField: 'sundayFlag',
          width: 30,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
      ],
    },
    {
      headerText: '권역별 <br />상위창고',
      dataField: 'parentCenterCodeKr',
      width: 150,
    },
    {
      headerText: '권역별 <br />하위창고',
      dataField: 'centerCodeKr',
      width: 150,
    },
    {
      headerText: '권역넘버',
      dataField: 'zipcodeRangeSeq',
      width: 100,
    },
    {
      headerText: '그룹넘버',
      dataField: 'zipcodeGroupSeq',
      width: 100,
    },
    {
      headerText: '권역정보',
      dataField: 'zipcodeGroupInfo',
      editable: true,
      headerTooltip: {
        show: true,
        tooltipHtml: `<div">
        수정 가능
          </div>`,
      },
    },
  ];

  const labellingKr = (data: PagingListDTO<IZipcodeGroupListDTOExtends>, isExcel = false) => {
    const list = data?.list?.map((row, idx) => {
      return {
        ...row, // isFirst 포함,

        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        parentCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.parentCenterCode],
        areaKr: row?.area?.join(','),
      };
    });

    return {
      ...data,
      list,
    };
  };

  const fetchPaging = async (searchObj: ZipcodeGroupSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.installAction(`zipcode/group/list/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<IZipcodeGroupListDTOExtends>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));

    const { list } = labellingKr(
      {
        list: (await serviceStore.installAction(`zipcode/group/list/excel`, 'GET', searchObj, null))?.data as IZipcodeGroupListDTOExtends[],
      },
      true,
    );
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '배송그룹설정' });
    dispatch(setLoading(null));
  };

  const mergeGroup = async (items) => {
    if (window.confirm(`병합하시겠습니까?`)) {
      const seqs = items?.map((ele) => ele.zipcodeGroupSeq);
      let url = 'zipcode/sector?';
      seqs?.length > 0 &&
        seqs.forEach((seq, index) => {
          index === 0 ? (url += `zipcodeGroupSeq=${seq}`) : (url += `&zipcodeGroupSeq=${seq}`);
        });
      const rs = await serviceStore.installAction(url, 'POST', null);
      if (rs?.data?.result) {
        alert('병합에 성공하였습니다.');
        wrappedFetchList(searchObj);
      }
    }
  };

  const divideSector = async (item) => {
    const url = `zipcode/sector?zipcodeSectorSeq=${item.zipcodeSectorSeq}`;
    const rs = await serviceStore.installAction(url, 'DELETE', null, null);
    if (rs.data.result) {
      alert('섹터 분할에 성공하였습니다.');
      wrappedFetchList(searchObj);
    }
  };

  const resetAllDriver = async (items) => {
    if (window.confirm('설치기사 일괄 삭제를 진행 하시겠습니까?')) {
      dispatch(setLoading('DELETE'));
      const rs = await serviceStore.installAction('zipcode/group/driver/clear', 'DELETE', null, {
        zipcodeGroupSeq: items?.map((ele) => ele.zipcodeGroupSeq),
      });
      if (rs.status === 200) {
        alert('해당 섹터의 기사가 일괄삭제 되었습니다.');
        wrappedFetchList(searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    console.log(id, items);
    if (items?.length > 0) {
      if (id === 'ZIPCODEGROUPSECTORMERGE') {
        mergeGroup(items);
      } else if (id === 'ZIPCODEGROUPDRIVERREMOVE') {
        resetAllDriver(items);
      } else if (id === 'ZIPCODEGROUPDRIVERADD') {
        const centerCodes = [...new Set(items?.map((ele) => ele.centerCode))];
        const filtered = centerCodes?.filter((ele) => ele);
        if (centerCodes?.length !== filtered?.length) {
          alert('하위창고가 존재하지 않는 건이 있습니다!(기사 추가 불가)');
        } else if (centerCodes?.length > 1) {
          alert('선택된 그룹들의 "하위창고"가 동일할 시에만 기사를 일괄 적용할 수 있습니다!');
        } else {
          setDriverManageObj({
            mode: 'addAll',
            visible: true,
            data: items,
            searchObj: JSON.parse(items[0].searchObj),
            fetchFn: (searchObj) => wrappedFetchList(searchObj),
          });
        }
      } else if (id === 'ZIPCODEGROUPCHANGEWAREHOUSE') {
        setSectorManageObj({
          visible: true,
          mode: 'change-zipcodeRangeCenterCode',
          items,
          searchObj: JSON.parse(items[0].searchObj),
          fetchFn: (searchObj) => wrappedFetchList(searchObj),
        });
      } else if (id === 'ZIPCODEGROUPCHANGEDELIVERYDAY') {
        setSectorManageObj({
          visible: true,
          mode: 'change-deliveryDate',
          items,
          searchObj: JSON.parse(items[0].searchObj),
          fetchFn: (searchObj) => wrappedFetchList(searchObj),
        });
      } else {
        const seqs = [...new Set(items?.map((ele) => ele.zipcodeSectorSeq)?.filter((ele) => ele))];
        console.log(seqs);
        if (seqs?.length > 0) {
          if (seqs?.length > 1) {
            alert('한 섹터씩 작업가능 합니다');
          } else {
            if (id === 'ZIPCODEGROUPSECTORDIVIDE') divideSector(items?.[0]);
            else if (id === 'ZIPCODEGROUPINPUTZIPCODE') {
              setSectorManageObj({
                visible: true,
                mode: 'set-zipInfo',
                item: items[0],
                searchObj,
                fetchFn: (searchObj) => wrappedFetchList(searchObj),
              });
            }
          }
        } else {
          alert('선택된 섹터 그룹이 없습니다');
        }
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    console.log(id);
    if (id === 'ZIPCODEGROUPEXCEL') {
      downloadExcel('main');
    } else {
      handleCheckItems(id);
    }
  };

  useEffect(() => {
    if (gridRef.current) {
      gridRef?.current?.bind('cellClick', onCellClick);
      gridRef?.current?.bind('rowCheckClick', onCheckboxClick);
    }
  }, []);

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {driverManageObj?.visible && <DriverManageModal driverManageObj={driverManageObj} setDriverManageObj={setDriverManageObj} />}
      {sectorManageObj?.visible && <SectorManageModal sectorManageObj={sectorManageObj} setSectorManageObj={setSectorManageObj} />}
      {driverDetailObj?.visible && <DetailPopup detailObj={driverDetailObj} setDetailObj={setDriverDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            selectionMode: 'multipleCells',
            showRowNumColumn: false,
            showStateColumn: false,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            enableCellMerge: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
