import { getToday } from 'common/util/dateParsingFn';
import { ParcelInvoiceTempDTO } from '../../../_interface/parcel';
import { ForwardedRef, useEffect, useRef, useState } from 'react';
import Barcode from 'react-barcode';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { RootState } from 'redux/store';
import styled from 'styled-components';

const PrintBarcode100123 = styled.div({
  position: 'relative',
  paddingRight: 0,
  paddingLeft: 0,
  width: '123mm',
  height: '100mm',
  maxWidth: '123mm',
  maxHeight: '100mm',
  display: 'flex',
  alignItems: 'flex-start',
});

interface IPrint {
  data: ParcelInvoiceTempDTO;
  scanInputRef: ForwardedRef<HTMLInputElement>;
}

export const Print = ({ data, scanInputRef }: IPrint) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  useEffect(() => {
    console.log(data);
    if (data) handlePrint();
  }, [data]);
  const today = getToday();
  const wrapperRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => wrapperRef.current,
    onAfterPrint: () => {
      if (typeof scanInputRef !== 'function') {
        scanInputRef.current.value = '';
        scanInputRef?.current?.focus();
      }
    },
  });

  const [mode, setMode] = useState<'P' | 'L'>('P');
  const changeMode = (e) => {
    localStorage.setItem('modeInLinehaul', e.target.id);
    setMode(e.target.id);
  };

  return (
    <div style={{ minWidth: 500, display: 'flex', flexDirection: 'column', marginLeft: 30 }}>
      {data && (
        <PrintBarcode100123 className="break-for-onePage-print" ref={wrapperRef} style={{ transform: mode !== 'P' ? 'rotate(-90deg)' : 'unset' }}>
          <table className="border-table noBorder">
            <tbody>
              <tr>
                <td colSpan={5}>
                  <span style={{ fontSize: 10, color: 'white' }}>운송장번호 : </span>
                  {data.parcelInvoiceNumber}
                </td>
                <td colSpan={2} className="ssm right" style={{ fontSize: 10 }}>
                  {today}
                </td>
                <td colSpan={5}></td>
              </tr>
              <tr>
                <td colSpan={12}>
                  <div style={{ display: 'flex', maxHeight: '15mm', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <div>{data?.groupBarcode && <Barcode width={1.7} value={data?.groupBarcode + '' || ''} margin={0} height={50} displayValue={false} />}</div>
                    <div style={{ fontSize: 36, fontWeight: 700 }}>
                      {data?.groupCode} {data?.groupStore}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td rowSpan={2} style={{ writingMode: 'vertical-lr', textAlign: 'center', fontSize: 10 }}></td>
                <td colSpan={11}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      {data?.receiverName} <span className="b">{data?.receiverPhone}</span>
                    </div>
                    <div style={{ paddingRight: '10mm' }}>
                      {data?.parcelInvoiceNumber && <Barcode width={1.3} value={data?.parcelInvoiceNumber + '' || ''} margin={0} height={20} displayValue={false} />}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={11} style={{ fontSize: 22, fontWeight: 700, letterSpacing: '-0.5mm' }}>
                  {data?.receiverAddr}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 10, textAlign: 'center' }}></td>
                <td colSpan={11}>
                  <div style={{ fontSize: 10 }}>
                    {data?.senderName} <span className="b">{data?.senderPhone}</span>
                    <div className="b">{data?.senderAddr}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={12}>
                  <div style={{ minHeight: '10mm', maxHeight: '10m' }}>{/* 상품명 */}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={12}>
                  <div style={{ maxHeight: '30mm', textAlign: 'right', paddingRight: '10mm' }}>
                    {data?.parcelInvoiceNumber && <Barcode width={2.5} margin={0} value={data?.parcelInvoiceNumber + '' || ''} height={35} displayValue={false} />}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </PrintBarcode100123>
      )}
    </div>
  );
};
