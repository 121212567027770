export interface ToastProps {
  message?: string;
  open: boolean;
  color?: string;
}

const Toast = ({ message, open, color }) => {
  return (
    <div
      style={{
        backgroundColor: '#FFF',
        boxShadow: 'none',
      }}
    >
      <div
        style={{
          fontSize: 13,
          display: open ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: 350,
          border: color ? `1px solid ${color}` : '1px solid #3DAE92',
          color: color || '#3DAE92',
          padding: 10,
          borderRadius: 5,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {message}
      </div>
    </div>
  );
};

export default Toast;
