export const REGION_SEOUL = '02';
export const REGION_BUSAN = '051';
export const REGION_DAEGU = '053';
export const REGION_INCHEON = '032';
export const REGION_GWANGJU = '062';
export const REGION_DAEJEON = '042';
export const REGION_ULSAN = '052';
export const REGION_SEJONG = '044';
export const REGION_GYEONGGI = '031';

export const REGION_GANGWON = '033';
export const REGION_CHUNGBUCK = '043';
export const REGION_CHUNGNAM = '041';
export const REGION_JEONBUCK = '063';
export const REGION_JEONNAM = '061';
export const REGION_GYEONGBUCK = '054';
export const REGION_GYEONGNAM = '055';
export const REGION_JEJU = '064';

export const REGION_NUMBERS = [
  REGION_SEOUL,
  REGION_BUSAN,
  REGION_DAEGU,
  REGION_INCHEON,
  REGION_GWANGJU,
  REGION_DAEJEON,
  REGION_ULSAN,
  REGION_SEJONG,
  REGION_GYEONGGI,
  REGION_GANGWON,
  REGION_CHUNGBUCK,
  REGION_CHUNGNAM,
  REGION_JEONBUCK,
  REGION_JEONNAM,
  REGION_GYEONGBUCK,
  REGION_GYEONGNAM,
  REGION_JEJU,
];
