import { inventoryService } from 'pages/INVENTORY/1inventory/_service/service';
import { useEffect, useRef, useState } from 'react';
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import { defaultGridProps } from 'common/grid/gridBox';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IInfoDetailObj {
  visible?: boolean;
  e?: IGrid.ButtonClickEvent;
}

export const InfoDetail = ({ infoDetailObj, setInfoDetailObj }) => {
  const dispatch = useDispatch();
  const collectsRef = useRef<AUIGrid>();
  const modifiesRef = useRef<AUIGrid>();
  const { masterOptions } = useSelector((state: RootState) => state.menu);

  const hasTabs = [{ title: '고객회수정보' }, { title: '재고조정정보' }];
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    fetchDetailInfo(infoDetailObj?.e);
  }, []);

  const fetchDetailInfo = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const dataField = e?.dataField;
    const item = e?.item;
    const searchObj = JSON.parse(e?.item?.searchObj);

    const { collects, modifies } = await inventoryService.getInventoryDefectedDetailInfo(item?.modelStockSeq, item?.centerCode, searchObj);

    collects.forEach((row) => {
      row['centerCodeKr'] = selectlabel(row?.centerCode, masterOptions?.CENTER_WHOLE);
      row['collectCenterCodeKr'] = selectlabel(row?.collectCenterCode, masterOptions?.CENTER_WHOLE);
      row['feeTypeKr'] = selectlabel(row?.feeType, masterOptions?.FEE_TYPE);
      row['statusKr'] = selectlabel(row?.status, masterOptions?.DEFECTED_STAT);
      row['customerAddr'] = row.customerAddr1 + ',' + row.customerAddr2;
    });
    modifies.forEach((row) => {
      row['centerCodeKr'] = selectlabel(row?.centerCode, masterOptions?.CENTER_WHOLE);
      row['modifyCenterCodeKr'] = selectlabel(row?.modifyCenterCode, masterOptions?.CENTER_WHOLE);
      row['statusKr'] = selectlabel(row.status, masterOptions?.DEFECTED_STAT);
    });
    collectsRef.current.setGridData(collects);
    modifiesRef.current.setGridData(modifies);
    dispatch(setLoading(null));
  };

  const excelDownload = (type: 'collects' | 'modifies') => {
    if (type === 'collects') collectsRef.current.exportAsXlsx({ fileName: '고객회수정보' });
    else modifiesRef.current.exportAsXlsx({ fileName: '재고조정정보' });
  };

  const columns = {
    COLLECTS: [
      {
        headerText: '구분',
        dataField: 'statusKr',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '현재창고',
        dataField: 'centerCodeKr',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '회수창고',
        dataField: 'collectCenterCodeKr',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '수령인',
        dataField: 'customerName',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '주소',
        dataField: 'customerAddr',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '배송기사',
        dataField: 'userId',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '송장번호',
        dataField: 'customerOrderInvoiceSeq',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '외부송장번호',
        dataField: 'externalInvoiceNumber',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '송장유형',
        dataField: 'feeTypeKr',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '주문등록일',
        dataField: 'orderDate',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '주문지정일',
        dataField: 'deliveryDate',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '회수완료일',
        dataField: 'finishDate',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '특이사항',
        dataField: 'collectNote',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '불량 / 수리메모',
        dataField: 'defectedNote',
        filter: {
          showIcon: true,
        },
      },
    ],
    MODIFIES: [
      {
        headerText: '구분',
        dataField: 'statusKr',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '현재창고',
        dataField: 'centerCodeKr',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '조정창고',
        dataField: 'modifyCenterCodeKr',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '조정사유',
        dataField: 'reason',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '신청자',
        dataField: 'requestId',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '승인자',
        dataField: 'confirmId',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '조정번호',
        dataField: 'inventoryModificationSeq',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '승인일시',
        dataField: 'confirmDatetime',
        filter: {
          showIcon: true,
        },
      },
    ],
  };

  return (
    <CommonModalNew
      title={'불용 재고정보'}
      style={{ width: 1000 }}
      visible={infoDetailObj?.visible}
      setVisible={() => setInfoDetailObj(null)}
      children={
        <div style={{ minHeight: 620 }}>
          <div className="div-top-tab">
            {hasTabs?.map((tab, index) => {
              return (
                <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                  <li
                    onClick={() => {
                      setCurrentTab(index);
                    }}
                  >
                    {tab?.title}
                  </li>
                </div>
              );
            })}
          </div>
          <div style={{ zIndex: currentTab === 0 ? 13 : 10 }}>
            <div className="grid-button-area">
              <div className="green" id="add-child" onClick={() => excelDownload('collects')}>
                엑셀다운
              </div>
            </div>
            <AUIGrid
              ref={collectsRef}
              name={`collects`}
              columnLayout={columns?.COLLECTS}
              gridProps={{
                //
                ...defaultGridProps,
                height: 500,
                showRowAllCheckBox: false,
                showRowCheckColumn: false,
              }}
            />
          </div>
          <div style={{ zIndex: currentTab === 1 ? 13 : 10 }}>
            <div className="grid-button-area">
              <div className="green" id="add-child" onClick={() => excelDownload('modifies')}>
                엑셀다운
              </div>
            </div>
            <AUIGrid
              ref={modifiesRef}
              name={`modifies`}
              columnLayout={columns?.MODIFIES}
              gridProps={{
                //
                ...defaultGridProps,
                height: 500,
                showRowAllCheckBox: false,
                showRowCheckColumn: false,
              }}
            />
          </div>
        </div>
      }
    />
  );
};
