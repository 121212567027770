import { ReactNode } from 'react';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IAlertObj {
  visible?: boolean;
  children?: ReactNode | string;
}

const AlertModal = ({ alertModalObj, setAlertModalObj }) => {
  return (
    <CommonModalNew
      style={{ width: 425, whiteSpace: 'break-spaces' }}
      children={<>{alertModalObj?.children}</>}
      visible={alertModalObj?.visible}
      setVisible={() => {
        setAlertModalObj(null);
      }}
    />
  );
};

export default AlertModal;
