// react
import { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { setLoading } from 'redux/services/menuSlice';
import { LoadingOutDetailListDTO } from 'interface/warehouse';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { takeoutService } from '../_services/service';
import ModalforBarcode, { HandleWithCare, IBarcodeInfoObj, JustPallet, PalletAndSign, PrintTitle } from 'components/print/print';
import { SelectD } from 'components/reactstrap/reactstrap';

export interface IPrints {
  visible?: boolean;
  type?: 'picking' | 'chulgo' | 'takeout';
  item?: any;
}

const convertor = {
  picking: {
    title: '반출피킹지시서',
    seq: 'stockRemoveSeq',
  },
  chulgo: {
    title: '반출출고지시서',
    seq: 'loadingStatementSeq',
  },
  takeout: {
    title: '반출출차 운송명세서',
    seq: 'transportStatementSeq',
  },
};

export const BanchulPrints = ({ banchulPrintObj, setBanchulPrintObj }) => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [data, setData] = useState<any>();
  const [barcodeInfoObj, setBarcodeInfoObj] = useState<IBarcodeInfoObj>();

  const wrapperRef = useRef();
  useEffect(() => {
    console.log(banchulPrintObj);
    if (banchulPrintObj?.item) {
      fetchPrintData(banchulPrintObj?.item);
    }
  }, []);

  const fetchPrintData = async (item) => {
    dispatch(setLoading('GET'));
    let searchDTO, rs1, rs2;
    if (banchulPrintObj?.type === 'picking') {
      searchDTO = {
        stockRemoveSeq: item?.stockRemoveSeq,
        centerCode: item?.centerCode,
        target: item?.target,
        stockRemoveDate: item?.stockRemoveDate,
      };
      rs1 = await takeoutService?.getAction(`/warehouse/remove/picking/list?stockRemoveSeq=${item?.stockRemoveSeq}`, null);
      rs2 = await takeoutService?.getAction(`/warehouse/remove/picking/detail`, searchDTO);
    } else if (banchulPrintObj?.type === 'chulgo') {
      searchDTO = {
        stockRemoveSeq: item?.stockRemoveSeq,
        loadingStatementSeq: item?.loadingStatementSeq,
      };
      rs1 = await takeoutService?.getAction(`/warehouse/remove/beforeLoading`, searchDTO);
      rs2 = await takeoutService?.getAction(`/warehouse/remove//beforeLoading/detail/print?loadingStatementSeq=${item?.loadingStatementSeq}`, null);
    } else if (banchulPrintObj?.type === 'takeout') {
      rs1 = await takeoutService?.getAction(`/warehouse/remove/loading/web?transportStatementSeq=${item?.transportStatementSeq}&loadingStatementSeq=${item?.loadingStatementSeq}`, null);
      rs2 = await takeoutService?.getAction(`/warehouse/remove/loading/detail/${item?.loadingOutSeq}`, null);
    }

    if (rs1?.status === 200) {
      const originalCenterCode = masterOptions?.CENTER_WHOLE?.filter((ele) => ele.centerCode === item?.centerCode)[0];
      const data = {
        ...rs1?.data[0],
        originalCenterCode,
        destinationCenterCode: item?.target,
        initDatetime: item?.initDatetime,
        stockRemoveDate: item?.stockRemoveDate,
        availableCount: ((rs2?.data || []) as LoadingOutDetailListDTO[])
          ?.map((ele) => ele.availableQuantity)
          ?.reduce((acc, cur) => {
            return acc + cur;
          }),
        defectedCount: ((rs2?.data || []) as LoadingOutDetailListDTO[])
          ?.map((ele) => ele.defectedQuantity)
          ?.reduce((acc, cur) => {
            return acc + cur;
          }),
        modelList: rs2?.data?.map((row) => {
          return {
            ...row,
            receiverNames: row.receiverNames?.length > 0 ? row.receiverNames : !row.receiverNames ? null : new Array(row.quantity)?.fill('-'),
            serialNumbers: row.serialNumbers?.length > 0 ? row.serialNumbers : !row.serialNumbers ? null : new Array(row.quantity)?.fill('-'),
          };
        }),
      };
      setData(data);
    }
    dispatch(setLoading(null));
  };

  const [printFormat, setPrintFormat] = useState<'P' | 'L'>('P');
  const printFormatOptions = [
    {
      value: 'P', // 세로출력
      label: '기본출력',
    },
    {
      value: 'L', // 가로출력
      label: '상세출력',
    },
  ];

  const printAvailableType = (data) => {
    if (data?.availableQuantity + data?.defectedQuantity + data?.etcQuantity === 0) return '';
    if (data?.availableQuantity > 0) return '양품';
    if (data?.defectedQuantity > 0) return '불용';
    if (data?.etcQuantity > 0) return '기타';
  };

  return (
    <>
      <CommonModalNew
        title={convertor[banchulPrintObj?.type]?.title}
        style={{ width: banchulPrintObj?.type === 'picking' ? 1300 : 900 }}
        visible={banchulPrintObj?.visible}
        setVisible={() => setBanchulPrintObj(null)}
        rightTitle={
          <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            {banchulPrintObj?.type === 'picking' && (
              <div style={{ width: 100 }}>
                <label className="col-form-label">출력형식</label>
                <SelectD
                  hasNull={false}
                  value={{ value: printFormat, label: selectlabel(printFormat, printFormatOptions) }}
                  options={printFormatOptions}
                  onChange={(option) => {
                    setPrintFormat((option as OptionItem)?.value);
                  }}
                />
              </div>
            )}
            <div style={{ paddingBottom: 3 }}>
              <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
            </div>
          </div>
        }
        children={
          <>
            <div
              ref={wrapperRef}
              style={{
                display: 'flex',
                justifyContent: convertor[banchulPrintObj?.type]?.justifyContent || 'center',
                flexDirection: 'column',
                alignItems: convertor[banchulPrintObj?.type]?.alignItems || 'center',
              }}
            >
              <div style={{ padding: 30, width: '100%' }} className="break-for-onePage-print">
                <ModalforBarcode barcodeInfoObj={barcodeInfoObj} setBarcodeInfoObj={setBarcodeInfoObj} />
                <PrintTitle seq={banchulPrintObj?.item?.[convertor[banchulPrintObj?.type]?.seq]} title={convertor[banchulPrintObj?.type]?.title} />
                {banchulPrintObj?.type === 'picking' && (
                  <>
                    <div className="presenterInput">
                      <span>1. 기본정보</span>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <table className="border-table" style={{ width: '50%' }}>
                          <thead>
                            <tr>
                              <th>피킹지시 확정일시</th>
                              <td colSpan={2}>{data?.initDatetime}</td>
                            </tr>
                            <tr>
                              <th>반출창고</th>
                              <td colSpan={2}>
                                <span className="b">{data?.originalCenterCode?.centerName}</span> <br /> {data?.originalCenterCode?.address} {data?.originalCenterCode?.addressDetail || ''}
                              </td>
                            </tr>
                            <tr>
                              <th>반출대상</th>
                              <td colSpan={2}>{data?.destinationCenterCode}</td>
                            </tr>
                          </thead>
                        </table>
                        <table className="border-table" style={{ width: '100mm' }}>
                          <thead>
                            <tr>
                              <th>작업지시일</th>
                              <th>작업지시자(인)</th>
                              <th>작업자(인)</th>
                              <th>창고장(인)</th>
                            </tr>
                            <tr style={{ height: 50 }}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>

                    <div className="presenterInput">
                      {printFormat === 'P' ? (
                        <>
                          <span>2. 상품정보</span>
                          <table className="border-table">
                            <thead className="text-center">
                              <tr>
                                <th colSpan={12}>제품정보</th>
                                <th colSpan={2}>
                                  피킹지시
                                  <br />
                                  수량
                                </th>
                              </tr>
                              <tr>
                                <th colSpan={2}>로케이션</th>
                                <th colSpan={2}>바코드</th>
                                <th colSpan={2}>SKU</th>
                                <th colSpan={3}>제품</th>
                                <th colSpan={3}>제품명</th>
                                <th>양품</th>
                                <th>불용</th>
                              </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                              {data?.modelList?.map((list, idx) => (
                                <tr key={`modelListP_${idx}`}>
                                  <td colSpan={2}>{list?.locationCode}</td>
                                  <td colSpan={2} key={`${idx}-barcode`} id={`${idx}-barcode2323`} onClick={() => setBarcodeInfoObj({ visible: true, data: list?.barcode })}>
                                    {list?.barcode}
                                  </td>
                                  <td colSpan={2}>{list?.modelStockSeq}</td>
                                  <td colSpan={3}>{list?.model}</td>
                                  <td colSpan={3}>
                                    {list?.handleWithCareFlag && <HandleWithCare />}
                                    {list?.modelName}
                                  </td>
                                  <td>{list?.availableQuantity}</td>
                                  <td>{list?.defectedQuantity}</td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot style={{ textAlign: 'center' }}>
                              <tr>
                                <td style={{ textAlign: 'right' }} colSpan={12}>
                                  계
                                </td>
                                <td>{data?.availableCount}</td>
                                <td>{data?.defectedCount}</td>
                              </tr>
                            </tfoot>
                          </table>
                        </>
                      ) : (
                        <>
                          <span>2. 제품정보</span>
                          <table className="border-table">
                            <thead className="text-center">
                              <tr>
                                <th colSpan={3}>로케이션</th>
                                <th colSpan={3}>바코드</th>
                                <th colSpan={4}>제품</th>
                                <th colSpan={4}>제품명</th>
                                <th colSpan={2}>재고상태</th>
                                <th colSpan={2}>고객명</th>
                                <th>수량</th>
                                <th colSpan={4}>시리얼번호</th>
                              </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                              {data?.modelList?.map((list, idx) => (
                                <tr key={`modelListL_${idx}`}>
                                  <td colSpan={3}>{list?.locationCode}</td>
                                  <td colSpan={3} onClick={() => setBarcodeInfoObj({ visible: true, data: list?.barcode })}>
                                    {list?.barcode}
                                  </td>
                                  <td colSpan={4}>{list?.model}</td>
                                  <td colSpan={4}>
                                    {list?.handleWithCareFlag && <HandleWithCare />}
                                    {list?.modelName}
                                  </td>
                                  <td colSpan={2}>{printAvailableType(list)}</td>
                                  <td colSpan={2}>
                                    {list?.receiverNames?.map((sn) => {
                                      return <div>{sn || '-'}</div>;
                                    })}
                                  </td>
                                  <td>{list?.quantity}</td>
                                  <td colSpan={4}>
                                    {list?.serialNumbers?.map((sn) => {
                                      return <div>{sn || '-'}</div>;
                                    })}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot style={{ textAlign: 'center' }}>
                              <tr>
                                <td style={{ textAlign: 'right', paddingRight: 10 }} colSpan={23}>
                                  합계 &nbsp;
                                  {data?.modelList
                                    ?.map((ele) => Number(ele.quantity))
                                    .reduce((acc, cur) => {
                                      return acc + cur;
                                    })}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </>
                      )}
                    </div>
                    <JustPallet />
                  </>
                )}
                {banchulPrintObj?.type === 'chulgo' && (
                  <>
                    <div className="presenterInput">
                      <span>1. 기본정보</span>
                      <table className="border-table">
                        <thead>
                          <tr>
                            <th>출고예정일</th>
                            <td colSpan={4}>{data?.stockRemoveDate}</td>
                            <th>반출유형</th>
                            <td colSpan={2}>{data?.reason && selectlabel(data?.reason, masterOptions?.REMOVE_REASON)}</td>
                          </tr>
                          <tr>
                            <th>반출출고 지시일</th>
                            <td colSpan={4}>{data?.initDatetime}</td>
                            <th>출고지시확정자</th>
                            <td colSpan={2}> {data?.confirmId}</td>
                          </tr>
                          <tr>
                            <th>반출창고</th>
                            <td colSpan={4}>
                              {data?.originalCenterCode?.centerName} / {data?.originalCenterCode?.address} {data?.originalCenterCode?.addressDetail || ''}
                            </td>
                            <th>관리자</th>
                            <td colSpan={2}>
                              {data?.originalCenterCode?.managerName || '-'} / {data?.originalCenterCode?.managerPhone || '-'}
                            </td>
                          </tr>
                          <tr>
                            <th>반출대상</th>
                            <td colSpan={4}>{data?.destinationCenterCode}</td>
                            <th></th>
                            <td colSpan={2}></td>
                          </tr>
                        </thead>
                      </table>
                    </div>

                    <div className="presenterInput">
                      <span>2. 상품정보</span>
                      <table className="border-table">
                        <thead className="text-center">
                          <tr>
                            <th colSpan={15}>제품정보</th>
                            <th colSpan={2}>
                              반출출고
                              <br />
                              지시수량
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={2}>로케이션</th>
                            <th colSpan={2}>제품그룹</th>
                            <th colSpan={2}>바코드</th>
                            <th colSpan={2}>SKU(외부SKU)</th>
                            <th colSpan={2}>판매사</th>
                            <th colSpan={5}>제품</th>
                            <th>양품</th>
                            <th>불용</th>
                          </tr>
                        </thead>
                        <tbody style={{ textAlign: 'center' }}>
                          {data?.modelList?.map((list, idx) => (
                            <tr key={`modelList_${idx}`}>
                              <td colSpan={2}>{list?.locationCode}</td>
                              <td colSpan={2}>{list?.modelGroup && selectlabel(list?.modelGroup, masterOptions?.MODEL_GROUP)}</td>
                              <td colSpan={2} key={`${idx}-barcode`} id={`${idx}-barcode2323`} onClick={() => setBarcodeInfoObj({ visible: true, data: list?.barcode })}>
                                {list?.barcode}
                              </td>
                              <td colSpan={2}>
                                {list?.modelStockSeq}({list?.skuNumber})
                              </td>
                              <td colSpan={2}>{list?.partnerSeq && selectlabel(list?.partnerSeq, masterOptions?.SELLER)}</td>
                              <td colSpan={5}>{list?.modelName}</td>
                              <td>{list?.availableQuantity}</td>
                              <td>{list?.defectedQuantity}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot style={{ textAlign: 'center' }}>
                          <tr>
                            <td style={{ textAlign: 'right' }} colSpan={15}>
                              계
                            </td>
                            <td>{data?.availableCount}</td>
                            <td>{data?.defectedCount}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <PalletAndSign signWhen="* 상.하차 / 검수 완료 후 서명 하시오" />
                  </>
                )}
                {banchulPrintObj?.type === 'takeout' && (
                  <>
                    <div className="presenterInput">
                      <span>1. 기본정보</span>

                      <table className="border-table">
                        <thead>
                          <tr>
                            <th>출고예정일</th>
                            <td colSpan={7}>{data?.stockRemoveDate}</td>
                          </tr>
                          <tr>
                            <th>출차완료일</th>
                            <td colSpan={7}>{data?.initDatetime}</td>
                          </tr>
                          <tr>
                            <th>입고예정일</th>
                            <td colSpan={7}>{data?.promiseUnloadDate}</td>
                          </tr>
                          <tr>
                            <th>반출창고</th>
                            <td colSpan={4}>
                              {data?.originalCenterCode?.centerName} / {data?.originalCenterCode?.address} {data?.originalCenterCode?.addressDetail || ''}
                            </td>
                            <th>관리자</th>
                            <td colSpan={2}>
                              {data?.originalCenterCode?.managerName || '-'} / {data?.originalCenterCode?.managerPhone || '-'}
                            </td>
                          </tr>
                          <tr>
                            <th>비고</th>
                            <td colSpan={7}>{data?.loadingOutMemo}</td>
                          </tr>
                          <tr>
                            <th>반출대상</th>
                            <td colSpan={4}>{data?.destinationCenterCode}</td>
                            <th></th>
                            <td colSpan={2}></td>
                          </tr>
                          <tr>
                            <th>운송기사</th>
                            <td colSpan={4}>
                              {data?.loadingDriverName} / {data?.loadingDriverPhone}
                            </td>
                            <th>운송차량</th>
                            <td colSpan={2}>
                              {data?.loadingDriverCarType && selectlabel(data?.loadingDriverCarType, masterOptions?.CAR_TYPE)} / {data?.loadingDriverCarNumber || '-'}
                            </td>
                          </tr>
                        </thead>
                      </table>
                    </div>

                    <div className="presenterInput">
                      <span>2. 상품정보</span>
                      <table className="border-table">
                        <thead className="text-center">
                          <tr>
                            <th colSpan={13}>제품정보</th>
                            <th colSpan={2}>
                              반출출차
                              <br />
                              지시수량
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={2}>바코드</th>
                            <th colSpan={2}>SKU</th>
                            <th colSpan={2}>외부SKU</th>
                            <th colSpan={2}>판매사</th>
                            <th colSpan={5}>제품</th>
                            <th>양품</th>
                            <th>불용</th>
                          </tr>
                        </thead>
                        <tbody style={{ textAlign: 'center' }}>
                          {data?.modelList?.map((list, idx) => (
                            <tr key={`modelList_${idx}`}>
                              <td colSpan={2} key={`${idx}-barcode`} id={`${idx}-barcode2323`} onClick={() => setBarcodeInfoObj({ visible: true, data: list?.barcode })}>
                                {list?.barcode}
                              </td>
                              <td colSpan={2}>{list?.modelStockSeq}</td>
                              <td colSpan={2}>{list?.skuNumber}</td>
                              <td colSpan={2}>{list?.partnerSeq && selectlabel(list?.partnerSeq, masterOptions?.SELLER)}</td>
                              <td colSpan={5}>{list?.modelName}</td>
                              <td>{list?.availableQuantity}</td>
                              <td>{list?.defectedQuantity}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot style={{ textAlign: 'center' }}>
                          <tr>
                            <td style={{ textAlign: 'right' }} colSpan={13}>
                              계
                            </td>
                            <td>{data?.availableCount}</td>
                            <td>{data?.defectedCount}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <PalletAndSign
                      signWhen="* 상.하차 / 검수 완료 후 서명 하시오"
                      signArr={['반출창고 담당자', '운송기사', '입고창고 담당자']}
                      contentArr={[data?.originalCenterCode?.managerName, '', '']}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
