import { useEffect, useState } from 'react';
import Presenter from './presenter';
import { WMS_PICKING_STRATEGY, WMS_PICKING_STRATEGY_DETAIL, WMS_PICKING_STRATEGY_DETAIL_SAVE } from 'envVar';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { v4 as uuidv4 } from 'uuid';
const Container = ({ tabId }) => {
  const dispatch = useDispatch();
  //옵션
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);
  const [gridId, setGridId] = useState();
  const [rows, setRows] = useState();
  const columns = {
    strategy_1: [
      {
        headerText: '선택',
        dataField: 'detail',
        style: 'click-fake-item',
        editable: false,
      },
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '제품그룹',
        dataField: 'modelGroupKr',
        editable: false,
      },
      {
        headerText: '전략명',
        dataField: 'title',
        style: 'click-fake-item',
        editable: false,
      },
      {
        headerText: '활성화 여부',
        dataField: 'activateFlagKr',
        style: 'click-fake-item',
        editable: false,
      },
      {
        headerText: '삭제',
        dataField: 'delete',
        style: 'click-fake-item',
        editable: false,
      },
    ],
    strategy_2: [
      {
        headerText: '전략',
        dataField: 'strategyKr',
        editable: false,
      },
      {
        headerText: '비고',
        dataField: 'note',
        cellMerge: true,
        mergeRef: 'group',
        mergePolicy: 'restrict',
        editable: false,
      },
      {
        headerText: '추가',
        dataField: 'add',
        style: 'click-fake-item',
        editable: false,
      },
    ],
    strategy_3: [
      {
        headerText: '우선순위',
        dataField: 'priority',
        width: 50,
        editable: false,
      },
      {
        headerText: '전략',
        dataField: 'strategyKr',
        editable: false,
      },
      {
        headerText: '이동(상)',
        dataField: 'up',
        width: 50,
        editable: false,
      },
      {
        headerText: '이동(하)',
        dataField: 'down',
        width: 50,
        editable: false,
      },
      {
        headerText: '삭제',
        dataField: 'delete',
        style: 'click-fake-item',
        editable: false,
      },
    ],
  };
  //검색자
  const [searchObj, setSearchObj] = useState({
    centerCode: null,
    partnerSeq: null,
    modelGroup: null,
    type: 'PICK',
  });

  const [modalObj, setModalObj] = useState({
    visible: false,
    data: null,
    createFn: () => {},
    updateFn: (data) => updateStrategyAPI(data),
  });

  useEffect(() => {
    initGridId();
  }, []);

  const initGridId = () => {
    const seed = uuidv4();
    setGridId({
      strategy_1: `strategy_1_${seed}`,
      strategy_2: `strategy_2_${seed}`,
      strategy_3: `strategy_3_${seed}`,
    });
  };

  const paramingSearchObjToUrl = (url, searchObj) => {
    if (searchObj?.centerCode) url += `&centerCode=${searchObj?.centerCode}`;
    if (searchObj?.partnerSeq) url += `&partnerSeq=${searchObj?.partnerSeq}`;
    if (searchObj?.modelGroup) url += `&modelGroup=${searchObj?.modelGroup}`;
    if (searchObj?.type) url += `&type=${searchObj?.type}`;
    return url;
  };

  const fetchList = async ({ type, data, searchObj }) => {
    if (type === 'strategy_1') {
      return await getStategyStep1(searchObj);
    } else if ('strategy_3') {
      return await getStategyStep3(data);
    }
  };
  const getStategyStep1 = async (searchObj) => {
    let url = WMS_PICKING_STRATEGY + '?';
    if (!searchObj.centerCode) {
      alert('창고는 필수값 입니다.');
      return;
    } else if (!searchObj.partnerSeq) {
      alert('판매사는 필수값 입니다.');
      return;
    } else {
      dispatch(setLoading('GET'));
      const search = paramingSearchObjToUrl(url, searchObj);
      return httpClient
        .get(search)
        .then((res) => {
          dispatch(setLoading(null));
          if (res?.status === 200) {
            const list = res.data;
            list.forEach((row) => {
              row.detail = '선택';
              row.searchObj = JSON.stringify(searchObj);
              row.delete = row.title === '기본피킹전략' ? '' : '삭제';
              row.activateFlagKr = row.activateFlag === true ? '활성화' : '미활성화';
              row.partnerSeqKr = MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq];
              row.modelGroupKr = MASTER_OBJ?.MODEL_GROUP?.[row.modelGroup];
            });
            return list;
          }
          return [];
        })
        .catch((err) => {
          dispatch(setLoading(null));
          return [];
        });
    }
  };

  const getStategyStep3 = async (data) => {
    let url = WMS_PICKING_STRATEGY_DETAIL + `?warehouseStrategySeq=${data.warehouseStrategySeq}`;
    return httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        const list = rs.data;
        list.forEach((row) => {
          row.strategyKr = MASTER_OBJ?.WH_PICK_STRATEGY?.[row.strategy];
          row.up = '⬆';
          row.down = '⬇';
          row.delete = data?.title === '기본피킹전략' ? null : '삭제';
        });
        return list;
      }
      return [];
    });
  };

  const openDetailStategy = async (item) => {
    const strategy_3 = await fetchList({ type: 'strategy_3', data: item });
    const strategy_2 = MASTER_OPS?.WH_PICK_STRATEGY?.map((ele) => {
      return {
        warehouseStrategySeq: item.warehouseStrategySeq,
        strategyKr: ele.label,
        add: item?.title === '기본피킹전략' ? null : '추가',
        strategy: ele.value,
        group: setCollector(ele.value),
        note: setCollector(ele.value) ? '동시선택 불가' : '',
      };
    });
    setRows((prev) => {
      return {
        ...prev,
        strategy_2,
        strategy_3,
      };
    });
  };

  const activateStrategyAPI = async (item) => {
    const url = `/warehouse/strategy/activate?warehouseStrategySeq=${item.warehouseStrategySeq}`;
    httpClient.post(url).then(async (rs) => {
      if (rs?.status === 200) {
        alert('전략이 활성화되었습니다!');
        setRows({
          strategy_1: await fetchList({ type: 'strategy_1', searchObj: JSON.parse(item.searchObj) }),
          strategy_2: [],
          strategy_3: [],
        });
      }
    });
  };

  const deleteStategyAPI = async (item) => {
    if (window.confirm('해당 전략을 삭제하시겠습니까?')) {
      const url = `/warehouse/strategy/delete?warehouseStrategySeq=${item.warehouseStrategySeq}`;
      httpClient.post(url).then(async (rs) => {
        if (rs?.status === 200) {
          alert('해당 전략이 삭제되었습니다');
          setRows({
            strategy_1: await fetchList({ type: 'strategy_1', searchObj: JSON.parse(item.searchObj) }),
            strategy_2: [],
            strategy_3: [],
          });
        }
      });
    }
  };

  const updateStrategyAPI = async ({ data, searchObj }) => {
    let url = `/warehouse/strategy/save`;
    httpClient.post(url, data).then(async (rs) => {
      if (rs?.status === 200) {
        alert(data?.warehouseStrategySeq ? '전략명이 수정되었습니다!' : '전략명이 추가되었습니다!');
        setModalObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
        setRows({
          strategy_1: await fetchList({ type: 'strategy_1', searchObj }),
          strategy_2: [],
          strategy_3: [],
        });
      }
    });
  };

  const setCollector = (str) => {
    const group = sets.filter((ele) => str.includes(ele))[0];
    return group;
  };
  const sets = ['REG_FIFO', 'QTY', 'LOC_DOCK', 'LOC_FLOOR'];

  const onCellClick = async (e) => {
    if (e.pid.includes('strategy_1')) {
      if (e.dataField === 'title') {
        if (e.value === '기본피킹전략') {
          alert('기본피킹전략은 수정할 수 없습니다!');
        } else {
          setModalObj((prev) => {
            return {
              ...prev,
              visible: true,
              data: e.item,
            };
          });
        }
      } else if (e.dataField === 'delete' && e.value) {
        deleteStategyAPI(e.item);
      } else if (e.dataField === 'activateFlagKr') {
        if (e.value === '활성화') {
          alert('이미 활성화된 전략입니다!');
        } else {
          activateStrategyAPI(e.item);
        }
      } else if (e.dataField === 'detail' && e.value) {
        openDetailStategy(e.item);
      }
    } else if (e.pid.includes('strategy_2')) {
      if (e.dataField === 'add' && e.value) {
        const row3 = window.AUIGrid.getGridData(`#strategy_3_${e.pid.split('_')[2]}`);
        const strate3 = row3.map((a) => {
          return a.strategy;
        });
        if (strate3.some((ele) => ele?.includes(e.item.group))) {
          alert('동시선택 불가한 항목이 이미 포함되어있습니다!');
        } else {
          // 추가
          row3.push({
            warehouseStrategySeq: e.item.warehouseStrategySeq,
            strategyKr: MASTER_OBJ?.WH_PICK_STRATEGY?.[e.item.strategy],
            up: '⬆',
            down: '⬇',
            delete: '삭제',
            strategy: e.item.strategy,
            priority: row3.length + 1,
          });
          setRows({
            ...rows,
            strategy_3: row3,
          });
        }
      }
    } else if (e.pid.includes('strategy_3')) {
      if (e.dataField === 'delete' && e.value) {
        const row3 = window.AUIGrid.getGridData(e.pid);
        const deleted = row3.filter((ele, idx) => idx !== e.rowIndex);
        setRows({
          ...rows,
          strategy_3: deleted,
        });
      } else if (e.dataField === 'up') {
        window.AUIGrid.moveRowsToUp(e.pid);
        const row3 = window.AUIGrid.getGridData(e.pid);
        row3.forEach((row, idx) => {
          row.priority = idx + 1;
        });
        setRows({
          ...rows,
          strategy_3: row3,
        });
      } else if (e.dataField === 'down') {
        window.AUIGrid.moveRowsToDown(e.pid);
        const row3 = window.AUIGrid.getGridData(e.pid);
        row3.forEach((row, idx) => {
          row.priority = idx + 1;
        });
        setRows({
          ...rows,
          strategy_3: row3,
        });
      }
    }
  };

  const saveStrategyAPI = async (dataArr) => {
    let url = WMS_PICKING_STRATEGY_DETAIL_SAVE + `?warehouseStrategySeq=${dataArr[0].warehouseStrategySeq}`;
    let data = dataArr.map((ele) => {
      return {
        priority: ele.priority,
        strategy: ele.strategy,
      };
    });
    httpClient.post(url, data).then((rs) => {
      if (rs?.status === 200) {
        alert('전략이 저장되었습니다!');
      }
    });
  };

  return (
    <Presenter
      gridId={gridId}
      columns={columns}
      rows={rows}
      setRows={setRows}
      // obj
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      // modal
      modalObj={modalObj}
      setModalObj={setModalObj}
      //function
      fetchList={fetchList}
      onCellClick={onCellClick}
      saveStrategyAPI={saveStrategyAPI}
    />
  );
};
export default Container;
