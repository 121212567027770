import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Col, Row, Form } from 'reactstrap';
import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { ParcelInvoiceSearchDTO } from '../../../_interface/order';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { numberThousandComma } from 'common/util/counting';
import { FLAG_OPT } from 'pages/PARCEL/_asset/asset';
import { useDidMountEffect } from 'hooks/basichooks';

export const SearchBox = (props: ISearchBoxProps<ParcelInvoiceSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList, fetchCount } = props;
  const [flagArr, setFlagArr] = useState<string[]>(FLAG_OPT?.map((ele) => ele.value));
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE: null,
    SEARCH_ORDER: null,
    SEARCH_INVOICE: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_ORDER, searchObj));
  }, [selectTypeObj?.SEARCH_ORDER]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_INVOICE, searchObj));
  }, [selectTypeObj?.SEARCH_INVOICE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  const isMountedRef = useRef(false);
  useDidMountEffect(() => {
    if (isMountedRef?.current) {
      if (searchObj?.collectFlag || searchObj?.transitFlag || searchObj?.centerFlag || searchObj?.finishFlag || searchObj?.deliveryFlag || searchObj?.transferFlag) fetchList(searchObj);
    } else {
      isMountedRef.current = true;
    }
  }, [searchObj?.collectFlag, searchObj?.transitFlag, searchObj?.centerFlag, searchObj?.finishFlag, searchObj?.deliveryFlag, searchObj?.transferFlag]);

  useEffect(() => {
    if (searchObj) {
      const _searchObj = { ...searchObj };
      FLAG_OPT.forEach(({ value }) => {
        if (flagArr?.includes(value)) _searchObj[value] = 'true';
        else _searchObj[value] = 'false';
      });
      fetchList(_searchObj);
      setSearchObj(_searchObj);
    }
  }, [flagArr]);

  const handleCheckbox = (e) => {
    const id = e.target.id;
    if (e.target.id === 'all') {
      if (e.target.checked === true) {
        setFlagArr(FLAG_OPT?.map((ele) => ele.value));
      } else {
        setFlagArr([]);
      }
    } else {
      let set = new Set(flagArr);
      if (set.has(id)) {
        set.delete(id);
      } else {
        set.add(id);
      }
      const newArr = Array.from(set);
      setFlagArr(newArr);
    }
  };

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">판매사</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
              value={searchObj?.partnerSeqList?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.SELLER_WHOLE?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeqList: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">판매처</label>
            <InputD
              value={searchObj?.seller || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  seller: e.target.value,
                });
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">판매채널</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.SALES_CHANNEL}
              value={searchObj?.salesChannelSeqList?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.SALES_CHANNEL?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  salesChannelSeqList: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">소속창고</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.CENTER_AUTH}
              value={searchObj?.centerCodeList?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.CENTER_WHOLE?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  centerCodeList: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">주문번호</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_ORDER
                      ? {
                          value: selectTypeObj?.SEARCH_ORDER,
                          label: MASTER_OBJ?.SEARCH_ORDER?.[selectTypeObj?.SEARCH_ORDER],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_ORDER}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_ORDER: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_ORDER}
                  value={searchObj?.[selectTypeObj?.SEARCH_ORDER] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_ORDER]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">송장번호</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_INVOICE
                      ? {
                          value: selectTypeObj?.SEARCH_INVOICE,
                          label: MASTER_OBJ?.SEARCH_INVOICE?.[selectTypeObj?.SEARCH_INVOICE],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_INVOICE}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_INVOICE: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_INVOICE}
                  value={searchObj?.[selectTypeObj?.SEARCH_INVOICE] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_INVOICE]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">송장유형</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.FEE_TYPE}
              value={searchObj?.feeTypes?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.FEE_TYPE?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  feeTypes: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">송장상태</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.INVOICE_STATUS}
              value={searchObj?.invoiceStatuses?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.INVOICE_STATUS?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  invoiceStatuses: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">택배상태</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.PARCEL_INVOICE_STATUS}
              value={searchObj?.statuses?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.PARCEL_INVOICE_STATUS?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  statuses: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">기간검색</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_DATE
                      ? {
                          value: selectTypeObj?.SEARCH_DATE,
                          label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_DATE}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_DATE: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <FlatpickrD
                  onReset={() => {
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                      [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                    });
                  }}
                  disabled={!selectTypeObj?.SEARCH_DATE}
                  value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                      [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                    });
                  }}
                  onClose={(option) => {
                    if (option?.length === 1)
                      setSearchObj({
                        ...searchObj,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                        [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                      });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col md={2}>
            <label className="col-form-label">차수</label>
            <InputD
              value={searchObj?.stage || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  stage: e.target.value,
                });
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">고객명(수령인)</label>
            <InputD
              value={searchObj?.receiverName || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  receiverName: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <label className="col-form-label">주소</label>
            <InputD
              value={searchObj?.receiverAddr || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  receiverAddr: e.target.value,
                });
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">연락처</label>
            <InputD
              value={searchObj?.receiverPhone || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  receiverPhone: e.target.value,
                });
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">배송기사</label>
            <div style={{ display: 'flex' }}>
              <Col md={6} style={{ display: 'inline-block' }}>
                <SelectD
                  options={MASTER_OPS?.DRIVER_AUTH}
                  value={
                    searchObj?.userId
                      ? {
                          value: searchObj?.userId,
                          label: MASTER_OBJ?.DRIVER_WHOLE?.[searchObj?.userId],
                        }
                      : null
                  }
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      userId: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={6} style={{ display: 'inline-block' }}>
                <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">유사검색여부</label>
            <div className="radio-select-area">
              <span
                className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: true,
                    };
                  });
                }}
              >
                유사검색
              </span>
              <span
                className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: false,
                    };
                  });
                }}
              >
                일치검색
              </span>
            </div>
          </Col>
          <Col>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                fetchList(searchObj);
              }}
            >
              조회
            </button>
          </Col>
          <Col />
        </Row>
      </Form>
      <div style={{ textAlign: 'right' }}>
        <div className="status-panel">
          <span>
            <input type="checkbox" id="all" checked={flagArr?.length === FLAG_OPT?.length} onChange={handleCheckbox} />
            <label htmlFor="all">전체</label> {numberThousandComma(fetchCount?.totalQuantity || 0)} 건
          </span>
          {FLAG_OPT?.map(({ value, label }) => {
            return (
              <span key={`flag_${value}`}>
                <input type="checkbox" id={value} checked={flagArr?.includes(value)} onChange={handleCheckbox} />
                <label htmlFor={value}>{label}</label> {numberThousandComma(fetchCount?.[value?.replaceAll('Flag', 'Quantity')] || 0)} 건
              </span>
            );
          })}
        </div>
      </div>
    </>
  );
};
