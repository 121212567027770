import { CargoManifestDTO, CargoManifestDetailDTO, CargoManifestTimeDTO } from '../../../../_interface/order';
import { cargoService } from 'pages/CARGO/_service/service';
import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';

// temp img
import { TitleText } from 'pages/ORDER/1order/parcelListTab/parcelList/component/parcelDetail';
import { InputD } from 'components/reactstrap/reactstrap';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IDetail {
  visible?: boolean;
  item?: CargoManifestDTO;
}
export const Detail = ({ detailObj, setDetailObj }) => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [modiFlag, setModiFlag] = useState(false);
  const [nowTab, setNowTab] = useState(0);
  const [cargoDetailInfo, setCargoDetailInfo] = useState<CargoManifestDetailDTO>();
  const [cargoDetailTimeInfo, setCargoDetailTimeInfo] = useState<CargoManifestTimeDTO>();

  const tabs = [
    {
      title: '상세정보',
      action: 'detail',
    },
    { title: '진행현황', action: 'time' },
    // { title: '배송비용' },
  ];

  useEffect(() => {
    fetchDetailData();
  }, [nowTab]);

  const fetchDetailData = async () => {
    if (tabs[nowTab]?.action && !cargoDetailInfo) {
      const data = await cargoService.getCargoDetail(detailObj?.item?.cargoManifestSeq, tabs[nowTab]?.action);
      if (tabs[nowTab]?.action === 'detail') setCargoDetailInfo(data);
      else setCargoDetailTimeInfo(data);
    }
  };

  const saveDetail = async () => {
    dispatch(setLoading('POST'));
    const rs = await cargoService.postOrderCargoAction('detail/save', cargoDetailInfo);
    if (rs?.status === 200) {
      alert('저장되었습니다!');
      setModiFlag(false);
    }
    dispatch(setLoading(null));
  };

  const handleDaumPost = () => {
    if (modiFlag) {
      new (window as any).daum.Postcode({
        oncomplete: function (data) {
          const { roadAddress, zonecode, jibunAddress } = data;

          setCargoDetailInfo((prev) => {
            return {
              ...prev,
              consigneeAddr1: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
              consigneeZipcode: zonecode,
            };
          });
        },
      }).open();
    }
  };
  return (
    <CommonModalNew
      style={{ width: 900 }}
      title="상세정보"
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      rightTitle={
        <>
          <Button
            className="btn btn-orange ssm"
            onClick={() => {
              if (modiFlag) saveDetail();
              setModiFlag(!modiFlag);
            }}
          >
            {modiFlag ? '저장하기' : '수정하기'}
          </Button>
        </>
      }
      children={
        <>
          <div className={modiFlag ? '' : 'disabled'}>
            <div>
              {tabs?.map((content, idx) => {
                return (
                  <Button key={`buttons_${idx}`} onClick={() => setNowTab(idx)} className={nowTab === idx ? 'btn btn-secondary ssm' : 'btn btn-etc ssm'}>
                    {content.title}
                  </Button>
                );
              })}
            </div>

            {nowTab === 0 && (
              <>
                <TitleText style={{ marginBottom: 5 }}>■ 주문정보</TitleText>
                <table className="border-table">
                  <thead>
                    <tr>
                      <th>주문번호</th>
                      <td>{cargoDetailInfo?.orderNumber}</td>
                      <th>MBL</th>
                      <td>{cargoDetailInfo?.masterNumber}</td>
                      <th>HBL</th>
                      <td>{cargoDetailInfo?.houseNumber}</td>
                    </tr>
                    <tr>
                      <th>진행상태</th>
                      <td>{selectlabel(cargoDetailInfo?.status, masterOptions?.CM_STATUS_2)}</td>
                      <th>운송방식</th>
                      <td>{selectlabel(cargoDetailInfo?.transportType, masterOptions?.CM_TRANSPORT_TYPE)}</td>
                      <th>
                        개인통관
                        <br />
                        고유번호
                      </th>
                      <td>{cargoDetailInfo?.personalCustomsClearanceCode}</td>
                    </tr>
                    <tr>
                      <th>받는분</th>
                      <td>
                        <InputD
                          value={cargoDetailInfo?.consigneeName || ''}
                          onChange={(e) => {
                            setCargoDetailInfo((prev) => {
                              return {
                                ...prev,
                                consigneeName: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                      <th>전화번호</th>
                      <td>
                        <InputD
                          value={cargoDetailInfo?.consigneePhone || ''}
                          onChange={(e) => {
                            setCargoDetailInfo((prev) => {
                              return {
                                ...prev,
                                consigneePhone: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                      <th>통관실패사유</th>
                      <td></td>
                    </tr>
                    <tr>
                      <th>우편번호</th>
                      <td onClick={handleDaumPost} style={{ cursor: 'pointer' }}>
                        <InputD
                          className="read-only-should-click"
                          value={cargoDetailInfo?.consigneeZipcode || ''}
                          onChange={(e) => {
                            setCargoDetailInfo({
                              ...cargoDetailInfo,
                              consigneeZipcode: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>주소</th>
                      <td colSpan={3}>
                        <InputD
                          disabled
                          value={cargoDetailInfo?.consigneeAddr1 || ''}
                          onChange={(e) => {
                            setCargoDetailInfo((prev) => {
                              return {
                                ...prev,
                                consigneeAddr1: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>상세주소</th>
                      <td colSpan={5}>
                        <InputD
                          value={cargoDetailInfo?.consigneeAddr2 || ''}
                          onChange={(e) => {
                            setCargoDetailInfo((prev) => {
                              return {
                                ...prev,
                                consigneeAddr2: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>출항지</th>
                      <td>{selectlabel(cargoDetailInfo?.fromPortCode, masterOptions?.PORT_CODE)}</td>
                      <th>입항지</th>
                      <td>{selectlabel(cargoDetailInfo?.toPortCode, masterOptions?.PORT_CODE)}</td>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <th>출항예정시간</th>
                      <td>{cargoDetailInfo?.estimatedTimeDeparture}</td>
                      <th>입항예정시간</th>
                      <td>{cargoDetailInfo?.estimatedTimeArrival}</td>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <th>고객상담</th>
                      <td colSpan={5}>{cargoDetailInfo?.memo}</td>
                    </tr>
                  </thead>
                </table>
                <TitleText style={{ marginBottom: 5 }}>■ 상품정보</TitleText>
                <table className="border-table">
                  <thead>
                    <tr>
                      <th>중국상품명</th>
                      <td>{cargoDetailInfo?.productName}</td>
                      <th>영문상품명</th>
                      <td>{cargoDetailInfo?.productNameEn}</td>
                      <th>총 수량</th>
                      <td>{cargoDetailInfo?.totalQuantity}</td>
                    </tr>
                    <tr>
                      <th>무게(kg)</th>
                      <td>{cargoDetailInfo?.totalWeight}</td>
                      <th>신고금액($)</th>
                      <td>{cargoDetailInfo?.declarePrice}</td>
                      <th>HS코드</th>
                      <td>{cargoDetailInfo?.hsCode}</td>
                    </tr>
                    <tr>
                      <th>전자상거래URL</th>
                      <td colSpan={5}>{cargoDetailInfo?.ecommerceWebsiteUrl}</td>
                    </tr>
                    <tr>
                      <th>이메일주소</th>
                      <td colSpan={5}>{cargoDetailInfo?.consigneeEmail}</td>
                    </tr>
                  </thead>
                </table>
              </>
            )}

            {nowTab === 1 && (
              <div style={{ padding: 10 }}>
                <div style={{ display: 'flex' }}>
                  <span className="b" style={{ fontSize: 12 }}>
                    {cargoDetailTimeInfo?.parcelName}
                  </span>
                  <span>{cargoDetailTimeInfo?.parcelInvoiceNumber}</span>
                </div>
                <div style={{ padding: 10 }}>
                  {cargoDetailTimeInfo?.tracks?.map((row) => {
                    return (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                          ● {row.location} - {row.eventName}{' '}
                        </span>
                        <span>{row?.eventDatetime}</span>
                      </div>
                    );
                  })}
                </div>

                <div style={{ display: 'flex', marginTop: 20 }}>
                  <span className="b" style={{ fontSize: 12 }}>
                    관세청 통관 BL(운송장)번호
                  </span>
                  <span>{cargoDetailTimeInfo?.parcelInvoiceNumber}</span>
                </div>
                <div style={{ padding: 10 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>● 통관목록심사완료 </span>
                    <span>{cargoDetailTimeInfo?.customsClearanceReviewCompleteDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>● 반출신고 </span>
                    <span>{cargoDetailTimeInfo?.carryOutDeclareDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>● 반입신고 </span>
                    <span>{cargoDetailTimeInfo?.carryInDeclareDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>● 통관목록접수 </span>
                    <span>{cargoDetailTimeInfo?.customsClearanceAcceptDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>● 하기신고 수리 </span>
                    <span>{cargoDetailTimeInfo?.unloadDeclareDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>● 입항적재화물목록 운항정보 정정 </span>
                    <span>{cargoDetailTimeInfo?.cargoManifestUpdateDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>● 입항보고 수리 </span>
                    <span>{cargoDetailTimeInfo?.shipEntryReportDeclareDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>● 입항보고 제출 </span>
                    <span>{cargoDetailTimeInfo?.shipEntryReportSubmitDatetime || '-'}</span>
                  </div>
                </div>

                <div style={{ display: 'flex', marginTop: 20 }}>
                  <span className="b" style={{ fontSize: 12 }}>
                    업무 진행 이력
                  </span>
                  <span></span>
                </div>
                <div style={{ padding: 10 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>● 폐기 </span>
                    <span>{cargoDetailTimeInfo?.returnDiscardDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 반송인계 </span>
                    <span>{cargoDetailTimeInfo?.returnHandoverDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 반송준비 </span>
                    <span>{cargoDetailTimeInfo?.returnReadyDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 반송예정 </span>
                    <span>{cargoDetailTimeInfo?.returnPlanDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 재반입 </span>
                    <span>{cargoDetailTimeInfo?.sendBackDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 재반입예정 </span>
                    <span>{cargoDetailTimeInfo?.sendBackPlanDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 배송실패폐기 </span>
                    <span>{cargoDetailTimeInfo?.failDiscardDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 배송실패반송 </span>
                    <span>{cargoDetailTimeInfo?.failReturnDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 배송완료 </span>
                    <span>{cargoDetailTimeInfo?.finishDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 택배인계 </span>
                    <span>{cargoDetailTimeInfo?.parcelTransferDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 통관완료 </span>
                    <span>{cargoDetailTimeInfo?.ccCompleteDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 통관시작 </span>
                    <span>{cargoDetailTimeInfo?.ccStartDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 한국입항 </span>
                    <span>{cargoDetailTimeInfo?.arriveDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 중국출항 </span>
                    <span>{cargoDetailTimeInfo?.departDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 선적 </span>
                    <span>{cargoDetailTimeInfo?.loadDatetime || '-'}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <span>● 인계 </span>
                    <span>{cargoDetailTimeInfo?.transferDatetime || '-'}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      }
    />
  );
};
