import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';

import { InventoryDetailInfoListDTO, InventoryLocationListDTO, InventoryLocationSearchDTO, LocationDetailDTO, LocationSelectListDTO } from 'interface/warehouse';
import { INVENTORY_LOCATION_LISTS_FOR_LOCATION, INVENTORY_LOCATION_LISTS_FOR_LOCATION_PAGING, LOCATION_CENTER_LOCATION_FETCH, WMS_ACTION } from 'envVar';
import { PagingListDTO, ResponseDTO } from 'interface/util';

export interface IBulyongSearchDTO {
  centerCode: string;
  locationCode: string;
  modelStockSeq: number;
}

class LocationService {
  public async getWarehouseLocation(centerCode: string, locationCode: string): Promise<LocationDetailDTO> {
    let url = `${LOCATION_CENTER_LOCATION_FETCH}/${centerCode}/${locationCode}`;
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getWarehouseLocationBulyong({ centerCode, locationCode, modelStockSeq }: IBulyongSearchDTO): Promise<InventoryDetailInfoListDTO[]> {
    let url = `${LOCATION_CENTER_LOCATION_FETCH}/${centerCode}/${locationCode}/${modelStockSeq}/defected/orderInfo`;
    const { data } = await httpClient.get(url);
    return data;
  }
  // locationList
  public async getInventoryLocationListPaging(searchDTO: InventoryLocationSearchDTO): Promise<PagingListDTO<LocationSelectListDTO>> {
    let url = INVENTORY_LOCATION_LISTS_FOR_LOCATION_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryLocationListExcel(searchDTO: InventoryLocationSearchDTO): Promise<InventoryLocationListDTO> {
    let url = INVENTORY_LOCATION_LISTS_FOR_LOCATION + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInventoryMovePagingAction(action: string, searchDTO: any): Promise<any> {
    let url = WMS_ACTION(action) + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getInventoryMoveAction(action: string, searchDTO: any, isPaging = false): Promise<any> {
    let url = WMS_ACTION(action) + (isPaging ? addingParamsToUrl(searchDTO) : addingParamsToUrlexceptPaging(searchDTO));
    const { data } = await httpClient.get(url);
    return data;
  }
  public async postInventoryMoveAction(action: string, searchDTO: any, dataDTO: any): Promise<ResponseDTO> {
    let url = WMS_ACTION(action);
    if (searchDTO) url += addingParamsToUrlexceptPaging(searchDTO);
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async getWMSAction(action: string, searchDTO: any, isPaging = false): Promise<any> {
    let url = WMS_ACTION(action) + (isPaging ? addingParamsToUrl(searchDTO) : addingParamsToUrlexceptPaging(searchDTO));
    const { data } = await httpClient.get(url);
    return data;
  }
  public async postWMSAction(action: string, searchDTO: any, dataDTO: any): Promise<ResponseDTO> {
    let url = WMS_ACTION(action);
    if (searchDTO) url += addingParamsToUrlexceptPaging(searchDTO);
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
}

export const locationService = new LocationService();
