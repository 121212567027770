import { format } from 'date-fns';

export const return2weeksArr = (todayDate) => {
  const arr = [];
  for (let index = 0; index < 14; index++) {
    var copiedDate = new Date(todayDate);
    var day = new Date(copiedDate.setDate(copiedDate.getDate() + index));
    arr.push(returnDateyyyymmdd(day));
  }
  return arr;
};

export const extractLocaleISODATE = (IncomingDate, hourDiffer) => {
  if (IncomingDate === undefined || IncomingDate === null || !IncomingDate) {
    return;
  }

  let newDate = new Date(IncomingDate);
  // 차이나는 시간 만큼 더해준다
  if (hourDiffer !== undefined || hourDiffer || hourDiffer !== null) {
    newDate.setHours(newDate.getHours() + hourDiffer);
  }
  const getISODATEtime = newDate.toISOString();

  let getISOArr, getISOdate, getISOEachtime, getISOtime;
  if (getISODATEtime) {
    getISOArr = getISODATEtime?.split('T');
  }
  if (getISOArr) {
    getISOdate = getISOArr[0];
    getISOEachtime = getISOArr[1]?.split(':');
  }
  if (getISOEachtime) {
    getISOtime = `${getISOEachtime[0]}:${getISOEachtime[1]}:${getISOEachtime[2].split('.')[0]}`;
  }

  let date = getISOdate;
  let time = getISOtime;

  return {
    date,
    time,
  };
};
export function returnDateyymmddKr(date) {
  var d = new Date(date),
    year = '' + d.getFullYear(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();
  return year + '년 ' + month + '월 ' + day + '일';
}

export function returnDatemmddKr(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();
  return month + '월 ' + day + '일';
}

export function returnDateyymm(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    year = d.getFullYear();

  return [year, month].join('-');
}

export function returnDateyyyymmdd(date) {
  if (date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  return null;
}

export function returnDateyyyymmddHhmmssNotab(date, divider) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hours = ('0' + d.getHours()).slice(-2),
    minutes = ('0' + d.getMinutes()).slice(-2),
    seconds = ('0' + d.getSeconds()).slice(-2);

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day, hours, minutes, seconds].join(divider);
}

export function returnDateyyyymmddHhmmss(date, divider) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hours = ('0' + d.getHours()).slice(-2),
    minutes = ('0' + d.getMinutes()).slice(-2),
    seconds = ('0' + d.getSeconds()).slice(-2);

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join(divider) + ' ' + [hours, minutes, seconds].join(divider);
}

export const weekArrKr = ['일', '월', '화', '수', '목', '금', '토'];
const weekdayObjKr = {
  Monday: '월',
  Tuesday: '화',
  Wednesday: '수',
  Thursday: '목',
  Friday: '금',
  Saturday: '토',
  Sunday: '일',
};

// NOTE: iOS Safari에서 new Date 메소드를 사용할 경우 "Invalid Date"가 발생하는 것을 방지하기 위해 커스텀하게 new Date의 파라미터를 가공하여 사용
const convertDateWithDateStr = (dateStr) => {
  if (!dateStr) return null;
  const dayAndTime = dateStr?.split(' ');
  const dayArr = dayAndTime?.[0]?.split('-').map((date, index) => (index === 1 ? Number(date) - 1 : Number(date)));
  let timeArr = dayAndTime?.[1]?.split(':');
  timeArr && timeArr.push('00');

  return timeArr ? new Date(dayArr[0], dayArr[1], dayArr[2], timeArr?.[0], timeArr?.[1], timeArr?.[2]) : new Date(dayArr[0], dayArr[1], dayArr[2]);
};

export function returnYoil(dateStr) {
  // NOTE: date.getDay() 메소드가 리턴 값이 없는 현상이 있어 포매팅 방식 변경
  // const dayOfWeek = weekArrKr[new Date(dateStr).getDay()];

  const dayOfWeekKr = weekdayObjKr[format(convertDateWithDateStr(dateStr), 'EEEE')];
  return dayOfWeekKr;
}

export const getDateFrom = (plusMinus) => {
  let preDate = new Date();
  preDate.setDate(preDate.getDate() + Number(plusMinus));
  let year = preDate.getFullYear();
  let month = ('0' + (preDate.getMonth() + 1)).slice(-2);
  let day = ('0' + preDate.getDate()).slice(-2);

  let dateString = year + '-' + month + '-' + day;
  return dateString;
};

export const getMonDotDay = (_date) => {
  // NOTE: date.getMonth(), date.getDate() 등이 NaN으로 return 되는 현상이 있어 포매팅 방식 변경
  // let date = new Date(_date);
  // let month = ('0' + (date.getMonth() + 1)).slice(-2);
  // let day = ('0' + date.getDate()).slice(-2);
  // let dateString = month + '.' + day;

  let dateString = format(convertDateWithDateStr(_date), 'MM.dd');
  return dateString;
};

export const getMonNDayKr = (_date) => {
  // NOTE: date.getMonth(), date.getDate() 등이 NaN으로 return 되는 현상이 있어 포매팅 방식 변경
  // let date = new Date(_date);
  // let month = ('0' + (date.getMonth() + 1)).slice(-2);
  // let day = ('0' + date.getDate()).slice(-2);
  let date = format(convertDateWithDateStr(_date), 'MM.dd').split('.');
  let dateString = date[0] + '월 ' + date[1] + '일';
  return dateString;
};

export const getNowTime = () => {
  let milliseconds = Date.now();

  return milliseconds;
};

export const getDateFromToday = (fromToday: number) => {
  // ex) 어제: -1
  const date = new Date();
  date.setDate(date.getDate() + fromToday);

  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return year + '-' + month + '-' + day;
};

export const getTimeStamp = () => {
  const timeStamp = new Date().getTime();
  return timeStamp;
};
export const getTodayLocalDateString = () => {
  const today = new Date();
  return today.toLocaleString();
};

export const getToday = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = ('0' + (today.getMonth() + 1)).slice(-2);
  const day = ('0' + today.getDate()).slice(-2);

  return year + '-' + month + '-' + day;
};

export function getTodayKr() {
  let today = new Date();
  let year = today.getFullYear(); // 년도
  let month = today.getMonth() + 1; // 월
  let date = today.getDate();
  return year + '년 ' + month + '월 ' + date + '일';
}

export function getTodayDots() {
  let today = new Date();
  let year = today.getFullYear(); // 년도
  let month = today.getMonth() + 1; // 월
  let date = today.getDate();
  return year + '. ' + month + '. ' + date;
}
