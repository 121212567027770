// react
import { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { OrderListDTO } from '../../_interface/order';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IPostObj {
  cancelType?: string;
  invoiceSeqs?: number[];
  invoiceStatus?: string;
}

export interface IOrderManObj {
  visible?: boolean;
  type?: string;
  items?: OrderListDTO[];
  actionFn: (data) => {};
}

export const OrderCancel = ({ orderManObj, setOrderManObj }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [postObj, setPostObj] = useState<IPostObj>();
  useEffect(() => {
    if (orderManObj?.type === 'CANCEL') {
      setPostObj({
        cancelType: null,
        invoiceSeqs: orderManObj?.items.map((item) => item.invoiceSeq),
      });
    } else {
      setPostObj({
        invoiceStatus: null,
        invoiceSeqs: orderManObj?.items.map((item) => item.invoiceSeq),
      });
    }
  }, []);
  return (
    <CommonModalNew
      title={orderManObj?.type === 'CANCEL' ? '주문취소' : '주문보류'}
      style={{ width: 350 }}
      visible={orderManObj?.visible}
      setVisible={() => {
        setOrderManObj(null);
      }}
      rightTitle={
        <>
          <button
            className="btn btn-orange ssm"
            onClick={() => {
              if (orderManObj?.type === 'CANCEL' && !postObj?.cancelType) {
                alert('취소 유형은 필수값입니다!');
              } else if (orderManObj?.type === 'PEND' && !postObj?.invoiceStatus) {
                alert('보류 유형은 필수값입니다!');
              } else {
                orderManObj?.actionFn(postObj);
              }
            }}
          >
            {orderManObj?.type === 'CANCEL' ? '주문취소' : '주문보류'}
          </button>
        </>
      }
      children={
        <>
          <div style={{ textAlign: 'center' }}>
            총 <span className="b">{orderManObj?.items?.length}</span>건에 대하여 {orderManObj?.type === 'CANCEL' ? '주문취소' : '주문보류'}를 진행합니다.
          </div>
          <div style={{ marginTop: 30 }}>
            <label className="col-form-label">{orderManObj?.type === 'CANCEL' ? '취소' : '보류'}유형을 선택하세요</label>
            <Row>
              <SelectD
                options={orderManObj?.type === 'CANCEL' ? masterOptions?.['CANCEL_TYPE'] : masterOptions?.['INVOICE_STATUS'].filter((ele) => ele.value.includes('PEND'))}
                onChange={(option) => {
                  if (orderManObj?.type === 'CANCEL') {
                    setPostObj({
                      ...postObj,
                      cancelType: (option as OptionItem).value,
                    });
                  } else {
                    setPostObj({
                      ...postObj,
                      invoiceStatus: (option as OptionItem).value,
                    });
                  }
                }}
              />
            </Row>
          </div>
        </>
      }
    />
  );
};
