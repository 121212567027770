import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging, eveningPostDTO } from 'common/util/httpclient';
import { INSTALL_ACTION, MASTER_ACTION, ORDER_ACTION, PARCEL_ACTION, USER_ACTION, WAREHOUSE_ACTION } from 'envVar2';
import { ResponseDTO } from 'interface/util';

export type IMethod = 'GET' | 'POST' | 'DELETE' | 'PUT';

class ServiceStore {
  public async orderAction(action: string, method: IMethod, searchDTO: any, dataDTO = null, isPaging = false, needEvening = false): Promise<ResponseDTO> {
    let url = `${ORDER_ACTION(action)}`;
    let postDTO = dataDTO;
    if (searchDTO) url += isPaging ? addingParamsToUrl(searchDTO) : addingParamsToUrlexceptPaging(searchDTO);
    if (needEvening) postDTO = eveningPostDTO(postDTO);
    switch (method) {
      case 'GET':
        return await httpClient.get(url);
      case 'PUT':
        return await httpClient.put(url, postDTO);
      case 'POST':
        return await httpClient.post(url, postDTO);
      case 'DELETE':
        return await httpClient.delete(url, { data: postDTO });
      default:
        return null;
    }
  }

  public async warehouseAction(action: string, method: IMethod, searchDTO: any, dataDTO = null, isPaging = false, needEvening = false): Promise<ResponseDTO> {
    let url = `${WAREHOUSE_ACTION(action)}`;
    let postDTO = dataDTO;
    if (searchDTO) url += isPaging ? addingParamsToUrl(searchDTO) : addingParamsToUrlexceptPaging(searchDTO);
    if (needEvening) postDTO = eveningPostDTO(postDTO);
    switch (method) {
      case 'GET':
        return await httpClient.get(url);
      case 'PUT':
        return await httpClient.put(url, postDTO);
      case 'POST':
        return await httpClient.post(url, postDTO);
      case 'DELETE':
        return await httpClient.delete(url, { data: postDTO });
      default:
        return null;
    }
  }

  public async masterAction(action: string, method: IMethod, searchDTO: any, dataDTO = null, isPaging = false, needEvening = false): Promise<ResponseDTO> {
    let url = `${MASTER_ACTION(action)}`;
    let postDTO = dataDTO;
    if (searchDTO) url += isPaging ? addingParamsToUrl(searchDTO) : addingParamsToUrlexceptPaging(searchDTO);
    if (needEvening) postDTO = eveningPostDTO(postDTO);
    switch (method) {
      case 'GET':
        return await httpClient.get(url);
      case 'PUT':
        return await httpClient.put(url, postDTO);
      case 'POST':
        return await httpClient.post(url, postDTO);
      case 'DELETE':
        return await httpClient.delete(url, { data: postDTO });
      default:
        return null;
    }
  }

  public async userAction(action: string, method: IMethod, searchDTO: any, dataDTO = null, isPaging = false, needEvening = false): Promise<ResponseDTO> {
    let url = `${USER_ACTION(action)}`;
    let postDTO = dataDTO;
    if (searchDTO) url += isPaging ? addingParamsToUrl(searchDTO) : addingParamsToUrlexceptPaging(searchDTO);
    if (needEvening) postDTO = eveningPostDTO(postDTO);
    switch (method) {
      case 'GET':
        return await httpClient.get(url);
      case 'PUT':
        return await httpClient.put(url, postDTO);
      case 'POST':
        return await httpClient.post(url, postDTO);
      case 'DELETE':
        return await httpClient.delete(url, { data: postDTO });
      default:
        return null;
    }
  }

  public async parcelAction(action: string, method: IMethod, searchDTO: any, dataDTO = null, isPaging = false, needEvening = false): Promise<ResponseDTO> {
    let url = `${PARCEL_ACTION(action)}`;
    let postDTO = dataDTO;
    if (searchDTO) url += isPaging ? addingParamsToUrl(searchDTO) : addingParamsToUrlexceptPaging(searchDTO);
    if (needEvening) postDTO = eveningPostDTO(postDTO);
    switch (method) {
      case 'GET':
        return await httpClient.get(url);
      case 'PUT':
        return await httpClient.put(url, postDTO);
      case 'POST':
        return await httpClient.post(url, postDTO);
      case 'DELETE':
        return await httpClient.delete(url, { data: postDTO });
      default:
        return null;
    }
  }

  public async installAction(action: string, method: IMethod, searchDTO: any, dataDTO = null, isPaging = false, needEvening = false): Promise<ResponseDTO> {
    let url = `${INSTALL_ACTION(action)}`;
    let postDTO = dataDTO;
    if (searchDTO) url += isPaging ? addingParamsToUrl(searchDTO) : addingParamsToUrlexceptPaging(searchDTO);
    if (needEvening) postDTO = eveningPostDTO(postDTO);
    switch (method) {
      case 'GET':
        return await httpClient.get(url);
      case 'PUT':
        return await httpClient.put(url, postDTO);
      case 'POST':
        return await httpClient.post(url, postDTO);
      case 'DELETE':
        return await httpClient.delete(url, { data: postDTO });
      default:
        return null;
    }
  }
}
export const serviceStore = new ServiceStore();
