import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
//
import styles from './sectorManageModal.module.scss';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { serviceStore } from 'services/services';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ZipcodeGroupSearchDTO } from 'pages/ETC/_interface/install';
import { DayCheckBox, defaultDayOfweek } from 'common/util/checkBox';
import { IZipcodeGroupListDTOExtends } from '..';
// component
import CommonModalNew from 'components/modal/commonModalNew';
import { getTimeStamp } from 'common/util/dateParsingFn';

export interface ISectorManageObj {
  visible: boolean;
  mode: string;
  searchObj: ZipcodeGroupSearchDTO;
  fetchFn: (searchObj: ZipcodeGroupSearchDTO) => {};
  items?: IZipcodeGroupListDTOExtends[];
  item?: IZipcodeGroupListDTOExtends;
}

const SectorManageModal = ({ sectorManageObj, setSectorManageObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [dayOfweek, setDayOfweek] = useState(defaultDayOfweek);
  const [deliveryDuration, setdeliveryDuration] = useState(0);
  const [centerCode, setCenterCode] = useState<string>();
  const [zipInfo, setZipInfo] = useState<{ zipcodeGroupSeq?: string; zipcodeGroupInfo?: string }>();
  useEffect(() => {
    if (sectorManageObj?.mode === 'change-deliveryDate') initChangeDeliveryDate();
    else if (sectorManageObj?.mode === 'set-zipInfo') initZipInfo();
    else initZipcodeRangeCenterCode();
  }, []);

  const initZipInfo = () => {
    setZipInfo({
      zipcodeGroupSeq: sectorManageObj?.item?.zipcodeGroupSeq,
      zipcodeGroupInfo: '',
    });
  };

  const initChangeDeliveryDate = () => {
    setDayOfweek({
      mon: sectorManageObj?.items?.[0]?.mondayFlag,
      tue: sectorManageObj?.items?.[0]?.tuesdayFlag,
      wed: sectorManageObj?.items?.[0]?.wednesdayFlag,
      thu: sectorManageObj?.items?.[0]?.thursdayFlag,
      fri: sectorManageObj?.items?.[0]?.fridayFlag,
      sat: sectorManageObj?.items?.[0]?.saturdayFlag,
      sun: sectorManageObj?.items?.[0]?.sundayFlag,
    });
    setdeliveryDuration(sectorManageObj?.items?.[0]?.deliveryDuration);
  };

  const initZipcodeRangeCenterCode = () => {
    setCenterCode(sectorManageObj?.items?.[0]?.centerCode);
  };

  const changeDeliveryDate = async (items) => {
    const zipcodeGroupSeqs = items?.map((data) => data?.zipcodeGroupSeq);
    const dataDTO = {
      zipcodeGroupSeqs,
      sundayFlag: dayOfweek?.sun,
      mondayFlag: dayOfweek?.mon,
      tuesdayFlag: dayOfweek?.tue,
      wednesdayFlag: dayOfweek?.wed,
      thursdayFlag: dayOfweek?.thu,
      fridayFlag: dayOfweek?.fri,
      saturdayFlag: dayOfweek?.sat,
    };

    const rs = await serviceStore.installAction(`zipcode/group/week`, 'PUT', null, dataDTO);
    if (rs?.status === 200) {
      alert('섹터 배송일 변경에 성공하였습니다.');
      sectorManageObj?.fetchFn?.(sectorManageObj?.searchObj);
      setSectorManageObj({
        ...sectorManageObj,
        visible: false,
        result: 'edit-deliveryDate',
      });
    }
  };

  const changeZipInfo = async (zipInfo) => {
    if (zipInfo?.zipcodeGroupInfo?.trim() === '') {
      alert('권역정보를 입력하세요');
    } else {
      const rs = await serviceStore.installAction(`zipcode/group/info`, 'PUT', null, [zipInfo]);
      if (rs?.status === 200) {
        alert('권역정보가 저장되었습니다.');
        sectorManageObj?.fetchFn?.(sectorManageObj?.searchObj);
        setSectorManageObj({
          ...sectorManageObj,
          visible: false,
          result: 'set-zipInfo',
        });
      }
    }
  };

  const changeZipcodeRangeCenterCode = async (zipcodeRangeSeqs, centerCode) => {
    const zipcodeRangeSeq = [
      ...new Set(
        zipcodeRangeSeqs?.map((ele) => {
          return ele.zipcodeRangeSeq;
        }),
      ),
    ];
    const dataDTO = {
      zipcodeRangeSeq,
      centerCode,
    };
    const rs = await serviceStore.installAction(`zipcode/group/range/center`, 'PUT', null, dataDTO);
    if (rs?.status === 200) {
      alert('해당권역의 하위창고 변경에 성공하였습니다.');
      sectorManageObj?.fetchFn?.(sectorManageObj?.searchObj);
      setSectorManageObj({
        ...sectorManageObj,
        visible: false,
        result: 'edit-zipcodeRangeCenterCode',
      });
    }
  };

  return (
    <>
      <CommonModalNew
        style={{ width: 700 }}
        title={
          sectorManageObj?.mode === 'change-zipcodeRangeCenterCode'
            ? `권역별 하위창고 수정`
            : sectorManageObj?.mode === 'set-zipInfo'
            ? `권역정보입력`
            : `${sectorManageObj?.items?.length > 1 ? '섹터' + sectorManageObj?.items[0]?.zipcodeSectorSeq : ''} 배송요일 변경`
        }
        visible={sectorManageObj?.visible}
        setVisible={() => {
          setSectorManageObj(null);
        }}
        rightTitle={
          <div>
            <button
              className="btn btn-orange ssm"
              onClick={() => {
                if (sectorManageObj?.mode === 'change-deliveryDate') {
                  changeDeliveryDate(sectorManageObj?.items);
                } else if (sectorManageObj?.mode === 'set-zipInfo') {
                  changeZipInfo(zipInfo);
                } else if (sectorManageObj?.mode === 'change-zipcodeRangeCenterCode') {
                  changeZipcodeRangeCenterCode(sectorManageObj?.items, centerCode);
                }
              }}
            >
              수정
            </button>
          </div>
        }
        children={
          <>
            {sectorManageObj?.mode === 'change-zipcodeRangeCenterCode' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginBottom: 100,
                  }}
                >
                  <div>
                    인프라 그룹 <span className="b">{MASTER_OBJ?.INFRA?.[sectorManageObj?.items?.[0]?.infraSeq]}</span>
                    <br />
                    권역명 <span className="b">{sectorManageObj?.items?.[0]?.zipcodeRangeName}</span>
                    <br />
                    그룹명 <span className="b">{sectorManageObj?.items?.[0]?.zipcodeGroupName}</span>
                    <br />
                    <br />
                    <br />
                    <span className={`${styles.hoverParent}`}>
                      {sectorManageObj?.items?.length > 1 && `외  ${sectorManageObj?.items?.length - 1}개`}
                      <span style={{ display: 'none' }}>
                        {sectorManageObj?.items?.map((data, idx) => {
                          return (
                            <div key={`zipcodeRangeCenterCode_${getTimeStamp() + idx}`}>
                              인프라 그룹 <span className="b">{data?.infraSeqKr}</span>
                              &nbsp; 권역명 <span className="b">{data?.zipcodeRangeName}</span>
                              &nbsp; 그룹명 <span className="b">{data?.zipcodeGroupName}</span>
                              <br />
                            </div>
                          );
                        })}
                      </span>
                    </span>
                    의 배송지 정보를 변경하시겠습니까?
                  </div>
                  <div style={{ marginLeft: 70, width: 200 }}>
                    <Row>
                      <Col>
                        <label className="col-form-label">하위창고</label>
                        <div className="col-lg-12" style={{ justifyContent: 'center' }}>
                          <Col>
                            <SelectD
                              value={
                                centerCode
                                  ? {
                                      value: centerCode,
                                      label: MASTER_OBJ?.CENTER_AUTH?.[centerCode],
                                    }
                                  : null
                              }
                              onChange={({ value }) => {
                                setCenterCode(value);
                              }}
                              options={MASTER_OPS?.CENTER_AUTH}
                            />
                          </Col>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            )}
            {sectorManageObj?.mode === 'set-zipInfo' && (
              <>
                <Row className="mb-3">
                  <Col>
                    <label className="col-form-label">그룹넘버</label>
                    <InputD disabled readOnly value={zipInfo?.zipcodeGroupSeq || ''} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="col-form-label">권역정보</label>
                    <textarea
                      value={zipInfo?.zipcodeGroupInfo || ''}
                      onChange={(e) => {
                        setZipInfo((prev) => {
                          return {
                            ...prev,
                            zipcodeGroupInfo: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {sectorManageObj?.mode === 'change-deliveryDate' && (
              <>
                <div>
                  인프라 그룹 <span className="b">{sectorManageObj?.items[0]?.infraSeqKr}</span>
                  <br />
                  권역명 <span className="b">{sectorManageObj?.items[0]?.zipcodeRangeName}</span>
                  <br />
                  그룹명 <span className="b">{sectorManageObj?.items[0]?.zipcodeGroupName}</span>
                  <br />
                  <br />
                  <br />
                  <span className={`${styles.hoverParent}`}>
                    {sectorManageObj?.items?.length > 1 && `외  ${sectorManageObj?.items?.length - 1}개`}
                    <span style={{ display: 'none' }}>
                      {sectorManageObj?.items?.map((data, i) => {
                        if (i > 0)
                          return (
                            <div key={`sectorMangemodalObj_${i}`}>
                              인프라 그룹 <span className="b">{data?.infraSeqKr}</span>&nbsp; 권역명 <span className="b">{data?.zipcodeRangeName}</span>
                              &nbsp; 그룹명 <span className="b">{data?.zipcodeGroupName}</span>
                              <br />
                            </div>
                          );
                      })}
                    </span>
                  </span>
                  의 배송지 정보를 변경하시겠습니까?
                </div>
                <div style={{ display: 'flex', paddingTop: 30 }}>
                  <Row className="mb-3">
                    <label className="col-form-label">배송요일</label>
                    <div className="col-lg-12" style={{ justifyContent: 'center' }}>
                      <Col>
                        <DayCheckBox dayOfweek={dayOfweek} setDayOfweek={setDayOfweek} />
                      </Col>
                    </div>
                  </Row>
                </div>
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default SectorManageModal;
