import { KeyboardEvent, useEffect, useRef, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { ParcelLinehaulSearchDTO } from '../../../../_interface/parcel';

export const SearchBox = (props: ISearchBoxProps<ParcelLinehaulSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchCount, fetchList } = props;
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE: null,
    SEARCH_SEQ: null,
  });
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SEQ, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ]);

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    fetchList(searchObj);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <label className="col-form-label">기간검색</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_DATE
                      ? {
                          value: selectTypeObj?.SEARCH_DATE,
                          label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_DATE}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_DATE: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <FlatpickrD
                  disabled={!selectTypeObj?.SEARCH_DATE}
                  onClose={(option) => {
                    if (option?.length === 1)
                      setSearchObj({
                        ...searchObj,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                        [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                      });
                  }}
                  value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                      [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">택배간선번호</label>
            <InputD
              type="number"
              value={searchObj?.parcelLinehaulSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  parcelLinehaulSeq: Number(e.target.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">진행상태</label>
            <SelectD
              options={MASTER_OPS?.PLINE_STATUS}
              value={
                searchObj?.status
                  ? {
                      value: searchObj?.status,
                      label: MASTER_OBJ?.PLINE_STATUS?.[searchObj?.status],
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  status: (option as OptionItem).value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">상차지</label>
            <SelectD
              options={MASTER_OPS?.CENTER_AUTH}
              value={
                searchObj?.originalCenterCode
                  ? {
                      value: searchObj?.originalCenterCode,
                      label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.originalCenterCode],
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  originalCenterCode: (option as OptionItem).value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">하차지</label>
            <SelectD
              options={MASTER_OPS?.CENTER_AUTH}
              value={
                searchObj?.destinationCenterCode
                  ? {
                      value: searchObj?.destinationCenterCode,
                      label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.destinationCenterCode],
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  destinationCenterCode: (option as OptionItem).value,
                });
              }}
            />
          </Col>
          <Col>
            <button className="btn btn-search" type="submit">
              조회
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
