export const basicTemplateContent = {
  TYPE_1: `배송 스케줄 안내 안녕하세요
  #(수령인이름)고객님! 
  주문하신 상품의 배송/설치 주문이 접수되어 
  #(약속일자)로 확정되어 안내드립니다. 
  
  ■ 제 품 명 : #(상품명) 
  ■ 배 송 지 : #(수령인주소) 
  
  받 는 분 : #(수령인이름)(#(수령인연락처1)) 
  담당기사 : #(주기사이름)기사님(#(주기사연락처)) 
  
  담당 기사님이 배송시간 안내 관련하여 배송일 전날 전화 연락을 드릴 예정입니다. (기사님 일정안내를 수신하지 않으시는 경우 배송지연될 수 있습니다. 부재시 기사님 번호로 꼭 연락주시기 바랍니다.)
  
  ■ 고객주문정보 확인 #(배송조회링크) 
  ■ 배송안내 
  
  1. 상품 설치장소 진입불가 및 설치공간 미확보시 설치가 어려울수 있으니 이점 참고하여 주시기 바랍니다. 
  2. 기상악화 시 제품의 특성상 배송이 지연될 수 있습니다.`,
  TYPE_2: `[날짜 미지정 안내]
  안녕하세요 #(수령인이름)고객님!
  방문 날짜 지정이 필요하여 안내 문자 드립니다.
  방문을 원하시는 날짜를 기사님께 전달해주시면 희망하시는 날짜에 방문하도록 하겠습니다.
  
  ■ 송장번호: #(송장번호)
  ■ 제 품 명 : #(상품명)
  ■ 배 송 지 : #(수령인주소)
  
  받 는 분 : #(수령인이름)(#(수령인연락처1))
  담당기사 : #(주기사이름)기사님(#(주기사연락처))
  
  ■ 고객주문정보 확인 #(배송조회링크)
  ■ 배송안내
  
  1. 상품 설치장소 진입불가 및 설치공간 미확보시 설치가 어려울수 있으니 이점 참고하여 주시기 바랍니다.
  2. 기상악화 시 제품의 특성상 배송이 지연될 수 있습니다.
  
  감사합니다.`,
  TYPE_3: `[배송 지정일 안내]
  안녕하세요 #(수령인이름)고객님!
  배송일이 배정되어 안내 문자 드립니다.
  담당 기사님이 배송시간 안내 관련하여 배송일 전날 전화 연락을 드릴 예정입니다. 
  (기사님 일정안내를 수신하지 않으시는 경우 배송지연될 수 있습니다. 부재시 기사님 번호로 꼭 연락주시기 바랍니다.)
  
  ■ 송장번호: #(송장번호)
  ■ 제 품 명 : #(상품명)
  ■ 배 송 지 : #(수령인주소)

  받 는 분 : #(수령인이름)(#(수령인연락처1))
  담당기사 : #(주기사이름)기사님(#(주기사연락처))
  
  ■ 고객주문정보 확인 #(배송조회링크)
  ■ 배송안내
  
  1. 상품 설치장소 진입불가 및 설치공간 미확보시 설치가 어려울수 있으니 이점 참고하여 주시기 바랍니다.
  2. 기상악화 시 제품의 특성상 배송이 지연될 수 있습니다.
  
  감사합니다.`,
  TYPE_4: `[배송 지정일 안내]
  안녕하세요 #(수령인이름)고객님!
  배송일이 배정되어 안내 문자 드립니다.
  담당 기사님이 배송시간 안내 관련하여 배송일 전날 전화 연락을 드릴 예정입니다. 
  (기사님 일정안내를 수신하지 않으시는 경우 배송지연될 수 있습니다. 부재시 기사님 번호로 꼭 연락주시기 바랍니다.)
  
  ■ 송장번호: #(송장번호)
  ■ 제 품 명 : #(상품명)
  ■ 배 송 지 : #(수령인주소)
  
  받 는 분 : #(수령인이름)(#(수령인연락처1))
  담당기사 : #(주기사이름)기사님(#(주기사연락처))
  
  ■ 고객주문정보 확인 #(배송조회링크)
  ■ 배송안내
  
  1. 상품 설치장소 진입불가 및 설치공간 미확보시 설치가 어려울수 있으니 이점 참고하여 주시기 바랍니다.
  2. 기상악화 시 제품의 특성상 배송이 지연될 수 있습니다.
  
  감사합니다.`,
  TYPE_5: `[배송 스케줄 안내]
  안녕하세요 #(주문인이름)고객님! 
  주문하신 상품의 설치 일정이
  #(약속일자)로 되어 안내드립니다 
  담당 기사님이 배송시간 안내 관련하여 배송일 전날 전화 연락을 드릴 예정입니다. 
  (기사님 일정안내를 수신하지 않으시는 경우 배송지연될 수 있습니다. 부재시 기사님 번호로 꼭 연락주시기 바랍니다.)
  
  ■ 송장번호: #(송장번호)
  ■ 제 품 명 : #(상품명)
  ■ 배 송 지 : #(수령인주소)
  
  받 는 분 : #(수령인이름)(#(수령인연락처1))
  담당기사 : #(주기사이름)기사님(#(주기사연락처))
  
  ■ 고객주문정보 확인 #(배송조회링크)
  ■ 배송안내
  
  1. 상품 설치장소 진입불가 및 설치공간 미확보시 설치가 어려울수 있으니 이점 참고하여 주시기 바랍니다.
  2. 기상악화 시 제품의 특성상 배송이 지연될 수 있습니다.
  
  감사합니다.`,
  TYPE_6: `[배송 스케줄 안내]
  안녕하세요 #(주문인이름)고객님! 
  주문하신 상품의 설치 일정이
  #(약속일자)로 되어 안내드립니다 
  담당 기사님이 배송시간 안내 관련하여 배송일 전날 전화 연락을 드릴 예정입니다. 
  (기사님 일정안내를 수신하지 않으시는 경우 배송지연될 수 있습니다. 부재시 기사님 번호로 꼭 연락주시기 바랍니다.)
  
  ■ 송장번호: #(송장번호)
  ■ 제 품 명 : #(상품명)
  ■ 배 송 지 : #(수령인주소)
  
  받 는 분 : #(수령인이름)(#(수령인연락처1))
  담당기사 : #(주기사이름)기사님(#(주기사연락처))
  
  ■ 고객주문정보 확인 #(배송조회링크)
  ■ 배송안내
  
  1. 상품 설치장소 진입불가 및 설치공간 미확보시 설치가 어려울수 있으니 이점 참고하여 주시기 바랍니다.
  2. 기상악화 시 제품의 특성상 배송이 지연될 수 있습니다.
  
  감사합니다.`,
  TYPE_7: `[배송예정 안내]
  안녕하세요 #(주문인이름)고객님! 
  주문하신 상품의 설치 일정이
  #(약속일시)로 되어 안내드립니다.
  담당 기사님이 곧 방문 예정이니 출발 전에 전화 연락을 드릴 예정입니다.
  (기사님 방문예정 연락을 수신하지 않으시는 경우 배송지연될 수 있습니다. 부재시 기사님 번호로 꼭 연락주시기 바랍니다.)
  
  ■ 송장번호: #(송장번호)
  ■ 제 품 명 : #(상품명)
  ■ 배 송 지 : #(수령인주소)
  ■ 배송예정시간 : #(약속시간대)
  
  받 는 분 : #(수령인이름)(#(수령인연락처1))
  담당기사 : #(주기사이름)기사님(#(주기사연락처))
  
  ■ 고객주문정보 확인 #(배송조회링크)
  ■ 배송안내
  
  1. 상품 설치장소 진입불가 및 설치공간 미확보시 설치가 어려울수 있으니 이점 참고하여 주시기 바랍니다.
  2. 기상악화 시 제품의 특성상 배송이 지연될 수 있습니다.
  
  감사합니다.`,
  TYPE_8: `[배송 / 설치 완료 안내]
  안녕하세요 #(수령인이름)고객님! 
  주문하신 상품의 배송/설치가 완료되었습니다.
  
  ■ 송장번호: #(송장번호)
  ■ 제 품 명 : #(상품명)
  ■ 배 송 지 : #(수령인주소)
  
  받 는 분 : #(수령인이름)(#(수령인연락처1))
  담당기사 : #(주기사이름)기사님(#(주기사연락처))
  
  ■ 고객주문정보 확인 #(배송조회링크)
  
  감사합니다.`,
};
