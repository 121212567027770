import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid, PARCEL_GRID_PAGE_ROW_COUNT } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
// service
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch } from 'react-redux';
import { CargoManifestAccidentSearchDTO, CargoManifestAccidentDTO } from '../../../_interface/order';
import useGridButton from 'hooks/grid/useGridButton';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
// component
import { PagingListDTO } from 'interface/util';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';

import { CARGO_MANIFEST_ACCIDENT_LACK_UPLOAD_FORM } from 'envVar2';
import { serviceStore } from 'services/services';
import { useLocation } from 'react-router-dom';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const [TYPE, setTYPE] = useState(tabId?.includes('OVER') ? 'excess' : 'lack');

  const labellingKr = (data: PagingListDTO<CargoManifestAccidentDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: CargoManifestAccidentSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.orderAction(`cargoManifest/accident/${TYPE}/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<CargoManifestAccidentDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const downloadExcel = async (type: 'main') => {
    const prompt = window.confirm(`엑셀다운로드를 시작합니다. \n※ 많은 양의 데이터는 30초 이상의 시간이 소요될 수 있습니다.`);
    if (prompt) {
      dispatch(setLoading('GET'));
      const { list } = labellingKr({ list: (await serviceStore.orderAction(`cargoManifest/accident/${TYPE}/excel`, 'GET', searchObj, null))?.data as CargoManifestAccidentDTO[] }, true);
      if (type === 'main') {
        excelGridRef.current.setGridData(list);
        excelGridRef.current.exportAsXlsx({ fileName: `사고접수_${TYPE === 'lack' ? '미입고' : '과입고'}` });
      }
      dispatch(setLoading(null));
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id?.includes('EXCELDOWN')) {
      downloadExcel('main');
    } else if (id === 'CARGO_MANIFEST_ACCIDENT_LACK_UPLOAD_FORM') {
      window.open(CARGO_MANIFEST_ACCIDENT_LACK_UPLOAD_FORM);
    } else if (id === 'CARGO_MANIFEST_ACCIDENT_LACK_UPLOAD') {
      uploaderClick(id);
    }
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(TYPE);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
    setTYPE(tabId?.includes('OVER') ? 'excess' : 'lack');
  }, [searchObj, tabId]);

  const columns: IGrid.Column[] = [
    {
      headerText: '등록일시',
      dataField: 'registerDate',
    },
    {
      headerText: 'HBL',
      dataField: 'houseNumber',
    },
  ];

  const EXCELHEADER = {
    CARGO_MANIFEST_ACCIDENT_LACK_UPLOAD: ['houseNumber'],
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER });

  useEffect(() => {
    if (parsedData) {
      if (parsedData?.data?.length > 0) {
        //
        uploadData(parsedData?.data, parsedData?.id);
      } else {
        alert('읽혀진 값이 없습니다');
      }
    }
  }, [parsedData]);

  const uploadData = async (excelrows, id) => {
    let rs;
    if (id === 'CARGO_MANIFEST_ACCIDENT_LACK_UPLOAD') {
      rs = await serviceStore.orderAction(`cargoManifest/accident/lack/save`, 'POST', null, excelrows);
    }
    if (rs?.status === 200) {
      alert(`업로드에 성공하였습니다!`);
      wrappedFetchList(searchObj);
    }

    dispatch(setLoading(null));
  };

  useEffect(() => {
    gridRef?.current?.clearGridData();
    excelGridRef?.current?.clearGridData();
  }, [tabId]);

  return (
    <div className="page-content">
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} TARGET={tabId} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            pageRowCount: PARCEL_GRID_PAGE_ROW_COUNT,
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
