import { useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { statisticsService } from '../../_services/service';
import { InvoiceMonthDTO, InvoiceMonthSearchDTO } from '../../_interface/order';
import { BottomSheet, IBottomSheetObj } from 'components/template/bottomSheet';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { columns } from './data/data';
import { PagingListDTO } from 'interface/util';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const validationForFetchList = (searchObj: InvoiceMonthSearchDTO) => {
    if (!searchObj?.year && !searchObj?.month) {
      alert('기준일은 필수 검색자입니다!');
      return false;
    }
    return true;
  };

  const fetchTotal = async (searchObj: InvoiceMonthSearchDTO) => {
    const total = await statisticsService.getInvoiceMonthTotal(searchObj);

    return total;
  };

  const labellingKr = (data: PagingListDTO<InvoiceMonthDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        totalQuantityForWorkdayKr: `${row.totalQuantityForWorkday}(${row.totalPercentageForWorkday}%)`,
        totalQuantityForWeekendKr: `${row.totalQuantityForWeekend}(${row.totalPercentageForWeekend}%)`,
        leftoverCapacityQuantityKr: `${row.leftoverCapacityQuantity}(${row.leftoverCapacityPercentage}%)`,
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InvoiceMonthSearchDTO) => {
    dispatch(setLoading('GET'));
    setBottomSheetObj(null);
    const dataKr = labellingKr(await statisticsService.getInvoiceMonthPaging(searchObj));
    const days = dataKr?.list?.[0];
    const holidays = Object.keys(days).filter((ele) => ele.includes('HolidayFlag') && days[ele]);
    const workdays = Object.keys(days).filter((ele) => ele.includes('HolidayFlag') && !days[ele]);

    drawHolidayColumn(holidays, workdays);
    dispatch(setLoading(null));
    return dataKr;
  };

  const drawHolidayColumn = (holidays: string[], workdays: string[]) => {
    holidays.forEach((holiday) => {
      gridRef?.current?.setColumnPropByDataField(holiday.split('HolidayFlag')[0] + 'Quantity', { headerStyle: 'red-column' });
    });
    workdays.forEach((days) => {
      gridRef?.current?.setColumnPropByDataField(days.split('HolidayFlag')[0] + 'Quantity', { headerStyle: 'black-column' });
    });
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    },
    gridRef,
    fetchTotal,
    fetchPaging,
    validationForFetchList,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    if (validationForFetchList(searchObj)) {
      dispatch(setLoading('GET'));
      const { list } = labellingKr({ list: await statisticsService.getInvoiceMonthExcelMain(searchObj) }, true);
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '주별KPI' });
      dispatch(setLoading(null));
    }
  };

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheet bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area only-right">
          <div
            className="green"
            onClick={() => {
              downloadExcel('main');
            }}
          >
            엑셀 다운로드
          </div>
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            headerHeight: 35,
            fixedColumnCount: 9,
            showFooter: true,
            footerPosition: 'top',
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
