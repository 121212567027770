import { defaultGridProps } from 'common/grid/gridBox';
import { delay } from 'common/util/counting';
import CommonModalNew from 'components/modal/commonModalNew';
import AUIGrid from 'modules/Grid';
import { useEffect, useRef } from 'react';

export interface ISerialObj {
  visible?: boolean;
  data?: any;
  title?: string;
  bottomtitle?: string;
}

interface ISerialPoupObj {
  serialsPopupObj: ISerialObj;
  setSerialsPopupObj: React.Dispatch<React.SetStateAction<ISerialObj>>;
}

export const SerialsPopup = ({ serialsPopupObj, setSerialsPopupObj }: ISerialPoupObj) => {
  const gridRef = useRef<AUIGrid>();

  useEffect(() => {
    void delay(300).then(() => {
      if (gridRef?.current) {
        if (serialsPopupObj?.data) {
          const data = JSON.parse(serialsPopupObj.data);
          data?.sort();
          gridRef.current?.setGridData(
            data?.map((ele) => {
              return { serialNumber: ele };
            }),
          );
        }
      }
    });
  }, [gridRef?.current]);

  return (
    <CommonModalNew
      visible={serialsPopupObj.visible}
      setVisible={() =>
        setSerialsPopupObj({
          ...serialsPopupObj,
          visible: false,
        })
      }
      title={serialsPopupObj.title}
      subTitle={serialsPopupObj.bottomtitle}
      style={{ width: 400 }}
      children={
        <>
          <AUIGrid
            ref={gridRef}
            name={`serialGansunPop`}
            columnLayout={[
              {
                headerText: '시리얼번호',
                dataField: 'serialNumber',
              },
            ]}
            gridProps={{
              //
              ...defaultGridProps,
              height: 500,
              editable: true,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
            }}
          />
        </>
      }
    />
  );
};
