import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl } from 'common/util/httpclient';
import {
  MESSAGE_SCHEDULE_SEND,
  //
  USER_ANNOUNCEMENT_DELETE,
  USER_ANNOUNCEMENT_DETAIL,
  USER_ANNOUNCEMENT_PAGING,
  USER_ANNOUNCEMENT_SAVE,
  USER_ANNOUNCEMENT_TEMPLIST,
  USER_ANNOUNCEMENT_VIEW,
  USER_ANNOUNCEMENT_VIEW_INCREASE,
  USER_SHOULD_READ_5ANNOUNCEMENT,
} from 'envVar';
import { PagingListDTO } from 'interface/util';

import { AnnouncementDTO, AnnouncementListDTO, AnnouncementListSearchDTO, AnnouncementSaveDTO, AnnouncementSeqListDTO, ResponseDTO } from 'interface/user';

class DeliveryService {
  // announcement
  public async getAnnouncementPaging(searchDTO: AnnouncementListSearchDTO, category: 'SYSTEM'): Promise<PagingListDTO<AnnouncementListDTO>> {
    let url = USER_ANNOUNCEMENT_PAGING(category) + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    console.log(url, data);
    return data;
  }

  public async getAnnouncementTemplist(category: 'SYSTEM'): Promise<AnnouncementListDTO[]> {
    let url = USER_ANNOUNCEMENT_TEMPLIST(category);
    const { data } = await httpClient.get(url);
    console.log(url, data);
    return data;
  }

  public async getAnnouncementDetail(announcementSeq: string): Promise<AnnouncementDTO> {
    let url = USER_ANNOUNCEMENT_DETAIL(announcementSeq);
    const { data } = await httpClient.get(url);
    console.log(url, data);
    return data;
  }

  public async getView(seq: string): Promise<ResponseDTO> {
    let url = USER_ANNOUNCEMENT_VIEW(seq);
    const rs = await httpClient.get(url);
    return rs;
  }

  public async increaseView(seq: string): Promise<ResponseDTO> {
    let url = USER_ANNOUNCEMENT_VIEW_INCREASE(seq);
    const rs = await httpClient.post(url);
    return rs;
  }
  public async saveBoard(data: AnnouncementSaveDTO, category: 'SYSTEM'): Promise<ResponseDTO> {
    let url = USER_ANNOUNCEMENT_SAVE(category);
    const rs = await httpClient.post(url, data);
    return rs;
  }

  public async deleteBoard(data: AnnouncementSeqListDTO): Promise<ResponseDTO> {
    let url = USER_ANNOUNCEMENT_DELETE;
    const rs = await httpClient.post(url, data);
    return rs;
  }

  public async getAnnouncement5(): Promise<AnnouncementListDTO[]> {
    let url = USER_SHOULD_READ_5ANNOUNCEMENT;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async sendMessageSchedule(dataDTO: { invoiceSeqs: number[] }): Promise<ResponseDTO> {
    let url = MESSAGE_SCHEDULE_SEND;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
}

export const deliveryService = new DeliveryService();
