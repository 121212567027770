import { ReactNode, useMemo, useRef, useState } from 'react';

// utils
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';

// hooks
import useGridButton from 'hooks/grid/useGridButton';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { deliveryService } from '../../_services/service';
import { InvoiceDTOForOrderSchedule, InvoiceSearchDTOForOrder } from 'interface/order';
import { useDidMountEffect } from 'hooks/basichooks';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import { orderService } from 'pages/ORDER/1order/_services/service';
import { IInventoryLocationObj, InventoryLocation } from 'pages/ORDER/1order/orderListTab/common/component/inventoryLocation';
import { DivideOrder, IDividerObj } from 'pages/ORDER/1order/orderListTab/common/component/divideOrder';
import { DetailPopup, IDetailObj } from 'pages/ETC/1driver/driverListTab/driverList/component/detailPopup';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const [inventoryLocationObj, setInventoryLocationObj] = useState<IInventoryLocationObj>();
  const fetchInventoryLocation = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const data = await orderService.getInvoiceInventoryLocation(e.item.invoiceSeq);
    if (data?.length > 0) {
      setInventoryLocationObj({
        visible: true,
        data,
      });
    }
    dispatch(setLoading(null));
  };

  const [driverDetailObj, setDriverDetailObj] = useState<IDetailObj>();
  const popupDriverDetail = async (e) => {
    if (e.text) {
      setDriverDetailObj((prev) => {
        return {
          ...prev,
          visible: true,
          item: { userId: e.item?.[e.dataField?.split('Kr')[0]] },
        };
      });
    }
  };
  const labellingKr = (data: PagingListDTO<InvoiceDTOForOrderSchedule>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        modelGroup: MASTER_OBJ?.MODEL_GROUP?.[row.modelGroup],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row.feeType],
        inventoryLocationKr: MASTER_OBJ?.INVENTORY_LOCATION?.[row.inventoryLocation],
        invoiceStatusKr: MASTER_OBJ?.INVOICE_STATUS?.[row.invoiceStatus],
        assistUserIdKr: MASTER_OBJ?.DRIVER_WHOLE?.[row.assistUserId],
        memberCountKr: MASTER_OBJ?.TEAM_TYPE?.[row.memberCount],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InvoiceSearchDTOForOrder) => {
    dispatch(setLoading('GET'));
    const data = labellingKr(await deliveryService.getOrderSchedulePagingAction('all/paging', searchObj));
    dispatch(setLoading(null));
    return data;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: await deliveryService.getOrderScheduleAction('all', searchObj) }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '주문별 배송일정관리' });
    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'DELIVERYSCHEDULE_ORDER_EXCELDOWN') {
      downloadExcel('main');
    } else if (id === 'DELIVERYSCHEDULE_ORDER_DIVIDE_UPLOAD_FORM') {
      window.open('https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EB%B0%B0%EC%86%A1/%EC%97%91%EC%85%80+%EC%A3%BC%EB%AC%B8%EB%B6%84%EB%B0%B0+%EC%96%91%EC%8B%9D.xlsx');
    } else if (id === 'DELIVERYSCHEDULE_ORDER_DIVIDE_UPLOAD') {
      uploaderClick(id);
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'DELIVERYSCHEDULE_ORDER_DIVIDE') {
        divideOrderAction(items);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const uploadData = async ({ data, header, id }) => {
    if (id === 'DELIVERYSCHEDULE_ORDER_DIVIDE_UPLOAD') {
      const rs = await deliveryService.postOrderScheduleAction('change/invoice/excel', null, data);
      if (rs?.status === 200) {
        alert('주문분배 엑셀업로드에 성공하였습니다!');
        wrappedFetchList(searchObj);
      }
    }
    dispatch(setLoading(null));
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const KRTOVALUE = {
    centerCode: 'CENTER_WHOLE',
  };

  const EXCELHEADER = {
    DELIVERYSCHEDULE_ORDER_DIVIDE_UPLOAD: ['invoiceSeq', 'deliveryDateFlag', 'deliveryDate', 'centerCode', 'userId', 'assistUserId', 'internUserId', 'confirmFlag'],
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE });
  useDidMountEffect(() => {
    if (parsedData?.data?.length > 0) {
      uploadData({ data: parsedData?.data, id: parsedData?.id, header: parsedData?.header });
    }
  }, [parsedData]);

  const [divideOrderObj, setDivideOrderObj] = useState<IDividerObj>();

  const divideOrderAction = (items = []) => {
    if (items.some((item) => item.invoiceStatusKr === '배송중' || item.invoiceStatusKr === '배송완료')) {
      alert('송장상태가 배송중, 배송완료의 경우는 주문분배를 할 수 없습니다!');
    } else {
      setDivideOrderObj({
        visible: true,
        searchObj,
        fetchFn: (data) => wrappedFetchList(data),
        items: items?.map((item) => item.invoiceSeq),
      });
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '고객주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '지정일(예정일)',
      dataField: 'deliveryDate',
    },
    {
      headerText: '배송확정일',
      dataField: 'deliveryConfirmDate',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '송장상태',
      dataField: 'invoiceStatusKr',
      styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
        //
        const invStat = item.invoiceStatus;
        if (invStat === 'INIT') {
          return 'status-badge-yellow';
        } else if (
          invStat === 'NOT_IN_INVENTORY' ||
          invStat === 'DRIVER_CAPA_EXCEED' ||
          invStat === 'EXCLUDE_ZIPCODE' ||
          invStat === 'ZIPCODE_ERROR' ||
          invStat === 'ADDRESS_ERROR' ||
          invStat === 'NOT_EXIST_CENTER' ||
          invStat === 'NOT_EXIST_DRIVER' ||
          invStat === 'DRIVER_SETTING_ERROR' ||
          invStat === 'CENTER_CUTOFF_CLOSE'
        ) {
          return 'status-badge-orange';
        } else if (
          //
          invStat === 'ASSIGN' ||
          invStat === 'ASSIGN_VIRT' ||
          invStat === 'REMOVING' ||
          invStat === 'CONFIRM' ||
          invStat === 'LOAN' ||
          invStat === 'DELIVERY'
        ) {
          return 'status-badge-green';
        } else if (invStat.startsWith('FINISH_')) {
          return 'status-badge-blue';
        } else if (invStat.startsWith('CANCEL_')) {
          return 'status-badge-red';
        } else if (invStat.startsWith('PEND')) {
          return 'status-badge-gray';
        } else if (invStat === 'ASSIGNING') {
          return 'status-badge-purple';
        }
        return null;
      },
    },
    {
      headerText: '재고위치',
      dataField: 'inventoryLocationKr',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: fetchInventoryLocation,
      },
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '실수령자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'receiverName',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr1',
        },
        {
          headerText: '상세주소',
          dataField: 'receiverAddr2',
        },
        {
          headerText: '연락처',
          dataField: 'receiverPhone',
        },
      ],
    },
    {
      headerText: '고객 요청사항',
      dataField: 'shipmentNote',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '배송팀 유형',
      dataField: 'memberCountKr',
    },
    {
      headerText: '주기사',
      dataField: 'userIdKr',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: popupDriverDetail,
      },
    },
    {
      headerText: '부기사',
      dataField: 'assistUserIdKr',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: popupDriverDetail,
      },
    },
    {
      headerText: '기사 요청사항',
      dataField: 'driverMemo',
    },
  ];

  return (
    <div className="page-content">
      {inventoryLocationObj?.visible && <InventoryLocation inventoryLocationObj={inventoryLocationObj} setInventoryLocationObj={setInventoryLocationObj} />}
      {divideOrderObj?.visible && <DivideOrder divideOrderObj={divideOrderObj} setDivideOrderObj={setDivideOrderObj} />}
      {driverDetailObj?.visible && <DetailPopup detailObj={driverDetailObj} setDetailObj={setDriverDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            fixedColumnCount: 1,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
