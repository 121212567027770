import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { CATEGORY, feeService } from '../../_service/service';
import { SearchBox } from './component/searchBox';
import { useLocation } from 'react-router-dom';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';

import { getKey } from './asset/asset';
import { useDidMountEffect } from 'hooks/basichooks';
import useExcelHooks from 'hooks/excel/useExcelHooks';
import {
  OrderFeeCancelDTO,
  OrderFeeCancelSearchDTO,
  OrderFeeDateDTO,
  OrderFeeDateSearchDTO,
  OrderFeeDefaultDTO,
  OrderFeeDefaultSearchDTO,
  OrderFeeGroupDTO,
  OrderFeeGroupSearchDTO,
  OrderFeeModelDTO,
  OrderFeeModelSearchDTO,
  OrderFeeOptionDTO,
  OrderFeeOptionSearchDTO,
  OrderFeeProductDTO,
  OrderFeeProductSearchDTO,
  OrderFeeRegionDTO,
  OrderFeeRegionSearchDTO,
} from '../../_interface/order';
import { AddType, IAddTypeObj } from './component/addType';

const Index = ({ TARGET }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { MASTER_OBJ, MASTER_OPS, nowMenu } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const [addTypeObj, setAddTypeObj] = useState<IAddTypeObj>();

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));
  const pathOrder = currentDesignType === 1 ? 2 : 1;

  const showDetail = async (e: IGrid.ButtonClickEvent) => {
    if (TARGET === 'GROUP') {
      const rs = await feeService.getOmsFeeAction(TARGET, location.pathname.split('/')[pathOrder].toLocaleUpperCase() as CATEGORY, `detail?orderFeeGroupSeq=${e.item.orderFeeGroupSeq}`);
      setAddTypeObj({
        visible: true,
        target: TARGET,
        searchObj,
        data: rs.data as OrderFeeGroupDTO,
        fetchFn: wrappedFetchList,
      });
    } else if (TARGET === 'REGION') {
      const rs = await feeService.getOmsFeeAction(TARGET, location.pathname.split('/')[pathOrder].toLocaleUpperCase() as CATEGORY, `detail?orderFeeRegionSeq=${e.item.orderFeeRegionSeq}`);
      setAddTypeObj({
        visible: true,
        target: TARGET,
        searchObj,
        data: rs.data as OrderFeeRegionDTO,
        fetchFn: wrappedFetchList,
      });
    }
  };

  const COLUMNS = {
    DEFAULT_COLUMNS: {
      HEADQUARTERS: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      SUBCONTRACT: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '인프라',
          dataField: 'infraSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      CENTER: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      DRIVER: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '설치팀등급',
          dataField: 'teamGradeKr',
        },
        {
          headerText: '소속',
          dataField: 'partyKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
    },
    PRODUCT_COLUMNS: {
      HEADQUARTERS: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '상품번호',
          dataField: 'productSeq',
        },
        {
          headerText: '상품명',
          dataField: 'productName',
        },
        {
          headerText: '상품구성수량',
          dataField: 'quantity',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      SUBCONTRACT: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '인프라',
          dataField: 'infraSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '상품번호',
          dataField: 'productSeq',
        },
        {
          headerText: '상품명',
          dataField: 'productName',
        },
        {
          headerText: '상품구성수량',
          dataField: 'quantity',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      CENTER: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '상품번호',
          dataField: 'productSeq',
        },
        {
          headerText: '상품명',
          dataField: 'productName',
        },
        {
          headerText: '상품구성수량',
          dataField: 'quantity',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      DRIVER: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '설치팀등급',
          dataField: 'teamGradeKr',
        },
        {
          headerText: '소속',
          dataField: 'partyKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '상품번호',
          dataField: 'productSeq',
        },
        {
          headerText: '상품명',
          dataField: 'productName',
        },
        {
          headerText: '상품구성수량',
          dataField: 'quantity',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
    },

    GROUP_COLUMNS: {
      HEADQUARTERS: [
        {
          headerText: '상세보기',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            labelText: '상세보기',
            onClick: showDetail,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '그룹명',
          dataField: 'groupName',
        },
        {
          headerText: '그룹수량',
          dataField: 'quantity',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      SUBCONTRACT: [
        {
          headerText: '상세보기',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            labelText: '상세보기',
            onClick: showDetail,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '인프라',
          dataField: 'infraSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '그룹명',
          dataField: 'groupName',
        },
        {
          headerText: '그룹수량',
          dataField: 'quantity',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      CENTER: [
        {
          headerText: '상세보기',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            labelText: '상세보기',
            onClick: showDetail,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '그룹명',
          dataField: 'groupName',
        },
        {
          headerText: '그룹수량',
          dataField: 'quantity',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      DRIVER: [
        {
          headerText: '상세보기',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            labelText: '상세보기',
            onClick: showDetail,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '설치팀등급',
          dataField: 'teamGradeKr',
        },
        {
          headerText: '소속',
          dataField: 'partyKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '그룹명',
          dataField: 'groupName',
        },
        {
          headerText: '그룹수량',
          dataField: 'quantity',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
    },

    REGION_COLUMNS: {
      HEADQUARTERS: [
        {
          headerText: '상세보기',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            labelText: '상세보기',
            onClick: showDetail,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '도서산간명',
          dataField: 'regionName',
        },
        {
          headerText: '완료금액',
          dataField: 'finishFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
        {
          headerText: '출고후취소금액',
          dataField: 'cancelAfterFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
        {
          headerText: '고객집도착후취소금액',
          dataField: 'cancelVisitFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
        {
          headerText: '개봉후취소금액',
          dataField: 'cancelOpenFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
      ],
      SUBCONTRACT: [
        {
          headerText: '상세보기',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            labelText: '상세보기',
            onClick: showDetail,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '인프라',
          dataField: 'infraSeqKr',
        },
        {
          headerText: '도서산간명',
          dataField: 'regionName',
        },
        {
          headerText: '완료금액',
          dataField: 'finishFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
        {
          headerText: '출고후취소금액',
          dataField: 'cancelAfterFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
        {
          headerText: '고객집도착후취소금액',
          dataField: 'cancelVisitFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
        {
          headerText: '개봉후취소금액',
          dataField: 'cancelOpenFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
      ],
      CENTER: [
        {
          headerText: '상세보기',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            labelText: '상세보기',
            onClick: showDetail,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '도서산간명',
          dataField: 'regionName',
        },
        {
          headerText: '완료금액',
          dataField: 'finishFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
        {
          headerText: '출고후취소금액',
          dataField: 'cancelAfterFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
        {
          headerText: '고객집도착후취소금액',
          dataField: 'cancelVisitFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
        {
          headerText: '개봉후취소금액',
          dataField: 'cancelOpenFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
      ],
      DRIVER: [
        {
          headerText: '상세보기',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            labelText: '상세보기',
            onClick: showDetail,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '설치팀등급',
          dataField: 'teamGradeKr',
        },
        {
          headerText: '소속',
          dataField: 'partyKr',
        },
        {
          headerText: '도서산간명',
          dataField: 'regionName',
        },
        {
          headerText: '완료금액',
          dataField: 'finishFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
        {
          headerText: '출고후취소금액',
          dataField: 'cancelAfterFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
        {
          headerText: '고객집도착후취소금액',
          dataField: 'cancelVisitFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
        {
          headerText: '개봉후취소금액',
          dataField: 'cancelOpenFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
      ],
    },

    MODEL_COLUMNS: {
      HEADQUARTERS: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      SUBCONTRACT: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '인프라',
          dataField: 'infraSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      CENTER: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      DRIVER: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '설치팀등급',
          dataField: 'teamGradeKr',
        },
        {
          headerText: '소속',
          dataField: 'partyKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
    },

    DATE_COLUMNS: {
      HEADQUARTERS: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '배송요일',
          children: [
            {
              headerText: '월',
              dataField: 'mondayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '화',
              dataField: 'tuesdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '수',
              dataField: 'wednesdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '목',
              dataField: 'thursdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '금',
              dataField: 'fridayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '토',
              dataField: 'saturdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '일',
              dataField: 'sundayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
          ],
        },
        {
          headerText: '공휴일',
          dataField: 'holidayFlag',
          style: 'sub-total-color',
          width: 30,
          renderer: {
            type: 'CheckBoxEditRenderer',
            editable: true,
          },
        },
        {
          headerText: '특정일',
          dataField: 'specificDateFlag',
          style: 'sub-total-color',
          width: 30,
          renderer: {
            type: 'CheckBoxEditRenderer',
            editable: true,
          },
        },
        {
          dataField: 'fromSpecificDate',
          headerText: '특정일(시작)',
          width: 120,
          style: 'sub-total-color',
          dataType: 'date',
          formatString: 'yyyy-mm-dd',
          editRenderer: {
            onlyCalendar: true,
            type: 'CalendarRenderer',
            showTodayBtn: true,
            showEditorBtnOver: true,
            showExtraDays: true,
            titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            defaultFormat: 'yyyy-mm-dd',
          },
          renderer: {
            type: 'TemplateRenderer',
          },
          //  //  style: 'my-custom-aui',
        },
        {
          dataField: 'toSpecificDate',
          headerText: '특정일(종료)',
          width: 120,
          dataType: 'date',
          style: 'sub-total-color',
          formatString: 'yyyy-mm-dd',
          editRenderer: {
            onlyCalendar: true,
            type: 'CalendarRenderer',
            showTodayBtn: true,
            showEditorBtnOver: true,
            showExtraDays: true,
            titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            defaultFormat: 'yyyy-mm-dd',
          },
          renderer: {
            type: 'TemplateRenderer',
          },
          //  //  style: 'my-custom-aui',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      SUBCONTRACT: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '인프라',
          dataField: 'infraSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '배송요일',
          children: [
            {
              headerText: '월',
              dataField: 'mondayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '화',
              dataField: 'tuesdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '수',
              dataField: 'wednesdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '목',
              dataField: 'thursdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '금',
              dataField: 'fridayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '토',
              dataField: 'saturdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '일',
              dataField: 'sundayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
          ],
        },
        {
          headerText: '공휴일',
          dataField: 'holidayFlag',
          style: 'sub-total-color',
          width: 30,
          renderer: {
            type: 'CheckBoxEditRenderer',
            editable: true,
          },
        },
        {
          headerText: '특정일',
          dataField: 'specificDateFlag',
          style: 'sub-total-color',
          width: 30,
          renderer: {
            type: 'CheckBoxEditRenderer',
            editable: true,
          },
        },
        {
          dataField: 'fromSpecificDate',
          headerText: '특정일(시작)',
          width: 120,
          style: 'sub-total-color',
          dataType: 'date',
          formatString: 'yyyy-mm-dd',
          editRenderer: {
            onlyCalendar: true,
            type: 'CalendarRenderer',
            showTodayBtn: true,
            showEditorBtnOver: true,
            showExtraDays: true,
            titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            defaultFormat: 'yyyy-mm-dd',
          },
          renderer: {
            type: 'TemplateRenderer',
          },
        },
        {
          dataField: 'toSpecificDate',
          headerText: '특정일(종료)',
          width: 120,
          dataType: 'date',
          style: 'sub-total-color',
          formatString: 'yyyy-mm-dd',
          editRenderer: {
            onlyCalendar: true,
            type: 'CalendarRenderer',
            showTodayBtn: true,
            showEditorBtnOver: true,
            showExtraDays: true,
            titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            defaultFormat: 'yyyy-mm-dd',
          },
          renderer: {
            type: 'TemplateRenderer',
          },
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      CENTER: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '배송요일',
          children: [
            {
              headerText: '월',
              dataField: 'mondayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '화',
              dataField: 'tuesdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '수',
              dataField: 'wednesdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '목',
              dataField: 'thursdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '금',
              dataField: 'fridayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '토',
              dataField: 'saturdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '일',
              dataField: 'sundayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
          ],
        },
        {
          headerText: '공휴일',
          dataField: 'holidayFlag',
          style: 'sub-total-color',
          width: 30,
          renderer: {
            type: 'CheckBoxEditRenderer',
            editable: true,
          },
        },
        {
          headerText: '특정일',
          dataField: 'specificDateFlag',
          style: 'sub-total-color',
          width: 30,
          renderer: {
            type: 'CheckBoxEditRenderer',
            editable: true,
          },
        },
        {
          dataField: 'fromSpecificDate',
          headerText: '특정일(시작)',
          width: 120,
          style: 'sub-total-color',
          dataType: 'date',
          formatString: 'yyyy-mm-dd',
          editRenderer: {
            onlyCalendar: true,
            type: 'CalendarRenderer',
            showTodayBtn: true,
            showEditorBtnOver: true,
            showExtraDays: true,
            titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            defaultFormat: 'yyyy-mm-dd',
          },
          renderer: {
            type: 'TemplateRenderer',
          },
          //  style: 'my-custom-aui',
        },
        {
          dataField: 'toSpecificDate',
          headerText: '특정일(종료)',
          width: 120,
          dataType: 'date',
          style: 'sub-total-color',
          formatString: 'yyyy-mm-dd',
          editRenderer: {
            onlyCalendar: true,
            type: 'CalendarRenderer',
            showTodayBtn: true,
            showEditorBtnOver: true,
            showExtraDays: true,
            titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            defaultFormat: 'yyyy-mm-dd',
          },
          renderer: {
            type: 'TemplateRenderer',
          },
          //  style: 'my-custom-aui',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
      DRIVER: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '설치팀등급',
          dataField: 'teamGradeKr',
        },
        {
          headerText: '소속',
          dataField: 'partyKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '배송요일',
          children: [
            {
              headerText: '월',
              dataField: 'mondayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '화',
              dataField: 'tuesdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '수',
              dataField: 'wednesdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '목',
              dataField: 'thursdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '금',
              dataField: 'fridayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '토',
              dataField: 'saturdayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
            {
              headerText: '일',
              dataField: 'sundayFlag',
              style: 'sub-total-color',
              width: 30,
              renderer: {
                type: 'CheckBoxEditRenderer',
                editable: true,
              },
            },
          ],
        },
        {
          headerText: '공휴일',
          dataField: 'holidayFlag',
          style: 'sub-total-color',
          width: 30,
          renderer: {
            type: 'CheckBoxEditRenderer',
            editable: true,
          },
        },
        {
          headerText: '특정일',
          dataField: 'specificDateFlag',
          style: 'sub-total-color',
          width: 30,
          renderer: {
            type: 'CheckBoxEditRenderer',
            editable: true,
          },
        },
        {
          dataField: 'fromSpecificDate',
          headerText: '특정일(시작)',
          width: 120,
          style: 'sub-total-color',
          dataType: 'date',
          formatString: 'yyyy-mm-dd',
          editRenderer: {
            onlyCalendar: true,
            type: 'CalendarRenderer',
            showTodayBtn: true,
            showEditorBtnOver: true,
            showExtraDays: true,
            titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            defaultFormat: 'yyyy-mm-dd',
          },
          renderer: {
            type: 'TemplateRenderer',
          },
          //  style: 'my-custom-aui',
        },
        {
          dataField: 'toSpecificDate',
          headerText: '특정일(종료)',
          width: 120,
          dataType: 'date',
          style: 'sub-total-color',
          formatString: 'yyyy-mm-dd',
          editRenderer: {
            onlyCalendar: true,
            type: 'CalendarRenderer',
            showTodayBtn: true,
            showEditorBtnOver: true,
            showExtraDays: true,
            titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            defaultFormat: 'yyyy-mm-dd',
          },
          renderer: {
            type: 'TemplateRenderer',
          },
          //  style: 'my-custom-aui',
        },
        {
          headerText: '완료금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'finishFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'finishFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'finishFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '출고후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelAfterFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelAfterFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelAfterFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '고객집도착후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelVisitFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelVisitFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelVisitFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
        {
          headerText: '개봉후취소금액',
          children: [
            {
              headerText: '수도권',
              dataField: 'cancelOpenFeeForCapital',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '지방',
              dataField: 'cancelOpenFeeForProvince',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
            {
              headerText: '제주',
              dataField: 'cancelOpenFeeForJeju',
              style: 'sub-total-color',
              dataType: 'numeric',
              formatString: '#,##0',
              editable: true,
            },
          ],
        },
      ],
    },

    CANCEL_COLUMNS: {
      HEADQUARTERS: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '취소사유',
          dataField: 'cancelReasonKr',
        },
        {
          headerText: '취소시점',
          children: [
            {
              headerText: '출고후취소 적용여부(O/X)',
              dataField: 'cancelAfterFeeFlagKr',
              style: 'sub-total-color',
              renderer: {
                type: 'DropDownListRenderer',
                list: MASTER_OPS?.OX_FLAG,
                keyField: 'value',
                valueField: 'label',
              },
            },
            {
              headerText: '고객집도착후취소 적용여부(O/X)',
              dataField: 'cancelVisitFeeFlagKr',
              style: 'sub-total-color',
              renderer: {
                type: 'DropDownListRenderer',
                list: MASTER_OPS?.OX_FLAG,
                keyField: 'value',
                valueField: 'label',
              },
            },
            {
              headerText: '개봉후취소 적용여부(O/X)',
              dataField: 'cancelOpenFeeFlagKr',
              style: 'sub-total-color',
              renderer: {
                type: 'DropDownListRenderer',
                list: MASTER_OPS?.OX_FLAG,
                keyField: 'value',
                valueField: 'label',
              },
            },
          ],
        },
      ],
      SUBCONTRACT: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '인프라',
          dataField: 'infraSeqKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '취소사유',
          dataField: 'cancelReasonKr',
        },
        {
          headerText: '취소시점',
          children: [
            {
              headerText: '출고후취소 적용여부(O/X)',
              dataField: 'cancelAfterFeeFlagKr',
              style: 'sub-total-color',
              renderer: {
                type: IGrid.RendererKind.DropDownListRenderer,
                list: MASTER_OPS?.OX_FLAG,
                keyField: 'value',
                valueField: 'label',
              },
            },
            {
              headerText: '고객집도착후취소 적용여부(O/X)',
              dataField: 'cancelVisitFeeFlagKr',
              style: 'sub-total-color',
              renderer: {
                type: IGrid.RendererKind.DropDownListRenderer,
                list: MASTER_OPS?.OX_FLAG,
                keyField: 'value',
                valueField: 'label',
              },
            },
            {
              headerText: '개봉후취소 적용여부(O/X)',
              dataField: 'cancelOpenFeeFlagKr',
              style: 'sub-total-color',
              renderer: {
                type: IGrid.RendererKind.DropDownListRenderer,
                list: MASTER_OPS?.OX_FLAG,
                keyField: 'value',
                valueField: 'label',
              },
            },
          ],
        },
      ],
      CENTER: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '취소사유',
          dataField: 'cancelReasonKr',
        },
        {
          headerText: '취소시점',
          children: [
            {
              headerText: '출고후취소 적용여부(O/X)',
              dataField: 'cancelAfterFeeFlagKr',
              style: 'sub-total-color',
              renderer: {
                type: IGrid.RendererKind.DropDownListRenderer,
                list: MASTER_OPS?.OX_FLAG,
                keyField: 'value',
                valueField: 'label',
              },
            },
            {
              headerText: '고객집도착후취소 적용여부(O/X)',
              dataField: 'cancelVisitFeeFlagKr',
              style: 'sub-total-color',
              renderer: {
                type: IGrid.RendererKind.DropDownListRenderer,
                list: MASTER_OPS?.OX_FLAG,
                keyField: 'value',
                valueField: 'label',
              },
            },
            {
              headerText: '개봉후취소 적용여부(O/X)',
              dataField: 'cancelOpenFeeFlagKr',
              style: 'sub-total-color',
              renderer: {
                type: IGrid.RendererKind.DropDownListRenderer,
                list: MASTER_OPS?.OX_FLAG,
                keyField: 'value',
                valueField: 'label',
              },
            },
          ],
        },
      ],
      DRIVER: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '설치팀등급',
          dataField: 'teamGradeKr',
        },
        {
          headerText: '소속',
          dataField: 'partyKr',
        },
        {
          headerText: '송장유형',
          dataField: 'invoiceTypeKr',
        },
        {
          headerText: '취소사유',
          dataField: 'cancelReasonKr',
        },
        {
          headerText: '취소시점',
          children: [
            {
              headerText: '출고후취소 적용여부(O/X)',
              dataField: 'cancelAfterFeeFlagKr',
              style: 'sub-total-color',
              renderer: {
                type: IGrid.RendererKind.DropDownListRenderer,
                list: MASTER_OPS?.OX_FLAG,
                keyField: 'value',
                valueField: 'label',
              },
            },
            {
              headerText: '고객집도착후취소 적용여부(O/X)',
              dataField: 'cancelVisitFeeFlagKr',
              style: 'sub-total-color',
              renderer: {
                type: IGrid.RendererKind.DropDownListRenderer,
                list: MASTER_OPS?.OX_FLAG,
                keyField: 'value',
                valueField: 'label',
              },
            },
            {
              headerText: '개봉후취소 적용여부(O/X)',
              dataField: 'cancelOpenFeeFlagKr',
              style: 'sub-total-color',
              renderer: {
                type: IGrid.RendererKind.DropDownListRenderer,
                list: MASTER_OPS?.OX_FLAG,
                keyField: 'value',
                valueField: 'label',
              },
            },
          ],
        },
      ],
    },

    OPTION_COLUMNS: {
      HEADQUARTERS: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '유형',
          dataField: 'typeKr',
        },
        {
          headerText: '유형상세',
          dataField: 'detail',
        },
        {
          headerText: '부가정보금액',
          dataField: 'additionalFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
      ],
      SUBCONTRACT: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '인프라',
          dataField: 'infraSeqKr',
        },
        {
          headerText: '유형',
          dataField: 'typeKr',
        },
        {
          headerText: '유형상세',
          dataField: 'detail',
        },
        {
          headerText: '부가정보금액',
          dataField: 'additionalFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
      ],
      CENTER: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '유형',
          dataField: 'typeKr',
        },
        {
          headerText: '유형상세',
          dataField: 'detail',
        },
        {
          headerText: '부가정보금액',
          dataField: 'additionalFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
      ],
      DRIVER: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '설치팀등급',
          dataField: 'teamGradeKr',
        },
        {
          headerText: '소속',
          dataField: 'partyKr',
        },
        {
          headerText: '유형',
          dataField: 'typeKr',
        },
        {
          headerText: '유형상세',
          dataField: 'detail',
        },
        {
          headerText: '부가정보금액',
          dataField: 'additionalFee',
          style: 'sub-total-color',
          dataType: 'numeric',
          formatString: '#,##0',
          editable: true,
        },
      ],
    },
  };

  const labellingKr = (
    data: PagingListDTO<OrderFeeCancelDTO & OrderFeeDateDTO & OrderFeeDefaultDTO & OrderFeeModelDTO & OrderFeeGroupDTO & OrderFeeOptionDTO & OrderFeeProductDTO & OrderFeeRegionDTO>,
    isExcel = false,
  ) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),

        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        teamGradeKr: MASTER_OBJ?.TEAM_GRADE?.[row.teamGrade],
        partyKr: MASTER_OBJ?.DRIVER_PARTY?.[row.party],
        invoiceTypeKr: MASTER_OBJ?.FEE_TYPE?.[row.invoiceType],
        cancelReasonKr: MASTER_OBJ?.CANCEL_REASON?.[row.cancelReason],
        typeKr: MASTER_OBJ?.OPTION_TYPE?.[row.type],
        cancelAfterFeeFlagKr: row.cancelAfterFeeFlag ? 'O' : 'X',
        cancelVisitFeeFlagKr: row.cancelVisitFeeFlag ? 'O' : 'X',
        cancelOpenFeeFlagKr: row.cancelOpenFeeFlag ? 'O' : 'X',
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };
  const fetchPaging = async (
    searchObj: OrderFeeCancelSearchDTO &
      OrderFeeDateSearchDTO &
      OrderFeeDefaultSearchDTO &
      OrderFeeModelSearchDTO &
      OrderFeeGroupSearchDTO &
      OrderFeeOptionSearchDTO &
      OrderFeeProductSearchDTO &
      OrderFeeRegionSearchDTO,
  ) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await feeService.getOmsFeePaging(searchObj, TARGET, location.pathname.split('/')[pathOrder].toLocaleUpperCase() as CATEGORY));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: await feeService.getOmsFeeMainExcel(searchObj, TARGET, location.pathname.split('/')[pathOrder].toLocaleUpperCase() as CATEGORY) }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `${nowMenu?.menuName}_${nowMenu?.tabName}` });
    dispatch(setLoading(null));
  };

  const deleteFee = async (id: string, items) => {
    if (window.confirm('정말로 삭제하시곘습니까?')) {
      const key = getKey(items[0]);
      if (key) {
        const dataDTO = {
          [key + 's']: items?.map((ele) => {
            return ele?.[key];
          }),
        };
        const rs = await feeService.deleteOmsFeeAction(dataDTO, TARGET, location.pathname.split('/')[pathOrder].toLocaleUpperCase() as CATEGORY);
        if (rs?.status === 200) {
          alert('삭제되었습니다!');
          wrappedFetchList(searchObj);
        }
      }
    }
  };

  const saveFee = async (items) => {
    const rs = await feeService.postOmsFeeAction(items, TARGET, location.pathname.split('/')[pathOrder].toLocaleUpperCase() as CATEGORY, 'save');
    if (rs?.status === 200) {
      alert('금액이 저장되었습니다');
      wrappedFetchList(searchObj);
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id?.includes('EXCELDOWN_')) {
      downloadExcel('main');
    } else if (id?.includes('EXCELUPLOADFORM_')) {
      alert('준비중입니다!');
    } else if (id?.includes('EXCELUPLOAD_')) {
      alert('준비중입니다!');
    } else if (id?.includes('ADDTYPE_')) {
      setAddTypeObj({
        visible: true,
        target: TARGET,
        searchObj,
        fetchFn: wrappedFetchList,
      });
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id?.includes('DELETETYPE_')) {
        deleteFee(id, items);
      } else if (id?.includes('SAVEFEE_')) {
        saveFee(items);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const uploadData = async (dataArr: any) => {
    // const rs = await orderService.uploadService(dataArr);
    // if (rs?.status === 200) {
    //   alert('업로드 되었습니다!');
    //   fetchPaging(searchObj);
    // }
  };

  // 기능권한 버튼
  const tabName =
    location.pathname.split('/')[pathOrder].toLocaleUpperCase() === 'HEADQUARTERS' ? `BASICFEE_${TARGET}_TAB` : `BASICFEE_${TARGET}_TAB_${location.pathname.split('/')[pathOrder].toLocaleUpperCase()}`;
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabName);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj, location.pathname]);

  const [columns, setColumns] = useState<IGrid.Column[]>();

  useEffect(() => {
    setCurrentTarget(TARGET);
    setColumns(COLUMNS[`${TARGET}_COLUMNS`][location.pathname.toUpperCase().split('/')[pathOrder]]);
  }, [location.pathname]);

  const [currentTarget, setCurrentTarget] = useState(null);

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks();
  useDidMountEffect(() => {
    if (parsedData?.data?.length > 0) {
      const data = parsedData?.data?.map((row) => {
        return {
          // partnerSeq: Number(selectvalue(row[0], masterOptions?.SELLER)),
          // serviceName: row[1],
          // price: Number(row[2]),
        };
      });
      uploadData(data);
    }
  }, [parsedData]);

  return (
    <div className="page-content">
      {addTypeObj?.visible && <AddType addTypeObj={addTypeObj} setAddTypeObj={setAddTypeObj} />}
      <div className="presenterSearch">
        <SearchBox TARGET={TARGET} searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        {columns && currentTarget === TARGET && (
          <GridBox
            gridRef={gridRef}
            columns={columns}
            gridProps={{
              fixedColumnCount: TARGET === 'GROUP' || TARGET === 'REGION' ? 1 : 0,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              editable: true,
            }}
          />
        )}
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
