import { useCallback, useState } from 'react';
import Toast from '../components/toast/toast';

interface useToastProps {
  ms: number;
  message: string;
}

const useToast = ({ ms, message }: useToastProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [msg, setMsg] = useState(message);
  const [color, setColor] = useState('green');
  const showToast = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      setTimeout(() => {
        setIsOpen(false);
      }, ms);
    }
  }, []);

  const displayToast = () => {
    setIsOpen(true);
  };

  const hideToast = () => {
    setIsOpen(false);
  };

  return {
    Toast: () => {
      return <Toast message={msg} open={isOpen} color={color} />;
    },
    setColor,
    setMsg,
    showToast,
    displayToast,
    hideToast,
  };
};

export default useToast;
