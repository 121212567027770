import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, OptionItem, SelectD } from 'components/reactstrap/reactstrap';

import { InvoiceDaySearchDTO } from 'interface/order';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { KeyboardEvent } from 'react';

export const SearchBox = (props: ISearchBoxProps<InvoiceDaySearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };
  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">*날짜(지정일)</label>
          <FlatpickrD
            value={searchObj?.deliveryDate}
            options={{ mode: 'single' }}
            onReset={() => {
              setSearchObj({
                ...searchObj,
                deliveryDate: null,
              });
            }}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                deliveryDate: returnDateyyyymmdd(option[0]),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.SELLER_SELLER_AUTH}
            value={searchObj?.partnerSeq?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">인프라</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.INFRA}
            value={searchObj?.infraSeq?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.INFRA?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                infraSeq: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.CENTER_AUTH}
            value={searchObj?.centerCode?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.CENTER_AUTH?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
      </Row>
    </Form>
  );
};
