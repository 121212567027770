import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import useGridButton from 'hooks/grid/useGridButton';
import { DetailPopup, IDetailObj } from './component/detailPopup';
import { DriverDTO, DriverSearchDTO } from 'interface/install';
import { PagingListDTO } from 'interface/util';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import { useDidMountEffect } from 'hooks/basichooks';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { DRIVER_DOCK_UPLOAD_FORM, DRIVER_UPLOAD_FORM } from 'envVar2';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [detailObj, setDetailObj] = useState<IDetailObj>();

  const popupProductDetail = (e: IGrid.ButtonClickEvent) => {
    setDetailObj({
      visible: true,
      item: e.item,
      searchObj: e.item.searchObj,
      fetchFn: (searchObj: DriverSearchDTO) => wrappedFetchList(searchObj),
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupProductDetail,
      },
    },
    {
      headerText: '기사코드',
      dataField: 'userId',
    },
    {
      headerText: '기사명',
      dataField: 'driverName',
    },
    {
      headerText: '직급',
      dataField: 'positionKr',
      width: 70,
    },
    {
      headerText: '기사타입',
      dataField: 'driverTypeKr',
      width: 70,
    },
    {
      headerText: '총 Capa(건/30분)',
      dataField: 'duration',
    },
    {
      headerText: '연락처',
      width: '15%',
      dataField: 'phone1',
    },
    {
      headerText: '소속',
      dataField: 'partyKr',
    },
    {
      headerText: '소속창고',
      width: '10%',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '도크코드',
      dataField: 'dockNumber',
    },
    {
      headerText: '등급',
      dataField: 'teamGradeKr',
    },
    {
      headerText: '차종',
      width: '10%',
      dataField: 'carTypeKr',
    },
    {
      headerText: '근무가능요일',
      dataField: 'mondayFlag',
      width: 35,
      colSpan: 7,
      renderer: {
        type: IGrid.RendererKind.CheckBoxEditRenderer,
      },
      children: [
        {
          headerText: '월',
          dataField: 'mondayFlag',
          width: 35,
          editable: false,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '화',
          dataField: 'tuesdayFlag',
          width: 35,
          editable: false,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '수',
          dataField: 'wednesdayFlag',
          width: 35,
          editable: false,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '목',
          dataField: 'thursdayFlag',
          width: 35,
          editable: false,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '금',
          dataField: 'fridayFlag',
          width: 35,
          editable: false,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '토',
          dataField: 'saturdayFlag',
          width: 35,
          editable: false,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '일',
          dataField: 'sundayFlag',
          width: 35,
          editable: false,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
      ],
    },
    {
      headerText: '유니폼 사이즈',
      dataField: 'uniformSize',
      width: 70,
    },
    {
      headerText: '상태',
      dataField: 'useFlagKr',
    },
  ];

  const labellingKr = (data: PagingListDTO<DriverDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        duration: Math.floor((row.amDuration + row.pmDuration) / 30) + '건',
        useFlagKr: MASTER_OBJ?.USE_FLAG?.[row.useFlag + ''],
        partyKr: MASTER_OBJ?.DRIVER_PARTY?.[row.party],
        teamGradeKr: MASTER_OBJ?.TEAM_GRADE?.[row.teamGrade],
        positionKr: MASTER_OBJ?.DRV_POS?.[row.position],
        driverTypeKr: MASTER_OBJ?.DRV_TYPE?.[row.driverType],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        carTypeKr: MASTER_OBJ?.CAR_TYPE?.[row.carType],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: DriverSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.installAction(`driver/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<DriverDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));

    const { list } = labellingKr(
      {
        list: ((await serviceStore.installAction(`driver/excel`, 'GET', searchObj, null))?.data as DriverDTO[])?.map((row) => {
          const keys = Object.keys(row)?.filter((ele) => ele.includes('day'));
          keys.forEach((key) => {
            row[key] = row[key] === true ? 'O' : 'X';
          });
          return {
            ...row,
          };
        }),
      },
      true,
    );
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '기사관리' });
    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'DRIVERREGISTER') {
      setDetailObj({
        visible: true,
        item: null,
        searchObj: JSON.stringify(searchObj),
        fetchFn: (searchObj: DriverSearchDTO) => wrappedFetchList(searchObj),
      });
    } else if (id === 'DRIVEREXCELDOWNLOAD') {
      downloadExcel('main');
    } else if (id === 'DRIVERFORM') {
      window.open(DRIVER_UPLOAD_FORM);
    } else if (id === 'DRIVEREXCELUPLOAD' || id === 'DRIVEREXCELDOCKUPLOAD') {
      uploaderClick(id);
    } else if (id === 'DRIVEREXCELDOCKUPLOADFORM') {
      window.open(DRIVER_DOCK_UPLOAD_FORM);
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const KRTOVALUE = {
    memberCount: 'TEAM_TYPE',
    centerCode: 'CENTER_WHOLE',
    partnerSeq: 'SELLER_NETWORK_AUTH',
    position: 'DRV_POS',
    driverType: 'DRV_TYPE',
    carType: 'CAR_TYPE',
    modelGroup: MASTER_OPS?.MODEL_GROUP?.map((ele) => ele.value),
    amDuration: (amDuration) => {
      return Number(amDuration?.split('건')[0]) * 30;
    },
    pmDuration: (pmDuration) => {
      return Number(pmDuration?.split('건')[0]) * 30;
    },
  };

  const EXCELHEADER = {
    DRIVEREXCELUPLOAD: [
      'dockNumber',
      'driverName',
      'driverType',
      'position',
      'phone1',
      'address',
      'zipCode',
      'carType',
      'partnerSeq',
      'centerCode',
      'memberCount',
      'licenseType',
      'carNumber',
      'amDuration',
      'pmDuration',
      'duration',
      'mondayFlag',
      'tuesdayFlag',
      'wednesdayFlag',
      'thursdayFlag',
      'fridayFlag',
      'saturdayFlag',
      'sundayFlag',
      'modelGroup',
    ],
    DRIVEREXCELDOCKUPLOAD: ['userId', 'driverName', 'dockNumber'],
  };

  const uploadData = async (excelrows, id) => {
    let rs;
    if (id === 'DRIVEREXCELUPLOAD') {
      const dataDTO = excelrows?.map((row) => {
        return {
          ...row,
          duration: row.amDuration + row.pmDuration,
        };
      });

      rs = await serviceStore?.userAction(`driver/excel`, 'POST', null, dataDTO);
    } else if (id === 'DRIVEREXCELDOCKUPLOAD') {
      rs = await serviceStore?.userAction(`driver/excel/update`, 'POST', null, excelrows);
    }
    if (rs?.status === 200) {
      alert(`업로드에 성공하였습니다!`);
      wrappedFetchList(searchObj);
    }

    dispatch(setLoading(null));
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE });
  useDidMountEffect(() => {
    if (parsedData?.data?.length > 0) {
      uploadData(parsedData?.data, parsedData?.id);
    }
  }, [parsedData]);

  return (
    <div className="page-content">
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            fixedColumnCount: 1,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns.filter((_, idx) => idx > 0)} />
      </div>
    </div>
  );
};

export default Index;
