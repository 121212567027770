import { useState, useEffect } from 'react';
import { DetailTopTab, IDetailTopTab } from 'components/template/detailTopTab';

// redux
import { nullingMASTER, RAWDATA } from 'redux/services/menuSlice';
import { useSelector, useDispatch } from 'react-redux';

// component
import StatementRegister from './statementRegister';

import { useParams } from 'react-router';
import { RootState } from 'redux/store';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';

export interface ITransactionRegisterParams {
  from: string;
  seq: string | null;
}

export const TRANSACTION_REGISTER_MASTER = [
  `${RAWDATA}DRIVER_AUTH`,
  'DRIVER_AUTH',
  //
  'SELLER_SELLER_AUTH',
  'SELLER_SELLER_WHOLE',
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  //
  'INFRA',
  'TS_STATUS',
  'FEE_TYPE',
  //
  'INPUT_CATEGORY',
  'INVOICE_STATUS',
  'ORDER_TYPE',
  {
    FLAG: [
      { value: null, label: '선택...' },
      { value: 'false', label: 'X' },
      { value: 'true', label: 'O' },
    ],
  },
];

const Index = () => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const params = useParams();
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState<IDetailTopTab>();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(TRANSACTION_REGISTER_MASTER);
  };

  useEffect(() => {
    if (MASTER_OBJ) {
      setTabObj({
        titles: ['거래명세서', `거래명세서 ${params.seq === '0' ? '생성' : '상세'}`],
        routers: ['/' + params.from, '/transactionRegister'],
        container: [<StatementRegister />],
      });
    }
  }, [MASTER_OBJ]);

  return <>{tabObj && MASTER_OBJ && <DetailTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
