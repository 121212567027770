import { useEffect, useRef, useState } from 'react';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { addMASTER, setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { FunctionDTO, MenuDTO, MenuSearchDTO, TabDTO } from 'interface/user';
import { Col, Row } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';

// utils
import styled from 'styled-components';
import { ISortObj, SortModal } from './component/sortModal';
import AUIGrid from 'modules/Grid';
import { useIsMounted } from 'hooks/basichooks';
import { delay } from 'common/util/counting';
import { serviceStore } from 'services/services';

const BorderWrapper = styled.div`
  width: 100%;
  border: 4px solid rgb(216, 223, 225);
  border-radius: 10px;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const Index = () => {
  const dispatch = useDispatch();
  const [sortObj, setSortObj] = useState<ISortObj>();
  const [tabObj, setTabObj] = useState<TabDTO>();
  const [functionObj, setFunctionObj] = useState<FunctionDTO>();
  const [detailObj, setDetailObj] = useState<MenuDTO>();

  const gridRef = useRef<IPagingGrid>();

  const tabRef1 = useRef<AUIGrid>();
  const tabRef2 = useRef<AUIGrid>();
  const functionRef1 = useRef<AUIGrid>();
  const functionRef2 = useRef<AUIGrid>();

  const [onNoff, setOnNoff] = useState<{ tab: string; function: string }>({
    tab: 'whole',
    function: 'whole',
  });
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const columns: IGrid.Column[] = [
    {
      headerText: '메뉴명',
      dataField: 'menuName',
      width: 130,
    },
    {
      headerText: '연결 주소',
      dataField: 'url',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: (e) => {
          setDetailObj(e.item);
        },
      },
    },
    {
      headerText: '등록 탭',
      dataField: 'tabNames',
    },
  ];

  useEffect(() => {
    addMasterOptions();
    wrappedFetchList(searchObj);
  }, []);

  const addMasterOptions = async () => {
    if (!MASTER_OBJ?.SCREENID_URL) {
      dispatch(
        addMASTER({
          SCREENID_URL: MASTER_OPS?.SCREEN_WHOLE?.map((ele) => {
            return {
              value: ele.screenId,
              label: ele.url,
            };
          }),
          TAB_NAME: MASTER_OPS?.TAB_WHOLE?.map((ele) => {
            return {
              value: ele.tabId,
              label: ele.tabName,
            };
          }),
        }),
      );
    }
  };

  const isMounted = useIsMounted();
  useEffect(() => {
    void delay(100).then(() => {
      if (isMounted) {
        tabRef1.current.bind('cellClick', function (e) {
          clickTab(e.item.tabId);
        });
        functionRef1.current.bind('cellClick', function (e) {});
      }
    });
  }, [isMounted]);

  const activeTab = useRef<string>();
  const clickTab = (tabId: string) => {
    const functions = MASTER_OPS?.FUNCTION_WHOLE?.filter((ele) => ele.tabId === tabId);
    functionRef1.current.setGridData(functions);
    activeTab.current = tabId;
  };

  useEffect(() => {
    if (detailObj) {
      const tabs = MASTER_OPS?.TAB_WHOLE?.filter((ele) => ele.screenId === detailObj?.screenId);
      tabRef1.current.setGridData(tabs);
      const notabs = MASTER_OPS?.TAB_WHOLE?.filter((ele) => ele.screenId !== detailObj?.screenId);
      tabRef2.current.setGridData(notabs);
      functionRef2.current.setGridData(MASTER_OPS?.FUNCTION_WHOLE);
    }
  }, [detailObj]);

  useEffect(() => {
    const notabs = MASTER_OPS?.TAB_WHOLE?.filter((ele) => ele.screenId !== detailObj?.screenId);
    if (onNoff?.tab === 'whole') {
      tabRef2.current.setGridData(notabs);
    } else {
      tabRef2.current.setGridData(notabs?.filter((ele) => (onNoff?.tab === 'true' ? ele.url : !ele.url)));
    }
  }, [onNoff?.tab]);

  useEffect(() => {
    const notabs = MASTER_OPS?.FUNCTION_WHOLE?.filter((ele) => ele.screenId !== detailObj?.screenId);
    if (onNoff?.function === 'whole') {
      functionRef2.current.setGridData(notabs);
    } else {
      functionRef2.current.setGridData(notabs?.filter((ele) => (onNoff?.function === 'true' ? ele.tabId : !ele.tabId)));
    }
  }, [onNoff?.function]);

  const fetchPaging = async (searchObj: MenuSearchDTO) => {
    dispatch(setLoading('GET'));
    const data = (await serviceStore?.userAction(`menu`, 'GET', searchObj, null))?.data;
    const allMenu = data?.list;
    allMenu.forEach((ele) => {
      delete ele['childMenus'];
    });
    const parentParent = allMenu?.filter((ele) => ele.level === 1).sort((a, b) => a.sort - b.sort);
    const parent = allMenu?.filter((ele) => ele.level === 2).sort((a, b) => a.sort - b.sort);
    const child0 = allMenu?.filter((ele) => ele.level === 3).sort((a, b) => a.sort - b.sort);
    const child = child0.map((ele) => {
      return {
        ...ele,
        tabNames: MASTER_OPS?.TAB_WHOLE?.filter((tab) => tab.screenId === ele.screenId)
          ?.map((ele) => ele.tabName)
          ?.join(', '),
      };
    });
    const menu23 = parent.map((p) => {
      return {
        ...p,
        children: child.filter((ele) => ele.parentMenuId === p.menuId),
      };
    });
    const menu12 = parentParent.map((pp) => {
      return {
        ...pp,
        children: menu23.filter((ele) => ele.parentMenuId === pp.menuId),
      };
    });
    gridRef.current.setGridData(menu12);
    dispatch(setLoading(null));
    return data;
  };

  const { searchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 400,
      useFlag: true,
    },
    gridRef,
    fetchPaging,
  });

  const createTab = async (tabObj: TabDTO) => {
    const length = MASTER_OPS?.TAB_WHOLE?.filter((ele) => ele.screenId === tabObj?.screenId)?.length;
    const dataDTO = {
      ...tabObj,
      tabId: `${tabObj?.screenId}_TAB${length + 1}`,
    };
    const rs = await serviceStore?.userAction(`tab`, 'POST', null, dataDTO);
    if (rs?.status === 200) {
      alert('탭이 생성되었습니다!');
      window.location.reload();
    }
  };
  const createFunction = async (functionObj: FunctionDTO) => {
    //
  };

  const saveThisScreenTab = async () => {
    const tabs = tabRef1.current.getGridData();
    const functions = functionRef1.current.getGridData();
    if (tabs?.length > 0) {
      await serviceStore?.userAction(
        'tab/sort',
        'PUT',
        null,
        tabs?.map((ele, idx) => {
          return {
            sort: idx + 1,
            tabId: ele.tabId,
          };
        }),
      );
    }

    if (functions?.length > 0) {
      await serviceStore?.userAction(
        'function/sort',
        'PUT',
        null,
        functions?.map((ele, idx) => {
          return {
            sort: idx + 1,
            functionId: ele.functionId,
          };
        }),
      );

      await serviceStore?.userAction(
        'function/sort',
        'PUT',
        null,
        functions?.map((ele, idx) => {
          return {
            sort: idx + 1,
            functionId: ele.functionId,
          };
        }),
      );

      const promises = functions.map((ele) => {
        serviceStore?.userAction(`function`, 'PUT', null, { ...ele, tabId: activeTab?.current });
      });
      await Promise.all(promises);
    }
  };

  return (
    <div className="page-content">
      {sortObj?.visible && <SortModal sortObj={sortObj} setSortObj={setSortObj} />}
      <div className="presenterGridBox">
        <div style={{ display: 'flex' }}>
          <div style={{ width: 'calc(100% - 1100px)', maxWidth: '50%', marginRight: 20, flexGrow: 1 }}>
            <GridBox
              gridRef={gridRef}
              columns={columns}
              gridProps={{
                height: 900,
                showRowNumColumn: false,
                fixedColumnCount: 1,
                showRowAllCheckBox: false,
                showRowCheckColumn: false,
                pageRowCount: 400,
                showPageRowSelect: false,
                rowIdField: 'menuId',
                displayTreeOpen: true,
              }}
            />
          </div>
          <div style={{ width: 500, marginRight: 20 }}>
            <BorderWrapper style={{ height: '100%', marginBottom: 20 }}>
              <Title>메뉴 - 탭 - 기능 관리</Title>
              <Row>
                <Col>
                  <label>소메뉴이름</label>
                  <InputD disabled readOnly value={detailObj?.menuName || ''} />
                </Col>
                <Col>
                  <label>URL</label>
                  <InputD disabled readOnly value={detailObj?.url || ''} />
                </Col>
              </Row>
              <Row>
                <div style={{ display: 'flex', paddingBottom: 20, paddingTop: 20 }}>
                  <div style={{ marginRight: 20 }}>
                    <GridBox
                      gridRef={tabRef1}
                      columns={[
                        {
                          headerText: '탭이름',
                          dataField: 'tabName',
                        },
                      ]}
                      gridProps={{
                        height: 200,
                        width: 220,
                        showRowNumColumn: true,
                        showRowAllCheckBox: false,
                        showRowCheckColumn: false,
                        usePaging: true,
                        pagingMode: 'simple',
                        //
                        enableDrag: true,
                        enableMultipleDrag: true,
                        enableDragByCellDrag: true,
                        enableDrop: true,
                        dropToOthers: true,
                      }}
                    />
                  </div>
                  <div>
                    <GridBox
                      gridRef={functionRef1}
                      columns={[
                        {
                          headerText: '기능이름',
                          dataField: 'functionName',
                        },
                        {
                          headerText: '기능ID',
                          dataField: 'functionId',
                        },
                      ]}
                      gridProps={{
                        height: 200,
                        width: 220,
                        showRowNumColumn: true,
                        showRowAllCheckBox: false,
                        showRowCheckColumn: false,
                        usePaging: true,
                        pagingMode: 'simple',
                        //
                        enableDrag: true,
                        enableMultipleDrag: true,
                        enableDragByCellDrag: true,
                        enableDrop: true,
                        dropToOthers: true,
                      }}
                    />
                  </div>
                </div>
              </Row>
              <Row>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: 20 }}>
                    <label className="col-form-label">탭 사용중 여부</label>
                    <div className="radio-select-area" style={{ marginBottom: 10 }}>
                      <span
                        className={onNoff?.tab === 'whole' ? 'active' : ''}
                        onClick={() => {
                          setOnNoff((prev) => {
                            return {
                              ...prev,
                              tab: 'whole',
                            };
                          });
                        }}
                      >
                        전체
                      </span>
                      <span
                        className={onNoff?.tab === 'true' ? 'active' : ''}
                        onClick={() => {
                          setOnNoff((prev) => {
                            return {
                              ...prev,
                              tab: 'true',
                            };
                          });
                        }}
                      >
                        사용중
                      </span>
                      <span
                        className={onNoff?.tab === 'false' ? 'active' : ''}
                        onClick={() => {
                          setOnNoff((prev) => {
                            return {
                              ...prev,
                              tab: 'false',
                            };
                          });
                        }}
                      >
                        미사용중
                      </span>
                    </div>
                    <GridBox
                      gridRef={tabRef2}
                      columns={[
                        {
                          headerText: '탭이름',
                          dataField: 'tabName',
                        },
                      ]}
                      gridProps={{
                        height: 400,
                        width: 220,
                        showRowNumColumn: false,
                        showRowAllCheckBox: false,
                        showRowCheckColumn: false,
                        usePaging: true,
                        pagingMode: 'simple',
                        //
                        enableDrag: true,
                        enableMultipleDrag: true,
                        enableDragByCellDrag: true,
                        enableDrop: true,
                        dropToOthers: true,
                        dropAcceptableGridIDs: ['#auigrid-wrap-tab_main'],
                      }}
                    />
                  </div>
                  <div>
                    <label className="col-form-label">기능 사용중 여부</label>
                    <div className="radio-select-area" style={{ marginBottom: 10 }}>
                      <span
                        className={onNoff?.function === 'whole' ? 'active' : ''}
                        onClick={() => {
                          setOnNoff((prev) => {
                            return {
                              ...prev,
                              function: 'whole',
                            };
                          });
                        }}
                      >
                        전체
                      </span>
                      <span
                        className={onNoff?.function === 'true' ? 'active' : ''}
                        onClick={() => {
                          setOnNoff((prev) => {
                            return {
                              ...prev,
                              function: 'true',
                            };
                          });
                        }}
                      >
                        사용중
                      </span>
                      <span
                        className={onNoff?.function === 'false' ? 'active' : ''}
                        onClick={() => {
                          setOnNoff((prev) => {
                            return {
                              ...prev,
                              function: 'false',
                            };
                          });
                        }}
                      >
                        미사용중
                      </span>
                    </div>
                    <GridBox
                      gridRef={functionRef2}
                      columns={[
                        {
                          headerText: '기능이름',
                          dataField: 'functionName',
                        },
                        {
                          headerText: '기능ID',
                          dataField: 'functionId',
                        },
                      ]}
                      gridProps={{
                        height: 400,
                        width: 220,
                        showRowNumColumn: false,
                        showRowAllCheckBox: false,
                        showRowCheckColumn: false,
                        usePaging: true,
                        pagingMode: 'simple',
                        //
                        enableDrag: true,
                        enableMultipleDrag: true,
                        enableDragByCellDrag: true,
                        enableDrop: true,
                        dropToOthers: true,
                        dropAcceptableGridIDs: ['#auigrid-wrap-function_main'],
                      }}
                    />
                  </div>
                </div>
              </Row>

              <Row>
                <Col style={{ textAlign: 'right' }}>
                  <button
                    className="btn btn-secondary sm"
                    onClick={() => {
                      //
                      saveThisScreenTab();
                    }}
                  >
                    저장
                  </button>
                </Col>
              </Row>
            </BorderWrapper>
          </div>
          <div style={{ width: 500, marginRight: 0 }}>
            <BorderWrapper style={{ height: 300, marginBottom: 20 }}>
              <Title>간편 탭 등록</Title>
              <Row>
                <Col>
                  <label>탭이름(*노출값)</label>
                  <InputD
                    value={tabObj?.tabName || ''}
                    onChange={(e) => {
                      setTabObj((prev) => {
                        return {
                          ...prev,
                          tabName: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label>URL</label>
                  <SelectD
                    options={MASTER_OPS?.SCREENID_URL}
                    value={
                      tabObj?.screenId
                        ? {
                            label: MASTER_OBJ?.SCREENID_URL?.[tabObj?.screenId],
                            value: tabObj?.screenId,
                          }
                        : null
                    }
                    onChange={(option) => {
                      setTabObj((prev) => {
                        return {
                          ...prev,
                          screenId: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>탭 설명 및 메모</label>
                  <textarea
                    style={{ width: '100%', height: 80 }}
                    value={tabObj?.description || ''}
                    onChange={(e) => {
                      setTabObj((prev) => {
                        return {
                          ...prev,
                          description: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: 'right' }}>
                  <button
                    className="btn btn-secondary sm"
                    onClick={() => {
                      //
                      createTab(tabObj);
                    }}
                  >
                    저장
                  </button>
                </Col>
              </Row>
            </BorderWrapper>
            <BorderWrapper style={{ height: 'calc(100% - 320px)', marginBottom: 20 }}>
              <Title>간편 기능 등록</Title>
              <Row>
                <Col>
                  <label>기능이름</label>
                  <InputD
                    value={functionObj?.functionName || ''}
                    onChange={(e) => {
                      setFunctionObj((prev) => {
                        return {
                          ...prev,
                          functionName: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label>기능 ID(*프론트 함수와 연결됨)</label>
                  <InputD
                    value={functionObj?.functionId || ''}
                    onChange={(e) => {
                      setFunctionObj((prev) => {
                        return {
                          ...prev,
                          functionId: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>URL</label>
                  <SelectD
                    options={MASTER_OPS?.SCREENID_URL}
                    value={
                      functionObj?.screenId
                        ? {
                            label: MASTER_OBJ?.SCREENID_URL?.[functionObj?.screenId],
                            value: functionObj?.screenId,
                          }
                        : null
                    }
                    onChange={(option) => {
                      setFunctionObj((prev) => {
                        return {
                          ...prev,
                          screenId: (option as OptionItem).value,
                          tabId: null,
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label>탭 선택</label>
                  <SelectD
                    isDisabled={!functionObj?.screenId}
                    options={MASTER_OPS?.TAB_WHOLE?.filter((ele) => ele.screenId === functionObj?.screenId)?.map((ele) => {
                      return {
                        value: ele.tabId,
                        label: ele.tabName,
                      };
                    })}
                    value={
                      functionObj?.tabId
                        ? {
                            label: MASTER_OBJ?.TAB_NAME?.[functionObj?.tabId],
                            value: functionObj?.tabId,
                          }
                        : null
                    }
                    onChange={(option) => {
                      setFunctionObj((prev) => {
                        return {
                          ...prev,
                          tabId: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>기능 탭위치</label>
                  <SelectD
                    options={MASTER_OPS?.GRID_WHICH}
                    value={
                      functionObj?.functionType
                        ? {
                            label: MASTER_OBJ?.GRID_WHICH?.[functionObj?.functionType],
                            value: functionObj?.functionType,
                          }
                        : null
                    }
                    onChange={(option) => {
                      setFunctionObj((prev) => {
                        return {
                          ...prev,
                          functionType: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label>기능 색상</label>
                  <SelectD
                    options={MASTER_OPS?.COLOR}
                    value={
                      functionObj?.color
                        ? {
                            label: MASTER_OBJ?.COLOR?.[functionObj?.color],
                            value: functionObj?.color,
                          }
                        : null
                    }
                    onChange={(option) => {
                      setFunctionObj((prev) => {
                        return {
                          ...prev,
                          color: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>탭 설명 및 메모</label>
                  <textarea
                    style={{ width: '100%', height: 80 }}
                    value={functionObj?.description || ''}
                    onChange={(e) => {
                      setFunctionObj((prev) => {
                        return {
                          ...prev,
                          description: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: 'right' }}>
                  <button
                    className="btn btn-secondary sm"
                    onClick={() => {
                      createFunction(functionObj);
                    }}
                  >
                    저장
                  </button>
                </Col>
              </Row>
            </BorderWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
