import { useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './calc';

// options
import { nullingMASTER } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      //
      'DRIVER_AUTH',
      'DRIVER_WHOLE',
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_NETWORK_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      'SALES_CHANNEL',
      //
      'MODEL_GROUP',
      'MODEL_TYPE',
      'FEE_TYPE',
      'LO_STATUS',
      'CANCEL_REASON',
      'DEFECTED_STAT',
      'INVA_STATUS',
      'INVA_TYPE',
      'INVM_STATUS',
      'ASSET_TYPE',
      'LOCATION_TYPE',
      'SEARCH_MODEL',
      {
        SEARCH_NUMBER: [
          { value: 'inventorySeq', label: '재고번호' },
          { value: 'serialNumber', label: '시리얼번호' },
        ],
      },
      {
        SEARCH_SEQ: [
          {
            label: '주문번호',
            value: 'orderSeq',
          },
          {
            label: '외부주문번호',
            value: 'externalOrderNumber',
          },
          {
            label: '송장번호',
            value: 'invoiceSeq',
          },
          {
            label: '외부송장번호',
            value: 'externalInvoiceNumber',
          },
          {
            label: '고객명',
            value: 'receiverName',
          },
        ],
      },
      {
        SERIAL_DATE: [
          { value: 'CreateDate', label: '생성일' },
          { value: 'ManufactureDate', label: '제조일' },
          { value: 'DistributeDate', label: '유통일' },
        ],
      },
    ];
    initMasterOptions(masterArr);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PARCEL_CALCULATE_FARE: <Tab1 tabId={'PARCEL_CALCULATE_FARE'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
