import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import useToast from 'hooks/useToast';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { SelectD } from 'components/reactstrap/reactstrap';
import { ParcelInvoiceCenterAndBoxInfoDTO, ParcelInvoiceLoadUserDTO } from '../../../_interface/parcel';
import { setLoading } from 'redux/services/menuSlice';
import { numberThousandComma } from 'common/util/counting';
import { detectMobileDevice } from 'common/util/detectDevice';
import { ACT_FEE_PACEL, DASONG_FEE_PACEL } from 'pages/PARCEL/_asset/asset';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const isMobile = detectMobileDevice(window.navigator.userAgent);
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const [inputs, setInputs] = useState<ParcelInvoiceCenterAndBoxInfoDTO>({
    centerCode: reduxUserInfo?.centerCode,
    destinationCenterCode: null,
    parcelInvoiceNumber: null,
  });
  const [todayList, setTodayList] = useState<ParcelInvoiceLoadUserDTO[]>();
  const successPlayer = useRef<HTMLAudioElement>();
  const failurePlayer = useRef<HTMLAudioElement>();
  const currentMode = localStorage.getItem('modeInLinehaul') || 'center';
  const [mode, setMode] = useState<string>(currentMode);
  const { Toast, displayToast, hideToast, setColor, setMsg } = useToast({
    message: '',
    ms: 3000,
  });

  const scanInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    getLinehaulToday();
    scanInputRef?.current?.focus();
  }, []);

  const getLinehaulToday = async () => {
    if (inputs?.centerCode) {
      dispatch(setLoading('GET'));
      const { data } = await serviceStore.parcelAction(`invoice/load/user`, 'GET', inputs, null);
      if (data) setTodayList(data as ParcelInvoiceLoadUserDTO[]);
      dispatch(setLoading(null));
    }
  };

  const scanWarning = (str) => {
    setColor('orange');
    setMsg(str);
    displayToast();
    scanInputRef.current.value = '';
    scanInputRef?.current?.focus();
  };

  const scanRight = (str = 'centerName') => {
    setColor('green');
    setMsg(str);
    displayToast();

    scanInputRef.current.value = '';
    scanInputRef?.current?.focus();
  };

  const scanError = (str = '오류입니다') => {
    setColor('red');
    setMsg(str);
    displayToast();
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        parcelInvoiceNumber: null,
      };
    });
    scanInputRef?.current?.focus();
  };

  const changeMode = (e) => {
    localStorage.setItem('modeInLinehaul', e.target.id);
    getLinehaulToday();
    reset(e);
  };

  useEffect(() => {
    const [volumePriceName, price] = calculateVolumePriceName(inputs?.volume, inputs?.partnerSeq);
    console.log(volumePriceName, price);
    setInputs((prev) => {
      return {
        ...prev,
        volumePriceName,
        price,
      };
    });
  }, [inputs?.volume]);

  const ACTCORE_SELLER = [423, 422, 421, 420, 304, 318, 331, 343, 381, 409, 410];
  const calculateVolumePriceName = (volume, partnerSeq): [string, number] => {
    const v = typeof volume === 'string' ? Number(volume) : typeof volume === 'number' ? volume : 0;
    let vName_start, vName_end, price;
    if (ACTCORE_SELLER?.includes(partnerSeq)) {
      vName_start = 50000;
      vName_end = 60000;
      price = ACT_FEE_PACEL[0];
      ACT_FEE_PACEL?.forEach((fee, idx) => {
        if (idx * 10000 + 50000 < v) {
          vName_start = idx * 10000 + 60000;
          vName_end = idx * 10000 + 70000;
          price = fee;
        }
      });

      return [`${price !== ACT_FEE_PACEL[0] ? numberThousandComma(vName_start) + 'cm3 초과' : ''} ~ ${numberThousandComma(vName_end)}cm3 이하`, price];
    } else {
      vName_start = 80000;
      vName_end = 90000;
      price = DASONG_FEE_PACEL[0];
      DASONG_FEE_PACEL?.forEach((fee, idx) => {
        if (idx * 10000 + 80000 < v) {
          vName_start = idx * 10000 + 90000;
          vName_end = idx * 10000 + 100000;
          price = fee;
        }
      });
      return [`${price !== DASONG_FEE_PACEL[0] ? numberThousandComma(vName_start) + 'cm3 초과' : ''} ~ ${numberThousandComma(vName_end)}cm3 이하`, price];
    }
  };

  const validation = (inputs: ParcelInvoiceCenterAndBoxInfoDTO) => {
    if (!inputs?.centerCode) {
      alert('상차지는 필수값입니다!');
      return false;
    }
    if (!inputs?.parcelInvoiceNumber) {
      alert('운송장번호는 필수값입니다!');
      return false;
    }
    return true;
  };

  const validationV = (inputs: ParcelInvoiceCenterAndBoxInfoDTO) => {
    if (!inputs?.parcelInvoiceNumber) {
      alert('운송장번호는 필수값입니다!');
      return false;
    }
    return true;
  };

  const getLoadInfo = async () => {
    if (validation(inputs)) {
      if (mode !== 'center') {
        const data = (await serviceStore.parcelAction('invoice/centerAndboxInfo', 'GET', inputs))?.data as ParcelInvoiceCenterAndBoxInfoDTO;
        setInputs((prev) => {
          return {
            ...data,
            centerCode: prev?.centerCode,
            destinationCenterCode: prev?.destinationCenterCode,
            targetCenterCode: data?.centerCode,
            parcelInvoiceNumber: mode === 'center' ? null : prev?.parcelInvoiceNumber,
          };
        });
        console.log(data);
        if (data) {
          if (!data?.volume && !(!data?.price || data?.price === 0)) {
            scanRight(MASTER_OBJ?.CENTER_WHOLE?.[data?.centerCode]);
          } else if (!data?.price || data?.price === 0 || !data?.volume) {
            // 둘다 없는경우
            scanWarning('센터를 확인하려면 체적정보를 입력하세요.');
          } else if (!data?.volume && (!data?.price || data?.price === 0)) {
            // price없는 경우
            scanWarning('체적정보를 입력하세요.');
          } else {
            scanRight(MASTER_OBJ?.CENTER_WHOLE?.[data?.centerCode]);
          }
        } else {
          scanError((data as any)?.message);
        }
      } else {
        const rs = await serviceStore.parcelAction(`invoice/load`, 'POST', inputs);
        if (rs?.status === 200) {
          const datStr = (rs as any)?.data as string;
          if (datStr?.includes('\n')) {
            scanError(`${MASTER_OBJ?.CENTER_WHOLE?.[datStr?.split('\n')[0]]}\n${datStr?.split('\n')[1]}`);
          } else {
            scanRight(MASTER_OBJ?.CENTER_WHOLE?.[datStr]);
          }
          getLinehaulToday();
          scanInputRef.current.value = '';
          setInputs((prev) => {
            return {
              ...prev,
              parcelInvoiceNumber: null,
            };
          });
          scanInputRef?.current?.focus();
        } else {
          scanError((rs as any)?.data?.message);
        }
      }
    } else {
      scanError('택배운송장이 존재하지 않습니다.');
    }
  };

  const postSaveLoad = async () => {
    if (validationV(inputs)) {
      const data = {
        ...inputs,
        width: inputs?.width ? inputs?.width : 1,
        height: inputs?.height ? inputs?.height : 1,
        depth: inputs?.depth ? inputs?.depth : 1,
        volume: inputs?.volume ? inputs?.volume : 1,
      };

      const rs = await serviceStore.parcelAction(
        `invoice/load/boxInfo`,
        'POST',
        {
          centerCode: inputs?.centerCode,
          destinationCenterCode: inputs?.destinationCenterCode,
          parcelInvoiceNumber: inputs?.parcelInvoiceNumber,
        },
        data,
      );
      console.log(rs);
      if (rs?.status === 200) {
        scanRight(MASTER_OBJ?.CENTER_WHOLE?.[inputs?.targetCenterCode]);
        reset();
      } else {
        scanError(rs?.data?.message);
      }
    }
  };

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    getLoadInfo();
  };

  const reset = (e = null) => {
    setInputs((prev) => {
      return {
        centerCode: prev.centerCode,
        destinationCenterCode: prev.destinationCenterCode,
      };
    });
    getLinehaulToday();
    scanInputRef.current.value = '';
    scanInputRef?.current?.focus();
    if (e) {
      const id = e.target.id;
      setMode(id);
      hideToast();
    } else {
      setMode(mode);
    }
  };

  return (
    <div className="page-content" style={{ paddingTop: isMobile ? 0 : '4rem' }}>
      <div style={{ maxWidth: 500 }}>
        <div style={{ position: 'relative', height: 50, marginBottom: 12 }}>
          <div style={{ textAlign: 'right' }}>
            <div className="toggle-area">
              <div id="center" className={`element ${mode === 'center' ? 'active' : ''}`} onClick={changeMode}>
                센터확인
              </div>
              <div id="volume" className={`element ${mode === 'volume' ? 'active' : ''}`} onClick={changeMode}>
                체적입력
              </div>
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            <Toast />
          </div>
        </div>
        <Form onSubmit={handleSubmit}>
          <Row style={{ marginTop: 50 }}>
            <Col>
              <label className="col-form-label">*상차지</label>
              <SelectD
                options={MASTER_OPS?.CENTER_AUTH}
                hasNull={false}
                value={
                  inputs?.centerCode
                    ? {
                        value: inputs?.centerCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[inputs?.centerCode],
                      }
                    : null
                }
                onChange={(option) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      centerCode: (option as OptionItem)?.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">하차지</label>
              <SelectD
                options={MASTER_OPS?.CENTER_DIFF}
                value={
                  inputs?.destinationCenterCode
                    ? {
                        value: inputs?.destinationCenterCode,
                        label: MASTER_OBJ?.CENTER_DIFF?.[inputs?.destinationCenterCode],
                      }
                    : null
                }
                onChange={(option) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      destinationCenterCode: (option as OptionItem)?.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*운송장번호</label>
              <input
                ref={scanInputRef}
                value={inputs?.parcelInvoiceNumber || ''}
                className={'form-control backgroundYellow'}
                placeholder="운송장번호를 입력하세요"
                id="parcelInvoiceNumber"
                onChange={(e) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      parcelInvoiceNumber: e.target.value.substring(0, 13),
                    };
                  });
                }}
              />
            </Col>
          </Row>
          {inputs?.partnerSeq && mode === 'volume' && (
            <>
              <Row style={{ flexDirection: 'unset' }}>
                <Col>
                  <label className="col-form-label">*가로(cm)</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="1"
                    value={inputs?.width || ''}
                    onChange={(e) => {
                      setInputs((prev) => {
                        return {
                          ...prev,
                          width: e.target.value,
                          volume: parseInt(e.target.value) * parseInt(inputs?.height) * parseInt(inputs?.depth) + '',
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">*세로(cm)</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="1"
                    value={inputs?.depth || ''}
                    onChange={(e) => {
                      setInputs((prev) => {
                        return {
                          ...prev,
                          depth: e.target.value,
                          volume: parseInt(e.target.value) * parseInt(inputs?.height) * parseInt(inputs?.width) + '',
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">*높이(cm)</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="1"
                    id="height"
                    value={inputs?.height || ''}
                    onChange={(e) => {
                      setInputs((prev) => {
                        return {
                          ...prev,
                          height: e.target.value,
                          volume: parseInt(e.target.value) * parseInt(inputs?.depth) * parseInt(inputs?.width) + '',
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">운임비용</label>
                  <input
                    type="number"
                    className="form-control"
                    value={inputs?.price || ''}
                    onChange={(e) => {
                      setInputs((prev) => {
                        return {
                          ...prev,
                          price: Number(e.target.value),
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setMode('center');
                      scanInputRef.current.value = '';
                      hideToast();
                      setInputs({
                        centerCode: inputs?.centerCode,
                      });
                      scanInputRef?.current?.focus();
                    }}
                    style={{ width: '100%' }}
                    className="btn btn-etc"
                  >
                    뒤로가기
                  </button>
                </Col>
                <Col>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      postSaveLoad();
                    }}
                    style={{ width: '100%' }}
                    className="btn btn-secondary sm"
                  >
                    체적입력
                  </button>
                </Col>
              </Row>
            </>
          )}
        </Form>
        <div className="grid-button-area space-between" style={{ marginTop: 30 }}>
          <div></div>
          <div>상차리스트 : {todayList?.length || 0}</div>
        </div>
        <table className="border-table noBorder fontSize10" style={{ fontSize: 10, marginTop: 0 }}>
          <thead>
            <tr>
              <th>순번</th>
              <th colSpan={5}>운송장번호</th>
              <th colSpan={3}>운임비</th>
              <th colSpan={3}>도착창고</th>
            </tr>
          </thead>
          <tbody>
            {todayList?.map((ele, idx) => {
              return (
                <tr key={`today_${idx}`}>
                  <th>{todayList?.length - idx}</th>
                  <th colSpan={5}>{ele.parcelInvoiceNumber}</th>
                  <th colSpan={3}>{numberThousandComma(ele.price)}</th>
                  <th colSpan={3}>{MASTER_OBJ?.CENTER_WHOLE?.[ele.destinationCenterCode]}</th>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="audioWrapper">
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_sound.mp3" ref={successPlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_fail_sound.mp3" ref={failurePlayer} controls></audio>
      </div>
    </div>
  );
};

export default Index;
