import { useRef } from 'react';

import { Col, Row, Form } from 'reactstrap';
import { InputD } from 'components/reactstrap/reactstrap';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { ModelStockAndModelDTO, ModelStockAndModelSearchDTO } from '../../../_interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { orderService } from 'pages/ORDER/1order/_services/service';
import { PagingListDTO } from 'interface/util';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IPopupGridObj {
  visible?: boolean;
  partnerSeq?: number;
  isRadio?: boolean;
  // gridRef: ForwardedRef<IPagingGrid>;
  returns?: any;
}

const ModelStockModal = ({ modelStockObj, setModelStockObj }) => {
  const gridRefModel = useRef<IPagingGrid>();
  const dispatch = useDispatch();
  const { MASTER_OBJ, masterOptions } = useSelector((state: RootState) => state.menu);

  const columns = [
    {
      headerText: '대분류',
      dataField: 'modelGroupLargeKr',
    },
    {
      headerText: '중분류',
      dataField: 'modelGroupMediumKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
      width: 250,
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
      width: 250,
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
  ];

  const labellingKr = (data: PagingListDTO<ModelStockAndModelDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        modelGroupLargeKr: MASTER_OBJ?.MODEL_GRP_1?.[row.modelGroupLarge] ?? masterOptions?.MODEL_GRP_1_OBJ?.[row.modelGroupLarge],
        modelGroupMediumKr: MASTER_OBJ?.MODEL_GRP_2?.[row.modelGroupMedium] ?? masterOptions?.MODEL_GRP_2_OBJ?.[row.modelGroupMedium],
        modelGroupSmallKr: MASTER_OBJ?.MODEL_GRP_3?.[row.modelGroupSmall] ?? masterOptions?.MODEL_GRP_3_OBJ?.[row.modelGroupSmall],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: ModelStockAndModelSearchDTO) => {
    dispatch(setLoading('GET'));
    const useFlagTrueSearchObj = {
      ...searchObj,
      partnerSeq: modelStockObj?.partnerSeq,
      useFlag: true,
    };
    const dataKr = labellingKr(await orderService.getModelListPaging(useFlagTrueSearchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    gridRef: gridRefModel,
    fetchPaging,
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      partnerSeq: modelStockObj?.partnerSeq,
    },
  });

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      wrappedFetchList(searchObj);
    }
  };

  return (
    <CommonModalNew
      title="제품 검색"
      subTitle={`판매사 : ${MASTER_OBJ?.SELLER_WHOLE?.[modelStockObj?.partnerSeq] ?? selectlabel(modelStockObj?.partnerSeq, masterOptions?.SELLER_WHOLE)}`}
      style={{ width: 1200 }}
      visible={modelStockObj?.visible}
      setVisible={() => {
        setModelStockObj({ visible: false });
      }}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={(e) => {
              e.preventDefault();
              const items = gridRefModel?.current.getCheckedRowItemsAll();
              if (items?.length > 0) {
                alert('추가되었습니다');
                setModelStockObj((prev) => {
                  return {
                    ...prev,
                    returns: items,
                  };
                });
              } else {
                alert('선택된 제품이 없습니다!');
              }
            }}
          >
            선택
          </button>
        </>
      }
      children={
        <>
          <Form onKeyPress={onKeyPress}>
            <Row style={{ marginBottom: 20 }}>
              <Col>
                <label style={{ width: 60 }}>제품</label>
                <InputD
                  value={searchObj?.model || ''}
                  onChange={(e) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        model: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ width: 60 }}>제품명</label>
                <InputD
                  value={searchObj?.modelName || ''}
                  onChange={(e) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        modelName: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ width: 60 }}>바코드</label>
                <InputD
                  value={searchObj?.barcode || ''}
                  onChange={(e) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        barcode: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ width: 60 }}>외부SKU</label>
                <InputD
                  value={searchObj?.skuNumber || ''}
                  onChange={(e) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        skuNumber: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col style={{ display: 'flex', paddingTop: 11 }}>
                <button
                  className="btn btn-search ssm"
                  onClick={(e) => {
                    e.preventDefault();
                    wrappedFetchList(searchObj);
                  }}
                >
                  조회
                </button>
              </Col>
            </Row>
          </Form>
          <GridBox
            gridRef={gridRefModel}
            columns={columns}
            gridProps={{
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              height: 550,
              rowCheckToRadio: modelStockObj?.isRadio || false,
            }}
          />
        </>
      }
    />
  );
};

export default ModelStockModal;
