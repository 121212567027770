import { useState, useEffect } from 'react';
import { defaultUnitedPageWithTopTab, UnitedPageWithTopTab, KPI_TAB } from 'components/template/topTab';

import Container1 from './inventoryReturn';

// redux
import { useSelector, useDispatch } from 'react-redux';

// options
import { centerListForlabeling, codeMasterOptionReturn, driverListForLabeling, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';

const Index = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    const initMasterOptions = async () => {
      const [CENTER_SELECT, REMOVE_REASON, REMOVE_STATUS, SELLER_SELECT] = await Promise.all([
        centerListForlabeling({ selectFlag: true }),
        codeMasterOptionReturn('REMOVE_REASON'),
        codeMasterOptionReturn('REMOVE_STATUS'),
        partnerListForlabeling({ sellerFlag: true }),
      ]);

      dispatch(
        setMasterOptions({
          REMOVE_STATUS,
          REMOVE_REASON,
          SELLER: SELLER_SELECT?.map?.((ele) => {
            return {
              value: ele.partnerSeq,
              label: ele.partnerName,
            };
          }),
          CENTER: CENTER_SELECT?.map((ele) => {
            return {
              label: ele.centerName,
              value: ele.centerCode,
            };
          }),
        }),
      );
    };

    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (masterOptions) {
      setTabObj({
        currentTab: 0,
        titles: ['반출/택배 조회'],
        containers: [<Container1 />],
      });
    }
  }, [masterOptions]);

  return <>{masterOptions && tabObj?.titles.length > 0 && <UnitedPageWithTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
