import { useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './feeByType';

// options
import { nullingMASTER } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { InvoiceDetailSearchDTO } from 'interface/order';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
export interface IDetailObjProps {
  visible: boolean;
  item?: InvoiceDetailSearchDTO;
}

export const MANAGE_PARCEL_FEE_LIST_MASTER = [
  'SELLER_FORWARDER_WHOLE',
  'SELLER_FORWARDER_AUTH',
  //
  'SALES_CHANNEL',
  'INFRA',
  'PARCEL_PAY_TYPE',
];

const Index = () => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_FEE_LIST_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PARCEL_FEE_DAILY: <Tab1 tabId={'PARCEL_FEE_DAILY'} />,
            PARCEL_FEE_SELLER: <Tab1 tabId={'PARCEL_FEE_SELLER'} />,
            PARCEL_FEE_RETURN: <Tab1 tabId={'PARCEL_FEE_RETURN'} />,
            PARCEL_FEE_SALESCHANNEL: <Tab1 tabId={'PARCEL_FEE_SALESCHANNEL'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
