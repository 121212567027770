import { useEffect, useState } from 'react';
import { centerListForlabeling, codeMasterOptionReturn, driverListForLabeling, partnerListForlabeling, regionOptionReturnTmp } from 'common/master/codeMasterOptionReturn';
import { httpClient } from 'common/http-client/axiosConfig';
import { GET_DASHBOARD_TODAY_DRIVER, GET_DASHBOARD_TODAY_MODEL, GET_DASHBOARD_TODAY_REGION } from 'envVar';
import Presenter from 'pages/_HOME/dashboard/presenter';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setMasterOptions } from 'redux/services/menuSlice';
import { getDateFrom, getToday, returnDateyyyymmdd, returnYoil } from 'common/util/dateParsingFn';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

import normalImg from './img/normal.png';
import importImg from './img/import.png';
import updateImg from './img/update.png';

import deliveryImg from './img/배송설치.png';
import asImg from './img/AS.png';

const Container = (props) => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state) => state.menu);

  const [todayRegionData, setTodayRegionData] = useState();

  const [centerList, setCenterList] = useState();
  const [inoutByCenterData, setInoutByCenterData] = useState();
  const [inventoryByCenterData, setInventoryByCenterData] = useState();

  const [orderNdeliveryData, setOrderNdeliveryData] = useState();

  const [bestProductData, setBestProductData] = useState();

  const [bestDriverData, setBestDriverData] = useState();
  const [csboardData, setCsBoardData] = useState();
  const [systemBoardData, setSystemBoardData] = useState();

  const [settingPopupObj, setSettingPopupObj] = useState({
    type: null,
    visible: false,
    inoutFn: (data) => setInoutData(data),
    inventoryFn: (data) => setInventoryData(data),
  });

  useEffect(() => {
    !masterOptions && fetchOptions();
  }, []);

  useEffect(() => {
    if (masterOptions) {
      getOrderNdelivery();
      getBestProductData();
      getBestDriver();
      fetchCenterData();
      getCsBoardData();
      getSystemBoardData();
      getOrderByRegion();
    }
  }, [masterOptions]);

  const fetchOptions = async () => {
    const [CENTER_WHOLE, DRIVER_WHOLE, FEE_TYPE, REGION, SERVICE_TYPE, INVOICE_STATUS, COUNSEL_TYPE, COUNSEL_DETAIL_TYPE, DRIVER_QNA_STATUS, QNA_TYPE1, QNA_TYPE2, QNA_TYPE3, USER_CODE] =
      await Promise.all([
        centerListForlabeling(),
        driverListForLabeling(),
        codeMasterOptionReturn('FEE_TYPE'),
        regionOptionReturnTmp('지역 전체'),
        codeMasterOptionReturn('SERVICE_TYPE'),
        codeMasterOptionReturn('INVOICE_STATUS'),
        codeMasterOptionReturn('COUNSEL_TYPE'),
        codeMasterOptionReturn('COUNSEL_DETAIL_TYPE'),
        codeMasterOptionReturn('QNA_STATUS'),
        codeMasterOptionReturn('QNA_TYPE1'),
        codeMasterOptionReturn('QNA_TYPE2'),
        codeMasterOptionReturn('QNA_TYPE3'),
        codeMasterOptionReturn('USER_CODE'),
      ]);
    dispatch(
      setMasterOptions({
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        DRIVER: DRIVER_WHOLE?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        PARTNER_SELLER: (await partnerListForlabeling({ sellerFlag: true })).map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        USER_CODE,
        FEE_TYPE,
        REGION,
        SERVICE_TYPE,
        INVOICE_STATUS,
        COUNSEL_TYPE,
        COUNSEL_DETAIL_TYPE,
        DRIVER_QNA_STATUS,
        QNA_TYPE1,
        QNA_TYPE2,
        QNA_TYPE3,
      }),
    );
  };

  useEffect(() => {
    if (centerList) {
      getLocalItems(centerList);
    }
  }, [centerList]);

  const getLocalItems = (centerList) => {
    const inout = localStorage.getItem('DASHBOARD_INOUT_CHART');
    if (inout) {
      const centerArr = JSON.parse(inout);
      const list = [];
      centerList?.INOUT?.list.forEach((ele) => {
        if (centerArr.includes(ele.centerCode)) {
          ele.useFlag = true;
          list.push(ele);
        } else {
          ele.useFlag = false;
        }
      });
      getWorkdoneByCenter(list);
    }
    const inventory = localStorage.getItem('DASHBOARD_INVENTORY_CHART');
    if (inventory) {
      const centerArr = JSON.parse(inventory);
      const list = [];
      centerList?.INVENTORY?.list.forEach((ele) => {
        if (centerArr.includes(ele.centerCode)) {
          ele.useFlag = true;
          list.push(ele);
        } else {
          ele.useFlag = false;
        }
      });

      if (centerArr?.length < 5) {
        for (let i = 0; i < 5 - centerArr.length; i++) {
          list.push({ centerName: '-' });
        }
      }

      setInventoryByCenterData({ list, now: returnDateyyyymmdd(new Date()) });
    }
  };

  const fetchCenterData = async () => {
    const [INOUT, INVENTORY] = await Promise.all([inoutDataReturn(), inventoryReturn()]);
    setCenterList({
      INOUT,
      INVENTORY,
    });
  };

  const inoutDataReturn = async () => {
    return httpClient.get(`/warehouse/inventory/dashboard/today/inOut`).then((rs) => {
      if (rs?.status === 200) return rs.data;
    });
  };

  const inventoryReturn = async () => {
    return httpClient.get(`/warehouse/inventory/dashboard/inventory/quantity`).then((rs) => {
      if (rs?.status === 200) return rs.data;
    });
  };
  const setInoutData = ({ list, centerList }) => {
    memorizeLocalStorage('DASHBOARD_INOUT_CHART', list, centerList);
    getWorkdoneByCenter(list);
  };

  const setInventoryData = ({ list, centerList }) => {
    memorizeLocalStorage('DASHBOARD_INVENTORY_CHART', list, centerList);
    const _list = JSON.parse(JSON.stringify(list));
    if (list?.length < 5) {
      for (let i = 0; i < 5 - list.length; i++) {
        _list.push({ centerName: '-' });
      }
    }
    setInventoryByCenterData({
      list: _list,
      now: returnDateyyyymmdd(new Date()),
    });
  };

  const memorizeLocalStorage = (name, pickedList, centerList) => {
    const arr = [];
    pickedList?.forEach((ele) => {
      arr.push(ele.centerCode);
    });
    if (name?.includes('INOUT')) {
      centerList?.INOUT?.list?.forEach((ele) => {
        if (arr.includes(ele.centerCode)) {
          ele.useFlag = true;
        } else {
          ele.useFlag = false;
        }
      });
    } else {
      centerList?.INVENTORY?.list?.forEach((ele) => {
        if (arr.includes(ele.centerCode)) {
          ele.useFlag = true;
        } else {
          ele.useFlag = false;
        }
      });
    }
    if (arr?.length > 0) localStorage.setItem(name, JSON.stringify(arr));
  };
  const getWorkdoneByCenter = (centerList) => {
    const series = [
      {
        name: '입고',
        data: [],
      },
      {
        name: '입고예정',
        data: [],
      },
    ];
    const series2 = [
      {
        name: '출고',
        data: [],
      },
      {
        name: '출고예정',
        data: [],
      },
    ];

    const options = {
      chart: {
        type: 'bar',
        height: 265,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#FF910F', '#FFE2BF'],
      dataLabels: {
        style: {
          colors: ['black'],
          fontSize: '10px',
          fontWeight: 400,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          columnWidth: '20%',
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '11px',
                fontWeight: 700,
              },
            },
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    };

    const options2 = {
      chart: {
        type: 'bar',
        height: 265,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#21AD76', '#D2EBE1'],
      dataLabels: {
        style: {
          colors: ['black'],
          fontSize: '10px',
          fontWeight: 400,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          columnWidth: '20%',
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '11px',
                fontWeight: 700,
              },
            },
          },
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
          offsetX: 0,
          offsetY: 0,
        },
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    };
    const centerNames = [];
    centerList?.forEach((center) => {
      centerNames.push(center?.centerName);
      series?.forEach((ele, idx) => {
        ele.data?.push(idx === 0 ? center.totalInFinishQuantity : center.totalInNotFinishQuantity);
      });
      series2?.forEach((ele, idx) => {
        ele.data?.push(idx === 0 ? center.totalOutFinishQuantity : center?.totalOutNotFinishQuantity);
      });
    });
    if (centerList?.length < 5) {
      for (let i = 0; i < 5 - centerList.length; i++) {
        centerNames.push('-');
        series?.forEach((ele, idx) => {
          ele.data?.push(0);
        });
        series2?.forEach((ele, idx) => {
          ele.data?.push(0);
        });
      }
    }
    setInoutByCenterData({
      options,
      series,
      options2,
      series2,
      centerNames,
    });
  };

  useEffect(() => {
    if (orderNdeliveryData?.searchDate[0] && orderNdeliveryData?.searchDate[1] && orderNdeliveryData?.refetch) getOrderNdelivery(orderNdeliveryData?.searchDate);
  }, [orderNdeliveryData?.searchDate]);

  const getOrderNdelivery = async (date = [getDateFrom(-6), getToday()]) => {
    let url = `/order/dashboard/order/delivery?`;
    if (date) url += `&fromDate=${date[0]}&toDate=${date[1]}`;
    const rs = await httpClient.get(url);
    if (rs?.status === 200) {
      const data = rs.data;
      const series = [
        {
          key: 'orderCount',
          data: [],
          name: '주문 건수',
          type: 'line',
        },
        {
          key: 'deliveryFinishCount',
          data: [],
          name: '배송 완료',
          type: 'column',
        },
        {
          key: 'deliveryNotFinishCount',
          data: [],
          name: '배송 예정',
          type: 'column',
        },
      ];

      const options = {
        chart: {
          background: 'transparent',
          height: 400,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          type: 'line',
        },
        colors: ['#FF910F', '#2F9FFF', '#D0E9FF'],
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 2],
          textAnchor: 'middle',
          offsetY: -18,
          style: {
            colors: ['black'],
            fontSize: '11px',
            fontWeight: 500,
          },
          formatter: function (val, opt) {
            if (opt?.seriesIndex === 2) {
              return opt.config.series[1]?.data?.[opt?.dataPointIndex] + ' / ' + val;
            }
            return val;
          },
        },
        plotOptions: {
          bar: {
            columnWidth: 16,
            dataLabels: {
              position: 'top',
            },
            barHeight: '35%',
            borderRadius: 0,
          },
          dataLabels: {
            enabled: true,
          },
        },
        stroke: {
          width: [2, 0, 0],
          curve: 'straight',
          dashArray: [4, 0, 0],
        },
        markers: {
          size: 5,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          show: false,
          categories: [],
          type: 'category',
        },
        legend: {
          show: false,
        },
        yaxis: {
          show: false,
        },
      };

      Object.keys(data?.orderDeliveryCount).forEach((key) => {
        const dateStr = key;
        const [y, m, d] = dateStr.split('-');
        const yoil = returnYoil(dateStr);
        const label = Number(m) + '월 ' + Number(d) + '일 ' + '(' + yoil + ')';

        options?.xaxis?.categories.push(label);
        series.forEach((ele) => {
          ele.data.push(data?.orderDeliveryCount?.[key][ele.key]);
        });
      });

      setOrderNdeliveryData({
        series,
        options,
        rawData: data,
        searchDate: date,
        refetch: false,
      });
    }
  };

  const getBestDriver = () => {
    httpClient.get(GET_DASHBOARD_TODAY_DRIVER).then((rs) => {
      if (rs?.status === 200) {
        setBestDriverData(rs.data);
      }
    });
  };

  const getCsBoardData = () => {
    httpClient.get(`/order/dashboard/today/counsel`).then((rs) => {
      if (rs?.status === 200) {
        rs.data?.list?.forEach((row) => {
          row.detail = '상세보기';
          row.detailTypeKr = selectlabel(row.detailType, masterOptions?.COUNSEL_DETAIL_TYPE);
          row.solveFlagKr = row.solveFlag === true ? '완료' : '미완료';
          row.counselTypeKr = selectlabel(row.counselType, masterOptions?.COUNSEL_TYPE);
          row.counselTypeKrImg = row.counselTypeKr === '배송설치' ? deliveryImg : asImg;
        });
        setCsBoardData(rs.data);
      }
    });
  };

  const getSystemBoardData = () => {
    let url = `/user/announcement/dashboard/today/announcement`;
    httpClient.get(url).then((rs) => {
      if (rs.status === 200) {
        rs.data?.list?.forEach((row) => {
          row.detail = '상세보기';
          row.typeKrImg = row.type === 'UPDATE' ? updateImg : row.type === 'IMPORTANT' ? importImg : normalImg;
        });
        setSystemBoardData(rs.data);
      }
    });
  };

  const getOrderByRegion = (searchObj) => {
    let url = GET_DASHBOARD_TODAY_REGION + '?';
    if (searchObj) {
      if (searchObj?.region) url += `?region=${searchObj?.region}`;
    }
    httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        setTodayRegionData(rs.data);
      }
    });
  };

  const getBestProductData = async () => {
    httpClient.get(GET_DASHBOARD_TODAY_MODEL).then((rs) => {
      if (rs?.status === 200) {
        const _data = rs.data.list;
        const _series = [];
        const _categories = [];

        const _top5Arr = new Array(5).fill(1);
        _top5Arr.forEach((empty, idx) => {
          if (_data[idx]) {
            _series.push(_data[idx].orderCount);
            _categories.push(_data[idx].modelName);
          } else {
            _series.push(null);
            _categories.push('-');
          }
        });

        const _bestProductData = {
          rawData: rs.data,
          categories: _categories,
          series: [
            {
              data: _series,
            },
          ],
          options: {
            chart: {
              type: 'bar',
              width: 350,
              height: 400,
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              show: false,
              labels: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            yaxis: {
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
              labels: {
                show: false,
              },
              max: function (max) {
                return max * 1.1;
              },
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  position: 'top',
                },
                barHeight: '35%',
                borderRadius: 0,
                horizontal: true,
              },
              dataLabels: {
                enabled: true,
              },
            },
            grid: {
              show: false,
            },
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['black'],
              },
              offsetX: 20,
            },
            colors: ['#FF910F'],
            stroke: {
              show: true,
              width: 1,
              colors: ['#fff'],
            },
            distributed: true,
            xaxis: {
              categories: _categories,
            },
          },
        };
        setBestProductData(_bestProductData);
      }
    });
  };

  return (
    <Presenter
      inoutByCenterData={inoutByCenterData}
      inventoryByCenterData={inventoryByCenterData}
      bestProductData={bestProductData}
      orderNdeliveryData={orderNdeliveryData}
      setOrderNdeliveryData={setOrderNdeliveryData}
      bestDriverData={bestDriverData}
      getOrderByRegion={getOrderByRegion}
      todayRegionData={todayRegionData}
      csboardData={csboardData}
      systemBoardData={systemBoardData}
      settingPopupObj={settingPopupObj}
      setSettingPopupObj={setSettingPopupObj}
      centerList={centerList}
    />
  );
};

export default Container;
