import { ReactNode, useEffect, useState } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { setMasterOptions } from 'redux/services/menuSlice';

import { centerListForlabeling, codeMasterOptionReturn, driverListForLabeling, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';

// component
import Tab2 from './refurManagement';
import Tab1 from './refur';

import { RootState } from 'redux/store';
import { TopTab } from 'components/template/topTabNew';
import { useLocation } from 'react-router-dom';
import { TabDTO } from 'interface/user';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Index = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [tabs, setTabs] = useState<TabDTO[]>();
  const [tabItems, setTabItems] = useState<{ titles?: string[]; containers: ReactNode[] }>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { useableTabs } = useSelector((state: RootState) => state.auth);

  const containerList = [
    { container: <Tab2 tabId={'REFUR_MANAGEMENT'} />, tabId: 'REFUR_MANAGEMENT' },
    { container: <Tab1 tabId={'REFUR'} />, tabId: 'REFUR' },
  ];

  const initMasterOptions = async () => {
    const [CENTER_WHOLE, PARTNER_SELLER_WHOLE, INV_REFUR_STAT, MODEL_GROUP, MODEL_TYPE, DRIVER_WHOLE, CENTER_SELECT_WHOLE, FEE_TYPE] = await Promise.all([
      centerListForlabeling(),
      partnerListForlabeling({ sellerFlag: true, selectFlag: true }),
      codeMasterOptionReturn('INV_REFUR_STAT'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
      driverListForLabeling(),
      centerListForlabeling({ selectFlag: true }),
      codeMasterOptionReturn('FEE_TYPE'),
    ]);

    dispatch(
      setMasterOptions({
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        SELLER: PARTNER_SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        INV_REFUR_STAT,
        MODEL_GROUP,
        MODEL_TYPE,
        DRIVER_WHOLE,
        CENTER_SELECT_WHOLE,
        FEE_TYPE,
        BEFORE: [
          { value: 'beforeBarcode', label: '바코드' },
          { value: 'beforeModelStockSeq', label: 'SKU' },
          { value: 'beforeSkuNumber', label: '외부SKU' },
          { value: 'beforeModel', label: '제품' },
          { value: 'beforeModelName', label: '제품명' },
          { value: 'beforeSerialNumber', label: '시리얼번호' },
        ],
        AFTER: [
          { value: 'afterBarcode', label: '바코드' },
          { value: 'afterModelStockSeq', label: 'SKU' },
          { value: 'afterSkuNumber', label: '외부SKU' },
          { value: 'afterModel', label: '제품' },
          { value: 'afterModelName', label: '제품명' },
          { value: 'afterSerialNumber', label: '시리얼번호' },
        ],
        STATUS: [
          { value: 'AVAILABLE', label: '양품' },
          { value: 'DEFECTED', label: '불용' },
        ],
      }),
    );
  };

  useEffect(() => {
    if (tabs) {
      const tabIds = tabs?.map((ele) => ele.tabId);
      const tabLabeling = tabs?.map((ele) => {
        return {
          value: ele.tabId,
          label: ele.tabName,
        };
      });

      setTabItems({
        titles: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => selectlabel(ele.tabId, tabLabeling)),
        containers: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => ele.container),
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (useableTabs) {
      const tabDTO = useableTabs?.[location.pathname] ? [...useableTabs?.[location.pathname]] : [];
      if (tabDTO?.length > 0) {
        tabDTO?.sort((a: TabDTO, b: TabDTO) => a?.sort - b?.sort);
        setTabs(tabDTO);
        initMasterOptions();
      }
    }
  }, [useableTabs]);

  return <>{masterOptions && tabItems && <TopTab titles={tabItems?.titles} containers={tabItems?.containers} />}</>;
};

export default Index;
