import { useState, useEffect, useMemo, useRef } from 'react';
import { Col, Row, Form, Label, Button, Table } from 'reactstrap';
import { LINEHAUL_ROLLBACK, LINEHAUL_CANCEL, WMS_LINEHAUL_PICKING_PHOTO, DROPSHIPPING_INVOICE_PRINT } from 'envVar';
import Grid from 'components/grid/auiGrid';

import { selectlabel, selectvalue } from 'common/master/codeMasterReturnHelper';
import LinehaulCenterChangeModal from './linehaulCenterChangeModal';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { httpClient } from 'common/http-client/axiosConfig';
import { handleFile, uploadFile } from 'common/util/photo';

//component
import { BottomSheets } from 'components/template/bottomSheets';
import ImageModal from 'components/modal/imageModal';
//redux
import { useSelector } from 'react-redux';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
// lib
import { v4 as uuidv4 } from 'uuid';
// img
import CloseImg from 'assets/images/close/cross.png';
import { Prints } from 'pages/OUT/3driver/fcPickingTab/total/component/prints';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import useExcelHooks from 'hooks/excel/useExcelHooks';
import { useDidMountEffect } from 'hooks/basichooks';
import CommonModalNew from 'components/modal/commonModalNew';
import { HaldangPopup } from './haldang';
import { TrunkPrints } from '../../_print/trunkPrint';
import { TRUNK_PICKING_CENTER_FORM } from 'envVar2';

import { resetAllSelections } from 'common/util/searchBox';
import PrintGridBox from 'common/grid/printGridBox';
import { useNavigate } from 'react-router-dom';

const Presenter = (props) => {
  const {
    getCheckedDataTriggerThisGrid,
    modalObj,
    setModalObj,
    alertModalObj,
    setAlertModalObj,
    bottomSheetObj,
    setBottomSheetObj,
    inventoryCancelModalObj,
    setInventoryCancelModalObj,
    //
    photoPopupObj,
    setPhotoPopupObj,
    fetchList,
    locationChangingObj,
    setLocationChangingObj,
    haldangPopupObj,
    setHaldangPopupObj,
    trunkPrintObj,
    setTrunkPrintObj,
    searchObj,
    setSearchObj,
  } = props;

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      if (getCheckedDataTriggerThisGrid?.type === 'prints') {
        const items = getCheckedDataTriggerThisGrid?.items;
        const invoices = items?.filter((ele) => ele?.invoiceSeq);
        if (items?.length !== invoices?.length) alert('송장번호가 없는 건은 제외됩니다');
        if (invoices?.length > 0) {
          setPrintObj({
            visible: true,
            items: invoices,
          });
        } else {
          setPrintObj(null);
          alert('출력할 건이 없습니다');
        }
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const [printObj, setPrintObj] = useState();
  return (
    <>
      <div className="page-content">
        {trunkPrintObj?.visible && <TrunkPrints trunkPrintObj={trunkPrintObj} setTrunkPrintObj={setTrunkPrintObj} />}
        {haldangPopupObj?.visible && <HaldangPopup haldangPopupObj={haldangPopupObj} setHaldangPopupObj={setHaldangPopupObj} />}
        {printObj?.visible && <Prints printObj={printObj} setPrintObj={setPrintObj} />}
        {photoPopupObj?.visible && <PhotoPopup photoPopupObj={photoPopupObj} setPhotoPopupObj={setPhotoPopupObj} />}
        {locationChangingObj?.visible && <LocationChangingModal locationChangingObj={locationChangingObj} setLocationChangingObj={setLocationChangingObj} />}
        {modalObj?.visible && <LinehaulCenterChangeModal modalObj={modalObj} setModalObj={setModalObj} PfetchList={fetchList}></LinehaulCenterChangeModal>}
        {alertModalObj?.visible && alertModalObj.type === 'rollBack' && <LinehaulRollBackModal alertModalObj={alertModalObj} setAlertModalObj={setAlertModalObj} />}
        {alertModalObj?.visible && alertModalObj.type === 'cancel' && <LinehaulCancelModal alertModalObj={alertModalObj} setAlertModalObj={setAlertModalObj} />}
        {inventoryCancelModalObj?.visible && (
          <InventoryCancelModal inventoryCancelModalObj={inventoryCancelModalObj} setInventoryCancelModalObj={setInventoryCancelModalObj} searchObj={searchObj} setSearchObj={setSearchObj} />
        )}
        {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const PhotoPopup = ({ photoPopupObj, setPhotoPopupObj }) => {
  const handleFileUpload = async (e) => {
    const contentArr = e.target.id.split('_');
    const content = contentArr[0];
    const idx = Number(contentArr[1]);
    const ret = await handleFile(e);
    const uploadUrl = await uploadFile(ret.file, ret.url, `${content}_${idx}`);
    const data = {
      linehaulSeq: photoPopupObj?.photos?.linehaulSeq,
      addFiles: [
        {
          column: `LH_PICKING${idx + 1}`,
          uploadUrl,
        },
      ],
    };
    httpClient.post(WMS_LINEHAUL_PICKING_PHOTO + '/save', data).then((res) => {
      if (res?.status === 200) {
        alert('사진 업로드에 성공하였습니다!');
        photoPopupObj?.fetchPhotosFn(photoPopupObj?.item);
      }
    });
  };

  const handleFileRemove = async (e) => {
    const contentArr = e.target.id.split('_');
    const idx = Number(contentArr[1]);

    const prompt = window.confirm('사진을 정말 삭제하시겠습니까?');
    if (prompt) {
      const data = {
        linehaulSeq: photoPopupObj?.photos?.linehaulSeq,
        deleteFiles: [
          {
            column: `LH_PICKING${idx + 1}`,
            uploadUrl: files[idx],
          },
        ],
      };
      httpClient.post(WMS_LINEHAUL_PICKING_PHOTO + '/save', data).then((res) => {
        if (res?.status === 200) {
          alert('사진 삭제에 성공하였습니다!');
          photoPopupObj?.fetchPhotosFn(photoPopupObj?.item);
        }
      });
    }
  };

  const [files, setFiles] = useState();

  useEffect(() => {
    if (photoPopupObj?.photos) {
      setPhotos();
    }
  }, [photoPopupObj?.photos]);

  const setPhotos = () => {
    const photos = new Array(10).fill(null);
    photoPopupObj?.photos?.files.forEach((file) => {
      const i = Number(file?.column.split('LH_PICKING')[1]) - 1;
      photos[i] = file.uploadUrl;
    });
    setFiles(photos);
  };

  return (
    <>
      <CommonModalNew
        title="사진 모아보기"
        subTitle={`피킹지시번호 ${photoPopupObj?.photos?.linehaulSeq}`}
        visible={photoPopupObj?.visible}
        setVisible={() => {
          setPhotoPopupObj({
            ...photoPopupObj,
            visible: false,
          });
        }}
        children={
          <>
            <Row>
              <div className="moa-bogi">
                <Row>
                  {files?.map((url, i) => {
                    return (
                      <Col key={`photos_${i}_${uuidv4()}`}>
                        <div className="photo-zone">
                          <span className="bck-font"> 사진 {i + 1}</span>
                          {url ? (
                            <div style={{ display: 'inline-block' }}>
                              <img
                                src={CloseImg}
                                id={`photos_${i}_`}
                                alt=""
                                onClick={(e) => {
                                  handleFileRemove(e);
                                }}
                                className="closeBtn"
                              />
                              <ImageModal className="top_bar_profile_img" src={url ? url : null} alt="이미지미리보기" title={`사진 ${i + 1}`} />
                            </div>
                          ) : (
                            <>
                              <input style={{ display: 'none' }} type="file" accept="image/*" id={`photos_${i}`} className="unloadPhoto" onChange={(e) => handleFileUpload(e)} />

                              <Button size="md" color="primary" outline>
                                <Label for={`photos_${i}`} style={{ marginBottom: 0 }}>
                                  ㅤㅤㅤ파일 선택ㅤㅤㅤ
                                </Label>
                              </Button>
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Row>
          </>
        }
      />
    </>
  );
};

const Search = (props) => {
  const { fetchList, setSearchObj, searchObj, pagingSetting, setPagingSetting } = props;
  const { masterOptions } = useSelector((state) => state.menu);
  const [selectTypeObj, setSelectTypeObj] = useState({
    ORDER_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.ORDER_SEQ, searchObj));
  }, [selectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    if (pagingSetting?.pageNo && pagingSetting?.fetchNew) {
      // 단순히 페이지만 바뀔때
      fetchList(searchObj);
    }
  }, [pagingSetting?.fetchNew]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPagingSetting((prev) => {
        return {
          ...prev,
          pageNo: 1,
          fetchNew: true,
        };
      });
    }
  };

  const dateOption = [
    {
      label: '출고예정일',
      value: 'LinehaulDate',
    },
    {
      label: '피킹지시확정일',
      value: 'ConfirmDate',
    },
    {
      label: '피킹완료일',
      value: 'PickDate',
    },
  ];

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">기간검색</label>
            <div style={{ display: 'flex' }}>
              <Col style={{ display: 'inline-block' }}>
                <SelectD
                  options={dateOption}
                  value={
                    searchObj?.dateType
                      ? {
                          label: selectlabel(searchObj?.dateType, dateOption),
                          value: searchObj?.dateType,
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    const _searchObj = {
                      ...searchObj,
                      dateType: value,
                    };
                    if (value === null) {
                      _searchObj['fromDate'] = null;
                      _searchObj['toDate'] = null;
                    }
                    setSearchObj(_searchObj);
                  }}
                />
              </Col>
              <Col style={{ display: 'inline-block' }}>
                <FlatpickrD
                  disabled={searchObj?.dateType === null}
                  value={[searchObj?.fromDate, searchObj?.toDate]}
                  onReset={() => {
                    setSearchObj({
                      ...searchObj,
                      fromDate: null,
                      toDate: null,
                    });
                  }}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      fromDate: returnDateyyyymmdd(option[0]),
                      toDate: returnDateyyyymmdd(option[1]),
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">피킹지시번호</label>
            <InputD
              value={searchObj?.linehaulSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  linehaulSeq: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">출발창고</label>
            <SelectD
              value={
                searchObj?.originalCenterCode
                  ? {
                      value: searchObj?.originalCenterCode,
                      label: selectlabel(searchObj?.originalCenterCode, masterOptions?.CENTER),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  originalCenterCode: option.value,
                });
              }}
              options={masterOptions?.CENTER_LABEL}
            />
          </Col>
          <Col>
            <label className="col-form-label">도착창고</label>
            <SelectD
              value={
                searchObj?.destinationCenterCode
                  ? {
                      value: searchObj?.destinationCenterCode,
                      label: selectlabel(searchObj?.destinationCenterCode, masterOptions?.CENTER_DIFF),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  destinationCenterCode: option.value,
                });
              }}
              options={masterOptions?.CENTER_DIFF}
            />
          </Col>
          <Col>
            <label className="col-form-label">간선유형</label>
            <SelectD
              value={
                searchObj?.linehaulType
                  ? {
                      value: searchObj?.linehaulType,
                      label: selectlabel(searchObj?.linehaulType, masterOptions?.LINEHAUL_TYPE),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  linehaulType: option.value,
                });
              }}
              options={masterOptions?.LINEHAUL_TYPE}
            />
          </Col>
          <Col>
            <label className="col-form-label">진행상태</label>
            <SelectD
              value={
                searchObj?.status
                  ? {
                      value: searchObj?.status,
                      label: selectlabel(searchObj?.status, masterOptions?.LINE_STAT),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  status: option.value,
                });
              }}
              options={masterOptions?.LINE_STAT}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">주문번호/송장번호</label>
            {/* orderSeq */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.ORDER_SEQ
                      ? {
                          value: selectTypeObj?.ORDER_SEQ,
                          label: selectlabel(selectTypeObj?.ORDER_SEQ, masterOptions?.ORDER_SEQ),
                        }
                      : null
                  }
                  options={masterOptions?.ORDER_SEQ}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      ORDER_SEQ: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.ORDER_SEQ}
                  value={searchObj?.[selectTypeObj?.ORDER_SEQ] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.ORDER_SEQ]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">제품/제품명</label>
            {/* model / modelName  */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_MODEL
                      ? {
                          value: selectTypeObj?.SEARCH_MODEL,
                          label: selectlabel(selectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_MODEL}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_MODEL: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_MODEL}
                  value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">유사검색여부</label>
            {/* similaritySearchFlag */}
            <div className="radio-select-area">
              <span
                className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: true,
                    };
                  });
                }}
              >
                유사검색
              </span>
              <span
                className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: false,
                    };
                  });
                }}
              >
                일치검색
              </span>
            </div>
          </Col>
          <Col>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                setPagingSetting({
                  ...pagingSetting,
                  pageNo: 1,
                  fetchNew: true,
                });
              }}
            >
              조회
            </button>
          </Col>
          <Col />
          <Col />
        </Row>
      </Form>
    </>
  );
};

const excelDetailColumns = [
  { headerText: '피킹지시번호', dataField: 'linehaulSeq' },
  { headerText: '상태', dataField: 'statusKr' },
  { headerText: '출발창고', dataField: 'originalCenterCodeKr' },
  { headerText: '도착창고', dataField: 'destinationCenterCodeKr' },
  { headerText: '출고예정일', dataField: 'linehaulDate' },
  { headerText: '간선유형', dataField: 'linehaulTypeKr' },
  { headerText: '로케이션', dataField: 'locationCode' },
  { headerText: '제품그룹', dataField: 'modelGroupKr' },
  { headerText: 'SKU', dataField: 'modelStockSeq' },
  { headerText: '외부SKU', dataField: 'skuNumber' },
  { headerText: '판매사', dataField: 'partnerSeqKr' },
  { headerText: '바코드', dataField: 'barcode' },
  { headerText: '제품', dataField: 'model' },
  {
    headerText: '간선피킹대상',
    children: [
      { headerText: '양품', dataField: 'availableQuantity' },
      { headerText: '불용', dataField: 'defectedQuantity' },
      { headerText: '기타', dataField: 'etcQuantity' },
    ],
  },
  {
    headerText: '피킹완료',
    children: [
      { headerText: '양품', dataField: 'pickAvailableQuantity' },
      { headerText: '불용', dataField: 'pickDefectedQuantity' },
      { headerText: '기타', dataField: 'pickEtcQuantity' },
    ],
  },
  {
    headerText: '미피킹',
    children: [
      { headerText: '양품', dataField: 'availableDiff' },
      { headerText: '불용', dataField: 'defectedDiff' },
      { headerText: '기타', dataField: 'etcDiff' },
    ],
  },
  { headerText: '피킹지시 확정자', dataField: 'registerId' },
  { headerText: '피킹지시 확정일', dataField: 'registerDatetime' },
  { headerText: '피킹완료일', dataField: 'pickDatetime' },
];

const GridBox = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const navigate = useNavigate();
  const {
    rows = [],
    gridId,
    columns = [],
    onCellClick,
    setGetCheckedDataTriggerThisGrid,
    getCheckedDataTriggerThisGrid,
    pagingSetting,
    setPagingSetting,
    downloadExcel,
    tmpExcelDataTriggerThisGrid,
    ExcelUploadFunction,
    searchObj,
  } = props;
  const excelGridRef = useRef();
  const excelDetailGridRef = useRef();

  const checkedArr = [
    //
    'TRUNK_LINEHAUL_CONFIRM',
    'TRUNK_LINEHAUL_BODY_CONFIRM',
    'TRUNK_LINEHAUL_CHANGE_CENTER',
    'TRUNK_LINEHAUL_MERGE',
    'TRUNK_LINEHAUL_ROLLBACK',
    'TRUNK_LINEHAUL_CANCEL',
    'TRUNK_LINEHAUL_CANCEL',
    'TRUNK_PICKING_CLOSE',
  ];
  const excelDownClicked = async (type) => {
    const data = await downloadExcel(type);
    if (data?.length > 0) {
      if (type === 'main') {
        excelGridRef.current.setGridData(data);
        excelGridRef.current.exportAsXlsx({ fileName: '간선피킹지시(메인)' });
      } else {
        excelDetailGridRef.current.setGridData(data);
        excelDetailGridRef.current.exportAsXlsx({ fileName: '간선피킹지시(상세)' });
      }
    } else {
      alert('조회되는 값이 없습니다!');
    }
  };

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (checkedArr?.includes(id)) {
      setGetCheckedDataTriggerThisGrid({
        type: id,
        id: `#${gridId}`,
      });
    } else if (id === 'TRUNK_REGISTER') {
      currentDesignType === 1 ? navigate('/main/trunkWorks/trunkRegister') : window.open('/trunkRegister');
    } else if (id === 'TRUNK_REGIT') {
      currentDesignType === 1 ? navigate('/main/trunkWorks/trunkRegit') : window.open('/trunkRegit');
    } else if (id === 'TRUNK_LINEHAUL_UPLOADFORM') {
      window.open(TRUNK_PICKING_CENTER_FORM);
    } else if (id === 'TRUNK_LINEHAUL_UPLOAD') {
      uploaderClick(id);
    } else if (id === 'TRUNK_LINEHAUL_PICKING_DOWNLOAD_MAIN') {
      excelDownClicked('main');
    } else if (id === 'TRUNK_LINEHAUL_PICKING_DOWNLOAD_DETAIL') {
      excelDownClicked('detail');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState();
  const functions = fetchUsableFunctionsInThisTab('LINEHAULOUT_TAB1');
  useMemo(() => {
    if (gridId) {
      const btns = printFunctionToBtns(functions, gridButtonhandler);
      setFunctionBtns(btns);
    }
  }, [gridId, searchObj]);

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks();

  useDidMountEffect(() => {
    if (parsedData?.data?.length > 0) {
      const data = parsedData?.data?.map((row) => {
        return {
          externalLinehaulNumber: row[0],
          linehaulDate: row[1],
          partnerSeq: selectvalue(row[2], masterOptions?.SELLER),
          originalCenterCode: selectvalue(row[3], masterOptions?.CENTER),
          destinationCenterCode: selectvalue(row[4], masterOptions?.CENTER),
          barcode: row[5],
          availableQuantity: row[6] || 0,
          defectedQuantity: row[7] || 0,
        };
      });
      ExcelUploadFunction(data);
    }
  }, [parsedData]);
  return (
    <>
      {functionBtns && (
        <div className="grid-button-area">
          {functionBtns}
          <ExcelDetector />
        </div>
      )}
      <div style={{ width: '100%' }}>
        {gridId && (
          <>
            <Grid
              id={gridId}
              rows={rows}
              columns={columns}
              onCellClick={onCellClick}
              // custompaging **

              pagingSetting={pagingSetting}
              setPagingSetting={setPagingSetting}
              //엑셀다운
              tmpExcelDataTriggerThisGrid={tmpExcelDataTriggerThisGrid}
              getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
              setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
              settingOptions={{
                fixedColumnCount: 4,
                isRowAllCheckCurrentView: true,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
            />
            <PrintGridBox gridRef={excelGridRef} columns={columns} />
            <PrintGridBox gridRef={excelDetailGridRef} columns={excelDetailColumns} />
          </>
        )}
      </div>
    </>
  );
};

const LinehaulRollBackModal = ({ alertModalObj, setAlertModalObj }) => {
  const { visible, linehaulStatus, linehaulSeqs, rollBackData } = alertModalObj;

  const tempArr = ['피킹지시확정', '피킹중'];
  const [answerFlag, setAnswerFlag] = useState(false);

  const linehaulRollBackAPI = async (data) => {
    const payloadArr = rollBackData;
    let url = LINEHAUL_ROLLBACK;

    httpClient
      .post(url, payloadArr)
      .then((res) => {})
      .catch(async (err) => {
        setAlertModalObj({ visible: false });
        alert(err);
      });
  };

  return (
    <CommonModalNew
      title="이전단계"
      setVisible={setAlertModalObj}
      visible={visible}
      style={{ width: 500 }}
      children={
        <>
          <Table
            style={{
              marginTop: '1rem',
              textAlign: 'left',
              borderColor: 'transparent',
            }}
          >
            {linehaulStatus === '피킹지시대기' && answerFlag === false ? "피킹지시대기 상태에서는 이전단계로 되돌릴수 없습니다.\n 간선피킹 취소를 원하실 경우 '간선피킹취소 버튼을 눌러주세요'" : ''}
            {linehaulStatus === '피킹지시확정' && answerFlag === false ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>피킹 지시확정' 상태에서 '피킹 지시대기' 상태로 돌아가시겠습니까?</span>
                <span style={{ color: 'red' }}>* 단, 안전재고는 이전단계로 돌아갈 경우 기록을 확인할 수 없습니다.</span>
              </div>
            ) : (
              ''
            )}
            {linehaulStatus === '피킹지시확정' && answerFlag === true ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>간선피킹 지시번호 {linehaulSeqs} 의 상태값이 변경되었습니다.</div>
                <div style={{ color: 'red' }}>※ 단, 안전재고의 경우 삭제됩니다.</div>
              </div>
            ) : (
              ''
            )}
            {linehaulStatus === '피킹중' && answerFlag === false ? '"피킹 중" 상태에서 “피킹 지시확정” 상태로 돌아가시겠습니까?' : ''}
            {linehaulStatus === '피킹중' && answerFlag === true ? `간선피킹 지시번호 ${linehaulSeqs} 의 상태값이 “피킹 지시확정”으로 변경되었습니다.` : ''}
            {linehaulStatus === '피킹완료' ? '“피킹 완료” 상태에서는 이전단계로 돌아갈 수 없습니다.' : ''}
          </Table>
          <div style={{ marginTop: '2rem', textAlign: 'end' }}>
            {answerFlag === false && tempArr.includes(linehaulStatus) ? (
              <button
                className="btn btn-cancel"
                onClick={() => {
                  linehaulRollBackAPI();
                  setAnswerFlag(true);
                }}
              >
                네
              </button>
            ) : (
              ''
            )}
            {answerFlag === false && tempArr.includes(linehaulStatus) ? (
              <button
                className="btn btn-etc sm"
                onClick={() => {
                  setAlertModalObj({ visible: false });
                }}
              >
                아니요
              </button>
            ) : (
              <button
                className="btn btn-secondary sm"
                onClick={() => {
                  setAlertModalObj({ visible: false });
                }}
              >
                확인
              </button>
            )}
          </div>
        </>
      }
    />
  );
};

const LinehaulCancelModal = ({ alertModalObj, setAlertModalObj }) => {
  const { visible, linehaulStatus, contentValue, orderHoldType, cancelData } = alertModalObj;

  const { masterOptions } = useSelector((state) => state.menu);
  const [answerFlag, setAnswerFlag] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);

  const linehaulCancelAPI = async (data) => {
    const payloadArr = [];
    let url = LINEHAUL_CANCEL;
    for (let i = 0; i < cancelData.length; i++) {
      payloadArr.push({
        holdType: orderHoldType,
        holdNote: contentValue,
        linehaulSeq: cancelData[i].linehaulSeq,
        originalCenterCode: cancelData[i].originalCenterCode,
        destinationCenterCode: cancelData[i].destinationCenterCode,
        linehaulDate: cancelData[i].linehaulDate,
        linehaulType: cancelData[i].linehaulType,
      });
    }
    httpClient
      .post(url, payloadArr)
      .then((res) => {})
      .catch(async (err) => {
        setErrorFlag(true);
      });
  };

  const onContentChange = (event) => {
    setAlertModalObj({
      ...alertModalObj,
      contentValue: event.currentTarget.value,
    });
  };

  const tempArr = ['피킹지시확정', '피킹지시대기'];

  return (
    <CommonModalNew
      title="간선피킹 취소"
      setVisible={setAlertModalObj}
      visible={visible}
      style={{ width: 500 }}
      children={
        <>
          <Table
            style={{
              marginTop: '1rem',
              textAlign: 'left',
              borderColor: 'transparent',
            }}
          >
            {tempArr.includes(linehaulStatus) && answerFlag === false && errorFlag === false ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>간선피킹 작업을 취소하시겠습니까?</span>
                <span style={{ color: 'red' }}>※ 취소할 경우 다시 되돌릴 수 없으니 신중히 선택해주세요.</span>
              </div>
            ) : (
              ''
            )}
            {tempArr.includes(linehaulStatus) && answerFlag === true && errorFlag === false ? '간선피킹 작업이 취소되었습니다.' : ''}
            {!tempArr.includes(linehaulStatus) && answerFlag === false ? `"${linehaulStatus}" 상태에서는 간선피킹을 취소할 수 없습니다.` : ''}
            {errorFlag === true ? '오류' : ''}
          </Table>
          {tempArr.includes(linehaulStatus) && answerFlag === false && errorFlag === false ? (
            <>
              <div>
                <label className="col-form-label">피킹취소사유</label>
                <SelectD
                  options={masterOptions?.ORDER_HOLD_TYPE}
                  onChange={(option) => {
                    setAlertModalObj({
                      ...alertModalObj,
                      orderHoldType: option.value,
                    });
                  }}
                />
              </div>
              <div style={{ marginTop: '1rem' }}>
                <InputD value={contentValue} onChange={(e) => onContentChange(e)} placeholder="피킹취소사유 입력" />
              </div>
            </>
          ) : (
            ''
          )}
          <div style={{ marginTop: '2rem', textAlign: 'end' }}>
            {answerFlag === false && tempArr.includes(linehaulStatus) ? (
              <button
                className="btn btn-etc"
                onClick={() => {
                  linehaulCancelAPI();
                  setAnswerFlag(true);
                }}
              >
                네
              </button>
            ) : (
              ''
            )}
            {answerFlag === false && tempArr.includes(linehaulStatus) ? (
              <button
                className="btn btn-etc sm"
                onClick={() => {
                  setAlertModalObj({ visible: false });
                }}
              >
                아니요
              </button>
            ) : (
              <button
                className="btn btn-etc sm"
                onClick={() => {
                  setAlertModalObj({ visible: false });
                }}
              >
                확인
              </button>
            )}
          </div>
        </>
      }
    />
  );
};

const InventoryCancelModal = ({ inventoryCancelModalObj, setInventoryCancelModalObj, searchObj, setSearchObj }) => {
  const { visible, cancelData, inventorySeqs } = inventoryCancelModalObj;

  const { masterOptions } = useSelector((state) => state.menu);

  const linehaulModalCancelAction = async () => {
    const payloadObj = {
      linehaulSeq: cancelData.linehaulSeq,
      loadingStatementSeq: cancelData.loadingStatementSeq,
      originalCenterCode: cancelData.originalCenterCode,
      destinationCenterCode: cancelData.destinationCenterCode,
      linehaulType: cancelData.linehaulType,
      linehaulDate: cancelData.linehaulDate,
      holdType: cancelData.orderHoldType,
      holdNote: cancelData.contentValue,
      inventorySeqs: inventorySeqs,
    };
    let url = LINEHAUL_CANCEL;
    httpClient
      .post(url, [payloadObj])
      .then((res) => {
        if (res?.status === 200) {
          alert('취소 되었습니다.');
          setSearchObj({ ...searchObj });
        } else {
          alert(res.data.message);
        }
        setInventoryCancelModalObj({ visible: false });
      })
      .catch(async (err) => {
        setInventoryCancelModalObj({ visible: false });
      });
  };

  return (
    <CommonModalNew
      title="피킹취소"
      setVisible={setInventoryCancelModalObj}
      visible={visible}
      style={{ width: 500 }}
      children={
        <div>
          <Col>
            <label className="col-form-label">피킹취소사유</label>
            <SelectD
              value={
                cancelData?.orderHoldType
                  ? {
                      value: cancelData?.orderHoldType,
                      label: selectlabel(cancelData?.orderHoldType, masterOptions?.ORDER_HOLD_TYPE),
                    }
                  : null
              }
              options={masterOptions?.['ORDER_HOLD_TYPE']}
              onChange={(option) => {
                setInventoryCancelModalObj({
                  ...inventoryCancelModalObj,
                  cancelData: { ...cancelData, orderHoldType: option.value },
                });
              }}
            />
          </Col>
          <Col style={{ marginTop: '1rem' }}>
            <InputD
              value={cancelData.contentValue || ''}
              onChange={(e) =>
                setInventoryCancelModalObj({
                  ...inventoryCancelModalObj,
                  cancelData: {
                    ...cancelData,
                    contentValue: e.currentTarget.value,
                  },
                })
              }
              placeholder="피킹취소사유 입력"
            />
          </Col>
          <Col style={{ marginTop: '1rem', textAlign: 'right' }}>
            <Button
              onDoubleClick={() => {
                return;
              }}
              className="btn-secondary btn sm"
              onClick={() => linehaulModalCancelAction()}
            >
              확인
            </Button>
          </Col>
        </div>
      }
    />
  );
};

const LocationChangingModal = ({ locationChangingObj, setLocationChangingObj }) => {
  const [gridId, _] = useState({
    SEARCH: `locationChange_search_${uuidv4()}`,
    SAVE: `locationChange_save_${uuidv4()}`,
  });
  const [rows, setRows] = useState({
    SEARCH: [],
    SAVE: [],
  });
  const [searchObj, setSearchObj] = useState({
    locationCode: null,
  });
  const columns = [
    {
      headerText: '로케이션',
      dataField: 'locationCode',
      editable: false,
    },
    {
      headerText: '피킹가능수량',
      dataField: 'availableQuantity',
      editable: false,
    },
    {
      headerText: '변경수량',
      dataField: 'quantity',
      style: 'my-custom-aui',
      editable: true,
      renderer: {
        type: 'TemplateRenderer',
        aliasFunction: function (rowIndex, columnIndex, value, headerText, item) {
          return value;
        },
      },
    },
  ];

  const changeLocations = () => {
    const data = {
      originalCenterCode: locationChangingObj?.item?.originalCenterCode,
      destinationCenterCode: locationChangingObj?.item?.destinationCenterCode,
      linehaulDate: locationChangingObj?.item?.linehaulDate,
      linehaulType: locationChangingObj?.item?.linehaulType,
      modelStockSeq: locationChangingObj?.item?.modelStockSeq,
      beforeLocationCode: locationChangingObj?.item?.locationCode,

      list: [],
    };

    const rows = window.AUIGrid.getGridData(`#${gridId?.SAVE}`);
    data.list = rows.map((ele) => {
      if (Number(ele.quantity) > 0) {
        return {
          quantity: ele.quantity,
          afterLocationCode: ele.locationCode,
        };
      }
      return null;
    });
    if (data.list?.filter((ele) => ele !== null)?.length === rows.length) {
      locationChangingObj?.saveFn({ data, event: locationChangingObj?.event, searchObj: locationChangingObj?.searchObj });
    } else {
      alert('수량을 입력하지 않은 아이템이 존재합니다!');
    }
  };

  const fetchList = (searchObj) => {
    let url = `/warehouse/inventory/centerCode/locationCode/modelStock/movable/list?modelStockSeq=${locationChangingObj?.item?.modelStockSeq}`;
    if (locationChangingObj?.item?.originalCenterCode) url += `&centerCode=${locationChangingObj?.item?.originalCenterCode}`;
    if (searchObj?.locationCode) url += `&locationCode=${searchObj?.locationCode}`;
    httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        const data = rs.data.map((ele) => {
          return {
            locationCode: ele.locationCode,
            availableQuantity: ele.availableQuantity,
            quantity: 0,
          };
        });

        setRows((prev) => {
          return {
            ...prev,
            SEARCH: data,
          };
        });
      }
    });
  };
  return (
    <CommonModalNew
      style={{ width: 400 }}
      title="피킹 로케이션 변경"
      visible={locationChangingObj?.visible}
      setVisible={() => {
        setLocationChangingObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      children={
        <>
          <Row>
            <div style={{ fontWeight: 'bold', fontSize: 18 }}>{locationChangingObj?.item?.locationCode}</div>
            <div style={{ color: 'gray' }}>{locationChangingObj?.item?.modelName}</div>
            <div style={{ color: 'gray' }}>{locationChangingObj?.item?.barcode}</div>
          </Row>
          {gridId?.SAVE && (
            <>
              <div className="grid-button-area only-right">
                <div
                  className="dark"
                  onClick={() => {
                    changeLocations();
                  }}
                >
                  저장
                </div>
              </div>
              <Grid
                id={gridId?.SAVE}
                rows={rows?.SAVE}
                style={{ height: 200, marginBottom: 20 }}
                columns={columns}
                settingOptions={{
                  showRowNumColumn: true,
                  showRowAllCheckBox: false,
                  showRowCheckColumn: false,
                  //
                  enableDrag: true,
                  enableDragByCellDrag: true,
                  enableDrop: true,
                  enableMultipleDrag: true,
                  dropToOthers: true,
                }}
              />
            </>
          )}
          {gridId && (
            <div className="grid-button-area" style={{ margin: 15, justifyContent: 'space-evenly' }}>
              <div className="blue fake">추가 ▲</div>
              <div className="transparent">드래그 앤 드롭으로 이동</div>
              <div className="red fake">삭제 ▼</div>
            </div>
          )}
          <Row>
            <Col lg={9}>
              <label className="col-form-label">로케이션</label>
              <InputD
                value={searchObj?.locationCode || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    locationCode: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <button
                className="btn btn-etc sssm"
                onClick={(e) => {
                  e.preventDefault();
                  fetchList(searchObj);
                }}
              >
                조회
              </button>
            </Col>
          </Row>

          {gridId?.SEARCH && (
            <Grid
              id={gridId?.SEARCH}
              rows={rows?.SEARCH}
              style={{ height: 200, marginTop: 10, marginBottom: 20 }}
              columns={columns}
              settingOptions={{
                showRowNumColumn: true,
                showRowAllCheckBox: false,
                showRowCheckColumn: false,
                //
                enableDrag: true,
                enableMultipleDrag: true,
                enableDragByCellDrag: true,
                enableDrop: true,
                dropToOthers: true,
              }}
            />
          )}
        </>
      }
    />
  );
};
