import { Input } from 'reactstrap';

// redux
import { useState } from 'react';

import searchImg from './search.png';
import styles from './bunryuModal.module.scss';
import { httpClient } from 'common/http-client/axiosConfig';

import CommonModalNew from './commonModalNew';
import { CODE_MASTER, CODE_GROUP_MODEL } from 'envVar';
import { CodeDTO } from 'interface/master';
import styled from 'styled-components';

export const defaultCategorizationObj = {
  visible: true,
};

export const defaultModalInModalObj = {
  visible: false,
  codeWhat: 0,
  from: null,
};

export interface IBunryuModalObj {
  visible: boolean;
  codeWhat?: number;
  from?: string;
}

interface IBunryuObj {
  MODEL_GRP_1?: CodeDTO;
  MODEL_GRP_2?: CodeDTO;
  MODEL_GRP_3?: CodeDTO;
  MODEL_GRP_DIV?: CodeDTO;
  MODAL_IN_MODAL?: CodeDTO;
}

interface ISearchInput {
  MODEL_GRP_1?: string;
  MODEL_GRP_2?: string;
  MODEL_GRP_3?: string;
  MODEL_GRP_DIV?: string;
  MODAL_IN_MODAL?: string;
}

const BunRyuModal = (props) => {
  const { visible, setVisible } = props;
  const [currentTab, setCurrentTab] = useState(0);
  const hasTabs = [
    { title: '대분류', code: 'MODEL_GRP_1' },
    { title: '중분류', code: 'MODEL_GRP_2' },
    { title: '소분류', code: 'MODEL_GRP_3' },
    { title: '용량/인치', code: 'MODEL_GRP_DIV' },
  ];

  const [list, setList] = useState([]);
  const [crudObj, setCrudObj] = useState<IBunryuObj>({
    MODEL_GRP_1: {
      code: '',
      codeDescription: '',
      codeName: '',
      codeGroup: '',
    },
    MODEL_GRP_2: {
      code: '',
      codeDescription: '',
      codeName: '',
      codeGroup: '',
    },
    MODEL_GRP_3: {
      code: '',
      codeDescription: '',
      codeName: '',
      codeGroup: '',
    },
    MODEL_GRP_DIV: {
      code: '',
      codeDescription: '',
      codeName: '',
      codeGroup: '',
    },
  });
  const [searchInput, setSearchInput] = useState<ISearchInput>();
  const [modalInModalObj, setModalInModalObj] = useState(defaultModalInModalObj);

  const searchClick = async (masterCode, isInModal) => {
    console.log(masterCode, isInModal);
    let url = CODE_GROUP_MODEL + `?&codeGroup=${masterCode}`;
    if (searchInput?.[masterCode] || searchInput?.['MODAL_IN_MODAL']) url += `&codeName=${isInModal ? searchInput['MODAL_IN_MODAL'] : searchInput[masterCode]}`;
    httpClient.get(url).then((res) => {
      if (res?.status === 200) {
        const data = {
          ...list,
        };
        data[isInModal ? 'MODAL_IN_MODAL' : masterCode] = res.data?.codes;
        setList(data);
      }
    });
  };

  const CRUDBunryu = async (crud) => {
    const validation = (obj: CodeDTO) => {
      if (obj?.codeName === '') {
        alert('코드명을 입력하세요');
        return false;
      }

      if (hasTabs[currentTab]?.code !== 'MODEL_GRP_1') {
        if (obj?.parentCodeSeq + '' === '') {
          alert('상위분류를 선택 하셔야합니다!');
          return false;
        }
      }

      if (crud !== 'C') {
        if (!obj?.code) {
          alert('"수정/삭제"하시려면 위의 테이블의 기존 코드를 선택하시고 코드명을 입력해주세요');
          return false;
        }
      }
      return true;
    };
    const codeGroup = hasTabs[currentTab]?.code;

    const data = {
      codeGroup,
      crud,
      codes: [],
    };

    if (validation(crudObj[codeGroup])) {
      const obj: CodeDTO = {
        locale: 'ko',
        codeName: crudObj[codeGroup].codeName,
      };
      if (crudObj[codeGroup].parentCodeSeq) obj.parentCodeSeq = crudObj[codeGroup].parentCodeSeq;
      if (crud !== 'C') {
        if (crudObj[codeGroup].code) obj.code = crudObj[codeGroup].code;
        if (crudObj[codeGroup].codeSeq) obj.codeSeq = crudObj[codeGroup].codeSeq;
      }
      data.codes = [obj];
      httpClient.post(CODE_MASTER, data).then((res) => {
        if (res?.status === 200) {
          alert(res?.data?.code || '저장되었습니다');
          searchClick(codeGroup, false);
        }
      });
    }
  };

  return (
    <>
      {modalInModalObj?.visible && (
        <CommonModalNew
          visible={modalInModalObj?.visible}
          setVisible={() => {
            setModalInModalObj(defaultModalInModalObj);
          }}
          style={{ width: 500 }}
          children={
            <>
              <div style={{ display: 'flex', marginBottom: '20px 0' }}>
                <span style={{ fontWeight: 700, width: 60 }}>{hasTabs[modalInModalObj?.codeWhat]?.title}명</span>
                <Input
                  style={{ width: '55%', margin: '0 25px' }}
                  value={searchInput?.MODAL_IN_MODAL || ''}
                  onChange={(e) => {
                    setSearchInput({
                      ...searchInput,
                      MODAL_IN_MODAL: e.target.value,
                    });
                  }}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    searchClick(hasTabs[modalInModalObj?.codeWhat]?.code, true);
                  }}
                  style={{ margin: 0 }}
                  className="btn btn-search sssm"
                >
                  조회
                </button>
              </div>
              <div className={styles.searchTable}>
                <table className="border-table">
                  <thead className={styles.stickyThead}>
                    <tr>
                      <th>No.</th>
                      {hasTabs[modalInModalObj?.codeWhat]?.title !== '대분류' && <th>대분류명</th>}
                      <th>{hasTabs[modalInModalObj?.codeWhat]?.title}코드</th>
                      <th>{hasTabs[modalInModalObj?.codeWhat]?.title}명</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!list['MODAL_IN_MODAL'] && (
                      <tr>
                        <td colSpan={3}>
                          "{hasTabs[modalInModalObj?.codeWhat]?.title}
                          명"으로 조회해주세요
                        </td>
                      </tr>
                    )}
                    {list['MODAL_IN_MODAL']?.map((row, idx) => {
                      return (
                        <tr
                          key={`modelInModel_${idx}`}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const obj = { ...crudObj };
                            obj[modalInModalObj?.from] = {
                              parentCode: row.code,
                              parentCodeSeq: row.codeSeq,
                              parentCodeName: row.codeName,
                            };
                            if (row.parentCode) obj[modalInModalObj?.from]['parentParentCode'] = row.parentCode;
                            if (row.parentCodeName) obj[modalInModalObj?.from]['parentParentCodeName'] = row.parentCodeName;

                            setCrudObj(obj);
                            setModalInModalObj({
                              ...defaultModalInModalObj,
                            });
                          }}
                        >
                          <td>{idx + 1}</td>
                          {hasTabs[modalInModalObj?.codeWhat]?.title !== '대분류' && <td>{row.parentCodeName}</td>}
                          <td>{row.code}</td>
                          <td>{row.codeName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          }
        />
      )}
      <CommonModalNew
        title="분류표 관리"
        style={{ width: 500 }}
        visible={visible}
        setVisible={() => {
          setVisible(false);
        }}
        children={
          <>
            <div style={{ minHeight: 640 }}>
              <div
                className="div-top-tab"
                style={{
                  position: 'sticky',
                  marginBottom: 0,
                  marginTop: -10,
                  top: -10,
                  zIndex: 15,
                }}
              >
                {hasTabs.map((tab, index) => {
                  return (
                    <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                      <li
                        onClick={() => {
                          setCurrentTab(index);
                        }}
                      >
                        {tab?.title}
                      </li>
                    </div>
                  );
                })}
              </div>
              <BunRyuModalBodyWrapper className={styles.bodyWrapper} currentTab={currentTab}>
                <div>
                  <div className={styles.searchWrapper}>
                    <span style={{ fontWeight: 700 }}>대분류명</span>
                    <Input
                      style={{ width: '55%', margin: '0 25px' }}
                      value={searchInput?.MODEL_GRP_1 || ''}
                      onChange={(e) => {
                        setSearchInput({
                          ...searchInput,
                          MODEL_GRP_1: e.target.value,
                        });
                      }}
                    />
                    <button onClick={() => searchClick('MODEL_GRP_1', false)} style={{ margin: 0 }} className="btn btn-search sssm">
                      조회
                    </button>
                  </div>
                  <div className={styles.searchTable}>
                    <table className="border-table">
                      <thead className={styles.stickyThead}>
                        <tr>
                          <th style={{ width: 45 }}>No.</th>
                          <th>대분류코드</th>
                          <th>대분류명</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!list['MODEL_GRP_1'] && (
                          <tr>
                            <td colSpan={3}>"대분류명"으로 조회해주세요</td>
                          </tr>
                        )}
                        {list['MODEL_GRP_1']?.map((row, idx) => {
                          return (
                            <tr
                              key={`modelGrp1_${idx}`}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setCrudObj({
                                  ...crudObj,
                                  MODEL_GRP_1: { ...row },
                                });
                              }}
                            >
                              <td>{idx + 1}</td>
                              <td>{row.code}</td>
                              <td>{row.codeName}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className={styles.crudBar}>
                    <div style={{ width: 200 }}>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: 10,
                          }}
                        >
                          <label className="col-form-label">대분류코드</label>
                          <Input disabled value={crudObj?.MODEL_GRP_1?.code || ''} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label className="col-form-label">대분류명</label>
                          <Input
                            value={crudObj?.MODEL_GRP_1?.codeName || ''}
                            onChange={(e) => {
                              const obj = { ...crudObj };
                              obj['MODEL_GRP_1'] = {
                                ...obj['MODEL_GRP_1'],
                                codeName: e.target.value,
                              };
                              setCrudObj(obj);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <button onClick={() => CRUDBunryu('C')} style={{ marginLeft: 10 }} className="btn btn-secondary sssm">
                        등록
                      </button>
                      <button onClick={() => CRUDBunryu('U')} style={{ marginLeft: 10 }} className="btn btn-red sssm">
                        수정
                      </button>
                      <button onClick={() => CRUDBunryu('D')} style={{ marginLeft: 10 }} className="btn btn-red sssm">
                        삭제
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.searchWrapper}>
                    <span style={{ fontWeight: 700 }}>중분류명</span>
                    <Input
                      style={{ width: '55%', margin: '0 25px' }}
                      value={searchInput?.MODEL_GRP_2 || ''}
                      onChange={(e) => {
                        setSearchInput({
                          ...searchInput,
                          MODEL_GRP_2: e.target.value,
                        });
                      }}
                    />
                    <button onClick={() => searchClick('MODEL_GRP_2', false)} style={{ margin: 0 }} className="btn btn-search sssm">
                      조회
                    </button>
                  </div>

                  <div className={styles.searchTable}>
                    <table className="border-table">
                      <thead className={styles.stickyThead}>
                        <tr>
                          <th style={{ width: 45 }}>No.</th>
                          <th>대분류명</th>
                          <th>중분류코드</th>
                          <th>중분류명</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!list['MODEL_GRP_2'] && (
                          <tr>
                            <td colSpan={4}>"중분류명"으로 조회해주세요</td>
                          </tr>
                        )}
                        {list['MODEL_GRP_2']?.map((row, idx) => {
                          return (
                            <tr
                              key={`modelGrp2_${idx}`}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setCrudObj({
                                  ...crudObj,
                                  MODEL_GRP_2: { ...row },
                                });
                              }}
                            >
                              <td>{idx + 1}</td>
                              <td>{row.parentCodeName}</td>
                              <td>{row.code}</td>
                              <td>{row.codeName}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className={styles.crudBar}>
                    <div style={{ width: 200 }}>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: 10,
                          }}
                        >
                          <label className="col-form-label">대분류코드</label>
                          <Input disabled value={crudObj?.MODEL_GRP_2?.parentCode || ''} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label className="col-form-label">대분류명</label>
                          <Input disabled value={crudObj?.MODEL_GRP_2?.parentCodeName || ''} />
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: 10,
                          }}
                        >
                          <label className="col-form-label">중분류코드</label>
                          <Input disabled value={crudObj?.MODEL_GRP_2?.code || ''} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label className="col-form-label">중분류명</label>
                          <Input
                            value={crudObj?.MODEL_GRP_2?.codeName || ''}
                            onChange={(e) => {
                              const obj = { ...crudObj };
                              obj['MODEL_GRP_2'] = {
                                ...obj['MODEL_GRP_2'],
                                codeName: e.target.value,
                              };
                              setCrudObj(obj);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        position: 'relative',
                      }}
                    >
                      <img
                        src={searchImg}
                        alt="대분류조회"
                        style={{
                          width: 24,
                          top: 17,
                          left: -25,
                          position: 'absolute',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setModalInModalObj({
                            visible: true,
                            codeWhat: 0,
                            from: 'MODEL_GRP_2',
                          });
                        }}
                      />
                      <button onClick={() => CRUDBunryu('C')} style={{ marginLeft: 10 }} className="btn btn-secondary sssm">
                        등록
                      </button>
                      <button onClick={() => CRUDBunryu('U')} style={{ marginLeft: 10 }} className="btn btn-red sssm">
                        수정
                      </button>
                      <button onClick={() => CRUDBunryu('D')} style={{ marginLeft: 10 }} className="btn btn-red sssm">
                        삭제
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.searchWrapper}>
                    <span style={{ fontWeight: 700 }}>소분류명</span>
                    <Input
                      style={{ width: '55%', margin: '0 25px' }}
                      value={searchInput?.MODEL_GRP_3 || ''}
                      onChange={(e) => {
                        setSearchInput({
                          ...searchInput,
                          MODEL_GRP_3: e.target.value,
                        });
                      }}
                    />
                    <button onClick={() => searchClick('MODEL_GRP_3', false)} style={{ margin: 0 }} className="btn btn-search sssm">
                      조회
                    </button>
                  </div>
                  <div className={styles.searchTable}>
                    <table className="border-table">
                      <thead className={styles.stickyThead}>
                        <tr>
                          <th style={{ width: 45 }}>No.</th>
                          <th style={{ width: 70 }}>대분류명</th>
                          <th>중분류명</th>
                          <th>소분류코드</th>
                          <th>소분류명</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!list['MODEL_GRP_3'] && (
                          <tr>
                            <td colSpan={5}>"소분류명"으로 조회해주세요</td>
                          </tr>
                        )}
                        {list['MODEL_GRP_3']?.map((row, idx) => {
                          return (
                            <tr
                              key={`modelGrp3_${idx}`}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setCrudObj({
                                  ...crudObj,
                                  MODEL_GRP_3: { ...row },
                                });
                              }}
                            >
                              <td>{idx + 1}</td>
                              <td>{row.parentParentCodeName}</td>
                              <td>{row.parentCodeName}</td>
                              <td>{row.code}</td>
                              <td>{row.codeName}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className={styles.crudBar}>
                    <div style={{ width: 200 }}>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: 10,
                          }}
                        >
                          <label className="col-form-label">대분류코드</label>
                          <Input disabled value={crudObj?.MODEL_GRP_3?.parentParentCode || ''} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label className="col-form-label">대분류명</label>
                          <Input disabled value={crudObj?.MODEL_GRP_3?.parentParentCodeName || ''} />
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: 10,
                          }}
                        >
                          <label className="col-form-label">중분류코드</label>
                          <Input disabled value={crudObj?.MODEL_GRP_3?.parentCode || ''} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label className="col-form-label">중분류명</label>
                          <Input disabled value={crudObj?.MODEL_GRP_3?.parentCodeName || ''} />
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: 10,
                          }}
                        >
                          <label className="col-form-label">소분류코드</label>
                          <Input disabled value={crudObj?.MODEL_GRP_3?.code || ''} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label className="col-form-label">소분류명</label>
                          <Input
                            value={crudObj?.MODEL_GRP_3?.codeName || ''}
                            onChange={(e) => {
                              const obj = { ...crudObj };
                              obj['MODEL_GRP_3'] = {
                                ...obj['MODEL_GRP_3'],
                                codeName: e.target.value,
                              };
                              setCrudObj(obj);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        position: 'relative',
                      }}
                    >
                      <img
                        src={searchImg}
                        alt="중분류조회"
                        style={{
                          width: 24,
                          top: 75,
                          left: -65,
                          position: 'absolute',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setModalInModalObj({
                            visible: true,
                            codeWhat: 1,
                            from: 'MODEL_GRP_3',
                          });
                        }}
                      />
                      <button onClick={() => CRUDBunryu('C')} style={{ marginLeft: 10 }} className="btn btn-secondary sssm">
                        등록
                      </button>
                      <button onClick={() => CRUDBunryu('U')} style={{ marginLeft: 10 }} className="btn btn-red sssm">
                        수정
                      </button>
                      <button onClick={() => CRUDBunryu('D')} style={{ marginLeft: 10 }} className="btn btn-red sssm">
                        삭제
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.searchWrapper}>
                    <span style={{ fontWeight: 700 }}>용량/인치명</span>
                    <Input
                      style={{ width: '55%', margin: '0 25px' }}
                      value={searchInput?.MODEL_GRP_DIV || ''}
                      onChange={(e) => {
                        setSearchInput({
                          ...searchInput,
                          MODEL_GRP_DIV: e.target.value,
                        });
                      }}
                    />
                    <button onClick={() => searchClick('MODEL_GRP_DIV', false)} style={{ margin: 0 }} className="btn btn-search sssm">
                      조회
                    </button>
                  </div>
                  <div className={styles.searchTable}>
                    <table className="border-table">
                      <thead className={styles.stickyThead}>
                        <tr>
                          <th style={{ width: 45 }}>No.</th>
                          <th>대분류명</th>
                          <th>용량/인치코드</th>
                          <th>용량/인치명</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!list['MODEL_GRP_DIV'] && (
                          <tr>
                            <td colSpan={4}>"용량/인치명명"으로 조회해주세요</td>
                          </tr>
                        )}
                        {list['MODEL_GRP_DIV']?.map((row, idx) => {
                          return (
                            <tr
                              key={`modelGrpDiv_${idx}`}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setCrudObj({
                                  ...crudObj,
                                  MODEL_GRP_DIV: { ...row },
                                });
                              }}
                            >
                              <td>{idx + 1}</td>
                              <td>{row.parentCodeName}</td>
                              <td>{row.code}</td>
                              <td>{row.codeName}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className={styles.crudBar}>
                    <div style={{ width: 200 }}>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: 10,
                          }}
                        >
                          <label className="col-form-label">대분류코드</label>
                          <Input disabled value={crudObj?.MODEL_GRP_DIV?.parentCode || ''} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label className="col-form-label">대분류명</label>
                          <Input disabled value={crudObj?.MODEL_GRP_DIV?.parentCodeName || ''} />
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: 10,
                          }}
                        >
                          <label className="col-form-label">용량/인치코드</label>
                          <Input disabled value={crudObj?.MODEL_GRP_DIV?.code || ''} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label className="col-form-label">용량/인치명</label>
                          <Input
                            value={crudObj?.MODEL_GRP_DIV?.codeName || ''}
                            onChange={(e) => {
                              const obj = {
                                ...crudObj,
                                MODEL_GRP_DIV: {
                                  ...crudObj.MODEL_GRP_DIV,
                                  codeName: e.target.value,
                                },
                              };
                              setCrudObj(obj);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        position: 'relative',
                      }}
                    >
                      <img
                        src={searchImg}
                        alt="대분류조회"
                        style={{
                          width: 24,
                          top: 24,
                          left: -65,
                          position: 'absolute',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setModalInModalObj({
                            visible: true,
                            codeWhat: 0,
                            from: 'MODEL_GRP_DIV',
                          });
                        }}
                      />
                      <button onClick={() => CRUDBunryu('C')} style={{ marginLeft: 10 }} className="btn btn-secondary sssm">
                        등록
                      </button>
                      <button onClick={() => CRUDBunryu('U')} style={{ marginLeft: 10 }} className="btn btn-red sssm">
                        수정
                      </button>
                      <button onClick={() => CRUDBunryu('D')} style={{ marginLeft: 10 }} className="btn btn-red sssm">
                        삭제
                      </button>
                    </div>
                  </div>
                </div>
              </BunRyuModalBodyWrapper>
            </div>
          </>
        }
      />
    </>
  );
};

export default BunRyuModal;

const BunRyuModalBodyWrapper = styled.div<{ currentTab: number }>`
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 10;
    visibility: hidden;

    &:nth-child(1) {
      z-index: ${(props) => (props.currentTab === 0 ? 13 : 10)};
      visibility: ${(props) => (props.currentTab === 0 ? 'visible' : 'hidden')};
    }
    &:nth-child(2) {
      z-index: ${(props) => (props.currentTab === 1 ? 13 : 10)};
      visibility: ${(props) => (props.currentTab === 1 ? 'visible' : 'hidden')};
    }
    &:nth-child(3) {
      z-index: ${(props) => (props.currentTab === 2 ? 13 : 10)};
      visibility: ${(props) => (props.currentTab === 2 ? 'visible' : 'hidden')};
    }
    &:nth-child(4) {
      z-index: ${(props) => (props.currentTab === 3 ? 13 : 10)};
      visibility: ${(props) => (props.currentTab === 3 ? 'visible' : 'hidden')};
    }
  }
`;
