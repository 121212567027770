import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { setLoading } from 'redux/services/menuSlice';
import { ParcelInvoiceSearchDTO } from '../../../_interface/parcel';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';

interface ICreateObj {
  transferParcel?: string;
  externalInvoiceNumber?: string;
  transferPrice?: number;
}

export interface ITransferObj {
  visible?: boolean;
  searchObj?: ParcelInvoiceSearchDTO;
  items?: string[];
  fetchFn?: (data) => {};
}

export const TransferrderPopup = ({ transferOrderObj, setTransferOrderObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const dispatch = useDispatch();
  const [createObj, setCreateObj] = useState<ICreateObj>({
    transferParcel: null,
    externalInvoiceNumber: null,
    transferPrice: null,
  });

  const transferOrderAPI = async ({ createObj, items }) => {
    const data = transferOrderObj?.items?.map((ele) => {
      return {
        seq: ele.parcelInvoiceSeq,
      };
    });

    dispatch(setLoading('PUT'));

    const rs = await serviceStore?.parcelAction(`invoice/transfer`, 'POST', createObj, data);
    if (rs?.status === 200) {
      alert('이관되었습니다!');
      if (transferOrderObj?.fetchFn && transferOrderObj?.searchObj) transferOrderObj?.fetchFn(transferOrderObj?.searchObj);
      setTransferOrderObj(null);
    }
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title="타택배이관"
      visible={transferOrderObj?.visible}
      setVisible={() => {
        setTransferOrderObj(null);
      }}
      style={{ width: 350 }}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={() => {
              if (!createObj?.transferParcel) {
                alert('택배사는 필수값입니다!');
              } else {
                transferOrderAPI({ createObj, items: transferOrderObj?.items });
              }
            }}
          >
            이관
          </button>
        </>
      }
      children={
        <>
          <Row style={{ flexDirection: 'column' }}>
            <Col>
              <label className="col-form-label">택배사</label>
              <SelectD
                options={MASTER_OPS?.EXTERNAL_PARCEL}
                value={
                  createObj?.transferParcel
                    ? {
                        value: createObj?.transferParcel,
                        label: MASTER_OBJ?.EXTERNAL_PARCEL?.[createObj?.transferParcel],
                      }
                    : null
                }
                onChange={(option) => {
                  setCreateObj((prev) => {
                    return {
                      ...prev,
                      transferParcel: (option as OptionItem)?.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">타택배사 외부운송장번호</label>
              <InputD
                value={createObj?.externalInvoiceNumber || ''}
                onChange={(e) => {
                  setCreateObj((prev) => {
                    return {
                      ...prev,
                      externalInvoiceNumber: e.target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">이관택배운임</label>
              <InputD
                type="number"
                value={createObj?.transferPrice || ''}
                onChange={(e) => {
                  setCreateObj((prev) => {
                    return {
                      ...prev,
                      transferPrice: Number(e.target.value),
                    };
                  });
                }}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};
