import { useState, useEffect } from 'react';
import { defaultUnitedPageWithTopTab, UnitedPageWithTopTab, TAKEOUT_WORKS } from 'components/template/topTab';

// redux
import { useSelector, useDispatch } from 'react-redux';

// // component
import Container1 from './picking/container.js';
import Container2 from './chulgo/container.js';
import Container3 from './takeout/container.js';
import CommonTab from '../../_common/supplement';

// options
import { setLoading, setMasterOptions } from 'redux/services/menuSlice';

import { centerListForlabeling, codeMasterOptionReturn, driverListForLabeling, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { RootState } from 'redux/store.js';
import { getLabelNvalueOptions } from 'common/master/codeMasterReturnHelper';

const TakeoutUnited = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    initMasterOptions();
  }, []);

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (masterOptions) {
      const unitedName = TAKEOUT_WORKS;
      const currentTab = JSON.parse(localStorage.getItem(unitedName)) ? JSON.parse(localStorage.getItem(unitedName)) : 0;
      setTabObj({
        unitedName: unitedName,
        currentTab: currentTab,
        titles: reduxUserInfo?.sub?.includes('LD') ? ['재고보충지시', '반출피킹지시', '반출출고지시', '반출출차지시'] : ['반출피킹지시', '반출출고지시', '반출출차지시'],
        containers: reduxUserInfo?.sub?.includes('LD') ? [<CommonTab tabId={'remove'} />, <Container1 />, <Container2 />, <Container3 />] : [<Container1 />, <Container2 />, <Container3 />],
      });
    }
  }, [masterOptions]);

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [
      LOADING_STAT,
      MODEL_GRP_DIV,
      MODEL_GRP_1,
      MODEL_GRP_2,
      MODEL_GRP_3,
      TRANSPORT_STAT,
      CENTER_LABEL,
      CENTER_WHOLE,
      SELLER_SELECT_WHOLE,
      SELLER_WHOLE,
      REMOVE_STATUS,
      REMOVE_REASON,
      CAR_TYPE,
      ORDER_HOLD_TYPE,
      LOADING_OUT_STAT,
      LOGITYPE,
      MODEL_GROUP,
      MODEL_TYPE,
      LINEHAUL_TYPE,
      LINE_STAT,
      PARCEL_BOX_BARCODE,
      DRIVER_WHOLE,
      INVA_TYPE,
      ORDER_SEQ,
    ] = await Promise.all([
      codeMasterOptionReturn('LOADING_STAT'),
      codeMasterOptionReturn('MODEL_GRP_DIV'),
      codeMasterOptionReturn('MODEL_GRP_1'),
      codeMasterOptionReturn('MODEL_GRP_2'),
      codeMasterOptionReturn('MODEL_GRP_3'),
      codeMasterOptionReturn('TRANSPORT_STAT'),
      centerListForlabeling(),
      centerListForlabeling({ selectFlag: false }),
      partnerListForlabeling({ sellerFlag: true }),
      partnerListForlabeling({ sellerFlag: true, selectFlag: false }),
      codeMasterOptionReturn('REMOVE_STATUS'),
      codeMasterOptionReturn('REMOVE_REASON'),
      codeMasterOptionReturn('CAR_TYPE'),
      codeMasterOptionReturn('ORDER_HOLD_TYPE'),
      codeMasterOptionReturn('LOADING_OUT_STAT'),

      codeMasterOptionReturn('LOGI_TYPE'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
      codeMasterOptionReturn('LINEHAUL_TYPE'),
      codeMasterOptionReturn('LINE_STAT'),
      codeMasterOptionReturn('PARCEL_BOX_BARCODE'),
      driverListForLabeling(),
      codeMasterOptionReturn('INVA_TYPE'),
      codeMasterOptionReturn('ORDER_SEQ'),
    ]);
    const DRIVER_LABEL = getLabelNvalueOptions(DRIVER_WHOLE, 'driverName', 'userId');
    const DRIVER_PHONE = getLabelNvalueOptions(DRIVER_WHOLE, 'phone1', 'userId');
    dispatch(
      setMasterOptions({
        CENTER_WHOLE,
        PARCEL_BOX_BARCODE,
        LOADING_STAT,
        MODEL_GRP_DIV,
        MODEL_GRP_1,
        MODEL_GRP_2,
        MODEL_GRP_3,
        TRANSPORT_STAT,
        DRIVER_LABEL,
        DRIVER_PHONE,
        CENTER_AUTH: CENTER_LABEL?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        SELLER_WHOLE: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER_AUTH: SELLER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER: SELLER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        REMOVE_STATUS,
        REMOVE_REASON,
        CAR_TYPE,
        ORDER_HOLD_TYPE,
        LOADING_OUT_STAT,
        LOGITYPE,
        MODEL_GROUP,
        MODEL_TYPE,
        LINEHAUL_TYPE,
        LINE_STAT,
        SEARCH_DATE_PICKING: [
          { value: 'ConfirmDate', label: '피킹지시 확정일' },
          { value: 'PickDate', label: '피킹 완료일' },
        ],
        SEARCH_DATE_DELIVERY: [
          { value: 'StockRemoveDate', label: '출고 예정일' },
          { value: 'ConfirmDate', label: '출고지시 확정일' },
          { value: 'LoadDate', label: '출고 완료일' },
        ],
        SEARCH_DATE_TAKEOUT: [
          { value: 'StockRemoveDate', label: '출차 예정일' },
          { value: 'ConfirmDate', label: '출차 완료일' },
        ],
        SEARCH_SEQ_DELIVERY: [
          { value: 'stockRemoveSeq', label: '피킹 지시번호' },
          { value: 'loadingStatementSeq', label: '출고 지시번호' },
        ],
        SEARCH_SEQ_TAKEOUT: [
          { value: 'loadingStatementSeq', label: '출고 지시번호' },
          { value: 'transportStatementSeq', label: '운송번호' },
        ],
        INVA_TYPE,
        ORDER_SEQ: [
          { value: 'orderSeq', label: '주문번호' },
          { value: 'externalOrderNumber', label: '외부주문번호' },
          { value: 'invoiceSeq', label: '송장번호' },
          { value: 'externalInvoiceNumber', label: '외부송장번호' },
          { value: 'customerName', label: '고객명' },
        ],
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'barcode', label: '바코드' },
          { value: 'modelStockSeq', label: 'SKU' },
          { value: 'skuNumber', label: '외부SKU' },
        ],
        INVENTORY_SEQ: [
          { value: 'inventorySeq', label: '재고번호' },
          { value: 'serialNumber', label: '시리얼번호' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };

  return <>{masterOptions && tabObj?.titles.length > 0 && <UnitedPageWithTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default TakeoutUnited;
