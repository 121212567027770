import { KeyboardEvent } from 'react';

import { Col, Row, Form } from 'reactstrap';
import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { AccessCenterSearchDTO, AccessDiffCenterSearchDTO, AccessFrontSearchDTO, AccessInfraSearchDTO, AccessSalesChannelSearchDTO, AccessUserSearchDTO } from 'interface/user';

const tanslatorKr = {
  PARTNERS: '판매사',
  INFRA: '인프라',
  CENTERS: '창고',
  DIFF: '타창고',
  SALESCHANNEL: '판매채널',
};
const tanslatorMaster = {
  PARTNERS: 'SELLER',
  INFRA: 'INFRA',
  CENTERS: 'CENTER',
  DIFF: 'CENTER_DIFF',
  SALESCHANNEL: 'SALES_CHANNEL',
};

const tanslatorSeqs = {
  PARTNERS: 'partnerSeqs',
  INFRA: 'infraSeqs',
  CENTERS: 'centerCodes',
  DIFF: 'diffCenterCodes',
  SALESCHANNEL: 'salesChannelSeqs',
};

export interface IAccessSearch extends AccessCenterSearchDTO, AccessDiffCenterSearchDTO, AccessFrontSearchDTO, AccessUserSearchDTO, AccessSalesChannelSearchDTO, AccessInfraSearchDTO {}

export const SearchBox = (props: ISearchBoxProps<IAccessSearch>) => {
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList, TARGET } = props;
  console.log(reduxUserInfo);
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      {TARGET === 'USERS' ? (
        <>
          <Row>
            <Col>
              <label className="col-form-label">소속 조직</label>
              <SelectD
                isDisabled={reduxUserInfo?.userCode !== 'ROOT'}
                options={MASTER_OPS?.JOJIK_GROUP}
                value={
                  searchObj?.groupSeq
                    ? {
                        label: MASTER_OBJ?.['JOJIK_GROUP']?.[searchObj?.groupSeq],
                        value: searchObj?.groupSeq,
                      }
                    : null
                }
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      groupSeq: (option as OptionItem)?.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">소속 파트너</label>
              <SelectD
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                value={
                  searchObj?.partnerSeq
                    ? {
                        label: MASTER_OBJ?.['SELLER_SELLER_AUTH']?.[searchObj?.partnerSeq],
                        value: searchObj?.partnerSeq,
                      }
                    : null
                }
                onChange={({ value }) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      partnerSeq: value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">소속 센터</label>
              <SelectD
                options={MASTER_OPS?.CENTER_AUTH}
                value={
                  searchObj?.centerCode
                    ? {
                        label: MASTER_OBJ?.['CENTER_AUTH']?.[searchObj?.centerCode],
                        value: searchObj?.centerCode,
                      }
                    : null
                }
                onChange={({ value }) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      centerCode: value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">이름</label>
              <InputD
                value={searchObj?.userName || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    userName: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">아이디</label>
              <InputD
                value={searchObj?.userId || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    userId: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">이메일</label>
              <InputD
                value={searchObj?.email || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    email: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">연락처</label>
              <InputD
                value={searchObj?.phoneNumber || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    phoneNumber: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">사용자 유형</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.USER_CODE}
                value={searchObj?.userCode?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.['USER_CODE']?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      userCode: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">사용자 상태값</label>
              <SelectD
                options={MASTER_OPS?.USE_FLAG}
                value={
                  searchObj?.useFlag
                    ? {
                        value: searchObj?.useFlag,
                        label: MASTER_OBJ?.USE_FLAG?.[searchObj?.useFlag + ''],
                      }
                    : null
                }
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      useFlag: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <button
                className="btn btn-search"
                onClick={(e) => {
                  e.preventDefault();
                  fetchList(searchObj);
                }}
              >
                조회
              </button>
            </Col>
            <Col />
            <Col />
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col>
              <label className="col-form-label">소속 조직</label>
              <SelectD
                isDisabled={reduxUserInfo?.userCode !== 'ROOT'}
                options={MASTER_OPS?.JOJIK_GROUP}
                value={
                  searchObj?.groupSeq
                    ? {
                        label: MASTER_OBJ?.['JOJIK_GROUP']?.[searchObj?.groupSeq],
                        value: searchObj?.groupSeq,
                      }
                    : null
                }
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      groupSeq: (option as OptionItem)?.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">권한그룹명</label>
              <InputD
                value={searchObj?.title || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    title: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">사용자 아이디</label>
              <InputD
                value={searchObj?.userId || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    userId: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">사용자 이름</label>
              <InputD
                value={searchObj?.userName || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    userName: e.target.value,
                  });
                }}
              />
            </Col>
            {TARGET !== 'SCREEN' && (
              <Col>
                <label className="col-form-label">{tanslatorKr[TARGET]}</label>
                <SelectD
                  isMulti
                  options={MASTER_OPS?.[tanslatorMaster[TARGET]]}
                  value={searchObj?.[tanslatorSeqs[TARGET]]?.map((ele) => {
                    return {
                      label: MASTER_OBJ?.[tanslatorMaster[TARGET]]?.[ele],
                      value: ele,
                    };
                  })}
                  onChange={(option) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        [tanslatorSeqs[TARGET]]: (option as OptionItem[])?.map((ele) => ele.value),
                      };
                    });
                  }}
                />
              </Col>
            )}
            <Col>
              <label className="col-form-label">사용자 상태값</label>
              <SelectD
                options={MASTER_OPS?.USE_FLAG}
                value={
                  searchObj?.useFlag
                    ? {
                        value: searchObj?.useFlag,
                        label: MASTER_OBJ?.USE_FLAG?.[searchObj?.useFlag + ''],
                      }
                    : null
                }
                onChange={(option) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      useFlag: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            {TARGET === 'SCREEN' && (
              <Col>
                <button
                  className="btn btn-search"
                  onClick={(e) => {
                    e.preventDefault();
                    fetchList(searchObj);
                  }}
                >
                  조회
                </button>
              </Col>
            )}
          </Row>
          {TARGET !== 'SCREEN' && (
            <Row>
              <Col style={{ textAlign: 'right' }}>
                <button
                  className="btn btn-search"
                  onClick={(e) => {
                    e.preventDefault();
                    fetchList(searchObj);
                  }}
                >
                  조회
                </button>
              </Col>
            </Row>
          )}
        </>
      )}
    </Form>
  );
};
