import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './userList';

// options
import { RootState } from 'redux/store';
import { nullingMASTER } from 'redux/services/menuSlice';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { TopTabController } from 'components/template/TopTabController';

export const USER_MASTER = [
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  'SELLER_AUTH',
  'INFRA',
  //
  'MENU_WHOLE',
  'SCREEN_WHOLE',
  'TAB_WHOLE',
  'FUNCTION_WHOLE',

  'USER_CODE',
  'SALES_CHANNEL_TYPE',
  'USER_GROUP',
  'USE_FLAG',
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    await initMasterOptions(USER_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            USER: <Tab1 tabId={'USER'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
