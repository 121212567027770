// component
import { Invoices, SelectedInvoice } from '../trackInvoice';

// redux
import { useEffect, useState } from 'react';
import { httpClient } from 'common/http-client/axiosConfig';
import { TRAKING_V2 } from 'envVar';
import { OrderTrackingDTO } from 'interface/order';
import { useLocation } from 'react-router-dom';

const Container = () => {
  // options
  const [data, setData] = useState();
  const [invoices, setInvoices] = useState<OrderTrackingDTO[]>();
  const [selected, setSelected] = useState<OrderTrackingDTO>();
  const location = useLocation();
  useEffect(() => {
    const [isMobile, seq] = location?.search?.split('=');
    if (isMobile?.includes('p')) fetchMData(seq);
    else if (isMobile?.includes('e')) fetchData(seq);
  }, []);

  useEffect(() => {
    if (data) {
      setSelected(data);
    }
  }, [data]);

  const fetchMData = (seq) => {
    if (seq) {
      let url = TRAKING_V2 + `/m?p=${seq}`;
      httpClient.get(url).then((rs) => {
        if (rs?.status === 200) {
          setData(rs.data);
        }
      });
    }
  };

  const fetchData = (seq) => {
    if (seq) {
      let url = TRAKING_V2 + `?externalOrderNumber=${seq}`;
      httpClient.get(url).then((rs) => {
        if (rs?.status === 200) {
          setInvoices(rs.data);
        }
      });
    }
  };

  return (
    <div className="page-content">
      {invoices?.length > 0 && <Invoices invoices={invoices} setSelected={setSelected} />}
      <div className="page-content mobile">{selected && <SelectedInvoice selected={selected} />}</div>
    </div>
  );
};

export default Container;
