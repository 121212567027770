import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { InventoryModificationDefectedListDTO, InventoryModificationDefectedSearchDTO, InventoryModificationListDTO, InventoryModificationSearchDTO } from 'interface/warehouse';
import { inventoryService } from '../../_service/service';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { SearchBox } from '../_component/searchBox';
import { IPrintObj, ModificationPrint } from '../_component/print';
import { useIsMounted } from 'hooks/basichooks';
import { delay } from 'common/util/counting';
import { useLocation, useNavigate } from 'react-router-dom';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const [printObj, setPrintObj] = useState<IPrintObj>();
  const openPrintModal = (e: IGrid.ButtonClickEvent) => {
    setPrintObj({ visible: true, item: e.item });
  };
  const isMounted = useIsMounted();
  useEffect(() => {
    void delay(100).then(() => {
      if (isMounted) {
        gridRef.current.bind('cellEditEnd', bindCellEdit);
      }
    });
  }, [isMounted, tabId]);

  const bindCellEdit = (e: IGrid.CellEditEndEvent) => {
    if (e.dataField === 'note') {
      saveMemo(e);
    }
  };

  const saveMemo = async (e: IGrid.CellEditEndEvent) => {
    if (window.confirm('입력한 비고를 저장하시겠습니까?')) {
      dispatch(setLoading('POST'));
      if (e.value) {
        const seq = tabId === 'MODIFICATION_AVA' ? 'inventoryModificationSeq' : 'inventoryModificationDefectedSeq';
        const action = `modification/${tabId === 'MODIFICATION_AVA' ? '' : 'defected/'}note`;
        const dataDTO = {
          [seq]: e.item?.[seq],
          note: e.value,
        };
        const rs = await inventoryService.postInventoryAction(action, dataDTO, null, 'U');
        if (rs?.status === 200) {
          alert('비고가 수정되었습니다!');
        }
      }
    }
    const searchObj = JSON.parse(e.item.searchObj);
    wrappedFetchList(searchObj);
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '프린트',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '프린트',
        onClick: openPrintModal,
      },
    },
    {
      headerText: '조정번호',
      dataField: 'seq',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '제품정보',
      children: [
        {
          dataField: 'partnerSeqKr',
          headerText: '판매사',
        },
        {
          dataField: 'model',
          headerText: '제품',
        },
        {
          dataField: 'modelName',
          headerText: '제품명',
        },
        {
          dataField: 'modelStockSeq',
          headerText: 'SKU',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          dataField: 'barcode',
          headerText: '바코드',
        },
      ],
    },
    {
      headerText: '수량',
      dataField: 'quantity',
    },
    {
      headerText: '변경전 상태값<br />(상태-유형)',
      dataField: 'beforeAvailableStatusKr',
    },
    {
      headerText: '변경후 상태값<br />(상태-유형)',
      dataField: 'afterAvailableStatusKr',
    },
    {
      headerText: '조정사유',
      dataField: 'reason',
      width: '20%',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '비고',
      dataField: 'note',
      width: 200,
      style: 'aui-textinputer',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
      },
      headerTooltip: {
        show: true,
        tooltipHtml: `<div style="width:50px;">
            <div> 수정 가능</div>
          </div>`,
      },
    },
    {
      headerText: '조정요청자',
      dataField: 'modifier',
    },
    {
      headerText: '조정일시',
      dataField: 'requestDatetime',
    },
  ];

  const labellingKr = (data: PagingListDTO<InventoryModificationListDTO & InventoryModificationDefectedListDTO & InventoryModificationListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        tabId,
        seq: tabId === 'MODIFICATION_AVA' ? row.inventoryModificationSeq : row.inventoryModificationDefectedSeq,
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        availableStatusKr: selectlabel(row.availableStatus, masterOptions?.STATUS),
        beforeAvailableStatusKr:
          row.beforeAvailableStatus && row.beforeAvailableType
            ? selectlabel(row.beforeAvailableStatus, masterOptions?.INVA_STATUS) + '-' + selectlabel(row.beforeAvailableType, masterOptions?.INVA_TYPE)
            : '',
        afterAvailableStatusKr:
          row.afterAvailableStatus && row.afterAvailableType
            ? selectlabel(row.afterAvailableStatus, masterOptions?.INVA_STATUS) + '-' + selectlabel(row.afterAvailableType, masterOptions?.INVA_TYPE)
            : '',
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryModificationSearchDTO & InventoryModificationDefectedSearchDTO) => {
    dispatch(setLoading('GET'));
    const url = `modification/${tabId === 'MODIFICATION_AVA' ? '' : 'defected/'}list/paging`;
    const dataKr = labellingKr(
      (await serviceStore.warehouseAction(`inventory/${url}`, 'GET', searchObj, null, true))?.data as PagingListDTO<InventoryModificationDefectedListDTO & InventoryModificationListDTO>,
    );
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const url = `modification/${tabId === 'MODIFICATION_AVA' ? '' : 'defected/'}list`;
    const dataKr = labellingKr({ list: (await serviceStore.warehouseAction(`inventory/${url}`, 'GET', searchObj, null))?.data as InventoryModificationListDTO[] }, true);
    excelGridRef.current.setGridData(dataKr.list);
    excelGridRef.current.exportAsXlsx({ fileName: tabId === 'MODIFICATION_AVA' ? '양품재고조정' : '불용재고조정' });
    dispatch(setLoading(null));
  };

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  const gridButtonhandler = (e) => {
    const id = e.target?.id;
    if (!id) return;
    if (id === 'INVENTORYMODIFICATION' || id === 'INVENTORYMODIFICATIONDEFECTED') {
      currentDesignType === 1
        ? history(tabId === 'MODIFICATION_AVA' ? '/main/modifications/modificationAvaRegit' : '/main/modifications/modificationDefRegit')
        : history(tabId === 'MODIFICATION_AVA' ? '/modificationAvaRegit' : '/modificationDefRegit');
    } else if (id?.includes('EXCEL')) {
      downloadExcel('main');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj, tabId]);

  useEffect(() => {
    gridRef?.current?.clearGridData();
    excelGridRef?.current?.clearGridData();
  }, [tabId, location.pathname]);

  return (
    <div className="page-content">
      {printObj?.visible && <ModificationPrint printObj={printObj} setPrintObj={setPrintObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} TARGET={tabId} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: false,
            fixedColumnCount: 1,
            showRowCheckColumn: false,
            editable: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
