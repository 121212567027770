import { useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';

import Container1 from './systemAnnouncement';
import { centerListForlabeling, codeMasterOptionReturn } from 'common/master/codeMasterOptionReturn';

import { setLoading, setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { TopTab } from 'components/template/topTabNew';

const Index = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [CENTER_WHOLE, ANNOUNCE_CATEGORY, ANNOUNCE_TYPE] = await Promise.all([centerListForlabeling(), codeMasterOptionReturn('NOTICE_CATEGORY'), codeMasterOptionReturn('NOTICE_TYPE')]);

    dispatch(
      setMasterOptions({
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        ANNOUNCE_CATEGORY,
        ANNOUNCE_TYPE,
      }),
    );
    dispatch(setLoading(null));
  };

  return <>{masterOptions && <TopTab titles={['시스템 공지사항']} containers={[<Container1 />]} />}</>;
};

export default Index;
