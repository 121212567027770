import { LocalTime } from 'interface/util';

export const defaultCenterObj = {
  centerCode: null,
  useFlag: true,
  centerType: null,
  cutoffFlagForFcPicking: true,
  cutoffFlagForLinePicking: true,
  cutoffFlagForRemovePicking: true,
  cutoffTimeForLinePicking: '12:00' as LocalTime,
  cutoffTimeForFcPicking: '12:00' as LocalTime,
  cutoffTimeForRemovePicking: '12:00' as LocalTime,
  mondayFlagForFcPicking: true,
  tuesdayFlagForFcPicking: true,
  wednesdayFlagForFcPicking: true,
  thursdayFlagForFcPicking: true,
  fridayFlagForFcPicking: true,
  saturdayFlagForFcPicking: true,
  //
  mondayFlagForLinePicking: true,
  tuesdayFlagForLinePicking: true,
  wednesdayFlagForLinePicking: true,
  thursdayFlagForLinePicking: true,
  fridayFlagForLinePicking: true,
  saturdayFlagForLinePicking: true,
  //
  mondayFlagForRemovePicking: true,
  tuesdayFlagForRemovePicking: true,
  wednesdayFlagForRemovePicking: true,
  thursdayFlagForRemovePicking: true,
  fridayFlagForRemovePicking: true,
  saturdayFlagForRemovePicking: true,
};
