import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// component
import Container1 from './1menu';
import Container2 from './2screen';
import Container3 from './3tab';
import Container4 from './4function';
import Container5 from './5grid';

// options
import { RootState } from 'redux/store';
import { nullingMASTER } from 'redux/services/menuSlice';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { TopTabController } from 'components/template/TopTabController';



export const SETTINGSLIST_MASTER = 
[
      //
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      //
      'MENU_WHOLE',
      'SCREEN_WHOLE',
      'TAB_WHOLE',
      'FUNCTION_WHOLE',

      'COLOR',
      'FUNCTION_TYPE',
      'USE_FLAG',
      //
      {
        GRID_WHICH: [
          { value: 'main', label: '메인' },
          { value: 'detail', label: '상세' },
        ],
      },
      {
        SEARCH_MENUNAME: [
          { value: 'parentParentMenuName', label: '대메뉴명' },
          { value: 'parentMenuName', label: '중메뉴명' },
          { value: 'menuName', label: '소메뉴명' },
        ],
      },
      {
        SEARCH_MENUID: [
          { value: 'parentParentMenuId', label: '대메뉴명' },
          { value: 'parentMenuId', label: '중메뉴명' },
          { value: 'menuId', label: '소메뉴명' },
        ],
      },
      {
        MENU_LEVEL: [
          { value: 1, label: '대메뉴' },
          { value: 2, label: '중메뉴' },
          { value: 3, label: '소메뉴' },
        ],
      },
    ]

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    await initMasterOptions(SETTINGSLIST_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            SETTINGS_LIST_MENU: <Container1 />,
            SETTINGS_LIST_SCREEN: <Container2 />,
            SETTINGS_LIST_TABS: <Container3 />,
            SETTINGS_LIST_FUNCTIONS: <Container4 />,
            SETTINGS_LIST_GRID: <Container5 />,
          }}
        />
      )}
    </>
  );
};

export default Index;
