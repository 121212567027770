import { useEffect, useRef, useState } from 'react';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { addMASTER, setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { UserGroupDTO, UserGroupSearchDTO } from 'interface/user';

// utils
import { BottomSheets, IBottomSheetObj, defaultBottomSheetObj } from 'components/template/bottomSheets';
import { PagingListDTO } from 'interface/util';
import { SearchBox } from './component/searchBox';
import { IUserInGroup, AddUser } from './component/addUser';
import { IMenuModalObj, MenuModal } from './component/modiMenuModal';
import { AddGroup, IAddGroup } from './component/addGroup';
import { serviceStore } from 'services/services';

const Index = () => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<IPagingGrid>();
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>(defaultBottomSheetObj);
  const [addUserObj, setAddUserObj] = useState<IAddGroup>();
  const [menuObj, setMenuObj] = useState<IMenuModalObj>();

  const showGroupInfo = async (e: IGrid.ButtonClickEvent) => {
    setMenuObj({
      visible: true,
      type: e?.dataField === 'updateUserMenu' ? 'USER' : 'GROUP',
      searchObj,
      fetchFn: (searchObj) => wrappedFetchList(searchObj),
      item: e.item,
    });
  };

  useEffect(() => {
    addMasterOptions();
  }, []);

  const addMasterOptions = async () => {
    if (!MASTER_OBJ?.SCREENID_URL) {
      dispatch(
        addMASTER({
          SCREENID_URL: MASTER_OPS?.SCREEN_WHOLE?.map((ele) => {
            return {
              value: ele.screenId,
              label: ele.url,
            };
          }),
          TAB_NAME: MASTER_OPS?.TAB_WHOLE?.map((ele) => {
            return {
              value: ele.tabId,
              label: ele.tabName,
            };
          }),
        }),
      );
    }
  };

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '권한',
      dataField: 'updateUserMenu',
      width: 70,
      renderer: {
        labelText: '수정',
        type: IGrid.RendererKind.ButtonRenderer,
        visibleFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          if (item?.userGroupId === 'ADMIN') return false;
          return true;
        },
        onClick: showGroupInfo,
      },
    },
    {
      headerText: '사용자 아이디',
      dataField: 'userId',
    },
    {
      headerText: '사용자 이름',
      dataField: 'userName',
    },
    {
      headerText: '이메일',
      dataField: 'email',
    },
    {
      headerText: '연락처',
      dataField: 'userPhone',
    },
    {
      headerText: '소속파트너',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '소속창고',
      dataField: 'centerCode',
      visible: false,
    },
    {
      headerText: '소속창고',
      dataField: 'centerName',
    },
  ];
  const [userInGroupObj, setUserInGroupObj] = useState<IUserInGroup>();

  const deleteUserInGroup = async (items) => {
    dispatch(setLoading('DELETE'));
    const data = items?.map((ele) => {
      return {
        userId: ele.userId,
      };
    });
    const rs = await serviceStore?.userAction(`authority/userGroupUser/${items[0].userGroupId}`, 'DELETE', null, data);
    if (rs?.status === 200) {
      alert(`${items.length === 1 ? items[0].userName : items[0].userName + '외 ' + items.length + '명'}이 그룹에서 삭제되었습니다`);
      showGroupUser(JSON.parse(items[0]?.e));
    }
    dispatch(setLoading(null));
  };

  const showGroupUser = async (eG: IGrid.ButtonClickEvent) => {
    const gridButtonhandler = (e) => {
      const id = e.target.id;
      const items = bottomGridRef.current.getCheckedRowItemsAll();
      if (id === 'excel-down') {
        bottomGridRef.current.exportAsXlsx({ fileName: `그룹 사용자 상세` });
      } else if (id === 'user_add') {
        setUserInGroupObj({
          visible: true,
          thisGroup: eG.item,
          groupName: eG.item.userGroupName,
          searchObj,
          e: eG,
          fetchFn: (searchObj) => wrappedFetchList(searchObj),
          detailFetchFn: (e) => showGroupUser(e),
        });
      } else if (id === 'user_delete') {
        if (items?.length > 0) {
          //
          deleteUserInGroup(items);
        } else {
          alert('선택한 건이 없습니다!');
        }
      }
    };
    const data = (await serviceStore?.userAction(`authority/userGroupUser/${eG.item.userGroupId}`, 'GET', null))?.data;
    if (data) {
      const rows = data?.map((row) => {
        return {
          ...row,
          e: JSON.stringify(eG),
          searchObj,
          userPhone: row.phoneNumber,
          centerName: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
          update: row.userGroupId === 'ADMIN' ? '' : '수정',
          partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        };
      });
      setBottomSheetObj({
        visible: true,
        hasTabs: [
          {
            title: `[${rows[0]?.userGroupName || eG.item.userGroupName}]그룹 사용자 상세`,
            gridRef: bottomGridRef,
            columns: detailColumns,
            rows,
            buttons: [
              <div key={`user_add`} id={`user_add`} className={`blue`} onClick={gridButtonhandler}>
                사용자 추가
              </div>,
              <div key={`user_delete`} id={`user_delete`} className={`red`} onClick={gridButtonhandler}>
                사용자 삭제
              </div>,
              <div key={`excel-down`} id={`excel-down`} className={`green`} onClick={gridButtonhandler}>
                엑셀다운
              </div>,
            ],
            options: {
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              editable: true,
            },
          },
        ],
      });
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '그룹아이디',
      dataField: 'userGroupId',
    },
    {
      headerText: '그룹명',
      dataField: 'userGroupName',
    },
    {
      headerText: '그룹 권한',
      dataField: 'update',
      width: 70,
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: showGroupInfo,
      },
    },
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '설명',
      dataField: 'description',
    },
    {
      headerText: '소속 사용자',
      dataField: 'userGroupUser',
      width: 70,
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: showGroupUser,
      },
    },
    {
      headerText: '등록일시',
      dataField: 'registerDatetime',
      width: 150,
    },
  ];

  const labellingKr = (data: PagingListDTO<UserGroupDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        infraSeqKr: row.infraSeq?.map((e) => MASTER_OBJ?.INFRA?.[e])?.join(', ') || '전체',
        update: row.userGroupId && row.userGroupId === 'ADMIN' ? '' : '수정',
        userGroupUser: '보기',
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: UserGroupSearchDTO) => {
    dispatch(setLoading('GET'));
    setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore?.userAction('authority/userGroup', 'GET', searchObj, null, true))?.data as PagingListDTO<UserGroupDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const deleteGroup = async (items) => {
    if (window.confirm('삭제하시겠습니까?')) {
      const data = items?.map((ele) => ele.userGroupId);
      const rs = await serviceStore.userAction(`authority/userGroup`, 'DELETE', null, data);
      if (rs?.status === 200) {
        alert('삭제되었습니다');
        wrappedFetchList(searchObj);
      }
    }
  };

  const handleCheckItems = (e) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      const id = e.target.id;
      if (id === 'modi-group') {
        if (items?.length > 1) {
          alert('하나씩 수정가능합니다');
        } else {
          setAddUserObj({
            visible: true,
            item: items[0],
            searchObj,
            fetchFn: (searchObj) => wrappedFetchList(searchObj),
          });
        }
      } else if (id === 'delete-group') {
        //
        deleteGroup(items);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 400,
    },
    gridRef,
    fetchPaging,
  });

  return (
    <div className="page-content">
      {addUserObj?.visible && <AddGroup addGroupObj={addUserObj} setAddGroupObj={setAddUserObj} />}
      {userInGroupObj?.visible && <AddUser userInGroupObj={userInGroupObj} setUserInGroupObj={setUserInGroupObj} />}
      {menuObj?.visible && <MenuModal menuObj={menuObj} setMenuObj={setMenuObj} />}
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area space-between">
          <div>
            <div
              className="blue"
              onClick={() => {
                setAddUserObj({
                  visible: true,
                  item: null,
                  searchObj,
                  fetchFn: (searchObj) => wrappedFetchList(searchObj),
                });
              }}
            >
              권한그룹 추가
            </div>
            <div className="orange" id="modi-group" onClick={handleCheckItems}>
              권한그룹 수정
            </div>
            <div className="red" id="delete-group" onClick={handleCheckItems}>
              권한그룹 삭제
            </div>
          </div>
          <div>
            {/* <div
              className="green"
              onClick={() => {
                //
                setMenuExcelObj({ visible: true });
              }}
            >
              권한그룹 엑셀
            </div> */}
          </div>
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 0,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            rowCheckDisabledFunction(rowIndex, isChecked, item) {
              if (item?.userGroupId === 'ADMIN') return false;
              return true;
            },
          }}
        />
      </div>
    </div>
  );
};

export default Index;
