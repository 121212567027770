import { ReactNode, useMemo, useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';

// utils
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
// service
import { InventoryCheckDTO, InventoryCheckSearchDTO } from '../../../_interface/warehouse';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const bottomGridRef = useRef<IPagingGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const showDetail = async (e: IGrid.ButtonClickEvent) => {
    const data = (await serviceStore.warehouseAction(`inventory/check/detail?inventoryCheckNumber=${e.item?.inventoryCheckNumber}`, 'GET', null))?.data;
    console.log(data);
    if (data?.length > 0) {
      const rows = data?.map((row) => {
        return {
          ...row,
          centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
          partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        };
      });
      setBottomSheetObj({
        visible: true,
        hasTabs: [
          {
            title: '재고조사 상세',
            gridRef: bottomGridRef,
            columns: detailColumns,
            rows,
            options: {
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              editable: true,
            },
          },
        ],
      });
    } else {
      alert('조회되는 정보가 없습니다!');
    }
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: showDetail,
      },
    },
    {
      headerText: '작업지시번호',
      dataField: 'inventoryCheckNumber',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '작업자',
      dataField: 'registerId',
    },
    {
      headerText: '수량',
      children: [
        {
          headerText: '전산수량',
          dataField: 'systemQuantity',
        },
        {
          headerText: '실사수량',
          dataField: 'physicalQuantity',
        },
      ],
    },
    {
      headerText: '완료일시',
      dataField: 'registerDatetime',
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '작업지시번호',
      dataField: 'inventoryCheckNumber',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '작업자',
      dataField: 'registerId',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
    },
    {
      headerText: '수량',
      children: [
        {
          headerText: '전산수량',
          dataField: 'systemQuantity',
          styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
            if (item?.systemQuantity > item?.physicalQuantity) {
              return 'text-color-blue';
            } else if (item?.systemQuantity < item?.physicalQuantity) {
              return 'text-color-red';
            } else {
              return 'text-color-black';
            }
          },
        },
        {
          headerText: '실사수량',
          dataField: 'physicalQuantity',
          styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
            if (item?.systemQuantity > item?.physicalQuantity) {
              return 'text-color-blue';
            } else if (item?.systemQuantity < item?.physicalQuantity) {
              return 'text-color-red';
            } else {
              return 'text-color-black';
            }
          },
        },
      ],
    },
    {
      headerText: '완료일시',
      dataField: 'registerDatetime',
    },
  ];

  const labellingKr = (data: PagingListDTO<InventoryCheckDTO & { partnerSeq: number }>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryCheckSearchDTO) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore.warehouseAction(`inventory/check/paging`, 'POST', null, searchObj, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: (await serviceStore.warehouseAction(`inventory/check/${type === 'main' ? 'excel' : 'detail/excel'}`, 'POST', null, searchObj))?.data }, true);
    excelGridRef?.current?.changeColumnLayout(type === 'main' ? columns : detailColumns);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `재고조사(${type === 'main' ? '메인' : '상세'})` });
    dispatch(setLoading(null));
  };

  const handleCheckItems = (e) => {
    const id = e.target.id;
    if (id?.includes('EXCEL')) {
      downloadExcel(id?.includes('MAIN') ? 'main' : 'detail');
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, handleCheckItems);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}

      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
