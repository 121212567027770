import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Tab1 from './1receipt';
import Tab2 from './2inOurStorage';
import Tab3 from './5recalculateReceipt';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';

export const ORDERRECEIPT_MASTER = [
  //
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  'DRIVER_PHONE_WHOLE',
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  'INFRA',
  'SALES_CHANNEL',
  //
  'OR_STATUS',

  'INVOICE_STATUS',
  'FEE_TYPE',
  'ADJUSTMENT_TYPE',
  'ORDER_TYPE',
  'CANCEL_REASON',

  'LOGISTIC_TYPE',
  'WORK_TYPE',
  'PALLET_TYPE',
  'DATE_UNIT',
  'STORAGE_TYPE',
  'LOCATION_SIZE',
  'LOCATION_CATEGORY',
  'MONTH',
  {
    SEARCH_ORDER: [
      {
        label: '주문번호',
        value: 'orderSeq',
      },
      {
        label: '외부주문번호',
        value: 'externalOrderNumber',
      },
    ],
  },
  {
    SEARCH_INVOICE: [
      {
        label: '송장번호',
        value: 'invoiceSeq',
      },
      {
        label: '외부송장번호',
        value: 'externalInvoiceNumber',
      },
    ],
  },
  {
    SEARCH_DATE: [
      {
        label: '고객주문일',
        value: 'OrderDate',
      },
      {
        label: '등록일',
        value: 'RegisterDate',
      },
      {
        label: '희망일',
        value: 'PromiseDeliveryDate',
      },
      {
        label: '지정일',
        value: 'DeliveryDate',
      },
      {
        label: '완료일/취소일',
        value: 'FinishOrCancelDate',
      },
    ],
  },
  //
  'SEARCH_MODEL',
  'OX_FLAG',
];

export const receiptCategoryChanger = {
  OrderReceiptheadquaters: 'HEADQUARTERS',
  OrderReceiptSubcontract: 'SUBCONTRACT',
  OrderReceiptCenter: 'CENTER',
  OrderReceiptDriverteam: 'DRIVER',
};

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(ORDERRECEIPT_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            ORDER_RECEIPT_TAB1: <Tab1 />,
            ORDER_RECEIPT_TAB1_CENTER: <Tab1 />,
            ORDER_RECEIPT_TAB1_DRIVER: <Tab1 />,
            ORDER_RECEIPT_TAB1_SUBCONTRACT: <Tab1 />,
            //
            ORDER_RECEIPT_IN_CENTER: <Tab2 tabId={'ORDER_RECEIPT_IN_CENTER'} />,
            ORDER_RECEIPT_OUT_CENTER: <Tab2 tabId={'ORDER_RECEIPT_OUT_CENTER'} />,
            ORDER_RECEIPT_STORAGE_CENTER: <Tab2 tabId={'ORDER_RECEIPT_STORAGE_CENTER'} />,
            //
            ORDER_RECEIPT_TAB2: <Tab3 />,
            ORDER_RECEIPT_TAB2_CENTER: <Tab3 />,
            ORDER_RECEIPT_TAB2_DRIVER: <Tab3 />,
            ORDER_RECEIPT_TAB2_SUBCONTRACT: <Tab3 />,
          }}
        />
      )}
    </>
  );
};

export default Index;
