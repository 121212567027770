import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// redux
import { Button, Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import useDebounce from 'hooks/debounceHooks';

import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { PurchaseOrderSaveDTO } from 'interface/warehouse';

import * as IGrid from 'aui-grid';

import { IModelObj, ModelPopup } from './component/model';
import { useLocation, useNavigate } from 'react-router-dom';

import queryString from 'query-string';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { PagingListDTO } from 'interface/util';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { InvoiceInventoryPurchaseDTO, InvoiceInventoryPurchaseSearchDTO } from 'interface/order';
import { IinvoicesObj, InvoicesPopup } from './component/invoices';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import { useIsMounted } from 'hooks/basichooks';
import { delay } from 'common/util/counting';
import { PURCHASE_REGIT_FORM } from 'envVar2';
import { serviceStore } from 'services/services';
import { generateUrlByDesignType } from 'components/LDS/utils/path';

const Index = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const history = useNavigate();
  const currentSeq = queryString.parse(location.search);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();

  const defaultSearchFilter = useMemo(() => {
    return {
      centerCode: reduxUserInfo['centerCode'], // 입고창고
      purchaseDate: returnDateyyyymmdd(new Date()), // 발주일
      promiseDate: returnDateyyyymmdd(new Date()), // 입고예정일
    };
  }, [reduxUserInfo]);

  const [postObj, setPostObj] = useState<PurchaseOrderSaveDTO>({ ...defaultSearchFilter, logisticType: '', workType: '', details: [] });
  const [modelObj, setModelObj] = useState<IModelObj>();
  const [invoicesObj, setInvoicesObj] = useState<IinvoicesObj>();

  useEffect(() => {
    if (currentSeq['invoiceSeq']) {
      wrappedFetchList(searchObj);
    }
  }, []);

  const isMounted = useIsMounted();
  useEffect(() => {
    void delay(100).then(() => {
      if (isMounted) {
        gridRef.current.bind('cellEditEnd', bindCellEdit);
      }
    });
  }, [isMounted]);

  const dupDataField = ['primePrice', 'purchaseQuantity', 'memo'];

  const bindCellEdit = (e: IGrid.CellEditEndEvent) => {
    if (dupDataField?.includes(e.dataField)) {
      const items = gridRef?.current?.getGridData();
      const rows = items?.map((row) => {
        return {
          ...row,
          [e.dataField]: row?.partnerSeq === e.item?.partnerSeq && row?.modelStockSeq === e.item?.modelStockSeq ? e.value : row?.[e.dataField],
        };
      });
      gridRef?.current?.setGridData(rows);
    }
  };

  const sortingSystem = (returns, isDel = false) => {
    // mtoFlag === true 인 경우: 시리얼번호
    // mtoFlag === false 인 경우: modelStockSeq
    const rows = gridRef.current.getGridData();
    const beforeSkus = rows?.map((ele) => ele.modelStockSeq);
    const mtos = [];
    const noMtos = [];
    rows?.forEach((ele) => {
      if (ele.mtoFlag) mtos.push(ele.serialNumber);
      else noMtos?.push(ele.modelStockSeq);
    });
    let dupMto = false;
    let dupNoMto = false;

    const adding = [];
    returns?.forEach((ele) => {
      if (ele.mtoFlag) {
        if (mtos?.includes(ele.serialNumber)) dupMto = true;
        else adding.push(ele);
      } else {
        if (noMtos?.includes(ele.modelStockSeq)) dupNoMto = true;
        else adding.push(ele);
      }
    });
    const added = adding?.length > 0 ? [...rows, ...adding] : rows;
    const skus = [];
    const obj = {};
    const results = [];
    added?.forEach((row, idx) => {
      //
      if (beforeSkus?.includes(row.modelStockSeq)) {
        if (skus?.includes(row?.modelStockSeq)) {
          if (row.mtoFlag) results.push(row);
        } else {
          obj[row?.modelStockSeq] = row?.tempQunatity;
          results.push(row);
        }
      } else {
        // 새로 추가된 것들
        if (skus?.includes(row?.modelStockSeq)) {
          if (row.mtoFlag) {
            results.push(row);
            obj[row?.modelStockSeq] += 1;
          } else {
            obj[row?.modelStockSeq] += Number(row.purchaseQuantity) || 1;
          }
        } else {
          if (row.mtoFlag) obj[row?.modelStockSeq] = 1;
          else obj[row?.modelStockSeq] = Number(row.purchaseQuantity) || 0;
          skus?.push(row?.modelStockSeq);
          results.push(row);
        }
      }
    });
    gridRef.current.setGridData(
      results?.map((row) => {
        return {
          ...row,
          purchaseQuantity: obj[row?.modelStockSeq],
          tempQunatity: obj[row?.modelStockSeq],
          orderSeq: row?.mtoFlag ? row.orderSeq : '-',
          invoiceSeq: row?.mtoFlag ? row.invoiceSeq : '-',
          customerName: row?.mtoFlag ? row.customerName : '-',
        };
      }),
    );

    if (isDel) {
      // 삭제시 mute
    } else if (adding?.length > 0) {
      alert(`${dupMto ? '중복 시리얼 ' : ''}${dupNoMto && dupMto ? '/중복 SKU을' : dupNoMto ? '중복 SKU을' : ''} ${dupMto || dupNoMto ? '제외하고 ' : ''}추가되었습니다!`);
    } else alert(`중복건을 제외하고 추가할 건이 없습니다!`);
  };

  useEffect(() => {
    if (invoicesObj?.returns?.length > 0) {
      sortingSystem(invoicesObj?.returns, invoicesObj?.first);
      setInvoicesObj((prev) => {
        return {
          ...prev,
          returns: [],
          first: false,
        };
      });
    }
  }, [invoicesObj?.returns]);

  useEffect(() => {
    // mtoFlag === false 언제나
    if (modelObj?.returns?.length > 0) {
      sortingSystem(modelObj?.returns);
      setModelObj((prev) => {
        return {
          ...prev,
          returns: [],
        };
      });
    }
  }, [modelObj?.returns]);

  const labellingKr = (data: PagingListDTO<InvoiceInventoryPurchaseDTO>) => {
    const returns = data?.list?.map((row) => {
      return {
        ...row,
        mtoFlagKr: row.mtoFlag ? 'O' : 'X',
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        supplierPartnerSeqKr: selectlabel(row.supplierPartnerSeq, masterOptions?.SUPPLIER),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
        orderSeq: row?.mtoFlag ? row.orderSeq : '-',
        invoiceSeq: row?.mtoFlag ? row.invoiceSeq : '-',
        customerName: row?.mtoFlag ? row.customerName : '-',
        serialNumber: row?.mtoFlag ? row.serialNumber : '-',
        purchaseQuantity: 1,
      };
    });
    returns?.sort((a, b) => a.modelStockSeq - b.modelStockSeq);
    setInvoicesObj((prev) => {
      return {
        ...prev,
        returns,
        first: true,
      };
    });
  };

  const fetchPaging = async (searchObj: InvoiceInventoryPurchaseSearchDTO) => {
    dispatch(setLoading('GET'));
    labellingKr((await serviceStore.orderAction(`invoice/inventory/purchase/search`, 'POST', null, searchObj))?.data as PagingListDTO<InvoiceInventoryPurchaseDTO>);
    dispatch(setLoading(null));
    return [];
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      similaritySearchFlag: false,
      invoiceSeq: currentSeq['invoiceSeq'] ? (currentSeq['invoiceSeq'] as string)?.split(',')?.map((ele) => Number(ele)) : null,
    },
    gridRef,
    fetchPaging,
  });

  const columns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      mergeRef: 'modelStockSeq',
      cellMerge: true,
      mergePolicy: 'restrict',
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqKr',
      mergeRef: 'modelStockSeq',
      cellMerge: true,
      mergePolicy: 'restrict',
    },
    {
      headerText: '제품그룹',
      width: 100,
      dataField: 'modelGroupKr',
      mergeRef: 'modelStockSeq',
      cellMerge: true,
      mergePolicy: 'restrict',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
      mergeRef: 'modelStockSeq',
      cellMerge: true,
      mergePolicy: 'restrict',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
      mergeRef: 'modelStockSeq',
      cellMerge: true,
      mergePolicy: 'restrict',
    },
    {
      headerText: '제품',
      dataField: 'model',
      mergeRef: 'modelStockSeq',
      cellMerge: true,
      mergePolicy: 'restrict',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
      mergeRef: 'modelStockSeq',
      cellMerge: true,
      mergePolicy: 'restrict',
    },
    {
      headerText: '바코드',
      mergeRef: 'modelStockSeq',
      dataField: 'barcode',
      cellMerge: true,
      mergePolicy: 'restrict',
    },
    {
      headerText: 'MTO여부',
      dataField: 'mtoFlagKr',
      mergeRef: 'modelStockSeq',
      width: 70,
      cellMerge: true,
      mergePolicy: 'restrict',
    },
    {
      headerText: '원가(단가)',
      dataField: 'primePrice',
      mergeRef: 'modelStockSeq',
      dataType: 'numeric',
      width: 100,
      cellMerge: true,
      mergePolicy: 'restrict',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        onlyNumeric: true,
        autoThousandSeparator: true,
        allowNegative: false,
      },
    },
    {
      headerText: '*발주수량',
      dataField: 'purchaseQuantity',
      mergeRef: 'modelStockSeq',
      cellMerge: true,
      width: 70,
      mergePolicy: 'restrict',
      editable: true,
      headerTooltip: {
        show: true,
        tooltipHtml: '<div style="width:140px;"><div>MTO여부가 O인 경우 수정불가</div></div>',
      },
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        validator: (oldValue, newValue, item, dataField, fromClipboard, which) => {
          if (item?.mtoFlag) return { validate: false, message: '수정할 수 없습니다' };
        },
        autoThousandSeparator: true,
        onlyNumeric: true,
        allowNegative: false,
      },
    },
    {
      headerText: '비고',
      dataField: 'memo',
      style: 'aui-textinputer',
      mergeRef: 'modelStockSeq',
      cellMerge: true,
      mergePolicy: 'restrict',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
      },
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '수령인명',
      dataField: 'customerName',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
      style: 'aui-textinputer',
      editable: true,
      headerTooltip: {
        show: true,
        tooltipHtml: '<div style="width:140px;"><div>MTO여부가 X인 경우 입력불가</div></div>',
      },
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        validator: (oldValue, newValue, item, dataField, fromClipboard, which) => {
          if (!item?.mtoFlag) return { validate: false, message: 'mto여부가 "O"인 경우만 입력가능합니다' };
        },
      },
    },
  ];

  const actionRegister = useCallback(
    useDebounce(async (dataDTO) => {
      dispatch(setLoading('POST'));
      const rs = await serviceStore?.warehouseAction(`purchase/save`, 'POST', null, dataDTO);
      if (rs?.status === 200) {
        alert('발주등록이 완료되었습니다!');
        history(generateUrlByDesignType('/purchaseOrderAdmin'));
      }
      dispatch(setLoading(null));
    }, 500),
    [],
  );

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'UPLOAD_FORM') {
      window.open(PURCHASE_REGIT_FORM);
    } else if (id === 'UPLOAD') {
      const items = gridRef?.current?.getGridData();
      if (items?.length > 0) {
        if (window.confirm('엑셀 업로드시 기존 항목들은 초기화됩니다. 진행하시겠습니까?')) uploaderClick(id);
      } else {
        uploaderClick(id);
      }
    } else if (id === 'GET_MODEL') {
      setModelObj({ visible: true });
    } else if (id === 'GET_INVOICE') {
      setInvoicesObj({ visible: true });
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItems();
    if (items?.length > 0) {
      const wholeRows = gridRef.current.getGridData();
      if (id === 'DEL_ROW') {
        const checked = items.map((ele) => ele.rowIndex);
        const rows = wholeRows?.filter((_, idx) => !checked?.includes(idx));
        gridRef?.current?.setGridData([]);
        sortingSystem(rows, true);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const validation = (postObj) => {
    const items = gridRef?.current?.getGridData();
    if (!postObj?.centerCode) {
      alert('입고창고는 필수값입니다');
      return null;
    }

    if (!postObj?.purchaseDate) {
      alert('발주일은 필수값입니다');
      return null;
    }

    if (!postObj?.promiseDate) {
      alert('입고예정일은 필수값입니다');
      return null;
    }

    if (!postObj?.logisticType) {
      alert('운송타입은 필수값입니다');
      return null;
    }

    const hasQunatity = items?.filter((ele) => ele.purchaseQuantity > 0);
    if (hasQunatity?.length !== items?.length) {
      alert('발주수량이 입력되지 않은 건이 있습니다');
      return null;
    }

    const details = [];
    items?.forEach((row) => {
      if (row.mtoFlag) {
        const detailsMSS = details?.map((ele) => ele.modelStockSeq);
        if (detailsMSS?.includes(row.modelStockSeq)) {
          const idx = detailsMSS?.indexOf(row.modelStockSeq);
          details?.[idx]?.serialNumberMtoList?.push({
            inventorySeq: row.inventorySeq,
            serialNumber: row.serialNumber,
            customerName: row.customerName,
            invoiceSeq: row.invoiceSeq,
            modelGroup: row.modelGroup,
          });
        } else {
          details.push({
            modelStockSeq: row.modelStockSeq,
            purchaseQuantity: row.purchaseQuantity,
            primePrice: row.primePrice,
            memo: row.memo,
            serialNumberMtoList: [
              {
                inventorySeq: row.inventorySeq,
                serialNumber: row.mtoFlag ? row.serialNumber : null,
                customerName: row.customerName,
                invoiceSeq: row.invoiceSeq,
                modelGroup: row.modelGroup,
              },
            ],
          });
        }
      } else {
        details.push({
          modelStockSeq: row.modelStockSeq,
          purchaseQuantity: row.purchaseQuantity,
          primePrice: row.primePrice,
          memo: row.memo,
        });
      }
    });

    const dataDTO = {
      ...postObj,
      details,
    };

    return dataDTO;
  };

  const uploadData = async (excelData) => {
    if (excelData?.filter((ele) => (ele.barcode || ele.modelName) && ele.partnerSeq)?.length !== excelData?.length) {
      alert('최소 필수값인 판매사와 바코드가 입력되지 않은 건이 있습니다. 엑셀파일을 확인해주세요!');
    } else {
      dispatch(setLoading('GET'));
      const findModel = await Promise.all(
        excelData?.map(async (row) =>
          row?.invoiceSeq
            ? (
                await serviceStore?.orderAction(`invoice/inventory/purchase/excel`, 'POST', null, {
                  partnerSeq: [row.partnerSeq],
                  invoiceSeq: row.invoiceSeq?.split(','),
                  barcode: [row.barcode],
                  modelName: [row.modelName],
                  similaritySearchFlag: true,
                })
              )?.data
            : (
                await serviceStore?.warehouseAction(
                  `model/stock/excel`,
                  'GET',
                  {
                    ...row,
                    mtoFlag: false,
                  },
                  null,
                )
              )?.data,
        ),
      );
      const rows = [];
      findModel.forEach((row, i) => {
        if (row?.length > 0) {
          row?.forEach((r) => {
            rows.push({
              ...r,
              purchaseQuantity: excelData[i]['purchaseQuantity'],
            });
          });
        }
      });
      gridRef.current.setGridData([]);
      labellingKr({ list: rows });

      dispatch(setLoading(null));
    }
  };

  const KRTOVALUE = {
    partnerSeq: 'SELLER',
  };

  const EXCELHEADER = {
    UPLOAD: ['partnerSeq', 'modelName', 'barcode', 'purchaseQuantity', 'invoiceSeq'],
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE });
  useEffect(() => {
    if (parsedData?.data?.length > 0) {
      uploadData(parsedData?.data);
    }
  }, [parsedData]);

  const useNewDesignFlag = JSON.parse(localStorage.getItem('useNewDesignFlag'));
  return (
    <>
      <div className="page-content">
        {invoicesObj?.visible && <InvoicesPopup invoicesObj={invoicesObj} setInvoicesObj={setInvoicesObj} />}
        {modelObj?.visible && <ModelPopup modelObj={modelObj} setModelObj={setModelObj} />}
        <Row style={{ textAlign: 'right' }}>
          <Col>
            <Button
              className="btn-blue btn"
              onClick={() => {
                if (window.confirm('발주등록을 진행하시겠습니까?')) {
                  const dataDTO = validation(postObj);
                  if (dataDTO) actionRegister(dataDTO);
                }
              }}
            >
              {useNewDesignFlag === 0 ? '발주등록' : '발주요청'}
            </Button>
          </Col>
        </Row>
        <div className="presenterInput">
          <p>1. 기본정보</p>
          <Row>
            <Col>
              <label className="col-form-label">외부발주번호</label>
              <InputD
                value={postObj?.externalPurchaseNumber || ''}
                onChange={(e) => {
                  setPostObj({
                    ...postObj,
                    externalPurchaseNumber: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*입고창고</label>
              <SelectD
                options={masterOptions?.CENTER_AUTH}
                value={
                  postObj?.centerCode
                    ? {
                        value: postObj?.centerCode,
                        label: selectlabel(postObj?.centerCode, masterOptions?.CENTER),
                      }
                    : null
                }
                onChange={(option) => {
                  const items = gridRef.current.getGridData();
                  if (postObj?.centerCode && (option as OptionItem).value !== postObj?.centerCode && items?.length > 0) {
                    if (window.confirm('창고 변경 시 입력한 정보가 초기화됩니다. 변경하시겠습니까?')) {
                      gridRef.current.setGridData([]);
                      setPostObj({
                        ...postObj,
                        centerCode: (option as OptionItem).value,
                      });
                    }
                  } else {
                    setPostObj({
                      ...postObj,
                      centerCode: (option as OptionItem).value,
                    });
                  }
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*발주일</label>
              <FlatpickrD
                options={{ mode: 'single' }}
                value={postObj?.purchaseDate}
                onChange={(date) => {
                  setPostObj({
                    ...postObj,
                    purchaseDate: returnDateyyyymmdd(date[0]),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*입고예정일</label>
              <FlatpickrD
                options={{ mode: 'single' }}
                value={postObj?.promiseDate}
                onChange={(date) => {
                  setPostObj({
                    ...postObj,
                    promiseDate: returnDateyyyymmdd(date[0]),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*운송타입</label>
              <SelectD
                value={
                  postObj?.logisticType
                    ? {
                        value: postObj?.logisticType,
                        label: selectlabel(postObj?.logisticType, masterOptions?.LOGISTIC_TYPE),
                      }
                    : null
                }
                options={masterOptions?.LOGISTIC_TYPE}
                onChange={(option) => {
                  setPostObj({
                    ...postObj,
                    logisticType: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*작업유형</label>
              <SelectD
                value={
                  postObj?.workType
                    ? {
                        value: postObj?.workType,
                        label: selectlabel(postObj?.workType, masterOptions?.WORK_TYPE),
                      }
                    : null
                }
                options={masterOptions?.WORK_TYPE}
                onChange={(option) => {
                  setPostObj({
                    ...postObj,
                    workType: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">특이사항</label>
              <InputD
                value={postObj?.memo || ''}
                onChange={(e) => {
                  setPostObj((prev) => {
                    return {
                      ...prev,
                      memo: e.target.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <div>
          <div className="presenterInput">
            <div className="presenterGridBox">
              <p>2. 상세 정보</p>
              <div className="grid-button-area">
                <ExcelDetector />
                <div id={`DEL_ROW`} className={`red`} onClick={gridButtonhandler}>
                  -삭제
                </div>
                <div id={`GET_MODEL`} className={`orange`} onClick={gridButtonhandler}>
                  제품등록
                </div>
                <div id={`GET_INVOICE`} className={`orange`} onClick={gridButtonhandler}>
                  송장 불러오기
                </div>
                <div className={`transparent`}>엑셀 업로드</div>
                <div id={`UPLOAD_FORM`} className={`green`} onClick={gridButtonhandler}>
                  양식
                </div>
                <div id={`UPLOAD`} className={`green`} onClick={gridButtonhandler}>
                  +등록
                </div>
              </div>
              <GridBox
                gridRef={gridRef}
                columns={columns}
                gridProps={{
                  showRowAllCheckBox: true,
                  showRowCheckColumn: true,
                  enableCellMerge: true,
                  cellMergePolicy: 'withNull',
                  editable: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
