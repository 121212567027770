import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';
import {
  OMS_FEE,
  OMS_RECEIPT_ADJUSTMENT_SAVE,
  OMS_RECEIPT_CALCULATE,
  OMS_RECEIPT_CREATE,
  OMS_RECEIPT_DETAIL_SAVE,
  OMS_RECEIPT_EXCEL,
  OMS_RECEIPT_PAGING,
  OMS_RECEIPT_RECALCULATE,
  OMS_RECEIPT_RECALCULATE_EXCEL,
  OMS_RECEIPT_RECALCULATE_PAGING,
  OMS_RECEIPT_RECALCULATE_TOTAL,
  OMS_RECEIPT_TOTAL,
  OMS_TRANSACTION_STATEMENT,
  WMS_FEE,
  WMS_RECEIPT,
  ZIPCODE_EXCEL,
} from 'envVar';
import { PagingListDTO } from 'interface/util';
import { ZipcodeDTO, ZipcodeSearchDTO } from 'interface/install';
import {
  OrderFeeCancelDTO,
  OrderFeeCancelSearchDTO,
  OrderFeeDateDTO,
  OrderFeeDateSearchDTO,
  OrderFeeDefaultDTO,
  OrderFeeDefaultSearchDTO,
  OrderFeeGroupDTO,
  OrderFeeGroupSearchDTO,
  OrderFeeModelDTO,
  OrderFeeModelSearchDTO,
  OrderFeeOptionDTO,
  OrderFeeOptionSearchDTO,
  OrderFeeProductDTO,
  OrderFeeProductSearchDTO,
  OrderFeeRegionDTO,
  OrderFeeRegionSearchDTO,
  OrderReceiptAdjustmentSaveDTO,
  OrderReceiptCreateDTO,
  OrderReceiptDTO,
  OrderReceiptSearchDTO,
  OrderReceiptSeqListDTO,
  TransactionStatementDTO,
  TransactionStatementSearchDTO,
} from 'interface/order';
import { ResponseDTO } from 'interface/user';
import {
  WarehouseFeeInDTO,
  WarehouseFeeInSearchDTO,
  WarehouseFeeOutDTO,
  WarehouseFeeOutSearchDTO,
  WarehouseFeeStorageDTO,
  WarehouseFeeStorageSearchDTO,
  WarehouseReceiptInDTO,
  WarehouseReceiptInSearchDTO,
  WarehouseReceiptOutDTO,
  WarehouseReceiptOutSearchDTO,
  WarehouseReceiptStorageDTO,
  WarehouseReceiptStorageSearchDTO,
} from '../_interface/warehouse';

import * as IGrid from 'aui-grid';
import { makeObjtoFooter } from 'common/util/grid';
export interface IFeeSearch {
  partnerSeq?: number;
  pageNo: number;
  pageSize: number;
}

export type OMS_TARGET = 'CANCEL' | 'DATE' | 'DEFAULT' | 'GROUP' | 'MODEL' | 'OPTION' | 'PRODUCT' | 'REGION';
export type WMS_TARGET = 'IN' | 'OUT' | 'STORAGE';
export type CATEGORY = 'HEADQUATERS' | 'SUBCONTRACT' | 'CENTER' | 'DRIVER';

const seqChanger = {
  in: 'warehouseReceiptInSeq',
  out: 'warehouseReceiptOutSeq',
  storage: 'warehouseReceiptStorageSeq',
};

class FeeService {
  public async getZipcode(searchDTO: ZipcodeSearchDTO): Promise<ZipcodeDTO[]> {
    let url = ZIPCODE_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getReceiptRecalculatePaging(searchDTO: OrderReceiptSearchDTO, category = 'HEADQUATERS'): Promise<PagingListDTO<OrderReceiptDTO>> {
    let url = OMS_RECEIPT_RECALCULATE_PAGING(category) + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getReceiptRecalculateTotal(searchDTO: OrderReceiptSearchDTO, category = 'HEADQUATERS'): Promise<IGrid.Footer[]> {
    let url = OMS_RECEIPT_RECALCULATE_TOTAL(category) + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    const footerData = {
      ...data,
    };
    const footer = makeObjtoFooter(footerData);
    return footer;
  }

  public async getReceiptTotal(searchDTO: OrderReceiptSearchDTO, category = 'HEADQUATERS'): Promise<IGrid.Footer[]> {
    let url = OMS_RECEIPT_TOTAL(category) + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    const footerData = {
      ...data,
    };
    const footer = makeObjtoFooter(footerData);
    return footer;
  }
  public async getReceiptPaging(searchDTO: OrderReceiptSearchDTO, category = 'HEADQUATERS'): Promise<PagingListDTO<OrderReceiptDTO>> {
    let url = OMS_RECEIPT_PAGING(category) + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getReceiptExcelMain(searchDTO: OrderReceiptSearchDTO, category = 'HEADQUATERS'): Promise<OrderReceiptDTO[]> {
    let url = OMS_RECEIPT_EXCEL(category) + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getReceiptRecalculateExcelMain(searchDTO: OrderReceiptSearchDTO, category = 'HEADQUATERS'): Promise<OrderReceiptDTO[]> {
    let url = OMS_RECEIPT_RECALCULATE_EXCEL(category) + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postReceiptCalculate(data: OrderReceiptSeqListDTO, category = 'HEADQUATERS'): Promise<ResponseDTO> {
    let url = OMS_RECEIPT_CALCULATE(category);
    const rs = await httpClient.post(url, data);
    return rs;
  }

  public async postCreateRecalculate(data: {}, category = 'HEADQUATERS'): Promise<ResponseDTO> {
    let url = OMS_RECEIPT_RECALCULATE(category);
    const rs = await httpClient.post(url, data);
    return rs;
  }

  public async postCreateRecalculateType(data: OrderReceiptSearchDTO | { orderReceiptSeqs: number[] }, category = 'HEADQUATERS', type: string): Promise<ResponseDTO> {
    let url = OMS_RECEIPT_CALCULATE(category) + `/${type}`;
    const rs = await httpClient.post(url, data);
    return rs;
  }
  public async postSaveReceipDetail(data: OrderReceiptDTO, category = 'HEADQUATERS'): Promise<ResponseDTO> {
    let url = OMS_RECEIPT_DETAIL_SAVE(category);
    const rs = await httpClient.post(url, data);
    return rs;
  }

  public async postCreateReceipt(data: OrderReceiptCreateDTO, category = 'HEADQUATERS'): Promise<ResponseDTO> {
    let url = OMS_RECEIPT_CREATE(category);
    const rs = await httpClient.post(url, data);
    return rs;
  }

  public async postAdjustmentSave(data: OrderReceiptAdjustmentSaveDTO, category = 'HEADQUATERS'): Promise<ResponseDTO> {
    let url = OMS_RECEIPT_ADJUSTMENT_SAVE(category);
    const rs = await httpClient.post(url, data);
    return rs;
  }

  // warehouse inoutstorage
  public async getWmsFeePaging(
    searchDTO: IFeeSearch,
    TARGET: WMS_TARGET,
    category: CATEGORY,
  ): //
  Promise<PagingListDTO<WarehouseFeeInDTO> | PagingListDTO<WarehouseFeeOutDTO> | PagingListDTO<WarehouseFeeStorageDTO>> {
    let url = WMS_FEE(category?.toLowerCase(), TARGET?.toLocaleLowerCase(), 'paging') + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getWmsFeeMainExcel(
    searchDTO: IFeeSearch,
    TARGET: WMS_TARGET,
    category: CATEGORY,
  ): //
  Promise<WarehouseFeeInDTO[] | WarehouseFeeOutDTO[] | WarehouseFeeStorageDTO[]> {
    let url = WMS_FEE(category?.toLowerCase(), TARGET?.toLocaleLowerCase(), 'excel') + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async saveWmsFeeAction(
    dataDTO: WarehouseFeeInSearchDTO[] | WarehouseFeeOutSearchDTO[] | WarehouseFeeStorageSearchDTO[],
    TARGET: WMS_TARGET,
    category: CATEGORY,
  ): //
  Promise<ResponseDTO> {
    let url = WMS_FEE(category?.toLowerCase(), TARGET?.toLocaleLowerCase(), 'save');
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  // order
  public async getOmsFeePaging(
    searchDTO: OrderFeeCancelSearchDTO &
      OrderFeeDateSearchDTO &
      OrderFeeDefaultSearchDTO &
      OrderFeeModelSearchDTO &
      OrderFeeGroupSearchDTO &
      OrderFeeOptionSearchDTO &
      OrderFeeProductSearchDTO &
      OrderFeeRegionSearchDTO,
    target: OMS_TARGET,
    category: CATEGORY,
  ): //
  Promise<PagingListDTO<OrderFeeCancelDTO & OrderFeeDateDTO & OrderFeeDefaultDTO & OrderFeeModelDTO & OrderFeeGroupDTO & OrderFeeOptionDTO & OrderFeeProductDTO & OrderFeeRegionDTO>> {
    let url = OMS_FEE(category?.toLowerCase(), target?.toLocaleLowerCase(), 'paging') + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getOmsFeeMainExcel(
    searchDTO: OrderFeeCancelSearchDTO &
      OrderFeeDateSearchDTO &
      OrderFeeDefaultSearchDTO &
      OrderFeeModelSearchDTO &
      OrderFeeGroupSearchDTO &
      OrderFeeOptionSearchDTO &
      OrderFeeProductSearchDTO &
      OrderFeeRegionSearchDTO,
    target: OMS_TARGET,
    category: CATEGORY,
  ): //
  Promise<OrderFeeCancelDTO[] | OrderFeeDateDTO[] | OrderFeeDefaultDTO[] | OrderFeeModelDTO[] | OrderFeeGroupDTO[] | OrderFeeOptionDTO[] | OrderFeeProductDTO[] | OrderFeeRegionDTO[]> {
    let url = OMS_FEE(category?.toLowerCase(), target?.toLocaleLowerCase(), 'excel') + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getOmsFeeAction(
    // dataDTO: WarehouseFeeInSearchDTO[] | WarehouseFeeOutSearchDTO[] | WarehouseFeeStorageSearchDTO[],
    target: OMS_TARGET,
    category: CATEGORY,
    action: string,
  ): //
  Promise<ResponseDTO> {
    let url = OMS_FEE(category?.toLowerCase(), target?.toLocaleLowerCase(), action);
    const rs = await httpClient.get(url);
    return rs;
  }

  public async postOmsFeeAction(
    dataDTO: OrderFeeCancelDTO[] | OrderFeeDateDTO[] | OrderFeeDefaultDTO[] | OrderFeeModelDTO[] | OrderFeeGroupDTO[] | OrderFeeOptionDTO[] | OrderFeeProductDTO[] | OrderFeeRegionDTO,
    target: OMS_TARGET,
    category: CATEGORY,
    action: string,
  ): //
  Promise<ResponseDTO> {
    let url = OMS_FEE(category?.toLowerCase(), target?.toLocaleLowerCase(), action);
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async deleteOmsFeeAction(
    dataDTO: { [key: string]: Array<number> },
    target: OMS_TARGET,
    category: CATEGORY,
  ): //
  Promise<ResponseDTO> {
    let url = OMS_FEE(category?.toLowerCase(), target?.toLocaleLowerCase(), 'delete');
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async deleteWmsFeeAction(
    dataDTO: Array<number>,
    target = 'in',
    category = 'HEADQUATERS',
  ): //
  Promise<ResponseDTO> {
    let url = OMS_FEE(category?.toLowerCase(), target, 'delete');
    const data = {};
    if (target === 'in') data['warehouseFeeInSeqs'] = dataDTO;
    else if (target === 'out') data['warehouseFeeOutSeqs'] = dataDTO;
    else if (target === 'storage') data['warehouseFeeStorageSeqs'] = dataDTO;

    const rs = await httpClient.post(url, data);
    return rs;
  }
  // receipt

  public async getReciptPaging(
    searchDTO: WarehouseReceiptInSearchDTO | WarehouseReceiptOutSearchDTO | WarehouseReceiptStorageSearchDTO,
    target: WMS_TARGET,
    category: CATEGORY,
  ): //
  Promise<PagingListDTO<WarehouseFeeInDTO> | PagingListDTO<WarehouseFeeOutDTO> | PagingListDTO<WarehouseFeeStorageDTO>> {
    let url = WMS_RECEIPT(category?.toLowerCase(), target, 'paging') + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getReciptTotal(
    searchDTO: WarehouseReceiptInSearchDTO | WarehouseReceiptOutSearchDTO | WarehouseReceiptStorageSearchDTO,
    target: WMS_TARGET,
    category: CATEGORY,
  ): Promise<IGrid.Footer[]> {
    let url = WMS_RECEIPT(category?.toLowerCase(), target, 'total') + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    const footerData = {
      ...data,
    };
    const footer = makeObjtoFooter(footerData);
    return footer;
  }

  public async getReceiptMainExcel(
    searchDTO: WarehouseReceiptInSearchDTO | WarehouseReceiptOutSearchDTO | WarehouseReceiptStorageSearchDTO,
    target: WMS_TARGET,
    category: CATEGORY,
  ): //
  Promise<WarehouseReceiptInDTO[] | WarehouseReceiptOutDTO[] | WarehouseReceiptStorageDTO[]> {
    let url = WMS_RECEIPT(category?.toLowerCase(), target, 'excel') + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getReceiptDetail(
    seq: number,
    target: WMS_TARGET,
    category: CATEGORY,
  ): //
  Promise<WarehouseReceiptInDTO[] | WarehouseReceiptOutDTO[] | WarehouseReceiptStorageDTO[]> {
    let url = WMS_RECEIPT(category?.toLowerCase(), target, 'detail') + `${seqChanger[target]}=${seq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  // transaction statement

  // inoutstorage
  public async getTransactionPaging(
    searchDTO: TransactionStatementSearchDTO,
    category: CATEGORY,
  ): //
  Promise<PagingListDTO<TransactionStatementDTO>> {
    let url = OMS_TRANSACTION_STATEMENT(category?.toLowerCase(), 'paging') + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getTransactionAction(
    searchDTO: TransactionStatementSearchDTO,
    category: CATEGORY,
    action: string,
  ): //
  Promise<TransactionStatementDTO[]> {
    let url = OMS_TRANSACTION_STATEMENT(category?.toLowerCase(), action) + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postTransactionAction(
    dataDTO: any,
    category: CATEGORY,
    action: string,
  ): //
  Promise<ResponseDTO> {
    let url = OMS_TRANSACTION_STATEMENT(category?.toLowerCase(), action);
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
}

export const feeService = new FeeService();
