import CommonModalNew from 'components/modal/commonModalNew';
import { OrderTrackingDTO } from '../../_interface/order';
import { SelectedInvoice } from 'pages/DELIVERY/2delivery/orderTrackingTab/trackInvoice';

export interface IInvoicePopupObj {
  visible?: boolean;
  item?: OrderTrackingDTO;
}

export const InvoicePopup = ({ invoicePopupObj, setInvoicePopupObj }) => {
  const { item } = invoicePopupObj;
  return (
    <CommonModalNew
      style={{ width: 400 }}
      visible={invoicePopupObj?.visible}
      setVisible={() => {
        setInvoicePopupObj({ visible: false });
      }}
      children={<>{item && <SelectedInvoice selected={item[0]} />}</>}
    />
  );
};
