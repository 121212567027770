import { v4 as uuidv4 } from 'uuid';
import CommonModalNew from './commonModalNew';

export const defaultAlertModal = {
  visible: false,
  title: '제목',
  content: ['내용', '부내용'],
  yBtnTitle: undefined,
  nBtnTitle: '확인',
  yCallback: () => {},
  // nCallback: () => {}
};

const AlertModal = (props) => {
  const { alertModalObj, setAlertModalObj } = props;

  return (
    <>
      <CommonModalNew
        style={{ width: 500 }}
        title={alertModalObj?.title}
        rightTitle={
          <>
            {alertModalObj?.yBtnTitle && (
              <button className={'btn btn-secondary ssm'} onClick={alertModalObj?.yCallback}>
                {alertModalObj?.yBtnTitle}
              </button>
            )}
          </>
        }
        visible={alertModalObj?.visible}
        setVisible={() => {
          setAlertModalObj(defaultAlertModal);
        }}
        children={
          <>
            {alertModalObj?.content?.map((str, idx) => {
              return (
                <div
                  style={{
                    color: idx === 1 ? '#FF910F' : 'black',
                    marginBottom: idx === alertModalObj?.content?.length - 1 ? 100 : 0,
                  }}
                  key={`alertmodal_content_${uuidv4()}`}
                >
                  {str}
                </div>
              );
            })}
          </>
        }
      />
    </>
  );
};

export default AlertModal;
