import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import * as IGrid from 'aui-grid';
import CommonModalNew from 'components/modal/commonModalNew';
import { userService } from 'pages/ETC/3user/_service/service';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { InfraDTOForMain } from 'interface/warehouse';
import { Col, Row } from 'reactstrap';
import { InputD } from 'components/reactstrap/reactstrap';
import { setLoading } from 'redux/services/menuSlice';

export const DetailPopup = ({ detailObj, setDetailObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const detailGridRef = useRef<IPagingGrid>();
  const [data, setData] = useState<InfraDTOForMain>();

  useEffect(() => {
    fetchDetails(detailObj?.item);
  }, []);

  const fetchDetails = async (item) => {
    dispatch(setLoading('GET'));
    const rs = await userService.getInfraDetail(item.infraSeq);
    const data = {
      ...rs,
      infraType: item?.infraType,
      infraSeq: item?.infraSeq,
      infraName: MASTER_OBJ?.INFRA?.[item.infraSeq],
    };
    const details = data?.details?.map((row) => {
      return {
        ...row,
        infraName: MASTER_OBJ?.INFRA?.[row.infraSeq],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row.modelGroup],
        parentCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.parentCenterCode],
        networkPartnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.networkPartnerSeq],
      };
    });
    detailGridRef.current.setGridData(details);
    setData(data);
    dispatch(setLoading(null));
  };

  const columns: { [key: string]: IGrid.Column[] } = {
    DELIVERY: [
      {
        headerText: '*인프라',
        dataField: 'infraName',
      },
      {
        headerText: '*창고',
        dataField: 'centerCodeKr',
        editable: true,
        editRenderer: {
          type: IGrid.EditRendererKind.ComboBoxRenderer,
          showEditorBtnOver: true,
          autoCompleteMode: true,
          autoEasyMode: true,
          matchFromFirst: false,
          list: MASTER_OPS?.CENTER_AUTH,
          keyField: 'label',
          valueField: 'label',
        },
      },
      {
        headerText: '*판매사',
        dataField: 'partnerSeqKr',
        editable: true,
        editRenderer: {
          type: IGrid.EditRendererKind.ComboBoxRenderer,
          showEditorBtnOver: true,
          autoCompleteMode: true,
          autoEasyMode: true,
          matchFromFirst: false,
          list: MASTER_OPS?.SELLER_SELLER_AUTH,
          keyField: 'label',
          valueField: 'label',
        },
      },
      {
        headerText: '*제품그룹',
        dataField: 'modelGroupKr',
        editable: true,
        editRenderer: {
          type: IGrid.EditRendererKind.ComboBoxRenderer,
          showEditorBtnOver: true,
          autoCompleteMode: true,
          autoEasyMode: true,
          matchFromFirst: false,
          list: MASTER_OPS?.MODEL_GROUP,
          keyField: 'label',
          valueField: 'label',
        },
      },
      {
        headerText: '상위창고',
        dataField: 'parentCenterCodeKr',
        editable: true,
        editRenderer: {
          type: IGrid.EditRendererKind.ComboBoxRenderer,
          showEditorBtnOver: true,
          autoCompleteMode: true,
          autoEasyMode: true,
          matchFromFirst: false,
          list: MASTER_OPS?.CENTER_AUTH,
          keyField: 'label',
          valueField: 'label',
        },
      },
    ],
    PARCEL: [
      {
        headerText: '*인프라',
        dataField: 'infraName',
      },
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: true,
        editRenderer: {
          type: IGrid.EditRendererKind.ComboBoxRenderer,
          showEditorBtnOver: true,
          autoCompleteMode: true,
          autoEasyMode: true,
          matchFromFirst: false,
          list: MASTER_OPS?.SELLER_SELLER_AUTH,
          keyField: 'label',
          valueField: 'label',
        },
      },
      {
        headerText: '*위탁사',
        dataField: 'networkPartnerSeqKr',
        editable: true,
        editRenderer: {
          type: IGrid.EditRendererKind.ComboBoxRenderer,
          showEditorBtnOver: true,
          autoCompleteMode: true,
          autoEasyMode: true,
          matchFromFirst: false,
          list: MASTER_OPS?.SELLER_NETWORK_AUTH,
          keyField: 'label',
          valueField: 'label',
        },
      },
    ],
  };

  const validateForRows = (items) => {
    if (detailObj?.item?.infraType === 'DELIVERY')
      if (items?.some((ele) => ele.centerCodeKr === ele.parentCenterCodeKr)) {
        alert('상위창고와 창고가 같은 건이 있습니다!');
        return false;
      }
    return true;
  };

  const makeDetails = (items) => {
    const data = items?.map((ele) => {
      return {
        infraSeq: MASTER_OBJ?.INFRA?.[ele.infraName],
        partnerSeq: MASTER_OBJ?.SELLER_WHOLE?.[ele.partnerSeqKr],
        centerCode: MASTER_OBJ?.CENTER_WHOLE?.[ele.centerCodeKr],
        modelGroup: MASTER_OBJ?.MODEL_GROUP?.[ele.modelGroupKr],
        parentCenterCode: MASTER_OBJ?.CENTER_WHOLE?.[ele.parentCenterCodeKr],
        networkPartnerSeq: MASTER_OBJ?.SELLER_WHOLE?.[ele.networkPartnerSeqKr],
      };
    });
    return data;
  };

  const saveInfraMain = async () => {
    const items = detailGridRef.current.getGridData();
    if (items?.length > 0) {
      if (validateForRows(items)) {
        const dataDTO = {
          ...data,
          details: makeDetails(items),
        };
        const rs = await userService.saveInfra(dataDTO);
        if (rs?.status === 200) {
          alert('저장되었습니다!');
          detailObj?.fetchFn(JSON.parse(detailObj.item.searchObj));
          setDetailObj(null);
        }
      }
    } else {
      alert('저장할 내용이 없습니다');
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'addRow') {
      detailGridRef.current.addRow({ infraSeq: data?.infraSeq, infraName: MASTER_OBJ?.INFRA?.[data?.infraSeq] });
    } else if (id === 'deleteRow') {
      const idxs = detailGridRef.current.getCheckedRowItems().map((ele) => ele.rowIndex);
      if (idxs?.length > 0) {
        const rows = detailGridRef.current.getGridData();
        const newRows = rows.filter((_, i) => !idxs.includes(i));
        detailGridRef.current.setGridData(newRows);
      } else {
        alert('선택된 것이 없습니다');
      }
    }
  };

  return (
    <CommonModalNew
      //
      style={{ width: 700 }}
      title={`[${detailObj?.item?.infraName}] 인프라상세`}
      subTitle={
        detailObj?.item?.infraType === 'DELIVERY'
          ? `1) 인프라명 변경가능합니다\n
          2) 인프라/창고/판매사/제품그룹이 동시에 겹칠수 없습니다`
          : `1) 인프라명 변경가능합니다\n
          2) 인프라/판매사/위탁사가 동시에 겹칠수 없습니다`
      }
      visible={detailObj?.visible}
      setVisible={() => setDetailObj(null)}
      rightTitle={
        <>
          <button
            className="btn btn-orange ssm"
            onClick={(e) => {
              e.preventDefault();
              saveInfraMain();
            }}
          >
            저장
          </button>
        </>
      }
      children={
        <>
          <div className="presenterSearch">
            <Row>
              <Col>
                <label className="col-form-label">인프라타입</label>
                <InputD readOnly disabled value={MASTER_OBJ?.INFRA_TYPE?.[data?.infraType] || ''} />
              </Col>
              <Col>
                <label className="col-form-label">인프라명</label>
                <InputD
                  value={data?.infraName || ''}
                  onChange={(e) => {
                    setData({
                      ...data,
                      infraName: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col />
            </Row>
          </div>
          <div className="grid-button-area">
            <div key={`row_plus`} id="addRow" className={`blue`} onClick={gridButtonhandler}>
              +행추가
            </div>
            <div key={`delete_thisrow`} id="deleteRow" className={`red`} onClick={gridButtonhandler}>
              -행삭제
            </div>
          </div>
          <GridBox
            gridRef={detailGridRef}
            columns={columns[detailObj?.item?.infraType]}
            gridProps={{
              height: 500,
              editable: true,

              showRowAllCheckBox: true,
              showRowCheckColumn: true,
            }}
          />
        </>
      }
    />
  );
};
