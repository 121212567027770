import { useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { statisticsService } from '../../_services/service';
import { InvoiceWeekDTO, InvoiceWeekSearchDTO } from '../../_interface/order';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { getToday } from 'common/util/dateParsingFn';
import { pickThisWeek } from 'common/util/calendar';
import { PagingListDTO } from 'interface/util';

const Index = ({ tabId }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const columns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '월~토 <br />Capa(건수)',
      dataField: 'capacityForWorkday',
    },
    {
      headerText: '일요일/공휴일<br /> Capa(건수)',
      dataField: 'capacityForWeekend',
    },
    {
      headerText: `월~토 <br />완료 건수(%)`,
      dataField: 'totalQuantityForWorkdayKr',
    },
    {
      headerText: '일요일/공휴일 <br />완료 건수(%)',
      dataField: 'totalQuantityForWeekendKr',
    },
    {
      headerText: '평균 완료 건수 <br />(일/공휴일 제외)',
      dataField: 'averageQuantity',
    },
    {
      headerText: '잔여 Capa(%)',
      dataField: 'leftoverCapacityQuantityKr',
    },
    {
      headerText: '일',
      dataField: 'sundayQuantity',
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item: InvoiceWeekDTO) {
        let div = `<div class="yoil-column">
        <span class="${item.sundayHolidayFlag ? 'red date' : 'date'} ">${item.sundayDate}</span>
        <span>${item.sundayQuantity}건</span>
        </div>`;
        return div;
      },
    },
    {
      headerText: '월',
      dataField: 'mondayQuantity',
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item: InvoiceWeekDTO) {
        let div = `<div class="yoil-column">
        <span class="${item.mondayHolidayFlag ? 'red date' : 'date'} ">${item.mondayDate}</span>
        <span>${item.mondayQuantity}건</span>
        </div>`;
        return div;
      },
    },
    {
      headerText: '화',
      dataField: 'tuesdayQuantity',
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item: InvoiceWeekDTO) {
        let div = `<div class="yoil-column">
        <span class="${item.tuesdayHolidayFlag ? 'red date' : 'date'} ">${item.tuesdayDate}</span>
        <span>${item.tuesdayQuantity}건</span>
        </div>`;
        return div;
      },
    },
    {
      headerText: '수',
      dataField: 'wednesdayQuantity',
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item: InvoiceWeekDTO) {
        let div = `<div class="yoil-column">
        <span class="${item.wednesdayHolidayFlag ? 'red date' : 'date'} ">${item.wednesdayDate}</span>
        <span>${item.wednesdayQuantity}건</span>
        </div>`;
        return div;
      },
    },
    {
      headerText: '목',
      dataField: 'thursdayQuantity',
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item: InvoiceWeekDTO) {
        let div = `<div class="yoil-column">
        <span class="${item.thursdayHolidayFlag ? 'red date' : 'date'} ">${item.thursdayDate}</span>
        <span>${item.thursdayQuantity}건</span>
        </div>`;
        return div;
      },
    },
    {
      headerText: '금',
      dataField: 'fridayQuantity',
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item: InvoiceWeekDTO) {
        let div = `<div class="yoil-column">
        <span class="${item.fridayHolidayFlag ? 'red date' : 'date'} ">${item.fridayDate}</span>
        <span>${item.fridayQuantity}건</span>
        </div>`;
        return div;
      },
    },
    {
      headerText: '토',
      dataField: 'saturdayQuantity',
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item: InvoiceWeekDTO) {
        let div = `<div class="yoil-column">
        <span class="${item.saturdayHolidayFlag ? 'red date' : 'date'} ">${item.saturdayDate}</span>
        <span>${item.saturdayQuantity}건</span>
        </div>`;
        return div;
      },
    },
  ];

  const validationForFetchList = (searchObj: InvoiceWeekSearchDTO) => {
    if (!searchObj?.fromFinishDate && !searchObj?.toFinishDate) {
      alert('기준일은 필수 검색자입니다!');
      return false;
    }
    return true;
  };

  const fetchTotal = async (searchObj: InvoiceWeekSearchDTO) => {
    const total = await statisticsService.getInvoiceWeekTotal(searchObj);

    return total;
  };

  const labellingKr = (data: PagingListDTO<InvoiceWeekDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        totalQuantityForWorkdayKr: `${row.totalQuantityForWorkday}(${row.totalPercentageForWorkday}%)`,
        totalQuantityForWeekendKr: `${row.totalQuantityForWeekend}(${row.totalPercentageForWeekend}%)`,
        leftoverCapacityQuantityKr: `${row.leftoverCapacityQuantity}(${row.leftoverCapacityPercentage}%)`,
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InvoiceWeekSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await statisticsService.getInvoiceWeekPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      fromFinishDate: pickThisWeek(getToday())[0],
      toFinishDate: pickThisWeek(getToday())[1],
    },
    gridRef,
    fetchTotal,
    fetchPaging,
    validationForFetchList,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    if (validationForFetchList(searchObj)) {
      dispatch(setLoading('GET'));
      const { list } = labellingKr({ list: await statisticsService.getInvoiceWeekExcelMain(searchObj) }, true);

      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '주별KPI' });

      dispatch(setLoading(null));
    }
  };

  return (
    <div className="page-content">
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area only-right">
          <div
            className="green"
            onClick={() => {
              downloadExcel('main');
            }}
          >
            엑셀다운
          </div>
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            rowHeight: 40,
            headerHeight: 35,
            showFooter: true,
            footerPosition: 'top',
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
