import { COLORS } from 'common/constants/appearance';
import LDSButton from 'components/LDS/atoms/Button';
import { LDSSpacing } from 'components/LDS/atoms/Spacing';
import { LDSTitle } from 'components/LDS/atoms/Typography';
import LDSNotFound from 'components/LDS/layouts/NotFound';
import { lazyRetry } from 'components/LDS/utils/lazyRetry';
import { generateFullCamelCasePath, generateTabPathUrl } from 'components/LDS/utils/path';
import { lazy } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

// TYPE A: React의 Dynamic import를 활용한 url과 하위 path, children을 담고 있는 객체를 동적으로 생성한다.
// tab에 새로운 필드 filepath 등을 추가하여 해당 필드에 파일 경로를 지정하는 작업 필요 - 코드 레벨에서는 url에 연결된 컴포넌트를 찾기가 어려움.
const tabs = JSON.parse(localStorage.getItem('tabs'));
/*const flatMenus = JSON.parse(localStorage.getItem('flatMenus'));

let routingTabElements = {};

if (flatMenus) {
  for (let menu of flatMenus) {
    if (!flatMenus || !tabs) break;
    const currentTabs = tabs[menu.url]; //tabs 객체에서 menu.url을 키로 가지는 배열 값을 가져온다.

    const returnFunction = () => {
      const returnArray = currentTabs.map((tab) => {
        const filepath = `./${tab.description}`;
        const Component = lazyRetry(() => import(`${filepath}`));
        console.log(filepath, Component);

        return {
          path: generateFullCamelCasePath(menu.url, tab.tabId),
          element: <>{tab.description.includes('pages') && <Component tabId={tab.tabId || null} />}</>,
        };
      });
      return returnArray;
    };
    routingTabElements[menu.url] = returnFunction;
  }
}

export { routingTabElements };*/

// TYPE B: 소분류 메뉴 path를 key로, value로는 path와 element가 정의된 탭 아이템 배열을 리턴하는 객체 방식
// 페이지의 url을 key로, 탭의 url과 id, 연결된 컴포넌트를 dynamic import하고 있어 가독성 확보
const RegisterComponentsWrapper = ({ $title, children, $backUrl }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const screenUrl = location.pathname?.split('/')?.[2];
  const lastTabUrl = JSON.parse(localStorage.getItem(`/${screenUrl}`));
  const mainPath = generateTabPathUrl(`/${screenUrl}`);

  return (
    <>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center', marginLeft: '-8px' }}>
        <LDSButton
          $primary={false}
          $size={'SM'}
          $type={'BUTTON'}
          $style={'TRANSPARENT'}
          $showIcon={true}
          $icon={<FiChevronLeft size={24} />}
          onClick={() => navigate(lastTabUrl?.tabUrl ? `${mainPath}/${lastTabUrl?.tabUrl}` : $backUrl ?? '/')}
          $tooltip={{ $direction: 'BOTTOM', $description: '뒤로가기', $anchorPosition: 'CENTER' }}
        />
        <LDSTitle $color={COLORS.GRAY_09}>{$title}</LDSTitle>
      </div>
      {($title?.includes('리퍼생성') || $title?.includes('재고품목전환')) && <LDSSpacing $direction="V" $value={24} />}
      {children}
    </>
  );
};

export const routingTabElementsDynamic = {
  //홈
  //대시보드
  '/dashboard': (url) => {
    const Dashboard = lazyRetry(() => import('pages/_HOME/dashboard/container'));
    return [
      {
        path: generateTabPathUrl(url), //탭 path 없음
        element: <Dashboard />,
      },
    ];
  },
  //통관
  //통관예정
  '/cargoManifestTransferList': (url) => {
    const CargoManifestTransfer = lazyRetry(() => import('pages/CARGO/cargoList/transferTab/transfer')); // 통관예정  탭

    return [
      {
        path: generateFullCamelCasePath(url, 'CARGO_MANIFEST_TRANSFER'),
        element: <CargoManifestTransfer tabId={'CARGO_MANIFEST_TRANSFER'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //통관목록조회
  '/cargoManifestList': (url) => {
    const CargoManifestList = lazyRetry(() => import(`pages/CARGO/cargoList/cargoListTab/cargoList`)); // 통관목록조회 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'CARGO_MANIFEST'),
        element: <CargoManifestList tabId={'CARGO_MANIFEST'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //사고접수
  '/cargoManifestAccidentList': (url) => {
    const Accident = lazyRetry(() => import('pages/CARGO/cargoList/accidentTab/accident'));
    return [
      {
        path: generateFullCamelCasePath(url, 'CARGO_MANIFEST_ACCIDENT'), //'CARGO_MANIFEST_ACCIDENT' // 미입고 탭
        element: <Accident tabId={'CARGO_MANIFEST_ACCIDENT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'CARGO_MANIFEST_OVER_ACCIDENT'), // 'CARGO_MANIFEST_OVER_ACCIDENT' // 과입고 탭
        element: <Accident tabId={'CARGO_MANIFEST_OVER_ACCIDENT'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },

  //택배
  //집화처리
  '/manageParcelTaskList': (url) => {
    const CargoTasking = lazyRetry(() => import('pages/PARCEL/orderList/taskTab/task')); // 집화처리 탭
    const CargoTaskList = lazyRetry(() => import('pages/PARCEL/orderList/taskTab/list')); // 집화내역 탭
    const CargoTaskingSm = lazyRetry(() => import('pages/PARCEL/orderList/taskTab/task_small')); // 집화처리(소형) 탭
    const CargoTaskingSmList = lazyRetry(() => import('pages/PARCEL/orderList/taskTab/task_small_new')); //집화처리(소형일괄) 탭
    const CargoTaskingExport = lazyRetry(() => import('pages/PARCEL/orderList/taskTab/export')); //집화처리(보세반출) 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'CARGO_TASKING'),
        element: <CargoTasking tabId={'CARGO_TASKING'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'CARGO_TASK_LIST'),
        element: <CargoTaskList tabId={'CARGO_TASK_LIST'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'CARGO_TASKING_SM'),
        element: <CargoTaskingSm tabId={'CARGO_TASKING_SM'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'CARGO_TASKING_SM_LIST'),
        element: <CargoTaskingSmList tabId={'CARGO_TASKING_SM_LIST'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'CARGO_TASKING_EXPORT'),
        element: <CargoTaskingExport tabId={'CARGO_TASKING_EXPORT'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //택배조회
  '/manageParcelServiceList': (url) => {
    const ParcelServiceList = lazyRetry(() => import('pages/PARCEL/parcelListTab/common')); // 택배조회 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_SERVICE_LIST'),
        element: <ParcelServiceList tabId={'PARCEL_SERVICE_LIST'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'PARCEL_RESERVED_LIST'),
        element: <ParcelServiceList tabId={'PARCEL_RESERVED_LIST'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'PARCEL_DELIVERY_COMPLETED_LIST'),
        element: <ParcelServiceList tabId={'PARCEL_DELIVERY_COMPLETED_LIST'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //사고접수
  '/manageParcelAccident': (url) => {
    const ParcelListAccident = lazyRetry(() => import('pages/PARCEL/orderList/accidentTab/accident')); // 사고접수 (택배) 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_LIST_ACCIDENT'),
        element: <ParcelListAccident />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //미배송
  '/manageParcelRevisit': (url) => {
    const ParcelListRevisit = lazyRetry(() => import('pages/PARCEL/orderList/revisitTab/revisit')); // 미배송 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_LIST_REVISIT'),
        element: <ParcelListRevisit tabId={'PARCEL_LIST_REVISIT'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //택배추적
  '/manageParcelTracking': (url) => {
    const OrderTracking = lazyRetry(() => import('pages/DELIVERY/2delivery/orderTracking')); // 택배추적 (탭 없음)

    return [
      {
        path: generateTabPathUrl(url), //탭 path 없음
        element: <OrderTracking />,
      },
    ];
  },
  //표준운임계산
  '/manageParcelCalculateFare': (url) => {
    const ParcelCalculateFare = lazyRetry(() => import('pages/PARCEL/orderList/calculateTab/calc')); // 표준운임계산

    return [
      {
        path: generateTabPathUrl(url),
        element: <ParcelCalculateFare tabId={'PARCEL_CALCULATE_FARE'} />,
      },
    ];
  },
  //택배간선조회
  '/manageParcelLinehaulList': (url) => {
    const ParcelLineHaulList = lazyRetry(() => import('pages/PARCEL/linehaul/linehaulTab/list')); // 간선조회 탭
    const ParcelLinehaulLoad = lazyRetry(() => import('pages/PARCEL/linehaul/linehaulTab/load')); // 간선상차 탭
    const ParcelLinehaulUnload = lazyRetry(() => import('pages/PARCEL/linehaul/linehaulTab/unload')); // 간선하차 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_LINEHAUL_LIST'),
        element: <ParcelLineHaulList tabId={'PARCEL_LINEHAUL_LIST'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'PARCEL_LINEHAUL_LOAD'),
        element: <ParcelLinehaulLoad tabId={'PARCEL_LINEHAUL_LOAD'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'PARCEL_LINEHAUL_UNLOAD'),
        element: <ParcelLinehaulUnload tabId={'PARCEL_LINEHAUL_UNLOAD'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //상차조회
  '/manageParcelLoadList': (url) => {
    const ParcelLinehaulLoadList = lazyRetry(() => import('pages/PARCEL/linehaul/loadingTab/list')); // 상차조회 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_LINEHAUL_LOAD_LIST'),
        element: <ParcelLinehaulLoadList tabId={'PARCEL_LINEHAUL_LOAD_LIST'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //하차조회
  '/manageParcelUnloadList': (url) => {
    const ParcelLinehauUnloadlList = lazyRetry(() => import('pages/PARCEL/linehaul/loadingTab/list')); // 하차조회 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_LINEHAUL_UNLOAD_LIST'),
        element: <ParcelLinehauUnloadlList tabId={'PARCEL_LINEHAUL_UNLOAD_LIST'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //잔류처리
  '/manageParcelRemain': (url) => {
    const ParcelRemainTask = lazyRetry(() => import('pages/PARCEL/remain/remain')); // 잔류처리 탭
    const ParcelRemainList = lazyRetry(() => import('pages/PARCEL/remain/list')); // 잔류조회 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_REMAIN_TASK'),
        element: <ParcelRemainTask tabId={'PARCEL_REMAIN_TASK'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'PARCEL_REMAIN_LIST'),
        element: <ParcelRemainList tabId={'PARCEL_REMAIN_LIST'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //운송장신청
  '/manageParcelNumber': (url) => {
    const ParcelNumber = lazyRetry(() => import('pages/PARCEL/numberTab/number')); // 운송장신청 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_NUMBER'),
        element: <ParcelNumber tabId={'PARCEL_NUMBER'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //운송장출력
  '/manageParcelNumberPrint': (url) => {
    const ParcelNumberPrint = lazyRetry(() => import('pages/PARCEL/numberPrintTab/numberPrint')); // 운송장출력 탭
    const ParcelNumberPrintAuto = lazyRetry(() => import('pages/PARCEL/numberPrintTab/numberPrintAuto')); // 운송장출력(자동) 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_NUMBER_PRINT'),
        element: <ParcelNumberPrint tabId={'PARCEL_NUMBER_PRINT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'PARCEL_NUMBER_PRINT_AUTO'),
        element: <ParcelNumberPrintAuto tabId={'PARCEL_NUMBER_PRINT_AUTO'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //운임정산
  '/manageParcelFeeList': (url) => {
    const ParcelFeeList = lazyRetry(() => import('pages/PARCEL/fee/feeListTab/feeByType')); //현황 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_FEE_DAILY'), // PARCEL_FEE_DAILY 일자별현황 탭
        element: <ParcelFeeList tabId={'PARCEL_FEE_DAILY'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'PARCEL_FEE_SELLER'), // PARCEL_FEE_SELLER 판매사별현황 탭
        element: <ParcelFeeList tabId={'PARCEL_FEE_SELLER'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'PARCEL_FEE_RETURN'), // PARCEL_FEE_RETURN 반송현황 탭
        element: <ParcelFeeList tabId={'PARCEL_FEE_RETURN'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'PARCEL_FEE_SALESCHANNEL'), // PARCEL_FEE_SALESCHANNEL 판매채널별현황 탭
        element: <ParcelFeeList tabId={'PARCEL_FEE_SALESCHANNEL'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //여신관리
  '/manageParcelFeeLoan': (url) => {
    const ParcelFeeLoan = lazyRetry(() => import('pages/PARCEL/fee/loanListTab/loanList')); // 여신관리 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_FEE_LOAN'),
        element: <ParcelFeeLoan tabId={'PARCEL_FEE_LOAN'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //운임비변경
  '/manageParcelFeeChangeFare': (url) => {
    const ParcelFeeChangeFareList = lazyRetry(() => import('pages/PARCEL/fee/feeChangeFareTab/changeFareList')); // 운임비변경 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_FEE_CHANGE_FARE_LIST'),
        element: <ParcelFeeChangeFareList tabId={'PARCEL_FEE_CHANGE_FARE_LIST'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //출고/반품 현황
  '/manageParcelTrackingReport': (url) => {
    const ParcelTrackingTakeout = lazyRetry(() => import('pages/PARCEL/tracking/reportTab/takeout')); // 출고현황 탭
    const ParcelTrackingReturn = lazyRetry(() => import('pages/PARCEL/tracking/reportTab/returns')); // 반품현황 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PARCEL_TRACKING_3_TAKEOUT'),
        element: <ParcelTrackingTakeout tabId={'PARCEL_TRACKING_3_TAKEOUT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'PARCEL_TRACKING_3_RETURNS'),
        element: <ParcelTrackingReturn tabId={'PARCEL_TRACKING_3_RETURNS'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 입고
  //발주입고
  '/purchaseOrderAdmin': (url) => {
    const PurchaseOrderTab = lazyRetry(() => import('pages/IPGO/1purchaseOrder/purchaseOrderTab/puchaseOrder')); // 발주입고 탭
    const PurchaseInspectTab = lazyRetry(() => import('pages/IPGO/1purchaseOrder/purchaseOrderTab/purchaseInspect')); // 발주입고 탭 (신규)

    return [
      {
        path: generateFullCamelCasePath(url, 'PURCHASEORDER_TAB'),
        element: <PurchaseOrderTab tabId={'PURCHASEORDER_TAB'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'PURCHASINSPECT_TAB'),
        element: <PurchaseInspectTab tabId={'PURCHASINSPECT_TAB'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //발주등록
  '/placeOrderRegister': (url) => {
    const PlaceOrderRegister = lazyRetry(() => import('pages/_REGIT/_purchaseRegitTab/purchaseRegit')); // 발주등록 탭

    return [
      {
        path: generateTabPathUrl(url), //탭 path 없음
        element: <PlaceOrderRegister />,
      },
    ];
  },
  //간선입고
  '/trunkReceiving': (url) => {
    const TrunkreceiveTab = lazyRetry(() => import('pages/IPGO/2trunk/gansunIpgoTab/gansunIpgo')); // 간선입고 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'TRUNKRECEIVE_TAB'),
        element: <TrunkreceiveTab tabId={'TRUNKRECEIVE_TAB'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //회수입고
  '/collectReceiving': (url) => {
    const DrivercollectModel = lazyRetry(() => import('pages/IPGO/3collect/collectTab/model')); //제품회수입고 탭
    const DrivercollectRemove = lazyRetry(() => import('pages/IPGO/3collect/collectTab/return')); //반출회수입고 탭
    const DrivercollectComponent = lazyRetry(() => import('pages/IPGO/3collect/collectTab/component')); //자재반납입고 탭
    const DrivercollectInspect = lazyRetry(() => import('pages/IPGO/3collect/collectTab/inspect')); //회수검수 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'DRIVERCOLLECT_MODEL'),
        element: <DrivercollectModel tabId={'DRIVERCOLLECT_MODEL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'DRIVERCOLLECT_REMOVE'),
        element: <DrivercollectRemove tabId={'DRIVERCOLLECT_REMOVE'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'DRIVERCOLLECT_COMPONENT'),
        element: <DrivercollectComponent tabId={'DRIVERCOLLECT_COMPONENT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'COLLECTINSPECT_TAB'),
        element: <DrivercollectInspect tabId={'COLLECTINSPECT_TAB'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //로케이션지정내역
  '/inventoryLocationSelect': (url) => {
    const InventoryLocationList = lazyRetry(() => import('pages/IPGO/4location/locationHistoryTab/locationHistory')); //로케이션지정 내역 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'INVENTORY_LOCATION_LIST'),
        element: <InventoryLocationList tabId={'INVENTORY_LOCATION_LIST'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },

  '/purchaseIn': (url) => {
    const PurchaseInTab = lazyRetry(() => import('pages/IPGO/5purchase/purchaseInTab/puchaseIn')); // 발주입고 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'PURCHASE_IN'),
        element: <PurchaseInTab tabId={'PURCHASE_IN'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },

  //출고
  //간선 작업 지시
  '/trunkWorks': (url) => {
    const LinehauloutTab1 = lazyRetry(() => import('pages/OUT/1trunk/gansunWorksTab/picking/container')); //간선 피킹 지시 탭
    const LinehauloutTab2 = lazyRetry(() => import('pages/OUT/1trunk/gansunWorksTab/chulgo/container')); //간선 출고 지시 탭
    const LinehauloutTab3 = lazyRetry(() => import('pages/OUT/1trunk/gansunWorksTab/takeout/container')); //간선 출차 지시 탭
    const TrunkRegister = lazyRetry(() => import('pages/_REGIT/_trunkRegitTab/trunkRegit')); //간선 피킹 생성
    const TrunkRegit = lazyRetry(() => import('pages/_REGIT/trunkRegitTab/trunkRegit')); //간선 피킹 생성 new
    const LinehauloutSupplement = lazyRetry(() => import('pages/OUT/_common/supplement')); //재고 보충 지시

    return [
      {
        path: generateFullCamelCasePath(url, 'LINEHAULOUT_TAB1'),
        element: <LinehauloutTab1 tabId={'LINEHAULOUT_TAB1'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'LINEHAULOUT_TAB2'),
        element: <LinehauloutTab2 tabId={'LINEHAULOUT_TAB2'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'LINEHAULOUT_TAB3'),
        element: <LinehauloutTab3 />,
      },
      {
        path: generateFullCamelCasePath(url, 'LINEHAULOUT_SUPPLEMENT'),
        element: <LinehauloutSupplement tabId={'loan'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'trunkRegister'),
        element: <RegisterComponentsWrapper $title={'간선 피킹 생성'} children={<TrunkRegister />} $backUrl={generateFullCamelCasePath(url, 'LINEHAULOUT_TAB1')} />,
      },
      {
        path: generateFullCamelCasePath(url, 'trunkRegit'),
        element: <RegisterComponentsWrapper $title={'간선 피킹 생성 new'} children={<TrunkRegit />} $backUrl={generateFullCamelCasePath(url, 'LINEHAULOUT_TAB1')} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //반출작업지시
  '/takeoutWorks': (url) => {
    const StockremoveTab1 = lazyRetry(() => import('pages/OUT/2takeout/banchulWorksTab/picking/container')); //반출 피킹 지시 탭
    const StockremoveTab2 = lazyRetry(() => import('pages/OUT/2takeout/banchulWorksTab/chulgo/container')); //반출 출고 지시 탭
    const StockremoveTab3 = lazyRetry(() => import('pages/OUT/2takeout/banchulWorksTab/takeout/container')); //반출 출차 지시 탭
    const TakeOutPickingInstructionRegister = lazyRetry(() => import('pages/_REGIT/_takeoutRegitTab/container')); //반출 피킹 생성
    const TakeoutRegit = lazyRetry(() => import('pages/_REGIT/takeoutRegitTab/takeoutRegit')); //반출피킹생성new
    const StoreTakeOutPickingInstructionRegister = lazyRetry(() => import('pages/_REGIT/bodyBanchulRegitTab/container')); //양판점출고
    const StockremoveSupplement = lazyRetry(() => import('pages/OUT/_common/supplement')); //재고 보충 지시

    return [
      {
        path: generateFullCamelCasePath(url, 'STOCKREMOVE_TAB1'),
        element: <StockremoveTab1 />,
      },
      {
        path: generateFullCamelCasePath(url, 'STOCKREMOVE_TAB2'),
        element: <StockremoveTab2 />,
      },
      {
        path: generateFullCamelCasePath(url, 'STOCKREMOVE_TAB3'),
        element: <StockremoveTab3 />,
      },
      {
        path: generateFullCamelCasePath(url, 'STOCKREMOVE_SUPPLEMENT'),
        element: <StockremoveSupplement tabId={'loan'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'TakeOutPickingInstructionRegister'),
        element: <RegisterComponentsWrapper $title={'반출 피킹 생성'} children={<TakeOutPickingInstructionRegister />} $backUrl={generateFullCamelCasePath(url, 'STOCKREMOVE_TAB1')} />,
      },
      {
        path: generateFullCamelCasePath(url, 'takeoutRegit'),
        element: <RegisterComponentsWrapper $title={'반출 피킹 생성 new'} children={<TakeoutRegit />} $backUrl={generateFullCamelCasePath(url, 'STOCKREMOVE_TAB1')} />,
      },
      {
        path: generateFullCamelCasePath(url, 'storeTakeOutPickingInstructionRegister'),
        element: <RegisterComponentsWrapper $title={'양판점 출고'} children={<StoreTakeOutPickingInstructionRegister />} $backUrl={generateFullCamelCasePath(url, 'STOCKREMOVE_TAB1')} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //납품작업지시
  '/deliveryWorks': (url) => {
    const DeliveryPicking = lazyRetry(() => import('pages/OUT/delivery/deliveryWorksTab/picking')); //납품피킹지시 탭
    const DeliveryTakeout = lazyRetry(() => import('pages/OUT/delivery/deliveryWorksTab/takeout')); //납품출고지시 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'DELIVERY_PICKING'),
        element: <DeliveryPicking tabId={'DELIVERY_PICKING'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'DELIVERY_TAKEOUT'),
        element: <DeliveryTakeout tabId={'DELIVERY_TAKEOUT'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },

  //반출/택배 조회
  '/stockRemoveParcel': (url) => {
    const Stockremoveparcel = lazyRetry(() => import('pages/OUT/2takeout/inventoryReturnTab')); //반출/택배조회 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'STOCKREMOVEPARCEL'),
        element: <Stockremoveparcel />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //폐가전 출고
  '/wasteAppliances': (url) => {
    const Wasteappliances = lazyRetry(() => import('pages/OUT/2takeout/wasteAppliancesTab')); //폐가전출고 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'WASTEAPPLIANCES'),
        element: <Wasteappliances />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //FC피킹
  '/fcpicking': (url) => {
    const FcpickingTotal = lazyRetry(() => import('pages/OUT/3driver/fcPickingTab/total/container')); //FC피킹(총량) 탭
    const FcpickingDriver = lazyRetry(() => import('pages/OUT/3driver/fcPickingTab/driver/container')); //FC피킹(기사) 탭
    const Createfcpicking = lazyRetry(() => import('pages/OUT/3driver/createFcPicking/container')); //FC피킹 생성
    const FcpickingSupplement = lazyRetry(() => import('pages/OUT/_common/supplement')); //재고 보충 지시

    return [
      {
        path: generateFullCamelCasePath(url, 'FCPICKING_TOTAL'),
        element: <FcpickingTotal />,
      },
      {
        path: generateFullCamelCasePath(url, 'FCPICKING_DRIVER'),
        element: <FcpickingDriver />,
      },
      {
        path: generateFullCamelCasePath(url, 'FCPICKING_SUPPL'),
        element: <FcpickingSupplement tabId={'loan'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'createfcpicking'),
        element: <RegisterComponentsWrapper $title={'FC피킹 생성'} children={<Createfcpicking />} $backUrl={generateFullCamelCasePath(url, 'FCPICKING_TOTAL')} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //기사출고
  '/deliveryOrder': (url) => {
    const DeliveryorderModel = lazyRetry(() => import('pages/OUT/3driver/takeoutTab/loanModel/container')); //제품출고 탭
    const DeliveryorderComponent = lazyRetry(() => import('pages/OUT/3driver/takeoutTab/loanMaterial/index')); //자재출고 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'DELIVERYORDER_MODEL'),
        element: <DeliveryorderModel />,
      },
      {
        path: generateFullCamelCasePath(url, 'DELIVERYORDER_COMPONENT'),
        element: <DeliveryorderComponent />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //배차
  '/distributingCar': (url) => {
    const Distributingcar = lazyRetry(() => import('pages/OUT/4car/BaeCha2/index')); //배차 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'DISTRIBUTINGCAR'),
        element: <Distributingcar />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },

  // 재고
  // 재고현황
  '/inventoryReports': (url) => {
    const InventoryLiveModel = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryReportTab/model')); //실시간 제품별 재고현황new
    const InventoryModel = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryReportTab/_model')); //실시간 제품별 재고현황
    const InventorySerial = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryReportTab/liveSerial')); //실시간 시리얼별 재고현황
    const InventoryDef = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryReportTab/defected')); //실시간 불용재고현황
    const InventoryDaily = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryReportTab/dailymagam')); //일자별 마감현황
    const InventoryDailyLoca = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryReportTab/dailyLoca')); //일자별 로케이션현황
    const InventorySales = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryReportTab/dailySales')); //일자별 판매현황
    const InventoryPay = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryReportTab/inoutPay')); //수불현황
    const InventoryWholeModel = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryReportTab/whole')); //전체재고현황

    return [
      {
        path: generateFullCamelCasePath(url, 'INVENTORY_LIVE_MODEL'), //INVENTORY_LIVE_MODEL
        element: <InventoryLiveModel tabId={'INVENTORY_LIVE_MODEL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INVENTORY_MODEL'), //INVENTORY_MODEL
        element: <InventoryModel tabId={'INVENTORY_MODEL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INVENTORY_SERIAL'), //INVENTORY_SERIAL
        element: <InventorySerial tabId={'INVENTORY_SERIAL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INVENTORY_DEF'), //INVENTORY_DEF
        element: <InventoryDef tabId={'INVENTORY_DEF'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INVENTORY_DAILY'), //INVENTORY_DAILY
        element: <InventoryDaily tabId={'INVENTORY_DAILY'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INVENTORY_DAILY_LOCA'), //INVENTORY_DAILY_LOCA
        element: <InventoryDailyLoca tabId={'INVENTORY_DAILY_LOCA'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INVENTORY_SALES'), //INVENTORY_SALES
        element: <InventorySales tabId={'INVENTORY_SALES'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INVENTORY_PAY'), //INVENTORY_PAY
        element: <InventoryPay tabId={'INVENTORY_PAY'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INVENTORY_WHOLE_MODEL'), //INVENTORY_WHOLE_MODEL
        element: <InventoryWholeModel tabId={'INVENTORY_WHOLE_MODEL'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 재고조정
  '/modifications': (url) => {
    const ModificationAvaDef = lazyRetry(() => import('pages/INVENTORY/1inventory/modificationTab/avaOrDef')); //양품/불용재고조정
    const ModificationHistory = lazyRetry(() => import('pages/INVENTORY/1inventory/modificationTab/history')); //과거이력조회
    const ModificationAvaRegit = lazyRetry(() => import('pages/_REGIT/modificationRegitTab/availlRegit/container')); //양품재고조정 신청
    const ModificationDefRegit = lazyRetry(() => import('pages/_REGIT/modificationRegitTab/defRegit/container')); //불용재고조정 신청

    return [
      {
        path: generateFullCamelCasePath(url, 'MODIFICATION_AVA'), //MODIFICATION_AVA
        element: <ModificationAvaDef tabId={'MODIFICATION_AVA'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'MODIFICATION_DEF'), //MODIFICATION_DEF
        element: <ModificationAvaDef tabId={'MODIFICATION_DEF'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'MODIFICATION_HISTORY'), //MODIFICATION_HISTORY
        element: <ModificationHistory tabId={'MODIFICATION_HISTORY'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'modificationAvaRegit'),
        element: (
          <RegisterComponentsWrapper $title={'양품재고조정 신청'} children={<ModificationAvaRegit tabId={'MODIFICATION_AVA'} />} $backUrl={generateFullCamelCasePath(url, 'MODIFICATION_AVA')} />
        ),
      },
      {
        path: generateFullCamelCasePath(url, 'modificationDefRegit'),
        element: (
          <RegisterComponentsWrapper $title={'불용재고조정 신청'} children={<ModificationDefRegit tabId={'MODIFICATION_DEF'} />} $backUrl={generateFullCamelCasePath(url, 'MODIFICATION_DEF')} />
        ),
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 재고조사
  '/inventoryInvestigate': (url) => {
    const InventoryinvestigateSimple = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryInvestigateTab/simple')); //재고조사(간편)
    const InventoryinvestigateDetail = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryInvestigateTab/detail')); //재고조사(상세)
    const InventoryinvestigateApply = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryInvestigateTab/apply')); //재고조사(반영)
    const InventoryinvestigateRegit = lazyRetry(() => import('pages/_REGIT/investigateRegitTab/investigateRegit')); //재고조사 지시서
    const InventoryinvestigateApplyRegit = lazyRetry(() => import('pages/_REGIT/investigateApplyRegitTab/investigateApplyRegit')); //재고조사 재고반영

    return [
      {
        path: generateFullCamelCasePath(url, 'INVENTORYINVESTIGATE_SIMPLE'), //INVENTORYINVESTIGATE_SIMPLE
        element: <InventoryinvestigateSimple tabId={'INVENTORYINVESTIGATE_SIMPLE'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INVENTORYINVESTIGATE_DETAIL'), //INVENTORYINVESTIGATE_DETAIL
        element: <InventoryinvestigateDetail tabId={'INVENTORYINVESTIGATE_DETAIL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INVENTORYINVESTIGATE_APPLY'), //INVENTORYINVESTIGATE_APPLY
        element: <InventoryinvestigateApply tabId={'INVENTORYINVESTIGATE_APPLY'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'investigateRegit'),
        element: <RegisterComponentsWrapper $title={'재고조사 생성'} children={<InventoryinvestigateRegit />} $backUrl={generateFullCamelCasePath(url, 'INVENTORYINVESTIGATE_DETAIL')} />,
      },
      {
        path: generateFullCamelCasePath(url, 'investigateApplyRegit'),
        element: <RegisterComponentsWrapper $title={'재고조사 재고반영'} children={<InventoryinvestigateApplyRegit />} $backUrl={generateFullCamelCasePath(url, 'INVENTORYINVESTIGATE_DETAIL')} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INVENTORYINVESTIGATE_SIMPLE'), //INVENTORYINVESTIGATE_SIMPLE
        element: <InventoryinvestigateSimple tabId={'INVENTORYINVESTIGATE_SIMPLE'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 재고추적
  '/inventoryTracking': (url) => {
    const Inventorytrack = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryTrackingTab/inventoryTracking')); // 재고추적

    return [
      {
        path: generateFullCamelCasePath(url, 'INVENTORYTRACK'), //INVENTORYTRACK
        element: <Inventorytrack tabId={'INVENTORYTRACK'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 리퍼관리
  '/refur': (url) => {
    const RefurManagement = lazyRetry(() => import('pages/INVENTORY/4modelNproduct/refurTab/refurManagement')); //리퍼관리 new
    const Refur = lazyRetry(() => import('pages/INVENTORY/4modelNproduct/refurTab/refur')); //리퍼관리 (구))
    const RefurRegit = lazyRetry(() => import('pages/_REGIT/refurRegitTab/refurRegit')); //리퍼생성신청 new
    const RefurRegister = lazyRetry(() => import('pages/_REGIT/_refurRegitTab/create/container')); //리퍼생성신청
    return [
      {
        path: generateFullCamelCasePath(url, 'REFUR_MANAGEMENT'), //REFUR_MANAGEMENT 리퍼관리 new
        element: <RefurManagement tabId={'REFUR_MANAGEMENT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'REFUR'), //REFUR 리퍼관리 (구)
        element: <Refur tabId={'REFUR'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'refurRegister'),
        element: <RegisterComponentsWrapper $title={'리퍼생성 신청'} children={<RefurRegister />} $backUrl={generateFullCamelCasePath(url, 'REFUR')} />,
      },
      {
        path: generateFullCamelCasePath(url, 'refurRegit'),
        element: <RegisterComponentsWrapper $title={'리퍼생성'} children={<RefurRegit />} $backUrl={generateFullCamelCasePath(url, 'REFUR_MANAGEMENT')} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 재고품목전환
  '/modelMoveList': (url) => {
    const ModelMoveList = lazyRetry(() => import('pages/INVENTORY/1inventory/modelMoveTab/modelMove')); //재고품목전환
    const ModelMoveRegit = lazyRetry(() => import('pages/_REGIT/modelMoveRegitTab/modelMoveRegit/')); //재고품목전환 생성
    return [
      {
        path: generateFullCamelCasePath(url, 'MODEL_MOVE_LIST'), //MODEL_MOVE_LIST
        element: <ModelMoveList tabId={'MODEL_MOVE_LIST'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'modelMoveRegit'),
        element: <RegisterComponentsWrapper $title={'재고품목전환 생성'} children={<ModelMoveRegit />} $backUrl={generateFullCamelCasePath(url, 'MODEL_MOVE_LIST')} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  '/inventoryInspect': (url) => {
    const InventoryInspectTab = lazyRetry(() => import('pages/INVENTORY/1inventory/inventoryInspectTab/inspect')); //실시간 SCM
    const InventoryinspecteRegit = lazyRetry(() => import('pages/_REGIT/inventoryInspectRegitTab/inventoryInspectRegit')); //재고조사 지시서

    return [
      {
        path: generateFullCamelCasePath(url, 'INVENTORY_INSPECT_TAB'), //SCM_LIVE
        element: <InventoryInspectTab tabId={'INVENTORY_INSPECT_TAB'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INVENTORY_INSPECT_REGIT'),
        element: <RegisterComponentsWrapper $title={'보관재고검수 '} children={<InventoryinspecteRegit />} $backUrl={generateFullCamelCasePath(url, 'INVENTORY_INSPECT_TAB')} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },

  // SCM
  '/scmManagement': (url) => {
    const ScmLive = lazyRetry(() => import('pages/INVENTORY/2scm/scmManagement/liveSCM')); //실시간 SCM
    const ScmPeriod = lazyRetry(() => import('pages/INVENTORY/2scm/scmManagement/periodSCM')); //기간별 SCM

    return [
      {
        path: generateFullCamelCasePath(url, 'SCM_LIVE'), //SCM_LIVE
        element: <ScmLive tabId={'SCM_LIVE'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'SCM_PERIOD'), //SCM_PERIOD
        element: <ScmPeriod tabId={'SCM_PERIOD'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 입출고 내역
  '/inventoryInOutList': (url) => {
    const Locationinout = lazyRetry(() => import('pages/INVENTORY/3location/inoutListTab/inoutList')); //입출고내역
    return [
      {
        path: generateFullCamelCasePath(url, 'LOCATIONINOUT'), //LOCATIONINOUT
        element: <Locationinout tabId={'LOCATIONINOUT'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 로케이션 조회
  '/inventoryLocation': (url) => {
    const InventoryLocationList = lazyRetry(() => import('pages/IPGO/4location/locationHistoryTab/locationHistory')); //로케이션지정 내역 (미사용?)
    const Locationsearch = lazyRetry(() => import('pages/INVENTORY/1inventory/locationStaticsTab/locationStatics')); //로케이션조회

    return [
      {
        path: generateFullCamelCasePath(url, 'LOCATIONSEARCH'), //LOCATIONSEARCH
        element: <Locationsearch tabId={'LOCATIONSEARCH'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 재고이동
  '/moveActionList': (url) => {
    const InvMoveaction = lazyRetry(() => import('pages/INVENTORY/3location/moveActionListTab/moveActionList')); //재고이동 탭
    const MoveActionRegit = lazyRetry(() => import('pages/_REGIT/moveActionRegitTab/moveActionRegit')); //재고이동 지시
    return [
      {
        path: generateFullCamelCasePath(url, 'INV_MOVEACTION'), //INV_MOVEACTION
        element: <InvMoveaction tabId={'INV_MOVEACTION'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'moveActionRegit'),
        element: <RegisterComponentsWrapper $title={'재고이동 지시'} children={<MoveActionRegit />} $backUrl={generateFullCamelCasePath(url, 'INV_MOVEACTION')} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 제품 관리
  '/modelList': (url) => {
    const ModelTab = lazyRetry(() => import('pages/INVENTORY/4modelNproduct/modelTab/model')); //제품 관리 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'MODEL_TAB'), //MODEL_TAB
        element: <ModelTab tabId={'MODEL_TAB'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 상품 관리
  '/productList': (url) => {
    const ProductTab = lazyRetry(() => import('pages/INVENTORY/4modelNproduct/productTab/product')); //상품 관리 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'PRODUCT_TAB'), //PRODUCT_TAB
        element: <ProductTab tabId={'PRODUCT_TAB'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 바코드 출력
  '/barcodePrinting': (url) => {
    const Barcodeprint = lazyRetry(() => import('pages/INVENTORY/4modelNproduct/barcodePrintTab/barcodePrint')); //바코드 출력 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'BARCODEPRINT'), //BARCODEPRINT
        element: <Barcodeprint tabId={'BARCODEPRINT'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },

  // 주문
  // 주문양식 관리
  '/OrderUploadForm': (url) => {
    const Orderform = lazyRetry(() => import('pages/ORDER/1order/customUploadFormTab/customUploadForm')); //주문양식관리 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'ORDERFORM'), //ORDERFORM
        element: <Orderform tabId={'ORDERFORM'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  // 주문조회
  '/manageorderInfoList': (url) => {
    const OrderTab = lazyRetry(() => import('pages/ORDER/1order/orderListTab/common')); //주문조회 탭
    const PurchaseRegit = lazyRetry(() => import('pages/_REGIT/purchaseRegitTab/purchaseRegit')); //발주등록(발주요청) 화면
    const ManageorderRegister = lazyRetry(() => import('pages/ORDER/1order/manageorderRegisterTab/manageorderRegister')); // 주문 불러오기
    const ManageorderRegit = lazyRetry(() => import('pages/_REGIT/orderRegitTab/orderRegit')); // 주문 등록2

    return [
      {
        path: generateFullCamelCasePath(url, 'ORDER_TAB'), // ORDER_TAB
        element: <OrderTab tabId={'ORDER_TAB'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'ORDER_PROGRESS'), // ORDER_PROGRESS
        element: <OrderTab tabId={'ORDER_PROGRESS'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'ORDER_FINISHORCANCEL'), // ORDER_FINISHORCANCEL
        element: <OrderTab tabId={'ORDER_FINISHORCANCEL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'purchaseRegit'), // 주문조회에서 발주요청 버튼 클릭 시 넘어옴
        element: <RegisterComponentsWrapper $title={'발주 요청'} children={<PurchaseRegit />} $backUrl={generateFullCamelCasePath(url, 'ORDER_TAB')} />,
      },
      {
        path: generateFullCamelCasePath(url, 'manageorderRegister'), // 주문조회에서 발주요청 버튼 클릭 시 넘어옴
        element: <RegisterComponentsWrapper $title={'주문 불러오기 (건별)'} children={<ManageorderRegister />} $backUrl={generateFullCamelCasePath(url, 'ORDER_TAB')} />,
      },
      {
        path: generateFullCamelCasePath(url, 'manageorderRegit'), // 주문등록 이후 주문조회로
        element: <RegisterComponentsWrapper $title={'주문 불러오기2 (건별)'} children={<ManageorderRegit />} $backUrl={generateFullCamelCasePath(url, 'ORDER_TAB')} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //택배운송장조회
  '/manageParcelList': (url) => {
    const ParcelinvoiceTab1 = lazyRetry(() => import('pages/ORDER/1order/parcelListTab/parcelList')); // 택배운송장 조회 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'PARCELINVOICE_TAB1'), // PARCELINVOICE_TAB1
        element: <ParcelinvoiceTab1 tabId={'PARCELINVOICE_TAB1'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //주문변경이력조회
  '/manageOrderHistory': (url) => {
    const OrderHistoryTab1 = lazyRetry(() => import('pages/ORDER/1order/orderHistoryTab/orderHistory')); // 주문변경이력 조회
    return [
      {
        path: generateFullCamelCasePath(url, 'ORDER_HISTORY_TAB1'), //ORDER_HISTORY_TAB1
        element: <OrderHistoryTab1 tabId={'ORDER_HISTORY_TAB1'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //서비스관리
  '/manageService': (url) => {
    const ManageserviceTab1 = lazyRetry(() => import('pages/ORDER/1order/manageServiceTab/manageService')); //서비스 관리 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'MANAGESERVICE_TAB1'), //MANAGESERVICE_TAB1
        element: <ManageserviceTab1 tabId={'MANAGESERVICE_TAB1'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //추가결제내역
  '/payment': (url) => {
    const ExtrapaymentTab = lazyRetry(() => import('pages/ORDER/1order/paymentTab/payment')); //추가결제내역 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'EXTRAPAYMENT_TAB'), //EXTRAPAYMENT_TAB
        element: <ExtrapaymentTab tabId={'EXTRAPAYMENT_TAB'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //이전 주문조회
  '/manageRelocationorderInfoList': (url) => {
    const RelocateOrderTab = lazyRetry(() => import('pages/ORDER/1order/relocateOrderTab/relocateOrder')); //이전 주문조회 탭

    return [
      {
        path: generateFullCamelCasePath(url, 'RELOCATE_ORDER_TAB'), //RELOCATE_ORDER_TAB
        element: <RelocateOrderTab tabId={'RELOCATE_ORDER_TAB'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //주문등록
  '/manageorderRegister': (url) => {
    const ManageorderRegister = lazyRetry(() => import('pages/ORDER/1order/manageorderRegisterTab/manageorderRegister')); //주문등록 탭
    return [
      {
        path: generateTabPathUrl(url), //탭 path 없음
        element: <ManageorderRegister />,
      },
    ];
  },
  //문자발송 관리
  '/MessageSendManange': (url) => {
    const Messagesendmanage = lazyRetry(() => import('pages/ORDER/1order/messageSendManageTab/messageSendManage')); //문자발송 관리 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'MESSAGESENDMANAGE'), //MESSAGESENDMANAGE
        element: <Messagesendmanage tabId={'MESSAGESENDMANAGE'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //판매현황
  '/ssList': (url) => {
    const SsList = lazyRetry(() => import('pages/ORDER/2ss/ssListTab/ssList')); // 주문 등록2
    return [
      {
        path: generateFullCamelCasePath(url, 'SS_LIST'), //RELOCATE_ORDER_TAB
        element: <SsList tabId={'SS_LIST'} />,
      },
    ];
  },
  //판매등록
  '/ssRegit': (url) => {
    const SsRegit = lazyRetry(() => import('pages/ORDER/2ss/ssRegitTab/ssRegit')); // 주문 등록2
    return [
      {
        path: generateTabPathUrl(url), //탭 path 없음
        element: <SsRegit />,
      },
    ];
  },

  //배송
  //고객 상담
  '/customerCs': (url) => {
    const Customercs = lazyRetry(() => import('pages/DELIVERY/1qna/customerCsTab/customerCs')); //고객상담 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'CUSTOMERCS'), //CUSTOMERCS
        element: <Customercs tabId={'CUSTOMERCS'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //설치기사 상담
  '/driverCs': (url) => {
    const Drivercs = lazyRetry(() => import('pages/DELIVERY/1qna/driverCsTab/driverCs')); //설치기사 상담 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'DRIVERCS'), //DRIVERCS
        element: <Drivercs tabId={'DRIVERCS'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //공지사항
  '/systemAnnouncement': (url) => {
    const Notice = lazyRetry(() => import('pages/DELIVERY/1qna/systemAnnouncementTab/systemAnnouncement')); //공지사항 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'NOTICE'), //NOTICE
        element: <Notice />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //알림 메시지 관리
  '/customAlertmsg': (url) => {
    const Customeralertmsg = lazyRetry(() => import('pages/DELIVERY/2delivery/customAlertMsgTab/customAlertMsg')); //알림 메시지 관리
    return [
      {
        path: generateFullCamelCasePath(url, 'CUSTOMALERTMSG'), //CUSTOMALERTMSG
        element: <Customeralertmsg tabId={'CUSTOMALERTMSG'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //주문연기 요청대상
  '/manageLazyorderList': (url) => {
    const Lazyorder = lazyRetry(() => import('pages/DELIVERY/2delivery/manageLazyorderListTab/manageLazyorderList')); //주문연기 요청대상 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'LAZYORDER'), //LAZYORDER
        element: <Lazyorder />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //배송 일정관리
  '/manageDeliverySchedules': (url) => {
    const DeliveryscheduledOld = lazyRetry(() => import('pages/DELIVERY/2delivery/deliveryScheduleTab/deliveryByDriver/container')); //기사별 배송일정관리
    const DeliveryscheduledCenter = lazyRetry(() => import('pages/DELIVERY/2delivery/deliveryScheduleTab/deliveryByCenter')); //창고별 배송일정관리
    const DeliveryscheduledMap = lazyRetry(() => import('pages/DELIVERY/2delivery/deliveryScheduleTab/deliveryByRegionMap/container')); //지역별 배송일정관리 (지도)
    const DeliveryscheduledRegion = lazyRetry(() => import('pages/DELIVERY/2delivery/deliveryScheduleTab/deliveryByRegion/container')); //지역별 배송일정 관리
    const DeliveryscheduledOrder = lazyRetry(() => import('pages/DELIVERY/2delivery/deliveryScheduleTab/deliveryByOrderTab')); //주문별 배송일정 관리
    const TMS = lazyRetry(() => import('pages/DELIVERY/2delivery/deliveryScheduleTab/tms')); //주문별 배송일정 관리
    return [
      {
        path: generateFullCamelCasePath(url, 'DELIVERYSCHEDULE_OLD'), //DELIVERYSCHEDULE_OLD
        element: <DeliveryscheduledOld />,
      },
      {
        path: generateFullCamelCasePath(url, 'DELIVERYSCHEDULE_CENTER'), //DELIVERYSCHEDULE_CENTER
        element: <DeliveryscheduledCenter />,
      },
      {
        path: generateFullCamelCasePath(url, 'DELIVERYSCHEDULE_MAP'), //DELIVERYSCHEDULE_MAP
        element: <DeliveryscheduledMap />,
      },
      {
        path: generateFullCamelCasePath(url, 'DELIVERYSCHEDULE_REGION'), //DELIVERYSCHEDULE_REGION
        element: <DeliveryscheduledRegion />,
      },
      {
        path: generateFullCamelCasePath(url, 'DELIVERYSCHEDULE_ORDER'), //DELIVERYSCHEDULE_ORDER
        element: <DeliveryscheduledOrder />,
      },
      {
        path: generateFullCamelCasePath(url, 'TMS_TAB'), //DELIVERYSCHEDULE_ORDER
        element: <TMS tabId={'TMS_TAB'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //일일 마감
  '/dailyClosing': (url) => {
    const DailycloseDelivery = lazyRetry(() => import('pages/DELIVERY/2delivery/dailyMagamTab/dailyDeliveryMagam/container')); //일일 배송마감 탭
    const DailycloseInventory = lazyRetry(() => import('pages/DELIVERY/2delivery/dailyMagamTab/dailyCenterReport/container')); //일일 재고마감 탭
    // const DailycloseDelivery = lazyRetry(() => import('pages/DELIVERY/2delivery/dailyClosingTab/delivery')); //일일 배송마감 탭 (신규?)
    // const DailycloseInventory = lazyRetry(() => import('pages/DELIVERY/2delivery/dailyClosingTab/inventory')); //일일 재고마감 탭 (신규?)
    return [
      {
        path: generateFullCamelCasePath(url, 'DAILYCLOSE_DELIVERY'), //DAILYCLOSE_DELIVERY
        element: <DailycloseDelivery />,
      },
      {
        path: generateFullCamelCasePath(url, 'DAILYCLOSE_INVENTORY'), //DAILYCLOSE_INVENTORY
        element: <DailycloseInventory />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //기사별 배송마감
  '/driverDeliveryClosing': (url) => {
    const Driverdeliveryclose = lazyRetry(() => import('pages/DELIVERY/2delivery/driverDeliveryClosing/container')); //기사별 배송마감 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'DRIVERDELIVERYCLOSE'), //DRIVERDELIVERYCLOSE
        element: <Driverdeliveryclose />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //배송추적
  '/ordertracking': (url) => {
    const Ordertracking = lazyRetry(() => import('pages/DELIVERY/2delivery/orderTracking')); //배송추적 탭
    return [
      {
        path: generateTabPathUrl(url), //ORDERTRACKING
        element: <Ordertracking />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },

  //통계
  //KPI
  '/kpis': (url) => {
    const KpiLeadtime = lazyRetry(() => import('pages/STATISTICS/KPI/1leadtime')); //리드타임 탭
    const KpiPromisedate = lazyRetry(() => import('pages/STATISTICS/KPI/2promise')); //약속일준수율 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'KPI_LEADTIME'), //KPI_LEADTIME
        element: <KpiLeadtime />,
      },
      {
        path: generateFullCamelCasePath(url, 'KPI_PROMISEDATE'), //KPI_PROMISEDATE
        element: <KpiPromisedate />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //Capa대비KPI
  '/kpibycapa': (url) => {
    const KpibycapaDay = lazyRetry(() => import('pages/STATISTICS/KPIinCapa/1day')); //일별KPI 탭
    const KpibycapaWeek = lazyRetry(() => import('pages/STATISTICS/KPIinCapa/2week')); //주별KPI 탭
    const KpibycapaMonth = lazyRetry(() => import('pages/STATISTICS/KPIinCapa/3month')); //월별KPI 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'KPIBYCAPA_DAY'), //KPIBYCAPA_DAY
        element: <KpibycapaDay />,
      },
      {
        path: generateFullCamelCasePath(url, 'KPIBYCAPA_WEEK'), //KPIBYCAPA_WEEK
        element: <KpibycapaWeek />,
      },
      {
        path: generateFullCamelCasePath(url, 'KPIBYCAPA_MONTH'), //KPIBYCAPA_MONTH
        element: <KpibycapaMonth />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },

  //정산
  //본사 기준정보
  '/headquarters': (url) => {
    const Omsfee = lazyRetry(() => import('pages/FEE/1basicFee/omsFee'));
    return [
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_DEFAULT_TAB'), //BASICFEE_DEFAULT_TAB 기본금액
        element: <Omsfee TARGET={'DEFAULT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_PRODUCT_TAB'), //BASICFEE_PRODUCT_TAB 상품별금액
        element: <Omsfee TARGET={'PRODUCT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_GROUP_TAB'), //BASICFEE_GROUP_TAB 그룹별금액
        element: <Omsfee TARGET={'GROUP'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_REGION_TAB'), //BASICFEE_REGION_TAB
        element: <Omsfee TARGET={'REGION'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_MODEL_TAB'), //BASICFEE_MODEL_TAB
        element: <Omsfee TARGET={'MODEL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_DATE_TAB'), //BASICFEE_DATE_TAB
        element: <Omsfee TARGET={'DATE'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_CANCEL_TAB'), //BASICFEE_CANCEL_TAB
        element: <Omsfee TARGET={'CANCEL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_OPTION_TAB'), //BASICFEE_OPTION_TAB
        element: <Omsfee TARGET={'OPTION'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //도급 기준정보
  '/subcontract': (url) => {
    const Omsfee = lazyRetry(() => import('pages/FEE/1basicFee/omsFee'));
    return [
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_DEFAULT_TAB_SUBCONTRACT'), //BASICFEE_DEFAULT_TAB_SUBCONTRACT 기본금액
        element: <Omsfee TARGET={'DEFAULT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_PRODUCT_TAB_SUBCONTRACT'), //BASICFEE_PRODUCT_TAB_SUBCONTRACT 상품별금액
        element: <Omsfee TARGET={'PRODUCT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_GROUP_TAB_SUBCONTRACT'), //BASICFEE_GROUP_TAB_SUBCONTRACT 그룹별금액
        element: <Omsfee TARGET={'GROUP'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_REGION_TAB_SUBCONTRACT'), //BASICFEE_REGION_TAB_SUBCONTRACT
        element: <Omsfee TARGET={'REGION'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_MODEL_TAB_SUBCONTRACT'), //BASICFEE_MODEL_TAB_SUBCONTRACT
        element: <Omsfee TARGET={'MODEL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_DATE_TAB_SUBCONTRACT'), //BASICFEE_DATE_TAB_SUBCONTRACT
        element: <Omsfee TARGET={'DATE'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_CANCEL_TAB_SUBCONTRACT'), //BASICFEE_CANCEL_TAB_SUBCONTRACT
        element: <Omsfee TARGET={'CANCEL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_OPTION_TAB_SUBCONTRACT'), //BASICFEE_OPTION_TAB_SUBCONTRACT
        element: <Omsfee TARGET={'OPTION'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //지점 기준정보
  '/center': (url) => {
    const Omsfee = lazyRetry(() => import('pages/FEE/1basicFee/omsFee'));
    const Wmsfee = lazyRetry(() => import('pages/FEE/1basicFee/wmsFee'));
    return [
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_DEFAULT_TAB_CENTER'), //BASICFEE_DEFAULT_TAB_CENTER 기본금액
        element: <Omsfee TARGET={'DEFAULT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_PRODUCT_TAB_CENTER'), //BASICFEE_PRODUCT_TAB_CENTER 상품별금액
        element: <Omsfee TARGET={'PRODUCT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_GROUP_TAB_CENTER'), //BASICFEE_GROUP_TAB_CENTER 그룹별금액
        element: <Omsfee TARGET={'GROUP'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_REGION_TAB_CENTER'), //BASICFEE_REGION_TAB_CENTER
        element: <Omsfee TARGET={'REGION'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_MODEL_TAB_CENTER'), //BASICFEE_MODEL_TAB_CENTER
        element: <Omsfee TARGET={'MODEL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_DATE_TAB_CENTER'), //BASICFEE_DATE_TAB_CENTER
        element: <Omsfee TARGET={'DATE'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_CANCEL_TAB_CENTER'), //BASICFEE_CANCEL_TAB_CENTER
        element: <Omsfee TARGET={'CANCEL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_OPTION_TAB_CENTER'), //BASICFEE_OPTION_TAB_CENTER
        element: <Omsfee TARGET={'OPTION'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_IN_TAB_CENTER'), //BASICFEE_IN_TAB_CENTER
        element: <Wmsfee tabId={'BASICFEE_IN_TAB_CENTER'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_OUT_TAB_CENTER'), //BASICFEE_OUT_TAB_CENTER
        element: <Wmsfee tabId={'BASICFEE_OUT_TAB_CENTER'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_STORAGE_TAB_CENTER'), //BASICFEE_STORAGE_TAB_CENTER
        element: <Wmsfee tabId={'BASICFEE_STORAGE_TAB_CENTER'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //팀 기준정보
  '/driver': (url) => {
    const Omsfee = lazyRetry(() => import('pages/FEE/1basicFee/omsFee'));
    return [
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_DEFAULT_TAB_DRIVER'), //BASICFEE_DEFAULT_TAB_DRIVER 기본금액
        element: <Omsfee TARGET={'DEFAULT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_PRODUCT_TAB_DRIVER'), //BASICFEE_PRODUCT_TAB_DRIVER 상품별금액
        element: <Omsfee TARGET={'PRODUCT'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_GROUP_TAB_DRIVER'), //BASICFEE_GROUP_TAB_DRIVER 그룹별금액
        element: <Omsfee TARGET={'GROUP'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_REGION_TAB_DRIVER'), //BASICFEE_REGION_TAB_DRIVER
        element: <Omsfee TARGET={'REGION'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_MODEL_TAB_DRIVER'), //BASICFEE_MODEL_TAB_DRIVER
        element: <Omsfee TARGET={'MODEL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_DATE_TAB_DRIVER'), //BASICFEE_DATE_TAB_DRIVER
        element: <Omsfee TARGET={'DATE'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_CANCEL_TAB_DRIVER'), //BASICFEE_CANCEL_TAB_DRIVER
        element: <Omsfee TARGET={'CANCEL'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'BASICFEE_OPTION_TAB_DRIVER'), //BASICFEE_OPTION_TAB_DRIVER
        element: <Omsfee TARGET={'OPTION'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },

  //본사 영수증
  '/OrderReceiptheadquaters': (url) => {
    const OrderReceipt = lazyRetry(() => import('pages/FEE/2orderReceipt/1receipt'));
    const OrderReceiptRecalculateReceipt = lazyRetry(() => import('pages/FEE/2orderReceipt/5recalculateReceipt'));
    return [
      {
        path: generateFullCamelCasePath(url, 'ORDER_RECEIPT_TAB1'), //ORDER_RECEIPT_TAB1
        element: <OrderReceipt />,
      },
      {
        path: generateFullCamelCasePath(url, 'ORDER_RECEIPT_TAB2'), //ORDER_RECEIPT_TAB2
        element: <OrderReceiptRecalculateReceipt />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //도급 영수증
  '/OrderReceiptSubcontract': (url) => {
    const OrderReceipt = lazyRetry(() => import('pages/FEE/2orderReceipt/1receipt'));
    const OrderReceiptRecalculateReceipt = lazyRetry(() => import('pages/FEE/2orderReceipt/5recalculateReceipt'));
    return [
      {
        path: generateFullCamelCasePath(url, 'ORDER_RECEIPT_TAB1_SUBCONTRACT'), //ORDER_RECEIPT_TAB1_SUBCONTRACT
        element: <OrderReceipt />,
      },
      {
        path: generateFullCamelCasePath(url, 'ORDER_RECEIPT_TAB2_SUBCONTRACT'), //ORDER_RECEIPT_TAB2_SUBCONTRACT
        element: <OrderReceiptRecalculateReceipt />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //지점 영수증
  '/OrderReceiptCenter': (url) => {
    const OrderReceipt = lazyRetry(() => import('pages/FEE/2orderReceipt/1receipt'));
    const OrderReceiptInOurStorage = lazyRetry(() => import('pages/FEE/2orderReceipt/2inOurStorage'));
    const OrderReceiptRecalculateReceipt = lazyRetry(() => import('pages/FEE/2orderReceipt/5recalculateReceipt'));
    return [
      {
        path: generateFullCamelCasePath(url, 'ORDER_RECEIPT_TAB1_CENTER'), //ORDER_RECEIPT_TAB1_CENTER
        element: <OrderReceipt />,
      },
      {
        path: generateFullCamelCasePath(url, 'ORDER_RECEIPT_IN_CENTER'), //ORDER_RECEIPT_IN_CENTER
        element: <OrderReceiptInOurStorage tabId={'ORDER_RECEIPT_IN_CENTER'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'ORDER_RECEIPT_OUT_CENTER'), //ORDER_RECEIPT_OUT_CENTER
        element: <OrderReceiptInOurStorage tabId={'ORDER_RECEIPT_OUT_CENTER'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'ORDER_RECEIPT_STORAGE_CENTER'), //ORDER_RECEIPT_STORAGE_CENTER
        element: <OrderReceiptInOurStorage tabId={'ORDER_RECEIPT_STORAGE_CENTER'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'ORDER_RECEIPT_TAB2_CENTER'), //ORDER_RECEIPT_TAB2_CENTER
        element: <OrderReceiptRecalculateReceipt />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //팀 영수증
  '/OrderReceiptDriverteam': (url) => {
    const OrderReceipt = lazyRetry(() => import('pages/FEE/2orderReceipt/1receipt'));
    const OrderReceiptRecalculateReceipt = lazyRetry(() => import('pages/FEE/2orderReceipt/5recalculateReceipt'));
    return [
      {
        path: generateFullCamelCasePath(url, 'ORDER_RECEIPT_TAB1_DRIVER'), //ORDER_RECEIPT_TAB1_DRIVER
        element: <OrderReceipt />,
      },
      {
        path: generateFullCamelCasePath(url, 'ORDER_RECEIPT_TAB2_DRIVER'), //ORDER_RECEIPT_TAB2_DRIVER
        element: <OrderReceiptRecalculateReceipt />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },

  //본사 거래명세서
  '/transactionStatheadquaters': (url) => {
    const Statement = lazyRetry(() => import('pages/FEE/3transactionStatement/1statement'));
    const TransactionRegister = lazyRetry(() => import('pages/_REGIT/statementRegitTab/statementRegister')); // 거래명세서 생성 화면
    return [
      {
        path: generateFullCamelCasePath(url, 'TRANSACTION_STATEMENT_HEADQUATERS'), //TRANSACTION_STATEMENT_HEADQUATERS
        element: <Statement />,
      },
      {
        path: generateFullCamelCasePath(url, '/transactionRegister/:from/:seq'),
        element: <RegisterComponentsWrapper $title={'본사 거래명세서 생성'} children={<TransactionRegister />} $backUrl={generateFullCamelCasePath(url, 'TRANSACTION_STATEMENT_HEADQUATERS')} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //도급 거래명세서
  '/transactionStatSubcontract': (url) => {
    const Statement = lazyRetry(() => import('pages/FEE/3transactionStatement/1statement'));
    const TransactionRegister = lazyRetry(() => import('pages/_REGIT/statementRegitTab/statementRegister')); // 거래명세서 생성 화면
    return [
      {
        path: generateFullCamelCasePath(url, 'TRANSACTION_STATEMENT_SUBCONTRACT'), //TRANSACTION_STATEMENT_SUBCONTRACT
        element: <Statement />,
      },
      {
        path: generateFullCamelCasePath(url, '/transactionRegister/:from/:seq'),
        element: <RegisterComponentsWrapper $title={'도급 거래명세서 생성'} children={<TransactionRegister />} $backUrl={generateFullCamelCasePath(url, 'TRANSACTION_STATEMENT_SUBCONTRACT')} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //지점 거래명세서
  '/transactionStatCenter': (url) => {
    const Statement = lazyRetry(() => import('pages/FEE/3transactionStatement/1statement'));
    const TransactionRegister = lazyRetry(() => import('pages/_REGIT/statementRegitTab/statementRegister')); // 거래명세서 생성 화면
    return [
      {
        path: generateFullCamelCasePath(url, 'TRANSACTION_STATEMENT_CENTER'), //TRANSACTION_STATEMENT_CENTER
        element: <Statement />,
      },
      {
        path: generateFullCamelCasePath(url, '/transactionRegister/:from/:seq'),
        element: <RegisterComponentsWrapper $title={'지점 거래명세서 생성'} children={<TransactionRegister />} $backUrl={generateFullCamelCasePath(url, 'TRANSACTION_STATEMENT_CENTER')} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //팀 거래명세서
  '/transactionStatDriverteam': (url) => {
    const Statement = lazyRetry(() => import('pages/FEE/3transactionStatement/1statement'));
    const TransactionRegister = lazyRetry(() => import('pages/_REGIT/statementRegitTab/statementRegister')); // 거래명세서 생성 화면
    return [
      {
        path: generateFullCamelCasePath(url, 'TRANSACTION_STATEMENT_DRIVER'), //TRANSACTION_STATEMENT_DRIVER
        element: <Statement />,
      },
      {
        path: generateFullCamelCasePath(url, '/transactionRegister/:from/:seq'),
        element: <RegisterComponentsWrapper $title={'팀 거래명세서 생성'} children={<TransactionRegister />} $backUrl={generateFullCamelCasePath(url, 'TRANSACTION_STATEMENT_DRIVER')} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },

  //기타
  //기사관리
  '/driverList': (url) => {
    const DriverList = lazyRetry(() => import('pages/ETC/1driver/driverListTab/driverList'));
    return [
      {
        path: generateFullCamelCasePath(url, 'DRIVER_LIST'), //DRIVER_LIST
        element: <DriverList tabId={'DRIVER_LIST'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //배송그룹 설정
  '/deliverySector': (url) => {
    const Deliverysector = lazyRetry(() => import('pages/ETC/1driver/deliverySectorTab/deliverySector'));
    return [
      {
        path: generateFullCamelCasePath(url, 'DELIVERYSECTOR'), //DELIVERYSECTOR
        element: <Deliverysector tabId={'DELIVERYSECTOR'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //창고 관리
  '/inventoryList': (url) => {
    const Warehouse = lazyRetry(() => import('pages/ETC/2center/warehouseListTab/warehouseList'));
    return [
      {
        path: generateFullCamelCasePath(url, 'WAREHOUSE'), //WAREHOUSE
        element: <Warehouse tabId={'WAREHOUSE'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //로케이션 조정
  '/centerLocation': (url) => {
    const Warehouselocation = lazyRetry(() => import('pages/ETC/2center/warehouseLocationTab/warehouseLocation'));
    return [
      {
        path: generateFullCamelCasePath(url, 'WAREHOUSELOCATION'), //WAREHOUSELOCATION
        element: <Warehouselocation tabId={'WAREHOUSELOCATION'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //판매사별 피킹전략
  '/PickingStrategy': (url) => {
    const Pickingstrategy = lazyRetry(() => import('pages/ETC/2center/_hide_pickingStrategy/container'));
    return [
      {
        path: generateFullCamelCasePath(url, 'PICKINGSTRATEGY'), //PICKINGSTRATEGY
        element: <Pickingstrategy />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //권한관리
  '/settings': (url) => {
    const Authorization = lazyRetry(() => import('pages/ETC/4settings/settingTabs/1groups'));
    const Menu = lazyRetry(() => import('pages/ETC/4settings/settingTabs/2menuNscreen'));
    const Tabs = lazyRetry(() => import('pages/ETC/4settings/settingTabs/3tabsNfunctions'));

    return [
      {
        path: generateFullCamelCasePath(url, 'SETTINGS_OLD_GROUPS'),
        element: <Authorization />,
      },
      {
        path: generateFullCamelCasePath(url, 'SETTINGS_MENU_SCREENS'),
        element: <Menu />,
      },
      {
        path: generateFullCamelCasePath(url, 'SETTINGS_TAB_FUNCTIONS'),
        element: <Tabs />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  '/settingList': (url) => {
    const Menu = lazyRetry(() => import('pages/ETC/4settings/listTabs/1menu'));
    const Screen = lazyRetry(() => import('pages/ETC/4settings/listTabs/2screen'));
    const Tab = lazyRetry(() => import('pages/ETC/4settings/listTabs/3tab'));
    const Function = lazyRetry(() => import('pages/ETC/4settings/listTabs/4function'));
    const Grid = lazyRetry(() => import('pages/ETC/4settings/listTabs/5grid'));

    return [
      {
        path: generateFullCamelCasePath(url, 'SETTINGS_LIST_MENU'),
        element: <Menu />,
      },
      {
        path: generateFullCamelCasePath(url, 'SETTINGS_LIST_SCREEN'),
        element: <Screen />,
      },
      {
        path: generateFullCamelCasePath(url, 'SETTINGS_LIST_TABS'),
        element: <Tab />,
      },
      {
        path: generateFullCamelCasePath(url, 'SETTINGS_LIST_FUNCTIONS'),
        element: <Function />,
      },
      {
        path: generateFullCamelCasePath(url, 'SETTINGS_LIST_GRID'),
        element: <Grid />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //판매채널 조회
  '/salesChannel': (url) => {
    const Saleschannel = lazyRetry(() => import('pages/ETC/3user/salesChannelTab/salesChannel')); //판매채널 조회 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'SALESCHANNEL'), //SALESCHANNEL
        element: <Saleschannel tabId={'SALESCHANNEL'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //사용자 관리
  '/inuserList': (url) => {
    const User = lazyRetry(() => import('pages/ETC/3user/userTab/userList')); //사용자 관리 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'USER'), //USER
        element: <User tabId={'USER'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //파트너 조회
  '/partnerList': (url) => {
    const Partner = lazyRetry(() => import('pages/ETC/3user/partnerListTab/partner')); //파트너 조회 탭
    const Vendor = lazyRetry(() => import('pages/ETC/3user/partnerListTab/vendor'));
    return [
      {
        path: generateFullCamelCasePath(url, 'PARTNER'), //파트너관리
        element: <Partner tabId={'PARTNER'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'VENDOR_TAB'), // 거래처관리
        element: <Vendor tabId={'VENDOR_TAB'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //인프라 조회
  '/infraGroupList': (url) => {
    const InfraTab1 = lazyRetry(() => import('pages/ETC/3user/infraGroupTab/infra')); //인프라조회 탭
    const InfraTab2 = lazyRetry(() => import('pages/ETC/3user/infraGroupTab/withOther')); //전체인프라관리 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'INFRA_TAB1'), //INFRA_TAB1
        element: <InfraTab1 tabId={'INFRA_TAB1'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'INFRA_TAB2'), //INFRA_TAB2
        element: <InfraTab2 tabId={'INFRA_TAB2'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //조직관리 (물류대장용)
  '/managerLD': (url) => {
    const ManagerldGroup = lazyRetry(() => import('pages/ETC/3user/jojikLDTab/manageLD')); //조직관리 탭
    const ManagerldUser = lazyRetry(() => import('pages/ETC/3user/jojikLDTab/userList')); //사용자관리 탭
    return [
      {
        path: generateFullCamelCasePath(url, 'MANAGERLD_GROUP'), //MANAGERLD_GROUP
        element: <ManagerldGroup tabId={'MANAGERLD_GROUP'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'MANAGERLD_USER'), //MANAGERLD_USER
        element: <ManagerldUser tabId={'MANAGERLD_USER'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
  //조직원관리
  '/manageUserInGroup': (url) => {
    const ManageUsergroup = lazyRetry(() => import('pages/ETC/3user/jojikCaptainTab/commonTab'));
    return [
      {
        path: generateFullCamelCasePath(url, 'MANAGE_USERGROUP_USERS'), //MANAGE_USERGROUP_USERS
        element: <ManageUsergroup tabId={'MANAGE_USERGROUP_USERS'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'MANAGE_USERGROUP_SCREEN'), //MANAGE_USERGROUP_SCREEN
        element: <ManageUsergroup tabId={'MANAGE_USERGROUP_SCREEN'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'MANAGE_USERGROUP_PARTNERS'), //MANAGE_USERGROUP_PARTNERS
        element: <ManageUsergroup tabId={'MANAGE_USERGROUP_PARTNERS'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'MANAGE_USERGROUP_INFRA'), //MANAGE_USERGROUP_INFRA
        element: <ManageUsergroup tabId={'MANAGE_USERGROUP_INFRA'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'MANAGE_USERGROUP_CENTERS'), //MANAGE_USERGROUP_CENTERS
        element: <ManageUsergroup tabId={'MANAGE_USERGROUP_CENTERS'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'MANAGE_USERGROUP_DIFF'), //MANAGE_USERGROUP_DIFF
        element: <ManageUsergroup tabId={'MANAGE_USERGROUP_DIFF'} />,
      },
      {
        path: generateFullCamelCasePath(url, 'MANAGE_USERGROUP_SALESCHANNEL'), //MANAGE_USERGROUP_SALESCHANNEL
        element: <ManageUsergroup tabId={'MANAGE_USERGROUP_SALESCHANNEL'} />,
      },
      {
        path: '*', //fallback
        element: <LDSNotFound $description={'페이지에 접근 권한이 없거나\n 존재하지 않는 경로입니다.'} />,
      },
    ];
  },
};
