import { CSSProperties, ForwardedRef, ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import CloseImg from 'assets/images/close/cross.png';
import Draggable from './draggable';

const VisibleModalWrapper = styled.div({
  padding: 10,
  position: 'absolute',
  zIndex: 12,
  borderRadius: '0.5rem',
  minHeight: 500,
  width: 200,
  boxShadow: '1px 1px 3px 4px #80808036',
  backgroundColor: 'white',
});

const VisibleModalTitle = styled.div({
  fontWeight: 700,
  fontSize: 12,
});

const VisibleModalHeader = styled.div({
  position: 'relative',
  minHeight: 20,
  width: '100%',
});

export interface IVisibleModal {
  visible: boolean;
  setVisible: () => void;
  className?: string;
  children: ReactNode;
  style?: CSSProperties;
  subtitle?: string;
  bottomtitle?: string;
  title?: string;
  movable?: boolean;
  dragParentRef: ForwardedRef<HTMLDivElement>;
}

export const VisibleModal = ({ dragParentRef, visible, setVisible, className, title, subtitle, bottomtitle, children, style, movable = true }: IVisibleModal) => {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        setVisible();
      }
    };
    window.addEventListener('keydown', close);
    return () => {
      window.removeEventListener('keydown', close);
    };
  }, []);

  return (
    <>
      {visible && (
        <Draggable
          parentRef={dragParentRef}
          defaultPosition={{ topOrBottom: { number: 10, position: 'top' }, leftOrRight: { position: 'left', number: -10 } }}
          children={
            <VisibleModalWrapper style={style}>
              <VisibleModalHeader className={movable ? 'handle' : ''} style={{ cursor: movable ? 'grab' : '' }}>
                <VisibleModalTitle>
                  {title && <span>{title}</span>}&nbsp;
                  {subtitle && <span style={{ fontWeight: 400, fontSize: 10 }}>{subtitle}</span>}
                  {bottomtitle && <span style={{ display: 'block', fontWeight: 500, fontSize: 10 }}>{bottomtitle}</span>}
                </VisibleModalTitle>
                <img src={CloseImg} onClick={setVisible} alt="닫기" style={{ width: 15, height: 15, position: 'absolute', top: 0, right: 0, cursor: 'pointer' }} />
              </VisibleModalHeader>
              {children}
            </VisibleModalWrapper>
          }
        />
      )}
    </>
  );
};
