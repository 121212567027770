import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';
import {
  ORDER_INVOICE_DAY_EXCEL,
  ORDER_INVOICE_DAY_PAGING,
  ORDER_INVOICE_DAY_TOTAL,
  ORDER_INVOICE_LEAD_TIME_DETAIL,
  ORDER_INVOICE_LEAD_TIME_EXCEL,
  ORDER_INVOICE_LEAD_TIME_PAGING,
  ORDER_INVOICE_LEAD_TIME_TOTAL,
  ORDER_INVOICE_MONTH_EXCEL,
  ORDER_INVOICE_MONTH_PAGING,
  ORDER_INVOICE_MONTH_TOTAL,
  ORDER_INVOICE_PROMISE_DETAIL,
  ORDER_INVOICE_PROMISE_EXCEL,
  ORDER_INVOICE_PROMISE_PAGING,
  ORDER_INVOICE_PROMISE_TOTAL,
  ORDER_INVOICE_WEEK_EXCEL,
  ORDER_INVOICE_WEEK_PAGING,
  ORDER_INVOICE_WEEK_TOTAL,
} from 'envVar';
import {
  InvoiceDayDTO,
  InvoiceDaySearchDTO,
  InvoiceLeadTimeDTO,
  InvoiceLeadTimeDetailDTO,
  InvoiceLeadTimeSearchDTO,
  InvoiceMonthDTO,
  InvoiceMonthSearchDTO,
  InvoicePromiseDTO,
  InvoicePromiseDetailDTO,
  InvoicePromiseSearchDTO,
  InvoiceWeekDTO,
  InvoiceWeekSearchDTO,
} from 'interface/order';

import * as IGrid from 'aui-grid';
import { makeObjtoFooter } from 'common/util/grid';
import { PagingListDTO } from 'interface/util';

class StatisticsService {
  public async getInvoiceLeadTimePaging(searchDTO: InvoiceLeadTimeSearchDTO): Promise<PagingListDTO<InvoiceLeadTimeDTO>> {
    let url = ORDER_INVOICE_LEAD_TIME_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInvoiceLeadTimeTotal(searchDTO: InvoiceLeadTimeSearchDTO): Promise<IGrid.Footer[]> {
    let url = ORDER_INVOICE_LEAD_TIME_TOTAL + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    const footer = makeObjtoFooter(data);
    return footer;
  }

  public async getInvoiceLeadTimeDetail(searchDTO: InvoiceLeadTimeSearchDTO): Promise<InvoiceLeadTimeDetailDTO[]> {
    let url = ORDER_INVOICE_LEAD_TIME_DETAIL + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInvoiceLeadTimeExcelMain(searchDTO: InvoiceLeadTimeSearchDTO): Promise<InvoiceLeadTimeDTO[]> {
    let url = ORDER_INVOICE_LEAD_TIME_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  // promise

  public async getInvoicePromisePaging(searchDTO: InvoicePromiseSearchDTO): Promise<PagingListDTO<InvoicePromiseDTO>> {
    let url = ORDER_INVOICE_PROMISE_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInvoicePromiseTotal(searchDTO: InvoicePromiseSearchDTO): Promise<IGrid.Footer[]> {
    let url = ORDER_INVOICE_PROMISE_TOTAL + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return makeObjtoFooter(data);
  }

  public async getInvoicePromiseDetail(searchDTO: InvoicePromiseSearchDTO): Promise<InvoicePromiseDetailDTO[]> {
    let url = ORDER_INVOICE_PROMISE_DETAIL + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInvoicePromiseExcelMain(searchDTO: InvoicePromiseSearchDTO): Promise<InvoicePromiseDTO[]> {
    let url = ORDER_INVOICE_PROMISE_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  // day
  public async getInvoiceDayTotal(searchDTO: InvoiceDaySearchDTO): Promise<IGrid.Footer[]> {
    let url = ORDER_INVOICE_DAY_TOTAL + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    const dataKr = {
      ...data,
      unpromisedQuantityKr: `${data.unpromisedQuantity}(${data.unpromisedPercentageForTotal}%)`,
      promisedQuantityKr: `${data.promisedQuantity}(${data.promisedPercentageForTotal}%)`,
      finishQuantityByPromisedKr: `${data.finishQuantity}(${data.finishPercentageForPromised}%)`,
      notFinishQuantityByPromisedKr: `${data.notFinishQuantity}(${data.notFinishPercentageForPromised}%)`,
      cancelQuantityKr: `${data.cancelQuantity}(${data.cancelPercentageForPromised}%)`,
      promisedQuantityByCapa: `${data.promisedQuantity}(${data.promisedPercentageForCapacity}%)`,
      finishQuantityByCapa: `${data.finishQuantity}(${data.finishPercentageForCapacity}%)`,
    };
    return makeObjtoFooter(dataKr);
  }

  public async getInvoiceDayPaging(searchDTO: InvoiceDaySearchDTO): Promise<PagingListDTO<InvoiceDayDTO>> {
    let url = ORDER_INVOICE_DAY_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInvoiceDayExcelMain(searchDTO: InvoiceDaySearchDTO): Promise<InvoiceDayDTO[]> {
    let url = ORDER_INVOICE_DAY_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  // week
  public async getInvoiceWeekTotal(searchDTO: InvoiceWeekSearchDTO): Promise<IGrid.Footer[]> {
    let url = ORDER_INVOICE_WEEK_TOTAL + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    const dataKr = {
      ...data,
      totalQuantityForWorkdayKr: `${data.totalQuantityForWorkday}(${data.totalPercentageForWorkday}%)`,
      totalQuantityForWeekendKr: `${data.totalQuantityForWeekend}(${data.totalPercentageForWeekend}%)`,
      leftoverCapacityQuantityKr: `${data.leftoverCapacityQuantity}(${data.leftoverCapacityPercentage}%)`,
    };
    return makeObjtoFooter(dataKr);
  }

  public async getInvoiceWeekPaging(searchDTO: InvoiceWeekSearchDTO): Promise<PagingListDTO<InvoiceWeekDTO>> {
    let url = ORDER_INVOICE_WEEK_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInvoiceWeekExcelMain(searchDTO: InvoiceWeekSearchDTO): Promise<InvoiceWeekDTO[]> {
    let url = ORDER_INVOICE_WEEK_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  // month
  public async getInvoiceMonthTotal(searchDTO: InvoiceMonthSearchDTO): Promise<IGrid.Footer[]> {
    let url = ORDER_INVOICE_MONTH_TOTAL + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    const dataKr = {
      ...data,
      totalQuantityForWorkdayKr: `${data.totalQuantityForWorkday}(${data.totalPercentageForWorkday}%)`,
      totalQuantityForWeekendKr: `${data.totalQuantityForWeekend}(${data.totalPercentageForWeekend}%)`,
      leftoverCapacityQuantityKr: `${data.leftoverCapacityQuantity}(${data.leftoverCapacityPercentage}%)`,
    };
    return makeObjtoFooter(dataKr);
  }

  public async getInvoiceMonthPaging(searchDTO: InvoiceMonthSearchDTO): Promise<PagingListDTO<InvoiceMonthDTO>> {
    let url = ORDER_INVOICE_MONTH_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getInvoiceMonthExcelMain(searchDTO: InvoiceMonthSearchDTO): Promise<InvoiceMonthDTO[]> {
    let url = ORDER_INVOICE_MONTH_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
}

export const statisticsService = new StatisticsService();
