import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// redux
import { Button, Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import useDebounce from 'hooks/debounceHooks';

import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import GridBox from 'common/grid/gridBox';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { PurchaseOrderSetDTO } from 'interface/warehouse';

import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

import { excelTranslator } from 'common/util/fileUploader';
import { IModelObj, ModelPopup } from './component/model';
import { useNavigate } from 'react-router-dom';
import { _PURCHASE_REGIT_FORM } from 'envVar2';
import { generateUrlByDesignType } from 'components/LDS/utils/path';
import { serviceStore } from 'services/services';

const Index = () => {
  const history = useNavigate();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();

  const defaultSearchFilter = useMemo(() => {
    return {
      centerCode: reduxUserInfo['centerCode'], // 입고창고
      purchaseDate: returnDateyyyymmdd(new Date()), // 발주일
      promiseDate: returnDateyyyymmdd(new Date()), // 입고예정일
    };
  }, [reduxUserInfo]);

  const [postObj, setPostObj] = useState<PurchaseOrderSetDTO>({ ...defaultSearchFilter, add: [] });
  const [modelObj, setModelObj] = useState<IModelObj>();

  useEffect(() => {
    if (modelObj?.returns?.length > 0) {
      const items = gridRef.current.getGridData();
      const rows = items.concat(modelObj?.returns);
      alert('추가되었습니다!');
      setModelObj((prev) => {
        return {
          ...prev,
          returns: [],
        };
      });
      gridRef.current.setGridData(rows);
    }
  }, [modelObj?.returns]);

  const columns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },

    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '원가(단가)',
      dataField: 'primePrice',
    },
    {
      headerText: '발주수량',
      dataField: 'purchaseQuantity',
    },
    {
      headerText: '비고',
      dataField: 'memo',
      editable: true,
    },
  ];

  const validCreateRegister = (postObj, items) => {
    if (!postObj?.centerCode) {
      alert('입고창고는 필수값입니다');
      return false;
    }

    if (!postObj?.purchaseDate) {
      alert('발주일은 필수값입니다');
      return false;
    }

    if (!postObj?.promiseDate) {
      alert('입고예정일은 필수값입니다');
      return false;
    }

    if (!postObj?.logisticType) {
      alert('운송타입은 필수값입니다');
      return false;
    }

    const hasQunatity = items?.filter((ele) => ele.purchaseQuantity > 0);
    if (hasQunatity?.length !== items?.length) {
      alert('발주수량이 입력되지 않은 건이 있습니다');
      return false;
    }

    return true;
  };

  const purchaseRegister = useCallback(
    useDebounce(async (postObj, items) => {
      if (validCreateRegister(postObj, items)) {
        dispatch(setLoading('POST'));
        const dataDTO = {
          ...postObj,
          add: items,
        };
        const rs = await serviceStore.warehouseAction(`purchase`, 'POST', null, dataDTO);
        if (rs?.status === 200) {
          alert('발주가 등록되었습니다!');
          history(generateUrlByDesignType('/purchaseOrderAdmin'));
        }
        dispatch(setLoading(null));
      }
    }, 500),
    [],
  );

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'PURCHASEREGISTER_EXCEL_FORM') {
      window.open(_PURCHASE_REGIT_FORM);
    } else if (id === 'PURCHASEREGISTER_MODEL_ADD') {
      if (postObj?.centerCode) setModelObj({ visible: true, centerCode: postObj?.centerCode });
      else alert('*입고창고를 선택해주세요!');
    } else if (id === 'PURCHASEREGISTER_EXCEL_UPLOAD') {
      const wholeRows = gridRef.current.getGridData();
      if (wholeRows.length > 0) {
        if (window.confirm('양식업로드를 하면 기존의 모델 데이터는 사라집니다. 계속 하시겠습니까?')) {
          document.getElementById('excelUpload').click();
        }
      } else {
        document.getElementById('excelUpload').click();
      }
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItems();
    if (items?.length > 0) {
      const wholeRows = gridRef.current.getGridData();
      if (id === 'PURCHASEREGISTER_ROW_REMOVE') {
        const checked = items.map((ele) => ele.rowIndex);
        console.log(checked);
        const filtered = wholeRows.filter((_, idx) => !checked.includes(idx));
        gridRef.current.setGridData(filtered);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const fileSelector = async (e) => {
    const excelData = await excelTranslator(e, ['partnerSeq', 'modelName', 'barcode', 'purchaseQuantity']);
    if (excelData?.filter((ele) => ele.barcode && ele.partnerSeq)?.length !== excelData?.length) {
      alert('최소 필수값인 판매사와 바코드가 입력되지 않은 건이 있습니다. 엑셀파일을 확인해주세요!');
    } else {
      dispatch(setLoading('GET'));
      excelData.forEach((row) => {
        Object.keys(row).forEach((key, i) => {
          if (key === 'partnerSeq') row[key] = MASTER_OBJ?.SELLER_WHOLE?.[row[key]];
        });
      });
      const findModel = await Promise.all(
        excelData.map(async (row) =>
          serviceStore.warehouseAction(`model/stock/excel`, 'GET', {
            ...row,
            similaritySearchFlag: false,
          }),
        ),
      );
      const modelDataArr = findModel.map((rs) => rs.data?.[0]);
      const rows = modelDataArr.map((row, i) => {
        return {
          ...row,
          partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],
          supplierPartnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.supplierPartnerSeq],
          modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
          purchaseQuantity: excelData[i]['purchaseQuantity'],
        };
      });
      gridRef.current.setGridData(rows);
      dispatch(setLoading(null));
    }
  };

  return (
    <>
      <div className="page-content">
        {modelObj?.visible && <ModelPopup modelObj={modelObj} setModelObj={setModelObj} />}
        <Row style={{ textAlign: 'right' }}>
          <Col>
            <Button
              className="btn-confirm btn"
              onClick={() => {
                const items = gridRef.current.getGridData();
                purchaseRegister(postObj, items);
              }}
            >
              발주등록
            </Button>
          </Col>
        </Row>
        <div className="presenterInput">
          <p>1. 기본 주문정보</p>
          <Row>
            <Col>
              <label className="col-form-label">외부발주번호</label>
              <InputD
                value={postObj?.externalPurchaseNumber || ''}
                onChange={(e) => {
                  setPostObj((prev) => {
                    return {
                      ...prev,
                      externalPurchaseNumber: e.target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*입고창고</label>
              <Row>
                <SelectD
                  value={
                    postObj?.centerCode
                      ? {
                          value: postObj?.centerCode,
                          label: MASTER_OBJ?.CENTER_AUTH?.[postObj?.centerCode],
                        }
                      : null
                  }
                  options={MASTER_OPS?.CENTER_AUTH}
                  onChange={(option) => {
                    setPostObj({
                      ...postObj,
                      centerCode: (option as OptionItem).value,
                    });
                  }}
                />
              </Row>
            </Col>
            <Col>
              <label className="col-form-label">*발주일</label>
              <FlatpickrD
                options={{ mode: 'single' }}
                value={postObj?.purchaseDate}
                onChange={(date) => {
                  setPostObj({
                    ...postObj,
                    purchaseDate: returnDateyyyymmdd(date[0]),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*입고예정일</label>
              <FlatpickrD
                options={{ mode: 'single' }}
                value={postObj?.promiseDate}
                onChange={(date) => {
                  setPostObj({
                    ...postObj,
                    promiseDate: returnDateyyyymmdd(date[0]),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*운송타입</label>
              <SelectD
                value={
                  postObj?.logisticType
                    ? {
                        value: postObj?.logisticType,
                        label: MASTER_OBJ?.LOGISTIC_TYPE?.[postObj?.logisticType],
                      }
                    : null
                }
                options={MASTER_OPS?.LOGISTIC_TYPE}
                onChange={(option) => {
                  setPostObj({
                    ...postObj,
                    logisticType: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*작업유형</label>
              <SelectD
                value={
                  postObj?.workType
                    ? {
                        value: postObj?.workType,
                        label: MASTER_OBJ?.WORK_TYPE?.[postObj?.workType],
                      }
                    : null
                }
                options={MASTER_OPS?.WORK_TYPE}
                onChange={(option) => {
                  setPostObj({
                    ...postObj,
                    workType: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">특이사항</label>
              <InputD
                value={postObj?.memo || ''}
                onChange={(e) => {
                  setPostObj((prev) => {
                    return {
                      ...prev,
                      memo: e.target.value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <div>
          <div className="presenterGridBox">
            <InputD style={{ display: 'none' }} onChange={fileSelector} accept=".xlsx" type="file" name="file" id="excelUpload" />
            <div className="grid-button-area only-right">
              <div className="red" id="PURCHASEREGISTER_ROW_REMOVE" onClick={gridButtonhandler}>
                - 행삭제
              </div>
              <div className="green" id="PURCHASEREGISTER_EXCEL_FORM" onClick={gridButtonhandler}>
                엑셀 양식
              </div>
              <div className="green" id="PURCHASEREGISTER_EXCEL_UPLOAD" onClick={gridButtonhandler}>
                + 엑셀등록
              </div>
              <div className="orange" id="PURCHASEREGISTER_MODEL_ADD" onClick={gridButtonhandler}>
                + 제품등록
              </div>
            </div>
            <GridBox gridRef={gridRef} columns={columns} gridProps={{ editable: true }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
