import { FlatpickrD, InputD } from 'components/reactstrap/reactstrap';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { setLoading } from 'redux/services/menuSlice';

import { Button } from 'reactstrap';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';
import { PartnerVendorDTO, PartnerVendorSearchDTO } from 'pages/ETC/3user/_interface/user';

export interface IDetailObj {
  visible: boolean;
  item?: PartnerVendorDTO;
  searchObj?: PartnerVendorSearchDTO;
  fetchFn?: (data: PartnerVendorSearchDTO) => {};
}

export const DetailPopup = ({ detailObj, setDetailObj }) => {
  const dispatch = useDispatch();
  const [partnerInfos, setpartnerInfos] = useState<PartnerVendorDTO>();

  const [currentTab, setCurrentTab] = useState(0);
  const hasTabs = [{ title: '기본정보' }];

  useEffect(() => {
    if (detailObj?.item) {
      fetchDetailData(detailObj?.item?.partnerSeq);
    } else {
      setpartnerInfos({
        status: 'ACTIVE',
      });
    }
  }, []);

  const fetchDetailData = async (partnerSeq: string) => {
    dispatch(setLoading('GET'));
    const data = (await serviceStore.userAction(`partner/vendor/${partnerSeq}`, 'GET', null))?.data;
    setpartnerInfos(data);
    dispatch(setLoading(null));
  };

  const handleDaumPost = () => {
    new (window as any).daum.Postcode({
      oncomplete: function (data) {
        const { zonecode, roadAddress, jibunAddress } = data;
        setpartnerInfos((prev) => {
          return {
            ...prev,
            zipcode: zonecode,
            address: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
          };
        });
      },
    }).open();
  };

  const save = async (partnerInfos) => {
    const rs = await serviceStore.userAction(detailObj?.item ? `partner/vendor/${partnerInfos?.partnerSeq}` : 'partner/vendor', detailObj?.item ? 'PUT' : 'POST', null, partnerInfos);
    if (rs?.status === 200) {
      alert(`${detailObj?.item ? '수정' : '생성'}되었습니다!`);
      detailObj?.fetchFn(detailObj?.searchObj);
      setDetailObj(null);
    }
  };

  const validate = (data) => {
    if (data?.sellerFlag && !data?.cutoffDay) {
      alert('판매사 체크시 재고할당 기준일수는 필수값입니다!');
      return false;
    }
    return true;
  };

  return (
    <CommonModalNew
      title={`거래처 ${!detailObj?.item ? '등록' : '상세보기'}`}
      style={{ width: 1000 }}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      rightTitle={
        <>
          <Button
            className="btn-secondary btn ssm"
            onClick={(e) => {
              save(partnerInfos);
            }}
          >
            {detailObj?.item ? '수정' : '등록'}
          </Button>
        </>
      }
      children={
        <>
          <div style={{ minHeight: 600 }}>
            <div className="div-top-tab">
              {hasTabs.map((tab, index) => {
                return (
                  <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                    <li
                      onClick={() => {
                        setCurrentTab(index);
                      }}
                    >
                      {tab?.title}
                    </li>
                  </div>
                );
              })}
            </div>
            <div style={{ flexDirection: 'column', display: currentTab === 0 ? 'flex' : 'none' }}>
              <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
                <thead>
                  <tr>
                    <th colSpan={9}>1. 기본정보</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>*거래처명</th>
                    <td colSpan={2}>
                      <InputD
                        value={partnerInfos?.partnerName || ''}
                        onChange={(e) => {
                          setpartnerInfos({
                            ...partnerInfos,
                            partnerName: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>거래처코드</th>
                    <td colSpan={2}>
                      <InputD
                        value={partnerInfos?.externalPartnerCode || ''}
                        onChange={(e) => {
                          setpartnerInfos({
                            ...partnerInfos,
                            externalPartnerCode: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>거래처 운영중</th>
                    <td colSpan={2}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="checkbox"
                          checked={partnerInfos?.status === 'ACTIVE' || false}
                          onChange={(e) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              status: !e.target.checked ? 'END' : 'ACTIVE',
                            });
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>대표자명</th>
                    <td colSpan={2}>
                      <InputD
                        value={partnerInfos?.managerName || ''}
                        onChange={(e) => {
                          setpartnerInfos({
                            ...partnerInfos,
                            managerName: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>우편번호/주소</th>
                    <td colSpan={5}>
                      <div style={{ display: 'flex' }}>
                        <InputD readOnly onClick={handleDaumPost} style={{ display: 'inline-block', width: 80, marginRight: 10, cursor: 'pointer' }} value={partnerInfos?.zipcode || ''} />
                        <InputD
                          style={{ width: 200 }}
                          placeholder="우편번호를 클릭하세요"
                          disabled
                          value={partnerInfos?.address || ''}
                          onChange={(e) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              address: e.target.value,
                            });
                          }}
                        />
                        <InputD
                          style={{ width: 240 }}
                          value={partnerInfos?.addressDetail || ''}
                          onChange={(e) => {
                            setpartnerInfos({
                              ...partnerInfos,
                              addressDetail: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>사업자번호</th>
                    <td colSpan={2}>
                      <InputD
                        value={partnerInfos?.businessNumber || ''}
                        onChange={(e) => {
                          setpartnerInfos({
                            ...partnerInfos,
                            businessNumber: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>연락처</th>
                    <td colSpan={2}>
                      <InputD
                        value={partnerInfos?.managerPhone || ''}
                        onChange={(e) => {
                          setpartnerInfos({
                            ...partnerInfos,
                            managerPhone: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>이메일</th>
                    <td colSpan={2}>
                      <InputD
                        value={partnerInfos?.managerEmail || ''}
                        onChange={(e) => {
                          setpartnerInfos({
                            ...partnerInfos,
                            managerEmail: e.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>은행</th>
                    <td colSpan={2}>
                      <InputD
                        value={partnerInfos?.bank || ''}
                        onChange={(e) => {
                          setpartnerInfos({
                            ...partnerInfos,
                            bank: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>계좌번호</th>
                    <td colSpan={2}>
                      <InputD
                        value={partnerInfos?.accountNumber || ''}
                        onChange={(e) => {
                          setpartnerInfos({
                            ...partnerInfos,
                            accountNumber: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <th>거래시작일</th>
                    <td colSpan={2}>
                      <FlatpickrD
                        disabled={detailObj?.item}
                        value={partnerInfos?.startDate || ''}
                        onChange={(date) => {
                          setpartnerInfos({
                            ...partnerInfos,
                            startDate: returnDateyyyymmdd(date[0]),
                          });
                        }}
                        options={{ mode: 'single' }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>할인율(%)</th>
                    <td colSpan={2}>
                      <InputD
                        type="number"
                        value={partnerInfos?.discountRate || ''}
                        onChange={(e) => {
                          setpartnerInfos({
                            ...partnerInfos,
                            discountRate: Number(e.target.value),
                          });
                        }}
                      />
                    </td>
                    <th>담당자명</th>
                    <td colSpan={2}>
                      <InputD
                        value={partnerInfos?.leadManagerName || ''}
                        onChange={(e) => {
                          setpartnerInfos({
                            ...partnerInfos,
                            leadManagerName: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <td colSpan={3}></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      }
    />
  );
};
