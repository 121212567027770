import { KeyboardEvent } from 'react';
import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { LocationSearchDTO } from 'interface/warehouse';
import { OptionItem } from 'common/master/codeMasterReturnHelper';

export const SearchBox = (props: ISearchBoxProps<LocationSearchDTO>) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">*창고</label>
          <SelectD
            options={MASTER_OPS?.CENTER_AUTH}
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  centerCode: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">로케이션코드</label>
          <InputD
            value={searchObj?.locationCode || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                locationCode: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">로케이션유형</label>
          <SelectD
            options={MASTER_OPS?.LOCATION_TYPE}
            value={
              searchObj?.locationType
                ? {
                    value: searchObj?.locationType,
                    label: MASTER_OBJ?.LOCATION_TYPE?.[searchObj?.locationType],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  locationType: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">로케이션구분</label>
          <SelectD
            options={MASTER_OPS?.LOCATION_CATEGORY}
            value={
              searchObj?.locationCategory
                ? {
                    value: searchObj?.locationCategory,
                    label: MASTER_OBJ?.LOCATION_CATEGORY?.[searchObj?.locationCategory],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  locationCategory: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">로케이션규격</label>
          <SelectD
            options={MASTER_OPS?.LOCATION_SIZE}
            value={
              searchObj?.locationSize
                ? {
                    value: searchObj?.locationSize,
                    label: MASTER_OBJ?.LOCATION_SIZE?.[searchObj?.locationSize],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  locationSize: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">피킹할당여부</label>
          <SelectD
            options={MASTER_OPS?.PICK_FLAG}
            value={
              searchObj?.pickFlag
                ? {
                    value: searchObj?.pickFlag,
                    label: MASTER_OBJ?.PICK_FLAG?.[searchObj?.pickFlag + ''],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  pickFlag: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">운영상태</label>
          <SelectD
            options={MASTER_OPS?.USE_FLAG}
            value={
              searchObj?.useFlag
                ? {
                    value: searchObj?.useFlag,
                    label: MASTER_OBJ?.USE_FLAG?.[searchObj?.useFlag + ''],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  useFlag: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
