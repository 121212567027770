import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as IGrid from 'aui-grid';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { Col, Row, Form } from 'reactstrap';
import { ModelStockAndModelDTO, ModelStockAndModelSearchDTO } from 'interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';
import { resetAllSelections } from 'common/util/searchBox';
import AUIGrid from 'modules/Grid';
import { defaultGridProps } from 'common/grid/gridBox';
import { serviceStore } from 'services/services';

export interface IModelObj {
  visible?: boolean;
  centerCode?: string;
  returns?: any[];
}

export const ModelPopup = ({ modelObj, setModelObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      centerCode: modelObj?.centerCode,
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  const [searchObj, setSearchObj] = useState<ModelStockAndModelSearchDTO>({ ...defaultSearchFilter });
  const gridRef = useRef<AUIGrid>();
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const columns: IGrid.Column[] = [
    {
      headerText: '단종여부',
      dataField: 'stopFlagKr',
      editable: false,
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      editable: false,
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqKr',
      editable: false,
    },
    {
      headerText: '제품타입',
      dataField: 'modelTypeKr',
      editable: false,
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
      editable: false,
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
      editable: false,
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
      editable: false,
    },
    {
      headerText: '제품',
      dataField: 'model',
      editable: false,
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
      editable: false,
    },

    {
      headerText: '바코드',
      dataField: 'barcode',
      editable: false,
    },
    {
      headerText: '원가(단가)',
      dataField: 'primePrice',
      editable: false,
    },
    {
      headerText: '발주수량',
      dataField: 'purchaseQuantity',
    },
  ];

  const validate = (items) => {
    const stops = items?.filter((ele) => ele.stopFlag);
    const quantities = items?.filter((ele) => !ele.purchaseQuantity);
    if (stops?.length > 0) {
      alert('단종된 제품은 발주할수 없습니다!');
      return false;
    }
    if (quantities?.length > 0) {
      alert('수량이 입력되지 않은 제품이 있습니다!');
      return false;
    }
    return true;
  };

  const returnThese = () => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (validate(items)) {
        setModelObj((prev) => {
          return {
            ...prev,
            returns: items,
          };
        });
      }
    } else {
      alert('선택한 제품이 없습니다');
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchPaging(searchObj);
    }
  };

  const fetchPaging = async (searchObj: ModelStockAndModelSearchDTO) => {
    dispatch(setLoading('GET'));
    const data = (await serviceStore.warehouseAction(`model/stock/excel`, 'GET', searchObj))?.data;
    console.log(data);
    const dataKr = (data as ModelStockAndModelDTO[])?.map((row) => {
      return {
        ...row,
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],
        supplierPartnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.supplierPartnerSeq],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        modelTypeKr: MASTER_OBJ?.MODEL_TYPE?.[row?.modelType],
        stopFlagKr: MASTER_OBJ?.STOP_FLAG?.[row?.stopFlag as any],
      };
    });

    gridRef.current.setGridData(dataKr);
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title="제품검색"
      style={{ width: 1400 }}
      visible={modelObj?.visible}
      setVisible={() => {
        setModelObj(null);
      }}
      children={
        <>
          <Form onKeyPress={onKeyPress}>
            <Row style={{ marginBottom: 20 }}>
              <Col>
                <label className="col-form-label">판매사</label>
                <SelectD
                  isMulti
                  value={searchObj?.partnerSeq?.map((ele) => {
                    return {
                      value: ele,
                      label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[ele],
                    };
                  })}
                  options={MASTER_OPS?.SELLER_SELLER_AUTH}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      partnerSeq: (option as OptionItem[]).map((ele) => ele.value),
                    });
                  }}
                />
              </Col>
              <Col>
                <label>제품타입</label>
                <SelectD
                  isMulti
                  options={MASTER_OPS?.MODEL_TYPE}
                  value={searchObj?.modelType?.map((ele) => {
                    return {
                      value: ele,
                      label: MASTER_OBJ?.MODEL_TYPE?.[ele],
                    };
                  })}
                  onChange={(option) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        modelType: (option as OptionItem[]).map((ele) => ele.value),
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label>제품그룹</label>
                <SelectD
                  options={MASTER_OPS?.MODEL_GROUP}
                  value={
                    searchObj?.modelGroup
                      ? {
                          value: searchObj?.modelGroup,
                          label: MASTER_OBJ?.MODEL_GROUP?.[searchObj?.modelGroup],
                        }
                      : null
                  }
                  onChange={(option) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        modelGroup: (option as OptionItem).value,
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label>제품정보</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      value={
                        selectTypeObj?.SEARCH_MODEL
                          ? {
                              value: selectTypeObj?.SEARCH_MODEL,
                              label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                            }
                          : null
                      }
                      options={MASTER_OPS?.SEARCH_MODEL}
                      onChange={(option) => {
                        setSelectTypeObj({
                          ...selectTypeObj,
                          SEARCH_MODEL: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!selectTypeObj?.SEARCH_MODEL}
                      value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                      onChange={(e) => {
                        setSearchObj({
                          ...searchObj,
                          [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">유사검색여부</label>
                <div className="radio-select-area">
                  <span
                    className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                    onClick={() => {
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          similaritySearchFlag: true,
                        };
                      });
                    }}
                  >
                    유사검색
                  </span>
                  <span
                    className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                    onClick={() => {
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          similaritySearchFlag: false,
                        };
                      });
                    }}
                  >
                    일치검색
                  </span>
                </div>
              </Col>
              <Col>
                <div style={{ paddingTop: 13 }}>
                  <button
                    className="btn btn-etc ssm"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchPaging(searchObj);
                    }}
                  >
                    조회
                  </button>
                  <button
                    className="btn btn-secondary ssm"
                    onClick={(e) => {
                      e.preventDefault();
                      returnThese();
                    }}
                  >
                    추가
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
          <AUIGrid
            //
            ref={gridRef}
            name={'searchModel'}
            columnLayout={columns}
            gridProps={{ ...defaultGridProps, showRowAllCheckBox: true, showRowCheckColumn: true, height: 500, editable: true, fixedColumnCount: 1 }}
          />
        </>
      }
    />
  );
};
