import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Presenter from './presenter';
// redux
import { WMS_FCPICKING_CREATE } from 'envVar';
import { useDispatch, useSelector } from 'react-redux';
import { httpClient } from 'common/http-client/axiosConfig';

import { checkHTML5Brower, rABS, XLSX, fixdata, process_wb, parseCvs } from 'common/util/fileUploader';
import { setLoading } from 'redux/services/menuSlice';
import useDebounce from '../../../../hooks/debounceHooks';

const Container = (props) => {
  const dispatch = useDispatch();

  // options
  const { MASTER_OBJ } = useSelector((state) => state.menu);

  const [gridId, _] = useState(`trunkRegister_Main${uuidv4()}`);
  const [rows, setRows] = useState([]);
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'selectedRows-deleted') {
        deleteRows(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const [createObj, setCreateObj] = useState({
    loanPickDate: null,
    centerCode: null,
    userId: null,
    models: [],
  });

  const [searchModalObj, setSearchModalObj] = useState({
    type: null,
    visible: false,
  });

  const deleteRows = (items) => {
    const idxs = items.map((ele) => ele.rowIndex);
    const rows = window.AUIGrid.getGridData(`#${gridId}`);
    const filtered = rows.filter((ele, idx) => !idxs.includes(idx));
    setRows(filtered);
  };

  const createPicking = useCallback(
    useDebounce(async (createObj) => {
      const rows = window.AUIGrid.getGridData(`#${gridId}`);
      if (!createObj.centerCode) {
        alert('기본정보에서 출고창고를 선택해주세요');
      } else if (!createObj.loanPickDate) {
        alert('출고예정일을 선택해주세요');
      } else if (rows.length === 0) {
        alert('제품을 선택해주세요');
      } else {
        dispatch(setLoading('PUT'));
        let payloadObj = {
          centerCode: createObj.centerCode,
          userId: createObj.userId,
          loanPickDate: createObj.loanPickDate,
          models: rows.map((ele) => {
            return {
              modelStockSeq: ele.modelStockSeq,
              partnerSeq: ele.partnerSeq,
              modelGroup: ele.modelGroup,

              locationCode: ele.locationCode,
              quantity: ele.quantityInput,
            };
          }),
        };

        let url = WMS_FCPICKING_CREATE;
        await httpClient.post(url, payloadObj).then((res) => {
          if (res?.status === 200) {
            alert('FC피킹 생성되었습니다!');
          }
        });
        dispatch(setLoading(null));
      }
    }, 1500),
    [],
  );

  const fileSelector = (e) => {
    console.log(e);
    try {
      if (!checkHTML5Brower()) {
        alert('브라우저가 HTML5 를 지원하지 않습니다.\r\n서버로 업로드해서 해결하십시오.');
        return;
      }

      const file = e.target.files[0];
      if (typeof file === 'undefined') {
        return;
      }

      const reader = new FileReader();
      reader.onload = async function (e) {
        const data = e.target.result;
        let workbook;
        if (rABS) {
          workbook = XLSX.read(data, { type: 'binary' });
        } else {
          var arr = fixdata(data);
          workbook = XLSX.read(Buffer.from(arr, 'base64'), { type: 'base64' });
        }

        const jsonObj = process_wb(workbook);
        if (jsonObj[Object.keys(jsonObj)[0]]) {
          const parseExcelDataArr = parseCvs(jsonObj[Object.keys(jsonObj)[0]]);
          const addArr = [];
          const colField = ['locationCode', 'modelGroup', 'model', 'barcode', 'modelStockSeq', 'skuNumber', 'partnerSeqKr', 'totalAvailableQuantity', 'usableAvailableQuantity', 'quantity'];
          parseExcelDataArr.forEach((textArr = [], idx) => {
            if (idx !== 0) {
              const obj = {};
              // colField 길이만큼
              for (let index = 0; index < colField.length; index++) {
                if (colField[index] === 'partnerSeqKr') obj['partnerSeq'] = MASTER_OBJ?.SELLER_SELLER_WHOLE?.[textArr[index]];
                if (colField[index] === 'locationCode' && (textArr[index] === '자동' || !textArr[index])) obj['locationCode'] = null;
                obj[colField[index]] = textArr[index];
              }
              addArr.push(obj);
            }
          });
          console.log(addArr);
          const data = {
            centerCode: createObj?.centerCode,
            models: addArr.map((ele) => {
              return {
                barcode: ele.barcode,
                model: ele.model,
                partnerSeq: ele.partnerSeq,
              };
            }),
          };

          const availables = await searchInventoryFrom(data);
          console.log(availables);
          if (availables?.length > 0) {
            availables?.forEach((row, idx) => {
              row.quantityInput = addArr[idx]['quantity'] || 0;
              row.locationCode = row.locationCode ? row?.locationCode : '자동';
              row.usableAvailableQuantity = row?.availableQuantity;
              row.partnerSeqKr = MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq]; //SELLER_OBJ
              row.centerCodeKr = MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode]; //CENTER_FC_OBJ
              row.modelTypeKr = MASTER_OBJ?.MODEL_TYPE?.[row?.modelType];
              row.modelGroupKr = MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup];
              row.modelGroupLargeKr = MASTER_OBJ?.MODEL_GRP_1?.[row?.modelGroupLarge];
              row.modelGroupMediumKr = MASTER_OBJ?.MODEL_GRP_2?.[row?.modelGroupMedium];
              row.modelGroupSmallKr = MASTER_OBJ?.MODEL_GRP_3?.[row?.modelGroupSmall];
            });

            // 누적 합산
            const addedRows = rows.concat(availables);
            alert('추가되었습니다!');
            setRows(addedRows);
          } else {
            alert('가능한 재고가 없습니다!');
          }
        }
      };

      reader.onloadend = () => {
        e.target.value = '';
      };
      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {}
  };

  const searchInventoryFrom = async (data) => {
    return httpClient.post(`/warehouse/inventory/v2/picking/model/list`, data).then((rs) => {
      if (rs?.status === 200) {
        return rs.data;
      }
    });
  };

  const columns = [
    {
      headerText: '출고창고',
      dataField: 'centerCodeKr',
      editable: false,
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          editable: false,
        },
        {
          headerText: '제품타입',
          dataField: 'modelTypeKr',
          editable: false,
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          editable: false,
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          editable: false,
        },
        {
          headerText: '제품',
          dataField: 'model',
          editable: false,
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          editable: false,
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          editable: false,
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          editable: false,
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          editable: false,
        },
      ],
    },
    {
      headerText: '현재보관수량',
      children: [
        {
          headerText: '양품',
          dataField: 'totalAvailableQuantity',
          editable: false,
        },
        {
          headerText: '이동가능양품',
          dataField: 'usableAvailableQuantity',
          editable: false,
        },
      ],
    },
    {
      headerText: 'FC피킹 대상수량',
      dataField: 'quantityInput',
      dataType: 'numeric',
      style: 'my-custom-aui',
      renderer: {
        type: 'TemplateRenderer',
        aliasFunction: function (rowIndex, columnIndex, value, headerText, item) {
          // 엑셀, PDF 등 내보내기 시 값 가공 함수
          return value;
        },
      },
    },
  ];

  return (
    <Presenter
      gridId={gridId}
      rows={rows}
      setRows={setRows}
      columns={columns}
      fileSelector={fileSelector}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      //
      createPicking={createPicking}
      createObj={createObj}
      setCreateObj={setCreateObj}
      searchModalObj={searchModalObj}
      setSearchModalObj={setSearchModalObj}
    />
  );
};

export default Container;
