export const DEFAULT_COLUMNS = [
  {
    parameter: 'showDetail',
    parameterName: '상세보기',
    parameterNameEn: null,
    parameterNameZh: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    level: 1,
    sort: 1,
  },
  {
    parameter: 'registerDate',
    parameterName: '등록일자',
    parameterNameEn: null,
    parameterNameZh: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    level: 1,
    sort: 2,
  },
  {
    parameter: 'houseNumber',
    parameterName: 'HBL',
    parameterNameEn: null,
    parameterNameZh: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    level: 1,
    sort: 3,
  },
  {
    parameter: 'consigneeName',
    parameterName: '받는사람',
    parameterNameEn: null,
    parameterNameZh: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    level: 1,
    sort: 4,
  },
  {
    parameter: 'consigneePhone',
    parameterName: '연락처',
    parameterNameEn: null,
    parameterNameZh: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    level: 1,
    sort: 5,
  },
  {
    parameter: 'statusKr',
    parameterName: '진행상태',
    parameterNameEn: null,
    parameterNameZh: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    level: 1,
    sort: 6,
  },
  {
    parameter: 'customCounsel',
    parameterName: '고객상담',
    parameterNameEn: null,
    parameterNameZh: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    level: 1,
    sort: 7,
  },
  {
    parameter: 'failReason',
    parameterName: '통관실패사유',
    parameterNameEn: null,
    parameterNameZh: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    level: 1,
    sort: 8,
  },
];

export const FLAG_OPT = [
  {
    value: 'verifyFail',
    label: '검증실패',
  },
  {
    value: 'verifyComplete',
    label: '검증완료',
  },
  {
    value: 'shippingAppoint',
    label: '선적대상지정',
  },
  {
    value: 'warehouseStock',
    label: '창고입고',
  },
  {
    value: 'customsDecalre',
    label: '세관신고',
  },
  {
    value: 'entryPend',
    label: '통관보류',
  },
  {
    value: 'entryComplete',
    label: '통관완료',
  },
  {
    value: 'noExistInvoice',
    label: '화물송장없음',
  },
  {
    value: 'parcelTransfer',
    label: '택배인계',
  },
  {
    value: 'collectTotal',
    label: '회수내역',
    children: [],
  },
];
