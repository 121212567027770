import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { InvoiceSearchDTOForOrder } from 'interface/order';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

const filteredInvoiceStatus = [
  //
  'ADDRESS_ERROR',
  'ASSIGNING',
  'ZIPCODE_ERROR',
  'NOT_EXIST_CENTER',
  'NOT_EXIST_DRIVER',
  'DRIVER_SETTING_ERROR',
  'DRIVER_CAPA_EXCEED',
  'CENTER_CUTOFF_CLOSE',
  'NOT_IN_INVENTORY',
  'ASSIGN',
  'ASSIGN_VIRT',
  'CONFIRM',
  'LOAN',
];

export const SearchBox = (props: ISearchBoxProps<InvoiceSearchDTOForOrder>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE: null,
    SEARCH_INVOICE: null,
  });
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_INVOICE, searchObj));
  }, [selectTypeObj?.SEARCH_INVOICE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">기간검색</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_DATE
                      ? {
                          value: selectTypeObj?.SEARCH_DATE,
                          label: MASTER_OBJ?.SEARCH_DATE[selectTypeObj?.SEARCH_DATE],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_DATE}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_DATE: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <FlatpickrD
                  onReset={() => {
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                      [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                    });
                  }}
                  disabled={!selectTypeObj?.SEARCH_DATE}
                  value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                      [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                    });
                  }}
                  onClose={(option) => {
                    if (option?.length === 1)
                      setSearchObj({
                        ...searchObj,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                        [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                      });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">창고</label>
            <SelectD
              value={
                searchObj?.centerCode
                  ? {
                      value: searchObj?.centerCode,
                      label: selectlabel(searchObj?.centerCode, MASTER_OPS?.CENTER_WHOLE),
                    }
                  : null
              }
              options={MASTER_OPS?.CENTER_AUTH}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: (option as OptionItem).value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">판매사</label>
            <div>
              <SelectD
                value={
                  searchObj?.partnerSeq
                    ? {
                        label: selectlabel(searchObj?.partnerSeq, MASTER_OPS?.SELLER_WHOLE),
                        value: searchObj?.partnerSeq,
                      }
                    : null
                }
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    partnerSeq: (option as OptionItem).value,
                  });
                }}
              />
            </div>
          </Col>
          <Col>
            <label className="col-form-label">송장번호</label>{' '}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_INVOICE
                      ? {
                          value: selectTypeObj?.SEARCH_INVOICE,
                          label: selectlabel(selectTypeObj?.SEARCH_INVOICE, MASTER_OPS?.SEARCH_INVOICE),
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_INVOICE}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_INVOICE: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_INVOICE}
                  value={searchObj?.[selectTypeObj?.SEARCH_INVOICE] || ''}
                  onChange={(e) => {
                    const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                    if (value?.length > 50) {
                      alert('50개까지 검색가능합니다');
                    }
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_INVOICE]: value?.length > 50 ? value?.splice(0, 50) : value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">송장유형</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.FEE_TYPE}
              value={searchObj?.feeTypes?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.FEE_TYPE?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  feeTypes: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">송장상태</label>
            <SelectD
              isMulti
              options={filteredInvoiceStatus?.map((ele) => {
                return {
                  value: ele,
                  label: selectlabel(ele, MASTER_OPS?.INVOICE_STATUS),
                };
              })}
              value={searchObj?.invoiceStatuses?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.INVOICE_STATUS?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  invoiceStatuses: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">재고위치</label>
            <SelectD
              isMulti={true}
              options={MASTER_OPS?.INVENTORY_LOCATION}
              value={searchObj?.inventoryLocations?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.INVENTORY_LOCATION?.[ele],
                };
              })}
              onChange={(opts) => {
                setSearchObj({
                  ...searchObj,
                  inventoryLocations: (opts as OptionItem[]).map((ele) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">고객명(수령인)</label>
            <InputD
              value={searchObj?.receiverName || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  receiverName: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">주소</label>
            <InputD
              value={searchObj?.receiverAddr || ''}
              onChange={(e) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    receiverAddr: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">연락처</label>
            <InputD
              value={searchObj?.receiverPhone || ''}
              onChange={(e) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    receiverPhone: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">날짜지정 여부</label>
            <SelectD
              options={MASTER_OPS?.FIX_DATE}
              value={
                searchObj?.promiseFlag
                  ? {
                      value: searchObj?.promiseFlag + '',
                      label: MASTER_OBJ?.FIX_DATE?.[searchObj?.promiseFlag + ''],
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  promiseFlag: value,
                });
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">배송기사</label>
            <div style={{ display: 'flex' }}>
              <Col md={6} style={{ display: 'inline-block' }}>
                <SelectD
                  options={MASTER_OPS?.DRIVER_AUTH}
                  value={
                    searchObj?.userId
                      ? {
                          value: searchObj?.userId,
                          label: MASTER_OBJ?.DRIVER_WHOLE?.[searchObj?.userId],
                        }
                      : null
                  }
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      userId: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={6} style={{ display: 'inline-block' }}>
                <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
              </Col>
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: 'right' }}>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                fetchList(searchObj);
              }}
            >
              조회
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
