import { KeyboardEvent, useEffect, useRef, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { ParcelInvoiceLoadSearchDTO, ParcelInvoiceUnloadSearchDTO } from '../../../../_interface/parcel';

export const SearchBox = (props: ISearchBoxProps<ParcelInvoiceLoadSearchDTO & ParcelInvoiceUnloadSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList, TARGET } = props;

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    fetchList(searchObj);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <label className="col-form-label">{TARGET === 'PARCEL_LINEHAUL_LOAD_LIST' ? '상차일' : '하차일'}</label>
            <FlatpickrD
              onClose={(option) => {
                if (option?.length === 1)
                  setSearchObj({
                    ...searchObj,
                    fromRegisterDate: null,
                    toRegisterDate: null,
                  });
              }}
              value={[searchObj?.fromRegisterDate, searchObj?.toRegisterDate]}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  fromRegisterDate: returnDateyyyymmdd(option[0]),
                  toRegisterDate: returnDateyyyymmdd(option[1]),
                });
              }}
            />
          </Col>
          {TARGET === 'PARCEL_LINEHAUL_LOAD_LIST' ? (
            <Col>
              <label className="col-form-label">상차지</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.CENTER_AUTH}
                value={searchObj?.originalCenterCode?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.CENTER_AUTH?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    originalCenterCode: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
          ) : (
            <Col>
              <label className="col-form-label">하차지</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.CENTER_AUTH}
                value={searchObj?.destinationCenterCode?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.CENTER_AUTH?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    destinationCenterCode: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
          )}
          <Col>
            <button className="btn btn-search" type="submit">
              조회
            </button>
          </Col>
          <Col />
          <Col />
          <Col />
        </Row>
      </Form>
    </>
  );
};
