import { useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// component
import Router from '../../../routes/router';

import { detectMobileDevice } from 'common/util/detectDevice';

import HFSLOGO from '../_img/HFS_LOGO.png';
import HFSLOGOW from '../_img/HFS_LOGO_W.png';

import ring from '../_img/ring.png';
import ringActive from '../_img/ring_active.png';

import lang from '../_img/lang.png';
import langActive from '../_img/langActive.png';

import close from 'assets/images/close/cross.png';
import favImg from 'assets/images/button/star-solid.png';
import recentImg from 'assets/images/button/recent.svg';

import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

// redux

import { httpClient, NO_NEED_REFRESH, tokenCheck } from 'common/http-client/axiosConfig';
import { MY_BOOKMARK, MY_RECENTVISIT, USER_SIGN_OUT } from 'envVar';
import { postRefreshToken } from 'common/http-client/refreshToken';
import { getMyBookmarks, getMyRecentVisits } from './layoutManager';
import { clearScreen, setLoading, setNowMenu } from 'redux/services/menuSlice';
import { justServer } from 'common/util/isServer';
import { SideMenu } from './sideMenu';

import { Oval } from 'react-loader-spinner';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { ReadOnlyPopup } from '../../DELIVERY/1qna/systemAnnouncementTab/presenter';
import { UserDetailPopupforUser } from 'pages/ETC/3user/userTab/userList/component/userDetailforUsers';
import axios from 'axios';
import { useClickAway } from '@uidotdev/usehooks';
import { ILOCALE, LocaleObj, setLocale, setLocaleVisible } from 'redux/services/localeSlice';
import { getLanguageInBrowser, getLanguangeInlocalStorage, saveLanguangeInlocalStorage } from 'common/util/lang';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { setNullAuth } from 'redux/services/authSlice';
import { RootState } from 'redux/store';
import { clearLocalStorageData, setLocalStorageTokens, setLocalTabs } from 'common/util/localStorage';
import CommonModalNew from 'components/modal/commonModalNew';
import { LDSSpacing } from 'components/LDS/atoms/Spacing';

interface IAlarm {
  visible?: boolean;
  count?: number;
  list?: any[];
}

export const home = {
  menuName: '대시보드',
  url: '/',
};

const Index = () => {
  const { getFetchUseableFunctions, getFetchUseableScreenNTabs } = useUseableScreenTabFunctions();
  const { useableFunctions, useableScreens } = useSelector((state: RootState) => state.auth);
  const { loading, openOrclose } = useSelector((state: RootState) => state.menu);
  const { locale } = useSelector((state: RootState) => state.locale);

  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const [tokenValidFlag, setTokenValidFlag] = useState(false);
  const [navHidden, setNavHidden] = useState(false);
  const [headerObj, setHeaderObj] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const url = location?.pathname;
  const search = location?.search;

  useEffect(() => {
    if (tokenValidFlag && !headerObj) {
      initAfterValidToken();
    }
  }, [tokenValidFlag]);

  const initAfterValidToken = async () => {
    if (!useableScreens) {
      await fetchUserSettingInfos();
    } else {
      const [bookMarks, recentVisits] = await Promise.all([getMyBookmarks(), getMyRecentVisits()]);
      setHeaderObj({
        bookMarks: bookMarks || [],
        recentVisits: recentVisits || [],
        header: true,
        headerMode: 'bookMark',
        side: true,
      });
    }
  };

  const fetchUserSettingInfos = async () => {
    console.log('fetchsetting::');
    const [functions, screens] = await Promise.all([getFetchUseableFunctions(), getFetchUseableScreenNTabs()]);
    setLocalTabs(screens);
    return [functions, screens];
  };

  const itsOkay = async () => {
    if (!useableScreens) await fetchUserSettingInfos();
    setTokenValidFlag(true);
  };

  useEffect(() => {
    dispatch(clearScreen());
    const isMobile = detectMobileDevice(window.navigator.userAgent);
    if (isMobile) setIsMobile(true);
    if (
      (url?.includes('/mordertracking') && (search?.includes('p=') || search?.includes('e='))) ||
      (url?.includes('/morderReturnTracking') && search?.includes('p=')) ||
      url === '/login' ||
      (url?.includes('/cargoManifestTask') && isMobile) ||
      url?.includes('/logis')
    ) {
      setNavHidden(true);
    } else {
      setNavHidden(false);
    }

    document.title = `HFS`;

    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken && (location?.pathname === '/login' || location?.pathname?.includes('/logis') || location?.pathname.includes('/mordertracking'))) {
      // dispatch(setLocale())
    } else if (!accessToken && location?.pathname !== '/login') {
      alert('로그인이 필요합니다');
      history('/login');
    } else if (accessToken) {
      if (tokenCheck() === NO_NEED_REFRESH) {
        if (location?.pathname === '/login') {
          setTokenValidFlag(true);
          alert('로그인 되어있으므로 홈으로 이동합니다');
          history('/');
          setTokenValidFlag(false);
        } else {
          itsOkay();
        }
      } else {
        postRefreshToken()
          .then((res) => {
            if (res?.status === 200) {
              setLocalStorageTokens(res?.data);
              if (location?.pathname === '/login') {
                setTokenValidFlag(true);
                alert('로그인 되어있으므로 홈으로 이동합니다');
                history('/');
                setTokenValidFlag(false);
              } else {
                itsOkay();
              }
            } else {
              // 혹시나
              alert('토큰이 만료되어 로그인창으로 이동합니다!');
              clearLocalStorageData();
              history('/login');
            }
          })
          .catch((err) => {
            alert('토큰이 만료되어 로그인창으로 이동합니다!');
            clearLocalStorageData();
            history('/login');
          });
      }
    }

    const modalContentFlags = JSON.parse(localStorage.getItem('modalContentFlags'));
    const newModalContentFlags = modalContentFlags?.length > 0 ? [...modalContentFlags, 0] : [0];

    if (modalContentFlags?.filter((flag) => flag === 0)?.length !== 1 && location.pathname !== '/login') {
      localStorage.setItem('modalContentFlags', JSON.stringify(newModalContentFlags));
      setNewDesignNotiObj({
        visible: true,
      });
    }
  }, [location?.pathname]);

  const deleteMyRecentVisit = (data) => {
    httpClient.delete(MY_RECENTVISIT, { data }).then(async (res) => {
      if (res?.status === 200) {
        alert('최근 방문기록이 삭제되었습니다');
        setHeaderObj({
          ...headerObj,
          recentVisits: await getMyRecentVisits(),
        });
      }
    });
  };

  const deleteMyBookmark = async (data) => {
    await httpClient.delete(MY_BOOKMARK, { data }).then(async (res) => {
      if (res?.status === 200) {
        alert('즐겨찾기에서 삭제되었습니다.');
        setHeaderObj({
          ...headerObj,
          bookMarks: await getMyBookmarks(),
        });
      }
    });
  };

  const [classNameForWrapper, setClassNameForWrapper] = useState<string>();

  useEffect(() => {
    let str = '';
    if (justServer('lfs-temp.')) str += 'temp';
    if (isMobile) str += ' mobileAccess';
    setClassNameForWrapper(str);
    settingLanguage();
  }, []);

  const settingLanguage = () => {
    const localStorageLang = getLanguangeInlocalStorage();
    const browserLang = getLanguageInBrowser().split('-')[0];
    // localStorage가 우선
    if (localStorageLang?.trim() === 'undefined' || !localStorageLang) {
      dispatch(setLocale(browserLang));
      saveLanguangeInlocalStorage(browserLang as ILOCALE);
    } else {
      dispatch(setLocale(localStorageLang));
    }
  };

  // profilemenu

  const [menu, setMenu] = useState(false);
  const [gongjiObj, setGongjiObj] = useState<{ visible: boolean; announcementSeq: number }>();
  const [userPopObj, setUserPopObj] = useState({
    userId: '',
  });
  const [newDesignNotiObj, setNewDesignNotiObj] = useState<{ visible: boolean }>();
  const [pagingSetting, setPagingSetting] = useState({
    pageNo: 1,
    pageSize: 10,
    fetchNew: false,
  });
  const [langObj, setLangObj] = useState({
    visible: false,
    type: 'ko',
  });

  const [alarms, setAlarms] = useState<IAlarm>();

  const accessToken = localStorage.getItem('accessToken');
  const [fetched, setFetched] = useState(false);
  useEffect(() => {
    if (userInfo && accessToken && !fetched) {
      fetchAlarms();
      setFetched(true);
    }
  }, [location?.pathname, userInfo]);

  const headerprofileStyle = {
    height: 20,
    width: 20,
    borderRadius: 10,
    fontSize: 13,
    marginRight: 5,
    backgroundColor: '#ffb919',
  };

  useEffect(() => {
    if (pagingSetting?.fetchNew) {
      concatAlarmList();
    }
  }, [pagingSetting?.pageNo]);

  const fetchAlarms = async () => {
    const [count, list] = await Promise.all([fetchAlarmCount(), fetchAlarmList()]);
    setAlarms((prev) => {
      return {
        ...prev,
        visible: false,
        count,
        list,
      };
    });
  };

  const concatAlarmList = async () => {
    const more = await fetchAlarmList();
    setAlarms((prev) => {
      return {
        ...prev,
        list: alarms?.list?.concat(more),
      };
    });
  };

  const fetchAlarmCount = async () => {
    return await httpClient.get(`/user/notification/quantity?erpFlag=true`).then((rs) => {
      if (rs?.status === 200) {
        return rs?.data;
      }
    });
  };

  const fetchAlarmList = async () => {
    let search = `/user/notification/list/paging?erpFlag=true&pageNo=${pagingSetting.pageNo}&pageSize=${pagingSetting.pageSize}`;
    return await httpClient.get(search).then((rs) => {
      if (rs?.status === 200) {
        return rs?.data?.list;
      }
    });
  };

  const readNotification = async (ele) => {
    const rs = await httpClient.post(`/user/notification/read?notificationSeq=${ele.notificationSeq}`);
    if (rs?.status === 200) {
      await fetchAlarms();
      setGongjiObj({
        visible: true,
        announcementSeq: ele?.tableSeq,
      });
    }
  };

  const fetchLogout = async () => {
    const rs = await axios.post(process.env.REACT_APP_API_URL + USER_SIGN_OUT, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
      },
    });

    if (rs?.status === 200) {
      clearLocalStorageData();
      dispatch(setNullAuth());
      alert('로그아웃 되었습니다!');
      history('/login');
    }
  };

  const alermRef = useClickAway<HTMLDivElement>(() => {
    if (alarms?.visible)
      setAlarms((prev) => {
        return {
          ...prev,
          visible: !alarms?.visible,
        };
      });
  });

  return (
    <div className={classNameForWrapper}>
      {gongjiObj?.visible && <ReadOnlyPopup popupObj={gongjiObj} setPopupObj={setGongjiObj} />}
      {newDesignNotiObj?.visible && (
        <CommonModalNew
          visible={newDesignNotiObj?.visible}
          style={{ width: 360 }}
          title={'새로워진 로지메이트를 체험해 보세요.'}
          setVisible={() => {
            setNewDesignNotiObj(null);
          }}
          children={
            <div style={{ display: 'flex', flexDirection: 'column', gap: 4, wordBreak: 'keep-all' }}>
              <span style={{ flexShrink: 0 }}>로지메이트의 디자인이 새로워졌어요!</span>
              <span>즐겨찾기 모아보기, 메뉴명 검색, 사이드바 레이아웃 개선 등 사용성이 더욱 강화된 신규 디자인을 체험해보시려면, 아래의 신규 디자인 체험하기 버튼을 클릭하세요.</span>
              <LDSSpacing $direction="V" $value={12} />
              <button className="btn-confirm btn btn" style={{ placeSelf: 'center', width: 'calc(100% - 5px)', transform: 'translateX(-5px)' }} onClick={() => handleNewDesignFlag(1)}>
                신규 디자인 체험하기 (Beta)
              </button>
            </div>
          }
        />
      )}
      {locale?.visible && (
        <div className="whole-div locale">
          <img
            style={{ width: 30, position: 'absolute', top: 10, right: 10 }}
            src={close}
            alt="언어설정창 닫기"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setLocaleVisible());
            }}
          />
          <div className="locales">
            {Object.keys(LocaleObj)?.map((ele, i) => {
              return (
                <div
                  key={`ele_${i}`}
                  className={locale?.value === ele ? 'active' : ''}
                  onClick={() => {
                    dispatch(setLocale(ele));
                  }}
                >
                  {LocaleObj?.[ele]?.label}
                </div>
              );
            })}
          </div>
          <div className="exp">
            {Object.keys(LocaleObj)?.map((ele, i) => {
              return (
                <div className={locale?.value === ele ? 'active' : ''} key={`exp_${ele}_${i}`}>
                  {LocaleObj?.[ele]?.exp}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!navHidden && (
        <>
          <div className="header-container">
            <div className="thin-header">
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (location?.pathname !== home.url) {
                    setTokenValidFlag(false);
                    dispatch(setNowMenu(home));
                  }
                }}
              >
                <Link to="/" className="home-logo">
                  <img src={justServer('lfs-temp.') ? HFSLOGOW : HFSLOGO} />
                </Link>
              </span>
              <span style={{ display: 'flex', gap: '0', flexGrow: 1, marginRight: '-4px' }}>
                <span style={{ flexGrow: 1 }}></span>
                <button className="btn-confirm btn btn" style={{ placeSelf: 'center' }} onClick={() => handleNewDesignFlag(1)}>
                  신규 디자인 체험하기 (Beta)
                </button>
                <span style={{ width: '12px' }}></span>
                {/* profileMenu 자리 start */}
                {location.pathname === '/manageorderInfoList' && (
                  <span className="lang" style={{ right: '0px', position: 'relative' }}>
                    <span
                      onClick={() => {
                        dispatch(setLocaleVisible());
                      }}
                    >
                      <img src={langObj?.visible ? langActive : lang} alt="언어" />
                    </span>
                  </span>
                )}
                <span className="alarm" style={{ position: 'relative', right: '-8px' }}>
                  <span
                    onClick={() => {
                      setAlarms((prev) => {
                        return {
                          ...prev,
                          visible: !alarms?.visible,
                        };
                      });
                    }}
                  >
                    <img src={alarms?.visible ? ringActive : ring} alt="알림" />

                    {alarms?.count > 0 && <span className="count">{alarms?.count > 10 ? '+10' : alarms?.count}</span>}
                  </span>
                  {alarms?.visible && (
                    <div className="alarm-list" ref={alermRef}>
                      <div style={{ padding: 5, width: 350, fontSize: 15, fontWeight: 500, position: 'fixed', backgroundColor: 'white', top: 30 }}>알림</div>
                      <div style={{ marginTop: 30, overflowY: 'scroll', height: 450 }}>
                        {alarms?.list.map((ele, idx) => {
                          return (
                            <div
                              className="list"
                              key={`alarms_list_${idx}`}
                              onClick={() => {
                                readNotification(ele);
                              }}
                            >
                              <div style={{ width: 320 }} className="ellipsis">
                                <div style={{ position: 'relative', paddingLeft: 10 }}>
                                  [{ele.menu}]{ele.readFlag === false && <span style={{ position: 'absolute', left: 0, top: 0, backgroundColor: '#ff00008c', width: 8, height: 8, borderRadius: 4 }} />}
                                </div>
                                <div style={{ fontSize: 12, fontWeight: 500, marginLeft: 10 }}>{ele.content}</div>
                              </div>
                              <div style={{ fontSize: 9, color: 'gray', textAlign: 'right' }}>{ele.registerDatetime}</div>
                            </div>
                          );
                        })}
                        {alarms?.list?.length !== alarms?.count && (
                          <div
                            className="list"
                            style={{ justifyContent: 'center', padding: 0 }}
                            onClick={() => {
                              if (alarms?.list?.length !== alarms?.count) {
                                setPagingSetting((prev) => {
                                  return {
                                    ...prev,
                                    pageNo: pagingSetting?.pageNo + 1,
                                    fetchNew: true,
                                  };
                                });
                              }
                            }}
                          >
                            + 더보기
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </span>
              </span>
              {userPopObj?.userId && <UserDetailPopupforUser userPopObj={userPopObj} setUserPopObj={setUserPopObj} />}
              <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                  <span style={headerprofileStyle}>{userInfo?.name ? userInfo?.name[0] : ''}</span>
                  <span className="userName">{userInfo?.name}</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    onClick={() => {
                      setUserPopObj({
                        userId: userInfo?.sub,
                      });
                    }}
                  >
                    개인정보 수정
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="logout"
                    onClick={() => {
                      fetchLogout();
                    }}
                  >
                    로그아웃
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {/* profileMenu 자리 end */}
            </div>
            <div className={headerObj?.header ? 'sub-thin-header' : 'sub-thin-header close'}>
              <span className="favNrecent-tab">
                <img
                  alt=""
                  onClick={() => {
                    setHeaderObj({
                      ...headerObj,
                      headerMode: 'bookMark',
                    });
                  }}
                  className={headerObj?.headerMode === 'bookMark' ? 'active' : ''}
                  src={favImg}
                />
                |
                <img
                  alt=""
                  onClick={() => {
                    setHeaderObj({
                      ...headerObj,
                      headerMode: 'recentVisit',
                    });
                  }}
                  className={headerObj?.headerMode === 'recentVisit' ? 'active' : ''}
                  src={recentImg}
                />
              </span>
              {headerObj?.headerMode === 'bookMark'
                ? headerObj?.bookMarks?.map((ele, idx) => (
                    <div
                      key={idx}
                      className="myMenu"
                      onClick={() => {
                        window.open(ele.url, '_blank');
                      }}
                    >
                      {ele.menuName}
                      <span className="remove-fav">
                        <img
                          src={close}
                          alt="즐겨찾기 삭제"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteMyBookmark(ele);
                          }}
                        />
                      </span>
                    </div>
                  ))
                : headerObj?.recentVisits?.map((ele, idx) => (
                    <div
                      key={`recentmenu_${idx}`}
                      className="myMenu"
                      onClick={() => {
                        window.open(ele.url, '_blank');
                      }}
                    >
                      {ele.menuName}
                      <span className="remove-fav">
                        <img
                          src={close}
                          alt="최근방문 삭제"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteMyRecentVisit(ele);
                          }}
                        />
                      </span>
                    </div>
                  ))}
            </div>
          </div>
          <div className={headerObj?.header ? 'fake-header-container' : 'fake-header-container favNheader-close'} />
        </>
      )}

      <div className={headerObj?.header ? 'container-fluid' : 'container-fluid favNheader-close'}>
        {/* 사이드바 */}
        {!navHidden && <SideMenu deleteMyBookmark={deleteMyBookmark} headerObj={headerObj} setHeaderObj={setHeaderObj} tokenValidFlag={tokenValidFlag} setTokenValidFlag={setTokenValidFlag} />}

        {location?.pathname === '/login' ||
        (location?.pathname !== 'login' && tokenValidFlag && headerObj?.recentVisits) ||
        url?.includes('/mordertracking') ||
        location?.pathname?.includes('/logis') ||
        // 없애도되나
        (url?.includes('/morderReturnTracking') && search?.includes('p=')) ? ( // 토큰이 있어야 화면을 보여주자
          <>
            {loading !== null && (
              <div style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ position: 'relative' }}>
                  <img
                    style={{ width: 10, position: 'absolute', top: '52%', left: '58%', zIndex: 1057, cursor: 'pointer' }}
                    src={close}
                    alt="로딩바닫기"
                    onClick={(e) => {
                      dispatch(setLoading(null));
                    }}
                  />
                  <Oval
                    height={100}
                    width={100}
                    color="red"
                    wrapperClass={loading === 'GET' ? 'progress-bar-wrapper get' : 'progress-bar-wrapper put'}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#ff6d6d"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                  />
                </div>
              </div>
            )}
            {/* 주화면 */}
            <div
              style={{ width: navHidden === true ? '100%' : '', height: navHidden ? '100vh' : '' }}
              className={location?.pathname === '/login' ? 'main-content login-page' : openOrclose?.side ? 'main-content' : 'main-content close'}
            >
              <Router />
            </div>
          </>
        ) : location?.pathname !== '/login' && !headerObj /*blurred로 처리하면 좋겠다 */ ? (
          <div
            style={{
              zIndex: 1000,
              width: '100vw',
              height: '100vh',
              opacity: 0.3,
              position: 'fixed',
              top: 0,
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Index;

export const handleNewDesignFlag = (value) => {
  if (value === 0) {
    if (window.confirm('이전 디자인으로 돌아가시겠습니까? 언제든 설정을 변경할 수 있습니다.')) {
      localStorage.setItem('useNewDesignFlag', '0');
    } else {
      return;
    }
  } else {
    if (window.confirm('신규 디자인을 적용하시겠습니까? 언제든 설정을 변경할 수 있습니다.')) {
      localStorage.setItem('useNewDesignFlag', '1');
    } else {
      return;
    }
  }
  window.location.href = '/';
};
