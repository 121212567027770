import { useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { statisticsService } from '../../_services/service';
import { InvoiceDayDTO, InvoiceDaySearchDTO } from '../../_interface/order';
import { BottomSheet, IBottomSheetObj } from 'components/template/bottomSheet';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { getToday } from 'common/util/dateParsingFn';
import { PagingListDTO } from 'interface/util';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const columns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '전체 주문 건수',
      dataField: 'totalQuantity',
      style: 'main-total-color',
    },
    {
      headerText: '미지정 주문 건수(%)',
      dataField: 'unpromisedQuantityKr',
    },
    {
      headerText: '지정 주문 건수(%)',
      dataField: 'promisedQuantityKr',
      style: 'sub-total-color',
      headerTooltip: {
        show: true,
        tooltipHtml: `해당 날짜에 지정된 총 주문 건수<br />
        (총 주문 건수 대비 지정 건수)`,
      },
    },
    {
      headerText: '완료 건수(%)',
      dataField: 'finishQuantityByPromisedKr',
      headerTooltip: {
        show: true,
        tooltipHtml: `지정일 대비`,
      },
    },
    {
      headerText: '미완료 건수(%)',
      dataField: 'notFinishQuantityByPromisedKr',
      headerTooltip: {
        show: true,
        tooltipHtml: `지정일 대비`,
      },
    },
    {
      headerText: '취소 건수(%)',
      dataField: 'cancelQuantityKr',
      headerTooltip: {
        show: true,
        tooltipHtml: `지정일 대비 취소 퍼센트`,
      },
    },
    {
      headerText: 'Capa(건수)',
      dataField: 'capacity',
      style: 'sub-total-color',
    },
    {
      headerText: 'Capa 대비 지정 주문 건수(%)',
      dataField: 'promisedQuantityByCapa',
    },
    {
      headerText: 'Capa 대비 완료 건수(%)',
      dataField: 'finishQuantityByCapa',
    },
  ];

  const validationForFetchList = (searchObj: InvoiceDaySearchDTO) => {
    if (!searchObj?.deliveryDate) {
      alert('기준일은 필수 검색자입니다!');
      return false;
    }
    return true;
  };

  const fetchTotal = async (searchObj: InvoiceDaySearchDTO) => {
    const total = await statisticsService.getInvoiceDayTotal(searchObj);

    return total;
  };

  const labellingKr = (data: PagingListDTO<InvoiceDayDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        unpromisedQuantityKr: `${row.unpromisedQuantity}(${row.unpromisedPercentageForTotal}%)`,
        promisedQuantityKr: `${row.promisedQuantity}(${row.promisedPercentageForTotal}%)`,
        finishQuantityByPromisedKr: `${row.finishQuantity}(${row.finishPercentageForPromised}%)`,
        notFinishQuantityByPromisedKr: `${row.notFinishQuantity}(${row.notFinishPercentageForPromised}%)`,
        cancelQuantityKr: `${row.cancelQuantity}(${row.cancelPercentageForPromised}%)`,
        promisedQuantityByCapa: `${row.promisedQuantity}(${row.promisedPercentageForCapacity}%)`,
        finishQuantityByCapa: `${row.finishQuantity}(${row.finishPercentageForCapacity}%)`,
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InvoiceDaySearchDTO) => {
    dispatch(setLoading('GET'));
    setBottomSheetObj(null);
    const dataKr = labellingKr(await statisticsService.getInvoiceDayPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      deliveryDate: getToday(),
    },
    gridRef,
    fetchTotal,
    fetchPaging,
    validationForFetchList,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    if (validationForFetchList(searchObj)) {
      dispatch(setLoading('GET'));
      const { list } = labellingKr({ list: await statisticsService.getInvoiceDayExcelMain(searchObj) }, true);

      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '일별KPI' });

      dispatch(setLoading(null));
    }
  };

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheet bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area only-right">
          <div
            className="green"
            onClick={() => {
              downloadExcel('main');
            }}
          >
            엑셀다운
          </div>
        </div>
        <GridBox //
          gridRef={gridRef}
          columns={columns}
          gridProps={{ showFooter: true, footerPosition: 'top', showRowAllCheckBox: false, showRowCheckColumn: false }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
