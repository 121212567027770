import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
// redux
import { Button, Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import useDebounce from 'hooks/debounceHooks';

import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import { ExplanationD, SelectD } from 'components/reactstrap/reactstrap';
import { InventoryMoveSaveDTO } from 'interface/warehouse';

import * as IGrid from 'aui-grid';

import { IModalObj, ModalPopup } from './component/modal';
import { DetailPopup, IDetailObj } from './component/detail';
import { useNavigate } from 'react-router-dom';
import { locationService } from 'pages/INVENTORY/3location/_services/service';
import { generateUrlByDesignType } from 'components/LDS/utils/path';

const Index = () => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const history = useNavigate();
  const gridRef = useRef<IPagingGrid>();

  const defaultSearchFilter = useMemo(() => {
    return {
      centerCode: reduxUserInfo['centerCode'], // 창고
    };
  }, [reduxUserInfo]);

  const [postObj, setPostObj] = useState<InventoryMoveSaveDTO>({ ...defaultSearchFilter, moveDetails: [] });
  const [modalObj, setModalObj] = useState<IModalObj>();

  useEffect(() => {
    if (modalObj?.returns?.length > 0) {
      const rows = gridRef.current.getGridData();

      const mergesArr = rows.map((ele) => ele.forMerges);
      const forMerges = [...new Set(mergesArr)];
      const selected = detailObj?.selected ? JSON.parse(JSON.stringify(detailObj?.selected)) : {};

      // selected setting
      modalObj?.returns?.forEach((row) => {
        if (!forMerges?.includes(row.forMerges)) {
          selected[row.forMerges] = [];
          forMerges.push(row.forMerges);
        }
        new Array(Number(row.inputQuantity)).fill(0)?.forEach((_, idx) => {
          const obj = {};
          if (row?.availableQuantityForAssigned > 0) obj['moveAvailableInventorySeqList'] = [];
          if (row?.defectedQuantityForAssigned > 0) obj['moveDefectedInventorySeqList'] = [];
          if (row?.etcQuantity > 0) obj['moveEtcInventorySeqList'] = [];

          selected[row.forMerges].push(obj);
          rows.push({
            ...row,
            partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
            centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],

            moveAvailableInventorySeqListKr: '선택',
            moveDefectedInventorySeqListKr: '선택',
            moveEtcInventorySeqListKr: '선택',
            //
            selected: JSON.stringify(selected),
          });
        });
      });
      // sorting
      let newRows = [];
      forMerges?.forEach((ele) => {
        const [modelStockSeq, locationCode] = ele?.split('_');
        const thisChilds = rows.filter((row) => row.modelStockSeq + '' === modelStockSeq + '' && row.locationCode === locationCode);
        newRows = [...newRows, ...thisChilds];
      });
      gridRef.current.setGridData(newRows);
      alert('추가되었습니다!');
      setModalObj((prev) => {
        return {
          ...prev,
          returns: [],
        };
      });
      setDetailObj((prev) => {
        return {
          ...prev,
          selected,
        };
      });
    }
  }, [modalObj?.returns]);

  const [detailObj, setDetailObj] = useState<IDetailObj>();
  const findDetail = (e: IGrid.ButtonClickEvent) => {
    if (
      (e.dataField === 'moveDefectedInventorySeqListKr' && e.item.defectedQuantityForAssigned > 0) ||
      (e.dataField === 'moveAvailableInventorySeqListKr' && e.item.availableQuantityForAssigned > 0) ||
      (e.dataField === 'moveEtcInventorySeqListKr' && e.item.etcQuantity > 0)
    ) {
      const mergeIdx = countForMergeIdx(e.rowIndex, JSON.parse(e.item?.selected));
      setDetailObj((prev) => {
        return {
          ...prev,
          visible: true,
          dataField: e.dataField,
          mergeIdx,
          item: e.item,
        };
      });
    }
  };

  const countForMergeIdx = (rowIdx, selected) => {
    //
    const items = gridRef.current.getGridData();
    const merges = [];
    items?.forEach((ele) => {
      if (!merges?.includes(ele.forMerges)) merges.push(ele?.forMerges);
    });
    const mergesArr = merges?.map((key) => selected?.[key]);
    let mergeIdx = 0;
    let count = 0;
    mergesArr?.forEach((group) => {
      group?.forEach((ele, idx) => {
        if (rowIdx === count) mergeIdx = idx;
        count += 1;
      });
    });
    return mergeIdx;
  };
  const getMergeFlatLikeItems = (items) => {
    const merges = [];
    items?.forEach((ele) => {
      if (!merges?.includes(ele.forMerges)) merges.push(ele?.forMerges);
    });
    const mergesArr = merges?.map((key) => detailObj?.selected?.[key]);
    const mergesFlatLikeItems = mergesArr?.flat();
    return mergesFlatLikeItems;
  };
  // !visible일때 갯수 체크
  useEffect(() => {
    if (detailObj?.selected) {
      const items = gridRef.current.getGridData();
      if (detailObj?.selected && items?.length > 0) {
        const mergesFlatLikeItems = getMergeFlatLikeItems(items);
        if (items?.length > 0) {
          items?.forEach((row, idx) => {
            row.selected = JSON.stringify(detailObj?.selected);
            row.moveDefectedInventorySeqListKr = mergesFlatLikeItems?.[idx]['moveDefectedInventorySeqList']?.length || '선택';
            row.moveDefectedInventorySeqList = JSON.stringify(mergesFlatLikeItems?.[idx]['moveDefectedInventorySeqList']);
            row.moveAvailableInventorySeqListKr = mergesFlatLikeItems?.[idx]['moveAvailableInventorySeqList']?.length || '선택';
            row.moveAvailableInventorySeqList = JSON.stringify(mergesFlatLikeItems?.[idx]['moveAvailableInventorySeqList']);
            row.moveEtcInventorySeqListKr = mergesFlatLikeItems?.[idx]['moveEtcInventorySeqList']?.length || '선택';
            row.moveEtcInventorySeqList = JSON.stringify(mergesFlatLikeItems?.[idx]['moveEtcInventorySeqList']);
          });
          gridRef.current.setGridData(items);
        }
      }
    }
  }, [detailObj?.selected]);

  const columns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      cellMerge: true,
      mergeRef: 'forMerges',
      mergePolicy: 'restrict',
      editable: false,
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
      mergeRef: 'forMerges',
      mergePolicy: 'restrict',
      cellMerge: true,
      editable: false,
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
      mergeRef: 'forMerges',
      mergePolicy: 'restrict',
      cellMerge: true,
      editable: false,
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
      mergeRef: 'forMerges',
      mergePolicy: 'restrict',
      cellMerge: true,
      editable: false,
    },
    {
      headerText: '제품',
      dataField: 'model',
      mergeRef: 'forMerges',
      mergePolicy: 'restrict',
      cellMerge: true,
      editable: false,
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
      editable: false,
      mergeRef: 'forMerges',
      mergePolicy: 'restrict',
      cellMerge: true,
    },

    {
      headerText: '바코드',
      dataField: 'barcode',
      mergeRef: 'forMerges',
      mergePolicy: 'restrict',
      cellMerge: true,
      editable: false,
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
      mergeRef: 'forMerges',
      mergePolicy: 'restrict',
      cellMerge: true,
      editable: false,
    },
    {
      headerText: '로케이션<br/>총 수량',
      dataField: 'totalQuantity',
      mergeRef: 'forMerges',
      mergePolicy: 'restrict',
      cellMerge: true,
      editable: false,
    },
    {
      headerText: '이동불가능<br/>수량',
      dataField: 'unavailableQuantity',
      mergeRef: 'forMerges',
      mergePolicy: 'restrict',
      cellMerge: true,
      editable: false,
      headerTooltip: {
        show: true,
        tooltipHtml: '<div style="width:150px;"><div>이동불가능수량은</div><div>피킹지시확정 상태이거나</div><div>로케이션 미지정된 재고입니다.</div></div>',
      },
    },
    {
      headerText: '이동가능수량',
      children: [
        {
          headerText: '불용미할당재고',
          dataField: 'defectedQuantityForUnassigned',
          mergeRef: 'forMerges',
          mergePolicy: 'restrict',
          cellMerge: true,
          editable: false,
        },
        {
          headerText: '불용할당재고',
          dataField: 'defectedQuantityForAssigned',
          mergeRef: 'forMerges',
          mergePolicy: 'restrict',
          cellMerge: true,
          editable: false,
        },
        {
          headerText: '양품미할당재고',
          dataField: 'availableQuantityForUnassigned',
          mergeRef: 'forMerges',
          mergePolicy: 'restrict',
          cellMerge: true,
          editable: false,
        },

        {
          headerText: '양품할당재고',
          dataField: 'availableQuantityForAssigned',
          mergeRef: 'forMerges',
          mergePolicy: 'restrict',
          cellMerge: true,
          editable: false,
        },
        {
          headerText: '고객보관재고',
          dataField: 'etcQuantity',
          mergeRef: 'forMerges',
          mergePolicy: 'restrict',
          cellMerge: true,
          editable: false,
        },
      ],
    },
    {
      headerText: '이동수량',
      children: [
        {
          headerText: '불용미할당재고',
          dataField: 'moveDefectedQuantity',
          headerTooltip: {
            show: true,
            tooltipHtml: '<div style="width:150px;"><div>불용미할당재고는</div><div>재고조정을 통해</div><div>불용으로 전환된 재고입니다.</div></div>',
          },
          style: 'aui-textinputer',
          editable: true,
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            autoThousandSeparator: true,
            onlyNumeric: true,
            allowNegative: false,
          },
        },
        {
          headerText: '불용할당재고',
          dataField: 'moveDefectedInventorySeqListKr',
          headerTooltip: {
            show: true,
            tooltipHtml: '<div style="width:150px;"><div>불용할당재고는</div><div>시리얼번호를 관리하는 제품 중</div><div>박스 개봉 후 취소되어 회수입고된 재고입니다.</div></div>',
          },
          editable: false,
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: findDetail,
            disabledFunction(rowIndex, columnIndex, value, item, dataField) {
              if (item?.defectedQuantityForAssigned === 0) return true;
              return false;
            },
          },
        },
        {
          headerText: '양품미할당재고',
          dataField: 'moveAvailableQuantity',
          headerTooltip: {
            show: true,
            tooltipHtml: '<div style="width:150px;"><div>양품미할당재고는</div><div>주문에 할당되지 않은</div><div>사용 가능한 안전재고입니다.</div></div>',
          },
          style: 'aui-textinputer',
          editable: true,
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            validator: (oldValue, newValue, item, dataField, fromClipboard, which) => {
              if (item?.mtoFlag) return { validate: false, message: '수정할 수 없습니다' };
            },
            autoThousandSeparator: true,
            onlyNumeric: true,
            allowNegative: false,
          },
        },

        {
          headerText: '양품할당재고',
          dataField: 'moveAvailableInventorySeqListKr',
          headerTooltip: {
            show: true,
            tooltipHtml: '<div style="width:150px;"><div>양품할당재고는</div><div>주문에 할당된 재고입니다.</div></div>',
          },
          editable: false,
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: findDetail,
            disabledFunction(rowIndex, columnIndex, value, item, dataField) {
              if (item?.availableQuantityForAssigned === 0) return true;
              return false;
            },
          },
        },
        {
          headerText: '고객보관재고',
          dataField: 'moveEtcInventorySeqListKr',
          headerTooltip: {
            show: true,
            tooltipHtml: '<div style="width:150px;"><div>고객보관재고는</div><div>이전 또는 수리를 통해</div><div>회수입고된 재고입니다.</div></div>',
          },
          editable: false,
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: findDetail,
            disabledFunction(rowIndex, columnIndex, value, item, dataField) {
              if (item?.etcQuantity === 0) return true;
              return false;
            },
          },
        },
      ],
    },
    {
      headerText: '이동로케이션',
      dataField: 'moveLocationCode',
      style: 'aui-textinputer',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
      },
    },
  ];

  const validCreateRegister = (postObj, items) => {
    if (!postObj?.centerCode) {
      alert('창고는 필수값입니다');
      return false;
    }

    return true;
  };

  const moveActionRegister = useCallback(
    useDebounce(async (postObj, selected) => {
      const items = gridRef.current.getGridData();
      if (validCreateRegister(postObj, items)) {
        dispatch(setLoading('POST'));

        const categorized = Object.keys(selected)?.map((ele) => {
          const [modelStockSeq, locationCode] = ele.split('_');
          const filteredRow = items?.filter((item) => {
            if (item.locationCode === locationCode && item.modelStockSeq + '' === modelStockSeq + '') return true;
            return false;
          });
          return {
            centerCode: postObj?.centerCode,
            destinationCenterCode: postObj?.destinationCenterCode,
            locationCode,
            modelStockSeq,
            moveDetails: Object.keys(selected?.[ele])?.map((key) => {
              //
              return {
                ...selected?.[ele][key],
                moveAvailableQuantity: filteredRow?.[key]?.moveAvailableQuantity ? Number(filteredRow?.[key]?.moveAvailableQuantity) : 0,
                moveDefectedQuantity: filteredRow?.[key]?.moveDefectedQuantity ? Number(filteredRow?.[key]?.moveDefectedQuantity) : 0,
                moveLocationCode: filteredRow?.[key]?.moveLocationCode,
              };
            }),
          };
        });
        const rs = await locationService.postInventoryMoveAction('inventory/move/save', null, categorized);
        if (rs?.status === 200) {
          alert(postObj?.destinationCenterCode ? '창고간 이동이 완료되었습니다' : '재고 이동이 완료되었습니다');
          history(generateUrlByDesignType('/moveActionList'));
        }
        dispatch(setLoading(null));
      }
    }, 500),
    [],
  );

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'INV_MOVEACTION_REGIT_ADDROW') {
      if (!postObj?.centerCode) {
        alert('창고는 필수값입니다!');
      } else {
        setModalObj({ visible: true, centerCode: postObj?.centerCode });
      }
    } else {
      handleCheckItems(id);
    }
  };

  const arrangeRows = (checked, rows) => {
    const newRow = rows?.filter((_, idx) => !checked?.includes(idx));
    const mergesArr = newRow.map((ele) => ele.forMerges);
    const forMerges = [...new Set(mergesArr)];

    newRow?.forEach((row, idx) => {
      if (!forMerges?.includes(row.forMerges)) {
        selected[row.forMerges] = [];
        forMerges.push(row.forMerges);
      }
    });

    const selected = {};
    forMerges?.forEach((ele: string) => {
      selected[ele] = newRow
        ?.filter((row) => row.forMerges === ele)
        ?.map((row) => {
          return {
            moveAvailableInventorySeqList: row.moveAvailableInventorySeqList ? JSON.parse(row.moveAvailableInventorySeqList) : [],
            moveDefectedInventorySeqList: row.moveDefectedInventorySeqList ? JSON.parse(row.moveDefectedInventorySeqList) : [],
            moveEtcInventorySeqList: row.moveEtcInventorySeqList ? JSON.parse(row.moveEtcInventorySeqList) : [],
          };
        });
    });
    gridRef.current.setGridData(newRow);
    setDetailObj({ selected });
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItems();
    if (items?.length > 0) {
      const wholeRows = gridRef.current.getGridData();
      if (id === 'INV_MOVEACTION_REGIT_DELROW') {
        const checked = items.map((ele) => ele.rowIndex);
        arrangeRows(checked, wholeRows);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  return (
    <>
      <div className="page-content">
        {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
        {modalObj?.visible && <ModalPopup modalObj={modalObj} setModalObj={setModalObj} />}
        <Row style={{ textAlign: 'right' }}>
          <Col>
            <Button
              className="btn-blue btn"
              onClick={() => {
                if (postObj?.destinationCenterCode) {
                  if (window.confirm('창고간 이동을 진행하시겠습니까? 일반 재고이동을 원하시는 경우 선택하신 이동할 창고를 해제해주세요')) {
                    moveActionRegister(postObj, detailObj?.selected);
                  }
                } else {
                  if (window.confirm('재고이동을 진행하시겠습니까?')) {
                    moveActionRegister(postObj, detailObj?.selected);
                  }
                }
              }}
            >
              이동 확정
            </Button>
          </Col>
        </Row>
        <div className="presenterInput">
          <p>1. 기본 주문정보</p>
          <Row>
            <Col>
              <label className="col-form-label">*창고</label>
              <SelectD
                options={MASTER_OPS?.CENTER_AUTH}
                value={
                  postObj?.centerCode
                    ? {
                        value: postObj?.centerCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[postObj?.centerCode],
                      }
                    : null
                }
                onChange={(option) => {
                  const items = gridRef.current.getGridData();
                  if (postObj?.centerCode && (option as OptionItem).value !== postObj?.centerCode && items?.length > 0) {
                    if (window.confirm('창고 변경 시 입력한 정보가 초기화됩니다. 변경하시겠습니까?')) {
                      gridRef.current.setGridData([]);
                      setDetailObj((prev) => {
                        return {
                          ...prev,
                          selected: null,
                        };
                      });
                      setPostObj({
                        ...postObj,
                        centerCode: (option as OptionItem).value,
                      });
                    }
                  } else {
                    setPostObj({
                      ...postObj,
                      centerCode: (option as OptionItem).value,
                    });
                  }
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">
                이동할 창고 <ExplanationD title="이동할 창고" content={['창고간 이동을', '진행하는 경우에만', '이동시킬 창고를 선택해주세요.']} />
              </label>
              <SelectD
                options={MASTER_OPS?.CENTER_AUTH}
                value={
                  postObj?.destinationCenterCode
                    ? {
                        value: postObj?.destinationCenterCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[postObj?.destinationCenterCode],
                      }
                    : null
                }
                onChange={(option) => {
                  setPostObj({
                    ...postObj,
                    destinationCenterCode: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col />
            <Col />
            <Col />
            <Col />
          </Row>
        </div>
        <div>
          <div className="presenterInput">
            <div className="presenterGridBox">
              <p>2. 재고이동 상세 정보</p>
              <div className="grid-button-area">
                <div id={`INV_MOVEACTION_REGIT_ADDROW`} className={`blue`} onClick={gridButtonhandler}>
                  +추가
                </div>
                <div id={`INV_MOVEACTION_REGIT_DELROW`} className={`red`} onClick={gridButtonhandler}>
                  -삭제
                </div>
              </div>
              <GridBox
                gridRef={gridRef}
                columns={columns}
                gridProps={{
                  showRowAllCheckBox: true,
                  showRowCheckColumn: true,
                  enableCellMerge: true,
                  editable: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
