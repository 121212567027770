export const COLORS = {
  PRIMARY: '#3042E1',
  PRIMARY_DEEP: '#2436D5',
  PRIMARY_DEEP_MORE: '#1A2CD3',
  PRIMARY_004: 'rgba(63, 80, 229, 0.04)',
  PRIMARY_008: 'rgba(63, 80, 229, 0.08)',
  PRIMARY_015: 'rgba(63, 80, 229, 0.15)',
  WHITE: '#FFF',
  GRAY_01: '#FAFAFA',
  GRAY_01_DEEP: '#F5F5F5',
  GRAY_02: '#F3F3F3',
  GRAY_02_DEEP: '#F0F0F0',
  GRAY_03: '#EAEAEA',
  GRAY_03_DEEP: '#DADADA',
  GRAY_04: '#AEAEAE',
  GRAY_04_DEEP: '#9A9A9A',
  GRAY_05: '#878787',
  GRAY_05_DEEP: '#686868',
  GRAY_06: '#545454',
  GRAY_06_DEEP: '#505050',
  GRAY_07: '#484848',
  GRAY_08: '#2D2D2D',
  GRAY_09: '#121212',
  BLACK: '#000',
  BLACK_004: 'rgba(0,0,0, 0.04)',
  BLACK_008: 'rgba(0,0,0, 0.08)',
  BLACK_015: 'rgba(0,0,0, 0.15)',
  BLACK_020: 'rgba(0,0,0, 0.2)',
  BLACK_030: 'rgba(0,0,0, 0.3)',
  BLACK_040: 'rgba(0,0,0, 0.4)',
  BLACK_050: 'rgba(0,0,0, 0.5)',
  BLACK_060: 'rgba(0,0,0, 0.6)',
  BLACK_070: 'rgba(0,0,0, 0.7)',
  ALERT: '#FD5C51',
  ALERT_DEEP: '#EF4B40',
  ALERT_DEEP_MORE: '#E13B30',
  ALERT_LIGHT: '#F69892',
  ALERT_015: 'rgba(253, 92, 81, 0.15)',
  WARNING: '#FF910F',
  WARNING_DEEP: '#F28403',
  WARNING_DEEP_MORE: '#E57C00',
  WARNING_LIGHT: '#FFBE72',
  WARNING_015: 'rgba(255, 145, 15, 0.15)',
  SUCCESS: '#34BC90',
  SUCCESS_DEEP: '#25AB7F',
  SUCCESS_DEEP_MORE: '#14A072',
  SUCCESS_LIGHT: '#98DCC6',
  SUCCESS_015: 'rgba(52, 188, 144, 0.15)',
  GREEN: '#036F3B',
  GREEN_DEEP: '#005B2F',
  GREEN_DEEP_MORE: '#004B27',
  GREEN_015: 'rgba(3, 111, 59, 0.15)',
  BLUE: '#2196F3',
  BLUE_DEEP: '#0B87EA',
  BLUE_DEEP_MORE: '#0078D8',
  BLUE_LIGHT: '#8CC9FA',
  BLUE_015: 'rgba(33, 150, 243, 0.15)',
  PURPLE: '#9747FF',
  PURPLE_DEEP: '#8837F1',
  PURPLE_DEEP_MORE: '#7822E7',
  PURPLE_LIGHT: '#B884FC',
  PURPLE_015: 'rgba(151, 71, 255, 0.15)',
} as const;

export const SIZES = {
  SIZE_XS: 24,
  SIZE_SM: 36,
  SIZE_MD: 64,
  SIZE_LG: 80,
  SIZE_XL: 120,
} as const;

export const WIDTHS = {
  LAYOUT_XXS_WIDTH: 200,
  LAYOUT_XS_WIDTH: 240,
  LAYOUT_SM_WIDTH: 320,
  LAYOUT_MD_WIDTH: 480,
  LAYOUT_LG_WIDTH: 640,
  LAYOUT_XL_WIDTH: 990,
  LAYOUT_XXL_WIDTH: 1400,
  BUTTON_SM_WIDTH: 80,
  BUTTON_MD_WIDTH: 140,
  BUTTON_LG_WIDTH: 200,
} as const;

export const BREAKPOINT = {
  BL_XS: 480,
  BL_SM: 720,
  BL_MD: 1024,
  BP_LG: 1200,
  BP_XL: 1600,
} as const;

export const VALUES = {
  RADIUS: 5,
  BUFFER: 12,
  NOTIFICATION_MIN_HEIGHT: 400,
  ONBOARDING_MODAL_IMG_MIN_HEIGHT: 400,
  ONBOARDING_MODAL_CONTENT_WIDTH: 300,
  SIDEBAR_EXPANDED: 224,
  SIDEBAR_COLLAPSED: 64,
  DROPDOWN_MIN_HEIGHT: 200,
  DROPDOWN_MAX_HEIGHT: 480,
  FIELD_ITEM_MIN_WIDTH: 186,
  GRID_GAP: 8,
  MODAL_Z_INDEX: 999,
  DIALOG_Z_INDEX: 1001,
  DROPDOWN_Z_INDEX: 1101,
  TOOLTIP_Z_INDEX: 1201,
  SIDEBAR_TRANSITION_TIME: 250,
  SEARCH_THROTTLE_BUFFER: 500,
  ONBOARDING_LOTATION_TIME_01: 30500,
  ONBOARDING_LOTATION_TIME_02: 13900,
  ONBOARDING_LOTATION_TIME_03: 11840,
  LOGIN_BUFFER_TIME: 2500,
  DYNAMIC_LOADING_BUFFER_TIME: 2000,
} as const;

export const TRANSITIONS = {
  BEZIER: 'cubic-bezier(0.44, 0.13, 0.37, 1.07)',
  SMOOTHY: 'cubic-bezier(0.52, 0.53, 0.12, 0.89)',
  BOUNCY: 'cubic-bezier(0.67, 0.24, 0.14, 1.12)',
  QUICK: 'cubic-bezier(0.06, 0.92, 0.4, 1.19)',
} as const;
