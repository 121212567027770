import { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { feeService } from 'pages/FEE/_service/service';
import { OrderReceiptCalculateDTO, OrderReceiptSearchDTO } from '../../../_interface/order';
import { receiptCategoryChanger } from '../..';
import { useLocation } from 'react-router-dom';

export interface ICalculateReceipt {
  visible?: boolean;
  searchObj?: OrderReceiptSearchDTO;
  fetchFn?: (data) => {};
}

export const CalculateReceiptPopup = ({ calculateReceiptObj, setCalculateReceiptObj }) => {
  const location = useLocation();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [obj, setObj] = useState<OrderReceiptCalculateDTO>();

  const saveObj = async (obj) => {
    const data = {
      ...obj,
      invoiceTypes: obj?.invoiceTypes,
      invoiceStatuses: obj?.invoiceStatuses,
    };
    const rs = await feeService.postReceiptCalculate(data);
    if (rs?.status === 200) {
      alert('영수증을 재산정하였습니다!');
      calculateReceiptObj?.fetchFn(JSON.parse(calculateReceiptObj?.searchObj));
      setCalculateReceiptObj(null);
    }
  };

  return (
    <CommonModalNew
      style={{ width: 350 }}
      title={'영수증 재산정'}
      visible={calculateReceiptObj?.visible}
      setVisible={() => {
        setCalculateReceiptObj((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
      }}
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">판매사</label>
              <SelectD
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                value={
                  obj?.partnerSeq
                    ? {
                        value: obj?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[obj?.partnerSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setObj({
                    ...obj,
                    partnerSeq: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">송장유형</label>
              <SelectD
                isMulti
                height100
                options={MASTER_OPS?.FEE_TYPE}
                value={obj?.invoiceTypes?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.FEE_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setObj({
                    ...obj,
                    invoiceTypes: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">송장상태</label>
              <SelectD
                isMulti
                height100
                options={MASTER_OPS?.INVOICE_STATUS}
                value={obj?.invoiceStatuses?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.INVOICE_STATUS?.[ele],
                  };
                })}
                onChange={(option) => {
                  setObj({
                    ...obj,
                    invoiceStatuses: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            {receiptCategoryChanger[location.pathname] === 'SUBCONTRACT' ? (
              <Col>
                <label className="col-form-label">인프라</label>
                <SelectD
                  options={MASTER_OPS?.INFRA}
                  value={
                    obj?.infraSeq
                      ? {
                          value: obj?.infraSeq,
                          label: MASTER_OBJ?.INFRA?.[obj?.infraSeq],
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    setObj({
                      ...obj,
                      infraSeq: value,
                    });
                  }}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            {receiptCategoryChanger[location.pathname] === 'CENTER' || receiptCategoryChanger[location.pathname] === 'DRIVER' ? (
              <Col>
                <label className="col-form-label">창고</label>
                <SelectD
                  options={MASTER_OPS?.CENTER_AUTH}
                  value={
                    obj?.centerCode
                      ? {
                          value: obj?.centerCode,
                          label: MASTER_OBJ?.CENTER_AUTH?.[obj?.centerCode],
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    setObj({
                      ...obj,
                      centerCode: value,
                    });
                  }}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>
          {receiptCategoryChanger[location.pathname] === 'DRIVER' ? (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">설치팀등급</label>
                  <SelectD
                    options={MASTER_OPS?.TEAM_GRADE}
                    value={
                      obj?.teamGrade
                        ? {
                            value: obj?.teamGrade,
                            label: MASTER_OBJ?.TEAM_GRADE?.[obj?.teamGrade],
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setObj({
                        ...obj,
                        teamGrade: value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">소속</label>
                  <SelectD
                    options={MASTER_OPS?.DRIVER_PARTY}
                    value={
                      obj?.party
                        ? {
                            value: obj?.party,
                            label: MASTER_OBJ?.DRIVER_PARTY?.[obj?.party],
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setObj({
                        ...obj,
                        party: value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          <Row>
            <Col style={{ textAlign: 'right' }}>
              <button
                className="btn-secondary btn"
                onClick={(e) => {
                  saveObj(obj);
                }}
              >
                재산정
              </button>
            </Col>
          </Row>
        </>
      }
    />
  );
};
