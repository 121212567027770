import { ReactNode, useMemo, useRef, useState } from 'react';
import * as IGrid from 'aui-grid';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';

// service
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { serviceStore } from 'services/services';
import { QuestionAndAnswerDTO, QuestionAndAnswerSearchDTO } from 'interface/user';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { RootState } from 'redux/store';
import QnAModal, { IQnaObj } from './component/qnaModal';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';

const Index = ({ tabId }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const [searchObj, setSearchObj] = useState<QuestionAndAnswerSearchDTO>();
  const [qnaObj, setQnaObj] = useState<IQnaObj>();
  const gridRef = useRef<IPagingGrid>();

  const labellingKr = (data: QuestionAndAnswerDTO[]) => {
    const labeledList = data?.map((row) => {
      return {
        ...row,
        statusKr: selectlabel(row.status, masterOptions?.QNA_STATUS),
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER),
        inquiryTypeLargeKr: selectlabel(row.inquiryTypeLarge, masterOptions?.QNA_TYPE1),
        inquiryTypeMediumKr: selectlabel(row.inquiryTypeMedium, masterOptions?.QNA_TYPE2),
        inquiryTypeSmallKr: selectlabel(row.inquiryTypeSmall, masterOptions?.QNA_TYPE3),
      };
    });
    return labeledList;
  };

  const fetchList = async (searchObj: QuestionAndAnswerSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.userAction(`question/web`, 'GET', searchObj, null))?.data as QuestionAndAnswerDTO[]);
    gridRef?.current?.setGridData(dataKr);
    dispatch(setLoading(null));
  };

  const fetchDetail = (e: IGrid.ButtonClickEvent) => {
    if (e?.item?.questionAndAnswerSeq) {
      setQnaObj({
        visible: true,
        questionAndAnswerSeq: e?.item?.questionAndAnswerSeq,
        searchObj,
        fetchFn: fetchList,
      });
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetail,
      },
    },
    {
      headerText: '접수번호',
      dataField: 'questionAndAnswerSeq',
    },
    {
      headerText: '지시번호',
      dataField: 'tableSeq',
    },
    {
      headerText: '소속창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '기사명',
      dataField: 'userName',
    },
    {
      headerText: '고객명',
      dataField: 'questionedCustomerName',
    },
    {
      headerText: '문의유형1',
      dataField: 'inquiryTypeLargeKr',
    },
    {
      headerText: '문의유형2',
      dataField: 'inquiryTypeMediumKr',
    },
    {
      headerText: '문의유형3',
      dataField: 'inquiryTypeSmallKr',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        // onClick: changeStatus,
      },
    },
    {
      headerText: '담당자',
      dataField: 'answerUserName',
    },
    {
      headerText: 'CS접수일',
      dataField: 'questionDatetime',
    },
    {
      headerText: 'CS처리완료일',
      dataField: 'answerDatetime',
    },
    {
      headerText: 'CS수정일',
      dataField: 'answerUpdateDatetime',
    },
  ];

  const removeData = async (items) => {
    if (window.confirm(`정말 삭제하시겠습니까?`)) {
      const rs = await serviceStore.userAction(`question/delete`, 'DELETE', null, { questionAndAnswerSeqs: items?.map((ele) => ele.questionAndAnswerSeq) });
      if (rs?.status === 200) {
        alert('삭제되었습니다!');
        fetchList(searchObj);
      }
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'DRIVERCSREMOVE') removeData(items);
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };
  const downloadExcel = async (type = 'main') => {
    const prompt = window.confirm(`엑셀다운로드를 시작합니다. \n※ 많은 양의 데이터는 30초 이상의 시간이 소요될 수 있습니다.`);
    if (prompt) {
      dispatch(setLoading('GET'));
      gridRef?.current?.exportAsXlsx({ fileName: '설치기사상담' });
      dispatch(setLoading(null));
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'DRIVERCSEXCEL') {
      downloadExcel();
    } else {
      handleCheckItems(id);
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {qnaObj?.visible && <QnAModal qnaObj={qnaObj} setQnaObj={setQnaObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={fetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            pageRowCount: GRID_PAGE_ROW_COUNT,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
      </div>
    </div>
  );
};

export default Index;
