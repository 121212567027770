import { useEffect, useRef, useState } from 'react';
import CommonModalNew from 'components/modal/commonModalNew';
import ReactToPrint from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { TransportStatementDetailListDTOForUnload, WarehouseDTO } from 'interface/warehouse';
import styled from 'styled-components';
import { RAWDATA, setLoading } from 'redux/services/menuSlice';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import ModalforBarcode, { PalletAndSign, PrintTitle } from 'components/print/print';
import { serviceStore } from 'services/services';

const PrintWrapper = styled.div({
  position: 'relative',
  padding: 30,
  minHeight: '297mm',
  width: '100%',
  marginBottom: 10,
});

export interface IIpgoPrint {
  visible?: Boolean;
  transportStatementSeq?: number;
}

export const ConfirmIpgo = ({ printObj, setPrintObj }) => {
  const { MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>();
  const [data, setData] = useState<TransportStatementDetailListDTOForUnload>();
  const [center, setCenter] = useState<{ oc: WarehouseDTO; dc: WarehouseDTO }>();
  useEffect(() => {
    fetchPrintData();
  }, []);

  const fetchPrintData = async () => {
    dispatch(setLoading('GET'));
    const data = (await serviceStore.warehouseAction(`linehaul/loading/unload?transportStatementSeq=${printObj.transportStatementSeq}`, 'GET', null))?.data;
    const detailData = (await serviceStore.warehouseAction(`linehaul/loading/unload/${printObj.transportStatementSeq}`, 'GET', null))?.data;
    const dataDTO = {
      ...data?.[0],
      ...detailData?.[0],
      detailData,
    };
    if (dataDTO) {
      setData(dataDTO);
      const oc = MASTER_OPS?.[`${RAWDATA}CENTER_WHOLE`]?.filter((ele) => ele.centerCode === dataDTO.originalCenterCode)?.[0];
      const dc = MASTER_OPS?.[`${RAWDATA}CENTER_WHOLE`]?.filter((ele) => ele.centerCode === dataDTO.destinationCenterCode)?.[0];
      setCenter({ oc, dc });
    }
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      visible={printObj?.visible}
      style={{ width: 1000 }}
      setVisible={() => {
        setPrintObj(null);
      }}
      title={
        <>
          <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
        </>
      }
      children={
        <>
          <div ref={wrapperRef}>
            <PrintWrapper className="break-for-onePage-print">
              <PrintTitle seq={printObj?.transportStatementSeq} title={'간선입고 확정 명세서'} />
              {data && (
                <>
                  <BasicInfo detailInfo={data} center={center} />
                  <LinehaulDetail detailInfo={data} center={center} />
                </>
              )}
              <PalletAndSign signWhen="* 상.하차 / 검수 완료 후 서명 하시오" signArr={['작업자']} contentArr={['']} />
            </PrintWrapper>
          </div>
        </>
      }
    />
  );
};

const LinehaulDetail = (props) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { detailInfo } = props;
  const [sums, setSums] = useState<{ [key: string]: number }>();
  const [barcodeInfoObj, setBarcodeInfoObj] = useState({
    visible: false,
    data: null,
  });

  useEffect(() => {
    if (detailInfo) {
      let sumavailableQuantity = 0,
        sumdefectedQuantity = 0,
        sumetcQuantity = 0,
        sumunloadAvailableQuantity = 0,
        sumunloadDefectedQuantity = 0,
        sumunloadEtcQuantity = 0,
        sumavailableDiff = 0,
        sumdefectedDiff = 0,
        sumetcDiff = 0;
      detailInfo?.detailData?.forEach((row) => {
        sumavailableQuantity += row.availableQuantity;
        sumdefectedQuantity += row.defectedQuantity;
        sumetcQuantity += row.etcQuantity;
        sumunloadAvailableQuantity += row.unloadAvailableQuantity;
        sumunloadDefectedQuantity += row.unloadDefectedQuantity;
        sumunloadEtcQuantity += row.unloadEtcQuantity;
        sumavailableDiff += row.availableDiff;
        sumdefectedDiff += row.defectedDiff;
        sumetcDiff += row.etcDiff;
      });
      setSums({
        sumavailableQuantity,
        sumdefectedQuantity,
        sumetcQuantity,
        sumunloadAvailableQuantity,
        sumunloadDefectedQuantity,
        sumunloadEtcQuantity,
        sumavailableDiff,
        sumdefectedDiff,
        sumetcDiff,
      });
    }
  }, [detailInfo]);
  return (
    <div className="presenterInput">
      {barcodeInfoObj?.visible && <ModalforBarcode barcodeInfoObj={barcodeInfoObj} setBarcodeInfoObj={setBarcodeInfoObj} />}

      <p>2. 상품 정보</p>
      <div> *입고예정수량 (= 출차수량), *로케이션 지정수량 (= 입고수량), *미입고수량 (= 출차 - 로케이션지정 수량)</div>
      <table className="border-table" id="table_detail">
        <thead className="text-center">
          <tr>
            <th colSpan={14}>제품정보</th>
            <th colSpan={3}>입고예정 수량</th>
            <th colSpan={3}>입고완료 수량</th>
            <th colSpan={3}>미입고 수량</th>
          </tr>
          <tr>
            <th colSpan={2}>바코드</th>
            <th colSpan={2}>SKU</th>
            <th colSpan={2}>외부SKU</th>
            <th colSpan={2}>판매사</th>
            <th colSpan={2}>입고로케이션</th>
            <th colSpan={4}>제품</th>
            {/* 9 */}
            <th>양품</th>
            <th>불용</th>
            <th>기타</th>
            <th>양품</th>
            <th>불용</th>
            <th>기타</th>
            <th>양품</th>
            <th>불용</th>
            <th>기타</th>
          </tr>
        </thead>

        <tbody style={{ textAlign: 'center' }}>
          {detailInfo?.detailData?.map((list, idx) => (
            <tr key={`sums_${idx}`}>
              <td
                colSpan={2}
                key={`${idx}-barcode`}
                id={`${idx}-barcode2323`}
                onClick={() => {
                  setBarcodeInfoObj((prev) => {
                    return {
                      ...prev,
                      visible: true,
                      data: list.barcode,
                    };
                  });
                }}
              >
                {list.barcode}
              </td>
              <td colSpan={2}>{list.modelStockSeq}</td>
              <td colSpan={2}>{list.skuNumber}</td>
              <td colSpan={2}>{MASTER_OBJ?.SELLER_WHOLE?.[list?.partnerSeq] || ''}</td>
              <td colSpan={2}>{list.locationCode}</td>
              <td colSpan={4}>{list.model}</td>
              <td>{list.availableQuantity}</td>
              <td>{list.defectedQuantity}</td>
              <td>{list.etcQuantity}</td>
              <td>{list.unloadAvailableQuantity}</td>
              <td>{list.unloadDefectedQuantity}</td>
              <td>{list.unloadEtcQuantity}</td>
              <td>{list.availableDiff}</td>
              <td>{list.defectedDiff}</td>
              <td>{list.etcDiff}</td>
            </tr>
          ))}
        </tbody>
        <tfoot className="text-center">
          <tr>
            <td colSpan={10}></td>
            <td colSpan={4}>합 계</td>
            <td>{sums?.sumavailableQuantity}</td>
            <td>{sums?.sumdefectedQuantity}</td>
            <td>{sums?.sumetcQuantity}</td>
            <td>{sums?.sumunloadAvailableQuantity}</td>
            <td>{sums?.sumunloadDefectedQuantity}</td>
            <td>{sums?.sumunloadEtcQuantity}</td>
            <td>{sums?.sumavailableDiff}</td>
            <td>{sums?.sumdefectedDiff}</td>
            <td>{sums?.sumetcDiff}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const BasicInfo = ({ detailInfo, center }) => {
  return (
    <div className="presenterInput">
      <span>1. 입고정보</span>
      <table className="border-table">
        <thead>
          <tr>
            <th>출차일</th>
            <td colSpan={6}>{detailInfo?.linehaulDate}</td>
          </tr>
          <tr>
            <th>입고예정일</th>
            <td colSpan={6}>{detailInfo?.promiseUnloadDate}</td>
          </tr>
          <tr>
            <th>출고창고</th>
            <td colSpan={3}>{`${center?.oc ? center?.oc?.centerName + ' / ' + center?.oc?.address + ' ' + (center?.oc?.addressDetail || '') : ''}`}</td>
            <th>관리자</th>
            <td colSpan={2}>{`${center?.oc ? center?.dc?.managerName + ' / ' + center?.oc?.managerPhone : ''}`}</td>
          </tr>
          <tr>
            <th>입고창고</th>
            <td colSpan={3}>{`${center?.dc ? center?.oc?.centerName + ' / ' + center?.dc?.address + ' ' +( center?.dc?.addressDetail || '') : ''}`}</td>
            <th>관리자</th>
            <td colSpan={2}>{`${center?.dc ? center?.dc?.managerName + ' / ' + center?.dc?.managerPhone : ''}`}</td>
          </tr>
          <tr>
            <th>운송차량</th>
            <td colSpan={3}>{`${detailInfo?.loadingDriverCarType || ''} ${detailInfo?.loadingDriverCarNumber ? ' / ' + detailInfo?.loadingDriverCarNumber : ''}`}</td>
            <th>운송기사</th>
            <td colSpan={2}>{`${detailInfo?.loadingDriverName || ''} ${detailInfo?.loadingDriverPhone ? ' / ' + detailInfo?.loadingDriverPhone : ''}`}</td>
          </tr>
        </thead>
      </table>
    </div>
  );
};
