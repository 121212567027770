import { v4 as uuidv4 } from 'uuid';
// react
import { useEffect, useState } from 'react';

// utils
import { selectlabel } from 'common/master/codeMasterReturnHelper';

// http
import { httpClient } from 'common/http-client/axiosConfig';
import { BAECHA_LIST, INVENTORY_LOCATION_LISTS } from 'envVar';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';

// component
import Presenter from './presenter';
import { XLSX, checkHTML5Brower, fixdata, parseCvs, process_wb, rABS } from 'common/util/fileUploader';

const Container = (props) => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state) => state.menu);
  const [fileData, setFileData] = useState();

  // grid
  const [rows, setRows] = useState([]);
  const [gridId, setGridId] = useState(`baecha2_Main${uuidv4()}`);

  // grid-function
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  //검색자
  const [searchObj, setSearchObj] = useState({
    centerCode: null,
    partnerSeq: null,
    locationCode: null,
    barcode: null,
  });

  const [movePopupObj, setMovePopupObj] = useState({
    visible: false,
  });

  useEffect(() => {
    if (masterOptions) {
      fetchList();
    }
  }, []);

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'delete-baecha') {
        deleteBaecha(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const saveBaecha = async (items) => {
    let url = BAECHA_LIST;
    httpClient.post(url, items).then((rs) => {
      if (rs?.status === 200) {
        alert('배차가 저장되었습니다');
        fetchList();
      }
    });
  };

  const deleteBaecha = async (items) => {
    const data = {
      transportationSeqs: [],
    };
    items.forEach((item) => {
      data.transportationSeqs.push(item.transportationSeq);
    });
    let url = BAECHA_LIST;
    httpClient.delete(url, { data }).then((rs) => {
      if (rs?.status === 200) {
        alert('배차삭제에 성공하였습니다!');
        fetchList();
      }
    });
  };

  useEffect(() => {
    if (fileData) {
      if (window.confirm('엑셀 업로드를 시작합니다')) {
        saveBaecha(fileData);
      }
    }
  }, [fileData]);

  const baechaExcelUploader = async (e) => {
    try {
      if (!checkHTML5Brower()) {
        alert('브라우저가 HTML5 를 지원하지 않습니다.\r\n서버로 업로드해서 해결하십시오.');
        return;
      }

      var file = e.target.files[0];
      if (typeof file === 'undefined') {
        // alert("파일 선택 시 오류 발생!!");😨
        return;
      }
      // setLoading(true);

      var reader = new FileReader();

      reader.onload = function (e) {
        var data = e.target.result;

        var workbook;

        if (rABS) {
          workbook = XLSX.read(data, { type: 'binary' });
        } else {
          var arr = fixdata(data);
          workbook = XLSX.read(Buffer.from(arr, 'base64'), { type: 'base64' });
        }

        var jsonObj = process_wb(workbook);

        if (jsonObj[Object.keys(jsonObj)[0]]) {
          const parseExcelDataArr = parseCvs(jsonObj[Object.keys(jsonObj)[0]]);
          const addArr = [];
          // //필수 입력값들 arr
          const colField = [
            'requesterName',
            'itemName',
            'quantity',
            'carType',
            'loadDate',
            'loadTime',
            'loadPlace',
            'loadPlaceCompany',
            'loadAddress',
            'loadName',
            'loadPhone',
            'unloadDate',
            'unloadTime',
            'unloadPlace',
            'unloadPlaceCompany',
            'unloadAddress',
            'unloadName',
            'unloadPhone',
            'stopOverPlace1',
            'stopOverPlaceCompany1',
            'stopOverAddress1',
            'stopOverName1',
            'stopOverPhone1',
            'stopOverPlace2',
            'stopOverPlaceCompany2',
            'stopOverAddress2',
            'stopOverName2',
            'stopOverPhone2',
            'stopOverPlace3',
            'stopOverPlaceCompany3',
            'stopOverAddress3',
            'stopOverName3',
            'stopOverPhone3',

            // 'note',
            // 'price',
            // 'carNumber',
            // 'drvierName',
            // 'driverPhone',
          ];
          parseExcelDataArr.forEach((textArr = [], idx) => {
            if (idx !== 0) {
              const obj = {};
              for (let index = 0; index < textArr.length; index++) {
                obj[colField[index]] = textArr[index];
              }
              addArr.push(obj);
            }
          });

          setFileData(addArr);
        }
      };

      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {}
  };
  const paramingSearchObjToUrl = (url, searchObj) => {
    if (searchObj?.partnerSeq) url += `&partnerSeq=${searchObj?.partnerSeq}`;
    if (searchObj?.centerCode) url += `&centerCode=${searchObj?.centerCode}`;
    if (searchObj?.locationCode) url += `&locationCode=${searchObj?.locationCode}`;
    if (searchObj?.barcode) url += `&barcode=${searchObj?.barcode}`;
    return url;
  };

  const fetchList = async () => {
    dispatch(setLoading('GET'));
    let url = BAECHA_LIST;

    httpClient
      .get(url)
      .then((res) => {
        const addList = res.data || [];
        addList?.forEach((row) => {
          row.moveAction = '다중이동';
          row.receipt = row.receipt ? '업로드됨' : '미업로드';
          row.receipt2 = row.receipt2 ? '다운' : '';
        });
        setRows(addList);
        dispatch(setLoading(null));
      })
      .catch(async (err) => {
        dispatch(setLoading(null));
      });
  };

  const downloadExcel = async () => {
    dispatch(setLoading('GET'));

    let url = INVENTORY_LOCATION_LISTS + `?`;
    const search = paramingSearchObjToUrl(url, searchObj);
    if (search) {
      return await httpClient
        .get(search)
        .then((rs) => {
          if (rs?.status === 200) {
            rs.data.forEach((row) => {
              row['statusKr'] = row.availableStatus === 'AVAILABLE' ? '양품' : '불용';
              row['partnerSeq'] = selectlabel(row.partnerSeq, masterOptions?.['SELLER']);
              row['centerCode'] = selectlabel(row.centerCode, masterOptions?.['CENTER']);
              row['modelGroup'] = selectlabel(row.modelGroup, masterOptions?.['MODEL_GROUP']);
              row['Quantity'] = row.availableStatus === 'AVAILABLE' ? row.availableQuantity : row.defectedQuantity;
              row['movableQuantity'] = row.availableStatus === 'AVAILABLE' ? row.movableAvailableQuantity : row.movableDefectedQuantity;
            });
            dispatch(setLoading(null));
            return rs.data;
          } else {
            return null;
          }
        })
        .catch((err) => {
          dispatch(setLoading(null));
          return null;
        });
    }
  };

  const columns = [
    {
      headerText: '배차 입력일시',
      dataField: 'registerDatetime',
      editable: false,
      width: 100,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '인수증업로드',
      dataField: 'receipt',
      editable: false,
      renderer: {
        type: 'ButtonRenderer',
        onClick: function (event) {
          // setgetrowData(event);
        },
      },
    },
    {
      headerText: '인수증다운',
      dataField: 'receipt2',
      editable: false,
      renderer: {
        type: 'ButtonRenderer',
        // labelText: "설치기사 선택",
        onClick: function (event) {
          window.open(event?.item.receiptData);
        },
        visibleFunction: function (rowIndex, columnIndex, value, item, dataField) {
          // 행 아이템의 name 이 Anna 라면 버튼 표시하지 않음
          if (value === '미업로드' || value === '') {
            return false;
          }
          return true;
        },
      },
    },
    {
      headerText: '인수증데이터',
      dataField: 'receiptData',
      visible: false,
    },
    {
      headerText: '요청자',
      dataField: 'requesterName',
      style: 'edit-here-color',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '물품명',
      dataField: 'itemName',
      style: 'edit-here-color',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '물품 수량',
      dataField: 'quantity',
      style: 'edit-here-color',
      filter: {
        showIcon: true,
      },
      dataType: 'numeric',
    },
    {
      headerText: '차량',
      dataField: 'carType',
      style: 'edit-here-color',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '특이사항',
      dataField: 'note',
      style: 'edit-here-color',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '운임',
      dataField: 'price',
      style: 'edit-here-color',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '차량번호',
      dataField: 'carNumber',
      style: 'edit-here-color',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '기사명',
      dataField: 'driverName',
      style: 'edit-here-color',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '기사연락처',
      dataField: 'driverPhone',
      style: 'edit-here-color',
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'loadDate',
      headerText: '상차일',
      width: 120,
      dataType: 'date',
      formatString: 'yyyy-mm-dd',
      filter: {
        showIcon: true,
      },
      editRenderer: {
        onlyCalendar: true,
        type: 'CalendarRenderer',
        showTodayBtn: true,
        showEditorBtnOver: true,
        showExtraDays: true,
        titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        defaultFormat: 'yyyy-mm-dd',
      },
      style: 'my-custom-aui edit-here-color',
    },
    {
      headerText: '상차시간',
      dataField: 'loadTime',
      style: 'edit-here-color',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '상차지',
      dataField: 'loadPlace',
      style: 'edit-here-color',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '상차지 회사명',
      dataField: 'loadPlaceCompany',
      style: 'edit-here-color',
      width: 150,

      filter: {
        showIcon: true,
      },
    },
    //
    {
      headerText: '상차지 주소',
      dataField: 'loadAddress',
      style: 'edit-here-color',
      width: 300,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '상차지 담당자',
      dataField: 'loadName',
      style: 'edit-here-color',
      width: 100,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '상차지 담당자 연락처',
      dataField: 'loadPhone',
      style: 'edit-here-color',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'unloadDate',
      headerText: '하차일',
      width: 120,
      dataType: 'date',
      formatString: 'yyyy-mm-dd',
      filter: {
        showIcon: true,
      },
      editRenderer: {
        onlyCalendar: true,
        type: 'CalendarRenderer',
        showTodayBtn: true,
        showEditorBtnOver: true,
        showExtraDays: true,
        titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        defaultFormat: 'yyyy-mm-dd',
      },
      style: 'my-custom-aui edit-here-color',
    },
    {
      headerText: '하차시간',
      dataField: 'unloadTime',
      style: 'edit-here-color',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '하차지',
      dataField: 'unloadPlace',
      style: 'edit-here-color',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '하차지 회사명',
      dataField: 'unloadPlaceCompany',
      style: 'edit-here-color',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '하차지 주소',
      dataField: 'unloadAddress',
      style: 'edit-here-color',
      width: 300,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '하차지 담당자',
      dataField: 'unloadName',
      style: 'edit-here-color',
      width: 100,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '하차지 담당자 연락처',
      dataField: 'unloadPhone',
      style: 'edit-here-color',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지1',
      dataField: 'stopOverPlace1',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지1 회사명',
      dataField: 'stopOverPlaceCompany1',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지1 주소',
      dataField: 'stopOverAddress1',
      width: 300,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지1 담당자',
      dataField: 'stopOverName1',
      width: 100,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지1 담당자 연락처',
      dataField: 'stopOverPhone1',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지2',
      dataField: 'stopOverPlace2',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지2 회사명',
      dataField: 'stopOverPlaceCompany2',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지2 주소',
      dataField: 'stopOverAddress2',
      width: 300,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지2 담당자',
      dataField: 'stopOverName2',
      width: 100,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지2 담당자 연락처',
      dataField: 'stopOverPhone2',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지3',
      dataField: 'stopOverPlace3',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지3 회사명',
      dataField: 'stopOverPlaceCompany3',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지3 주소',
      dataField: 'stopOverAddress3',
      width: 300,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지3 담당자',
      dataField: 'stopOverName3',
      width: 100,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '경유지3 담당자 연락처',
      dataField: 'stopOverPhone3',
      width: 150,
      filter: {
        showIcon: true,
      },
    },
  ];

  return (
    <Presenter
      // grid
      gridId={gridId}
      rows={rows}
      setRows={setRows}
      columns={columns}
      //function
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      fetchList={fetchList}
      downloadExcel={downloadExcel}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      // excel-uploader
      saveBaecha={saveBaecha}
      baechaExcelUploader={baechaExcelUploader}
      //
      movePopupObj={movePopupObj}
      setMovePopupObj={setMovePopupObj}
    />
  );
};
export default Container;
