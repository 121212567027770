import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { orderService } from '../../_services/service';
import { ExtraServiceDTO, ExtraServiceSearchDTO } from '../../_interface/order';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import { useDidMountEffect } from 'hooks/basichooks';
import { DetailPopup, IDetailObj } from './component/detail';
import { PagingListDTO } from 'interface/util';
import { SERVICE_UPLOAD } from 'envVar2';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [detailObj, setDetailObj] = useState<IDetailObj>();

  const labellingKr = (data: PagingListDTO<ExtraServiceDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: ExtraServiceSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await orderService.getServicePaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const fetchDetail = (e: IGrid.ButtonClickEvent) => {
    setDetailObj({
      visible: true,
      data: e.item,
      searchObj: JSON.parse(e.item.searchObj),
      fetchFn: (data) => wrappedFetchList(data),
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      width: 100,
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetail,
      },
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '서비스명',
      dataField: 'serviceName',
    },
    {
      headerText: '금액',
      dataField: 'price',
      dataType: 'numeric',
      formatString: '#,##0',
    },
  ];

  const uploadData = async (dataArr: ExtraServiceDTO[]) => {
    const rs = await orderService.uploadService(dataArr);
    if (rs?.status === 200) {
      alert('업로드 되었습니다!');
      wrappedFetchList(searchObj);
    }
  };

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: await orderService.getServiceMainExcel(searchObj) }, true);

    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '서비스관리' });

    dispatch(setLoading(null));
  };

  const deleteService = async (items) => {
    dispatch(setLoading('POST'));
    const rs = await orderService.postDeleteExtraService({ extraServiceSeqs: items?.map((ele) => ele.extraServiceSeq) });
    if (rs?.status === 200) {
      alert('삭제되었습니다!');
      const searchObj = JSON.parse(items[0].searchObj);
      wrappedFetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'EXCELUPLOADFORM') {
      window.open(SERVICE_UPLOAD);
    } else if (id === 'EXCELUPLOAD') {
      uploaderClick(id);
    } else if (id === 'DOWNLOADEXCEL') {
      downloadExcel('main');
    } else if (id === 'CREATESERVICE') {
      setDetailObj({
        visible: true,
        data: null,
        searchObj,
        fetchFn: (data) => wrappedFetchList(data),
      });
    } else {
      handleCheckItems(id);
    }
  };
  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'DELETESERVICE') deleteService(items);
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const KRTOVALUE = {
    partnerSeq: 'SELLER_WHOLE',
  };

  const EXCELHEADER = {
    UPLOAD: ['partnerSeq', 'serviceName', 'price'],
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE });
  useDidMountEffect(() => {
    if (parsedData?.data?.length > 0) {
      uploadData(parsedData?.data);
    }
  }, [parsedData]);

  return (
    <div className="page-content">
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        <GridBox gridRef={gridRef} columns={columns} gridProps={{ showRowAllCheckBox: true, showRowCheckColumn: true }} />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
