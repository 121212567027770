import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';
import {
  FCPICKING_CLOSE,
  FCPICKING_COMPLETE,
  FCPICKING_CONFIRM,
  FCPICKING_DETAIL_EXCEL,
  FCPICKING_EXCEL,
  FCPICKING_INVENTORY,
  FCPICKING_LOCATION_CHANGE,
  FCPICKING_ROLLBACK,
  FCPICKING_TOTAL_DETAIL,
  FCPICKING_TOTAL_PAGING,
  ORDER_USELESS_APPLIANCE,
  WAREHOUSE_LOAN_DETAIL,
  WAREHOUSE_LOAN_EXCEL,
  WAREHOUSE_LOAN_PAGING,
} from 'envVar';
import { PagingListDTO } from 'interface/util';
import { PaymentSearchDTO, ScrapListDTO } from 'interface/order';
import { ResponseDTO } from 'interface/user';
import {
  LoanComponentDTO,
  LoanComponentDetailDTO,
  LoanComponentSearchDTO,
  LoanPickRollbackDTO,
  LoanPickingCloseDTO,
  LoanPickingConfirmDTO,
  LoanPickingDetailListDTO,
  LoanPickingDetailSearchDTO,
  LoanPickingInventoryListDTO,
  LoanPickingListDTO,
  LoanPickingLocationChangeDTO,
} from 'interface/warehouse';

class DriverService {
  public async getOrderUselessAppliance(): Promise<ScrapListDTO[]> {
    let url = ORDER_USELESS_APPLIANCE;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getMaterialPaging(searchDTO: LoanComponentSearchDTO): Promise<PagingListDTO<LoanComponentDTO>> {
    let url = WAREHOUSE_LOAN_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getMaterialMainExcel(searchDTO: PaymentSearchDTO): Promise<LoanComponentDTO[]> {
    let url = WAREHOUSE_LOAN_EXCEL + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getMaterialDetail(loanComponentSeq: number): Promise<LoanComponentDetailDTO[]> {
    let url = WAREHOUSE_LOAN_DETAIL + `?loanComponentSeq=${loanComponentSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  /////// fc-picking
  // total

  public async getTotalFCPickingPaging(searchDTO: LoanComponentSearchDTO): Promise<PagingListDTO<LoanComponentDTO>> {
    let url = FCPICKING_TOTAL_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getTotalFCPickingMainExcel(searchDTO: LoanComponentSearchDTO): Promise<LoanPickingListDTO[]> {
    let url = FCPICKING_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getTotalFCPickingDetailExcel(searchDTO: LoanComponentSearchDTO): Promise<LoanPickingListDTO[]> {
    let url = FCPICKING_DETAIL_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getTotalFCPickingDetail(searchDTO: LoanPickingDetailSearchDTO): Promise<LoanPickingDetailListDTO[]> {
    let url = FCPICKING_TOTAL_DETAIL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getTotalFCPickingInventory(searchDTO: LoanPickingDetailSearchDTO): Promise<LoanPickingInventoryListDTO[]> {
    let url = FCPICKING_INVENTORY + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async changePickingLocation(dataDTO: LoanPickingLocationChangeDTO): Promise<ResponseDTO> {
    let url = FCPICKING_LOCATION_CHANGE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async closeLoanPicking(dataDTO: LoanPickingCloseDTO): Promise<ResponseDTO> {
    let url = FCPICKING_CLOSE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async confirmLoanPicking(dataDTO: LoanPickingConfirmDTO[]): Promise<ResponseDTO> {
    let url = FCPICKING_CONFIRM;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async completeLoanPicking(loanPickSeq: number, userId?: string): Promise<ResponseDTO> {
    let url = FCPICKING_COMPLETE;
    if (loanPickSeq) url += `?loanPickSeq=${loanPickSeq}`;
    if (userId) url += `&userId=${userId}`;
    const rs = await httpClient.post(url);
    return rs;
  }

  public async rollbackLoanPicking(dataDTO: LoanPickRollbackDTO[]): Promise<ResponseDTO> {
    let url = FCPICKING_ROLLBACK;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async getAction(action: string, searchDTO = null): Promise<any> {
    let url = action;
    if (searchDTO) url += addingParamsToUrl(searchDTO);
    const rs = await httpClient.get(url);
    return rs;
  }

  public async postAction(action: string, dataDTO: any, searchDTO = null): Promise<ResponseDTO> {
    let url = action;
    if (searchDTO) url += addingParamsToUrl(searchDTO);
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
}

export const driverService = new DriverService();
