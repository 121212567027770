import AUIGrid from 'modules/Grid';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { MenuDTO } from 'interface/user';
import { userService } from 'pages/ETC/3user/_service/service';
import { defaultGridProps } from 'common/grid/gridBox';
import { useDispatch, useSelector } from 'react-redux';
import { SelectD } from 'components/reactstrap/reactstrap';
import { RootState } from 'redux/store';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';

export interface ISortObj {
  visible: boolean;
  fetchFn?: <T>(T) => void;
}

interface ISortModal {
  sortObj: ISortObj;
  setSortObj: React.Dispatch<React.SetStateAction<ISortObj>>;
}

export const SortModal = ({ sortObj, setSortObj }: ISortModal) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();
  const [level, setLevel] = useState(1);
  const [parentMenu, setParentMenu] = useState<string>();
  const columns0 = [
    {
      headerText: '메뉴명',
      dataField: 'menuName',
    },
    {
      headerText: '설명',
      dataField: 'description',
    },
  ];

  const columns = [
    {
      headerText: '메뉴명',
      dataField: 'menuName',
    },
    {
      headerText: '설명',
      dataField: 'description',
    },
    {
      headerText: 'url',
      dataField: 'url',
    },
  ];

  useEffect(() => {
    if (level === 1 || (level !== 1 && parentMenu !== null)) {
      setLevelData();
    }
  }, [level, parentMenu]);

  const setLevelData = () => {
    dispatch(setLoading('GET'));
    if (level === 3) gridRef.current.changeColumnLayout(columns);
    else gridRef.current.changeColumnLayout(columns0);

    const copied = [...MASTER_OPS?.[`MENU${level}`]];
    const filtered = level === 1 ? copied : copied.filter((ele: MenuDTO) => ele.parentMenuId === parentMenu);
    gridRef.current.setGridData(filtered);
    dispatch(setLoading(null));
  };

  const saveSort = async () => {
    const rows = gridRef.current.getGridData();
    const data = rows.map((ele, idx) => {
      return {
        menuId: ele.menuId,
        sort: idx + 1,
      };
    });

    const rs = await userService.saveMenuSort(data);
    if (rs?.status === 200) {
      alert('저장되었습니다!');
      window.location.reload();
    }
  };
  return (
    <CommonModalNew
      style={{ width: 500 }}
      title="메뉴 순서조정"
      subTitle={'드래그앤 드롭으로 순서를 정하세요 '}
      visible={sortObj?.visible}
      setVisible={() => {
        setSortObj(null);
      }}
      children={
        <div style={{ padding: 20 }}>
          <Row>
            <Col>
              <label>상위메뉴</label>
              <SelectD
                isDisabled={level === 1}
                options={MASTER_OPS?.[`MENU${level - 1}`]?.map((ele) => {
                  return {
                    value: ele.menuId,
                    label: ele.menuName,
                  };
                })}
                value={
                  parentMenu
                    ? {
                        label: MASTER_OBJ?.MENU_NAME?.[parentMenu],
                        value: parentMenu,
                      }
                    : null
                }
                onChange={(option) => {
                  setParentMenu((option as OptionItem).value);
                }}
              />
            </Col>
            <Col>
              <label>메뉴레벨</label>
              <SelectD
                options={MASTER_OPS?.MENU_LEVEL}
                value={
                  level
                    ? {
                        label: MASTER_OBJ?.MENU_LEVEL?.[level],
                        value: level,
                      }
                    : null
                }
                onChange={(option) => {
                  setLevel((option as OptionItem).value);
                  setParentMenu(null);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'right', marginBottom: 20 }}>
              <button
                className="btn btn-create"
                onClick={() => {
                  saveSort();
                }}
              >
                저장
              </button>
            </Col>
          </Row>
          <AUIGrid
            ref={gridRef}
            columnLayout={columns0}
            name={`sortModal`}
            gridProps={{
              ...defaultGridProps,
              height: 300,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              //
              enableDrag: true,
              enableMultipleDrag: true,
              enableDragByCellDrag: true,
            }}
          />
        </div>
      }
    />
  );
};
