import { KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, OptionItem, SelectD } from 'components/reactstrap/reactstrap';

import { ExtraServiceSearchDTO } from '../../../_interface/order';

export const SearchBox = (props: ISearchBoxProps<ExtraServiceSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };
  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            options={MASTER_OPS?.SELLER_AUTH}
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: MASTER_OBJ?.SELLER_AUTH?.[searchObj?.partnerSeq],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">서비스명</label>
          <InputD
            value={searchObj?.serviceName}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                serviceName: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
