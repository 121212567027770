import { useEffect, useRef, useState } from 'react';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// print
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import Barcode from 'react-barcode';

export interface IPrint {
  type?: 'detail' | 'haldang';
  items?: any;
  visible?: boolean;
}

const FORMTAG_6 = styled.div({
  backgroundColor: 'white',
  width: '210mm',
  height: '297mm',
  display: 'grid',
  alignItems: 'center',
  justifyItems: 'center',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(3, 1fr)',
  border: '1px solid lightgray',
  columnGap: '2mm',
  padding: '8mm 5mm',
});

const FORMTAG_24 = styled.div({
  backgroundColor: 'white',
  width: '210mm',
  height: '297mm',
  display: 'grid',
  alignItems: 'center',
  justifyItems: 'center',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(8, 1fr)',
  border: '1px solid lightgray',
  columnGap: '2mm',
  padding: '10mm 7mm',
  paddingTop: '13mm',
});

const PrintBarcode991931 = styled.div({
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '99.1mm',
  height: '93.1mm',
  border: '1px solid #d0d0d0',
  fontSize: 15,
  fontWeight: 800,
});
const PrintBarcode6434 = styled.div({
  backgroundColor: 'white',
  position: 'relative',
  height: '34mm',
  width: '64mm',
  border: '1px solid #d0d0d0',
  fontSize: 15,
  fontWeight: 800,
});

const printSize = [
  { value: '991_931', label: '99.1mm X 93.1mm' },
  { value: '64_34', label: '64mm X 34mm' },
];

const printOrder = {
  haldang: [
    { value: 'serialNumber', label: '시리얼번호순' },
    { value: 'orderSeq', label: '주문번호순' },
    { value: 'invoiceSeq', label: '송장번호순' },
    { value: 'customerName', label: '고객명순' },
  ],
  detail: [
    { value: 'barcode', label: '바코드' },
    { value: 'model', label: '제품' },
    { value: 'modelName', label: '제품명' },
  ],
};

const formOptions = [
  { value: 'default_1', label: '단일' },
  { value: 'formtag_6', label: '폼텍 6형' },
  { value: 'formtag_24', label: '폼텍 24형' },
];

export const Print = ({ printObj, setPrintObj }) => {
  const wrapperRef = useRef();
  const [printContents, setPrintContents] = useState<any[]>();
  const [printSetting, setPrintSetting] = useState<{ order?: string; quantity: number; size?: string; format?: string }>();
  useEffect(() => {
    setPrintSetting({ size: printSize?.[0]?.value, order: printOrder?.[printObj?.type][0]?.value, quantity: 2, format: 'default_1' });
  }, []);

  useEffect(() => {
    if (printSetting) setPrint(printSetting);
  }, [printSetting]);

  const setPrint = (printSetting) => {
    const items = JSON.parse(JSON.stringify(printObj?.items));

    items?.sort(function (a, b) {
      return a[printSetting?.order] < b[printSetting?.order] ? -1 : a[printSetting?.order] > b[printSetting?.order] ? 1 : 0;
    });

    let array = [];
    items?.forEach((item) => {
      new Array(printSetting?.quantity).fill(item).forEach((item) => {
        array.push(item);
      });
    });

    if (printSetting?.format === 'default_1') setPrintContents(array);
    else {
      const a4Arr = [];
      const onePageHas = Number(printSetting?.format?.split('formtag_')?.[1]);
      new Array(Math.ceil(array?.length / onePageHas)).fill(0)?.forEach((vacant, gi) => {
        const group = [];
        new Array(Number(onePageHas))?.fill(0)?.forEach((_, idx) => {
          group.push(array[gi * onePageHas + idx]);
        });
        a4Arr?.push(group);
      });
      setPrintContents(a4Arr);
    }
  };

  return (
    <CommonModalNew
      title={printObj?.type === 'haldang' ? '시리얼 출력' : '바코드 출력'}
      type="print"
      visible={printObj?.visible}
      setVisible={() => {
        setPrintObj(null);
      }}
      rightTitle={
        <>
          <div style={{ display: 'flex' }}>
            <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
          </div>
        </>
      }
      style={{ width: 1045 }}
      children={
        <div style={{ display: 'flex', minHeight: 300 }}>
          <div style={{ width: 185, borderRight: '1px solid lightgray', paddingRight: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'column', position: 'fixed' }}>
              <div>
                <label className="col-form-label">출력형식</label>
                <SelectD
                  hasNull={false}
                  value={printSetting?.format ? { value: printSetting?.format, label: selectlabel(printSetting?.format, formOptions) } : null}
                  options={printObj?.type === 'haldang' ? formOptions?.filter((ele) => !ele.value?.includes('24')) : formOptions}
                  onChange={(option) => {
                    setPrintSetting((prev) => {
                      return {
                        ...prev,
                        format: (option as OptionItem)?.value,
                        size: (option as OptionItem)?.value === 'formtag_24' ? '64_34' : (option as OptionItem)?.value === 'formtag_6' ? '991_931' : prev.size,
                      };
                    });
                  }}
                />
              </div>
              <div>
                <label className="col-form-label">출력순서</label>
                <SelectD
                  hasNull={false}
                  value={printSetting?.order ? { value: printSetting?.order, label: selectlabel(printSetting?.order, printOrder[printObj?.type]) } : null}
                  options={printOrder[printObj?.type]}
                  onChange={(option) => {
                    setPrintSetting((prev) => {
                      return {
                        ...prev,
                        order: (option as OptionItem)?.value,
                      };
                    });
                  }}
                />
              </div>
              <div>
                <label className="col-form-label">사이즈</label>
                <SelectD hasNull={false} isDisabled={true} value={printSetting?.size ? { value: printSetting?.size, label: selectlabel(printSetting?.size, printSize) } : null} options={printSize} />
              </div>
              <div>
                <label className="col-form-label">출력매수</label>
                <InputD
                  placeholder="출력 매수"
                  type="number"
                  value={printSetting?.quantity || ''}
                  onChange={(e) => {
                    setPrintSetting((prev) => {
                      return {
                        ...prev,
                        quantity: Number(e.target.value),
                      };
                    });
                  }}
                />
              </div>
              <hr />
              <div>
                <div style={{ textAlign: 'right' }}>
                  <span className="b">{printObj?.items?.length * printSetting?.quantity}</span> 개의 라벨 / 전체&nbsp;
                  <span className="b">{Math.ceil((printObj?.items?.length * printSetting?.quantity) / Number(printSetting?.format?.split('_')[1]))}</span> 장
                </div>
              </div>
              <hr />
              <div>
                <div style={{ textAlign: 'right', marginBottom: 5 }}>
                  {printObj?.type === 'detail' ? '바코드번호' : '시리얼번호'} 선택: 총 <span className="b">{printObj?.items?.length}</span> 건
                </div>
                <div style={{ maxHeight: 500, overflow: 'scroll', textAlign: 'center' }}>
                  {printObj?.items?.map((row, idx) => {
                    if (printObj?.type === 'detail') return <div key={`barcode_${idx}`}>{row.barcode}</div>;
                    else if (printObj?.type === 'haldang') return <div key={`serialNumber_${idx}`}>{row.serialNumber}</div>;
                  })}
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="break-for-onePage-print-wrapper" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '210mm', marginLeft: 20 }} ref={wrapperRef}>
            {printObj?.type === 'haldang' ? (
              <>
                {printSetting?.format === 'default_1'
                  ? printContents?.map((ele, idx) => {
                      return (
                        <div className="break-for-onePage-print" key={`wrapper_default_1_${idx}`}>
                          <Haldang_Element ele={ele} />
                        </div>
                      );
                    })
                  : printContents?.map((group, idx) => {
                      if (printSetting?.format === 'formtag_6' && group?.length > 0)
                        return (
                          <FORMTAG_6 className="break-for-onePage-print-keep-raw" key={`wrapper_formtag_6_${idx}`}>
                            {group?.map((ele, gi) => {
                              return <Haldang_Element ele={ele} key={`formtag_6_${gi}`} />;
                            })}
                          </FORMTAG_6>
                        );
                      else if (printSetting?.format === 'formtag_24' && group?.length > 0)
                        return (
                          <FORMTAG_24 className="break-for-onePage-print-keep-raw" key={`wrapper_formtag_24_${idx}`}>
                            {group?.map((ele, gi) => {
                              return <Haldang_Element ele={ele} key={`formtag_24_${gi}`} />;
                            })}
                          </FORMTAG_24>
                        );
                    })}
              </>
            ) : (
              // printObj?.type === 'detail'
              <>
                {printSetting?.format === 'default_1'
                  ? printContents?.map((ele, idx) => {
                      return (
                        <div className="break-for-onePage-print" key={`wrapper_default_1_${idx}`}>
                          <Detail_Element ele={ele} size={printSetting?.size} />
                        </div>
                      );
                    })
                  : printContents?.map((group, idx) => {
                      if (printSetting?.format === 'formtag_6' && group?.length > 0)
                        return (
                          <FORMTAG_6 className="break-for-onePage-print-keep-raw" key={`wrapper_formtag_6_${idx}`}>
                            {group?.map((ele, gi) => {
                              return <Detail_Element ele={ele} size={printSetting?.size} key={`formtag_6_${gi}`} />;
                            })}
                          </FORMTAG_6>
                        );
                      else if (printSetting?.format === 'formtag_24' && group?.length > 0)
                        return (
                          <FORMTAG_24 className="break-for-onePage-print-keep-raw" key={`wrapper_formtag_24_${idx}`}>
                            {group?.map((ele, gi) => {
                              return <Detail_Element ele={ele} size={printSetting?.size} key={`formtag_24_${gi}`} />;
                            })}
                          </FORMTAG_24>
                        );
                    })}
              </>
            )}
          </div>
        </div>
      }
    />
  );
};
//  className={isSingle ?'break-for-onePage-print':''}

const Haldang_Element = ({ ele = null }) => {
  return (
    <PrintBarcode991931>
      {ele && (
        <table className="border-table printing" style={{ height: '100%' }}>
          <tbody>
            <tr>
              <td colSpan={6} style={{ textAlign: 'center' }}>
                {ele?.serialNumber && <Barcode width={1.3} value={ele?.serialNumber} height={40} />}
              </td>
            </tr>
            <tr>
              <th>시리얼번호</th>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {ele?.serialNumber}
              </td>
            </tr>
            <tr>
              <th>제품</th>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {ele?.model}
              </td>
            </tr>
            <tr>
              <th>제품명</th>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {ele?.modelName}
              </td>
            </tr>
            <tr>
              <th>주문번호</th>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {ele?.orderSeq}
              </td>
            </tr>
            <tr>
              <th>송장번호</th>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {ele?.invoiceSeq}
              </td>
            </tr>
            <tr>
              <th>고객명</th>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {ele?.customerName}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </PrintBarcode991931>
  );
};

const Detail_Element = ({ size = null, ele = null }) => {
  const Content = ({ size = null }) => {
    return (
      <table className="border-table printing" style={{ height: '100%', maxHeight: size === '64_34' ? '34mm' : '100%' }}>
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: 'center' }}>
              {ele?.barcode && <Barcode width={size === '64_34' ? 1.2 : 1.3} value={ele?.barcode} height={40} fontSize={15} displayValue={size === '64_34' ? false : true} />}
            </td>
          </tr>
          <tr>
            <th>제품</th>
            <td colSpan={5} style={{ textAlign: 'center' }}>
              {ele?.model}
            </td>
          </tr>
          <tr>
            <th>제품명</th>
            <td colSpan={5} style={{ textAlign: 'center', fontSize: size === '64_34' ? 10 : 12.5 }}>
              {ele?.modelName}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  if (size === '991_931') return <PrintBarcode991931>{ele && <Content />}</PrintBarcode991931>;
  else if (size === '64_34') return <PrintBarcode6434>{ele && <Content size={size} />}</PrintBarcode6434>;
};
