import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setUseableScreens, setUseableFunction, setUseableTabs } from 'redux/services/authSlice';
import { httpClient } from 'common/http-client/axiosConfig';
import { FunctionDTO, ScreenDTO, TabDTO } from 'interface/user';
import { useLocation } from 'react-router-dom';

const useUseableScreenTabFunctions = () => {
  const { useableFunctions, useableScreens } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  const gridButtonUseable = (gridButtons: FunctionDTO[]) => {
    const btnFlagObj = {};
    gridButtons?.forEach((btn) => {
      btnFlagObj[btn?.functionId] = btn.useFlag;
    });
    return btnFlagObj;
  };
  // old start

  const getFetchUseableFunctions = async () => {
    const requestURL = '/user/my/function';
    const { data } = await httpClient.get(requestURL);
    const parsedUseableFunction: { [key: string]: FunctionDTO } = {};
    if (data?.length > 0) {
      for (const property of data as FunctionDTO[]) {
        parsedUseableFunction[property.functionId] = property;
      }
      dispatch(setUseableFunction(parsedUseableFunction));
      return parsedUseableFunction;
    }
  };

  const fetchUsableFunctionsInThisScreen = (screenId: string, functionType: 'MAIN' | 'DETAIL' = 'MAIN') => {
    const functionArr = Object.values(useableFunctions);
    const filtered = functionArr.filter((ele) => ele.screenId === screenId && ele.functionType === functionType);
    return filtered;
  };

  // old end

  // new start

  const getFetchUseableScreenNTabs = async () => {
    const [screens, tabs] = await Promise.all([
      //
      httpClient.get('/user/my/screen'),
      httpClient.get('/user/my/tab'),
    ]);
    // default 값
    const parsedUseableScreen: { [key: string]: ScreenDTO } = {
      '/': {
        screenId: 'HOME',
        screenName: '홈화면',
        description: '',
        url: '/',
        useFlag: true,
      },
      '/purchaseRegit': {
        screenId: '',
        screenName: '',
        description: '',
        url: '/purchaseRegit',
        useFlag: true,
      },
      '/trunkRegit': {
        screenId: '',
        screenName: '',
        description: '',
        url: '/trunkRegit',
        useFlag: true,
      },
      '/takeoutRegit': {
        screenId: '',
        screenName: '',
        description: '',
        url: '/takeoutRegit',
        useFlag: true,
      },
      '/transactionRegister': {
        screenId: '',
        screenName: '',
        description: '',
        url: '/transactionRegister',
        useFlag: true,
      },
      '/modificationAvaRegit': {
        screenId: '',
        screenName: '',
        description: '',
        url: '/modificationAvaRegit',
        useFlag: true,
      },
      '/modificationDefRegit': {
        screenId: '',
        screenName: '',
        description: '',
        url: '/modificationDefRegit',
        useFlag: true,
      },
      '/modelMoveRegit': {
        screenId: '',
        screenName: '',
        description: '',
        url: '/modelMoveRegit',
        useFlag: true,
      },
      '/refurRegister': {
        screenId: '',
        screenName: '',
        description: '',
        url: '/refurRegit',
        useFlag: true,
      },
      '/TakeOutPickingInstructionRegister': {
        screenId: '',
        screenName: '',
        description: '',
        url: '/TakeOutPickingInstructionRegister',
        useFlag: true,
      },
      '/storeTakeOutPickingInstructionRegister': {
        screenId: '',
        screenName: '',
        description: '',
        url: '/storeTakeOutPickingInstructionRegister',
        useFlag: true,
      },
      '/createfcpicking': {
        screenId: '',
        screenName: '',
        description: '',
        url: '/createfcpicking',
        useFlag: true,
      },
      '/trunkRegister': {
        screenId: '',
        screenName: '',
        description: '',
        url: '/trunkRegister',
        useFlag: true,
      },
    };
    const parsedUseableTab: { [key: string]: ScreenDTO[] } = {};
    if (screens?.data?.length > 0) {
      for (const property of screens?.data as ScreenDTO[]) {
        property['TABS'] = tabs?.data?.filter((ele: TabDTO) => ele.screenId === property.screenId);
        parsedUseableScreen[property.url] = property;
      }
      dispatch(setUseableScreens(parsedUseableScreen));
    } else {
      dispatch(setUseableScreens({ '/': null })); // TEMP: 홈화면은 들어올수있다
    }

    if (screens?.data?.length > 0) {
      screens?.data?.forEach((ele) => {
        parsedUseableTab[ele.url] = tabs?.data?.filter((tab) => tab.url === ele.url);
      });

      dispatch(setUseableTabs(parsedUseableTab));
      return parsedUseableTab;
    }
  };

  const getTabsInThisScreen = (fixedPathname?: string) => {
    const tabs = useableScreens[fixedPathname || location.pathname];
    if (tabs) {
      const TABS = [...tabs?.TABS]?.sort((a: TabDTO, b: TabDTO) => a.sort - b.sort);
      return TABS;
    }
    return [];
  };

  const fetchUsableFunctionsInThisTab = (tabId: string, functionType = 'MAIN') => {
    if (useableFunctions) {
      const functionArr = Object.values(useableFunctions);
      const filtered = functionArr.filter((ele) => ele.tabId === tabId && ele.functionType === functionType);
      return filtered;
    }
  };

  // new end

  const isUseableFunctions = (fucntionKey: string) => {
    if (useableFunctions.length > 0) {
      return useableFunctions?.some((ele) => ele.functionId === fucntionKey);
    } else {
      return false;
    }
  };

  return { gridButtonUseable, getFetchUseableFunctions, getFetchUseableScreenNTabs, fetchUsableFunctionsInThisScreen, fetchUsableFunctionsInThisTab, isUseableFunctions, getTabsInThisScreen };
};

export default useUseableScreenTabFunctions;
