import { createSlice } from '@reduxjs/toolkit';
import { FunctionDTO, MenuDTO, ScreenDTO, TabDTO } from 'interface/user';

export type UseableFunctionType = {
  functionId: string;
  functionName: string;
  description: string;
  screenId: string;
  screenName: string;
  useFlag: boolean;
  registerId: string;
  registerDatetime: string;
};

interface InitProps {
  reduxUserInfo?: any;
  useableMenus?: MenuDTO[];
  useableFunctions?: FunctionDTO[];
  useableTabs?: TabDTO[];
  useableScreens?: ScreenDTO[];
}
const initialState: InitProps = {
  reduxUserInfo: null,
  useableMenus: null,
  useableFunctions: null,
  useableTabs: null,
  useableScreens: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setNullAuth: (state) => {
      state.reduxUserInfo = null;
      state.useableMenus = null;
      state.useableFunctions = null;
      state.useableScreens = null;
    },
    setAuth: (state, action) => {
      state.reduxUserInfo = action.payload;
    },
    setUseableMenus: (state, action) => {
      state.useableMenus = action.payload;
    },
    setUseableScreens: (state, action) => {
      state.useableScreens = action.payload;
    },
    setUseableTabs: (state, action) => {
      state.useableTabs = action.payload;
    },
    setUseableFunction: (state, action) => {
      state.useableFunctions = {
        ...state.useableFunctions,
        ...action.payload,
      };
    },
  },
});

export const { setAuth, setUseableMenus, setUseableScreens, setUseableTabs, setUseableFunction, setNullAuth } = authSlice.actions;

export default authSlice.reducer;
