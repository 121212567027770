import { useEffect, useRef, useState } from 'react';

// lib
import * as IGrid from 'aui-grid';
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import CommonModalNew from 'components/modal/commonModalNew';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { InventoryLocationListDTO } from 'interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';
import { CENTER_CELL_NUMBER_LENGTH, DOCKS } from 'common/util/grid';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

export interface ILocationDetailObj {
  visible: boolean;
  locationCode: string;
  data: string; // InventoryLocationListDTO
  activeData: InventoryLocationListDTO;
}
export interface ILocationDetailGridProps {
  detailObj: ILocationDetailObj;
  setDetailObj: React.Dispatch<React.SetStateAction<ILocationDetailObj>>;
}

export const LocationDetailPopup = ({ detailObj, setDetailObj }) => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const [activeData, setActiveData] = useState<InventoryLocationListDTO>();
  const [clickedCellData, setClickedCellData] = useState<InventoryLocationListDTO[]>();

  useEffect(() => {
    setActiveData(JSON.parse(detailObj?.activeData));
    setClickedCellData(JSON.parse(detailObj?.data));
  }, []);
  const dockColumns: IGrid.Column[] = [
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },

    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },

    {
      headerText: '제품',
      dataField: 'model',
      width: 200,
    },

    {
      headerText: '제품명',
      dataField: 'modelName',
      width: 200,
    },

    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '양품',
      dataField: 'availableQuantity',
    },
    {
      headerText: '불용',
      dataField: 'defectedQuantity',
    },
    {
      headerText: '계',
      dataField: 'quantity',
    },
  ];
  const mapColumns: IGrid.Column[] = [
    {
      headerText: '층',
      dataField: 'floorCode',
      width: 40,
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },

    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },

    {
      headerText: '제품',
      dataField: 'model',
      width: 250,
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },

    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '양품',
      dataField: 'availableQuantity',
      width: 50,
    },
    {
      headerText: '불용',
      dataField: 'defectedQuantity',
      width: 50,
    },
    {
      headerText: '계',
      dataField: 'quantity',
      width: 50,
    },
  ];
  useEffect(() => {
    if (clickedCellData && activeData) {
      dispatch(setLoading('GET'));
      const rows = clickedCellData.map((row: InventoryLocationListDTO) => {
        return {
          ...row,
          activeData: JSON.stringify(activeData),
          floorCode: DOCKS.includes(row.locationCode) ? '' : row.locationCode.slice(CENTER_CELL_NUMBER_LENGTH[row.centerCode]),
          partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        };
      });
      gridRef.current.setGridData(rows);
      dispatch(setLoading(null));
    }
  }, [clickedCellData, activeData]);

  return (
    <CommonModalNew
      title={`${detailObj?.locationCode} 재고정보`}
      style={{ width: 1000 }}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      children={
        <>
          <GridBox
            gridRef={gridRef}
            columns={DOCKS?.includes(detailObj?.locationCode) ? dockColumns : mapColumns}
            gridProps={{
              height: 500,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              rowStyleFunction: function (rowIndex, item) {
                const activeData = JSON.parse(item.activeData);
                if (activeData?.locationCode?.includes(item?.locationCode) && activeData?.modelStockSeq === item?.modelStockSeq) return 'here-row-style';
                // if (activeData?.barcode === item.barcode || activeData?.modelGroup === item.modelGroup || activeData?.modelStockSeq === item.modelStockSeq) {
                //   return 'here-row-style';
                // }
                return '';
              },
            }}
          />
        </>
      }
    />
  );
};
