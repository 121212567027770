import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { SearchBox } from './components/searchBox';
import { feeService } from 'pages/FEE/_service/service';
import { OrderReceiptDTO, OrderReceiptSearchDTO } from '../../_interface/order';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { CreateReceiptPopup, ICreateReceipt } from './components/create';
import { CalculateReceiptPopup, ICalculateReceipt } from './components/calculateReceipt';
import { AdjustmentPopup, IAdjustmentObj } from './components/adjustment';
import { DetailPopup, IDetailObj } from './components/detailPopup';
import { IReceipt, ReceiptPopup } from './components/receipt';
import { receiptCategoryChanger } from '..';
import { useLocation } from 'react-router-dom';
import { PagingListDTO } from 'interface/util';
import { serviceStore } from 'services/services';

const Index = () => {
  const location = useLocation();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const detailExcelGridRef = useRef<AUIGrid>();

  const [detailObj, setDetailObj] = useState<IDetailObj>();
  const [receiptObj, setReceiptObj] = useState<IReceipt>();
  const [adjustmentObj, setAdjustmentObj] = useState<IAdjustmentObj>();
  const [createReceiptObj, setCreateReceiptObj] = useState<ICreateReceipt>();
  const [calculateReceiptObj, setCalculateReceiptObj] = useState<ICalculateReceipt>();

  const fecthDetail = async (e: IGrid.ButtonClickEvent) => {
    setDetailObj({
      visible: true,
      data: e.item,
      target: 'default',
      searchObj: searchObj,
      fetchFn: wrappedFetchList,
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fecthDetail,
      },
    },
    {
      headerText: '영수증<br/>상태값',
      dataField: 'statusKr',
    },
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '고객주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '등록일',
      dataField: 'registerDate',
    },
    {
      headerText: '희망일',
      dataField: 'promiseDeliveryDate',
    },
    {
      headerText: '지정일',
      dataField: 'deliveryDate',
    },
    {
      headerText: '완료일/취소일',
      dataField: 'finishOrCancelDate',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '주문유형',
      dataField: 'orderTypeKr',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '송장상태',
      dataField: 'invoiceStatusKr',
    },
    {
      headerText: '취소사유',
      dataField: 'cancelReasonKr',
    },
    {
      headerText: '상품번호',
      dataField: 'productSeq',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '상품구성수량',
      dataField: 'quantity',
    },
    {
      headerText: '기본금액(A)',
      dataField: 'defaultFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '상품금액(B)',
      dataField: 'productFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '그룹금액(C)',
      dataField: 'groupFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '제품금액(D)',
      dataField: 'modelFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '지역금액(E)',
      dataField: 'regionFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '요일금액(F)',
      dataField: 'dateFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '양중금액(G)',
      dataField: 'optionFeeForLifting',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '내림금액(H)',
      dataField: 'optionFeeForScrap',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '수거금액(I)',
      dataField: 'optionFeeForCollect',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '조정금액(J)',
      dataField: 'adjustmentFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '재정산금액(K)',
      dataField: 'recalculateFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: 'A~K 합계(M)',
      dataField: 'supplyValue',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '세액',
      dataField: 'valueAddedTax',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '합계(L+M)',
      dataField: 'total',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '비고',
      dataField: 'remark',
    },
    {
      headerText: '실수령자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'receiverName',
        },
        {
          headerText: '우편번호',
          dataField: 'receiverZipcode',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr1',
          width: 150,
        },
        {
          headerText: '주소 상세',
          dataField: 'receiverAddr2',
          width: 150,
        },
        {
          headerText: '연락처1',
          dataField: 'receiverPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'receiverPhone2',
        },
      ],
    },
    {
      headerText: '주문자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'customerName',
        },
        {
          headerText: '우편번호',
          dataField: 'customerZipcode',
        },
        {
          headerText: '주소',
          dataField: 'customerAddr1',
          width: 150,
        },
        {
          headerText: '주소 상세',
          dataField: 'customerAddr2',
          width: 150,
        },
        {
          headerText: '연락처1',
          dataField: 'customerPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'customerPhone2',
        },
      ],
    },
    {
      headerText: '고객요청사항',
      dataField: 'shipmentNote',
    },
    {
      headerText: '소속창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '주기사',
      dataField: 'userIdKr',
    },
    {
      headerText: '판매채널',
      dataField: 'salesChannelName',
    },
    {
      headerText: '택배사',
      dataField: 'parcelType',
    },
    {
      headerText: '운임구분',
      dataField: 'parcelPayType',
    },
    {
      headerText: '운임비',
      dataField: 'cashOnDelivery',
    },
    {
      headerText: '작업특이사항',
      dataField: 'workNote',
    },
  ];
  const excelColumns: IGrid.Column[] = [
    {
      headerText: '영수증<br/>상태값',
      dataField: 'statusKr',
    },
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '고객주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '등록일',
      dataField: 'registerDate',
    },
    {
      headerText: '희망일',
      dataField: 'promiseDeliveryDate',
    },
    {
      headerText: '지정일',
      dataField: 'deliveryDate',
    },
    {
      headerText: '완료일/취소일',
      dataField: 'finishOrCancelDate',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '주문유형',
      dataField: 'orderTypeKr',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '송장상태',
      dataField: 'invoiceStatusKr',
    },
    {
      headerText: '취소사유',
      dataField: 'cancelReasonKr',
    },
    {
      headerText: '상품번호',
      dataField: 'productSeq',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '상품구성수량',
      dataField: 'quantity',
    },
    {
      headerText: '기본금액(A)',
      dataField: 'defaultFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '상품금액(B)',
      dataField: 'productFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '그룹금액(C)',
      dataField: 'groupFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '제품금액(D)',
      dataField: 'modelFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '지역금액(E)',
      dataField: 'regionFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '요일금액(F)',
      dataField: 'dateFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '양중금액(G)',
      dataField: 'optionFeeForLifting',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '내림금액(H)',
      dataField: 'optionFeeForScrap',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '수거금액(I)',
      dataField: 'optionFeeForCollect',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '조정금액(J)',
      dataField: 'adjustmentFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '재정산금액(K)',
      dataField: 'recalculateFee',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: 'A~K 합계(M)',
      dataField: 'supplyValue',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '세액',
      dataField: 'valueAddedTax',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '합계(L+M)',
      dataField: 'total',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '비고',
      dataField: 'remark',
    },
    {
      headerText: '실수령자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'receiverName',
        },
        {
          headerText: '우편번호',
          dataField: 'receiverZipcode',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr1',
          width: 150,
        },
        {
          headerText: '주소 상세',
          dataField: 'receiverAddr2',
          width: 150,
        },
        {
          headerText: '연락처1',
          dataField: 'receiverPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'receiverPhone2',
        },
      ],
    },
    {
      headerText: '주문자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'customerName',
        },
        {
          headerText: '우편번호',
          dataField: 'customerZipcode',
        },
        {
          headerText: '주소',
          dataField: 'customerAddr1',
          width: 150,
        },
        {
          headerText: '주소 상세',
          dataField: 'customerAddr2',
          width: 150,
        },
        {
          headerText: '연락처1',
          dataField: 'customerPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'customerPhone2',
        },
      ],
    },
    {
      headerText: '고객요청사항',
      dataField: 'shipmentNote',
    },
    {
      headerText: '소속창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '주기사',
      dataField: 'userIdKr',
    },
    {
      headerText: '판매채널',
      dataField: 'salesChannelName',
    },
    {
      headerText: '택배사',
      dataField: 'parcelType',
    },
    {
      headerText: '운임구분',
      dataField: 'parcelPayType',
    },
    {
      headerText: '운임비',
      dataField: 'cashOnDelivery',
    },
    {
      headerText: '작업특이사항',
      dataField: 'workNote',
    },
    {
      headerText: '모델명',
      dataField: 'modelsKr',
    },
  ];
  const labellingKr = (data: PagingListDTO<OrderReceiptDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        orderTypeKr: MASTER_OBJ?.ORDER_TYPE?.[row.orderType],

        cancelReasonKr: MASTER_OBJ?.CANCEL_REASON?.[row.cancelReason],
        feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row.feeType],
        statusKr: MASTER_OBJ?.OR_STATUS?.[row.status],
        invoiceStatusKr: MASTER_OBJ?.INVOICE_STATUS?.[row.invoiceStatus],
        userIdKr: MASTER_OBJ?.DRIVER_WHOLE?.[row.userId],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));
  const pathOrder = currentDesignType === 1 ? 2 : 1;

  const fetchTotal = async (searchObj: OrderReceiptSearchDTO) => {
    const total = await feeService.getReceiptTotal(searchObj, receiptCategoryChanger[location.pathname.split('/')[pathOrder]]);
    return total;
  };

  const fetchPaging = async (searchObj: OrderReceiptSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await feeService.getReceiptPaging(searchObj, receiptCategoryChanger[location.pathname.split('/')[pathOrder]]));
    dispatch(setLoading(null));
    return dataKr;
  };

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list:
          type === 'main'
            ? (await serviceStore.orderAction(`receipt/${receiptCategoryChanger[location.pathname.split('/')[pathOrder]]}/excel`, 'GET', searchObj))?.data
            : (await serviceStore.orderAction(`receipt/${receiptCategoryChanger[location.pathname.split('/')[pathOrder]]}/detail/excel`, 'GET', searchObj))?.data?.map((row) => {
                return {
                  ...row,
                  modelsKr: row.models?.map((ele) => ele.modelName)?.join(' + '),
                };
              }),
      },
      true,
    );

    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '주문영수증' });
    } else {
      detailExcelGridRef.current.setGridData(list);
      detailExcelGridRef.current.exportAsXlsx({ fileName: '주문영수증(상세)' });
    }

    dispatch(setLoading(null));
  };

  const createRecalculate = async (type, items = []) => {
    if (window.confirm(`재정산영수증${type === 'search' ? '(검색)' : type === 'select' ? '(선택)' : ''}을 생성하시겠습니까? `)) {
      const data = { orderReceiptSeqs: items?.map((ele) => ele.orderReceiptSeq) };
      const rs =
        type === 'whole'
          ? await feeService.postCreateRecalculate(data, receiptCategoryChanger[location.pathname.split('/')[pathOrder]])
          : type === 'select'
          ? await feeService.postCreateRecalculateType({ orderReceiptSeqs: items?.map((ele) => ele.orderReceiptSeq) }, receiptCategoryChanger[location.pathname.split('/')[pathOrder]], type)
          : await feeService.postCreateRecalculateType(searchObj, receiptCategoryChanger[location.pathname.split('/')[pathOrder]], type);

      if (rs?.status === 200) {
        alert('재정산 주문영수증을 생성하였습니다!');
        wrappedFetchList(searchObj);
      }
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id?.includes('RECEIPTMAINEXCELDOWN')) {
      downloadExcel('main');
    } else if (id?.includes('RECEIPTEXCELDOWNDETAIL')) {
      downloadExcel('detail');
    } else if (id === 'CREATERECEIPT') {
      setCreateReceiptObj((prev) => {
        return {
          ...prev,
          visible: true,
          searchObj,
          fetchFn: (data) => wrappedFetchList(data),
        };
      });
    } else if (id?.includes('CALCULATERECEIPT')) {
      setCalculateReceiptObj((prev) => {
        return {
          ...prev,
          visible: true,
          searchObj,
          fetchFn: (data) => wrappedFetchList(data),
        };
      });
    } else if (id?.includes('CREATERECEIPTSEARCH')) {
      createRecalculate('search');
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id?.includes('DOWNLOADRECEIPT')) {
        setReceiptObj({
          visible: true,
          data: items,
        });
      } else if (id?.includes('ADDADJUSTMENT')) {
        setAdjustmentObj((prev) => {
          return {
            ...prev,
            visible: true,
            fetchFn: (data) => wrappedFetchList(data),
            data: items?.map((ele) => ele.orderReceiptSeq),
          };
        });
      } else if (id?.includes('CREATERECALCULATE')) {
        createRecalculate('whole', items);
      } else if (id?.includes('CREATERECEIPTSELECT')) {
        createRecalculate('select', items);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchTotal,
    fetchPaging,
  });

  const tabName =
    receiptCategoryChanger[location.pathname.split('/')[pathOrder]] === 'HEADQUARTERS' ? `ORDER_RECEIPT_TAB1` : `ORDER_RECEIPT_TAB1_${receiptCategoryChanger[location.pathname.split('/')[pathOrder]]}`;
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabName);
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj, location]);

  return (
    <div className="page-content">
      {createReceiptObj?.visible && <CreateReceiptPopup createReceiptObj={createReceiptObj} setCreateReceiptObj={setCreateReceiptObj} />}
      {calculateReceiptObj?.visible && <CalculateReceiptPopup calculateReceiptObj={calculateReceiptObj} setCalculateReceiptObj={setCalculateReceiptObj} />}
      {adjustmentObj?.visible && <AdjustmentPopup adjustmentObj={adjustmentObj} setAdjustmentObj={setAdjustmentObj} />}
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      {receiptObj?.visible && <ReceiptPopup receiptObj={receiptObj} setReceiptObj={setReceiptObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showFooter: true,
            footerPosition: 'top',
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={detailExcelGridRef} columns={excelColumns} />
      </div>
    </div>
  );
};

export default Index;
