// redux
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, setMasterOptions } from 'redux/services/menuSlice';

import { ReactNode, useEffect, useState } from 'react';
import { centerListForlabeling, codeMasterOptionReturn, InfraOptionReturn, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { RootState } from 'redux/store';

import Tab1 from './moveActionList';
import { TopTab } from 'components/template/topTabNew';
import { useLocation } from 'react-router-dom';
import { TabDTO } from 'interface/user';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Index = () => {
  const location = useLocation();
  const [tabs, setTabs] = useState<TabDTO[]>();
  const [tabItems, setTabItems] = useState<{ titles?: string[]; containers: ReactNode[] }>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { useableTabs } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [CENTER_AUTH_WHOLE, CENTER_WHOLE, SELLER_AUTH_WHOLE, SELLER_WHOLE, INFRA, MODEL_GROUP, INVENTORY_MOVE_TYPE] = await Promise.all([
      centerListForlabeling({ selectFlag: true }),
      centerListForlabeling(),
      partnerListForlabeling({ sellerFlag: true }),
      partnerListForlabeling(),
      InfraOptionReturn(),
      //
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('INVENTORY_MOVE_TYPE'),
    ]);

    dispatch(
      setMasterOptions({
        CENTER_WHOLE,
        CENTER_AUTH: CENTER_AUTH_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        SELLER_AUTH: SELLER_AUTH_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        INFRA,
        MODEL_GROUP,
        INVENTORY_MOVE_TYPE,
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'barcode', label: '바코드' },
          { label: 'SKU', value: 'modelStockSeq' },
          { label: '외부SKU', value: 'skuNumber' },
        ],
        SEARCH_LOC: [
          { value: 'toLocationCode', label: '입고로케이션' },
          { value: 'fromLocationCode', label: '출고로케이션' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };

  const containerList = [{ container: <Tab1 tabId={'INV_MOVEACTION'} />, tabId: 'INV_MOVEACTION' }];

  useEffect(() => {
    if (tabs) {
      const tabIds = tabs?.map((ele) => ele.tabId);
      const tabLabeling = tabs?.map((ele) => {
        return {
          value: ele.tabId,
          label: ele.tabName,
        };
      });

      setTabItems({
        titles: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => selectlabel(ele.tabId, tabLabeling)),
        containers: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => ele.container),
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (useableTabs) {
      const tabDTO = useableTabs?.[location.pathname] ? [...useableTabs?.[location.pathname]] : [];
      if (tabDTO?.length > 0) {
        tabDTO?.sort((a: TabDTO, b: TabDTO) => a?.sort - b?.sort);
        setTabs(tabDTO);
        initMasterOptions();
      }
    }
  }, [useableTabs]);

  return <>{masterOptions && tabItems && <TopTab titles={tabItems?.titles} containers={tabItems?.containers} />}</>;
};

export default Index;
