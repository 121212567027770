import styled from 'styled-components';
import { FiCheck } from 'react-icons/fi';
import { COLORS, VALUES } from 'common/constants/appearance';
import { useEffect, useState } from 'react';
import { LDSLabelMD } from './Typography';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { UnionFieldTypeProps, UnionFormTypeProps } from '../types/types';

type CheckboxPropType = {
  $id?: string;
  $isSelected?: boolean;
  $label?: string;
  onClick?: () => void;
  $fieldName?: UnionFieldTypeProps;
  register?: UseFormRegister<UnionFormTypeProps>;
  watch?: UseFormWatch<UnionFormTypeProps>;
};

export default function LDSCheckbox({ $id, $isSelected, $label, onClick, $fieldName, register, watch }: CheckboxPropType) {
  const [checked, setChecked] = useState($isSelected);
  const { onChange, onBlur, name, ref } = register && register($fieldName);

  let flag = watch && (watch($fieldName) as boolean); //watch('loginRemainFlag');

  return (
    <LDSCheckBoxWrapperStyle $selected={checked} className="lds-checkbox">
      <input
        type="checkbox"
        defaultChecked={$isSelected}
        onChange={onChange && onChange}
        // onChange={(event: React.ChangeEvent<HTMLInputElement>) => (onChange ? onChange : setChecked(!checked))}
        ref={ref && ref}
        name={name && name}
      />
      <LDSCheckboxStyle className="input-checkbox" onClick={(event: React.SyntheticEvent) => onClick && onClick()} $selected={checked || flag}>
        {(checked || flag) && <FiCheck strokeWidth={4} size={14} />}
      </LDSCheckboxStyle>
      {$label && <LDSLabelMD $weight="MEDIUM">{$label}</LDSLabelMD>}
    </LDSCheckBoxWrapperStyle>
  );
}
const LDSCheckBoxWrapperStyle = styled.div<{
  $selected: boolean;
}>`
  display: flex;
  align-self: baseline;
  gap: 6px;
  align-items: center;
  padding: 4px 6px;
  border-radius: ${VALUES.RADIUS}px;
  cursor: default;
  position: relative;

  &:hover {
    background-color: ${COLORS.BLACK_004};
  }

  span {
    color: ${COLORS.GRAY_06};
    white-space: nowrap;
  }

  input {
    content: '';
    position: absolute;
    top: 0;
    left: -2px;
    bottom: 0;
    right: -2px;
    opacity: 0;
    margin: 0;
    z-index: 1;
    width: auto !important;
    height: auto !important;
  }

  &:hover .input-checkbox {
    filter: ${(props) => (props.$selected ? 'brightness(0.95)' : 'brightness(1)')};
    border-color: ${(props) => (props.$selected ? 'transparent' : COLORS.GRAY_06)};
  }

  &:active .input-checkbox {
    -webkit-transform: scale(0.96);
    transform: scale(0.96);
    -webkit-box-shadow: 0 1px 3px 0 transparent;
    box-shadow: 0 1px 3px 0 transparent;
  }
`;

const LDSCheckboxStyle = styled.div<{
  $selected: boolean;
}>`
  width: 16px;
  height: 16px;
  border-radius: 5px;
  border: ${(props) => (props.$selected ? `1px solid ${COLORS.PRIMARY_DEEP}` : `1.5px solid ${COLORS.GRAY_05}`)};
  flex-shrink: 0;
  cursor: pointer;
  color: ${COLORS.WHITE};
  padding: 1px 1px;
  display: flex;
  align-items: center;

  -webkit-transition: all 150ms ease-out;
  transition: all 150ms ease-out;

  background-color: ${(props) => (props.$selected ? COLORS.PRIMARY_DEEP : COLORS.WHITE)};
  box-shadow: ${(props) => (props.$selected ? `0 1px 3px 0 transparent` : `0 1px 3px 0 ${COLORS.BLACK_008}`)};
`;
