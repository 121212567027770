import { useEffect, useRef } from 'react';
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import GridBox from 'common/grid/gridBox';
import { delay } from 'common/util/counting';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';

export interface ISerialObj {
  visible?: boolean;
  item?: any;
  event?: string;
  searchObj?: string;
  fetchFn?: (e: IGrid.ButtonClickEvent) => void;
}

interface ISerialPoupObj {
  serialsPopupObj: ISerialObj;
  setSerialsPopupObj: React.Dispatch<React.SetStateAction<ISerialObj>>;
}

export const SerialsPopup = ({ serialsPopupObj, setSerialsPopupObj }: ISerialPoupObj) => {
  const gridRef = useRef<AUIGrid>();
  useEffect(() => {
    void delay(300).then(() => {
      if (gridRef?.current) {
        if (serialsPopupObj?.item) {
          const strSerial = serialsPopupObj.item.serialNumber;
          const data = new Array(Number(serialsPopupObj.item.purchaseQuantity)).fill(null);
          const serialArr = strSerial?.split(',');
          data.forEach((ele, idx) => {
            if (idx < serialArr?.length) data[idx] = serialArr?.[idx];
          });
          gridRef.current?.setGridData(
            data?.map((ele) => {
              return { serialNumber: ele };
            }),
          );
        }
      }
    });
  }, [gridRef?.current]);

  const saveSerials = async () => {
    const rs = await serviceStore?.warehouseAction(`purchase/detail/serial/number/save`, 'POST', null, {
      purchaseDetailSeq: serialsPopupObj?.item?.purchaseDetailSeq,
      serialNumbers: gridRef.current.getGridData()?.map((ele) => ele.serialNumber),
    });
    if (rs?.status === 200) serialsPopupObj?.fetchFn(JSON.parse(serialsPopupObj?.event));
  };

  return (
    <CommonModalNew
      visible={serialsPopupObj.visible}
      setVisible={() => setSerialsPopupObj(null)}
      title={serialsPopupObj.item.model}
      subTitle={serialsPopupObj.item.barcode}
      style={{ width: 400 }}
      children={
        <>
          <div className="grid-button-area only-right">
            <div
              className="dark"
              onClick={() => {
                saveSerials();
              }}
            >
              저장
            </div>
          </div>
          <GridBox
            gridRef={gridRef}
            columns={[
              {
                headerText: '시리얼번호',
                dataField: 'serialNumber',
              },
            ]}
            gridProps={{
              height: 500,
              editable: true,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
            }}
          />
        </>
      }
    />
  );
};
