import { useEffect, useState } from 'react';
import Presenter from './presenter';
import { httpClient } from 'common/http-client/axiosConfig';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

import {
  WMS_LINEHAUL_PICKING_EXCEL,
  WMS_LINEHAUL_BEFORE_LOADING,
  WMS_LINEHAUL_BEFORE_LOADING_EXCEL,
  WMS_LINEHAUL_LOADING_WEB,
  WMS_LINEHAUL_LOADING_WEB_EXCEL,
  TAKE_OUT_PICKING_LIST,
  TAKE_OUT_PICKING_LIST_EXCEL,
  TAKE_OUT_BEFORELOADING_LIST,
  TAKE_OUT_BEFORELOADING_LIST_EXCEL,
  TAKE_OUT_BEFORELOADING_CONFRIM,
  TAKE_OUT_BEFORELOADING_CONFRIM_EXCEL,
  WMS_LINEHAUL_UNLOADING_LIST,
  WMS_LINEHAUL_UNLOADING_LIST_EXCEL,
  WAREHOUSE_PURCHASE_LIST_EXCEL,
  WAREHOUSE_PURCHASE_LIST_EXCEL_DETAIL,
} from 'envVar';

const Container = (props) => {
  const dispatch = useDispatch();

  //옵션
  const { masterOptions } = useSelector((state) => state.menu);
  const [excelRowsMain, setExcelRows] = useState([]);
  const [excelheaders, setExcelHeaders] = useState([]);

  //검색자
  const [searchObj, setSearchObj] = useState({
    urlType: null,
    excelType: null,
    linehaulSeq: null,
    loadingStatementSeq: null,
    transportStatementSeq: null,
    originalCenterCode: null,
    destinationCenterCode: null,
    linehaulType: null,
    status: null,
    dateType: null,
    fromDate: null,
    toDate: null,
  });

  const categoryList = [
    // { label: '간선피킹', value: WMS_LINEHAUL_PICKING_CANCEL },
    { label: '간선피킹상세', value: WMS_LINEHAUL_PICKING_EXCEL },
    { label: '간선출고', value: WMS_LINEHAUL_BEFORE_LOADING },
    { label: '간선출고상세', value: WMS_LINEHAUL_BEFORE_LOADING_EXCEL },
    { label: '간선출차', value: WMS_LINEHAUL_LOADING_WEB },
    { label: '간선출차상세', value: WMS_LINEHAUL_LOADING_WEB_EXCEL },
    { label: '반출피킹', value: TAKE_OUT_PICKING_LIST },
    { label: '반출피킹상세', value: TAKE_OUT_PICKING_LIST_EXCEL },
    { label: '반출출고', value: TAKE_OUT_BEFORELOADING_LIST },
    { label: '반출출고상세', value: TAKE_OUT_BEFORELOADING_LIST_EXCEL },
    { label: '반출출차', value: TAKE_OUT_BEFORELOADING_CONFRIM },
    { label: '반출출차상세', value: TAKE_OUT_BEFORELOADING_CONFRIM_EXCEL },
    { label: '간선입고', value: WMS_LINEHAUL_UNLOADING_LIST },
    { label: '간선입고상세', value: WMS_LINEHAUL_UNLOADING_LIST_EXCEL },
    { label: '발주입고', value: WAREHOUSE_PURCHASE_LIST_EXCEL },
    { label: '발주입고상세', value: WAREHOUSE_PURCHASE_LIST_EXCEL_DETAIL },
  ];

  const searchValidation = (url, searchObj) => {
    if (searchObj?.dateType && searchObj?.fromDate !== null) url += `&from${searchObj?.dateType}=${searchObj?.fromDate}&to${searchObj?.dateType}=${searchObj.toDate}`;
    if (searchObj?.purchaseSeq && searchObj?.purchaseSeqType) url += `&${searchObj?.purchaseSeqType}=${searchObj?.purchaseSeq}`;
    if (searchObj?.infraSeq) url += `&infraSeq=${searchObj?.infraSeq}`;
    if (searchObj.linehaulSeq) url += `&linehaulSeq=${searchObj.linehaulSeq}`;
    if (searchObj.transportStatementSeq) url += `&transportStatementSeq=${searchObj.transportStatementSeq}`;
    if (searchObj.loadingStatementSeq) url += `&loadingStatementSeq=${searchObj.loadingStatementSeq}`;
    if (searchObj.linehaulSeq2) url += `&linehaulSeq=${searchObj.linehaulSeq2}`;
    if (searchObj.transportStatementSeq2) url += `&transportStatementSeq=${searchObj.transportStatementSeq2}`;
    if (searchObj.loadingStatementSeq2) url += `&loadingStatementSeq=${searchObj.loadingStatementSeq2}`;
    if (searchObj.centerCode) url += `&centerCode=${searchObj.centerCode}`;
    if (searchObj.centerCodeTo) url += `&centerCode=${searchObj.centerCode}`;
    if (searchObj.originalCenterCodeTo) url += `&originalCenterCode=${searchObj.originalCenterCode}`;
    if (searchObj.originalCenterCode) url += `&originalCenterCode=${searchObj.originalCenterCode}`;
    if (searchObj.destinationCenterCode) url += `&destinationCenterCode=${searchObj.destinationCenterCode}`;
    if (searchObj.linehaulType) url += `&linehaulType=${searchObj.linehaulType}`;
    if (searchObj.status) url += `&status=${searchObj.status}`;
    if (searchObj.statusOut) url += `&status=${searchObj.status}`;
    if (searchObj.partnerSeq) url += `&partnerSeq=${searchObj.partnerSeq}`;
    if (searchObj.networkPartnerSeq) url += `&networkPartnerSeq=${searchObj.networkPartnerSeq}`;
    return url;
  };

  const downloadExcel = async (str) => {
    let url;
    if (searchObj.urlType === null) {
      alert('카테고리를 먼저 선택해주세요.');
    } else if (searchObj.urlType) {
      url = searchObj.urlType + '?';
      headerDeclration();
    }
    dispatch(setLoading('GET'));
    const search = searchValidation(url, searchObj);
    console.log(search);
    httpClient
      .get(search)
      .then((res) => {
        const data = res.data || [];
        data.forEach((row) => {
          row['originalCenterCodeKr'] = selectlabel(row.originalCenterCode, masterOptions['CENTER_LABEL']);
          row['centerCodeKr'] = selectlabel(row.centerCode, masterOptions['CENTER_LABEL']);
          row['destinationCenterCodeKr'] = selectlabel(row.destinationCenterCode, masterOptions['CENTER_LABEL']);
          row['linehaulTypeKr'] = selectlabel(row.linehaulType, masterOptions['LINEHAUL_TYPE']);
          row['stockRemoveTypeKrKr'] = selectlabel(row.stockRemoveType, masterOptions['REMOVE_REASON']);
          row['modelGroupKr'] = selectlabel(row.modelGroupLarge, masterOptions['MODEL_LARGE']);
          row['partnerSeqKr'] = selectlabel(row.partnerSeq, masterOptions['SELLER']);
          row['statusKr'] = selectlabel(row.status, masterOptions['LINE_STAT']);
          row['statusOutKr'] = selectlabel(row.status, masterOptions['REMOVE_STATUS']);
          row['statusPoKr'] = selectlabel(row.status, masterOptions['PO_STATUS']);
          row['supplierPartnerSeqKr'] = selectlabel(row.supplierPartnerSeq, masterOptions['SUPPLIER']);
          row['networkPartnerSeqKr'] = selectlabel(row.networkPartnerSeq, masterOptions['NETWORK']);
          row['infraSeqKr'] = selectlabel(row.infraSeq, masterOptions['INFRA']);
        });
        setExcelRows(data);
        dispatch(setLoading(null));
      })
      .catch(async (err) => {
        dispatch(setLoading(null));
      });
  };

  const headerDeclration = () => {
    if (searchObj.urlType === 'WMS_LINEHAUL_PICKING') {
      setExcelHeaders(excelheader_tp);
    } else if (searchObj.urlType === WMS_LINEHAUL_PICKING_EXCEL) {
      setExcelHeaders(excelheader_tp_d);
    } else if (searchObj.urlType === WMS_LINEHAUL_BEFORE_LOADING) {
      setExcelHeaders(excelheader_tb);
    } else if (searchObj.urlType === WMS_LINEHAUL_BEFORE_LOADING_EXCEL) {
      setExcelHeaders(excelheader_tb_d);
    } else if (searchObj.urlType === WMS_LINEHAUL_LOADING_WEB) {
      setExcelHeaders(excelheader_tl);
    } else if (searchObj.urlType === WMS_LINEHAUL_LOADING_WEB_EXCEL) {
      setExcelHeaders(excelheader_tl_d);
    } else if (searchObj.urlType === TAKE_OUT_PICKING_LIST) {
      setExcelHeaders(excelheader_op);
    } else if (searchObj.urlType === TAKE_OUT_PICKING_LIST_EXCEL) {
      setExcelHeaders(excelheader_op_d);
    } else if (searchObj.urlType === TAKE_OUT_BEFORELOADING_LIST) {
      setExcelHeaders(excelheader_ob);
    } else if (searchObj.urlType === TAKE_OUT_BEFORELOADING_CONFRIM) {
      setExcelHeaders(excelheader_ob_d);
    } else if (searchObj.urlType === TAKE_OUT_BEFORELOADING_CONFRIM_EXCEL) {
      setExcelHeaders(excelheader_ol);
    } else if (searchObj.urlType === TAKE_OUT_BEFORELOADING_LIST_EXCEL) {
      setExcelHeaders(excelheader_ol_d);
    } else if (searchObj.urlType === WMS_LINEHAUL_UNLOADING_LIST) {
      setExcelHeaders(excelheader_tr);
    } else if (searchObj.urlType === WMS_LINEHAUL_UNLOADING_LIST_EXCEL) {
      setExcelHeaders(excelheader_tr_d);
    } else if (searchObj.urlType === WAREHOUSE_PURCHASE_LIST_EXCEL) {
      setExcelHeaders(excelheader_po);
    } else if (searchObj.urlType === WAREHOUSE_PURCHASE_LIST_EXCEL_DETAIL) {
      setExcelHeaders(excelheader_po_d);
    }
  };

  const excelheader_tp = [
    { label: '피킹지시번호', key: 'linehaulSeq' },
    { label: '상태', key: 'statusKr' },
    { label: '출발창고', key: 'originalCenterCodeKr' },
    { label: '도착창고', key: 'destinationCenterCodeKr' },
    { label: '출고예정일', key: 'linehaulDate' },
    { label: '간선유형', key: 'linehaulTypeKr' },
    { label: '간선피킹대상 양품', key: 'availableQuantity' },
    { label: '간선피킹대상 불용', key: 'defectedQuantity' },
    { label: '간선피킹대상 기타', key: 'etcQuantity' },
    { label: '피킹완료 양품', key: 'pickAvailableQuantity' },
    { label: '피킹완료 불용', key: 'pickDefectedQuantity' },
    { label: '피킹완료 기타', key: 'pickEtcQuantity' },
    { label: '미피킹 양품', key: 'availableDiff' },
    { label: '미피킹 불용', key: 'defectedDiff' },
    { label: '미피킹 기타', key: 'etcDiff' },
    { label: '피킹지시 확정자', key: 'confirmId' },
    { label: '피킹지시 확정일', key: 'confirmDatetime' },
    { label: '피킹완료일', key: 'pickDatetime' },
  ];

  const excelheader_tp_d = [
    { label: '피킹지시번호', key: 'linehaulSeq' },
    { label: '상태', key: 'statusKr' },
    { label: '출발창고', key: 'originalCenterCodeKr' },
    { label: '도착창고', key: 'destinationCenterCodeKr' },
    { label: '출고예정일', key: 'linehaulDate' },
    { label: '간선유형', key: 'linehaulTypeKr' },
    { label: '로케이션', key: 'locationCode' },
    { label: '제품그룹', key: 'modelGroupKr' },
    { label: 'SKU', key: 'modelStockSeq' },
    { label: '외부SKU', key: 'skuNumber' },
    { label: '판매사', key: 'partnerSeqKr' },
    { label: '바코드', key: 'barcode' },
    { label: '제품', key: 'model' },
    { label: '간선피킹대상 양품', key: 'availableQuantity' },
    { label: '간선피킹대상 불용', key: 'defectedQuantity' },
    { label: '간선피킹대상 기타', key: 'etcQuantity' },
    { label: '피킹완료 양품', key: 'pickAvailableQuantity' },
    { label: '피킹완료 불용', key: 'pickDefectedQuantity' },
    { label: '피킹완료 기타', key: 'pickEtcQuantity' },
    { label: '미피킹 양품', key: 'availableDiff' },
    { label: '미피킹 불용', key: 'defectedDiff' },
    { label: '미피킹 기타', key: 'etcDiff' },
    { label: '피킹지시 확정자', key: 'registerId' },
    { label: '피킹지시 확정일', key: 'registerDatetime' },
    { label: '피킹완료일', key: 'pickDatetime' },
  ];

  const excelheader_tb = [
    { label: '피킹지시번호', key: 'linehaulSeq' },
    { label: '출고지시번호', key: 'loadingStatementSeq' },
    { label: '상태', key: 'statusKr' },
    { label: '출발창고', key: 'originalCenterCodeKr' },
    { label: '도착창고', key: 'destinationCenterCodeKr' },
    { label: '출고예정일', key: 'linehaulDate' },
    { label: '간선유형', key: 'linehaulTypeKr' },
    { label: '간선출고대상 양품', key: 'availableQuantity' },
    { label: '간선출고대상 불용', key: 'defectedQuantity' },
    { label: '간선출고대상 기타', key: 'etcQuantity' },
    { label: '출고완료 양품', key: 'pickAvailableQuantity' },
    { label: '출고완료 불용', key: 'pickDefectedQuantity' },
    { label: '출고완료 기타', key: 'pickEtcQuantity' },
    { label: '미출고 양품', key: 'availableDiff' },
    { label: '미출고 불용', key: 'defectedDiff' },
    { label: '미출고 기타', key: 'etcDiff' },
    { label: '출고지시 확정자', key: 'confirmId' },
    { label: '출고지시 확정일', key: 'confirmDatetime' },
    { label: '출고완료일', key: 'promiseUnloadDate' },
  ];

  const excelheader_tb_d = [
    { label: '피킹지시번호', key: 'linehaulSeq' },
    { label: '출고지시번호', key: 'loadingStatementSeq' },
    { label: '상태', key: 'statusKr' },
    { label: '출발창고', key: 'originalCenterCodeKr' },
    { label: '도착창고', key: 'destinationCenterCodeKr' },
    { label: '출고예정일', key: 'linehaulDate' },
    { label: '간선유형', key: 'linehaulTypeKr' },
    { label: '로케이션', key: 'locationCode' },
    { label: '제품그룹', key: 'modelGroupKr' },
    { label: 'SKU', key: 'modelStockSeq' },
    { label: '외부SKU', key: 'skuNumber' },
    { label: '판매사', key: 'partnerSeqKr' },
    { label: '바코드', key: 'barcode' },
    { label: '제품', key: 'model' },
    { label: '간선출고대상 양품', key: 'availableQuantity' },
    { label: '간선출고대상 불용', key: 'defectedQuantity' },
    { label: '간선출고대상 기타', key: 'etcQuantity' },
    { label: '출고완료 양품', key: 'pickAvailableQuantity' },
    { label: '출고완료 불용', key: 'pickDefectedQuantity' },
    { label: '출고완료 기타', key: 'pickEtcQuantity' },
    { label: '미출고 양품', key: 'availableDiff' },
    { label: '미출고 불용', key: 'defectedDiff' },
    { label: '미출고 기타', key: 'etcDiff' },
    { label: '출고지시 확정자', key: 'registerId' },
    { label: '출고지시 확정일', key: 'registerDatetime' },
    { label: '출고완료일', key: 'loadDatetime' },
  ];

  const excelheader_tl = [
    { label: '피킹지시번호', key: 'linehaulSeq' },
    { label: '출고지시번호', key: 'loadingStatementSeq' },
    { label: '운송번호', key: 'transportStatementSeq' },
    { label: '상태', key: 'statusKr' },
    { label: '출발창고', key: 'originalCenterCodeKr' },
    { label: '도착창고', key: 'destinationCenterCodeKr' },
    { label: '출차예정일', key: 'linehaulDate' },
    { label: '간선유형', key: 'linehaulTypeKr' },
    { label: '간선출차대상 양품', key: 'availableQuantity' },
    { label: '간선출차대상 불용', key: 'defectedQuantity' },
    { label: '간선출차대상 기타', key: 'etcQuantity' },
    { label: '출차지시 확정자', key: 'confirmId' },
    { label: '출차완료일', key: 'loadDatetime' },
    { label: '운송기사명', key: 'loadingDriverName' },
    { label: '기사전화번호', key: 'loadingDriverPhone' },
    { label: '차량종류', key: 'loadingDriverCarType' },
    { label: '차량번호', key: 'loadingDriverCarNumber' },
  ];

  const excelheader_tl_d = [
    { label: '피킹지시번호', key: 'linehaulSeq' },
    { label: '출고지시번호', key: 'loadingStatementSeq' },
    { label: '운송번호', key: 'transportStatementSeq' },
    { label: '상태', key: 'statusKr' },
    { label: '출발창고', key: 'originalCenterCodeKr' },
    { label: '도착창고', key: 'destinationCenterCodeKr' },
    { label: '출차예정일', key: 'linehaulDate' },
    { label: '간선유형', key: 'linehaulTypeKr' },
    { label: '로케이션', key: 'locationCode' },
    { label: '제품그룹', key: 'modelGroupKr' },
    { label: 'SKU', key: 'modelStockSeq' },
    { label: '외부SKU', key: 'skuNumber' },
    { label: '판매사', key: 'partnerSeqKr' },
    { label: '바코드', key: 'barcode' },
    { label: '제품', key: 'model' },
    { label: '간선출차대상 양품', key: 'availableQuantity' },
    { label: '간선출차대상 불용', key: 'defectedQuantity' },
    { label: '간선출차대상 기타', key: 'etcQuantity' },
    { label: '출차지시 확정자', key: 'confirmId' },
    { label: '출차완료일', key: 'loadDatetime' },
    { label: '운송기사명', key: 'loadingDriverName' },
    { label: '기사전화번호', key: 'loadingDriverPhone' },
    { label: '차량종류', key: 'loadingDriverCarType' },
    { label: '차량번호', key: 'loadingDriverCarNumber' },
  ];

  const excelheader_op = [
    { label: '피킹지시번호', key: 'stockRemoveSeq' },
    { label: '상태', key: 'statusOutKr' },
    { label: '반출창고', key: 'centerCodeKr' },
    { label: '출고예정일', key: 'stockRemoveDate' },
    { label: '반출유형', key: 'stockRemoveTypeKr' },
    { label: '반출피킹대상 양품', key: 'availableQuantity' },
    { label: '반출피킹대상 불용', key: 'defectedQuantity' },
    { label: '피킹완료 양품', key: 'pickAvailableQuantity' },
    { label: '피킹완료 불용', key: 'pickDefectedQuantity' },
    { label: '미피킹 양품', key: 'availableDiff' },
    { label: '미피킹 불용', key: 'defectedDiff' },
    { label: '피킹지시 확정자', key: 'confirmId' },
    { label: '피킹지시 확정일', key: 'initDatetime' },
  ];

  const excelheader_op_d = [
    { label: '피킹지시번호', key: 'stockRemoveSeq' },
    { label: '상태', key: 'statusOutKr' },
    { label: '반출창고', key: 'centerCodeKr' },
    { label: '출고예정일', key: 'stockRemoveDate' },
    { label: '반출유형', key: 'stockRemoveTypeKr' },
    { label: '로케이션', key: 'locationCode' },
    { label: '제품그룹', key: 'modelGroupKr' },
    { label: 'SKU', key: 'modelStockSeq' },
    { label: '외부SKU', key: 'skuNumber' },
    { label: '판매사', key: 'partnerSeqKr' },
    { label: '바코드', key: 'barcode' },
    { label: '제품', key: 'model' },
    { label: '반출피킹대상 양품', key: 'availableQuantity' },
    { label: '반출피킹대상 불용', key: 'defectedQuantity' },
    { label: '피킹완료 양품', key: 'pickAvailableQuantity' },
    { label: '피킹완료 불용', key: 'pickDefectedQuantity' },
    { label: '미피킹 양품', key: 'availableDiff' },
    { label: '미피킹 불용', key: 'defectedDiff' },
    { label: '피킹지시 확정자', key: 'register' },
    { label: '피킹지시 확정일', key: 'registerDatetime' },
    { label: '피킹완료일', key: 'pickDatetime' },
  ];

  const excelheader_ob = [
    { label: '피킹지시번호', key: 'stockRemoveSeq' },
    { label: '출고지시번호', key: 'loadingStatementSeq' },
    { label: '반출창고', key: 'centerCodeKr' },
    { label: '판매사', key: 'partnerSeqKr' },
    { label: '상태', key: 'statusOutKr' },
    { label: '반출대상', key: 'target' },
    { label: '출고예정일', key: 'stockRemoveDate' },
    { label: '반출유형', key: 'stockRemoveTypeKr' },
    { label: '반출출고대상 양품', key: 'availableQuantity' },
    { label: '반출출고대상 불용', key: 'defectedQuantity' },
    { label: '출고완료 양품', key: 'loadAvailableQuantity' },
    { label: '출고완료 불용', key: 'loadDefectedQuantity' },
    { label: '미출고 양품', key: 'availableDiff' },
    { label: '미출고 불용', key: 'defectedDiff' },
    { label: '출고지시 확정자', key: 'confirmId' },
    { label: '출고지시 확정일', key: 'initDatetime' },
  ];

  const excelheader_ob_d = [
    { label: '피킹지시번호', key: 'stockRemoveSeq' },
    { label: '출고지시번호', key: 'loadingStatementSeq' },
    { label: '반출창고', key: 'centerCodeKr' },
    { label: '판매사', key: 'partnerSeqKr' },
    { label: '상태', key: 'statusOutKr' },
    { label: '반출대상', key: 'target' },
    { label: '출고예정일', key: 'stockRemoveDate' },
    { label: '반출유형', key: 'stockRemoveTypeKr' },
    { label: '로케이션', key: 'locationCode' },
    { label: '제품그룹', key: 'modelGroupKr' },
    { label: 'SKU', key: 'modelStockSeq' },
    { label: '외부SKU', key: 'skuNumber' },
    { label: '바코드', key: 'barcode' },
    { label: '제품', key: 'model' },
    { label: '반출출고대상 양품', key: 'availableQuantity' },
    { label: '반출출고대상 불용', key: 'defectedQuantity' },
    { label: '출고완료 양품', key: 'loadAvailableQuantity' },
    { label: '출고완료 불용', key: 'loadDefectedQuantity' },
    { label: '미출고 양품', key: 'availableDiff' },
    { label: '미출고 불용', key: 'defectedDiff' },
    { label: '출고지시 확정자', key: 'register' },
    { label: '출고지시 확정일', key: 'registerDatetime' },
    { label: '출고완료일', key: 'loadDatetime' },
  ];

  const excelheader_ol = [
    { label: '피킹지시번호', key: 'stockRemoveSeq' },
    { label: '출고지시번호', key: 'loadingStatementSeq' },
    { label: '운송번호', key: 'transportStatementSeq' },
    { label: '반출창고', key: 'centerCodeKr' },
    { label: '판매사', key: 'partnerSeqKr' },
    { label: '상태', key: 'statusOutKr' },
    { label: '반출대상', key: 'target' },
    { label: '출고예정일', key: 'stockRemoveDate' },
    { label: '반출유형', key: 'stockRemoveTypeKr' },
    { label: '반출출차대상 양품', key: 'availableQuantity' },
    { label: '반출출차대상 불용', key: 'defectedQuantity' },
    { label: '출차지시 확정자', key: 'confirmId' },
    { label: '운송기사명', key: 'loadingDriverName' },
    { label: '기사전화번호', key: 'loadingDriverPhone' },
    { label: '차량종류', key: 'loadingDriverCarType' },
    { label: '차량번호', key: 'loadingDriverCarNumber' },
  ];

  const excelheader_ol_d = [
    { label: '피킹지시번호', key: 'stockRemoveSeq' },
    { label: '출고지시번호', key: 'loadingStatementSeq' },
    { label: '운송번호', key: 'transportStatementSeq' },
    { label: '반출창고', key: 'centerCodeKr' },
    { label: '판매사', key: 'partnerSeqKr' },
    { label: '상태', key: 'statusOutKr' },
    { label: '반출대상', key: 'target' },
    { label: '출고예정일', key: 'stockRemoveDate' },
    { label: '반출유형', key: 'stockRemoveTypeKr' },
    { label: '로케이션', key: 'locationCode' },
    { label: '제품그룹', key: 'modelGroupKr' },
    { label: 'SKU', key: 'modelStockSeq' },
    { label: '외부SKU', key: 'skuNumber' },
    { label: '바코드', key: 'barcode' },
    { label: '제품', key: 'model' },
    { label: '반출출차대상 양품', key: 'availableQuantity' },
    { label: '반출출차대상 불용', key: 'defectedQuantity' },
    { label: '출차지시 확정자', key: 'register' },
    { label: '출차완료일', key: 'loadDatetime' },
    { label: '운송기사명', key: 'loadingDriverName' },
    { label: '기사전화번호', key: 'loadingDriverPhone' },
    { label: '차량종류', key: 'loadingDriverCarType' },
    { label: '차량번호', key: 'loadingDriverCarNumber' },
  ];

  const excelheader_tr = [
    { label: '피킹지시번호', key: 'linehaulSeq' },
    { label: '출고지시번호', key: 'loadingStatementSeq' },
    { label: '운송번호', key: 'transportStatementSeq' },
    { label: '상태', key: 'statusKr' },
    { label: '출발창고', key: 'originalCenterCodeKr' },
    { label: '도착창고', key: 'destinationCenterCodeKr' },
    { label: '출고예정일', key: 'linehaulDate' },
    { label: '간선유형', key: 'linehaulTypeKr' },
    { label: '입고예정수량 양품', key: 'availableQuantity' },
    { label: '입고예정수량 불용', key: 'defectedQuantity' },
    { label: '입고예정수량 기타', key: 'etcQuantity' },
    { label: '입고완료 양품', key: 'unloadAvailableQuantity' },
    { label: '입고완료 불용', key: 'unloadDefectedQuantity' },
    { label: '입고완료 기타', key: 'unloadEtcQuantity' },
    { label: '로케이션 양품', key: 'locationAvailableQuantity' },
    { label: '로케이션 불용', key: 'locationDefectedQuantity' },
    { label: '로케이션 기타', key: 'locationEtcQuantity' },
    { label: '미입고 양품', key: 'availableDiff' },
    { label: '미입고 불용', key: 'defectedDiff' },
    { label: '미입고 기타', key: 'etcDiff' },
    { label: '입고마감 확정자', key: 'unloadId' },
    { label: '입고마감 확정일', key: 'unloadDatetime' },
    { label: '운송기사명', key: 'loadingDriverName' },
    { label: '기사전화번호', key: 'loadingDriverPhone' },
    { label: '차량종류', key: 'loadingDriverCarType' },
    { label: '차량번호', key: 'loadingDriverCarNumber' },
  ];

  const excelheader_tr_d = [
    { label: '피킹지시번호', key: 'linehaulSeq' },
    { label: '출고지시번호', key: 'loadingStatementSeq' },
    { label: '운송번호', key: 'transportStatementSeq' },
    { label: '상태', key: 'statusKr' },
    { label: '출발창고', key: 'originalCenterCodeKr' },
    { label: '도착창고', key: 'destinationCenterCodeKr' },
    { label: '출고예정일', key: 'linehaulDate' },
    { label: '간선유형', key: 'linehaulTypeKr' },
    { label: '로케이션', key: 'locationCode' },
    { label: '제품그룹', key: 'modelGroupKr' },
    { label: 'SKU', key: 'modelStockSeq' },
    { label: '외부SKU', key: 'skuNumber' },
    { label: '판매사', key: 'partnerSeqKr' },
    { label: '바코드', key: 'barcode' },
    { label: '제품', key: 'model' },
    { label: '입고예정수량 양품', key: 'availableQuantity' },
    { label: '입고예정수량 불용', key: 'defectedQuantity' },
    { label: '입고예정수량 기타', key: 'etcQuantity' },
    { label: '입고완료 양품', key: 'unloadAvailableQuantity' },
    { label: '입고완료 불용', key: 'unloadDefectedQuantity' },
    { label: '입고완료 기타', key: 'unloadEtcQuantity' },
    { label: '로케이션 양품', key: 'locationAvailableQuantity' },
    { label: '로케이션 불용', key: 'locationDefectedQuantity' },
    { label: '로케이션 기타', key: 'locationEtcQuantity' },
    { label: '미입고 양품', key: 'availableDiff' },
    { label: '미입고 불용', key: 'defectedDiff' },
    { label: '미입고 기타', key: 'etcDiff' },
    { label: '입고마감 확정자', key: 'unloadId' },
    { label: '입고마감 확정일', key: 'unloadDatetime' },
    { label: '운송기사명', key: 'loadingDriverName' },
    { label: '기사전화번호', key: 'loadingDriverPhone' },
    { label: '차량종류', key: 'loadingDriverCarType' },
    { label: '차량번호', key: 'loadingDriverCarNumber' },
  ];

  const excelheader_po = [
    { label: '발주번호', key: 'purchaseSeq' },
    { label: '외부발주번호', key: 'externalPurchaseNumber' },
    { label: '진행상태', key: 'statusPoKr' },
    { label: '판매사', key: 'partnerSeqKr' },
    { label: '위탁사', key: 'networkPartnerSeqKr' },
    { label: '공급사', key: 'supplierPartnerSeqKr' },
    { label: '인프라', key: 'infraSeqKr' },
    { label: '입고창고', key: 'centerCodeKr' },
    { label: '발주일', key: 'purchaseDate' },
    { label: '입고예정일', key: 'promiseDate' },
    { label: '입고확정일', key: 'finishDate' },
    { label: '발주수량', key: 'purchaseCount' },
    { label: '입고수량(누적)', key: 'confirmCount' },
    { label: '위치지정수량', key: 'locationCount' },
    { label: '미입고(발주-위치지정)', key: 'detailCount' },
    { label: '발주등록자', key: 'registerId' },
    { label: '발주마감일', key: 'receiveDate' },
    { label: '발주마감자', key: 'approveId' },
    { label: '특이사항', key: 'memo' },
  ];

  const excelheader_po_d = [
    { label: '발주번호', key: 'purchaseSeq' },
    { label: '외부발주번호', key: 'externalPurchaseNumber' },
    { label: '진행상태', key: 'statusPoKr' },
    { label: '판매사', key: 'partnerSeqKr' },
    { label: '위탁사', key: 'networkPartnerSeqKr' },
    { label: '공급사', key: 'supplierPartnerSeqKr' },
    { label: '인프라', key: 'infraSeqKr' },
    { label: '로케이션', key: 'locationCode' },
    { label: '제품그룹', key: 'modelGroupKr' },
    { label: 'SKU', key: 'modelStockSeq' },
    { label: '외부SKU', key: 'skuNumber' },
    { label: '판매사', key: 'partnerSeqKr' },
    { label: '바코드', key: 'barcode' },
    { label: '제품', key: 'model' },
    { label: '입고센터', key: 'centerCodeKr' },
    { label: '발주일', key: 'purchaseDate' },
    { label: '입고예정일', key: 'promiseDate' },
    { label: '입고확정일', key: 'finishDate' },
    { label: '발주수량', key: 'purchaseQuantity' },
    { label: '입고수량(누적)', key: 'confirmQuantity' },
    { label: '위치지정수량', key: 'locationQuantity' },
    { label: '미입고(발주-위치지정)', key: 'diff' },
    { label: '발주마감자', key: 'approveId' },
    { label: '특이사항', key: 'memo' },
    { label: '수량차이사유', key: 'description' },
  ];

  return <Presenter searchObj={searchObj} setSearchObj={setSearchObj} excelheaders={excelheaders} excelRowsMain={excelRowsMain} downloadExcel={downloadExcel} categoryList={categoryList} />;
};
export default Container;
