import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { userService } from '../../_service/service';

// utils
import { InfraDTO, InfraSearchDTO } from 'interface/warehouse';
import { SearchBox } from './component/searchBox';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { Create, ICreate } from './component/create';
import { DetailPopup } from './component/detail';
import { PagingListDTO } from 'interface/util';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [createObj, setCreateObj] = useState<ICreate>();
  const [detailObj, setDetailObj] = useState<any>();

  const popupDetail = (e) => {
    setDetailObj({
      visible: true,
      item: e.item,
      fetchFn: (data) => wrappedFetchList(data),
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupDetail,
      },
    },
    {
      headerText: '인프라유형',
      dataField: 'infraTypeKr',
    },
    {
      headerText: '인프라번호',
      dataField: 'infraSeq',
    },
    {
      headerText: '인프라이름',
      dataField: 'infraName',
    },
  ];

  const labellingKr = (data: PagingListDTO<InfraDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        infraTypeKr: MASTER_OBJ?.INFRA_TYPE?.[row.infraType],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InfraSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await userService.getInfraListPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 100,
    },
    gridRef,
    fetchPaging,
  });

  const deleteInfra = async (items) => {
    if (window.confirm('삭제하시겠습니까?')) {
      const rs = await userService.deleteInfra(
        items.map((ele) => {
          return { infraSeq: ele.infraSeq };
        }),
      );
      if (rs?.status === 200) {
        alert('삭제되었습니다');
        wrappedFetchList(searchObj);
      }
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'CREATEINFRA') {
      setCreateObj({ visible: true, searchObj: JSON.stringify(searchObj), fetchFn: (data) => wrappedFetchList(data) });
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'DELETEINFRA') deleteInfra(items);
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      {createObj?.visible && <Create createObj={createObj} setCreateObj={setCreateObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            fixedColumnCount: 1,
          }}
        />
      </div>
    </div>
  );
};

export default Index;
