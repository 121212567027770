import { v4 as uuidv4 } from 'uuid';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import Presenter from './presenter';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { defaultBottomSheetObj } from 'components/template/bottomSheets';
import useDebounce from 'hooks/debounceHooks';

// util
import { capitalizeParamName } from 'common/util/searchBox';

const Container = (props) => {
  const dispatch = useDispatch();
  // options
  const { masterOptions } = useSelector((state) => state.menu);

  const { reduxUserInfo } = useSelector((state) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  const [gridId, setGrid] = useState('');
  const [rows, setRows] = useState([]);

  const [pagingSetting, setPagingSetting] = useState({
    pageNo: 1,
    pageSize: 100,
    fetchNew: false,
  });

  //바텀시트
  const [bottomSheetObj, setBottomSheetObj] = useState(defaultBottomSheetObj);
  const [haldangObj, setHaldangObj] = useState({
    visible: false,
    data: null,
    confirmFn: (data, searchObj) => confirmPickingAPI(data, searchObj),
    rollbackFn: (data, searchObj) => rollbackStatusAPI(data, searchObj),
  });
  const [cancelObj, setCancelObj] = useState({
    visible: false,
    data: null,
    cancelFn: (data, searchObj) => cancelPickingAPI(data, searchObj),
  });
  const [locationChangingObj, setLocationChangingObj] = useState({
    visible: false,
    item: null,
    saveFn: (data, searchObj) => saveLocationAPI(data, searchObj),
  });
  const [searchObj, setSearchObj] = useState({
    loanPickSeq: null,
    loanPickDate: null,
    centerCode: null,
    userId: null,
    status: null,

    ...defaultSearchFilter,
  });

  /* 미사용
  const [forcingMagamObj, setForcingMagamObj] = useState({
    visible: false,
    items: null,
    magamFn: (data, searchObj) => forcingMagamAPI(data, searchObj),
  }); */

  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false); ///////main

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'confirm-picking') {
        if (items?.length > 1) {
          alert('피킹지시 확정은 한개씩만 가능합니다!');
        } else {
          confirmPicking(items[0]);
        }
      } else if (getCheckedDataTriggerThisGrid?.type === 'complete-picking') {
        if (items?.length > 1) {
          alert('피킹 완료는 한개씩만 가능합니다!');
        } else {
          completePickingAPI(items[0], searchObj);
        }
      } else if (getCheckedDataTriggerThisGrid?.type === 'rollback-status') {
        rollbackStatus(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'cancel-picking') {
        cancelPicking(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'print-all') {
        printAllAction(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  //NOTE: 특정 API 액션 수행 이후 refetch를 처리할 함수
  const refetchList = (searchObj, bottomSheetObj = null) => {
    fetchList(searchObj, bottomSheetObj ? false : true);
    // NOTE: refetch 시점에 바텀시트가 열려있다면 origin 객체를 기준으로 fetchDetailList를 수행.
    if (bottomSheetObj) fetchDetailList(bottomSheetObj);
  };

  useEffect(() => {
    setGrid(`fcPicking_Driver_${uuidv4()}`);
  }, []);

  const saveLocationAPI = ({ data, event, searchObj, bottomSheetObj }) => {
    httpClient.post(`/warehouse/loan/picking/location/change`, data).then((rs) => {
      if (rs?.status === 200) {
        alert('로케이션 이동에 성공하였습니다!');

        setLocationChangingObj((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        refetchList(searchObj, bottomSheetObj);
      }
    });
  };
  const paramingSearchObjToUrl = (url, searchObj) => {
    let searchParams = [];
    if (searchObj) searchParams = Object.keys(searchObj);
    if (searchParams.length === 0) return url;

    let urlWithSearchParams = url;
    for (let param of searchParams) {
      if (searchObj[param] !== null && searchObj[param] !== '')
        !param.includes('Date')
          ? (urlWithSearchParams += `&${param}=${searchObj[param]}`)
          : searchObj[param]?.[1] !== null
          ? (urlWithSearchParams += `&from${capitalizeParamName(param)}=${searchObj[param][0]}&to${capitalizeParamName(param)}=${searchObj[param][1]}`)
          : (urlWithSearchParams += '');
    }

    return urlWithSearchParams;
  };

  const fetchList = (searchObj, init = true) => {
    if (init) {
      setBottomSheetObj(defaultBottomSheetObj);
    }

    dispatch(setLoading('GET'));
    let url = `/warehouse/loan/picking/driver/paging?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
    url = paramingSearchObjToUrl(url, searchObj);

    httpClient
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          const addList = res.data.list || [];
          const maxPage = Math.ceil(res.data.totalCount / pagingSetting?.pageSize);
          addList?.forEach((row) => {
            row.maxPage = maxPage;
            row.totalCount = res.data.totalCount;
            row.searchObj = JSON.stringify(searchObj);
            row.print = row.status === 'INIT' ? '' : '프린트';
            row.userIdKr = masterOptions?.DRIVER_LABEL_OBJ?.[row.userId];
            row.loanPickTypeKr = masterOptions?.LOAN_PICK_TYPE_OBJ?.[row.loanPickType];
            row.statusKr = masterOptions?.LOAN_PICK_STATUS_OBJ?.[row.status];
            row.centerCodeKr = masterOptions?.CENTER_OBJ?.[row.centerCode];
          });

          setRows(addList);
          setPagingSetting((prev) => {
            return {
              ...prev,
              fetchNew: false,
            };
          });
        }
      })
      .catch((error) => alert(error.message))
      .finally(() => dispatch(setLoading(null)));
  };

  const bottomGridRef = useRef();

  const confirmLocateChanging = (items) => {
    const event = items?.[0]?.e;
    setLocationChangingObj(() => {
      return {
        item: items?.[0],
        visible: true,
        event,
        searchObj: items?.[0]?.searchObj,
        saveFn: (data, searchObj, bottomSheetObj) => saveLocationAPI(data, searchObj, bottomSheetObj),
      };
    });
  };

  const fetchDetailList = async (e) => {
    dispatch(setLoading('GET'));
    let url = '/warehouse/loan/picking/detail?';
    if (e.item.loanPickSeq) url += `&loanPickSeq=${e.item?.loanPickSeq}`;
    if (e.item.centerCode) url += `&centerCode=${e.item?.centerCode}`;
    if (e.item.loanPickDate) url += `&loanPickDate=${e.item?.loanPickDate}`;
    if (e.item.userId) url += `&userId=${e.item?.userId}`;

    const rs = await httpClient.get(url);
    if (rs?.status === 200) {
      const rows = rs.data || [];
      rows?.forEach((row) => {
        row.e = JSON.stringify(e);
        row.searchObj = e.item.searchObj;
        row.modelGroupKr = selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP);
        row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
        row.statusKr = selectlabel(row.status, masterOptions?.LOAN_PICK_STATUS);
      });

      const gridButtonhandler = (e) => {
        const id = e.target.id;
        const items = bottomGridRef?.current?.getCheckedRowItemsAll();
        if (id === 'excel-down') {
          bottomGridRef?.current?.exportAsXlsx({ fileName: `FC피킹(기사)_상세` });
        } else if (id === 'change-location') {
          const filtered = items.filter((ele) => ele.statusKr !== '피킹지시대기');
          if (filtered?.length > 0) {
            alert('피킹지시대기상태에서만 가능합니다!');
          } else {
            if (items?.length > 1) {
              alert('한개씩 변경가능합니다!');
            } else {
              confirmLocateChanging(items);
            }
          }
        }
      };

      setBottomSheetObj({
        visible: true,
        hasTabs: [
          {
            title: '기사출고 상세',
            gridRef: bottomGridRef,
            columns: detailColumns,
            rows,
            buttons: [
              <div key={`change-location`} id={`change-location`} className={`orange`} onClick={gridButtonhandler}>
                로케이션 변경
              </div>,
              <div key={`excel-down`} id={`excel-down`} className={`green`} onClick={gridButtonhandler}>
                엑셀다운
              </div>,
            ],
            options: {
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              showFooter: false,
              rowStyleFunction: function (rowIndex, item) {
                if (item.orderCancelFlag === true) return 'warning-row-style';
                return '';
              },
            },
          },
        ],
      });
    }
    dispatch(setLoading(null));
  };

  /* 미사용
  const forcingMagamAPI = async ({ loanPickSeq, list, e, searchObj }) => {
    const data = {
      loanPickSeq,
      list,
    };
    dispatch(setLoading('POST'));
    await httpClient.post(`/warehouse/loan/picking/close`, data).then((rs) => {
      if (rs.status === 200) {
        alert('강제마감 되었습니다!');

        refetchList(searchObj);
      }
    });
    dispatch(setLoading(null));
  }; */

  const confirmPickingAPI = async (dataArr, searchObj) => {
    dispatch(setLoading('POST'));
    await httpClient.post('/warehouse/loan/picking/confirm', dataArr).then((rs) => {
      if (rs?.status === 200) {
        if (rs?.data?.message) alert(rs?.data?.message);
        else alert('FC피킹 지시 확정되었습니다!');

        refetchList(searchObj);
      }
    });
    dispatch(setLoading(null));
  };

  const confirmPicking = useCallback(
    useDebounce(async (item) => {
      const data = {
        centerCode: item?.centerCode,
        loanPickDate: item?.loanPickDate,
        userId: item?.userId,
      };
      confirmPickingAPI([data], searchObj);
    }, 1000),
    [searchObj],
  );

  const completePickingAPI = async (item, searchObj) => {
    if (item.statusKr === '피킹지시확정') {
      if (window.confirm('피킹 완료 처리하시겠습니까?')) {
        dispatch(setLoading('POST'));
        await httpClient.post(`/warehouse/loan/picking/complete?loanPickSeq=${item.loanPickSeq}&userId=${item.userId}`).then((rs) => {
          if (rs.status === 200) {
            alert('완료 처리되었습니다!');

            refetchList(searchObj);
          }
        });
        dispatch(setLoading(null));
      }
    } else {
      alert('피킹 완료처리 불가한 진행상태 입니다!');
    }
  };

  const rollbackStatusAPI = async (dataArr, searchObj) => {
    dispatch(setLoading('POST'));
    await httpClient.post('/warehouse/loan/picking/rollback', dataArr).then((rs) => {
      if (rs?.status === 200) {
        alert('이전단계로 상태값 변경이 완료되었습니다!');

        refetchList(searchObj);
      }
    });
    dispatch(setLoading(null));
  };

  const rollbackStatus = (items) => {
    const availStat = ['피킹지시확정', '피킹중'];
    const statusKr = items[0].statusKr;
    if (items.every((item) => item.statusKr === statusKr)) {
      if (availStat.includes(statusKr)) {
        if (window.confirm('"이전단계"를 진행하시겠습니까?')) {
          const dataArr = items.map((ele) => {
            return {
              loanPickSeq: ele.loanPickSeq,
              userId: ele.userId,
            };
          });
          rollbackStatusAPI(dataArr, searchObj);
        }
      } else {
        alert('피킹지시확정 혹은 피킹중 상태만 이전단계로 돌릴 수 있습니다!');
      }
    } else {
      alert(`진행상태가 서로 다른 건들이 선택되었습니다.\n 이전단계로 돌아가려면 진행상태가 동일해야합니다.`);
    }
  };

  const cancelPicking = (items) => {
    const availStat = ['피킹지시대기', '피킹지시확정'];
    if (items.every((item) => availStat.includes(item.statusKr))) {
      setCancelObj((prev) => {
        return {
          ...prev,
          visible: true,
          data: items,
          searchObj,
        };
      });
    } else {
      alert('피킹취소는 진행상태가 피킹지시대기, 피킹지시확정 일때에만 가능합니다!');
    }
  };

  const cancelPickingAPI = async (dataArr, searchObj) => {
    dispatch(setLoading('POST'));
    await httpClient.post('/warehouse/loan/picking/cancel', dataArr).then((rs) => {
      if (rs?.status === 200) {
        alert('피킹취소에 성공하였습니다!');

        setCancelObj((prev) => {
          return {
            ...prev,
            visible: false,
            refetchFn: null,
            searchObj: null,
          };
        });

        refetchList(searchObj);
      }
    });
    dispatch(setLoading(null));
  };

  const onCellClick = (e) => {
    if (e.pid.includes('fcPicking')) {
      if (e.dataField === 'print' && e.value) {
        printAction(e);
      }
    }
  };

  const [fcPrintObj, setFcPrintObj] = useState();
  const printAction = async (event) => {
    setFcPrintObj({
      visible: true,
      type: 'driver',
      items: [event?.item],
    });
  };

  const printAllAction = (items) => {
    setFcPrintObj({
      visible: true,
      type: 'driver',
      items,
    });
  };

  const haldangModalAction = async (e) => {
    setHaldangObj((prev) => {
      return {
        ...prev,
        visible: true,
        origin: e.item,
      };
    });
  };

  const columns = [
    {
      headerText: '상세보기',
      renderer: {
        type: 'ButtonRenderer',
        labelText: '상세보기',
        onClick: fetchDetailList,
      },
    },
    {
      headerText: '할당정보',
      dataField: 'modal',
      renderer: {
        type: 'ButtonRenderer',
        labelText: '할당정보',
        onClick: haldangModalAction,
      },
    },
    {
      headerText: '피킹지시서',
      dataField: 'print',
      style: 'click-fake-item',
    },
    {
      headerText: '피킹지시번호',
      dataField: 'loanPickSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고예정일',
      dataField: 'loanPickDate',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '유형',
      dataField: 'loanPickTypeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '기사',
      dataField: 'userIdKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: 'FC피킹지시 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },

    {
      headerText: '피킹완료 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'pickAvailableQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'pickEtcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '미피킹 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcDiff',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '피킹지시<br />확정일',
      dataField: 'confirmDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '피킹지시<br />확정자',
      dataField: 'confirmId',
      filter: {
        showIcon: true,
      },
    },
  ];

  const detailColumns = [
    {
      headerText: '피킹지시번호',
      dataField: 'loanPickSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: 'FC피킹지시 수량',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '바코드',
          dataField: 'barcode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: 'FC피킹지시 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },

    {
      headerText: '피킹완료 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'pickAvailableQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'pickEtcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '미피킹 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcDiff',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '피킹완료일',
      dataField: 'pickDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '피킹완료자',
      dataField: 'pickId',
      filter: {
        showIcon: true,
      },
    },
  ];

  const downloadExcel = async (type) => {
    dispatch(setLoading('GET'));
    let url;

    if (type === 'main') {
      url = '/warehouse/loan/picking/driver/excel?';
      url = paramingSearchObjToUrl(url, searchObj);
    } else {
      url = '/warehouse/loan/picking/driver/detail/excel?';
      url = paramingSearchObjToUrl(url, searchObj);
    }

    return await httpClient.get(url).then((rs) => {
      if (rs.status === 200) {
        rs.data.forEach((row) => {
          row.userIdKr = masterOptions?.DRIVER_LABEL_OBJ?.[row.userId];
          row.loanPickTypeKr = masterOptions?.LOAN_PICK_TYPE_OBJ?.[row.loanPickType];
          row.statusKr = masterOptions?.LOAN_PICK_STATUS_OBJ?.[row.status];
          row.centerCodeKr = masterOptions?.CENTER_OBJ?.[row.centerCode];
          row.partnerSeqKr = masterOptions?.SELLER_OBJ?.[row.partnerSeq];
          row.modelGroupKr = masterOptions?.MODEL_GRP_1_OBJ?.[row.modelGroupLarge];
        });
        dispatch(setLoading(null));
        return rs.data;
      } else {
        dispatch(setLoading(null));
        return null;
      }
    });
  };

  return (
    <Presenter
      gridId={gridId}
      rows={rows}
      columns={columns}
      detailColumns={detailColumns}
      //
      pagingSetting={pagingSetting}
      setPagingSetting={setPagingSetting}
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      fetchList={fetchList}
      onCellClick={onCellClick}
      downloadExcel={downloadExcel}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      //
      bottomSheetObj={bottomSheetObj}
      setBottomSheetObj={setBottomSheetObj}
      haldangObj={haldangObj}
      setHaldangObj={setHaldangObj}
      // 로케이션 변경
      locationChangingObj={locationChangingObj}
      setLocationChangingObj={setLocationChangingObj}
      cancelObj={cancelObj}
      setCancelObj={setCancelObj}
      // forcingMagamObj={forcingMagamObj}
      // setForcingMagamObj={setForcingMagamObj}
      fcPrintObj={fcPrintObj}
      setFcPrintObj={setFcPrintObj}
    />
  );
};

export default Container;
