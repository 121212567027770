import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';

import MenuLayout from 'pages/_HOME/home';
// css import
import './App.css';
import '../src/common/css/common.scss';
import '../src/common/css/locationPlan.css';
import '../src/common/css/auigrid.scss'; // custom auigrid.css

import 'react-day-picker/dist/style.css';
import 'flatpickr/dist/themes/light.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/scss/app.scss';

import { new_router } from 'routes/router_new';

// react v18
const container = document.getElementById('root');
const root = createRoot(container);

const useNewDesignFlag = JSON.parse(localStorage.getItem('useNewDesignFlag'));

root.render(
  <Provider store={store}>
    {useNewDesignFlag !== 1 ? ( //NOTE: useNewDesign값이 0면 이전 라우터를, 1이면 새로운 라우터를 적용
      <BrowserRouter>
        <MenuLayout />
      </BrowserRouter>
    ) : (
      <RouterProvider router={new_router} />
    )}
  </Provider>,
);
