import { useEffect, useRef, useState } from 'react';
import CommonModalNew from 'components/modal/commonModalNew';
import ReactToPrint from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { Col, Row } from 'reactstrap';
import { WarehouseDTO } from 'interface/order';
import { PurchaseOrderDTO, PurchaseOrderDetailDTO } from 'interface/warehouse';
import styled from 'styled-components';
import { RAWDATA, setLoading } from 'redux/services/menuSlice';
import ModalforBarcode from 'components/print/print';
import { serviceStore } from 'services/services';

const PrintWrapper = styled.div({
  position: 'relative',
  padding: 30,
  // height: '297mm',
  width: '100%',
  // width: '210mm',
  // border: '1px solid #d0d0d0',
  fontSize: 16,
  fontWeight: 700,
  marginBottom: 10,
});

export interface IPrintObj {
  visible?: Boolean;
  data?: any;
  type?: 'model' | 'confirm';
}

export const PurchaseOrderPrint = ({ printObj, setPrintObj }) => {
  const { MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>();
  const [data, setData] = useState<PurchaseOrderDTO>();
  const [center, setCenter] = useState<WarehouseDTO>();
  useEffect(() => {
    fetchPrintData();
  }, []);

  const fetchPrintData = async () => {
    dispatch(setLoading('GET'));
    const data = (await serviceStore.warehouseAction(`purchase/${printObj?.data?.purchaseSeq}`, 'GET', null))?.data;
    if (data) {
      setData(data);
      const center = MASTER_OPS?.[`${RAWDATA}CENTER_WHOLE`]?.filter((ele) => ele.centerCode === data.centerCode)?.[0];
      console.log(center);
      setCenter(center);

      const { JsBarcode } = window;
      JsBarcode('#barcode', printObj?.data?.purchaseSeq as string, {
        width: 2,
        height: 40,
        textPosition: 'top',
        fontSize: 15,
      });
    }
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      visible={printObj?.visible}
      style={{ width: 1000 }}
      setVisible={() => {
        setPrintObj(null);
      }}
      title={
        <>
          <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
        </>
      }
      children={
        <>
          <div ref={wrapperRef}>
            {printObj?.type === 'model' ? (
              <PrintWrapper className="break-for-onePage-print">
                <Row>
                  <Col />
                  <Col className="text-center">
                    <div className="printTitle" style={{ fontSize: 20 }}>
                      제품 발주 상세 명세서
                    </div>
                  </Col>
                  <Col>
                    <svg id="barcode"></svg>
                  </Col>
                </Row>
                {data && (
                  <>
                    <BasicInfoModel detailInfo={data} />
                    <OriginalCenterInfo detailInfo={data} center={center} />
                    <ProductInfoModel detailInfo={data} />
                  </>
                )}

                <p className="mb-5">* 2부 출력하여 입고 시 필히 지참하시길 바랍니다.</p>
                <div className="signs_area" style={{ marginBottom: 100 }}>
                  <div>
                    <span>물류기사명 :ㅤ</span>
                    <span className="sign_area"></span>
                    <span>(인)</span>
                  </div>
                  <div>
                    <span>창고장 :ㅤ</span>
                    <span className="sign_area"></span>

                    <span style={{ position: 'relative' }}>
                      (인)<span>{center?.sealImage && <img style={{ width: 50, height: 50, position: 'absolute', top: 0, left: 10 }} src={center?.sealImage} alt="창고장인" />}</span>
                    </span>
                  </div>
                </div>
              </PrintWrapper>
            ) : (
              <PrintWrapper className="break-for-onePage-print">
                <Row>
                  <Col />
                  <Col className="text-center">
                    <div className="printTitle" style={{ marginLeft: '-5rem' }}>
                      제품 입고 확정 명세서
                    </div>
                  </Col>
                  <Col>
                    <svg id="barcode"></svg>
                  </Col>
                </Row>
                {data && (
                  <>
                    <BasicInfo infos={data} center={center} />
                    <ProductInfo detail={data?.details} />
                  </>
                )}
                <div className="signs_area">
                  <div style={{ marginBottom: 20 }}>
                    <span>판매사 :ㅤ</span>
                    <span className="sign_area"></span>
                    <span>(인)</span>
                  </div>
                  <div>
                    <span>창고장 :ㅤ</span>
                    <span className="sign_area" style={{ width: 172 }}></span>
                    <span style={{ position: 'relative' }}>
                      <img
                        src={center?.sealImage}
                        style={{
                          width: 60,
                          position: 'absolute',
                          zIndex: 4,
                          right: -20,
                        }}
                      />
                      <span style={{ position: 'absolute', zIndex: 5, right: 0, top: 5 }}>(인)</span>
                    </span>
                  </div>
                </div>
              </PrintWrapper>
            )}
          </div>
        </>
      }
    />
  );
};

const BasicInfoModel = (props) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { detailInfo } = props;

  return (
    <div className="presenterInput">
      <p>1. 판매사 정보</p>
      <table className="border-table">
        <thead>
          <tr>
            <th>판매사명</th>
            <td colSpan={6}>{detailInfo.partnerSeqs?.map((ele) => MASTER_OBJ?.SELLER_WHOLE?.[ele]).join(',')}</td>
          </tr>
          <tr>
            <th>발주등록자</th>
            <td colSpan={6}>{detailInfo?.registerId}</td>
          </tr>
        </thead>
      </table>
    </div>
  );
};

const OriginalCenterInfo = (props) => {
  const { detailInfo, center } = props;
  return (
    <div className="presenterInput">
      <p>2. 입고창고 정보</p>
      <table className="border-table">
        <thead>
          <tr>
            <th>입고 예정일</th>
            <td colSpan={6}>{detailInfo?.promiseDate}</td>
          </tr>
          <tr>
            <th>입고창고</th>
            <td>{center?.centerName}</td>
            <td colSpan={5}>
              {center?.address}&nbsp;
              {center?.addressDetail}
            </td>
          </tr>
          <tr>
            <th>담당자</th>
            <td>{center?.managerName}</td>
            <td colSpan={5}>{center?.managerPhone}</td>
          </tr>
        </thead>
      </table>
    </div>
  );
};

const ProductInfoModel = ({ detailInfo }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [barcodeInfoObj, setBarcodeInfoObj] = useState({
    visible: false,
    data: null,
  });
  const [total, setTotal] = useState({
    PURCHASE: 0,
    CONFIRM: 0,
  });

  useEffect(() => {
    const PURCHASE = detailInfo?.details
      ?.map((ele: PurchaseOrderDetailDTO) => ele.purchaseQuantity)
      .reduce((acc: number, cur: number) => {
        return acc + cur;
      });
    const CONFIRM = detailInfo?.details
      ?.map((ele: PurchaseOrderDetailDTO) => ele.confirmQuantity)
      .reduce((acc: number, cur: number) => {
        return acc + cur;
      });
    setTotal({ PURCHASE, CONFIRM });
  }, []);

  return (
    <div className="presenterInput">
      {barcodeInfoObj?.visible && <ModalforBarcode barcodeInfoObj={barcodeInfoObj} setBarcodeInfoObj={setBarcodeInfoObj} />}
      <p>3. 상품 정보</p>
      <table className="border-table">
        <thead className="text-center">
          <tr>
            <th style={{ width: 50 }}>No.</th>
            <th colSpan={3}>제품</th>
            <th colSpan={3}>제품명</th>
            <th colSpan={2}>바코드</th>
            <th style={{ width: 100 }}>공급사</th>
            <th style={{ width: 100 }}>발주수량</th>
            <th>비고</th>
          </tr>
          {detailInfo?.details?.map((detail: PurchaseOrderDetailDTO, idx) => (
            <tr key={`details_${idx}`}>
              <td>{idx + 1}</td>
              <td colSpan={3}>{detail.model}</td>
              <td colSpan={3}>{detail.modelName}</td>
              <td
                colSpan={2}
                key={`${idx}-barcode`}
                id={`${idx}-barcode2323`}
                onClick={() => {
                  setBarcodeInfoObj((prev) => {
                    return {
                      ...prev,
                      visible: true,
                      data: detail.barcode,
                    };
                  });
                }}
              >
                {detail.barcode}
              </td>
              <td>{MASTER_OBJ?.SELLER_WHOLE?.[detail.supplierPartnerSeq]}</td>
              <td>{detail.purchaseQuantity}</td>
              <td>{'                '}</td>
            </tr>
          ))}
        </thead>
        <tfoot className="text-center">
          <tr>
            <td colSpan={9}></td>
            <td>합 계</td>
            <td>{total?.PURCHASE}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const BasicInfo = ({ infos, center }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  return (
    <div className="presenterInput">
      <span>1. 입고정보</span>
      <table className="border-table">
        <thead>
          <tr>
            <th style={{ width: 180 }}>발주번호</th>
            <td>{infos.purchaseSeq}</td>
          </tr>
          <tr>
            <th style={{ width: 180 }}>외부발주번호</th>
            <td>{infos?.externalPhNexternalPurchaseNumberumber || ''}</td>
          </tr>
          <tr>
            <th style={{ width: 180 }}>판매사</th>
            <td>{infos.partnerSeqs?.map((ele) => MASTER_OBJ?.SELLER_WHOLE?.[ele]).join(',')}</td>
          </tr>
          <tr>
            <th style={{ width: 180 }}>입고창고</th>
            <td>{MASTER_OBJ?.CENTER_WHOLE?.[infos?.centerCode]}</td>
          </tr>
          <tr>
            <th style={{ width: 180 }}>발주일</th>
            <td>{infos?.purchaseDate}</td>
          </tr>
          <tr>
            <th style={{ width: 180 }}>입고확정일</th>
            <td>{infos?.finishDatetime}</td>
          </tr>
          <tr>
            <th style={{ width: 180 }}>특이사항</th>
            <td>{infos?.memo}</td>
          </tr>
        </thead>
      </table>
      <br />
      <span>2. 입고담당자</span>
      <table className="border-table">
        <thead>
          <tr>
            <th style={{ width: 180 }}>입고담당자</th>
            <td>{center?.managerName}</td>
          </tr>
          <tr>
            <th style={{ width: 180 }}>연락처 / 이메일</th>
            <td>
              {center?.managerPhone} / {center?.managerEmail}
            </td>
          </tr>
          <tr>
            <th style={{ width: 180 }}>주소</th>
            <td>
              {center?.address}&nbsp;
              {center?.addressDetail}
            </td>
          </tr>
        </thead>
      </table>
    </div>
  );
};

const ProductInfo = (props) => {
  const [barcodeInfoObj, setBarcodeInfoObj] = useState({
    visible: false,
    data: null,
  });
  const { detail = [] } = props;

  let totalPurchaseQuantity = 0;
  let totalConfirmQuantity = 0;
  let totalDiff = 0;

  for (let index = 0; index < detail.length; index++) {
    totalPurchaseQuantity += detail[index].purchaseQuantity;
    totalConfirmQuantity += detail[index].confirmQuantity;
    totalDiff += detail[index].purchaseQuantity - detail[index].confirmQuantity;
  }

  return (
    <div className="presenterInput">
      {barcodeInfoObj?.visible && <ModalforBarcode barcodeInfoObj={barcodeInfoObj} setBarcodeInfoObj={setBarcodeInfoObj} />}
      <span>3. 상품 정보</span>
      <table className="border-table">
        <thead className="text-center">
          <tr>
            <th>No.</th>
            <th>SKU</th>
            <th>외부SKU</th>
            <th>바코드</th>
            <th colSpan={3}>제품명</th>
            <th>발주수량</th>
            <th>입고수량</th>
            <th>차이</th>
          </tr>
          {detail?.map((detail, idx) => (
            <tr key={`${idx}_detail`}>
              <td>{idx + 1}</td>
              <td>{detail.modelStockSeq}</td>
              <td>{detail.skuNumber}</td>
              <td
                key={`${idx}-barcode`}
                id={`${idx}-barcode2323`}
                onClick={() => {
                  setBarcodeInfoObj((prev) => {
                    return {
                      ...prev,
                      visible: true,
                      data: detail.barcode,
                    };
                  });
                }}
              >
                {detail.barcode}
              </td>
              <td colSpan={3}>{detail.modelName}</td>
              <td>{detail.purchaseQuantity}</td>
              <td>{detail.confirmQuantity}</td>
              <td>{detail.purchaseQuantity - detail.confirmQuantity}</td>
            </tr>
          ))}
        </thead>
        <tfoot className="text-center">
          <tr>
            <td colSpan={4}></td>
            <td colSpan={3}>합 계</td>
            <td>{totalPurchaseQuantity}</td>
            <td>{totalConfirmQuantity}</td>
            <td>{totalDiff}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
