import { throttle } from 'lodash';
import { useRef, useState } from 'react';
import { PortalPositionDataProps } from '../modules/Tooltip';

export function useDropdown() {
  const dropdownRef = useRef(null);
  const [dropdownSize, setDropdownSize] = useState<PortalPositionDataProps>({ x: 0, y: 0 });
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = throttle(() => {
    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);
  }, 500);

  return { dropdownRef, dropdownSize, setDropdownSize, windowHeight, windowWidth, setWindowWidth, setWindowHeight, handleResize };
}
