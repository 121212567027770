import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';
import { INVENTORY_RETURN_LIST, ORDER_USELESS_APPLIANCE, REMOVE_BEFORELOADING_PARCEL, REMOVE_LOADING_PARCEL } from 'envVar';
import { ScrapListDTO } from 'interface/order';
import { ResponseDTO } from 'interface/user';
import { LoadingStatementAddParcelDTO, LoadingStatementStockRemoveDTOForParcel, LoadingStatementStockRemoveDetailDTOForParcel, StockRemoveListDTO } from 'interface/warehouse';

class TakeoutService {
  public async getOrderUselessAppliance(): Promise<ScrapListDTO[]> {
    let url = ORDER_USELESS_APPLIANCE;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getTakeoutNParcel(): Promise<StockRemoveListDTO[]> {
    let url = INVENTORY_RETURN_LIST;
    const { data } = await httpClient.get(url);
    return data;
  }

  // 검수
  public async getRemoveParcel(loadingStatementSeq: string): Promise<LoadingStatementStockRemoveDTOForParcel> {
    let url = REMOVE_BEFORELOADING_PARCEL + `?loadingStatementSeq=${loadingStatementSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postRemoveParcel(dataDTO: LoadingStatementAddParcelDTO): Promise<ResponseDTO> {
    let url = REMOVE_LOADING_PARCEL;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async getAction(action: string, searchDTO = null): Promise<ResponseDTO> {
    let url = action;
    if (searchDTO) url += addingParamsToUrl(searchDTO);
    const rs = await httpClient.get(url);
    return rs;
  }

  public async postAction(action: string, dataDTO: any, searchDTO: any): Promise<ResponseDTO> {
    let url = action;
    if (searchDTO) url += addingParamsToUrlexceptPaging(searchDTO);
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async putAction(action: string, dataDTO: any, searchDTO: any): Promise<ResponseDTO> {
    let url = action;
    if (searchDTO) url += addingParamsToUrlexceptPaging(searchDTO);
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }
}

export const takeoutService = new TakeoutService();
