import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { detectMobileDevice } from 'common/util/detectDevice';
import { ParcelInvoiceAccidentSearchDTO } from '../../../../_interface/parcel';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { resetAllSelections } from 'common/util/searchBox';

export const SearchBox = (props: ISearchBoxProps<ParcelInvoiceAccidentSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const isMobile = detectMobileDevice(window.navigator.userAgent);
  const { searchObj, setSearchObj, fetchList, fetchCount } = props;
  const [closed, setClosed] = useState(false);
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE: null,
    SEARCH_SEQ: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SEQ, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ]);

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    fetchList(searchObj);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <label className="col-form-label">기간검색</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_DATE
                      ? {
                          value: selectTypeObj?.SEARCH_DATE,
                          label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_DATE}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_DATE: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <FlatpickrD
                  disabled={!selectTypeObj?.SEARCH_DATE}
                  onClose={(option) => {
                    if (option?.length === 1)
                      setSearchObj({
                        ...searchObj,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                        [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                      });
                  }}
                  value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                      [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">송장조회</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_SEQ
                      ? {
                          value: selectTypeObj?.SEARCH_SEQ,
                          label: MASTER_OBJ?.SEARCH_SEQ?.[selectTypeObj?.SEARCH_SEQ],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_SEQ}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_SEQ: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_SEQ}
                  value={searchObj?.[selectTypeObj?.SEARCH_SEQ]}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_SEQ]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">판매사(포워더)</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
              value={searchObj?.partnerSeq?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.SELLER_FORWARDER_AUTH?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: (option as OptionItem[])?.map((ele) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">양륙항</label>
            <SelectD
              isMulti
              value={searchObj?.portCode?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.PORT_CODE?.[ele],
                };
              })}
              options={MASTER_OPS?.PORT_CODE}
              onChange={(options) => {
                setSearchObj({
                  ...searchObj,
                  portCode: (options as OptionItem[]).map((ele) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">고객명</label>
            <InputD
              value={searchObj?.receiverName || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  receiverName: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">배달지</label>
            <SelectD
              isMulti
              value={searchObj?.centerCode?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.CENTER_AUTH?.[ele],
                };
              })}
              options={MASTER_OPS?.CENTER_AUTH}
              onChange={(options) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: (options as OptionItem[]).map((ele) => ele.value),
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">사고유형</label>
            <SelectD
              isMulti
              value={searchObj?.type?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.ACCIDENT_TYPE?.[ele],
                };
              })}
              options={MASTER_OPS?.ACCIDENT_TYPE}
              onChange={(options) => {
                setSearchObj({
                  ...searchObj,
                  type: (options as OptionItem[]).map((ele) => ele.value),
                });
              }}
            />
          </Col>
          <Col />
          <Col />
          <Col />
          <Col />
          <Col style={{ textAlign: 'right' }}>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                fetchList(searchObj);
              }}
            >
              조회
            </button>
            {isMobile && (
              <button
                className="btn  sssm"
                onClick={(e) => {
                  e.preventDefault();
                  setClosed(!closed);
                }}
              >
                {closed ? '⬇️' : '⬆️'}
              </button>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};
