import { memo, useEffect, useState } from 'react';
import Grid, { GetCheckedDataTriggerThisGridProps, GetCheckedDataTriggerThisGridPropsReturnedWithRowIndex, rowsProps } from '../../../components/grid/auiGrid';
import { Form, Col, Row, Button } from 'reactstrap';

import * as IGrid from 'aui-grid';
import { TransactionStatementDTO, TransactionStatementDetailDTO, TransactionStatementImportDTO } from 'interface/order';
import { v4 as uuidv4 } from 'uuid';
import { httpClient } from 'common/http-client/axiosConfig';
//redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { categoryChanger } from '../../FEE/3transactionStatement';

import { useParams } from 'react-router';
import CommonModalNew from 'components/modal/commonModalNew';
import { SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { Title } from 'pages/ORDER/1order/manageorderRegisterTab/manageorderRegister';

interface IProductProps {
  infoData: TransactionStatementDTO;
  setInfoData: React.Dispatch<React.SetStateAction<TransactionStatementDTO>>;
}

interface DetailObjProps {
  visible: boolean;
  data: [];
}
interface importObjProps {
  visible: boolean;
  indexes: number[];
  transactionStatementSeq: number;
  transactionStatementDetailSeq: number;
  importFn?: (data: importReceiptAPIProps) => void;
}

const defaultImportObj: importObjProps = {
  visible: false,
  indexes: [],
  transactionStatementDetailSeq: null,
  transactionStatementSeq: null,
};

interface importReceiptAPIProps {
  data: TransactionStatementImportDTO;
  indexes: number[];
}

export const ProductView = memo(({ infoData, setInfoData }: IProductProps) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [gridId, setGridId] = useState<string>();
  const params = useParams();
  const [detailObj, setDetailObj] = useState<DetailObjProps>();
  const [importObj, setImportObj] = useState<importObjProps>();

  useEffect(() => {
    setGridId(`productView_instatementRegister_${uuidv4()}`);
  }, []);

  useEffect(() => {
    if (infoData?.details) {
      setFooterLayoutWithTotal(infoData?.details);
      setRows(infoData?.details);
    }
  }, [infoData]);

  const [rows, setRows] = useState<TransactionStatementDetailDTO[]>(infoData?.details);

  const [totalFooter, setTotalFooter] = useState<any>();
  const footerLayout = [
    {
      dataField: 'quantity',
      positionField: 'quantity',
      operation: 'SUM',
      formatString: '#,##0',
      style: 'aui-grid-my-footer-sum-total',
    },
    {
      dataField: 'supplyValue',
      positionField: 'supplyValue',
      operation: 'SUM',
      formatString: '#,##0',
      style: 'aui-grid-my-footer-sum-total',
    },
    {
      dataField: 'valueAddedTax',
      positionField: 'valueAddedTax',
      operation: 'SUM',
      formatString: '#,##0',
      style: 'aui-grid-my-footer-sum-total',
    },
    {
      dataField: 'total',
      positionField: 'total',
      operation: 'SUM',
      formatString: '#,##0',
      style: 'aui-grid-my-footer-sum-total',
    },
  ];
  const onEditEnded = (e: IGrid.CellEditEndEvent) => {
    const rows = window.AUIGrid.getGridData(`#${gridId}`);
    if (e.dataField === 'inputCategory') {
      rows[e.rowIndex]['quantity'] = 0;
      rows[e.rowIndex]['supplyValue'] = 0;
      rows[e.rowIndex]['valueAddedTax'] = 0;
      rows[e.rowIndex]['total'] = 0;
    } else if (e.dataField === 'supplyValue' || e.dataField === 'valueAddedTax') {
      rows[e.rowIndex]['total'] = rows[e.rowIndex]['supplyValue'] + rows[e.rowIndex]['valueAddedTax'];
    }
    setFooterLayoutWithTotal(rows);
    setRows(rows);
    setInfoData((prev) => {
      return {
        ...prev,
        ...returnSums(rows),
        details: rows,
      };
    });
  };

  const returnSums = (rows: TransactionStatementDetailDTO[]) => {
    const quantity = rows
      .map((ele: TransactionStatementDetailDTO) => ele.quantity)
      .reduce((acc: number, cur: number) => {
        return acc + cur;
      });

    const supplyValue = rows
      .map((ele: TransactionStatementDetailDTO) => ele.supplyValue)
      .reduce((acc: number, cur: number) => {
        return acc + cur;
      });

    const valueAddedTax = rows
      .map((ele: TransactionStatementDetailDTO) => ele.valueAddedTax)
      .reduce((acc: number, cur: number) => {
        return acc + cur;
      });

    const total = rows
      .map((ele: TransactionStatementDetailDTO) => ele.total)
      .reduce((acc: number, cur: number) => {
        return acc + cur;
      });

    const data = {
      quantity,
      supplyValue,
      valueAddedTax,
      total,
    };
    return data;
  };

  const setFooterLayoutWithTotal = (rows: TransactionStatementDetailDTO[]) => {
    const data = returnSums(rows);
    footerLayout.forEach((ele) => {
      ele['labelText'] = data[ele['positionField']];
    });
    setTotalFooter(footerLayout);
  };

  const fetchDetailList = async (e: IGrid.ButtonClickEvent) => {
    const data = await httpClient
      .get(`/order/transaction/statement/${categoryChanger('/' + params?.from)}/detail/receipt?transactionStatementDetailSeq=${e.item.transactionStatementDetailSeq}`)
      .then((rs) => {
        if (rs?.status === 200) return rs.data;
        return null;
      });
    if (data) {
      setDetailObj({
        visible: true,
        data,
      });
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      editable: false,
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        visibleFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          if (item?.inputCategory === 'DIRECT') return false;
          if (item?.quantity > 0) return true;
          return false;
        },
        onClick: fetchDetailList,
      },
    },
    {
      headerText: '품목번호',
      dataField: 'transactionStatementDetailSeq',
      editable: false,
    },
    {
      headerText: '입력구분',
      dataField: 'inputCategory',
      renderer: {
        type: IGrid.RendererKind.DropDownListRenderer,
        list: MASTER_OPS?.INPUT_CATEGORY,
        keyField: 'value',
        valueField: 'label',
      },
      headerTooltip: {
        show: true,
        tooltipHtml: `<div style="width:350px;">
            <div> - 입력구분이 "불러오기" 인 경우 : 수량/공급가액/부가세/합계금액 변경이 불가</div>
            <div> - 입력구분이 "직접입력" 인 경우 : 상세보기 불가능</div>
            <div> - 영수증 불러오기: 입력구분이 "불러오기"이고, 품목번호가 있을 경우에만 "단건 씩"처리 가능합니다</div>
          </div>`,
      },
      styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
        if (item.transactionStatementDetailSeq) {
          return 'cantEdit';
        }
        return null;
      },
    },
    {
      headerText: '품목',
      dataField: 'item',
    },
    {
      headerText: '수량',
      dataField: 'quantity',
      style: 'sub-total-color',
      editable: false,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        autoThousandSeparator: true,
        onlyNumeric: true,
      },
      dataType: 'string',
      formatString: '#,##0',
    },
    {
      headerText: '공급가액',
      dataField: 'supplyValue',
      style: 'sub-total-color',
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        autoThousandSeparator: true,
        onlyNumeric: true,
        allowNegative: true,
      },
      styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
        if (value < 0) {
          if (item.inputCategory === 'IMPORT') return 'color-red cantEdit';
          return 'color-red';
        }
        if (item.inputCategory === 'IMPORT') return 'cantEdit';
        return null;
      },
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '부가세',
      dataField: 'valueAddedTax',
      style: 'sub-total-color',
      styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
        if (value < 0) {
          if (item.inputCategory === 'IMPORT') return 'color-red cantEdit';
          return 'color-red';
        }
        if (item.inputCategory === 'IMPORT') return 'cantEdit';
        return null;
      },
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        autoThousandSeparator: true,
        onlyNumeric: true,
        allowNegative: true,
      },
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '합계금액',
      dataField: 'total',
      style: 'sub-total-color',
      editable: false,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        autoThousandSeparator: true,
        onlyNumeric: true,
        allowNegative: true,
      },
      styleFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
        if (value < 0) {
          return 'color-red';
        }
        return null;
      },
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '비고',
      dataField: 'remark',
    },
  ];

  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState<GetCheckedDataTriggerThisGridProps>();

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'row-remove') {
        deleteRow(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'import-receipt') {
        importReceipt(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'initialize-receipt') {
        initializeReceipt(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const initializeReceipt = async (selected: GetCheckedDataTriggerThisGridPropsReturnedWithRowIndex[]) => {
    if (window.confirm('초기화를 진행하시겠습니까?')) {
      const indexes = selected.map((ele) => ele.rowIndex);
      const items = selected.map((ele) => ele.item);
      const body = {
        transactionStatementDetailSeqs: items?.map((ele) => ele.transactionStatementDetailSeq),
      };
      await httpClient.post(`/order/transaction/statement/${categoryChanger('/' + params?.from)}/initialize`, body).then((rs) => {
        if (rs?.status === 200) {
          alert('초기화 진행되었습니다!');
          const rows = window.AUIGrid.getGridData(`#${gridId}`);
          rows.forEach((row: TransactionStatementDetailDTO, index: number) => {
            if (indexes?.includes(index)) {
              row.quantity = 0;
              row.supplyValue = 0;
              row.valueAddedTax = 0;
              row.total = 0;
            }
          });
          setRows(rows);
          setInfoData((prev) => {
            return {
              ...prev,
              ...returnSums(rows),
              details: rows,
            };
          });
        }
      });
    }
  };

  const deleteRow = (items: { rowIndex: number; item: TransactionStatementDetailDTO }[]) => {
    const indexs = items.map((ele) => ele.rowIndex);
    const rows = window.AUIGrid.getGridData(`#${gridId}`);
    const filtered = rows.filter((ele, idx) => !indexs.includes(idx));
    setRows(filtered);
  };

  const importReceipt = async (selected: GetCheckedDataTriggerThisGridPropsReturnedWithRowIndex[]) => {
    if (selected?.length > 1) {
      alert('단건씩만 작업가능합니다');
    } else {
      const indexes = selected.map((ele) => ele.rowIndex);
      const items = selected.map((ele) => ele.item);
      setImportObj({
        visible: true,
        indexes,
        transactionStatementSeq: infoData?.transactionStatementSeq,
        transactionStatementDetailSeq: items[0].transactionStatementDetailSeq,
        importFn: (data) => importReceiptAPI(data),
      });
    }
  };

  const importReceiptAPI = async ({ data, indexes }: importReceiptAPIProps) => {
    return await httpClient.post(`/order/transaction/statement/${categoryChanger('/' + params?.from)}/import`, data).then((rs) => {
      if (rs?.status === 200) {
        alert('영수증을 불러왔습니다!');
        setImportObj(defaultImportObj);
        const rows = window.AUIGrid.getGridData(`#${gridId}`);
        rows.forEach((row: TransactionStatementDetailDTO, index: number) => {
          if (indexes?.includes(index)) {
            row.quantity = rs.data.data.quantity;
            row.supplyValue = rs.data.data.supplyValue;
            row.valueAddedTax = rs.data.data.valueAddedTax;
            row.total = rs.data.data.total;
          }
        });
        setRows(rows);
        setInfoData((prev) => {
          return {
            ...prev,
            ...returnSums(rows),
            details: rows,
          };
        });
      }
    });
  };

  return (
    <>
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      {importObj?.visible && <ImportPopup importObj={importObj} setImportObj={setImportObj} />}
      <Title>품목정보</Title>
      <div className="grid-button-area">
        <div className="transparent">즉시 적용되는 기능</div>
        <div
          className="orange"
          onClick={() => {
            setGetCheckedDataTriggerThisGrid({
              type: 'import-receipt',
              id: `#${gridId}`,
              withRowIndex: true,
            });
          }}
        >
          영수증 불러오기
        </div>
        <div
          className="red"
          onClick={() => {
            setGetCheckedDataTriggerThisGrid({
              type: 'initialize-receipt',
              id: `#${gridId}`,
              withRowIndex: true,
            });
          }}
        >
          초기화
        </div>
        <div className="transparent">거래명세서 저장시 적용</div>
        <div
          className="blue"
          onClick={() => {
            const rows = window.AUIGrid.getGridData(`#${gridId}`);
            rows.push({ inputCategory: 'DIRECT', item: null, quantity: 0, supplyValue: 0, valueAddedTax: 0, total: 0 });
            setRows(rows);
          }}
        >
          품목 생성
        </div>
        <div
          className="red"
          onClick={() => {
            setGetCheckedDataTriggerThisGrid({
              type: 'row-remove',
              id: `#${gridId}`,
              withRowIndex: true,
            });
          }}
        >
          행 삭제
        </div>
      </div>
      <div style={{ width: '100%' }}>
        {gridId && (
          <Grid
            id={gridId}
            rows={rows}
            columns={columns}
            style={{ height: 300 }}
            footerLayout={totalFooter}
            onEditEnded={onEditEnded}
            getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
            setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            settingOptions={{
              showFooter: true,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
            }}
          />
        )}
      </div>
    </>
  );
});

interface DetailPopupProps {
  detailObj: DetailObjProps;
  setDetailObj: React.Dispatch<React.SetStateAction<DetailObjProps>>;
}
export const DetailPopup = ({ detailObj, setDetailObj }: DetailPopupProps) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const [gridId, setGridId] = useState<string>();
  const [rows, setRows] = useState<rowsProps[]>();
  const columns = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      editable: false,
    },
    {
      headerText: '고객주문일',
      dataField: 'orderDate',
      editable: false,
    },
    {
      headerText: '등록일시',
      dataField: 'reigsterDate',
      editable: false,
    },
    {
      headerText: '희망일',
      dataField: 'promiseDeliveryDate',
      editable: false,
    },
    {
      headerText: '지정일',
      dataField: 'deliveryDate',
      editable: false,
    },
    {
      headerText: '완료일',
      dataField: 'finishDate',
      editable: false,
    },
    {
      headerText: '취소일',
      dataField: 'cancelDate',
      editable: false,
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
      editable: false,
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
      editable: false,
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
      editable: false,
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
      editable: false,
    },
    {
      headerText: '주문유형',
      dataField: 'orderTypeKr',
      editable: false,
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
      editable: false,
    },
    {
      headerText: '송장상태',
      dataField: 'invoiceStatusKr',
      editable: false,
    },
    {
      headerText: '취소사유',
      dataField: 'cancelReason',
      editable: false,
    },
    {
      headerText: '상품명',
      dataField: 'productName',
      editable: false,
    },
    {
      headerText: '상품구성수량',
      dataField: 'quantity',
      editable: false,
    },
    {
      headerText: '기본금액(A)',
      dataField: 'defaultFee',
      editable: false,
    },
    {
      headerText: '상품금액(B)',
      dataField: 'productFee',
      editable: false,
    },
    {
      headerText: '그룹금액(C)',
      dataField: 'groupFee',
      editable: false,
    },
    {
      headerText: '제품금액(D)',
      dataField: 'modelFee',
      editable: false,
    },
    {
      headerText: '지역금액(E)',
      dataField: 'regionFee',
      editable: false,
    },
    {
      headerText: '요일금액(F)',
      dataField: 'dateFee',
      editable: false,
    },
    {
      headerText: '양중금액(G)',
      dataField: 'optionFeeForLifting',
      editable: false,
    },
    {
      headerText: '내림금액(H)',
      dataField: 'optionFeeForScrap',
      editable: false,
    },
    {
      headerText: '수거금액(I)',
      dataField: 'optionFeeForCollect',
      editable: false,
    },
    {
      headerText: '조정금액(J)',
      dataField: 'adjustmentFee',
      editable: false,
    },
    {
      headerText: '재정산금액(K)',
      dataField: 'recalculateFee',
      editable: false,
    },
    {
      headerText: 'A~K 합계(M)',
      dataField: 'supplyValue',
      editable: false,
    },
    {
      headerText: '세액',
      dataField: 'valueAddedTax',
      editable: false,
    },
    {
      headerText: '합계(L+M)',
      dataField: 'total',
      editable: false,
    },
  ];

  useEffect(() => {
    setGridId(`productView_instatementRegister_detail_${uuidv4()}`);
    const rows: rowsProps[] = detailObj?.data?.map((row: rowsProps) => {
      return {
        ...row,
        partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
        orderTypeKr: MASTER_OBJ?.ORDER_TYPE?.[row?.orderType],
        feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row?.feeType],
        invoiceStatusKr: MASTER_OBJ?.INVOICE_STATUS?.[row?.invoiceStatus],
      };
    });
    setRows(rows);
  }, []);

  return (
    <CommonModalNew
      title="품목정보 상세보기"
      style={{ width: 1000 }}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      children={
        <>
          {gridId && (
            <Grid
              id={gridId}
              rows={rows}
              columns={columns}
              style={{ height: 300 }}
              settingOptions={{
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
            />
          )}
        </>
      }
    />
  );
};

interface ImportPopupProps {
  importObj: importObjProps;
  setImportObj: React.Dispatch<React.SetStateAction<importObjProps>>;
}

export const ImportPopup = ({ importObj, setImportObj }: ImportPopupProps) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [obj, setObj] = useState<TransactionStatementImportDTO>({
    transactionStatementSeq: importObj?.transactionStatementSeq,
    transactionStatementDetailSeq: importObj?.transactionStatementDetailSeq,
    feeTypes: [],
    invoiceStatuses: [],
    recalculateFlag: false,
  });
  return (
    <CommonModalNew
      style={{ width: 400 }}
      title="영수증 불러오기"
      subTitle="*즉시 적용되는 기능입니다"
      visible={importObj?.visible}
      setVisible={() => {
        setImportObj(defaultImportObj);
      }}
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">송장유형</label>
              <SelectD
                isMulti
                height100
                placeholder="선택하지 않을시 '전체'를 불러옵니다"
                options={MASTER_OPS?.FEE_TYPE}
                value={obj?.feeTypes.map((ele) => {
                  return {
                    label: MASTER_OBJ?.FEE_TYPE?.[ele],
                    value: ele,
                  };
                })}
                onChange={(options) => {
                  setObj((prev) => {
                    return {
                      ...prev,
                      feeTypes: (options as OptionItem[]).map((ele) => ele.value),
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">송장상태</label>
              <SelectD
                isMulti
                height100
                placeholder="선택하지 않을시 '전체'를 불러옵니다"
                options={MASTER_OPS?.INVOICE_STATUS}
                value={obj?.invoiceStatuses.map((ele) => {
                  return {
                    label: MASTER_OBJ?.INVOICE_STATUS?.[ele],
                    value: ele,
                  };
                })}
                onChange={(options) => {
                  setObj((prev) => {
                    return {
                      ...prev,
                      invoiceStatuses: (options as OptionItem[]).map((ele) => ele.value),
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row style={{ margin: '15px 0' }}>
            <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                checked={obj?.recalculateFlag}
                onChange={(e) => {
                  setObj((prev) => {
                    return {
                      ...prev,
                      recalculateFlag: e.target.checked,
                    };
                  });
                }}
              />
              <label className="col-form-label">재정산 영수증여부</label>
            </Col>
          </Row>
          <Row style={{ float: 'right' }}>
            <Button
              className="btn"
              onClick={() => {
                importObj?.importFn({ data: obj, indexes: importObj?.indexes });
              }}
            >
              불러오기
            </Button>
          </Row>
        </>
      }
    />
  );
};
