// react
import { useEffect, useRef, useState } from 'react';
// redux
import { useDispatch } from 'react-redux';
import CommonModalNew from 'components/modal/commonModalNew';
import { setLoading } from 'redux/services/menuSlice';
import ModalforBarcode, { IBarcodeInfoObj, PrintTitle } from 'components/print/print';
import { serviceStore } from 'services/services';
import ReactToPrint from 'react-to-print';
import { InventoryInvestigationDTO, InventoryInvestigationLocationDTOForPrint, InventoryInvestigationPrintDTO } from 'pages/INVENTORY/_interface/warehouse';

export interface IPrints {
  visible?: boolean;
  item?: InventoryInvestigationDTO;
}

export const InvestigatePrint = ({ printObj, setPrintObj }) => {
  const dispatch = useDispatch();
  const [printData, setPrintData] = useState<InventoryInvestigationPrintDTO & { totalAvailableCount: number; totalDefectedCount: number }>();
  const [barcodeInfoObj, setBarcodeInfoObj] = useState<IBarcodeInfoObj>();

  const wrapperRef = useRef();
  useEffect(() => {
    fetchPrintData(printObj?.item);
  }, []);

  const fetchPrintData = async (item) => {
    dispatch(setLoading('GET'));
    //
    const rs = await serviceStore.warehouseAction(`inventory/investigate/print`, 'GET', { inventoryInvestigationSeq: item?.inventoryInvestigationSeq });
    if (rs?.status === 200) {
      setPrintData({
        ...rs?.data,
        totalAvailableCount: (rs?.data?.locations as InventoryInvestigationLocationDTOForPrint[])
          ?.map((ele) => ele.availableQuantity)
          ?.reduce((acc: number, cur: number) => {
            return acc + cur;
          }),
        totalDefectedCount: (rs?.data?.locations as InventoryInvestigationLocationDTOForPrint[])
          ?.map((ele) => ele.defectedQuantity)
          ?.reduce((acc: number, cur: number) => {
            return acc + cur;
          }),
      });
    }
    dispatch(setLoading(null));
  };

  return (
    <>
      <CommonModalNew
        style={{ width: 1000 }}
        visible={printObj?.visible}
        setVisible={() => setPrintObj(null)}
        rightTitle={
          <>
            <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
          </>
        }
        children={
          <>
            <div
              ref={wrapperRef}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ModalforBarcode barcodeInfoObj={barcodeInfoObj} setBarcodeInfoObj={setBarcodeInfoObj} />
              <div style={{ padding: 30, width: '100%' }} className="break-for-onePage-print">
                <PrintTitle seq={printData?.inventoryInvestigationSeq} title={<div>재고조사 지시서</div>} />
                <div className="presenterInput">
                  <span>1. 기본정보</span>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <table className="border-table" style={{ width: '100mm' }}>
                      <thead>
                        <tr>
                          <th>생성일</th>
                          <td colSpan={5}>{printData?.registerDate}</td>
                        </tr>
                        <tr>
                          <th>조사창고</th>
                          <td colSpan={5}>{printData?.centerName}</td>
                        </tr>
                      </thead>
                    </table>
                    <table className="border-table" style={{ width: '100mm' }}>
                      <thead>
                        <tr>
                          <th>작업지시일</th>
                          <th>작업지시자(인)</th>
                          <th>창고장(인)</th>
                        </tr>
                        <tr style={{ height: 50 }}>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
                <div className="presenterInput">
                  <span>2. 로케이션정보</span>

                  <table className="border-table">
                    <thead className="text-center">
                      <tr>
                        <th colSpan={1}>No.</th>
                        <th colSpan={7}>로케이션</th>
                        <th colSpan={2}>양품</th>
                        <th colSpan={2}>불용</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {printData?.locations?.map((list, idx) => (
                        <tr key={`model_${idx}`}>
                          <td colSpan={1}>{idx + 1}</td>
                          <td colSpan={7}>{list.locationCode}</td>
                          <td colSpan={2}>{list.availableQuantity}</td>
                          <td colSpan={2}>{list.defectedQuantity}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot style={{ textAlign: 'center' }}>
                      <tr>
                        <td style={{ textAlign: 'right' }} colSpan={8}>
                          계
                        </td>
                        <td colSpan={2}>{printData?.totalAvailableCount}</td>
                        <td colSpan={2}>{printData?.totalDefectedCount}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
