import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import styled from 'styled-components';

import { stringCommaToInt, stringToThousandComma } from 'common/util/counting';

import { InputD, SelectD } from 'components/reactstrap/reactstrap';
// service
import { feeService } from 'pages/FEE/_service/service';
import { OrderReceiptDTO, OrderReceiptSearchDTO } from '../../../_interface/order';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IDetailObj {
  visible?: boolean;
  data?: OrderReceiptDTO;
  target?: 'in' | 'out' | 'storage' | 'default';
  searchObj?: OrderReceiptSearchDTO;
  fetchFn?: (data) => {};
}

const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
  margin: 40px 5px 5px 20px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const WrapperCol = styled.div`
  width: 50%;
  padding: 0 30px;
`;

const ReceiptRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ReceiptRowTitle = styled.div`
  display: flex;
  width: 100px;
`;
const NamedRow = styled.div`
  display: flex;
`;

const NamedCol = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 500;
  width: 150px;
  padding: 0 10px;
  justify-content: right;
`;

const ButtonTotalReceipt = styled.div`
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid red;
  width: 20px;
  color: red;
  justify-content: center;
  border-radius: 5px;
`;

export const DetailPopup = ({ detailObj, setDetailObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [obj, setObj] = useState<OrderReceiptDTO>();

  useEffect(() => {
    if (detailObj?.data) {
      const obj = {};
      Object.keys(detailObj?.data).forEach((key) => {
        if (key.toLowerCase().includes('fee') || key.toLowerCase().includes('value') || key.toLowerCase().includes('total')) {
          obj[key] = stringToThousandComma(detailObj?.data?.[key] + '');
        } else {
          obj[key] = detailObj?.data?.[key] + '';
        }
      });
      setObj(obj);
    }
  }, []);

  const sumAdjustmentFees = (orderReceiptAdjustments) => {
    const adjustmentFeeSum = orderReceiptAdjustments
      .map((ele) => stringCommaToInt(ele.adjustmentFee))
      .reduce((acc, cur) => {
        return acc + cur;
      });
    const adjustmentFeeTaxSum = orderReceiptAdjustments
      .map((ele) => stringCommaToInt(ele.adjustmentFeeTax))
      .reduce((acc, cur) => {
        return acc + cur;
      });

    return [adjustmentFeeSum, adjustmentFeeTaxSum];
  };

  const sumUpFee = (obj) => {
    const feeTaxes = [];
    const fees = [];
    Object.keys(obj).forEach((key) => {
      if (key?.includes('Tax') && !key?.includes('valueAddedTax')) feeTaxes.push(stringCommaToInt(obj[key]));
      else if (key?.includes('Fee')) fees.push(stringCommaToInt(obj[key]));
    });

    const feeTaxesSum = feeTaxes.reduce((acc, cur) => {
      return acc + cur;
    });
    const feesSum = fees.reduce((acc, cur) => {
      return acc + cur;
    });
    return [feeTaxesSum, feesSum];
  };

  const handleInput = (e) => {
    const isDetail = e.target.id.split('_')[2];
    if (typeof isDetail === 'string') {
      const orderReceiptAdjustments = JSON.parse(JSON.stringify(obj?.orderReceiptAdjustments));
      orderReceiptAdjustments[e.target.id.split('_')[2]][e.target.id.split('_')[1]] = stringToThousandComma(e.target.value);
      const [adjustmentFeeSum, adjustmentFeeTaxSum] = sumAdjustmentFees(orderReceiptAdjustments);

      const [feeTaxesSum, feeSum] = sumUpFee(obj);

      setObj((prev) => {
        return {
          ...prev,
          adjustmentFee: Number(adjustmentFeeSum),
          adjustmentFeeTax: Number(adjustmentFeeTaxSum),
          orderReceiptAdjustments,
          supplyValue: Number(feeSum),
          valueAddedTax: Number(feeTaxesSum),
          total: Number(feeSum + feeTaxesSum),
        };
      });
    } else {
      const dupObj = JSON.parse(JSON.stringify(obj));
      dupObj[e.target.id] = stringToThousandComma(e.target.value);

      const [feeTaxesSum, feeSum] = sumUpFee(dupObj);

      setObj({
        ...dupObj,
        supplyValue: stringToThousandComma(feeSum),
        valueAddedTax: stringToThousandComma(feeTaxesSum),
        total: stringToThousandComma(feeSum + feeTaxesSum),
      });
    }
  };

  const saveObj = async (data) => {
    const obj = {};
    Object.keys(detailObj?.data).forEach((key) => {
      if (key.toLowerCase().includes('fee') || key.toLowerCase().includes('value') || key.toLowerCase().includes('total')) {
        obj[key] = stringCommaToInt(detailObj?.data?.[key]);
      } else {
        obj[key] = detailObj?.data?.[key];
      }
    });
    const rs = await feeService.postSaveReceipDetail(obj);
    if (rs?.status === 200) {
      alert('저장되었습니다!');
      // fetch
    }
  };

  return (
    <CommonModalNew
      style={{ width: 1000 }}
      title={`상세보기`}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      children={
        <>
          <div style={{ textAlign: 'right' }}>
            <button
              className="btn-secondary btn"
              onClick={(e) => {
                saveObj(obj);
              }}
            >
              저장하기
            </button>
          </div>
          <div>
            <Title>주문정보</Title>
            <Wrapper>
              <WrapperCol>
                <ReceiptRow>
                  <ReceiptRowTitle>영수증번호</ReceiptRowTitle>
                  <div>{obj?.orderReceiptSeq}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>판매사</ReceiptRowTitle>
                  <div>{MASTER_OBJ?.SELLER_WHOLE?.[obj?.partnerSeq]}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>등록일</ReceiptRowTitle>
                  <div>{obj?.registerDate}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>희망일</ReceiptRowTitle>
                  <div>{obj?.promiseDeliveryDate}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>지정일</ReceiptRowTitle>
                  <div>{obj?.deliveryDate}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>완료일</ReceiptRowTitle>
                  <div>{obj?.finishDate}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>취소일</ReceiptRowTitle>
                  <div>{obj?.cancelDate}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>주문번호</ReceiptRowTitle>
                  <div>{obj?.orderSeq}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>외부주문번호</ReceiptRowTitle>
                  <div>{obj?.externalOrderNumber}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>송장번호</ReceiptRowTitle>
                  <div>{obj?.invoiceSeq}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>외부송장번호</ReceiptRowTitle>
                  <div>{obj?.externalInvoiceNumber}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>송장유형</ReceiptRowTitle>
                  <div>{MASTER_OBJ?.FEE_TYPE?.[obj?.feeType]}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>송장상태</ReceiptRowTitle>
                  <div>{MASTER_OBJ?.INVOICE_STATUS?.[obj?.invoiceStatus]}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>취소사유</ReceiptRowTitle>
                  <div>{obj?.cancelReason}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>상품명</ReceiptRowTitle>
                  <div>{obj?.productName}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>상품구성수량</ReceiptRowTitle>
                  <div>{obj?.quantity}</div>
                </ReceiptRow>
              </WrapperCol>
              <WrapperCol>
                <ReceiptRow>
                  <ReceiptRowTitle>실수령자</ReceiptRowTitle>
                  <div>{obj?.customerName}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>실수령자 우편번호</ReceiptRowTitle>
                  <div>{obj?.customerZipcode}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>실수령자 주소</ReceiptRowTitle>
                  <div>
                    {obj?.customerAddr1} {obj?.customerAddr2}
                  </div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>실수령자 연락처1</ReceiptRowTitle>
                  <div>{obj?.customerPhone}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>실수령자 연락처2</ReceiptRowTitle>
                  <div>{obj?.customerPhone2}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>주문자</ReceiptRowTitle>
                  <div>{obj?.receiverName}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>주문자 우편번호</ReceiptRowTitle>
                  <div>{obj?.receiverZipcode}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>주문자 주소</ReceiptRowTitle>
                  <div>
                    {obj?.receiverAddr1} {obj?.receiverAddr2}
                  </div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>주문자 연락처1</ReceiptRowTitle>
                  <div>{obj?.receiverPhone}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>주문자 연락처2</ReceiptRowTitle>
                  <div>{obj?.receiverPhone2}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>고객요청사항</ReceiptRowTitle>
                  <div>{obj?.shipmentNote}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>소속창고</ReceiptRowTitle>
                  <div>{MASTER_OBJ?.CENTER_WHOLE?.[obj?.centerCode]}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>주기사</ReceiptRowTitle>
                  <div>{MASTER_OBJ?.DRIVER_WHOLE?.[obj?.userId]}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <ReceiptRowTitle>작업특이사항</ReceiptRowTitle>
                  <div>{obj?.workNote}</div>
                </ReceiptRow>
              </WrapperCol>
            </Wrapper>
          </div>
          <div>
            <Title>결제금액</Title>
            <WrapperCol style={{ width: '100%' }}>
              <ReceiptRow>
                <ReceiptRowTitle></ReceiptRowTitle>
                <NamedRow>
                  <NamedCol>공급가액</NamedCol>
                  <NamedCol>부가세액</NamedCol>
                </NamedRow>
              </ReceiptRow>
              <ReceiptRow>
                <ReceiptRowTitle>기본금액(A)</ReceiptRowTitle>
                <NamedRow>
                  <NamedCol>
                    <InputD id="defaultFee" value={obj?.defaultFee || ''} onChange={handleInput} />
                  </NamedCol>
                  <NamedCol>
                    <InputD id="defaultFeeTax" value={obj?.defaultFeeTax || ''} onChange={handleInput} />
                  </NamedCol>
                </NamedRow>
              </ReceiptRow>
              <ReceiptRow>
                <ReceiptRowTitle>상품금액(B)</ReceiptRowTitle>
                <NamedRow>
                  <NamedCol>
                    <InputD id="productFee" value={obj?.productFee || ''} onChange={handleInput} />
                  </NamedCol>
                  <NamedCol>
                    <InputD id="productFeeTax" value={obj?.productFeeTax || ''} onChange={handleInput} />
                  </NamedCol>
                </NamedRow>
              </ReceiptRow>
              <ReceiptRow>
                <ReceiptRowTitle>그룹금액(C)</ReceiptRowTitle>
                <NamedRow>
                  <NamedCol>
                    <InputD id="groupFee" value={obj?.groupFee || ''} onChange={handleInput} />
                  </NamedCol>
                  <NamedCol>
                    <InputD id="groupFeeTax" value={obj?.groupFeeTax || ''} onChange={handleInput} />
                  </NamedCol>
                </NamedRow>
              </ReceiptRow>
              <ReceiptRow>
                <ReceiptRowTitle>제품금액(D)</ReceiptRowTitle>
                <NamedRow>
                  <NamedCol>
                    <InputD id="modelFee" value={obj?.modelFee || ''} onChange={handleInput} />
                  </NamedCol>
                  <NamedCol>
                    <InputD id="modelFeeTax" value={obj?.modelFeeTax || ''} onChange={handleInput} />
                  </NamedCol>
                </NamedRow>
              </ReceiptRow>
              <ReceiptRow>
                <ReceiptRowTitle>지역금액(E)</ReceiptRowTitle>
                <NamedRow>
                  <NamedCol>
                    <InputD id="modelFee" value={obj?.modelFee || ''} onChange={handleInput} />
                  </NamedCol>
                  <NamedCol>
                    <InputD id="modelFeeTax" value={obj?.modelFeeTax || ''} onChange={handleInput} />
                  </NamedCol>
                </NamedRow>
              </ReceiptRow>
              <ReceiptRow>
                <ReceiptRowTitle>요일금액(F)</ReceiptRowTitle>
                <NamedRow>
                  <NamedCol>
                    <InputD id="dateFee" value={obj?.dateFee || ''} onChange={handleInput} />
                  </NamedCol>
                  <NamedCol>
                    <InputD id="dateFeeTax" value={obj?.dateFeeTax || ''} onChange={handleInput} />
                  </NamedCol>
                </NamedRow>
              </ReceiptRow>
              <ReceiptRow>
                <ReceiptRowTitle>양중금액(G)</ReceiptRowTitle>
                <NamedRow>
                  <NamedCol>
                    <InputD id="optionFeeForLifting" value={obj?.optionFeeForLifting || ''} onChange={handleInput} />
                  </NamedCol>
                  <NamedCol>
                    <InputD id="optionFeeForLiftingTax" value={obj?.optionFeeForLiftingTax || ''} onChange={handleInput} />
                  </NamedCol>
                </NamedRow>
              </ReceiptRow>
              <ReceiptRow>
                <ReceiptRowTitle>내림금액(H)</ReceiptRowTitle>
                <NamedRow>
                  <NamedCol>
                    <InputD id="optionFeeForScrap" value={obj?.optionFeeForScrap || ''} onChange={handleInput} />
                  </NamedCol>
                  <NamedCol>
                    <InputD id="optionFeeForScrapTax" value={obj?.optionFeeForScrapTax || ''} onChange={handleInput} />
                  </NamedCol>
                </NamedRow>
              </ReceiptRow>
              <ReceiptRow>
                <ReceiptRowTitle>수거금액(I)</ReceiptRowTitle>
                <NamedRow>
                  <NamedCol>
                    <InputD id="optionFeeForCollect" value={obj?.optionFeeForCollect || ''} onChange={handleInput} />
                  </NamedCol>
                  <NamedCol>
                    <InputD id="optionFeeForCollectTax" value={obj?.optionFeeForCollectTax || ''} onChange={handleInput} />
                  </NamedCol>
                </NamedRow>
              </ReceiptRow>
              <ReceiptRow>
                <ReceiptRowTitle>
                  <span>총 조정금액(J)</span>
                  <ButtonTotalReceipt
                    style={{ color: 'blue', border: '1px solid blue' }}
                    onClick={() => {
                      const orderReceiptAdjustments = obj?.orderReceiptAdjustments ? JSON.parse(JSON.stringify(obj?.orderReceiptAdjustments)) : [];
                      orderReceiptAdjustments.push({});
                      setObj((prev) => {
                        return {
                          ...prev,
                          orderReceiptAdjustments,
                        };
                      });
                    }}
                  >
                    +
                  </ButtonTotalReceipt>
                </ReceiptRowTitle>
                <NamedRow>
                  <NamedCol style={{ fontWeight: 300, paddingRight: 20 }}>{obj?.adjustmentFee}</NamedCol>
                  <NamedCol style={{ fontWeight: 300, paddingRight: 20 }}>{obj?.adjustmentFeeTax}</NamedCol>
                </NamedRow>
              </ReceiptRow>
              {obj?.orderReceiptAdjustments?.map((ele, idx) => {
                return (
                  <ReceiptRow key={`adjustEle_${idx}`}>
                    <ReceiptRowTitle style={{ width: 200 }} className="shallow-select">
                      <ButtonTotalReceipt
                        onClick={() => {
                          const orderReceiptAdjustments = JSON.parse(JSON.stringify(obj?.orderReceiptAdjustments)).filter((ele, i) => i !== idx);
                          const [adjustmentFeeSum, adjustmentFeeTaxSum] = sumAdjustmentFees(orderReceiptAdjustments);
                          setObj((prev) => {
                            return {
                              ...prev,
                              adjustmentFee: adjustmentFeeSum,
                              adjustmentFeeTax: adjustmentFeeTaxSum,
                              orderReceiptAdjustments,
                            };
                          });
                        }}
                      >
                        -
                      </ButtonTotalReceipt>
                      <SelectD
                        options={MASTER_OPS?.ADJUSTMENT_TYPE}
                        value={
                          ele.type
                            ? {
                                label: MASTER_OBJ?.ADJUSTMENT_TYPE?.[ele.type],
                                value: ele.type,
                              }
                            : null
                        }
                        onChange={({ value }) => {
                          const orderReceiptAdjustments = JSON.parse(JSON.stringify(obj?.orderReceiptAdjustments));
                          orderReceiptAdjustments[idx]['type'] = value;
                          setObj((prev) => {
                            return {
                              ...prev,
                              orderReceiptAdjustments,
                            };
                          });
                        }}
                      />
                    </ReceiptRowTitle>
                    <NamedRow>
                      <NamedCol>
                        <InputD id={`orderReceiptAdjustments_adjustmentFee_${idx}`} value={ele?.adjustmentFee || ''} onChange={handleInput} />
                      </NamedCol>
                      <NamedCol>
                        <InputD id={`orderReceiptAdjustments_adjustmentFeeTax_${idx}`} value={ele?.adjustmentFeeTax || ''} onChange={handleInput} />
                      </NamedCol>
                    </NamedRow>
                  </ReceiptRow>
                );
              })}
              <ReceiptRow>
                <ReceiptRowTitle>재정산금액(K)</ReceiptRowTitle>
                <NamedRow>
                  <NamedCol>
                    <InputD id="recalculateFee" value={obj?.recalculateFee || ''} onChange={handleInput} />
                  </NamedCol>
                  <NamedCol>
                    <InputD id="recalculateFeeTax" value={obj?.recalculateFeeTax || ''} onChange={handleInput} />
                  </NamedCol>
                </NamedRow>
              </ReceiptRow>
              <ReceiptRow>
                <ReceiptRowTitle>합계(A~K)(L)</ReceiptRowTitle>
                <NamedRow>
                  <NamedCol>{obj?.supplyValue}</NamedCol>
                  <NamedCol>{obj?.valueAddedTax}</NamedCol>
                </NamedRow>
              </ReceiptRow>
              <hr />
              <ReceiptRow>
                <ReceiptRowTitle>총 합계(금액 + 세액)</ReceiptRowTitle>
                <NamedRow>
                  <NamedCol></NamedCol>
                  <NamedCol>{obj?.total}</NamedCol>
                </NamedRow>
              </ReceiptRow>
            </WrapperCol>
          </div>
        </>
      }
    />
  );
};
