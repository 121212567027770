import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { SerialNumberLiveSearchDTO } from 'interface/warehouse';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections, updateSelectTypeInSearchObj } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { numberTestRegExp } from 'common/util/regExp';
import { setSearchObj } from 'redux/services/tabSlice';

export const SearchBox = (props: ISearchBoxProps<SerialNumberLiveSearchDTO>) => {
  const { fetchList, handleSearchObj, TARGET } = props;
  const dispatch = useDispatch();
  const searchObj = useSelector((state: RootState) => state.tab)?.searchObj?.[TARGET];
  const [selectTypeObj, setSelectTypeObj] = useState({
    SERIAL_DATE: searchObj?.['SERIAL_DATE'] ?? null,
    SEARCH_MODEL: searchObj?.['SEARCH_MODEL'] ?? null,
  });

  useEffect(() => {
    localStorage.setItem(`searchObj`, JSON.stringify({ [TARGET]: searchObj }));
  }, [searchObj]);

  useEffect(() => {
    dispatch(setSearchObj({ tabId: TARGET, searchObj: updateSelectTypeInSearchObj(resetAllSelections(masterOptions?.SERIAL_DATE, searchObj), selectTypeObj) }));
  }, [selectTypeObj?.SERIAL_DATE]);

  useEffect(() => {
    dispatch(setSearchObj({ tabId: TARGET, searchObj: updateSelectTypeInSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj), selectTypeObj) }));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const { masterOptions, MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    fetchList(searchObj);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <label className="col-form-label">발주번호</label>
          <InputD
            type="number"
            value={searchObj?.purchaseSeq || ''}
            onChange={(e) => {
              handleSearchObj('purchaseSeq', Number(e.target.value));
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">송장번호</label>
          <InputD
            value={searchObj?.invoiceSeq || ''}
            onChange={(e) => {
              if (numberTestRegExp(e.target.value)) {
                handleSearchObj('invoiceSeq', Number(e.target.value));
              } else {
                alert('숫자만 입력 가능합니다!');
              }
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER_WHOLE),
                  }
                : null
            }
            options={masterOptions?.CENTER_AUTH}
            onChange={(option) => {
              handleSearchObj('centerCode', (option as OptionItem).value);
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품그룹</label>
          <SelectD
            value={
              searchObj?.modelGroup
                ? {
                    value: searchObj?.modelGroup,
                    label: selectlabel(searchObj?.modelGroup, masterOptions?.MODEL_GROUP),
                  }
                : null
            }
            options={masterOptions?.MODEL_GROUP}
            onChange={(option) => {
              handleSearchObj('modelGroup', (option as OptionItem).value);
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품유형</label>
          <SelectD
            value={
              searchObj?.modelType
                ? {
                    value: searchObj?.modelType,
                    label: selectlabel(searchObj?.modelType, masterOptions?.MODEL_TYPE),
                  }
                : null
            }
            options={masterOptions?.MODEL_TYPE}
            onChange={(option) => {
              handleSearchObj('modelType', (option as OptionItem).value);
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER_WHOLE),
                  }
                : null
            }
            options={masterOptions?.SELLER_SELLER_AUTH}
            onChange={(option) => {
              handleSearchObj('partnerSeq', (option as OptionItem).value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">재고상태</label>
          <SelectD
            isMulti
            options={masterOptions?.INVA_STATUS}
            value={searchObj?.availableStatus?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.INVA_STATUS?.[ele],
              };
            })}
            onChange={(option) => {
              handleSearchObj(
                'availableStatus',
                (option as OptionItem[])?.map((ele) => ele.value),
              );
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">재고유형</label>
          <SelectD
            isMulti
            options={masterOptions?.INVA_TYPE}
            value={searchObj?.availableType?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.INVA_TYPE?.[ele],
              };
            })}
            onChange={(option) => {
              handleSearchObj(
                'availableType',
                (option as OptionItem[])?.map((ele) => ele.value),
              );
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">이동상태</label>
          <SelectD
            isMulti
            options={masterOptions?.INVM_STATUS}
            value={searchObj?.moveStatus?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.INVM_STATUS?.[ele],
              };
            })}
            onChange={(option) => {
              handleSearchObj(
                'moveStatus',
                (option as OptionItem[])?.map((ele) => ele.value),
              );
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품/제품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                      }
                    : null
                }
                options={masterOptions?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  handleSearchObj(selectTypeObj?.SEARCH_MODEL, e.target.value);
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">기준일</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SERIAL_DATE
                    ? {
                        value: selectTypeObj?.SERIAL_DATE,
                        label: MASTER_OBJ?.SERIAL_DATE?.[selectTypeObj?.SERIAL_DATE],
                      }
                    : null
                }
                options={masterOptions?.SERIAL_DATE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SERIAL_DATE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <FlatpickrD
                disabled={!selectTypeObj?.SERIAL_DATE}
                value={[searchObj?.[`from${selectTypeObj?.SERIAL_DATE}`], searchObj?.[`to${selectTypeObj?.SERIAL_DATE}`]]}
                onChange={(option) => {
                  dispatch(
                    setSearchObj({
                      tabId: TARGET,
                      searchObj: {
                        ...searchObj,
                        [`from${selectTypeObj?.SERIAL_DATE}`]: returnDateyyyymmdd(option[0]),
                        [`to${selectTypeObj?.SERIAL_DATE}`]: returnDateyyyymmdd(option[1]),
                      },
                    }),
                  );
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">자산유형</label>
          <SelectD
            value={
              searchObj?.assetType
                ? {
                    value: searchObj?.assetType,
                    label: selectlabel(searchObj?.assetType, masterOptions?.ASSET_TYPE),
                  }
                : null
            }
            options={masterOptions?.ASSET_TYPE}
            onChange={(option) => {
              handleSearchObj('assetType', (option as OptionItem).value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">설치기사</label>
          <div style={{ display: 'flex' }}>
            <Col md={6} style={{ display: 'inline-block' }}>
              <SelectD
                options={masterOptions?.DRIVER_AUTH}
                value={
                  searchObj?.userId
                    ? {
                        value: searchObj?.userId,
                        label: selectlabel(searchObj?.userId, masterOptions?.DRIVER_AUTH),
                      }
                    : null
                }
                onChange={(option) => {
                  handleSearchObj('userId', (option as OptionItem)?.value);
                }}
              />
            </Col>
            <Col md={6} style={{ display: 'inline-block' }}>
              <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">수령인</label>
          <InputD
            value={searchObj?.customerName || ''}
            onChange={(e) => {
              handleSearchObj('customerName', e.target.value);
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">시리얼번호</label>
          <InputD
            value={searchObj?.serialNumber || ''}
            onChange={(e) => {
              handleSearchObj('serialNumber', e.target.value);
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">유사검색여부</label>
          <div className="radio-select-area">
            <span
              className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
              onClick={() => {
                handleSearchObj('similaritySearchFlag', true);
              }}
            >
              유사검색
            </span>
            <span
              className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
              onClick={() => {
                handleSearchObj('similaritySearchFlag', false);
              }}
            >
              일치검색
            </span>
          </div>
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
      </Row>
    </Form>
  );
};
