import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { OrderDTOForTracking } from 'interface/order';
import parsingDataString from 'query-string';
import { httpClient } from 'common/http-client/axiosConfig';

// img
import closeImg from 'assets/images/button/closeImg.png';
import LogimateLogo from './img/logimateLogi.png';

// styled-component
import styled from 'styled-components';
import { numberTestRegExp } from 'common/util/regExp';
import { SelectedInvoice as OrderInvoice } from '../orderTrackingTab/trackInvoice';
import { SelectedInvoice as ParcelInvoice } from '../orderTrackingParcelTab/trackInvoice';
import { generateUrlByDesignType } from 'components/LDS/utils/path';
import { LDSSpacing } from 'components/LDS/atoms/Spacing';

const Wrapper = styled.div`
  min-height: 80vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  justify-content: center;
  align-items: center;
`;

const WrapperInput = styled.div`
  position: relative;
  display: flex;
`;

const SearchInput = styled.input`
  border: 1px solid rgba(217, 222, 231, 1);
  height: 50px;
  width: 250px;
  color: rgba(163, 167, 185, 1);
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 13px;
`;

const SearchButton = styled.button`
  height: 50px;
  width: 60px;
  margin-left: 20px;
  background-color: black;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  border: none;
`;

const CloseImg = styled.img`
  position: absolute;
  top: 15px;
  right: 78px;
  cursor: pointer;
`;

const Index = () => {
  const history = useNavigate();
  const location = useLocation();
  const [invoiceSeq, setInvoiceSeq] = useState<string>();
  const [infoData, setInfoData] = useState<OrderDTOForTracking>();

  useEffect(() => {
    const seq = parsingDataString.parse(location.search)?.seq as string;
    if (seq) fetchInfoData(seq);
    else location.pathname.split('/')?.length > 2 && setInvoiceSeq('');
  }, [location]);

  const fetchInfoData = async (seq: string) => {
    if (seq) {
      if (location?.pathname?.includes('manageParcelTracking')) {
        const rs = await httpClient.get(`/parcel/invoice/user?parcelInvoiceNumber=${seq}`);
        if (rs?.status === 200) {
          setInfoData(rs?.data);
        }
      } else {
        const rs = await httpClient.get(`/order/v2/tracking/manager?invoiceSeq=${seq}`);
        if (rs?.status === 200) {
          setInfoData(rs?.data);
        }
      }
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (invoiceSeq) {
        location?.pathname?.includes('manageParcelTracking') ? history(`/manageParcelTracking?seq=${invoiceSeq}`) : history(`/ordertracking?seq=${invoiceSeq}`);
      } else alert('송장번호를 입력해주세요!');
    }
  };

  return (
    <>
      {!infoData ? (
        <div className="page-content" style={{ margin: 0, padding: 0, height: '100vh' }}>
          <Wrapper onKeyPress={onKeyPress}>
            <div style={{ height: 100 }}>
              <img src={LogimateLogo} style={{ width: 200 }} />
            </div>
            <WrapperInput>
              <SearchInput
                placeholder={location?.pathname?.includes('manageParcelTracking') ? '운송장번호를 입력해주세요.' : '송장번호를 입력해주세요.'}
                value={invoiceSeq || ''}
                onChange={(e) => {
                  if (numberTestRegExp(e.target.value)) {
                    setInvoiceSeq(e.target.value);
                  } else {
                    alert('숫자만 기입가능합니다!');
                  }
                }}
              />
              {invoiceSeq && (
                <CloseImg
                  src={closeImg}
                  onClick={() => {
                    setInvoiceSeq('');
                  }}
                />
              )}
              <SearchButton
                onClick={() => {
                  if (invoiceSeq) {
                    location?.pathname?.includes('manageParcelTracking')
                      ? history(generateUrlByDesignType(`/manageParcelTracking?seq=${invoiceSeq}`))
                      : history(generateUrlByDesignType(`/ordertracking?seq=${invoiceSeq}`));
                  } else alert(location?.pathname?.includes('parcel') ? '운송장번호를 입력해주세요!' : '송장번호를 입력해주세요!');
                }}
              >
                조회
              </SearchButton>
            </WrapperInput>
          </Wrapper>
        </div>
      ) : (
        <div className="page-content">
          {location?.pathname?.split('/')?.[1] === 'main' && <LDSSpacing $direction="V" $value={24} />}
          <Wrapper style={{ justifyContent: 'flex-start' }}>
            {location?.pathname?.includes('manageParcelTracking') ? <ParcelInvoice selected={infoData} /> : <OrderInvoice selected={infoData} />}
            <span
              style={{ width: '100%', maxWidth: 360, textAlign: 'center', padding: '13px 0', backgroundColor: '#FF9110', fontSize: 16, color: 'white', height: 50, cursor: 'pointer' }}
              onClick={() => {
                location?.pathname?.includes('manageParcelTracking') ? history(generateUrlByDesignType('/manageParcelTracking')) : history(generateUrlByDesignType('/ordertracking'));
                setInfoData(null);
              }}
            >
              뒤로가기
            </span>
          </Wrapper>
        </div>
      )}
    </>
  );
};

export default Index;
