import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './parcelList';

// options
import { RootState } from 'redux/store';
import { InvoiceDetailSearchDTO } from 'interface/order';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { getGridParameters } from 'common/master/codeMasterOptionReturn';
import { COMMON_GRIDID, nullingMASTER } from 'redux/services/menuSlice';
import { DRIVER_LIST_MASTER } from 'pages/ETC/1driver/driverListTab';
import { ORDER_MASTER } from '../orderListTab';
export interface IDetailObjProps {
  visible: boolean;
  item?: InvoiceDetailSearchDTO;
}

export const ORDERPARCEL_MASTER = [
  //
  ...DRIVER_LIST_MASTER,
  ...ORDER_MASTER,
  // 택배운송장
  {
    SEARCH_DATE: [
      {
        label: '주문등록일',
        value: 'RegisterDate',
      },
      {
        label: '배송완료일',
        value: 'DeliveryDate',
      },
    ],
  },
  // 택배운송장
  'PACKING_STATE',
  'PARCEL_PAY_TYPE',
  'INV_TRACK_TYPE',
  'PARCEL_INVOICE_STATUS',
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions([...ORDERPARCEL_MASTER, { [COMMON_GRIDID + 'PARCELINVOICE_TAB1']: await getGridParameters('PARCELINVOICE_TAB1') }]);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            //
            PARCELINVOICE_TAB1: <Tab1 tabId={'PARCELINVOICE_TAB1'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
