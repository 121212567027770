import styled from 'styled-components';

type DividerPropsType = {
  $direction: 'V' | 'H';
  $value: number | 'FULL';
  $color: string;
  $spacing?: 'SM' | 'MD' | 'LG' | 'ZERO';
  $margin?: number;
};
export default function LDSDivider({ ...props }: DividerPropsType) {
  return <DividerStyle className="LDSDivider" {...props} />;
}

const DividerStyle = styled.div<DividerPropsType>`
  width: ${(props) => (props.$direction === 'V' ? '1px' : props.$value !== 'FULL' ? `${props.$value}px` : '100%')};
  height: ${(props) => (props.$direction === 'H' ? '1px' : props.$value !== 'FULL' ? `${props.$value}px` : '100%')};
  background-color: ${(props) => props.$color};
  box-sizing: content-box;

  margin-top: ${(props) => props.$direction === 'H' && (props.$spacing === 'SM' ? '4px' : props.$spacing === 'MD' ? '8px' : props.$spacing === 'LG' ? '20px' : 0)};
  margin-bottom: ${(props) => props.$direction === 'H' && (props.$spacing === 'SM' ? '4px' : props.$spacing === 'MD' ? '8px' : props.$spacing === 'LG' ? '20px' : 0)};

  margin-left: ${(props) => (props.$direction === 'V' ? (props.$spacing === 'SM' ? '4px' : props.$spacing === 'MD' ? '8px' : 0) : props.$direction === 'H' ? `${props.$margin}px` : 0)};
  margin-right: ${(props) => (props.$direction === 'V' ? (props.$spacing === 'SM' ? '4px' : props.$spacing === 'MD' ? '8px' : 0) : props.$direction === 'H' ? `${props.$margin}px` : 0)};
`;
