import { useEffect, useRef, useState } from 'react';
import { UserGroupDTO } from 'interface/user';
import { userService } from 'pages/ETC/3user/_service/service';
import { useDispatch, useSelector } from 'react-redux';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';
import { OptionItem } from 'common/master/codeMasterReturnHelper';

export interface IAddGroup {
  visible?: boolean;
  item?: any;
  searchObj?: any;
  fetchFn?: <T>(T) => void;
}

export const AddGroup = ({ addGroupObj, setAddGroupObj }) => {
  // options
  const dispatch = useDispatch();
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [createObj, setCreateObj] = useState<UserGroupDTO>();

  useEffect(() => {
    if (addGroupObj?.item) {
      setCreateObj(addGroupObj?.item);
    }
  }, []);

  const validGroup = (createObj) => {
    if (!createObj?.userGroupId) {
      alert('그룹 아이디는 필수입니다!');
      return false;
    }
    if (!createObj?.userGroupName) {
      alert('그룹 이름은 필수입니다!');
      return false;
    }
    return true;
  };

  const saveGroup = async () => {
    if (validGroup(createObj)) {
      let rs;
      dispatch(setLoading('POST'));
      if (addGroupObj?.item) {
        rs = await userService.putUserGroup(createObj);
      } else {
        rs = await userService.postUserGroup(createObj);
      }
      dispatch(setLoading(null));
      if (rs?.status === 200) {
        alert(`${addGroupObj?.item ? '수정' : '생성'} 되었습니다!`);
        addGroupObj?.fetchFn(addGroupObj?.searchObj);
      }
    }
  };

  return (
    <CommonModalNew
      style={{ width: 350 }}
      title={!addGroupObj?.item ? '권한그룹 추가' : '권한그룹 수정'}
      visible={addGroupObj?.visible}
      setVisible={() => {
        setAddGroupObj(null);
      }}
      rightTitle={
        <button className="btn btn-orange ssm" onClick={saveGroup}>
          {!addGroupObj?.item ? '추가' : '수정'}
        </button>
      }
      children={
        <>
          <label>그룹 아이디*</label>
          <InputD
            value={createObj?.userGroupId || ''}
            disabled={addGroupObj?.item?.userGroupId}
            onChange={(e) => {
              setCreateObj({
                ...createObj,
                userGroupId: e.target.value,
              });
            }}
          />
          <label>인프라</label>
          <SelectD
            isMulti={true}
            placeholder={`선택하지 않으면 "전체"로 적용됩니다`}
            // value={createObj?.infraSeq}
            onChange={(options) => {
              setCreateObj({
                ...createObj,
                infraSeq: (options as OptionItem[])?.map((ele) => ele.value),
              });
            }}
            options={MASTER_OPS?.INFRA}
          />
          <label>그룹명*</label>
          <InputD
            value={createObj?.userGroupName || ''}
            onChange={(e) => {
              setCreateObj({
                ...createObj,
                userGroupName: e.target.value,
              });
            }}
          />
          <label>설명</label>
          <textarea
            style={{ width: '100%' }}
            value={createObj?.description || ''}
            onChange={(e) => {
              setCreateObj({
                ...createObj,
                description: e.target.value,
              });
            }}
          />
        </>
      }
    />
  );
};
