import CommonModalNew from 'components/modal/commonModalNew';
import { BarcodeRenderer } from 'components/template/PrintMode';
import { PurchaseOrderSerialNumberListDTO } from 'interface/warehouse';
import { useRef } from 'react';

import ReactToPrint from 'react-to-print';

// styled
import styled from 'styled-components';
const PrintBarcode8028 = styled.div({
  padding: '0 10px',
  paddingRight: 50,
  display: ' flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  height: '28mm',
  width: '120mm',
  border: '1px solid #d0d0d0',
});

export interface IPrintObj {
  visible: boolean;
  data: Array<PurchaseOrderSerialNumberListDTO>;
}

interface IBarcodePopup {
  printObj: IPrintObj;
  setPrintObj: React.Dispatch<React.SetStateAction<IPrintObj>>;
}

const BarcodePopup = ({ printObj, setPrintObj }: IBarcodePopup) => {
  const wrapperRef = useRef<HTMLDivElement>();

  return (
    <CommonModalNew
      title="바코드 출력"
      style={{ width: 500 }}
      visible={printObj?.visible}
      setVisible={() => {
        setPrintObj((prev) => {
          return {
            ...prev,
            data: null,
            visible: false,
          };
        });
      }}
      rightTitle={
        <>
          <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
        </>
      }
      children={
        <>
          <div ref={wrapperRef}>
            {printObj?.data?.map((ele, index) => {
              return (
                <PrintBarcode8028 key={`barcode_${index}`} id={`barcode_${index}`} className="break-for-onePage-print">
                  <table className="barcode-table">
                    <tbody>
                      <tr style={{ height: 21, float: 'inline-start' }}>
                        <td colSpan={6} style={{ fontSize: 20, fontWeight: 700 }}>
                          {ele.modelName}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} style={{ fontSize: 15, textAlign: 'left', fontWeight: 700 }}>
                          {ele.model}
                        </td>
                        <td colSpan={3} style={{ fontSize: 15, textAlign: 'right', fontWeight: 700 }}>
                          {ele.barcode}
                        </td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <td colSpan={6} style={{ position: 'relative', overflow: 'hidden', height: 60 }}>
                          <div style={{ bottom: -2, position: 'absolute', left: 0, width: '100%', textAlign: 'center' }}>
                            <svg
                              className="barcode"
                              jsbarcode-format="CODE128"
                              jsbarcode-fontoptions="bold"
                              jsbarcode-fill="transparent"
                              jsbarcode-value={ele.serialNumber}
                              jsbarcode-textmargin="0"
                              jsbarcode-width="2px"
                            />
                            <BarcodeRenderer name=".barcode" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </PrintBarcode8028>
              );
            })}
          </div>
        </>
      }
    />
  );
};

export default BarcodePopup;
