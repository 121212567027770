import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// component
import Tab1 from './warehouseLocation';

// options
import { RootState } from 'redux/store';
import { nullingMASTER } from 'redux/services/menuSlice';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { TopTabController } from 'components/template/TopTabController';

export const WAREHOUSELOCATION_MASTER = [
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  //
  'LOCATION_TYPE',
  'LOCATION_SETTING',
  'LOCATION_CATEGORY',
  'LOCATION_SIZE',
  //
  'USE_FLAG',
  'PICK_FLAG',
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    await initMasterOptions(WAREHOUSELOCATION_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            WAREHOUSELOCATION: <Tab1 tabId={'WAREHOUSELOCATION'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
