import { useEffect, useState } from 'react';
import { Button, Col } from 'reactstrap';
import { FileDTOForAdd } from 'interface/util';
import { handleFiles, uploadFile } from 'common/util/photo';
import { v4 as uuidv4 } from 'uuid';
import CloseImg from 'assets/images/close/cross.png';
import { ImageModal } from 'pages/ORDER/1order/orderListTab/common/component/photos/imageModal';
import { getToday } from 'common/util/dateParsingFn';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';

export const defaultPhotoPopupObj = {
  visible: false,
  data: null,
  photos: null,
};
export interface IPhotoObj {
  visible?: boolean;
  item?: any;
  searchObj?: string;
}

interface IPhotoPoupObj {
  photoPopupObj: IPhotoObj;
  setPhotoPopupObj: React.Dispatch<React.SetStateAction<IPhotoObj>>;
}

export const PhotoPopup = ({ photoPopupObj, setPhotoPopupObj }: IPhotoPoupObj) => {
  const [files, setFiles] = useState<FileDTOForAdd[]>();

  useEffect(() => {
    if (photoPopupObj?.item) {
      fetchPhotos(photoPopupObj?.item?.purchaseSeq);
    }
  }, []);

  const handleFileUpload = async (e) => {
    const returns = await handleFiles(e);
    const res = await Promise.all(
      returns?.map(async (ret) => {
        return await uploadFile(ret.file, ret.url);
      }),
    );
    const dataDTO = {
      purchaseSeq: photoPopupObj?.item?.purchaseSeq,
      addFiles: res.map((uploadUrl, idx) => {
        return {
          column: `PURCHASE${getToday()}_${uuidv4().split('-')[4]}_${idx}`,
          uploadUrl,
        };
      }),
    };
    const rs = await serviceStore?.warehouseAction(`purchase/image/save`, 'POST', null, dataDTO);
    if (rs?.status === 200) {
      alert('사진 업로드에 성공하였습니다!');
      fetchPhotos(photoPopupObj?.item?.purchaseSeq);
    }
  };

  const handleFileRemove = async (e) => {
    const idx = Number(e.target.id.split('_')[1]);
    const prompt = window.confirm('사진을 정말 삭제하시겠습니까?');
    if (prompt) {
      const dataDTO = {
        purchaseSeq: photoPopupObj?.item?.purchaseSeq,
        deleteFiles: [files[idx]],
      };

      const rs = await serviceStore?.warehouseAction(`purchase/image/save`, 'POST', null, dataDTO);
      if (rs?.status === 200) {
        alert('사진을 삭제하였습니다!');
        fetchPhotos(photoPopupObj?.item?.purchaseSeq);
        const _files = files?.filter((_, i) => i !== Number(idx));
        setFiles(_files);
      }
    }
  };

  const fetchPhotos = async (purchaseSeq: number) => {
    const data = (await serviceStore?.warehouseAction(`purchase/image/save/${purchaseSeq}`, 'GET', null))?.data;
    if (data) {
      setFiles(data?.files);
    }
  };

  return (
    <CommonModalNew
      visible={photoPopupObj.visible}
      setVisible={() => setPhotoPopupObj(null)}
      title={`발주번호 ${photoPopupObj.item.purchaseSeq}`}
      style={{ width: 600 }}
      children={
        <div className="grid-wrapper div2">
          {files?.map((url, i) => {
            return (
              <Col key={`purchasePhoto_${i}_${uuidv4()}`}>
                <div className="photo-zone">
                  <span className="bck-font"> 사진 {i + 1}</span>
                  {url && (
                    <div style={{ display: 'inline-block' }}>
                      <img src={CloseImg} id={`purchasePhoto_${i}`} alt="" onClick={handleFileRemove} className="closeBtn" />
                      <ImageModal photoArray={files} idx={i} title={`사진`} />
                    </div>
                  )}
                </div>
              </Col>
            );
          })}
          <div className="photo-zone">
            <span className="bck-font"></span>
            <input
              //
              style={{ display: 'none' }}
              type="file"
              multiple
              accept="image/*"
              id={`add_purchasePhoto`}
              onChange={handleFileUpload}
            />
            <Button size="md" color="primary" outline>
              <label htmlFor={`add_purchasePhoto`} style={{ marginBottom: 0 }}>
                ㅤㅤㅤ파일 추가ㅤㅤ
              </label>
            </Button>
          </div>
        </div>
      }
    />
  );
};
