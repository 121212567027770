import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import useGridButton from 'hooks/grid/useGridButton';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { qnaService } from '../../_service/service';
import { CounselListDTO, CounselListSearchDTO } from 'interface/order';
import { OrderDetail, IOrderDetailObj } from 'pages/ORDER/1order/orderListTab/common/component/invocieDetail/orderDetail';
import { CallCenterModal } from 'pages/ORDER/1order/orderListTab/common/component/invocieDetail/callcenterModal';
import { orderService } from 'pages/ORDER/1order/_services/service';
import { InvoiceDetailDTO } from 'pages/ORDER/1order/orderListTab/_interface/order';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [orderDetailObj, setOrderDetailObj] = useState<IOrderDetailObj>(); // js => tsx
  const [orderDetailInfo, setOrderDetailInfo] = useState<InvoiceDetailDTO>();

  const [visibleModal, setVisibleModal] = useState({
    type: null,
    visible: false,
  });

  const visibleOrderDetail = (e: IGrid.ButtonClickEvent) => {
    setOrderDetailObj({
      item: e.item,
      visible: true,
    });
  };

  const visibleCSDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const orderDetailInfo = await orderService.getInvoiceDetail(e.item.invoiceSeq);
    dispatch(setLoading(null));
    setOrderDetailInfo(orderDetailInfo);
    setVisibleModal({
      type: 'call-center',
      visible: true,
    });
  };

  const labellingKr = (data: PagingListDTO<CounselListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        duration: Math.ceil((row.duration / 12) * 0.5) + '일',
        openFlagKr: row.openFlag === false ? '미열람' : '열람',
        solveFlagKr: row.solveFlag === true ? '완료' : '미완료',
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        detailTypeKr: MASTER_OBJ?.COUNSEL_DETAIL_TYPE?.[row.detailType],
        counselTypeKr: MASTER_OBJ?.COUNSEL_TYPE?.[row.counselType],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: CounselListSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await qnaService.getPaging('counsel/board/paging', searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const changeStatus = async (e: IGrid.ButtonClickEvent) => {
    //
    if (e.text === '미완료') {
      if (window.confirm('완료하시겠습니까?')) {
        dispatch(setLoading('POST'));
        const rs = await qnaService.putAction(`counsel/solve/${e.item.counselSeq}`, null, null);
        if (rs?.status === 200) alert('완료처리 되었습니다!');

        wrappedFetchList(JSON.parse(e.item.searchObj));
        dispatch(setLoading(null));
      }
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: visibleOrderDetail,
      },
    },
    {
      headerText: 'CS상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: visibleCSDetail,
      },
    },
    {
      headerText: 'CS상태',
      dataField: 'solveFlagKr',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          if (value === '완료') return true;
          return false;
        },
        onClick: changeStatus,
      },
    },
    {
      headerText: '열람여부',
      dataField: 'openFlagKr',
    },
    {
      headerText: '접수유형',
      dataField: 'counselTypeKr',
    },
    {
      headerText: '세부유형',
      dataField: 'detailTypeKr',
    },
    {
      headerText: 'CS접수일',
      dataField: 'registerDatetime',
    },
    {
      headerText: 'CS처리완료일',
      dataField: 'solveDatetime',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '접수자',
      dataField: 'counselor',
    },
    {
      headerText: '실무자',
      dataField: 'charger',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '수령인',
      dataField: 'receiverName',
    },
    {
      headerText: '인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품명',
      dataField: 'productName',
    },
    {
      headerText: '추천인 고유번호',
      dataField: 'recommenderNumber',
    },
  ];

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: await qnaService.getAction('counsel/board/excel', searchObj) }, true);

    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '고객상담' });
    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'DRIVERREGISTER') {
    } else if (id === 'DRIVEREXCELDOWNLOAD') {
      downloadExcel('main');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {orderDetailObj?.visible && <OrderDetail orderDetailObj={orderDetailObj} setOrderDetailObj={setOrderDetailObj} />}
      {visibleModal?.type === 'call-center' && (
        <CallCenterModal
          visible={visibleModal?.visible}
          setVisible={() => {
            setVisibleModal((prev) => {
              return {
                ...prev,
                visible: !visibleModal?.visible,
              };
            });
          }}
          invoiceDetail={orderDetailInfo}
        />
      )}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            fixedColumnCount: 3,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns.filter((_, idx) => idx > 0)} />
      </div>
    </div>
  );
};

export default Index;
