import CommonModalNew from 'components/modal/commonModalNew';
import { FileDTO } from 'interface/util';
import { ParcelInvoiceDetailDTO } from '../../../_interface/parcel';
import { ImageModal } from 'pages/IPGO/1purchaseOrder/purchaseOrderTab/puchaseOrder/component/photos/imageModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col } from 'reactstrap';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';
import CloseImg from 'assets/images/close/cross.png';
import { setLoading } from 'redux/services/menuSlice';
import { handleFiles, uploadFile } from 'common/util/photo';
import { getTimeStamp, getToday } from 'common/util/dateParsingFn';

export interface IPhotoObj {
  visible?: boolean;
  data?: ParcelInvoiceDetailDTO;
}

export const Photos = ({ photoObj, setPhotoObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [files, setFiles] = useState<FileDTO[]>();
  useEffect(() => {
    if (photoObj?.visible) fetchPhotos(photoObj?.data?.parcelInvoiceSeq);
  }, [photoObj?.visible]);
  const fetchPhotos = async (parcelInvoiceSeq) => {
    const rs = await serviceStore?.parcelAction(`invoice/image`, 'GET', { parcelInvoiceSeq });
    if (rs?.status === 200) {
      setFiles(rs?.data);
    }
  };

  const handleFilesUpload = async (e) => {
    dispatch(setLoading('GET'));
    const returns = await handleFiles(e);
    const res = await Promise.all(
      returns?.map(async (ret) => {
        return await uploadFile(ret.file, ret.url);
      }),
    );
    const rs = await serviceStore.parcelAction('invoice/image/save', 'POST', null, {
      parcelInvoiceSeq: photoObj?.data?.parcelInvoiceSeq,
      addFiles: res.map((uploadUrl, idx) => {
        return {
          column: `MODEL${getToday()}_${getTimeStamp()}_${idx}`,
          uploadUrl,
        };
      }),
    });
    if (rs?.status === 200) {
      alert('사진 업로드에 성공하였습니다!');
      fetchPhotos(photoObj?.data?.parcelInvoiceSeq);
    }
    dispatch(setLoading(null));
  };

  const handleFileRemove = async (e) => {
    const [_, idx] = e.target.id.split('_');
    const prompt = window.confirm('사진을 정말 삭제하시겠습니까?');
    if (prompt) {
      const data = {
        parcelInvoiceSeq: photoObj?.data?.parcelInvoiceSeq,
        deleteFiles: [{ column: files[idx]?.tableColumn }],
      };
      const rs = await serviceStore.parcelAction('invoice/image/save', 'POST', null, data);
      if (rs?.status === 200) {
        alert('사진을 삭제하였습니다!');
        fetchPhotos(photoObj?.data?.parcelInvoiceSeq);
        const _files = files?.filter((_, i) => i !== Number(idx));
        setFiles(_files);
      }
    }
  };

  return (
    <CommonModalNew
      title="택배완료 사진보기"
      subTitle={`배송완료 장소 : ${MASTER_OBJ?.FINISH_LOCATION?.[photoObj?.data?.finishLocation || '기타']}`}
      style={{ width: 600 }}
      visible={photoObj?.visible}
      setVisible={() => {
        setPhotoObj(null);
      }}
      children={
        <div className="grid-wrapper div6">
          {files?.map((url, i) => {
            return (
              <Col key={`photo_${i}`} style={{ position: 'relative' }}>
                <ImageModal photoArray={files} idx={i} title={`사진`} deleteFn={handleFileRemove} mainSeq={photoObj?.data?.parcelInvoiceNumber} />
                <label htmlFor={`photo_${i}`} className={'photo-zone'}>
                  <span className="bck-font"> 사진 {i + 1}</span>
                  {url && (
                    <div style={{ display: 'inline-block' }}>
                      <img src={CloseImg} id={`photo_${i}`} alt="" onClick={handleFileRemove} className="closeBtn" />
                    </div>
                  )}
                </label>
              </Col>
            );
          })}
          <div className="photo-zone">
            <span className="bck-font"></span>
            <input
              //
              style={{ display: 'none' }}
              type="file"
              multiple
              accept="image/*"
              id={`add_parcelPhoto`}
              onChange={handleFilesUpload}
            />
            <Button size="md" color="primary" outline>
              <label htmlFor={`add_parcelPhoto`} style={{ marginBottom: 0 }}>
                파일 업로드
              </label>
            </Button>
          </div>
        </div>
      }
    />
  );
};
