import { ReactNode, useMemo, useRef, useState } from 'react';
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';

import AUIGrid from 'modules/Grid';

import * as IGrid from 'aui-grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import { PagingListDTO } from 'interface/util';
import { InventoryLocationSnapshotDTO, InventoryLocationSnapshotSearchDTO } from 'interface/warehouse';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { getDateFromToday } from 'common/util/dateParsingFn';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { serviceStore } from 'services/services';
import { makeObjtoFooter } from 'common/util/grid';
import { InventoryReportsCommonDefaultSearchFilters } from '..';
import useSearchGridPagingNew from 'hooks/grid/useGridPagingNew';

const Index = ({ tabId }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const searchObj = useSelector((state: RootState) => state.tab)?.searchObj?.[tabId];
  const defaultSearchFilter = useMemo(() => {
    return {
      ...InventoryReportsCommonDefaultSearchFilters,
      registerDate: getDateFromToday(-1),
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    };
  }, [reduxUserInfo]);

  const fetchTotal = async (searchObj: InventoryLocationSnapshotSearchDTO) => {
    const total = makeObjtoFooter((await serviceStore.warehouseAction('inventory/snapshot/location/quantity', 'GET', searchObj))?.data);
    return total;
  };

  const labellingKr = (data: PagingListDTO<InventoryLocationSnapshotDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        registerDate: searchObj?.registerDate,
        centerCodeKr: row.centerName,
        locationTypeKr: selectlabel(row.locationType, masterOptions?.LOCATION_TYPE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
        modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE),
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryLocationSnapshotSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.warehouseAction(`inventory/snapshot/location/paging`, 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { initFetch, handleSearchObj } = useSearchGridPagingNew({
    tabId,
    gridRef,
    fetchTotal,
    fetchPaging,
    initialSearchObj: {
      ...defaultSearchFilter,
      ...searchObj,
    },
  });

  const columns: IGrid.Column[] = [
    {
      headerText: '마감일자',
      dataField: 'registerDate',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션코드',
      dataField: 'locationCode',
    },
    {
      headerText: '로케이션유형',
      dataField: 'locationTypeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품타입',
          dataField: 'modelTypeKr',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
          width: 280,
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          width: 280,
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },

        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '대표상품코드',
          dataField: 'productCode',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
      // end of 1분기 children
    },
    {
      headerText: '합계',
      dataField: 'totalQuantity',
      dataType: 'numeric',
      style: 'main-total-color',
    },
    {
      headerText: '총 보관 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          dataType: 'numeric',
          style: 'sub-total-color',
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          dataType: 'numeric',
          style: 'sub-total-color',
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
          dataType: 'numeric',
          style: 'sub-total-color',
        },
      ],
    },
  ];

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const data = (await serviceStore.warehouseAction('inventory/snapshot/location/excel', 'GET', searchObj))?.data;
    const { list } = labellingKr({ list: data }, true);
    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '일자별_로케이션_마감현황' });
    }

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'INVENTORY_DAILY_LOCA_EXCELDOWN') {
      downloadExcel('main');
    }
  };
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      <div className="presenterSearch">
        <SearchBox handleSearchObj={handleSearchObj} fetchList={initFetch} TARGET={tabId} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area only-right">{functionBtns}</div>}
        <GridBox
          //
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            //
            showFooter: true,
            footerPosition: 'top',
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            fixedColumnCount: 1,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
