import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as IGrid from 'aui-grid';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { Col, Row, Form } from 'reactstrap';
import { InventoryMoveInfoSearchDTO, InventoryMoveInfoDTO } from 'interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';
import { resetAllSelections } from 'common/util/searchBox';
import { defaultGridProps } from 'common/grid/gridBox';
import { locationService } from 'pages/INVENTORY/3location/_services/service';

import AUIGrid, { mappingDefaultOptionToColumn } from 'modules/Grid';
import { useIsMounted } from 'hooks/basichooks';
import { delay } from 'common/util/counting';

export interface IModalObj {
  visible?: boolean;
  centerCode?: string;
  returns?: any[];
}

export const ModalPopup = ({ modalObj, setModalObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);
  const [searchObj, setSearchObj] = useState<InventoryMoveInfoSearchDTO>({ ...defaultSearchFilter, centerCode: modalObj?.centerCode });
  const gridRef = useRef<AUIGrid>();

  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SEQ, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ]);
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const isMounted = useIsMounted();
  useEffect(() => {
    void delay(100).then(() => {
      if (isMounted) {
        gridRef.current.bind('rowAllCheckClick', filterRowAllCheckClick);
        gridRef.current.bind('rowCheckClick', isAllCheckCounter);
      }
    });
  }, [isMounted]);

  const filterRowAllCheckClick = (checked: boolean) => {
    if (checked) {
      gridRef.current.addCheckedRowsByValue('checkable', checked);
    } else {
      gridRef.current.addUncheckedRowsByValue('checkable', true);
    }
  };

  const isAllCheckCounter = (event) => {
    const checkableLength = gridRef.current?.getGridData()?.filter((ele) => ele.checkable)?.length;
    const checkedLength = gridRef.current?.getCheckedRowItemsAll()?.length;
    if (event.checked) {
      if (checkableLength === checkedLength) {
        gridRef.current?.setAllCheckedRows(true);
      }
    } else {
      if (checkableLength !== checkedLength) {
        gridRef.current?.setAllCheckedRows(false);
      }
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      editable: false,
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
      editable: false,
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
      editable: false,
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
      editable: false,
    },
    {
      headerText: '제품',
      dataField: 'model',
      minWidth: 200,
      editable: false,
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
      minWidth: 200,
      editable: false,
    },

    {
      headerText: '바코드',
      dataField: 'barcode',
      editable: false,
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
      editable: false,
    },
    {
      headerText: '로케이션<br/>총 수량',
      dataField: 'totalQuantity',
      editable: false,
    },
    {
      headerText: '이동불가능<br/>수량',
      dataField: 'unavailableQuantity',
      editable: false,
      headerTooltip: {
        show: true,
        tooltipHtml: '<div style="width:150px;"><div>이동불가능수량은</div><div>피킹지시확정 상태이거나</div><div>로케이션 미지정된 재고입니다.</div></div>',
      },
    },
    {
      headerText: '이동가능수량',
      children: [
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          editable: false,
        },
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          editable: false,
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
          editable: false,
        },
      ],
    },
    {
      headerText: '추가 행 수량',
      dataField: 'inputQuantity',
      style: 'aui-textinputer',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        validator: (oldValue: any, newValue: any, item: any, dataField: string, fromClipboard: boolean, which: any) => {
          if (item?.availableQuantity + item?.defectedQuantity + item?.etcQuantity === 0) return { validate: false, message: '이동가능한 수량이 없습니다' };
          if (item?.availableQuantity + item?.defectedQuantity + item?.etcQuantity < newValue) return { validate: false, message: '이동가능한 수량 초과' };
        },
        autoThousandSeparator: true,
        onlyNumeric: true,
        allowNegative: false,
      },
    },
  ];

  const validate = (items) => {
    const quantities = items?.filter((ele) => !ele.inputQuantity);
    if (quantities?.length > 0) {
      alert('수량이 입력되지 않은 제품이 있습니다!');
      return false;
    }
    return true;
  };

  const returnThese = () => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (validate(items)) {
        setModalObj((prev) => {
          return {
            ...prev,
            returns: items,
          };
        });
      }
    } else {
      alert('선택한 제품이 없습니다');
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchPaging(searchObj);
    }
  };

  const fetchPaging = async (searchObj: InventoryMoveInfoSearchDTO) => {
    dispatch(setLoading('GET'));
    const data = await locationService.getInventoryMoveAction('inventory/move/info', searchObj, false);
    const dataKr = (data as InventoryMoveInfoDTO[])?.map((row) => {
      return {
        ...row,
        partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        inputQuantity: row?.availableQuantity + row?.defectedQuantity + row?.etcQuantity === 0 ? 0 : 1,
        forMerges: `${row.modelStockSeq}_${row.locationCode}`,
        checkable: row?.availableQuantity + row?.defectedQuantity + row?.etcQuantity === 0 ? false : true,
      };
    });
    gridRef.current.setGridData(dataKr);
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title="항목 추가"
      style={{ width: 1300 }}
      visible={modalObj?.visible}
      setVisible={() => {
        setModalObj(null);
      }}
      children={
        <>
          <Form onKeyPress={onKeyPress}>
            <Row>
              <Col>
                <label>주문/송장번호</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      value={
                        selectTypeObj?.SEARCH_SEQ
                          ? {
                              value: selectTypeObj?.SEARCH_SEQ,
                              label: MASTER_OBJ?.SEARCH_SEQ?.[selectTypeObj?.SEARCH_SEQ],
                            }
                          : null
                      }
                      options={MASTER_OPS?.SEARCH_SEQ}
                      onChange={(option) => {
                        setSelectTypeObj({
                          ...selectTypeObj,
                          SEARCH_SEQ: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!selectTypeObj?.SEARCH_SEQ}
                      value={searchObj?.[selectTypeObj?.SEARCH_SEQ] || ''}
                      onChange={(e) => {
                        setSearchObj({
                          ...searchObj,
                          [selectTypeObj?.SEARCH_SEQ]: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">판매사</label>
                <SelectD
                  options={MASTER_OPS?.SELLER_SELLER_AUTH}
                  value={
                    searchObj?.partnerSeq
                      ? {
                          value: searchObj?.partnerSeq,
                          label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[searchObj?.partnerSeq],
                        }
                      : null
                  }
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      partnerSeq: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label>제품그룹</label>
                <SelectD
                  options={MASTER_OPS?.MODEL_GROUP}
                  value={
                    searchObj?.modelGroup
                      ? {
                          value: searchObj?.modelGroup,
                          label: MASTER_OBJ?.MODEL_GROUP?.[searchObj?.modelGroup],
                        }
                      : null
                  }
                  onChange={(option) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        modelGroup: (option as OptionItem).value,
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label>제품</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      value={
                        selectTypeObj?.SEARCH_MODEL
                          ? {
                              value: selectTypeObj?.SEARCH_MODEL,
                              label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                            }
                          : null
                      }
                      options={MASTER_OPS?.SEARCH_MODEL}
                      onChange={(option) => {
                        setSelectTypeObj({
                          ...selectTypeObj,
                          SEARCH_MODEL: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!selectTypeObj?.SEARCH_MODEL}
                      value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                      onChange={(e) => {
                        setSearchObj({
                          ...searchObj,
                          [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">로케이션</label>
                <InputD
                  value={searchObj?.locationCode || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      locationCode: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="col-form-label">고객명</label>
                <InputD
                  value={searchObj?.customerName || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      customerName: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">유사검색여부</label>
                <div className="radio-select-area">
                  <span
                    className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                    onClick={() => {
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          similaritySearchFlag: true,
                        };
                      });
                    }}
                  >
                    유사검색
                  </span>
                  <span
                    className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                    onClick={() => {
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          similaritySearchFlag: false,
                        };
                      });
                    }}
                  >
                    일치검색
                  </span>
                </div>
              </Col>
              <Col>
                <div style={{ paddingTop: 10, textAlign: 'left' }}>
                  <button
                    className="btn btn-search"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchPaging(searchObj);
                    }}
                  >
                    조회
                  </button>
                </div>
              </Col>
              <Col />
              <Col />
            </Row>
          </Form>
          <div className="presenterGridBox">
            <div className="grid-button-area only-right">
              <div
                className="blue"
                onClick={(e) => {
                  //
                  e.preventDefault();
                  returnThese();
                }}
              >
                +추가
              </div>
            </div>
            <AUIGrid
              //
              ref={gridRef}
              name={'searchModal'}
              columnLayout={mappingDefaultOptionToColumn(columns)}
              gridProps={{
                ...defaultGridProps,
                //
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
                editable: true,
                independentAllCheckBox: true,
                rowCheckableFunction: (rI, iC, item) => {
                  if (item?.availableQuantity + item?.defectedQuantity + item?.etcQuantity === 0) return false;
                  return true;
                },
                rowCheckDisabledFunction: (rI, iC, item) => {
                  if (item?.availableQuantity + item?.defectedQuantity + item?.etcQuantity === 0) return false;
                  return true;
                },
              }}
            />
          </div>
        </>
      }
    />
  );
};
