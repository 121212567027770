import { OptionItem } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { ParcelInvoiceCollectChangeDTO } from '../../../../_interface/parcel';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';

export interface IChangeObj {
  visible?: boolean;
  checked?: number[];
  fetchFn?: (mode: string) => {};
}

export const ChangeModal = ({ changeObj, setChangeObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [inputs, setInputs] = useState<ParcelInvoiceCollectChangeDTO>();

  const changeInfo = async () => {
    //
    if (window.confirm('변경하시겠습니까?')) {
      const dataDTO = {
        parcelInvoiceCollectSeqs: changeObj?.checked,
        boxSize: inputs?.boxSize,
        parcelType: inputs?.parcelType,
      };

      const rs = await serviceStore.parcelAction(`invoice/collect/change`, 'POST', null, dataDTO);
      if (rs?.status === 200) {
        alert('변경되었습니다');
        setChangeObj(null);
      }
    }
  };

  return (
    <CommonModalNew
      title={'정보 수정'}
      style={{ width: 280 }}
      visible={changeObj?.visible}
      setVisible={() => {
        setChangeObj(null);
      }}
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">택배사</label>
              <SelectD
                menuPlacement="top"
                hasNull={false}
                placeholder="택배사를 선택하세요"
                value={
                  inputs?.parcelType
                    ? {
                        value: inputs?.parcelType,
                        label: MASTER_OBJ?.PARCEL_TYPE?.[inputs?.parcelType],
                      }
                    : null
                }
                options={MASTER_OPS?.PARCEL_TYPE}
                onChange={(options) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      parcelType: (options as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">크기</label>
              <SelectD
                menuPlacement="top"
                hasNull={false}
                placeholder="크기를 선택하세요"
                value={
                  inputs?.boxSize
                    ? {
                        value: inputs?.boxSize,
                        label: MASTER_OBJ?.CARGO_BOX_SIZE?.[inputs?.boxSize],
                      }
                    : null
                }
                options={MASTER_OPS?.CARGO_BOX_SIZE_SELECT_OPTION}
                onChange={(options) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      boxSize: (options as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10, textAlign: 'right' }}>
            <Col>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  changeInfo();
                }}
                className="btn btn-secondary sm"
              >
                변경
              </button>
            </Col>
          </Row>
        </>
      }
    />
  );
};
