import { KeyboardEvent, useEffect, useRef, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { ParcelInvoiceFeeSearchDTO } from '../../../../_interface/parcel';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = (props: ISearchBoxProps<ParcelInvoiceFeeSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList, TARGET } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!searchObj?.fromCollectDate || !searchObj?.toCollectDate) {
        alert('집하일자는 필수값입니다');
      } else {
        fetchList(searchObj);
      }
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        {TARGET === 'PARCEL_FEE_DAILY' && (
          <Row>
            <Col>
              <label className="col-form-label">*집하일자</label>
              <FlatpickrD
                value={[searchObj?.fromCollectDate, searchObj?.toCollectDate]}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    fromCollectDate: returnDateyyyymmdd(option[0]),
                    toCollectDate: returnDateyyyymmdd(option[1]),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">인프라</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.INFRA}
                value={searchObj?.infraSeq?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.INFRA?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    infraSeq: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">운임구분</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.PARCEL_PAY_TYPE}
                value={searchObj?.payType?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PARCEL_PAY_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    payType: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <button
                className="btn btn-search"
                onClick={(e) => {
                  e.preventDefault();
                  if (!searchObj?.fromCollectDate || !searchObj?.toCollectDate) {
                    alert('집하일자는 필수값입니다');
                  } else {
                    fetchList(searchObj);
                  }
                }}
              >
                조회
              </button>
            </Col>
            <Col />
            <Col />
          </Row>
        )}

        {TARGET === 'PARCEL_FEE_SELLER' && (
          <Row>
            <Col>
              <label className="col-form-label">*집하일자</label>
              <FlatpickrD
                value={[searchObj?.fromCollectDate, searchObj?.toCollectDate]}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    fromCollectDate: returnDateyyyymmdd(option[0]),
                    toCollectDate: returnDateyyyymmdd(option[1]),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">인프라</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.INFRA}
                value={searchObj?.infraSeq?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.INFRA?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    infraSeq: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">판매사(포워더)</label>
              <SelectD
                options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
                value={
                  searchObj?.partnerSeq
                    ? {
                        value: searchObj?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_FORWARDER_AUTH?.[searchObj?.partnerSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    partnerSeq: (option as OptionItem)?.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">운임구분</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.PARCEL_PAY_TYPE}
                value={searchObj?.payType?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PARCEL_PAY_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    payType: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <button
                className="btn btn-search"
                onClick={(e) => {
                  e.preventDefault();
                  if (!searchObj?.fromCollectDate || !searchObj?.toCollectDate) {
                    alert('집하일자는 필수값입니다');
                  } else {
                    fetchList(searchObj);
                  }
                }}
              >
                조회
              </button>
            </Col>
            <Col />
          </Row>
        )}

        {TARGET === 'PARCEL_FEE_RETURN' && (
          <Row>
            <Col>
              <label className="col-form-label">*집하일자</label>
              <FlatpickrD
                value={[searchObj?.fromCollectDate, searchObj?.toCollectDate]}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    fromCollectDate: returnDateyyyymmdd(option[0]),
                    toCollectDate: returnDateyyyymmdd(option[1]),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">인프라</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.INFRA}
                value={searchObj?.infraSeq?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.INFRA?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    infraSeq: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">운임구분</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.PARCEL_PAY_TYPE}
                value={searchObj?.payType?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PARCEL_PAY_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    payType: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <button
                className="btn btn-search"
                onClick={(e) => {
                  e.preventDefault();
                  if (!searchObj?.fromCollectDate || !searchObj?.toCollectDate) {
                    alert('집하일자는 필수값입니다');
                  } else {
                    fetchList(searchObj);
                  }
                }}
              >
                조회
              </button>
            </Col>
            <Col />
            <Col />
          </Row>
        )}

        {TARGET === 'PARCEL_FEE_SALESCHANNEL' && (
          <Row>
            <Col>
              <label className="col-form-label">*집하일자</label>
              <FlatpickrD
                value={[searchObj?.fromCollectDate, searchObj?.toCollectDate]}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    fromCollectDate: returnDateyyyymmdd(option[0]),
                    toCollectDate: returnDateyyyymmdd(option[1]),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">인프라</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.INFRA}
                value={searchObj?.infraSeq?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.INFRA?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    infraSeq: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">판매사(포워더)</label>
              <SelectD
                options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
                value={
                  searchObj?.partnerSeq
                    ? {
                        value: searchObj?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_FORWARDER_AUTH?.[searchObj?.partnerSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    partnerSeq: (option as OptionItem)?.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">판매채널</label>
              <SelectD
                options={MASTER_OPS?.SALES_CHANNEL}
                value={
                  searchObj?.salesChannelSeq
                    ? {
                        value: searchObj?.salesChannelSeq,
                        label: MASTER_OBJ?.SALES_CHANNEL?.[searchObj?.salesChannelSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    salesChannelSeq: (option as OptionItem)?.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">운임구분</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.PARCEL_PAY_TYPE}
                value={searchObj?.payType?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PARCEL_PAY_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    payType: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <button
                className="btn btn-search"
                onClick={(e) => {
                  e.preventDefault();
                  if (!searchObj?.fromCollectDate || !searchObj?.toCollectDate) {
                    alert('집하일자는 필수값입니다');
                  } else {
                    fetchList(searchObj);
                  }
                }}
              >
                조회
              </button>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
};
