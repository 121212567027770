import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './inspect';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';

export const INVENTORY_INSPECT_TAB_MASTER = [
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  'INFRA',
  //
  'MODEL_TYPE',
  'MODEL_GROUP',
  //
  'INVA_STATUS',
  'INVENTORY_INSPECT_STATUS',
  'INSPECT_CAUSE',
  'INSPECT_REASON',
  'INSPECT_PROCESS',
  {
    SEARCH_DATE: [
      {
        label: '검수생성일',
        value: 'RegisterDate',
      },
      {
        label: '검수완료일',
        value: 'CenterConfirmDate',
      },
    ],
  },
  'SEARCH_MODEL',
  //
  'INSPECT_FLAG',
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(INVENTORY_INSPECT_TAB_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            INVENTORY_INSPECT_TAB: <Tab1 tabId={'INVENTORY_INSPECT_TAB'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
