import * as IGrid from 'aui-grid';
import { httpClient } from 'common/http-client/axiosConfig';
import { INVENTORY_LOCATION_LISTS_FOR_LOCATION, LOCATION_LISTS, WAREHOUSE_LOCATION_LIST_CENTERCODE } from 'envVar';
import { InventoryLocationListDTO, LocationListDTO, LocationListDTOInCell } from 'interface/warehouse';

class MapService {
  public async getLocationRowData(centerCode: string, locationCode: string): Promise<InventoryLocationListDTO[]> {
    let url = `${INVENTORY_LOCATION_LISTS_FOR_LOCATION}?centerCode=${centerCode}&locationCode=${locationCode}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  // 창고 로케이션 전체 지도를 출력한다
  public async getLocationHeadData(centerCode: string): Promise<string[][]> {
    let url = `/warehouse/${centerCode}/location`;
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getInventoriesAndFloor(centerCode: string, locationCode: string): Promise<LocationListDTOInCell[]> {
    let url = `${LOCATION_LISTS}/${centerCode}/locationCode/${locationCode}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getLocationsWhereHaveInvent(centerCode: string): Promise<LocationListDTO[]> {
    let url = `${WAREHOUSE_LOCATION_LIST_CENTERCODE}/${centerCode}`;
    const { data } = await httpClient.get(url);
    return data;
  }
}

export const mapService = new MapService();
