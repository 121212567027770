import { useState, useEffect } from 'react';
import { defaultUnitedPageWithTopTab, DRIVER_TAKEOUT, UnitedPageWithTopTab } from 'components/template/topTab';

import Container1 from './loanModel/container.js';
import Container2 from './loanMaterial';

// redux
import { useSelector, useDispatch } from 'react-redux';

// options
import { centerListForlabeling, codeMasterOptionReturn, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { setMasterOptions } from 'redux/services/menuSlice';

import { driverListForLabeling } from 'common/master/codeMasterOptionReturn';
import { RootState } from 'redux/store';

const InventoryReportUnited = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    initMasterOptions();
  }, []);

  useEffect(() => {
    if (masterOptions) {
      const unitedName = DRIVER_TAKEOUT;
      const currentTab = JSON.parse(localStorage.getItem(unitedName)) ? JSON.parse(localStorage.getItem(unitedName)) : 0;

      setTabObj({
        unitedName: unitedName,
        currentTab: currentTab,
        titles: ['제품출고', '자재출고'],
        containers: [<Container1 />, <Container2 />],
      });
    }
  }, [masterOptions]);

  const initMasterOptions = async () => {
    const [
      //
      DRIVER_WHOLE,
      SELLER_WHOLE,
      CENTER_WHOLE,
      LOAN_COMP_STATUS,
      FEE_TYPE,
      LOAN_STATUS,
      MODEL_GROUP,

      WH_TYPE,
      MODEL_LARGE,
      TOSS_PAYMENT_STATUS,
      ORDER_SEQ,
    ] = await Promise.all([
      driverListForLabeling(),
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling(),
      codeMasterOptionReturn('LOAN_COMP_STATUS'),
      codeMasterOptionReturn('FEE_TYPE'),
      codeMasterOptionReturn('LOAN_STATUS'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('WH_TYPE'),
      codeMasterOptionReturn('MODEL_GRP_1'),
      codeMasterOptionReturn('TOSS_PAYMENT_STATUS'),
      codeMasterOptionReturn('ORDER_SEQ'),
    ]);
    dispatch(
      setMasterOptions({
        TOSS_PAYMENT_STATUS,
        MODEL_LARGE,
        WH_TYPE,
        MODEL_GROUP,
        FEE_TYPE,
        LOAN_STATUS,
        LOAN_COMP_STATUS,
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_LBL: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        DRIVER_LABEL: DRIVER_WHOLE?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        SEARCH_DATE: [
          { value: 'RegisterDate', label: '요청일시' },
          { value: 'PromiseDate', label: '출고예정일' },
          { value: 'LoanDate', label: '출고일시' },
        ],
        DRIVER_NAME: DRIVER_WHOLE.map((ele) => {
          return { value: ele.userId, label: ele.driverName };
        }),
        DRIVER_PHONE: DRIVER_WHOLE.map((ele) => {
          return { value: ele.userId, label: ele.phone1 };
        }),
        DRIVER_MEMBERCOUNT: DRIVER_WHOLE.map((ele) => {
          return { value: ele.userId, label: ele.memberCount };
        }),
        ORDER_TYPE: [
          { value: null, label: '선택' },
          { value: 'invoiceSeq', label: '송장번호' },
          { value: 'loanSeq', label: '기사출고번호' },
        ],
        ORDER_SEQ: [
          { value: 'orderSeq', label: '주문번호' },
          { value: 'externalOrderNumber', label: '외부주문번호' },
          { value: 'invoiceSeq', label: '송장번호' },
          { value: 'externalInvoiceNumber', label: '외부송장번호' },
          { value: 'customerName', label: '고객명' },
        ],
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'barcode', label: '바코드' },
          { value: 'modelStockSeq', label: 'SKU' },
          { value: 'skuNumber', label: '외부SKU' },
        ],
      }),
    );
  };

  return <>{masterOptions && tabObj?.titles.length > 0 && <UnitedPageWithTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default InventoryReportUnited;
