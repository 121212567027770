import { useEffect, useRef } from 'react';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import CommonModalNew from 'components/modal/commonModalNew';
import * as IGrid from 'aui-grid';
import { Col, Row, Form } from 'reactstrap';
import { PagingListDTO } from 'interface/util';
import { ModelStockAndModelSSDTO } from 'pages/ORDER/2ss/_interface/warehouse';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { serviceStore } from 'services/services';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';

const translator = {
  title: {
    model: '제품 등록',
    center: '창고 변경',
  },
  width: {
    model: 1000,
    center: 400,
  },
  columns: {
    model: [
      {
        headerText: '출고창고',
        dataField: 'centerCodeKr',
      },
      {
        headerText: '현재보관수량',
        dataField: 'available',
        formatString: '#,##0',
      },
      {
        headerText: '제품',
        dataField: 'model',
      },
      {
        headerText: '제품명',
        dataField: 'modelName',
      },
      {
        headerText: '바코드',
        dataField: 'barcode',
      },
      {
        headerText: '금액정보(개당)',
        children: [
          {
            headerText: '공급가액',
            dataField: 'sellingPrice',
            formatString: '#,##0',
            editRenderer: {
              type: IGrid.EditRendererKind.InputEditRenderer,
              autoThousandSeparator: true,
              onlyNumeric: true,
            },
          },
          {
            headerText: '부가세',
            dataField: 'surtax',
            formatString: '#,##0',
            editRenderer: {
              type: IGrid.EditRendererKind.InputEditRenderer,
              autoThousandSeparator: true,
              onlyNumeric: true,
            },
          },
          {
            headerText: '단가(VAT포함)',
            dataField: 'totalPrice',
            formatString: '#,##0',
            editRenderer: {
              type: IGrid.EditRendererKind.InputEditRenderer,
              autoThousandSeparator: true,
              onlyNumeric: true,
            },
          },
        ],
      },
    ],
    center: [
      {
        headerText: '보관창고',
        dataField: 'centerCodeKr',
      },
      {
        headerText: '현재보관수량',
        dataField: 'available',
        formatString: '#,##0',
      },
    ],
  },
  isPaging: {
    model: true,
    center: false,
  },
  getAPI: {
    model: 'model/stock/ss/list/paging',
    center: 'model/stock/ss',
  },
};

export interface IPopupObj {
  visible?: boolean;
  type?: 'model' | 'center';
  item?: any;
  returns?: any;
}

export const Popup = ({ popupObj, setPopupObj }) => {
  const gridRef = useRef<IPagingGrid>();
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    if (popupObj?.type === 'center') {
      fetchPaging({ modelStockSeq: popupObj?.item?.modelStockSeq });
    }
  }, []);

  const labellingKr = (data: PagingListDTO<ModelStockAndModelSSDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(
      popupObj?.type === 'center'
        ? { list: (await serviceStore?.warehouseAction(`model/stock/ss?modelStockSeq=${searchObj.modelStockSeq}`, 'GET', null))?.data?.warehouseList }
        : (await serviceStore?.warehouseAction('model/stock/ss/list/paging', 'GET', searchObj, null, true))?.data,
    );
    if (popupObj?.type === 'center') gridRef?.current?.setGridData(dataKr?.list);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      wrappedFetchList(searchObj);
    }
  };

  return (
    <CommonModalNew
      title={translator?.title?.[popupObj?.type]}
      subTitle={
        <>
          {popupObj?.type === 'model' && <div>판매사: {MASTER_OBJ?.SELLER_WHOLE?.[popupObj?.item?.partnerSeq]}</div>}
          {popupObj?.type === 'center' && (
            <div>
              <div>현재창고: {popupObj?.item?.centerCodeKr}</div>
              <div>제품: {popupObj?.item?.model}</div>
              <div>제품명: {popupObj?.item?.modelName}</div>
              <div>바코드: {popupObj?.item?.barcode}</div>
            </div>
          )}
        </>
      }
      visible={popupObj?.visible}
      setVisible={() => {
        setPopupObj(null);
      }}
      style={{ width: translator?.width?.[popupObj?.type] }}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={(e) => {
              e.preventDefault();
              const items = gridRef?.current.getCheckedRowItemsAll();
              if (items?.length > 0) {
                setPopupObj((prev) => {
                  return {
                    ...prev,
                    returns: items,
                  };
                });
              } else {
                alert('선택된 항목이 없습니다!');
              }
            }}
          >
            선택
          </button>
        </>
      }
      children={
        <>
          <Form onKeyPress={onKeyPress}>
            <Row style={{ marginBottom: 20 }}>
              {popupObj?.type === 'model' && (
                <>
                  <Col>
                    <label className="col-form-label">출고창고</label>
                    <SelectD
                      value={
                        searchObj?.centerCode
                          ? {
                              value: searchObj?.centerCode,
                              label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                            }
                          : null
                      }
                      options={MASTER_OPS?.CENTER_AUTH}
                      onChange={(option) => {
                        setSearchObj({
                          ...searchObj,
                          centerCode: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label>제품</label>
                    <InputD
                      value={searchObj?.model || ''}
                      onChange={(e) => {
                        setSearchObj((prev) => {
                          return {
                            ...prev,
                            model: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label>제품명</label>
                    <InputD
                      value={searchObj?.modelName || ''}
                      onChange={(e) => {
                        setSearchObj((prev) => {
                          return {
                            ...prev,
                            modelName: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label>바코드</label>
                    <InputD
                      value={searchObj?.barcode || ''}
                      onChange={(e) => {
                        setSearchObj((prev) => {
                          return {
                            ...prev,
                            barcode: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col style={{ display: 'flex', paddingTop: 11 }}>
                    <button
                      className="btn btn-search ssm"
                      onClick={(e) => {
                        e.preventDefault();
                        wrappedFetchList(searchObj);
                      }}
                    >
                      조회
                    </button>
                  </Col>
                </>
              )}
            </Row>
          </Form>
          <GridBox
            gridRef={gridRef}
            columns={translator?.columns?.[popupObj?.type]}
            gridProps={{
              height: 400,
              rowCheckDisabledFunction(rowIndex, isChecked, item) {
                if (popupObj?.type === 'center') {
                  if (item?.available === 0) return false;
                  if (item?.centerCode === popupObj?.item?.centerCode) return false;
                  return true;
                }
                if (popupObj?.type === 'model') {
                  if (item?.available === 0) return false;
                  if (!item?.sellingPrice || !item?.totalPrice) return false;
                  return true;
                }
                return true;
              },
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              rowCheckToRadio: popupObj?.type === 'model' ? false : true,
            }}
          />
        </>
      }
    />
  );
};
