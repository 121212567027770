import { useEffect } from 'react';
import Container1 from './dailyDeliveryMagam/container';
import Container2 from './dailyCenterReport/container';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { TopTab } from 'components/template/topTabNew';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { RAWDATA } from 'redux/services/menuSlice';

const Index = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
  }, []);
  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      `${RAWDATA}DRIVER_WHOLE`,
      `${RAWDATA}DRIVER_AUTH`,
      'DRIVER_AUTH',
      'DRIVER_WHOLE',
      'DRIVER_PHONE_WHOLE', // 라벨용이 많으니
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'CENTER_CALL_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_NETWORK_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      'SALES_CHANNEL',
      //
      'ORDER_TYPE',
      `${RAWDATA}ORDER_TYPE`,
      'INVOICE_STATUS',
      'INVOICE_STATUS_1',
      'INVOICE_STATUS_2',
      'FEE_TYPE',
      'CANCEL_REASON',
      'COLLECT_REASON',
      'SERVICE_TYPE',
      'MODEL_TYPE',
      'MODEL_GROUP',
      'RESCHEDULE_TYPE',
      'BODY_WIFI_REASON',
      'EOL_GROUP2',
      `${RAWDATA}EOL_GROUP2`,
      'EOL_GROUP3',
      'COUNSEL_DETAIL_TYPE',
      'COUNSEL_TYPE',
      'LADDER_CO',
      'MODEL_GRP_1',
      'MODEL_GRP_2',
      'MODEL_GRP_3',
      'CANCEL_TYPE',
      'MESSAGE_TYPE',
      'INVENTORY_LOCATION',
      'PROVINCE_WHOLE',
      'CITY',
      'DRIVER_PARTY',
      'TEAM_GRADE',
      'DRV_POS',
      'CAR_TYPE',
      'DRV_TYPE',
      'TEAM_TYPE',
      'LOGI_TYPE',
      'PACKING_STATE',
      'INV_TRACK_TYPE',
      'PARCEL_PAY_TYPE',
      'INVOICE_UPDATE_CONTENT',
      'INSTALL_FORM',
      //
      'SEARCH_ORDER',
      'SEARCH_INVOICE',
      'SEARCH_MODEL',
      //
      'INSTALL_DURATION',
      'DELIVERY_TIME',
      'UNIFORM_SIZE',
      'WEEK_DAY',
      'OX_FLAG',
      'USE_FLAG',
      'LITE_FLAG',
      'FIX_DATE',
      {
        CLOSE_STATUS: [
          { value: 'NOT_LOAN', label: '미출고' },
          { value: 'NOT_FINISH', label: '미완료' },
          { value: 'FINISH', label: '완료' },
          { value: 'CANCEL', label: '취소' },
        ],
      },
      {
        PMODEL_TYPE: [
          { value: 'MODEL', label: '구성품' },
          { value: 'GIFT', label: '사은품' },
        ],
      },
    ];
    initMasterOptions(masterArr);
  };

  return <>{masterOptions && <TopTab titles={['일일 배송마감', '일일 재고마감']} containers={[<Container1 />, <Container2 />]} />}</>;
};

export default Index;
