import { useEffect, useRef, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  WMS_LINEHAUL_PICKING_LIST_EXCEL,
  WMS_LINEHAUL_PICKING_LIST_EXCEL_DETAIL,
  WMS_LINEHAUL_PICKING_DETAIL,
  WMS_LINEHAUL_PICKING_LIST_PAGING,
  WMS_LINEHAUL_PICKING_MERGE,
  WMS_LINEHAUL_PICKING_COMPLETE,
  WMS_LINEHAUL_PICKING_COMPLETE_DETAIL,
  WMS_LINEHAUL_PICKING_DETAIL_INVENTORY,
  LINEHAUL_ROLLBACK,
  WMS_LINEHAUL_PICKING_PHOTO,
} from 'envVar';
import Presenter from './presenter';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';

import { defaultBottomSheetObj } from 'components/template/bottomSheets';
import { setLoading } from 'redux/services/menuSlice';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { serviceStore } from 'services/services';

const Container = (props) => {
  const dispatch = useDispatch();

  const [gridId, setGridId] = useState();

  //그리드
  const [rows, setRows] = useState([]);
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);
  const [locationChangingObj, setLocationChangingObj] = useState({
    visible: false,
    item: null,
    saveFn: (data) => saveLocation(data),
  });
  //옵션
  const { masterOptions } = useSelector((state) => state.menu);
  const { reduxUserInfo } = useSelector((state) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  //검색자
  const [searchObj, setSearchObj] = useState({
    linehaulSeq: null,
    originalCenterCode: null,
    destinationCenterCode: null,
    linehaulType: null,
    status: null,
    dateType: null,
    fromDate: null,
    toDate: null,
    ...defaultSearchFilter,
  });

  //페이징
  const [pagingSetting, setPagingSetting] = useState({
    pageNo: 1,
    pageSize: 100,
    fetchNew: false,
  });
  const [bottomSheetObj, setBottomSheetObj] = useState(defaultBottomSheetObj);
  const [modalObj, setModalObj] = useState({ visible: false });
  const [alertModalObj, setAlertModalObj] = useState({ visible: false });
  const [inventoryCancelModalObj, setInventoryCancelModalObj] = useState({
    visible: false,
  });

  useEffect(() => {
    setGridId(`trunkPickingList_Main${uuidv4()}`);
  }, []);

  const paramingSearchObjToUrl = (url, searchObj) => {
    const searchParams = Object.keys(searchObj);

    let urlWithSearchParams = url;
    let dateType = searchObj['dateType'];
    for (let param of searchParams) {
      if (searchObj[param] !== null && searchObj[param] !== '' && param !== 'fromDate' && param !== 'toDate')
        param === 'dateType' && searchObj.fromDate !== null
          ? (urlWithSearchParams += `&from${dateType}=${searchObj['fromDate']}&to${dateType}=${searchObj['toDate']}`)
          : (urlWithSearchParams += `&${param}=${searchObj[param]}`);
    }

    return urlWithSearchParams;
  };

  const saveLocation = ({ data, event, searchObj }) => {
    httpClient.post(`/warehouse/linehaul/picking/location/change`, data).then((rs) => {
      if (rs?.status === 200) {
        alert('로케이션 이동에 성공하였습니다!');
        fetchDetailList(event);
        fetchList(searchObj ? JSON.parse(searchObj) : null);
      }
    });
  };
  const [trunkPrintObj, setTrunkPrintObj] = useState();
  const printAction = async (event) => {
    setTrunkPrintObj({
      visible: true,
      type: 'picking',
      item: event?.item,
    });
  };

  const fetchList = async (searchObj) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(defaultBottomSheetObj);

    let url = WMS_LINEHAUL_PICKING_LIST_PAGING + `?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
    const search = paramingSearchObjToUrl(url, searchObj);

    await httpClient.get(search).then((res) => {
      const data = res.data.list || [];
      const maxPage = Math.ceil(res.data.totalCount / pagingSetting?.pageSize);
      data.forEach((row) => {
        row['originalCenterCodeKr'] = selectlabel(row.originalCenterCode, masterOptions?.['CENTER_WHOLE_WITH_CLOSED']);
        row['destinationCenterCodeKr'] = selectlabel(row.destinationCenterCode, masterOptions?.['CENTER_WHOLE_WITH_CLOSED']);
        row['linehaulTypeKr'] = selectlabel(row.linehaulType, masterOptions?.['LINEHAUL_TYPE']);
        row['statusKr'] = row.status ? selectlabel(row.status, masterOptions?.['LINE_STAT']) : '';
        row['print'] = '피킹지시서';
        row.searchObj = JSON.stringify(searchObj);
        row.maxPage = maxPage;
        row.totalCount = res.data.totalCount;
      });
      setRows(data);
    });
    dispatch(setLoading(null));
  };

  const bottomGridRef = useRef();
  const fetchDetailList = async (event) => {
    dispatch(setLoading('GET'));

    let url = WMS_LINEHAUL_PICKING_DETAIL + '?';
    if (event.item.linehaulType) url += `&linehaulType=${event.item.linehaulType}`;
    if (event.item.linehaulSeq) url += `&linehaulSeq=${event.item.linehaulSeq}`;
    if (event.item.originalCenterCode) url += `&originalCenterCode=${event.item.originalCenterCode}`;
    if (event.item.destinationCenterCode) url += `&destinationCenterCode=${event.item.destinationCenterCode}`;
    if (event.item.linehaulDate) url += `&linehaulDate=${event.item.linehaulDate}`;
    if (event.item.customerOrderSeq) url += `&customerOrderSeq=${event.item.customerOrderSeq}`;
    if (event.item.customerOrderInvoiceSeq) url += `&customerOrderInvoiceSeq=${event.item.customerOrderInvoiceSeq}`;

    const res = await httpClient.get(url);
    const rows = res.data || [];
    rows.forEach((row) => {
      row['modelGroupKr'] = selectlabel(row.modelGroupLarge, masterOptions?.['MODEL_LARGE']);
      row['partnerSeqKr'] = selectlabel(row.partnerSeq, masterOptions?.['SELLER']);
      row['statusKr'] = row.status ? selectlabel(row.status, masterOptions?.['LINE_STAT']) : '';
      row['linehaulType'] = event.item?.linehaulType;
      row['linehaulDate'] = event.item?.linehaulDate;
    });

    const gridButtonhandler = (e) => {
      const id = e.target.id;
      const items = bottomGridRef?.current?.getCheckedRowItemsAll();
      if (id === 'excel-down') {
        bottomGridRef?.current?.exportAsXlsx({ fileName: `간선피킹지시_상세` });
      } else {
        if (items?.length > 0) {
          if (id === 'linehaul-detail-picking') {
            linehaulDetailPickingAction({ items, event, searchObj: event?.item?.searchObj });
          } else if (id === 'linehaul-detail-picking-confirm') {
            linehaulDetailPickingConfirm(items);
          } else if (id === 'change-location') {
            const filtered = items.filter((ele) => ele.statusKr !== '피킹지시대기');
            if (filtered?.length > 0) {
              alert('피킹지시대기상태에서만 가능합니다!');
            } else {
              if (items?.length > 1) {
                alert('한개씩 변경가능합니다!');
              } else {
                setLocationChangingObj((prev) => {
                  return {
                    ...prev,
                    item: items[0],
                    visible: true,
                    event,
                    searchObj,
                  };
                });
              }
            }
          }
        } else {
          alert('선택된 건이 없습니다!');
        }
      }
    };

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '간선피킹지시 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          buttons: [
            <div key={`linehaul-detail-picking`} id={`linehaul-detail-picking`} className={`orange`} onClick={gridButtonhandler}>
              부분피킹지시 확정
            </div>,
            <div key={`linehaul-detail-picking-confirm`} id={`linehaul-detail-picking-confirm`} className={`orange`} onClick={gridButtonhandler}>
              간선피킹상세 완료
            </div>,
            <div key={`change-location`} id={`change-location`} className={`orange`} onClick={gridButtonhandler}>
              로케이션 변경
            </div>,
            <div key={`excel-down`} id={`excel-down`} className={`green`} onClick={gridButtonhandler}>
              엑셀다운
            </div>,
          ],
          options: {
            isRowAllCheckCurrentView: true,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          },
        },
      ],
    });

    dispatch(setLoading(null));
  };

  const linehaulConfirmAction = async (items = []) => {
    const statusKr = items.map((a) => a.statusKr);
    let duplicate = true;
    for (let i = 0; i < statusKr.length; i++) {
      const currElem = statusKr[i];

      for (let j = i + 1; j < statusKr.length; j++) {
        if (currElem !== statusKr[j]) {
          duplicate = false;
        }
      }
    }
    if (duplicate === true) {
      const confirm = window.confirm('확정하시겠습니까?');
      if (confirm) {
        dispatch(setLoading('PUT'));

        const payloadArr = [];
        let url = `/warehouse/linehaul/picking?`;

        items.forEach((i) => {
          payloadArr.push({
            originalCenterCode: i.originalCenterCode,
            destinationCenterCode: i.destinationCenterCode,
            linehaulDate: i.linehaulDate,
            linehaulType: i.linehaulType,
          });
        });
        await httpClient.post(url, payloadArr).then((res) => {
          if (res?.status === 200) {
            alert('확정되었습니다.');
            fetchList(searchObj);
          }
        });
        dispatch(setLoading(null));
      }
    } else {
      alert('간선피킹 지시대기 상태에서만 확정할 수 있습니다.');
    }
  };

  const linehaulMergeAction = async (items = []) => {
    const originalCenterCodes = items.map((a) => a.originalCenterCode);
    const destinationCenterCodes = items.map((a) => a.destinationCenterCode);
    let duplicate = true;
    for (let i = 0; i < originalCenterCodes.length; i++) {
      const currElem = originalCenterCodes[i];

      for (let j = i + 1; j < originalCenterCodes.length; j++) {
        if (currElem !== originalCenterCodes[j]) {
          duplicate = false;
        }
      }
    }
    for (let i = 0; i < destinationCenterCodes.length; i++) {
      const currElem = destinationCenterCodes[i];

      for (let j = i + 1; j < destinationCenterCodes.length; j++) {
        if (currElem !== destinationCenterCodes[j]) {
          duplicate = false;
        }
      }
    }

    if (duplicate === true) {
      const confirm = window.confirm('병합 하시겠습니까?');
      if (confirm) {
        dispatch(setLoading('PUT'));

        const payloadArr = [];
        let url = WMS_LINEHAUL_PICKING_MERGE;

        items.forEach((i, idx) => {
          payloadArr.push({
            linehaulSeq: i?.linehaulSeq,
            destinationCenterCode: i?.destinationCenterCode,
            originalCenterCode: i?.originalCenterCode,
          });
        });
        await httpClient.post(url, payloadArr).then((res) => {
          if (res?.status === 200) {
            alert('병합되었습니다.');
            fetchList(searchObj);
          }
        });
        dispatch(setLoading(null));
      }
    } else {
      alert('출발창고와 도착창고가 동일해야합니다.');
    }
  };

  const linehaulCenterChangeAction = async (items = []) => {
    if ((items.length = 1)) {
      const result = items.map((a) => a.statusKr);
      const result2 = items.map((a) => a.linehaulTypeKr);
      const linehaulStatus = result[0];
      const linehaulType = result2[0];
      if (linehaulStatus === '피킹지시대기' && linehaulType === '주문연동') {
        let duplicate = true;
        for (let i = 0; i < result.length; i++) {
          const currElem = result[i];

          for (let j = i + 1; j < result.length; j++) {
            if (currElem !== result[j]) {
              duplicate = false;
            }
          }
        }

        if (duplicate === true && linehaulStatus === '피킹지시대기') {
          const searchObj = {
            originalCenterCode: items[0].originalCenterCode,
            destinationCenterCode: items[0].destinationCenterCode,
            linehaulDate: items[0].linehaulDate,
            linehaulType: items[0].linehaulType,
          };

          setModalObj({
            ...modalObj,
            visible: true,
            searchObj: searchObj,
            linehaulStatus: linehaulStatus,
          });
        } else {
          setModalObj({
            ...modalObj,
            visible: true,
            linehaulStatus: linehaulStatus,
          });
        }
      } else {
        alert('피킹지시대기 상태 및 주문연동 일때만 변경 가능합니다.');
      }
    } else {
      alert('출발창고 변경은 한건씩만 가능합니다.');
    }
  };

  const linehaulRollBackAction = async (items = []) => {
    const statusKr = items.map((a) => a.statusKr);
    const linehaulSeqs = JSON.stringify(items.map((a) => a.linehaulSeq));
    const linehaulStatus = statusKr[0];
    let duplicate = true;
    for (let i = 0; i < statusKr.length; i++) {
      const currElem = statusKr[i];

      for (let j = i + 1; j < statusKr.length; j++) {
        if (currElem !== statusKr[j]) {
          duplicate = false;
        }
      }
    }
    const validArr = ['피킹지시대기', '피킹지시확정', '피킹중', '피킹완료'];
    if (duplicate === true && validArr.includes(linehaulStatus)) {
      const rollBackData = [];
      for (let i = 0; i < items.length; i++) {
        rollBackData.push({
          linehaulSeq: items[i].linehaulSeq,
          loadingStatementSeq: items[i].loadingStatementSeq,
          inventorySeqs: items[i].inventories?.map((a, idx) => a.inventorySeq),
        });
      }
      setAlertModalObj({
        ...alertModalObj,
        visible: true,
        type: 'rollBack',
        linehaulStatus: linehaulStatus,
        linehaulSeqs: linehaulSeqs,
        rollBackData: rollBackData,
      });
    } else if (duplicate === false) {
      alert('진행상태가 서로 다른 건들이 선택되었습니다. \n 취소하시려면 진행상태가 같은건끼리만 선택가능합니다.');
    } else if (duplicate === true && !validArr.includes(linehaulStatus)) {
      alert('이전단계로 돌아 갈수 없는 상태입니다');
    }
  };

  const linehaulCancelAction = async (items = []) => {
    const statusKr = items.map((a) => a.statusKr);
    const linehaulSeqs = JSON.stringify(items.map((a) => a.linehaulSeq));
    const linehaulStatus = statusKr[0];
    let duplicate = true;
    for (let i = 0; i < statusKr.length; i++) {
      const currElem = statusKr[i];

      for (let j = i + 1; j < statusKr.length; j++) {
        if (currElem !== statusKr[j]) {
          duplicate = false;
        }
      }
    }
    const tempArr = ['피킹지시대기', '피킹지시확정', '피킹중', '피킹완료'];
    if (duplicate === true && tempArr.includes(linehaulStatus)) {
      const cancelData = [];
      for (let i = 0; i < items.length; i++) {
        cancelData.push({
          linehaulSeq: items[i].linehaulSeq,
          loadingStatementSeq: items[i].loadingStatementSeq,
          originalCenterCode: items[i].originalCenterCode,
          destinationCenterCode: items[i].destinationCenterCode,
          linehaulDate: items[i].linehaulDate,
          linehaulType: items[i].linehaulType,
          inventorySeqs: items[i].inventories?.map((a, idx) => a.inventorySeq),
        });
      }

      setAlertModalObj({
        ...alertModalObj,
        visible: true,
        type: 'cancel',
        linehaulStatus: linehaulStatus,
        linehaulSeqs: linehaulSeqs,
        cancelData: cancelData,
      });
    } else if (duplicate === false) {
      alert('진행상태가 서로 다른 건들이 선택되었습니다. \n 취소하시려면 진행상태가 같은건끼리만 선택가능합니다.');
    } else if (duplicate === true && !tempArr.includes(linehaulStatus)) {
      alert('취소할 수 없는 상태입니다.');
    }
  };

  const linehaulDetailPickingAction = async ({ items, event, searchObj }) => {
    const confirm = window.confirm('확정하시겠습니까?');
    if (confirm) {
      setLoading(true);

      let url = WMS_LINEHAUL_PICKING_DETAIL;
      const payloadObj = {
        originalCenterCode: items[0].originalCenterCode,
        destinationCenterCode: items[0].destinationCenterCode,
        linehaulDate: items[0].linehaulDate,
        linehaulType: items[0].linehaulType,
        models: items?.map((ele) => {
          return {
            modelSeq: ele.modelSeq,
            locationCode: ele.locationCode,
          };
        }),
      };
      await httpClient.post(url, payloadObj).then((res) => {
        if (res?.status === 200) {
          alert(res.data.code);
          fetchList(JSON.parse(searchObj));
          fetchDetailList(event);
        }
      });
      dispatch(setLoading(null));
    }
  };
  // NOTE: 할당정보 모달 호출 시 사용되는 기준 정보
  const [currentHaldangItem, setCurrentHaldangItem] = useState(null);
  const [haldangPopupObj, setHaldangPopupObj] = useState();
  const inventoryModalAction = async (e) => {
    dispatch(setLoading('GET'));
    setCurrentHaldangItem(e.item);
    setHaldangPopupObj({
      visible: true,
      item: e.item,
      fetchFn: fetchList,
      fns: {
        'linehaul-modal-rollback': (items) => linehaulModalRollBackAction(items),
        'linehaul-modal-confirm': (items) => linehaulModalConfirmAction(items),
        'linehaul-modal-cancel': (items) => linehaulModalCancelAction(items),
      },
    });
    dispatch(setLoading(null));
  };

  // NOTE: 할당정보 액션 변경 시 searchObj가 업데이트된 경우, 할당정보 모달이 열려있을 때 정보를 갱신할 수 있도록 effect hook 적용.
  useEffect(() => {
    if (haldangPopupObj?.visible) {
      setHaldangPopupObj(() => {
        return {
          ...haldangPopupObj,
          origin: { ...currentHaldangItem },
        };
      });
    }
  }, [searchObj]);

  const linehaulModalCancelAction = async (data) => {
    const inventorySeqs = data.map((a) => a.inventorySeq);
    const cancelData = {
      linehaulSeq: data[0].linehaulSeq,
      loadingStatementSeq: data[0].loadingStatementSeq,
      originalCenterCode: data[0].originalCenterCode,
      destinationCenterCode: data[0].destinationCenterCode,
      linehaulType: data[0].linehaulType,
      linehaulDate: data[0].linehaulDate,
    };

    setInventoryCancelModalObj({
      visible: true,
      cancelData: cancelData,
      inventorySeqs: inventorySeqs,
    });
  };

  const linehaulModalRollBackAction = async (data) => {
    dispatch(setLoading('PUT'));
    const inventorySeqs = data.map((a) => a.inventorySeq);
    const payloadObj = {
      linehaulSeq: data[0].linehaulSeq,
      loadingStatementSeq: data[0].loadingStatementSeq,
      inventorySeqs: inventorySeqs,
    };
    let url = LINEHAUL_ROLLBACK;
    httpClient
      .post(url, [payloadObj])
      .then((res) => {
        if (res?.status === 200) {
          alert('선택건 이전단계로 변경 완료되었습니다.');
          fetchList(searchObj);
          setSearchObj({ ...searchObj });
          dispatch(setLoading(null));
        } else {
          alert(`${res.data.message}`);
          fetchList(searchObj);
          dispatch(setLoading(null));
        }
      })
      .catch(async (err) => {
        alert('오류가 있습니다.');
        dispatch(setLoading(null));
        fetchList(searchObj);
      });
  };

  const linehaulModalConfirmAction = async (data) => {
    if (data[0].status === 'INIT') {
      dispatch(setLoading('PUT'));
      const payloadObj = {
        linehaulSeq: data[0].linehaulSeq,
        linehaulType: data[0].linehaulType,
        linehaulDate: data[0].linehaulDate,
        originalCenterCode: data[0].originalCenterCode,
        destinationCenterCode: data[0].destinationCenterCode,
        inventorySeqs: data.map((a) => a.inventorySeq),
      };
      let url = WMS_LINEHAUL_PICKING_DETAIL_INVENTORY;
      httpClient
        .post(url, payloadObj)
        .then((res) => {
          if (res?.status === 200) {
            alert('피킹지시 확정되었습니다.');
            fetchList(searchObj);
            setSearchObj({ ...searchObj });
            dispatch(setLoading(null));
          } else {
            alert(`${res.data.message}`);
            fetchList(searchObj);
            dispatch(setLoading(null));
          }
        })
        .catch(async (err) => {
          alert('부분피킹지시 실패하였습니다.');
          dispatch(setLoading(null));
          fetchList(searchObj);
        });
    } else {
      alert('피킹지시대기 상태 외에는 부분피킹지시확정을 진행할수 없습니다.');
    }
  };

  const linehaulPickingConfirmAction = async (data) => {
    if (window.confirm('간선피킹을 완료하시겠습니까?')) {
      if (data.length === 1 && data[0].linehaulSeq) {
        dispatch(setLoading('PUT'));
        let url = WMS_LINEHAUL_PICKING_COMPLETE + `/${data[0].linehaulSeq}`;

        httpClient
          .post(url, '')
          .then((res) => {
            if (res?.status === 200) {
              alert(`피킹지시번호 ${data[0].linehaulSeq} 건 간선피킹완료 하였습니다.`);
              fetchList(searchObj);
              dispatch(setLoading(null));
            } else {
              alert(`${res.data.message}`);
              fetchList(searchObj);
              dispatch(setLoading(null));
            }
          })
          .catch(async (err) => {
            alert(`피킹지시번호 ${data[0].linehaulSeq} 건 간선피킹완료에 실패 하였습니다.`);
            dispatch(setLoading(null));
            fetchList(searchObj);
          });
      } else {
        alert('간선피킹 완료는 피킹지시확정 또는 피킹중 상태에서 한 건씩만 가능합니다. 상태값을 재확인하거나 한 건씩 진행해주세요.');
        fetchList(searchObj);
      }
    }
  };

  const linehaulDetailPickingConfirm = async (data) => {
    const newArr = [];
    for (let i = 0; i < data.length; i++) {
      if (data[0].linehaulSeq === null) {
        alert('피킹지시번호가 없는 건입니다.');
        break;
      }
      newArr.push({
        modelSeq: data[i].modelSeq,
        locationCode: data[i].locationCode,
      });
    }
    dispatch(setLoading('PUT'));
    const payloadObj = {
      linehaulSeq: data[0].linehaulSeq,
      models: newArr,
    };
    let url = WMS_LINEHAUL_PICKING_COMPLETE_DETAIL;
    httpClient.post(url, payloadObj).then((res) => {
      if (res?.status === 200) {
        alert('간선피킹 상세완료 하였습니다.');
        fetchList(searchObj);
        dispatch(setLoading(null));
      } else {
        alert(`${res.data.message}`);
        fetchList(searchObj);
      }
    });
    dispatch(setLoading(null));
  };

  const downloadExcel = async (str) => {
    dispatch(setLoading('GET'));
    let url;
    if (str === 'main') {
      url = WMS_LINEHAUL_PICKING_LIST_EXCEL + `?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
      const search = paramingSearchObjToUrl(url, searchObj);

      return httpClient.get(search).then((res) => {
        dispatch(setLoading(null));
        const data = res.data || [];
        data.forEach((row) => {
          row['originalCenterCodeKr'] = selectlabel(row.originalCenterCode, masterOptions?.['CENTER_LABEL']);
          row['destinationCenterCodeKr'] = selectlabel(row.destinationCenterCode, masterOptions?.['CENTER_LABEL']);
          row['linehaulTypeKr'] = selectlabel(row.linehaulType, masterOptions?.['LINEHAUL_TYPE']);
          row['modelGroupKr'] = selectlabel(row.modelGroupLarge, masterOptions?.['MODEL_LARGE']);
          row['partnerSeqKr'] = selectlabel(row.partnerSeq, masterOptions?.['SELLER']);
          row['statusKr'] = row.status ? selectlabel(row.status, masterOptions?.['LINE_STAT']) : '';
          row['print'] = '피킹지시서';
        });
        return data;
      });
    } else if (str === 'detail') {
      url = WMS_LINEHAUL_PICKING_LIST_EXCEL_DETAIL + `?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
      const search = paramingSearchObjToUrl(url, searchObj);

      return httpClient.get(search).then((res) => {
        dispatch(setLoading(null));
        const data = res.data || [];
        data.forEach((row) => {
          row['originalCenterCodeKr'] = selectlabel(row.originalCenterCode, masterOptions?.['CENTER_LABEL']);
          row['destinationCenterCodeKr'] = selectlabel(row.destinationCenterCode, masterOptions?.['CENTER_LABEL']);
          row['linehaulTypeKr'] = selectlabel(row.linehaulType, masterOptions?.['LINEHAUL_TYPE']);
          row['modelGroupKr'] = selectlabel(row.modelGroupLarge, masterOptions?.['MODEL_LARGE']);
          row['partnerSeqKr'] = selectlabel(row.partnerSeq, masterOptions?.['SELLER']);
          row['statusKr'] = row.status ? selectlabel(row.status, masterOptions?.['LINE_STAT']) : '';
          row['print'] = '피킹지시서';
        });
        return data;
      });
    }
  };

  const [photoPopupObj, setPhotoPopupObj] = useState({
    visible: false,
    item: null,
    photos: null,
    fetchPhotosFn: (data) => fetchPhotos(data),
  });

  const fetchPhotos = (item) => {
    if (item.linehaulSeq) {
      httpClient.get(WMS_LINEHAUL_PICKING_PHOTO + `/${item.linehaulSeq}`).then((rs) => {
        if (rs?.status === 200) {
          setPhotoPopupObj((prev) => {
            return {
              ...prev,
              visible: true,
              item: item,
              photos: rs.data,
            };
          });
        }
      });
    } else {
      alert('피킹 지시번호가 존재하는 건만 사진열람이 가능합니다');
    }
  };

  const columns = [
    {
      headerText: '상세보기',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '상세보기',
        onClick: fetchDetailList,
      },
    },
    {
      headerText: '사진보기',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '사진보기',
        onClick: (e) => fetchPhotos(e.item),
      },
    },
    {
      headerText: '간선피킹지시서',
      dataField: 'print',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '프린트',
        onClick: printAction,
        visibleFunction: function (rowIndex, columnIndex, value, item, dataField) {
          if (!item.linehaulSeq) {
            return false;
          }
          return true;
        },
      },
    },
    {
      headerText: '할당정보',
      dataField: 'modal',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '할당정보',
        onClick: inventoryModalAction,
      },
    },
    {
      headerText: '피킹지시번호',
      dataField: 'linehaulSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고예정일',
      dataField: 'linehaulDate',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출발창고',
      dataField: 'originalCenterCodeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '도착창고',
      dataField: 'destinationCenterCodeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '간선유형',
      dataField: 'linehaulTypeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '간선피킹대상 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },

    {
      headerText: '피킹완료 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'pickAvailableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'pickDefectedQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'pickEtcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '미피킹 수량(대상 - 완료)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedDiff',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcDiff',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '피킹지시<br />확정일',
      dataField: 'initDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '피킹지시<br />확정자',
      dataField: 'confirmId',
      filter: {
        showIcon: true,
      },
    },
  ];

  const detailColumns = [
    {
      headerText: '피킹지시번호',
      dataField: 'linehaulSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '바코드',
          dataField: 'barcode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '간선피킹대상 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },

    {
      headerText: '피킹완료 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'pickAvailableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'pickDefectedQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'pickEtcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '미피킹 수량<br />(대상 - 완료)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedDiff',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcDiff',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '피킹완료일',
      dataField: 'pickDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '피킹완료자',
      dataField: 'pickId',
      filter: {
        showIcon: true,
      },
    },
  ];

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'TRUNK_LINEHAUL_CONFIRM') {
        linehaulConfirmAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'TRUNK_LINEHAUL_MERGE') {
        linehaulMergeAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'TRUNK_LINEHAUL_CHANGE_CENTER') {
        linehaulCenterChangeAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'TRUNK_LINEHAUL_ROLLBACK') {
        linehaulRollBackAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'TRUNK_LINEHAUL_CANCEL') {
        linehaulCancelAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'TRUNK_LINEHAUL_BODY_CONFIRM') {
        linehaulPickingConfirmAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'linehaul-modal-confirm') {
        linehaulModalConfirmAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'linehaul-modal-cancel') {
        linehaulModalCancelAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'linehaul-modal-rollback') {
        linehaulModalRollBackAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'TRUNK_PICKING_CLOSE') {
        pickingCloseAction(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const pickingCloseAction = async (items) => {
    if (window.confirm('강제마감 하시겠습니까?')) {
      const rs = await serviceStore.warehouseAction(
        'linehaul/picking/close',
        'POST',
        null,
        items?.map((ele) => {
          return {
            seq: ele.linehaulSeq,
          };
        }),
      );
      if (rs?.status === 200) {
        alert('강제마감 완료되었습니다!');
        fetchList(searchObj);
      }
    }
  };

  const ExcelUploadFunction = async (excelrow) => {
    dispatch(setLoading('POST'));
    await httpClient.post(`/warehouse/linehaul/picking/excel`, excelrow).then((rs) => {
      if (rs?.status === 200) {
        alert('업로드 완료되었습니다!');
        fetchList(searchObj);
      }
    });

    dispatch(setLoading(null));
  };

  return (
    <Presenter
      //그리드
      rows={rows}
      columns={columns}
      gridId={gridId}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      //엑셀
      downloadExcel={downloadExcel}
      //함수
      fetchList={fetchList}
      //
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      pagingSetting={pagingSetting}
      setPagingSetting={setPagingSetting}
      //modal
      modalObj={modalObj}
      setModalObj={setModalObj}
      //alertModal
      alertModalObj={alertModalObj}
      setAlertModalObj={setAlertModalObj}
      //inventoryModal
      inventoryCancelModalObj={inventoryCancelModalObj}
      setInventoryCancelModalObj={setInventoryCancelModalObj}
      //bottomSheet
      bottomSheetObj={bottomSheetObj}
      setBottomSheetObj={setBottomSheetObj}
      // 로케이션 변경
      locationChangingObj={locationChangingObj}
      setLocationChangingObj={setLocationChangingObj}
      ExcelUploadFunction={ExcelUploadFunction}
      photoPopupObj={photoPopupObj}
      setPhotoPopupObj={setPhotoPopupObj}
      haldangPopupObj={haldangPopupObj}
      setHaldangPopupObj={setHaldangPopupObj}
      //
      trunkPrintObj={trunkPrintObj}
      setTrunkPrintObj={setTrunkPrintObj}
    />
  );
};

export default Container;
