import { useEffect, useRef } from 'react';
import AUIGrid from 'modules/Grid';
import GridBox from 'common/grid/gridBox';
import { delay } from 'common/util/counting';
import CommonModalNew from 'components/modal/commonModalNew';

export interface ISerialObj {
  visible?: boolean;
  item?: any;
}

interface ISerialPoupObj {
  serialObj: ISerialObj;
  setSerialObj: React.Dispatch<React.SetStateAction<ISerialObj>>;
}

export const SerialsPopup = ({ serialObj, setSerialObj }: ISerialPoupObj) => {
  const gridRef = useRef<AUIGrid>();
  useEffect(() => {
    void delay(300).then(() => {
      if (gridRef?.current) {
        if (serialObj?.item) {
          // const serials = serialObj?.item?.[serialObj?.serialKey];
          // const count = new Array(Number(serialObj?.item?.[serialsPopupObj?.quantityKey])).fill('');
          // const data = [];
          // count.forEach((ele, idx) => {
          //   if (serials?.[idx]) data.push(serials?.[idx]);
          //   else data.push('');
          // });
          // //
          // gridRef.current?.setGridData(
          //   data?.map((ele) => {
          //     return { serialNumber: ele };
          //   }),
          // );
        }
      }
    });
  }, [gridRef?.current]);

  return (
    <CommonModalNew
      visible={serialObj.visible}
      setVisible={() => setSerialObj(null)}
      title={serialObj.item.model}
      subTitle={serialObj.item.barcode}
      style={{ width: 400 }}
      children={
        <>
          <div className="grid-button-area only-right"></div>
          <GridBox
            gridRef={gridRef}
            columns={[
              {
                headerText: '시리얼번호',
                dataField: 'serialNumber',
              },
            ]}
            gridProps={{
              height: 500,
              editable: true,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
            }}
          />
        </>
      }
    />
  );
};
