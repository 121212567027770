import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as IGrid from 'aui-grid';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { Col, Row, Form } from 'reactstrap';
import { ModelStockAndModelSearchDTO, ModelStockAndModelDTO } from 'interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';
import { resetAllSelections } from 'common/util/searchBox';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';

import { PagingListDTO } from 'interface/util';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { serviceStore } from 'services/services';

export interface IModelObj {
  visible?: boolean;
  returns?: any[];
}

export const ModelPopup = ({ modelObj, setModelObj }) => {
  const gridRef = useRef<IPagingGrid>();
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_SEQ, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ]);
  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const columns: IGrid.Column[] = [
    {
      headerText: '단종여부',
      dataField: 'stopFlagKr',
      width: 70,
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '제품',
      dataField: 'model',
      minWidth: 200,
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
      minWidth: 200,
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '원가(단가)',
      dataField: 'primePrice',
      width: 100,
      style: 'aui-textinputer',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        onlyNumeric: true,
        autoThousandSeparator: true,
        allowNegative: false,
      },
    },
    {
      headerText: '발주수량',
      width: 70,
      dataField: 'purchaseQuantity',
      style: 'aui-textinputer',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        autoThousandSeparator: true,
        onlyNumeric: true,
        allowNegative: false,
      },
    },
    {
      headerText: '비고',
      dataField: 'memo',
      style: 'aui-textinputer',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
      },
    },
  ];

  const validate = (items) => {
    return true;
  };

  const returnThese = () => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (validate(items)) {
        setModelObj((prev) => {
          return {
            ...prev,
            returns: items,
          };
        });
      }
    } else {
      alert('선택한 제품이 없습니다');
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      wrappedFetchList(searchObj);
    }
  };

  const labellingKr = (data: PagingListDTO<ModelStockAndModelDTO>) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        stopFlagKr: row.stopFlag ? 'O' : 'X',
        mtoFlagKr: row.mtoFlag === true ? 'O' : 'X',
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        supplierPartnerSeqKr: selectlabel(row.supplierPartnerSeq, masterOptions?.SUPPLIER),
        modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: ModelStockAndModelSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.warehouseAction('model/stock/list/paging', 'GET', searchObj, null, true))?.data as PagingListDTO<ModelStockAndModelDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      mtoFlag: false,
      ...defaultSearchFilter,
    },
    gridRef,
    fetchPaging,
  });

  return (
    <CommonModalNew
      title="제품 검색"
      style={{ width: 1200 }}
      visible={modelObj?.visible}
      setVisible={() => {
        setModelObj(null);
        setSearchObj(null);
      }}
      children={
        <>
          <Form onKeyPress={onKeyPress}>
            <Row>
              <Col>
                <label className="col-form-label">판매사</label>
                <SelectD
                  isMulti
                  options={masterOptions?.SELLER}
                  value={searchObj?.partnerSeq?.map((ele) => {
                    return {
                      value: ele,
                      label: selectlabel(ele, masterOptions?.SELLER),
                    };
                  })}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      partnerSeq: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                    });
                  }}
                />
              </Col>
              <Col>
                <label>제품그룹</label>
                <SelectD
                  options={masterOptions?.MODEL_GROUP}
                  value={
                    searchObj?.modelGroup
                      ? {
                          value: searchObj?.modelGroup,
                          label: masterOptions?.MODEL_GROUP_OBJ?.[searchObj?.modelGroup],
                        }
                      : null
                  }
                  onChange={(option) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        modelGroup: (option as OptionItem).value,
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label>제품</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      value={
                        selectTypeObj?.SEARCH_MODEL
                          ? {
                              value: selectTypeObj?.SEARCH_MODEL,
                              label: masterOptions?.SEARCH_MODEL_OBJ[selectTypeObj?.SEARCH_MODEL],
                            }
                          : null
                      }
                      options={masterOptions?.SEARCH_MODEL}
                      onChange={(option) => {
                        setSelectTypeObj({
                          ...selectTypeObj,
                          SEARCH_MODEL: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!selectTypeObj?.SEARCH_MODEL}
                      value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                      onChange={(e) => {
                        setSearchObj({
                          ...searchObj,
                          [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">유사검색여부</label>
                <div className="radio-select-area">
                  <span
                    className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                    onClick={() => {
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          similaritySearchFlag: true,
                        };
                      });
                    }}
                  >
                    유사검색
                  </span>
                  <span
                    className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                    onClick={() => {
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          similaritySearchFlag: false,
                        };
                      });
                    }}
                  >
                    일치검색
                  </span>
                </div>
              </Col>
              <Col>
                <div style={{ paddingTop: 10, textAlign: 'left' }}>
                  <button
                    className="btn btn-search"
                    onClick={(e) => {
                      console.log('search');
                      e.preventDefault();
                      wrappedFetchList(searchObj);
                    }}
                  >
                    조회
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
          <div className="presenterGridBox">
            <div className="grid-button-area only-right">
              <div
                className="blue"
                onClick={(e) => {
                  //
                  e.preventDefault();
                  returnThese();
                }}
              >
                +추가
              </div>
            </div>
            <GridBox
              gridRef={gridRef}
              columns={columns}
              gridProps={{
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
                editable: true,
                fixedColumnCount: 1,
              }}
            />
          </div>
        </>
      }
    />
  );
};
