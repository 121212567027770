export const mainUrl = '/main';
export const mainFallbackUrl = '/main/dashboard';

export const convertCamelCase = (id) => {
  if (!id) return '';
  const words = id.split('_').map((word) => word.toLowerCase());
  const camelCasedWords = words.map((word, index) => {
    let newWord = word;
    if (index !== 0) newWord = word.charAt(0).toUpperCase() + word.slice(1, word.length);
    return newWord;
  });

  return camelCasedWords.join('');
};

export const generateTabPathUrl = (url) => {
  return `${mainUrl}${url}`;
};

export const generateFullCamelCasePath = (url, tabId) => {
  return generateTabPathUrl(url) + '/' + convertCamelCase(tabId);
};

export const isRegisterScreen = (path) => {
  if (!path) return;

  if (path.includes('Register') || path.includes('create') || path.includes('Regit')) {
    return true;
  }

  return false;
};

export const isTrackingScreen = (path) => {
  if (!path) return;

  if (path === 'manageParcelTracking' || path === 'ordertracking' || path === 'manageParcelCalculateFare') {
    return true;
  }
  return false;
};

export const generateUrlByDesignType = (url) => {
  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));
  if (currentDesignType === 1) {
    return `/main${url}`;
  } else {
    return `${url}`;
  }
};

export const saveCurrentTabUrl = (screenUrl: string, tabUrl: string) => {
  const currentTab = {
    tabUrl,
  };
  localStorage.setItem(`/${screenUrl}`, JSON.stringify(currentTab));
};
