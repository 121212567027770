import { useState, useEffect } from 'react';
import { defaultUnitedPageWithTopTab, TRUNK_UNITED, UnitedPageWithTopTab } from 'components/template/topTab';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Container1 from './picking/container.js';
import Container2 from './chulgo/container.js';
import Container3 from './takeout/container.js';
import CommonTab from '../../_common/supplement';

// options
import { codeMasterOptionReturn, InfraOptionReturn, partnerListForlabeling, centerListForlabeling, diffCenterListReturn } from 'common/master/codeMasterOptionReturn';
import { setLoading, setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store.js';

const Index = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    initMasterOptions();
  }, []);

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (masterOptions) {
      const unitedName = TRUNK_UNITED;
      const currentTab = JSON.parse(localStorage.getItem(unitedName)) ? JSON.parse(localStorage.getItem(unitedName)) : 0;
      setTabObj({
        unitedName,
        currentTab: currentTab,
        titles: reduxUserInfo?.sub?.includes('LD') ? ['재고보충지시', '간선피킹지시', '간선출고지시', '간선출차지시'] : ['간선피킹지시', '간선출고지시', '간선출차지시'],
        containers: reduxUserInfo?.sub?.includes('LD') ? [<CommonTab tabId={'linehaul'} />, <Container1 />, <Container2 />, <Container3 />] : [<Container1 />, <Container2 />, <Container3 />],
      });
    }
  }, [masterOptions]);

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [
      //
      SELLER_SELECT_WHOLE,
      CENTER_SELECT_WHOLE,
      CENTER_WHOLE,
      CENTER_DIFF,
      MODEL_GROUP,
      LINEHAUL_TYPE,
      LINE_STAT,
      ORDER_HOLD_TYPE,
      INVA_TYPE,
      LOADING_STAT,
      MODEL_LARGE,
      LOADING_OUT_STAT,
      CAR_TYPE,
      INFRA,
      PALLET_TYPE,
      ORDER_SEQ,
    ] = await Promise.all([
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling(),
      centerListForlabeling({ selectFlag: false }),

      diffCenterListReturn(),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('LINEHAUL_TYPE'),
      codeMasterOptionReturn('LINE_STAT'),
      codeMasterOptionReturn('ORDER_HOLD_TYPE'),
      codeMasterOptionReturn('INVA_TYPE'),
      codeMasterOptionReturn('LOADING_STAT'),
      codeMasterOptionReturn('MODEL_GRP_1'),
      codeMasterOptionReturn('LOADING_OUT_STAT'),
      codeMasterOptionReturn('CAR_TYPE'),
      InfraOptionReturn(),
      codeMasterOptionReturn('PALLET_TYPE'),
      codeMasterOptionReturn('ORDER_SEQ'),
    ]);
    dispatch(
      setMasterOptions({
        CENTER_WHOLE,
        PALLET_TYPE,
        SELLER_AUTH: SELLER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER: SELLER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_AUTH: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_LABEL: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_DIFF,
        CENTER_WHOLE_WITH_CLOSED: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        MODEL_GROUP,
        LINEHAUL_TYPE,
        LINE_STAT,
        ORDER_HOLD_TYPE,
        INVA_TYPE,
        LOADING_STAT,
        MODEL_LARGE,
        LOADING_OUT_STAT,
        CAR_TYPE,
        INFRA,
        ORDER_SEQ: [
          { value: 'orderSeq', label: '주문번호' },
          { value: 'externalOrderNumber', label: '외부주문번호' },
          { value: 'invoiceSeq', label: '송장번호' },
          { value: 'externalInvoiceNumber', label: '외부송장번호' },
          { value: 'customerName', label: '고객명' },
        ],
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'barcode', label: '바코드' },
          { value: 'modelStockSeq', label: 'SKU' },
          { value: 'skuNumber', label: '외부SKU' },
        ],
        INVENTORY_SEQ: [
          { value: 'inventorySeq', label: '재고번호' },
          { value: 'serialNumber', label: '시리얼번호' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };

  return <>{masterOptions && tabObj?.titles.length > 0 && <UnitedPageWithTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
