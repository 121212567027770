import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { InventoryRefurbishDTO, InventoryRefurbishDetailDTO, InventoryRefurbishSearchDTO } from 'interface/warehouse';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { modelNproductService } from '../../_services/service';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { useNavigate } from 'react-router-dom';

const Index = ({ tabId }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const bottomGridRef = useRef<IPagingGrid>();
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const fetchDetail = async (e) => {
    const inventoryRefurbishSeq = e.item.inventoryRefurbishSeq;
    if (inventoryRefurbishSeq) {
      dispatch(setLoading('GET'));
      const rs = await modelNproductService.getRefurAction(null, `create/detail?inventoryRefurbishSeq=${inventoryRefurbishSeq}`);
      const rows = (rs.data as (InventoryRefurbishDetailDTO & { [key: string]: string })[]) || [];
      rows.forEach((row) => {
        row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER);
        row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
        row.afterModelGroupKr = selectlabel(row.afterModelGroup, masterOptions?.MODEL_GROUP);
        row.beforeModelGroupKr = selectlabel(row.beforeModelGroup, masterOptions?.MODEL_GROUP);
      });

      const gridButtonhandler = (e) => {
        const id = e.target.id;
        if (id === 'REFUR_DETAIL_EXCELDOWN') {
          bottomGridRef.current.exportAsXlsx({ fileName: '리퍼_상세' });
        }
      };

      const buttons = printFunctionToBtns(
        gridFunctions?.filter((ele) => !ele.functionId?.includes('SHOW')),
        gridButtonhandler,
      );

      setBottomSheetObj({
        visible: true,
        hasTabs: [
          {
            title: '리퍼 상세',
            gridRef: bottomGridRef,
            columns: detailColumns,
            rows,
            buttons,
            options: {
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
            },
          },
        ],
      });
      dispatch(setLoading(null));
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      dataField: 'detail',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetail,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.REFUR_SHOWDETAIL;
        },
      },
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '변경일',
      dataField: 'modifyDate',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '반출피킹지시번호',
      dataField: 'stockRemoveSeq',
    },
    {
      headerText: '발주번호',
      dataField: 'purchaseSeq',
    },
    {
      headerText: '변경신청수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
        },
      ],
    },
    {
      headerText: '리퍼생성신청일시',
      dataField: 'registerDatetime',
    },
    {
      headerText: '리퍼생성신청자',
      dataField: 'registerId',
    },
    {
      headerText: '리퍼생성확정일시',
      dataField: 'confirmDatetime',
    },
    {
      headerText: '리퍼생성확정자',
      dataField: 'confirmId',
    },
    {
      headerText: '확정상태',
      dataField: 'statusKr',
    },
  ];

  const detailColumns = [
    {
      headerText: '출고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '변경 전 제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'beforeModelGroupKr',
        },
        {
          headerText: 'SKU',
          dataField: 'beforeModelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'beforeSkuNumber',
        },
        {
          headerText: '바코드',
          dataField: 'beforeBarcode',
        },
        {
          headerText: '제품',
          dataField: 'beforeModel',
        },
        {
          headerText: '제품명',
          dataField: 'beforeModelName',
        },
      ],
    },
    {
      headerText: '변경신청수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
        },
      ],
    },
    {
      headerText: '변경 후 제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'afterModelGroupKr',
        },
        {
          headerText: 'SKU',
          dataField: 'afterModelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'afterSkuNumber',
        },
        {
          headerText: '바코드',
          dataField: 'afterBarcode',
        },
        {
          headerText: '제품',
          dataField: 'afterModel',
        },
        {
          headerText: '제품명',
          dataField: 'afterModelName',
        },
      ],
    },
    {
      headerText: '변경 전',
      children: [
        {
          headerText: '재고번호',
          dataField: 'beforeInventorySeq',
        },
        {
          headerText: '시리얼번호',
          dataField: 'beforeSerialNumber',
        },
      ],
    },
    {
      headerText: '변경 후',
      children: [
        {
          headerText: '재고번호',
          dataField: 'afterInventorySeq',
        },
        {
          headerText: '시리얼번호',
          dataField: 'afterSerialNumber',
        },
      ],
    },
    {
      headerText: '비고',
      dataField: 'memo',
    },
  ];

  const labellingKr = (data: PagingListDTO<InventoryRefurbishDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        statusKr: selectlabel(row.status, masterOptions?.INV_REFUR_STAT),
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryRefurbishSearchDTO) => {
    dispatch(setLoading('GET'));
    const rs = await modelNproductService.getRefurAction(searchObj, 'create/paging', true);
    if (rs) {
      const dataKr = labellingKr(rs?.data as PagingListDTO<InventoryRefurbishDTO>);
      dispatch(setLoading(null));
      return dataKr;
    }
    return [];
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const confirmRefur = async (item) => {
    if (window.confirm('"리퍼생성"을 확정하시겠습니까?')) {
      dispatch(setLoading('POST'));
      const rs = await modelNproductService.postRefurAction(null, `create/confirm?inventoryRefurbishSeq=${item?.inventoryRefurbishSeq}`, null);
      if (rs?.status === 200) {
        alert('리퍼생성이 확정되었습니다!');
        wrappedFetchList(searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  const rejectRefur = async (item) => {
    if (window.confirm('"리퍼생성 반려"을 확정하시겠습니까?')) {
      dispatch(setLoading('POST'));
      const rs = await modelNproductService.postRefurAction(null, `create/reject?inventoryRefurbishSeq=${item?.inventoryRefurbishSeq}`, null);
      if (rs?.status === 200) {
        alert('리퍼생성이 확정되었습니다!');
        wrappedFetchList(searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: (await modelNproductService.getRefurAction(searchObj, 'create/excel', false))?.data as InventoryRefurbishDTO[] }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '리퍼' });
    dispatch(setLoading(null));
  };

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  const handleCheckItems = (e) => {
    const id = e.target.id;
    if (id === 'REFUR_EXCELDOWN_MAIN') {
      downloadExcel('main');
    } else if (id === 'REFUR_CREATE') {
      currentDesignType === 1 ? navigate('/main/refur/refurRegister') : window.open('/refurRegister');
    } else {
      const items = gridRef.current.getCheckedRowItemsAll();
      if (items?.length > 0) {
        if (id === 'REFUR_CONFIRM') confirmRefur(items[0]);
        if (id === 'REFUR_DENY') rejectRefur(items[0]);
      } else {
        alert('선택된 항목이 존재하지 않습니다.');
      }
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, handleCheckItems);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            rowCheckToRadio: true,
            editable: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
