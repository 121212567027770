import styled from 'styled-components';
import { LDSHeading } from '../../atoms/Typography';
import LDSFieldItem from '../../modules/FieldItem';
import { LDSSpacing } from '../../atoms/Spacing';
import LDSCheckbox from '../../atoms/Checkbox';
import LDSButton from '../../atoms/Button';
import { BREAKPOINT, COLORS, SIZES, TRANSITIONS } from 'common/constants/appearance';

import LOGIMATE_NEW_LOGO from 'assets/images/new-logo.png';

import { LABELS } from 'common/constants/localization';
import Aside, { Copyrights } from './aside';
import { useEffect, useLayoutEffect } from 'react';
import { httpClient } from 'common/http-client/axiosConfig';
import { USER_SIGN_IN } from 'envVar';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { useForm, SubmitHandler } from 'react-hook-form';
import { LoginFormInputType } from 'components/LDS/types/types';
import { decodeAndSetUserInfo, setLocalStorageTokens } from 'common/util/localStorage';
import { useDispatch } from 'react-redux';
import { setAuth } from 'redux/services/authSlice';
import { serviceStore } from 'services/services';

export default function LoginPage({ $isLogin }: { $isLogin?: boolean }) {
  const navigate = useNavigate();
  const history = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    resetField,
    watch,
    handleSubmit,
    // formState: { errors },
  } = useForm<LoginFormInputType>();

  const { hasAuth } = useLoaderData() as { hasAuth: boolean };

  useLayoutEffect(() => {
    if (hasAuth) {
      alert('로그인 되어있으므로 홈으로 이동합니다');
      navigate('/');
    }
  }, [hasAuth]);

  const onSubmit: SubmitHandler<LoginFormInputType> = async(formData) => {
    const data = JSON.stringify({
      userId: formData?.userId,
      password: formData?.password,
      loginRemainFlag: formData?.loginRemainFlag,
    });

    const res = await serviceStore?.userAction('sign/in', 'POST', null, data);
      if (res?.data?.result && res?.data?.accessToken && res?.data?.code === 1000) {
        setLocalStorageTokens(res?.data);
        const userInfo = decodeAndSetUserInfo(res?.data);
        dispatch(setAuth(userInfo));

        alert('로그인 되었습니다!');
        history('/')
      } else if (res?.status === 200 && res?.data?.result === false) {
        alert(res?.data?.message);
      }
  };

  useEffect(() => {
    document.body.className = 'authentication-bg';
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <PageWrapper>
      <Aside />
      <SectionContainer className="login-form">
        <LogoImage className="logo mobile" src={LOGIMATE_NEW_LOGO} alt={'new logo'} />
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
          <LDSHeading $align="center">{LABELS.AUTH_LOGIN_TITLE_KR}</LDSHeading>
          <LDSSpacing $direction="V" $value={48} />
          <LDSFieldItem
            $label={LABELS.FIELD_ID_KR}
            $isRequire={true}
            $fieldType={'text'}
            $type={'SINGLE'}
            register={register}
            watch={watch}
            resetField={resetField}
            $fieldDataName={'userId'}
          ></LDSFieldItem>
          <LDSSpacing $direction="V" $value={16} />
          <LDSFieldItem
            $label={LABELS.FIELD_PASSWORD_KR}
            $isRequire={true}
            $fieldType={'password'}
            $type={'SINGLE'}
            register={register}
            watch={watch}
            resetField={resetField}
            $fieldDataName={'password'}
          ></LDSFieldItem>
          <LDSSpacing $direction="V" $value={16} />
          <LDSCheckbox $label={LABELS.OPTION_KEEP_SIGNIN_KR} register={register} watch={watch} $fieldName={'loginRemainFlag'} />
          <LDSSpacing $direction="V" $value={36} />
          <LDSButton $primary={true} $label={LABELS.AUTH_LOGIN_LABEL_KR} $type="SUBMIT" $size="LG" $style="FILL" $usage="PRIMARY" />
        </FormWrapper>
        <Copyrights $width={1600} $color={COLORS.GRAY_03} />
        <LDSSpacing $direction="V" $value={36} />
      </SectionContainer>
    </PageWrapper>
  );
}

const FormWrapper = styled.form`
  width: 360px;

  @media screen and (max-width: ${BREAKPOINT.BP_LG}px) {
    max-width: 90%;
  }
`;

export const PageWrapper = styled.main`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
`;

export const LogoImage = styled.img`
  width: ${SIZES.SIZE_MD}px;
  height: ${SIZES.SIZE_MD}px;
  z-index: 1;

  &.mobile {
    height: 64px;
    width: 64px;
    top: 0;
    margin: 40px 0 24px 40px;
    align-self: baseline;
    display: none;

    @media screen and (max-width: ${BREAKPOINT.BP_LG}px) {
      display: block;
      margin-left: 24px;
      margin-top: 24px;
    }
  }
`;

export type SectionProps = {
  $width?: number;
  $maxWidth?: number;
};

export const SectionContainer = styled.section<SectionProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: ${(props) => (props.$width ? `calc(${props.$width}vw - ${SIZES.SIZE_LG}px)` : 'auto')};
  max-width: ${(props) => (props.$maxWidth ? `${props.$maxWidth}px` : 'auto')};
  min-width: 460px;

  @media screen and (max-width: ${BREAKPOINT.BP_LG}px) {
    min-width: 0px;
  }

  &.login-form {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  form {
    padding-bottom: 24px;
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    @media screen and (max-width: ${BREAKPOINT.BP_LG}px) {
      padding-bottom: 40px;
    }

    & + div {
      display: none;
      label {
        color: ${COLORS.GRAY_05};
        text-shadow: 0 0 5px rgba(0, 0, 0, 0);
      }
      @media screen and (max-width: ${BREAKPOINT.BP_LG}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

export const AsideContainer = styled(SectionContainer)`
  background-color: ${COLORS.BLACK_004};
  overflow: hidden;
  position: relative;
  padding: 40px;
  justify-content: space-between;

  .LDSDivider {
    height: 1.5px;
    transform: scaleX(0);
    transform-origin: left center;

    @keyframes scaleDivider {
      0% {
        transform: scaleX(0);
      }
      100% {
        transform: scaleX(1);
      }
    }

    animation-name: scaleDivider;
    animation-delay: 125ms;
    animation-duration: 825ms;
    animation-fill-mode: forwards;
    animation-timing-function: ${TRANSITIONS.SMOOTHY};
  }

  @media screen and (max-width: ${BREAKPOINT.BP_LG}px) {
    display: none;
  }
`;
