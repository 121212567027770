import { ForwardedRef, useEffect, useState } from 'react';
import AUIGrid, { mappingDefaultOptionToColumn } from '../../modules/Grid';
import { v4 as uuidv4 } from 'uuid';
import * as IGrid from 'aui-grid';
import { Pager } from 'interface/util';
import { ColumnManager, defaultFixedColumnCountObjType, IColumnManagerObj } from './columnManager';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import styled from 'styled-components';
import { VALUES } from 'common/constants/appearance';
export interface IGridObj {
  gridId: string | null;
  rows: any[] | null;
}

export const GRID_PAGE_ROW_COUNT = 100;
export const PARCEL_GRID_PAGE_ROW_COUNT = 500;
export const IGridPageRowSelectValues = [50, 100, 200, 300, 500];

export const defaultGridProps: IGrid.Props = {
  sortableByFormatValue: true,
  enableMultipleSorting: true,
  usePaging: true,
  // height: '100%', // 지정하지 않으면 부모의 height
  showRowAllCheckBox: true,
  showRowCheckColumn: true,
  showFooter: false,
  enableFilter: true,
  pagingMode: 'simple',
  showPageRowSelect: true,
  enableRowCheckShiftKey: true,
  selectionMode: 'multipleCells',
  enableDragByCellDrag: true,
  copySingleCellOnRowMode: true,
  pageRowCount: GRID_PAGE_ROW_COUNT,
  pageRowSelectValues: IGridPageRowSelectValues,
  enableMovingColumn: true,
  isRowAllCheckCurrentView: true,
  enableSorting: true,
  height: 500,
};

export interface IPagingGrid extends AUIGrid {
  current: any;
  totalCount?: number;
  pagination?: Pager;
}

interface IGridProps {
  gridRef: ForwardedRef<IPagingGrid | AUIGrid>;
  columns: IGrid.Column[];
  gridProps?: IGrid.Props;
  gridId?: string;
  gridName?: string;
  defaultFixedColumnCountObj?: defaultFixedColumnCountObjType;
}

const GridBox: React.FC<IGridProps> = ({ gridRef, columns, gridProps, gridId = undefined, gridName = '', defaultFixedColumnCountObj }) => {
  const { openOrclose, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [columnManagerObj, setColumnManagerObj] = useState<IColumnManagerObj>({
    visible: false,
    gridId,
    defaultFixedColumnCountObj,
  });

  useEffect(() => {
    if (gridRef && typeof gridRef !== 'function') {
      setTimeout(
        () => {
          gridRef?.current?.resize();
        },
        JSON.parse(localStorage.getItem('useNewDesignFlag')) === 1 ? VALUES.SIDEBAR_TRANSITION_TIME : 0, //NOTE: 신규 디자인 Sidebar Animation Duration 만큼 Delay 적용
      );
    }
  }, [openOrclose?.side, gridRef]);

  useEffect(() => {
    setColumnManagerObj((prev) => {
      return {
        ...prev,
        gridId,
        defaultFixedColumnCountObj,
      };
    });
  }, [gridId]);

  return (
    <div className={`gridbox-wrapper`} style={{ position: 'relative', width: '100%', height: gridProps?.height }}>
      {gridId && MASTER_OBJ?.[gridId]?.userGridParameters?.length > 0 && <ColumnManager columnManagerObj={columnManagerObj} setColumnManagerObj={setColumnManagerObj} />}
      <AUIGrid
        //
        ref={gridRef}
        name={`${gridName ? gridName + '-' : ''}${uuidv4()}`}
        columnLayout={mappingDefaultOptionToColumn(columns)}
        gridProps={{ ...defaultGridProps, ...gridProps }}
      />
    </div>
  );
};

export default GridBox;

export const LoadingGridBox = () => {
  return (
    <LoadingGridBoxWrapper>
      <div className="aui-hscrollbar">
        <div className="aui-scroll-track"></div>
        <div className="aui-scroll-thumb"></div>
        <div className="aui-scroll-up"></div>
        <div className="aui-scroll-down"></div>
      </div>
      <div className="aui-vscrollbar">
        <div className="aui-scroll-track"></div>
        <div className="aui-scroll-thumb"></div>
        <div className="aui-scroll-up"></div>
        <div className="aui-scroll-down"></div>
      </div>
    </LoadingGridBoxWrapper>
  );
};

const LoadingGridBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede !important;
  background-color: #ffffff;
  min-height: 500px;
  position: relative;

  .aui-hscrollbar {
    position: absolute;
    height: 14px;
    left: 0px;
    top: 0;
    right: 0;

    .aui-scroll-track {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 14px;
    }
    .aui-scroll-thumb {
      position: absolute;
      display: none;
      left: 14px;
      top: 2px;
      width: 30px;
      height: 10px;
    }
    .aui-scroll-up {
      position: absolute;
      display: block;
      left: 0px;
      top: 0px;
      width: 14px;
      height: 14px;
    }
    .aui-scroll-down {
      position: absolute;
      display: block;
      right: 0;
      top: 0px;
      width: 14px;
      height: 14px;
    }
  }
  .aui-vscrollbar {
    position: absolute;
    width: 14px;
    bottom: 0;
    right: 0;
    top: 0px;

    .aui-scroll-track {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 14px;
      bottom: 0;
    }
    .aui-scroll-thumb {
      position: absolute;
      display: none;
      left: 2px;
      top: 0px;
      width: 10px;
      height: 518px;
    }
    .aui-scroll-up {
      position: absolute;
      display: block;
      left: 0px;
      top: 0px;
      width: 14px;
      height: 14px;
    }
    .aui-scroll-down {
      position: absolute;
      display: block;
      left: 0px;
      bottom: 0;
      width: 14px;
      height: 14px;
    }
  }
`;
