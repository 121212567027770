import { DragEvent, ForwardedRef } from 'react';
import styles from './bottomSheet.module.scss';
import handleDrag from './handleDrag.png';
import close from 'assets/images/close/cross.png';
import { useEffect, useRef, useState } from 'react';
import transparent from './transparent.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';
import GridBox from 'common/grid/gridBox';
import { serviceStore } from 'services/services';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { InvoiceLeadTimeDetailDTO } from 'interface/order';
import { setLoading } from 'redux/services/menuSlice';
import styled from 'styled-components';
//

export interface IGridButton {
  color: 'red' | 'green' | 'orange';
  title: string;
  onClick: () => void;
}

export interface IBottomSheetObj {
  visible: boolean;
  title: string;
  grid: {
    gridRef: ForwardedRef<AUIGrid>;
    columns: IGrid.Column[];
    buttons: IGridButton[];
    options?: {
      showRowAllCheckBox: false;
      showRowCheckColumn: false;
    };
  };
  item: any;
  dataField: any;
  searchObj: any;
}

export const defaultBottomSheetObj: IBottomSheetObj = {
  visible: false,
  title: '',
  grid: {
    gridRef: null,
    columns: [],
    buttons: [],
    options: {
      showRowAllCheckBox: false,
      showRowCheckColumn: false,
    },
  },
  item: null,
  dataField: null,
  searchObj: null,
};

// 이 파일을 쓰세요 *****
export const BottomSheet = ({ bottomSheetObj, setBottomSheetObj }) => {
  const { openOrclose } = useSelector((state: RootState) => state.menu);
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const { visible, title, grid } = bottomSheetObj;
  const defaultTop = 150;
  const [top, setTop] = useState<number>(650);
  const [tmp, setTmp] = useState<number>();
  const ghostEle = useRef(null);

  useEffect(() => {
    ghostEle.current = new Image();
    ghostEle.current.src = transparent;
  }, []);

  useEffect(() => {
    fetchDetail(bottomSheetObj);
  }, [bottomSheetObj.visible, bottomSheetObj.item, bottomSheetObj.searchObj]);

  const fetchDetail = async (bottomSheetObj) => {
    dispatch(setLoading('GET'));
    const { item, searchObj, dataField } = bottomSheetObj;
    const orderType = dataField?.split('TotalQuantity')?.[0];
    if (item?.partnerSeq) searchObj['partnerSeq'] = item?.partnerSeq;
    if (item?.infraSeq) searchObj['infraSeq'] = item?.infraSeq;
    if (item?.centerCode) searchObj['centerCode'] = item?.centerCode;
    if (orderType && orderType !== 'total') searchObj['orderType'] = orderType;

    if (!orderType) return;
    //통계 KPI 메뉴에서만 사용
    const targetUrl = bottomSheetObj.title?.includes('리드타임') ? 'invoice/lead/time/detail' : '/invoice/promise/detail';
    const { data } = await serviceStore.orderAction(targetUrl, 'GET', searchObj, null);
    const rows = (data as InvoiceLeadTimeDetailDTO[]).map((row) => {
      return {
        ...row,
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        infraSeqKr: selectlabel(row.infraSeq, masterOptions?.INFRA),
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER),
        dateTypeKr: row.dateType,
        orderTypeKr: selectlabel(row.orderType, masterOptions?.ORDER_TYPE),
        feeTypeKr: selectlabel(row.feeType, masterOptions?.FEE_TYPE),
        invoiceStatusKr: selectlabel(row.invoiceStatus, masterOptions?.INVOICE_STATUS),
        userIdKr: masterOptions?.DRIVER_LABEL_OBJ?.[row.userId],
        inventoryLocationKr: masterOptions?.INVENTORY_LOCATION_OBJ?.[row.inventoryLocation],
      };
    });

    bottomSheetObj?.grid?.gridRef?.current?.setGridData(rows);
    dispatch(setLoading(null));
  };

  const onDragStart = (e: DragEvent) => {
    e.dataTransfer.setDragImage(ghostEle.current, 0, 0);
    if (e.clientY > defaultTop) {
      setTmp(e.clientY);
    }
  };

  const onTouchStart = (e: any) => {
    const y = e.changedTouches?.[0].pageY;
    if (y > defaultTop) setTmp(y);
  };
  const onTouchEnd = (e: any) => {
    const y = e.changedTouches?.[0].pageY;
    if (y > defaultTop) {
      setTmp(y);
      setTop(top - (tmp - y));
    }
  };

  const onDragOver = (e: DragEvent) => {
    if (e.clientY > defaultTop) {
      setTmp(e.clientY);
      setTop(top - (tmp - e.clientY));
    }
  };

  return (
    <BottomSheetWrapper
      style={{ top }}
      visible={visible}
      openOrclose={openOrclose.side}
      draggable
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      className={styles.sheetWrapper}
    >
      <div className={styles.sheetBody}>
        <div className={styles.sheetHandler}>
          <img alt="드래그 핸들" src={handleDrag} className={styles.dragImg} />
          <img
            alt="닫기"
            src={close}
            className={styles.closeImg}
            onClick={() => {
              setBottomSheetObj({ ...bottomSheetObj, visible: false });
            }}
          />
        </div>

        <div className={styles.sheetContent}>
          <div className="div-top-tab" style={{ position: 'relative', marginBottom: 0 }}>
            <div className={'bordering-current'}>
              <li>{title}</li>
            </div>
          </div>
          <div className="presenterGridBox" style={{ position: 'relative' }}>
            <div className="grid-button-area">
              {grid?.buttons?.map((button: IGridButton, index: number) => {
                return (
                  <div
                    key={`button_${index}`}
                    className={button?.color}
                    onDoubleClick={() => {
                      return;
                    }}
                    onClick={button.onClick}
                  >
                    {button?.title}
                  </div>
                );
              })}
            </div>
            <GridBox gridRef={grid?.gridRef} columns={grid?.columns} gridProps={{ height: 500, ...grid?.options }} />
          </div>
        </div>
      </div>
    </BottomSheetWrapper>
  );
};

export const BottomSheetWrapper = styled.div<{ visible?: boolean; openOrclose?: boolean; isDragging?: boolean }>`
  display: ${(props) => (props?.visible ? 'block' : 'none')};
  width: ${(props) => (props?.openOrclose ? 'calc(100% - 181px)' : 'calc(100% - 31px)')};

  & > [class*='sheetBody'] {
    & > [class*='sheetHandler'] {
      height: 48px;
      position: relative;
      z-index: 2;
      cursor: grab;
    }
  }
`;
