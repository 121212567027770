import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// component
import Container1 from './container.js';

// options
import { RootState } from 'redux/store';
import { nullingMASTER } from 'redux/services/menuSlice';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { TopTabController } from 'components/template/TopTabController';

export const PICKINGSTRATEGY_MASTER = [
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  //
  'WH_PICK_STRATEGY',
  'MODEL_GROUP',
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    await initMasterOptions(PICKINGSTRATEGY_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PICKINGSTRATEGY: <Container1 tabId={'PICKINGSTRATEGY'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
