import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { InventoryModelMoveSearchDTO } from 'interface/warehouse';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = (props: ISearchBoxProps<InventoryModelMoveSearchDTO>) => {
  const [selectTypeObj, setSelectTypeObj] = useState({
    BEFORE: null,
    AFTER: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.BEFORE, searchObj));
  }, [selectTypeObj?.BEFORE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.AFTER, searchObj));
  }, [selectTypeObj?.AFTER]);

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">전환일시</label>
          <FlatpickrD
            value={[searchObj?.fromRegisterDate, searchObj?.toRegisterDate]}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                fromRegisterDate: returnDateyyyymmdd(option[0]),
                toRegisterDate: returnDateyyyymmdd(option[1]),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            options={masterOptions?.CENTER}
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">로케이션코드</label>
          <InputD
            value={searchObj?.locationCode || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                locationCode: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            options={masterOptions?.SELLER}
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">변경전</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.BEFORE
                    ? {
                        value: selectTypeObj?.BEFORE,
                        label: masterOptions?.BEFORE_OBJ[selectTypeObj?.BEFORE],
                      }
                    : null
                }
                options={masterOptions?.BEFORE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    BEFORE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.BEFORE}
                value={searchObj?.[selectTypeObj?.BEFORE] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.BEFORE]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">변경후</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.AFTER
                    ? {
                        value: selectTypeObj?.AFTER,
                        label: masterOptions?.AFTER_OBJ[selectTypeObj?.AFTER],
                      }
                    : null
                }
                options={masterOptions?.AFTER}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    AFTER: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.AFTER}
                value={searchObj?.[selectTypeObj?.AFTER] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.AFTER]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">재고상태</label>
          <SelectD
            options={masterOptions?.STATUS}
            value={
              searchObj?.availableStatus !== undefined
                ? {
                    value: searchObj?.availableStatus + '',
                    label: masterOptions?.STATUS_OBJ?.[searchObj?.availableStatus + ''],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                availableStatus: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
