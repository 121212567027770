import { KeyboardEvent, useEffect, useRef, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { OrderReceiptSearchDTO } from '../../../_interface/order';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

export const SearchBox = (props: ISearchBoxProps<OrderReceiptSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchCount, fetchList } = props;
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_ORDER: null,
    SEARCH_INVOICE: null,
    SEARCH_DATE: null,

    SEARCH_MODEL: null,
    SEARCH_SKU: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_ORDER, searchObj));
  }, [selectTypeObj?.SEARCH_ORDER]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_INVOICE, searchObj));
  }, [selectTypeObj?.SEARCH_INVOICE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SKU, searchObj));
  }, [selectTypeObj?.SEARCH_SKU]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">주문번호</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_ORDER
                      ? {
                          value: selectTypeObj?.SEARCH_ORDER,
                          label: MASTER_OBJ?.SEARCH_ORDER?.[selectTypeObj?.SEARCH_ORDER],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_ORDER}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_ORDER: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_ORDER}
                  value={searchObj?.[selectTypeObj?.SEARCH_ORDER] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_ORDER]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">송장번호</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_INVOICE
                      ? {
                          value: selectTypeObj?.SEARCH_INVOICE,
                          label: MASTER_OBJ?.SEARCH_INVOICE?.[selectTypeObj?.SEARCH_INVOICE],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_INVOICE}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_INVOICE: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_INVOICE}
                  value={searchObj?.[selectTypeObj?.SEARCH_INVOICE] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_INVOICE]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">판매사</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.SELLER_SELLER_AUTH}
              value={searchObj?.partnerSeqList?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeqList: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">인프라</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.INFRA}
              value={searchObj?.infraSeqList?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.INFRA?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  infraSeqList: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">소속창고</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.CENTER_AUTH}
              value={searchObj?.centerCodeList?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.CENTER_AUTH?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  centerCodeList: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">배송기사</label>
            <div style={{ display: 'flex' }}>
              <Col md={6} style={{ display: 'inline-block' }}>
                <SelectD
                  options={MASTER_OPS?.DRIVER_AUTH}
                  value={
                    searchObj?.userId
                      ? {
                          value: searchObj?.userId,
                          label: MASTER_OBJ?.DRIVER_AUTH?.[searchObj?.userId],
                        }
                      : null
                  }
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      userId: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={6} style={{ display: 'inline-block' }}>
                <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
              </Col>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">주문유형</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.ORDER_TYPE}
              value={searchObj?.orderTypes?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.ORDER_TYPE?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  orderTypes: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">송장유형</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.FEE_TYPE}
              value={searchObj?.feeTypes?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.FEE_TYPE?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  feeTypes: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">송장상태</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.INVOICE_STATUS}
              value={searchObj?.invoiceStatuses?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.INVOICE_STATUS?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  invoiceStatuses: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">취소사유</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.CANCEL_REASON}
              value={searchObj?.cancelReasons?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.CANCEL_REASON?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  cancelReasons: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">기간검색</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_DATE
                      ? {
                          value: selectTypeObj?.SEARCH_DATE,
                          label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_DATE}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_DATE: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <FlatpickrD
                  onReset={() => {
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                      [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                    });
                  }}
                  disabled={!selectTypeObj?.SEARCH_DATE}
                  value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                      [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                    });
                  }}
                  onClose={(option) => {
                    if (option?.length === 1)
                      setSearchObj({
                        ...searchObj,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                        [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                      });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">제품</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_MODEL
                      ? {
                          value: selectTypeObj?.SEARCH_MODEL,
                          label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_MODEL}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_MODEL: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_MODEL}
                  value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">영수증 상태값</label>
            <SelectD
              isMulti
              options={MASTER_OPS?.OR_STATUS}
              value={searchObj?.statuses?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.OR_STATUS?.[ele],
                };
              })}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  statuses: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                });
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">고객명(수령인)</label>
            <InputD
              value={searchObj?.receiverName || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  receiverName: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">주소</label>
            <InputD
              value={searchObj?.receiverAddr || ''}
              onChange={(e) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    receiverAddr: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">연락처</label>
            <InputD
              value={searchObj?.receiverPhone || ''}
              onChange={(e) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    receiverPhone: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                fetchList(searchObj);
              }}
            >
              조회
            </button>
          </Col>
          <Col />
        </Row>
      </Form>
    </>
  );
};
