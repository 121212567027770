import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tab1 from './1day';
import Tab2 from './2week';
import Tab3 from './3month';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      //
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_WHOLE',
      'INFRA',
    ];
    initMasterOptions(masterArr);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            KPIBYCAPA_DAY: <Tab1 tabId={'KPIBYCAPA_DAY'} />,
            KPIBYCAPA_WEEK: <Tab2 tabId={'KPIBYCAPA_WEEK'} />,
            KPIBYCAPA_MONTH: <Tab3 tabId={'KPIBYCAPA_MONTH'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
