import { MENUS } from 'common/constants/localization';
import useUseableMenus from 'hooks/user/useUseableMenus';
import { setAuth } from 'redux/services/authSlice';
import { MenuDTO } from 'interface/user';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLocalFetchAndFlatMenus } from 'common/util/localStorage';
import { setShowDropdown } from 'redux/services/portalSlice';
import { useLocation } from 'react-router-dom';
import { returnMasterCodesWithArray } from 'common/master/codeMasterOptionReturn';

export function useSidebarMenus($target) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [menus, setMenus] = useState([]);
  const [flatMenus, setFlatMenus] = useState([]);
  const [expandedMenuNames, setExpandedMenuNames] = useState([]);
  const [centerValues, setCenterValues] = useState({});

  const { getFetchUseableMenus, getFavoriteMenus } = useUseableMenus();

  const screenUrl = location.pathname?.split('/')?.[2];

  const generateCenterValues = async () => {
    const CENTER_WHOLE = await returnMasterCodesWithArray(['CENTER_WHOLE']);
    const centerMap = CENTER_WHOLE?.[0]?.map((ele) => {
      return {
        centerCode: ele?.centerCode,
        centerName: ele?.centerName,
      };
    });

    let centerObj = {};
    for (let center of centerMap) {
      if (!centerObj[center?.centerCode]) centerObj[center?.centerCode] = center?.centerName;
    }
    setCenterValues(centerObj);
  };

  const handleMenuDepthType = (option: string) => {
    localStorage.setItem('unUseMiddleMenu', option);
    setShowDropdown('');
    window.location.href = '/';
  };

  const getMenuByUserInfo = async () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    dispatch(setAuth(userInfo));
    const menus = await getFetchUseableMenus();

    let compoundedMenus = menus?.map((menu) => {
      const childMenus = menu?.childMenus?.map((child) => {
        const childrenMenus = menu?.childMenus?.map((child) => child?.childMenus)?.flat(1);

        return {
          ...child,
          expanded: childrenMenus?.find((menu) => menu?.url?.includes(screenUrl))?.parentMenuId === child?.menuId ? true : false,
        };
      });

      return {
        ...menu,
        childMenus,
        expanded: childMenus?.filter((menu) => menu?.expanded === true)?.length > 0 ? true : false,
        headerType: 'TOGGLE',
      };
    });

    // NOTE : 접근 가능한 메뉴가 없는 경우 /main/dashboard만 존재하도록 설정
    if (!compoundedMenus) {
      setLocalFetchAndFlatMenus([...staticMenu]);
      return;
    }

    compoundedMenus = [
      ...staticMenu,
      {
        menuName: MENUS.FAVORITE_KR,
        headerType: 'STATIC',
        expanded: true,
        children: [],
      },
      ...compoundedMenus,
    ];

    const fetchMenus = splitTwoDepthMenus(menus, screenUrl, expandedMenuNames);
    setLocalFetchAndFlatMenus(fetchMenus);

    const bookmarks = await getFavoriteMenus();

    bookmarks?.length > 0 && fetchMenus?.find((menu) => menu?.menuName === MENUS.FAVORITE_KR && menu?.children.push(...bookmarks));
    bookmarks?.length > 0 &&
      fetchMenus?.find((menu) => menu?.menuName === MENUS.FAVORITE_KR && compoundedMenus.find((menu) => menu?.menuName === MENUS.FAVORITE_KR && menu?.children?.push(...bookmarks)));
    setMenus($target === '2_DEPTH_MENUS' ? fetchMenus : compoundedMenus);
    setFlatMenus(fetchMenus);
  };

  return { menus, setMenus, centerValues, setCenterValues, flatMenus, expandedMenuNames, setExpandedMenuNames, getMenuByUserInfo, handleMenuDepthType, generateCenterValues };
}

export const staticMenu = [
  {
    menuName: '홈',
    headerType: '',
    expanded: true,
    children: [
      {
        url: '/dashboard',
        menuName: MENUS.DASHBOARD_KR,
        menuType: 'MENU_ACTION',
      },
      {
        url: '/notification',
        menuName: MENUS.NOTIFICATION_KR,
        menuType: 'MENU_ACTION',
      },
    ],
  },
];

/* Expected Output
[
  {
    menuName: "통관", // 1depth menuname
    headerType: 'TOGGLE', // list header의 타입 지정 - TOGGLE : 확장/축소 가능한 헤더, STATIC : 인터랙션 없는 헤더
    children: [
      {
        url: "/cargoManifestTransferList",
        name: "통관예정",
        menuId: "CARGO_MANIFEST_TRANSFER",
        screenId: "CARGO_MANIFEST_TRANSFER",
        ...
      }, ...
    ]
  }, ...
] */
export function splitTwoDepthMenus(menus: MenuDTO[], pathname?: string, expandedMenuNames?: string[]) {
  let secondDepthChildren = [];

  let collectedMenu = menus?.map((menu) => {
    menu?.childMenus && secondDepthChildren.push(menu?.childMenus);
    const isExpanded = expandedMenuNames && expandedMenuNames.filter((menuName) => menuName === menu.menuName);

    return {
      menuName: menu?.menuName,
      headerType: 'TOGGLE',
      expanded: isExpanded?.length > 0 ? true : false,
      children: [],
    };
  });

  let thirdDepthChildren = [];
  secondDepthChildren = secondDepthChildren.map((menus) => {
    const thirdDepthMenus = menus.map((menu) => {
      const feeChildMenus =
        menu.parentMenuName === '정산' &&
        menu?.childMenus &&
        menu.childMenus.map((menu) => {
          return {
            ...menu,
            menuName: `${menu.menuName} ${menu.parentMenuName}`, // —
          };
        });
      return {
        menuName: menu.parentMenuName,
        children: menu.parentMenuName !== '정산' && menu?.childMenus ? [...menu.childMenus] : feeChildMenus ? [...feeChildMenus] : [],
      };
    });
    thirdDepthChildren.push(...thirdDepthMenus);
    return thirdDepthMenus;
  });

  for (let menus of thirdDepthChildren) {
    const target = collectedMenu?.find((result) => result.menuName === menus.menuName);
    target.children.push(...menus.children);
  }

  return [
    ...staticMenu,
    {
      menuName: MENUS.FAVORITE_KR,
      headerType: 'OPTION',
      expanded: true,
      children: [],
    },
    ...collectedMenu,
  ];
}
