import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { InventoryTrackSearchDTO } from 'interface/warehouse';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { numberTestRegExp } from 'common/util/regExp';

export const SearchBox = (props: ISearchBoxProps<InventoryTrackSearchDTO>) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_MODEL: null,
    SEARCH_LOCATION: null,
    SEARCH_PLACE: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_LOCATION, searchObj));
  }, [selectTypeObj?.SEARCH_LOCATION]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_PLACE, searchObj));
  }, [selectTypeObj?.SEARCH_PLACE]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <div style={{ display: 'flex' }}>
            <Col style={{ display: 'inline-block' }}>
              <label className="col-form-label">수불일</label>
              <FlatpickrD
                value={[searchObj?.fromRegisterDate, searchObj?.toRegisterDate]}
                onReset={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      fromRegisterDate: null,
                      toRegisterDate: null,
                    };
                  });
                }}
                onChange={(value) => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      fromRegisterDate: returnDateyyyymmdd(value[0]),
                      toRegisterDate: returnDateyyyymmdd(value[1]),
                    };
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">출고/입고지</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_PLACE
                    ? {
                        value: selectTypeObj?.SEARCH_PLACE,
                        label: masterOptions?.SEARCH_PLACE_OBJ[selectTypeObj?.SEARCH_PLACE],
                      }
                    : null
                }
                options={masterOptions?.SEARCH_PLACE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_PLACE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_PLACE}
                value={searchObj?.[selectTypeObj?.SEARCH_PLACE] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_PLACE]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">입고/출고 로케이션</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_LOCATION
                    ? {
                        value: selectTypeObj?.SEARCH_LOCATION,
                        label: masterOptions?.SEARCH_LOCATION_OBJ[selectTypeObj?.SEARCH_LOCATION],
                      }
                    : null
                }
                options={masterOptions?.SEARCH_LOCATION}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_LOCATION: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_LOCATION}
                value={searchObj?.[selectTypeObj?.SEARCH_LOCATION] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_LOCATION]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            isMulti
            value={searchObj?.partnerSeqs?.map((ele) => {
              return {
                value: ele,
                label: masterOptions?.SELLER_OBJ?.[ele],
              };
            })}
            options={masterOptions?.SELLER}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeqs: (option as OptionItem[]).map((ele) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">재고상태</label>
          <SelectD
            isMulti
            value={searchObj?.availableStatuses?.map((ele) => {
              return {
                value: ele,
                label: masterOptions?.INV_AVAIL_STAT_OBJ?.[ele],
              };
            })}
            options={masterOptions?.INV_AVAIL_STAT}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                availableStatuses: (option as OptionItem[]).map((ele) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품/제품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: masterOptions?.SEARCH_MODEL_OBJ[selectTypeObj?.SEARCH_MODEL],
                      }
                    : null
                }
                options={masterOptions?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">재고번호</label>
          <InputD
            value={searchObj?.inventorySeq || ''}
            onChange={(e) => {
              if (numberTestRegExp(e.target.value)) {
                setSearchObj({
                  ...searchObj,
                  inventorySeq: Number(e.target.value),
                });
              } else {
                alert('숫자만 입력가능합니다!');
              }
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">작업지시번호</label>
          <InputD
            value={searchObj?.tableSeq || ''}
            onChange={(e) => {
              if (numberTestRegExp(e.target.value)) {
                setSearchObj({
                  ...searchObj,
                  tableSeq: Number(e.target.value),
                });
              } else {
                alert('숫자만 입력가능합니다!');
              }
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">시리얼번호</label>
          <InputD
            value={searchObj?.serialNumber || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                serialNumber: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">수불유형</label>
          <SelectD
            isMulti
            value={searchObj?.trackTypes?.map((ele) => {
              return {
                value: ele,
                label: masterOptions?.INV_TRACK_TYPE_OBJ?.[ele],
              };
            })}
            options={masterOptions?.INV_TRACK_TYPE}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                trackTypes: (option as OptionItem[]).map((ele) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">유사검색여부</label>
          <div className="radio-select-area">
            <span
              className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: true,
                  };
                });
              }}
            >
              유사검색
            </span>
            <span
              className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: false,
                  };
                });
              }}
            >
              일치검색
            </span>
          </div>
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
