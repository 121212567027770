import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { LocationListDTO, LocationSearchDTO } from 'interface/warehouse';
import useGridButton from 'hooks/grid/useGridButton';
import { DetailPopup, IDetailObj } from './component/detailPopup';
import { colorWhatModi } from 'common/util/grid';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { WAREHOUSELOCATIONFORM } from 'envVar2';
import { serviceStore } from 'services/services';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import { IPrint, Print } from './component/print';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [detailObj, setDetailObj] = useState<IDetailObj>();

  const popupDetail = (e: IGrid.ButtonClickEvent) => {
    setDetailObj({
      visible: true,
      item: e.item,
      searchObj: e.item.searchObj,
      fetchFn: (searchObj: LocationSearchDTO) => wrappedFetchList(searchObj),
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupDetail,
      },
    },
    {
      headerText: '창고코드',
      dataField: 'centerCode',
    },
    {
      headerText: '창고이름',
      dataField: 'centerName',
    },
    {
      headerText: '로케이션코드',
      dataField: 'locationCode',
    },
    {
      headerText: '행코드',
      dataField: 'columnCode',
    },
    {
      headerText: '열코드',
      dataField: 'rowCode',
    },
    {
      headerText: '층코드',
      dataField: 'floorCode',
    },
    {
      headerText: '로케이션 유형',
      dataField: 'locationTypeKr',
    },
    {
      headerText: '로케이션 구분',
      dataField: 'locationCategoryKr',
      width: 120,
    },
    {
      headerText: '로케이션 일련번호',
      dataField: 'locationSeq',
      width: 100,
    },
    {
      headerText: '로케이션 규격',
      dataField: 'locationSizeKr',
      width: 100,
    },
    {
      headerText: '입출고설정', //이전 : 설정방식
      dataField: 'settingsKr',
      width: 500,
    },
    {
      headerText: '피킹할당여부',
      dataField: 'pickFlagKr',
    },
    {
      headerText: '운영여부',
      dataField: 'useFlagKr',
    },
  ];

  const labellingKr = (data: PagingListDTO<LocationListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        settingsKr: row.settings?.map((ele) => MASTER_OBJ?.LOCATION_SETTING?.[ele]).join(', '),
        settingsStr: JSON.stringify(row.settings),
        pickFlagKr: MASTER_OBJ?.PICK_FLAG?.[row.pickFlag + ''],
        useFlagKr: MASTER_OBJ?.USE_FLAG?.[row.useFlag + ''],
        locationTypeKr: MASTER_OBJ?.LOCATION_TYPE?.[row.locationType],
        locationSizeKr: MASTER_OBJ?.LOCATION_SIZE?.[row.locationSize],
        locationCategoryKr: MASTER_OBJ?.LOCATION_CATEGORY?.[row.locationCategory],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: LocationSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.warehouseAction(`location/list`, 'GET', searchObj, null, true))?.data as PagingListDTO<LocationListDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const validationForFetchList = (searchObj: LocationSearchDTO) => {
    if (!searchObj?.centerCode) {
      alert('창고는 필수 검색자입니다!');
      return false;
    }
    return true;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      centerCode: '',
    },
    gridRef,
    fetchPaging,
    validationForFetchList,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    const currentGridItems = gridRef?.current?.getGridData();

    if (currentGridItems?.length < 1) {
      alert('다운로드할 데이터가 없습니다!');
      return;
    }

    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction('location/list/excel', 'GET', searchObj, null))?.data as LocationListDTO[],
      },
      true,
    );
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '로케이션조정' });

    dispatch(setLoading(null));
  };
  const printModalOpen = (items) => {
    setBarcodePrintObj({
      type: 'locationCode',
      visible: true,
      items,
    });
  };

  const handleCheckItems = (id) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (id === 'WAREHOUSELOCATION_PRINT') {
      printModalOpen(items);
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;

    if (id === 'WAREHOUSELOCATIONFORM') {
      window.open(WAREHOUSELOCATIONFORM);
      return;
    }

    if (!searchObj?.centerCode) {
      alert('창고를 선택해주세요!');
    } else {
      if (id === 'WAREHOUSELOCATIONREGISTER') {
        setDetailObj({
          visible: true,
          item: null,
          searchObj: JSON.stringify(searchObj),
          fetchFn: (searchObj: LocationSearchDTO) => wrappedFetchList(searchObj),
        });
      } else if (id === 'WAREHOUSELOCATIONEXCELDOWNLOAD') {
        downloadExcel('main');
      } else if (id === 'WAREHOUSELOCATIONFORMUPLOAD' || id === 'WAREHOUSELOCATIONFORMUPLOADMOD') {
        uploaderClick(id);
      } else {
        handleCheckItems(id);
      }
    }
  };

  const [barcodePrintObj, setBarcodePrintObj] = useState<IPrint>();

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const KRTOVALUE = {
    locationType: 'LOCATION_TYPE',
    locationCategory: 'LOCATION_CATEGORY',
    locationSize: 'LOCATION_SIZE',
    pickFlag: 'PICK_FLAG',
    useFlag: 'USE_FLAG',
    centerCode: 'CENTER_WHOLE',
    settings: (settings) => {
      if (settings === '') return [];
      const settingsArr = settings?.split(',');
      return settingsArr?.map((ele: string) => MASTER_OBJ?.LOCATION_SETTING?.[ele.trim()]);
    },
  };

  const EXCELHEADER = {
    WAREHOUSELOCATIONFORMUPLOAD: ['centerCode', 'locationCode', 'columnCode', 'rowCode', 'floorCode', 'locationType', 'locationCategory', 'locationSize', 'pickFlag'],
    WAREHOUSELOCATIONFORMUPLOADMOD: ['centerCode', 'locationCode', '', '', '', 'locationType', 'locationCategory', 'locationSize', 'pickFlag'],
  };

  const uploadExcel = async (dataDTO, id) => {
    let action = 'location/excel';
    action += id === 'WAREHOUSELOCATIONFORMUPLOAD' ? '/create' : id === 'WAREHOUSELOCATIONFORMUPLOADMOD' ? '/update' : '';

    if (action?.split('/')?.length === 2) {
      alert('기능 버튼 ID를 확인해주세요.');
      return;
    }
    const rs = await serviceStore?.warehouseAction(action, 'POST', null, dataDTO);
    if (rs?.status === 200) {
      alert(rs?.data?.message ?? `로케이션 ${id === 'WAREHOUSELOCATIONFORMUPLOAD' ? '등록' : '수정'}에 성공하였습니다!`);
      wrappedFetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE });

  useEffect(() => {
    if (parsedData) {
      if (parsedData?.data?.length > 0) {
        uploadExcel(parsedData?.data, parsedData?.id);
      } else {
        alert('읽혀진 값이 없습니다');
      }
    }
  }, [parsedData]);

  return (
    <div className="page-content">
      {barcodePrintObj?.visible && <Print printObj={barcodePrintObj} setPrintObj={setBarcodePrintObj} />}
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox
          gridRef={excelGridRef}
          columns={colorWhatModi(
            columns.filter((_, idx) => idx > 1),
            EXCELHEADER?.WAREHOUSELOCATIONFORMUPLOADMOD,
          )}
        />
      </div>
    </div>
  );
};

export default Index;
