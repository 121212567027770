import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { ZipcodeGroupSearchDTO } from 'pages/ETC/_interface/install';
import { RAWDATA } from 'redux/services/menuSlice';

export const SearchBox = (props: ISearchBoxProps<ZipcodeGroupSearchDTO>) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    fetchList(searchObj);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <label className="col-form-label">인프라 그룹</label>
          <SelectD
            value={
              searchObj?.infraSeq
                ? {
                    value: searchObj?.infraSeq,
                    label: MASTER_OBJ?.INFRA?.[searchObj?.infraSeq],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                infraSeq: (option as OptionItem)?.value,
              });
            }}
            options={MASTER_OPS?.INFRA}
          />
        </Col>
        <Col>
          <label className="col-form-label">상위창고</label>
          <SelectD
            value={
              searchObj?.parentCenterCode
                ? {
                    value: searchObj?.parentCenterCode,
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.parentCenterCode],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                parentCenterCode: (option as OptionItem)?.value,
              });
            }}
            options={MASTER_OPS?.CENTER_AUTH}
          />
        </Col>
        <Col>
          <label className="col-form-label">하위창고</label>
          <SelectD
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: (option as OptionItem)?.value,
              });
            }}
            options={MASTER_OPS?.CENTER_AUTH}
          />
        </Col>
        <Col>
          <label className="col-form-label">권역명(시도)</label>
          <SelectD
            value={
              searchObj?.province
                ? {
                    value: searchObj?.province,
                    label: searchObj?.province,
                  }
                : null
            }
            onChange={(option: OptionItem) => {
              setSearchObj({
                ...searchObj,
                province: option?.value !== null ? option?.label : null,
                city: null,
              });
            }}
            options={MASTER_OPS?.PROVINCE}
          />
        </Col>
        <Col>
          <label className="col-form-label">그룹명(시군구)</label>
          <SelectD
            isDisabled={!searchObj?.province}
            placeholder="시도를 먼저 선택해주세요"
            value={
              searchObj?.city
                ? {
                    value: searchObj?.city,
                    label: searchObj?.city,
                  }
                : null
            }
            onChange={(option: OptionItem) => {
              setSearchObj({
                ...searchObj,
                city: option?.value !== null ? option?.label : null,
              });
            }}
            options={MASTER_OPS?.PROVINCE?.filter((ele) => ele.label === searchObj?.province)?.[0]?.children}
          />
        </Col>
        <Col>
          <label className="col-form-label">기사명</label>
          <InputD
            value={searchObj?.driverName || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                driverName: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'right' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
