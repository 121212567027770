export const isServer = (where: 'lfs-body.' | 'lfs.' | 'lfs-temp.' | 'lfs-dev') => {
  let flag = false;
  const url = process.env.REACT_APP_API_URL;
  if (url.includes('lfs-dev')) {
    flag = true;
  } else if (url.includes(where)) {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
};

export const isAuthEnv = () => {
  let flag = false;
  const apiUrl = process.env.REACT_APP_API_URL;
  if (apiUrl.includes('lfs-dev')) {
    flag = true;
  } else if (apiUrl.includes('lfs-temp')) {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
};

export const urlServer = () => {
  const url = process.env.REACT_APP_API_URL;
  if (url.includes('lfs-dev')) {
    return 'dev';
  } else if (url?.includes('lfs-body')) {
    return 'body';
  } else if (url?.includes('lfs-temp')) {
    return 'temp';
  } else if (url?.includes('lfs')) {
    return 'lfs';
  }
  return '';
};

export const justServer = (where: 'lfs-dev.' | 'lfs-body.' | 'lfs.' | 'lfs-temp.') => {
  let flag = false;
  const url = process.env.REACT_APP_API_URL;
  if (url.includes(where)) {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
};

export const exceptServer = (where: 'lfs-body.' | 'lfs.' | 'lfs-temp.') => {
  let flag = false;
  const url = process.env.REACT_APP_API_URL;
  if (url.includes('lfs-dev')) {
    flag = true;
  } else if (url.includes(where)) {
    flag = false;
  } else {
    flag = true;
  }
  return flag;
};
