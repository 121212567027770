import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { CollectInspectSearchDTO } from '../../../../_interface/warehouse';
import { OptionItem } from 'common/master/codeMasterReturnHelper';

export const SearchBox = (props: ISearchBoxProps<CollectInspectSearchDTO>) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">회수번호</label>
          <InputD
            type="number"
            value={searchObj?.collectSeq || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                collectSeq: Number(e.target.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <Row>
            <SelectD
              value={
                searchObj?.partnerSeq
                  ? {
                      value: searchObj?.partnerSeq,
                      label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[searchObj?.partnerSeq],
                    }
                  : null
              }
              options={MASTER_OPS?.SELLER_SELLER_AUTH}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: (option as OptionItem).value,
                });
              }}
            />
          </Row>
        </Col>
        <Col>
          <label className="col-form-label">입고창고</label>
          <SelectD
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                  }
                : null
            }
            options={MASTER_OPS?.CENTER_AUTH}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">검수상태</label>
          <SelectD
            value={
              searchObj?.inspectStatus
                ? {
                    value: searchObj?.inspectStatus,
                    label: MASTER_OBJ?.COLLECT_INSPECT_STATUS?.[searchObj?.inspectStatus],
                  }
                : null
            }
            options={MASTER_OPS?.COLLECT_INSPECT_STATUS}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                inspectStatus: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판정결과</label>
          <SelectD
            value={
              searchObj?.result
                ? {
                    value: searchObj?.result,
                    label: MASTER_OBJ?.INSPECT_FLAG?.[searchObj?.result + ''],
                  }
                : null
            }
            options={MASTER_OPS?.INSPECT_FLAG}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                result: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
