import { useEffect, useRef } from 'react';
import * as IGrid from 'aui-grid';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import CommonModalNew from 'components/modal/commonModalNew';
import useSearchGridPagingNew from 'hooks/grid/useGridPagingNew';
import { serviceStore } from 'services/services';
import { OrderSearchDTO, OrderSimpleDTO } from '../../../../ORDER/1order/orderListTab/_interface/order';
import { Col, Form, Row } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setSearchObj } from 'redux/services/tabSlice';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { PagingListDTO } from 'interface/util';

export interface IOrders {
  visible?: boolean;
  returns?: OrderSimpleDTO;
}

const ORDER_MODAL_ID = 'ORDERREGIT_FIND_ORDER_MODAL';
export const Orders = ({ orderObj, setOrderObj }) => {
  const searchObj = useSelector((state: RootState) => state.tab)?.searchObj?.[ORDER_MODAL_ID];
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const gridRef = useRef<IPagingGrid>();
  const columns: IGrid.Column[] = [
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '판매채널',
      dataField: 'salesChannelName',
    },
    {
      headerText: '주문인명',
      dataField: 'customerName',
    },
    {
      headerText: '주문인 주소',
      dataField: 'customerAddrKr',
    },
    {
      headerText: '주문인 연락처',
      dataField: 'customerPhoneKr',
    },
  ];

  const labellingKr = (data: PagingListDTO<any>) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        customerAddrKr: `${row.customerAddr1} ${row.customerAddr2}`,
        customerPhoneKr: row.customerPhone || row.customerPhone2,
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: OrderSearchDTO) => {
    console.log(searchObj);
    const dataKr = labellingKr((await serviceStore.orderAction(`simple/paging`, 'GET', searchObj, null, true))?.data);
    console.log(dataKr);
    return dataKr;
  };
  const { initFetch, initSearchObj } = useSearchGridPagingNew({
    tabId: ORDER_MODAL_ID,
    gridRef,
    fetchPaging,
  });

  useEffect(() => {
    initSearchObj({
      pageNo: 1,
      pageSize: 200,
    });
  }, []);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      initFetch(searchObj);
    }
  };

  const handleSearchObj = (id: string, value: any) => {
    dispatch(
      setSearchObj({
        tabId: ORDER_MODAL_ID,
        searchObj: {
          ...searchObj,
          [id]: value,
        },
      }),
    );
  };

  const handleRadio = () => {
    const checked = gridRef?.current?.getCheckedRowItemsAll();
    console.log(checked);
    if (checked?.length > 0) {
      setOrderObj({
        ...orderObj,
        returns: checked[0],
      });
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  return (
    <CommonModalNew
      title={'주문 검색'}
      visible={orderObj?.visible}
      setVisible={() => {
        setOrderObj(null);
      }}
      rightTitle={
        <button
          className="btn btn-secondary"
          onClick={() => {
            handleRadio();
          }}
        >
          선택
        </button>
      }
      children={
        <>
          <Form onKeyPress={onKeyPress}>
            <Row style={{ marginBottom: 20 }}>
              <Col>
                <label className="col-form-label">주문번호</label>
                <InputD
                  value={searchObj?.orderSeq || ''}
                  onChange={(e) => {
                    handleSearchObj('orderSeq', e.target.value);
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">주문번호</label>
                <InputD
                  value={searchObj?.orderSeq || ''}
                  onChange={(e) => {
                    handleSearchObj('orderSeq', e.target.value);
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">판매채널</label>
                <SelectD
                  options={MASTER_OPS?.SALES_CHANNEL}
                  value={
                    searchObj?.salesChannelSeq
                      ? {
                          value: searchObj?.salesChannelSeq,
                          label: MASTER_OBJ?.SALES_CHANNEL?.[searchObj?.salesChannelSeq],
                        }
                      : null
                  }
                  onChange={(option) => {
                    handleSearchObj('orderSeq', (option as OptionItem)?.value);
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">주문인이름</label>
                <InputD
                  value={searchObj?.customerName || ''}
                  onChange={(e) => {
                    handleSearchObj('customerName', e.target.value);
                  }}
                />
              </Col>
              <Col style={{ display: 'flex', paddingTop: 10 }}>
                <button
                  className="btn btn-search ssm"
                  onClick={(e) => {
                    e.preventDefault();
                    initFetch(searchObj);
                  }}
                >
                  조회
                </button>
              </Col>
            </Row>
          </Form>
          <GridBox
            gridRef={gridRef}
            columns={columns}
            gridProps={{
              pageRowCount: GRID_PAGE_ROW_COUNT,
              rowCheckToRadio: true,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
            }}
          />
        </>
      }
    />
  );
};
