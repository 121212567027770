import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const COMMON_GRIDID = 'GRIDID_';
export const RAWDATA = 'RAWDATA_';
interface InitProps {
  nowTab?: {
    tabName?: string;
    tabNum?: number;
    tabId?: string;
  };
  searchObj: {
    [tabId: string]: any;
  };
  total: {
    [tabId: string]: any;
  };
  main: {
    [tabId: string]: any;
  };
}
const initialState: InitProps = {
  nowTab: {},
  //
  searchObj: null,
  total: null,
  main: null,
  //
};

export const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    clearTab: (state) => {
      state.nowTab = null;
      state.searchObj = null;
      state.main = null;
      state.total = null;
    },
    setNowTab: (state, action) => {
      state.nowTab = action.payload;
    },
    setSearchObj: (state, action: PayloadAction<{ tabId: string; searchObj: any }> | null) => {
      if (action?.payload?.tabId) {
        state.searchObj = {
          ...state?.searchObj,
          [action.payload.tabId]: action.payload?.searchObj,
        };
      }
    },
    setMain: (state, action: PayloadAction<{ tabId: string; main: any }>) => {
      if (action.payload.tabId) {
        state.main = {
          ...state.main,
          [action.payload.tabId]: action.payload?.main,
        };
      }
    },
    setTotal: (state, action: PayloadAction<{ tabId: string; total: any }>) => {
      if (action.payload.tabId) {
        state.total = {
          ...state.total,
          [action.payload.tabId]: action.payload?.total,
        };
      }
    },
  },
});

export const {
  clearTab,
  //
  setSearchObj,
  setMain,
  setTotal,
  //
  setNowTab,
} = tabSlice.actions;

export default tabSlice.reducer;
