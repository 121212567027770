import { handleFile, uploadFile } from 'common/util/photo';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { DriverDTO, DriverSearchDTO } from 'interface/install';
import { dayToFullDayFlag, OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { RAWDATA, setLoading } from 'redux/services/menuSlice';
import { defaultSeachObj } from 'hooks/grid/useSearch';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';

export interface IDetailObj {
  visible: boolean;
  item?: DriverDTO;
  searchObj?: string;
  fetchFn?: (data: DriverSearchDTO) => {};
}

interface IDetailPoup {
  detailObj: IDetailObj;
  setDetailObj: React.Dispatch<React.SetStateAction<IDetailObj>>;
}
export const DetailPopup = ({ detailObj, setDetailObj }: IDetailPoup) => {
  const dispatch = useDispatch();
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);

  const defaultSearchFilter = useMemo(() => {
    return {
      centerCode: reduxUserInfo['centerCode'], // 소속창고
    };
  }, [reduxUserInfo]);

  const [driverInfo, setDriverInfo] = useState<DriverDTO>(defaultSearchFilter);
  const [dayOfweek, setDayOfweek] = useState<string[]>([]);
  const [filteredCity, setFilteredCity] = useState<OptionItem[]>([]);

  useEffect(() => {
    if (detailObj?.item) {
      fetchDetail(detailObj?.item?.userId);
    }
  }, []);

  useEffect(() => {
    if (driverInfo?.province) {
      const city = MASTER_OPS?.[`${RAWDATA}PROVINCE`]?.filter((ele) => ele.code === driverInfo?.province)[0].subCodes;
      setFilteredCity(
        city?.map((ele) => {
          return {
            value: ele.code,
            label: ele.codeName,
          };
        }) || [],
      );
    } else {
      setFilteredCity([]);
    }
  }, [driverInfo?.province]);

  const fetchDetail = async (userId: string) => {
    dispatch(setLoading('GET'));
    const data = (await serviceStore?.installAction(`driver/${userId}`, 'GET', null))?.data;
    if (data) {
      data.duration = data.amDuration + data.pmDuration;
      setDriverInfo(data);
      const dayOfweek = [];
      MASTER_OPS?.WEEK_DAY?.forEach((ele) => {
        if (data[dayToFullDayFlag[ele.value]]) dayOfweek.push(ele.value);
      });
      setDayOfweek(dayOfweek);
    }
    dispatch(setLoading(null));
  };

  const handleDaumPost = () => {
    new (window as any).daum.Postcode({
      oncomplete: function (data) {
        const { zonecode, roadAddress, jibunAddress } = data;
        setDriverInfo({
          ...driverInfo,
          zipCode: zonecode,
          address: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
        });
      },
    }).open();
  };

  const handleFileInput = async (e) => {
    const ret = await handleFile(e);
    const uploadUrl = await uploadFile(ret.file, ret.url);
    if (ret) {
      setDriverInfo((prev) => {
        return {
          ...prev,
          profileImage: uploadUrl,
        };
      });
    }
  };

  const validation = (driverInfo: DriverDTO, weekend: string[]) => {
    if (!driverInfo?.driverName) {
      alert('이름은 필수값입니다!');
      return false;
    }
    if (!driverInfo?.phone1) {
      alert('연락처는 필수값입니다!');
      return false;
    }
    if (!driverInfo?.driverType) {
      alert('타입/직급은 필수값입니다!');
      return false;
    }
    if (!driverInfo?.carType) {
      alert('트럭타입은 필수값입니다!');
      return false;
    }
    if (!driverInfo?.memberCount) {
      alert('설치팀은 필수값입니다!');
      return false;
    }
    if (!driverInfo?.carNumber) {
      alert('차량번호는 필수값입니다!');
      return false;
    }
    if (!driverInfo?.partnerSeq) {
      alert('위탁사는 필수값입니다!');
      return false;
    }
    if (!driverInfo?.centerCode) {
      alert('소속창고는 필수값입니다!');
      return false;
    }
    if (weekend?.length === 0) {
      alert('근무가능일은 필수값입니다!');
      return false;
    }

    return true;
  };

  const makeData = (driverInfo: DriverDTO, weekend: string[]) => {
    const data: DriverDTO = JSON.parse(JSON.stringify(driverInfo));
    data.duration = data.amDuration + data.pmDuration;
    MASTER_OPS?.WEEK_DAY?.forEach((ele: OptionItem) => {
      data[dayToFullDayFlag[ele.value]] = weekend.includes(ele.value) ? true : false;
    });
    return data;
  };

  const saveAPI = async (driverInfo: DriverDTO, weekend: string[]) => {
    if (validation(driverInfo, weekend)) {
      const data = makeData(driverInfo, weekend);
      const rs = !detailObj?.item ? await serviceStore?.userAction(`driver/save`, 'POST', null, data) : await serviceStore?.userAction(`driver/update`, 'PUT', null, data);

      if (rs?.status === 200) {
        alert('기사 정보를 저장하였습니다!');
        let searchObj = defaultSeachObj;
        if (detailObj?.searchObj) searchObj = JSON.parse(detailObj?.searchObj);
        detailObj.fetchFn(searchObj);
        setDetailObj(null);
      }
    }
  };

  const faceArea: CSSProperties = {
    display: 'flex',
    width: '180px',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const faceAreaImg: CSSProperties = {
    objectFit: 'cover',
    width: 170,
    height: 170,
    borderRadius: 90,
    backgroundColor: '#eff2f7',
    border: '1px solid #e0e0e0',
  };

  return (
    <CommonModalNew
      title={`기사 ${!detailObj?.item ? '등록' : '상세보기'}`}
      style={{ width: 1300 }}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      children={
        <div style={{ minHeight: 550, pointerEvents: detailObj?.fetchFn ? 'unset' : 'none' }}>
          <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
            <thead>
              <tr>
                <th colSpan={12}>1. 기본정보</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2} rowSpan={6} style={{ padding: 0, paddingTop: 10 }}>
                  <div style={faceArea}>
                    <img src={driverInfo?.profileImage} style={faceAreaImg} alt="" />
                    {detailObj?.item && (
                      <button style={{ margin: '10px 0' }} className="btn btn-photo-add sm">
                        <input id="profile-photo" type="file" accept="image/*" onChange={(e) => handleFileInput(e)} />
                        사진 {driverInfo?.profileImage ? '수정' : '추가'}
                      </button>
                    )}
                  </div>
                </td>
                <th className="b">기사 코드</th>
                <td colSpan={2}>
                  <div style={{ display: 'flex' }}>
                    <InputD style={{ width: 50 }} readOnly value={driverInfo?.driverType || ''} disabled={true} />
                    <InputD value={driverInfo?.userId || ''} pattern="[0-9]+" disabled={true} readOnly placeholder="자동생성" />
                  </div>
                </td>
                <th>상태</th>
                <td colSpan={2}>
                  <SelectD
                    value={
                      driverInfo?.useFlag + ''
                        ? {
                            value: driverInfo?.useFlag,
                            label: MASTER_OBJ?.USE_FLAG?.[driverInfo?.useFlag + ''],
                          }
                        : null
                    }
                    options={MASTER_OPS?.USE_FLAG}
                    onChange={(option) => {
                      setDriverInfo({
                        ...driverInfo,
                        useFlag: (option as OptionItem).value,
                      });
                    }}
                  />
                </td>
                <th>*타입/직급</th>
                <td colSpan={3}>
                  <div style={{ display: 'flex' }}>
                    <SelectD
                      value={
                        driverInfo?.driverType
                          ? {
                              value: driverInfo?.driverType,
                              label: MASTER_OBJ?.DRV_TYPE?.[driverInfo?.driverType],
                            }
                          : null
                      }
                      options={MASTER_OPS?.DRV_TYPE}
                      onChange={(option) => {
                        setDriverInfo({
                          ...driverInfo,
                          driverType: (option as OptionItem)?.value,
                        });
                      }}
                    />
                    <SelectD
                      isDisabled={!driverInfo?.driverType}
                      value={
                        driverInfo?.position
                          ? {
                              value: driverInfo?.position,
                              label: MASTER_OBJ?.DRV_POS?.[driverInfo?.position],
                            }
                          : null
                      }
                      options={MASTER_OPS?.DRV_POS}
                      onChange={({ value }) => {
                        setDriverInfo({
                          ...driverInfo,
                          position: value,
                        });
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th className="b">*이름(한글)</th>
                <td colSpan={2}>
                  <InputD
                    value={driverInfo?.driverName || ''}
                    onChange={(e) => {
                      setDriverInfo({
                        ...driverInfo,
                        driverName: e.target.value,
                      });
                    }}
                  />
                </td>
                <th>*연락처</th>
                <td colSpan={2}>
                  <InputD
                    value={driverInfo?.phone1 || ''}
                    onChange={(e) => {
                      setDriverInfo({
                        ...driverInfo,
                        phone1: e.target.value,
                      });
                    }}
                  />
                </td>
                <th>부기사</th>
                <td colSpan={3}>
                  <div style={{ display: 'flex' }}>
                    <SelectD
                      value={
                        driverInfo?.assistUserId
                          ? {
                              value: driverInfo?.assistUserId,
                              label: MASTER_OBJ?.DRIVER_AUTH?.[driverInfo?.assistUserId],
                            }
                          : null
                      }
                      options={MASTER_OPS?.DRIVER_AUTH}
                      onChange={(option) => {
                        setDriverInfo({
                          ...driverInfo,
                          assistUserId: (option as OptionItem)?.value,
                        });
                      }}
                    />
                    <InputD disabled value={driverInfo?.assistUserId || ''} />
                  </div>
                </td>
              </tr>
              <tr>
                <th>*트럭타입</th>
                <td colSpan={2}>
                  <SelectD
                    value={
                      driverInfo?.carType
                        ? {
                            value: driverInfo?.carType,
                            label: MASTER_OBJ?.CAR_TYPE?.[driverInfo?.carType],
                          }
                        : null
                    }
                    options={MASTER_OPS?.CAR_TYPE}
                    onChange={({ value }) => {
                      setDriverInfo({
                        ...driverInfo,
                        carType: value,
                      });
                    }}
                  />
                </td>
                <th>*설치팀</th>
                <td colSpan={2}>
                  <SelectD
                    value={
                      driverInfo?.memberCount
                        ? {
                            value: driverInfo?.memberCount,
                            label: MASTER_OBJ?.TEAM_TYPE?.[driverInfo?.memberCount],
                          }
                        : null
                    }
                    options={MASTER_OPS?.TEAM_TYPE}
                    onChange={(option) => {
                      setDriverInfo({
                        ...driverInfo,
                        memberCount: (option as OptionItem).value,
                      });
                    }}
                  />
                </td>
                <th>동행</th>
                <td colSpan={3}>
                  <div style={{ display: 'flex' }}>
                    <SelectD
                      value={
                        driverInfo?.internUserId
                          ? {
                              value: driverInfo?.internUserId,
                              label: MASTER_OBJ?.DRIVER_AUTH?.[driverInfo?.internUserId],
                            }
                          : null
                      }
                      options={MASTER_OPS?.DRIVER_AUTH}
                      onChange={({ value }) => {
                        setDriverInfo({
                          ...driverInfo,
                          internUserId: value,
                        });
                      }}
                    />
                    <InputD disabled value={driverInfo?.internUserId || ''} />
                  </div>
                </td>
              </tr>
              <tr>
                <th>소속</th>
                <td colSpan={2}>
                  <SelectD
                    value={
                      driverInfo?.party
                        ? {
                            value: driverInfo?.party,
                            label: MASTER_OBJ?.DRIVER_PARTY?.[driverInfo?.party],
                          }
                        : null
                    }
                    options={MASTER_OPS?.DRIVER_PARTY}
                    onChange={(option) => {
                      setDriverInfo({
                        ...driverInfo,
                        party: (option as OptionItem).value,
                      });
                    }}
                  />
                </td>
                <th>등급</th>
                <td colSpan={2}>
                  <SelectD
                    value={
                      driverInfo?.teamGrade
                        ? {
                            value: driverInfo?.teamGrade,
                            label: MASTER_OBJ?.TEAM_GRADE?.[driverInfo?.teamGrade],
                          }
                        : null
                    }
                    options={MASTER_OPS?.TEAM_GRADE}
                    onChange={(option) => {
                      setDriverInfo({
                        ...driverInfo,
                        teamGrade: (option as OptionItem)?.value,
                      });
                    }}
                  />
                </td>
                <th colSpan={4}></th>
              </tr>
              <tr>
                <th>
                  *차량번호
                  <br />
                  (뒤4자리)
                </th>
                <td colSpan={2}>
                  <InputD
                    value={driverInfo?.carNumber || ''}
                    onChange={(e) => {
                      setDriverInfo((prev) => {
                        return {
                          ...prev,
                          carNumber: e.target.value,
                        };
                      });
                    }}
                  />
                </td>
                <th>유니폼사이즈</th>
                <td colSpan={2}>
                  <SelectD
                    value={
                      driverInfo?.uniformSize
                        ? {
                            value: driverInfo?.uniformSize,
                            label: MASTER_OBJ?.UNIFORM_SIZE?.[driverInfo?.uniformSize],
                          }
                        : null
                    }
                    options={MASTER_OPS?.UNIFORM_SIZE}
                    onChange={(option) => {
                      setDriverInfo({
                        ...driverInfo,
                        uniformSize: (option as OptionItem)?.value,
                      });
                    }}
                  />
                </td>
                <th>
                  화물운송 <br />
                  자격증번호
                </th>
                <td colSpan={3}>
                  <InputD
                    value={driverInfo?.licenseType || ''}
                    onChange={(e) => {
                      setDriverInfo((prev) => {
                        return {
                          ...prev,
                          licenseType: e.target.value,
                        };
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>우편번호</th>
                <td colSpan={2} onClick={handleDaumPost} style={{ cursor: 'pointer' }}>
                  <InputD
                    className="read-only-should-click"
                    value={driverInfo?.zipCode || ''}
                    onChange={(e) => {
                      setDriverInfo({
                        ...driverInfo,
                        zipCode: e.target.value,
                      });
                    }}
                  />
                </td>
                <th>주소</th>
                <td colSpan={3}>
                  <InputD
                    value={driverInfo?.address || ''}
                    onChange={(e) => {
                      setDriverInfo({
                        ...driverInfo,
                        address: e.target.value,
                      });
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <InputD
                    value={driverInfo?.addressDetail || ''}
                    onChange={(e) => {
                      setDriverInfo({
                        ...driverInfo,
                        addressDetail: e.target.value,
                      });
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="border-table detail-table no-td-line" style={{ width: '100%', marginBottom: 30, height: 'fit-content' }}>
            <thead>
              <tr>
                <th colSpan={12}>2. 작업정보</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colSpan={2} rowSpan={3}>
                  <div>*제품그룹</div>
                  <div style={{ padding: 10 }}>
                    <SelectD
                      isMulti
                      menuPlacement="top"
                      height100
                      selectAll={() => {
                        setDriverInfo({
                          ...driverInfo,
                          modelGroup: MASTER_OPS?.MODEL_GROUP.map((ele) => ele.value),
                        });
                      }}
                      value={driverInfo?.modelGroup?.map((ele) => {
                        return {
                          value: ele,
                          label: MASTER_OBJ?.MODEL_GROUP?.[ele],
                        };
                      })}
                      options={MASTER_OPS?.MODEL_GROUP}
                      onChange={(options) => {
                        setDriverInfo({
                          ...driverInfo,
                          modelGroup: (options as OptionItem[]).map((ele) => ele.value),
                        });
                      }}
                    />
                  </div>
                </th>
                <th>*위탁사</th>
                <td colSpan={2}>
                  <SelectD
                    value={
                      driverInfo?.partnerSeq
                        ? {
                            value: driverInfo?.partnerSeq,
                            label: MASTER_OBJ?.SELLER_NETWORK_AUTH?.[driverInfo?.partnerSeq],
                          }
                        : null
                    }
                    options={MASTER_OPS?.SELLER_NETWORK_AUTH}
                    onChange={(option) => {
                      setDriverInfo({
                        ...driverInfo,
                        partnerSeq: (option as OptionItem)?.value,
                      });
                    }}
                  />
                </td>
                <th>*소속창고</th>
                <td colSpan={2}>
                  <SelectD
                    value={
                      driverInfo?.centerCode
                        ? {
                            value: driverInfo?.centerCode,
                            label: MASTER_OBJ?.CENTER_AUTH?.[driverInfo?.centerCode],
                          }
                        : null
                    }
                    options={MASTER_OPS?.CENTER_AUTH}
                    onChange={({ value }) => {
                      setDriverInfo({
                        ...driverInfo,
                        centerCode: value,
                      });
                    }}
                  />
                </td>
                <th>
                  *설치 가능 Capa
                  <br />
                  (오전/오후)
                </th>
                <td colSpan={3}>
                  <div style={{ display: 'flex' }}>
                    <SelectD
                      value={
                        driverInfo?.amDuration
                          ? {
                              value: driverInfo?.amDuration,
                              label: MASTER_OBJ?.AM?.[driverInfo?.amDuration],
                            }
                          : null
                      }
                      options={MASTER_OPS?.AM}
                      onChange={({ value }) => {
                        setDriverInfo({
                          ...driverInfo,
                          amDuration: value,
                        });
                      }}
                    />
                    <SelectD
                      value={
                        driverInfo?.pmDuration
                          ? {
                              value: driverInfo?.pmDuration,
                              label: MASTER_OBJ?.PM?.[driverInfo?.pmDuration],
                            }
                          : null
                      }
                      options={MASTER_OPS?.PM}
                      onChange={(option) => {
                        setDriverInfo({
                          ...driverInfo,
                          pmDuration: (option as OptionItem).value,
                        });
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>선호지역(시/도)</th>
                <td colSpan={2}>
                  <SelectD
                    value={
                      driverInfo?.province
                        ? {
                            value: driverInfo?.province,
                            label: MASTER_OBJ?.PROVINCE?.[driverInfo?.province],
                          }
                        : null
                    }
                    options={MASTER_OPS?.PROVINCE}
                    onChange={(option) => {
                      setDriverInfo({
                        ...driverInfo,
                        province: (option as OptionItem).value,
                        city: null,
                      });
                    }}
                  />
                </td>
                <th>선호지역(구/군)</th>
                <td colSpan={2}>
                  <SelectD
                    isDisabled={!driverInfo?.province}
                    value={
                      driverInfo?.city
                        ? {
                            value: driverInfo?.city,
                            label: selectlabel(driverInfo?.city, filteredCity),
                          }
                        : null
                    }
                    options={filteredCity}
                    onChange={(option) => {
                      setDriverInfo({
                        ...driverInfo,
                        city: (option as OptionItem).value,
                      });
                    }}
                  />
                </td>
                <th>
                  총시간
                  <br />
                  (1건 = 30분)
                </th>
                <td colSpan={3}>
                  <InputD readOnly value={driverInfo?.duration ? `${driverInfo?.duration / 30}건 / ${driverInfo?.duration}분` : ''} disabled />
                </td>
              </tr>
              <tr>
                <th colSpan={3} />
                <th>도크코드</th>
                <td colSpan={2}>
                  <InputD
                    value={driverInfo?.dockNumber || ''}
                    onChange={(e) => {
                      setDriverInfo({
                        ...driverInfo,
                        dockNumber: e.target.value,
                      });
                    }}
                  />
                </td>
                <th>*근무가능일</th>
                <td colSpan={3}>
                  <SelectD
                    isMulti
                    height50
                    menuPlacement="top"
                    selectAll={() => {
                      setDayOfweek(MASTER_OPS?.WEEK_DAY?.map((ele) => ele.value));
                    }}
                    value={dayOfweek?.map((ele) => {
                      console.log(ele);
                      return {
                        value: ele,
                        label: MASTER_OBJ?.WEEK_DAY?.[ele],
                      };
                    })}
                    options={MASTER_OPS?.WEEK_DAY}
                    onChange={(options) => {
                      setDayOfweek((options as OptionItem[])?.map((ele) => ele.value));
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ textAlign: 'right' }}>
            {!detailObj?.item ? (
              <button
                className="btn btn-secondary sm"
                onClick={() => {
                  saveAPI(driverInfo, dayOfweek);
                }}
              >
                등록
              </button>
            ) : (
              <>
                {detailObj?.fetchFn && (
                  <button
                    className="btn btn-secondary sm"
                    onClick={() => {
                      saveAPI(driverInfo, dayOfweek);
                    }}
                  >
                    수정
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      }
    />
  );
};
