import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import GridBox from 'common/grid/gridBox';
import CommonModalNew from 'components/modal/commonModalNew';

import { ZipcodeGroupDTOForDriverAdd, ZipcodeGroupSearchDTO } from 'pages/ETC/_interface/install';
import { IZipcodeGroupListDTOExtends } from '..';
import { DetailPopup, IDetailObj } from 'pages/ETC/1driver/driverListTab/driverList/component/detailPopup';

export interface IDriverManageObj {
  visible: boolean;
  mode: string;
  searchObj: ZipcodeGroupSearchDTO;
  fetchFn: (searchObj: ZipcodeGroupSearchDTO) => {};
  data?: IZipcodeGroupListDTOExtends | IZipcodeGroupListDTOExtends[];
}

export const DriverManageModal = ({ driverManageObj, setDriverManageObj }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridListRef = useRef<AUIGrid>();
  const gridMainRef = useRef<AUIGrid>();
  const [chooseZipcodeGroup, setChooseZipcodeGroup] = useState<ZipcodeGroupDTOForDriverAdd>();
  const [driverDetailObj, setDriverDetailObj] = useState<IDetailObj>(); //driverDetail
  const onDriverClick = (e: IGrid.ButtonClickEvent) => {
    if (e.columnIndex < 3) {
      const userId = e.dataField === 'driverName' ? e.item?.userId : e.dataField === 'assistantDriverName' ? e.item?.assistUserId : e.item.internUserId;
      if (userId) {
        setDriverDetailObj({
          visible: true,
          item: { userId },
        });
      }
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '주기사',
      dataField: 'driverName',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: onDriverClick,
      },
    },
    {
      headerText: '부기사',
      dataField: 'assistantDriverName',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: onDriverClick,
      },
    },
    {
      headerText: '동행',
      dataField: 'internDriverName',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: onDriverClick,
      },
    },
    {
      headerText: '근무요일',
      children: [
        {
          headerText: '월',
          dataField: 'mondayFlag',
          width: 40,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '화',
          dataField: 'tuesdayFlag',
          width: 40,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '수',
          dataField: 'wednesdayFlag',
          width: 40,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '목',
          dataField: 'thursdayFlag',
          width: 40,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '금',
          dataField: 'fridayFlag',
          width: 40,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '토',
          dataField: 'saturdayFlag',
          width: 40,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
        {
          headerText: '일',
          dataField: 'sundayFlag',
          width: 40,
          renderer: {
            type: IGrid.RendererKind.CheckBoxEditRenderer,
          },
        },
      ],
    },
  ];

  useEffect(() => {
    getDrivers(driverManageObj?.data);
    if (driverManageObj?.mode === 'edit') {
      getDriverAvailableList(driverManageObj?.data);
    } else {
      getDriverAvailableList(driverManageObj?.data[0]);
    }
  }, []);

  const getDrivers = (data) => {
    gridMainRef?.current?.setGridData(data?.drivers);
  };

  const getDriverAvailableList = async (item) => {
    const rs = await serviceStore.installAction(`zipcode/group/driver/${item.zipcodeGroupSeq}`, 'GET', null);
    if (rs?.status === 200) {
      const result = rs?.data;
      setChooseZipcodeGroup({ ...result, centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[result?.centerCode] });
      gridListRef?.current?.setGridData(result.availableDrivers);
      gridMainRef?.current?.setGridData(result.drivers);
    }
  };

  const updateDrivers = async (zipcodeGroupSeq) => {
    const addUserId = gridMainRef?.current?.getGridData()?.map((item) => item.userId);
    const deleteUserId = gridListRef?.current?.getGridData()?.map((item) => item.userId);
    const rs = await serviceStore.installAction(`zipcode/group/driver`, 'POST', null, {
      zipcodeGroupSeq,
      addUserId,
      deleteUserId,
    });
    if (rs?.status === 200) {
      alert('지정되었습니다!');
      driverManageObj?.fetchFn?.(driverManageObj?.searchObj);
      setDriverManageObj({ visible: false });
    }
  };

  const addAllDrivers = async () => {
    const addUserId = gridMainRef?.current?.getGridData()?.map((item) => item.userId);
    const rs = await serviceStore.installAction(`zipcode/group/driver/list`, 'POST', null, {
      zipcodeGroupSeq: [...new Set(driverManageObj?.data.map((item) => item.zipcodeGroupSeq))],
      addUserId,
    });
    if (rs?.status === 200) {
      alert('지정되었습니다!');
      driverManageObj?.fetchFn?.(driverManageObj?.searchObj);
      setDriverManageObj({ visible: false });
    }
  };

  const moveCheck = (to: 'main' | 'list') => {
    const listBefore = gridListRef?.current?.getGridData();
    const mainBefore = gridMainRef?.current?.getGridData();
    const listChecked = gridListRef?.current?.getCheckedRowItemsAll();
    const mainChecked = gridMainRef?.current?.getCheckedRowItemsAll();
    //
    if (to === 'main') {
      if (listChecked?.length > 0) {
        const users = listChecked?.map((ele) => ele.userId);
        const listAfter = listBefore?.filter((ele) => !users?.includes(ele?.userId));
        const toMains = listChecked?.filter((ele) => users?.includes(ele?.userId));
        console.log(listAfter, toMains);
        gridListRef?.current?.setGridData(listAfter);
        gridMainRef?.current?.setGridData([...toMains, ...mainBefore]);
      } else {
        alert('선택된 항목이 없습니다');
      }
    } else {
      if (mainChecked?.length > 0) {
        const users = mainChecked?.map((ele) => ele.userId);
        const mainAfter = mainBefore?.filter((ele) => !users?.includes(ele?.userId));
        const toList = mainChecked?.filter((ele) => users?.includes(ele?.userId));
        gridMainRef?.current?.setGridData(mainAfter);
        gridListRef?.current?.setGridData([...toList, ...listBefore]);
      } else {
        alert('선택된 항목이 없습니다');
      }
    }
  };

  return (
    <>
      <CommonModalNew
        style={{ width: 1300 }}
        title={`설치기사 ${driverManageObj?.mode === 'edit' ? '수정' : '일괄 추가'}`}
        visible={driverManageObj?.visible}
        setVisible={() => {
          setDriverManageObj({
            ...driverManageObj,
            visible: !driverManageObj?.visible,
          });
        }}
        rightTitle={
          <div>
            <button
              className="btn btn-orange ssm"
              onClick={() => {
                driverManageObj?.mode === 'edit' ? updateDrivers(chooseZipcodeGroup?.zipcodeGroupSeq) : addAllDrivers();
              }}
            >
              {driverManageObj?.mode === 'edit' ? '수정' : '추가'}
            </button>
          </div>
        }
        children={
          <>
            {driverDetailObj?.visible && <DetailPopup detailObj={driverDetailObj} setDetailObj={setDriverDetailObj} />}
            <div style={{ display: 'flex' }}>
              <GridBox
                gridRef={gridListRef}
                columns={columns}
                gridProps={{
                  selectionMode: 'multipleCells',
                  enableDrag: true,
                  enableMultipleDrag: true,
                  enableDragByCellDrag: true,
                  enableDrop: true,
                  dropToOthers: true,
                }}
              />
              <div className="grid-button-area" style={{ margin: 15, flexDirection: 'column', justifyContent: 'space-evenly' }}>
                <div className="blue fake" style={{ height: 80, textAlign: 'center' }} onClick={() => moveCheck('main')}>
                  추가 ➡️
                </div>
                <div className="transparent" style={{ height: 100 }}>
                  드래그 앤 드롭
                </div>
                <div className="red fake" style={{ height: 80, textAlign: 'center' }} onClick={() => moveCheck('list')}>
                  ⬅️ 삭제
                </div>
              </div>
              <GridBox
                gridRef={gridMainRef}
                columns={columns}
                gridProps={{
                  selectionMode: 'multipleCells',
                  enableDrag: true,
                  enableMultipleDrag: true,
                  enableDragByCellDrag: true,
                  enableDrop: true,
                  dropToOthers: true,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};
