// react
import { useEffect, useRef, useState, useMemo } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { SelectD } from 'components/reactstrap/reactstrap';
// lib
import { v4 as uuidv4 } from 'uuid';
// utils
import { INVENTORY_RETURN_ROLLBACK, REMOVE_LOADING_MEMO_SAVE, REMOVE_LOADSTAT_IMG, TAKE_OUT_REMOVE_CANCEL, WMS_REMOVE_LOADING_COMPLETE } from 'envVar';
// modal
import Presenter from './presenter';
import { defaultAlertModal } from 'components/modal/_alertModal';
import { httpClient } from 'common/http-client/axiosConfig';
import { defaultBottomSheetObj } from 'components/template/bottomSheets';
import { setLoading } from 'redux/services/menuSlice';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { serviceStore } from 'services/services';

const defaultHaldangObj = {
  visible: false,
  data: null,
  rowItems: null,
};
const Container = () => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { reduxUserInfo } = useSelector((state) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);
  const [photoPopupObj, setPhotoPopupObj] = useState({
    visible: false,
    item: null,
    photos: null,
    fetchPhotosFn: (data) => fetchPhotos(data),
  });
  const [gridId, setGrid] = useState();
  const detailGridId = 'takeoutDelivery_Detail';
  const [pagingSetting, setPagingSetting] = useState({
    pageNo: 1,
    pageSize: 100,
    fetchNew: false,
  });
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const [alertModalObj, setAlertModalObj] = useState(defaultAlertModal);
  const [haldangObj, setHaldangObj] = useState(defaultHaldangObj);
  const [bottomSheetObj, setBottomSheetObj] = useState(defaultBottomSheetObj);
  const [loadingParcelObj, setLoadingParcelObj] = useState();
  // 검색 조건
  const [searchObj, setSearchObj] = useState({
    seqSearchType: null,
    seqSearchValue: null,
    dateSearchType: null,
    dateSearchValue: null,

    partnerSeq: null,
    centerCode: null,
    stockRemoveType: null,
    status: null,

    ...defaultSearchFilter,
  });

  useEffect(() => {
    if (alertModalObj.visible === false) {
      setAlertModalObj(defaultAlertModal);
    }
  }, [alertModalObj.visible]);

  // grid-function
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState();
  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_CHULGO_CONFIRM') {
        confirmChulgo(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'chulgo-confirm-parts') {
        confirmChulgoParts(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_CHULGO_CANCEL') {
        if (items.length > 1) {
          alert('한 건씩 작업 가능합니다!');
        } else {
          validCancelChulgo({ item: items[0] });
        }
      } else if (getCheckedDataTriggerThisGrid?.type === 'chulgo-cancel-parts') {
        validCancelChulgo({ item: items[0], items });
      } else if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_CHULGO_ROLLBACK') {
        if (items.length > 1) {
          alert('한 건씩 작업 가능합니다!');
        } else {
          validGoPrevStep({ item: items[0] });
        }
      } else if (getCheckedDataTriggerThisGrid?.type === 'go-prev-step-parts') {
        validGoPrevStep({ item: items[0], items });
      } else if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_CHULGO_COMPLETE') {
        if (items.length > 1) {
          alert('한 건씩 작업 가능합니다!');
        } else {
          completeChulgo(items[0]);
        }
      } else if (getCheckedDataTriggerThisGrid?.type === 'chulgo-complete-parts') {
        completeChulgoParts(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'REMOVE_CHULGO_CLOSE') {
        chulgoCloseAction(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const chulgoCloseAction = async (items) => {
    if (window.confirm('강제마감 하시겠습니까?')) {
      const rs = await serviceStore.warehouseAction(
        'remove/picking/close',
        'POST',
        null,
        items?.map((ele) => {
          return {
            seq: ele.stockRemoveSeq,
          };
        }),
      );
      if (rs?.status === 200) {
        alert('강제마감 완료되었습니다!');
        fetchList(searchObj);
      }
    }
  };

  const completeChulgo = async (item) => {
    if (item.statusKr === '출고중' || item.statusKr === '출고지시확정') {
      const result = window.confirm(`반출 출고지시번호${item.loadingStatementSeq}의 출고 완료처리를 진행하시겠습니까?`);
      if (result) {
        dispatch(setLoading('PUT'));
        await httpClient.put(WMS_REMOVE_LOADING_COMPLETE + `/${item.loadingStatementSeq}`).then((res) => {
          if (res?.status === 200) {
            alert('출고 완료처리에 성공하였습니다!');
            fetchList(searchObj);
          }
        });
        dispatch(setLoading(null));
      }
    } else {
      alert('출고중 혹은 출고지시확정 상태만 출고완료 할 수 있습니다!');
    }
  };

  const chulgoCompleteDetail = async ({ items, event, searchObj }) => {
    const filtered = items?.filter((ele) => ele.statusKr === '출고중' || ele.statusKr === '출고지시확정');
    if (filtered?.length === items?.length) {
      const data = {
        loadingStatementDetailSeqs: items?.map((ele) => ele.loadingStatementDetailSeq),
      };
      dispatch(setLoading('PUT'));
      await httpClient.put(`/warehouse/remove/loading/complete/${items[0].loadingStatementSeq}/detail`, data).then((rs) => {
        if (rs.status === 200) {
          alert('반출출고 상세 완료처리 성공하였습니다!');
          fetchList(JSON.parse(searchObj));
          fetchDetailList(event);
        }
      });

      dispatch(setLoading(null));
    } else {
      alert('출고중 혹은 출고지시확정 상태만 출고완료 할 수 있습니다!');
    }
  };

  const completeChulgoParts = async (items) => {
    const data = {
      loadingStatementDetailSeqs: [],
    };
    items.forEach((item) => {
      data.loadingStatementDetailSeqs.push(item.loadingStatementDetailSeq);
    });
    dispatch(setLoading('PUT'));

    await httpClient.put(WMS_REMOVE_LOADING_COMPLETE + `${items[0].loadingStatementSeq}/detail`, data).then((res) => {
      if (res?.status === 200) {
        alert('출고 완료처리에 성공하였습니다!');
        fetchList(searchObj);
      }
    });
    dispatch(setLoading(null));
  };

  const haldangAction = (event) => {
    const data = {
      originalCenterCode: event.item.centerCode,
      destinationCenterCode: event.item.target,
      stockRemoveDate: event.item.stockRemoveDate,
      partnerSeq: event.item.partnerSeq,
      stockRemoveType: event.item.stockRemoveType,
      locationCode: event.item.locationCode,
      centerCode: event.item.centerCode,
      target: event.item.target,
      modelStockSeq: event.item.modelStockSeq,
      status: event.item.status,
    };
    if (event.item.loadingStatementSeq) data['loadingStatementSeq'] = event.item.loadingStatementSeq;
    else data['loadFlag'] = true;
    if (event.item.stockRemoveSeq) data['stockRemoveSeq'] = event.item.stockRemoveSeq;
    setCurrentHaldangItem(data);
    setHaldangObj({
      ...haldangObj,
      visible: true,
      data,
      rowItems: event.item,
      'go-prev-step-parts': (data) => validGoPrevStep(data),
      'chulgo-cancel-parts': (data) => validCancelChulgo(data),
      'chulgo-confirm-parts': (data) => confirmChulgoParts(data),
    });
  };

  // NOTE: 할당정보 모달 호출 시 사용되는 기준 정보
  const [currentHaldangItem, setCurrentHaldangItem] = useState(null);
  // NOTE: 할당정보 액션 변경 시 searchObj가 업데이트된 경우, 할당정보 모달이 열려있을 때 정보를 갱신할 수 있도록 effect hook 적용.
  useEffect(() => {
    if (haldangObj?.visible) {
      setHaldangObj(() => {
        return {
          ...haldangObj,
          data: { ...currentHaldangItem },
        };
      });
    }
  }, [searchObj]);

  const [banchulPrintObj, setBanchulPrintObj] = useState();
  const printAction = async (event) => {
    setBanchulPrintObj({
      visible: true,
      type: 'chulgo',
      item: event?.item,
    });
  };
  const confirmChulgo = (items) => {
    const result = window.confirm('출고지시를 확정하시겠습니까?');
    if (result) {
      const arr = [];
      items.forEach((item) => {
        arr.push({
          stockRemoveSeq: item.stockRemoveSeq,
          centerCode: item.centerCode,
          target: item.target,
          stockRemoveDate: item.stockRemoveDate,
        });
      });
      comfirmChulgoAPI(arr);
    }
  };

  const confirmChulgoParts = (items) => {
    const result = window.confirm('출고지시를 확정하시겠습니까?');
    if (result) {
      const data = {
        stockRemoveSeq: items[0].stockRemoveSeq,
        centerCode: items[0].centerCode,
        target: items[0].target,
        stockRemoveDate: items[0].stockRemoveDate,
      };

      const inventorySeqs = [];
      items.forEach((item) => {
        inventorySeqs.push(item.inventorySeq);
      });

      data['inventorySeqs'] = inventorySeqs;

      comfirmChulgoAPI([data]);
    }
  };

  const comfirmChulgoAPI = async (arr) => {
    dispatch(setLoading('POST'));
    await httpClient.post('/warehouse/remove/loading/web/list', arr).then((rs) => {
      if (rs?.status === 200) {
        alert('출고지시 확정에 성공하였습니다!');
        fetchList(searchObj);
        setSearchObj({ ...searchObj });
      }
    });
    dispatch(setLoading(null));
  };

  const paramingSearchObjToUrl = (url, searchObj) => {
    if (searchObj?.seqSearchValue) url += `&${searchObj?.seqSearchType}=${searchObj?.seqSearchValue}`;
    if (searchObj?.dateSearchValue && searchObj?.dateSearchType)
      url += `&from${searchObj?.dateSearchType}=${searchObj?.dateSearchValue[0]}&to${searchObj?.dateSearchType}=${searchObj?.dateSearchValue[1]}`;

    let searchParams = {};
    let urlWithSearchParams = url;

    if (searchObj !== undefined) {
      searchParams = Object.keys(searchObj);
      for (let param of searchParams) {
        if (searchObj[param] !== null && searchObj[param] !== '')
          param === 'seqSearchValue' || param === 'seqSearchType' || param === 'dateSearchValue' || param === 'dateSearchType'
            ? (urlWithSearchParams += '')
            : (urlWithSearchParams += `&${param}=${searchObj[param]}`);
      }
    }

    return urlWithSearchParams;
  };

  const loadingParcelArr = ['SINGLE_PARCEL', 'MULTI_PARCEL'];

  const fetchList = async (searchObj) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj.visible) setBottomSheetObj(defaultBottomSheetObj);
    setRows([]);

    let url = `/warehouse/remove/beforeLoading/temp?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
    url = paramingSearchObjToUrl(url, searchObj);

    await httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        const data = rs.data.list || [];
        const maxPage = Math.ceil(rs.data.totalCount / pagingSetting?.pageSize);
        data.forEach((row) => {
          row.maxPage = maxPage;
          row.totalCount = rs.data.totalCount;
          row.searchObj = JSON.stringify(searchObj);
          row.ocenterKr = selectlabel(row.centerCode, masterOptions?.CENTER);
          row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
          row.statusKr = masterOptions?.LOADING_STAT_OBJ[row.status];
          row.loadingCarTypeKr = masterOptions?.CAR_TYPE_OBJ[row.loadingDriverCarType];
          row.shippingBill = row.confirmDatetime ? '프린트' : '';
          row.haldangInfo = '할당정보';
          row.stockRemoveTypeKr = masterOptions?.REMOVE_REASON_OBJ?.[row.stockRemoveType];
          row.loadingParcel = row.loadingStatementSeq && loadingParcelArr?.includes(row.stockRemoveType) ? '검수하기' : '';
        });
        if (pagingSetting?.fetchNew) {
          setPagingSetting({
            ...pagingSetting,
            fetchNew: false,
          });
        }
        setRows(data);
      }
    });
    dispatch(setLoading(null));
  };

  const bottomGridRef = useRef();
  const fetchDetailList = async (event) => {
    dispatch(setLoading('GET'));
    let url = `/warehouse/remove/beforeLoading/detail?`;
    if (event.item?.stockRemoveSeq) url += `&stockRemoveSeq=${event.item?.stockRemoveSeq}`;
    if (event.item?.loadingStatementSeq) url += `&loadingStatementSeq=${event.item?.loadingStatementSeq}`;
    const rs = await httpClient.get(url);
    if (rs?.status === 200) {
      const rows = rs.data;
      rows?.forEach((row) => {
        row.loadingStatementSeq = event.item.loadingStatementSeq;
        row.modelGroupKr = masterOptions?.MODEL_GRP_1_OBJ[row.modelGroupLarge];
        row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
        row.statusKr = masterOptions?.LOADING_STAT_OBJ[row.status];
      });

      const gridButtonhandler = (e) => {
        const id = e.target.id;
        const items = bottomGridRef?.current?.getCheckedRowItemsAll();
        if (id === 'excel-down') {
          bottomGridRef?.current?.exportAsXlsx({ fileName: `반출출고_상세` });
        } else {
          if (items?.length > 0) {
            if (id === 'chulgo-complete') {
              chulgoCompleteDetail({ items, event, searchObj: event.item.searchObj });
            }
          } else {
            alert('선택된 건이 없습니다!');
          }
        }
      };

      setBottomSheetObj({
        visible: true,
        hasTabs: [
          {
            title: '반출출고 상세',
            gridRef: bottomGridRef,
            columns: detailColumns,
            rows,
            buttons: [
              <div key={`chulgo-complete`} id={`chulgo-complete`} className={`orange`} onClick={gridButtonhandler}>
                반출출고 완료
              </div>,
              <div key={`excel-down`} id={`excel-down`} className={`green`} onClick={gridButtonhandler}>
                엑셀다운
              </div>,
            ],
            options: {
              isRowAllCheckCurrentView: true,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
            },
          },
        ],
      });
    }
    dispatch(setLoading(null));
  };

  const fetchPhotos = (item) => {
    if (item.loadingStatementSeq) {
      httpClient.get(REMOVE_LOADSTAT_IMG + `/${item.loadingStatementSeq}`).then((rs) => {
        if (rs?.status === 200) {
          setPhotoPopupObj((prev) => {
            return {
              ...prev,
              visible: true,
              item: item,
              photos: rs.data,
            };
          });
        }
      });
    } else {
      alert('출고 지시번호가 존재하는 건만 사진열람이 가능합니다');
    }
  };

  const loadingParcel = (e) => {
    if (e.text) {
      setLoadingParcelObj({
        visible: true,
        item: e.item,
        fetchFn: (searchObj) => fetchList(searchObj),
      });
    }
  };

  const columns = [
    {
      headerText: '상세보기',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '상세보기',
        onClick: fetchDetailList,
      },
    },
    {
      headerText: '사진보기',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '사진보기',
        onClick: (e) => fetchPhotos(e.item),
      },
    },
    {
      headerText: '출고지시서',
      dataField: 'shippingBill',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '프린트',
        onClick: printAction,
        visibleFunction: function (rowIndex, columnIndex, value, item, dataField) {
          if (item.loadingStatementSeq === null) {
            return false;
          }
          return true;
        },
      },
    },
    {
      headerText: '할당정보',
      dataField: 'haldangInfo',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        onClick: haldangAction,
      },
    },
    {
      headerText: '검수하기',
      dataField: 'loadingParcel',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        onClick: loadingParcel,
      },
    },
    {
      headerText: '피킹지시번호',
      dataField: 'stockRemoveSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고지시번호',
      dataField: 'loadingStatementSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출치예정일',
      dataField: 'stockRemoveDate',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고지시<br />확정일',
      dataField: 'initDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고완료일',
      dataField: 'loadDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '반출창고',
      dataField: 'ocenterKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '반출대상',
      dataField: 'target',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '반출유형',
      dataField: 'stockRemoveTypeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '특이사항',
      dataField: 'memo',
      headerTooltip: {
        show: true,
        tooltipHtml: `<div">
        직접 수정 가능
          </div>`,
      },
    },
    {
      headerText: '출고지시 수량<br />(피킹완료수량)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '출고완료 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'loadAvailableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'loadDefectedQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '미출고 수량<br />(지시 - 완료)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedDiff',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '출고지시<br />확정자',
      dataField: 'confirmId',
      filter: {
        showIcon: true,
      },
    },
  ];

  const detailColumns = [
    {
      headerText: '피킹지시번호',
      dataField: 'stockRemoveSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고지시번호',
      dataField: 'loadingStatementSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '출고지시 수량<br />(피킹완료 수량)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '출고완료 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'loadAvailableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'loadDefectedQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '미출고 수량<br />(지시 - 완료)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedDiff',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
      filter: {
        showIcon: true,
      },
    },

    {
      headerText: '출고완료일',
      dataField: 'loadDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고완료자',
      dataField: 'loadId',
      filter: {
        showIcon: true,
      },
    },
  ];

  const getColumns = columns.filter(
    (item, index) =>
      item.dataField === 'stockRemoveSeq' ||
      item.dataField === 'loadingStatementSeq' ||
      item.dataField === 'stockRemoveDate' ||
      item.dataField === 'initDatetime' ||
      item.dataField === 'loadDatetime' ||
      item.dataField === 'ocenterKr' ||
      item.dataField === 'target' ||
      item.dataField === 'stockRemoveTypeKr' ||
      item.dataField === 'partnerSeqKr',
  );
  const getDetailColumns = detailColumns.filter((item, index) => index > 1);
  const excelDetailColumns = [...getColumns, ...getDetailColumns];
  excelDetailColumns.push(columns.find((item, index) => item.dataField === 'confirmId'));

  useEffect(() => {
    initGrid();
  }, []);

  const initGrid = () => {
    setGrid(`takeoutworks_2delivery_${uuidv4()}`);
  };

  const validGoPrevStep = ({ item, items }) => {
    let validFlag = false;
    let content = [];

    if (item?.status === 'INIT') {
      content = ['“출고 지시대기” 상태에서는 이전단계로 되돌릴 수 없습니다.'];
      validFlag = false;
    } else if (item?.status === 'CANCEL') {
      content = ['“출고 취소” 상태에서는 이전 단계로 돌아갈 수 없습니다.'];
      validFlag = false;
    } else if (item?.status === 'LOAD') {
      content = ['“출고 완료” 상태에서는 이전 단계로 돌아갈 수 없습니다.'];
      validFlag = false;
    } else if (item?.status === 'LOADING') {
      content = ['“출고중” 상태에서 “출고지시확정” 상태로 돌아가시겠습니까?'];
      validFlag = true;
    } else if (item?.status === 'READY') {
      content = ['“출고 지시확정” 상태에서 “출고 지시대기” 상태로 돌아가시겠습니까?'];
      validFlag = true;
    }

    const _alertModalObj = {
      ...alertModalObj,
      visible: true,
      title: '이전단계',
      content,
    };

    if (validFlag) {
      _alertModalObj.yBtnTitle = '진행';
      _alertModalObj.yCallback = () => {
        goPrevStep(item, items);
      };
    }
    setAlertModalObj(_alertModalObj);
  };

  const goPrevStep = async (item, items) => {
    const data = [];
    const json = {
      stockRemoveSeq: item.stockRemoveSeq,
      loadingStatementSeq: item.loadingStatementSeq,
    };

    const inventorySeqs = [];
    if (items) {
      items.forEach((item) => {
        inventorySeqs.push(item.inventorySeq);
      });
    }
    json.inventorySeqs = inventorySeqs;
    data.push(json);

    let url = INVENTORY_RETURN_ROLLBACK;
    dispatch(setLoading('POST'));

    await httpClient.post(url, data).then((rs) => {
      if (rs.data.result) {
        setAlertModalObj({
          ...alertModalObj,
          visible: true,
          title: '이전단계 완료',
          content: [`반출출고 지시번호 ${item.stockRemoveSeq}의 상태값이 변경되었습니다.`],
        });
        fetchList(searchObj);
        setSearchObj({ ...searchObj });
      }
    });
    dispatch(setLoading(null));
  };

  const validCancelChulgo = ({ item, items }) => {
    let validFlag = false;
    let content = [];

    if (item?.status === 'INIT') {
      content = [
        '반출출고 작업을 취소하시겠습니까?',
        '※ 취소할 경우 다시 되돌릴 수 없으니 신중히 선택해주세요',
        <Row>
          <Col>
            <label className="col-form-label">취소사유</label>
            <SelectD
              onChange={(option) => {
                item.holdType = option.value;
              }}
              options={masterOptions?.ORDER_HOLD_TYPE}
            />
          </Col>
          <Col>
            <label className="col-form-label">메모</label>
            <Input
              onChange={(e) => {
                item.holdNote = e.target.value;
              }}
            />
          </Col>
        </Row>,
      ];
      validFlag = true;
    } else if (item?.status === 'READY') {
      content = [
        '반출출고 작업을 취소하시겠습니까?',
        '※ 취소할 경우 다시 되돌릴 수 없으니 신중히 선택해주세요',
        <Row>
          <Col>
            <label className="col-form-label">취소사유</label>
            <SelectD
              onChange={(option) => {
                item.holdType = option.value;
              }}
              options={masterOptions?.ORDER_HOLD_TYPE}
            />
          </Col>
          <Col>
            <label className="col-form-label">직접입력</label>
            <Input
              onChange={(e) => {
                item.holdNote = e.target.value;
              }}
            />
          </Col>
        </Row>,
      ];
      validFlag = true;
    } else if (item?.status === 'LOAD') {
      content = ['“출고 완료” 상태에서는 반출출고을 취소할 수 없습니다'];
      validFlag = false;
    } else if (item?.status === 'LOADING') {
      content = ['“출고 중” 상태에서는 반출출고을 취소할 수 없습니다'];
      validFlag = false;
    } else if (item?.status === 'CANCEL') {
      content = ['이미 출고 취소된 건 입니다'];
      validFlag = false;
    }

    const _alertModalObj = {
      ...alertModalObj,
      visible: true,
      title: '반출출고 취소',
      content,
    };

    if (validFlag) {
      _alertModalObj.yBtnTitle = '진행';
      _alertModalObj.yCallback = () => {
        cancelChulgo(item, items);
      };
    }
    setAlertModalObj(_alertModalObj);
  };

  const cancelChulgo = async (item, items) => {
    if (!item.holdType) {
      alert('취소사유를 선택하세요');
    } else {
      const data = [];
      const json = {
        centerCode: item.centerCode,
        target: item.target,
        partnerSeq: item.partnerSeq,
        stockRemoveDate: item.stockRemoveDate,
        stockRemoveType: item.stockRemoveType,
        holdType: item.holdType,
        holdNote: item.holdNote,

        stockRemoveSeq: item.stockRemoveSeq,
        loadingStatementSeq: item.loadingStatementSeq,
      };

      const inventorySeqs = [];
      if (items) {
        items.forEach((item) => {
          inventorySeqs.push(item.inventorySeq);
        });
      }
      json.inventorySeqs = inventorySeqs;
      data.push(json);

      let url = TAKE_OUT_REMOVE_CANCEL;
      dispatch(setLoading('POST'));

      await httpClient.post(url, data).then((rs) => {
        if (rs.data.result) {
          setAlertModalObj({
            ...alertModalObj,
            visible: true,
            title: '반출출고 취소 완료',
            content: [`반출출고 지시번호 ${item.stockRemoveSeq}가 취소되었습니다`],
          });
          fetchList(searchObj);
          setSearchObj({ ...searchObj });
        }
      });
      dispatch(setLoading(null));
    }
  };

  const downloadExcel = async (type) => {
    dispatch(setLoading('GET'));
    let url;
    if (type === 'main') {
      url = '/warehouse/remove/beforeLoading?';
      url = paramingSearchObjToUrl(url, searchObj);
    } else {
      url = '/warehouse/remove/beforeLoading/excel?';
      url = paramingSearchObjToUrl(url, searchObj);
    }

    return await httpClient.get(url).then((rs) => {
      if (rs.status === 200) {
        rs.data.forEach((row) => {
          row.ocenterKr = selectlabel(row.centerCode, masterOptions?.CENTER);
          row.statusKr = masterOptions?.LOADING_STAT_OBJ[row.status];
          row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
          row.loadingCarTypeKr = masterOptions?.CAR_TYPE_OBJ[row.loadingDriverCarType];
          row.modelGroupKr = masterOptions?.MODEL_GRP_1_OBJ[row.modelGroupLarge];
          row.statusKr = masterOptions?.LOADING_STAT_OBJ[row.status];
          row.stockRemoveTypeKr = masterOptions?.REMOVE_REASON_OBJ?.[row.stockRemoveType];
        });
        dispatch(setLoading(null));
        return rs.data;
      } else {
        dispatch(setLoading(null));
        return null;
      }
    });
  };

  const onEditEnded = async (e) => {
    if (e.dataField === 'memo' && e.value) {
      const searchObj = JSON.stringify(e.item.searchObj);
      if (e.item.loadingStatementSeq) {
        if (window.confirm('특이사항을 저장하시겠습니까?')) {
          await httpClient.post(REMOVE_LOADING_MEMO_SAVE, { loadingStatementSeq: e.item.loadingStatementSeq, memo: e.item.memo }).then((rs) => {
            if (rs?.status === 200) {
              alert('특이사항이 저장되었습니다!');
              fetchList(searchObj);
            }
          });
        }
      } else {
        alert('"출고지시대기"상태에서는 특이사항을 저장할 수 없습니다!');
        fetchList(searchObj);
      }
    }
  };

  return (
    <Presenter
      gridId={gridId}
      columns={columns}
      rows={rows}
      //
      downloadExcel={downloadExcel}
      detailGridId={detailGridId}
      detailColumns={detailColumns}
      excelDetailColumns={excelDetailColumns}
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      fetchList={fetchList}
      // grid-function
      onEditEnded={onEditEnded}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      //
      pagingSetting={pagingSetting}
      setPagingSetting={setPagingSetting}
      alertModalObj={alertModalObj}
      setAlertModalObj={setAlertModalObj}
      haldangObj={haldangObj}
      setHaldangObj={setHaldangObj}
      bottomSheetObj={bottomSheetObj}
      setBottomSheetObj={setBottomSheetObj}
      photoPopupObj={photoPopupObj}
      setPhotoPopupObj={setPhotoPopupObj}
      loadingParcelObj={loadingParcelObj}
      setLoadingParcelObj={setLoadingParcelObj}
      banchulPrintObj={banchulPrintObj}
      setBanchulPrintObj={setBanchulPrintObj}
    />
  );
};

export default Container;
