import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ImageModal = ({ title = '', src }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div>
      <img alt="" src={src} style={{ width: 45, height: 45, cursor: 'pointer' }} onClick={toggle} />
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <div style={{ textAlign: 'center' }}>
            <img alt="" style={{ maxWidth: 400 }} src={src} />
          </div>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
};

export default ImageModal;
