import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';
import { WMS_LINEHAUL_ACTION, WMS_LINEHAUL_PICKING_LIST_PAGING } from 'envVar';
import { OrderListDTO } from 'interface/order';

import { LinehaulPickingSearchDTO } from 'interface/warehouse';
import { PagingListDTO, ResponseDTO } from 'interface/util';

class OutService {
  // 주문정보
  public async getLinehaulPickingPaging(searchDTO: LinehaulPickingSearchDTO): Promise<PagingListDTO<OrderListDTO>> {
    let url = WMS_LINEHAUL_PICKING_LIST_PAGING + addingParamsToUrl(searchDTO);
    const { data, status } = await httpClient.get(url);
    return data;
  }
  public async getLinehaulPickingAction(searchDTO: any, action: string): Promise<ResponseDTO> {
    let url = WMS_LINEHAUL_ACTION(action) + addingParamsToUrl(searchDTO);
    const rs = await httpClient.get(url);
    return rs;
  }

  public async postLinehaulPickingAction(dataDTO: any, action: string): Promise<ResponseDTO> {
    let url = WMS_LINEHAUL_ACTION(action);
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
  public async getAction(action: string, searchDTO = null): Promise<ResponseDTO> {
    let url = action;
    if (searchDTO) url += addingParamsToUrl(searchDTO);
    const rs = await httpClient.get(url);
    return rs;
  }

  public async postAction(action: string, dataDTO: any, searchDTO: any): Promise<ResponseDTO> {
    let url = action;
    if (searchDTO) url += addingParamsToUrlexceptPaging(searchDTO);
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async putAction(action: string, dataDTO: any, searchDTO: any): Promise<ResponseDTO> {
    let url = action;
    if (searchDTO) url += addingParamsToUrlexceptPaging(searchDTO);
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }
}

export const outService = new OutService();
