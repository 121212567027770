import { useState } from 'react';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { Col, Row } from 'reactstrap';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
// servicce
import { serviceStore } from 'services/services';
import { ParcelInvoiceNumberSearchDTO } from '../../../_interface/parcel';
// component
import CommonModalNew from 'components/modal/commonModalNew';

export interface IRequestObj {
  visible?: boolean;
  searchObj?: ParcelInvoiceNumberSearchDTO;
  fetchFn?: (searchObj) => {};
}

export const RequestPopup = ({ requestObj, setRequestObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [createObj, setCreateObj] = useState<{ [key: string]: string | number }>();

  const validate = (createObj) => {
    if (!createObj?.partnerSeq) {
      alert('판매사는 필수값입니다!');
      return false;
    }
    if (!createObj?.quantity) {
      alert('신청수량은 필수값입니다!');
      return false;
    }
    return true;
  };

  const requestNumber = async () => {
    if (validate(createObj)) {
      dispatch(setLoading('POST'));
      const rs = await serviceStore.parcelAction(`invoice/number/request`, 'POST', null, createObj);
      if (rs?.status === 200) {
        alert('운송장신청이 완료되었습니다!');
        requestObj?.fetchFn(requestObj?.searchObj);
        setRequestObj(null);
      }
      dispatch(setLoading(null));
    }
  };
  return (
    <CommonModalNew
      title="운송장신청"
      style={{ width: 350 }}
      visible={requestObj?.visible}
      setVisible={() => {
        setRequestObj(null);
      }}
      rightTitle={
        <button className="btn btn-orange ssm" onClick={requestNumber}>
          신청
        </button>
      }
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">*판매사</label>
              <SelectD
                options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
                value={
                  createObj?.partnerSeq
                    ? {
                        value: createObj?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_FORWARDER_AUTH?.[createObj?.partnerSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setCreateObj({
                    ...createObj,
                    partnerSeq: (option as OptionItem)?.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*신청수량</label>
              <InputD
                type="number"
                value={createObj?.quantity || ''}
                onChange={(e) => {
                  setCreateObj({
                    ...createObj,
                    quantity: Number(e.target.value),
                  });
                }}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};
