// react
import { useEffect, useRef, useState, useMemo } from 'react';
//
import { v4 as uuidv4 } from 'uuid';
import { selectlabel, selectlabelForGrid } from 'common/master/codeMasterReturnHelper';
import {
  TAKE_OUT_LOADING_DETAIL,
  TAKE_OUT_LOADING,
  TAKE_OUT_LOADING_CONFIRM,
  LINEHAUL_LOADING_CHANGE,
  TAKE_OUT_LOADINGOUT_IMAGE,
  REMOVE_LOADINGOUT_MEMO_SAVE,
  INVENTORY_RETURN_ROLLBACK,
} from 'envVar';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { httpClient } from 'common/http-client/axiosConfig';
import { setLoading } from 'redux/services/menuSlice';
import { defaultBottomSheetObj } from 'components/template/bottomSheets';
import { defaultPhotoPopupObj } from 'pages/IPGO/1purchaseOrder/purchaseOrderTab/puchaseOrder/component/photoPopup';
// component
import Presenter from './presenter';
import { serviceStore } from 'services/services';

const defaultHaldangObj = {
  visible: false,
  data: null,
  rowItems: null,
};

const Container = () => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state) => state.menu);
  const { reduxUserInfo } = useSelector((state) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  // 그리드
  const [gridId, setGrid] = useState();
  const detailGridId = 'takeoutLoadingShipping_Detail';
  const [rows, setRows] = useState([]);

  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState();

  const [inventoryCancelModalObj, setInventoryCancelModalObj] = useState({
    visible: false,
    data: null,
    searchObj: null,
    fetchListFn: (data) => fetchList(data),
  });

  const [pagingSetting, setPagingSetting] = useState({
    pageNo: 1,
    pageSize: 100,
    fetchNew: false,
  });

  const [searchObj, setSearchObj] = useState({
    seqSearchType: null,
    seqSearchValue: null,

    dateSearchType: null,
    dateSearchValue: null,

    partnerSeq: null,
    centerCode: null,
    reason: null,
    status: null,

    ...defaultSearchFilter,
  });

  const [bottomSheetObj, setBottomSheetObj] = useState(defaultBottomSheetObj);
  const [haldangObj, setHaldangObj] = useState(defaultHaldangObj);
  //운송정보 모달
  const [driverModalObj, setDriverModalObj] = useState({ visible: false });

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_OUT_COMPLETE') {
        confirmChulcha(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_MANAGE_DRIVER') {
        driverSelectInRow(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_OUT_ROLLBACK') {
        handleModalRollBackAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_OUT_CANCEL') {
        handleModalCancelAction(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const handleModalRollBackAction = async (data, isParts = false) => {
    if (window.confirm('"이전단계"를 진행하시겠습니까?')) {
      dispatch(setLoading('PUT'));
      let payloadArr;

      if (isParts) {
        payloadArr = [
          {
            stockRemoveSeq: data[0].stockRemoveSeq,
            loadingStatementSeq: data[0].loadingStatementSeq,
            loadingOutSeq: data[0].loadingOutSeq,
            transportStatementSeq: data[0].transportStatementSeq,
            inventorySeqs: data?.map((ele) => ele.inventorySeq),
          },
        ];
      } else {
        payloadArr = data.map((ele) => {
          return {
            stockRemoveSeq: ele.stockRemoveSeq,
            loadingStatementSeq: ele.loadingStatementSeq,
            loadingOutSeq: ele.loadingOutSeq,
            transportStatementSeq: ele.transportStatementSeq,
          };
        });
      }
      let url = INVENTORY_RETURN_ROLLBACK;
      await httpClient.post(url, payloadArr).then((res) => {
        if (res?.status === 200) {
          alert('선택건 이전단계로 변경 완료되었습니다.');
          fetchList(searchObj);
          setSearchObj({ ...searchObj });
        }
      });
      dispatch(setLoading(null));
    }
  };

  const handleModalCancelAction = async (data, isParts = false) => {
    setInventoryCancelModalObj({
      visible: true,
      isParts,
      searchObj,
      data,
    });
  };

  const [banchulPrintObj, setBanchulPrintObj] = useState();
  const printAction = async (event) => {
    setBanchulPrintObj({
      visible: true,
      type: 'takeout',
      item: event?.item,
    });
  };

  const confirmChulcha = async (items) => {
    const result = window.confirm('확정하시겠습니까?');
    if (result) {
      let flag = true;
      const data = items.map((item) => {
        if (!(item?.loadingOutSeq && item?.promiseUnloadDate && item?.loadingDriverName && item?.loadingDriverPhone && item?.loadingDriverCarType && item?.loadingDriverCarNumber)) flag = false;
        return {
          loadingOutSeq: item?.loadingOutSeq,
          promiseUnloadDate: item?.promiseUnloadDate,
          loadingDriverName: item?.loadingDriverName,
          loadingDriverPhone: item?.loadingDriverPhone,
          loadingDriverCarType: item?.loadingDriverCarType,
          loadingDriverCarNumber: item?.loadingDriverCarNumber,
        };
      });

      if (flag) {
        dispatch(setLoading('POST'));
        await httpClient.post(TAKE_OUT_LOADING_CONFIRM, data).then((rs) => {
          if (rs?.status === 200) {
            alert('확정되었습니다.');
            fetchList(searchObj);
          }
        });
        dispatch(setLoading(null));
      } else {
        alert('운송정보 혹은 입고예정일이 입력되지 않은 건이 있습니다! 운송정보와 입고예정일을 입력해주세요!');
      }
    }
  };

  const paramingSearchObjToUrl = (url, searchObj) => {
    if (searchObj?.seqSearchValue && searchObj?.seqSearchType) url += `&${searchObj?.seqSearchType}=${searchObj?.seqSearchValue}`;
    if (searchObj?.dateSearchValue && searchObj?.dateSearchType)
      url += `&from${searchObj?.dateSearchType}=${searchObj?.dateSearchValue[0]}&to${searchObj?.dateSearchType}=${searchObj?.dateSearchValue[1]}`;

    let searchParams = {};
    let urlWithSearchParams = url;

    if (searchObj !== undefined) {
      searchParams = Object.keys(searchObj);
      for (let param of searchParams) {
        if (searchObj[param] !== null && searchObj[param] !== '')
          param === 'seqSearchValue' || param === 'seqSearchType' || param === 'dateSearchValue' || param === 'dateSearchType'
            ? (urlWithSearchParams += '')
            : (urlWithSearchParams += `&${param}=${searchObj[param]}`);
      }
    }

    return urlWithSearchParams;
  };

  const fetchList = async (searchObj) => {
    dispatch(setLoading('GET'));
    let url = `/warehouse/remove/loading/web/temp?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
    url = paramingSearchObjToUrl(url, searchObj);
    await httpClient.get(url).then((rs) => {
      const data = rs.data.list || [];
      const maxPage = Math.ceil(rs.data.totalCount / pagingSetting?.pageSize);
      if (rs?.status === 200) {
        data.forEach((row) => {
          row.maxPage = maxPage;
          row.totalCount = rs.data.totalCount;
          row.photo = '사진보기';
          row.shippingBill = row.confirmDatetime ? '프린트' : '';
          row.haldangInfo = '할당정보';
          row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
          row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER);
          row.dcenterKr = selectlabel(row.destinationCenterCode, masterOptions?.CENTER);
          row.stockremoveKr = selectlabel(row.reason, masterOptions?.REMOVE_REASON);
          row.statusKr = selectlabel(row.status, masterOptions?.LOADING_OUT_STAT);
          row.loadingCarTypeKr = selectlabel(row.loadingDriverCarType, masterOptions?.CAR_TYPE);
        });

        setRows(data);
      }
    });
    dispatch(setLoading(null));
  };

  const [photoPopupObj, setPhotoPopupObj] = useState(defaultPhotoPopupObj);

  useEffect(() => {
    if (photoPopupObj?.data) {
      fetchPhotos(photoPopupObj?.data);
    }
  }, [photoPopupObj?.data]);

  const fetchPhotos = async (data) => {
    if (data.loadingStatementSeq) {
      httpClient.get(TAKE_OUT_LOADINGOUT_IMAGE + `/${data.loadingStatementSeq}`).then((res) => {
        if (res?.status === 200) {
          setPhotoPopupObj({
            ...photoPopupObj,
            visible: true,
            photos: res.data,
          });
        } else {
        }
      });
    } else {
      alert('출차 운송번호가 존재하는 건만 사진열람이 가능합니다');
    }
  };

  useEffect(() => {
    if (!photoPopupObj.visible) {
      setPhotoPopupObj(defaultPhotoPopupObj);
    }
  }, [photoPopupObj.visible]);

  const haldangAction = (event) => {
    const data = {
      originalCenterCode: event.item.centerCode,
      destinationCenterCode: event.item.target,
      stockRemoveDate: event.item.stockRemoveDate,
      partnerSeq: event.item.partnerSeq,
      loadingOutSeq: event.item.loadingOutSeq,
      stockRemoveType: event.item.reason,
      locationCode: event.item.locationCode,
      centerCode: event.item.centerCode,
      target: event.item.target,
      modelStockSeq: event.item.modelStockSeq,
      status: event.item.status,
    };
    if (event.item.transportStatementSeq) data.transportStatementSeq = event.item.transportStatementSeq;
    if (event.item.loadingStatementSeq) data['loadingStatementSeq'] = event.item.loadingStatementSeq;
    else data['loadFlag'] = true;
    if (event.item.stockRemoveSeq) data['stockRemoveSeq'] = event.item.stockRemoveSeq;
    setCurrentHaldangItem(data);
    setHaldangObj({
      ...haldangObj,
      visible: true,
      data,
      cancel: handleModalCancelAction,
      rollback: handleModalRollBackAction,
      rowItems: event.item,
    });
  };

  // NOTE: 할당정보 모달 호출 시 사용되는 기준 정보
  const [currentHaldangItem, setCurrentHaldangItem] = useState(null);
  // NOTE: 할당정보 액션 변경 시 searchObj가 업데이트된 경우, 할당정보 모달이 열려있을 때 정보를 갱신할 수 있도록 effect hook 적용.
  useEffect(() => {
    if (haldangObj?.visible) {
      setHaldangObj(() => {
        return {
          ...haldangObj,
          data: { ...currentHaldangItem },
        };
      });
    }
  }, [searchObj]);

  const columns = [
    {
      headerText: '상세보기',
      renderer: {
        type: 'ButtonRenderer',
        labelText: '상세보기',
        onClick: function (event) {
          onCellClick(event);
        },
      },
    },
    {
      headerText: '사진보기',
      dataField: 'photo',
      editable: false,
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 기본 URL
        jsCallback: function (rowIndex, columnIndex, value, item) {
          setPhotoPopupObj({
            ...photoPopupObj,
            data: item,
          });
        },
      },
    },
    {
      headerText: '운송명세서',
      dataField: 'shippingBill',
      editable: false,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '프린트',
        onClick: printAction,
        visibleFunction: function (rowIndex, columnIndex, value, item, dataField) {
          if (!item.promiseUnloadDate || !item.transportStatementSeq) {
            return false;
          }
          return true;
        },
      },
    },
    {
      headerText: '할당정보',
      dataField: 'haldangInfo',
      renderer: {
        type: 'ButtonRenderer',
        onClick: haldangAction,
      },
    },
    {
      headerText: '출고지시번호',
      dataField: 'loadingStatementSeq',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '운송번호',
      dataField: 'transportStatementSeq',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    //
    {
      headerText: '출차예정일',
      dataField: 'stockRemoveDate',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출차완료일',
      dataField: 'confirmDatetime',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      dataField: 'promiseUnloadDate',
      headerText: '입고예정일',
      width: 120,
      dataType: 'date',
      formatString: 'yyyy-mm-dd',
      filter: {
        showIcon: true,
      },
      editRenderer: {
        onlyCalendar: true,
        type: 'CalendarRenderer',
        showTodayBtn: true,
        showEditorBtnOver: true,
        showExtraDays: true,
        titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        defaultFormat: 'yyyy-mm-dd',
      },
      style: 'my-custom-aui',
    },
    {
      headerText: '반출창고',
      dataField: 'centerCodeKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '반출대상',
      dataField: 'target',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '반출유형',
      dataField: 'stockremoveKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '특이사항',
      dataField: 'memo',
      headerTooltip: {
        show: true,
        tooltipHtml: `<div">
        직접 수정 가능
          </div>`,
      },
    },
    {
      headerText: '출차 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '운송정보',
      children: [
        {
          headerText: '운송기사명',
          dataField: 'loadingDriverName',
          filter: {
            showIcon: true,
          },
          editable: true,
        },
        {
          headerText: '연락처',
          dataField: 'loadingDriverPhone',
          filter: {
            showIcon: true,
          },
          editable: true,
        },
        {
          headerText: '차량종류',
          dataField: 'loadingDriverCarType',
          filter: {
            showIcon: true,
          },
          renderer: {
            type: 'DropDownListRenderer',
            list: masterOptions?.CAR_TYPE,
            keyField: 'value', // key 에 해당되는 필드명
            valueField: 'label', // value 에 해당되는 필드명
          },
        },
        {
          headerText: '차량번호',
          dataField: 'loadingDriverCarNumber',
          filter: {
            showIcon: true,
          },
          editable: true,
        },
      ],
    },
    {
      headerText: '출차완료자',
      dataField: 'confirmId',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
  ];

  const detailColumns = [
    {
      headerText: '운송번호',
      dataField: 'transportStatementSeq',
    },
    {
      headerText: '반출창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },

        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
      ],
    },
    {
      headerText: '출차수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
        },
      ],
    },
    {
      headerText: '출차완료일',
      dataField: 'confirmDatetime',
    },
    {
      headerText: '출차완료자',
      dataField: 'confirmId',
    },
  ];

  const getColumns = columns.filter(
    (item, index) =>
      item.dataField === 'loadingStatementSeq' ||
      item.dataField === 'transportStatementSeq' ||
      item.dataField === 'stockRemoveDate' ||
      item.dataField === 'confirmDatetime' ||
      item.dataField === 'promiseUnloadDate' ||
      item.dataField === 'centerCodeKr' ||
      item.dataField === 'target' ||
      item.dataField === 'stockRemoveTypeKr' ||
      item.dataField === 'partnerSeqKr',
  );
  const getDetailColumns = detailColumns.filter((item, index) => index > 1);
  const excelDetailColumns = [...getColumns, ...getDetailColumns];

  useEffect(() => {
    initGrid();
  }, []);

  const initGrid = () => {
    setGrid(`takeoutworks_3takeout${uuidv4()}`);
  };

  const bottomGridRef = useRef();
  const fetchDetail = async (loadingOutSeq) => {
    dispatch(setLoading('GET'));
    const rs = await serviceStore.warehouseAction(`remove/loading/detail/${loadingOutSeq}`, 'GET', null);
    if (rs?.status === 200) {
      const rows = rs.data?.map((row) => {
        return {
          ...row,
          modelGroupKr: selectlabel(row.modelGroupMedium, masterOptions?.MODEL_GRP_2),
          partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
          statusKr: selectlabel(row.status, masterOptions?.LOADING_OUT_STAT),
          centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER),
        };
      });

      const gridButtonhandler = (e) => {
        const id = e.target.id;
        if (id === 'excel-down') {
          bottomGridRef?.current?.exportAsXlsx({ fileName: `반출출차지시_상세` });
        }
      };

      setBottomSheetObj({
        visible: true,
        hasTabs: [
          {
            title: '반출출차 상세',
            gridRef: bottomGridRef,
            columns: detailColumns,
            rows,
            buttons: [
              <div key={`excel-down`} id={`excel-down`} className={`green`} onClick={gridButtonhandler}>
                엑셀다운
              </div>,
            ],
            options: {
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
            },
          },
        ],
      });
    }
    dispatch(setLoading(null));
  };

  const onCellClick = async (event) => {
    if (event.pid.includes('takeoutworks_3takeout')) {
      if (event.type === 'buttonClick' && event.columnIndex === 0) {
        const loadingOutSeq = event.item.loadingOutSeq;
        fetchDetail(loadingOutSeq);
      }
    }
  };

  const onEditEnded = (event) => {
    if (event.pid.includes('takeoutworks_3takeout')) {
      if (event.type === 'cellEditEnd') {
        if (event.item.status === 'OUT' && event.columnIndex > 16 && event.columnIndex < 22) {
          let confirm = window.confirm('운송정보를 변경하시겠습니까??');
          if (confirm) {
            editChulcha(event.item);
          } else {
            fetchList(searchObj);
          }
        }
        if (event?.columnIndex === 14) {
          let confirm = window.confirm('특이사항을 저장하시겠습니까?');
          if (confirm) {
            saveMemo(event?.item);
          } else {
            fetchList(searchObj);
          }
        }
      }
    }
  };

  const saveMemo = async (item) => {
    const data = {
      loadingOutSeq: item.loadingOutSeq,
      memo: item.memo,
    };
    dispatch(setLoading('PUT'));
    await httpClient.post(REMOVE_LOADINGOUT_MEMO_SAVE, data).then((rs) => {
      if (rs?.status === 200) {
        alert('간선출차 메모를 업데이트 했습니다!');
        fetchList(searchObj);
      }
    });
    dispatch(setLoading(null));
  };

  const editChulcha = async (item) => {
    const postArr = [];
    postArr.push({
      loadingOutSeq: item?.loadingOutSeq,
      promiseUnloadDate: item?.promiseUnloadDate,
      loadingDriverName: item?.loadingDriverName,
      loadingDriverPhone: item?.loadingDriverPhone,
      loadingDriverCarType: item?.loadingDriverCarType,
      loadingDriverCarNumber: item?.loadingDriverCarNumber,
      transportStatementSeq: item?.transportStatementSeq,
    });

    driverSettingAPI(postArr);
  };

  const driverSetting = ({ data, loadingStatementSeqs }) => {
    const rows = window.AUIGrid.getGridData(`#${gridId}`);
    let validFlag = false;
    rows.forEach((row) => {
      if (loadingStatementSeqs.includes(row.loadingStatementSeq)) {
        if (row.transportStatementSeq) {
          validFlag = true;
        } else {
          row.loadingDriverName = data?.driverName;
          row.loadingDriverPhone = data?.phoneNumber;
          row.loadingDriverCarType = data?.carType;
          row.loadingDriverCarNumber = data?.carNumber;
        }
      }
    });
    if (validFlag) alert('출차대기 상태 이외의 건들은 운송정보를 수정에서 제외됩니다');
    setRows(rows);
  };

  const driverSettingAPI = async (arr) => {
    dispatch(setLoading('PUT'));
    await httpClient.put(LINEHAUL_LOADING_CHANGE, arr).then((rs) => {
      if (rs?.status === 200) {
        alert('운송정보가 변경되었습니다.');
        fetchList(searchObj);
      }
    });
    dispatch(setLoading(null));
  };

  const driverSelectInRow = (data) => {
    const loadingStatementSeqs = data.map((ele) => {
      return ele.loadingStatementSeq;
    });
    if (data?.length > 0) {
      setDriverModalObj({
        ...driverModalObj,
        visible: true,
        loadingStatementSeqs,
        driverSetFn: (data) => driverSetting(data),
      });
    }
  };

  const downloadExcel = async (str) => {
    if (str === 'main') {
      let url = TAKE_OUT_LOADING + '?';
      url = paramingSearchObjToUrl(url, searchObj);

      return httpClient.get(url).then((rs) => {
        const result = rs.data;
        result.forEach((row) => {
          row.photo = '사진보기';
          row.modelGroupKr = masterOptions?.MODEL_GRP_1_OBJ[row.modelGroupLarge];
          row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER);
          row.stockremoveKr = masterOptions?.REMOVE_REASON_OBJ[row.reason];
          row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
          row.statusKr = masterOptions?.LOADING_OUT_STAT_OBJ[row.status];
          row.loadingCarTypeKr = selectlabelForGrid(row.loadingDriverCarType, masterOptions?.CAR_TYPE);
          row.shippingBill = row.confirmDatetime ? '프린트' : '';
        });
        return result;
      });
    } else {
      let url = '/warehouse/remove/loading/web/excel?';
      url = paramingSearchObjToUrl(url, searchObj);

      return httpClient.get(url).then((rs) => {
        if (rs.status === 200);
        const result = rs.data;
        result.forEach((row) => {
          row.statusKr = masterOptions?.LOADING_OUT_STAT_OBJ[row.status];
          row.modelGroupKr = masterOptions?.MODEL_GRP_1_OBJ[row.modelGroupLarge];
          row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER);
          row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
        });
        return result;
      });
    }
  };

  return (
    <Presenter
      gridId={gridId}
      columns={columns}
      rows={rows}
      detailGridId={detailGridId}
      detailColumns={detailColumns}
      excelDetailColumns={excelDetailColumns}
      //
      masterOptions={masterOptions}
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      //
      fetchList={fetchList}
      pagingSetting={pagingSetting}
      setPagingSetting={setPagingSetting}
      // grid-function
      onCellClick={onCellClick}
      onEditEnded={onEditEnded}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      downloadExcel={downloadExcel}
      //
      fetchPhotos={fetchPhotos}
      photoPopupObj={photoPopupObj}
      setPhotoPopupObj={setPhotoPopupObj}
      driverModalObj={driverModalObj}
      setDriverModalObj={setDriverModalObj}
      bottomSheetObj={bottomSheetObj}
      setBottomSheetObj={setBottomSheetObj}
      haldangObj={haldangObj}
      setHaldangObj={setHaldangObj}
      inventoryCancelModalObj={inventoryCancelModalObj}
      setInventoryCancelModalObj={setInventoryCancelModalObj}
      banchulPrintObj={banchulPrintObj}
      setBanchulPrintObj={setBanchulPrintObj}
    />
  );
};
export default Container;
