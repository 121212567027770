import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './deliverySector';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER, RAWDATA } from 'redux/services/menuSlice';
import { DRIVER_LIST_MASTER } from 'pages/ETC/1driver/driverListTab';

export const DELIVERYSECTOR_MASTER = [
  //
  ...DRIVER_LIST_MASTER,
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  'DRIVER_PHONE_WHOLE', // 라벨용이 많으니
  `${RAWDATA}CENTER_WHOLE`,
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'CENTER_CALL_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_NETWORK_AUTH',
  'SELLER_WHOLE',
  'INFRA',
  'SALES_CHANNEL',
  //
  'ZIPCODE_RANGE',
  'ZIPCODE_2ND',
  'ZIPCODE_MIN_GROUP',
  `${RAWDATA}PROVINCE`,
  'PROVINCE',
  'CITY',
  'DRV_POS',
  'CAR_TYPE',
  'DRV_TYPE',
  'DRIVER_PARTY',
  'TEAM_GRADE',
  'TEAM_TYPE',
  'MODEL_GROUP',
  'USE_FLAG',
  'WEEK_DAY',
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(DELIVERYSECTOR_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            DELIVERYSECTOR: <Tab1 tabId={'DELIVERYSECTOR'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
