import { useState, useEffect } from 'react';
import { defaultUnitedPageWithTopTab, DetailTopTab } from 'components/template/detailTopTab';

import Container1 from './container';

// redux
import { useSelector, useDispatch } from 'react-redux';

// options
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER, RAWDATA } from 'redux/services/menuSlice';

export const CREATE_FCPICKING_MASTER = [
  'SELLER_SELLER_AUTH',
  'SELLER_SELLER_WHOLE',
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  //
  `${RAWDATA}DRIVER_AUTH`,
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  //
  'MODEL_GROUP',
  'MODEL_TYPE',
  'MODEL_GRP_1',
  'MODEL_GRP_2',
  'MODEL_GRP_3',
];

const Index = () => {
  const { MASTER_OBJ } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(CREATE_FCPICKING_MASTER);
  };

  useEffect(() => {
    if (MASTER_OBJ) {
      setTabObj({
        titles: ['FC피킹', 'FC피킹 생성'],
        routers: ['/fcpicking', '/createfcpicking'],
        container: [<Container1 />],
      });
    }
  }, [MASTER_OBJ]);

  return <>{tabObj && MASTER_OBJ && <DetailTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
