import { OptionItem } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { serviceStore } from 'services/services';
import { PagingListDTO } from 'interface/util';
import { setLoading } from 'redux/services/menuSlice';
import { useNavigate } from 'react-router-dom';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { InventoryInspectCreateDTO, InventoryInspectModelDTO } from '../../../INVENTORY/_interface/warehouse';
import { resetAllSelections } from 'common/util/searchBox';

const Index = () => {
  const useNewDesignFlag = JSON.parse(localStorage.getItem('useNewDesignFlag'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();
  const gridSearchRef = useRef<IPagingGrid>();
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [saveDTO, setSaveDTO] = useState<InventoryInspectCreateDTO>({
    centerCode: reduxUserInfo?.centerCode,
    details: [],
  });

  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const columns: IGrid.Column[] = [
    {
      headerText: '출고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
    },
    {
      headerText: '보관재고 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
        },
      ],
    },
    {
      headerText: '검수지시 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'inputAvailableQuantity',
          style: 'aui-textinputer',
          editable: true,
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            autoThousandSeparator: true,
            onlyNumeric: true,
            allowNegative: false,
            validator: (oldValue: any, newValue: any, item: any, dataField: string, fromClipboard: boolean, which: any) => {
              // if (newValue + item?.inputDefectedQuantity === 0) return { validate: false, message: '양품, 불용 입력값이 모두 0일 수 없습니다!' };
              if (newValue > item?.availableQuantity) return { validate: false, message: '보관재고 양품 수량을 넘을 수 없습니다!' };
            },
          },
        },
        {
          headerText: '불용',
          dataField: 'inputDefectedQuantity',
          style: 'aui-textinputer',
          editable: true,
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            autoThousandSeparator: true,
            onlyNumeric: true,
            allowNegative: false,
            validator: (oldValue: any, newValue: any, item: any, dataField: string, fromClipboard: boolean, which: any) => {
              // if (newValue + item?.inputAvailableQuantity === 0) return { validate: false, message: '양품, 불용 입력값이 모두 0일 수 없습니다!' };
              if (newValue > item?.defectedQuantity) return { validate: false, message: '보관재고 불용 수량을 넘을 수 없습니다!' };
            },
          },
        },
      ],
    },
  ];

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'deleteRow') {
      const idxs = gridRef.current.getCheckedRowItems().map((ele) => ele.rowIndex);
      if (idxs?.length > 0) {
        const rows = gridRef.current.getGridData();
        const newRows = rows.filter((_, i) => !idxs.includes(i));
        gridRef.current.setGridData(newRows);
      } else {
        alert('선택된 것이 없습니다');
      }
    } else {
      if (!saveDTO?.centerCode) {
        alert('창고를 입력하세요!');
      } else {
        setItempPopup({ visible: true, id });
      }
    }
  };

  const validator = () => {
    if (!saveDTO?.centerCode) {
      alert('창고는 필수값입니다!');
      return false;
    }
    // 항목 체크
    const items = gridRef?.current?.getGridData();
    if (items?.length === 0) {
      alert('작업지시를 생성할 항목이없습니다!');
      return false;
    }
    return true;
  };

  const createInspect = async () => {
    if (validator() && window.confirm('검수지시서를 생성하시겠습니까?')) {
      const items = gridRef?.current?.getGridData();
      const dataDTO = {
        ...saveDTO,
        details: items?.map((row) => {
          return {
            modelStockSeq: row?.modelStockSeq,
            locationSeq: row?.locationSeq,
            locationCode: row?.locationCode,
            availableQuantity: row?.inputAvailableQuantity,
            defectedQuantity: row?.inputDefectedQuantity,
          };
        }),
      };
      const rs = await serviceStore?.warehouseAction(`inventory/inspect/create`, 'POST', null, dataDTO);
      if (rs?.status === 200) {
        alert('작업지시서가 생성되었습니다!');
        useNewDesignFlag === 1 ? navigate(`/main/inventoryInspect/inventoryInspectTab`) : window.open(`/inventoryInspect`);
      }
    }
  };

  const labellingKr = (data: PagingListDTO<InventoryInspectModelDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row.modelGroup],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.warehouseAction(`inventory/inspect/model`, 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      centerCode: saveDTO?.centerCode,
      pageSize: GRID_PAGE_ROW_COUNT,
      similaritySearchFlag: false,
    },
    gridRef: gridSearchRef,
    fetchPaging,
  });

  const addItem = () => {
    const items = gridSearchRef.current.getCheckedRowItemsAll();
    const old = gridRef?.current?.getGridData();

    if (items?.length > 0) {
      const oldLoca = old?.map((ele) => ele.locationCode);
      const filtered = items?.filter((ele) => !oldLoca?.includes(ele?.locationCode));
      if (filtered?.length !== items?.length) {
        if (filtered?.length > 0) alert('중복을 제외하고 추가되었습니다!');
        else alert('중복으로 추가할 건이 없습니다!');
      } else {
        alert('추가되었습니다!');
      }

      gridRef?.current?.addRow(
        filtered?.map((row) => {
          return {
            ...row,
            inputAvailableQuantity: 1,
            inputDefectedQuantity: 1,
          };
        }),
      );
    } else {
      alert('선택된 건이 없습니다!');
    }
  };

  const [itemPopup, setItempPopup] = useState({ visible: false, id: null });
  return (
    <div className="page-content">
      <div style={{ textAlign: 'right' }}>
        <button
          className="btn btn-secondary"
          onClick={() => {
            createInspect();
          }}
        >
          검수지시서 생성
        </button>
      </div>
      {itemPopup?.visible && (
        <CommonModalNew
          title={`+항목추가(로케이션 정보)`}
          subTitle={`창고: ${MASTER_OBJ?.CENTER_WHOLE?.[saveDTO?.centerCode]}`}
          style={{ width: 1000 }}
          visible={itemPopup?.visible}
          setVisible={() => {
            setItempPopup(null);
          }}
          rightTitle={
            <>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  addItem();
                }}
              >
                추가
              </button>
            </>
          }
          children={
            <>
              <Row>
                <Col>
                  <label>제품타입</label>
                  <SelectD
                    options={MASTER_OPS?.MODEL_TYPE}
                    value={
                      searchObj?.modelType
                        ? {
                            value: searchObj?.modelType,
                            label: MASTER_OBJ?.MODEL_TYPE?.[searchObj?.modelType],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          modelType: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label>제품그룹</label>
                  <SelectD
                    options={MASTER_OPS?.MODEL_GROUP}
                    value={
                      searchObj?.modelGroup
                        ? {
                            value: searchObj?.modelGroup,
                            label: MASTER_OBJ?.MODEL_GROUP?.[searchObj?.modelGroup],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          modelGroup: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label>제품</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={4} style={{ display: 'inline-block' }}>
                      <SelectD
                        value={
                          selectTypeObj?.SEARCH_MODEL
                            ? {
                                value: selectTypeObj?.SEARCH_MODEL,
                                label: MASTER_OBJ?.SEARCH_MODEL[selectTypeObj?.SEARCH_MODEL],
                              }
                            : null
                        }
                        options={MASTER_OPS?.SEARCH_MODEL}
                        onChange={(option) => {
                          setSelectTypeObj({
                            ...selectTypeObj,
                            SEARCH_MODEL: (option as OptionItem).value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={8} style={{ display: 'inline-block' }}>
                      <InputD
                        disabled={!selectTypeObj?.SEARCH_MODEL}
                        value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                        onChange={(e) => {
                          setSearchObj({
                            ...searchObj,
                            [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </div>
                </Col>
                <Col>
                  <label className="col-form-label">유사검색여부</label>
                  <div className="radio-select-area">
                    <span
                      className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                      onClick={() => {
                        setSearchObj((prev) => {
                          return {
                            ...prev,
                            similaritySearchFlag: true,
                          };
                        });
                      }}
                    >
                      유사검색
                    </span>
                    <span
                      className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                      onClick={() => {
                        setSearchObj((prev) => {
                          return {
                            ...prev,
                            similaritySearchFlag: false,
                          };
                        });
                      }}
                    >
                      일치검색
                    </span>
                  </div>
                </Col>
                <Col>
                  <div style={{ paddingTop: 10, textAlign: 'left' }}>
                    <button
                      className="btn btn-search"
                      onClick={(e) => {
                        console.log('search');
                        e.preventDefault();
                        wrappedFetchList(searchObj);
                      }}
                    >
                      조회
                    </button>
                  </div>
                </Col>
              </Row>
              <div className="presenterGridBox">
                <GridBox
                  gridRef={gridSearchRef}
                  columns={columns?.filter((_, idx) => idx < 12)}
                  gridProps={{
                    fixedColumnCount: 1,
                    showRowAllCheckBox: true,
                    showRowCheckColumn: true,
                    rowCheckDisabledFunction(rowIndex, isChecked, item) {
                      if (item?.availableQuantity + item?.defectedQuantity === 0) return false;
                      return true;
                    },
                  }}
                />
              </div>
            </>
          }
        />
      )}
      <div className="presenterInput">
        <p>1. 기본정보</p>
        <Row>
          <Col>
            <label className="col-form-label">*전기일</label>
            <FlatpickrD
              value={saveDTO?.postingDate || ''}
              onChange={(value) => {
                setSaveDTO({
                  ...saveDTO,
                  postingDate: returnDateyyyymmdd(value[0]),
                });
              }}
              options={{
                mode: 'single',
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">*창고</label>
            <SelectD
              value={
                saveDTO?.centerCode
                  ? {
                      value: saveDTO?.centerCode,
                      label: MASTER_OBJ?.CENTER_AUTH?.[saveDTO?.centerCode],
                    }
                  : null
              }
              options={MASTER_OPS?.CENTER_AUTH}
              onChange={(option) => {
                const items = gridRef?.current?.getGridData();
                if (saveDTO?.centerCode && (option as OptionItem).value !== saveDTO?.centerCode && items?.length > 0) {
                  if (window.confirm('창고선택을 수정하시면 "2. 로케이션정보" 가 초기화됩니다. 계속하시겠습니까?')) {
                    setSaveDTO({
                      ...saveDTO,
                      centerCode: (option as OptionItem).value,
                    });
                    setSearchObj({
                      ...searchObj,
                      centerCode: (option as OptionItem).value,
                    });
                    gridRef?.current?.setGridData([]);
                  }
                } else {
                  setSaveDTO({
                    ...saveDTO,
                    centerCode: (option as OptionItem).value,
                  });
                  setSearchObj({
                    ...searchObj,
                    centerCode: (option as OptionItem).value,
                  });
                }
              }}
            />
          </Col>
          <Col />
          <Col />
          <Col />
        </Row>
      </div>
      <div className="presenterGridBox" style={{ marginBottom: 0 }}>
        <div>2. 로케이션정보</div>
        <div className="grid-button-area" style={{ marginTop: 0 }}>
          <div id="deleteRow" className={`red`} onClick={gridButtonhandler}>
            -행삭제
          </div>
          <div id="addItems" className={`orange`} onClick={gridButtonhandler}>
            +항목추가
          </div>
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            editable: true,
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
      </div>
    </div>
  );
};

export default Index;
