import { useState, useEffect } from 'react';
import { defaultUnitedPageWithTopTab, UnitedPageWithTopTab } from 'components/template/topTab';

import Container1 from './manageLazyorderList';

// redux
import { useSelector, useDispatch } from 'react-redux';

// options
import { centerListForlabeling, codeMasterOptionReturn, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';

const Index = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    const initMasterOptions = async () => {
      const [CENTER_WHOLE, SELLER_WHOLE, REMOVE_STATUS, REMOVE_REASON] = await Promise.all([
        //
        centerListForlabeling(),
        partnerListForlabeling({ sellerFlag: true }),
        codeMasterOptionReturn('REMOVE_STATUS'),
        codeMasterOptionReturn('REMOVE_REASON'),
      ]);

      dispatch(
        setMasterOptions({
          CENTER_LABEL: CENTER_WHOLE?.map((ele) => {
            return {
              value: ele.centerCode,
              label: ele.centerName,
            };
          }),
          SELLER: SELLER_WHOLE?.map((ele) => {
            return {
              value: ele.partnerSeq,
              label: ele.partnerName,
            };
          }),
          REMOVE_STATUS,
          REMOVE_REASON,
        }),
      );
    };

    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (masterOptions) {
      setTabObj({
        currentTab: 0,
        titles: ['주문연기요청대상'],
        containers: [<Container1 />],
      });
    }
  }, [masterOptions]);

  return <>{masterOptions && tabObj?.titles.length > 0 && <UnitedPageWithTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
