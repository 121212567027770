import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Container1 from './1statement';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER, RAWDATA } from 'redux/services/menuSlice';

type ICategory = 'HEADQUATERS' | 'SUBCONTRACT' | 'CENTER' | 'DRIVER';

export const categoryChanger = (url: string): ICategory => {
  if (url?.includes('transactionStatheadquaters')) {
    return 'HEADQUATERS';
  } else if (url?.includes('transactionStatSubcontract')) {
    return 'SUBCONTRACT';
  } else if (url?.includes('transactionStatCenter')) {
    return 'CENTER';
  } else if (url?.includes('transactionStatDriverteam')) {
    return 'DRIVER';
  }
};
export const TRANSACTION_MASTER = [
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  'INFRA',
  //
  'INVOICE_STATUS',
  'FEE_TYPE',
  'CANCEL_REASON',
  'COLLECT_REASON',
  'SERVICE_TYPE',
  'PMODEL_TYPE',
  'MODEL_TYPE',
  'MODEL_GROUP',
  'MODEL_GRP_1',
  'MODEL_GRP_2',
  'MODEL_GRP_3',
  'CANCEL_TYPE',
  `${RAWDATA}ORDER_TYPE`,
  'MESSAGE_TYPE',
  'INVENTORY_LOCATION',
  'CENTER_AUTH',
  'TS_STATUS',
  'INPUT_CATEGORY',
  //
  'FIX_DATE',
  'FLOOR',
  'DELIVERY_TIME',
  //
  {
    SEARCH_PROVIDER: [
      {
        label: '사업번호',
        value: 'providerBusinessNumber',
      },
      {
        label: '상호',
        value: 'providerCompanyName',
      },
      {
        label: '사업장 주소',
        value: 'providerOfficeAddress',
      },
      {
        label: '업태',
        value: 'providerIndustry',
      },
      {
        label: '종목',
        value: 'providerProduct',
      },
    ],
  },
  {
    SEARCH_RECEIVER: [
      {
        label: '사업번호',
        value: 'receiverBusinessNumber',
      },
      {
        label: '상호',
        value: 'receiverCompanyName',
      },
      {
        label: '사업장 주소',
        value: 'receiverOfficeAddress',
      },
      {
        label: '업태',
        value: 'receiverIndustry',
      },
      {
        label: '종목',
        value: 'receiverProduct',
      },
    ],
  },
  {
    SEARCH_DATE: [
      {
        label: '고객주문일',
        value: 'OrderDate',
      },
      {
        label: '등록일',
        value: 'RegisterDate',
      },
      {
        label: '희망일',
        value: 'PromiseDeliveryDate',
      },
      {
        label: '지정일',
        value: 'DeliveryDate',
      },
      {
        label: '완료일',
        value: 'FinishDate',
      },
      {
        label: '취소일',
        value: 'CancelDate',
      },
    ],
  },
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(TRANSACTION_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            TRANSACTION_STATEMENT_CENTER: <Container1 />,
            TRANSACTION_STATEMENT_DRIVER: <Container1 />,
            TRANSACTION_STATEMENT_SUBCONTRACT: <Container1 />,
            TRANSACTION_STATEMENT_HEADQUATERS: <Container1 />,
          }}
        />
      )}
    </>
  );
};

export default Index;
