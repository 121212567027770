import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './model';
import Tab2 from './_model';
import Tab3 from './liveSerial';
import Tab4 from './defected';
import Tab5 from './dailymagam';
import Tab6 from './dailySales';
import Tab7 from './inoutPay';
import Tab8 from './dailyLoca';
import Tab9 from './whole';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      //
      'DRIVER_AUTH',
      'DRIVER_WHOLE',
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_NETWORK_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      'SALES_CHANNEL',
      //
      'MODEL_GROUP',
      'MODEL_TYPE',
      'FEE_TYPE',
      'LO_STATUS',
      'CANCEL_REASON',
      'DEFECTED_STAT',
      'INVA_STATUS',
      'INVA_TYPE',
      'INVM_STATUS',
      'ASSET_TYPE',
      'LOCATION_TYPE',
      'SEARCH_MODEL',
      {
        SEARCH_NUMBER: [
          { value: 'inventorySeq', label: '재고번호' },
          { value: 'serialNumber', label: '시리얼번호' },
        ],
      },
      {
        SEARCH_SEQ: [
          {
            label: '주문번호',
            value: 'orderSeq',
          },
          {
            label: '외부주문번호',
            value: 'externalOrderNumber',
          },
          {
            label: '송장번호',
            value: 'invoiceSeq',
          },
          {
            label: '외부송장번호',
            value: 'externalInvoiceNumber',
          },
          {
            label: '고객명',
            value: 'receiverName',
          },
        ],
      },
      {
        SERIAL_DATE: [
          { value: 'CreateDate', label: '생성일' },
          { value: 'ManufactureDate', label: '제조일' },
          { value: 'DistributeDate', label: '유통일' },
        ],
      },
      {
        SEARCH_TABLE_SEQ: [
          { value: 'linehaulSeq', label: '간선피킹번호' },
          { value: 'stockRemoveSeq', label: '반출피킹번호' },
          { value: 'loadingStatementSeq', label: '출고번호' },
          { value: 'transportStatementSeq', label: '운송번호' },
          { value: 'loanPickSeq', label: 'FC피킹번호' },
          { value: 'loanSeq', label: '기사출고번호' },
          { value: 'loanComponentSeq', label: '자재출고번호' },
        ],
      },
    ];
    initMasterOptions(masterArr);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            INVENTORY_WHOLE_MODEL: <Tab9 tabId={'INVENTORY_WHOLE_MODEL'} />,
            INVENTORY_LIVE_MODEL: <Tab1 tabId={'INVENTORY_LIVE_MODEL'} />,
            INVENTORY_MODEL: <Tab2 tabId={'INVENTORY_MODEL'} />,
            INVENTORY_SERIAL: <Tab3 tabId={'INVENTORY_SERIAL'} />,
            INVENTORY_DEF: <Tab4 tabId={'INVENTORY_DEF'} />,
            INVENTORY_DAILY: <Tab5 tabId={'INVENTORY_DAILY'} />,
            INVENTORY_DAILY_LOCA: <Tab8 tabId={'INVENTORY_DAILY_LOCA'} />,
            INVENTORY_SALES: <Tab6 tabId={'INVENTORY_SALES'} />,
            INVENTORY_PAY: <Tab7 tabId={'INVENTORY_PAY'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;

const InventoryReportsCommonDefaultSearchFilters = {
  orderSeq: null,
  externalOrderNumber: null,
  invoiceSeq: null,
  externalInvoiceNumber: null,
  customerName: null,
  inventorySeq: null,
  serialNumber: null,
  model: null,
  modelName: null,
  modelStockSeq: null,
  barcode: null,
  skuNumber: null,
  //
  centerCode: null,
  modelGroup: null,
  modelType: null,
  partnerSeq: null,
  userId: null,
  //
  receiverName: null,
  //
  purchaseSeq: null,
  availableStatus: [],
  availableType: [],
  moveStatus: [],
  assetType: null,
  //
  registerDate: null,
  infraSeq: null,
  //
  locationCode: null,
  locationType: null,
  //
  fromInOutDate: null,
  toInOutDate: null,
};

export { InventoryReportsCommonDefaultSearchFilters };
