import { useEffect, useState } from 'react';
import { Button, Col } from 'reactstrap';
import { FileDTOForAdd } from 'interface/util';
import { handleFiles, uploadFile } from 'common/util/photo';
import { v4 as uuidv4 } from 'uuid';
import CloseImg from 'assets/images/close/cross.png';
import { ImageModal } from './imageModal';
import { getToday } from 'common/util/dateParsingFn';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';

export const defaultPhotoPopupObj = {
  visible: false,
  data: null,
  photos: null,
};
export interface IPhotoObj {
  visible?: boolean;
  title?: string;
  mainSeq?: string;
  item?: any;
  searchObj?: string;
}

interface IPhotoPoupObj {
  photoPopupObj: IPhotoObj;
  setPhotoPopupObj: React.Dispatch<React.SetStateAction<IPhotoObj>>;
}

export const PhotosPopup = ({ photoPopupObj, setPhotoPopupObj }: IPhotoPoupObj) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<FileDTOForAdd[]>();

  useEffect(() => {
    if (photoPopupObj?.item) {
      fetchPhotos(photoPopupObj?.item?.transportStatementSeq);
    }
  }, []);

  const handleFilesUpload = async (e) => {
    dispatch(setLoading('GET'));
    const returns = await handleFiles(e);
    console.log(returns);
    const res = await Promise.all(
      returns?.map(async (ret) => {
        return await uploadFile(ret.file, ret.url);
      }),
    );
    const dataDTO = {
      transportStatementSeq: photoPopupObj?.item?.transportStatementSeq,
      addFiles: res.map((uploadUrl, idx) => {
        return {
          column: `UNLOAD${getToday()}_${uuidv4().split('-')[4]}_${idx}`,
          uploadUrl,
        };
      }),
    };
    const rs = await serviceStore.warehouseAction(`linehaul/unload/image/save`, 'POST', null, dataDTO);
    if (rs?.status === 200) {
      alert('사진 업로드에 성공하였습니다!');
      fetchPhotos(photoPopupObj?.item?.transportStatementSeq);
      setChecked([]);
    }
    dispatch(setLoading(null));
  };

  const handleFileRemove = async (e) => {
    const [idx, mainSeq] = e.target.id.split('_');
    const prompt = window.confirm('사진을 정말 삭제하시겠습니까?');
    if (prompt) {
      const dataDTO = {
        [mainSeq]: photoPopupObj?.item?.[mainSeq],
        deleteFiles: [files[idx]],
      };
      const rs = await serviceStore.warehouseAction(`linehaul/unload/image/save`, 'POST', null, dataDTO);
      if (rs?.status === 200) {
        alert('사진을 삭제하였습니다!');
        fetchPhotos(photoPopupObj?.item?.[mainSeq]);
        const _files = files?.filter((_, i) => i !== Number(idx));
        setFiles(_files);
        setChecked([]);
      }
    }
  };

  const handleFilesRemove = async (e) => {
    const idx = Number(e.target.id.split('_')[1]);
    const prompt = window.confirm('사진을 정말 삭제하시겠습니까?');
    if (prompt) {
      const dataDTO = {
        transportStatementSeq: photoPopupObj?.item?.transportStatementSeq,
        deleteFiles: [files[idx]],
      };

      const rs = await serviceStore.warehouseAction(`linehaul/unload/image/save`, 'POST', null, dataDTO);
      if (rs?.status === 200) {
        alert('사진을 삭제하였습니다!');
        fetchPhotos(photoPopupObj?.item?.transportStatementSeq);
        const _files = files?.filter((_, i) => i !== Number(idx));
        setFiles(_files);
        setChecked([]);
      }
    }
  };

  const handleCheckedFilesRemove = async (e) => {
    const idx = Number(e.target.id.split('_')[1]);
    const prompt = window.confirm('선택한 사진을 삭제하시겠습니까?');
    if (prompt) {
      const dataDTO = {
        transportStatementSeq: photoPopupObj?.item?.[photoPopupObj?.mainSeq],
        deleteFiles: checked.map((ele) => {
          return files[ele];
        }),
      };

      const rs = await serviceStore.warehouseAction(`linehaul/unload/image/save`, 'POST', null, dataDTO);
      if (rs?.status === 200) {
        alert('사진을 삭제하였습니다!');
        fetchPhotos(photoPopupObj?.item?.[photoPopupObj?.mainSeq]);
        const _files = files?.filter((_, i) => i !== Number(idx));
        setFiles(_files);
        setChecked([]);
      }
    }
  };

  const fetchPhotos = async (transportStatementSeq: number) => {
    const data = (await serviceStore.warehouseAction(`linehaul/unload/image/${transportStatementSeq}`, 'GET', null))?.data;
    if (data) {
      setFiles(data?.files);
    }
  };

  const [checked, setChecked] = useState([]);
  const handleCheckChange = (e) => {
    const thisIdx = Number(e.target.id.split('_')[1]);
    const set = new Set(checked);

    if (!set.has(thisIdx)) {
      // 없으면
      set.add(thisIdx);

      const _ = JSON.parse(JSON.stringify(checked));
      _.push(thisIdx);
      setChecked(_);
    } else {
      set.delete(thisIdx);
      setChecked(checked.filter((ele) => ele !== thisIdx));
    }
  };

  return (
    <CommonModalNew
      visible={photoPopupObj.visible}
      setVisible={() => setPhotoPopupObj(null)}
      title={`${photoPopupObj?.title} 사진보기`}
      subTitle={`${photoPopupObj?.item[photoPopupObj?.mainSeq]}`}
      rightTitle={
        <div>
          <Button className="btn btn-blue ssm">
            <label htmlFor={`add_purchasePhoto`} style={{ marginBottom: 0 }}>
              파일 업로드
            </label>
          </Button>
          <Button
            //
            disabled={checked?.length === 0}
            className="btn btn-cancel ssm"
            onClick={handleCheckedFilesRemove}
          >
            다중 삭제
          </Button>
        </div>
      }
      style={{ width: 1000 }}
      children={
        <div className="grid-wrapper div6">
          {files?.map((url, i) => {
            return (
              <Col key={`purchasePhoto_${i}_${uuidv4()}`} style={{ position: 'relative' }}>
                <ImageModal photoArray={files} idx={i} title={`사진`} deleteFn={handleFileRemove} mainSeq={photoPopupObj?.mainSeq} />
                <label htmlFor={`photo_${i}`} className={checked?.includes(i) ? 'photo-zone active' : 'photo-zone'}>
                  <span className="bck-font"> 사진 {i + 1}</span>
                  {url && (
                    <div style={{ display: 'inline-block' }}>
                      <input type="checkbox" id={`photo_${i}`} style={{ display: 'none' }} checked={checked?.includes(i)} onChange={handleCheckChange} />
                      <img src={CloseImg} id={`purchasePhoto_${i}`} alt="" onClick={handleFilesRemove} className="closeBtn" />
                    </div>
                  )}
                </label>
              </Col>
            );
          })}
          <div className="photo-zone">
            <span className="bck-font"></span>
            <input
              //
              style={{ display: 'none' }}
              type="file"
              multiple
              accept="image/*"
              id={`add_purchasePhoto`}
              onChange={handleFilesUpload}
            />
            <Button size="md" color="primary" outline>
              <label htmlFor={`add_purchasePhoto`} style={{ marginBottom: 0 }}>
                파일 업로드
              </label>
            </Button>
          </div>
        </div>
      }
    />
  );
};
