import { useEffect, useRef } from 'react';
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import GridBox from 'common/grid/gridBox';
import { delay } from 'common/util/counting';
import CommonModalNew from 'components/modal/commonModalNew';

export interface ISerialObj {
  visible?: boolean;
  item?: any;
  event?: string;
  searchObj?: string;
  quantityKey?: string;
  serialKey?: string;
  fetchFn?: (e: IGrid.ButtonClickEvent) => void;
}

interface ISerialPoupObj {
  serialsPopupObj: ISerialObj;
  setSerialsPopupObj: React.Dispatch<React.SetStateAction<ISerialObj>>;
}

export const SerialsPopup = ({ serialsPopupObj, setSerialsPopupObj }: ISerialPoupObj) => {
  const gridRef = useRef<AUIGrid>();
  useEffect(() => {
    void delay(300).then(() => {
      if (gridRef?.current) {
        if (serialsPopupObj?.item) {
          const serials = serialsPopupObj?.item?.[serialsPopupObj?.serialKey];
          const count = new Array(Number(serialsPopupObj?.item?.[serialsPopupObj?.quantityKey])).fill('');
          const data = [];
          count.forEach((ele, idx) => {
            if (serials?.[idx]) data.push(serials?.[idx]);
            else data.push('');
          });
          //
          gridRef.current?.setGridData(
            data?.map((ele) => {
              return { serialNumber: ele };
            }),
          );
        }
      }
    });
  }, [gridRef?.current]);

  return (
    <CommonModalNew
      visible={serialsPopupObj.visible}
      setVisible={() => setSerialsPopupObj(null)}
      title={serialsPopupObj.item.model}
      subTitle={serialsPopupObj.item.barcode}
      style={{ width: 400 }}
      children={
        <>
          <div className="grid-button-area only-right"></div>
          <GridBox
            gridRef={gridRef}
            columns={[
              {
                headerText: '시리얼번호',
                dataField: 'serialNumber',
              },
            ]}
            gridProps={{
              height: 500,
              editable: true,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
            }}
          />
        </>
      }
    />
  );
};
