import { Col, Row, Form } from 'reactstrap';
import { ISearchBoxProps } from 'hooks/grid/useSearch';
// redux
import { SelectD } from 'components/reactstrap/reactstrap';
import { InfraDetailSearchDTO } from 'interface/warehouse';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export const SearchBox = (props: ISearchBoxProps<InfraDetailSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">*인프라유형</label>
          <SelectD
            hasNull={false}
            value={
              searchObj?.infraType
                ? {
                    value: searchObj?.infraType,
                    label: MASTER_OBJ?.INFRA_TYPE?.[searchObj?.infraType],
                  }
                : null
            }
            options={MASTER_OPS?.INFRA_TYPE}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                infraType: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">인프라</label>
          <SelectD
            value={
              searchObj?.infraSeq
                ? {
                    value: searchObj?.infraSeq,
                    label: MASTER_OBJ?.INFRA?.[searchObj?.infraSeq],
                  }
                : null
            }
            options={MASTER_OPS?.INFRA}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                infraSeq: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[searchObj?.partnerSeq],
                  }
                : null
            }
            options={MASTER_OPS?.SELLER}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                  }
                : null
            }
            options={MASTER_OPS?.CENTER_AUTH}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품그룹</label>
          <SelectD
            value={
              searchObj?.modelGroup
                ? {
                    value: searchObj?.modelGroup,
                    label: MASTER_OBJ?.MODEL_GROUP?.[searchObj?.modelGroup],
                  }
                : null
            }
            options={MASTER_OPS?.MODEL_GROUP}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                modelGroup: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col />
      </Row>
    </Form>
  );
};
