import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, OptionItem, SelectD } from 'components/reactstrap/reactstrap';

import { KeyboardEvent, useEffect, useState } from 'react';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { resetAllSelections } from 'common/util/searchBox';
import { InventoryLiveModelSearchDTO } from 'interface/warehouse';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

export const SearchBox = (props: ISearchBoxProps<InventoryLiveModelSearchDTO>) => {
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_NUMBER: null,
    SEARCH_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_NUMBER, searchObj));
  }, [selectTypeObj?.SEARCH_NUMBER]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_SEQ, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };
  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">재고번호/시리얼번호</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_NUMBER
                    ? {
                        value: selectTypeObj?.SEARCH_NUMBER,
                        label: selectlabel(selectTypeObj?.SEARCH_NUMBER, masterOptions?.SEARCH_NUMBER),
                      }
                    : null
                }
                options={masterOptions?.SEARCH_NUMBER}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_NUMBER: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_NUMBER}
                value={searchObj?.[selectTypeObj?.SEARCH_NUMBER] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_NUMBER]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">주문번호/송장번호</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_SEQ
                    ? {
                        value: selectTypeObj?.SEARCH_SEQ,
                        label: selectlabel(selectTypeObj?.SEARCH_SEQ, masterOptions?.SEARCH_SEQ),
                      }
                    : null
                }
                options={masterOptions?.SEARCH_SEQ}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_SEQ: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_SEQ}
                value={searchObj?.[selectTypeObj?.SEARCH_SEQ] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_SEQ]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            options={masterOptions?.CENTER_AUTH}
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER_AUTH),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품그룹</label>
          <SelectD
            options={masterOptions?.MODEL_GROUP}
            value={
              searchObj?.modelGroup
                ? {
                    value: searchObj?.modelGroup,
                    label: selectlabel(searchObj?.modelGroup, masterOptions?.MODEL_GROUP),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                modelGroup: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품타입</label>
          <SelectD
            options={masterOptions?.MODEL_TYPE}
            value={
              searchObj?.modelType
                ? {
                    value: searchObj?.modelType,
                    label: selectlabel(searchObj?.modelType, masterOptions?.MODEL_TYPE),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                modelType: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            options={masterOptions?.SELLER_SELLER_AUTH}
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER_SELLER_AUTH),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem).value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">제품/제품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: selectlabel(selectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                      }
                    : null
                }
                options={masterOptions?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">기사</label>
          <div style={{ display: 'flex' }}>
            <Col md={6} style={{ display: 'inline-block' }}>
              <SelectD
                options={masterOptions?.DRIVER_AUTH}
                value={
                  searchObj?.userId
                    ? {
                        value: searchObj?.userId,
                        label: selectlabel(searchObj?.userId, masterOptions?.DRIVER_AUTH),
                      }
                    : null
                }
                onChange={({ value }) => {
                  setSearchObj({
                    ...searchObj,
                    userId: value,
                  });
                }}
              />
            </Col>
            <Col md={6} style={{ display: 'inline-block' }}>
              <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">유사검색여부</label>
          <div className="radio-select-area">
            <span
              className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: true,
                  };
                });
              }}
            >
              유사검색
            </span>
            <span
              className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: false,
                  };
                });
              }}
            >
              일치검색
            </span>
          </div>
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
