import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as IGrid from 'aui-grid';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { InventoryMoveInfoDTO, InventoryMoveDetailInfoDTO } from 'interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';
import { defaultGridProps } from 'common/grid/gridBox';
import { locationService } from 'pages/INVENTORY/3location/_services/service';

import AUIGrid, { mappingDefaultOptionToColumn } from 'modules/Grid';
import { useIsMounted } from 'hooks/basichooks';
import { delay } from 'common/util/counting';

export interface IDetailObj {
  visible?: boolean;
  mergeIdx?: number;
  item?: InventoryMoveInfoDTO;
  selected?: { [key: string]: any[] };
  dataField?: string;
}

export const DetailPopup = ({ detailObj, setDetailObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const gridRef = useRef<AUIGrid>();
  const columns: { [key: string]: IGrid.Column[] } = {
    moveDefectedInventorySeqListKr: [
      {
        headerText: '재고번호',
        dataField: 'inventorySeq',
      },
      {
        headerText: '송장유형',
        dataField: 'feeTypeKr',
      },
      {
        headerText: '회수주문번호',
        dataField: 'orderSeqForCollect',
      },
      {
        headerText: '회수송장번호',
        dataField: 'invoiceSeqForCollect',
      },
      {
        headerText: '고객명',
        dataField: 'customerName',
      },
      {
        headerText: '시리얼번호',
        dataField: 'serialNumber',
      },
    ],
    moveAvailableInventorySeqListKr: [
      {
        headerText: '재고번호',
        dataField: 'inventorySeq',
      },
      {
        headerText: '지정일',
        dataField: 'deliveryDate',
      },
      {
        headerText: '기사출고창고',
        dataField: 'loanCenterCodeKr',
      },
      {
        headerText: '주문번호',
        dataField: 'orderSeq',
      },
      {
        headerText: '송장번호',
        dataField: 'invoiceSeq',
      },
      {
        headerText: '고객명',
        dataField: 'customerName',
      },
      {
        headerText: '시리얼번호',
        dataField: 'serialNumber',
      },
    ],
    moveEtcInventorySeqListKr: [
      {
        headerText: '재고번호',
        dataField: 'inventorySeq',
      },
      {
        headerText: '재고유형',
        dataField: 'availableTypeKr',
      },
      {
        headerText: '주문번호',
        dataField: 'orderSeq',
      },
      {
        headerText: '회수송장번호',
        dataField: 'invoiceSeqForCollect',
      },
      {
        headerText: '설치송장번호',
        dataField: 'invoiceSeqForInstall',
      },
      {
        headerText: '고객명',
        dataField: 'customerName',
      },
      {
        headerText: '시리얼번호',
        dataField: 'serialNumber',
      },
    ],
  };

  const thisKey = detailObj?.item?.forMerges;
  const isMounted = useIsMounted();
  useEffect(() => {
    void delay(100).then(() => {
      console.log(detailObj?.selected);
      if (isMounted) {
        gridRef.current.bind('rowAllCheckClick', filterRowAllCheckClick);
        gridRef.current.bind('rowCheckClick', isAllCheckCounter);
      }
    });
  }, [isMounted]);
  const filterRowAllCheckClick = (checked: boolean) => {
    if (checked) {
      gridRef.current.addCheckedRowsByValue('checkable', true);
    } else {
      gridRef.current.addUncheckedRowsByValue('checkable', true);
    }
  };

  const isAllCheckCounter = (event) => {
    const checkableLength = gridRef.current?.getGridData()?.filter((ele) => ele.checkable)?.length;
    const checkedLength = gridRef.current?.getCheckedRowItemsAll()?.length;
    if (event.checked) {
      if (checkableLength === checkedLength) {
        gridRef.current?.setAllCheckedRows(true);
      }
    } else {
      if (checkableLength !== checkedLength) {
        gridRef.current?.setAllCheckedRows(false);
      }
    }
  };

  useEffect(() => {
    if (detailObj?.visible) fetchPaging();
  }, [detailObj?.visible]);

  const returnThese = () => {
    const items = gridRef.current.getCheckedRowItemsAll();
    const selected = JSON.parse(JSON.stringify(detailObj?.selected));
    selected[thisKey][detailObj?.mergeIdx][detailObj?.dataField?.split('Kr')[0]] = items?.map((ele) => ele.inventorySeq);
    alert('저장되었습니다');
    setDetailObj((prev) => {
      return {
        ...prev,
        selected,
      };
    });
  };

  const fetchPaging = async () => {
    dispatch(setLoading('GET'));
    const searchDTO = {
      centerCode: detailObj?.item?.centerCode,
      locationCode: detailObj?.item?.locationCode,
      modelStockSeq: detailObj?.item?.modelStockSeq,
      availableType: detailObj?.dataField?.includes('moveDefectedInventorySeqList') ? 'DEFECTED' : detailObj?.dataField?.includes('moveEtcInventorySeqList') ? 'ETC' : 'AVAILABLE',
    };
    let otherInvens = [];
    let thisInvens = [];
    detailObj?.selected?.[thisKey]?.forEach((row, idx) => {
      if (idx !== detailObj?.mergeIdx) otherInvens = otherInvens?.length > 0 ? [...otherInvens, ...row?.[detailObj?.dataField?.split('Kr')[0]]] : row?.[detailObj?.dataField?.split('Kr')[0]];
      else thisInvens = row?.[detailObj?.dataField?.split('Kr')[0]];
    });

    const data = await locationService.getInventoryMoveAction('inventory/move/detail/info', searchDTO, false);
    const dataKr = (data as InventoryMoveDetailInfoDTO[])?.map((row) => {
      return {
        ...row,
        availableTypeKr: MASTER_OBJ?.INVA_TYPE?.[row?.availableType],
        feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row?.feeType],
        loanCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.loanCenterCode],
        checkable: otherInvens?.includes(row?.inventorySeq) ? false : true,
      };
    });
    setDetailObj((prev) => {
      return {
        ...prev,
      };
    });
    gridRef.current.setGridData(dataKr);
    gridRef.current.setCheckedRowsByValue('inventorySeq', thisInvens);
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title={detailObj?.dataField === 'moveDefectedInventorySeqListKr' ? '불용할당재고 선택' : detailObj?.dataField === 'moveEtcInventorySeqListKr' ? '고객보관재고 선택' : '양품할당재고 선택'}
      style={{ width: 1300 }}
      visible={detailObj?.visible}
      rightTitle={
        <>
          <button
            className="btn btn-secondary sm"
            onClick={() => {
              returnThese();
            }}
          >
            저장
          </button>
        </>
      }
      setVisible={() => {
        setDetailObj((prev) => {
          return {
            ...prev,
            visible: false,
            item: null,
            mergeIdx: null,
            dataField: null,
          };
        });
      }}
      children={
        <>
          <div className="presenterGridBox">
            <AUIGrid
              //
              ref={gridRef}
              name={'detailModal'}
              columnLayout={mappingDefaultOptionToColumn(columns[detailObj?.dataField])}
              gridProps={{
                //
                ...defaultGridProps,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
                independentAllCheckBox: true,
                rowCheckDisabledFunction(rowIndex, isChecked, item) {
                  return item?.checkable;
                },
              }}
            />
          </div>
        </>
      }
    />
  );
};
