import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import useGridButton from 'hooks/grid/useGridButton';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { serviceStore } from 'services/services';
import { IPopupObj, Popup } from './component/popup';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const labellingKr = (data: PagingListDTO<any>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: row.partnerSeqs?.map((ele) => MASTER_OBJ?.SELLER_WHOLE?.[ele])?.join(','),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };
  const fetchPaging = async (searchObj: any) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.warehouseAction(`location/select/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<any>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction(`location/select/list`, 'GET', searchObj, null))?.data as any[],
      },
      true,
    );

    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '로케이션지정내역' });

    dispatch(setLoading(null));
  };

  const [popupObj, setPopupObj] = useState<IPopupObj>();
  const popupPopup = (items) => {
    setPopupObj({
      visible: true,
      type: 'confirm',
      items,
      searchObj: searchObj,
      fetchFn: (data) => wrappedFetchList(data),
    });
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      //
      if (id === 'SS_LIST_CHANGEDATE') {
        popupPopup(items);
      } else {
        alert('준비중입니다');
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    handleCheckItems(id);
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const columns: IGrid.Column[] = [
    {
      headerText: '배차상태',
      dataField: '',
    },
    {
      headerText: '출고지시번호',
      dataField: '',
    },
    {
      headerText: '배송권역',
      dataField: '',
    },
    {
      headerText: '배송예정일',
      dataField: '',
    },
    {
      headerText: '오전/오후',
      dataField: '',
    },
    {
      headerText: '주문번호',
      dataField: '',
    },
    {
      headerText: '송장번호',
      dataField: '',
    },
    {
      headerText: '출고창고',
      dataField: '',
    },
    {
      headerText: '제품',
      dataField: '',
    },
    {
      headerText: '제품명',
      dataField: '',
    },
    {
      headerText: '제품수량',
      dataField: '',
    },
    {
      headerText: '제품CMB',
      dataField: '',
    },
    {
      headerText: '합산CMB',
      dataField: '',
    },
    {
      headerText: '수령인 주소',
      dataField: '',
      width: 350,
    },
    {
      headerText: '운송정보',
      children: [
        {
          headerText: '이름',
          dataField: '',
        },
        {
          headerText: '연락처',
          dataField: '',
        },
        {
          headerText: '출발창고',
          dataField: '',
        },
      ],
    },
    {
      headerText: '간선필요 여부',
      dataField: '',
    },
    {
      headerText: '배차확정자',
      dataField: '',
    },
    {
      headerText: '배차확정시각',
      dataField: '',
    },
  ];

  return (
    <div className="page-content">
      {popupObj?.visible && <Popup popupObj={popupObj} setPopupObj={setPopupObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            fixedColumnCount: 1,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns.filter((_, idx) => idx > 1)} />
      </div>
    </div>
  );
};

export default Index;
