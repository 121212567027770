import { KeyboardEvent } from 'react';
import { Col, Row, Form } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { WarehouseSearchDTO } from 'interface/warehouse';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export const SearchBox = (props: ISearchBoxProps<WarehouseSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };
  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">창고명</label>
          <InputD
            value={searchObj?.centerName || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                centerName: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고상태</label>
          <SelectD
            value={
              searchObj?.useFlag
                ? {
                    value: searchObj?.useFlag,
                    label: MASTER_OBJ?.USE_FLAG?.[searchObj?.useFlag + ''],
                  }
                : null
            }
            options={MASTER_OPS?.USE_FLAG}
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                useFlag: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고타입</label>
          <SelectD
            value={
              searchObj?.centerType
                ? {
                    value: searchObj?.centerType,
                    label: MASTER_OBJ?.WH_TYPE?.[searchObj?.centerType],
                  }
                : null
            }
            options={MASTER_OPS?.WH_TYPE}
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                centerType: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">위탁사</label>
          <SelectD
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: MASTER_OBJ?.SELLER_NETWORK_AUTH?.[searchObj?.partnerSeq],
                  }
                : null
            }
            options={MASTER_OPS?.SELLER_NETWORK_AUTH}
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: value,
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
      </Row>
    </Form>
  );
};
