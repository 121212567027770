import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { Col, Row, Form, Input, Table, Button } from 'reactstrap';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';

import { returnDateyyyymmdd, returnYoil } from 'common/util/dateParsingFn';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { CenterTable, DetailScheduleTable, DriverTable, MultiItemsPopup } from './component/scheduleSection';

// img
import delivery from 'common/navermaps/flags/delivery.svg';
import visit from 'common/navermaps/flags/visit.svg';
import collect from 'common/navermaps/flags/collect.svg';
import exchange from 'common/navermaps/flags/exchange.svg';
import move from 'common/navermaps/flags/move.svg';
import store from 'common/navermaps/flags/store.svg';
import as from 'common/navermaps/flags/as.svg';
import fix from 'common/navermaps/flags/fix.svg';

import CloseImg from 'assets/images/close/cross.png';

import driver1 from './component/driver1.png';

import { defaultMapObj } from './container';

// component
import { round2 } from 'common/util/counting';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';
import { coloringData } from 'common/util/invoice';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';

export const CloseBtnStyle = {
  width: 17,
  position: 'absolute',
  right: -10,
  top: 15,
  cursor: 'pointer',
  zIndex: 100,
};

const Presenter = (props) => {
  const { currentPage, scheduleBoardData, popupDataObj, setPopupDataObj, multiItemsPopupObj, setMultiItemsPopupObj, searchObj, setDetailScheduleObj, detailScheduleObj } = props;
  const [messageAllWhichObj, setMessageAllWhichObj] = useState();

  useEffect(() => {
    if (multiItemsPopupObj?.visible) {
      setDetailScheduleObj({
        ...detailScheduleObj,
        visible: false,
        data: null,
      });
    }
  }, [multiItemsPopupObj]);

  return (
    <>
      <div
        className="page-content"
        style={{
          width: '100%',
          display: !currentPage ? 'block' : currentPage === 1 ? 'block' : 'none',
        }}
      >
        {popupDataObj?.visible && <PopupData popupDataObj={popupDataObj} setPopupDataObj={setPopupDataObj} searchObj={searchObj} />}
        {multiItemsPopupObj?.visible && <MultiItemsPopup multiItemsPopupObj={multiItemsPopupObj} setMultiItemsPopupObj={setMultiItemsPopupObj} searchObj={searchObj} />}
        {messageAllWhichObj?.visible && <MessageAllWhichObj messageAllWhichObj={messageAllWhichObj} setMessageAllWhichObj={setMessageAllWhichObj} />}
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox" style={{ position: 'relative' }}>
          {scheduleBoardData ? <GridBox {...props} setMessageAllWhichObj={setMessageAllWhichObj} /> : <HowToUse />}
        </div>
      </div>
    </>
  );
};

export default Presenter;

const HowToUse = () => {
  return (
    <div className="howtouse-wrapper">
      <div>HOW TO USE</div>
      <div>■ 검색자 설정 후 조회 버튼을 눌러주세요. (배송설치일, 창고 필수값)</div>
      <div>■ 배송설치일, 창고 선택시 창고에 속한 모든 기사의 당일 스케줄이 출력됩니다</div>
      <div>■ 배송설치일, 창고, 기사 선택시 한 기사의 2주치 스케줄이 출력됩니다</div>
    </div>
  );
};

const MessageAllWhichObj = ({ messageAllWhichObj, setMessageAllWhichObj }) => {
  const [filteredData, setFilteredData] = useState();
  const [checkedItem, setCheckedItem] = useState('confirmed');

  useEffect(() => {
    setFilteredData({
      confirmed: messageAllWhichObj?.data?.filter((ele) => ele.confirmFlag),
      unconfirmed: messageAllWhichObj?.data?.filter((ele) => !ele.confirmFlag),
    });
  }, []);

  return (
    <CommonModalNew
      title="알림메시지 발송"
      visible={messageAllWhichObj?.visible}
      setVisible={() => {
        setMessageAllWhichObj({
          ...messageAllWhichObj,
          visible: false,
          data: null,
        });
      }}
      rightTitle={
        <div>
          <Button
            className="btn btn-orange ssm"
            onClick={() => {
              if (window.confirm(`현 페이지의 모든 ${checkedItem === 'confirmed' ? '확정' : '미확정'}된 스케줄에 대해 알림 메시지를 보내시겠습니까?`)) {
                messageAllWhichObj?.messageFn(checkedItem === 'confirmed' ? filteredData.confirmed : filteredData.unconfirmed);
              }
            }}
          >
            발송
          </Button>
        </div>
      }
      children={
        <>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <span>
              <Input
                type="radio"
                disabled={filteredData?.confirmed?.length === 0}
                checked={checkedItem === 'confirmed'}
                onClick={() => {
                  setCheckedItem('confirmed');
                }}
              />
              확정 {filteredData?.confirmed?.length}건
            </span>
            <span>
              <Input
                type="radio"
                disabled={filteredData?.unconfirmed?.length === 0}
                checked={checkedItem === 'unconfirmed'}
                onClick={() => {
                  setCheckedItem('unconfirmed');
                }}
              />
              미확정 {filteredData?.unconfirmed?.length}건
            </span>
          </div>
        </>
      }
    />
  );
};

const Search = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const { searchObj, setSearchObj, mapObj, setMapObj, filteredOptions, detailScheduleObj, setDetailScheduleObj, fetchTableData } = props;

  useEffect(() => {
    if (mapObj?.visible) setMapObj(defaultMapObj);
    if (detailScheduleObj?.visible) {
      setDetailScheduleObj({
        ...detailScheduleObj,
        visible: false,
        data: null,
      });
    }
  }, [searchObj]);

  useEffect(() => {
    setSearchObj({
      ...searchObj,
      userId: null,
    });
  }, [searchObj?.centerCode]);

  useEffect(() => {
    if (searchObj?.centerCode) {
      dispatch(setLoading('GET'));
      fetchTableData(searchObj, masterOptions);
    }
  }, [searchObj?.deliveryDate, searchObj?.centerCode, searchObj?.userId]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!searchObj?.centerCode) {
        alert('창고값은 필수검색자입니다!');
      } else {
        dispatch(setLoading('GET'));
        fetchTableData(searchObj, masterOptions);
      }
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">*배송일</label>
            <FlatpickrD
              value={searchObj?.deliveryDate}
              onChange={(date) => {
                setSearchObj({
                  ...searchObj,
                  deliveryDate: returnDateyyyymmdd(date[0]),
                });
              }}
              options={{
                mode: 'single',
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">*창고</label>
            <SelectD
              value={
                searchObj?.centerCode
                  ? {
                      value: searchObj?.centerCode,
                      label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER_WHOLE),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: value,
                });
              }}
              options={masterOptions?.CENTER_AUTH}
            />
          </Col>
          <Col>
            <label className="col-form-label">설치기사</label>
            <SelectD
              isDisabled={!searchObj?.centerCode}
              value={
                searchObj?.userId
                  ? {
                      value: searchObj?.userId,
                      label: selectlabel(searchObj?.userId, masterOptions?.DRIVER_WHOLE),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  userId: value,
                });
              }}
              options={filteredOptions?.DRIVER_NAME}
            />
          </Col>
          <Col>
            <label className="col-form-label">판매사</label>
            <SelectD
              value={
                searchObj?.partnerSeq
                  ? {
                      value: searchObj?.partnerSeq,
                      label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER_WHOLE),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: value,
                });
              }}
              options={masterOptions?.SELLER_SELLER_AUTH}
            />
          </Col>
          <Col>
            <label className="col-form-label">스케줄 확정 여부</label>
            <SelectD
              value={
                searchObj?.confirmFlag
                  ? {
                      value: searchObj?.confirmFlag,
                      label: selectlabel(searchObj?.confirmFlag, masterOptions?.CONFIRM_FLAG),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  confirmFlag: value,
                });
              }}
              options={masterOptions?.CONFIRM_FLAG}
            />
          </Col>
          <Col>
            <label className="col-form-label">배송일지정 여부</label>
            <SelectD
              value={
                searchObj?.promiseFlag
                  ? {
                      value: searchObj?.promiseFlag,
                      label: selectlabel(searchObj?.promiseFlag, masterOptions?.FIX_DATE),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  promiseFlag: value,
                });
              }}
              options={masterOptions?.FIX_DATE}
            />
          </Col>
          <Col style={{ textAlign: 'left' }}>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                if (!searchObj?.centerCode) {
                  alert('창고값은 필수검색자입니다!');
                } else {
                  dispatch(setLoading('GET'));
                  fetchTableData(searchObj, masterOptions);
                }
              }}
            >
              조회
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const GridBox = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { searchObj, setSearchObj, scheduleBoardData, confirmAll, messageAll, detailScheduleObj, mapObj, setMapObj, setMessageAllWhichObj } = props;

  const handleClickAiFill = (id) => {
    const isYoil = id.includes('yoil');
    const isUp = id.includes('Up');

    const _searchObj = { ...searchObj };
    let idx;
    let _deliveryDate = searchObj?.deliveryDate;
    let _centerCode = searchObj?.centerCode;

    if (isYoil) {
      _deliveryDate = returnDateyyyymmdd(new Date(_searchObj?.deliveryDate).setDate(new Date(searchObj?.deliveryDate).getDate() + (isUp ? +1 : -1)));
    } else {
      masterOptions?.CENTER_WHOLE.forEach((ele, i) => {
        if (ele.value === _searchObj?.centerCode) idx = i;
      });

      if (isUp) {
        if (idx !== masterOptions?.CENTER_WHOLE.length - 1) {
          _centerCode = masterOptions?.CENTER_WHOLE?.[idx + 1]?.value;
        }
      } else {
        if (idx !== 0) {
          _centerCode = masterOptions?.CENTER_WHOLE?.[idx - 1]?.value;
        }
      }
    }

    setSearchObj({
      ..._searchObj,
      deliveryDate: _deliveryDate,
      centerCode: _centerCode,
    });
  };

  return (
    <>
      <div
        className="status-panel"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <label>총 건수</label> {scheduleBoardData.totalConfirm + scheduleBoardData.totalUnConfirm || '-'} 건 &nbsp;
        <label>확정</label> {scheduleBoardData.totalConfirm || '-'} 건 &nbsp;
        <label>미확정</label> {scheduleBoardData.totalUnConfirm || '-'} 건 &nbsp;
        <label className="blue">완료</label> {scheduleBoardData.schedule?.filter((ele) => ele.invoiceStatus?.includes('FINISH')).length || '-'} 건 &nbsp;
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
          }}
        >
          <AiFillCaretLeft className="AiFillLikeBtn" onClick={() => handleClickAiFill('yoilDown')} />
          <span>{searchObj?.deliveryDate + ' (' + returnYoil(searchObj?.deliveryDate) + ')'}</span>
          <AiFillCaretRight className="AiFillLikeBtn" onClick={() => handleClickAiFill('yoilUp')} />
          <AiFillCaretLeft className="AiFillLikeBtn" onClick={() => handleClickAiFill('centerDown')} />
          <span>{selectlabel(searchObj?.centerCode, masterOptions?.CENTER_WHOLE)}</span>
          <AiFillCaretRight className="AiFillLikeBtn" onClick={() => handleClickAiFill('centerUp')} />
          {searchObj?.userId && (
            <div>
              <img
                src={searchObj?.userId?.profileImage ? searchObj?.userId?.profileImage : driver1}
                alt="썸네일"
                style={{
                  width: 35,
                  height: 35,
                  borderRadius: 30,
                  marginRight: 10,
                  marginLeft: 20,
                }}
              />
              <span>{searchObj?.userId ? selectlabel(searchObj?.userId, masterOptions?.DRIVER_WHOLE) + ' 기사님' : ''}</span>
            </div>
          )}
        </div>
        <CountingPanel schedule={scheduleBoardData?.schedule} />
        <div style={{ display: 'flex' }}>
          <button
            className="btn btn-secondary"
            onClick={() =>
              setMessageAllWhichObj({
                visible: true,
                data: scheduleBoardData?.schedule,
                messageFn: (data) => messageAll(data),
              })
            }
          >
            알림메시지 발송
          </button>
          <button
            className="btn btn-magam"
            onClick={() => {
              confirmAll({ items: scheduleBoardData?.schedule, searchObj });
            }}
          >
            전체 확정
          </button>
        </div>
      </div>
      <Row>
        <Col md={detailScheduleObj?.visible ? 8 : mapObj?.visible ? 6 : 12}>{searchObj?.userId ? <DriverTable {...props} /> : <CenterTable {...props} />}</Col>
        {detailScheduleObj?.visible && (
          <Col key={`detailItem_${detailScheduleObj?.data?.customerOrderInvoiceSeq}_${detailScheduleObj?.data?.invoiceStatus}`}>
            <DetailScheduleTable {...props} />
          </Col>
        )}
        {mapObj?.visible && (
          <Col>
            <Map mapObj={mapObj} setMapObj={setMapObj} />
          </Col>
        )}
      </Row>
    </>
  );
};

const CountingPanel = ({ schedule }) => {
  const [flagData, setFlagData] = useState();

  useEffect(() => {
    if (schedule?.length > 0) setFlagData(coloringData(schedule));
  }, [schedule]);

  return (
    <div className="float-nav vertical">
      <img id="delivery-mode" src={delivery} alt="" />
      <span>{flagData?.delivery || '-'} 건</span>
      <img id="visit-mode" src={visit} alt="" />
      <span>{flagData?.visit || '-'}건</span>
      <img id="collect-mode" src={collect} alt="" />
      <span>{flagData?.collect || '-'}건</span>
      <img id="exchange-mode" src={exchange} alt="" />
      <span>{flagData?.exchange || '-'}건</span>
      <img id="move-mode" src={move} alt="" />
      <span>{flagData?.move || '-'}건</span>
      <img id="store-mode" src={store} alt="" />
      <span>{flagData?.store || '-'}건</span>
      <img id="as-mode" src={as} alt="" />
      <span>{flagData?.as || '-'}건</span>
      <img id="fix-mode" src={fix} alt="" />
      <span>{flagData?.fix || '-'}건</span>
    </div>
  );
};

const Map = ({ mapObj, setMapObj }) => {
  const navermaps = window.naver.maps;
  const [totalData, setTotalData] = useState({
    count: 0,
    installDuration: 0,
    distance: 0,
  });

  const [mapProps, setMapProps] = useState({
    zoom: 10,
    minZoom: 7,
    center: mapObj?.center,
    zoomControl: false, //줌 컨트롤의 표시 여부
  });

  useEffect(() => {
    initPanel();
    initMap();
  }, [mapObj?.schedule]);

  const initPanel = () => {
    const data = mapObj?.mapPanelData;
    let duration = 0;
    let count = data.length;
    let distance = [];
    let rsDistance = 0;
    data.forEach((ele) => {
      duration += ele.installDuration;
      distance.push(ele.distance);
    });

    distance.forEach((d) => {
      if (d && typeof d === 'number') rsDistance += d;
    });

    rsDistance = round2(rsDistance);
    setTotalData({
      count: count,
      installDuration: duration,
      distance: rsDistance,
    });
  };

  const initMap = async () => {
    const map = new navermaps.Map('map', mapProps);
    const path = [];
    mapObj.schedule?.forEach((sc, index) => {
      const p = new navermaps.LatLng(sc.latitude, sc.longitude);
      path.push(p);
      let marker = new navermaps.Marker({
        map: map,
        position: p,
        icon: {
          content: `
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.3" cx="18" cy="18" r="18" fill=${sc.color} />
          <circle cx="18.1528" cy="18.1528" r="12.4268" fill=${sc.color} />
          <span class="index" >${index + 1}</span>
          </svg>
          `,
          size: new navermaps.Size(22, 35),
          anchor: new navermaps.Point(18, 18),
          animation: navermaps.Animation.BOUNCE,
        },
      });
    });
    // draw polyline
    const polyline = new navermaps.Polyline({
      map: map,
      path: path,
      clickable: true,
      strokeColor: '#413E52',
      strokeStyle: 'shortdash',
      strokeOpacity: 0.7,
      strokeWeight: 3,
    });
  };

  return (
    <>
      <img src={CloseImg} alt="" onClick={() => setMapObj(defaultMapObj)} style={CloseBtnStyle} />
      <div className="onmap_desc">
        총 배송건수: {totalData.count}건 <br />총 설치소요시간: {totalData.installDuration}분 <br />총 직선이동거리: {totalData.distance}km <br />
        {/* 총 설치대행료: (대행료 적용시) */}
      </div>
      <div id="map" style={{ width: '100%', height: 'calc(100% - 40px)', marginTop: 30 }} />
    </>
  );
};

const PopupData = ({ popupDataObj, setPopupDataObj, searchObj }) => {
  const { MASTER_OBJ } = useSelector((state) => state.menu);
  const [popupdata, setPoupdata] = useState();

  useEffect(() => {
    setPoupdata(popupDataObj?.data);
  }, []);

  return (
    <CommonModalNew
      title={popupDataObj?.title}
      style={{ width: 1200 }}
      visible={popupDataObj?.visible}
      setVisible={() => setPopupDataObj({ ...popupDataObj, visible: false, data: null })}
      rightTitle={
        <div style={{ display: 'flex' }}>
          <button
            className="btn btn-secondary ssm"
            onDoubleClick={() => {
              return;
            }}
            onClick={(e) => {
              e.preventDefault();
              popupDataObj?.changeFn({ arr: popupdata, searchObj });
            }}
          >
            일괄 변경
          </button>
          <button
            className="btn btn-search ssm"
            onDoubleClick={() => {
              return;
            }}
            onClick={(e) => {
              e.preventDefault();
              popupDataObj?.confirmAllFn({ items: popupdata, searchObj });
            }}
          >
            일괄 확정
          </button>
        </div>
      }
      children={
        <>
          <Table bordered>
            <thead>
              <tr>
                <th style={{ width: 50 }} rowSpan={2}>
                  배송
                  <br />
                  순서
                </th>
                <th style={{ width: 100 }} rowSpan={2}>
                  판매사
                </th>
                <th style={{ width: 100 }} rowSpan={2}>
                  송장번호
                </th>
                <th style={{ width: 200 }} rowSpan={2}>
                  송장유형
                </th>
                <th style={{ width: 200 }} rowSpan={2}>
                  수령인
                </th>
                <th style={{ width: 400 }} rowSpan={2}>
                  주소
                </th>
                <th style={{ width: 150 }} rowSpan={2}>
                  확정/
                  <br />
                  미확정
                </th>
                <th style={{ width: 150 }} rowSpan={2}>
                  송장상태
                </th>
                <th style={{ width: 600 }} colSpan={2}>
                  배송예정일
                </th>
                <th style={{ width: 40 }} rowSpan={2}>
                  변경
                </th>
              </tr>
              <tr>
                <th style={{ width: 300 }}>변경전</th>
                <th style={{ width: 300 }}>변경후</th>
              </tr>
            </thead>
            <tbody>
              {popupdata?.map((sc = {}, idx) => (
                <tr key={`schedulemodallist_${uuidv4()}`}>
                  <th>{idx + 1}</th>
                  <th>{MASTER_OBJ?.SELLER_WHOLE?.[sc.partnerSeq]}</th>
                  <th>{sc.customerOrderInvoiceSeq}</th>
                  <th>{MASTER_OBJ?.FEE_TYPE?.[sc.feeType]}</th>
                  <th>{sc.receiverName}</th>
                  <th>{sc.receiverAddr1 + ' ' + sc.receiverAddr2}</th>
                  <th>{sc.confirmFlag ? '확정' : '미확정'}</th>
                  <th>{MASTER_OBJ?.INVOICE_STATUS?.[sc.invoiceStatus]}</th>
                  <th>{sc.deliveryDatetime}</th>
                  <th>
                    <FlatpickrD
                      value={sc?.newDate}
                      onChange={(date) => {
                        const _popupdata = popupdata?.length > 0 ? [...popupdata] : [];
                        _popupdata[idx]['newDate'] = returnDateyyyymmdd(date);
                        setPoupdata(_popupdata);
                      }}
                      options={{
                        mode: 'single',
                      }}
                    />
                  </th>
                  <th>
                    <button
                      className="btn btn-etc sssm"
                      id={`${idx}-schduled`}
                      key={`${idx}-schduled-key`}
                      onDoubleClick={() => {
                        return;
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!sc?.newDate) {
                          alert('변경후를 선택하세요!');
                        } else {
                          popupDataObj?.changeFn({ arr: [sc], searchObj });
                        }
                      }}
                    >
                      변경
                    </button>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      }
    />
  );
};
