import * as IGrid from 'aui-grid';
import { InventoryLocationListDTO, LocationListDTO } from 'interface/warehouse';

export const makeObjtoFooter = <T>(data: T) => {
  const footer = Object.keys(data)
    .map((key) => {
      // makeFooter
      if (typeof data[key] === 'number' || key?.includes('Qunatity'))
        return {
          positionField: key,
          labelText: data[key] + '',
          style: 'aui-grid-my-column',
          formatString: '#,##0',
        };
    })
    ?.filter((ele) => ele);
  return footer;
};

export const LOCATION_AVAILABLE_CENTER = ['W001', 'W004'];
export const CENTER_CELL_NUMBER_LENGTH = { W001: -1 };
export const DOCKS = ['DOCK', 'STAGE', 'CROSS', 'SHORT', 'TEMP', 'AS'];
// map-grid
export const makeColumns = (data: string[]): IGrid.Column[] => {
  return data.map((columnName, idx) => {
    if (columnName === '0') {
      return {
        //
        // dataField: 'emptyground',
        dataField: `col-${idx}`,
        headerStyle: 'aui-grid-user-custom-header',
        style: 'aui-grid-user-custom-header',
        width: 10,
        editable: false,
      };
    } else {
      return {
        headerText: '',
        dataField: `col-${idx}`,
        renderer: {
          type: IGrid.RendererKind.TemplateRenderer,
        },
        labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
          let str = '';
          str += '<div>';
          str += `<div>${value}</div>`;
          str += item[value + '_SUM'] ? `<div class="bold">${item[value + '_SUM']}</div>` : item[value + '_SUM'] === 0 ? '<div class="bold">0</div>' : '';
          str += '</div>';
          return str;
        },
        styleFunction: function (rowIndex, columnIndex, value, headerText, item, dataField) {
          const activeData = item.activeData ? JSON.parse(item.activeData) : '';
          if (activeData?.locationCode?.includes(value)) {
            return 'aui-grid-locaiton-selected-stage';
          }

          if (item[`${value}_INVENTORY`] > 0) {
            // console.log(item[`${value}_INVENTORY`]);
            return `aui-grid-location-inventory-${item[value + '_INVENTORY']}`;
          }
          return '';
        },
      };
    }
  });
};

export const mappingMapRowData = (inventories: LocationListDTO[], map: string[][]) => {
  const groups = [];
  map.forEach((rows, i) => {
    const group = {};
    rows.forEach((cel, idx) => {
      const cell = cel === '0' ? null : inventories.filter((ele) => ele.locationCode?.includes(cel))[0];

      group[`col-${idx}`] = {
        locationCode: cel,
        sum: cell?.quantity || 0,
        floors: cell ? cell?.notEmptyFloorCount + '/' + cell?.totalFloorCount : cell,
      };
    });
    groups.push(group);
  });
  return groups;
};

export const mappingDockRowData = (data: InventoryLocationListDTO[], type: string) => {
  const sum =
    data?.length > 0
      ? data
          ?.map((ele) => ele.quantity)
          ?.reduce((acc, cur) => {
            return acc + cur;
          })
      : 0;

  return {
    locationCode: type,
    sum: sum,
    rawdata: JSON.stringify(data),
  };
};

export const colorWhatModi = (columns: IGrid.Column[], checked: string[]): IGrid.Column[] => {
  const checkedFiltered = checked.filter((ele) => ele);
  //
  const colored = columns.map((column) => {
    return {
      ...column,
      headerStyle: checked?.includes(column.dataField) ? 'canMod-header' : 'white-header',
    };
  });
  return colored;
};
