import { ReactNode, useMemo, useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { PagingListDTO } from 'interface/util';
import { serviceStore } from 'services/services';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import PrintGridBox from 'common/grid/printGridBox';
import AUIGrid from 'modules/Grid';
import { SalesChannelDTO, SalesChannelSearchDTO } from 'interface/order';
import SalesModal, { IModalObj } from './component/salesModal';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const [modalObj, setModalObj] = useState<IModalObj>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const labellingKr = (data: PagingListDTO<SalesChannelDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        typeKr: MASTER_OBJ?.['SALES_CHANNEL_TYPE']?.[row?.type],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: SalesChannelSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.orderAction(`salesChannel/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<SalesChannelDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.orderAction(`salesChannel`, 'GET', searchObj, null))?.data as SalesChannelDTO[],
      },
      true,
    );
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '판매채널_관리' });
    dispatch(setLoading(null));
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const columns: IGrid.Column[] = [
    {
      headerText: '판매조직코드',
      dataField: 'type',
    },
    {
      headerText: '판매조직명',
      dataField: 'typeKr',
    },
    {
      headerText: '판매채널코드',
      dataField: 'salesChannelSeq',
    },
    {
      headerText: '판매채널명',
      dataField: 'salesChannelName',
    },
  ];

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'SALESCHANNEL_MANAGE') {
      setModalObj({
        visible: true,
        type: null,
        data: null,
        searchObj,
        fetchFn: (searchObj) => wrappedFetchList(searchObj),
      });
    } else if (id === 'SALESCHANNEL_EXCELDOWN') {
      downloadExcel('main');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {modalObj?.visible && <SalesModal modalObj={modalObj} setModalObj={setModalObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox gridRef={gridRef} columns={columns} gridProps={{ showRowAllCheckBox: false, showRowCheckColumn: false }} />
        <PrintGridBox gridRef={excelGridRef} columns={columns.filter((_, idx) => idx > 0)} />
      </div>
    </div>
  );
};

export default Index;
