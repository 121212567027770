import { useEffect, useState } from 'react';

// img
import driver1 from './img/driver1.png';
import inoutLabel from './img/inoutLabel.png';
import orderNdeliveryLabel from './img/orderNdeliveryLabel.png';

//dummy
import { Map } from './map.js';

// redux
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
//component
import { FlatpickrD } from 'components/reactstrap/reactstrap';
// lib
import { v4 as uuidv4 } from 'uuid';
import ReactApexChart from 'react-apexcharts';

//utils
import { getToday, returnDatemmddKr, returnDateyyyymmdd, returnYoil } from 'common/util/dateParsingFn';
import { selectlabel, selectvalue } from 'common/master/codeMasterReturnHelper';

//styles
import styles from './presenter.module.scss';
import { ReadOnlyPopup } from '../../DELIVERY/1qna/systemAnnouncementTab/presenter';
import { RecentAnnounce } from 'pages/DELIVERY/1qna/systemAnnouncementTab/systemAnnouncement/component/recentAnnounce';
import CommonModalNew from 'components/modal/commonModalNew';
import { Button } from 'reactstrap';
import { addDays, isBefore } from 'date-fns';

const Presenter = (props) => {
  const history = useNavigate();
  const {
    inoutByCenterData,
    inventoryByCenterData,

    orderNdeliveryData,
    setOrderNdeliveryData,
    systemBoardData,
    csboardData,

    todayRegionData,
    getOrderByRegion,

    bestProductData,
    bestDriverData,

    settingPopupObj,
    setSettingPopupObj,
    centerList,
  } = props;
  const useNewDesignFlag = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  const now = getToday();
  const [popupObj, setPopupObj] = useState();
  return (
    <div className={`page-content ${styles.align}`}>
      <RecentAnnounce />
      {popupObj?.visible && <ReadOnlyPopup popupObj={popupObj} setPopupObj={setPopupObj} />}
      <div className={styles.titleYoil}>
        {returnDatemmddKr(now)} {returnYoil(now)}요일
      </div>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          {settingPopupObj?.visible && <SettingPopupModal centerList={centerList} settingPopupObj={settingPopupObj} setSettingPopupObj={setSettingPopupObj} />}
          <div className={styles.workdoneByCenter}>
            <div className={styles.innerTitle}>
              창고별 작업건수
              <span
                className={styles.setting}
                onClick={() => {
                  setSettingPopupObj({
                    ...settingPopupObj,
                    visible: true,
                    type: 'INOUT',
                  });
                }}
              >
                설정
              </span>
              <span
                className={styles.seeAll}
                onClick={() => {
                  alert('준비중입니다!');
                }}
              >
                전체보기 {'>'}
              </span>
            </div>
            <img alt="label" src={inoutLabel} style={{ width: 200, position: 'absolute', right: 10, top: 52 }} />
            <div className={styles.exp}>당일 {returnDateyyyymmdd(new Date())} 기준</div>
            {inoutByCenterData ? (
              <InoutByCenter inoutByCenterData={inoutByCenterData} />
            ) : (
              <div className={styles.needSetting}>
                <p>설정에서 창고를 선택해주세요</p>
              </div>
            )}
          </div>

          <div className={styles.inventoryByCenter}>
            <div className={styles.innerTitle}>
              창고별 재고 현황
              <span
                className={styles.setting}
                onClick={() => {
                  setSettingPopupObj({
                    ...settingPopupObj,
                    visible: true,
                    type: 'INVENTORY',
                  });
                }}
              >
                설정
              </span>
              <span
                className={styles.seeAll}
                onClick={() => {
                  alert('준비중입니다!');
                }}
              >
                전체보기 {'>'}
              </span>
            </div>
            <div className={styles.exp}>당일 {inventoryByCenterData?.now} 기준 </div>
            <div className={styles.content}>
              {inventoryByCenterData?.list?.length > 0 ? (
                <TableSet
                  columns={[
                    { label: '창고', value: 'centerName' },
                    { label: '양품', value: 'availableStorageQuantity' },
                    { label: '불용', value: 'defectedStorageQuantity' },
                    { label: '고객보관', value: 'customerStorageQuantity' },
                    { label: '이동중', value: 'transitQuantity' },
                  ]}
                  rows={inventoryByCenterData?.list}
                />
              ) : (
                <div className={styles.needSetting} style={{ height: '100%' }}>
                  <p>설정에서 창고를 선택해주세요</p>
                </div>
              )}
            </div>
          </div>

          <div className={styles.bestProductArea}>
            <div className={styles.innerTitle}>주문량이 높은 상품</div>
            <div className={styles.exp}>
              지난 7일({bestProductData?.rawData.fromDate}~{bestProductData?.rawData.toDate}) 기준
            </div>
            {bestProductData && (
              <div
                className={styles.content}
                style={{
                  height: '85%',
                  paddingTop: 50,
                  marginLeft: -10,
                  justifyContent: 'flex-start',
                }}
              >
                <BestProducts bestProductData={bestProductData} />
              </div>
            )}
          </div>

          <div className={styles.orderNdeliveryArea}>
            <div className={styles.innerTitle}>
              주문 및 배송 현황
              <span
                style={{
                  fontSize: 11,
                  fontWeight: 500,
                  position: 'absolute',
                  left: 150,
                }}
              >
                총 주문 : <span style={{ color: '#FF910F', fontWeight: 700 }}>{orderNdeliveryData?.rawData?.totalOrderCount}</span>건 &nbsp;<span style={{ color: 'lightgray' }}>|</span> &nbsp;주문
                평균 : <span style={{ color: '#FF910F', fontWeight: 700 }}>{orderNdeliveryData?.rawData?.orderAverage}</span>건{' '}
              </span>
            </div>
            <img alt="label" src={orderNdeliveryLabel} style={{ width: 170, position: 'absolute', right: 10, top: 52 }} />
            <FlatpickrD
              className="form-control d-block transparent"
              value={orderNdeliveryData?.searchDate}
              onChange={(date) => {
                if (date?.length < 2) return;
                if (isBefore(addDays(date[0], 6), date[1])) {
                  alert('기간 설정은 최대 7일까지 설정 가능합니다');
                  return;
                }

                let _orderNdeliveryData = { ...orderNdeliveryData };
                _orderNdeliveryData = {
                  ...orderNdeliveryData,
                  searchDate: [returnDateyyyymmdd(date[0]), returnDateyyyymmdd(date[1])],
                  refetch: true,
                };
                setOrderNdeliveryData(_orderNdeliveryData);
              }}
            />
            <div className={styles.exp}>* 기간은 최대 7일까지 설정 가능합니다</div>
            {orderNdeliveryData?.options && orderNdeliveryData?.series && <OrderNdelivery orderNdeliveryData={orderNdeliveryData} />}
          </div>
          <div className={styles.bestDriverArea}>
            <div className={styles.innerTitle}>
              Top3 기사 리스트
              <span
                style={{ right: 20 }}
                className={styles.seeAll}
                onClick={() => {
                  alert('준비중입니다!');
                }}
              >
                전체보기 {'>'}
              </span>
            </div>
            <div className={styles.exp}>
              {bestDriverData?.fromDate?.split('-')[0]}&nbsp;
              {returnDatemmddKr(bestDriverData?.fromDate).split(' ')[0]} 한달 기준
            </div>
            <div className={styles.content}>
              <BestDriver bestDriverData={bestDriverData} />
            </div>
          </div>

          <div className={styles.customerQnaArea}>
            <div className={styles.innerTitle}>
              고객 상담
              <span
                className={styles.seeAll}
                onClick={() => {
                  history(useNewDesignFlag === 0 ? '/customerCs' : '/main/customerCs');
                }}
              >
                전체보기 {'>'}
              </span>
            </div>
            <div className={styles.content}>
              {csboardData?.list && (
                <TableSet
                  columns={[
                    { label: '구분', value: 'counselTypeKrImg' },
                    { label: '세부유형', value: 'detailTypeKr' },
                    { label: 'CS상태', value: 'solveFlagKr' },
                    { label: '고객명', value: 'customerName' },
                    { label: '문의유형', value: 'counselTypeKr' },
                    { label: '상세보기', value: 'detail' },
                  ]}
                  rows={csboardData?.list}
                />
              )}
            </div>
          </div>

          <div className={styles.systemBoardArea}>
            <div className={styles.innerTitle}>
              시스템 공지사항
              <span
                className={styles.seeAll}
                onClick={() => {
                  history(useNewDesignFlag === 0 ? '/systemAnnouncement' : '/main/systemAnnouncement');
                }}
              >
                전체보기 {'>'}
              </span>
            </div>
            <div className={styles.content}>
              {systemBoardData?.list && (
                <TableSet
                  columns={[
                    { label: '구분', value: 'typeKrImg' },
                    { label: '제목', value: 'title' },
                    { label: '등록일', value: 'registerDatetime' },
                    {
                      label: '상세보기',
                      value: 'detail',
                      onclickFn: (data) => {
                        setPopupObj({
                          visible: true,
                          announcementSeq: data?.announcementSeq,
                        });
                      },
                    },
                  ]}
                  rows={systemBoardData?.list}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.mapContainer}>
          <div
            style={{
              position: 'absolute',
              top: 10,
              left: 10,
              padding: 10,
              zIndex: 2,
              backgroundColor: '#F7FAF8',
              borderRadius: 10,
              width: 200,
            }}
          >
            <div className={styles.innerTitle} style={{ fontSize: 15 }}>
              지역별 작업 건수
            </div>
            <div className={styles.exp}>{todayRegionData?.now}기준</div>
          </div>
          {todayRegionData && <Map todayRegionData={todayRegionData} getOrderByRegion={getOrderByRegion} />}
        </div>
      </div>
    </div>
  );
};

const InoutByCenter = (props) => {
  const { inoutByCenterData } = props;
  return (
    <>
      <ReactApexChart
        type="bar"
        style={{ position: 'absolute', top: 70, left: '-2%', width: '96%' }}
        options={inoutByCenterData?.options}
        series={inoutByCenterData?.series}
        width={'96%'}
        height={265}
      />
      <ReactApexChart
        type="bar"
        style={{ position: 'absolute', top: 70, left: '4%', width: '96%' }}
        options={inoutByCenterData?.options2}
        series={inoutByCenterData?.series2}
        width={'96%'}
        height={265}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 28,
          left: '2%',
          width: '96%',
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        {inoutByCenterData?.centerNames?.map((centerName, idx) => {
          return (
            <span
              key={centerName + idx}
              title={centerName}
              style={{
                width: '18%',
                padding: '0 3px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                display: 'inline-block',
              }}
            >
              {centerName}
            </span>
          );
        })}
      </div>
      <div
        style={{
          color: 'gray',
          position: 'absolute',
          bottom: 10,
          width: '100%',
          textAlign: 'center',
          fontSize: 9,
          left: 0,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        * 입고: 간선 + 발주 + 기사회수 수량 / 출고: 간선 + 반출 + 기사출고 수량입니다.
      </div>
    </>
  );
};
const OrderNdelivery = (props) => {
  const { orderNdeliveryData } = props;
  return <ReactApexChart type="bar" options={orderNdeliveryData?.options} series={orderNdeliveryData?.series} width={`100%`} height={280} />;
};

const TableSet = (props) => {
  const { columns, rows } = props;
  return (
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          {columns?.map((column, idx) => {
            return <th key={`columns_head_${idx}`}>{column?.label}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {rows?.map((row, idx) => {
          return (
            <tr key={`row_body_${idx}`}>
              {columns?.map((ele, i) => {
                if (!ele.label.includes('구분'))
                  return (
                    <td
                      key={`column_value_${i}`}
                      className={ele.value.includes('detail') ? 'click-fake-item-default' : ''}
                      onClick={() => {
                        if (ele.onclickFn) {
                          ele.onclickFn(row);
                        }
                      }}
                    >
                      {row[ele.value]}
                    </td>
                  );
                else
                  return (
                    <td key={`column_value_${i}2`}>
                      <img src={row[ele.value]} alt="ele.value" />
                    </td>
                  );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const BestProducts = (props) => {
  const { bestProductData } = props;
  return (
    <>
      <ReactApexChart type="bar" width={300} height={300} style={{ marginTop: 5 }} options={bestProductData.options} series={bestProductData.series} />
      <div className={styles.categoriesLabels}>
        {bestProductData.categories.map((ele, idx) => (
          <div className={styles.category} key={`bestproduct_${uuidv4()}`} title={`${idx + 1}. ${ele}`}>
            <span className={styles.numbering}>{idx + 1}</span> <span>{ele}</span>
          </div>
        ))}
      </div>
      <div className={styles.hideArea} style={{ bottom: 0, height: 43, width: '100%' }} />
      <div className={styles.hideArea} style={{ left: 0, bottom: 0, height: 320, width: 42 }} />
    </>
  );
};

const BestDriver = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { bestDriverData } = props;
  return (
    <>
      {bestDriverData?.list?.map((ele) => {
        return (
          <div className={styles.driverArea} key={`bestDriverdata_${uuidv4()}`}>
            <img className={styles.driverPhoto} src={ele.profileImage ? ele.profileImage : driver1} alt="" />
            <span className={styles.driverName}>{ele.driverName}</span>
            <span>({selectvalue(ele.driverName, masterOptions?.DRIVER)})</span>
            <span className={styles.center}>{selectlabel(ele.centerCode, masterOptions?.CENTER)}</span>
            <span className={styles.orderCount}>{ele.orderCount}건</span>
          </div>
        );
      })}
    </>
  );
};

const SettingPopupModal = (props) => {
  const { settingPopupObj, setSettingPopupObj, centerList } = props;
  const [_centerList, _setCenterList] = useState();
  useEffect(() => {
    if (centerList) {
      _setCenterList(JSON.parse(JSON.stringify(settingPopupObj?.type === 'INOUT' ? centerList?.INOUT?.list : centerList?.INVENTORY?.list)));
    }
  }, []);

  return (
    <CommonModalNew
      style={{ width: 400 }}
      subTitle="* 최대 5개 창고를 선택하실 수 있습니다."
      title={settingPopupObj?.type === 'INOUT' ? '창고별 작업건수 설정' : '창고별 재고현황 설정'}
      visible={settingPopupObj?.visible}
      setVisible={() => {
        setSettingPopupObj({
          ...settingPopupObj,
          visible: false,
          type: null,
        });
      }}
      rightTitle={
        <div>
          <Button
            className="btn btn-orange ssm"
            onClick={() => {
              const list = _centerList.filter((ele) => ele.useFlag);
              if (list?.length > 5) {
                alert('5개 이하만  설정가능합니다!');
              } else {
                if (settingPopupObj?.type === 'INOUT') settingPopupObj?.inoutFn({ list, centerList });
                else settingPopupObj?.inventoryFn({ list, centerList });
              }
            }}
          >
            적용
          </Button>
        </div>
      }
      children={
        <>
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#D9DEE7', height: 26 }}>
                <th>-</th>
                <th>No.</th>
                <th>창고</th>
              </tr>
            </thead>
            <tbody>
              {_centerList?.map((row, idx) => {
                return (
                  <tr key={`center_inout_${idx}`}>
                    <td style={{ textAlign: 'center', height: 26 }}>
                      <input
                        type="checkbox"
                        checked={row.useFlag || false}
                        onChange={() => {
                          const _ = JSON.parse(JSON.stringify(_centerList));
                          _.forEach((ele) => {
                            if (ele.centerCode === row.centerCode) ele['useFlag'] = ele['useFlag'] ? !ele['useFlag'] : true;
                          });
                          _setCenterList(_);
                        }}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>{idx + 1}</td>
                    <td style={{ textAlign: 'center' }}>{row.centerName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      }
    />
  );
};
export default Presenter;
