import { httpClient } from 'common/http-client/axiosConfig';
import { MY_BOOKMARK } from 'envVar';
import { useDispatch } from 'react-redux';
import { setBookmarkUpdated } from 'redux/services/menuSlice';

export function useBookmarks() {
  const dispatch = useDispatch();
  const addMyBookmark = async (data) => {
    await httpClient.post(MY_BOOKMARK, data).then(async (res) => {
      if (res?.status === 200) {
        alert('즐겨찾기에 추가되었습니다.');
        dispatch(setBookmarkUpdated(true));
        // setHeaderObj({
        //   ...headerObj,
        //   bookMarks: await getMyBookmarks(),
        // });
      }
    });
  };
  const deleteMyBookmark = async (data) => {
    await httpClient.delete(MY_BOOKMARK, { data }).then(async (res) => {
      if (res?.status === 200) {
        alert('즐겨찾기에서 삭제되었습니다.');
        dispatch(setBookmarkUpdated(true));
        // setHeaderObj({
        //   ...headerObj,
        //   bookMarks: await getMyBookmarks(),
        // });
      }
    });
  };
  return { addMyBookmark, deleteMyBookmark };
}
