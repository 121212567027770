import styled from 'styled-components';
import { LDSFormLabel } from '../atoms/Typography';
import LDSInputField from '../atoms/InputField';
import { LDSSpacing } from '../atoms/Spacing';
import { UseFormRegister, UseFormResetField, UseFormWatch } from 'react-hook-form';
import { UnionFieldTypeProps, UnionFormTypeProps } from '../types/types';

type FieldItemProps = {
  $label: string;
  $fieldType: string;
  $isRequire?: boolean;
  $type?: 'SINGLE' | 'DOUBLE' | 'COMPOUND' | 'SELECT';
  $width?: number | 'FULL';
  $fieldDataName?: UnionFieldTypeProps;
  register?: UseFormRegister<UnionFormTypeProps>;
  watch?: UseFormWatch<UnionFormTypeProps>;
  resetField?: UseFormResetField<UnionFormTypeProps>;
  children?: React.ReactNode;
};

export default function LDSFieldItem({ $label, $isRequire, $type, $width, $fieldType, register, watch, resetField, $fieldDataName, children }: FieldItemProps) {
  return (
    <LDSFieldItemWrapper $width={$width}>
      <LDSFormLabel $isRequired={$isRequire} htmlFor={$label}>
        {$label}
      </LDSFormLabel>
      <LDSSpacing $direction="V" $value={6} />
      <FieldGroupWrapper $type={$type}>
        {($type === 'COMPOUND' || $type === 'SELECT') && children}
        {$type !== 'SELECT' && (
          <LDSInputField
            $type={$fieldType}
            $status={'ACTIVE'}
            $fieldName={$label}
            $isRequire={$isRequire}
            register={register}
            watch={watch && watch}
            resetField={resetField}
            $fieldDataName={$fieldDataName}
          />
        )}
      </FieldGroupWrapper>
    </LDSFieldItemWrapper>
  );
}

type FieldItemWrapperProps = Pick<FieldItemProps, '$width'>;

export type FieldGroupWrapperProps = Pick<FieldItemProps, '$type'>;

const LDSFieldItemWrapper = styled.div<FieldItemWrapperProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.$width === 'FULL' ? '100%' : typeof props.$width === 'number' ? `${props.$width}px` : 'auto')};
`;

const FieldGroupWrapper = styled.div<FieldGroupWrapperProps>`
  display: flex;
  gap: 0;
  & > div {
    &:hover {
      z-index: 1;
    }
    &:nth-child(1) {
      max-width: ${(props) => (props.$type === 'COMPOUND' ? '40%' : '100%')};
      width: ${(props) => (props.$type !== 'COMPOUND' ? '100%' : null)};

      .input:focus ~ .field-background {
        z-index: 1;
      }
      .field-background {
        border-radius: ${(props) => (props.$type === 'COMPOUND' ? '5px 0 0 5px' : null)};
      }
    }
    &:nth-child(2) {
      margin-left: -1px;
      flex-grow: 1;

      .field-background {
        border-radius: 0 5px 5px 0;
      }
    }
  }
`;
