import jwt_decode from 'jwt-decode';

type TokenDataProps = {
  accessToken?: string;
  accessTokenExp?: string;
  refreshToken?: string;
  refreshTokenExp?: string;
};

export const setLocalStorageTokens = (data: TokenDataProps) => {
  if (!data) return;
  localStorage.setItem('accessToken', data?.accessToken);
  localStorage.setItem('refreshToken', data?.refreshToken);
  localStorage.setItem('accessTokenExp', data?.accessTokenExp);
  localStorage.setItem('refreshTokenExp', data?.refreshTokenExp);
};

export const decodeAndSetUserInfo = (data: TokenDataProps) => {
  const decodedUserInfo = jwt_decode(data.accessToken);
  localStorage.setItem('userInfo', JSON.stringify(decodedUserInfo));
  return decodedUserInfo;
};

export const clearLocalStorageData = () => {
  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));
  const modalContentFlags = JSON.parse(localStorage.getItem('modalContentFlags'));
  localStorage.clear();

  const newModalContentFlags = modalContentFlags?.length > 0 ? [...modalContentFlags] : [];
  localStorage.setItem('useNewDesignFlag', currentDesignType === 1 ? '1' : '0');
  localStorage.setItem('modalContentFlags', JSON.stringify(newModalContentFlags));
};

export const setLocalFetchAndFlatMenus = (fetchMenus) => {
  localStorage.setItem('fetchMenus', JSON.stringify(fetchMenus));
  const flatMenus = fetchMenus
    ?.map((menu) => {
      let spreadChildren = [];
      //if (menu?.menuName !== '홈') {
      spreadChildren = [...menu?.children];
      //}

      return spreadChildren;
    })
    .flat();
  flatMenus && localStorage.setItem('flatMenus', JSON.stringify(flatMenus));
};

export const setLocalTabs = (screens) => {
  const tabs = { ...screens };
  if (Object.keys(tabs)?.length === 0) return;
  for (let tab in tabs) {
    const tabArray = [...tabs[tab]];
    tabArray?.sort((a, b) => a?.sort - b?.sort);
    tabs[tab] = tabArray;
  }
  localStorage.setItem('tabs', JSON.stringify(tabs));
};
