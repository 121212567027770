import { useState, useEffect } from 'react';
import { DetailTopTab, IDetailTopTab } from 'components/template/detailTopTab';

// redux
import { nullingMASTER } from 'redux/services/menuSlice';
import { useSelector, useDispatch } from 'react-redux';

// component
import Container1 from './refurRegit';
import { RootState } from 'redux/store';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { REFUR_AND_MODEL_MOVE_REGISTER_MASTER } from '../_refurRegitTab/create';

const Index = () => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState<IDetailTopTab>();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(REFUR_AND_MODEL_MOVE_REGISTER_MASTER);
  };

  useEffect(() => {
    if (MASTER_OBJ) {
      setTabObj({
        titles: ['리퍼관리', '리퍼생성'],
        routers: ['/refur', '/refurRegit'],
        container: [<Container1 />],
      });
    }
  }, [MASTER_OBJ]);

  return <>{tabObj && MASTER_OBJ && <DetailTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
