import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import Divider from 'components/LDS/atoms/Divider';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, OptionItem, SelectD } from 'components/reactstrap/reactstrap';
import { useFrontSearchData } from 'hooks/grid/useFrontSearchData';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'reactstrap';
import { RootState } from 'redux/store';
import * as IGrid from 'aui-grid';

export type IModalObj = {
  visible?: boolean;
  title?: string;
  item?: any[];
};

export const InventoryModal = (props) => {
  const { inventoryModalObj, setInventoryModalObj } = props;
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '실수령자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'receiverName',
        },
        {
          headerText: '우편번호',
          dataField: 'receiverZipcode',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr',
        },
        {
          headerText: '연락처1',
          dataField: 'receiverPhone1',
        },
        {
          headerText: '연락처2',
          dataField: 'receiverPhone2',
        },
      ],
    },
    {
      headerText: '주문자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'customerName',
        },
        {
          headerText: '우편번호',
          dataField: 'customerZipcode',
        },
        {
          headerText: '주소',
          dataField: 'customerAddr',
        },
        {
          headerText: '연락처1',
          dataField: 'customerPhone1',
        },
        {
          headerText: '연락처2',
          dataField: 'customerPhone2',
        },
      ],
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '출고창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '수량',
          dataField: 'quantity',
        },
      ],
    },
  ];

  const detailGridRef = useRef<IPagingGrid>();
  const detailSelectType = { SEARCH_SEQ: null, SEARCH_NAME: null, SEARCH_MODEL: null };

  const convertKeyValue = {
    // customerAddr: (row) => (row.customerAddr1 ? `${row.customerAddr1} ${row.customerAddr2 || ''}` : ''),
    centerCodeKr: (row) => MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
    // originalCenterCodeKr: (row) => MASTER_OBJ?.CENTER_WHOLE?.[row.originalCenterCode],
    partnerSeqKr: (row) => MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row.partnerSeq],
    // modelGroupKr: (row) => MASTER_OBJ?.MODEL_GROUP?.[row.modelGroup],
    // statusKr: (row) => MASTER_OBJ?.REMOVE_STATUS?.[row.status],
    //
    receiverAddr: (row) => `${row?.receiverAddr1?.replace('null', '') || ''} ${row?.receiverAddr2?.replace('null', '') || ''}`,
    customerAddr: (row) => `${row?.customerAddr1?.replace('null', '') || ''} ${row?.customerAddr2?.replace('null', '') || ''}`,
    //
    // unloadFlagKr: (row) => (row.unloadFlag === true ? 'O' : 'X'),
    // orderCancelFlagKr: (row) => (row.orderCancelFlag === true ? 'O' : 'X'),
    // pickFlagKr: (row) => (row.pickFlag === true ? 'O' : 'X'),
    // loadFlagKr: (row) => (row.loadFlag === true ? 'O' : 'X'),
  };

  const { setGridData, setDetailSelectTypeObj, handleSubmitFiltering, detailSelectTypeObj, detailSearchObj, setDetailSearchObj, convertFieldValue, returnKeyValues, resetFilters } = useFrontSearchData(
    {
      gridRef: detailGridRef,
      initDetailSearchObj: {},
      detailSelectType,
      convertKeyValue,
    },
  );

  useEffect(() => {
    fetchInventoryDetailInfo(inventoryModalObj?.item?.loadingStatementSeq);
  }, [inventoryModalObj.item]);

  const fetchInventoryDetailInfo = async (seq: number) => {
    //TODO : API fetch 후 setGridData로 적용
    setGridData(sampleInventoryGridData?.list);
    const dataKr = sampleInventoryGridData?.list.map((row) => {
      let keyValues = returnKeyValues(convertKeyValue, row);

      return {
        ...row,
        ...keyValues,
      };
    });
    detailGridRef.current?.setGridData(dataKr);
  };

  console.log(inventoryModalObj);

  return (
    <>
      <CommonModalNew
        title={inventoryModalObj?.title}
        subTitle={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <strong>출고지시번호</strong> : {inventoryModalObj?.item?.loadingStatementSeq || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <strong>출발창고</strong> : {inventoryModalObj?.item?.originalCenterCodeKr || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
            <strong>진행상태</strong> : {inventoryModalObj?.item?.statusKr || '-'}
          </div>
        }
        style={{ width: 1400 }}
        visible={inventoryModalObj?.visible}
        setVisible={() => setInventoryModalObj({ visible: false })}
        rightTitle={<div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}></div>}
        children={
          <>
            <Form onSubmit={handleSubmitFiltering}>
              <Row>
                <Col>
                  <label className="col-form-label">주문번호/송장번호</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={4} style={{ display: 'inline-block' }}>
                      <SelectD
                        options={MASTER_OPS?.SEARCH_SEQ_TAKEOUT}
                        value={
                          detailSelectTypeObj?.SEARCH_SEQ
                            ? {
                                label: MASTER_OBJ?.SEARCH_SEQ_TAKEOUT?.[detailSelectTypeObj?.SEARCH_SEQ],
                                value: detailSelectTypeObj?.SEARCH_SEQ,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setDetailSelectTypeObj({
                            ...detailSelectTypeObj,
                            SEARCH_SEQ: (option as OptionItem).value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={8} style={{ display: 'inline-block' }}>
                      <InputD
                        disabled={!detailSelectTypeObj?.SEARCH_SEQ}
                        value={detailSearchObj?.[detailSelectTypeObj?.SEARCH_SEQ] || ''}
                        onChange={(e) => {
                          setDetailSearchObj({
                            ...detailSearchObj,
                            [detailSelectTypeObj?.SEARCH_SEQ]: convertFieldValue(detailSelectTypeObj['SEARCH_SEQ'], e.target.value),
                          });
                        }}
                      />
                    </Col>
                  </div>
                </Col>

                <Col>
                  <label className="col-form-label">제품/제품명</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={4} style={{ display: 'inline-block' }}>
                      <SelectD
                        options={MASTER_OPS?.SEARCH_MODEL_TAKEOUT}
                        value={
                          detailSelectTypeObj?.SEARCH_MODEL
                            ? {
                                label: MASTER_OBJ.SEARCH_MODEL_TAKEOUT?.[detailSelectTypeObj?.SEARCH_MODEL],
                                value: detailSelectTypeObj?.SEARCH_MODEL,
                              }
                            : null
                        }
                        onChange={(option) => {
                          setDetailSelectTypeObj({
                            ...detailSelectTypeObj,
                            SEARCH_MODEL: (option as OptionItem).value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={8} style={{ display: 'inline-block' }}>
                      <InputD
                        disabled={!detailSelectTypeObj?.SEARCH_MODEL}
                        value={detailSearchObj?.[detailSelectTypeObj?.SEARCH_MODEL] || ''}
                        onChange={(e) => {
                          setDetailSearchObj({
                            ...detailSearchObj,
                            [detailSelectTypeObj?.SEARCH_MODEL]: convertFieldValue(detailSelectTypeObj['SEARCH_MODEL'], e.target.value),
                          });
                        }}
                      />
                    </Col>
                  </div>
                </Col>
                <Col>
                  <label className="col-form-label">고객명</label>
                  <div style={{ display: 'flex' }}>
                    <Col md={4} style={{ display: 'inline-block' }}>
                      <SelectD
                        value={
                          detailSelectTypeObj?.SEARCH_NAME
                            ? {
                                value: detailSelectTypeObj?.SEARCH_NAME,
                                label: MASTER_OBJ?.SEARCH_NAME?.[detailSelectTypeObj?.SEARCH_NAME],
                              }
                            : null
                        }
                        options={MASTER_OPS?.SEARCH_NAME}
                        onChange={(option) => {
                          setDetailSelectTypeObj({
                            ...detailSelectTypeObj,
                            SEARCH_NAME: (option as OptionItem).value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={8} style={{ display: 'inline-block' }}>
                      <InputD
                        disabled={!detailSelectTypeObj?.SEARCH_NAME}
                        value={detailSearchObj?.[detailSelectTypeObj?.SEARCH_NAME] || ''}
                        onChange={(e) => {
                          setDetailSearchObj((prev) => {
                            return {
                              ...prev,
                              [detailSelectTypeObj?.SEARCH_NAME]: e.target.value,
                            };
                          });
                        }}
                      />
                    </Col>
                  </div>
                </Col>
                <Col />
                <Col style={{ textAlign: 'right', placeSelf: 'flex-end', display: 'flex', gap: '4px' }}>
                  <button type="button" className="btn" style={{ border: '1px solid #DADADA', minWidth: '60px' }} onClick={resetFilters}>
                    초기화
                  </button>
                  <button type="submit" className="btn btn-search" style={{ flexGrow: 1 }}>
                    조회
                  </button>
                </Col>
              </Row>
            </Form>
            <Divider $direction={'H'} $color={'#eaeaea'} $spacing={'LG'} $value={'FULL'} />
            <GridBox
              gridRef={detailGridRef}
              columns={detailColumns}
              gridProps={{
                fixedColumnCount: 0,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
            />
          </>
        }
      />
    </>
  );
};

const sampleInventoryGridData = {
  list: [
    {
      orderSeq: 2100045952,
      invoiceSeq: 1000040524,
      //
      receiverName: '냉동',
      receiverZipcode: '63108',
      receiverAddr1: '광주 북구 일곡택지로',
      receiverAddr2: '(차디찬냉동-9)',
      receiverPhone1: '010-1234-5678',
      receiverPhone2: '010-1234-5678',
      customerName: '냉동',
      customerZipcode: '63108',
      customerAddr1: '광주 북구 일곡택지로',
      customerAddr2: '',
      customerPhone1: '010-1234-5678',
      customerPhone2: '010-1234-5678',
      //
      centerCode: 'R053',
      partnerSeq: 89,
      model: 'AC145CN4FHH1',
      modelName: 'AR-EH05',
      barcode: 'SAMHANTEST00001',
      quantity: 11,
      //
    },
  ],
};

/* TODO : fetch 및 setGridData 참고
const fetchHaldangInfo = async (item) => {
    dispatch(setLoading('GET'));
    setHaldangObj((prev) => {
      return {
        ...prev,
        item: {
          statusKr: item?.statusKr,
          userId: item?.userId,
          loanPickSeq: item?.loanPickSeq,
        },
      };
    });

    const searchDTO = {
      loanPickSeq: item?.loanPickSeq,
      centerCode: item?.centerCode,
      loanPickDate: item?.loanPickDate,
    };

    // NOTE: 액션 버튼 api 호출 시 필요한 parameter들을 정의
    setActionParams({
      linehaulSeq: item?.linehaulSeq,
      loadingStatementSeq: item?.loadingStatementSeq,
      loadingOutSeq: item?.loadingOutSeq,
      transportStatementSeq: item?.transportStatementSeq,
      originalCenterCode: item?.originalCenterCode,
      destinationCenterCode: item?.destinationCenterCode,
      linehaulDate: item?.linehaulDate,
      linehaulType: item?.linehaulType,
      status: item?.status,
      stockRemoveSeq: item?.stockRemoveSeq,
      centerCode: item?.centerCode,
      target: item?.target,
      partnerSeq: item?.partnerSeq,
      stockRemoveDate: item?.stockRemoveDate,
      stockRemoveType: item?.stockRemoveType,
      holdType: item?.holdType,
    });

    const rs = await serviceStore?.warehouseAction(`loan/picking/info`, 'GET', searchDTO);

    if (rs?.status === 200) {
      const data = rs?.data;
      setGridData(data);
      const dataKr = data?.map((row) => {
        let keyValues = returnKeyValues(convertKeyValue, row);

        return {
          ...row,
          ...keyValues,
        };
      });

      gridRef?.current?.setGridData(dataKr);
      dispatch(setLoading(null));
    }
  };
  */
