import styled from 'styled-components';
import LDSProfile from '../atoms/Profile';
import { LDSLabel3XS, LDSLabelMD } from '../atoms/Typography';
import { COLORS, SIZES } from 'common/constants/appearance';
import LDSButton from '../atoms/Button';
import { FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { TooltipProps } from './Tooltip';
import { DropdownProps } from '../layouts/Dropdown';
import { useLocation, useNavigate } from 'react-router-dom';

type ProfileInfoType = {
  username?: string;
  profileImageUrl?: string;
  organization?: string;
};

type ProfielGroupProps = {
  $profile: ProfileInfoType;
  $status: 'EXPAND' | 'COLLAPSED';
  $type: 'USER' | 'BRAND';
  $border: 'TOP' | 'BOTTOM';
  onClick?: () => void;
  $tooltip?: Omit<TooltipProps, '$data'>;
  $dropdown?: Omit<DropdownProps, '$data'>;
  $imageLink?: string;
  children?: React.ReactNode;
};

export default function LDSProfileGroup({ $profile, $status, $type, $border, onClick, $tooltip, $dropdown, $imageLink, children }: ProfielGroupProps) {
  const navigate = useNavigate();
  return (
    <ProfileGroupStyle $status={$status} $type={$type} $border={$border}>
      {$imageLink ? (
        <div className={'image-link'} onClick={() => navigate($imageLink)}>
          <LDSProfile
            $size={'SM'}
            $type={$profile.profileImageUrl ? 'IMAGE' : 'USER'}
            $imageUrl={$profile?.profileImageUrl}
            $name={$profile?.username?.charAt(0)}
            $interactive={$status === 'COLLAPSED' && $type === 'USER' ? true : false}
            $tooltip={$status === 'COLLAPSED' && $tooltip}
            $dropdown={$status === 'COLLAPSED' && $dropdown}
          />
        </div>
      ) : (
        <LDSProfile
          $size={'SM'}
          $type={$profile.profileImageUrl ? 'IMAGE' : 'USER'}
          $imageUrl={$profile?.profileImageUrl}
          $name={$profile?.username?.charAt(0)}
          $interactive={$status === 'COLLAPSED' && $type === 'USER' ? true : false}
          $tooltip={$status === 'COLLAPSED' && $tooltip}
          $dropdown={$status === 'COLLAPSED' && $dropdown}
        />
      )}
      {$status === 'EXPAND' && (
        <span className="user-info">
          <LDSLabelMD>{$profile.username}</LDSLabelMD>
          {$profile.organization?.length > 0 && <LDSLabel3XS $weight="SEMIBOLD">{$profile.organization}</LDSLabel3XS>}
        </span>
      )}
      {($status === 'COLLAPSED' && $type === 'USER') || (
        <ButtonGroup $status={$status}>
          {children}

          <LDSButton
            $primary={false}
            $size={'SM'}
            $type={'BUTTON'}
            $style={'TRANSPARENT'}
            $showIcon={true}
            $icon={$type === 'USER' && $status === 'EXPAND' ? <FiChevronRight /> : $type === 'BRAND' && $status === 'EXPAND' ? <FiChevronsLeft /> : <FiChevronsRight />}
            onClick={onClick}
            $tooltip={$tooltip}
            $dropdown={$dropdown}
          />
        </ButtonGroup>
      )}
    </ProfileGroupStyle>
  );
}

type ProfileStyleProps = Omit<ProfielGroupProps, '$profile'>;

const ProfileGroupStyle = styled.div<ProfileStyleProps>`
  display: flex;
  gap: 4px;
  align-items: ${(props) => (props.$status === 'COLLAPSED' ? 'flex-start' : 'flex-start')};

  box-shadow: inset ${(props) => (props.$border === 'TOP' ? '0 1px 0 0' : '0 -1px 0 0')} ${COLORS.GRAY_03};

  padding-left: 14px;
  padding-right: ${(props) => (props.$status === 'COLLAPSED' ? 14 : 10)}px;
  padding-top: ${(props) => (props.$status === 'COLLAPSED' ? 10 : 10)}px;
  padding-bottom: ${(props) => (props.$status === 'COLLAPSED' ? 10 : 10)}px;

  background-color: ${COLORS.GRAY_01};
  width: 100%;
  justify-content: space-between;

  flex-direction: ${(props) => (props.$status === 'COLLAPSED' ? 'column' : 'row')};

  button {
    flex-shrink: 0;
    width: ${SIZES.SIZE_SM}px;
    justify-self: flex-end;
    height: ${SIZES.SIZE_SM}px;
  }

  .image-link {
    cursor: pointer;
    * {
      cursor: inherit;
    }
  }
  .user-info {
    display: flex;
    align-self: center;
    flex-direction: column;
    opacity: ${(props) => (props.$type === 'USER' ? 1 : 0)};

    flex-grow: 1;
    white-space: nowrap;
    max-width: calc(100% - 72px);
    overflow: hidden;

    label:first-child {
      color: ${COLORS.GRAY_07};
      width: 100%;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: -1px;
    }
    label:nth-child(2) {
      color: ${COLORS.GRAY_04};
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: -2px;
      margin-bottom: -2px;
    }
  }
`;

type ButtonGroupStyleProps = Pick<ProfielGroupProps, '$status'>;

const ButtonGroup = styled.div<ButtonGroupStyleProps>`
  display: flex;
  flex-direction: ${(props) => (props.$status === 'COLLAPSED' ? 'column' : 'row')};
  gap: 2px;
`;
