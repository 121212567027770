import { ReactNode, useMemo, useRef, useState } from 'react';
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';

import AUIGrid from 'modules/Grid';
// service
import { inventoryService } from '../../_service/service';

import * as IGrid from 'aui-grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import { PagingListDTO } from 'interface/util';
import { InventoryInOutDTO, InventoryInoutSearchDTO } from 'interface/warehouse';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { getDateFromToday } from 'common/util/dateParsingFn';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { InventoryReportsCommonDefaultSearchFilters } from '..';
import useSearchGridPagingNew from 'hooks/grid/useGridPagingNew';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();
  const locationGridRef = useRef<AUIGrid>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const searchObj = useSelector((state: RootState) => state.tab)?.searchObj?.[tabId];
  const defaultSearchFilter = useMemo(() => {
    return {
      ...InventoryReportsCommonDefaultSearchFilters,
      fromInOutDate: getDateFromToday(-1),
      toInOutDate: getDateFromToday(-1),
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    };
  }, [reduxUserInfo]);

  const labellingKr = (data: PagingListDTO<InventoryInOutDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        registerDate: searchObj?.registerDate,
        centerCodeKr: row.centerCode ? selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE) : '전체',
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryInoutSearchDTO) => {
    dispatch(setLoading('GET'));
    setBottomSheetObj(null);
    const dataKr = labellingKr(await inventoryService.getInventoryInoutPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { initFetch, handleSearchObj } = useSearchGridPagingNew({
    tabId,
    gridRef,
    // fetchTotal,
    fetchPaging,
    initialSearchObj: {
      ...defaultSearchFilter,
      ...searchObj,
    },
  });

  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    //
    dispatch(setLoading('GET'));
    const searchObj = {
      ...JSON.parse(e.item.searchObj),
      inOutDate: e.item.inOutDate,
    };
    const data = await inventoryService.getInventoryInoutDetail(searchObj);
    const rows = data?.map((row) => {
      return {
        ...row,
        searchObj: JSON.stringify(searchObj),
        centerCodeKr: searchObj?.centerCode ? selectlabel(searchObj?.centerCode, masterOptions?.CENTER_WHOLE) : selectlabel(row?.centerCode || '전체', masterOptions?.CENTER_WHOLE),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
      };
    });

    const gridButtonhandler = (e) => {
      const id = e.target.id;
      if (id === 'INVENTORY_PAY_BTMSHEET_EXCEL') {
        bottomGridRef.current.exportAsXlsx({ fileName: '수불현황_상세' });
      }
    };

    const buttons = printFunctionToBtns(
      gridFunctions?.filter((ele) => !ele.functionId?.includes('SHOW')),
      gridButtonhandler,
    );
    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '수불현황 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          buttons,
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
      ],
    });
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetail,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.INVENTORY_PAY_SHOWDETAIL;
        },
      },
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '일자',
      dataField: 'inOutDate',
    },

    {
      headerText: '입고',
      children: [
        {
          headerText: '입고합계',
          children: [
            {
              headerText: '양품',
              dataField: 'totalAvailableIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'totalDefectedIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'totalEtcIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '발주입고',
          children: [
            {
              headerText: '양품',
              dataField: 'purchase',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '간선입고',
          children: [
            {
              headerText: '양품',
              dataField: 'lineAvailableIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'lineDefectedIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'lineEtcIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '제품회수',
          children: [
            {
              headerText: '양품',
              dataField: 'modelCollectAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'modelCollectDefected',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'modelCollectEtc',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '반출회수',
          children: [
            {
              headerText: '양품',
              dataField: 'removeCollectAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'removeCollectDefected',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '자재반납',
          children: [
            {
              headerText: '양품',
              dataField: 'componentCollectAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '재고이동',
          children: [
            {
              headerText: '양품',
              dataField: 'moveAvailableIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'moveDefectedIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'moveEtcIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '재고조정',
          children: [
            {
              headerText: '양품',
              dataField: 'modificationAvailableIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'modificationDefectedIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '재고품목전환',
          children: [
            {
              headerText: '양품',
              dataField: 'modelMoveAvailableIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'modelMoveDefectedIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
    {
      headerText: '출고',
      children: [
        {
          headerText: '출고합계',
          children: [
            {
              headerText: '양품',
              dataField: 'totalAvailableOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'totalDefectedOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'totalEtcOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '간선출고',
          children: [
            {
              headerText: '양품',
              dataField: 'lineAvailableOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'lineDefectedOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'lineEtcOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '기사출고',
          children: [
            {
              headerText: '양품',
              dataField: 'loanAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'loanEtc',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '자재출고',
          children: [
            {
              headerText: '양품',
              dataField: 'loanComponent',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '반출',
          children: [
            {
              headerText: '양품반출',
              dataField: 'removeAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용반출',
              dataField: 'removeDefected',
              dataType: 'numeric',
              formatString: '#,##0',
            },

            {
              headerText: '택배반출',
              dataField: 'parcel',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '분실',
          children: [
            {
              headerText: '양품분실',
              dataField: 'lostAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용분실',
              dataField: 'lostDefected',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '재고이동',
          children: [
            {
              headerText: '양품',
              dataField: 'moveAvailableOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'moveDefectedOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'moveEtcOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '재고조정',
          children: [
            {
              headerText: '양품',
              dataField: 'modificationAvailableOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'modificationDefectedOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '재고품목전환',
          children: [
            {
              headerText: '양품',
              dataField: 'modelMoveAvailableOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'modelMoveDefectedOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
    {
      headerText: '차이',
      children: [
        {
          headerText: '(입고 - 출고)',
          children: [
            {
              headerText: '양품',
              dataField: 'availableDiff',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'defectedDiff',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'etcDiff',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
    {
      headerText: '현재',
      children: [
        {
          headerText: '보관 재고',
          children: [
            {
              headerText: '양품',
              dataField: 'totalAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'totalDefected',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'totalEtc',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
    {
      headerText: '이동',
      children: [
        {
          headerText: '계',
          dataField: 'totalMove',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          headerText: '간선이동',
          children: [
            {
              headerText: '양품',
              dataField: 'lineAvailableMove',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'lineDefectedMove',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'lineEtcMove',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '기사배송',
          children: [
            {
              headerText: '배송중',
              dataField: 'driverLoan',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '회수중',
              dataField: 'driverCollect',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '일자',
      dataField: 'inOutDate',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '대표상품코드',
          dataField: 'productCode',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
      ],
    },
    {
      headerText: '입고',
      children: [
        {
          headerText: '입고합계',
          children: [
            {
              headerText: '양품',
              dataField: 'totalAvailableIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'totalDefectedIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'totalEtcIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '발주입고',
          children: [
            {
              headerText: '양품',
              dataField: 'purchase',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '간선입고',
          children: [
            {
              headerText: '양품',
              dataField: 'lineAvailableIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'lineDefectedIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'lineEtcIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '제품회수',
          children: [
            {
              headerText: '양품',
              dataField: 'modelCollectAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'modelCollectDefected',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'modelCollectEtc',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '반출회수',
          children: [
            {
              headerText: '양품',
              dataField: 'removeCollectAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'removeCollectDefected',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '자재반납',
          children: [
            {
              headerText: '양품',
              dataField: 'componentCollectAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '재고이동',
          children: [
            {
              headerText: '양품',
              dataField: 'moveAvailableIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'moveDefectedIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'moveEtcIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '재고조정',
          children: [
            {
              headerText: '양품',
              dataField: 'modificationAvailableIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'modificationDefectedIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '재고품목전환',
          children: [
            {
              headerText: '양품',
              dataField: 'modelMoveAvailableIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'modelMoveDefectedIn',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
    {
      headerText: '출고',
      children: [
        {
          headerText: '출고합계',
          children: [
            {
              headerText: '양품',
              dataField: 'totalAvailableOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'totalDefectedOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'totalEtcOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '간선출고',
          children: [
            {
              headerText: '양품',
              dataField: 'lineAvailableOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'lineDefectedOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'lineEtcOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '기사출고',
          children: [
            {
              headerText: '양품',
              dataField: 'loanAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'loanEtc',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '자재출고',
          children: [
            {
              headerText: '양품',
              dataField: 'loanComponent',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '반출',
          children: [
            {
              headerText: '양품반출',
              dataField: 'removeAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용반출',
              dataField: 'removeDefected',
              dataType: 'numeric',
              formatString: '#,##0',
            },

            {
              headerText: '택배반출',
              dataField: 'parcel',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '분실',
          children: [
            {
              headerText: '양품분실',
              dataField: 'lostAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용분실',
              dataField: 'lostDefected',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '재고이동',
          children: [
            {
              headerText: '양품',
              dataField: 'moveAvailableOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'moveDefectedOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'moveEtcOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '재고조정',
          children: [
            {
              headerText: '양품',
              dataField: 'modificationAvailableOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'modificationDefectedOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '재고품목전환',
          children: [
            {
              headerText: '양품',
              dataField: 'modelMoveAvailableOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'modelMoveDefectedOut',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
    {
      headerText: '차이',
      children: [
        {
          headerText: '(입고 - 출고)',
          children: [
            {
              headerText: '양품',
              dataField: 'availableDiff',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'defectedDiff',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'etcDiff',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
    {
      headerText: '현재',
      children: [
        {
          headerText: '보관재고',
          children: [
            {
              headerText: '양품',
              dataField: 'totalAvailable',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'totalDefected',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'totalEtc',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
    {
      headerText: '이동',
      children: [
        {
          headerText: '계',
          dataField: 'totalMove',
        },
        {
          headerText: '간선이동',
          children: [
            {
              headerText: '양품',
              dataField: 'lineAvailableMove',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '불용',
              dataField: 'lineDefectedMove',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기타',
              dataField: 'lineEtcMove',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '기사배송',
          children: [
            {
              headerText: '배송중',
              dataField: 'driverLoan',
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '회수중',
              dataField: 'driverCollect',
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
  ];

  const locationColumns = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '합계',
      dataField: 'totalQuantity',
    },
    {
      headerText: '양품',
      dataField: 'availableQuantity',
    },
    {
      headerText: '불용',
      dataField: 'defectedQuantity',
    },
    {
      headerText: '재고연령',
      children: [
        {
          headerText: '~30일',
          dataField: 'before30Day',
        },
        {
          headerText: '31~60일',
          dataField: 'before60Day',
        },
        {
          headerText: '61일~',
          dataField: 'after60Day',
        },
      ],
    },
  ];

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const data = type === 'main' ? await inventoryService.getInventoryInoutExcelMain(searchObj) : await inventoryService.getInventoryInoutExcelDetail(searchObj);
    const { list } = labellingKr({ list: data }, true);
    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '수불_현황' });
    } else if (type === 'detail') {
      excelDetailGridRef.current.setGridData(list);
      excelDetailGridRef.current.exportAsXlsx({ fileName: '수불_현황(상세)' });
    }

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'INVENTORY_PAY_EXCELDOWN_MAIN') {
      downloadExcel('main');
    } else if (id === 'INVENTORY_PAY_EXCELDOWN_DETAIL') {
      downloadExcel('detail');
    }
  };

  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox handleSearchObj={handleSearchObj} TARGET={tabId} fetchList={initFetch} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area only-right">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            //
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            fixedColumnCount: 1,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={detailColumns} />
        <PrintGridBox gridRef={locationGridRef} columns={locationColumns} />
      </div>
    </div>
  );
};

export default Index;
