import { ReactNode, useMemo, useRef, useState } from 'react';

// utils
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';

// hooks
import { PagingListDTO } from 'interface/util';
import { SearchBox } from './component/searchBox';
import PrintGridBox from 'common/grid/printGridBox';
import { detectMobileDevice } from 'common/util/detectDevice';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { ParcelInvoiceRevisitDTO, ParcelInvoiceRevisitSearchDTO } from '../../../_interface/parcel';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const isMobile = detectMobileDevice(window.navigator.userAgent);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const columns: IGrid.Column[] = [
    {
      headerText: '미배송<br/>접수일시',
      dataField: 'registerDatetime',
    },
    {
      headerText: '운송장번호',
      dataField: 'parcelInvoiceNumber',
    },
    {
      headerText: 'HBL',
      dataField: 'houseNumber',
    },
    {
      headerText: 'SBL',
      dataField: 'subNumber',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '양륙항',
      dataField: 'portCodeKr',
    },
    {
      headerText: '고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'receiverName',
        },
        {
          headerText: '우편번호',
          dataField: 'receiverZipcode',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr',
        },
        {
          headerText: '전화번호1',
          dataField: 'receiverPhone',
        },
      ],
    },
    {
      headerText: '배달지',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '주기사',
      dataField: 'userIdKr',
    },
    {
      headerText: '미배송사유',
      dataField: 'revisitReasonKr',
    },
    {
      headerText: '미배송접수자',
      dataField: 'registerId',
    },
  ];

  const labellingKr = (data: PagingListDTO<ParcelInvoiceRevisitDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_FORWARDER_WHOLE?.[row?.partnerSeq],
        portCodeKr: MASTER_OBJ?.PORT_CODE?.[row?.portCode],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
        userIdKr: MASTER_OBJ?.DRIVER_WHOLE?.[row?.userId],
        revisitReasonKr: MASTER_OBJ?.PARCEL_REVISIT_REASON?.[row?.revisitReason],
        receiverAddr: row.receiverAddr1 || '' + row.receiverAddr2 || '',
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: ParcelInvoiceRevisitSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.parcelAction(`invoice/revisit/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<ParcelInvoiceRevisitDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 500,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: (await serviceStore.parcelAction(`invoice/revisit/excel`, 'GET', searchObj, null))?.data as ParcelInvoiceRevisitDTO[] }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `미배송` });

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'PARCEL_REVISIT_EXCELDOWN_MAIN') {
      downloadExcel('main');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content" style={{ paddingTop: isMobile ? 0 : '3rem' }}>
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            pageRowCount: 500,
            pageRowSelectValues: [500],
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
