import { useEffect, useMemo, useRef, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { ParcelInvoiceSearchDTO } from '../../../_interface/parcel';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { numberThousandComma } from 'common/util/counting';
import { FLAG_OPT } from 'pages/PARCEL/_asset/asset';
import { setSearchObj } from 'redux/services/tabSlice';

type SearchBoxSelectType = {
  SEARCH_DATE?: string;
  SEARCH_SEQ?: string;
  SEARCH_NAME?: string;
};

export const SearchBox = (props: ISearchBoxProps<ParcelInvoiceSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, fetchCount, fetchList, TARGET } = props;
  const setPropsSearchObj = props.setSearchObj;
  const reduxSearchObj = useSelector((state: RootState) => state.tab)?.searchObj?.[TARGET];
  const [flagArr, setFlagArr] = useState<string[]>();
  const dispatch = useDispatch();

  const [selectTypeObj, setSelectTypeObj] = useState<SearchBoxSelectType>({});

  const defaultSelectTypeObj = useMemo(() => {
    return {
      SEARCH_DATE: reduxSearchObj?.['SEARCH_DATE'] ?? null,
      SEARCH_SEQ: reduxSearchObj?.['SEARCH_SEQ'] ?? null,
      SEARCH_NAME: reduxSearchObj?.['SEARCH_NAME'] ?? null,
    };
  }, [TARGET]);

  const useNewDesignFlag = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  useEffect(() => {
    setSelectTypeObj({ ...defaultSelectTypeObj });
    setFlagArr(FLAG_OPT?.map((ele) => ele.value));
  }, [TARGET]);

  useEffect(() => {
    dispatch(
      setSearchObj({
        tabId: TARGET,
        searchObj: {
          ...searchObj,
        },
      }),
    );
  }, [searchObj]);

  const updateSearchObj = (searchObj) => {
    const selectKeys = Object?.keys(selectTypeObj);
    if (!selectKeys) return searchObj;

    let updatedSearchObj = { ...searchObj };

    for (let key of selectKeys) {
      updatedSearchObj[key] = selectTypeObj[key];
    }

    return updatedSearchObj;
  };

  useEffect(() => {
    setPropsSearchObj(updateSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj)));
  }, [selectTypeObj?.SEARCH_DATE]);
  useEffect(() => {
    setPropsSearchObj(updateSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SEQ, searchObj)));
  }, [selectTypeObj?.SEARCH_SEQ]);
  useEffect(() => {
    setPropsSearchObj(updateSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_NAME, searchObj)));
  }, [selectTypeObj?.SEARCH_NAME]);

  /* NOTE: flagArr 변환 시 setSearchObj를 수행하므로 아래 코드는 비활성화
  const isMountedRef = useRef(false);
  useDidMountEffect(() => {
    if (isMountedRef?.current) {
      if (
        searchObj?.collectFlag ||
        searchObj?.transitFlag ||
        searchObj?.centerFlag ||
        searchObj?.finishFlag ||
        searchObj?.deliveryFlag ||
        searchObj?.transferFlag ||
        searchObj?.scheduleFlag ||
        searchObj?.wrongCenterFlag ||
        searchObj?.failFlag
      )
        fetchList(searchObj);
    } else {
      isMountedRef.current = true;
    }
  }, [
    searchObj?.wrongCenterFlag,
    searchObj?.collectFlag,
    searchObj?.transitFlag,
    searchObj?.centerFlag,
    searchObj?.finishFlag,
    searchObj?.deliveryFlag,
    searchObj?.transferFlag,
    searchObj?.scheduleFlag,
    searchObj?.failFlag,
  ]); 
  */

  useEffect(() => {
    if (searchObj) {
      const _searchObj = { ...searchObj };
      FLAG_OPT.forEach(({ value }) => {
        if (flagArr?.includes(value)) _searchObj[value] = 'true';
        else _searchObj[value] = 'false';
      });

      setPropsSearchObj(_searchObj);
      _searchObj?.pageNo !== -1 && fetchList(_searchObj);
    }
  }, [flagArr]);

  const handleCheckbox = (e) => {
    const id = e.target.id;
    if (e.target.id === 'all') {
      if (e.target.checked === true) {
        setFlagArr(FLAG_OPT?.map((ele) => ele.value));
      } else {
        setFlagArr([]);
      }
    } else {
      let set = new Set(flagArr);
      if (set.has(id)) {
        set.delete(id);
      } else {
        set.add(id);
      }
      const newArr = Array.from(set);
      setFlagArr(newArr);
    }
  };

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    fetchList(searchObj);
  };

  return (
    <>
      {TARGET === 'PARCEL_SERVICE_LIST' && (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <label className="col-form-label">기간검색</label>
              <div style={{ display: 'flex' }}>
                <Col md={4} style={{ display: 'inline-block' }}>
                  <SelectD
                    value={
                      selectTypeObj?.SEARCH_DATE
                        ? {
                            value: selectTypeObj?.SEARCH_DATE,
                            label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                          }
                        : null
                    }
                    options={MASTER_OPS?.SEARCH_DATE}
                    onChange={(option) => {
                      setSelectTypeObj({
                        ...selectTypeObj,
                        SEARCH_DATE: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col md={8} style={{ display: 'inline-block' }}>
                  <FlatpickrD
                    disabled={!selectTypeObj?.SEARCH_DATE}
                    onClose={(option) => {
                      if (option?.length === 1)
                        setPropsSearchObj({
                          ...searchObj,
                          [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                          [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                        });
                    }}
                    value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                    onChange={(option) => {
                      setPropsSearchObj({
                        ...searchObj,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                        [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                      });
                    }}
                  />
                </Col>
              </div>
            </Col>
            <Col>
              <label className="col-form-label">송장조회</label>
              <div style={{ display: 'flex' }}>
                <Col md={4} style={{ display: 'inline-block' }}>
                  <SelectD
                    value={
                      selectTypeObj?.SEARCH_SEQ
                        ? {
                            value: selectTypeObj?.SEARCH_SEQ,
                            label: MASTER_OBJ?.SEARCH_SEQ?.[selectTypeObj?.SEARCH_SEQ],
                          }
                        : null
                    }
                    options={MASTER_OPS?.SEARCH_SEQ}
                    onChange={(option) => {
                      setSelectTypeObj({
                        ...selectTypeObj,
                        SEARCH_SEQ: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col md={8} style={{ display: 'inline-block' }}>
                  <InputD
                    placeholder="다중 입력..."
                    disabled={!selectTypeObj?.SEARCH_SEQ}
                    value={searchObj?.[selectTypeObj?.SEARCH_SEQ] || ''}
                    onChange={(e) => {
                      const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];

                      setPropsSearchObj({
                        ...searchObj,
                        [selectTypeObj?.SEARCH_SEQ]: value,
                      });
                    }}
                  />
                </Col>
              </div>
            </Col>
            <Col>
              <label className="col-form-label">인프라</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.INFRA}
                value={searchObj?.infraSeq?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.INFRA?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    infraSeq: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">판매사(포워더)</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
                value={searchObj?.partnerSeq?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.SELLER_WHOLE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    partnerSeq: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">양륙항</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.PORT_CODE}
                value={searchObj?.portCode?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PORT_CODE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    portCode: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">택배상태</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.PARCEL_INVOICE_STATUS}
                value={searchObj?.status?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PARCEL_INVOICE_STATUS?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    status: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">택배사</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.PARCEL_TYPE}
                value={searchObj?.parcelType?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PARCEL_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    parcelType: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">이름검색</label>
              <div style={{ display: 'flex' }}>
                <Col md={4} style={{ display: 'inline-block' }}>
                  <SelectD
                    value={
                      selectTypeObj?.SEARCH_NAME
                        ? {
                            value: selectTypeObj?.SEARCH_NAME,
                            label: MASTER_OBJ?.SEARCH_NAME?.[selectTypeObj?.SEARCH_NAME],
                          }
                        : null
                    }
                    options={MASTER_OPS?.SEARCH_NAME}
                    onChange={(option) => {
                      setSelectTypeObj({
                        ...selectTypeObj,
                        SEARCH_NAME: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col md={8} style={{ display: 'inline-block' }}>
                  <InputD
                    placeholder="다중 입력..."
                    disabled={!selectTypeObj?.SEARCH_NAME}
                    value={searchObj?.[selectTypeObj?.SEARCH_NAME] || ''}
                    onChange={(e) => {
                      const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];

                      setPropsSearchObj({
                        ...searchObj,
                        [selectTypeObj?.SEARCH_NAME]: value,
                      });
                    }}
                  />
                </Col>
              </div>
            </Col>
            <Col>
              <label className="col-form-label">주소</label>
              <InputD
                value={searchObj?.receiverAddr || ''}
                onChange={(e) => {
                  setPropsSearchObj({
                    ...searchObj,
                    receiverAddr: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">전화번호</label>
              <InputD
                value={searchObj?.receiverPhone || ''}
                onChange={(e) => {
                  setPropsSearchObj({
                    ...searchObj,
                    receiverPhone: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">배달지</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.CENTER_AUTH}
                value={searchObj?.centerCode?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.CENTER_WHOLE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    centerCode: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">주기사</label>
              <div style={{ display: 'flex' }}>
                <Col md={6} style={{ display: 'inline-block' }}>
                  <SelectD
                    options={MASTER_OPS?.DRIVER_AUTH}
                    value={
                      searchObj?.userId
                        ? {
                            value: searchObj?.userId,
                            label: MASTER_OBJ?.DRIVER_WHOLE?.[searchObj?.userId],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setPropsSearchObj({
                        ...searchObj,
                        userId: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col md={6} style={{ display: 'inline-block' }}>
                  <InputD disabled value={searchObj?.userId || ''} />
                </Col>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">운임구분</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.PARCEL_PAY_TYPE}
                value={searchObj?.payType?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PARCEL_PAY_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    payType: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">운송장등록차수</label>
              <InputD
                value={searchObj?.stage || ''}
                onChange={(e) => {
                  setPropsSearchObj({
                    ...searchObj,
                    stage: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">운송장등록자</label>
              <InputD
                value={searchObj?.registerId || ''}
                onChange={(e) => {
                  setPropsSearchObj({
                    ...searchObj,
                    registerId: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">박스크기</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.PARCEL_BOX_SIZE}
                value={searchObj?.boxSize?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PARCEL_BOX_SIZE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    boxSize: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">유사검색여부</label>
              <div className="radio-select-area">
                <span
                  className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                  onClick={() => {
                    setPropsSearchObj((prev) => {
                      return {
                        ...prev,
                        similaritySearchFlag: true,
                      };
                    });
                  }}
                >
                  유사검색
                </span>
                <span
                  className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                  onClick={() => {
                    setPropsSearchObj((prev) => {
                      return {
                        ...prev,
                        similaritySearchFlag: false,
                      };
                    });
                  }}
                >
                  일치검색
                </span>
              </div>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <button className="btn btn-search" type="submit">
                조회
              </button>
            </Col>
          </Row>
        </Form>
      )}
      {TARGET !== 'PARCEL_SERVICE_LIST' && (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <label className="col-form-label">기간검색</label>
              <div style={{ display: 'flex' }}>
                <Col md={4} style={{ display: 'inline-block' }}>
                  <SelectD
                    value={
                      selectTypeObj?.SEARCH_DATE
                        ? {
                            value: selectTypeObj?.SEARCH_DATE,
                            label: MASTER_OBJ?.SEARCH_DATE[selectTypeObj?.SEARCH_DATE],
                          }
                        : null
                    }
                    options={MASTER_OPS?.SEARCH_DATE}
                    onChange={(option) => {
                      setSelectTypeObj({
                        ...selectTypeObj,
                        SEARCH_DATE: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col md={8} style={{ display: 'inline-block' }}>
                  <FlatpickrD
                    disabled={!selectTypeObj?.SEARCH_DATE}
                    onClose={(option) => {
                      if (option?.length === 1)
                        setPropsSearchObj({
                          ...searchObj,
                          [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                          [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                        });
                    }}
                    value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                    onChange={(option) => {
                      setPropsSearchObj({
                        ...searchObj,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                        [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                      });
                    }}
                  />
                </Col>
              </div>
            </Col>
            <Col>
              <label className="col-form-label">송장조회</label>
              <div style={{ display: 'flex' }}>
                <Col md={4} style={{ display: 'inline-block' }}>
                  <SelectD
                    value={
                      selectTypeObj?.SEARCH_SEQ
                        ? {
                            value: selectTypeObj?.SEARCH_SEQ,
                            label: MASTER_OBJ?.SEARCH_SEQ?.[selectTypeObj?.SEARCH_SEQ],
                          }
                        : null
                    }
                    options={MASTER_OPS?.SEARCH_SEQ}
                    onChange={(option) => {
                      setSelectTypeObj({
                        ...selectTypeObj,
                        SEARCH_SEQ: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col md={8} style={{ display: 'inline-block' }}>
                  <InputD
                    placeholder="다중 입력..."
                    disabled={!selectTypeObj?.SEARCH_SEQ}
                    value={searchObj?.[selectTypeObj?.SEARCH_SEQ] || ''}
                    onChange={(e) => {
                      const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];

                      setPropsSearchObj({
                        ...searchObj,
                        [selectTypeObj?.SEARCH_SEQ]: value,
                      });
                    }}
                  />
                </Col>
              </div>
            </Col>
            <Col>
              <label className="col-form-label">인프라</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.INFRA}
                value={searchObj?.infraSeq?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.INFRA?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    infraSeq: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">판매사(포워더)</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
                value={searchObj?.partnerSeq?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.SELLER_FORWARDER_AUTH?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    partnerSeq: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">양륙항</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.PORT_CODE}
                value={searchObj?.portCode?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PORT_CODE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    portCode: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">택배사</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.PARCEL_TYPE}
                value={searchObj?.parcelType?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PARCEL_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    parcelType: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">이름검색</label>
              <div style={{ display: 'flex' }}>
                <Col md={4} style={{ display: 'inline-block' }}>
                  <SelectD
                    value={
                      selectTypeObj?.SEARCH_NAME
                        ? {
                            value: selectTypeObj?.SEARCH_NAME,
                            label: MASTER_OBJ?.SEARCH_NAME?.[selectTypeObj?.SEARCH_NAME],
                          }
                        : null
                    }
                    options={MASTER_OPS?.SEARCH_NAME}
                    onChange={(option) => {
                      setSelectTypeObj({
                        ...selectTypeObj,
                        SEARCH_NAME: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col md={8} style={{ display: 'inline-block' }}>
                  <InputD
                    placeholder="다중 입력..."
                    disabled={!selectTypeObj?.SEARCH_NAME}
                    value={searchObj?.[selectTypeObj?.SEARCH_NAME] || ''}
                    onChange={(e) => {
                      const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];

                      setPropsSearchObj({
                        ...searchObj,
                        [selectTypeObj?.SEARCH_NAME]: value,
                      });
                    }}
                  />
                </Col>
              </div>
            </Col>
            <Col>
              <label className="col-form-label">주소</label>
              <InputD
                value={searchObj?.receiverAddr || ''}
                onChange={(e) => {
                  setPropsSearchObj({
                    ...searchObj,
                    receiverAddr: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">전화번호</label>
              <InputD
                value={searchObj?.receiverPhone || ''}
                onChange={(e) => {
                  setPropsSearchObj({
                    ...searchObj,
                    receiverPhone: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">배달지</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.CENTER_AUTH}
                value={searchObj?.centerCode?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.CENTER_WHOLE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    centerCode: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">주기사</label>
              <div style={{ display: 'flex' }}>
                <Col md={6} style={{ display: 'inline-block' }}>
                  <SelectD
                    options={MASTER_OPS?.DRIVER_AUTH}
                    value={
                      searchObj?.userId
                        ? {
                            value: searchObj?.userId,
                            label: MASTER_OBJ?.DRIVER_WHOLE?.[searchObj?.userId],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setPropsSearchObj({
                        ...searchObj,
                        userId: (option as OptionItem).value,
                      });
                    }}
                  />
                </Col>
                <Col md={6} style={{ display: 'inline-block' }}>
                  <InputD disabled value={searchObj?.userId || ''} />
                </Col>
              </div>
            </Col>
            <Col>
              <label className="col-form-label">운임구분</label>
              <SelectD
                isMulti
                options={MASTER_OPS?.PARCEL_PAY_TYPE}
                value={searchObj?.payType?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.PARCEL_PAY_TYPE?.[ele],
                  };
                })}
                onChange={(option) => {
                  setPropsSearchObj({
                    ...searchObj,
                    payType: (option as OptionItem[])?.map((ele) => ele.value),
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            {TARGET === 'PARCEL_RESERVED_LIST' && (
              <>
                <Col>
                  <label className="col-form-label">운송장등록차수</label>
                  <InputD
                    value={searchObj?.stage || ''}
                    onChange={(e) => {
                      setPropsSearchObj({
                        ...searchObj,
                        stage: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">운송장등록자</label>
                  <InputD
                    value={searchObj?.registerId || ''}
                    onChange={(e) => {
                      setPropsSearchObj({
                        ...searchObj,
                        registerId: e.target.value,
                      });
                    }}
                  />
                </Col>
              </>
            )}
            {TARGET === 'PARCEL_DELIVERY_COMPLETED_LIST' && (
              <Col>
                <label className="col-form-label">박스크기</label>
                <SelectD
                  isMulti
                  options={MASTER_OPS?.PARCEL_BOX_SIZE}
                  value={searchObj?.boxSize?.map((ele) => {
                    return {
                      value: ele,
                      label: MASTER_OBJ?.PARCEL_BOX_SIZE?.[ele],
                    };
                  })}
                  onChange={(option) => {
                    setPropsSearchObj({
                      ...searchObj,
                      boxSize: (option as OptionItem[])?.map((ele) => ele.value),
                    });
                  }}
                />
              </Col>
            )}

            <Col>
              <label className="col-form-label">유사검색여부</label>
              <div className="radio-select-area">
                <span
                  className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                  onClick={() => {
                    setPropsSearchObj((prev) => {
                      return {
                        ...prev,
                        similaritySearchFlag: true,
                      };
                    });
                  }}
                >
                  유사검색
                </span>
                <span
                  className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                  onClick={() => {
                    setPropsSearchObj((prev) => {
                      return {
                        ...prev,
                        similaritySearchFlag: false,
                      };
                    });
                  }}
                >
                  일치검색
                </span>
              </div>
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <button className="btn btn-search" type="submit">
                조회
              </button>
            </Col>
            <Col />
            <Col />
            {TARGET === 'PARCEL_DELIVERY_COMPLETED_LIST' && <Col />}
          </Row>
        </Form>
      )}

      {TARGET === 'PARCEL_SERVICE_LIST' && (
        <div style={{ textAlign: 'right' }}>
          <div className="status-panel">
            <span>
              <input type="checkbox" id="all" checked={!flagArr ? true : flagArr?.length === FLAG_OPT?.length} onChange={handleCheckbox} />
              <label htmlFor="all">전체</label> {numberThousandComma(fetchCount?.totalQuantity || 0)} 건
            </span>
            {FLAG_OPT?.map(({ value, label }) => {
              return (
                <span key={`flag_${value}`}>
                  <input type="checkbox" id={value} checked={!flagArr ? true : flagArr?.includes(value)} onChange={handleCheckbox} />
                  <label htmlFor={value}>{label}</label> {numberThousandComma(fetchCount?.[value?.replaceAll('Flag', 'Quantity')] || 0)} 건
                </span>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
