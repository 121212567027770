import { useEffect, useState } from 'react';
import styles from './presenter.module.scss';
import './map.scss';

import delivery from 'common/navermaps/flags/delivery.svg';
import visit from 'common/navermaps/flags/visit.svg';
import collect from 'common/navermaps/flags/collect.svg';
import exchange from 'common/navermaps/flags/exchange.svg';
import move from 'common/navermaps/flags/move.svg';
import store from 'common/navermaps/flags/store.svg';
import as from 'common/navermaps/flags/as.svg';
import fix from 'common/navermaps/flags/fix.svg';

import arrowDown from 'common/navermaps/flags/arrowDown.svg';
import arrowUp from 'common/navermaps/flags/arrowUp.svg';

import './map.scss';

// navermaps
import { MarkerClustering } from 'common/navermaps/MarkerClustering';
import clusteringMarker from 'common/navermaps/marker/clustering.svg';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import Select from 'react-select';

// redux
import { useSelector } from 'react-redux';
import { returnFeeTypes } from 'common/util/orderTypes';

export const Map = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);

  const navermaps = window.naver.maps;
  const { todayRegionData, getOrderByRegion } = props;
  const [typedData, setTypedData] = useState();
  const [flagData, setFlagData] = useState();
  const [flagsClose, setFlagsClose] = useState(false);
  const [coordsPlate, setCoordsPlate] = useState();
  const [markerMode, setMarkerMode] = useState('default');
  const [mode, setMode] = useState('default'); // default / driver

  const [searchObj, setSearchObj] = useState({
    region: null,
  });

  const initCoords = [35.71, 127.7];
  const [mapProps, setMapProps] = useState({
    zoom: 7,
    minZoom: 7,
    center: new navermaps.LatLng(initCoords[0], initCoords[1]),
    zoomControl: false, //줌 컨트롤의 표시 여부
  });

  useEffect(() => {
    if (todayRegionData && masterOptions) {
      coloringData(todayRegionData?.list);
    }
  }, [todayRegionData, masterOptions]);

  useEffect(() => {
    getOrderByRegion(searchObj);
  }, [searchObj?.region]);

  const renewMap = () => {
    const mapid = document.querySelector('#map');
    if (mapid) {
      mapid.remove(); // 제거

      // 재생성
      const newMapid = document.createElement('div');
      newMapid.id = 'map';
      const parentDiv = document.querySelector('.dashboardMap');
      parentDiv.appendChild(newMapid);
    }
  };

  useEffect(() => {
    if (coordsPlate?.coordsPlate) {
      renewMap();
      if (mapProps.zoom === 7) {
        setSearchObj({
          ...searchObj,
          region: null,
        });
        initRegionMap(coordsPlate);
      } else {
        initMartkerMap(coordsPlate);
      }
    }
  }, [coordsPlate?.coordsPlate, mapProps?.zoom]);

  useEffect(() => {
    if (typedData) {
      // sorting
      if (markerMode === 'finish') {
        // finish인 것들이 우선순위
        typedData.sort(function (a, b) {
          if (a.invoiceStatus === 'FINISH' && a.invoiceStatus !== 'FINISH') return 1;
          if (a.invoiceStatus === 'FINISH' && b.invoiceStatus === 'FINISH') return 0;
          if (a.invoiceStatus !== 'FINISH' && b.invoiceStatus !== 'FINISH') return 0;
          if (a.invoiceStatus !== 'FINISH' && b.invoiceStatus === 'FINISH') return -1;
        });
      } else if (markerMode !== 'finish' && markerMode !== 'default') {
        // 모드에 해당되는 애들이 우선순위
        typedData.sort(function (a, b) {
          if (a.type === markerMode && a.type !== markerMode) return 1;
          if (a.type === markerMode && b.type === markerMode) return 0;
          if (a.type !== markerMode && b.type !== markerMode) return 0;
          if (a.type !== markerMode && b.type === markerMode) return -1;
        });
      }

      const latPlate = [];
      const lngPlate = [];
      const coordsPlate = {};
      typedData.forEach((ele, idx) => {
        if (idx === 0) {
          latPlate.push(ele.latitude);
          lngPlate.push(ele.longitude);
          coordsPlate[idx] = [ele];
        } else {
          let isPlateHasCoords = -1;
          latPlate.forEach((lat, i) => {
            if (lat === ele.latitude && lngPlate[i] === ele.longitude) {
              isPlateHasCoords = i;
            }
          });

          if (isPlateHasCoords === -1) {
            latPlate.push(ele.latitude);
            lngPlate.push(ele.longitude);
            coordsPlate[latPlate.length - 1] = [ele];
          } else {
            coordsPlate[isPlateHasCoords].push(ele);
          }
        }
      });
      setCoordsPlate({
        latPlate,
        lngPlate,
        coordsPlate,
      });
    }
  }, [typedData, markerMode]);

  const zoom7Coords = {
    충청: new navermaps.LatLng(36.5860586, 127.3056152),
    강원: new navermaps.LatLng(37.3, 128.9),
    호남: new navermaps.LatLng(35.2291715, 127.2903869),
    제주: new navermaps.LatLng(33.4335548, 126.5481873),
    수도권: new navermaps.LatLng(37.5, 127),
    영남: new navermaps.LatLng(35.4877009, 128.9134827),
  };
  const transit = {
    충청: 'chungcheong',
    강원: 'gangwon',
    호남: 'honam',
    제주: 'jeju',
    수도권: 'sudogwon',
    영남: 'yeongnam',
  };

  const initRegionMap = () => {
    const map = new navermaps.Map('map', mapProps);
    const seed = Math.random();
    Object.keys(zoom7Coords)?.forEach((region, i) => {
      const marker = new navermaps.Marker({
        position: zoom7Coords[region],
        map: map,
        icon: {
          content: `
						<svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="60" cy="60" r="60" fill="#F4F6F9" fill-opacity="0.85"/>
						<div class="circleWrapper" id="region_${region}_${seed}">
							<span class="region">${region}</span>
							<span class="text">
								${todayRegionData?.[transit[region]]}<span>건</span>
							</span>
						</div>
						</svg>
					`,
          size: new navermaps.Size(22, 35),
          anchor: new navermaps.Point(70, 75),
          animation: navermaps.Animation.BOUNCE,
        },
      });
    });
    Object.keys(zoom7Coords)?.forEach((region, index) => {
      if (todayRegionData?.[transit[region]] > 0) {
        const unit = document.getElementById(`region_${region}_${seed}`);
        unit.onclick = (e) => {
          e.preventDefault();
          setSearchObj({
            ...searchObj,
            region: region,
          });
          setMapProps({
            ...mapProps,
            center: zoom7Coords[region],
            zoom: 10,
          });
        };
      }
    });

    // idle: 유휴상태(움직임이 멈춘)
    navermaps.Event.addListener(map, 'idle', function () {
      // 인터랙션한 정보를 state에 담는다
      const { _lat, _lng } = map.getOptions('center');
      const zoom = map.getOptions('zoom');
      setMapProps({
        ...mapProps,
        center: new navermaps.LatLng(_lat, _lng),
        zoom: zoom,
      });
    });
  };

  const initMartkerMap = (plate) => {
    const { coordsPlate, latPlate, lngPlate } = plate;
    const coordsPlates = JSON.parse(JSON.stringify(coordsPlate));
    const map = new navermaps.Map('map', mapProps);
    const markers = [];
    const path = [];
    // 지도위 1차원 마커
    Object.keys(coordsPlates)?.forEach((group, i) => {
      const lastEle = coordsPlates[i][coordsPlates[i].length - 1];
      const moreThan1 = coordsPlates[i].length - 1;
      const active = lastEle.type === markerMode;
      const isFinish = lastEle.invoiceStatus === 'FINISH' && markerMode === 'finish' ? true : false;
      const isNonFinish = lastEle.invoiceStatus !== 'FINISH' && markerMode === 'nonfinish' ? true : false;
      path.push(new navermaps.LatLng(latPlate[i], lngPlate[i]));

      const marker = new navermaps.Marker({
        position: new navermaps.LatLng(lastEle.latitude, lastEle.longitude),
        map: map,
        zIndex: isFinish || isNonFinish || active ? 10 : 1,
        icon: {
          content:
            isFinish || isNonFinish
              ? `<svg width="150" height="80" viewBox="0 0 177 89" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="path-1-inside-1_1_629" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34 0C15.2223 0 0 15.2223 0 34C0 52.7777 15.2223 68 34 68H71.3756L83.5 89L95.6244 68H143C161.778 68 177 52.7777 177 34C177 15.2223 161.778 0 143 0H34Z"/>
          </mask>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34 0C15.2223 0 0 15.2223 0 34C0 52.7777 15.2223 68 34 68H71.3756L83.5 89L95.6244 68H143C161.778 68 177 52.7777 177 34C177 15.2223 161.778 0 143 0H34Z" fill="white"/>
          <path d="M71.3756 68L73.1077 67L72.5303 66H71.3756V68ZM83.5 89L81.768 90L83.5 93L85.232 90L83.5 89ZM95.6244 68V66H94.4697L93.8923 67L95.6244 68ZM2 34C2 16.3269 16.3269 2 34 2V-2C14.1177 -2 -2 14.1177 -2 34H2ZM34 66C16.3269 66 2 51.6731 2 34H-2C-2 53.8822 14.1177 70 34 70V66ZM71.3756 66H34V70H71.3756V66ZM85.232 88L73.1077 67L69.6436 69L81.768 90L85.232 88ZM93.8923 67L81.768 88L85.232 90L97.3564 69L93.8923 67ZM143 66H95.6244V70H143V66ZM175 34C175 51.6731 160.673 66 143 66V70C162.882 70 179 53.8822 179 34H175ZM143 2C160.673 2 175 16.3269 175 34H179C179 14.1177 162.882 -2 143 -2V2ZM34 2H143V-2H34V2Z" fill=${
            lastEle.color
          } mask="url(#path-1-inside-1_1_629)"/>
          <circle opacity="0.3" cx="36" cy="34" r="24" fill=${lastEle.color} />
          <circle cx="36.1528" cy="34.1528" r="17.4268" fill=${lastEle.color} />
          <path d="M29 33.4444L34 38.4444L43.4444 29" stroke=${mode !== 'driver' ? 'white' : 'transparent'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="driver active ${mode === 'driver' ? 'on' : ''}" >${i + 1}</span>
          <span class="active-receiver">${lastEle.receiverName}</span>
        `
              : active
              ? `<svg width="150" height="80" viewBox="0 0 177 89" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="path-1-inside-1_1_629" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34 0C15.2223 0 0 15.2223 0 34C0 52.7777 15.2223 68 34 68H71.3756L83.5 89L95.6244 68H143C161.778 68 177 52.7777 177 34C177 15.2223 161.778 0 143 0H34Z"/>
          </mask>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34 0C15.2223 0 0 15.2223 0 34C0 52.7777 15.2223 68 34 68H71.3756L83.5 89L95.6244 68H143C161.778 68 177 52.7777 177 34C177 15.2223 161.778 0 143 0H34Z" fill="white"/>
          <path d="M71.3756 68L73.1077 67L72.5303 66H71.3756V68ZM83.5 89L81.768 90L83.5 93L85.232 90L83.5 89ZM95.6244 68V66H94.4697L93.8923 67L95.6244 68ZM2 34C2 16.3269 16.3269 2 34 2V-2C14.1177 -2 -2 14.1177 -2 34H2ZM34 66C16.3269 66 2 51.6731 2 34H-2C-2 53.8822 14.1177 70 34 70V66ZM71.3756 66H34V70H71.3756V66ZM85.232 88L73.1077 67L69.6436 69L81.768 90L85.232 88ZM93.8923 67L81.768 88L85.232 90L97.3564 69L93.8923 67ZM143 66H95.6244V70H143V66ZM175 34C175 51.6731 160.673 66 143 66V70C162.882 70 179 53.8822 179 34H175ZM143 2C160.673 2 175 16.3269 175 34H179C179 14.1177 162.882 -2 143 -2V2ZM34 2H143V-2H34V2Z" fill=${
            lastEle.color
          } mask="url(#path-1-inside-1_1_629)"/>
          <circle opacity="0.3" cx="35" cy="34" r="24" fill=${lastEle.color} />
          <circle cx="35.1528" cy="34.1528" r="17.4268" fill=${lastEle.color} />
          <path d="M29 33.4444L34 38.4444L43.4444 29" stroke=${
            mode === 'driver' ? 'transparent' : lastEle.invoiceStatus === 'FINISH' ? 'white' : 'transparent'
          } stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="driver active ${mode === 'driver' ? 'on' : ''}" >${i + 1}</span>
          <span class="active-receiver">${lastEle.receiverName}</span>
          `
              : `
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.3" cx="18" cy="18" r="18" fill=${lastEle.color} />
          <circle cx="18.1528" cy="18.1528" r="12.4268" fill=${lastEle.color} />
          <span class="driver ${mode === 'driver' ? 'on' : ''}" >${i + 1}</span>
          <span class="moreThan1 _${moreThan1} ${mode === 'driver' ? 'off' : ''}">+${moreThan1}</span>
          </svg>
          `,
          size: new navermaps.Size(22, 35),
          anchor: isFinish || isNonFinish ? new navermaps.Point(70, 75) : active ? new navermaps.Point(70, 75) : new navermaps.Point(18, 18),
          animation: navermaps.Animation.BOUNCE,
        },
      });
      // 마커 클릭시 인포창 안의 마커들
      let contents = '';
      if (moreThan1 > 0) {
        coordsPlates[i].forEach((ele, index) => {
          contents += `<div class="relative-box" id="${i}_${index}"><span class="inner-absolute-box">${ele.receiverName}</span>
          <span class="inner-absolute-box">${selectlabel(ele.invoiceStatus, masterOptions?.['INVOICE_STATUS'])},${masterOptions?.['FEE_TYPE_OBJ']?.[ele.feeType]}</span>
          <svg width="160" height="60" viewBox="0 0 126 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="160" height="60" rx="2" fill="white"/>
          <circle opacity="0.3" cx="19.6875" cy="21.4375" r="12.6875" fill=${ele.color} />
          <circle cx="19.6875" cy="21.4375" r="9.1875" fill=${ele.color} />
          <path d="M14.9116 21.4934L18.4327 25.0835L25.0835 18.3022" stroke=${
            ele.invoiceStatus === 'FINISH' ? 'white' : 'transparent'
          }  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg></div>
          `;
        });
      }
      contents = "<div class='wrapper-whole'>" + contents + '</div>';
      const infowindow = new navermaps.InfoWindow({
        content: contents,
        borderColor: '#e2e2e2',
        borderRadius: 10,
        maxHeight: 500,
      });

      navermaps.Event.addListener(map, 'click', function (e) {
        if (infowindow.getMap()) {
          infowindow.close();
        }
      });

      navermaps.Event.addListener(marker, 'click', function (e) {
        if (moreThan1 > 0) {
          if (infowindow.getMap()) {
            infowindow.close();
          } else {
            infowindow.open(map, marker);
          }
        }
      });
      markers.push(marker);
      // for문 end
    });

    var markerClustering = new MarkerClustering({
      minClusterSize: 2,
      maxZoom: 9,
      map: map,
      markers: markers,
      disableClickZoom: true,
      gridSize: 150,
      icons: [clusteringMarker],
      indexGenerator: [10, 100, 200, 500, 1000],
      averageCenter: true,
      stylingFunction: function (clusterMarker, count) {
        clusterMarker.getElement().innerHTML = `<span class="cluster-count"><span>${count}<span></span>`;
      },
    });

    // idle: 유휴상태(움직임이 멈춘)
    navermaps.Event.addListener(map, 'idle', function () {
      // 인터랙션한 정보를 state에 담는다
      const { _lat, _lng } = map.getOptions('center');
      const zoom = map.getOptions('zoom');
      setMapProps({
        ...mapProps,
        center: new navermaps.LatLng(_lat, _lng),
        zoom: zoom,
      });
    });
  };

  const coloringData = (rawData) => {
    let delivery = 0;
    let visit = 0;
    let collect = 0;
    let exchange = 0;
    let move = 0;
    let store = 0;
    let as = 0;
    let fix = 0;
    let finish = 0;

    if (JSON.stringify(rawData)) {
    }
    const _typedData = JSON.parse(JSON.stringify(rawData));

    _typedData?.forEach((ele) => {
      if (returnFeeTypes(ele.feeType.trim()) === 'delivery') {
        delivery += 1;
        ele.type = 'delivery';
        ele.color = '#3EA1FF';
      } else if (returnFeeTypes(ele.feeType.trim()) === 'visit') {
        visit += 1;
        ele.type = 'visit';
        ele.color = '#FFAD0F';
      } else if (returnFeeTypes(ele.feeType.trim()) === 'collect') {
        collect += 1;
        ele.type = 'collect';
        ele.color = '#FFA09C';
      } else if (returnFeeTypes(ele.feeType.trim()) === 'exchange') {
        exchange += 1;
        ele.type = 'exchange';
        ele.color = '#61CACA';
      } else if (returnFeeTypes(ele.feeType.trim()) === 'move') {
        move += 1;
        ele.type = 'move';
        ele.color = '#CA87FF';
      } else if (returnFeeTypes(ele.feeType.trim()) === 'store') {
        store += 1;
        ele.type = 'store';
        ele.color = '#DDA97A';
      } else if (returnFeeTypes(ele.feeType.trim()) === 'as') {
        as += 1;
        ele.type = 'as';
        ele.color = '#21AD76';
      } else if (returnFeeTypes(ele.feeType.trim()) === 'fix') {
        fix += 1;
        ele.type = 'fix';
        ele.color = '#21AD76';
      } else {
        ele.color = 'red';
        // console.log(ele);
      }

      if (ele.invoiceStatus === 'FINISH') {
        finish += 1;
        ele.color = '#A3A7B9';
      }
    });

    _typedData.sort(function (a, b) {
      if (a.invoiceStatus === 'FINISH' && a.invoiceStatus !== 'FINISH') return 1;
      if (a.invoiceStatus === 'FINISH' && b.invoiceStatus === 'FINISH') return 0;
      if (a.invoiceStatus !== 'FINISH' && b.invoiceStatus !== 'FINISH') return 0;
      if (a.invoiceStatus !== 'FINISH' && b.invoiceStatus === 'FINISH') return -1;
    });

    setTypedData(_typedData);
    const _flagData = {
      total: rawData.length,
      delivery,
      visit,
      collect,
      exchange,
      move,
      store,
      as,
      fix,
      finish,
    };
    setFlagData(_flagData);
  };

  const markerModeHandler = (e) => {
    if (mapProps.zoom !== 7) {
      const mode = e.target.id.split('-')[0];
      // setMarkerMode(mode);
    }
  };

  return (
    <div className="dashboardMap">
      <div className={styles.searchBar}>
        <Select
          isDisabled
          options={masterOptions?.REGION}
          value={{
            label: masterOptions?.REGION_OBJ?.[searchObj?.region],
            value: searchObj?.region,
          }}
        />
      </div>
      <div className={styles.feetypeFlags} style={{ height: flagsClose ? 30 : 'auto' }}>
        <div style={{ display: flagsClose ? 'none' : 'flex', marginTop: 10 }}>
          <img alt="" id="delivery-mode" src={delivery} onClick={markerModeHandler} />
          <span>{flagData?.delivery} 건</span>
          <img alt="" id="visit-mode" src={visit} onClick={markerModeHandler} />
          <span>{flagData?.visit}건</span>
          <img alt="" id="collect-mode" src={collect} onClick={markerModeHandler} />
          <span>{flagData?.collect}건</span>
          <img alt="" id="exchange-mode" src={exchange} onClick={markerModeHandler} />
          <span>{flagData?.exchange}건</span>
          <img alt="" id="move-mode" src={move} onClick={markerModeHandler} />
          <span>{flagData?.move}건</span>
          <img alt="" id="store-mode" src={store} onClick={markerModeHandler} />
          <span>{flagData?.store}건</span>
          <img alt="" id="as-mode" src={as} onClick={markerModeHandler} />
          <span>{flagData?.as}건</span>
          <img alt="" id="fix-mode" src={fix} onClick={markerModeHandler} />
          <span>{flagData?.fix}건</span>
        </div>
        <img
          onClick={() => {
            setFlagsClose(!flagsClose);
          }}
          src={flagsClose ? arrowDown : arrowUp}
        />
      </div>

      <div id="map" />
    </div>
  );
};
