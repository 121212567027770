import AUIGrid from 'modules/Grid';
import { useEffect, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import * as IGrid from 'aui-grid';
import { MenuDTO } from 'interface/user';
import { userService } from 'pages/ETC/3user/_service/service';
import { defaultGridProps } from 'common/grid/gridBox';
import { delay } from 'common/util/counting';
import { useDispatch } from 'react-redux';
import { useIsMounted } from 'hooks/basichooks';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';

export interface ISortObj {
  e?: IGrid.ButtonClickEvent;
  visible: boolean;
  level?: number;
  parentMenuName?: string;
  items?: MenuDTO[];
  fetchFn?: <T>(T) => void;
}

interface ISortModal {
  sortObj: ISortObj;
  setSortObj: React.Dispatch<React.SetStateAction<ISortObj>>;
}

export const SortModal = ({ sortObj, setSortObj }: ISortModal) => {
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();
  const columns0 = [
    {
      headerText: '메뉴명',
      dataField: 'menuName',
    },
    {
      headerText: '설명',
      dataField: 'description',
    },
  ];

  const columns = [
    {
      headerText: '상위메뉴',
      dataField: 'parentMenuName',
    },
    {
      headerText: '메뉴명',
      dataField: 'menuName',
    },
    {
      headerText: '설명',
      dataField: 'description',
    },
  ];

  const isMounted = useIsMounted();

  useEffect(() => {
    dispatch(setLoading('GET'));
    void delay(1000).then(() => {
      if (isMounted) {
        gridRef.current.setGridData(sortObj?.items);
        dispatch(setLoading(null));
      }
    });
  }, [isMounted]);

  const saveSort = async () => {
    const rows = gridRef.current.getGridData();
    const data = rows.map((ele, idx) => {
      return {
        menuId: ele.menuId,
        sort: idx + 1,
      };
    });

    const rs = await userService.saveMenuSort(data);
    if (rs?.status === 200) {
      const searchObj = JSON.parse(sortObj?.e?.item?.searchObj);
      sortObj?.fetchFn(searchObj);
      setSortObj(null);
    }
  };
  return (
    <CommonModalNew
      style={{ width: 350 }}
      title={`[${sortObj?.level === 1 ? '대' : sortObj?.level === 2 ? '중' : '소'}]${sortObj?.level === 1 ? '' : sortObj?.parentMenuName + ' 하위메뉴'}`}
      subTitle={'드래그앤 드롭으로 순서를 정하세요 '}
      visible={sortObj?.visible}
      setVisible={() => {
        setSortObj(null);
      }}
      children={
        <>
          <Row>
            <Col style={{ textAlign: 'right' }}>
              <button
                className="btn btn-create"
                onClick={() => {
                  saveSort();
                }}
              >
                저장
              </button>
            </Col>
          </Row>
          <AUIGrid
            ref={gridRef}
            columnLayout={sortObj?.level === 1 ? columns0 : columns}
            name={`sortModal`}
            gridProps={{
              ...defaultGridProps,
              height: 300,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              //
              enableDrag: true,
              enableMultipleDrag: true,
              enableDragByCellDrag: true,
            }}
          />
        </>
      }
    />
  );
};
