import { useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import { FunctionDTO, FunctionSearchDTO } from 'interface/user';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';

export interface IDetailObj {
  searchObj?: FunctionSearchDTO;
  visible: boolean;
  item?: FunctionDTO;
  CRUDFn?: <T>(T) => void;
}

interface FunctionDTOEX extends FunctionDTO {
  screenUrl?: string;
}

interface IDetailModal {
  detailObj: IDetailObj;
  setDetailObj: React.Dispatch<React.SetStateAction<IDetailObj>>;
}

export const DetailModal = ({ detailObj, setDetailObj }: IDetailModal) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [data, setData] = useState<FunctionDTOEX>();
  useEffect(() => {
    if (detailObj?.item) {
      setData(detailObj?.item);
    }
  }, []);

  const saveDetail = (data: FunctionDTO) => {
    if (!detailObj?.item) {
      if (window.confirm('등록 하시겠습니까?')) {
        detailObj?.CRUDFn({ data, crud: 'C', searchObj: detailObj?.searchObj });
      }
    } else {
      if (window.confirm('수정 하시겠습니까?')) {
        detailObj?.CRUDFn({ data, crud: 'U', searchObj: detailObj?.searchObj });
      }
    }
  };

  const deleteDetail = (data: FunctionDTO) => {
    if (window.confirm('삭제 하시겠습니까?')) {
      detailObj?.CRUDFn({ data: [data?.functionId], crud: 'U', searchObj: detailObj?.searchObj });
    }
  };

  return (
    <CommonModalNew
      title={!detailObj?.item ? '기능 생성' : '기능 수정'}
      style={{ width: 600 }}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      children={
        <>
          <label>기능 아이디*</label>
          <InputD
            value={data?.functionId || ''}
            disabled={detailObj?.item !== null}
            onChange={(e) => {
              setData({
                ...data,
                functionId: e.target.value,
              });
            }}
          />
          <label>기능그룹명</label>
          <InputD
            value={data?.functionGroup || ''}
            onChange={(e) => {
              setData({
                ...data,
                functionGroup: e.target.value,
              });
            }}
          />
          <label>기능명*</label>
          <InputD
            value={data?.functionName || ''}
            onChange={(e) => {
              setData({
                ...data,
                functionName: e.target.value,
              });
            }}
          />
          <label>설명</label>
          <textarea
            style={{ width: '100%' }}
            value={data?.description || ''}
            onChange={(e) => {
              setData({
                ...data,
                description: e.target.value,
              });
            }}
          />
          <label>소속 화면/소속 탭</label>
          <div className="grid-wrapper div2">
            <SelectD
              value={
                data?.screenId
                  ? {
                      label: MASTER_OBJ?.SCREENID_URL?.[data?.screenId],
                      value: data?.screenId,
                    }
                  : null
              }
              onChange={(option) => {
                setData({
                  ...data,
                  screenId: (option as OptionItem)?.value,
                  url: (option as OptionItem)?.label,
                  tabId: null,
                  tabName: null,
                });
              }}
              options={MASTER_OPS?.SCREENID_URL}
            />
            <SelectD
              isDisabled={!data?.screenId}
              placeholder="소속화면을 선택하세요"
              options={MASTER_OPS?.TAB_WHOLE?.filter((ele) => ele.screenId === data?.screenId)?.map((ele) => {
                return {
                  label: ele.tabName,
                  value: ele.tabId,
                };
              })}
              value={
                data?.tabId
                  ? {
                      label: MASTER_OBJ?.TAB_NAME?.[data?.tabId],
                      value: data?.tabId,
                    }
                  : null
              }
              onChange={(option) => {
                setData({
                  ...data,
                  tabId: (option as OptionItem).value,
                  tabName: (option as OptionItem).label,
                });
              }}
            />
          </div>
          <div className="grid-wrapper div2">
            <div>
              <label>등록 화면</label>
              <SelectD
                filterOnlyLabel={false}
                placeholder="화면ID로 검색할것"
                value={
                  data?.openScreenId
                    ? {
                        label: MASTER_OBJ?.SCREENID_URL?.[data?.openScreenId],
                        value: data?.openScreenId,
                      }
                    : null
                }
                onChange={(option) => {
                  setData({
                    ...data,
                    openScreenId: (option as OptionItem)?.value,
                    openUrl: (option as OptionItem)?.label,
                  });
                }}
                options={MASTER_OPS?.SCREENID_URL}
              />
            </div>
            <div>
              <label>연결된 화면ID</label>
              <InputD value={data?.openScreenId || ''} placeholder="자동기입" readOnly disabled />
            </div>
          </div>

          <div className="grid-wrapper div3">
            <div>
              <label>위치*</label>
              <SelectD
                options={MASTER_OPS?.FUNCTION_TYPE}
                value={
                  data?.functionType
                    ? {
                        label: MASTER_OBJ?.FUNCTION_TYPE?.[data?.functionType],
                        value: data?.functionType,
                      }
                    : null
                }
                onChange={(option) => {
                  setData({
                    ...data,
                    functionType: (option as OptionItem).value,
                  });
                }}
              />
            </div>
            <div>
              <label>제목</label>
              <InputD
                value={data?.functionTitle || ''}
                onChange={(e) => {
                  setData({
                    ...data,
                    functionTitle: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <label>버튼색상</label>
              <SelectD
                options={MASTER_OPS?.COLOR}
                value={
                  data?.color
                    ? {
                        label: MASTER_OBJ?.COLOR?.[data?.color],
                        value: data?.color,
                      }
                    : null
                }
                onChange={(option) => {
                  setData({
                    ...data,
                    color: (option as OptionItem).value,
                  });
                }}
              />
            </div>
          </div>
          <label>사용여부</label>
          <div className="radio-area one-line">
            <Label>
              <Input
                type="radio"
                checked={data?.useFlag}
                onChange={(e) => {
                  setData({
                    ...data,
                    useFlag: true,
                  });
                }}
              />
              &nbsp;&nbsp;사용
            </Label>
            <Label>
              <Input
                type="radio"
                checked={!data?.useFlag}
                onChange={(e) => {
                  setData({
                    ...data,
                    useFlag: false,
                  });
                }}
              />
              &nbsp;&nbsp;사용 안 함
            </Label>
          </div>
          <div style={{ paddingTop: 30, textAlign: 'right' }}>
            {detailObj?.item && (
              <button className="btn btn-danger" onClick={() => deleteDetail(data)}>
                삭제
              </button>
            )}
            <button className={detailObj?.item ? 'btn btn-orange' : 'btn btn-blue'} onClick={() => saveDetail(data)}>
              {detailObj?.item ? '수정' : '등록'}
            </button>
          </div>
        </>
      }
    />
  );
};
