import { Row, Col } from 'reactstrap';
import Barcode from 'react-barcode';
import CommonModalNew from 'components/modal/commonModalNew';

export const HandleWithCare = () => {
  return <span style={{ position: 'relative', marginRight: 5, backgroundColor: 'red', color: 'white', padding: '1px 3px' }}>취급주의</span>;
};

export const JustSign = ({ signWhen = '*상,하차 / 검수 완료후 서명 하시오', signArr = ['작업지시일', '작업지시자', '작업자', '창고장'], contentArr = [' ', '', '', ''] }) => {
  return (
    <div>
      <div style={{ paddingTop: 10 }}>
        <p className="b">{signWhen}</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div className="signs_area">
          {signArr?.map((ele, i) => (
            <div style={{ display: 'flex', width: 350, padding: 5, justifyContent: 'space-between' }} key={`sign_${ele}_${i}`}>
              <span>{ele} : </span>
              {contentArr?.[i] ? (
                <>
                  <span className="sign_area" style={{ width: 200, textAlign: 'center' }}>
                    {contentArr?.[i]}
                  </span>
                </>
              ) : (
                <>
                  <span className="sign_area" style={{ width: 200, textAlign: 'right' }}>
                    (인)
                  </span>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const JustPallet = ({ message = '* 피킹 완료 후 서명 후 창고장에게 전달하세요.' }) => {
  return (
    <>
      <div>{message}</div>
      <table className="border-table">
        <thead>
          <tr>
            <th colSpan={1}></th>
            <th colSpan={2}>KPP</th>
            <th colSpan={2}>AJ</th>
            <th colSpan={2}>기타</th>
            <th colSpan={1}>합계</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th>파렛트종류</th>
            <th>1100*1100</th>
            <th>1400*1100</th>
            <th>1100*1100</th>
            <th>1965*1145</th>
            <th>ㅤㅤㅤㅤㅤ</th>
            <th>ㅤㅤㅤㅤㅤ</th>
            <th>ㅤㅤㅤㅤ</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>개수</th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export const PalletAndSign = ({ signWhen = '*상,하차 / 검수 완료후 서명 하시오', signArr = ['작업지시일', '작업지시자', '작업자', '창고장'], contentArr = [' ', '', '', ''] }) => {
  return (
    <div>
      <div style={{ paddingTop: 10 }}>
        <p className="b">{signWhen}</p>
      </div>
      <table className="border-table">
        <thead>
          <tr>
            <th colSpan={1}></th>
            <th colSpan={2}>KPP</th>
            <th colSpan={2}>AJ</th>
            <th colSpan={2}>기타</th>
            <th colSpan={1}>합계</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th>파렛트종류</th>
            <th>1100*1100</th>
            <th>1400*1100</th>
            <th>1100*1100</th>
            <th>1965*1145</th>
            <th>ㅤㅤㅤㅤㅤ</th>
            <th>ㅤㅤㅤㅤㅤ</th>
            <th>ㅤㅤㅤㅤ</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>개수</th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div className="signs_area">
          {signArr?.map((ele, i) => (
            <div style={{ display: 'flex', width: 350, padding: 5, justifyContent: 'space-between' }} key={`sign_${ele}_${i}`}>
              <span>{ele} : </span>
              {contentArr?.[i] ? (
                <>
                  <span className="sign_area" style={{ width: 200, textAlign: 'center' }}>
                    {contentArr?.[i]}
                  </span>
                </>
              ) : (
                <>
                  <span className="sign_area" style={{ width: 200, textAlign: 'right' }}>
                    (인)
                  </span>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const PrintTitle = ({ title, seq }) => {
  return (
    <Row>
      <Col lg={6}>
        <div style={{ fontSize: 40, fontWeight: 600 }}>{title}</div>
      </Col>
      <Col lg={6}>
        <div style={{ textAlign: 'right' }}>{seq && <Barcode width={1.5} value={seq + '' || ''} height={40} />}</div>
      </Col>
    </Row>
  );
};

export interface IBarcodeInfoObj {
  visible?: boolean;
  data?: string | number;
}
const ModalforBarcode = ({ barcodeInfoObj, setBarcodeInfoObj }) => {
  return (
    <CommonModalNew
      style={{ width: 700 }}
      visible={barcodeInfoObj?.visible}
      setVisible={() => {
        setBarcodeInfoObj({
          visible: false,
        });
      }}
      children={
        <div style={{ textAlign: 'center' }}>
          <Barcode value={barcodeInfoObj?.data || '12345'} />
        </div>
      }
    />
  );
};

export default ModalforBarcode;
