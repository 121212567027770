import { useEffect, useRef, useState } from 'react';
import { XLSX, checkHTML5Brower, fixdata, parseCvs, process_wb, rABS } from 'common/util/fileUploader';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { selectvalue } from 'common/master/codeMasterReturnHelper';
import { RootState } from 'redux/store';

const OX_FLAG = [
  { value: 'true', label: 'O' },
  { value: 'false', label: 'X' },
];
interface IUseExcelHooks {
  EXCELHEADER?: { [key: string]: string[] };
  KRTOVALUE?: { [key: string]: any };
  HEADERLINEIDX?: { [key: string]: number };
}

// EXCELHEADER 예시
// const EXCELHEADER = {
//   DRIVEREXCELUPLOAD: [
//     'dockNumber',
//     'driverName',
//     'driverType',
//     'position',
//     'phone1',
//     'address',
//     'zipCode',
//     'carType',
//     'partnerSeq',
//     'centerCode',
//     'memberCount',
//     'licenseType',
//     'carNumber',
//     'amDuration',
//     'pmDuration',
//   ],
//   DRIVEREXCELDOCKUPLOAD: ['userId', 'driverName', 'dockNumber'],
// };

// const KRTOVALUE = {
//   memberCount: 'TEAM_TYPE',
//   centerCode: 'CENTER',
//   partnerSeq: 'NETWORK',
//   position: 'DRV_POS',
//   driverType: 'DRV_TYPE',
//   carType: 'CAR_TYPE',
//   mondayFlag: true,
//   tuesdayFlag: true,
//   wednesdayFlag: true,
//   thursdayFlag: true,
//   fridayFlag: true,
//   saturdayFlag: true,
//   sundayFlag: true,
//   modelGroup: masterOptions?.MODEL_GROUP?.map((ele) => ele.value),
//   amDuration: (amDuration) => {
//     return Number(amDuration?.split('건')[0]) * 30;
//   },
//   pmDuration: (pmDuration) => {
//     return Number(pmDuration?.split('건')[0]) * 30;
//   },
// };

const itsNumber = ['transferPrice', 'price', 'extraFee', 'parcelExtraFee', 'fee', 'totalFee', 'emptyFee'];

const useExcelHooks = ({ EXCELHEADER, KRTOVALUE, HEADERLINEIDX }: IUseExcelHooks) => {
  const { masterOptions, MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const fileRef = useRef<HTMLInputElement>();
  const [parsedData, setParsedData] = useState<{ id: string; header: string[]; data: { [key: string]: string }[] }>();

  // useEffect(() => {
  //   console.log(masterOptions, MASTER_OPS, MASTER_OBJ)
  // }, [])


  const uploaderClick = (id: string) => {
    fileRef.current.id = id;
    fileRef.current.click();
  };

  const parseExcelFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLoading('GET'));
    if (!checkHTML5Brower()) {
      alert('브라우저가 HTML5 를 지원하지 않습니다.\r\n서버로 업로드해서 해결하십시오.');
      return;
    }
    const id = e.target.id;
    const file = (e.target as HTMLInputElement).files[0];
    if (typeof file === 'undefined') {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      /* 엑셀 바이너리 읽기 */
      let workbook;
      if (rABS) {
        // 일반적인 바이너리 지원하는 경우
        workbook = XLSX.read(data, { type: 'binary' });
      } else {
        // IE 10, 11인 경우
        const arr = fixdata(data);
        workbook = XLSX.read(Buffer.from(arr, 'base64'), { type: 'base64' });
      }

      const jsonObj = process_wb(workbook);
      if (jsonObj[Object.keys(jsonObj)[0]]) {
        const parseExcelDataArr = parseCvs(jsonObj[Object.keys(jsonObj)[0]]);
        let header = new Array(parseExcelDataArr[HEADERLINEIDX?.[id] || 0]?.length).fill('');
        // 완전히 빈 컬럼 찾기
        // console.log(parseExcelDataArr);
        parseExcelDataArr?.forEach((row, idx) => {
          if (idx <= HEADERLINEIDX?.[id] || 0) {
            row.forEach((el, i) => (header[i] = header[i] + el));
          }
        });
        if (header?.indexOf('')) header = header?.slice(0, header?.indexOf('')); // 띄어쓰기 꼭 해주세요
        const keys = KRTOVALUE ? Object.keys(KRTOVALUE) : [];
        const resultData = parseExcelDataArr
          ?.slice((HEADERLINEIDX?.[id] || 0) + 1)
          ?.filter((rw) => {
            const has =
              Object?.values(rw)
                ?.slice(0, header?.length)
                ?.filter((el) => el !== '')?.length > 0;
            return has;
          })
          ?.map((row) => {
            return row.slice(0, header?.length + 1);
          });
        const data = resultData?.map((row) => {
          const obj = {};
          EXCELHEADER?.[id]?.map((key, i) => {
            if (!row[i] && !KRTOVALUE?.[key]) {
              obj[key] = '';
            } else {
              if (!keys?.includes(key)) {
                if (key?.includes('Flag')) obj[key] = selectvalue(row[i], OX_FLAG);
                else if (itsNumber?.includes(key)) obj[key] = Number(row[i]?.replaceAll(',', ''));
                else obj[key] = row[i];
              } else {
                if (typeof KRTOVALUE?.[key] === 'undefined') {
                  obj[key] = row[i];
                } else if (typeof KRTOVALUE?.[key] === 'string') {
                  if (KRTOVALUE?.[key] === 'isNumber') obj[key] = Number(row[i] as string);
                  else {
                    if (MASTER_OBJ) {
                      obj[key] = selectvalue(row[i] + '', MASTER_OPS?.[KRTOVALUE?.[key] as string]);
                    } else {
                      obj[key] = selectvalue(row[i] + '', masterOptions?.[KRTOVALUE?.[key] as string]);
                    }
                  }
                } else if (typeof KRTOVALUE?.[key] === 'boolean') {
                  obj[key] = KRTOVALUE?.[key];
                } else if (typeof KRTOVALUE?.[key] === 'object') {
                  obj[key] = KRTOVALUE?.[key];
                } else if (typeof KRTOVALUE?.[key] === 'function') {
                  obj[key] = KRTOVALUE?.[key](row[i]);
                }
              }
            }
          });
          return obj;
        });
        if (data?.length > 0) setParsedData({ id, header, data });
        else alert('읽혀진 엑셀 값이 없습니다! 양식을 확인해주세요');
      }
    };

    reader.onloadend = () => {
      (e.target as HTMLInputElement).value = '';
      (e.target as HTMLInputElement).id = '';
      dispatch(setLoading(null));
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  return {
    ExcelDetector: () => {
      return <input accept=".xlsx" type="file" ref={fileRef} style={{ display: 'none' }} onChange={parseExcelFiles} />;
    },
    uploaderClick,
    parsedData,
  };
};

export default useExcelHooks;
