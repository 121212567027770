import CommonModalNew from 'components/modal/commonModalNew';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import GridBox, { defaultGridProps } from 'common/grid/gridBox';

export const ModelListModal = ({ modelSearchObj, setModelSearchObj }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<AUIGrid>();

  useEffect(() => {
    if (modelSearchObj?.data?.length > 0) {
      modelSearchObj?.data?.forEach((ele) => {
        ele.centerCodeKr = MASTER_OBJ?.CENTER_WHOLE?.[ele?.centerCode];
        ele.partnerSeqKr = MASTER_OBJ?.SELLER_SELLER_WHOLE?.[ele?.partnerSeq];
        if (ele['collectQuantity'] + ele['refurbishQuantity'] + ele['scrapQuantity'] + ele['manufactureQuantity'] + ele['investigateQuantity'] > 0) ele.choose = '추가';
      });
      gridRef.current.setGridData(modelSearchObj?.data);
    }
  }, []);

  const columns: IGrid.Column[] = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '양품',
      dataField: 'availableQuantity',
    },
    {
      headerText: '불용',
      children: [
        {
          headerText: '회수입고',
          dataField: 'collectQuantity',
        },
        {
          headerText: '리퍼대상',
          dataField: 'refurbishQuantity',
        },
        {
          headerText: '폐기대상',
          dataField: 'scrapQuantity',
        },
        {
          headerText: '반출대상',
          dataField: 'manufactureQuantity',
        },
        {
          headerText: '재고조사',
          dataField: 'investigateQuantity',
        },
      ],
    },
    {
      headerText: '*수량<br />입력',
      dataField: 'quantity',
      style: 'aui-textinputer',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        validator: (oldValue, newValue, item, dataField, fromClipboard, which) => {
          if (item?.collectQuantity + item?.refurbishQuantity + item?.scrapQuantity + item?.manufactureQuantity + item?.investigateQuantity === 0)
            return { validate: false, message: '가능한 수량이 없습니다' };
          if (item?.collectQuantity + item?.refurbishQuantity + item?.scrapQuantity + item?.manufactureQuantity + item?.investigateQuantity < newValue)
            return { validate: false, message: '가능한 수량 초과' };
        },
        autoThousandSeparator: true,
        onlyNumeric: true,
        allowNegative: false,
      },
    },
  ];

  const handleCheckItems = (e) => {
    const id = e.target.id;
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'add-row') {
        const noQuantity = items?.some((ele) => !ele.quantity);
        if (noQuantity) {
          alert('수량을 입력하지 않은 값이 있습니다');
        } else {
          setModelSearchObj((prev) => {
            return {
              ...prev,
              returns: items,
            };
          });
        }
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  return (
    <CommonModalNew
      title={'제품정보'}
      style={{ width: 1200 }}
      visible={modelSearchObj?.visible}
      setVisible={() => {
        setModelSearchObj(null);
      }}
      children={
        <>
          <div style={{ width: '100%' }}>
            <div className="grid-button-area">
              <div className="orange" id="add-row" onClick={handleCheckItems}>
                +제품추가
              </div>
            </div>
            <GridBox gridRef={gridRef} columns={columns} gridProps={{ editable: true }} />
          </div>
        </>
      }
    />
  );
};
