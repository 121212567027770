import React, { useEffect, useState } from 'react';

import { selectlabel } from 'common/master/codeMasterReturnHelper';

import { serviceStore } from 'services/services';
import { QuestionAndAnswerDetailDTO, QuestionAndAnswerSearchDTO } from 'interface/user';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import CommonModalNew from 'components/modal/commonModalNew';
import { ImageModal } from 'pages/ORDER/1order/orderListTab/common/component/photos/imageModal';

export interface IQnaObj {
  visible?: boolean;
  questionAndAnswerSeq?: number;
  searchObj?: QuestionAndAnswerSearchDTO;
  fetchFn: (searchObj: QuestionAndAnswerSearchDTO) => {};
}

const QnAModal = ({ qnaObj, setQnaObj }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [detailData, setDetailData] = useState<QuestionAndAnswerDetailDTO>();

  useEffect(() => {
    if (qnaObj?.questionAndAnswerSeq) {
      fetchDetail(qnaObj?.questionAndAnswerSeq);
    }
  }, []);

  const readThisQnA = async () => {
    await serviceStore.userAction(`question/read`, 'POST', null, { questionAndAnswerSeq: qnaObj?.questionAndAnswerSeq });
  };

  const fetchDetail = async (questionAndAnswerSeq: number) => {
    console.log(questionAndAnswerSeq);
    readThisQnA();
    const data = (await serviceStore.userAction(`question/${questionAndAnswerSeq}`, 'GET', null))?.data;
    console.log(data);
    if (data) {
      setDetailData(data);
    }
  };

  const removeThisData = async () => {
    if (window.confirm(`정말 삭제하시겠습니까?`)) {
      const rs = await serviceStore.userAction(`question/delete`, 'DELETE', null, { questionAndAnswerSeqs: [detailData?.questionAndAnswerSeq] });
      if (rs?.status === 200) {
        alert('삭제되었습니다!');
        qnaObj?.fetchFn(qnaObj?.searchObj);
      }
    }
  };

  // 답변 등록/삭제
  const regitAnswer = async () => {
    if (window.confirm('답변을 등록하시겠습니까?')) {
      const rs = await serviceStore?.userAction(`question/answer`, 'POST', null, {
        questionAndAnswerSeq: detailData?.questionAndAnswerSeq,
        answer: detailData?.answer,
      });
      if (rs?.status === 200) {
        alert('답변 등록이 완료되었습니다!');
      }
    }
  };

  return (
    <>
      <CommonModalNew
        style={{ width: 1000 }}
        title={'QnA 상세보기'}
        visible={qnaObj?.visible}
        setVisible={() => {
          setQnaObj(null);
        }}
        rightTitle={
          <>
            <button
              className="btn btn-danger ssm"
              onClick={() => {
                removeThisData();
              }}
            >
              삭제
            </button>
            <button
              className="btn btn-secondary ssm"
              onClick={() => {
                regitAnswer();
              }}
            >
              저장
            </button>
          </>
        }
        children={
          <>
            <div style={{ marginBottom: 10 }}>
              <span>1. 기본정보</span>
              <table className="border-table">
                <thead>
                  <tr>
                    <th>접수번호</th>
                    <th>지시번호</th>
                    <th>소속창고</th>
                    <th>기사명</th>
                    <th>고객명</th>
                  </tr>
                  <tr>
                    <td>{detailData?.questionAndAnswerSeq}</td>
                    <td>{detailData?.tableSeq}</td>
                    <td>{selectlabel(detailData?.centerCode, masterOptions?.CENTER)}</td>
                    <td>{detailData?.userName}</td>
                    <td>{detailData?.questionedCustomerName}</td>
                  </tr>
                  <tr>
                    <th>문의유형1</th>
                    <th>문의유형2</th>
                    <th>문의유형3</th>
                    <th>진행상태</th>
                    <th>담당자</th>
                  </tr>
                  <tr>
                    <td>{selectlabel(detailData?.inquiryTypeLarge, masterOptions?.QNA_TYPE1)}</td>
                    <td>{selectlabel(detailData?.inquiryTypeMedium, masterOptions?.QNA_TYPE2)}</td>
                    <td>{selectlabel(detailData?.inquiryTypeSmall, masterOptions?.QNA_TYPE3)}</td>
                    <td>{selectlabel(detailData?.status, masterOptions?.QNA_STATUS)}</td>
                    <td>{detailData?.answerUserName}</td>
                  </tr>
                  <tr>
                    <th>CS접수일</th>
                    <th>CS처리완료일</th>
                    <th>CS수정일</th>
                  </tr>
                  <tr>
                    <td>{detailData?.questionDatetime}</td>
                    <td>{detailData?.answerDatetime}</td>
                    <td>{detailData?.answerUpdateDatetime}</td>
                  </tr>
                </thead>
              </table>
            </div>
            <div style={{ marginBottom: 10 }}>
              <span>2. 문의내용</span>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '50%',
                    marginRight: 25,
                    padding: 10,
                    overflow: 'auto',
                    border: '1px solid lightgray',
                  }}
                >
                  {detailData?.files?.map((photo, i) => {
                    return (
                      <div key={`qnaImage_${i}}`} style={{}}>
                        <ImageModal
                          className="top_bar_profile_img"
                          photoArray={detailData?.files?.map((uploadUrl) => {
                            return {
                              uploadUrl,
                              width: 200,
                              height: 200,
                            };
                          })}
                          idx={i}
                          alt="이미지미리보기"
                          title={`사진`}
                        />
                      </div>
                    );
                  })}
                  {detailData?.files?.length === 0 && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', border: '1px solid lightgray' }}> 사진 정보 없음</div>
                  )}
                </div>
                <textarea style={{ fontWeight: 700, width: '50%' }} readOnly disabled value={detailData?.question || ''} />
              </div>
            </div>
            <div>
              <span>3. 답변내용</span>
              <textarea
                value={detailData?.answer || ''}
                onChange={(e) => {
                  setDetailData((prev) => {
                    return {
                      ...prev,
                      answer: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default QnAModal;
