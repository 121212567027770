import CommonModalNew from 'components/modal/commonModalNew';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { serviceStore } from 'services/services';
import { tanslatorSeqs, translatorAPI } from '..';
import { AccessCenterDetailDTO, AccessDiffCenterDetailDTO, AccessFrontDetailDTO, AccessInfraDetailDTO, AccessPartnerDetailDTO, AccessSalesChannelDetailDTO, AccessUserDTO } from 'interface/user';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import GridBox, { defaultGridProps } from 'common/grid/gridBox';
import AUIGrid from 'modules/Grid';
import { Col, Row, Form } from 'reactstrap';

import * as IGrid from 'aui-grid';
import { IAccessSearch } from './searchBox';
import { ControllerScreen, ITrappingData } from 'pages/ETC/4settings/settingTabs/1groups/component/modiMenuModal';
export interface IAuthDetailObj {
  visible: boolean;
  tabId: string;
  item: any;
  searchObj?: any;
  fetchFn: (searchObj) => {};
}

const translatorKr = {
  MANAGE_USERGROUP_SCREEN: '화면',
  MANAGE_USERGROUP_PARTNERS: '판매사',
  MANAGE_USERGROUP_INFRA: '인프라',
  MANAGE_USERGROUP_CENTERS: '창고',
  MANAGE_USERGROUP_DIFF: '타창고',
  MANAGE_USERGROUP_SALESCHANNEL: '판매채널',
};
const translatorName = {
  MANAGE_USERGROUP_PARTNERS: 'partnerName',
  MANAGE_USERGROUP_INFRA: 'infraName',
  MANAGE_USERGROUP_CENTERS: 'centerName',
  MANAGE_USERGROUP_DIFF: 'diffCenterName',
  MANAGE_USERGROUP_SALESCHANNEL: 'salesChannelName',
};
const translatorSeq = {
  MANAGE_USERGROUP_PARTNERS: 'partnerSeq',
  MANAGE_USERGROUP_INFRA: 'infraSeq',
  MANAGE_USERGROUP_CENTERS: 'centerCode',
  MANAGE_USERGROUP_DIFF: 'diffCenterCode',
  MANAGE_USERGROUP_SALESCHANNEL: 'salesChannelSeq',
};
export const AuthGroupDetail = ({ authDetailObj, setAuthDetailObj }) => {
  const excludedUserRef = useRef<AUIGrid>();
  const includedUserRef = useRef<AUIGrid>();
  const excludedRef = useRef<AUIGrid>();
  const includedRef = useRef<AUIGrid>();
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const [detailData, setDetailData] = useState<
    AccessDiffCenterDetailDTO & AccessCenterDetailDTO & AccessInfraDetailDTO & AccessFrontDetailDTO & AccessPartnerDetailDTO & AccessSalesChannelDetailDTO
  >();

  const [searchObj, setSearchObj] = useState<IAccessSearch>();
  const userColumns: IGrid.Column[] = [
    {
      headerText: '이름',
      dataField: 'userName',
    },
    {
      headerText: '아이디',
      dataField: 'userId',
    },
    {
      headerText: '사용자 유형',
      dataField: 'userCodeKr',
    },
    {
      headerText: '소속파트너',
      dataField: 'partnerSeqKr',
    },
  ];

  const tabIdColumns: { [key: string]: IGrid.Column[] } = {
    MANAGE_USERGROUP_PARTNERS: [
      {
        headerText: '판매사',
        dataField: 'partnerName',
      },
    ],
    MANAGE_USERGROUP_INFRA: [
      {
        headerText: '인프라',
        dataField: 'infraName',
      },
    ],
    MANAGE_USERGROUP_CENTERS: [
      {
        headerText: '창고명',
        dataField: 'centerName',
      },
    ],
    MANAGE_USERGROUP_DIFF: [
      {
        headerText: '타창고명',
        dataField: 'diffCenterName',
      },
    ],
    MANAGE_USERGROUP_SALESCHANNEL: [
      {
        headerText: '판매채널명',
        dataField: 'salesChannelName',
      },
    ],
  };

  useEffect(() => {
    if (detailData) {
      bindFunctions();
    }
  }, [detailData]);

  const printUseFlagData = (e: IGrid.DropEndEvent) => {
    const excluded = excludedRef?.current?.getGridData()?.map((ele) => {
      return {
        ...ele,
        useFlag: false,
      };
    });
    const included = includedRef?.current?.getGridData()?.map((ele) => {
      return {
        ...ele,
        useFlag: true,
      };
    });
    setDetailData((prev) => {
      return {
        ...prev,
        data: [...excluded, ...included],
      };
    });
    //
    excludedRef?.current?.setGridData(excluded);
    includedRef?.current?.setGridData(included);
  };

  const printUseFlagDataUsers = (e: IGrid.DropEndEvent) => {
    const excluded = excludedUserRef?.current?.getGridData()?.map((ele) => {
      return {
        ...ele,
        useFlag: false,
      };
    });
    const included = includedUserRef?.current?.getGridData()?.map((ele) => {
      return {
        ...ele,
        useFlag: true,
      };
    });
    setDetailData((prev) => {
      return {
        ...prev,
        users: [...excluded, ...included],
      };
    });
    //
    excludedUserRef?.current?.setGridData(excluded);
    includedUserRef?.current?.setGridData(included);
  };

  const bindFunctions = () => {
    excludedRef?.current?.bind('dropEnd', function (e) {
      printUseFlagData(e);
    });
    includedRef?.current?.bind('dropEnd', function (e) {
      printUseFlagData(e);
    });
    excludedUserRef?.current?.bind('dropEnd', function (e) {
      printUseFlagDataUsers(e);
    });
    includedUserRef?.current?.bind('dropEnd', function (e) {
      printUseFlagDataUsers(e);
    });
  };

  const fetchDetail = async (groupSeq) => {
    dispatch(setLoading('GET'));
    const data = (
      await serviceStore?.userAction(`access/${translatorAPI[authDetailObj?.tabId]}/detail`, 'GET', {
        groupSeq,
        [tanslatorSeqs[authDetailObj?.tabId]]: authDetailObj?.item?.[tanslatorSeqs[authDetailObj?.tabId]] || null,
      })
    )?.data;
    const detailData = {
      ...data,
      users: data?.users?.map((user) => {
        return {
          ...user,
          userCodeKr: MASTER_OBJ?.['USER_CODE']?.[user.userCode],
        };
      }),
    };
    excludedRef?.current?.setGridData(detailData?.data?.filter((ele) => !ele.useFlag));
    includedRef?.current?.setGridData(detailData?.data?.filter((ele) => ele.useFlag));
    excludedUserRef?.current?.setGridData(detailData?.users?.filter((ele) => !ele.useFlag));
    includedUserRef?.current?.setGridData(detailData?.users?.filter((ele) => ele.useFlag));

    setDetailData(detailData);
    dispatch(setLoading(null));
  };

  useEffect(() => {
    if (!reduxUserInfo?.sub?.includes('LD')) fetchDetail(reduxUserInfo?.groupSeq);
    else if (authDetailObj?.item?.groupSeq) fetchDetail(authDetailObj?.item?.groupSeq);
  }, []);

  const clickDrag = (e) => {
    const id = e.target.id?.split('-')[1];
    if (currentTab === 1) {
      const checkedItems = id === 'included' ? excludedRef?.current?.getCheckedRowItemsAll() : includedRef?.current?.getCheckedRowItemsAll();
      const checkedSeqs = checkedItems?.map((ele) => ele?.[translatorSeq[authDetailObj?.tabId]]);

      const beforeEx = excludedRef?.current?.getGridData();
      const beforeIn = includedRef?.current?.getGridData();
      const excludedRow =
        id === 'included'
          ? beforeEx?.filter((ele) => !checkedSeqs?.includes(ele?.[translatorSeq[authDetailObj?.tabId]]))
          : beforeEx?.concat(checkedItems)?.sort((a, b) => b?.[translatorSeq[authDetailObj?.tabId]] - a?.[translatorSeq[authDetailObj?.tabId]]);
      const includedRow =
        id === 'excluded'
          ? beforeIn?.filter((ele) => !checkedSeqs?.includes(ele?.[translatorSeq[authDetailObj?.tabId]]))
          : beforeIn?.concat(checkedItems)?.sort((a, b) => b?.[translatorSeq[authDetailObj?.tabId]] - a?.[translatorSeq[authDetailObj?.tabId]]);

      setDetailData((prev) => {
        return {
          ...prev,
          data: [
            ...excludedRow?.map((row) => {
              return {
                ...row,
                useFlag: false,
              };
            }),
            ...includedRow?.map((row) => {
              return {
                ...row,
                useFlag: true,
              };
            }),
          ],
        };
      });
      excludedRef?.current?.setGridData(excludedRow);
      includedRef?.current?.setGridData(includedRow);
    } else {
      const checkedItems = id === 'included' ? excludedUserRef?.current?.getCheckedRowItemsAll() : includedUserRef?.current?.getCheckedRowItemsAll();
      const checkedSeqs = checkedItems?.map((ele) => ele?.userId);

      const beforeEx = excludedUserRef?.current?.getGridData();
      const beforeIn = includedUserRef?.current?.getGridData();

      const excludedRow = id === 'included' ? beforeEx?.filter((ele) => !checkedSeqs?.includes(ele?.userId)) : beforeEx?.concat(checkedItems)?.sort((a, b) => b.userId - a.userId);
      const includedRow = id === 'excluded' ? beforeIn?.filter((ele) => !checkedSeqs?.includes(ele?.userId)) : beforeIn?.concat(checkedItems)?.sort((a, b) => b.userId - a.userId);

      setDetailData((prev) => {
        return {
          ...prev,
          users: [
            ...excludedRow?.map((row) => {
              return {
                ...row,
                useFlag: false,
              };
            }),
            ...includedRow?.map((row) => {
              return {
                ...row,
                useFlag: true,
              };
            }),
          ],
        };
      });
      excludedUserRef?.current?.setGridData(excludedRow);
      includedUserRef?.current?.setGridData(includedRow);
    }
    //
  };
  // 미추가 리스트만 필터링
  const filterSearch = () => {
    if (currentTab === 1) {
      let data = excludedRef?.current?.getGridData();
      if (!searchObj?.[translatorName[authDetailObj?.tabId]]) {
        excludedRef?.current?.setGridData(detailData?.data?.filter((ele) => !ele.useFlag));
      } else {
        // data;
        data = data?.filter((ele) => ele?.[translatorName[authDetailObj?.tabId]]?.includes(searchObj?.[translatorName[authDetailObj?.tabId]]));
        excludedRef?.current?.setGridData(data);
      }
    } else {
      let data = excludedUserRef?.current?.getGridData();
      if (!searchObj?.userName && !searchObj?.userId && !searchObj?.userCode && !searchObj?.partnerSeq) {
        excludedUserRef?.current?.setGridData(detailData?.users?.filter((ele) => !ele.useFlag));
      } else {
        if (searchObj?.userName) data = data?.filter((ele) => ele.userName?.includes(searchObj?.userName));
        if (searchObj?.userId) data = data?.filter((ele) => ele.userId?.includes(searchObj?.userId));
        if (searchObj?.userCode) data = data?.filter((ele) => searchObj?.userCode?.includes(ele.userCode));
        if (searchObj?.partnerSeq) data = data?.filter((ele) => ele.partnerSeq === searchObj?.partnerSeq);
        excludedUserRef?.current?.setGridData(data);
      }
    }
  };

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      filterSearch();
    }
  };

  const validator = (detailData) => {
    if (!detailData?.title) {
      alert('권한 그룹명을 입력해주세요!');
      return false;
    }
    return true;
  };
  const [trappingData, setTrappingData] = useState<ITrappingData>();
  const postSave = async (frontData = null) => {
    if (window?.confirm(`저장하시겠습니까?`)) {
      if (validator(detailData)) {
        const users = includedUserRef?.current?.getGridData();
        const data = includedRef?.current?.getGridData();
        const dataDTO = {
          ...detailData,
          ...frontData,
          users,
          data,
        };
        dispatch(setLoading('POST'));
        const rs = await serviceStore.userAction(`access/${translatorAPI[authDetailObj?.tabId]}/save`, 'POST', null, dataDTO);
        if (rs?.status === 200) {
          alert('저장되었습니다!');
          authDetailObj?.fetchFn(authDetailObj?.searchObj);
        }
        dispatch(setLoading(null));
      }
    }
  };

  useEffect(() => {
    if (trappingData?.data) {
      postSave(trappingData?.data);
    }
  }, [trappingData]);

  const [currentTab, setCurrentTab] = useState(0);
  const hasTabs = [{ title: '기본정보' }, { title: '권한' }, { title: '사용자' }];

  const handleCurrentTab = (e) => {
    if (detailData?.groupSeq) {
      const nextTab = Number(e.target.id?.split('_')[1]);
      if (nextTab) {
        excludedUserRef?.current?.setGridData(detailData?.users?.filter((ele) => !ele.useFlag));
        includedUserRef?.current?.setGridData(detailData?.users?.filter((ele) => ele.useFlag));
        excludedRef?.current?.setGridData(detailData?.data?.filter((ele) => !ele.useFlag));
        includedRef?.current?.setGridData(detailData?.data?.filter((ele) => ele.useFlag));
      }
      setSearchObj(null);
      setCurrentTab(nextTab);
    } else {
      alert('소속조직을 선택해주세요');
    }
  };

  return (
    <CommonModalNew
      style={{ width: 1280 }}
      visible={authDetailObj?.visible}
      setVisible={() => {
        setAuthDetailObj(null);
      }}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={() => {
              if (authDetailObj?.tabId === 'MANAGE_USERGROUP_SCREEN') {
                setTrappingData({
                  on: true,
                  data: null,
                });
              } else {
                postSave();
              }
            }}
          >
            {authDetailObj?.item ? '수정' : '추가'}
          </button>
        </>
      }
      title={`권한 그룹 ${authDetailObj?.item ? '상세보기' : '추가'} (${translatorKr[authDetailObj?.tabId]})`}
      children={
        <div style={{ minHeight: 700 }}>
          <div className="div-top-tab">
            {hasTabs.map((tab, index) => {
              return (
                <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                  <li id={`tab_${index}`} onClick={handleCurrentTab}>
                    {tab?.title}
                  </li>
                </div>
              );
            })}
          </div>

          <div style={{ flexDirection: 'column', zIndex: currentTab === 0 ? 13 : 10 }}>
            <table className="border-table" style={{ width: '100%', marginBottom: 30 }}>
              <thead>
                <tr>
                  <th>*권한그룹명</th>
                  <td colSpan={2}>
                    <InputD
                      value={detailData?.title || ''}
                      onChange={(e) => {
                        setDetailData((prev) => {
                          return {
                            ...prev,
                            title: e.target.value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*소속조직</th>
                  <td colSpan={2}>
                    <SelectD
                      // 상세보기 시에 클릭못하게
                      isDisabled={reduxUserInfo?.groupSeq || authDetailObj?.item}
                      options={MASTER_OPS?.JOJIK_GROUP}
                      value={
                        detailData?.groupSeq
                          ? {
                              value: detailData?.groupSeq,
                              label: MASTER_OBJ?.['JOJIK_GROUP']?.[detailData.groupSeq],
                            }
                          : null
                      }
                      onChange={(option) => {
                        const groupSeq = (option as OptionItem).value;
                        fetchDetail(groupSeq);
                        setDetailData((prev) => {
                          return {
                            ...prev,
                            groupSeq,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>
                    미리보기
                    <br />
                    (자동생성)
                  </th>
                  <td colSpan={5}>
                    <InputD
                      disabled
                      readOnly
                      value={detailData?.preview || ''}
                      onChange={(e) => {
                        setDetailData((prev) => {
                          return {
                            ...prev,
                            preview: e.target.value,
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>설명</th>
                  <td colSpan={11}>
                    <textarea
                      value={detailData?.description || ''}
                      onChange={(e) => {
                        setDetailData((prev) => {
                          return {
                            ...prev,
                            description: e.target.value,
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
              </thead>
            </table>
          </div>
          {authDetailObj?.tabId === 'MANAGE_USERGROUP_SCREEN' ? (
            <div style={{ flexDirection: 'column', zIndex: currentTab === 1 ? 13 : 10 }}>
              {detailData && <ControllerScreen rawData={detailData} trappingData={trappingData} setTrappingData={setTrappingData} />}
            </div>
          ) : (
            <div style={{ flexDirection: 'column', zIndex: currentTab === 1 ? 13 : 10, paddingTop: 0 }}>
              <div className="presenterSearch">
                <label className="b">[미추가 리스트 검색]</label>
                <Form onKeyDown={onKeyPress}>
                  <Row>
                    <Col>
                      <label className="col-form-label">{translatorKr[authDetailObj?.tabId]}</label>
                      <InputD
                        value={searchObj?.[translatorName[authDetailObj?.tabId]] || ''}
                        onChange={(e) => {
                          setSearchObj({
                            [translatorName[authDetailObj?.tabId]]: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col>
                      <label className="col-form-label" style={{ height: 12 }}></label>
                      <button
                        className="btn btn-search"
                        onClick={(e) => {
                          e.preventDefault();
                          filterSearch();
                        }}
                      >
                        조회
                      </button>
                    </Col>
                    <Col />
                  </Row>
                </Form>
              </div>
              <div className="grid2-wrapper" style={{ paddingTop: 3 }}>
                <div style={{ width: 'calc(50% - 30px)' }}>
                  <label className="b">[미추가 리스트]</label>
                  <GridBox
                    gridName="excluded"
                    gridRef={excludedRef}
                    columns={tabIdColumns[authDetailObj?.tabId]}
                    gridProps={{
                      ...defaultGridProps,
                      enableDrag: true,
                      enableDrop: true,
                      dropToOthers: true,
                      showRowAllCheckBox: true,
                      showRowCheckColumn: true,
                      pageRowCount: 500,
                      pageRowSelectValues: [500],
                    }}
                  />
                </div>
                <div style={{ width: 'calc(50% - 30px)' }}>
                  <div className="grid-button-between">
                    <div className="button" id={'to-included'} onClick={clickDrag}>
                      ▶️ 추 가
                    </div>
                    <div className="button" id={'to-excluded'} onClick={clickDrag}>
                      삭 제 ◀️
                    </div>
                  </div>
                  <label className="b">[추가된 리스트]</label>

                  <GridBox
                    gridName="included"
                    gridRef={includedRef}
                    columns={tabIdColumns[authDetailObj?.tabId]}
                    gridProps={{
                      ...defaultGridProps,
                      enableDrag: true,
                      enableDrop: true,
                      dropToOthers: true,
                      showRowAllCheckBox: true,
                      showRowCheckColumn: true,
                      pageRowCount: 500,
                      pageRowSelectValues: [500],
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div style={{ flexDirection: 'column', zIndex: currentTab === 2 ? 13 : 10, paddingTop: 0 }}>
            <div className="presenterSearch">
              <label className="b">[미추가 리스트 검색]</label>
              <Form onKeyDown={onKeyPress}>
                <Row>
                  <Col>
                    <label className="col-form-label">이름</label>
                    <InputD
                      value={searchObj?.userName || ''}
                      onChange={(e) => {
                        setSearchObj((prev) => {
                          return {
                            ...prev,
                            userName: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label className="col-form-label">아이디</label>
                    <InputD
                      value={searchObj?.userId || ''}
                      onChange={(e) => {
                        setSearchObj((prev) => {
                          return {
                            ...prev,
                            userId: e.target.value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label className="col-form-label">사용자 유형</label>
                    <SelectD
                      isMulti
                      value={searchObj?.userCode?.map((ele) => {
                        return {
                          value: ele,
                          label: MASTER_OBJ?.['USER_CODE']?.[ele],
                        };
                      })}
                      options={MASTER_OPS?.USER_CODE}
                      onChange={(option) => {
                        setSearchObj({
                          ...searchObj,
                          userCode: (option as OptionItem[])?.map((ele) => ele.value),
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label className="col-form-label">소속 파트너</label>
                    <SelectD
                      value={
                        searchObj?.partnerSeq
                          ? {
                              value: searchObj?.partnerSeq,
                              label: MASTER_OBJ?.['SELLER_SELLER_AUTH']?.[searchObj?.partnerSeq],
                            }
                          : null
                      }
                      options={MASTER_OPS?.SELLER_SELLER_AUTH}
                      onChange={({ value }) => {
                        setSearchObj({
                          ...searchObj,
                          partnerSeq: value,
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label className="col-form-label" style={{ height: 12 }}></label>
                    <button
                      className="btn btn-search"
                      onClick={(e) => {
                        e.preventDefault();
                        filterSearch();
                      }}
                    >
                      조회
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="grid2-wrapper" style={{ paddingTop: 3 }}>
              <div style={{ width: 'calc(50% - 30px)' }}>
                <label className="b">[미추가 리스트]</label>
                <GridBox
                  gridName="excludedUser"
                  gridRef={excludedUserRef}
                  columns={userColumns}
                  gridProps={{
                    ...defaultGridProps,
                    enableDrag: true,
                    enableDrop: true,
                    dropToOthers: true,
                    showRowAllCheckBox: true,
                    showRowCheckColumn: true,
                    pageRowCount: 500,
                    pageRowSelectValues: [500],
                  }}
                />
              </div>
              <div style={{ width: 'calc(50% - 30px)' }}>
                <div className="grid-button-between">
                  <div className="button" id={'to-included'} onClick={clickDrag}>
                    ▶️ 추 가
                  </div>
                  <div className="button" id={'to-excluded'} onClick={clickDrag}>
                    삭 제 ◀️
                  </div>
                </div>
                <label className="b">[추가된 리스트]</label>

                <GridBox
                  gridName="includedUser"
                  gridRef={includedUserRef}
                  columns={userColumns}
                  gridProps={{
                    ...defaultGridProps,
                    enableDrag: true,
                    enableDrop: true,
                    dropToOthers: true,
                    showRowAllCheckBox: true,
                    showRowCheckColumn: true,
                    pageRowCount: 500,
                    pageRowSelectValues: [500],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
