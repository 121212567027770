import { ReactNode, useEffect, useState } from 'react';

// redux
import { useSelector } from 'react-redux';

// component
import Tab1 from './locationStatics';

// options
import { RootState } from 'redux/store';
import { TopTab } from 'components/template/topTabNew';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { useLocation } from 'react-router-dom';
import { TabDTO } from 'interface/user';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';

const Index = () => {
  const location = useLocation();
  const [tabs, setTabs] = useState<TabDTO[]>();
  const [tabItems, setTabItems] = useState<{ titles?: string[]; containers: ReactNode[] }>();
  const { useableTabs } = useSelector((state: RootState) => state.auth);

  const { initMasterOptions } = useMasterOptions();

  const containerList = [{ container: <Tab1 tabId={'LOCATIONSEARCH'} />, tabId: 'LOCATIONSEARCH' }];

  useEffect(() => {
    if (tabs) {
      const tabIds = tabs?.map((ele) => ele.tabId);
      const tabLabeling = tabs?.map((ele) => {
        return {
          value: ele.tabId,
          label: ele.tabName,
        };
      });

      setTabItems({
        titles: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => selectlabel(ele.tabId, tabLabeling)),
        containers: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => ele.container),
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (useableTabs) {
      const tabDTO = useableTabs?.[location.pathname] ? [...useableTabs?.[location.pathname]] : [];
      if (tabDTO?.length > 0) {
        tabDTO?.sort((a: TabDTO, b: TabDTO) => a?.sort - b?.sort);
        setTabs(tabDTO);
        const masterArr = [
          'USE_FLAG',
          'LOCATION_TYPE',
          'DRIVER_WHOLE',
          'SELLER_AUTH',
          'SELLER_WHOLE',
          'CENTER_AUTH',
          'CENTER_WHOLE',
          'MODEL_GROUP',
          'FEE_TYPE',
          'ORDER_SEQ',
          'INVA_STATUS',
          'INVA_TYPE',
          'INVM_STATUS',
          'SEARCH_SKU',
          'PICK_FLAG',
          'SEARCH_MODEL',
        ];
        initMasterOptions(masterArr);
      }
    }
  }, [useableTabs]);

  return <>{tabItems && <TopTab titles={tabItems?.titles} containers={tabItems?.containers} />}</>;
};

export default Index;
