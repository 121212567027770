import styled from 'styled-components';
import { LDSLabel3XS } from './Typography';
import { COLORS } from 'common/constants/appearance';

type BadgeProps = {
  $type: 'NUMBER' | 'LABEL';
  $value: number | string;
  $size: 'SM' | 'MD';
  $color: 'PRIMARY' | 'ALERT' | 'WARNING' | 'BLUE' | 'SUCCESS' | 'PURPLE' | 'NEUTRAL' | 'WHITE' | 'ALERT_LIGHT' | 'WARNING_LIGHT' | 'BLUE_LIGHT' | 'SUCCESS_LIGHT' | 'PURPLE_LIGHT';
  $isDotType?: boolean;
};

export default function LDSBadge({ ...props }: BadgeProps) {
  return (
    <LDSBadgeStyle {...props} className="lds-badge">
      {props.$isDotType && <div className="dot"></div>}
      <LDSLabel3XS $weight={'SEMIBOLD'}>{props.$value}</LDSLabel3XS>
    </LDSBadgeStyle>
  );
}

const LDSBadgeStyle = styled.div<BadgeProps>`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: ${(props) => (props.$size === 'SM' ? '2px 6px' : '3px 8px')};
  border-radius: 24px;

  background-color: ${(props) =>
    props.$type === 'NUMBER'
      ? COLORS.PRIMARY
      : props.$isDotType
      ? COLORS.WHITE
      : props.$color === 'NEUTRAL'
      ? COLORS.GRAY_02_DEEP
      : props.$color === 'WHITE'
      ? COLORS.WHITE
      : COLORS[`${props.$color}_015`]};

  color: ${(props) => (props.$type === 'NUMBER' ? COLORS.WHITE : props.$color === 'WHITE' ? COLORS.GRAY_08 : props.$isDotType || props.$color === 'NEUTRAL' ? COLORS.GRAY_05 : COLORS[props.$color])};

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: ${(props) => (props.$color === 'NEUTRAL' || props.$color === 'WHITE' ? COLORS.GRAY_05 : COLORS[props.$color])};
  }
`;
