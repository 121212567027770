import styled from 'styled-components';
import LDSBadge from '../atoms/Badge';
import { VALUES, COLORS, TRANSITIONS } from 'common/constants/appearance';
import { LDSLabelXS } from '../atoms/Typography';
import { createPortal } from 'react-dom';
import { PortalProps } from '../layouts/Dropdown';
import { useLayoutEffect, useRef, useState } from 'react';
import { calculateElementPositionAndSize } from 'common/util/handleElement';
import { TBRL } from '../types/types';

export type TooltipProps = {
  $direction: TBRL;
  $anchorPosition: TBRL | 'CENTER';
  $description: string;
  $badgeDescription?: string;
  $maxWidth?: number;
  $data?: PortalPositionDataProps;
};

export default function LDSTooltip({ $description, $badgeDescription, $data, ...props }: TooltipProps) {
  const TooltipPortal = ({ children }: PortalProps) => {
    return createPortal(children, document.getElementById('tooltip') as HTMLElement);
  };

  const tooltipRef = useRef(null);
  const [tooltipSize, setTooltipSize] = useState<PortalPositionDataProps>({ x: 0, y: 0 });

  useLayoutEffect(() => {
    setTooltipSize(calculateElementPositionAndSize(tooltipRef.current));
  }, [$description]);

  return (
    <TooltipPortal>
      <LDSTooltipContainer $data={$data} {...props} ref={tooltipRef} $tooltipWidth={tooltipSize.width} $tooltipHeight={tooltipSize.height}>
        {$description && (
          <LDSTooltipWrapper {...props} $opacity={tooltipSize.width !== 0 ? 1 : 0}>
            <LDSLabelXS $weight={'SEMIBOLD'}>{$description}</LDSLabelXS>
            {$badgeDescription && <LDSBadge $type={'LABEL'} $color={'WHITE'} $size={'SM'} $value={$badgeDescription} />}
          </LDSTooltipWrapper>
        )}
      </LDSTooltipContainer>
    </TooltipPortal>
  );
}

export type PortalPositionDataProps = {
  x: number;
  y: number;
  width?: number;
  height?: number;
};

const LDSTooltipContainer = styled.div<Omit<TooltipProps, '$badgeDescription' | '$description'> & { $tooltipWidth?: number; $tooltipHeight?: number }>`
  z-index: ${VALUES.TOOLTIP_Z_INDEX};
  position: ${(props) => (!props.$data ? 'relative' : 'fixed')};
  left: ${(props) =>
    (props.$direction === 'BOTTOM' || props.$direction === 'TOP') && props.$anchorPosition === 'CENTER'
      ? props.$data?.x + props.$data?.width / 2 - props.$tooltipWidth / 2
      : (props.$direction === 'BOTTOM' || props.$direction === 'TOP') && props.$anchorPosition === 'LEFT'
      ? props.$data?.x
      : (props.$direction === 'BOTTOM' || props.$direction === 'TOP') && props.$anchorPosition === 'RIGHT'
      ? props.$data?.x + props.$data?.width - props.$tooltipWidth
      : props.$direction === 'RIGHT'
      ? props.$data?.x + props.$data?.width + VALUES.BUFFER
      : props.$direction === 'LEFT'
      ? props.$data?.x - props.$tooltipWidth - VALUES.BUFFER
      : 0}px;
  top: ${(props) =>
    props.$direction === 'BOTTOM'
      ? props.$data?.y + props.$data?.height + VALUES.BUFFER
      : props.$direction === 'TOP'
      ? props.$data?.y - props.$tooltipHeight - VALUES.BUFFER
      : (props.$direction === 'RIGHT' || props.$direction === 'LEFT') && props.$anchorPosition === 'CENTER'
      ? props.$data?.y + props.$data?.height / 2 - props.$tooltipHeight / 2
      : (props.$direction === 'RIGHT' || props.$direction === 'LEFT') && props.$anchorPosition === 'TOP'
      ? props.$data?.y
      : (props.$direction === 'RIGHT' || props.$direction === 'LEFT') && props.$anchorPosition === 'BOTTOM'
      ? props.$data?.y + props.$data?.height - props.$tooltipHeight
      : 0}px;
`;

const LDSTooltipWrapper = styled.div<Omit<TooltipProps, '$description'> & { $opacity?: number }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: ${VALUES.RADIUS}px;
  background-color: ${COLORS.GRAY_09};
  color: ${COLORS.WHITE};
  padding: 8px 12px;
  gap: 4px;
  justify-content: center;
  position: relative;

  opacity: ${(props) => props.$opacity};
  transition: opacity 200ms ${TRANSITIONS.BEZIER};

  max-width: ${(props) => props.$maxWidth && props.$maxWidth}px;

  label {
    word-wrap: break-word;
    word-break: break-word;
    text-align: center;
    white-space: pre-line;
  }
  &:before {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    background-color: inherit;
    width: 8px;
    height: 8px;

    top: ${(props) => (props.$direction === 'BOTTOM' ? '-4px' : props.$direction === 'TOP' ? 'auto' : props.$anchorPosition === 'TOP' ? '14px' : 'auto')};
    bottom: ${(props) => (props.$direction === 'TOP' ? '-4px' : props.$direction === 'BOTTOM' ? 'auto' : props.$anchorPosition === 'BOTTOM' ? '14px' : 'auto')};
    left: ${(props) => (props.$direction === 'RIGHT' ? '-4px' : props.$direction === 'LEFT' ? 'auto' : props.$anchorPosition === 'LEFT' ? '14px' : null)};
    right: ${(props) => (props.$direction === 'LEFT' ? '-4px' : props.$direction === 'RIGHT' ? 'auto' : props.$anchorPosition === 'RIGHT' ? '14px' : null)};
  }
`;
