import { KeyboardEvent, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { numberThousandComma } from 'common/util/counting';
import { detectMobileDevice } from 'common/util/detectDevice';
import { ParcelInvoiceCollectSearchDTO } from '../../../../_interface/parcel';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export const SearchBox = (props: ISearchBoxProps<ParcelInvoiceCollectSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const isMobile = detectMobileDevice(window.navigator.userAgent);
  const { searchObj, setSearchObj, fetchList, fetchCount } = props;
  const [closed, setClosed] = useState(false);
  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    fetchList(searchObj);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <div style={{ display: !closed ? 'unset' : 'none' }}>
              <label className="col-form-label">*등록일</label>
              <FlatpickrD
                onReset={() => {
                  setSearchObj({
                    ...searchObj,
                    fromRegisterDate: null,
                    toRegisterDate: null,
                  });
                }}
                value={[searchObj?.fromRegisterDate, searchObj?.toRegisterDate]}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    fromRegisterDate: returnDateyyyymmdd(option[0]),
                    toRegisterDate: returnDateyyyymmdd(option[1]),
                  });
                }}
              />
            </div>
          </Col>
          <Col>
            <div style={{ display: !closed ? 'unset' : 'none' }}>
              <label className="col-form-label">SBL</label>
              <InputD
                value={searchObj?.subNumber || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    subNumber: e.target.value,
                  });
                }}
              />
            </div>
          </Col>
          <Col>
            <label className="col-form-label">판매사(포워더)</label>
            <SelectD
              isMulti
              value={searchObj?.partnerSeq?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.SELLER_FORWARDER_AUTH?.[ele],
                };
              })}
              options={MASTER_OPS?.SELLER_FORWARDER_AUTH}
              onChange={(options) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: (options as OptionItem[]).map((ele) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">양륙항</label>
            <SelectD
              isMulti
              value={searchObj?.portCode?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.PORT_CODE?.[ele],
                };
              })}
              options={MASTER_OPS?.PORT_CODE}
              onChange={(options) => {
                setSearchObj({
                  ...searchObj,
                  portCode: (options as OptionItem[]).map((ele) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <div style={{ display: !closed ? 'unset' : 'none' }}>
              <label className="col-form-label">등록자</label>
              <InputD
                value={searchObj?.registerId || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    registerId: e.target.value,
                  });
                }}
              />
            </div>
          </Col>
          <Col>
            <div style={{ display: !closed ? 'unset' : 'none' }}>
              <label className="col-form-label">스캔순번</label>
              <div style={{ display: 'flex' }}>
                <InputD
                  type="number"
                  placeholder="시작순번 입력.."
                  value={searchObj?.fromNumber || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      fromNumber: Number(e.target.value),
                    });
                  }}
                />
                <InputD
                  placeholder="종료순번 입력.."
                  value={searchObj?.toNumber || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      toNumber: Number(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ display: !closed ? 'unset' : 'none' }}>
              <label className="col-form-label">집화전송차수</label>
              <InputD
                value={searchObj?.stage || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    stage: e.target.value,
                  });
                }}
              />
            </div>
          </Col>
          <Col>
            <label className="col-form-label">택배사</label>
            <SelectD
              isMulti
              value={searchObj?.parcelType?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.PARCEL_TYPE?.[ele],
                };
              })}
              options={MASTER_OPS?.PARCEL_TYPE}
              onChange={(options) => {
                setSearchObj({
                  ...searchObj,
                  parcelType: (options as OptionItem[]).map((ele) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">크기</label>
            <SelectD
              isMulti
              value={searchObj?.boxSize?.map((ele) => {
                const label = ele === 'ABNORMAL' ? '이형' : MASTER_OBJ?.CARGO_BOX_SIZE?.[ele];
                return {
                  value: ele,
                  label: label,
                };
              })}
              options={[
                ...MASTER_OPS?.CARGO_BOX_SIZE,
                {
                  label: '이형',
                  value: 'ABNORMAL',
                },
              ]}
              onChange={(options) => {
                setSearchObj({
                  ...searchObj,
                  boxSize: (options as OptionItem[]).map((ele) => ele.value),
                });
              }}
            />
          </Col>
          <Col>
            <div style={{ display: !closed ? 'unset' : 'none' }}>
              <label className="col-form-label">HBL</label>
              <InputD
                placeholder="다중 입력..."
                value={searchObj?.houseNumber || ''}
                onChange={(e) => {
                  const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                  if (value?.length > 50) {
                    alert('50개까지 검색가능합니다');
                  }
                  setSearchObj({
                    ...searchObj,
                    houseNumber: value?.length > 50 ? value?.splice(0, 50) : value,
                  });
                }}
              />
            </div>
          </Col>
          <Col>
            <button className="btn btn-search" type="submit">
              조회
            </button>
            {isMobile && (
              <button
                className="btn  sssm"
                onClick={(e) => {
                  e.preventDefault();
                  setClosed(!closed);
                }}
              >
                {closed ? '⬇️' : '⬆️'}
              </button>
            )}
          </Col>
          <Col />
        </Row>
      </Form>
      <div style={{ textAlign: 'right' }}>
        <div className="status-panel">
          <span>
            <label>전체집화내역</label> {numberThousandComma(fetchCount?.totalQuantity || 0)} 건
          </span>
          <span>
            <label>미전송집화내역</label> {numberThousandComma(fetchCount?.notSaveQuantity || 0)} 건
          </span>
          <span>
            <label>전송집화내역</label> {numberThousandComma(fetchCount?.saveQuantity || 0)} 건
          </span>
        </div>
      </div>
    </>
  );
};
