import { useEffect } from 'react';

import Container1 from './total/container.js';
import Container2 from './driver/container.js';
import CommonTab from '../../_common/supplement';
// redux
import { useSelector, useDispatch } from 'react-redux';

// options
import { centerListForlabeling, codeMasterOptionReturn, driverListForLabeling, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { setLoading, setMasterOptions } from 'redux/services/menuSlice';

import { RootState } from 'redux/store';
import { TopTab } from 'components/template/topTabNew';

const Index = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [
      SELLER_WHOLE,
      SELLER_AUTH,
      CENTER_WHOLE,
      CENTER_AUTH,
      LOAN_PICK_STATUS,
      ORDER_HOLD_TYPE,
      MODEL_GROUP,
      LOAN_PICK_CLOSE_REASON,
      DRIVER,
      MODEL_GRP_1,
      LOAN_PICK_TYPE,
      MODEL_TYPE,
      INVA_TYPE,
      ORDER_SEQ,
    ] = await Promise.all([
      partnerListForlabeling({ sellerFlag: true }),
      partnerListForlabeling({ sellerFlag: true, selectFlag: false }),
      centerListForlabeling(),
      centerListForlabeling({ selectFlag: false }),
      codeMasterOptionReturn('LOAN_PICK_STATUS'),
      codeMasterOptionReturn('ORDER_HOLD_TYPE'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('LOAN_PICK_CLOSE_REASON'),
      driverListForLabeling(),
      codeMasterOptionReturn('MODEL_GRP_1'),
      codeMasterOptionReturn('LOAN_PICK_TYPE'),
      codeMasterOptionReturn('MODEL_TYPE'),
      codeMasterOptionReturn('INVA_TYPE'),
      codeMasterOptionReturn('ORDER_SEQ'),
    ]);

    dispatch(
      setMasterOptions({
        SELLER_AUTH: SELLER_AUTH?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER_WHOLE,
        CENTER_AUTH: CENTER_AUTH?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        LOAN_PICK_STATUS,
        ORDER_HOLD_TYPE,
        MODEL_GROUP,
        LOAN_PICK_CLOSE_REASON,
        DRIVER: DRIVER?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        DRIVER_LABEL: DRIVER?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        MODEL_GRP_1,
        LOAN_PICK_TYPE,
        MODEL_TYPE,
        INVA_TYPE,
        PRINT_TYPE: [
          { value: 'basic', label: '상품구성순' },
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'dockNumber', label: '도크넘버' },
          { value: 'locationCode', label: '로케이션' },
        ],
        ASC_TYPE: [
          { value: 'ascend', label: '오름차순' },
          { value: 'descend', label: '오름차순' },
        ],
        ORDER_SEQ: [
          { value: 'orderSeq', label: '주문번호' },
          { value: 'externalOrderNumber', label: '외부주문번호' },
          { value: 'invoiceSeq', label: '송장번호' },
          { value: 'externalInvoiceNumber', label: '외부송장번호' },
          { value: 'customerName', label: '고객명' },
        ],
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'barcode', label: '바코드' },
          { value: 'modelStockSeq', label: 'SKU' },
          { value: 'skuNumber', label: '외부SKU' },
        ],
        INVENTORY_SEQ: [
          { value: 'inventorySeq', label: '재고번호' },
          { value: 'serialNumber', label: '시리얼번호' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
  }, []);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);

  return (
    <>
      {masterOptions && (
        <TopTab
          titles={reduxUserInfo?.sub?.includes('LD') ? ['재고보충지시', 'FC피킹(총량피킹)', 'FC피킹(기사별피킹)'] : ['FC피킹(총량피킹)', 'FC피킹(기사별피킹)']}
          containers={reduxUserInfo?.sub?.includes('LD') ? [<CommonTab tabId={'loan'} />, <Container1 />, <Container2 />] : [<Container1 />, <Container2 />]}
        />
      )}
    </>
  );
};

export default Index;
