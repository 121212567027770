import { useState, useEffect, ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled, { CSSProperties } from 'styled-components';
import closeImg from 'assets/images/close/closeIcon_black.png';
import transparent from '../template/transparent.png';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';
import { COLORS } from 'common/constants/appearance';

const OverlayWrapper = styled.div({
  minWidth: '100vw',
  minHeight: '100vh',
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 999,
  backgroundColor: 'rgba(65, 62, 82, 0.6);', // 그림자 느낌
});

const ModalWrapper = styled.div<{ designType: string }>`
  border-radius: 10px;
  background-color: white;
  //
  max-width: 100vw !important;
  max-height: 100vh;

  width: 350px; //default
  padding: 20px;
  padding-top: 15px;
  padding-right: 15px;
  position: absolute;
  top: 50%;
  left: 50%;

  .grid-button-area {
    padding: ${(props) => (props.designType === 'new' ? '8px 0' : null)} !important;
    row-gap: ${(props) => (props.designType === 'new' ? '4px' : null)} !important;

    .orange,
    .red,
    .blue,
    .green,
    .gray {
      border-radius: ${(props) => (props.designType === 'new' ? '5px' : null)} !important;
    }
  }
  .div-top-tab {
    height: ${(props) => (props.designType === 'new' ? '34px' : null)};
  }
  .bordering {
    padding: ${(props) => (props.designType === 'new' ? '8px 16px' : null)} !important;
    background-color: transparent;
    li {
      font-size: ${(props) => (props.designType === 'new' ? '12px' : null)} !important;
      line-height: ${(props) => (props.designType === 'new' ? '16px' : null)} !important;
    }
  }
  .bordering-current {
    padding: ${(props) => (props.designType === 'new' ? '8px 16px' : null)} !important;
    border-color: ${(props) => (props.designType === 'new' ? COLORS.PRIMARY : null)} !important;
    li {
      color: ${(props) => (props.designType === 'new' ? COLORS.PRIMARY : null)} !important;
      font-size: ${(props) => (props.designType === 'new' ? '12px' : null)} !important;
      line-height: ${(props) => (props.designType === 'new' ? '16px' : null)} !important;
      font-weight: ${(props) => (props.designType === 'new' ? '700' : null)} !important;
    }
  }
  .btn-secondary:not(.btn-reload):not(.btn-etc),
  .btn-orange {
    background-color: ${(props) => (props.designType === 'new' ? COLORS.PRIMARY : null)} !important;
    border-color: ${(props) => (props.designType === 'new' ? COLORS.PRIMARY : null)} !important;
  }
  .btn.btn-photo-add,
  .btn.btn-address-search,
  .btn.btn-etc,
  .light-orange {
    color: ${(props) => (props.designType === 'new' ? COLORS.PRIMARY : null)} !important;
    border-color: ${(props) => (props.designType === 'new' ? COLORS.PRIMARY : null)} !important;
  }
  .radio-select-area .active {
    background-color: ${(props) => (props.designType === 'new' ? COLORS.PRIMARY : null)} !important;
    border-color: ${(props) => (props.designType === 'new' ? COLORS.PRIMARY : null)} !important;
  }
`;

const ModalHeader = styled.div({
  position: 'relative',
  backgroundColor: 'white',
  minHeight: 30,
  paddingBottom: 10,
  display: 'flex',
  justifyContent: 'space-between',
});

const ModalBody = styled.div`
  background-color: white;
`;

const ModalFooter = styled.div`
  background-color: white;
`;

export interface ICommonModalProps {
  visible: boolean;
  setVisible: () => void;
  type?: 'print' | null;
  className?: string;
  style?: CSSProperties;
  bodyStyle?: CSSProperties;
  children: ReactNode;
  footer?: ReactNode;
  disableOverlayClick?: boolean;
  overlayVisible?: boolean;
  title?: string | ReactNode;
  rightTitle?: string | ReactNode;
  subTitle?: string | ReactNode;
}

const CommonModalNew = ({ visible, setVisible, type = null, style, children, footer, title, rightTitle = null, subTitle, disableOverlayClick = true }: ICommonModalProps) => {
  const location = useLocation();
  const el = document.getElementById('modal-root') as HTMLElement;
  const [key, _] = useState(location?.pathname + Date.now());
  const [top, setTop] = useState<number>(10);
  const [left, setLeft] = useState<number>(window.innerWidth / 2 - ((style?.width as number) || 1200) / 2 > 0 ? window.innerWidth / 2 - ((style?.width as number) || 1200) / 2 : 0);
  const [tmp, setTmp] = useState<{ top: number; left: number }>();
  const ghostEle = useRef(null);
  const wrapperRef = useRef<HTMLDivElement>();

  const handleResize = debounce(() => {
    initPosition();
  }, 200);

  const isThis = () => {
    return wrapperRef?.current === el?.childNodes?.[el?.childNodes?.length - 1]?.firstChild;
  };

  const onDragStart = (e: any) => {
    if (isThis()) {
      e.dataTransfer.setDragImage(ghostEle.current, 0, 0);
      setTmp({ top: e.clientY, left: e.clientX });
    }
  };

  const onDragOver = (e: any) => {
    if (isThis()) {
      setTmp({ top: e.clientY, left: e.clientX });
      setTop(top - (tmp?.top - e.clientY));
      setLeft(left - (tmp?.left - e.clientX));
    }
  };

  // 어플용
  const onTouchStart = (e: any) => {
    if (isThis()) {
      const x = e.changedTouches?.[0].pageX;
      const y = e.changedTouches?.[0].pageY;

      setTmp({ top: y, left: x });
    }
  };
  const onTouchEnd = (e: any) => {
    if (isThis()) {
      const x = e.changedTouches?.[0].pageX;
      const y = e.changedTouches?.[0].pageY;
      setTmp({ top: y, left: x });
      setTop(top - (tmp?.top - y));
      setLeft(left - (tmp?.left - x));
    }
  };

  const initPosition = () => {
    setLeft(window.innerWidth / 2 - wrapperRef?.current?.clientWidth / 2);
    setTmp({ top: 10, left: window.innerWidth / 2 - wrapperRef?.current?.clientWidth / 2 });
  };

  useEffect(() => {
    ghostEle.current = new Image();
    ghostEle.current.src = transparent;
  }, []);

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.keyCode === 27) {
        setVisible();
      }
    };
    window?.addEventListener('resize', handleResize);
    window?.addEventListener('keydown', close);
    el?.focus();
    return () => {
      window?.removeEventListener('resize', handleResize);
      window?.removeEventListener('keydown', close);
    };
  }, []);

  const useNewDesignFlag = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  if (visible && children) {
    return createPortal(
      <>
        <OverlayWrapper
          autoFocus
          className="fadeIn-div"
          onClick={() => {
            if (!disableOverlayClick) {
              setVisible();
            }
          }}
        >
          <ModalWrapper
            ref={wrapperRef}
            style={{
              ...style,
              width: style?.width || 1200,
              top,
              left,
            }}
            designType={useNewDesignFlag === 1 ? 'new' : 'old'}
          >
            <ModalHeader
              className="grabbable"
              draggable
              //
              onTouchStart={onTouchStart}
              onTouchMove={onTouchEnd}
              onTouchEnd={onTouchEnd}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
            >
              <div style={{ display: 'flex', flexDirection: subTitle ? 'column' : 'row', width: '100%', paddingRight: 30 }}>
                {title &&
                  (typeof title === 'string' ? (
                    <div className="b" style={{ fontSize: 15 }}>
                      {title}
                    </div>
                  ) : (
                    <>{title}</>
                  ))}
                {subTitle && <div>{subTitle}</div>}
              </div>
              {rightTitle && (
                <div style={{ marginRight: 35, display: 'flex', minWidth: 150, justifyContent: 'flex-end' }}>
                  {typeof rightTitle === 'string' ? (
                    <span className="b" style={{ fontSize: 15 }}>
                      {rightTitle}
                    </span>
                  ) : (
                    <span style={{ display: 'flex', justifyContent: 'flex-end' }}>{rightTitle}</span>
                  )}
                </div>
              )}
              <img
                style={{ position: 'absolute', top: -7, right: -7, width: 20, cursor: 'pointer' }}
                src={closeImg}
                alt="모달닫기"
                onClick={() => {
                  setVisible();
                }}
              />
            </ModalHeader>
            <ModalBody className="modal-body" style={{ padding: '10px 0' }}>
              {children}
            </ModalBody>
            <ModalFooter>{footer}</ModalFooter>
          </ModalWrapper>
        </OverlayWrapper>
      </>,
      el,
      key,
    );
  }
};
export default CommonModalNew;
