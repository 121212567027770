import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './product';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER, RAWDATA } from 'redux/services/menuSlice';

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      //
      'SELLER_SELLER_AUTH',
      'SELLER_SUPPLIER_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      'MODEL_GRP_DIV',
      'MODEL_GROUP',
      `${RAWDATA}MODEL_GRP_1`,
      'MODEL_GRP_1',
      'MODEL_GRP_2',
      'MODEL_GRP_3',
      'INFRA',
      'SERVICE',
      'TEAM_TYPE',
      'BRAND',
      'PRODUCT_MANUAL_TYPE',
      'MODEL_TYPE',
      'INSTALL_DURATION',

      //
      {
        SEARCH_SEQ: [
          { value: 'productSeq', label: '상품번호' },
          { value: 'externalProductNumber', label: '외부상품번호' },
        ],
      },
      {
        SEARCH_MODEL: [
          { value: 'productName', label: '상품명' },
          { value: 'modelName', label: '제품명' },
        ],
      },
      'OX_FLAG',
      'NEED_FLAG',
    ];
    initMasterOptions(masterArr);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PRODUCT_TAB: <Tab1 tabId={'PRODUCT_TAB'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
