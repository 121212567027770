import { v4 as uuidv4 } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { Row, Col, Table, Input, Button } from 'reactstrap';
import Checkbox from 'rc-checkbox';
import CloseImg from 'assets/images/close/cross.png';

import phonePng from './phone.png';

// callendar
import { returnDDay } from 'common/util/counting';
import { REGION_NUMBERS } from 'common/data/regionNumber';
import { returnFeeTypes, returnIsInvoiceStatusFinished } from 'common/util/orderTypes';
import { CloseBtnStyle } from '../presenter';
import { defaultMapObj } from '../container';
import { returnDateyyyymmdd, returnYoil } from 'common/util/dateParsingFn';

// redux
import { setLoading } from 'redux/services/menuSlice';
import { useSelector, useDispatch } from 'react-redux';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';
import { httpClient } from 'common/http-client/axiosConfig';
import { IMS_DRIVER_LIST } from 'envVar';
import { DetailPopup } from 'pages/ETC/1driver/driverListTab/driverList/component/detailPopup';
import { getTimeZone, selectlabel } from 'common/master/codeMasterReturnHelper';
import { throttle } from 'common/util/useDragScroll';
import CommonModalNew from 'components/modal/commonModalNew';

export const CenterTable = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const scrollRef = useRef(null);
  const {
    searchObj,

    scheduleBoardData,
    popupDataObj,

    setMapObj,
    fetchMapData,
    handleMoveSchedule,

    detailScheduleObj,
    setDetailScheduleObj,

    multiItemsPopupObj,
    setMultiItemsPopupObj,
  } = props;

  const [driverDetailObj, setDriverDetailObj] = useState({
    visible: false,
    data: null,
    crudFn: null,
  });
  const [movingScheduleData, setMovingScheduleData] = useState();
  const [activeItem, setActiveItem] = useState();

  const [isDrag, setIsDrag] = useState(false);
  const [startX, setStartX] = useState();

  const [isMoving, setIsMoving] = useState(false);

  const onDragStart = (e) => {
    setStartX(e.pageX + scrollRef.current.scrollLeft);
    if (e.target.className === 'theadTh' && startX && Math.abs(e.pageX + scrollRef.current.scrollLeft - startX) > 30) {
      setIsDrag(true);
    } else if (e.target.className === 'hov') {
      setIsMoving(true);
    }
  };

  const onDragEnd = (e) => {
    e.preventDefault();
    setIsDrag(false);
    setIsMoving(false);
    setMovingScheduleData(null);
  };

  const onDragMove = (e) => {
    e.preventDefault();
    if (isDrag) {
      const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
      scrollRef.current.scrollLeft = startX - e.pageX;
      if (scrollLeft === 0) {
        setStartX(e.pageX);
      } else if (scrollWidth <= clientWidth + scrollLeft) {
        setStartX(e.pageX + scrollLeft);
      }
    }
  };

  const fetchDetail = (data, isMulti, colidx, rowidx) => {
    if (isMulti) {
      setMultiItemsPopupObj({
        ...multiItemsPopupObj,
        originData: { data, isMulti, colidx, rowidx },
        visible: true,
        data: data,
        title: `[${scheduleBoardData?.driverName[rowidx]} 기사님] ${scheduleBoardData.deliveryDate[rowidx]} ${colidx % 2 === 0 ? `${colidx / 2 + 8}:00` : `${parseInt(colidx / 2) + 8}:30`} 배송일정 (${
          data?.length
        }건)`,
      });
    } else {
      detailScheduleObj?.triggerFn(data[0]).then((rs) => {
        if (rs) {
          setDetailScheduleObj({
            ...detailScheduleObj,
            visible: true,
            data: rs,
            originData: { data, isMulti, colidx, rowidx },
          });
        }
      });
    }
  };

  const dragScheduleEnd = (colIdx, rowIdx, movingScheduleData) => {
    if (movingScheduleData) {
      const dragedSchedule = [...movingScheduleData];
      const time = getTimeZone(false)[colIdx].value;
      if (dragedSchedule) {
        const data = [];
        dragedSchedule?.forEach((ele) => {
          data.push({
            customerOrderSeq: ele?.customerOrderSeq,
            customerOrderInvoiceSeq: ele?.customerOrderInvoiceSeq,
            userId: scheduleBoardData.userId[rowIdx],
            deliveryDatetime: `${scheduleBoardData.deliveryDate[rowIdx]} ${time}:00`,
          });
        });
        handleMoveSchedule({
          driverName: scheduleBoardData.driverName[rowIdx],
          data,
          time,
          searchObj,
        });
      }
    }
  };

  const onThrottleDragMove = throttle(onDragMove, 50);

  const COMMON_BORDER_STYLE = '1px solid #CDCFD9';
  const scheduleContentStyle = {
    border: '1px solid #dedede',
    width: '100%',
    height: '80vh',
    marginTop: 20,
    zIndex: 0,
  };

  const COMMON_COL_WIDTH = 110;
  const TimeZoneStyle = {
    borderBottom: '0px',
    height: 60,
    borderRight: COMMON_BORDER_STYLE,
    ...COMMON_COL_WIDTH,
    minWidth: 90,
  };

  const ItemCellStyle = {
    borderBottom: '1px solid white',
    borderRight: '1px solid #e8e8e8',
    cursor: 'grab',
  };

  const ColumnStyle = {
    width: COMMON_COL_WIDTH,
    zIndex: 1,
  };

  const popupDriverDetail = async (userId) => {
    setDriverDetailObj((prev) => {
      return {
        ...prev,
        visible: true,
        item: { userId: userId },
      };
    });
  };

  return (
    <>
      {driverDetailObj?.visible && <DetailPopup detailObj={driverDetailObj} setDetailObj={setDriverDetailObj} />}
      {scheduleBoardData && (
        <div style={scheduleContentStyle} className="table-wrapper hasStickyElement" ref={scrollRef} onMouseDown={onDragStart} onMouseMove={isDrag ? onThrottleDragMove : null} onMouseUp={onDragEnd}>
          <Table bordered className="puttedHeader">
            <thead className="disabledTextDrag">
              <tr>
                <th style={{ borderRight: COMMON_BORDER_STYLE }} className="theadTh stickyColumn puttedColumn">
                  배송경로
                </th>

                {scheduleBoardData?.userId?.map((user, idx) => (
                  <th style={ColumnStyle} className="theadTh" scope="row" key={user + idx}>
                    <div
                      className="click-fake-item"
                      onClick={(e) => {
                        e.preventDefault();
                        setMapObj(defaultMapObj);
                        fetchMapData(scheduleBoardData, idx);
                      }}
                    >
                      보기
                    </div>
                  </th>
                ))}

                <th className="theadTh" scope="row"></th>
              </tr>

              <tr>
                <th
                  style={{
                    width: COMMON_COL_WIDTH,
                    borderRight: COMMON_BORDER_STYLE,
                    verticalAlign: 'middle',
                  }}
                  className="theadTh stickyColumn puttedColumn"
                  scope="row"
                >
                  설치 기사
                </th>
                {scheduleBoardData?.driverName?.map((name, idx) => (
                  <th key={`${name}_${idx}`} style={ColumnStyle} className="theadTh" scope="row">
                    <div
                      className="clickable"
                      title="기사 상세보기"
                      onClick={(e) => {
                        e.preventDefault();
                        popupDriverDetail(scheduleBoardData.userId[idx]);
                        // window.open(`/driverDetail?seq=${scheduleBoardData.userId[idx]}`);
                      }}
                    >
                      {name}
                    </div>
                  </th>
                ))}
                <th className="theadTh" scope="row"></th>
              </tr>

              <tr>
                <th
                  style={{
                    borderBottom: COMMON_BORDER_STYLE,
                    borderRight: COMMON_BORDER_STYLE,
                    width: 70,
                    minWidth: 70,
                    verticalAlign: 'middle',
                  }}
                  className="theadTh stickyColumn puttedColumn"
                  scope="row"
                >
                  확정/미확정
                </th>
                {scheduleBoardData?.confirmCount?.map((name, rowidx) => (
                  <th
                    key={`${name}_${rowidx}`}
                    style={{
                      ...ColumnStyle,
                      borderBottom: COMMON_BORDER_STYLE,
                    }}
                    className="theadTh"
                    scope="row"
                  >
                    <div
                      className="clickable-h4"
                      onClick={(e) => {
                        e.preventDefault();
                        const data = {
                          userId: scheduleBoardData.userId[rowidx],
                          deliveryDatetime: `${scheduleBoardData.deliveryDate[rowidx]}`,
                        };
                        popupDataObj?.triggerFn(data);
                      }}
                    >
                      {name}
                    </div>
                  </th>
                ))}
                <th style={{ borderBottom: COMMON_BORDER_STYLE }} className="theadTh" scope="row"></th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(scheduleBoardData?.timeZone)?.map((time, colidx) => (
                <tr key={`shceduleData.timezone_${colidx}`}>
                  <th className="puttedColumn" style={TimeZoneStyle}>
                    {colidx % 2 === 0 ? `${colidx / 2 + 8} : 00` : `${parseInt(colidx / 2) + 8} : 30`}
                  </th>
                  {scheduleBoardData?.timeZone[time]?.map((dataArr, rowidx) => {
                    if (dataArr && dataArr.length > 0) {
                      return (
                        <td
                          key={`_${colidx}_${rowidx}_1`}
                          className={activeItem?.colidx === colidx && activeItem?.rowidx === rowidx ? 'active-item scheduleitem-td' : 'scheduleitem-td'}
                          draggable
                          onMouseUp={(e) => {
                            e.preventDefault();
                            if (isMoving) {
                              dragScheduleEnd(colidx, rowidx, movingScheduleData);
                            }
                          }}
                          onDragStart={(e) => {
                            e.preventDefault();
                            onDragStart(e);
                            setMovingScheduleData(dataArr);
                          }}
                          style={ItemCellStyle}
                        >
                          <div
                            style={{
                              position: 'relative',
                              width: 110,
                              height: 60,
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              setActiveItem({ colidx, rowidx });
                              fetchDetail(dataArr, dataArr.length > 1 ? true : false, colidx, rowidx);
                            }}
                          >
                            {dataArr.length > 1 && (
                              <>
                                <span className="moreItems-label">{dataArr.length}건</span>
                                <div className="notSingle" key={`dataArr_notsingle_${uuidv4()}`}>
                                  <div className="schedule-item-style multi" style={{ top: -4, right: -4, zIndex: 0 }}></div>
                                </div>
                              </>
                            )}
                            {dataArr[0].revisitFlag && (
                              <span className="moreItems-label revisit" title="재방문">
                                재
                              </span>
                            )}
                            {dataArr[0].promiseDeliveryDate && <span className="moreItems-label dday">{returnDDay(dataArr[0].deliveryDate, dataArr[0].promiseDeliveryDate)}</span>}
                            <ScheduleItem masterOptions={masterOptions} data={dataArr[0]} />
                          </div>
                        </td>
                      );
                    } else {
                      return (
                        <td
                          onMouseUp={(e) => {
                            if (isMoving) {
                              dragScheduleEnd(colidx, rowidx, movingScheduleData);
                            }
                          }}
                          key={`empty-td_${colidx}+${rowidx}_1`}
                          className="empty-td"
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                          draggable
                          style={ItemCellStyle}
                        >
                          <div
                            className="empty-cell"
                            draggable
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <div style={{ width: 100, height: 60 }}></div>
                          </div>
                        </td>
                      );
                    }
                  })}
                  <td style={ItemCellStyle}></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export const DriverTable = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);

  const scrollRef = useRef(null);
  const {
    searchObj,

    scheduleBoardData,
    popupDataObj,

    setMapObj,
    fetchMapData,
    handleMoveSchedule,

    detailScheduleObj,
    setDetailScheduleObj,

    multiItemsPopupObj,
    setMultiItemsPopupObj,
  } = props;
  const [movingScheduleData, setMovingScheduleData] = useState();
  const [activeItem, setActiveItem] = useState();

  const [isDrag, setIsDrag] = useState(false);
  const [startX, setStartX] = useState();

  const [isMoving, setIsMoving] = useState(false);

  const onDragStart = (e) => {
    setStartX(e.pageX + scrollRef.current.scrollLeft);
    if (e.target.className === 'theadTh' && startX && Math.abs(e.pageX + scrollRef.current.scrollLeft - startX) > 30) {
      setIsDrag(true);
    } else if (e.target.className === 'hov') {
      setIsMoving(true);
    }
  };

  const onDragEnd = (e) => {
    e.preventDefault();
    setIsDrag(false);
    setIsMoving(false);
    setMovingScheduleData(null);
  };

  const onDragMove = (e) => {
    e.preventDefault();
    if (isDrag) {
      const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
      scrollRef.current.scrollLeft = startX - e.pageX;
      if (scrollLeft === 0) {
        setStartX(e.pageX);
      } else if (scrollWidth <= clientWidth + scrollLeft) {
        setStartX(e.pageX + scrollLeft);
      }
    }
  };

  const fetchDetail = (data, isMulti, colidx, rowidx) => {
    if (isMulti) {
      setMultiItemsPopupObj({
        ...multiItemsPopupObj,
        originData: { data, isMulti, colidx, rowidx },
        visible: true,
        data: data,
        title: `[${scheduleBoardData?.driverName[rowidx]} 기사님] ${scheduleBoardData.deliveryDate[rowidx]} ${colidx % 2 === 0 ? `${colidx / 2 + 8}:00` : `${parseInt(colidx / 2) + 8}:30`} 배송일정 (${
          data?.length
        }건)`,
      });
    } else {
      detailScheduleObj?.triggerFn(data[0]).then((rs) => {
        if (rs) {
          setDetailScheduleObj({
            ...detailScheduleObj,
            visible: true,
            data: rs,
            originData: { data, isMulti, colidx, rowidx },
          });
        }
      });
    }
  };

  const dragScheduleEnd = (colIdx, rowIdx, movingScheduleData) => {
    if (movingScheduleData) {
      const dragedSchedule = [...movingScheduleData];
      const time = getTimeZone(false)[colIdx].value;
      if (dragedSchedule) {
        const data = [];
        dragedSchedule?.forEach((ele) => {
          data.push({
            customerOrderSeq: ele?.customerOrderSeq,
            customerOrderInvoiceSeq: ele?.customerOrderInvoiceSeq,
            userId: scheduleBoardData.userId[rowIdx],
            deliveryDatetime: `${scheduleBoardData.deliveryDate[rowIdx]} ${time}:00`,
          });
        });
        handleMoveSchedule({
          driverName: scheduleBoardData.driverName[rowIdx],
          data,
          time,
          searchObj,
        });
      }
    }
  };

  const onThrottleDragMove = throttle(onDragMove, 50);

  const COMMON_BORDER_STYLE = '1px solid #CDCFD9';
  const scheduleContentStyle = {
    border: '1px solid #dedede',
    width: '100%',
    height: '80vh',
    marginTop: 20,
    zIndex: 0,
  };

  const COMMON_COL_WIDTH = 110;
  const TimeZoneStyle = {
    borderBottom: '0px',
    height: 60,
    borderRight: COMMON_BORDER_STYLE,
    ...COMMON_COL_WIDTH,
    minWidth: 90,
  };

  const ItemCellStyle = {
    borderBottom: '1px solid white',
    borderRight: '1px solid #e8e8e8',
    cursor: 'grab',
  };

  const ColumnStyle = {
    width: COMMON_COL_WIDTH,
    zIndex: 1,
  };

  return (
    <>
      {scheduleBoardData && (
        <div style={scheduleContentStyle} className="table-wrapper hasStickyElement" ref={scrollRef} onMouseDown={onDragStart} onMouseMove={isDrag ? onThrottleDragMove : null} onMouseUp={onDragEnd}>
          <Table bordered className="puttedHeader">
            <thead className="disabledTextDrag">
              <tr>
                <th style={{ borderRight: COMMON_BORDER_STYLE }} className="theadTh stickyColumn puttedColumn">
                  배송경로
                </th>

                {scheduleBoardData?.userId?.map((user, idx) => (
                  <th style={ColumnStyle} className="theadTh" scope="row" key={user + idx}>
                    <div
                      className="click-fake-item"
                      onClick={(e) => {
                        e.preventDefault();
                        setMapObj(defaultMapObj);
                        fetchMapData(scheduleBoardData, idx);
                      }}
                    >
                      보기
                    </div>
                  </th>
                ))}

                <th className="theadTh" scope="row"></th>
              </tr>

              <tr>
                <th
                  style={{
                    width: COMMON_COL_WIDTH,
                    borderRight: COMMON_BORDER_STYLE,
                    verticalAlign: 'middle',
                  }}
                  className="theadTh stickyColumn puttedColumn"
                  scope="row"
                >
                  날짜
                </th>
                {scheduleBoardData?.deliveryDate?.map((name, idx) => (
                  <th key={`deliveryDate_${idx}`} style={ColumnStyle} className="theadTh" scope="row">
                    <div>{`${name?.split('-')[1]}월 ${name?.split('-')[2]}일 (${returnYoil(name)})`}</div>
                  </th>
                ))}
                <th className="theadTh" scope="row"></th>
              </tr>

              <tr>
                <th
                  style={{
                    borderBottom: COMMON_BORDER_STYLE,
                    borderRight: COMMON_BORDER_STYLE,
                    width: 70,
                    minWidth: 70,
                    verticalAlign: 'middle',
                  }}
                  className="theadTh stickyColumn puttedColumn"
                  scope="row"
                >
                  확정/미확정
                </th>
                {scheduleBoardData?.confirmCount?.map((name, rowidx) => (
                  <th
                    key={`${name}_${rowidx}`}
                    style={{
                      ...ColumnStyle,
                      borderBottom: COMMON_BORDER_STYLE,
                    }}
                    className="theadTh"
                    scope="row"
                  >
                    <div
                      className="clickable-h4"
                      onClick={(e) => {
                        e.preventDefault();
                        const data = {
                          userId: scheduleBoardData.userId[rowidx],
                          deliveryDatetime: `${scheduleBoardData.deliveryDate[rowidx]}`,
                        };
                        popupDataObj?.triggerFn(data);
                      }}
                    >
                      {name}
                    </div>
                  </th>
                ))}
                <th style={{ borderBottom: COMMON_BORDER_STYLE }} className="theadTh" scope="row"></th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(scheduleBoardData?.timeZone)?.map((time, colidx) => (
                <tr key={`shceduleData.timezone_${colidx}`}>
                  <th className="puttedColumn" style={TimeZoneStyle} scope="row">
                    {colidx % 2 === 0 ? `${colidx / 2 + 8} : 00` : `${parseInt(colidx / 2) + 8} : 30`}
                  </th>
                  {scheduleBoardData?.timeZone[time]?.map((dataArr, rowidx) => {
                    if (dataArr && dataArr.length > 0) {
                      return (
                        <td
                          key={`_${colidx}_${rowidx}_2`}
                          className={activeItem?.colidx === colidx && activeItem?.rowidx === rowidx ? 'active-item scheduleitem-td' : 'scheduleitem-td'}
                          draggable
                          onMouseUp={(e) => {
                            e.preventDefault();
                            if (isMoving) {
                              dragScheduleEnd(colidx, rowidx, movingScheduleData);
                            }
                          }}
                          onDragStart={(e) => {
                            e.preventDefault();
                            setMovingScheduleData(dataArr);
                            onDragStart(e);
                          }}
                          style={ItemCellStyle}
                        >
                          <div
                            style={{
                              position: 'relative',
                              width: 110,
                              height: 60,
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              setActiveItem({ colidx, rowidx });
                              fetchDetail(dataArr, dataArr.length > 1 ? true : false, colidx, rowidx);
                            }}
                          >
                            {dataArr.length > 1 && (
                              <>
                                <span className="moreItems-label">{dataArr.length}건</span>
                                <div className="notSingle">
                                  <div className="schedule-item-style multi" style={{ top: -4, right: -4, zIndex: 0 }}></div>
                                </div>
                              </>
                            )}
                            {dataArr[0].revisitFlag && (
                              <span className="moreItems-label revisit" title="재방문">
                                재
                              </span>
                            )}
                            {dataArr[0].promiseDeliveryDate && <span className="moreItems-label dday">{returnDDay(dataArr[0].deliveryDate, dataArr[0].promiseDeliveryDate)}</span>}
                            <ScheduleItem masterOptions={masterOptions} data={dataArr[0]} />
                          </div>
                        </td>
                      );
                    } else {
                      return (
                        <td
                          key={`empty-td_${colidx}+${rowidx}_2`}
                          onMouseUp={(e) => {
                            if (isMoving) {
                              dragScheduleEnd(colidx, rowidx, movingScheduleData);
                            }
                          }}
                          className="empty-td"
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                          draggable
                          style={ItemCellStyle}
                        >
                          <div
                            className="empty-cell"
                            draggable
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <div style={{ width: 100, height: 60 }} />
                          </div>
                        </td>
                      );
                    }
                  })}
                  <td style={ItemCellStyle}></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export const DetailScheduleTable = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const { detailScheduleObj, setDetailScheduleObj, searchObj, multiItemsPopupObj, originData } = props;

  const [detailData, setDetailData] = useState();
  const [timeZone, setTimeZone] = useState(getTimeZone(false));

  useEffect(() => {
    setDetailData(detailScheduleObj?.data);
  }, []);

  useEffect(() => {
    if (detailData) {
      const [deliveryDate, deliveryTime] = detailData?.deliveryDatetime?.split(' ');
      const [h, m, s] = deliveryTime.split(':');
      setChangeModal({
        ...changeModal,
        deliveryDate: deliveryDate,
        deliveryTime: h + ':' + m,
        centerCode: detailData?.centerCode,
        userId: detailScheduleObj?.originData?.data?.[0].userId,
      });
    }
  }, [detailData]);

  const [changeModal, setChangeModal] = useState({
    visible: false,
    centerCode: null,
    userId: null,
    deliveryDate: null,
    deliveryTime: null,
  });
  useEffect(() => {
    if (changeModal?.centerCode) fetchDriver(changeModal?.centerCode);
  }, [changeModal?.centerCode]);

  const [filteredOptions, setFilteredOptions] = useState([]);
  const fetchDriver = async (centerCode) => {
    if (centerCode) {
      await httpClient.get(`${IMS_DRIVER_LIST}?centerCode=${centerCode}`).then((rs) => {
        const result = rs?.status === 200 ? rs.data : [];
        const filtered = result.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        });

        setFilteredOptions({
          ...filteredOptions,
          DRIVER_NAME: filtered,
        });
      });
    }
  };

  return (
    <>
      {changeModal?.visible && (
        <CommonModalNew
          style={{ width: 800 }}
          title={'스케줄 변경'}
          rightTitle={
            <div>
              <Button
                className="btn btn-orange ssm"
                onClick={() => {
                  if (!changeModal?.centerCode) {
                    alert('창고를 선택하세요!');
                  } else if (!changeModal?.userId) {
                    alert('기사를 선택하세요!');
                  } else if (!changeModal?.deliveryDate) {
                    alert('배송일을 선택하세요!');
                  } else if (!changeModal?.deliveryTime) {
                    alert('배송 시간를 선택하세요!');
                  } else {
                    const result = window.confirm('스케줄을 변경하시겠습니까?');
                    if (result) {
                      const data = {
                        driverName: selectlabel(changeModal, masterOptions?.DRIVER_WHOLE),
                        data: [
                          {
                            customerOrderSeq: detailData?.customerOrderSeq,
                            customerOrderInvoiceSeq: detailData?.customerOrderInvoiceSeq,
                            userId: changeModal?.userId,
                            deliveryDatetime: changeModal?.deliveryDate + ' ' + changeModal?.deliveryTime + ':00',
                          },
                        ],
                        time: changeModal?.deliveryTime,
                        searchObj: searchObj,
                      };
                      if (detailScheduleObj?.changeScheduleFn) {
                        detailScheduleObj?.changeScheduleFn(data);
                      } else {
                        multiItemsPopupObj?.changeScheduleFn(data);
                      }
                    }
                  }
                }}
              >
                변경
              </Button>
            </div>
          }
          children={
            <div>
              <Row>
                <Col>
                  <label className="col-form-label">창고*</label>
                  <SelectD
                    options={masterOptions?.['CENTER'].filter((ele) => ele.value)}
                    value={
                      changeModal.centerCode
                        ? {
                            value: changeModal.centerCode,
                            label: selectlabel(changeModal?.centerCode, masterOptions?.CENTER_WHOLE),
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setChangeModal({
                        ...changeModal,
                        centerCode: value,
                        userId: null,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">기사명*</label>
                  <SelectD
                    options={filteredOptions['DRIVER_NAME']}
                    value={
                      changeModal.userId
                        ? {
                            value: changeModal.userId,
                            label: selectlabel(changeModal?.userId, masterOptions?.DRIVER_WHOLE),
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setChangeModal({
                        ...changeModal,
                        userId: value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">배송날짜*</label>
                  <FlatpickrD
                    value={changeModal.deliveryDate}
                    onChange={(option) => {
                      setChangeModal({
                        ...changeModal,
                        deliveryDate: returnDateyyyymmdd(option),
                      });
                    }}
                    options={{
                      mode: 'single',
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">배송시간*</label>
                  <SelectD
                    options={timeZone || []}
                    value={{
                      value: changeModal?.deliveryTime,
                      label: changeModal?.deliveryTime,
                    }}
                    onChange={({ value }) => {
                      setChangeModal({
                        ...changeModal,
                        deliveryTime: value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
          }
        />
      )}
      {!multiItemsPopupObj?.visible && (
        <img
          src={CloseImg}
          alt=""
          onClick={() =>
            setDetailScheduleObj({
              ...detailScheduleObj,
              data: null,
              visible: false,
            })
          }
          style={CloseBtnStyle}
        />
      )}
      <div
        className="presentinputTitle"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: !multiItemsPopupObj?.visible ? 50 : 0,
        }}
      >
        <span>접수정보</span>
        <span>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setChangeModal({
                ...changeModal,
                visible: true,
              });
            }}
          >
            개별 스케줄 변경
          </button>
          <button className="btn btn-secondary" onClick={() => detailScheduleObj?.messageFn(detailData)}>
            개별 알림메시지 발송
          </button>
          {!detailData?.confirmFlag && (
            <button
              className="btn btn-search"
              onClick={() => {
                dispatch(setLoading('PUT'));
                detailScheduleObj?.confirmOne({
                  data: detailData,
                  searchObj,
                  originData,
                });
              }}
            >
              개별 스케줄확정
            </button>
          )}
        </span>
      </div>

      <Table bordered>
        <tbody>
          <tr>
            <th style={{ width: 150 }}>주문번호 / 외부주문번호</th>
            <td>
              {detailData?.customerOrderSeq || '-'} / {detailData?.externalOrderNumber || '-'}
            </td>
          </tr>
          <tr>
            <th>송장번호 / 외부송장번호</th>
            <td>
              {detailData?.customerOrderInvoiceSeq || '-'} / {detailData?.externalInvoiceNumber || '-'}
            </td>
          </tr>
          <tr>
            <th>송장유형 / 송장상태</th>
            <td>
              {selectlabel(detailData?.feeType, masterOptions?.FEE_TYPE) || '-'} / {selectlabel(detailData?.invoiceStatus, masterOptions?.INVOICE_STATUS) || '-'}
            </td>
          </tr>
          <tr>
            <th>상품명 / 제품</th>
            <td>
              {detailData?.productName || '-'} /{' '}
              {detailData?.productModels?.map((p, idx) => (
                <div key={`productModel_${idx}`}>{p.model}</div>
              ))}
            </td>
          </tr>
          <tr>
            <th>인프라 / 판매사</th>
            <td>
              {selectlabel(detailData?.infraSeq, masterOptions?.INFRA) || '-'} / {selectlabel(detailData?.partnerSeq, masterOptions?.SELLER_WHOLE) || '-'}
            </td>
          </tr>
          <tr>
            <th>주문유형 / 고객 요청사항</th>
            <td>
              <div>{selectlabel(detailData?.orderType, masterOptions?.ORDER_TYPE) || '-'}</div>
              <div>{detailData?.shipmentNote || '-'}</div>
            </td>
          </tr>
          <tr>
            <th>주문일 / 희망일</th>
            <td>
              {detailData?.orderDate || '-'} / {detailData?.promiseDeliveryDate || '-'}
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="presentinputTitle" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>상담내용</span>
        <span></span>
      </div>
      <Table bordered>
        <tbody>
          <tr>
            <th style={{ width: 150 }}>수령 고객</th>
            <td>{detailData?.receiverName || '-'}</td>
          </tr>
          <tr>
            <th>수령인 주소</th>
            <td>
              {detailData?.receiverAddr1 || '-'} {detailData?.receiverAddr2 || ''}
            </td>
          </tr>
          <tr>
            <th>수령인 연락처1</th>
            <td>{detailData?.receiverPhone || '-'}</td>
          </tr>
          <tr>
            <th>콜센터 요청사항</th>
            <td style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                value={detailData?.callCenterNote || '-'}
                onChange={(e) => {
                  setDetailData({
                    ...detailData,
                    callCenterNote: e.target.value,
                  });
                }}
              />
              <button
                className="btn btn-etc sssm"
                style={{ margin: 0 }}
                disabled={detailData?.callCenterNote === detailScheduleObj?.data?.callCenterNote}
                onClick={() => {
                  detailScheduleObj?.changeNoteFn({
                    note: detailData,
                    searchObj,
                  });
                }}
              >
                수정
              </button>
            </td>
          </tr>
          <tr>
            <th>기사 요청사항</th>
            <td>{detailScheduleObj?.data?.driverMemo || '-'}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export const ScheduleItem = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { isMulti, onClick, data, onMouseDown = () => {} } = props;
  const [hov, setHov] = useState(false);

  const SCHEDULE_COLOR = Object.freeze({
    DELIVERY: {
      ACTIVE_BG: '#3EA1FF',
      NONE_ACTIVE_BG: '#E4F2FF',
    },
    VISIT: {
      ACTIVE_BG: '#FFC56F',
      NONE_ACTIVE_BG: '#FFF0D4',
    },
    COLLECT: {
      ACTIVE_BG: '#FFA09C',
      NONE_ACTIVE_BG: '#ffdedd',
    },
    EXCHANGE: {
      ACTIVE_BG: '#61CACA',
      NONE_ACTIVE_BG: '#CEF3F3',
    },
    MOVE: {
      ACTIVE_BG: '#CA87FF',
      NONE_ACTIVE_BG: '#EDD7FF',
    },
    DP: {
      ACTIVE_BG: '#DDA97A',
      NONE_ACTIVE_BG: '#FFD8B5',
    },
    ASNREPAIR: {
      ACTIVE_BG: '#21AD76',
      NONE_ACTIVE_BG: '#BBDDD0',
    },
    DONE: {
      ACTIVE_BG: 'rgb(206 206 206)',
      NONE_ACTIVE_BG: 'rgb(206 206 206)',
    },
  });

  let border = '1px solid lightgrey';
  let backgroundColor = 'white';
  let color = 'gray';
  if (returnFeeTypes(data.feeType.trim()) === 'delivery') {
    if (data.confirmFlag) {
      backgroundColor = SCHEDULE_COLOR.DELIVERY.ACTIVE_BG;
      color = 'white';
    } else {
      backgroundColor = SCHEDULE_COLOR.DELIVERY.NONE_ACTIVE_BG;
      color = SCHEDULE_COLOR.DELIVERY.ACTIVE_BG;
    }
    border = 'none';
  }

  if (returnFeeTypes(data.feeType.trim()) === 'visit') {
    if (data.confirmFlag) {
      backgroundColor = SCHEDULE_COLOR.VISIT.ACTIVE_BG;
      color = 'white';
    } else {
      backgroundColor = SCHEDULE_COLOR.VISIT.NONE_ACTIVE_BG;
      color = SCHEDULE_COLOR.VISIT.ACTIVE_BG;
    }
    border = 'none';
  }

  if (returnFeeTypes(data.feeType.trim()) === 'collect') {
    if (data.confirmFlag) {
      backgroundColor = SCHEDULE_COLOR.COLLECT.ACTIVE_BG;
      color = 'white';
    } else {
      backgroundColor = SCHEDULE_COLOR.COLLECT.NONE_ACTIVE_BG;
      color = SCHEDULE_COLOR.COLLECT.ACTIVE_BG;
    }
    border = 'none';
  }

  if (returnFeeTypes(data.feeType.trim()) === 'exchange') {
    if (data.confirmFlag) {
      backgroundColor = SCHEDULE_COLOR.EXCHANGE.ACTIVE_BG;
      color = 'white';
    } else {
      backgroundColor = SCHEDULE_COLOR.EXCHANGE.NONE_ACTIVE_BG;
      color = SCHEDULE_COLOR.EXCHANGE.ACTIVE_BG;
    }
    border = 'none';
  }

  if (returnFeeTypes(data.feeType.trim()) === 'move') {
    if (data.confirmFlag) {
      backgroundColor = SCHEDULE_COLOR.MOVE.ACTIVE_BG;
      color = 'white';
    } else {
      backgroundColor = SCHEDULE_COLOR.MOVE.NONE_ACTIVE_BG;
      color = SCHEDULE_COLOR.MOVE.ACTIVE_BG;
    }
    border = 'none';
  }

  if (returnFeeTypes(data.feeType.trim()) === 'store') {
    if (data.confirmFlag) {
      backgroundColor = SCHEDULE_COLOR.DP.ACTIVE_BG;
      color = 'white';
    } else {
      backgroundColor = SCHEDULE_COLOR.DP.NONE_ACTIVE_BG;
      color = SCHEDULE_COLOR.DP.ACTIVE_BG;
    }
    border = 'none';
  }

  if (returnFeeTypes(data.feeType.trim()) === 'as' || returnFeeTypes(data.feeType.trim()) === 'fix') {
    if (data.confirmFlag) {
      backgroundColor = SCHEDULE_COLOR.ASNREPAIR.ACTIVE_BG;
      color = 'white';
    } else {
      backgroundColor = SCHEDULE_COLOR.ASNREPAIR.NONE_ACTIVE_BG;
      color = SCHEDULE_COLOR.ASNREPAIR.ACTIVE_BG;
    }
    border = 'none';
  }
  if (returnIsInvoiceStatusFinished(data.invoiceStatus.trim())) {
    backgroundColor = SCHEDULE_COLOR.DONE.ACTIVE_BG;
    color = 'white';
  }

  const ScheduleItemStyle = {
    width: 110,
    height: 65,
    backgroundColor,
    color,
    border,
    cursor: 'pointer',
    borderRadius: 4,
    fontWeight: '500',
    textAlign: 'center',
    paddingTop: 8,
    zIndex: 0,
    fontSize: 11,
    position: 'absolute',
  };

  const hovChildStyle = {
    position: 'absolute',
    fontSize: 12,
    color: '#f6a612',
    backgroundColor: 'black',
    left: -50,
    top: -30,
    zIndex: 999,
    minWidth: 100,
    padding: '3px 8px',
    maxHeight: 40,
    width: '22em',
    display: 'none',
    boxShadow: '10px 10px 10px #0000001c',
  };
  const [dataHasPhone, setDataHasPhone] = useState(false);
  useEffect(() => {
    const front3 = data?.receiverPhone?.substr(0, 3);
    let flag = false;
    if (front3) {
      REGION_NUMBERS?.forEach((ele) => {
        if (front3.includes(ele)) {
          flag = true;
        }
      });
    }
    if (flag) setDataHasPhone(true);
  }, []);

  return (
    <>
      <div className="hov-child ellipsis" style={hovChildStyle}>
        {data?.productName} {isMulti ? '+α' : ''}
      </div>
      <div
        className={hov ? 'hov' : ''}
        onMouseEnter={() => {
          setHov(true);
        }}
        onMouseLeave={() => setHov(false)}
        onMouseDown={onMouseDown}
        onDragOver={(e) => {
          e.preventDefault();
        }} // it makes div draggable
        draggable={true} // with this
        style={ScheduleItemStyle}
        onClick={onClick}
      >
        {dataHasPhone && <img src={phonePng} alt="수령자의 등록된 연락처가 지역번호입니다" style={{ position: 'absolute', width: 15, top: -5, left: -3 }} />}
        <span
          className="ellipsis"
          style={{
            display: 'block',
            padding: '0 2px',
          }}
        >
          {data?.receiverName}
        </span>
        {data.city}
        <div>({selectlabel(data.modelGroup, masterOptions?.MODEL_GROUP)})</div>
      </div>
    </>
  );
};

export const MultiItemsPopup = ({ multiItemsPopupObj, setMultiItemsPopupObj, searchObj }) => {
  const { masterOptions } = useSelector((state) => state.menu);

  const [selectedIdx, setSeletcedIdx] = useState(0);
  const [checkedItems, setCheckedItems] = useState([]);
  const [detailScheduleObj, setDetailScheduleObj] = useState();

  useEffect(() => {
    setCheckedItems(new Array(multiItemsPopupObj?.data?.length).fill(false));
  }, []);

  useEffect(() => {
    if (selectedIdx > -1) {
      multiItemsPopupObj?.triggerFn(multiItemsPopupObj?.data[selectedIdx]).then((rs) => {
        setDetailScheduleObj({
          ...multiItemsPopupObj,
          data: rs,
        });
      });
    }
  }, [selectedIdx, multiItemsPopupObj?.data]);

  return (
    <CommonModalNew
      style={{ width: 700 }}
      visible={multiItemsPopupObj?.visible && multiItemsPopupObj?.data !== undefined}
      setVisible={() => {
        setMultiItemsPopupObj({
          ...multiItemsPopupObj,
          visible: false,
          data: null,
        });
      }}
      title={multiItemsPopupObj?.title}
      children={
        <>
          <Row>
            <Col md={3}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  className="btn btn-search ssm"
                  style={{ paddingLeft: 100 }}
                  disabled={checkedItems.filter((ele) => ele === true)?.length === 0}
                  onClick={() => {
                    const items = [];
                    checkedItems?.forEach((ele, idx) => {
                      if (ele) items.push(multiItemsPopupObj?.data[idx]);
                    });
                    multiItemsPopupObj?.confirmFn({ items, searchObj });
                  }}
                >
                  일괄 스케줄확정
                </button>
              </div>
              {multiItemsPopupObj?.data?.map((ele, i) => (
                <div key={`dataArr_inModal_${i}`} style={{ position: 'relative' }}>
                  {selectedIdx === i && <span className="selected-item">선택</span>}
                  <Checkbox
                    disabled={ele.confirmFlag}
                    style={{
                      position: 'absolute',
                      top: '40%',
                      left: -10,
                      zIndex: 10,
                    }}
                    className={`checkbox_${i}`}
                    checked={checkedItems[i]}
                    onClick={(e) => {
                      const _copied = [...checkedItems];
                      _copied[i] = !_copied[i];
                      setCheckedItems(_copied);
                    }}
                  />
                  <Row
                    style={{
                      position: 'relative',
                      display: 'flex',
                      height: 80,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {ele.revisitFlag && (
                      <span className="moreItems-label revisit" title="재방문">
                        재
                      </span>
                    )}
                    {ele.promiseDeliveryDate && <span className="moreItems-label dday">{returnDDay(ele.deliveryDate, ele.promiseDeliveryDate)}</span>}
                    <ScheduleItem
                      masterOptions={masterOptions}
                      data={ele}
                      onClick={(e) => {
                        e.preventDefault();
                        setSeletcedIdx(i);
                      }}
                    />
                  </Row>
                </div>
              ))}
            </Col>
            <Col md={9} key={`detailItem_${detailScheduleObj?.data?.customerOrderInvoiceSeq}_${detailScheduleObj?.data?.invoiceStatus}`}>
              <DetailScheduleTable
                detailScheduleObj={detailScheduleObj}
                multiItemsPopupObj={multiItemsPopupObj}
                setMultiItemsPopupObj={setMultiItemsPopupObj}
                searchObj={searchObj}
                originData={multiItemsPopupObj?.originData}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};
