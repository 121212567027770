import { LuAlertTriangle } from 'react-icons/lu';
import LDSButton from '../atoms/Button';
import { LDSSubTitle } from '../atoms/Typography';
import { COLORS } from 'common/constants/appearance';
import styled from 'styled-components';
import { LDSSpacing } from '../atoms/Spacing';

export default function LDSError({ $description, $buttonLabel, $link }: { $description?: string; $buttonLabel?: string; $link?: string }) {
  const handleButtonClick = () => {
    localStorage.clear();
    window.location.href = $link ?? '/';
  };

  return (
    <ErrorWrapper>
      <LuAlertTriangle size={64} />
      <LDSSubTitle $color={COLORS.GRAY_06}>
        {$description ? (
          $description?.split('\n').map((row, index) => <div key={`${row}_${index}`}>{row}</div>)
        ) : (
          <>
            오류가 발생했습니다.
            <br />
            잠시 후 다시 시도해주세요.
          </>
        )}
      </LDSSubTitle>
      <LDSSpacing $direction="V" $value={8} />
      <LDSButton
        $type={$link ? 'LINK' : 'BUTTON'}
        $size={'LG'}
        $primary={false}
        $usage={'BLUE'}
        $style={'FILL'}
        $label={$buttonLabel ?? '메인으로'}
        $linkTo={$link || ''}
        onClick={$link ? () => {} : handleButtonClick}
      />
    </ErrorWrapper>
  );
}

export const ErrorWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  text-align: center;
  gap: 8px;
  align-items: center;
  & > svg {
    color: ${COLORS.ALERT_DEEP_MORE};
  }
`;
