import { useEffect, useMemo, useState } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useLocation } from 'react-router-dom';
import { setDocumentTitle } from 'common/util/html';
import { setNowTab } from 'redux/services/tabSlice';
import { TabDTO } from 'interface/user';

export interface ITopTabProps {
  containers: {
    [tabId: string]: JSX.Element;
  };
}
export interface INowTab {
  tabName: string;
  tabId: string;
  tabNum: number;
}

export interface ITabItems {
  titles: string[];
  tabIds: string[];
}

export const dontSaveThisPath = [
  //
  // '/cargoManifestTask',
];

export const TopTabController = ({ containers }: ITopTabProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { useableScreens, useableTabs } = useSelector((state: RootState) => state.auth);
  const { nowTab } = useSelector((state: RootState) => state.tab);
  const [tabItems, setTabItems] = useState<ITabItems>();

  useEffect(() => {
    if (useableTabs) {
      const localTabInfo = localStorage.getItem(location.pathname) ? JSON.parse(localStorage.getItem(location.pathname)) : { tabNum: 0 };
      const tabNum = Number(localTabInfo?.tabNum || 0);
      const tabDTO: TabDTO[] = useableTabs?.[location.pathname] ? [...useableTabs?.[location.pathname]] : [];
      if (tabDTO?.length > 0) {
        tabDTO?.sort((a: TabDTO, b: TabDTO) => a?.sort - b?.sort);
        const nowTab: INowTab = {
          tabNum,
          tabName: tabDTO?.[tabNum]?.tabName,
          tabId: tabDTO?.[tabNum]?.tabId,
        };
        moveTabs(nowTab);

        // rendering
        setTabItems({
          titles: tabDTO?.map((ele) => ele.tabName),
          tabIds: tabDTO?.map((ele) => ele.tabId),
        });
      }
    }
  }, []);

  const saveNowTab = (nowTab: INowTab) => {
    dispatch(setNowTab(nowTab));
  };

  const moveTabs = (nowTab: INowTab) => {
    const { tabName } = nowTab;
    setDocumentTitle(tabName);
    if (!dontSaveThisPath?.includes(location.pathname)) localStorage.setItem(location.pathname, JSON.stringify(nowTab));
    saveNowTab(nowTab);
  };

  const containerKeys = useMemo(() => {
    return Object?.keys(containers);
  }, [containers]);

  return (
    <>
      {useableScreens && Object.keys(useableScreens)?.includes(location.pathname) ? (
        <>
          {tabItems?.tabIds?.length === 0 ? (
            <div style={{ padding: 10, position: 'absolute', top: 0, left: 0 }}>해당 페이지에 접근 가능한 탭화면이 없습니다</div>
          ) : (
            <>
              <div className={`div-top-tab`}>
                {tabItems?.titles?.map((title: string, index: number) => {
                  if (containers?.[tabItems?.tabIds[index]])
                    return (
                      <div
                        key={`toptap_${index}`}
                        className={nowTab?.tabNum === index ? 'bordering-current' : 'bordering'}
                        onClick={() => {
                          moveTabs({
                            tabNum: index,
                            tabName: tabItems?.titles?.[index],
                            tabId: tabItems?.tabIds?.[index],
                          });
                        }}
                      >
                        <li>{title}</li>
                      </div>
                    );
                })}
              </div>
              <div className="container-wrapper">
                {tabItems &&
                  containerKeys?.map((key, i) => {
                    return (
                      <div key={`${key}_${i}`} className={nowTab?.tabId === tabItems?.tabIds.find((id) => id === key) ? 'active' : ''}>
                        {containers[key]}
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div style={{ padding: 10 }}>
            해당 페이지에 접근 권한이 없습니다
            <br />
            관리자에게 문의해주세요
          </div>
        </>
      )}
    </>
  );
};
