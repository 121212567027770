import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';

import {
  GET_AUTHORITY,
  GRID,
  GRID_PARAM,
  INFRA_CREATE,
  INFRA_DELETE,
  INFRA_DETAIL,
  INFRA_MAIN_SAVE,
  INFRA_PAGING,
  INFRA_SUB_LIST,
  INFRA_SUB_SAVE,
  USERGROUP_AUTHORITY,
  USER_DETAIL,
  USER_FUNCTION,
  USER_FUNCTION_SORT,
  USER_LIST_EXCEL,
  USER_MENU,
  USER_MENU_SORT,
  USER_PARTNER_DETAIL,
  USER_PARTNER_EXCEL,
  USER_PARTNER_PAGING,
  USER_PASSWORD_RESET,
  USER_SAVE,
  USER_SCREEN,
  USER_TAB,
  USER_TAB_SORT,
  USER_USER_LIST_PAGING,
} from 'envVar';
import { PagingListDTO } from 'interface/util';
import {
  FunctionDTO,
  FunctionSearchDTO,
  FunctionSortDTO,
  GridDTO,
  GridSearchDTO,
  MenuDTO,
  MenuSearchDTO,
  MenuSortDTO,
  PartnerDTO,
  PartnerSearchDTO,
  ResponseDTO,
  ScreenDTO,
  ScreenSearchDTO,
  TabDTO,
  TabSearchDTO,
  TabSortDTO,
  UserDTOForAdmin,
  UserGroupAuthorityDTO,
  UserGroupDTO,
  UserGroupUserDTO,
  UserListDTO,
  UserSearchDTO,
} from 'interface/user';
import { InfraCreateDTO, InfraDTO, InfraDTOForMain, InfraDTOForSub, InfraDetailSearchDTO, InfraSearchDTO, InfraSeqDTO } from 'interface/warehouse';

class UserService {
  // 비밀번호 초기화
  public async postResetPasswordForAdmin(id: string): Promise<ResponseDTO> {
    let url = USER_PASSWORD_RESET;
    const rs = await httpClient.post(url, {
      userIds: [id],
      resetPassword: '123456789a',
    });
    return rs;
  }

  public async deleteUserGroup(data: string[]): Promise<ResponseDTO> {
    let url = USERGROUP_AUTHORITY;
    const rs = await httpClient.delete(url, { data });
    return rs;
  }

  public async postUserGroup(dataDTO: UserGroupAuthorityDTO, action?: string): Promise<ResponseDTO> {
    let url = USERGROUP_AUTHORITY;
    if (action) url += action;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async putUserGroup(dataDTO: UserGroupDTO): Promise<ResponseDTO> {
    let url = USERGROUP_AUTHORITY;
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }
  public async getUserGroupPaging(searchDTO: UserSearchDTO): Promise<PagingListDTO<UserGroupDTO>> {
    let url = USERGROUP_AUTHORITY + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getUserInGroups(userGroupId: string): Promise<UserGroupUserDTO[]> {
    let url = `${GET_AUTHORITY}/userGroupUser/${userGroupId}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getUserGroupMenu(userGroupId: string): Promise<UserGroupAuthorityDTO> {
    let url = `${GET_AUTHORITY}/userGroup/${userGroupId}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getUserMenu(userGroupId: string, userId: string): Promise<UserGroupAuthorityDTO> {
    let url = `${GET_AUTHORITY}/userGroup/${userGroupId}/${userId}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  // partnerList
  public async getPartnerListPaging(searchDTO: PartnerSearchDTO): Promise<PagingListDTO<PartnerDTO>> {
    let url = USER_PARTNER_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getPartnerMainExcel(searchDTO: PartnerSearchDTO): Promise<PartnerDTO[]> {
    let url = USER_PARTNER_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getPartnerDetail(partnerSeq: string): Promise<PartnerDTO> {
    let url = `${USER_PARTNER_DETAIL}/${partnerSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  //
  public async savePartnerDetail(dataDTO: PartnerDTO): Promise<ResponseDTO> {
    let url = `${USER_PARTNER_DETAIL}`;
    const { data } = await httpClient.post(url, dataDTO);
    return data;
  }

  // user
  public async getUserListPaging(searchDTO: UserSearchDTO): Promise<PagingListDTO<UserListDTO>> {
    let url = USER_USER_LIST_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getUserDetail(userId: string): Promise<UserDTOForAdmin> {
    let url = `${USER_DETAIL}/${userId}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postUser(data: UserDTOForAdmin): Promise<ResponseDTO> {
    let url = `${USER_SAVE}`;
    const rs = await httpClient.post(url, data);
    return rs;
  }

  public async putUser(data: UserDTOForAdmin): Promise<ResponseDTO> {
    let url = `${USER_SAVE}`;
    const rs = await httpClient.put(url, data);
    return rs;
  }

  public async getUserExcelMain(searchDTO: UserSearchDTO): Promise<UserListDTO[]> {
    let url = USER_LIST_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  // 스크린
  public async getScreenListPaging(searchDTO: ScreenSearchDTO): Promise<PagingListDTO<ScreenDTO>> {
    let url = USER_SCREEN + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async addScreen(dataDTO: ScreenDTO): Promise<ResponseDTO> {
    let url = USER_SCREEN;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async modiScreen(dataDTO: ScreenDTO): Promise<ResponseDTO> {
    let url = USER_SCREEN;
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }

  public async deleteScreen(data: string[]): Promise<ResponseDTO> {
    let url = USER_SCREEN;
    const rs = await httpClient.delete(url, { data });
    return rs;
  }

  // 탭
  public async getTabListPaging(searchDTO: TabSearchDTO): Promise<PagingListDTO<TabDTO>> {
    let url = USER_TAB + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async addTab(dataDTO: TabDTO): Promise<ResponseDTO> {
    let url = USER_TAB;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async modiTab(dataDTO: TabDTO): Promise<ResponseDTO> {
    let url = USER_TAB;
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }

  public async deleteTab(data: string[]): Promise<ResponseDTO> {
    let url = USER_TAB;
    const rs = await httpClient.delete(url, { data });
    return rs;
  }

  public async saveTabSort(dataDTO: TabSortDTO[]): Promise<ResponseDTO> {
    let url = USER_TAB_SORT;
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }
  // 기능
  public async getFunctionListPaging(searchDTO: FunctionSearchDTO): Promise<PagingListDTO<FunctionDTO>> {
    let url = USER_FUNCTION + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async addFunction(dataDTO: FunctionDTO): Promise<ResponseDTO> {
    let url = USER_FUNCTION;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async modiFunction(dataDTO: FunctionDTO): Promise<ResponseDTO> {
    let url = USER_FUNCTION;
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }

  public async deleteFunction(data: string[]): Promise<ResponseDTO> {
    let url = USER_FUNCTION;
    const rs = await httpClient.delete(url, { data });
    return rs;
  }

  public async saveFunctionSort(dataDTO: FunctionSortDTO[]): Promise<ResponseDTO> {
    let url = USER_FUNCTION_SORT;
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }

  // menu
  public async getMenuListPaging(searchDTO: MenuSearchDTO): Promise<PagingListDTO<MenuDTO>> {
    let url = USER_MENU + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async addMenu(dataDTO: MenuDTO): Promise<ResponseDTO> {
    let url = USER_MENU;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async modiMenu(dataDTO: MenuDTO): Promise<ResponseDTO> {
    let url = USER_MENU;
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }

  public async deleteMenu(data: string[]): Promise<ResponseDTO> {
    let url = USER_MENU;
    const rs = await httpClient.delete(url, { data });
    return rs;
  }

  public async saveMenuSort(dataDTO: MenuSortDTO[]): Promise<ResponseDTO> {
    let url = USER_MENU_SORT;
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }
  // 그리드
  public async getGridListPaging(searchDTO: GridSearchDTO): Promise<PagingListDTO<GridDTO>> {
    let url = GRID + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async deleteGrid(data: string[]): Promise<ResponseDTO> {
    let url = GRID;
    const rs = await httpClient.delete(url, { data });
    return rs;
  }

  public async getGridDetail(gridId: string): Promise<GridDTO> {
    let url = GRID_PARAM + `?gridId=${gridId}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async modiGrid(dataDTO: GridDTO): Promise<ResponseDTO> {
    let url = GRID_PARAM;
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }

  public async createGrid(dataDTO: GridDTO): Promise<ResponseDTO> {
    let url = GRID_PARAM;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  // 인프라
  public async getInfraListPaging(searchDTO: InfraSearchDTO): Promise<PagingListDTO<InfraDTO>> {
    let url = INFRA_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getInfraDetail(infraSeq: number): Promise<InfraDTOForMain> {
    let url = INFRA_DETAIL + `?infraSeq=${infraSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getInfraSubDetail(infraSeq: number): Promise<InfraDTOForMain> {
    let url = INFRA_DETAIL + `?infraSeq=${infraSeq}`;
    const { data } = await httpClient.get(url);
    return data;
  }

  public async createInfra(dataDTO: InfraCreateDTO): Promise<ResponseDTO> {
    let url = INFRA_CREATE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async deleteInfra(dataDTO: InfraSeqDTO): Promise<ResponseDTO> {
    let url = INFRA_DELETE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async saveInfra(dataDTO: InfraDTOForMain): Promise<ResponseDTO> {
    let url = INFRA_MAIN_SAVE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async getSubInfra(searchDTO: InfraDetailSearchDTO): Promise<InfraDTOForSub> {
    let url = INFRA_SUB_LIST + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async saveSubInfra(dataDTO: InfraDTOForSub): Promise<ResponseDTO> {
    let url = INFRA_SUB_SAVE;
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
}

export const userService = new UserService();
