import { FormEvent, useEffect, useMemo, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { resetAllSelections } from 'common/util/searchBox';

export const SearchBox = (props) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList, TARGET } = props;

  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE_TAKEOUT: null,
    SEARCH_SEQ_TAKEOUT: null,
    SEARCH_CENTER_TAKEOUT: null,
    SEARCH_NAME: null,
  });

  const defaultSelectTypeObj = useMemo(() => {
    return {
      SEARCH_DATE_TAKEOUT: searchObj?.['SEARCH_DATE_TAKEOUT'] ?? null,
      SEARCH_SEQ_TAKEOUT: searchObj?.['SEARCH_SEQ_TAKEOUT'] ?? null,
      SEARCH_CENTER_TAKEOUT: searchObj?.['SEARCH_CENTER_TAKEOUT'] ?? null,
      SEARCH_NAME: searchObj?.['SEARCH_NAME'] ? searchObj?.['SEARCH_NAME'] : 'receiverName',
    };
  }, [TARGET]);

  useEffect(() => {
    setSelectTypeObj({ ...defaultSelectTypeObj });
  }, [TARGET]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE_TAKEOUT, searchObj));
  }, [selectTypeObj?.SEARCH_DATE_TAKEOUT]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SEQ_TAKEOUT, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ_TAKEOUT]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_CENTER_TAKEOUT, searchObj));
  }, [selectTypeObj?.SEARCH_CENTER_TAKEOUT]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_NAME, searchObj));
  }, [selectTypeObj?.SEARCH_NAME]);

  // NOTE: 버튼 키 다운 이벤트, 클릭 이벤트를 submit 이벤트에 묶어 하나로 처리
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    fetchList(searchObj);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <label className="col-form-label">출고지시번호</label>
            <InputD
              type="number"
              value={searchObj?.loadingStatementSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  loadingStatementSeq: Number(e.target.value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">날짜</label>
            <div style={{ display: 'flex' }}>
              <Col style={{ display: 'inline-block' }} className="col-md-4">
                <SelectD
                  options={MASTER_OPS.SEARCH_DATE_TAKEOUT}
                  value={
                    selectTypeObj?.SEARCH_DATE_TAKEOUT
                      ? {
                          label: MASTER_OBJ?.SEARCH_DATE_TAKEOUT?.[selectTypeObj?.SEARCH_DATE_TAKEOUT],
                          value: selectTypeObj?.SEARCH_DATE_TAKEOUT,
                        }
                      : null
                  }
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_DATE_TAKEOUT: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col style={{ display: 'inline-block' }}>
                <FlatpickrD
                  disabled={!selectTypeObj?.SEARCH_DATE_TAKEOUT}
                  onClose={(option) => {
                    if (option?.length === 1)
                      setSearchObj({
                        ...searchObj,
                        [`from${selectTypeObj?.SEARCH_DATE_TAKEOUT}`]: null,
                        [`to${selectTypeObj?.SEARCH_DATE_TAKEOUT}`]: null,
                      });
                  }}
                  value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE_TAKEOUT}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE_TAKEOUT}`]]}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE_TAKEOUT}`]: returnDateyyyymmdd(option[0]),
                      [`to${selectTypeObj?.SEARCH_DATE_TAKEOUT}`]: returnDateyyyymmdd(option[1]),
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">창고</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_CENTER_TAKEOUT
                      ? {
                          value: selectTypeObj?.SEARCH_CENTER_TAKEOUT,
                          label: MASTER_OBJ?.SEARCH_CENTER_TAKEOUT?.[selectTypeObj?.SEARCH_CENTER_TAKEOUT],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_CENTER_TAKEOUT}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_CENTER_TAKEOUT: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_CENTER_TAKEOUT}
                  value={searchObj?.[selectTypeObj?.SEARCH_CENTER_TAKEOUT] || ''}
                  onChange={(e) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        [selectTypeObj?.SEARCH_CENTER_TAKEOUT]: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">주문/송장번호</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_SEQ_TAKEOUT
                      ? {
                          value: selectTypeObj?.SEARCH_SEQ_TAKEOUT,
                          label: MASTER_OBJ?.SEARCH_SEQ_TAKEOUT?.[selectTypeObj?.SEARCH_SEQ_TAKEOUT],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_SEQ_TAKEOUT}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_SEQ_TAKEOUT: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_SEQ_TAKEOUT}
                  value={searchObj?.[selectTypeObj?.SEARCH_SEQ_TAKEOUT] || ''}
                  onChange={(e) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        [selectTypeObj?.SEARCH_SEQ_TAKEOUT]: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col md={2}>
            <label className="col-form-label">고객명</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_NAME
                      ? {
                          value: selectTypeObj?.SEARCH_NAME,
                          label: MASTER_OBJ?.SEARCH_NAME?.[selectTypeObj?.SEARCH_NAME],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_NAME}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_NAME: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_NAME}
                  value={searchObj?.[selectTypeObj?.SEARCH_NAME] || ''}
                  onChange={(e) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        [selectTypeObj?.SEARCH_NAME]: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">유사검색여부</label>
            <div className="radio-select-area">
              <span
                className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: true,
                    };
                  });
                }}
              >
                유사검색
              </span>
              <span
                className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: false,
                    };
                  });
                }}
              >
                일치검색
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: 'right' }}>
            <button className="btn btn-search" type="submit">
              조회
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
