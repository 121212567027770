import { defaultGridProps } from 'common/grid/gridBox';
import { ParcelInvoiceDTO, ParcelInvoiceSearchDTO } from '../../../_interface/order';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';
import { orderService } from 'pages/ORDER/1order/_services/service';
import { useEffect, useRef, useState } from 'react';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { RAWDATA, setLoading } from 'redux/services/menuSlice';
import { Button } from 'reactstrap';
import { InputD } from 'components/reactstrap/reactstrap';
import CommonModalNew from 'components/modal/commonModalNew';
import OrderPhoto from 'pages/ORDER/1order/orderListTab/common/component/photos/PhotoPopUpForDetail';

export const TitleText = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: rgba(255, 145, 14, 1);
  font-weight: 500;
`;

export interface IParcelDetail {
  visible?: boolean;
  item?: ParcelInvoiceDTO;
  searchObj?: ParcelInvoiceSearchDTO;
  fetchFn?: (data: ParcelInvoiceSearchDTO) => {};
}

export const ParcelDetail = ({ detailObj, setDetailObj }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [parcelInvoices, setParcelInvoices] = useState<ParcelInvoiceDTO[]>();
  const [parcelDetailInfo, setParcelDetailInfo] = useState<ParcelInvoiceDTO>();

  useEffect(() => {
    if (detailObj?.item) {
      fetchDetail(detailObj?.item?.orderSeq);
    }
  }, []);

  const fetchDetail = async (orderSeq: number) => {
    const data = await orderService.getParcelDetail(orderSeq);
    if (data?.length > 0) {
      setParcelInvoices(data);
      setParcelDetailInfo(data[0]);
    } else {
      alert('조회되는 운송장이 없습니다!');
      setDetailObj(null);
    }
  };

  useEffect(() => {
    if (parcelDetailInfo) {
      gridRef.current.setGridData(parcelDetailInfo?.tracks);
    }
  }, [parcelDetailInfo]);

  const columns: IGrid.Column[] = [
    {
      headerText: '배송일시',
      dataField: 'eventDatetime',
    },
    {
      headerText: '현재위치',
      dataField: 'location',
    },
    {
      headerText: '배송상태',
      dataField: 'eventName',
    },
  ];

  const getDetail = (parcelInvoiceSeq: number) => {
    dispatch(setLoading('GET'));
    const data = parcelInvoices?.filter((ele) => ele.parcelInvoiceSeq === parcelInvoiceSeq)[0];
    setParcelDetailInfo(data);
    dispatch(setLoading(null));
  };

  const saveParcelDetail = async () => {
    const rs = await orderService.postParcelDetailInvoiceSave(parcelDetailInfo);
    if (rs?.status === 200) {
      alert('저장되었습니다');
      detailObj?.fetchFn(detailObj?.searchObj);
    }
  };

  const [visibleModal, setVisibleModal] = useState({
    type: null,
    visible: false,
  });

  return (
    <CommonModalNew
      //
      style={{ width: 1000 }}
      visible={detailObj?.visible}
      setVisible={() => setDetailObj(null)}
      title={
        <>
          {parcelInvoices?.map((parcel: ParcelInvoiceDTO, idx: number) => {
            return (
              <button
                key={`parcelInvoiceSeqs_inModal_${idx}`}
                onClick={() => getDetail(parcel.parcelInvoiceSeq)}
                className={parcel?.parcelInvoiceSeq === parcelDetailInfo?.parcelInvoiceSeq ? 'btn btn-secondary' : 'btn btn-etc'}
              >
                {parcel?.parcelInvoiceNumber}
              </button>
            );
          })}
        </>
      }
      rightTitle={
        <>
          <Button
            className="btn-reload btn ssm"
            onClick={() => {
              if (parcelDetailInfo?.invoiceStatus?.includes('FINISH')) {
                setVisibleModal({
                  type: 'photo',
                  visible: true,
                });
              } else {
                alert('완료된 건만 사진을 확인하실 수 있습니다!');
              }
            }}
          >
            사진보기
          </Button>
          <button
            className="btn btn-orange ssm"
            onClick={() => {
              saveParcelDetail();
            }}
          >
            저장
          </button>
        </>
      }
      children={
        <div>
          {visibleModal?.type === 'photo' && (
            <OrderPhoto
              visible={visibleModal?.visible}
              setVisible={() => {
                setVisibleModal({
                  ...visibleModal,
                  visible: !visibleModal?.visible,
                });
              }}
              invoiceDetail={parcelDetailInfo}
            />
          )}
          <div className="grid-wrapper div2">
            <div>
              <div>
                <TitleText>■ 주문정보</TitleText>
                <table className="border-table">
                  <tbody>
                    <tr>
                      <th>운송장번호</th>
                      <td>{parcelDetailInfo?.parcelInvoiceNumber}</td>
                      <th>총운임비</th>
                      <td>{parcelDetailInfo?.totalPrice}</td>
                    </tr>
                    <tr>
                      <th>주문등록일</th>
                      <td>{parcelDetailInfo?.registerDatetime}</td>
                      <th>차수</th>
                      <td>{parcelDetailInfo?.stage}</td>
                    </tr>
                    <tr>
                      <th>외부주문번호</th>
                      <td>{parcelDetailInfo?.externalOrderNumber}</td>
                      <th>외부송장번호</th>
                      <td>{parcelDetailInfo?.externalInvoiceNumber}</td>
                    </tr>
                    <tr>
                      <th>주문번호</th>
                      <td>{parcelDetailInfo?.orderSeq}</td>
                      <th>송장번호</th>
                      <td>{parcelDetailInfo?.invoiceSeq}</td>
                    </tr>
                    <tr>
                      <th>판매처</th>
                      <td>{parcelDetailInfo?.seller}</td>
                      <th>판매채널</th>
                      <td>{parcelDetailInfo?.salesChannelName}</td>
                    </tr>
                    <tr>
                      <th>판매사</th>
                      <td>{MASTER_OBJ?.SELLER_WHOLE?.[parcelDetailInfo?.partnerSeq]}</td>
                      <th>전화번호</th>
                      <td>{parcelDetailInfo?.senderPhone}</td>
                    </tr>
                    <tr>
                      <th>주소</th>
                      <td colSpan={3}>
                        {parcelDetailInfo?.senderAddr1} {parcelDetailInfo?.senderAddr2}
                      </td>
                    </tr>
                    <tr>
                      <th>실수령자</th>
                      <td>{parcelDetailInfo?.receiverName}</td>
                      <th>전화번호</th>
                      <td>{parcelDetailInfo?.receiverPhone}</td>
                    </tr>
                    <tr>
                      <th>주소</th>
                      <td colSpan={3}>
                        {parcelDetailInfo?.receiverAddr1} {parcelDetailInfo?.receiverAddr2}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <TitleText>■ 배송정보</TitleText>
                <table className="border-table">
                  <tbody>
                    <tr>
                      <th>품목명</th>
                      <td colSpan={3}>{parcelDetailInfo?.productName}</td>
                    </tr>
                    <tr>
                      <th>소속창고</th>
                      <td>{MASTER_OBJ?.CENTER_WHOLE?.[parcelDetailInfo?.centerCode]}</td>
                      <th>창고연락처</th>
                      <td>{MASTER_OPS?.[`${RAWDATA}CENTER_WHOLE`]?.filter((ele) => ele.centerCode === parcelDetailInfo?.centerCode)[0]?.managerPhone}</td>
                    </tr>
                    <tr>
                      <th>배송기사</th>
                      <td>{parcelDetailInfo?.userId}</td>
                      <th>기사연락처</th>
                      <td>{MASTER_OBJ?.DRIVER_PHONE_WHOLE?.[parcelDetailInfo?.userId]}</td>
                    </tr>
                    <tr>
                      <th>수량</th>
                      <td>{parcelDetailInfo?.quantity}</td>
                      <th>무게(g)</th>
                      <td>
                        <InputD
                          value={parcelDetailInfo?.weight || ''}
                          onChange={(e) => {
                            setParcelDetailInfo((prev) => {
                              return {
                                ...prev,
                                weight: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>포장상태</th>
                      <td>{MASTER_OBJ?.PACKING_STATE?.[parcelDetailInfo?.packingState]}</td>
                      <th>가로(cm)</th>
                      <td>
                        <InputD
                          type="number"
                          value={parcelDetailInfo?.width || ''}
                          onChange={(e) => {
                            setParcelDetailInfo((prev) => {
                              return {
                                ...prev,
                                width: e.target.value,
                                volume: parseInt(e.target.value) * parseInt(parcelDetailInfo?.depth) * parseInt(parcelDetailInfo?.height) + '',
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>송장상태</th>
                      <td>{MASTER_OBJ?.FEE_TYPE?.[parcelDetailInfo?.feeType]}</td>
                      <th>세로(cm)</th>
                      <td>
                        <InputD
                          type="number"
                          value={parcelDetailInfo?.depth || ''}
                          onChange={(e) => {
                            setParcelDetailInfo((prev) => {
                              return {
                                ...prev,
                                depth: e.target.value,
                                volume: parseInt(parcelDetailInfo?.width) * parseInt(e.target.value) * parseInt(parcelDetailInfo?.height) + '',
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>운임구분</th>
                      <td>{MASTER_OBJ?.PARCEL_PAY_TYPE?.[parcelDetailInfo?.parcelPayType]}</td>
                      <th>높이(cm)</th>
                      <td>
                        <InputD
                          type="number"
                          value={parcelDetailInfo?.height || ''}
                          onChange={(e) => {
                            setParcelDetailInfo((prev) => {
                              return {
                                ...prev,
                                height: e.target.value,
                                volume: parseInt(parcelDetailInfo?.width) * parseInt(parcelDetailInfo?.depth) * parseInt(e.target.value) + '',
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>운임비</th>
                      <td>
                        <InputD
                          type="number"
                          value={parcelDetailInfo?.price || ''}
                          onChange={(e) => {
                            setParcelDetailInfo((prev) => {
                              return {
                                ...prev,
                                price: Number(e.target.value),
                              };
                            });
                          }}
                        />
                      </td>
                      <th>체적(cm3)</th>
                      <td>
                        <InputD readOnly disabled value={parcelDetailInfo?.volume || '자동계산'} />
                      </td>
                    </tr>
                    <tr>
                      <th>도선료</th>
                      <td>
                        <InputD
                          type="number"
                          value={parcelDetailInfo?.extraFee || ''}
                          onChange={(e) => {
                            setParcelDetailInfo((prev) => {
                              return {
                                ...prev,
                                extraFee: Number(e.target.value),
                              };
                            });
                          }}
                        />
                      </td>
                      <th>배송완료일</th>
                      <td>{parcelDetailInfo?.deliveryDate}</td>
                    </tr>
                    <tr>
                      <th>운임합계</th>
                      <td>{parcelDetailInfo?.totalBoxQuantity}</td>
                      <td colSpan={2}></td>
                    </tr>
                    <tr style={{ height: 60 }}>
                      <th>고객요청사항</th>
                      <td colSpan={3}>{parcelDetailInfo?.shipmentNote}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{ position: 'relative' }}>
              <TitleText style={{ marginBottom: 5 }}>■ 배송추적</TitleText>
              <AUIGrid
                ref={gridRef}
                name={'parcelDetail'}
                columnLayout={columns}
                gridProps={{
                  //
                  ...defaultGridProps,
                  height: 585,
                  showRowAllCheckBox: false,
                  showRowCheckColumn: false,
                }}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};
