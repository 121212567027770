import { ReactNode, useMemo, useRef, useState } from 'react';

// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { DetailPopup, IDetailObj } from './component/detailPopup';
import { PagingListDTO } from 'interface/util';
import AUIGrid from 'modules/Grid';
import PrintGridBox from 'common/grid/printGridBox';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { serviceStore } from 'services/services';
import { RootState } from 'redux/store';
import { VENDOR_TAB_EXCELFORM } from 'envVar2';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import { useDidMountEffect } from 'hooks/basichooks';
import { PartnerVendorDTO, PartnerVendorSearchDTO } from '../../_interface/user';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const excelGridRef = useRef<AUIGrid>();
  const [detailObj, setDetailObj] = useState<IDetailObj>();
  const gridRef = useRef<IPagingGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const labellingKr = (data: PagingListDTO<PartnerVendorDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        addressKr: `${row.address} ${row.addressDetail || ''}`,
        statusKr: MASTER_OBJ?.ACTIVE_OP?.[row.status],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: PartnerVendorSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.userAction(`partner/vendor/list/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<PartnerVendorDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const openDetail = async (e: IGrid.ButtonClickEvent) => {
    setDetailObj({ visible: true, item: e.item, searchObj: JSON.parse(e.item.searchObj), fetchFn: wrappedFetchList });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      dataField: 'detail',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: openDetail,
      },
    },
    {
      headerText: '거래처명',
      dataField: 'partnerName',
    },
    {
      headerText: '거래처코드',
      dataField: 'externalPartnerCode',
    },
    {
      headerText: '사업자번호',
      dataField: 'businessNumber',
    },
    {
      headerText: '대표자명',
      dataField: 'managerName',
    },
    {
      headerText: '주소',
      dataField: 'addressKr',
      width: 350,
    },
    {
      headerText: '연락처',
      dataField: 'managerPhone',
    },
    {
      headerText: '은행',
      dataField: 'bank',
    },
    {
      headerText: '계좌번호',
      dataField: 'accountNumber',
    },
    {
      headerText: '담당자명',
      dataField: 'leadManagerName',
    },
    {
      headerText: '운영상태',
      dataField: 'statusKr',
    },
  ];

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'VENDOR_TAB_CREATE') {
      setDetailObj({
        visible: true,
        item: null,
        searchObj,
        fetchFn: wrappedFetchList,
      });
    } else if (id === 'VENDOR_TAB_EXCELDOWN_MAIN') {
      downloadExcel('main');
    } else if (id === 'VENDOR_TAB_EXCELFORM') {
      window.open(VENDOR_TAB_EXCELFORM);
    } else if (id === 'VENDOR_TAB_EXCELFORM_UPLOAD') {
      uploaderClick(id);
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj, tabId]);

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.userAction(`partner/vendor/list/excel`, 'GET', searchObj, null))?.data as PartnerVendorDTO[],
      },
      true,
    );
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `거래처관리` });
    dispatch(setLoading(null));
  };

  const KRTOVALUE = {
    discountRate: (discountRate) => {
      return discountRate?.includes('%') ? discountRate?.split('%')[0] : Number(discountRate);
    },
  };

  const EXCELHEADER = {
    VENDOR_TAB_EXCELFORM_UPLOAD: [
      //
      'partnerName',
      'externalPartnerCode',
      'managerName',
      'address',
      'businessNumber',
      'managerPhone',
      'managerEmail',
      'bank',
      'accountNumber',
      'startDate',
      'discountRate',
      'leadManagerName',
    ],
  };

  const uploadData = async (excelrows, id) => {
    dispatch(setLoading('POST'));
    if (id === 'VENDOR_TAB_EXCELFORM_UPLOAD') {
      const rs = await serviceStore?.userAction(`partner/vendor/excel`, 'POST', null, excelrows);
      if (rs?.status === 200) {
        alert(`업로드에 성공하였습니다!`);
        wrappedFetchList(searchObj);
      }
    }

    dispatch(setLoading(null));
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE });
  useDidMountEffect(() => {
    if (parsedData?.data?.length > 0) {
      uploadData(parsedData?.data, parsedData?.id);
    }
  }, [parsedData]);

  return (
    <div className="page-content">
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        <GridBox gridRef={gridRef} columns={columns} gridProps={{ showRowAllCheckBox: false, showRowCheckColumn: false, fixedColumnCount: 1 }} />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
