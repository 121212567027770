import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ParcelTrackingDTO } from 'interface/order';
import parsingDataString from 'query-string';
import { httpClient } from 'common/http-client/axiosConfig';

// img
import closeImg from '../../../../assets/images/button/closeImg.png';
import logo from './img/logimateLogi.png';

// styled-component
import styled from 'styled-components';
import { numberTestRegExp } from 'common/util/regExp';
import { SelectedInvoice } from '../orderTrackingParcelTab/trackInvoice';
const Wrapper = styled.div`
  min-height: 80vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  justify-content: center;
  align-items: center;
`;

const WrapperInput = styled.div`
  position: relative;
  display: flex;
`;

const SearchInput = styled.input`
  border: 1px solid rgba(217, 222, 231, 1);
  height: 50px;
  width: 250px;
  color: rgba(163, 167, 185, 1);
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 13px;
`;

const SearchButton = styled.button`
  height: 50px;
  width: 60px;
  margin-left: 20px;
  background-color: black;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  border: none;
`;

const CloseImg = styled.img`
  position: absolute;
  top: 15px;
  right: 78px;
  cursor: pointer;
`;

const Index = () => {
  const history = useNavigate();
  const location = useLocation();
  const [invoiceSeq, setInvoiceSeq] = useState<string>();
  const [infoData, setInfoData] = useState<ParcelTrackingDTO>();

  useEffect(() => {
    const seq = parsingDataString.parse(location.search)?.seq as string;
    if (seq) fetchInfoData(seq);
  }, [location]);

  const fetchInfoData = async (seq: string) => {
    if (seq) {
      const rs = await httpClient.get(`/parcel/invoice/customer?parcelInvoiceNumber=${seq}`);
      if (rs?.status === 200) {
        setInfoData(rs?.data);
      }
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (invoiceSeq) history(`/logistics?seq=${invoiceSeq}`, { replace: true });
      else alert('운송장번호를 입력해주세요!');
    }
  };

  return (
    <>
      {!infoData ? (
        <div className="page-content" style={{ margin: 0, padding: 0, height: '100vh' }}>
          <Wrapper onKeyPress={onKeyPress}>
            {location?.pathname === '/logistics' && <img src={logo} style={{ width: 200, marginBottom: 50 }} />}
            <WrapperInput>
              <SearchInput
                placeholder="운송장번호를 입력해주세요."
                value={invoiceSeq || ''}
                onChange={(e) => {
                  if (numberTestRegExp(e.target.value)) {
                    setInvoiceSeq(e.target.value);
                  } else {
                    alert('숫자만 기입가능합니다!');
                  }
                }}
              />
              {invoiceSeq && (
                <CloseImg
                  src={closeImg}
                  onClick={() => {
                    setInvoiceSeq('');
                  }}
                />
              )}
              <SearchButton
                onClick={() => {
                  if (invoiceSeq) history(`/logistics?seq=${invoiceSeq}`);
                  else alert('운송장번호를 입력해주세요!');
                }}
              >
                조회
              </SearchButton>
            </WrapperInput>
            <div style={{ textAlign: 'center', padding: 50, fontWeight: 500, fontSize: 15 }}>
              본 정보는 물류사의 제공받는 정보로, 실제 배송 상황과 다를 수 있습니다. <br />
              실제 배송 수행은 물류사에서 진행하여 물류대장은 배송 책임을 지지 않습니다.
            </div>
          </Wrapper>
        </div>
      ) : (
        <div className="page-content" style={{ padding: 0 }}>
          <Wrapper style={{ justifyContent: 'flex-start' }}>
            <SelectedInvoice selected={infoData} />
            <span
              style={{ width: '100%', maxWidth: 360, textAlign: 'center', padding: '13px 0', backgroundColor: '#FF9110', fontSize: 16, color: 'white', height: 50, cursor: 'pointer' }}
              onClick={() => {
                history('/logistics');
                window.location.reload();
              }}
            >
              뒤로가기
            </span>
          </Wrapper>
        </div>
      )}
    </>
  );
};

export default Index;
