import { createSlice } from '@reduxjs/toolkit';
import { GroupDetailDTO } from 'interface/user';

interface InitProps {
  // rawData?: GroupDetailDTO;
  nowData?: GroupDetailDTO;
  tabData?: { [key: string]: any };
  currentTab?: string;
}
export const initialGroupState: InitProps = {
  // rawData: null,
  nowData: null,
  tabData: null,
  currentTab: null,
};

export const groupSlice = createSlice({
  name: 'group',
  initialState: initialGroupState,
  reducers: {
    init: () => {
      return initialGroupState;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    // setRawData: (state, action) => {
    //   state.rawData = action.payload;
    // },
    setNowData: (state, action) => {
      state.nowData = action.payload;
    },
    setTabData: (state, action) => {
      state.tabData = {
        ...state.tabData,
        [action.payload.tab]: {
          ...state.tabData?.[action.payload.tab],
          [action.payload.key]: action.payload.value,
        },
      };
    },
  },
});

export const {
  //
  init,
  setCurrentTab,
  // setRawData,
  setNowData,
  setTabData,
} = groupSlice.actions;

export default groupSlice.reducer;
