import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab2 from './simple';
import Tab3 from './detail';
import Tab4 from './apply';

// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER, RAWDATA } from 'redux/services/menuSlice';

export const INVESTIGATE_MASTER = [
  //
  `${RAWDATA}CENTER_AUTH`,
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  //
  'INVA_TYPE',
  'INV_STATUS',
  'LOCATION_TYPE',
  'INV_REQUEST_STATUS',
  'INVESTIGATION_APPLY_TYPE',
  'INVESTIGATION_TYPE',
  'MODEL_GROUP',
  'SEARCH_MODEL',
  {
    SEARCH_DATE: [{ value: 'RegisterDate', label: '종료일' }],
  },
  {
    SEARCH_DATE_DETAIL: [
      { value: 'StartDate', label: '시작일' },
      { value: 'FinishDate', label: '완료일' },
      { value: 'CloseDate', label: '마감일' },
      { value: 'ApplyDate', label: '재고반영일' },
    ],
  },
  {
    SEARCH_DATE_APPLY: [
      { value: 'PostingDate', label: '반영일' },
      { value: 'ApplyDate', label: '전기일' },
    ],
  },
  //
  'PICK_FLAG',
  'USE_FLAG',
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(INVESTIGATE_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            INVENTORYINVESTIGATE_SIMPLE: <Tab2 tabId={'INVENTORYINVESTIGATE_SIMPLE'} />,
            INVENTORYINVESTIGATE_DETAIL: <Tab3 tabId={'INVENTORYINVESTIGATE_DETAIL'} />,
            INVENTORYINVESTIGATE_APPLY: <Tab4 tabId={'INVENTORYINVESTIGATE_APPLY'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
