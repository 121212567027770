// react
import React, { useEffect, useRef, useState } from 'react';

import ReactToPrint from 'react-to-print';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { setLoading } from 'redux/services/menuSlice';
import { LoanPickingDetailListDTO, LoanPickingPrintDTO, WarehouseDTO } from 'interface/warehouse';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import ModalforBarcode, { HandleWithCare, IBarcodeInfoObj, JustPallet, PalletAndSign, PrintTitle } from 'components/print/print';
import { driverService } from '../_services/service';
import { SelectD } from 'components/reactstrap/reactstrap';

export interface IPrints {
  visible?: boolean;
  type?: 'total' | 'driver' | 'takeout';
  items?: any;
}

const convertor = {
  total: {
    title: 'FC피킹 지시서',
    seq: 'loanPickSeq',
  },
  driver: {
    title: 'FC피킹 지시서',
    seq: 'loanPickSeq',
  },
  takeout: {
    title: '간선출차 운송명세서',
    seq: 'transportStatementSeq',
  },
};

export const FCPrint = ({ fcPrintObj, setFcPrintObj }) => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [printData, setPrintData] = useState<any>();
  const [barcodeInfoObj, setBarcodeInfoObj] = useState<IBarcodeInfoObj>();

  const wrapperRef = useRef();
  useEffect(() => {
    if (fcPrintObj?.items?.length > 0) {
      fetchPrintData(fcPrintObj?.items);
    } else {
      alert('조회할 정보가 없습니다');
      setFcPrintObj(null);
    }
  }, []);

  const fetchPrintData = async (items) => {
    dispatch(setLoading('GET'));
    let rs, dataDTO;
    if (fcPrintObj?.type === 'total') {
      dataDTO = items?.map((ele) => {
        return {
          loanPickSeq: ele.loanPickSeq,
        };
      });
      rs = await driverService?.postAction(`/warehouse/loan/picking/print`, dataDTO);
    } else if (fcPrintObj?.type === 'driver') {
      dataDTO = items?.map((ele) => {
        return {
          loanPickSeq: ele.loanPickSeq,
          userId: ele.userId,
        };
      });
      rs = await driverService?.postAction(`/warehouse/loan/picking/driver/print`, dataDTO);
    } else if (fcPrintObj?.type === 'takeout') {
    }

    if (rs?.status === 200) {
      const printData = [];
      if (fcPrintObj?.type === 'driver') {
        console.log(rs?.data);
        rs?.data?.forEach((dat) => {
          dat?.forEach((row) => {
            printData?.push({
              ...row,
              totalCenter: masterOptions?.CENTER_WHOLE?.filter((ele) => ele.centerCode === row?.centerCode)[0] || {},
              availableCount: (row?.list as LoanPickingDetailListDTO[])
                ?.map((ele) => ele.availableQuantity)
                ?.reduce((acc: number, cur: number) => {
                  return acc + cur;
                }),
              etcCount: (row?.list as LoanPickingDetailListDTO[])
                ?.map((ele) => ele.etcQuantity)
                ?.reduce((acc: number, cur: number) => {
                  return acc + cur;
                }),
              list: row?.list?.map((model) => {
                return {
                  ...model,
                  receiverNames: row.receiverNames?.length > 0 ? row.receiverNames : !row.receiverNames ? null : new Array(row.quantity)?.fill('-'),
                  serialNumbers: row.serialNumbers?.length > 0 ? row.serialNumbers : !row.serialNumbers ? null : new Array(row.quantity)?.fill('-'),
                };
              }),
            });
          });
        });
      } else {
        rs?.data?.forEach((row) => {
          printData?.push({
            ...row,
            totalCenter: masterOptions?.CENTER_WHOLE?.filter((ele) => ele.centerCode === row?.centerCode)[0] || {},
            availableCount: (row?.list as LoanPickingDetailListDTO[])
              ?.map((ele) => ele.availableQuantity)
              ?.reduce((acc: number, cur: number) => {
                return acc + cur;
              }),
            etcCount: (row?.list as LoanPickingDetailListDTO[])
              ?.map((ele) => ele.etcQuantity)
              ?.reduce((acc: number, cur: number) => {
                return acc + cur;
              }),
          });
        });
      }
      setPrintData(printData);
    }
    dispatch(setLoading(null));
  };

  const [printFormat, setPrintFormat] = useState<'P' | 'L'>('P');
  const printFormatOptions = [
    {
      value: 'P', // 세로출력
      label: '기본출력',
    },
    {
      value: 'L', // 가로출력
      label: '상세출력',
    },
  ];

  const printAvailableType = (data) => {
    if (data?.availableQuantity + data?.defectedQuantity + data?.etcQuantity === 0) return '';
    if (data?.availableQuantity > 0) return '양품';
    if (data?.defectedQuantity > 0) return '불용';
    if (data?.etcQuantity > 0) return '기타';
  };

  return (
    <>
      <CommonModalNew
        title={convertor[fcPrintObj?.type]?.title}
        style={{ width: fcPrintObj?.type !== 'takeout' ? 1300 : 900 }}
        visible={fcPrintObj?.visible}
        setVisible={() => setFcPrintObj(null)}
        rightTitle={
          <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            {fcPrintObj?.type !== 'takeout' && (
              <div style={{ width: 100 }}>
                <label className="col-form-label">출력형식</label>
                <SelectD
                  hasNull={false}
                  value={{ value: printFormat, label: selectlabel(printFormat, printFormatOptions) }}
                  options={printFormatOptions}
                  onChange={(option) => {
                    setPrintFormat((option as OptionItem)?.value);
                  }}
                />
              </div>
            )}
            <div style={{ paddingBottom: 3 }}>
              <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
            </div>
          </div>
        }
        children={
          <>
            <div
              ref={wrapperRef}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ModalforBarcode barcodeInfoObj={barcodeInfoObj} setBarcodeInfoObj={setBarcodeInfoObj} />
              {printData?.map((data: LoanPickingPrintDTO & { totalCenter: WarehouseDTO; availableCount: number; etcCount: number }, idx) => (
                <div style={{ padding: 30, width: '100%' }} className="break-for-onePage-print" key={`data_${idx}`}>
                  <PrintTitle
                    seq={data?.[convertor[fcPrintObj?.type]?.seq]}
                    title={
                      <div>
                        {convertor[fcPrintObj?.type]?.title}
                        {printFormat === 'L' && fcPrintObj?.type === 'driver' && <span style={{ fontWeight: 800 }}>({selectlabel(data?.userId, masterOptions?.DRIVER)})</span>}
                      </div>
                    }
                  />

                  <div className="presenterInput">
                    <span>1. 기본정보</span>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {fcPrintObj?.type === 'driver' && printFormat === 'P' && (
                        <table className="border-table" style={{ marginRight: 20, width: '200mm' }}>
                          <tbody>
                            <tr>
                              <th>기사</th>
                            </tr>
                            <tr>
                              <td style={{ fontSize: 100, textAlign: 'center', fontWeight: 900 }}>{selectlabel(data?.userId, masterOptions?.DRIVER)}</td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                      <table className="border-table" style={{ width: '150mm' }}>
                        <thead>
                          <tr>
                            <th>출고예정일</th>
                            <td colSpan={5}>{data?.loanPickDate}</td>
                          </tr>
                          <tr>
                            <th>확정일자</th>
                            <td colSpan={2}>{data?.confirmDatetime}</td>
                            <th>확정자</th>
                            <td colSpan={2}>{data?.confirmId}</td>
                          </tr>
                          <tr>
                            <th rowSpan={2}>출고창고</th>
                            <td colSpan={2}>{data?.totalCenter?.centerName}</td>
                            <th>관리자</th>
                            <td colSpan={2}>
                              {data?.totalCenter?.managerName} / {data?.totalCenter?.managerPhone}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={5}>
                              {data?.totalCenter?.address} {data?.totalCenter?.addressDetail || ''}
                            </td>
                          </tr>
                        </thead>
                      </table>
                      {!(fcPrintObj?.type === 'driver' && printFormat === 'P') && (
                        <table className="border-table" style={{ width: '100mm' }}>
                          <thead>
                            <tr>
                              <th>작업지시일</th>
                              <th>작업지시자(인)</th>
                              <th>작업자(인)</th>
                              <th>창고장(인)</th>
                            </tr>
                            <tr style={{ height: 50 }}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </thead>
                        </table>
                      )}
                    </div>
                  </div>
                  <div className="presenterInput">
                    <span>2. 제품정보</span>
                    {fcPrintObj?.type === 'takeout' ? (
                      <>
                        <span>2. 제품정보</span>
                        <table className="border-table">
                          <thead className="text-center">
                            <tr>
                              <th colSpan={12}>제품정보</th>
                              <th colSpan={2}>수량</th>
                            </tr>
                            <tr>
                              <th colSpan={2}>로케이션</th>
                              <th colSpan={2}>바코드</th>
                              <th colSpan={2}>
                                SKU
                                <br />
                                (외부SKU)
                              </th>
                              <th colSpan={3}>제품</th>
                              <th colSpan={3}>제품명</th>
                              <th>양품</th>
                              <th>기타</th>
                            </tr>
                          </thead>
                          <tbody style={{ textAlign: 'center' }}>
                            {data?.list?.map((list, idx) => (
                              <tr key={`modelList_${idx}`}>
                                <td colSpan={2}>{list.locationCode}</td>
                                <td colSpan={2} onClick={() => setBarcodeInfoObj({ visible: true, data: list?.barcode })}>
                                  {list.barcode}
                                </td>
                                <td colSpan={2}>
                                  {list.modelStockSeq}
                                  <br />({list.skuNumber})
                                </td>
                                <td colSpan={3}>{list.model}</td>
                                <td colSpan={3}>
                                  {list?.handleWithCareFlag && <HandleWithCare />}
                                  {list.modelName}
                                </td>
                                <td>{list.availableQuantity}</td>
                                <td>{list.etcQuantity}</td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot style={{ textAlign: 'center' }}>
                            <tr>
                              <td style={{ textAlign: 'right' }} colSpan={12}>
                                계
                              </td>
                              <td>{data?.availableCount}</td>
                              <td>{data?.etcCount}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </>
                    ) : (
                      <>
                        {printFormat === 'P' ? (
                          <>
                            <table className="border-table">
                              <thead className="text-center">
                                <tr>
                                  <th colSpan={12}>제품정보</th>
                                  <th colSpan={2}>수량</th>
                                </tr>
                                <tr>
                                  <th colSpan={2}>로케이션</th>
                                  <th colSpan={2}>바코드</th>
                                  <th colSpan={2}>
                                    SKU
                                    <br />
                                    (외부SKU)
                                  </th>
                                  <th colSpan={3}>제품</th>
                                  <th colSpan={3}>제품명</th>
                                  <th>양품</th>
                                  <th>기타</th>
                                </tr>
                              </thead>
                              <tbody style={{ textAlign: 'center' }}>
                                {data?.list?.map((list, idx) => (
                                  <tr key={`modelListP_${idx}`}>
                                    <td colSpan={2}>{list.locationCode}</td>
                                    <td colSpan={2} onClick={() => setBarcodeInfoObj({ visible: true, data: list?.barcode })}>
                                      {list.barcode}
                                    </td>
                                    <td colSpan={2}>
                                      {list.modelStockSeq}
                                      <br />({list.skuNumber})
                                    </td>
                                    <td colSpan={3}>{list.model}</td>
                                    <td colSpan={3}>
                                      {list?.handleWithCareFlag && <HandleWithCare />}
                                      {list.modelName}
                                    </td>
                                    <td>{list.availableQuantity}</td>
                                    <td>{list.etcQuantity}</td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot style={{ textAlign: 'center' }}>
                                <tr>
                                  <td style={{ textAlign: 'right' }} colSpan={12}>
                                    계
                                  </td>
                                  <td>{data?.availableCount}</td>
                                  <td>{data?.etcCount}</td>
                                </tr>
                              </tfoot>
                            </table>
                          </>
                        ) : (
                          <>
                            <table className="border-table">
                              <thead className="text-center">
                                <tr>
                                  <th colSpan={3}>로케이션</th>
                                  <th colSpan={3}>바코드</th>
                                  <th colSpan={4}>제품</th>
                                  <th colSpan={4}>제품명</th>
                                  <th colSpan={2}>재고상태</th>
                                  <th colSpan={2}>고객명</th>
                                  <th>수량</th>
                                  <th colSpan={4}>시리얼번호</th>
                                </tr>
                              </thead>
                              <tbody style={{ textAlign: 'center' }}>
                                {data?.list?.map((list, idx) => (
                                  //   new Array(list?.quantity)?.fill(0)?.map((row) => {
                                  //   return ()
                                  // })
                                  <tr key={`modelListL_${idx}`}>
                                    <td colSpan={3}>{list?.locationCode}</td>
                                    <td colSpan={3} onClick={() => setBarcodeInfoObj({ visible: true, data: list?.barcode })}>
                                      {list?.barcode}
                                    </td>
                                    <td colSpan={4}>{list?.model}</td>
                                    <td colSpan={4}>
                                      {list?.handleWithCareFlag && <HandleWithCare />}
                                      {list?.modelName}
                                    </td>
                                    <td colSpan={2}>{printAvailableType(list)}</td>
                                    <td colSpan={2}>
                                      {list?.receiverNames?.map((sn) => {
                                        return <div>{sn || '-'}</div>;
                                      })}
                                    </td>
                                    <td>{list?.quantity}</td>
                                    <td colSpan={4}>
                                      {list?.serialNumbers?.map((sn) => {
                                        return <div>{sn || '-'}</div>;
                                      })}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot style={{ textAlign: 'center' }}>
                                <tr>
                                  <td style={{ textAlign: 'right', paddingRight: 10 }} colSpan={23}>
                                    합계 &nbsp;
                                    {data?.list
                                      ?.map((ele) => Number(ele.quantity))
                                      .reduce((acc, cur) => {
                                        return acc + cur;
                                      })}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {printFormat === 'P' && fcPrintObj?.type === 'driver' ? <PalletAndSign signWhen="* 피킹 완료 후 서명하시고 창고장에게 전달하세요." /> : <JustPallet />}
                </div>
              ))}
            </div>
          </>
        }
      />
    </>
  );
};
