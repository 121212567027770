import { KeyboardEvent, useEffect, useRef, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { LocationSupplementSearchDTO } from 'interface/warehouse';

export const SearchBox = (props: ISearchBoxProps<LocationSupplementSearchDTO>) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">창고</label>
            <SelectD
              options={masterOptions?.CENTER_AUTH}
              value={
                searchObj?.centerCode
                  ? {
                      value: searchObj?.centerCode,
                      label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER_AUTH),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    centerCode: (option as OptionItem).value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">보충 로케이션</label>
            <InputD
              value={searchObj?.locationCodeForSupplement || ''}
              onChange={(e) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    locationCodeForSupplement: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">대표 로케이션</label>
            <InputD
              value={searchObj?.locationCodeForRepresentative || ''}
              onChange={(e) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    locationCodeForRepresentative: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">판매사</label>
            <SelectD
              options={masterOptions?.SELLER_AUTH}
              value={
                searchObj?.partnerSeq
                  ? {
                      value: searchObj?.partnerSeq,
                      label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER_AUTH),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    partnerSeq: (option as OptionItem).value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">제품/제품명</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_MODEL
                      ? {
                          value: selectTypeObj?.SEARCH_MODEL,
                          label: selectlabel(selectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_MODEL}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_MODEL: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_MODEL}
                  value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">유사검색여부</label>
            <div className="radio-select-area">
              <span
                className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: true,
                    };
                  });
                }}
              >
                유사검색
              </span>
              <span
                className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: false,
                    };
                  });
                }}
              >
                일치검색
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: 'right' }}>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                fetchList(searchObj);
              }}
            >
              조회
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
