import { COLORS } from 'common/constants/appearance';
import styled from 'styled-components';
import { Children, useRef, cloneElement } from 'react';
import { useNavigation } from '../hooks/useNavigation';

type NavigationProps = {
  $style: 'RESPONSIVE' | 'FILL';
  $activeId: string;
  $hasBorder?: boolean;
  $width?: number;
  $sticky?: boolean;
};

export default function LDSNavigation({ $style, $hasBorder, $width, $activeId, children, $sticky }: NavigationProps & { children?: React.ReactNode }) {
  const { activeItem, handleNavigation } = useNavigation($activeId || '');
  const navRef = useRef();

  const injectActiveIdToChildren = ({ children }) => {
    const ModifiedChildren = () =>
      Children.map(children, (child) => {
        let tabItems = child;

        return cloneElement(tabItems, {
          $activeId: activeItem,
        });
      });
    return <ModifiedChildren />;
  };

  return (
    <NavigationWrapper $style={$style} $hasBorder={$hasBorder} $width={$width} $sticky={$sticky}>
      <ChildrenContainer ref={navRef} onClick={handleNavigation}>
        {injectActiveIdToChildren({ children })}
      </ChildrenContainer>
      {$hasBorder && <NavigationBorder />}
    </NavigationWrapper>
  );
}

type NavigationWrapperProps = Omit<NavigationProps, '$activeId'>;

const NavigationWrapper = styled.div<NavigationWrapperProps>`
  position: relative;
  width: ${(props) => (props.$width ? `${props.$width}px` : 'auto')};
  background-color: inherit;
  position: ${(props) => (props.$sticky ? 'sticky' : null)};
  top: ${(props) => (props.$sticky ? '0' : 'auto')};
  box-shadow: ${(props) => (props.$sticky ? '0 -20px 0 0 #FFF' : null)};

  z-index: ${(props) => (props.$sticky ? 2 : null)};
  & > nav > * {
    flex-grow: ${(props) => (props.$style === 'FILL' ? 1 : 0)};
  }
`;

const ChildrenContainer = styled.nav`
  display: flex;
  gap: 0;
  position: relative;
  overflow-x: auto;
  z-index: 1;
`;

const NavigationBorder = styled.div`
  position: absolute;
  bottom: 0;
  left: -100vw;
  right: -100vw;
  height: 1px;
  z-index: 0;

  background-color: ${COLORS.GRAY_02_DEEP};
`;
