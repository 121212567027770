import { COLORS, TRANSITIONS, VALUES } from 'common/constants/appearance';
import { FiChevronDown } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { LDSLabelMD, LDSLabelSM, LDSLabelXS } from './Typography';
import LDSBadge from './Badge';
import LDSTooltip, { TooltipProps } from '../modules/Tooltip';
import { useTooltip } from '../hooks/useTooltip';
import { calculateElementPositionAndSize } from 'common/util/handleElement';
import LDSDropdown, { DropdownProps } from '../layouts/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setShowDropdown } from 'redux/services/portalSlice';

export type ButtonProps = {
  $type: 'LINK' | 'BUTTON' | 'SUBMIT';
  $primary: boolean;
  $label?: string;
  $usage?: 'PRIMARY' | 'ALERT' | 'WARNING' | 'GREEN' | 'SUCCESS' | 'BLUE' | 'PURPLE';
  $showIcon?: boolean;
  $icon?: React.ReactNode;
  $isDropdown?: boolean;
  $isCircled?: boolean;
  $size?: 'XS' | 'SM' | 'MD' | 'LG';
  $style?: 'FILL' | 'OUTLINED' | 'TRANSPARENT';
  $linkTo?: string;
  $width?: number | 'FULL';
  $badgeCount?: string;
  $status?: 'ACTIVE' | 'DEFAULT';
  onClick?: (e: any) => void;
  onDoubleClick?: () => void;
  $tooltip?: Omit<TooltipProps, '$data'>;
  $dropdown?: Omit<DropdownProps, '$data'>;
  id?: string;
  autoFocus?: boolean;
  tabIndex?: number;
  $disabled?: boolean;
};

export default function LDSButton({
  $primary = false,
  $showIcon,
  $icon,
  $type,
  $label,
  $linkTo,
  $isDropdown,
  $size,
  $badgeCount,
  $status,
  $tooltip,
  $dropdown,
  id,
  onDoubleClick,
  autoFocus,
  tabIndex,
  ...props
}: ButtonProps) {
  const { showTooltip, setShowTooltip, targetRef } = useTooltip();
  const dispatch = useDispatch();
  const { showDropdown } = useSelector((state: RootState) => state.portal);

  return (
    <>
      {$type === 'LINK' && (
        <LDSBaseButtonStyle
          to={$linkTo}
          className={$status === 'ACTIVE' ? 'active' : ''}
          type="button"
          $primary={$primary}
          $size={$size}
          ref={targetRef}
          onMouseOver={() => $tooltip && setShowTooltip(true)}
          onMouseOut={() => $tooltip && setShowTooltip(false)}
          onMouseDown={() => $tooltip && setShowTooltip(false)}
          {...props}
        >
          {$showIcon && $icon}
          {$label && $size === 'LG' && $label?.length > 0 ? (
            <LDSLabelMD $weight="BOLD">{$label}</LDSLabelMD>
          ) : ($size === 'MD' && $label?.length > 0) || ($size === 'SM' && $label?.length > 0) ? (
            <LDSLabelSM $weight={$size === 'MD' ? 'BOLD' : 'SEMIBOLD'}>{$label}</LDSLabelSM>
          ) : $size === 'XS' && $label?.length > 0 ? (
            <LDSLabelXS $weight={'SEMIBOLD'}>{$label}</LDSLabelXS>
          ) : (
            <></>
          )}
          {$isDropdown && <FiChevronDown className="dropdown-arrow" strokeWidth={3} />}
          {$badgeCount && <LDSBadge $type={'NUMBER'} $size={'SM'} $value={$badgeCount} $color={'PRIMARY'} />}
        </LDSBaseButtonStyle>
      )}
      {($type === 'BUTTON' || $type === 'SUBMIT') && (
        <LDSBaseButtonStyle
          tabIndex={tabIndex}
          as="button"
          className={$status === 'ACTIVE' ? 'active' : showDropdown === $tooltip?.$description && $dropdown ? 'selected' : ''}
          type={$type === 'BUTTON' ? 'button' : 'submit'}
          $primary={$primary}
          $size={$size}
          id={id}
          ref={targetRef}
          autoFocus={autoFocus}
          onDoubleClick={onDoubleClick}
          onMouseOver={() => $tooltip && setShowTooltip(true)}
          onMouseOut={() => $tooltip && setShowTooltip(false)}
          onMouseDown={() => $tooltip && setShowTooltip(false)}
          onMouseUp={() => $dropdown && !showDropdown && dispatch(setShowDropdown($label || $tooltip?.$description))}
          {...props}
        >
          {$showIcon && $icon}
          {$label && $size === 'LG' && $label?.length > 0 ? (
            <LDSLabelMD $weight="BOLD">{$label}</LDSLabelMD>
          ) : ($size === 'MD' && $label?.length > 0) || ($size === 'SM' && $label?.length > 0) ? (
            <LDSLabelSM $weight={$size === 'MD' ? 'BOLD' : 'SEMIBOLD'}>{$label}</LDSLabelSM>
          ) : $size === 'XS' && $label?.length > 0 ? (
            <LDSLabelXS $weight={'SEMIBOLD'}>{$label}</LDSLabelXS>
          ) : (
            <></>
          )}
          {$isDropdown && <FiChevronDown className="dropdown-arrow" strokeWidth={3} />}
          {$badgeCount && <LDSBadge $type={'NUMBER'} $size={'SM'} $value={$badgeCount} $color={'PRIMARY'} />}
        </LDSBaseButtonStyle>
      )}
      {showTooltip && (
        <LDSTooltip
          $data={calculateElementPositionAndSize(targetRef?.current)}
          $direction={$tooltip.$direction}
          $anchorPosition={$tooltip.$anchorPosition}
          $description={$tooltip.$description}
        ></LDSTooltip>
      )}
      {showDropdown === $tooltip?.$description && $dropdown && (
        <LDSDropdown $width={$dropdown.$width} $maxHeight={$dropdown.$maxHeight} $position={$dropdown.$position} $align={$dropdown.$align} $data={calculateElementPositionAndSize(targetRef?.current)}>
          {$dropdown.children}
        </LDSDropdown>
      )}
    </>
  );
}

type StyledButtonProps = Omit<ButtonProps, '$showIcon' | '$icon' | '$type' | '$linkTo' | '$isDropdown'>;

export const LDSBaseButtonStyle = styled(NavLink)<StyledButtonProps>`
  padding: ${(props) => (props.$size === 'XS' ? '2px 8px' : props.$size === 'SM' ? '5px 11px' : props.$size === 'MD' ? '8px 14px' : '10px 22px')};

  border-radius: ${(props) => (props.$isCircled ? '100' : VALUES.RADIUS)}px;

  -webkit-transition: all 200ms ${TRANSITIONS.BEZIER};
  transition: all 200ms ${TRANSITIONS.BEZIER};

  border-width: 1px;
  border-color: ${(props) => (props.$style !== 'OUTLINED' ? 'transparent' : !props.$primary ? COLORS.GRAY_03_DEEP : COLORS[props.$usage])};
  border-style: solid;
  outline: none;
  margin: 0 !important;
  position: relative;

  display: flex;
  gap: ${(props) => (props.$size === 'XS' ? '4px' : props.$size === 'SM' || props.$size === 'MD' ? '6px' : '8px')};
  justify-content: center;
  align-items: center;

  text-decoration: none;

  opacity: ${(props) => (props.$disabled ? '0.5' : '1')};
  cursor: ${(props) => (props.$disabled ? 'not-allowed !important' : 'auto')};

  background-color: ${(props) =>
    !props.$primary && props.$style === 'FILL' ? COLORS.GRAY_02_DEEP : props.$style !== 'FILL' ? 'transparent' : props.$primary && props.$style === 'FILL' ? COLORS[props.$usage] : 'transparent'};
  color: ${(props) => (!props.$primary ? COLORS.GRAY_06 : props.$primary && props.$style === 'FILL' ? COLORS.WHITE : COLORS[props.$usage])};
  width: ${(props) => (props.$width === 'FULL' ? '100%' : typeof props.$width === 'number' ? `${props.$width}px` : 'auto')};

  & > * {
    pointer-events: none;
  }

  & > svg {
    &:first-child {
      &:last-child {
        margin-right: ${(props) => !props.$label && (props.$size !== 'LG' ? '-5px' : !props.$label && props.$size === 'LG' ? '-14px' : null)};
        margin-left: ${(props) => !props.$label && (props.$size !== 'LG' ? '-5px' : !props.$label && props.$size === 'LG' ? '-14px' : null)};
      }
      &:not(:last-child) {
        margin-left: ${(props) => (props.$size === 'XS' ? '-1px' : props.$size === 'SM' ? '-1px' : props.$size === 'MD' ? '-2px' : '-4px')};
      }

      justify-content: center;

      -webkit-transform: translateY(${(props) => (props.$size === 'XS' ? '0' : '-0.5')}px);
      transform: translateY(${(props) => (props.$size === 'XS' ? '0' : '-0.5')}px);
      flex-shrink: 0;
      width: ${(props) => (props.$size === 'XS' ? '14px' : props.$size === 'SM' || props.$size === 'MD' ? '16px' : props.$size === 'LG' ? '18px' : '20px')};
      height: ${(props) => (props.$size === 'XS' ? '14px' : props.$size === 'SM' || props.$size === 'MD' ? '16px' : props.$size === 'LG' ? '18px' : '20px')};
    }
    &:last-child.dropdown-arrow {
      width: ${(props) => (props.$size === 'XS' || props.$size === 'SM' ? '12px' : '14px')};
      height: ${(props) => (props.$size === 'XS' || props.$size === 'SM' ? '12px' : '14px')};
      margin-left: -2px;
      margin-right: ${(props) => (props.$size === 'XS' || props.$size === 'SM' ? '-3px' : props.$size === 'MD' ? '-4px' : '-6px')};
      flex-shrink: 0;

      -webkit-transition: transform 200ms ${TRANSITIONS.BEZIER};
      transition: transform 200ms ${TRANSITIONS.BEZIER};
    }
  }

  &.selected {
    background-color: ${(props) => !props.$primary && COLORS.BLACK_008};
  }

  &.active {
    color: ${(props) => !props.$primary && COLORS.PRIMARY};
    background-color: ${(props) => !props.$primary && COLORS.BLACK_004};

    & > svg:last-child.dropdown-arrow {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  & > span,
  & > label {
    display: block;
    pointer-events: none;
    cursor: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: ${(props) => (!props.$primary ? COLORS.GRAY_03 : props.$primary && props.$style === 'FILL' ? COLORS[`${props.$usage}_DEEP`] : COLORS.BLACK_004)};
  }
  &:active {
    background-color: ${(props) => (props.$primary === true ? COLORS[`${props.$usage}_DEEP_MORE`] : COLORS.GRAY_03_DEEP)};
    background-color: ${(props) => (!props.$primary && props.$style === 'FILL' ? COLORS.GRAY_03_DEEP : props.$style !== 'FILL' ? COLORS.BLACK_008 : COLORS[`${props.$usage}_DEEP_MORE`])};
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  .lds-badge {
    position: absolute;
    top: -8px;
    right: -6px;
  }
`;
