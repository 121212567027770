import { Col, Row, Form } from 'reactstrap';
import { ISearchBoxProps } from 'hooks/grid/useSearch';
// redux
import { UserGroupSearchDTO } from 'interface/user';
import { InputD } from 'components/reactstrap/reactstrap';

export const SearchBox = (props: ISearchBoxProps<UserGroupSearchDTO>) => {
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };
  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">사용자 그룹 명</label>
          <InputD
            value={searchObj?.userGroupName || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                userGroupName: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">아이디</label>
          <InputD
            value={searchObj?.userId || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                userId: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">이름</label>
          <InputD
            value={searchObj?.userName || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                userName: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">연락처</label>
          <InputD
            value={searchObj?.userPhone || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                userPhone: e.target.value,
              });
            }}
          />
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
      </Row>
    </Form>
  );
};
