import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// component
import Tab1 from './driverList';

// options
import { RootState } from 'redux/store';
import { InvoiceDetailSearchDTO } from 'interface/order';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER, RAWDATA } from 'redux/services/menuSlice';
export interface IDetailObjProps {
  visible: boolean;
  item?: InvoiceDetailSearchDTO;
}

export const TABID = 'DRIVER_LIST';

export const DRIVER_LIST_MASTER = [
  `${RAWDATA}DRIVER_WHOLE`,
  `${RAWDATA}PROVINCE`,
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  'DRIVER_PHONE_WHOLE', // 라벨용이 많으니
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'CENTER_CALL_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_NETWORK_AUTH',
  'SELLER_WHOLE',
  'INFRA',
  'SALES_CHANNEL',
  //
  'DRV_POS',
  'CAR_TYPE',
  'DRV_TYPE',
  'MODEL_GROUP',
  'TEAM_TYPE',
  'DRIVER_PARTY',
  'TEAM_GRADE',
  'UNIFORM_SIZE',
  'AM',
  'PM',
  'WEEK_DAY',
  'USE_FLAG',
  'PROVINCE',
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(DRIVER_LIST_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            //
            DRIVER_LIST: <Tab1 tabId={'DRIVER_LIST'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
