import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Form } from 'reactstrap';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

//
import { CREATE_FC_PICKING_UPLOAD, INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION } from 'envVar';
// lib
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
//component
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import Grid from '../../../../components/grid/auiGrid';
import { httpClient } from 'common/http-client/axiosConfig';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { setLoading, RAWDATA } from 'redux/services/menuSlice';
import { getLabelNvalueOptions } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';

const Presenter = (props) => {
  const { searchModalObj, setSearchModalObj, gridId, setRows } = props;

  return (
    <>
      <div className="page-content">
        {searchModalObj?.visible && <SearchModal searchModalObj={searchModalObj} setSearchModalObj={setSearchModalObj} mainGridId={gridId} setMainRows={setRows} />}
        <div className="presenterInput">
          <BasicInfo {...props} />
        </div>
        <div className="presenterInput">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const menuOptions = [
  { label: '바코드', value: 'barcode' },
  { label: 'SKU', value: 'modelStockSeq' },
  { label: '외부SKU', value: 'skuNumber' },
  { label: '제품', value: 'model' },
  { label: '제품명', value: 'modelName' },
];

export const SearchModal = ({ searchModalObj, setSearchModalObj, mainGridId, setMainRows }) => {
  const dispatch = useDispatch();
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state) => state.menu);
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState();

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      const rows = window.AUIGrid.getGridData(`#${mainGridId}`);
      if (getCheckedDataTriggerThisGrid?.type === 'add-items-model') {
        let validFlag = true;
        items.forEach((row) => {
          if (row.quantityInput > row.usableAvailableQuantity) validFlag = false;
        });

        if (validFlag) {
          let doubled = [];
          if (rows?.length > 0) {
            items?.forEach((item, idx) => {
              rows?.forEach((row) => {
                if (row.locationCode === item.locationCode && row.modelStockSeq === item.modelStockSeq) {
                  if (!doubled[idx] || doubled[idx] === 'false') doubled[idx] = 'true';
                } else {
                  if (!doubled[idx]) doubled[idx] = 'false';
                }
              });
            });
            if (doubled?.filter((ele) => ele === 'false')?.length > 0) {
              alert(`${doubled?.filter((ele) => ele === 'true')?.length > 0 ? '중복을 제외하고' : ''} 추가되었습니다`);
              const letsAdd = items.filter((ele, idx) => doubled[idx] === 'false');

              setMainRows(rows?.concat(letsAdd));
            } else {
              alert('중복 제외 추가건이 없습니다!');
            }
          } else {
            alert('추가되었습니다!');
            setMainRows(items);
          }
        } else {
          alert('이동가능양품보다 대상수량이 많은 추가건이 있습니다! 수량을 확인해주세요');
        }
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const [searchObj, setSearchObj] = useState();
  const [gridId, _] = useState(`trunkRegister_searhModal${uuidv4()}`);
  const [rows, setRows] = useState();

  const modelColumn = [
    {
      headerText: '출고창고',
      dataField: 'centerCodeKr',
      editable: false,
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          editable: false,
        },
        {
          headerText: '제품타입',
          dataField: 'modelTypeKr',
          editable: false,
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          editable: false,
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          editable: false,
        },
        {
          headerText: '제품',
          dataField: 'model',
          editable: false,
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          editable: false,
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          editable: false,
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          editable: false,
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          editable: false,
        },
      ],
    },
    {
      headerText: '현재보관수량',
      children: [
        {
          headerText: '양품',
          dataField: 'totalAvailableQuantity',
          editable: false,
        },
        {
          headerText: '이동가능양품',
          dataField: 'usableAvailableQuantity',
          editable: false,
        },
      ],
    },
  ];

  useEffect(() => {
    if (searchModalObj?.createObj) {
      setSearchObj((prev) => {
        return {
          ...prev,
          centerCode: searchModalObj?.createObj?.centerCode,
        };
      });
    }
  }, []);

  const fetchList = async (searchObj) => {
    //
    dispatch(setLoading('GET'));
    let url;
    url = INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION + '?';
    if (searchObj?.centerCode) url += `&centerCode=${searchObj?.centerCode}`;
    if (searchObj?.modelGroup) url += `&modelGroup=${searchObj?.modelGroup}`;
    if (searchObj?.modelType) url += `&modelType=${searchObj?.modelType}`;
    if (searchObj?.optionValue && searchObj?.OptionItem) url += `&${searchObj?.OptionItem}=${searchObj?.optionValue}`;

    await httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        const data = rs.data;
        if (searchModalObj?.type === 'gansun') {
        } else {
          data?.forEach((row) => {
            row.partnerSeqKr = MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq];
            row.centerCodeKr = MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode];
            row.modelTypeKr = MASTER_OBJ?.MODEL_TYPE?.[row?.modelType];
            row.modelGroupKr = MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup];
            row.modelGroupLargeKr = MASTER_OBJ?.MODEL_GRP_1?.[row?.modelGroupLarge];
            row.modelGroupMediumKr = MASTER_OBJ?.MODEL_GRP_2?.[row?.modelGroupMedium];
            row.modelGroupSmallKr = MASTER_OBJ?.MODEL_GRP_3?.[row?.modelGroupSmall];
            //
            row.availableQuantityInput = 0;
            row.defectedQuantityInput = 0;
            //
            row.importAvailableQuantity = 0;
            row.importDefectedQuantity = 0;
            row.usableAvailableQuantity = row.availableQuantity;
            row.usableDefectedQuantity = row.defectedQuantity;
            row.sumAvailableQuantity = row.availableQuantity;
            row.sumDefectedQuantity = row.defectedQuantity;
            // row.totalDefectedQuantity = 0;
            // row.totalAvailableQuantity = 0;
          });
          console.log(data);
          setRows(data);
        }
      }
    });
    dispatch(setLoading(null));
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <CommonModalNew
      style={{ width: 1300 }}
      title={'안전재고 제품추가'}
      visible={searchModalObj?.visible}
      setVisible={() => {
        setSearchModalObj((prev) => {
          return {
            ...prev,
            visible: false,
            type: null,
          };
        });
      }}
      children={
        <>
          <Form onKeyPress={onKeyPress}>
            <Row>
              <Col>
                <label className="col-form-label">제품타입</label>
                <SelectD
                  options={MASTER_OPS?.MODEL_TYPE}
                  value={
                    searchObj?.modelType
                      ? {
                          value: searchObj?.modelType,
                          label: MASTER_OBJ?.MODEL_TYPE?.[searchObj?.modelType],
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      modelType: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">제품그룹</label>
                <SelectD
                  options={MASTER_OPS?.MODEL_GROUP}
                  value={
                    searchObj?.modelGroup
                      ? {
                          value: searchObj?.modelGroup,
                          label: MASTER_OBJ?.MODEL_GROUP?.[searchObj?.modelGroup],
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      modelGroup: value,
                    });
                  }}
                />
              </Col>
              <Col lg={4}>
                <label className="col-form-label">제품/바코드</label>
                <div style={{ display: 'flex' }}>
                  <Col md={6} style={{ display: 'inline-block' }}>
                    <SelectD
                      value={
                        searchObj?.OptionItem
                          ? {
                              value: searchObj?.OptionItem,
                              label: selectlabel(searchObj?.OptionItem, menuOptions),
                            }
                          : null
                      }
                      onChange={({ value }) => {
                        setSearchObj({
                          ...searchObj,
                          OptionItem: value,
                          optionValue: value === null ? null : searchObj?.optionValue,
                        });
                      }}
                      options={menuOptions}
                    />
                  </Col>
                  <Col md={6} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!searchObj?.OptionItem}
                      value={searchObj?.optionValue || ''}
                      onChange={(e) => {
                        setSearchObj({
                          ...searchObj,
                          optionValue: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col style={{ textAlign: 'left' }}>
                <div>
                  <button
                    className="btn btn-search"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchList(searchObj);
                    }}
                  >
                    조회
                  </button>
                  <button
                    className="btn btn-secondary sm"
                    onClick={(e) => {
                      e.preventDefault();
                      setGetCheckedDataTriggerThisGrid({
                        type: 'add-items-model',
                        id: `#${gridId}`,
                      });
                    }}
                  >
                    추가
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
          {gridId && (
            <Grid
              id={gridId}
              rows={rows}
              columns={modelColumn}
              getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
              setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
              settingOptions={{
                isRowAllCheckCurrentView: true,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
            />
          )}
        </>
      }
    />
  );
};

const BasicInfo = (props) => {
  const { createObj, setCreateObj, createPicking } = props;
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state) => state.menu);
  console.log(MASTER_OPS);
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="presenterInput mb-4">1. 기본정보</div>
        <Button
          className="btn-confirm btn"
          onDoubleClick={() => {
            return;
          }}
          onClick={() => {
            createPicking(createObj);
          }}
        >
          FC피킹 생성
        </Button>
      </div>
      <>
        <Row style={{ marginLeft: 10 }}>
          <Col>
            <label className="col-form-label">*출고 예정일</label>
            <FlatpickrD
              options={{ mode: 'single' }}
              value={createObj?.loanPickDate}
              onChange={(value) => {
                setCreateObj({
                  ...createObj,
                  loanPickDate: returnDateyyyymmdd(value),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">*출고창고</label>
            <SelectD
              value={
                createObj?.centerCode
                  ? {
                      value: createObj?.centerCode,
                      label: MASTER_OBJ?.CENTER_WHOLE?.[createObj?.centerCode],
                    }
                  : null
              }
              options={MASTER_OPS?.CENTER_WHOLE} //CENTER_FC
              onChange={({ value }) => {
                setCreateObj((prev) => {
                  return {
                    ...prev,
                    centerCode: value,
                  };
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">기사</label>
            <div style={{ display: 'flex' }}>
              <Col md={6} style={{ display: 'inline-block' }}>
                <SelectD
                  options={getLabelNvalueOptions(
                    MASTER_OPS?.[`${RAWDATA}DRIVER_AUTH`]?.filter((ele) => ele.centerCode === createObj?.centerCode),
                    'driverName',
                    'userId',
                  )}
                  value={
                    createObj?.userId
                      ? {
                          label: MASTER_OBJ?.DRIVER_AUTH?.[createObj?.userId],
                          value: createObj?.userId,
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    setCreateObj((prev) => {
                      return {
                        ...prev,
                        userId: value,
                      };
                    });
                  }}
                />
              </Col>
              <Col md={6} style={{ display: 'inline-block' }}>
                <InputD disabled value={createObj?.userId || ''} placeholder="기사를 선택하세요" />
              </Col>
            </div>
          </Col>
          <Col />
          <Col />
          <Col />
        </Row>
      </>
    </>
  );
};

const GridBox = (props) => {
  const {
    gridId,
    columns = [],
    rows = [],
    //
    getCheckedDataTriggerThisGrid,
    setGetCheckedDataTriggerThisGrid,
    fileSelector,
    //
    createObj,
    setSearchModalObj,
  } = props;

  const excelRef = useRef();

  return (
    <>
      <div className="presenterInput mb-2">2. 피킹상세 정보</div>
      <div className="grid-button-area space-between">
        <div style={{ border: 0 }}>
          <div
            className="red"
            onClick={() =>
              setGetCheckedDataTriggerThisGrid({
                type: 'selectedRows-deleted',
                id: `#${gridId}`,
                withRowIndex: true,
              })
            }
          >
            선택행 삭제
          </div>
          <div
            className="green"
            onClick={() => {
              window.open(CREATE_FC_PICKING_UPLOAD);
            }}
          >
            엑셀양식
          </div>
          <div className="green" onClick={() => (createObj?.centerCode ? excelRef?.current.click() : alert('출고창고를 먼저 선택해주세요!'))}>
            <input
              ref={excelRef}
              style={{ display: 'none' }}
              onChange={(e) => {
                fileSelector(e);
              }}
              id="excelRef"
              accept=".xlsx"
              type="file"
            />
            + 엑셀등록
          </div>
          <div
            className="orange"
            onClick={() => {
              createObj?.centerCode
                ? setSearchModalObj((prev) => {
                    return {
                      ...prev,
                      visible: true,
                      createObj,
                    };
                  })
                : alert('출고창고를 선택해주세요');
            }}
          >
            + 안전재고 제품추가
          </div>
        </div>
        <div className="notBtn">
          <div>
            제품 수량 : 총 &nbsp; <span>{rows?.length}</span> 개
          </div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        {gridId && (
          <Grid
            id={gridId}
            rows={rows}
            columns={columns}
            settingOptions={{
              isRowAllCheckCurrentView: true,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
            }}
            getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
            setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
          />
        )}
      </div>
    </>
  );
};
