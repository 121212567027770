import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { UserGridDTO, UserGridParameterDTO } from 'interface/user';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import GridBox, { IGridPageRowSelectValues, defaultGridProps } from './gridBox';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import unfav from 'assets/images/button/star-regular.svg';
import fav from 'assets/images/button/star-solid.png';
// auigrid
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';
import { VisibleModal } from 'components/modal/visibleModal';
import { httpClient } from 'common/http-client/axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { COMMON_GRIDID, setLoading } from 'redux/services/menuSlice';
import { userService } from 'pages/ETC/3user/_service/service';

export interface IColumnManagerObj {
  visible?: boolean;
  gridId?: string;
  returns?: UserGridDTOEx;
  mappingFunction?: object;
  defaultFixedColumnCountObj?: defaultFixedColumnCountObjType;
}

export type defaultFixedColumnCountObjType = {
  [key: string]: number;
};

export interface UserGridDTOEx extends UserGridDTO {
  columns?: IGrid.Column[];
}

const mappingToGridColumn = (dataArr: UserGridParameterDTO[], mappingFunction) => {
  if (dataArr?.length > 0) {
    const keys = Object.keys(mappingFunction);
    const rows = dataArr?.map((row) => {
      return {
        headerText: row.parameterName + (row.parameterNameCustom ? `(${row.parameterNameCustom || ''})` : ''),
        dataField: row.parameter,
        filter: {
          showIcon: row.parameter?.includes('show') ? false : true,
        },
        _$sort: row.sort,
        _$parent: row.parentParameter,

        // mapping functions: columnManagerObj?.mappingFunction
        [keys?.includes(row.parameter) ? mappingFunction?.[row.parameter]['type'] : '_$']: keys?.includes(row.parameter) ? mappingFunction?.[row.parameter]['mapValue'] : null,
        [keys?.includes(row.parameter) && mappingFunction?.[row.parameter]['width'] ? 'width' : '_$']: mappingFunction?.[row.parameter]?.['width'],
        [keys?.includes(row.parameter) && mappingFunction?.[row.parameter]['dataType'] ? 'dataType' : '_$']: mappingFunction?.[row.parameter]?.['dataType'],
        [keys?.includes(row.parameter) && mappingFunction?.[row.parameter]['formatString'] ? 'formatString' : '_$']: mappingFunction?.[row.parameter]?.['formatString'],
      };
    });
    return rows;
  }
  return [];
};

export const gridParamToGridColumn = (gridParameters: UserGridParameterDTO[], mappingFunction) => {
  if (gridParameters?.length > 0) {
    const pparentArr = mappingToGridColumn(
      gridParameters?.filter((ele) => !ele.parentParameter && ele.useFlag),
      mappingFunction,
    ); // 최상부모
    const parentArr = mappingToGridColumn(
      gridParameters?.filter((ele) => ele.level === 2 && ele.useFlag),
      mappingFunction,
    );
    const lastChildArr = mappingToGridColumn(
      gridParameters?.filter((ele) => ele.level === 3 && ele.useFlag),
      mappingFunction,
    );
    if (lastChildArr?.length > 0) {
      parentArr?.map((parent) => {
        return {
          ...parent,
          children: lastChildArr?.filter((ele) => ele._$parent === parent.dataField),
        };
      });
    }
    const tree = pparentArr?.map((parent) => {
      if (parentArr?.filter((ele) => ele._$parent === parent.dataField)?.length > 0)
        return {
          ...parent,
          children: parentArr?.filter((ele) => ele._$parent === parent.dataField),
        };
      else return parent;
    });
    return tree;
  }
  return [];
};

export const ColumnManager = ({ columnManagerObj, setColumnManagerObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const gridRef = useRef<AUIGrid>();
  const [columnObj, setColumnObj] = useState<UserGridDTO>();

  useEffect(() => {
    if (columnManagerObj?.visible) {
      setPopupInfo();
    }
  }, [columnManagerObj?.visible]);

  const letsConclusion = () => {
    const tree = gridRef.current.getTreeGridData();
    const useFlags = gridRef.current.getCheckedRowItemsAll()?.map((ele) => ele.parameter);
    const flats = [];
    tree?.forEach((pparent, ppi) => {
      pparent.sort = ppi + 1;
      pparent.parentParameter = null;
      pparent.level = 1;
      pparent.useFlag = useFlags.includes(pparent.parameter) ? true : false;
      flats.push(pparent);
      pparent.children?.forEach((parent, pi) => {
        parent.sort = pi + 1;
        parent.parentParameter = pparent.parameter;
        parent.level = 2;
        parent.useFlag = useFlags.includes(parent.parameter) ? true : false;
        flats.push(parent);
        parent.children?.forEach((child, i) => {
          child.sort = i + 1;
          child.level = 3;
          child.parentParameter = parent.parameter;
          child.useFlag = useFlags.includes(child.parameter) ? true : false;
          flats.push(child);
        });
      });
    });
    return flats;
  };

  const saveCustomGridColumn = async () => {
    dispatch(setLoading('GET'));
    const userGridParameters = letsConclusion();
    const dataDTO = {
      ...columnObj,
      columns: null,
      userGridParameters,
    };
    const rs = await httpClient.post('/user/my/grid/parameter', dataDTO);
    if (rs?.status === 200) {
      alert('커스텀 그리드 설정이 저장되었습니다');
      window.location.reload();
    }
    dispatch(setLoading(null));
  };

  const setPopupInfo = () => {
    gridRef.current.setGridData(gridParamToTree(MASTER_OBJ?.[columnManagerObj?.gridId]?.userGridParameters));
    gridRef.current.setCheckedRowsByValue('useFlag', true);
    setColumnObj({
      ...MASTER_OBJ?.[columnManagerObj?.gridId],
      fixedColumn: MASTER_OBJ?.[columnManagerObj?.gridId]?.fixedColumn ?? columnManagerObj?.defaultFixedColumnCountObj?.[columnManagerObj?.gridId],
    });
    // bind event
    gridRef?.current?.bind('rowCheckClick', function (e) {
      const item = e.item;
      if (item._$leafCount > 0) {
        if (e.checked) {
          gridRef.current.addCheckedRowsByValue('parentParameter', e.item.parameter);
        } else {
          gridRef.current.addUncheckedRowsByValue('parentParameter', e.item.parameter);
        }
      }
    });
    gridRef?.current?.bind('dropEndBefore', function (e) {
      const flat = gridRef.current.getGridData();
      const item = e.items[0];
      // 자식
      if (item.parentParameter !== flat[e.toRowIndex].parentParameter) {
        alert('같은 층위에서만 움직이실 수 있습니다');
        return false;
      }
      return true;
    });
  };

  const gridParamToTree = (gridParameters) => {
    if (gridParameters?.length > 0) {
      const pparentArr = gridParameters?.filter((ele) => !ele.parentParameter); // 최상부모
      const parentArr = gridParameters?.filter((ele) => ele.level === 2);
      const lastChildArr = gridParameters?.filter((ele) => ele.level === 3);
      if (lastChildArr?.length > 0) {
        parentArr?.forEach((parent) => {
          if (lastChildArr?.filter((ele) => ele.parentParameter === parent.parameter)?.length > 0) parent.children = lastChildArr?.filter((ele) => ele.parentParameter === parent.parameter);
        });
      }
      const tree = pparentArr?.map((parent) => {
        if (parentArr?.filter((ele) => ele.parentParameter === parent.parameter)?.length > 0)
          return {
            ...parent,
            children: parentArr?.filter((ele) => ele.parentParameter === parent.parameter),
          };
        else return parent;
      });
      return tree;
    }
    return [];
  };

  const resetColumns = async () => {
    const gridId = columnManagerObj?.gridId?.split(COMMON_GRIDID)?.[1];
    const data = await userService.getGridDetail(gridId);
    if (data) {
      const parameters = data?.gridParameters?.map((row) => {
        return {
          ...row,
          useFlag: true,
        };
      });
      setColumnObj((prev) => {
        return {
          ...prev,
          //NOTE: 초기화 버튼 클릭 시 defaultFixedColumnCountObj에 할당된 값으로 고정 컬럼을 할당
          fixedColumn: columnManagerObj?.defaultFixedColumnCountObj?.[gridId],
        };
      });
      gridRef.current.setGridData(gridParamToTree(parameters));
    }
  };
  // 마스터맵핑
  // mappingFunction: 불러온 column에 function맵핑
  const columns: IGrid.Column[] = [
    {
      headerText: '기존변수명',
      dataField: 'parameterName',
      editable: false,
    },
    {
      headerText: '커스텀변수명',
      dataField: 'parameterNameCustom',
    },
    {
      headerText: '변수',
      dataField: 'parameter',
    },
  ];
  const dragParentRef = useRef<HTMLDivElement>();
  return (
    <div style={{ left: -20, top: -20, position: 'absolute', zIndex: 2 }} ref={dragParentRef}>
      <span>
        <img
          style={{ width: 16 }}
          alt="칼럼즐겨찾기"
          src={columnManagerObj?.visible ? fav : unfav}
          onClick={() =>
            setColumnManagerObj((prev) => {
              return {
                ...prev,
                visible: !columnManagerObj?.visible,
              };
            })
          }
        />
      </span>
      <VisibleModal
        dragParentRef={dragParentRef}
        style={{ minWidth: 600 }}
        title="그리드 관리"
        visible={columnManagerObj?.visible}
        setVisible={() => {
          setColumnManagerObj((prev) => {
            return {
              ...prev,
              visible: false,
            };
          });
        }}
        children={
          <div style={{ height: 400 }}>
            <Row>
              <Col>
                <label className="col-form-label">*고정칼럼</label>
                <InputD
                  type="number"
                  value={columnObj?.fixedColumn || ''}
                  onChange={(e) => {
                    setColumnObj({
                      ...columnObj,
                      fixedColumn: Number(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col>
                <label className="col-form-label">*페이지크기</label>{' '}
                <SelectD
                  options={IGridPageRowSelectValues?.map((ele) => {
                    return {
                      label: ele,
                      value: ele,
                    };
                  })}
                  value={
                    columnObj?.pageSize
                      ? {
                          label: columnObj?.pageSize,
                          value: columnObj?.pageSize,
                        }
                      : null
                  }
                  onChange={(option) => {
                    setColumnObj({
                      ...columnObj,
                      pageSize: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col lg={6} style={{ textAlign: 'right' }}>
                <button
                  className="btn btn-search ssm"
                  onClick={(e) => {
                    resetColumns();
                  }}
                >
                  초기값으로
                </button>
                <button
                  className="btn btn-blue ssm"
                  onClick={(e) => {
                    saveCustomGridColumn();
                  }}
                >
                  저장
                </button>
              </Col>
            </Row>
            <div className="presenterGridBox">
              <GridBox
                //
                gridRef={gridRef}
                columns={reduxUserInfo?.userGroup === 'ADMIN' ? columns : columns.filter((ele) => ele.dataField !== 'parameter')}
                gridProps={{
                  //
                  ...defaultGridProps,
                  editable: true,
                  height: 360,

                  showRowAllCheckBox: true,
                  showRowCheckColumn: true,
                  // rowCheckDependingTree: true,

                  //
                  enableDrag: true,
                  enableDragByCellDrag: true,
                  enableDrop: true,
                }}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};
