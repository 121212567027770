import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// component
import Container1 from './1groups';
import Container2 from './2menuNscreen';
import Container3 from './3tabsNfunctions';

// options
import { RootState } from 'redux/store';
import { nullingMASTER } from 'redux/services/menuSlice';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { TopTabController } from 'components/template/TopTabController';
import { SETTINGSLIST_MASTER } from '../listTabs';

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(SETTINGSLIST_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            SETTINGS_OLD_GROUPS: <Container1 />,
            SETTINGS_MENU_SCREENS: <Container2 />,
            SETTINGS_TAB_FUNCTIONS: <Container3 />,
          }}
        />
      )}
    </>
  );
};

export default Index;
