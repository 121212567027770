import styled from 'styled-components';
import { InvoiceListDTOKr, Title } from '..';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// img
import delivery from 'common/navermaps/flags/delivery.svg';
import visit from 'common/navermaps/flags/visit.svg';
import collect from 'common/navermaps/flags/collect.svg';
import exchange from 'common/navermaps/flags/exchange.svg';
import move from 'common/navermaps/flags/move.svg';
import store from 'common/navermaps/flags/store.svg';
import as from 'common/navermaps/flags/as.svg';
import fix from 'common/navermaps/flags/fix.svg';
import phonePng from '../data/phone.png';
import noInvenPng from '../img/noInven.png';

import { OptionItem, getTimeZone } from 'common/master/codeMasterReturnHelper';
import { IColoredData, coloringData } from 'common/util/invoice';
import { REGION_NUMBERS } from 'common/data/regionNumber';
import { returnFeeTypes } from 'common/util/orderTypes';
import { httpClient } from 'common/http-client/axiosConfig';
import { SCHEDULE_CHANGE_DETAIL_LIST } from 'envVar';
import { DetailPopup } from 'pages/ETC/1driver/driverListTab/driverList/component/detailPopup';
import { returnDDay } from 'common/util/counting';
import { IMultiCells, MultiCells } from './multiCells';
import { setLoading } from 'redux/services/menuSlice';
import { InvoiceDetailScheduleUpdateDTO } from 'interface/order';
import { IMapObj, Map } from './map';
import { OrderDetail } from 'pages/ORDER/1order/orderListTab/common/component/invocieDetail/orderDetail';

const HasDriverArea = styled.div`
  position: relative;
  padding: 20px;
  border: 1px solid #cccccc;
  width: 70%;
  min-height: 100%;
`;

const DisplayArea = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const TableArea = styled.div`
  //
  display: flex;
`;

export const HasDriver = ({ hasDriverObj }) => {
  const { MASTER_OBJ, masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const driversRef = useRef<null[] | AUIGrid[]>([]);
  const fixedRef = useRef<AUIGrid>();
  const timezoneArr = getTimeZone(false)?.map((ele) => ele.value);
  const [coloredData, setColoredData] = useState<IColoredData>();
  const [driverDetailObj, setDriverDetailObj] = useState({
    visible: false,
    data: null,
  });
  const [orderDetailObj, setOrderDetailObj] = useState<{ item?: any; visible?: boolean }>({
    visible: false,
  });
  const [columns, setColumns] = useState<IGrid.Column[]>();
  const [userData, setUserData] = useState<{ users: string[]; data: any[]; fixed: any[] }>();
  const [multiCellsObj, setMultiCellsObj] = useState<IMultiCells>();
  const [mapObj, setMapObj] = useState<IMapObj>();
  const [driverList, setDriverList] = useState<any>();

  useEffect(() => {
    if (hasDriverObj?.data) {
      const drivers = hasDriverObj?.data ? hasDriverObj?.data?.map((ele) => ele.userId)?.filter((ele) => ele) : [];
      if (drivers?.length > 0) {
        const driverList = [...masterOptions?.DRIVER_FILTERED];
        driverList?.sort((a: OptionItem, b: OptionItem) => {
          if (drivers?.includes(a.value) && !drivers?.includes(b.value)) return -1;
        });
        setDriverList(driverList);
      }
    }
  }, [hasDriverObj]);

  useEffect(() => {
    if (driverList?.length > 0) {
      makeData(hasDriverObj?.data);
    }
  }, [driverList]);

  const makeData = (data: InvoiceListDTOKr[]) => {
    const coloredData = coloringData(data);
    const columns: IGrid.Column[] = [
      {
        dataField: 'fixed0',
        headerText: '설치기사',
        width: 100,
      },
    ];

    driverList?.forEach((ele) => {
      columns.push({
        dataField: ele.value,
        headerText: ele.label,
        style: 'schedule-cell',
        width: 170,
        renderer: {
          type: IGrid.RendererKind.TemplateRenderer,
        },
        labelFunction: function (rowIndex, columnIndex, value, headerText, item, dataField) {
          if (rowIndex === 0) {
            return `<div class="clickable"> ${value}</div>`;
          } else if (rowIndex === 1 || rowIndex === 2) {
            const data = item[dataField + '_ele'];

            if (data?.length) {
              let div = `<div class="has-schedule ${data?.invoiceStatus?.includes('FINISH') ? 'done' : ''} ${returnFeeTypes(data?.feeType)}">`;
              div += data?.length + '건';
              div += '</div>';
              return div;
            }
            return '';
          } else {
            let div = `<div><span class="time-zone">${item.headerText}</span>`;
            if (item[dataField + '_ele']) {
              const datas = item[dataField + '_ele'];
              const data = datas[0];
              div += `<div class="has-schedule ${returnFeeTypes(data?.feeType)} ${data?.invoiceStatus?.includes('FINISH') ? 'done' : ''} ${data?.confirmFlag ? 'confirm' : ''}">`;
              if (REGION_NUMBERS?.includes(data?.receiverPhone?.substr(0, 3))) {
                div += `<span class="phone"><img src=${phonePng} alt="수령자의 등록된 연락처가 지역번호입니다" /></span>`;
              }
              if (data?.invoiceStatus === 'NOT_IN_INVENTORY') div += `<span class="noInvnent"><img src=${noInvenPng} alt="" /></span>`;

              div += `<span class="labeling masterProduct" title="${data?.productName}">${data?.productName}</span>`;
              if (data?.predictDeliveryDate) div += `<span class="labeling predictDay">${data?.predictDeliveryDate}</span>`;
              if (data?.revisitFlag) div += `<span class="labeling revisit" title="재방문">재방문</span>`;
              if (datas?.length > 1) div += `<span class="labeling over1">${datas?.length}건</span>`;
              if (data.promiseDeliveryDate) div += `<span class="labeling dday">${returnDDay(data.deliveryDate, data.promiseDeliveryDate)}</span>`;

              div += '<div>';
              div += data?.receiverName;
              div += '</div>';
              div += '<div>';
              div += data?.city;
              div += '</div>';
              div += '<div>';
              div += data?.modelGroupKr || '가구';
              div += '</div>';
              div += '</div>';
            }
            div += '</div>';
            return div;
          }
        },
      });
    });

    setColoredData(coloredData);
    setColumns(columns);
  };

  useEffect(() => {
    if (columns) {
      makeRows(hasDriverObj?.data);
    }
  }, [columns]);

  const makeRows = (data: InvoiceListDTOKr[]) => {
    const users = driverList?.map((ele: OptionItem) => ele.value);
    const usersData = [];
    // fixed
    const fixed = [{ fixed: '배송경로' }, { fixed: '날짜 미지정' }, { fixed: '시간 미지정' }];
    timezoneArr.forEach((ele: string) => {
      fixed.push({ fixed: ele });
    });

    //users
    users.forEach((user: string, i: number) => {
      const datas = data.filter((ele) => ele.userId === user);
      usersData.push([
        // rows
        { [user]: '보기' },
        { [`${user}_ele`]: datas.filter((ele) => !ele.deliveryDate) },
        { [`${user}_ele`]: datas.filter((ele) => ele.deliveryDate && !ele.deliveryDatetime) },
      ]);
      timezoneArr.forEach((ele: string) => {
        usersData[i].push({ [user]: ele });
      });

      const hasTimes = datas.filter((ele: InvoiceListDTOKr) => ele.deliveryDatetime);
      hasTimes?.forEach((elem: InvoiceListDTOKr) => {
        const hm = elem.deliveryDatetime?.split(' ')[1].slice(0, 5);
        const index = timezoneArr.indexOf(hm) + 3;
        usersData[i][index][`${user}_ele`] ? usersData[i][index][`${user}_ele`].push(elem) : (usersData[i][index][`${user}_ele`] = [elem]);
      });
    });
    setUserData({ users, data: usersData, fixed });
  };

  const popupDriverDetail = async (userId: string) => {
    setDriverDetailObj((prev) => {
      return {
        ...prev,
        visible: true,
        item: { userId: userId },
      };
    });
  };

  const dragSchedule = (e) => {
    const fromGrid = e.pid.split('_');
    const toGrid = e.pidToDrop.split('_');
    let toRowIdx = e.toRowIndex - 4;
    const item = e.items[0];
    if (toRowIdx > -4 && (item?.[`${fromGrid[2]}_ele`]?.length > 0 || fromGrid.length === 1)) {
      if (window.confirm('스케줄을 변경하시겠습니까?')) {
        const data = {
          invoiceSeqs: fromGrid?.length > 1 ? item?.[`${fromGrid[2]}_ele`]?.map((ele) => ele.invoiceSeq) : [item.invoiceSeq],
          userId: toGrid[2],
          deliveryDate: toRowIdx === -3 ? null : hasDriverObj?.searchObj?.deliveryDate,
          deliveryTime: toRowIdx === -3 || toRowIdx === -2 ? null : timezoneArr[e.direction ? toRowIdx : toRowIdx + 1],
        };
        //
        changeScheduleAPI(data);
      }
    }
  };

  const changeScheduleAPI = async (data: InvoiceDetailScheduleUpdateDTO) => {
    dispatch(setLoading('POST'));
    await httpClient.post(SCHEDULE_CHANGE_DETAIL_LIST, data).then((rs) => {
      if (rs?.status === 200) {
        alert(rs?.data?.message);
        hasDriverObj?.fetchFn(hasDriverObj?.searchObj);
        setMultiCellsObj(null);
      }
    });
    dispatch(setLoading(null));
  };

  const openMap = (e) => {
    const idx = e.pid.split('_')[1];
    const data = [];
    userData.data[idx]?.forEach((obj, i) => {
      if (i > 2)
        Object.keys(obj)?.forEach((key) => {
          if (key.includes('_ele')) {
            obj[key].forEach((ele) => {
              data.push(ele);
            });
          }
        });
    });
    if (data?.length > 0) {
      setMapObj({
        title: `[${MASTER_OBJ?.DRIVER_WHOLE?.[e.pid.split('_')[2]]}] ${hasDriverObj?.searchObj?.deliveryDate} 일정`,
        visible: true,
        data,
      });
    } else {
      alert('표현할 배송정보가 없습니다!');
    }
  };

  useEffect(() => {
    if (userData && driversRef.current) {
      fixedRef.current.setGridData(userData?.fixed);
      driversRef.current.forEach((ref, idx) => {
        (ref as AUIGrid)?.setGridData(userData?.data?.[idx]);
        (ref as AUIGrid)?.bind('headerClick', function (e) {
          popupDriverDetail(e.dataField);
        });
        (ref as AUIGrid)?.bind('cellClick', function (e) {
          if (e.rowIndex === 0) {
            openMap(e);
          } else {
            const hasData = e.value ? e.item[e.dataField + '_ele'] : [];
            if (hasData.length > 0) {
              if (hasData.length > 1) {
                setMultiCellsObj({
                  e,
                  mode: e?.rowIndex === 1 ? 'noDate' : e?.rowIndex === 2 ? 'noTime' : null,
                  visible: true,
                  items: hasData,
                });
              } else {
                setOrderDetailObj({
                  visible: true,
                  item: hasData[0],
                });
              }
            }
          }
        });
        (ref as AUIGrid)?.bind('dragBegin', function (e) {
          // draging text
          return '';
        });
        (ref as AUIGrid)?.bind('dropEndBefore', function (e) {
          dragSchedule(e);
          return false;
        });
      });
    }
  }, [userData, driversRef.current]);

  const gridProps = {
    showRowAllCheckBox: false,
    showRowCheckColumn: false,
    showRowNumColumn: false,
    rowHeight: 115,
    pagingPanelHeight: 0,
    usePaging: false,
    autoGridHeight: true,
    scrollHeight: 0,
    scrollThumbHeight: 0,
    enableDragByCellDrag: true,
    enableDrag: true,
    enableDrop: true,
    enableSorting: false,
    dropToOthers: true,
    enableFilter: false,
    enableMultipleSorting: false,
  };

  const dragParentRef = useRef<HTMLDivElement>();
  return (
    <HasDriverArea ref={dragParentRef}>
      {mapObj?.visible && <Map mapObj={mapObj} setMapObj={setMapObj} dragParentRef={dragParentRef} />}
      {multiCellsObj?.visible && <MultiCells multiCellsObj={multiCellsObj} setMultiCellsObj={setMultiCellsObj} dragSchedule={dragSchedule} dragParentRef={dragParentRef} />}
      {orderDetailObj?.visible && <OrderDetail orderDetailObj={orderDetailObj} setOrderDetailObj={setOrderDetailObj} />}
      {driverDetailObj?.visible && <DetailPopup detailObj={driverDetailObj} setDetailObj={setDriverDetailObj} />}
      <Title>기사 지정 주문</Title>
      <DisplayArea>
        <div className="status-panel">
          <label>총 건수</label> {hasDriverObj?.data?.length || '-'} 건 &nbsp;
          <label>확정</label> {hasDriverObj?.data?.filter((ele) => ele.confirmFlag)?.length || '-'} 건 &nbsp;
          <label>미확정</label> {hasDriverObj?.data?.filter((ele) => !ele.confirmFlag)?.length || '-'} 건 &nbsp;
          <label className="blue">완료</label> {hasDriverObj?.data?.filter((ele) => ele.invoiceStatus?.includes('FINISH')).length || '-'} 건 &nbsp;
        </div>
        <CountingPanel schedule={coloredData} />
      </DisplayArea>

      <TableArea className="bottom0Grid inline-grids">
        {userData?.fixed && (
          <AUIGrid
            name={'sticky'}
            ref={fixedRef}
            columnLayout={[
              {
                dataField: 'fixed',
                headerText: '-',
                width: 100,
              },
            ]}
            gridProps={{ ...gridProps, width: 101 }}
          />
        )}
        {userData?.users?.length > 0 &&
          userData?.data?.map((user, idx) => {
            return (
              <AUIGrid
                key={`${userData?.users[idx]}_${idx}`}
                ref={(el) => (driversRef.current[idx] = el)}
                name={userData?.users?.length === idx + 1 ? `last_${idx}_${userData?.users[idx]}` : `_${idx}_${userData?.users[idx]}`}
                gridProps={{ ...gridProps, width: 172 }}
                columnLayout={[
                  {
                    dataField: userData?.users[idx],
                    headerText: MASTER_OBJ?.DRIVER_WHOLE?.[userData?.users[idx]],
                    style: 'schedule-cell',
                    width: 170,
                    renderer: {
                      type: IGrid.RendererKind.TemplateRenderer,
                    },
                    labelFunction: function (rowIndex, columnIndex, value, headerText, item, dataField) {
                      if (rowIndex < 1) {
                        return `<div class="clickable"> ${value}</div>`;
                      } else if (rowIndex === 2 || rowIndex === 1) {
                        const data = item[dataField + '_ele'];
                        if (data?.length) {
                          let div = `<div class="has-schedule ${data?.invoiceStatus?.includes('FINISH') ? 'done' : ''} ${returnFeeTypes(data?.feeType)}">`;
                          div += data?.length + '건';
                          div += '</div>';
                          return div;
                        }
                        return '';
                      } else {
                        if (item[dataField + '_ele']) {
                          const datas = item[dataField + '_ele'];
                          const data = datas[0];
                          let div = `<div class="has-schedule ${data?.invoiceStatus?.includes('FINISH') ? 'done' : ''} ${returnFeeTypes(data?.feeType)} ${data?.confirmFlag ? 'confirm' : ''} ${
                            datas?.length > 1 ? 'lots' : ''
                          }">`;

                          if (REGION_NUMBERS?.includes(data?.receiverPhone?.substr(0, 3))) {
                            div += `<span class="phone"><img src=${phonePng} alt="수령자의 등록된 연락처가 지역번호입니다" /></span>`;
                          }
                          if (data?.invoiceStatus === 'NOT_IN_INVENTORY') div += `<span class="noInvnent"><img src=${noInvenPng} alt="" /></span>`;
                          div += `<span class="labeling masterProduct" title="${data?.productName}">${data?.productName}</span>`;
                          if (data?.predictDeliveryDate) div += `<span class="labeling predictDay">${data?.predictDeliveryDate}</span>`;
                          if (data?.revisitFlag) div += `<span class="labeling revisit" title="재방문">재방문</span>`;
                          if (datas?.length > 1) div += `<span class="labeling over1">${datas?.length}건</span>`;
                          if (data.promiseDeliveryDate) div += `<span class="labeling dday">${returnDDay(data.deliveryDate, data.promiseDeliveryDate)}</span>`;

                          div += '<div>';
                          div += data?.receiverName;
                          div += '</div>';
                          div += '<div>';
                          div += data?.city;
                          div += '</div>';
                          div += '<div>';
                          div += data?.modelGroupKr || '가구';
                          div += '</div>';
                          div += '</div>';
                          return div;
                        }
                        return '';
                      }
                    },
                  },
                ]}
              />
            );
          })}
      </TableArea>
    </HasDriverArea>
  );
};

const CountingPanel = ({ schedule }) => {
  return (
    <div className="float-nav vertical">
      <img id="delivery-mode" src={delivery} alt="" />
      <span>{schedule?.delivery || '-'} 건</span>
      <img id="visit-mode" src={visit} alt="" />
      <span>{schedule?.visit || '-'}건</span>
      <img id="collect-mode" src={collect} alt="" />
      <span>{schedule?.collect || '-'}건</span>
      <img id="exchange-mode" src={exchange} alt="" />
      <span>{schedule?.exchange || '-'}건</span>
      <img id="move-mode" src={move} alt="" />
      <span>{schedule?.move || '-'}건</span>
      <img id="store-mode" src={store} alt="" />
      <span>{schedule?.store || '-'}건</span>
      <img id="as-mode" src={as} alt="" />
      <span>{schedule?.as || '-'}건</span>
      <img id="fix-mode" src={fix} alt="" />
      <span>{schedule?.fix || '-'}건</span>
    </div>
  );
};
