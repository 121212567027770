import { useEffect, useState } from 'react';

export function useNavigation(activeId: string) {
  const [activeItem, setActiveItem] = useState(activeId);

  useEffect(() => {
    setActiveItem(activeId);
  }, [activeId]);

  const handleNavigation = (event: React.SyntheticEvent) => {
    const target = (event.target as HTMLDivElement).dataset.tabId;
    target !== undefined && setActiveItem(target);
  };

  return { activeItem, setActiveItem, handleNavigation };
}
