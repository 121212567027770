import { useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

// service
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ParcelInvoiceLiveDetailListDTO, ParcelInvoiceLiveListDTO, ParcelInvoiceLiveSearchDTO } from '../../../_interface/parcel';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
// component
import { PagingListDTO } from 'interface/util';
import { serviceStore } from 'services/services';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';

export const defaultOrderDetailObj = {
  visible: false,
  item: null,
};

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<AUIGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const labellingKr = (data: PagingListDTO<ParcelInvoiceLiveListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_FORWARDER_WHOLE?.[row?.partnerSeq],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchParcelDetail = async (e: IGrid.ButtonClickEvent) => {
    const targetDataField = e.dataField;
    const targetDataFieldValue = e.item[targetDataField];

    // NOTE: 셀의 데이터가 0일때 예외처리 추가
    if (targetDataFieldValue === 0) {
      alert('조회할 데이터가 없습니다.');
      return;
    }
    const { partnerSeq, workType, collectDate } = e.item;

    const searchDTO = {
      pageNo: 1,
      pageSize: targetDataFieldValue,
      partnerSeq,
      workType,
      collectDate,
      workDetailType: targetDataField,
    };
    dispatch(setLoading('GET'));

    // NOTE: detail api의 요청 필수값에 pageNo와 pageSize가 존재하기에 isPaging 파라미터를 true로 요청함.
    const rows = (await serviceStore.parcelAction('invoice/live/detail', 'GET', searchDTO, null, true))?.data;

    // NOTE: payType값을 기준으로 payTypeKr 키를 추가
    if (rows?.list) {
      rows.list = rows?.list.map((row: ParcelInvoiceLiveDetailListDTO) => {
        return {
          ...row,
          payTypeKr: MASTER_OBJ?.PARCEL_PAY_TYPE?.[row?.payType],
        };
      });
    }

    rows?.list.length > 0 && // NOTE: 응답 값이 있을 때만 실행되도록 처리
      setBottomSheetObj({
        visible: true,
        hasTabs: [
          {
            title: e.dataField === 'notRegister' ? '운송장 미등록 내역' : '운송장상세 내역',
            gridRef: bottomGridRef,
            columns: detailColumns,
            rows: rows.list,
            buttons: [],
            options: {
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
            },
          },
        ],
      });
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '집화일자',
      dataField: 'collectDate',
    },
    {
      headerText: '구분',
      dataField: 'workType',
    },
    {
      headerText: '출고정보',
      children: [
        {
          headerText: '예약접수',
          dataField: 'schedule',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchParcelDetail,
          },
        },
        {
          headerText: '집화처리',
          dataField: 'collect',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchParcelDetail,
          },
        },
        {
          headerText: '운송장등록',
          dataField: 'register',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchParcelDetail,
          },
        },
        {
          headerText: '미등록',
          dataField: 'notRegister',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchParcelDetail,
          },
        },
        {
          headerText: '출고',
          dataField: 'load',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchParcelDetail,
          },
        },
        {
          headerText: '미출고',
          dataField: 'notLoad',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchParcelDetail,
          },
        },
      ],
    },
    {
      headerText: '화물이동',
      children: [
        {
          headerText: '도착',
          dataField: 'unload',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchParcelDetail,
          },
        },
        {
          headerText: '미도착',
          dataField: 'notUnload',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchParcelDetail,
          },
        },
        {
          headerText: '잔류',
          dataField: 'remain',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchParcelDetail,
          },
        },
      ],
    },
    {
      headerText: '배송정보',
      children: [
        {
          headerText: '배송완료',
          dataField: 'delivery',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchParcelDetail,
          },
        },
        {
          headerText: '미배송',
          dataField: 'notDelivery',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchParcelDetail,
          },
        },
        {
          headerText: '화물사고',
          dataField: 'accident',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchParcelDetail,
          },
        },
      ],
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '집화일자',
      dataField: 'collectDate',
    },
    {
      headerText: '작업구분',
      dataField: 'workType',
    },
    {
      headerText: '운송장번호',
      dataField: 'parcelInvoiceNumber',
    },
    {
      headerText: '송하인명',
      dataField: 'senderName',
    },
    {
      headerText: '수하인명',
      dataField: 'receiverName',
    },
    {
      headerText: '박스수량',
      dataField: 'totalBoxQuantity',
    },
    {
      headerText: '운임구분',
      dataField: 'payTypeKr',
    },
    {
      headerText: '운임합계',
      dataField: 'totalFee',
    },
    {
      headerText: '기본운임',
      dataField: 'price',
    },
    {
      headerText: '별도운임',
      dataField: 'extraFee',
    },
  ];

  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const fetchPaging = async (searchObj: ParcelInvoiceLiveSearchDTO) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore?.parcelAction('invoice/live/paging', 'GET', searchObj, null, true))?.data as PagingListDTO<ParcelInvoiceLiveListDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 500,
      workType: '출고',
    },
    gridRef,
    fetchPaging,
  });

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {/* {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )} */}

        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
