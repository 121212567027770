import { useEffect, useRef, useState } from 'react';

// component
import { defaultGridProps } from 'common/grid/gridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { userService } from '../../_service/service';

// utils
import { InfraDetailSearchDTO, InfraSearchDTO } from 'interface/warehouse';
import { SearchBox } from './component/searchBox';
import AUIGrid from 'modules/Grid';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<AUIGrid>();
  const [searchObj, setSearchObj] = useState<InfraDetailSearchDTO>({ infraType: 'DELIVERY' });

  const saveRows = async () => {
    const gridData = gridRef.current.getGridData();
    const details = gridData?.map((ele) => {
      return {
        ...ele,
        infraSeq: MASTER_OBJ?.INFRA?.[ele.infraName],
        partnerSeq: MASTER_OBJ?.SELLER_WHOLE?.[ele.partnerSeqKr],
        centerCode: MASTER_OBJ?.CENTER_WHOLE?.[ele.centerCodeKr],
        modelGroup: MASTER_OBJ?.MODEL_GROUP?.[ele.modelGroupKr],
        parentCenterCode: MASTER_OBJ?.CENTER_WHOLE?.[ele.parentCenterCodeKr],
        networkPartnerSeq: MASTER_OBJ?.SELLER_WHOLE?.[ele.networkPartnerSeqKr],
      };
    });
    const dataDTO = {
      ...searchObj,
      details,
    };
    const rs = await userService.saveSubInfra(dataDTO);
    if (rs?.status === 200) {
      alert('저장되었습니다!');
      fetchList(searchObj);
    }
  };

  const columns: { [key: string]: IGrid.Column[] } = {
    DELIVERY: [
      {
        headerText: '*인프라',
        dataField: 'infraName',
      },
      {
        headerText: '*창고',
        dataField: 'centerCodeKr',
        editable: true,
        editRenderer: {
          type: IGrid.EditRendererKind.ComboBoxRenderer,
          showEditorBtnOver: true,
          autoCompleteMode: true,
          autoEasyMode: true,
          matchFromFirst: false,
          list: MASTER_OPS?.CENTER_AUTH,
          keyField: 'label',
          valueField: 'label',
        },
      },
      {
        headerText: '*판매사',
        dataField: 'partnerSeqKr',
        editable: true,
        editRenderer: {
          type: IGrid.EditRendererKind.ComboBoxRenderer,
          showEditorBtnOver: true,
          autoCompleteMode: true,
          autoEasyMode: true,
          matchFromFirst: false,
          list: MASTER_OPS?.SELLER_SELLER_AUTH,
          keyField: 'label',
          valueField: 'label',
        },
      },
      {
        headerText: '*제품그룹',
        dataField: 'modelGroupKr',
        editable: true,
        editRenderer: {
          type: IGrid.EditRendererKind.ComboBoxRenderer,
          showEditorBtnOver: true,
          autoCompleteMode: true,
          autoEasyMode: true,
          matchFromFirst: false,
          list: MASTER_OPS?.MODEL_GROUP,
          keyField: 'label',
          valueField: 'label',
        },
      },
      {
        headerText: '상위창고',
        dataField: 'parentCenterCodeKr',
        editable: true,
        editRenderer: {
          type: IGrid.EditRendererKind.ComboBoxRenderer,
          showEditorBtnOver: true,
          autoCompleteMode: true,
          autoEasyMode: true,
          matchFromFirst: false,
          list: MASTER_OPS?.CENTER_AUTH,
          keyField: 'label',
          valueField: 'label',
        },
      },
    ],
    PARCEL: [
      {
        headerText: '*인프라',
        dataField: 'infraName',
      },
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: true,
        editRenderer: {
          type: IGrid.EditRendererKind.ComboBoxRenderer,
          showEditorBtnOver: true,
          autoCompleteMode: true,
          autoEasyMode: true,
          matchFromFirst: false,
          list: MASTER_OPS?.SELLER_SELLER_AUTH,
          keyField: 'label',
          valueField: 'label',
        },
      },
      {
        headerText: '*위탁사',
        dataField: 'networkPartnerSeqKr',
        editable: true,
        editRenderer: {
          type: IGrid.EditRendererKind.ComboBoxRenderer,
          showEditorBtnOver: true,
          autoCompleteMode: true,
          autoEasyMode: true,
          matchFromFirst: false,
          list: MASTER_OPS?.SELLER_NETWORK_AUTH,
          keyField: 'label',
          valueField: 'label',
        },
      },
    ],
  };

  const fetchList = async (searchObj: InfraSearchDTO) => {
    dispatch(setLoading('GET'));
    gridRef?.current?.changeColumnLayout(columns[searchObj?.infraType]);
    const data = await userService.getSubInfra(searchObj);
    const rows = data.details?.map((row) => {
      return {
        ...row,
        infraName: MASTER_OBJ?.INFRA?.[row.infraSeq],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row.modelGroup],
        parentCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.parentCenterCode],
        networkPartnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.networkPartnerSeq],
      };
    });
    gridRef.current.setGridData(rows);
    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'addRow') {
      gridRef.current.addRow({
        ...searchObj,
        infraName: MASTER_OBJ?.INFRA?.[searchObj.infraSeq],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[searchObj.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[searchObj.partnerSeq],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[searchObj.modelGroup],
      });
    } else if (id === 'deleteRow') {
      const idxs = gridRef.current.getCheckedRowItems().map((ele) => ele.rowIndex);
      if (idxs?.length > 0) {
        const rows = gridRef.current.getGridData();
        const newRows = rows.filter((_, i) => !idxs.includes(i));
        gridRef.current.setGridData(newRows);
      } else {
        alert('선택된 것이 없습니다');
      }
    } else if (id === 'saveRows') {
      saveRows();
    }
  };

  useEffect(() => {
    if (Object.values(searchObj)?.filter((ele) => ele)?.length > 0) {
      fetchList(searchObj);
    }
  }, [searchObj]);

  return (
    <div className="page-content">
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={fetchList} />
      </div>

      <div className="presenterGridBox">
        <div className="grid-button-area space-between">
          {Object.values(searchObj)?.filter((ele) => ele)?.length > 0 && (
            <>
              <div>
                <div id="addRow" className={`blue`} onClick={gridButtonhandler}>
                  +행추가
                </div>
                <div id="deleteRow" className={`red`} onClick={gridButtonhandler}>
                  -행삭제
                </div>
              </div>
              <div>
                <div id="saveRows" className={`orange`} onClick={gridButtonhandler}>
                  저장
                </div>
              </div>
            </>
          )}
        </div>
        <AUIGrid
          ref={gridRef}
          name={`detail_infra}`}
          columnLayout={columns[searchObj?.infraType]}
          gridProps={{
            ...defaultGridProps,
            editable: true,
            height: 700,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
      </div>
    </div>
  );
};

export default Index;
