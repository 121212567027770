import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { SearchBox } from './components/searchBox';
import { feeService } from 'pages/FEE/_service/service';
import { TransactionStatementDTO, TransactionStatementSearchDTO } from 'interface/order';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { PagingListDTO } from 'interface/util';
import { categoryChanger } from '..';
import { PdfPopup } from '../_print/transactionPrint';

const Index = () => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const [pdfObj, setPdfObj] = useState({
    visible: false,
    seq: null,
    data: null,
    from: null,
  });

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));
  const pathOrder = currentDesignType === 1 ? 2 : 1;

  const popupPdf = (e) => {
    console.log(e);
    setPdfObj({
      visible: true,
      seq: e.item.transactionStatementSeq,
      data: null,
      from: location?.pathname,
    });
  };
  const fecthDetail = async (e) => {
    history(
      currentDesignType === 1 ? `/main/transactionRegister${location?.pathname}/${e.item.transactionStatementSeq}` : `/transactionRegister${location?.pathname}/${e.item.transactionStatementSeq}`,
    );
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fecthDetail,
      },
    },
    {
      headerText: '거래명세서PDF',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '다운로드',
        onClick: popupPdf,
      },
    },
    {
      headerText: '거래명세서번호',
      dataField: 'transactionStatementSeq',
      editable: false,
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      editable: false,
    },
    {
      headerText: '상태값',
      dataField: 'statusKr',
      editable: false,
    },
    {
      headerText: '작성일',
      dataField: 'issueDate',
      editable: false,
    },
    {
      headerText: '시작일',
      dataField: 'startDate',
      editable: false,
    },
    {
      headerText: '종료일',
      dataField: 'endDate',
      editable: false,
    },
    {
      headerText: '발급일',
      dataField: 'registerDate',
      editable: false,
    },
    {
      headerText: '확정일',
      dataField: 'confirmDate',
      editable: false,
    },
    {
      headerText: '전기일',
      dataField: 'postingDate',
      editable: false,
    },
    {
      headerText: '지급일',
      dataField: 'payDate',
      editable: false,
    },
    {
      headerText: '취소일',
      dataField: 'cancelDate',
      editable: false,
    },
    {
      headerText: '수량',
      dataField: 'quantity',
      editable: false,
    },
    {
      headerText: '공급가액',
      dataField: 'supplyValue',
      editable: false,
    },
    {
      headerText: '부가세',
      dataField: 'valueAddedTax',
      editable: false,
    },
    {
      headerText: '합계',
      dataField: 'total',
      editable: false,
    },
    {
      headerText: '비고',
      dataField: 'remark',
      editable: false,
    },
    {
      headerText: '이메일',
      dataField: 'email',
      editable: false,
    },
    {
      headerText: '이메일 전송 여부',
      dataField: 'emailSendFlagKr',
      editable: false,
    },
  ];

  const confirmStatement = async (items) => {
    const data = {
      transactionStatementSeqs: items?.map((ele) => ele.transactionStatementSeq),
    };
    const rs = await feeService.postTransactionAction(data, categoryChanger(location.pathname.split('/')[pathOrder]), '/confirm');
    if (rs?.status === 200) {
      alert('확정되었습니다');
      wrappedFetchList(searchObj);
    }
  };

  const postStatement = async (items) => {
    const data = {
      transactionStatementSeqs: items?.map((ele) => ele.transactionStatementSeq),
    };
    const rs = await feeService.postTransactionAction(data, categoryChanger(location.pathname.split('/')[pathOrder]), '/posting');
    if (rs?.status === 200) {
      alert('전기되었습니다');
      wrappedFetchList(searchObj);
    }
  };

  const payStatement = async (items) => {
    const data = {
      transactionStatementSeqs: items?.map((ele) => ele.transactionStatementSeq),
    };
    const rs = await feeService.postTransactionAction(data, categoryChanger(location.pathname.split('/')[pathOrder]), '/pay');
    if (rs?.status === 200) {
      alert('지급되었습니다');
      wrappedFetchList(searchObj);
    }
  };

  const emailStatement = async (items) => {
    const data = {
      transactionStatementSeqs: items?.map((ele) => ele.transactionStatementSeq),
    };
    const rs = await feeService.postTransactionAction(data, categoryChanger(location.pathname.split('/')[pathOrder]), '/confirm');
    if (rs?.status === 200) {
      alert('이메일 전송되었습니다');
      wrappedFetchList(searchObj);
    }
  };

  const initializeStatement = async (items) => {
    const data = {
      transactionStatementSeqs: items?.map((ele) => ele.transactionStatementSeq),
    };
    const rs = await feeService.postTransactionAction(data, categoryChanger(location.pathname.split('/')[pathOrder]), '/initialize');
    if (rs?.status === 200) {
      alert('초기화되었습니다');
      wrappedFetchList(searchObj);
    }
  };

  const cancelStatement = async (items) => {
    const data = {
      transactionStatementSeqs: items?.map((ele) => ele.transactionStatementSeq),
    };
    const rs = await feeService.postTransactionAction(data, categoryChanger(location.pathname.split('/')[pathOrder]), '/cancel');
    if (rs?.status === 200) {
      alert('취소되었습니다');
      wrappedFetchList(searchObj);
    }
  };

  const labellingKr = (data: PagingListDTO<TransactionStatementDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],

        statusKr: MASTER_OBJ?.TS_STATUS?.[row.status],
        emailSendFlagKr: row.emailSendFlag === true ? 'O' : 'X',
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: TransactionStatementSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await feeService.getTransactionPaging(searchObj, categoryChanger(location.pathname.split('/')[pathOrder])));
    dispatch(setLoading(null));
    return dataKr;
  };

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: await feeService.getTransactionAction(searchObj, categoryChanger(location.pathname.split('/')[pathOrder]), 'excel') }, true);

    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '거래명세서' });
    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id?.includes('DOWNLOADTRACSACTION')) {
      downloadExcel('main');
    } else if (id?.includes('CREATERECEIPTTRACSACTION')) {
      history(
        currentDesignType === 1
          ? `/main/${location?.pathname?.split('/')?.[pathOrder]}/transactionRegister/${location?.pathname?.split('/')?.[pathOrder]}/0`
          : `/transactionRegister/${location?.pathname?.split('/')?.[pathOrder]}/0`,
      );
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id?.includes('RECEIPTTRACSACTIONCONFIRM')) {
        confirmStatement(items);
      } else if (id?.includes('RECEIPTTRACSACTIONPOST')) {
        postStatement(items);
      } else if (id?.includes('RECEIPTTRACSACTIONPAY')) {
        payStatement(items);
      } else if (id?.includes('RECEIPTTRACSACTIONEMAIL')) {
        emailStatement(items);
      } else if (id?.includes('RECEIPTTRACSACTIONRESET')) {
        initializeStatement(items);
      } else if (id?.includes('RECEIPTTRACSACTIONCANCEL')) {
        cancelStatement(items);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  // 기능권한 버튼
  const tabName = `TRANSACTION_STATEMENT_${categoryChanger(location.pathname.split('/')[pathOrder])}`;
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabName);
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj, location]);

  return (
    <div className="page-content">
      {pdfObj?.visible && <PdfPopup pdfObj={pdfObj} setPdfObj={setPdfObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
