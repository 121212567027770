import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tab1 from './gansunIpgo';
// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER, RAWDATA } from 'redux/services/menuSlice';

export const GANSUNIPGO_MASTER = [
  //
  `${RAWDATA}CENTER_AUTH`,
  `${RAWDATA}CENTER_WHOLE`,
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'CENTER_DIFF',
  'SELLER_NETWORK_AUTH',
  'SELLER_SUPPLIER_AUTH',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  'INFRA',
  //
  'BRAND',
  'PO_STATUS',
  'MODEL_GROUP',
  'MODEL_GRP_1',
  'LINEHAUL_TYPE',
  'TRANSPORT_STAT',
  'INVA_TYPE',
  //
  'SEARCH_MODEL',
  {
    ORDER_SEQ: [
      { value: 'orderSeq', label: '주문번호' },
      { value: 'externalOrderNumber', label: '외부주문번호' },
      { value: 'invoiceSeq', label: '송장번호' },
      { value: 'externalInvoiceNumber', label: '외부송장번호' },
      { value: 'customerName', label: '고객명' },
    ],
  },
  {
    INVENTORY_SEQ: [
      { value: 'inventorySeq', label: '재고번호' },
      { value: 'serialNumber', label: '시리얼번호' },
    ],
  },
  {
    SEARCH_SEQ: [
      { value: 'linehaulSeq', label: '간선피킹지시번호' },
      { value: 'loadingStatementSeq', label: '간선출고지시번호' },
      { value: 'transportStatementSeq', label: '운송번호' },
    ],
  },
  {
    SEARCH_DATE: [
      { value: 'PromiseUnloadDate', label: '입고예정일' },
      { value: 'LinehaulDate', label: '출고예정일' },
      { value: 'LoadDate', label: '출차지시확정일' },
    ],
  },
  //
  'PICK_FLAG',
  'USE_FLAG',
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(GANSUNIPGO_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            TRUNKRECEIVE_TAB: <Tab1 tabId={'TRUNKRECEIVE_TAB'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
