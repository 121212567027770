import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';
import { INVENTORY_RETURN_LIST, ORDER_SCHEDULE_ACTION, ORDER_SCHEDULE_CENTER_LIST } from 'envVar';
import { PagingListDTO, ResponseDTO } from 'interface/util';
import { InvoiceCenterSearchDTO, InvoiceDTOForOrderSchedule, InvoiceListDTO } from 'interface/order';

import { StockRemoveListDTO } from 'interface/warehouse';

class DeliveryService {
  public async getOrderScheduleByCenter(searchDTO: InvoiceCenterSearchDTO): Promise<InvoiceListDTO[]> {
    let url = ORDER_SCHEDULE_CENTER_LIST + addingParamsToUrl(searchDTO);
    const rs = await httpClient.get(url);
    return rs.data;
  }
  public async getInventoryReturnList(): Promise<StockRemoveListDTO[]> {
    let url = INVENTORY_RETURN_LIST;
    const rs = await httpClient.get(url);
    return rs.data;
  }

  public async getOrderSchedulePagingAction(action: string, searchDTO: any): Promise<PagingListDTO<InvoiceDTOForOrderSchedule>> {
    let url = ORDER_SCHEDULE_ACTION(action) + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getOrderScheduleAction(action: string, searchDTO: any): Promise<any> {
    let url = ORDER_SCHEDULE_ACTION(action) + addingParamsToUrlexceptPaging(searchDTO);
    const rs = await httpClient.get(url);
    return rs;
  }

  public async postOrderScheduleAction(action: string, searchDTO: any, dataDTO: any): Promise<ResponseDTO> {
    let url = ORDER_SCHEDULE_ACTION(action) + (searchDTO ? addingParamsToUrlexceptPaging(searchDTO) : '');
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
}

export const deliveryService = new DeliveryService();
