import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { resetAllSelections } from 'common/util/searchBox';
import { OrderSearchDTO } from 'pages/ORDER/2ss/_interface/order';

export const SearchBox = (props: ISearchBoxProps<OrderSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_SEQ: null,
    SEARCH_CENTER: null,
    SEARCH_DATE: null,
    SEARCH_DRIVER: null,
    SEARCH_MODEL: null,
    SEARCH_NAME: null,
    SEARCH_ADDRESS: null,
    SEARCH_PHONE: null,
  });
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SEQ, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ]);
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_CENTER, searchObj));
  }, [selectTypeObj?.SEARCH_CENTER]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DRIVER, searchObj));
  }, [selectTypeObj?.SEARCH_DRIVER]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_NAME, searchObj));
  }, [selectTypeObj?.SEARCH_NAME]);
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_ADDRESS, searchObj));
  }, [selectTypeObj?.SEARCH_ADDRESS]);
  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_PHONE, searchObj));
  }, [selectTypeObj?.SEARCH_PHONE]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">주문/송장번호</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_SEQ
                    ? {
                        value: selectTypeObj?.SEARCH_SEQ,
                        label: MASTER_OBJ?.SEARCH_SEQ?.[selectTypeObj?.SEARCH_SEQ],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_SEQ}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_SEQ: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_SEQ}
                value={searchObj?.[selectTypeObj?.SEARCH_SEQ] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_SEQ]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">창고검색</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_CENTER
                    ? {
                        value: selectTypeObj?.SEARCH_CENTER,
                        label: MASTER_OBJ?.SEARCH_CENTER?.[selectTypeObj?.SEARCH_CENTER],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_CENTER}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_CENTER: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <SelectD
                isMulti
                isDisabled={!selectTypeObj?.SEARCH_CENTER}
                options={MASTER_OPS?.CENTER_AUTH}
                value={searchObj?.[selectTypeObj?.SEARCH_CENTER]?.map((ele) => {
                  return {
                    value: ele,
                    label: MASTER_OBJ?.CENTER_AUTH?.[ele],
                  };
                })}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_CENTER]: (option as OptionItem)?.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">송장유형</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.SS_FEE_TYPE}
            value={searchObj?.feeTypes?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.SS_FEE_TYPE?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  feeTypes: (option as OptionItem[])?.map((ele) => ele.value),
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">기간</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_DATE
                    ? {
                        value: selectTypeObj?.SEARCH_DATE,
                        label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_DATE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_DATE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <FlatpickrD
                disabled={!selectTypeObj?.SEARCH_DATE}
                value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                onReset={() => {
                  setSearchObj({
                    ...searchObj,
                    [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                    [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                  });
                }}
                onClose={(option) => {
                  if (option?.length === 1)
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                      [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                    });
                }}
                onChange={(date) => {
                  setSearchObj({
                    ...searchObj,
                    [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(date[0]),
                    [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(date[1]),
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">기사검색</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_DRIVER
                    ? {
                        value: selectTypeObj?.SEARCH_DRIVER,
                        label: MASTER_OBJ?.SEARCH_DRIVER?.[selectTypeObj?.SEARCH_DRIVER],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_DRIVER}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_DRIVER: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <SelectD
                isDisabled={!selectTypeObj?.SEARCH_DRIVER}
                options={MASTER_OPS?.DRIVER_AUTH}
                value={
                  searchObj?.[selectTypeObj?.SEARCH_DRIVER]
                    ? {
                        value: searchObj?.[selectTypeObj?.SEARCH_DRIVER],
                        label: MASTER_OBJ?.DRIVER_AUTH?.[searchObj?.[selectTypeObj?.SEARCH_DRIVER]],
                      }
                    : null
                }
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_DRIVER]: (option as OptionItem)?.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">제품/제품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">고객명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_NAME
                    ? {
                        value: selectTypeObj?.SEARCH_NAME,
                        label: MASTER_OBJ?.SEARCH_NAME?.[selectTypeObj?.SEARCH_NAME],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_NAME}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_NAME: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_NAME}
                value={searchObj?.[selectTypeObj?.SEARCH_NAME] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_NAME]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">주소</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_ADDRESS
                    ? {
                        value: selectTypeObj?.SEARCH_ADDRESS,
                        label: MASTER_OBJ?.SEARCH_ADDRESS?.[selectTypeObj?.SEARCH_ADDRESS],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_ADDRESS}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_ADDRESS: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_ADDRESS}
                value={searchObj?.[selectTypeObj?.SEARCH_ADDRESS] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_ADDRESS]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">연락처</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_PHONE
                    ? {
                        value: selectTypeObj?.SEARCH_PHONE,
                        label: MASTER_OBJ?.SEARCH_PHONE?.[selectTypeObj?.SEARCH_PHONE],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_PHONE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_PHONE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_PHONE}
                value={searchObj?.[selectTypeObj?.SEARCH_PHONE] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_PHONE]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col />
        <Col />
        <Col style={{ textAlign: 'right' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
