import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import useToast from 'hooks/useToast';
import { ParcelInvoiceTempDTO } from '../../_interface/parcel';
import { serviceStore } from 'services/services';
import { useReactToPrint } from 'react-to-print';

const Index = ({ tabId }) => {
  const [inputs, setInputs] = useState<{ parcelInvoiceNumber: string }>();
  const successPlayer = useRef<HTMLAudioElement>();
  const failurePlayer = useRef<HTMLAudioElement>();
  const validatePlayer = useRef<HTMLAudioElement>();
  const { Toast, displayToast, setColor, setMsg } = useToast({
    message: '',
    ms: 3000,
  });

  const wrapperRef = useRef();
  const scanInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    scanInputRef?.current?.focus();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => wrapperRef.current,
    onAfterPrint: () => {
      scanInputRef.current.value = '';
      setInputs((prev) => {
        return {
          ...prev,
          parcelInvoiceNumber: null,
        };
      });
      scanInputRef?.current?.focus();
    },
  });

  const [printList, setPrintList] = useState<{ data: ParcelInvoiceTempDTO; status: '대기' | '완료' }[]>();
  const scanRight = (str = 'SUCCESS', data: ParcelInvoiceTempDTO) => {
    successPlayer.current.play();
    setColor('green');
    setMsg(str);
    displayToast();
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        parcelInvoiceNumber: null,
      };
    });
    const _printList = printList ? JSON.parse(JSON.stringify(printList)) : [];
    _printList?.push({ data, status: '대기' });
    _printList?.reverse();
    setPrintList(_printList);
    handlePrint();
  };

  const scanError = (str = 'ERROR') => {
    failurePlayer.current.play();
    setColor('red');
    setMsg(str);
    displayToast();
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        parcelInvoiceNumber: null,
      };
    });
    scanInputRef?.current?.focus();
  };

  const getParcelInfo = async (searchDTO) => {
    const rs = await serviceStore.parcelAction(`invoice/temp`, 'GET', searchDTO);
    console.log(rs);
    if (rs?.status === 200) {
      scanRight(searchDTO?.parcelInvoiceNumber, rs?.data);
    } else {
      scanError();
    }
  };

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      if (inputs?.parcelInvoiceNumber) {
        getParcelInfo(inputs);
      }
    }
  };
  const [mode, setMode] = useState('CJ');
  const changeMode = (e) => {
    localStorage.setItem('modeInLinehaul', e.target.id);
    setMode(e.target.id);
  };

  return (
    <div className="page-content">
      <div style={{ maxWidth: 500, marginBottom: 200 }}>
        <div style={{ position: 'relative', height: 50, marginBottom: 12, paddingTop: 20 }}>
          <div style={{ textAlign: 'right' }}>
            <div className="toggle-area">
              <div id="CJ" className={`element ${mode === 'CJ' ? 'active' : ''}`} onClick={changeMode}>
                CJ송장
              </div>
              <div id="XX" className={`element ${mode === 'XX' ? 'active' : ''}`} onClick={changeMode}>
                XX송장
              </div>
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            <Toast />
          </div>
        </div>
        <Form onKeyDown={onKeyPress}>
          <Row>
            <Col>
              <label className="col-form-label">*운송장번호</label>
              <input
                ref={scanInputRef}
                value={inputs?.parcelInvoiceNumber || ''}
                className="form-control backgroundYellow"
                placeholder="운송장번호를 스캔하세요"
                onChange={(e) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      parcelInvoiceNumber: e.target.value.substring(0, 13),
                    };
                  });
                }}
              />
            </Col>
          </Row>
        </Form>
        <div className="grid-button-area space-between" style={{ marginTop: 30 }}></div>
        <table className="border-table noBorder fontSize10" style={{ fontSize: 10, marginTop: 0 }}>
          <thead>
            <tr>
              <th>순번</th>
              <th colSpan={12}>운송장번호</th>
              <th colSpan={2}>출력상태</th>
            </tr>
          </thead>
        </table>
        <table className="border-table noBorder">
          <tbody>
            {printList?.map(({ data, status }, idx) => {
              return (
                <tr key={`today_${idx}`}>
                  <th>{printList?.length - idx}</th>
                  <th colSpan={12}>{data?.parcelInvoiceNumber}</th>
                  <th colSpan={2}>{status}</th>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div ref={wrapperRef}></div>
      {/* <iframe ref={iframeRef} srcDoc="<div>슈파두파</div>" /> */}
      <div className="audioWrapper">
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_sound.mp3" ref={successPlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_fail_sound.mp3" ref={failurePlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/surprise-sound.mp3" ref={validatePlayer} controls></audio>
      </div>
    </div>
  );
};

export default Index;
