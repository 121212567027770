import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

// service
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { serviceStore } from 'services/services';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { Prints } from './component/printOrder';
import { StockRemovePickingSSDetailDTO, StockRemovePickingSSDetailSearchDTO, StockRemovePickingSSListDTO, StockRemoveSearchDTO } from 'pages/OUT/_interface/warehouse';
import { PagingListDTO } from 'interface/util';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      dateType: null,
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  const labellingKr = (data, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
        statusKr: MASTER_OBJ?.REMOVE_STATUS?.[row?.status],
        receiverAddr: `${row?.receiverAddr1?.replace('null', '') || ''} ${row?.receiverAddr2?.replace('null', '') || ''}`,
        customerAddr: `${row?.customerAddr1?.replace('null', '') || ''} ${row?.customerAddr2?.replace('null', '') || ''}`,
        //
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
        //
        promiseTimeZoneKr: MASTER_OBJ?.TIME_ZONE?.[row?.promiseTimeZone],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  useEffect(() => {
    // gridRef?.current?.setGridData(labellingKr(sampleGridData)?.list);
  }, []);

  const fetchDetailList = async (e: IGrid.ButtonClickEvent) => {
    const searchDTO: StockRemovePickingSSDetailSearchDTO = {
      stockRemoveSeq: e?.item?.stockRemoveSeq,
      invoiceSeq: e?.item?.invoiceSeq,
      centerCode: e?.item?.centerCode,
    };

    dispatch(setLoading('GET'));
    const originalData = (await serviceStore?.warehouseAction('remove/picking/ss/detail', 'GET', searchDTO, null, false))?.data as StockRemovePickingSSDetailDTO[];
    dispatch(setLoading(null));

    const rows = labellingKr({
      list: originalData?.map((data) => {
        return {
          ...data,
          ...searchDTO,
        };
      }),
    })?.list;

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '납품피킹 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          options: {
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          },
        },
      ],
    });
  };

  const [printObj, setPrintObj] = useState<{ visible: boolean; type: string; items: any[] }>();
  const printPickingOrder = async (e: IGrid.ButtonClickEvent) => {
    setPrintObj({
      visible: true,
      type: 'picking',
      items: [e?.item],
    });
  };

  const printAllPickingOrder = (items) => {
    setPrintObj({
      visible: true,
      type: 'picking',
      items,
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      width: 80,
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetailList,
      },
    },
    {
      headerText: '피킹지시서',
      width: 80,
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '프린트',
        onClick: printPickingOrder,
      },
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '희망일',
      dataField: 'promiseDate',
    },
    {
      headerText: '희망시간대',
      dataField: 'promiseTimeZoneKr',
    },
    {
      headerText: '배송예정일',
      dataField: 'deliveryDate',
    },
    {
      headerText: '피킹완료일',
      dataField: 'pickDatetime',
    },
    {
      headerText: '출고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '피킹대상 수량',
      dataField: 'availableQuantity',
    },
    {
      headerText: '특이사항',
      dataField: 'memo',
    },
    {
      headerText: '피킹완료자',
      dataField: 'confirmId',
    },
    {
      headerText: '실수령자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'receiverName',
        },
        {
          headerText: '우편번호',
          dataField: 'receiverZipcode',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr',
        },
        {
          headerText: '연락처1',
          dataField: 'receiverPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'receiverPhone2',
        },
      ],
    },
    {
      headerText: '주문자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'customerName',
        },
        {
          headerText: '우편번호',
          dataField: 'customerZipcode',
        },
        {
          headerText: '주소',
          dataField: 'customerAddr',
        },
        {
          headerText: '연락처1',
          dataField: 'customerPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'customerPhone2',
        },
      ],
    },
  ];

  const detailColumns = [
    ...columns?.filter((column) => column.dataField === 'orderSeq' || column.dataField === 'invoiceSeq' || column.dataField === 'statusKr'),
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
      ],
    },
    ...columns?.filter((column) => column.dataField === 'availableQuantity'),
  ];
  // console.log(MASTER_OBJ);

  const fetchPaging = async (searchObj: StockRemoveSearchDTO) => {
    dispatch(setLoading('GET'));
    bottomSheetObj?.visible && setBottomSheetObj({ visible: false });
    const dataKr = labellingKr((await serviceStore?.warehouseAction('remove/picking/ss/list', 'GET', searchObj, null, true))?.data as PagingListDTO<StockRemovePickingSSListDTO[]>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 500,
      ...defaultSearchFilter,
    },
    gridRef,
    fetchPaging,
  });

  const gridButtonhandler = (e) => {
    //TODO: 임시 얼럿 & return 제거 필요
    alert('준비중입니다!');
    return;
    const id = e.target.id;
    handleCheckItems(id);
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      //TODO: 기능버튼 정의
      if (id === 'DELIVERY_PICKING_PRINT') printAllPickingOrder(items);
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      {printObj?.visible && <Prints printObj={printObj} setPrintObj={setPrintObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} TARGET={tabId} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        {/* TODO: 임시 버튼 추후 제거하고 포스트맨으로 생성 */}
        <div className="grid-button-area">
          <div id={'DELIVERY_PICKING_CANCEL'} className={`red MAIN`} onClick={gridButtonhandler}>
            납품피킹 취소
          </div>
          <div id={'DELIVERY_PICKING_ROLLBACK'} className={`red MAIN`} onClick={gridButtonhandler}>
            이전단계
          </div>
          <div className="justText">피킹지시서</div>
          <div id={'DELIVERY_PICKING_PRINT'} className={`gray MAIN`} onClick={gridButtonhandler}>
            일괄 프린트
          </div>
          <div className="justText">엑셀다운</div>
          <div id={'DELIVERY_PICKING_EXCEL_MAIN'} className={`green MAIN`} onClick={gridButtonhandler}>
            메인
          </div>
          <div id={'DELIVERY_PICKING_EXCEL_DETAIL'} className={`green MAIN`} onClick={gridButtonhandler}>
            상세
          </div>
        </div>
        {/* TODO: 임시 버튼 추후 제거 필요*/}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 0,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;

export const sampleGridData = {
  list: [
    {
      orderSeq: 2100045949,
      invoiceSeq: 1000040520,
      desireDate: '2024-11-01',
      desireTime: '오전',
      deliveryDate: '2024-11-01',
      pickDate: '2024-10-31 10:22:00',
      centerCode: 'R053',
      status: 'READY',
      pickQuantity: 23,
      memo: '오전 희망',
      pickUser: 'samhan',
      receiverName: '냉동',
      receiverZipcode: '63108',
      receiverAddr1: '광주 북구 일곡택지로',
      receiverAddr2: '(차디찬냉동-9)',
      receiverPhone1: '010-1234-5678',
      receiverPhone2: '010-1234-5678',
      customerName: '냉동',
      customerZipcode: '63108',
      customerAddr1: '광주 북구 일곡택지로',
      customerAddr2: '',
      customerPhone1: '010-1234-5678',
      customerPhone2: '010-1234-5678',
      //
      locationCode: '4P-A1',
      modelGroup: 'AIR',
      model: 'AC145CN4FHH1',
      modelName: 'AR-EH05',
      barcode: 'SAMHANTEST00001',
      partnerSeq: 89,
    },
  ],
};
/* {
          headerText: '로케이션',
          dataField: 'locationCode',
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },*/
