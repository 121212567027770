import CommonModalNew from 'components/modal/commonModalNew';

export const ViewList = (props) => {
  const { viewObj, setViewObj } = props;
  return (
    <CommonModalNew
      title={'조회수'}
      visible={viewObj?.visible}
      setVisible={() => {
        setViewObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      children={
        <>
          <table className="border-table">
            <thead style={{ textAlign: 'center' }}>
              <tr>
                <th>No.</th>
                <th>조회자</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: 'center' }}>
              {viewObj?.data?.map((ele, idx) => {
                return (
                  <tr key={`viewList_${idx}`}>
                    <td>{idx + 1}</td>
                    <td>{ele}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      }
    />
  );
};
