import { useState, useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import { useLocation } from 'react-router-dom';
import { setMasterOptions } from 'redux/services/menuSlice';
import { TabDTO } from 'interface/user';
import { TopTab } from 'components/template/topTabNew';

import Tab1 from './avaOrDef';
import Tab3 from './history';

import { centerListForlabeling, codeMasterOptionReturn, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Index = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [tabs, setTabs] = useState<TabDTO[]>();
  const [tabItems, setTabItems] = useState<{ titles?: string[]; containers: ReactNode[] }>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { useableTabs } = useSelector((state: RootState) => state.auth);

  const containerList = [
    { container: <Tab1 tabId={'MODIFICATION_AVA'} />, tabId: 'MODIFICATION_AVA' },
    { container: <Tab1 tabId={'MODIFICATION_DEF'} />, tabId: 'MODIFICATION_DEF' },
    { container: <Tab3 tabId={'MODIFICATION_HISTORY'} />, tabId: 'MODIFICATION_HISTORY' },
  ];

  const initMasterOptions = async () => {
    const [SUPPLIER_WHOLE, SELLER_AUTH_WHOLE, SELLER_WHOLE, CENTER_WHOLE, CENTER_AUTH_WHOLE, INVA_STATUS, INVA_TYPE, BRAND, MODEL_GROUP, MODEL_TYPE, MODEL_1, MODEL_2, MODEL_3] = await Promise.all([
      partnerListForlabeling({ sellerFlag: false, supplierFlag: true }),
      partnerListForlabeling({ sellerFlag: true }),
      partnerListForlabeling({ sellerFlag: false }),
      centerListForlabeling({ selectFlag: false }),
      centerListForlabeling(),
      codeMasterOptionReturn('INVA_STATUS'),
      codeMasterOptionReturn('INVA_TYPE'),
      codeMasterOptionReturn('BRAND'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
      codeMasterOptionReturn('MODEL_GRP_1'),
      codeMasterOptionReturn('MODEL_GRP_2'),
      codeMasterOptionReturn('MODEL_GRP_3'),
    ]);

    dispatch(
      setMasterOptions({
        SELLER_AUTH: SELLER_AUTH_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SUPPLIER: SUPPLIER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER_AUTH: CENTER_AUTH_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        INVA_STATUS,
        INVA_TYPE,
        BRAND,
        MODEL_GROUP,
        MODEL_TYPE,
        MODEL_1,
        MODEL_2,
        MODEL_3,
      }),
    );
  };

  useEffect(() => {
    if (tabs) {
      const tabIds = tabs?.map((ele) => ele.tabId);
      const tabLabeling = tabs?.map((ele) => {
        return {
          value: ele.tabId,
          label: ele.tabName,
        };
      });

      setTabItems({
        titles: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => selectlabel(ele.tabId, tabLabeling)),
        containers: containerList?.filter((ele) => tabIds?.includes(ele.tabId))?.map((ele) => ele.container),
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (useableTabs) {
      const tabDTO = useableTabs?.[location.pathname] ? [...useableTabs?.[location.pathname]] : [];
      if (tabDTO?.length > 0) {
        tabDTO?.sort((a: TabDTO, b: TabDTO) => a?.sort - b?.sort);
        setTabs(tabDTO);
        initMasterOptions();
      }
    }
  }, [useableTabs]);

  return <>{masterOptions && tabItems && <TopTab titles={tabItems?.titles} containers={tabItems?.containers} />}</>;
};

export default Index;
