// lib
import { v4 as uuidv4 } from 'uuid';
// react
import { useEffect, useState, useRef, useMemo } from 'react';
import { Col, Row, Form, Label, Button } from 'reactstrap';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// utils
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { handleFile, uploadFile } from 'common/util/photo';
import { httpClient } from 'common/http-client/axiosConfig';
// redux
import { useSelector } from 'react-redux';
import { TAKE_OUT_PICKING_PHOTO } from 'envVar';

// img
import CloseImg from 'assets/images/close/cross.png';
// component
import Grid from 'components/grid/auiGrid';
import AlertModal from 'components/modal/_alertModal';
import TakeoutCenterChangeModal from './takeoutCenterChangeModal';
import { BottomSheets } from 'components/template/bottomSheets';
import ImageModal from 'components/modal/imageModal';
import { HaldangModal } from './component/haldang';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { BanchulPrints } from '../../_print/banchulPrint';

import { resetAllSelections } from 'common/util/searchBox';
import PrintGridBox from 'common/grid/printGridBox';
import { useNavigate } from 'react-router-dom';

// util
const Presenter = (props) => {
  const {
    bottomSheetObj,
    setBottomSheetObj,
    haldangObj,
    setHaldangObj,
    changeCenterObj,
    setChangeCenterObj,
    fetchList,
    //
    photoPopupObj,
    setPhotoPopupObj,

    alertModalObj,
    setAlertModalObj,
    locationChangingObj,
    setLocationChangingObj,
    banchulPrintObj,
    setBanchulPrintObj,
  } = props;

  return (
    <>
      <div className="page-content">
        {banchulPrintObj?.visible && <BanchulPrints banchulPrintObj={banchulPrintObj} setBanchulPrintObj={setBanchulPrintObj} />}
        {photoPopupObj?.visible && <PhotoPopup photoPopupObj={photoPopupObj} setPhotoPopupObj={setPhotoPopupObj} />}
        {locationChangingObj?.visible && <LocationChangingModal locationChangingObj={locationChangingObj} setLocationChangingObj={setLocationChangingObj} />}
        {changeCenterObj?.visible && <TakeoutCenterChangeModal PfetchList={fetchList} changeCenterObj={changeCenterObj} setChangeCenterObj={setChangeCenterObj} />}
        {haldangObj?.visible && <HaldangModal haldangObj={haldangObj} setHaldangObj={setHaldangObj} />}
        {alertModalObj.visible && <AlertModal alertModalObj={alertModalObj} setAlertModalObj={setAlertModalObj} />}
        {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const PhotoPopup = ({ photoPopupObj, setPhotoPopupObj }) => {
  const handleFileUpload = async (e) => {
    const contentArr = e.target.id.split('_');
    const content = contentArr[0];
    const idx = Number(contentArr[1]);
    const ret = await handleFile(e);
    const uploadUrl = await uploadFile(ret.file, ret.url, `${content}_${idx}`);
    const data = {
      stockRemoveSeq: photoPopupObj?.photos?.stockRemoveSeq,
      addFiles: [
        {
          column: `RM_PICKING${idx + 1}`,
          uploadUrl,
        },
      ],
    };
    httpClient.post(TAKE_OUT_PICKING_PHOTO + '/save', data).then((res) => {
      if (res?.status === 200) {
        alert('사진 업로드에 성공하였습니다!');
        photoPopupObj?.fetchPhotosFn(photoPopupObj?.item);
      }
    });
  };

  const handleFileRemove = async (e) => {
    const contentArr = e.target.id.split('_');
    const idx = Number(contentArr[1]);

    const prompt = window.confirm('사진을 정말 삭제하시겠습니까?');
    if (prompt) {
      const data = {
        stockRemoveSeq: photoPopupObj?.photos?.stockRemoveSeq,
        deleteFiles: [
          {
            column: `RM_PICKING${idx + 1}`,
            uploadUrl: files[idx],
          },
        ],
      };
      httpClient.post(TAKE_OUT_PICKING_PHOTO + '/save', data).then((res) => {
        if (res?.status === 200) {
          alert('사진 삭제에 성공하였습니다!');
          photoPopupObj?.fetchPhotosFn(photoPopupObj?.item);
        }
      });
    }
  };

  const [files, setFiles] = useState();

  useEffect(() => {
    if (photoPopupObj?.photos) {
      setPhotos();
    }
  }, [photoPopupObj?.photos]);

  const setPhotos = () => {
    const photos = new Array(10).fill(null);
    photoPopupObj?.photos?.files.forEach((file) => {
      const i = Number(file?.column.split('RM_PICKING')[1]) - 1;
      photos[i] = file.uploadUrl;
    });
    setFiles(photos);
  };

  return (
    <>
      <CommonModalNew
        title="사진 모아보기"
        subTitle={`피킹지시번호 ${photoPopupObj?.photos?.stockRemoveSeq}`}
        visible={photoPopupObj?.visible}
        setVisible={() => {
          setPhotoPopupObj({
            ...photoPopupObj,
            visible: false,
          });
        }}
        children={
          <>
            <Row>
              <div className="moa-bogi">
                <Row>
                  {files?.map((url, i) => {
                    return (
                      <Col key={`photos_${i}_${uuidv4()}`}>
                        <div className="photo-zone">
                          <span className="bck-font"> 사진 {i + 1}</span>
                          {url ? (
                            <div style={{ display: 'inline-block' }}>
                              <img
                                src={CloseImg}
                                id={`photos_${i}_`}
                                alt=""
                                onClick={(e) => {
                                  handleFileRemove(e);
                                }}
                                className="closeBtn"
                              />
                              <ImageModal className="top_bar_profile_img" src={url ? url : null} alt="이미지미리보기" title={`사진 ${i + 1}`} />
                            </div>
                          ) : (
                            <>
                              <input style={{ display: 'none' }} type="file" accept="image/*" id={`photos_${i}`} className="unloadPhoto" onChange={(e) => handleFileUpload(e)} />

                              <Button size="md" color="primary" outline>
                                <Label for={`photos_${i}`} style={{ marginBottom: 0 }}>
                                  ㅤㅤㅤ파일 선택ㅤㅤㅤ
                                </Label>
                              </Button>
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Row>
          </>
        }
      />
    </>
  );
};

const LocationChangingModal = ({ locationChangingObj, setLocationChangingObj }) => {
  const [gridId, setGrid] = useState({
    SEARCH: `locationChange_search_${uuidv4()}`,
    SAVE: `locationChange_save_${uuidv4()}`,
  });
  const [rows, setRows] = useState({
    SEARCH: [],
    SAVE: [],
  });
  const [searchObj, setSearchObj] = useState({
    locationCode: null,
  });
  const columns = [
    {
      headerText: '로케이션',
      dataField: 'locationCode',
      editable: false,
    },
    {
      headerText: '피킹가능수량',
      dataField: 'availableQuantity',
      editable: false,
    },
    {
      headerText: '변경수량',
      dataField: 'quantity',
      style: 'my-custom-aui',
      editable: true,
      renderer: {
        type: 'TemplateRenderer',
        aliasFunction: function (rowIndex, columnIndex, value, headerText, item) {
          return value;
        },
      },
    },
  ];

  const changeLocations = () => {
    const data = {
      centerCode: locationChangingObj?.item?.centerCode,
      target: locationChangingObj?.item?.target,
      stockRemoveDate: locationChangingObj?.item?.stockRemoveDate,
      partnerSeq: locationChangingObj?.item?.partnerSeq,
      modelStockSeq: locationChangingObj?.item?.modelStockSeq,
      beforeLocationCode: locationChangingObj?.item?.locationCode,
      list: [],
    };

    const rows = window.AUIGrid.getGridData(`#${gridId?.SAVE}`);
    data.list = rows.map((ele) => {
      if (Number(ele.quantity) > 0) {
        return {
          quantity: ele.quantity,
          afterLocationCode: ele.locationCode,
        };
      }
      return null;
    });
    if (data.list?.filter((ele) => ele !== null)?.length === rows.length) {
      locationChangingObj?.saveFn({ data, event: locationChangingObj?.event, searchObj: locationChangingObj?.searchObj });
    } else {
      alert('수량을 입력하지 않은 아이템이 존재합니다!');
    }
  };

  const fetchList = async (searchObj) => {
    let url = `/warehouse/inventory/centerCode/locationCode/modelStock/movable/list?modelStockSeq=${locationChangingObj?.item?.modelStockSeq}`;
    if (locationChangingObj?.item?.centerCode) url += `&centerCode=${locationChangingObj?.item?.centerCode}`;
    if (searchObj?.locationCode) url += `&locationCode=${searchObj?.locationCode}`;
    await httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        const data = rs.data.map((ele) => {
          return {
            locationCode: ele.locationCode,
            availableQuantity: ele.availableQuantity,
            quantity: 0,
          };
        });

        setRows((prev) => {
          return {
            ...prev,
            SEARCH: data,
          };
        });
      }
    });
  };
  return (
    <CommonModalNew
      style={{ width: 400 }}
      title="피킹 로케이션 변경"
      visible={locationChangingObj?.visible}
      setVisible={() => {
        setLocationChangingObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      children={
        <>
          <Row>
            <div style={{ fontWeight: 'bold', fontSize: 18 }}>{locationChangingObj?.item?.locationCode}</div>
            <div style={{ color: 'gray' }}>{locationChangingObj?.item?.modelName}</div>
            <div style={{ color: 'gray' }}>{locationChangingObj?.item?.barcode}</div>
          </Row>
          {gridId?.SAVE && (
            <>
              <div className="grid-button-area only-right">
                <div
                  className="dark"
                  onClick={() => {
                    changeLocations();
                  }}
                >
                  저장
                </div>
              </div>
              <Grid
                id={gridId?.SAVE}
                rows={rows?.SAVE}
                style={{ height: 200, marginBottom: 20 }}
                columns={columns}
                settingOptions={{
                  showRowNumColumn: true,
                  showRowAllCheckBox: false,
                  showRowCheckColumn: false,
                  //
                  enableDrag: true,
                  enableMultipleDrag: true,
                  enableDragByCellDrag: true,
                  enableDrop: true,
                  dropToOthers: true,
                }}
              />
            </>
          )}
          {gridId && (
            <div className="grid-button-area" style={{ margin: 15, justifyContent: 'space-evenly' }}>
              <div className="blue fake">추가 ▲</div>
              <div className="transparent">드래그 앤 드롭으로 이동</div>
              <div className="red fake">삭제 ▼</div>
            </div>
          )}
          <Row>
            <Col lg={9}>
              <label className="col-form-label">로케이션</label>
              <InputD
                value={searchObj?.locationCode || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    locationCode: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <button
                className="btn btn-etc sssm"
                onClick={(e) => {
                  e.preventDefault();
                  fetchList(searchObj);
                }}
              >
                조회
              </button>
            </Col>
          </Row>

          {gridId?.SEARCH && (
            <Grid
              id={gridId?.SEARCH}
              rows={rows?.SEARCH}
              style={{ height: 200, marginTop: 10, marginBottom: 20 }}
              columns={columns}
              settingOptions={{
                showRowNumColumn: true,
                showRowAllCheckBox: false,
                showRowCheckColumn: false,
                //
                enableDrag: true,
                enableMultipleDrag: true,
                enableDragByCellDrag: true,
                enableDrop: true,
                dropToOthers: true,
              }}
            />
          )}
        </>
      }
    />
  );
};

const Search = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { searchObj, setSearchObj, fetchList, pagingSetting, setPagingSetting } = props;
  const [selectTypeObj, setSelectTypeObj] = useState({
    ORDER_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.ORDER_SEQ, searchObj));
  }, [selectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    if (pagingSetting?.pageNo && pagingSetting?.fetchNew) {
      // 단순히 페이지만 바뀔때
      fetchList(searchObj);
    }
  }, [pagingSetting?.fetchNew]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setPagingSetting((prev) => {
        return {
          ...prev,
          pageNo: 1,
          fetchNew: true,
        };
      });
    }
  };

  useEffect(() => {
    if (searchObj?.dateSearchValue && searchObj?.dateSearchValue[1] === '') {
      setSearchObj((prev) => {
        return {
          ...prev,
          dateSearchValue: null,
        };
      });
    }
  }, [searchObj?.dateSearchValue]);

  useEffect(() => {
    if (searchObj?.stockRemoveDate && searchObj?.stockRemoveDate[1] === '') {
      setSearchObj((prev) => {
        return {
          ...prev,
          stockRemoveDate: null,
        };
      });
    }
  }, [searchObj?.stockRemoveDate]);

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">피킹 지시번호</label>
            <InputD
              value={searchObj?.stockRemoveSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  stockRemoveSeq: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">출고 예정일</label>
            <FlatpickrD
              value={searchObj?.stockRemoveDate}
              onReset={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    stockRemoveDate: null,
                  };
                });
              }}
              onChange={(date) => {
                if (date.length === 2)
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      stockRemoveDate: [returnDateyyyymmdd(date[0]), returnDateyyyymmdd(date[1])],
                    };
                  });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">피킹 날짜</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    searchObj?.dateSearchType
                      ? {
                          label: masterOptions?.SEARCH_DATE_PICKING_OBJ[searchObj?.dateSearchType],
                          value: searchObj?.dateSearchType,
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_DATE_PICKING}
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      dateSearchType: value,
                      dateSearchValue: null,
                    });
                  }}
                />
              </Col>
              <Col md={8}>
                <FlatpickrD
                  value={searchObj?.dateSearchValue}
                  disabled={!searchObj?.dateSearchType}
                  onReset={() => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        dateSearchValue: null,
                      };
                    });
                  }}
                  onChange={(date) => {
                    if (date.length === 2)
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          dateSearchValue: [returnDateyyyymmdd(date[0]), returnDateyyyymmdd(date[1])],
                        };
                      });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">판매사</label>
            <SelectD
              value={
                searchObj?.partnerSeq
                  ? {
                      value: searchObj?.partnerSeq,
                      label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: value,
                });
              }}
              options={masterOptions?.SELLER}
            />
          </Col>
          <Col>
            <label className="col-form-label">반출창고</label>
            <SelectD
              value={
                searchObj?.centerCode
                  ? {
                      value: searchObj?.centerCode,
                      label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: option.value,
                });
              }}
              options={masterOptions?.CENTER_AUTH}
            />
          </Col>
          <Col>
            <label className="col-form-label">반출유형</label>
            <SelectD
              value={
                searchObj?.stockRemoveType
                  ? {
                      value: searchObj?.stockRemoveType,
                      label: selectlabel(searchObj?.stockRemoveType, masterOptions?.REMOVE_REASON),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  stockRemoveType: value,
                });
              }}
              options={masterOptions?.REMOVE_REASON}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">진행상태</label>
            <SelectD
              value={
                searchObj?.status
                  ? {
                      value: searchObj?.status,
                      label: selectlabel(searchObj?.status, masterOptions?.REMOVE_STATUS),
                    }
                  : null
              }
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  status: value,
                });
              }}
              options={masterOptions?.REMOVE_STATUS}
            />
          </Col>
          <Col>
            <label className="col-form-label">주문번호/송장번호</label>
            {/* orderSeq */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.ORDER_SEQ
                      ? {
                          value: selectTypeObj?.ORDER_SEQ,
                          label: selectlabel(selectTypeObj?.ORDER_SEQ, masterOptions?.ORDER_SEQ),
                        }
                      : null
                  }
                  options={masterOptions?.ORDER_SEQ}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      ORDER_SEQ: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.ORDER_SEQ}
                  value={searchObj?.[selectTypeObj?.ORDER_SEQ] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.ORDER_SEQ]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">제품/제품명</label>
            {/* model / modelName  */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_MODEL
                      ? {
                          value: selectTypeObj?.SEARCH_MODEL,
                          label: selectlabel(selectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_MODEL}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_MODEL: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_MODEL}
                  value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">유사검색여부</label>
            {/* similaritySearchFlag */}
            <div className="radio-select-area">
              <span
                className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: true,
                    };
                  });
                }}
              >
                유사검색
              </span>
              <span
                className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: false,
                    };
                  });
                }}
              >
                일치검색
              </span>
            </div>
          </Col>
          <Col />
          <Col style={{ textAlign: 'right' }}>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                setPagingSetting({
                  ...pagingSetting,
                  pageNo: 1,
                  fetchNew: true,
                });
              }}
            >
              조회
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const GridBox = (props) => {
  const {
    onEditEnded,
    rows = [],
    gridId,
    columns = [],
    detailColumns = [],
    excelDetailColumns,
    downloadExcel,
    onCellClick,
    getCheckedDataTriggerThisGrid,
    setGetCheckedDataTriggerThisGrid,
    pagingSetting,
    setPagingSetting,
    searchObj,
  } = props;
  const navigate = useNavigate();

  const excelGridRef = useRef();
  const excelDetailGridRef = useRef();
  const excelDownClicked = async (type) => {
    const data = await downloadExcel(type);
    if (data?.length > 0) {
      if (type === 'main') {
        excelGridRef.current.setGridData(data);
        excelGridRef.current.exportAsXlsx({ fileName: '반출피킹지시(메인)' });
      } else {
        excelDetailGridRef.current.setGridData(data);
        excelDetailGridRef.current.exportAsXlsx({ fileName: '반출피킹지시(상세)' });
      }
    } else {
      alert('조회되는 값이 없습니다!');
    }
  };

  const checkedArr = [
    //
    'TAKEOUT_PICKING_CENTER_CHANGE',
    'TAKEOUT_PICKING_CONFIRM',
    'TAKEOUT_PICKING_COMPLETE',
    'TAKEOUT_PICKING_COMPLETE_LOST',
    'TAKEOUT_PICKING_ROLLBACK',
    'TAKEOUT_PICKING_CANCEL',
    'REMOVE_PICKING_CLOSE',
  ];

  const currentDesignType = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    console.log(id);
    if (checkedArr?.includes(id)) {
      setGetCheckedDataTriggerThisGrid({
        type: id,
        id: `#${gridId}`,
      });
    } else if (id === 'TAKEOUT_REGIT_GO') {
      currentDesignType === 1 ? navigate('/main/takeoutWorks/takeoutRegit') : window.open('/takeoutRegit');
    } else if (id === 'TAKEOUT_REGISTER') {
      currentDesignType === 1 ? navigate('/main/takeoutWorks/TakeOutPickingInstructionRegister') : window.open('/TakeOutPickingInstructionRegister');
    } else if (id === 'TAKEOUT_REGISTER_BODY') {
      currentDesignType === 1 ? navigate('/main/takeoutWorks/storeTakeOutPickingInstructionRegister') : window.open('/storeTakeOutPickingInstructionRegister');
    } else if (id === 'TAKEOUT_PICKING_DOWNLOAD_MAIN') {
      excelDownClicked('main');
    } else if (id === 'TAKEOUT_PICKING_DOWNLOAD_DETAIL') {
      excelDownClicked('detail');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState();
  const functions = fetchUsableFunctionsInThisTab('STOCKREMOVE_TAB1');
  useMemo(() => {
    if (gridId) {
      const btns = printFunctionToBtns(functions, gridButtonhandler);
      setFunctionBtns(btns);
    }
  }, [gridId, searchObj]);
  return (
    <>
      {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
      <div style={{ width: '100%' }}>
        {gridId && (
          <>
            <Grid
              id={gridId}
              rows={rows}
              columns={columns}
              onCellClick={onCellClick}
              pagingSetting={pagingSetting}
              setPagingSetting={setPagingSetting}
              onEditEnded={onEditEnded}
              checkDisabled={(item) => item.transportStatementSeq}
              getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
              setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
              settingOptions={{
                fixedColumnCount: 4,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
            />
            <PrintGridBox gridRef={excelGridRef} columns={columns} />
            <PrintGridBox gridRef={excelDetailGridRef} columns={excelDetailColumns} />
          </>
        )}
      </div>
    </>
  );
};
