import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// redux
import { Button, Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import useDebounce from 'hooks/debounceHooks';

import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { InventoryInfoDTO, StockRemoveDetailDTOForCreateThird } from 'interface/warehouse';

import * as IGrid from 'aui-grid';

import { IModelObj, ModelPopup } from './component/model';
import { useNavigate } from 'react-router-dom';

import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import { locationService } from 'pages/INVENTORY/3location/_services/service';

import AUIGrid from 'modules/Grid';
import GridBox from 'common/grid/gridBox';
import { DetailPopup, IDetailObj } from './component/detail';
import { TAKEOUT_PICKING_REGIT_FORM } from 'envVar2';
import { serviceStore } from 'services/services';
import { generateUrlByDesignType } from 'components/LDS/utils/path';

const Index = () => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const history = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();

  const defaultSearchFilter = useMemo(() => {
    return {
      stockRemoveDate: returnDateyyyymmdd(new Date()), // 출고예정일
      centerCode: reduxUserInfo['centerCode'], // 반출창고
      partnerSeq: MASTER_OPS?.SELLER_SELLER_WHOLE.find((seller) => seller.value === reduxUserInfo['partner']) ? reduxUserInfo['partner'] : null, // 판매사
    };
  }, [reduxUserInfo]);

  const [postObj, setPostObj] = useState<StockRemoveDetailDTOForCreateThird>({ ...defaultSearchFilter, target: '', reason: '', models: [] });
  const [modelObj, setModelObj] = useState<IModelObj>();

  useEffect(() => {
    if (modelObj?.returns?.length > 0) {
      const rows = gridRef.current.getGridData();

      const forMergesArr = rows.map((ele) => ele.forMerges);
      const selected = detailObj?.selected || {};
      const isPicked = Object?.keys(modelObj?.returns[0])?.includes('availableInventorySeqs');

      modelObj?.returns?.forEach((row) => {
        const forMerges = `${row.modelStockSeq}_${row.locationCode}`;
        if (!forMergesArr?.includes(`${row.modelStockSeq}_${row.locationCode}`)) {
          // 없으면 추가, 셋팅
          selected[forMerges] = {
            availableInventorySeqs: row?.availableInventorySeqs || [],
            defectedInventorySeqs: row?.defectedInventorySeqs || [], // 간선 불러오는건 주문(이전/수리)연동된 재고만 불러오기때문에 불용재고가 없습니다. 다만 해당 재고는 사람이 직접 선택하는 부분이니까 이후에 처리하면 됩니다.
          };
          forMergesArr.push(forMerges);
          rows.push({
            ...row,
            forMerges,
            partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
            centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],

            defectedQuantity: isPicked ? row.defectedQuantity || 0 : 0,
            availableQuantity: isPicked ? row.availableQuantity || 0 : 0,

            availableInventorySeqsKr: row.availableInventorySeqs?.length || '선택',
            defectedInventorySeqsKr: row.defectedInventorySeqs?.length || '선택',
            //
          });
        } else {
          // 재고가 있는경우 inventorySeq를 합쳐준다
          const availableInventorySeqs = [...new Set(selected[forMerges]?.availableInventorySeqs?.concat(row?.availableInventorySeqs))];
          const defectedInventorySeqs = [...new Set(selected[forMerges]?.defectedInventorySeqs?.concat(row?.defectedInventorySeqs))];

          selected[forMerges] = {
            availableInventorySeqs,
            defectedInventorySeqs,
          };
          rows[forMergesArr?.indexOf(forMerges)] = {
            ...rows[forMergesArr?.indexOf(forMerges)],
            availableInventorySeqsKr: availableInventorySeqs?.length || '선택',
            etcInventorySeqsKr: defectedInventorySeqs?.length || '선택',
          };
        }
      });
      gridRef.current.setGridData(rows);
      alert('추가되었습니다!');
      setModelObj((prev) => {
        return {
          ...prev,
          returns: [],
        };
      });
      setDetailObj((prev) => {
        return {
          ...prev,
          selected,
        };
      });
    }
  }, [modelObj?.returns]);

  const [detailObj, setDetailObj] = useState<IDetailObj>();
  useEffect(() => {
    if (detailObj?.selected) {
      const items = gridRef.current.getGridData();
      if (detailObj?.selected && items?.length > 0) {
        if (items?.length > 0) {
          const rows = items?.map((row, idx) => {
            return {
              ...row,
              defectedInventorySeqsKr: detailObj?.selected?.[row?.forMerges]['defectedInventorySeqs']?.length || '선택',
              defectedInventorySeqs: detailObj?.selected?.[row?.forMerges]['defectedInventorySeqs'],
              availableInventorySeqsKr: detailObj?.selected?.[row?.forMerges]['availableInventorySeqs']?.length || '선택',
              availableInventorySeqs: detailObj?.selected?.[row?.forMerges]['availableInventorySeqs'],
            };
          });
          gridRef.current.setGridData(rows);
        }
      }
    }
  }, [detailObj?.selected]);

  const findDetail = (e: IGrid.ButtonClickEvent) => {
    if ((e.dataField === 'defectedInventorySeqsKr' && e.item.defectedQuantityForAssigned > 0) || (e.dataField === 'availableInventorySeqsKr' && e.item.availableQuantityForAssigned > 0)) {
      setDetailObj((prev) => {
        return {
          ...prev,
          visible: true,
          dataField: e.dataField,
          item: e.item,
        };
      });
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
      editable: false,
      mergeRef: 'forMerges',
      mergePolicy: 'restrict',
      cellMerge: true,
    },

    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '로케이션<br/>총 수량',
      dataField: 'totalQuantity',
    },
    {
      headerText: '이동불가능<br/>수량',
      dataField: 'unavailableQuantity',
      headerTooltip: {
        show: true,
        tooltipHtml: '<div style="width:150px;"><div>이동불가능수량은</div><div>피킹지시확정 상태이거나</div><div>로케이션 미지정된 재고입니다.</div></div>',
      },
    },
    {
      headerText: '이동가능수량',
      children: [
        {
          headerText: '양품미할당재고',
          dataField: 'availableQuantityForUnassigned',
          mergeRef: 'forMerges',
          mergePolicy: 'restrict',
          cellMerge: true,
        },

        {
          headerText: '양품할당재고',
          dataField: 'availableQuantityForAssigned',
          mergeRef: 'forMerges',
          mergePolicy: 'restrict',
          cellMerge: true,
        },
        {
          headerText: '불용미할당재고',
          dataField: 'defectedQuantityForUnassigned',
          mergeRef: 'forMerges',
          mergePolicy: 'restrict',
          cellMerge: true,
        },
        {
          headerText: '불용할당재고',
          dataField: 'defectedQuantityForAssigned',
          mergeRef: 'forMerges',
          mergePolicy: 'restrict',
          cellMerge: true,
        },
      ],
    },
    {
      headerText: '이동수량',
      children: [
        {
          headerText: '양품미할당재고',
          dataField: 'availableQuantity',
          headerTooltip: {
            show: true,
            tooltipHtml: '<div style="width:150px;"><div>양품미할당재고는</div><div>주문에 할당되지 않은</div><div>사용 가능한 안전재고입니다.</div></div>',
          },
          style: 'aui-textinputer',
          editable: true,
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            validator: (oldValue: any, newValue: any, item: any, dataField: string, fromClipboard: boolean, which: any) => {
              if (item?.availableQuantityForUnassigned === 0) return { validate: false, message: '이동가능한 수량이 없습니다' };
              if (item?.availableQuantityForUnassigned < newValue) return { validate: false, message: '이동가능한 수량 초과' };
            },
            autoThousandSeparator: true,
            onlyNumeric: true,
            allowNegative: false,
          },
        },

        {
          headerText: '양품할당재고',
          dataField: 'availableInventorySeqsKr',
          headerTooltip: {
            show: true,
            tooltipHtml: '<div style="width:150px;"><div>양품할당재고는</div><div>주문에 할당된 재고입니다.</div></div>',
          },
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: findDetail,
            disabledFunction(rowIndex, columnIndex, value, item, dataField) {
              if (item?.availableQuantityForAssigned === 0) return true;
              return false;
            },
          },
        },
        {
          headerText: '불용미할당재고',
          dataField: 'defectedQuantity',
          headerTooltip: {
            show: true,
            tooltipHtml: '<div style="width:150px;"><div>불용미할당재고는</div><div>재고조정을 통해</div><div>불용으로 전환된 재고입니다.</div></div>',
          },
          style: 'aui-textinputer',
          editable: true,
          editRenderer: {
            type: IGrid.EditRendererKind.InputEditRenderer,
            validator: (oldValue: any, newValue: any, item: any, dataField: string, fromClipboard: boolean, which: any) => {
              if (item?.defectedQuantityForUnassigned === 0) return { validate: false, message: '이동가능한 수량이 없습니다' };
              if (item?.defectedQuantityForUnassigned < newValue) return { validate: false, message: '이동가능한 수량 초과' };
            },
            autoThousandSeparator: true,
            onlyNumeric: true,
            allowNegative: false,
          },
        },
        {
          headerText: '불용할당재고',
          dataField: 'defectedInventorySeqsKr',
          headerTooltip: {
            show: true,
            tooltipHtml: '<div style="width:150px;"><div>불용할당재고는</div><div>시리얼번호를 관리하는 제품 중</div><div>박스 개봉 후 취소되어 회수입고된 재고입니다.</div></div>',
          },
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: findDetail,
            disabledFunction(rowIndex, columnIndex, value, item, dataField) {
              if (item?.defectedQuantityForAssigned === 0) return true;
              return false;
            },
          },
        },
      ],
    },
  ];

  const actionRegister = useCallback(
    useDebounce(async (dataDTO) => {
      dispatch(setLoading('POST'));
      const rs = await locationService.postWMSAction('remove/picking/v2', null, dataDTO);
      if (rs?.status === 200) {
        alert('반출피킹생성이 완료되었습니다!');
        history(generateUrlByDesignType('/takeoutWorks'));
      }
      dispatch(setLoading(null));
    }, 500),
    [],
  );

  const validationForGet = (postObj: StockRemoveDetailDTOForCreateThird) => {
    if (!postObj?.stockRemoveDate) {
      alert('출고예정일을 입력하세요');
      return false;
    }
    if (!postObj?.centerCode) {
      alert('반출창고를 입력하세요');
      return false;
    }
    if (!postObj?.reason) {
      alert('반출유형을 입력하세요');
      return false;
    }
    if (!postObj?.partnerSeq) {
      alert('판매사를 입력하세요');
      return false;
    }
    if (!postObj?.target) {
      alert('반출대상을 입력하세요');
      return false;
    }

    return true;
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    const items = gridRef?.current?.getGridData();
    if (id === 'UPLOAD_FORM') {
      window.open(TAKEOUT_PICKING_REGIT_FORM);
    } else if (id === 'UPLOAD') {
      if (postObj?.centerCode) {
        if (items?.length > 0) {
          if (window.confirm('엑셀 업로드시 기존 항목들은 초기화됩니다. 진행하시겠습니까?')) uploaderClick(id);
        } else {
          uploaderClick(id);
        }
      } else {
        alert('출발창고를 선택해주세요!');
      }
    } else if (id === 'GET_PICKED') {
      if (items?.[0]?.locationCode === '자동완성') {
        alert('엑셀업로드 항목이 있을 경우 추가 불가능합니다');
      } else {
        if (validationForGet(postObj)) {
          setModelObj({ visible: true, type: 'picked', postObj });
        }
      }
    } else if (id === 'GET_MODEL') {
      if (items?.[0]?.locationCode === '자동완성') {
        alert('엑셀업로드 항목이 있을 경우 추가 불가능합니다');
      } else {
        if (validationForGet(postObj)) {
          setModelObj({ visible: true, type: 'model', postObj });
        }
      }
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItems();
    if (items?.length > 0) {
      const wholeRows = gridRef.current.getGridData();
      if (id === 'DEL_ROW') {
        const checked = items.map((ele) => ele.rowIndex);
        const rows = wholeRows?.filter((_, idx) => !checked?.includes(idx));
        gridRef?.current?.setGridData(rows);

        const delForMerges = items?.map((ele) => ele?.item?.forMerges);
        const selected = JSON.parse(JSON.stringify(detailObj?.selected));

        delForMerges?.forEach((ele) => {
          delete selected?.[ele];
        });

        setDetailObj((prev) => {
          return {
            ...prev,
            selected,
          };
        });
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const validation = (postObj) => {
    const items = gridRef?.current?.getGridData();
    if (!postObj?.stockRemoveDate) {
      alert('기본정보에서 출고예정일을 선택해주세요');
      return null;
    }
    if (!postObj?.centerCode) {
      alert('기본정보에서 반출창고를 선택해주세요');
      return null;
    }

    if (!postObj?.reason) {
      alert('기본정보에서 반출유형을 선택해주세요');
      return null;
    }

    if (!postObj?.partnerSeq) {
      alert('기본정보에서 판매사를 선택해주세요');
      return null;
    }

    if (!postObj?.target) {
      alert('기본정보에서 반출대상을 입력해주세요');
      return null;
    }

    if (items?.length === 0) {
      alert('피킹할 상세정보가 없습니다!');
      return null;
    } else {
      const dataDTO = {
        ...postObj,
        models: items?.map((row) => {
          return {
            modelStockSeq: row.modelStockSeq,
            partnerSeq: row.partnerSeq,
            modelGroup: row.modelGroup,
            locationCode: row.locationCode,
            //
            moveAvailableQuantity: row.availableQuantity,
            moveDefectedQuantity: row.defectedQuantity,
            moveAvailableInventorySeqList: detailObj?.selected?.[row?.forMerges]['availableInventorySeqs'],
            moveDefectedInventorySeqList: detailObj?.selected?.[row?.forMerges]['defectedInventorySeqs'],
          };
        }),
      };
      return dataDTO;
    }
  };

  const uploadData = async (models) => {
    dispatch(setLoading('GET'));
    const dataDTO = {
      centerCode: postObj?.centerCode,
      models,
    };
    const rs = await serviceStore?.warehouseAction(`inventory/remove/info/excel`, 'POST', null, dataDTO);
    if (rs?.status === 200) {
      gridRef?.current?.setGridData([]);
      setModelObj((prev) => {
        return {
          ...prev,
          returns: (rs?.data as InventoryInfoDTO[])?.map((row) => {
            return {
              ...row,
              availableQuantity: row.moveAvailableQuantity,
              defectedQuantity: row.moveDefectedQuantity,
              partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
              modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
            };
          }),
        };
      });
    }
    dispatch(setLoading(null));
  };

  const KRTOVALUE = {
    centerCode: 'CENTER',
    partnerSeq: 'SELLER',
  };

  const EXCELHEADER = {
    UPLOAD: ['barcode', 'partnerSeq', 'availableQuantity', 'defectedQuantity'],
  };

  const createAction = (e) => {
    if (window.confirm('반출피킹생성을 진행하시겠습니까?')) {
      const dataDTO = validation(postObj);
      if (dataDTO) actionRegister(dataDTO);
    }
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE });
  useEffect(() => {
    if (parsedData?.data?.length > 0) {
      uploadData(parsedData?.data);
    }
  }, [parsedData]);

  return (
    <>
      <div className="page-content">
        {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
        {modelObj?.visible && <ModelPopup modelObj={modelObj} setModelObj={setModelObj} />}
        <Row style={{ textAlign: 'right' }}>
          <Col>
            <Button className="btn-blue btn" onClick={createAction}>
              반출피킹 생성
            </Button>
          </Col>
        </Row>
        <div className="presenterInput">
          <p>1. 기본정보</p>
          <Row style={{ marginLeft: 10 }}>
            <Col>
              <label className="col-form-label">*출고 예정일</label>
              <FlatpickrD
                value={postObj?.stockRemoveDate || ''}
                onChange={(value) => {
                  setPostObj({
                    ...postObj,
                    stockRemoveDate: returnDateyyyymmdd(value[0]),
                  });
                }}
                options={{
                  mode: 'single',
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*반출창고</label>
              <SelectD
                value={
                  postObj?.centerCode
                    ? {
                        value: postObj?.centerCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[postObj?.centerCode],
                      }
                    : null
                }
                options={MASTER_OPS?.CENTER_AUTH}
                onChange={(option) => {
                  const items = gridRef.current.getGridData();
                  if (postObj?.centerCode && (option as OptionItem).value !== postObj?.centerCode && items?.length > 0) {
                    if (window.confirm('창고 변경 시 입력한 정보가 초기화됩니다. 변경하시겠습니까?')) {
                      gridRef.current.setGridData([]);
                      setDetailObj((prev) => {
                        return {
                          ...prev,
                          selected: null,
                        };
                      });
                      setPostObj({
                        ...postObj,
                        centerCode: (option as OptionItem).value,
                      });
                    }
                  } else {
                    setPostObj({
                      ...postObj,
                      centerCode: (option as OptionItem).value,
                    });
                  }
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*반출유형</label>
              <SelectD
                value={
                  postObj?.reason
                    ? {
                        value: postObj?.reason,
                        label: MASTER_OBJ?.REMOVE_REASON?.[postObj?.reason],
                      }
                    : null
                }
                options={MASTER_OPS?.REMOVE_REASON}
                onChange={(option) => {
                  setPostObj({
                    ...postObj,
                    reason: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*판매사</label>
              <SelectD
                value={
                  postObj?.partnerSeq
                    ? {
                        value: postObj?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[postObj?.partnerSeq],
                      }
                    : null
                }
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                onChange={(option) => {
                  const items = gridRef.current.getGridData();
                  if (postObj?.partnerSeq && (option as OptionItem).value !== postObj?.partnerSeq && items?.length > 0) {
                    if (window.confirm('판매사 변경 시 입력한 정보가 초기화됩니다. 변경하시겠습니까?')) {
                      gridRef.current.setGridData([]);
                      setDetailObj((prev) => {
                        return {
                          ...prev,
                          selected: null,
                        };
                      });
                      setPostObj({
                        ...postObj,
                        partnerSeq: (option as OptionItem).value,
                      });
                    }
                  } else {
                    setPostObj({
                      ...postObj,
                      partnerSeq: (option as OptionItem).value,
                    });
                  }
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">*반출대상</label>
              <InputD
                value={postObj?.target || ''}
                onChange={(e) => {
                  setPostObj({
                    ...postObj,
                    target: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">특이사항</label>
              <InputD
                value={postObj?.memo || ''}
                onChange={(e) => {
                  setPostObj({
                    ...postObj,
                    memo: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <div>
          <div className="presenterInput">
            <div className="presenterGridBox">
              <p>2. 상세정보</p>
              <div className="grid-button-area">
                <ExcelDetector />
                <div id={`DEL_ROW`} className={`red`} onClick={gridButtonhandler}>
                  선택행 삭제
                </div>
                <div id={`GET_PICKED`} className={`orange`} onClick={gridButtonhandler}>
                  피킹지시대기 조회
                </div>
                <div id={`GET_MODEL`} className={`orange`} onClick={gridButtonhandler}>
                  + 항목 추가
                </div>
                <div className={`transparent`}>엑셀 업로드</div>
                <div id={`UPLOAD_FORM`} className={`green`} onClick={gridButtonhandler}>
                  양식
                </div>
                <div id={`UPLOAD`} className={`green`} onClick={gridButtonhandler}>
                  +등록
                </div>
              </div>
              <GridBox
                gridRef={gridRef}
                columns={columns}
                gridProps={{
                  showRowAllCheckBox: true,
                  showRowCheckColumn: true,
                  editable: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
