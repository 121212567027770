import { ReactNode, useEffect, useState } from 'react';
import { setLoading, setMasterOptions } from 'redux/services/menuSlice';
// redux
import { useDispatch } from 'react-redux';
import { detectMobileDevice } from 'common/util/detectDevice';
import { setDocumentTitle } from 'common/util/html';

export const defaultUnitedPageWithTopTab: TabObjectType = {
  unitedName: '',
  currentTab: 0,
  titles: [],
  containers: [],
};

export const DRIVER_TAKEOUT = 'driver_takeout';

export const BASIC_FEE = 'basic_fee';
export const ORDER_RECEIPT = 'order_receipt';
export const KPI_TAB = 'kpi_tab';
export const KPI_IN_CAPA_TAB = 'kpi_incapa_tab';

export const FC_PICKING = 'fc_picking';
export const DELIVERY_UNITED = 'delivery_united';

export const SCM_TAB = 'scm_tab';
export const INVENTORY_CONTROL_UNITED = 'united_inventoryontrol';
export const INVENTORY_REPORT_UNITED = 'united_invetoryReport';
export const TAKEOUT_UNITED = 'united_takeout';
export const BARCODE_PRINT = 'barcode_print';

export const MANAGE_ORDER = 'manage_order';
export const TAKEOUT_WORKS = 'takeout_works';
export const TRUNK_UNITED = 'united_trunk';
export const MAGAM_UNITED = 'united_magan';
export const COLLECT_UNITED = 'united_collect';
export const ORDER_FORM_UNITED = 'united_orderform';
export const SINGLE_EXCEL = 'single_excel';
export const SINGLE_PARTNER_DETAIL = 'single_partnerDetail';
export const SINGLE_SHIPPINGFEE_REGISTER = 'single_shippingFeeRegister';
export const SINGLE_SHIPPINGFEE_CALC = 'single_shippingFeeCalc';
export const SINGLE_INVENTORY_LOCATION_SELECT = 'single_inventoryLocationSelect';
export const INVENTORY_MOVE_ACTION = 'inventory_move_action';
export const PICKING_STRATEGY = 'picking_strategy';
export const DELIVERY_SCHEDULE_UNITED = 'united_deliverySchedule';
export const AUTHOR_MANAGE_UNITED = 'united_deliverySchedule';
export const FAILED_EXCEL_UNITED = 'united_failedExcel';
export const TRUNK_REGISTER = 'united_trunkRegister';
export const INVENTORY_MOVE_UNITED = 'united_inventoryMove';
export const INFRA_MAN = 'infra_man';

export type TabObjectType = {
  unitedName?: string;
  currentTab: number;
  titles: Array<string>;
  containers: Array<ReactNode>;
};

interface UnitedPageWithTopTabProps {
  tabObj: TabObjectType;
  setTabObj: React.Dispatch<React.SetStateAction<TabObjectType>>;
}

export const UnitedPageWithTopTab = ({ tabObj, setTabObj }) => {
  const dispatch = useDispatch();
  const isMobile = detectMobileDevice(window.navigator.userAgent);

  useEffect(() => {
    return () => {
      dispatch(setLoading(null)); // 설마켜져있다면 없애
      dispatch(setMasterOptions(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (tabObj?.titles?.[tabObj?.currentTab]) {
      setDocumentTitle(tabObj?.titles?.[tabObj?.currentTab]);
    }

    if (tabObj?.unitedName) {
      if (tabObj?.currentTab >= 0) {
        localStorage.setItem(tabObj?.unitedName, JSON.stringify(tabObj.currentTab));
      }
    }
  }, [tabObj?.currentTab]);

  return (
    <>
      {/* <div className={`div-top-tab ${isMobile ? 'hidden' : ''}`}> */}
      <div className={`div-top-tab`}>
        {tabObj.titles?.map((title, index) => {
          return (
            <div
              key={`toptap_${index}`}
              className={tabObj.currentTab === index ? 'bordering-current' : 'bordering'}
              onClick={() => {
                setTabObj({ ...tabObj, currentTab: index });
              }}
            >
              <li>{title}</li>
            </div>
          );
        })}
      </div>
      <div className="container-wrapper">
        {tabObj?.containers?.map((container, index) => {
          return (
            <div key={`container_${index}`} className={tabObj.currentTab === index ? 'active' : ''}>
              {container}
            </div>
          );
        })}
      </div>
    </>
  );
};
