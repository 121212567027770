import { useState, useEffect } from 'react';
import { defaultUnitedPageWithTopTab, UnitedPageWithTopTab } from 'components/template/topTab';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Container1 from './driverCs';

// options
import { setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { centerListForlabeling, codeMasterOptionReturn, driverListForLabeling, partnerListForlabeling } from 'common/master/codeMasterOptionReturn';

const Index = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    const initMasterOptions = async () => {
      const [CENTER, DRIVER, SELLER, QNA_TYPE1, QNA_TYPE2, QNA_TYPE3, QNA_STATUS] = await Promise.all([
        //
        centerListForlabeling(),
        driverListForLabeling(),
        partnerListForlabeling({ sellerFlag: true }),
        codeMasterOptionReturn('QNA_TYPE1'),
        codeMasterOptionReturn('QNA_TYPE2'),
        codeMasterOptionReturn('QNA_TYPE3'),
        codeMasterOptionReturn('QNA_STATUS'),
      ]);

      dispatch(
        setMasterOptions({
          CENTER: CENTER?.map((ele) => {
            return {
              value: ele.centerCode,
              label: ele.centerName,
            };
          }),
          DRIVER: DRIVER?.map((ele) => {
            return {
              value: ele.userId,
              label: ele.driverName,
            };
          }),
          SELLER: SELLER?.map((ele) => {
            return {
              value: ele.partnerSeq,
              label: ele.partnerName,
            };
          }),
          //
          QNA_TYPE1,
          QNA_TYPE2,
          QNA_TYPE3,
          QNA_STATUS,
        }),
      );
    };
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (masterOptions) {
      setTabObj({
        currentTab: 0,
        titles: ['설치기사 상담'],
        containers: [<Container1 tabId={'DRIVERCS'} />],
      });
    }
  }, [masterOptions]);

  return <>{masterOptions && tabObj?.titles.length > 0 && <UnitedPageWithTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
