import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tab1 from './puchaseIn';
// options
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { nullingMASTER } from 'redux/services/menuSlice';

export const PURCHASEIN_MASTER = [
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_NETWORK_AUTH',
  'SELLER_SUPPLIER_AUTH',
  'SELLER_SELLER_AUTH',
  'SELLER_WHOLE',
  'INFRA',
  //
  'BRAND',
  'PO_STATUS',
  'DESC_DIFF',
  'LOGISTIC_TYPE',
  'WORK_TYPE',
  'MODEL_GROUP',
  'PURCHASE_ORDER_TYPE',
  //
  'SEARCH_MODEL',
  {
    SEARCH_SEQ: [
      { value: '', label: '납품번호' },
      { value: '', label: '주문번호' },
      { value: '', label: '견적번호' },
    ],
  },
  {
    SEARCH_DATE: [
      { value: '', label: '주문일' },
      { value: '', label: '요구납기일' },
      { value: '', label: '배달예정일' },
      { value: '', label: '입고완료일' },
    ],
  },
  //
  'PICK_FLAG',
  'USE_FLAG',
];
const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(PURCHASEIN_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PURCHASE_IN: <Tab1 tabId={'PURCHASE_IN'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
