import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { SelectD } from 'components/reactstrap/reactstrap';
import {
  WarehouseReceiptInSearchDTO,
  WarehouseReceiptOutSearchDTO,
  WarehouseReceiptStorageSearchDTO,
  WarehouseReceiptInDTO,
  WarehouseReceiptOutDTO,
  WarehouseReceiptStorageDTO,
} from '../../../_interface/warehouse';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';
// component
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';
import { urlConvertor } from '..';

export interface ITypeObj {
  visible?: boolean;
  tabId: string;
  data: WarehouseReceiptInDTO & WarehouseReceiptOutDTO & WarehouseReceiptStorageDTO;
  searchObj?: WarehouseReceiptInSearchDTO & WarehouseReceiptOutSearchDTO & WarehouseReceiptStorageSearchDTO;
  fetchFn?: (searchObj: WarehouseReceiptInSearchDTO & WarehouseReceiptOutSearchDTO & WarehouseReceiptStorageSearchDTO) => {};
}

export const Types = ({ typeObj, setTypeObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [crudObj, setCrudObj] = useState<WarehouseReceiptInDTO & WarehouseReceiptOutDTO & WarehouseReceiptStorageDTO>();

  useEffect(() => {
    if (typeObj?.data) {
      setCrudObj(typeObj?.data);
    }
  }, []);

  const saveType = async (crudObj) => {
    const rs = await serviceStore.warehouseAction(`fee/center/${urlConvertor[typeObj?.TARGET]}/save`, 'POST', null, [crudObj]);
    if (rs?.status === 200) {
      alert('저장되었습니다');
      if (typeObj?.fetchFn) typeObj?.fetchFn(typeObj?.searchObj);
      setTypeObj(null);
    }
  };

  return (
    <CommonModalNew
      title={typeObj?.data ? '유형 상세' : '유형 추가'}
      style={{ width: 600 }}
      visible={typeObj?.visible}
      setVisible={() => setTypeObj(null)}
      rightTitle={
        <>
          <button
            className="btn-secondary btn ssm"
            onClick={(e) => {
              e.preventDefault();
              saveType(crudObj);
            }}
          >
            {typeObj?.data ? '수정' : '등록'}
          </button>
        </>
      }
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">판매사</label>
              <SelectD
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                value={
                  crudObj?.partnerSeq
                    ? {
                        label: MASTER_OBJ?.SELLER_WHOLE?.[crudObj?.partnerSeq],
                        value: crudObj?.partnerSeq,
                      }
                    : null
                }
                onChange={(option) => {
                  setCrudObj((prev) => {
                    return {
                      ...prev,
                      partnerSeq: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">창고</label>
              <SelectD
                options={MASTER_OPS?.CENTER_AUTH}
                value={
                  crudObj?.centerCode
                    ? {
                        label: MASTER_OBJ?.CENTER_AUTH?.[crudObj?.partnerSeq],
                        value: crudObj?.partnerSeq,
                      }
                    : null
                }
                onChange={(option) => {
                  setCrudObj((prev) => {
                    return {
                      ...prev,
                      partnerSeq: (option as OptionItem).value,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          {typeObj?.TARGET?.includes('_IN_') && (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">*운송유형</label>
                  <SelectD
                    options={MASTER_OPS?.LOGISTIC_TYPE}
                    value={
                      crudObj?.logisticType
                        ? {
                            label: MASTER_OBJ?.LOGISTIC_TYPE?.[crudObj?.logisticType],
                            value: crudObj?.logisticType,
                          }
                        : null
                    }
                    onChange={(option) => {
                      setCrudObj((prev) => {
                        return {
                          ...prev,
                          logisticType: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">*작업유형</label>
                  <SelectD
                    options={MASTER_OPS?.WORK_TYPE}
                    value={
                      crudObj?.workType
                        ? {
                            label: MASTER_OBJ?.WORK_TYPE?.[crudObj?.workType],
                            value: crudObj?.workType,
                          }
                        : null
                    }
                    onChange={(option) => {
                      setCrudObj((prev) => {
                        return {
                          ...prev,
                          workType: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          {typeObj?.TARGET?.includes('_OUT_') && (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">*작업유형</label>
                  <SelectD
                    options={MASTER_OPS?.WORK_TYPE}
                    value={
                      crudObj?.workType
                        ? {
                            label: MASTER_OBJ?.WORK_TYPE?.[crudObj?.workType],
                            value: crudObj?.workType,
                          }
                        : null
                    }
                    onChange={(option) => {
                      setCrudObj((prev) => {
                        return {
                          ...prev,
                          workType: (option as OptionItem).value,
                          palletType: null,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">*파렛트유형</label>
                  <SelectD
                    isDisabled={crudObj?.workType !== 'PALLET'}
                    options={MASTER_OPS?.PALLET_TYPE}
                    value={
                      crudObj?.palletType
                        ? {
                            label: MASTER_OBJ?.PALLET_TYPE?.[crudObj?.palletType],
                            value: crudObj?.palletType,
                          }
                        : null
                    }
                    onChange={(option) => {
                      setCrudObj((prev) => {
                        return {
                          ...prev,
                          palletType: (option as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          {typeObj?.TARGET?.includes('_STORAGE_') && (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">로케이션구분</label>
                  <SelectD
                    options={MASTER_OPS?.LOCATION_CATEGORY}
                    // value={}
                    onChange={(option) => {
                      setCrudObj((prev) => {
                        return {
                          ...prev,
                          locationCategory: (option as OptionItem)?.value,
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">로케이션규격</label>
                  <SelectD
                    options={MASTER_OPS?.LOCATION_SIZE}
                    // value={
                    //   crudObj?.locationSize
                    //     ? {
                    //         value: crudObj?.locationSize,
                    //         label: MASTER_OBJ?.LOCATION_SIZE?.[crudObj?.locationSize],
                    //       }
                    //     : null
                    // }
                    onChange={(option) => {
                      // setCrudObj((prev) => {
                      //   return {
                      //     ...prev,
                      //     locationSize: (option as OptionItem)?.value,
                      //   };
                      // });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">적용단위</label>
                  <SelectD
                    options={MASTER_OPS?.DATE_UNIT}
                    value={
                      crudObj?.dateUnit
                        ? {
                            label: MASTER_OBJ?.DATE_UNIT?.[crudObj?.dateUnit],
                            value: crudObj?.dateUnit,
                          }
                        : null
                    }
                    onChange={(option) => {
                      setCrudObj((prev) => {
                        return {
                          ...prev,
                          dateUnit: (option as OptionItem).value,
                          day: null,
                        };
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">적용단위</label>
                  {/* <SelectD
                    options={MASTER_OPS?.MONTH}
                    value={
                      crudObj?.day
                        ? {
                            label: MASTER_OBJ?.MONTH?.[crudObj?.day],
                            value: crudObj?.day,
                          }
                        : null
                    }
                    onChange={(option) => {
                      setCrudObj((prev) => {
                        return {
                          ...prev,
                          day: (option as OptionItem).value,
                        };
                      });
                    }}
                  /> */}
                </Col>
              </Row>
            </>
          )}
        </>
      }
    />
  );
};
