import { useEffect, useState } from 'react';
import { Row, Col, Input, Label } from 'reactstrap';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AccessUserDetailDTO, UserDTOForAdmin, UserListDTO } from 'interface/user';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { setLoading } from 'redux/services/menuSlice';
import { serviceStore } from 'services/services';
import { updateToken } from 'common/util/authFunc';
import { setLocalStorageTokens, decodeAndSetUserInfo } from 'common/util/localStorage';
import { setAuth } from 'redux/services/authSlice';

export interface IAuthDetailObj {
  visible: boolean;
  item?: UserListDTO;
  searchObj?: any;
  fetchFn: (searchObj) => {};
}

export const UserDetailPopup = ({ authDetailObj, setAuthDetailObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [userInfos, setUserInfos] = useState<AccessUserDetailDTO>();

  useEffect(() => {
    if (authDetailObj?.item) fetchDetail(authDetailObj?.item?.userId);
  }, []);

  const fetchDetail = async (userId) => {
    dispatch(setLoading('GET'));

    const data = (await serviceStore.userAction(`${userId}`, 'GET', null))?.data;
    if (data) setUserInfos(data);
    dispatch(setLoading(null));
  };

  const userValidator = (userInfos) => {
    if (!userInfos) {
      alert('내용을 입력하세요!');
      return false;
    }

    if (!userInfos.userName) {
      alert('이름을 입력하세요!');
      return false;
    }

    if (!userInfos.phoneNumber) {
      alert('연락처를 입력하세요!');
      return false;
    }

    if (userInfos?.password !== userInfos?.password2) {
      alert('비밀번호와 비밀번호 재입력값이 다릅니다!');
      return false;
    }

    return true;
  };

  const makeData = (userInfos: AccessUserDetailDTO) => {
    return userInfos;
  };

  const updateUser = async (data: UserDTOForAdmin) => {
    const rs = await serviceStore.userAction(`access/user/save`, 'PUT', null, data);
    if (rs?.status === 200) {
      alert('사용자 정보가 수정되었습니다!');
      const refreshedTokens = await updateToken();
      if (refreshedTokens) {
        setLocalStorageTokens(refreshedTokens);
        const userInfo = decodeAndSetUserInfo(refreshedTokens);
        dispatch(setAuth(userInfo));
      }
      setAuthDetailObj(null);
    }
  };

  const resetPassword = async () => {
    if (window.confirm('비밀번호 초기화 진행하시겠습니까?\n "123456789a"로 초기화됩니다')) {
      const rs = await serviceStore.userAction('password/admin', 'POST', null, {
        userIds: [authDetailObj?.item?.userId],
        resetPassword: '123456789a',
      });
      if (rs?.status === 200) {
        alert('초기화되었습니다');
      }
    }
  };

  return (
    <CommonModalNew
      title={`사용자 ${authDetailObj?.item ? '상세보기' : '추가'}`}
      style={{ width: 1000 }}
      visible={authDetailObj?.visible}
      rightTitle={
        <>
          {authDetailObj?.item && (
            <button
              className={'btn btn-secondary'}
              onClick={() => {
                resetPassword();
              }}
            >
              비밀번호 초기화
            </button>
          )}
          <button
            className={'btn btn-secondary ssm'}
            onClick={() => {
              if (userValidator(userInfos)) {
                const data = makeData(userInfos);
                if (data) {
                  updateUser(data);
                }
              }
            }}
          >
            {authDetailObj?.item ? '수정' : '추가'}
          </button>
        </>
      }
      setVisible={() => {
        setAuthDetailObj(null);
      }}
      children={
        <div style={{ minHeight: 500 }}>
          <div className="div-top-tab">
            <div className={'bordering-current'}>
              <li>기본정보</li>
            </div>
          </div>
          <div style={{ display: 'flex', zIndex: 13 }}>
            <div style={{ width: 270, display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  backgroundColor: '#eff2f7',
                  border: '1px solid #e0e0e0',
                  marginTop: 30,
                  width: 180,
                  height: 180,
                  borderRadius: 180,
                  position: 'absolute',
                }}
              ></div>
            </div>
            <div style={{ width: 645 }}>
              <Row>
                <Col>
                  <label className="col-form-label">*이름</label>
                  <InputD
                    value={userInfos?.userName || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        userName: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">*사용자ID</label>
                  <InputD
                    disabled={authDetailObj?.item}
                    value={userInfos?.userId || ''}
                    onChange={(e) => {
                      setUserInfos((prev) => {
                        return {
                          ...prev,
                          userId: e.target.value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">*사용자 유형</label>
                  <SelectD
                    hasNull={false}
                    options={MASTER_OPS?.USER_CODE}
                    value={
                      userInfos?.userCode
                        ? {
                            value: userInfos?.userCode,
                            label: MASTER_OBJ?.['USER_CODE']?.[userInfos.userCode],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setUserInfos({
                        ...userInfos,
                        userCode: (option as OptionItem)?.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">소속 조직</label>
                  <SelectD
                    options={MASTER_OPS?.JOJIK_GROUP}
                    value={
                      userInfos?.groupSeq
                        ? {
                            value: userInfos?.groupSeq,
                            label: MASTER_OBJ?.['JOJIK_GROUP']?.[userInfos.groupSeq],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setUserInfos({
                        ...userInfos,
                        groupSeq: (option as OptionItem)?.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">소속파트너</label>
                  <SelectD
                    options={MASTER_OPS?.SELLER_AUTH}
                    value={
                      userInfos?.partnerSeq
                        ? {
                            value: userInfos?.partnerSeq,
                            label: MASTER_OBJ?.['SELLER_AUTH']?.[userInfos.partnerSeq],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setUserInfos({
                        ...userInfos,
                        partnerSeq: (option as OptionItem)?.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">소속인프라</label>
                  <SelectD
                    options={MASTER_OPS?.INFRA}
                    value={
                      userInfos?.infraSeq
                        ? {
                            value: userInfos?.infraSeq,
                            label: MASTER_OBJ?.['INFRA']?.[userInfos.infraSeq],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setUserInfos({
                        ...userInfos,
                        infraSeq: (option as OptionItem)?.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">소속창고</label>
                  <SelectD
                    options={MASTER_OPS?.CENTER_AUTH}
                    value={
                      userInfos?.centerCode
                        ? {
                            value: userInfos?.centerCode,
                            label: MASTER_OBJ?.['CENTER_AUTH']?.[userInfos.centerCode],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setUserInfos({
                        ...userInfos,
                        centerCode: (option as OptionItem)?.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">소속판매채널</label>
                  <SelectD
                    options={MASTER_OPS?.SALES_CHANNEL}
                    value={
                      userInfos?.salesChannelSeq
                        ? {
                            value: userInfos?.salesChannelSeq,
                            label: MASTER_OBJ?.['SALES_CHANNEL']?.[userInfos.salesChannelSeq],
                          }
                        : null
                    }
                    onChange={(option) => {
                      setUserInfos({
                        ...userInfos,
                        salesChannelSeq: (option as OptionItem)?.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">*연락처</label>
                  <InputD
                    value={userInfos?.phoneNumber || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        phoneNumber: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label className="col-form-label">이메일</label>
                  <InputD
                    value={userInfos?.email || ''}
                    onChange={(e) => {
                      setUserInfos({
                        ...userInfos,
                        email: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      }
    />
  );
};
