import { KeyboardEvent } from 'react';
import { Col, Row, Form } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { ISearchBoxProps } from 'hooks/grid/useSearch';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { SalesChannelSearchDTO } from 'interface/order';

export const SearchBox = (props: ISearchBoxProps<SalesChannelSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };
  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">판매조직명</label>
          <SelectD
            value={
              searchObj?.type
                ? {
                    value: searchObj?.type,
                    label: MASTER_OBJ?.SALES_CHANNEL_TYPE?.[searchObj?.type],
                  }
                : null
            }
            options={MASTER_OPS?.SALES_CHANNEL_TYPE}
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                type: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매채널명</label>
          <InputD
            value={searchObj?.salesChannelName || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                salesChannelName: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
