import CommonModalNew from 'components/modal/commonModalNew';
import { ParcelInvoiceAccidentDTO, ParcelInvoiceAccidentSearchDTO } from '../../../../_interface/parcel';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { setLoading } from 'redux/services/menuSlice';
import { serviceStore } from 'services/services';

export interface ISolveObj {
  visible?: boolean;
  items?: ParcelInvoiceAccidentDTO[];
  searchObj?: ParcelInvoiceAccidentSearchDTO;
  fetchFn?: (searchObj: ParcelInvoiceAccidentSearchDTO) => {};
}

export const SolvePopup = ({ solveObj, setSolveObj }) => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState<string>();

  const solveAction = async ({ solution, items }) => {
    const data = items?.map((ele) => {
      return {
        seq: ele.parcelInvoiceAccidentSeq,
      };
    });

    dispatch(setLoading('POST'));
    const rs = await serviceStore.parcelAction(`invoice/accident/solve`, 'POST', { solution }, data);
    if (rs?.status === 200) {
      alert('사고 접수 처리가 완료 되었습니다!');
      if (solveObj?.fetchFn && solveObj?.searchObj) solveObj?.fetchFn(solveObj?.searchObj);
      setSolveObj(null);
    }
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title={'사고 접수'}
      style={{ width: 350 }}
      visible={solveObj?.visible}
      setVisible={() => {
        setSolveObj(null);
      }}
      rightTitle={
        <button
          className="btn btn-orange ssm"
          onClick={(e) => {
            solveAction({ solution: inputs, items: solveObj?.items });
          }}
        >
          처리
        </button>
      }
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">*처리내용</label>
              <textarea
                style={{ height: 300 }}
                value={inputs || ''}
                onChange={(e) => {
                  setInputs(e.target.value);
                }}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};
