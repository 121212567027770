import { useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './remain';
import Tab2 from './list';

// options
import { nullingMASTER } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';

export const MANAGE_PARCEL_REMAIN_MASTER = ['CENTER_AUTH', 'CENTER_WHOLE'];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_REMAIN_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PARCEL_REMAIN_TASK: <Tab1 tabId={'PARCEL_REMAIN_TASK'} />,
            PARCEL_REMAIN_LIST: <Tab2 tabId={'PARCEL_REMAIN_LIST'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
