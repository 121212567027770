import AUIGrid from 'modules/Grid';
import { useEffect, useState } from 'react';

type FrontSearchDataProps = {
  gridRef: React.MutableRefObject<AUIGrid>;
  initDetailSearchObj: { [key: string]: any }; // 초기 검색 조건을 담은 객체
  detailSelectType: { [key: string]: null | string }; // 검색자로 사용할 selectType 옵션명을 key로 가지는 객체
  convertKeyValue: { [key: string]: (any) => string }; // 변환할 필드명을 key로, 변환된 string을 리턴하는 익명함수를 value로 가진 객체
};

export function useFrontSearchData({ gridRef, initDetailSearchObj, detailSelectType, convertKeyValue }: FrontSearchDataProps) {
  const [gridData, setGridData] = useState([]);
  const [filteredGridData, setFilteredGridData] = useState([]);

  const [detailSearchObj, setDetailSearchObj] = useState(initDetailSearchObj);
  const [detailSelectTypeObj, setDetailSelectTypeObj] = useState(detailSelectType);

  useEffect(() => {
    handleSubmitFiltering();
  }, [gridData]);

  const resetFilters = () => {
    setGridData([...gridData]);
    setDetailSearchObj(initDetailSearchObj);
    setDetailSelectTypeObj(detailSelectType);
  };

  const handleSubmitFiltering = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    let filterKeys = Object.keys(detailSearchObj);

    const filterObj = {};
    for (let filter of filterKeys) {
      if (detailSearchObj[filter] !== null && detailSearchObj[filter] !== '' && detailSearchObj[filter] !== undefined) {
        filterObj[filter] = detailSearchObj[filter];
      }
    }
    filterKeys = Object.keys(filterObj);

    // eslint-disable-next-line array-callback-return
    const filteredData = gridData?.filter((row) => {
      let hasMatched = true;

      for (let filter of filterKeys) {
        if (row[filter] === null || row[filter] === undefined) {
          hasMatched = false; //NOTE: 값이 없다면 false
        }
        if (typeof filterObj[filter] !== 'boolean' && typeof row[filter] === 'string') {
          if (!String(row[filter]).toUpperCase().includes(filterObj[filter].toUpperCase())) {
            hasMatched = false; //NOTE: 문자열은 uppercase로 변환 후 포함하는지 비교
          }
        }
        if (typeof filterObj[filter] !== 'boolean' && typeof row[filter] === 'number') {
          if (filter === 'partnerSeq' && String(row[filter]) !== String(filterObj[filter])) {
            hasMatched = false; //NOTE: 판매사의 경우 문자열로 변환후 일치하는지 비교
          } else if (filter !== 'partnerSeq' && !String(row[filter]).includes(filterObj[filter])) {
            hasMatched = false; //NOTE: 숫자는 문자열로 변환후 포함하는지 비교
          }
        }
        if (typeof filterObj[filter] === 'boolean' && filterObj[filter] !== row[filter]) {
          hasMatched = false; //NOTE: boolean값은 값 그대로 일치하는지 비교
        }
      }

      if (hasMatched) return row;
    });

    setFilteredGridData(filteredData);

    const dataKr =
      filteredData.length > 0 &&
      filteredData.map((row) => {
        const keyValues = returnKeyValues(convertKeyValue, row);

        return {
          ...row,
          ...keyValues,
        };
      });

    gridRef?.current && gridRef.current.setGridData(dataKr || []);
  };

  const convertFieldValue = (fieldName: string, value: string) => {
    // 공백은 공백 처리
    if (value === '') {
      return '';
    }
    // 숫자 타입인 필드는 number 타입으로 변환
    if (fieldName.includes('Seq')) {
      return parseInt(value);
    }
    // 나머지는 문자열로 리턴
    return value;
  };

  const returnKeyValues = (obj, row) => {
    const columnKeys = Object.keys(obj);

    let keyValues = {};
    for (let key of columnKeys) {
      keyValues[key] = obj[key](row);
    }
    return keyValues;
  };

  return {
    gridData,
    setGridData,
    filteredGridData,
    detailSelectTypeObj,
    setDetailSelectTypeObj,
    detailSearchObj,
    setDetailSearchObj,
    resetFilters,
    handleSubmitFiltering,
    convertFieldValue,
    returnKeyValues,
  };
}
