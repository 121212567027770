import { OptionItem } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { ExtraServiceDTO, ExtraServiceSearchDTO } from '../../../_interface/order';
import { orderService } from 'pages/ORDER/1order/_services/service';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';
import { stringValueToNumber } from 'common/util/regExp';
export interface IDetailObj {
  visible?: boolean;
  data?: ExtraServiceDTO;
  searchObj?: ExtraServiceSearchDTO;
  fetchFn?: (data: ExtraServiceSearchDTO) => {};
}

type ExtendExtraServiceDTO = ExtraServiceDTO & {
  priceString?: string;
};
export const DetailPopup = ({ detailObj, setDetailObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [dataDTO, setDataDTO] = useState<ExtendExtraServiceDTO>();

  useEffect(() => {
    if (detailObj?.data)
      setDataDTO({
        ...detailObj?.data,
        priceString: detailObj?.data?.price,
      });
  }, []);

  const validator = (data: ExtraServiceDTO) => {
    if (!dataDTO?.partnerSeq) {
      alert('판매사는 필수값입니다');
      return false;
    }
    if (!dataDTO?.serviceName) {
      alert('서비스명은 필수값입니다');
      return false;
    }
    if (!dataDTO?.priceString) {
      alert('금액은 필수값입니다');
      return false;
    }
    return true;
  };

  const saveDetail = async () => {
    if (validator(dataDTO)) {
      const data = { ...dataDTO };
      data['price'] = Number(data?.priceString);
      delete data['priceString'];
      const rs = await orderService.uploadService([data]);
      if (rs?.status === 200) {
        alert('저장되었습니다!');
        detailObj?.fetchFn(detailObj?.searchObj);
        setDetailObj({ visible: false });
      }
    }
  };

  return (
    <CommonModalNew
      style={{ width: 350 }}
      title={`서비스 ${detailObj?.data ? '수정' : '추가'}`}
      visible={detailObj?.visible}
      setVisible={() => setDetailObj(null)}
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">*판매사</label>
              <SelectD
                options={MASTER_OPS?.SELLER_AUTH}
                value={
                  dataDTO?.partnerSeq
                    ? {
                        value: dataDTO?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_AUTH?.[dataDTO?.partnerSeq],
                      }
                    : null
                }
                onChange={(option) => {
                  setDataDTO({
                    ...dataDTO,
                    partnerSeq: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*서비스명</label>
              <InputD
                value={dataDTO?.serviceName || ''}
                onChange={(e) => {
                  setDataDTO({
                    ...dataDTO,
                    serviceName: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*금액</label>
              <InputD
                type="number"
                value={dataDTO?.priceString ?? ''}
                onChange={(e) => {
                  setDataDTO({
                    ...dataDTO,
                    priceString: stringValueToNumber(e.target.value),
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'right' }}>
              <button
                className="btn-orange btn"
                onClick={(e) => {
                  e.preventDefault();
                  saveDetail();
                }}
              >
                저장
              </button>
            </Col>
          </Row>
        </>
      }
    />
  );
};
