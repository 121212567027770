import styled from 'styled-components';
import { LDSLabelSM } from '../atoms/Typography';
import { COLORS, TRANSITIONS, VALUES } from 'common/constants/appearance';
import { FiPlus, FiMinus } from 'react-icons/fi';
import LDSTooltip, { TooltipProps } from '../modules/Tooltip';
import { calculateElementPositionAndSize } from 'common/util/handleElement';
import { useTooltip } from '../hooks/useTooltip';
import React from 'react';

type ListHeaderProps = {
  $type: 'TOGGLE' | 'STATIC' | 'OPTION';
  $title: string;
  $isExpanded?: boolean;
  $icon?: React.ReactNode;
  onClick?: () => void;
  $tooltip?: Omit<TooltipProps, '$data'>;
};

function LDSListHeader({ $icon, $title, $type, $isExpanded, onClick, $tooltip }: ListHeaderProps) {
  const { showTooltip, setShowTooltip, targetRef } = useTooltip();

  return (
    <>
      <LDSListHeaderWrapper
        ref={targetRef}
        className={$type === 'TOGGLE' && 'interactive'}
        $type={$type}
        onClick={$type === 'TOGGLE' ? onClick : () => {}}
        onMouseOverCapture={() => $type === 'TOGGLE' && $tooltip && setShowTooltip(true)}
        onMouseOutCapture={() => $type === 'TOGGLE' && $tooltip && setShowTooltip(false)}
        onMouseDownCapture={() => $tooltip && setShowTooltip(false)}
      >
        {$icon && $icon}
        <LDSLabelSM $weight={'SEMIBOLD'}>{$title}</LDSLabelSM>
        {$type === 'TOGGLE' && !$isExpanded && <FiPlus />}
        {$type === 'TOGGLE' && $isExpanded && <FiMinus />}
      </LDSListHeaderWrapper>
      {showTooltip && (
        <LDSTooltip
          $data={calculateElementPositionAndSize(targetRef?.current)}
          $direction={$tooltip.$direction}
          $anchorPosition={$tooltip.$anchorPosition}
          $description={$tooltip.$description}
        ></LDSTooltip>
      )}
    </>
  );
}

export default React.memo(LDSListHeader);

type ListHeaderWrapperProps = Omit<ListHeaderProps, '$icon' | '$title'>;

const LDSListHeaderWrapper = styled.div<ListHeaderWrapperProps>`
  display: flex;
  gap: 6px;
  align-items: center;
  border-radius: ${(props) => (props.$type === 'OPTION' ? 0 : VALUES.RADIUS)}px;
  padding: 6px 10px;
  padding-bottom: ${(props) => (props.$type === 'OPTION' ? 2 : 6)}px;

  transition: all 200ms ${TRANSITIONS.BEZIER};
  cursor: ${(props) => (props.$type === 'TOGGLE' ? 'pointer' : 'default')};

  background-color: inherit;
  position: sticky;
  top: ${(props) => (props.$type === 'TOGGLE' ? null : '-4px')};
  z-index: 1;

  & * {
    cursor: inherit;
    pointer-events: none;
  }

  color: ${(props) => (props.$type === 'OPTION' ? COLORS.GRAY_04 : COLORS.GRAY_05)};

  & > svg {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
  }

  & > label {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.interactive {
    &:hover {
      background-color: ${COLORS.BLACK_004};
      color: ${COLORS.GRAY_06};
    }
    &:active {
      background-color: ${COLORS.BLACK_008};
      color: ${COLORS.GRAY_06};
    }
  }
`;
