import CommonModalNew from 'components/modal/commonModalNew';
import { InvoiceListDTO } from 'interface/order';
import { deliveryService } from 'pages/DELIVERY/_services/service';

export interface IData {
  rawData?: InvoiceListDTO[];
  hasDriver?: InvoiceListDTO[];
  thisDriver?: InvoiceListDTO[];
}

export interface IMsgObj {
  visible?: boolean;
  type?: 'thisDriver' | 'hasDriver';
  data?: IData;
}

export const MessagePops = ({ msgObj, setMsgObj }) => {
  const msgAll = async (id: string) => {
    let invoiceSeqs;
    if (id === 'all-confirm') {
      invoiceSeqs = msgObj?.data?.[msgObj?.type]?.filter((ele) => ele.confirmFlag)?.map((ele) => ele.invoiceSeq);
    } else if (id === 'no-time') {
      invoiceSeqs = msgObj?.data?.[msgObj?.type]?.filter((ele) => ele.deliveryDate && !ele.promiseFlag)?.map((ele) => ele.invoiceSeq);
    } else if (id === 'no-date') {
      invoiceSeqs = msgObj?.data?.[msgObj?.type]?.filter((ele) => !ele.deliveryDate)?.map((ele) => ele.invoiceSeq);
    }
    if (invoiceSeqs?.length > 0) {
      if (window.confirm('문자메세지를 보내시겠습니까?')) {
        const dataDTO = { invoiceSeqs };
        const rs = await deliveryService.sendMessageSchedule(dataDTO);
        if (rs?.status === 200) {
          alert('문자메세지를 전송하였습니다!');
          setMsgObj(null);
        }
      }
    }
  };

  const handleStatus = (e) => {
    const id = e.target.id;
    msgAll(id);
  };

  return (
    <CommonModalNew
      //
      title={'알림메세지 전송'}
      style={{ width: 350 }}
      visible={msgObj?.visible}
      setVisible={() => setMsgObj(null)}
      children={
        <>
          <div className="grid-button-area center">
            <div className="orange" id={'all-confirm'} onClick={handleStatus}>
              시간확정
              {msgObj?.type === 'thisDriver' ? `(${msgObj?.data?.thisDriver?.filter((ele) => ele.confirmFlag).length}건)` : `(${msgObj?.data?.hasDriver?.filter((ele) => ele.confirmFlag).length}건)`}
            </div>
            <div className="orange" id={'no-time'} onClick={handleStatus}>
              시간미지정
              {msgObj?.type === 'thisDriver'
                ? `(${msgObj?.data?.thisDriver?.filter((ele) => ele.deliveryDate && !ele.promiseFlag).length}건)`
                : `(${msgObj?.data?.hasDriver?.filter((ele) => ele.deliveryDate && !ele.promiseFlag).length}건)`}
            </div>
            <div className="orange" id={'no-date'} onClick={handleStatus}>
              날짜미지정
              {msgObj?.type === 'thisDriver'
                ? `(${msgObj?.data?.thisDriver?.filter((ele) => !ele.deliveryDate).length}건)`
                : `(${msgObj?.data?.hasDriver?.filter((ele) => !ele.deliveryDate).length}건)`}
            </div>
          </div>
        </>
      }
    />
  );
};
