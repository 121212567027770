import { useEffect, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Presenter from './presenter';
import axios from 'axios';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { TAKE_OUT_PICKING_REGISTER } from 'envVar';
import { useSelector } from 'react-redux';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

const Container = (props) => {
  const token = localStorage.getItem('accessToken');
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);
  const [Loading, setLoading] = useState(false);
  const [gridId, setGridId] = useState();

  const { reduxUserInfo } = useSelector((state) => state.auth);

  const defaultSearchFilter = useMemo(() => {
    return {
      stockRemoveDate: returnDateyyyymmdd(new Date()), // 반출예정일
      centerCode: reduxUserInfo['centerCode'], // 반출창고
    };
  }, [reduxUserInfo]);

  const [searchObj, setSearchObj] = useState({
    reason: 'MAKER_EXPORT',
    partnerSeq: 46,
    target: null,
    ...defaultSearchFilter,
  });
  const [getAllGridDataTriggerThisGrid, setGetAllGridDataTriggerThisGrid] = useState();
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  const [searchModelObj, setSearchModelObj] = useState();
  const [rows, setRows] = useState();

  useEffect(() => {
    initGridId();
  }, []);

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'remove-thisRows') {
        removeThisRows(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'create-picking') {
        createPicking(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const removeThisRows = (items) => {
    const stockSeqs = [];
    items.forEach((item) => stockSeqs.push(item.modelStockSeq));

    const _rows = [...rows];
    const filtered = _rows.filter((row) => !stockSeqs.includes(row.modelStockSeq));
    setRows(filtered);
  };

  const initGridId = () => {
    setGridId(`yangpanChulgoRegister_${uuidv4()}`);
  };

  const columns = [
    {
      headerText: '출고창고',
      dataField: 'centerCodeKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '현재보관수량',
      children: [
        {
          headerText: '양품',
          dataField: 'totalAvailableQuantity',
          editable: false,
        },
        {
          headerText: '불용',
          dataField: 'totalDefectedQuantity',
          editable: false,
        },
        {
          headerText: '이동가능양품',
          dataField: 'availableQuantity',
          editable: false,
        },
        {
          headerText: '이동가능불용',
          dataField: 'defectedQuantity',
          editable: false,
        },
      ],
    },
    {
      headerText: '반출재고수량',
      children: [
        {
          headerText: '양품',
          dataField: 'requestAvailableQuantity',
          dataType: 'numeric',
          style: 'my-custom-aui',
          renderer: {
            type: 'TemplateRenderer',
            aliasFunction: function (rowIndex, columnIndex, value, headerText, item) {
              // 엑셀, PDF 등 내보내기 시 값 가공 함수
              return value;
            },
          },
        },
        {
          headerText: '불용',
          dataField: 'requestDefectedQuantity',
          dataType: 'numeric',
          style: 'my-custom-aui',
          renderer: {
            type: 'TemplateRenderer',
            aliasFunction: function (rowIndex, columnIndex, value, headerText, item) {
              // 엑셀, PDF 등 내보내기 시 값 가공 함수
              return value;
            },
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (searchModelObj?.returns) {
      setSearchObj({
        ...searchObj,
        target: searchModelObj.returns[0].target,
      });
      const _returns = JSON.parse(JSON.stringify(searchModelObj?.returns));
      const oldRowsStockSeqs = [];
      rows.forEach((row) => {
        oldRowsStockSeqs.push(row.modelStockSeq);
      });

      const _rows = [...rows];
      let jungbokFlag = false;
      _returns.forEach((row) => {
        if (_rows.length === 0) {
          const _newRow = { ...row };
          _newRow.requestQuantity = row.requestQuantity;
          _newRow.canAvailableQuantity = row.requestQuantity;
          _newRow.canDefectedQuantity = 0;
          _newRow.centerCodeKr = MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode];
          _newRow.modelGroupKr = MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup];
          _newRow.partnerSeqKr = MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq];
          _rows.push(_newRow);
        } else {
          if (!oldRowsStockSeqs.includes(row.modelStockSeq)) {
            // 없으면 추가
            const _newRow = { ...row };
            _newRow.requestQuantity = row.requestQuantity;
            _newRow.canAvailableQuantity = row.requestQuantity;
            _newRow.canDefectedQuantity = 0;
            _newRow.centerCodeKr = MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode];
            _newRow.modelGroupKr = MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup];
            _newRow.partnerSeqKr = MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq];
            _rows.push(_newRow);
          } else {
            jungbokFlag = true;
          }
        }
      });
      if (jungbokFlag) {
        alert('중복 되는 값을 제외하고 추가되었습니다');
      }
      setRows(_rows);
      setSearchModelObj(null); //초기화
    }
  }, [searchModelObj?.returns]);

  function listingmodel(rows) {
    const result = [];
    rows.forEach((rowdata, idx) => {
      const obj = {};
      obj['modelStockSeq'] = rowdata?.modelStockSeq;
      obj['locationCode'] = rowdata?.locationCode;
      obj['stockRemoveSeq'] = rowdata?.stockRemoveSeq;
      obj['availableQuantity'] = rowdata?.requestQuantity;
      // obj["defectedQuantity"] = rowdata?.defectedQuantity;
      result.push(obj);
    });
    return result;
  }

  const createPicking = async (items) => {
    if (validCreatePicking(items)) {
      try {
        let json = {
          stockRemoveDate: searchObj.stockRemoveDate,
          centerCode: searchObj.centerCode,
          target: searchObj.target,
          partnerSeq: searchObj.partnerSeq,
          reason: searchObj.reason,
          externalRemoveNumber: items[0].drNo,
          stockRemoveDetails: listingmodel(items),
        };
        const rs = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}${TAKE_OUT_PICKING_REGISTER}`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: json,
        });

        if (rs.data.result) {
          alert('반출 피킹 생성에 성공했습니다');
          setRows([]);
          return;
        }
      } catch (error) {
        if (error.response.data && error.response.data?.message) {
          alert(error.response.data?.message);
          return;
        } else {
          alert('반출 피킹 생성에 실패했습니다');
        }
        return;
      }
    } else {
      alert('수량이 없는 재고가 있습니다!');
    }
  };

  const validCreatePicking = (items) => {
    let validFlag = true;
    items.forEach((item) => {
      if (item.availableQuantity === 0) {
        validFlag = false;
      }
    });
    return validFlag;
  };

  return (
    <Presenter
      Loading={Loading}
      setLoading={setLoading}
      gridId={gridId}
      columns={columns}
      rows={rows}
      setRows={setRows}
      //obj
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      searchModelObj={searchModelObj}
      setSearchModelObj={setSearchModelObj}
      MASTER_OBJ={MASTER_OBJ}
      MASTER_OPS={MASTER_OPS}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      getAllGridDataTriggerThisGrid={getAllGridDataTriggerThisGrid}
      setGetAllGridDataTriggerThisGrid={setGetAllGridDataTriggerThisGrid}
    />
  );
};

export default Container;
