import { ReactNode, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { IPagingGrid, PARCEL_GRID_PAGE_ROW_COUNT } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
// service
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useGridButton from 'hooks/grid/useGridButton';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
// component
import { PagingListDTO } from 'interface/util';
import { ParcelInvoiceRemainDTO, ParcelInvoiceRemainSearchDTO } from '../../_interface/parcel';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';

export const defaultOrderDetailObj = {
  visible: false,
  item: null,
};

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const labellingKr = (data: PagingListDTO<ParcelInvoiceRemainDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: ParcelInvoiceRemainSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.parcelAction(`invoice/remain/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<ParcelInvoiceRemainDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const downloadExcel = async (type: 'main') => {
    const prompt = window.confirm(`엑셀다운로드를 시작합니다. \n※ 많은 양의 데이터는 30초 이상의 시간이 소요될 수 있습니다.`);
    if (prompt) {
      dispatch(setLoading('GET'));
      const { list } = labellingKr(
        {
          list: (await serviceStore.parcelAction(`invoice/remain/excel`, 'GET', searchObj, null))?.data as ParcelInvoiceRemainDTO[],
        },
        true,
      );
      if (type === 'main') {
        excelGridRef.current.setGridData(list);
        excelGridRef.current.exportAsXlsx({ fileName: '잔류조회' });
      }
      dispatch(setLoading(null));
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'PARCEL_REMAIN_EXCELDOWN') {
      downloadExcel('main');
    }
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: PARCEL_GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const columns: IGrid.Column[] = [
    {
      headerText: '잔류처리일',
      dataField: 'registerDatetime',
    },
    {
      headerText: '현재위치',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '운송장번호',
      dataField: 'parcelInvoiceNumber',
    },
    {
      headerText: '잔류처리자',
      dataField: 'registerId',
    },
  ];

  return (
    <div className="page-content">
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}

        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            pageRowCount: PARCEL_GRID_PAGE_ROW_COUNT,
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
