import { KeyboardEvent } from 'react';

import { Col, Row, Form } from 'reactstrap';
import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD } from 'components/reactstrap/reactstrap';

// redux
import { GroupSearchDTO } from 'interface/user';

export const SearchBox = (props: ISearchBoxProps<GroupSearchDTO>) => {
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">조직명</label>
          <InputD
            value={searchObj?.groupName || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                groupName: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">조직장명</label>
          <InputD
            value={searchObj?.userName || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                userName: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
