import { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { IdefaultSearchObj } from './useSearch';
import { PageRowCountChangeEvent } from 'aui-grid';
import { ForwardedRef, MutableRefObject, useEffect, useRef, useState } from 'react';
import { Pager } from 'interface/util';
import { AUIGRID_ID_PRE } from 'modules/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setDataInUrlMain, setDataInUrlSearchObj, setDataInUrlTotal } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { numberThousandComma, stringToThousandComma } from 'common/util/counting';

interface PagingConnectorProps {
  mode?: string;
  count?: number;
}
export const initialSearchObj = {
  pageNo: -1,
  pageSize: GRID_PAGE_ROW_COUNT,
};

interface IuseSearchGridPaging<T> {
  initialSearchObj: T;
  gridRef: ForwardedRef<IPagingGrid>;
  fetchTotal?: (searchObj: T) => Promise<any>;
  fetchPaging?: (searchObj: T) => Promise<any>;
  validationForFetchList?: (searchObj: T) => boolean;
}

const useSearchGridPaging = <T extends IdefaultSearchObj>(props: IuseSearchGridPaging<T>) => {
  const menu = useSelector((state: RootState) => state.menu);
  const { tabs, dataInUrl } = menu;
  const dispatch = useDispatch();
  const location = useLocation();
  const { initialSearchObj, gridRef, fetchTotal, fetchPaging, validationForFetchList = () => true } = props;

  const [gridId, setGridId] = useState<string>();
  const [searchObj, setSearchObj] = useState<T>(initialSearchObj);
  const [pagingConnector, setPagingConnector] = useState<PagingConnectorProps | null>();
  const [pagination, setPagination] = useState<Pager>();

  useEffect(() => {
    if (dataInUrl[tabs[location.pathname]]) {
      const { searchObj, main, total } = dataInUrl[tabs[location.pathname]];
      if (searchObj) setSearchObj(searchObj);
      if (typeof gridRef !== 'function') {
        if (total) gridRef.current?.setFooter(total);
        if (main) gridRef.current?.setGridData(main?.list);
      }
    }
    if (typeof gridRef !== 'function' && gridRef?.current) {
      bindCustomPageBtns();
      bindPagingGridFunction(gridRef);
    }

    return () => {
      // unbindCustomPageBtns();
    };
  }, []);

  useEffect(() => {
    if (searchObj) {
      if (tabs[location.pathname]) dispatch(setDataInUrlSearchObj({ key: tabs[location.pathname], value: searchObj }));
    }
  }, [searchObj]);

  // pageSize가 바뀌면 1로 바뀌면서 전체 fetch

  useEffect(() => {
    if (searchObj?.pageNo > 0) {
      if (fetchTotal && searchObj?.pageNo === 1) {
        // 1페이지일때만 둘다 터트림
        wrappedFetchHasTotal(searchObj);
      } else {
        wrappedFetch(searchObj);
      }
    } else if (searchObj?.pageNo === 0) {
      setSearchObj((prev) => {
        return {
          ...prev,
          pageNo: 1,
        };
      });
    }
  }, [searchObj?.pageNo, searchObj?.pageSize]);

  // 새 조회를 할때
  const wrappedFetchList = async (searchObj: T) => {
    if (searchObj?.pageNo === 1)
      setSearchObj((prev) => {
        return {
          ...prev,
          pageNo: 0,
        };
      });
    else
      setSearchObj((prev) => {
        return {
          ...prev,
          pageNo: 1,
        };
      });
  };

  const wrappedFetchHasTotal = async (searchObj: T) => {
    if (validationForFetchList(searchObj) && searchObj?.pageNo > 0) {
      const [total, main] = await Promise.all([fetchTotal(searchObj), fetchPaging(searchObj)]);
      if (total) {
        if (typeof gridRef !== 'function') gridRef?.current?.setFooter(total);
        dispatch(setDataInUrlTotal({ value: total, key: tabs[location.pathname] }));
      }
      if (main) {
        if (typeof gridRef !== 'function') {
          gridRef?.current?.closeFilterLayer();
          gridRef?.current?.clearFilterAll();
          gridRef?.current?.setGridData(main?.list);
        }
        dispatch(setDataInUrlMain({ value: main, key: tabs[location.pathname] }));
        if (main?.pagination) {
          setPagination(main?.pagination);
          labeling(`${searchObj?.pageNo} / ${main?.pagination?.totalPage}`, main?.pagination?.totalCount);
        }
      }
    }
  };

  const wrappedFetch = async (searchObj: T) => {
    if (validationForFetchList(searchObj) && searchObj?.pageNo > 0) {
      const main = await fetchPaging(searchObj);
      if (main) {
        if (typeof gridRef !== 'function') {
          gridRef.current.closeFilterLayer();
          gridRef.current.clearFilterAll();
          gridRef.current.setGridData(main?.list);
        }
        dispatch(setDataInUrlMain({ value: main, key: tabs[location.pathname] }));
        if (main?.pagination) {
          setPagination(main?.pagination);
          labeling(`${searchObj?.pageNo} / ${main?.pagination.totalPage}`, main?.pagination?.totalCount);
        }
      }
    }
  };

  const bindPagingGridFunction = (gridRef: ForwardedRef<IPagingGrid>) => {
    bindPageRowChange(gridRef);
  };

  const bindCustomPageBtns = () => {
    if (!gridId) {
      const gridId = `#${AUIGRID_ID_PRE}${(gridRef as MutableRefObject<IPagingGrid>)?.current?.props?.name}`;
      setGridId(gridId);

      const existPrev = document.querySelector(`${gridId} .aui-grid-paging-prev`);
      const existNext = document.querySelector(`${gridId} .aui-grid-paging-next`);
      existPrev?.addEventListener('click', prevBtnClick);
      existNext?.addEventListener('click', nextBtnClick);
      const pagingText = document.querySelector(`${gridId} .aui-grid-paging-panel`);
      const pagingLabel = document.createElement('span');
      const totalLabel = document.createElement('span');

      if (typeof gridRef !== 'function') {
        if (gridRef?.current?.props?.gridProps?.showPageRowSelect) {
          pagingLabel.className = 'custom-paging-text';
          pagingText?.appendChild(pagingLabel);
          totalLabel.className = 'custom-total-text';
          pagingText?.appendChild(totalLabel);
        } else {
          pagingLabel.className = 'custom-paging-text disabled';
          pagingText?.appendChild(pagingLabel);
        }
      }
    }
  };

  useEffect(() => {
    if (pagingConnector?.count) {
      if (searchObj?.pageNo + pagingConnector?.count < 1) {
        alert('첫 페이지 입니다.');
      } else if (searchObj?.pageNo + pagingConnector?.count > pagination?.totalPage) {
        alert('마지막 페이지 입니다.');
      } else {
        setSearchObj((prev) => {
          return {
            ...prev,
            pageNo: searchObj?.pageNo + pagingConnector?.count,
          };
        });
      }
      setPagingConnector((prev) => {
        return {
          ...prev,
          mode: null,
          count: null,
        };
      });
    }
  }, [pagingConnector?.count]);

  const labeling = (str: string, totalPage: number) => {
    const pagingLabel = document.querySelector(`${gridId} .custom-paging-text`);
    const totalLabel = document.querySelector(`${gridId} .custom-total-text`);
    if (pagingLabel) {
      pagingLabel.innerHTML = str;
    } else {
      // 그럴일 없겠지만..
      const pagingText = document.querySelector(`${gridId} .aui-grid-paging-panel`);
      const pagingLabel = document.createElement('span');
      pagingLabel.className = 'custom-paging-text';
      pagingLabel.innerText = str;
      pagingText?.appendChild(pagingLabel);
    }
    if (totalLabel) {
      totalLabel.innerHTML = `총 ${totalPage ? numberThousandComma(totalPage) : '-'}건`;
    }
  };

  const prevBtnClick = () => {
    setPagingConnector((prev) => {
      return {
        ...prev,
        mode: 'pageNo',
        count: -1,
      };
    });
  };

  const nextBtnClick = () => {
    setPagingConnector((prev) => {
      return {
        ...prev,
        mode: 'pageNo',
        count: 1,
      };
    });
  };

  const bindPageRowChange = (gridRef: ForwardedRef<IPagingGrid>) => {
    (gridRef as MutableRefObject<IPagingGrid>)?.current?.bind('pageRowCountChange', function (e: PageRowCountChangeEvent) {
      setSearchObj((prev) => {
        return {
          ...prev,
          pageNo: 1,
          pageSize: e.rowCount,
        };
      });
    });

    (gridRef as MutableRefObject<IPagingGrid>)?.current?.bind('filtering', function (e: PageRowCountChangeEvent) {
      if (typeof gridRef !== 'function') {
        gridRef.current.setAllCheckedRows(false);
      }
    });
  };

  // method

  const getCheckedRowItems = () => {
    const data = (gridRef as MutableRefObject<IPagingGrid>).current.getCheckedRowItemsAll();
    return data;
  };

  const getCheckedRowItemsWithIndex = () => {
    const data = (gridRef as MutableRefObject<IPagingGrid>).current.getCheckedRowItems();
    return data;
  };

  return {
    searchObj,
    setSearchObj,
    wrappedFetchList,
    // method
    getCheckedRowItems,
    getCheckedRowItemsWithIndex,
  };
};

export default useSearchGridPaging;
