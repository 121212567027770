import { Row, Col } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';
import { INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION } from 'envVar';

import Grid from 'components/grid/auiGrid';
import { setLoading } from 'redux/services/menuSlice';
import { v4 as uuidv4 } from 'uuid';
import CommonModalNew from 'components/modal/commonModalNew';
const menuOptions = [
  { label: '바코드', value: 'barcode' },
  { label: 'SKU', value: 'modelStockSeq' },
  { label: '외부SKU', value: 'skuNumber' },
  { label: '제품', value: 'model' },
  { label: '제품명', value: 'modelName' },
];

export const SearchModelForRefurModal = ({ modelModalObj, setModelModalObj }) => {
  const dispatch = useDispatch();
  const { masterOptions, MASTER_OPS, MASTER_OBJ } = useSelector((state) => state.menu);
  const { reduxUserInfo } = useSelector((state) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);
  const [gridId, _] = useState(`refur_search_model_${uuidv4()}`);
  const [rows, setRows] = useState([]);
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState();

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'item-to') {
        addAfterModel(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'model-add') {
        addModel(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const [searchObj, setSearchObj] = useState({
    centerCode: modelModalObj?.searchObj?.centerCode,
    partnerSeq: modelModalObj?.searchObj?.partnerSeq,
    modelType: null,
    modelGroup: null,
    menuSelect: null,
    menuValue: null,
    ...defaultSearchFilter,
  });

  const columns = [
    {
      headerText: '출고창고',
      dataField: 'centerCodeKr',
      editable: false,
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          editable: false,
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          editable: false,
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          editable: false,
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          editable: false,
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          editable: false,
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          editable: false,
        },
        {
          headerText: '제품',
          dataField: 'model',
          editable: false,
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          editable: false,
        },
      ],
    },
    {
      headerText: '현재보관수량',
      children: [
        {
          headerText: '양품',
          dataField: 'totalAvailableQuantity',
          editable: false,
        },
        {
          headerText: '불용',
          dataField: 'totalDefectedQuantity',
          editable: false,
        },
        {
          headerText: '리퍼가능양품',
          dataField: 'availableQuantity',
          editable: false,
        },
        {
          headerText: '리퍼가능불용',
          dataField: 'defectedQuantity',
          editable: false,
        },
      ],
    },
    {
      headerText: '변경수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantityTo',
          dataType: 'numeric',
          style: 'my-custom-aui',
          editable: true,
          renderer: {
            type: 'TemplateRenderer',
            aliasFunction: function (rowIndex, columnIndex, value, headerText, item) {
              return value;
            },
          },
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantityTo',
          dataType: 'numeric',
          style: 'my-custom-aui',
          editable: true,
          renderer: {
            type: 'TemplateRenderer',
            aliasFunction: function (rowIndex, columnIndex, value, headerText, item) {
              return value;
            },
          },
        },
      ],
    },
  ];

  const changeColumns = [
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          editable: false,
        },
      ],
    },
  ];

  const addSearchObj = (url, searchObj) => {
    if (searchObj?.centerCode) url += `&centerCode=${searchObj?.centerCode}`;
    if (searchObj?.partnerSeq) url += `&partnerSeq=${searchObj?.partnerSeq}`;

    if (searchObj?.modelType) url += `&modelType=${searchObj?.modelType}`;
    if (searchObj?.modelGroup) url += `&modelGroup=${searchObj?.modelGroup}`;
    if (searchObj?.similaritySearchFlag) url += `&similaritySearchFlag=${searchObj?.similaritySearchFlag}`;

    if (searchObj?.menuSelect) url += `&${searchObj?.menuSelect}=${searchObj?.menuValue}`;

    return url;
  };

  const fetchList = async (type) => {
    dispatch(setLoading('GET'));
    let url;
    if (type === 'model') {
      url = INVENTORY_MODEL_FOR_LINEHAULMAKE_WITH_LOCATION + `?`;
    } else {
      url = '/warehouse/model/stock/excel?';
    }
    url = addSearchObj(url, searchObj);

    await httpClient.get(url).then((rs) => {
      if (rs?.status === 200) {
        rs.data?.forEach((row) => {
          row.centerCodeKr = MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode];
          row.partnerSeqKr = MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq];
          row.modelGroupKr = MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup];
          row.availableQuantityTo = 0;
          row.defectedQuantityTo = 0;
        });
        setRows(rs.data);
      }
    });
    dispatch(setLoading(null));
  };

  const addModel = (items) => {
    let validFlag = true;

    items.forEach((ele) => {
      if (ele?.defectedQuantityTo === 0 && ele?.availableQuantityTo === 0) {
        alert('변경수량이 입력되지 않은 제품이 있습니다!');
        validFlag = false;
      }
      if (ele?.availableQuantity < ele.availableQuantityTo) {
        alert('리퍼가능 양품보다 변경수량 양품이 많습니다!');
        validFlag = false;
      }
      if (ele?.defectedQuantity < ele.defectedQuantityTo) {
        alert('리퍼가능 불용보다 변경수량 불용이 많습니다!');
        validFlag = false;
      }
    });

    if (validFlag) {
      alert('추가되었습니다!');
      setModelModalObj({
        ...modelModalObj,
        data: items,
      });
      fetchList(modelModalObj?.type);
    }
  };

  const addAfterModel = (items) => {
    setModelModalObj({
      ...modelModalObj,
      visible: false,
      afterData: items[0],
    });
  };

  return (
    <CommonModalNew
      title={`${modelModalObj?.type === 'model' ? '리퍼용 제품추가' : '변경제품 선택'}`}
      style={{ width: modelModalObj?.type === 'model' ? 1500 : 1000 }}
      visible={modelModalObj?.visible}
      setVisible={() => {
        setModelModalObj({
          ...modelModalObj,
          visible: false,
          data: null,
        });
      }}
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">제품타입</label>
              <SelectD
                options={MASTER_OPS?.MODEL_TYPE}
                value={
                  searchObj?.modelType
                    ? {
                        value: searchObj?.modelType,
                        label: MASTER_OBJ?.MODEL_TYPE?.[searchObj?.modelType],
                      }
                    : null
                }
                onChange={({ value }) => {
                  setSearchObj({
                    ...searchObj,
                    modelType: value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">제품그룹</label>
              <SelectD
                options={MASTER_OPS?.MODEL_GROUP}
                value={
                  searchObj?.modelGroup
                    ? {
                        value: searchObj?.modelGroup,
                        label: MASTER_OBJ?.MODEL_GROUP?.[searchObj?.modelGroup],
                      }
                    : null
                }
                onChange={({ value }) => {
                  setSearchObj({
                    ...searchObj,
                    modelGroup: value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">제품/바코드</label>
              <div style={{ display: 'flex' }}>
                <SelectD
                  options={menuOptions}
                  value={
                    searchObj?.menuSelect
                      ? {
                          value: searchObj?.menuSelect,
                          label: selectlabel(searchObj?.menuSelect, menuOptions),
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      menuSelect: value,
                    });
                  }}
                />
                <InputD
                  value={searchObj?.menuValue || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      menuValue: e.target.value,
                    });
                  }}
                />
              </div>
            </Col>
            <Col>
              <label className="col-form-label">유사검색여부</label>
              <div className="radio-select-area">
                <span
                  className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                  onClick={() => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        similaritySearchFlag: true,
                      };
                    });
                  }}
                >
                  유사검색
                </span>
                <span
                  className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                  onClick={() => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        similaritySearchFlag: false,
                      };
                    });
                  }}
                >
                  일치검색
                </span>
              </div>
            </Col>
            <Col>
              <button
                className="btn btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  fetchList(modelModalObj?.type);
                }}
              >
                조회
              </button>
            </Col>
          </Row>
          {gridId && (
            <>
              <div className="grid-button-area">
                {modelModalObj?.type === 'model' ? (
                  <div
                    className="orange"
                    onClick={() => {
                      setGetCheckedDataTriggerThisGrid({
                        type: 'model-add',
                        id: `#${gridId}`,
                      });
                    }}
                  >
                    +제품추가
                  </div>
                ) : (
                  <div
                    className="orange"
                    onClick={() => {
                      setGetCheckedDataTriggerThisGrid({
                        type: 'item-to',
                        id: `#${gridId}`,
                      });
                    }}
                  >
                    +변경제품 선택
                  </div>
                )}
              </div>
              <Grid
                style={{ height: 500 }}
                id={gridId}
                rows={rows}
                columns={modelModalObj?.type === 'model' ? columns : changeColumns}
                settingOptions={{
                  isRowAllCheckCurrentView: true,
                  showRowAllCheckBox: true,
                  showRowCheckColumn: true,
                  rowCheckToRadio: modelModalObj?.type === 'model' ? false : true,
                  // rowCheckDisabledFunction: function (rowIndex, isChecked, item) {
                  //   if (modelModalObj?.type === 'model' && item.availableQuantity + item.availableQuantity === 0) {
                  //     // 이름이 "Anna" 인 경우 엑스트라 행 체크박스 비활성화 처리함.
                  //     return false;
                  //   }
                  //   return true; // 일반 활성화 된 체크박스로 표현
                  // },
                }}
                getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
                setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
              />
            </>
          )}
        </>
      }
    />
  );
};
