import { ReactNode, useMemo, useRef, useState } from 'react';

// utils
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';

// hooks
import { PagingListDTO } from 'interface/util';
import { SearchBox } from './component/searchBox';
import PrintGridBox from 'common/grid/printGridBox';
import { detectMobileDevice } from 'common/util/detectDevice';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { ParcelInvoiceAccidentDTO, ParcelInvoiceAccidentSearchDTO } from '../../../_interface/parcel';
import { ISolveObj, SolvePopup } from './component/solve';
import { serviceStore } from 'services/services';

const Index = (tabId) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const isMobile = detectMobileDevice(window.navigator.userAgent);
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const columns: IGrid.Column[] = [
    {
      headerText: '사고접수일시',
      dataField: 'registerDatetime',
    },
    {
      headerText: '운송장번호',
      dataField: 'parcelInvoiceNumber',
    },
    {
      headerText: 'HBL',
      dataField: 'houseNumber',
    },
    {
      headerText: 'SBL',
      dataField: 'subNumber',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '양륙항',
      dataField: 'portCodeKr',
    },
    {
      headerText: '고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'receiverName',
        },
        {
          headerText: '우편번호',
          dataField: 'receiverZipcode',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddr',
        },
        {
          headerText: '전화번호1',
          dataField: 'receiverPhone',
        },
      ],
    },
    {
      headerText: '배달지',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '사고유형',
      dataField: 'typeKr',
    },
    {
      headerText: '사고상세',
      dataField: 'detailKr',
    },
    {
      headerText: '사고품목',
      dataField: 'itemKr',
    },
    {
      headerText: '내부포장',
      dataField: 'interiorPackageKr',
    },
    {
      headerText: '외부포장',
      dataField: 'exteriorPackageKr',
    },
    {
      headerText: '사고접수자',
      dataField: 'registerId',
    },
    {
      headerText: '처리내용',
      dataField: 'solution',
    },
    {
      headerText: '사고처리일시',
      dataField: 'solveDatetime',
    },
    {
      headerText: '사고처리자',
      dataField: 'solveId',
    },
  ];

  const labellingKr = (data: PagingListDTO<ParcelInvoiceAccidentDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],
        portCodeKr: MASTER_OBJ?.PORT_CODE?.[row?.portCode],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
        typeKr: MASTER_OBJ?.ACCIDENT_TYPE?.[row?.type],
        detailKr: MASTER_OBJ?.ACCIDENT_DETAIL?.[row?.detail],
        itemKr: MASTER_OBJ?.ACCIDENT_ITEM?.[row?.item],
        interiorPackageKr: MASTER_OBJ?.INTERIOR_PACKAGE?.[row?.interiorPackage],
        exteriorPackageKr: MASTER_OBJ?.EXTERIOR_PACKAGE?.[row?.exteriorPackage],
        receiverAddr: row.receiverAddr1 || '' + row.receiverAddr2 || '',
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: ParcelInvoiceAccidentSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.parcelAction(`invoice/accident/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<ParcelInvoiceAccidentDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: 500,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: (await serviceStore.parcelAction('invoice/accident/excel', 'GET', searchObj))?.data as ParcelInvoiceAccidentDTO[] }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `사고접수` });

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'PARCEL_ACCIDENT_EXCELDOWN_MAIN') {
      downloadExcel('main');
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'PARCEL_ACCIDENT_COMPLETE') {
        setSolveObj({
          visible: true,
          items,
          searchObj,
          fetchFn: (searchObj) => wrappedFetchList(searchObj),
        });
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab('PARCEL_LIST_ACCIDENT', 'MAIN');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const [solveObj, setSolveObj] = useState<ISolveObj>();

  return (
    <div className="page-content" style={{ paddingTop: isMobile ? 0 : '3rem' }}>
      {solveObj?.visible && <SolvePopup solveObj={solveObj} setSolveObj={setSolveObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            pageRowCount: 500,
            pageRowSelectValues: [500],
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
