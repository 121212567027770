export const LABELS = {
  AUTH_LOGIN_TITLE_KR: '로그인',
  AUTH_LOGIN_LABEL_KR: '로그인',
  FIELD_ID_KR: '아이디',
  FIELD_PASSWORD_KR: '비밀번호',
  OPTION_KEEP_SIGNIN_KR: '로그인 상태 유지',
  SEARCH_KR: '조회하기',
  OPTIMIZE_FILTER_KR: '필터 간소화',
} as const;

export const DESCRIPTIONS = {
  LOGIN_TAGLINE_KR: '대형물류 업무는\n홈인 풀필먼트 시스템\n하나로 끝.',
  LOGIN_TAGLINE_EN: 'We guarantee\nyour properties,\nbe in the right place\nat the right time.',
  LOGIN_TAGLINE_EN_2440: 'We guarantee your properties,\nbe in the right place at the right time.',
  FAVORITE_EMPTY_KR: '즐겨찾기한 메뉴가 표시됩니다.',
} as const;

export const MENUS = {
  FAVORITE_KR: '즐겨찾기',
  DASHBOARD_KR: '대시보드',
  NOTIFICATION_KR: '알림',
  CARGO_KR: '통관',
  PARCEL_KR: '택배',
  IPGO_KR: '입고',
  OUT_KR: '출고',
  INVENTORY_KR: '재고',
  ORDER_KR: '주문',
  DELIVERY_KR: '배송',
  STATISTICS_KR: '통계',
  FEE_KR: '정산',
  ETC_KR: '기타',
  IPGO_PURCHASE_ORDER_KR: '발주 입고',
  SEARCH_KR: '검색',
  HOME_KR: '홈',
} as const;

export const FILTERS = {
  PERIOD_KR: '기간 검색',
  REGISTER_DATE_KR: '등록일',
  REGISTER_NAME_KR: '등록자',
  SEARCH_NUMBER_KR: '번호 검색',
  ORDDER_NUMBER_KR: '주문 번호',
  SCAN_ORDER_KR: '스캔순번',
  BIG_BAG_NUMBER: 'bigBag No.',
  BATCH_NUMBER: 'batch No.',
  HBL_NUMBER: 'HBL',
  SBL_NUMBER: 'SBL',
  RECEIVER_NAME_KR: '받는 사람명',
  CONTACT_NUMBER_KR: '연락처',
  STATUS_KR: '진행 상태',
  IPGO_ORDER_NUMBER_KR: '발주 번호',
  PARCELL_TYPE_KR: '택배사',
  PARTNER_NAME_KR: '판매사',
  PARTNER_NAME_FORWARDER_KR: '판매사(포워더)',
  IPGO_STORAGE_KR: '입고 창고',
  IPGO_ORDER_STATUS_KR: '진행 상태',
  DISEMBARKATION_PORT_KR: '양륙항',
  COLLECTION_TRANSFER_COUNT_KR: '집화전송차수',
  PRODUCT_INFO: '제품 정보',
  BOX_SIZE_KR: '크기',
} as const;
