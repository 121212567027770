import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { InventoryLocationListDTO, InventoryLocationSearchDTO } from 'interface/warehouse';
import { inventoryService } from '../../_service/service';
import { BulyongModal, IBulyongModalObj } from './component/bulyongModal';
import { LOCATION_AVAILABLE_CENTER } from 'common/util/grid';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { IMapObj } from 'components/grid/locationGrid/locationGrid';
import { MapModal } from 'components/grid/locationGrid/mapModal';
import { ILocationDetailModalObj, ILocationDetailSearchDTOAvailableType, LocationDetailModal } from './component/locationDetailModal';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const staticsGridRef = useRef<IPagingGrid>();
  const gridRef = useRef<IPagingGrid>();

  const excelGridRef = useRef<AUIGrid>();
  const [bulyongModalObj, setBulyongModalObj] = useState<IBulyongModalObj>();
  const [locationDetailModalObj, setLocationDetailModalObj] = useState<ILocationDetailModalObj>();
  const [mapObj, setMapObj] = useState<IMapObj>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  const fetchMap = (e: IGrid.ButtonClickEvent) => {
    if (e.text) {
      setMapObj({
        visible: true,
        activeData: e.item,
      });
    }
  };

  const fetchInventoryDetail = (e: IGrid.ButtonClickEvent) => {
    // 로케이션 조회 모달을 호출한다.
    // 컬럼명이 합계면 availableType을 빈 값으로, 양품이면 AVAILABLE, 불용이면 DEFECTED, 기타인 경우 ETC를 할당하여 요청을 보낸다.
    const { centerCode, modelStockSeq, locationCode } = e.item;

    let availableType: ILocationDetailSearchDTOAvailableType = '';
    if (e.dataField === 'availableQuantity' || e.dataField === 'movableAvailableQuantity') availableType = 'AVAILABLE';
    if (e.dataField === 'defectedQuantity' || e.dataField === 'movableDefectedQuantity') availableType = 'DEFECTED';
    if (e.dataField === 'etcQuantity' || e.dataField === 'movableEtcQuantity') availableType = 'ETC';

    setLocationDetailModalObj({
      visible: true,
      data: {
        centerCode,
        modelStockSeq,
        locationCode,
        availableType,
      },
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '지도보기',
      dataField: 'showMap',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: fetchMap,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.INVENTORYLOCATION_SHOWMAP;
        },
      },
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션코드',
      dataField: 'locationCode',
      style: 'sub-total-color',
    },
    {
      headerText: '로케이션 유형',
      dataField: 'locationTypeKr',
      style: 'sub-total-color',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
      width: 200,
    },

    {
      headerText: '제품명',
      dataField: 'modelName',
      width: 200,
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '합계',
      dataField: 'quantity',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: fetchInventoryDetail,
      },
    },
    {
      headerText: '총 보관 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchInventoryDetail,
          },
        },
        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          headerTooltip: {
            show: true,
            tooltipHtml: `"회수"된 제품이 아니면 주문정보가 없을 수 있습니다`,
          },
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchInventoryDetail,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchInventoryDetail,
          },
        },
      ],
    },
    {
      headerText: '이동가능 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'movableAvailableQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchInventoryDetail,
          },
        },
        {
          headerText: '불용',
          dataField: 'movableDefectedQuantity',
          headerTooltip: {
            show: true,
            tooltipHtml: `"회수"된 제품이 아니면 주문정보가 없을 수 있습니다`,
          },
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchInventoryDetail,
          },
        },
        {
          headerText: '기타',
          dataField: 'movableEtcQuantity',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: fetchInventoryDetail,
          },
        },
      ],
    },
    {
      headerText: '재고 보관일수',
      children: [
        {
          headerText: '~30일',
          dataField: 'before30Day',
        },
        {
          headerText: '31~60일',
          dataField: 'before60Day',
        },
        {
          headerText: '61일~',
          dataField: 'after60Day',
        },
      ],
    },
  ];

  const staticsColumns: IGrid.Column[] = [
    {
      headerText: '',
      dataField: 'title',
    },
    {
      headerText: '합계',
      dataField: 'ForTotal',
    },
    {
      headerText: '피킹(대표)',
      dataField: 'ForPicking',
    },
    {
      headerText: '보충',
      dataField: 'ForSupplement',
    },
    {
      headerText: '반품',
      dataField: 'ForReturn',
    },
    {
      headerText: '불용',
      dataField: 'ForDefected',
    },
    {
      headerText: 'DOCK',
      dataField: 'ForDock',
    },
    {
      headerText: 'STAGE',
      dataField: 'ForStage',
    },
    {
      headerText: 'CANCEL',
      dataField: 'ForCancel',
    },
    {
      headerText: '기타',
      dataField: 'ForEtc',
    },
  ];

  const labellingKr = (data: PagingListDTO<InventoryLocationListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        //

        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row.modelGroup],
        locationTypeKr: MASTER_OBJ?.LOCATION_TYPE?.[row.locationType],

        showMap: LOCATION_AVAILABLE_CENTER?.includes(row.centerCode) ? '지도보기' : null,
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InventoryLocationSearchDTO) => {
    dispatch(setLoading('GET'));
    const staticsData = [
      { title: '총 로케이션 수', rowHeader: 'totalLocationQuantity' },
      { title: '사용 로케이션 수', rowHeader: 'useLocationQuantity' },
      { title: '미사용 로케이션 수', rowHeader: 'disuseLocationQuantity' },
      { title: '사용률', rowHeader: 'useLocationPercentage' },
      { title: '적재재고 수량', rowHeader: 'existInventoryQuantity' },
    ];
    const [statics, data] = await Promise.all([
      //
      inventoryService.getInventoryLocationStatistics(searchObj),
      inventoryService.getInventoryLocationStatisticsListPaging(searchObj),
    ]);
    staticsColumns?.forEach((column, colIdx) => {
      staticsData?.forEach((ele, rowIdx) => {
        if (column?.dataField !== 'title') {
          const key = staticsData[rowIdx]['rowHeader'] + column.dataField;
          staticsData[rowIdx][column.dataField] = statics[key];
        }
      });
    });
    staticsGridRef.current.setGridData(staticsData);
    const dataKr = labellingKr(data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      ...defaultSearchFilter,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: await inventoryService.getInventoryLocationStatisticsMainExcel(searchObj) }, true);
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '로케이션별 재고현황' });

    dispatch(setLoading(null));
  };

  const handleCheckItems = (e) => {
    const id = e.target.id;
    if (id === 'INVENTORYLOCATIONEXCEL') {
      downloadExcel('main');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, handleCheckItems);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {bulyongModalObj?.visible && <BulyongModal bulyongModalObj={bulyongModalObj} setBulyongModalObj={setBulyongModalObj} />}
      {locationDetailModalObj?.visible && <LocationDetailModal locationDetailModalObj={locationDetailModalObj} setLocationDetailModalObj={setLocationDetailModalObj} />}
      {mapObj?.visible && <MapModal mapObj={mapObj} setMapObj={setMapObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="noborder-grid map-free">
        <GridBox
          gridRef={staticsGridRef}
          columns={staticsColumns}
          gridProps={{
            fixedColumnCount: 1,
            autoGridHeight: true,
            scrollHeight: 0,
            height: 150,
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            showRowNumColumn: false,
            rowStyleFunction: function (rowIndex, item) {
              if (item.title == '사용률') {
                return 'colored-row';
              }
              return '';
            },
          }}
        />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns.filter((_, idx) => idx !== 0)} />
      </div>
    </div>
  );
};

export default Index;
