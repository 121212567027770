import { VisibleModal } from 'components/modal/visibleModal';
import { useEffect } from 'react';
import styled from 'styled-components';
import { returnFeeTypes, returnIsInvoiceStatusFinished } from 'common/util/orderTypes';
import { InvoiceListDTO } from 'interface/order';

const MapWrapper = styled.div`
  width: 100%;
  height: 1000px;
`;

export interface IMapObj {
  title?: string;
  visible?: boolean;
  data?: InvoiceListDTO[];
}

export const convertColor = (ele) => {
  let color = '';
  if (returnFeeTypes(ele.feeType.trim()) === 'delivery') {
    color = '#3EA1FF';
  } else if (returnFeeTypes(ele.feeType.trim()) === 'visit') {
    color = '#FFAD0F';
  } else if (returnFeeTypes(ele.feeType.trim()) === 'collect') {
    color = '#FFA09C';
  } else if (returnFeeTypes(ele.feeType.trim()) === 'exchange') {
    color = '#61CACA';
  } else if (returnFeeTypes(ele.feeType.trim()) === 'move') {
    color = '#CA87FF';
  } else if (returnFeeTypes(ele.feeType.trim()) === 'store') {
    color = '#DDA97A';
  } else if (returnFeeTypes(ele.feeType.trim()) === 'as') {
    color = '#21AD76';
  } else if (returnFeeTypes(ele.feeType.trim()) === 'fix') {
    color = '#21AD76';
  } else if (returnFeeTypes(ele.feeType.trim()) === 'parcel') {
    color = '#5772D4';
  } else if (returnFeeTypes(ele.feeType.trim()) === 'nothing') {
    color = 'white';
  }

  if (returnIsInvoiceStatusFinished(ele.invoiceStatus.trim())) {
    color = '#A3A7B9';
  }
  return color;
};

export const Map = ({ mapObj, setMapObj, dragParentRef }) => {
  const navermaps = (window as any).naver.maps;

  useEffect(() => {
    if (mapObj?.visible && mapObj?.data) {
      const hasDeliveryTime = mapObj?.data.filter((ele) => ele.deliveryDatetime);
      markOnMap(hasDeliveryTime);
    }
  }, [mapObj]);

  const initMap = (data: InvoiceListDTO[]) => {
    const mapDiv = document.getElementById('map');
    return new navermaps.Map(mapDiv, {
      zoom: 10,
      center: new navermaps.LatLng(
        data
          .map((ele) => ele.latitude)
          ?.reduce((acc, cur) => {
            return acc + cur;
          }) / data.length,

        data
          .map((ele) => ele.longitude)
          ?.reduce((acc, cur) => {
            return acc + cur;
          }) / data.length,
      ),
    });
  };

  const markOnMap = (data: InvoiceListDTO[]) => {
    // initMap

    const map = initMap(data);
    const path = [];
    data.forEach((ele, idx) => {
      const [h, m, s] = ele.deliveryDatetime.split(' ')[1].split(':');
      new navermaps.Marker({
        position: new navermaps.LatLng(ele.latitude, ele.longitude),
        map: map,
        icon: {
          content: `
          <svg class='marker ${ele.latitude}_${ele.longitude} ${ele.invoiceSeq}' 
           width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
           <circle opacity="0.3" cx="20" cy="20" r="20" fill=${convertColor(ele)} />
           
           <circle cx="20" cy="20" r="17" fill=${convertColor(ele)} />
           <span class="label-time">(${idx + 1})${h}: ${m}</span>
          </svg>
          `,
          size: new navermaps.Size(22, 35),
          anchor: new navermaps.Point(20, 20),
          animation: navermaps.Animation.BOUNCE,
        },
      });
      path.push(new navermaps.LatLng(ele.latitude, ele.longitude));
    });
    new navermaps.Polyline({
      map,
      path,
      clickable: true,
      strokeColor: 'purple',
      strokeStyle: 'shortdash',
      strokeOpacity: 0.7,
      strokeWeight: 5,
      endIcon: 1,
      endIconSize: 15,
    });
  };

  return (
    <VisibleModal
      dragParentRef={dragParentRef}
      movable={false}
      title={mapObj?.title}
      style={{ left: 0, top: 0, width: 700, maxWidth: 700, height: 1050 }}
      visible={mapObj?.visible}
      setVisible={() => {
        setMapObj(null);
      }}
      children={<MapWrapper id="map"></MapWrapper>}
    />
  );
};
