import { ReactNode, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import { InventoryLiveDTO, InventoryLiveSearchDTO } from '../../../_interface/warehouse';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { IInfoDetailObj, InfoDetail } from './component/infoDetail';
import { PagingListDTO } from 'interface/util';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { serviceStore } from 'services/services';
import { makeObjtoFooter } from 'common/util/grid';
import { InventoryReportsCommonDefaultSearchFilters } from '..';
import { optimizeSearchObj } from 'common/util/searchBox';
import useSearchGridPagingNew from 'hooks/grid/useGridPagingNew';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const bottomGridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();
  const [infoDetailObj, setInfoDetailObj] = useState<IInfoDetailObj>();
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const searchObj = useSelector((state: RootState) => state.tab)?.searchObj?.[tabId];
  const defaultSearchFilter = useMemo(() => {
    return {
      ...InventoryReportsCommonDefaultSearchFilters,
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    };
  }, [reduxUserInfo]);

  const fetchTotal = async (searchObj: InventoryLiveSearchDTO) => {
    const data = (await serviceStore.warehouseAction('inventory/live/quantity', 'GET', searchObj, null))?.data;
    const footerData = {
      ...data,
      customerTotal: data?.move + data?.repair,
    };
    return makeObjtoFooter(footerData);
  };

  const labellingKr = (data: PagingListDTO<InventoryLiveDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: row.centerCode ? selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE) : '전체',
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
        modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
        customerTotal: row.move + row.repair,
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (originSearchObj: InventoryLiveSearchDTO) => {
    const searchObj = optimizeSearchObj(originSearchObj);
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore.warehouseAction('inventory/live/temp', 'GET', searchObj, null, true))?.data as PagingListDTO<InventoryLiveSearchDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { initFetch, handleSearchObj } = useSearchGridPagingNew({
    tabId,
    gridRef,
    fetchTotal,
    fetchPaging,
    initialSearchObj: {
      ...defaultSearchFilter,
      ...searchObj,
    },
  });

  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const searchObj = JSON.parse(e.item.searchObj);
    const gridButtonhandler = (e) => {
      const id = e.target.id;
      if (id === 'INVENTORY_MODEL_BTMSHEET_EXCEL') {
        bottomGridRef.current.exportAsXlsx({ fileName: '실시간 제품별 재고현황(상세)' });
      }
    };
    const buttons = printFunctionToBtns(
      gridFunctions?.filter((ele) => !ele.functionId?.includes('SHOW')),
      gridButtonhandler,
    );
    const data = (await serviceStore.warehouseAction(`inventory/live/${e.item.modelStockSeq}`, 'GET', searchObj, null))?.data;
    if (data) {
      const rows = data?.map((row) => {
        return {
          ...row,
          centerCodeKr: row.centerCode ? selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE) : '전체',
          partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER_WHOLE),
          modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
          modelTypeKr: selectlabel(row.modelType, masterOptions?.MODEL_TYPE),
          infraSeqKr: row.infraName,
          customerTotal: row.move + row.repair,
          searchObj: JSON.stringify(searchObj),
        };
      });

      setBottomSheetObj({
        visible: true,
        hasTabs: [
          {
            title: '실시간 제품별 재고현황 상세',
            gridRef: bottomGridRef,
            columns: detailColumns,
            rows,
            buttons,
            options: {
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
            },
          },
        ],
      });
    }
    dispatch(setLoading(null));
  };

  const modalOpenAction = async (e: IGrid.ButtonClickEvent) => {
    setInfoDetailObj({ visible: true, e });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: fetchDetail,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.INVENTORY_MODEL_SHOWDETAIL;
        },
      },
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품타입',
          dataField: 'modelTypeKr',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '대표상품코드',
          dataField: 'productCode',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
    },
    {
      headerText: '전국재고 계',
      dataField: 'total',
      dataType: 'numeric',
      formatString: '#,##0',
      style: 'main-total-color',
    },
    {
      headerText: '양품보관재고',
      children: [
        {
          headerText: '양품보관<br />재고합계',
          dataField: 'storageTotal',
          dataType: 'numeric',
          formatString: '#,##0',
          style: 'sub-total-color',
        },
        {
          headerText: '로케이션<br />미지정',
          dataField: 'unlocated',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          headerText: '안전재고',
          children: [
            {
              headerText: '주문대기',
              dataField: 'available',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '피킹할당',
              dataField: 'availableStorageLineAndRemove',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '고객 주문할당',
          children: [
            {
              headerText: '미출고',
              dataField: 'reservedProcess',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '익일기사출고',
              dataField: 'reservedDriver',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '익일간선출고',
              dataField: 'reservedStorageLine',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '약속(D+2 이상)',
              dataField: 'reserved',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
    {
      headerText: '고객보관재고',
      children: [
        {
          headerText: '고객보관<br />재고합계',
          dataField: 'customerTotal',
          dataType: 'numeric',
          formatString: '#,##0',
          style: 'sub-total-color',
        },
        {
          headerText: '이전',
          dataField: 'move',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          headerText: '수리',
          dataField: 'repair',
          dataType: 'numeric',
          formatString: '#,##0',
        },
      ],
    },
    {
      headerText: '이동중 재고',
      children: [
        {
          headerText: '이동재고합계<br />(간선출고+기사배송)',
          dataField: 'deliveryTotal',
          dataType: 'numeric',
          formatString: '#,##0',
          style: 'sub-total-color',
        },
        {
          headerText: '간선입고',
          children: [
            {
              headerText: '안전',
              dataField: 'availableTransitLineIn',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '고객 주문할당',
              dataField: 'reservedTransitLineIn',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '간선출고',
          children: [
            {
              headerText: '안전',
              dataField: 'availableTransitLineOut',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '고객 주문할당',
              dataField: 'reservedTransitLineOut',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
        {
          headerText: '기사배송',
          children: [
            {
              headerText: '배송중',
              dataField: 'driverLoan',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
            {
              headerText: '기사회수',
              dataField: 'driverCollect',
              filter: {
                showIcon: true,
              },
              dataType: 'numeric',
              formatString: '#,##0',
            },
          ],
        },
      ],
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품타입',
          dataField: 'modelTypeKr',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '대표상품코드',
          dataField: 'productCode',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
      ],
    },
    {
      headerText: '전국재고 계',
      dataField: 'total',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '양품보관재고',
      children: [
        {
          headerText: '양품보관<br />재고합계',
          dataField: 'storageTotal',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          headerText: '로케이션<br />미지정',
          dataField: 'unlocated',
          dataType: 'numeric',
          formatString: '#,##0',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: modalOpenAction,
          },
        },
        {
          headerText: '안전재고',
          children: [
            {
              headerText: '주문대기',
              dataField: 'available',
              dataType: 'numeric',
              formatString: '#,##0',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: modalOpenAction,
              },
            },
            {
              headerText: '피킹할당',
              dataField: 'availableStorageLineAndRemove',
              dataType: 'numeric',
              formatString: '#,##0',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: modalOpenAction,
              },
            },
          ],
        },
        {
          headerText: '고객 주문할당',
          children: [
            {
              headerText: '미출고',
              dataField: 'reservedProcess',
              dataType: 'numeric',
              formatString: '#,##0',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: modalOpenAction,
              },
            },
            {
              headerText: '익일기사출고',
              dataField: 'reservedDriver',
              dataType: 'numeric',
              formatString: '#,##0',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: modalOpenAction,
              },
            },
            {
              headerText: '익일간선출고',
              dataField: 'reservedStorageLine',
              dataType: 'numeric',
              formatString: '#,##0',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: modalOpenAction,
              },
            },
            {
              headerText: '약속(D+2 이상)',
              dataField: 'reserved',
              dataType: 'numeric',
              formatString: '#,##0',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: modalOpenAction,
              },
            },
          ],
        },
      ],
    },
    {
      headerText: '고객보관재고',
      children: [
        {
          headerText: '고객보관<br />재고합계',
          dataField: 'customerTotal',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          headerText: '이전',
          dataField: 'move',
          dataType: 'numeric',
          formatString: '#,##0',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: modalOpenAction,
          },
        },
        {
          headerText: '수리',
          dataField: 'repair',
          dataType: 'numeric',
          formatString: '#,##0',
          renderer: {
            type: IGrid.RendererKind.ButtonRenderer,
            onClick: modalOpenAction,
          },
        },
      ],
    },
    {
      headerText: '이동중 재고',
      children: [
        {
          headerText: '이동재고합계<br />(간선출고+기사배송)',
          dataField: 'deliveryTotal',
          dataType: 'numeric',
          formatString: '#,##0',
        },
        {
          headerText: '간선입고',
          children: [
            {
              headerText: '안전',
              dataField: 'availableTransitLineIn',
              dataType: 'numeric',
              formatString: '#,##0',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: modalOpenAction,
              },
            },
            {
              headerText: '고객 주문할당',
              dataField: 'reservedTransitLineIn',
              dataType: 'numeric',
              formatString: '#,##0',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: modalOpenAction,
              },
            },
          ],
        },
        {
          headerText: '간선출고',
          children: [
            {
              headerText: '안전',
              dataField: 'availableTransitLineOut',
              dataType: 'numeric',
              formatString: '#,##0',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: modalOpenAction,
              },
            },
            {
              headerText: '고객 주문할당',
              dataField: 'reservedTransitLineOut',
              dataType: 'numeric',
              formatString: '#,##0',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: modalOpenAction,
              },
            },
          ],
        },
        {
          headerText: '기사배송',
          children: [
            {
              headerText: '배송중',
              dataField: 'driverLoan',
              dataType: 'numeric',
              formatString: '#,##0',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: modalOpenAction,
              },
            },
            {
              headerText: '기사회수',
              dataField: 'driverCollect',
              dataType: 'numeric',
              formatString: '#,##0',
              renderer: {
                type: IGrid.RendererKind.ButtonRenderer,
                onClick: modalOpenAction,
              },
            },
          ],
        },
      ],
    },
  ];

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction(type === 'main' ? 'inventory/live/excel' : 'inventory/live/detail/excel', 'GET', searchObj, null))?.data as InventoryLiveDTO[],
      },
      true,
    );

    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '실시간_제품별_재고현황' });
    } else {
      excelDetailGridRef.current.setGridData(list);
      excelDetailGridRef.current.exportAsXlsx({ fileName: '실시간_제품별_재고현황(상세)' });
    }

    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'INVENTORY_MODEL_EXCELDOWN_MAIN') {
      downloadExcel('main');
    } else if (id === 'INVENTORY_MODEL_EXCELDOWN_DETAIL') {
      downloadExcel('detail');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {infoDetailObj?.visible && <InfoDetail infoDetailObj={infoDetailObj} setInfoDetailObj={setInfoDetailObj} />}
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      <div className="presenterSearch">
        <SearchBox fetchList={initFetch} handleSearchObj={handleSearchObj} TARGET={tabId} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area only-right">{functionBtns}</div>}
        <GridBox
          //
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            //
            showFooter: true,
            footerPosition: 'top',
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            fixedColumnCount: 1,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={detailColumns} />
      </div>
    </div>
  );
};

export default Index;
