import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ModelStockAndModelSearchDTO } from 'interface/warehouse';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { RAWDATA } from 'redux/services/menuSlice';

export const SearchBox = (props: ISearchBoxProps<ModelStockAndModelSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_MODEL: null,
    SEARCH_BOOL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_BOOL, searchObj));
  }, [selectTypeObj?.SEARCH_BOOL]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };
  const [filteredOptions, setFilteredOptions] = useState({
    GRP_2: [],
    GRP_3: [],
  });

  useEffect(() => {
    if (searchObj?.modelGroupLarge) {
      const GRP_2 =
        MASTER_OPS?.[`${RAWDATA}MODEL_GRP_1`]
          ?.filter((ele) => ele.code === searchObj?.modelGroupLarge)[0]
          ?.['subCodes']?.filter((ele) => ele.codeGroup === 'MODEL_GRP_2')
          ?.map((ele) => {
            return {
              value: ele.code,
              label: ele.codeName,
            };
          }) || [];
      setFilteredOptions({
        GRP_2,
        GRP_3: [],
      });
    }
  }, [searchObj?.modelGroupLarge]);

  useEffect(() => {
    if (searchObj?.modelGroupMedium) {
      const GRP_3 =
        MASTER_OPS?.[`${RAWDATA}MODEL_GRP_2`]
          ?.filter((ele) => ele.code === searchObj?.modelGroupMedium)[0]
          ?.['subCodes']?.filter((ele) => ele.codeGroup === 'MODEL_GRP_3')
          ?.map((ele) => {
            return {
              value: ele.code,
              label: ele.codeName,
            };
          }) || [];
      setFilteredOptions((prev) => {
        return {
          ...prev,
          GRP_3,
        };
      });
    }
  }, [searchObj?.modelGroupMedium]);

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">제품타입</label>
          <Row>
            <SelectD
              options={MASTER_OPS?.MODEL_TYPE}
              value={
                searchObj?.modelType
                  ? {
                      value: searchObj?.modelType,
                      label: selectlabel(searchObj?.modelType + '', MASTER_OPS?.MODEL_TYPE),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  modelType: (option as OptionItem).value,
                });
              }}
            />
          </Row>
        </Col>
        <Col>
          <label className="col-form-label">대분류</label>
          <SelectD
            value={
              searchObj?.modelGroupLarge
                ? {
                    value: searchObj?.modelGroupLarge,
                    label: selectlabel(searchObj?.modelGroupLarge, MASTER_OPS?.MODEL_GRP_1),
                  }
                : null
            }
            options={MASTER_OPS?.MODEL_GRP_1}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                modelGroupLarge: (option as OptionItem).value,
                modelGroupMedium: null,
                modelGroupSmall: null,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">중분류</label>
          <SelectD
            isDisabled={!searchObj?.modelGroupLarge}
            options={filteredOptions?.GRP_2}
            value={
              searchObj?.modelGroupMedium
                ? {
                    value: searchObj?.modelGroupMedium,
                    label: selectlabel(searchObj?.modelGroupMedium, MASTER_OPS?.MODEL_GRP_2),
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                modelGroupMedium: (option as OptionItem).value,
                modelGroupSmall: null,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">소분류</label>
          <SelectD
            isDisabled={!searchObj?.modelGroupMedium}
            value={
              searchObj?.modelGroupSmall
                ? {
                    value: searchObj?.modelGroupSmall,
                    label: selectlabel(searchObj?.modelGroupSmall, MASTER_OPS?.MODEL_GRP_3),
                  }
                : null
            }
            options={filteredOptions?.GRP_3}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                modelGroupSmall: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            isMulti
            value={searchObj?.partnerSeq?.map((ele) => {
              return {
                value: ele,
                label: selectlabel(ele, MASTER_OPS?.SELLER_SELLER_AUTH),
              };
            })}
            options={MASTER_OPS?.SELLER_SELLER_AUTH}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem[]).map((ele) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">공급사</label>
          <SelectD
            isMulti
            value={searchObj?.supplierPartnerSeq?.map((ele) => {
              return {
                value: ele,
                label: selectlabel(ele, MASTER_OPS?.SELLER_SUPPLIER_AUTH),
              };
            })}
            options={MASTER_OPS?.SELLER_SUPPLIER_AUTH}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                supplierPartnerSeq: (option as OptionItem[]).map((ele) => ele.value),
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">브랜드</label>
          <SelectD
            isMulti
            value={searchObj?.brand?.map((ele) => {
              return {
                value: ele,
                label: selectlabel(ele, MASTER_OPS?.BRAND),
              };
            })}
            options={MASTER_OPS?.BRAND}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                brand: (option as OptionItem[]).map((ele) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label>제품정보</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">활성/단종여부</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_BOOL
                    ? {
                        value: selectTypeObj?.SEARCH_BOOL,
                        label: MASTER_OBJ?.SEARCH_BOOL?.[selectTypeObj?.SEARCH_BOOL],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_BOOL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_BOOL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <SelectD
                isDisabled={!selectTypeObj?.SEARCH_BOOL}
                value={
                  searchObj?.[selectTypeObj?.SEARCH_BOOL]
                    ? {
                        value: searchObj?.[selectTypeObj?.SEARCH_BOOL],
                        label: selectlabel(searchObj?.[selectTypeObj?.SEARCH_BOOL] + '', selectTypeObj?.SEARCH_BOOL === 'useFlag' ? MASTER_OPS?.ACTIVE_FLAG : MASTER_OPS?.STOP_FLAG),
                      }
                    : null
                }
                options={selectTypeObj?.SEARCH_BOOL === 'useFlag' ? MASTER_OPS?.ACTIVE_FLAG : MASTER_OPS?.STOP_FLAG}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_BOOL]: (option as OptionItem)?.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">유사검색여부</label>
          <div className="radio-select-area">
            <span
              className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: true,
                  };
                });
              }}
            >
              유사검색
            </span>
            <span
              className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: false,
                  };
                });
              }}
            >
              일치검색
            </span>
          </div>
        </Col>
        <Col>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
      </Row>
    </Form>
  );
};
