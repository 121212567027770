import CommonModalNew from 'components/modal/commonModalNew';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';
import GridBox from 'common/grid/gridBox';
import { PurchaseOrderInfoDTO } from '../../../_interface/warehouse';
import { IPrint, Print } from './print';
import { serviceStore } from 'services/services';

export interface IhangdangObj {
  visible?: boolean;
  item?: any;
}

export const HaldangPopup = ({ haldangObj, setHaldangObj }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<AUIGrid>();
  const columns: IGrid.Column[] = [
    {
      headerText: '고객정보',
      children: [
        {
          headerText: '주문번호',
          dataField: 'orderSeq',
          mergeRef: 'invoiceSeq',
          mergePolicy: 'restrict',
          cellMerge: true,
        },
        {
          headerText: '송장번호',
          dataField: 'invoiceSeq',
          mergeRef: 'invoiceSeq',
          mergePolicy: 'restrict',
          cellMerge: true,
        },
        {
          headerText: '고객명',
          dataField: 'receiverName',
          mergeRef: 'invoiceSeq',
          mergePolicy: 'restrict',
          cellMerge: true,
        },
        {
          headerText: '고객주소',
          dataField: 'receiverAddr',
          mergeRef: 'invoiceSeq',
          mergePolicy: 'restrict',
          cellMerge: true,
        },
        {
          headerText: '연락처',
          dataField: 'receiverPhone',
          mergeRef: 'invoiceSeq',
          mergePolicy: 'restrict',
          cellMerge: true,
        },
      ],
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
        {
          headerText: '상품명',
          dataField: 'productName',
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '시리얼번호',
          width: 350,
          dataField: 'serialNumber',
        },
      ],
    },
  ];

  useEffect(() => {
    fetchHaldangInfo();
  }, []);

  const [printObj, setPrintObj] = useState<IPrint>();
  const handleCheckItems = (e) => {
    const id = e.target.id;
    if (id === 'excel') {
      gridRef.current.exportAsXlsx({ fileName: '발주입고_할당정보' });
    } else {
      const items = gridRef.current.getCheckedRowItemsAll();
      if (items?.length > 0) {
        if (id === 'prints') {
          setPrintObj({
            visible: true,
            items,
            type: 'haldang',
          });
        }
      } else {
        alert('선택된 항목이 존재하지 않습니다.');
      }
    }
  };

  const fetchHaldangInfo = async () => {
    const data = (await serviceStore.warehouseAction(`purchase/detail/info`, 'GET', { purchaseSeq: haldangObj?.item?.purchaseSeq }))?.data;
    if (data) {
      const rows = (data as PurchaseOrderInfoDTO[])?.map((row) => {
        return {
          ...row,
          partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
          modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row.modelGroup],
          receiverAddr: `${row.receiverAddr1} ${row.receiverAddr2 || ''}`,
        };
      });
      if (rows?.length > 0) {
        gridRef?.current?.setGridData(rows);
      } else {
        alert('할당정보가 없습니다!');
        setHaldangObj(null);
      }
    }
  };

  return (
    <>
      {printObj?.visible && <Print printObj={printObj} setPrintObj={setPrintObj} />}
      <CommonModalNew
        title={`할당정보`}
        subTitle={`발주번호: ${haldangObj?.item?.purchaseSeq}`}
        visible={haldangObj?.visible}
        setVisible={() => {
          setHaldangObj(null);
        }}
        children={
          <>
            <div className="grid-button-area">
              <div className="gray" id="prints" onClick={handleCheckItems}>
                시리얼출력
              </div>
              <div className="green" id="excel" onClick={handleCheckItems}>
                엑셀다운
              </div>
            </div>
            <GridBox
              //
              gridRef={gridRef}
              columns={columns}
              gridProps={{ height: 500, enableCellMerge: true }}
            />
          </>
        }
      />
    </>
  );
};
