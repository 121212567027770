import { useState, useEffect } from 'react';
import { defaultUnitedPageWithTopTab, UnitedPageWithTopTab, KPI_TAB } from 'components/template/topTab';

import Container1 from './wasteAppliances';

// redux
import { useSelector, useDispatch } from 'react-redux';

// options
import { centerListForlabeling, driverListForLabeling } from 'common/master/codeMasterOptionReturn';
import { setMasterOptions } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';

const Index = () => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();

  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    const initMasterOptions = async () => {
      const [CENTER_SELECT, DRIVER_SELECT] = await Promise.all([
        //
        centerListForlabeling({ selectFlag: true }),
        driverListForLabeling({ selectFlag: true }),
      ]);

      dispatch(
        setMasterOptions({
          CENTER_LABEL: CENTER_SELECT?.map((ele) => {
            return {
              label: ele.centerName,
              value: ele.centerCode,
            };
          }),
          DRIVER_NAME_LABEL: DRIVER_SELECT?.map((ele) => {
            return {
              label: ele.driverName,
              value: ele.userId,
            };
          }),
        }),
      );
    };

    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (masterOptions) {
      setTabObj({
        currentTab: 0,
        titles: ['폐가전 출고'],
        containers: [<Container1 />],
      });
    }
  }, [masterOptions]);

  return <>{masterOptions && tabObj?.titles.length > 0 && <UnitedPageWithTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
