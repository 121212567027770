export const ORDER_ACTION = (action: string) => `/order/${action}`;
export const WAREHOUSE_ACTION = (action: string) => `/warehouse/${action}`;
export const MASTER_ACTION = (action: string) => `/master/${action}`;
export const USER_ACTION = (action: string) => `/user/${action}`;
export const INSTALL_ACTION = (action: string) => `/install/${action}`;
export const PARCEL_ACTION = (action: string) => `/parcel/${action}`;

// 엑셀 업로드 폼 2024-08

export const FILE_URL = 'https://apigw.logimate.co.kr/v1/file';

// 통관
export const CARGO_MANIFEST_ACCIDENT_LACK_UPLOAD_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%ED%86%B5%EA%B4%80/%EB%AF%B8%EC%9E%85%EA%B3%A0_%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D.xlsx';

export const CARGO_MANIFEST_TRANSFER_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%ED%86%B5%EA%B4%80/%ED%86%B5%EA%B4%80%EB%AA%A9%EB%A1%9D%EC%A1%B0%ED%9A%8C_%EC%9D%B8%EA%B3%84_%EC%96%91%EC%8B%9D.xlsx';

export const CARGO_MANIFEST_TARGET_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%ED%86%B5%EA%B4%80/%ED%86%B5%EA%B4%80%EB%AA%A9%EB%A1%9D%EC%A1%B0%ED%9A%8C_%EC%84%A0%EC%A0%81_%EC%96%91%EC%8B%9D.xlsx';

export const CARGO_MANIFEST_LOADFAIL_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%ED%86%B5%EA%B4%80/%ED%86%B5%EA%B4%80%EB%AA%A9%EB%A1%9D%EC%A1%B0%ED%9A%8C_%EC%8B%A4%ED%8C%A8_%EC%96%91%EC%8B%9D.xlsx';

export const CARGO_MANIFEST_INV_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%ED%86%B5%EA%B4%80/%ED%86%B5%EA%B4%80%EB%AA%A9%EB%A1%9D%EC%A1%B0%ED%9A%8C_%EC%97%AD%EB%B0%A9%ED%96%A5_%EC%96%91%EC%8B%9D.xlsx';

// 택배
export const PARCEL_SERVICE_ORDERNUMBER_IMPORT =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%ED%83%9D%EB%B0%B0/%ED%83%9D%EB%B0%B0%EC%A1%B0%ED%9A%8C_%EC%A3%BC%EB%AC%B8%EB%B2%88%ED%98%B8%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D.xlsx';

export const PARCEL_SERVICE_PARCEL_TRANSFER_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%ED%83%9D%EB%B0%B0/%ED%83%9D%EB%B0%B0%EC%A1%B0%ED%9A%8C_%ED%83%80%ED%83%9D%EB%B0%B0%EC%9D%B4%EA%B4%80_%EC%96%91%EC%8B%9D.xlsx';

export const PARCEL_SERVICE_PARCEL_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%ED%83%9D%EB%B0%B0/%ED%83%9D%EB%B0%B0%EC%A1%B0%ED%9A%8C_%EC%9A%B4%EC%86%A1%EC%9E%A5_%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D.xlsx';

export const PARCEL_SERVICE_PARCEL_CUSTOMERINFO_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%ED%83%9D%EB%B0%B0/%ED%83%9D%EB%B0%B0%EC%A1%B0%ED%9A%8C_%EA%B3%A0%EA%B0%9D%EC%A0%95%EB%B3%B4%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D.xlsx';

export const PARCEL_SERVICE_PARCEL_FEE_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%ED%83%9D%EB%B0%B0/%ED%83%9D%EB%B0%B0%EC%A1%B0%ED%9A%8C_%EC%9A%B4%EC%9E%84%EB%B9%84%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D.xlsx';

// 입고
export const _PURCHASE_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%9E%85%EA%B3%A0/%EB%B0%9C%EC%A3%BC/%5B%EB%B0%9C%EC%A3%BC%EB%93%B1%EB%A1%9D%5D%EC%A0%9C%ED%92%88_%EC%96%91%EC%8B%9D.xlsx';

export const PURCHASE_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EB%B0%9C%EC%A3%BC/%EB%B0%9C%EC%A3%BC%EB%93%B1%EB%A1%9D_%EC%A0%9C%ED%92%88_%EC%96%91%EC%8B%9D.xlsx';

export const PURCHASE_UPLOAD_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EB%B0%9C%EC%A3%BC/%EB%B0%9C%EC%A3%BC%EC%9E%85%EA%B3%A0_%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D.xlsx';

export const PURCHASE_SERIAL_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EB%B0%9C%EC%A3%BC/%EB%B0%9C%EC%A3%BC%EC%9E%85%EA%B3%A0_%EC%8B%9C%EB%A6%AC%EC%96%BC_%EC%96%91%EC%8B%9D.xlsx';

// 출고
export const _TRUNK_PICKING_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EC%B6%9C%EA%B3%A0/%EA%B0%84%EC%84%A0%ED%94%BC%ED%82%B9%EC%83%9D%EC%84%B1_%ED%95%AD%EB%AA%A9%EC%B6%94%EA%B0%80_%EC%97%91%EC%85%80%EC%96%91%EC%8B%9D.xlsx';

export const TRUNK_PICKING_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/lfs-all/%EC%B6%9C%EA%B3%A0/%EA%B0%84%EC%84%A0/%5B%EA%B0%84%EC%84%A0%ED%94%BC%ED%82%B9%EC%83%9D%EC%84%B1%5D%ED%95%AD%EB%AA%A9%EC%B6%94%EA%B0%80_%EC%97%91%EC%85%80%EC%97%85%EB%A1%9C%EB%93%9C_%EC%96%91%EC%8B%9D.xlsx';

export const TRUNK_PICKING_CENTER_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EC%B6%9C%EA%B3%A0/%EA%B0%84%EC%84%A0%ED%94%BC%ED%82%B9%EC%A7%80%EC%8B%9C_%EC%B0%BD%EA%B3%A0%EB%B3%84%EC%83%9D%EC%84%B1_%EC%96%91%EC%8B%9D.xlsx';
export const TRUNK_CHULGO_SERIAL_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EC%B6%9C%EA%B3%A0/%EA%B0%84%EC%84%A0%EC%B6%9C%EA%B3%A0%EC%A7%80%EC%8B%9C_%EC%8B%9C%EB%A6%AC%EC%96%BC%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D.xlsx';

export const _TAKEOUT_PICKING_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EC%B6%9C%EA%B3%A0/%EB%B0%98%EC%B6%9C%ED%94%BC%ED%82%B9%EC%83%9D%EC%84%B1_%EC%97%91%EC%85%80%EC%96%91%EC%8B%9D.xlsx';

export const TAKEOUT_PICKING_REGIT_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EC%B6%9C%EA%B3%A0/%EB%B0%98%EC%B6%9C%ED%94%BC%ED%82%B9%EC%83%9D%EC%84%B1_%ED%95%AD%EB%AA%A9%EC%B6%94%EA%B0%80_%EC%96%91%EC%8B%9D.xlsx';
export const TAKEOUT_CHULGO_SERIAL_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EC%B6%9C%EA%B3%A0/%EB%B0%98%EC%B6%9C%EC%B6%9C%EA%B3%A0%EC%A7%80%EC%8B%9C_%EC%8B%9C%EB%A6%AC%EC%96%BC%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D.xlsx';

//재고
export const PRODUCT_UPLOAD_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/lfs-all/%EC%9E%AC%EA%B3%A0/%EC%83%81%ED%92%88%EA%B4%80%EB%A6%AC/240919/%EC%83%81%ED%92%88%EA%B4%80%EB%A6%AC_%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D.xlsx';
export const PRODUCT_MOD_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/lfs-all/%EC%9E%AC%EA%B3%A0/%EC%83%81%ED%92%88%EA%B4%80%EB%A6%AC/240919/%EC%83%81%ED%92%88%EA%B4%80%EB%A6%AC_%EC%88%98%EC%A0%95_%EC%96%91%EC%8B%9D.xlsx';
export const PRODUCT_MODEL_MOD_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/lfs-all/%EC%9E%AC%EA%B3%A0/%EC%83%81%ED%92%88%EA%B4%80%EB%A6%AC/240919/%EC%83%81%ED%92%88%EA%B4%80%EB%A6%AC_%EA%B5%AC%EC%84%B1%EC%88%98%EC%A0%95_%EC%96%91%EC%8B%9D.xlsx';

export const MODEL_UPLOAD_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/lfs-all/%EC%9E%AC%EA%B3%A0/%EC%A0%9C%ED%92%88%EA%B4%80%EB%A6%AC/240924/%EC%A0%9C%ED%92%88%EA%B4%80%EB%A6%AC_%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D.xlsx';
export const MODEL_MOD_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/lfs-all/%EC%9E%AC%EA%B3%A0/%EC%A0%9C%ED%92%88%EA%B4%80%EB%A6%AC/241030/%EC%A0%9C%ED%92%88%EA%B4%80%EB%A6%AC_%EC%88%98%EC%A0%95_%EC%96%91%EC%8B%9D.xlsx';
export const LIVE_SEIRAL_TODAY_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EC%9E%AC%EA%B3%A0/%EC%8B%A4%EC%8B%9C%EA%B0%84%EC%8B%9C%EB%A6%AC%EC%96%BC%EB%B3%84%EC%9E%AC%EA%B3%A0%ED%98%84%ED%99%A9_%EC%97%91%EC%85%80_%EC%96%91%EC%8B%9D.xlsx';
export const INV_TRANSFER_UPLOAD_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/lfs-all/%EC%9E%AC%EA%B3%A0/%5B%EC%9E%AC%EA%B3%A0%ED%92%88%EB%AA%A9%EC%A0%84%ED%99%98%EC%83%9D%EC%84%B1%5D%EC%97%91%EC%85%80%EC%96%91%EC%8B%9D.xlsx';
//주문
export const ORDER_MODEL_UPLOAD =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EC%A3%BC%EB%AC%B8/%EC%A3%BC%EB%AC%B8%EC%A1%B0%ED%9A%8C_%EC%A0%9C%ED%92%88_%EC%96%91%EC%8B%9D.xlsx';
export const ORDER_PRODUCT_UPLOAD =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EC%A3%BC%EB%AC%B8/%EC%A3%BC%EB%AC%B8%EC%A1%B0%ED%9A%8C_%EC%83%81%ED%92%88_%EC%96%91%EC%8B%9D.xlsx';
export const ORDER_PARCEL_UPLOAD =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EC%A3%BC%EB%AC%B8/%EC%A3%BC%EB%AC%B8%EC%A1%B0%ED%9A%8C_%ED%83%9D%EB%B0%B0_%EC%96%91%EC%8B%9D.xlsx';
export const PARCEL_UPLOAD =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EC%A3%BC%EB%AC%B8/%ED%83%9D%EB%B0%B0%EC%9A%B4%EC%86%A1%EC%9E%A5%EC%A1%B0%ED%9A%8C_%EC%9A%B4%EC%9E%84%EB%B9%84%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D.xlsx';
export const SERVICE_UPLOAD =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EC%A3%BC%EB%AC%B8/%EC%84%9C%EB%B9%84%EC%8A%A4%EA%B4%80%EB%A6%AC_%EC%97%91%EC%85%80_%EC%96%91%EC%8B%9D.xlsx';
//정산

//기타
export const DRIVER_UPLOAD_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EA%B8%B0%ED%83%80/%EA%B8%B0%EC%82%AC%EA%B4%80%EB%A6%AC_%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D.xlsx';
export const DRIVER_DOCK_UPLOAD_FORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EA%B8%B0%ED%83%80/%EA%B8%B0%EC%82%AC%EA%B4%80%EB%A6%AC_%EA%B8%B0%EC%82%AC%EB%8F%84%ED%81%AC_%EC%96%91%EC%8B%9D.xlsx';
export const WAREHOUSELOCATIONFORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/excelUploadForm/lfs-all/%EA%B8%B0%ED%83%80/%EB%A1%9C%EC%BC%80%EC%9D%B4%EC%85%98%EC%A1%B0%EC%A0%95_%EC%97%91%EC%85%80%EC%96%91%EC%8B%9D/241106/%E1%84%85%E1%85%A9%E1%84%8F%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%89%E1%85%A7%E1%86%AB%E1%84%8C%E1%85%A9%E1%84%8C%E1%85%A5%E1%86%BC_%E1%84%8B%E1%85%A6%E1%86%A8%E1%84%89%E1%85%A6%E1%86%AF%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.xlsx';

export const VENDOR_TAB_EXCELFORM =
  'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EA%B8%B0%ED%83%80/%EC%82%AC%EC%9A%A9%EC%9E%90/%ED%8C%8C%ED%8A%B8%EB%84%88%EA%B4%80%EB%A6%AC/241101/%EA%B1%B0%EB%9E%98%EC%B2%98%EA%B4%80%EB%A6%AC_%EC%97%91%EC%85%80%EC%96%91%EC%8B%9D.xlsx';
