import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { UserDTOForAdmin, UserListDTO, UserSearchDTO } from 'interface/user';
import { UserDTOForAdminEx, UserDetailPopup } from './component/userDetail';
import { urlServer } from 'common/util/isServer';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const [detailObj, setDetailObj] = useState({
    visible: false,
    type: null,
    data: null,
    searchObj: null,
    createFn: (data: { data: UserDTOForAdmin; searchObj: UserSearchDTO }) => createUser(data),
    updateFn: (data: { data: UserDTOForAdmin; searchObj: UserSearchDTO }) => updateUser(data),
  });
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: (e) => {
          fetchDetail(e);
        },
      },
    },
    {
      headerText: '이름',
      dataField: 'userName',
    },
    {
      headerText: '아이디',
      dataField: 'userId',
    },
    {
      headerText: '권한그룹',
      dataField: 'userGroupIdKr',
    },
    {
      headerText: '사용자 유형',
      dataField: 'userTypeKr',
    },
    {
      headerText: '소속 파트너',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '소속 인프라',
      dataField: 'infraSeqKr',
    },
    {
      headerText: '소속 창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '소속 판매채널',
      dataField: 'salesChannelSeqKr',
    },
    {
      headerText: '사용자 상태값',
      dataField: 'useFlagKr',
    },
    {
      headerText: '이메일',
      dataField: 'email',
    },
    {
      headerText: '연락처',
      dataField: 'phoneNumber',
    },
  ];

  const createUser = async ({ data, searchObj }) => {
    const rs = await serviceStore.userAction('save', 'POST', null, data);
    if (rs?.status === 200) {
      alert('사용자 정보가 등록되었습니다!');
      wrappedFetchList(searchObj);
      setDetailObj({
        ...detailObj,
        visible: false,
        type: null,
        data: null,
        searchObj: null,
      });
    }
    dispatch(setLoading(null));
  };

  const updateUser = async ({ data, searchObj }) => {
    const rs = await serviceStore.userAction('save', 'PUT', null, data);
    if (rs?.status === 200) {
      alert('사용자 정보가 수정되었습니다!');
      wrappedFetchList(searchObj);
      setDetailObj({
        ...detailObj,
        visible: false,
        type: null,
        data: null,
        searchObj: null,
      });
    }
    dispatch(setLoading(null));
  };

  const labellingKr = (data: PagingListDTO<UserListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        salesChannelSeqKr: MASTER_OBJ?.SALES_CHANNEL_TYPE?.[row.salesChannelSeq],
        userCodeKr: row.userCode?.includes('ADMIN') ? 'ADMIN' : row.userCode?.substring(0, 2),
        userTypeKr: MASTER_OBJ?.USER_CODE?.[row.userCode],
        useFlagKr: row.useFlag === true ? '사용' : '미사용',
        userGroupIdKr: row.userGroupId?.map((ele) => MASTER_OBJ?.USER_GROUP?.[ele]).join(','),
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: UserSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.userAction(`list/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<UserListDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const data: UserDTOForAdminEx = (await serviceStore.userAction(`${e.item.userId}`, 'GET', null))?.data;
    if (data) {
      data['userCodeKr'] = data.userCode?.includes('ADMIN') ? 'ADMIN' : data.userCode?.substring(0, 2);
      setDetailObj({
        ...detailObj,
        visible: true,
        type: 'U',
        data,
        searchObj: JSON.parse(e.item.searchObj),
      });
    }
    dispatch(setLoading(null));
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.userAction(`list/excel`, 'GET', searchObj, null))?.data as UserListDTO[],
      },
      true,
    );
    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: `사용자관리-${urlServer()}` });
    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'USERREGISTER') {
      setDetailObj({
        ...detailObj,
        visible: true,
        type: 'C',
        searchObj: searchObj,
        data: null,
      });
    } else if (id === 'USER_EXCELDOWN') {
      downloadExcel('main');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {detailObj?.visible && <UserDetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
