import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './manageLD';
import Tab2 from './userList';

// options
import { RootState } from 'redux/store';
import { nullingMASTER } from 'redux/services/menuSlice';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { TopTabController } from 'components/template/TopTabController';

export const MANAGERLD_MASTER = [
  //
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_SELLER_AUTH',
  'SELLER_NETWORK_AUTH',
  'SELLER_WHOLE',
  'SELLER_AUTH',
  'INFRA',
  //
  'JOJIK_GROUP',
  'SALES_CHANNEL',
  'USE_FLAG',
  'USER_CODE',
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    await initMasterOptions(MANAGERLD_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            MANAGERLD_GROUP: <Tab1 tabId={'MANAGERLD_GROUP'} />,
            MANAGERLD_USER: <Tab2 tabId={'MANAGERLD_USER'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
