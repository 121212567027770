import { useEffect, useRef, useState } from 'react';

import img from './img/desc-location.png';
// lib
import { v4 as uuidv4 } from 'uuid';
import * as IGrid from 'aui-grid';
import GridBox, { IPagingGrid, defaultGridProps } from 'common/grid/gridBox';
import { mapService } from './_service/service';
import { DOCKS, makeColumns, mappingDockRowData, mappingMapRowData } from 'common/util/grid';
import { ILocationDetailObj, LocationDetailPopup } from './locationDetailPopup';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { InventoryLocationListDTO } from 'interface/warehouse';
import AUIGrid from 'modules/Grid';

export interface ILocationGrid {
  mapObj: IMapObj;
  setMapObj: React.Dispatch<React.SetStateAction<IMapObj>>;
}

export interface IMapObj {
  activeData: InventoryLocationListDTO;
  visible: boolean;
}

export const LocationGrid = ({ mapObj, setMapObj }: ILocationGrid) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const dockRef = useRef<AUIGrid>();
  const mapRef = useRef<AUIGrid>();
  const [detailObj, setDetailObj] = useState<ILocationDetailObj>();

  const [activeData, setActiveData] = useState<InventoryLocationListDTO>();

  useEffect(() => {
    setActiveData(mapObj?.activeData);
  }, []);

  const cellClick = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    if (e.pid?.includes('docks_')) {
      const whichDock = e.dataField;
      setDetailObj({
        visible: true,
        locationCode: whichDock,
        activeData: e.item.activeData,
        data: e.item[whichDock + '_RAW'],
      });
    } else if (e.pid?.includes('map_')) {
      if (e.rowIndex > 0) {
        const whichDock = e.item[e.dataField];
        const data = await mapService.getLocationRowData(activeData?.centerCode, whichDock);
        setDetailObj({
          visible: true,
          locationCode: whichDock,
          activeData: e.item.activeData,
          data: JSON.stringify(data),
        });
      }
    }
    dispatch(setLoading(null));
  };

  const dockColumn: IGrid.Column[] = DOCKS.map((ele) => {
    return {
      headerText: '',
      dataField: ele,
      style: 'dock-style',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: cellClick,
      },
      styleFunction: function (rowIndex, columnIndex, value, headerText, item, dataField) {
        const activeData = item.activeData ? JSON.parse(item.activeData) : '';
        if (value?.includes(activeData?.locationCode)) {
          return 'aui-grid-locaiton-selected-stage';
        }
        return 'dock-style';
      },
    };
  });

  const [mapColumn, setMapColumn] = useState<IGrid.Column[]>();

  useEffect(() => {
    if (activeData) {
      gridRef.current.setGridData([activeData]);
      makeDockMap(activeData);
      makeMap(activeData);
    }
  }, [activeData]);

  const columns: IGrid.Column[] = [
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
      width: 200,
    },

    {
      headerText: '제품명',
      dataField: 'modelName',
      width: 200,
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '합계',
      dataField: 'quantity',
    },
    {
      headerText: '양품',
      dataField: 'availableQuantity',
    },
    {
      headerText: '불용',
      dataField: 'defectedQuantity',
    },
    {
      headerText: '재고연령',
      children: [
        {
          headerText: '~30일',
          dataField: 'before30Day',
        },
        {
          headerText: '31~60일',
          dataField: 'before60Day',
        },
        {
          headerText: '61일~',
          dataField: 'after60Day',
        },
      ],
    },
  ];

  const makeMap = async (activeData: InventoryLocationListDTO) => {
    const headData = await mapService.getLocationHeadData(activeData?.centerCode);
    // columns
    const mapColumn = makeColumns(headData[0]);
    setMapColumn(mapColumn);
    makeMapRows(activeData, mapColumn);
  };

  const makeMapRows = async (activeData: InventoryLocationListDTO, mapColumn: IGrid.Column[]) => {
    dispatch(setLoading('GET'));
    const { centerCode } = activeData;
    const [headData, inventories] = await Promise.all([
      //
      mapService.getLocationHeadData(centerCode),
      mapService.getLocationsWhereHaveInvent(centerCode),
    ]);
    const ROWS_DATA = mappingMapRowData(inventories, headData.splice(1));
    const ROWS = [];

    const fakeHead = {};
    headData[0].forEach((ele, idx) => {
      fakeHead[`col-${idx}`] = ele;
    });
    ROWS.push(fakeHead);
    ROWS_DATA.forEach((rows, idx) => {
      const ROW = {};
      headData[0].forEach((ele, idx) => {
        ROW[`col-${idx}`] = rows[`col-${idx}`].locationCode;
        ROW[`${rows[`col-${idx}`].locationCode}_INVENTORY`] = rows[`col-${idx}`].floors?.split('/')[0];
        ROW['activeData'] = JSON.stringify(activeData);
        ROW[`${rows[`col-${idx}`].locationCode}_SUM`] = rows[`col-${idx}`].sum;
      });
      ROWS.push(ROW);
    });
    mapRef.current.bind('cellClick', cellClick);
    mapRef.current.setGridData(ROWS);
    dispatch(setLoading(null));
  };

  const makeDockMap = async (activeData: InventoryLocationListDTO) => {
    const { centerCode } = activeData;
    const DOCKS_DATA = (await Promise.all(DOCKS.map((ele) => mapService.getLocationRowData(centerCode, ele)))).map((ele, idx) => {
      return mappingDockRowData(ele, DOCKS[idx]);
    });

    const DOCK_ROW = {};
    DOCKS_DATA.forEach((ele) => {
      DOCK_ROW[ele.locationCode] = `${ele.locationCode} 재고: ${ele.sum}`;
      DOCK_ROW[`${ele.locationCode}_RAW`] = ele.rawdata;
      DOCK_ROW['activeData'] = JSON.stringify(activeData);
    });
    dockRef.current.setGridData([DOCK_ROW]);
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <img src={img} alt="" style={{ width: 800 }} />
      </div>
      {detailObj?.visible && <LocationDetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}

      <div className="dockMap map-free">
        <GridBox
          gridRef={gridRef}
          columns={activeData?.after60Day ? columns : columns.slice(0, 12)}
          gridProps={{
            //
            autoGridHeight: true,
            scrollHeight: 0,
            rowHeight: 28,
            height: 28,
            pagingPanelHeight: 0,
            usePaging: false,
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            showRowNumColumn: false,
          }}
        />
        <div style={{ marginBottom: 10 }}></div>
        <AUIGrid
          ref={dockRef}
          name={`docks_${uuidv4()}`}
          columnLayout={dockColumn}
          gridProps={{
            ...defaultGridProps,
            autoGridHeight: true,
            headerHeight: 0,
            scrollHeight: 0,
            rowHeight: 28,
            height: 28,
            pagingPanelHeight: 0,
            usePaging: false,
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            showRowNumColumn: false,
          }}
        />
      </div>
      <div className="Map">
        {mapColumn && (
          <AUIGrid
            ref={mapRef}
            name={`map_${uuidv4()}`}
            columnLayout={mapColumn}
            gridProps={{
              ...defaultGridProps,
              autoGridHeight: true,
              headerHeight: 0,
              rowHeight: 30,
              pagingPanelHeight: 0,
              scrollHeight: 0,
              usePaging: false,
              fillColumnSizeMode: true,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              showRowNumColumn: false,
            }}
          />
        )}
      </div>
    </>
  );
};
