import { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { OrderSearchDTO } from '../../_interface/order';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { numberThousandComma } from 'common/util/counting';
import { defaultSelectTypeObj, FLAG_DETAILS, ORDER_COUNTS, ORDER_FLAG_KR, translatorInvoiceStatus } from '../../_asset/asset';
import { useDidMountEffect } from 'hooks/basichooks';
import { BUTTONS, LABEL } from 'pages/ORDER/1order/orderListTab/_asset/locale';
import { setSearchObj } from 'redux/services/tabSlice';

export const SearchBox = ({ TARGET, fetchCount, fetchList, searchObj, setSearchObj }: ISearchBoxProps<OrderSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { locale } = useSelector((state: RootState) => state.locale);
  const dispatch = useDispatch();
  const [flagArr, setFlagArr] = useState<string[]>();
  const [selectTypeObj, setSelectTypeObj] = useState(defaultSelectTypeObj);
  const useNewDesignFlag = JSON.parse(localStorage.getItem('useNewDesignFlag'));

  useEffect(() => {
    setFlagArr(ORDER_COUNTS?.[TARGET]);
  }, [TARGET]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_ORDER, searchObj));
  }, [selectTypeObj?.SEARCH_ORDER]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_INVOICE, searchObj));
  }, [selectTypeObj?.SEARCH_INVOICE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE?.[TARGET], searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SKU, searchObj));
  }, [selectTypeObj?.SEARCH_SKU]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_NAME, searchObj));
  }, [selectTypeObj?.SEARCH_NAME]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_ADDRESS, searchObj));
  }, [selectTypeObj?.SEARCH_ADDRESS]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_CONTACT, searchObj));
  }, [selectTypeObj?.SEARCH_CONTACT]);

  const isMountedRef = useRef(false);
  useDidMountEffect(() => {
    if (isMountedRef?.current && useNewDesignFlag !== 1) {
      if (searchObj?.confirmFlag || searchObj?.assignFlag || searchObj?.unassignFlag || searchObj?.finishFlag || searchObj?.pendFlag || searchObj?.cancelFlag) fetchList(searchObj);
    } else {
      isMountedRef.current = true;
    }
  }, [searchObj?.confirmFlag, searchObj?.assignFlag, searchObj?.unassignFlag, searchObj?.finishFlag, searchObj?.pendFlag, searchObj?.cancelFlag]);

  const flagArrTosearchObj = (searchObj) => {
    const _searchObj = { ...searchObj };

    ORDER_COUNTS?.[TARGET]?.forEach((flag) => {
      if (flagArr?.includes(flag)) _searchObj[flag] = 'true';
      else _searchObj[flag] = 'false';
    });

    if (!fetchCount || _searchObj?.pageNo === -1) {
      const resetFlags = Object.keys(_searchObj)?.filter((key) => key.includes('Flag') && !key.includes('similar') && key !== 'promiseFlag' && key !== 'liteFlag');
      for (let flag of resetFlags) {
        _searchObj[flag] = 'true';
      }
    }
    setSearchObj(_searchObj);
    if (fetchCount) fetchList(_searchObj);
  };

  useEffect(() => {
    flagArrTosearchObj(searchObj);
  }, [flagArr]);

  const handleCheckbox = (e) => {
    const id = e.target.id;
    if (e.target.id === 'all') {
      if (e.target.checked === true) {
        setFlagArr(ORDER_COUNTS?.[TARGET]);
      } else {
        setFlagArr([]);
      }
    } else {
      let set = new Set(flagArr);
      if (set.has(id)) {
        set.delete(id);
      } else {
        set.add(id);
      }
      const newArr = Array.from(set);
      setFlagArr(newArr);
    }
  };

  const handleSearchObj = (id: string, value: any) => {
    setSearchObj({
      ...searchObj,
      [id]: value,
    });
  };

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">{LABEL?.SEARCH_ORDER?.[locale?.value]}</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  locale={locale.value}
                  value={
                    selectTypeObj?.SEARCH_ORDER
                      ? {
                          value: selectTypeObj?.SEARCH_ORDER,
                          label: MASTER_OBJ?.SEARCH_ORDER?.[selectTypeObj?.SEARCH_ORDER],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_ORDER}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_ORDER: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  placeholder="다중 입력..."
                  locale={locale.value}
                  disabled={!selectTypeObj?.SEARCH_ORDER}
                  value={searchObj?.[selectTypeObj?.SEARCH_ORDER] || ''}
                  onChange={(e) => {
                    const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                    handleSearchObj(selectTypeObj?.SEARCH_ORDER, value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">{LABEL?.SEARCH_INVOICE?.[locale?.value]}</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  locale={locale.value}
                  value={
                    selectTypeObj?.SEARCH_INVOICE
                      ? {
                          value: selectTypeObj?.SEARCH_INVOICE,
                          label: MASTER_OBJ?.SEARCH_INVOICE?.[selectTypeObj?.SEARCH_INVOICE],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_INVOICE}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_INVOICE: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  placeholder="다중 입력..."
                  locale={locale.value}
                  disabled={!selectTypeObj?.SEARCH_INVOICE}
                  value={searchObj?.[selectTypeObj?.SEARCH_INVOICE] || ''}
                  onChange={(e) => {
                    const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                    handleSearchObj(selectTypeObj?.SEARCH_INVOICE, value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">{LABEL?.partnerSeqList?.[locale?.value]}</label>
            <SelectD
              isMulti
              locale={locale.value}
              options={MASTER_OPS?.SELLER_SELLER_AUTH}
              value={searchObj?.partnerSeqList?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.SELLER_WHOLE?.[ele],
                };
              })}
              onChange={(option) => {
                handleSearchObj(
                  'partnerSeqList',
                  (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                );
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">{LABEL?.infraSeqList?.[locale?.value]}</label>
            <SelectD
              isMulti
              locale={locale.value}
              options={MASTER_OPS?.INFRA}
              value={searchObj?.infraSeqList?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.INFRA?.[ele],
                };
              })}
              onChange={(option) => {
                handleSearchObj(
                  'infraSeqList',
                  (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                );
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">{LABEL?.centerCodeList?.[locale?.value]}</label>
            <SelectD
              isMulti
              locale={locale.value}
              options={MASTER_OPS?.CENTER_AUTH}
              value={searchObj?.centerCodeList?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.CENTER_WHOLE?.[ele],
                };
              })}
              onChange={(option) => {
                handleSearchObj(
                  'centerCodeList',
                  (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                );
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">{LABEL?.orderTypes?.[locale?.value]}</label>
            <SelectD
              isMulti
              locale={locale.value}
              options={MASTER_OPS?.ORDER_TYPE}
              value={searchObj?.orderTypes?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.ORDER_TYPE?.[ele],
                };
              })}
              onChange={(option) => {
                handleSearchObj(
                  'orderTypes',
                  (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                );
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">{LABEL?.feeTypes?.[locale?.value]}</label>
            <SelectD
              isMulti
              locale={locale.value}
              options={MASTER_OPS?.FEE_TYPE}
              value={searchObj?.feeTypes?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.FEE_TYPE?.[ele],
                };
              })}
              onChange={(option) => {
                handleSearchObj(
                  'feeTypes',
                  (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                );
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">{LABEL?.invoiceStatuses?.[locale?.value]}</label>
            <SelectD
              isMulti
              locale={locale.value}
              options={MASTER_OPS?.[translatorInvoiceStatus?.[TARGET]]}
              value={searchObj?.invoiceStatuses?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.[translatorInvoiceStatus?.[TARGET]]?.[ele],
                };
              })}
              onChange={(option) => {
                handleSearchObj(
                  'invoiceStatuses',
                  (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                );
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">{LABEL?.userId?.[locale?.value]}</label>
            <div style={{ display: 'flex' }}>
              <Col md={6} style={{ display: 'inline-block' }}>
                <SelectD
                  locale={locale.value}
                  options={MASTER_OPS?.DRIVER_AUTH}
                  value={
                    searchObj?.userId
                      ? {
                          value: searchObj?.userId,
                          label: MASTER_OBJ?.DRIVER_AUTH?.[searchObj?.userId],
                        }
                      : null
                  }
                  onChange={(option) => {
                    handleSearchObj('userId', (option as OptionItem).value);
                  }}
                />
              </Col>
              <Col md={6} style={{ display: 'inline-block' }}>
                <InputD disabled value={searchObj?.userId || ''} locale={locale.value} />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">{LABEL?.SEARCH_DATE?.[locale?.value]}</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  locale={locale.value}
                  value={
                    selectTypeObj?.SEARCH_DATE
                      ? {
                          value: selectTypeObj?.SEARCH_DATE,
                          label: MASTER_OBJ?.SEARCH_DATE?.[TARGET]?.[selectTypeObj?.SEARCH_DATE] ?? selectlabel(selectTypeObj?.SEARCH_DATE, MASTER_OPS?.SEARCH_DATE?.[TARGET]),
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_DATE?.[TARGET]}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_DATE: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <FlatpickrD
                  disabled={!selectTypeObj?.SEARCH_DATE}
                  locale={locale.value}
                  value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                  onReset={() => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                        [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                      };
                    });
                  }}
                  onClose={(option) => {
                    if (option?.length === 1)
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                          [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                        };
                      });
                  }}
                  onChange={(option) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                        [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                      };
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">{LABEL?.SEARCH_MODEL?.[locale?.value]}</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  locale={locale.value}
                  value={
                    selectTypeObj?.SEARCH_MODEL
                      ? {
                          value: selectTypeObj?.SEARCH_MODEL,
                          label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_MODEL}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_MODEL: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  placeholder="다중 입력..."
                  locale={locale.value}
                  disabled={!selectTypeObj?.SEARCH_MODEL}
                  value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                  onChange={(e) => {
                    const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                    handleSearchObj(selectTypeObj?.SEARCH_MODEL, value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col md={2}>
            <label className="col-form-label">{LABEL?.receiverName?.[locale?.value]}</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  locale={locale.value}
                  value={
                    selectTypeObj?.SEARCH_NAME
                      ? {
                          value: selectTypeObj?.SEARCH_NAME,
                          label: MASTER_OBJ?.SEARCH_NAME?.[selectTypeObj?.SEARCH_NAME],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_NAME}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_NAME: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  placeholder="다중 입력..."
                  locale={locale.value}
                  disabled={!selectTypeObj?.SEARCH_NAME}
                  value={searchObj?.[selectTypeObj?.SEARCH_NAME] || ''}
                  onChange={(e) => {
                    const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                    handleSearchObj(selectTypeObj?.SEARCH_NAME, value);
                  }}
                />
              </Col>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <label className="col-form-label">{LABEL?.receiverAddr?.[locale?.value]}</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  locale={locale.value}
                  value={
                    selectTypeObj?.SEARCH_ADDRESS
                      ? {
                          value: selectTypeObj?.SEARCH_ADDRESS,
                          label: MASTER_OBJ?.SEARCH_ADDRESS?.[selectTypeObj?.SEARCH_ADDRESS],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_ADDRESS}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_ADDRESS: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  placeholder="다중 입력..."
                  locale={locale.value}
                  disabled={!selectTypeObj?.SEARCH_ADDRESS}
                  value={searchObj?.[selectTypeObj?.SEARCH_ADDRESS] || ''}
                  onChange={(e) => {
                    const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                    handleSearchObj(selectTypeObj?.SEARCH_ADDRESS, value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col md={2}>
            <label className="col-form-label">{LABEL?.receiverPhone?.[locale?.value]}</label>
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  locale={locale.value}
                  value={
                    selectTypeObj?.SEARCH_CONTACT
                      ? {
                          value: selectTypeObj?.SEARCH_CONTACT,
                          label: MASTER_OBJ?.SEARCH_CONTACT?.[selectTypeObj?.SEARCH_CONTACT],
                        }
                      : null
                  }
                  options={MASTER_OPS?.SEARCH_CONTACT}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_CONTACT: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  placeholder="다중 입력..."
                  locale={locale.value}
                  disabled={!selectTypeObj?.SEARCH_CONTACT}
                  value={searchObj?.[selectTypeObj?.SEARCH_CONTACT] || ''}
                  onChange={(e) => {
                    const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                    handleSearchObj(selectTypeObj?.SEARCH_CONTACT, value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col md={2}>
            <label className="col-form-label">{LABEL?.promiseFlag?.[locale?.value]}</label>
            <SelectD
              locale={locale.value}
              options={MASTER_OPS?.FIX_DATE}
              value={
                searchObj?.promiseFlag
                  ? {
                      value: searchObj?.promiseFlag,
                      label: MASTER_OBJ?.FIX_DATE?.[searchObj?.promiseFlag + ''],
                    }
                  : null
              }
              onChange={({ value }) => {
                handleSearchObj('promiseFlag', value);
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">{LABEL?.inventoryLocations?.[locale?.value]}</label>
            <SelectD
              isMulti
              locale={locale.value}
              options={MASTER_OPS?.INVENTORY_LOCATION}
              value={searchObj?.inventoryLocations?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.INVENTORY_LOCATION?.[ele],
                };
              })}
              onChange={(option) => {
                handleSearchObj(
                  'inventoryLocations',
                  (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                );
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">{LABEL?.recommenderName?.[locale?.value]}</label>
            <InputD
              placeholder="다중 입력..."
              locale={locale.value}
              value={searchObj?.recommenderName || ''}
              onChange={(e) => {
                const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                handleSearchObj('recommenderName', value);
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">{LABEL?.salesChannelSeqList?.[locale?.value]}</label>
            <SelectD
              isMulti
              locale={locale.value}
              options={MASTER_OPS?.SALES_CHANNEL}
              value={searchObj?.salesChannelSeqList?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.SALES_CHANNEL?.[ele],
                };
              })}
              onChange={(option) => {
                handleSearchObj(
                  'salesChannelSeqList',
                  (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                );
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <label className="col-form-label">{LABEL?.seller?.[locale?.value]}</label>
            <InputD
              placeholder="다중 입력..."
              locale={locale.value}
              value={searchObj?.seller || ''}
              onChange={(e) => {
                const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                handleSearchObj('seller', value);
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">{LABEL?.liteFlag?.[locale?.value]}</label>
            <SelectD
              locale={locale.value}
              options={MASTER_OPS?.LITE_FLAG}
              value={
                searchObj?.liteFlag
                  ? {
                      value: searchObj?.liteFlag,
                      label: MASTER_OBJ?.LITE_FLAG?.[searchObj?.liteFlag + ''],
                    }
                  : null
              }
              onChange={(option) => {
                handleSearchObj('liteFlag', (option as OptionItem)?.value);
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">{LABEL?.parcelPayTypes?.[locale?.value]}</label>
            <SelectD
              isMulti
              locale={locale.value}
              options={MASTER_OPS?.PARCEL_PAY_TYPE}
              value={searchObj?.parcelPayTypes?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.PARCEL_PAY_TYPE?.[ele],
                };
              })}
              onChange={(option) => {
                handleSearchObj(
                  'parcelPayTypes',
                  (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                );
              }}
            />
          </Col>
          <Col md={2}>
            <label className="col-form-label">{LABEL?.sendStage?.[locale?.value]}</label>
            <InputD
              placeholder="다중 입력..."
              type="number"
              locale={locale.value}
              value={searchObj?.sendStage || ''}
              onChange={(e) => {
                const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                handleSearchObj('sendStage', value);
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">{LABEL?.similaritySearchFlag?.[locale?.value]}</label>
            <div className="radio-select-area">
              <span
                className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                onClick={() => {
                  handleSearchObj('similaritySearchFlag', true);
                }}
              >
                {BUTTONS?.similarities?.[locale?.value]}
              </span>
              <span
                className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                onClick={() => {
                  handleSearchObj('similaritySearchFlag', false);
                }}
              >
                {BUTTONS?.accordance?.[locale?.value]}
              </span>
            </div>
          </Col>
          <Col>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                fetchList(searchObj);
              }}
            >
              {BUTTONS?.search?.[locale?.value]}
            </button>
          </Col>
        </Row>
      </Form>
      <div style={{ textAlign: 'right' }}>
        <div className="status-panel">
          {ORDER_COUNTS?.[TARGET]?.length > 0 && (
            <span>
              <input type="checkbox" id="all" checked={!flagArr ? true : flagArr?.length === ORDER_COUNTS?.[TARGET]?.length} onChange={handleCheckbox} />
              <label htmlFor="all">전체</label> {numberThousandComma(fetchCount?.totalQuantity || 0)} 건
            </span>
          )}
          {ORDER_COUNTS?.[TARGET]?.map((flag, idx) => {
            return (
              <span key={`flag_${idx}`}>
                <input type="checkbox" id={flag} checked={!flagArr ? true : flagArr?.includes(flag)} onChange={handleCheckbox} />
                <label htmlFor={flag}>{ORDER_FLAG_KR?.[flag]}</label> {numberThousandComma(fetchCount?.[`${flag?.split('Flag')?.[0]}Quantity`] || 0)} 건
              </span>
            );
          })}
          {fetchCount && (
            <div className="hover-panel">
              {ORDER_COUNTS?.[TARGET]?.map((flagVar, i) => {
                return (
                  <div key={`flagDetail_${i}`}>
                    {FLAG_DETAILS?.[flagVar]?.map((flag, idx) => {
                      return (
                        <span key={`flagVar_${idx}`}>
                          <label className={FLAG_DETAILS?.[flagVar + 'Color'] || ''}>{FLAG_DETAILS?.[flagVar + 'Kr']?.[idx]}</label>
                          {numberThousandComma(fetchCount?.[flag?.split('Flag')[0]] || 0)} 건
                        </span>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
