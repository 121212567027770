// react
import React, { useEffect, useRef, useState } from 'react';

import ReactToPrint from 'react-to-print';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { setLoading } from 'redux/services/menuSlice';
import ModalforBarcode, { IBarcodeInfoObj, JustSign, PrintTitle } from 'components/print/print';
import { driverService } from '../_services/service';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

export interface IPrints {
  visible?: boolean;
  type?: 'model';
  item?: any;
}

export const DriverPrint = ({ driverPrintObj, setDriverPrintObj }) => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [printData, setPrintData] = useState<any>();
  const [barcodeInfoObj, setBarcodeInfoObj] = useState<IBarcodeInfoObj>();

  const wrapperRef = useRef();
  useEffect(() => {
    if (driverPrintObj?.item) {
      fetchPrintData(driverPrintObj?.item);
    }
  }, []);

  const fetchPrintData = async (item) => {
    dispatch(setLoading('GET'));
    const searchDTO = {
      loanSeq: item?.loanSeq,
      loanDate: item?.loanDate,
      centerCode: item?.centerCode,
      userId: item?.userId,
    };
    const rs1 = await driverService?.getAction(`/warehouse/loan/v2`, searchDTO);
    const rs2 = await driverService?.getAction(`/warehouse/loan/v2/model`, searchDTO);

    if (rs1?.status === 200) {
      const detailAv = rs1?.data?.details?.filter((c) => c.targetQuantity > 0 || c.liteTargetQuantity > 0);
      const detailMv = rs1?.data?.details?.filter((c) => c.moveTargetQuantity > 0);
      const modelAv = rs2?.data?.loanModels?.filter((c) => c.availableQuantity > 0);
      const modelMv = rs2?.data?.loanModels?.filter((c) => c.moveQuantity > 0);
      const printData = {
        ...item,
        detailLoanData: {
          ...rs1?.data,
          availables: detailAv,
          moves: detailMv,
        },
        modelData: {
          ...rs2?.data,
          availables: modelAv,
          moves: modelMv,
          availableTotal:
            modelAv?.length > 0
              ? modelAv
                  ?.map((ele) => ele.availableQuantity)
                  ?.reduce((acc: number, cur: number) => {
                    return acc + cur;
                  })
              : 0,
          moveTotal:
            modelMv?.length > 0
              ? modelMv
                  ?.map((ele) => ele.moveQuantity)
                  ?.reduce((acc: number, cur: number) => {
                    return acc + cur;
                  })
              : 0,
        },
      };
      setPrintData(printData);
    }
    dispatch(setLoading(null));
  };
  return (
    <>
      <CommonModalNew
        title={'출고지시서'}
        style={{ width: 1300 }}
        visible={driverPrintObj?.visible}
        setVisible={() => setDriverPrintObj(null)}
        rightTitle={
          <>
            <ReactToPrint
              pageStyle={` @page {
        size: auto;
        @top-right {
            content: "Page " counter(page);
          }
}`}
              trigger={() => <button>출력</button>}
              content={() => wrapperRef.current}
            />
          </>
        }
        children={
          <>
            <div
              ref={wrapperRef}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                fontSize: '13px !important',
              }}
            >
              <ModalforBarcode barcodeInfoObj={barcodeInfoObj} setBarcodeInfoObj={setBarcodeInfoObj} />
              <div style={{ padding: 30 }} className="break-for-onePage-print">
                <div>
                  <PrintTitle seq={printData?.loanSeq} title={'1. 기사출고 지시서'} />
                  <table className="border-table fs14" style={{ width: '60%' }}>
                    <thead>
                      <tr>
                        <th>기사출고번호</th>
                        <th>설치기사</th>
                        <th>출고일</th>
                        <th>
                          출고지시
                          <br />
                          확정일시
                        </th>
                        <th>출고창고</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{printData?.loanSeq || ''}</th>
                        <th>{selectlabel(printData?.userId, masterOptions?.DRIVER_LABEL) || ''}</th>
                        <th>{printData?.loanDate || ''}</th>
                        <th>
                          {printData?.loanInitDatetime?.split('_')[0]}&nbsp;{printData?.loanInitDatetime?.split('_')[1]}
                        </th>
                        <th>{selectlabel(printData?.centerCode, masterOptions?.CENTER) || ''}</th>
                      </tr>
                    </tbody>
                  </table>
                  <table className="border-table fs14 table-avoid-break">
                    <thead>
                      <tr>
                        <th colSpan={12}>1. 양품출고</th>
                      </tr>
                      <tr>
                        <th>
                          설치구분
                          <br />
                          (송장번호)
                        </th>
                        <th>
                          수령인
                          <br />
                          (시간)
                        </th>
                        <th>
                          연락처1
                          <br />
                          연락처2
                        </th>
                        <th colSpan={4}>
                          주소 <br /> (기사 요청사항)
                        </th>
                        <th colSpan={5}>
                          제품명 <br /> (바코드)
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center', fontWeight: 600 }}>
                      {printData?.detailLoanData?.availables?.map((list, idx) => (
                        <React.Fragment key={`1available_${idx}`}>
                          <tr>
                            <td>
                              {selectlabel(list.feeType, masterOptions?.FEE_TYPE)} <br /> ({list.invoiceSeq}){' '}
                            </td>
                            <td>
                              {list.receiverName}
                              <br />({list.deliveryDatetime})
                            </td>
                            <td>
                              {list.receiverPhone}
                              <br />
                              {list.receiverPhone2}
                            </td>
                            <td colSpan={4}>
                              {list.receiverAddr1 + ' ' + list.receiverAddr2}
                              <br />({list.driverMemo || '-'})
                            </td>
                            <td style={{ padding: 0 }} colSpan={5}>
                              {list?.inventories?.map((x, idx) => (
                                <div key={`inventory1_${idx}`} style={{ borderTop: idx !== 0 ? '1px solid lightgray' : 'none' }}>
                                  <span>{x.modelName}</span>
                                  <br />
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setBarcodeInfoObj({
                                        visible: true,
                                        data: x.barcode,
                                      });
                                    }}
                                  >
                                    ({x.barcode})
                                  </span>
                                </div>
                              ))}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                  <table className="border-table fs14">
                    <thead>
                      <tr>
                        <th colSpan={12}>2. 이전출고</th>
                      </tr>
                      <tr>
                        <th>
                          설치구분
                          <br />
                          (송장번호)
                        </th>
                        <th>
                          수령인
                          <br />
                          (시간)
                        </th>
                        <th>
                          연락처1
                          <br />
                          연락처2
                        </th>
                        <th colSpan={4}>
                          주소 <br /> (기사 요청사항)
                        </th>
                        <th colSpan={5}>
                          제품명 <br /> (바코드)
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center', fontWeight: 600 }}>
                      {printData?.detailLoanData?.moves.map((list, idx) => (
                        <React.Fragment key={`1available_${idx}`}>
                          <tr>
                            <td>
                              {selectlabel(list.feeType, masterOptions?.FEE_TYPE)} <br /> ({list.invoiceSeq}){' '}
                            </td>
                            <td>
                              {list.receiverName}
                              <br />
                              {list.deliveryDatetime}
                            </td>
                            <td>
                              {list.receiverPhone}
                              <br />
                              {list.receiverPhone2}
                            </td>
                            <td colSpan={4}>
                              {list.receiverAddr1 + ' ' + list.receiverAddr2}
                              <br />({list.driverMemo || '-'})
                            </td>
                            <td style={{ padding: 0 }} colSpan={5}>
                              {list?.inventories?.map((x, idx) => (
                                <div key={`inventory1_${idx}`} style={{ borderTop: idx !== 0 ? '1px solid lightgray' : 'none' }}>
                                  <span>{x.modelName}</span>
                                  <br />
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setBarcodeInfoObj({
                                        visible: true,
                                        data: x.barcode,
                                      });
                                    }}
                                  >
                                    ({x.barcode})
                                  </span>
                                </div>
                              ))}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                  <JustSign signWhen=" " signArr={['설치기사', '창고장']} contentArr={[selectlabel(printData?.userId, masterOptions?.DRIVER_LABEL) || '', '']} />
                </div>
                <div className="break-for-onePage-print">
                  <PrintTitle seq={printData?.loanSeq} title={'2. 기사출고 목록'} />
                  <table className="border-table fs14" style={{ width: '60%' }}>
                    <thead>
                      <tr>
                        <th>기사출고번호</th>
                        <th>설치기사</th>
                        <th>출고일</th>
                        <th>
                          출고지시
                          <br />
                          확정일시
                        </th>
                        <th>출고창고</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{printData?.loanSeq || ''}</th>
                        <th>{selectlabel(printData?.userId, masterOptions?.DRIVER_LABEL) || ''}</th>
                        <th>{printData?.loanDate || ''}</th>
                        <th>
                          {printData?.loanInitDatetime?.split('_')[0]}&nbsp;{printData?.loanInitDatetime?.split('_')[1]}
                        </th>
                        <th>{selectlabel(printData?.centerCode, masterOptions?.CENTER) || ''}</th>
                      </tr>
                    </tbody>
                  </table>
                  <table className="border-table fs14 table-avoid-break">
                    <thead>
                      <tr>
                        <th colSpan={18}>1. 양품출고</th>
                      </tr>
                      <tr>
                        <th colSpan={1} rowSpan={2}>
                          번호
                        </th>
                        <th colSpan={2} rowSpan={2}>
                          로케이션
                        </th>
                        <th colSpan={2} rowSpan={2}>
                          제품그룹
                        </th>
                        <th colSpan={4} rowSpan={2}>
                          제품
                        </th>
                        <th colSpan={4} rowSpan={2}>
                          제품명
                        </th>
                        <th colSpan={2} rowSpan={2}>
                          외부SKU
                        </th>
                        <th colSpan={2} rowSpan={2}>
                          바코드
                        </th>
                        <th colSpan={1} rowSpan={1}>
                          수량
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center', fontWeight: 600 }}>
                      {printData?.modelData?.availables?.map((ele, idx) => {
                        return (
                          <tr key={`produxt_available_${idx}`}>
                            <td>{idx + 1}</td>
                            <td colSpan={2}>{ele.locationCode}</td>
                            <td colSpan={2}>{selectlabel(ele.modelGroup, masterOptions?.MODEL_GROUP) || ''}</td>
                            <td colSpan={4}>{ele.model}</td>
                            <td colSpan={4}>{ele.modelName}</td>
                            <td colSpan={2}>{ele.skuNumber}</td>
                            <td
                              colSpan={2}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setBarcodeInfoObj({
                                  visible: true,
                                  data: ele.barcode,
                                });
                              }}
                            >
                              {ele.barcode}
                            </td>
                            <td>{ele.availableQuantity}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    {printData?.modelData?.availableTotal > 0 && (
                      <tfoot>
                        <tr>
                          <td colSpan={17} style={{ textAlign: 'right' }}>
                            총
                          </td>
                          <td style={{ textAlign: 'center' }}>{printData?.modelData?.availableTotal}</td>
                        </tr>
                      </tfoot>
                    )}
                  </table>
                  <table className="border-table fs14">
                    <thead>
                      <tr>
                        <th colSpan={18}>2. 이전출고</th>
                      </tr>
                      <tr>
                        <th colSpan={1} rowSpan={2}>
                          번호
                        </th>
                        <th colSpan={2} rowSpan={2}>
                          로케이션
                        </th>
                        <th colSpan={2} rowSpan={2}>
                          제품그룹
                        </th>
                        <th colSpan={4} rowSpan={2}>
                          제품
                        </th>
                        <th colSpan={4} rowSpan={2}>
                          제품명
                        </th>
                        <th colSpan={2} rowSpan={2}>
                          외부SKU
                        </th>
                        <th colSpan={2} rowSpan={2}>
                          바코드
                        </th>
                        <th colSpan={1} rowSpan={1}>
                          수량
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center', fontWeight: 600 }}>
                      {printData?.modelData?.moves?.map((ele, idx) => {
                        return (
                          <tr key={`produxt_move_${idx}`}>
                            <td>{idx + 1}</td>
                            <td colSpan={2}>{ele.locationCode}</td>
                            <td colSpan={2}>{selectlabel(ele.modelGroup, masterOptions?.MODEL_GROUP) || ''}</td>
                            <td colSpan={4}>{ele.model}</td>
                            <td colSpan={4}>{ele.modelName}</td>
                            <td colSpan={2}>{ele.skuNumber}</td>
                            <td
                              colSpan={2}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setBarcodeInfoObj({
                                  visible: true,
                                  data: ele.barcode,
                                });
                              }}
                            >
                              {ele.barcode}
                            </td>
                            <td>{ele.availableQuantity}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    {printData?.modelData?.moveTotal > 0 && (
                      <tfoot>
                        <tr>
                          <td colSpan={17} style={{ textAlign: 'right' }}>
                            총
                          </td>
                          <td style={{ textAlign: 'center' }}>{printData?.modelData?.moveTotal}</td>
                        </tr>
                      </tfoot>
                    )}
                  </table>
                  <JustSign signWhen=" " signArr={['설치기사', '창고장']} contentArr={[selectlabel(printData?.userId, masterOptions?.DRIVER_LABEL) || '', '']} />
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
