import { KeyboardEvent } from 'react';
import { Col, Row, Form } from 'reactstrap';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { PartnerSearchDTO } from 'pages/ETC/3user/_interface/user';

export const SearchBox = (props: ISearchBoxProps<PartnerSearchDTO>) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  const handleFixedFlag = (e) => {
    const id = e.target.id;
    if (id) {
      setSearchObj((prev) => {
        return {
          ...prev,
          [id]: !searchObj[id],
        };
      });
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">파트너코드</label>
          <InputD
            type="number"
            value={searchObj?.partnerSeq || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: Number(e.target.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">파트너명</label>
          <InputD
            value={searchObj?.partnerName || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                partnerName: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">운영상태</label>
          <SelectD
            value={
              searchObj?.status
                ? {
                    value: searchObj?.status,
                    label: MASTER_OBJ?.ACTIVE_OP?.[searchObj?.status],
                  }
                : null
            }
            options={MASTER_OPS?.ACTIVE_OP}
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                status: value,
              });
            }}
          />
        </Col>
        <Col colSpan={4}>
          <label className="col-form-label">파트너타입</label>
          <div className="radio-select-area">
            <span id="supplierFlag" className={searchObj?.supplierFlag === (true || undefined) ? 'active' : ''} onClick={handleFixedFlag}>
              공급사
            </span>
            <span id="sellerFlag" className={searchObj?.sellerFlag === (true || undefined) ? 'active' : ''} onClick={handleFixedFlag}>
              판매사
            </span>
            <span id="networkFlag" className={searchObj?.networkFlag === (true || undefined) ? 'active' : ''} onClick={handleFixedFlag}>
              위탁사
            </span>
            <span id="transportFlag" className={searchObj?.transportFlag === (true || undefined) ? 'active' : ''} onClick={handleFixedFlag}>
              운송사
            </span>
            <span id="forwarderFlag" className={searchObj?.forwarderFlag === (true || undefined) ? 'active' : ''} onClick={handleFixedFlag}>
              포워더
            </span>
          </div>
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
