import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Grid from 'components/grid/auiGrid';
import { LINEHAUL_TRANSFER_LIST, LINEHAUL_TRANSFER_REQUEST } from 'envVar';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { SelectD } from 'components/reactstrap/reactstrap';
import CommonModalNew from 'components/modal/commonModalNew';

const LinehaulCenterChangeModal = ({ modalObj, setModalObj, PfetchList }) => {
  const dispatch = useDispatch();
  const { visible, searchObj } = modalObj;

  const { masterOptions } = useSelector((state) => state.menu);
  const [rows, setRows] = useState(null);
  const [gridId, setGrid] = useState();

  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  useEffect(() => {
    if (visible === true) {
      const seed = uuidv4();
      setGrid(`linehaulCenterChangeModal2_${seed}`);
    } else {
      setGrid();
    }
  }, [visible]);

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'linehaul-centerChange') {
        centerChangeAPI(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const fetchList = async () => {
    dispatch(setLoading('GET'));
    let url =
      LINEHAUL_TRANSFER_LIST +
      `?transferCenterCode=${searchObj.transferCenterCode}&originalCenterCode=${searchObj.originalCenterCode}&destinationCenterCode=${searchObj.destinationCenterCode}&linehaulDate=${searchObj.linehaulDate}&linehaulType=${searchObj.linehaulType}`;

    const res = await httpClient.get(url);
    const data = res.data?.map((row) => {
      return {
        ...row,
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
      };
    });
    console.log(data);
    setRows(data);
    dispatch(setLoading(null));
  };

  const centerChangeAPI = async (data) => {
    const payloadArr = modalObj?.searchObj;
    const modelStockSeqs = data.map((a) => {
      return a.modelStockSeq;
    });
    console.log(modelStockSeqs);
    if (modelStockSeqs?.length > 0) {
      payloadArr.modelStockSeqs = modelStockSeqs;
      payloadArr.transferCenterCode = searchObj.transferCenterCode;
    }
    if (payloadArr.originalCenterCode !== payloadArr.transferCenterCode) {
      let url = LINEHAUL_TRANSFER_REQUEST;
      const rs = await httpClient.post(url, payloadArr);
      if (rs?.status === 200) {
        alert(`${selectlabel(payloadArr.originalCenterCode, masterOptions?.CENTER)}에서 ${selectlabel(payloadArr.transferCenterCode, masterOptions?.CENTER)}로 변경이 완료되었습니다.`);
        PfetchList();
      } else {
        // if (rs?.data?.message) alert(rs?.data?.message);
      }
      dispatch(setLoading(null));
    } else {
      alert('출발창고와 변경창고가 동일합니다. 서로 다른 창고를 선택하여주세요.');
    }
  };

  const columns = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품',
      dataField: 'modelName',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '변경할 수량',
      dataField: 'transferQuantity',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '변경가능 수량',
      dataField: 'availableQuantity',
      filter: {
        showIcon: true,
      },
    },
  ];

  return (
    <>
      <CommonModalNew
        title="출발창고 변경"
        visible={visible}
        setVisible={setModalObj}
        style={{ width: 1000 }}
        children={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '15rem' }}>
                <label className="col-form-label">출발창고</label>
                <SelectD
                  options={masterOptions?.CENTER}
                  onChange={({ value }) => {
                    setModalObj({
                      ...modalObj,
                      searchObj: {
                        ...modalObj?.searchObj,
                        transferCenterCode: value,
                      },
                    });
                  }}
                />
              </div>
              <div className="grid-button-area" style={{ margin: 15 }}>
                <div
                  onClick={() => {
                    if (!modalObj?.searchObj?.transferCenterCode) {
                      alert('출발창고를 선택해주세요!');
                    } else {
                      fetchList();
                    }
                  }}
                >
                  조회
                </div>
                <div
                  className="orange"
                  onClick={() =>
                    setGetCheckedDataTriggerThisGrid({
                      type: 'linehaul-centerChange',
                      id: `#${gridId}`,
                    })
                  }
                >
                  변경
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              {gridId && (
                <Grid
                  style={{ height: 500 }}
                  id={gridId}
                  rows={rows}
                  columns={columns}
                  getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
                  setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
                  settingOptions={{
                    showPageRowSelect: false,
                    showRowAllCheckBox: false,
                    showRowCheckColumn: true,
                    editable: false,
                  }}
                />
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default LinehaulCenterChangeModal;
