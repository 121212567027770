// react
import { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Barcode from 'react-barcode';

import ReactToPrint from 'react-to-print';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  //
  CustomerOrderInvoicePrintDTO,
  InvoiceDTOForOrderSheetPrint,
  InvoiceLadderTruckDTOForPrint,
  OrderListDTO,
} from '../../_interface/order';
import { numberThousandComma } from 'common/util/counting';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { orderService } from 'pages/ORDER/1order/_services/service';
import { setLoading } from 'redux/services/menuSlice';
import { HandleWithCare } from 'components/print/print';

const PrintBarcode500460 = styled.div({
  position: 'relative',
  paddingRight: 0,
  paddingLeft: 0,
  transform: 'rotate(-90deg)',
  fontSize: 16,
  fontWeight: 700,
});

export interface IPrints {
  visible?: boolean;
  type?: 'sheet' | 'old' | 'ladders';
  items?: OrderListDTO[];
}

const convertor = {
  ladders: {
    title: '사다리차 사용증',
    url: 'ladderTruck',
    flexDirection: 'column',
    width: 900,
  },
  sheet: {
    title: '주문서',
    url: 'invoice/order/sheet/print',
    width: 1200,
    flexDirection: 'column',
  },
  old: {
    title: '송장',
    url: 'invoice/print',
    width: 550,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
};

export const Prints = ({ printObj, setPrintObj }) => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [invoices, setInvoices] = useState<(InvoiceDTOForOrderSheetPrint & CustomerOrderInvoicePrintDTO & InvoiceLadderTruckDTOForPrint)[]>();

  const wrapperRef = useRef();
  useEffect(() => {
    console.log(printObj);
    if (printObj?.items?.length > 0) {
      fetchPrintData(printObj?.items);
    }
  }, []);

  const fetchPrintData = async (items) => {
    dispatch(setLoading('GET'));
    const searchDTO = items?.map((ele) => {
      return {
        invoiceSeq: ele.invoiceSeq,
      };
    });
    const { data } = await orderService.posOMSAction(convertor[printObj?.type]?.url, searchDTO, null);
    if ((data as InvoiceLadderTruckDTOForPrint[])?.length > 0) {
      setInvoices(data as InvoiceLadderTruckDTOForPrint[]);
    } else {
      alert('출력할 값이 없습니다!');
      setPrintObj(null);
    }
    dispatch(setLoading(null));
  };

  return (
    <>
      {invoices && (
        <CommonModalNew
          title={convertor[printObj?.type]?.title}
          style={{ width: convertor[printObj?.type]?.width }}
          visible={printObj?.visible}
          setVisible={() => setPrintObj(null)}
          rightTitle={
            <>
              <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
            </>
          }
          children={
            <>
              <div
                ref={wrapperRef}
                style={{
                  display: 'flex',
                  justifyContent: convertor[printObj?.type]?.justifyContent || 'center',
                  flexDirection: convertor[printObj?.type]?.flexDirection,
                  alignItems: convertor[printObj?.type]?.alignItems || 'center',
                }}
              >
                {printObj?.type === 'sheet' &&
                  invoices?.map((ele, idx) => {
                    return (
                      <div className="break-for-onePage-print" style={{ padding: 30 }} key={`invoice_${idx}`}>
                        <Row>
                          <Col />
                          <Col className="text-center">
                            <div className="printTitle" style={{ fontSize: 20 }}>
                              주 문 서
                            </div>
                          </Col>
                          <Col>{ele?.invoiceSeq && <Barcode width={1.5} value={ele?.invoiceSeq + ''} height={40} />}</Col>
                        </Row>

                        <table>
                          <tbody>
                            <tr>
                              <td>판매사</td>
                              <td style={{ fontSize: 15 }}> &nbsp;&nbsp;{selectlabel(ele.partnerSeq, masterOptions?.SELLER_WHOLE)}</td>
                            </tr>
                            <tr>
                              <td>송장유형</td>
                              <td style={{ fontSize: 15 }}> &nbsp;&nbsp;{selectlabel(ele.feeType, masterOptions?.FEE_TYPE)}</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="presenterInput">
                          <p>1. 주문 정보</p>
                          <table className="border-table">
                            <thead>
                              <tr>
                                <th>주문번호</th>
                                <td>{ele.orderSeq}</td>
                                <th>지정일</th>
                                <td>{ele.deliveryDate}</td>
                              </tr>
                              <tr>
                                <th>외부주문번호</th>
                                <td>{ele.externalOrderNumber}</td>
                                <th>주문자명</th>
                                <td>{ele.customerName}</td>
                              </tr>
                              <tr>
                                <th>송장번호</th>
                                <td>{ele.invoiceSeq}</td>
                                <th>수령자명</th>
                                <td>{ele.receiverName}</td>
                              </tr>
                              <tr>
                                <th>외부송장번호</th>
                                <td>{ele.externalInvoiceNumber}</td>
                                <th>주문자 연락처</th>
                                <td>{ele.customerPhone}</td>
                              </tr>
                              <tr>
                                <th>우편번호</th>
                                <td>{ele.receiverZipcode}</td>
                                <th>수령자 연락처</th>
                                <td>{ele.receiverPhone}</td>
                              </tr>
                              <tr>
                                <th>주소</th>
                                <td colSpan={3}>
                                  {ele.receiverAddr1} {ele.receiverAddr2 && ele.receiverAddr2}
                                </td>
                              </tr>
                            </thead>
                          </table>

                          <table className="border-table" style={{ marginTop: 26 }}>
                            <thead>
                              <tr>
                                <th>운임구분</th>
                                <td>{(ele.parcelPayType && selectlabel(ele.parcelPayType, masterOptions?.PARCEL_PAY_TYPE)) || '-'}</td>
                                <th>운임비</th>
                                <td>{ele.cashOnDelivery ? numberThousandComma(ele.cashOnDelivery) : '-'}</td>
                              </tr>
                              <tr>
                                <th>고객요청사항</th>
                                <td colSpan={3}>{ele.shipmentNote}</td>
                              </tr>
                            </thead>
                          </table>
                        </div>

                        <div className="presenterInput">
                          <p>2. 제품 정보</p>
                          <table className="border-table">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th colSpan={2}>제품</th>
                                <th colSpan={5}>제품명</th>
                                <th colSpan={2}>바코드</th>
                                <th colSpan={2}>외부SKU</th>
                                <th>수량</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ele?.productModels?.map((m, idx) => {
                                return (
                                  <tr key={`product_${idx}`}>
                                    <th>{idx + 1}</th>
                                    <th colSpan={2}>{m?.model}</th>
                                    <th colSpan={5}>
                                      {m?.handleWithCareFlag && <HandleWithCare />}
                                      {m?.modelName}
                                    </th>
                                    <th colSpan={2}>{m?.barcode}</th>
                                    <th colSpan={2}>{m?.skuNumber}</th>
                                    <th>{m?.quantity}</th>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  })}
                {printObj?.type === 'ladders' &&
                  invoices?.map((ele, idx) => {
                    return (
                      <div className="break-for-onePage-print" style={{ position: 'relative' }} key={`laddercar_${idx}`}>
                        <LaddercarTable data={ele} whos={0} />
                        <LaddercarTable data={ele} whos={1} />
                      </div>
                    );
                  })}
                {printObj?.type === 'old' &&
                  invoices?.map((a, idx) => {
                    const isSudogwon = a.receiverAddr1.split(' ')[0].includes('서울') || a.receiverAddr1.split(' ')[0].includes('인천') || a.receiverAddr1.split(' ')[0].includes('경기');
                    return (
                      <PrintBarcode500460 key={`print_${idx}`} className="break-for-onePage-print" style={{ width: 600, height: 600 }}>
                        <table className="printPage-order noborder" style={{ marginTop: 20 }}>
                          <tbody>
                            <tr>
                              <td />
                              <td className="ssm right">{a.registerDate ? a.registerDate : ''}</td> {/*주문승인날 */}
                              <td className="ssm right">{'운송장번호 : '}</td>
                              <td colSpan={2} className="ssm">
                                {a.customerOrderInvoiceSeq}
                              </td>
                              <td colSpan={3} rowSpan={3} className="bb bolder italic center">
                                <div
                                  className="overFlowHidden"
                                  style={{
                                    maxHeight: 75,
                                    height: 75,
                                  }}
                                >
                                  {selectlabel(a.centerCode || '', masterOptions?.CENTER_WHOLE)}
                                </div>
                              </td>
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td colSpan={2} rowSpan={2} className="b center bold">
                                {isSudogwon ? '수도권' : '지방권'}
                              </td>
                              <td colSpan={2} rowSpan={2} className="b italic">
                                <div
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    border: '1px solid black',
                                    overflow: 'visible',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {selectlabel(a.feeType || '', masterOptions?.FEE_TYPE)}
                                </div>
                              </td>
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td colSpan={7} rowSpan={2} className="s">
                                {a.receiverAddr1 ? a.receiverAddr1 : ''}&nbsp;
                                {a.receiverAddr1 ? a.receiverAddr2 : ''}
                              </td>
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td colSpan={2} rowSpan={2} className="b bold center">
                                <div className="colCenter">{a.receiverName ? a.receiverName.substring(0, a.receiverName.length - 1) + '*' : ''}</div>
                              </td>
                              <td colSpan={3} className="s">
                                {a.receiverPhone ? a.receiverPhone : ''}
                              </td>
                              <td colSpan={2} className="center bold s">
                                {a.skuNumber ? a.skuNumber : ''}
                              </td>
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td colSpan={3} className="s">
                                {a.receiverPhone2 ? a.receiverPhone2 : a.receiverPhone}
                              </td>
                              <td />
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td colSpan={7} rowSpan={2} className="s">
                                <div
                                  className="colCenter s"
                                  style={{
                                    maxHeight: 50,
                                    height: 50,
                                    border: '1px solid black',
                                  }}
                                >
                                  {a.shipmentNote ? a.shipmentNote : ''}
                                </div>
                              </td>
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td colSpan={2} rowSpan={2} className="m center">
                                {selectlabel(a.partnerSeq || '', masterOptions?.SELLER_WHOLE)}
                              </td>
                              <td colSpan={5} rowSpan={4} className="center">
                                {a?.invoiceSeq && <Barcode width={1} value={a?.invoiceSeq + '' || ''} height={40} />}
                              </td>
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td colSpan={2} className="center m italic">
                                {selectlabel(a.feeType || '', masterOptions?.FEE_TYPE)}
                              </td>
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td className="center s">중량(KG)</td>
                              <td className="m">{a.modelWeight}</td>
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td colSpan={7} rowSpan={3} className="m">
                                <div
                                  style={{
                                    maxHeight: 75,
                                    height: 75,
                                    paddingTop: 3,
                                  }}
                                >
                                  <span style={{ fontStyle: 'italic' }}>[{a.skuNumber ? a.skuNumber : ''}] &nbsp;</span>
                                  <span style={{ fontSize: 18 }}>{a.productName ? a.productName : ''}</span>
                                </div>
                              </td>
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td colSpan={2} className="center s">
                                <div
                                  style={{
                                    border: '1px solid black',
                                    height: '100%',
                                    width: '100%',
                                  }}
                                >
                                  {a.skuNumber ? a.skuNumber : ''}
                                </div>
                              </td>
                              <td className="right s">
                                <div
                                  style={{
                                    borderTop: '1px solid black',
                                    borderBottom: '1px solid black',
                                    height: '100%',
                                  }}
                                >
                                  구분 :
                                </div>
                              </td>
                              <td className="center s">
                                <div
                                  style={{
                                    border: '1px solid black',
                                    height: '100%',
                                    width: '100%',
                                  }}
                                >
                                  {selectlabel(a.partnerSeq || '', masterOptions?.SELLER_WHOLE)}
                                </div>
                              </td>
                              <td className="right s">
                                <div
                                  style={{
                                    borderTop: '1px solid black',
                                    borderBottom: '1px solid black',
                                    height: '100%',
                                  }}
                                >
                                  바코드 :
                                </div>
                              </td>
                              <td colSpan={2} className="s">
                                <div
                                  style={{
                                    border: '1px solid black',
                                    height: '100%',
                                    width: '100%',
                                  }}
                                >
                                  {a.barcode ? a.barcode : ''}
                                </div>
                              </td>
                              <td />
                            </tr>
                          </tbody>
                        </table>
                      </PrintBarcode500460>
                    );
                  })}
              </div>
            </>
          }
        />
      )}
    </>
  );
};

const LaddercarTable = ({ data, whos }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  return (
    <div style={{ padding: 50 }}>
      {whos === 1 && (
        <>
          <div
            style={{
              height: 3,
              width: '100%',
              margin: '50px 0',
              marginBottom: 100,
              backgroundColor: '#9f9f9f',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#9f9f9f',
            }}
          >
            절취선입니다
          </div>
        </>
      )}
      <table className="border-table">
        <tbody>
          <tr>
            <th colSpan={8}>사다리차 사용증({whos === 0 ? '설치기사용' : '사다리차기사용'})</th>
          </tr>
          <tr>
            <th>지사명</th>
            <td colSpan={3}> {selectlabel(data?.centerCode, masterOptions?.CENTER_WHOLE)}</td>
            <th>지정일</th>
            <td colSpan={3}>{data?.deliveryDate}</td>
          </tr>
          <tr>
            <th>송장번호(외부송장번호)</th>
            <td colSpan={3}>
              {data?.customerOrderInvoiceSeq}
              {data?.externalInvoiceNumber ? `(${data?.externalInvoiceNumber})` : ''}
            </td>
            <th>제품명</th>
            <td colSpan={3}>{data?.productName}</td>
          </tr>
          <tr>
            <th>주소</th>
            <td colSpan={7}>
              {data?.receiverAddr1}&nbsp;{data?.receiverAddr2}
            </td>
          </tr>
          <tr>
            <th>고객명</th>
            <td colSpan={3}>{data?.receiverName}</td>
            <th>작업시간</th>
            <td colSpan={3}></td>
          </tr>
          <tr>
            <th>작업층수</th>
            <td colSpan={3}></td>
            <td colSpan={4} style={{ textAlign: 'center' }}>
              사다리차(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)&nbsp;&nbsp;&nbsp; 스카이차(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)&nbsp;&nbsp;&nbsp; 회차(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
            </td>
          </tr>

          <tr>
            <th rowSpan={3} className="thin">
              확<br />인
            </th>
            <th>구분</th>
            <td colSpan={2}></td>
            <th>사다리차</th>
            <td colSpan={3}></td>
          </tr>

          <tr>
            <th>기사명</th>
            <td colSpan={2}></td>
            <th colSpan={4}></th>
          </tr>

          <tr>
            <th>연락처</th>
            <td colSpan={2}></td>
            <th colSpan={4}></th>
          </tr>
        </tbody>
      </table>
      <div className="b" style={{ marginTop: 10 }}>
        * 특수장비 사업부 콜창고: 02-2631-2404, 02-2632-2404
        <br />
        * 주의사항 <br />
        <span>
          - 안전을 위해 사다리 기사의 작업안전 지시를 준수해 주세요.
          <br />- 사다리차 운반카에 사람 탑승 절대 금지
        </span>
      </div>
    </div>
  );
};
