import { ReactNode, useMemo, useRef, useState } from 'react';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';

// component
import GridBox, { IPagingGrid, PARCEL_GRID_PAGE_ROW_COUNT } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';

import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
// service
import { RootState } from 'redux/store';
import { COMMON_GRIDID, setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CargoManifestDTO, CargoManifestSearchDTO } from '../../../_interface/order';
import useGridButton from 'hooks/grid/useGridButton';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
// component
import { PagingListDTO } from 'interface/util';
import { Detail, IDetail } from './component/detail';
import { ActionPopup, IActionObj } from './component/actionPopup';
import { DEFAULT_COLUMNS } from 'pages/CARGO/_asset/asset';
import { useDidMountEffect } from 'hooks/basichooks';
import { CHAIprint } from './component/print';
import { defaultFixedColumnCountObjType, gridParamToGridColumn } from 'common/grid/columnManager';
import { CARGO_MANIFEST_INV_REGIT_FORM, CARGO_MANIFEST_LOADFAIL_REGIT_FORM, CARGO_MANIFEST_TARGET_REGIT_FORM, CARGO_MANIFEST_TRANSFER_REGIT_FORM } from 'envVar2';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();

  const GRIDID = useMemo(() => {
    return `${COMMON_GRIDID}${tabId}`;
  }, [tabId]);

  const labellingKr = (data: PagingListDTO<any>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        statusKr: MASTER_OBJ?.CM_STATUS_2?.[row.status],
        transportTypeKr: MASTER_OBJ?.CM_TRANSPORT_TYPE?.[row.transportType],
        fromPortCodeKr: MASTER_OBJ?.PORT_CODE?.[row.fromPortCode],
        toPortCodeKr: MASTER_OBJ?.PORT_CODE?.[row.toPortCode],
        shipperZipcode: row.shipperZipcode1 + ' ' + row.shipperZipcode2,
        consigneeAddr: row.consigneeAddr1 + ' ' + row.consigneeAddr2,
        searchObj: JSON.stringify(searchObj),
        //
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: CargoManifestSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.orderAction(`cargoManifest/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<CargoManifestDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const downloadExcel = async (type: 'main' | 'detail') => {
    const prompt = window.confirm(`엑셀다운로드 ${type === 'main' ? '' : '*상세*'}를 시작합니다. \n※ 많은 양의 데이터는 30초 이상의 시간이 소요될 수 있습니다.`);
    if (prompt) {
      dispatch(setLoading('GET'));
      const { list } = labellingKr(
        {
          list:
            type === 'main'
              ? ((await serviceStore.orderAction('cargoManifest/excel', 'GET', searchObj, null))?.data as CargoManifestDTO[])
              : ((await serviceStore.orderAction('cargoManifest/detail/excel', 'GET', searchObj, null))?.data as CargoManifestDTO[]),
        },
        true,
      );
      if (type === 'main') {
        excelGridRef.current.setGridData(list);
        excelGridRef.current.exportAsXlsx({ fileName: '통관목록조회' });
      } else {
        const maxLength = list.map((ele) => ele.items?.length);
        const maxValue = Math.max(...maxLength);
        const itemsArr = [];
        new Array(maxValue).fill(0).forEach((ele, idx) => {
          itemsArr.push({
            headerText: `품목${idx + 1} 정보`,
            children: [
              {
                headerText: '상품명',
                dataField: `itemName${idx + 1}`,
              },
              {
                headerText: '상품명(영어)',
                dataField: `itemNameEn${idx + 1}`,
              },
              {
                headerText: '수량',
                dataField: `itemQuantity${idx + 1}`,
              },
              {
                headerText: '단가',
                dataField: `itemPrice${idx + 1}`,
              },
            ],
          });
        });
        list.forEach((row) => {
          row.items?.forEach((el, idx) => {
            row[`itemName${idx + 1}`] = el.itemName;
            row[`itemNameEn${idx + 1}`] = el.itemNameEn;
            row[`itemQuantity${idx + 1}`] = el.itemQuantity;
            row[`itemPrice${idx + 1}`] = el.itemPrice;
          });
        });
        excelDetailGridRef.current.changeColumnLayout(detailColumns.concat(itemsArr));
        excelDetailGridRef.current.setGridData(list);

        excelDetailGridRef.current.exportAsXlsx({ fileName: '통관목록조회(상세)' });
      }
      dispatch(setLoading(null));
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    console.log(id);
    if (id === 'CARGO_MANIFEST_EXCELDOWN_MAIN') {
      downloadExcel('main');
    } else if (id === 'CARGO_MANIFEST_EXCELDOWN_DETAIL') {
      downloadExcel('detail');
    } else if (id === 'CARGO_MANIFEST_TRANSFER_REGIT_FORM') {
      window.open(CARGO_MANIFEST_TRANSFER_REGIT_FORM);
    } else if (id === 'CARGO_MANIFEST_TARGET_REGIT_FORM') {
      window.open(CARGO_MANIFEST_TARGET_REGIT_FORM);
    } else if (id === 'CARGO_MANIFEST_LOADFAIL_REGIT_FORM') {
      window.open(CARGO_MANIFEST_LOADFAIL_REGIT_FORM);
    } else if (id === 'CARGO_MANIFEST_INV_REGIT_FORM') {
      window.open(CARGO_MANIFEST_INV_REGIT_FORM);
    } else if (id?.includes('_REGIT') || id?.includes('_INV')) {
      uploaderClick(id);
    } else {
      handleCheckItems(id);
    }
  };
  const [printObj, setPrintObj] = useState<{ visible: boolean; data: string[] }>();

  const printURL = async (items) => {
    if (window.confirm('차이냐오 운송장PDF 프린트를 가져오시겠습니까?')) {
      const dataDTO = items?.map((ele) => {
        return {
          seq: ele.cargoManifestSeq,
        };
      });
      const { data } = await serviceStore.orderAction(`cargoManifest/printPDF`, 'POST', null, dataDTO);
      if (data) {
        setPrintObj({ visible: true, data: data as string[] });
      }
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      if (id === 'CARGO_MANIFEST_TRANSFER_PRINT_CHAINAO') printURL(items);
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const HEADERLINEIDX = {
    CARGO_MANIFEST_TARGET_REGIT: -1,
  };

  const KRTOVALUE = {
    transportType: 'CM_TRANSPORT_TYPE',
    fromPortCode: 'PORT_CODE',
    toPortCode: 'PORT_CODE',
  };

  const EXCELHEADER = {
    CARGO_MANIFEST_TARGET_REGIT: ['key', 'value'],
    //
    CARGO_MANIFEST_LOADFAIL_REGIT: ['houseNumber', 'opCode'],
    CARGO_MANIFEST_FAILURE_REGIT: ['houseNumber', 'opCode'],
    CARGO_MANIFEST_FAILURE_DELIVERY_REGIT: ['houseNumber', 'opCode'],
    CARGO_MANIFEST_FAILURE_CCPEND_REGIT: ['houseNumber', 'opCode'],
    //
    CARGO_MANIFEST_TRANSFER_REGIT: ['houseNumbers'],
    CARGO_MANIFEST_INV_SEND_BACK: ['houseNumbers'],
    CARGO_MANIFEST_INV_PLAN: ['houseNumbers'],
    CARGO_MANIFEST_INV_DISCARD: ['houseNumbers'],
    CARGO_MANIFEST_INV_RETURN_READY: ['houseNumbers'],
    CARGO_MANIFEST_INV_RETURN_HANDOVER: ['houseNumbers'],
  };

  const converter = {
    CARGO_MANIFEST_LOADFAIL_REGIT: 'loadFail',
    CARGO_MANIFEST_FAILURE_REGIT: 'ccFail',
    CARGO_MANIFEST_FAILURE_DELIVERY_REGIT: 'fail',
    CARGO_MANIFEST_FAILURE_CCPEND_REGIT: 'ccPend',
    CARGO_MANIFEST_TRANSFER_REGIT: 'transfer',
    CARGO_MANIFEST_INV_SEND_BACK: 'sendBack',
    CARGO_MANIFEST_INV_PLAN: 'returnPlan',
    CARGO_MANIFEST_INV_DISCARD: 'returnDiscard',
    CARGO_MANIFEST_INV_RETURN_READY: 'returnReady',
    CARGO_MANIFEST_INV_RETURN_HANDOVER: 'returnHandover',
  };

  const uploadData = async ({ data, id, header }) => {
    let rs;
    if (id === 'CARGO_MANIFEST_TRANSFER_REGIT' || id?.includes('INV_')) {
      const dataDTO = { houseNumbers: data?.map((ele) => ele.houseNumbers) };
      rs = await serviceStore.orderAction(`cargoManifest/${converter[id]}`, 'POST', null, dataDTO);
    } else if (id === 'CARGO_MANIFEST_TARGET_REGIT') {
      const dataDTO = {
        masterNumber: data[0].value,
        transportType: MASTER_OBJ?.CM_TRANSPORT_TYPE?.[data[1].value],
        fromPortCode: MASTER_OBJ?.PORT_CODE?.[data[2].value],
        toPortCode: MASTER_OBJ?.PORT_CODE?.[data[3].value],
        estimatedTimeDeparture: data[4].value,
        estimatedTimeArrival: data[5].value,
        houseNumbers: data?.slice(7)?.map((row) => row?.key),
      };
      rs = await serviceStore.orderAction(`cargoManifest/load`, 'POST', null, dataDTO);
    } else {
      rs = await serviceStore.orderAction(`cargoManifest/${converter[id]}`, 'POST', null, data);
    }
    if (rs?.status === 200) {
      alert(rs?.data?.message);
      wrappedFetchList(searchObj);
    }

    dispatch(setLoading(null));
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE, HEADERLINEIDX });
  useDidMountEffect(() => {
    if (parsedData?.data?.length > 0) {
      uploadData({ data: parsedData?.data, id: parsedData?.id, header: parsedData?.header });
    }
  }, [parsedData]);

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: MASTER_OBJ?.[GRIDID]?.pageSize || PARCEL_GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    // fetchTotal,
    fetchPaging,
  });

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const openDetail = async (e) => {
    console.log(e.item);
    setDetailObj({
      visible: true,
      item: e.item,
    });
  };
  const registerMemo = async (e) => {
    setActionObj({
      visible: true,
      type: 'memo',
      item: e.item,
      searchObj: JSON.parse(e.item.searchObj),
      fetchFn: (searchObj) => wrappedFetchList(searchObj),
    });
  };

  const mappingFunction = {
    showDetail: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',

        onClick: openDetail,
      },
    },
    customCounsel: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '메모수정',
        onClick: registerMemo,
      },
    },
  };
  const [actionObj, setActionObj] = useState<IActionObj>();
  const [detailObj, setDetailObj] = useState<IDetail>();
  const [columns, _] = useState(gridParamToGridColumn(MASTER_OBJ?.[GRIDID] ? MASTER_OBJ?.[GRIDID]?.userGridParameters : DEFAULT_COLUMNS, mappingFunction));

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '주문번호',
      dataField: 'orderNumber',
    },
    {
      headerText: '마스터 B/L',
      dataField: 'masterNumber',
    },
    {
      headerText: '하우스 B/L',
      dataField: 'houseNumber',
    },
    {
      headerText: '상태값',
      dataField: 'statusKr',
    },
    {
      headerText: '운송유형',
      dataField: 'transportTypeKr',
    },
    {
      headerText: '발송인정보',
      children: [
        {
          headerText: '발송인',
          dataField: 'shipperName',
        },
        {
          headerText: '우편번호',
          dataField: 'shipperZipcode',
        },
        {
          headerText: '주소',
          dataField: 'shipperAddr1',
        },
        {
          headerText: '상세주소',
          dataField: 'shipperAddr2',
        },
        {
          headerText: '연락처',
          dataField: 'shipperPhone',
        },
      ],
    },
    {
      headerText: '수취인정보',
      children: [
        {
          headerText: '발송인',
          dataField: 'consigneeName',
        },
        {
          headerText: '우편번호',
          dataField: 'consigneeZipcode',
        },
        {
          headerText: '주소',
          dataField: 'consigneeAddr1',
        },
        {
          headerText: '상세주소',
          dataField: 'consigneeAddr2',
        },
        {
          headerText: '연락처',
          dataField: 'consigneePhone',
        },
        {
          headerText: '이메일',
          dataField: 'consigneeEmail',
        },
      ],
    },
    {
      headerText: '비고',
      dataField: 'memo',
    },
    {
      headerText: '출항지코드',
      dataField: 'fromPortCode',
    },
    {
      headerText: '입항지코드',
      dataField: 'toPortCode',
    },
    {
      headerText: '출항지',
      dataField: 'fromPortCodeKr',
    },
    {
      headerText: '입항지',
      dataField: 'toPortCodeKr',
    },
    {
      headerText: '출발<br />예정시간',
      dataField: 'estimatedTimeDeparture',
    },
    {
      headerText: '도착<br />예정시간',
      dataField: 'estimatedTimeArrival',
    },
    {
      headerText: '개인통관고유부호',
      dataField: 'personalCustomsClearanceCode',
    },
    {
      headerText: '상품정보',
      children: [
        {
          headerText: '상품명',
          dataField: 'productName',
        },
        {
          headerText: '상품명(영어)',
          dataField: 'productNameEn',
        },
        {
          headerText: '추천상품명',
          dataField: 'suggestedProductName',
        },
        {
          headerText: '추천상품명(영어)',
          dataField: 'suggestedProductNameEn',
        },
        {
          headerText: '전체수량',
          dataField: 'totalQuantity',
        },
        {
          headerText: '전체금액',
          dataField: 'totalPrice',
        },
        {
          headerText: '전체무게(kg)',
          dataField: 'totalWeight',
        },
        {
          headerText: '신고금액',
          dataField: 'declarePrice',
        },
        {
          headerText: 'HS코드',
          dataField: 'hsCode',
        },
        {
          headerText: '전자상거래 URL',
          dataField: 'ecommerceWebsiteUrl',
        },
      ],
    },
  ];

  const defaultFixedColumnCountObj: defaultFixedColumnCountObjType = {
    CARGO_MANIFEST: 1,
  };

  return (
    <div className="page-content">
      {printObj?.visible && <CHAIprint printObj={printObj} setPrintObj={setPrintObj} />}
      {actionObj?.visible && <ActionPopup actionObj={actionObj} setActionObj={setActionObj} />}
      {detailObj?.visible && <Detail detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox" style={{ marginTop: 0 }}>
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}

        {GRIDID && (
          <GridBox
            gridId={GRIDID}
            gridRef={gridRef}
            columns={columns}
            gridProps={{
              fixedColumnCount: MASTER_OBJ?.[GRIDID]?.fixedColumn || 1,
              pageRowCount: MASTER_OBJ?.[GRIDID]?.pageSize || PARCEL_GRID_PAGE_ROW_COUNT,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
            }}
            defaultFixedColumnCountObj={defaultFixedColumnCountObj}
          />
        )}
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={detailColumns} />
      </div>
    </div>
  );
};

export default Index;
