import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { stringCommaToInt, stringToThousandComma } from 'common/util/counting';
import { feeService } from 'pages/FEE/_service/service';
import { OrderReceiptSearchDTO } from '../../../_interface/order';

export interface IAdjustmentObj {
  visible?: boolean;
  data?: string[];
  searchObj?: OrderReceiptSearchDTO;
  fetchFn?: (data) => {};
  type?: 'in' | 'out' | 'storage' | 'default';
}
export const AdjustmentPopup = ({ adjustmentObj, setAdjustmentObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const defaultAdmjustment = {
    type: null,
    adjustmentFee: 0,
    adjustmentFeeTax: 0,
  };
  const [obj, setObj] = useState([defaultAdmjustment]);

  const saveObj = async (obj) => {
    const orderReceiptAdjustments = obj?.map((ele) => {
      return {
        ...ele,
        adjustmentFee: stringCommaToInt(ele.adjustmentFee),
        adjustmentFeeTax: stringCommaToInt(ele.adjustmentFeeTax),
      };
    });
    const rs = await feeService.postAdjustmentSave({ orderReceiptSeqs: adjustmentObj?.data, orderReceiptAdjustments });
    if (rs?.status === 200) {
      adjustmentObj?.fetchFn(adjustmentObj?.searchObj);
      setAdjustmentObj(null);
    }
  };

  const handleInput = (e) => {
    const adjustments = JSON.parse(JSON.stringify(obj));
    adjustments[e.target.id.split('_')[1]][e.target.id.split('_')[0]] = stringToThousandComma(e.target.value);
    setObj(adjustments);
  };

  return (
    <CommonModalNew
      title={'조정금액 추가'}
      style={{ width: 600 }}
      visible={adjustmentObj?.visible}
      setVisible={() => {
        setAdjustmentObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      rightTitle={
        <>
          <button
            className="btn-secondary btn"
            onClick={(e) => {
              saveObj(obj);
            }}
          >
            추가
          </button>
        </>
      }
      children={
        <div style={{ display: 'flex', alignContent: 'center', textAlign: 'center' }}>
          <div style={{ width: '100%' }}>
            {obj?.map((adjustment, idx) => {
              return (
                <div key={`adjustment_${idx}`} style={{ display: 'flex' }}>
                  <div style={{ width: 350, marginRight: 10 }}>
                    <label className="col-form-label">조정사유</label>
                    <SelectD
                      options={MASTER_OPS?.ADJUSTMENT_TYPE}
                      value={
                        adjustment?.type
                          ? {
                              value: adjustment?.type,
                              label: MASTER_OBJ?.ADJUSTMENT_TYPE?.[adjustment?.type],
                            }
                          : null
                      }
                      onChange={({ value }) => {
                        const adjustments = JSON.parse(JSON.stringify(obj));
                        adjustments[idx]['type'] = value;
                        setObj(adjustments);
                      }}
                    />
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <label className="col-form-label">공급가액</label>
                    <InputD id={`adjustmentFee_${idx}`} value={adjustment?.adjustmentFee} onChange={handleInput} />
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <label className="col-form-label">부가세액</label>
                    <InputD id={`adjustmentFeeTax_${idx}`} value={adjustment?.adjustmentFeeTax} onChange={handleInput} />
                  </div>
                  {idx !== 0 ? (
                    <button
                      className="btn-etc ssm btn"
                      onClick={(e) => {
                        const adjustments = JSON.parse(JSON.stringify(obj)).filter((ele, i) => idx !== i);
                        setObj(adjustments);
                      }}
                    >
                      삭제
                    </button>
                  ) : (
                    <button
                      className="btn-etc btn ssm"
                      onClick={(e) => {
                        const adjustments = JSON.parse(JSON.stringify(obj));
                        adjustments.push(defaultAdmjustment);
                        setObj(adjustments);
                      }}
                    >
                      + 추가
                    </button>
                  )}
                  <hr />
                </div>
              );
            })}
          </div>
        </div>
      }
    />
  );
};
