export const UPLOADFORM = {
  DEFAULT: {
    HEADQUARTERS:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EA%B8%B0%EB%B3%B8%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EA%B8%B0%EB%B3%B8%EA%B8%88%EC%95%A1_%EB%B3%B8%EC%82%AC.xlsx',
    SUBCONTRACT:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EA%B8%B0%EB%B3%B8%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EA%B8%B0%EB%B3%B8%EA%B8%88%EC%95%A1_%EB%8F%84%EA%B8%89.xlsx',
    CENTER:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EA%B8%B0%EB%B3%B8%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EA%B8%B0%EB%B3%B8%EA%B8%88%EC%95%A1_%EC%A7%80%EC%A0%90.xlsx',
    DRIVER:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EA%B8%B0%EB%B3%B8%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EA%B8%B0%EB%B3%B8%EA%B8%88%EC%95%A1_%ED%8C%80.xlsx',
  },
  PRODUCT: {
    HEADQUARTERS:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EC%83%81%ED%92%88%EB%B3%84%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EC%83%81%ED%92%88%EB%B3%84+%EA%B8%88%EC%95%A1_%EB%B3%B8%EC%82%AC.xlsx',
    SUBCONTRACT:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EC%83%81%ED%92%88%EB%B3%84%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EC%83%81%ED%92%88%EB%B3%84+%EA%B8%88%EC%95%A1_%EB%8F%84%EA%B8%89.xlsx',
    CENTER:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EC%83%81%ED%92%88%EB%B3%84%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EC%83%81%ED%92%88%EB%B3%84+%EA%B8%88%EC%95%A1_%EC%A7%80%EC%A0%90.xlsx',
    DRIVER:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EC%83%81%ED%92%88%EB%B3%84%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EC%83%81%ED%92%88%EB%B3%84+%EA%B8%88%EC%95%A1_%ED%8C%80.xlsx',
  },
  GROUP: {
    HEADQUARTERS: '',
    SUBCONTRACT: '',
    CENTER: '',
    DRIVER: '',
  },
  REGION: {
    HEADQUARTERS:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EB%8F%84%EC%84%9C%EC%82%B0%EA%B0%84%EB%B3%84+%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EB%8F%84%EC%84%9C%EC%82%B0%EA%B0%84%EB%B3%84+%EA%B8%88%EC%95%A1_%EB%B3%B8%EC%82%AC.xlsx',
    SUBCONTRACT:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EB%8F%84%EC%84%9C%EC%82%B0%EA%B0%84%EB%B3%84+%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EB%8F%84%EC%84%9C%EC%82%B0%EA%B0%84%EB%B3%84+%EA%B8%88%EC%95%A1_%EB%8F%84%EA%B8%89.xlsx',
    CENTER:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EB%8F%84%EC%84%9C%EC%82%B0%EA%B0%84%EB%B3%84+%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EB%8F%84%EC%84%9C%EC%82%B0%EA%B0%84%EB%B3%84+%EA%B8%88%EC%95%A1_%EC%A7%80%EC%A0%90.xlsx',
    DRIVER:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EB%8F%84%EC%84%9C%EC%82%B0%EA%B0%84%EB%B3%84+%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EB%8F%84%EC%84%9C%EC%82%B0%EA%B0%84%EB%B3%84+%EA%B8%88%EC%95%A1_%ED%8C%80.xlsx',
  },
  MODEL: {
    HEADQUARTERS:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EC%A0%9C%ED%92%88%EB%B3%84%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EC%A0%9C%ED%92%88%EB%B3%84+%EA%B8%88%EC%95%A1_%EB%B3%B8%EC%82%AC.xlsx',
    SUBCONTRACT:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EC%A0%9C%ED%92%88%EB%B3%84%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EC%A0%9C%ED%92%88%EB%B3%84+%EA%B8%88%EC%95%A1_%EB%8F%84%EA%B8%89.xlsx',
    CENTER:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EC%A0%9C%ED%92%88%EB%B3%84%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EC%A0%9C%ED%92%88%EB%B3%84+%EA%B8%88%EC%95%A1_%EC%A7%80%EC%A0%90.xlsx',
    DRIVER:
      'https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/%EC%A0%95%EC%82%B0/%EC%A0%9C%ED%92%88%EB%B3%84%EA%B8%88%EC%95%A1/%EA%B8%B0%EC%A4%80%EC%A0%95%EB%B3%B4_%EC%A0%9C%ED%92%88%EB%B3%84+%EA%B8%88%EC%95%A1_%ED%8C%80.xlsx',
  },
  DATE: {
    HEADQUARTERS: '',
    SUBCONTRACT: '',
    CENTER: '',
    DRIVER: '',
  },
  CANCEL: {
    HEADQUARTERS: '',
    SUBCONTRACT: '',
    CENTER: '',
    DRIVER: '',
  },
  OPTION: {
    HEADQUARTERS: '',
    SUBCONTRACT: '',
    CENTER: '',
    DRIVER: '',
  },
};

export const convertKr = {
  invoiceType: '송장유형',
  invoiceTypes: '송장유형',
  cancelReasons: '취소사유',
  partnerSeq: '판매사',
  infraSeq: '인프라',
  centerCode: '창고',
  groupName: '그룹명',
  modelName: '모델명',
  teamGrade: '설치팀등급',
  party: '소속',
  modelGroupLarge: '대분류',
  modelGroupMedium: '중분류',
  modelGroupSmall: '소분류',
  productSeq: '상품번호',
  productName: '상품명',
  barcode: '바코드',
  province: '권역명(시/도)',
  city: '그룹병(시/군/구)',
  area: '그룹상세(읍/면/동)',
  type: '유형',
  detail: '유형상세',
  regionName: '도서산간명',
};

export const convertMaster = {
  partnerSeq: 'SELLER_WHOLE',
  infraSeq: 'INFRA',
  centerCode: 'CENTER_WHOLE',
  teamGrade: 'TEAM_GRADE',
  party: 'DRIVER_PARTY',
  modelGroupLarge: 'MODEL_GRP_1',
  modelGroupMedium: 'MODEL_GRP_2',
  modelGroupSmall: 'MODEL_GRP_3',
  invoiceType: 'FEE_TYPE',
  invoiceTypes: 'FEE_TYPE',
  cancelReasons: 'CANCEL_REASON',
  province: 'PROVINCE',
  city: 'CITY',
  type: 'OPTION_TYPE',
};

export const INPUT = ['productSeq', 'productName', 'groupName', 'area', 'modelName', 'barcode', 'detail', 'regionName'];
export const SELECT_SINGLE = ['partnerSeq', 'invoiceType', 'infraSeq', 'centerCode', 'teamGrade', 'party', 'modelGroupLarge', 'modelGroupMedium', 'modelGroupSmall', 'province', 'city', 'type'];
export const SELECT_MULTI = ['invoiceTypes', 'cancelReasons'];
export const NOMAL_ADDTYPE = ['DEFAULT', 'DATE', 'CANCEL', 'OPTION'];

export const getKey = (object: object): string => {
  let uniqueKey;
  Object.keys(object).forEach((key) => {
    if (key?.includes('orderFee') && !uniqueKey) uniqueKey = key;
  });
  return uniqueKey;
};

export const TYPE = {
  DEFAULT: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'infraSeq'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'centerCode'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'teamGrade', 'party'],
  },
  PRODUCT: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'infraSeq'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'centerCode'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'teamGrade', 'party'],
  },

  HAS_GROUP: {
    HEADQUARTERS: ['partnerSeq', 'invoiceType', 'groupName'],
    SUBCONTRACT: ['partnerSeq', 'invoiceType', 'infraSeq', 'groupName'],
    CENTER: ['partnerSeq', 'invoiceType', 'centerCode', 'groupName'],
    DRIVER: ['partnerSeq', 'invoiceType', 'centerCode', 'teamGrade', 'party', 'groupName'],
  },
  HAS_REGION: {
    HEADQUARTERS: ['partnerSeq', 'invoiceType', 'regionName'],
    SUBCONTRACT: ['partnerSeq', 'invoiceType', 'infraSeq', 'regionName'],
    CENTER: ['partnerSeq', 'invoiceType', 'centerCode', 'regionName'],
    DRIVER: ['partnerSeq', 'invoiceType', 'centerCode', 'teamGrade', 'party', 'regionName'],
  },
  GROUP: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes', 'groupName'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'infraSeq', 'groupName'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'groupName'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'teamGrade', 'party', 'groupName'],
  },
  REGION: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'infraSeq'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'centerCode'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'teamGrade', 'party'],
  },
  MODEL: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'infraSeq'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'centerCode'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'teamGrade', 'party'],
  },
  DATE: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'infraSeq'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'centerCode'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'teamGrade', 'party'],
  },
  CANCEL: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes', 'cancelReasons'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'cancelReasons', 'infraSeq'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'cancelReasons', 'centerCode'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'cancelReasons', 'centerCode', 'teamGrade', 'party'],
  },
  OPTION: {
    HEADQUARTERS: ['partnerSeq', 'type', 'detail'],
    SUBCONTRACT: ['partnerSeq', 'type', 'detail', 'infraSeq'],
    CENTER: ['partnerSeq', 'type', 'detail', 'centerCode'],
    DRIVER: ['partnerSeq', 'type', 'detail', 'centerCode', 'teamGrade', 'party'],
  },
};

export const SEARCH = {
  DEFAULT: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'infraSeq'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'centerCode'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'teamGrade', 'party'],
  },
  PRODUCT: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes', 'productSeq', 'productName'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'infraSeq', 'productSeq', 'productName'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'productSeq', 'productName'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'teamGrade', 'party', 'productSeq', 'productName'],
  },
  GROUP: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes', 'groupName', 'modelGroupLarge', 'modelGroupMedium', 'modelGroupSmall'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'infraSeq', 'groupName', 'modelGroupLarge', 'modelGroupMedium', 'modelGroupSmall'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'groupName', 'modelGroupLarge', 'modelGroupMedium', 'modelGroupSmall'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'teamGrade', 'party', 'groupName', 'modelGroupLarge', 'modelGroupMedium', 'modelGroupSmall'],
  },
  REGION: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes', 'regionName'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'infraSeq', 'regionName'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'regionName'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'teamGrade', 'party', 'regionName'],
  },
  MODEL: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes', 'modelName', 'barcode'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'infraSeq', 'modelName', 'barcode'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'modelName', 'barcode'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'teamGrade', 'party', 'modelName', 'barcode'],
  },
  DATE: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'infraSeq'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'centerCode'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'centerCode', 'teamGrade', 'party'],
  },
  CANCEL: {
    HEADQUARTERS: ['partnerSeq', 'invoiceTypes', 'cancelReasons'],
    SUBCONTRACT: ['partnerSeq', 'invoiceTypes', 'cancelReasons', 'infraSeq'],
    CENTER: ['partnerSeq', 'invoiceTypes', 'cancelReasons', 'centerCode'],
    DRIVER: ['partnerSeq', 'invoiceTypes', 'cancelReasons', 'centerCode', 'teamGrade', 'party'],
  },
  OPTION: {
    HEADQUARTERS: ['partnerSeq', 'type', 'detail'],
    SUBCONTRACT: ['partnerSeq', 'type', 'detail', 'infraSeq'],
    CENTER: ['partnerSeq', 'type', 'detail', 'centerCode'],
    DRIVER: ['partnerSeq', 'type', 'detail', 'centerCode', 'teamGrade', 'party'],
  },
};

export const COLUMNS = {
  DEFAULT_COLUMNS: {
    HEADQUARTERS: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    SUBCONTRACT: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '인프라',
        dataField: 'infraSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    CENTER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    DRIVER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '설치팀등급',
        dataField: 'teamGradeKr',
        editable: false,
      },
      {
        headerText: '소속',
        dataField: 'partyKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
  },

  PRODUCT_COLUMNS: {
    HEADQUARTERS: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '상품번호',
        dataField: 'productSeq',
        editable: false,
      },
      {
        headerText: '상품명',
        dataField: 'productName',
        editable: false,
      },
      {
        headerText: '상품구성수량',
        dataField: 'quantity',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    SUBCONTRACT: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '인프라',
        dataField: 'infraSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '상품번호',
        dataField: 'productSeq',
        editable: false,
      },
      {
        headerText: '상품명',
        dataField: 'productName',
        editable: false,
      },
      {
        headerText: '상품구성수량',
        dataField: 'quantity',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    CENTER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '상품번호',
        dataField: 'productSeq',
        editable: false,
      },
      {
        headerText: '상품명',
        dataField: 'productName',
        editable: false,
      },
      {
        headerText: '상품구성수량',
        dataField: 'quantity',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    DRIVER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '설치팀등급',
        dataField: 'teamGradeKr',
        editable: false,
      },
      {
        headerText: '소속',
        dataField: 'partyKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '상품번호',
        dataField: 'productSeq',
        editable: false,
      },
      {
        headerText: '상품명',
        dataField: 'productName',
        editable: false,
      },
      {
        headerText: '상품구성수량',
        dataField: 'quantity',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
  },

  GROUP_COLUMNS: {
    HEADQUARTERS: [
      {
        headerText: '상세보기',
        dataField: 'showDetail',
        editable: false,
      },
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '그룹명',
        dataField: 'groupName',
        editable: false,
      },
      {
        headerText: '그룹수량',
        dataField: 'quantity',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    SUBCONTRACT: [
      {
        headerText: '상세보기',
        dataField: 'showDetail',
        editable: false,
      },
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '인프라',
        dataField: 'infraSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '그룹명',
        dataField: 'groupName',
        editable: false,
      },
      {
        headerText: '그룹수량',
        dataField: 'quantity',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    CENTER: [
      {
        headerText: '상세보기',
        dataField: 'showDetail',
        editable: false,
      },
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '그룹명',
        dataField: 'groupName',
        editable: false,
      },
      {
        headerText: '그룹수량',
        dataField: 'quantity',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    DRIVER: [
      {
        headerText: '상세보기',
        dataField: 'showDetail',
        editable: false,
      },
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '설치팀등급',
        dataField: 'teamGradeKr',
        editable: false,
      },
      {
        headerText: '소속',
        dataField: 'partyKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '그룹명',
        dataField: 'groupName',
        editable: false,
      },
      {
        headerText: '그룹수량',
        dataField: 'quantity',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
  },
  REGION_COLUMNS: {
    HEADQUARTERS: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '도서산간명',
        dataField: 'regionName',
        editable: false,
      },
      {
        headerText: '완료금액',
        dataField: 'finishFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
      {
        headerText: '출고후취소금액',
        dataField: 'cancelAfterFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
      {
        headerText: '고객집도착후취소금액',
        dataField: 'cancelVisitFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
      {
        headerText: '개봉후취소금액',
        dataField: 'cancelOpenFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
    ],
    SUBCONTRACT: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '인프라',
        dataField: 'infraSeqKr',
        editable: false,
      },
      {
        headerText: '도서산간명',
        dataField: 'regionName',
        editable: false,
      },
      {
        headerText: '완료금액',
        dataField: 'finishFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
      {
        headerText: '출고후취소금액',
        dataField: 'cancelAfterFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
      {
        headerText: '고객집도착후취소금액',
        dataField: 'cancelVisitFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
      {
        headerText: '개봉후취소금액',
        dataField: 'cancelOpenFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
    ],
    CENTER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '도서산간명',
        dataField: 'regionName',
        editable: false,
      },
      {
        headerText: '완료금액',
        dataField: 'finishFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
      {
        headerText: '출고후취소금액',
        dataField: 'cancelAfterFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
      {
        headerText: '고객집도착후취소금액',
        dataField: 'cancelVisitFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
      {
        headerText: '개봉후취소금액',
        dataField: 'cancelOpenFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
    ],
    DRIVER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '설치팀등급',
        dataField: 'teamGradeKr',
        editable: false,
      },
      {
        headerText: '소속',
        dataField: 'partyKr',
        editable: false,
      },
      {
        headerText: '도서산간명',
        dataField: 'regionName',
        editable: false,
      },
      {
        headerText: '완료금액',
        dataField: 'finishFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
      {
        headerText: '출고후취소금액',
        dataField: 'cancelAfterFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
      {
        headerText: '고객집도착후취소금액',
        dataField: 'cancelVisitFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
      {
        headerText: '개봉후취소금액',
        dataField: 'cancelOpenFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
    ],
  },
  MODEL_COLUMNS: {
    HEADQUARTERS: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '제품명',
        dataField: 'modelName',
        editable: false,
      },
      {
        headerText: '바코드',
        dataField: 'barcode',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    SUBCONTRACT: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '인프라',
        dataField: 'infraSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '제품명',
        dataField: 'modelName',
        editable: false,
      },
      {
        headerText: '바코드',
        dataField: 'barcode',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    CENTER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '제품명',
        dataField: 'modelName',
        editable: false,
      },
      {
        headerText: '바코드',
        dataField: 'barcode',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    DRIVER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '설치팀등급',
        dataField: 'teamGradeKr',
        editable: false,
      },
      {
        headerText: '소속',
        dataField: 'partyKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '제품명',
        dataField: 'modelName',
        editable: false,
      },
      {
        headerText: '바코드',
        dataField: 'barcode',
        editable: false,
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
  },

  DATE_COLUMNS: {
    HEADQUARTERS: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '배송요일',
        children: [
          {
            headerText: '월',
            dataField: 'mondayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '화',
            dataField: 'tuesdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '수',
            dataField: 'wednesdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '목',
            dataField: 'thursdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '금',
            dataField: 'fridayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '토',
            dataField: 'saturdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '일',
            dataField: 'sundayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
        ],
      },
      {
        headerText: '공휴일',
        dataField: 'holidayFlag',
        style: 'sub-total-color',
        width: 30,
        renderer: {
          type: 'CheckBoxEditRenderer',
          editable: true,
        },
      },
      {
        headerText: '특정일',
        dataField: 'specificDateFlag',
        style: 'sub-total-color',
        width: 30,
        renderer: {
          type: 'CheckBoxEditRenderer',
          editable: true,
        },
      },
      {
        dataField: 'fromSpecificDate',
        headerText: '특정일(시작)',
        width: 120,
        style: 'sub-total-color',
        dataType: 'date',
        formatString: 'yyyy-mm-dd',
        filter: {
          showIcon: true,
        },
        editRenderer: {
          onlyCalendar: true,
          type: 'CalendarRenderer',
          showTodayBtn: true,
          showEditorBtnOver: true,
          showExtraDays: true,
          titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          defaultFormat: 'yyyy-mm-dd',
        },
        renderer: {
          type: 'TemplateRenderer',
        },
        //  //  style: 'my-custom-aui',
      },
      {
        dataField: 'toSpecificDate',
        headerText: '특정일(종료)',
        width: 120,
        dataType: 'date',
        style: 'sub-total-color',
        formatString: 'yyyy-mm-dd',
        filter: {
          showIcon: true,
        },
        editRenderer: {
          onlyCalendar: true,
          type: 'CalendarRenderer',
          showTodayBtn: true,
          showEditorBtnOver: true,
          showExtraDays: true,
          titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          defaultFormat: 'yyyy-mm-dd',
        },
        renderer: {
          type: 'TemplateRenderer',
        },
        //  //  style: 'my-custom-aui',
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    SUBCONTRACT: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '인프라',
        dataField: 'infraSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '배송요일',
        children: [
          {
            headerText: '월',
            dataField: 'mondayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '화',
            dataField: 'tuesdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '수',
            dataField: 'wednesdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '목',
            dataField: 'thursdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '금',
            dataField: 'fridayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '토',
            dataField: 'saturdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '일',
            dataField: 'sundayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
        ],
      },
      {
        headerText: '공휴일',
        dataField: 'holidayFlag',
        style: 'sub-total-color',
        width: 30,
        renderer: {
          type: 'CheckBoxEditRenderer',
          editable: true,
        },
      },
      {
        headerText: '특정일',
        dataField: 'specificDateFlag',
        style: 'sub-total-color',
        width: 30,
        renderer: {
          type: 'CheckBoxEditRenderer',
          editable: true,
        },
      },
      {
        dataField: 'fromSpecificDate',
        headerText: '특정일(시작)',
        width: 120,
        style: 'sub-total-color',
        dataType: 'date',
        formatString: 'yyyy-mm-dd',
        filter: {
          showIcon: true,
        },
        editRenderer: {
          onlyCalendar: true,
          type: 'CalendarRenderer',
          showTodayBtn: true,
          showEditorBtnOver: true,
          showExtraDays: true,
          titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          defaultFormat: 'yyyy-mm-dd',
        },
        renderer: {
          type: 'TemplateRenderer',
        },
        //  style: 'my-custom-aui',
      },
      {
        dataField: 'toSpecificDate',
        headerText: '특정일(종료)',
        width: 120,
        dataType: 'date',
        style: 'sub-total-color',
        formatString: 'yyyy-mm-dd',
        filter: {
          showIcon: true,
        },
        editRenderer: {
          onlyCalendar: true,
          type: 'CalendarRenderer',
          showTodayBtn: true,
          showEditorBtnOver: true,
          showExtraDays: true,
          titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          defaultFormat: 'yyyy-mm-dd',
        },
        renderer: {
          type: 'TemplateRenderer',
        },
        //  style: 'my-custom-aui',
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    CENTER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '배송요일',
        children: [
          {
            headerText: '월',
            dataField: 'mondayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '화',
            dataField: 'tuesdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '수',
            dataField: 'wednesdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '목',
            dataField: 'thursdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '금',
            dataField: 'fridayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '토',
            dataField: 'saturdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '일',
            dataField: 'sundayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
        ],
      },
      {
        headerText: '공휴일',
        dataField: 'holidayFlag',
        style: 'sub-total-color',
        width: 30,
        renderer: {
          type: 'CheckBoxEditRenderer',
          editable: true,
        },
      },
      {
        headerText: '특정일',
        dataField: 'specificDateFlag',
        style: 'sub-total-color',
        width: 30,
        renderer: {
          type: 'CheckBoxEditRenderer',
          editable: true,
        },
      },
      {
        dataField: 'fromSpecificDate',
        headerText: '특정일(시작)',
        width: 120,
        style: 'sub-total-color',
        dataType: 'date',
        formatString: 'yyyy-mm-dd',
        filter: {
          showIcon: true,
        },
        editRenderer: {
          onlyCalendar: true,
          type: 'CalendarRenderer',
          showTodayBtn: true,
          showEditorBtnOver: true,
          showExtraDays: true,
          titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          defaultFormat: 'yyyy-mm-dd',
        },
        renderer: {
          type: 'TemplateRenderer',
        },
        //  style: 'my-custom-aui',
      },
      {
        dataField: 'toSpecificDate',
        headerText: '특정일(종료)',
        width: 120,
        dataType: 'date',
        style: 'sub-total-color',
        formatString: 'yyyy-mm-dd',
        filter: {
          showIcon: true,
        },
        editRenderer: {
          onlyCalendar: true,
          type: 'CalendarRenderer',
          showTodayBtn: true,
          showEditorBtnOver: true,
          showExtraDays: true,
          titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          defaultFormat: 'yyyy-mm-dd',
        },
        renderer: {
          type: 'TemplateRenderer',
        },
        //  style: 'my-custom-aui',
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
    DRIVER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '설치팀등급',
        dataField: 'teamGradeKr',
        editable: false,
      },
      {
        headerText: '소속',
        dataField: 'partyKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '배송요일',
        children: [
          {
            headerText: '월',
            dataField: 'mondayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '화',
            dataField: 'tuesdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '수',
            dataField: 'wednesdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '목',
            dataField: 'thursdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '금',
            dataField: 'fridayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '토',
            dataField: 'saturdayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
          {
            headerText: '일',
            dataField: 'sundayFlag',
            style: 'sub-total-color',
            width: 30,
            renderer: {
              type: 'CheckBoxEditRenderer',
              editable: true,
            },
          },
        ],
      },
      {
        headerText: '공휴일',
        dataField: 'holidayFlag',
        style: 'sub-total-color',
        width: 30,
        renderer: {
          type: 'CheckBoxEditRenderer',
          editable: true,
        },
      },
      {
        headerText: '특정일',
        dataField: 'specificDateFlag',
        style: 'sub-total-color',
        width: 30,
        renderer: {
          type: 'CheckBoxEditRenderer',
          editable: true,
        },
      },
      {
        dataField: 'fromSpecificDate',
        headerText: '특정일(시작)',
        width: 120,
        style: 'sub-total-color',
        dataType: 'date',
        formatString: 'yyyy-mm-dd',
        filter: {
          showIcon: true,
        },
        editRenderer: {
          onlyCalendar: true,
          type: 'CalendarRenderer',
          showTodayBtn: true,
          showEditorBtnOver: true,
          showExtraDays: true,
          titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          defaultFormat: 'yyyy-mm-dd',
        },
        renderer: {
          type: 'TemplateRenderer',
        },
        //  style: 'my-custom-aui',
      },
      {
        dataField: 'toSpecificDate',
        headerText: '특정일(종료)',
        width: 120,
        dataType: 'date',
        style: 'sub-total-color',
        formatString: 'yyyy-mm-dd',
        filter: {
          showIcon: true,
        },
        editRenderer: {
          onlyCalendar: true,
          type: 'CalendarRenderer',
          showTodayBtn: true,
          showEditorBtnOver: true,
          showExtraDays: true,
          titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          defaultFormat: 'yyyy-mm-dd',
        },
        renderer: {
          type: 'TemplateRenderer',
        },
        //  style: 'my-custom-aui',
      },
      {
        headerText: '완료금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'finishFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'finishFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'finishFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '출고후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelAfterFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelAfterFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelAfterFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '고객집도착후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelVisitFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelVisitFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelVisitFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
      {
        headerText: '개봉후취소금액',
        children: [
          {
            headerText: '수도권',
            dataField: 'cancelOpenFeeForCapital',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '지방',
            dataField: 'cancelOpenFeeForProvince',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
          {
            headerText: '제주',
            dataField: 'cancelOpenFeeForJeju',
            style: 'sub-total-color',
            dataType: 'numeric',
            formatString: '#,##0',
          },
        ],
      },
    ],
  },

  CANCEL_COLUMNS: {
    HEADQUARTERS: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '취소사유',
        dataField: 'cancelReasonKr',
        editable: false,
      },
      {
        headerText: '취소시점',
        children: [
          {
            headerText: '출고후취소 적용여부(O/X)',
            dataField: 'cancelAfterFeeFlagKr',
            style: 'sub-total-color',
            renderer: {
              type: 'DropDownListRenderer',
              // list: masterOptions?.FLAG,
              keyField: 'value',
              valueField: 'label',
            },
          },
          {
            headerText: '고객집도착후취소 적용여부(O/X)',
            dataField: 'cancelVisitFeeFlagKr',
            style: 'sub-total-color',
            renderer: {
              type: 'DropDownListRenderer',
              // list: masterOptions?.FLAG,
              keyField: 'value',
              valueField: 'label',
            },
          },
          {
            headerText: '개봉후취소 적용여부(O/X)',
            dataField: 'cancelOpenFeeFlagKr',
            style: 'sub-total-color',
            renderer: {
              type: 'DropDownListRenderer',
              // list: masterOptions?.FLAG,
              keyField: 'value',
              valueField: 'label',
            },
          },
        ],
      },
    ],
    SUBCONTRACT: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '인프라',
        dataField: 'infraSeqKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '취소사유',
        dataField: 'cancelReasonKr',
        editable: false,
      },
      {
        headerText: '취소시점',
        children: [
          {
            headerText: '출고후취소 적용여부(O/X)',
            dataField: 'cancelAfterFeeFlagKr',
            style: 'sub-total-color',
            renderer: {
              type: 'DropDownListRenderer',
              // list: masterOptions?.FLAG,
              keyField: 'value',
              valueField: 'label',
            },
          },
          {
            headerText: '고객집도착후취소 적용여부(O/X)',
            dataField: 'cancelVisitFeeFlagKr',
            style: 'sub-total-color',
            renderer: {
              type: 'DropDownListRenderer',
              // list: masterOptions?.FLAG,
              keyField: 'value',
              valueField: 'label',
            },
          },
          {
            headerText: '개봉후취소 적용여부(O/X)',
            dataField: 'cancelOpenFeeFlagKr',
            style: 'sub-total-color',
            renderer: {
              type: 'DropDownListRenderer',
              // list: masterOptions?.FLAG,
              keyField: 'value',
              valueField: 'label',
            },
          },
        ],
      },
    ],
    CENTER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '취소사유',
        dataField: 'cancelReasonKr',
        editable: false,
      },
      {
        headerText: '취소시점',
        children: [
          {
            headerText: '출고후취소 적용여부(O/X)',
            dataField: 'cancelAfterFeeFlagKr',
            style: 'sub-total-color',
            renderer: {
              type: 'DropDownListRenderer',
              // list: masterOptions?.FLAG,
              keyField: 'value',
              valueField: 'label',
            },
          },
          {
            headerText: '고객집도착후취소 적용여부(O/X)',
            dataField: 'cancelVisitFeeFlagKr',
            style: 'sub-total-color',
            renderer: {
              type: 'DropDownListRenderer',
              // list: masterOptions?.FLAG,
              keyField: 'value',
              valueField: 'label',
            },
          },
          {
            headerText: '개봉후취소 적용여부(O/X)',
            dataField: 'cancelOpenFeeFlagKr',
            style: 'sub-total-color',
            renderer: {
              type: 'DropDownListRenderer',
              // list: masterOptions?.FLAG,
              keyField: 'value',
              valueField: 'label',
            },
          },
        ],
      },
    ],
    DRIVER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '설치팀등급',
        dataField: 'teamGradeKr',
        editable: false,
      },
      {
        headerText: '소속',
        dataField: 'partyKr',
        editable: false,
      },
      {
        headerText: '송장유형',
        dataField: 'invoiceTypeKr',
        editable: false,
      },
      {
        headerText: '취소사유',
        dataField: 'cancelReasonKr',
        editable: false,
      },
      {
        headerText: '취소시점',
        children: [
          {
            headerText: '출고후취소 적용여부(O/X)',
            dataField: 'cancelAfterFeeFlagKr',
            style: 'sub-total-color',
            renderer: {
              type: 'DropDownListRenderer',
              // list: masterOptions?.FLAG,
              keyField: 'value',
              valueField: 'label',
            },
          },
          {
            headerText: '고객집도착후취소 적용여부(O/X)',
            dataField: 'cancelVisitFeeFlagKr',
            style: 'sub-total-color',
            renderer: {
              type: 'DropDownListRenderer',
              // list: masterOptions?.FLAG,
              keyField: 'value',
              valueField: 'label',
            },
          },
          {
            headerText: '개봉후취소 적용여부(O/X)',
            dataField: 'cancelOpenFeeFlagKr',
            style: 'sub-total-color',
            renderer: {
              type: 'DropDownListRenderer',
              // list: masterOptions?.FLAG,
              keyField: 'value',
              valueField: 'label',
            },
          },
        ],
      },
    ],
  },

  OPTION_COLUMNS: {
    HEADQUARTERS: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '유형',
        dataField: 'typeKr',
        editable: false,
      },
      {
        headerText: '유형상세',
        dataField: 'detailKr',
        editable: false,
      },
      {
        headerText: '부가정보금액',
        dataField: 'additionalFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
    ],
    SUBCONTRACT: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '인프라',
        dataField: 'infraSeqKr',
        editable: false,
      },
      {
        headerText: '유형',
        dataField: 'typeKr',
        editable: false,
      },
      {
        headerText: '유형상세',
        dataField: 'detailKr',
        editable: false,
      },
      {
        headerText: '부가정보금액',
        dataField: 'additionalFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
    ],
    CENTER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '유형',
        dataField: 'typeKr',
        editable: false,
      },
      {
        headerText: '유형상세',
        dataField: 'detailKr',
        editable: false,
      },
      {
        headerText: '부가정보금액',
        dataField: 'additionalFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
    ],
    DRIVER: [
      {
        headerText: '판매사',
        dataField: 'partnerSeqKr',
        editable: false,
      },
      {
        headerText: '창고',
        dataField: 'centerCodeKr',
        editable: false,
      },
      {
        headerText: '설치팀등급',
        dataField: 'teamGradeKr',
        editable: false,
      },
      {
        headerText: '소속',
        dataField: 'partyKr',
        editable: false,
      },
      {
        headerText: '유형',
        dataField: 'typeKr',
        editable: false,
      },
      {
        headerText: '유형상세',
        dataField: 'detailKr',
        editable: false,
      },
      {
        headerText: '부가정보금액',
        dataField: 'additionalFee',
        style: 'sub-total-color',
        dataType: 'numeric',
        formatString: '#,##0',
      },
    ],
  },
};
