export const PARCEL_SERVICE_LIST_GRID = [
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'showDetail',
    parameterName: '운송장상세',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '운송장상세',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 1,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'printCountKr',
    parameterName: '출력여부',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '출력여부',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 2,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'infraSeqKr',
    parameterName: '인프라',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '인프라',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 3,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'parcelInvoiceNumber',
    parameterName: '운송장번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '운송장번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 4,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'houseNumber',
    parameterName: 'HBL',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: 'HBL',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 5,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'subNumber',
    parameterName: 'SBL',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: 'SBL',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 6,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'partnerSeqKr',
    parameterName: '판매사',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '판매사',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 7,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'parcelTypeKr',
    parameterName: '택배사',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '택배사',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 8,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'portCodeKr',
    parameterName: '양륙항',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '양륙항',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 9,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'boxSizeKr',
    parameterName: '박스크기',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '박스크기',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 10,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'registerDatetime',
    parameterName: '등록일시',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '등록일시',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 11,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'customsReleaseDatetime',
    parameterName: '세관반출일시',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '세관반출일시',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 12,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'collectScanDatetime',
    parameterName: '집화스캔일시',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '집화스캔일시',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 13,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'finishDatetime',
    parameterName: '완료일시',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '완료일시',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 14,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'customsReleaseProgressDayKr',
    parameterName: '반출경과일',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '반출경과일',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 15,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'collectProgressDayKr',
    parameterName: '집화경과일',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '집화경과일',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 16,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'statusKr',
    parameterName: '택배상태',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '택배상태',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 17,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'number',
    parameterName: '스캔순번',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '스캔순번',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 18,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'senderName',
    parameterName: '송하인명',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '송하인명',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 19,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'receiverInfo',
    parameterName: '고객정보',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '고객정보',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 20,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'location',
    parameterName: '현재위치',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '현재위치',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 21,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'centerCodeKr',
    parameterName: '배달지',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '배달지',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 22,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'userIdKr',
    parameterName: '주기사',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '주기사',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 23,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'volumeInfo',
    parameterName: '체적정보',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '체적정보',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 24,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'weight',
    parameterName: '무게(kg)',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '무게(kg)',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 25,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'payTypeKr',
    parameterName: '운임구분',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '운임구분',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 26,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'price',
    parameterName: '운임비',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '운임비',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 27,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'extraFee',
    parameterName: '도선료',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '도선료',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 28,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'productName',
    parameterName: '상품명',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '상품명',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 29,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'shipmentNote',
    parameterName: '고객요청사항',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '고객요청사항',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 30,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'stage',
    parameterName: '운송장등록<br/>차수',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '운송장등록<br/>차수',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 31,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'printCount',
    parameterName: '출력횟수',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '출력횟수',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 32,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'transferParcel',
    parameterName: '타택배이관',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '타택배이관',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 33,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'externalInvoiceNumber',
    parameterName: '외부<br/>운송장번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '외부<br/>운송장번호',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 34,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'transferPrice',
    parameterName: '이관비',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '이관비',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 35,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'registerId',
    parameterName: '운송장등록자',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '운송장등록자',
    },
    parameterNameCustom: null,
    parentParameter: null,
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 1,
    sort: 36,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'receiverName',
    parameterName: '고객명',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '고객명',
    },
    parameterNameCustom: null,
    parentParameter: 'receiverInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 1,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'receiverZipcode',
    parameterName: '우편번호',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '우편번호',
    },
    parameterNameCustom: null,
    parentParameter: 'receiverInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 2,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'receiverAddr',
    parameterName: '주소',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '주소',
    },
    parameterNameCustom: null,
    parentParameter: 'receiverInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 3,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'receiverPhone',
    parameterName: '전화번호1',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '전화번호1',
    },
    parameterNameCustom: null,
    parentParameter: 'receiverInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 4,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'width',
    parameterName: '가로(cm)',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '가로(cm)',
    },
    parameterNameCustom: null,
    parentParameter: 'volumeInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 1,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'depth',
    parameterName: '세로(cm)',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '세로(cm)',
    },
    parameterNameCustom: null,
    parentParameter: 'volumeInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 2,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'height',
    parameterName: '높이(cm)',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '높이(cm)',
    },
    parameterNameCustom: null,
    parentParameter: 'volumeInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 3,
    useFlag: true,
  },
  {
    gridId: 'PARCEL_SERVICE_LIST',
    parameter: 'volume',
    parameterName: '부피(cm3)',
    parameterNameEn: null,
    parameterNameZh: null,
    parameterNameObj: {
      ko: '부피(cm3)',
    },
    parameterNameCustom: null,
    parentParameter: 'volumeInfo',
    description: null,
    descriptionEn: null,
    descriptionZh: null,
    descriptionObj: {},
    level: 2,
    sort: 4,
    useFlag: true,
  },
];
export const FLAG_OPT = [
  {
    value: 'scheduleFlag',
    label: '예약접수',
  },
  {
    value: 'failFlag',
    label: '등록실패',
  },
  {
    value: 'collectFlag',
    label: '인수',
  },
  {
    value: 'transitFlag',
    label: '이동중',
  },
  {
    value: 'centerFlag',
    label: '배달지',
  },
  {
    value: 'deliveryFlag',
    label: '배송출발',
  },
  {
    value: 'finishFlag',
    label: '배송완료',
  },
  {
    value: 'transferFlag',
    label: '타택배배송중',
  },
  {
    value: 'wrongCenterFlag',
    label: '오입고',
  },
];

export const ACT_FEE_PACEL = [
  4000, 4400, 4400, 4800, 5200, 5200, 5600, 6000, 6400, 6900, 7400, 7900, 8400, 8800, 9600, 9700, 10200, 10700, 11200, 11600, 12000, 12500, 13000, 13500, 13600, 14400, 14800, 15300, 15800, 16300,
  16800, 17200, 17600, 18100, 18400, 19100, 19600, 20000, 20400, 20900, 21400, 21900, 22400, 22800, 23200, 23700, 24200, 24700, 25200, 25600, 26000, 26500, 27000, 27500, 28000, 28400, 28800, 29300,
  29800, 30300, 30800, 31200, 31600, 32100, 32600, 33100, 33600, 34000, 34400, 34900, 35400, 35900, 36400, 36800, 37200, 37700, 38200, 38700, 39200, 39600, 40000, 40500, 41000, 41500, 42000, 42400,
  42800, 43300, 43800, 44300, 44800, 45200, 45600, 46100, 46600, 47100, 47600, 48000, 48400, 48900, 49400, 49900, 50400, 50800, 51200, 51700, 52200, 52700, 53200, 53600, 54000, 54500, 55000, 55500,
  56000, 56400, 56800, 57300, 57800, 58300, 58800, 59200, 59600, 60100, 64000, 64400, 64900, 65400, 65900, 66400, 66800, 67400, 67900, 68400, 68800, 69300, 69800, 70400, 70800, 71300, 71800, 72300,
  72800, 73200, 73800, 74300, 74800, 75200, 75700, 76200, 76800, 77200, 77700, 78200, 78700, 79200, 79600, 80200, 80700, 81200, 81600, 82100, 82600, 83200, 83600, 84100, 84600, 85100, 85600, 86000,
  86600, 87100, 87600, 88000, 88500, 89000, 89600, 90000, 90500, 91000, 91500, 92000, 92400, 93000, 93500, 94000, 94400, 94900, 95400, 96000, 96400, 96900, 97400, 97900, 98400,
];

export const DASONG_FEE_PACEL = [
  6000, 6500, 6500, 7000, 7500, 8100, 8700, 9300, 9900, 10500, 11000, 12000, 12200, 12800, 13400, 14000, 14500, 15100, 15700, 16300, 16900, 17000, 18000, 18600, 19200, 19800, 20400, 21000, 21500,
  22100, 22700, 23000, 23900, 24500, 25000, 25600, 26200, 26800, 27400, 28000, 28500, 29100, 29700, 30300, 30900, 31500, 32000, 32600, 33200, 33800, 34400, 35000, 35500, 36100, 36700, 37300, 37900,
  38500, 39000, 39600, 40200, 40800, 41400, 42000, 42500, 43100, 43700, 44300, 44900, 45500, 46000, 46600, 47200, 47800, 48400, 49000, 49500, 50100, 50700, 51300, 51900, 52500, 53000, 53600, 54200,
  54800, 55400, 56000, 56500, 57100, 57700, 58300, 58900, 59500, 60100, 60600, 61200, 61800, 62400, 63000, 63600, 64100, 64700, 65300, 65900, 66500, 67100, 67600, 68200, 68800, 69400, 70000, 70600,
  71100, 71700, 72300, 72900, 73500, 74100, 74600, 75200, 80000, 80600, 81200, 81800, 82400, 83000, 83600, 84300, 84900, 85500, 86100, 86700, 87300, 88000, 88600, 89200, 89800, 90400, 91000, 91600,
  92300, 92900, 93500, 94100, 94700, 95300, 96000, 96600, 97200, 97800, 98400, 99000, 99600, 100300, 100900, 101500, 102100, 102700, 103300, 104000, 104600, 105200, 105800, 106400, 107000, 107600,
  108300, 108900, 109500, 110100, 110700, 111300, 112000, 112600, 113200, 113800, 114400, 115000, 115600, 116300, 116900, 117500, 118100, 118700, 119300, 120000, 120600, 121200, 121800, 122400,
  123000,
];
