import { ReactNode,  useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import useGridButton from 'hooks/grid/useGridButton';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { serviceStore } from 'services/services';
import { OrderSearchDTO, OrderSSListDTO } from '../../_interface/order';
import { DetailPopup, IDetailObj } from './component/detail/detail';
import { IPopupObj, Popup } from './component/popup';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const labellingKr = (data: PagingListDTO<OrderSSListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        //
        receiverAddr: `${row?.receiverAddr1 || ''} ${row?.receiverAddr2 || ''}`,
        customerAddr: `${row?.customerAddr1 || ''} ${row?.customerAddr2 || ''}`,
        overTimeRegitKr: row.overTimeRegit === 0 ? 'D-DAY' : `D+${row.overTimeRegit}`,
        feeTypeKr: MASTER_OBJ?.SS_FEE_TYPE?.[row.feeType],
        invoiceStatusKr: MASTER_OBJ?.INVOICE_STATUS?.[row.invoiceStatus],
        startCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.startCenterCode],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        promiseTimeZoneKr: MASTER_OBJ?.PROMISE_TIME_ZONE_1?.[row.promiseTimeZone],
        dispatchFlagKr: MASTER_OBJ?.OX_FLAG?.[row.dispatchFlag + ''],
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };
  const fetchPaging = async (searchObj: OrderSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.orderAction(`v2/list/ss/paging`, 'POST', null, searchObj, true))?.data as PagingListDTO<OrderSSListDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    // const { list } = labellingKr(
    //   {
    //     list: (await serviceStore.warehouseAction(`location/select/list`, 'GET', searchObj, null))?.data as LocationSelectListDTO[],
    //   },
    //   true,
    // );

    // excelGridRef.current.setGridData(list);
    // excelGridRef.current.exportAsXlsx({ fileName: '로케이션지정내역' });

    dispatch(setLoading(null));
  };

  const fetchSmsList = async (e: IGrid.ButtonClickEvent) => {
    alert('준비중입니다!');
    // dispatch(setLoading('GET'));
    // const data = await orderService.getOrderSms(e.item.invoiceSeq);
    // if (data) {
    //   setSmsObj({
    //     visible: true,
    //     data,
    //   });
    // }
    // dispatch(setLoading(null));
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      //
      if (id === 'SS_LIST_CHANGEDATE') {
        popupPopup(items);
      } else {
        alert('준비중입니다');
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    handleCheckItems(id);
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const [popupObj, setPopupObj] = useState<IPopupObj>();
  const popupPopup = (items) => {
    setPopupObj({
      visible: true,
      type: 'divide',
      items,
      searchObj: searchObj,
      fetchFn: (data) => wrappedFetchList(data),
    });
  };

  const [detailObj, setDetailObj] = useState<IDetailObj>();
  const popupDetail = (e: IGrid.ButtonClickEvent) => {
    setDetailObj({
      visible: true,
      item: e.item,
      searchObj: searchObj,
      fetchFn: (data) => wrappedFetchList(data),
      fetchDetailFn: (e: IGrid.ButtonClickEvent) => popupDetail(e),
    });
  };

  const fetchInvoiceData = (e: IGrid.ButtonClickEvent) => {
    alert('준비중입니다!');
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupDetail,
      },
    },
    {
      headerText: '배송/반품현황',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '보기',
        onClick: fetchInvoiceData,
      },
    },
    {
      headerText: '고객주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '등록일시',
      dataField: 'registerDatetime',
    },
    {
      headerText: '등록일경과일',
      dataField: 'overTimeRegitKr',
      styleFunction: function (rowIndex, columnIndex, value, headerText, item, dataField) {
        if (item?.overTimeRegit < 3) {
          return '';
        } else {
          return 'warning-column';
        }
      },
    },
    {
      headerText: '배송희망일',
      dataField: 'promiseDate',
    },
    {
      headerText: '배송희망시간대',
      dataField: 'promiseTimeZoneKr',
    },
    {
      headerText: '배송예정일',
      dataField: 'deliveryDate',
    },
    {
      headerText: '완료/취소일시',
      dataField: 'finishOrCancelDatetime',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '송장상태',
      dataField: 'invoiceStatusKr',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '상품구성수량',
      dataField: 'productQuantity',
    },
    {
      headerText: '판매가(VAT포함)',
      dataField: 'sellingPrice',
      formatString: '#,##0',
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        autoThousandSeparator: true,
        onlyNumeric: true,
      },
    },
    {
      headerText: '고객요청사항',
      dataField: 'shipmentNote',
    },
    {
      headerText: '보관창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '출발창고',
      dataField: 'startCenterCodeKr',
    },
    {
      headerText: '배차확정여부',
      dataField: 'dispatchFlagKr',
    },
    {
      headerText: '배송권역',
      dataField: 'deliveryZone',
    },
    {
      headerText: '실수령자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'receiverName',
        },
        {
          headerText: '주소',
          dataField: 'receiverAddrKr',
        },
        {
          headerText: '연락처',
          dataField: 'receiverPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'receiverPhone2',
        },
      ],
    },
    {
      headerText: '주문자 고객정보',
      children: [
        {
          headerText: '고객명',
          dataField: 'customerName',
        },
        {
          headerText: '주소',
          dataField: 'customerAddrKr',
        },
        {
          headerText: '연락처',
          dataField: 'customerPhone',
        },
        {
          headerText: '연락처2',
          dataField: 'customerPhone2',
        },
      ],
    },
    {
      headerText: '기사정보',
      children: [
        {
          headerText: '운송기사명',
          dataField: 'lhUserName',
        },
        {
          headerText: '운송기사 연락처',
          dataField: 'lhUserPhone',
        },
        {
          headerText: '설치기사명',
          dataField: 'installerName',
        },
        {
          headerText: '설치기사 생년월일',
          dataField: 'installerBirthDate',
        },
      ],
    },
    {
      headerText: '메세지발송횟수',
      dataField: 'smsCount',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: fetchSmsList,
      },
    },
    {
      headerText: '총 CBM',
      dataField: 'totalBoxVolume',
    },
    {
      headerText: '주문등록자',
      dataField: 'registerId',
    },
  ];

  return (
    <div className="page-content">
      {popupObj?.visible && <Popup popupObj={popupObj} setPopupObj={setPopupObj} />}
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            fixedColumnCount: 2,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns.filter((_, idx) => idx > 1)} />
      </div>
    </div>
  );
};

export default Index;
