import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import Presenter from './presenter';
import { defaultSearchModelObj } from '../bodyBanchulRegitTab/bodyBanchulRegit/searchModelModalForStoreTakeOutPickingInstructionRegister';
import { INVENTORY_MODEL_FOR_LINEHAULMAKE, TAKE_OUT_PICKING_REGISTER } from 'envVar';
import { checkHTML5Brower, fixdata, parseCvs, process_wb } from 'common/util/fileUploader';

// redux
import { httpClient } from 'common/http-client/axiosConfig';
import useDebounce from '../../../hooks/debounceHooks';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';

import { useNavigate } from 'react-router';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { generateUrlByDesignType } from 'components/LDS/utils/path';

export const defaultSearchObj = {
  stockRemoveDate: null,
  centerCode: null,
  reason: null,
  partnerSeq: null,
  target: null,
};

const Container = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);
  const { reduxUserInfo } = useSelector((state) => state.auth);

  const [gridId, setGridId] = useState();

  const defaultSearchFilter = useMemo(() => {
    return {
      stockRemoveDate: returnDateyyyymmdd(new Date()), // 반출예정일
      centerCode: reduxUserInfo['centerCode'], // 반출창고
      partnerSeq: MASTER_OPS?.SELLER_SELLER_WHOLE?.find((seller) => seller.value === reduxUserInfo['partner']) ? reduxUserInfo['partner'] : null, // 판매사
    };
  }, [reduxUserInfo]);

  const [searchObj, setSearchObj] = useState({ ...defaultSearchObj, ...defaultSearchFilter });
  const [getAllGridDataTriggerThisGrid, setGetAllGridDataTriggerThisGrid] = useState();
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  const [searchModelObj, setSearchModelObj] = useState(defaultSearchModelObj);
  const [rows, setRows] = useState([]);

  // excel

  const variable = useRef({
    isDoubleClick: false,
  });

  useEffect(() => {
    initGridId();
  }, []);

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'remove-thisRows') {
        removeThisRows(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const removeThisRows = (items) => {
    const stockSeqs = [];
    items.forEach((item) => stockSeqs.push(item.modelStockSeq));

    const _rows = [...rows];
    const filtered = _rows.filter((row) => !stockSeqs.includes(row.modelStockSeq));
    setRows(filtered);
  };

  const initGridId = () => {
    setGridId(`takeoutPickingRegister_${uuidv4()}`);
  };

  const columns = [
    {
      headerText: '반출창고',
      dataField: 'centerCodeKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '현재보관수량',
      children: [
        {
          headerText: '양품',
          dataField: 'totalAvailableQuantity',
          editable: false,
        },
        {
          headerText: '불용',
          dataField: 'totalDefectedQuantity',
          editable: false,
        },
        {
          headerText: '이동가능양품',
          dataField: 'availableQuantity',
          editable: false,
        },
        {
          headerText: '이동가능불용',
          dataField: 'defectedQuantity',
          editable: false,
        },
      ],
    },
    {
      headerText: '반출재고수량',
      children: [
        {
          headerText: '양품',
          dataField: 'requestAvailableQuantity',
          dataType: 'numeric',
          style: 'my-custom-aui',
          renderer: {
            type: 'TemplateRenderer',
            aliasFunction: function (rowIndex, columnIndex, value, headerText, item) {
              // 엑셀, PDF 등 내보내기 시 값 가공 함수
              return value;
            },
          },
        },
        {
          headerText: '불용',
          dataField: 'requestDefectedQuantity',
          dataType: 'numeric',
          style: 'my-custom-aui',
          renderer: {
            type: 'TemplateRenderer',
            aliasFunction: function (rowIndex, columnIndex, value, headerText, item) {
              // 엑셀, PDF 등 내보내기 시 값 가공 함수
              return value;
            },
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (searchModelObj?.returns) {
      const _returns = [...searchModelObj?.returns];
      const oldRowsStockSeqs = {};
      rows.forEach((row) => {
        if (oldRowsStockSeqs[row.modelStockSeq]) {
          oldRowsStockSeqs[row.modelStockSeq].push(row.locationCode);
        } else {
          oldRowsStockSeqs[row.modelStockSeq] = [row.locationCode];
        }
      });

      const _rows = rows?.length > 0 ? window.AUIGrid.getGridData(`#${gridId}`) : [];
      let jungbokFlag = false;
      _returns.forEach((row) => {
        const reason = row?.reason;
        if (_rows.length === 0) {
          const _newRow = { ...row };
          _newRow.requestAvailableQuantity = reason !== 'DEFECT_EXPORT' ? row.requestAvailableQuantity : 0;
          _newRow.requestAvailableQuantityDefault = reason !== 'DEFECT_EXPORT' ? row.requestAvailableQuantityDefault : 0;
          _newRow.requestDefectedQuantity = reason !== 'MAKER_EXPORT' ? row.requestDefectedQuantity : 0;
          _newRow.requestDefectedQuantityDefault = reason !== 'MAKER_EXPORT' ? row.requestDefectedQuantityDefault : 0;
          _newRow.reason = reason;
          _rows.push(_newRow);
        } else {
          if (!oldRowsStockSeqs[row.modelStockSeq]?.includes(row.locationCode)) {
            // 없으면 추가
            const _newRow = { ...row };
            _newRow.requestAvailableQuantity = reason !== 'DEFECT_EXPORT' ? row.requestAvailableQuantity : 0;
            _newRow.requestAvailableQuantityDefault = reason !== 'DEFECT_EXPORT' ? row.requestAvailableQuantityDefault : 0;
            _newRow.requestDefectedQuantity = reason !== 'MAKER_EXPORT' ? row.requestDefectedQuantity : 0;
            _newRow.requestDefectedQuantityDefault = reason !== 'MAKER_EXPORT' ? row.requestDefectedQuantityDefault : 0;
            _newRow.reason = reason;
            _rows.push(_newRow);
          } else {
            jungbokFlag = true;
          }
        }
      });
      if (jungbokFlag) {
        alert('중복 되는 값을 제외하고 추가되었습니다');
      }

      setRows(_rows);
    }
  }, [searchModelObj?.returns]);

  const listingmodel = (rows) => {
    const reason = rows[0].reason;
    const result = [];
    rows.forEach((rowdata, idx) => {
      const obj = {};
      obj['modelStockSeq'] = rowdata?.modelStockSeq;
      obj['stockRemoveSeq'] = rowdata?.stockRemoveSeq;

      if (rowdata?.locationCode && rowdata?.locationCode !== '자동생성') obj['locationCode'] = rowdata?.locationCode;
      if (reason === 'MAKER_EXPORT') {
        obj['availableQuantity'] = rowdata?.requestAvailableQuantity;
      } else if (reason === 'DEFECT_EXPORT') {
        obj['defectedQuantity'] = rowdata?.requestDefectedQuantity;
      } else {
        obj['availableQuantity'] = rowdata?.requestAvailableQuantity;
        obj['defectedQuantity'] = rowdata?.requestDefectedQuantity;
      }
      result.push(obj);
    });
    return result;
  };

  const createPicking = useCallback(
    useDebounce(async (items, searchObj) => {
      if (validCreatePicking(items)) {
        let json = {
          stockRemoveDate: searchObj.stockRemoveDate,
          centerCode: searchObj.centerCode,
          target: searchObj.target,
          partnerSeq: searchObj.partnerSeq,
          reason: searchObj.reason,
          memo: searchObj?.memo,
          stockRemoveDetails: listingmodel(items),
        };
        httpClient.post(TAKE_OUT_PICKING_REGISTER, json).then((rs) => {
          if (rs?.status === 200) {
            alert('반출 피킹 생성에 성공했습니다');
            setRows([]);
            dispatch(setLoading(null));
            history(generateUrlByDesignType('/takeoutWorks'));
          }
        });
        dispatch(setLoading(null));
      } else {
        alert('수량이 없는 재고가 있습니다!');
        dispatch(setLoading(null));
      }
    }, 3000),
    [],
  );

  const validCreatePicking = (items) => {
    const reason = items[0].reason;
    let validFlag = true;
    items.forEach((item) => {
      if (reason === 'MAKER_EXPORT' && item.requestAvailableQuantity === 0) {
        validFlag = false;
      } else if (reason === 'DEFECT_EXPORT' && item.requestDefectedQuantity === 0) {
        validFlag = false;
      } else if ((reason === 'LOST' || reason === 'PARCEL') && item.requestAvailableQuantity === 0 && item.requestDefectedQuantity === 0) {
        validFlag = false;
      }
    });
    return validFlag;
  };

  const XLSX = require('xlsx');

  const rABS = typeof FileReader !== 'undefined' && typeof FileReader.prototype !== 'undefined' && typeof FileReader.prototype.readAsBinaryString !== 'undefined';

  const fileSelector = (evt) => {
    try {
      if (!checkHTML5Brower()) {
        alert('브라우저가 HTML5 를 지원하지 않습니다.\r\n서버로 업로드해서 해결하십시오.');
        return;
      }

      var data = null;
      var file = evt.target.files[0];
      if (typeof file === 'undefined') {
        return;
      }

      var reader = new FileReader();

      reader.onload = function (e) {
        var data = e.target.result;
        var workbook;

        if (rABS) {
          workbook = XLSX.read(data, { type: 'binary' });
        } else {
          var arr = fixdata(data);
          workbook = XLSX.read(Buffer.from(arr, 'base64'), { type: 'base64' });
        }
        var jsonObj = process_wb(workbook);
        if (jsonObj[Object.keys(jsonObj)[0]]) {
          const parseExcelDataArr = parseCvs(jsonObj[Object.keys(jsonObj)[0]]);
          const addArr = [];
          const colField = [
            'maker',
            'centerCode',
            'locationCode',
            'partnerSeq',
            'modelStockSeq',
            'model',
            'barcode',
            'reserverdAvailableQuantity',
            'reseredDefectedQuantity',
            'availableQuantity',
            'defectedQuantity',
          ];

          parseExcelDataArr.forEach((textArr = [], idx) => {
            if (idx !== 0) {
              const obj = {};
              for (let index = 0; index < textArr.length; index++) {
                obj[colField[index]] = textArr[index];
              }
              addArr.push(obj);
            }
          });

          settingExcelRow(addArr);
        }
      };

      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {}
  };

  const settingExcelRow = async (excelrows) => {
    let url = INVENTORY_MODEL_FOR_LINEHAULMAKE + '?';
    const postjsonArr = [];

    excelrows.forEach((row) => {
      const postJson = {
        centerCode: searchObj?.centerCode,
        partnerSeq: searchObj?.partnerSeq,
        barcode: row.barcode,
        availableQuantity: row.availableQuantity,
        defectedQuantity: row.defectedQuantity,
      };
      postjsonArr.push(postJson);
    });

    await httpClient
      .post(url, postjsonArr)
      .then((rs) => {
        const _rows = rs.data.list || [];
        const message = rs.data.response.message;

        if (_rows.length === 0) alert('조회되는 재고가 없습니다');
        else {
          _rows.forEach((row, i) => {
            row.centerCodeKr = MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode];
            row.modelGroupKr = MASTER_OBJ?.MODEL_GRP_1?.[row?.modelGroupLarge];
            row.partnerSeqKr = MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq];
            row.requestAvailableQuantity = excelrows.filter((ele) => ele.barcode === row.barcode)[0].availableQuantity;
            row.requestDefectedQuantity = excelrows.filter((ele) => ele.barcode === row.barcode)[0].defectedQuantity;
            row.centerCode = searchObj?.centerCode;
            row.partnerSeq = searchObj?.partnerSeq;
            row.reason = searchObj?.reason;
            row.locationCode = row.locationCode ? row.locationCode : '자동생성';
          });

          setRows(_rows);
          alert(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Presenter
      variable={variable}
      gridId={gridId}
      columns={columns}
      rows={rows}
      setRows={setRows}
      createPicking={createPicking}
      //obj
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      searchModelObj={searchModelObj}
      setSearchModelObj={setSearchModelObj}
      fileSelector={fileSelector}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      getAllGridDataTriggerThisGrid={getAllGridDataTriggerThisGrid}
      setGetAllGridDataTriggerThisGrid={setGetAllGridDataTriggerThisGrid}
    />
  );
};

export default Container;
