import { useRef, useState } from 'react';
import { Row, Col, Form } from 'reactstrap';

import Grid from 'components/grid/auiGrid';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
// redux
import { useSelector } from 'react-redux';
import { BottomSheets } from 'components/template/bottomSheets';

import { DayPicker } from 'react-day-picker';
import { ko } from 'date-fns/locale';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { OrderDetail } from 'pages/ORDER/1order/orderListTab/common/component/invocieDetail/orderDetail';
import PrintGridBox from 'common/grid/printGridBox';

const Presenter = (props) => {
  const { bottomSheetObj, setBottomSheetObj, orderDetailObj, setOrderDetailObj } = props;
  return (
    <>
      <div className="page-content">
        {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
        {orderDetailObj?.visible && <OrderDetail orderDetailObj={orderDetailObj} setOrderDetailObj={setOrderDetailObj} />}

        <div className="presenterSearch" style={{ zIndex: 10 }}>
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const Search = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!searchObj?.deliveryDate) {
        alert('지정일은 필수 검색값입니다!');
      } else {
        e.preventDefault();
        fetchList(searchObj);
      }
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">*지정일</label>
            <FlatpickrD
              value={searchObj?.deliveryDate}
              onReset={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    deliveryDate: null,
                  };
                });
              }}
              options={{
                mode: 'single',
              }}
              onChange={(date) => {
                setSearchObj({
                  ...searchObj,
                  deliveryDate: returnDateyyyymmdd(date[0]),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">인프라</label>
            <SelectD
              value={
                searchObj?.infraSeq
                  ? {
                      value: searchObj?.infraSeq,
                      label: selectlabel(searchObj?.infraSeq, masterOptions?.INFRA),
                    }
                  : null
              }
              options={masterOptions?.INFRA}
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  infraSeq: value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">창고</label>
            <SelectD
              value={
                searchObj?.centerCode
                  ? {
                      value: searchObj?.centerCode,
                      label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER_WHOLE),
                    }
                  : null
              }
              options={masterOptions?.CENTER_AUTH}
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">판매사</label>
            <SelectD
              value={
                searchObj?.partnerSeq
                  ? {
                      value: searchObj?.partnerSeq,
                      label: selectlabel(searchObj?.partnerSeq, masterOptions?.SELLER_WHOLE),
                    }
                  : null
              }
              options={masterOptions?.SELLER_SELLER_AUTH}
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">진행상태</label>
            <SelectD
              value={
                searchObj?.closeStatus
                  ? {
                      value: searchObj?.closeStatus,
                      label: masterOptions?.CLOSE_STATUS_OBJ[searchObj?.closeStatus],
                    }
                  : null
              }
              options={masterOptions?.CLOSE_STATUS}
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  closeStatus: value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">주문번호</label>
            <div style={{ display: 'flex' }}>
              <Col md={6} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    searchObj?.orderNumType
                      ? {
                          value: searchObj?.orderNumType,
                          label: selectlabel(searchObj?.orderNumType, masterOptions?.SEARCH_ORDER),
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_ORDER}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      orderNumType: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={6} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!searchObj?.orderNumType}
                  value={searchObj?.orderNum || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      orderNum: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">송장번호</label>
            <div style={{ display: 'flex' }}>
              <Col md={6} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    searchObj?.invoiceNumType
                      ? {
                          value: searchObj?.invoiceNumType,
                          label: masterOptions?.SEARCH_INVOICE_OBJ[searchObj?.invoiceNumType],
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_INVOICE}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      invoiceNumType: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={6} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!searchObj?.invoiceNumType}
                  value={searchObj?.invoiceNum || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      invoiceNum: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col />
          <Col />
          <Col />
          <Col />
          <Col style={{ textAlign: 'right' }}>
            <button
              className="btn-search btn"
              onClick={(e) => {
                if (!searchObj?.deliveryDate) {
                  alert('지정일은 필수 검색값입니다!');
                } else {
                  e.preventDefault();
                  fetchList(searchObj);
                }
              }}
            >
              조회
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const GridBox = (props) => {
  const { gridId, columns = [], rows = [], excelColumns = [], excelDetailColumns = [], searchObj, setSearchObj, downloadExcelV2 } = props;
  const [dueDateObj, setDueDateObj] = useState({ visible: false, deliveryDate: {} });

  const excelGridRef = useRef();
  const excelDetailGridRef = useRef();
  const excelDetailRangeGridRef = useRef();

  const excelDownClicked = async (type, range) => {
    if (!range && !searchObj?.deliveryDate) {
      alert('지정일은 필수 검색값입니다!');
    } else {
      const prompt = window.confirm('엑셀다운를 시작합니다. \n※ 많은 양의 데이터는 30초 이상의 시간이 소요될 수 있습니다.');
      if (prompt) {
        const data = await downloadExcelV2(type, range);
        if (data?.length > 0) {
          if (type === 'main') {
            excelGridRef.current.setGridData(data);
            excelGridRef.current.exportAsXlsx({ fileName: '일일배송마감(메인)' });
          } else if (type === 'detail') {
            excelDetailGridRef.current.setGridData(data);
            excelDetailGridRef.current.exportAsXlsx({ fileName: '일일배송마감(상세)' });
          } else {
            excelDetailRangeGridRef.current.setGridData(data);
            excelDetailRangeGridRef.current.exportAsXlsx({ fileName: '일일배송마감(기간별상세)' });
          }
        } else {
          alert('다운로드할 데이터가 없습니다!');
        }
      }
    }
  };

  return (
    <>
      <CommonModalNew
        style={{ width: 400 }}
        title={'기간별 엑셀다운(상세)'}
        subTitle={'기간을 선택하세요'}
        visible={dueDateObj?.visible}
        setVisible={() => {
          setDueDateObj({ visible: false, deliveryDate: {} });
        }}
        children={
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <DayPicker
              locale={ko}
              mode="range"
              selected={dueDateObj?.deliveryDate}
              onSelect={(date) => {
                setDueDateObj((prev) => {
                  return {
                    ...prev,
                    deliveryDate: date,
                  };
                });
              }}
            />
            <div style={{ textAlign: 'right' }}>
              <button
                className="btn-etc btn"
                onClick={(e) => {
                  setDueDateObj({ visible: false, deliveryDate: {} });
                }}
              >
                닫기
              </button>
              <button
                className="btn-secondary btn"
                onClick={(e) => {
                  //
                  if (!dueDateObj?.deliveryDate?.from || !dueDateObj?.deliveryDate?.to) {
                    alert('기간을 선택하세요!');
                  } else {
                    excelDownClicked('rangeDetail', dueDateObj?.deliveryDate);
                  }
                }}
              >
                다운로드
              </button>
            </div>
          </div>
        }
      />
      <div className="grid-button-area only-right">
        <div
          className="green"
          onDoubleClick={() => {
            return;
          }}
          onClick={() => {
            excelDownClicked('main');
          }}
        >
          엑셀다운
        </div>
        <div
          className="green"
          onClick={() => {
            excelDownClicked('detail');
          }}
        >
          엑셀다운(상세)
        </div>
        <div
          className="green"
          onDoubleClick={() => {
            return;
          }}
          onClick={() => {
            setDueDateObj({ visible: true, deliveryDate: {} });
          }}
        >
          기간별 엑셀다운(상세)
        </div>
      </div>
      {gridId && (
        <>
          <Grid
            id={gridId}
            rows={rows}
            columns={columns}
            settingOptions={{
              usePaging: true,
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
              displayTreeOpen: true, // 그리드 ROW 스타일 함수 정의
              rowStyleFunction: function (rowIndex, item) {
                if (item.infraSeq === null) return 'whole-row-style';
                if ((item.partnerSeq === null) & (item.closeStatus === 'NOT_FINISH')) return 'warning-row-style';
                if (item._$depth === 3 && item.closeStatus === null) return 'aui-grid-row-depth3-style';
                switch (item._$depth) {
                  case 1:
                    return 'aui-grid-row-depth1-style';
                  case 2:
                    return 'aui-grid-row-depth2-style';
                  default:
                    return 'aui-grid-row-depth-default-style';
                }
              },
            }}
          />
          <PrintGridBox gridRef={excelGridRef} columns={excelColumns} />
          <PrintGridBox gridRef={excelDetailGridRef} columns={excelDetailColumns} />
          <PrintGridBox gridRef={excelDetailRangeGridRef} columns={excelDetailColumns} />
        </>
      )}
    </>
  );
};
