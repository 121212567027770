import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
// service
import { ParcelInvoiceSearchDTO } from '../../../_interface/parcel';
import { serviceStore } from 'services/services';
// component
import CommonModalNew from 'components/modal/commonModalNew';

interface ICreateObj {
  centerCode?: string;
  userId?: string;
}

export interface IDividerObj {
  visible?: boolean;
  searchObj?: ParcelInvoiceSearchDTO;
  items?: string[];
  fetchFn?: (data) => {};
}

export const DivideOrderPopup = ({ divideOrderObj, setDivideOrderObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [createObj, setCreateObj] = useState<ICreateObj>({
    centerCode: null,
    userId: null,
  });

  const [driverInCenterOption, setDriverInCenterOption] = useState<OptionItem[]>();

  useEffect(() => {
    if (createObj?.centerCode) {
      const filtered = MASTER_OPS?.RAWDATA_DRIVER_WHOLE?.filter((ele) => ele.centerCode === createObj?.centerCode);
      const driverIncenterOption = filtered?.map((user) => {
        return {
          value: user.userId,
          label: user.driverName,
        };
      });
      setDriverInCenterOption(driverIncenterOption);
    }
  }, [createObj?.centerCode]);

  const divideOrderAPI = async ({ createObj, items }) => {
    const data = divideOrderObj?.items?.map((ele) => {
      return {
        seq: ele.parcelInvoiceSeq,
      };
    });

    dispatch(setLoading('PUT'));

    const rs = await serviceStore?.parcelAction(`invoice/distribute`, 'POST', createObj, data);
    if (rs?.status === 200) {
      alert('분배 완료 되었습니다!');
      if (divideOrderObj?.fetchFn && divideOrderObj?.searchObj) divideOrderObj?.fetchFn(divideOrderObj?.searchObj);
      setDivideOrderObj(null);
    }
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title="운송장 분배"
      visible={divideOrderObj?.visible}
      setVisible={() => {
        setDivideOrderObj(null);
      }}
      style={{ width: 350 }}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={() => {
              if (!createObj?.centerCode) {
                alert('창고는 필수값입니다!');
              } else {
                divideOrderAPI({ createObj, items: divideOrderObj?.items });
              }
            }}
          >
            분배
          </button>
        </>
      }
      children={
        <>
          <Row style={{ flexDirection: 'column' }}>
            <Col>
              <label className="col-form-label">*창고</label>
              <SelectD
                value={
                  createObj?.centerCode
                    ? {
                        value: createObj?.centerCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[createObj?.centerCode],
                      }
                    : null
                }
                options={MASTER_OPS?.CENTER_AUTH}
                onChange={(option) => {
                  setCreateObj({
                    ...createObj,
                    centerCode: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">주기사</label>
              <SelectD
                isDisabled={!createObj?.centerCode}
                value={
                  createObj?.userId
                    ? {
                        value: createObj?.userId,
                        label: MASTER_OBJ?.DRIVER_AUTH?.[createObj?.userId],
                      }
                    : null
                }
                options={driverInCenterOption}
                onChange={(option) => {
                  setCreateObj({
                    ...createObj,
                    userId: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center', marginTop: 30 }}>
              <div>
                총 <span className="b">{divideOrderObj?.items?.length}</span>건에 대하여 운송장분배를 진행합니다
              </div>
            </Col>
          </Row>
        </>
      }
    />
  );
};
