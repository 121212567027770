import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { InventoryLocationSearchDTO } from 'interface/warehouse';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';

export const SearchBox = (props: ISearchBoxProps<InventoryLocationSearchDTO>) => {
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_MODEL: null,
    SEARCH_SKU: null,
    ORDER_SEQ: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_SKU, searchObj));
  }, [selectTypeObj?.SEARCH_SKU]);

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.ORDER_SEQ, searchObj));
  }, [selectTypeObj?.ORDER_SEQ]);

  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            options={MASTER_OPS?.CENTER_AUTH}
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  centerCode: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품그룹</label>
          <SelectD
            options={MASTER_OPS?.MODEL_GROUP}
            value={
              searchObj?.modelGroup
                ? {
                    value: searchObj?.modelGroup,
                    label: MASTER_OBJ?.MODEL_GROUP?.[searchObj?.modelGroup],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  modelGroup: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            options={MASTER_OPS?.SELLER_AUTH}
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: MASTER_OBJ?.SELLER_AUTH?.[searchObj?.partnerSeq],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  partnerSeq: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품/제품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: MASTER_OBJ?.SEARCH_MODEL?.[selectTypeObj?.SEARCH_MODEL],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">시리얼번호</label>
          <InputD
            value={searchObj?.serialNumber || ''}
            onChange={(e) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  serialNumber: e.target.value,
                };
              });
            }}
          />
        </Col>

        <Col>
          <label className="col-form-label">주문번호/송장번호</label>
          {/* orderSeq */}
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.ORDER_SEQ
                    ? {
                        value: selectTypeObj?.ORDER_SEQ,
                        label: MASTER_OBJ?.ORDER_SEQ?.[selectTypeObj?.ORDER_SEQ],
                      }
                    : null
                }
                options={MASTER_OPS?.ORDER_SEQ}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    ORDER_SEQ: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.ORDER_SEQ}
                value={searchObj?.[selectTypeObj?.ORDER_SEQ] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.ORDER_SEQ]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">로케이션유형</label>
          <SelectD
            options={MASTER_OPS?.LOCATION_TYPE}
            value={
              searchObj?.locationType
                ? {
                    value: searchObj?.locationType,
                    label: MASTER_OBJ?.LOCATION_TYPE?.[searchObj?.locationType],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  locationType: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>

        <Col>
          <label className="col-form-label">로케이션코드</label>
          <InputD
            value={searchObj?.locationCode || ''}
            onChange={(e) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  locationCode: e.target.value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">피킹할당여부</label>
          <SelectD
            value={
              searchObj?.pickFlag
                ? {
                    value: searchObj?.pickFlag,
                    label: MASTER_OBJ?.PICK_FLAG?.[searchObj?.pickFlag],
                  }
                : null
            }
            options={MASTER_OPS?.PICK_FLAG}
            onChange={(option) => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  pickFlag: (option as OptionItem).value,
                };
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">유사검색여부</label>
          {/* similaritySearchFlag */}
          <div className="radio-select-area">
            <span
              className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: true,
                  };
                });
              }}
            >
              유사검색
            </span>
            <span
              className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
              onClick={() => {
                setSearchObj((prev) => {
                  return {
                    ...prev,
                    similaritySearchFlag: false,
                  };
                });
              }}
            >
              일치검색
            </span>
          </div>
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
      </Row>
    </Form>
  );
};
