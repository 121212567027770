import { useEffect, useRef } from 'react';
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import GridBox from 'common/grid/gridBox';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { InventoryDetailInfoSearchDTO } from 'interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';

export interface IInfoDetailObj {
  visible?: boolean;
  e?: IGrid.ButtonClickEvent;
}

export const InfoDetail = ({ infoDetailObj, setInfoDetailObj }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();
  const { masterOptions } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    fetchDetailInfo(infoDetailObj?.e);
  }, []);

  const dataFieldToFlag = {
    available: 'availableFlag',
    availableStorageLineAndRemove: 'availableStorageFlag',
    reservedProcess: 'processFlag',
    reservedDriver: 'driverFlag',
    reservedStorageLine: 'storageLineFlag',
    reserved: 'reservedFlag',
    move: 'moveFlag',
    repair: 'repairFlag',
    availableTransitLineIn: 'availableTransitLineInFlag',
    reservedTransitLineIn: 'reservedTransitLineInFlag',
    availableTransitLineOut: 'availableTransitLineOutFlag',
    reservedTransitLineOut: 'reservedTransitLineOutFlag',
    driverLoan: 'loanFlag',
    driverCollect: 'collectFlag',
    unlocated: 'unlocatedFlag',
  };

  const fetchDetailInfo = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const dataField = e?.dataField;
    const item = e?.item;
    const searchDTO: InventoryDetailInfoSearchDTO = {
      ...JSON.parse(item?.searchObj),
      centerCode: item.centerCode,
      modelStockSeq: item.modelStockSeq,
      // TEMP TO 2024-07-11
      [dataFieldToFlag[dataField]]: true,
      // availableFlag: dataField === 'available',
      // availableStorageFlag: dataField === 'availableStorageLineAndRemove',
      // processFlag: dataField === 'reservedProcess',
      // driverFlag: dataField === 'reservedDriver',
      // storageLineFlag: dataField === 'reservedStorageLine',
      // reservedFlag: dataField === 'reserved',
      // moveFlag: dataField === 'move',
      // repairFlag: dataField === 'repair',
      // availableTransitLineInFlag: dataField === 'availableTransitLineIn',
      // reservedTransitLineInFlag: dataField === 'reservedTransitLineIn',
      // availableTransitLineOutFlag: dataField === 'availableTransitLineOut',
      // reservedTransitLineOutFlag: dataField === 'reservedTransitLineOut',
      // loanFlag: dataField === 'driverLoan',
      // collectFlag: dataField === 'driverCollect',
      // unlocatedFlag: dataField === 'unlocated',
    };
    const data = (await serviceStore.warehouseAction(`inventory/detailInfo`, 'GET', searchDTO, null))?.data;
    if (data) {
      const rows = data?.map((row) => {
        return {
          ...row,
          centerCodeKr: row.centerCode ? selectlabel(row.centerCode, masterOptions?.CENTER_WHOLE) : '전체',
          originalCenterCodeKr: selectlabel(row.originalCenterCode, masterOptions?.CENTER_WHOLE),
          destinationCenterCodeKr: selectlabel(row.destinationCenterCode, masterOptions?.CENTER_WHOLE),
          firstInventoryCenterCodeKr: selectlabel(row.firstInventoryCenterCode, masterOptions?.CENTER_WHOLE),
          driverCenterCodeKr: selectlabel(row.driverCenterCode, masterOptions?.CENTER_WHOLE),
          cancelReasonKr: selectlabel(row.cancelReason, masterOptions?.CANCEL_REASON),
          feeTypeKr: selectlabel(row.feeType, masterOptions?.FEE_TYPE),
          modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
        };
      });
      gridRef.current.setGridData(rows);
    }
    dispatch(setLoading(null));
  };

  const excelDownload = () => {
    gridRef.current.exportAsXlsx({ fileName: '재고정보' });
  };

  const columns = [
    {
      headerText: '재고번호',
      dataField: 'inventorySeq',
    },
    {
      headerText: '로케이션코드',
      dataField: 'locationCode',
    },
    {
      headerText: '최초재고창고',
      dataField: 'firstInventoryCenterCodeKr',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '송장유형',
      dataField: 'feeTypeKr',
    },
    {
      headerText: '수령인',
      dataField: 'receiverName',
    },
    {
      headerText: '수령인 연락처',
      dataField: 'receiverPhone',
    },
    {
      headerText: '기사이름',
      dataField: 'driverName',
    },
    {
      headerText: '기사소속창고',
      dataField: 'driverCenterCodeKr',
    },
    {
      headerText: '지정일',
      dataField: 'deliveryDate',
    },
    {
      headerText: '취소사유',
      dataField: 'cancelReasonKr',
    },
    {
      headerText: '간선번호',
      dataField: 'linehaulSeq',
    },
    {
      headerText: '반출번호',
      dataField: 'stockRemoveSeq',
    },
    {
      headerText: '출고번호',
      dataField: 'loadingStatementSeq',
    },
    {
      headerText: '운송번호',
      dataField: 'transportStatementSeq',
    },
    {
      headerText: '출발창고',
      dataField: 'originalCenterCodeKr',
    },
    {
      headerText: '도착창고',
      dataField: 'destinationCenterCodeKr',
    },
    {
      headerText: '입고예정일',
      dataField: 'promiseUnloadDate',
    },
  ];

  return (
    <CommonModalNew
      //
      title={'재고정보'}
      style={{ width: 1000 }}
      visible={infoDetailObj?.visible}
      setVisible={() => setInfoDetailObj(null)}
      children={
        <>
          <div className="grid-button-area">
            <div className="green" id="add-child" onClick={excelDownload}>
              엑셀다운
            </div>
          </div>
          <GridBox
            gridRef={gridRef}
            columns={columns}
            gridProps={{
              showRowAllCheckBox: false,
              showRowCheckColumn: false,
            }}
          />
        </>
      }
    />
  );
};
