import { handleFiles, uploadFile } from 'common/util/photo';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CloseImg from 'assets/images/close/cross.png';
import { Button, Label, Col } from 'reactstrap';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { modelNproductService } from 'pages/INVENTORY/4modelNproduct/_services/service';
import { numberFloatTestRegExp, numberTestRegExp } from 'common/util/regExp';
import { v4 as uuidv4 } from 'uuid';
import { httpClient } from 'common/http-client/axiosConfig';
import { WMS_MODEL_SAVE } from 'envVar';
import { RAWDATA, setLoading } from 'redux/services/menuSlice';
import BunRyuModal, { IBunryuModalObj } from 'components/modal/bunryuModal';
import { FileDTOForModelStock, ModelSetDTO, ModelStockAndModelDTO, ModelStockAndModelSearchDTO } from 'interface/warehouse';
import Barcode from 'react-barcode';
import { defaultSeachObj } from 'hooks/grid/useSearch';
import { ImageModal } from 'pages/ORDER/1order/orderListTab/common/component/photos/imageModal';

export interface IModelDetailObj {
  visible: boolean;
  item?: ModelStockAndModelDTO;
  searchObj?: string;
  fetchFn?: (data: ModelStockAndModelSearchDTO) => {};
}

interface IDetailPoup {
  detailObj: IModelDetailObj;
  setDetailObj: React.Dispatch<React.SetStateAction<IModelDetailObj>>;
}

export const DetailPopup = ({ detailObj, setDetailObj }: IDetailPoup) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [bunryuModalObj, setBunryuModalObj] = useState<IBunryuModalObj>();
  const [modelObj, setModelObj] = useState<ModelStockAndModelDTO>();
  const [files, setFiles] = useState<{
    MODEL_PHOTO?: FileDTOForModelStock[];
    MODELBOX_PHOTO?: FileDTOForModelStock[];
    MODEL_INSTALL?: FileDTOForModelStock[];
    MODEL_PALLET?: FileDTOForModelStock[];
  }>();

  const [currentTab, setCurrentTab] = useState(0);
  const hasTabs = [{ title: '기본정보' }, { title: '시리얼정보' }, { title: '사진첨부' }];

  useEffect(() => {
    if (detailObj?.item !== null) {
      fetchDetail(detailObj?.item?.modelStockSeq);
    } else {
      // default 값
      setModelObj((prev) => {
        return {
          ...prev,
          serialNumberFlag: false,
          useFlag: true,
          stopFlag: false,
        };
      });
      setFiles({
        MODEL_PHOTO: [],
        MODELBOX_PHOTO: [],
        MODEL_INSTALL: [],
        MODEL_PALLET: [],
      });
    }
  }, []);

  // 시리얼번호 여부가 관리면 기타재고 수집시점이 다 체크되지만 disabled

  useEffect(() => {
    if (modelObj?.serialNumberFlag === false) {
      setFlagDisabled(true);
      allCheck(false);
    } else if (modelObj?.serialNumberFlag === true) {
      // allEtc(true);
      setFlagDisabled(false);
    }
  }, [modelObj?.serialNumberFlag]);

  const handleCheckbox = (e) => {
    if (e.target.id === 'purchaseFlag') {
      allCheck(e.target.checked);
    } else if (e.target.id === 'defectedCollectFlag') {
      setModelObj((prev) => {
        return {
          ...prev,
          defectedCollectFlag: e.target.checked,
          defectedLineFlag: e.target.checked,
          defectedRemoveFlag: e.target.checked,
          defectedLocationFlag: false,
        };
      });
    } else if (e.target.id === 'availableInvoiceInstallFlag') {
      setModelObj((prev) => {
        return {
          ...prev,
          availableInvoiceInstallFlag: e.target.checked,
          defectedInvoiceCollectFlag: e.target.checked,
        };
      });
    } else if (e.target.id === 'availableLocationFlag') {
      setModelObj((prev) => {
        return {
          ...prev,
          availableLocationFlag: e.target.checked,
          mtoFlag: false,
        };
      });
    } else {
      setModelObj((prev) => {
        return {
          ...prev,
          [e.target.id]: e.target.checked,
        };
      });
    }
  };
  const [flagDisabled, setFlagDisabled] = useState(true);

  const allCheck = (flag: boolean) => {
    setModelObj((prev) => {
      return {
        ...prev,
        purchaseFlag: flag,
        availableLineFlag: flag,
        availableLoanFlag: flag,
        availableInvoiceInstallFlag: flag,
        availableRemoveFlag: flag,
        availableCollectFlag: flag,
        availableInvoiceCollectFlag: flag,
        defectedLineFlag: flag,
        defectedCollectFlag: flag,
        defectedInvoiceCollectFlag: flag,
        defectedRemoveFlag: flag,
        availableLocationFlag: false,
        defectedLocationFlag: false,
        mtoFlag: false,
      };
    });
  };

  const fetchDetail = async (modelStockSeq: number) => {
    dispatch(setLoading('GET'));
    const rsData = await modelNproductService.getModelDetail(modelStockSeq);
    if (rsData) {
      const data = {
        ...rsData,
        mtoFlag: !rsData.purchaseFlag ? false : rsData?.mtoFlag,
      };
      setModelObj(data);
      setFiles({
        MODEL_PHOTO: data?.files?.filter((ele) => ele.column?.includes('MODEL_PHOTO')),
        MODELBOX_PHOTO: data?.files?.filter((ele) => ele.column?.includes('MODELBOX_PHOTO')),
        MODEL_INSTALL: data?.files?.filter((ele) => ele.column?.includes('MODEL_INSTALL')),
        MODEL_PALLET: data?.files?.filter((ele) => ele.column?.includes('MODEL_PALLET')),
      });
    }
    dispatch(setLoading(null));
  };

  const handleFileRemove = (e) => {
    dispatch(setLoading('POST'));
    const id = e.target.id;
    const idx = id.split('_')[2];
    if (id?.includes('MODEL_PHOTO')) {
      const MODEL_PHOTO = files?.MODEL_PHOTO?.filter((_, i) => i !== Number(idx));
      setFiles((prev) => {
        return {
          ...prev,
          MODEL_PHOTO,
        };
      });
    } else if (id?.includes('MODELBOX_PHOTO')) {
      const MODELBOX_PHOTO = files?.MODELBOX_PHOTO?.filter((_, i) => i !== Number(idx));
      setFiles((prev) => {
        return {
          ...prev,
          MODELBOX_PHOTO,
        };
      });
    } else if (id?.includes('MODEL_INSTALL')) {
      const MODEL_INSTALL = files?.MODEL_INSTALL?.filter((_, i) => i !== Number(idx));
      setFiles((prev) => {
        return {
          ...prev,
          MODEL_INSTALL,
        };
      });
    } else if (id?.includes('MODEL_PALLET')) {
      const MODEL_PALLET = files?.MODEL_PALLET?.filter((_, i) => i !== Number(idx));
      setFiles((prev) => {
        return {
          ...prev,
          MODEL_PALLET,
        };
      });
    }
    dispatch(setLoading(null));
  };

  const handleFileUpload = async (e) => {
    dispatch(setLoading('POST'));
    const id = e.target.id;
    const returns = await handleFiles(e);
    const res = await Promise.all(
      returns?.map(async (ret) => {
        return await uploadFile(ret.file, ret.url);
      }),
    );
    if (id === 'ADD_MODEL_PHOTO') {
      const MODEL_PHOTO = JSON.parse(JSON.stringify(files?.MODEL_PHOTO));
      res.forEach((uploadUrl, idx) => {
        MODEL_PHOTO.push({
          column: `MODEL_PHOTO${uuidv4().split('-')[4]}`,
          uploadUrl,
        });
      });
      setFiles((prev) => {
        return {
          ...prev,
          MODEL_PHOTO,
        };
      });
    } else if (id === 'ADD_MODELBOX_PHOTO') {
      const MODELBOX_PHOTO = JSON.parse(JSON.stringify(files?.MODELBOX_PHOTO));
      res.forEach((uploadUrl, idx) => {
        MODELBOX_PHOTO.push({
          column: `MODELBOX_PHOTO${uuidv4().split('-')[4]}`,
          uploadUrl,
        });
      });
      setFiles((prev) => {
        return {
          ...prev,
          MODELBOX_PHOTO,
        };
      });
    } else if (id === 'ADD_MODEL_INSTALL') {
      const MODEL_INSTALL = JSON.parse(JSON.stringify(files?.MODEL_INSTALL));
      res.forEach((uploadUrl, idx) => {
        MODEL_INSTALL.push({
          column: `MODEL_INSTALL${uuidv4().split('-')[4]}`,
          uploadUrl,
        });
      });
      setFiles((prev) => {
        return {
          ...prev,
          MODEL_INSTALL,
        };
      });
    } else if (id === 'ADD_MODEL_PALLET') {
      const MODEL_PALLET = JSON.parse(JSON.stringify(files?.MODEL_PALLET));
      res.forEach((uploadUrl, idx) => {
        MODEL_PALLET.push({
          column: `MODEL_PALLET${uuidv4().split('-')[4]}`,
          uploadUrl,
        });
      });
      setFiles((prev) => {
        return {
          ...prev,
          MODEL_PALLET,
        };
      });
    }
    dispatch(setLoading(null));
  };

  const [filteredOptions, setFilteredOptions] = useState({
    GRP_2: [],
    GRP_3: [],
  });

  useEffect(() => {
    if (modelObj?.modelGroupLarge) {
      const GRP_2 =
        MASTER_OPS?.[`${RAWDATA}MODEL_GRP_1`]
          ?.filter((ele) => ele.code === modelObj?.modelGroupLarge)[0]
          ?.['subCodes']?.filter((ele) => ele.codeGroup === 'MODEL_GRP_2')
          ?.map((ele) => {
            return {
              value: ele.code,
              label: ele.codeName,
            };
          }) || [];
      setFilteredOptions({
        GRP_2,
        GRP_3: [],
      });
    }
  }, [modelObj?.modelGroupLarge]);

  useEffect(() => {
    if (modelObj?.modelGroupMedium) {
      const GRP_3 =
        MASTER_OPS?.[`${RAWDATA}MODEL_GRP_2`]
          ?.filter((ele) => ele.code === modelObj?.modelGroupMedium)[0]
          ?.['subCodes']?.filter((ele) => ele.codeGroup === 'MODEL_GRP_3')
          ?.map((ele) => {
            return {
              value: ele.code,
              label: ele.codeName,
            };
          }) || [];
      setFilteredOptions((prev) => {
        return {
          ...prev,
          GRP_3,
        };
      });
    }
  }, [modelObj?.modelGroupMedium]);

  // TODO 삭제는 나중..
  const saveModelAPI = (modelObj: ModelStockAndModelDTO, crud: 'add' | 'update'): ModelSetDTO => {
    const data: ModelStockAndModelDTO = {
      ...modelObj,
      sellPrice: modelObj?.sellPrice === 0 ? null : modelObj?.sellPrice,
      purchasePrice: modelObj?.purchasePrice === 0 ? null : modelObj?.purchasePrice,
      files: [...files?.MODEL_PHOTO, ...files?.MODELBOX_PHOTO, ...files?.MODEL_INSTALL, ...files?.MODEL_PALLET],
    };
    return {
      [crud]: [data],
    };
  };

  const validationForSave = (data: ModelStockAndModelDTO) => {
    if (data?.serialNumberFlag) {
      if (Number(data?.serialNumberLength) <= data?.externalBarcode?.length) {
        alert('시리얼번호 자리수는 LOT번호 길이보다 최소 1이상 커야합니다!');
        return false;
      }
    }

    if (data?.externalBarcodeFlag) {
      if (!data?.externalBarcode || !data?.serialNumberLength) {
        alert('LOT번호 여부가 활성화되어있으면 LOT번호 및 시리얼번호 자리수는 필수값입니다! ');
        return false;
      }
    }
    return true;
  };

  const saveModel = async (data: ModelStockAndModelDTO) => {
    if (validationForSave(data)) {
      dispatch(setLoading('POST'));
      const obj = saveModelAPI(data, detailObj?.item ? 'update' : 'add');
      await httpClient.post(WMS_MODEL_SAVE, obj).then((rs) => {
        if (rs?.status === 200) {
          alert('제품을 저장하였습니다!');
          let searchObj = defaultSeachObj;
          if (detailObj?.searchObj) searchObj = JSON.parse(detailObj?.searchObj);
          detailObj.fetchFn(searchObj);
          setDetailObj(null);
        }
      });
      dispatch(setLoading(null));
    }
  };

  return (
    <CommonModalNew
      style={{ width: 1250 }}
      title={detailObj?.item ? `제품 상세` : '제품 등록'}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      rightTitle={
        <>
          <Button
            className="btn-secondary btn ssm"
            onClick={(e) => {
              saveModel(modelObj);
            }}
          >
            {detailObj?.item ? '수정' : '등록'}
          </Button>
        </>
      }
      children={
        <div style={{ minHeight: 685 }}>
          {bunryuModalObj?.visible && (
            <BunRyuModal
              visible={bunryuModalObj?.visible}
              setVisible={() => {
                setBunryuModalObj(null);
              }}
            />
          )}
          <div className="div-top-tab">
            {hasTabs.map((tab, index) => {
              return (
                <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                  <li
                    onClick={() => {
                      setCurrentTab(index);
                    }}
                  >
                    {tab?.title}
                  </li>
                </div>
              );
            })}
          </div>

          <div style={{ flexDirection: 'column', display: currentTab === 0 ? 'flex' : 'none', pointerEvents: detailObj?.fetchFn ? 'unset' : 'none' }}>
            <table className="border-table detail-table" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th colSpan={12}>기본정보</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>*활성여부</th>
                  <td colSpan={2}>
                    <SelectD
                      hasNull={false}
                      options={MASTER_OPS?.ACTIVE_FLAG}
                      value={
                        modelObj?.useFlag !== null
                          ? {
                              value: modelObj?.useFlag,
                              label: selectlabel(modelObj?.useFlag, MASTER_OPS?.ACTIVE_FLAG),
                            }
                          : null
                      }
                      onChange={(option) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            useFlag: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*단종여부</th>
                  <td colSpan={2}>
                    <SelectD
                      hasNull={false}
                      options={MASTER_OPS?.STOP_FLAG}
                      value={
                        modelObj?.stopFlag !== null
                          ? {
                              value: modelObj?.stopFlag,
                              label: selectlabel(modelObj?.stopFlag, MASTER_OPS?.STOP_FLAG),
                            }
                          : null
                      }
                      onChange={(option) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            stopFlag: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*바코드(영문,숫자)</th>
                  <td colSpan={2}>
                    <InputD
                      value={modelObj?.barcode || ''}
                      onChange={(e) => {
                        // 50자 이하 가능
                        if (/^[0-9a-zA-Z-_]{0,50}$/.test(e.target.value)) {
                          setModelObj((prev) => {
                            return {
                              ...prev,
                              barcode: e.target.value,
                            };
                          });
                        } else {
                          //
                          alert('바코드 길이는 50자 이하 영문, 숫자, -, _만 가능합니다');
                        }
                      }}
                    />
                  </td>
                  <td colSpan={3} rowSpan={3}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{modelObj?.barcode?.length > 0 ? <Barcode value={modelObj?.barcode} /> : ''}</div>
                  </td>
                </tr>
                <tr>
                  <th>*공급사</th>
                  <td colSpan={2}>
                    <div style={{ display: 'flex' }}>
                      <Col md={8} style={{ display: 'inline-block' }}>
                        <SelectD
                          isDisabled={detailObj?.item !== null}
                          options={MASTER_OPS?.SELLER_SUPPLIER_AUTH}
                          value={
                            modelObj?.supplierPartnerSeq
                              ? {
                                  value: modelObj?.supplierPartnerSeq,
                                  label: selectlabel(modelObj?.supplierPartnerSeq, MASTER_OPS?.SELLER_SUPPLIER_AUTH),
                                }
                              : null
                          }
                          onChange={(option) => {
                            setModelObj((prev) => {
                              return {
                                ...prev,
                                supplierPartnerSeq: (option as OptionItem).value,
                              };
                            });
                          }}
                        />
                      </Col>
                      <Col md={4} style={{ display: 'inline-block' }}>
                        <InputD readOnly disabled value={modelObj?.supplierPartnerSeq || ''} />
                      </Col>
                    </div>
                  </td>
                  <th>*판매사</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={detailObj?.item !== null}
                      options={MASTER_OPS?.SELLER_SELLER_AUTH}
                      value={
                        modelObj?.partnerSeq
                          ? {
                              value: modelObj?.partnerSeq,
                              label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[modelObj?.partnerSeq],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            partnerSeq: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*브랜드</th>
                  <td colSpan={2}>
                    <SelectD
                      options={MASTER_OPS?.BRAND}
                      value={
                        modelObj?.brand
                          ? {
                              value: modelObj?.brand,
                              label: MASTER_OBJ?.BRAND?.[modelObj?.brand],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            brand: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>*제품그룹</th>
                  <td colSpan={2}>
                    <SelectD
                      options={MASTER_OPS?.MODEL_GROUP}
                      value={
                        modelObj?.modelGroup
                          ? {
                              value: modelObj?.modelGroup,
                              label: MASTER_OBJ?.MODEL_GROUP?.[modelObj?.modelGroup],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            modelGroup: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*제품타입</th>
                  <td colSpan={2}>
                    <SelectD
                      options={MASTER_OPS?.MODEL_TYPE}
                      value={
                        modelObj?.modelType
                          ? {
                              value: modelObj?.modelType,
                              label: MASTER_OBJ?.MODEL_TYPE?.[modelObj?.modelType],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            modelType: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>취급주의 여부</th>
                  <td colSpan={2} style={{ textAlign: 'center' }}>
                    <InputD
                      type="checkbox"
                      checked={modelObj?.handleWithCareFlag || false}
                      onChange={(e) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            handleWithCareFlag: e.target.checked,
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>*대분류</th>
                  <td colSpan={2}>
                    <SelectD
                      options={MASTER_OPS?.MODEL_GRP_1}
                      value={
                        modelObj?.modelGroupLarge
                          ? {
                              value: modelObj?.modelGroupLarge,
                              label: MASTER_OBJ?.MODEL_GRP_1?.[modelObj?.modelGroupLarge],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            modelGroupLarge: (option as OptionItem).value,
                            modelGroupMedium: null,
                            modelGroupSmall: null,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*중분류</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={!modelObj?.modelGroupLarge}
                      options={filteredOptions?.GRP_2}
                      value={
                        modelObj?.modelGroupMedium
                          ? {
                              value: modelObj?.modelGroupMedium,
                              label: selectlabel(modelObj?.modelGroupMedium, filteredOptions?.['GRP_2']),
                            }
                          : null
                      }
                      onChange={(option) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            modelGroupMedium: (option as OptionItem).value,
                            modelGroupSmall: null,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>소분류</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={!modelObj?.modelGroupMedium}
                      options={filteredOptions?.GRP_3}
                      value={
                        modelObj?.modelGroupSmall
                          ? {
                              value: modelObj?.modelGroupSmall,
                              label: MASTER_OBJ?.MODEL_GRP_3?.[modelObj?.modelGroupSmall],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            modelGroupSmall: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>대표상품코드</th>
                  <td colSpan={2}>
                    <InputD
                      value={modelObj?.productCode || ''}
                      onChange={(e) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            productCode: e.target.value,
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>*제품</th>
                  <td colSpan={5}>
                    <InputD
                      value={modelObj?.model || ''}
                      onChange={(e) => {
                        // 100자 이하 가능
                        if (/^.{0,100}$/.test(e.target.value)) {
                          setModelObj((prev) => {
                            return {
                              ...prev,
                              model: e.target.value,
                            };
                          });
                        } else {
                          alert('모델 길이는 100자 이하로만 가능합니다');
                        }
                      }}
                    />
                  </td>
                  <th>*제품명</th>
                  <td colSpan={5}>
                    <InputD
                      value={modelObj?.modelName || ''}
                      onChange={(e) => {
                        // 100자 이하 가능
                        if (/^.{0,100}$/.test(e.target.value)) {
                          setModelObj((prev) => {
                            return {
                              ...prev,
                              modelName: e.target.value,
                            };
                          });
                        } else {
                          alert('모델명 길이는 100자 이하로만 가능합니다');
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>*배송팀 유형</th>
                  <td colSpan={2}>
                    <SelectD
                      options={MASTER_OPS?.TEAM_TYPE}
                      value={
                        modelObj?.memberCount
                          ? {
                              value: modelObj?.memberCount,
                              label: MASTER_OBJ?.TEAM_TYPE?.[modelObj?.memberCount],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            memberCount: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*설치소요시간</th>
                  <td colSpan={2}>
                    <SelectD
                      options={MASTER_OPS?.INSTALL_DURATION}
                      value={
                        selectlabel(modelObj?.installDuration, MASTER_OPS?.INSTALL_DURATION)
                          ? {
                              value: modelObj?.installDuration,
                              label: selectlabel(modelObj?.installDuration, MASTER_OPS?.INSTALL_DURATION),
                            }
                          : null
                      }
                      onChange={(option) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            installDuration: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>SKU</th>
                  <td colSpan={2}>
                    {detailObj?.item ? <InputD readOnly disabled={detailObj?.item !== null} value={modelObj?.modelStockSeq || ''} /> : <InputD disabled readOnly value={'자동입력'} />}
                  </td>
                  <th>외부SKU</th>
                  <td colSpan={2}>
                    <InputD
                      value={modelObj?.skuNumber || ''}
                      onChange={(e) => {
                        // 50자 이하 가능
                        if (e.target.value?.trim()) {
                          // 모든글자 50자 이하
                          if (/^.{0,50}$/.test(e.target.value)) {
                            if (/^[0-9a-zA-Z\-]{0,50}$/.test(e.target.value)) {
                              setModelObj((prev) => {
                                return {
                                  ...prev,
                                  skuNumber: e.target.value,
                                };
                              });
                            } else {
                              alert('외부SKU는 영문, 숫자, 하이픈(-)만 입력 가능합니다.');
                            }
                          } else {
                            alert('외부SKU 길이는 50자 이하 가능합니다');
                          }
                        } else {
                          setModelObj((prev) => {
                            return {
                              ...prev,
                              skuNumber: '',
                            };
                          });
                        }
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="border-table detail-table" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th colSpan={12}>제품정보</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>*제품사이즈(mm)</th>
                  <td colSpan={4}>
                    <div>
                      <div className="grid-wrapper div4">
                        <div className="grid-ele">가로</div>
                        <div className="grid-ele">세로</div>
                        <div className="grid-ele">높이</div>
                        <div></div>
                      </div>
                      <div className="grid-wrapper div4">
                        <InputD
                          value={modelObj?.modelWidth || ''}
                          onChange={(e) => {
                            if (numberTestRegExp(e.target.value)) {
                              setModelObj((prev) => {
                                return {
                                  ...prev,
                                  modelWidth: Number(e.target.value),
                                };
                              });
                            } else {
                              alert('숫자만 입력가능합니다!');
                            }
                          }}
                        />
                        <InputD
                          value={modelObj?.modelDepth || ''}
                          onChange={(e) => {
                            if (numberTestRegExp(e.target.value)) {
                              setModelObj((prev) => {
                                return {
                                  ...prev,
                                  modelDepth: Number(e.target.value),
                                };
                              });
                            } else {
                              alert('숫자만 입력가능합니다!');
                            }
                          }}
                        />
                        <InputD
                          value={modelObj?.modelHeight || ''}
                          onChange={(e) => {
                            if (numberTestRegExp(e.target.value)) {
                              setModelObj((prev) => {
                                return {
                                  ...prev,
                                  modelHeight: Number(e.target.value),
                                };
                              });
                            } else {
                              alert('숫자만 입력가능합니다!');
                            }
                          }}
                        />
                      </div>
                    </div>
                  </td>
                  <th>용량/인치</th>
                  <td colSpan={2}>
                    <SelectD
                      options={MASTER_OPS?.MODEL_GRP_DIV}
                      value={
                        modelObj?.modelDivision
                          ? {
                              value: modelObj?.modelDivision,
                              label: MASTER_OBJ?.MODEL_GRP_DIV?.[modelObj?.modelDivision],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            modelDivision: (option as OptionItem).value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>*무게(kg)</th>
                  <td colSpan={1}>
                    <InputD
                      type="number"
                      value={modelObj?.modelWeight || ''}
                      onChange={(e) => {
                        if (numberFloatTestRegExp(e.target.value)) {
                          setModelObj((prev) => {
                            return {
                              ...prev,
                              modelWeight: Number(e.target.value),
                            };
                          });
                        } else {
                          alert('숫자만 입력가능합니다!');
                        }
                      }}
                    />
                  </td>
                  <th>
                    유통기한
                    <br />
                    (최대00일)
                  </th>
                  <td>
                    <InputD
                      value={modelObj?.shelfLife || ''}
                      onChange={(e) => {
                        if (numberTestRegExp(e.target.value)) {
                          setModelObj((prev) => {
                            return {
                              ...prev,
                              shelfLife: Number(e.target.value),
                            };
                          });
                        } else {
                          alert('숫자만 입력가능합니다!');
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>파렛트유형</th>
                  <td colSpan={4}>
                    <div>
                      <div className="grid-wrapper div4">
                        <div className="grid-ele">규격</div>
                        <div className="grid-ele">가로</div>
                        <div className="grid-ele">세로</div>
                        <div className="grid-ele">높이</div>
                      </div>
                      <div className="grid-wrapper div4">
                        <SelectD
                          options={MASTER_OPS?.PALLET_TYPE}
                          value={
                            modelObj?.palletType
                              ? {
                                  value: modelObj?.palletType,
                                  label: MASTER_OBJ?.PALLET_TYPE?.[modelObj?.palletType],
                                }
                              : null
                          }
                          onChange={(option) => {
                            setModelObj((prev) => {
                              return {
                                ...prev,
                                palletType: (option as OptionItem).value,
                              };
                            });
                          }}
                        />
                        <InputD readOnly disabled value={modelObj?.palletType ? MASTER_OBJ?.PALLET_TYPE?.[modelObj?.palletType]?.split('*')[0] : ''} />
                        <InputD readOnly disabled value={modelObj?.palletType ? MASTER_OBJ?.PALLET_TYPE?.[modelObj?.palletType]?.split('*')[1] : ''} />
                        <InputD readOnly disabled value={modelObj?.palletType ? 1700 : ''} />
                      </div>
                    </div>
                  </td>
                  <th>평균단가</th>
                  <td colSpan={2}>
                    <div>
                      <div className="grid-wrapper div2">
                        <div className="grid-ele">판매단가</div>
                        <div className="grid-ele">구매단가</div>
                      </div>
                      <div className="grid-wrapper div2">
                        <InputD
                          value={modelObj?.sellPrice || ''}
                          onChange={(e) => {
                            if (numberTestRegExp(e.target.value)) {
                              setModelObj((prev) => {
                                return {
                                  ...prev,
                                  sellPrice: Number(e.target.value),
                                };
                              });
                            } else {
                              alert('숫자만 입력가능합니다!');
                            }
                          }}
                        />
                        <InputD
                          value={modelObj?.purchasePrice || ''}
                          onChange={(e) => {
                            if (numberTestRegExp(e.target.value)) {
                              setModelObj((prev) => {
                                return {
                                  ...prev,
                                  purchasePrice: Number(e.target.value),
                                };
                              });
                            } else {
                              alert('숫자만 입력가능합니다!');
                            }
                          }}
                        />
                      </div>
                    </div>
                  </td>
                  <th>
                    *파렛트당
                    <br />
                    최대 적재수량
                  </th>
                  <td>
                    <InputD
                      value={modelObj?.palletCapacity || ''}
                      onChange={(e) => {
                        if (numberTestRegExp(e.target.value)) {
                          setModelObj((prev) => {
                            return {
                              ...prev,
                              palletCapacity: Number(e.target.value),
                            };
                          });
                        } else {
                          alert('숫자만 입력가능합니다!');
                        }
                      }}
                    />
                  </td>
                  <th>기준대행료</th>
                  <td>
                    <InputD
                      value={modelObj?.standardFee || ''}
                      onChange={(e) => {
                        if (numberTestRegExp(e.target.value)) {
                          setModelObj((prev) => {
                            return {
                              ...prev,
                              standardFee: Number(e.target.value),
                            };
                          });
                        } else {
                          alert('숫자만 입력가능합니다!');
                        }
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ display: 'flex' }}>
              <table className="border-table detail-table" style={{ width: 'calc(50% - 10px)', marginRight: 10 }}>
                <thead>
                  <tr>
                    <th colSpan={12}>CBM(부피 계산값)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan={2}>*박스사이즈(mm)</th>
                    <td colSpan={8}>
                      <div>
                        <div className="grid-wrapper div3">
                          <div className="grid-ele">가로</div>
                          <div className="grid-ele">세로</div>
                          <div className="grid-ele">높이</div>
                        </div>
                        <div className="grid-wrapper div3">
                          <InputD
                            value={modelObj?.boxWidth || ''}
                            onChange={(e) => {
                              if (numberTestRegExp(e.target.value)) {
                                setModelObj((prev) => {
                                  return {
                                    ...prev,
                                    boxWidth: Number(e.target.value),
                                  };
                                });
                              } else {
                                alert('숫자만 입력가능합니다!');
                              }
                            }}
                          />
                          <InputD
                            value={modelObj?.boxDepth || ''}
                            onChange={(e) => {
                              if (numberTestRegExp(e.target.value)) {
                                setModelObj((prev) => {
                                  return {
                                    ...prev,
                                    boxDepth: Number(e.target.value),
                                  };
                                });
                              } else {
                                alert('숫자만 입력가능합니다!');
                              }
                            }}
                          />
                          <InputD
                            value={modelObj?.boxHeight || ''}
                            onChange={(e) => {
                              if (numberTestRegExp(e.target.value)) {
                                setModelObj((prev) => {
                                  return {
                                    ...prev,
                                    boxHeight: Number(e.target.value),
                                  };
                                });
                              } else {
                                alert('숫자만 입력가능합니다!');
                              }
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td colSpan={2}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="grid-ele">부피(m³)</div>
                        <div className="grid-ele">
                          <InputD disabled readOnly value={((modelObj?.boxWidth * modelObj?.boxDepth * modelObj?.boxHeight) / 1000000000).toFixed(2) || 0} />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="border-table detail-table" style={{ width: '50%' }}>
                <thead>
                  <tr>
                    <th colSpan={12}> 보관료</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan={2}>박스별</th>
                    <td colSpan={10}>
                      <div>
                        <div className="grid-wrapper div2">
                          <div className="grid-ele">적용단위</div>
                          <div className="grid-ele">보관료</div>
                        </div>
                        <div className="grid-wrapper div2">
                          <SelectD
                            options={MASTER_OPS?.STORAGE_FEE}
                            value={
                              modelObj?.boxStorageFeeUnit
                                ? {
                                    value: modelObj?.boxStorageFeeUnit,
                                    label: MASTER_OBJ?.STORAGE_FEE?.[modelObj?.boxStorageFeeUnit],
                                  }
                                : null
                            }
                            onChange={(option) => {
                              setModelObj((prev) => {
                                return {
                                  ...prev,
                                  boxStorageFeeUnit: (option as OptionItem).value,
                                };
                              });
                            }}
                          />
                          <InputD
                            disabled={!modelObj?.boxStorageFeeUnit}
                            value={modelObj?.boxStorageFeeUnit ? (modelObj?.boxStorageFeeUnit === 'DAY' ? modelObj?.boxStorageDayFee : modelObj?.boxStorageMonthFee) : ''}
                            onChange={(e) => {
                              if (numberTestRegExp(e.target.value)) {
                                if (modelObj?.boxStorageFeeUnit === 'DAY') {
                                  setModelObj((prev) => {
                                    return {
                                      ...prev,
                                      boxStorageDayFee: Number(e.target.value),
                                    };
                                  });
                                } else {
                                  setModelObj((prev) => {
                                    return {
                                      ...prev,
                                      boxStorageMonthFee: Number(e.target.value),
                                    };
                                  });
                                }
                              } else {
                                alert('숫자만 입력가능합니다!');
                              }
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2}>파렛트별</th>
                    <td colSpan={10}>
                      <div>
                        <div className="grid-wrapper div2">
                          <div className="grid-ele">적용단위</div>
                          <div className="grid-ele">보관료</div>
                        </div>
                        <div className="grid-wrapper div2">
                          <SelectD
                            options={MASTER_OPS?.STORAGE_FEE}
                            value={
                              modelObj?.palletStorageFeeUnit
                                ? {
                                    value: modelObj?.palletStorageFeeUnit,
                                    label: MASTER_OBJ?.STORAGE_FEE?.[modelObj?.palletStorageFeeUnit],
                                  }
                                : null
                            }
                            onChange={(option) => {
                              setModelObj((prev) => {
                                return {
                                  ...prev,
                                  palletStorageFeeUnit: (option as OptionItem).value,
                                };
                              });
                            }}
                          />
                          <InputD
                            disabled={!modelObj?.palletStorageFeeUnit}
                            value={modelObj?.palletStorageFeeUnit ? (modelObj?.palletStorageFeeUnit === 'DAY' ? modelObj?.palletStorageDayFee : modelObj?.palletStorageMonthFee) : ''}
                            onChange={(e) => {
                              if (numberTestRegExp(e.target.value)) {
                                if (modelObj?.palletStorageFeeUnit === 'DAY') {
                                  setModelObj((prev) => {
                                    return {
                                      ...prev,
                                      palletStorageDayFee: Number(e.target.value),
                                    };
                                  });
                                } else {
                                  setModelObj((prev) => {
                                    return {
                                      ...prev,
                                      palletStorageMonthFee: Number(e.target.value),
                                    };
                                  });
                                }
                              } else {
                                alert('숫자만 입력가능합니다!');
                              }
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {detailObj?.item && (
              <div style={{ textAlign: 'right', marginTop: 3 }}>
                <span className="b" style={{ marginRight: 10 }}>
                  등록일/등록자
                </span>
                <span style={{ marginRight: 10 }}>
                  {modelObj?.registerDatetime}
                  {modelObj?.registerId ? `/${modelObj?.registerId}` : ''}
                </span>
                <span className="b" style={{ marginRight: 10 }}>
                  수정일/수정자
                </span>
                <span>
                  {modelObj?.updateDatetime}
                  {modelObj?.updateId ? `/${modelObj?.updateId}` : ''}
                </span>
              </div>
            )}
          </div>
          <div style={{ flexDirection: 'column', display: currentTab === 1 ? 'flex' : 'none', pointerEvents: detailObj?.fetchFn ? 'unset' : 'none' }}>
            <table className="border-table detail-table" style={{ width: '100%', marginBottom: 10 }}>
              <thead>
                <tr>
                  <th colSpan={12}>시리얼 관리</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>*시리얼번호 여부</th>
                  <td colSpan={2}>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                      {MASTER_OPS?.MANAGE_FLAG?.map((ele, idx) => {
                        return (
                          <span
                            key={`serialNumberFlag_${ele?.value}_${idx}`}
                            //
                            style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'baseline' }}
                          >
                            <label style={{ marginRight: 10 }}>{ele.label}</label>
                            <InputD
                              type="radio"
                              checked={modelObj?.serialNumberFlag + '' === ele.value}
                              onChange={() => {
                                console.log(ele.value);
                                console.log(typeof ele.value);
                                setModelObj((prev) => {
                                  return {
                                    ...prev,
                                    serialNumberFlag: JSON.parse(ele.value),
                                    externalBarcodeFlag: JSON.parse(ele.value),
                                    externalBarcode: null,
                                    serialNumberLength: null,
                                  };
                                });
                              }}
                            />
                          </span>
                        );
                      })}
                    </div>
                  </td>
                  <th>
                    LOT번호 여부
                    <br />
                    (외부바코드)
                  </th>
                  <td colSpan={2}>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                      {MASTER_OPS?.MANAGE_FLAG?.map((ele, idx) => {
                        return (
                          <span key={`externalBarcodeFlag_${ele.value}_${idx}`} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'baseline' }}>
                            <label style={{ marginRight: 10 }}>{ele.label}</label>
                            <InputD
                              disabled={modelObj?.serialNumberFlag + '' !== 'true'}
                              type="radio"
                              checked={modelObj?.externalBarcodeFlag + '' === ele.value}
                              onChange={(e) => {
                                setModelObj((prev) => {
                                  return {
                                    ...prev,
                                    externalBarcodeFlag: JSON.parse(ele.value),
                                  };
                                });
                              }}
                            />
                          </span>
                        );
                      })}
                    </div>
                  </td>

                  <th>LOT번호</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={!modelObj?.serialNumberFlag || !modelObj?.externalBarcodeFlag || false}
                      value={modelObj?.externalBarcode || ''}
                      onChange={(e) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            externalBarcode: e.target.value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>시리얼번호 자리수</th>
                  <td colSpan={2}>
                    <InputD
                      type="number"
                      disabled={!modelObj?.serialNumberFlag || !modelObj?.externalBarcodeFlag || false}
                      placeholder={modelObj?.serialNumberFlag && modelObj?.externalBarcodeFlag ? 'LOT번호 길이보다 1이상 커야함(숫자만 입력)' : ''}
                      value={modelObj?.serialNumberLength || ''}
                      onChange={(e) => {
                        setModelObj((prev) => {
                          return {
                            ...prev,
                            serialNumberLength: Number(e.target.value),
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
              <thead>
                <tr>
                  <th colSpan={12}>시리얼 수집시점</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={6}>
                    <label className="col-form-label bold-f" style={{ justifyContent: 'center' }}>
                      양품재고 수집 시점
                    </label>
                  </td>
                  <td colSpan={6}>
                    <label className="col-form-label bold-f" style={{ justifyContent: 'center' }}>
                      불용재고 수집 시점
                    </label>
                  </td>
                </tr>
                <tr>
                  <td colSpan={6} style={{ padding: `10px 20px` }}>
                    <div className="status-panel no-bold" style={{ margin: 0, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <input disabled={flagDisabled} type="checkbox" id="purchaseFlag" checked={modelObj?.purchaseFlag || false} onChange={handleCheckbox} />
                      <label htmlFor="purchaseFlag">발주입고</label>
                      <input disabled={true} type="checkbox" id="availableLineFlag" checked={modelObj?.availableLineFlag || false} onChange={handleCheckbox} />
                      <label htmlFor="availableLineFlag">간선입출고</label>
                      <input
                        disabled={flagDisabled || modelObj?.purchaseFlag || false}
                        type="checkbox"
                        id="availableRemoveFlag"
                        checked={modelObj?.availableRemoveFlag || false}
                        onChange={handleCheckbox}
                      />
                      <label htmlFor="availableRemoveFlag">반출출고</label>
                      <input disabled={true} type="checkbox" id="availableLoanFlag" checked={modelObj?.availableLoanFlag || false} onChange={handleCheckbox} />
                      <label htmlFor="availableLoanFlag">기사출고</label>
                      <input
                        disabled={flagDisabled || modelObj?.purchaseFlag || false}
                        type="checkbox"
                        id="availableInvoiceInstallFlag"
                        checked={modelObj?.availableInvoiceInstallFlag || false}
                        onChange={handleCheckbox}
                      />
                      <label htmlFor="availableInvoiceInstallFlag">설치완료</label>

                      <input disabled={true} type="checkbox" id="availableInvoiceCollectFlag" checked={modelObj?.availableInvoiceCollectFlag || false} onChange={handleCheckbox} />
                      <label htmlFor="availableInvoiceCollectFlag">회수완료</label>
                      <input disabled={true} type="checkbox" id="availableCollectFlag" checked={modelObj?.availableCollectFlag || false} onChange={handleCheckbox} />
                      <label htmlFor="availableCollectFlag">회수입고</label>
                    </div>
                  </td>
                  <td colSpan={6} style={{ padding: `10px 20px` }}>
                    <div className="status-panel no-bold" style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <input disabled={true} type="checkbox" id="defectedLineFlag" checked={modelObj?.defectedLineFlag || false} onChange={handleCheckbox} />
                      <label htmlFor="defectedLineFlag">간선입출고</label>
                      <input
                        disabled={flagDisabled || modelObj?.purchaseFlag || modelObj?.defectedCollectFlag || false}
                        type="checkbox"
                        id="defectedRemoveFlag"
                        checked={modelObj?.defectedRemoveFlag || false}
                        onChange={handleCheckbox}
                      />
                      <label htmlFor="defectedRemoveFlag">반출출고</label>
                      <input disabled={true} type="checkbox" id="defectedInvoiceCollectFlag" checked={modelObj?.defectedInvoiceCollectFlag || false} onChange={handleCheckbox} />
                      <label htmlFor="defectedInvoiceCollectFlag">회수완료</label>
                      <input
                        disabled={flagDisabled || modelObj?.purchaseFlag || false}
                        type="checkbox"
                        id="defectedCollectFlag"
                        checked={modelObj?.defectedCollectFlag || false}
                        onChange={handleCheckbox}
                      />
                      <label htmlFor="defectedCollectFlag">회수입고</label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="border-table detail-table" style={{ width: '100%', marginBottom: 30 }}>
              <thead>
                <tr>
                  <th colSpan={12}>시리얼 로케이션 관리 여부</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={6}>
                    <label className="col-form-label bold-f" style={{ justifyContent: 'center' }}>
                      양품재고
                    </label>
                  </td>
                  <td colSpan={6}>
                    <label className="col-form-label bold-f" style={{ justifyContent: 'center' }}>
                      불용재고
                    </label>
                  </td>
                </tr>
                <tr>
                  <td colSpan={6} style={{ padding: `10px 20px` }}>
                    <div className="status-panel no-bold" style={{ margin: 0, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <input disabled={!modelObj?.purchaseFlag} type="checkbox" id="availableLocationFlag" checked={modelObj?.availableLocationFlag || false} onChange={handleCheckbox} />
                      <label htmlFor="availableLocationFlag">로케이션</label>
                      <input disabled={!modelObj?.availableLocationFlag} type="checkbox" id="mtoFlag" checked={modelObj?.mtoFlag || false} onChange={handleCheckbox} />
                      <label htmlFor="mtoFlag">MTO</label>
                    </div>
                  </td>
                  <td colSpan={6} style={{ padding: `10px 20px` }}>
                    <div className="status-panel no-bold" style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <input disabled={!modelObj?.defectedCollectFlag} type="checkbox" id="defectedLocationFlag" checked={modelObj?.defectedLocationFlag || false} onChange={handleCheckbox} />
                      <label htmlFor="defectedLocationFlag">로케이션</label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ flexDirection: 'column', display: currentTab === 2 ? 'flex' : 'none', pointerEvents: detailObj?.fetchFn ? 'unset' : 'none' }}>
            <div className="inputSmaillTitle">제품사진</div>
            <div className="grid-wrapper">
              {files?.MODEL_PHOTO?.map((photo, i) => {
                return (
                  <div className="grid-ele" key={`MODEL_PHOTO_${i}_${photo.uploadUrl}}`}>
                    <div className="photo-zone">
                      <span className="bck-font">제품{i + 1}</span>
                      {photo && (
                        <div style={{ display: 'inline-block' }}>
                          <img
                            src={CloseImg}
                            id={`MODEL_PHOTO_${i}`}
                            alt=""
                            onClick={(e) => {
                              handleFileRemove(e);
                            }}
                            className="closeBtn"
                          />
                          <ImageModal photoArray={files?.MODEL_PHOTO} idx={i} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="grid-ele">
                <div className="photo-zone">
                  <span className="bck-font">추가</span>
                  <input style={{ display: 'none' }} type="file" multiple accept="image/*" id={'ADD_MODEL_PHOTO'} onChange={handleFileUpload} />
                  <Button size="md" color="primary" outline>
                    <Label for={`ADD_MODEL_PHOTO`} style={{ marginBottom: 0 }}>
                      ㅤㅤㅤ파일 선택ㅤㅤㅤ
                    </Label>
                  </Button>
                </div>
              </div>
            </div>
            <div className="inputSmaillTitle">박스포장사진</div>
            <div className="grid-wrapper">
              {files?.MODELBOX_PHOTO?.map((photo, i) => {
                return (
                  <div className="grid-ele" key={`MODELBOX_PHOTO_${i}_${photo.uploadUrl}}`}>
                    <div className="photo-zone">
                      <span className="bck-font">포장{i + 1}</span>
                      {photo && (
                        <div style={{ display: 'inline-block' }}>
                          <img
                            src={CloseImg}
                            id={`MODELBOX_PHOTO_${i}`}
                            alt=""
                            onClick={(e) => {
                              handleFileRemove(e);
                            }}
                            className="closeBtn"
                          />
                          <ImageModal photoArray={files?.MODELBOX_PHOTO} idx={i} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

              <div className="grid-ele">
                <div className="photo-zone">
                  <span className="bck-font">추가</span>
                  <input style={{ display: 'none' }} type="file" multiple accept="image/*" id={'ADD_MODELBOX_PHOTO'} onChange={handleFileUpload} />
                  <Button size="md" color="primary" outline>
                    <Label for={`ADD_MODELBOX_PHOTO`} style={{ marginBottom: 0 }}>
                      ㅤㅤㅤ파일 선택ㅤㅤㅤ
                    </Label>
                  </Button>
                </div>
              </div>
            </div>
            <div className="inputSmaillTitle">파렛트적재 사진</div>
            <div className="grid-wrapper">
              {files?.MODEL_INSTALL?.map((photo, i) => {
                return (
                  <div className="grid-ele" key={`MODEL_INSTALL_${i}_${photo.uploadUrl}}`}>
                    <div className="photo-zone">
                      <span className="bck-font">적재{i + 1}</span>
                      {photo && (
                        <div style={{ display: 'inline-block' }}>
                          <img
                            src={CloseImg}
                            id={`MODEL_INSTALL_${i}`}
                            alt=""
                            onClick={(e) => {
                              handleFileRemove(e);
                            }}
                            className="closeBtn"
                          />
                          <ImageModal photoArray={files?.MODEL_INSTALL} idx={i} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

              <div className="grid-ele">
                <div className="photo-zone">
                  <span className="bck-font">추가</span>
                  <input style={{ display: 'none' }} type="file" multiple accept="image/*" id={'ADD_MODEL_INSTALL'} onChange={handleFileUpload} />
                  <Button size="md" color="primary" outline>
                    <Label for={`ADD_MODEL_INSTALL`} style={{ marginBottom: 0 }}>
                      ㅤㅤㅤ파일 선택ㅤㅤㅤ
                    </Label>
                  </Button>
                </div>
              </div>
            </div>
            <div className="inputSmaillTitle">설치사진</div>
            <div className="grid-wrapper">
              {files?.MODEL_PALLET?.map((photo, i) => {
                return (
                  <div className="grid-ele" key={`MODEL_PALLET_${i}_${photo.uploadUrl}}`}>
                    <div className="photo-zone">
                      <span className="bck-font">설치{i + 1}</span>
                      {photo && (
                        <div style={{ display: 'inline-block' }}>
                          <img
                            src={CloseImg}
                            id={`MODEL_PALLET_${i}`}
                            alt=""
                            onClick={(e) => {
                              handleFileRemove(e);
                            }}
                            className="closeBtn"
                          />
                          <ImageModal photoArray={files?.MODEL_PALLET} idx={i} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

              <div className="grid-ele">
                <div className="photo-zone">
                  <span className="bck-font">추가</span>
                  <input style={{ display: 'none' }} type="file" multiple accept="image/*" id={'ADD_MODEL_PALLET'} onChange={handleFileUpload} />
                  <Button size="md" color="primary" outline>
                    <Label for={`ADD_MODEL_PALLET`} style={{ marginBottom: 0 }}>
                      ㅤㅤㅤ파일 선택ㅤㅤㅤ
                    </Label>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
