import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { numberThousandComma } from 'common/util/counting';
import { regExpNotNumber } from 'common/util/regExp';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { ParcelInvoiceCollectChangeDTO } from '../../../../_interface/parcel';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';

export interface IChangeObj {
  visible?: boolean;
  checked?: number[];
  mode?: string;
  fetchFn?: (mode: string) => {};
}

export const ChangeModal = ({ changeObj, setChangeObj }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [inputs, setInputs] = useState<ParcelInvoiceCollectChangeDTO>();

  const changeInfo = async () => {
    //
    if (window.confirm('변경하시겠습니까?')) {
      const rs = await serviceStore.parcelAction(
        `invoice/collect/change`,
        'POST',
        null,
        changeObj?.mode !== 'SMALL'
          ? {
              parcelInvoiceCollectSeqs: changeObj?.checked,
              price: inputs?.price,
            }
          : {
              parcelInvoiceCollectSeqs: changeObj?.checked,
              boxSize: inputs?.boxSize,
              parcelType: inputs?.parcelType,
            },
      );

      if (rs?.status === 200) {
        alert('변경되었습니다');
        changeObj?.fetchFn(changeObj?.mode);
        setChangeObj(null);
      }
    }
  };

  return (
    <CommonModalNew
      title={'정보 수정'}
      style={{ width: 280 }}
      visible={changeObj?.visible}
      setVisible={() => {
        setChangeObj(null);
      }}
      children={
        <>
          {changeObj?.mode === 'ABNORMAL' ? (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">운임비</label>
                  {inputs?.price > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        backgroundColor: 'white',
                        left: 20,
                        top: 22,
                      }}
                    >
                      {numberThousandComma(inputs?.price)}
                    </div>
                  )}
                  <InputD
                    type="number"
                    placeholder="운임비를 입력하세요."
                    value={inputs?.price || ''}
                    onChange={(e) => {
                      const value = e.target.value.replace(regExpNotNumber, '');
                      setInputs((prev) => {
                        return {
                          ...prev,
                          price: Number(value),
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">택배사</label>
                  <SelectD
                    menuPlacement="top"
                    hasNull={false}
                    placeholder="택배사를 선택하세요"
                    value={
                      inputs?.parcelType
                        ? {
                            value: inputs?.parcelType,
                            label: MASTER_OBJ?.PARCEL_TYPE?.inputs?.parcelType,
                          }
                        : null
                    }
                    options={MASTER_OPS?.PARCEL_TYPE}
                    onChange={(options) => {
                      setInputs((prev) => {
                        return {
                          ...prev,
                          parcelType: (options as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">크기</label>
                  <SelectD
                    menuPlacement="top"
                    hasNull={false}
                    placeholder="크기를 선택하세요"
                    value={
                      inputs?.boxSize
                        ? {
                            value: inputs?.boxSize,
                            label: MASTER_OBJ?.CARGO_BOX_SIZE?.inputs?.boxSize,
                          }
                        : null
                    }
                    options={MASTER_OPS?.CARGO_BOX_SIZE}
                    onChange={(options) => {
                      setInputs((prev) => {
                        return {
                          ...prev,
                          boxSize: (options as OptionItem).value,
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row style={{ marginTop: 10, textAlign: 'right' }}>
            <Col>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  changeInfo();
                }}
                className="btn btn-secondary sm"
              >
                변경
              </button>
            </Col>
          </Row>
        </>
      }
    />
  );
};
