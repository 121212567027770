import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';

import { modelNproductService } from '../../_services/service';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { ProductListDTO, ProductListExcelDTO, ProductSearchDTO } from 'interface/order';
import useGridButton from 'hooks/grid/useGridButton';
import { DetailPopup, IDetailObj } from './component/detailPopup';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
import { useDidMountEffect } from 'hooks/basichooks';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { PRODUCT_MOD_FORM, PRODUCT_MODEL_MOD_FORM, PRODUCT_UPLOAD_FORM } from 'envVar2';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const excelDetailGridRef = useRef<AUIGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [detailObj, setDetailObj] = useState<IDetailObj>();

  const popupProductDetail = (e: IGrid.ButtonClickEvent) => {
    setDetailObj({
      visible: true,
      item: e.item,
      searchObj: e.item.searchObj,
      fetchFn: (searchObj: ProductSearchDTO) => wrappedFetchList(searchObj),
    });
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      dataField: 'showDetail',
      width: 70,
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: popupProductDetail,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.PRODUCT_TAB_SHOWDETAIL;
        },
      },
    },
    {
      headerText: '수정가능<br/>여부',
      dataField: 'productModelUpdateFlagKr',
      style: 'ellipsis-div',
      width: 70,
    },
    {
      headerText: '상품번호',
      dataField: 'productSeq',
      style: 'ellipsis-div',
    },
    {
      headerText: '외부상품번호',
      dataField: 'externalProductNumber',
      style: 'ellipsis-div',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      style: 'ellipsis-div',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
      style: 'ellipsis-div',
      width: 200,
    },
    {
      headerText: '판매가',
      dataField: 'sellingPrice',
      style: 'ellipsis-div',
      dataType: 'numeric',
      formatString: '#,##0',
      width: 70,
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
      style: 'ellipsis-div',
      width: 70,
    },
    {
      headerText: '설치소요시간',
      dataField: 'installDurationKr',
      style: 'ellipsis-div',
      width: 70,
    },
    {
      headerText: '팀유형',
      dataField: 'memberCountKr',
      style: 'ellipsis-div',
      width: 70,
    },
    {
      headerText: '최대높이',
      dataField: 'maxHeight',
      dataType: 'numeric',
      style: 'ellipsis-div',
      formatString: '#,##0',
      width: 70,
    },
    {
      headerText: '제품 정보',
      children: [
        {
          headerText: '제품구분',
          width: 70,
          dataField: 'giftFlagKr',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.giftFlag ? '사은품' : '구성품' || '  '}</span>`;
            });
            div += ' </div>';
            return div;
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.modelStockSeq || '  '}</span>`;
            });
            div += ' </div>';
            return div;
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.skuNumber || '  '}</span>`;
            });
            div += ' </div>';
            return div;
          },
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.barcode || '  '}</span>`;
            });
            div += '</div>';
            return div;
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.model || '  '}</span>`;
            });
            div += '</div>';
            return div;
          },
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.modelName || '  '}</span>`;
            });
            div += '</div>';
            return div;
          },
          width: 350,
        },
        {
          headerText: '수량',
          dataField: 'quantity',
          style: 'ellipsis-div',
          renderer: {
            type: IGrid.RendererKind.TemplateRenderer,
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
            let div = `<div class="column-direction">`;
            item?.productModels?.forEach((model) => {
              div += `<span>${model?.quantity || '  '}</span>`;
            });
            div += '</div>';
            return div;
          },
          width: 50,
        },
      ],
    },
  ];

  const excelColumnProp = {
    cellMerge: true,
    mergePolicy: 'restrict' as IGrid.Column['mergePolicy'],
    mergeRef: 'productSeq',
  };

  const excelColumns: IGrid.Column[] = [
    {
      headerText: '수정가능<br/>여부',
      dataField: 'productModelUpdateFlagKr',
      ...excelColumnProp,
    },
    {
      headerText: '상품번호',
      dataField: 'productSeq',
      ...excelColumnProp,
    },
    {
      headerText: '외부상품번호',
      dataField: 'externalProductNumber',
      ...excelColumnProp,
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      ...excelColumnProp,
    },
    {
      headerText: '상품명',
      dataField: 'productName',
      ...excelColumnProp,
    },
    {
      headerText: '판매가',
      dataField: 'sellingPrice',
      dataType: 'numeric',
      ...excelColumnProp,
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
      ...excelColumnProp,
    },
    {
      headerText: '설치소요시간',
      dataField: 'installDurationKr',
      ...excelColumnProp,
    },
    {
      headerText: '팀유형',
      dataField: 'memberCountKr',
      ...excelColumnProp,
    },
    {
      headerText: '최대높이',
      dataField: 'maxHeight',
      dataType: 'numeric',
      formatString: '#,##0',
      ...excelColumnProp,
    },
    {
      headerText: '제품 정보',
      children: [
        {
          headerText: '제품구분',
          dataField: 'giftFlagKr',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '수량',
          dataField: 'quantity',
        },
      ],
    },
  ];

  const detailExcelColumns: IGrid.Column[] = [
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '상품번호',
      dataField: 'productSeq',
    },
    {
      headerText: '외부상품번호',
      dataField: 'externalProductNumber',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '설치소요시간',
      dataField: 'installDurationKr',
    },
    {
      headerText: '팀유형',
      dataField: 'memberCountKr',
    },
    {
      headerText: '최대높이',
      dataField: 'maxHeight',
      dataType: 'numeric',
      formatString: '#,##0',
    },
    {
      headerText: '구성 제품 정보',
      children: [
        {
          headerText: '제품대분류',
          dataField: 'modelGroupLargeKr',
        },
        {
          headerText: '제품중분류',
          dataField: 'modelGroupMediumKr',
        },
        {
          headerText: '구분',
          dataField: 'giftFlagKr',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '수량',
          dataField: 'quantity',
        },
      ],
    },
  ];

  const labellingKr = (data: PagingListDTO<ProductListDTO & ProductListExcelDTO>, isExcel = false) => {
    const labeledList = [];
    const productSeqs = [];
    if (isExcel) {
      data?.list?.forEach((product) => {
        product.productModels?.forEach((model) => {
          labeledList?.push({
            ...Object.assign({}, product, model),
            giftFlagKr: model.giftFlag ? '사은품' : '구성품',
            // product Kr
            modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[product?.modelGroup],
            partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[product?.partnerSeq],
            productModelUpdateFlagKr: product.productModelUpdateFlag ? 'O' : 'X',
            installDurationKr: MASTER_OBJ?.INSTALL_DURATION?.[product?.installDuration],
            memberCountKr: MASTER_OBJ?.TEAM_TYPE?.[product?.memberCount],
          });
        });
      });
    } else {
      data?.list?.forEach((product) => {
        product.productModels?.forEach((model) => {
          if (!productSeqs?.includes(product?.productSeq))
            labeledList?.push({
              ...Object.assign({}, product, model),
              ...gridButtonUseable(gridFunctions),
              searchObj: isExcel ? '' : JSON.stringify(searchObj),
              // model Kr
              giftFlagKr: model.giftFlag ? '사은품' : '구성품',
              // product Kr
              modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[product?.modelGroup],
              partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[product?.partnerSeq],
              productModelUpdateFlagKr: product.productModelUpdateFlag ? 'O' : 'X',
              installDurationKr: MASTER_OBJ?.INSTALL_DURATION?.[product?.installDuration],
              memberCountKr: MASTER_OBJ?.TEAM_TYPE?.[product?.memberCount],
              showDetail: '상세보기',
            });
          productSeqs.push(product?.productSeq);
        });
      });
    }
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: ProductSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await modelNproductService.getProductListPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const transExelAPI = {
    main: `product/list/excel`,
    detail: `product/list/excel/detail`,
  };

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: (await serviceStore?.orderAction(transExelAPI[type], 'GET', searchObj))?.data });
    if (type === 'main') {
      /* NOTE:
        list 배열 내 각 객체마다 productModels 배열이 존재하고 그 하위에 개별 제품 정보를 담고있는 객체가 존재함
        따라서 list 마다 map을 돌려 productModels 배열의 길이만큼의 객체 배열을 생성하고, 최종적으로는 flat(1)을 수행하여 배열을 flat하게 만들어 excelGridRef에 적용
      */
      let compiledList = list?.map((ele) => {
        let newList = [];
        const modelsLength = ele?.productModels?.length;
        for (let i = 0; i < modelsLength; i++) {
          const model = ele?.productModels[i];
          newList.push({
            ...ele,
            ...model,
          });
        }
        return newList;
      });

      excelGridRef?.current.setGridData(compiledList?.flat(1));
      excelGridRef?.current.exportAsXlsx({ fileName: '상품관리' });
    } else {
      excelDetailGridRef.current.setGridData(list);
      excelDetailGridRef.current.exportAsXlsx({ fileName: '상품관리(상세)' });
    }
    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'PRODUCTREGISTER') {
      setDetailObj({
        visible: true,
        item: null,
        searchObj: JSON.stringify(searchObj),
        fetchFn: (searchObj: ProductSearchDTO) => wrappedFetchList(searchObj),
      });
    } else if (id === 'PRODUCTEXCEL') {
      downloadExcel('main');
    } else if (id === 'PRODUCTUPLOADFORM') {
      window.open(PRODUCT_UPLOAD_FORM);
    } else if (id === 'PRODUCTMODIFORM') {
      window.open(PRODUCT_MOD_FORM);
    } else if (id === 'PRODUCT_MODELS_MODI_FORM') {
      window.open(PRODUCT_MODEL_MOD_FORM);
    } else if (id === 'PRODUCTUPLOAD' || id === 'PRODUCTMODI' || id === 'PRODUCT_MODELS_MODI_UPLOAD') {
      uploaderClick(id);
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const transUploadAPI = {
    PRODUCTUPLOAD: 'product/save/excel',
    PRODUCTMODI: 'product/update/excel',
    PRODUCT_MODELS_MODI_UPLOAD: 'product/model/update/excel',
  };

  const ExcelUploadFunction = async (dataDTO, id) => {
    dispatch(setLoading('POST'));
    const rs = await serviceStore.orderAction(transUploadAPI[id], 'POST', null, dataDTO);
    if (rs?.status === 200) {
      alert(rs?.data?.message);
      wrappedFetchList(searchObj);
    }
    dispatch(setLoading(null));
  };
  const KRTOVALUE = {
    partnerSeq: 'SELLER_WHOLE',
    memberCount: 'TEAM_TYPE',
    installDuration: 'INSTALL_DURATION',
    modelGroup: 'MODEL_GROUP',
    giftFlag: 'OX_FLAG',
  };

  const EXCELHEADER = {
    PRODUCTUPLOAD: [
      //
      'partnerSeq',
      'brand',
      'productName',
      'externalProductNumber',
      'memberCount',
      'installDuration',
      'modelGroup',
      'barcode',
      'giftFlag',
      'quantity',
      'sellingPrice',
    ],
    PRODUCTMODI: [
      //
      'partnerSeq',
      'productSeq',
      'externalProductNumber',
      'productName',
      'installDuration',
      'memberCount',
      'modelGroup',
      'sellingPrice',
    ],
    PRODUCT_MODELS_MODI_UPLOAD: [
      //
      'partnerSeq',
      'externalProductNumber',
      'productSeq',
      'barcode',
      'quantity',
      'giftFlag',
    ],
  };

  const HEADERLINEIDX = {
    PRODUCTMODI: 1,
    PRODUCT_MODELS_MODI_UPLOAD: 1,
  };

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE, HEADERLINEIDX });
  useDidMountEffect(() => {
    if (parsedData?.data?.length > 0) {
      ExcelUploadFunction(parsedData?.data, parsedData?.id);
    }
  }, [parsedData]);

  return (
    <div className="page-content">
      {detailObj?.visible && <DetailPopup detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
            enableCellMerge: true,
            enableSummaryMerge: true,
            wordWrap: true,
            fixedColumnCount: 4,
            rowStyleFunction(rowIndex, item) {
              return `row-height-${item?.productModels?.length}-count`; // display: none
            },
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={excelColumns} gridProps={{ enableCellMerge: true }} />
        <PrintGridBox gridRef={excelDetailGridRef} columns={detailExcelColumns} />
      </div>
    </div>
  );
};

export default Index;
