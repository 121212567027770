import { useState, useEffect } from 'react';
import { DetailTopTab, IDetailTopTab } from 'components/template/detailTopTab';

// redux
import { useSelector, useDispatch } from 'react-redux';

// component
import Tab1 from './moveActionRegit';
// options
import { nullingMASTER } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';

export const MOVE_ACTION_REGIT_MASTER = [
  'SELLER_SELLER_AUTH',
  'SELLER_SELLER_WHOLE',
  //
  'CENTER_AUTH',
  'CENTER_WHOLE',
  //
  'MODEL_GROUP',
  'INVA_TYPE',
  'FEE_TYPE',
  //
  'SEARCH_MODEL',
  {
    SEARCH_SEQ: [
      {
        label: '주문번호',
        value: 'orderSeq',
      },
      {
        label: '외부주문번호',
        value: 'externalOrderNumber',
      },
      {
        label: '송장번호',
        value: 'invoiceSeq',
      },
      {
        label: '외부송장번호',
        value: 'externalInvoiceNumber',
      },
    ],
  },
];

const Index = () => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [tabObj, setTabObj] = useState<IDetailTopTab>();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MOVE_ACTION_REGIT_MASTER);
  };

  useEffect(() => {
    if (MASTER_OBJ) {
      setTabObj({
        titles: ['재고이동', '재고이동 지시'],
        routers: ['/moveActionList', '/moveActionRegit'],
        container: [<Tab1 />],
      });
    }
  }, [MASTER_OBJ]);

  return <>{tabObj && <DetailTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
