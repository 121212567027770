import CommonModalNew from 'components/modal/commonModalNew';
import { LocationGrid } from './locationGrid';

export const MapModal = ({ mapObj, setMapObj }) => {
  return (
    <CommonModalNew
      title={mapObj?.activeData?.centerCodeKr}
      style={{ width: 1400 }}
      visible={mapObj?.visible}
      setVisible={() => {
        setMapObj({
          visible: false,
          data: null,
        });
      }}
      children={
        <>
          {mapObj?.activeData && (
            <>
              <LocationGrid mapObj={mapObj} setMapObj={setMapObj} />
            </>
          )}
        </>
      }
    />
  );
};
