import { RootState } from 'redux/store';

import { useSelector } from 'react-redux';
import { useState } from 'react';
import { MessageDTO } from '../../_interface/order';
import CommonModalNew from 'components/modal/commonModalNew';

export interface ISms {
  visible?: boolean;
  data?: MessageDTO[];
}

export const SmsLogPopup = ({ smsObj, setSmsObj }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [selectedContent, setSelectedContent] = useState<string>();

  return (
    <CommonModalNew
      style={{ width: 800 }}
      visible={smsObj?.visible}
      setVisible={() => {
        setSmsObj({
          visible: false,
          data: null,
        });
      }}
      title="메시지 발송 내역"
      children={
        <div style={{ display: 'flex', height: 450 }}>
          <table className="border" style={{ width: 450, height: 'min-content' }}>
            <thead>
              <tr>
                <th>No.</th>
                <th>구분</th>
                <th>일시</th>
                <th>발신자</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              {smsObj?.data.map((ele: MessageDTO, idx) => {
                return (
                  <tr key={`smsobj_data_${idx}`}>
                    <td>{idx + 1}</td> <td>{masterOptions?.MESSAGE_TYPE_OBJ[ele.type] || '-'}</td>
                    <td>{ele.sendDatetime}</td>
                    <td>{ele.userId}</td>
                    <td
                      className="click-fake-item"
                      onClick={() => {
                        setSelectedContent(ele.content);
                      }}
                    >
                      상세보기
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              border: '4px solid #d8dfe1',
              borderRadius: 10,
              width: 350,
              height: 450,
              margin: '0 10px',
              padding: 10,
            }}
          >
            {selectedContent ? (
              <textarea style={{ height: '100%', border: 'none' }} disabled value={selectedContent} />
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  color: '#b7b7b7',
                  fontStyle: 'italic',
                }}
              >
                '상세보기'를 클릭해주세요!
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};
