import { useEffect, useState } from 'react';
import CommonModalNew from 'components/modal/commonModalNew';
import { InvoiceHappyCallHistoryDTO, OrderListDTO, OrderSearchDTO } from '../../../_interface/order';
import { orderService } from 'pages/ORDER/1order/_services/service';

export interface IhappycallObj {
  visible?: boolean;
  searchObj?: OrderSearchDTO;
  invoiceDetail: OrderListDTO;
}

export const HappyCallModal = ({ happycallObj, setHappycallObj }) => {
  const [checked, setChecked] = useState<number[]>();
  const [data, setData] = useState<InvoiceHappyCallHistoryDTO[]>();

  useEffect(() => {
    fetchData();
  }, []);

  const saveRead = async () => {
    if (window.confirm('해피콜 내역을 기록하시겠습니까?')) {
      const readRs = await orderService.readHappycall(happycallObj?.invoiceDetail?.invoiceSeq);
      if (readRs?.status === 200) {
        alert('해피콜 내역 기록이 완료되었습니다.');
        fetchData();
        if (happycallObj?.fetchFn && happycallObj?.searchObj) happycallObj?.fetchFn(happycallObj?.searchObj);
      }
    }
  };
  const deleteRead = async () => {
    console.log(checked);
    const dataDTO = checked?.map((seq) => {
      return {
        seq,
      };
    });
    if (window.confirm('해피콜 내역을 삭제하시겠습니까?')) {
      const readRs = await orderService.deleteHappycall(happycallObj?.invoiceDetail?.invoiceSeq, dataDTO);
      if (readRs?.status === 200) {
        alert('해피콜 내역 기록이 삭제되었습니다.');
        fetchData();
        if (happycallObj?.fetchFn && happycallObj?.searchObj) happycallObj?.fetchFn(happycallObj?.searchObj);
      }
    }
  };

  const fetchData = async () => {
    const getRs = await orderService.getHappycall(happycallObj?.invoiceDetail?.invoiceSeq);
    if (getRs?.status === 200) {
      setChecked([]);
      setData(getRs?.data as InvoiceHappyCallHistoryDTO[]);
    }
  };

  return (
    <CommonModalNew
      visible={happycallObj?.visible}
      setVisible={() => {
        setHappycallObj(null);
      }}
      title="해피콜 내역"
      style={{ width: 500 }}
      children={
        <>
          <div>
            <div className="grid-button-area space-between">
              <div>
                <div
                  className="red"
                  onClick={() => {
                    deleteRead();
                  }}
                >
                  삭제
                </div>
              </div>
              <div>
                <div
                  className="blue"
                  onClick={() => {
                    saveRead();
                  }}
                >
                  기록하기
                </div>
              </div>
            </div>
            <table className="border-table" style={{ marginTop: 0 }}>
              <thead>
                <tr>
                  <th>
                    <input
                      style={{ marginTop: 4 }}
                      type="checkbox"
                      disabled={data?.length === 0}
                      checked={checked?.length === data?.length}
                      onChange={() => {
                        if (checked?.length === data?.length) {
                          setChecked([]);
                        } else {
                          setChecked(data?.map((ele) => ele.seq));
                        }
                      }}
                    />
                  </th>
                  <th colSpan={2}>No.</th>
                  <th colSpan={5}>담당자</th>
                  <th colSpan={5}>일시</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((p = {}, index) => {
                  return (
                    <tr key={`happycall_${index}`}>
                      <th>
                        <input
                          style={{ marginTop: 4 }}
                          type="checkbox"
                          checked={checked?.includes(p.seq) || false}
                          onChange={() => {
                            if (checked?.includes(p.seq)) {
                              setChecked(checked?.filter((check) => check !== p.seq));
                            } else {
                              setChecked(checked?.concat(p.seq) || [p.seq]);
                            }
                          }}
                        />
                      </th>
                      <th colSpan={2}>{index + 1}</th>
                      <th colSpan={5}>{p?.registerId}</th>
                      <th colSpan={5}>{p?.happyCallDatetime}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      }
    />
  );
};
