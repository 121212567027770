import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import useToast from 'hooks/useToast';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { SelectD } from 'components/reactstrap/reactstrap';
import { ParcelInvoiceRemainUserDTO } from '../../_interface/parcel';
import { setLoading } from 'redux/services/menuSlice';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);

  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const [inputs, setInputs] = useState<{ centerCode: string; parcelInvoiceNumber: string }>({
    centerCode: reduxUserInfo?.centerCode,
    parcelInvoiceNumber: null,
  });
  const [todayList, setTodayList] = useState<ParcelInvoiceRemainUserDTO[]>();
  const successPlayer = useRef<HTMLAudioElement>();
  const failurePlayer = useRef<HTMLAudioElement>();
  const { Toast, displayToast, setColor, setMsg } = useToast({
    message: '',
    ms: 3000,
  });

  const scanInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    scanInputRef?.current?.focus();
    if (inputs?.centerCode) {
      getLinehaulToday();
    }
  }, []);

  useEffect(() => {
    if (inputs?.centerCode) {
      getLinehaulToday();
    }
  }, [inputs?.centerCode]);

  const getLinehaulToday = async () => {
    dispatch(setLoading('GET'));
    const { data } = await serviceStore.parcelAction(`invoice/remain/user`, 'GET', inputs, null);
    if (data) setTodayList(data as ParcelInvoiceRemainUserDTO[]);
    dispatch(setLoading(null));
  };

  const scanRight = (str = 'centerName') => {
    setColor('green');
    successPlayer.current.play();
    setMsg(str);
    displayToast();
  };

  const scanError = (str = '오류입니다') => {
    setColor('red');
    failurePlayer.current.play();
    setMsg(str);
    displayToast();
    setInputs((prev) => {
      return {
        ...prev,
        parcelInvoiceNumber: null,
      };
    });
    scanInputRef.current.value = '';
  };

  const validation = (inputs) => {
    if (!inputs?.centerCode) {
      alert('현재위치는 필수값입니다!');
      return false;
    }
    if (!inputs?.parcelInvoiceNumber) {
      alert('운송장번호는 필수값입니다!');
      return false;
    }
    return true;
  };

  const reset = () => {
    scanInputRef.current.value = '';
    setInputs((prev) => {
      return {
        ...prev,
        parcelInvoiceNumber: null,
      };
    });
    scanInputRef?.current?.focus();
  };

  const postSaveLoad = async () => {
    console.log(inputs);
    if (validation(inputs)) {
      const rs = await serviceStore.parcelAction(`invoice/remain`, 'POST', null, inputs);
      if (rs?.status === 200) {
        rs?.data?.result ? scanRight('잔류처리 되었습니다') : scanError('오류가 있습니다');
        getLinehaulToday();
        reset();
      } else {
        scanError(rs?.data?.message);
      }
    }
  };

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    postSaveLoad();
  };

  return (
    <div className="page-content">
      <div style={{ maxWidth: 500 }}>
        <div style={{ position: 'relative', height: 50, marginTop: 30, marginBottom: 12 }}>
          <Toast />
        </div>
        <Form onSubmit={handleSubmit}>
          <Row style={{ marginTop: 50 }}>
            <Col>
              <label className="col-form-label">*현재위치</label>
              <SelectD
                isDisabled={MASTER_OPS?.CENTER_AUTH?.length < 2}
                options={MASTER_OPS?.CENTER_AUTH}
                hasNull
                value={
                  inputs?.centerCode
                    ? {
                        value: inputs?.centerCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[inputs?.centerCode],
                      }
                    : null
                }
                onChange={(option) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      centerCode: (option as OptionItem)?.value,
                      parcelLinehaulSeq: null,
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">*운송장번호</label>
              <input
                ref={scanInputRef}
                value={inputs?.parcelInvoiceNumber || ''}
                className={'form-control backgroundYellow'}
                placeholder="운송장번호를 입력하세요"
                onChange={(e) => {
                  setInputs((prev) => {
                    return {
                      ...prev,
                      parcelInvoiceNumber: e.target.value.substring(0, 13),
                    };
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <button type="submit" style={{ visibility: 'hidden' }} />
          </Row>
        </Form>
        <div className="grid-button-area space-between" style={{ marginTop: 30 }}>
          <div></div>
          <div>건수 : {todayList?.length || 0}</div>
        </div>
        <table className="border-table noBorder fontSize10" style={{ fontSize: 10 }}>
          <thead>
            <tr>
              <th colSpan={2}>순번</th>
              <th colSpan={10}>운송장번호</th>
            </tr>
          </thead>
          <tbody>
            {todayList?.map((ele, idx) => {
              return (
                <tr key={`today_${idx}`}>
                  <th colSpan={2}>{todayList?.length - idx}</th>
                  <th colSpan={10}>{ele.parcelInvoiceNumber}</th>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="audioWrapper">
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_sound.mp3" ref={successPlayer} controls></audio>
        <audio src="https://s3.ap-northeast-2.amazonaws.com/static.logimate.co.kr/template/mp3/scan_fail_sound.mp3" ref={failurePlayer} controls></audio>
      </div>
    </div>
  );
};

export default Index;
