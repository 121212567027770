import { Routes, Route } from 'react-router-dom';

/// 0. [홈]
import HOME from 'pages/_HOME/dashboard/container';
import Login from 'pages/_HOME/authentication';

// 0. 생성/등록 화면
import ModificationDERegister from 'pages/_REGIT/modificationRegitTab/defRegit'; //불용재고조정
import ModificationAVRegister from 'pages/_REGIT/modificationRegitTab/availlRegit'; //양품재고조정
import RegisterManageInventoryMove from 'pages/_REGIT/modelMoveRegitTab'; //재고품목전환
import INVMOVEREGIT from 'pages/_REGIT/moveActionRegitTab'; //재고이동지시
import TrunkRegister from 'pages/_REGIT/_trunkRegitTab'; // 간선피킹생성
import TrunkRegit from 'pages/_REGIT/trunkRegitTab'; // 간선피킹생성 new
import TakeoutRegit from 'pages/_REGIT/takeoutRegitTab'; //반출피킹생성 new
import TakeOutPickingInstructionRegister from 'pages/_REGIT/_takeoutRegitTab'; //반출피킹생성
import StoreTakeOutPickingInstructionRegister from 'pages/_REGIT/bodyBanchulRegitTab/index.js'; //양판점출고
import RefurRegister from 'pages/_REGIT/_refurRegitTab/create'; //리퍼생성(구)
import RefurRegit from 'pages/_REGIT/refurRegitTab'; //리퍼생성 new
import PlaceOrderRegister from 'pages/_REGIT/_purchaseRegitTab'; // 발주등록
import PurchaseRegit from 'pages/_REGIT/purchaseRegitTab';
import TransactionRegister from 'pages/_REGIT/statementRegitTab/index';
import InventoryInvestigateRegit from 'pages/_REGIT/inventoryInspectRegitTab'; // 주문등록2
import OrderRegit from 'pages/_REGIT/orderRegitTab'; // 주문등록2

// [택배]
import ParcelServiceList from 'pages/PARCEL/parcelListTab';
import ParcelTaskList from 'pages/PARCEL/orderList/taskTab';
import ParcelLinehaul from 'pages/PARCEL/linehaul/linehaulTab';
import ParcelLoading from 'pages/PARCEL/linehaul/loadingTab';
import ParcelRemain from 'pages/PARCEL/remain';
import ParcelAccident from 'pages/PARCEL/orderList/accidentTab';
import ParcelRevisit from 'pages/PARCEL/orderList/revisitTab';
import ParcelCalculateFare from 'pages/PARCEL/orderList/calculateTab';
import ParcelNumber from 'pages/PARCEL/numberTab';
import ParcelNumberPrint from 'pages/PARCEL/numberPrintTab';

import ParcelFee from 'pages/PARCEL/fee/feeListTab';
import ParcelLoan from 'pages/PARCEL/fee/loanListTab';
import ParcelChangeFare from 'pages/PARCEL/fee/feeChangeFareTab';
import ParcelTracking from 'pages/PARCEL/tracking/reportTab';

/// 1. [입고]
import PurchaseOrder from 'pages/IPGO/1purchaseOrder/purchaseOrderTab';
import TrunkReceiving from 'pages/IPGO/2trunk/gansunIpgoTab';
import CollectReceiving from 'pages/IPGO/3collect/collectTab';

import InventoryLocationSelect2 from 'pages/IPGO/4location/locationHistoryTab';
import PurchaseIn from 'pages/IPGO/5purchase/purchaseInTab';

import GansunWorks from 'pages/OUT/1trunk/gansunWorksTab';

// 2-2. 반출
import TakeoutWorks from 'pages/OUT/2takeout/banchulWorksTab';
import InventoryReturnManageMent from 'pages/OUT/2takeout/inventoryReturnTab';
import WasteAppliances from 'pages/OUT/2takeout/wasteAppliancesTab';

// 2-3. 납품
import DeliveryWorks from 'pages/OUT/delivery/deliveryWorksTab';

// 2-4. 기사
import FCPicking from 'pages/OUT/3driver/fcPickingTab';
import CreateFCPicking from 'pages/OUT/3driver/createFcPicking';
import DeliveryOrder from 'pages/OUT/3driver/takeoutTab/index';
import DeliveryOrderLite from 'pages/OUT/3driver/driverDeliveryOrderLite';

// 2-5. 배차
// 2-5-1. 배차
import BaeChaList2 from 'pages/OUT/4car/BaeCha2/index';

/// 3. [재고]
// 3-1. 재고
import InventoryReportUnited from 'pages/INVENTORY/1inventory/inventoryReportTab';
import InventoryInvestigate from 'pages/INVENTORY/1inventory/inventoryInvestigateTab';
import InventoryTracking from 'pages/INVENTORY/1inventory/inventoryTrackingTab';
import ManageInventoryMove from 'pages/INVENTORY/1inventory/modelMoveTab';
import Modifications from 'pages/INVENTORY/1inventory/modificationTab';
import LocationStatics from 'pages/INVENTORY/1inventory/locationStaticsTab';
import InventoryInspect from 'pages/INVENTORY/1inventory/inventoryInspectTab';

import SCMmanagement from 'pages/INVENTORY/2scm/scmManagement';
// 3-2. 로케이션
// 3-2-1. 입출고 내역
import InventoryInOutList from 'pages/INVENTORY/3location/inoutListTab';
// 3-2-2. 로케이션 조회
// 3-2-3. 재고이동 지시

import INVMOVELIST from 'pages/INVENTORY/3location/moveActionListTab';

// 3-3. 제품/상품
import ModelList from 'pages/INVENTORY/4modelNproduct/modelTab';
import ProductList from 'pages/INVENTORY/4modelNproduct/productTab';
import BarcodePrinting from 'pages/INVENTORY/4modelNproduct/barcodePrintTab';
import RefurList from 'pages/INVENTORY/4modelNproduct/refurTab';

/// 4. [주문]
// 4-1. 주문
// 공지사항
import MessageSendManage from 'pages/ORDER/1order/messageSendManageTab';
import OrderHistory from 'pages/ORDER/1order/orderHistoryTab';
import ParcelList from 'pages/ORDER/1order/parcelListTab';
import OrderUploadForm from 'pages/ORDER/1order/customUploadFormTab';
import OrderList from 'pages/ORDER/1order/orderListTab';
import ManageService from 'pages/ORDER/1order/manageServiceTab';
import Payment from 'pages/ORDER/1order/paymentTab';
import ManageRelocationorderInfoList from 'pages/ORDER/1order/relocateOrderTab';

/// 5. [배송]
// 5-1. 상담
import DriverCs from 'pages/DELIVERY/1qna/driverCsTab';
import CustomerCS from 'pages/DELIVERY/1qna/customerCsTab';
import SystemAnnouncement from 'pages/DELIVERY/1qna/systemAnnouncementTab';
// 5-2. 배송
import CustomAlertMsg from 'pages/DELIVERY/2delivery/customAlertMsgTab';
import OrderTracking3 from 'pages/DELIVERY/2delivery/orderTrackingTab';
import Logis from 'pages/DELIVERY/2delivery/morderTrackingForInnos';
import OrderTracking from 'pages/DELIVERY/2delivery/orderTracking';
import POrderTracking from 'pages/DELIVERY/2delivery/morderTrackingForParcel';
import ManageLazyorderList from 'pages/DELIVERY/2delivery/manageLazyorderListTab';

// 7. [정산]
// 7-1. 정산
import BasicFee from 'pages/FEE/1basicFee/index';
import OrderReceipt from 'pages/FEE/2orderReceipt/index';
import TransactionStatement from 'pages/FEE/3transactionStatement/index';

import KPIbyCapa from 'pages/STATISTICS/KPIinCapa/index';
import KPI from 'pages/STATISTICS/KPI/index';
import ExcelSinglePage from 'pages/STATISTICS/excelSinglePageTab';

/// 9. [기타]
// 9-1. 기사관리
import DriverList from 'pages/ETC/1driver/driverListTab';
// 9-2. 창고
import WarehouseList from 'pages/ETC/2center/warehouseListTab';
import WarehouseLocation from 'pages/ETC/2center/warehouseLocationTab';
// 9-3. 사용자
import User from 'pages/ETC/3user/userTab';
import ManageLD from 'pages/ETC/3user/jojikLDTab';
import ManageUserInGroupTab from 'pages/ETC/3user/jojikCaptainTab';
import SalesChannel from 'pages/ETC/3user/salesChannelTab';
import PartnerList from 'pages/ETC/3user/partnerListTab';
// 9-4. 설정
import Settings from 'pages/ETC/4settings/settingTabs';
import SettingsList from 'pages/ETC/4settings/listTabs';

import CargoManifest from 'pages/CARGO/cargoList/cargoListTab';
import CargoManifestTransfer from 'pages/CARGO/cargoList/transferTab';
import CargoManifestAccident from 'pages/CARGO/cargoList/accidentTab';
// 기사
import InfraGroupList from 'pages/ETC/3user/infraGroupTab';
import DeliverySector from 'pages/ETC/1driver/deliverySectorTab';

//정산

// 주문 완전히 새로운거

import ManageorderRegister from 'pages/ORDER/1order/manageorderRegisterTab'; // 주문등록
import SsRegit from 'pages/ORDER/2ss/ssRegitTab';

// 테스트
import DriverDeliveryClosing from 'pages/DELIVERY/2delivery/driverDeliveryClosing';
import DeliveryMagam from 'pages/DELIVERY/2delivery/dailyMagamTab';
import DeliveryScheduleUnited from 'pages/DELIVERY/2delivery/deliveryScheduleTab';
import PickingStrategy2 from 'pages/ETC/2center/_hide_pickingStrategy';

export const Router = () => {
  return (
    <Routes>
      {/* 기본 LOGIN / HOME */}
      <Route path="/" element={<HOME />} />
      <Route path="/login" element={<Login />} />
      {/* 외부 접근 페이지 */}
      <Route path="/logis" element={<Logis />} />
      <Route path="/logistics" element={<POrderTracking />} />
      <Route path="/mordertracking3" element={<OrderTracking3 />} />
      {/* REGIT 생성 페이지 */}
      <Route path="/modificationDefRegit" element={<ModificationDERegister />} />
      <Route path="/modificationAvaRegit" element={<ModificationAVRegister />} />
      <Route path="/moveActionRegit" element={<INVMOVEREGIT />} />
      <Route path="/modelMoveRegit" element={<RegisterManageInventoryMove />} />
      <Route path="/manageorderRegit" element={<OrderRegit />} />
      <Route path="/inventoryInspectRegit" element={<InventoryInvestigateRegit />} />

      {/* 이노스용 */}
      {/* [통관목록조회] */}
      <Route path="/cargoManifestList" element={<CargoManifest />} />
      <Route path="/cargoManifestAccidentList" element={<CargoManifestAccident />} />
      <Route path="/cargoManifestTransferList" element={<CargoManifestTransfer />} />
      {/* [택배] */}
      <Route path="/manageParcelServiceList" element={<ParcelServiceList />} />
      <Route path="/manageParcelTaskList" element={<ParcelTaskList />} />
      <Route path="/manageParcelLinehaulList" element={<ParcelLinehaul />} />
      <Route path="/manageParcelLoadList" element={<ParcelLoading />} />
      <Route path="/manageParcelUnloadList" element={<ParcelLoading />} />
      <Route path="/manageParcelRemain" element={<ParcelRemain />} />
      <Route path="/manageParcelRevisit" element={<ParcelRevisit />} />
      <Route path="/manageParcelCalculateFare" element={<ParcelCalculateFare />} />
      <Route path="/manageParcelNumber" element={<ParcelNumber />} />
      <Route path="/manageParcelNumberPrint" element={<ParcelNumberPrint />} />
      <Route path="/manageParcelAccident" element={<ParcelAccident />} />
      <Route path="/manageParcelFeeList" element={<ParcelFee />} />
      <Route path="/manageParcelFeeLoan" element={<ParcelLoan />} />
      <Route path="/manageParcelFeeChangeFare" element={<ParcelChangeFare />} />
      <Route path="/manageParcelTrackingReport" element={<ParcelTracking />} />
      {/**** 1.입고 */}
      {/* 1-1. 발주 */}
      <Route path="/purchaseOrderAdmin" element={<PurchaseOrder />} />
      <Route path="/purchaseRegit" element={<PurchaseRegit />} />
      <Route path="/placeOrderRegister" element={<PlaceOrderRegister />} />
      {/* 1-2. 간선 */}
      <Route path="/trunkReceiving" element={<TrunkReceiving />} />
      {/* 1-3. 기사회수 */}
      <Route path="/collectReceiving" element={<CollectReceiving />} />
      {/* 1-4. 로케이션 */}
      <Route path="/inventoryLocationSelect" element={<InventoryLocationSelect2 />} />
      {/* 1-5. 구매입고 */}
      <Route path="/purchaseIn" element={<PurchaseIn />} />
      {/**** 2.출고 */}
      {/* 2-1. 간선 */}
      <Route path="/trunkWorks" element={<GansunWorks />} />
      <Route path="/trunkRegister" element={<TrunkRegister />} />
      <Route path="/trunkRegit" element={<TrunkRegit />} />
      <Route path="/takeoutRegit" element={<TakeoutRegit />} />
      {/* 2-2. 반출 */}
      <Route path="/takeoutWorks" element={<TakeoutWorks />} />
      <Route path="/storeTakeOutPickingInstructionRegister" element={<StoreTakeOutPickingInstructionRegister />} />
      <Route path="/TakeOutPickingInstructionRegister" element={<TakeOutPickingInstructionRegister />} />
      <Route path="/stockRemoveParcel" element={<InventoryReturnManageMent />} />
      <Route path="/wasteAppliances" element={<WasteAppliances />} />
      {/* 2-3. 납품 */}
      <Route path="/deliveryWorks" element={<DeliveryWorks />} />
      {/* 2-4. 기사 */}
      {/* 프린트 */}
      <Route path="/deliveryorder" element={<DeliveryOrder />} />
      <Route path="/deliveryorderLite" element={<DeliveryOrderLite />} />
      <Route path="/fcpicking" element={<FCPicking />} />
      <Route path="/createfcpicking" element={<CreateFCPicking />} />
      {/* 2-5. 배차 */}
      <Route path="/distributingCar" element={<BaeChaList2 />} />
      {/**** 3.재고 */}
      {/* 3-1. 재고 */}
      <Route path="/inventoryReports" element={<InventoryReportUnited />} />
      <Route path="/inventoryInvestigate" element={<InventoryInvestigate />} />
      <Route path="/InventoryTracking" element={<InventoryTracking />} />
      {/* 재고조정 */}
      <Route path="/modifications" element={<Modifications />} />
      <Route path="/modelMoveList" element={<ManageInventoryMove />} />
      {/* 3-2. 로케이션 */}
      <Route path="/moveActionList" element={<INVMOVELIST />} />
      <Route path="/inventoryInOutList" element={<InventoryInOutList />} />
      <Route path="/inventoryLocation" element={<LocationStatics />} />
      {/* 3-3. 제품/상품 */}
      <Route path="/modelList" element={<ModelList />} />
      <Route path="/productList" element={<ProductList />} />
      <Route path="/barcodePrinting" element={<BarcodePrinting />} />
      <Route path="/refurRegister" element={<RefurRegister />} />
      <Route path="/refurRegit" element={<RefurRegit />} />
      <Route path="/refur" element={<RefurList />} />
      <Route path="/inventoryInspect" element={<InventoryInspect />} />
      <Route path="/scmManagement" element={<SCMmanagement />} />
      {/**** 4.주문 */}
      {/* 4-1. 주문 */}
      <Route path="/OrderUploadForm" element={<OrderUploadForm />} />
      <Route path="/manageorderInfoList" element={<OrderList />} />
      <Route path="/manageService" element={<ManageService />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/manageOrderHistory" element={<OrderHistory />} />
      <Route path="/manageParcelList" element={<ParcelList />} />
      <Route path="/manageRelocationorderInfoList" element={<ManageRelocationorderInfoList />} />
      <Route path="/manageorderRegister" element={<ManageorderRegister />} />
      <Route path="/MessageSendManange" element={<MessageSendManage />} />

      {/* 4-2. 판매 */}
      <Route path="/ssList" element={<SsRegit />} />
      <Route path="/ssRegit" element={<SsRegit />} />

      {/**** 5.배송 */}
      {/* 5-1. 상담 */}
      <Route path="/customerCs" element={<CustomerCS />} />
      <Route path="/driverCs" element={<DriverCs />} />
      {/* 5-2. 배송 */}
      <Route path="/customAlertMsg" element={<CustomAlertMsg />} />
      <Route path="/dailyClosing" element={<DeliveryMagam />} />
      {/* 물류대장용(내부-메뉴 ) */}
      <Route path="/orderTracking" element={<OrderTracking />} />
      <Route path="/manageParcelTracking" element={<OrderTracking />} />
      {/* 외부트래킹용  */}
      <Route path="/manageLazyorderList" element={<ManageLazyorderList />} />
      {/**** 7.정산 */}
      {/* 7-1. 정산 */}
      <Route path="/headquarters" element={<BasicFee />} />
      <Route path="/subcontract" element={<BasicFee />} />
      <Route path="/center" element={<BasicFee />} />
      <Route path="/driver" element={<BasicFee />} />
      {/* 주문영수증 */}
      <Route path="/OrderReceiptheadquaters" element={<OrderReceipt />} />
      <Route path="/OrderReceiptSubcontract" element={<OrderReceipt />} />
      <Route path="/OrderReceiptCenter" element={<OrderReceipt />} />
      <Route path="/OrderReceiptDriverteam" element={<OrderReceipt />} />
      {/* 거래명세서 */}
      <Route path="/transactionStatheadquaters" element={<TransactionStatement />} />
      <Route path="/transactionStatSubcontract" element={<TransactionStatement />} />
      <Route path="/transactionStatCenter" element={<TransactionStatement />} />
      <Route path="/transactionStatDriverteam" element={<TransactionStatement />} />
      <Route path="/transactionRegister/:from/:seq" element={<TransactionRegister />} />
      {/**** 7.KPI */}
      {/* 7-1. KPI */}
      <Route path="/kpis" element={<KPI />} />
      <Route path="/kpibycapa" element={<KPIbyCapa />} />
      {/**** 9.기타 */}
      {/* 9-1. 기사관리 */}
      <Route path="/driverList" element={<DriverList />} />
      <Route path="/deliverySector" element={<DeliverySector />} />
      {/* 9-2. 창고 */}
      <Route path="/inventoryList" element={<WarehouseList />} />
      <Route path="/centerLocation" element={<WarehouseLocation />} />
      {/* 9-3. 사용자 */}
      <Route path="/manageUserInGroup" element={<ManageUserInGroupTab />} />
      <Route path="/managerLD" element={<ManageLD />} />
      <Route path="/inuserList" element={<User />} />
      <Route path="/salesChannel" element={<SalesChannel />} />
      <Route path="/partnerList" element={<PartnerList />} />
      <Route path="/infraGroupList" element={<InfraGroupList />} />
      {/* 9-4. 설정 */}
      <Route path="/settings" element={<Settings />} />
      <Route path="/settingList" element={<SettingsList />} />
      {/* 안쓰는것? */}
      <Route path="/excelSinglePage" element={<ExcelSinglePage />} />
      <Route path="/driverDeliveryClosing" element={<DriverDeliveryClosing />} />
      {/* unitedTab으로 묶은것 */}
      <Route path="/manageDeliverySchedules" element={<DeliveryScheduleUnited />} />
      {/* 리팩토링 */}
      <Route path="/systemAnnouncement" element={<SystemAnnouncement />} />
      <Route path="/PickingStrategy" element={<PickingStrategy2 />} />
      {/* fallback url */}
      <Route path="*" element={<></>} />
    </Routes>
  );
};

export default Router;
