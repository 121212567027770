// react
import { useEffect, useRef, useState, useMemo } from 'react';

// lib
import { v4 as uuidv4 } from 'uuid';
import { WMS_LINEHAUL_BEFORE_LOADING_TEMP, WMS_LINEHAUL_BEFORE_LOADING_DETAIL, WMS_LINEHAUL_BEFORE_LOADING, WMS_LINEHAUL_BEFORE_LOADING_EXCEL } from 'envVar';

// utils
import { selectlabel, selectvalue } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';

// component
import Presenter from './presenter';
import { setLoading } from 'redux/services/menuSlice';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { serviceStore } from 'services/services';

const Container = (props) => {
  const dispatch = useDispatch();

  //그리드
  const [rows, setRows] = useState([]);
  const [gridId, setGridId] = useState();
  const inventoryModalGridId = 'trunkShippingInstruction_inventoryModal';
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  const [photoPopupObj, setPhotoPopupObj] = useState({
    visible: false,
    item: null,
    photos: null,
    fetchPhotosFn: (data) => fetchPhotos(data),
  });
  //옵션
  const { masterOptions } = useSelector((state) => state.menu);
  const { reduxUserInfo } = useSelector((state) => state.auth);

  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  //검색자
  const [searchObj, setSearchObj] = useState({
    linehaulSeq: null,
    originalCenterCode: null,
    destinationCenterCode: null,
    linehaulType: null,
    status: null,
    dateType: null,
    fromDate: null,
    toDate: null,
    ...defaultSearchFilter,
  });

  //페이징
  const [pagingSetting, setPagingSetting] = useState({
    pageNo: 1,
    pageSize: 100,
    fetchNew: false,
  });

  //바텀시트
  const [bottomSheetObj, setBottomSheetObj] = useState();

  //알랏 모달
  const [alertModalObj, setAlertModalObj] = useState({ visible: false });

  //할당정보 모달
  const [inventoryModalObj, setInventoryModalObj] = useState({
    visible: false,
  });
  const [inventoryCancelModalObj, setInventoryCancelModalObj] = useState({
    visible: false,
  });

  useEffect(() => {
    setGridId(`trunkShippingInstruction_Main${uuidv4()}`);
  }, []);

  const paramingSearchObjToUrl = (url, searchObj) => {
    const searchParams = Object.keys(searchObj);

    let urlWithSearchParams = url;
    let dateType = searchObj['dateType'];
    for (let param of searchParams) {
      if (searchObj[param] !== null && searchObj[param] !== '' && param !== 'fromDate' && param !== 'toDate')
        param === 'dateType' && searchObj.fromDate !== null
          ? (urlWithSearchParams += `&from${dateType}=${searchObj['fromDate']}&to${dateType}=${searchObj['toDate']}`)
          : (urlWithSearchParams += `&${param}=${searchObj[param]}`);
    }

    return urlWithSearchParams;
  };

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'LINEHAUL_SHIPPING_CONFIRM') {
        handleTrunkShippingConfirm(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'LINEHAUL_SHIPPING_COMPLETE') {
        handleTrunkShippingComplete(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'LINEHAUL_SHIPPING_MERGE') {
        handleTrunkShippingMerge(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'LINEHAUL_SHIPPING_ROLLBACK') {
        handleLinehaulRollBack(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'LINEHAUL_SHIPPING_CANCEL') {
        handleLinehaulCancel(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'trunkShipping-modal-confirm') {
        handleModalConfirmAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'trunkShipping-modal-rollback') {
        handleModalRollBackAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'trunkShipping-modal-cancel') {
        handleModalCancelAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'TRUNK_CHULGO_CLOSE') {
        chulgoCloseAction(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const chulgoCloseAction = async (items) => {
    if (window.confirm('강제마감 하시겠습니까?')) {
      const rs = await serviceStore.warehouseAction(
        'linehaul/loading/close',
        'POST',
        null,
        items?.map((ele) => {
          return {
            seq: ele.loadingStatementSeq,
          };
        }),
      );
      if (rs?.status === 200) {
        alert('강제마감 완료되었습니다!');
        fetchList(searchObj);
      }
    }
  };

  const [trunkPrintObj, setTrunkPrintObj] = useState();
  const printAction = async (event) => {
    setTrunkPrintObj({
      visible: true,
      type: 'chulgo',
      item: event?.item,
    });
  };

  const fetchList = async (searchObj) => {
    console.log(searchObj);
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) {
      setBottomSheetObj(null);
    }

    let url = WMS_LINEHAUL_BEFORE_LOADING_TEMP + `?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
    const search = paramingSearchObjToUrl(url, searchObj);

    httpClient
      .get(search)
      .then((res) => {
        dispatch(setLoading(null));
        const data = res.data.list || [];
        const maxPage = Math.ceil(res.data.totalCount / pagingSetting?.pageSize);
        data.forEach((row) => {
          row['originalCenterCodeKr'] = selectlabel(row.originalCenterCode, masterOptions?.['CENTER']);
          row['destinationCenterCodeKr'] = selectlabel(row.destinationCenterCode, masterOptions?.['CENTER']);
          row['linehaulTypeKr'] = selectlabel(row.linehaulType, masterOptions?.['LINEHAUL_TYPE']);
          row['statusKr'] = row.status ? selectlabel(row.status, masterOptions?.['LOADING_STAT']) : '';
          row['shippingBill'] = '간선작업지시서';
          row.searchObj = searchObj;
          row.maxPage = maxPage;
          row.totalCount = res.data.totalCount;
        });

        setRows(data);
      })
      .catch(async (err) => {
        dispatch(setLoading(null));
      });
  };

  const bottomGridRef = useRef();
  const fetchDetailList = async (event) => {
    dispatch(setLoading('GET'));

    let url = WMS_LINEHAUL_BEFORE_LOADING_DETAIL + '?';
    if (event.item.linehaulSeq) url += `linehaulSeqs=${event.item.linehaulSeq}`;
    if (event.item.loadingStatementSeq) url += `&loadingStatementSeq=${event.item.loadingStatementSeq}`;
    if (event.item.customerOrderSeq) url += `&customerOrderSeq=${event.item.customerOrderSeq}`;
    if (event.item.customerOrderInvoiceSeq) url += `&customerOrderInvoiceSeq=${event.item.customerOrderInvoiceSeq}`;

    const res = await httpClient.get(url);
    const rows = res.data || [];
    rows?.forEach((row) => {
      row['modelGroupKr'] = selectlabel(row.modelGroupLarge, masterOptions?.['MODEL_LARGE']);
      row['partnerSeqKr'] = selectlabel(row.partnerSeq, masterOptions?.['SELLER']);
      row['statusKr'] = row.status ? selectlabel(row.status, masterOptions?.['LOADING_STAT']) : '';
      row['linehaulType'] = event.item.linehaulType;
      row['linehaulDate'] = event.item.linehaulDate;
      row.originalCenterCode = event.item.originalCenterCode;
      row.destinationCenterCode = event.item.destinationCenterCode;
    });

    const gridButtonhandler = (e) => {
      const id = e.target.id;
      const items = bottomGridRef?.current?.getCheckedRowItemsAll();
      if (id === 'excel-down') {
        bottomGridRef?.current?.exportAsXlsx({ fileName: `간선출고지시_상세` });
      } else {
        if (items?.length > 0) {
          if (id === 'trunkShipping-detail-picking') {
            handleTrunkShippingConfirmDetail(items);
          } else if (id === 'trunkShipping-detail-confirm') {
            handleTrunkShippingCompleteDetail(items);
          }
        } else {
          alert('선택된 건이 없습니다!');
        }
      }
    };

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '간선출고지시 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          buttons: [
            <div key={`trunkShipping-detail-picking`} id={`trunkShipping-detail-picking`} className={`orange`} onClick={gridButtonhandler}>
              부분출고지시 확정
            </div>,
            <div key={`trunkShipping-detail-confirm`} id={`trunkShipping-detail-confirm`} className={`orange`} onClick={gridButtonhandler}>
              간선출고상세 완료
            </div>,
            <div key={`excel-down`} id={`excel-down`} className={`green`} onClick={gridButtonhandler}>
              엑셀다운
            </div>,
          ],
          options: {
            isRowAllCheckCurrentView: true,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          },
        },
      ],
    });
    dispatch(setLoading(null));
  };

  // NOTE: 할당정보 모달 호출 시 사용되는 기준 정보
  const [currentHaldangItem, setCurrentHaldangItem] = useState(null);

  // NOTE: 할당정보 모달 액션
  const haldangAction = (event) => {
    setCurrentHaldangItem(event.item);
    setInventoryModalObj({
      ...inventoryModalObj,
      visible: true,
      origin: event.item,
    });
  };

  // NOTE: 할당정보 액션 변경 시 searchObj가 업데이트된 경우, 할당정보 모달이 열려있을 때 정보를 갱신할 수 있도록 effect hook 적용.
  useEffect(() => {
    if (inventoryModalObj?.visible) {
      setInventoryModalObj(() => {
        return {
          ...inventoryModalObj,
          origin: { ...currentHaldangItem },
        };
      });
    }
  }, [searchObj]);

  const handleTrunkShippingConfirm = async (event) => {
    if (window.confirm('간선출고 지시확정을 하시겠습니까?')) {
      dispatch(setLoading('POST'));
      const payloadObj = {
        linehaulSeqs: event.map((a) => {
          return a.linehaulSeq;
        }),
      };
      const rs = await serviceStore?.warehouseAction(`linehaul/loading/web/list`, 'POST', null, payloadObj);
      if (rs?.status === 200) {
        alert('확정되었습니다.');
        fetchList(searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  const handleTrunkShippingComplete = async (data) => {
    if (window.confirm('간선출고를 완료하시겠습니까?')) {
      const set = new Set(data.map((item, i) => item.loadingStatementSeq));
      if (set.size === 1) {
        dispatch(setLoading('PUT'));
        const rs = await serviceStore?.warehouseAction(`linehaul/loading/complete/${data[0].loadingStatementSeq}`, 'PUT', null);
        if (rs?.status === 200) {
          alert(`출고지시번호 ${data[0].loadingStatementSeq} 건 간선출고지시 완료 하였습니다.`);
          fetchList(searchObj);
        }
        dispatch(setLoading(null));
      } else {
        alert('한 건씩만 처리 가능합니다.');
      }
    }
  };

  const handleTrunkShippingMerge = async (data) => {
    const payloadArr = [];
    for (let i = 0; i < data.length; i++) {
      const obj = {};
      obj['loadingStatementSeq'] = data[i].loadingStatementSeq;
      obj['originalCenterCode'] = data[i].originalCenterCode;
      obj['destinationCenterCode'] = data[i].destinationCenterCode;
      payloadArr.push(obj);
    }
    const rs = await serviceStore?.warehouseAction(`linehaul/loading/merge`, 'POST', null, payloadArr);
    if (rs?.status === 200) {
      alert('병합되었습니다.');
      fetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  const handleTrunkShippingConfirmDetail = async (data) => {
    const linehaulSeqs = data.map((ele) => ele.linehaulSeq);
    const linehaulDetailSeqs = data.map((ele) => ele.linehaulDetailSeq);

    const payloadObj = {
      linehaulSeqs: linehaulSeqs,
      linehaulDetailSeqs: linehaulDetailSeqs,
      originalCenterCode: data[0].originalCenterCode,
      destinationCenterCode: data[0].destinationCenterCode,
    };

    dispatch(setLoading('POST'));
    const rs = await serviceStore?.warehouseAction(`linehaul/loading/web`, 'POST', null, payloadObj);
    if (rs?.status === 200) {
      alert('확정되었습니다.');
      fetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  const handleTrunkShippingCompleteDetail = async (data) => {
    const payloadObj = {
      loadingStatementDetailSeqs: data.map((ele) => {
        return ele.loadingStatementDetailSeq;
      }),
    };
    dispatch(setLoading('POST'));
    const rs = await serviceStore?.warehouseAction(`linehaul/loading/complete/${data?.[0]?.loadingStatementSeq}/detail`, 'POST', null, payloadObj);
    if (rs?.status === 200) {
      alert('간선출고상세에 대한 해당건들 완료하였습니다.');
      fetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  const handleLinehaulRollBack = async (items = []) => {
    const statusKr = items.map((a) => a.statusKr);
    const linehaulSeqs = JSON.stringify(items.map((a) => a.linehaulSeq));
    const linehaulStatus = statusKr[0];
    let duplicate = true;
    for (let i = 0; i < statusKr.length; i++) {
      const currElem = statusKr[i];

      for (let j = i + 1; j < statusKr.length; j++) {
        if (currElem !== statusKr[j]) {
          duplicate = false;
        }
      }
    }
    const validArr = ['출고지시대기', '출고지시확정', '출고중', '출고완료'];
    if (duplicate === true && validArr.includes(linehaulStatus)) {
      const rollBackData = [];
      for (let i = 0; i < items.length; i++) {
        rollBackData.push({
          linehaulSeq: items[i].linehaulSeq,
          loadingStatementSeq: items[i].loadingStatementSeq,
          inventorySeqs: items[i].inventories?.map((a, idx) => a.inventorySeq),
        });
      }
      setAlertModalObj({
        ...alertModalObj,
        visible: true,
        type: 'rollBack',
        linehaulStatus: linehaulStatus,
        linehaulSeqs: linehaulSeqs,
        rollBackData: rollBackData,
        searchObj,
        fetchFn: (searchObj) => fetchList(searchObj),
      });
    } else if (duplicate === false) {
      alert('진행상태가 서로 다른 건들이 선택되었습니다. \n 취소하시려면 진행상태가 같은건끼리만 선택가능합니다.');
    } else if (duplicate === true && !validArr.includes(linehaulStatus)) {
      alert('이전단계로 돌아 갈수 없는 상태입니다');
    }
  };

  const handleLinehaulCancel = async (items = []) => {
    const statusKr = items.map((a) => a.statusKr);
    const linehaulSeqs = JSON.stringify(items.map((a) => a.linehaulSeq));
    const linehaulStatus = statusKr[0];
    let duplicate = true;
    for (let i = 0; i < statusKr.length; i++) {
      const currElem = statusKr[i];

      for (let j = i + 1; j < statusKr.length; j++) {
        if (currElem !== statusKr[j]) {
          duplicate = false;
        }
      }
    }
    const validArr = ['출고지시대기', '출고지시확정', '출고중', '출고완료'];
    if (duplicate === true && validArr.includes(linehaulStatus)) {
      const cancelData = [];
      for (let i = 0; i < items.length; i++) {
        cancelData.push({
          linehaulSeq: items[i].linehaulSeq,
          loadingStatementSeq: items[i].loadingStatementSeq,
          originalCenterCode: items[i].originalCenterCode,
          destinationCenterCode: items[i].destinationCenterCode,
          linehaulDate: items[i].linehaulDate,
          linehaulType: items[i].linehaulType,
          inventorySeqs: items[i].inventories?.map((a, idx) => a.inventorySeq),
        });
      }
      setAlertModalObj({
        ...alertModalObj,
        visible: true,
        type: 'cancel',
        linehaulStatus: linehaulStatus,
        linehaulSeqs: linehaulSeqs,
        cancelData: cancelData,
      });
    } else if (duplicate === false) {
      alert('진행상태가 서로 다른 건들이 선택되었습니다. \n 취소하시려면 진행상태가 같은건끼리만 선택가능합니다.');
    } else if (duplicate === true && !validArr.includes(linehaulStatus)) {
      alert('취소할 수 없는 상태입니다.');
    }
  };

  const handleModalConfirmAction = async (data) => {
    dispatch(setLoading('PUT'));
    const payloadObj = {
      linehaulSeq: data[0].linehaulSeq,
      loadingStatementSeq: data[0].loadingStatementSeq,
      linehaulType: data[0].linehaulType,
      originalCenterCode: selectvalue(data[0].originalCenterCode, masterOptions?.['CENTER_WHOLE_WITH_CLOSED']),
      destinationCenterCode: selectvalue(data[0].destinationCenterCode, masterOptions?.['CENTER_WHOLE_WITH_CLOSED']),
      linehaulDate: data[0].linehaulDate,
      inventorySeqs: data.map((ele) => {
        return ele.inventorySeq;
      }),
    };
    const rs = await serviceStore.warehouseAction(`linehaul/picking/detail/inventory`, 'POST', null, payloadObj);
    if (rs?.status === 200) {
      alert('출고지시 확정되었습니다.'); //이전 : 부분출고지시 확정되었습니다.
      fetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  const handleModalCancelAction = async (data) => {
    const inventorySeqs = data.map((a) => a.inventorySeq);
    const cancelData = {
      linehaulSeq: data[0].linehaulSeq,
      loadingStatementSeq: data[0].loadingStatementSeq,
      originalCenterCode: data[0].originalCenterCode,
      destinationCenterCode: data[0].destinationCenterCode,
      linehaulType: data[0].linehaulType,
      linehaulDate: data[0].linehaulDate,
    };

    setInventoryCancelModalObj({
      visible: true,
      cancelData: cancelData,
      inventorySeqs: inventorySeqs,
    });
  };

  const handleModalRollBackAction = async (data) => {
    dispatch(setLoading('PUT'));
    const inventorySeqs = data.map((a) => a.inventorySeq);
    const payloadObj = {
      linehaulSeq: data[0].linehaulSeq,
      loadingStatementSeq: data[0].loadingStatementSeq,
      loadingOutSeq: data[0].loadingOutSeq,
      transportStatementSeq: data[0].transportStatementSeq,
      inventorySeqs: inventorySeqs,
    };
    const rs = await serviceStore?.warehouseAction(`linehaul/beforeLoading/rollback`, 'POST', null, [payloadObj]);
    if (rs?.status === 200) {
      alert('선택건 이전단계로 변경 완료되었습니다.');
      fetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  const downloadExcel = async (str) => {
    let url;
    if (str === 'main') {
      url = WMS_LINEHAUL_BEFORE_LOADING + `?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
      const search = paramingSearchObjToUrl(url, searchObj);

      return httpClient.get(search).then((res) => {
        dispatch(setLoading(null));
        const data = res.data || [];
        data.forEach((row) => {
          row['originalCenterCodeKr'] = selectlabel(row.originalCenterCode, masterOptions?.['CENTER_WHOLE_WITH_CLOSED']);
          row['destinationCenterCodeKr'] = selectlabel(row.destinationCenterCode, masterOptions?.['CENTER_WHOLE_WITH_CLOSED']);
          row['linehaulTypeKr'] = selectlabel(row.linehaulType, masterOptions?.['LINEHAUL_TYPE']);
          row['statusKr'] = row.status ? selectlabel(row.status, masterOptions?.['LOADING_STAT']) : '';
          row['shippingBill'] = '간선작업지시서';
          row['modelGroupKr'] = selectlabel(row.modelGroupLarge, masterOptions?.['MODEL_LARGE']);
          row['partnerSeqKr'] = selectlabel(row.partnerSeq, masterOptions?.['SELLER']);
          row['statusKr'] = row.status ? selectlabel(row.status, masterOptions?.['LOADING_STAT']) : '';
        });
        return data;
      });
    } else if (str === 'detail') {
      url = WMS_LINEHAUL_BEFORE_LOADING_EXCEL + `?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
      const search = paramingSearchObjToUrl(url, searchObj);

      return httpClient.get(search).then((res) => {
        dispatch(setLoading(null));
        const data = res.data || [];
        data.forEach((row) => {
          row['originalCenterCodeKr'] = selectlabel(row.originalCenterCode, masterOptions?.['CENTER_LABEL']);
          row['destinationCenterCodeKr'] = selectlabel(row.destinationCenterCode, masterOptions?.['CENTER_LABEL']);
          row['linehaulTypeKr'] = selectlabel(row.linehaulType, masterOptions?.['LINEHAUL_TYPE']);
          row['statusKr'] = row.status ? selectlabel(row.status, masterOptions?.['LOADING_STAT']) : '';
          row['shippingBill'] = '간선작업지시서';
          row['modelGroupKr'] = selectlabel(row.modelGroupLarge, masterOptions?.['MODEL_LARGE']);
          row['partnerSeqKr'] = selectlabel(row.partnerSeq, masterOptions?.['SELLER']);
          row['statusKr'] = row.status ? selectlabel(row.status, masterOptions?.['LOADING_STAT']) : '';
        });
        return data;
      });
    }
  };

  const fetchPhotos = async (item) => {
    if (item.loadingStatementSeq) {
      const rs = await serviceStore.warehouseAction(`linehaul/loadingStatement/image/${item.loadingStatementSeq}`);
      if (rs?.status === 200) {
        setPhotoPopupObj((prev) => {
          return {
            ...prev,
            visible: true,
            item: item,
            photos: rs.data,
          };
        });
      }
    } else {
      alert('출고 지시번호가 존재하는 건만 사진열람이 가능합니다');
    }
  };

  const columns = [
    {
      headerText: '상세보기',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '상세보기',
        onClick: fetchDetailList,
      },
    },
    {
      headerText: '사진보기',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '사진보기',
        onClick: (e) => fetchPhotos(e.item),
      },
    },
    {
      headerText: '출고지시서',
      width: 80,
      dataField: 'shippingBill',
      renderer: {
        type: 'ButtonRenderer',
        labelText: '프린트',
        onClick: printAction,
        visibleFunction: function (rowIndex, columnIndex, value, item, dataField) {
          if (item.loadingStatementSeq === null) {
            return false;
          }
          return true;
        },
      },
    },
    {
      headerText: '할당정보',
      width: 80,
      dataField: 'modal',
      renderer: {
        type: 'ButtonRenderer',
        labelText: '할당정보',
        onClick: haldangAction,
      },
    },
    {
      headerText: '피킹지시번호',
      dataField: 'linehaulSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고지시번호',
      dataField: 'loadingStatementSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고예정일',
      dataField: 'linehaulDate',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출발창고',
      dataField: 'originalCenterCodeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '도착창고',
      dataField: 'destinationCenterCodeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '간선유형',
      dataField: 'linehaulTypeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고지시 수량(피킹완료수량)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '간선출고완료수량',
      children: [
        {
          headerText: '양품',
          dataField: 'loadAvailableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'loadDefectedQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'loadEtcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '미출고 수량(지시 - 완료)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedDiff',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcDiff',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '출고지시<br />확정일',
      dataField: 'initDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고지시<br />확정자',
      dataField: 'confirmId',
      filter: {
        showIcon: true,
      },
    },
  ];

  const detailColumns = [
    {
      headerText: '피킹지시번호',
      dataField: 'linehaulSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고지시번호',
      dataField: 'loadingStatementSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '바코드',
          dataField: 'barcode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          filter: {
            showIcon: true,
          },
        },
      ],
    },

    {
      headerText: '출고지시 수량(피킹완료수량)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '간선출고완료수량',
      children: [
        {
          headerText: '양품',
          dataField: 'loadAvailableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'loadDefectedQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'loadEtcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '미출고 수량(지시 - 완료)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedDiff',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcDiff',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고완료일',
      dataField: 'loadDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고완료자',
      dataField: 'loadId',
      filter: {
        showIcon: true,
      },
    },
  ];

  const ExcelUploadFunction = async (data) => {
    const rs = await serviceStore?.warehouseAction(`inehaul/loading/serial/number`, 'POST', null, data);
    if (rs.status === 200) {
      alert('간선 시리얼번호 상차 처리에 성공하였습니다!');
      fetchList(searchObj);
    }
  };

  return (
    <Presenter
      //그리드
      rows={rows}
      columns={columns}
      gridId={gridId}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      //함수
      fetchList={fetchList}
      //
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      pagingSetting={pagingSetting}
      setPagingSetting={setPagingSetting}
      //alertModal
      alertModalObj={alertModalObj}
      setAlertModalObj={setAlertModalObj}
      //inventoryModal
      inventoryModalObj={inventoryModalObj}
      setInventoryModalObj={setInventoryModalObj}
      inventoryCancelModalObj={inventoryCancelModalObj}
      setInventoryCancelModalObj={setInventoryCancelModalObj}
      //bottomSheet
      bottomSheetObj={bottomSheetObj}
      setBottomSheetObj={setBottomSheetObj}
      //엑셀
      downloadExcel={downloadExcel}
      ExcelUploadFunction={ExcelUploadFunction}
      //
      photoPopupObj={photoPopupObj}
      setPhotoPopupObj={setPhotoPopupObj}
      trunkPrintObj={trunkPrintObj}
      setTrunkPrintObj={setTrunkPrintObj}
      // 할당모달그리드ID
      inventoryModalGridId={inventoryModalGridId}
    />
  );
};

export default Container;
