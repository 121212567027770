import styled from 'styled-components';

type SpacingPropsType = {
  $direction: 'V' | 'H';
  $value: number;
};

export const LDSSpacing = styled.div<SpacingPropsType>`
  margin-top: ${(props) => (props.$direction === 'V' ? props.$value : 0)}px;
  margin-left: ${(props) => (props.$direction === 'H' ? props.$value : 0)}px;
`;
