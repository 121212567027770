import { OptionItem } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OrderSearchDTO, OrderSSListDTO } from 'pages/ORDER/2ss/_interface/order';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';

const translator = {
  title: {
    confirm: '배차확정',
  },
};

export interface IPopupObj {
  visible: boolean;
  type: 'confirm';
  items?: OrderSSListDTO[];
  searchObj?: OrderSearchDTO;
  fetchFn?: (searchObj: OrderSearchDTO) => void;
}

export const Popup = ({ popupObj, setPopupObj }) => {
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [createObj, setCreateObj] = useState<any>();
  const actionHandler = () => {
    //
  };
  return (
    <CommonModalNew
      title={translator?.title?.[popupObj?.type]}
      visible={popupObj?.visible}
      setVisible={() => {
        setPopupObj(null);
      }}
      style={{ width: 320 }}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={() => {
              actionHandler();
            }}
          >
            확정
          </button>
        </>
      }
      children={
        <>
          <Row>
            <Col style={{ textAlign: 'center', height: 50 }}>
              <div>
                총 건수 : <span className="b">{popupObj?.items?.length}</span>
              </div>
              <div>제품수 :</div>
              <div>CBM :</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">창고선택</label>
              <SelectD
                value={
                  createObj?.centerCode
                    ? {
                        value: createObj?.centerCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[createObj?.centerCode],
                      }
                    : null
                }
                options={MASTER_OPS?.CENTER_AUTH}
                onChange={(option) => {
                  setCreateObj({
                    ...createObj,
                    centerCode: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">차량정보</label>
              <SelectD
                value={
                  createObj?.centerCode
                    ? {
                        value: createObj?.centerCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[createObj?.centerCode],
                      }
                    : null
                }
                options={MASTER_OPS?.CENTER_AUTH}
                onChange={(option) => {
                  setCreateObj({
                    ...createObj,
                    centerCode: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">운송기사 이름</label>
              <InputD
              // value={
              //   createObj?.centerCode
              //     ? {
              //         value: createObj?.centerCode,
              //         label: MASTER_OBJ?.CENTER_AUTH?.[createObj?.centerCode],
              //       }
              //     : null
              // }
              // onChange={(e) => {
              //   setCreateObj({
              //     ...createObj,
              //     centerCode: e.target.value,
              //   });
              // }}
              />
            </Col>
          </Row>{' '}
          <Row>
            <Col>
              <label className="col-form-label">운송기사 연락처</label>
              <InputD
              // value={
              //   createObj?.centerCode
              //     ? {
              //         value: createObj?.centerCode,
              //         label: MASTER_OBJ?.CENTER_AUTH?.[createObj?.centerCode],
              //       }
              //     : null
              // }
              // onChange={(e) => {
              //   setCreateObj({
              //     ...createObj,
              //     centerCode: e.target.value,
              //   });
              // }}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};
