// react
import { useEffect, useRef, useState, useMemo } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { SelectD } from 'components/reactstrap/reactstrap';

// lib
import { v4 as uuidv4 } from 'uuid';

// utils
import { httpClient } from 'common/http-client/axiosConfig';
import { INVENTORY_RETURN_ROLLBACK, TAKE_OUT_REMOVE_CANCEL, TAKE_OUT_PICKING, TAKE_OUT_PICKING_FINISH, TAKE_OUT_PICKING_COMPLETE, REMOVE_PICKING_MEMO_SAVE, TAKE_OUT_PICKING_PHOTO } from 'envVar';

// component
import { defaultAlertModal } from 'components/modal/_alertModal';
import { defaultBottomSheetObj } from 'components/template/bottomSheets';
import Presenter from './presenter';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { serviceStore } from 'services/services';

const Container = () => {
  const { masterOptions } = useSelector((state) => state.menu);
  const { reduxUserInfo } = useSelector((state) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);
  const dispatch = useDispatch();
  const [searchObj, setSearchObj] = useState({
    stockRemoveSeq: null,
    stockRemoveDate: [],
    dateSearchType: null,
    dateSearchValue: [],
    partnerSeq: null,
    centerCode: null,
    stockRemoveType: null,
    status: null,
    ...defaultSearchFilter,
  });

  const [gridId, setGrid] = useState();
  const [rows, setRows] = useState();
  const [bottomSheetObj, setBottomSheetObj] = useState(defaultBottomSheetObj);

  const [pagingSetting, setPagingSetting] = useState({
    pageNo: 1,
    pageSize: 100,
    fetchNew: false,
  });
  const [alertModalObj, setAlertModalObj] = useState(defaultAlertModal);
  const [changeCenterObj, setChangeCenterObj] = useState();
  const [haldangObj, setHaldangObj] = useState({
    visible: false,
    data: null,
    rowItems: null,
    'go-prev-step-parts': (data) => validGoPrevStep(data),
    'cancel-picking-parts': (data) => validCancelPicking(data),
    'confirm-picking-parts': (data) => confirmPickingParts(data),
  });
  const [locationChangingObj, setLocationChangingObj] = useState({
    visible: false,
    item: null,
    saveFn: (data) => saveLocation(data),
  });

  useEffect(() => {
    initGrid();
  }, []);

  const initGrid = () => {
    setGrid(`takeoutPicking_main${uuidv4()}`);
  };

  useEffect(() => {
    if (alertModalObj.visible === false) {
      setAlertModalObj(defaultAlertModal);
    }
  }, [alertModalObj.visible]);

  const paramingSearchObjToUrl = (url, searchObj) => {
    if (searchObj?.stockRemoveDate?.length === 2) {
      url += `&fromStockRemoveDate=${searchObj?.stockRemoveDate[0]}`;
      url += `&toStockRemoveDate=${searchObj?.stockRemoveDate[1]}`;
    }

    if (searchObj?.dateSearchValue?.length === 2 && searchObj?.dateSearchType)
      url += `&from${searchObj?.dateSearchType}=${searchObj?.dateSearchValue[0]}&to${searchObj?.dateSearchType}=${searchObj?.dateSearchValue[1]}`;

    const searchParams = Object.keys(searchObj);

    let urlWithSearchParams = url;
    for (let param of searchParams) {
      if (searchObj[param] !== null && searchObj[param] !== '')
        param === 'stockRemoveDate' || param === 'dateSearchValue' || param === 'dateSearchType' ? (urlWithSearchParams += '') : (urlWithSearchParams += `&${param}=${searchObj[param]}`);
    }

    return urlWithSearchParams;
  };

  const fetchList = async (searchObj) => {
    if (bottomSheetObj.visible) setBottomSheetObj(defaultBottomSheetObj);
    dispatch(setLoading('GET'));

    let url = `/warehouse/remove/picking/list/temp?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
    url = paramingSearchObjToUrl(url, searchObj);

    await httpClient.get(url).then((rs) => {
      const data = rs.data.list || [];
      const maxPage = Math.ceil(rs.data.totalCount / pagingSetting?.pageSize);
      if (rs?.status === 200) {
        data.forEach((row) => {
          row.maxPage = maxPage;
          row.totalCount = rs.data.totalCount;
          row.print = row.stockRemoveSeq ? '프린트' : '';
          row.detail = '상세보기';
          row.haldangInfo = '할당정보';
          row.searchObj = JSON.stringify(searchObj);
          row.statusKr = masterOptions?.REMOVE_STATUS_OBJ[row.status];
          row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
          row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER);
          row.stockRemoveType = row.stockRemoveType ? row.stockRemoveType : '';
          row.stockRemoveTypeKr = row.stockRemoveType ? masterOptions?.REMOVE_REASON_OBJ[row.stockRemoveType] : '';
        });
        setRows(data);
      }
    });
    dispatch(setLoading(null));
  };

  const [banchulPrintObj, setBanchulPrintObj] = useState();
  const printAction = async (event) => {
    setBanchulPrintObj({
      visible: true,
      type: 'picking',
      item: event?.item,
    });
  };

  const haldangAction = (event) => {
    const data = {
      originalCenterCode: event.item.centerCode,
      destinationCenterCode: event.item.target,
      stockRemoveDate: event.item.stockRemoveDate,
      partnerSeq: event.item.partnerSeq,
      stockRemoveType: event.item.stockRemoveType,
      locationCode: event.item.locationCode,
      centerCode: event.item.centerCode,
      target: event.item.target,
      modelStockSeq: event.item.modelStockSeq,
      status: event.item.status,
    };
    if (event.item.stockRemoveSeq) data['stockRemoveSeq'] = event.item.stockRemoveSeq; // 있으면

    setCurrentHaldangItem(data);
    setHaldangObj({
      ...haldangObj,
      visible: true,
      data,
      rowItems: event.item,
    });
  };

  // NOTE: 할당정보 모달 호출 시 사용되는 기준 정보
  const [currentHaldangItem, setCurrentHaldangItem] = useState(null);
  // NOTE: 할당정보 액션 변경 시 searchObj가 업데이트된 경우, 할당정보 모달이 열려있을 때 정보를 갱신할 수 있도록 effect hook 적용.
  useEffect(() => {
    if (haldangObj?.visible) {
      setHaldangObj(() => {
        return {
          ...haldangObj,
          data: { ...currentHaldangItem },
        };
      });
    }
  }, [searchObj]);

  const bottomGridRef = useRef();
  const fetchDetailList = async (event) => {
    dispatch(setLoading('GET'));
    let url = `/warehouse/remove/picking/detail?`;
    if (event.item?.stockRemoveSeq) url += `&stockRemoveSeq=${event.item?.stockRemoveSeq}`;
    if (event.item?.centerCode) url += `&centerCode=${event.item?.centerCode}`;
    if (event.item?.target) url += `&target=${event.item?.target}`;
    if (event.item?.stockRemoveDate) url += `&stockRemoveDate=${event.item?.stockRemoveDate}`;
    if (event.item?.partnerSeq) url += `&partnerSeq=${event.item?.partnerSeq}`;

    const rs = await httpClient.get(url);
    if (rs?.status !== 200) alert(rs?.data?.message ?? '오류가 발생했습니다.');

    const rows = rs.data;
    rows?.forEach((row) => {
      row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
      row.modelGroupKr = masterOptions?.MODEL_GRP_1_OBJ[row.modelGroupLarge];
      row.statusKr = masterOptions?.REMOVE_STATUS_OBJ[row.status];
    });

    const gridButtonhandler = (e) => {
      const id = e.target.id;
      const items = bottomGridRef?.current?.getCheckedRowItemsAll();
      if (id === 'excel-down') {
        bottomGridRef?.current?.exportAsXlsx({ fileName: `반출피킹지시_상세` });
      } else {
        if (items?.length > 0) {
          if (id === 'change-location') {
            const filtered = items?.filter((ele) => ele.statusKr !== '피킹지시대기');
            if (filtered?.length > 0) {
              alert('피킹지시대기상태에서만 가능합니다!');
            } else {
              if (items?.length > 1) {
                alert('한개씩 변경가능합니다!');
              } else {
                setLocationChangingObj((prev) => {
                  return {
                    ...prev,
                    item: items[0],
                    visible: true,
                    event,
                    searchObj,
                  };
                });
              }
            }
          }
        } else {
          alert('선택된 건이 없습니다!');
        }
      }
    };

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '반출피킹 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          buttons: [
            <div key={`change-location`} id={`change-location`} className={`orange`} onClick={gridButtonhandler}>
              로케이션 변경
            </div>,
            <div key={`excel-down`} id={`excel-down`} className={`green`} onClick={gridButtonhandler}>
              엑셀다운
            </div>,
          ],
          options: {
            isRowAllCheckCurrentView: true,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          },
        },
      ],
    });

    dispatch(setLoading(null));
  };

  const onEditEnded = async (e) => {
    if (e.dataField === 'memo' && e.value) {
      if (e.item.stockRemoveSeq) {
        if (window.confirm('특이사항을 저장하시겠습니까?')) {
          await httpClient.post(REMOVE_PICKING_MEMO_SAVE, { stockRemoveSeq: e.item.stockRemoveSeq, memo: e.item.memo }).then((rs) => {
            if (rs?.status === 200) {
              alert('특이사항이 저장되었습니다!');
              const searchObj = JSON.stringify(e.item.searchObj);
              fetchList(searchObj);
            }
          });
        }
      } else {
        alert('"피킹지시대기"상태에서는 특이사항을 저장할 수 없습니다!');
      }
    }
  };

  const saveLocation = async ({ data, event, searchObj }) => {
    dispatch(setLoading('POST'));

    await httpClient.post(`/warehouse/remove/picking/location/change`, data).then((rs) => {
      if (rs?.status === 200) {
        alert('로케이션 이동에 성공하였습니다!');
        fetchDetailList(event);
        fetchList(searchObj);
      }
    });
    dispatch(setLoading(null));
  };

  const [photoPopupObj, setPhotoPopupObj] = useState({
    visible: false,
    item: null,
    photos: null,
    fetchPhotosFn: (data) => fetchPhotos(data),
  });
  const fetchPhotos = (item) => {
    if (item.stockRemoveSeq) {
      httpClient.get(TAKE_OUT_PICKING_PHOTO + `/${item.stockRemoveSeq}`).then((rs) => {
        if (rs?.status === 200) {
          setPhotoPopupObj((prev) => {
            return {
              ...prev,
              visible: true,
              item: item,
              photos: rs.data,
            };
          });
        }
      });
    } else {
      alert('피킹 지시번호가 존재하는 건만 사진열람이 가능합니다');
    }
  };

  const columns = [
    {
      headerText: '상세보기',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '상세보기',
        onClick: fetchDetailList,
      },
    },
    {
      headerText: '사진보기',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        labelText: '사진보기',
        onClick: (e) => fetchPhotos(e.item),
      },
    },
    {
      headerText: '반출피킹지시서',
      dataField: 'print',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        onClick: printAction,
      },
    },
    {
      headerText: '할당정보',
      dataField: 'haldangInfo',
      width: 80,
      renderer: {
        type: 'ButtonRenderer',
        onClick: haldangAction,
      },
    },
    {
      headerText: '피킹지시번호',
      dataField: 'stockRemoveSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출차예정일',
      dataField: 'stockRemoveDate',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '피킹지시<br />확정일',
      dataField: 'initDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '피킹완료일',
      dataField: 'pickDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '반출창고',
      dataField: 'centerCodeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '반출대상',
      dataField: 'target',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '반출유형',
      dataField: 'stockRemoveTypeKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '반출피킹대상 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },

    {
      headerText: '피킹완료 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'pickAvailableQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '불용',
          dataField: 'pickDefectedQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '미피킹 수량<br />(대상 - 완료)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedDiff',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '특이사항',
      dataField: 'memo',
      headerTooltip: {
        show: true,
        tooltipHtml: `<div">
        직접 수정 가능
          </div>`,
      },
    },
    {
      headerText: '피킹지시<br />확정자',
      dataField: 'confirmId',
      filter: {
        showIcon: true,
      },
    },
  ];

  const detailColumns = [
    {
      headerText: '피킹지시번호',
      dataField: 'stockRemoveSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '바코드',
          dataField: 'barcode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '반출피킹대상 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },

    {
      headerText: '피킹완료 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'pickAvailableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'pickDefectedQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '미피킹 수량<br />(대상 - 완료)',
      children: [
        {
          headerText: '양품',
          dataField: 'availableDiff',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedDiff',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '특이사항',
      dataField: 'memo',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '피킹완료일',
      dataField: 'pickDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '피킹완료자',
      dataField: 'pickId',
      filter: {
        showIcon: true,
      },
    },
  ];

  const getColumns = columns.filter(
    (item, index) =>
      item.dataField === 'stockRemoveSeq' ||
      item.dataField === 'stockRemoveDate' ||
      item.dataField === 'initDatetime' ||
      item.dataField === 'centerCodeKr' ||
      item.dataField === 'target' ||
      item.dataField === 'stockRemoveTypeKr' ||
      item.dataField === 'partnerSeqKr',
  );
  const getDetailColumns = detailColumns.filter((item, index) => index > 0);
  const excelDetailColumns = [...getColumns, ...getDetailColumns];
  excelDetailColumns.push(columns.find((item, index) => item.dataField === 'confirmId'));

  // gridTrigger
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState();

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_PICKING_CONFIRM') {
        confirmPicking(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'confirm-picking-single') {
        confirmRemovePickingType(items, 'single');
      } else if (getCheckedDataTriggerThisGrid?.type === 'confirm-picking-multiple') {
        confirmRemovePickingType(items, 'multiple');
      } else if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_PICKING_COMPLETE') {
        if (items.length > 1) {
          alert('한개씩 완료 할 수 있습니다!');
        } else {
          completePicking(items[0]);
        }
      } else if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_PICKING_COMPLETE_LOST') {
        if (items.length > 1) {
          alert('한개씩만 작업 할 수 있습니다!');
        } else {
          completeLost(items[0]);
        }
      } else if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_PICKING_ROLLBACK') {
        if (items.length > 1) {
          alert('한개씩만 작업 할 수 있습니다!');
        } else if (items[0]?.stockRemoveType === 'REFURBISH') {
          alert('반출유형이 "리퍼생성" 건은 반출피킹 취소가 불가합니다.');
        } else {
          validGoPrevStep({ item: items[0] });
        }
      } else if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_PICKING_CANCEL') {
        if (items.length > 1) {
          alert('한개씩 취소 할 수 있습니다!');
        } else {
          validCancelPicking({ item: items[0] });
        }
      } else if (getCheckedDataTriggerThisGrid?.type === 'TAKEOUT_PICKING_CENTER_CHANGE') {
        changeCenter(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'REMOVE_PICKING_CLOSE') {
        pickingCloseAction(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const pickingCloseAction = async (items) => {
    if (window.confirm('강제마감 하시겠습니까?')) {
      const rs = await serviceStore.warehouseAction(
        'remove/loading/close',
        'POST',
        null,
        items?.map((ele) => {
          return {
            seq: ele.loadingStatementSeq,
          };
        }),
      );
      if (rs?.status === 200) {
        alert('강제마감 완료되었습니다!');
        fetchList(searchObj);
      }
    }
  };

  const changeCenter = (items) => {
    if (items.length === 1) {
      const data = items[0];
      if (data?.status !== 'INIT') {
        alert('피킹지시대기 상태만 확정 지을 수 있습니다!');
      } else {
        setChangeCenterObj({
          visible: true,
          data,
        });
      }
    } else {
      alert('출발창고 변경은 한건씩만 가능합니다.');
    }
  };

  const validConfirmPicking = (items) => {
    let validFlag = true;
    items?.forEach((item) => {
      if (item?.status !== 'INIT') {
        alert('피킹지시대기 상태만 확정 지을 수 있습니다!');
        validFlag = false;
      }
    });
    return validFlag;
  };

  const confirmPicking = (items) => {
    if (validConfirmPicking(items)) {
      if (window.confirm('확정하시겠습니까?')) {
        const stockRemoveSeqsArr = items.map((ele) => {
          return {
            centerCode: ele.centerCode,
            stockRemoveDate: ele.stockRemoveDate,
            target: ele.target,
            partnerSeq: ele.partnerSeq,
            stockRemoveType: ele.stockRemoveType,
          };
        });
        confirmPickingAPI(stockRemoveSeqsArr);
      }
    }
  };

  const confirmPickingParts = (items) => {
    if (validConfirmPicking([items[0]])) {
      const result = window.confirm('확정하시겠습니까?');
      if (result) {
        const dataArr = [
          {
            partnerSeq: items[0].partnerSeq,
            centerCode: items[0].centerCode,
            target: items[0].target,
            stockRemoveDate: items[0].stockRemoveDate,
            stockRemoveType: items[0].stockRemoveType,
            inventorySeqs: items?.map((ele) => ele.inventorySeq),
          },
        ];
        confirmPickingAPI(dataArr);
      }
    }
  };

  const confirmPickingAPI = async (dataArr) => {
    let url = TAKE_OUT_PICKING;

    dispatch(setLoading('POST'));
    await httpClient.post(url, dataArr).then((rs) => {
      if (rs?.status === 200 && rs.data.result) {
        alert('반출 피킹 확정되었습니다!');
        // 할당 정보 모달 닫는 로직 제거 (다른 메뉴의 UX와 통일)
        // setHaldangObj((prev) => {
        //   return {
        //     ...prev,
        //     visible: false,
        //     data: null,
        //     rowItems: null,
        //   };
        // });
        fetchList(searchObj);
        setSearchObj({ ...searchObj });
      }
    });
    dispatch(setLoading(null));
  };

  const validCompletePicking = (item) => {
    if (item.stockRemoveSeq) return true;
    else {
      alert('피킹되지 않은 건입니다!');
      return false;
    }
  };

  const completePicking = async (item) => {
    if (validCompletePicking(item)) {
      const result = window.confirm('반출피킹 완료 하시겠습니까?');
      if (result) {
        dispatch(setLoading('POST'));
        let url = TAKE_OUT_PICKING_COMPLETE + `/${item.stockRemoveSeq}`;

        await httpClient.post(url, '').then((rs) => {
          if (rs?.status === 200 && rs.data.result) {
            alert('반출 피킹 완료되었습니다!');
            fetchList(searchObj);
          }
        });
        dispatch(setLoading(null));
      }
    }
  };

  const validCompleteLost = (item) => {
    let validFlag = true;
    if (item?.stockRemoveType !== 'LOST') {
      alert('반출유형이 "분실"만 완료 지을 수 있습니다!');
      validFlag = false;
    }
    return validFlag;
  };

  const completeLost = async (item) => {
    if (validCompleteLost(item)) {
      const result = window.confirm('분실처리 완료 하시겠습니까?');
      if (result) {
        dispatch(setLoading('POST'));
        let url = TAKE_OUT_PICKING_FINISH + `?stockRemoveSeqs=${item.stockRemoveSeq}`;

        await httpClient.post(url, '').then((rs) => {
          if (rs.data.result) {
            alert('분실처리 완료되었습니다!');
            fetchList(searchObj);
          }
        });
        dispatch(setLoading(null));
      }
    }
  };

  const validGoPrevStep = ({ item, items }) => {
    let validFlag = false;
    let content = [];

    if (item?.statuKr === '피킹지시대기') {
      content = ['“피킹 지시대기” 상태에서는 이전단계로 되돌릴 수 없습니다.', '\n 반출 피킹 취소를 원하실 경우 ‘반출피킹 취소’ 버튼을 눌러주세요.'];
      validFlag = false;
    } else if (item?.statusKr === '피킹취소') {
      content = ['“피킹 취소” 상태에서는 이전 단계로 돌아갈 수 없습니다.'];
      validFlag = false;
    } else if (item?.statusKr === '피킹완료') {
      content = ['“피킹 완료” 상태에서는 이전 단계로 돌아갈 수 없습니다.'];
      validFlag = false;
    } else if (item?.statusKr === '피킹중') {
      content = ['“피킹 중” 상태에서 “피킹 지시확정” 상태로 돌아가시겠습니까?'];
      validFlag = true;
    } else if (item?.statusKr === '피킹지시확정') {
      content = ['“피킹 지시확정” 상태에서 “피킹 지시대기” 상태로 돌아가시겠습니까?', '※단, 이전단계로 돌아갈 경우 "반출유형-택배반출"의 경우에만 기록 확인이 가능합니다.'];
      validFlag = true;
    }

    const _alertModalObj = {
      ...alertModalObj,
      visible: true,
      title: '이전단계',
      content,
    };

    if (validFlag) {
      _alertModalObj.yBtnTitle = '진행';
      _alertModalObj.yCallback = () => {
        goPrevStep(item, items);
      };
    }
    setAlertModalObj(_alertModalObj);
  };

  const goPrevStep = async (item, items) => {
    const data = [];
    const json = {
      stockRemoveSeq: item.stockRemoveSeq,
    };

    const inventorySeqs = [];
    if (items) {
      items.forEach((item) => {
        inventorySeqs.push(item.inventorySeq);
      });
    }
    json.inventorySeqs = inventorySeqs;
    data.push(json);

    let url = INVENTORY_RETURN_ROLLBACK;

    dispatch(setLoading('POST'));
    await httpClient.post(url, data).then((rs) => {
      if (rs?.status === 200 && rs?.data?.result) {
        setAlertModalObj({
          ...alertModalObj,
          visible: true,
          title: '이전단계 완료',
          content: [`반출피킹 지시번호 ${item.stockRemoveSeq}의 상태값이 변경되었습니다.`],
        });
        fetchList(searchObj);
        setSearchObj({ ...searchObj });
      }
    });
    dispatch(setLoading(null));
  };

  const validCancelPicking = ({ item, items }) => {
    let validFlag = false;
    let content = [];

    if (item?.statusKr === '피킹지시대기') {
      content = [
        '반출피킹 작업을 취소하시겠습니까?',
        '※ 취소할 경우 다시 되돌릴 수 없으니 신중히 선택해주세요',
        <Row>
          <Col>
            <label className="col-form-label">취소사유</label>
            <SelectD
              onChange={(option) => {
                item.holdType = option.value;
              }}
              options={masterOptions?.ORDER_HOLD_TYPE}
            />
          </Col>
          <Col>
            <label className="col-form-label">메모</label>
            <Input
              onChange={(e) => {
                item.holdNote = e.target.value;
              }}
            />
          </Col>
        </Row>,
      ];
      validFlag = true;
    } else if (item?.statusKr === '피킹지시확정') {
      content = [
        '반출피킹 작업을 취소하시겠습니까?',
        '※ 취소할 경우 다시 되돌릴 수 없으니 신중히 선택해주세요',
        <Row>
          <Col>
            <label className="col-form-label">취소사유</label>
            <SelectD
              onChange={(option) => {
                item.holdType = option.value;
              }}
              options={masterOptions?.ORDER_HOLD_TYPE}
            />
          </Col>
          <Col>
            <label className="col-form-label">직접입력</label>
            <Input
              onChange={(e) => {
                item.holdNote = e.target.value;
              }}
            />
          </Col>
        </Row>,
      ];
      validFlag = true;
    } else if (item?.statusKr === '피킹완료') {
      content = ['“피킹 완료” 상태에서는 반출피킹을 취소할 수 없습니다'];
      validFlag = false;
    } else if (item?.statusKr === '피킹중') {
      content = ['“피킹 중” 상태에서는 반출피킹을 취소할 수 없습니다'];
      validFlag = false;
    } else if (item?.statusKr === '피킹취소') {
      content = ['이미 피킹 취소된 건 입니다'];
      validFlag = false;
    }

    const _alertModalObj = {
      ...alertModalObj,
      visible: true,
      title: '반출피킹 취소',
      content,
      nBtnTitle: '확인',
    };

    if (validFlag) {
      _alertModalObj.yBtnTitle = '진행';
      _alertModalObj.yCallback = () => {
        cancelPicking(item, items);
      };
    }
    setAlertModalObj(_alertModalObj);
  };

  const cancelPicking = async (item, items) => {
    if (!item.holdType) {
      alert('취소사유를 선택하세요');
    } else {
      const data = [];
      const json = {
        centerCode: item.centerCode,
        target: item.target,
        partnerSeq: item.partnerSeq,
        stockRemoveDate: item.stockRemoveDate,
        stockRemoveType: item.stockRemoveType,
        stockRemoveSeq: item.stockRemoveSeq,
        holdType: item.holdType,
        holdNote: item.holdNote,
      };
      const inventorySeqs = [];
      if (items) {
        items.forEach((item) => {
          inventorySeqs.push(item.inventorySeq);
        });
      }
      json.inventorySeqs = inventorySeqs;
      data.push(json);

      let url = TAKE_OUT_REMOVE_CANCEL;
      dispatch(setLoading('POST'));

      await httpClient.post(url, data).then((rs) => {
        if (rs?.status === 200 && rs?.data?.result) {
          setAlertModalObj({
            ...alertModalObj,
            visible: true,
            title: '반출피킹 취소 완료',
            content: [`반출피킹 지시번호 ${item.stockRemoveSeq}가 취소되었습니다`],
          });
          fetchList(searchObj);
          setSearchObj({ ...searchObj });
        }
      });
      dispatch(setLoading(null));
    }
  };

  const downloadExcel = async (type) => {
    dispatch(setLoading('GET'));
    let url;
    if (type === 'main') {
      url = '/warehouse/remove/picking/list?';
      url = paramingSearchObjToUrl(url, searchObj);
    } else if (type === 'detail') {
      url = '/warehouse/remove/picking/list/excel/detail?';
      url = paramingSearchObjToUrl(url, searchObj);
    }

    return await httpClient.get(url).then((rs) => {
      if (rs.status === 200) {
        const data = rs.data;
        data.forEach((row) => {
          row.statusKr = masterOptions?.REMOVE_STATUS_OBJ[row.status];
          row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
          row.modelGroupKr = selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP);
          row.stockRemoveTypeKr = selectlabel(row.stockRemoveType, masterOptions?.REMOVE_REASON);
          row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER);
          row.userIdKr = selectlabel(row.userId, masterOptions?.DRIVER_LABEL);
          row.driverPhoneKr = selectlabel(row.userId, masterOptions?.DRIVER_PHONE);
          row.teamKr = row.memberCount === 2 ? '2인 1조' : row.memberCount === 1 ? '1인 1조' : '';
        });
        dispatch(setLoading(null));
        return data;
      } else {
        dispatch(setLoading(null));
        return null;
      }
    });
  };

  const confirmRemovePickingType = async (items, type) => {
    if (window.confirm(`${type === 'single' ? '단포장' : '합포장'} 하시겠습니까?`)) {
      const data = items.map((ele) => {
        return {
          centerCode: ele.centerCode,
          target: ele.target,
          stockRemoveType: ele.stockRemoveType,
          stockRemoveDate: ele.stockRemoveDate,
          partnerSeq: ele.partnerSeq,
        };
      });

      const rs = await httpClient.post(`/warehouse/remove/picking/${type}`, data);
      if (rs?.status === 200) {
        alert(`${type === 'single' ? '단포장' : '합포장'} 처리되었습니다`);
        fetchList(searchObj);
      }
    }
  };

  return (
    <Presenter
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      fetchList={fetchList}
      //
      pagingSetting={pagingSetting}
      setPagingSetting={setPagingSetting}
      //
      gridId={gridId}
      rows={rows}
      columns={columns}
      detailColumns={detailColumns}
      excelDetailColumns={excelDetailColumns}
      downloadExcel={downloadExcel}
      onEditEnded={onEditEnded}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      //
      bottomSheetObj={bottomSheetObj}
      setBottomSheetObj={setBottomSheetObj}
      changeCenterObj={changeCenterObj}
      setChangeCenterObj={setChangeCenterObj}
      alertModalObj={alertModalObj}
      setAlertModalObj={setAlertModalObj}
      haldangObj={haldangObj}
      setHaldangObj={setHaldangObj}
      locationChangingObj={locationChangingObj}
      setLocationChangingObj={setLocationChangingObj}
      photoPopupObj={photoPopupObj}
      setPhotoPopupObj={setPhotoPopupObj}
      banchulPrintObj={banchulPrintObj}
      setBanchulPrintObj={setBanchulPrintObj}
    />
  );
};

export default Container;
