import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { CollectDTO, CollectSearchDTO } from '../../../_interface/warehouse';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { CollectPrint, ICollectPrint } from 'pages/IPGO/_print/collectPrint';
import { serviceStore } from 'services/services';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.bind('cellEditEnd', function (e) {
        saveNote(e);
      });
    }
  }, []);

  const handleCheckItems = (e) => {
    const id = e.target.id;
    if (id?.includes('_EXCELDOWN')) {
      downloadExcel('main');
    } else {
      const items = gridRef.current.getCheckedRowItemsAll();
      if (items?.length > 0) {
        if (id?.includes('_PRINT')) printAll(items);
        if (id?.includes('_COLLECT')) collectSave(items);
        if (id?.includes('_BEFORE')) rollbackItems(items);
      } else {
        alert('선택된 항목이 존재하지 않습니다.');
      }
    }
  };

  const rollbackItems = async (items) => {
    const rs = await serviceStore?.warehouseAction(`collect/rollback`, 'POST', null, { collectSeqs: items?.map((ele) => ele.collectSeq) });
    if (rs?.status === 200) {
      alert(rs?.data?.message ?? '원복되었습니다');
      wrappedFetchList(searchObj);
    }
  };

  const collectSave = async (items) => {
    if (window.confirm('회수 입고 하시겠습니까? 확정시 복원이 불가합니다.')) {
      let flag = true;
      items.forEach((ele) => {
        if (ele.status !== 'COLLECT') {
          alert('회수중인 상태만이 회수입고 진행을 할 수 있습니다');
          flag = false;
        }
      });

      if (flag) {
        dispatch(setLoading('PUT'));
        const res = await serviceStore?.warehouseAction(`collect/confirm`, 'POST', null, { collectSeqs: items?.map((ele) => ele.collectSeq) });
        if (res?.status === 200) {
          alert(res?.data?.message ?? '회수 입고 확정되었습니다');
          const searchObj = JSON.parse(items[0].searchObj);
          wrappedFetchList(searchObj);
        }
        dispatch(setLoading(null));
      }
    }
  };

  const [printObj, setPrintObj] = useState<ICollectPrint>();

  const printAll = (items) => {
    setPrintObj({
      visible: true,
      collectSeqs: items.map((ele) => ele.collectSeq),
    });
  };

  const printAction = async (e: IGrid.ButtonClickEvent) => {
    setPrintObj({
      visible: true,
      collectSeqs: [e.item?.collectSeq],
    });
  };
  const saveNote = async (e: IGrid.CellEditEndEvent) => {
    if (e.value && (e.dataField === 'workNote' || e.dataField === 'defectedNote')) {
      if (window.confirm(`${e.headerText}를 저장하시겠습니까?`)) {
        const data = {
          collectSeq: e.item.collectSeq,
        };
        if (e.dataField === 'workNote') {
          data['workNote'] = e.item.workNote;
        } else {
          data['defectedNote'] = e.item.defectedNote;
        }
        const rs = await serviceStore.warehouseAction(e.dataField === 'workNote' ? 'collect/work/note/save' : 'collect/defected/note/save', 'POST', null, data);
        if (rs?.status === 200) {
          alert(`${e.headerText}가 저장되었습니다!`);
          const searchObj = JSON.parse(e.item.searchObj);
          wrappedFetchList(searchObj);
        }
      } else {
        gridRef.current.setCellValue(e.rowIndex, e.dataField, '');
      }
    }
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '프린트',
      dataField: 'print',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '프린트',
        onClick: printAction,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.DRIVERCOLLECT_REMOVE_GRID_PRINT;
        },
      },
    },
    {
      headerText: '회수번호',
      dataField: 'collectSeq',
    },
    {
      headerText: '재고번호',
      dataField: 'inventorySeq',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '구분',
      dataField: 'collectTypeKr',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },

    {
      headerText: '주문정보',
      children: [
        {
          headerText: '고객주문번호',
          dataField: 'orderSeq',
        },
        {
          headerText: '송장번호',
          dataField: 'invoiceSeq',
        },
        {
          headerText: '외부송장유형',
          dataField: 'externalInvoiceTypeKr',
        },
        {
          headerText: '고객명',
          dataField: 'customerName',
        },
        {
          headerText: '택배사',
          dataField: 'userId',
        },
      ],
    },
    {
      headerText: '비고',
      headerTooltip: {
        show: true,
        tooltipHtml: `<div">
        직접 수정 가능
          </div>`,
      },
      children: [
        {
          headerText: '작업 특이사항',
          dataField: 'workNote',
          editable: true,
        },
        {
          headerText: '불량/수리메모',
          dataField: 'defectedNote',
          editable: true,
        },
      ],
    },
    {
      headerText: '처리이력',
      children: [
        {
          headerText: '회수생성일',
          dataField: 'registerDatetime',
        },
        {
          headerText: '창고확정일',
          dataField: 'centerConfirmDatetime',
        },
      ],
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },

        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: '시리얼번호',
          dataField: 'serialNumber',
        },
      ],
    },
  ];

  const labellingKr = (data: PagingListDTO<CollectDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        userIdKr: MASTER_OBJ?.DRIVER_WHOLE?.[row.userId],
        //
        statusKr: MASTER_OBJ?.COLLECT_STATUS?.[row.status],
        collectTypeKr: MASTER_OBJ?.COLLECT_TYPE?.[row.collectType],
        cancelReasonKr: MASTER_OBJ?.CANCEL_REASON?.[row.cancelReason],
        externalInvoiceTypeKr: MASTER_OBJ?.BODY_SHIPPING_TYPE?.[row.externalInvoiceType],
        //
        workNote: row.workNote ? row.workNote.replaceAll('"', '') : '',
        collectNote: row.collectNote ? row.collectNote.replaceAll('"', '') : '',
        defectedNote: row.defectedNote ? row.defectedNote.replaceAll('"', '') : '',
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: CollectSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.warehouseAction(`collect/remove/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<CollectDTO>);

    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));

    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction(`collect/remove/excel`, 'GET', searchObj, null))?.data as CollectDTO[],
      },
      true,
    );

    excelGridRef.current.setGridData(list);
    excelGridRef.current.exportAsXlsx({ fileName: '반출회수입고' });
    dispatch(setLoading(null));
  };
  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, handleCheckItems);
    setFunctionBtns(btns);
  }, [searchObj]);

  return (
    <div className="page-content">
      {printObj?.visible && <CollectPrint printObj={printObj} setPrintObj={setPrintObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            editable: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns} />
      </div>
    </div>
  );
};

export default Index;
