import Select from 'react-select';

import { Button, Col, Row, Input, Spinner } from 'reactstrap';
import Grid from 'components/grid/auiGrid';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import SearchModelModalForStoreTakeOutPickingInstructionRegister from './bodyBanchulRegit/searchModelModalForStoreTakeOutPickingInstructionRegister.js';
import { useEffect } from 'react';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';

const Presenter = (props) => {
  const {
    gridId,
    rows,
    setRows,
    columns,

    Loading,
    setLoading,

    searchObj,
    searchModelObj,
    setSearchModelObj,

    getAllGridDataTriggerThisGrid,
    setGetAllGridDataTriggerThisGrid,
    getCheckedDataTriggerThisGrid,
    setGetCheckedDataTriggerThisGrid,
  } = props;

  useEffect(() => {
    if (!searchModelObj?.visible) setLoading(false);
  }, [searchModelObj?.visible]);

  useEffect(() => {
    if (searchObj?.centerCode) setRows([]);
  }, [searchObj?.centerCode]);

  return (
    <>
      {searchModelObj?.visible && <SearchModelModalForStoreTakeOutPickingInstructionRegister searchModelObj={searchModelObj} setSearchModelObj={setSearchModelObj} />}
      <div className="page-content">
        {Loading && (
          <Spinner
            style={{
              position: 'absolute',
              left: '50%',
              top: '30%',
              zIndex: 100,
            }}
            color="danger"
          />
        )}
        <div style={{ textAlign: 'right' }}>
          <Button
            className="btn btn-create sm"
            onClick={() => {
              setGetCheckedDataTriggerThisGrid({
                type: 'create-picking',
                id: `#${gridId}`,
              });
            }}
          >
            피킹 생성
          </Button>
        </div>
        <div className="presenterInput">
          <SearchInfo {...props} />
        </div>
        <div className="presenterInput">
          <GridBox
            setLoading={setLoading}
            gridId={gridId}
            rows={rows}
            setRows={setRows}
            columns={columns}
            searchObj={searchObj}
            searchModelObj={searchModelObj}
            setSearchModelObj={setSearchModelObj}
            getAllGridDataTriggerThisGrid={getAllGridDataTriggerThisGrid}
            setGetAllGridDataTriggerThisGrid={setGetAllGridDataTriggerThisGrid}
            getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
            setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
          />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const SearchInfo = (props) => {
  const { MASTER_OBJ, MASTER_OPS, searchObj, setSearchObj } = props;

  return (
    <>
      <div className="inputTitle mb-4">1. 기본정보</div>
      <>
        <Row style={{ marginLeft: 10 }}>
          <Col>
            <label className="col-form-label">반출예정일</label>
            <FlatpickrD
              value={searchObj.stockRemoveDate}
              onChange={(date) => {
                setSearchObj({
                  ...searchObj,
                  stockRemoveDate: returnDateyyyymmdd(date),
                });
              }}
              options={{
                mode: 'single',
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">반출창고</label>
            <SelectD
              value={
                searchObj.centerCode
                  ? {
                      value: searchObj.centerCode,
                      label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                    }
                  : null
              }
              options={MASTER_OPS?.CENTER_AUTH}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: option.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">반출유형</label>
            <Select
              isDisabled={true}
              value={{
                value: searchObj.reason,
                label: MASTER_OBJ?.REMOVE_REASON?.[searchObj?.reason],
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">판매사</label>
            <Select
              isDisabled={true}
              value={{
                value: searchObj.partnerSeq,
                label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[searchObj?.partnerSeq],
              }}
              options={MASTER_OPS?.SELLER_SELLER_AUTH}
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  partnerSeq: option.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">반출대상</label>
            <Input
              disabled
              value={searchObj.target || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  target: e.target.value,
                });
              }}
            />
          </Col>
          <Col />
          <Col />
        </Row>
      </>
    </>
  );
};

const GridBox = (props) => {
  const { AUIGrid } = window;

  const {
    setLoading,
    gridId,
    rows = [],
    setRows,
    columns,
    searchObj,
    searchModelObj,
    setSearchModelObj,
    getAllGridDataTriggerThisGrid,
    setGetAllGridDataTriggerThisGrid,
    getCheckedDataTriggerThisGrid,
    setGetCheckedDataTriggerThisGrid,
  } = props;

  const cellEditEndThisGrid = (event) => {
    if (event.headerText === '양품') {
      if (event.value > event.item.canAvailableQuantity) {
        alert('보관수량보다 많이 입력하셨습니다');
        AUIGrid.restoreEditedRows(`#${gridId}`, event.rowIndex);
      }
    }
  };

  return (
    <>
      <div className="inputTitle mb-2">2. 피킹 상세정보</div>
      <div className="grid-button-area only-right">
        <div
          className="red"
          onClick={() => {
            setGetCheckedDataTriggerThisGrid({
              type: 'remove-thisRows',
              id: `#${gridId}`,
            });
          }}
        >
          선택 행 삭제
        </div>
        <div
          className="dark"
          onClick={() => {
            if (!searchObj?.stockRemoveDate) {
              alert('반출예정일을 입력하세요');
            } else if (!searchObj?.centerCode) {
              alert('반출창고를 입력하세요');
            } else {
              setLoading(true);
              setSearchModelObj({
                ...searchModelObj,
                visible: true,
                params: searchObj,
              });
            }
          }}
        >
          양판점출고 조회
        </div>
      </div>
      <div style={{ width: '100%' }}>
        {gridId && (
          <Grid
            id={gridId}
            rows={rows}
            columns={columns}
            style={{
              height: 700,
            }}
            //grid-function
            cellEditEndThisGrid={cellEditEndThisGrid}
            getAllGridDataTriggerThisGrid={getAllGridDataTriggerThisGrid}
            setGetAllGridDataTriggerThisGrid={setGetAllGridDataTriggerThisGrid}
            getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
            setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            settingOptions={{
              enableFocus: false,
              showStateColumn: false,
              selectionMode: 'singleRow',
              softRemoveRowMode: false,
              showRowAllCheckBox: true,
              independentAllCheckBox: false,
              showRowCheckColumn: true,
            }}
          />
        )}
      </div>
    </>
  );
};
