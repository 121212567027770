import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { IPagingGrid, PARCEL_GRID_PAGE_ROW_COUNT } from 'common/grid/gridBox';
import { SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { COMMON_GRIDID, setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { orderService } from '../../_services/service';
import { ParcelInvoiceQuantityDTO, ParcelInvoiceDTO, ParcelInvoiceSearchDTO } from '../../_interface/order';
import useGridButton from 'hooks/grid/useGridButton';

import useSearchGridPaging from 'hooks/grid/useGridPaging';
import useExcelHooks from 'hooks/excel/useExcelHooks';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import { DetailPopup, IDetailObj } from 'pages/ETC/1driver/driverListTab/driverList/component/detailPopup';
import { IParcelDetail, ParcelDetail } from './component/parcelDetail';
import { PARCEL_DEFAULT_COLUMNS } from '../../orderListTab/_asset/asset';
import { defaultFixedColumnCountObjType, gridParamToGridColumn } from 'common/grid/columnManager';
import PrintGridBox from 'common/grid/printGridBox';
import { PagingListDTO } from 'interface/util';
import { PARCEL_UPLOAD } from 'envVar2';
import { defaultOrderDetailObj } from '../../orderListTab/common';
import { IInventoryCheck, ProductInventoryCheck } from '../../orderListTab/common/component/productInventoryCheck';
import { OrderDetail } from '../../orderListTab/common/component/invocieDetail/orderDetail';

const GRIDID = `${COMMON_GRIDID}PARCELINVOICE_TAB1`;

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const [driverDetailObj, setDriverDetailObj] = useState<IDetailObj>();
  const [orderDetailObj, setOrderDetailObj] = useState(defaultOrderDetailObj); // js => tsx
  const [detailObj, setDetailObj] = useState<IParcelDetail>();
  const [inventoryCheckObj, setInventoryCheckObj] = useState<IInventoryCheck>();
  const [fetchCount, setFetchCount] = useState<ParcelInvoiceQuantityDTO>();
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);

  const fetchInventoryDetailList = async (e: IGrid.ButtonClickEvent) => {
    if (e.item) {
      dispatch(setLoading('GET'));
      setInventoryCheckObj({ visible: true, item: e.item, centerCodeKr: e?.item?.centerCodeKr });
      dispatch(setLoading(null));
    }
  };

  const labellingKr = (data: PagingListDTO<ParcelInvoiceDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        //
        statusKr: MASTER_OBJ?.PARCEL_INVOICE_STATUS?.[row.status],
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row.partnerSeq],
        infraSeqKr: MASTER_OBJ?.INFRA?.[row.infraSeq],
        userIdKr: MASTER_OBJ?.DRIVER_WHOLE?.[row.userId],
        userIdPhoneKr: MASTER_OBJ?.DRIVER_PHONE_WHOLE?.[row.status],
        parcelPayTypeKr: MASTER_OBJ?.PARCEL_PAY_TYPE?.[row.parcelPayType],
        feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row.feeType],
        invoiceStatusKr: MASTER_OBJ?.INVOICE_STATUS?.[row.invoiceStatus],
        //
        seller: row.seller ? row.seller : '',
        receiverAddr: row.receiverAddr1 + ' ' + row.receiverAddr2,
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchTotal = async (searchObj: ParcelInvoiceSearchDTO) => {
    const total = await orderService.getOrderParcelQuantity(searchObj);
    setFetchCount(total);
    return total;
  };
  const fetchPaging = async (searchObj: ParcelInvoiceSearchDTO) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr(await orderService.getOrderParcelPaging(searchObj));
    dispatch(setLoading(null));
    return dataKr;
  };

  const downloadExcel = async (type: 'main' | 'detail') => {
    const prompt = window.confirm(`엑셀다운로드 ${type === 'main' ? '' : '*상세*'}를 시작합니다. \n※ 많은 양의 데이터는 30초 이상의 시간이 소요될 수 있습니다.`);
    if (prompt) {
      dispatch(setLoading('GET'));
      const { list } = labellingKr({ list: await orderService.getOrderParcelMainExcel(searchObj) }, true);

      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '택배운송장' });
      dispatch(setLoading(null));
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'PARCELEXCELDOWNMAIN') {
      downloadExcel('main');
    } else if (id === 'PARCELEXCELDOWNDETAIL') {
      downloadExcel('detail');
    } else if (id === 'PARCELEXCELUPLOADFORM') {
      window.open(PARCEL_UPLOAD);
    } else if (id === 'PARCELEXCELUPLOAD') {
      uploaderClick(id);
    } else {
      handleCheckItems(id);
    }
  };

  const handleCheckItems = (id: string) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const popupDriverDetail = async (e) => {
    if (e.text) {
      setDriverDetailObj((prev) => {
        return {
          ...prev,
          visible: true,
          item: { userId: e.item?.[e.dataField?.split('Kr')[0]] },
        };
      });
    }
  };

  const visibleParcelDetail = (e: IGrid.ButtonClickEvent) => {
    setDetailObj({
      visible: true,
      item: e.item,
      fetchFn: (data) => wrappedFetchList(data),
    });
  };

  const visibleOrderDetail = (e: IGrid.ButtonClickEvent) => {
    setOrderDetailObj({
      visible: true,
      item: e.item,
    });
  };

  const mappingFunction = {
    showParcelDetail: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: visibleParcelDetail,
      },
    },
    showOrderDetail: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: visibleOrderDetail,
      },
    },
    invoiceStatusKr: {
      type: 'styleFunction',
      mapValue: function (rowIndex, columnIndex, value, headerText, item, dataField) {
        const invStat = item.invoiceStatus;
        if (invStat === 'INIT') {
          return 'status-badge-yellow';
        } else if (
          invStat === 'NOT_IN_INVENTORY' ||
          invStat === 'DRIVER_CAPA_EXCEED' ||
          invStat === 'EXCLUDE_ZIPCODE' ||
          invStat === 'ZIPCODE_ERROR' ||
          invStat === 'ADDRESS_ERROR' ||
          invStat === 'NOT_EXIST_CENTER' ||
          invStat === 'NOT_EXIST_DRIVER' ||
          invStat === 'DRIVER_SETTING_ERROR' ||
          invStat === 'CENTER_CUTOFF_CLOSE'
        ) {
          return 'status-badge-orange';
        } else if (
          //
          invStat === 'ASSIGN' ||
          invStat === 'ASSIGN_VIRT' ||
          invStat === 'REMOVING' ||
          invStat === 'CONFIRM' ||
          invStat === 'LOAN' ||
          invStat === 'DELIVERY'
        ) {
          return 'status-badge-green';
        } else if (invStat.startsWith('FINISH_')) {
          return 'status-badge-blue';
        } else if (invStat.startsWith('CANCEL_')) {
          return 'status-badge-red';
        } else if (invStat.startsWith('PEND')) {
          return 'status-badge-gray';
        } else if (invStat === 'ASSIGNING') {
          return 'status-badge-purple';
        }
        return null;
      },
    },
    // price: {
    //   dataType: 'numeric',
    //   formatString: '#,##0',
    // },
    productName: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: fetchInventoryDetailList,
      },
    },
    userIdKr: {
      type: 'renderer',
      mapValue: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: popupDriverDetail,
      },
    },
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: MASTER_OBJ?.[`${COMMON_GRIDID}${tabId}`]?.pageSize || PARCEL_GRID_PAGE_ROW_COUNT,
      ...defaultSearchFilter,
    },
    gridRef,
    fetchTotal,
    fetchPaging,
  });

  const updateWithExcel = async (excelrows = []) => {
    if (excelrows?.length > 0) {
      const rs = await orderService.postParcelInvoiceExcelSave(excelrows);
      if (rs?.status === 200) {
        alert('저장되었습니다');
        wrappedFetchList(searchObj);
      }
    } else {
      alert('추출된 값이 없습니다. 엑셀을 확인해주세요');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks();
  useEffect(() => {
    if (parsedData?.data?.length > 0) {
      const data = parsedData?.data?.map((row) => {
        return {
          parcelInvoiceNumber: row[0],
          price: row[1].replaceAll(',', '').trim(),
          extraFee: row[2],
          weight: row[3],
          width: row[4],
          depth: row[5],
          height: row[6],
          volume: row[7],
        };
      });
      updateWithExcel(data);
    }
  }, [parsedData]);
  const [columns, _] = useState(
    gridParamToGridColumn(MASTER_OBJ?.[`${COMMON_GRIDID}${tabId}`] ? MASTER_OBJ?.[`${COMMON_GRIDID}${tabId}`]?.userGridParameters : PARCEL_DEFAULT_COLUMNS, mappingFunction),
  );

  const dragParentRef = useRef<HTMLDivElement>();

  const defaultFixedColumnCountObj: defaultFixedColumnCountObjType = {
    PARCELINVOICE_TAB1: 2,
  };

  return (
    <div className="page-content">
      {inventoryCheckObj?.visible && <ProductInventoryCheck inventoryCheckObj={inventoryCheckObj} setInventoryCheckObj={setInventoryCheckObj} />}
      {orderDetailObj?.visible && <OrderDetail orderDetailObj={orderDetailObj} setOrderDetailObj={setOrderDetailObj} />}
      {driverDetailObj?.visible && <DetailPopup detailObj={driverDetailObj} setDetailObj={setDriverDetailObj} />}
      {detailObj?.visible && <ParcelDetail detailObj={detailObj} setDetailObj={setDetailObj} />}
      <div className="presenterSearch">
        <SearchBox fetchCount={fetchCount} searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox" ref={dragParentRef}>
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        <GridBox
          gridId={GRIDID}
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            fixedColumnCount: MASTER_OBJ?.[`${COMMON_GRIDID}${tabId}`]?.fixedColumn || 2,
            pageRowCount: MASTER_OBJ?.[`${COMMON_GRIDID}${tabId}`]?.pageSize || 500,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
          defaultFixedColumnCountObj={defaultFixedColumnCountObj}
        />
        <PrintGridBox gridRef={excelGridRef} columns={gridParamToGridColumn(MASTER_OBJ?.[`${COMMON_GRIDID}${tabId}`]?.userGridParameters, mappingFunction)} />
      </div>
    </div>
  );
};

export default Index;
