import { OptionItem } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';

import phoneMock from './phone-mock.png';
import { setLoading } from 'redux/services/menuSlice';
import { serviceStore } from 'services/services';
import { MessageTemplateDTO, MessageTemplateSearchDTO } from 'interface/order';
import { whatJustInputEntered } from 'common/util/counting';
import { basicTemplateContent } from './asset';

export interface ITplDetailObj {
  visible?: boolean;
  item?: MessageTemplateDTO;
  searchObj?: MessageTemplateSearchDTO;
  fetchFn?: (searchObj?: MessageTemplateSearchDTO) => {};
}

export interface ITplDetailPoupProps {
  tplDetailObj: ITplDetailObj;
  setTplDetailObj: React.Dispatch<React.SetStateAction<ITplDetailObj>>;
}

export const TplDetailPopup = ({ tplDetailObj, setTplDetailObj }: ITplDetailPoupProps) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [messageDTO, setMessageDTO] = useState<MessageTemplateDTO>();
  const [sampleMsgPhoneNum, setSampleMsgPhoneNum] = useState<string>();

  const textareaRef = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (tplDetailObj?.item) {
      setMessageDTO({
        ...tplDetailObj?.item,
        feeTypes: JSON.parse(tplDetailObj?.item?.feeTypes + ''),
      });
    } else {
      setMessageDTO({
        ...tplDetailObj?.item,
        messageType: 'TYPE_1',
        content: basicTemplateContent['TYPE_1'],
        customFlag: false,
        sendFlag: true
      });
    }
  }, []);

  const saveTemplate = async (dataDTO) => {
    dispatch(setLoading('POST'));
    const rs = await serviceStore.orderAction(`message/template/save`, 'POST', null, dataDTO);
    if (rs?.status === 200) {
      alert(`배송안내 템플릿이 ${dataDTO?.messageTemplateSeq ? '수정' : '등록'} 되었습니다!`);
      if (tplDetailObj?.fetchFn && tplDetailObj?.searchObj) tplDetailObj?.fetchFn(tplDetailObj?.searchObj);
    }
    dispatch(setLoading(null));
  };

  const messageSend = async (dataDTO) => {
    dispatch(setLoading('POST'));
    const rs = await serviceStore.orderAction(`message/template/send`, 'POST', null, dataDTO);
    if (rs?.status === 200) {
      alert('문자메시지를 성공적으로 전송하였습니다!');
    }
    dispatch(setLoading(null));
  };

  const handleVars = (e) => {
    const selectionStart = textareaRef?.current?.selectionStart;
    const newText = messageDTO?.content
      ? messageDTO?.content?.slice(0, selectionStart) + ` ${'#(' + e.target.innerHTML + ')'} ` + messageDTO?.content?.slice(selectionStart)
      : ` ${'#(' + e.target.innerHTML + ')'} `;
    if (messageDTO?.customFlag) {
      setMessageDTO({
        ...messageDTO,
        content: newText,
      });
    }

    setTimeout(async () => {
      textareaRef?.current?.setSelectionRange(selectionStart, selectionStart);
    }, 1000);
  };

  return (
    <CommonModalNew
      style={{ width: 1000 }}
      title={`배송 안내 템플릿 ${tplDetailObj?.item ? '수정' : '등록'}`}
      visible={tplDetailObj?.visible}
      setVisible={() => {
        setTplDetailObj(null);
      }}
      rightTitle={
        <div>
          <Button
            className="btn btn-orange sm"
            onClick={() => {
              if (!messageDTO?.partnerSeq) {
                alert('판매사는 필수값입니다!');
              } else if (messageDTO?.feeTypes?.length === 0) {
                alert('송장유형은 필수값입니다!');
              } else if (!messageDTO?.title) {
                alert('템플릿 관리명은 필수값입니다!');
              } else if (!messageDTO?.content || messageDTO?.content.trim() === '') {
                alert('내용을 입력하세요!');
              } else {
                saveTemplate(messageDTO);
              }
            }}
          >
            템플릿 {tplDetailObj?.item ? '수정' : '등록'}
          </Button>
        </div>
      }
      children={
        <>
          {!tplDetailObj?.item ? (
            MASTER_OPS?.MESSAGE_TYPE?.filter((ele) => ele?.value).map((btn, idx) => {
              return (
                <Button
                  key={`msgMode_${idx}`}
                  className={btn.value === messageDTO?.messageType ? 'btn btn-secondary' : 'btn btn-etc'}
                  onClick={() => {
                    if (basicTemplateContent[btn.value]) {
                      setMessageDTO({
                        ...messageDTO,
                        messageType: btn.value,
                        content: basicTemplateContent[btn.value],
                      });
                    } else {
                      alert('준비중인 템플릿입니다!');
                    }
                  }}
                >
                  {btn.label}
                </Button>
              );
            })
          ) : (
            <Button className={'btn btn-secondary'}>{MASTER_OBJ?.MESSAGE_TYPE?.[messageDTO?.messageType]}</Button>
          )}
          <div className="template-content" style={{ marginTop: 15, display: 'flex', alignItems: 'flex-start' }}>
            <div style={{ position: 'relative' }}>
              <img src={phoneMock} style={{ width: 350 }} alt="내용을 입력하세요" />
              <textarea
                ref={textareaRef}
                disabled={!messageDTO?.customFlag}
                style={{
                  position: 'absolute',
                  backgroundColor: 'transparent',
                  top: 93,
                  width: 265,
                  left: 20,
                  height: 415,
                  border: 'none',
                }}
                value={messageDTO?.content || ''}
                onChange={(e) => {
                  if (messageDTO?.content?.length < 1000) {
                    const entered = whatJustInputEntered(e.target.value, messageDTO?.content);
                    if (!entered?.includes('#')) {
                      setMessageDTO({
                        ...messageDTO,
                        content: e.target.value,
                      });
                    } else {
                      alert('#는 직접 입력할 수 없는 특수문자입니다!');
                    }
                  } else {
                    alert('1000자까지만 발송 가능합니다');
                    setMessageDTO({
                      ...messageDTO,
                      content: e.target.value.substring(0, 1000),
                    });
                  }
                }}
              />
              <span
                style={{
                  position: 'absolute',
                  zIndex: 100,
                  bottom: 10,
                  left: 25,
                  width: 100,
                  height: 30,
                  cursor: 'pointer',
                  backgroundColor: messageDTO?.customFlag === true ? 'transparent' : '#F1F1F1',
                }}
                onClick={() => {
                  if (messageDTO?.customFlag === true) {
                    setMessageDTO({
                      ...messageDTO,
                      content: basicTemplateContent[messageDTO.messageType],
                    });
                  }
                }}
              />
              <span
                style={{
                  position: 'absolute',
                  zIndex: 100,
                  bottom: 10,
                  right: 77,
                  width: 32,
                  height: 30,
                  cursor: 'pointer',
                  backgroundColor: '#F1F1F1',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  color: messageDTO?.content?.length > 1000 ? 'red' : 'black',
                }}
              >
                {messageDTO?.content?.length}
              </span>
            </div>
            <div style={{ width: 660, paddingLeft: 50 }}>
              <Row>
                <Col md={4}>Step.1 판매사*</Col>
                <Col md={8}>
                  <SelectD
                    value={
                      messageDTO?.partnerSeq
                        ? {
                            value: messageDTO?.partnerSeq,
                            label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[messageDTO?.partnerSeq],
                          }
                        : null
                    }
                    options={MASTER_OPS?.SELLER_SELLER_AUTH}
                    onChange={({ value }) => {
                      setMessageDTO({
                        ...messageDTO,
                        partnerSeq: value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>Step.2 송장유형 선택*</Col>
                <Col md={8} className="no-maxWidth">
                  <SelectD
                    //
                    height100
                    isMulti
                    value={messageDTO?.feeTypes?.map((ele) => {
                      return {
                        value: ele,
                        label: MASTER_OBJ?.FEE_TYPE?.[ele],
                      };
                    })}
                    options={MASTER_OPS?.FEE_TYPE}
                    onChange={(opt) => {
                      setMessageDTO({
                        ...messageDTO,
                        feeTypes: (opt as OptionItem[])?.map((ele) => ele.value),
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>Step.3 템플릿 관리명*</Col>
                <Col md={8}>
                  <InputD
                    value={messageDTO?.title || ''}
                    onChange={(e) => {
                      setMessageDTO({
                        ...messageDTO,
                        title: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>Step.4 내용 입력</Col>
                <Col md={8}>좌측 핸드폰 영역에 입력하세요</Col>
              </Row>
              <Row>
                <Col md={4}>Step.5 템플릿 유형 선택*</Col>
                <Col md={8} style={{ display: 'flex' }}>
                  <span>
                    <InputD
                      type="radio"
                      checked={!messageDTO?.customFlag || false}
                      onChange={() => {
                        setMessageDTO({
                          ...messageDTO,
                          customFlag: false,
                          content: basicTemplateContent[messageDTO?.messageType],
                        });
                      }}
                    />{' '}
                    기본
                  </span>
                  <span>
                    <InputD
                      type="radio"
                      checked={messageDTO?.customFlag || false}
                      onChange={() => {
                        setMessageDTO({
                          ...messageDTO,
                          customFlag: true,
                        });
                      }}
                    />
                    커스텀
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={4}>Step.6 변수 추가</Col>
                <Col
                  md={8}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr',
                  }}
                  className={!messageDTO?.customFlag ? 'disabled' : ''}
                >
                  <div className="items" onClick={handleVars}>
                    주문인이름
                  </div>
                  <div className="items" onClick={handleVars}>
                    상품명
                  </div>
                  <div className="items" onClick={handleVars}>
                    수령인주소
                  </div>
                  <div className="items" onClick={handleVars}>
                    수령인이름
                  </div>
                  <div className="items" onClick={handleVars}>
                    수령인연락처1
                  </div>
                  <div className="items" onClick={handleVars}>
                    약속일자
                  </div>
                  <div className="items" onClick={handleVars}>
                    약속시간
                  </div>
                  <div className="items" onClick={handleVars}>
                    약속시간대
                  </div>
                  <div className="items" onClick={handleVars}>
                    주기사이름
                  </div>
                  <div className="items" onClick={handleVars}>
                    주기사연락처
                  </div>
                  <div className="items" onClick={handleVars}>
                    송장번호
                  </div>
                  <div className="items" onClick={handleVars}>
                    배송조회링크
                  </div>
                  <div className="items" onClick={handleVars}>
                    창고연락처
                  </div>
                  <div className="items" onClick={handleVars}>
                    창고명
                  </div>
                  <div className="items" onClick={handleVars}>
                    주문인이름(마스킹)
                  </div>
                  <div className="items" onClick={handleVars}>
                    수령인주소(마스킹)
                  </div>
                  <div className="items" onClick={handleVars}>
                    수령인이름(마스킹)
                  </div>
                  <div className="items" onClick={handleVars}>
                    수령인연락처1(마스킹)
                  </div>
                  <div className="items" onClick={handleVars}>
                    주기사이름(마스킹)
                  </div>
                  <div className="items" onClick={handleVars}>
                    주기사연락처(마스킹)
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>Step.7 전송 유무*</Col>
                <Col md={8} style={{ display: 'flex' }}>
                  <span>
                    <InputD
                      type="radio"
                      checked={messageDTO?.sendFlag || false}
                      onChange={() => {
                        setMessageDTO({
                          ...messageDTO,
                          sendFlag: true,
                        });
                      }}
                    />
                    전송
                  </span>
                  <span>
                    <InputD
                      type="radio"
                      checked={!messageDTO?.sendFlag || false}
                      onChange={() => {
                        setMessageDTO({
                          ...messageDTO,
                          sendFlag: false,
                        });
                      }}
                    />
                    미전송
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={4}>Step.8 문자보내기</Col>
                <Col md={8} style={{ display: 'flex' }}>
                  <InputD
                    value={sampleMsgPhoneNum || ''}
                    onChange={(e) => {
                      setSampleMsgPhoneNum(e.target.value);
                    }}
                  />
                  <Button
                    className="btn btn-etc ssm"
                    onClick={() => {
                      if (!sampleMsgPhoneNum) {
                        alert('문자메시지를 전송할 번호를 입력하세요!');
                      } else {
                        messageSend({
                          content: messageDTO?.content,
                          phone: sampleMsgPhoneNum,
                        });
                      }
                    }}
                  >
                    문자전송
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </>
      }
    />
  );
};
