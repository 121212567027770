import { USER_SIGN_REFRESH } from 'envVar';
import axios from 'axios';

export const postRefreshToken = async () => {
  return await axios.post(process.env.REACT_APP_API_URL + USER_SIGN_REFRESH, null, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
    },
  });
};
