import { ReactNode, useEffect } from 'react';
import { setNowTab } from 'redux/services/menuSlice';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useLocation } from 'react-router-dom';
import { setDocumentTitle } from 'common/util/html';

export interface ITopTab {
  titles: Array<string>;
  containers: Array<ReactNode>;
}

export const dontSaveThisPath = [
  //
  // '/cargoManifestTask',
];

export const TopTab = ({ titles, containers }: ITopTab) => {
  const { loading } = useSelector((state: RootState) => state.menu);
  const { useableScreens } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const { tabs } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    const tabNum = localStorage.getItem(location.pathname) || 0;
    dispatch(
      setNowTab({
        key: location.pathname,
        value: Number(tabNum) || 0,
        label: titles[tabNum],
      }),
    );
  }, []);

  useEffect(() => {
    if (tabs?.[location.pathname] >= 0) {
      setDocumentTitle(titles?.[tabs?.[location.pathname]]);
      if (!dontSaveThisPath?.includes(location.pathname)) localStorage.setItem(location.pathname, tabs?.[location.pathname] + '');
    }
  }, [tabs]);

  return (
    <>
      {useableScreens && Object.keys(useableScreens)?.includes(location.pathname) ? (
        <>
          {!loading && <div style={{ padding: 10, position: 'absolute', top: 0, left: 0 }}>해당 페이지에 접근 가능한 탭화면이 없습니다</div>}
          <div className={`div-top-tab`}>
            {titles?.map((title: string, index: number) => {
              return (
                <div
                  key={`toptap_${index}`}
                  className={tabs?.[location.pathname] === index ? 'bordering-current' : 'bordering'}
                  onClick={() => {
                    dispatch(
                      setNowTab({
                        key: location.pathname,
                        value: index,
                      }),
                    );
                  }}
                >
                  <li>{title}</li>
                </div>
              );
            })}
          </div>
          <div className="container-wrapper">
            {containers?.map((container, index) => {
              if (tabs?.[location.pathname] === index)
                return (
                  <div key={`container_${index}`} className="active">
                    {container}
                  </div>
                );
            })}
          </div>
        </>
      ) : (
        <>
          <div style={{ padding: 10 }}>
            해당 페이지에 접근 권한이 없습니다
            <br />
            관리자에게 문의해주세요
          </div>
        </>
      )}
    </>
  );
};
