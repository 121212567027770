import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { defaultGridProps } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import * as IGrid from 'aui-grid';

import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { InventoryModificationListDTO } from 'interface/warehouse';
import { inventoryService } from '../../_service/service';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { PagingListDTO } from 'interface/util';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const gridRef = useRef<AUIGrid>();

  const { masterOptions } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    fetchList();
  }, []);

  const columns: IGrid.Column[] = [
    {
      headerText: '재고조정번호',
      dataField: 'inventoryModificationSeq',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '로케이션',
      dataField: 'locationCode',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '제품',
      dataField: 'model',
      width: 350,
    },
    {
      headerText: '갯수',
      children: [
        { headerText: '조정전', dataField: 'beforeQuantity' },
        {
          headerText: '조정후',
          dataField: 'afterQuantity',
        },
      ],
    },
    {
      headerText: '재고유형',
      dataField: 'availableStatusKr',
    },
    {
      headerText: '조정사유',
      dataField: 'reason',
      width: 250,
    },
    {
      headerText: '신청자',
      dataField: 'requestId',
    },
    {
      headerText: '승인자',
      dataField: 'confirmId',
    },
    {
      headerText: '조정자',
      dataField: 'modifier',
    },
    {
      headerText: '승인일',
      dataField: 'confirmDatetime',
    },
    {
      headerText: '확정여부',
      dataField: 'statusKr',
    },
  ];

  const labellingKr = (data: PagingListDTO<InventoryModificationListDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        centerCodeKr: selectlabel(row.centerCode, masterOptions?.CENTER),
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        availableStatusKr: row.availableStatus === 'AVAILABLE' ? '양품' : '불용',
        statusKr: row.status === 'CONFIRM' ? '확정' : '',
      };
    });

    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchList = async () => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr({ list: await inventoryService.getInventoryAction(null, 'modification/list', false) });
    gridRef.current.setGridData(list);
    dispatch(setLoading(null));
    return list;
  };

  const downloadExcel = async (type: 'main') => {
    dispatch(setLoading('GET'));
    gridRef.current.exportAsXlsx({ fileName: '과거이력조회' });
    dispatch(setLoading(null));
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'INVENTORYMODIFICATIONHISTORYEXCEL') {
      downloadExcel('main');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');

  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, []);

  return (
    <div className="page-content">
      <div className="presenterGridBox">
        {functionBtns && <div className="grid-button-area only-right">{functionBtns}</div>}
        <GridBox gridRef={gridRef} columns={columns} gridProps={{ height: 700, showRowAllCheckBox: false, showRowCheckColumn: false }} />
      </div>
    </div>
  );
};

export default Index;
