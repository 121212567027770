import { useEffect, useRef, useState } from 'react';

// redux
import { useSelector } from 'react-redux';

//components
import CommonModalNew from 'components/modal/commonModalNew';
import { categoryChanger } from '..';
import { RootState } from 'redux/store';
import { TransactionStatementDTO } from 'interface/order';
import { httpClient } from 'common/http-client/axiosConfig';

// styled
import styled from 'styled-components';
import { stringToThousandComma } from 'common/util/counting';

import ReactToPrint from 'react-to-print';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

const Title = styled.div`
  text-align: center;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
`;
const OneRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface PdfPopupProp {
  pdfObj: IPdfObj;
  setPdfObj: React.Dispatch<React.SetStateAction<IPdfObj>>;
}

interface IPdfObj {
  visible: boolean;
  seq: string;
  from: string;
}
export const PdfPopup = ({ pdfObj, setPdfObj }: PdfPopupProp) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [infoData, setInfoData] = useState<TransactionStatementDTO>();

  const fetchDetail = async (seq: string) => {
    await httpClient.get(`/order/transaction/statement/${categoryChanger('/' + pdfObj?.from)}/detail?transactionStatementSeq=${pdfObj?.seq}`).then((rs) => {
      if (rs?.status === 200) {
        setInfoData(rs?.data);
      }
    });
  };
  useEffect(() => {
    if (pdfObj?.seq) {
      fetchDetail(pdfObj?.seq);
    }
  }, []);

  const wrapperRef = useRef();
  return (
    <CommonModalNew
      style={{ width: 1200 }}
      visible={pdfObj?.visible}
      setVisible={() => {
        setPdfObj(null);
      }}
      title={
        <>
          <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
        </>
      }
      children={
        <div ref={wrapperRef} style={{ padding: 40 }}>
          <OneRow>
            <Title>거래명세서</Title>
          </OneRow>
          <OneRow>
            <div style={{ width: '20%' }}>
              <table className="border-table">
                <thead>
                  <tr>
                    <th>일련번호</th>
                    <td>{infoData?.transactionStatementSeq}</td>
                  </tr>
                </thead>
              </table>
            </div>
            <div style={{ width: '20%' }}></div>
          </OneRow>
          <OneRow>
            <table className="border-table">
              <thead>
                <tr>
                  <th rowSpan={4}>공급자</th>
                  <th>사업자번호</th>
                  <td colSpan={3}>{infoData?.providerBusinessNumber}</td>
                  <th rowSpan={4}>공급받는자</th>
                  <th>사업자번호</th>
                  <td colSpan={3}>{infoData?.receiverBusinessNumber}</td>
                </tr>
                <tr>
                  <th>상호</th>
                  <td>{infoData?.providerCompanyName}</td>
                  <th>대표자명</th>
                  <td>{infoData?.providerName}</td>
                  <th>상호</th>
                  <td>{infoData?.receiverCompanyName}</td>
                  <th>대표자명</th>
                  <td>{infoData?.providerName}</td>
                </tr>
                <tr>
                  <th>사업장 주소</th>
                  <td colSpan={3}>
                    {infoData?.providerOfficeAddress} {infoData?.providerOfficeAddressDetail}
                  </td>
                  <th>사업장 주소</th>
                  <td colSpan={3}>
                    {infoData?.receiverOfficeAddress} {infoData?.receiverOfficeAddressDetail}
                  </td>
                </tr>
                <tr>
                  <th>업태</th>
                  <td>{infoData?.providerIndustry}</td>
                  <th>종목</th>
                  <td>{infoData?.providerProduct}</td>
                  <th>업태</th>
                  <td>{infoData?.receiverIndustry}</td>
                  <th>종목</th>
                  <td>{infoData?.receiverProduct}</td>
                </tr>
              </thead>
            </table>
          </OneRow>

          <OneRow>
            <table className="border-table">
              <thead>
                <tr>
                  <th>작성일</th>
                  <th>시작일</th>
                  <th>종료일</th>
                  <th>발급일</th>
                  <th>전기일</th>
                  <th rowSpan={2}>비고란</th>
                  <td colSpan={4} rowSpan={2}>
                    {infoData?.remark}
                  </td>
                </tr>
                <tr>
                  <td>{infoData?.issueDate}</td>
                  <td>{infoData?.startDate}</td>
                  <td>{infoData?.endDate}</td>
                  <td>{infoData?.registerDate}</td>
                  <td>{infoData?.postingDate}</td>
                </tr>
              </thead>
            </table>
          </OneRow>

          <OneRow>
            <table className="border-table">
              <thead>
                <tr>
                  <th>순번</th>
                  <th>입력 구분</th>
                  <th colSpan={2}>품목</th>
                  <th>수량</th>
                  <th>공급가액</th>
                  <th>부가세</th>
                  <th>합계</th>
                  <th colSpan={2}>비고란</th>
                </tr>
              </thead>
              <tbody className="textAlignCenter">
                {infoData?.details?.map((ele, idx) => {
                  return (
                    <tr key={`info_detail_${idx}`}>
                      <td>{idx + 1}</td>
                      <td>{MASTER_OBJ?.INPUT_CATEGORY?.[ele?.inputCategory]}</td>
                      <td colSpan={2}>{ele.item}</td>
                      <td>{stringToThousandComma(ele.quantity + '')}</td>
                      <td>{stringToThousandComma(ele.supplyValue + '')}</td>
                      <td>{stringToThousandComma(ele.valueAddedTax + '')}</td>
                      <td>{stringToThousandComma(ele.total + '')}</td>
                      <td colSpan={2}>{ele.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </OneRow>

          <OneRow>
            <table className="border-table">
              <thead className="textAlignCenter">
                <tr>
                  <th colSpan={4}>합계</th>
                  <td>{stringToThousandComma(infoData?.quantity + '')}</td>
                  <td>{stringToThousandComma(infoData?.supplyValue + '')}</td>
                  <td>{stringToThousandComma(infoData?.valueAddedTax + '')}</td>
                  <td>{stringToThousandComma(infoData?.total + '')}</td>
                  <td colSpan={2}></td>
                </tr>
              </thead>
            </table>
          </OneRow>
        </div>
      }
    />
  );
};
