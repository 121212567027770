import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD } from 'components/reactstrap/reactstrap';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { PagingListDTO } from 'interface/util';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'reactstrap';
import { setLoading } from 'redux/services/menuSlice';
import { serviceStore } from 'services/services';
import * as IGrid from 'aui-grid';
import { RootState } from 'redux/store';
import { PartnerVendorDTO } from 'pages/ETC/3user/_interface/user';

export interface IGetVendorObj {
  visible: boolean;
  item?: any;
  returns?: any;
}

export const GetVendor = ({ getVendorObj, setGetVendorObj }) => {
  const gridRef = useRef<IPagingGrid>();
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const columns: IGrid.Column[] = [
    {
      headerText: '거래처명',
      dataField: 'partnerName',
    },
    {
      headerText: '거래처코드',
      dataField: 'externalPartnerCode',
    },
    {
      headerText: '운영상태',
      dataField: 'statusKr',
    },
    {
      headerText: '사업자번호',
      dataField: 'businessNumber',
    },
    {
      headerText: '대표자명',
      dataField: 'managerName',
    },
    {
      headerText: '주소',
      dataField: 'addressKr',
      width: 350,
    },
    {
      headerText: '연락처',
      dataField: 'managerPhone',
    },
    {
      headerText: '은행',
      dataField: 'bank',
    },
    {
      headerText: '계좌번호',
      dataField: 'accountNumber',
    },
    {
      headerText: '담당자명',
      dataField: 'leadManagerName',
    },
  ];

  const labellingKr = (data: PagingListDTO<PartnerVendorDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        addressKr: `${row.address} ${row.addressDetail || ''}`,
        statusKr: MASTER_OBJ?.ACTIVE_OP?.[row.status],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj) => {
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore?.userAction('partner/vendor/list/paging', 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      wrappedFetchList(searchObj);
    }
  };

  return (
    <CommonModalNew
      title={'거래처 불러오기'}
      visible={getVendorObj?.visible}
      setVisible={() => {
        setGetVendorObj(null);
      }}
      style={{ width: 1000 }}
      rightTitle={
        <>
          <button
            className="btn btn-secondary ssm"
            onClick={(e) => {
              e.preventDefault();
              const items = gridRef?.current.getCheckedRowItemsAll();
              if (items?.length > 0) {
                setGetVendorObj((prev) => {
                  return {
                    ...prev,
                    returns: items,
                  };
                });
              } else {
                alert('선택된 항목이 없습니다!');
              }
            }}
          >
            선택
          </button>
        </>
      }
      children={
        <>
          <Form onKeyPress={onKeyPress}>
            <Row style={{ marginBottom: 20 }}>
              <Col>
                <label>거래처명</label>
                <InputD
                  value={searchObj?.partnerName || ''}
                  onChange={(e) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        partnerName: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col>
                <label>거래처코드</label>
                <InputD
                  value={searchObj?.externalPartnerCode || ''}
                  onChange={(e) => {
                    setSearchObj((prev) => {
                      return {
                        ...prev,
                        externalPartnerCode: e.target.value,
                      };
                    });
                  }}
                />
              </Col>
              <Col style={{ display: 'flex', paddingTop: 11 }}>
                <button
                  className="btn btn-search ssm"
                  onClick={(e) => {
                    e.preventDefault();
                    wrappedFetchList(searchObj);
                  }}
                >
                  조회
                </button>
              </Col>
            </Row>
          </Form>
          <GridBox
            gridRef={gridRef}
            columns={columns}
            gridProps={{
              height: 400,
              rowCheckDisabledFunction(rowIndex, isChecked, item) {
                return true;
              },
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              rowCheckToRadio: true,
            }}
          />
        </>
      }
    />
  );
};
