import { useEffect, useRef, useState } from 'react';

import AUIGrid from 'modules/Grid';
// component
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { IAccessSearch, SearchBox } from './component/searchBox';
import * as IGrid from 'aui-grid';

import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { PagingListDTO } from 'interface/util';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';
import { AuthGroupDetail, IAuthDetailObj } from './component/authGroupDetail';
import { AccessCenterDTO, AccessDiffCenterDTO, AccessFrontDTO, AccessInfraDTO, AccessPartnerDTO, AccessSalesChannelDTO } from 'interface/user';
import { IinviteObj, InvitePopup } from './component/invite';
import { AuthGroupUser } from './component/authGroupUser';

export const translatorAPI = {
  MANAGE_USERGROUP_USERS: 'user',
  MANAGE_USERGROUP_SCREEN: 'front',
  MANAGE_USERGROUP_PARTNERS: 'partner',
  MANAGE_USERGROUP_INFRA: 'infra',
  MANAGE_USERGROUP_CENTERS: 'center',
  MANAGE_USERGROUP_DIFF: 'diff/center',
  MANAGE_USERGROUP_SALESCHANNEL: 'salesChannel',
};

export const tanslatorSeqs = {
  MANAGE_USERGROUP_USERS: 'userId',
  MANAGE_USERGROUP_SCREEN: 'accessFrontSeq',
  MANAGE_USERGROUP_PARTNERS: 'accessPartnerSeq',
  MANAGE_USERGROUP_INFRA: 'accessInfraSeq',
  MANAGE_USERGROUP_CENTERS: 'accessCenterSeq',
  MANAGE_USERGROUP_DIFF: 'accessDiffCenterSeq',
  MANAGE_USERGROUP_SALESCHANNEL: 'accessSalesChannelSeq',
};

export interface IAccessDTO extends AccessCenterDTO, AccessFrontDTO, AccessDiffCenterDTO, AccessInfraDTO, AccessSalesChannelDTO, AccessPartnerDTO {}
// export interface IAccessDetailDTO extends AccessCenterDetailDTO, AccessFrontDetailDTO {}

const Index = ({ tabId }) => {
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [inviteObj, setInviteObj] = useState<IinviteObj>();
  const dispatch = useDispatch();
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();

  const labellingKr = (data: PagingListDTO<any>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        centerCodeKr: MASTER_OBJ?.['CENTER_AUTH']?.[row.centerCode],
        partnerSeqKr: MASTER_OBJ?.['SELLER_SELLER_AUTH']?.[row.partnerSeq],
        userCodeKr: MASTER_OBJ?.['USER_CODE']?.[row.userCode],
        useFlagKr: MASTER_OBJ?.USE_FLAG?.[row.useFlag],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: IAccessSearch) => {
    console.log(searchObj);
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore?.userAction(`access/${translatorAPI[tabId]}/paging`, 'GET', searchObj, null, true))?.data);
    dispatch(setLoading(null));
    return dataKr;
  };

  const deleteRows = async (e) => {
    const checked = gridRef.current.getCheckedRowItemsAll();
    if (checked?.length > 0) {
      if (window.confirm('선택한 조직을 정말로 삭제하시겠습니까?')) {
        dispatch(setLoading('GET'));
        const rs = await serviceStore.userAction(
          `access/${translatorAPI[tabId]}/delete`,
          'POST',
          null,
          checked?.map((ele) => {
            return {
              seq: ele?.[tanslatorSeqs[tabId]],
            };
          }),
        );
        if (rs?.status === 200) {
          alert('삭제되었습니다');
          wrappedFetchList(searchObj);
        }
        dispatch(setLoading(null));
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };
  const [authDetailObj, setAuthDetailObj] = useState<IAuthDetailObj>();
  const fetchDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    console.log(e.item);
    setAuthDetailObj({
      tabId,
      visible: true,
      fetchFn: (searchObj) => wrappedFetchList(searchObj),
      searchObj: JSON.parse(e.item?.searchObj),
      item: e.item,
    });
    dispatch(setLoading(null));
  };

  const columns: { [key: string]: IGrid.Column[] } = {
    USERS: [
      {
        headerText: '상세보기',
        renderer: {
          type: IGrid.RendererKind.ButtonRenderer,
          labelText: '상세보기',
          onClick: fetchDetail,
        },
      },
      {
        headerText: '소속 조직',
        dataField: 'groupName',
      },
      {
        headerText: '소속 파트너',
        dataField: 'partnerSeqKr',
      },
      {
        headerText: '이름',
        dataField: 'userName',
      },
      {
        headerText: '아이디',
        dataField: 'userId',
      },
      {
        headerText: '사용자 유형',
        dataField: 'userCodeKr',
      },
      {
        headerText: '소속 창고',
        dataField: 'centerCodeKr',
      },
      {
        headerText: '이메일',
        dataField: 'email',
      },
      {
        headerText: '연락처',
        dataField: 'phoneNumber',
      },
      {
        headerText: '권한(개)',
        children: [
          {
            headerText: '화면',
            dataField: 'frontQuantity',
          },
          {
            headerText: '판매사',
            dataField: 'partnerQuantity',
          },
          {
            headerText: '인프라',
            dataField: 'infraQuantity',
          },
          {
            headerText: '창고',
            dataField: 'centerQuantity',
          },
          {
            headerText: '타창고',
            dataField: 'diffCenterQuantity',
          },
          {
            headerText: '판매채널',
            dataField: 'salesChannelQuantity',
          },
        ],
      },
      {
        headerText: '사용자<br/>상태값',
        dataField: 'useFlagKr',
      },
    ],
    OTHERS: [
      {
        headerText: '상세보기',
        renderer: {
          type: IGrid.RendererKind.ButtonRenderer,
          labelText: '상세보기',
          onClick: fetchDetail,
        },
      },
      {
        headerText: '소속조직',
        dataField: 'groupName',
      },
      {
        headerText: '권한 그룹명',
        dataField: 'title',
      },
      {
        headerText: '설명',
        dataField: 'description',
      },
      {
        headerText: '적용된 권한',
        dataField: 'preview',
      },
      {
        headerText: '그룹 사용자(명)',
        dataField: 'userQuantity',
      },
      {
        headerText: '사용자<br/>상태값',
        dataField: 'useFlagKr',
      },
    ],
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      groupSeq: reduxUserInfo?.groupSeq,
    },
    gridRef,
    fetchPaging,
  });

  return (
    <div className="page-content">
      {inviteObj?.visible && <InvitePopup inviteObj={inviteObj} setInviteObj={setInviteObj} />}
      {authDetailObj?.visible && authDetailObj?.tabId?.includes('USERS') && <AuthGroupUser authDetailObj={authDetailObj} setAuthDetailObj={setAuthDetailObj} />}
      {authDetailObj?.visible && !authDetailObj?.tabId?.includes('USERS') && <AuthGroupDetail authDetailObj={authDetailObj} setAuthDetailObj={setAuthDetailObj} />}

      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} TARGET={tabId?.split('MANAGE_USERGROUP_')[1]} />
      </div>
      <div className="presenterGridBox">
        <div className="grid-button-area">
          {tabId === 'MANAGE_USERGROUP_USERS' ? (
            <>
              <div
                className="blue"
                onClick={() => {
                  setInviteObj({
                    visible: true,
                  });
                }}
              >
                조직원 초대
              </div>
              <div className="red" onClick={deleteRows}>
                삭제
              </div>
            </>
          ) : (
            <>
              <div
                className="blue"
                onClick={() => {
                  setAuthDetailObj({
                    tabId,
                    visible: true,
                    fetchFn: (searchObj) => wrappedFetchList(searchObj),
                    searchObj,
                    item: null,
                  });
                }}
              >
                권한그룹 추가
              </div>
              <div className="red" onClick={deleteRows}>
                권한그룹 삭제
              </div>
            </>
          )}
        </div>
        <GridBox
          gridRef={gridRef}
          columns={columns[tabId === 'MANAGE_USERGROUP_USERS' ? 'USERS' : 'OTHERS']}
          gridProps={{
            fixedColumnCount: 1,
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns[tabId]} />
      </div>
    </div>
  );
};

export default Index;
