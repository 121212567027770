import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import CommonModalNew from 'components/modal/commonModalNew';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';
import { InvoiceScheduleUpdateDTO, InvoiceSearchDTOForOrder, OrderSearchDTO } from '../../_interface/order';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { RAWDATA, setLoading } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';

interface ICreateObj {
  centerCode?: string;
  deliveryDateFlag?: boolean;
  deliveryDate?: string;
  userId?: string;
  assistUserId?: string;
  internUserId?: string;
  confirmFlag?: boolean;
}

export interface IDividerObj {
  visible?: boolean;
  searchObj?: OrderSearchDTO | InvoiceSearchDTOForOrder;
  items?: string[];
  fetchFn?: (data) => void;
}

export const DivideOrder = ({ divideOrderObj, setDivideOrderObj }) => {
  const dispatch = useDispatch();
  const { MASTER_OPS, MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const [createObj, setCreateObj] = useState<InvoiceScheduleUpdateDTO>();

  const [driverInCenterOption, setDriverInCenterOption] = useState<OptionItem[]>();

  useEffect(() => {
    if (createObj?.centerCode) {
      const filtered = MASTER_OPS?.[`${RAWDATA}DRIVER_AUTH`]?.filter((ele) => ele.useFlag && ele.centerCode === createObj?.centerCode);
      const driverIncenterOption = filtered?.map((user) => {
        return {
          value: user.userId,
          label: user.driverName,
        };
      });
      setDriverInCenterOption(driverIncenterOption);
    }
  }, [createObj?.centerCode]);

  const divideOrderAPI = async (createObj) => {
    const data = {
      ...createObj,
      deliveryDateFlag: createObj?.deliveryDateFlag || false,
      confirmFlag: createObj?.confirmFlag || false,
      invoiceSeqs: divideOrderObj?.items,
    };
    dispatch(setLoading('PUT'));

    const rs = await serviceStore.orderAction('schedule/change/invoice/list', 'POST', null, data);
    if (rs?.status === 200) {
      alert(rs?.data?.message);
      if (divideOrderObj?.fetchFn && divideOrderObj?.searchObj) divideOrderObj?.fetchFn(divideOrderObj?.searchObj);
      setDivideOrderObj(null);
    }
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title="주문분배"
      style={{ width: 350 }}
      visible={divideOrderObj?.visible}
      setVisible={() => {
        setDivideOrderObj(null);
      }}
      rightTitle={
        <>
          <button
            className={'btn btn-secondary ssm'}
            onClick={() => {
              if (createObj?.deliveryDateFlag && !createObj?.deliveryDate && createObj?.confirmFlag) {
                alert('변경할 지정일 없이 스케줄확정처리 할 수 없습니다');
              } else {
                divideOrderAPI(createObj);
              }
            }}
          >
            분배
          </button>
        </>
      }
      children={
        <>
          <Row>
            <Col style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
              <input
                id="confirm"
                type="checkbox"
                checked={createObj?.confirmFlag || false}
                onChange={() => {
                  if (createObj?.deliveryDateFlag && !createObj?.deliveryDate) {
                    alert('지정일 없이 스케줄확정처리 할 수 없습니다');
                  } else {
                    setCreateObj({
                      ...createObj,
                      confirmFlag: !createObj?.confirmFlag,
                    });
                  }
                }}
              />
              <label htmlFor="confirm">스케줄확정 포함</label>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center', height: 50 }}>
              <div>
                총 <span className="b">{divideOrderObj?.items?.length}</span>건에 대하여 주문분배를 진행합니다
              </div>
            </Col>
          </Row>
          <Row style={{ flexDirection: 'column', height: 285 }}>
            <Col>
              <label className="col-form-label" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>지정일 날짜선택</span>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    id="deliveryFlag"
                    style={{ marginRight: 5, marginBottom: 2 }}
                    type="checkbox"
                    checked={createObj?.deliveryDateFlag}
                    onChange={() => {
                      setCreateObj({
                        ...createObj,
                        deliveryDateFlag: !createObj?.deliveryDateFlag,
                        deliveryDate: null,
                        confirmFlag: false,
                      });
                    }}
                  />
                  <label htmlFor="deliveryFlag">변경 여부</label>
                </span>
              </label>
              <FlatpickrD
                disabled={!createObj?.deliveryDateFlag}
                options={{
                  mode: 'single',
                }}
                value={createObj?.deliveryDate}
                onReset={() => {
                  setCreateObj({
                    ...createObj,
                    deliveryDate: null,
                  });
                }}
                onChange={(date) => {
                  setCreateObj({
                    ...createObj,
                    deliveryDate: returnDateyyyymmdd(date[0]),
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">창고선택</label>
              <SelectD
                value={
                  createObj?.centerCode
                    ? {
                        value: createObj?.centerCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[createObj?.centerCode],
                      }
                    : null
                }
                options={MASTER_OPS?.CENTER_AUTH}
                onChange={(option) => {
                  setCreateObj({
                    ...createObj,
                    centerCode: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">주기사선택</label>
              <SelectD
                isDisabled={!createObj?.centerCode}
                value={
                  createObj?.userId
                    ? {
                        value: createObj?.userId,
                        label: MASTER_OBJ?.DRIVER_WHOLE?.[createObj?.userId],
                      }
                    : null
                }
                options={driverInCenterOption}
                onChange={(option) => {
                  setCreateObj({
                    ...createObj,
                    userId: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">부기사선택</label>
              <SelectD
                isDisabled={!createObj?.centerCode}
                value={
                  createObj?.assistUserId
                    ? {
                        value: createObj?.assistUserId,
                        label: MASTER_OBJ?.DRIVER_WHOLE?.[createObj?.assistUserId],
                      }
                    : null
                }
                options={driverInCenterOption}
                onChange={(option) => {
                  setCreateObj({
                    ...createObj,
                    assistUserId: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">동행기사선택</label>
              <SelectD
                isDisabled={!createObj?.centerCode}
                value={
                  createObj?.internUserId
                    ? {
                        value: createObj?.internUserId,
                        label: MASTER_OBJ?.DRIVER_WHOLE?.[createObj?.internUserId],
                      }
                    : null
                }
                options={driverInCenterOption}
                onChange={(option) => {
                  setCreateObj({
                    ...createObj,
                    internUserId: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};
