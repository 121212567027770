import { COLORS, VALUES } from 'common/constants/appearance';
import { menuIcon, MenusChildProps } from './Sidebar';
import LDSListGroup, { LDSEmptyListGroup } from '../modules/ListGroup';
import LDSButton from '../atoms/Button';
import LDSDivider from '../atoms/Divider';
import { Fragment } from 'react';
import LDSListItem from '../modules/ListItem';
import { DESCRIPTIONS, MENUS } from 'common/constants/localization';
import { useLocation, useNavigate } from 'react-router-dom';
import LDSListHeader from '../modules/ListHeader';
import { useDispatch } from 'react-redux';
import { setShowDropdown } from 'redux/services/portalSlice';
import Notification from './Notification';

export default function LDSTwoDepthMenuList({ collapsed, menus, setMenus, expandedMenuNames, setExpandedMenuNames, alarms, setGongjiObj, hideMenus }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const screenUrl = location.pathname?.split('/')?.[2];

  return (
    <>
      {/* 검색어가 없을 때 */}
      {!collapsed &&
        !hideMenus &&
        menus?.map(
          (menu, index) =>
            menu?.menuName !== '홈' && (
              <Fragment key={`${menu?.menuName}_${index}`}>
                <LDSListGroup $padding={8} $gap={4} data-group-id={`${menu?.menuName}_${index}`} className={menu?.menuName === MENUS.FAVORITE_KR ? 'favorite' : ''}>
                  {menu?.headerType && (
                    <LDSListHeader
                      $type={menu?.headerType}
                      $title={menu?.menuName}
                      $isExpanded={menu?.headerType === 'TOGGLE' && menu?.expanded ? true : false}
                      $icon={menuIcon[menu?.menuName]}
                      onClick={() => {
                        const prevMenus = [...menus];
                        const currentSection = prevMenus?.find((prevMenu) => prevMenu?.menuName === menu?.menuName);
                        if (currentSection) {
                          currentSection.expanded = !currentSection?.expanded;
                        }
                        setMenus(prevMenus);

                        const newMenuNames = expandedMenuNames?.filter((menuname) => menuname !== menu?.menuName);
                        currentSection?.expanded ? setExpandedMenuNames([...expandedMenuNames, menu?.menuName]) : setExpandedMenuNames(newMenuNames);
                      }}
                      $tooltip={{
                        $description: `메뉴 ${menu?.expanded ? '접기' : '펼치기'}`,
                        $direction: 'RIGHT',
                        $anchorPosition: 'CENTER',
                      }}
                    />
                  )}
                  {menu.children.length > 0 && menu.expanded
                    ? menu?.children?.map((child: MenusChildProps, index: number) => (
                        <LDSListItem
                          key={child?.menuType + child?.menuName + index}
                          $type={child?.menuType === 'MENU_ACTION' ? 'MENU_ACTION' : 'MENU'}
                          $label={child?.menuName}
                          $icon={menuIcon[child?.menuName]}
                          $status={screenUrl === `${child?.url?.replace('/', '')}` ? 'ACTIVE' : 'DEFAULT'}
                          onClick={() => navigate(`/main${child.url}`)}
                          autoFocus={screenUrl === `${child?.url?.replace('/', '')}` ? true : false}
                          $navigate={`/main${child?.url}`}
                        />
                      ))
                    : menu?.children?.map((child: MenusChildProps, index: number) => (
                        <Fragment key={`${child?.menuName}_${index}`}>
                          {/* 메뉴가 접힘 상태일 때, active 메뉴만 렌더링함  */}
                          {location.pathname.split('/')?.[2] === `${child?.url?.replace('/', '')}` && (
                            <LDSListItem
                              key={child?.menuType + child?.menuName + index}
                              $type={child?.menuType === 'MENU_ACTION' ? 'MENU_ACTION' : 'MENU'}
                              $label={child?.menuName}
                              $icon={menuIcon[child?.menuName]}
                              $status={screenUrl === `${child?.url?.replace('/', '')}` ? 'ACTIVE' : 'DEFAULT'}
                              onClick={() => navigate(`/main${child?.url}`)}
                              $navigate={`/main${child?.url}`}
                            />
                          )}
                        </Fragment>
                      ))}
                  {menu?.menuName === MENUS.FAVORITE_KR && menu?.children?.length === 0 && <LDSEmptyListGroup>{DESCRIPTIONS.FAVORITE_EMPTY_KR}</LDSEmptyListGroup>}
                </LDSListGroup>
                <LDSDivider $direction={'H'} $color={COLORS.GRAY_03} $value={'FULL'} $margin={index > 1 && 10} $spacing={'SM'} />
              </Fragment>
            ),
        )}

      {/* Sidebar가 접힌 상태일 때 */}
      {collapsed &&
        menus?.map(
          (menu, index) =>
            menu?.menuName !== MENUS.SEARCH_KR && (
              <Fragment key={`${menu?.menuName}_${index}`}>
                <LDSListGroup $padding={8} $gap={8} key={`${menu?.menuName}_${index}`}>
                  {menu?.children?.length > 0 && index === 0 ? (
                    menu?.children?.map((child: MenusChildProps, index: number) => (
                      <LDSButton
                        key={`${child?.menuName}_${index}`}
                        $primary={false}
                        $icon={menuIcon[child?.menuName]}
                        $type={'BUTTON'}
                        $showIcon={true}
                        $size={'MD'}
                        $width={40}
                        $linkTo={child?.url}
                        $badgeCount={child?.menuName !== MENUS.NOTIFICATION_KR ? null : alarms?.unreadCount > 9 ? '9+' : String(alarms?.unreadCount || '')} // TODO: 알림 갯수가 있을 경우 값 적용
                        $status={screenUrl === `${child?.url?.replace('/', '')}` ? 'ACTIVE' : 'DEFAULT'}
                        $tooltip={{ $description: child?.menuName, $direction: 'RIGHT', $anchorPosition: 'CENTER' }}
                        $dropdown={
                          child?.menuName === MENUS.NOTIFICATION_KR && {
                            $position: 'RIGHT',
                            $align: 'TOP',
                            $width: 280,
                            $maxHeight: VALUES.NOTIFICATION_MIN_HEIGHT,
                            children: <Notification setGongjiObj={setGongjiObj} />,
                          }
                        }
                        onClick={() => child?.menuName !== MENUS.NOTIFICATION_KR && navigate(`/main${child?.url}`)}
                      />
                    ))
                  ) : (
                    <LDSButton
                      $primary={false}
                      $icon={menuIcon[menu?.menuName]}
                      $type={'BUTTON'}
                      $showIcon={true}
                      $size={'MD'}
                      $width={40}
                      $tooltip={{ $description: menu?.menuName, $direction: 'RIGHT', $anchorPosition: 'CENTER' }}
                      $status={menu?.children?.find((menu) => screenUrl === `${menu?.url?.replace('/', '')}`) && menu?.menuName !== '즐겨찾기' ? 'ACTIVE' : 'DEFAULT'}
                      $dropdown={{
                        $position: 'RIGHT',
                        $align: 'TOP',
                        $width: 240,
                        children: (
                          <>
                            <LDSListGroup $gap={4} $padding={8}>
                              <LDSListHeader $icon={menuIcon[menu?.menuName]} $isExpanded $title={menu?.menuName} $type="STATIC" />
                              {menu?.children?.map((child: MenusChildProps, index: number) => (
                                <LDSListItem
                                  $label={child?.menuName}
                                  $type="MENU"
                                  key={`${child?.menuName}_${index}`}
                                  $status={screenUrl === `${child?.url?.replace('/', '')}` ? 'ACTIVE' : 'DEFAULT'}
                                  onClick={() => {
                                    dispatch(setShowDropdown(''));
                                    child?.menuName !== MENUS.NOTIFICATION_KR && navigate(`/main${child?.url}`);
                                  }}
                                  autoFocus={screenUrl === `${child?.url?.replace('/', '')}` ? true : false}
                                  $navigate={`/main${child?.url}`}
                                />
                              ))}
                              {menu?.menuName === MENUS.FAVORITE_KR && menu?.children?.length === 0 && <LDSEmptyListGroup>{DESCRIPTIONS.FAVORITE_EMPTY_KR}</LDSEmptyListGroup>}
                            </LDSListGroup>
                          </>
                        ),
                      }}
                    />
                  )}
                </LDSListGroup>
                {index <= 1 && <LDSDivider $direction={'H'} $color={COLORS.GRAY_03} $value={'FULL'} $margin={10} $spacing={'ZERO'} />}
              </Fragment>
            ),
        )}
    </>
  );
}
