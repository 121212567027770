import { OptionItem } from 'common/master/codeMasterReturnHelper';
import CommonModalNew from 'components/modal/commonModalNew';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { InfraCreateDTO } from 'interface/warehouse';
import { userService } from 'pages/ETC/3user/_service/service';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { RootState } from 'redux/store';

export interface ICreate {
  visible?: boolean;
  searchObj?: string;
  fetchFn?: (data) => void;
}

interface ICreatePopup {
  createObj: ICreate;
  setCreateObj: React.Dispatch<React.SetStateAction<ICreate>>;
}

export const Create = ({ createObj, setCreateObj }: ICreatePopup) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [dataDTO, setDataDTO] = useState<InfraCreateDTO>();

  const letsCreate = async (e) => {
    if (!dataDTO?.infraName) {
      alert('인프라명을 입력하세요');
    } else {
      const rs = await userService.createInfra(dataDTO);
      if (rs?.status === 200) {
        alert('인프라가 생성되었습니다');
        window.location.reload();
      }
    }
  };
  return (
    <CommonModalNew
      //
      style={{ width: 350 }}
      title="인프라등록"
      visible={createObj?.visible}
      setVisible={() => setCreateObj(null)}
      rightTitle={
        <button className="btn btn-blue ssm" onClick={letsCreate}>
          생성
        </button>
      }
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">인프라이름</label>
              <InputD
                value={dataDTO?.infraName || ''}
                onChange={(e) => {
                  setDataDTO({
                    ...dataDTO,
                    infraName: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">인프라유형</label>
              <SelectD
                value={
                  dataDTO?.infraType
                    ? {
                        value: dataDTO?.infraType,
                        label: MASTER_OBJ?.INFRA_TYPE?.[dataDTO?.infraType],
                      }
                    : null
                }
                options={MASTER_OPS?.INFRA_TYPE}
                onChange={(option) => {
                  setDataDTO({
                    ...dataDTO,
                    infraType: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};
