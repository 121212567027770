export const defaultDayOfweek = {
  mon: false,
  tue: false,
  wed: false,
  thu: false,
  fri: false,
  sat: false,
  sun: false,
};

export const DayCheckBox = (props) => {
  const { dayOfweek, setDayOfweek, style } = props;

  const DAY_OF_THE_WEEK = Object.freeze([
    {
      title: '월',
      type: 'mon',
    },
    {
      title: '화',
      type: 'tue',
    },
    {
      title: '수',
      type: 'wed',
    },
    {
      title: '목',
      type: 'thu',
    },
    {
      title: '금',
      type: 'fri',
    },
    {
      title: '토',
      type: 'sat',
    },
    {
      title: '일',
      type: 'sun',
    },
  ]);

  const handleChange = (e) => {
    const copied = JSON.parse(JSON.stringify(dayOfweek));
    if (e.target.className) {
      const when = e.target.className;
      copied[when] = !copied[when];
    }
    setDayOfweek(copied);
  };

  return (
    <>
      {DAY_OF_THE_WEEK.map((obj, idx) => {
        return (
          <div key={'checkboxday_' + idx} style={{ display: 'inline-block' }}>
            <input style={style} type="checkbox" id={`dayCheckBox${idx}`} checked={dayOfweek[obj.type]} className={obj.type} onChange={handleChange} />
            <label style={style} htmlFor={`dayCheckBox${idx}`}>
              {obj.title}
            </label>
          </div>
        );
      })}
    </>
  );
};
