import { useEffect } from 'react';
import Tab1 from './accident';

// redux
import { useSelector, useDispatch } from 'react-redux';

// options
import { nullingMASTER } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';
import { TopTabController } from 'components/template/TopTabController';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';

export const MANAGE_PARCEL_ACCIDENT_MASTER = [
  'CENTER_WHOLE',
  'CENTER_AUTH',
  'SELLER_WHOLE',
  'SELLER_FORWARDER_AUTH',
  //
  'PORT_CODE',
  'ACCIDENT_TYPE',
  'ACCIDENT_DETAIL',
  'ACCIDENT_ITEM',
  'INTERIOR_PACKAGE',
  'EXTERIOR_PACKAGE',
  //
  {
    SEARCH_SEQ: [
      { value: 'parcelInvoiceNumber', label: '운송장번호' },
      { value: 'houseNumber', label: 'HBL' },
      { value: 'subNumber', label: 'SBL' },
    ],
  },
  {
    SEARCH_DATE: [
      { value: 'RegisterDate', label: '사고접수일시' },
      { value: 'SolveDate', label: '사고처리일시' },
    ],
  },
];

const Index = () => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_ACCIDENT_MASTER);
  };

  return (
    <>
      {MASTER_OBJ && (
        <TopTabController
          containers={{
            PARCEL_LIST_ACCIDENT: <Tab1 tabId={'PARCEL_LIST_ACCIDENT'} />,
          }}
        />
      )}
    </>
  );
};

export default Index;
