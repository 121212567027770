import { defaultGridProps } from 'common/grid/gridBox';
import CommonModalNew from 'components/modal/commonModalNew';
import AUIGrid, { mappingDefaultOptionToColumn } from 'modules/Grid';
import { useEffect, useRef } from 'react';
import * as IGrid from 'aui-grid';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

export interface IHaldangObj {
  visible?: boolean;
  items?: any[];
}

export const HaldangPopup = ({ haldangObj, setHaldangObj }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const gridRef = useRef<AUIGrid>();
  const halColumns: IGrid.Column[] = [
    {
      headerText: '피킹여부',
      dataField: 'pickFlagKr',
    },
    {
      headerText: '주문취소여부',
      dataField: 'orderCancelFlagKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '재고유형',
      dataField: 'availableTypeKr',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '고객명',
      dataField: 'receiverName',
    },
    {
      headerText: '고객주소',
      dataField: 'receiverAddr',
    },
    {
      headerText: '연락처',
      dataField: 'receiverPhone',
    },
    {
      headerText: '기사출고창고',
      dataField: 'driverCenterCodeKr',
    },
    {
      headerText: '권역정보',
      dataField: 'zipcodeGroupInfo',
    },
    {
      headerText: '설치기사',
      dataField: 'driverName',
    },
    {
      headerText: '기사출고예정일',
      dataField: 'deliveryDate',
    },
    {
      headerText: '주문등록일',
      dataField: 'orderDatetime',
    },
    {
      headerText: '간선번호',
      dataField: 'linehaulSeq',
    },
    {
      headerText: '상차번호',
      dataField: 'loadingStatementSeq',
    },
    {
      headerText: '입고번호',
      dataField: 'transportStatementSeq',
    },
    {
      headerText: '출발창고',
      dataField: 'originalCenterCodeKr',
    },
    {
      headerText: '도착창고',
      dataField: 'destinationCenterCodeKr',
    },
    {
      headerText: '입고예정일',
      dataField: 'promiseUnloadDate',
    },
  ];

  useEffect(() => {
    if (haldangObj?.items?.length > 0) {
      const rows = haldangObj?.items?.map((row) => {
        return {
          ...row,
          centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
          feeTypeKr: MASTER_OBJ?.FEE_TYPE?.[row?.feeType],
          partnerSeqKr: MASTER_OBJ?.SELLER_SELLER_WHOLE?.[row?.partnerSeq],
          modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
          availableTypeKr: MASTER_OBJ?.INVA_TYPE?.[row?.availableType],
          originalCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.originalCenterCode],
          destinationCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.destinationCenterCode],
          driverCenterCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.driverCenterCode],
          receiverAddr: `${row.receiverAddr1 || ''} ${row.receiverAddr2 || ''}`,
          pickFlagKr: row.pickFlag === true ? 'O' : 'X',
          orderCancelFlagKr: haldangObj?.item?.orderCancelFlag === true ? 'O' : 'X',
        };
      });
      gridRef?.current?.setGridData(rows);
    }
  }, []);

  return (
    <CommonModalNew
      title={'할당정보'}
      subTitle="재고정보"
      visible={haldangObj?.visible}
      setVisible={() => setHaldangObj(null)}
      //
      children={
        <>
          <AUIGrid
            //
            ref={gridRef}
            name={'haldang_'}
            columnLayout={mappingDefaultOptionToColumn(halColumns)}
            gridProps={{
              ...defaultGridProps,
              //
              height: 500,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
              editable: true,
            }}
          />
        </>
      }
    />
  );
};
