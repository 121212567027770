import { useEffect, useRef, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  WMS_LINEHAUL_LOADING_DETAIL,
  WMS_LINEHAUL_LOADING_WEB_TEMP,
  WMS_LINEHAUL_LOADING_CONFIRM,
  LINEHAUL_LOADING_CHANGE,
  LINEHAUL_LOADSTAT_IMG,
  WMS_LINEHAUL_LOADING_WEB,
  WMS_LINEHAUL_LOADING_WEB_EXCEL,
  LINEHAUL_ROLLBACK,
  WMS_LINEHAUL_PICKING_DETAIL_INFO,
} from 'envVar';
import Presenter from './presenter';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';
import { setLoading } from 'redux/services/menuSlice';

//bottomSheet
import { defaultBottomSheetObj } from 'components/template/bottomSheets';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { defaultPhotoPopupObj } from 'pages/IPGO/1purchaseOrder/purchaseOrderTab/puchaseOrder/component/photoPopup';

const Container = () => {
  const dispatch = useDispatch();

  //그리드
  const [gridId, setGridId] = useState();
  const inventoryModalGridId = 'trunkLoadingShipping_inventoryModal';
  const [rows, setRows] = useState([]);

  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false);

  //할당정보 모달
  const [inventoryModalObj, setInventoryModalObj] = useState({
    visible: false,
  });
  const [inventoryCancelModalObj, setInventoryCancelModalObj] = useState({
    visible: false,
    data: null,
    searchObj: null,
    fetchListFn: (data) => fetchList(data),
  });
  //옵션
  const { masterOptions } = useSelector((state) => state.menu);
  const { reduxUserInfo } = useSelector((state) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);
  const [searchObj, setSearchObj] = useState({
    loadingStatementSeq: null,
    transportStatementSeq: null,
    originalCenterCode: null,
    destinationCenterCode: null,
    linehaulType: null,
    status: null,
    dateType: null,
    fromDate: null,
    toDate: null,
    ...defaultSearchFilter,
  });

  //페이징
  const [pagingSetting, setPagingSetting] = useState({
    pageNo: 1,
    pageSize: 100,
    fetchNew: false,
  });

  //바텀시트
  const [bottomSheetObj, setBottomSheetObj] = useState(defaultBottomSheetObj);
  const [photoPopupObj, setPhotoPopupObj] = useState(defaultPhotoPopupObj);
  const [driverModalObj, setDriverModalObj] = useState({ visible: false });

  useEffect(() => {
    setGridId(`trunkLoadingShipping_Main${uuidv4()}`);
  }, []);

  const paramingSearchObjToUrl = (url, searchObj) => {
    const searchParams = Object.keys(searchObj);

    let urlWithSearchParams = url;
    let dateType = searchObj['dateType'];
    for (let param of searchParams) {
      if (searchObj[param] !== null && searchObj[param] !== '' && param !== 'fromDate' && param !== 'toDate')
        param === 'dateType' && searchObj.fromDate !== null
          ? (urlWithSearchParams += `&from${dateType}=${searchObj['fromDate']}&to${dateType}=${searchObj['toDate']}`)
          : (urlWithSearchParams += `&${param}=${searchObj[param]}`);
    }

    return urlWithSearchParams;
  };

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'LINEHAUL_OUT_CONFIRM') {
        trunkShippingConfirm(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'LINEHAUL_MANAGE_DRIVER') {
        driverSelectInRow(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'LINEHAUL_OUT_ROLLBACK') {
        handleModalRollBackAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'MODAL_ROLLBACK') {
        handleModalRollBackAction(items, true);
      } else if (getCheckedDataTriggerThisGrid?.type === 'LINEHAUL_OUT_CANCEL') {
        handleModalCancelAction(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'MODAL_CANCEL') {
        handleModalCancelAction(items, true);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  const handleModalRollBackAction = async (data, isParts = false) => {
    if (window.confirm('"이전단계"를 진행하시겠습니까?')) {
      dispatch(setLoading('PUT'));
      let payloadArr;
      if (isParts) {
        payloadArr = [
          {
            linehaulSeq: data[0].linehaulSeq,
            loadingStatementSeq: data[0].loadingStatementSeq,
            loadingOutSeq: data[0].loadingOutSeq,
            transportStatementSeq: data[0].transportStatementSeq,
            inventorySeqs: data?.map((ele) => ele.inventorySeq),
          },
        ];
      } else {
        payloadArr = data.map((ele) => {
          return {
            linehaulSeq: ele.linehaulSeq,
            loadingStatementSeq: ele.loadingStatementSeq,
            loadingOutSeq: ele.loadingOutSeq,
            transportStatementSeq: ele.transportStatementSeq,
          };
        });
      }
      let url = '/warehouse/linehaul/loading/rollback';
      await httpClient.post(url, payloadArr).then((res) => {
        if (res?.status === 200) {
          alert('선택건 이전단계로 변경 완료되었습니다.');
          fetchList(searchObj);
          setSearchObj({ ...searchObj });
        }
      });
      dispatch(setLoading(null));
    }
  };

  const handleModalCancelAction = async (data, isParts = false) => {
    setInventoryCancelModalObj((prev) => {
      return {
        ...prev,
        visible: true,
        searchObj,
        isParts,
        data,
      };
    });
  };

  useEffect(() => {
    if (photoPopupObj?.data) {
      fetchPhotos(photoPopupObj?.data);
    }
  }, [photoPopupObj?.data]);

  const [trunkPrintObj, setTrunkPrintObj] = useState();
  const printAction = async (event) => {
    setTrunkPrintObj({
      visible: true,
      type: 'takeout',
      item: event?.item,
    });
  };

  const fetchList = async (searchObj) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(defaultBottomSheetObj);

    let url = WMS_LINEHAUL_LOADING_WEB_TEMP + `?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
    const search = paramingSearchObjToUrl(url, searchObj);

    httpClient
      .get(search)
      .then((res) => {
        const data = res.data.list || [];
        const maxPage = Math.ceil(res.data.totalCount / pagingSetting?.pageSize);
        data.forEach((row) => {
          row['originalCenterCodeKr'] = selectlabel(row.originalCenterCode, masterOptions?.['CENTER']);
          row['destinationCenterCodeKr'] = selectlabel(row.destinationCenterCode, masterOptions?.['CENTER']);
          row['linehaulTypeKr'] = selectlabel(row.linehaulType, masterOptions?.['LINEHAUL_TYPE']);
          row['statusKr'] = row.status ? selectlabel(row.status, masterOptions?.['LOADING_OUT_STAT']) : '';
          row['shippingBill'] = '간선작업지시서';
          row['photo'] = '사진보기';
          row.searchObj = searchObj;
          row.maxPage = maxPage;
          row.totalCount = res.data.totalCount;
        });
        setRows(data);
        dispatch(setLoading(null));
      })
      .catch((err) => {
        dispatch(setLoading(null));
      });
  };

  const bottomGridRef = useRef();
  const fetchDetailList = async (event) => {
    dispatch(setLoading('GET'));

    let url = WMS_LINEHAUL_LOADING_DETAIL + `/${event?.item?.loadingOutSeq}`;
    const res = await httpClient.get(url);
    const rows = res.data || [];
    rows?.forEach((row) => {
      row['modelGroupKr'] = selectlabel(row.modelGroupLarge, masterOptions?.['MODEL_LARGE']);
      row['partnerSeqKr'] = selectlabel(row.partnerSeq, masterOptions?.['SELLER']);
      row['statusKr'] = row.status ? selectlabel(row.status, masterOptions?.['LOADING_OUT_STAT']) : '';
      row['linehaulType'] = event?.item.linehaulType;
      row['linehaulDate'] = event?.item.linehaulDate;
    });

    const gridButtonhandler = (e) => {
      const id = e.target.id;
      if (id === 'excel-down') {
        bottomGridRef?.current?.exportAsXlsx({ fileName: `간선출차지시_상세` });
      }
    };

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '간선출차지시 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          rows,
          buttons: [
            <div key={`excel-down`} id={`excel-down`} className={`green`} onClick={gridButtonhandler}>
              엑셀다운
            </div>,
          ],
          options: {
            showRowAllCheckBox: false,
            showRowCheckColumn: false,
          },
        },
      ],
    });
    dispatch(setLoading(null));
  };

  const fetchPhotos = async (data) => {
    if (data.loadingStatementSeq) {
      httpClient.get(LINEHAUL_LOADSTAT_IMG + `/${data.loadingStatementSeq}`).then((res) => {
        if (res?.status === 200) {
          setPhotoPopupObj({
            ...photoPopupObj,
            visible: true,
            photos: res.data,
          });
        } else {
        }
      });
    } else {
      alert('출고지시번호가 존재하는 건만 사진열람이 가능합니다');
    }
  };

  const confirmValidation = (items) => {
    let flag = true;
    items.forEach((ele) => {
      if (!ele.promiseUnloadDate) {
        flag = false;
      } else if (!ele.loadingDriverName) {
        alert('운송정보와 입고예정일을 입력해 주세요.');
        flag = false;
      } else if (!ele.loadingDriverCarType) {
        alert('운송정보와 입고예정일을 입력해 주세요.');
        flag = false;
      } else if (!ele.loadingDriverPhone) {
        alert('운송정보와 입고예정일을 입력해 주세요.');
        flag = false;
      }
    });
    return flag;
  };

  const trunkShippingConfirm = async (items = []) => {
    if (window.confirm('확정하시겠습니까?')) {
      if (confirmValidation(items)) {
        const postArr = [];
        items.forEach((ele) => {
          postArr.push({
            palletType: ele.palletType,
            palletQuantity: ele.palletQuantity,
            loadingOutSeq: ele.loadingOutSeq,
            promiseUnloadDate: ele.promiseUnloadDate,
            loadingDriverName: ele.loadingDriverName,
            loadingDriverPhone: ele.loadingDriverPhone,
            loadingDriverCarType: selectlabel(ele.loadingDriverCarType, masterOptions?.CAR_TYPE),
            loadingDriverCarNumber: ele.loadingDriverCarNumber,
          });
        });
        let url = WMS_LINEHAUL_LOADING_CONFIRM;
        httpClient.post(url, postArr).then((res) => {
          if (res.status === 200) {
            alert('확정되었습니다.');
            fetchList(searchObj);
          }
        });
      } else {
        alert('운송정보와 입고예정일이 입력되지 않은 건이 있습니다! 운송정보와 입고예정일을 입력해 주세요.');
      }
    }
  };

  const driverSetting = ({ data, loadingStatementSeqs }) => {
    const rows = window.AUIGrid.getGridData(`#${gridId}`);
    let validFlag = false;
    rows.forEach((row) => {
      if (loadingStatementSeqs.includes(row.loadingStatementSeq)) {
        if (row.transportStatementSeq) {
          validFlag = true;
        } else {
          row.loadingDriverName = data?.driverName;
          row.loadingDriverPhone = data?.phoneNumber;
          row.loadingDriverCarType = data?.carType;
          row.loadingDriverCarNumber = data?.carNumber;
        }
      }
    });
    if (validFlag) alert('출차대기 상태 이외의 건들은 운송정보를 수정에서 제외됩니다');
    setRows(rows);
  };

  const driverSettingAPI = async (arr) => {
    httpClient.put(LINEHAUL_LOADING_CHANGE, arr).then((rs) => {
      if (rs?.status === 200) {
        alert('운송정보가 변경되었습니다.');
        fetchList(searchObj);
      }
    });
  };

  const driverSelectInRow = (data) => {
    const loadingStatementSeqs = data.map((ele) => {
      return ele.loadingStatementSeq;
    });
    if (data?.length > 0) {
      setDriverModalObj({
        ...driverModalObj,
        visible: true,
        loadingStatementSeqs,
        driverSetFn: (data) => driverSetting(data),
      });
    }
  };

  const editChulcha = async (item) => {
    const postArr = [];
    postArr.push({
      loadingOutSeq: item?.loadingOutSeq,
      promiseUnloadDate: item?.promiseUnloadDate,
      loadingDriverName: item?.loadingDriverName,
      loadingDriverPhone: item?.loadingDriverPhone,
      loadingDriverCarType: item?.loadingDriverCarType,
      loadingDriverCarNumber: item?.loadingDriverCarNumber,
      transportStatementSeq: item?.transportStatementSeq,
    });

    driverSettingAPI(postArr);
  };

  const saveMemo = (item) => {
    const data = {
      loadingOutSeq: item.loadingOutSeq,
      loadingOutMemo: item.loadingOutMemo,
    };
    httpClient.put(`/warehouse/linehaul/loading/memo`, data).then((rs) => {
      if (rs?.status === 200) {
        alert('간선출차 메모를 업데이트 했습니다!');
        fetchList(searchObj);
      }
    });
  };

  const onEditEnded = async (event) => {
    if (event?.pid.includes('trunkLoadingShipping_Main')) {
      if (event?.type === 'cellEditEnd') {
        if (event?.item.status === 'OUT' && event?.columnIndex > 14 && event?.columnIndex < 22) {
          let confirm = window.confirm('운송정보를 변경하시겠습니까??');
          if (confirm) {
            editChulcha(event?.item);
          } else {
            fetchList(searchObj);
          }
        }
        if (event?.columnIndex === 11) {
          let confirm = window.confirm('비고 내용을 저장하시겠습니까?');
          if (confirm) {
            saveMemo(event?.item);
          } else {
            fetchList(searchObj);
          }
        }
      }
    }
  };

  const downloadExcel = async (str) => {
    let url;
    if (str === 'main') {
      url = WMS_LINEHAUL_LOADING_WEB + `?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
      const search = paramingSearchObjToUrl(url, searchObj);

      return httpClient.get(search).then((res) => {
        dispatch(setLoading(null));
        const data = res.data || [];
        data.forEach((row) => {
          row['originalCenterCodeKr'] = selectlabel(row.originalCenterCode, masterOptions?.['CENTER']);
          row['destinationCenterCodeKr'] = selectlabel(row.destinationCenterCode, masterOptions?.['CENTER']);
          row['linehaulTypeKr'] = selectlabel(row.linehaulType, masterOptions?.['LINEHAUL_TYPE']);
          row['statusKr'] = selectlabel(row.status, masterOptions?.['LOADING_OUT_STAT']);
          row['shippingBill'] = '간선작업지시서';
          row['modelGroupKr'] = selectlabel(row.modelGroupLarge, masterOptions?.['MODEL_LARGE']);
          row['partnerSeqKr'] = selectlabel(row.partnerSeq, masterOptions?.['SELLER']);
        });
        return data;
      });
    } else if (str === 'detail') {
      url = WMS_LINEHAUL_LOADING_WEB_EXCEL + `?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
      const search = paramingSearchObjToUrl(url, searchObj);

      return httpClient.get(search).then((res) => {
        dispatch(setLoading(null));
        const data = res.data || [];
        data.forEach((row) => {
          row['originalCenterCodeKr'] = selectlabel(row.originalCenterCode, masterOptions?.['CENTER']);
          row['destinationCenterCodeKr'] = selectlabel(row.destinationCenterCode, masterOptions?.['CENTER']);
          row['linehaulTypeKr'] = selectlabel(row.linehaulType, masterOptions?.['LINEHAUL_TYPE']);
          row['statusKr'] = selectlabel(row.status, masterOptions?.['LOADING_OUT_STAT']);
          row['shippingBill'] = '간선작업지시서';
          row['modelGroupKr'] = selectlabel(row.modelGroupLarge, masterOptions?.['MODEL_LARGE']);
          row['partnerSeqKr'] = selectlabel(row.partnerSeq, masterOptions?.['SELLER']);
        });
        return data;
      });
    }
  };

  // NOTE: 할당정보 모달 호출 시 사용되는 기준 정보
  const [currentHaldangItem, setCurrentHaldangItem] = useState(null);

  // NOTE: 할당정보 모달 액션
  const haldangAction = (event) => {
    setCurrentHaldangItem(event.item);
    setInventoryModalObj({
      ...inventoryModalObj,
      visible: true,
      origin: event.item,
    });
  };

  // NOTE: 할당정보 액션 변경 시 searchObj가 업데이트된 경우, 할당정보 모달이 열려있을 때 정보를 갱신할 수 있도록 effect hook 적용.
  useEffect(() => {
    if (inventoryModalObj?.visible) {
      setInventoryModalObj(() => {
        return {
          ...inventoryModalObj,
          origin: { ...currentHaldangItem },
        };
      });
    }
  }, [searchObj]);

  // NOTE: 이전 할당정보 모달 (미사용)
  const inventoryModalAction = async (event) => {
    dispatch(setLoading('GET'));
    let url = WMS_LINEHAUL_PICKING_DETAIL_INFO + '?loadFlag=true';
    if (event.item.linehaulSeq) url += `&linehaulSeq=${event.item.linehaulSeq}`;
    if (event.item.originalCenterCode) url += `&originalCenterCode=${event.item.originalCenterCode}`;
    if (event.item.destinationCenterCode) url += `&destinationCenterCode=${event.item.destinationCenterCode}`;
    if (event.item.linehaulDate) url += `&linehaulDate=${event.item.linehaulDate}`;
    if (event.item.linehaulType) url += `&linehaulType=${event.item.linehaulType}`;
    if (event.item.loadingStatementSeq) url += `&loadingStatementSeq=${event.item.loadingStatementSeq}`;
    //
    if (event.item.loadingOutSeq) url += `&loadingOutSeq=${event.item.loadingOutSeq}`;
    if (event.item.transportStatementSeq) url += `&transportStatementSeq=${event.item.transportStatementSeq}`;

    await httpClient.get(url).then((res) => {
      const data = res.data || [];
      data.forEach((row) => {
        row['centerCodeKr'] = selectlabel(row.centerCode, masterOptions?.['CENTER_LABEL']);
        row['driverCenterCodeKr'] = row.driverCenterCode ? selectlabel(row.driverCenterCode, masterOptions?.['CENTER_LABEL']) : '';
        row['originalCenterCodeKr'] = selectlabel(row.originalCenterCode, masterOptions?.['CENTER_LABEL']);
        row['destinationCenterCodeKr'] = selectlabel(row.destinationCenterCode, masterOptions?.['CENTER_LABEL']);
        row['availableTypeKr'] = selectlabel(row.availableType, masterOptions?.['INVA_TYPE']);
        row['partnerSeqKr'] = selectlabel(row.partnerSeq, masterOptions?.['SELLER']);
        row['modelGroupKr'] = selectlabel(row.modelGroup, masterOptions?.['MODEL_GROUP']);
        row['receiverAddr'] = row.receiverAddr1 ? row.receiverAddr1 + ',' + row.receiverAddr2 : '';
        row['loadFlagKr'] = row.loadFlag === true ? 'O' : 'X';
        row['linehaulDate'] = event.item.linehaulDate;
        row['linehaulType'] = event.item.linehaulType;
        row['loadingStatementSeq'] = event.item.loadingStatementSeq;
        row['originalCenterCodeOg'] = event.item.originalCenterCode;
        row['destinationCenterCodeOg'] = event.item.destinationCenterCode;
        row['orderCancelFlagKr'] = event?.item?.orderCancelFlag === true ? 'O' : 'X';

        row['statusOg'] = event.item.status;
      });
      setInventoryModalObj({
        ...inventoryModalObj,
        visible: true,
        setGetCheckedDataTriggerThisGrid,
        hasTabs: [
          {
            title: '할당정보',
            gridId: inventoryModalGridId,
            columns,
            rows: data,
            buttons: [
              {
                color: 'red',
                title: '이전단계',
                type: 'MODAL_ROLLBACK',
              },
              {
                color: 'red',
                title: '출차취소',
                type: 'MODAL_CANCEL',
              },
            ],
            options: {
              isRowAllCheckCurrentView: true,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
            },
          },
        ],
      });
    });
    dispatch(setLoading(null));
  };

  const columns = [
    {
      headerText: '상세보기',
      renderer: {
        type: 'ButtonRenderer',
        labelText: '상세보기',
        onClick: fetchDetailList,
      },
    },
    {
      headerText: '사진보기',
      dataField: 'photo',
      style: 'myLinkStyle',
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 기본 URL
        jsCallback: function (rowIndex, columnIndex, value, item) {
          setPhotoPopupObj({
            ...photoPopupObj,
            data: item,
          });
        },
      },
    },
    {
      headerText: '운송명세서',
      dataField: 'shippingBill',
      renderer: {
        type: 'ButtonRenderer',
        labelText: '프린트',
        onClick: printAction,
        visibleFunction: function (rowIndex, columnIndex, value, item, dataField) {
          if (!item.promiseUnloadDate || !item.transportStatementSeq) {
            return false;
          }
          return true;
        },
      },
    },
    {
      headerText: '할당정보',
      dataField: 'modal',
      renderer: {
        type: 'ButtonRenderer',
        labelText: '할당정보',
        onClick: haldangAction,
      },
    },
    {
      headerText: '출고지시번호',
      dataField: 'loadingStatementSeq',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '운송번호',
      dataField: 'transportStatementSeq',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고예정일',
      dataField: 'linehaulDate',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출발창고',
      dataField: 'originalCenterCodeKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '도착창고',
      dataField: 'destinationCenterCodeKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '간선유형',
      dataField: 'linehaulTypeKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '비고입력란',
      dataField: 'loadingOutMemo',
    },
    {
      headerText: '출차 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          editable: false,
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
          editable: false,
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '운송정보',
      children: [
        {
          headerText: '운송기사명',
          dataField: 'loadingDriverName',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '연락처',
          dataField: 'loadingDriverPhone',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '차량종류',
          dataField: 'loadingDriverCarType',
          filter: {
            showIcon: true,
          },
          renderer: {
            type: 'DropDownListRenderer',
            list: masterOptions?.['CAR_TYPE'],
            keyField: 'value',
            valueField: 'label',
          },
        },

        {
          headerText: '차량번호',
          dataField: 'loadingDriverCarNumber',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      dataField: 'promiseUnloadDate',
      headerText: '입고<br />예정일',
      width: 120,
      dataType: 'date',
      formatString: 'yyyy-mm-dd',
      filter: {
        showIcon: true,
      },
      editRenderer: {
        onlyCalendar: true,
        type: 'CalendarRenderer',
        showTodayBtn: true,
        showEditorBtnOver: true,
        showExtraDays: true,
        titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        defaultFormat: 'yyyy-mm-dd',
      },
      style: 'my-custom-aui',
    },
    {
      headerText: '출고<br />완료일',
      dataField: 'initDatetime',
      editable: false,
      filter: {
        showIcon: true,
      },
    },

    {
      headerText: '출차지시<br />확정일',
      dataField: 'confirmDatetime',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '파렛트 정보',
      children: [
        {
          headerText: '파렛트명',
          dataField: 'palletType',
          renderer: {
            type: 'DropDownListRenderer',
            list: masterOptions?.PALLET_TYPE,
            keyField: 'value',
            valueField: 'label',
          },
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '수량',
          dataField: 'palletQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '출차지시<br />확정자',
      dataField: 'confirmId',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
  ];

  const detailColumns = [
    {
      headerText: '출고지시번호',
      dataField: 'loadingStatementSeq',
      editable: false,
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '운송번호',
      dataField: 'transportStatementSeq',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '로케이션',
          dataField: 'locationCode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupKr',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '상품명',
          dataField: 'productName',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품',
          dataField: 'model',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '바코드',
          dataField: 'barcode',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '주문번호',
          dataField: 'orderSeq',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '송장번호',
          dataField: 'invoiceSeq',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '간선출차수량',
      children: [
        {
          headerText: '양품',
          dataField: 'availableQuantity',
          filter: {
            showIcon: true,
          },
        },

        {
          headerText: '불용',
          dataField: 'defectedQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기타',
          dataField: 'etcQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },

    {
      headerText: '진행상태',
      dataField: 'statusKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출차완료일',
      dataField: 'confirmDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출차완료자',
      dataField: 'confirmId',
      filter: {
        showIcon: true,
      },
    },
  ];

  return (
    <Presenter
      //그리드
      rows={rows}
      columns={columns}
      gridId={gridId}
      onEditEnded={onEditEnded}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      //엑셀
      //함수
      downloadExcel={downloadExcel}
      fetchList={fetchList}
      fetchPhotos={fetchPhotos}
      //
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      pagingSetting={pagingSetting}
      setPagingSetting={setPagingSetting}
      //bottomSheet
      bottomSheetObj={bottomSheetObj}
      setBottomSheetObj={setBottomSheetObj}
      driverModalObj={driverModalObj}
      setDriverModalObj={setDriverModalObj}
      photoPopupObj={photoPopupObj}
      setPhotoPopupObj={setPhotoPopupObj}
      //inventoryModal
      inventoryModalObj={inventoryModalObj}
      setInventoryModalObj={setInventoryModalObj}
      //
      inventoryCancelModalObj={inventoryCancelModalObj}
      setInventoryCancelModalObj={setInventoryCancelModalObj}
      trunkPrintObj={trunkPrintObj}
      setTrunkPrintObj={setTrunkPrintObj}
      // 할당모달그리드ID
      inventoryModalGridId={inventoryModalGridId}
    />
  );
};

export default Container;
