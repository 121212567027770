import { useState, useEffect } from 'react';
import { defaultUnitedPageWithTopTab, UnitedPageWithTopTab } from 'components/template/topTab';

import Container1 from './orderTracking';

const Index = () => {
  const [tabObj, setTabObj] = useState(defaultUnitedPageWithTopTab);

  useEffect(() => {
    setTabObj({
      currentTab: 0,
      titles: ['배송현황조회v3'],
      containers: [<Container1 />],
    });
  }, []);

  return <>{tabObj?.titles.length > 0 && <UnitedPageWithTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
