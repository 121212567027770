import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchBoxViewType } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';

export type ViewType = 'OPTIMIZED' | 'DEFAULT';

export function useSearchBox(childrenCount: number) {
  const dispatch = useDispatch();
  const [columnCount, setColumnCount] = useState(childrenCount);
  const { searchBoxViewType } = useSelector((state: RootState) => state.menu);
  // const [viewType, setViewType] = useState<ViewType>('DEFAULT');

  const toggleViewType = () => {
    searchBoxViewType === 'DEFAULT' ? dispatch(setSearchBoxViewType('OPTIMIZED')) : dispatch(setSearchBoxViewType('DEFAULT'));
  };

  const handleCustomSubmit = async (event: React.FormEvent) => {
    console.log(event);
    event.preventDefault();
    //submit logic
  };

  return { columnCount, setColumnCount, toggleViewType, handleCustomSubmit, searchBoxViewType };
}
