import { KeyboardEvent, useEffect, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { InventoryLiveScmSearchDTO } from 'interface/warehouse';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';

export const SearchBox = (props: ISearchBoxProps<InventoryLiveScmSearchDTO>) => {
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_MODEL: null,
    SEARCH_SKU: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_SKU, searchObj));
  }, [selectTypeObj?.SEARCH_SKU]);

  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  const handleFixedFlag = (e) => {
    console.log(e.target.id);
    if (e.target.id) {
      setSearchObj((prev) => {
        return {
          ...prev,
          period: e.target.id === '-1' ? null : Number(e.target.id),
        };
      });
    }
  };

  useEffect(() => {}, [searchObj]);

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">기간설정</label>
          <div className="radio-select-area">
            <span id="-1" className={searchObj?.period === null ? 'active' : ''} onClick={handleFixedFlag}>
              전체
            </span>
            <span id="7" className={searchObj?.period === 7 ? 'active' : ''} onClick={handleFixedFlag}>
              D+7
            </span>
            <span id="15" className={searchObj?.period === 15 ? 'active' : ''} onClick={handleFixedFlag}>
              D+15
            </span>
            <span id="30" className={searchObj?.period === 30 ? 'active' : ''} onClick={handleFixedFlag}>
              D+30
            </span>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            isMulti
            options={masterOptions?.CENTER}
            value={searchObj?.centerCode?.map((ele) => {
              return {
                value: ele,
                label: masterOptions?.CENTER_OBJ?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCode: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품그룹</label>
          <SelectD
            isMulti
            options={masterOptions?.MODEL_GROUP}
            value={searchObj?.modelGroup?.map((ele) => {
              return {
                value: ele,
                label: masterOptions?.MODEL_GROUP_OBJ?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                modelGroup: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제품타입</label>
          <SelectD
            isMulti
            options={masterOptions?.MODEL_TYPE}
            value={searchObj?.modelType?.map((ele) => {
              return {
                value: ele,
                label: masterOptions?.MODEL_TYPE_OBJ?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                modelType: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            isMulti
            options={masterOptions?.SELLER}
            value={searchObj?.partnerSeq?.map((ele) => {
              return {
                value: ele,
                label: masterOptions?.SELLER_OBJ?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">SKU/외부SKU</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_SKU
                    ? {
                        value: selectTypeObj?.SEARCH_SKU,
                        label: masterOptions?.SEARCH_SKU_OBJ[selectTypeObj?.SEARCH_SKU],
                      }
                    : null
                }
                options={masterOptions?.SEARCH_SKU}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_SKU: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_SKU}
                value={searchObj?.[selectTypeObj?.SEARCH_SKU] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_SKU]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">제품/제품명</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_MODEL
                    ? {
                        value: selectTypeObj?.SEARCH_MODEL,
                        label: masterOptions?.SEARCH_MODEL_OBJ[selectTypeObj?.SEARCH_MODEL],
                      }
                    : null
                }
                options={masterOptions?.SEARCH_MODEL}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_MODEL: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <InputD
                disabled={!selectTypeObj?.SEARCH_MODEL}
                value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                onChange={(e) => {
                  setSearchObj({
                    ...searchObj,
                    [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                  });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">바코드</label>
          <InputD
            value={searchObj?.barcode || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                barcode: e.target.value,
              });
            }}
          />
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        <Col />
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
