import {
  centerListForlabeling,
  codeMasterOptionReturn,
  diffCenterListReturn,
  driverListForLabeling,
  getGridParameters,
  InfraOptionReturn,
  partnerListForlabeling,
  regionOptionReturnTmp,
} from 'common/master/codeMasterOptionReturn';

import { getLabelNvalueOptions } from 'common/master/codeMasterReturnHelper';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_GRIDID, nullingMASTER, RAWDATA, setLoading, setMasterOptions } from 'redux/services/menuSlice';
import styled from 'styled-components';
import { ILOCALE } from 'redux/services/localeSlice';
import { RootState } from 'redux/store';
import { justServer } from 'common/util/isServer';
import { useLocation } from 'react-router-dom';
import { DRIVER_LIST_MASTER } from 'pages/ETC/1driver/driverListTab';
import { DELIVERY_SCHEDULE_MASTER } from 'pages/DELIVERY/2delivery/deliveryScheduleTab';
import { setShowModal } from 'redux/services/portalSlice';
import { VALUES } from 'common/constants/appearance';
import ModalOnboarding from 'components/LDS/layouts/Modal.Onboarding';
import LDSModal from 'components/LDS/layouts/Modal';
import { getLanguangeInlocalStorage, saveLanguangeInlocalStorage } from 'common/util/lang';
import { USER_MASTER } from 'pages/ETC/3user/userTab';
import { PARTNER_MASTER } from 'pages/ETC/3user/partnerListTab';
import { INFRA_MASTER } from 'pages/ETC/3user/infraGroupTab';
import { WAREHOUSE_MASTER } from 'pages/ETC/2center/warehouseListTab';
import { WAREHOUSELOCATION_MASTER } from 'pages/ETC/2center/warehouseLocationTab';
import { PICKINGSTRATEGY_MASTER } from 'pages/ETC/2center/_hide_pickingStrategy';
import { MANAGERLD_MASTER } from 'pages/ETC/3user/jojikLDTab';
import { DELIVERYSECTOR_MASTER } from 'pages/ETC/1driver/deliverySectorTab';
import { CARGO_MANIFEST_TRANSFER_LIST_MASTER } from 'pages/CARGO/cargoList/transferTab';
import { CARGO_MANIFEST_LIST_MASTER } from 'pages/CARGO/cargoList/cargoListTab';
import { CARGO_MANIFEST_ACCIDENTLIST_MASTER } from 'pages/CARGO/cargoList/accidentTab';
import { MANAGE_PARCEL_TASK_LIST_MASTER } from 'pages/PARCEL/orderList/taskTab';
import { MANAGE_PARCEL_SERVICE_LIST_MASTER } from 'pages/PARCEL/parcelListTab';
import { MANAGE_PARCEL_ACCIDENT_MASTER } from 'pages/PARCEL/orderList/accidentTab';
import { MANAGE_PARCEL_REVISIT_MASTER } from 'pages/PARCEL/orderList/revisitTab';
import { MANAGE_PARCEL_LINEHAUL_LIST_MASTER } from 'pages/PARCEL/linehaul/linehaulTab';
import { MANAGE_PARCEL_LOAD_LIST_MASTER } from 'pages/PARCEL/linehaul/loadingTab';
import { MANAGE_PARCEL_REMAIN_MASTER } from 'pages/PARCEL/remain';
import { MANAGE_PARCEL_NUMBER_MASTER } from 'pages/PARCEL/numberTab';
import { MANAGE_PARCEL_NUMBER_PRINT_MASTER } from 'pages/PARCEL/numberPrintTab';
import { MANAGE_PARCEL_FEE_LIST_MASTER } from 'pages/PARCEL/fee/feeListTab';
import { MANAGE_PARCEL_FEE_LOAN_MASTER } from 'pages/PARCEL/fee/loanListTab';
import { MANAGE_PARCEL_FEE_CHANGE_FARE_MASTER } from 'pages/PARCEL/fee/feeChangeFareTab';
import { MANAGE_PARCEL_TRACKING_REPORT_MASTER } from 'pages/PARCEL/tracking/reportTab';
import { TRUNK_REGISTER_MASTER } from 'pages/_REGIT/_trunkRegitTab';
import { TRUNK_REGIT_MASTER } from 'pages/_REGIT/trunkRegitTab';
import { TAKE_OUT_PICKING_INSTRUCTION_REGISTER_MASTER } from 'pages/_REGIT/_takeoutRegitTab';
import { TAKEOUT_REGIT_MASTER } from 'pages/_REGIT/takeoutRegitTab';
import { STORE_TAKE_OUT_PICKING_INSTRUCTION_REGISTER_MASTER } from 'pages/_REGIT/bodyBanchulRegitTab';
import { CREATE_FCPICKING_MASTER } from 'pages/OUT/3driver/createFcPicking';
import { MODIFICATION_AVA_DEF_REGIT_MASTER } from 'pages/_REGIT/modificationRegitTab/availlRegit';
import { REFUR_AND_MODEL_MOVE_REGISTER_MASTER } from 'pages/_REGIT/_refurRegitTab/create';
import { MOVE_ACTION_REGIT_MASTER } from 'pages/_REGIT/moveActionRegitTab';
import { BASICFEE_MASTER } from 'pages/FEE/1basicFee';
import { ORDERRECEIPT_MASTER } from 'pages/FEE/2orderReceipt';
import { TRANSACTION_MASTER } from 'pages/FEE/3transactionStatement';
import { INVESTIGATE_MASTER } from 'pages/INVENTORY/1inventory/inventoryInvestigateTab';
import { CUSTOMALERTMSG_MASTER } from 'pages/DELIVERY/2delivery/customAlertMsgTab';
import { PURCHASEORDER_MASTER } from 'pages/IPGO/1purchaseOrder/purchaseOrderTab';
import { GANSUNIPGO_MASTER } from 'pages/IPGO/2trunk/gansunIpgoTab';
import { GANSUNCOLLECT_MASTER } from 'pages/IPGO/3collect/collectTab';
import { LOCATIONHISTORY_MASTER } from 'pages/IPGO/4location/locationHistoryTab';
import { INVENTORY_INSPECT_TAB_MASTER } from 'pages/INVENTORY/1inventory/inventoryInspectTab';
import { PURCHASEIN_MASTER } from 'pages/IPGO/5purchase/purchaseInTab';
import { SETTINGSLIST_MASTER } from 'pages/ETC/4settings/listTabs';
import { ORDER_MASTER } from 'pages/ORDER/1order/orderListTab';
import { ORDERHISTORY_MASTER } from 'pages/ORDER/1order/orderHistoryTab';
import { ORDERSERVICE_MASTER } from 'pages/ORDER/1order/manageServiceTab';
import { EXTRAPAYMENT_MASTER } from 'pages/ORDER/1order/paymentTab';
import { RELOCATEORDER_MASTER } from 'pages/ORDER/1order/relocateOrderTab';
import { MANAGEMESSAGE_MASTER } from 'pages/ORDER/1order/messageSendManageTab';
import { CUSTOMFORM_MASTER } from 'pages/ORDER/1order/customUploadFormTab';
import { SSREGIT_MASTER } from 'pages/ORDER/2ss/ssRegitTab';
import { SSLIST_MASTER } from 'pages/ORDER/2ss/ssListTab';
import { DELIVERY_WORKS_MASTER } from 'pages/OUT/delivery/deliveryWorksTab';

const SEARCH_MODEL = [
  { label: '제품', value: 'model' },
  { label: '제품명', value: 'modelName' },
  { label: '바코드', value: 'barcode' },
  { label: 'SKU', value: 'modelStockSeq' },
  { label: '외부SKU', value: 'skuNumber' },
  { label: '대표상품코드', value: 'productCode' },
];

const InvisibleWrapper = styled.span`
  position: absolute;
  pointer-events: none;
  right: 0;
  z-index: 9999;

  display: ${justServer('lfs-dev.') ? 'block' : 'none'};

  &:before {
    content: '마스터옵션 :';
  }
`;

export function PurchaseIn() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(PURCHASEIN_MASTER);
  };

  return <InvisibleWrapper>통관예정</InvisibleWrapper>;
}

export function Dashboard() {
  const dispatch = useDispatch();
  const fetchOptions = async () => {
    dispatch(setLoading('GET'));
    const [CENTER_WHOLE, DRIVER_WHOLE, FEE_TYPE, REGION, SERVICE_TYPE, INVOICE_STATUS, COUNSEL_TYPE, COUNSEL_DETAIL_TYPE, DRIVER_QNA_STATUS, QNA_TYPE1, QNA_TYPE2, QNA_TYPE3, USER_CODE] =
      await Promise.all([
        centerListForlabeling(),
        driverListForLabeling(),
        codeMasterOptionReturn('FEE_TYPE'),
        regionOptionReturnTmp(),
        codeMasterOptionReturn('SERVICE_TYPE'),
        codeMasterOptionReturn('INVOICE_STATUS'),
        codeMasterOptionReturn('COUNSEL_TYPE'),
        codeMasterOptionReturn('COUNSEL_DETAIL_TYPE'),
        codeMasterOptionReturn('QNA_STATUS'),
        codeMasterOptionReturn('QNA_TYPE1'),
        codeMasterOptionReturn('QNA_TYPE2'),
        codeMasterOptionReturn('QNA_TYPE3'),
        codeMasterOptionReturn('USER_CODE'),
      ]);
    dispatch(
      setMasterOptions({
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        DRIVER: DRIVER_WHOLE?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        PARTNER_SELLER: (await partnerListForlabeling({ sellerFlag: true })).map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        USER_CODE,
        FEE_TYPE,
        REGION,
        SERVICE_TYPE,
        INVOICE_STATUS,
        COUNSEL_TYPE,
        COUNSEL_DETAIL_TYPE,
        DRIVER_QNA_STATUS,
        QNA_TYPE1,
        QNA_TYPE2,
        QNA_TYPE3,
      }),
    );
    dispatch(setLoading(null));
  };

  useEffect(() => {
    fetchOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);

  const { showModal } = useSelector((state: RootState) => state.portal);
  const [modalContentFlags, setModalContentFlags] = useState<number[]>(JSON.parse(localStorage.getItem('modalContentFlags')));
  //NOTE: modalContentFlags 배열 내 id가 1인경우 ModalOnboarding을 봤다는 것으로 간주한다.

  useEffect(() => {
    if (modalContentFlags?.filter((flag) => flag === 1)?.length !== 1 && showModal === '') {
      const newModalContentFlags = modalContentFlags?.length > 0 ? [...modalContentFlags, 1] : [1];
      setModalContentFlags((prev) => newModalContentFlags);
      localStorage.setItem('modalContentFlags', JSON.stringify(newModalContentFlags));
      setTimeout(() => {
        dispatch(setShowModal('onboarding'));
      }, VALUES.SEARCH_THROTTLE_BUFFER * 3);
    }
  }, []);

  return (
    <>
      {showModal === 'onboarding' && (
        <LDSModal $size={1000}>
          <ModalOnboarding $size={1000} />
        </LDSModal>
      )}
      <InvisibleWrapper>대시보드</InvisibleWrapper>
    </>
  );
}

export function CargoManifestTransferList() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(CARGO_MANIFEST_TRANSFER_LIST_MASTER);
  };

  return <InvisibleWrapper>통관예정</InvisibleWrapper>;
}

export function CargoManifestList() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const GRIDID = 'CARGO_MANIFEST';
    const CARGO_MANIFEST = await getGridParameters(GRIDID);

    initMasterOptions([{ [`${COMMON_GRIDID}CARGO_MANIFEST`]: CARGO_MANIFEST }, ...CARGO_MANIFEST_LIST_MASTER]);
  };

  return <InvisibleWrapper>통관목록조회</InvisibleWrapper>;
}

export function CargoManifestAccidentList() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(CARGO_MANIFEST_ACCIDENTLIST_MASTER);
  };

  return <InvisibleWrapper>통관사고접수</InvisibleWrapper>;
}

export function ManageParcelTaskList() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_TASK_LIST_MASTER);
  };

  return <InvisibleWrapper>집화처리</InvisibleWrapper>;
}

export function ManageParcelServiceList() {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    const PARCEL_SERVICE_LIST = await getGridParameters('PARCEL_SERVICE_LIST');
    const PARCEL_RESERVED_LIST = await getGridParameters('PARCEL_RESERVED_LIST');
    const PARCEL_DELIVERY_COMPLETED_LIST = await getGridParameters('PARCEL_DELIVERY_COMPLETED_LIST');
    const masterArr = [
      //
      { [`${COMMON_GRIDID}PARCEL_SERVICE_LIST`]: PARCEL_SERVICE_LIST },
      { [`${COMMON_GRIDID}PARCEL_RESERVED_LIST`]: PARCEL_RESERVED_LIST },
      { [`${COMMON_GRIDID}PARCEL_DELIVERY_COMPLETED_LIST`]: PARCEL_DELIVERY_COMPLETED_LIST },
      ...DRIVER_LIST_MASTER,
      ...MANAGE_PARCEL_SERVICE_LIST_MASTER,
    ];
    initMasterOptions(masterArr);
  };

  return <InvisibleWrapper>택배조회</InvisibleWrapper>;
}

export function ManageParcelAccident() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = () => {
    initMasterOptions(MANAGE_PARCEL_ACCIDENT_MASTER);
  };

  return <InvisibleWrapper>택배사고접수</InvisibleWrapper>;
}

export function ManageParcelRevisit() {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_REVISIT_MASTER);
  };

  return <InvisibleWrapper>택배미배송</InvisibleWrapper>;
}

export function ManageParcelLinehaulList() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_LINEHAUL_LIST_MASTER);
  };

  return <InvisibleWrapper>택배간선조회</InvisibleWrapper>;
}

export function ManageParcelLoadList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { initMasterOptions } = useMasterOptions();

  const screenUrl = location.pathname.split('/')?.[2];

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, [screenUrl]); //NOTE: 동일한 screenUrl을 가지는 경우 리렌더링될 수 있도록 pathname을 deps로 지정

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_LOAD_LIST_MASTER);
  };

  return <InvisibleWrapper>택배상하차조회</InvisibleWrapper>;
}

export function ManageParcelRemain() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_REMAIN_MASTER);
  };

  return <InvisibleWrapper>택배잔류처리</InvisibleWrapper>;
}

export function ManageParcelNumber() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_NUMBER_MASTER);
  };

  return <InvisibleWrapper>택배운송장신청</InvisibleWrapper>;
}

export function ManageParcelNumberPrint() {
  const dispatch = useDispatch();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_NUMBER_PRINT_MASTER);
  };

  return <InvisibleWrapper>택배운송장출력</InvisibleWrapper>;
}

export function ManageParcelFeeList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, [location.pathname?.split('/')?.[3]]);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_FEE_LIST_MASTER);
  };

  return <InvisibleWrapper>택배운임정산</InvisibleWrapper>;
}

export function ManageParcelFeeLoan() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_FEE_LOAN_MASTER);
  };

  return <InvisibleWrapper>택배여신관리</InvisibleWrapper>;
}

export function ManageParcelFeeChangeFare() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_FEE_CHANGE_FARE_MASTER);
  };

  return <InvisibleWrapper>택배운임비변경</InvisibleWrapper>;
}

export function ManageParcelTrackingReport() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MANAGE_PARCEL_TRACKING_REPORT_MASTER);
  };

  return <InvisibleWrapper>택배출고반품현황</InvisibleWrapper>;
}

export function PurchaseOrderAdmin() {
  const dispatch = useDispatch();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(PURCHASEORDER_MASTER);
  };

  return <InvisibleWrapper>입고발주입고</InvisibleWrapper>;
}

export function PlaceOrderRegister() {
  const dispatch = useDispatch();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(PURCHASEORDER_MASTER);
  };

  return <InvisibleWrapper>입고발주등록</InvisibleWrapper>;
}

export function TrunkReceiving() {
  const dispatch = useDispatch();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(GANSUNIPGO_MASTER);
  };

  return <InvisibleWrapper>입고간선입고</InvisibleWrapper>;
}

export function CollectReceiving() {
  const dispatch = useDispatch();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(GANSUNCOLLECT_MASTER);
  };

  return <InvisibleWrapper>입고회수입고</InvisibleWrapper>;
}

export function InventoryLocationSelect() {
  const dispatch = useDispatch();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(LOCATIONHISTORY_MASTER);
  };

  return <InvisibleWrapper>입고로케이션지정내역</InvisibleWrapper>;
}

export function TrunkWorks() {
  const dispatch = useDispatch();

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [
      //
      SELLER_SELECT_WHOLE,
      CENTER_SELECT_WHOLE,
      CENTER_WHOLE,
      CENTER_DIFF,
      MODEL_GROUP,
      LINEHAUL_TYPE,
      LINE_STAT,
      ORDER_HOLD_TYPE,
      INVA_TYPE,
      LOADING_STAT,
      MODEL_LARGE,
      LOADING_OUT_STAT,
      CAR_TYPE,
      INFRA,
      PALLET_TYPE,
      ORDER_SEQ,
    ] = await Promise.all([
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling(),
      centerListForlabeling({ selectFlag: false }),

      diffCenterListReturn(),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('LINEHAUL_TYPE'),
      codeMasterOptionReturn('LINE_STAT'),
      codeMasterOptionReturn('ORDER_HOLD_TYPE'),
      codeMasterOptionReturn('INVA_TYPE'),
      codeMasterOptionReturn('LOADING_STAT'),
      codeMasterOptionReturn('MODEL_GRP_1'),
      codeMasterOptionReturn('LOADING_OUT_STAT'),
      codeMasterOptionReturn('CAR_TYPE'),
      InfraOptionReturn(),
      codeMasterOptionReturn('PALLET_TYPE'),
      codeMasterOptionReturn('ORDER_SEQ'),
    ]);
    dispatch(
      setMasterOptions({
        CENTER_WHOLE,
        PALLET_TYPE,
        SELLER_AUTH: SELLER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER: SELLER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_AUTH: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_LABEL: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_DIFF,
        CENTER_WHOLE_WITH_CLOSED: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        MODEL_GROUP,
        LINEHAUL_TYPE,
        LINE_STAT,
        ORDER_HOLD_TYPE,
        INVA_TYPE,
        LOADING_STAT,
        MODEL_LARGE,
        LOADING_OUT_STAT,
        CAR_TYPE,
        INFRA,
        ORDER_SEQ: [
          { value: 'orderSeq', label: '주문번호' },
          { value: 'externalOrderNumber', label: '외부주문번호' },
          { value: 'invoiceSeq', label: '송장번호' },
          { value: 'externalInvoiceNumber', label: '외부송장번호' },
          { value: 'customerName', label: '고객명' },
        ],
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'barcode', label: '바코드' },
          { value: 'modelStockSeq', label: 'SKU' },
          { value: 'skuNumber', label: '외부SKU' },
        ],
        INVENTORY_SEQ: [
          { value: 'inventorySeq', label: '재고번호' },
          { value: 'serialNumber', label: '시리얼번호' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };

  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>출고간선작업지시</InvisibleWrapper>;
}

export function TrunkRegister() {
  const dispatch = useDispatch();
  const { locale } = useSelector((state: RootState) => state.locale);

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters(locale.value);
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = (locale: ILOCALE) => {
    initMasterOptions(TRUNK_REGISTER_MASTER);
  };

  return <InvisibleWrapper>출고간선작업지시간선피킹생성</InvisibleWrapper>;
}
export function TrunkRegit() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = () => {
    initMasterOptions(TRUNK_REGIT_MASTER);
  };

  return <InvisibleWrapper>출고간선작업지시간선피킹생성new</InvisibleWrapper>;
}

export function TakeoutWorks() {
  const dispatch = useDispatch();

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [
      LOADING_STAT,
      MODEL_GRP_DIV,
      MODEL_GRP_1,
      MODEL_GRP_2,
      MODEL_GRP_3,
      TRANSPORT_STAT,
      CENTER_LABEL,
      CENTER_WHOLE,
      SELLER_SELECT_WHOLE,
      SELLER_WHOLE,
      REMOVE_STATUS,
      REMOVE_REASON,
      CAR_TYPE,
      ORDER_HOLD_TYPE,
      LOADING_OUT_STAT,
      LOGITYPE,
      MODEL_GROUP,
      MODEL_TYPE,
      LINEHAUL_TYPE,
      LINE_STAT,
      PARCEL_BOX_BARCODE,
      DRIVER_WHOLE,
      INVA_TYPE,
      ORDER_SEQ,
    ] = await Promise.all([
      codeMasterOptionReturn('LOADING_STAT'),
      codeMasterOptionReturn('MODEL_GRP_DIV'),
      codeMasterOptionReturn('MODEL_GRP_1'),
      codeMasterOptionReturn('MODEL_GRP_2'),
      codeMasterOptionReturn('MODEL_GRP_3'),
      codeMasterOptionReturn('TRANSPORT_STAT'),
      centerListForlabeling(),
      centerListForlabeling({ selectFlag: false }),
      partnerListForlabeling({ sellerFlag: true }),
      partnerListForlabeling({ sellerFlag: true, selectFlag: false }),
      codeMasterOptionReturn('REMOVE_STATUS'),
      codeMasterOptionReturn('REMOVE_REASON'),
      codeMasterOptionReturn('CAR_TYPE'),
      codeMasterOptionReturn('ORDER_HOLD_TYPE'),
      codeMasterOptionReturn('LOADING_OUT_STAT'),

      codeMasterOptionReturn('LOGI_TYPE'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
      codeMasterOptionReturn('LINEHAUL_TYPE'),
      codeMasterOptionReturn('LINE_STAT'),
      codeMasterOptionReturn('PARCEL_BOX_BARCODE'),
      driverListForLabeling(),
      codeMasterOptionReturn('INVA_TYPE'),
      codeMasterOptionReturn('ORDER_SEQ'),
    ]);
    const DRIVER_LABEL = getLabelNvalueOptions(DRIVER_WHOLE, 'driverName', 'userId');
    const DRIVER_PHONE = getLabelNvalueOptions(DRIVER_WHOLE, 'phone1', 'userId');
    dispatch(
      setMasterOptions({
        CENTER_WHOLE,
        PARCEL_BOX_BARCODE,
        LOADING_STAT,
        MODEL_GRP_DIV,
        MODEL_GRP_1,
        MODEL_GRP_2,
        MODEL_GRP_3,
        TRANSPORT_STAT,
        DRIVER_LABEL,
        DRIVER_PHONE,
        CENTER_AUTH: CENTER_LABEL?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        SELLER_WHOLE: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER_AUTH: SELLER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER: SELLER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        REMOVE_STATUS,
        REMOVE_REASON,
        CAR_TYPE,
        ORDER_HOLD_TYPE,
        LOADING_OUT_STAT,
        LOGITYPE,
        MODEL_GROUP,
        MODEL_TYPE,
        LINEHAUL_TYPE,
        LINE_STAT,
        SEARCH_DATE_PICKING: [
          { value: 'ConfirmDate', label: '피킹지시 확정일' },
          { value: 'PickDate', label: '피킹 완료일' },
        ],
        SEARCH_DATE_DELIVERY: [
          { value: 'StockRemoveDate', label: '출고 예정일' },
          { value: 'ConfirmDate', label: '출고지시 확정일' },
          { value: 'LoadDate', label: '출고 완료일' },
        ],
        SEARCH_DATE_TAKEOUT: [
          { value: 'StockRemoveDate', label: '출차 예정일' },
          { value: 'ConfirmDate', label: '출차 완료일' },
        ],
        SEARCH_SEQ_DELIVERY: [
          { value: 'stockRemoveSeq', label: '피킹 지시번호' },
          { value: 'loadingStatementSeq', label: '출고 지시번호' },
        ],
        SEARCH_SEQ_TAKEOUT: [
          { value: 'loadingStatementSeq', label: '출고 지시번호' },
          { value: 'transportStatementSeq', label: '운송번호' },
        ],
        INVA_TYPE,
        ORDER_SEQ: [
          { value: 'orderSeq', label: '주문번호' },
          { value: 'externalOrderNumber', label: '외부주문번호' },
          { value: 'invoiceSeq', label: '송장번호' },
          { value: 'externalInvoiceNumber', label: '외부송장번호' },
          { value: 'customerName', label: '고객명' },
        ],
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'barcode', label: '바코드' },
          { value: 'modelStockSeq', label: 'SKU' },
          { value: 'skuNumber', label: '외부SKU' },
        ],
        INVENTORY_SEQ: [
          { value: 'inventorySeq', label: '재고번호' },
          { value: 'serialNumber', label: '시리얼번호' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };

  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>출고반출작업지시</InvisibleWrapper>;
}

export function TakeOutPickingInstructionRegister() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = () => {
    initMasterOptions(TAKE_OUT_PICKING_INSTRUCTION_REGISTER_MASTER);
  };

  return <InvisibleWrapper>출고반출작업지시반출피킹생성</InvisibleWrapper>;
}
export function TakeoutRegit() {
  const dispatch = useDispatch();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(TAKEOUT_REGIT_MASTER);
  };

  return <InvisibleWrapper>출고반출작업지시반출피킹생성new</InvisibleWrapper>;
}
export function StoreTakeOutPickingInstructionRegister() {
  const dispatch = useDispatch();

  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(STORE_TAKE_OUT_PICKING_INSTRUCTION_REGISTER_MASTER);
  };

  return <InvisibleWrapper>출고반출작업지시양판점출고</InvisibleWrapper>;
}

export function DeliveryWorks() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = () => {
    initMasterOptions(DELIVERY_WORKS_MASTER);
  };

  return <InvisibleWrapper>출고납품작업지시</InvisibleWrapper>;
}

export function StockRemoveParcel() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [CENTER_SELECT, REMOVE_REASON, REMOVE_STATUS, SELLER_SELECT] = await Promise.all([
      centerListForlabeling({ selectFlag: true }),
      codeMasterOptionReturn('REMOVE_REASON'),
      codeMasterOptionReturn('REMOVE_STATUS'),
      partnerListForlabeling({ sellerFlag: true }),
    ]);

    dispatch(
      setMasterOptions({
        REMOVE_STATUS,
        REMOVE_REASON,
        SELLER: SELLER_SELECT?.map?.((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_SELECT?.map((ele) => {
          return {
            label: ele.centerName,
            value: ele.centerCode,
          };
        }),
      }),
    );
    dispatch(setLoading(null));
  };

  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>출고반출택배조회</InvisibleWrapper>;
}
export function WasteAppliances() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [CENTER_SELECT, DRIVER_SELECT] = await Promise.all([
      //
      centerListForlabeling({ selectFlag: true }),
      driverListForLabeling({ selectFlag: true }),
    ]);

    dispatch(
      setMasterOptions({
        CENTER_LABEL: CENTER_SELECT?.map((ele) => {
          return {
            label: ele.centerName,
            value: ele.centerCode,
          };
        }),
        DRIVER_NAME_LABEL: DRIVER_SELECT?.map((ele) => {
          return {
            label: ele.driverName,
            value: ele.userId,
          };
        }),
      }),
    );
    dispatch(setLoading(null));
  };

  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>출고폐가전출고</InvisibleWrapper>;
}
export function Fcpicking() {
  const dispatch = useDispatch();

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [
      SELLER_WHOLE,
      SELLER_AUTH,
      CENTER_WHOLE,
      CENTER_AUTH,
      LOAN_PICK_STATUS,
      ORDER_HOLD_TYPE,
      MODEL_GROUP,
      LOAN_PICK_CLOSE_REASON,
      DRIVER,
      MODEL_GRP_1,
      LOAN_PICK_TYPE,
      MODEL_TYPE,
      INVA_TYPE,
      ORDER_SEQ,
    ] = await Promise.all([
      partnerListForlabeling({ sellerFlag: true }),
      partnerListForlabeling({ sellerFlag: true, selectFlag: false }),
      centerListForlabeling(),
      centerListForlabeling({ selectFlag: false }),
      codeMasterOptionReturn('LOAN_PICK_STATUS'),
      codeMasterOptionReturn('ORDER_HOLD_TYPE'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('LOAN_PICK_CLOSE_REASON'),
      driverListForLabeling(),
      codeMasterOptionReturn('MODEL_GRP_1'),
      codeMasterOptionReturn('LOAN_PICK_TYPE'),
      codeMasterOptionReturn('MODEL_TYPE'),
      codeMasterOptionReturn('INVA_TYPE'),
      codeMasterOptionReturn('ORDER_SEQ'),
    ]);

    dispatch(
      setMasterOptions({
        SELLER_AUTH: SELLER_AUTH?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER_WHOLE,
        CENTER_AUTH: CENTER_AUTH?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        LOAN_PICK_STATUS,
        ORDER_HOLD_TYPE,
        MODEL_GROUP,
        LOAN_PICK_CLOSE_REASON,
        DRIVER: DRIVER?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        DRIVER_LABEL: DRIVER?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        MODEL_GRP_1,
        LOAN_PICK_TYPE,
        MODEL_TYPE,
        INVA_TYPE,
        PRINT_TYPE: [
          { value: 'basic', label: '상품구성순' },
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'dockNumber', label: '도크넘버' },
          { value: 'locationCode', label: '로케이션' },
        ],
        ASC_TYPE: [
          { value: 'ascend', label: '오름차순' },
          { value: 'descend', label: '오름차순' },
        ],
        ORDER_SEQ: [
          { value: 'orderSeq', label: '주문번호' },
          { value: 'externalOrderNumber', label: '외부주문번호' },
          { value: 'invoiceSeq', label: '송장번호' },
          { value: 'externalInvoiceNumber', label: '외부송장번호' },
          { value: 'customerName', label: '고객명' },
        ],
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'barcode', label: '바코드' },
          { value: 'modelStockSeq', label: 'SKU' },
          { value: 'skuNumber', label: '외부SKU' },
        ],
        INVENTORY_SEQ: [
          { value: 'inventorySeq', label: '재고번호' },
          { value: 'serialNumber', label: '시리얼번호' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };

  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>출고fc피킹</InvisibleWrapper>;
}
export function FcpickingRegit() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(CREATE_FCPICKING_MASTER);
  };

  return <InvisibleWrapper>출고fc피킹생성</InvisibleWrapper>;
}

export function DeliveryOrder() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [
      //
      DRIVER_WHOLE,
      SELLER_WHOLE,
      CENTER_WHOLE,
      LOAN_COMP_STATUS,
      FEE_TYPE,
      LOAN_STATUS,
      MODEL_GROUP,

      WH_TYPE,
      MODEL_LARGE,
      TOSS_PAYMENT_STATUS,
      ORDER_SEQ,
    ] = await Promise.all([
      driverListForLabeling(),
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling(),
      codeMasterOptionReturn('LOAN_COMP_STATUS'),
      codeMasterOptionReturn('FEE_TYPE'),
      codeMasterOptionReturn('LOAN_STATUS'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('WH_TYPE'),
      codeMasterOptionReturn('MODEL_GRP_1'),
      codeMasterOptionReturn('TOSS_PAYMENT_STATUS'),
      codeMasterOptionReturn('ORDER_SEQ'),
    ]);
    dispatch(
      setMasterOptions({
        TOSS_PAYMENT_STATUS,
        MODEL_LARGE,
        WH_TYPE,
        MODEL_GROUP,
        FEE_TYPE,
        LOAN_STATUS,
        LOAN_COMP_STATUS,
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_LBL: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        DRIVER_LABEL: DRIVER_WHOLE?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        SEARCH_DATE: [
          { value: 'RegisterDate', label: '요청일시' },
          { value: 'PromiseDate', label: '출고예정일' },
          { value: 'LoanDate', label: '출고일시' },
        ],
        DRIVER_NAME: DRIVER_WHOLE.map((ele) => {
          return { value: ele.userId, label: ele.driverName };
        }),
        DRIVER_PHONE: DRIVER_WHOLE.map((ele) => {
          return { value: ele.userId, label: ele.phone1 };
        }),
        DRIVER_MEMBERCOUNT: DRIVER_WHOLE.map((ele) => {
          return { value: ele.userId, label: ele.memberCount };
        }),
        ORDER_TYPE: [
          { value: null, label: '선택' },
          { value: 'invoiceSeq', label: '송장번호' },
          { value: 'loanSeq', label: '기사출고번호' },
        ],
        ORDER_SEQ: [
          { value: 'orderSeq', label: '주문번호' },
          { value: 'externalOrderNumber', label: '외부주문번호' },
          { value: 'invoiceSeq', label: '송장번호' },
          { value: 'externalInvoiceNumber', label: '외부송장번호' },
          { value: 'customerName', label: '고객명' },
        ],
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'barcode', label: '바코드' },
          { value: 'modelStockSeq', label: 'SKU' },
          { value: 'skuNumber', label: '외부SKU' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>출고기사출고</InvisibleWrapper>;
}

export function DistributingCar() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [INFRA, PROVINCE, CITY, PARTNER_SELLER_WHOLE, MODEL_GROUP, CENTER_SELECT_WHOLE] = await Promise.all([
      //
      InfraOptionReturn(),
      codeMasterOptionReturn('PROVINCE'),
      codeMasterOptionReturn('CITY'),
      partnerListForlabeling({ sellerFlag: true }),
      codeMasterOptionReturn('MODEL_GROUP'),
      centerListForlabeling(),
    ]);
    dispatch(
      setMasterOptions({
        INFRA,
        PROVINCE,
        CITY,
        SELLER: PARTNER_SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        MODEL_GROUP,
        CENTER: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>출고배차</InvisibleWrapper>;
}

export function InventoryReports() {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      //
      'DRIVER_AUTH',
      'DRIVER_WHOLE',
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_NETWORK_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      'SALES_CHANNEL',
      //
      'MODEL_GROUP',
      'MODEL_TYPE',
      'FEE_TYPE',
      'LO_STATUS',
      'CANCEL_REASON',
      'DEFECTED_STAT',
      'INVA_STATUS',
      'INVA_TYPE',
      'INVM_STATUS',
      'ASSET_TYPE',
      'LOCATION_TYPE',
      'SEARCH_MODEL',
      {
        SEARCH_NUMBER: [
          { value: 'inventorySeq', label: '재고번호' },
          { value: 'serialNumber', label: '시리얼번호' },
        ],
      },
      {
        SEARCH_SEQ: [
          {
            label: '주문번호',
            value: 'orderSeq',
          },
          {
            label: '외부주문번호',
            value: 'externalOrderNumber',
          },
          {
            label: '송장번호',
            value: 'invoiceSeq',
          },
          {
            label: '외부송장번호',
            value: 'externalInvoiceNumber',
          },
          {
            label: '고객명',
            value: 'receiverName',
          },
        ],
      },
      {
        SERIAL_DATE: [
          { value: 'CreateDate', label: '생성일' },
          { value: 'ManufactureDate', label: '제조일' },
          { value: 'DistributeDate', label: '유통일' },
        ],
      },
      {
        SEARCH_TABLE_SEQ: [
          { value: 'linehaulSeq', label: '간선피킹번호' },
          { value: 'stockRemoveSeq', label: '반출피킹번호' },
          { value: 'loadingStatementSeq', label: '출고번호' },
          { value: 'transportStatementSeq', label: '운송번호' },
          { value: 'loanPickSeq', label: 'FC피킹번호' },
          { value: 'loanSeq', label: '기사출고번호' },
          { value: 'loanComponentSeq', label: '자재출고번호' },
        ],
      },
    ];
    initMasterOptions(masterArr);
  };
  return <InvisibleWrapper>재고재고현황</InvisibleWrapper>;
}
export function Modifications() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [SUPPLIER_WHOLE, SELLER_AUTH_WHOLE, SELLER_WHOLE, CENTER_WHOLE, CENTER_AUTH_WHOLE, INVA_STATUS, INVA_TYPE, BRAND, MODEL_GROUP, MODEL_TYPE, MODEL_1, MODEL_2, MODEL_3] = await Promise.all([
      partnerListForlabeling({ sellerFlag: false, supplierFlag: true }),
      partnerListForlabeling({ sellerFlag: true }),
      partnerListForlabeling({ sellerFlag: false }),
      centerListForlabeling({ selectFlag: false }),
      centerListForlabeling(),
      codeMasterOptionReturn('INVA_STATUS'),
      codeMasterOptionReturn('INVA_TYPE'),
      codeMasterOptionReturn('BRAND'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
      codeMasterOptionReturn('MODEL_GRP_1'),
      codeMasterOptionReturn('MODEL_GRP_2'),
      codeMasterOptionReturn('MODEL_GRP_3'),
    ]);

    dispatch(
      setMasterOptions({
        SELLER_AUTH: SELLER_AUTH_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SUPPLIER: SUPPLIER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER_AUTH: CENTER_AUTH_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        INVA_STATUS,
        INVA_TYPE,
        BRAND,
        MODEL_GROUP,
        MODEL_TYPE,
        MODEL_1,
        MODEL_2,
        MODEL_3,
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>재고재고조정</InvisibleWrapper>;
}
export function ModificationAvaDefRegit() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MODIFICATION_AVA_DEF_REGIT_MASTER);
  };

  return <InvisibleWrapper>재고양품/불용재고조정신청</InvisibleWrapper>;
}
export function InventoryInvestigate() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(INVESTIGATE_MASTER);
  };
  return <InvisibleWrapper>재고재고조사</InvisibleWrapper>;
}

export function InventoryTracking() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [SELLER_WHOLE, INV_TRACK_TYPE, INV_AVAIL_STAT] = await Promise.all([
      partnerListForlabeling({ sellerFlag: true }),
      codeMasterOptionReturn('INV_TRACK_TYPE'),
      codeMasterOptionReturn('INV_AVAIL_STAT'),
    ]);

    dispatch(
      setMasterOptions({
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        INV_TRACK_TYPE,
        INV_AVAIL_STAT,
        SEARCH_MODEL,
        SEARCH_LOCATION: [
          { value: 'locationCodeIn', label: '입고로케이션' },
          { value: 'locationCodeOut', label: '출고로케이션' },
        ],
        SEARCH_PLACE: [
          { value: 'placeIn', label: '입고지' },
          { value: 'placeOut', label: '출고지' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>재고재고추적</InvisibleWrapper>;
}
export function Refur() {
  const dispatch = useDispatch();

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [CENTER_WHOLE, PARTNER_SELLER_WHOLE, INV_REFUR_STAT, MODEL_GROUP, MODEL_TYPE, DRIVER_WHOLE, CENTER_SELECT_WHOLE, FEE_TYPE] = await Promise.all([
      centerListForlabeling(),
      partnerListForlabeling({ sellerFlag: true, selectFlag: true }),
      codeMasterOptionReturn('INV_REFUR_STAT'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
      driverListForLabeling(),
      centerListForlabeling({ selectFlag: true }),
      codeMasterOptionReturn('FEE_TYPE'),
    ]);

    dispatch(
      setMasterOptions({
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        SELLER: PARTNER_SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        INV_REFUR_STAT,
        MODEL_GROUP,
        MODEL_TYPE,
        DRIVER_WHOLE,
        CENTER_SELECT_WHOLE,
        FEE_TYPE,
        BEFORE: [
          { value: 'beforeBarcode', label: '바코드' },
          { value: 'beforeModelStockSeq', label: 'SKU' },
          { value: 'beforeSkuNumber', label: '외부SKU' },
          { value: 'beforeModel', label: '제품' },
          { value: 'beforeModelName', label: '제품명' },
          { value: 'beforeSerialNumber', label: '시리얼번호' },
        ],
        AFTER: [
          { value: 'afterBarcode', label: '바코드' },
          { value: 'afterModelStockSeq', label: 'SKU' },
          { value: 'afterSkuNumber', label: '외부SKU' },
          { value: 'afterModel', label: '제품' },
          { value: 'afterModelName', label: '제품명' },
          { value: 'afterSerialNumber', label: '시리얼번호' },
        ],
        STATUS: [
          { value: 'AVAILABLE', label: '양품' },
          { value: 'DEFECTED', label: '불용' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>재고리퍼관리</InvisibleWrapper>;
}

export function InventoryInspect() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(INVENTORY_INSPECT_TAB_MASTER);
  };

  return <InvisibleWrapper>재고품목전환</InvisibleWrapper>;
}

export function InventoryRegister() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(REFUR_AND_MODEL_MOVE_REGISTER_MASTER);
  };

  return <InvisibleWrapper>재고리퍼,품목전환생성(공통)</InvisibleWrapper>;
}

export function ModelMoveList() {
  const dispatch = useDispatch();

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [
      //
      DRIVER_WHOLE,
      SELLER,
      CENTER_SELECT_WHOLE,
      CENTER_WHOLE,
      MODEL_GROUP,
      MODEL_TYPE,
      FEE_TYPE,
      INVA_STATUS, //재고품목전환생성에서 사용
      INVA_TYPE, //재고품목전환생성에서 사용
    ] = await Promise.all([
      driverListForLabeling(),
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling({ selectFlag: true }),
      centerListForlabeling(),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
      codeMasterOptionReturn('FEE_TYPE'),
      codeMasterOptionReturn('INVA_STATUS'),
      codeMasterOptionReturn('INVA_TYPE'),
    ]);

    dispatch(
      setMasterOptions({
        STATUS: [
          { value: 'AVAILABLE', label: '양품' },
          { value: 'DEFECTED', label: '불용' },
        ],
        DRIVER_WHOLE,
        DRIVER_NAME: DRIVER_WHOLE?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        SELLER: SELLER?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_AUTH: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        MODEL_GROUP,
        MODEL_TYPE,
        INVA_STATUS,
        INVA_TYPE,
        FEE_TYPE,
        BEFORE: [
          { value: 'beforeBarcode', label: '바코드' },
          { value: 'beforeModelStockSeq', label: 'SKU' },
          { value: 'beforeSkuNumber', label: '외부SKU' },
          { value: 'beforeModel', label: '제품' },
          { value: 'beforeModelName', label: '제품명' },
          { value: 'beforeSerialNumber', label: '시리얼번호' },
        ],
        AFTER: [
          { value: 'afterBarcode', label: '바코드' },
          { value: 'afterModelStockSeq', label: 'SKU' },
          { value: 'afterSkuNumber', label: '외부SKU' },
          { value: 'afterModel', label: '제품' },
          { value: 'afterModelName', label: '제품명' },
          { value: 'afterSerialNumber', label: '시리얼번호' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>재고재고품목전환</InvisibleWrapper>;
}
export function ModelMoveRegit() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [SELLER_WHOLE, CENTER_WHOLE, INVA_STATUS, INVA_TYPE, MODEL_GROUP, MODEL_TYPE] = await Promise.all([
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling(),
      codeMasterOptionReturn('INVA_STATUS'),
      codeMasterOptionReturn('INVA_TYPE'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
    ]);

    dispatch(
      setMasterOptions({
        MODEL_TYPE,
        MODEL_GROUP,
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        INVA_STATUS,
        INVA_TYPE,
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>재고재고품목전환생성(미사용)</InvisibleWrapper>;
}

export function ScmManagement() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [
      DRIVER_WHOLE,
      SELLER,
      CENTER_SELECT_WHOLE,
      CENTER_WHOLE,
      INFRA,
      NETWORK_WHOLE,
      MODEL_GROUP,
      MODEL_TYPE,
      FEE_TYPE,
      LO_STATUS,
      CANCEL_REASON,
      DEFECTED_STAT,
      INVA_STATUS,
      INVA_TYPE,
      INVM_STATUS,
    ] = await Promise.all([
      driverListForLabeling(),
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling({ selectFlag: true }),
      centerListForlabeling(),
      InfraOptionReturn(),
      partnerListForlabeling({ networkFlag: true }),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
      codeMasterOptionReturn('FEE_TYPE'),
      codeMasterOptionReturn('LO_STATUS'),
      codeMasterOptionReturn('CANCEL_REASON'),
      codeMasterOptionReturn('DEFECTED_STAT'),
      codeMasterOptionReturn('INVA_STATUS'),
      codeMasterOptionReturn('INVA_TYPE'),
      codeMasterOptionReturn('INVM_STATUS'),
    ]);

    dispatch(
      setMasterOptions({
        DRIVER_WHOLE,
        DRIVER_NAME: DRIVER_WHOLE?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        SELLER: SELLER?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER_AUTH: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        INFRA,
        NETWORK: NETWORK_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        MODEL_GROUP,
        MODEL_TYPE,
        FEE_TYPE,
        LO_STATUS,
        CANCEL_REASON,
        DEFECTED_STAT,
        INVA_STATUS,
        INVA_TYPE,
        INVM_STATUS,
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
        ],

        SEARCH_SKU: [
          { value: 'modelStockSeq', label: 'SKU' },
          { value: 'skuNumber', label: '외부SKU' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>재고SCM</InvisibleWrapper>;
}
export function InventoryInOutList() {
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [PARTNER_SELLER_WHOLE, CENTER_SELECT_WHOLE, MODEL_GROUP] = await Promise.all([
      //
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling(),
      codeMasterOptionReturn('MODEL_GROUP'),
    ]);

    dispatch(
      setMasterOptions({
        SELLER: PARTNER_SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        MODEL_GROUP,
      }),
    );
    dispatch(setLoading(null));
  };
  const dispatch = useDispatch();
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>재고입출고내역</InvisibleWrapper>;
}
export function InventoryLocation() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    const masterArr = [
      'USE_FLAG',
      'LOCATION_TYPE',
      'DRIVER_WHOLE',
      'SELLER_AUTH',
      'SELLER_WHOLE',
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'MODEL_GROUP',
      'FEE_TYPE',
      'ORDER_SEQ',
      'INVA_STATUS',
      'INVA_TYPE',
      'INVM_STATUS',
      'SEARCH_SKU',
      'PICK_FLAG',
      'SEARCH_MODEL',
    ];
    initMasterOptions(masterArr);
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>재고로케이션조회</InvisibleWrapper>;
}
export function MoveActionList() {
  const dispatch = useDispatch();

  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [CENTER_AUTH_WHOLE, CENTER_WHOLE, SELLER_AUTH_WHOLE, SELLER_WHOLE, INFRA, MODEL_GROUP, INVENTORY_MOVE_TYPE, FEE_TYPE, INVA_TYPE] = await Promise.all([
      centerListForlabeling({ selectFlag: true }),
      centerListForlabeling(),
      partnerListForlabeling({ sellerFlag: true }),
      partnerListForlabeling(),
      InfraOptionReturn(),
      //
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('INVENTORY_MOVE_TYPE'),
      codeMasterOptionReturn('FEE_TYPE'),
      codeMasterOptionReturn('INVA_TYPE'),
    ]);

    dispatch(
      setMasterOptions({
        CENTER_WHOLE,
        CENTER_AUTH: CENTER_AUTH_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        SELLER_AUTH: SELLER_AUTH_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        INFRA,
        MODEL_GROUP,
        INVENTORY_MOVE_TYPE,
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
          { value: 'barcode', label: '바코드' },
          { label: 'SKU', value: 'modelStockSeq' },
          { label: '외부SKU', value: 'skuNumber' },
        ],
        SEARCH_LOC: [
          { value: 'toLocationCode', label: '입고로케이션' },
          { value: 'fromLocationCode', label: '출고로케이션' },
        ],
        FEE_TYPE,
        INVA_TYPE,
        SEARCH_SEQ: [
          {
            label: '주문번호',
            value: 'orderSeq',
          },
          {
            label: '외부주문번호',
            value: 'externalOrderNumber',
          },
          {
            label: '송장번호',
            value: 'invoiceSeq',
          },
          {
            label: '외부송장번호',
            value: 'externalInvoiceNumber',
          },
        ],
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>재고재고이동</InvisibleWrapper>;
}

export function MoveActionRegit() {
  const dispatch = useDispatch();
  const { initMasterOptions } = useMasterOptions();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const initMasters = async () => {
    initMasterOptions(MOVE_ACTION_REGIT_MASTER);
  };
  return <InvisibleWrapper>재고재고이동지시생성</InvisibleWrapper>;
}

export function ModelList() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      //
      'SELLER_SELLER_AUTH',
      'SELLER_SUPPLIER_AUTH',
      'SELLER_WHOLE',
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'INFRA',
      'MODEL_GRP_DIV',
      'MODEL_GROUP',
      'MODEL_TYPE',
      `${RAWDATA}MODEL_GRP_1`,
      `${RAWDATA}MODEL_GRP_2`,
      'MODEL_GRP_1',
      'MODEL_GRP_2',
      'MODEL_GRP_3',
      'INFRA',
      'SERVICE',
      'CONFIRM_STATUS',
      'TEAM_TYPE',
      'BRAND',
      'INSTALL_DURATION',
      'PALLET_TYPE',
      'STORAGE_FEE',
      //
      {
        SEARCH_SEQ: [
          { value: 'productSeq', label: '상품번호' },
          { value: 'externalProductNumber', label: '외부상품번호' },
        ],
      },
      'SEARCH_MODEL',
      {
        SEARCH_BOOL: [
          { value: 'useFlag', label: '활성여부' },
          { value: 'stopFlag', label: '단종여부' },
        ],
      },
      'STOP_FLAG',
      'ACTIVE_FLAG',
      'USE_FLAG',
      'OX_FLAG',
      'MANAGE_FLAG',
    ];
    initMasterOptions(masterArr);
  };
  return <InvisibleWrapper>재고제품관리</InvisibleWrapper>;
}
export function ProductList() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      //
      'SELLER_SELLER_AUTH',
      'SELLER_SUPPLIER_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      'MODEL_GRP_DIV',
      'MODEL_GROUP',
      `${RAWDATA}MODEL_GRP_1`,
      'MODEL_GRP_1',
      'MODEL_GRP_2',
      'MODEL_GRP_3',
      'INFRA',
      'SERVICE',
      'TEAM_TYPE',
      'BRAND',
      'PRODUCT_MANUAL_TYPE',
      'MODEL_TYPE',
      'INSTALL_DURATION',

      //
      {
        SEARCH_SEQ: [
          { value: 'productSeq', label: '상품번호' },
          { value: 'externalProductNumber', label: '외부상품번호' },
        ],
      },
      {
        SEARCH_MODEL: [
          { value: 'productName', label: '상품명' },
          { value: 'modelName', label: '제품명' },
        ],
      },
      'OX_FLAG',
      'NEED_FLAG',
    ];
    initMasterOptions(masterArr);
  };

  return <InvisibleWrapper>재고상품관리</InvisibleWrapper>;
}

export function Settings() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(SETTINGSLIST_MASTER);
  };

  return <InvisibleWrapper>설정</InvisibleWrapper>;
}

export function SettingLists() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(SETTINGSLIST_MASTER);
  };

  return <InvisibleWrapper>설정</InvisibleWrapper>;
}

export function BarcodePrinting() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [PARTNER_SELLER, CENTER_SELECT_WHOLE, LOGITYPE, MODEL_GROUP, MODEL_TYPE, LINEHAUL_TYPE, LINE_STAT, REMOVE_REASON, BRAND] = await Promise.all([
      partnerListForlabeling({ sellerFlag: true }),
      centerListForlabeling(),
      codeMasterOptionReturn('LOGI_TYPE'),
      codeMasterOptionReturn('MODEL_GROUP'),
      codeMasterOptionReturn('MODEL_TYPE'),
      codeMasterOptionReturn('LINEHAUL_TYPE'),
      codeMasterOptionReturn('LINE_STAT'),
      codeMasterOptionReturn('REMOVE_REASON'),
      codeMasterOptionReturn('BRAND'),
    ]);

    dispatch(
      setMasterOptions({
        SELLER: PARTNER_SELLER?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        CENTER: CENTER_SELECT_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        LOGITYPE,
        MODEL_GROUP,
        MODEL_TYPE,
        LINEHAUL_TYPE,
        LINE_STAT,
        REMOVE_REASON,
        BRAND,
        SEARCH_MODEL: [
          { value: 'model', label: '제품' },
          { value: 'modelName', label: '제품명' },
        ],
        SEARCH_BARCODE: [
          { value: 'barcode', label: '바코드' },
          { value: 'externalBarcode', label: 'LOT번호(외부바코드)' },
        ],
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>재고바코드출력</InvisibleWrapper>;
}
export function OrderUploadForm() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(CUSTOMFORM_MASTER);
  };
  return <InvisibleWrapper>주문주문양식관리</InvisibleWrapper>;
}

export function ManageorderInfoList() {
  const dispatch = useDispatch();
  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);
  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions([...ORDER_MASTER, { [COMMON_GRIDID + 'MANAGEORDERINFOLIST1_MAIN']: await getGridParameters('MANAGEORDERINFOLIST1_MAIN') }]);
  };

  return <InvisibleWrapper>주문주문조회</InvisibleWrapper>;
}

export function PurchaseRegit() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(PURCHASEORDER_MASTER);
  };
  return <InvisibleWrapper>주문주문조회발주입고(발주요청)</InvisibleWrapper>;
}

export function ManageParcelList() {
  const dispatch = useDispatch();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const GRIDID = 'PARCELINVOICE_TAB1';
    const PARCELINVOICE_TAB1 = await getGridParameters(GRIDID);
    const masterArr = [
      //
      { [COMMON_GRIDID + GRIDID]: PARCELINVOICE_TAB1 },
      //
      'SELLER_FORWARDER_AUTH',
      //
      `${RAWDATA}DRIVER_WHOLE`,
      'DRIVER_AUTH',
      'DRIVER_WHOLE',
      'DRIVER_PHONE_WHOLE', // 라벨용이 많으니
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'CENTER_CALL_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_NETWORK_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      'SALES_CHANNEL',
      //
      'ORDER_TYPE',
      `${RAWDATA}ORDER_TYPE`,
      'INVOICE_STATUS',
      'INVOICE_STATUS_1',
      'INVOICE_STATUS_2',
      'FEE_TYPE',
      'FEE_TYPE',
      'CANCEL_REASON',
      'COLLECT_REASON',
      'SERVICE_TYPE',
      'MODEL_TYPE',
      'MODEL_GROUP',
      'RESCHEDULE_TYPE',
      'BODY_WIFI_REASON',
      'EOL_GROUP2',
      `${RAWDATA}EOL_GROUP2`,
      'EOL_GROUP3',
      'COUNSEL_DETAIL_TYPE',
      'COUNSEL_TYPE',
      'LADDER_CO',
      'MODEL_GRP_1',
      'MODEL_GRP_2',
      'MODEL_GRP_3',
      'CANCEL_TYPE',
      'MESSAGE_TYPE',
      'INVENTORY_LOCATION',
      'PROVINCE_WHOLE',
      'CITY',
      'DRIVER_PARTY',
      'TEAM_GRADE',
      'DRV_POS',
      'CAR_TYPE',
      'DRV_TYPE',
      'TEAM_TYPE',
      'LOGI_TYPE',
      'PACKING_STATE',
      'INV_TRACK_TYPE',
      'PARCEL_PAY_TYPE',
      'INVOICE_UPDATE_CONTENT',
      'INSTALL_FORM',
      //
      'SEARCH_ORDER',
      'SEARCH_INVOICE',
      'SEARCH_MODEL',
      //
      'INSTALL_DURATION',
      'DELIVERY_TIME',
      'UNIFORM_SIZE',
      'WEEK_DAY',
      'OX_FLAG',
      'USE_FLAG',
      'LITE_FLAG',
      'FIX_DATE',
      {
        SEARCH_DATE: [
          {
            label: '주문등록일',
            value: 'RegisterDate',
          },
          {
            label: '배송완료일',
            value: 'DeliveryDate',
          },
        ],
      },
      // 택배운송장
      'PACKING_STATE',
      'PARCEL_PAY_TYPE',
      'INV_TRACK_TYPE',
      'PARCEL_INVOICE_STATUS',
    ];
    initMasterOptions(masterArr);
  };
  return <InvisibleWrapper>주문택배운송장조회</InvisibleWrapper>;
}
export function ManageOrderHistory() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(ORDERHISTORY_MASTER);
  };
  return <InvisibleWrapper>주문주문변경이력조회</InvisibleWrapper>;
}
export function ManageService() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(ORDERSERVICE_MASTER);
  };
  return <InvisibleWrapper>주문서비스관리</InvisibleWrapper>;
}

export function Payment() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(EXTRAPAYMENT_MASTER);
  };

  return <InvisibleWrapper>주문추가결제내역</InvisibleWrapper>;
}

export function ManageRelocationorderInfoList() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(RELOCATEORDER_MASTER);
  };
  return <InvisibleWrapper>주문이전주문조회</InvisibleWrapper>;
}

export function SsList() {
  const dispatch = useDispatch();
  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);
  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(SSLIST_MASTER);
  };

  return <InvisibleWrapper>판매현황</InvisibleWrapper>;
}
export function SsRegit() {
  const dispatch = useDispatch();
  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);
  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(SSREGIT_MASTER);
  };

  return <InvisibleWrapper>판매등록</InvisibleWrapper>;
}
export function ManageorderRegister() {
  const dispatch = useDispatch();
  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);
  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(ORDER_MASTER);
  };

  return <InvisibleWrapper>주문주문등록(주문불러오기)</InvisibleWrapper>;
}

export function MessageSendManange() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();

  const initMasters = async () => {
    initMasterOptions(MANAGEMESSAGE_MASTER);
  };
  return <InvisibleWrapper>주문문자발송관리</InvisibleWrapper>;
}

export function CustomerCs() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      //
      `${RAWDATA}DRIVER_WHOLE`,
      'DRIVER_AUTH',
      'DRIVER_WHOLE',
      'DRIVER_PHONE_WHOLE', // 라벨용이 많으니
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'CENTER_CALL_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_NETWORK_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      'SALES_CHANNEL',
      //
      'ORDER_TYPE',
      `${RAWDATA}ORDER_TYPE`,
      'INVOICE_STATUS',
      'FEE_TYPE',
      'FEE_TYPE',
      'CANCEL_REASON',
      'COLLECT_REASON',
      'SERVICE_TYPE',
      'MODEL_TYPE',
      'MODEL_GROUP',
      'RESCHEDULE_TYPE',
      'BODY_WIFI_REASON',
      'EOL_GROUP2',
      `${RAWDATA}EOL_GROUP2`,
      'EOL_GROUP3',
      'COUNSEL_DETAIL_TYPE',
      'COUNSEL_TYPE',
      'LADDER_CO',
      'MODEL_GRP_1',
      'MODEL_GRP_2',
      'MODEL_GRP_3',
      'CANCEL_TYPE',
      'MESSAGE_TYPE',
      'INVENTORY_LOCATION',
      'RAWDATA_PROVINCE',
      'CITY',
      'DRIVER_PARTY',
      'TEAM_GRADE',
      'DRV_POS',
      'CAR_TYPE',
      'DRV_TYPE',
      'TEAM_TYPE',
      'LOGI_TYPE',
      'PACKING_STATE',
      'INV_TRACK_TYPE',
      'PARCEL_PAY_TYPE',
      'INVOICE_UPDATE_CONTENT',
      'INSTALL_FORM',
      //
      'SEARCH_ORDER',
      'SEARCH_INVOICE',
      'SEARCH_MODEL',
      //
      'INSTALL_DURATION',
      'DELIVERY_TIME',
      'UNIFORM_SIZE',
      'WEEK_DAY',
      'OX_FLAG',
      'USE_FLAG',
      'LITE_FLAG',
      'FIX_DATE',
      {
        SEARCH_DATE: [
          {
            label: '고객주문일',
            value: 'OrderDate',
          },
          {
            label: '등록일',
            value: 'RegisterDate',
          },
          {
            label: '희망일',
            value: 'PromiseDeliveryDate',
          },
          {
            label: '지정일',
            value: 'DeliveryDate',
          },
          {
            label: '배송확정일',
            value: 'DeliveryConfirmDate',
          },
          {
            label: '완료일/취소일',
            value: 'FinishOrCancelDate',
          },
        ],
      },
      {
        PMODEL_TYPE: [
          { value: 'MODEL', label: '구성품' },
          { value: 'GIFT', label: '사은품' },
        ],
      },
      {
        SEARCH_PRODUCT: [
          { value: 'productName', label: '상품명' },
          { value: 'productSeq', label: '상품번호' },
          { value: 'externalProductNumber', label: '외부상품번호' },
        ],
      },
    ];
    initMasterOptions(masterArr);
  };
  return <InvisibleWrapper>배송고객상담</InvisibleWrapper>;
}
export function DriverCs() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [CENTER, DRIVER, SELLER, QNA_TYPE1, QNA_TYPE2, QNA_TYPE3, QNA_STATUS] = await Promise.all([
      //
      centerListForlabeling(),
      driverListForLabeling(),
      partnerListForlabeling({ sellerFlag: true }),
      codeMasterOptionReturn('QNA_TYPE1'),
      codeMasterOptionReturn('QNA_TYPE2'),
      codeMasterOptionReturn('QNA_TYPE3'),
      codeMasterOptionReturn('QNA_STATUS'),
    ]);

    dispatch(
      setMasterOptions({
        CENTER: CENTER?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        DRIVER: DRIVER?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
        SELLER: SELLER?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        //
        QNA_TYPE1,
        QNA_TYPE2,
        QNA_TYPE3,
        QNA_STATUS,
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>배송설치기사상담</InvisibleWrapper>;
}
export function SystemAnnouncement() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [CENTER_WHOLE, ANNOUNCE_CATEGORY, ANNOUNCE_TYPE] = await Promise.all([centerListForlabeling(), codeMasterOptionReturn('NOTICE_CATEGORY'), codeMasterOptionReturn('NOTICE_TYPE')]);

    dispatch(
      setMasterOptions({
        CENTER: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        ANNOUNCE_CATEGORY,
        ANNOUNCE_TYPE,
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>배송공지사항</InvisibleWrapper>;
}

export function CustomAlertmsg() {
  const dispatch = useDispatch();
  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);
  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(CUSTOMALERTMSG_MASTER);
  };

  return <InvisibleWrapper>배송알림메시지관리</InvisibleWrapper>;
}

export function ManageLazyorderList() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [CENTER_WHOLE, SELLER_WHOLE, REMOVE_STATUS, REMOVE_REASON] = await Promise.all([
      //
      centerListForlabeling(),
      partnerListForlabeling({ sellerFlag: true }),
      codeMasterOptionReturn('REMOVE_STATUS'),
      codeMasterOptionReturn('REMOVE_REASON'),
    ]);

    dispatch(
      setMasterOptions({
        CENTER_LABEL: CENTER_WHOLE?.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        SELLER: SELLER_WHOLE?.map((ele) => {
          return {
            value: ele.partnerSeq,
            label: ele.partnerName,
          };
        }),
        REMOVE_STATUS,
        REMOVE_REASON,
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>배송주문연기요청대상</InvisibleWrapper>;
}
export function ManageDeliverySchedules() {
  const dispatch = useDispatch();
  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);
  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(DELIVERY_SCHEDULE_MASTER);
  };
  return <InvisibleWrapper>배송배송일정관리</InvisibleWrapper>;
}
export function DailyClosing() {
  useEffect(() => {
    initMasters();
  }, []);
  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      `${RAWDATA}DRIVER_WHOLE`,
      'DRIVER_AUTH',
      'DRIVER_WHOLE',
      'DRIVER_PHONE_WHOLE', // 라벨용이 많으니
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'CENTER_CALL_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_NETWORK_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      'SALES_CHANNEL',
      //
      'ORDER_TYPE',
      `${RAWDATA}ORDER_TYPE`,
      'INVOICE_STATUS',
      'INVOICE_STATUS_1',
      'INVOICE_STATUS_2',
      'FEE_TYPE',
      'FEE_TYPE',
      'CANCEL_REASON',
      'COLLECT_REASON',
      'SERVICE_TYPE',
      'MODEL_TYPE',
      'MODEL_GROUP',
      'RESCHEDULE_TYPE',
      'BODY_WIFI_REASON',
      'EOL_GROUP2',
      `${RAWDATA}EOL_GROUP2`,
      'EOL_GROUP3',
      'COUNSEL_DETAIL_TYPE',
      'COUNSEL_TYPE',
      'LADDER_CO',
      'MODEL_GRP_1',
      'MODEL_GRP_2',
      'MODEL_GRP_3',
      'CANCEL_TYPE',
      'MESSAGE_TYPE',
      'INVENTORY_LOCATION',
      'PROVINCE_WHOLE',
      'CITY',
      'DRIVER_PARTY',
      'TEAM_GRADE',
      'DRV_POS',
      'CAR_TYPE',
      'DRV_TYPE',
      'TEAM_TYPE',
      'LOGI_TYPE',
      'PACKING_STATE',
      'INV_TRACK_TYPE',
      'PARCEL_PAY_TYPE',
      'INVOICE_UPDATE_CONTENT',
      'INSTALL_FORM',
      //
      'SEARCH_ORDER',
      'SEARCH_INVOICE',
      'SEARCH_MODEL',
      //
      'INSTALL_DURATION',
      'DELIVERY_TIME',
      'UNIFORM_SIZE',
      'WEEK_DAY',
      'OX_FLAG',
      'USE_FLAG',
      'LITE_FLAG',
      'FIX_DATE',
      {
        CLOSE_STATUS: [
          { value: 'NOT_LOAN', label: '미출고' },
          { value: 'NOT_FINISH', label: '미완료' },
          { value: 'FINISH', label: '완료' },
          { value: 'CANCEL', label: '취소' },
        ],
      },
      {
        PMODEL_TYPE: [
          { value: 'MODEL', label: '구성품' },
          { value: 'GIFT', label: '사은품' },
        ],
      },
    ];
    initMasterOptions(masterArr);
  };

  return <InvisibleWrapper>배송일일마감</InvisibleWrapper>;
}
export function DriverDeliveryClosing() {
  const dispatch = useDispatch();
  const initMasterOptions = async () => {
    dispatch(setLoading('GET'));
    const [FEE_TYPE, INVOICE_STATUS, CANCEL_REASON, CENTER_WHOLE, DRIVER_WHOLE] = await Promise.all([
      codeMasterOptionReturn('FEE_TYPE'),
      codeMasterOptionReturn('INVOICE_STATUS'),
      codeMasterOptionReturn('CANCEL_REASON'),
      centerListForlabeling(),
      driverListForLabeling(),
    ]);
    dispatch(
      setMasterOptions({
        FEE_TYPE,
        INVOICE_STATUS,
        CANCEL_REASON,
        CENTER: CENTER_WHOLE.map((ele) => {
          return {
            value: ele.centerCode,
            label: ele.centerName,
          };
        }),
        DRIVER: DRIVER_WHOLE?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
      }),
    );
    dispatch(setLoading(null));
  };
  useEffect(() => {
    initMasterOptions();
    return () => {
      dispatch(setMasterOptions(null));
    };
  }, []);
  return <InvisibleWrapper>배송기사별매송마감</InvisibleWrapper>;
}

export function Kpis() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      //
      'DRIVER_AUTH',
      'DRIVER_WHOLE',
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_WHOLE',
      'INFRA',
      //
      'INVENTORY_LOCATION',
      'ORDER_TYPE',
      'FEE_TYPE',
      'INVOICE_STATUS',
      //
      {
        SEARCH_LEADTIME_DATE: [
          {
            label: '고객주문일',
            value: 'OrderDate',
          },
          {
            label: '등록일',
            value: 'RegisterDate',
          },
        ],
      },
      {
        SEARCH_PROMISE_DATE: [
          {
            label: '지정일',
            value: 'DeliveryDate',
          },
          {
            label: '희망일',
            value: 'PromiseDate',
          },
        ],
      },
    ];
    initMasterOptions(masterArr);
  };

  return <InvisibleWrapper>통계kpi</InvisibleWrapper>;
}

export function Kpibycapa() {
  const dispatch = useDispatch();

  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    const masterArr = [
      //
      'CENTER_AUTH',
      'CENTER_WHOLE',
      'SELLER_SELLER_AUTH',
      'SELLER_WHOLE',
      'INFRA',
    ];
    initMasterOptions(masterArr);
  };
  return <InvisibleWrapper>통계capa대비kpi</InvisibleWrapper>;
}

export function Headquarters() {
  const location = useLocation();
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    initMasters();
  }, [location.pathname.split('/')?.[2]]);
  const initMasters = async () => {
    initMasterOptions(BASICFEE_MASTER);
  };

  return <InvisibleWrapper>정산 기준정보</InvisibleWrapper>;
}

export function OrderReceiptheadquaters() {
  const location = useLocation();
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    initMasters();
  }, [location.pathname.split('/')?.[2]]);
  const initMasters = async () => {
    initMasterOptions(ORDERRECEIPT_MASTER);
  };
  return <InvisibleWrapper>정산 주문영수증</InvisibleWrapper>;
}

export function TransactionStatheadquaters() {
  const location = useLocation();
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    initMasters();
  }, [location.pathname.split('/')?.[2]]);
  const initMasters = async () => {
    initMasterOptions(TRANSACTION_MASTER);
  };
  return <InvisibleWrapper>정산 거래명세서</InvisibleWrapper>;
}

export function DriverList() {
  const { initMasterOptions } = useMasterOptions();
  const dispatch = useDispatch();
  useEffect(() => {
    initMasters();
    return () => {
      dispatch(nullingMASTER());
    };
  }, []);
  const initMasters = async () => {
    initMasterOptions(DRIVER_LIST_MASTER);
  };

  return <InvisibleWrapper>기타기사관리</InvisibleWrapper>;
}

export function DeliverySector() {
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    initMasters();
  }, []);

  const initMasters = async () => {
    initMasterOptions(DELIVERYSECTOR_MASTER);
  };

  return <InvisibleWrapper>기타배송그룹설정</InvisibleWrapper>;
}

export function WarehouseList() {
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    initMasters();
  }, []);

  const initMasters = async () => {
    initMasterOptions(WAREHOUSE_MASTER);
  };
  return <InvisibleWrapper>기타창고관리</InvisibleWrapper>;
}

export function CenterLocation() {
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    initMasters();
  }, []);

  const initMasters = async () => {
    initMasterOptions(WAREHOUSELOCATION_MASTER);
  };
  return <InvisibleWrapper>기타로케이션조정</InvisibleWrapper>;
}

export function PickingStrategy() {
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    initMasters();
  }, []);

  const initMasters = async () => {
    initMasterOptions(PICKINGSTRATEGY_MASTER);
  };
  return <InvisibleWrapper>기타판매사별피킹전략</InvisibleWrapper>;
}

export function SalesChannel() {
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    const masterArr = ['SALES_CHANNEL', 'SALES_CHANNEL_TYPE'];
    initMasterOptions(masterArr);
  }, []);
  return <InvisibleWrapper>기타판매채널조회</InvisibleWrapper>;
}

export function InuserList() {
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    initMasters();
  }, []);

  const initMasters = async () => {
    initMasterOptions(USER_MASTER);
  };
  return <InvisibleWrapper>기타사용자관리</InvisibleWrapper>;
}

export function PartnerList() {
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    initMasters();
  }, []);

  const initMasters = async () => {
    initMasterOptions(PARTNER_MASTER);
  };
  return <InvisibleWrapper>기타파트너관리</InvisibleWrapper>;
}

export function InfraGroupList() {
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    initMasters();
  }, []);
  const initMasters = async () => {
    initMasterOptions(INFRA_MASTER);
  };
  return <InvisibleWrapper>기타인프라조회</InvisibleWrapper>;
}

export function ManagerLD() {
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    initMasterOptions(MANAGERLD_MASTER);
  }, []);
  return <InvisibleWrapper>기타조직관리물류대장용</InvisibleWrapper>;
}

export function ManageUserInGroup() {
  const { initMasterOptions } = useMasterOptions();
  useEffect(() => {
    initMasterOptions(MANAGERLD_MASTER);
  }, []);
  return <InvisibleWrapper>기타조직원관리</InvisibleWrapper>;
}
