// react
import { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Row, Table, Form, Label, Button } from 'reactstrap';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// utils
import { LINEHAUL_ROLLBACK, LINEHAUL_CANCEL, LINEHAUL_LOADSTAT_IMG_SAVE } from 'envVar';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { httpClient } from 'common/http-client/axiosConfig';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

//component
import Grid from 'components/grid/auiGrid';
import { GridModal } from 'components/modal/gridModal';
import { BottomSheets } from 'components/template/bottomSheets';

//redux
import { useSelector, useDispatch } from 'react-redux';

// img
import CloseImg from 'assets/images/close/cross.png';

// lib
import { handleFile, uploadFile } from 'common/util/photo';
import { v4 as uuidv4 } from 'uuid';
import ImageModal from 'components/modal/imageModal';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import useExcelHooks from 'hooks/excel/useExcelHooks';
import { useDidMountEffect } from 'hooks/basichooks';
import CommonModalNew from 'components/modal/commonModalNew';
import { TrunkPrints } from '../../_print/trunkPrint';
import { TRUNK_CHULGO_SERIAL_FORM } from 'envVar2';

import { resetAllSelections, toggleBooleanOptions } from 'common/util/searchBox';
import PrintGridBox from 'common/grid/printGridBox';
import { useFrontSearchData } from 'hooks/grid/useFrontSearchData';
import { setLoading } from 'redux/services/menuSlice';
import { serviceStore } from 'services/services';
import Divider from 'components/LDS/atoms/Divider';
import GridBoxCommon from 'common/grid/gridBox';

const excelDetailColumns = [
  { headerText: '피킹지시번호', dataField: 'linehaulSeq' },
  { headerText: '출고지시번호', dataField: 'loadingStatementSeq' },
  { headerText: '상태', dataField: 'statusKr' },
  { headerText: '출발창고', dataField: 'originalCenterCodeKr' },
  { headerText: '도착창고', dataField: 'destinationCenterCodeKr' },
  { headerText: '출고예정일', dataField: 'linehaulDate' },
  { headerText: '간선유형', dataField: 'linehaulTypeKr' },
  { headerText: '로케이션', dataField: 'locationCode' },
  { headerText: '제품그룹', dataField: 'modelGroupKr' },
  { headerText: 'SKU', dataField: 'modelStockSeq' },
  { headerText: '외부SKU', dataField: 'skuNumber' },
  { headerText: '판매사', dataField: 'partnerSeqKr' },
  { headerText: '바코드', dataField: 'barcode' },
  { headerText: '제품', dataField: 'model' },
  {
    headerText: '간선출고대상',
    children: [
      { headerText: '양품', dataField: 'availableQuantity' },
      { headerText: '불용', dataField: 'defectedQuantity' },
      { headerText: '기타', dataField: 'etcQuantity' },
    ],
  },
  {
    headerText: '출고완료',
    children: [
      { headerText: '양품', dataField: 'loadAvailableQuantity' },
      { headerText: '불용', dataField: 'loadDefectedQuantity' },
      { headerText: '기타', dataField: 'loadEtcQuantity' },
    ],
  },
  {
    headerText: '미출고',
    children: [
      { headerText: '양품', dataField: 'availableDiff' },
      { headerText: '불용', dataField: 'defectedDiff' },
      { headerText: '기타', dataField: 'etcDiff' },
    ],
  },
  { headerText: '출고지시 확정자', dataField: 'registerId' },
  { headerText: '출고지시 확정일', dataField: 'registerDatetime' },
  { headerText: '출고완료일', dataField: 'pickDatetime' },
];
const Presenter = (props) => {
  const {
    photoPopupObj,
    setPhotoPopupObj,
    alertModalObj,
    setAlertModalObj,
    bottomSheetObj,
    setBottomSheetObj,
    inventoryModalObj,
    setInventoryModalObj,
    inventoryCancelModalObj,
    setInventoryCancelModalObj,
    trunkPrintObj,
    setTrunkPrintObj,
    getCheckedDataTriggerThisGrid,
    setGetCheckedDataTriggerThisGrid,
    inventoryModalGridId,
    setSearchObj,
    searchObj,
  } = props;

  return (
    <>
      <div className="page-content">
        {trunkPrintObj?.visible && <TrunkPrints trunkPrintObj={trunkPrintObj} setTrunkPrintObj={setTrunkPrintObj} />}
        {photoPopupObj?.visible && <PhotoPopup photoPopupObj={photoPopupObj} setPhotoPopupObj={setPhotoPopupObj} />}
        {alertModalObj?.visible && alertModalObj.type === 'rollBack' && <LinehaulRollBackModal alertModalObj={alertModalObj} setAlertModalObj={setAlertModalObj} />}
        {alertModalObj?.visible && alertModalObj.type === 'cancel' && <LinehaulCancelModal alertModalObj={alertModalObj} setAlertModalObj={setAlertModalObj} />}
        {inventoryModalObj?.visible && (
          <HaldangModal
            haldangObj={inventoryModalObj}
            setHaldangObj={setInventoryModalObj}
            getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
            setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            inventoryModalGridId={inventoryModalGridId}
          />
        )}
        {inventoryCancelModalObj?.visible && (
          <InventoryCancelModal inventoryCancelModalObj={inventoryCancelModalObj} setInventoryCancelModalObj={setInventoryCancelModalObj} setSearchObj={setSearchObj} searchObj={searchObj} />
        )}
        {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const PhotoPopup = ({ photoPopupObj, setPhotoPopupObj }) => {
  const handleFileUpload = async (e) => {
    const contentArr = e.target.id.split('_');
    const content = contentArr[0];
    const idx = Number(contentArr[1]);
    const ret = await handleFile(e);
    const uploadUrl = await uploadFile(ret.file, ret.url, `${content}_${idx}`);
    const data = {
      loadingStatementSeq: photoPopupObj?.photos?.loadingStatementSeq,
      addFiles: [
        {
          column: `LOADINGOUT${idx + 1}`,
          uploadUrl,
        },
      ],
    };
    httpClient.post(LINEHAUL_LOADSTAT_IMG_SAVE, data).then((res) => {
      if (res?.status === 200) {
        alert('사진 업로드에 성공하였습니다!');
        photoPopupObj?.fetchPhotosFn(photoPopupObj?.item);
      }
    });
  };

  const handleFileRemove = async (e) => {
    const contentArr = e.target.id.split('_');
    const idx = Number(contentArr[1]);

    const prompt = window.confirm('사진을 정말 삭제하시겠습니까?');
    if (prompt) {
      const data = {
        loadingStatementSeq: photoPopupObj?.photos?.loadingStatementSeq,
        deleteFiles: [
          {
            column: `LOADINGOUT${idx + 1}`,
            uploadUrl: files[idx],
          },
        ],
      };
      httpClient.post(LINEHAUL_LOADSTAT_IMG_SAVE, data).then((res) => {
        if (res?.status === 200) {
          alert('사진 삭제에 성공하였습니다!');
          photoPopupObj?.fetchPhotosFn(photoPopupObj?.item);
        }
      });
    }
  };

  const [files, setFiles] = useState();

  useEffect(() => {
    if (photoPopupObj?.photos) {
      setPhotos();
    }
  }, [photoPopupObj?.photos]);

  const setPhotos = () => {
    const photos = new Array(10).fill(null);
    photoPopupObj?.photos?.files.forEach((file) => {
      const i = Number(file?.column.split('LOADINGOUT')[1]) - 1;
      photos[i] = file.uploadUrl;
    });
    setFiles(photos);
  };

  return (
    <>
      <CommonModalNew
        title="사진 모아보기"
        subTitle={`출고지시번호 ${photoPopupObj?.photos?.loadingStatementSeq}`}
        visible={photoPopupObj?.visible}
        setVisible={() => {
          setPhotoPopupObj({
            ...photoPopupObj,
            visible: false,
          });
        }}
        childre={
          <>
            <Row>
              <div className="moa-bogi">
                <Row>
                  {files?.map((url, i) => {
                    return (
                      <Col key={`photos_${i}_${uuidv4()}`}>
                        <div className="photo-zone">
                          <span className="bck-font"> 사진 {i + 1}</span>
                          {url ? (
                            <div style={{ display: 'inline-block' }}>
                              <img
                                src={CloseImg}
                                id={`photos_${i}_`}
                                alt=""
                                onClick={(e) => {
                                  handleFileRemove(e);
                                }}
                                className="closeBtn"
                              />
                              <ImageModal className="top_bar_profile_img" src={url ? url : null} alt="이미지미리보기" title={`사진 ${i + 1}`} />
                            </div>
                          ) : (
                            <>
                              <input style={{ display: 'none' }} type="file" accept="image/*" id={`photos_${i}`} className="unloadPhoto" onChange={(e) => handleFileUpload(e)} />

                              <Button size="md" color="primary" outline>
                                <Label for={`photos_${i}`} style={{ marginBottom: 0 }}>
                                  ㅤㅤㅤ파일 선택ㅤㅤㅤ
                                </Label>
                              </Button>
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Row>
          </>
        }
      />
    </>
  );
};

const Search = (props) => {
  const { fetchList, setSearchObj, searchObj, pagingSetting, setPagingSetting } = props;
  const { masterOptions } = useSelector((state) => state.menu);
  const [selectTypeObj, setSelectTypeObj] = useState({
    ORDER_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.ORDER_SEQ, searchObj));
  }, [selectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    if (pagingSetting?.pageNo && pagingSetting?.fetchNew) {
      // 단순히 페이지만 바뀔때
      fetchList(searchObj);
    }
  }, [pagingSetting?.fetchNew]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPagingSetting((prev) => {
        return {
          ...prev,
          pageNo: 1,
          fetchNew: true,
        };
      });
    }
  };

  const dateOption = [
    {
      label: '출고예정일',
      value: 'LinehaulDate',
    },
    {
      label: '출고지시확정일',
      value: 'ConfirmDate',
    },
    {
      label: '출고완료일',
      value: 'LoadDate',
    },
  ];

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">기간검색</label>
            <div style={{ display: 'flex' }}>
              <Col style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    searchObj?.dateType
                      ? {
                          label: selectlabel(searchObj?.dateType, dateOption),
                          value: searchObj?.dateType,
                        }
                      : null
                  }
                  options={dateOption}
                  onChange={({ value }) => {
                    const _searchObj = {
                      ...searchObj,
                      dateType: value,
                    };
                    if (value === null) {
                      _searchObj['fromDate'] = null;
                      _searchObj['toDate'] = null;
                    }
                    setSearchObj(_searchObj);
                  }}
                />
              </Col>
              <Col style={{ display: 'inline-block' }}>
                <FlatpickrD
                  disabled={searchObj?.dateType === null}
                  value={[searchObj?.fromDate, searchObj?.toDate]}
                  onReset={() => {
                    setSearchObj({
                      ...searchObj,
                      fromDate: null,
                      toDate: null,
                    });
                  }}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      fromDate: returnDateyyyymmdd(option[0]),
                      toDate: returnDateyyyymmdd(option[1]),
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">피킹지시번호</label>
            <InputD
              value={searchObj?.linehaulSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  linehaulSeq: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">출고지시번호</label>
            <InputD
              value={searchObj?.loadingStatementSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  loadingStatementSeq: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">출발창고</label>
            <SelectD
              value={
                searchObj?.originalCenterCode
                  ? {
                      value: searchObj?.originalCenterCode,
                      label: selectlabel(searchObj?.originalCenterCode, masterOptions?.CENTER),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  originalCenterCode: option.value,
                });
              }}
              options={masterOptions?.CENTER_LABEL}
            />
          </Col>
          <Col>
            <label className="col-form-label">도착창고</label>
            <SelectD
              value={
                searchObj?.destinationCenterCode
                  ? {
                      value: searchObj?.destinationCenterCode,
                      label: selectlabel(searchObj?.destinationCenterCode, masterOptions?.CENTER_DIFF),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  destinationCenterCode: option.value,
                });
              }}
              options={masterOptions?.CENTER_DIFF}
            />
          </Col>
          <Col>
            <label className="col-form-label">간선유형</label>
            <SelectD
              value={
                searchObj?.linehaulType
                  ? {
                      value: searchObj?.linehaulType,
                      label: selectlabel(searchObj?.linehaulType, masterOptions?.LINEHAUL_TYPE),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  linehaulType: option.value,
                });
              }}
              options={masterOptions?.LINEHAUL_TYPE}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">진행상태</label>
            <SelectD
              value={
                searchObj?.status
                  ? {
                      value: searchObj?.status,
                      label: selectlabel(searchObj?.status, masterOptions?.LOADING_STAT),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  status: option.value,
                });
              }}
              options={masterOptions?.LOADING_STAT}
            />
          </Col>
          <Col>
            <label className="col-form-label">주문번호/송장번호</label>
            {/* orderSeq */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.ORDER_SEQ
                      ? {
                          value: selectTypeObj?.ORDER_SEQ,
                          label: selectlabel(selectTypeObj?.ORDER_SEQ, masterOptions?.ORDER_SEQ),
                        }
                      : null
                  }
                  options={masterOptions?.ORDER_SEQ}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      ORDER_SEQ: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.ORDER_SEQ}
                  value={searchObj?.[selectTypeObj?.ORDER_SEQ] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.ORDER_SEQ]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">제품/제품명</label>
            {/* model / modelName  */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_MODEL
                      ? {
                          value: selectTypeObj?.SEARCH_MODEL,
                          label: selectlabel(selectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_MODEL}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_MODEL: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_MODEL}
                  value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">유사검색여부</label>
            {/* similaritySearchFlag */}
            <div className="radio-select-area">
              <span
                className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: true,
                    };
                  });
                }}
              >
                유사검색
              </span>
              <span
                className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: false,
                    };
                  });
                }}
              >
                일치검색
              </span>
            </div>
          </Col>
          <Col />
          <Col style={{ textAlign: 'right' }}>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                setPagingSetting({
                  ...pagingSetting,
                  pageNo: 1,
                  fetchNew: true,
                });
              }}
            >
              조회
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const GridBox = (props) => {
  const { masterOptions } = useSelector((state) => state.menu);
  const {
    ExcelUploadFunction,
    searchObj,
    rows = [],
    gridId,
    columns = [],
    onCellClick,
    setGetCheckedDataTriggerThisGrid,
    getCheckedDataTriggerThisGrid,
    pagingSetting,
    setPagingSetting,
    downloadExcel,
  } = props;
  const excelGridRef = useRef();
  const excelDetailGridRef = useRef();
  const checkedArr = [
    //
    'LINEHAUL_SHIPPING_CONFIRM',
    'LINEHAUL_SHIPPING_COMPLETE',
    'LINEHAUL_SHIPPING_MERGE',
    'TRUNK_LINEHAUL_MERGE',
    'LINEHAUL_SHIPPING_CANCEL',
    'LINEHAUL_SHIPPING_ROLLBACK',
    'TRUNK_CHULGO_CLOSE',
  ];
  const excelDownClicked = async (type) => {
    const data = await downloadExcel(type);
    if (data?.length > 0) {
      if (type === 'main') {
        excelGridRef.current.setGridData(data);
        excelGridRef.current.exportAsXlsx({ fileName: '간선출고지시(메인)' });
      } else {
        excelDetailGridRef.current.setGridData(data);
        excelDetailGridRef.current.exportAsXlsx({ fileName: '간선출고지시(상세)' });
      }
    } else {
      alert('조회되는 값이 없습니다!');
    }
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (checkedArr?.includes(id)) {
      setGetCheckedDataTriggerThisGrid({
        type: id,
        id: `#${gridId}`,
      });
    } else if (id === 'LINEHAUL_SHIPPING_UPLOAD_SERIAL') {
      uploaderClick(id);
    } else if (id === 'LINEHAUL_SHIPPING_DOWNLOAD_MAIN') {
      excelDownClicked('main');
    } else if (id === 'LINEHAUL_SHIPPING_DOWNLOAD_DETAIL') {
      excelDownClicked('detail');
    } else if (id === 'LINEHAUL_SHIPPING_UPLOADFORM_SERIAL') {
      window.open(TRUNK_CHULGO_SERIAL_FORM);
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState();
  const functions = fetchUsableFunctionsInThisTab('LINEHAULOUT_TAB2');
  useMemo(() => {
    if (gridId) {
      const btns = printFunctionToBtns(functions, gridButtonhandler);
      setFunctionBtns(btns);
    }
  }, [gridId, searchObj]);

  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks();

  useDidMountEffect(() => {
    if (parsedData?.data?.length > 0) {
      const data = parsedData?.data?.map((row) => {
        return {
          loadingStatementSeq: row[0],
          barcode: row[1],
          serialNumber: row[2],
        };
      });
      ExcelUploadFunction(data);
    }
  }, [parsedData]);
  return (
    <>
      {functionBtns && (
        <div className="grid-button-area">
          {functionBtns}
          <ExcelDetector />
        </div>
      )}
      <div style={{ width: '100%' }}>
        {gridId && (
          <>
            <Grid
              id={gridId}
              settingOptions={{
                fixedColumnCount: 4,
                isRowAllCheckCurrentView: true,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
              rows={rows}
              columns={columns}
              onCellClick={onCellClick}
              // custompaging **

              pagingSetting={pagingSetting}
              setPagingSetting={setPagingSetting}
              //엑셀다운
              getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
              setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            />
            <PrintGridBox gridRef={excelGridRef} columns={columns} />
            <PrintGridBox gridRef={excelDetailGridRef} columns={excelDetailColumns} />
          </>
        )}
      </div>
    </>
  );
};

const LinehaulRollBackModal = ({ alertModalObj, setAlertModalObj }) => {
  const dispatch = useDispatch();
  const { visible, linehaulStatus, linehaulSeqs, rollBackData } = alertModalObj;
  const tempArr = ['출고지시확정', '출고중'];
  const [answerFlag, setAnswerFlag] = useState(false);

  useEffect(() => {
    console.log(rollBackData);
  }, []);

  const linehaulRollBackAPI = async (data) => {
    dispatch(setLoading('POST'));
    const rs = await serviceStore?.warehouseAction(`linehaul/beforeLoading/rollback`, 'POST', null, rollBackData);
    if (rs?.status === 200) {
      setAnswerFlag(true);
      if (alertModalObj?.fetchFn && alertModalObj?.searchObj) alertModalObj?.fetchFn(alertModalObj?.searchObj);
    }
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title="이전단계"
      setVisible={setAlertModalObj}
      visible={visible}
      style={{ width: 500 }}
      children={
        <>
          <div
            style={{
              marginTop: '1rem',
              textAlign: 'left',
              borderColor: 'transparent',
            }}
          >
            {linehaulStatus === '출고지시대기' && answerFlag === false ? '출고지시대기 상태에서는 이전단계로 되돌릴수 없습니다.\n 간선출고 취소를 원하실 경우 "간선출고취소 버튼"을 눌러주세요' : ''}
            {linehaulStatus === '출고지시확정' && answerFlag === false ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>출고 지시확정' 상태에서 '출고 지시대기' 상태로 돌아가시겠습니까?</span>
              </div>
            ) : (
              ''
            )}
            {linehaulStatus === '출고지시확정' && answerFlag === true ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>간선출고 지시번호 {linehaulSeqs} 의 상태값이 변경되었습니다.</div>
                <div style={{ color: 'red' }}>※ 단, 안전재고의 경우 삭제됩니다.</div>
              </div>
            ) : (
              ''
            )}
            {linehaulStatus === '출고중' && answerFlag === false ? '"출고 중" 상태에서 “출고 지시확정” 상태로 돌아가시겠습니까?' : ''}
            {linehaulStatus === '출고중' && answerFlag === true ? `간선출고 지시번호 ${linehaulSeqs} 의 상태값이 “출고 지시확정”으로 변경되었습니다.` : ''}
            {linehaulStatus === '출고완료' ? '“출고 완료” 상태에서는 이전단계로 돌아갈 수 없습니다.' : ''}
          </div>
          <div style={{ marginTop: '2rem', textAlign: 'end' }}>
            {answerFlag === false && tempArr.includes(linehaulStatus) ? (
              <button
                className="btn btn-cancel"
                onClick={() => {
                  linehaulRollBackAPI();
                }}
              >
                네
              </button>
            ) : (
              ''
            )}
            {answerFlag === false && tempArr.includes(linehaulStatus) ? (
              <button
                className="btn btn-etc sm"
                onClick={() => {
                  setAlertModalObj({ visible: false });
                }}
              >
                아니요
              </button>
            ) : (
              <button
                className="btn btn-secondary sm"
                onClick={() => {
                  setAlertModalObj({ visible: false });
                }}
              >
                확인
              </button>
            )}
          </div>
        </>
      }
    />
  );
};

const LinehaulCancelModal = ({ alertModalObj, setAlertModalObj }) => {
  const { visible, linehaulStatus, contentValue, orderHoldType, cancelData } = alertModalObj;

  const { masterOptions } = useSelector((state) => state.menu);
  const [answerFlag, setAnswerFlag] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);

  const linehaulCancelAPI = async (data) => {
    const payloadArr = [];
    let url = LINEHAUL_CANCEL;
    for (let i = 0; i < cancelData.length; i++) {
      payloadArr.push({
        holdType: orderHoldType,
        holdNote: contentValue,
        linehaulSeq: cancelData[i].linehaulSeq,
        originalCenterCode: cancelData[i].originalCenterCode,
        destinationCenterCode: cancelData[i].destinationCenterCode,
        linehaulDate: cancelData[i].linehaulDate,
        linehaulType: cancelData[i].linehaulType,
        loadingStatementSeq: cancelData[i].loadingStatementSeq,
      });
    }
    httpClient
      .post(url, payloadArr)
      .then((res) => {})
      .catch(async (err) => {
        setErrorFlag(true);
      });
  };

  const onContentChange = (event) => {
    setAlertModalObj({
      ...alertModalObj,
      contentValue: event.currentTarget.value,
    });
  };

  const tempArr = ['출고지시확정', '출고지시대기'];

  return (
    <CommonModalNew
      title="간선출고 취소"
      setVisible={setAlertModalObj}
      visible={visible}
      style={{ width: 300 }}
      children={
        <>
          <Table
            style={{
              marginTop: '1rem',
              textAlign: 'left',
              borderColor: 'transparent',
            }}
          >
            {tempArr.includes(linehaulStatus) && answerFlag === false && errorFlag === false ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>간선출고 작업을 취소하시겠습니까?</span>
                <span style={{ color: 'red' }}>※ 취소할 경우 다시 되돌릴 수 없으니 신중히 선택해주세요.</span>
              </div>
            ) : (
              ''
            )}
            {tempArr.includes(linehaulStatus) && answerFlag === true && errorFlag === false ? '간선출고 작업이 취소되었습니다.' : ''}
            {!tempArr.includes(linehaulStatus) && answerFlag === false ? `'${linehaulStatus}' 상태에서는 간선출고을 취소할 수 없습니다.` : ''}
            {errorFlag === true ? '오류' : ''}
          </Table>
          {tempArr.includes(linehaulStatus) && answerFlag === false && errorFlag === false ? (
            <>
              <div>
                <label className="col-form-label">출고취소사유</label>
                <SelectD
                  options={masterOptions?.ORDER_HOLD_TYPE}
                  onChange={(option) => {
                    setAlertModalObj({
                      ...alertModalObj,
                      orderHoldType: option.value,
                    });
                  }}
                />
              </div>
              <div style={{ marginTop: '1rem' }}>
                <InputD value={contentValue} onChange={(e) => onContentChange(e)} placeholder="출고취소사유 입력" />
              </div>
            </>
          ) : (
            ''
          )}
          <div style={{ marginTop: '2rem', textAlign: 'end' }}>
            {answerFlag === false && tempArr.includes(linehaulStatus) ? (
              <button
                className="btn btn-etc"
                onClick={() => {
                  linehaulCancelAPI();
                  setAnswerFlag(true);
                }}
              >
                네
              </button>
            ) : (
              ''
            )}
            {answerFlag === false && tempArr.includes(linehaulStatus) ? (
              <button
                className="btn btn-etc sm"
                onClick={() => {
                  setAlertModalObj({ visible: false });
                }}
              >
                아니요
              </button>
            ) : (
              <button
                className="btn btn-etc sm"
                onClick={() => {
                  setAlertModalObj({ visible: false });
                }}
              >
                확인
              </button>
            )}
          </div>
        </>
      }
    />
  );
};

const InventoryCancelModal = ({ inventoryCancelModalObj, setInventoryCancelModalObj, setSearchObj, searchObj }) => {
  const { visible, cancelData, inventorySeqs } = inventoryCancelModalObj;

  const { masterOptions } = useSelector((state) => state.menu);

  const linehaulModalCancelAction = async () => {
    const payloadObj = {
      linehaulSeq: cancelData.linehaulSeq,
      loadingStatementSeq: cancelData.loadingStatementSeq,
      originalCenterCode: cancelData.originalCenterCode,
      destinationCenterCode: cancelData.destinationCenterCode,
      linehaulType: cancelData.linehaulType,
      linehaulDate: cancelData.linehaulDate,
      holdType: cancelData.orderHoldType,
      holdNote: cancelData.contentValue,
      inventorySeqs: inventorySeqs,
    };
    let url = LINEHAUL_CANCEL;
    httpClient
      .post(url, [payloadObj])
      .then((res) => {
        if (res?.status === 200) {
          alert('취소 되었습니다.');
          setSearchObj({ ...searchObj });
        } else {
          alert(res.data.message);
        }
        setInventoryCancelModalObj({ visible: false });
      })
      .catch(async (err) => {
        setInventoryCancelModalObj({ visible: false });
      });
  };

  return (
    <CommonModalNew
      title="출고취소"
      setVisible={setInventoryCancelModalObj}
      visible={visible}
      style={{ width: 300 }}
      children={
        <div>
          <Col>
            <label className="col-form-label">출고취소사유</label>
            <SelectD
              value={{
                value: cancelData?.orderHoldType,
                label: selectlabel(cancelData?.orderHoldType, masterOptions?.ORDER_HOLD_TYPE),
              }}
              options={masterOptions?.['ORDER_HOLD_TYPE']}
              onChange={(option) => {
                setInventoryCancelModalObj({
                  ...inventoryCancelModalObj,
                  cancelData: { ...cancelData, orderHoldType: option.value },
                });
              }}
            />
          </Col>
          <Col style={{ marginTop: '1rem' }}>
            <InputD
              value={cancelData.contentValue || ''}
              onChange={(e) =>
                setInventoryCancelModalObj({
                  ...inventoryCancelModalObj,
                  cancelData: {
                    ...cancelData,
                    contentValue: e.currentTarget.value,
                  },
                })
              }
              placeholder="출고취소사유 입력"
            />
          </Col>
          <Col style={{ marginTop: '1rem', textAlign: 'right' }}>
            <Button
              onDoubleClick={() => {
                return;
              }}
              className="btn-secondary btn sm"
              onClick={() => linehaulModalCancelAction()}
            >
              확인
            </Button>
          </Col>
        </div>
      }
    />
  );
};

// 할당 정보 모달 (이전 : inventoryModalObj)
const HaldangModal = ({ haldangObj, setHaldangObj, haldangModalActionObj, getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid, inventoryModalGridId }) => {
  const [actionParams, setActionParams] = useState({});

  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state) => state.menu);

  const gridRef = useRef();
  const convertKeyValue = {
    customerAddr: (row) => (row?.customerAddr1 ? `${row?.customerAddr1} ${row?.customerAddr2 || ''}` : ''),
    centerCodeKr: (row) => selectlabel(row?.centerCode, masterOptions?.CENTER),
    originalCenterCodeKr: (row) => selectlabel(row?.originalCenterCode, masterOptions?.CENTER),
    destinationCenterCodeKr: (row) => selectlabel(row?.destinationCenterCode, masterOptions?.CENTER),
    driverCenterCodeKr: (row) => selectlabel(row?.driverCenterCode, masterOptions?.CENTER),
    partnerSeqKr: (row) => selectlabel(row?.partnerSeq, masterOptions?.SELLER),
    modelGroupKr: (row) => selectlabel(row?.modelGroup, masterOptions?.MODEL_GROUP),
    availableTypeKr: (row) => selectlabel(row?.availableType, masterOptions?.INVA_TYPE),
    unloadFlagKr: (row) => (row?.unloadFlag === true ? 'O' : 'X'),
    orderCancelFlagKr: (row) => (row?.orderCancelFlag === true ? 'O' : 'X'),
    pickFlagKr: (row) => (row?.pickFlag === true ? 'O' : 'X'),
    loadFlagKr: (row) => (row?.loadFlag === true ? 'O' : 'X'),
  };

  const detailSelectType = { INVENTORY_SEQ: null, ORDER_SEQ: null, SEARCH_MODEL: null };

  const { setGridData, setDetailSelectTypeObj, handleSubmitFiltering, detailSelectTypeObj, detailSearchObj, setDetailSearchObj, convertFieldValue, returnKeyValues, resetFilters } = useFrontSearchData(
    {
      gridRef,
      initDetailSearchObj: {},
      detailSelectType,
      convertKeyValue,
    },
  );

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.INVENTORY_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.INVENTORY_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.ORDER_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, detailSearchObj));
  }, [detailSelectTypeObj?.SEARCH_MODEL]);

  //NOTE: 할당정보 (간선 출고)컬럼
  const columns = [
    {
      headerText: '출고여부',
      dataField: 'loadFlagKr',
    },
    {
      headerText: '주문취소여부',
      dataField: 'orderCancelFlagKr',
    },
    {
      headerText: '재고번호',
      dataField: 'inventorySeq',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '재고유형',
      dataField: 'availableTypeKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '고객명',
      dataField: 'customerName',
    },
    {
      headerText: '고객주소',
      dataField: 'customerAddr',
    },
    {
      headerText: '연락처',
      dataField: 'customerPhone',
    },
    {
      headerText: '권역정보',
      dataField: 'zipcodeGroupInfo',
    },
    {
      headerText: '도크코드',
      dataField: 'dockNumber',
    },
    {
      headerText: '기사명', // 이전 : 설치기사
      dataField: 'driverName',
    },
    {
      headerText: '기사소속창고', // 이전 : 기사출고창고
      dataField: 'driverCenterCodeKr',
    },
    {
      headerText: '지정일', // 이전 : 기사출고예정일
      dataField: 'deliveryDate',
    },
    {
      headerText: '고객주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '비고',
      dataField: 'memo',
    },
  ];

  useEffect(() => {
    fetchHaldangInfo(haldangObj?.origin);
  }, [haldangObj?.origin]);

  const fetchHaldangInfo = async (item) => {
    dispatch(setLoading('GET'));

    setHaldangObj((prev) => {
      return {
        ...prev,
        item: {
          linehaulSeq: item?.linehaulSeq,
          loadingStatementSeq: item?.loadingStatementSeq,
          originalCenterCodeKr: item?.originalCenterCodeKr,
          destinationCenterCodeKr: item?.destinationCenterCodeKr,
          statusKr: item?.statusKr,
        },
      };
    });

    const searchDTO = {
      linehaulSeq: item?.linehaulSeq,
      loadingStatementSeq: item?.loadingStatementSeq,
    };

    // NOTE: 액션 버튼 api 호출 시 필요한 parameter들을 정의
    setActionParams({
      linehaulSeq: item?.linehaulSeq,
      loadingStatementSeq: item?.loadingStatementSeq,
      loadingOutSeq: item?.loadingOutSeq,
      transportStatementSeq: item?.transportStatementSeq,
      originalCenterCode: item?.originalCenterCode,
      destinationCenterCode: item?.destinationCenterCode,
      linehaulDate: item?.linehaulDate,
      linehaulType: item?.linehaulType,
      status: item?.status,
    });

    const rs = await serviceStore?.warehouseAction(`linehaul/beforeLoading/info`, 'GET', searchDTO);

    if (rs?.status === 200) {
      const data = rs?.data;
      setGridData(data);
      const dataKr = data?.map((row) => {
        let keyValues = returnKeyValues(convertKeyValue, row);

        return {
          ...row,
          ...keyValues,
        };
      });
      setHaldangObj((prev) => {
        return { ...prev, data: dataKr };
      });

      gridRef?.current?.setGridData(dataKr);
      dispatch(setLoading(null));
    }
  };

  const handleCheckItems = (e) => {
    const buttonId = e?.target?.id;
    const items = gridRef?.current?.getCheckedRowItemsAll();

    if (items?.length === 0 && !buttonId?.includes('excel')) {
      alert('선택된 항목이 존재하지 않습니다.');
      return;
    }

    if (buttonId?.includes('excel')) {
      gridRef?.current?.exportAsXlsx({ fileName: '간선출고_할당정보' });
      return;
    }

    if (actionParams?.linehaulSeq === '') {
      alert('피킹지시번호가 없습니다!');
      return;
    }

    if (buttonId === 'trunkShipping-modal-rollback') {
      if (!actionParams?.loadingStatementSeq) {
        alert('출고지시번호가 없습니다!');
        return;
      }
      if (actionParams?.status !== 'READY' && actionParams?.status !== 'LOADING') {
        alert('출고중, 출고지시확정 상태일 때만 가능합니다!');
        return;
      }
    }

    if (buttonId === 'trunkShipping-modal-cancel') {
      if (actionParams?.status !== 'READY' && actionParams?.status !== 'INIT') {
        alert('출고지시대기, 출고지시확정 상태일 때만 가능합니다!');
        return;
      }
    }

    if (buttonId === 'trunkShipping-modal-confirm') {
      if (actionParams?.loadingStatementSeq) {
        alert('출고지시번호가 이미 존재합니다!');
        return;
      }
      if (actionParams?.status !== 'INIT') {
        alert('출고지시대기 상태일 때만 가능합니다!');
        return;
      }
    }

    const paramKeys = Object.keys(actionParams);
    for (let param of paramKeys) {
      items[0][param] = actionParams?.[param];
    }

    setGetCheckedDataTriggerThisGrid({
      type: buttonId,
      id: inventoryModalGridId,
      items,
    });

    e.target.blur();
  };

  return (
    <CommonModalNew
      title="간선출고 할당정보"
      style={{ width: 1400 }}
      subTitle={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>피킹지시번호</strong> : {haldangObj?.item?.linehaulSeq || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>출고지시번호</strong> : {haldangObj?.item?.loadingStatementSeq || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>출발창고</strong> : {haldangObj?.item?.originalCenterCodeKr || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>도착창고</strong> : {haldangObj?.item?.destinationCenterCodeKr || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>진행상태</strong> : {haldangObj?.item?.statusKr || '-'}
        </div>
      }
      visible={haldangObj?.visible}
      setVisible={() => {
        setHaldangObj(null);
      }}
      children={
        <>
          <Form onSubmit={handleSubmitFiltering}>
            <Row>
              <Col>
                <label className="col-form-label">재고번호/시리얼번호</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.INVENTORY_SEQ}
                      value={
                        detailSelectTypeObj?.INVENTORY_SEQ
                          ? {
                              label: selectlabel(detailSelectTypeObj?.INVENTORY_SEQ, masterOptions?.INVENTORY_SEQ),
                              value: detailSelectTypeObj?.INVENTORY_SEQ,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          INVENTORY_SEQ: option.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.INVENTORY_SEQ}
                      value={detailSearchObj?.[detailSelectTypeObj?.INVENTORY_SEQ] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.INVENTORY_SEQ]: convertFieldValue(detailSelectTypeObj['INVENTORY_SEQ'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">주문번호/송장번호</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.ORDER_SEQ}
                      value={
                        detailSelectTypeObj?.ORDER_SEQ
                          ? {
                              label: selectlabel(detailSelectTypeObj?.ORDER_SEQ, masterOptions?.ORDER_SEQ),
                              value: detailSelectTypeObj?.ORDER_SEQ,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          ORDER_SEQ: option.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.ORDER_SEQ}
                      value={detailSearchObj?.[detailSelectTypeObj?.ORDER_SEQ] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.ORDER_SEQ]: convertFieldValue(detailSelectTypeObj['ORDER_SEQ'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">제품/제품명</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.SEARCH_MODEL}
                      value={
                        detailSelectTypeObj?.SEARCH_MODEL
                          ? {
                              label: selectlabel(detailSelectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                              value: detailSelectTypeObj?.SEARCH_MODEL,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          SEARCH_MODEL: option.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.SEARCH_MODEL}
                      value={detailSearchObj?.[detailSelectTypeObj?.SEARCH_MODEL] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.SEARCH_MODEL]: convertFieldValue(detailSelectTypeObj['SEARCH_MODEL'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">판매사</label>
                <div style={{ display: 'flex' }}>
                  <Col style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.SELLER}
                      value={
                        detailSearchObj?.partnerSeq
                          ? {
                              label: selectlabel(detailSearchObj?.partnerSeq, masterOptions?.SELLER),
                              value: detailSearchObj?.partnerSeq,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          partnerSeq: option.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>

              <Col>
                <label className="col-form-label">출고여부</label>
                <div
                  className="radio-select-area"
                  onClick={(e) => {
                    toggleBooleanOptions('loadFlag', e.target.dataset.flagId, detailSearchObj, setDetailSearchObj);
                  }}
                >
                  <span className={detailSearchObj?.loadFlag === true ? 'active' : ''} data-flag-id="true">
                    O
                  </span>
                  <span className={detailSearchObj?.loadFlag === false ? 'active' : ''} data-flag-id="false">
                    X
                  </span>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">주문취소여부</label>
                <div
                  className="radio-select-area"
                  onClick={(e) => {
                    toggleBooleanOptions('orderCancelFlag', e.target.dataset.flagId, detailSearchObj, setDetailSearchObj);
                  }}
                >
                  <span className={detailSearchObj?.orderCancelFlag === true ? 'active' : ''} data-flag-id="true">
                    O
                  </span>
                  <span className={detailSearchObj?.orderCancelFlag === false ? 'active' : ''} data-flag-id="false">
                    X
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col />
              <Col />
              <Col />
              <Col />
              <Col />
              <Col style={{ textAlign: 'right', placeSelf: 'flex-end', display: 'flex', gap: '4px' }}>
                <button type="button" className="btn" style={{ border: '1px solid #DADADA', minWidth: '60px' }} onClick={resetFilters}>
                  초기화
                </button>
                <button type="submit" className="btn btn-search" style={{ flexGrow: 1 }}>
                  조회
                </button>
              </Col>
            </Row>
          </Form>
          <Divider $direction={'H'} $color={'#eaeaea'} $spacing={'LG'} $value={'FULL'} />
          <div className="grid-button-area space-between">
            <div>
              <div className="red" id="trunkShipping-modal-rollback" onClick={handleCheckItems}>
                이전단계
              </div>
              <div className="red" id="trunkShipping-modal-cancel" onClick={handleCheckItems}>
                출고취소
              </div>
              <div className="orange" id="trunkShipping-modal-confirm" onClick={handleCheckItems}>
                출고지시확정
              </div>
              <div className="green" id="trunkShipping-excel-download" onClick={handleCheckItems}>
                엑셀다운
              </div>
            </div>
          </div>
          <GridBoxCommon gridRef={gridRef} columns={columns} getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid} setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid} />
        </>
      }
    />
  );
};
