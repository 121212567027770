import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as IGrid from 'aui-grid';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { Col, Row, Form } from 'reactstrap';
import { setLoading } from 'redux/services/menuSlice';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import { PagingListDTO } from 'interface/util';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import { InvoiceInventoryPurchaseDTO, InvoiceInventoryPurchaseSearchDTO } from 'interface/order';
import { resetAllSelections } from 'common/util/searchBox';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { serviceStore } from 'services/services';

export interface IinvoicesObj {
  visible?: boolean;
  returns?: any;
  first?: boolean;
}

export const InvoicesPopup = ({ invoicesObj, setInvoicesObj }) => {
  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const defaultSearchFilter = useMemo(() => {
    return {
      similaritySearchFlag: reduxUserInfo['similaritySearchFlag'] ?? true, // 유사검색여부
    };
  }, [reduxUserInfo]);
  const gridRef = useRef<IPagingGrid>();
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_SEQ, searchObj));
  }, [selectTypeObj?.SEARCH_SEQ]);
  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  const columns: IGrid.Column[] = [
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
      mergeRef: 'invoiceSeq',
      cellMerge: true,
      mergePolicy: 'restrict',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
      mergeRef: 'invoiceSeq',
      cellMerge: true,
      mergePolicy: 'restrict',
    },
    {
      headerText: '수령인명',
      dataField: 'customerName',
      mergeRef: 'invoiceSeq',
      cellMerge: true,
      mergePolicy: 'restrict',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: 'MTO여부',
      dataField: 'mtoFlagKr',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
  ];

  const returnThese = () => {
    const returns = gridRef.current.getCheckedRowItemsAll();
    returns?.sort((a, b) => a.modelStockSeq - b.modelStockSeq);
    setInvoicesObj((prev) => {
      return {
        ...prev,
        returns,
      };
    });
  };

  const labellingKr = (data: PagingListDTO<InvoiceInventoryPurchaseDTO>) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        mtoFlagKr: row.mtoFlag ? 'O' : 'X',
        partnerSeqKr: selectlabel(row.partnerSeq, masterOptions?.SELLER),
        supplierPartnerSeqKr: selectlabel(row.supplierPartnerSeq, masterOptions?.SUPPLIER),
        modelGroupKr: selectlabel(row.modelGroup, masterOptions?.MODEL_GROUP),
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const fetchPaging = async (searchObj: InvoiceInventoryPurchaseSearchDTO) => {
    const dataDTO = {
      ...searchObj,
    };
    dispatch(setLoading('GET'));
    const dataKr = labellingKr((await serviceStore.orderAction('invoice/inventory/purchase/search', 'POST', null, dataDTO, true))?.data as PagingListDTO<InvoiceInventoryPurchaseDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
      ...defaultSearchFilter,
    },
    gridRef,
    fetchPaging,
  });

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      wrappedFetchList(searchObj);
    }
  };

  return (
    <CommonModalNew
      title={'송장 불러오기'}
      style={{ width: 1200 }}
      visible={invoicesObj?.visible}
      setVisible={() => {
        setInvoicesObj(null);
        setSearchObj(null);
      }}
      children={
        <>
          <Form onKeyPress={onKeyPress}>
            <Row>
              <Col>
                <label className="col-form-label">판매사</label>
                <SelectD
                  isMulti
                  options={masterOptions?.SELLER}
                  value={searchObj?.partnerSeq?.map((ele) => {
                    return {
                      value: ele,
                      label: selectlabel(ele, masterOptions?.SELLER),
                    };
                  })}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      partnerSeq: (option as OptionItem[]).map((ele: OptionItem) => ele.value),
                    });
                  }}
                />
              </Col>
              <Col>
                <label>주문정보</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      value={
                        selectTypeObj?.SEARCH_SEQ
                          ? {
                              value: selectTypeObj?.SEARCH_SEQ,
                              label: masterOptions?.SEARCH_SEQ_OBJ[selectTypeObj?.SEARCH_SEQ],
                            }
                          : null
                      }
                      options={masterOptions?.SEARCH_SEQ}
                      onChange={(option) => {
                        setSelectTypeObj({
                          ...selectTypeObj,
                          SEARCH_SEQ: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      placeholder="다중 입력..."
                      disabled={!selectTypeObj?.SEARCH_SEQ}
                      value={searchObj?.[selectTypeObj?.SEARCH_SEQ] || ''}
                      onChange={(e) => {
                        const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                        if (value?.length > 50) {
                          alert('50개까지 검색가능합니다');
                        }
                        setSearchObj({
                          ...searchObj,
                          [selectTypeObj?.SEARCH_SEQ]: value?.length > 50 ? value?.splice(0, 50) : value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label>제품정보</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      value={
                        selectTypeObj?.SEARCH_MODEL
                          ? {
                              value: selectTypeObj?.SEARCH_MODEL,
                              label: masterOptions?.SEARCH_MODEL_OBJ[selectTypeObj?.SEARCH_MODEL],
                            }
                          : null
                      }
                      options={masterOptions?.SEARCH_MODEL}
                      onChange={(option) => {
                        setSelectTypeObj({
                          ...selectTypeObj,
                          SEARCH_MODEL: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      placeholder="다중 입력..."
                      disabled={!selectTypeObj?.SEARCH_MODEL}
                      value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                      onChange={(e) => {
                        const value = e.target.value?.includes(' ') ? e.target.value?.split(' ') : e.target.value?.includes(',') ? e.target.value?.split(',') : [e.target.value];
                        if (value?.length > 50) {
                          alert('50개까지 검색가능합니다');
                        }
                        setSearchObj({
                          ...searchObj,
                          [selectTypeObj?.SEARCH_MODEL]: value?.length > 50 ? value?.splice(0, 50) : value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">유사검색여부</label>
                <div className="radio-select-area">
                  <span
                    className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                    onClick={() => {
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          similaritySearchFlag: true,
                        };
                      });
                    }}
                  >
                    유사검색
                  </span>
                  <span
                    className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                    onClick={() => {
                      setSearchObj((prev) => {
                        return {
                          ...prev,
                          similaritySearchFlag: false,
                        };
                      });
                    }}
                  >
                    일치검색
                  </span>
                </div>
              </Col>
              <Col>
                <div style={{ paddingTop: 10, textAlign: 'left' }}>
                  <button
                    className="btn btn-search"
                    onClick={(e) => {
                      console.log('search');
                      e.preventDefault();
                      wrappedFetchList(searchObj);
                    }}
                  >
                    조회
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
          <div className="presenterGridBox">
            <div className="grid-button-area only-right">
              <div
                className="blue"
                onClick={(e) => {
                  //
                  e.preventDefault();
                  returnThese();
                }}
              >
                +추가
              </div>
            </div>
            <GridBox
              gridRef={gridRef}
              columns={columns}
              gridProps={{
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
                fixedColumnCount: 3,
                enableCellMerge: true,
                cellMergePolicy: 'withNull',
                editable: true,
              }}
            />
          </div>
        </>
      }
    />
  );
};
