import CommonModalNew from 'components/modal/commonModalNew';
import { useEffect, useRef, useState } from 'react';
import { serviceStore } from 'services/services';
import { AccessUserDetailDTO } from 'interface/user';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import GridBox from 'common/grid/gridBox';
import AUIGrid from 'modules/Grid';
import { Col, Row } from 'reactstrap';

import * as IGrid from 'aui-grid';

type ITabId =
  | 'MANAGERLD_USER'
  | 'MANAGE_USERGROUP_SCREEN'
  | 'MANAGE_USERGROUP_PARTNERS'
  | 'MANAGE_USERGROUP_INFRA'
  | 'MANAGE_USERGROUP_CENTERS'
  | 'MANAGE_USERGROUP_DIFF'
  | 'MANAGE_USERGROUP_SALESCHANNEL';

export interface IAuthDetailObj {
  visible: boolean;
  tabId: ITabId; //
  searchObj?: any;
  fetchFn: (searchObj) => {};
}

const translatorAccessSeq = ['accessFrontSeq', 'accessPartnerSeq', 'accessInfraSeq', 'accessCenterSeq', 'accessDiffCenterSeq', 'accessSalesChannelSeq'];
const translatorAccess = ['', 'accessFronts', 'accessPartners', 'accessInfras', 'accessCenters', 'accessDiffCenters', 'accessSalesChannels'];
const accessOptions = ['front', 'partner', 'infra', 'center', 'diff/center', 'salesChannel'];

export const AuthGroupUser = ({ authDetailObj, setAuthDetailObj }) => {
  const gridRef = useRef<AUIGrid>();
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [accessDatas, setAccessDatas] = useState<AccessUserDetailDTO>();
  const [detailOptions, setDetailOptions] = useState<any[]>();
  const columns: IGrid.Column[] = [];

  const fetchDetail = async (tabId: ITabId) => {
    dispatch(setLoading('GET'));
    // option fetch
    const data = (await serviceStore?.userAction(`access/user/detail?userId=${authDetailObj?.item?.userId}`, 'GET', null))?.data;
    const options = (await Promise.all(accessOptions?.map((ele) => ele && serviceStore.userAction(`access/${ele}/select`, 'GET', { groupSeq: data?.groupSeq }))))?.map((ele) => ele.data);
    setDetailOptions(options);
    setAccessDatas(data);
    dispatch(setLoading(null));
  };
  useEffect(() => {
    fetchDetail(authDetailObj?.tabId);
  }, []);

  const validator = (accessDatas) => {
    if (!accessDatas?.userCode) {
      alert('사용자 유형은 필수값입니다!');
      return false;
    }
    if (!accessDatas?.groupSeq) {
      alert('소속 조직은 필수값입니다!');
      return false;
    }
    if (!accessDatas?.partnerSeq) {
      alert('소속 파트너는 필수값입니다!');
      return false;
    }
    return true;
  };

  const saveDetail = async () => {
    console.log(accessDatas);
    if (window.confirm('저장하시겠습니까?')) {
      if (validator(accessDatas)) {
        const rs = await serviceStore.userAction(`access/user/save`, 'POST', null, accessDatas);
        if (rs?.status === 200) {
          alert('저장되었습니다!');
          authDetailObj?.fetchFn(authDetailObj?.searchObj);
        }
      }
    }
  };

  const handleCheckItems = (e) => {
    const id = e.target.id;
    const items = gridRef.current.getCheckedRowItems();
    if (items?.length > 0) {
      const wholeRows = gridRef.current.getGridData();
      if (id === 'DELROW') {
        const checked = items.map((ele) => ele.rowIndex);
        const checkedRow = items?.map((ele) => ele.item);
        const rows = wholeRows?.filter((_, idx) => !checked?.includes(idx));
        saveNewRowsToAccessData(rows, checkedRow[0]?.key);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  const [currentTab, setCurrentTab] = useState(0);
  const hasTabs = [{ title: '기본정보' }, { title: '화면 권한' }, { title: '판매사 권한' }, { title: '인프라 권한' }, { title: '창고 권한' }, { title: '타창고 권한' }, { title: '판매채널 권한' }];

  const handleCurrentTab = (e) => {
    const id = Number(e.target.id?.split('_')[1]);
    if (id || currentTab) {
      if (currentTab) {
        const acessDataToSave = {
          ...accessDatas,
          [translatorAccess[currentTab]]: gridRef?.current?.getGridData(),
        };
        setAccessDatas(acessDataToSave);
      }

      gridRef?.current?.setGridData(
        accessDatas[translatorAccess[id]]?.map((ele) => {
          return {
            ...ele,
            key: translatorAccessSeq[id - 1],
            option: JSON.stringify(detailOptions[id - 1] || []),
          };
        }),
      );

      const columns: IGrid.Column[] = [
        {
          headerText: '권한 그룹명',
          dataField: 'title',
          editable: true,
          editRenderer: {
            type: IGrid.RendererKind.DropDownListRenderer,
            // list: detailOptions?.[id - 1] || [],
            listFunction: (rowIndex: number, columnIndex: number, item: any, dataField: string) => {
              const seqs = (gridRef?.current?.getGridData() || [])?.map((ele) => ele?.[translatorAccessSeq[id - 1]]);
              return detailOptions?.[id - 1]?.filter((ele) => !seqs?.includes(ele?.[translatorAccessSeq[id - 1]]));
            },
            keyField: translatorAccessSeq?.[id - 1],
            valueField: 'title',
          },
        },
        {
          headerText: '적용된 권한',
          dataField: 'preview',
        },
        {
          headerText: '설명',
          dataField: 'description',
          width: 500,
        },
      ];

      gridRef?.current?.changeColumnLayout(columns);
    }
    setCurrentTab(id);
  };

  const saveNewRowsToAccessData = (rows = [], key) => {
    const tab = translatorAccessSeq?.indexOf(key) + 1;
    const acessDataToSave = {
      ...accessDatas,
      [translatorAccess[tab]]: rows,
    };
    gridRef?.current?.setGridData(rows);
    setAccessDatas(acessDataToSave);
  };

  const setGroupDetail = (e: IGrid.CellEditEndEvent) => {
    switch (e.type) {
      case 'cellEditEnd':
        if (e.dataField == 'title') {
          const option = e.item.option ? JSON.parse(e.item.option) : [];
          const newItem = option?.filter((ele) => ele?.[e.item.key] + '' === e.value)[0];
          if (typeof newItem === 'undefined') {
            return;
          }
          gridRef?.current?.updateRow(newItem, e.rowIndex);
          const rows = gridRef?.current?.getGridData();
          saveNewRowsToAccessData(rows, e.item?.key);
        }
        break;
    }
  };

  const bindFunctions = () => {
    gridRef?.current?.bind('cellEditEnd', function (e) {
      setGroupDetail(e);
    });
  };

  useEffect(() => {
    if (accessDatas) {
      bindFunctions();
    }
  }, [accessDatas, detailOptions]);

  const resetPassword = async () => {
    if (window.confirm('비밀번호 초기화 진행하시겠습니까?\n "123456789a"로 초기화됩니다')) {
      const rs = await serviceStore.userAction('password/admin', 'POST', null, {
        userIds: [authDetailObj?.item?.userId],
        resetPassword: '123456789a',
      });
      if (rs?.status === 200) {
        alert('초기화되었습니다');
      }
    }
  };

  return (
    <CommonModalNew
      style={{ width: 1000 }}
      visible={authDetailObj?.visible}
      setVisible={() => {
        setAuthDetailObj(null);
      }}
      rightTitle={
        <>
          {authDetailObj?.item && (
            <button
              className={'btn btn-secondary'}
              onClick={() => {
                resetPassword();
              }}
            >
              비밀번호 초기화
            </button>
          )}
          <button
            className="btn btn-secondary ssm"
            onClick={() => {
              saveDetail();
            }}
          >
            저장
          </button>
        </>
      }
      title={'조직원 상세보기'}
      children={
        <div style={{ minHeight: 600 }}>
          <div className="div-top-tab">
            {hasTabs.map((tab, index) => {
              return (
                <div key={`tabTitle-${index}`} className={currentTab === index ? 'bordering-current' : 'bordering'}>
                  <li id={`tab_${index}`} onClick={handleCurrentTab}>
                    {tab?.title}
                  </li>
                </div>
              );
            })}
          </div>

          <div style={{ flexDirection: 'column', zIndex: currentTab === 0 ? 13 : 10, height: 545 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 270, display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{
                    backgroundColor: '#eff2f7',
                    border: '1px solid #e0e0e0',
                    marginTop: 20,
                    width: 150,
                    height: 150,
                    borderRadius: 150,
                    position: 'absolute',
                  }}
                ></div>
              </div>
              <div style={{ width: 645 }}>
                <Row>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                  <Col>
                    <label className="col-form-label">사용자 상태값</label>
                    <div className="radio-select-area">
                      {MASTER_OPS?.USE_FLAG?.map((flag) => {
                        return (
                          <span
                            className={(accessDatas?.useFlag ? accessDatas?.useFlag + '' : 'false') === flag?.value ? 'active' : ''}
                            onClick={() => {
                              setAccessDatas((prev) => {
                                return {
                                  ...prev,
                                  userCode: flag?.value,
                                };
                              });
                            }}
                          >
                            {flag?.label}
                          </span>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="col-form-label">*이름</label>
                    <InputD readOnly disabled value={accessDatas?.userName || ''} />
                  </Col>
                  <Col>
                    <label className="col-form-label">*아이디</label>
                    <InputD readOnly disabled value={accessDatas?.userId || ''} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="col-form-label">*사용자 유형</label>
                    <SelectD
                      hasNull={false}
                      options={MASTER_OPS?.USER_CODE}
                      value={
                        accessDatas?.userCode
                          ? {
                              value: accessDatas?.userCode,
                              label: MASTER_OBJ?.['USER_CODE']?.[accessDatas?.userCode],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setAccessDatas((prev) => {
                          return {
                            ...prev,
                            userCode: (option as OptionItem)?.value,
                          };
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label className="col-form-label">*소속 조직</label>
                    <SelectD
                      isDisabled
                      options={MASTER_OPS?.JOJIK_GROUP}
                      value={
                        accessDatas?.groupSeq
                          ? {
                              value: accessDatas?.groupSeq,
                              label: MASTER_OBJ?.['JOJIK_GROUP']?.[accessDatas?.groupSeq],
                            }
                          : null
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label className="col-form-label">*소속 파트너</label>
                    <SelectD
                      hasNull={false}
                      options={MASTER_OPS?.SELLER_SELLER_AUTH}
                      value={
                        accessDatas?.partnerSeq
                          ? {
                              value: accessDatas?.partnerSeq,
                              label: MASTER_OBJ?.['SELLER_SELLER_AUTH']?.[accessDatas?.partnerSeq],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setAccessDatas({
                          ...accessDatas,
                          partnerSeq: (option as OptionItem)?.value,
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <label className="col-form-label">소속 창고</label>
                    <SelectD
                      hasNull={false}
                      options={MASTER_OPS?.CENTER_AUTH}
                      value={
                        accessDatas?.centerCode
                          ? {
                              value: accessDatas?.centerCode,
                              label: MASTER_OBJ?.['CENTER_AUTH']?.[accessDatas?.centerCode],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setAccessDatas({
                          ...accessDatas,
                          centerCode: (option as OptionItem)?.value,
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="col-form-label">*연락처</label>
                    <InputD disabled readOnly value={accessDatas?.phoneNumber || ''} />
                  </Col>
                  <Col>
                    <label className="col-form-label">이메일</label>
                    <InputD disabled readOnly value={accessDatas?.email || ''} />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div style={{ flexDirection: 'column', zIndex: currentTab !== 0 ? 13 : 10, paddingTop: 0 }}>
            <div className="grid-button-area">
              {detailOptions?.[currentTab - 1]?.length > 0 ? (
                <>
                  <div
                    className="blue"
                    onClick={() => {
                      if (gridRef?.current?.getGridData()?.length < detailOptions?.[currentTab - 1]?.length) {
                        gridRef?.current?.addRow({
                          key: translatorAccessSeq[currentTab - 1],
                          option: JSON.stringify(detailOptions[currentTab - 1] || []),
                        });
                      } else {
                        alert('부여된 권한 그룹명 수량을 초과하여 추가하실 수 없습니다.');
                      }
                    }}
                  >
                    행 추가
                  </div>
                  <div className="red" id="DELROW" onClick={handleCheckItems}>
                    행 삭제
                  </div>
                </>
              ) : (
                <div className="transparent">추가 가능한 권한 그룹이 없습니다!</div>
              )}
            </div>
            <GridBox
              gridRef={gridRef}
              columns={columns}
              gridProps={{
                editable: true,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
            />
          </div>
        </div>
      }
    />
  );
};
