import { useEffect, useState } from 'react';
import { Table, Button, Col } from 'reactstrap';

import Select from 'react-select';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { httpClient } from 'common/http-client/axiosConfig';

import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { ORDER_DETAIL_SAVE } from 'envVar';

// redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RAWDATA, setLoading } from 'redux/services/menuSlice';
import { RootState } from 'redux/store';

// component
import {
  InvoiceDetailDTO,
  InvoiceDetailListDTO,
  InvoiceInventoryDTO,
  LadderTruckInvoiceDetailDTO,
  LiftingInvoiceDetailDTO,
  OrderListDTO,
  OrderSearchDTO,
  ScrapInvoiceDetailDTO,
} from '../../../_interface/order';
import { orderService } from 'pages/ORDER/1order/_services/service';
import { numberThousandComma, stringCommaToInt } from 'common/util/counting';
import ProductStockModal from './productStockModal';
import ModelStockModal, { IPopupGridObj } from './modelStockModal';
import CommonModalNew from 'components/modal/commonModalNew';
import { HappyCallModal, IhappycallObj } from './happycall';
import { History } from './history';
import { CallCenterModal } from './callcenterModal';
import { TimeStampModal } from './timeStamp';
import { IinstallModalObj, InstallationModal } from './installationModal';

import * as IGrid from 'aui-grid';
import { stringValueToNumber } from 'common/util/regExp';
import OrderPhoto from '../photos/PhotoPopUpForDetail';
export const defaultOrderDetailObj = {
  visible: false,
  item: null,
  fetchFn: null,
};

const defaultLadder = {
  company: null,
  companyName: null,
  carNumber: null,
  driverName: null,
  floor: null,
  duration: null,
  ladderNote: null,
};

const defaultLifting = {
  floor: null,
  liftingNote: null,
};

export interface IOrderDetailObj {
  visible?: boolean;
  item?: OrderListDTO;
  searchObj?: OrderSearchDTO;
  e?: IGrid.ButtonClickEvent;
  fetchFn?: (searchDTO: OrderSearchDTO) => void;
  fetchDetailFn?: (e: IGrid.ButtonClickEvent) => void;
}

export const OrderDetail = ({ orderDetailObj, setOrderDetailObj }) => {
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);

  const [orderDetailInfo, setOrderDetailInfo] = useState<InvoiceDetailDTO>();
  const [invoiceList, setInvoiceList] = useState<InvoiceDetailListDTO[]>();

  useEffect(() => {
    if (orderDetailObj?.item?.orderSeq) {
      initOrders(orderDetailObj?.item?.orderSeq);
    }
  }, [orderDetailObj]);

  const initOrders = async (orderSeq: number) => {
    const invoiceList = await orderService.getInvoiceList(orderSeq);
    fetchInvoiceDetail(orderDetailObj?.item?.invoiceSeq);
    setInvoiceList(invoiceList);
  };

  const fetchInvoiceDetail = async (invoiceSeq: number) => {
    const orderDetailInfo = await orderService.getInvoiceDetail(invoiceSeq);
    setOrderDetailInfo(orderDetailInfo);
  };

  return (
    <>
      <CommonModalNew
        visible={orderDetailObj?.visible}
        setVisible={() => {
          setOrderDetailObj(defaultOrderDetailObj);
        }}
        title={
          <>
            {invoiceList?.map((invoice, idx) => {
              return (
                <Button
                  key={`invoiceSeqList_inModal_${idx}`}
                  onClick={() => fetchInvoiceDetail(invoice.invoiceSeq)}
                  className={orderDetailInfo?.invoiceSeq === invoice.invoiceSeq ? 'btn btn-secondary' : 'btn btn-etc'}
                  style={{ minWidth: 170 }}
                >
                  {invoice.invoiceSeq}({MASTER_OBJ?.FEE_TYPE?.[invoice?.feeType]})
                </Button>
              );
            })}
          </>
        }
        style={{ width: 1500 }}
        children={
          <div style={{ display: 'relative' }}>
            {orderDetailInfo && (
              <OrderInfoView //
                orderDetailObj={orderDetailObj}
                setOrderDetailObj={setOrderDetailObj}
                orderDetailInfo={orderDetailInfo}
                initOrders={initOrders}
              />
            )}
          </div>
        }
      />
    </>
  );
};

const OrderInfoView = (props) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { orderDetailInfo, initOrders, orderDetailObj } = props;

  const [invoiceData, setInvoiceData] = useState<InvoiceDetailDTO>();
  const [invoiceInventories, setInvoiceInventories] = useState<InvoiceInventoryDTO[][]>();
  const [inventoryIdx, setInventoryIdx] = useState(0);

  const [orderInfoScraps, setOrderInfoScraps] = useState<ScrapInvoiceDetailDTO[]>();
  const [companyNameTmpHandle, setcompanyNameTmpHandle] = useState<string>();

  const [disabledFlag, setDisabledFlag] = useState(true);
  const [visibleModal, setVisibleModal] = useState({
    type: null,
    visible: false,
  });

  const [filteredOptions, setFilteredOptions] = useState<OptionItem[]>();

  useEffect(() => {
    setDisabledFlag(true);
    //
    setInvoiceData(orderDetailInfo);
    setInvoiceInventories(orderDetailInfo?.invoiceInventories);
    setOrderInfoScraps(orderDetailInfo?.scraps);

    if (orderDetailInfo) {
      if (orderDetailInfo?.ladderTruck) {
        const truckInfo = { ...orderDetailInfo?.ladderTruck };
        if (!truckInfo?.company) setcompanyNameTmpHandle(truckInfo?.companyName);
        setLadderObj(truckInfo);
      }

      if (orderDetailInfo?.lifting) {
        const liftingInfo = { ...orderDetailInfo?.lifting };
        setLiftingObj(liftingInfo);
      }
      if (orderDetailInfo?.orderType) {
        if (orderDetailInfo?.liteFlag) {
          setFilteredOptions(filterFeeTypeLite(orderDetailInfo?.orderType));
        } else {
          setFilteredOptions(filterFeeType(orderDetailInfo?.orderType));
        }
      }
      fetchInfraNCenterOptions();
    }
  }, [orderDetailInfo]);

  const fetchInfraNCenterOptions = async (modelGroup = null) => {
    let url = `/warehouse/infra/sub/detail?partnerSeq=${orderDetailInfo?.partnerSeq}`;
    if (orderDetailInfo?.modelGroup) url += `&modelGroup=${modelGroup ? modelGroup : orderDetailInfo?.modelGroup}`;
    const { data } = await httpClient.get(url);
    const { INFRA, CENTER } = filterWithRawDataLabeled(data?.details, orderDetailInfo?.centerCode, orderDetailInfo?.infraSeq);
    setInfraNCenterOptions({
      RAW_DATA: data?.details,
      INFRA,
      CENTER,
    });
  };

  const filterFeeType = (orderType: string) => {
    if (orderType === 'RECORD') {
      return MASTER_OPS?.FEE_TYPE;
    } else {
      let arr = [];
      MASTER_OPS?.[`${RAWDATA}ORDER_TYPE`]?.forEach((ele) => {
        if (ele?.code === orderType) {
          arr = ele.subCodes;
        }
      });
      return arr?.map((ele) => {
        return {
          value: ele.code,
          label: ele.codeName,
        };
      });
    }
  };

  const filterFeeTypeLite = (orderTypeKr) => {
    if (orderTypeKr === '회수') {
      return [{ label: '완료회수', value: 'ENDC' }];
    } else if (orderTypeKr === '배송') {
      return [
        { label: '배송설치', value: 'INSTA' },
        { label: '단순배송', value: 'DELIV' },
      ];
    } else if (orderTypeKr === '교환') {
      return [
        { value: 'EXINSTA', label: '교환설치(동일)' },
        { value: 'DEXINSTA', label: '교환설치(상이)' },
        { value: 'EXCHAOUT', label: '교환회수(동일)' },
        { value: 'DEXCHAOUT', label: '교환회수(상이)' },
      ];
    }
  };

  const [ladderObj, setLadderObj] = useState<LadderTruckInvoiceDetailDTO>();
  const [liftingObj, setLiftingObj] = useState<LiftingInvoiceDetailDTO>();

  const [productStockObj, setProductStockObj] = useState<IPopupGridObj>();
  const [modelStockObj, setModelStockObj] = useState<IPopupGridObj>();

  useEffect(() => {
    if (modelStockObj?.returns) setModelStockObj(null);
    if (productStockObj?.returns) setProductStockObj(null);
  }, [invoiceInventories]);

  useEffect(() => {
    if (modelStockObj?.returns) {
      const _invoiceInventories = JSON.parse(JSON.stringify(invoiceInventories));
      const groups = _invoiceInventories?.map((group) => {
        const concated = group?.concat(modelStockObj?.returns)?.map((row) => {
          return {
            ...row,
            invoiceInventorySeq: null,
          };
        });
        return concated;
      });
      setInvoiceInventories(groups);
      setInvoiceData((prev) => {
        return {
          ...prev,
          productSeq: null,
          productName: null,
        };
      });
    }
  }, [modelStockObj?.returns]);

  useEffect(() => {
    if (productStockObj?.returns) {
      if (window.confirm('상품추가시에 상품목록이 초기화됩니다 진행하시겠습니까?')) {
        productToInventories(productStockObj?.returns[0]);
        setInventoryIdx(0);
      }
    }
  }, [productStockObj?.returns]);

  const [driverInCenterOption, setDriverInCenterOption] = useState<OptionItem[]>();
  useEffect(() => {
    if (invoiceData?.centerCode) {
      const filtered = MASTER_OPS?.RAWDATA_DRIVER_WHOLE?.filter((ele) => ele.centerCode === invoiceData?.centerCode) || [];
      setDriverInCenterOption(
        filtered?.map((ele) => {
          return {
            value: ele.userId,
            label: ele.driverName,
          };
        }),
      );
    }
  }, [invoiceData?.centerCode]);

  const productToInventories = async (item) => {
    const products = await httpClient.get(`/order/product/model/individual?productSeq=${item.productSeq}`).then((rs) => {
      if (rs.status === 200) {
        return rs.data;
      }
      return null;
    });
    if (products) {
      setInvoiceData((prev) => {
        return {
          ...prev,
          productSeq: item.productSeq,
          productName: item.productName,
          modelGroup: item.modelGroup,
          installDuration: item.installDuration,
          memberCount: item.memberCount,
        };
      });
      setInvoiceInventories([products]);
      alert('변경되었습니다!');
    }
  };

  const postDetail = async () => {
    let dat = { ...invoiceData };
    let flag = true;
    if (dat?.invoiceStatus?.includes('FINISH') && (ladderObj || liftingObj)) {
      // if (ladderOrLiftValidatorAndBinding(dat)) {
      dat['ladderTruck'] = ladderObj;
      dat['lifting'] = liftingObj;
      // } else {
      //   flag = false;
      // }
    } else {
      if (dat?.ladderTruck) dat.ladderTruck = null;
      if (dat?.lifting) dat.lifting = null;
    }

    if (orderInfoScraps?.length > 0) {
      // if (scrapsValidator(orderInfoScraps)) {
      dat['scraps'] = orderInfoScraps;
      // } else {
      //   flag = false;
      // }
    } else {
      dat['scraps'] = [];
    }

    if (!dat?.infraSeq) {
      alert('인프라는 필수값입니다!');
      flag = false;
    }
    if (dat?.invoiceStatus === 'ADDRESS_ERROR' && !dat?.centerCode) {
      alert('소속창고는 필수값입니다!');
      flag = false;
    }
    if (!dat?.salesChannelSeq) {
      alert('판매채널은 필수값입니다!');
      flag = false;
    }

    // 상품저장
    if (invoiceInventories?.length > 0) {
      invoiceInventories?.forEach((row) => {
        row?.forEach((ele) => {
          ele.sellingPrice = stringCommaToInt(ele.sellingPrice || 0);
          ele.primePrice = stringCommaToInt(ele.primePrice || 0);
          ele.giftFlag = ele.giftFlag ? true : false;
        });
      });
      dat.invoiceInventories = invoiceInventories;
    }
    if (flag) {
      dispatch(setLoading('POST'));
      const rs = await httpClient.post(ORDER_DETAIL_SAVE, dat);
      if (rs?.status === 200) {
        alert('수정이 완료 되었습니다');
        initOrders(dat?.orderSeq);
        if (orderDetailObj?.fetchFn && orderDetailObj?.searchObj) orderDetailObj?.fetchFn(orderDetailObj?.searchObj);
      }
      dispatch(setLoading(null));
    }
  };

  const handleDaumPost = (type) => {
    new (window as any).daum.Postcode({
      oncomplete: function (data) {
        const { zonecode, roadAddress, jibunAddress } = data;

        setInvoiceData({
          ...invoiceData,
          receiverZipcode: zonecode,
          receiverAddr1: data?.userSelectedType === 'R' ? roadAddress : jibunAddress,
        });
      },
    }).open();
  };

  const MessageCancelOrFinishforBody = async (invoiceSeq) => {
    dispatch(setLoading('POST'));
    await httpClient.post(`/order/body/send/finish/cancel?invoiceSeq=${invoiceSeq}`).then((rs) => {
      if (rs?.status === 200) {
        alert('완료값이 전송되었습니다!');
      }
    });
    dispatch(setLoading(null));
  };

  const [infraNcenterOptions, setInfraNCenterOptions] = useState<{ [key: string]: any[] }>();

  const filterWithRawDataLabeled = (RAW_DATA, centerCode, infraSeq) => {
    const INFRA = [];
    const CENTER = [];
    const INFRA_DATA = centerCode ? RAW_DATA?.filter((ele) => ele.centerCode === centerCode) : RAW_DATA;
    const CENTER_DATA = infraSeq ? RAW_DATA?.filter((ele) => ele.infraSeq === infraSeq) : RAW_DATA;
    INFRA_DATA?.forEach((ele) => {
      if (!INFRA?.includes(ele.infraSeq)) INFRA?.push(ele.infraSeq);
    });
    CENTER_DATA?.forEach((ele) => {
      if (!CENTER?.includes(ele.centerCode)) CENTER?.push(ele.centerCode);
    });
    return {
      INFRA: INFRA?.map((ele) => {
        return {
          value: ele,
          label: MASTER_OBJ?.INFRA?.[ele],
        };
      }),
      CENTER: CENTER?.map((ele) => {
        return { value: ele, label: MASTER_OBJ?.CENTER_WHOLE?.[ele] };
      }),
    };
  };

  const filterInfraNCenterOptions = (centerCode, infraSeq) => {
    const { INFRA, CENTER } = filterWithRawDataLabeled(infraNcenterOptions?.RAW_DATA, centerCode, infraSeq);
    setInfraNCenterOptions({
      ...infraNcenterOptions,
      INFRA,
      CENTER,
    });
  };

  const [installModalObj, setInstallModalObj] = useState<IinstallModalObj>();
  const [happycallObj, setHappycallObj] = useState<IhappycallObj>();
  const [yongRyangOps, setYongryangOps] = useState<OptionItem[]>();
  return (
    <>
      {productStockObj?.visible && <ProductStockModal productStockObj={productStockObj} setProductStockObj={setProductStockObj} />}
      {modelStockObj?.visible && <ModelStockModal modelStockObj={modelStockObj} setModelStockObj={setModelStockObj} />}
      {visibleModal?.type === 'history' && (
        <History
          style={{ maxWidth: 800 }}
          visible={visibleModal?.visible}
          setVisible={() => {
            setVisibleModal((prev) => {
              return {
                ...prev,
                visible: !visibleModal?.visible,
              };
            });
          }}
          invoiceDetail={invoiceData}
        />
      )}
      {happycallObj?.visible && <HappyCallModal happycallObj={happycallObj} setHappycallObj={setHappycallObj} />}
      {visibleModal?.type === 'call-center' && (
        <CallCenterModal
          visible={visibleModal?.visible}
          setVisible={() => {
            setVisibleModal((prev) => {
              return {
                ...prev,
                visible: !visibleModal?.visible,
              };
            });
          }}
          invoiceDetail={invoiceData}
        />
      )}
      {visibleModal?.type === 'time-stamp' && (
        <TimeStampModal
          visible={visibleModal?.visible}
          setVisible={() => {
            setVisibleModal((prev) => {
              return {
                ...prev,
                visible: !visibleModal?.visible,
              };
            });
          }}
          invoiceDetail={invoiceData}
        />
      )}

      {visibleModal?.type === 'photo' && (
        <OrderPhoto
          visible={visibleModal?.visible}
          setVisible={() => {
            setVisibleModal((prev) => {
              return {
                ...prev,
                visible: !visibleModal?.visible,
              };
            });
          }}
          invoiceDetail={invoiceData}
        />
      )}

      {installModalObj?.visible && <InstallationModal installModalObj={installModalObj} setInstallModalObj={setInstallModalObj} />}
      <div style={{ float: 'right' }}>
        {invoiceData?.partnerSeq === 46 && (invoiceData?.invoiceStatus?.includes('CANCEL') || invoiceData?.invoiceStatus?.includes('FINISH')) && (
          <Button
            className="btn-reload btn"
            onDoubleClick={() => {
              return;
            }}
            onClick={() => {
              if (window.confirm('완료값을 전송하시겠습니까?')) MessageCancelOrFinishforBody(invoiceData?.invoiceSeq);
            }}
          >
            완료값 전송
          </Button>
        )}
        <Button
          className="btn-reload btn"
          onClick={() => {
            setVisibleModal({
              type: 'history',
              visible: true,
            });
          }}
        >
          변경이력조회
        </Button>
        <Button
          className="btn-reload btn"
          onClick={() => {
            setVisibleModal({
              type: 'call-center',
              visible: true,
            });
          }}
        >
          고객 상담
        </Button>
        <Button
          className="btn-reload btn"
          onClick={() => {
            setVisibleModal({
              type: 'time-stamp',
              visible: true,
            });
          }}
        >
          타임스탬프
        </Button>
        <Button
          className="btn-reload btn"
          onClick={() => {
            if (invoiceData?.invoiceStatus?.includes('FINISH')) {
              setVisibleModal({
                type: 'photo',
                visible: true,
              });
            } else {
              alert('완료된 건만 사진을 확인하실 수 있습니다!');
            }
          }}
        >
          사진보기
        </Button>
        <Button
          className="btn-reload btn"
          onClick={() => {
            setInstallModalObj({
              visible: true,
              item: orderDetailInfo,
            });
          }}
        >
          설치확인서
        </Button>
        <Button
          className="btn-reload btn"
          onClick={() => {
            setHappycallObj({
              visible: true,
              invoiceDetail: orderDetailInfo,
            });
          }}
        >
          해피콜
        </Button>
        <Button
          className={'btn btn-secondary'}
          onClick={() => {
            if (disabledFlag) {
              setDisabledFlag(false);
            } else {
              postDetail();
            }
          }}
        >
          {disabledFlag ? '수정하기' : '저장하기'}
        </Button>
      </div>
      {invoiceData && (
        <>
          <div>
            <div className="inputSmaillTitle mb-2" style={{ marginTop: 0 }}>
              <span style={{ fontSize: 16, fontWeight: 500 }}>주문정보</span>
              <span style={{ fontSize: 13, margin: '0 10px' }}>주문번호 : {invoiceData.orderSeq}</span>
              <span style={{ fontSize: 13, fontWeight: 600, color: invoiceData?.liteFlag ? '#1183a6' : 'black' }}>{invoiceData?.liteFlag ? '라이트' : '일반'}</span>
            </div>
            <table className="border-table">
              <tbody>
                <tr>
                  <th>*송장번호</th>
                  <td colSpan={2}>
                    <InputD readOnly value={invoiceData?.invoiceSeq || ''} disabled />
                  </td>
                  <th>
                    *외부
                    <br />
                    주문번호
                  </th>
                  <td colSpan={2}>
                    <InputD readOnly value={invoiceData?.externalOrderNumber || ''} disabled />
                  </td>
                  <th>
                    *외부
                    <br />
                    송장번호
                  </th>
                  <td colSpan={2}>
                    <InputD
                      value={invoiceData?.externalInvoiceNumber || ''}
                      disabled={disabledFlag || invoiceData.orderType !== 'PARCEL'}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          externalInvoiceNumber: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <th>*주문유형</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={true}
                      options={MASTER_OPS?.['ORDER_TYPE']}
                      value={
                        invoiceData?.orderType
                          ? {
                              value: invoiceData?.orderType,
                              label: MASTER_OBJ?.ORDER_TYPE?.[invoiceData?.orderType],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setInvoiceData({
                          ...invoiceData,
                          orderType: (option as OptionItem).value,
                        });
                      }}
                    />
                  </td>
                  <th>*송장유형</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={disabledFlag || invoiceData?.invoiceStatus?.includes('FINISH') || invoiceData?.invoiceStatus === 'DELIVERY'}
                      options={filteredOptions}
                      value={
                        invoiceData?.feeType
                          ? {
                              value: invoiceData?.feeType,
                              label: MASTER_OBJ?.FEE_TYPE?.[invoiceData?.feeType],
                            }
                          : null
                      }
                      onChange={({ value }) => {
                        const FEE_TYPE = filterFeeType(value);
                        setFilteredOptions(FEE_TYPE);
                        setInvoiceData({
                          ...invoiceData,
                          feeType: value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>주문일</th>
                  <td colSpan={2}>
                    <FlatpickrD
                      disabled={disabledFlag || invoiceData?.invoiceStatus?.includes('FINISH') || invoiceData?.invoiceStatus?.includes('CANCEL')}
                      value={invoiceData?.orderDate}
                      onReset={() => {
                        setInvoiceData({
                          ...invoiceData,
                          orderDate: null,
                        });
                      }}
                      onChange={(date) => {
                        setInvoiceData({
                          ...invoiceData,
                          orderDate: returnDateyyyymmdd(date),
                        });
                      }}
                      options={{
                        mode: 'single',
                      }}
                    />
                  </td>
                  <th>희망일</th>
                  <td colSpan={2} style={{ position: 'relative' }}>
                    <FlatpickrD
                      disabled={disabledFlag || invoiceData?.invoiceStatus?.includes('FINISH') || invoiceData?.invoiceStatus?.includes('CANCEL')}
                      value={invoiceData?.promiseDeliveryDate}
                      onChange={(date) => {
                        setInvoiceData({
                          ...invoiceData,
                          promiseDeliveryDate: returnDateyyyymmdd(date),
                        });
                      }}
                      onReset={() => {
                        setInvoiceData({
                          ...invoiceData,
                          promiseDeliveryDate: null,
                        });
                      }}
                      options={{
                        mode: 'single',
                      }}
                    />
                  </td>
                  <th>
                    서비스
                    <br />
                    개월
                  </th>
                  <td colSpan={2}>
                    <InputD
                      disabled={disabledFlag}
                      type="number"
                      value={invoiceData?.warrantyMonth || ''}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          warrantyMonth: Number(e.target.value),
                        });
                      }}
                    />
                  </td>
                  <th>추천인명</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={disabledFlag}
                      value={invoiceData?.recommenderName || ''}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          recommenderName: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <th>
                    추천인
                    <br />
                    고유번호
                  </th>
                  <td colSpan={2}>
                    <InputD
                      disabled={disabledFlag}
                      value={invoiceData?.recommenderNumber || ''}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          recommenderNumber: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>*주문자명</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={disabledFlag}
                      value={invoiceData?.customerName || ''}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          customerName: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <th>*연락처1</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={disabledFlag}
                      value={invoiceData?.customerPhone || ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                          let check = /^[0-9\-\b]+$/;
                          if (!check.test(value)) {
                            alert('숫자와 "-"만 입력 가능합니다.');
                          } else {
                            setInvoiceData({
                              ...invoiceData,
                              customerPhone: value,
                            });
                          }
                        } else {
                          setInvoiceData({
                            ...invoiceData,
                            customerPhone: value,
                          });
                        }
                      }}
                    />
                  </td>
                  <th>연락처2</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={disabledFlag}
                      value={invoiceData?.customerPhone2 || ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                          let check = /^[0-9\-\b]+$/;
                          if (!check.test(value)) {
                            alert('숫자와 "-"만 입력 가능합니다.');
                          } else {
                            setInvoiceData({
                              ...invoiceData,
                              customerPhone2: value,
                            });
                          }
                        } else {
                          setInvoiceData({
                            ...invoiceData,
                            customerPhone2: value,
                          });
                        }
                      }}
                    />
                  </td>
                  <th>*판매사</th>
                  <td colSpan={2}>
                    <InputD readOnly value={MASTER_OBJ?.SELLER_WHOLE?.[invoiceData?.partnerSeq] || ''} disabled />
                  </td>
                  <th>*판매채널</th>
                  <td colSpan={2}>
                    <SelectD
                      hasNull={false}
                      isDisabled={disabledFlag}
                      value={
                        invoiceData?.salesChannelSeq
                          ? {
                              value: invoiceData?.salesChannelSeq,
                              label: MASTER_OBJ?.SALES_CHANNEL?.[invoiceData?.salesChannelSeq],
                            }
                          : null
                      }
                      options={MASTER_OPS?.SALES_CHANNEL?.filter((ele) => ele.value)}
                      onChange={(option) => {
                        setInvoiceData({
                          ...invoiceData,
                          salesChannelSeq: (option as OptionItem).value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>*수령자명</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={disabledFlag}
                      value={invoiceData?.receiverName || ''}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          receiverName: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <th>*연락처1</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={disabledFlag}
                      value={invoiceData?.receiverPhone || ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                          let check = /^[0-9\-\b]+$/;
                          if (!check.test(value)) {
                            alert('숫자와 "-"만 입력 가능합니다.');
                          } else {
                            setInvoiceData({
                              ...invoiceData,
                              receiverPhone: value,
                            });
                          }
                        } else {
                          setInvoiceData({
                            ...invoiceData,
                            receiverPhone: value,
                          });
                        }
                      }}
                    />
                  </td>
                  <th>연락처2</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={disabledFlag}
                      value={invoiceData?.receiverPhone2 || ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                          let check = /^[0-9\-\b]+$/;
                          if (!check.test(value)) {
                            alert('숫자와 "-"만 입력 가능합니다.');
                          } else {
                            setInvoiceData({
                              ...invoiceData,
                              receiverPhone2: value,
                            });
                          }
                        } else {
                          setInvoiceData({
                            ...invoiceData,
                            receiverPhone2: value,
                          });
                        }
                      }}
                    />
                  </td>
                  <th>판매처</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={disabledFlag || invoiceData?.invoiceStatus?.includes('FINISH') || invoiceData?.invoiceStatus?.includes('CANCEL')}
                      value={invoiceData?.seller || ''}
                      onChange={(e) => {
                        setInvoiceData((prev) => {
                          return {
                            ...prev,
                            seller: e.target.value,
                          };
                        });
                      }}
                    />
                  </td>
                  <th>{invoiceData.orderType === 'PARCEL' && '*'}택배사</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={
                        disabledFlag ||
                        invoiceData?.orderType !== 'PARCEL' ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      value={invoiceData?.parcelType || ''}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          parcelType: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>*우편번호</th>
                  <td colSpan={2}>
                    <InputD
                      disabled={disabledFlag}
                      value={invoiceData?.receiverZipcode || ''}
                      onClick={handleDaumPost}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          receiverZipcode: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <th>*주소</th>
                  <td colSpan={5}>
                    <InputD
                      placeholder="우편번호를 클릭하세요"
                      disabled
                      value={invoiceData?.receiverAddr1?.replace('null', '') || ''}
                      onClick={handleDaumPost}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          receiverAddr1: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <th>상세주소</th>
                  <td colSpan={5}>
                    <InputD
                      disabled={disabledFlag}
                      value={invoiceData?.receiverAddr2?.replace('null', '') || ''}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          receiverAddr2: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <th>
                    고객
                    <br />
                    요청사항
                  </th>
                  <td colSpan={5}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <textarea
                        disabled={disabledFlag}
                        value={invoiceData?.shipmentNote || ''}
                        onChange={(e) => {
                          setInvoiceData({
                            ...invoiceData,
                            shipmentNote: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </td>
                  <th>
                    콜센터 <br />
                    요청사항
                  </th>
                  <td colSpan={5}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <textarea
                        disabled={disabledFlag}
                        value={invoiceData?.callCenterNote || ''}
                        onChange={(e) => {
                          setInvoiceData({
                            ...invoiceData,
                            callCenterNote: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </td>
                  <th>
                    기사
                    <br />
                    요청사항
                  </th>
                  <td colSpan={2}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <textarea
                        disabled={disabledFlag}
                        value={invoiceData?.driverMemo || ''}
                        onChange={(e) => {
                          setInvoiceData({
                            ...invoiceData,
                            driverMemo: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div style={{ fontSize: 16, fontWeight: 500 }} className="inputSmaillTitle mb-2">
              기사정보
            </div>
            <table className="border-table">
              <tbody className="body-style">
                <tr>
                  <th>*인프라</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={
                        disabledFlag ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      value={
                        invoiceData?.infraSeq
                          ? {
                              value: invoiceData?.infraSeq,
                              label: MASTER_OBJ?.INFRA?.[invoiceData?.infraSeq],
                            }
                          : null
                      }
                      options={infraNcenterOptions?.INFRA}
                      onChange={(option) => {
                        setInvoiceData({
                          ...invoiceData,
                          infraSeq: (option as OptionItem).value,
                        });
                        filterInfraNCenterOptions(invoiceData?.centerCode, (option as OptionItem).value);
                      }}
                    />
                  </td>
                  <th>*소속창고</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={
                        disabledFlag ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      value={
                        invoiceData?.centerCode
                          ? {
                              value: invoiceData?.centerCode,
                              label: MASTER_OBJ?.CENTER_WHOLE?.[invoiceData?.centerCode],
                            }
                          : null
                      }
                      options={infraNcenterOptions?.CENTER}
                      onChange={(option) => {
                        setInvoiceData({
                          ...invoiceData,
                          centerCode: (option as OptionItem).value,
                          userId: null,
                        });
                        filterInfraNCenterOptions((option as OptionItem).value, invoiceData?.infraSeq);
                      }}
                    />
                  </td>
                  <th>주기사</th>
                  <td colSpan={2}>
                    <div style={{ display: 'flex' }}>
                      <Col md={6} style={{ display: 'inline-block' }}>
                        <SelectD
                          placeholder="창고를 선택하세요"
                          isDisabled={
                            disabledFlag ||
                            !invoiceData?.centerCode ||
                            invoiceData?.invoiceStatus === 'DELIVERY' ||
                            invoiceData?.invoiceStatus === 'REMOVING' ||
                            invoiceData?.invoiceStatus?.includes('FINISH') ||
                            invoiceData?.invoiceStatus?.includes('CANCEL')
                          }
                          options={driverInCenterOption}
                          value={
                            invoiceData?.userId
                              ? {
                                  value: invoiceData?.userId,
                                  label: MASTER_OBJ?.DRIVER_WHOLE?.[invoiceData?.userId],
                                }
                              : null
                          }
                          onChange={({ value }) => {
                            setInvoiceData({
                              ...invoiceData,
                              userId: value,
                            });
                          }}
                        />
                      </Col>
                      <Col md={6} style={{ display: 'inline-block' }}>
                        <InputD disabled value={invoiceData?.userId || ''} placeholder="기사를 선택하세요" />
                      </Col>
                    </div>
                  </td>
                  <th>부기사</th>
                  <td colSpan={2}>
                    <div style={{ display: 'flex' }}>
                      <Col md={6} style={{ display: 'inline-block' }}>
                        <SelectD
                          isDisabled={
                            disabledFlag ||
                            invoiceData?.invoiceStatus === 'DELIVERY' ||
                            invoiceData?.invoiceStatus === 'REMOVING' ||
                            invoiceData?.invoiceStatus?.includes('FINISH') ||
                            invoiceData?.invoiceStatus?.includes('CANCEL')
                          }
                          options={driverInCenterOption}
                          value={
                            invoiceData?.assistUserId
                              ? {
                                  value: invoiceData?.assistUserId,
                                  label: MASTER_OBJ?.DRIVER_WHOLE?.[invoiceData?.assistUserId],
                                }
                              : null
                          }
                          onChange={({ value }) => {
                            setInvoiceData({
                              ...invoiceData,
                              assistUserId: value,
                            });
                          }}
                        />
                      </Col>
                      <Col md={6} style={{ display: 'inline-block' }}>
                        <InputD disabled value={invoiceData?.assistUserId || ''} placeholder="기사를 선택하세요" />
                      </Col>
                    </div>
                  </td>
                  <th>동행기사</th>
                  <td colSpan={2}>
                    <div style={{ display: 'flex' }}>
                      <Col md={6} style={{ display: 'inline-block' }}>
                        <SelectD
                          isDisabled={
                            disabledFlag ||
                            invoiceData?.invoiceStatus === 'DELIVERY' ||
                            invoiceData?.invoiceStatus === 'REMOVING' ||
                            invoiceData?.invoiceStatus?.includes('FINISH') ||
                            invoiceData?.invoiceStatus?.includes('CANCEL')
                          }
                          options={driverInCenterOption}
                          value={
                            invoiceData?.internUserId
                              ? {
                                  value: invoiceData?.internUserId,
                                  label: MASTER_OBJ?.DRIVER_WHOLE?.[invoiceData?.internUserId],
                                }
                              : null
                          }
                          onChange={(option) => {
                            setInvoiceData({
                              ...invoiceData,
                              internUserId: (option as OptionItem).value,
                            });
                          }}
                        />
                      </Col>
                      <Col md={6} style={{ display: 'inline-block' }}>
                        <InputD disabled value={invoiceData?.internUserId || ''} placeholder="기사를 선택하세요" />
                      </Col>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th colSpan={6}></th>
                  <th>
                    주기사
                    <br />
                    연락처
                  </th>
                  <td colSpan={2}>
                    <InputD readOnly style={{ textAlign: 'center' }} value={invoiceData?.userId ? MASTER_OBJ?.DRIVER_PHONE_WHOLE?.[invoiceData?.userId] : ''} disabled />
                  </td>
                  <th>
                    부기사
                    <br />
                    연락처
                  </th>
                  <td colSpan={2}>
                    <InputD readOnly style={{ textAlign: 'center' }} value={invoiceData?.assistUserId ? MASTER_OBJ?.DRIVER_PHONE_WHOLE?.[invoiceData?.assistUserId] : ''} disabled />
                  </td>
                  <th>
                    동행기사
                    <br />
                    연락처
                  </th>
                  <td colSpan={2}>
                    <InputD readOnly style={{ textAlign: 'center' }} value={invoiceData?.internUserId ? MASTER_OBJ?.DRIVER_PHONE_WHOLE?.[invoiceData?.internUserId] : ''} disabled />
                  </td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
          </div>
          <div>
            <div style={{ fontSize: 16, fontWeight: 500 }} className="inputSmaillTitle mb-2">
              송장정보
            </div>
            <table className="border-table">
              <tbody>
                <tr>
                  <th>*송장상태</th>
                  <td colSpan={2}>
                    <InputD readOnly value={invoiceData?.invoiceStatus ? MASTER_OBJ?.INVOICE_STATUS?.[invoiceData?.invoiceStatus] : ''} disabled />
                  </td>
                  <th>지정일</th>
                  <td colSpan={2}>
                    <FlatpickrD
                      value={invoiceData?.deliveryDate}
                      onReset={() => {
                        setInvoiceData({
                          ...invoiceData,
                          deliveryDate: null,
                          deliveryTime: null,
                        });
                      }}
                      disabled={
                        disabledFlag ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      onChange={(date) => {
                        setInvoiceData({
                          ...invoiceData,
                          deliveryDate: returnDateyyyymmdd(date),
                        });
                      }}
                      options={{
                        mode: 'single',
                      }}
                    />
                  </td>
                  <th>약속시간</th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={disabledFlag || !invoiceData?.deliveryDate || invoiceData?.invoiceStatus?.includes('FINISH') || invoiceData?.invoiceStatus?.includes('CANCEL')}
                      options={MASTER_OPS?.DELIVERY_TIME}
                      value={
                        invoiceData?.deliveryTime
                          ? {
                              value: invoiceData?.deliveryTime,
                              label: invoiceData?.deliveryTime,
                            }
                          : null
                      }
                      onChange={({ value }) => {
                        setInvoiceData({
                          ...invoiceData,
                          deliveryTime: value,
                        });
                      }}
                    />
                  </td>
                  <th>
                    지정일
                    <br />
                    변경사유
                  </th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={
                        disabledFlag ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      value={
                        invoiceData?.rescheduleType
                          ? {
                              value: invoiceData?.rescheduleType,
                              label: MASTER_OBJ?.RESCHEDULE_TYPE?.[invoiceData?.rescheduleType],
                            }
                          : null
                      }
                      options={MASTER_OPS?.RESCHEDULE_TYPE}
                      onChange={({ value }) => {
                        setInvoiceData({
                          ...invoiceData,
                          rescheduleType: value,
                        });
                      }}
                    />
                  </td>
                  <th>(직접입력)</th>
                  <td colSpan={2}>
                    <InputD
                      placeholder='지정일 변경사유 "기타" 시에 입력 가능합니다'
                      disabled={
                        disabledFlag ||
                        invoiceData?.rescheduleType !== 'ETC' ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      value={invoiceData?.rescheduleNote || ''}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          rescheduleNote: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    완료일/
                    <br />
                    취소일
                  </th>
                  <td colSpan={2}>
                    <FlatpickrD
                      disabled={disabledFlag || !invoiceData.invoiceStatus.includes('FINISH')}
                      value={invoiceData?.finishOrCancelDate || ''}
                      onChange={(date) => {
                        setInvoiceData({
                          ...invoiceData,
                          finishOrCancelDate: returnDateyyyymmdd(date),
                        });
                      }}
                      onReset={() => {
                        setInvoiceData({
                          ...invoiceData,
                          finishOrCancelDate: null,
                        });
                      }}
                      options={{
                        mode: 'single',
                      }}
                    />
                  </td>
                  <th>
                    완료일/취소일
                    <br />
                    변경자
                  </th>
                  <td colSpan={2}>
                    <InputD readOnly value={invoiceData?.finishOrCancelUpdateUserId || ''} disabled />
                  </td>
                  <th>
                    완료일/취소일
                    <br />
                    변경사유
                  </th>
                  <td colSpan={2}>
                    <InputD
                      disabled={disabledFlag || !invoiceData.invoiceStatus.includes('FINISH')}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          finishOrCancelUpdateReason: e.target.value,
                        });
                      }}
                      value={invoiceData?.finishOrCancelUpdateReason || ''}
                    />
                  </td>
                  <th>취소사유</th>
                  <td colSpan={2}>
                    <InputD readOnly value={invoiceData?.cancelReason ? MASTER_OBJ?.CANCEL_REASON?.[invoiceData?.cancelReason] : ''} disabled />
                  </td>
                  <th>
                    운임구분/
                    <br />
                    운임비
                  </th>
                  <td colSpan={2}>
                    <div style={{ display: 'flex' }}>
                      <Col md={6} style={{ display: 'inline-block' }}>
                        <SelectD
                          isDisabled={disabledFlag}
                          // parcelPayType
                          options={MASTER_OPS?.PARCEL_PAY_TYPE}
                          value={
                            invoiceData?.parcelPayType
                              ? {
                                  value: invoiceData?.parcelPayType,
                                  label: MASTER_OBJ?.PARCEL_PAY_TYPE?.[invoiceData?.parcelPayType],
                                }
                              : null
                          }
                          onChange={({ value }) => {
                            setInvoiceData({
                              ...invoiceData,
                              parcelPayType: value,
                            });
                          }}
                        />
                      </Col>
                      <Col md={6} style={{ display: 'inline-block' }}>
                        <InputD
                          disabled={disabledFlag}
                          value={invoiceData?.cashOnDelivery || ''}
                          onChange={(e) => {
                            setInvoiceData((prev) => {
                              return {
                                ...prev,
                                cashOnDelivery: Number(stringValueToNumber(e.target.value)),
                              };
                            });
                          }}
                        />
                      </Col>
                    </div>
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <th>설치형태</th>
                  <td colSpan={2}>
                    <SelectD
                      value={
                        invoiceData?.installForm
                          ? {
                              value: invoiceData?.installForm,
                              label: MASTER_OBJ?.INSTALL_FORM?.[invoiceData?.installForm],
                            }
                          : null
                      }
                      isDisabled={
                        disabledFlag ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      options={MASTER_OPS?.INSTALL_FORM}
                      onChange={(option) => {
                        setInvoiceData({
                          ...invoiceData,
                          installForm: (option as OptionItem).value,
                        });
                      }}
                    />
                  </td>
                  <th>
                    대리수령
                    <br />
                    여부
                  </th>
                  <td colSpan={2}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                      <InputD
                        disabled={
                          disabledFlag ||
                          invoiceData?.invoiceStatus === 'DELIVERY' ||
                          invoiceData?.invoiceStatus === 'REMOVING' ||
                          invoiceData?.invoiceStatus?.includes('FINISH') ||
                          invoiceData?.invoiceStatus?.includes('CANCEL')
                        }
                        type="radio"
                        style={{ marginRight: 5 }}
                        checked={invoiceData?.relationship === '10'}
                        onChange={(e) => {
                          setInvoiceData({
                            ...invoiceData,
                            relationship: '10',
                          });
                        }}
                      />
                      본인
                      <InputD
                        type="radio"
                        disabled={
                          disabledFlag ||
                          invoiceData?.invoiceStatus === 'DELIVERY' ||
                          invoiceData?.invoiceStatus === 'REMOVING' ||
                          invoiceData?.invoiceStatus?.includes('FINISH') ||
                          invoiceData?.invoiceStatus?.includes('CANCEL')
                        }
                        style={{ marginLeft: 10, marginRight: 5 }}
                        checked={invoiceData?.relationship === '20'}
                        onChange={(e) => {
                          setInvoiceData({
                            ...invoiceData,
                            relationship: '20',
                          });
                        }}
                      />
                      대리인
                    </div>
                  </td>
                  <th>Wifi-연결</th>
                  <td colSpan={2}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                      <InputD
                        type="radio"
                        disabled={
                          disabledFlag ||
                          invoiceData?.invoiceStatus === 'DELIVERY' ||
                          invoiceData?.invoiceStatus === 'REMOVING' ||
                          invoiceData?.invoiceStatus?.includes('FINISH') ||
                          invoiceData?.invoiceStatus?.includes('CANCEL')
                        }
                        checked={invoiceData?.wifiFlag === true}
                        onChange={(e) => {
                          setInvoiceData({
                            ...invoiceData,
                            wifiFlag: true,
                            wifiReason: null,
                            wifiMemo: null,
                          });
                        }}
                        style={{ marginRight: 5 }}
                      />
                      연결
                      <InputD
                        type="radio"
                        disabled={
                          disabledFlag ||
                          invoiceData?.invoiceStatus === 'DELIVERY' ||
                          invoiceData?.invoiceStatus === 'REMOVING' ||
                          invoiceData?.invoiceStatus?.includes('FINISH') ||
                          invoiceData?.invoiceStatus?.includes('CANCEL')
                        }
                        style={{ marginLeft: 10, marginRight: 5 }}
                        checked={invoiceData?.wifiFlag === false}
                        onChange={(e) => {
                          setInvoiceData({
                            ...invoiceData,
                            wifiFlag: false,
                          });
                        }}
                      />
                      미연결
                    </div>
                  </td>
                  <th>
                    미연결
                    <br />
                    사유
                  </th>
                  <td colSpan={2}>
                    <SelectD
                      isDisabled={
                        disabledFlag ||
                        invoiceData?.wifiFlag ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      value={
                        invoiceData?.wifiReason
                          ? {
                              value: invoiceData?.wifiReason,
                              label: MASTER_OBJ?.BODY_WIFI_REASON?.[invoiceData?.wifiReason],
                            }
                          : null
                      }
                      options={MASTER_OPS?.BODY_WIFI_REASON}
                      onChange={(option) => {
                        setInvoiceData({
                          ...invoiceData,
                          wifiReason: (option as OptionItem).value,
                        });
                      }}
                    />
                  </td>
                  <th>
                    사유
                    <br />
                    직접입력
                  </th>
                  <td colSpan={2}>
                    <InputD
                      value={invoiceData?.wifiMemo || ''}
                      disabled={
                        disabledFlag ||
                        !invoiceData?.wifiFlag ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          wifiMemo: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th style={{ width: 60 }}>작업특이사항</th>
                  <td colSpan={14}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <textarea
                        disabled={disabledFlag}
                        value={invoiceData?.workNote || ''}
                        onChange={(e) => {
                          setInvoiceData({
                            ...invoiceData,
                            workNote: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="inputSmaillTitle mb-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: '1rem', width: '50%' }}>
              <div>
                <span style={{ fontSize: 16, fontWeight: 500, marginRight: 10 }}>상품정보</span>
                <span>상품 번호 : {invoiceData?.productSeq || '-'}</span> &nbsp;&nbsp;
                <span>상품 수량 : {invoiceInventories?.length || '-'}개</span>
              </div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: 10 }}>
                    <label className="col-form-label">상품명</label>
                    <InputD
                      disabled={
                        disabledFlag ||
                        invoiceData?.productSeq !== null ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      placeholder="미등록 상품명을 별도로 입력하지 않을 시 자동 생성 됩니다. (예 : 라클라우드 외1건)"
                      style={{ width: 350 }}
                      value={invoiceData?.productName || ''}
                      onChange={(e) => {
                        setInvoiceData((prev) => {
                          return {
                            ...prev,
                            productName: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                  <div style={{ width: 150, marginRight: 10 }}>
                    <label className="col-form-label">제품그룹</label>
                    <SelectD
                      menuPlacement="top"
                      isDisabled={
                        disabledFlag ||
                        invoiceData?.productSeq !== null ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      value={
                        invoiceData?.modelGroup
                          ? {
                              value: invoiceData?.modelGroup,
                              label: MASTER_OBJ?.MODEL_GROUP?.[invoiceData?.modelGroup],
                            }
                          : null
                      }
                      options={MASTER_OPS?.MODEL_GROUP}
                      onChange={(option) => {
                        if (!invoiceData?.productSeq) {
                          fetchInfraNCenterOptions((option as OptionItem)?.value);
                          setInvoiceData((prev) => {
                            return {
                              ...prev,
                              infraSeq: null,
                              centerCode: null,
                              userId: null,
                              assistUserId: null,
                              internUserId: null,
                              modelGroup: (option as OptionItem)?.value,
                            };
                          });
                        } else {
                          setInvoiceData((prev) => {
                            return {
                              ...prev,
                              modelGroup: (option as OptionItem)?.value,
                            };
                          });
                        }
                      }}
                    />
                  </div>
                  <div style={{ width: 150, marginRight: 10 }}>
                    <label className="col-form-label">배송팀유형</label>
                    <SelectD
                      menuPlacement="top"
                      isDisabled={
                        disabledFlag ||
                        invoiceData?.productSeq !== null ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      options={MASTER_OPS?.TEAM_TYPE}
                      value={
                        invoiceData?.memberCount
                          ? {
                              value: invoiceData?.memberCount + '',
                              label: MASTER_OBJ?.TEAM_TYPE?.[invoiceData?.memberCount],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setInvoiceData((prev) => {
                          return {
                            ...prev,
                            memberCount: (option as OptionItem)?.value,
                          };
                        });
                      }}
                    />
                  </div>
                  <div style={{ width: 150 }}>
                    <label className="col-form-label">설치소요시간</label>
                    <SelectD
                      menuPlacement="top"
                      isDisabled={
                        disabledFlag ||
                        invoiceData?.productSeq !== null ||
                        invoiceData?.invoiceStatus === 'DELIVERY' ||
                        invoiceData?.invoiceStatus === 'REMOVING' ||
                        invoiceData?.invoiceStatus?.includes('FINISH') ||
                        invoiceData?.invoiceStatus?.includes('CANCEL')
                      }
                      options={MASTER_OPS?.INSTALL_DURATION}
                      value={
                        invoiceData?.installDuration + ''
                          ? {
                              value: invoiceData?.installDuration,
                              label: MASTER_OBJ?.INSTALL_DURATION?.[invoiceData?.installDuration],
                            }
                          : null
                      }
                      onChange={(option) => {
                        setInvoiceData((prev) => {
                          return {
                            ...prev,
                            installDuration: (option as OptionItem)?.value,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ color: 'red', marginRight: 10 }}>* 상품변경 시 "수정하기" 버튼을 눌러주셔야 상품정보가 최종 수정됩니다</span>
              <Button
                className="btn-secondary btn ssm"
                disabled={
                  disabledFlag ||
                  invoiceData?.invoiceStatus === 'DELIVERY' ||
                  invoiceData?.invoiceStatus === 'REMOVING' ||
                  invoiceData?.invoiceStatus?.includes('FINISH') ||
                  invoiceData?.invoiceStatus?.includes('CANCEL')
                }
                onClick={() => {
                  if (
                    disabledFlag ||
                    invoiceData?.invoiceStatus === 'DELIVERY' ||
                    invoiceData?.invoiceStatus === 'REMOVING' ||
                    invoiceData?.invoiceStatus?.includes('FINISH') ||
                    invoiceData?.invoiceStatus?.includes('CANCEL')
                  ) {
                    alert('완료 건 혹은 배송중일시에는 상품을 변경할 수 없습니다');
                  } else {
                    setProductStockObj({ visible: true, partnerSeq: orderDetailInfo?.partnerSeq });
                  }
                }}
              >
                상품 변경
              </Button>
              <Button
                className="btn-secondary btn ssm"
                disabled={
                  disabledFlag ||
                  invoiceData?.invoiceStatus === 'DELIVERY' ||
                  invoiceData?.invoiceStatus === 'REMOVING' ||
                  invoiceData?.invoiceStatus?.includes('FINISH') ||
                  invoiceData?.invoiceStatus?.includes('CANCEL')
                }
                onClick={() => {
                  if (
                    disabledFlag ||
                    invoiceData?.invoiceStatus === 'DELIVERY' ||
                    invoiceData?.invoiceStatus === 'REMOVING' ||
                    invoiceData?.invoiceStatus?.includes('FINISH') ||
                    invoiceData?.invoiceStatus?.includes('CANCEL')
                  ) {
                    alert('완료 건 혹은 배송중일시에는 상품을 변경할 수 없습니다');
                  } else {
                    setModelStockObj({ visible: true, partnerSeq: orderDetailInfo?.partnerSeq });
                  }
                }}
              >
                제품 추가
              </Button>
            </span>
          </div>
          <div className="grid-button-area space-between">
            <div>
              {invoiceInventories?.map((ele, idx) => {
                return (
                  <div
                    className={inventoryIdx === idx ? 'orange' : 'light-orange'}
                    key={`productModels_idx_${idx}`}
                    onClick={() => {
                      setInventoryIdx(idx);
                    }}
                  >
                    {idx + 1}
                  </div>
                );
              })}
              {!(
                disabledFlag ||
                invoiceData?.invoiceStatus === 'DELIVERY' ||
                invoiceData?.invoiceStatus === 'REMOVING' ||
                invoiceData?.invoiceStatus?.includes('FINISH') ||
                invoiceData?.invoiceStatus?.includes('CANCEL')
              ) && (
                <div
                  className={'light-orange'}
                  onClick={() => {
                    //

                    const _invoiceInventories = JSON.parse(JSON.stringify(invoiceInventories));
                    _invoiceInventories.push(JSON.parse(JSON.stringify(invoiceInventories[0])));
                    const newInvInventories = _invoiceInventories?.map((group, idx) => {
                      return group?.map((row) => {
                        return {
                          ...row,
                          productIndex: idx,
                          invoiceInventorySeq: idx > 0 ? row.invoiceInventorySeq : null,
                        };
                      });
                    });
                    setInvoiceInventories(newInvInventories);
                  }}
                >
                  + 추가
                </div>
              )}
            </div>
            <div>
              {!(
                disabledFlag ||
                invoiceData?.invoiceStatus === 'DELIVERY' ||
                invoiceData?.invoiceStatus === 'REMOVING' ||
                invoiceData?.invoiceStatus?.includes('FINISH') ||
                invoiceData?.invoiceStatus?.includes('CANCEL')
              ) && (
                <div
                  className={'red'}
                  onClick={() => {
                    if (invoiceInventories?.length > 1) {
                      const filtered = invoiceInventories.filter((_, i) => i !== inventoryIdx);
                      setInventoryIdx(inventoryIdx - 1 > 0 ? inventoryIdx - 1 : 0);
                      setInvoiceInventories(filtered);
                    } else {
                      alert('상품 수량이 1개인 경우에는 삭제가 불가능합니다.');
                    }
                  }}
                >
                  - 삭제
                </div>
              )}
            </div>
          </div>
          <table className="border-table" style={{ marginTop: 0 }}>
            <thead>
              <tr>
                <th>제품구분</th>
                <th>바코드</th>
                <th colSpan={3}>제품</th>
                <th colSpan={3}>제품명</th>
                <th colSpan={2}>외부SKU</th>
                <th>원가</th>
                <th>판매가</th>
                <th colSpan={2}>시리얼번호</th>
                <th>
                  박스개봉
                  <br />
                  여부
                </th>
                <th>
                  기사회수
                  <br />
                  여부
                </th>
                <th>제품회수사유</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invoiceInventories?.[inventoryIdx]?.map((p, index) => {
                return (
                  <tr key={`orderInfo_productModel_${index}`}>
                    <th>
                      <SelectD
                        menuPlacement="top"
                        hasNull={false}
                        isDisabled={
                          inventoryIdx > 0 ||
                          disabledFlag ||
                          invoiceData?.invoiceStatus === 'DELIVERY' ||
                          invoiceData?.invoiceStatus === 'REMOVING' ||
                          invoiceData?.invoiceStatus?.includes('FINISH') ||
                          invoiceData?.invoiceStatus?.includes('CANCEL')
                        }
                        options={[
                          {
                            value: false,
                            label: '구성품',
                          },
                          {
                            value: true,
                            label: '사은품',
                          },
                        ]}
                        value={
                          p?.giftFlag
                            ? {
                                value: true,
                                label: '사은품',
                              }
                            : {
                                value: false,
                                label: '구성품',
                              }
                        }
                        onChange={(option) => {
                          const _productModels = JSON.parse(JSON.stringify(invoiceInventories));
                          _productModels.forEach((group) => {
                            group?.forEach((ele, i) => {
                              if (index === i) group[i].giftFlag = (option as OptionItem)?.value;
                            });
                          });
                          setInvoiceInventories(_productModels);
                        }}
                      />
                    </th>
                    <th>{p?.barcode}</th>
                    <th colSpan={3}>{p?.model}</th>
                    <th colSpan={3}>{p?.modelName}</th>
                    <th colSpan={2}>{p?.skuNumber}</th>
                    <th>{p?.primePrice !== null ? numberThousandComma(p?.primePrice) : ''}</th>
                    <th>
                      <InputD
                        disabled={inventoryIdx === 0 ? disabledFlag : true}
                        value={p?.sellingPrice !== null ? numberThousandComma(p?.sellingPrice) : ''}
                        onChange={(e) => {
                          const _productModels = JSON.parse(JSON.stringify(invoiceInventories));
                          _productModels.forEach((group) => {
                            group?.forEach((ele, i) => {
                              if (index === i) group[i].sellingPrice = e.target.value;
                            });
                          });
                          setInvoiceInventories(_productModels);
                        }}
                      />
                    </th>
                    <th colSpan={2}>
                      <InputD
                        disabled={disabledFlag}
                        value={p?.serialNumber || ''}
                        onChange={(e) => {
                          const _productModels = JSON.parse(JSON.stringify(invoiceInventories));
                          const _thisModels = JSON.parse(JSON.stringify(invoiceInventories[inventoryIdx]));
                          _thisModels[index].serialNumber = e.target.value;
                          _productModels[inventoryIdx] = _thisModels;
                          setInvoiceInventories(_productModels);
                        }}
                      />
                    </th>
                    <th>{p?.boxOpenFlag === true ? '개봉' : '미개봉'}</th>
                    <th>{p?.driverCollectFlag === true ? '완료' : '미완료'}</th>
                    <th>{MASTER_OBJ?.COLLECT_REASON?.[p?.collectReason]}</th>
                    <th>
                      <button
                        disabled={
                          inventoryIdx > 0 ||
                          disabledFlag ||
                          invoiceData?.invoiceStatus === 'DELIVERY' ||
                          invoiceData?.invoiceStatus === 'REMOVING' ||
                          invoiceData?.invoiceStatus?.includes('FINISH') ||
                          invoiceData?.invoiceStatus?.includes('CANCEL')
                        }
                        onClick={(e) => {
                          if (invoiceInventories?.[0]?.length > 1) {
                            setInvoiceData((prev) => {
                              return {
                                ...prev,
                                productName: null,
                                productSeq: null,
                              };
                            });
                            const _invoiceInventories = JSON.parse(JSON.stringify(invoiceInventories));
                            const deleted = _invoiceInventories?.map((group) => {
                              return group?.filter((g, idx) => idx !== index);
                            });
                            setInvoiceInventories(deleted);
                          } else {
                            alert('구성 제품이 1개인 경우에는 삭제가 불가능합니다.');
                          }
                        }}
                        className="btn btn-outline-danger sssm"
                      >
                        삭제
                      </button>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {(invoiceData?.invoiceStatus?.includes('FINISH') || invoiceData?.invoiceStatus?.includes('CANCEL')) && (
            <div style={{ marginTop: '1rem' }}>
              <span style={{ fontSize: 16, fontWeight: 500, marginRight: 10 }}>부가정보</span>
              <div style={{ fontSize: 13 }} className="inputSmaillTitle mb-2">
                사다리차 / 양중
              </div>
              {ladderObj && (
                <div style={{ border: '1px solid #e3e3e3', padding: 10 }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span>사다리차 정보</span>
                    <span>
                      <button
                        disabled={disabledFlag}
                        onDoubleClick={() => {
                          return;
                        }}
                        onClick={(e) => {
                          setLadderObj(null);
                        }}
                        className="btn btn-etc"
                      >
                        사다리차 삭제
                      </button>
                    </span>
                  </div>
                  <Table>
                    <tbody className="body-style">
                      <tr>
                        <th>
                          사다리차
                          <br />
                          업체명
                        </th>
                        <td>
                          <div style={{ display: 'flex' }}>
                            <SelectD
                              isDisabled={disabledFlag}
                              value={
                                ladderObj?.company
                                  ? {
                                      value: ladderObj?.company,
                                      label: MASTER_OBJ?.LADDER_CO?.[ladderObj?.company],
                                    }
                                  : null
                              }
                              options={MASTER_OPS?.LADDER_CO}
                              onChange={({ value, label }) => {
                                setLadderObj({
                                  ...ladderObj,
                                  company: value,
                                  companyName: label,
                                });
                                setcompanyNameTmpHandle(null);
                              }}
                            />
                            <InputD
                              value={companyNameTmpHandle || ''}
                              disabled={disabledFlag || ladderObj?.company !== null}
                              onChange={(e) => {
                                setcompanyNameTmpHandle(e.target.value);
                              }}
                            />
                          </div>
                        </td>
                        <th>기사명</th>
                        <td>
                          <InputD
                            disabled={disabledFlag}
                            value={ladderObj?.driverName || ''}
                            onChange={(e) => {
                              setLadderObj({
                                ...ladderObj,
                                driverName: e.target.value,
                              });
                            }}
                          />
                        </td>
                        <th>차량번호</th>
                        <td>
                          <InputD
                            disabled={disabledFlag}
                            value={ladderObj?.carNumber || ''}
                            onChange={(e) => {
                              setLadderObj({
                                ...ladderObj,
                                carNumber: e.target.value,
                              });
                            }}
                          />
                        </td>
                        <th>사용층수</th>
                        <td>
                          <InputD
                            disabled={disabledFlag}
                            type="number"
                            value={ladderObj?.floor || ''}
                            onChange={(e) => {
                              setLadderObj({
                                ...ladderObj,
                                floor: Number(e.target.value),
                              });
                            }}
                          />
                        </td>
                        <th>시간(분)</th>
                        <td style={{ width: 150 }}>
                          <Select
                            isDisabled={disabledFlag}
                            options={MASTER_OPS?.INSTALL_DURATION}
                            value={
                              ladderObj?.duration
                                ? {
                                    value: ladderObj?.duration,
                                    label: MASTER_OBJ?.INSTALL_DURATION?.[ladderObj?.duration],
                                  }
                                : null
                            }
                            onChange={({ value }) => {
                              setLadderObj({
                                ...ladderObj,
                                duration: value,
                              });
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>특이사항</th>
                        <td colSpan={10}>
                          <InputD
                            disabled={disabledFlag}
                            value={ladderObj?.ladderNote || ''}
                            onChange={(e) => {
                              setLadderObj({
                                ...ladderObj,
                                ladderNote: e.target.value,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}

              {liftingObj && (
                <div
                  style={{
                    border: '1px solid #e3e3e3',
                    padding: 10,
                    borderTop: 'none',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span>양중 정보</span>
                    <span>
                      <button
                        disabled={disabledFlag}
                        onDoubleClick={() => {
                          return;
                        }}
                        onClick={(e) => {
                          setLiftingObj(null);
                        }}
                        className="btn btn-etc"
                      >
                        양중 삭제
                      </button>
                    </span>
                  </div>
                  <Table>
                    <tbody className="body-style">
                      <tr>
                        <th>양중층수</th>
                        <td>
                          <Select
                            isDisabled={disabledFlag}
                            options={MASTER_OPS?.FLOOR}
                            value={
                              liftingObj?.floor
                                ? {
                                    value: liftingObj?.floor,
                                    label: MASTER_OBJ?.FLOOR?.[liftingObj?.floor],
                                  }
                                : null
                            }
                            onChange={({ value }) => {
                              setLiftingObj({
                                ...liftingObj,
                                floor: value,
                              });
                            }}
                          />
                        </td>
                        <th>금액</th>
                        <td>
                          <InputD
                            type="number"
                            value={liftingObj?.price || ''}
                            disabled={true} // 추후 개발예정
                            onChange={(e) => {
                              setLiftingObj({
                                ...liftingObj,
                                price: Number(e.target.value),
                              });
                            }}
                          />
                        </td>
                        <th>특이사항</th>
                        <td colSpan={5}>
                          <InputD
                            disabled={disabledFlag}
                            value={liftingObj?.liftingNote || ''}
                            onChange={(e) => {
                              setLiftingObj({
                                ...liftingObj,
                                liftingNote: e.target.value,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {!ladderObj && (
                  <button
                    disabled={disabledFlag}
                    onDoubleClick={() => {
                      return;
                    }}
                    onClick={(e) => {
                      setLadderObj(defaultLadder);
                    }}
                    className="btn btn-etc"
                  >
                    + 사다리차 추가
                  </button>
                )}
                {!liftingObj && (
                  <button
                    disabled={disabledFlag}
                    onDoubleClick={() => {
                      return;
                    }}
                    onClick={(e) => {
                      setLiftingObj(defaultLifting);
                    }}
                    className="btn btn-etc"
                  >
                    + 양중 추가
                  </button>
                )}
              </div>

              <div>
                <div style={{ fontSize: 13 }} className="inputSmaillTitle mb-2">
                  폐가전 수거 / 내림
                </div>
                <table className="border-table">
                  <thead>
                    <tr>
                      <th style={{ width: 50 }}>No.</th>
                      <th>제품구분</th>
                      <th>수거/내림</th>
                      <th>용량</th>
                      <th style={{ width: 130 }}>수량(수거)/층수(내림)</th>
                      <th style={{ width: 100 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderInfoScraps?.map((eachScrap, idx) => {
                      return (
                        <tr key={`orderInfoScraps_${idx}`}>
                          <th>{idx + 1}</th>
                          <th>
                            <SelectD
                              isDisabled={disabledFlag}
                              menuPlacement="top"
                              options={MASTER_OPS?.EOL_GROUP2}
                              onChange={(option) => {
                                if ((option as OptionItem)?.value) {
                                  const filtered = MASTER_OPS?.[`${RAWDATA}EOL_GROUP2`]?.filter((ele) => ele.code === (option as OptionItem)?.value)[0]?.subCodes;
                                  setYongryangOps(
                                    filtered?.map((ele) => {
                                      return {
                                        value: ele.code,
                                        label: ele.codeName,
                                      };
                                    }),
                                  );
                                } else {
                                  setYongryangOps(null);
                                }
                                const copied = orderInfoScraps ? [...orderInfoScraps] : [];
                                copied[idx]['modelRange'] = (option as OptionItem).value;
                                setOrderInfoScraps(copied);
                              }}
                            />
                          </th>
                          <th>
                            <SelectD
                              isDisabled={disabledFlag}
                              menuPlacement="top"
                              options={[
                                { value: true, label: '수거' },
                                { value: false, label: '내림' },
                              ]}
                              placeholder={eachScrap?.collectFlag === true ? '수거' : eachScrap?.collectFlag === false ? '내림' : '선택'}
                              onChange={(option) => {
                                const copied = [...orderInfoScraps];
                                copied[idx]['collectFlag'] = (option as OptionItem).value;
                                setOrderInfoScraps(copied);
                              }}
                            />
                          </th>
                          <th>
                            <SelectD
                              menuPlacement="top"
                              isDisabled={!yongRyangOps}
                              options={yongRyangOps}
                              placeholder={selectlabel(eachScrap?.volume, yongRyangOps)}
                              onChange={(option) => {
                                const copied = orderInfoScraps ? [...orderInfoScraps] : [];
                                copied[idx]['volume'] = (option as OptionItem).value;
                                setOrderInfoScraps(copied);
                              }}
                            />
                          </th>
                          <th>
                            <InputD
                              disabled={disabledFlag}
                              type="number"
                              value={eachScrap?.quantity || ''}
                              onChange={(e) => {
                                const copied = orderInfoScraps ? [...orderInfoScraps] : [];
                                const thisScrap = { ...eachScrap };
                                thisScrap['quantity'] = Number(e.target.value);
                                copied[idx] = thisScrap;
                                setOrderInfoScraps(copied);
                              }}
                            />
                          </th>
                          <th>
                            <Button
                              disabled={disabledFlag}
                              className="btn-etc sssm"
                              onClick={() => {
                                const arr = [];
                                orderInfoScraps?.forEach((scrap, i) => {
                                  if (idx !== i) {
                                    arr.push(scrap);
                                  }
                                });
                                setOrderInfoScraps(arr);
                              }}
                            >
                              삭제
                            </Button>
                          </th>
                        </tr>
                      );
                    })}
                    <tr>
                      <th colSpan={6}>
                        <button
                          disabled={disabledFlag}
                          onDoubleClick={() => {
                            return;
                          }}
                          onClick={(e) => {
                            const scrapCopiedArr = orderInfoScraps ? [...orderInfoScraps] : [];
                            scrapCopiedArr.push({
                              modelRange: null,
                              collectFlag: null,
                              volume: null,
                              quantity: null,
                            });
                            setOrderInfoScraps(scrapCopiedArr);
                          }}
                          className="btn btn-etc"
                        >
                          + 추가하기
                        </button>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
