import { KeyboardEvent, useEffect, useRef, useState } from 'react';

import { Col, Row, Form } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { resetAllSelections } from 'common/util/searchBox';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { QuestionAndAnswerSearchDTO } from 'interface/user';

export const SearchBox = (props: ISearchBoxProps<QuestionAndAnswerSearchDTO>) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchCount, fetchList } = props;

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">기사소속창고</label>
            <SelectD
              value={
                searchObj?.centerCode
                  ? {
                      value: searchObj?.centerCode,
                      label: selectlabel(searchObj?.centerCode, masterOptions?.CENTER),
                    }
                  : null
              }
              options={masterOptions?.CENTER}
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  centerCode: value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">기사</label>
            <div style={{ display: 'flex' }}>
              <Col md={6} style={{ display: 'inline-block' }}>
                <SelectD
                  options={masterOptions?.DRIVER}
                  value={
                    searchObj?.userId
                      ? {
                          value: searchObj?.userId,
                          label: selectlabel(searchObj?.userId, masterOptions?.DRIVER),
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    setSearchObj({
                      ...searchObj,
                      userId: value,
                    });
                  }}
                />
              </Col>
              <Col md={6} style={{ display: 'inline-block' }}>
                <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">접수번호</label>
            <InputD
              type="number"
              value={searchObj?.questionAndAnswerSeq || ''}
              onChange={(e) =>
                setSearchObj({
                  ...searchObj,
                  questionAndAnswerSeq: Number(e.target.value),
                })
              }
            />
          </Col>
          <Col>
            <label className="col-form-label">작업지시번호</label>
            <InputD
              type="number"
              value={searchObj?.tableSeq || ''}
              onChange={(e) =>
                setSearchObj({
                  ...searchObj,
                  tableSeq: Number(e.target.value),
                })
              }
            />
          </Col>
          <Col>
            <label className="col-form-label">CS접수일</label>
            <FlatpickrD
              value={[searchObj?.fromQuestionDate, searchObj?.toQuestionDate]}
              onChange={(date) => {
                setSearchObj({
                  ...searchObj,
                  fromQuestionDate: returnDateyyyymmdd(date[0]),
                  toQuestionDate: returnDateyyyymmdd(date[1]),
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">CS처리완료일</label>
            <FlatpickrD
              value={[searchObj?.fromAnswerDate, searchObj?.toAnswerDate]}
              onChange={(date) => {
                setSearchObj({
                  ...searchObj,
                  fromAnswerDate: returnDateyyyymmdd(date[0]),
                  toAnswerDate: returnDateyyyymmdd(date[1]),
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">진행상태</label>
            <SelectD
              value={
                searchObj?.status
                  ? {
                      value: searchObj?.status,
                      label: selectlabel(searchObj?.status, masterOptions?.QNA_STATUS),
                    }
                  : null
              }
              options={masterOptions?.QNA_STATUS}
              onChange={({ value }) => {
                setSearchObj({
                  ...searchObj,
                  status: value,
                });
              }}
            />
          </Col>
          <Col>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                fetchList(searchObj);
              }}
            >
              조회
            </button>
          </Col>
          <Col />
          <Col />
          <Col />
          <Col />
        </Row>
      </Form>
    </>
  );
};
