import { httpClient } from 'common/http-client/axiosConfig';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import CommonModalNew from 'components/modal/commonModalNew';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { ImageModal } from 'pages/IPGO/1purchaseOrder/purchaseOrderTab/puchaseOrder/component/photos/imageModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Table } from 'reactstrap';
import { RootState } from 'redux/store';
import { serviceStore } from 'services/services';
import CloseImg from 'assets/images/close/cross.png';
import { handleFileReturnImage, uploadFile } from 'common/util/photo';
import { CounselDTO } from 'pages/ORDER/1order/orderListTab/_interface/order';
import { FileApiDTO } from 'interface/util';
import { setLoading } from 'redux/services/menuSlice';

export const CallCenterModal = (props) => {
  // options
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { invoiceDetail, visible, setVisible } = props;
  const [callCenterList, setCallCenterList] = useState<CounselDTO[]>();

  const dispatch = useDispatch();

  useEffect(() => {
    counselfetchList();
  }, [invoiceDetail]);

  const counselfetchList = async () => {
    const data = (await serviceStore?.orderAction(`detail/counsel/${invoiceDetail?.invoiceSeq}`, 'GET', null))?.data;
    if (data) {
      const files = data?.map((ele) => ele.files);
      setFiles(files);
      setCallCenterList(data);
    }
  };

  const [files, setFiles] = useState<(FileApiDTO | any)[][]>();

  const handleAddCounsel = () => {
    setCallCenterList([...callCenterList, {}]);
    setFiles([...files, []]);
  };
  const deleteImg = (counselIdx, photoIdx, files) => {
    const newFiles = [];
    files?.forEach((row, i) => {
      if (i === counselIdx) {
        newFiles.push(row.filter((ele, ie) => photoIdx !== ie));
      } else newFiles.push(row);
    });
    return newFiles;
  };
  const deleteFiles = (counselIdx, photoIdx, files) => {
    //
    const newFiles = deleteImg(counselIdx, photoIdx, files);
    saveAPI(callCenterList, false, newFiles);
  };

  const handleFileRemove = async (e) => {
    const [counselIdx, photoIdx] = e.target.id.split('-')?.map((ele) => Number(ele));
    if (callCenterList[counselIdx]?.counselSeq) {
      if (files?.[counselIdx]?.[photoIdx]?.ret) {
        const newFiles = deleteImg(counselIdx, photoIdx, files);
        setFiles(newFiles);
      } else {
        if (window.confirm('사진을 정말 삭제하시겠습니까?')) deleteFiles(counselIdx, photoIdx, files);
      }
    } else {
      const newFiles = deleteImg(counselIdx, photoIdx, files);
      setFiles(newFiles);
    }
  };

  // createObjectURL을 사용해서 이미지 업로드 후 미리보기
  // 업로드 => 사진 미리보기 => 최종 저장 => 파일 업로드
  // imageURL string type
  // imageFile File type
  // 1) FileReader를 사용하는 방법 : 이미지를 DataURI로 변경
  //  (첨부된 이미지 처럼 아주 수많은 알파벳 구성으로 변환 됨)
  // 이미지 등의 외부 바이너리 파일을 웹페이지에 인라인으로 넣기 위해 사용.
  // 컴퓨터 파일로 컴퓨터 저장과 처리 목적을 위해 이진 형식으로 인코딩된 데이터파일 (ex 이미지를 메모장으로 열었을때)
  // 2) createObjectUrl을 사용하는 방법 : 이미지를 blob형태로 변경
  // blob; js 에서 이미지, 사운드, 비디오 등 멀티 데이터를 다룰 때 사용
  // URL.createObjectURL() 메소드는 주어진 객체를 가리키는 URL을 DOMString으로 변환하는 기능을 한다.
  // 해당 url은 window 창이 사라지면 함께 사라지고, 다른 windowd에서 재 사용이 불가능 하고 이 URL은 수명이 한정되어 미리보기에 적합!

  const handleFilesUpload = async (e) => {
    const idx = Number(e.target.id?.split('_')[1]);
    const returns = await handleFileReturnImage(e);
    if (returns?.length > 0) {
      if (files?.[idx]?.length + returns?.length > 5) {
        alert('한개의 상담이력에는 5개 까지의 사진만 업로드 할 수 있습니다!');
      } else {
        // 상담이력만큼 돌린다
        const newFiles = [...files];
        callCenterList?.forEach((row, i) => {
          if (i === idx) {
            const tempF = returns?.map((ele, pi) => {
              return {
                ...ele,
                uploadUrl: ele.image,
                column: `COUNSEL${i}-${pi}`,
              };
            });
            newFiles[i] = [...files?.[i], ...tempF];
          }
        });
        setFiles(newFiles);
      }
    }
  };
  const validator = (items) => {
    const valid = items?.filter((ele) => ele.counselor);
    if (valid?.length > 0) {
      return valid;
    } else {
      alert('*접수자(상담자)는 필수내역입니다!');
    }
    return valid;
  };
  // 상담 저장
  const handleCounselListSave = async (items) => {
    const valid = validator(items);
    if (valid?.length > 0) {
      const data = valid?.map((row, i) => {
        return {
          ...row,
          invoiceSeq: invoiceDetail?.invoiceSeq,
          orderSeq: invoiceDetail?.orderSeq,
          solveFlag: row?.solveFlag ? true : false,
        };
      });
      saveAPI(data);
    }
  };

  // 상담 완료로 상태변경
  const handleCounselListSolve = async (index) => {
    const data = callCenterList?.map((row, i) => {
      return {
        ...row,
        solveFlag: i === index ? true : row.solveFlag,
      };
    });
    saveAPI(data);
  };
  const deleteAPI = async (data) => {
    const rs = await serviceStore.orderAction(`counsel/save`, 'POST', null, {
      delete: data?.map((ele) => {
        return {
          counselSeq: ele.counselSeq,
        };
      }),
    });
    if (rs?.status === 200) {
      alert('상담내역이 삭제되었습니다.');
      counselfetchList();
    }
  };

  const saveAPI = async (data, needFetch = true, originFiles = files) => {
    // files 얹음
    const bakedFiles = [];
    for (let fileArr of originFiles) {
      let count = 0;
      const rareRow = [];
      for (let file of fileArr) {
        if (file?.ret) {
          const uploadUrl = await uploadFile(file?.file, file?.ret?.fileUrl);
          rareRow?.push({ column: `COUNSEL${count + 1}`, uploadUrl });
        } else rareRow?.push(file);
        count += 1;
      }
      const baked = await Promise.all(rareRow);
      bakedFiles?.push(baked);
    }
    setFiles(originFiles);

    const concatDTO = data?.map((row, ri) => {
      return {
        ...row,
        files: bakedFiles?.[ri],
      };
    });
    const add = concatDTO?.filter((ele) => !ele.counselSeq);
    const update = concatDTO?.filter((ele) => ele.counselSeq);
    const rs = await serviceStore.orderAction(`counsel/save`, 'POST', null, {
      add,
      update,
    });
    if (rs?.status === 200) {
      if (needFetch) {
        alert('상담내역이 저장되었습니다.');
        counselfetchList();
      }
    }
  };

  const handleInputEvent = (id: string, value, idx: number) => {
    if (id) {
      const counselList = callCenterList?.map((row, ri) => {
        if (idx === ri)
          return {
            ...row,
            [id]: value,
          };
        return row;
      });
      setCallCenterList(counselList);
    }
  };

  // 상담 내용 삭제
  const handleCounselListDelete = async (item) => {
    deleteAPI([item]);
  };

  const handleCounselApproveExchangeOrder = async (item) => {
    const searchDTO = {
      counselSeq: item?.counselSeq,
      invoiceSeq: item?.invoiceSeq,
    };

    alert('준비중입니다!');
    return;

    dispatch(setLoading('GET'));
    const data = await serviceStore.orderAction('counsel/exchange/order', 'POST', searchDTO, null, false);
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title="고객 상담"
      setVisible={setVisible}
      visible={visible}
      style={{ width: 1000 }}
      rightTitle={
        <>
          <Button
            className="btn-reload btn"
            onClick={() => {
              handleAddCounsel();
            }}
          >
            상담이력추가
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={(e) => {
              if (window.confirm(`상담내역을 저장하시겠습니까?`)) {
                handleCounselListSave(callCenterList);
              }
            }}
          >
            저장
          </Button>
        </>
      }
      children={
        <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
          {callCenterList?.map((c = {}, index) => {
            return (
              <table className="border-table" key={`order-counsel-table_${index}`}>
                <thead>
                  <tr>
                    <th colSpan={12} style={{ position: 'relative', paddingLeft: 12, textAlign: 'left' }}>
                      <span style={{ lineHeight: '25px', fontSize: 13 }}>
                        <span className={'b'}> 상담이력 {index + 1}</span> <span>{c?.counselSeq && `(${c.solveFlag === true ? '완료' : '미완료'})`}</span>
                      </span>
                      <span style={{ float: 'right' }}>
                        {c?.counselSeq && !c.solveFlag && c.counselType === 'EXCHANGE_REFUND' && c.detailType === 'EXCHA' && (
                          <Button className="btn-blue btn ssm" onClick={() => handleCounselApproveExchangeOrder(c)}>
                            교환승인
                          </Button>
                        )}
                        <Button
                          className={`${c?.counselSeq ? 'btn-cancel ssm' : 'btn-etc ssm'}`}
                          onClick={(e) => {
                            if (window.confirm('삭제 하시겠습니까?')) {
                              if (!c.counselSeq) {
                                const newArr = callCenterList?.filter((_, i) => index !== i);
                                setCallCenterList(newArr);
                              } else if (c.counselSeq) {
                                handleCounselListDelete(c);
                              }
                            }
                          }}
                          disabled={c.solveFlag === true ? true : false}
                        >
                          {c?.counselSeq ? '삭제' : '취소'}
                        </Button>
                        {c.counselSeq && !c.solveFlag && (
                          <Button
                            className="btn-blue btn ssm"
                            onClick={(e) => {
                              if (window.confirm('완료 하시겠습니까?')) {
                                handleCounselListSolve(index);
                              }
                            }}
                            disabled={c.solveFlag}
                          >
                            완료처리
                          </Button>
                        )}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: 'rgb(242 242 242)' }}>
                    <td colSpan={12} style={{ textAlign: 'left', paddingLeft: 12, lineHeight: '24px', backgroundColor: '#F5F5F5' }}>
                      고객상담번호 : {c.counselSeq ?? '없음'}
                    </td>
                  </tr>
                  <tr>
                    <th>접수일</th>
                    <td colSpan={3}>
                      <FlatpickrD
                        value={c?.registerDatetime}
                        onChange={(dates) => {
                          handleInputEvent('registerDatetime', returnDateyyyymmdd(dates), index);
                        }}
                        options={{
                          mode: 'single',
                        }}
                      />
                    </td>
                    <th>접수유형</th>
                    <td colSpan={3}>
                      <SelectD
                        value={
                          c?.counselType
                            ? {
                                value: c?.counselType,
                                label: selectlabel(c?.counselType, masterOptions?.COUNSEL_TYPE),
                              }
                            : null
                        }
                        options={masterOptions?.COUNSEL_TYPE}
                        onChange={(option) => {
                          handleInputEvent('counselType', (option as OptionItem).value, index);
                        }}
                      />
                    </td>
                    <th>세부유형</th>
                    <td colSpan={3}>
                      <SelectD
                        value={
                          c?.detailType
                            ? {
                                value: c?.detailType,
                                label: selectlabel(c?.detailType, masterOptions?.COUNSEL_DETAIL_TYPE),
                              }
                            : null
                        }
                        options={masterOptions?.COUNSEL_DETAIL_TYPE}
                        onChange={(option) => {
                          handleInputEvent('detailType', (option as OptionItem).value, index);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      *접수자
                      <br />
                      (상담자)
                    </th>
                    <td colSpan={3}>
                      <InputD
                        value={c?.counselor || ''}
                        onChange={(e) => {
                          handleInputEvent('counselor', e.target.value, index);
                        }}
                      />
                    </td>
                    <th>
                      실무자 /
                      <br />
                      고객사
                    </th>
                    <td colSpan={3}>
                      <InputD
                        value={c.charger || ''}
                        onChange={(e) => {
                          handleInputEvent('charger', e.target.value, index);
                        }}
                      />
                    </td>
                    <th>연락처</th>
                    <td colSpan={3}>
                      <InputD
                        value={c.chargerPhone || ''}
                        onChange={(e) => {
                          const value = e.target.value;
                          let check = /^[0-9\-\b]+$/;
                          if (!check.test(value)) {
                            alert('숫자와 "-"만 입력 가능합니다.');
                          } else {
                            handleInputEvent('chargerPhone', e.target.value, index);
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>접수내용</th>
                    <td colSpan={11}>
                      <textarea
                        value={c.content || ''}
                        onChange={(e) => {
                          handleInputEvent('content', e.target.value, index);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      상담 및 <br />
                      처리내용
                    </th>
                    <td colSpan={11}>
                      <textarea
                        value={c.answer || ''}
                        onChange={(e) => {
                          handleInputEvent('answer', e.target.value, index);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>사진</th>
                    <td colSpan={11}>
                      <div className="grid-wrapper div6">
                        {files?.[index]?.map((file, i) => {
                          return (
                            <Col key={`${index}-${i}`} style={{ position: 'relative' }}>
                              <ImageModal photoArray={files?.[index]} idx={i} title={`사진`} />
                              <label className={'photo-zone'}>
                                <span className="bck-font"> 사진 {i + 1}</span>
                                {file?.uploadUrl && (
                                  <div style={{ display: 'inline-block' }}>
                                    <img src={CloseImg} id={`${index}-${i}`} alt="" onClick={handleFileRemove} className="closeBtn" />
                                  </div>
                                )}
                              </label>
                            </Col>
                          );
                        })}
                        <div className="photo-zone">
                          <span className="bck-font"></span>
                          <input
                            //
                            style={{ display: 'none' }}
                            type="file"
                            multiple
                            accept="image/*"
                            id={`uploads_${index}`}
                            onChange={handleFilesUpload}
                          />
                          <Button size="md" color="primary" outline>
                            <label htmlFor={`uploads_${index}`} style={{ marginBottom: 0 }}>
                              파일 업로드
                            </label>
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          })}
        </div>
      }
    />
  );
};
