// react
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import CommonModalNew from 'components/modal/commonModalNew';
import { serviceStore } from 'services/services';
import * as IGrid from 'aui-grid';
import GridBox, { IPagingGrid } from 'common/grid/gridBox';
import { PurchaseOrderSearchDTO } from '../../../_interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';

export type ApprovePurchaseObjType = {
  visible?: boolean;
  type?: string;
  items?: any[];
  searchObj?: string;
  fetchList?: (searchObj: PurchaseOrderSearchDTO) => void;
};

type ApprovePurchaseModalType = {
  approvePurchaseObj: ApprovePurchaseObjType;
  setApprovePurchaseObj: React.Dispatch<React.SetStateAction<ApprovePurchaseObjType>>;
};

export const ApprovePurchaseModal = (props: ApprovePurchaseModalType) => {
  const { approvePurchaseObj, setApprovePurchaseObj } = props;
  const defaultGridRef = useRef([]);
  const dispatch = useDispatch();

  const modalGridRef = useRef<IPagingGrid>();
  const columns: IGrid.Column[] = [
    {
      headerText: '발주번호',
      dataField: 'purchaseSeq',
    },
    {
      headerText: '작업유형',
      dataField: 'workTypeKr',
    },
    {
      headerText: '파렛트수량',
      dataField: 'palletQuantity',
      editable: true,
      editRenderer: {
        type: IGrid.EditRendererKind.NumberStepRenderer,
        min: 1,
        step: 1,
        textEditable: true,
        inputHeight: 24,
        inputMode: 'numeric',
      },
    },
  ];

  const submitApprovePurchase = async () => {
    const invalidPalletItems = approvePurchaseObj?.items?.filter((item) => item.workType === 'PALLET' && (!item.palletQuantity || item.palletQuantity < 1));

    if (invalidPalletItems?.length > 0) {
      alert('입력되지 않은 파렛트 수량이 있습니다!');
      return;
    }

    const dataDTO = approvePurchaseObj?.items?.map((ele) => {
      return {
        purchaseSeq: ele.purchaseSeq,
        palletQuantity: ele.palletQuantity ?? null,
      };
    });

    dispatch(setLoading('GET'));
    const rs = await serviceStore.warehouseAction(`purchase/close`, 'POST', null, dataDTO);
    if (rs?.status === 200) {
      alert(rs?.data?.message ?? '발주마감 승인 되었습니다.');

      approvePurchaseObj?.fetchList(JSON.parse(approvePurchaseObj?.searchObj));
      setApprovePurchaseObj({ visible: false });
    }
    dispatch(setLoading(null));
  };

  const bindGridEvents = () => {
    modalGridRef.current.bind('cellEditEnd', (e: IGrid.CellEditEndEvent) => {
      if (e.item?.workType === 'PALLET') {
        const updatedItems = modalGridRef?.current?.getGridData();
        setApprovePurchaseObj((prev) => {
          return {
            ...prev,
            items: updatedItems,
          };
        });
      } else {
        alert('작업유형이 "파렛트"인 경우에만 수량을 변경할 수 있습니다!');
        setDefaultGridFunc();
      }
    });
  };
  const setDefaultGridFunc = () => {
    const data = approvePurchaseObj?.items?.map((item) => {
      let result = { ...item };
      if (item?.logisticType === 'NONE' && item?.workType === 'PALLET' && !item?.palletQuantity) {
        result = {
          ...result,
          palletQuantity: 0,
        };
      }
      return result;
    });

    defaultGridRef.current = data;
    modalGridRef?.current?.setGridData(data);
  };

  useEffect(() => {
    setDefaultGridFunc();
    bindGridEvents();
  }, [approvePurchaseObj?.items]);

  return (
    <CommonModalNew
      title={'발주마감'}
      style={{ width: 360 }}
      visible={approvePurchaseObj?.visible}
      setVisible={() => {
        setApprovePurchaseObj({ visible: false });
      }}
      rightTitle={
        <>
          <button className="btn btn-orange ssm" onClick={() => submitApprovePurchase()}>
            발주마감
          </button>
        </>
      }
      children={
        <>
          <div style={{ marginBottom: 24 }}>
            <div style={{ marginBottom: 30 }}>
              <GridBox
                gridRef={modalGridRef}
                columns={approvePurchaseObj?.items?.filter((item) => item.workType === 'PALLET')?.length > 0 ? columns : columns.filter((_, index) => index < 2)}
                gridProps={{
                  editable: true,
                  showRowCheckColumn: false,
                  height: 300,
                }}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              총 <span className="b">{approvePurchaseObj?.items?.length}</span>건에 대하여 발주마감을 진행합니다.
            </div>
          </div>
        </>
      }
    />
  );
};
