import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { LocationListDTO, LocationSearchDTO, LocationCreateDTO } from 'interface/warehouse';
import { centerService } from 'pages/ETC/2center/_service/service';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';
import { Col, Row } from 'reactstrap';
import { serviceStore } from 'services/services';

interface LocationListDTOE extends LocationListDTO {
  settingsStr?: string;
}

export interface IDetailObj {
  visible: boolean;
  item?: LocationListDTOE;
  searchObj?: string;
  fetchFn?: (data: LocationSearchDTO) => {};
}

interface IDetailPoup {
  detailObj: IDetailObj;
  setDetailObj: React.Dispatch<React.SetStateAction<IDetailObj>>;
}

export const DetailPopup = ({ detailObj, setDetailObj }: IDetailPoup) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [locationInfo, setLocationInfo] = useState<LocationListDTO>();

  useEffect(() => {
    if (detailObj?.item !== null) {
      const data = detailObj?.item;
      if (data.settingsStr) data.settings = JSON.parse(data.settingsStr as string);
      setLocationInfo(data);
    } else {
      // 생성시
      setLocationInfo({
        settings: [],
        useFlag: true,
        pickFlag: false,
        centerCode: JSON.parse(detailObj?.searchObj).centerCode,
      });
    }
  }, []);

  const validation = (locationInfo: LocationListDTO) => {
    if (!locationInfo?.centerCode) {
      alert('창고는 필수값입니다!');
      return false;
    }
    if (!locationInfo?.locationCode) {
      alert('로케이션 코드는 필수값입니다!');
      return false;
    }
    if (!locationInfo?.locationType) {
      alert('로케이션 유형은 필수값입니다!');
      return false;
    }

    if (!locationInfo?.locationSize) {
      alert('로케이션 규격은 필수값입니다!');
      return false;
    }

    if (!locationInfo?.locationCategory) {
      alert('로케이션 구분은 필수값입니다!');
      return false;
    }

    if (!locationInfo?.columnCode) {
      alert('행코드는 필수값입니다!');
      return false;
    }

    if (!locationInfo?.rowCode) {
      alert('열코드는 필수값입니다!');
      return false;
    }

    if (!locationInfo?.floorCode) {
      alert('층코드는 필수값입니다!');
      return false;
    }

    return true;
  };

  const saveAPI = async (locationInfo: LocationListDTO) => {
    if (validation(locationInfo)) {
      if (window.confirm(`${detailObj?.item ? '수정' : '등록'}하시겠습니까?`)) {
        dispatch(setLoading('POST'));
        const data: LocationCreateDTO = {
          centerCode: locationInfo.centerCode,
          locationCode: locationInfo?.locationCode,
          locationType: locationInfo?.locationType,
          locationSize: locationInfo?.locationSize,
          locationCategory: locationInfo?.locationCategory,
          settings: locationInfo?.settings ?? [],
          pickFlag: locationInfo?.pickFlag,
          useFlag: locationInfo?.useFlag,
          columnCode: locationInfo?.columnCode,
          rowCode: locationInfo?.rowCode,
          floorCode: locationInfo?.floorCode,
        };

        let rs;
        if (detailObj?.item) {
          // 수정
          rs = await serviceStore.warehouseAction(`location/update`, 'POST', null, data, false);
        } else {
          rs = await serviceStore.warehouseAction(`location/create`, 'POST', null, data, false);
        }
        if (rs?.status === 200) {
          alert(`로케이션 정보를 ${detailObj?.item ? '수정' : '등록'}하였습니다!`);
          if (detailObj?.searchObj) {
            const searchObj = JSON.parse(detailObj?.searchObj);
            detailObj.fetchFn(searchObj);
            setDetailObj(null);
          }
        }
        dispatch(setLoading(null));
      }
    }
  };

  return (
    <CommonModalNew
      title={`로케이션 ${!detailObj?.item ? '등록' : '수정'}`}
      style={{ width: 480 }}
      visible={detailObj?.visible}
      setVisible={() => {
        setDetailObj(null);
      }}
      children={
        <div style={{ pointerEvents: detailObj?.fetchFn ? 'unset' : 'none' }}>
          <Row>
            <Col>
              <label className="col-form-label">창고명</label>
              <SelectD
                isDisabled
                value={
                  locationInfo?.centerCode
                    ? {
                        value: locationInfo?.centerCode,
                        label: MASTER_OBJ?.CENTER_AUTH?.[locationInfo?.centerCode],
                      }
                    : null
                }
                options={MASTER_OPS?.CENTER_AUTH}
                onChange={(option) => {
                  setLocationInfo({
                    ...locationInfo,
                    centerCode: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">로케이션코드</label>
              <InputD
                disabled={detailObj?.item !== null}
                value={locationInfo?.locationCode || ''}
                onChange={(e) => {
                  setLocationInfo({
                    ...locationInfo,
                    locationCode: e.target.value,
                  });
                }}
              />
            </Col>
            <Col />
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">행코드</label>
              <InputD
                disabled={detailObj?.item !== null}
                value={locationInfo?.columnCode || ''}
                onChange={(e) => {
                  setLocationInfo({
                    ...locationInfo,
                    columnCode: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">열코드</label>
              <InputD
                disabled={detailObj?.item !== null}
                value={locationInfo?.rowCode || ''}
                onChange={(e) => {
                  setLocationInfo({
                    ...locationInfo,
                    rowCode: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">층코드</label>
              <InputD
                disabled={detailObj?.item !== null}
                value={locationInfo?.floorCode || ''}
                onChange={(e) => {
                  setLocationInfo({
                    ...locationInfo,
                    floorCode: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">로케이션유형</label>
              <SelectD
                value={
                  locationInfo?.locationType
                    ? {
                        value: locationInfo?.locationType,
                        label: MASTER_OBJ?.LOCATION_TYPE?.[locationInfo?.locationType],
                      }
                    : null
                }
                options={MASTER_OPS?.LOCATION_TYPE}
                onChange={(option) => {
                  setLocationInfo({
                    ...locationInfo,
                    locationType: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">로케이션구분</label>
              <SelectD
                value={
                  locationInfo?.locationCategory
                    ? {
                        value: locationInfo?.locationCategory,
                        label: MASTER_OBJ?.LOCATION_CATEGORY?.[locationInfo?.locationCategory],
                      }
                    : null
                }
                options={MASTER_OPS?.LOCATION_CATEGORY}
                onChange={(option) => {
                  setLocationInfo({
                    ...locationInfo,
                    locationCategory: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col>
              <label className="col-form-label">로케이션규격</label>
              <SelectD
                value={
                  locationInfo?.locationSize
                    ? {
                        value: locationInfo?.locationSize,
                        label: MASTER_OBJ?.LOCATION_SIZE?.[locationInfo?.locationSize],
                      }
                    : null
                }
                options={MASTER_OPS?.LOCATION_SIZE}
                onChange={(option) => {
                  setLocationInfo({
                    ...locationInfo,
                    locationSize: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
          </Row>
          <div>
            <label className="col-form-label">입출고설정</label>
            <SelectD
              isMulti
              height100
              value={locationInfo?.settings?.map((ele) => {
                return {
                  value: ele,
                  label: MASTER_OBJ?.LOCATION_SETTING?.[ele],
                };
              })}
              options={MASTER_OPS?.LOCATION_SETTING}
              onChange={(options) => {
                setLocationInfo({
                  ...locationInfo,
                  settings: (options as OptionItem[]).map((ele) => ele.value),
                });
              }}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <label className="col-form-label">피킹할당여부</label>
              <div style={{ display: 'flex', height: 30, alignItems: 'center', justifyContent: 'center' }}>
                {MASTER_OPS?.PICK_FLAG?.map((ele, idx) => {
                  return (
                    <span key={`pixk_${idx}`} style={{ display: 'inline-flex', width: 50, justifyContent: 'space-evenly' }}>
                      <label>{ele.label}</label>
                      <input
                        type="radio"
                        checked={locationInfo?.pickFlag + '' === ele.value}
                        onChange={() => {
                          setLocationInfo((prev) => {
                            return {
                              ...prev,
                              pickFlag: ele.value,
                            };
                          });
                        }}
                      />
                    </span>
                  );
                })}
              </div>
            </div>
            <div style={{ width: '50%' }}>
              <label className="col-form-label">운영상태</label>
              <div style={{ display: 'flex', height: 30, justifyContent: 'center' }}>
                {MASTER_OPS?.USE_FLAG?.map((ele, idx) => {
                  return (
                    <span key={`use_${idx}`} style={{ display: 'inline-flex', width: 50, justifyContent: 'space-evenly' }}>
                      <label>{ele.label}</label>
                      <input
                        type="radio"
                        checked={locationInfo?.useFlag + '' === ele.value}
                        onChange={() => {
                          setLocationInfo((prev) => {
                            return {
                              ...prev,
                              useFlag: ele.value,
                            };
                          });
                        }}
                      />
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'right', marginTop: 20 }}>
            <button
              className="btn btn-secondary sm"
              onClick={() => {
                saveAPI(locationInfo);
              }}
            >
              {!detailObj?.item ? '등록' : '수정'}
            </button>
          </div>
        </div>
      }
    />
  );
};
