// react
import { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'reactstrap';
import CommonModalNew from 'components/modal/commonModalNew';

import Barcode from 'react-barcode';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { httpClient } from 'common/http-client/axiosConfig';
import { InvoiceDTOForOrderSheetPrint } from 'interface/warehouse';
import { setLoading } from 'redux/services/menuSlice';

import ReactToPrint from 'react-to-print';

export interface IPrintObj {
  visible: boolean;
  items: InvoiceDTOForOrderSheetPrint[];
}

export const Print = ({ printObj, setPrintObj }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState<InvoiceDTOForOrderSheetPrint[]>();

  const wrapperRef = useRef();
  useEffect(() => {
    if (printObj?.items) {
      const hasInvoice = printObj?.items?.filter((ele) => ele.invoiceSeq);
      if (hasInvoice?.length !== printObj?.items?.length) alert('송장정보가 없는 건은 제외됩니다');
      if (hasInvoice?.length > 0) {
        getPrints(hasInvoice);
      } else {
        alert('출력할 정보가 없습니다');
        setPrintObj(null);
      }
    }
  }, []);

  const getPrints = async (invoices) => {
    dispatch(setLoading('GET'));
    const data = {
      stockRemoveSeq: invoices[0].stockRemoveSeq,
      invoiceSeqs: invoices.map((ele) => ele.invoiceSeq),
    };
    const rs = await httpClient.post(`/warehouse/remove/picking/detail/order/sheet/print`, data);
    if (rs?.status === 200) {
      setInvoices(rs?.data);
    }
    dispatch(setLoading(null));
  };
  return (
    <>
      {invoices && (
        <CommonModalNew
          //
          title="피킹지시서(주문서)"
          style={{ width: 900 }}
          visible={printObj?.visible}
          setVisible={() => {
            setPrintObj(null);
          }}
          rightTitle={
            <>
              <ReactToPrint trigger={() => <button>출력</button>} content={() => wrapperRef.current} />
            </>
          }
          children={
            <>
              <div ref={wrapperRef}>
                {invoices?.map((ele, idx) => {
                  return (
                    <div style={{ padding: 30 }} key={`invoice_${idx}`} className="break-for-onePage-print">
                      <Row>
                        <Col />
                        <Col className="text-center">
                          <div className="printTitle" style={{ fontSize: 20 }}>
                            주 문 서
                          </div>
                        </Col>
                        <Col>{ele?.invoiceSeq && <Barcode width={1.5} value={ele?.invoiceSeq + ''} height={40} />}</Col>
                      </Row>

                      <div className="presenterInput">
                        <p>1. 주문 정보</p>
                        <table className="border-table">
                          <thead>
                            <tr>
                              <th colSpan={2}>주문번호</th>
                              <td colSpan={4}>{ele.orderSeq}</td>
                              <th colSpan={2}>지정일</th>
                              <td colSpan={4}>{ele.deliveryDate}</td>
                            </tr>
                            <tr>
                              <th colSpan={2}>외부주문번호</th>
                              <td colSpan={4}>{ele.externalOrderNumber}</td>
                              <th colSpan={2}>고객명</th>
                              <td colSpan={4}>{ele.receiverName}</td>
                            </tr>
                            <tr>
                              <th colSpan={2}>송장번호</th>
                              <td colSpan={4}>{ele.invoiceSeq}</td>
                              <th colSpan={2}>연락처</th>
                              <td colSpan={4}>{ele.receiverPhone}</td>
                            </tr>
                            <tr>
                              <th colSpan={2}>외부송장번호</th>
                              <td colSpan={4}>{ele.externalInvoiceNumber}</td>
                              <th colSpan={2}>주소</th>
                              <td colSpan={4}>
                                {ele.receiverAddr1} {ele.receiverAddr2}
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>

                      <div className="presenterInput">
                        <p>2. 제품 정보</p>
                        <table className="border-table">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th colSpan={2}>로케이션</th>
                              <th colSpan={2}>제품</th>
                              <th colSpan={3}>제품명</th>
                              <th colSpan={2}>바코드</th>
                              <th colSpan={2}>SKU</th>
                              <th colSpan={2}>외부SKU</th>
                              <th>수량</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ele.inventories?.map((m, idx) => {
                              return (
                                <tr key={`product_${idx}`}>
                                  <th>{idx + 1}</th>
                                  <th colSpan={2}>{m?.locationCode}</th>
                                  <th colSpan={2}>{m?.model}</th>
                                  <th colSpan={3}>{m?.modelName}</th>
                                  <th colSpan={2}>{m?.barcode}</th>
                                  <th colSpan={2}>{m?.modelStockSeq}</th>
                                  <th colSpan={2}>{m?.skuNumber}</th>
                                  <th>{m?.quantity}</th>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          }
        />
      )}
    </>
  );
};
