import { Col, Form, Row } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, OptionItem, SelectD } from 'components/reactstrap/reactstrap';

import { KeyboardEvent, useEffect, useState } from 'react';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { AnnouncementListSearchDTO } from 'interface/user';
import { selectlabel } from 'common/master/codeMasterReturnHelper';

export const SearchBox = (props: ISearchBoxProps<AnnouncementListSearchDTO>) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">등록일</label>
          <FlatpickrD
            value={[searchObj?.fromRegisterDate, searchObj?.toRegisterDate]}
            onReset={() => {
              setSearchObj((prev) => {
                return {
                  ...prev,
                  fromRegisterDate: null,
                  toRegisterDate: null,
                };
              });
            }}
            onChange={(date) => {
              setSearchObj({
                ...searchObj,
                fromRegisterDate: returnDateyyyymmdd(date[0]),
                toRegisterDate: returnDateyyyymmdd(date[1]),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">대상</label>
          <SelectD
            isMulti
            value={searchObj?.types?.map((ele) => {
              return {
                value: ele,
                label: selectlabel(ele, masterOptions?.ANNOUNCE_TYPE),
              };
            })}
            options={masterOptions?.ANNOUNCE_TYPE}
            onChange={(options) => {
              setSearchObj({
                ...searchObj,
                types: (options as OptionItem[]).map((ele) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">제목</label>
          <InputD
            value={searchObj?.title || ''}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                title: e.target.value,
              });
            }}
          />
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <button
            className="btn btn-search"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
        {/* <Col>
          <label className="col-form-label">구분</label>
          <SelectD
            isMulti
            value={searchObj?.category}
            options={masterOptions?.ANNOUNCE_CATEGORY}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                category: option,
              });
            }}
          />
        </Col> */}
        <Col />
        <Col />
      </Row>
    </Form>
  );
};
