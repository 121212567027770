import axios from 'axios';
import { useEffect, useState } from 'react';
import { IMS_DRIVER_LIST, INVENTORY_INOUT_CENTER, INVENTORY_INOUT_CENTER_LIST, INVENTORY_INOUT_CENTER_MODEL_MODAL } from 'envVar';
import Presenter from './presenter';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';
import { v4 as uuidv4 } from 'uuid';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { centerListForlabeling } from 'common/master/codeMasterOptionReturn';

const Container = () => {
  const token = localStorage.getItem('accessToken');

  const { masterOptions } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const [gridId, setGrid] = useState('');
  const [ipgoGridId, setIpgoGridId] = useState('');
  const [chulgoGridId, setChulgoGridId] = useState('');
  const [gisaChulgoGridId, setGisaChulgoGridId] = useState('');

  const [modalType, setModalType] = useState();

  const [rows, setRows] = useState([]);
  const [rowsIpgo, setRowsIpgo] = useState();
  const [rowsChulgo, setRowsChulgo] = useState();
  const [rowsGisaChulgo, setRowsGisaChulgo] = useState();
  const [rowsOnModal, setRowsOnModal] = useState();

  const [ipgoColumns, setIpgoColumns] = useState();
  const [chulgoColumns, setChulgoColumns] = useState();
  const [gisaChulgoColumns, setGisaChulgoColumns] = useState();

  const [ipchulFooterLayout, setIpchulFooterLayout] = useState();
  const [gisaFooterLayout, setGisaFooterLayout] = useState();

  const [modalVisible, setModalVisible] = useState(false);

  const [listCount, setlistCount] = useState('50');
  const [listCount2, setlistCount2] = useState('50');

  const [exportExcelThisGrid, setExportExcelThisGrid] = useState({});

  // search
  const [searchObj, setSearchObj] = useState({});
  const [searchDate, setSearchDate] = useState();
  const [searchCenter, setSearchCenter] = useState();

  const footerLayout = [
    {
      dataField: 'purchaseOrderQuantity',
      positionField: 'purchaseOrderQuantity',
      operation: 'SUM',
      style: 'aui-grid-my-column',
      formatString: '#,##0',
    },
    {
      dataField: 'linehaulInQuantity',
      positionField: 'linehaulInQuantity',
      operation: 'SUM',
      style: 'aui-grid-my-column',
      formatString: '#,##0',
    },
    {
      dataField: 'collectQuantity',
      positionField: 'collectQuantity',
      operation: 'SUM',
      style: 'aui-grid-my-column',
      formatString: '#,##0',
    },
    {
      dataField: 'moveInQuantity',
      positionField: 'moveInQuantity',
      operation: 'SUM',
      style: 'aui-grid-my-column',
      formatString: '#,##0',
    },
    {
      dataField: 'removeQuantity',
      positionField: 'removeQuantity',
      operation: 'SUM',
      style: 'aui-grid-my-column',
      formatString: '#,##0',
    },
    {
      dataField: 'linehaulOutQuantity',
      positionField: 'linehaulOutQuantity',
      operation: 'SUM',
      style: 'aui-grid-my-column',
      formatString: '#,##0',
    },
    {
      dataField: 'loanQuantity',
      positionField: 'loanQuantity',
      operation: 'SUM',
      style: 'aui-grid-my-column',
      formatString: '#,##0',
    },
    {
      dataField: 'moveOutQuantity',
      positionField: 'moveOutQuantity',
      operation: 'SUM',
      style: 'aui-grid-my-column',
      formatString: '#,##0',
    },
    {
      dataField: 'availableToDefectedModificationQuantity',
      positionField: 'availableToDefectedModificationQuantity',
      operation: 'SUM',
      style: 'aui-grid-my-column',
      formatString: '#,##0',
    },
    {
      dataField: 'defectedToAvailableModificationQuantity',
      positionField: 'defectedToAvailableModificationQuantity',
      operation: 'SUM',
      style: 'aui-grid-my-column',
      formatString: '#,##0',
    },
    {
      dataField: 'currentQuantity',
      positionField: 'currentQuantity',
      operation: 'SUM',
      style: 'aui-grid-my-column',
      formatString: '#,##0',
    },
  ];

  async function initDetailColumns(driversArr) {
    const centerOps = await centerListForlabeling().map((ele) => {
      return {
        value: ele.centerCode,
        label: ele.centerName,
      };
    });
    const columns1 = [
      {
        headerText: '제품명',
        dataField: 'modelName',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '바코드',
        dataField: 'barcode',
      },
      {
        headerText: '발주입고',
        dataField: 'purchaseOrderQuantity',
      },
    ];
    const columns2 = [
      {
        headerText: '제품명',
        dataField: 'modelName',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '바코드',
        dataField: 'barcode',
      },
    ];
    const columns3 = [
      {
        headerText: '제품명',
        dataField: 'modelName',
        filter: {
          showIcon: true,
        },
      },
      {
        headerText: '바코드',
        dataField: 'barcode',
      },
    ];
    const detailFooter = [
      {
        dataField: 'purchaseOrderQuantity',
        positionField: 'purchaseOrderQuantity',
        operation: 'SUM',
        style: 'aui-grid-my-column',
        formatString: '#,##0',
      },
    ];
    const detailFooter2 = [];
    centerOps.forEach((ele) => {
      columns1.push({
        headerText: ele.label,
        dataField: ele.value,
      });
      columns2.push({
        headerText: ele.label,
        dataField: ele.value,
      });
      detailFooter.push({
        dataField: ele.value,
        positionField: ele.value,
        operation: 'SUM',
        style: 'aui-grid-my-column',
        formatString: '#,##0',
      });
    });

    driversArr.forEach((ele) => {
      columns3.push({
        headerText: ele.label,
        dataField: ele.value,
      });
      detailFooter2.push({
        dataField: ele.value,
        positionField: ele.value,
        operation: 'SUM',
        style: 'aui-grid-my-column',
        formatString: '#,##0',
      });
    });

    setIpgoColumns(columns1);
    setChulgoColumns(columns2);
    setGisaChulgoColumns(columns3);
    setIpchulFooterLayout(detailFooter);
    setGisaFooterLayout(detailFooter2);
  }

  async function onCellClick(event) {
    const items = event.item;
    if (event.pid.includes('dailyCenterReport')) {
      const drivers = await fetchDriver(items['search_center']);
      initDetailColumns(drivers);
      // 입고 상세
      let url = INVENTORY_INOUT_CENTER + '?';
      url += `&type=INBOUND`;
      if (items['search_center']) url += `&centerCode=${items['search_center']}`;
      if (items['search_modelGroup']) url += `&modelGroup=${items['search_modelGroup']}`;
      if (items['search_modelType']) url += `&modelType=${items['search_modelType']}`;
      if (items['search_partnerSeq']) url += `&partnerSeq=${items['search_partnerSeq']}`;
      if (items['search_modelGroup']) url += `&modelGroup=${items['search_modelGroup']}`;
      if (items['search_modelName']) url += `&modelName=${items['search_modelName']}`;
      if (items['search_barcode']) url += `&centerCodes=${items['search_barcode']}`;
      if (items['search_fromInOutDate']) url += `&fromInOutDate=${items['search_fromInOutDate']}`;
      if (items['search_toInOutDate']) url += `&toInOutDate=${items['search_toInOutDate']}`;

      const centerOps = (await centerListForlabeling()).map((ele) => {
        return {
          value: ele.centerCode,
          label: ele.centerName,
        };
      });

      httpClient.get(url).then((rs) => {
        if (rs?.status === 200) {
          const data = [];
          rs.data.forEach((ele) => {
            ele['purchaseOrderQuantity'] = ele.quantities['PURCHASE'];
            centerOps.forEach((center) => {
              ele[center.value] = ele.quantities[center.value];
            });
            data.push(ele);
          });
          setRowsIpgo(data);
        }
      });

      // 출고 상세
      let url2 = INVENTORY_INOUT_CENTER + '?';
      url2 += `&type=OUTBOUND`;
      if (items['search_center']) url2 += `&centerCode=${items['search_center']}`;
      if (items['search_modelGroup']) url2 += `&modelGroup=${items['search_modelGroup']}`;
      if (items['search_modelType']) url2 += `&modelType=${items['search_modelType']}`;
      if (items['search_partnerSeq']) url2 += `&partnerSeq=${items['search_partnerSeq']}`;
      if (items['search_modelGroup']) url2 += `&modelGroup=${items['search_modelGroup']}`;
      if (items['search_modelName']) url2 += `&modelName=${items['search_modelName']}`;
      if (items['search_barcode']) url2 += `&centerCodes=${items['search_barcode']}`;
      if (items['search_fromInOutDate']) url2 += `&fromInOutDate=${items['search_fromInOutDate']}`;
      if (items['search_toInOutDate']) url2 += `&toInOutDate=${items['search_toInOutDate']}`;

      httpClient.get(url2).then((rs) => {
        if (rs?.status === 200) {
          const data = [];
          rs.data.forEach((ele) => {
            centerOps.forEach((center) => {
              ele[center.value] = ele.quantities[center.value];
            });
            data.push(ele);
          });
          setRowsChulgo(data);
        }
      });

      // 기사 출고
      let url3 = process.env.REACT_APP_API_URL + INVENTORY_INOUT_CENTER + '?';
      url3 += `&type=DRIVERLOAN`;
      if (items['search_center']) url3 += `&centerCode=${items['search_center']}`;
      if (items['search_modelGroup']) url3 += `&modelGroup=${items['search_modelGroup']}`;
      if (items['search_modelType']) url3 += `&modelType=${items['search_modelType']}`;
      if (items['search_partnerSeq']) url3 += `&partnerSeq=${items['search_partnerSeq']}`;
      if (items['search_modelGroup']) url3 += `&modelGroup=${items['search_modelGroup']}`;
      if (items['search_modelName']) url3 += `&modelName=${items['search_modelName']}`;
      if (items['search_barcode']) url3 += `&centerCodes=${items['search_barcode']}`;
      if (items['search_fromInOutDate']) url3 += `&fromInOutDate=${items['search_fromInOutDate']}`;
      if (items['search_toInOutDate']) url3 += `&toInOutDate=${items['search_toInOutDate']}`;

      httpClient.get(url3).then((rs) => {
        if (rs?.status === 200) {
          const data = [];
          rs.data.forEach((ele) => {
            drivers.forEach((center) => {
              ele[center.value] = ele.quantities[center.value];
            });
            data.push(ele);
          });
          setRowsGisaChulgo(data);
        }
      });
    } else if (event.pid.includes('linehaul_ipgo')) {
      if (event.dataField === 'purchaseOrderQuantity') {
        fetchDetail('PURCHASE');
      } else {
        fetchDetail('LINEHAULIN');
      }
      setModalVisible(true);
    } else if (event.pid.includes('linehaul_chulgo')) {
      fetchDetail('LINEHAULOUT');
      setModalVisible(true);
    } else if (event.pid.includes('linehaul_gisaChulgo')) {
      fetchDetail('DRIVERLOAN', event.dataField);
      setModalVisible(true);
    }
  }

  const fetchDetail = async (type, driverId) => {
    setRowsOnModal([]);
    setModalType(type);
    try {
      let url = process.env.REACT_APP_API_URL + INVENTORY_INOUT_CENTER_MODEL_MODAL + `?type=${type}`;
      if (searchDate) url += `&fromInOutDate=${searchDate}&toInOutDate=${searchDate}`;
      if (searchCenter) url += `&centerCode=${searchCenter.value}`;
      if (searchObj) {
        if (searchObj.modelGroup) url += `&modelGroup=${searchObj.modelGroup.value}`;
        if (searchObj.modelType) url += `&modelType=${searchObj.modelType.value}`;
        if (searchObj.seller) url += `&partnerSeq=${searchObj.seller.value}`;
        if (searchObj.model) url += `&model=${searchObj.model.value}`;
        if (searchObj.modelName) url += `&modelName=${searchObj.modelName}`;
        if (searchObj.barcode) url += `&centerCodes=${searchObj.barcode}`;
      }
      if (driverId) url += `&userId=${driverId}`;

      const rs = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (rs?.status === 200) {
        const data = [];

        rs.data?.forEach((ele) => {
          ele['modelTypeName'] = selectlabel(ele.modelGroup, masterOptions?.MODEL_GROUP);
          ele['originalCenterCodeName'] = selectlabel(ele.originalCenterCode, masterOptions?.CENTER_WHOLE);
          ele['destinationCenterCodeName'] = selectlabel(ele.destinationCenterCode, masterOptions?.CENTER_WHOLE);
          ele['availableStatusName'] = ele.availableStatus === 'AVAILABLE' ? '양품' : '불용';
          ele['logisticTypeName'] = ele.logisticType === 'TRUCK' ? '트럭' : '컨테이너';
          data.push(ele);
        });
        setRowsOnModal(data);
        dispatch(setLoading(null));
      }
    } catch (error) {
      dispatch(setLoading(null));
    }
  };

  const fetchDriver = async (centerCode) => {
    if (centerCode) {
      try {
        const url = `${IMS_DRIVER_LIST}?centerCode=${centerCode}`;
        const rs = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const result = rs?.status === 200 ? rs.data : [];
        const filtered = [];

        result.forEach((driver) => {
          filtered.push({
            value: driver.userId,
            label: driver.driverName,
          });
        });
        return filtered; // []
      } catch (error) {
        return null;
      }
    }
  };

  const fetchList = async () => {
    if (!searchDate) {
      alert('날짜는 필수 검색값입니다!');
      return;
    } else if (!searchCenter) {
      alert('창고는 필수 검색값입니다!');
      return;
    } else {
      setRowsChulgo([]);
      setRowsIpgo([]);
      setRowsGisaChulgo([]);
      dispatch(setLoading('GET'));

      let url = process.env.REACT_APP_API_URL + INVENTORY_INOUT_CENTER_LIST + '?';
      if (searchDate) url += `&fromInOutDate=${searchDate}&toInOutDate=${searchDate}`;
      if (searchCenter) url += `&centerCode=${searchCenter.value}`;
      if (searchObj) {
        if (searchObj.modelGroup) url += `&modelGroup=${searchObj.modelGroup.value}`;
        if (searchObj.modelType) url += `&modelType=${searchObj.modelType.value}`;
        if (searchObj.seller) url += `&partnerSeq=${searchObj.seller.value}`;
        if (searchObj.model) url += `&model=${searchObj.model}`;
        if (searchObj.modelName) url += `&modelName=${searchObj.modelName}`;
        if (searchObj.barcode) url += `&centerCodes=${searchObj.barcode}`;
      }

      await httpClient.get(url).then((res) => {
        console.log(res);
        if (res?.status === 200) {
          if (res.data) {
            const data = [];
            res.data.forEach((ele) => {
              ele['modelGroupName'] = selectlabel(ele.modelGroup, masterOptions?.MODEL_GROUP);
              if (searchDate) {
                ele['search_fromInOutDate'] = searchDate;
                ele['search_toInOutDate'] = searchDate;
              }
              if (searchCenter) ele['search_center'] = searchCenter.value;
              if (searchObj.modelGroup) ele['search_modelGroup'] = searchObj.modelGroup.value;
              if (searchObj.modelType) ele['search_modelType'] = searchObj.modelType.value;
              if (searchObj.seller) ele['search_partnerSeq'] = searchObj.seller.value;
              if (searchObj.model) ele['search_model'] = searchObj.model;
              if (searchObj.modelName) ele['search_modelName'] = searchObj.modelName;
              if (searchObj.barcode) ele['search_barcode'] = searchObj.barcode;
              data.push(ele);
            });

            setRows(data);
          }
        }
      });

      dispatch(setLoading(null));
    }
  };

  const columns = [
    {
      headerText: '제품명',
      dataField: 'modelName',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '입고',
      children: [
        {
          headerText: '발주입고',
          dataField: 'purchaseOrderQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '간선입고',
          dataField: 'linehaulInQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '회수입고',
          dataField: 'collectQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '반출',
      dataField: 'removeQuantity',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고',
      children: [
        {
          headerText: '간선출고',
          dataField: 'linehaulOutQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '기사출고',
          dataField: 'loanQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '이전출고',
          dataField: 'moveOutQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '재고조정',
      children: [
        {
          headerText: '양품->불용',
          dataField: 'availableToDefectedModificationQuantity',
          filter: {
            showIcon: true,
          },
        },
        {
          headerText: '불용->양품',
          dataField: 'defectedToAvailableModificationQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },
    {
      headerText: '현재고',
      dataField: 'currentQuantity',
      filter: {
        showIcon: true,
      },
    },
  ];

  const ipgoModalColumns = [
    {
      headerText: '발주번호',
      dataField: 'purchaseSeq',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '특이사항',
      dataField: 'logisticTypeName',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '입고확정일시',
      dataField: 'finishDatetime',
    },
  ];

  const ipgoModalColumns2 = [
    {
      headerText: '운송번호',
      dataField: 'transportStatementSeq',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '출밣창고',
      dataField: 'originalCenterCodeName',
    },
    {
      headerText: '도착창고',
      dataField: 'destinationCenterCodeName',
    },
    {
      headerText: '운송기사',
      dataField: 'userId',
    },
    {
      headerText: '재고상태',
      dataField: 'availableStatusName',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '입고확정일시',
      dataField: 'finishDatetime',
    },
  ];

  const chulgoModalColumns = [
    {
      headerText: '출차번호',
      dataField: 'loadingOutSeq',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '출밣창고',
      dataField: 'originalCenterCodeName',
    },
    {
      headerText: '도착창고',
      dataField: 'destinationCenterCodeName',
    },
    {
      headerText: '운송기사',
      dataField: 'userId',
    },
    {
      headerText: '재고상태',
      dataField: 'availableStatusName',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '출차일시',
      dataField: 'loadDatetime',
    },
  ];

  const gisaModalColumns = [
    {
      headerText: '기사출고번호',
      dataField: 'loanSeq',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '재고상태',
      dataField: 'availableStatusName',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '출고일시',
      dataField: 'loanDatetime',
    },
  ];

  useEffect(() => {
    initGrid();
  }, []);

  const initGrid = () => {
    const seed = uuidv4;
    setGrid(`dailyCenterReport_${seed}`);
    setIpgoGridId(`linehaul_ipgo_${seed}`);
    setChulgoGridId(`linehaul_chulgo_${seed}`);
    setGisaChulgoGridId(`linehaul_gisaChulgo_${seed}`);
  };

  return (
    <Presenter
      rows={rows}
      rowsChulgo={rowsChulgo}
      rowsIpgo={rowsIpgo}
      rowsGisaChulgo={rowsGisaChulgo}
      rowsOnModal={rowsOnModal}
      columns={columns}
      ipgoColumns={ipgoColumns}
      chulgoColumns={chulgoColumns}
      gisaChulgoColumns={gisaChulgoColumns}
      ipgoModalColumns={ipgoModalColumns}
      ipgoModalColumns2={ipgoModalColumns2}
      chulgoModalColumns={chulgoModalColumns}
      gisaModalColumns={gisaModalColumns}
      gridId={gridId}
      ipgoGridId={ipgoGridId}
      chulgoGridId={chulgoGridId}
      gisaChulgoGridId={gisaChulgoGridId}
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      searchDate={searchDate}
      setSearchDate={setSearchDate}
      searchCenter={searchCenter}
      setSearchCenter={setSearchCenter}
      // options
      fetchList={fetchList}
      onCellClick={onCellClick}
      listCount={listCount}
      listCount2={listCount2}
      setlistCount={setlistCount}
      setlistCount2={setlistCount2}
      exportExcelThisGrid={exportExcelThisGrid}
      setExportExcelThisGrid={setExportExcelThisGrid}
      footerLayout={footerLayout}
      ipchulFooterLayout={ipchulFooterLayout}
      gisaFooterLayout={gisaFooterLayout}
      modalType={modalType}
      setModalType={setModalType}
    />
  );
};

export default Container;
