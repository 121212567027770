import { useState, useEffect, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Label, Col, Row, Form, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import Grid from 'components/grid/auiGrid';
import Select from 'react-select';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { httpClient } from 'common/http-client/axiosConfig';
import { handleFile, uploadFile } from 'common/util/photo';
import { LINEHAUL_CANCEL, LINEHAUL_LOADSTAT_IMG_SAVE } from 'envVar';
import CloseImg from 'assets/images/close/cross.png';

//component
import { BottomSheets } from 'components/template/bottomSheets';
import DeliveryInformationManagePopup from './DeliveryInformationManagePopup.js';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { FlatpickrD, InputD, SelectD } from 'components/reactstrap/reactstrap';
import { GridModal } from 'components/modal/gridModal.js';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';
import useGridButton from 'hooks/grid/useGridButton';
import { setLoading } from 'redux/services/menuSlice';
import CommonModalNew from 'components/modal/commonModalNew';
import { TrunkPrints } from '../../_print/trunkPrint';

import { resetAllSelections, toggleBooleanOptions } from 'common/util/searchBox';
import PrintGridBox from 'common/grid/printGridBox';
import { useFrontSearchData } from 'hooks/grid/useFrontSearchData';
import { serviceStore } from 'services/services';
import Divider from 'components/LDS/atoms/Divider';
import GridBoxCommon from 'common/grid/gridBox';

const excelDetailColumns = [
  { headerText: '피킹지시번호', dataField: 'linehaulSeq' },
  { headerText: '출고지시번호', dataField: 'loadingStatementSeq' },
  { headerText: '운송번호', dataField: 'transportStatementSeq' },
  { headerText: '상태', dataField: 'statusKr' },
  { headerText: '출발창고', dataField: 'originalCenterCodeKr' },
  { headerText: '도착창고', dataField: 'destinationCenterCodeKr' },
  { headerText: '출고예정일', dataField: 'linehaulDate' },
  { headerText: '간선유형', dataField: 'linehaulTypeKr' },
  { headerText: '제품그룹', dataField: 'modelGroupKr' },
  { headerText: 'SKU', dataField: 'modelStockSeq' },
  { headerText: '외부SKU', dataField: 'skuNumber' },
  { headerText: '판매사', dataField: 'partnerSeqKr' },
  { headerText: '바코드', dataField: 'barcode' },
  { headerText: '제품', dataField: 'model' },
  { headerText: '제품명', dataField: 'modelName' },

  {
    headerText: '간선출차대상',
    children: [
      { headerText: '양품', dataField: 'availableQuantity' },
      { headerText: '불용', dataField: 'defectedQuantity' },
      { headerText: '기타', dataField: 'etcQuantity' },
    ],
  },
  { headerText: '기사명', dataField: 'loadingDriverName' },
  { headerText: '기사 연략처', dataField: 'loadingDriverPhone' },
  { headerText: '차종', dataField: 'loadingDriverCarType' },
  { headerText: '차량번호', dataField: 'loadingDriverCarNumber' },
  { headerText: '출차지시 확정자', dataField: 'registerId' },
  { headerText: '출차완료일', dataField: 'pickDatetime' },
];

const Presenter = (props) => {
  const {
    bottomSheetObj,
    setBottomSheetObj,
    photoPopupObj,
    setPhotoPopupObj,
    driverModalObj,
    setDriverModalObj,
    fetchList,
    fetchPhotos,
    inventoryCancelModalObj,
    setInventoryCancelModalObj,
    inventoryModalObj,
    setInventoryModalObj,
    trunkPrintObj,
    setTrunkPrintObj,
    getCheckedDataTriggerThisGrid,
    setGetCheckedDataTriggerThisGrid,
    inventoryModalGridId,
    searchObj,
    setSearchObj,
  } = props;

  return (
    <>
      <div className="page-content">
        {trunkPrintObj?.visible && <TrunkPrints trunkPrintObj={trunkPrintObj} setTrunkPrintObj={setTrunkPrintObj} />}
        {photoPopupObj?.visible && <PhotoPopup fetchList={fetchList} fetchPhotos={fetchPhotos} photoPopupObj={photoPopupObj} setPhotoPopupObj={setPhotoPopupObj} />}
        {driverModalObj?.visible && <DeliveryInformationManagePopup driverModalObj={driverModalObj} setDriverModalObj={setDriverModalObj} />}
        {inventoryModalObj?.visible && (
          <HaldangModal
            haldangObj={inventoryModalObj}
            setHaldangObj={setInventoryModalObj}
            getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
            setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            inventoryModalGridId={inventoryModalGridId}
          />
        )}
        {inventoryCancelModalObj?.visible && (
          <InventoryCancelModal inventoryCancelModalObj={inventoryCancelModalObj} setInventoryCancelModalObj={setInventoryCancelModalObj} setSearchObj={setSearchObj} searchObj={searchObj} />
        )}
        {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

export default Presenter;

const Search = (props) => {
  const { fetchList, setSearchObj, searchObj, pagingSetting, setPagingSetting } = props;
  const { masterOptions } = useSelector((state) => state.menu);
  const [selectTypeObj, setSelectTypeObj] = useState({
    ORDER_SEQ: null,
    SEARCH_MODEL: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.ORDER_SEQ, searchObj));
  }, [selectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, searchObj));
  }, [selectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    if (pagingSetting?.pageNo && pagingSetting?.fetchNew) {
      // 단순히 페이지만 바뀔때
      fetchList(searchObj);
    }
  }, [pagingSetting?.fetchNew]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPagingSetting((prev) => {
        return {
          ...prev,
          pageNo: 1,
          fetchNew: true,
        };
      });
    }
  };

  const dateOption = [
    {
      label: '출고예정일',
      value: 'LinehaulDate',
    },
    {
      label: '출차지시확정일',
      value: 'ConfirmDate',
    },
  ];

  return (
    <>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Col>
            <label className="col-form-label">기간검색</label>
            <div style={{ display: 'flex' }}>
              <Col style={{ display: 'inline-block' }}>
                <SelectD
                  options={dateOption}
                  value={
                    searchObj?.dateType
                      ? {
                          label: selectlabel(searchObj?.dateType, dateOption),
                          value: searchObj?.dateType,
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    const _searchObj = {
                      ...searchObj,
                      dateType: value,
                    };
                    if (value === null) {
                      _searchObj['fromDate'] = null;
                      _searchObj['toDate'] = null;
                    }
                    setSearchObj(_searchObj);
                  }}
                />
              </Col>
              <Col style={{ display: 'inline-block' }}>
                <FlatpickrD
                  disabled={searchObj?.dateType === null}
                  value={[searchObj?.fromDate, searchObj?.toDate]}
                  onReset={() => {
                    setSearchObj({
                      ...searchObj,
                      fromDate: null,
                      toDate: null,
                    });
                  }}
                  onChange={(option) => {
                    setSearchObj({
                      ...searchObj,
                      fromDate: returnDateyyyymmdd(option[0]),
                      toDate: returnDateyyyymmdd(option[1]),
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">출고지시번호</label>
            <InputD
              value={searchObj?.loadingStatementSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  loadingStatementSeq: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">운송번호</label>
            <InputD
              value={searchObj?.transportStatementSeq || ''}
              onChange={(e) => {
                setSearchObj({
                  ...searchObj,
                  transportStatementSeq: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <label className="col-form-label">출발창고</label>
            <SelectD
              value={
                searchObj?.originalCenterCode
                  ? {
                      value: searchObj?.originalCenterCode,
                      label: selectlabel(searchObj?.originalCenterCode, masterOptions?.CENTER),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  originalCenterCode: option.value,
                });
              }}
              options={masterOptions?.CENTER_LABEL}
            />
          </Col>
          <Col>
            <label className="col-form-label">도착창고</label>
            <SelectD
              value={
                searchObj?.destinationCenterCode
                  ? {
                      value: searchObj?.destinationCenterCode,
                      label: selectlabel(searchObj?.destinationCenterCode, masterOptions?.CENTER_DIFF),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  destinationCenterCode: option.value,
                });
              }}
              options={masterOptions?.CENTER_DIFF}
            />
          </Col>
          <Col>
            <label className="col-form-label">간선유형</label>
            <SelectD
              value={
                searchObj?.linehaulType
                  ? {
                      value: searchObj?.linehaulType,
                      label: selectlabel(searchObj?.linehaulType, masterOptions?.LINEHAUL_TYPE),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  linehaulType: option.value,
                });
              }}
              options={masterOptions?.LINEHAUL_TYPE}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="col-form-label">진행상태</label>
            <SelectD
              value={
                searchObj?.status
                  ? {
                      value: searchObj?.status,
                      label: selectlabel(searchObj?.status, masterOptions?.LOADING_OUT_STAT),
                    }
                  : null
              }
              onChange={(option) => {
                setSearchObj({
                  ...searchObj,
                  status: option.value,
                });
              }}
              options={masterOptions?.LOADING_OUT_STAT}
            />
          </Col>
          <Col>
            <label className="col-form-label">주문번호/송장번호</label>
            {/* orderSeq */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.ORDER_SEQ
                      ? {
                          value: selectTypeObj?.ORDER_SEQ,
                          label: selectlabel(selectTypeObj?.ORDER_SEQ, masterOptions?.ORDER_SEQ),
                        }
                      : null
                  }
                  options={masterOptions?.ORDER_SEQ}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      ORDER_SEQ: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.ORDER_SEQ}
                  value={searchObj?.[selectTypeObj?.ORDER_SEQ] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.ORDER_SEQ]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">제품/제품명</label>
            {/* model / modelName  */}
            <div style={{ display: 'flex' }}>
              <Col md={4} style={{ display: 'inline-block' }}>
                <SelectD
                  value={
                    selectTypeObj?.SEARCH_MODEL
                      ? {
                          value: selectTypeObj?.SEARCH_MODEL,
                          label: selectlabel(selectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                        }
                      : null
                  }
                  options={masterOptions?.SEARCH_MODEL}
                  onChange={(option) => {
                    setSelectTypeObj({
                      ...selectTypeObj,
                      SEARCH_MODEL: option.value,
                    });
                  }}
                />
              </Col>
              <Col md={8} style={{ display: 'inline-block' }}>
                <InputD
                  disabled={!selectTypeObj?.SEARCH_MODEL}
                  value={searchObj?.[selectTypeObj?.SEARCH_MODEL] || ''}
                  onChange={(e) => {
                    setSearchObj({
                      ...searchObj,
                      [selectTypeObj?.SEARCH_MODEL]: e.target.value,
                    });
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col>
            <label className="col-form-label">유사검색여부</label>
            {/* similaritySearchFlag */}
            <div className="radio-select-area">
              <span
                className={searchObj?.similaritySearchFlag === true ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: true,
                    };
                  });
                }}
              >
                유사검색
              </span>
              <span
                className={searchObj?.similaritySearchFlag === false ? 'active' : ''}
                onClick={() => {
                  setSearchObj((prev) => {
                    return {
                      ...prev,
                      similaritySearchFlag: false,
                    };
                  });
                }}
              >
                일치검색
              </span>
            </div>
          </Col>
          <Col />
          <Col style={{ textAlign: 'right' }}>
            <button
              className="btn btn-search"
              onClick={(e) => {
                e.preventDefault();
                setPagingSetting({
                  ...pagingSetting,
                  pageNo: 1,
                  fetchNew: true,
                });
              }}
            >
              조회
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const GridBox = (props) => {
  const {
    searchObj,
    rows = [],
    gridId,
    columns = [],
    onCellClick,
    onEditEnded,
    setGetCheckedDataTriggerThisGrid,
    getCheckedDataTriggerThisGrid,
    pagingSetting,
    setPagingSetting,
    downloadExcel,
  } = props;
  const excelGridRef = useRef();
  const excelDetailGridRef = useRef();

  const excelDownClicked = async (type) => {
    const data = await downloadExcel(type);
    if (data?.length > 0) {
      if (type === 'main') {
        excelGridRef.current.setGridData(data);
        excelGridRef.current.exportAsXlsx({ fileName: '간선출차지시(메인)' });
      } else {
        excelDetailGridRef.current.setGridData(data);
        excelDetailGridRef.current.exportAsXlsx({ fileName: '간선출차지시(상세)' });
      }
    } else {
      alert('조회되는 값이 없습니다!');
    }
  };
  const checkedArr = [
    //
    'LINEHAUL_MANAGE_DRIVER',
    'LINEHAUL_OUT_CONFIRM',
    'LINEHAUL_OUT_CANCEL',
    'LINEHAUL_OUT_ROLLBACK',
  ];

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (checkedArr?.includes(id)) {
      setGetCheckedDataTriggerThisGrid({
        type: id,
        id: `#${gridId}`,
      });
    } else if (id === 'LINEHAUL_OUT_DOWNLOAD_MAIN') {
      excelDownClicked('main');
    } else if (id === 'LINEHAUL_OUT_DOWNLOAD_DETAIL') {
      excelDownClicked('detail');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState();
  const functions = fetchUsableFunctionsInThisTab('LINEHAULOUT_TAB3');
  useMemo(() => {
    if (gridId) {
      const btns = printFunctionToBtns(functions, gridButtonhandler);
      setFunctionBtns(btns);
    }
  }, [gridId, searchObj]);

  return (
    <>
      {functionBtns && <div className="grid-button-area">{functionBtns}</div>}
      <div style={{ width: '100%' }}>
        {gridId && (
          <>
            <Grid
              id={gridId}
              settingOptions={{
                fixedColumnCount: 4,
                isRowAllCheckCurrentView: true,
                showRowAllCheckBox: true,
                showRowCheckColumn: true,
              }}
              rows={rows}
              columns={columns}
              onCellClick={onCellClick}
              onEditEnded={onEditEnded}
              // custompaging **

              pagingSetting={pagingSetting}
              setPagingSetting={setPagingSetting}
              getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
              setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
            />
            <PrintGridBox gridRef={excelGridRef} columns={columns} />
            <PrintGridBox gridRef={excelDetailGridRef} columns={excelDetailColumns} />
          </>
        )}
      </div>
    </>
  );
};

const InventoryCancelModal = ({ inventoryCancelModalObj, setInventoryCancelModalObj, setSearchObj, searchObj }) => {
  const dispatch = useDispatch();
  const { visible, data, isParts } = inventoryCancelModalObj;
  const { masterOptions } = useSelector((state) => state.menu);
  const [inputObj, setInputObj] = useState({
    holdType: null,
    holdNote: null,
  });
  const linehaulModalCancelAction = async (inputObj) => {
    let payloadArr;

    if (isParts) {
      payloadArr = [
        {
          linehaulSeq: data[0].linehaulSeq,
          loadingStatementSeq: data[0].loadingStatementSeq,
          loadingOutSeq: data[0].loadingOutSeq,
          transportStatementSeq: data[0].transportStatementSeq,
          originalCenterCode: data[0].originalCenterCode,
          destinationCenterCode: data[0].destinationCenterCode,
          linehaulDate: data[0].linehaulDate,
          linehaulType: data[0].linehaulType,
          inventorySeqs: data.map((ele) => ele.inventorySeq),
          holdType: inputObj?.holdType,
          holdNote: inputObj?.holdNote,
        },
      ];
    } else {
      payloadArr = data.map((ele) => {
        return {
          linehaulSeq: ele.linehaulSeq,
          loadingStatementSeq: ele.loadingStatementSeq,
          loadingOutSeq: ele.loadingOutSeq,
          transportStatementSeq: ele.transportStatementSeq,
          originalCenterCode: ele.originalCenterCode,
          destinationCenterCode: ele.destinationCenterCode,
          linehaulDate: ele.linehaulDate,
          linehaulType: ele.linehaulType,
          holdType: inputObj?.holdType,
          holdNote: inputObj?.holdNote,
        };
      });
    }
    let url = LINEHAUL_CANCEL;
    dispatch(setLoading('POST'));
    await httpClient.post(url, payloadArr).then((res) => {
      if (res?.status === 200) {
        alert('출차 취소 되었습니다.');
        inventoryCancelModalObj?.fetchListFn(inventoryCancelModalObj?.searchObj);
        setInventoryCancelModalObj(null);
        setSearchObj({ ...searchObj });
      }
    });
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      title="출차 취소"
      setVisible={() => {
        setInventoryCancelModalObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
            searchObj: null,
          };
        });
      }}
      visible={visible}
      style={{ width: 300 }}
      children={
        <div>
          <Col>
            <label className="col-form-label">출차취소 사유</label>
            <SelectD
              value={
                inputObj?.holdType
                  ? {
                      value: inputObj?.holdType,
                      label: selectlabel(inputObj?.holdType, masterOptions?.ORDER_HOLD_TYPE),
                    }
                  : null
              }
              options={masterOptions?.['ORDER_HOLD_TYPE']}
              onChange={({ value }) => {
                setInputObj((prev) => {
                  return {
                    ...prev,
                    holdType: value,
                  };
                });
              }}
            />
          </Col>
          <Col style={{ marginTop: '1rem' }}>
            <InputD
              value={inputObj.holdNote || ''}
              onChange={(e) => {
                setInputObj((prev) => {
                  return {
                    ...prev,
                    holdNote: e.target.value,
                  };
                });
              }}
              placeholder="출차취소사유 입력"
            />
          </Col>
          <Col style={{ marginTop: '1rem', textAlign: 'right' }}>
            <Button
              onDoubleClick={() => {
                return;
              }}
              className="btn-secondary btn sm"
              onClick={() => linehaulModalCancelAction(inputObj)}
            >
              확인
            </Button>
          </Col>
        </div>
      }
    />
  );
};

const PhotoPopup = ({ photoPopupObj, setPhotoPopupObj, fetchPhotos }) => {
  const ImageModal = (props) => {
    const { title = '', className, src } = props;
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
      <div>
        <img alt="" src={src} style={{ width: 45, height: 45, cursor: 'pointer' }} onClick={toggle} />
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <ModalBody>
            <div style={{ textAlign: 'center' }}>
              <img style={{ maxWidth: 400 }} src={src} />
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    );
  };

  const handleFileUpload = async (e, index) => {
    const contentArr = e.target.id.split('_');
    const content = contentArr[0];
    const idx = Number(contentArr[1]);
    const ret = await handleFile(e);
    const uploadUrl = await uploadFile(ret.file, ret.url, `${content}_${idx}`);
    const data = {
      loadingStatementSeq: photoPopupObj?.photos?.loadingStatementSeq,
      addFiles: [
        {
          column: `LOADINGOUT${index + 1}`,
          uploadUrl,
        },
      ],
    };
    httpClient.post(LINEHAUL_LOADSTAT_IMG_SAVE, data).then((res) => {
      if (res?.status === 200) {
        alert('사진 업로드에 성공하였습니다!');
        fetchPhotos(photoPopupObj?.data);
      } else {
        if (res?.data?.code) alert(res?.data?.code);
      }
    });
  };

  const handleFileRemove = async (e, index) => {
    const contentArr = e.target.id.split('_');
    const idx = Number(contentArr[1]) + 1;

    const prompt = window.confirm('사진을 정말 삭제하시겠습니까?');
    if (prompt) {
      const data = {
        loadingStatementSeq: photoPopupObj?.photos?.loadingStatementSeq,
        deleteFiles: [
          {
            column: `LOADINGOUT${idx}`,
          },
        ],
      };
      httpClient.post(LINEHAUL_LOADSTAT_IMG_SAVE, data).then((res) => {
        if (res?.status === 200) {
          fetchPhotos(photoPopupObj?.data);
        }
      });
    }
  };

  const [files, setFiles] = useState();

  useEffect(() => {
    if (photoPopupObj?.photos) {
      setPhotos();
    }
  }, [photoPopupObj?.photos]);

  const setPhotos = () => {
    const photos = new Array(10).fill(null);
    photoPopupObj?.photos?.files.forEach((file) => {
      const i = Number(file?.column.split('LOADINGOUT')[1]) - 1;
      photos[i] = file.uploadUrl;
    });
    setFiles(photos);
  };

  return (
    <>
      <CommonModalNew
        title="사진 모아보기"
        subTitle={`출고지시번호 ${photoPopupObj?.photos?.loadingStatementSeq}`}
        visible={photoPopupObj?.visible}
        setVisible={() => {
          setPhotoPopupObj({
            ...photoPopupObj,
            visible: false,
          });
        }}
        children={
          <>
            <Row>
              <div className="moa-bogi">
                <Row>
                  {files?.map((url, i) => {
                    return (
                      <Col key={`loadoutPhoto_${i}_${uuidv4()}`}>
                        <div className="photo-zone">
                          <span className="bck-font"> 사진 {i + 1}</span>
                          {url ? (
                            <div style={{ display: 'inline-block' }}>
                              <img
                                src={CloseImg}
                                id={`loadoutPhoto_${i}_`}
                                alt=""
                                onClick={(e) => {
                                  handleFileRemove(e, i);
                                }}
                                className="closeBtn"
                              />
                              <ImageModal className="top_bar_profile_img" src={url ? url : null} alt="이미지미리보기" title={`사진 ${i + 1}`} />
                            </div>
                          ) : (
                            <>
                              <input style={{ display: 'none' }} type="file" accept="image/*" id={`loadoutPhoto_${i}`} className="loadoutPhoto" onChange={(e) => handleFileUpload(e, i)} />

                              <Button size="md" color="primary" outline>
                                <Label for={`loadoutPhoto_${i}`} style={{ marginBottom: 0 }}>
                                  ㅤㅤㅤ파일 선택ㅤㅤㅤ
                                </Label>
                              </Button>
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Row>
          </>
        }
      />
    </>
  );
};

// 할당 정보 모달 (이전 : inventoryModalObj)
const HaldangModal = ({ haldangObj, setHaldangObj, haldangModalActionObj, getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid, inventoryModalGridId }) => {
  const [actionParams, setActionParams] = useState({});

  const dispatch = useDispatch();
  const { masterOptions } = useSelector((state) => state.menu);

  const gridRef = useRef();
  const convertKeyValue = {
    customerAddr: (row) => (row?.customerAddr1 ? `${row?.customerAddr1} ${row?.customerAddr2 || ''}` : ''),
    centerCodeKr: (row) => selectlabel(row?.centerCode, masterOptions?.CENTER),
    originalCenterCodeKr: (row) => selectlabel(row?.originalCenterCode, masterOptions?.CENTER),
    destinationCenterCodeKr: (row) => selectlabel(row?.destinationCenterCode, masterOptions?.CENTER),
    driverCenterCodeKr: (row) => selectlabel(row?.driverCenterCode, masterOptions?.CENTER),
    partnerSeqKr: (row) => selectlabel(row?.partnerSeq, masterOptions?.SELLER),
    modelGroupKr: (row) => selectlabel(row?.modelGroup, masterOptions?.MODEL_GROUP),
    availableTypeKr: (row) => selectlabel(row?.availableType, masterOptions?.INVA_TYPE),
    unloadFlagKr: (row) => (row?.unloadFlag === true ? 'O' : 'X'),
    orderCancelFlagKr: (row) => (row?.orderCancelFlag === true ? 'O' : 'X'),
    pickFlagKr: (row) => (row?.pickFlag === true ? 'O' : 'X'),
    loadFlagKr: (row) => (row?.loadFlag === true ? 'O' : 'X'),
  };
  const detailSelectType = { INVENTORY_SEQ: null, ORDER_SEQ: null, SEARCH_MODEL: null };

  const { setGridData, setDetailSelectTypeObj, handleSubmitFiltering, detailSelectTypeObj, detailSearchObj, setDetailSearchObj, convertFieldValue, returnKeyValues, resetFilters } = useFrontSearchData(
    {
      gridRef,
      initDetailSearchObj: {},
      detailSelectType,
      convertKeyValue,
    },
  );

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.INVENTORY_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.INVENTORY_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.ORDER_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, detailSearchObj));
  }, [detailSelectTypeObj?.SEARCH_MODEL]);

  //NOTE: 할당정보 (간선 출차)컬럼
  const columns = [
    {
      headerText: '주문취소여부',
      dataField: 'orderCancelFlagKr',
    },
    {
      headerText: '재고번호',
      dataField: 'inventorySeq',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '재고유형',
      dataField: 'availableTypeKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '고객명',
      dataField: 'customerName',
    },
    {
      headerText: '고객주소',
      dataField: 'customerAddr',
    },
    {
      headerText: '연락처',
      dataField: 'customerPhone',
    },
    {
      headerText: '권역정보',
      dataField: 'zipcodeGroupInfo',
    },
    {
      headerText: '도크코드',
      dataField: 'dockNumber',
    },
    {
      headerText: '기사명', // 이전 : 설치기사
      dataField: 'driverName',
    },
    {
      headerText: '기사소속창고', // 이전 : 기사출고창고
      dataField: 'driverCenterCodeKr',
    },
    {
      headerText: '지정일', // 이전 : 기사출고예정일
      dataField: 'deliveryDate',
    },
    {
      headerText: '고객주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '비고',
      dataField: 'memo',
    },
  ];

  useEffect(() => {
    fetchHaldangInfo(haldangObj?.origin);
  }, [haldangObj?.origin]);

  const fetchHaldangInfo = async (item) => {
    dispatch(setLoading('GET'));

    setHaldangObj((prev) => {
      return {
        ...prev,
        item: {
          linehaulSeq: item?.linehaulSeq,
          loadingStatementSeq: item?.loadingStatementSeq,
          transportStatementSeq: item?.transportStatementSeq,
          originalCenterCodeKr: item?.originalCenterCodeKr,
          destinationCenterCodeKr: item?.destinationCenterCodeKr,
          statusKr: item?.statusKr,
        },
      };
    });

    const searchDTO = {
      loadingOutSeq: item?.loadingOutSeq,
    };

    // NOTE: 액션 버튼 api 호출 시 필요한 parameter들을 정의
    setActionParams({
      linehaulSeq: item?.linehaulSeq,
      loadingStatementSeq: item?.loadingStatementSeq,
      loadingOutSeq: item?.loadingOutSeq,
      transportStatementSeq: item?.transportStatementSeq,
      originalCenterCode: item?.originalCenterCode,
      destinationCenterCode: item?.destinationCenterCode,
      linehaulDate: item?.linehaulDate,
      linehaulType: item?.linehaulType,
      status: item?.status,
    });

    const rs = await serviceStore?.warehouseAction(`linehaul/loading/out/info`, 'GET', searchDTO);

    if (rs?.status === 200) {
      const data = rs?.data;
      setGridData(data);
      const dataKr = data?.map((row) => {
        let keyValues = returnKeyValues(convertKeyValue, row);

        return {
          ...row,
          ...keyValues,
        };
      });
      setHaldangObj((prev) => {
        return { ...prev, data: dataKr };
      });
      gridRef?.current?.setGridData(dataKr);
      dispatch(setLoading(null));
    }
  };

  const handleCheckItems = (e) => {
    const buttonId = e?.target?.id;
    const items = gridRef?.current?.getCheckedRowItemsAll();

    if (items?.length === 0 && !buttonId?.includes('excel')) {
      alert('선택된 항목이 존재하지 않습니다.');
      return;
    }

    if (buttonId?.includes('excel')) {
      gridRef?.current?.exportAsXlsx({ fileName: '간선출차_할당정보' });
      return;
    }

    if (actionParams?.status !== 'OUT' && actionParams?.status !== 'INIT') {
      alert('출차확정, 출차지시대기 상태일 때만 가능합니다!');
      return;
    }

    const paramKeys = Object.keys(actionParams);
    for (let param of paramKeys) {
      items[0][param] = actionParams?.[param];
    }

    setGetCheckedDataTriggerThisGrid({
      type: buttonId,
      id: inventoryModalGridId,
      items,
    });

    e?.target?.blur();
  };

  return (
    <CommonModalNew
      title="간선출차 할당정보"
      style={{ width: 1400 }}
      subTitle={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>출고지시번호</strong> : {haldangObj?.item?.loadingStatementSeq || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>운송번호</strong> : {haldangObj?.item?.transportStatementSeq || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>출발창고</strong> : {haldangObj?.item?.originalCenterCodeKr || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>도착창고</strong> : {haldangObj?.item?.destinationCenterCodeKr || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>진행상태</strong> : {haldangObj?.item?.statusKr || '-'}
        </div>
      }
      visible={haldangObj?.visible}
      setVisible={() => {
        setHaldangObj(null);
      }}
      children={
        <>
          <Form onSubmit={handleSubmitFiltering}>
            <Row>
              <Col>
                <label className="col-form-label">재고번호/시리얼번호</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.INVENTORY_SEQ}
                      value={
                        detailSelectTypeObj?.INVENTORY_SEQ
                          ? {
                              label: selectlabel(detailSelectTypeObj?.INVENTORY_SEQ, masterOptions?.INVENTORY_SEQ),
                              value: detailSelectTypeObj?.INVENTORY_SEQ,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          INVENTORY_SEQ: option.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.INVENTORY_SEQ}
                      value={detailSearchObj?.[detailSelectTypeObj?.INVENTORY_SEQ] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.INVENTORY_SEQ]: convertFieldValue(detailSelectTypeObj['INVENTORY_SEQ'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">주문번호/송장번호</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.ORDER_SEQ}
                      value={
                        detailSelectTypeObj?.ORDER_SEQ
                          ? {
                              label: selectlabel(detailSelectTypeObj?.ORDER_SEQ, masterOptions?.ORDER_SEQ),
                              value: detailSelectTypeObj?.ORDER_SEQ,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          ORDER_SEQ: option.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.ORDER_SEQ}
                      value={detailSearchObj?.[detailSelectTypeObj?.ORDER_SEQ] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.ORDER_SEQ]: convertFieldValue(detailSelectTypeObj['ORDER_SEQ'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">제품/제품명</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.SEARCH_MODEL}
                      value={
                        detailSelectTypeObj?.SEARCH_MODEL
                          ? {
                              label: selectlabel(detailSelectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                              value: detailSelectTypeObj?.SEARCH_MODEL,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          SEARCH_MODEL: option.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.SEARCH_MODEL}
                      value={detailSearchObj?.[detailSelectTypeObj?.SEARCH_MODEL] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.SEARCH_MODEL]: convertFieldValue(detailSelectTypeObj['SEARCH_MODEL'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">판매사</label>
                <div style={{ display: 'flex' }}>
                  <Col style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.SELLER}
                      value={
                        detailSearchObj?.partnerSeq
                          ? {
                              label: selectlabel(detailSearchObj?.partnerSeq, masterOptions?.SELLER),
                              value: detailSearchObj?.partnerSeq,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          partnerSeq: option.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>

              <Col>
                <label className="col-form-label">주문취소여부</label>
                <div
                  className="radio-select-area"
                  onClick={(e) => {
                    toggleBooleanOptions('orderCancelFlag', e.target.dataset.flagId, detailSearchObj, setDetailSearchObj);
                  }}
                >
                  <span className={detailSearchObj?.orderCancelFlag === true ? 'active' : ''} data-flag-id="true">
                    O
                  </span>
                  <span className={detailSearchObj?.orderCancelFlag === false ? 'active' : ''} data-flag-id="false">
                    X
                  </span>
                </div>
              </Col>

              <Col style={{ textAlign: 'right', placeSelf: 'flex-end', display: 'flex', gap: '4px' }}>
                <button type="button" className="btn" style={{ border: '1px solid #DADADA', minWidth: '60px' }} onClick={resetFilters}>
                  초기화
                </button>
                <button type="submit" className="btn btn-search" style={{ flexGrow: 1 }}>
                  조회
                </button>
              </Col>
            </Row>
          </Form>
          <Divider $direction={'H'} $color={'#eaeaea'} $spacing={'LG'} $value={'FULL'} />
          <div className="grid-button-area space-between">
            <div>
              <div className="red" id="MODAL_ROLLBACK" onClick={handleCheckItems}>
                이전단계
              </div>
              <div className="red" id="MODAL_CANCEL" onClick={handleCheckItems}>
                출차취소
              </div>
              <div className="green" id="trunkShipping-excel-download" onClick={handleCheckItems}>
                엑셀다운
              </div>
            </div>
          </div>
          <GridBoxCommon gridRef={gridRef} columns={columns} getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid} setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid} />
        </>
      }
    />
  );
};
