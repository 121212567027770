import { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CommonModalNew from 'components/modal/commonModalNew';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';
import { OptionItem } from 'common/master/codeMasterReturnHelper';
import { feeService } from 'pages/FEE/_service/service';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { OrderReceiptSearchDTO } from '../../../_interface/order';
import { receiptCategoryChanger } from '../..';
import { useLocation } from 'react-router-dom';
import { setLoading } from 'redux/services/menuSlice';

export interface ICreateReceipt {
  visible?: boolean;
  searchObj?: OrderReceiptSearchDTO;
  fetchFn?: (data) => {};
}
export const CreateReceiptPopup = ({ createReceiptObj, setCreateReceiptObj }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const [obj, setObj] = useState({
    partnerSeq: null,
    infraSeq: null,
    centerCode: null,
    party: null,
    teamGrade: null,
    fromSearchDate: null,
    toSearchDate: null,
  });

  const saveObj = async (data) => {
    dispatch(setLoading('POST'));
    const rs = await feeService.postCreateReceipt(data);
    if (rs?.status === 200) {
      alert('누락영수증을 생성하였습니다!');
      createReceiptObj?.fetchFn(JSON.parse(createReceiptObj?.searchObj));
      setCreateReceiptObj(null);
    }
    dispatch(setLoading(null));
  };

  return (
    <CommonModalNew
      style={{ width: 350 }}
      title={`누락 영수증 생성`}
      visible={createReceiptObj?.visible}
      setVisible={() => {
        setCreateReceiptObj((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      }}
      children={
        <>
          <Row>
            <Col>
              <label className="col-form-label">판매사</label>
              <SelectD
                options={MASTER_OPS?.SELLER_SELLER_AUTH}
                value={
                  obj?.partnerSeq
                    ? {
                        value: obj?.partnerSeq,
                        label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[obj?.partnerSeq],
                      }
                    : null
                }
                onChange={({ value }) => {
                  setObj({
                    ...obj,
                    partnerSeq: value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="col-form-label">검색일자</label>
              <FlatpickrD
                value={[obj?.fromSearchDate, obj?.toSearchDate]}
                onChange={(option) => {
                  setObj({
                    ...obj,
                    fromSearchDate: returnDateyyyymmdd(option[0]),
                    toSearchDate: returnDateyyyymmdd(option[1]),
                  });
                }}
                options={{
                  mode: 'range',
                }}
              />
            </Col>
          </Row>
          <Row>
            {receiptCategoryChanger[location.pathname] === 'SUBCONTRACT' ? (
              <Col>
                <label className="col-form-label">인프라</label>
                <SelectD
                  options={MASTER_OPS?.INFRA}
                  value={
                    obj?.infraSeq
                      ? {
                          value: obj?.infraSeq,
                          label: MASTER_OBJ?.INFRA?.[obj?.infraSeq],
                        }
                      : null
                  }
                  onChange={(option) => {
                    setObj({
                      ...obj,
                      infraSeq: (option as OptionItem).value,
                    });
                  }}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            {receiptCategoryChanger[location.pathname] === 'CENTER' || receiptCategoryChanger[location.pathname] === 'DRIVER' ? (
              <Col>
                <label className="col-form-label">창고</label>
                <SelectD
                  options={MASTER_OPS?.CENTER_AUTH}
                  value={
                    obj?.centerCode
                      ? {
                          value: obj?.centerCode,
                          label: MASTER_OBJ?.CENTER_AUTH?.[obj?.centerCode],
                        }
                      : null
                  }
                  onChange={({ value }) => {
                    setObj({
                      ...obj,
                      centerCode: value,
                    });
                  }}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>
          {receiptCategoryChanger[location.pathname] === 'DRIVER' ? (
            <>
              <Row>
                <Col>
                  <label className="col-form-label">설치팀등급</label>
                  <SelectD
                    options={MASTER_OPS?.TEAM_GRADE}
                    value={
                      obj?.teamGrade
                        ? {
                            value: obj?.teamGrade,
                            label: MASTER_OBJ?.TEAM_GRADE?.[obj?.teamGrade],
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setObj({
                        ...obj,
                        teamGrade: value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-form-label">소속</label>
                  <SelectD
                    options={MASTER_OPS?.DRIVER_PARTY}
                    value={
                      obj?.party
                        ? {
                            value: obj?.party,
                            label: MASTER_OBJ?.DRIVER_PARTY?.[obj?.party],
                          }
                        : null
                    }
                    onChange={({ value }) => {
                      setObj({
                        ...obj,
                        party: value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          <Row>
            <Col style={{ textAlign: 'right' }}>
              <button
                className="btn-secondary btn"
                onClick={(e) => {
                  saveObj(obj);
                }}
              >
                생성
              </button>
            </Col>
          </Row>
        </>
      }
    />
  );
};
