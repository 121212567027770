import { Col, Form, Row } from 'reactstrap';

import { ISearchBoxProps } from 'hooks/grid/useSearch';
import { FlatpickrD, InputD, OptionItem, SelectD } from 'components/reactstrap/reactstrap';

import { KeyboardEvent, useEffect, useState } from 'react';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';
import { resetAllSelections } from 'common/util/searchBox';
import { PaymentSearchDTO } from '../../../_interface/order';
import { numberTestRegExp } from 'common/util/regExp';

export const SearchBox = (props: ISearchBoxProps<PaymentSearchDTO>) => {
  const [selectTypeObj, setSelectTypeObj] = useState({
    SEARCH_DATE: null,
  });

  useEffect(() => {
    setSearchObj(resetAllSelections(MASTER_OPS?.SEARCH_DATE, searchObj));
  }, [selectTypeObj?.SEARCH_DATE]);

  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { searchObj, setSearchObj, fetchList } = props;
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchList(searchObj);
    }
  };

  return (
    <Form onKeyPress={onKeyPress}>
      <Row>
        <Col>
          <label className="col-form-label">주문번호</label>
          <InputD
            value={searchObj?.orderSeq}
            onChange={(e) => {
              if (numberTestRegExp(e.target.value)) {
                setSearchObj({
                  ...searchObj,
                  orderSeq: Number(e.target.value),
                });
              } else {
                alert('숫자만 입력가능합니다!');
              }
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">송장번호</label>
          <InputD
            value={searchObj?.invoiceSeq}
            onChange={(e) => {
              if (numberTestRegExp(e.target.value)) {
                setSearchObj({
                  ...searchObj,
                  invoiceSeq: Number(e.target.value),
                });
              } else {
                alert('숫자만 입력가능합니다!');
              }
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">결제번호</label>
          <InputD
            value={searchObj?.paymentSeq}
            onChange={(e) => {
              if (numberTestRegExp(e.target.value)) {
                setSearchObj({
                  ...searchObj,
                  paymentSeq: Number(e.target.value),
                });
              } else {
                alert('숫자만 입력가능합니다!');
              }
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.SELLER_SELLER_AUTH}
            value={searchObj?.partnerSeqList?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.SELLER_WHOLE?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                partnerSeqList: (option as OptionItem[]).map((ele) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">인프라</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.INFRA}
            value={searchObj?.infraSeqList?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.INFRA?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                infraSeqList: (option as OptionItem[]).map((ele) => ele.value),
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">창고</label>
          <SelectD
            isMulti
            options={MASTER_OPS?.CENTER_AUTH}
            value={searchObj?.centerCodeList?.map((ele) => {
              return {
                value: ele,
                label: MASTER_OBJ?.CENTER_WHOLE?.[ele],
              };
            })}
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                centerCodeList: (option as OptionItem[]).map((ele) => ele.value),
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label">기사</label>
          <div style={{ display: 'flex' }}>
            <Col md={6} style={{ display: 'inline-block' }}>
              <SelectD
                options={MASTER_OPS?.DRIVER_AUTH}
                value={
                  searchObj?.userId
                    ? {
                        value: searchObj?.userId,
                        label: MASTER_OBJ?.DRIVER_WHOLE?.[searchObj?.userId],
                      }
                    : null
                }
                onChange={({ value }) => {
                  setSearchObj({
                    ...searchObj,
                    userId: value,
                  });
                }}
              />
            </Col>
            <Col md={6} style={{ display: 'inline-block' }}>
              <InputD disabled value={searchObj?.userId || ''} placeholder="기사를 선택하세요" />
            </Col>
          </div>
        </Col>
        <Col>
          <label className="col-form-label">고객명</label>
          <InputD
            value={searchObj?.customerName}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                customerName: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">진행상태</label>
          <SelectD
            options={MASTER_OPS?.TOSS_PAYMENT_STATUS}
            value={
              searchObj?.status
                ? {
                    value: searchObj?.status,
                    label: MASTER_OBJ?.TOSS_PAYMENT_STATUS?.[searchObj?.status],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                status: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">결제수단</label>
          <SelectD
            options={MASTER_OPS?.METHOD}
            value={
              searchObj?.method
                ? {
                    value: searchObj?.method,
                    label: MASTER_OBJ?.METHOD?.[searchObj?.method],
                  }
                : null
            }
            onChange={(option) => {
              setSearchObj({
                ...searchObj,
                method: (option as OptionItem).value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">기간검색</label>
          <div style={{ display: 'flex' }}>
            <Col md={4} style={{ display: 'inline-block' }}>
              <SelectD
                value={
                  selectTypeObj?.SEARCH_DATE
                    ? {
                        value: selectTypeObj?.SEARCH_DATE,
                        label: MASTER_OBJ?.SEARCH_DATE?.[selectTypeObj?.SEARCH_DATE],
                      }
                    : null
                }
                options={MASTER_OPS?.SEARCH_DATE}
                onChange={(option) => {
                  setSelectTypeObj({
                    ...selectTypeObj,
                    SEARCH_DATE: (option as OptionItem).value,
                  });
                }}
              />
            </Col>
            <Col md={8} style={{ display: 'inline-block' }}>
              <FlatpickrD
                disabled={!selectTypeObj?.SEARCH_DATE}
                value={[searchObj?.[`from${selectTypeObj?.SEARCH_DATE}`], searchObj?.[`to${selectTypeObj?.SEARCH_DATE}`]]}
                onChange={(option) => {
                  setSearchObj({
                    ...searchObj,
                    [`from${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[0]),
                    [`to${selectTypeObj?.SEARCH_DATE}`]: returnDateyyyymmdd(option[1]),
                  });
                }}
                onClose={(option) => {
                  if (option?.length === 1)
                    setSearchObj({
                      ...searchObj,
                      [`from${selectTypeObj?.SEARCH_DATE}`]: null,
                      [`to${selectTypeObj?.SEARCH_DATE}`]: null,
                    });
                }}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <button
            className="btn-search btn"
            onClick={(e) => {
              e.preventDefault();
              fetchList(searchObj);
            }}
          >
            조회
          </button>
        </Col>
      </Row>
    </Form>
  );
};
