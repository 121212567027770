import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import Presenter from './presenter';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { httpClient } from 'common/http-client/axiosConfig';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/services/menuSlice';
import { isNullishCoalesce } from 'typescript';

const Container = (props) => {
  const dispatch = useDispatch();
  // options
  const { masterOptions } = useSelector((state) => state.menu);

  const [gridId, setGrid] = useState('');
  const detailGridId = 'fcPicking_detail_';
  const [rows, setRows] = useState([]);

  const [pagingSetting, setPagingSetting] = useState({
    pageNo: 1,
    pageSize: 100,
    fetchNew: false,
  });

  //바텀시트
  const [bottomSheetObj, setBottomSheetObj] = useState();

  const [searchObj, setSearchObj] = useState({
    orderNumType: null,
    orderNum: null,
    loanDate: null,
    centerType: null,
    centerCode: null,
    userId: null,
    status: null,
  });
  const [getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid] = useState(false); ///////main

  useEffect(() => {
    if (getCheckedDataTriggerThisGrid?.items) {
      const items = getCheckedDataTriggerThisGrid?.items;
      if (getCheckedDataTriggerThisGrid?.type === 'confirm-loan') {
        if (items?.length > 1) {
          alert('기사출고지시 확정은 한개씩만 가능합니다!');
        } else {
          confirmLoan(items[0], searchObj);
        }
      } else if (getCheckedDataTriggerThisGrid?.type === 'save-lite-loan') {
        saveLiteLoan(items, searchObj);
      } else if (getCheckedDataTriggerThisGrid?.type === 'save-lite-loan-parts') {
        saveLiteLoanParts(items);
      } else if (getCheckedDataTriggerThisGrid?.type === 'save-lite-loan-cancel-parts') {
        saveLiteLoanCancelParts(items);
      }
    }
  }, [getCheckedDataTriggerThisGrid]);

  useEffect(() => {
    setGrid(`loanRenewal_${uuidv4()}`);
  }, []);

  const paramingSearchObjToUrl = (url) => {
    if (searchObj?.orderNum && searchObj?.orderNumType) url += `&${searchObj?.orderNumType}=${searchObj?.orderNum}`;
    if (searchObj?.loanDate) url += `&loanDate=${searchObj?.loanDate}`;
    if (searchObj?.centerType) url += `&centerType=${searchObj?.centerType}`;
    if (searchObj?.centerCode) url += `&centerCode=${searchObj?.centerCode}`;
    if (searchObj?.userId) url += `&userId=${searchObj?.userId}`;
    if (searchObj?.status) url += `&status=${searchObj?.status}`;
    return url;
  };

  const fetchList = (searchObj) => {
    if (bottomSheetObj?.visible) setBottomSheetObj(isNullishCoalesce);
    dispatch(setLoading('GET'));
    let url = `/warehouse/loan/v2/list/paging?pageNo=${pagingSetting?.pageNo}&pageSize=${pagingSetting?.pageSize}`;
    url = paramingSearchObjToUrl(url);

    httpClient.get(url).then((res) => {
      if (res.status === 200) {
        const addList = res.data.list || [];
        const maxPage = Math.ceil(res.data.totalCount / pagingSetting?.pageSize);
        addList?.forEach((row) => {
          row.maxPage = maxPage;
          row.totalCount = res.data.totalCount;
          row.searchObj = JSON.stringify(searchObj);
          row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER_LBL);
          row.userIdKr = selectlabel(row.userId, masterOptions?.DRIVER_NAME);
          row.driverPhoneKr = selectlabel(row.userId, masterOptions?.DRIVER_PHONE);
          row.teamKr = selectlabel(row.userId, masterOptions?.DRIVER_MEMBERCOUNT) === 2 ? '2인 1조' : selectlabel(row.userId, masterOptions?.DRIVER_MEMBERCOUNT) === 1 ? '1인 1조' : '';
          row.statusKr = selectlabel(row.status, masterOptions?.LOAN_STATUS);
          row.print = row.statusKr === '출고지시확정' || row.statusKr === '출고완료' ? '프린트' : '';
        });

        setRows(addList);
        setPagingSetting((prev) => {
          return {
            ...prev,
            fetchNew: false,
          };
        });
        dispatch(setLoading(null));
      }
    });
  };

  const fetchDetailList = (e) => {
    dispatch(setLoading('GET'));
    let url = '/warehouse/loan/v2?';
    if (e.item?.loanSeq) url += `&loanSeq=${e.item?.loanSeq}`;
    if (e.item?.centerCode) url += `&centerCode=${e.item?.centerCode}`;
    if (e.item?.userId) url += `&userId=${e.item?.userId}`;
    if (e.item?.loanDate) url += `&loanDate=${e.item?.loanDate}`;
    if (e.item?.orderSeq) url += `&orderSeq=${e.item?.orderSeq}`;
    if (e.item?.invoiceSeq) url += `&invoiceSeq=${e.item?.invoiceSeq}`;

    httpClient
      .get(url)
      .then((rs) => {
        if (rs?.status === 200) {
          const data = rs.data.details || [];
          const rows = [];
          data?.forEach((detail) => {
            detail.inventories.forEach((inventory) => {
              rows.push({
                ...detail,
                ...inventory,
                e: JSON.stringify(e),
                loanSeq: rs.data.loanSeq,
                centerCode: inventory.centerCode,
                centerType: rs.data.centerType,
                centerCodeKr: selectlabel(inventory.centerCode, masterOptions?.CENTER_LBL),
                driverPhoneKr: selectlabel(rs.data.userId, masterOptions?.DRIVER_PHONE),
                teamKr: selectlabel(rs.data.userId, masterOptions?.DRIVER_MEMBERCOUNT) === 2 ? '2인 1조' : selectlabel(rs.data.userId, masterOptions?.DRIVER_MEMBERCOUNT) === 1 ? '1인 1조' : '',

                userId: rs.data.userId,
                statusKr: selectlabel(detail.status, masterOptions?.LOAN_STATUS),
                userIdKr: selectlabel(rs.data.userId, masterOptions?.DRIVER_NAME),
                loanDate: rs.data.loanDate,
                targetQuantity: inventory?.targetFlag === true ? 1 : 0,
                liteTargetQuantity: inventory?.liteTargetFlag === true ? 1 : 0,
                moveTargetQuantity: inventory?.moveTargetFlag === true ? 1 : 0,
                liteSubtractQuantity: inventory?.liteSubtractFlag === true ? 1 : 0,
                loanQuantity: inventory?.loanFlag === true ? 1 : 0,
                liteLoanQuantity: inventory?.liteLoanFlag === true ? 1 : 0,
                moveLoanQuantity: inventory?.moveLoanFlag === true ? 1 : 0,
                noLoanQuantity: inventory?.noLoanFlag === true ? 1 : 0,
                noLiteLoanQuantity: inventory?.noLiteLoanFlag === true ? 1 : 0,
                noMoveLoanQuantity: inventory?.noMoveLoanFlag === true ? 1 : 0,

                partnerSeqKr: selectlabel(inventory.partnerSeq, masterOptions?.SELLER),
                feeTypeKr: selectlabel(detail?.feeType, masterOptions?.FEE_TYPE),
                modelGroupLargeKr: selectlabel(inventory.modelGroupLarge, masterOptions?.MODEL_LARGE),
              });
            });
          });
          setBottomSheetObj({
            ...bottomSheetObj,
            visible: true,
            openSearchObj: e.item.searchObj,
            openEvent: e,
            hasTabs: [
              {
                title: '기사출고 상세',
                gridId: detailGridId,
                columns: detailColumns,
                rows: rows || [],
                buttons: [
                  {
                    color: 'orange',
                    title: '재고차감',
                    type: 'save-lite-loan-parts',
                    action: ({ event, items, searchObj }) => {
                      saveLiteLoanParts(items, event, searchObj);
                    },
                  },
                  {
                    color: 'red',
                    title: '재고차감취소',
                    type: 'save-lite-loan-cancel-parts',
                    action: ({ event, items, searchObj }) => {
                      saveLiteLoanCancelParts(items, event, searchObj);
                    },
                  },
                ],

                options: {
                  showRowAllCheckBox: true,
                  showRowCheckColumn: true,
                  showFooter: false,
                  rowCheckToRadio: false,
                },
                valueCheckedRows: {
                  dataField: 'invoiceSeq',
                },
              },
            ],
          });
        }
        dispatch(setLoading(null));
      })
      .catch((err) => {
        dispatch(setLoading(null));
      });
  };

  const saveLiteLoan = async (items, searchObj) => {
    const availables = items.filter((ele) => ele.statusKr === '출고지시대기' || ele.statusKr === '출고지시확정' || ele.statusKr === '출고중');
    if (availables?.length === items?.length) {
      const availables2 = items.filter((ele) => ele.liteTargetQuantity > 0);
      if (availables2?.length === items?.length) {
        const data = items.map((ele) => {
          return {
            loanSeq: ele.loanSeq,
            userId: ele.userId,
            centerCode: ele.centerCode,
            loanDate: ele.loanDate,
          };
        });
        const rs = await saveLiteLoanAPI(data);
        if (rs?.status) {
          alert(rs?.data?.message);
          if (rs?.status === 200 && rs?.data?.status === 200) fetchList(searchObj);
        }
      } else {
        alert('라이트버전이 있는 기사출고만 재고차감할 수 있습니다. 출고대상수량-라이트 건수를 확인해주세요');
      }
    } else {
      alert('출고지시대기, 출고지시확정 혹은 출고중인 상태만 재고차감이 가능합니다!');
    }
  };

  const saveLiteLoanCancelParts = async (items, event, searchObj) => {
    if (items?.length === 1) {
      if (items[0].liteSubtractQuantity === 1) {
        if (window.confirm('재고차감을  취소하시겠습니까?')) {
          const data = {
            loanSeq: items[0].loanSeq,
            invoiceSeq: items[0].invoiceSeq,
          };

          await httpClient.post(`/warehouse/loan/v2/save/lite/cancel`, data).then((rs) => {
            console.log(rs);
            if (rs?.status) {
              if (rs?.status === 200 && rs?.data?.status === 200) {
                alert('재고차감에 취소가 완료되었습니다!');
                fetchList(JSON.parse(searchObj));
                fetchDetailList(event);
              }
            }
          });
        }
      } else {
        alert('재고차감완료 수량(ERP) 1의 경우 재고차감취소가 가능합니다');
      }
    } else {
      alert('하나씩만 취소 가능합니다!');
    }
  };

  const saveLiteLoanParts = async (items, event, searchObj) => {
    if (items[0].statusKr === '출고지시대기' || items[0].statusKr === '출고지시확정' || items[0].statusKr === '출고중') {
      const availables2 = items.filter((ele) => ele.liteTargetQuantity > 0);
      if (availables2?.length === items?.length) {
        const invoiceSeq = items.map((ele) => ele.invoiceSeq);
        const data = [
          {
            loanSeq: items[0].loanSeq,
            userId: items[0].userId,
            centerCode: items[0].centerCode,
            loanDate: items[0].loanDate,
            invoiceSeq,
          },
        ];
        const rs = await saveLiteLoanAPI(data);
        if (rs?.status) {
          alert(rs?.data?.message);
          if (rs?.status === 200 && rs?.data?.status === 200) {
            fetchList(JSON.parse(searchObj));
            fetchDetailList(event);
          }
        }
      } else {
        alert('라이트버전이 있는 기사출고만 재고차감할 수 있습니다. 출고대상수량-라이트 건수를 확인해주세요');
      }
    } else {
      alert('출고지시대기, 출고지시확정 혹은 출고중인 상태만 재고차감이 가능합니다!');
    }
  };

  const saveLiteLoanAPI = async (data) => {
    if (window.confirm('기사출고 재고차감을 진행하시겠습니까?')) {
      return await httpClient.post(`/warehouse/loan/v2/save/lite`, data).then((rs) => {
        return rs;
      });
    }
  };

  const confirmPickingAPI = (dataArr, searchObj) => {
    httpClient.post('/warehouse/loan/v2/save', dataArr).then((rs) => {
      if (rs.data.message) alert(rs.data.message);
      fetchList(searchObj);
    });
  };

  const confirmLoan = (item, searchObj) => {
    if (item.statusKr === '출고지시대기') {
      const data = {
        loanSeq: item.loanSeq,
        userId: item.userId,
        centerCode: item.centerCode,
        loanDate: item.loanDate,
      };
      confirmPickingAPI([data], searchObj);
    } else {
      alert('출고지시대기 상태에서 출고지시확정 할 수 있습니다');
    }
  };

  const onCellClick = (e) => {
    if (e.pid.includes('loanRenewal_')) {
      if (e.dataField === 'print' && e.value) {
        printAction(e);
      }
    }
  };

  const printAction = (event) => {
    let url = `/RDCdriverLoadingPrint?&userId=${event.item.userId}&centerCode=${event.item.centerCode}&loanDate=${event.item.loanDate}`;
    if (event.item.loanSeq) url += `&loanSeq=${event.item.loanSeq}`;
    if (event.item.loanInitDatetime) url += `&loanInitDatetime=${event.item.loanInitDatetime?.replace(' ', '_')}`;
    window.open(url, '_blank');
  };

  const columns = [
    {
      headerText: '상세보기',
      renderer: {
        type: 'ButtonRenderer',
        labelText: '상세보기',
        onClick: fetchDetailList,
      },
    },
    {
      headerText: '출고지시서',
      dataField: 'print',
      style: 'click-fake-item',
    },
    {
      headerText: '기사출고번호',
      dataField: 'loanSeq',
    },
    {
      headerText: '출고예정일',
      dataField: 'loanDate',
    },
    {
      headerText: '창고타입',
      dataField: 'centerType',
    },
    {
      headerText: '창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '기사이름',
      dataField: 'userIdKr',
    },
    {
      headerText: '기사연락처',
      dataField: 'driverPhoneKr',
    },
    {
      headerText: '팀유형',
      dataField: 'teamKr',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '출고대상 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'targetQuantity',
        },
        {
          headerText: '라이트',
          dataField: 'liteTargetQuantity',
        },
        {
          headerText: '이전/수리',
          dataField: 'moveTargetQuantity',
        },
      ],
    },
    {
      headerText: '재고차감완료 수량(ERP)',
      children: [
        {
          headerText: '라이트',
          dataField: 'liteSubtractQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },

    {
      headerText: '출고완료 수량(APP)',
      children: [
        {
          headerText: '양품',
          dataField: 'loanQuantity',
        },
        {
          headerText: '라이트',
          dataField: 'liteLoanQuantity',
        },
        {
          headerText: '이전/수리',
          dataField: 'moveLoanQuantity',
        },
      ],
    },
    {
      headerText: '미출고 수량(APP)',
      children: [
        {
          headerText: '양품',
          dataField: 'noLoanQuantity',
        },
        {
          headerText: '라이트',
          dataField: 'noLiteLoanQuantity',
        },
        {
          headerText: '이전/수리',
          dataField: 'noMoveLoanQuantity',
        },
      ],
    },
    {
      headerText: '출고지시<br />확정일시',
      dataField: 'loanInitDatetime',
    },
    {
      headerText: '출고지시<br />확정자',
      dataField: 'loanInitId',
    },
    {
      headerText: '재고차감일시',
      dataField: 'loanLiteDatetime',
    },
    {
      headerText: '출고완료일시',
      dataField: 'loanDatetime',
    },
  ];

  const detailColumns = [
    {
      headerText: '기사출고번호',
      dataField: 'loanSeq',
    },
    {
      headerText: '출고예정일',
      dataField: 'loanDate',
    },
    {
      headerText: '기사정보',
      children: [
        {
          headerText: '창고타입',
          dataField: 'centerType',
        },
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '기사이름',
          dataField: 'userIdKr',
        },
        {
          headerText: '기사연락처',
          dataField: 'driverPhoneKr',
        },
        {
          headerText: '팀유형',
          dataField: 'teamKr',
        },
      ],
    },
    {
      headerText: '주문정보',
      children: [
        {
          headerText: '송장번호',
          dataField: 'invoiceSeq',
        },
        {
          headerText: '수령인',
          dataField: 'receiverName',
        },
        {
          headerText: '송장유형',
          dataField: 'feeTypeKr',
        },
        {
          headerText: '약속시간',
          dataField: 'deliveryDatetime',
        },
      ],
    },
    {
      headerText: '제품정보',
      children: [
        {
          headerText: '창고',
          dataField: 'centerCodeKr',
        },
        {
          headerText: '로케이션',
          dataField: 'locationCode',
        },
        {
          headerText: '제품그룹',
          dataField: 'modelGroupLargeKr',
        },
        {
          headerText: '제품',
          dataField: 'model',
        },
        {
          headerText: '제품명',
          dataField: 'modelName',
        },
        {
          headerText: '바코드',
          dataField: 'barcode',
        },
        {
          headerText: 'SKU',
          dataField: 'modelStockSeq',
        },
        {
          headerText: '외부SKU',
          dataField: 'skuNumber',
        },
        {
          headerText: '판매사',
          dataField: 'partnerSeqKr',
        },
      ],
    },
    {
      headerText: '출고대상 수량',
      children: [
        {
          headerText: '양품',
          dataField: 'targetQuantity',
        },
        {
          headerText: '라이트',
          dataField: 'liteTargetQuantity',
        },
        {
          headerText: '이전/수리',
          dataField: 'moveTargetQuantity',
        },
      ],
    },
    {
      headerText: '재고차감완료 수량(ERP)',
      children: [
        {
          headerText: '라이트',
          dataField: 'liteSubtractQuantity',
          filter: {
            showIcon: true,
          },
        },
      ],
    },

    {
      headerText: '출고완료 수량(APP)',
      children: [
        {
          headerText: '양품',
          dataField: 'loanQuantity',
        },
        {
          headerText: '라이트',
          dataField: 'liteLoanQuantity',
        },
        {
          headerText: '이전/수리',
          dataField: 'moveLoanQuantity',
        },
      ],
    },
    {
      headerText: '미출고 수량(APP)',
      children: [
        {
          headerText: '양품',
          dataField: 'noLoanQuantity',
        },
        {
          headerText: '라이트',
          dataField: 'noLiteLoanQuantity',
        },
        {
          headerText: '이전/수리',
          dataField: 'noMoveLoanQuantity',
        },
      ],
    },
    {
      headerText: '강제입력<br />시리얼번호',
      dataField: 'serialNumber',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고지시<br />확정일시',
      dataField: 'loanInitDatetime',
    },
    {
      headerText: '출고지시<br />확정자',
      dataField: 'loanInitId',
    },
    {
      headerText: '재고차감일시',
      dataField: 'loanLiteDatetime',
    },
    {
      headerText: '출고완료일시',
      dataField: 'loanDatetime',
      filter: {
        showIcon: true,
      },
    },
    {
      headerText: '출고완료자',
      dataField: 'loanId',
      filter: {
        showIcon: true,
      },
    },
  ];

  const downloadExcel = async (type) => {
    dispatch(setLoading('GET'));
    let url;

    if (type === 'main') {
      url = '/warehouse/loan/v2/list/excel?';
    } else {
      url = '/warehouse/loan/v2/list/excel/detail?';
    }
    url = paramingSearchObjToUrl(url, searchObj);

    return await httpClient.get(url).then((rs) => {
      if (rs.status === 200) {
        rs.data.forEach((row) => {
          if (type === 'main') {
            row.statusKr = selectlabel(row.status, masterOptions?.LOAN_STATUS);
            row.teamKr = selectlabel(row.userId, masterOptions?.DRIVER_MEMBERCOUNT) === 2 ? '2인 1조' : selectlabel(row.userId, masterOptions?.DRIVER_MEMBERCOUNT) === 1 ? '1인 1조' : '';
            row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER_LBL);
            row.userIdKr = selectlabel(row.userId, masterOptions?.DRIVER_NAME);
            row.driverPhoneKr = selectlabel(row.userId, masterOptions?.DRIVER_PHONE);
          } else {
            row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
            row.centerCodeKr = selectlabel(row.centerCode, masterOptions?.CENTER_LBL);
            row.statusKr = selectlabel(row.status, masterOptions?.LOAN_STATUS);
            row.userIdKr = selectlabel(row.userId, masterOptions?.DRIVER_NAME);
            row.driverPhoneKr = selectlabel(row.userId, masterOptions?.DRIVER_PHONE);
            row.teamKr = selectlabel(row.userId, masterOptions?.DRIVER_MEMBERCOUNT) === 2 ? '2인 1조' : selectlabel(row.userId, masterOptions?.DRIVER_MEMBERCOUNT) === 1 ? '1인 1조' : '';

            row.targetFlagKr = row.targetQuantity;
            row.moveTargetFlagKr = row.moveTargetQuantity;
            row.loanFlagKr = row.loanQuantity;
            row.moveLoanFlagKr = row.moveLoanQuantity;
            row.noLoanFlagKr = row.noLoanQuantity;
            row.noMoveLoanFlagKr = row.noMoveLoanQuantity;
            row.partnerSeqKr = selectlabel(row.partnerSeq, masterOptions?.SELLER);
            row.feeTypeKr = selectlabel(row?.feeType, masterOptions?.FEE_TYPE);
            row.modelGroupLargeKr = selectlabel(row.modelGroupLarge, masterOptions?.MODEL_LARGE);
          }
        });
        dispatch(setLoading(null));
        return rs.data;
      } else {
        dispatch(setLoading(null));
        return null;
      }
    });
  };

  return (
    <Presenter
      gridId={gridId}
      rows={rows}
      columns={columns}
      detailColumns={detailColumns}
      //
      pagingSetting={pagingSetting}
      setPagingSetting={setPagingSetting}
      searchObj={searchObj}
      setSearchObj={setSearchObj}
      fetchList={fetchList}
      onCellClick={onCellClick}
      downloadExcel={downloadExcel}
      getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
      setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
      //
      bottomSheetObj={bottomSheetObj}
      setBottomSheetObj={setBottomSheetObj}
    />
  );
};

export default Container;
