import { configureStore } from '@reduxjs/toolkit';

import authReducer from './services/authSlice';
import menuReducer from './services/menuSlice';
import localeReducer from './services/localeSlice';
import groupReducer from './services/groupSlice';
import portalReducer from './services/portalSlice';
import tabReducer from './services/tabSlice';

export const store = configureStore({
  reducer: {
    menu: menuReducer,
    auth: authReducer,
    locale: localeReducer,
    group: groupReducer,
    portal: portalReducer,
    tab: tabReducer,
  },

  //캐싱, 요청 취소, 폴링 등등 유용한 rtk-query의 기능들을 위한 api 미들웨어 추가
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // timeout 연장
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
