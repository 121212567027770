import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

// redux
import { RootState } from 'redux/store';
import { setLoading } from 'redux/services/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import useGridButton from 'hooks/grid/useGridButton';
import useUseableScreenTabFunctions from 'hooks/user/useUseableScreenTabFunctions';

// utils
import { PURCHASE_UPLOAD_FORM, PURCHASE_SERIAL_FORM } from 'envVar2';
import { useDidMountEffect } from 'hooks/basichooks';
import { returnDateyyyymmddHhmmss } from 'common/util/dateParsingFn';
import useSearchGridPaging from 'hooks/grid/useGridPaging';
import useExcelHooks from 'hooks/excel/useExcelHooksNew';
// service
import { serviceStore } from 'services/services';
import { PurchaseOrderDetailDTO, PurchaseOrderListDTO, PurchaseOrderListExcelDetailDTO, PurchaseOrderSearchDTO } from '../../_interface/warehouse';

// component
import * as IGrid from 'aui-grid';
import AUIGrid from 'modules/Grid';
import { PagingListDTO } from 'interface/util';
import { BottomSheets, IBottomSheetObj } from 'components/template/bottomSheets';
import GridBox, { GRID_PAGE_ROW_COUNT, IPagingGrid } from 'common/grid/gridBox';
import PrintGridBox from 'common/grid/printGridBox';
import { SearchBox } from './component/searchBox';
import { ISerialObj, SerialsPopup } from './component/serialPopup';

import { PurchaseOrderPrint, IPrintObj } from 'pages/IPGO/1purchaseOrder/purchaseOrderTab/puchaseOrder/_print/purchaseOrder';
import { PhotosPopup, IPhotoObj } from './component/photos/photosPopup';
import { HaldangPopup, IhangdangObj } from './component/haldang';
import { IPrint, Print } from './component/print';
import { ApprovePurchaseModal, ApprovePurchaseObjType } from './component/approvePurchaseModal';

const Index = ({ tabId }) => {
  const dispatch = useDispatch();
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state: RootState) => state.menu);
  const { reduxUserInfo } = useSelector((state: RootState) => state.auth);
  const gridRef = useRef<IPagingGrid>();
  const excelGridRef = useRef<AUIGrid>();
  const detailExcelGridRef = useRef<AUIGrid>();
  const bottomGridRef = useRef<IPagingGrid>();

  const [bottomSheetObj, setBottomSheetObj] = useState<IBottomSheetObj>();
  const [photoPopupObj, setPhotoPopupObj] = useState<IPhotoObj>();
  const [approvePurchaseObj, setApprovePurchaseObj] = useState<ApprovePurchaseObjType>({
    visible: false,
  });

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.bind('cellEditEnd', bindCellEdit);
    }
  }, []);

  const bindCellEdit = (e: IGrid.CellEditEndEvent) => {
    if (e.dataField === 'promiseDate') {
      changePromiseDate(e);
    } else if (e.dataField === 'memo') {
      saveMemo(e);
    }
  };

  const fetchPaging = async (searchObj: PurchaseOrderSearchDTO) => {
    dispatch(setLoading('GET'));
    if (bottomSheetObj?.visible) setBottomSheetObj(null);
    const dataKr = labellingKr((await serviceStore.warehouseAction(`purchase/list/paging`, 'GET', searchObj, null, true))?.data as PagingListDTO<PurchaseOrderListDTO>);
    dispatch(setLoading(null));
    return dataKr;
  };

  const { searchObj, setSearchObj, wrappedFetchList } = useSearchGridPaging({
    initialSearchObj: {
      pageNo: -1,
      pageSize: GRID_PAGE_ROW_COUNT,
    },
    gridRef,
    fetchPaging,
  });

  const saveMemo = async (e: IGrid.CellEditEndEvent) => {
    if (window.confirm('입력한 특이사항을 저장하시겠습니까?')) {
      dispatch(setLoading('POST'));
      let rs;
      if (e.item.purchaseDetailSeq) {
        //NOTE: detail/memo/save 에서는 배열에 담아 보내야함.
        rs = await serviceStore?.warehouseAction(`purchase/detail/memo/save`, 'POST', null, [{ purchaseDetailSeq: e.item.purchaseDetailSeq, memo: e.value }]);
        const detailE = JSON.parse(e.item.e);
        getPurchaseDetail(detailE);
      } else {
        rs = await serviceStore?.warehouseAction(`purchase/memo/save`, 'POST', null, { purchaseSeq: e.item.purchaseSeq, memo: e.value });
      }
      if (rs?.status === 200) {
        alert('특이사항 저장되었습니다!');
      }
      const searchObj = JSON.parse(e.item.searchObj);
      wrappedFetchList(searchObj);
    } else {
      const searchObj = JSON.parse(e.item.searchObj);
      wrappedFetchList(searchObj);
    }
  };

  const changePromiseDate = async (e: IGrid.CellEditEndEvent) => {
    if (e.item.partnerSeq === 46) {
      alert('판매사가 "바디프랜드"는 입고예정일 변경이 불가합니다');
    } else if (e.item.status === 'READY') {
      if (window.confirm('입력한 입고예정일로 수정하시겠습니까?')) {
        dispatch(setLoading('POST'));
        const rs = await serviceStore?.warehouseAction(`purchase/update/promiseDate`, 'PUT', null, { purchaseSeq: e.item.purchaseSeq, promiseDate: e.value });
        if (rs?.status === 200) {
          alert('입고예정일이 변경되었습니다!');
        }
        dispatch(setLoading(null));
      }
    } else {
      alert('입고대기 상태일때만 입고 예정일 변경 가능합니다');
    }
    const searchObj = JSON.parse(e.item.searchObj);
    wrappedFetchList(searchObj);
  };

  const getPhotos = (e) => {
    setPhotoPopupObj({
      //
      visible: true,
      title: '발주',
      mainSeq: 'purchaseSeq',
      item: e.item,
      searchObj: e.item.searchObj,
    });
  };

  const gridButtonhandler = (e) => {
    const id = e.target.id;
    if (id === 'PURCHASESERIALUPLOADFORM') {
      window.open(PURCHASE_SERIAL_FORM);
    } else if (id === 'GETPURCHASEFORBODY') {
      purchaseSaveForBody();
    } else if (id === 'PURCHASESERIALUPLOAD' || id === 'PURCHASESERIALBEUPLOAD' || id === 'PURCHASESAVEEXCELALLUPLOAD') {
      uploaderClick(id);
    } else if (id === 'PURCHASEEXCEL') {
      downloadExcel('main');
    } else if (id === 'PURCHASEEXCELDETAIL') {
      downloadExcel('detail');
    } else if (id === 'PURCHASESAVEEXCELALLFORM') {
      window.open(PURCHASE_UPLOAD_FORM);
    } else {
      handleCheckItems(e);
    }
  };

  const handleCheckItems = (e) => {
    const items = gridRef.current.getCheckedRowItemsAll();
    if (items?.length > 0) {
      const id = e.target.id;
      if (id === 'APPROVEPURCHASE') {
        approvePurchase(items);
      } else if (id === 'COMPLETEPURCHASE') {
        finishPurchase(items);
      } else if (id === 'CANCELPURCHASE') {
        cancelPurchase(items);
      }
    } else {
      alert('선택된 항목이 존재하지 않습니다.');
    }
  };

  // 기능권한 버튼
  const { fetchUsableFunctionsInThisTab, gridButtonUseable } = useUseableScreenTabFunctions();
  const { printFunctionToBtns } = useGridButton();
  const [functionBtns, setFunctionBtns] = useState<ReactNode | ReactNode[]>();
  const functions = fetchUsableFunctionsInThisTab(tabId);
  const gridFunctions = fetchUsableFunctionsInThisTab(tabId, 'GRID');
  useMemo(() => {
    const btns = printFunctionToBtns(functions, gridButtonhandler);
    setFunctionBtns(btns);
  }, [searchObj]);

  const getPurchaseDetail = async (e: IGrid.ButtonClickEvent) => {
    dispatch(setLoading('GET'));
    const gridButtonhandler = (e) => {
      const id = e.target.id;
      const items = bottomGridRef.current.getCheckedRowItemsAll();
      if (items?.length > 0) {
        if (id === 'PURCHASESAVEDESCDIFF') saveDiff(items);
        if (id === 'PURCHASE_DETAIL_BARCODEPRINT')
          setBarcodePrintObj({
            visible: true,
            items,
            type: 'detail',
          });
      } else {
        alert('선택된 항목이 존재하지 않습니다.');
      }
    };
    const buttons = printFunctionToBtns(
      gridFunctions?.filter((ele) => !ele?.functionId?.includes('SHOW')),
      gridButtonhandler,
    );
    const data = (await serviceStore.warehouseAction(`purchase/${e.item?.purchaseSeq}`, 'GET', null))?.data;
    const rows = data.details.map((row) => {
      return {
        ...row,
        e: JSON.stringify(e),
        searchObj: e.item.searchObj,
        printQuantity: 1,
        //
        registerDatetime: data.registerDatetime,
        registerId: data.registerId,
        centerCode: data.centerCode,
        //
        finishDatetime: data.finishDatetime,
        barcode: row.barcode,
        partnerSeq: data.partnerSeq,
        purchaseSeq: data.purchaseSeq,
        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],
        supplierPartnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.supplierPartnerSeq],
        brandKr: MASTER_OBJ?.BRAND?.[row?.brand],
        logisticTypeKr: MASTER_OBJ?.LOGISTIC_TYPE?.[row?.logisticType],
        workTypeKr: MASTER_OBJ?.WORK_TYPE?.[row?.workType],

        now: returnDateyyyymmddHhmmss(new Date(), '-'),
        promiseDate: data.promiseDate,
        diff: row.purchaseQuantity - row.locationQuantity,
        serialNumberLength: row.serialNumber?.split(',')?.length || 0,
      };
    });

    setBottomSheetObj({
      visible: true,
      hasTabs: [
        {
          title: '발주입고 상세',
          gridRef: bottomGridRef,
          columns: detailColumns,
          bindCellEdit,
          rows,
          buttons,
          options: {
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            editable: true,
          },
        },
      ],
    });

    dispatch(setLoading(null));
  };
  const [printObj, setPrintObj] = useState<IPrintObj>();
  const [barcodePrintObj, setBarcodePrintObj] = useState<IPrint>();
  const printBalju = (e: IGrid.ButtonClickEvent) => {
    setPrintObj({
      visible: true,
      type: e.dataField === 'confirmPrint' ? 'confirm' : 'model',
      data: e.item,
    });
  };

  const [haldangObj, setHaldang] = useState<IhangdangObj>();
  const showHaldang = (e: IGrid.ButtonClickEvent) => {
    setHaldang({
      visible: true,
      item: e.item,
    });
  };

  const [serialsPopupObj, setSerialsPopupObj] = useState<ISerialObj>();
  const popupSerials = (event: IGrid.ButtonClickEvent) => {
    const serials = event.item.serialNumber;

    const count = new Array(Number(event.item.purchaseQuantity)).fill('');
    const data = [];
    const serialArr = serials?.split(',');
    count.forEach((ele, idx) => {
      if (serialArr?.[idx]) data.push(serialArr?.[idx]);
      else data.push('');
    });
    setSerialsPopupObj({
      visible: true,
      item: event.item,
      event: JSON.stringify(event),
      searchObj: event.item.searchObj,
      fetchFn: (e) => getPurchaseDetail(e),
    });
  };

  const labellingKr = (data: PagingListDTO<PurchaseOrderListDTO & PurchaseOrderListExcelDetailDTO>, isExcel = false) => {
    const labeledList = data?.list?.map((row) => {
      return {
        ...row,
        ...gridButtonUseable(gridFunctions),
        searchObj: isExcel ? '' : JSON.stringify(searchObj),
        //

        centerCodeKr: MASTER_OBJ?.CENTER_WHOLE?.[row?.centerCode],
        modelGroupKr: MASTER_OBJ?.MODEL_GROUP?.[row?.modelGroup],
        partnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.partnerSeq],
        partnerSeqsKr: row.partnerSeqs?.map((ele) => MASTER_OBJ?.SELLER_WHOLE?.[ele]).join(','),
        logisticTypeKr: MASTER_OBJ?.LOGISTIC_TYPE?.[row?.logisticType],
        workTypeKr: MASTER_OBJ?.WORK_TYPE?.[row?.workType],

        supplierPartnerSeqsKr: row.supplierPartnerSeqs?.map((ele) => MASTER_OBJ?.SELLER_WHOLE?.[ele]).join(','),
        supplierPartnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.supplierPartnerSeq],
        networkPartnerSeqKr: MASTER_OBJ?.SELLER_WHOLE?.[row?.networkPartnerSeq],
        statusKr: MASTER_OBJ?.PO_STATUS?.[row?.status],
        description: MASTER_OBJ?.PO_RECEIVE?.[row?.description],
      };
    });
    return {
      ...data,
      list: labeledList,
    };
  };

  const downloadExcel = async (type: 'main' | 'detail') => {
    dispatch(setLoading('GET'));
    const { list } = labellingKr(
      {
        list: (await serviceStore.warehouseAction(type === 'main' ? 'purchase/list/excel' : 'purchase/list/excel/detail', 'GET', searchObj, null))?.data as PurchaseOrderListDTO[],
      },
      true,
    );

    if (type === 'main') {
      excelGridRef.current.setGridData(list);
      excelGridRef.current.exportAsXlsx({ fileName: '발주입고' });
    } else {
      detailExcelGridRef.current.setGridData(list);
      detailExcelGridRef.current.exportAsXlsx({ fileName: '발주입고(상세)' });
    }
    dispatch(setLoading(null));
  };

  const columns: IGrid.Column[] = [
    {
      headerText: '상세보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '상세보기',
        onClick: getPurchaseDetail,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.PURCHASE_GRID_SHOWDETAIL;
        },
      },
    },
    {
      headerText: '사진보기',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '사진보기',
        onClick: getPhotos,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.PURCHASE_GRID_SHOWPICTURE;
        },
      },
    },
    {
      headerText: '발주명세',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '프린트',
        onClick: printBalju,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.PURCHASE_GRID_SHOWBALJUPRINT;
        },
      },
    },
    {
      headerText: '입고명세',
      dataField: 'confirmPrint',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '프린트',
        onClick: printBalju,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.PURCHASE_GRID_SHOWIPGOPRINT;
        },
      },
    },
    {
      headerText: '할당정보',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        labelText: '할당정보',
        onClick: showHaldang,
        disabledFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return !item?.PURCHASE_GRID_SHOWHALDANG;
        },
        visibleFunction: (rowIndex: number, columnIndex: number, value: any, item: any, dataField: string) => {
          return item?.mtoFlag;
        },
      },
    },
    {
      headerText: '발주번호',
      dataField: 'purchaseSeq',
    },
    {
      headerText: '외부발주번호',
      dataField: 'externalPurchaseNumber',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqsKr',
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqsKr',
    },
    {
      headerText: '입고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '발주일',
      dataField: 'purchaseDate',
    },
    {
      headerText: '발주등록일시',
      dataField: 'registerDatetime',
    },
    {
      dataField: 'promiseDate',
      headerText: '입고예정일',
      width: 120,
      dataType: 'date',
      formatString: 'yyyy-mm-dd',
      editable: true,
      editRenderer: {
        onlyCalendar: true,
        type: IGrid.EditRendererKind.CalendarRenderer,
        showTodayBtn: true,
        showEditorBtnOver: true,
        showExtraDays: true,
        titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        defaultFormat: 'yyyy-mm-dd',
      },
      style: 'my-custom-aui',
    },
    {
      headerText: '입고완료일',
      dataField: 'finishDate',
    },
    {
      headerText: '수량',
      children: [
        {
          headerText: '발주',
          dataField: 'purchaseCount',
        },
        {
          headerText: '스캔',
          dataField: 'confirmCount',
        },
        {
          headerText: '입고완료',
          dataField: 'inventoryCount',
        },
        {
          headerText: '미입고(발주-입고완료)',
          width: 115,
          dataField: 'diffCount',
        },
      ],
    },
    {
      headerText: '발주등록자',
      dataField: 'registerId',
    },
    {
      headerText: '발주마감일',
      dataField: 'receiveDate',
    },
    {
      headerText: '발주마감자',
      dataField: 'approveId',
    },
    {
      headerText: '원가',
      dataField: 'totalPrimePrice',
    },
    {
      headerText: '운송타입',
      dataField: 'logisticTypeKr',
    },
    {
      headerText: '작업유형',
      dataField: 'workTypeKr',
    },
    {
      headerText: '파렛트수량',
      dataField: 'palletQuantity',
    },
    {
      headerText: '비고',
      dataField: 'memo',
      editable: true,
      headerTooltip: {
        show: true,
        tooltipHtml: `<div">
        직접 수정 가능
          </div>`,
      },
    },
  ];

  const detailColumns: IGrid.Column[] = [
    {
      headerText: '발주번호',
      dataField: 'purchaseSeq',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },

    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '바코드 출력 매수(부)',
      dataField: 'printQuantity',
      dataType: 'numeric',
      style: 'my-custom-aui',
      renderer: {
        type: IGrid.RendererKind.TemplateRenderer,
        aliasFunction: function (rowIndex, columnIndex, value, headerText, item) {
          // 엑셀, PDF 등 내보내기 시 값 가공 함수
          return value;
        },
      },
    },
    {
      headerText: '적재위치',
      dataField: 'locationCode',
      style: 'click-fake-item',
    },
    {
      headerText: '원가(단가)',
      dataField: 'totalPrimePrice',
    },
    {
      headerText: '발주수량',
      dataField: 'purchaseQuantity',
    },
    {
      headerText: '스캔수량',
      dataField: 'confirmQuantity',
    },
    {
      headerText: '입고완료수량',
      dataField: 'inventoryQuantity',
      renderer: {
        type: IGrid.RendererKind.ButtonRenderer,
        onClick: popupSerials,
      },
    },
    {
      headerText: '미입고수량(발주-입고완료)',
      dataField: 'diffQuantity',
    },
    {
      headerText: '수량차이사유',
      dataField: 'description',
      renderer: {
        type: IGrid.RendererKind.DropDownListRenderer,
        list: MASTER_OPS?.PO_RECEIVE,
        keyField: 'value',
        valueField: 'label',
      },
    },
    {
      headerText: '입고확정일시',
      dataField: 'finishDatetime',
    },
    {
      headerText: '운송타입',
      dataField: 'logisticTypeKr',
    },
    {
      headerText: '작업유형',
      dataField: 'workTypeKr',
    },
    {
      headerText: '비고',
      dataField: 'memo',
      editable: true,
      style: 'aui-textinputer',
      editRenderer: {
        type: IGrid.EditRendererKind.InputEditRenderer,
        // validator: (oldValue: any, newValue: any, item: any, dataField: string, fromClipboard: boolean, which: any) => {
        //   if (item?.availableQuantity + item?.defectedQuantity + item?.etcQuantity === 0) return { validate: false, message: '이동가능한 수량이 없습니다' };
        //   if (item?.availableQuantity + item?.defectedQuantity + item?.etcQuantity < newValue) return { validate: false, message: '이동가능한 수량 초과' };
        // },
      },
      headerTooltip: {
        show: true,
        tooltipHtml: `<div">
        직접 수정 가능
          </div>`,
      },
    },
  ];

  const detailExcelColumns: IGrid.Column[] = [
    {
      headerText: '발주번호',
      dataField: 'purchaseSeq',
    },
    {
      headerText: '외부발주번호',
      dataField: 'externalPurchaseNumber',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '공급사',
      dataField: 'supplierPartnerSeqKr',
    },
    {
      headerText: '위탁사',
      dataField: 'networkPartnerSeqKr',
    },

    {
      headerText: '입고창고',
      dataField: 'centerCodeKr',
    },
    {
      headerText: '진행상태',
      dataField: 'statusKr',
    },
    {
      headerText: '발주일',
      dataField: 'purchaseDate',
    },
    {
      headerText: '발주등록일시',
      dataField: 'registerDatetime',
    },
    {
      headerText: '입고예정일',
      dataField: 'promiseDate',
    },
    {
      headerText: '입고확정일',
      dataField: 'finishDate',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: '원가(단가)',
      dataField: 'primePrice',
    },
    {
      headerText: '발주수량',
      dataField: 'purchaseQuantity',
    },
    {
      headerText: '스캔수량',
      dataField: 'confirmQuantity',
    },
    {
      headerText: '입고완료수량',
      dataField: 'inventoryQuantity',
    },
    {
      headerText: '미입고(발주-입고완료)',
      dataField: 'diffQuantity',
    },
    {
      headerText: '수량차이사유',
      dataField: 'description',
    },
    {
      headerText: '발주일',
      dataField: 'purchaseDate',
    },
    {
      headerText: '발주등록자',
      dataField: 'registerId',
    },
    {
      headerText: '발주마감일',
      dataField: 'receiveDate',
    },
    {
      headerText: '발주마감자',
      dataField: 'approveId',
    },
    {
      headerText: '운송타입',
      dataField: 'logisticTypeKr',
    },
    {
      headerText: '작업유형',
      dataField: 'workTypeKr',
    },
    {
      headerText: '비고',
      dataField: 'memo',
    },
  ];

  const purchaseSaveForBody = async () => {
    if (window.confirm('내자/외자 발주입고를 생성하시겠습니까?')) {
      const rs = await serviceStore.warehouseAction(`purchase/body/list/save`, 'POST', null, null);
      if (rs?.status === 200) {
        alert('바디 내자/외자 발주입고가 강제 생성되었습니다!');
      }
    }
  };

  const validatorCancel = (items) => {
    if (items?.length > 1) {
      alert('1개의 발주목록만 취소할 수 있습니다\n 1개의 체크박스만 선택해주세요');
      return false;
    } else if (items?.length === 1) {
      return true;
    }
  };

  const cancelPurchase = async (items) => {
    if (validatorCancel(items)) {
      if (window.confirm('한번 취소된 발주목록은 복구 하실수 없습니다\n"발주취소"를 진행하시겠습니까?')) {
        const rs = await serviceStore.warehouseAction(`purchase/${items[0]?.purchaseSeq}/cancel`, 'PUT', null, null);
        if (rs?.status === 200) {
          alert('취소 되었습니다.');
          wrappedFetchList(searchObj);
        }
      }
    }
  };

  const approvePurchase = async (items) => {
    let confirmapporve;

    if (items.filter((item) => item?.status !== 'READY' && item?.status !== 'RECEIVING' && item?.status !== 'FINISH')?.length > 0) {
      alert(
        `발주마감이 불가능한 발주입고가 포함되어있습니다. \n처리 불가능한 입고상태 : ${MASTER_OPS?.PO_STATUS?.filter(
          (code) => code.value !== 'READY' && code.value !== 'RECEIVING' && code.value !== 'FINISH',
        )
          ?.map((item) => item.label)
          ?.join(', ')}`,
      );
      confirmapporve = false;
    } else if (items.filter((item) => item?.status === 'READY' || item?.status === 'RECEIVING')?.length > 0) {
      confirmapporve = window.confirm('발주 수량이 모두 입고되지 않은 건이 있습니다. 그래도 발주마감하시겠습니까?');
    } else {
      confirmapporve = window.confirm('발주 마감하시겠습니까?');
    }
    if (confirmapporve) {
      setApprovePurchaseObj({
        visible: true,
        items,
        searchObj: items?.[0]?.searchObj,
        fetchList: wrappedFetchList,
      });
    }
  };

  const saveDiff = async (items) => {
    if (window.confirm('수량차이사유를 수정 하시겠습니까?')) {
      const rs = await serviceStore.warehouseAction(
        `purchase/update/description`,
        'PUT',
        null,
        items.map((ele: PurchaseOrderDetailDTO) => {
          return {
            purchaseDetailSeq: ele.purchaseDetailSeq,
            description: ele.description,
          };
        }),
      );

      if (rs?.status === 200) {
        alert('수량차이사유가 저장되었습니다!');
      }
      const searchObj = JSON.parse(items[0].searchObj);
      wrappedFetchList(searchObj);
      const e = JSON.parse(items[0].e);
      getPurchaseDetail(e);
    }
  };

  const finishPurchase = async (items) => {
    if (window.confirm('완료처리 하시겠습니까?')) {
      const rs = await serviceStore.warehouseAction(`purchase/finish`, 'POST', null, { purchaseSeqs: items?.map((ele) => ele.purchaseSeq) });
      if (rs?.status === 200) {
        alert('완료처리 되었습니다');
        wrappedFetchList(searchObj);
      }
    }
  };

  const savePurchaseSerialExcel = async (excelrows, isBe = false) => {
    if (excelrows?.length === 0) {
      alert('추출된 값이 없습니다. 엑셀을 확인해주세요');
    } else {
      let rs = await serviceStore.warehouseAction(isBe ? `purchase/excel/schedule/serial/number/save` : `purchase/excel/serial/number/save`, 'POST', null, excelrows);
      if (rs.status === 200) {
        alert('모든 시리얼번호 등록이 완료되었습니다!');
        wrappedFetchList(searchObj);
      }
    }
  };

  const savePurchaseAll = async (excelrows) => {
    dispatch(setLoading('POST'));
    const externalPurchaseNumberArr = [];
    const externalPurchaseNumberIdx = [];
    const dataDTO = [];
    excelrows.forEach((row, idx) => {
      if (!externalPurchaseNumberArr?.includes(row.externalPurchaseNumber)) {
        externalPurchaseNumberIdx.push(externalPurchaseNumberArr.length);
        dataDTO.push({
          externalPurchaseNumber: row.externalPurchaseNumber,
          centerCode: row.centerCode,
          purchaseDate: row.purchaseDate,
          promiseDate: row.promiseDate,
          logisticType: row.logisticType,
          workType: row.workType,
          memo: row.memo,
          details: [
            {
              partnerSeq: row.partnerSeq,
              barcode: row.barcode,
              skuNumber: row.skuNumber,
              purchaseQuantity: row.purchaseQuantity,
              primePrice: row.primePrice,
              memo: row.memo,
              invoiceSeqs: row.invoiceSeqs ? row.invoiceSeqs?.split(',') : null,
              scheduleSerialNumbers: row.scheduleSerialNumbers ? row.scheduleSerialNumbers?.split(',') : null,
            },
          ],
        });
        externalPurchaseNumberArr?.push(row.externalPurchaseNumber);
      } else {
        dataDTO[externalPurchaseNumberArr.indexOf(row.externalPurchaseNumber)]['details'].push({
          partnerSeq: row.partnerSeq,
          barcode: row.barcode,
          skuNumber: row.skuNumber,
          purchaseQuantity: row.purchaseQuantity,
          primePrice: row.primePrice,
          memo: row.memo,
          invoiceSeqs: row.invoiceSeqs ? row.invoiceSeqs?.split(',') : null,
          scheduleSerialNumbers: row.scheduleSerialNumbers ? row.scheduleSerialNumbers?.split(',') : null,
        });
      }
    });
    const rs = await serviceStore.warehouseAction(`purchase/save/excel`, 'POST', null, dataDTO);
    if (rs.status === 200) {
      alert('엑셀로 발주등록이 완료되었습니다!');
      wrappedFetchList(searchObj);
    }
    dispatch(setLoading(null));
  };

  const HEADERLINEIDX = {
    PURCHASESAVEEXCELALLUPLOAD: 1,
  };

  const KRTOVALUE = {
    centerCode: 'CENTER_WHOLE',
    logisticType: 'LOGISTIC_TYPE',
    workType: 'WORK_TYPE',
    partnerSeq: 'SELLER_WHOLE',
  };

  const EXCELHEADER = {
    PURCHASESERIALBEUPLOAD: ['purchaseSeq', 'externalPurchaseNumber', 'barcode', 'serialNumber'],
    PURCHASESERIALUPLOAD: ['purchaseSeq', 'externalPurchaseNumber', 'barcode', 'serialNumber'],
    PURCHASESAVEEXCELALLUPLOAD: [
      'externalPurchaseNumber',
      'centerCode',
      'purchaseDate',
      'promiseDate',
      'logisticType',
      'workType',
      'memo',
      'partnerSeq',
      'barcode',
      'skuNumber',
      'purchaseQuantity',
      'primePrice',
      'memo',
      'invoiceSeqs',
      'scheduleSerialNumbers',
    ],
  };


  const { ExcelDetector, uploaderClick, parsedData } = useExcelHooks({ EXCELHEADER, KRTOVALUE, HEADERLINEIDX });

  useDidMountEffect(() => {
    if (parsedData?.data?.length > 0) {
      if (parsedData?.id?.includes('PURCHASESERIAL')) {
        savePurchaseSerialExcel(parsedData?.data, parsedData?.id?.includes('BE'));
      } else if (parsedData?.id === 'PURCHASESAVEEXCELALLUPLOAD') {
        savePurchaseAll(parsedData?.data);
      }
    }
  }, [parsedData]);

  return (
    <div className="page-content">
      {barcodePrintObj?.visible && <Print printObj={barcodePrintObj} setPrintObj={setBarcodePrintObj} />}
      {haldangObj?.visible && <HaldangPopup haldangObj={haldangObj} setHaldangObj={setHaldang} />}
      {printObj?.visible && <PurchaseOrderPrint printObj={printObj} setPrintObj={setPrintObj} />}
      {photoPopupObj?.visible && <PhotosPopup photoPopupObj={photoPopupObj} setPhotoPopupObj={setPhotoPopupObj} />}
      {bottomSheetObj?.visible && <BottomSheets bottomSheetObj={bottomSheetObj} setBottomSheetObj={setBottomSheetObj} />}
      {serialsPopupObj?.visible && <SerialsPopup serialsPopupObj={serialsPopupObj} setSerialsPopupObj={setSerialsPopupObj} />}
      {approvePurchaseObj?.visible && <ApprovePurchaseModal approvePurchaseObj={approvePurchaseObj} setApprovePurchaseObj={setApprovePurchaseObj} />}
      <div className="presenterSearch">
        <SearchBox searchObj={searchObj} setSearchObj={setSearchObj} fetchList={wrappedFetchList} />
      </div>
      <div className="presenterGridBox">
        {functionBtns && (
          <div className="grid-button-area">
            {functionBtns}
            <ExcelDetector />
          </div>
        )}
        <GridBox
          gridRef={gridRef}
          columns={columns}
          gridProps={{
            showRowAllCheckBox: true,
            showRowCheckColumn: true,
            editable: true,
            fixedColumnCount: 5,
          }}
        />
        <PrintGridBox gridRef={excelGridRef} columns={columns.filter((_, idx) => idx > 3)} />
        <PrintGridBox gridRef={detailExcelGridRef} columns={detailExcelColumns} />
      </div>
    </div>
  );
};

export default Index;
