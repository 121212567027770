import { Row, Col } from 'reactstrap';

//redux
import { useSelector } from 'react-redux';

//components
import Grid from 'components/grid/auiGrid';
import { FlatpickrD, SelectD } from 'components/reactstrap/reactstrap';
import { selectlabel } from 'common/master/codeMasterReturnHelper';
import { SearchModelForRefurModal } from './searchModelForRefur';
import { returnDateyyyymmdd } from 'common/util/dateParsingFn';

const Presenter = (props) => {
  const { modelModalObj, setModelModalObj } = props;

  return (
    <>
      <div className="page-content">
        {modelModalObj?.visible && <SearchModelForRefurModal modelModalObj={modelModalObj} setModelModalObj={setModelModalObj} />}
        <div className="presenterSearch">
          <Search {...props} />
        </div>
        <div className="presenterGridBox">
          <GridBox {...props} />
        </div>
      </div>
    </>
  );
};

const Search = ({ searchObj, setSearchObj, createRefur, gridId }) => {
  const { MASTER_OBJ, MASTER_OPS } = useSelector((state) => state.menu);

  return (
    <>
      <Row>
        <Col>
          <label className="col-form-label">창고*</label>
          <SelectD
            options={MASTER_OPS?.CENTER_AUTH}
            value={
              searchObj?.centerCode
                ? {
                    value: searchObj?.centerCode,
                    label: MASTER_OBJ?.CENTER_AUTH?.[searchObj?.centerCode],
                  }
                : null
            }
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                centerCode: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">판매사*</label>
          <SelectD
            options={MASTER_OPS?.SELLER_SELLER_AUTH}
            value={
              searchObj?.partnerSeq
                ? {
                    value: searchObj?.partnerSeq,
                    label: MASTER_OBJ?.SELLER_SELLER_AUTH?.[searchObj?.partnerSeq],
                  }
                : null
            }
            onChange={({ value }) => {
              setSearchObj({
                ...searchObj,
                partnerSeq: value,
              });
            }}
          />
        </Col>
        <Col>
          <label className="col-form-label">변경일*</label>
          <FlatpickrD
            options={{ mode: 'single' }}
            value={searchObj?.modifyDate}
            onReset={() => {
              setSearchObj({
                ...searchObj,
                modifyDate: null,
              });
            }}
            onChange={(value) => {
              setSearchObj({
                ...searchObj,
                modifyDate: returnDateyyyymmdd(value[0]),
              });
            }}
          />
        </Col>
        <Col />
        <Col />
        <Col style={{ textAlign: 'right' }}>
          <button
            className="btn btn-blue"
            onClick={(e) => {
              e.preventDefault();
              createRefur(searchObj, window.AUIGrid.getGridData(`#${gridId}`));
            }}
          >
            리퍼생성 신청
          </button>
        </Col>
      </Row>
    </>
  );
};

const GridBox = ({ gridId, rows, columns, searchObj, modelModalObj, setModelModalObj, getCheckedDataTriggerThisGrid, setGetCheckedDataTriggerThisGrid }) => {
  return (
    <>
      {gridId && (
        <>
          <div className="grid-button-area">
            <div
              className="orange"
              onClick={() => {
                // if(!searchObj?.centerCode) {
                //   alert('제품추가를 위한 창고를 선택하세요!');
                // } else if(!searchObj?.partnerSeq) {
                //   alert('제품추가를 위한 판매사를 선택하세요!');
                // } else {
                setModelModalObj({
                  ...modelModalObj,
                  visible: true,
                  type: 'model',
                  searchObj: searchObj,
                });
                // }
              }}
            >
              +제품추가
            </div>
            <div
              className="orange"
              onClick={() => {
                setGetCheckedDataTriggerThisGrid({
                  type: 'pick-afterModel',
                  id: `#${gridId}`,
                  withRowIndex: true,
                });
              }}
            >
              변경제품 선택
            </div>
            <div
              className="red"
              onClick={() => {
                setGetCheckedDataTriggerThisGrid({
                  type: 'delete-row',
                  id: `#${gridId}`,
                  withRowIndex: true,
                });
              }}
            >
              선택행 삭제
            </div>
          </div>
          <Grid
            id={gridId}
            rows={rows}
            columns={columns}
            settingOptions={{
              isRowAllCheckCurrentView: true,
              showRowAllCheckBox: true,
              showRowCheckColumn: true,
            }}
            getCheckedDataTriggerThisGrid={getCheckedDataTriggerThisGrid}
            setGetCheckedDataTriggerThisGrid={setGetCheckedDataTriggerThisGrid}
          />
        </>
      )}
    </>
  );
};

export default Presenter;
