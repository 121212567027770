import CommonModalNew from 'components/modal/commonModalNew';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import AUIGrid from 'modules/Grid';
import * as IGrid from 'aui-grid';
import GridBox, { defaultGridProps } from 'common/grid/gridBox';
import { outService } from '../../_service/service';
import { LinehaulPickingDetailSearchDTO } from 'interface/warehouse';
import { OptionItem, selectlabel } from 'common/master/codeMasterReturnHelper';
import { IDropshippingPrint, Prints } from 'pages/OUT/3driver/fcPickingTab/total/component/prints';
import { serviceStore } from 'services/services';
import { InputD, SelectD } from 'components/reactstrap/reactstrap';
import { Col, Form, Row } from 'reactstrap';
import { useFrontSearchData } from 'hooks/grid/useFrontSearchData';
import { setLoading, setMasterOptions } from 'redux/services/menuSlice';
import Divider from 'components/LDS/atoms/Divider';
import { resetAllSelections, toggleBooleanOptions } from 'common/util/searchBox';

export interface IHaldangPopupObj {
  visible?: boolean;
  item?: any;
  fetchFn?: () => {};
  fns: {
    'linehaul-modal-rollback': () => {};
    'linehaul-modal-confirm': () => {};
    'linehaul-modal-cancel': () => {};
  };
}

export type actionParamsProp = {
  linehaulSeq?: number;
  loadingStatementSeq?: number;
  loadingOutSeq?: number;
  transportStatementSeq?: string;
  originalCenterCode?: string;
  destinationCenterCode?: string;
  linehaulDate?: string;
  linehaulType?: string;
  status?: string;
  statusKr?: string;
  stockRemoveSeq?: number;
};

export const HaldangPopup = ({ haldangPopupObj, setHaldangPopupObj }) => {
  const { masterOptions } = useSelector((state: RootState) => state.menu);
  const [printObj, setPrintObj] = useState<IDropshippingPrint>();
  const gridRef = useRef<AUIGrid>();
  const dispatch = useDispatch();
  const columns: IGrid.Column[] = [
    {
      headerText: '피킹여부',
      dataField: 'pickFlagKr',
    },
    {
      headerText: '주문취소여부',
      dataField: 'orderCancelFlagKr',
    },
    {
      headerText: '재고번호',
      dataField: 'inventorySeq',
    },
    {
      headerText: '시리얼번호',
      dataField: 'serialNumber',
    },
    {
      headerText: '재고유형',
      dataField: 'availableTypeKr',
    },
    {
      headerText: '판매사',
      dataField: 'partnerSeqKr',
    },
    {
      headerText: '제품그룹',
      dataField: 'modelGroupKr',
    },
    {
      headerText: '제품',
      dataField: 'model',
    },
    {
      headerText: '제품명',
      dataField: 'modelName',
    },
    {
      headerText: '바코드',
      dataField: 'barcode',
    },
    {
      headerText: 'SKU',
      dataField: 'modelStockSeq',
    },
    {
      headerText: '외부SKU',
      dataField: 'skuNumber',
    },
    {
      headerText: '상품명',
      dataField: 'productName',
    },
    {
      headerText: '주문번호',
      dataField: 'orderSeq',
    },
    {
      headerText: '외부주문번호',
      dataField: 'externalOrderNumber',
    },
    {
      headerText: '송장번호',
      dataField: 'invoiceSeq',
    },
    {
      headerText: '외부송장번호',
      dataField: 'externalInvoiceNumber',
    },
    {
      headerText: '고객명',
      dataField: 'customerName',
    },
    {
      headerText: '고객주소',
      dataField: 'customerAddr',
    },
    {
      headerText: '연락처',
      dataField: 'customerPhone',
    },
    {
      headerText: '권역정보',
      dataField: 'zipcodeGroupInfo',
    },
    {
      headerText: '도크코드',
      dataField: 'dockNumber',
    },
    {
      headerText: '기사명', // 이전 : 설치기사
      dataField: 'driverName',
    },
    {
      headerText: '기사소속창고', // 이전 : 기사출고창고
      dataField: 'driverCenterCodeKr',
    },
    {
      headerText: '지정일', // 이전 : 기사출고예정일
      dataField: 'deliveryDate',
    },
    {
      headerText: '고객주문일',
      dataField: 'orderDate',
    },
    {
      headerText: '비고',
      dataField: 'memo',
    },
    /*
    {
      headerText: '주문등록일',
      dataField: 'orderDatetime',
    },
    {
      headerText: '간선번호',
      dataField: 'linehaulSeq',
    },
    {
      headerText: '상차번호',
      dataField: 'loadingStatementSeq',
    },
    {
      headerText: '입고번호',
      dataField: 'transportStatementSeq',
    },
    {
      headerText: '출발창고',
      dataField: 'originalCenterCodeKr',
    },
    {
      headerText: '도착창고',
      dataField: 'destinationCenterCodeKr',
    },
    {
      headerText: '입고예정일',
      dataField: 'promiseUnloadDate',
    },
    */
  ];

  const [actionParams, setActionParams] = useState<actionParamsProp>({});

  const convertKeyValue = {
    customerAddr: (row) => (row?.customerAddr1 ? `${row?.customerAddr1} ${row?.customerAddr2 || ''}` : ''),
    centerCodeKr: (row) => selectlabel(row?.centerCode, masterOptions?.CENTER),
    originalCenterCodeKr: (row) => selectlabel(row?.originalCenterCode, masterOptions?.CENTER),
    destinationCenterCodeKr: (row) => selectlabel(row?.destinationCenterCode, masterOptions?.CENTER),
    driverCenterCodeKr: (row) => selectlabel(row?.driverCenterCode, masterOptions?.CENTER),
    partnerSeqKr: (row) => selectlabel(row?.partnerSeq, masterOptions?.SELLER),
    modelGroupKr: (row) => selectlabel(row?.modelGroup, masterOptions?.MODEL_GROUP),
    availableTypeKr: (row) => selectlabel(row?.availableType, masterOptions?.INVA_TYPE),
    unloadFlagKr: (row) => (row?.unloadFlag === true ? 'O' : 'X'),
    orderCancelFlagKr: (row) => (row?.orderCancelFlag === true ? 'O' : 'X'),
    pickFlagKr: (row) => (row?.pickFlag === true ? 'O' : 'X'),
    loadFlagKr: (row) => (row?.loadFlag === true ? 'O' : 'X'),
  };

  const detailSelectType = { INVENTORY_SEQ: null, ORDER_SEQ: null, SEARCH_MODEL: null };

  const { setGridData, setDetailSelectTypeObj, handleSubmitFiltering, detailSelectTypeObj, detailSearchObj, setDetailSearchObj, convertFieldValue, returnKeyValues, resetFilters } = useFrontSearchData(
    {
      gridRef,
      initDetailSearchObj: {},
      detailSelectType,
      convertKeyValue,
    },
  );

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.INVENTORY_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.INVENTORY_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.ORDER_SEQ, detailSearchObj));
  }, [detailSelectTypeObj?.ORDER_SEQ]);

  useEffect(() => {
    setDetailSearchObj(resetAllSelections(masterOptions?.SEARCH_MODEL, detailSearchObj));
  }, [detailSelectTypeObj?.SEARCH_MODEL]);

  useEffect(() => {
    fetchHaldangInfo();
  }, [haldangPopupObj?.origin]);

  const handleCheckItems = (e) => {
    const items = gridRef?.current?.getCheckedRowItemsAll();
    const id = e?.target?.id;

    if (id === 'excel') {
      gridRef?.current?.exportAsXlsx({ fileName: '간선피킹_할당정보' });
      return;
    }

    if (items?.length === 0) {
      alert('선택된 항목이 존재하지 않습니다.');
      return;
    }

    const paramKeys = Object.keys(actionParams);
    for (let param of paramKeys) {
      items[0][param] = actionParams?.[param];
    }

    if (id === 'linehaul-modal-rollback') {
      if (!actionParams?.linehaulSeq) {
        alert('피킹지시번호가 없습니다!');
        return;
      }
      if (actionParams?.status !== 'READY' && actionParams?.status !== 'PICKING') {
        alert('피킹중, 피킹지시확정 상태일 때만 가능합니다!');
        return;
      }
    }

    if (id === 'linehaul-modal-cancel') {
      if (actionParams?.status !== 'INIT' && actionParams?.status !== 'READY') {
        alert('피킹지시대기, 피킹지시확정 상태일 때만 가능합니다!');
        return;
      }
    }

    if (id === 'linehaul-modal-confirm') {
      if (actionParams?.linehaulSeq) {
        alert('피킹지시번호가 이미 존재합니다!');
        return;
      }
      if (actionParams?.status !== 'INIT') {
        alert('피킹지시대기 상태일 때만 가능합니다!');
        return;
      }
    }

    if (id === 'prints') {
      // 송장번호가 없을때 방어로직 추가
      for (let item of items) {
        if (!item?.invoiceSeq) {
          alert('송장번호가 없는 항목이 존재합니다!');
          return;
        }
      }

      setPrintObj({
        visible: true,
        items,
      });
    } else {
      haldangPopupObj?.fns?.[id]?.(items);
    }
  };

  const fetchHaldangInfo = async () => {
    dispatch(setLoading('GET'));
    const searchDTO = {
      linehaulSeq: haldangPopupObj?.item?.linehaulSeq,
      originalCenterCode: haldangPopupObj?.item?.originalCenterCode,
      destinationCenterCode: haldangPopupObj?.item?.destinationCenterCode,
      linehaulDate: haldangPopupObj?.item?.linehaulDate,
      linehaulType: haldangPopupObj?.item?.linehaulType,
      // loadingStatementSeq: haldangPopupObj?.item?.loadingStatementSeq,
    };

    // NOTE: 액션 버튼 api 호출 시 필요한 parameter들을 정의
    setActionParams({
      linehaulSeq: haldangPopupObj?.item?.linehaulSeq,
      loadingStatementSeq: haldangPopupObj?.item?.loadingStatementSeq,
      loadingOutSeq: haldangPopupObj?.item?.loadingOutSeq,
      transportStatementSeq: haldangPopupObj?.item?.transportStatementSeq,
      originalCenterCode: haldangPopupObj?.item?.originalCenterCode,
      destinationCenterCode: haldangPopupObj?.item?.destinationCenterCode,
      linehaulDate: haldangPopupObj?.item?.linehaulDate,
      linehaulType: haldangPopupObj?.item?.linehaulType,
      status: haldangPopupObj?.item?.status,
    });

    const data = (await serviceStore?.warehouseAction(`linehaul/picking/info`, 'GET', searchDTO as LinehaulPickingDetailSearchDTO))?.data;
    setGridData(data);

    if (data) {
      const dataKr = data?.map((row) => {
        let keyValues = returnKeyValues(convertKeyValue, row);

        return {
          ...haldangPopupObj?.item,
          ...row,
          ...keyValues,
        };
      });

      gridRef?.current?.setGridData(dataKr);
      dispatch(setLoading(null));
    }
  };

  return (
    <CommonModalNew
      title={`간선피킹 할당정보`}
      subTitle={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>피킹지시번호</strong> : {haldangPopupObj?.item?.linehaulSeq || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>출발창고</strong> : {haldangPopupObj?.item?.originalCenterCodeKr || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>도착창고</strong> : {haldangPopupObj?.item?.destinationCenterCodeKr || '-'} <Divider $direction={'V'} $spacing={'MD'} $color={'#D2D2D2'} $value={10} />
          <strong>진행상태</strong> : {haldangPopupObj?.item?.statusKr || '-'}
        </div>
      }
      style={{ width: 1400 }}
      visible={haldangPopupObj?.visible}
      setVisible={() => {
        setHaldangPopupObj(null);
      }}
      children={
        <>
          <Form onSubmit={handleSubmitFiltering}>
            <Row>
              <Col>
                <label className="col-form-label">재고번호/시리얼번호</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.INVENTORY_SEQ}
                      value={
                        detailSelectTypeObj?.INVENTORY_SEQ
                          ? {
                              label: selectlabel(detailSelectTypeObj?.INVENTORY_SEQ, masterOptions?.INVENTORY_SEQ),
                              value: detailSelectTypeObj?.INVENTORY_SEQ,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          INVENTORY_SEQ: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.INVENTORY_SEQ}
                      value={detailSearchObj?.[detailSelectTypeObj?.INVENTORY_SEQ] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.INVENTORY_SEQ]: convertFieldValue(detailSelectTypeObj['INVENTORY_SEQ'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">주문번호/송장번호</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.ORDER_SEQ}
                      value={
                        detailSelectTypeObj?.ORDER_SEQ
                          ? {
                              label: selectlabel(detailSelectTypeObj?.ORDER_SEQ, masterOptions?.ORDER_SEQ),
                              value: detailSelectTypeObj?.ORDER_SEQ,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          ORDER_SEQ: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.ORDER_SEQ}
                      value={detailSearchObj?.[detailSelectTypeObj?.ORDER_SEQ] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.ORDER_SEQ]: convertFieldValue(detailSelectTypeObj['ORDER_SEQ'], e.target.value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">제품/제품명</label>
                <div style={{ display: 'flex' }}>
                  <Col md={4} style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.SEARCH_MODEL}
                      value={
                        detailSelectTypeObj?.SEARCH_MODEL
                          ? {
                              label: selectlabel(detailSelectTypeObj?.SEARCH_MODEL, masterOptions?.SEARCH_MODEL),
                              value: detailSelectTypeObj?.SEARCH_MODEL,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSelectTypeObj({
                          ...detailSelectTypeObj,
                          SEARCH_MODEL: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ display: 'inline-block' }}>
                    <InputD
                      disabled={!detailSelectTypeObj?.SEARCH_MODEL}
                      value={detailSearchObj?.[detailSelectTypeObj?.SEARCH_MODEL] || ''}
                      onChange={(e) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          [detailSelectTypeObj?.SEARCH_MODEL]: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">판매사</label>
                <div style={{ display: 'flex' }}>
                  <Col style={{ display: 'inline-block' }}>
                    <SelectD
                      options={masterOptions?.SELLER}
                      value={
                        detailSearchObj?.partnerSeq
                          ? {
                              label: selectlabel(detailSearchObj?.partnerSeq, masterOptions?.SELLER),
                              value: detailSearchObj?.partnerSeq,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setDetailSearchObj({
                          ...detailSearchObj,
                          partnerSeq: (option as OptionItem).value,
                        });
                      }}
                    />
                  </Col>
                </div>
              </Col>

              <Col>
                <label className="col-form-label">피킹여부</label>
                <div
                  className="radio-select-area"
                  onClick={(e) => {
                    toggleBooleanOptions('pickFlag', (e.target as HTMLDivElement).dataset.flagId, detailSearchObj, setDetailSearchObj);
                  }}
                >
                  <span className={detailSearchObj?.pickFlag === true ? 'active' : ''} data-flag-id="true">
                    O
                  </span>
                  <span className={detailSearchObj?.pickFlag === false ? 'active' : ''} data-flag-id="false">
                    X
                  </span>
                </div>
              </Col>
              <Col>
                <label className="col-form-label">주문취소여부</label>
                <div
                  className="radio-select-area"
                  onClick={(e) => {
                    toggleBooleanOptions('orderCancelFlag', (e.target as HTMLDivElement).dataset.flagId, detailSearchObj, setDetailSearchObj);
                  }}
                >
                  <span className={detailSearchObj?.orderCancelFlag === true ? 'active' : ''} data-flag-id="true">
                    O
                  </span>
                  <span className={detailSearchObj?.orderCancelFlag === false ? 'active' : ''} data-flag-id="false">
                    X
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col />
              <Col />
              <Col />
              <Col />
              <Col />
              <Col style={{ textAlign: 'right', placeSelf: 'flex-end', display: 'flex', gap: '4px' }}>
                <button type="button" className="btn" style={{ border: '1px solid #DADADA', minWidth: '60px' }} onClick={resetFilters}>
                  초기화
                </button>
                <button type="submit" className="btn btn-search" style={{ flexGrow: 1 }}>
                  조회
                </button>
              </Col>
            </Row>
          </Form>
          <Divider $direction={'H'} $color={'#eaeaea'} $spacing={'LG'} $value={'FULL'} />
          {printObj?.visible && <Prints printObj={printObj} setPrintObj={setPrintObj} />}
          <div className="grid-button-area">
            <div className="red" id="linehaul-modal-rollback" onClick={handleCheckItems}>
              이전단계
            </div>
            <div className="red" id="linehaul-modal-cancel" onClick={handleCheckItems}>
              피킹취소
            </div>
            <div className="orange" id="linehaul-modal-confirm" onClick={handleCheckItems}>
              피킹지시확정{/* 이전 : 부분피킹지시확정 */}
            </div>
            <div className="gray" id="prints" onClick={handleCheckItems}>
              송장출력
            </div>
            <div className="green" id="excel" onClick={handleCheckItems}>
              엑셀다운
            </div>
          </div>
          <GridBox gridRef={gridRef} columns={columns} />
        </>
      }
    />
  );
};
