import { Fragment } from 'react/jsx-runtime';
import LDSTab from '../atoms/Tab';
import LDSListItem from '../modules/ListItem';
import LDSNavigation from '../modules/Navigation';
import LDSDivider from '../atoms/Divider';
import { COLORS, VALUES } from 'common/constants/appearance';
import LDSButton from '../atoms/Button';
import styled from 'styled-components';
import { ButtonGroup } from '../atoms/ButtonGroup';
import { LuMegaphone } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
import { setShowDropdown } from 'redux/services/portalSlice';
import { useEffect } from 'react';
import { useNotification } from './Notification.hooks';
import Loader from '../modules/Loader';
import { LDSEmptyListGroup } from '../modules/ListGroup';

export default function Notification({ setGongjiObj }) {
  const dispatch = useDispatch();
  const { alarms, fetchAlarms, pagingSetting, setPagingSetting, concatAlarmList, readNotification } = useNotification(setGongjiObj);

  useEffect(() => {
    fetchAlarms();
    setPagingSetting((prev) => {
      return {
        ...prev,
        pageNo: 1,
        fetchNew: false,
      };
    });
  }, []);

  useEffect(() => {
    if (pagingSetting?.fetchNew) {
      concatAlarmList();
    }
  }, [pagingSetting?.pageNo]);

  return (
    <>
      <LDSNavigation $style={'RESPONSIVE'} $activeId={'알림'} $hasBorder={true} $sticky={true}>
        <LDSTab $label={'알림'} $type={'TAB'} $tabId={'알림'} $activeId={'알림'} />
      </LDSNavigation>
      <NotificationItemWrapper>
        {alarms?.fetching && <Loader loadingStatus={'PUT'} />}
        {!alarms?.fetching && alarms?.list?.length === 0 && <NotificationItemEmptyGroup>표시할 알림이 없습니다.</NotificationItemEmptyGroup>}
        {alarms?.list?.length > 0 && (
          <>
            {alarms?.list?.map((ele, idx) => {
              return (
                <Fragment key={`alarms_list_${idx}`}>
                  <LDSListItem
                    $type={'NOTIFICATION'}
                    $label={ele.menu}
                    $description={ele.content}
                    $timeStamp={ele.registerDatetime}
                    onClick={() => {
                      readNotification(ele);
                      dispatch(setShowDropdown(''));
                    }}
                    $icon={<LuMegaphone />}
                    $status={ele.readFlag === false ? 'UNREAD' : 'DEFAULT'}
                  />
                  <LDSDivider $direction={'H'} $value={'FULL'} $color={COLORS.GRAY_03} />
                </Fragment>
              );
            })}
            {alarms?.list?.length !== alarms?.totalCount && (
              <ButtonGroup $padding={8} className="button-group">
                <LDSButton
                  $type={'BUTTON'}
                  $primary={false}
                  $label={'더보기'}
                  $style={'FILL'}
                  $usage={'PRIMARY'}
                  $width={'FULL'}
                  $size={'MD'}
                  $disabled={pagingSetting?.fetchNew}
                  onClick={() => {
                    if (alarms?.list?.length !== alarms?.totalCount) {
                      setPagingSetting((prev) => {
                        return {
                          ...prev,
                          pageNo: pagingSetting?.pageNo + 1,
                          fetchNew: true,
                        };
                      });
                    }
                  }}
                />
              </ButtonGroup>
            )}
          </>
        )}
      </NotificationItemWrapper>
    </>
  );
}

const NotificationItemWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .button-group {
    flex-grow: 1;
    align-items: flex-end;
  }
  .lds-loader {
    position: relative !important;

    min-height: ${VALUES.DROPDOWN_MIN_HEIGHT}px;

    & svg {
      transform: scale(0.5);
    }
  }
`;

const NotificationItemEmptyGroup = styled(LDSEmptyListGroup)`
  min-height: ${VALUES.DROPDOWN_MIN_HEIGHT}px;
  background-color: ${COLORS.WHITE};
  align-items: center;
  display: flex;
  justify-content: center;
`;
