import { useState, useEffect } from 'react';
// redux
import { useSelector } from 'react-redux';

// component
import Tab from './ssRegit';
import { DetailTopTab, IDetailTopTab } from 'components/template/detailTopTab';

// options
import { RootState } from 'redux/store';
import { getLanguangeInlocalStorage, saveLanguangeInlocalStorage } from 'common/util/lang';
import { useMasterOptions } from 'hooks/grid/useMasterOptions';
import { ORDER_MASTER } from '../../1order/orderListTab';

export const SSREGIT_MASTER = [
  //
  'DRIVER_AUTH',
  'DRIVER_WHOLE',
  'CENTER_AUTH',
  'CENTER_WHOLE',
  'SELLER_SELLER_AUTH',
  //
  'INVOICE_STATUS',
  'SS_FEE_TYPE',

  'INV_TRACK_TYPE',
  'INVOICE_UPDATE_CONTENT',
  //
  'SEARCH_ORDER',
  'SEARCH_INVOICE',
  //
  'PROMISE_TIME_ZONE_1',
  'OX_FLAG',
  'ACTIVE_OP',
];

const Index = () => {
  const [tabObj, setTabObj] = useState<IDetailTopTab>();
  const { MASTER_OBJ } = useSelector((state: RootState) => state.menu);
  const { locale } = useSelector((state: RootState) => state.locale);

  useEffect(() => {
    if (MASTER_OBJ)
      setTabObj({
        titles: ['주문조회', '판매등록'],
        routers: ['/manageorderInfoList', '/ssRegit'],
        container: [<Tab />],
      });
  }, [MASTER_OBJ]);

  useEffect(() => {
    if (locale.value) {
      const localLocale = getLanguangeInlocalStorage();
      if (localLocale !== locale.value || !MASTER_OBJ) {
        initMasters();
        saveLanguangeInlocalStorage(locale.value);
      }
    }
  }, [locale]);

  const { initMasterOptions } = useMasterOptions();
  const initMasters = async () => {
    initMasterOptions(ORDER_MASTER);
  };
  return <>{tabObj && MASTER_OBJ && <DetailTopTab tabObj={tabObj} setTabObj={setTabObj} />}</>;
};

export default Index;
