import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';
import { OMS } from 'envVar';
import { PagingListDTO, ResponseDTO } from 'interface/util';

class QNAService {
  public async getPaging(action: string, searchDTO: any): Promise<PagingListDTO<any>> {
    let url = OMS(`${action}`) + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }
  public async getAction(action: string, searchDTO: any): Promise<any[]> {
    let url = OMS(`${action}`) + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async postAction(action: string, dataDTO: any, searchDTO: any): Promise<ResponseDTO> {
    let url = OMS(`${action}`);
    if (searchDTO) url += addingParamsToUrlexceptPaging(searchDTO);
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }

  public async putAction(action: string, dataDTO: any, searchDTO: any): Promise<ResponseDTO> {
    let url = OMS(`${action}`);
    if (searchDTO) url += addingParamsToUrlexceptPaging(searchDTO);
    const rs = await httpClient.put(url, dataDTO);
    return rs;
  }
}

export const qnaService = new QNAService();
