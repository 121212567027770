import { httpClient } from 'common/http-client/axiosConfig';
import { addingParamsToUrl, addingParamsToUrlexceptPaging } from 'common/util/httpclient';

import {
  MODEL_STOCK_SEARCH,
  OMS_PRODUCT,
  OMS_PRODUCT_SAVE,
  PRODUCT_LIST_PAGING,
  PURCHASE_V2_SERIAL_PAGING,
  PURCHASE_V2_SERIAL_PRINT,
  WMS_MODEL_SAVE,
  WMS_MODEL_STOCK_MODEL_PAGING,
  OMS_PRODUCT_DETAIL_EXCEL,
  OMS_PRODUCT_MAIN_EXCEL,
  WMS_MODEL_MODI,
} from 'envVar';
import {
  ModelSetDTO,
  ModelStockAndModelDTO,
  ModelStockAndModelSearchDTO,
  PurchaseOrderSerialNumberListDTO,
  PurchaseOrderSerialNumberPrintDTO,
  PurchaseOrderSerialNumberSearchDTO,
  IdDTO,
} from 'interface/warehouse';
import { ProductDetailDTO, ProductListDTO, ProductListExcelDTO, ProductSearchDTO } from 'interface/order';
import { ResponseDTO } from 'interface/user';
import { PagingListDTO } from 'interface/util';

class ModelNproductService {
  public async getPurchaseSerialListPaging(searchDTO: PurchaseOrderSerialNumberSearchDTO): Promise<PagingListDTO<PurchaseOrderSerialNumberListDTO>> {
    let url = PURCHASE_V2_SERIAL_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async increatePurchasePrintCount(body: IdDTO[]): Promise<PurchaseOrderSerialNumberPrintDTO> {
    let url = PURCHASE_V2_SERIAL_PRINT;
    const { data, status } = await httpClient.post(url, body);

    return data;
  }

  // model
  public async getModelListPaging(searchDTO: ModelStockAndModelSearchDTO): Promise<PagingListDTO<ModelStockAndModelDTO>> {
    let url = WMS_MODEL_STOCK_MODEL_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  // 저장, 엑셀등록 둘다 가능
  public async saveModelDetail(dataDTO: ModelSetDTO): Promise<ResponseDTO> {
    let url = WMS_MODEL_SAVE;
    const rs = await httpClient.post(url, dataDTO);

    return rs;
  }

  public async getModelDetail(modelStockSeq: number): Promise<ModelStockAndModelDTO> {
    let url = `/warehouse/model/stock/model/${modelStockSeq}`;
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getModelMainExcel(searchDTO: ModelStockAndModelSearchDTO): Promise<ModelStockAndModelDTO[]> {
    let url = MODEL_STOCK_SEARCH + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  // product
  public async getProductListPaging(searchDTO: ProductSearchDTO): Promise<PagingListDTO<ProductListDTO>> {
    let url = PRODUCT_LIST_PAGING + addingParamsToUrl(searchDTO);
    const { data } = await httpClient.get(url);

    return data;
  }

  public async saveProductDetail(dataDTO: ProductDetailDTO): Promise<ResponseDTO> {
    let url = OMS_PRODUCT_SAVE;
    const rs = await httpClient.post(url, dataDTO);

    return rs;
  }

  public async putModelInfo(dataDTO: ProductDetailDTO): Promise<ResponseDTO> {
    let url = WMS_MODEL_MODI;
    const rs = await httpClient.put(url, dataDTO);

    return rs;
  }
  public async getProductDetail(productSeq: number): Promise<ProductDetailDTO> {
    let url = OMS_PRODUCT + `/${productSeq}`;
    const { data } = await httpClient.get(url);

    return data;
  }

  public async getProductMainExcel(searchDTO: ProductSearchDTO): Promise<ProductListDTO[]> {
    let url = OMS_PRODUCT_MAIN_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  public async getProductDetailExcel(searchDTO: ProductSearchDTO): Promise<ProductListExcelDTO[]> {
    let url = OMS_PRODUCT_DETAIL_EXCEL + addingParamsToUrlexceptPaging(searchDTO);
    const { data } = await httpClient.get(url);
    return data;
  }

  //

  public async getRefurAction(searchDTO: any, action: string, isPaging = false): Promise<ResponseDTO> {
    let url = `/warehouse/inventory/refurbish/${action}` + (isPaging ? addingParamsToUrl(searchDTO) : addingParamsToUrlexceptPaging(searchDTO));
    const rs = await httpClient.get(url);
    return rs;
  }

  public async postRefurAction(searchDTO: any, action: string, dataDTO: any): Promise<ResponseDTO> {
    let url = `/warehouse/inventory/refurbish/${action}`;
    if (searchDTO) url += addingParamsToUrlexceptPaging(searchDTO);
    const rs = await httpClient.post(url, dataDTO);
    return rs;
  }
}

export const modelNproductService = new ModelNproductService();
